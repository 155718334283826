export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSON: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AbgrenzungsBuchungPreview = {
  aufwandBuchungDerAufwandAbgrenzung: OrderBuchungsanweisung;
  buchungMitAufwandAbgrenzung: OrderBuchungsanweisung;
};

export type AbgrenzungsBuchungPreviewResponse = {
  data: AbgrenzungsBuchungPreview;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AbgrenzungsStornoBuchungPreview = {
  aufwandKorrekturBuchungDerAufwandAbgrenzung: OrderBuchungsanweisung;
  aufwandStornoBuchungDerAufwandAbgrenzung: OrderBuchungsanweisung;
  korrekturBuchungMitAufwandAbgrenzung: OrderBuchungsanweisung;
  originalBuchungList: Array<OrderBuchungsanweisung>;
  stornoBuchungMitAufwandAbgrenzung: OrderBuchungsanweisung;
};

export type AbgrenzungsStornoBuchungPreviewResponse = {
  data: AbgrenzungsStornoBuchungPreview;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AbrInfoAbrechnungskreis = {
  abrechnungsdefinitionId: Scalars['String']['output'];
  abrechnungsdefinitionVersionId: Scalars['String']['output'];
  abrechnungskreisId: Scalars['ID']['output'];
  aufwand: AbrInfoAufwand;
  bezeichnung: Scalars['String']['output'];
  erloes: AbrInfoErloes;
  saldo: Scalars['Float']['output'];
  sumAufwand: Scalars['Float']['output'];
  vorsaldo: Scalars['Float']['output'];
};

export type AbrInfoAufwand = {
  kontoList: Array<AbrInfoKonto>;
  summe: Scalars['Float']['output'];
};

export type AbrInfoBuchungszeile = {
  auftragId?: Maybe<Scalars['String']['output']>;
  belegDatum?: Maybe<Scalars['String']['output']>;
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegId: Scalars['String']['output'];
  belegnummer?: Maybe<Scalars['String']['output']>;
  betrag: Scalars['Float']['output'];
  buchungIdList: Array<Scalars['String']['output']>;
  buchungskreisRechtstraeger: RechtstraegerBase;
  dueDate?: Maybe<Scalars['String']['output']>;
  sollHaben: SollHabenTuple;
  standingInvoicePartialAmountId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  umbuchung?: Maybe<Scalars['Boolean']['output']>;
  umgebucht?: Maybe<Scalars['Boolean']['output']>;
  vertragspartner?: Maybe<RechtstraegerBase>;
};

export type AbrInfoErloes = {
  kontoList: Array<AbrInfoKonto>;
  summe: Scalars['Float']['output'];
};

export type AbrInfoKonto = {
  bezeichnung: Scalars['String']['output'];
  buchungszeileList: Array<AbrInfoBuchungszeile>;
  kontoId: Scalars['ID']['output'];
  summe: Scalars['Float']['output'];
};

export type AbrechdefAbrechkreis = {
  abrechnungskreis: Abrechnungskreis;
  aufteilungsschluessel?: Maybe<Aufteilungsschluessel>;
  vorschreibungsposition?: Maybe<Vorschreibungsposition>;
};

export type AbrechdefAbrechkreisInput = {
  abrechnungskreisId: Scalars['String']['input'];
  aufteilungsschluesselId?: InputMaybe<Scalars['String']['input']>;
  vorschreibungspositionId?: InputMaybe<Scalars['String']['input']>;
};

export type AbrechnungGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  fromInclusive: Scalars['String']['output'];
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  toInclusive: Scalars['String']['output'];
};

export type AbrechnungGenerierlaufResponse = {
  data: AbrechnungGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AbrechnungGenerierlaufType {
  HeAbrechnungGenerierlauf = 'HE_ABRECHNUNG_GENERIERLAUF',
  NamedHeAbrechnungGenerierlauf = 'NAMED_HE_ABRECHNUNG_GENERIERLAUF',
  NamedObjektAbrechnungGenerierlauf = 'NAMED_OBJEKT_ABRECHNUNG_GENERIERLAUF',
  NamedSubAbrechnungGenerierlauf = 'NAMED_SUB_ABRECHNUNG_GENERIERLAUF',
  ObjektAbrechnungGenerierlauf = 'OBJEKT_ABRECHNUNG_GENERIERLAUF',
  SubAbrechnungGenerierlauf = 'SUB_ABRECHNUNG_GENERIERLAUF',
  TopAbrechnungGenerierlauf = 'TOP_ABRECHNUNG_GENERIERLAUF',
}

export type AbrechnungListEntry = {
  abrechnungId: Scalars['ID']['output'];
  auftragCreationStatus: AuftragCreationStatusTuple;
  bezeichnung: Scalars['String']['output'];
  countAuftragCreated?: Maybe<Scalars['Int']['output']>;
  countAuftragToCreateTotal?: Maybe<Scalars['Int']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  fromInclusive: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  objektAbrechnungId?: Maybe<Scalars['String']['output']>;
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung: Scalars['String']['output'];
  status: AbrechnungStatusTuple;
  toInclusive: Scalars['String']['output'];
  type: AbrechnungTypeTuple;
  updatedByMitarebiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type AbrechnungListEntryPage = {
  contentList: Array<AbrechnungListEntry>;
  page: Page;
};

export type AbrechnungListEntryPageResponse = {
  data: AbrechnungListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AbrechnungOrderBy {
  Bezeichnung = 'BEZEICHNUNG',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  ObjektKurzBezeichnung = 'OBJEKT_KURZ_BEZEICHNUNG',
  Status = 'STATUS',
  Type = 'TYPE',
  Zeitraum = 'ZEITRAUM',
}

export enum AbrechnungStatus {
  Invalid = 'INVALID',
  ReviewError = 'REVIEW_ERROR',
  UnderReview = 'UNDER_REVIEW',
  Valid = 'VALID',
}

export type AbrechnungStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: AbrechnungStatus;
};

export enum AbrechnungType {
  BkAbrechnung = 'BK_ABRECHNUNG',
  BkTopAbrechnung = 'BK_TOP_ABRECHNUNG',
  HeAbrechnung = 'HE_ABRECHNUNG',
  SubAbrechnung = 'SUB_ABRECHNUNG',
  WeAbrechnung = 'WE_ABRECHNUNG',
  WeTopAbrechnung = 'WE_TOP_ABRECHNUNG',
}

export type AbrechnungTypeListResponse = {
  data: Array<AbrechnungTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AbrechnungTypeTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: AbrechnungType;
};

export type AbrechnungsdefinitionBase = {
  abrechnungsdefinitionId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
  status?: Maybe<AbrechnungsdefinitionStatusTuple>;
  type: AbrechnungsdefinitionTypeTuple;
};

export type AbrechnungsdefinitionKontoAbrechnungskreis = {
  abrechnungsdefinition: AbrechnungsdefinitionListEntry;
  abrechnungsdefinitionVersionId: Scalars['String']['output'];
  kontoAbrechnungskreisList: Array<KontoAbrechnungskreis>;
};

export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnung = {
  abrechnungsdefinition: AbrechnungsdefinitionListEntry;
  kontoAbrechnungskreisList: Array<AbrechnungsdefinitionKontoAbrechnungskreis>;
};

export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput = {
  abrechnungsdefinitionId: Scalars['String']['input'];
  abrechnungsdefinitionVersionId: Scalars['String']['input'];
  abrechnungskreisId?: InputMaybe<Scalars['String']['input']>;
  aufteilungsschluesselId?: InputMaybe<Scalars['String']['input']>;
  buchungszeilenAnzeigen: Scalars['Boolean']['input'];
  expenseReducing: Scalars['Boolean']['input'];
  kontoId: Scalars['String']['input'];
  ustVomAufwand: Scalars['Boolean']['input'];
};

export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListInput = {
  zuordnungList: Array<AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput>;
};

export type AbrechnungsdefinitionListEntry = {
  abrechnungsdefinitionId: Scalars['ID']['output'];
  abrechnungsdefinitionVersion?: Maybe<AbrechnungsdefinitionVersion>;
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<AbrechnungsdefinitionStatusTuple>;
  textBausteineBefuellt: Scalars['Boolean']['output'];
  type: AbrechnungsdefinitionTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type AbrechnungsdefinitionListEntryPage = {
  contentList: Array<AbrechnungsdefinitionListEntry>;
  page: Page;
};

export type AbrechnungsdefinitionListEntryPageResponse = {
  data: AbrechnungsdefinitionListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AbrechnungsdefinitionListEntryResponse = {
  data: AbrechnungsdefinitionListEntry;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AbrechnungsdefinitionListResponse = {
  data: Array<AbrechnungsdefinitionListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AbrechnungsdefinitionStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type AbrechnungsdefinitionStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: AbrechnungsdefinitionStatus;
};

export type AbrechnungsdefinitionTemplate = {
  textbausteinList: Array<Textbaustein>;
};

export type AbrechnungsdefinitionTemplateResponse = {
  data: AbrechnungsdefinitionTemplate;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AbrechnungsdefinitionTemplateType {
  HeAbrechnung = 'HE_ABRECHNUNG',
  ObjektAbrechnung = 'OBJEKT_ABRECHNUNG',
  SubAbrechnung = 'SUB_ABRECHNUNG',
  TopAbrechnung = 'TOP_ABRECHNUNG',
}

export enum AbrechnungsdefinitionType {
  BkAbrechnung = 'BK_ABRECHNUNG',
  HeAbrechnung = 'HE_ABRECHNUNG',
  SubAbrechnung = 'SUB_ABRECHNUNG',
  WeAbrechnung = 'WE_ABRECHNUNG',
}

export type AbrechnungsdefinitionTypeResponse = {
  data: AbrechnungsdefinitionTypeTuple;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AbrechnungsdefinitionTypeTuple = {
  text: Scalars['String']['output'];
  value: AbrechnungsdefinitionType;
};

export type AbrechnungsdefinitionVersion = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreis>;
  abrechnungsdefinitionVersionId: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kommentar?: Maybe<Scalars['String']['output']>;
  kontoAbrechnungskreisList: Array<KontoAbrechnungskreis>;
  lastUpdateTs: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type AbrechnungsdefinitionVersionListResponse = {
  data: Array<Abrechnungskreis>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Abrechnungskreis = {
  abrechnungskreisId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['ID']['output']>;
  status: AbrechnungskreisStatusTuple;
  type: AbrechnungskreisTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  verrechnungUstVomAufwand?: Maybe<VerrechnungUstVomAufwandTuple>;
  warningList: Array<ResponseDataWarning>;
};

export type AbrechnungskreisCreateInput = {
  bezeichnung: Scalars['String']['input'];
  type: AbrechnungskreisType;
  verrechnungUstVomAufwand?: InputMaybe<VerrechnungsUstVomAufwand>;
};

export type AbrechnungskreisListResponse = {
  data: Array<Abrechnungskreis>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AbrechnungskreisResponse = {
  data: Abrechnungskreis;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AbrechnungskreisStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type AbrechnungskreisStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: AbrechnungskreisStatus;
};

export type AbrechnungskreisTemplateListResponse = {
  data: Array<Abrechnungskreis>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AbrechnungskreisTemplateResponse = {
  data: Abrechnungskreis;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AbrechnungskreisType {
  Ausgaben = 'AUSGABEN',
  Bewirtschaftungskosten = 'BEWIRTSCHAFTUNGSKOSTEN',
  Eigentuemerverrechnung = 'EIGENTUEMERVERRECHNUNG',
  Einnahmen = 'EINNAHMEN',
  Information = 'INFORMATION',
  ReparaturFonds = 'REPARATUR_FONDS',
  Vorsteuerkuerzung = 'VORSTEUERKUERZUNG',
}

export type AbrechnungskreisTypeListResponse = {
  data: Array<AbrechnungskreisTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AbrechnungskreisTypeTuple = {
  text: Scalars['String']['output'];
  value: AbrechnungskreisType;
};

export type AbrechnungskreisUpdateInput = {
  bezeichnung: Scalars['String']['input'];
  type: AbrechnungskreisType;
  verrechnungUstVomAufwand?: InputMaybe<VerrechnungsUstVomAufwand>;
};

export type AbstractAuftragVerbuchenGenerierlaufEntryAuftragDetails = AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute & {
  auftragId: Scalars['String']['output'];
  belegFileId: Scalars['String']['output'];
  belegId: Scalars['String']['output'];
  belegType: Scalars['String']['output'];
  belegdatum: Scalars['String']['output'];
  belegnummer: Scalars['String']['output'];
  buchungIdList: Array<Scalars['String']['output']>;
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  sumUst: Scalars['Float']['output'];
  type: AbstractAuftragVerbuchenGenerierlaufEntryDetailsType;
};

export type AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute = {
  belegFileId: Scalars['String']['output'];
  belegId: Scalars['String']['output'];
  belegType: Scalars['String']['output'];
  belegdatum: Scalars['String']['output'];
  belegnummer: Scalars['String']['output'];
  buchungIdList: Array<Scalars['String']['output']>;
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  sumUst: Scalars['Float']['output'];
  type: AbstractAuftragVerbuchenGenerierlaufEntryDetailsType;
};

export enum AbstractAuftragVerbuchenGenerierlaufEntryDetailsType {
  Auftrag = 'AUFTRAG',
  SingleInvoice = 'SINGLE_INVOICE',
}

export type AbstractAuftragVerbuchenGenerierlaufEntrySingleInvoiceDetails = AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute & {
  belegFileId: Scalars['String']['output'];
  belegId: Scalars['String']['output'];
  belegType: Scalars['String']['output'];
  belegdatum: Scalars['String']['output'];
  belegnummer: Scalars['String']['output'];
  buchungIdList: Array<Scalars['String']['output']>;
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  sumUst: Scalars['Float']['output'];
  type: AbstractAuftragVerbuchenGenerierlaufEntryDetailsType;
};

export type AbstractBeInfoFeld = {
  beInfoFeldId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  postIt?: Maybe<PostIt>;
  status: InfoFeldStatusTuple;
  type: BeInfoFeldTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type AbstractDeviationAttribute = {
  attributeId: Scalars['Int']['output'];
  hasChanged: Scalars['Boolean']['output'];
  name: DeviationAttributeNameTypeTuple;
  type: DeviationAttributeType;
};

export type AbstractDeviationSourceEntity = {
  entityId: Scalars['ID']['output'];
  type: DeviationSourceEntityTypeTuple;
};

export type AbstractDeviationUriId = {
  type: DeviationUriIdType;
};

export type AbstractHistoryAttribute = {
  name: HistoryAttributeName;
  type: HistoryAttributeType;
};

export type AbstractObjektInfoFeld = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  objektInfoFeldId: Scalars['ID']['output'];
  postIt?: Maybe<PostIt>;
  status: InfoFeldStatusTuple;
  type: ObjektInfoFeldTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type AbstractRechtstraegerInfoFeld = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  postIt?: Maybe<PostIt>;
  rechtstraegerInfoFeldId: Scalars['ID']['output'];
  status: InfoFeldStatusTuple;
  type: RechtstraegerInfoFeldTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type AbstractVPosWertChangeReason = {
  text: Scalars['String']['output'];
  type: ChangeReason;
};

export type AbstractVertragInfoFeld = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  postIt?: Maybe<PostIt>;
  status: InfoFeldStatusTuple;
  type: VertragInfoFeldTypeTuple;
  vertragInfoFeldId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type AbstractVertragVposIndexationAgreement = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  indexOld?: Maybe<Scalars['Float']['output']>;
  indexOldPreliminary: Scalars['Boolean']['output'];
  indexSeries: IndexSeries;
  monthYearOld: Scalars['String']['output'];
  type: ValuePreservationTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  vertragVposIndexationAgreementId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type ActionInput = {
  action: Scalars['String']['input'];
};

export type ActionResponse = {
  data: Scalars['JSON']['output'];
  errorList?: Maybe<Array<ErrorData>>;
  warningList?: Maybe<Array<WarningData>>;
};

export type Address = {
  addressId: Scalars['ID']['output'];
  city: Scalars['String']['output'];
  cityAdditionalInformation?: Maybe<Scalars['String']['output']>;
  countryCodeIso2: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  haupt?: Maybe<Scalars['Boolean']['output']>;
  houseEntranceApartmentNumber?: Maybe<Scalars['String']['output']>;
  postofficeBoxNumber?: Maybe<Scalars['String']['output']>;
  postofficeBoxZipCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  tagList: Array<Scalars['String']['output']>;
  type: AddressType;
  warningList: Array<ResponseDataWarning>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AddressAndContactTagListInput = {
  haupt: Scalars['Boolean']['input'];
  tagList: Array<InputMaybe<Scalars['String']['input']>>;
};

export type AddressCreateInput = {
  city: Scalars['String']['input'];
  cityAdditionalInformation?: InputMaybe<Scalars['String']['input']>;
  countryCodeIso2: Scalars['String']['input'];
  houseEntranceApartmentNumber?: InputMaybe<Scalars['String']['input']>;
  operatingSiteId?: InputMaybe<Scalars['String']['input']>;
  postofficeBoxNumber?: InputMaybe<Scalars['String']['input']>;
  postofficeBoxZipCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  type: AddressType;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type AddressResponse = {
  data: Address;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AddressStreetCreateInput = {
  city: Scalars['String']['input'];
  cityAdditionalInformation?: InputMaybe<Scalars['String']['input']>;
  countryCodeIso2: Scalars['String']['input'];
  houseEntranceApartmentNumber: Scalars['String']['input'];
  street: Scalars['String']['input'];
  type: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export enum AddressType {
  PostofficeBox = 'POSTOFFICE_BOX',
  Street = 'STREET',
}

export type AddressUpdateInput = {
  addressId?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  cityAdditionalInformation?: InputMaybe<Scalars['String']['input']>;
  countryCodeIso2: Scalars['String']['input'];
  houseEntranceApartmentNumber?: InputMaybe<Scalars['String']['input']>;
  postofficeBoxNumber?: InputMaybe<Scalars['String']['input']>;
  postofficeBoxZipCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  type: AddressType;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type AddressesAndContacts = {
  contactPersonList: Array<ContactPerson>;
  emailContactList: Array<Contact>;
  hauptAddress?: Maybe<Address>;
  hauptEmailContact?: Maybe<Contact>;
  hauptPhoneContact?: Maybe<Contact>;
  phoneContactList: Array<Contact>;
  postofficeBoxAddressList: Array<Address>;
  streetAddressList: Array<Address>;
};

export type AddressesAndContactsResponse = {
  data: AddressesAndContacts;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AmountReducingType {
  Expense = 'EXPENSE',
  Revenue = 'REVENUE',
}

export type AmountReducingTypeTuple = {
  text: Scalars['String']['output'];
  value: AmountReducingType;
};

export type AppAuftrag = {
  auftragIdList: Array<Scalars['String']['output']>;
  auftragsKopf: AppAuftragsKopf;
  bestandseinheit: Bestandseinheit;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  objekt: Objekt;
  sumBrutto?: Maybe<Scalars['Float']['output']>;
  sumNetto?: Maybe<Scalars['Float']['output']>;
  sumUst?: Maybe<Scalars['Float']['output']>;
  vertragId: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type AppAuftragsKopf = {
  auftragsartType: AuftragsartTypeTuple;
  aussteller: Rechtstraeger;
  empfaenger: Rechtstraeger;
  fakturierungsperiode: Fakturierungsperiode;
};

export enum AppGenerierlaufType {
  ApplyVertragVposIndexedValues = 'APPLY_VERTRAG_VPOS_INDEXED_VALUES',
  Auftragserstellung = 'AUFTRAGSERSTELLUNG',
  BkObjektAbrechnungErstellung = 'BK_OBJEKT_ABRECHNUNG_ERSTELLUNG',
  BkTopAbrechnung = 'BK_TOP_ABRECHNUNG',
  FirmendatenInitializer = 'FIRMENDATEN_INITIALIZER',
  HeAbrechnungErstellung = 'HE_ABRECHNUNG_ERSTELLUNG',
  InfoMailDeliveryDetermineRecipientList = 'INFO_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST',
  InfoMailDeliverySend = 'INFO_MAIL_DELIVERY_SEND',
  SubAbrechnungErstellung = 'SUB_ABRECHNUNG_ERSTELLUNG',
  VertragVposIndexedValuesErstellung = 'VERTRAG_VPOS_INDEXED_VALUES_ERSTELLUNG',
  WeObjektAbrechnungErstellung = 'WE_OBJEKT_ABRECHNUNG_ERSTELLUNG',
  WeTopAbrechnung = 'WE_TOP_ABRECHNUNG',
  ZinslisteErstellung = 'ZINSLISTE_ERSTELLUNG',
}

export type AppKonto = {
  bezeichnung: Scalars['String']['output'];
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kontoId: Scalars['ID']['output'];
  kontoKlasse: Scalars['String']['output'];
  nummer: Scalars['String']['output'];
};

export type ApplyVertragVposIndexedValueGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type ApplyVertragVposIndexedValueGenerierlaufCreateInput = {
  bestandseinheitId?: InputMaybe<Scalars['String']['input']>;
  indexSeriesId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  validFrom?: InputMaybe<Scalars['String']['input']>;
  validTo?: InputMaybe<Scalars['String']['input']>;
  valuePreservationTypeList?: InputMaybe<Array<ValuePreservationType>>;
  vertragId?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
  vorschreibungspositionName?: InputMaybe<Scalars['String']['input']>;
};

export type ApplyVertragVposIndexedValueGenerierlaufEntry = {
  bestandseinheitBezeichnung: Scalars['String']['output'];
  bestandseinheitId: Scalars['String']['output'];
  currentStep: Scalars['Int']['output'];
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  indexedValue: VertragVposIndexedValue;
  infoMessage?: Maybe<Scalars['String']['output']>;
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung: Scalars['String']['output'];
  rechnungsAusstellerBezeichnung: Scalars['String']['output'];
  rechnungsAusstellerId: Scalars['String']['output'];
  retry: Scalars['Boolean']['output'];
  stepCount: Scalars['Int']['output'];
  stepList: Array<GenerierlaufAsyncStep>;
  vertragBezeichnung: Scalars['String']['output'];
  vertragId: Scalars['String']['output'];
  vertragspartnerId: Scalars['String']['output'];
  vertragspartnerKurzBezeichnung: Scalars['String']['output'];
  warningList: Array<WarningData>;
};

export type ApplyVertragVposIndexedValueGenerierlaufResponse = {
  data: ApplyVertragVposIndexedValueGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ApplyVertragVposIndexedValueListEntryPage = {
  contentList: Array<ApplyVertragVposIndexedValueGenerierlaufEntry>;
  page: Page;
};

export type ApplyVertragVposIndexedValueListEntryPageResponse = {
  data: ApplyVertragVposIndexedValueListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListResponse = {
  data: Array<AbrechnungsdefinitionKontoAbrechnungskreisZuordnung>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Aufteilungsschluessel = {
  aufteilungsschluesselId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
  bezugsbasis: BezugsbasisTuple;
  bezugsbasisEditable?: Maybe<Scalars['Boolean']['output']>;
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  masseinheit: MasseinheitTuple;
  status: AufteilungsschluesselStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type AufteilungsschluesselCreateInput = {
  bezeichnung: Scalars['String']['input'];
  bezugsbasis: Scalars['String']['input'];
  kurzBezeichnung: Scalars['String']['input'];
  masseinheit: Scalars['String']['input'];
};

export type AufteilungsschluesselPage = {
  contentList: Array<Aufteilungsschluessel>;
  page: Page;
};

export type AufteilungsschluesselPageResponse = {
  data: AufteilungsschluesselPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AufteilungsschluesselResponse = {
  data: Aufteilungsschluessel;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AufteilungsschluesselStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type AufteilungsschluesselStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: AufteilungsschluesselStatus;
};

export type AufteilungsschluesselTemplateListResponse = {
  data: Array<Aufteilungsschluessel>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AufteilungsschluesselUpdateInput = {
  bezeichnung: Scalars['String']['input'];
  bezugsbasis: Scalars['String']['input'];
  kurzBezeichnung: Scalars['String']['input'];
  masseinheit: Scalars['String']['input'];
};

export type Auftrag = {
  auftragId: Scalars['ID']['output'];
  auftragsKopf: AuftragsKopf;
  auftragsPositionList: Array<AuftragsPosition>;
  auftragsart: AuftragsartBase;
  billingAddress: OrderAddress;
  buchungErrorList: Array<ErrorData>;
  buchungIdList?: Maybe<Array<Scalars['String']['output']>>;
  copyEmailRecipientList?: Maybe<Array<EmailRecipient>>;
  copyMailDeliveryList: Array<VorschreibungMailDelivery>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['String']['output'];
  eigenbeleg?: Maybe<Eigenbeleg>;
  empfaengerBankDetailsList: Array<Maybe<BankDetails>>;
  fibuStatus?: Maybe<AuftragFibuStatusTuple>;
  generierDatum?: Maybe<Scalars['String']['output']>;
  generierlaufId?: Maybe<Scalars['String']['output']>;
  hasAuftragskette: Scalars['Boolean']['output'];
  objektBestandseinheitIdDataList: Array<ObjektBestandseinheitId>;
  originalMailDelivery?: Maybe<VorschreibungMailDelivery>;
  status: AuftragStatusTuple;
  stornierbar?: Maybe<Scalars['Boolean']['output']>;
  storniert: Scalars['Boolean']['output'];
  stornoAuftrag?: Maybe<Auftrag>;
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  sumUst: Scalars['Float']['output'];
};

export enum AuftragAction {
  ApplyAllDeviations = 'APPLY_ALL_DEVIATIONS',
  Stornieren = 'STORNIEREN',
}

export type AuftragCreateInput = {
  auftragsKopf: AuftragskopfInput;
  auftragsPositionList: AuftragsPositionListInput;
  billingAddressId: Scalars['String']['input'];
  generierDatum: Scalars['String']['input'];
  generierlaufId: Scalars['String']['input'];
};

export enum AuftragCreationStatus {
  Created = 'CREATED',
  NotCreatable = 'NOT_CREATABLE',
  NotCreated = 'NOT_CREATED',
}

export type AuftragCreationStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: AuftragCreationStatus;
};

export type AuftragDeviationListResponse = {
  data: Array<Deviation>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AuftragDeviationSourceEntity = AbstractDeviationSourceEntity & {
  belegDatum: Scalars['String']['output'];
  belegnummer: Scalars['String']['output'];
  entityId: Scalars['ID']['output'];
  rechnungsausstellerId: Scalars['String']['output'];
  rechnungsempfaengerId: Scalars['String']['output'];
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  type: DeviationSourceEntityTypeTuple;
};

export enum AuftragFibuStatus {
  Freigegeben = 'FREIGEGEBEN',
  Uebertragen = 'UEBERTRAGEN',
  UmbuchungFreigegeben = 'UMBUCHUNG_FREIGEGEBEN',
}

export type AuftragFibuStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: AuftragFibuStatus;
};

export type AuftragGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  labelList: Array<Label>;
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type AuftragGenerierlaufEntry = {
  auftragCreateTs?: Maybe<Scalars['String']['output']>;
  auftragCreatedBy?: Maybe<Scalars['String']['output']>;
  auftragCreatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  auftragIdList: Array<Scalars['String']['output']>;
  aussteller: RechtstraegerBase;
  bestandseinheit: BestandseinheitBase;
  empfaenger: RechtstraegerBase;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  fakturierungsperiode: Fakturierungsperiode;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  objekt: ObjektBase;
  sumBrutto?: Maybe<Scalars['Float']['output']>;
  sumNetto?: Maybe<Scalars['Float']['output']>;
  sumUst?: Maybe<Scalars['Float']['output']>;
  vertragId?: Maybe<Scalars['String']['output']>;
  warningList: Array<WarningData>;
};

export type AuftragGenerierlaufInput = {
  generierDatum: Scalars['String']['input'];
  objektAbrechnungId?: InputMaybe<Scalars['String']['input']>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  vorschreibungsperiodeList?: InputMaybe<Array<VorschreibungsperiodeInput>>;
};

export type AuftragGenerierlaufListEntryPage = {
  contentList: Array<AuftragGenerierlaufEntry>;
  page: Page;
};

export type AuftragGenerierlaufListEntryPageResponse = {
  data: AuftragGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AuftragGenerierlaufOrderBy {
  Aussteller = 'AUSSTELLER',
  Brutto = 'BRUTTO',
  Empfaenger = 'EMPFAENGER',
  Netto = 'NETTO',
  Objekt = 'OBJEKT',
  Status = 'STATUS',
  Ust = 'UST',
  Zinsliste = 'ZINSLISTE',
}

export type AuftragGenerierlaufResponse = {
  data: AuftragGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AuftragLinkListEntry = {
  auftragId: Scalars['String']['output'];
  auftragsartType: AuftragsartTypeTuple;
  ausstellerRechtstraegerId: Scalars['String']['output'];
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegdatum?: Maybe<Scalars['String']['output']>;
  belegnummer?: Maybe<Scalars['String']['output']>;
  buchungIdList?: Maybe<Array<Scalars['String']['output']>>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current?: Maybe<Scalars['Boolean']['output']>;
  dueDate: DueDateVorschreibungTuple;
  empfaengerRechtstraegerId: Scalars['String']['output'];
  paymentMethod: PaymentMethodTuple;
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  sumUst: Scalars['Float']['output'];
  verrechnungsart: VerrechnungsartTuple;
};

export type AuftragLinkListEntryListResponse = {
  data: Array<AuftragLinkListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AuftragListEntry = {
  auftragId: Scalars['String']['output'];
  auftragsart: AuftragsartBase;
  aussteller: OrderRechtstraeger;
  ausstellerBankDetails: OrderBankDetails;
  ausstellerRechtstraegerId: Scalars['String']['output'];
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegId?: Maybe<Scalars['String']['output']>;
  belegnummer?: Maybe<Scalars['String']['output']>;
  bestandseinheitWithObjektBezeichnungList: Array<Scalars['String']['output']>;
  buchungIdList?: Maybe<Array<Scalars['String']['output']>>;
  buchungsanweisungStatus?: Maybe<OrderBuchungsanweisungStatusTuple>;
  buchungskreisId?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  empfaenger: OrderRechtstraeger;
  empfaengerBankDetails?: Maybe<OrderBankDetails>;
  empfaengerRechtstraegerId: Scalars['String']['output'];
  fakturierungsperiode: Fakturierungsperiode;
  generierlaufId?: Maybe<Scalars['String']['output']>;
  objektBestandseinheitIdDataList: Array<ObjektBestandseinheitId>;
  offenerBetrag?: Maybe<Scalars['Float']['output']>;
  paymentCreatable: Scalars['Boolean']['output'];
  paymentMethod: PaymentMethodTuple;
  rechnungsDatum?: Maybe<Scalars['String']['output']>;
  sepaCreditorId?: Maybe<Scalars['String']['output']>;
  sepaMandatReference?: Maybe<Scalars['String']['output']>;
  status: AuftragStatusTuple;
  storniert: Scalars['Boolean']['output'];
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  sumUst: Scalars['Float']['output'];
  vertragsart: OrderVertragsartTuple;
};

export type AuftragListEntryPage = {
  contentList: Array<AuftragListEntry>;
  page: Page;
};

export type AuftragListEntryPageResponse = {
  data: AuftragListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AuftragListSummary = {
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  sumUst: Scalars['Float']['output'];
};

export type AuftragListSummaryResponse = {
  data: AuftragListSummary;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AuftragOrderBy {
  AuftragsArt = 'AUFTRAGS_ART',
  Aussteller = 'AUSSTELLER',
  AusstellerBankDetailsIban = 'AUSSTELLER_BANK_DETAILS_IBAN',
  Belegnummer = 'BELEGNUMMER',
  Brutto = 'BRUTTO',
  BuchungsanweisungStatus = 'BUCHUNGSANWEISUNG_STATUS',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  DueDate = 'DUE_DATE',
  Empfaenger = 'EMPFAENGER',
  EmpfaengerBankDetailsIban = 'EMPFAENGER_BANK_DETAILS_IBAN',
  Fakturierungsperiode = 'FAKTURIERUNGSPERIODE',
  Kundennummer = 'KUNDENNUMMER',
  Netto = 'NETTO',
  PaymentMethod = 'PAYMENT_METHOD',
  RechnungsDatum = 'RECHNUNGS_DATUM',
  SepaCreditorId = 'SEPA_CREDITOR_ID',
  SepaMandatReference = 'SEPA_MANDAT_REFERENCE',
  Status = 'STATUS',
  Ust = 'UST',
  Vertragsart = 'VERTRAGSART',
}

export type AuftragResponse = {
  data: Auftrag;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AuftragStatus {
  Invalid = 'INVALID',
  Valid = 'VALID',
}

export type AuftragStatusDetails = {
  auftragId: Scalars['String']['output'];
  buchungsanweisungStatus?: Maybe<OrderBuchungsanweisungStatusTuple>;
  eigenbelegStatus: EigenbelegStatusTuple;
  fakturierungsperiode: Fakturierungsperiode;
  offenerBetrag?: Maybe<Scalars['Float']['output']>;
  paymentCreatable: Scalars['Boolean']['output'];
  paymentIdList: Array<Scalars['String']['output']>;
};

export type AuftragStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: AuftragStatus;
};

export type AuftragVerbuchenGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type AuftragVerbuchenGenerierlaufEntry = {
  buchungsdatum?: Maybe<Scalars['String']['output']>;
  currentStep?: Maybe<Scalars['Int']['output']>;
  entryDetailsList: Array<AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  fakturierungsperiode: Fakturierungsperiode;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  rechnungsaussteller: OrderRechtstraeger;
  rechnungsempfaenger: OrderRechtstraeger;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type AuftragVerbuchenGenerierlaufInput = {
  buchungsdatum: Scalars['String']['input'];
  dueDate?: InputMaybe<Scalars['String']['input']>;
  generierDatum: Scalars['String']['input'];
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AuftragVerbuchenGenerierlaufListEntryPage = {
  contentList: Array<AuftragVerbuchenGenerierlaufEntry>;
  page: Page;
};

export type AuftragVerbuchenGenerierlaufListEntryPageResponse = {
  data: AuftragVerbuchenGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AuftragVerbuchenGenerierlaufOrderBy {
  Fakturierungsperiode = 'FAKTURIERUNGSPERIODE',
  Rechnungsaussteller = 'RECHNUNGSAUSSTELLER',
  Rechnungsempfaenger = 'RECHNUNGSEMPFAENGER',
  Status = 'STATUS',
}

export type AuftragVerbuchenGenerierlaufResponse = {
  data: AuftragVerbuchenGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AuftragsKopf = {
  auftragsartType: AuftragsartTypeTuple;
  aussteller: OrderRechtstraeger;
  ausstellerBankDetails: OrderBankDetails;
  belegform: BelegFormTuple;
  consolidationCriteria?: Maybe<Scalars['String']['output']>;
  deliveryAddress: OrderAddress;
  dueDateVorschreibung: DueDateVorschreibungTuple;
  empfaenger: OrderRechtstraeger;
  empfaengerBankDetails?: Maybe<OrderBankDetails>;
  fakturierungsperiode: Fakturierungsperiode;
  objekt: OrderObjekt;
  paymentMethod: PaymentMethodTuple;
  rechnungsAusstellerBezeichnung: Scalars['String']['output'];
  recipientEmailContact: OrderContact;
  sepaCreditorId?: Maybe<Scalars['String']['output']>;
  sepaMandatReference?: Maybe<Scalars['String']['output']>;
  verrechnungsart: VerrechnungsartTuple;
  vertragsart: OrderVertragsartTuple;
  zustellRechtstraeger: OrderRechtstraeger;
};

export type AuftragsPosition = {
  auftragId: Scalars['ID']['output'];
  auftragsPositionId: Scalars['String']['output'];
  bestandseinheit: Bestandseinheit;
  bestandseinheitId: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  brutto: Scalars['Float']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  fibuStatus?: Maybe<AuftragFibuStatusTuple>;
  generierDatum?: Maybe<Scalars['String']['output']>;
  generierlaufId?: Maybe<Scalars['String']['output']>;
  labelList: Array<Label>;
  netto: Scalars['Float']['output'];
  objekt: Objekt;
  objektId: Scalars['String']['output'];
  originId: Scalars['String']['output'];
  performancePeriodFromInclusive: Scalars['String']['output'];
  performancePeriodToInclusive: Scalars['String']['output'];
  rechnungskennzeichen: Scalars['String']['output'];
  steuersatz: Scalars['Int']['output'];
  ust: Scalars['Float']['output'];
  ustKategorieEntryId: Scalars['String']['output'];
  ustKategorieId: Scalars['String']['output'];
  ustKategorieVersionId: Scalars['String']['output'];
  vertragBezeichnung: Scalars['String']['output'];
  vertragId: Scalars['String']['output'];
  vertragsart: VertragsartTuple;
  vorschreibungspositionBezeichnung: Scalars['String']['output'];
  vorschreibungspositionId: Scalars['String']['output'];
};

export type AuftragsPositionLabelGroup = {
  label: Scalars['String']['output'];
  valueCountMap: Scalars['JSON']['output'];
};

export type AuftragsPositionListInput = {
  bestandseinheitId: Scalars['String']['input'];
  bezeichnung: Scalars['String']['input'];
  brutto: Scalars['Float']['input'];
  labelList: Array<LabelInput>;
  netto: Scalars['Float']['input'];
  objektId: Scalars['String']['input'];
  originId: Scalars['String']['input'];
  performancePeriodFromInclusive: Scalars['String']['input'];
  performancePeriodToInclusive: Scalars['String']['input'];
  rechnungskennzeichen?: InputMaybe<Scalars['String']['input']>;
  steuersatz?: InputMaybe<Scalars['Int']['input']>;
  ust: Scalars['Float']['input'];
  ustKategorieEntryId: Scalars['String']['input'];
  ustKategorieId: Scalars['String']['input'];
  ustKategorieVersionId: Scalars['String']['input'];
  vertragId: Scalars['String']['input'];
  vertragsart: Verrechnungsart;
  vorschreibungspositionId: Scalars['String']['input'];
};

export type Auftragsart = {
  auftragsartId: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  showUstBlock: Scalars['Boolean']['output'];
  status: AuftragsartStatusTuple;
  stornoAuftragsart?: Maybe<Auftragsart>;
  textbausteinList: Array<Textbaustein>;
  type: AuftragsartTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type AuftragsartBase = {
  auftragsartId: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  showUstBlock: Scalars['Boolean']['output'];
  status: AuftragsartStatusTuple;
  type: AuftragsartTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type AuftragsartBaseListResponse = {
  data: Array<AuftragsartBase>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AuftragsartCreateInput = {
  bezeichnung: Scalars['String']['input'];
  showUstBlock: Scalars['Boolean']['input'];
  stornoAuftragsartId?: InputMaybe<Scalars['String']['input']>;
  textbausteinList: Array<TextbausteinInput>;
  type: AuftragsartType;
};

export type AuftragsartResponse = {
  data: Auftragsart;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AuftragsartStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type AuftragsartStatusTuple = {
  text: Scalars['String']['output'];
  value: AuftragsartStatus;
};

export type AuftragsartTemplateListResponse = {
  data: Array<AuftragsartBase>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AuftragsartTemplateResponse = {
  data: Auftragsart;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AuftragsartTemplatesListResponse = {
  data: Array<Textbaustein>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AuftragsartType {
  VorschreibungDauerrechnung = 'VORSCHREIBUNG_DAUERRECHNUNG',
  VorschreibungDauerrechnungStorno = 'VORSCHREIBUNG_DAUERRECHNUNG_STORNO',
  VorschreibungEinzel = 'VORSCHREIBUNG_EINZEL',
  VorschreibungEinzelAbrechnungsergebnisGutschrift = 'VORSCHREIBUNG_EINZEL_ABRECHNUNGSERGEBNIS_GUTSCHRIFT',
  VorschreibungEinzelAbrechnungsergebnisGutschriftStorno = 'VORSCHREIBUNG_EINZEL_ABRECHNUNGSERGEBNIS_GUTSCHRIFT_STORNO',
  VorschreibungEinzelAbrechnungsergebnisRechnung = 'VORSCHREIBUNG_EINZEL_ABRECHNUNGSERGEBNIS_RECHNUNG',
  VorschreibungEinzelAbrechnungsergebnisRechnungStorno = 'VORSCHREIBUNG_EINZEL_ABRECHNUNGSERGEBNIS_RECHNUNG_STORNO',
  VorschreibungEinzelStorno = 'VORSCHREIBUNG_EINZEL_STORNO',
}

export type AuftragsartTypeListResponse = {
  data: Array<AuftragsartTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AuftragsartTypeTuple = {
  text: Scalars['String']['output'];
  value: AuftragsartType;
};

export type AuftragsartUpdateInput = {
  bezeichnung: Scalars['String']['input'];
  showUstBlock: Scalars['Boolean']['input'];
  stornoAuftragsartId?: InputMaybe<Scalars['String']['input']>;
  textbausteinList: Array<TextbausteinInput>;
};

export type AuftragskopfInput = {
  auftragsartType: AuftragsartType;
  ausstellerBankDetailsId: Scalars['String']['input'];
  ausstellerRechtstraegerId: Scalars['String']['input'];
  belegform: BelegFormArt;
  consolidationCriteria?: InputMaybe<Scalars['String']['input']>;
  deliveryAddressId: Scalars['String']['input'];
  dueDateVorschreibung: DueDateVorschreibungArt;
  empfaengerBankDetailsId?: InputMaybe<Scalars['String']['input']>;
  empfaengerRechtstraegerId: Scalars['String']['input'];
  fakturierungsperiode: FakturierungsperiodeInput;
  paymentMethod: PaymentMethod;
  recipientEmailContactId?: InputMaybe<Scalars['String']['input']>;
  verrechnungsart: Verrechnungsart;
  zustellRechtstraegerId: Scalars['String']['input'];
};

export type Auszifferung = {
  auszifferungId: Scalars['String']['output'];
  auszifferungsDatum: Scalars['String']['output'];
  betrag: Scalars['Float']['output'];
  bookingSuggestionId?: Maybe<Scalars['String']['output']>;
  buchungId: Scalars['String']['output'];
  buchungsdatum: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  offenePostenId: Scalars['String']['output'];
  source: AuszifferungSourceTuple;
  storniert: Scalars['Boolean']['output'];
  storniertByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  storniertTs?: Maybe<Scalars['String']['output']>;
  stornierteOffenePostenId?: Maybe<Scalars['String']['output']>;
  stornoOffenePostenId?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  zahlungId?: Maybe<Scalars['String']['output']>;
};

export type AuszifferungCreateInput = {
  betrag: Scalars['Float']['input'];
  offenePostenId: Scalars['String']['input'];
};

export type AuszifferungListCreateInput = {
  auszifferungList: Array<AuszifferungCreateInput>;
};

export type AuszifferungListResponse = {
  data: Array<Auszifferung>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AuszifferungListWithOffenePostenResponse = {
  data: Array<AuszifferungWithOffenePosten>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type AuszifferungResponse = {
  data: Auszifferung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum AuszifferungSource {
  StornierteRechnung = 'STORNIERTE_RECHNUNG',
  StornierteSpesen = 'STORNIERTE_SPESEN',
  Storno = 'STORNO',
  StornoSpesen = 'STORNO_SPESEN',
  Zahlung = 'ZAHLUNG',
}

export type AuszifferungSourceTuple = {
  text: Scalars['String']['output'];
  value: AuszifferungSource;
};

export type AuszifferungWithOffenePosten = {
  auszifferungId: Scalars['String']['output'];
  auszifferungsDatum: Scalars['String']['output'];
  betrag: Scalars['Float']['output'];
  bookingSuggestionId?: Maybe<Scalars['String']['output']>;
  buchungId: Scalars['String']['output'];
  buchungsdatum: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  offenePosten: OffenePosten;
  offenePostenId: Scalars['String']['output'];
  source: AuszifferungSourceTuple;
  storniert: Scalars['Boolean']['output'];
  storniertByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  storniertTs?: Maybe<Scalars['String']['output']>;
  stornierteOffenePostenId?: Maybe<Scalars['String']['output']>;
  stornoOffenePostenId?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  zahlungId?: Maybe<Scalars['String']['output']>;
};

export type AuszifferungWithOffenePostenResponse = {
  data: AuszifferungWithOffenePosten;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BankDetails = {
  accountHolder: Scalars['String']['output'];
  accountLimit?: Maybe<Scalars['Float']['output']>;
  assignedHeVertragZahlungsregelMap: Scalars['JSON']['output'];
  assignedObjektMap: Scalars['JSON']['output'];
  bankDetailsId: Scalars['ID']['output'];
  bankname: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  ebicsCreditTransferUserList: Array<Scalars['String']['output']>;
  ebicsDirectDebitUserList: Array<Scalars['String']['output']>;
  fibuKontoId?: Maybe<Scalars['String']['output']>;
  fibuKontonummer?: Maybe<Scalars['String']['output']>;
  fibuStatus: BankDetailsFibuStatusTuple;
  fibuVerwendung: BankDetailsFibuVerwendungTuple;
  iban: Scalars['String']['output'];
  sepaMandat?: Maybe<SepaMandat>;
  status: BankDetailsStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export enum BankDetailsAction {
  AddKonto = 'ADD_KONTO',
  Archivieren = 'ARCHIVIEREN',
  ChangeAccountLimit = 'CHANGE_ACCOUNT_LIMIT',
  Reaktivieren = 'REAKTIVIEREN',
  SetVerwendung = 'SET_VERWENDUNG',
}

export type BankDetailsChangeAccountLimitInput = {
  accountLimit: Scalars['Float']['input'];
};

export enum BankDetailsFibuStatus {
  Angelegt = 'ANGELEGT',
  NichtAngelegt = 'NICHT_ANGELEGT',
  WirdAngelegt = 'WIRD_ANGELEGT',
}

export type BankDetailsFibuStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: BankDetailsFibuStatus;
};

export enum BankDetailsFibuVerwendung {
  RepFonds = 'REP_FONDS',
  Standard = 'STANDARD',
}

export type BankDetailsFibuVerwendungTuple = {
  text: Scalars['String']['output'];
  value: BankDetailsFibuVerwendung;
};

export type BankDetailsListResponse = {
  data: Array<BankDetails>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BankDetailsResponse = {
  data: BankDetails;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BankDetailsStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type BankDetailsStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: BankDetailsStatus;
};

export type BankSettings = {
  bankSettingsId?: Maybe<Scalars['String']['output']>;
  sepaCreditorId?: Maybe<Scalars['String']['output']>;
};

export type BankSettingsResponse = {
  data: BankSettings;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BankStatement = {
  bankStatementId: Scalars['ID']['output'];
  closingBalance?: Maybe<Scalars['Float']['output']>;
  countTransactions?: Maybe<Scalars['Int']['output']>;
  countTransactionsCredited?: Maybe<Scalars['Int']['output']>;
  countTransactionsDebited?: Maybe<Scalars['Int']['output']>;
  createdOn: Scalars['String']['output'];
  deletable: Scalars['Boolean']['output'];
  iban: Scalars['String']['output'];
  openingBalance?: Maybe<Scalars['Float']['output']>;
  referencedDataCarrierNameList: Array<Scalars['String']['output']>;
  statementNumber: Scalars['String']['output'];
  sumTransactions?: Maybe<Scalars['Float']['output']>;
};

export type BeAufteilingsschluesselWert = {
  aktiv: Scalars['Boolean']['output'];
  beAufteilungsschluesselWertId?: Maybe<Scalars['ID']['output']>;
  createTs?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  geloescht: Scalars['Boolean']['output'];
  validFrom?: Maybe<Scalars['String']['output']>;
  verwaltungSeitRelation: BeAufteilingsschluesselWertVerwaltungSeitRelationTuple;
  warningList: Array<ResponseDataWarning>;
  wert: Scalars['Float']['output'];
};

export type BeAufteilingsschluesselWertListResponse = {
  data: Array<BeAufteilingsschluesselWert>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeAufteilingsschluesselWertMitBezugInput = {
  status: Scalars['String']['input'];
  validFrom: Scalars['String']['input'];
};

export type BeAufteilingsschluesselWertOhneBezugInput = {
  validFrom: Scalars['String']['input'];
  wert: Scalars['Float']['input'];
};

export type BeAufteilingsschluesselWertResponse = {
  data: BeAufteilingsschluesselWert;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BeAufteilingsschluesselWertVerwaltungSeitRelation {
  IstVerwaltungSeit = 'IST_VERWALTUNG_SEIT',
  NachVerwaltungSeit = 'NACH_VERWALTUNG_SEIT',
  VorVerwaltungSeit = 'VOR_VERWALTUNG_SEIT',
}

export type BeAufteilingsschluesselWertVerwaltungSeitRelationTuple = {
  text: Scalars['String']['output'];
  value: BeAufteilingsschluesselWertVerwaltungSeitRelation;
};

export type BeAufteilungsschluessel = {
  anteil: Scalars['Float']['output'];
  aufteilungsschluesselId: Scalars['String']['output'];
  basis: Scalars['Float']['output'];
  bezeichnung: Scalars['String']['output'];
  bezugsbasis: BezugsbasisTuple;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  objektAufteilungsschluesselStatus: ObjektAufteilungsschluesselStatusTuple;
  warningList: Array<ResponseDataWarning>;
  wert?: Maybe<Scalars['Float']['output']>;
};

export type BeAufteilungsschluesselListResponse = {
  data: Array<BeAufteilungsschluessel>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BeAufteilungsschluesselWertType {
  MitBezug = 'MIT_BEZUG',
  OhneBezug = 'OHNE_BEZUG',
}

export type BeInfoFeldKellerabteil = AbstractBeInfoFeld & {
  beInfoFeldId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  nummer: Scalars['String']['output'];
  postIt?: Maybe<PostIt>;
  status: InfoFeldStatusTuple;
  text?: Maybe<Scalars['String']['output']>;
  type: BeInfoFeldTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type BeInfoFeldListResponse = {
  data: Array<AbstractBeInfoFeld>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeInfoFeldParkplatz = AbstractBeInfoFeld & {
  beInfoFeldId: Scalars['ID']['output'];
  bestandseinheit?: Maybe<BestandseinheitBase>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  nummer: Scalars['String']['output'];
  postIt?: Maybe<PostIt>;
  status: InfoFeldStatusTuple;
  text?: Maybe<Scalars['String']['output']>;
  type: BeInfoFeldTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type BeInfoFeldResponse = {
  data: AbstractBeInfoFeld;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BeInfoFeldType {
  Kellerabteil = 'KELLERABTEIL',
  Parkplatz = 'PARKPLATZ',
  Zimmer = 'ZIMMER',
}

export type BeInfoFeldTypeListResponse = {
  data: Array<BeInfoFeldTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeInfoFeldTypeTuple = {
  text: Scalars['String']['output'];
  value: BeInfoFeldType;
};

export type BeInfoFeldZimmer = AbstractBeInfoFeld & {
  anmerkung?: Maybe<Scalars['String']['output']>;
  beInfoFeldId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
  bodenbelag?: Maybe<Scalars['String']['output']>;
  breite?: Maybe<Scalars['Float']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  energiekennzahl?: Maybe<Scalars['String']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  flaechentyp?: Maybe<Scalars['String']['output']>;
  heizung?: Maybe<Scalars['String']['output']>;
  hoehe?: Maybe<Scalars['Float']['output']>;
  initialQuadratmeterBetrag?: Maybe<Scalars['Float']['output']>;
  initialQuadratmeterPreis?: Maybe<Scalars['Float']['output']>;
  kubikmeter?: Maybe<Scalars['Float']['output']>;
  laenge?: Maybe<Scalars['Float']['output']>;
  nummer?: Maybe<Scalars['String']['output']>;
  nutzflaeche?: Maybe<Scalars['Float']['output']>;
  nutzung?: Maybe<Scalars['Boolean']['output']>;
  postIt?: Maybe<PostIt>;
  quadratmeter?: Maybe<Scalars['Float']['output']>;
  quadratmeterBetrag?: Maybe<Scalars['Float']['output']>;
  quadratmeterPreis?: Maybe<Scalars['Float']['output']>;
  status: InfoFeldStatusTuple;
  type: BeInfoFeldTypeTuple;
  vorschreibungsposition?: Maybe<ObjektVorschreibungsposition>;
  warningList: Array<ResponseDataWarning>;
};

export type BeNutzungsArt = {
  beNutzungsArtId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  historicizedList?: Maybe<Array<BeNutzungsArt>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  nutzungsArt: NutzungsArtTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  vertragId?: Maybe<Scalars['String']['output']>;
  vertragKurzBezeichnung?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type BeNutzungsArtCreateInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  nutzungsArt: NutzungsArt;
  validFrom: Scalars['String']['input'];
};

export type BeNutzungsArtListResponse = {
  data: Array<BeNutzungsArt>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeNutzungsArtResponse = {
  data: BeNutzungsArt;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeNutzungsArtUpdateInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  nutzungsArt: NutzungsArt;
  validFrom: Scalars['String']['input'];
};

export type BeOptionSteuerpflichtVersion = {
  beOptionSteuerpflichtId: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  historicizedList?: Maybe<Array<BeOptionSteuerpflichtVersion>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  optionSteuerpflicht: Scalars['Boolean']['output'];
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BeOptionSteuerpflichtVersionInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  optionSteuerpflicht: Scalars['Boolean']['input'];
  validFrom: Scalars['String']['input'];
};

export type BeOptionSteuerpflichtVersionListResponse = {
  data: Array<BeOptionSteuerpflichtVersion>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeOptionSteuerpflichtVersionResponse = {
  data: BeOptionSteuerpflichtVersion;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeVertrag = {
  applyIndexedValues: Scalars['Boolean']['output'];
  bankDetails?: Maybe<BankDetails>;
  belegform: BelegFormTuple;
  consolidationCriteria?: Maybe<Scalars['String']['output']>;
  consolidationCriteriaEditable: Scalars['Boolean']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  dueDateVorschreibung: DueDateVorschreibungTuple;
  paymentMethod: PaymentMethodTuple;
  postIt?: Maybe<PostIt>;
  status: VertragStatusTuple;
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  sumUst: Scalars['Float']['output'];
  tagList?: Maybe<Array<Scalars['String']['output']>>;
  updatedByMitabeiterId?: Maybe<Scalars['ID']['output']>;
  verrechnungsart: VerrechnungsartTuple;
  vertragId: Scalars['ID']['output'];
  vertragsBeginn: Scalars['String']['output'];
  vertragsEnde?: Maybe<Scalars['String']['output']>;
  vertragsart: VertragsartTuple;
  vertragsartAndPartnerUpdatable?: Maybe<Scalars['Boolean']['output']>;
  vertragspartner: Rechtstraeger;
  warningList: Array<ResponseDataWarning>;
};

export type BeVertragBase = {
  bestandseinheitId: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  status: VertragStatus;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragId: Scalars['String']['output'];
  vertragsBeginn: Scalars['String']['output'];
  vertragsart: VertragsartTuple;
  vertragspartner: RechtstraegerBase;
  warningList: Array<ResponseDataWarning>;
};

export type BeVertragBaseListResponse = {
  data: Array<BeVertragBase>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeVertragCreateInput = {
  bankDetailsId?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  verrechnungsart?: InputMaybe<Verrechnungsart>;
  vertragsBeginn: Scalars['String']['input'];
  vertragsEnde?: InputMaybe<Scalars['String']['input']>;
  vertragsart: Vertragsart;
  vertragspartnerId: Scalars['String']['input'];
};

export type BeVertragDeviationListResponse = {
  data: Array<Deviation>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeVertragDeviationSourceEntity = AbstractDeviationSourceEntity & {
  bestandseinheitBezeichnung: Scalars['String']['output'];
  entityId: Scalars['ID']['output'];
  ids?: Maybe<BeVertragDeviationSourceEntityIds>;
  objektBezeichnung: Scalars['String']['output'];
  rechnungsAusstellerKurzBezeichnung: Scalars['String']['output'];
  type: DeviationSourceEntityTypeTuple;
  vertragsBeginn: Scalars['String']['output'];
  vertragsEnde?: Maybe<Scalars['String']['output']>;
  vertragspartnerKurzBezeichnung: Scalars['String']['output'];
};

export type BeVertragDeviationSourceEntityIds = {
  bestandseinheitId: Scalars['String']['output'];
  linkText: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  vertragId: Scalars['String']['output'];
};

export type BeVertragDokumentenversandInput = {
  emailAktiv?: InputMaybe<Scalars['Boolean']['input']>;
  emailContactId?: InputMaybe<Scalars['String']['input']>;
  emailHaupt?: InputMaybe<Scalars['Boolean']['input']>;
  postAddressId?: InputMaybe<Scalars['String']['input']>;
  postAktiv?: InputMaybe<Scalars['Boolean']['input']>;
  postHaupt?: InputMaybe<Scalars['Boolean']['input']>;
  rechtstraegerId: Scalars['String']['input'];
};

export type BeVertragListEntry = {
  aktivIn?: Maybe<Scalars['Int']['output']>;
  aktivInUnit?: Maybe<EnumData>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  status: VertragStatusTuple;
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  sumUst: Scalars['Float']['output'];
  vertragId: Scalars['ID']['output'];
  vertragKurzBezeichnung: Scalars['String']['output'];
  vertragsBeginn: Scalars['String']['output'];
  vertragsart: VertragsartTuple;
  vertragspartner: Rechtstraeger;
  warningList: Array<ResponseDataWarning>;
};

export type BeVertragListEntryListResponse = {
  data: Array<BeVertragListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeVertragResponse = {
  data: BeVertrag;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeVertragUpdateInput = {
  applyIndexedValues: Scalars['Boolean']['input'];
  bankDetailsId?: InputMaybe<Scalars['String']['input']>;
  consolidationCriteria?: InputMaybe<Scalars['String']['input']>;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  vertragsBeginn: Scalars['String']['input'];
  vertragsEnde?: InputMaybe<Scalars['String']['input']>;
  vertragsart: Vertragsart;
  vertragspartnerId: Scalars['String']['input'];
};

export type BeVertragVersion = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  dueDateVorschreibung: DueDateVorschreibungTuple;
  historicizedList?: Maybe<Array<BeVertragVersion>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  paymentMethod: PaymentMethodTuple;
  validFrom: Scalars['String']['output'];
  verrechnungsart: VerrechnungsartTuple;
  vertragVersionId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BeVertragVersionCreateInput = {
  dueDateVorschreibung: Scalars['String']['input'];
  kommentar?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: PaymentMethod;
  validFrom: Scalars['String']['input'];
  verrechnungsart: Scalars['String']['input'];
};

export type BeVertragVersionListResponse = {
  data: Array<BeVertragVersion>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeVertragVersionResponse = {
  data: BeVertragVersion;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeVertragVersionUpdateInput = {
  dueDateVorschreibung: Scalars['String']['input'];
  kommentar?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: PaymentMethod;
  validFrom: Scalars['String']['input'];
  verrechnungsart: Scalars['String']['input'];
};

export type BeVertragVorschreibunsposition = {
  art: VorschreibungspositionArtTuple;
  basis?: Maybe<VorschreibungspositionBasisTuple>;
  bezeichnung: Scalars['String']['output'];
  brutto?: Maybe<Scalars['Float']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  netto?: Maybe<Scalars['Float']['output']>;
  objektVorschreibungspositionStatus: ObjektVorschreibungspositionStatusTuple;
  steuersatz: Scalars['Int']['output'];
  ust?: Maybe<Scalars['Float']['output']>;
  ustKategorie?: Maybe<UstKategorie>;
  ustKategorieEntry?: Maybe<UstKategorieEntry>;
  validFrom?: Maybe<Scalars['String']['output']>;
  vorschreibungspositionId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BeVertragVorschreibunspositionBase = {
  art: VorschreibungspositionArtTuple;
  basis?: Maybe<VorschreibungspositionBasisTuple>;
  bezeichnung: Scalars['String']['output'];
  brutto?: Maybe<Scalars['Float']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  netto?: Maybe<Scalars['Float']['output']>;
  steuersatz: Scalars['Int']['output'];
  ust?: Maybe<Scalars['Float']['output']>;
  validFrom?: Maybe<Scalars['String']['output']>;
  vorschreibungspositionId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BeVertragVorschreibunspositionListResponse = {
  data: Array<BeVertragVorschreibunsposition>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeVertragVorschreibunspositionWert = {
  basis: VorschreibungspositionBasisTuple;
  brutto: Scalars['Float']['output'];
  changeReason?: Maybe<AbstractVPosWertChangeReason>;
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  deletable: Scalars['Boolean']['output'];
  netto: Scalars['Float']['output'];
  steuersatz: Scalars['Int']['output'];
  updateTs?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  ust: Scalars['Float']['output'];
  ustKategorie?: Maybe<UstKategorie>;
  ustKategorieEntry?: Maybe<UstKategorieEntry>;
  ustKategorieVersionIndex?: Maybe<Scalars['Int']['output']>;
  validFrom: Scalars['String']['output'];
  vertragVorschreibungspositionWertId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BeVertragVorschreibunspositionWertListResponse = {
  data: Array<BeVertragVorschreibunspositionWert>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BeVertragVorschreibunspositionWertResponse = {
  data: BeVertragVorschreibunspositionWert;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BelegFormArt {
  BruttoNettoKombination = 'BRUTTO_NETTO_KOMBINATION',
}

export type BelegFormTuple = {
  text: Scalars['String']['output'];
  value: BelegFormArt;
};

export type BelegTexte = {
  belegFussBildAlignment?: Maybe<DocumentHeaderAndFooterImageAlignmentTuple>;
  belegFussBildFileInfo?: Maybe<OrderFileInfo>;
  belegFussText?: Maybe<Scalars['JSON']['output']>;
  belegFussTextAsHtml?: Maybe<Scalars['String']['output']>;
  belegKopfBildAlignment?: Maybe<DocumentHeaderAndFooterImageAlignmentTuple>;
  belegKopfBildFileInfo?: Maybe<OrderFileInfo>;
  belegKopfText?: Maybe<Scalars['JSON']['output']>;
  belegKopfTextAsHtml?: Maybe<Scalars['JSON']['output']>;
  createTs?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type BelegTexteResponse = {
  data: BelegTexte;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BelegTexteTemplate = {
  textbausteinList: Array<Textbaustein>;
};

export type BelegTexteTemplateReponse = {
  data: BelegTexteTemplate;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Belegnummernkreis = {
  belegnummernkreisId: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  jahr: Scalars['Float']['output'];
  nextBelegnummer: Scalars['String']['output'];
  status: BelegnummernkreisStatusTuple;
  verwendung: BelegnummernkreisVerwendungTuple;
  warningList: Array<ResponseDataWarning>;
};

export type BelegnummernkreisDefinition = {
  belegnummernkreisDefinitionId: Scalars['String']['output'];
  belegnummernkreisList: Array<Belegnummernkreis>;
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  jaehrlichNeu: Scalars['Boolean']['output'];
  nummernteil: Scalars['String']['output'];
  postfix?: Maybe<Textbaustein>;
  prefix?: Maybe<Textbaustein>;
  status: BelegnummernkreisDefinitionStatusTuple;
  warningList: Array<ResponseDataWarning>;
};

export type BelegnummernkreisDefinitionInput = {
  bezeichnung: Scalars['String']['input'];
  jaehrlichNeu?: InputMaybe<Scalars['Boolean']['input']>;
  nummernteil: Scalars['String']['input'];
  postfix?: InputMaybe<Scalars['String']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
};

export type BelegnummernkreisDefinitionListResponse = {
  data: Array<BelegnummernkreisDefinition>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BelegnummernkreisDefinitionResponse = {
  data: BelegnummernkreisDefinition;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BelegnummernkreisDefinitionStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type BelegnummernkreisDefinitionStatusTuple = {
  text: Scalars['String']['output'];
  value: BelegnummernkreisDefinitionStatus;
};

export type BelegnummernkreisDefinitionTemplate = {
  errorList: Array<ErrorData>;
  postfix: Textbaustein;
  prefix: Textbaustein;
  warningList: Array<WarningData>;
};

export type BelegnummernkreisDefinitionTemplateListResponse = {
  data: Array<BelegnummernkreisDefinitionTemplate>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BelegnummernkreisStatus {
  Aktiv = 'AKTIV',
  Beendet = 'BEENDET',
}

export type BelegnummernkreisStatusTuple = {
  text: Scalars['String']['output'];
  value: BelegnummernkreisStatus;
};

export enum BelegnummernkreisVerwendung {
  Eigenbeleg = 'EIGENBELEG',
  Intern = 'INTERN',
  Mahnung = 'MAHNUNG',
}

export type BelegnummernkreisVerwendungTuple = {
  text: Scalars['String']['output'];
  value: BelegnummernkreisVerwendung;
};

export type Bestandseinheit = {
  address: Address;
  aktivSeit?: Maybe<Scalars['String']['output']>;
  bauteil?: Maybe<Scalars['String']['output']>;
  bestandseinheitId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  geschoss?: Maybe<Scalars['String']['output']>;
  inaktivSeit?: Maybe<Scalars['String']['output']>;
  nutzflaeche?: Maybe<Scalars['Float']['output']>;
  nutzungsArt?: Maybe<NutzungsArtTuple>;
  nutzwert?: Maybe<Scalars['Int']['output']>;
  objektVerwaltungsart: VerwaltungsartTuple;
  optionSteuerpflicht?: Maybe<BeOptionSteuerpflichtVersion>;
  postIt?: Maybe<PostIt>;
  status: BestandseinheitStatusTuple;
  stiege?: Maybe<Scalars['String']['output']>;
  tagList: Array<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type BestandseinheitBase = {
  bestandseinheitId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  objektId: Scalars['String']['output'];
  status: BestandseinheitStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type BestandseinheitBaseListResponse = {
  data: Array<BestandseinheitBase>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BestandseinheitCreateInput = {
  addressId: Scalars['String']['input'];
  aktivSeit?: InputMaybe<Scalars['String']['input']>;
  bauteil?: InputMaybe<Scalars['String']['input']>;
  bestandseinheitInsertBeforeId?: InputMaybe<Scalars['String']['input']>;
  bezeichnung: Scalars['String']['input'];
  geschoss?: InputMaybe<Scalars['String']['input']>;
  inaktivSeit?: InputMaybe<Scalars['String']['input']>;
  nutzung: BestandseinheitNutzungInput;
  optionSteuerpflicht?: InputMaybe<BeOptionSteuerpflichtVersionInput>;
  stiege?: InputMaybe<Scalars['String']['input']>;
  tagList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BestandseinheitListEntry = {
  aktivSeit?: Maybe<Scalars['String']['output']>;
  bauteil?: Maybe<Scalars['String']['output']>;
  bestandseinheitId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  geschoss?: Maybe<Scalars['String']['output']>;
  inaktivSeit?: Maybe<Scalars['String']['output']>;
  nutzungsArt?: Maybe<NutzungsArtTuple>;
  optionSteuerpflicht?: Maybe<BeOptionSteuerpflichtVersion>;
  position: Scalars['Int']['output'];
  status: BestandseinheitStatusTuple;
  stiege?: Maybe<Scalars['String']['output']>;
  vertragspartnerId?: Maybe<Scalars['String']['output']>;
  vertragspartnerKurzBezeichnung?: Maybe<Scalars['String']['output']>;
  vertragspartnerStatus?: Maybe<RechtstraegerStatusTuple>;
  vertragspartnerType?: Maybe<RechtstraegerTypeTuple>;
  warningList: Array<ResponseDataWarning>;
};

export type BestandseinheitListEntryPageResponse = {
  data: Array<BestandseinheitListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BestandseinheitNutzflaecheVersion = {
  beNutzflaecheId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  historicizedList?: Maybe<Array<BestandseinheitNutzflaecheVersion>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  nutzflaeche: Scalars['Float']['output'];
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BestandseinheitNutzflaecheVersionInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  nutzflaeche: Scalars['Float']['input'];
  validFrom: Scalars['String']['input'];
};

export type BestandseinheitNutzflaecheVersionListResponse = {
  data: Array<BestandseinheitNutzflaecheVersion>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BestandseinheitNutzflaecheVersionResponse = {
  data: BestandseinheitNutzflaecheVersion;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BestandseinheitNutzung = {
  nutzflaeche?: Maybe<Scalars['Float']['output']>;
  nutzflaecheAnteil: Scalars['Float']['output'];
  nutzwert?: Maybe<Scalars['Int']['output']>;
  nutzwertAnteil: Scalars['Float']['output'];
};

export type BestandseinheitNutzungInput = {
  nutzflaeche?: InputMaybe<Scalars['Float']['input']>;
  nutzungsArt: NutzungsArt;
  nutzwert?: InputMaybe<Scalars['Int']['input']>;
  validFrom: Scalars['String']['input'];
};

export type BestandseinheitNutzungResponse = {
  data: BestandseinheitNutzung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BestandseinheitNutzungsArtListResponse = {
  data: Array<NutzungsArtTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BestandseinheitNutzwertByDateResponse = {
  data: Scalars['Int']['output'];
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BestandseinheitNutzwertVersion = {
  beNutzwertId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  historicizedList?: Maybe<Array<BestandseinheitNutzwertVersion>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  nutzwert: Scalars['Int']['output'];
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BestandseinheitNutzwertVersionInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  nutzwert: Scalars['Int']['input'];
  validFrom: Scalars['String']['input'];
};

export type BestandseinheitNutzwertVersionListResponse = {
  data: Array<BestandseinheitNutzwertVersion>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BestandseinheitNutzwertVersionResponse = {
  data: BestandseinheitNutzwertVersion;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BestandseinheitResponse = {
  data: Bestandseinheit;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BestandseinheitStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type BestandseinheitStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: BestandseinheitStatus;
};

export type BestandseinheitUpdateInput = {
  addressId: Scalars['String']['input'];
  aktivSeit?: InputMaybe<Scalars['String']['input']>;
  bauteil?: InputMaybe<Scalars['String']['input']>;
  bezeichnung: Scalars['String']['input'];
  geschoss?: InputMaybe<Scalars['String']['input']>;
  inaktivSeit?: InputMaybe<Scalars['String']['input']>;
  stiege?: InputMaybe<Scalars['String']['input']>;
  tagList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum BetragBasis {
  Brutto = 'BRUTTO',
  Netto = 'NETTO',
}

export enum Bezugsbasis {
  Nutzflaeche = 'NUTZFLAECHE',
  Nutzwert = 'NUTZWERT',
  OhneBezug = 'OHNE_BEZUG',
}

export type BezugsbasisListResponse = {
  data: Array<BezugsbasisTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BezugsbasisTuple = {
  text: Scalars['String']['output'];
  value: Bezugsbasis;
};

export type BkAbrechnungsdefinition = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreis>;
  abrechnungsdefinitionId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kontoAbrechnungskreisDataList: Array<KontoAbrechnungskreis>;
  objektAbrechnungTextbausteinList: Array<Textbaustein>;
  status: AbrechnungsdefinitionStatusTuple;
  topAbrechnungTextbausteinList: Array<Textbaustein>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BkAbrechnungsdefinitionCreateInput = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreisInput>;
  bezeichnung: Scalars['String']['input'];
  objektAbrechnungOutputOptions: ObjektAbrechnungOutputOptionsInput;
  objektAbrechnungTextbausteinList: Array<TextbausteinInput>;
  topAbrechnungOutputOptions: TopAbrechnungOutputOptionsInput;
  topAbrechnungTextbausteinList: Array<TextbausteinInput>;
  validFrom: Scalars['String']['input'];
};

export type BkAbrechnungsdefinitionResponse = {
  data: BkAbrechnungsdefinition;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BkAbrechnungsdefinitionUpdateInput = {
  bezeichnung: Scalars['String']['input'];
};

export type BkAbrechnungsdefinitionVersion = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreis>;
  abrechnungsdefinitionVersionId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kommentar?: Maybe<Scalars['String']['output']>;
  kontoAbrechnungskreisList: Array<KontoAbrechnungskreis>;
  lastUpdateTs: Scalars['String']['output'];
  objektAbrechnungOutputOptions: ObjektAbrechnungOutputOptions;
  objektAbrechnungTextbausteinList: Array<Textbaustein>;
  topAbrechnungOutputOptions: TopAbrechnungOutputOptions;
  topAbrechnungTextbausteinList: Array<Textbaustein>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BkAbrechnungsdefinitionVersionCreateInput = {
  abrechdefAbrechkreisList: Array<InputMaybe<AbrechdefAbrechkreisInput>>;
  kommentar?: InputMaybe<Scalars['String']['input']>;
  objektAbrechnungOutputOptions: ObjektAbrechnungOutputOptionsInput;
  objektAbrechnungTextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  topAbrechnungOutputOptions: TopAbrechnungOutputOptionsInput;
  topAbrechnungTextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  validFrom: Scalars['String']['input'];
};

export type BkAbrechnungsdefinitionVersionListEntry = {
  abrechnungsdefinitionVersionId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  historicizedList?: Maybe<Array<BkAbrechnungsdefinitionVersionListEntry>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  textBausteineBefuellt: Scalars['Boolean']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BkAbrechnungsdefinitionVersionListEntryResponse = {
  data: Array<BkAbrechnungsdefinitionVersionListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BkAbrechnungsdefinitionVersionResponse = {
  data: BkAbrechnungsdefinitionVersion;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BkAbrechnungsdefinitionVersionUpdateInput = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreisInput>;
  kommentar?: InputMaybe<Scalars['String']['input']>;
  kontoAbrechnungskreisList: Array<AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput>;
  objektAbrechnungOutputOptions: ObjektAbrechnungOutputOptionsInput;
  objektAbrechnungTextbausteinList: Array<TextbausteinInput>;
  topAbrechnungOutputOptions: TopAbrechnungOutputOptionsInput;
  topAbrechnungTextbausteinList: Array<TextbausteinInput>;
  validFrom: Scalars['String']['input'];
};

export type BookingSuggestion = {
  bookingSuggestionId: Scalars['String']['output'];
  buchungsanweisung: PaymentBuchungsanweisung;
  clearingSuggestionList: Array<ClearingSuggestion>;
  fibuBuchungIdList: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type BookingSuggestionBuchungszeile = {
  betrag: Scalars['Float']['output'];
  buchungsKZ?: Maybe<Scalars['String']['output']>;
  buchungszeileId: Scalars['String']['output'];
  dataCarrierName: Scalars['String']['output'];
  deletable: Scalars['Boolean']['output'];
  editable: Scalars['Boolean']['output'];
  gegenKonto?: Maybe<PaymentKonto>;
  heVertragId?: Maybe<Scalars['String']['output']>;
  konto: PaymentKonto;
  objekt?: Maybe<PaymentObjekt>;
  sollHaben: SollHabenTuple;
  steuer?: Maybe<Scalars['Float']['output']>;
  steuercode?: Maybe<Scalars['String']['output']>;
  steuersatz?: Maybe<Scalars['Int']['output']>;
  text: Scalars['String']['output'];
  ustVomAufwandSteuersatz?: Maybe<Scalars['Int']['output']>;
};

export type BookingSuggestionCreationGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type BookingSuggestionCreationGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  receivedBankTransactionRuecklastschrift?: Maybe<ReceivedBankTransaction>;
  receivedBankTransactionSpesen?: Maybe<ReceivedBankTransaction>;
  receivedBankTransactionZahlung?: Maybe<ReceivedBankTransaction>;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type BookingSuggestionCreationGenerierlaufEntryListPage = {
  contentList: Array<BookingSuggestionCreationGenerierlaufEntry>;
  page: Page;
};

export type BookingSuggestionCreationGenerierlaufEntryListPageResponse = {
  data: BookingSuggestionCreationGenerierlaufEntryListPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BookingSuggestionCreationGenerierlaufOrderBy {
  Amount = 'AMOUNT',
  Creditor = 'CREDITOR',
  DataCarrierName = 'DATA_CARRIER_NAME',
  Debtor = 'DEBTOR',
  StatementNumber = 'STATEMENT_NUMBER',
  Status = 'STATUS',
  ValueDate = 'VALUE_DATE',
}

export type BookingSuggestionCreationGenerierlaufResponse = {
  data: BookingSuggestionCreationGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BookingSuggestionFibuInfo = {
  bookingSuggestionId: Scalars['String']['output'];
  currentFibuBuchungId?: Maybe<Scalars['String']['output']>;
  fibuBuchungIdList: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type BookingSuggestionListResponse = {
  data: Array<BookingSuggestion>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BookingSuggestionResponse = {
  data: BookingSuggestion;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BookingSuggestionUpdateInput = {
  buchungsanweisung: PaymentBuchungsanweisungInput;
  buchungsdatum?: InputMaybe<Scalars['String']['input']>;
  clearingSuggestionList?: InputMaybe<Array<ClearingSuggestionInput>>;
};

export type BookingSuggestionVerbuchenGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type BookingSuggestionVerbuchenGenerierlaufCreateInput = {
  buchungsdatum: Scalars['String']['input'];
  paymentProposalId?: InputMaybe<Scalars['String']['input']>;
};

export type BookingSuggestionVerbuchenGenerierlaufEntry = {
  buchungskreisRechtstraeger: PaymentRechtstraeger;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId: Scalars['String']['output'];
  currentStep?: Maybe<Scalars['Int']['output']>;
  detailsList: Array<BookingSuggestionVerbuchenGenerierlaufEntryDetails>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  objekt: PaymentObjekt;
  payee: PaymentRechtstraeger;
  paymentProposalId: Scalars['String']['output'];
  paymentProposalName: Scalars['String']['output'];
  paymentTransactionType: PaymentTransactionTypeTuple;
  paymentType: PaymentTypeTuple;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
  zahlungsdatum: Scalars['String']['output'];
};

export type BookingSuggestionVerbuchenGenerierlaufEntryDetails = {
  amount: Scalars['Float']['output'];
  belegSymbol: PaymentBelegSymbolTuple;
  buchungType: PaymentBuchungTypeTuple;
  chargesInSeparateEntry?: Maybe<Scalars['Boolean']['output']>;
  dataCarrierCamtId: Scalars['String']['output'];
  dataCarrierCamtName: Scalars['String']['output'];
  fibuBuchungId?: Maybe<Scalars['String']['output']>;
  reversal: Scalars['Boolean']['output'];
  statementNumber: Scalars['String']['output'];
  transactionType: TransactionTypeTuple;
  valueDate?: Maybe<Scalars['String']['output']>;
};

export type BookingSuggestionVerbuchenGenerierlaufEntryListPage = {
  contentList: Array<BookingSuggestionVerbuchenGenerierlaufEntry>;
  page: Page;
};

export type BookingSuggestionVerbuchenGenerierlaufEntryListPageResponse = {
  data: BookingSuggestionVerbuchenGenerierlaufEntryListPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BookingSuggestionVerbuchenGenerierlaufOrderBy {
  Amount = 'AMOUNT',
  BelegSymbol = 'BELEG_SYMBOL',
  BuchungskreisRechtstraeger = 'BUCHUNGSKREIS_RECHTSTRAEGER',
  BuchungsType = 'BUCHUNGS_TYPE',
  ChargesInSeparateEntry = 'CHARGES_IN_SEPARATE_ENTRY',
  CreateTs = 'CREATE_TS',
  DataCarrierName = 'DATA_CARRIER_NAME',
  Objekt = 'OBJEKT',
  Payee = 'PAYEE',
  PaymentProposalName = 'PAYMENT_PROPOSAL_NAME',
  PaymentTransactionType = 'PAYMENT_TRANSACTION_TYPE',
  PaymentType = 'PAYMENT_TYPE',
  Reversal = 'REVERSAL',
  StatementNumber = 'STATEMENT_NUMBER',
  Status = 'STATUS',
  TransactionType = 'TRANSACTION_TYPE',
  ValueDate = 'VALUE_DATE',
  Zahlungsdatum = 'ZAHLUNGSDATUM',
}

export type BookingSuggestionVerbuchenGenerierlaufResponse = {
  data: BookingSuggestionVerbuchenGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Buchung = {
  bezeichnung: Scalars['String']['output'];
  bookingSuggestionId?: Maybe<Scalars['String']['output']>;
  buchungId: Scalars['String']['output'];
  buchungsanweisungId: Scalars['String']['output'];
  zahlungId?: Maybe<Scalars['String']['output']>;
};

export type BuchungListResponse = {
  data: Array<Buchung>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BuchungPreview = {
  buchung: OrderBuchungsanweisung;
};

export type BuchungPreviewResponse = {
  data: BuchungPreview;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BuchungResponse = {
  data: Buchung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BuchungSource {
  Manual = 'MANUAL',
  System = 'SYSTEM',
}

export type BuchungSourceTuple = {
  text: Scalars['String']['output'];
  value: BuchungSource;
};

export enum BuchungStatus {
  Erstellt = 'ERSTELLT',
  Verbucht = 'VERBUCHT',
}

export type BuchungStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: BuchungStatus;
};

export type BuchungsKopf = {
  auftragId?: Maybe<Scalars['String']['output']>;
  belegDatum?: Maybe<Scalars['String']['output']>;
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegId?: Maybe<Scalars['String']['output']>;
  belegSymbol: FibuBelegSymbolTuple;
  belegnummer?: Maybe<Scalars['String']['output']>;
  buchungsType: FibuBuchungTypeTuple;
  buchungsdatum: Scalars['String']['output'];
  buchungskreisRechtstraegerId: Scalars['String']['output'];
  dataCarrierBelegId?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  standingInvoicePartialAmountId?: Maybe<Scalars['String']['output']>;
  statementNumber?: Maybe<Scalars['String']['output']>;
  vertragspartnerId?: Maybe<Scalars['String']['output']>;
  waehrung: WaehrungTuple;
};

export type BuchungsanweisungDeviationListResponse = {
  data: Array<Deviation>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BuchungsanweisungDeviationSourceEntity = AbstractDeviationSourceEntity & {
  auftragsartType?: Maybe<AuftragsartTypeTuple>;
  belegnummer: Scalars['String']['output'];
  entityId: Scalars['ID']['output'];
  gegenKonto?: Maybe<KontoKey>;
  gesamtBetrag: Scalars['Float']['output'];
  type: DeviationSourceEntityTypeTuple;
};

export enum BuchungsdetailsType {
  BuchungSumPerAccount = 'BUCHUNG_SUM_PER_ACCOUNT',
  DisplayBuchungszeilen = 'DISPLAY_BUCHUNGSZEILEN',
}

export type BuchungsdetailsTypeTuple = {
  text: Scalars['String']['output'];
  value: BuchungsdetailsType;
};

export type Buchungskreis = {
  buchungskreisId: Scalars['String']['output'];
  rechtstraeger: FibuRechtstraeger;
};

export type BuchungskreisListResponse = {
  data: Array<Buchungskreis>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BuchungszeileBestandseinheit = {
  bestandseinheitId: Scalars['String']['output'];
  bezeichnung?: Maybe<Scalars['String']['output']>;
};

export type BuchungszeileInput = {
  amount: Scalars['Float']['input'];
  buchungszeileId?: InputMaybe<Scalars['ID']['input']>;
  kontoId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export enum BuchungszeileOrderBy {
  Belegnummer = 'BELEGNUMMER',
  BelegDatum = 'BELEG_DATUM',
  BelegSymbol = 'BELEG_SYMBOL',
  Bestandseinheit = 'BESTANDSEINHEIT',
  Betrag = 'BETRAG',
  BuchungskreisRechtstraeger = 'BUCHUNGSKREIS_RECHTSTRAEGER',
  BuchungsDatum = 'BUCHUNGS_DATUM',
  BuchungsKz = 'BUCHUNGS_KZ',
  BuchungsTyp = 'BUCHUNGS_TYP',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  DueDate = 'DUE_DATE',
  GegenKontoNummer = 'GEGEN_KONTO_NUMMER',
  KontoNummer = 'KONTO_NUMMER',
  Objekt = 'OBJEKT',
  OffenerOpBetrag = 'OFFENER_OP_BETRAG',
  SollHaben = 'SOLL_HABEN',
  Source = 'SOURCE',
  Steuer = 'STEUER',
  Steuersatz = 'STEUERSATZ',
  Storniert = 'STORNIERT',
  StornierteBuchung = 'STORNIERTE_BUCHUNG',
  Text = 'TEXT',
  Umbuchung = 'UMBUCHUNG',
  UstVomAufwandSteuersatz = 'UST_VOM_AUFWAND_STEUERSATZ',
  Vertragspartner = 'VERTRAGSPARTNER',
  Waehrung = 'WAEHRUNG',
}

export type BuchungszeileWithDeviationInfo = {
  bestandseinheitId?: Maybe<Scalars['String']['output']>;
  betrag: Scalars['Float']['output'];
  buchungsKZ?: Maybe<Scalars['String']['output']>;
  buchungszeileId: Scalars['String']['output'];
  gegenKontoKey?: Maybe<KontoKey>;
  kontoId?: Maybe<Scalars['String']['output']>;
  kontoKey: KontoKey;
  objektId?: Maybe<Scalars['String']['output']>;
  sollHaben: SollHabenTuple;
  steuer?: Maybe<Scalars['Float']['output']>;
  steuercode?: Maybe<Scalars['String']['output']>;
  steuersatz?: Maybe<Scalars['Int']['output']>;
  text: Scalars['String']['output'];
  vertragId?: Maybe<Scalars['String']['output']>;
};

export type BuchungszeilenResponse = {
  data?: Maybe<Array<BuchungszeileWithDeviationInfo>>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Budgeting = {
  bezeichnung: Scalars['String']['output'];
  budgetSumNextYear?: Maybe<Scalars['Float']['output']>;
  budgetingId: Scalars['ID']['output'];
  budgetingResultId?: Maybe<Scalars['ID']['output']>;
  budgetingVPosList: Array<BudgetingVPos>;
  considerationPeriodUntil: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  currentYear: Scalars['Int']['output'];
  eigentuemerAbrechnungsdefinition?: Maybe<AbrechnungsdefinitionBase>;
  increase?: Maybe<Scalars['Float']['output']>;
  mieterAbrechnungsdefinition?: Maybe<AbrechnungsdefinitionBase>;
  objekt: ObjektBase;
  prevYear: Scalars['Int']['output'];
  rechnungsAussteller?: Maybe<RechtstraegerBase>;
  restOfCurrentYearSum?: Maybe<Scalars['Float']['output']>;
  resultSourceType?: Maybe<BudgetingResultSourceTypeTuple>;
  status: BudgetingStatusTuple;
  totalCurrentYearSum?: Maybe<Scalars['Float']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragVPosWertDate?: Maybe<Scalars['String']['output']>;
  vorschreibungCurrentYearSum: Scalars['Float']['output'];
  vorschreibungPrevYearSum: Scalars['Float']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BudgetingAbrDefVPosLinkage = {
  abrKreisVPosList: Array<BudgetingAbrKreisVPos>;
  abrechnungsdefinition: AbrechnungsdefinitionBase;
  budgetingAbrDefVPosLinkageId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kundenSystemId?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type BudgetingAbrDefVPosLinkageCreateInput = {
  abrKreisVPosList: Array<BudgetingAbrKreisVPosInput>;
  abrechnungsdefinitionId: Scalars['String']['input'];
};

export type BudgetingAbrDefVPosLinkageListResponse = {
  data: Array<BudgetingAbrDefVPosLinkage>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BudgetingAbrDefVPosLinkageResponse = {
  data: BudgetingAbrDefVPosLinkage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BudgetingAbrDefVPosLinkageUpdateInput = {
  abrKreisVPosList: Array<BudgetingAbrKreisVPosInput>;
  abrechnungsdefinitionId: Scalars['String']['input'];
};

export type BudgetingAbrKreis = {
  abrechnungskreis: Abrechnungskreis;
  budgetingKontoList: Array<BudgetingKonto>;
  estimateNextYear: Scalars['Float']['output'];
  estimateRestOfCurrentYear: Scalars['Float']['output'];
  estimateTotalCurrentYear: Scalars['Float']['output'];
  gebuchtCurrentYear: Scalars['Float']['output'];
  gebuchtPrevYear: Scalars['Float']['output'];
  increase: Scalars['Float']['output'];
};

export type BudgetingAbrKreisVPos = {
  abrechnungskreis: Abrechnungskreis;
  vorschreibungsposition: Vorschreibungsposition;
};

export type BudgetingAbrKreisVPosInput = {
  abrechnungskreisId: Scalars['String']['input'];
  vorschreibungspositionId: Scalars['String']['input'];
};

export type BudgetingActionAdjustAufteilungsschluesselInput = {
  budgetingVPosId: Scalars['ID']['input'];
  leaveFlatRate: Scalars['Boolean']['input'];
  vertragLevelAufteilungsschluesselList: Array<BudgetingVertragLevelAufteilungsschluesselInput>;
};

export type BudgetingActionConfigureFinancialPlanTextsInput = {
  budgetingVPosId: Scalars['ID']['input'];
  einleitungsText?: InputMaybe<Scalars['String']['input']>;
  expenseList: Array<BudgetingConfigureFinancialPlanTextsExpenseInput>;
  schlussText?: InputMaybe<Scalars['String']['input']>;
};

export type BudgetingActionCreateVPosValuesFromAufwandInput = {
  validFrom: Scalars['String']['input'];
};

export type BudgetingActionCreateVPosValuesFromVPosInput = {
  validFrom: Scalars['String']['input'];
};

export type BudgetingActionResetKontoInput = {
  budgetingKontoId: Scalars['ID']['input'];
};

export type BudgetingActionTransferToVPosActionInput = {
  budgetingVPosId: Scalars['ID']['input'];
};

export type BudgetingActionUpdateKontoInput = {
  budgetingKontoId: Scalars['ID']['input'];
  fieldName: BudgetingUpdateKontoAction;
  value: Scalars['Float']['input'];
};

export type BudgetingActionUpdateVPosInput = {
  budgetingVPosId: Scalars['ID']['input'];
  fieldName: BudgetingUpdateVPosAction;
  value: Scalars['Float']['input'];
};

export type BudgetingAufteilungsschluessel = {
  aufteilungsschluessel: Aufteilungsschluessel;
  vertragLevel: VertragLevelTuple;
};

export type BudgetingBase = {
  bezeichnung: Scalars['String']['output'];
  budgetSumNextYear?: Maybe<Scalars['Float']['output']>;
  budgetingId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  currentYear: Scalars['Int']['output'];
  increase?: Maybe<Scalars['Float']['output']>;
  objekt: ObjektBase;
  prevYear: Scalars['Int']['output'];
  restOfCurrentYearSum?: Maybe<Scalars['Float']['output']>;
  resultSourceType?: Maybe<BudgetingResultSourceTypeTuple>;
  status: BudgetingStatusTuple;
  totalCurrentYearSum?: Maybe<Scalars['Float']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragVPosWertDate?: Maybe<Scalars['String']['output']>;
  vorschreibungCurrentYearSum: Scalars['Float']['output'];
  vorschreibungPrevYearSum: Scalars['Float']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type BudgetingBaseResponse = {
  data: BudgetingBase;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BudgetingBuchungszeile = {
  ausgabenCurrentYear?: Maybe<Scalars['Float']['output']>;
  ausgabenPrevYear?: Maybe<Scalars['Float']['output']>;
  belegDatum: Scalars['String']['output'];
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegnummer?: Maybe<Scalars['String']['output']>;
  buchungId?: Maybe<Scalars['String']['output']>;
  buchungsdatum?: Maybe<Scalars['String']['output']>;
  steuer?: Maybe<Scalars['Float']['output']>;
  text: Scalars['String']['output'];
  vertragspartner?: Maybe<RechtstraegerBase>;
};

export type BudgetingConfigureFinancialPlanTextsExpenseInput = {
  amount: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type BudgetingCreateInput = {
  bezeichnung: Scalars['String']['input'];
  considerationPeriodUntil: Scalars['String']['input'];
};

export type BudgetingGenerierlauf = {
  bezeichnung: Scalars['String']['output'];
  considerationPeriodUntil: Scalars['String']['output'];
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  entryList: Array<BudgetingGenerierlaufEntry>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  status: GenerierlaufStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<WarningData>;
};

export type BudgetingGenerierlaufEntry = {
  budgeting?: Maybe<BudgetingBase>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['String']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  warningList: Array<WarningData>;
};

export type BudgetingGenerierlaufResponse = {
  data: BudgetingGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BudgetingKonto = {
  budgetingAufteilungsschluesselList: Array<BudgetingAufteilungsschluessel>;
  budgetingBuchungszeileList: Array<BudgetingBuchungszeile>;
  budgetingKontoId: Scalars['String']['output'];
  estimateNextYear: Scalars['Float']['output'];
  estimateRestOfCurrentYear: Scalars['Float']['output'];
  estimateTotalCurrentYear: Scalars['Float']['output'];
  gebuchtCurrentYear: Scalars['Float']['output'];
  gebuchtPrevYear: Scalars['Float']['output'];
  increase: Scalars['Float']['output'];
  konto: AppKonto;
};

export type BudgetingKontoBase = {
  budgetingKontoId: Scalars['String']['output'];
  estimateNextYear: Scalars['Float']['output'];
  estimateRestOfCurrentYear: Scalars['Float']['output'];
  estimateTotalCurrentYear: Scalars['Float']['output'];
  gebuchtCurrentYear: Scalars['Float']['output'];
  gebuchtPrevYear: Scalars['Float']['output'];
  increase: Scalars['Float']['output'];
  konto: AppKonto;
};

export type BudgetingListResponse = {
  data: Array<BudgetingBase>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BudgetingResponse = {
  data: Budgeting;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BudgetingResult = {
  budgetingResultBestandseinheitList: Array<BudgetingResultBestandseinheit>;
  budgetingResultId: Scalars['ID']['output'];
  warningList: Array<BudgetingResultWarningTuple>;
};

export type BudgetingResultAufteilungsschluessel = {
  aufteilungsschluesselAnteil: Scalars['Float']['output'];
  aufteilungsschluesselBasis: Scalars['Float']['output'];
  budgetingResultBudgetingKontoList: Array<BudgetingResultBudgetingKonto>;
  budgetingResultBudgetingVPos?: Maybe<BudgetingResultBudgetingVPos>;
  budgetingWertAnteil: Scalars['Float']['output'];
  estimateNextYear: Scalars['Float']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  name: Scalars['String']['output'];
  unitValue: Scalars['Float']['output'];
};

export type BudgetingResultBestandseinheit = {
  bestandseinheit: BestandseinheitBase;
  budgetingResultVertragList: Array<BudgetingResultVertrag>;
  nutzflaeche: Scalars['Float']['output'];
  nutzflaecheAnteil: Scalars['Float']['output'];
  nutzungsArt: NutzungsArtTuple;
  nutzwert: Scalars['Int']['output'];
  nutzwertAnteil: Scalars['Float']['output'];
};

export type BudgetingResultBudgetingKonto = {
  budgetingKonto: BudgetingKontoBase;
  estimateNextYear: Scalars['Float']['output'];
  increase: Scalars['Float']['output'];
  vorschreibungCurrentYear: Scalars['Float']['output'];
};

export type BudgetingResultBudgetingVPos = {
  budgetingVPos: BudgetingVPosBase;
  estimateNextYear: Scalars['Float']['output'];
  increase: Scalars['Float']['output'];
  vorschreibungCurrentYear: Scalars['Float']['output'];
};

export type BudgetingResultByVertragLevel = {
  sumNetto: Scalars['Float']['output'];
  vertragLevel: VertragLevelTuple;
  vorschreibungspositionList: Array<BudgetingResultByVorschreibungsposition>;
};

export type BudgetingResultByVertragLevelResponse = {
  data: Array<BudgetingResultByVertragLevel>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BudgetingResultByVorschreibungsposition = {
  sumNetto: Scalars['Float']['output'];
  vorschreibungsposition: Scalars['String']['output'];
};

export type BudgetingResultResponse = {
  data: BudgetingResult;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum BudgetingResultSourceType {
  Expenses = 'EXPENSES',
  VPos = 'V_POS',
}

export type BudgetingResultSourceTypeTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: BudgetingResultSourceType;
};

export type BudgetingResultVertrag = {
  annualNetto: Scalars['Float']['output'];
  budgetingResultVertragVPosWertList: Array<BudgetingResultVertragVPosWert>;
  monthlyNetto: Scalars['Float']['output'];
  vertrag: VertragBase;
};

export type BudgetingResultVertragVPosWert = {
  annualNetto: Scalars['Float']['output'];
  budgetingResultAufteilungsschluesselList: Array<BudgetingResultAufteilungsschluessel>;
  monthlyNetto: Scalars['Float']['output'];
  validFrom: Scalars['String']['output'];
  vertragVorschreibungsposition: BeVertragVorschreibunspositionBase;
  warning?: Maybe<BudgetingResultVertragVPosWertWarning>;
};

export enum BudgetingResultVertragVPosWertWarning {
  VposValueUnchanged = 'VPOS_VALUE_UNCHANGED',
  VposValueWithDateExists = 'VPOS_VALUE_WITH_DATE_EXISTS',
}

export enum BudgetingResultWarning {
  KontoWithoutAufteilungsschluessel = 'KONTO_WITHOUT_AUFTEILUNGSSCHLUESSEL',
  VPosWithoutAufteilungsschluessel = 'V_POS_WITHOUT_AUFTEILUNGSSCHLUESSEL',
}

export type BudgetingResultWarningTuple = {
  text: Scalars['String']['output'];
  type: BudgetingResultWarning;
};

export enum BudgetingStatus {
  Archiviert = 'ARCHIVIERT',
  Erstellt = 'ERSTELLT',
  Verarbeitet = 'VERARBEITET',
}

export type BudgetingStatusTuple = {
  text: Scalars['String']['output'];
  value: BudgetingStatus;
};

export enum BudgetingUpdateKontoAction {
  EstimateNextYear = 'ESTIMATE_NEXT_YEAR',
  EstimateRestOfCurrentYear = 'ESTIMATE_REST_OF_CURRENT_YEAR',
  Increase = 'INCREASE',
}

export enum BudgetingUpdateVPosAction {
  EstimateNextYear = 'ESTIMATE_NEXT_YEAR',
  Increase = 'INCREASE',
}

export type BudgetingVPos = {
  art: VorschreibungspositionArtTuple;
  bezeichnung: Scalars['String']['output'];
  budgetingAbrKreisList: Array<BudgetingAbrKreis>;
  budgetingAufteilungsschluesselList: Array<BudgetingAufteilungsschluessel>;
  budgetingVPosId: Scalars['String']['output'];
  estimateNextYear: Scalars['Float']['output'];
  increase: Scalars['Float']['output'];
  leaveFlatRate: Scalars['Boolean']['output'];
  status: ObjektVorschreibungspositionStatusTuple;
  vorschreibungCurrentYear: Scalars['Float']['output'];
  vorschreibungPrevYear: Scalars['Float']['output'];
  vorschreibungRestOfCurrentYear: Scalars['Float']['output'];
  vorschreibungTotalCurrentYear: Scalars['Float']['output'];
  vorschreibungspositionId?: Maybe<Scalars['String']['output']>;
};

export type BudgetingVPosAuftschluesselLinkage = {
  aufteilungsschluessel: Aufteilungsschluessel;
  budgetingVPosAuftschluesselLinkageId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kundenSystemId?: Maybe<Scalars['String']['output']>;
  objektVerwaltungsart: VerwaltungsartTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragLevel: VertragLevelTuple;
  vorschreibungsposition: Vorschreibungsposition;
  warningList: Array<ResponseDataWarning>;
};

export type BudgetingVPosAuftschluesselLinkageCreateInput = {
  aufteilungsschluesselId: Scalars['String']['input'];
  objektVerwaltungsart: Verwaltungsart;
  vertragLevel: VertragLevel;
  vorschreibungspositionId: Scalars['String']['input'];
};

export type BudgetingVPosAuftschluesselLinkageListResponse = {
  data: Array<BudgetingVPosAuftschluesselLinkage>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BudgetingVPosAuftschluesselLinkageResponse = {
  data: BudgetingVPosAuftschluesselLinkage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BudgetingVPosAuftschluesselLinkageUpdateInput = {
  aufteilungsschluesselId: Scalars['String']['input'];
  objektVerwaltungsart: Verwaltungsart;
  vertragLevel: VertragLevel;
  vorschreibungspositionId: Scalars['String']['input'];
};

export type BudgetingVPosBase = {
  art: VorschreibungspositionArtTuple;
  bezeichnung: Scalars['String']['output'];
  budgetingVPosId: Scalars['String']['output'];
  estimateNextYear: Scalars['Float']['output'];
  increase: Scalars['Float']['output'];
  leaveFlatRate: Scalars['Boolean']['output'];
  status: ObjektVorschreibungspositionStatusTuple;
  vorschreibungCurrentYear: Scalars['Float']['output'];
  vorschreibungPrevYear: Scalars['Float']['output'];
  vorschreibungRestOfCurrentYear: Scalars['Float']['output'];
  vorschreibungTotalCurrentYear: Scalars['Float']['output'];
  vorschreibungspositionId?: Maybe<Scalars['String']['output']>;
};

export type BudgetingVertragLevelAufteilungsschluesselInput = {
  aufteilungsschluesselId: Scalars['String']['input'];
  vertragLevel: VertragLevel;
};

export type CallLog = {
  callLogId: Scalars['String']['output'];
  callTs: Scalars['String']['output'];
  callee: MitarbeiterBase;
  kommentar?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  rechtstraeger: RechtstraegerBase;
  type: CallLogTypeTuple;
};

export type CallLogCreateInput = {
  phoneNumber: Scalars['String']['input'];
};

export type CallLogListPage = {
  contentList: Array<CallLog>;
  page: Page;
};

export type CallLogListPageResponse = {
  data: CallLogListPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type CallLogListResponse = {
  data: Array<CallLog>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type CallLogResponse = {
  data: CallLog;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum CallLogType {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING',
}

export type CallLogTypeTuple = {
  text: Scalars['String']['output'];
  value: CallLogType;
};

export type CallLogUpdateInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
};

export type CamtGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type CamtGenerierlaufCreateInput = {
  ebicsUserId: Scalars['String']['input'];
};

export type CamtGenerierlaufEntry = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  currentStep?: Maybe<Scalars['Int']['output']>;
  dataCarrierList: Array<DataCarrierCamt>;
  ebicsUser?: Maybe<EbicsUser>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  fromInclusive?: Maybe<Scalars['String']['output']>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  toInclusive?: Maybe<Scalars['String']['output']>;
  warningList: Array<WarningData>;
};

export type CamtGenerierlaufEntryListPage = {
  contentList: Array<CamtGenerierlaufEntry>;
  page: Page;
};

export type CamtGenerierlaufEntryListPageResponse = {
  data: CamtGenerierlaufEntryListPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum CamtGenerierlaufOrderBy {
  EbicsUserName = 'EBICS_USER_NAME',
  EbicsUserUserId = 'EBICS_USER_USER_ID',
  MitarbeiterName = 'MITARBEITER_NAME',
  Status = 'STATUS',
}

export type CamtGenerierlaufResponse = {
  data: CamtGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ChangeLog = {
  changeType?: Maybe<Scalars['String']['output']>;
  changedBy?: Maybe<Scalars['String']['output']>;
  changedTs?: Maybe<Scalars['String']['output']>;
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ChangeLogsResponse = {
  data: ContentChangeLogs;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ChangePasswordViaTokenInput = {
  password: Scalars['String']['input'];
};

export type ChangePasswordViaTokenInputData = {
  data: ChangePasswordViaTokenInput;
};

export type ChangePaymentDateBulkActionInput = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  approvedByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  belegdatum?: InputMaybe<Scalars['String']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumBis?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumVon?: InputMaybe<Scalars['String']['input']>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  employeeApproval?: InputMaybe<EmployeeApproval>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeList?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceDateFrom?: InputMaybe<Scalars['String']['input']>;
  invoiceDateTo?: InputMaybe<Scalars['String']['input']>;
  noted?: InputMaybe<Scalars['Boolean']['input']>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  payeeIban?: InputMaybe<Scalars['String']['input']>;
  payeeId?: InputMaybe<Scalars['String']['input']>;
  paymentApprovalStatusList?: InputMaybe<Array<PaymentApprovalStatus>>;
  paymentDate: Scalars['String']['input'];
  paymentDateFrom?: InputMaybe<Scalars['String']['input']>;
  paymentDateTo?: InputMaybe<Scalars['String']['input']>;
  paymentProposalId?: InputMaybe<Scalars['String']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
  paymentTypeList?: InputMaybe<Array<PaymentType>>;
  rechnungsAusstellerFibuKontoId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerIban?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  sepaCreditorId?: InputMaybe<Scalars['String']['input']>;
  sepaMandateReference?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<OrderPaymentStatus>>;
};

export enum ChangeReason {
  BudgetingCreate = 'BUDGETING_CREATE',
  IndexedValueApply = 'INDEXED_VALUE_APPLY',
  NutzungsArtChange = 'NUTZUNGS_ART_CHANGE',
  OptionSteuerpflichtChange = 'OPTION_STEUERPFLICHT_CHANGE',
  SteuerpflichtChange = 'STEUERPFLICHT_CHANGE',
  SteuerpflichtSubverwaltungChange = 'STEUERPFLICHT_SUBVERWALTUNG_CHANGE',
  UstKategorieChange = 'UST_KATEGORIE_CHANGE',
  UstKategorieVersionChange = 'UST_KATEGORIE_VERSION_CHANGE',
  UstKzChange = 'UST_KZ_CHANGE',
}

export type ChangeReasonTuple = {
  text: Scalars['String']['output'];
  value: ChangeReason;
};

export enum ChronoUnit {
  Days = 'DAYS',
  Months = 'MONTHS',
}

export type ChronoUnitTuple = {
  text: Scalars['String']['output'];
  value: ChronoUnit;
};

export type ClearingSuggestion = {
  amount: Scalars['Float']['output'];
  clearingSuggestionId: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['String']['output'];
  offenePosten: OffenePosten;
  offenePostenId: Scalars['String']['output'];
};

export type ClearingSuggestionInput = {
  amount: Scalars['Float']['input'];
  clearingSuggestionId?: InputMaybe<Scalars['String']['input']>;
  offenePostenId: Scalars['String']['input'];
};

export type ColumnSettings = {
  columns: Scalars['String']['output'];
  filterIdentifier: Scalars['String']['output'];
  mitarbeiterId?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type ColumnSettingsCreateOrUpdateInput = {
  columns: Scalars['String']['input'];
  filterIdentifier: Scalars['String']['input'];
};

export type ColumnSettingsListResponse = {
  data: Array<ColumnSettings>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ColumnSettingsResponse = {
  data: ColumnSettings;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum ConsolidateEinnahmenType {
  AnnualTotal = 'ANNUAL_TOTAL',
  MonthlyTotal = 'MONTHLY_TOTAL',
}

export type ConsolidateEinnahmenTypeTuple = {
  text: Scalars['String']['output'];
  value: ConsolidateEinnahmenType;
};

export type Contact = {
  contactId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  haupt?: Maybe<Scalars['Boolean']['output']>;
  tagList: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
  wert: Scalars['String']['output'];
};

export type ContactCreateInput = {
  operatingSiteId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  wert: Scalars['String']['input'];
};

export type ContactListCreateInput = {
  operatingSiteId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  wertList: Array<Scalars['String']['input']>;
};

export type ContactListResponse = {
  data: Array<Contact>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ContactPerson = {
  contactList: Array<Contact>;
  contactPersonId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firstname: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  rechtstraeger?: Maybe<RechtstraegerBase>;
  salutation?: Maybe<SalutationTuple>;
  tagList: Array<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleTrailing?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type ContactPersonCreateInput = {
  contactList?: InputMaybe<Array<ContactCreateInput>>;
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  operatingSiteId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleTrailing?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPersonResponse = {
  data: ContactPerson;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ContactPersonUpdateInput = {
  contactList?: InputMaybe<Array<ContactUpdateInput>>;
  contactPersonId?: InputMaybe<Scalars['ID']['input']>;
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleTrailing?: InputMaybe<Scalars['String']['input']>;
};

export type ContactResponse = {
  data: Contact;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ContactUpdateInput = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  wert: Scalars['String']['input'];
};

export type ContentChangeLogs = {
  contentList: Array<ChangeLog>;
  page: Page;
};

export type CountryCode = {
  alpha2: Scalars['String']['output'];
  alpha3: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
};

export type CountryCodeListResponse = {
  data: Array<CountryCode>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type CountryCodeResponse = {
  data: CountryCode;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type CreateKsFirmendatenInput = {
  companyRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  contactList?: InputMaybe<Array<ContactCreateInput>>;
  contactPersonList?: InputMaybe<Array<ContactPersonCreateInput>>;
  hauptAddress: AddressCreateInput;
  kurzBezeichnung: Scalars['String']['input'];
  legalForm: LegalForm;
  name1: Scalars['String']['input'];
  name2?: InputMaybe<Scalars['String']['input']>;
  name3?: InputMaybe<Scalars['String']['input']>;
  sepaCreditorId?: InputMaybe<Scalars['String']['input']>;
  vatIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateKundenSystemInput = {
  erstelltVonFirma: CreateKsFirmendatenInput;
  name: Scalars['String']['input'];
};

export enum Currency {
  Eur = 'EUR',
}

export type CurrencyTuple = {
  text: Scalars['String']['output'];
  value: Currency;
};

export type DataCarrier = {
  dataCarrierId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DataCarrierCamt = {
  camtGenerierlaufEntryId?: Maybe<Scalars['ID']['output']>;
  dataCarrierDetailsList: Array<DataCarrierDetails>;
  dataCarrierId: Scalars['ID']['output'];
  dataCarrierType: DataCarrierTypeTuple;
  errorList: Array<ErrorData>;
  ibanImportedList: Array<Scalars['String']['output']>;
  ibanNotImportedList: Array<Scalars['String']['output']>;
  invoiceIssuerName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  paymentProposalName?: Maybe<Scalars['String']['output']>;
  paymentTransactionType: PaymentTransactionTypeTuple;
  status: DataCarrierCamtStatusTuple;
  warningList: Array<WarningData>;
  xmlFilename: Scalars['String']['output'];
};

export type DataCarrierCamtListEntry = {
  bankStatementList: Array<BankStatement>;
  createTs: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dataCarrierId: Scalars['ID']['output'];
  dataCarrierType: DataCarrierTypeTuple;
  deletable: Scalars['Boolean']['output'];
  ibanImportedList: Array<Scalars['String']['output']>;
  ibanNotImportedList: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  paymentTransactionType: PaymentTransactionTypeTuple;
  warningList: Array<WarningData>;
  xmlFilename: Scalars['String']['output'];
  zipFileId?: Maybe<Scalars['String']['output']>;
  zipFilename?: Maybe<Scalars['String']['output']>;
};

export type DataCarrierCamtListEntryPage = {
  contentList: Array<DataCarrierCamtListEntry>;
  page: Page;
};

export type DataCarrierCamtListEntryPageResponse = {
  data: DataCarrierCamtListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum DataCarrierCamtOrderBy {
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  Name = 'NAME',
  PaymentTransactionType = 'PAYMENT_TRANSACTION_TYPE',
  ZipFileName = 'ZIP_FILE_NAME',
}

export type DataCarrierCamtResponse = {
  data: DataCarrierCamt;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum DataCarrierCamtStatus {
  Created = 'CREATED',
  Error = 'ERROR',
  Processed = 'PROCESSED',
}

export type DataCarrierCamtStatusTuple = {
  text: Scalars['String']['output'];
  value: DataCarrierCamtStatus;
};

export type DataCarrierDetails = {
  bankStatementId: Scalars['String']['output'];
  closingBalance?: Maybe<Scalars['Float']['output']>;
  countBankStatements: Scalars['Int']['output'];
  countTransactionsCredited?: Maybe<Scalars['Int']['output']>;
  countTransactionsDebited?: Maybe<Scalars['Int']['output']>;
  createdByGenerierlaufId?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  openingBalance?: Maybe<Scalars['Float']['output']>;
  statementNumber: Scalars['String']['output'];
  sumTransactions?: Maybe<Scalars['Float']['output']>;
};

export type DataCarrierListResponse = {
  data: Array<DataCarrier>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type DataCarrierPain = {
  countTransactionInstructions: Scalars['Int']['output'];
  dataCarrierId: Scalars['ID']['output'];
  dataCarrierType: DataCarrierTypeTuple;
  ibanList: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  paymentProposalName: Scalars['String']['output'];
  paymentTransactionType: PaymentTransactionTypeTuple;
  status: DataCarrierPainStatusTuple;
  sumTransactionInstruction: Scalars['Float']['output'];
  xmlFilename: Scalars['String']['output'];
};

export type DataCarrierPainCreationGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type DataCarrierPainCreationGenerierlaufEntry = {
  createTs: Scalars['String']['output'];
  createdByMitarbeiterId: Scalars['String']['output'];
  currentStep?: Maybe<Scalars['String']['output']>;
  dataCarrier: DataCarrierPain;
  ebicsUser?: Maybe<EbicsUser>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['String']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type DataCarrierPainCreationGenerierlaufListEntryPage = {
  contentList: Array<DataCarrierPainCreationGenerierlaufEntry>;
  page: Page;
};

export type DataCarrierPainCreationGenerierlaufListEntryPageResponse = {
  data: DataCarrierPainCreationGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum DataCarrierPainCreationGenerierlaufOrderBy {
  CountTransactionInstructions = 'COUNT_TRANSACTION_INSTRUCTIONS',
  DataCarrierType = 'DATA_CARRIER_TYPE',
  EbicsUserName = 'EBICS_USER_NAME',
  EbicsUserUserId = 'EBICS_USER_USER_ID',
  Name = 'NAME',
  PaymentProposalName = 'PAYMENT_PROPOSAL_NAME',
  PaymentTransactionType = 'PAYMENT_TRANSACTION_TYPE',
  Status = 'STATUS',
  SumTransactionInstruction = 'SUM_TRANSACTION_INSTRUCTION',
}

export type DataCarrierPainCreationGenerierlaufResponse = {
  data: DataCarrierPainCreationGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type DataCarrierPainListEntry = {
  countTransactionInstructions: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dataCarrierId: Scalars['ID']['output'];
  dataCarrierType: DataCarrierTypeTuple;
  ibanList: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  paymentProposalName: Scalars['String']['output'];
  paymentTransactionType: PaymentTransactionTypeTuple;
  status: DataCarrierPainStatusTuple;
  sumTransactionInstruction: Scalars['Float']['output'];
  xmlFilename: Scalars['String']['output'];
};

export type DataCarrierPainListEntryPage = {
  contentList: Array<DataCarrierPainListEntry>;
  page: Page;
};

export type DataCarrierPainListEntryPageResponse = {
  data: DataCarrierPainListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum DataCarrierPainOrderBy {
  CountTransactionInstructions = 'COUNT_TRANSACTION_INSTRUCTIONS',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  DataCarrierType = 'DATA_CARRIER_TYPE',
  Name = 'NAME',
  PaymentProposalName = 'PAYMENT_PROPOSAL_NAME',
  PaymentTransactionType = 'PAYMENT_TRANSACTION_TYPE',
  Status = 'STATUS',
  SumTransactionInstruction = 'SUM_TRANSACTION_INSTRUCTION',
}

export type DataCarrierPainResponse = {
  data: DataCarrierPain;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum DataCarrierPainStatus {
  Created = 'CREATED',
  Error = 'ERROR',
  Processed = 'PROCESSED',
  Uploaded = 'UPLOADED',
  UploadManually = 'UPLOAD_MANUALLY',
}

export type DataCarrierPainStatusListResponse = {
  data: Array<DataCarrierPainStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type DataCarrierPainStatusTuple = {
  text: Scalars['String']['output'];
  value: DataCarrierPainStatus;
};

export enum DataCarrierType {
  BankStatement = 'BANK_STATEMENT',
  CreditTransfer = 'CREDIT_TRANSFER',
  DirectDebit = 'DIRECT_DEBIT',
}

export type DataCarrierTypeListResponse = {
  data: Array<DataCarrierTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type DataCarrierTypeTuple = {
  text: Scalars['String']['output'];
  value: DataCarrierType;
};

export enum DataChangedChangeType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

export type DataChangedEvent = {
  changeType: DataChangedChangeType;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  paramList: Array<DataChangedEventParam>;
  type: Scalars['String']['output'];
};

export type DataChangedEventParam = {
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DateUnit = {
  text: Scalars['String']['output'];
  value: PeriodUnit;
};

export type DateUnitListResponse = {
  data: Array<DateUnit>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Deviation = {
  changeType: DeviationChangeTypeTuple;
  entryList: Array<DeviationEntry>;
  group?: Maybe<DeviationGroup>;
  linkList?: Maybe<Array<DeviationAttributeUri>>;
  order?: Maybe<Scalars['Int']['output']>;
  sourceEntity: AbstractDeviationSourceEntity;
  type: DeviationType;
  ursache: DeviationUrsacheTypeTuple;
};

export type DeviationAttributeAmount = AbstractDeviationAttribute & {
  attributeId: Scalars['Int']['output'];
  hasChanged: Scalars['Boolean']['output'];
  name: DeviationAttributeNameTypeTuple;
  type: DeviationAttributeType;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DeviationAttributeDate = AbstractDeviationAttribute & {
  attributeId: Scalars['Int']['output'];
  hasChanged: Scalars['Boolean']['output'];
  name: DeviationAttributeNameTypeTuple;
  type: DeviationAttributeType;
  value?: Maybe<Scalars['String']['output']>;
};

export enum DeviationAttributeNameType {
  Anteil = 'ANTEIL',
  Anteile = 'ANTEILE',
  AnteilePercent = 'ANTEILE_PERCENT',
  AnteilNettoAufwand = 'ANTEIL_NETTO_AUFWAND',
  Aufwand = 'AUFWAND',
  AufwandsKonto = 'AUFWANDS_KONTO',
  Aussteller = 'AUSSTELLER',
  AusstellerBankDetails = 'AUSSTELLER_BANK_DETAILS',
  Belastung = 'BELASTUNG',
  Belegnummer = 'BELEGNUMMER',
  BelegDatum = 'BELEG_DATUM',
  Betrag = 'BETRAG',
  Bezeichnung = 'BEZEICHNUNG',
  Brutto = 'BRUTTO',
  ConsolidationCriteria = 'CONSOLIDATION_CRITERIA',
  DeliveryAddress = 'DELIVERY_ADDRESS',
  DueDate = 'DUE_DATE',
  Einnahmen = 'EINNAHMEN',
  Empfaenger = 'EMPFAENGER',
  EmpfaengerBankDetails = 'EMPFAENGER_BANK_DETAILS',
  GegenKontoNummer = 'GEGEN_KONTO_NUMMER',
  Gesamtanteile = 'GESAMTANTEILE',
  KontoInfo = 'KONTO_INFO',
  KontoNummer = 'KONTO_NUMMER',
  Netto = 'NETTO',
  Objekt = 'OBJEKT',
  Objektsumme = 'OBJEKTSUMME',
  PaymentMethod = 'PAYMENT_METHOD',
  Position = 'POSITION',
  Prozent = 'PROZENT',
  RechnungsAussteller = 'RECHNUNGS_AUSSTELLER',
  RecipientEmailContact = 'RECIPIENT_EMAIL_CONTACT',
  ReferenceOfCause = 'REFERENCE_OF_CAUSE',
  ReferenceOfCauseAufwand = 'REFERENCE_OF_CAUSE_AUFWAND',
  ReferenceOfCauseBestandseinheit = 'REFERENCE_OF_CAUSE_BESTANDSEINHEIT',
  ReferenceOfCauseEinnahmen = 'REFERENCE_OF_CAUSE_EINNAHMEN',
  ReferenceOfCauseHeAbrechnung = 'REFERENCE_OF_CAUSE_HE_ABRECHNUNG',
  ReferenceOfCauseRepFondsEinstellung = 'REFERENCE_OF_CAUSE_REP_FONDS_EINSTELLUNG',
  ReferenceOfCauseUstKategorie = 'REFERENCE_OF_CAUSE_UST_KATEGORIE',
  ReferenceOfCauseUstVomAufwand = 'REFERENCE_OF_CAUSE_UST_VOM_AUFWAND',
  ReferenceOfCauseVorsteuerkuerzung = 'REFERENCE_OF_CAUSE_VORSTEUERKUERZUNG',
  ReferenceOfCauseVstKrzEinstellung = 'REFERENCE_OF_CAUSE_VST_KRZ_EINSTELLUNG',
  RepFondsKonto = 'REP_FONDS_KONTO',
  SepaCreditorId = 'SEPA_CREDITOR_ID',
  SepaMandatReference = 'SEPA_MANDAT_REFERENCE',
  SepaMandatSignatureDate = 'SEPA_MANDAT_SIGNATURE_DATE',
  SollHaben = 'SOLL_HABEN',
  Steuercode = 'STEUERCODE',
  Symbol = 'SYMBOL',
  Top = 'TOP',
  Ust = 'UST',
  UstSatz = 'UST_SATZ',
  UstVomAufwand = 'UST_VOM_AUFWAND',
  ValidFrom = 'VALID_FROM',
  Verrechnungsart = 'VERRECHNUNGSART',
  Vertragsart = 'VERTRAGSART',
  Vertragspartner = 'VERTRAGSPARTNER',
  Vorsteuerkuerzung = 'VORSTEUERKUERZUNG',
  VstKrzKonto = 'VST_KRZ_KONTO',
  Waehrung = 'WAEHRUNG',
  ZustellRechtstraeger = 'ZUSTELL_RECHTSTRAEGER',
}

export type DeviationAttributeNameTypeTuple = {
  text: Scalars['String']['output'];
  value: DeviationAttributeNameType;
};

export type DeviationAttributeNumber = AbstractDeviationAttribute & {
  attributeId: Scalars['Int']['output'];
  hasChanged: Scalars['Boolean']['output'];
  name: DeviationAttributeNameTypeTuple;
  type: DeviationAttributeType;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DeviationAttributePercent = AbstractDeviationAttribute & {
  attributeId: Scalars['Int']['output'];
  hasChanged: Scalars['Boolean']['output'];
  name: DeviationAttributeNameTypeTuple;
  type: DeviationAttributeType;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DeviationAttributeString = AbstractDeviationAttribute & {
  attributeId: Scalars['Int']['output'];
  hasChanged: Scalars['Boolean']['output'];
  name: DeviationAttributeNameTypeTuple;
  type: DeviationAttributeType;
  value?: Maybe<Scalars['String']['output']>;
};

export enum DeviationAttributeType {
  Amount = 'AMOUNT',
  Date = 'DATE',
  Number = 'NUMBER',
  Percent = 'PERCENT',
  String = 'STRING',
  Uri = 'URI',
}

export type DeviationAttributeUri = {
  ids?: Maybe<AbstractDeviationUriId>;
  text: Scalars['String']['output'];
};

export type DeviationAttributeUriList = AbstractDeviationAttribute & {
  attributeId: Scalars['Int']['output'];
  hasChanged: Scalars['Boolean']['output'];
  name: DeviationAttributeNameTypeTuple;
  type: DeviationAttributeType;
  uriList: Array<DeviationAttributeUri>;
};

export enum DeviationChangeType {
  Aenderung = 'AENDERUNG',
  Entfernt = 'ENTFERNT',
  Neu = 'NEU',
}

export type DeviationChangeTypeTuple = {
  text: Scalars['String']['output'];
  value: DeviationChangeType;
};

export type DeviationDetails = {
  umbuchungInfoList: Array<UmbuchungInfo>;
};

export type DeviationEntry = {
  attributeList: Array<AbstractDeviationAttribute>;
  type: DeviationEntryTypeTuple;
};

export enum DeviationEntryType {
  Alt = 'ALT',
  Neu = 'NEU',
}

export type DeviationEntryTypeTuple = {
  text: Scalars['String']['output'];
  value: DeviationEntryType;
};

export type DeviationGroup = {
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export enum DeviationSourceEntityType {
  BeVertrag = 'BE_VERTRAG',
  Buchungsanweisung = 'BUCHUNGSANWEISUNG',
  HeAbrechnung = 'HE_ABRECHNUNG',
  ObjektAbrechnung = 'OBJEKT_ABRECHNUNG',
  TopAbrechnung = 'TOP_ABRECHNUNG',
  UstVomAufwand = 'UST_VOM_AUFWAND',
  Vorschreibung = 'VORSCHREIBUNG',
  Vorsteuerkuerzung = 'VORSTEUERKUERZUNG',
}

export type DeviationSourceEntityTypeTuple = {
  text: Scalars['String']['output'];
  value: DeviationSourceEntityType;
};

export enum DeviationType {
  Abrechnungskreis = 'ABRECHNUNGSKREIS',
  AbrechnungskreisRepFonds = 'ABRECHNUNGSKREIS_REP_FONDS',
  Auftragsposition = 'AUFTRAGSPOSITION',
  Buchungszeile = 'BUCHUNGSZEILE',
  InfoAbrechnungskreis = 'INFO_ABRECHNUNGSKREIS',
  Saldovortrag = 'SALDOVORTRAG',
  UstVomAufwandAufwandsKonto = 'UST_VOM_AUFWAND_AUFWANDS_KONTO',
  Vertragsbasis = 'VERTRAGSBASIS',
  Vorschreibungsposition = 'VORSCHREIBUNGSPOSITION',
  VstKrzAufwandsKonto = 'VST_KRZ_AUFWANDS_KONTO',
}

export type DeviationUriIdAuftrag = AbstractDeviationUriId & {
  auftragId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdBeNutzungsArt = AbstractDeviationUriId & {
  bestandseinheitId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdBeOptionSteuerpflicht = AbstractDeviationUriId & {
  bestandseinheitId?: Maybe<Scalars['String']['output']>;
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdBeVertrag = AbstractDeviationUriId & {
  bestandseinheitId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
  vertragId: Scalars['String']['output'];
};

export type DeviationUriIdBeVertragVorschreibungsposition = AbstractDeviationUriId & {
  bestandseinheitId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
  vertragId: Scalars['String']['output'];
  vorschreibungspositionId: Scalars['String']['output'];
};

export type DeviationUriIdBestandseinheit = AbstractDeviationUriId & {
  bestandseinheitId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdBkAbrechnungsdefinitionVersion = AbstractDeviationUriId & {
  bkAbrechnungsdefinitionId: Scalars['String']['output'];
  bkAbrechnungsdefinitionVersionId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdEingangsrechnung = AbstractDeviationUriId & {
  belegFileId: Scalars['String']['output'];
  belegId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdHeAbrechnungHeVertragVersion = AbstractDeviationUriId & {
  heAbrechnungHeVertragId: Scalars['String']['output'];
  heAbrechnungId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdHeAbrechnungsdefinitionVersion = AbstractDeviationUriId & {
  heAbrechnungsdefinitionId: Scalars['String']['output'];
  heAbrechnungsdefinitionVersionId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdHeVertrag = AbstractDeviationUriId & {
  heVertragId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdKontierungstabelle = AbstractDeviationUriId & {
  kontierungstabelleId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdLeerstehungAufwandskonto = AbstractDeviationUriId & {
  firmendatenId: Scalars['String']['output'];
  firmendatenLeerstehungAufwandskontoId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdPauschalmieteAufwandskonto = AbstractDeviationUriId & {
  firmendatenId: Scalars['String']['output'];
  firmendatenPauschalmieteAufwandskontoId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdRechtstraegerSteuerpflicht = AbstractDeviationUriId & {
  rechtstraegerId: Scalars['String']['output'];
  rechtstraegerType: RechtstraegerTypeTuple;
  type: DeviationUriIdType;
};

export type DeviationUriIdRepFondsEinstellung = AbstractDeviationUriId & {
  repFondsEinstellungId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export type DeviationUriIdTopAbrechnung = AbstractDeviationUriId & {
  objektAbrechnungId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  topAbrechnungId: Scalars['String']['output'];
  type: DeviationUriIdType;
};

export enum DeviationUriIdType {
  Auftrag = 'AUFTRAG',
  Bestandseinheit = 'BESTANDSEINHEIT',
  BeNutzungsArt = 'BE_NUTZUNGS_ART',
  BeOptionSteuerpflicht = 'BE_OPTION_STEUERPFLICHT',
  BeVertrag = 'BE_VERTRAG',
  BeVertragVorschreibungsposition = 'BE_VERTRAG_VORSCHREIBUNGSPOSITION',
  BkAbrechnungsdefinitionVersion = 'BK_ABRECHNUNGSDEFINITION_VERSION',
  Eingangsrechnung = 'EINGANGSRECHNUNG',
  HeAbrechnungsdefinitionVersion = 'HE_ABRECHNUNGSDEFINITION_VERSION',
  HeAbrechnungHeVertrag = 'HE_ABRECHNUNG_HE_VERTRAG',
  HeVertrag = 'HE_VERTRAG',
  Kontierungstabelle = 'KONTIERUNGSTABELLE',
  LeerstehungAufwandskonto = 'LEERSTEHUNG_AUFWANDSKONTO',
  PauschalmieteAufwandskonto = 'PAUSCHALMIETE_AUFWANDSKONTO',
  RechtstraegerSteuerpflicht = 'RECHTSTRAEGER_STEUERPFLICHT',
  RepFondsEinstellung = 'REP_FONDS_EINSTELLUNG',
  TopAbrechnung = 'TOP_ABRECHNUNG',
  UstKategorie = 'UST_KATEGORIE',
  UstKategorieEntry = 'UST_KATEGORIE_ENTRY',
  UstRegelset = 'UST_REGELSET',
  UstVomAufwand = 'UST_VOM_AUFWAND',
  Vertragsart = 'VERTRAGSART',
  Vertragspartner = 'VERTRAGSPARTNER',
  Vorschreibungsposition = 'VORSCHREIBUNGSPOSITION',
  VorschreibungspositionVersion = 'VORSCHREIBUNGSPOSITION_VERSION',
  Vorsteuerkuerzung = 'VORSTEUERKUERZUNG',
  VorsteuerkuerzungEinstellung = 'VORSTEUERKUERZUNG_EINSTELLUNG',
  WeAbrechnungsdefinitionVersion = 'WE_ABRECHNUNGSDEFINITION_VERSION',
  Zinsliste = 'ZINSLISTE',
}

export type DeviationUriIdUstKategorie = AbstractDeviationUriId & {
  status: UstKategorieStatusTuple;
  type: DeviationUriIdType;
  ustKategorieEntryId?: Maybe<Scalars['String']['output']>;
  ustKategorieId: Scalars['String']['output'];
  ustKategorieVersionId?: Maybe<Scalars['String']['output']>;
};

export type DeviationUriIdUstKategorieEntry = AbstractDeviationUriId & {
  type: DeviationUriIdType;
  ustKategorieEntryId: Scalars['String']['output'];
  ustKategorieId: Scalars['String']['output'];
  ustKategorieVersionId: Scalars['String']['output'];
};

export type DeviationUriIdUstRegelset = AbstractDeviationUriId & {
  type: DeviationUriIdType;
  ustRegelsetId: Scalars['String']['output'];
};

export type DeviationUriIdUstVomAufwand = AbstractDeviationUriId & {
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
  ustVomAufwandId: Scalars['String']['output'];
};

export type DeviationUriIdVertragsart = AbstractDeviationUriId & {
  bestandseinheitId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
  vertragId: Scalars['String']['output'];
};

export type DeviationUriIdVertragspartner = AbstractDeviationUriId & {
  bestandseinheitId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
  vertragId: Scalars['String']['output'];
};

export type DeviationUriIdVorschreibungsposition = AbstractDeviationUriId & {
  status: VorschreibungspositionStatusTuple;
  type: DeviationUriIdType;
  vorschreibungspositionId: Scalars['String']['output'];
};

export type DeviationUriIdVorschreibungspositionVersion = AbstractDeviationUriId & {
  type: DeviationUriIdType;
  vorschreibungspositionId: Scalars['String']['output'];
  vorschreibungspositionVersionId: Scalars['String']['output'];
};

export type DeviationUriIdVorsteuerkuerzung = AbstractDeviationUriId & {
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
  vorsteuerkuerzungId: Scalars['String']['output'];
};

export type DeviationUriIdVorsteuerkuerzungEinstellung = AbstractDeviationUriId & {
  type: DeviationUriIdType;
  vorsteuerkuerzungEinstellungId: Scalars['String']['output'];
};

export type DeviationUriIdWeAbrechnungsdefinitionVersion = AbstractDeviationUriId & {
  type: DeviationUriIdType;
  weAbrechnungsdefinitionId: Scalars['String']['output'];
  weAbrechnungsdefinitionVersionId: Scalars['String']['output'];
};

export type DeviationUriIdZinsliste = AbstractDeviationUriId & {
  objektId: Scalars['String']['output'];
  type: DeviationUriIdType;
  zinslisteId: Scalars['String']['output'];
};

export enum DeviationUrsacheType {
  AbrechnungsDefinitionAenderung = 'ABRECHNUNGS_DEFINITION_AENDERUNG',
  AuftragspositionAenderung = 'AUFTRAGSPOSITION_AENDERUNG',
  AuftragspositionErstellt = 'AUFTRAGSPOSITION_ERSTELLT',
  AuftragspositionGeloescht = 'AUFTRAGSPOSITION_GELOESCHT',
  AuftragsKopfAenderung = 'AUFTRAGS_KOPF_AENDERUNG',
  BestandseinheitErstellt = 'BESTANDSEINHEIT_ERSTELLT',
  HeVertragErstellt = 'HE_VERTRAG_ERSTELLT',
  HeVertragGeloescht = 'HE_VERTRAG_GELOESCHT',
  KontoAenderung = 'KONTO_AENDERUNG',
  NutzungsArt = 'NUTZUNGS_ART',
  NutzungsArtNichtErmittelbar = 'NUTZUNGS_ART_NICHT_ERMITTELBAR',
  OptionSteuerpflicht = 'OPTION_STEUERPFLICHT',
  OptionSteuerpflichtNichtErmittelbar = 'OPTION_STEUERPFLICHT_NICHT_ERMITTELBAR',
  RepFondsEinstellungAenderung = 'REP_FONDS_EINSTELLUNG_AENDERUNG',
  Steuerpflicht = 'STEUERPFLICHT',
  SteuerpflichtNichtErmittelbar = 'STEUERPFLICHT_NICHT_ERMITTELBAR',
  SteuerpflichtSubVerwaltung = 'STEUERPFLICHT_SUB_VERWALTUNG',
  SubAdministrationExpenseAccountIndeterminate = 'SUB_ADMINISTRATION_EXPENSE_ACCOUNT_INDETERMINATE',
  UstKategorie = 'UST_KATEGORIE',
  UstKategorieEntry = 'UST_KATEGORIE_ENTRY',
  UstKategorieEntryNichtErmittelbar = 'UST_KATEGORIE_ENTRY_NICHT_ERMITTELBAR',
  UstKategorieNichtErmittelbar = 'UST_KATEGORIE_NICHT_ERMITTELBAR',
  Vertragsart = 'VERTRAGSART',
  VertragsdatenAenderung = 'VERTRAGSDATEN_AENDERUNG',
  Vertragspartner = 'VERTRAGSPARTNER',
  VertragErstellt = 'VERTRAG_ERSTELLT',
  VertragGeloescht = 'VERTRAG_GELOESCHT',
  VorschreibungspositionErstellt = 'VORSCHREIBUNGSPOSITION_ERSTELLT',
  VorschreibungspositionWertAenderung = 'VORSCHREIBUNGSPOSITION_WERT_AENDERUNG',
  VorschreibungspositionWertErstellt = 'VORSCHREIBUNGSPOSITION_WERT_ERSTELLT',
  VorschreibungspositionWertGeloescht = 'VORSCHREIBUNGSPOSITION_WERT_GELOESCHT',
  VstKrzEinstellungAenderung = 'VST_KRZ_EINSTELLUNG_AENDERUNG',
  WertAenderung = 'WERT_AENDERUNG',
}

export type DeviationUrsacheTypeTuple = {
  text: Scalars['String']['output'];
  value: DeviationUrsacheType;
};

export type Dimensions = {
  height: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
};

export enum DocumentHeaderAndFooterImageAlignment {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export type DocumentHeaderAndFooterImageAlignmentTuple = {
  text: Scalars['String']['output'];
  value: DocumentHeaderAndFooterImageAlignment;
};

export type Dokumentenversand = {
  kopieList: Array<DokumentenversandEntry>;
  original: DokumentenversandEntry;
};

export type DokumentenversandEntry = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dokumentenversandEntryId: Scalars['String']['output'];
  emailAktiv: Scalars['Boolean']['output'];
  emailContact?: Maybe<Contact>;
  emailHaupt: Scalars['Boolean']['output'];
  missingEmailContactReason?: Maybe<MissingContactReasonTuple>;
  missingPostAddressReason?: Maybe<MissingContactReasonTuple>;
  postAddress?: Maybe<Address>;
  postAktiv: Scalars['Boolean']['output'];
  postHaupt: Scalars['Boolean']['output'];
  rechtstraeger: RechtstraegerBase;
  warningList: Array<ResponseDataWarning>;
};

export type DokumentenversandEntryResponse = {
  data: DokumentenversandEntry;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type DokumentenversandResponse = {
  data: Dokumentenversand;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type DueDateDisplayOption = {
  month?: Maybe<Scalars['Int']['output']>;
  type: DueDateDisplayOptionTypeTuple;
};

export enum DueDateDisplayOptionType {
  UebernaechsterZinstermin = 'UEBERNAECHSTER_ZINSTERMIN',
  Verrechnungsperiode = 'VERRECHNUNGSPERIODE',
}

export type DueDateDisplayOptionTypeTuple = {
  text: Scalars['String']['output'];
  value: DueDateDisplayOptionType;
};

export type DueDateDisplayOptionsInput = {
  month?: InputMaybe<Scalars['Int']['input']>;
  type: DueDateDisplayOptionType;
};

export enum DueDateVorschreibungArt {
  MonatsErster = 'MONATS_ERSTER',
  MonatsFuenfter = 'MONATS_FUENFTER',
}

export type DueDateVorschreibungTuple = {
  text: Scalars['String']['output'];
  value: DueDateVorschreibungArt;
};

export type EbicsBankConfig = {
  bankStatementBtf?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  creditTransferBtf?: Maybe<Scalars['String']['output']>;
  creditTransferStatusBtf?: Maybe<Scalars['String']['output']>;
  deletable: Scalars['Boolean']['output'];
  directDebitBtf?: Maybe<Scalars['String']['output']>;
  directDebitStatusBtf?: Maybe<Scalars['String']['output']>;
  ebicsBankConfigId: Scalars['ID']['output'];
  ebicsVersion: EbicsVersionTuple;
  hostId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pain001Schema: Pain001SchemaTuple;
  pain008Schema: Pain008SchemaTuple;
  preApprovalBtf?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type EbicsBankConfigCreateInput = {
  bankStatementBtf?: InputMaybe<Scalars['String']['input']>;
  creditTransferBtf?: InputMaybe<Scalars['String']['input']>;
  creditTransferStatusBtf?: InputMaybe<Scalars['String']['input']>;
  directDebitBtf?: InputMaybe<Scalars['String']['input']>;
  directDebitStatusBtf?: InputMaybe<Scalars['String']['input']>;
  ebicsVersion: EbicsVersion;
  hostId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pain001Schema: Pain001Schema;
  pain008Schema: Pain008Schema;
  preApprovalBtf?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type EbicsBankConfigListResponse = {
  data: Array<EbicsBankConfig>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EbicsBankConfigResponse = {
  data: EbicsBankConfig;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EbicsBankConfigUpdateInput = {
  bankStatementBtf?: InputMaybe<Scalars['String']['input']>;
  creditTransferBtf?: InputMaybe<Scalars['String']['input']>;
  creditTransferStatusBtf?: InputMaybe<Scalars['String']['input']>;
  directDebitBtf?: InputMaybe<Scalars['String']['input']>;
  directDebitStatusBtf?: InputMaybe<Scalars['String']['input']>;
  ebicsVersion: EbicsVersion;
  hostId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pain001Schema: Pain001Schema;
  pain008Schema: Pain008Schema;
  preApprovalBtf?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export enum EbicsIbanPermission {
  InitialSignature = 'INITIAL_SIGNATURE',
  SecondSignature = 'SECOND_SIGNATURE',
  SingleSignature = 'SINGLE_SIGNATURE',
  TransportSignature = 'TRANSPORT_SIGNATURE',
}

export type EbicsIbanPermissionTuple = {
  text: Scalars['String']['output'];
  value: EbicsIbanPermission;
};

export type EbicsOrderTypeTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type EbicsUser = {
  bankname: Scalars['String']['output'];
  camtImportEnabled: Scalars['Boolean']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable: Scalars['Boolean']['output'];
  ebicsBankConfigId: Scalars['String']['output'];
  ebicsUserId: Scalars['String']['output'];
  editable: Scalars['Boolean']['output'];
  initializationDocumentFileId?: Maybe<Scalars['String']['output']>;
  mitarbeiterId: Scalars['String']['output'];
  mitarbeiterName: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  orderTypeList: Array<EbicsOrderTypeTuple>;
  partnerId: Scalars['String']['output'];
  password: Scalars['String']['output'];
  status: EbicsUserStatusTuple;
  userId: Scalars['String']['output'];
};

export type EbicsUserCreateInput = {
  ebicsBankConfigId: Scalars['String']['input'];
  mitarbeiterId: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type EbicsUserIban = {
  accountHolder: Scalars['String']['output'];
  accountNumber?: Maybe<Scalars['String']['output']>;
  activationTs?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  creditTransferPermission?: Maybe<EbicsIbanPermissionTuple>;
  directDebitPermission?: Maybe<EbicsIbanPermissionTuple>;
  ebicsUserIbanId: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  lastBankStatementFetchDate?: Maybe<Scalars['String']['output']>;
  mitarbeiterName: Scalars['String']['output'];
  retrieveBankStatement: Scalars['Boolean']['output'];
  status: EbicsUserIbanStatusTuple;
};

export type EbicsUserIbanListResponse = {
  data: Array<EbicsUserIban>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EbicsUserIbanResponse = {
  data: EbicsUserIban;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum EbicsUserIbanStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type EbicsUserIbanStatusTuple = {
  text: Scalars['String']['output'];
  value: EbicsUserIbanStatus;
};

export type EbicsUserListResponse = {
  data: Array<EbicsUser>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EbicsUserMitarbeiter = {
  firmendatenId: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  mitarbeiterId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type EbicsUserMitarbeiterListResponse = {
  data: Array<EbicsUserMitarbeiter>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum EbicsUserOrderBy {
  Bankname = 'BANKNAME',
  Mitarbeiter = 'MITARBEITER',
  Partner = 'PARTNER',
  Status = 'STATUS',
  Teilnehmer = 'TEILNEHMER',
  User = 'USER',
}

export type EbicsUserPage = {
  contentList: Array<EbicsUser>;
  page: Page;
};

export type EbicsUserPageResponse = {
  data: EbicsUserPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EbicsUserResponse = {
  data: EbicsUser;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum EbicsUserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Initialized = 'INITIALIZED',
  InitializeError = 'INITIALIZE_ERROR',
}

export type EbicsUserStatusListResponse = {
  data: Array<EbicsUserStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EbicsUserStatusTuple = {
  text: Scalars['String']['output'];
  value: EbicsUserStatus;
};

export type EbicsUserUpdateInput = {
  ebicsBankConfigId: Scalars['String']['input'];
  mitarbeiterId: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export enum EbicsVersion {
  H003 = 'H003',
  H004 = 'H004',
  H005 = 'H005',
}

export type EbicsVersionListResponse = {
  data: Array<EbicsVersionTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EbicsVersionTuple = {
  text: Scalars['String']['output'];
  value: EbicsVersion;
};

export type Eigenbeleg = {
  auftragIdList: Array<Scalars['String']['output']>;
  auftragStatusDetailsList: Array<AuftragStatusDetails>;
  ausstellerBankDetails: OrderBankDetails;
  ausstellerRechtstraeger: Rechtstraeger;
  ausstellerRechtstraegerId: Scalars['String']['output'];
  belegId: Scalars['ID']['output'];
  belegdatum: Scalars['String']['output'];
  belegform: BelegFormTuple;
  belegnummer: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  buchungErrorList: Array<ErrorData>;
  buchungIdList: Array<Scalars['String']['output']>;
  buchungsanweisungIdList: Array<Scalars['String']['output']>;
  buchungsanweisungListEntryDataList: Array<OrderBuchungsanweisungListEntry>;
  buchungsanweisungStatus?: Maybe<OrderBuchungsanweisungStatusTuple>;
  buchungskreisId?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deviationDetails?: Maybe<DeviationDetails>;
  dueDateVorschreibung: DueDateVorschreibungTuple;
  eigenbelegPositionList: Array<EigenbelegsPosition>;
  empfaengerBankDetails?: Maybe<OrderBankDetails>;
  empfaengerRechtstraeger: Rechtstraeger;
  empfaengerRechtstraegerId: Scalars['String']['output'];
  fakturierungsperiode: Fakturierungsperiode;
  fileInfo: OrderFileInfo;
  mailId?: Maybe<Scalars['String']['output']>;
  paid: Scalars['Boolean']['output'];
  paymentMethod: PaymentMethodTuple;
  status: EigenbelegStatusTuple;
  sumBrutto?: Maybe<Scalars['Float']['output']>;
  sumNetto?: Maybe<Scalars['Float']['output']>;
  sumUst?: Maybe<Scalars['Float']['output']>;
  type: EigenbelegTypeTuple;
  verrechnungsart: VerrechnungsartTuple;
};

export type EigenbelegErstellenGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type EigenbelegErstellenGenerierlaufEntry = {
  auftrag?: Maybe<Auftrag>;
  currentStep?: Maybe<Scalars['Int']['output']>;
  eigenbeleg?: Maybe<Eigenbeleg>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  objektId?: Maybe<Scalars['String']['output']>;
  objektKurzBezeichnung?: Maybe<Scalars['String']['output']>;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type EigenbelegErstellenGenerierlaufInput = {
  belegdatum: Scalars['String']['input'];
  generierDatum: Scalars['String']['input'];
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum EigenbelegErstellenGenerierlaufOrderBy {
  Aussteller = 'AUSSTELLER',
  Belegnummer = 'BELEGNUMMER',
  Brutto = 'BRUTTO',
  Empfaenger = 'EMPFAENGER',
  Netto = 'NETTO',
  Status = 'STATUS',
  Ust = 'UST',
  Zinsliste = 'ZINSLISTE',
}

export type EigenbelegErstellenGenerierlaufResponse = {
  data: EigenbelegErstellenGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EigenbelegErstellenListEntryPage = {
  contentList: Array<EigenbelegErstellenGenerierlaufEntry>;
  page: Page;
};

export type EigenbelegErstellenListEntryPageResponse = {
  data: EigenbelegErstellenListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum EigenbelegOrderBy {
  Belegnummer = 'BELEGNUMMER',
}

export type EigenbelegResponse = {
  data: Eigenbeleg;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum EigenbelegStatus {
  Ausgegeben = 'AUSGEGEBEN',
  Freigegeben = 'FREIGEGEBEN',
  Uebertragen = 'UEBERTRAGEN',
  UebertragenFehler = 'UEBERTRAGEN_FEHLER',
}

export type EigenbelegStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: EigenbelegStatus;
};

export enum EigenbelegType {
  StornoRechnung = 'STORNO_RECHNUNG',
  VorschreibungAbrErgebnisGutschrift = 'VORSCHREIBUNG_ABR_ERGEBNIS_GUTSCHRIFT',
  VorschreibungAbrErgebnisRechnung = 'VORSCHREIBUNG_ABR_ERGEBNIS_RECHNUNG',
  VorschreibungDauerrechnung = 'VORSCHREIBUNG_DAUERRECHNUNG',
  VorschreibungEinzel = 'VORSCHREIBUNG_EINZEL',
}

export type EigenbelegTypeTuple = {
  text: Scalars['String']['output'];
  value: EigenbelegType;
};

export type EigenbelegsPosition = {
  bestandseinheit?: Maybe<Bestandseinheit>;
  bestandseinheitId: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  brutto: Scalars['Float']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  eigenbelegsPositionId: Scalars['ID']['output'];
  generierDatum?: Maybe<Scalars['String']['output']>;
  generierlaufId?: Maybe<Scalars['String']['output']>;
  labelList: Array<Label>;
  netto: Scalars['Float']['output'];
  objekt?: Maybe<Objekt>;
  objektId: Scalars['String']['output'];
  performancePeriodFromInclusive: Scalars['String']['output'];
  performancePeriodToInclusive: Scalars['String']['output'];
  steuersatz: Scalars['Int']['output'];
  ust: Scalars['Float']['output'];
  ustKategorieEntryId: Scalars['String']['output'];
  ustKategorieId: Scalars['String']['output'];
  vertragId: Scalars['String']['output'];
  vorschreibungspositionBezeichnung: Scalars['String']['output'];
  vorschreibungspositionId: Scalars['String']['output'];
};

export type EinnahmenBuchungszeile = {
  auftragId?: Maybe<Scalars['String']['output']>;
  belegDatum: Scalars['String']['output'];
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegId?: Maybe<Scalars['String']['output']>;
  belegnummer: Scalars['String']['output'];
  betrag: Scalars['Float']['output'];
  buchungId: Scalars['String']['output'];
  buchungskreisId: Scalars['String']['output'];
  buchungskreisRechtstraeger: RechtstraegerBase;
  dueDate: Scalars['String']['output'];
  kontoBezeichnung: Scalars['String']['output'];
  kontoId: Scalars['String']['output'];
  kontoNummer: Scalars['String']['output'];
  offenerBetrag: Scalars['Float']['output'];
  sollHaben: SollHabenTuple;
  text: Scalars['String']['output'];
  vertragspartner: RechtstraegerBase;
};

export type EmailDeliveryDefinition = {
  bezeichnung: Scalars['String']['output'];
  body?: Maybe<Textbaustein>;
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  emailDeliveryDefinitionId: Scalars['ID']['output'];
  subject: Textbaustein;
  type: EmailDeliveryDefinitionTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type EmailDeliveryDefinitionBase = {
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  emailDeliveryDefinitionId: Scalars['ID']['output'];
  type: EmailDeliveryDefinitionTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type EmailDeliveryDefinitionBaseListResponse = {
  data: Array<EmailDeliveryDefinitionBase>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EmailDeliveryDefinitionCreateInput = {
  bezeichnung: Scalars['String']['input'];
  body?: InputMaybe<TextbausteinInput>;
  subject: TextbausteinInput;
  type: EmailDeliveryDefinitionType;
};

export type EmailDeliveryDefinitionResponse = {
  data: EmailDeliveryDefinition;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum EmailDeliveryDefinitionStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type EmailDeliveryDefinitionStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: EmailDeliveryDefinitionStatus;
};

export type EmailDeliveryDefinitionTemplateListResponse = {
  data: Array<EmailDeliveryDefinitionBase>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EmailDeliveryDefinitionTemplateResponse = {
  data: EmailDeliveryDefinition;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum EmailDeliveryDefinitionType {
  Informationsschreiben = 'INFORMATIONSSCHREIBEN',
  Vorschreibung = 'VORSCHREIBUNG',
}

export type EmailDeliveryDefinitionTypeTuple = {
  text: Scalars['String']['output'];
  value: EmailDeliveryDefinitionType;
};

export type EmailDeliveryDefinitionUpdateInput = {
  bezeichnung: Scalars['String']['input'];
  body?: InputMaybe<TextbausteinInput>;
  subject: TextbausteinInput;
};

export enum EmailDeliveryStatus {
  NotSent = 'NOT_SENT',
  NotSentError = 'NOT_SENT_ERROR',
  NotToBeSent = 'NOT_TO_BE_SENT',
  Sent = 'SENT',
  Started = 'STARTED',
}

export type EmailDeliveryStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: EmailDeliveryStatus;
};

export type EmailDeliveryTemplate = {
  body?: Maybe<Textbaustein>;
  subject: Textbaustein;
};

export type EmailDeliveryTemplateListResponse = {
  data: Array<EmailDeliveryTemplate>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum EmailDeliveryType {
  Informationsschreiben = 'INFORMATIONSSCHREIBEN',
  Vorschreibung = 'VORSCHREIBUNG',
}

export type EmailDeliveryTypeResponse = {
  data: Array<EmailDeliveryTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EmailDeliveryTypeTuple = {
  text: Scalars['String']['output'];
  value: EmailDeliveryType;
};

export type EmailDeliveryWizardAttachmentListResponse = {
  data: Array<WizardFileInfo>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EmailDeliveryWizardDetermineRecipientListStep = {
  completed: Scalars['Boolean']['output'];
  currentStep: Scalars['Int']['output'];
  generierlaufId: Scalars['ID']['output'];
  stepCount: Scalars['Int']['output'];
  wizardId: Scalars['String']['output'];
};

export type EmailDeliveryWizardDetermineRecipientListStepResponse = {
  data: EmailDeliveryWizardDetermineRecipientListStep;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EmailDeliveryWizardRecipientListStep = {
  completed: Scalars['Boolean']['output'];
  currentStep: Scalars['Int']['output'];
  sendGenerierlaufId?: Maybe<Scalars['ID']['output']>;
  stepCount: Scalars['Int']['output'];
  wizardId: Scalars['String']['output'];
};

export type EmailDeliveryWizardRecipientListStepResponse = {
  data: EmailDeliveryWizardRecipientListStep;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EmailDeliveryWizardTemplateStep = {
  attachmentInfoList: Array<WizardFileInfo>;
  body?: Maybe<Textbaustein>;
  completed: Scalars['Boolean']['output'];
  currentStep: Scalars['Int']['output'];
  emailDeliveryDefinitionBezeichnung: Scalars['String']['output'];
  emailDeliveryDefinitionId: Scalars['String']['output'];
  stepCount: Scalars['Int']['output'];
  subject: Textbaustein;
  wizardId: Scalars['ID']['output'];
};

export type EmailDeliveryWizardTemplateStepResponse = {
  data: EmailDeliveryWizardTemplateStep;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EmailIntegration = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  firmendatenId: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type EmailIntegrationResponse = {
  data?: Maybe<EmailIntegration>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type EmailRecipient = {
  contact: OrderContact;
  rechtstraeger: OrderRechtstraeger;
};

export enum EmployeeApproval {
  ApprovedByMe = 'APPROVED_BY_ME',
  ToApproveByMe = 'TO_APPROVE_BY_ME',
}

export type EnumData = {
  text: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ErrechnetesErloesKonto = {
  buchungsKZ?: Maybe<Scalars['String']['output']>;
  erloeskonto: Scalars['Int']['output'];
  firmendatenId?: Maybe<Scalars['String']['output']>;
  steuersatz: Scalars['Int']['output'];
};

export type ErrorData = {
  message: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum ExitCodeStatus {
  Fehler = 'FEHLER',
  Ok = 'OK',
  Warnung = 'WARNUNG',
}

export type ExitCodeStatusTuple = {
  text: Scalars['String']['output'];
  value: ExitCodeStatus;
};

export type Fakturierungsperiode = {
  jahr: Scalars['Int']['output'];
  monat: Scalars['Int']['output'];
};

export type FakturierungsperiodeInput = {
  jahr: Scalars['Int']['input'];
  monat: Scalars['Int']['input'];
};

export type FakturierungsperiodeListResponse = {
  data: Array<Fakturierungsperiode>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FibuAddress = {
  addressId: Scalars['ID']['output'];
  city: Scalars['String']['output'];
  cityAdditionalInformation?: Maybe<Scalars['String']['output']>;
  countryCodeIso2: Scalars['String']['output'];
  houseEntranceApartmentNumber?: Maybe<Scalars['String']['output']>;
  postofficeBoxNumber?: Maybe<Scalars['String']['output']>;
  postofficeBoxZipCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  type: AddressType;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type FibuBankDetails = {
  accountHolder: Scalars['String']['output'];
  bankDetailsId: Scalars['String']['output'];
  bankname: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
};

export enum FibuBelegSymbol {
  EingangsRechnung = 'EINGANGS_RECHNUNG',
  Einzelrechnung = 'EINZELRECHNUNG',
  Gutschrift = 'GUTSCHRIFT',
  Mahnung = 'MAHNUNG',
  Ruecklastschrift = 'RUECKLASTSCHRIFT',
  Sonstiges = 'SONSTIGES',
  SpesenRuecklastschrift = 'SPESEN_RUECKLASTSCHRIFT',
  StornoRechnung = 'STORNO_RECHNUNG',
  StornoRuecklastschrift = 'STORNO_RUECKLASTSCHRIFT',
  StornoSpesenRuecklastschrift = 'STORNO_SPESEN_RUECKLASTSCHRIFT',
  StornoZahlung = 'STORNO_ZAHLUNG',
  UstVomAufwand = 'UST_VOM_AUFWAND',
  Vorschreibung = 'VORSCHREIBUNG',
  Vorsteuerkuerzung = 'VORSTEUERKUERZUNG',
  Zahlung = 'ZAHLUNG',
}

export type FibuBelegSymbolListResponse = {
  data: Array<FibuBelegSymbolTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FibuBelegSymbolTuple = {
  abbreviation: Scalars['String']['output'];
  text: Scalars['String']['output'];
  value: FibuBelegSymbol;
};

export type FibuBestandseinheit = {
  bestandseinheitId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
  objektKurzBezeichnung?: Maybe<Scalars['String']['output']>;
};

export type FibuBestandseinheitListResponse = {
  data: Array<FibuBestandseinheit>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum FibuBuchungType {
  Abgrenzung = 'ABGRENZUNG',
  AusgangsRechnung = 'AUSGANGS_RECHNUNG',
  EingangsRechnung = 'EINGANGS_RECHNUNG',
  Intern = 'INTERN',
  Mahnung = 'MAHNUNG',
  Ruecklastschrift = 'RUECKLASTSCHRIFT',
  Sonstiges = 'SONSTIGES',
  Spesen = 'SPESEN',
  Zahlung = 'ZAHLUNG',
}

export type FibuBuchungTypeTuple = {
  text: Scalars['String']['output'];
  value: FibuBuchungType;
};

export type FibuBuchungsTypeListResponse = {
  data: Array<FibuBuchungTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FibuBuchungsanweisungBuchungsKopf = {
  belegDatum?: Maybe<Scalars['String']['output']>;
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegSymbol: FibuBelegSymbolTuple;
  belegnummer?: Maybe<Scalars['String']['output']>;
  buchungsType: FibuBuchungTypeTuple;
  buchungsdatum: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['String']['output']>;
  vertragspartner?: Maybe<FibuRechtstraeger>;
  waehrung: WaehrungTuple;
};

export type FibuBuchungsanweisungBuchungsKopfInput = {
  belegDatum?: InputMaybe<Scalars['String']['input']>;
  belegFileId?: InputMaybe<Scalars['String']['input']>;
  belegSymbol: FibuBelegSymbol;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  buchungsType: FibuBuchungType;
  buchungsdatum: Scalars['String']['input'];
  dueDate?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type FibuBuchungsanweisungBuchungszeile = {
  bestandseinheit?: Maybe<FibuBestandseinheit>;
  betrag: Scalars['Float']['output'];
  buchungsKZ?: Maybe<Scalars['String']['output']>;
  buchungszeileId: Scalars['String']['output'];
  gegenKonto?: Maybe<Konto>;
  heVertragId?: Maybe<Scalars['String']['output']>;
  konto: Konto;
  objekt?: Maybe<FibuObjekt>;
  sollHaben: SollHabenTuple;
  steuer?: Maybe<Scalars['Float']['output']>;
  steuersatz?: Maybe<Scalars['Int']['output']>;
  text: Scalars['String']['output'];
  umbuchung: Scalars['Boolean']['output'];
  ustVomAufwandSteuersatz?: Maybe<Scalars['Int']['output']>;
  vertragId?: Maybe<Scalars['String']['output']>;
};

export type FibuBuchungsanweisungBuchungszeileInput = {
  bestandseinheitId?: InputMaybe<Scalars['String']['input']>;
  betrag: Scalars['Float']['input'];
  buchungsKZ?: InputMaybe<Scalars['String']['input']>;
  buchungszeileId?: InputMaybe<Scalars['String']['input']>;
  gegenKontoId?: InputMaybe<Scalars['String']['input']>;
  heVertragId?: InputMaybe<Scalars['String']['input']>;
  kontoId: Scalars['String']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  sollHaben: SollHaben;
  steuer?: InputMaybe<Scalars['Float']['input']>;
  steuersatz?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
  umbuchung?: InputMaybe<Scalars['Boolean']['input']>;
  ustVomAufwandSteuersatz?: InputMaybe<Scalars['Int']['input']>;
  vertragId?: InputMaybe<Scalars['String']['input']>;
};

export type FibuBuchungsanweisungInput = {
  buchungsKopf?: InputMaybe<FibuBuchungsanweisungBuchungsKopfInput>;
  buchungskreisRechtstraegerId: Scalars['String']['input'];
  buchungszeileList: Array<FibuBuchungsanweisungBuchungszeileInput>;
  deliveryAddressId?: InputMaybe<Scalars['String']['input']>;
  fachlichePruefung?: InputMaybe<Scalars['Boolean']['input']>;
  rechnungsAusstellerBankDetailsId?: InputMaybe<Scalars['String']['input']>;
  recipientEmailContactId?: InputMaybe<Scalars['String']['input']>;
  zuStornierendeBuchungId?: InputMaybe<Scalars['String']['input']>;
};

export type FibuBuchungsanweisungListEntry = {
  buchungId?: Maybe<Scalars['String']['output']>;
  buchungsKopf: FibuBuchungsanweisungBuchungsKopf;
  buchungsanweisungId: Scalars['String']['output'];
  buchungskreis: FibuBuchungskreis;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId: Scalars['String']['output'];
  fachlichePruefung: Scalars['Boolean']['output'];
  objekt?: Maybe<FibuObjekt>;
  status: FibuBuchungsanweisungStatusTuple;
  sumBrutto: Scalars['Float']['output'];
  sumSteuer: Scalars['Float']['output'];
  updatedByMitarbeiterId: Scalars['String']['output'];
  zuStornierendeBuchung: Buchung;
};

export type FibuBuchungsanweisungListEntryPage = {
  contentList: Array<FibuBuchungsanweisungListEntry>;
  page: Page;
};

export type FibuBuchungsanweisungListEntryPageResponse = {
  data: FibuBuchungsanweisungListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum FibuBuchungsanweisungOrderBy {
  Belegnummer = 'BELEGNUMMER',
  BelegDatum = 'BELEG_DATUM',
  BelegSymbol = 'BELEG_SYMBOL',
  BuchungskreisRechtstraeger = 'BUCHUNGSKREIS_RECHTSTRAEGER',
  BuchungsDatum = 'BUCHUNGS_DATUM',
  BuchungsTyp = 'BUCHUNGS_TYP',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  DueDate = 'DUE_DATE',
  SumBrutto = 'SUM_BRUTTO',
  SumSteuer = 'SUM_STEUER',
  Vertragspartner = 'VERTRAGSPARTNER',
  ZuStornierendeBuchung = 'ZU_STORNIERENDE_BUCHUNG',
}

export type FibuBuchungsanweisungResponse = {
  buchungsKopf: FibuBuchungsanweisungBuchungsKopf;
  buchungsanweisungId: Scalars['String']['output'];
  buchungskreis: FibuBuchungskreis;
  buchungszeileList: Array<FibuBuchungsanweisungBuchungszeile>;
  deliveryAddress?: Maybe<FibuAddress>;
  emailContact?: Maybe<FibuContact>;
  fachlichePruefung: Scalars['Boolean']['output'];
  rechnungsAusstellerBankDetails: FibuBankDetails;
  status: FibuBuchungsanweisungStatusTuple;
  zuStornierendeBuchungId: Scalars['String']['output'];
};

export enum FibuBuchungsanweisungStatus {
  Offen = 'OFFEN',
  Verbucht = 'VERBUCHT',
}

export type FibuBuchungsanweisungStatusTuple = {
  text: Scalars['String']['output'];
  value: FibuBuchungsanweisungStatus;
};

export type FibuBuchungskreis = {
  buchungskreisId: Scalars['String']['output'];
  rechtstraeger: FibuRechtstraeger;
};

export type FibuBuchungskreisListResponse = {
  data: Array<FibuBuchungskreis>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FibuBuchungszeile = {
  ausgezifferterBetrag?: Maybe<Scalars['Float']['output']>;
  bestandseinheit?: Maybe<BuchungszeileBestandseinheit>;
  betrag: Scalars['Float']['output'];
  buchungId: Scalars['String']['output'];
  buchungsKZ?: Maybe<Scalars['String']['output']>;
  buchungsKopf: BuchungsKopf;
  buchungsanweisungId: Scalars['String']['output'];
  buchungskreisBezeichnung: Scalars['String']['output'];
  buchungskreisId: Scalars['String']['output'];
  buchungszeileId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  fachlichePruefung?: Maybe<Scalars['Boolean']['output']>;
  gegenKonto?: Maybe<FibuKonto>;
  heVertragId?: Maybe<Scalars['String']['output']>;
  heVertragKurzbezeichnung?: Maybe<Scalars['String']['output']>;
  konto: FibuKonto;
  objekt?: Maybe<FibuObjekt>;
  offenePostenId?: Maybe<Scalars['String']['output']>;
  offenerBetrag?: Maybe<Scalars['String']['output']>;
  offenerOpBetrag?: Maybe<Scalars['Float']['output']>;
  sollHaben: SollHabenTuple;
  source: BuchungSourceTuple;
  steuer?: Maybe<Scalars['Float']['output']>;
  steuersatz?: Maybe<Scalars['Int']['output']>;
  storniert: Scalars['Boolean']['output'];
  stornierteBuchungBezeichnung?: Maybe<Scalars['String']['output']>;
  stornierteBuchungId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  umbuchung?: Maybe<Scalars['Boolean']['output']>;
  umgebucht?: Maybe<Scalars['Boolean']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  ustVomAufwandSteuersatz?: Maybe<Scalars['Int']['output']>;
  vertragId?: Maybe<Scalars['String']['output']>;
  vertragKurzbezeichnung?: Maybe<Scalars['String']['output']>;
};

export type FibuBuchungszeileListSummary = {
  sumBetrag: Scalars['Float']['output'];
  sumSteuer: Scalars['Float']['output'];
};

export type FibuBuchungszeileListSummaryResponse = {
  data: FibuBuchungszeileListSummary;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FibuBuchungszeilePage = {
  contentList: Array<FibuBuchungszeile>;
  page: Page;
};

export type FibuBuchungszeilePageResponse = {
  data: FibuBuchungszeilePage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FibuBuchungszeileResponse = {
  data: Array<FibuBuchungszeile>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FibuContact = {
  contactId: Scalars['String']['output'];
  type: Scalars['String']['output'];
  wert: Scalars['String']['output'];
};

export type FibuEinstellung = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  negierteHabenBetraege: Scalars['Boolean']['output'];
};

export type FibuEinstellungResponse = {
  data: FibuEinstellung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FibuEinstellungUpdateInput = {
  negierteHabenBetraege: Scalars['Boolean']['input'];
};

export enum FibuGenerierlaufType {
  MahnungAusgabe = 'MAHNUNG_AUSGABE',
  MahnungErstellung = 'MAHNUNG_ERSTELLUNG',
  MahnungVerbuchen = 'MAHNUNG_VERBUCHEN',
  MahnungVersand = 'MAHNUNG_VERSAND',
}

export type FibuKonto = {
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  haupt: Scalars['Boolean']['output'];
  klasse: KontoKlasseTuple;
  kontoId: Scalars['ID']['output'];
  kontoTemplateId?: Maybe<Scalars['String']['output']>;
  nummer: Scalars['String']['output'];
  status: KontoStatusTuple;
  tagList: Array<Scalars['String']['output']>;
  type: KontoTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  verwendung?: Maybe<KontoVerwendungTuple>;
};

export type FibuKontoListResponse = {
  data: Array<FibuKonto>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FibuObjekt = {
  kurzBezeichnung: Scalars['String']['output'];
  objektId: Scalars['ID']['output'];
};

export type FibuObjektListResponse = {
  data: Array<FibuObjekt>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FibuRechtstraeger = {
  kurzBezeichnung: Scalars['String']['output'];
  rechtstraegerId: Scalars['ID']['output'];
  status: RechtstraegerStatusTuple;
  type: RechtstraegerTypeTuple;
};

export type FibuRechtstraegerListResponse = {
  data: Array<FibuRechtstraeger>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum FibuZahlungStatus {
  Ausgeziffert = 'AUSGEZIFFERT',
}

export type FibuZahlungStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: FibuZahlungStatus;
};

export type FieldHelp = {
  deprecated: Scalars['Boolean']['output'];
  fieldHelp?: Maybe<Scalars['String']['output']>;
  fieldHelpId: Scalars['ID']['output'];
  selector: Scalars['String']['output'];
};

export enum FieldHelpOrderBy {
  Deprecated = 'DEPRECATED',
  FieldHelp = 'FIELD_HELP',
  Selector = 'SELECTOR',
}

export type FieldHelpPage = {
  contentList: Array<FieldHelp>;
  page: Page;
};

export type FieldHelpPageResponse = {
  data: FieldHelpPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FieldHelpResponse = {
  data: FieldHelp;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FieldHelpUpdateInput = {
  fieldHelp?: InputMaybe<Scalars['String']['input']>;
  selector: Scalars['String']['input'];
};

export type FileInfo = {
  bestandseinheitList: Array<FileStorageBestandseinheit>;
  budgetingYear?: Maybe<Scalars['Int']['output']>;
  contentType: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  dimensions?: Maybe<Dimensions>;
  dunningReferenceDate?: Maybe<Scalars['String']['output']>;
  fileId: Scalars['ID']['output'];
  geloescht: Scalars['Boolean']['output'];
  homeownerId?: Maybe<Scalars['String']['output']>;
  invoiceDate?: Maybe<Scalars['String']['output']>;
  invoiceIssuerId?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoiceRecipientId?: Maybe<Scalars['String']['output']>;
  labelList: Array<Label>;
  name: Scalars['String']['output'];
  objektList: Array<FileStorageObjekt>;
  size: Scalars['String']['output'];
  type: FileInfoTypeTuple;
};

export type FileInfoPage = {
  contentList: Array<FileInfo>;
  page: Page;
};

export type FileInfoPageResponse = {
  data?: Maybe<FileInfoPage>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FileInfoResponse = {
  data: FileInfo;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum FileInfoType {
  AusgangsRechnung = 'AUSGANGS_RECHNUNG',
  BelegFuss = 'BELEG_FUSS',
  BelegKopf = 'BELEG_KOPF',
  BkObjektAbrechnung = 'BK_OBJEKT_ABRECHNUNG',
  BkTopAbrechnung = 'BK_TOP_ABRECHNUNG',
  BuchungsanweisungBeleg = 'BUCHUNGSANWEISUNG_BELEG',
  DataCarrierCamt = 'DATA_CARRIER_CAMT',
  EbicsInitializationPdf = 'EBICS_INITIALIZATION_PDF',
  EbicsZip = 'EBICS_ZIP',
  HeAbrechnung = 'HE_ABRECHNUNG',
  IncomingInvoice = 'INCOMING_INVOICE',
  InfoFeldBeKellerabteil = 'INFO_FELD_BE_KELLERABTEIL',
  InfoFeldBeParkplatz = 'INFO_FELD_BE_PARKPLATZ',
  InfoFeldBeZimmer = 'INFO_FELD_BE_ZIMMER',
  InfoFeldObjektAuflage = 'INFO_FELD_OBJEKT_AUFLAGE',
  InfoFeldObjektGrundstuecksdaten = 'INFO_FELD_OBJEKT_GRUNDSTUECKSDATEN',
  InfoFeldObjektHausbetreuung = 'INFO_FELD_OBJEKT_HAUSBETREUUNG',
  InfoFeldObjektPerson = 'INFO_FELD_OBJEKT_PERSON',
  InfoFeldObjektVersicherung = 'INFO_FELD_OBJEKT_VERSICHERUNG',
  InfoFeldRechtstraegerPersoneninfos = 'INFO_FELD_RECHTSTRAEGER_PERSONENINFOS',
  InfoFeldVertragKuendigungsinfos = 'INFO_FELD_VERTRAG_KUENDIGUNGSINFOS',
  InfoFeldVertragWeiterePerson = 'INFO_FELD_VERTRAG_WEITERE_PERSON',
  Mahnung = 'MAHNUNG',
  MailAttachment = 'MAIL_ATTACHMENT',
  ObjektFinancialPlan = 'OBJEKT_FINANCIAL_PLAN',
  ObjektFoto = 'OBJEKT_FOTO',
  ObjektStreetviewFoto = 'OBJEKT_STREETVIEW_FOTO',
  ReportLogoGross = 'REPORT_LOGO_GROSS',
  ReportLogoKlein = 'REPORT_LOGO_KLEIN',
  SepaLastschriftMandat = 'SEPA_LASTSCHRIFT_MANDAT',
  SubAbrechnungBestandseinheit = 'SUB_ABRECHNUNG_BESTANDSEINHEIT',
  SubAbrechnungObjekt = 'SUB_ABRECHNUNG_OBJEKT',
  TicketAttachment = 'TICKET_ATTACHMENT',
  TicketEmail = 'TICKET_EMAIL',
  TopFinancialPlan = 'TOP_FINANCIAL_PLAN',
  Unknown = 'UNKNOWN',
  WeObjektAbrechnung = 'WE_OBJEKT_ABRECHNUNG',
  WeTopAbrechnung = 'WE_TOP_ABRECHNUNG',
}

export type FileInfoTypeTuple = {
  text: Scalars['String']['output'];
  value: FileInfoType;
};

export type FileStorageBestandseinheit = {
  bestandseinheitId: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
};

export type FileStorageBestandseinheitListResponse = {
  data: Array<FileStorageBestandseinheit>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FileStorageObjekt = {
  kurzBezeichnung: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
};

export type FileStorageObjektListResponse = {
  data: Array<FileStorageObjekt>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FileStorageRechtstraeger = {
  kurzBezeichnung: Scalars['String']['output'];
  rechtstraegerId: Scalars['String']['output'];
};

export type FileStorageRechtstraegerListResponse = {
  data: Array<FileStorageRechtstraeger>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FinancialPlanTexts = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  einleitungsText?: Maybe<Scalars['String']['output']>;
  expenseList: Array<FinancialPlanTextsExpense>;
  schlussText?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type FinancialPlanTextsExpense = {
  amount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type FinancialPlanTextsResultResponse = {
  data: FinancialPlanTexts;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Firma = {
  bankDetailsList: Array<BankDetails>;
  companyRegistrationNumber?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fibuKontoCreatable: Scalars['Boolean']['output'];
  kundennummer: Scalars['ID']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  legalForm: LegalFormTuple;
  name1: Scalars['String']['output'];
  name2?: Maybe<Scalars['String']['output']>;
  name3?: Maybe<Scalars['String']['output']>;
  personenbezugList: Array<Personenbezug>;
  postIt?: Maybe<PostIt>;
  rechtstraegerId: Scalars['ID']['output'];
  rechtstraegerSteuerpflicht?: Maybe<RechtstraegerSteuerpflicht>;
  salutation?: Maybe<SalutationTuple>;
  sprache: EnumData;
  status: RechtstraegerStatusTuple;
  tagList: Array<Scalars['String']['output']>;
  type: RechtstraegerTypeTuple;
  vatIdentificationNumber?: Maybe<Scalars['String']['output']>;
  vertragSummary?: Maybe<VertragSummary>;
  warningList: Array<ResponseDataWarning>;
};

export type FirmaBase = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  name1: Scalars['String']['output'];
  name2?: Maybe<Scalars['String']['output']>;
  name3?: Maybe<Scalars['String']['output']>;
  personenbezugList: Array<Personenbezug>;
  rechtstraegerId: Scalars['ID']['output'];
  status: RechtstraegerStatusTuple;
  type: RechtstraegerTypeTuple;
  vatIdentificationNumber?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type FirmaCreateInput = {
  companyRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  hauptAddress: AddressCreateInput;
  kurzBezeichnung: Scalars['String']['input'];
  legalForm: LegalForm;
  name1: Scalars['String']['input'];
  name2?: InputMaybe<Scalars['String']['input']>;
  name3?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sprache: Scalars['String']['input'];
  steuerpflicht: SteuerpflichtInput;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  vatIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type FirmaForKs = {
  companyRegistrationNumber?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  firmendatenId: Scalars['ID']['output'];
  firmeneinstellung: Firmeneinstellung;
  kundenSystemId: Scalars['ID']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  legalForm: LegalFormTuple;
  name1: Scalars['String']['output'];
  name2?: Maybe<Scalars['String']['output']>;
  name3?: Maybe<Scalars['String']['output']>;
  rolleList: Array<Rolle>;
  sepaCreditorId?: Maybe<Scalars['ID']['output']>;
  status: FirmendatenStatusTuple;
  vatIdentificationNumber?: Maybe<Scalars['ID']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type FirmaKurzbezeichnungInput = {
  legalForm?: InputMaybe<LegalForm>;
  name1?: InputMaybe<Scalars['String']['input']>;
  name2?: InputMaybe<Scalars['String']['input']>;
  name3?: InputMaybe<Scalars['String']['input']>;
};

export type FirmaResponse = {
  data: Firma;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FirmaUpdateInput = {
  companyRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  kurzBezeichnung: Scalars['String']['input'];
  legalForm: LegalForm;
  name1: Scalars['String']['input'];
  name2?: InputMaybe<Scalars['String']['input']>;
  name3?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sprache: Scalars['String']['input'];
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  vatIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Firmendaten = {
  companyRegistrationNumber?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  firmendatenId: Scalars['ID']['output'];
  firmeneinstellung: Firmeneinstellung;
  kundenSystemId: Scalars['ID']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  legalForm: LegalFormTuple;
  name1: Scalars['String']['output'];
  name2?: Maybe<Scalars['String']['output']>;
  name3?: Maybe<Scalars['String']['output']>;
  rolleList: Array<Rolle>;
  sepaCreditorId?: Maybe<Scalars['ID']['output']>;
  status: FirmendatenStatusTuple;
  vatIdentificationNumber?: Maybe<Scalars['ID']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type FirmendatenAufwandAbgrenzungskontoInput = {
  aufwandAbgrenzungskontoId?: InputMaybe<Scalars['String']['input']>;
};

export type FirmendatenBankDetails = {
  accountHolder: Scalars['String']['output'];
  accountLimit?: Maybe<Scalars['Float']['output']>;
  allowObjektZuweisung?: Maybe<Scalars['Boolean']['output']>;
  assignedHeVertragZahlungsregelMap: Scalars['JSON']['output'];
  assignedObjektMap: Scalars['JSON']['output'];
  bankDetailsId: Scalars['ID']['output'];
  bankname: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  ebicsCreditTransferUserList: Array<Scalars['String']['output']>;
  ebicsDirectDebitUserList: Array<Scalars['String']['output']>;
  fibuKontoId?: Maybe<Scalars['String']['output']>;
  fibuKontonummer?: Maybe<Scalars['String']['output']>;
  fibuStatus: BankDetailsFibuStatusTuple;
  fibuVerwendung: BankDetailsFibuVerwendungTuple;
  iban: Scalars['String']['output'];
  sepaMandat?: Maybe<SepaMandat>;
  status: BankDetailsStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type FirmendatenBankDetailsCreateInput = {
  accountHolder: Scalars['String']['input'];
  allowObjektZuweisung?: InputMaybe<Scalars['Boolean']['input']>;
  bankname: Scalars['String']['input'];
  bic: Scalars['String']['input'];
  createFibuKonto?: InputMaybe<Scalars['Boolean']['input']>;
  iban: Scalars['String']['input'];
};

export type FirmendatenBankDetailsListResponse = {
  data: Array<FirmendatenBankDetails>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FirmendatenBankDetailsResponse = {
  data: FirmendatenBankDetails;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FirmendatenBankDetailsUpdateInput = {
  accountHolder: Scalars['String']['input'];
  allowObjektZuweisung?: InputMaybe<Scalars['Boolean']['input']>;
  bankname: Scalars['String']['input'];
  bic: Scalars['String']['input'];
  iban: Scalars['String']['input'];
};

export type FirmendatenBankSettingsUpdateInput = {
  sepaCreditorId?: InputMaybe<Scalars['String']['input']>;
};

export type FirmendatenBase = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenId: Scalars['String']['output'];
  kundenSystemId: Scalars['String']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  name1: Scalars['String']['output'];
  name2?: Maybe<Scalars['String']['output']>;
  name3?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type FirmendatenBuchhaltungEinstellung = {
  aufwandAbgrenzungskontoId?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  heAbrechnungVerrechnungskontoId?: Maybe<Scalars['String']['output']>;
  leerstehungAufwandskontoId?: Maybe<Scalars['String']['output']>;
  leerstehungForderungskontoId?: Maybe<Scalars['String']['output']>;
  pauschalmieteAufwandskontoId?: Maybe<Scalars['String']['output']>;
  pauschalmieteForderungskontoId?: Maybe<Scalars['String']['output']>;
  subAdministrationBillingAccountId?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type FirmendatenBuchhaltungEinstellungResponse = {
  data: FirmendatenBuchhaltungEinstellung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FirmendatenBuchhaltungEinstellungUpdateInput = {
  heAbrechnungVerrechnungskontoId?: InputMaybe<Scalars['String']['input']>;
  subAdministrationBillingAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type FirmendatenInitializerGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  firmendaten: FirmendatenBase;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type FirmendatenInitializerGenerierlaufResponse = {
  data: FirmendatenInitializerGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FirmendatenLeerstehungAufwandskonto = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  firmendatenLeerstehungAufwandskontoId: Scalars['ID']['output'];
  historicizedList?: Maybe<Array<FirmendatenLeerstehungAufwandskonto>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  leerstehungAufwandskontoId: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  warningList?: Maybe<Array<ResponseDataWarning>>;
};

export type FirmendatenLeerstehungAufwandskontoInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  leerstehungAufwandskontoId: Scalars['String']['input'];
  validFrom: Scalars['String']['input'];
};

export type FirmendatenLeerstehungAufwandskontoListResponse = {
  data: Array<FirmendatenLeerstehungAufwandskonto>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FirmendatenLeerstehungAufwandskontoResponse = {
  data: FirmendatenLeerstehungAufwandskonto;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FirmendatenLeerstehungForderungkontoInput = {
  firmendatenLeerstehungForderungskontoId?: InputMaybe<Scalars['String']['input']>;
};

export type FirmendatenListEntry = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  firmendatenId: Scalars['ID']['output'];
  kundenSystemId: Scalars['ID']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  status: FirmendatenStatusTuple;
  warningList: Array<ResponseDataWarning>;
};

export type FirmendatenListEntryListResponse = {
  data: Array<FirmendatenListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FirmendatenPauschalmieteAufwandskonto = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  firmendatenPauschalmieteAufwandskontoId: Scalars['String']['output'];
  historicizedList?: Maybe<Array<FirmendatenPauschalmieteAufwandskonto>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  pauschalmieteAufwandskontoId: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type FirmendatenPauschalmieteAufwandskontoInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  pauschalmieteAufwandskontoId: Scalars['String']['input'];
  validFrom: Scalars['String']['input'];
};

export type FirmendatenPauschalmieteAufwandskontoListResponse = {
  data: Array<FirmendatenPauschalmieteAufwandskonto>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FirmendatenPauschalmieteAufwandskontoResponse = {
  data: FirmendatenPauschalmieteAufwandskonto;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FirmendatenPauschalmieteKontenplan = {
  pauschalmieteAufwandskonto: FirmendatenPauschalmieteAufwandskonto;
  pauschalmieteForderungskontoId: Scalars['String']['output'];
};

export type FirmendatenPauschalmieteKontenplanResponse = {
  data: FirmendatenPauschalmieteKontenplan;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FirmendatenResponse = {
  data: Firmendaten;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum FirmendatenStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Deleted = 'DELETED',
  Initializing = 'INITIALIZING',
}

export type FirmendatenStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: FirmendatenStatus;
};

export type FirmendatenUpdateInput = {
  companyRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  kurzBezeichnung: Scalars['String']['input'];
  legalForm: LegalForm;
  name1: Scalars['String']['input'];
  name2?: InputMaybe<Scalars['String']['input']>;
  name3?: InputMaybe<Scalars['String']['input']>;
  sepaCreditorId?: InputMaybe<Scalars['String']['input']>;
  vatIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Firmeneinstellung = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  dueDateVorschreibung: EnumData;
};

export type FirmeneinstellungResponse = {
  data: Firmeneinstellung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FloatResponse = {
  data?: Maybe<Scalars['Float']['output']>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum FotoType {
  Streetview = 'STREETVIEW',
  Uploaded = 'UPLOADED',
}

export type GeneralTextbaustein = {
  auftragsartList: Array<AuftragsartBase>;
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  textbaustein: Textbaustein;
  textbausteinId: Scalars['ID']['output'];
};

export type GeneralTextbausteinCreateInput = {
  auftragsartIdList: Array<Scalars['String']['input']>;
  bezeichnung: Scalars['String']['input'];
  textbaustein: TextbausteinInput;
};

export type GeneralTextbausteinListResponse = {
  data: Array<GeneralTextbaustein>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type GeneralTextbausteinResponse = {
  data: GeneralTextbaustein;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type GeneralTextbausteinTypeListResponse = {
  data: Array<TextbausteinTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type GeneralTextbausteinUpdateInput = {
  auftragsartIdList: Array<Scalars['String']['input']>;
  bezeichnung: Scalars['String']['input'];
  textbaustein: TextbausteinInput;
};

export type Generierlauf = {
  countEntries: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  type: Scalars['String']['output'];
};

export type GenerierlaufAsyncStep = {
  designation: Scalars['String']['output'];
  receivingApplication?: Maybe<Scalars['String']['output']>;
  status: GenerierlaufAsyncStepStatusTuple;
  stepId: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export enum GenerierlaufAsyncStepStatus {
  Error = 'ERROR',
  Finished = 'FINISHED',
  NotStartet = 'NOT_STARTET',
  Pending = 'PENDING',
}

export type GenerierlaufAsyncStepStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: GenerierlaufAsyncStepStatus;
};

export enum GenerierlaufEntryExitCode {
  Fehler = 'FEHLER',
  Ok = 'OK',
  Retry = 'RETRY',
}

export type GenerierlaufEntryExitCodeListResponse = {
  data: Array<GenerierlaufEntryExitCodeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type GenerierlaufEntryExitCodeTuple = {
  text: Scalars['String']['output'];
  value: GenerierlaufEntryExitCode;
};

export enum GenerierlaufOrderBy {
  CreateTs = 'CREATE_TS',
  GenerierDatum = 'GENERIER_DATUM',
  Status = 'STATUS',
  Type = 'TYPE',
}

export type GenerierlaufPage = {
  contentList: Array<Generierlauf>;
  page: Page;
};

export type GenerierlaufPageResponse = {
  data: GenerierlaufPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum GenerierlaufStatus {
  Beendet = 'BEENDET',
  BeendetFehler = 'BEENDET_FEHLER',
  BeendetNoDataFound = 'BEENDET_NO_DATA_FOUND',
  BeendetWarning = 'BEENDET_WARNING',
  Erstellt = 'ERSTELLT',
  Gestartet = 'GESTARTET',
  Initialisiert = 'INITIALISIERT',
  RetryFehler = 'RETRY_FEHLER',
}

export type GenerierlaufStatusResponse = {
  data: Array<GenerierlaufStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type GenerierlaufStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: GenerierlaufStatus;
};

export type HeAbrAbrechnungskreis = {
  abrechnungskreisId: Scalars['String']['output'];
  abrechnungskreisType: AbrechnungskreisTypeTuple;
  bezeichnung: Scalars['String']['output'];
  heAbrechnungsdefinitionId: Scalars['String']['output'];
  heAbrechnungsdefinitionVersionId: Scalars['String']['output'];
  kontoList: Array<HeAbrKonto>;
  summe: Scalars['Float']['output'];
};

export type HeAbrAbrechnungskreisList = {
  abrechnungskreisList: Array<HeAbrAbrechnungskreis>;
  abrechnungskreisType: AbrechnungskreisTypeTuple;
  anteil: Scalars['Float']['output'];
  anteile?: Maybe<Scalars['String']['output']>;
  anteilePercent?: Maybe<Scalars['Float']['output']>;
  summe: Scalars['Float']['output'];
};

export type HeAbrBuchungszeile = {
  auftragId?: Maybe<Scalars['String']['output']>;
  belegDatum?: Maybe<Scalars['String']['output']>;
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegId: Scalars['String']['output'];
  belegnummer?: Maybe<Scalars['String']['output']>;
  betrag: Scalars['Float']['output'];
  buchungIdList: Array<Scalars['String']['output']>;
  buchungskreisRechtstraeger: RechtstraegerBase;
  dueDate?: Maybe<Scalars['String']['output']>;
  sollHaben: SollHabenTuple;
  standingInvoicePartialAmountId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  umbuchung?: Maybe<Scalars['Boolean']['output']>;
  umgebucht?: Maybe<Scalars['Boolean']['output']>;
  ustVomAufwandSteuersatz?: Maybe<Scalars['Int']['output']>;
  vertragspartner?: Maybe<RechtstraegerBase>;
};

export type HeAbrKonto = {
  amountReducingType?: Maybe<AmountReducingTypeTuple>;
  bezeichnung: Scalars['String']['output'];
  buchungszeileList: Array<HeAbrBuchungszeile>;
  kontoId: Scalars['String']['output'];
  summe: Scalars['Float']['output'];
};

export type HeAbrOffenePosten = {
  bestandseinheitList: Array<Bestandseinheit>;
  dueDate: Scalars['String']['output'];
  fibuKontoId: Scalars['String']['output'];
  fibuKontonummer: Scalars['String']['output'];
  letzteBuchungAm?: Maybe<Scalars['String']['output']>;
  offenePostenId: Scalars['String']['output'];
  offenerBetrag: Scalars['Float']['output'];
  vertragspartner: Rechtstraeger;
};

export type HeAbrOffenePostenList = {
  anteil?: Maybe<Scalars['Float']['output']>;
  offenePostenList: Array<HeAbrOffenePosten>;
  summe: Scalars['Float']['output'];
};

export type HeAbrZahlungsrueckstand = {
  bestandseinheit: BestandseinheitBase;
  konto: AppKonto;
  letzteBuchungAm?: Maybe<Scalars['String']['output']>;
  offenerBetrag: Scalars['Float']['output'];
  rechtstraeger: RechtstraegerBase;
};

export type HeAbrechnung = {
  abrechnungszeitraumBis: Scalars['String']['output'];
  abrechnungszeitraumVon: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable: Scalars['Boolean']['output'];
  errorList: Array<ErrorData>;
  heAbrechnungHeVertragList: Array<HeAbrechnungHeVertragListEntry>;
  heAbrechnungId: Scalars['ID']['output'];
  objekt: Objekt;
  rechnungsausstellerId: Scalars['String']['output'];
  status: AbrechnungStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
  zahlungsrueckstandList: Array<HeAbrZahlungsrueckstand>;
};

export type HeAbrechnungDeviationListResponse = {
  data: Array<Deviation>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeAbrechnungGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  fromInclusive: Scalars['String']['output'];
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  heAbrechnungId?: Maybe<Scalars['String']['output']>;
  status: GenerierlaufStatusTuple;
  toInclusive: Scalars['String']['output'];
  vorabrechnung?: Maybe<Scalars['Boolean']['output']>;
  warningList: Array<WarningData>;
};

export type HeAbrechnungGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  heAbrechnungDeleted: Scalars['Boolean']['output'];
  heAbrechnungDeletedBy?: Maybe<Scalars['String']['output']>;
  heAbrechnungDeletedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  heAbrechnungDeletedTs?: Maybe<Scalars['String']['output']>;
  heAbrechnungHeVertrag?: Maybe<HeAbrechnungHeVertrag>;
  infoMessage?: Maybe<Scalars['String']['output']>;
  objektKurzBezeichnung: Scalars['String']['output'];
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type HeAbrechnungGenerierlaufListEntryPage = {
  contentList: Array<HeAbrechnungGenerierlaufEntry>;
  page: Page;
};

export type HeAbrechnungGenerierlaufListEntryPageResponse = {
  data: HeAbrechnungGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum HeAbrechnungGenerierlaufOrderBy {
  Hauseigentuemer = 'HAUSEIGENTUEMER',
  Objekt = 'OBJEKT',
  Status = 'STATUS',
  Zeitraum = 'ZEITRAUM',
}

export type HeAbrechnungGenerierlaufResponse = {
  data: HeAbrechnungGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeAbrechnungHeVertrag = {
  abrechnungsdefinitionType: AbrechnungsdefinitionTypeTuple;
  abrechnungszeitraumBis: Scalars['String']['output'];
  abrechnungszeitraumVon: Scalars['String']['output'];
  anteile: Scalars['String']['output'];
  anteilePercent: Scalars['Float']['output'];
  ausgaben?: Maybe<HeAbrAbrechnungskreisList>;
  bezeichnung: Scalars['String']['output'];
  buchungskreisId: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  eigentuemerverrechnung?: Maybe<HeAbrAbrechnungskreisList>;
  einnahmen?: Maybe<HeAbrAbrechnungskreisList>;
  errorList: Array<ErrorData>;
  fileId?: Maybe<Scalars['String']['output']>;
  guthaben: Scalars['Boolean']['output'];
  hauseigentuemer: Rechtstraeger;
  heAbrechnungHeVertragId: Scalars['String']['output'];
  heVertrag: HeVertrag;
  infoAbrechnungskreisList: Array<AbrInfoAbrechnungskreis>;
  objekt: Objekt;
  offenePosten?: Maybe<HeAbrOffenePostenList>;
  saldo: Scalars['Float']['output'];
  saldovortrag: Scalars['Float']['output'];
  status: HeAbrechnungHeVertragStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vorsteuerkuerzung?: Maybe<HeAbrAbrechnungskreisList>;
  warningList: Array<ResponseDataWarning>;
};

export type HeAbrechnungHeVertragDeviationSourceEntity = AbstractDeviationSourceEntity & {
  entityId: Scalars['ID']['output'];
  ids?: Maybe<HeAbrechnungHeVertragDeviationSourceEntityIds>;
  objektBezeichnung: Scalars['String']['output'];
  type: DeviationSourceEntityTypeTuple;
  vertragsBeginn: Scalars['String']['output'];
  vertragsEnde?: Maybe<Scalars['String']['output']>;
  vertragspartnerKurzBezeichnung: Scalars['String']['output'];
};

export type HeAbrechnungHeVertragDeviationSourceEntityIds = {
  heVertragId: Scalars['String']['output'];
  linkText: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
};

export type HeAbrechnungHeVertragListEntry = {
  abrechnungsdefinitionType: AbrechnungsdefinitionTypeTuple;
  abrechnungszeitraumBis: Scalars['String']['output'];
  abrechnungszeitraumVon: Scalars['String']['output'];
  anteile: Scalars['String']['output'];
  anteilePercent: Scalars['Float']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['String']['output']>;
  guthaben: Scalars['Boolean']['output'];
  hauseigentuemer: Rechtstraeger;
  heAbrechnungHeVertragId: Scalars['ID']['output'];
  saldo: Scalars['Float']['output'];
  status: HeAbrechnungHeVertragStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type HeAbrechnungHeVertragListEntryListResponse = {
  data: Array<HeAbrechnungHeVertragListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeAbrechnungHeVertragResponse = {
  data: HeAbrechnungHeVertrag;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum HeAbrechnungHeVertragStatus {
  Invalid = 'INVALID',
  ReviewError = 'REVIEW_ERROR',
  UnderReview = 'UNDER_REVIEW',
  Valid = 'VALID',
}

export type HeAbrechnungHeVertragStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: HeAbrechnungHeVertragStatus;
};

export type HeAbrechnungListEntry = {
  abrechnungsdefinitionType: AbrechnungsdefinitionTypeTuple;
  abrechnungszeitraumBis: Scalars['String']['output'];
  abrechnungszeitraumVon: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenId: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  heAbrechnungId: Scalars['ID']['output'];
  status: AbrechnungStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type HeAbrechnungListEntryListResponse = {
  data: Array<HeAbrechnungListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeAbrechnungOutputOptions = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  displayKontonummer?: Maybe<Scalars['Boolean']['output']>;
  rueckstaendeAnzeigen: Scalars['Boolean']['output'];
  rueckstaendeInAbrechnungsergebnis: Scalars['Boolean']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type HeAbrechnungOutputOptionsInput = {
  displayKontonummer?: InputMaybe<Scalars['Boolean']['input']>;
  rueckstaendeAnzeigen: Scalars['Boolean']['input'];
  rueckstaendeInAbrechnungsergebnis: Scalars['Boolean']['input'];
};

export type HeAbrechnungResponse = {
  data: HeAbrechnung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeAbrechnungsdefinition = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreis>;
  abrechnungsdefinitionId: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  heAbrechnungTextbausteinList: Array<Textbaustein>;
  kontoAbrechnungskreisDataList: Array<KontoAbrechnungskreis>;
  status: AbrechnungsdefinitionStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type HeAbrechnungsdefinitionCreateInput = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreisInput>;
  bezeichnung: Scalars['String']['input'];
  heAbrechnungTextbausteinList: Array<TextbausteinInput>;
  outputOptions: HeAbrechnungOutputOptionsInput;
  validFrom: Scalars['String']['input'];
};

export type HeAbrechnungsdefinitionResponse = {
  data: HeAbrechnungsdefinition;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeAbrechnungsdefinitionUpdateInput = {
  bezeichnung: Scalars['String']['input'];
};

export type HeAbrechnungsdefinitionVersion = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreis>;
  abrechnungsdefinitionVersionId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  heAbrechnungTextbausteinList: Array<Textbaustein>;
  kommentar?: Maybe<Scalars['String']['output']>;
  kontoAbrechnungskreisList: Array<KontoAbrechnungskreis>;
  lastUpdateTs: Scalars['String']['output'];
  outputOptions: HeAbrechnungOutputOptions;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type HeAbrechnungsdefinitionVersionCreateInput = {
  abrechdefAbrechkreisList: Array<InputMaybe<AbrechdefAbrechkreisInput>>;
  heAbrechnungTextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  kommentar?: InputMaybe<Scalars['String']['input']>;
  outputOptions: HeAbrechnungOutputOptionsInput;
  validFrom: Scalars['String']['input'];
};

export type HeAbrechnungsdefinitionVersionListEntry = {
  abrechnungsdefinitionVersionId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  historicizedList?: Maybe<Array<HeAbrechnungsdefinitionVersionListEntry>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  textBausteineBefuellt: Scalars['Boolean']['output'];
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type HeAbrechnungsdefinitionVersionListEntryResponse = {
  data: Array<HeAbrechnungsdefinitionVersionListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeAbrechnungsdefinitionVersionResponse = {
  data: HeAbrechnungsdefinitionVersion;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeAbrechnungsdefinitionVersionUpdateInput = {
  abrechdefAbrechkreisList: Array<InputMaybe<AbrechdefAbrechkreisInput>>;
  heAbrechnungTextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  kommentar?: InputMaybe<Scalars['String']['input']>;
  kontoAbrechnungskreisList: Array<AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput>;
  outputOptions: HeAbrechnungOutputOptionsInput;
  validFrom: Scalars['String']['input'];
};

export type HeOrObjektOrSubAbrechnungGenerierlaufCreateInput = {
  abrechnungType: AbrechnungType;
  fromInclusive: Scalars['String']['input'];
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  toInclusive: Scalars['String']['input'];
  type: AbrechnungGenerierlaufType;
};

export type HeOrObjektOrSubNamedAbrechnungGenerierlaufCreateInput = {
  abrechnungType: AbrechnungType;
  bezeichnung: Scalars['String']['input'];
  fromInclusive: Scalars['String']['input'];
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  toInclusive: Scalars['String']['input'];
  type: AbrechnungGenerierlaufType;
};

export type HeVertrag = {
  anteile: Scalars['Float']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable: Scalars['Boolean']['output'];
  deliveryAddress?: Maybe<Address>;
  deliveryAddressHaupt: Scalars['Boolean']['output'];
  heVertragId: Scalars['ID']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  objektSummeAnteile: Scalars['Float']['output'];
  objektVerwaltungSeit: Scalars['String']['output'];
  postIt?: Maybe<PostIt>;
  saldouebertrag: Scalars['Float']['output'];
  tagList?: Maybe<Array<Scalars['String']['output']>>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragsBeginn: Scalars['String']['output'];
  vertragsEnde?: Maybe<Scalars['String']['output']>;
  vertragspartner: Rechtstraeger;
  warningList: Array<ResponseDataWarning>;
};

export type HeVertragAuszahlung = {
  betrag?: Maybe<Scalars['Float']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  heVertragAuszahlungId: Scalars['ID']['output'];
  heVertragZahlungsregel?: Maybe<HeVertragZahlungsregel>;
  status: HeVertragAuszahlungStatusTuple;
  type: HeVertragAuszahlungTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  verwendungszweck?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
  zahlungsdatum: Scalars['String']['output'];
  zahlungsempfaenger: Rechtstraeger;
  zahlungsempfaengerBankDetails: BankDetails;
};

export type HeVertragAuszahlungCreateInput = {
  betrag?: InputMaybe<Scalars['Float']['input']>;
  verwendungszweck?: InputMaybe<Scalars['String']['input']>;
  zahlungsdatum: Scalars['String']['input'];
  zahlungsempfaengerBankDetailsId: Scalars['String']['input'];
  zahlungsempfaengerId: Scalars['String']['input'];
  zahlungsreferenz?: InputMaybe<Scalars['String']['input']>;
};

export type HeVertragAuszahlungResponse = {
  data: HeVertragAuszahlung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum HeVertragAuszahlungStatus {
  Durchgefuehrt = 'DURCHGEFUEHRT',
  Erstellt = 'ERSTELLT',
  InPlanung = 'IN_PLANUNG',
  NichtDurchgefuehrt = 'NICHT_DURCHGEFUEHRT',
  Zahlungsvorschlag = 'ZAHLUNGSVORSCHLAG',
}

export type HeVertragAuszahlungStatusTuple = {
  text: Scalars['String']['output'];
  value: HeVertragAuszahlungStatus;
};

export enum HeVertragAuszahlungType {
  Einmalzahlung = 'EINMALZAHLUNG',
  Regelmaessig = 'REGELMAESSIG',
}

export type HeVertragAuszahlungTypeTuple = {
  text: Scalars['String']['output'];
  value: HeVertragAuszahlungType;
};

export type HeVertragBase = {
  heVertragId: Scalars['String']['output'];
  kurzBezeichnung: Scalars['String']['output'];
};

export type HeVertragBaseListResponse = {
  data: Array<HeVertragBase>;
  errorList: Array<ErrorData>;
  warningList: Array<ResponseDataWarning>;
};

export type HeVertragCreateInput = {
  anteile: Scalars['Float']['input'];
  deliveryAddressHaupt?: InputMaybe<Scalars['Boolean']['input']>;
  deliveryAddressId?: InputMaybe<Scalars['String']['input']>;
  saldouebertrag: Scalars['Float']['input'];
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  vertragsBeginn: Scalars['String']['input'];
  vertragsEnde?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId: Scalars['String']['input'];
};

export type HeVertragList = {
  errorList: Array<ErrorData>;
  heVertragList: Array<HeVertragListEntry>;
};

export type HeVertragListEntry = {
  aktivIn?: Maybe<Scalars['Int']['output']>;
  aktivInUnit?: Maybe<ChronoUnitTuple>;
  anteile: Scalars['String']['output'];
  anteilePercent: Scalars['Float']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  deliveryAddress?: Maybe<Address>;
  heVertragId: Scalars['ID']['output'];
  inaktivSeit?: Maybe<Scalars['Int']['output']>;
  inaktivSeitUnit?: Maybe<ChronoUnitTuple>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragsBeginn: Scalars['String']['output'];
  vertragsEnde?: Maybe<Scalars['String']['output']>;
  vertragspartner: Rechtstraeger;
  warningList: Array<ResponseDataWarning>;
};

export type HeVertragListResponse = {
  data: HeVertragList;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeVertragOverviewListEntry = {
  anteile: Scalars['Float']['output'];
  anteilePercent: Scalars['Float']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  heVertragId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung: Scalars['String']['output'];
  objektSummeAnteile: Scalars['Float']['output'];
  rechnungsausstellerId: Scalars['String']['output'];
  rechnungsausstellerKurzBezeichnung: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragsBeginn: Scalars['String']['output'];
  vertragsEnde?: Maybe<Scalars['String']['output']>;
  vertragspartnerId: Scalars['String']['output'];
  vertragspartnerKurzBezeichnung: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type HeVertragOverviewListEntryPage = {
  contentList: Array<HeVertragOverviewListEntry>;
  page: Page;
};

export type HeVertragOverviewListEntryPageResponse = {
  data: HeVertragOverviewListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum HeVertragOverviewOrderBy {
  Anteile = 'ANTEILE',
  Objekt = 'OBJEKT',
  ObjektSummeAnteile = 'OBJEKT_SUMME_ANTEILE',
  Rechnungsaussteller = 'RECHNUNGSAUSSTELLER',
  Vertragspartner = 'VERTRAGSPARTNER',
  VertragsBeginn = 'VERTRAGS_BEGINN',
  VertragsEnde = 'VERTRAGS_ENDE',
}

export type HeVertragResponse = {
  data: HeVertrag;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeVertragUpdateInput = {
  anteile: Scalars['Float']['input'];
  deliveryAddressHaupt?: InputMaybe<Scalars['Boolean']['input']>;
  deliveryAddressId?: InputMaybe<Scalars['String']['input']>;
  saldouebertrag: Scalars['Float']['input'];
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  vertragsBeginn: Scalars['String']['input'];
  vertragsEnde?: InputMaybe<Scalars['String']['input']>;
};

export type HeVertragZahlungsregel = {
  beendetAm?: Maybe<Scalars['String']['output']>;
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable: Scalars['Boolean']['output'];
  durchfuehrenAm: Scalars['Int']['output'];
  fixbetrag?: Maybe<Scalars['Float']['output']>;
  heAbrechnungsdefinitionId?: Maybe<Scalars['String']['output']>;
  naechsteZahlung?: Maybe<Scalars['String']['output']>;
  periode: TurnusTuple;
  prozentsatz?: Maybe<Scalars['Float']['output']>;
  status: HeVertragZahlungsregelStatusTuple;
  turnus?: Maybe<TurnusTuple>;
  type: HeVertragZahlungsregelTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  verwendungszweck?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
  zahlungsempfaenger: Rechtstraeger;
  zahlungsempfaengerBankDetails?: Maybe<BankDetails>;
  zahlungsreferenz?: Maybe<Scalars['String']['output']>;
  zahlungsregelId: Scalars['ID']['output'];
};

export type HeVertragZahlungsregelCreateInput = {
  beendetAm?: InputMaybe<Scalars['String']['input']>;
  bezeichnung: Scalars['String']['input'];
  durchfuehrenAm: Scalars['Int']['input'];
  fixbetrag?: InputMaybe<Scalars['Float']['input']>;
  periode: Turnus;
  prozentsatz?: InputMaybe<Scalars['Float']['input']>;
  turnus?: InputMaybe<Turnus>;
  type: HeVertragZahlungsregelType;
  verwendungszweck?: InputMaybe<Scalars['String']['input']>;
  zahlungsempfaengerBankDetailsId?: InputMaybe<Scalars['String']['input']>;
  zahlungsempfaengerId: Scalars['String']['input'];
  zahlungsreferenz?: InputMaybe<Scalars['String']['input']>;
};

export type HeVertragZahlungsregelListResponse = {
  data: Array<HeVertragZahlungsregel>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeVertragZahlungsregelPeriodeListResponse = {
  data: Array<TurnusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HeVertragZahlungsregelResponse = {
  data: HeVertragZahlungsregel;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum HeVertragZahlungsregelStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type HeVertragZahlungsregelStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: HeVertragZahlungsregelStatus;
};

export type HeVertragZahlungsregelSumVposNetto = {
  betrag: Scalars['Float']['output'];
  vorschreibungSumme: Scalars['Float']['output'];
};

export type HeVertragZahlungsregelSumVposNettoInput = {
  percentage: Scalars['Float']['input'];
};

export type HeVertragZahlungsregelSumVposNettoResponse = {
  data: HeVertragZahlungsregelSumVposNetto;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum HeVertragZahlungsregelType {
  Fixbetrag = 'FIXBETRAG',
  ProzentsatzGesamtVs = 'PROZENTSATZ_GESAMT_VS',
  ProzentsatzHeAbrechnung = 'PROZENTSATZ_HE_ABRECHNUNG',
}

export type HeVertragZahlungsregelTypeTuple = {
  text: Scalars['String']['output'];
  value: HeVertragZahlungsregelType;
};

export type HeVertragZahlungsregelUpdateInput = {
  beendetAm?: InputMaybe<Scalars['String']['input']>;
  bezeichnung: Scalars['String']['input'];
  durchfuehrenAm: Scalars['Int']['input'];
  fixbetrag?: InputMaybe<Scalars['Float']['input']>;
  periode: Turnus;
  prozentsatz?: InputMaybe<Scalars['Float']['input']>;
  turnus?: InputMaybe<Turnus>;
  type: HeVertragZahlungsregelType;
  verwendungszweck?: InputMaybe<Scalars['String']['input']>;
  zahlungsempfaengerBankDetailsId?: InputMaybe<Scalars['String']['input']>;
  zahlungsempfaengerId: Scalars['String']['input'];
  zahlungsreferenz?: InputMaybe<Scalars['String']['input']>;
};

export type HeVertragZahlungsverlaufListEntry = {
  accountHolder: Scalars['String']['output'];
  betrag?: Maybe<Scalars['Float']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  durchfuehrungsdatum?: Maybe<Scalars['String']['output']>;
  fibuBuchungId?: Maybe<Scalars['String']['output']>;
  heVertragAuszahlungId?: Maybe<Scalars['String']['output']>;
  heVertragZahlungsregel?: Maybe<HeVertragZahlungsregel>;
  status: HeVertragAuszahlungStatusTuple;
  type: HeVertragAuszahlungTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  verwendungszweck?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
  zahlungsdatum: Scalars['String']['output'];
  zahlungsempfaenger: Rechtstraeger;
  zahlungsempfaengerBankDetails: Scalars['String']['output'];
  zahlungsreferenz?: Maybe<Scalars['String']['output']>;
};

export type HeVertragZahlungsverlaufListEntryResponse = {
  data: Array<HeVertragZahlungsverlaufListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type History = {
  attributeList: Array<AbstractHistoryAttribute>;
  updateTs: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
};

export type HistoryAmountAttribute = AbstractHistoryAttribute & {
  name: HistoryAttributeName;
  type: HistoryAttributeType;
  value?: Maybe<Scalars['Float']['output']>;
};

export type HistoryAttributeName = {
  text: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum HistoryAttributeType {
  Amount = 'AMOUNT',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Float = 'FLOAT',
  Html = 'HTML',
  Int = 'INT',
  Percent = 'PERCENT',
  String = 'STRING',
  StringList = 'STRING_LIST',
}

export type HistoryBooleanAttribute = AbstractHistoryAttribute & {
  name: HistoryAttributeName;
  type: HistoryAttributeType;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type HistoryDateAttribute = AbstractHistoryAttribute & {
  name: HistoryAttributeName;
  type: HistoryAttributeType;
  value?: Maybe<Scalars['String']['output']>;
};

export type HistoryFloatAttribute = AbstractHistoryAttribute & {
  name: HistoryAttributeName;
  scaling?: Maybe<Scalars['Int']['output']>;
  type: HistoryAttributeType;
  value?: Maybe<Scalars['Float']['output']>;
};

export type HistoryHtmlAttribute = AbstractHistoryAttribute & {
  name: HistoryAttributeName;
  type: HistoryAttributeType;
  value?: Maybe<Scalars['String']['output']>;
};

export type HistoryIntAttribute = AbstractHistoryAttribute & {
  name: HistoryAttributeName;
  type: HistoryAttributeType;
  value?: Maybe<Scalars['Int']['output']>;
};

export type HistoryListResponse = {
  data: Array<History>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HistoryPercentAttribute = AbstractHistoryAttribute & {
  name: HistoryAttributeName;
  scaling?: Maybe<Scalars['Int']['output']>;
  type: HistoryAttributeType;
  value?: Maybe<Scalars['Float']['output']>;
};

export type HistoryStringAttribute = AbstractHistoryAttribute & {
  name: HistoryAttributeName;
  type: HistoryAttributeType;
  value?: Maybe<Scalars['String']['output']>;
};

export type HistoryStringListAttribute = AbstractHistoryAttribute & {
  longText: Scalars['Boolean']['output'];
  name: HistoryAttributeName;
  type: HistoryAttributeType;
  value?: Maybe<Array<Scalars['String']['output']>>;
};

export type IbanResponse = {
  data?: Maybe<IbanValidation>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type IbanValidation = {
  bankname?: Maybe<Scalars['String']['output']>;
  bic?: Maybe<Scalars['String']['output']>;
  valid: Scalars['Boolean']['output'];
};

export enum IbanFibuKontoOrderBy {
  AccountHolder = 'ACCOUNT_HOLDER',
  Bankname = 'BANKNAME',
  Bic = 'BIC',
  CreateTs = 'CREATE_TS',
  FibuAccountNumber = 'FIBU_ACCOUNT_NUMBER',
  Iban = 'IBAN',
  LastBankStatement = 'LAST_BANK_STATEMENT',
  PaymentTransactionType = 'PAYMENT_TRANSACTION_TYPE',
}

export type IbanListEntry = {
  accountHolder: Scalars['String']['output'];
  bankDetailsList: Array<PaymentBankDetails>;
  bankname: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  createTs?: Maybe<Scalars['String']['output']>;
  dataCarrierId?: Maybe<Scalars['String']['output']>;
  ebicsUserList: Array<Scalars['String']['output']>;
  fibuAccountNumber: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  lastBankStatementCreatedOn?: Maybe<Scalars['String']['output']>;
  paymentTransactionType: PaymentTransactionTypeTuple;
  statementNumber?: Maybe<Scalars['String']['output']>;
};

export type IbanListEntryPage = {
  contentList: Array<IbanListEntry>;
  page: Page;
};

export type IbanListEntryPageResponse = {
  data: IbanListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type IncomingInvoice = SingleInvoice | StandingInvoice;

export enum IncomingInvoiceBasis {
  Brutto = 'BRUTTO',
  Netto = 'NETTO',
}

export type IncomingInvoiceBasisTuple = {
  text: Scalars['String']['output'];
  value: IncomingInvoiceBasis;
};

export type IncomingInvoiceBookingCreationGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type IncomingInvoiceBookingCreationGenerierlaufCreateInput = {
  billingType?: InputMaybe<Verrechnungsart>;
  bruttoFrom?: InputMaybe<Scalars['Float']['input']>;
  bruttoTo?: InputMaybe<Scalars['Float']['input']>;
  buchungsdatumFrom?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumTo?: InputMaybe<Scalars['String']['input']>;
  buchungstext?: InputMaybe<Scalars['String']['input']>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  generierDatum: Scalars['String']['input'];
  invoiceDateFrom?: InputMaybe<Scalars['String']['input']>;
  invoiceDateTo?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  issuerBankDetailsIban?: InputMaybe<Scalars['String']['input']>;
  issuerId?: InputMaybe<Scalars['String']['input']>;
  kontoId?: InputMaybe<Scalars['String']['input']>;
  kundenNummer?: InputMaybe<Scalars['String']['input']>;
  nettoFrom?: InputMaybe<Scalars['Float']['input']>;
  nettoTo?: InputMaybe<Scalars['Float']['input']>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  payeeBankDetailsIban?: InputMaybe<Scalars['String']['input']>;
  payeeId?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  sepaMandatReference?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IncomingInvoiceStatus>;
  vatFrom?: InputMaybe<Scalars['Float']['input']>;
  vatTo?: InputMaybe<Scalars['Float']['input']>;
};

export enum IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute {
  BillingType = 'BILLING_TYPE',
  Brutto = 'BRUTTO',
  BuchungsDatum = 'BUCHUNGS_DATUM',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  DueDate = 'DUE_DATE',
  InvoiceDate = 'INVOICE_DATE',
  InvoiceNumber = 'INVOICE_NUMBER',
  InvoiceStatus = 'INVOICE_STATUS',
  Issuer = 'ISSUER',
  IssuerBankDetailsIban = 'ISSUER_BANK_DETAILS_IBAN',
  KundenNummer = 'KUNDEN_NUMMER',
  Netto = 'NETTO',
  Objekt = 'OBJEKT',
  Payee = 'PAYEE',
  PayeeBankDetailsIban = 'PAYEE_BANK_DETAILS_IBAN',
  PaymentMethod = 'PAYMENT_METHOD',
  RechnungsDatum = 'RECHNUNGS_DATUM',
  RechnungsNummer = 'RECHNUNGS_NUMMER',
  SepaMandateReference = 'SEPA_MANDATE_REFERENCE',
  Status = 'STATUS',
  Vat = 'VAT',
}

export type IncomingInvoiceBookingCreationGenerierlaufListEntry = {
  belegId: Scalars['String']['output'];
  billingType: VerrechnungsartTuple;
  brutto: Scalars['Float']['output'];
  buchungIdList: Array<Scalars['String']['output']>;
  buchungsanweisungStatus?: Maybe<OrderBuchungsanweisungStatusTuple>;
  buchungsdatum: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdByMitarbeiterId: Scalars['String']['output'];
  currentStep?: Maybe<Scalars['Int']['output']>;
  dueDate: Scalars['String']['output'];
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  fileId: Scalars['String']['output'];
  generierlaufEntryId: Scalars['String']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  issuerBankDetailsIban: Scalars['String']['output'];
  issuerBankDetailsId: Scalars['String']['output'];
  issuerName: Scalars['String']['output'];
  issuerRechtstraegerId: Scalars['String']['output'];
  kundenNummer?: Maybe<Scalars['String']['output']>;
  netto: Scalars['Float']['output'];
  objektBezeichnung?: Maybe<Scalars['String']['output']>;
  objektId?: Maybe<Scalars['String']['output']>;
  partialAmountName?: Maybe<Scalars['String']['output']>;
  payeeName: Scalars['String']['output'];
  payeeRechtstraegerId: Scalars['String']['output'];
  paymentMethod: PaymentMethodTuple;
  paymentReferenceText?: Maybe<Scalars['String']['output']>;
  purposeOfUseText?: Maybe<Scalars['String']['output']>;
  rechnungsDatum: Scalars['String']['output'];
  rechnungsNummer: Scalars['String']['output'];
  recipientBankDetailsIban: Scalars['String']['output'];
  recipientBankDetailsId: Scalars['String']['output'];
  retry: Scalars['Boolean']['output'];
  sepaMandatReference?: Maybe<Scalars['String']['output']>;
  status: IncomingInvoiceStatusTuple;
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  type: InvoiceType;
  vat: Scalars['Float']['output'];
  warningList: Array<WarningData>;
};

export type IncomingInvoiceBookingCreationGenerierlaufListEntryPage = {
  contentList: Array<IncomingInvoiceBookingCreationGenerierlaufListEntry>;
  page: Page;
};

export type IncomingInvoiceBookingCreationGenerierlaufListEntryPageResponse = {
  data: IncomingInvoiceBookingCreationGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type IncomingInvoiceBookingCreationGenerierlaufResponse = {
  data: IncomingInvoiceBookingCreationGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type IncomingInvoiceBookingPositionListEntry = {
  bestandseinheitId?: Maybe<Scalars['String']['output']>;
  bestandseinheitKurzBezeichnung?: Maybe<Scalars['String']['output']>;
  betrag: Scalars['Float']['output'];
  buchungstext: Scalars['String']['output'];
  kontoBezeichnung: Scalars['String']['output'];
  kontoId: Scalars['String']['output'];
  kontoNummer: Scalars['String']['output'];
  steuersatz: Scalars['Int']['output'];
};

export type IncomingInvoiceBuchungPosition = {
  bestandseinheitBezeichnung?: Maybe<Scalars['String']['output']>;
  bestandseinheitId?: Maybe<Scalars['String']['output']>;
  betrag: Scalars['Float']['output'];
  buchungstext: Scalars['String']['output'];
  incomingInvoiceBuchungPositionId: Scalars['ID']['output'];
  kontoBezeichnung: Scalars['String']['output'];
  kontoId: Scalars['String']['output'];
  kontoNummer: Scalars['String']['output'];
  steuersatz: Scalars['Int']['output'];
};

export type IncomingInvoiceBuchungPositionCreateInput = {
  bestandseinheitId?: InputMaybe<Scalars['String']['input']>;
  betrag: Scalars['Float']['input'];
  buchungstext: Scalars['String']['input'];
  kontoId: Scalars['String']['input'];
  steuersatz: Scalars['Int']['input'];
};

export type IncomingInvoiceBuchungPositionUpdateInput = {
  bestandseinheitId?: InputMaybe<Scalars['String']['input']>;
  betrag: Scalars['Float']['input'];
  buchungstext: Scalars['String']['input'];
  incomingInvoiceBuchungPositionId?: InputMaybe<Scalars['String']['input']>;
  kontoId: Scalars['String']['input'];
  steuersatz: Scalars['Int']['input'];
};

export enum IncomingInvoiceFilterStatus {
  Bezahlt = 'BEZAHLT',
  Erstellt = 'ERSTELLT',
  Freigegeben = 'FREIGEGEBEN',
  Uebertragen = 'UEBERTRAGEN',
  UebertragenFehler = 'UEBERTRAGEN_FEHLER',
  UmbuchungFreigegeben = 'UMBUCHUNG_FREIGEGEBEN',
}

export type IncomingInvoiceListEntry = {
  belegId: Scalars['String']['output'];
  billingType: VerrechnungsartTuple;
  brutto?: Maybe<Scalars['Float']['output']>;
  buchungIdList: Array<Scalars['String']['output']>;
  buchungsanweisungStatus?: Maybe<OrderBuchungsanweisungStatusTuple>;
  buchungskreisId?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  fileId: Scalars['String']['output'];
  issuerBankDetailsIban: Scalars['String']['output'];
  issuerBankDetailsId: Scalars['String']['output'];
  issuerName: Scalars['String']['output'];
  issuerRechtstraegerId: Scalars['String']['output'];
  kundenNummer?: Maybe<Scalars['String']['output']>;
  netto?: Maybe<Scalars['Float']['output']>;
  objektBezeichnung?: Maybe<Scalars['String']['output']>;
  objektId?: Maybe<Scalars['String']['output']>;
  offenerBetrag?: Maybe<Scalars['Float']['output']>;
  paid: Scalars['Boolean']['output'];
  partialAmountList: Array<IncomingInvoicePartialAmountListEntry>;
  payeeBankDetailsIban: Scalars['String']['output'];
  payeeBankDetailsId: Scalars['String']['output'];
  payeeName: Scalars['String']['output'];
  payeeRechtstraegerId: Scalars['String']['output'];
  paymentCreatable: Scalars['Boolean']['output'];
  paymentIdList: Array<Scalars['String']['output']>;
  paymentMethod: PaymentMethodTuple;
  paymentReferenceText?: Maybe<Scalars['String']['output']>;
  purposeOfUseText?: Maybe<Scalars['String']['output']>;
  rechnungsDatum: Scalars['String']['output'];
  rechnungsNummer: Scalars['String']['output'];
  sepaMandatReference?: Maybe<Scalars['String']['output']>;
  status: IncomingInvoiceStatusTuple;
  vat?: Maybe<Scalars['Float']['output']>;
  warningList: Array<WarningData>;
};

export type IncomingInvoiceListEntryPage = {
  contentList: Array<IncomingInvoiceListEntry>;
  page: Page;
};

export type IncomingInvoiceListEntryPageResponse = {
  data: IncomingInvoiceListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type IncomingInvoiceListSummary = {
  brutto: Scalars['Float']['output'];
  netto: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type IncomingInvoiceListSummaryResponse = {
  data: IncomingInvoiceListSummary;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum IncomingInvoiceOrderAttribute {
  BillingType = 'BILLING_TYPE',
  Brutto = 'BRUTTO',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  Issuer = 'ISSUER',
  IssuerBankDetailsIban = 'ISSUER_BANK_DETAILS_IBAN',
  KundenNummer = 'KUNDEN_NUMMER',
  Netto = 'NETTO',
  Objekt = 'OBJEKT',
  Payee = 'PAYEE',
  PayeeBankDetailsIban = 'PAYEE_BANK_DETAILS_IBAN',
  PaymentMethod = 'PAYMENT_METHOD',
  PaymentReferenceText = 'PAYMENT_REFERENCE_TEXT',
  PurposeOfUseText = 'PURPOSE_OF_USE_TEXT',
  RechnungsDatum = 'RECHNUNGS_DATUM',
  RechnungsNummer = 'RECHNUNGS_NUMMER',
  SepaMandateReference = 'SEPA_MANDATE_REFERENCE',
  Status = 'STATUS',
  Vat = 'VAT',
}

export type IncomingInvoicePartialAmountListEntry = {
  abgrenzungsBuchung?: Maybe<Scalars['Boolean']['output']>;
  abgrenzungsBuchungsdatum?: Maybe<Scalars['String']['output']>;
  bookingPositionList: Array<IncomingInvoiceBookingPositionListEntry>;
  brutto: Scalars['Float']['output'];
  buchungsdatum?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['String']['output'];
  name: Scalars['String']['output'];
  netto: Scalars['Float']['output'];
  offenerBetrag: Scalars['Float']['output'];
  paid: Scalars['Boolean']['output'];
  paymentCreatable: Scalars['Boolean']['output'];
  requestedPaymentDate?: Maybe<Scalars['String']['output']>;
  standingInvoicePartialAmountId?: Maybe<Scalars['String']['output']>;
  vat: Scalars['Float']['output'];
};

export type IncomingInvoiceResponse = {
  data: IncomingInvoice;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum IncomingInvoiceStatus {
  Erstellt = 'ERSTELLT',
  Freigegeben = 'FREIGEGEBEN',
  Uebertragen = 'UEBERTRAGEN',
  UebertragenFehler = 'UEBERTRAGEN_FEHLER',
  UmbuchungFreigegeben = 'UMBUCHUNG_FREIGEGEBEN',
}

export type IncomingInvoiceStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: IncomingInvoiceStatus;
};

export type IncomingInvoiceSteuerzeile = {
  betrag: Scalars['Float']['output'];
  steuersatz: Scalars['Int']['output'];
};

export type IncomingInvoiceSteuerzeileInput = {
  betrag: Scalars['Float']['input'];
  steuersatz: Scalars['Int']['input'];
};

export type IncomingInvoiceUpdateStornoAbgrenzungInput = {
  korrekturAbgrenzungsBuchungsdatum: Scalars['String']['input'];
  korrekturBuchungsdatum: Scalars['String']['input'];
  stornoAbgrenzungsBuchungsdatum: Scalars['String']['input'];
  stornoBuchungsdatum: Scalars['String']['input'];
};

export type IncomingInvoiceUpdateStornoDirektInput = {
  korrekturBuchungsdatum: Scalars['String']['input'];
  stornoBuchungsdatum: Scalars['String']['input'];
};

export type IncomingInvoiceUpdateStornoInput = {
  abgrenzungsBuchung: Scalars['Boolean']['input'];
  stornoAbgrenzung?: InputMaybe<IncomingInvoiceUpdateStornoAbgrenzungInput>;
  stornoDirekt?: InputMaybe<IncomingInvoiceUpdateStornoDirektInput>;
};

export type IndexPoint = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  finalValue?: Maybe<Scalars['Float']['output']>;
  indexPointId: Scalars['ID']['output'];
  month: Scalars['String']['output'];
  preliminaryValue?: Maybe<Scalars['Float']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type IndexPointCreateInput = {
  finalValue?: InputMaybe<Scalars['Float']['input']>;
  month: Month;
  preliminaryValue?: InputMaybe<Scalars['Float']['input']>;
};

export type IndexPointUpdateInput = {
  finalValue?: InputMaybe<Scalars['Float']['input']>;
  month: Month;
  preliminaryValue?: InputMaybe<Scalars['Float']['input']>;
};

export type IndexSeries = {
  chainingFactor?: Maybe<Scalars['Float']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  csvColumnImportId?: Maybe<Scalars['String']['output']>;
  csvColumnIndexPoint?: Maybe<Scalars['String']['output']>;
  csvColumnMonthYear?: Maybe<Scalars['String']['output']>;
  csvRowImportId?: Maybe<Scalars['String']['output']>;
  deletable: Scalars['Boolean']['output'];
  firmendatenId?: Maybe<Scalars['String']['output']>;
  importUrl?: Maybe<Scalars['String']['output']>;
  indexSeriesEntryList: Array<IndexSeriesEntry>;
  indexSeriesId: Scalars['ID']['output'];
  indexType: IndexTypeTuple;
  name: Scalars['String']['output'];
  sourceType: IndexSeriesSourceTypeTuple;
  status?: Maybe<IndexSeriesStatusTuple>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type IndexSeriesCreateInput = {
  chainingFactor?: InputMaybe<Scalars['Float']['input']>;
  csvColumnImportId?: InputMaybe<Scalars['String']['input']>;
  csvColumnIndexPoint?: InputMaybe<Scalars['String']['input']>;
  csvColumnMonthYear?: InputMaybe<Scalars['String']['input']>;
  csvRowImportId?: InputMaybe<Scalars['String']['input']>;
  importUrl?: InputMaybe<Scalars['String']['input']>;
  indexType: IndexType;
  name: Scalars['String']['input'];
  sourceType: IndexSeriesSourceType;
};

export type IndexSeriesEntry = {
  annualAverageFinal?: Maybe<Scalars['Float']['output']>;
  annualAveragePreliminary?: Maybe<Scalars['Float']['output']>;
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  indexPointList: Array<IndexPoint>;
  indexSeriesEntryId: Scalars['ID']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
  year: Scalars['Int']['output'];
};

export type IndexSeriesEntryCreateInput = {
  indexPointList: Array<IndexPointCreateInput>;
  year: Scalars['Int']['input'];
};

export type IndexSeriesEntryListResponse = {
  data: Array<IndexSeriesEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type IndexSeriesEntryResponse = {
  data: IndexSeriesEntry;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type IndexSeriesEntryUpdateInput = {
  indexPointList: Array<IndexPointUpdateInput>;
  year: Scalars['Int']['input'];
};

export type IndexSeriesListResponse = {
  data: Array<IndexSeries>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type IndexSeriesPeriodInput = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type IndexSeriesResponse = {
  data: IndexSeries;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum IndexSeriesSourceType {
  Import = 'IMPORT',
  Manual = 'MANUAL',
}

export type IndexSeriesSourceTypeTuple = {
  text: Scalars['String']['output'];
  value: IndexSeriesSourceType;
};

export enum IndexSeriesStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type IndexSeriesStatusTuple = {
  text: Scalars['String']['output'];
  value: IndexSeriesStatus;
};

export type IndexSeriesUpdateInput = {
  chainingFactor?: InputMaybe<Scalars['Float']['input']>;
  csvColumnImportId?: InputMaybe<Scalars['String']['input']>;
  csvColumnIndexPoint?: InputMaybe<Scalars['String']['input']>;
  csvColumnMonthYear?: InputMaybe<Scalars['String']['input']>;
  csvRowImportId?: InputMaybe<Scalars['String']['input']>;
  importUrl?: InputMaybe<Scalars['String']['input']>;
  indexType: IndexType;
  name: Scalars['String']['input'];
  sourceType: IndexSeriesSourceType;
};

export enum IndexType {
  Category = 'CATEGORY',
  Index = 'INDEX',
  ReferenceValue = 'REFERENCE_VALUE',
}

export type IndexTypeTuple = {
  text: Scalars['String']['output'];
  value: IndexType;
};

export type IndexValueCreationCronJobSettings = {
  indexAnnual: Scalars['Boolean']['output'];
  indexThreshold: Scalars['Boolean']['output'];
};

export type IndexValueCreationCronJobSettingsResponse = {
  data: IndexValueCreationCronJobSettings;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type IndexValueCreationCronJobSettingsUpdateInput = {
  indexAnnual: Scalars['Boolean']['input'];
  indexThreshold: Scalars['Boolean']['input'];
};

export enum InfoFeldStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type InfoFeldStatusTuple = {
  text: Scalars['String']['output'];
  value: InfoFeldStatus;
};

export type InfoMailDelivery = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  emailDeliveryDefinitionBezeichnung: Scalars['String']['output'];
  emailDeliveryDefinitionId: Scalars['String']['output'];
  infoMailDeliveryId: Scalars['String']['output'];
  mailId?: Maybe<Scalars['String']['output']>;
  objektTopVertrag: ObjektTopVertrag;
  original: Scalars['Boolean']['output'];
  recipient: RechtstraegerBase;
  resentDeliveryList?: Maybe<Array<InfoMailDelivery>>;
  sender?: Maybe<Scalars['String']['output']>;
  sentTs?: Maybe<Scalars['String']['output']>;
  shouldSend: Scalars['Boolean']['output'];
  sourceDelivery?: Maybe<InfoMailDelivery>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragspartner: RechtstraegerBase;
  warningList: Array<ResponseDataWarning>;
};

export type InfoMailDeliveryDetermineRecipientListGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type InfoMailDeliveryDetermineRecipientListGenerierlaufEntry = {
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['String']['output'];
  infoMailDelivery?: Maybe<InfoMailDelivery>;
  infoMessage?: Maybe<Scalars['String']['output']>;
  warningList: Array<WarningData>;
};

export type InfoMailDeliveryDetermineRecipientListGenerierlaufListEntryPage = {
  contentList: Array<InfoMailDeliveryDetermineRecipientListGenerierlaufEntry>;
  page: Page;
};

export type InfoMailDeliveryDetermineRecipientListGenerierlaufListEntryPageResponse = {
  data: InfoMailDeliveryDetermineRecipientListGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum InfoMailDeliveryDetermineRecipientListGenerierlaufOrderBy {
  Email = 'EMAIL',
  Empfaenger = 'EMPFAENGER',
  Objekt = 'OBJEKT',
  Status = 'STATUS',
  Vertragspartner = 'VERTRAGSPARTNER',
}

export type InfoMailDeliveryDetermineRecipientListGenerierlaufResponse = {
  data: InfoMailDeliveryDetermineRecipientListGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type InfoMailDeliveryPage = {
  contentList: Array<InfoMailDelivery>;
  page: Page;
};

export type InfoMailDeliveryPageResponse = {
  data: InfoMailDeliveryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum InfoMailDeliveryRecipientListStepOrderBy {
  Email = 'EMAIL',
  ObjektTop = 'OBJEKT_TOP',
  OriginalKopie = 'ORIGINAL_KOPIE',
  Recipient = 'RECIPIENT',
  Vertragspartner = 'VERTRAGSPARTNER',
}

export type InfoMailDeliveryResponse = {
  data: InfoMailDelivery;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type InfoMailDeliverySendGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type InfoMailDeliverySendGenerierlaufEntry = {
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMailDelivery?: Maybe<InfoMailDelivery>;
  infoMessage?: Maybe<Scalars['String']['output']>;
  warningList: Array<WarningData>;
};

export type InfoMailDeliverySendGenerierlaufListEntryPage = {
  contentList: Array<InfoMailDeliverySendGenerierlaufEntry>;
  page: Page;
};

export type InfoMailDeliverySendGenerierlaufListEntryPageResponse = {
  data: InfoMailDeliverySendGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum InfoMailDeliverySendGenerierlaufOrderBy {
  Email = 'EMAIL',
  Empfaenger = 'EMPFAENGER',
  Objekt = 'OBJEKT',
  Status = 'STATUS',
  Vertragspartner = 'VERTRAGSPARTNER',
}

export type InfoMailDeliverySendGenerierlaufResponse = {
  data: InfoMailDeliverySendGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type InfoMailDeliveryWizardAttachmentListResponse = {
  data: Array<WizardFileInfo>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type InfoMailDeliveryWizardCreateInput = {
  body?: InputMaybe<TextbausteinInput>;
  emailDeliveryDefinitionId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  startNextStep?: InputMaybe<Scalars['Boolean']['input']>;
  subject: TextbausteinInput;
};

export type InfoMailDeliveryWizardDetermineRecipientListStep = {
  completed: Scalars['Boolean']['output'];
  currentStep: Scalars['Int']['output'];
  generierlaufId: Scalars['String']['output'];
  stepCount: Scalars['Int']['output'];
  wizardId: Scalars['String']['output'];
};

export type InfoMailDeliveryWizardDetermineRecipientListStepResponse = {
  data: InfoMailDeliveryWizardDetermineRecipientListStep;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type InfoMailDeliveryWizardRecipientListStep = {
  completed: Scalars['Boolean']['output'];
  currentStep: Scalars['Int']['output'];
  sendGenerierlaufId?: Maybe<Scalars['String']['output']>;
  stepCount: Scalars['Int']['output'];
  wizardId: Scalars['String']['output'];
};

export type InfoMailDeliveryWizardRecipientListStepActionResponse = {
  data?: Maybe<InfoMailDeliveryWizardRecipientListStep>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type InfoMailDeliveryWizardRecipientListStepResponse = {
  data: InfoMailDeliveryWizardRecipientListStep;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type InfoMailDeliveryWizardTemplateStep = {
  attachmentInfoList: Array<WizardFileInfo>;
  body?: Maybe<Textbaustein>;
  completed: Scalars['Boolean']['output'];
  currentStep: Scalars['Int']['output'];
  emailDeliveryDefinitionBezeichnung: Scalars['String']['output'];
  emailDeliveryDefinitionId: Scalars['String']['output'];
  stepCount: Scalars['Int']['output'];
  subject: Textbaustein;
  wizardId: Scalars['String']['output'];
};

export type InfoMailDeliveryWizardTemplateStepResponse = {
  data: InfoMailDeliveryWizardTemplateStep;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type IntResponse = {
  data?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum InvoiceType {
  SingleInvoice = 'SINGLE_INVOICE',
  StandingInvoice = 'STANDING_INVOICE',
}

export type Kontenplan = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  hauptUmsatzsteuerKonto?: Maybe<Konto>;
  hauptVorsteuerKonto?: Maybe<Konto>;
  kontenplanId: Scalars['ID']['output'];
};

export type KontenplanResponse = {
  data: Kontenplan;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KontenrahmenResponse = {
  data: Kontenplan;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Kontierungstabelle = {
  basiskonto: Scalars['Int']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  errechnetesErloesKontoList: Array<ErrechnetesErloesKonto>;
  firmendatenId?: Maybe<Scalars['ID']['output']>;
  kontierungstabelleId: Scalars['ID']['output'];
  status: KontierungstabelleStatusTuple;
  subAdministrationExpenseAccount?: Maybe<AppKonto>;
  updatedByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type KontierungstabelleBase = {
  basiskonto: Scalars['Int']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  kontierungstabelleId: Scalars['ID']['output'];
  status: KontierungstabelleStatusTuple;
  subAdministrationExpenseAccount?: Maybe<AppKonto>;
  updatedByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type KontierungstabelleCreateInput = {
  basiskonto: Scalars['Int']['input'];
  bezeichnung: Scalars['String']['input'];
  subAdministrationExpenseAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type KontierungstabelleListResponse = {
  data: Array<Kontierungstabelle>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KontierungstabelleResponse = {
  data: Kontierungstabelle;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum KontierungstabelleStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type KontierungstabelleStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: KontierungstabelleStatus;
};

export type KontierungstabelleTemplateListResponse = {
  data: Array<Kontierungstabelle>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KontierungstabelleTemplateResponse = {
  data: Kontierungstabelle;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KontierungstabelleUpdateInput = {
  basiskonto: Scalars['Int']['input'];
  bezeichnung: Scalars['String']['input'];
  subAdministrationExpenseAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type Konto = {
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  haupt: Scalars['Boolean']['output'];
  klasse: KontoKlasseTuple;
  kontoId: Scalars['ID']['output'];
  kontoTemplateId?: Maybe<Scalars['String']['output']>;
  nummer: Scalars['String']['output'];
  status: KontoStatusTuple;
  tagList: Array<Scalars['String']['output']>;
  type: KontoTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  verwendung?: Maybe<KontoVerwendungTuple>;
};

export type KontoAbrechnungskreis = {
  abrechnen: Scalars['Boolean']['output'];
  abrechnungskreis?: Maybe<Abrechnungskreis>;
  aufteilungsschluessel?: Maybe<Aufteilungsschluessel>;
  buchungszeilenAnzeigen: Scalars['Boolean']['output'];
  expenseReducing: Scalars['Boolean']['output'];
  konto: AppKonto;
  ustVomAufwand: Scalars['Boolean']['output'];
};

export type KontoAbrechnungskreisInput = {
  abrechnungsdefinitionId: Scalars['String']['input'];
  abrechnungsdefinitionVersionId: Scalars['String']['input'];
  abrechnungskreisId: Scalars['String']['input'];
  buchungszeilenAnzeigen?: InputMaybe<Scalars['Boolean']['input']>;
  kontoId: Scalars['String']['input'];
  ustVomAufwand?: InputMaybe<Scalars['Boolean']['input']>;
};

export type KontoAbrechnungskreisListResponse = {
  data: Array<AbrechnungsdefinitionKontoAbrechnungskreis>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum KontoActionType {
  Archivieren = 'ARCHIVIEREN',
  Reaktivieren = 'REAKTIVIEREN',
  SetKontoTagList = 'SET_KONTO_TAG_LIST',
}

export type KontoCreateInput = {
  bezeichnung: Scalars['String']['input'];
  klasse: KontoKlasse;
  nummer: Scalars['String']['input'];
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  type: KontoType;
  verwendung?: InputMaybe<KontoVerwendung>;
};

export type KontoKey = {
  klasse: KontoKlasseTuple;
  nummer: Scalars['String']['output'];
};

export enum KontoKlasse {
  AnlageVermoegen = 'ANLAGE_VERMOEGEN',
  BetrieblicherAufwand = 'BETRIEBLICHER_AUFWAND',
  BetrieblicherErloes = 'BETRIEBLICHER_ERLOES',
  Eigenkapital = 'EIGENKAPITAL',
  FinanzAufwand = 'FINANZ_AUFWAND',
  FinanzErloes = 'FINANZ_ERLOES',
  HandelswarenEinsatz = 'HANDELSWAREN_EINSATZ',
  HandelswarenVorrat = 'HANDELSWAREN_VORRAT',
  Lieferverbindlichkeit = 'LIEFERVERBINDLICHKEIT',
  PersonalAufwand = 'PERSONAL_AUFWAND',
  UmlaufVermoegen = 'UMLAUF_VERMOEGEN',
}

export type KontoKlasseListResponse = {
  data: Array<KontoKlasseTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KontoKlasseTuple = {
  text: Scalars['String']['output'];
  value: KontoKlasse;
};

export type KontoListResponse = {
  data: Array<Konto>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum KontoOrderBy {
  Bezeichnung = 'BEZEICHNUNG',
  CreateTs = 'CREATE_TS',
  Klasse = 'KLASSE',
  Nummer = 'NUMMER',
  Status = 'STATUS',
  Type = 'TYPE',
  Verwendung = 'VERWENDUNG',
}

export type KontoPage = {
  contentList: Array<Konto>;
  page: Page;
};

export type KontoPageResponse = {
  data: KontoPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KontoResponse = {
  data: Konto;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum KontoStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type KontoStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: KontoStatus;
};

export type KontoTagListInput = {
  haupt?: InputMaybe<Scalars['Boolean']['input']>;
  tagList: Array<InputMaybe<Scalars['String']['input']>>;
};

export enum KontoType {
  Personenkonto = 'PERSONENKONTO',
  Sachkonto = 'SACHKONTO',
}

export type KontoTypeListResponse = {
  data: Array<KontoTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KontoTypeTuple = {
  text: Scalars['String']['output'];
  value: KontoType;
};

export type KontoUpdateInput = {
  bezeichnung: Scalars['String']['input'];
  klasse: KontoKlasse;
  nummer: Scalars['String']['input'];
  type: KontoType;
  verwendung?: InputMaybe<KontoVerwendung>;
};

export enum KontoVerwendung {
  AllgemeineForderung = 'ALLGEMEINE_FORDERUNG',
  Bank = 'BANK',
  Debitor = 'DEBITOR',
  Kassa = 'KASSA',
  Kreditor = 'KREDITOR',
  Umsatzsteuer = 'UMSATZSTEUER',
  Vorsteuer = 'VORSTEUER',
}

export type KontoVerwendungListResponse = {
  data: Array<KontoVerwendungTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KontoVerwendungTuple = {
  text: Scalars['String']['output'];
  value: KontoVerwendung;
};

export enum KuendigungDurch {
  Bestandgeber = 'BESTANDGEBER',
  Bestandnehmer = 'BESTANDNEHMER',
  Einvernehmlich = 'EINVERNEHMLICH',
}

export type KuendigungDurchListResponse = {
  data: Array<KuendigungDurchTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KuendigungDurchTuple = {
  text: Scalars['String']['output'];
  value: KuendigungDurch;
};

export enum Kuendigungsfrist {
  Jahre = 'JAHRE',
  Monate = 'MONATE',
  Tage = 'TAGE',
  Wochen = 'WOCHEN',
}

export enum KuendigungsfristJeweilsZum {
  Monatserster = 'MONATSERSTER',
  Monatsletzter = 'MONATSLETZTER',
}

export type KuendigungsfristJeweilsZumListResponse = {
  data: Array<KuendigungsfristJeweilsZumTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KuendigungsfristJeweilsZumTuple = {
  text: Scalars['String']['output'];
  value: KuendigungsfristJeweilsZum;
};

export type KuendigungsfristListResponse = {
  data: Array<KuendigungsfristTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KuendigungsfristTuple = {
  text: Scalars['String']['output'];
  value: Kuendigungsfrist;
};

export type KundenSystem = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  erzeugtVonFirma: FirmaForKs;
  firmendatenList: Array<FirmaForKs>;
  kundenSystemId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: KundensystemStatusTuple;
  warningList: Array<ResponseDataWarning>;
};

export type KundenSystemEinstellungen = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  salutationList: Array<SalutationTuple>;
};

export type KundenSystemEinstellungenResponse = {
  data: KundenSystemEinstellungen;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KundenSystemListResponse = {
  data: Array<KundenSystem>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KundenSystemResponse = {
  data: KundenSystem;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum KundensystemStatus {
  Aktiv = 'AKTIV',
  Geloescht = 'GELOESCHT',
}

export type KundensystemStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: MitarbeiterStatus;
};

export type KurzbezeichnungInput = {
  name1?: InputMaybe<Scalars['String']['input']>;
  name2?: InputMaybe<Scalars['String']['input']>;
  name3?: InputMaybe<Scalars['String']['input']>;
};

export type Label = {
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LabelData = {
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LabelInput = {
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum LegalForm {
  Ag = 'AG',
  Einzelunternehmen = 'EINZELUNTERNEHMEN',
  Finanzamt = 'FINANZAMT',
  GesBR = 'GES_B_R',
  Gmbh = 'GMBH',
  GmbhCoKg = 'GMBH_CO_KG',
  Kg = 'KG',
  KoerperschOeffentlRechts = 'KOERPERSCH_OEFFENTL_RECHTS',
  Og = 'OG',
}

export type LegalFormListResponse = {
  data: Array<LegalFormTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type LegalFormTuple = {
  text: Scalars['String']['output'];
  value: LegalForm;
};

export type LocalDateRechtstraegerBaseTuple = {
  date: Scalars['String']['output'];
  rechtstraeger: RechtstraegerBase;
};

export type MahnPosition = {
  belegDatum: Scalars['String']['output'];
  belegFileId: Scalars['String']['output'];
  belegSymbol: FibuBelegSymbolTuple;
  belegnummer: Scalars['String']['output'];
  buchungskreisId: Scalars['String']['output'];
  dataCarrierBelegId?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['String']['output'];
  gesamtBetrag: Scalars['Float']['output'];
  iban?: Maybe<Scalars['String']['output']>;
  letzterMahnstichtag?: Maybe<Scalars['String']['output']>;
  mahnPositionId: Scalars['ID']['output'];
  mahnstufe: Scalars['Int']['output'];
  offenerBetrag: Scalars['Float']['output'];
  statementNumber?: Maybe<Scalars['String']['output']>;
  verzugszinsen: Scalars['Float']['output'];
  verzugszinsenBerechnen: Scalars['Boolean']['output'];
  zahlungBetrag: Scalars['Float']['output'];
};

export type MahnPositionInput = {
  mahnPositionId: Scalars['String']['input'];
  mahnstufe: Scalars['Int']['input'];
  verzugszinsenBerechnen: Scalars['Boolean']['input'];
};

export type Mahndefinition = {
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  mahndefinitionId: Scalars['ID']['output'];
  mahnfreieTage: Scalars['Int']['output'];
  mahnstufe1: Mahnstufe;
  mahnstufe1TextbausteinList: Array<Textbaustein>;
  mahnstufe2: Mahnstufe;
  mahnstufe2TextbausteinList: Array<Textbaustein>;
  mahnstufe3: Mahnstufe;
  mahnstufe3TextbausteinList: Array<Textbaustein>;
  mahnstufe4: Mahnstufe;
  mahnstufe4TextbausteinList: Array<Textbaustein>;
  status?: Maybe<MahndefinitionStatusTuple>;
  textBausteineBefuellt: Scalars['Boolean']['output'];
  verzugszinsen: Scalars['Float']['output'];
};

export type MahndefinitionCreateInput = {
  bezeichnung: Scalars['String']['input'];
  mahnfreieTage: Scalars['Int']['input'];
  mahnstufe1: MahnstufeCreateInput;
  mahnstufe1TextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  mahnstufe2: MahnstufeCreateInput;
  mahnstufe2TextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  mahnstufe3: MahnstufeCreateInput;
  mahnstufe3TextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  mahnstufe4: MahnstufeCreateInput;
  mahnstufe4TextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  verzugszinsen: Scalars['Float']['input'];
};

export type MahndefinitionListEntry = {
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  mahndefinitionId: Scalars['ID']['output'];
  status?: Maybe<MahndefinitionStatusTuple>;
  textBausteineBefuellt: Scalars['Boolean']['output'];
};

export type MahndefinitionListResponse = {
  data: Array<MahndefinitionListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahndefinitionResponse = {
  data: Mahndefinition;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahndefinitionStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: MahndefinitonStatus;
};

export type MahndefinitionTemplate = {
  textbausteinList: Array<Textbaustein>;
};

export type MahndefinitionTemplateResponse = {
  data: MahndefinitionTemplate;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahndefinitionUpdateInput = {
  bezeichnung: Scalars['String']['input'];
  mahnfreieTage: Scalars['Int']['input'];
  mahnstufe1: MahnstufeUpdateInput;
  mahnstufe1TextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  mahnstufe2: MahnstufeUpdateInput;
  mahnstufe2TextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  mahnstufe3: MahnstufeUpdateInput;
  mahnstufe3TextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  mahnstufe4: MahnstufeUpdateInput;
  mahnstufe4TextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  verzugszinsen: Scalars['Float']['input'];
};

export enum MahndefinitonStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type Mahngebuehr = {
  ab: Scalars['Float']['output'];
  betrag: Scalars['Float']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  mahngebuehrId: Scalars['ID']['output'];
};

export type MahngebuehrCreateInput = {
  ab: Scalars['Float']['input'];
  betrag: Scalars['Float']['input'];
};

export type MahngebuehrUpdateInput = {
  ab: Scalars['Float']['input'];
  betrag: Scalars['Float']['input'];
  mahngebuehrId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mahnstufe = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  mahngebuehrList: Array<Mahngebuehr>;
  minBetrag: Scalars['Float']['output'];
  naechsteMahnungNachTage: Scalars['Int']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  versandEinschreiben: Scalars['Boolean']['output'];
  verzugszinsenBerechnen: Scalars['Boolean']['output'];
};

export type MahnstufeCreateInput = {
  mahngebuehrList: Array<MahngebuehrCreateInput>;
  minBetrag: Scalars['Float']['input'];
  naechsteMahnungNachTage: Scalars['Int']['input'];
  versandEinschreiben: Scalars['Boolean']['input'];
  verzugszinsenBerechnen: Scalars['Boolean']['input'];
};

export type MahnstufeUpdateInput = {
  mahngebuehrList: Array<MahngebuehrUpdateInput>;
  minBetrag: Scalars['Float']['input'];
  naechsteMahnungNachTage: Scalars['Int']['input'];
  versandEinschreiben: Scalars['Boolean']['input'];
  verzugszinsenBerechnen: Scalars['Boolean']['input'];
};

export type Mahnung = {
  buchungId?: Maybe<Scalars['String']['output']>;
  buchungskreis: Buchungskreis;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  debitorKonto: Konto;
  fileId?: Maybe<Scalars['String']['output']>;
  freigegebenTs?: Maybe<Scalars['String']['output']>;
  mahnPositionList: Array<MahnPosition>;
  mahngebuehr: Scalars['Float']['output'];
  mahnstichtag: Scalars['String']['output'];
  mahnungDeliveryAddress?: Maybe<FibuAddress>;
  mahnungEmail?: Maybe<Scalars['String']['output']>;
  mahnungId: Scalars['ID']['output'];
  mailDelivery?: Maybe<MahnungMailDelivery>;
  objektList: Array<FibuObjekt>;
  opDeliveryAddress: FibuAddress;
  opEmail: Scalars['String']['output'];
  opZustellRechtstraeger: FibuRechtstraeger;
  rechnungsAussteller: FibuRechtstraeger;
  rechnungsAusstellerBankDetailsAccountHolder: Scalars['String']['output'];
  rechnungsAusstellerBankDetailsIban: Scalars['String']['output'];
  status: MahnungStatusTuple;
  sumMahnbetrag: Scalars['Float']['output'];
  sumRechnungsbetrag: Scalars['Float']['output'];
  sumVerzugszinsen: Scalars['Float']['output'];
  sumZahlungen: Scalars['Float']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  versandEinschreiben: Scalars['Boolean']['output'];
  versendetTs?: Maybe<Scalars['String']['output']>;
  vertragspartner: FibuRechtstraeger;
  verzugszinsen: Scalars['Float']['output'];
};

export type MahnungAusgebenGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  erstellerMitarbeiterIdList: Array<Scalars['String']['output']>;
  generierlaufId: Scalars['ID']['output'];
  mahnstichtagBisInklusive?: Maybe<Scalars['String']['output']>;
  mahnstichtagVonInklusive?: Maybe<Scalars['String']['output']>;
  mahnung?: Maybe<Mahnung>;
  objekt?: Maybe<FibuObjekt>;
  rechnungsAussteller?: Maybe<FibuRechtstraeger>;
  status: GenerierlaufStatusTuple;
  vertragspartner?: Maybe<FibuRechtstraeger>;
  warningList: Array<WarningData>;
};

export type MahnungAusgebenGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  mahnung: Mahnung;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type MahnungAusgebenGenerierlaufListEntryPage = {
  contentList: Array<MahnungAusgebenGenerierlaufEntry>;
  page: Page;
};

export type MahnungAusgebenGenerierlaufListEntryPageResponse = {
  data: MahnungAusgebenGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungAusgebenGenerierlaufResponse = {
  data: MahnungAusgebenGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungEntryListPage = {
  contentList: Array<MahnungListEntry>;
  page: Page;
};

export type MahnungEntryListPageResponse = {
  data: MahnungEntryListPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungErzeugenGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierlaufId: Scalars['ID']['output'];
  objekt?: Maybe<FibuObjekt>;
  rechnungsAussteller?: Maybe<FibuRechtstraeger>;
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type MahnungErzeugenGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  mahnung?: Maybe<Mahnung>;
  mahnungDeleted: Scalars['Boolean']['output'];
  mahnungDeletedBy?: Maybe<Scalars['String']['output']>;
  mahnungDeletedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  mahnungDeletedTs?: Maybe<Scalars['String']['output']>;
  rechnungsAussteller: FibuRechtstraeger;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  vertragspartner: FibuRechtstraeger;
  warningList: Array<WarningData>;
};

export type MahnungErzeugenGenerierlaufInput = {
  objektId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
};

export type MahnungErzeugenGenerierlaufListEntryPage = {
  contentList: Array<MahnungErzeugenGenerierlaufEntry>;
  page: Page;
};

export type MahnungErzeugenGenerierlaufListEntryPageResponse = {
  data: MahnungErzeugenGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungErzeugenGenerierlaufResponse = {
  data: MahnungErzeugenGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungGenerierlaufInput = {
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  mahnstichtagBisInklusive?: InputMaybe<Scalars['String']['input']>;
  mahnstichtagVonInklusive?: InputMaybe<Scalars['String']['input']>;
  mahnungId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export enum MahnungGenerierlaufOrderBy {
  MahnbetragSumme = 'MAHNBETRAG_SUMME',
  MahnungStatus = 'MAHNUNG_STATUS',
  Rechnungsaussteller = 'RECHNUNGSAUSSTELLER',
  RechnungsbetragSumme = 'RECHNUNGSBETRAG_SUMME',
  Status = 'STATUS',
  Vertragspartner = 'VERTRAGSPARTNER',
}

export type MahnungListEntry = {
  buchungId?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['String']['output']>;
  mahngebuehr: Scalars['Float']['output'];
  mahnstichtag: Scalars['String']['output'];
  mahnungId: Scalars['ID']['output'];
  rechnungsAussteller: FibuRechtstraeger;
  rechnungsAusstellerBankDetailsIban: Scalars['String']['output'];
  status: MahnungStatusTuple;
  sumMahnbetrag: Scalars['Float']['output'];
  sumRechnungsbetrag: Scalars['Float']['output'];
  sumVerzugszinsen: Scalars['Float']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  versandEinschreiben: Scalars['Boolean']['output'];
  vertragspartner: FibuRechtstraeger;
};

export type MahnungListSummary = {
  sumDefaultInterest: Scalars['Float']['output'];
  sumDunningAmount: Scalars['Float']['output'];
  sumDunningCharge: Scalars['Float']['output'];
  sumInvoiceAmount: Scalars['Float']['output'];
};

export type MahnungListSummaryResponse = {
  data: MahnungListSummary;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungMailDelivery = {
  mailId?: Maybe<Scalars['String']['output']>;
  status: MahnungMailDeliveryStatusTuple;
};

export enum MahnungMailDeliveryStatus {
  NotSentError = 'NOT_SENT_ERROR',
  Sent = 'SENT',
  Started = 'STARTED',
}

export type MahnungMailDeliveryStatusTuple = {
  text: Scalars['String']['output'];
  value: MahnungMailDeliveryStatus;
};

export type MahnungObjektListResponse = {
  data: Array<FibuObjekt>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum MahnungOrderBy {
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  Mahngebuehr = 'MAHNGEBUEHR',
  Mahnstichtag = 'MAHNSTICHTAG',
  RechnungsAusstellerBankDetails = 'RECHNUNGS_AUSSTELLER_BANK_DETAILS',
  RechnungsAusstellerKurzbezeichnung = 'RECHNUNGS_AUSSTELLER_KURZBEZEICHNUNG',
  Status = 'STATUS',
  SumMahnbetrag = 'SUM_MAHNBETRAG',
  SumRechnungsbetrag = 'SUM_RECHNUNGSBETRAG',
  SumVerzugszinsen = 'SUM_VERZUGSZINSEN',
  VertragspartnerKurzbezeichnung = 'VERTRAGSPARTNER_KURZBEZEICHNUNG',
}

export type MahnungResponse = {
  data: Mahnung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum MahnungStatus {
  Ausgegeben = 'AUSGEGEBEN',
  Erstellt = 'ERSTELLT',
  Freigegeben = 'FREIGEGEBEN',
  GemahntMitBuchung = 'GEMAHNT_MIT_BUCHUNG',
  GemahntOhneBuchung = 'GEMAHNT_OHNE_BUCHUNG',
  Gesperrt = 'GESPERRT',
  Versandt = 'VERSANDT',
  VersandtError = 'VERSANDT_ERROR',
  WirdVersendet = 'WIRD_VERSENDET',
}

export type MahnungStatusListResponse = {
  data: Array<MahnungStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: MahnungStatus;
};

export type MahnungSumVerzugszinsen = {
  mahnPositionIdVerzugszinsenMap: Scalars['JSON']['output'];
  sumVerzugszinsen: Scalars['Float']['output'];
};

export type MahnungSumVerzugszinsenResponse = {
  data: MahnungSumVerzugszinsen;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungUpdateInput = {
  mahnPositionList: Array<MahnPositionInput>;
  mahngebuehr: Scalars['Float']['input'];
  versandEinschreiben: Scalars['Boolean']['input'];
  verzugszinsen: Scalars['Float']['input'];
};

export type MahnungVerbuchenGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  erstellerMitarbeiterIdList: Array<Scalars['String']['output']>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  mahnstichtagBisInklusive?: Maybe<Scalars['String']['output']>;
  mahnstichtagVonInklusive?: Maybe<Scalars['String']['output']>;
  mahnung?: Maybe<Mahnung>;
  objekt?: Maybe<FibuObjekt>;
  rechnungsAussteller?: Maybe<FibuRechtstraeger>;
  status: GenerierlaufStatusTuple;
  vertragspartner?: Maybe<FibuRechtstraeger>;
  warningList: Array<WarningData>;
};

export type MahnungVerbuchenGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  mahnung: Mahnung;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type MahnungVerbuchenGenerierlaufListEntryPage = {
  contentList: Array<MahnungVerbuchenGenerierlaufEntry>;
  page: Page;
};

export type MahnungVerbuchenGenerierlaufListEntryPageResponse = {
  data: MahnungVerbuchenGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungVerbuchenGenerierlaufResponse = {
  data: MahnungVerbuchenGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungVersendenGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  erstellerMitarbeiterIdList: Array<Scalars['String']['output']>;
  generierlaufId: Scalars['ID']['output'];
  mahnstichtagBisInklusive?: Maybe<Scalars['String']['output']>;
  mahnstichtagVonInklusive?: Maybe<Scalars['String']['output']>;
  mahnung?: Maybe<Mahnung>;
  objekt?: Maybe<FibuObjekt>;
  rechnungsAussteller?: Maybe<FibuRechtstraeger>;
  status: GenerierlaufStatusTuple;
  vertragspartner?: Maybe<FibuRechtstraeger>;
  warningList: Array<WarningData>;
};

export type MahnungVersendenGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  mahnung: Mahnung;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type MahnungVersendenGenerierlaufInput = {
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  mahnstichtagBisInklusive?: InputMaybe<Scalars['String']['input']>;
  mahnstichtagVonInklusive?: InputMaybe<Scalars['String']['input']>;
  mahnungId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  startVerbuchenGenerierlauf?: InputMaybe<Scalars['Boolean']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type MahnungVersendenGenerierlaufListEntryPage = {
  contentList: Array<MahnungVersendenGenerierlaufEntry>;
  page: Page;
};

export type MahnungVersendenGenerierlaufListEntryPageResponse = {
  data: MahnungVersendenGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungVersendenGenerierlaufResponse = {
  data: MahnungVersendenGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MahnungenBulkActionFilterInput = {
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  mahnstichtagBisInklusive?: InputMaybe<Scalars['String']['input']>;
  mahnstichtagVonInklusive?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type MahnungenSperrenBulkActionFilterInput = {
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  mahnstichtagBisInklusive?: InputMaybe<Scalars['String']['input']>;
  mahnstichtagVonInklusive?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<MahnungStatus>>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type MailBaseData = {
  attachmentCount: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  labelList: Array<LabelData>;
  mailId: Scalars['String']['output'];
  recipient: Scalars['String']['output'];
  recipientRechtstraeger?: Maybe<RecipientRechtstraeger>;
  sender: Scalars['String']['output'];
  sentTs?: Maybe<Scalars['String']['output']>;
  status: MailStatusTuple;
  subject: Scalars['String']['output'];
  useCase: MailUseCaseTuple;
};

export type MailData = {
  attachmentCount: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  labelList: Array<LabelData>;
  mailId: Scalars['String']['output'];
  recipient: Scalars['String']['output'];
  recipientRechtstraeger?: Maybe<RecipientRechtstraeger>;
  sender: Scalars['String']['output'];
  sentTs?: Maybe<Scalars['String']['output']>;
  status: MailStatusTuple;
  subject: Scalars['String']['output'];
  textHtml?: Maybe<Scalars['String']['output']>;
  textPlain?: Maybe<Scalars['String']['output']>;
  useCase: MailUseCaseTuple;
};

export type MailListPage = {
  contentList: Array<MailBaseData>;
  page: Page;
};

export type MailListPageResponse = {
  data: MailListPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum MailOrderBy {
  CreatedByMitarbeiterId = 'CREATED_BY_MITARBEITER_ID',
  CreateTs = 'CREATE_TS',
  Recipient = 'RECIPIENT',
  RecipientKurzBezeichnung = 'RECIPIENT_KURZ_BEZEICHNUNG',
  Status = 'STATUS',
  Subject = 'SUBJECT',
  UseCase = 'USE_CASE',
}

export type MailPageResponse = {
  data: MailData;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum MailStatus {
  Created = 'CREATED',
  Sent = 'SENT',
}

export type MailStatusListResponse = {
  data: Array<MailStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MailStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: MailStatus;
};

export enum MailUseCase {
  EmailChange = 'EMAIL_CHANGE',
  InfoMail = 'INFO_MAIL',
  Invitation = 'INVITATION',
  Mahnung = 'MAHNUNG',
  PasswordReset = 'PASSWORD_RESET',
  Ticket = 'TICKET',
  VorschreibungMail = 'VORSCHREIBUNG_MAIL',
}

export type MailUseCaseListResponse = {
  data: Array<MailUseCaseTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MailUseCaseTuple = {
  text: Scalars['String']['output'];
  value: MailUseCase;
};

export enum Masseinheit {
  Anteile = 'ANTEILE',
  Prozent = 'PROZENT',
  QuadratMeter = 'QUADRAT_METER',
}

export type MasseinheitListResponse = {
  data: Array<MasseinheitTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MasseinheitTuple = {
  text: Scalars['String']['output'];
  value: Masseinheit;
};

export enum MissingContactReason {
  NichtAusgewaehlt = 'NICHT_AUSGEWAEHLT',
  NichtVorhanden = 'NICHT_VORHANDEN',
}

export type MissingContactReasonTuple = {
  text: Scalars['String']['output'];
  value: MissingContactReason;
};

export type Mitarbeiter = {
  contactPerson?: Maybe<ContactPerson>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  fileStorageInitialized: Scalars['Boolean']['output'];
  firmendatenId: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  funktionsModulList: Array<Scalars['String']['output']>;
  lastname: Scalars['String']['output'];
  mitarbeiterId: Scalars['ID']['output'];
  rolleList: Array<Rolle>;
  salutation?: Maybe<SalutationTuple>;
  status: MitarbeiterStatusTuple;
  title?: Maybe<Scalars['String']['output']>;
  titleTrailing?: Maybe<Scalars['String']['output']>;
  user?: Maybe<MitarbeiterUser>;
  username: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type MitarbeiterBase = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenBaseData: FirmendatenBase;
  firstname: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  mitarbeiterId: Scalars['ID']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type MitarbeiterCreateInput = {
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  salutation?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleTrailing?: InputMaybe<Scalars['String']['input']>;
  userEmail: Scalars['String']['input'];
};

export type MitarbeiterListResponse = {
  data: Array<Mitarbeiter>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MitarbeiterResponse = {
  data: Mitarbeiter;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum MitarbeiterStatus {
  Aktiv = 'AKTIV',
  Eingeladen = 'EINGELADEN',
  Geloescht = 'GELOESCHT',
  Gesperrt = 'GESPERRT',
}

export type MitarbeiterStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: MitarbeiterStatus;
};

export type MitarbeiterTicketCount = {
  mitarbeiter: MitarbeiterBase;
  ticketCount?: Maybe<Scalars['Int']['output']>;
};

export type MitarbeiterTicketCountListResponse = {
  data: Array<MitarbeiterTicketCount>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MitarbeiterUpdateInput = {
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  salutation?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleTrailing?: InputMaybe<Scalars['String']['input']>;
};

export type MitarbeiterUser = {
  email?: Maybe<Scalars['String']['output']>;
  emailUnverified?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  status: MitarbeiterUserStatusTuple;
  username: Scalars['String']['output'];
};

export type MitarbeiterUserStatusTuple = {
  text: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MitarbeiterWithFirmaInfo = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenBaseData: FirmendatenBase;
  mitarbeiterId: Scalars['ID']['output'];
};

export enum Month {
  April = 'APRIL',
  August = 'AUGUST',
  December = 'DECEMBER',
  February = 'FEBRUARY',
  January = 'JANUARY',
  July = 'JULY',
  June = 'JUNE',
  March = 'MARCH',
  May = 'MAY',
  November = 'NOVEMBER',
  October = 'OCTOBER',
  September = 'SEPTEMBER',
}

export type MonthListResponse = {
  data: Array<MonthTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MonthTuple = {
  text: Scalars['String']['output'];
  value: Month;
};

export type Mutation = {
  _empty?: Maybe<Scalars['String']['output']>;
  actionActivateEbicsUser: EbicsUserResponse;
  actionActivateEbicsUserIban: EbicsUserIbanResponse;
  actionActivateUser: UserResponse;
  actionAddKontoForFirmendatenBankDetails: FirmendatenBankDetailsResponse;
  actionAddKontoForRechtstraegerBankDetails: BankDetailsResponse;
  actionApplyVertragVposIndexedValue: VertragVposIndexedValueResponse;
  actionApprovePayment: OrderPaymentResponse;
  actionArchiveAbrechnungsdefinition: AbrechnungsdefinitionListEntryResponse;
  actionArchiveAbrechnungskreis: AbrechnungskreisResponse;
  actionArchiveAbrechnungskreisTemplate: AbrechnungskreisTemplateResponse;
  actionArchiveAufteilungsschluessel: AufteilungsschluesselResponse;
  actionArchiveAufteilungsschluesselTemplate: AufteilungsschluesselResponse;
  actionArchiveAuftragsart: AuftragsartResponse;
  actionArchiveBeInfoFeld: BeInfoFeldResponse;
  actionArchiveBeVertragInfoFeld: VertragInfoFeldResponse;
  actionArchiveBelegnummernkreisDefinition: BelegnummernkreisDefinitionResponse;
  actionArchiveBestandseinheit: BestandseinheitResponse;
  actionArchiveFirma: FirmaResponse;
  actionArchiveFirmendatenBankDetails: FirmendatenBankDetailsResponse;
  actionArchiveFirmendatenIndexSeries: IndexSeriesResponse;
  actionArchiveHeVertragZahlungsregel: HeVertragZahlungsregelResponse;
  actionArchiveKontierungstabelle: KontierungstabelleResponse;
  actionArchiveKontierungstabelleTemplate: KontierungstabelleTemplateResponse;
  actionArchiveKonto: KontoResponse;
  actionArchiveKontoTemplate: KontoResponse;
  actionArchiveMahndefinition: MahndefinitionResponse;
  actionArchiveNatuerlichePerson: NatuerlichePersonResponse;
  actionArchiveObjekt: ObjektResponse;
  actionArchiveObjektAufteilungsschluessel: ObjektAufteilungsschluesselResponse;
  actionArchiveObjektInfoFeld: ObjektInfoFeldResponse;
  actionArchiveObjektVorschreibungsposition: ObjektVorschreibungspositionsResponse;
  actionArchivePersonenGemeinschaft: PersonengemeinschaftResponse;
  actionArchiveReceivedBankTransaction: ReceivedBankTransactionResponse;
  actionArchiveRechtstraegerBankDetails: BankDetailsResponse;
  actionArchiveRechtstraegerInfoFeld: RechtstraegerInfoFeldResponse;
  actionArchiveTicket: TicketResponse;
  actionArchiveUstKategorie: UstKategorieResponse;
  actionArchiveVorschreibungsposition: VorschreibungspositionResponse;
  actionArchiveVorschreibungspositionTemplate: VorschreibungspositionResponse;
  actionAssignReceivedBankTransaction: ReceivedBankTransactionResponse;
  actionAuftragApplyAllDeviations: AuftragResponse;
  actionAuftragStornieren: AuftragResponse;
  actionAuszifferungStornieren: AuszifferungWithOffenePostenResponse;
  actionBeVertragApplyAllDeviations: BeVertragResponse;
  actionBeVertragMarkUnderReview: BeVertragResponse;
  actionBeVertragPruefungAktivieren: BeVertragResponse;
  actionBeVertragPruefungAussetzen: BeVertragResponse;
  actionBookReceivedBankTransaction: ReceivedBankTransactionResponse;
  actionBuchungsanweisungApplyAllDeviations: OrderBuchungsanweisungResponse;
  actionBudgetingAdjustAufteilungsschluessel: BudgetingResponse;
  actionBudgetingArchivieren: BudgetingResponse;
  actionBudgetingConfigureFinancialPlanTexts: BudgetingResponse;
  actionBudgetingCreateVPosValuesFromAufwand: BudgetingResponse;
  actionBudgetingCreateVPosValuesFromVPos: BudgetingResponse;
  actionBudgetingDeleteVPosValues: BudgetingResponse;
  actionBudgetingReaktivieren: BudgetingResponse;
  actionBudgetingResetKontoEstimate: BudgetingResponse;
  actionBudgetingTransferToVPos: BudgetingResponse;
  actionBudgetingUpdateKontoEstimate: BudgetingResponse;
  actionBudgetingUpdateVPosEstimate: BudgetingResponse;
  actionChangeAccountLimitForFirmendatenBankDetails: FirmendatenBankDetailsResponse;
  actionChangeAccountLimitForRechtstraegerBankDetails: BankDetailsResponse;
  actionChangePasswordMe: UserResponse;
  actionChangePositionBestandseinheit: BestandseinheitResponse;
  actionChangeToDatentraegerErzeugtPayment: OrderPaymentResponse;
  actionChangeToDurchfuehrbarPayment: OrderPaymentResponse;
  actionChangeToNichtDurchfuehrbarPayment: OrderPaymentResponse;
  actionChangeToNichtDurchgefuehrtPayment: OrderPaymentResponse;
  actionCompleteInfoMailDetermineRecipientListStep: InfoMailDeliveryWizardDetermineRecipientListStepResponse;
  actionCompleteVorschreibungMailDetermineRecipientListStep: EmailDeliveryWizardDetermineRecipientListStepResponse;
  actionCreateBuchungskreisForRechtstraeger: RechnungsAusstellerResponse;
  actionDeactivateEbicsUser: EbicsUserResponse;
  actionDeactivateEbicsUserIban: EbicsUserIbanResponse;
  actionDismissNotification: ResponseWithoutData;
  actionDoNotApplyVertragVposIndexedValue: VertragVposIndexedValueResponse;
  actionDoNotApplyVertragVposIndexedValueFirmendaten: IntResponse;
  actionEigenbelegMarkUnderReview: EigenbelegResponse;
  actionEntsperrenFirma: FirmaResponse;
  actionEntsperrenFirmendaten: FirmendatenResponse;
  actionEntsperrenMahnung: MahnungResponse;
  actionEntsperrenMitarbeiter: MitarbeiterResponse;
  actionEntsperrenNatuerlichePerson: NatuerlichePersonResponse;
  actionEntsperrenPersonenGemeinschaft: PersonengemeinschaftResponse;
  actionEntsperrenUser: UserResponse;
  actionFreigebenMahnung: MahnungResponse;
  actionHeAbrechnungApplyAllDeviations: HeAbrechnungResponse;
  actionHeAbrechnungMarkUnderReview: HeAbrechnungResponse;
  actionMarkReceivedBankTransactionAsNoted: ReceivedBankTransactionResponse;
  actionObjektAbrechnungApplyAllDeviations: ObjektAbrechnungResponse;
  actionObjektAbrechnungMarkUnderReview: ObjektAbrechnungResponse;
  actionObjektTopAbrechnungApplyAllDeviations: ObjektAbrechnungResponse;
  actionReimportDataCarrierCamt: DataCarrierCamtResponse;
  actionRemoveAssignmentReceivedBankTransaction: ReceivedBankTransactionResponse;
  actionResendEinladungMitarbeiter: MitarbeiterResponse;
  actionResendInfoMailDelivery: InfoMailDeliveryResponse;
  actionResendVorschreibungMailDelivery: VorschreibungMailDeliveryResponse;
  actionRestartApplyVertragVposIndexedValueGenerierlauf: ApplyVertragVposIndexedValueGenerierlaufResponse;
  actionRestartAuftragGenerierlauf: AuftragGenerierlaufResponse;
  actionRestartAuftragVerbuchenGenerierlauf: AuftragVerbuchenGenerierlaufResponse;
  actionRestartBookingSuggestionCreationGenerierlauf: BookingSuggestionCreationGenerierlaufResponse;
  actionRestartBudgetingGenerierlauf: BudgetingGenerierlaufResponse;
  actionRestartEigenbelegErstellenGenerierlauf: EigenbelegErstellenGenerierlaufResponse;
  actionRestartFirmendatenInitializerGenerierlauf: FirmendatenInitializerGenerierlaufResponse;
  actionRestartHeAbrechnungGenerierlauf: HeAbrechnungGenerierlaufResponse;
  actionRestartIncomingInvoiceBookingCreationGenerierlauf: IncomingInvoiceBookingCreationGenerierlaufResponse;
  actionRestartInfoMailDeliveryDetermineRecipientListGenerierlauf: InfoMailDeliveryDetermineRecipientListGenerierlaufResponse;
  actionRestartInfoMailDeliverySendGenerierlauf: InfoMailDeliverySendGenerierlaufResponse;
  actionRestartMahnungAusgebenGenerierlauf: MahnungAusgebenGenerierlaufResponse;
  actionRestartMahnungErzeugenGenerierlauf: MahnungErzeugenGenerierlaufResponse;
  actionRestartMahnungVerbuchenGenerierlauf: MahnungVerbuchenGenerierlaufResponse;
  actionRestartMahnungVersendenGenerierlauf: MahnungVersendenGenerierlaufResponse;
  actionRestartObjektAbrechnungGenerierlauf: ObjektAbrechnungGenerierlaufResponse;
  actionRestartObjektFinancialPlanGenerierlauf: ObjektFinancialPlanGenerierlaufResponse;
  actionRestartPaymentProposalCreationGenerierlauf: PaymentProposalCreationGenerierlaufResponse;
  actionRestartPaymentProposalExecutionGenerierlauf: PaymentProposalExecutionGenerierlaufResponse;
  actionRestartSubAbrechnungGenerierlauf: SubAbrechnungGenerierlaufResponse;
  actionRestartTopAbrechnungGenerierlauf: TopAbrechnungGenerierlaufResponse;
  actionRestartTopFinancialPlanGenerierlauf: TopFinancialPlanGenerierlaufResponse;
  actionRestartUstVomAufwandGenerierlauf: UstVomAufwandGenerierlaufResponse;
  actionRestartVertragVposIndexedValueGenerierlauf: VertragVposIndexedValueGenerierlaufResponse;
  actionRestartVorschreibungMailDeliveryDetermineRecipientListGenerierlauf: VorschreibungMailDeliveryDetermineRecipientListGenerierlaufResponse;
  actionRestartVorschreibungMailDeliverySendGenerierlauf: VorschreibungMailDeliverySendGenerierlaufResponse;
  actionRestartVorsteuerkuerzungGenerierlauf: VorsteuerkuerzungGenerierlaufResponse;
  actionRestartZinslisteGenerierlauf: ZinslisteGenerierlaufResponse;
  actionRestoreDefaultFirmeneinstellung: FirmeneinstellungResponse;
  actionSendInfoMail: InfoMailDeliveryWizardRecipientListStepActionResponse;
  actionSendVorschreibungMail: EmailDeliveryWizardRecipientListStepResponse;
  actionSetAbrechnungsdefinitionForRechtstraeger: RechnungsAusstellerResponse;
  actionSetAddressTagListForAddress: AddressResponse;
  actionSetContactPersonTagListForFirmaContactPerson: ContactPersonResponse;
  actionSetContactPersonTagListForFirmendatenContactPerson: ContactPersonResponse;
  actionSetContactTagListForContact: ContactResponse;
  actionSetKontoTagList: KontoResponse;
  actionSetKontoTemplateTagList: KontoResponse;
  actionSetMahndefinitionForRechtstraeger: RechnungsAusstellerResponse;
  actionSetMahnsperreForRechtstraeger: RechtstraegerVertragspartnerResponse;
  actionSetOperatingSiteForAddress: AddressResponse;
  actionSetOperatingSiteForContact: ContactResponse;
  actionSetOperatingSiteForFirmaContactPerson: ContactPersonResponse;
  actionSetOperatingSiteForFirmendatenContactPerson: ContactPersonResponse;
  actionSetPersonenbezugTagList: PersonenbezugResponse;
  actionSetVerwendungForKontoForRechtstraegerBankDetails: BankDetailsResponse;
  actionSingleInvoiceFreigeben: SingleInvoiceResponse;
  actionSingleInvoiceRestore: SingleInvoiceResponse;
  actionSperrenFirma: FirmaResponse;
  actionSperrenFirmendaten: FirmendatenResponse;
  actionSperrenMahnung: MahnungResponse;
  actionSperrenMitarbeiter: MitarbeiterResponse;
  actionSperrenNatuerlichePerson: NatuerlichePersonResponse;
  actionSperrenPersonenGemeinschaft: PersonengemeinschaftResponse;
  actionSperrenUser: UserResponse;
  actionStandingInvoiceFreigeben: StandingInvoiceResponse;
  actionStandingInvoiceRestore: StandingInvoiceResponse;
  actionSubmitVertragVposIndexedValue: VertragVposIndexedValueResponse;
  actionSubmitVertragVposIndexedValueFirmendaten: IntResponse;
  actionTicketChangeSubTicketPosition: TicketResponse;
  actionTicketCreateSubTicket: TicketResponse;
  actionTicketParticipant: TicketParticipantResponse;
  actionTicketTemplateChangePosition: TicketTemplateResponse;
  actionTicketTemplateCreateSubTicketTemplate: TicketTemplateResponse;
  actionTicketTemplateTemplateChangePosition: TicketTemplateTemplateResponse;
  actionTicketTemplateTemplateCreateSubTicketTemplate: TicketTemplateTemplateResponse;
  actionTicketUpdateStatus: TicketResponse;
  actionTriggerChangeEmail: UserResponse;
  actionTriggerChangeEmailMe: UserResponse;
  actionTriggerPasswordResetUser: UserResponse;
  actionUnarchiveAbrechnungsdefinition: AbrechnungsdefinitionListEntryResponse;
  actionUnarchiveAbrechnungskreis: AbrechnungskreisResponse;
  actionUnarchiveAbrechnungskreisTemplate: AbrechnungskreisTemplateResponse;
  actionUnarchiveAufteilungsschluessel: AufteilungsschluesselResponse;
  actionUnarchiveAufteilungsschluesselTemplate: AufteilungsschluesselResponse;
  actionUnarchiveAuftragsart: AuftragsartResponse;
  actionUnarchiveBeInfoFeld: BeInfoFeldResponse;
  actionUnarchiveBeVertragInfoFeld: VertragInfoFeldResponse;
  actionUnarchiveBelegnummernkreisDefinition: BelegnummernkreisDefinitionResponse;
  actionUnarchiveBestandseinheit: BestandseinheitResponse;
  actionUnarchiveFirma: FirmaResponse;
  actionUnarchiveFirmendatenBankDetails: FirmendatenBankDetailsResponse;
  actionUnarchiveFirmendatenIndexSeries: IndexSeriesResponse;
  actionUnarchiveHeVertragZahlungsregel: HeVertragZahlungsregelResponse;
  actionUnarchiveKontierungstabelle: KontierungstabelleResponse;
  actionUnarchiveKontierungstabelleTemplate: KontierungstabelleTemplateResponse;
  actionUnarchiveKonto: KontoResponse;
  actionUnarchiveKontoTemplate: KontoResponse;
  actionUnarchiveMahndefinition: MahndefinitionResponse;
  actionUnarchiveNatuerlichePerson: NatuerlichePersonResponse;
  actionUnarchiveObjekt: ObjektResponse;
  actionUnarchiveObjektAufteilungsschluessel: ObjektAufteilungsschluesselResponse;
  actionUnarchiveObjektInfoFeld: ObjektInfoFeldResponse;
  actionUnarchiveObjektVorschreibungsposition: ObjektVorschreibungspositionsResponse;
  actionUnarchivePersonenGemeinschaft: PersonengemeinschaftResponse;
  actionUnarchiveReceivedBankTransaction: ReceivedBankTransactionResponse;
  actionUnarchiveRechtstraegerBankDetails: BankDetailsResponse;
  actionUnarchiveRechtstraegerInfoFeld: RechtstraegerInfoFeldResponse;
  actionUnarchiveTicket: TicketResponse;
  actionUnarchiveUstKategorie: UstKategorieResponse;
  actionUnarchiveVorschreibungsposition: VorschreibungspositionResponse;
  actionUnarchiveVorschreibungspositionTemplate: VorschreibungspositionResponse;
  actionUpdateRechtstreagerBankSettings: RechnungsAusstellerResponse;
  actionUpdateSubAdministrationSettings: RechnungsAusstellerResponse;
  actionUpdateValidFromVertragVposIndexedValueFirmendaten: IntResponse;
  actionUploadManuallyDataCarrierPain: DataCarrierPainResponse;
  actionUploadedDataCarrierPain: DataCarrierPainResponse;
  actionUstVomAufwandApplyAllDeviations: UstVomAufwandResponse;
  actionUstVomAufwandFreigeben: UstVomAufwandResponse;
  actionUstVomAufwandMarkUnderReview: UstVomAufwandResponse;
  actionVorsteuerkuerzungApplyAllDeviations: VorsteuerkuerzungResponse;
  actionVorsteuerkuerzungFreigeben: VorsteuerkuerzungResponse;
  actionVorsteuerkuerzungMarkUnderReview: VorsteuerkuerzungResponse;
  actionZinslisteApplyAllDeviations: ZinslisteResponse;
  actionZinslisteMarkUnderReview: ZinslisteResponse;
  actionZuruecksetzenUstKategorieEntry: UstKategorieEntryResponse;
  approvePaymentBulkAction: IntResponse;
  assignAbrechnungsdefinitionKontoAbrechnungskreisTemplateList: AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListResponse;
  assignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList: AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListResponse;
  assignRechtstraegerToParticipant: TicketParticipantResponse;
  calculateHeVertragZahlungsregelSumVposNetto: HeVertragZahlungsregelSumVposNettoResponse;
  changePasswordViaToken: ResponseEntity;
  changePaymentDatePaymentBulkAction: IntResponse;
  createAbrechnungskreisTemplate: AbrechnungskreisTemplateResponse;
  createApplyVertragVposIndexedValueGenerierlauf: ApplyVertragVposIndexedValueGenerierlaufResponse;
  createAufteilungsschluesselTemplate: AufteilungsschluesselResponse;
  createAuftrag: AuftragResponse;
  createAuftragGenerierlauf: AuftragGenerierlaufResponse;
  createAuftragVerbuchenGenerierlauf: AuftragVerbuchenGenerierlaufResponse;
  createAuftragsart: AuftragsartResponse;
  createAuftragsartTemplate: AuftragsartTemplateResponse;
  createAuszifferungList: AuszifferungListResponse;
  createBeAufteilungsschluesselWertMitBezug: BeAufteilingsschluesselWertResponse;
  createBeAufteilungsschluesselWertOhneBezug: BeAufteilingsschluesselWertResponse;
  createBeInfoFeldPostIt: PostItResponse;
  createBeNutzungsArt: BeNutzungsArtResponse;
  createBeOptionSteuerpflichtVersion: BeOptionSteuerpflichtVersionResponse;
  createBePostIt: PostItResponse;
  createBeVertrag: BeVertragResponse;
  createBeVertragDokumentenversandEntry: DokumentenversandEntryResponse;
  createBeVertragInfoFeldPostIt: PostItResponse;
  createBeVertragPostIt: PostItResponse;
  createBeVertragVersion: BeVertragVersionResponse;
  createBeVertragVorschreibungspositionWert: BeVertragVorschreibunspositionWertResponse;
  createBelegnummernkreisDefinition: BelegnummernkreisDefinitionResponse;
  createBestandseinheit: BestandseinheitResponse;
  createBestandseinheitInfoFeldTicket: TicketResponse;
  createBestandseinheitNutzflaecheVersion: BestandseinheitNutzflaecheVersionResponse;
  createBestandseinheitNutzwertVersion: BestandseinheitNutzwertVersionResponse;
  createBestandseinheitTicket: TicketResponse;
  createBkAbrechnungsdefinitionForFD: BkAbrechnungsdefinitionResponse;
  createBkAbrechnungsdefinitionForKS: BkAbrechnungsdefinitionResponse;
  createBkAbrechnungsdefinitionTemplate: BkAbrechnungsdefinitionResponse;
  createBkAbrechnungsdefinitionTemplateVersion: BkAbrechnungsdefinitionVersionResponse;
  createBkAbrechnungsdefinitionVersion: BkAbrechnungsdefinitionVersionResponse;
  createBookingSuggestionVerbuchenGenerierlauf: BookingSuggestionVerbuchenGenerierlaufResponse;
  createBudgeting: BudgetingGenerierlaufResponse;
  createBudgetingAbrDefVPosLinkageFirmendaten: BudgetingAbrDefVPosLinkageResponse;
  createBudgetingAbrDefVPosLinkageKundenSystem: BudgetingAbrDefVPosLinkageResponse;
  createBudgetingAbrDefVPosLinkageTemplate: BudgetingAbrDefVPosLinkageResponse;
  createBudgetingVPosAuftschluesselLinkageFirmendaten: BudgetingVPosAuftschluesselLinkageResponse;
  createBudgetingVPosAuftschluesselLinkageKundenSystem: BudgetingVPosAuftschluesselLinkageResponse;
  createBudgetingVPosAuftschluesselLinkageTemplate: BudgetingVPosAuftschluesselLinkageResponse;
  createCamtGenerierlauf: CamtGenerierlaufResponse;
  createEbicsBankConfig: EbicsBankConfigResponse;
  createEbicsUser: EbicsUserResponse;
  createEigenbelegErstellenGenerierlauf: EigenbelegErstellenGenerierlaufResponse;
  createEinmalzahlung: HeVertragAuszahlungResponse;
  createEmailDeliveryDefinition: EmailDeliveryDefinitionResponse;
  createEmailDeliveryDefinitionTemplate: EmailDeliveryDefinitionTemplateResponse;
  createFDAbrechnungskreis: AbrechnungskreisResponse;
  createFDAufteilungsschluessel: AufteilungsschluesselResponse;
  createFDKontierungstabelle: KontierungstabelleResponse;
  createFDUstKategorie: UstKategorieResponse;
  createFDVorschreibungsposition: VorschreibungspositionResponse;
  createFirma: FirmaResponse;
  createFirmaAddress: AddressResponse;
  createFirmaContact: ContactListResponse;
  createFirmaContactPerson: ContactPersonResponse;
  createFirmaOperatingSite: OperatingSiteResponse;
  createFirmendatenAddress: AddressResponse;
  createFirmendatenBankDetails: FirmendatenBankDetailsResponse;
  createFirmendatenContact: ContactListResponse;
  createFirmendatenContactPerson: ContactPersonResponse;
  createFirmendatenHeOrObjektOrSubAbrechnungGenerierlauf: StringResponse;
  createFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlauf: StringResponse;
  createFirmendatenIndexSeries: IndexSeriesResponse;
  createFirmendatenIndexSeriesEntry: IndexSeriesEntryResponse;
  createFirmendatenOperatingSite: OperatingSiteResponse;
  createFirmendatenTopAbrechnungGenerierlauf: StringResponse;
  createHeAbrechnungsdefinitionForFD: HeAbrechnungsdefinitionResponse;
  createHeAbrechnungsdefinitionForKS: HeAbrechnungsdefinitionResponse;
  createHeAbrechnungsdefinitionTemplate: HeAbrechnungsdefinitionResponse;
  createHeAbrechnungsdefinitionTemplateVersion: HeAbrechnungsdefinitionVersionResponse;
  createHeAbrechnungsdefinitionVersion: HeAbrechnungsdefinitionVersionResponse;
  createHeVertrag: HeVertragResponse;
  createHeVertragPostIt: PostItResponse;
  createHeVertragZahlungsregel: HeVertragZahlungsregelResponse;
  createIncomingCallLog: CallLogListResponse;
  createIncomingInvoiceBookingCreationGenerierlauf: IncomingInvoiceBookingCreationGenerierlaufResponse;
  createIndexSeries: IndexSeriesResponse;
  createIndexSeriesEntry: IndexSeriesEntryResponse;
  createInfoMailDeliveryWizard: InfoMailDeliveryWizardTemplateStepResponse;
  createKSAbrechnungskreis: AbrechnungskreisResponse;
  createKSAufteilungsschluessel: AufteilungsschluesselResponse;
  createKSFirmendaten: FirmendatenResponse;
  createKSKontierungstabelle: KontierungstabelleResponse;
  createKSUstKategorie: UstKategorieResponse;
  createKSVorschreibungsposition: VorschreibungspositionResponse;
  createKontierungstabelleTemplate: KontierungstabelleTemplateResponse;
  createKonto: KontoResponse;
  createKontoTemplate: KontoResponse;
  createKundenSystem: KundenSystemResponse;
  createLeerstehungAufwandskonto: FirmendatenLeerstehungAufwandskontoResponse;
  createMahndefinition: MahndefinitionResponse;
  createMahndefinitionTemplate: MahndefinitionResponse;
  createMahnungAusgebenGenerierlauf: MahnungAusgebenGenerierlaufResponse;
  createMahnungErzeugenGenerierlauf: MahnungErzeugenGenerierlaufResponse;
  createMahnungVerbuchenGenerierlauf: MahnungVerbuchenGenerierlaufResponse;
  createMahnungVersendenGenerierlauf: MahnungVersendenGenerierlaufResponse;
  createMitarbeiter: MitarbeiterResponse;
  createNatuerlichePerson: NatuerlichePersonResponse;
  createNatuerlichePersonAddress: AddressResponse;
  createNatuerlichePersonContact: ContactListResponse;
  createObjekt: ObjektResponse;
  createObjektAddress: ObjektAddressResponse;
  createObjektBankDetails: ObjektBankDetailsResponse;
  createObjektFinancialPlanGenerierlauf: ObjektFinancialPlanGenerierlaufResponse;
  createObjektInfoFeldPostIt: PostItResponse;
  createObjektInfoFeldTicket: TicketResponse;
  createObjektPostIt: PostItResponse;
  createObjektSummeAnteile: ObjektSummeAnteileResponse;
  createObjektTicket: TicketResponse;
  createOrUpdateColumnSettings: ColumnSettingsResponse;
  createOutgoingCallLog: CallLogResponse;
  createPauschalmieteAufwandskonto: FirmendatenPauschalmieteAufwandskontoResponse;
  createPayment: OrderPaymentResponse;
  createPaymentProposalCreationGenerierlauf: PaymentProposalCreationGenerierlaufResponse;
  createPaymentProposalExecutionGenerierlauf: PaymentProposalExecutionGenerierlaufResponse;
  createPersonenGemeinschaft: PersonengemeinschaftResponse;
  createPersonenGemeinschaftAddress: AddressResponse;
  createPersonenGemeinschaftContact: ContactListResponse;
  createPersonenbezug: PersonenbezugResponse;
  createReceivedBankTransactionVerbuchenGenerierlauf: ReceivedBankTransactionVerbuchenGenerierlaufResponse;
  createRechtstraegerBankDetails: BankDetailsResponse;
  createRechtstraegerInfoFeldPostIt: PostItResponse;
  createRechtstraegerPostIt: PostItResponse;
  createRechtstraegerSteuerpflicht: RechtstraegerSteuerpflichtResponse;
  createRechtstragerInfoFeldTicket: TicketResponse;
  createRechtstragerTicket: TicketResponse;
  createRepFondsEinstellung: RepFondsEinstellungResponse;
  createRepFondsEinstellungTemplate: RepFondsEinstellungResponse;
  createRepFondsErloesKontenZuweisung: RepFondsErloesKontenZuweisungResponse;
  createSepaMandat: SepaMandatResponse;
  createSingleInvoice: SingleInvoiceResponse;
  createStandingInvoice: StandingInvoiceResponse;
  createSubAdministrationAbrechnungsdefinitionForFD: SubAdministrationAbrechnungsdefinitionResponse;
  createSubAdministrationAbrechnungsdefinitionForKS: SubAdministrationAbrechnungsdefinitionResponse;
  createSubAdministrationAbrechnungsdefinitionTemplate: SubAdministrationAbrechnungsdefinitionResponse;
  createSubAdministrationAbrechnungsdefinitionTemplateVersion: SubAdministrationAbrechnungsdefinitionVersionResponse;
  createSubAdministrationAbrechnungsdefinitionVersion: SubAdministrationAbrechnungsdefinitionVersionResponse;
  createSubAdministrationPostIt: PostItResponse;
  createTextbaustein: GeneralTextbausteinResponse;
  createTextbausteinTemplate: GeneralTextbausteinResponse;
  createTicketComment: TicketCommentResponse;
  createTicketTemplate: TicketTemplateResponse;
  createTicketTemplateTemplate: TicketTemplateTemplateResponse;
  createTopFinancialPlanGenerierlauf: TopFinancialPlanGenerierlaufResponse;
  createUser: UserResponse;
  createUstKategorieTemplate: UstKategorieResponse;
  createUstKategorieTemplateVersion: UstKategorieVersionResponse;
  createUstKategorieVersion: UstKategorieVersionResponse;
  createUstRegelsetFirmendaten: UstRegelsetResponse;
  createUstRegelsetKundenSystem: UstRegelsetResponse;
  createUstRegelsetTemplate: UstRegelsetResponse;
  createUstRegelsetTemplateUstKategorieZuweisung: UstRegelsetUstKategorieZuweisungResponse;
  createUstRegelsetUstKategorieZuweisung: UstRegelsetUstKategorieZuweisungResponse;
  createUstVomAufwandGenerierlauf: UstVomAufwandGenerierlaufResponse;
  createVertragInfoFeldTicket: TicketResponse;
  createVertragTicket: TicketResponse;
  createVertragVposIndexationAgreementCategory: VertragVposIndexationAgreementResponse;
  createVertragVposIndexationAgreementIndexAnnual: VertragVposIndexationAgreementResponse;
  createVertragVposIndexationAgreementIndexThreshold: VertragVposIndexationAgreementResponse;
  createVertragVposIndexationAgreementReferenceValue: VertragVposIndexationAgreementResponse;
  createVertragVposIndexedValueGenerierlauf: VertragVposIndexedValueGenerierlaufResponse;
  createVorschreibungMailDeliveryWizard: EmailDeliveryWizardTemplateStepResponse;
  createVorschreibungspositionTemplate: VorschreibungspositionResponse;
  createVorschreibungspositionTemplateVersion: VorschreibungspositionVersionResponse;
  createVorschreibungspositionVersion: VorschreibungspositionVersionResponse;
  createVorsteuerkuerzungEinstellung: VorsteuerkuerzungEinstellungResponse;
  createVorsteuerkuerzungEinstellungTemplate: VorsteuerkuerzungEinstellungResponse;
  createVorsteuerkuerzungGenerierlauf: VorsteuerkuerzungGenerierlaufResponse;
  createWeAbrechnungsdefinitionForFD: WeAbrechnungsdefinitionResponse;
  createWeAbrechnungsdefinitionForKS: WeAbrechnungsdefinitionResponse;
  createWeAbrechnungsdefinitionTemplate: WeAbrechnungsdefinitionResponse;
  createWeAbrechnungsdefinitionTemplateVersion: WeAbrechnungsdefinitionVersionResponse;
  createWeAbrechnungsdefinitionVersion: WeAbrechnungsdefinitionVersionResponse;
  createZinsliste: ZinslisteResponse;
  createZinslisteGenerierlauf: ZinslisteGenerierlaufResponse;
  deleteAbrechnungsdefinition: ResponseWithoutData;
  deleteAbrechnungsdefinitionTemplate: ResponseWithoutData;
  deleteAbrechnungskreis: ResponseWithoutData;
  deleteAbrechnungskreisTemplate: ResponseWithoutData;
  deleteAddress: ResponseWithoutData;
  deleteAppFieldHelp: ResponseWithoutData;
  deleteAufteilungsschluessel: ResponseWithoutData;
  deleteAufteilungsschluesselTemplate: ResponseWithoutData;
  deleteAuftragsart: ResponseWithoutData;
  deleteAuftragsartTemplate: ResponseWithoutData;
  deleteAuftragsposition: ResponseWithoutData;
  deleteAuszahlung: ResponseWithoutData;
  deleteBankStatement: ResponseWithoutData;
  deleteBeAufteilungsschluesselWert: BeAufteilingsschluesselWertResponse;
  deleteBeInfoFeld: ResponseWithoutData;
  deleteBeNutzungsArt: BeNutzungsArtResponse;
  deleteBeOptionSteuerpflichtVersion: ResponseWithoutData;
  deleteBeVertrag: ResponseWithoutData;
  deleteBeVertragDokumentenversandEntry: ResponseWithoutData;
  deleteBeVertragInfoFeld: ResponseWithoutData;
  deleteBeVertragVersion: ResponseWithoutData;
  deleteBeVertragVorschreibungspositionWert: BeVertragVorschreibunspositionWertResponse;
  deleteBelegTexteFirmendaten: ResponseWithoutData;
  deleteBelegTexteRechtstraeger: ResponseWithoutData;
  deleteBelegnummernkreisDefinition: ResponseWithoutData;
  deleteBestandseinheit: ResponseWithoutData;
  deleteBestandseinheitNutzflaecheVersion: ResponseWithoutData;
  deleteBestandseinheitNutzwertVersion: ResponseWithoutData;
  deleteBkAbrechnungsdefinitionTemplateVersion: ResponseWithoutData;
  deleteBkAbrechnungsdefinitionVersion: ResponseWithoutData;
  deleteBudgeting: ResponseWithoutData;
  deleteBudgetingAbrDefVPosLinkage: ResponseWithoutData;
  deleteBudgetingAbrDefVPosLinkageTemplate: ResponseWithoutData;
  deleteBudgetingVPosAuftschluesselLinkage: ResponseWithoutData;
  deleteBudgetingVPosAuftschluesselLinkageTemplate: ResponseWithoutData;
  deleteColumnSettings: ResponseWithoutData;
  deleteContact: ResponseWithoutData;
  deleteContactPerson: ResponseWithoutData;
  deleteDataCarrierCamt: ResponseWithoutData;
  deleteEbicsBankConfig: ResponseWithoutData;
  deleteEbicsUser: ResponseWithoutData;
  deleteEmailDeliveryDefinition: ResponseWithoutData;
  deleteEmailDeliveryDefinitionTemplate: ResponseWithoutData;
  deleteEmailIntegration: ResponseWithoutData;
  deleteFibuBuchungsanweisung: ResponseWithoutData;
  deleteFibuFieldHelp: ResponseWithoutData;
  deleteFileForFirmendaten: FileInfoResponse;
  deleteFileForMitarbeiter: FileInfoResponse;
  deleteFirma: ResponseWithoutData;
  deleteFirmaOperatingSite: ResponseWithoutData;
  deleteFirmendaten: FirmendatenResponse;
  deleteFirmendatenBankDetails: ResponseWithoutData;
  deleteFirmendatenIndexSeries: ResponseWithoutData;
  deleteFirmendatenIndexSeriesEntry: ResponseWithoutData;
  deleteFirmendatenOperatingSite: OperatingSiteResponse;
  deleteHeAbrechnung: ResponseWithoutData;
  deleteHeAbrechnungsdefinitionTemplateVersion: ResponseWithoutData;
  deleteHeAbrechnungsdefinitionVersion: ResponseWithoutData;
  deleteHeVertrag: ResponseWithoutData;
  deleteHeVertragZahlungsregel: ResponseWithoutData;
  deleteIndexSeries: ResponseWithoutData;
  deleteIndexSeriesEntry: ResponseWithoutData;
  deleteInfoMailDeliveryAttachment: InfoMailDeliveryResponse;
  deleteInfoMailDeliveryWizard: ResponseWithoutData;
  deleteKontierungstabelle: ResponseWithoutData;
  deleteKontierungstabelleTemplate: ResponseWithoutData;
  deleteKonto: ResponseWithoutData;
  deleteKontoTemplate: ResponseWithoutData;
  deleteKundenSystem: KundenSystemResponse;
  deleteLeerstehungAufwandskonto: ResponseWithoutData;
  deleteMahndefinition: ResponseWithoutData;
  deleteMahndefinitionTemplate: ResponseWithoutData;
  deleteMahnung: ResponseWithoutData;
  deleteMitarbeiter: MitarbeiterResponse;
  deleteNatuerlichePerson: ResponseWithoutData;
  deleteObjekt: ResponseWithoutData;
  deleteObjektAbrechnung: ResponseWithoutData;
  deleteObjektBankDetails: ResponseWithoutData;
  deleteObjektFinancialPlan: ResponseWithoutData;
  deleteObjektFoto: ObjektResponse;
  deleteObjektInfoFeld: ResponseWithoutData;
  deleteObjektSummeAnteile: ResponseWithoutData;
  deleteOrderFieldHelp: ResponseWithoutData;
  deletePauschalmieteAufwandskonto: ResponseWithoutData;
  deletePayment: ResponseWithoutData;
  deletePaymentFieldHelp: ResponseWithoutData;
  deletePaymentProposal: ResponseWithoutData;
  deletePersonenGemeinschaft: ResponseWithoutData;
  deletePersonenbezug: ResponseWithoutData;
  deletePostIt: ResponseWithoutData;
  deleteRechtstraegerBankDetails: ResponseWithoutData;
  deleteRechtstraegerInfoFeld: ResponseWithoutData;
  deleteRechtstraegerSteuerpflicht: ResponseWithoutData;
  deleteRepFondsEinstellung: ResponseWithoutData;
  deleteRepFondsEinstellungTemplate: ResponseWithoutData;
  deleteRepFondsErloesKontenZuweisung: ResponseWithoutData;
  deleteSingleInvoice: ResponseWithoutData;
  deleteStandingInvoice: ResponseWithoutData;
  deleteSubAbrechnung: ResponseWithoutData;
  deleteSubAdministrationAbrechnungsdefinitionTemplateVersion: ResponseWithoutData;
  deleteSubAdministrationAbrechnungsdefinitionVersion: ResponseWithoutData;
  deleteTextbaustein: ResponseWithoutData;
  deleteTextbausteinTemplate: ResponseWithoutData;
  deleteTicketAttachment: TicketResponse;
  deleteTicketComment: ResponseWithoutData;
  deleteTicketTemplate: ResponseWithoutData;
  deleteTicketTemplateTemplate: ResponseWithoutData;
  deleteTopAbrechnungen: ResponseWithoutData;
  deleteTopFinancialPlan: ResponseWithoutData;
  deleteUser: UserResponse;
  deleteUstKategorie: ResponseWithoutData;
  deleteUstKategorieTemplate: ResponseWithoutData;
  deleteUstKategorieTemplateVersion: ResponseWithoutData;
  deleteUstKategorieVersion: ResponseWithoutData;
  deleteUstRegelset: ResponseWithoutData;
  deleteUstRegelsetTemplate: ResponseWithoutData;
  deleteUstRegelsetTemplateUstKategorieZuweisung: ResponseWithoutData;
  deleteUstRegelsetUstKategorieZuweisung: ResponseWithoutData;
  deleteUstVomAufwand: ResponseWithoutData;
  deleteVertragVposIndexationAgreement: ResponseWithoutData;
  deleteVertragVposIndexedValue: ResponseWithoutData;
  deleteVorschreibungMailDeliveryAttachment: VorschreibungMailDeliveryResponse;
  deleteVorschreibungMailDeliveryWizard: ResponseWithoutData;
  deleteVorschreibungsposition: ResponseWithoutData;
  deleteVorschreibungspositionTemplate: ResponseWithoutData;
  deleteVorschreibungspositionTemplateVersion: ResponseWithoutData;
  deleteVorschreibungspositionVersion: ResponseWithoutData;
  deleteVorsteuerkuerzung: ResponseWithoutData;
  deleteVorsteuerkuerzungEinstellung: ResponseWithoutData;
  deleteVorsteuerkuerzungEinstellungTemplate: ResponseWithoutData;
  deleteWeAbrechnungsdefinitionTemplateVersion: ResponseWithoutData;
  deleteWeAbrechnungsdefinitionVersion: ResponseWithoutData;
  deleteZinsliste: ResponseWithoutData;
  doNotificationAction: ActionResponse;
  getFirmaKurzbezeichnungVorschlag: StringResponse;
  getKurzbezeichnungVorschlag: StringResponse;
  getNatuerlichePersonKurzbezeichnungVorschlag: StringResponse;
  getPersonengemeinschaftKurzbezeichnungVorschlag: StringResponse;
  mahnungenEntsperren: IntResponse;
  mahnungenFreigeben: IntResponse;
  mahnungenLoeschen: IntResponse;
  mahnungenSperren: IntResponse;
  markAsNotedPaymentBulkAction: IntResponse;
  markAsNotedReceivedBankTransactionBulkAction: ResponseWithoutData;
  removeReceivedBankTransactionPaymentAssignment: ResponseWithoutData;
  setObjektAufteilungsschluesselList: ObjektAufteilungsschluesselListResponse;
  setObjektVorschreibungspositionList: ObjektVorschreibungspositionListResponse;
  setObjektVorschreibungspositionVertragsartList: ObjektVorschreibungspositionsResponse;
  singleInvoiceAbgrenzungsBuchungPreview: AbgrenzungsBuchungPreviewResponse;
  singleInvoiceAbgrenzungsStornoBuchungPreview: AbgrenzungsStornoBuchungPreviewResponse;
  singleInvoiceBuchungPreview: BuchungPreviewResponse;
  singleInvoiceStornoBuchungPreview: StornoBuchungPreviewResponse;
  standingInvoiceBuchungPreview: StandingInvoiceBuchungPreviewResponse;
  standingInvoiceStornoBuchungPreview: StandingInvoiceStornoBuchungPreviewResponse;
  standingInvoiceUpdatePreview: StandingInvoicePartialAmountUpdatePreviewResponse;
  triggerPasswordReset: ResponseEntity;
  updateAbrechnungskreis: AbrechnungskreisResponse;
  updateAbrechnungskreisTemplate: AbrechnungskreisTemplateResponse;
  updateAddress: AddressResponse;
  updateAppFieldHelp: FieldHelpResponse;
  updateAufteilungsschluessel: AufteilungsschluesselResponse;
  updateAufteilungsschluesselTemplate: AufteilungsschluesselResponse;
  updateAuftragsart: AuftragsartResponse;
  updateAuftragsartTemplate: AuftragsartTemplateResponse;
  updateAufwandAbgrenzungskonto: FirmendatenBuchhaltungEinstellungResponse;
  updateBeAufteilungsschluesselWertMitBezug: BeAufteilingsschluesselWertResponse;
  updateBeAufteilungsschluesselWertOhneBezug: BeAufteilingsschluesselWertResponse;
  updateBeNutzungsArt: BeNutzungsArtResponse;
  updateBeOptionSteuerpflichtVersion: BeOptionSteuerpflichtVersionResponse;
  updateBeVertrag: BeVertragResponse;
  updateBeVertragDokumentenversandEntry: DokumentenversandEntryResponse;
  updateBeVertragVersion: BeVertragVersionResponse;
  updateBeVertragVorschreibungspositionWert: BeVertragVorschreibunspositionWertResponse;
  updateBelegnummernkreisDefinition: BelegnummernkreisDefinitionResponse;
  updateBestandseinheit: BestandseinheitResponse;
  updateBestandseinheitNutzflaecheVersion: BestandseinheitNutzflaecheVersionResponse;
  updateBestandseinheitNutzwertVersion: BestandseinheitNutzwertVersionResponse;
  updateBkAbrechnungsdefinition: BkAbrechnungsdefinitionResponse;
  updateBkAbrechnungsdefinitionTemplate: BkAbrechnungsdefinitionResponse;
  updateBkAbrechnungsdefinitionTemplateVersion: BkAbrechnungsdefinitionVersionResponse;
  updateBkAbrechnungsdefinitionVersion: BkAbrechnungsdefinitionVersionResponse;
  updateBookingSuggestion: BookingSuggestionResponse;
  updateBudgetingAbrDefVPosLinkage: BudgetingAbrDefVPosLinkageResponse;
  updateBudgetingAbrDefVPosLinkageTemplate: BudgetingAbrDefVPosLinkageResponse;
  updateBudgetingVPosAuftschluesselLinkage: BudgetingVPosAuftschluesselLinkageResponse;
  updateBudgetingVPosAuftschluesselLinkageTemplate: BudgetingVPosAuftschluesselLinkageResponse;
  updateCallLog: CallLogResponse;
  updateContact: ContactResponse;
  updateEbicsBankConfig: EbicsBankConfigResponse;
  updateEbicsUser: EbicsUserResponse;
  updateEmailDeliveryDefinition: EmailDeliveryDefinitionResponse;
  updateEmailDeliveryDefinitionTemplate: EmailDeliveryDefinitionTemplateResponse;
  updateFibuEinstellung: FibuEinstellungResponse;
  updateFibuFieldHelp: FieldHelpResponse;
  updateFirma: FirmaResponse;
  updateFirmaContactPerson: ContactPersonResponse;
  updateFirmaOperatingSite: OperatingSiteResponse;
  updateFirmendaten: FirmendatenResponse;
  updateFirmendatenBankDetails: FirmendatenBankDetailsResponse;
  updateFirmendatenBankSettings: BankSettingsResponse;
  updateFirmendatenBuchhaltungEinstellung: FirmendatenBuchhaltungEinstellungResponse;
  updateFirmendatenContactPerson: ContactPersonResponse;
  updateFirmendatenIndexSeries: IndexSeriesResponse;
  updateFirmendatenIndexSeriesEntry: IndexSeriesEntryResponse;
  updateFirmendatenOperatingSite: OperatingSiteResponse;
  updateFirmeneinstellung: FirmeneinstellungResponse;
  updateHeAbrechnungsdefinition: HeAbrechnungsdefinitionResponse;
  updateHeAbrechnungsdefinitionTemplate: HeAbrechnungsdefinitionResponse;
  updateHeAbrechnungsdefinitionTemplateVersion: HeAbrechnungsdefinitionVersionResponse;
  updateHeAbrechnungsdefinitionVersion: HeAbrechnungsdefinitionVersionResponse;
  updateHeVertrag: HeVertragResponse;
  updateHeVertragZahlungsregel: HeVertragZahlungsregelResponse;
  updateIndexSeries: IndexSeriesResponse;
  updateIndexSeriesEntry: IndexSeriesEntryResponse;
  updateIndexValueCreationCronJobSettings: IndexValueCreationCronJobSettingsResponse;
  updateInfoMailDelivery: InfoMailDeliveryResponse;
  updateKontierungstabelle: KontierungstabelleResponse;
  updateKontierungstabelleTemplate: KontierungstabelleTemplateResponse;
  updateKonto: KontoResponse;
  updateKontoTemplate: KontoResponse;
  updateKundenSystem: KundenSystemResponse;
  updateLeerstehungAufwandskonto: FirmendatenLeerstehungAufwandskontoResponse;
  updateLeerstehungForderungskonto: FirmendatenBuchhaltungEinstellungResponse;
  updateMahndefinition: MahndefinitionResponse;
  updateMahndefinitionTemplate: MahndefinitionResponse;
  updateMahnung: MahnungResponse;
  updateMitarbeiter: MitarbeiterResponse;
  updateNatuerlichePerson: NatuerlichePersonResponse;
  updateNatuerlichePersonAddress: AddressResponse;
  updateObjekt: ObjektResponse;
  updateObjektAufteilungsschluessel: ObjektAufteilungsschluesselResponse;
  updateObjektBankDetails: ObjektBankDetailsResponse;
  updateObjektSummeAnteile: ObjektSummeAnteileResponse;
  updateObjektVorschreibungsposition: ObjektVorschreibungspositionsResponse;
  updateOrderFieldHelp: FieldHelpResponse;
  updatePauschalmieteAufwandskonto: FirmendatenPauschalmieteAufwandskontoResponse;
  updatePauschalmieteForderungskonto: FirmendatenBuchhaltungEinstellungResponse;
  updatePayment: OrderPaymentResponse;
  updatePaymentFieldHelp: FieldHelpResponse;
  updatePersonenGemeinschaft: PersonengemeinschaftResponse;
  updatePersonenGemeinschaftAddress: AddressResponse;
  updatePostIt: PostItResponse;
  updateRechtstraegerBankDetails: BankDetailsResponse;
  updateRechtstraegerSteuerpflicht: RechtstraegerSteuerpflichtResponse;
  updateRepFondsEinstellung: RepFondsEinstellungResponse;
  updateRepFondsEinstellungTemplate: RepFondsEinstellungResponse;
  updateRepFondsErloesKontenZuweisung: RepFondsErloesKontenZuweisungResponse;
  updateSingleInvoice: SingleInvoiceResponse;
  updateStandingInvoice: StandingInvoiceResponse;
  updateSubAdministration: SubAdministrationResponse;
  updateSubAdministrationAbrechnungsdefinition: SubAdministrationAbrechnungsdefinitionResponse;
  updateSubAdministrationAbrechnungsdefinitionTemplate: SubAdministrationAbrechnungsdefinitionResponse;
  updateSubAdministrationAbrechnungsdefinitionTemplateVersion: SubAdministrationAbrechnungsdefinitionVersionResponse;
  updateSubAdministrationAbrechnungsdefinitionVersion: SubAdministrationAbrechnungsdefinitionVersionResponse;
  updateTextbaustein: GeneralTextbausteinResponse;
  updateTextbausteinTemplate: GeneralTextbausteinResponse;
  updateTicket: TicketResponse;
  updateTicketComment: TicketCommentResponse;
  updateTicketTemplate: TicketTemplateResponse;
  updateTicketTemplateTemplate: TicketTemplateTemplateResponse;
  updateUser: UserResponse;
  updateUstKategorie: UstKategorieResponse;
  updateUstKategorieEntry: UstKategorieEntryResponse;
  updateUstKategorieTemplate: UstKategorieResponse;
  updateUstKategorieTemplateVersion: UstKategorieVersionResponse;
  updateUstKategorieVersion: UstKategorieVersionResponse;
  updateUstRegelset: UstRegelsetResponse;
  updateUstRegelsetTemplate: UstRegelsetResponse;
  updateUstRegelsetTemplateUstKategorieZuweisung: UstRegelsetUstKategorieZuweisungResponse;
  updateUstRegelsetUstKategorieZuweisung: UstRegelsetUstKategorieZuweisungResponse;
  updateVertragVposIndexationAgreementCategory: VertragVposIndexationAgreementResponse;
  updateVertragVposIndexationAgreementIndexAnnual: VertragVposIndexationAgreementResponse;
  updateVertragVposIndexationAgreementIndexThreshold: VertragVposIndexationAgreementResponse;
  updateVertragVposIndexationAgreementReferenceValue: VertragVposIndexationAgreementResponse;
  updateVertragVposIndexedValue: VertragVposIndexedValueResponse;
  updateVorschreibungMailDelivery: VorschreibungMailDeliveryResponse;
  updateVorschreibungsposition: VorschreibungspositionResponse;
  updateVorschreibungspositionTemplate: VorschreibungspositionResponse;
  updateVorschreibungspositionTemplateVersion: VorschreibungspositionVersionResponse;
  updateVorschreibungspositionVersion: VorschreibungspositionVersionResponse;
  updateVorsteuerkuerzungEinstellung: VorsteuerkuerzungEinstellungResponse;
  updateVorsteuerkuerzungEinstellungTemplate: VorsteuerkuerzungEinstellungResponse;
  updateWeAbrechnungsdefinition: WeAbrechnungsdefinitionResponse;
  updateWeAbrechnungsdefinitionTemplate: WeAbrechnungsdefinitionResponse;
  updateWeAbrechnungsdefinitionTemplateVersion: WeAbrechnungsdefinitionVersionResponse;
  updateWeAbrechnungsdefinitionVersion: WeAbrechnungsdefinitionVersionResponse;
  validateIban: IbanResponse;
  validateVatIdentificationNumber: VidValidationResponse;
};

export type MutationActionActivateEbicsUserArgs = {
  ebicsUserId: Scalars['ID']['input'];
};

export type MutationActionActivateEbicsUserIbanArgs = {
  ebicsUserIbanId: Scalars['ID']['input'];
  ebicsUserId: Scalars['ID']['input'];
};

export type MutationActionActivateUserArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationActionAddKontoForFirmendatenBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
};

export type MutationActionAddKontoForRechtstraegerBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationActionApplyVertragVposIndexedValueArgs = {
  bestandseinheitId: Scalars['String']['input'];
  indexationAgreementId: Scalars['String']['input'];
  indexedValueId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  vertragId: Scalars['String']['input'];
  vorschreibungspositionId: Scalars['String']['input'];
};

export type MutationActionApprovePaymentArgs = {
  paymentId: Scalars['ID']['input'];
};

export type MutationActionArchiveAbrechnungsdefinitionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type MutationActionArchiveAbrechnungskreisArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
};

export type MutationActionArchiveAbrechnungskreisTemplateArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
};

export type MutationActionArchiveAufteilungsschluesselArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
};

export type MutationActionArchiveAufteilungsschluesselTemplateArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
};

export type MutationActionArchiveAuftragsartArgs = {
  auftragsartId: Scalars['ID']['input'];
};

export type MutationActionArchiveBeInfoFeldArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionArchiveBeVertragInfoFeldArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationActionArchiveBelegnummernkreisDefinitionArgs = {
  belegnummernkreisDefinitionId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationActionArchiveBestandseinheitArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionArchiveFirmaArgs = {
  firmaId: Scalars['ID']['input'];
};

export type MutationActionArchiveFirmendatenBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
};

export type MutationActionArchiveFirmendatenIndexSeriesArgs = {
  indexSeriesId: Scalars['ID']['input'];
};

export type MutationActionArchiveHeVertragZahlungsregelArgs = {
  heVertragId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  zahlungsregelId: Scalars['ID']['input'];
};

export type MutationActionArchiveKontierungstabelleArgs = {
  kontierungstabelleId: Scalars['ID']['input'];
};

export type MutationActionArchiveKontierungstabelleTemplateArgs = {
  kontierungstabelleId: Scalars['ID']['input'];
};

export type MutationActionArchiveKontoArgs = {
  kontoId: Scalars['ID']['input'];
};

export type MutationActionArchiveKontoTemplateArgs = {
  kontoId: Scalars['ID']['input'];
};

export type MutationActionArchiveMahndefinitionArgs = {
  mahndefinitionId: Scalars['ID']['input'];
};

export type MutationActionArchiveNatuerlichePersonArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationActionArchiveObjektArgs = {
  objektId: Scalars['ID']['input'];
};

export type MutationActionArchiveObjektAufteilungsschluesselArgs = {
  objektAufteilungsschluesselId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionArchiveObjektInfoFeldArgs = {
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionArchiveObjektVorschreibungspositionArgs = {
  objektId: Scalars['ID']['input'];
  objektVorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationActionArchivePersonenGemeinschaftArgs = {
  personenGemeinschaftId: Scalars['ID']['input'];
};

export type MutationActionArchiveReceivedBankTransactionArgs = {
  receivedBankTransactionId: Scalars['ID']['input'];
};

export type MutationActionArchiveRechtstraegerBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationActionArchiveRechtstraegerInfoFeldArgs = {
  infoFeldId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationActionArchiveTicketArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationActionArchiveUstKategorieArgs = {
  ustKategorieId: Scalars['ID']['input'];
};

export type MutationActionArchiveVorschreibungspositionArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationActionArchiveVorschreibungspositionTemplateArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationActionAssignReceivedBankTransactionArgs = {
  paymentId: Scalars['ID']['input'];
  receivedBankTransactionId: Scalars['ID']['input'];
};

export type MutationActionAuftragApplyAllDeviationsArgs = {
  auftragId: Scalars['ID']['input'];
};

export type MutationActionAuftragStornierenArgs = {
  auftragId: Scalars['ID']['input'];
};

export type MutationActionAuszifferungStornierenArgs = {
  auszifferungId: Scalars['String']['input'];
  zahlungId: Scalars['String']['input'];
};

export type MutationActionBeVertragApplyAllDeviationsArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationActionBeVertragMarkUnderReviewArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationActionBeVertragPruefungAktivierenArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationActionBeVertragPruefungAussetzenArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationActionBookReceivedBankTransactionArgs = {
  buchungsdatum: Scalars['String']['input'];
  receivedBankTransactionId: Scalars['ID']['input'];
};

export type MutationActionBuchungsanweisungApplyAllDeviationsArgs = {
  buchungsanweisungId: Scalars['String']['input'];
  buchungsdatum: Scalars['String']['input'];
};

export type MutationActionBudgetingAdjustAufteilungsschluesselArgs = {
  budgetingId: Scalars['ID']['input'];
  input: BudgetingActionAdjustAufteilungsschluesselInput;
  objektId: Scalars['ID']['input'];
};

export type MutationActionBudgetingArchivierenArgs = {
  budgetingId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionBudgetingConfigureFinancialPlanTextsArgs = {
  budgetingId: Scalars['ID']['input'];
  input: BudgetingActionConfigureFinancialPlanTextsInput;
  objektId: Scalars['ID']['input'];
};

export type MutationActionBudgetingCreateVPosValuesFromAufwandArgs = {
  budgetingId: Scalars['ID']['input'];
  input: BudgetingActionCreateVPosValuesFromAufwandInput;
  objektId: Scalars['ID']['input'];
};

export type MutationActionBudgetingCreateVPosValuesFromVPosArgs = {
  budgetingId: Scalars['ID']['input'];
  input: BudgetingActionCreateVPosValuesFromVPosInput;
  objektId: Scalars['ID']['input'];
};

export type MutationActionBudgetingDeleteVPosValuesArgs = {
  budgetingId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionBudgetingReaktivierenArgs = {
  budgetingId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionBudgetingResetKontoEstimateArgs = {
  budgetingId: Scalars['ID']['input'];
  input: BudgetingActionResetKontoInput;
  objektId: Scalars['ID']['input'];
};

export type MutationActionBudgetingTransferToVPosArgs = {
  budgetingId: Scalars['ID']['input'];
  input: BudgetingActionTransferToVPosActionInput;
  objektId: Scalars['ID']['input'];
};

export type MutationActionBudgetingUpdateKontoEstimateArgs = {
  budgetingId: Scalars['ID']['input'];
  input: BudgetingActionUpdateKontoInput;
  objektId: Scalars['ID']['input'];
};

export type MutationActionBudgetingUpdateVPosEstimateArgs = {
  budgetingId: Scalars['ID']['input'];
  input: BudgetingActionUpdateVPosInput;
  objektId: Scalars['ID']['input'];
};

export type MutationActionChangeAccountLimitForFirmendatenBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
  input: BankDetailsChangeAccountLimitInput;
};

export type MutationActionChangeAccountLimitForRechtstraegerBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  input: BankDetailsChangeAccountLimitInput;
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationActionChangePasswordMeArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type MutationActionChangePositionBestandseinheitArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  newPosition: Scalars['Int']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionChangeToDatentraegerErzeugtPaymentArgs = {
  paymentId: Scalars['ID']['input'];
};

export type MutationActionChangeToDurchfuehrbarPaymentArgs = {
  paymentId: Scalars['ID']['input'];
};

export type MutationActionChangeToNichtDurchfuehrbarPaymentArgs = {
  paymentId: Scalars['ID']['input'];
};

export type MutationActionChangeToNichtDurchgefuehrtPaymentArgs = {
  input: PaymentActionInput;
  paymentId: Scalars['ID']['input'];
};

export type MutationActionCompleteInfoMailDetermineRecipientListStepArgs = {
  infoMailDeliveryWizardId: Scalars['ID']['input'];
};

export type MutationActionCompleteVorschreibungMailDetermineRecipientListStepArgs = {
  vorschreibungMailDeliveryWizardId: Scalars['ID']['input'];
};

export type MutationActionCreateBuchungskreisForRechtstraegerArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationActionDeactivateEbicsUserArgs = {
  ebicsUserId: Scalars['ID']['input'];
};

export type MutationActionDeactivateEbicsUserIbanArgs = {
  ebicsUserIbanId: Scalars['ID']['input'];
  ebicsUserId: Scalars['ID']['input'];
};

export type MutationActionDismissNotificationArgs = {
  request: NotificationDismissInput;
};

export type MutationActionDoNotApplyVertragVposIndexedValueArgs = {
  bestandseinheitId: Scalars['String']['input'];
  indexationAgreementId: Scalars['String']['input'];
  indexedValueId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  vertragId: Scalars['String']['input'];
  vorschreibungspositionId: Scalars['String']['input'];
};

export type MutationActionDoNotApplyVertragVposIndexedValueFirmendatenArgs = {
  input: VertragVposIndexedValueUpdateStatusBulkActionInput;
};

export type MutationActionEigenbelegMarkUnderReviewArgs = {
  belegId: Scalars['ID']['input'];
};

export type MutationActionEntsperrenFirmaArgs = {
  firmaId: Scalars['ID']['input'];
};

export type MutationActionEntsperrenFirmendatenArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type MutationActionEntsperrenMahnungArgs = {
  mahnungId: Scalars['ID']['input'];
};

export type MutationActionEntsperrenMitarbeiterArgs = {
  firmendatenId: Scalars['String']['input'];
  mitarbeiterId: Scalars['ID']['input'];
};

export type MutationActionEntsperrenNatuerlichePersonArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationActionEntsperrenPersonenGemeinschaftArgs = {
  personenGemeinschaftId: Scalars['ID']['input'];
};

export type MutationActionEntsperrenUserArgs = {
  username: Scalars['String']['input'];
};

export type MutationActionFreigebenMahnungArgs = {
  mahnungId: Scalars['ID']['input'];
};

export type MutationActionHeAbrechnungApplyAllDeviationsArgs = {
  heAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionHeAbrechnungMarkUnderReviewArgs = {
  heAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionMarkReceivedBankTransactionAsNotedArgs = {
  receivedBankTransactionId: Scalars['ID']['input'];
};

export type MutationActionObjektAbrechnungApplyAllDeviationsArgs = {
  objektAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionObjektAbrechnungMarkUnderReviewArgs = {
  objektAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionObjektTopAbrechnungApplyAllDeviationsArgs = {
  objektAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionReimportDataCarrierCamtArgs = {
  dataCarrierId: Scalars['ID']['input'];
};

export type MutationActionRemoveAssignmentReceivedBankTransactionArgs = {
  receivedBankTransactionId: Scalars['ID']['input'];
};

export type MutationActionResendEinladungMitarbeiterArgs = {
  firmendatenId: Scalars['String']['input'];
  mitarbeiterId: Scalars['ID']['input'];
};

export type MutationActionResendInfoMailDeliveryArgs = {
  infoMailDeliveryId: Scalars['ID']['input'];
  recipient: Scalars['String']['input'];
};

export type MutationActionResendVorschreibungMailDeliveryArgs = {
  recipient: Scalars['String']['input'];
  vorschreibungMailDeliveryId: Scalars['ID']['input'];
};

export type MutationActionRestartApplyVertragVposIndexedValueGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartAuftragGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartAuftragVerbuchenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartBookingSuggestionCreationGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartBudgetingGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartEigenbelegErstellenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartFirmendatenInitializerGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartHeAbrechnungGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartIncomingInvoiceBookingCreationGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartInfoMailDeliveryDetermineRecipientListGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartInfoMailDeliverySendGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartMahnungAusgebenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartMahnungErzeugenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartMahnungVerbuchenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartMahnungVersendenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartObjektAbrechnungGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartObjektFinancialPlanGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartPaymentProposalCreationGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartPaymentProposalExecutionGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartSubAbrechnungGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartTopAbrechnungGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartTopFinancialPlanGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartUstVomAufwandGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartVertragVposIndexedValueGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartVorschreibungMailDeliverySendGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartVorsteuerkuerzungGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestartZinslisteGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type MutationActionRestoreDefaultFirmeneinstellungArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type MutationActionSendInfoMailArgs = {
  infoMailDeliveryWizardId: Scalars['ID']['input'];
};

export type MutationActionSendVorschreibungMailArgs = {
  vorschreibungMailDeliveryWizardId: Scalars['ID']['input'];
};

export type MutationActionSetAbrechnungsdefinitionForRechtstraegerArgs = {
  input: SetAbrechnungsdefinitionForRechtstraegerInput;
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationActionSetAddressTagListForAddressArgs = {
  addressId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
  input: AddressAndContactTagListInput;
};

export type MutationActionSetContactPersonTagListForFirmaContactPersonArgs = {
  contactPersonId: Scalars['ID']['input'];
  firmaId: Scalars['String']['input'];
  input: OperatingSiteActionInput;
};

export type MutationActionSetContactPersonTagListForFirmendatenContactPersonArgs = {
  contactPersonId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
  input: OperatingSiteActionInput;
};

export type MutationActionSetContactTagListForContactArgs = {
  contactId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
  input: AddressAndContactTagListInput;
};

export type MutationActionSetKontoTagListArgs = {
  input: KontoTagListInput;
  kontoId: Scalars['ID']['input'];
};

export type MutationActionSetKontoTemplateTagListArgs = {
  input: KontoTagListInput;
  kontoId: Scalars['ID']['input'];
};

export type MutationActionSetMahndefinitionForRechtstraegerArgs = {
  input: SetMahndefinitionForRechtstraegerInput;
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationActionSetMahnsperreForRechtstraegerArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  request: SetMahnsperreForRechtstraegerInput;
};

export type MutationActionSetOperatingSiteForAddressArgs = {
  addressId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
  input: OperatingSiteActionInput;
};

export type MutationActionSetOperatingSiteForContactArgs = {
  contactId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
  input: OperatingSiteActionInput;
};

export type MutationActionSetOperatingSiteForFirmaContactPersonArgs = {
  contactPersonId: Scalars['ID']['input'];
  firmaId: Scalars['String']['input'];
  input: OperatingSiteActionInput;
};

export type MutationActionSetOperatingSiteForFirmendatenContactPersonArgs = {
  contactPersonId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
  input: OperatingSiteActionInput;
};

export type MutationActionSetPersonenbezugTagListArgs = {
  input: PersonenbezugTagListInput;
  personenbezugId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationActionSetVerwendungForKontoForRechtstraegerBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  input: SetVerwendungForKontoForRechtstraegerInput;
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationActionSingleInvoiceFreigebenArgs = {
  belegId: Scalars['String']['input'];
};

export type MutationActionSingleInvoiceRestoreArgs = {
  belegId: Scalars['String']['input'];
};

export type MutationActionSperrenFirmaArgs = {
  firmaId: Scalars['ID']['input'];
};

export type MutationActionSperrenFirmendatenArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type MutationActionSperrenMahnungArgs = {
  mahnungId: Scalars['ID']['input'];
};

export type MutationActionSperrenMitarbeiterArgs = {
  firmendatenId: Scalars['String']['input'];
  mitarbeiterId: Scalars['ID']['input'];
};

export type MutationActionSperrenNatuerlichePersonArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationActionSperrenPersonenGemeinschaftArgs = {
  personenGemeinschaftId: Scalars['ID']['input'];
};

export type MutationActionSperrenUserArgs = {
  username: Scalars['String']['input'];
};

export type MutationActionStandingInvoiceFreigebenArgs = {
  belegId: Scalars['String']['input'];
};

export type MutationActionStandingInvoiceRestoreArgs = {
  belegId: Scalars['String']['input'];
};

export type MutationActionSubmitVertragVposIndexedValueArgs = {
  bestandseinheitId: Scalars['String']['input'];
  indexationAgreementId: Scalars['String']['input'];
  indexedValueId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  vertragId: Scalars['String']['input'];
  vorschreibungspositionId: Scalars['String']['input'];
};

export type MutationActionSubmitVertragVposIndexedValueFirmendatenArgs = {
  input: VertragVposIndexedValueUpdateStatusBulkActionInput;
};

export type MutationActionTicketChangeSubTicketPositionArgs = {
  input: TicketActionChangeSubTicketPositionInput;
  ticketId: Scalars['ID']['input'];
};

export type MutationActionTicketCreateSubTicketArgs = {
  input: TicketActionCreateSubTicketInput;
  ticketId: Scalars['ID']['input'];
};

export type MutationActionTicketParticipantArgs = {
  input: TicketParticipantActionInput;
  ticketId: Scalars['ID']['input'];
};

export type MutationActionTicketTemplateChangePositionArgs = {
  input: TicketTemplateActionChangePositionInput;
  ticketTemplateId: Scalars['ID']['input'];
};

export type MutationActionTicketTemplateCreateSubTicketTemplateArgs = {
  input: TicketTemplateActionCreateSubTicketTemplateInput;
  ticketTemplateId: Scalars['ID']['input'];
};

export type MutationActionTicketTemplateTemplateChangePositionArgs = {
  input: TicketTemplateActionChangePositionInput;
  ticketTemplateId: Scalars['ID']['input'];
};

export type MutationActionTicketTemplateTemplateCreateSubTicketTemplateArgs = {
  input: TicketTemplateActionCreateSubTicketTemplateInput;
  ticketTemplateId: Scalars['ID']['input'];
};

export type MutationActionTicketUpdateStatusArgs = {
  input: TicketActionUpdateStatusInput;
  ticketId: Scalars['ID']['input'];
};

export type MutationActionTriggerChangeEmailArgs = {
  email: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationActionTriggerChangeEmailMeArgs = {
  email: Scalars['String']['input'];
};

export type MutationActionTriggerPasswordResetUserArgs = {
  username: Scalars['String']['input'];
};

export type MutationActionUnarchiveAbrechnungsdefinitionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveAbrechnungskreisArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveAbrechnungskreisTemplateArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveAufteilungsschluesselArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveAufteilungsschluesselTemplateArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveAuftragsartArgs = {
  auftragsartId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveBeInfoFeldArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveBeVertragInfoFeldArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveBelegnummernkreisDefinitionArgs = {
  belegnummernkreisDefinitionId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationActionUnarchiveBestandseinheitArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveFirmaArgs = {
  firmaId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveFirmendatenBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
};

export type MutationActionUnarchiveFirmendatenIndexSeriesArgs = {
  indexSeriesId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveHeVertragZahlungsregelArgs = {
  heVertragId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  zahlungsregelId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveKontierungstabelleArgs = {
  kontierungstabelleId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveKontierungstabelleTemplateArgs = {
  kontierungstabelleId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveKontoArgs = {
  kontoId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveKontoTemplateArgs = {
  kontoId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveMahndefinitionArgs = {
  mahndefinitionId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveNatuerlichePersonArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveObjektArgs = {
  objektId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveObjektAufteilungsschluesselArgs = {
  objektAufteilungsschluesselId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveObjektInfoFeldArgs = {
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveObjektVorschreibungspositionArgs = {
  objektId: Scalars['ID']['input'];
  objektVorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationActionUnarchivePersonenGemeinschaftArgs = {
  personenGemeinschaftId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveReceivedBankTransactionArgs = {
  receivedBankTransactionId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveRechtstraegerBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationActionUnarchiveRechtstraegerInfoFeldArgs = {
  infoFeldId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveTicketArgs = {
  ticketId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveUstKategorieArgs = {
  ustKategorieId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveVorschreibungspositionArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationActionUnarchiveVorschreibungspositionTemplateArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationActionUpdateRechtstreagerBankSettingsArgs = {
  input: RechtstraegerBankSettingsUpdateInput;
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationActionUpdateSubAdministrationSettingsArgs = {
  input: UpdateSubAdministrationSettingsInput;
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationActionUpdateValidFromVertragVposIndexedValueFirmendatenArgs = {
  input: VertragVposIndexedValueUpdateValidFromBulkActionInput;
};

export type MutationActionUploadManuallyDataCarrierPainArgs = {
  dataCarrierId: Scalars['ID']['input'];
};

export type MutationActionUploadedDataCarrierPainArgs = {
  dataCarrierId: Scalars['ID']['input'];
};

export type MutationActionUstVomAufwandApplyAllDeviationsArgs = {
  input?: InputMaybe<UstVomAufwandActionApplyDeviationsInput>;
  objektId: Scalars['String']['input'];
  ustVomAufwandId: Scalars['ID']['input'];
};

export type MutationActionUstVomAufwandFreigebenArgs = {
  input: UstVomAufwandActionFreigebenInput;
  objektId: Scalars['ID']['input'];
  ustVomAufwandId: Scalars['ID']['input'];
};

export type MutationActionUstVomAufwandMarkUnderReviewArgs = {
  objektId: Scalars['ID']['input'];
  ustVomAufwandId: Scalars['ID']['input'];
};

export type MutationActionVorsteuerkuerzungApplyAllDeviationsArgs = {
  input?: InputMaybe<VorsteuerkuerzungActionApplyDeviationsInput>;
  objektId: Scalars['String']['input'];
  vorsteuerkuerzungId: Scalars['ID']['input'];
};

export type MutationActionVorsteuerkuerzungFreigebenArgs = {
  input: VorsteuerkuerzungActionFreigebenInput;
  objektId: Scalars['String']['input'];
  vorsteuerkuerzungId: Scalars['ID']['input'];
};

export type MutationActionVorsteuerkuerzungMarkUnderReviewArgs = {
  objektId: Scalars['String']['input'];
  vorsteuerkuerzungId: Scalars['ID']['input'];
};

export type MutationActionZinslisteApplyAllDeviationsArgs = {
  objektId: Scalars['String']['input'];
  zinslisteId: Scalars['ID']['input'];
};

export type MutationActionZinslisteMarkUnderReviewArgs = {
  objektId: Scalars['String']['input'];
  zinslisteId: Scalars['ID']['input'];
};

export type MutationActionZuruecksetzenUstKategorieEntryArgs = {
  ustKategorieEntryId: Scalars['ID']['input'];
  ustKategorieId: Scalars['ID']['input'];
};

export type MutationApprovePaymentBulkActionArgs = {
  input: PaymentBulkActionInput;
};

export type MutationAssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListArgs = {
  input: AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListInput;
};

export type MutationAssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListArgs = {
  input: AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListInput;
};

export type MutationAssignRechtstraegerToParticipantArgs = {
  input: RechtstraegerAssignmentInput;
  participantId: Scalars['ID']['input'];
  ticketId: Scalars['ID']['input'];
};

export type MutationCalculateHeVertragZahlungsregelSumVposNettoArgs = {
  input: HeVertragZahlungsregelSumVposNettoInput;
  objektId: Scalars['ID']['input'];
};

export type MutationChangePasswordViaTokenArgs = {
  request: ChangePasswordViaTokenInputData;
  token: Scalars['String']['input'];
};

export type MutationChangePaymentDatePaymentBulkActionArgs = {
  input: ChangePaymentDateBulkActionInput;
};

export type MutationCreateAbrechnungskreisTemplateArgs = {
  input: AbrechnungskreisCreateInput;
};

export type MutationCreateApplyVertragVposIndexedValueGenerierlaufArgs = {
  input: ApplyVertragVposIndexedValueGenerierlaufCreateInput;
};

export type MutationCreateAufteilungsschluesselTemplateArgs = {
  input: AufteilungsschluesselCreateInput;
};

export type MutationCreateAuftragArgs = {
  input: AuftragCreateInput;
};

export type MutationCreateAuftragGenerierlaufArgs = {
  request: AuftragGenerierlaufInput;
};

export type MutationCreateAuftragVerbuchenGenerierlaufArgs = {
  input: AuftragVerbuchenGenerierlaufInput;
};

export type MutationCreateAuftragsartArgs = {
  input: AuftragsartCreateInput;
};

export type MutationCreateAuftragsartTemplateArgs = {
  input: AuftragsartCreateInput;
};

export type MutationCreateAuszifferungListArgs = {
  input: AuszifferungListCreateInput;
  zahlungId: Scalars['String']['input'];
};

export type MutationCreateBeAufteilungsschluesselWertMitBezugArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  input: BeAufteilingsschluesselWertMitBezugInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateBeAufteilungsschluesselWertOhneBezugArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  input: BeAufteilingsschluesselWertOhneBezugInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateBeInfoFeldPostItArgs = {
  beInfoFeldId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  input: PostItInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateBeNutzungsArtArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: BeNutzungsArtCreateInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateBeOptionSteuerpflichtVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: BeOptionSteuerpflichtVersionInput;
  objektId: Scalars['String']['input'];
};

export type MutationCreateBePostItArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: PostItInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateBeVertragArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragInput: BeVertragCreateInput;
};

export type MutationCreateBeVertragDokumentenversandEntryArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: BeVertragDokumentenversandInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationCreateBeVertragInfoFeldPostItArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: PostItInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vertragInfoFeldId: Scalars['ID']['input'];
};

export type MutationCreateBeVertragPostItArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: PostItInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationCreateBeVertragVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  request: BeVertragVersionCreateInput;
  vertragId: Scalars['ID']['input'];
};

export type MutationCreateBeVertragVorschreibungspositionWertArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
  vorschreibungspositionWert: VorschreibungspositionWertInput;
};

export type MutationCreateBelegnummernkreisDefinitionArgs = {
  input: BelegnummernkreisDefinitionInput;
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationCreateBestandseinheitArgs = {
  bestandseinheit: BestandseinheitCreateInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateBestandseinheitInfoFeldTicketArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  bestandseinheitInfoFeldId: Scalars['ID']['input'];
  input: TicketCreateInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateBestandseinheitNutzflaecheVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  nutzflaecheVersion: BestandseinheitNutzflaecheVersionInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateBestandseinheitNutzwertVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  nutzwertVersion: BestandseinheitNutzwertVersionInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateBestandseinheitTicketArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: TicketCreateInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateBkAbrechnungsdefinitionForFdArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: BkAbrechnungsdefinitionCreateInput;
};

export type MutationCreateBkAbrechnungsdefinitionForKsArgs = {
  input: BkAbrechnungsdefinitionCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateBkAbrechnungsdefinitionTemplateArgs = {
  input: BkAbrechnungsdefinitionCreateInput;
};

export type MutationCreateBkAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: BkAbrechnungsdefinitionVersionCreateInput;
};

export type MutationCreateBkAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: BkAbrechnungsdefinitionVersionCreateInput;
};

export type MutationCreateBookingSuggestionVerbuchenGenerierlaufArgs = {
  input: BookingSuggestionVerbuchenGenerierlaufCreateInput;
};

export type MutationCreateBudgetingArgs = {
  input: BudgetingCreateInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateBudgetingAbrDefVPosLinkageFirmendatenArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: BudgetingAbrDefVPosLinkageCreateInput;
};

export type MutationCreateBudgetingAbrDefVPosLinkageKundenSystemArgs = {
  input: BudgetingAbrDefVPosLinkageCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateBudgetingAbrDefVPosLinkageTemplateArgs = {
  input: BudgetingAbrDefVPosLinkageCreateInput;
};

export type MutationCreateBudgetingVPosAuftschluesselLinkageFirmendatenArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: BudgetingVPosAuftschluesselLinkageCreateInput;
};

export type MutationCreateBudgetingVPosAuftschluesselLinkageKundenSystemArgs = {
  input: BudgetingVPosAuftschluesselLinkageCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateBudgetingVPosAuftschluesselLinkageTemplateArgs = {
  input: BudgetingVPosAuftschluesselLinkageCreateInput;
};

export type MutationCreateCamtGenerierlaufArgs = {
  request: CamtGenerierlaufCreateInput;
};

export type MutationCreateEbicsBankConfigArgs = {
  input: EbicsBankConfigCreateInput;
};

export type MutationCreateEbicsUserArgs = {
  input: EbicsUserCreateInput;
};

export type MutationCreateEigenbelegErstellenGenerierlaufArgs = {
  input: EigenbelegErstellenGenerierlaufInput;
};

export type MutationCreateEinmalzahlungArgs = {
  heVertragId: Scalars['ID']['input'];
  input: HeVertragAuszahlungCreateInput;
  objektId: Scalars['String']['input'];
};

export type MutationCreateEmailDeliveryDefinitionArgs = {
  input: EmailDeliveryDefinitionCreateInput;
};

export type MutationCreateEmailDeliveryDefinitionTemplateArgs = {
  input: EmailDeliveryDefinitionCreateInput;
};

export type MutationCreateFdAbrechnungskreisArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: AbrechnungskreisCreateInput;
};

export type MutationCreateFdAufteilungsschluesselArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: AufteilungsschluesselCreateInput;
};

export type MutationCreateFdKontierungstabelleArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: KontierungstabelleCreateInput;
};

export type MutationCreateFdUstKategorieArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: UstKategorieCreateInput;
};

export type MutationCreateFdVorschreibungspositionArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: VorschreibungspositionCreateInput;
};

export type MutationCreateFirmaArgs = {
  request: FirmaCreateInput;
};

export type MutationCreateFirmaAddressArgs = {
  firmaId: Scalars['ID']['input'];
  input: AddressCreateInput;
};

export type MutationCreateFirmaContactArgs = {
  firmaId: Scalars['ID']['input'];
  input: ContactListCreateInput;
};

export type MutationCreateFirmaContactPersonArgs = {
  firmaId: Scalars['ID']['input'];
  input: ContactPersonCreateInput;
};

export type MutationCreateFirmaOperatingSiteArgs = {
  firmaId: Scalars['String']['input'];
  request: OperatingSiteInput;
};

export type MutationCreateFirmendatenAddressArgs = {
  input: AddressCreateInput;
};

export type MutationCreateFirmendatenBankDetailsArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: FirmendatenBankDetailsCreateInput;
};

export type MutationCreateFirmendatenContactArgs = {
  input: ContactListCreateInput;
};

export type MutationCreateFirmendatenContactPersonArgs = {
  input: ContactPersonCreateInput;
};

export type MutationCreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufArgs = {
  input: HeOrObjektOrSubAbrechnungGenerierlaufCreateInput;
};

export type MutationCreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufArgs = {
  input: HeOrObjektOrSubNamedAbrechnungGenerierlaufCreateInput;
};

export type MutationCreateFirmendatenIndexSeriesArgs = {
  input: IndexSeriesCreateInput;
};

export type MutationCreateFirmendatenIndexSeriesEntryArgs = {
  indexSeriesId: Scalars['ID']['input'];
  input: IndexSeriesEntryCreateInput;
};

export type MutationCreateFirmendatenOperatingSiteArgs = {
  firmendatenId: Scalars['ID']['input'];
  request: OperatingSiteInput;
};

export type MutationCreateFirmendatenTopAbrechnungGenerierlaufArgs = {
  input: TopAbrechnungGenerierlaufCreateInput;
};

export type MutationCreateHeAbrechnungsdefinitionForFdArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: HeAbrechnungsdefinitionCreateInput;
};

export type MutationCreateHeAbrechnungsdefinitionForKsArgs = {
  input: HeAbrechnungsdefinitionCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateHeAbrechnungsdefinitionTemplateArgs = {
  input: HeAbrechnungsdefinitionCreateInput;
};

export type MutationCreateHeAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: HeAbrechnungsdefinitionVersionCreateInput;
};

export type MutationCreateHeAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: HeAbrechnungsdefinitionVersionCreateInput;
};

export type MutationCreateHeVertragArgs = {
  input: HeVertragCreateInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateHeVertragPostItArgs = {
  heVertragId: Scalars['ID']['input'];
  input: PostItInput;
  objektId: Scalars['String']['input'];
};

export type MutationCreateHeVertragZahlungsregelArgs = {
  heVertragId: Scalars['ID']['input'];
  input: HeVertragZahlungsregelCreateInput;
  objektId: Scalars['String']['input'];
};

export type MutationCreateIncomingCallLogArgs = {
  input: CallLogCreateInput;
};

export type MutationCreateIncomingInvoiceBookingCreationGenerierlaufArgs = {
  input: IncomingInvoiceBookingCreationGenerierlaufCreateInput;
};

export type MutationCreateIndexSeriesArgs = {
  input: IndexSeriesCreateInput;
};

export type MutationCreateIndexSeriesEntryArgs = {
  indexSeriesId: Scalars['ID']['input'];
  input: IndexSeriesEntryCreateInput;
};

export type MutationCreateInfoMailDeliveryWizardArgs = {
  emailDeliveryDefinitionId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationCreateKsAbrechnungskreisArgs = {
  input: AbrechnungskreisCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateKsAufteilungsschluesselArgs = {
  input: AufteilungsschluesselCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateKsFirmendatenArgs = {
  kundenSystemId: Scalars['ID']['input'];
  request: CreateKsFirmendatenInput;
};

export type MutationCreateKsKontierungstabelleArgs = {
  input: KontierungstabelleCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateKsUstKategorieArgs = {
  input: UstKategorieCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateKsVorschreibungspositionArgs = {
  input: VorschreibungspositionCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateKontierungstabelleTemplateArgs = {
  input: KontierungstabelleCreateInput;
};

export type MutationCreateKontoArgs = {
  input: KontoCreateInput;
};

export type MutationCreateKontoTemplateArgs = {
  input: KontoCreateInput;
};

export type MutationCreateKundenSystemArgs = {
  request: CreateKundenSystemInput;
};

export type MutationCreateLeerstehungAufwandskontoArgs = {
  input: FirmendatenLeerstehungAufwandskontoInput;
};

export type MutationCreateMahndefinitionArgs = {
  input: MahndefinitionCreateInput;
};

export type MutationCreateMahndefinitionTemplateArgs = {
  input: MahndefinitionCreateInput;
};

export type MutationCreateMahnungAusgebenGenerierlaufArgs = {
  input: MahnungGenerierlaufInput;
};

export type MutationCreateMahnungErzeugenGenerierlaufArgs = {
  input: MahnungErzeugenGenerierlaufInput;
};

export type MutationCreateMahnungVerbuchenGenerierlaufArgs = {
  input: MahnungGenerierlaufInput;
};

export type MutationCreateMahnungVersendenGenerierlaufArgs = {
  input: MahnungVersendenGenerierlaufInput;
};

export type MutationCreateMitarbeiterArgs = {
  firmendatenId: Scalars['String']['input'];
  input: MitarbeiterCreateInput;
};

export type MutationCreateNatuerlichePersonArgs = {
  request: NatuerlichePersonCreateInput;
};

export type MutationCreateNatuerlichePersonAddressArgs = {
  input: AddressCreateInput;
  natuerlichePersonId: Scalars['ID']['input'];
};

export type MutationCreateNatuerlichePersonContactArgs = {
  input: ContactListCreateInput;
  natuerlichePersonId: Scalars['ID']['input'];
};

export type MutationCreateObjektArgs = {
  input: ObjektCreateInput;
};

export type MutationCreateObjektAddressArgs = {
  input: AddressStreetCreateInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateObjektBankDetailsArgs = {
  input: ObjektBankDetailsCreateInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateObjektFinancialPlanGenerierlaufArgs = {
  input: ObjektFinancialPlanGenerierlaufInput;
};

export type MutationCreateObjektInfoFeldPostItArgs = {
  input: PostItInput;
  objekInfoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationCreateObjektInfoFeldTicketArgs = {
  input: TicketCreateInput;
  objektId: Scalars['ID']['input'];
  objektInfoFeldId: Scalars['ID']['input'];
};

export type MutationCreateObjektPostItArgs = {
  input: PostItInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateObjektSummeAnteileArgs = {
  input: ObjektSummeAnteileInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateObjektTicketArgs = {
  input: TicketCreateInput;
  objektId: Scalars['ID']['input'];
};

export type MutationCreateOrUpdateColumnSettingsArgs = {
  input: ColumnSettingsCreateOrUpdateInput;
};

export type MutationCreateOutgoingCallLogArgs = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationCreatePauschalmieteAufwandskontoArgs = {
  input: FirmendatenPauschalmieteAufwandskontoInput;
};

export type MutationCreatePaymentArgs = {
  input: PaymentCreateInput;
};

export type MutationCreatePaymentProposalCreationGenerierlaufArgs = {
  input: PaymentProposalCreationGenerierlaufCreateInput;
};

export type MutationCreatePaymentProposalExecutionGenerierlaufArgs = {
  input: PaymentProposalExecutionGenerierlaufCreateInput;
};

export type MutationCreatePersonenGemeinschaftArgs = {
  request: PersonenGemeinschaftCreateInput;
};

export type MutationCreatePersonenGemeinschaftAddressArgs = {
  input: AddressCreateInput;
  personenGemeinschaftId: Scalars['ID']['input'];
};

export type MutationCreatePersonenGemeinschaftContactArgs = {
  input: ContactListCreateInput;
  personenGemeinschaftId: Scalars['ID']['input'];
};

export type MutationCreatePersonenbezugArgs = {
  input: PersonenbezugInput;
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationCreateReceivedBankTransactionVerbuchenGenerierlaufArgs = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  bookingDateFrom?: InputMaybe<Scalars['String']['input']>;
  bookingDateTo?: InputMaybe<Scalars['String']['input']>;
  chargesFrom?: InputMaybe<Scalars['Float']['input']>;
  chargesInSeparateEntry?: InputMaybe<Scalars['Boolean']['input']>;
  chargesTo?: InputMaybe<Scalars['Float']['input']>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  createdByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  creditor?: InputMaybe<Scalars['String']['input']>;
  creditorIban?: InputMaybe<Scalars['String']['input']>;
  dataCarrierName?: InputMaybe<Scalars['String']['input']>;
  debtor?: InputMaybe<Scalars['String']['input']>;
  debtorIban?: InputMaybe<Scalars['String']['input']>;
  endToEndId?: InputMaybe<Scalars['String']['input']>;
  input: ReceivedBankTransactionVerbuchenGenerierlaufCreateInput;
  noted?: InputMaybe<Scalars['Boolean']['input']>;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  reversal?: InputMaybe<Scalars['Boolean']['input']>;
  statementNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReceivedBankTransactionStatus>;
  transactionType?: InputMaybe<TransactionType>;
  valueDateFrom?: InputMaybe<Scalars['String']['input']>;
  valueDateTo?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateRechtstraegerBankDetailsArgs = {
  input: RechtstraegerBankDetailsCreateInput;
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationCreateRechtstraegerInfoFeldPostItArgs = {
  input: PostItInput;
  rechtstraegerId: Scalars['ID']['input'];
  rechtstraegerInfoFeldId: Scalars['ID']['input'];
};

export type MutationCreateRechtstraegerPostItArgs = {
  input: PostItInput;
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationCreateRechtstraegerSteuerpflichtArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  request: SteuerpflichtInput;
};

export type MutationCreateRechtstragerInfoFeldTicketArgs = {
  input: TicketCreateInput;
  rechtstraegerId: Scalars['ID']['input'];
  rechtstraegerInfoFeldId: Scalars['ID']['input'];
};

export type MutationCreateRechtstragerTicketArgs = {
  input: TicketCreateInput;
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationCreateRepFondsEinstellungArgs = {
  input: RepFondsEinstellungCreateInput;
};

export type MutationCreateRepFondsEinstellungTemplateArgs = {
  input: RepFondsEinstellungCreateInput;
};

export type MutationCreateRepFondsErloesKontenZuweisungArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  request: RepFondsErloesKontenZuweisungInput;
};

export type MutationCreateSepaMandatArgs = {
  bankDetailsId: Scalars['ID']['input'];
  input: SepaMandatCreateInput;
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationCreateSingleInvoiceArgs = {
  input: SingleInvoiceCreateInput;
};

export type MutationCreateStandingInvoiceArgs = {
  input: StandingInvoiceCreateInput;
};

export type MutationCreateSubAdministrationAbrechnungsdefinitionForFdArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: SubAdministrationAbrechnungsdefinitionCreateInput;
};

export type MutationCreateSubAdministrationAbrechnungsdefinitionForKsArgs = {
  input: SubAdministrationAbrechnungsdefinitionCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateSubAdministrationAbrechnungsdefinitionTemplateArgs = {
  input: SubAdministrationAbrechnungsdefinitionCreateInput;
};

export type MutationCreateSubAdministrationAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: SubAdministrationAbrechnungsdefinitionVersionCreateInput;
};

export type MutationCreateSubAdministrationAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: SubAdministrationAbrechnungsdefinitionVersionCreateInput;
};

export type MutationCreateSubAdministrationPostItArgs = {
  input: PostItInput;
  objektId: Scalars['ID']['input'];
  subAdministrationId: Scalars['ID']['input'];
};

export type MutationCreateTextbausteinArgs = {
  input: GeneralTextbausteinCreateInput;
};

export type MutationCreateTextbausteinTemplateArgs = {
  input: GeneralTextbausteinCreateInput;
};

export type MutationCreateTicketCommentArgs = {
  input: TicketCommentCreateInput;
  ticketId: Scalars['ID']['input'];
};

export type MutationCreateTicketTemplateArgs = {
  input: TicketTemplateCreateInput;
};

export type MutationCreateTicketTemplateTemplateArgs = {
  input: TicketTemplateCreateInput;
};

export type MutationCreateTopFinancialPlanGenerierlaufArgs = {
  input: TopFinancialPlanGenerierlaufInput;
};

export type MutationCreateUserArgs = {
  request: UserCreateInput;
};

export type MutationCreateUstKategorieTemplateArgs = {
  input: UstKategorieCreateInput;
};

export type MutationCreateUstKategorieTemplateVersionArgs = {
  input: UstKategorieVersionCreateInput;
  ustKategorieId: Scalars['ID']['input'];
};

export type MutationCreateUstKategorieVersionArgs = {
  input: UstKategorieVersionCreateInput;
  ustKategorieId: Scalars['ID']['input'];
};

export type MutationCreateUstRegelsetFirmendatenArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: UstRegelsetCreateInput;
};

export type MutationCreateUstRegelsetKundenSystemArgs = {
  input: UstRegelsetCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateUstRegelsetTemplateArgs = {
  input: UstRegelsetCreateInput;
};

export type MutationCreateUstRegelsetTemplateUstKategorieZuweisungArgs = {
  input: UstRegelsetUstKategorieZuweisungCreateInput;
  ustRegelsetId: Scalars['ID']['input'];
};

export type MutationCreateUstRegelsetUstKategorieZuweisungArgs = {
  input: UstRegelsetUstKategorieZuweisungCreateInput;
  ustRegelsetId: Scalars['ID']['input'];
};

export type MutationCreateUstVomAufwandGenerierlaufArgs = {
  request: UstVomAufwandGenerierlaufInput;
};

export type MutationCreateVertragInfoFeldTicketArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: TicketCreateInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vertragInfoFeldId: Scalars['ID']['input'];
};

export type MutationCreateVertragTicketArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: TicketCreateInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationCreateVertragVposIndexationAgreementCategoryArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: VertragVposIndexationAgreementCategoryCreateInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationCreateVertragVposIndexationAgreementIndexAnnualArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: VertragVposIndexationAgreementIndexAnnualCreateInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationCreateVertragVposIndexationAgreementIndexThresholdArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: VertragVposIndexationAgreementIndexThresholdCreateInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationCreateVertragVposIndexationAgreementReferenceValueArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  input: VertragVposIndexationAgreementReferenceValueCreateInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationCreateVertragVposIndexedValueGenerierlaufArgs = {
  input: VertragVposIndexedValueGenerierlaufCreateInput;
};

export type MutationCreateVorschreibungMailDeliveryWizardArgs = {
  emailDeliveryDefinitionId: Scalars['ID']['input'];
};

export type MutationCreateVorschreibungspositionTemplateArgs = {
  input: VorschreibungspositionCreateInput;
};

export type MutationCreateVorschreibungspositionTemplateVersionArgs = {
  input: VorschreibungspositionVersionCreateInput;
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationCreateVorschreibungspositionVersionArgs = {
  input: VorschreibungspositionVersionCreateInput;
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationCreateVorsteuerkuerzungEinstellungArgs = {
  input: VorsteuerkuerzungEinstellungCreateInput;
};

export type MutationCreateVorsteuerkuerzungEinstellungTemplateArgs = {
  input: VorsteuerkuerzungEinstellungCreateInput;
};

export type MutationCreateVorsteuerkuerzungGenerierlaufArgs = {
  request: VorsteuerkuerzungGenerierlaufInput;
};

export type MutationCreateWeAbrechnungsdefinitionForFdArgs = {
  firmendatenId: Scalars['ID']['input'];
  input: WeAbrechnungsdefinitionCreateInput;
};

export type MutationCreateWeAbrechnungsdefinitionForKsArgs = {
  input: WeAbrechnungsdefinitionCreateInput;
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationCreateWeAbrechnungsdefinitionTemplateArgs = {
  input: WeAbrechnungsdefinitionCreateInput;
};

export type MutationCreateWeAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: WeAbrechnungsdefinitionVersionCreateInput;
};

export type MutationCreateWeAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: WeAbrechnungsdefinitionVersionCreateInput;
};

export type MutationCreateZinslisteArgs = {
  objektId: Scalars['String']['input'];
  vorschreibungsPeriode: VorschreibungsperiodeInput;
};

export type MutationCreateZinslisteGenerierlaufArgs = {
  input: ZinslisteGenerierlaufCreateInput;
};

export type MutationDeleteAbrechnungsdefinitionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type MutationDeleteAbrechnungsdefinitionTemplateArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type MutationDeleteAbrechnungskreisArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
};

export type MutationDeleteAbrechnungskreisTemplateArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
};

export type MutationDeleteAddressArgs = {
  addressId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
};

export type MutationDeleteAppFieldHelpArgs = {
  fieldHelpId: Scalars['ID']['input'];
};

export type MutationDeleteAufteilungsschluesselArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
};

export type MutationDeleteAufteilungsschluesselTemplateArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
};

export type MutationDeleteAuftragsartArgs = {
  auftragsartId: Scalars['ID']['input'];
};

export type MutationDeleteAuftragsartTemplateArgs = {
  auftragsartId: Scalars['ID']['input'];
};

export type MutationDeleteAuftragspositionArgs = {
  auftragId: Scalars['String']['input'];
  auftragspositionId: Scalars['ID']['input'];
};

export type MutationDeleteAuszahlungArgs = {
  heVertragAuszahlungId: Scalars['ID']['input'];
  heVertragId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
};

export type MutationDeleteBankStatementArgs = {
  bankStatementId: Scalars['ID']['input'];
  dataCarrierId: Scalars['ID']['input'];
};

export type MutationDeleteBeAufteilungsschluesselWertArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
  beAufteilungsschluesselWertId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteBeInfoFeldArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteBeNutzungsArtArgs = {
  beNutzungsArtId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteBeOptionSteuerpflichtVersionArgs = {
  beOptionSteuerpflichtVersionId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
};

export type MutationDeleteBeVertragArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationDeleteBeVertragDokumentenversandEntryArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  dokumentenversandEntryId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationDeleteBeVertragInfoFeldArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationDeleteBeVertragVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vertragVersionId: Scalars['ID']['input'];
};

export type MutationDeleteBeVertragVorschreibungspositionWertArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
  vorschreibungspositionWertId: Scalars['ID']['input'];
};

export type MutationDeleteBelegTexteFirmendatenArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type MutationDeleteBelegTexteRechtstraegerArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationDeleteBelegnummernkreisDefinitionArgs = {
  belegnummernkreisDefinitionId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationDeleteBestandseinheitArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteBestandseinheitNutzflaecheVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  nutzflaecheVersionId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteBestandseinheitNutzwertVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  nutzwertVersionId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteBkAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
};

export type MutationDeleteBkAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
};

export type MutationDeleteBudgetingArgs = {
  budgetingId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteBudgetingAbrDefVPosLinkageArgs = {
  budgetingAbrDefVPosLinkageId: Scalars['ID']['input'];
};

export type MutationDeleteBudgetingAbrDefVPosLinkageTemplateArgs = {
  budgetingAbrDefVPosLinkageId: Scalars['ID']['input'];
};

export type MutationDeleteBudgetingVPosAuftschluesselLinkageArgs = {
  budgetingVPosAuftschluesselLinkageId: Scalars['ID']['input'];
};

export type MutationDeleteBudgetingVPosAuftschluesselLinkageTemplateArgs = {
  budgetingVPosAuftschluesselLinkageId: Scalars['ID']['input'];
};

export type MutationDeleteColumnSettingsArgs = {
  filterIdentifier: Scalars['String']['input'];
};

export type MutationDeleteContactArgs = {
  contactId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
};

export type MutationDeleteContactPersonArgs = {
  contactPersonId: Scalars['ID']['input'];
};

export type MutationDeleteDataCarrierCamtArgs = {
  dataCarrierId: Scalars['ID']['input'];
};

export type MutationDeleteEbicsBankConfigArgs = {
  ebicsBankConfigId: Scalars['ID']['input'];
};

export type MutationDeleteEbicsUserArgs = {
  ebicsUserId: Scalars['ID']['input'];
};

export type MutationDeleteEmailDeliveryDefinitionArgs = {
  emailDeliveryDefinitionId: Scalars['ID']['input'];
};

export type MutationDeleteEmailDeliveryDefinitionTemplateArgs = {
  emailDeliveryDefinitionId: Scalars['ID']['input'];
};

export type MutationDeleteFibuBuchungsanweisungArgs = {
  buchungsanweisungId: Scalars['ID']['input'];
};

export type MutationDeleteFibuFieldHelpArgs = {
  fieldHelpId: Scalars['ID']['input'];
};

export type MutationDeleteFileForFirmendatenArgs = {
  fileId: Scalars['ID']['input'];
};

export type MutationDeleteFileForMitarbeiterArgs = {
  fileId: Scalars['ID']['input'];
  mitarbeiterId: Scalars['ID']['input'];
};

export type MutationDeleteFirmaArgs = {
  firmaId: Scalars['ID']['input'];
};

export type MutationDeleteFirmaOperatingSiteArgs = {
  firmaId: Scalars['String']['input'];
  operatingSiteId: Scalars['ID']['input'];
};

export type MutationDeleteFirmendatenArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type MutationDeleteFirmendatenBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
};

export type MutationDeleteFirmendatenIndexSeriesArgs = {
  indexSeriesId: Scalars['ID']['input'];
};

export type MutationDeleteFirmendatenIndexSeriesEntryArgs = {
  indexSeriesEntryId: Scalars['ID']['input'];
  indexSeriesId: Scalars['String']['input'];
};

export type MutationDeleteFirmendatenOperatingSiteArgs = {
  firmendatenId: Scalars['ID']['input'];
  operatingSiteId: Scalars['ID']['input'];
};

export type MutationDeleteHeAbrechnungArgs = {
  heAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteHeAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
};

export type MutationDeleteHeAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
};

export type MutationDeleteHeVertragArgs = {
  heVertragId: Scalars['ID']['input'];
  objektId: Scalars['String']['input'];
};

export type MutationDeleteHeVertragZahlungsregelArgs = {
  heVertragId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  zahlungsregelId: Scalars['ID']['input'];
};

export type MutationDeleteIndexSeriesArgs = {
  indexSeriesId: Scalars['ID']['input'];
};

export type MutationDeleteIndexSeriesEntryArgs = {
  indexSeriesEntryId: Scalars['ID']['input'];
  indexSeriesId: Scalars['String']['input'];
};

export type MutationDeleteInfoMailDeliveryAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
  infoMailDeliveryId: Scalars['String']['input'];
  infoMailDeliveryWizardId: Scalars['String']['input'];
};

export type MutationDeleteInfoMailDeliveryWizardArgs = {
  infoMailDeliveryWizardId: Scalars['ID']['input'];
};

export type MutationDeleteKontierungstabelleArgs = {
  kontierungstabelleId: Scalars['ID']['input'];
};

export type MutationDeleteKontierungstabelleTemplateArgs = {
  kontierungstabelleId: Scalars['ID']['input'];
};

export type MutationDeleteKontoArgs = {
  kontoId: Scalars['ID']['input'];
};

export type MutationDeleteKontoTemplateArgs = {
  kontoId: Scalars['ID']['input'];
};

export type MutationDeleteKundenSystemArgs = {
  kundenSystemId: Scalars['ID']['input'];
};

export type MutationDeleteLeerstehungAufwandskontoArgs = {
  firmendatenLeerstehungAufwandskontoId: Scalars['ID']['input'];
};

export type MutationDeleteMahndefinitionArgs = {
  mahndefinitionId: Scalars['ID']['input'];
};

export type MutationDeleteMahndefinitionTemplateArgs = {
  mahndefinitionId: Scalars['ID']['input'];
};

export type MutationDeleteMahnungArgs = {
  mahnungId: Scalars['ID']['input'];
};

export type MutationDeleteMitarbeiterArgs = {
  firmendatenId: Scalars['String']['input'];
  mitarbeiterId: Scalars['ID']['input'];
};

export type MutationDeleteNatuerlichePersonArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationDeleteObjektArgs = {
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteObjektAbrechnungArgs = {
  objektAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteObjektBankDetailsArgs = {
  objektBankDetailsId: Scalars['ID']['input'];
  objektId: Scalars['String']['input'];
};

export type MutationDeleteObjektFinancialPlanArgs = {
  budgetingId: Scalars['ID']['input'];
  input: ObjektFinancialPlanDeleteInput;
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteObjektFotoArgs = {
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteObjektInfoFeldArgs = {
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteObjektSummeAnteileArgs = {
  objektId: Scalars['String']['input'];
  summeAnteileId: Scalars['ID']['input'];
};

export type MutationDeleteOrderFieldHelpArgs = {
  fieldHelpId: Scalars['ID']['input'];
};

export type MutationDeletePauschalmieteAufwandskontoArgs = {
  firmendatenPauschalmieteAufwandskontoId: Scalars['ID']['input'];
};

export type MutationDeletePaymentArgs = {
  paymentId: Scalars['ID']['input'];
};

export type MutationDeletePaymentFieldHelpArgs = {
  fieldHelpId: Scalars['ID']['input'];
};

export type MutationDeletePaymentProposalArgs = {
  paymentProposalId: Scalars['ID']['input'];
};

export type MutationDeletePersonenGemeinschaftArgs = {
  personenGemeinschaftId: Scalars['ID']['input'];
};

export type MutationDeletePersonenbezugArgs = {
  personenbezugId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationDeletePostItArgs = {
  postItId: Scalars['ID']['input'];
};

export type MutationDeleteRechtstraegerBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationDeleteRechtstraegerInfoFeldArgs = {
  infoFeldId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationDeleteRechtstraegerSteuerpflichtArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  rechtstraegerSteuerpflichtId: Scalars['ID']['input'];
};

export type MutationDeleteRepFondsEinstellungArgs = {
  repFondsEinstellungId: Scalars['ID']['input'];
};

export type MutationDeleteRepFondsEinstellungTemplateArgs = {
  repFondsEinstellungId: Scalars['ID']['input'];
};

export type MutationDeleteRepFondsErloesKontenZuweisungArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  repFondsErloesKontenZuweisungId: Scalars['ID']['input'];
};

export type MutationDeleteSingleInvoiceArgs = {
  belegId: Scalars['String']['input'];
};

export type MutationDeleteStandingInvoiceArgs = {
  belegId: Scalars['String']['input'];
};

export type MutationDeleteSubAbrechnungArgs = {
  objektId: Scalars['ID']['input'];
  subAbrechnungId: Scalars['ID']['input'];
};

export type MutationDeleteSubAdministrationAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
};

export type MutationDeleteSubAdministrationAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
};

export type MutationDeleteTextbausteinArgs = {
  textbausteinId: Scalars['ID']['input'];
};

export type MutationDeleteTextbausteinTemplateArgs = {
  textbausteinId: Scalars['ID']['input'];
};

export type MutationDeleteTicketAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
  ticketId: Scalars['ID']['input'];
};

export type MutationDeleteTicketCommentArgs = {
  ticketCommentId: Scalars['ID']['input'];
  ticketId: Scalars['ID']['input'];
};

export type MutationDeleteTicketTemplateArgs = {
  ticketTemplateId: Scalars['ID']['input'];
};

export type MutationDeleteTicketTemplateTemplateArgs = {
  ticketTemplateId: Scalars['ID']['input'];
};

export type MutationDeleteTopAbrechnungenArgs = {
  objektAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteTopFinancialPlanArgs = {
  budgetingId: Scalars['ID']['input'];
  input: TopFinancialPlanDeleteInput;
  objektId: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
  username: Scalars['String']['input'];
};

export type MutationDeleteUstKategorieArgs = {
  ustKategorieId: Scalars['ID']['input'];
};

export type MutationDeleteUstKategorieTemplateArgs = {
  ustKategorieId: Scalars['ID']['input'];
};

export type MutationDeleteUstKategorieTemplateVersionArgs = {
  ustKategorieId: Scalars['ID']['input'];
  ustKategorieVersionId: Scalars['ID']['input'];
};

export type MutationDeleteUstKategorieVersionArgs = {
  ustKategorieId: Scalars['ID']['input'];
  ustKategorieVersionId: Scalars['ID']['input'];
};

export type MutationDeleteUstRegelsetArgs = {
  ustRegelsetId: Scalars['ID']['input'];
};

export type MutationDeleteUstRegelsetTemplateArgs = {
  ustRegelsetId: Scalars['ID']['input'];
};

export type MutationDeleteUstRegelsetTemplateUstKategorieZuweisungArgs = {
  ustRegelsetId: Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Scalars['ID']['input'];
};

export type MutationDeleteUstRegelsetUstKategorieZuweisungArgs = {
  ustRegelsetId: Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Scalars['ID']['input'];
};

export type MutationDeleteUstVomAufwandArgs = {
  objektId: Scalars['ID']['input'];
  ustVomAufwandId: Scalars['ID']['input'];
};

export type MutationDeleteVertragVposIndexationAgreementArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  indexationAgreementId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationDeleteVertragVposIndexedValueArgs = {
  bestandseinheitId: Scalars['String']['input'];
  indexationAgreementId: Scalars['String']['input'];
  indexedValueId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  vertragId: Scalars['String']['input'];
  vorschreibungspositionId: Scalars['String']['input'];
};

export type MutationDeleteVorschreibungMailDeliveryAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
  vorschreibungMailDeliveryId: Scalars['String']['input'];
  vorschreibungMailDeliveryWizardId: Scalars['String']['input'];
};

export type MutationDeleteVorschreibungMailDeliveryWizardArgs = {
  vorschreibungMailDeliveryWizardId: Scalars['ID']['input'];
};

export type MutationDeleteVorschreibungspositionArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationDeleteVorschreibungspositionTemplateArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationDeleteVorschreibungspositionTemplateVersionArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
  vorschreibungspositionVersionId: Scalars['ID']['input'];
};

export type MutationDeleteVorschreibungspositionVersionArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
  vorschreibungspositionVersionId: Scalars['ID']['input'];
};

export type MutationDeleteVorsteuerkuerzungArgs = {
  objektId: Scalars['String']['input'];
  vorsteuerkuerzungId: Scalars['ID']['input'];
};

export type MutationDeleteVorsteuerkuerzungEinstellungArgs = {
  verwaltungsart: Verwaltungsart;
  vorsteuerkuerzungEinstellungId: Scalars['ID']['input'];
};

export type MutationDeleteVorsteuerkuerzungEinstellungTemplateArgs = {
  vorsteuerkuerzungEinstellungId: Scalars['ID']['input'];
};

export type MutationDeleteWeAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
};

export type MutationDeleteWeAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
};

export type MutationDeleteZinslisteArgs = {
  objektId: Scalars['String']['input'];
  zinslisteId: Scalars['String']['input'];
};

export type MutationDoNotificationActionArgs = {
  body: Scalars['JSON']['input'];
  link: Scalars['String']['input'];
  method: Scalars['String']['input'];
  notificationType: NotificationType;
};

export type MutationGetFirmaKurzbezeichnungVorschlagArgs = {
  request: FirmaKurzbezeichnungInput;
};

export type MutationGetKurzbezeichnungVorschlagArgs = {
  request: KurzbezeichnungInput;
};

export type MutationGetNatuerlichePersonKurzbezeichnungVorschlagArgs = {
  request: NatuerlichePersonKurzbezeichnungInput;
};

export type MutationGetPersonengemeinschaftKurzbezeichnungVorschlagArgs = {
  request: PersonenGemeinschaftKurzbezeichnungInput;
};

export type MutationMahnungenEntsperrenArgs = {
  request: MahnungenBulkActionFilterInput;
};

export type MutationMahnungenFreigebenArgs = {
  request: MahnungenBulkActionFilterInput;
};

export type MutationMahnungenLoeschenArgs = {
  request: MahnungenBulkActionFilterInput;
};

export type MutationMahnungenSperrenArgs = {
  request: MahnungenSperrenBulkActionFilterInput;
};

export type MutationMarkAsNotedPaymentBulkActionArgs = {
  input: PaymentBulkActionInput;
};

export type MutationMarkAsNotedReceivedBankTransactionBulkActionArgs = {
  input: PaymentAssignmentBulkActionInput;
};

export type MutationRemoveReceivedBankTransactionPaymentAssignmentArgs = {
  input: PaymentAssignmentBulkActionInput;
};

export type MutationSetObjektAufteilungsschluesselListArgs = {
  input: Array<Scalars['String']['input']>;
  objektId: Scalars['ID']['input'];
};

export type MutationSetObjektVorschreibungspositionListArgs = {
  input: Array<Scalars['String']['input']>;
  objektId: Scalars['ID']['input'];
};

export type MutationSetObjektVorschreibungspositionVertragsartListArgs = {
  objektId: Scalars['ID']['input'];
  objektVorschreibungspositionId: Scalars['ID']['input'];
  vertragsartList: Array<Vertragsart>;
};

export type MutationSingleInvoiceAbgrenzungsBuchungPreviewArgs = {
  input: SingleInvoiceCreateInput;
};

export type MutationSingleInvoiceAbgrenzungsStornoBuchungPreviewArgs = {
  belegId: Scalars['String']['input'];
  input: SingleInvoiceUpdateInput;
};

export type MutationSingleInvoiceBuchungPreviewArgs = {
  input: SingleInvoiceCreateInput;
};

export type MutationSingleInvoiceStornoBuchungPreviewArgs = {
  belegId: Scalars['String']['input'];
  input: SingleInvoiceUpdateInput;
};

export type MutationStandingInvoiceBuchungPreviewArgs = {
  input: StandingInvoiceCreateInput;
};

export type MutationStandingInvoiceStornoBuchungPreviewArgs = {
  belegId: Scalars['String']['input'];
  input: StandingInvoiceUpdateInput;
};

export type MutationStandingInvoiceUpdatePreviewArgs = {
  belegId: Scalars['String']['input'];
  input: StandingInvoiceUpdateInput;
};

export type MutationTriggerPasswordResetArgs = {
  request: PasswordResetInputData;
};

export type MutationUpdateAbrechnungskreisArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
  input: AbrechnungskreisUpdateInput;
};

export type MutationUpdateAbrechnungskreisTemplateArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
  input: AbrechnungskreisUpdateInput;
};

export type MutationUpdateAddressArgs = {
  addressId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
  input: AddressUpdateInput;
};

export type MutationUpdateAppFieldHelpArgs = {
  fieldHelpId: Scalars['ID']['input'];
  input: FieldHelpUpdateInput;
};

export type MutationUpdateAufteilungsschluesselArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
  input: AufteilungsschluesselUpdateInput;
};

export type MutationUpdateAufteilungsschluesselTemplateArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
  input: AufteilungsschluesselUpdateInput;
};

export type MutationUpdateAuftragsartArgs = {
  auftragsartId: Scalars['ID']['input'];
  input: AuftragsartUpdateInput;
};

export type MutationUpdateAuftragsartTemplateArgs = {
  auftragsartId: Scalars['ID']['input'];
  input: AuftragsartUpdateInput;
};

export type MutationUpdateAufwandAbgrenzungskontoArgs = {
  input: FirmendatenAufwandAbgrenzungskontoInput;
};

export type MutationUpdateBeAufteilungsschluesselWertMitBezugArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
  beAufteilungsschluesselWertId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  input: BeAufteilingsschluesselWertMitBezugInput;
  objektId: Scalars['ID']['input'];
};

export type MutationUpdateBeAufteilungsschluesselWertOhneBezugArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
  beAufteilungsschluesselWertId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  input: BeAufteilingsschluesselWertOhneBezugInput;
  objektId: Scalars['ID']['input'];
};

export type MutationUpdateBeNutzungsArtArgs = {
  beNutzungsArtId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  input: BeNutzungsArtUpdateInput;
  objektId: Scalars['ID']['input'];
};

export type MutationUpdateBeOptionSteuerpflichtVersionArgs = {
  beOptionSteuerpflichtVersionId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['String']['input'];
  input: BeOptionSteuerpflichtVersionInput;
  objektId: Scalars['String']['input'];
};

export type MutationUpdateBeVertragArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vertragInput: BeVertragUpdateInput;
};

export type MutationUpdateBeVertragDokumentenversandEntryArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  dokumentenversandEntryId: Scalars['ID']['input'];
  input: BeVertragDokumentenversandInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type MutationUpdateBeVertragVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  request: BeVertragVersionUpdateInput;
  vertragId: Scalars['ID']['input'];
  vertragVersionId: Scalars['ID']['input'];
};

export type MutationUpdateBeVertragVorschreibungspositionWertArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
  vorschreibungspositionWert: VorschreibungspositionWertInput;
  vorschreibungspositionWertId: Scalars['ID']['input'];
};

export type MutationUpdateBelegnummernkreisDefinitionArgs = {
  belegnummernkreisDefinitionId: Scalars['ID']['input'];
  input: BelegnummernkreisDefinitionInput;
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationUpdateBestandseinheitArgs = {
  bestandseinheit: BestandseinheitUpdateInput;
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationUpdateBestandseinheitNutzflaecheVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  nutzflaecheVersion: BestandseinheitNutzflaecheVersionInput;
  nutzflaecheVersionId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationUpdateBestandseinheitNutzwertVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  nutzwertVersion: BestandseinheitNutzwertVersionInput;
  nutzwertVersionId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationUpdateBkAbrechnungsdefinitionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: BkAbrechnungsdefinitionUpdateInput;
};

export type MutationUpdateBkAbrechnungsdefinitionTemplateArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: BkAbrechnungsdefinitionUpdateInput;
};

export type MutationUpdateBkAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  input: BkAbrechnungsdefinitionVersionUpdateInput;
};

export type MutationUpdateBkAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  input: BkAbrechnungsdefinitionVersionUpdateInput;
};

export type MutationUpdateBookingSuggestionArgs = {
  bookingSuggestionId: Scalars['String']['input'];
  input: BookingSuggestionUpdateInput;
};

export type MutationUpdateBudgetingAbrDefVPosLinkageArgs = {
  budgetingAbrDefVPosLinkageId: Scalars['ID']['input'];
  input: BudgetingAbrDefVPosLinkageUpdateInput;
};

export type MutationUpdateBudgetingAbrDefVPosLinkageTemplateArgs = {
  budgetingAbrDefVPosLinkageId: Scalars['ID']['input'];
  input: BudgetingAbrDefVPosLinkageUpdateInput;
};

export type MutationUpdateBudgetingVPosAuftschluesselLinkageArgs = {
  budgetingVPosAuftschluesselLinkageId: Scalars['ID']['input'];
  input: BudgetingVPosAuftschluesselLinkageUpdateInput;
};

export type MutationUpdateBudgetingVPosAuftschluesselLinkageTemplateArgs = {
  budgetingVPosAuftschluesselLinkageId: Scalars['ID']['input'];
  input: BudgetingVPosAuftschluesselLinkageUpdateInput;
};

export type MutationUpdateCallLogArgs = {
  callLogId: Scalars['ID']['input'];
  input: CallLogUpdateInput;
  rechtstraegerId: Scalars['ID']['input'];
};

export type MutationUpdateContactArgs = {
  contactId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
  input: ContactUpdateInput;
};

export type MutationUpdateEbicsBankConfigArgs = {
  ebicsBankConfigId: Scalars['ID']['input'];
  input: EbicsBankConfigUpdateInput;
};

export type MutationUpdateEbicsUserArgs = {
  ebicsUserId: Scalars['ID']['input'];
  input: EbicsUserUpdateInput;
};

export type MutationUpdateEmailDeliveryDefinitionArgs = {
  emailDeliveryDefinitionId: Scalars['ID']['input'];
  input: EmailDeliveryDefinitionUpdateInput;
};

export type MutationUpdateEmailDeliveryDefinitionTemplateArgs = {
  emailDeliveryDefinitionId: Scalars['ID']['input'];
  input: EmailDeliveryDefinitionUpdateInput;
};

export type MutationUpdateFibuEinstellungArgs = {
  request: FibuEinstellungUpdateInput;
};

export type MutationUpdateFibuFieldHelpArgs = {
  fieldHelpId: Scalars['ID']['input'];
  input: FieldHelpUpdateInput;
};

export type MutationUpdateFirmaArgs = {
  firmaId: Scalars['ID']['input'];
  request: FirmaUpdateInput;
};

export type MutationUpdateFirmaContactPersonArgs = {
  contactPersonId: Scalars['ID']['input'];
  firmaId: Scalars['String']['input'];
  input: ContactPersonUpdateInput;
};

export type MutationUpdateFirmaOperatingSiteArgs = {
  firmaId: Scalars['String']['input'];
  operatingSiteId: Scalars['ID']['input'];
  request: OperatingSiteInput;
};

export type MutationUpdateFirmendatenArgs = {
  firmendatenId: Scalars['ID']['input'];
  request: FirmendatenUpdateInput;
};

export type MutationUpdateFirmendatenBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
  input: FirmendatenBankDetailsUpdateInput;
};

export type MutationUpdateFirmendatenBankSettingsArgs = {
  input: FirmendatenBankSettingsUpdateInput;
};

export type MutationUpdateFirmendatenBuchhaltungEinstellungArgs = {
  input: FirmendatenBuchhaltungEinstellungUpdateInput;
};

export type MutationUpdateFirmendatenContactPersonArgs = {
  contactPersonId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
  input: ContactPersonUpdateInput;
};

export type MutationUpdateFirmendatenIndexSeriesArgs = {
  indexSeriesId: Scalars['ID']['input'];
  input: IndexSeriesUpdateInput;
};

export type MutationUpdateFirmendatenIndexSeriesEntryArgs = {
  indexSeriesEntryId: Scalars['ID']['input'];
  indexSeriesId: Scalars['String']['input'];
  input: IndexSeriesEntryUpdateInput;
};

export type MutationUpdateFirmendatenOperatingSiteArgs = {
  firmendatenId: Scalars['ID']['input'];
  operatingSiteId: Scalars['ID']['input'];
  request: OperatingSiteInput;
};

export type MutationUpdateFirmeneinstellungArgs = {
  firmendatenId: Scalars['ID']['input'];
  request: UpdateFirmendateneinstellungenInput;
};

export type MutationUpdateHeAbrechnungsdefinitionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: HeAbrechnungsdefinitionUpdateInput;
};

export type MutationUpdateHeAbrechnungsdefinitionTemplateArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: HeAbrechnungsdefinitionUpdateInput;
};

export type MutationUpdateHeAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  input: HeAbrechnungsdefinitionVersionUpdateInput;
};

export type MutationUpdateHeAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  input: HeAbrechnungsdefinitionVersionUpdateInput;
};

export type MutationUpdateHeVertragArgs = {
  heVertragId: Scalars['ID']['input'];
  input: HeVertragUpdateInput;
  objektId: Scalars['String']['input'];
};

export type MutationUpdateHeVertragZahlungsregelArgs = {
  heVertragId: Scalars['String']['input'];
  input: HeVertragZahlungsregelUpdateInput;
  objektId: Scalars['String']['input'];
  zahlungsregelId: Scalars['ID']['input'];
};

export type MutationUpdateIndexSeriesArgs = {
  indexSeriesId: Scalars['ID']['input'];
  input: IndexSeriesUpdateInput;
};

export type MutationUpdateIndexSeriesEntryArgs = {
  indexSeriesEntryId: Scalars['ID']['input'];
  indexSeriesId: Scalars['String']['input'];
  input: IndexSeriesEntryUpdateInput;
};

export type MutationUpdateIndexValueCreationCronJobSettingsArgs = {
  input: IndexValueCreationCronJobSettingsUpdateInput;
};

export type MutationUpdateInfoMailDeliveryArgs = {
  infoMailDeliveryId: Scalars['ID']['input'];
  infoMailDeliveryWizardId: Scalars['String']['input'];
  shouldSend: Scalars['Boolean']['input'];
};

export type MutationUpdateKontierungstabelleArgs = {
  input: KontierungstabelleUpdateInput;
  kontierungstabelleId: Scalars['ID']['input'];
};

export type MutationUpdateKontierungstabelleTemplateArgs = {
  input: KontierungstabelleUpdateInput;
  kontierungstabelleId: Scalars['ID']['input'];
};

export type MutationUpdateKontoArgs = {
  input: KontoUpdateInput;
  kontoId: Scalars['ID']['input'];
};

export type MutationUpdateKontoTemplateArgs = {
  input: KontoUpdateInput;
  kontoId: Scalars['ID']['input'];
};

export type MutationUpdateKundenSystemArgs = {
  kundenSystemId: Scalars['ID']['input'];
  request: UpdateKundenSystemInput;
};

export type MutationUpdateLeerstehungAufwandskontoArgs = {
  firmendatenLeerstehungAufwandskontoId: Scalars['ID']['input'];
  input: FirmendatenLeerstehungAufwandskontoInput;
};

export type MutationUpdateLeerstehungForderungskontoArgs = {
  input: FirmendatenLeerstehungForderungkontoInput;
};

export type MutationUpdateMahndefinitionArgs = {
  input: MahndefinitionUpdateInput;
  mahndefinitionId: Scalars['ID']['input'];
};

export type MutationUpdateMahndefinitionTemplateArgs = {
  input: MahndefinitionUpdateInput;
  mahndefinitionId: Scalars['ID']['input'];
};

export type MutationUpdateMahnungArgs = {
  mahnungId: Scalars['ID']['input'];
  request: MahnungUpdateInput;
};

export type MutationUpdateMitarbeiterArgs = {
  firmendatenId: Scalars['String']['input'];
  input: MitarbeiterUpdateInput;
  mitarbeiterId: Scalars['ID']['input'];
};

export type MutationUpdateNatuerlichePersonArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  request: NatuerlichePersonUpdateInput;
};

export type MutationUpdateNatuerlichePersonAddressArgs = {
  addressId: Scalars['ID']['input'];
  natuerlichePersonId: Scalars['ID']['input'];
  request: AddressUpdateInput;
};

export type MutationUpdateObjektArgs = {
  input: ObjektUpdateInput;
  objektId: Scalars['ID']['input'];
};

export type MutationUpdateObjektAufteilungsschluesselArgs = {
  input: ObjektAufteilungsschluesselUpdateInput;
  objektAufteilungsschluesselId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type MutationUpdateObjektBankDetailsArgs = {
  input: ObjektBankDetailsUpdateInput;
  objektBankDetailsId: Scalars['ID']['input'];
  objektId: Scalars['String']['input'];
};

export type MutationUpdateObjektSummeAnteileArgs = {
  input: ObjektSummeAnteileInput;
  objektId: Scalars['String']['input'];
  summeAnteileId: Scalars['ID']['input'];
};

export type MutationUpdateObjektVorschreibungspositionArgs = {
  input: ObjektVorschreibungspositionUpdateInput;
  objektId: Scalars['ID']['input'];
  objektVorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationUpdateOrderFieldHelpArgs = {
  fieldHelpId: Scalars['ID']['input'];
  input: FieldHelpUpdateInput;
};

export type MutationUpdatePauschalmieteAufwandskontoArgs = {
  firmendatenPauschalmieteAufwandskontoId: Scalars['ID']['input'];
  input: FirmendatenPauschalmieteAufwandskontoInput;
};

export type MutationUpdatePauschalmieteForderungskontoArgs = {
  firmendatenPauschalmieteForderungskontoId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdatePaymentArgs = {
  input: PaymentUpdateInput;
  paymentId: Scalars['ID']['input'];
};

export type MutationUpdatePaymentFieldHelpArgs = {
  fieldHelpId: Scalars['ID']['input'];
  input: FieldHelpUpdateInput;
};

export type MutationUpdatePersonenGemeinschaftArgs = {
  personenGemeinschaftId: Scalars['ID']['input'];
  request: PersonenGemeinschaftUpdateInput;
};

export type MutationUpdatePersonenGemeinschaftAddressArgs = {
  addressId: Scalars['ID']['input'];
  personenGemeinschaftId: Scalars['ID']['input'];
  request: AddressUpdateInput;
};

export type MutationUpdatePostItArgs = {
  input: PostItInput;
  postItId: Scalars['ID']['input'];
};

export type MutationUpdateRechtstraegerBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  input: RechtstraegerBankDetailsUpdateInput;
  rechtstraegerId: Scalars['String']['input'];
};

export type MutationUpdateRechtstraegerSteuerpflichtArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  rechtstraegerSteuerpflichtId: Scalars['ID']['input'];
  request: SteuerpflichtInput;
};

export type MutationUpdateRepFondsEinstellungArgs = {
  input: RepFondsEinstellungUpdateInput;
  repFondsEinstellungId: Scalars['ID']['input'];
};

export type MutationUpdateRepFondsEinstellungTemplateArgs = {
  input: RepFondsEinstellungUpdateInput;
  repFondsEinstellungId: Scalars['ID']['input'];
};

export type MutationUpdateRepFondsErloesKontenZuweisungArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  repFondsErloesKontenZuweisungId: Scalars['ID']['input'];
  request: RepFondsErloesKontenZuweisungInput;
};

export type MutationUpdateSingleInvoiceArgs = {
  belegId: Scalars['String']['input'];
  input: SingleInvoiceUpdateInput;
};

export type MutationUpdateStandingInvoiceArgs = {
  belegId: Scalars['String']['input'];
  input: StandingInvoiceUpdateInput;
};

export type MutationUpdateSubAdministrationArgs = {
  input: SubAdministrationUpdateInput;
  objektId: Scalars['ID']['input'];
  subAdministrationId: Scalars['ID']['input'];
};

export type MutationUpdateSubAdministrationAbrechnungsdefinitionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: SubAdministrationAbrechnungsdefinitionUpdateInput;
};

export type MutationUpdateSubAdministrationAbrechnungsdefinitionTemplateArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: SubAdministrationAbrechnungsdefinitionUpdateInput;
};

export type MutationUpdateSubAdministrationAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  input: SubAdministrationAbrechnungsdefinitionVersionUpdateInput;
};

export type MutationUpdateSubAdministrationAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  input: SubAdministrationAbrechnungsdefinitionVersionUpdateInput;
};

export type MutationUpdateTextbausteinArgs = {
  input: GeneralTextbausteinUpdateInput;
  textbausteinId: Scalars['ID']['input'];
};

export type MutationUpdateTextbausteinTemplateArgs = {
  input: GeneralTextbausteinUpdateInput;
  textbausteinId: Scalars['ID']['input'];
};

export type MutationUpdateTicketArgs = {
  input: TicketUpdateInput;
  ticketId: Scalars['ID']['input'];
};

export type MutationUpdateTicketCommentArgs = {
  input: TicketCommentUpdateInput;
  ticketCommentId: Scalars['ID']['input'];
  ticketId: Scalars['ID']['input'];
};

export type MutationUpdateTicketTemplateArgs = {
  input: TicketTemplateUpdateInput;
  ticketTemplateId: Scalars['ID']['input'];
};

export type MutationUpdateTicketTemplateTemplateArgs = {
  input: TicketTemplateUpdateInput;
  ticketTemplateId: Scalars['ID']['input'];
};

export type MutationUpdateUserArgs = {
  request: UserUpdateInput;
  username: Scalars['String']['input'];
};

export type MutationUpdateUstKategorieArgs = {
  input: UstKategorieUpdateInput;
  ustKategorieId: Scalars['ID']['input'];
};

export type MutationUpdateUstKategorieEntryArgs = {
  input: UstKategorieEntryInput;
  ustKategorieEntryId: Scalars['ID']['input'];
  ustKategorieId: Scalars['ID']['input'];
};

export type MutationUpdateUstKategorieTemplateArgs = {
  input: UstKategorieUpdateInput;
  ustKategorieId: Scalars['ID']['input'];
};

export type MutationUpdateUstKategorieTemplateVersionArgs = {
  input: UstKategorieVersionUpdateInput;
  ustKategorieId: Scalars['ID']['input'];
  ustKategorieVersionId: Scalars['ID']['input'];
};

export type MutationUpdateUstKategorieVersionArgs = {
  input: UstKategorieVersionUpdateInput;
  ustKategorieId: Scalars['ID']['input'];
  ustKategorieVersionId: Scalars['ID']['input'];
};

export type MutationUpdateUstRegelsetArgs = {
  input: UstRegelsetUpdateInput;
  ustRegelsetId: Scalars['ID']['input'];
};

export type MutationUpdateUstRegelsetTemplateArgs = {
  input: UstRegelsetUpdateInput;
  ustRegelsetId: Scalars['ID']['input'];
};

export type MutationUpdateUstRegelsetTemplateUstKategorieZuweisungArgs = {
  input: UstRegelsetUstKategorieZuweisungUpdateInput;
  ustRegelsetId: Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Scalars['ID']['input'];
};

export type MutationUpdateUstRegelsetUstKategorieZuweisungArgs = {
  input: UstRegelsetUstKategorieZuweisungUpdateInput;
  ustRegelsetId: Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Scalars['ID']['input'];
};

export type MutationUpdateVertragVposIndexationAgreementCategoryArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  indexationAgreementId: Scalars['ID']['input'];
  input: VertragVposIndexationAgreementCategoryUpdateInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationUpdateVertragVposIndexationAgreementIndexAnnualArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  indexationAgreementId: Scalars['ID']['input'];
  input: VertragVposIndexationAgreementIndexAnnualUpdateInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationUpdateVertragVposIndexationAgreementIndexThresholdArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  indexationAgreementId: Scalars['ID']['input'];
  input: VertragVposIndexationAgreementIndexThresholdUpdateInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationUpdateVertragVposIndexationAgreementReferenceValueArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  indexationAgreementId: Scalars['ID']['input'];
  input: VertragVposIndexationAgreementReferenceValueUpdateInput;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationUpdateVertragVposIndexedValueArgs = {
  bestandseinheitId: Scalars['String']['input'];
  indexationAgreementId: Scalars['String']['input'];
  indexedValueId: Scalars['String']['input'];
  input: VertragVposIndexedValueUpdateInput;
  objektId: Scalars['String']['input'];
  vertragId: Scalars['String']['input'];
  vorschreibungspositionId: Scalars['String']['input'];
};

export type MutationUpdateVorschreibungMailDeliveryArgs = {
  shouldNeverSend: Scalars['Boolean']['input'];
  shouldSend: Scalars['Boolean']['input'];
  vorschreibungMailDeliveryId: Scalars['ID']['input'];
  vorschreibungMailDeliveryWizardId: Scalars['String']['input'];
};

export type MutationUpdateVorschreibungspositionArgs = {
  input: VorschreibungspositionUpdateInput;
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationUpdateVorschreibungspositionTemplateArgs = {
  input: VorschreibungspositionUpdateInput;
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type MutationUpdateVorschreibungspositionTemplateVersionArgs = {
  input: VorschreibungspositionVersionUpdateInput;
  vorschreibungspositionId: Scalars['ID']['input'];
  vorschreibungspositionVersionId: Scalars['ID']['input'];
};

export type MutationUpdateVorschreibungspositionVersionArgs = {
  input: VorschreibungspositionVersionUpdateInput;
  vorschreibungspositionId: Scalars['ID']['input'];
  vorschreibungspositionVersionId: Scalars['ID']['input'];
};

export type MutationUpdateVorsteuerkuerzungEinstellungArgs = {
  input: VorsteuerkuerzungEinstellungUpdateInput;
  vorsteuerkuerzungEinstellungId: Scalars['ID']['input'];
};

export type MutationUpdateVorsteuerkuerzungEinstellungTemplateArgs = {
  input: VorsteuerkuerzungEinstellungUpdateInput;
  vorsteuerkuerzungEinstellungId: Scalars['ID']['input'];
};

export type MutationUpdateWeAbrechnungsdefinitionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: WeAbrechnungsdefinitionUpdateInput;
};

export type MutationUpdateWeAbrechnungsdefinitionTemplateArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  input: WeAbrechnungsdefinitionUpdateInput;
};

export type MutationUpdateWeAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  input: WeAbrechnungsdefinitionVersionUpdateInput;
};

export type MutationUpdateWeAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  input: WeAbrechnungsdefinitionVersionUpdateInput;
};

export type MutationValidateIbanArgs = {
  iban: Scalars['String']['input'];
};

export type MutationValidateVatIdentificationNumberArgs = {
  vatIdentificationNumber: Scalars['String']['input'];
};

export type NatuerlichePerson = {
  bankDetailsList: Array<Maybe<BankDetails>>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fibuKontoCreatable: Scalars['Boolean']['output'];
  firstname: Scalars['String']['output'];
  kundennummer: Scalars['String']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  personenbezugList: Array<Personenbezug>;
  postIt?: Maybe<PostIt>;
  rechtstraegerId: Scalars['ID']['output'];
  rechtstraegerSteuerpflicht?: Maybe<RechtstraegerSteuerpflicht>;
  salutation?: Maybe<SalutationTuple>;
  sprache: EnumData;
  status: RechtstraegerStatusTuple;
  tagList: Array<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleTrailing?: Maybe<Scalars['String']['output']>;
  type: RechtstraegerTypeTuple;
  vertragSummary?: Maybe<VertragSummary>;
  warningList: Array<ResponseDataWarning>;
};

export type NatuerlichePersonCreateInput = {
  firstname: Scalars['String']['input'];
  hauptAddress?: InputMaybe<AddressCreateInput>;
  kurzBezeichnung: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  salutation?: InputMaybe<Scalars['String']['input']>;
  sprache: Scalars['String']['input'];
  steuerpflicht: SteuerpflichtInput;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleTrailing?: InputMaybe<Scalars['String']['input']>;
};

export type NatuerlichePersonKurzbezeichnungInput = {
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NatuerlichePersonResponse = {
  data: NatuerlichePerson;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type NatuerlichePersonUpdateInput = {
  firstname: Scalars['String']['input'];
  kurzBezeichnung: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  salutation?: InputMaybe<Scalars['String']['input']>;
  sprache: Scalars['String']['input'];
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleTrailing?: InputMaybe<Scalars['String']['input']>;
};

export type Notification = {
  actionList: Array<NotificationAction>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dismissed: Scalars['Boolean']['output'];
  notificationCategory: NotificationCategory;
  notificationId: Scalars['ID']['output'];
  notificationInfo: NotificationInfo;
  notificationType: NotificationType;
  progress: NotificationProgress;
  status: NotificationStatus;
};

export type NotificationAction = {
  body?: Maybe<Scalars['JSON']['output']>;
  link: Scalars['String']['output'];
  method: Scalars['String']['output'];
  typeOperation: NotificationTypeOperation;
};

export enum NotificationCategory {
  Error = 'ERROR',
  Info = 'INFO',
  Task = 'TASK',
  Warning = 'WARNING',
}

export enum NotificationDismiss {
  All = 'ALL',
  Single = 'SINGLE',
  Type = 'TYPE',
}

export type NotificationDismissInput = {
  action: NotificationDismiss;
  notificationId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationInfo = {
  parameterList: Array<NotificationInfoKeyValue>;
  titleType: NotificationTitleType;
};

export type NotificationInfoKeyValue = {
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum NotificationOrderBy {
  CreateTs = 'CREATE_TS',
  Type = 'TYPE',
}

export type NotificationPage = {
  contentList: Array<Notification>;
  page: Page;
};

export type NotificationPageResponse = {
  data: NotificationPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type NotificationProgress = {
  processed: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum NotificationStatus {
  Finished = 'FINISHED',
  FinishedError = 'FINISHED_ERROR',
  FinishedNoDataFound = 'FINISHED_NO_DATA_FOUND',
  FinishedWarning = 'FINISHED_WARNING',
  Initializing = 'INITIALIZING',
  Pending = 'PENDING',
  Running = 'RUNNING',
}

export enum NotificationTitleType {
  ApplyVertragVposIndexedValues = 'APPLY_VERTRAG_VPOS_INDEXED_VALUES',
  ApplyVertragVposIndexedValuesErstellung = 'APPLY_VERTRAG_VPOS_INDEXED_VALUES_ERSTELLUNG',
  Auftragserstellung = 'AUFTRAGSERSTELLUNG',
  AuftragVerbuchen = 'AUFTRAG_VERBUCHEN',
  Belegausgabe = 'BELEGAUSGABE',
  BkObjektAbrechnung = 'BK_OBJEKT_ABRECHNUNG',
  BkObjektAbrechnungErstellung = 'BK_OBJEKT_ABRECHNUNG_ERSTELLUNG',
  BkTopAbrechnung = 'BK_TOP_ABRECHNUNG',
  BookingSuggestionCreation = 'BOOKING_SUGGESTION_CREATION',
  Buchungsanweisung = 'BUCHUNGSANWEISUNG',
  Budgetierung = 'BUDGETIERUNG',
  DataCarrierPainCreation = 'DATA_CARRIER_PAIN_CREATION',
  EmpfangeneBankbuchungenVerbuchen = 'EMPFANGENE_BANKBUCHUNGEN_VERBUCHEN',
  FirmendatenInitializer = 'FIRMENDATEN_INITIALIZER',
  HeAbrechnung = 'HE_ABRECHNUNG',
  HeAbrechnungErstellung = 'HE_ABRECHNUNG_ERSTELLUNG',
  IncomingInvoiceBookingCreation = 'INCOMING_INVOICE_BOOKING_CREATION',
  InfoMailDeliveryDetermineRecipientList = 'INFO_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST',
  InfoMailDeliverySend = 'INFO_MAIL_DELIVERY_SEND',
  KontoauszugEbicsDownloadManuell = 'KONTOAUSZUG_EBICS_DOWNLOAD_MANUELL',
  KontoauszugOnlinebankingImportierenManuell = 'KONTOAUSZUG_ONLINEBANKING_IMPORTIEREN_MANUELL',
  MahnungAusgabe = 'MAHNUNG_AUSGABE',
  MahnungErstellung = 'MAHNUNG_ERSTELLUNG',
  MahnungVerbuchen = 'MAHNUNG_VERBUCHEN',
  MahnungVersand = 'MAHNUNG_VERSAND',
  ObjektWirtschaftsplanErstellung = 'OBJEKT_WIRTSCHAFTSPLAN_ERSTELLUNG',
  PaymentProposalCreation = 'PAYMENT_PROPOSAL_CREATION',
  PaymentProposalExecution = 'PAYMENT_PROPOSAL_EXECUTION',
  SepaLastschriftMandat = 'SEPA_LASTSCHRIFT_MANDAT',
  SubAbrechnungErstellung = 'SUB_ABRECHNUNG_ERSTELLUNG',
  TopWirtschaftsplanErstellung = 'TOP_WIRTSCHAFTSPLAN_ERSTELLUNG',
  UstVomAufwandErstellung = 'UST_VOM_AUFWAND_ERSTELLUNG',
  VertragVposIndexedValuesErstellung = 'VERTRAG_VPOS_INDEXED_VALUES_ERSTELLUNG',
  VorschreibungMailDeliveryDetermineRecipientList = 'VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST',
  VorschreibungMailDeliverySend = 'VORSCHREIBUNG_MAIL_DELIVERY_SEND',
  VorsteuerkuerzungErstellung = 'VORSTEUERKUERZUNG_ERSTELLUNG',
  WeObjektAbrechnung = 'WE_OBJEKT_ABRECHNUNG',
  WeObjektAbrechnungErstellung = 'WE_OBJEKT_ABRECHNUNG_ERSTELLUNG',
  WeTopAbrechnung = 'WE_TOP_ABRECHNUNG',
  ZahlungsvorschlagVerbuchen = 'ZAHLUNGSVORSCHLAG_VERBUCHEN',
  ZinslisteErstellung = 'ZINSLISTE_ERSTELLUNG',
}

export enum NotificationType {
  ApplyVertragVposIndexedValues = 'APPLY_VERTRAG_VPOS_INDEXED_VALUES',
  ApplyVertragVposIndexedValuesErstellung = 'APPLY_VERTRAG_VPOS_INDEXED_VALUES_ERSTELLUNG',
  Auftragserstellung = 'AUFTRAGSERSTELLUNG',
  AuftragVerbuchen = 'AUFTRAG_VERBUCHEN',
  Belegausgabe = 'BELEGAUSGABE',
  BkObjektAbrechnung = 'BK_OBJEKT_ABRECHNUNG',
  BkObjektAbrechnungErstellung = 'BK_OBJEKT_ABRECHNUNG_ERSTELLUNG',
  BkTopAbrechnung = 'BK_TOP_ABRECHNUNG',
  BookingSuggestionCreation = 'BOOKING_SUGGESTION_CREATION',
  Buchungsanweisung = 'BUCHUNGSANWEISUNG',
  Budgetierung = 'BUDGETIERUNG',
  DataCarrierPainCreation = 'DATA_CARRIER_PAIN_CREATION',
  EmpfangeneBankbuchungenVerbuchen = 'EMPFANGENE_BANKBUCHUNGEN_VERBUCHEN',
  FirmendatenInitializer = 'FIRMENDATEN_INITIALIZER',
  HeAbrechnung = 'HE_ABRECHNUNG',
  HeAbrechnungErstellung = 'HE_ABRECHNUNG_ERSTELLUNG',
  IncomingInvoiceBookingCreation = 'INCOMING_INVOICE_BOOKING_CREATION',
  InfoMailDeliveryDetermineRecipientList = 'INFO_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST',
  InfoMailDeliverySend = 'INFO_MAIL_DELIVERY_SEND',
  KontoauszugEbicsDownloadManuell = 'KONTOAUSZUG_EBICS_DOWNLOAD_MANUELL',
  KontoauszugOnlinebankingImportierenManuell = 'KONTOAUSZUG_ONLINEBANKING_IMPORTIEREN_MANUELL',
  MahnungAusgabe = 'MAHNUNG_AUSGABE',
  MahnungErstellung = 'MAHNUNG_ERSTELLUNG',
  MahnungVerbuchen = 'MAHNUNG_VERBUCHEN',
  MahnungVersand = 'MAHNUNG_VERSAND',
  ObjektWirtschaftsplanErstellung = 'OBJEKT_WIRTSCHAFTSPLAN_ERSTELLUNG',
  PaymentProposalCreation = 'PAYMENT_PROPOSAL_CREATION',
  PaymentProposalExecution = 'PAYMENT_PROPOSAL_EXECUTION',
  SepaLastschriftMandat = 'SEPA_LASTSCHRIFT_MANDAT',
  SubAbrechnungErstellung = 'SUB_ABRECHNUNG_ERSTELLUNG',
  TopWirtschaftsplanErstellung = 'TOP_WIRTSCHAFTSPLAN_ERSTELLUNG',
  UstVomAufwandErstellung = 'UST_VOM_AUFWAND_ERSTELLUNG',
  VertragVposIndexedValuesErstellung = 'VERTRAG_VPOS_INDEXED_VALUES_ERSTELLUNG',
  VorschreibungMailDeliveryDetermineRecipientList = 'VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST',
  VorschreibungMailDeliverySend = 'VORSCHREIBUNG_MAIL_DELIVERY_SEND',
  VorsteuerkuerzungErstellung = 'VORSTEUERKUERZUNG_ERSTELLUNG',
  WeObjektAbrechnung = 'WE_OBJEKT_ABRECHNUNG',
  WeObjektAbrechnungErstellung = 'WE_OBJEKT_ABRECHNUNG_ERSTELLUNG',
  WeTopAbrechnung = 'WE_TOP_ABRECHNUNG',
  ZahlungsvorschlagVerbuchen = 'ZAHLUNGSVORSCHLAG_VERBUCHEN',
  ZinslisteErstellung = 'ZINSLISTE_ERSTELLUNG',
}

export enum NotificationTypeOperation {
  Restart = 'RESTART',
}

export enum NutzungsArt {
  Garage = 'GARAGE',
  NichtWohnen = 'NICHT_WOHNEN',
  Wohnen = 'WOHNEN',
}

export type NutzungsArtTuple = {
  text: Scalars['String']['output'];
  value: NutzungsArt;
};

export type ObjAbrAufwand = {
  kontoList: Array<ObjAbrKonto>;
  summe: Scalars['Float']['output'];
};

export type ObjAbrBuchungszeile = {
  auftragId?: Maybe<Scalars['String']['output']>;
  belegDatum?: Maybe<Scalars['String']['output']>;
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegId: Scalars['String']['output'];
  belegnummer?: Maybe<Scalars['String']['output']>;
  betrag: Scalars['Float']['output'];
  buchungIdList: Array<Scalars['String']['output']>;
  buchungskreisRechtstraeger: RechtstraegerBase;
  dueDate?: Maybe<Scalars['String']['output']>;
  sollHaben: SollHabenTuple;
  standingInvoicePartialAmountId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  ustVomAufwandSteuersatz?: Maybe<Scalars['Int']['output']>;
  vertragspartner?: Maybe<RechtstraegerBase>;
};

export type ObjAbrErloes = {
  kontoList: Array<ObjAbrKonto>;
  summe: Scalars['Float']['output'];
};

export type ObjAbrKonto = {
  abweichenderAufteilungsschluesselBezeichnung?: Maybe<Scalars['String']['output']>;
  aufteilungsschluesselList: Array<Scalars['String']['output']>;
  bezeichnung: Scalars['String']['output'];
  buchungszeileList: Array<ObjAbrBuchungszeile>;
  expenseReducing: Scalars['Boolean']['output'];
  kontoId: Scalars['ID']['output'];
  summe: Scalars['Float']['output'];
};

export type Objekt = {
  addressList: Array<Address>;
  countryCodeIso2: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fotoFileId?: Maybe<Scalars['String']['output']>;
  fotoType?: Maybe<FotoType>;
  kurzBezeichnung: Scalars['String']['output'];
  objektId: Scalars['ID']['output'];
  objektSummeAnteile?: Maybe<ObjektSummeAnteile>;
  postIt?: Maybe<PostIt>;
  rechnungsAussteller: Rechtstraeger;
  rechnungsAusstellerBezeichnung?: Maybe<Scalars['String']['output']>;
  rechnungsAusstellerChangeable?: Maybe<Scalars['Boolean']['output']>;
  sachbearbeiter?: Maybe<Mitarbeiter>;
  status: ObjektStatusTuple;
  subAdministration: Scalars['Boolean']['output'];
  sumNutzflaeche: Scalars['Float']['output'];
  sumNutzwert: Scalars['Int']['output'];
  tagList: Array<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  verwaltungBis?: Maybe<Scalars['String']['output']>;
  verwaltungSeit: Scalars['String']['output'];
  verwaltungsart: VerwaltungsartTuple;
  verwaltungsartChangeable?: Maybe<Scalars['Boolean']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type ObjektAbrechnung = {
  abrechnungsdefinitionType: AbrechnungsdefinitionTypeTuple;
  abrechnungskreisList: Array<ObjektAbrechnungkreis>;
  bezeichnung: Scalars['String']['output'];
  buchungskreisId: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  file?: Maybe<FileInfo>;
  fileId?: Maybe<Scalars['String']['output']>;
  fromInclusive: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  infoAbrechnungskreisList: Array<AbrInfoAbrechnungskreis>;
  objekt: ObjektBase;
  objektAbrechnungId: Scalars['ID']['output'];
  rechnungsausstellerId: Scalars['String']['output'];
  status: AbrechnungStatusTuple;
  toInclusive: Scalars['String']['output'];
  topAbrechnungOverview?: Maybe<TopAbrechnungOverview>;
  type: AbrechnungTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type ObjektAbrechnungDeviationListResponse = {
  data: Array<Deviation>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektAbrechnungDeviationSourceEntity = AbstractDeviationSourceEntity & {
  abrechnungBezeichnung?: Maybe<Scalars['String']['output']>;
  abrechnungType: AbrechnungTypeTuple;
  entityId: Scalars['ID']['output'];
  fromInclusive: Scalars['String']['output'];
  ids?: Maybe<ObjektAbrechnungDeviationSourceEntityIds>;
  objektBezeichnung: Scalars['String']['output'];
  toInclusive: Scalars['String']['output'];
  type: DeviationSourceEntityTypeTuple;
};

export type ObjektAbrechnungDeviationSourceEntityIds = {
  linkText: Scalars['String']['output'];
  objektAbrechnungId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
};

export type ObjektAbrechnungGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  fromInclusive: Scalars['String']['output'];
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  toInclusive: Scalars['String']['output'];
  warningList: Array<WarningData>;
};

export type ObjektAbrechnungGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  objektAbrechnung?: Maybe<ObjektAbrechnung>;
  objektAbrechnungDeleted: Scalars['Boolean']['output'];
  objektAbrechnungDeletedBy?: Maybe<Scalars['String']['output']>;
  objektAbrechnungDeletedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  objektAbrechnungDeletedTs?: Maybe<Scalars['String']['output']>;
  objektKurzBezeichnung: Scalars['String']['output'];
  rechnungsAusstellerKurzBezeichnung?: Maybe<Scalars['String']['output']>;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type ObjektAbrechnungGenerierlaufListEntryPage = {
  contentList: Array<ObjektAbrechnungGenerierlaufEntry>;
  page: Page;
};

export type ObjektAbrechnungGenerierlaufListEntryPageResponse = {
  data: ObjektAbrechnungGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum ObjektAbrechnungGenerierlaufOrderBy {
  Objekt = 'OBJEKT',
  Status = 'STATUS',
}

export type ObjektAbrechnungGenerierlaufResponse = {
  data: ObjektAbrechnungGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektAbrechnungListEntry = {
  abrechnungsdefinitionType: AbrechnungsdefinitionTypeTuple;
  auftragCreationStatus: AuftragCreationStatusTuple;
  bezeichnung: Scalars['String']['output'];
  countAuftragCreated?: Maybe<Scalars['Int']['output']>;
  countAuftragToCreateTotal?: Maybe<Scalars['Int']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  fileId?: Maybe<Scalars['String']['output']>;
  firmendatenId: Scalars['String']['output'];
  fromInclusive: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  objektAbrechnungId: Scalars['ID']['output'];
  status: AbrechnungStatusTuple;
  toInclusive: Scalars['String']['output'];
  topAbrechnungenExist: Scalars['Boolean']['output'];
  warningList: Array<WarningData>;
};

export type ObjektAbrechnungListEntryListResponse = {
  data: Array<ObjektAbrechnungListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektAbrechnungOutputOptions = {
  buchungsdetails: BuchungsdetailsTypeTuple;
  consolidateEinnahmen?: Maybe<ConsolidateEinnahmenTypeTuple>;
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  displayEinnahmen: Scalars['Boolean']['output'];
  displayKontonummer: Scalars['Boolean']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
};

export type ObjektAbrechnungOutputOptionsInput = {
  buchungsdetails: BuchungsdetailsType;
  consolidateEinnahmen?: InputMaybe<ConsolidateEinnahmenType>;
  displayEinnahmen: Scalars['Boolean']['input'];
  displayKontonummer: Scalars['Boolean']['input'];
};

export type ObjektAbrechnungResponse = {
  data: ObjektAbrechnung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektAbrechnungkreis = {
  abrechnungsdefinitionId: Scalars['String']['output'];
  abrechnungsdefinitionVersionId: Scalars['String']['output'];
  abrechnungskreisId: Scalars['ID']['output'];
  abrechnungskreisType: AbrechnungskreisTypeTuple;
  auftSchluesselBezeichnung?: Maybe<Scalars['String']['output']>;
  aufwand: ObjAbrAufwand;
  bezeichnung: Scalars['String']['output'];
  erloes: ObjAbrErloes;
  saldo: Scalars['Float']['output'];
  sumAufwand: Scalars['Float']['output'];
  ustVomAufwand?: Maybe<ObjAbrAufwand>;
};

export type ObjektAbrechnungsdefinition = {
  abrechnungstitel?: Maybe<Scalars['String']['output']>;
};

export type ObjektAddressResponse = {
  data: Address;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektAddressUpdateInput = {
  addressId?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  cityAdditionalInformation?: InputMaybe<Scalars['String']['input']>;
  countryCodeIso2: Scalars['String']['input'];
  houseEntranceApartmentNumber?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type ObjektAufteilungsschluessel = {
  aufteilungsschluesselId: Scalars['ID']['output'];
  aufteilungsschluesselStatus: AufteilungsschluesselStatusTuple;
  bezeichnung: Scalars['String']['output'];
  bezeichnungOriginal: Scalars['String']['output'];
  bezeichnungOverwritten?: Maybe<Scalars['String']['output']>;
  bezugsbasis: BezugsbasisTuple;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  masseinheit: MasseinheitTuple;
  objektAufteilungsschluesselId: Scalars['String']['output'];
  objektAufteilungsschluesselStatus: ObjektAufteilungsschluesselStatusTuple;
  verwendung?: Maybe<ObjektAufteilungsschluesselVerwendungTuple>;
  warningList: Array<ResponseDataWarning>;
};

export type ObjektAufteilungsschluesselListResponse = {
  data: Array<ObjektAufteilungsschluessel>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektAufteilungsschluesselResponse = {
  data: ObjektAufteilungsschluessel;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum ObjektAufteilungsschluesselStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type ObjektAufteilungsschluesselStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: ObjektAufteilungsschluesselStatus;
};

export type ObjektAufteilungsschluesselUpdateInput = {
  bezeichnung?: InputMaybe<Scalars['String']['input']>;
  verwendung?: InputMaybe<Scalars['String']['input']>;
};

export enum ObjektAufteilungsschluesselVerwendung {
  Nutzflaeche = 'NUTZFLAECHE',
  Nutzwert = 'NUTZWERT',
}

export type ObjektAufteilungsschluesselVerwendungTuple = {
  text: Scalars['String']['output'];
  value: ObjektAufteilungsschluesselVerwendung;
};

export type ObjektBankDetails = {
  bankDetails: BankDetails;
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  objektBankDetailsId: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  verwendungList: Array<ObjektBankDetailsVerwendungTuple>;
  warningList: Array<ResponseDataWarning>;
};

export type ObjektBankDetailsCreateInput = {
  bankDetailsId: Scalars['String']['input'];
  bezeichnung: Scalars['String']['input'];
  verwendungList: Array<ObjektBankDetailsVerwendung>;
  weRechnungsAusstellerRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
};

export type ObjektBankDetailsResponse = {
  data: ObjektBankDetails;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektBankDetailsUpdateInput = {
  bankDetailsId: Scalars['String']['input'];
  bezeichnung: Scalars['String']['input'];
  verwendungList: Array<ObjektBankDetailsVerwendung>;
};

export enum ObjektBankDetailsVerwendung {
  ReparaturFonds = 'REPARATUR_FONDS',
  StandardAuszahlung = 'STANDARD_AUSZAHLUNG',
  StandardEinzahlung = 'STANDARD_EINZAHLUNG',
}

export type ObjektBankDetailsVerwendungListResponse = {
  data: Array<ObjektBankDetailsVerwendungTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektBankDetailsVerwendungTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: ObjektBankDetailsVerwendung;
};

export type ObjektBase = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  objektId: Scalars['ID']['output'];
  rechnungsAussteller: RechtstraegerBase;
  status: ObjektStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  verwaltungsart: VerwaltungsartTuple;
  warningList: Array<ResponseDataWarning>;
};

export type ObjektBaseListResponse = {
  data: Array<ObjektBase>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektBestandseinheitId = {
  bestandseinheitId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
};

export type ObjektBestandseinheitVertrag = {
  bestandseinheit: OrderBestandseinheit;
  objekt: OrderObjekt;
  vertragId: Scalars['ID']['output'];
};

export type ObjektCreateInput = {
  addressList: Array<InputMaybe<AddressStreetCreateInput>>;
  kurzBezeichnung: Scalars['String']['input'];
  rechnungsAusstellerBezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId: Scalars['ID']['input'];
  sachbearbeiterId?: InputMaybe<Scalars['ID']['input']>;
  tagList: Array<Scalars['String']['input']>;
  verwaltungSeit: Scalars['String']['input'];
  verwaltungsart: Verwaltungsart;
};

export type ObjektFinancialPlanDeleteInput = {
  fileId: Scalars['ID']['input'];
};

export type ObjektFinancialPlanGenerierlauf = {
  budgeting: BudgetingBase;
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  entryList: Array<ObjektFinancialPlanGenerierlaufEntry>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type ObjektFinancialPlanGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['String']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type ObjektFinancialPlanGenerierlaufInput = {
  budgetingId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
};

export type ObjektFinancialPlanGenerierlaufResponse = {
  data: ObjektFinancialPlanGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektInfoFeldAuflage = AbstractObjektInfoFeld & {
  amtlicheQuadratmeterFestsetzung: Scalars['Boolean']['output'];
  assanierungsgebiet: Scalars['Boolean']['output'];
  bebaut: Scalars['Boolean']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  denkmalschutz: Scalars['Boolean']['output'];
  fileIdList: Array<Scalars['String']['output']>;
  gruenderzeitviertel: Scalars['Boolean']['output'];
  naturschutz: Scalars['Boolean']['output'];
  objektInfoFeldId: Scalars['ID']['output'];
  postIt?: Maybe<PostIt>;
  schutzzone: Scalars['Boolean']['output'];
  status: InfoFeldStatusTuple;
  type: ObjektInfoFeldTypeTuple;
  vollanwendungMRG: Scalars['Boolean']['output'];
  warningList: Array<ResponseDataWarning>;
  wohnzone: Scalars['Boolean']['output'];
};

export type ObjektInfoFeldGrundstuecksdaten = AbstractObjektInfoFeld & {
  anzahlGeschosseUeberNiveau?: Maybe<Scalars['Int']['output']>;
  anzahlGeschosseUnterNiveau?: Maybe<Scalars['Int']['output']>;
  bauflaeche?: Maybe<Scalars['Float']['output']>;
  baujahr?: Maybe<Scalars['Int']['output']>;
  bebauteFlaeche?: Maybe<Scalars['Float']['output']>;
  benuetzungsbewilligung?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  einlagezahl?: Maybe<Scalars['String']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  grundstuecksflaeche?: Maybe<Scalars['String']['output']>;
  grundstuecksnummer: Scalars['String']['output'];
  katastralgemeinde?: Maybe<Scalars['String']['output']>;
  objektInfoFeldId: Scalars['ID']['output'];
  postIt?: Maybe<PostIt>;
  status: InfoFeldStatusTuple;
  type: ObjektInfoFeldTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type ObjektInfoFeldHausbetreuung = AbstractObjektInfoFeld & {
  beauftragungsgrund: Scalars['String']['output'];
  beginn?: Maybe<Scalars['String']['output']>;
  contactPerson?: Maybe<ContactPerson>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  ende?: Maybe<Scalars['String']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  kuendigungsfrist?: Maybe<KuendigungsfristTuple>;
  kuendigungsfristDauer?: Maybe<Scalars['Int']['output']>;
  kuendigungsfristJeweilsZum?: Maybe<KuendigungsfristJeweilsZumTuple>;
  objektInfoFeldId: Scalars['ID']['output'];
  postIt?: Maybe<PostIt>;
  rechtstraeger?: Maybe<RechtstraegerBase>;
  status: InfoFeldStatusTuple;
  type: ObjektInfoFeldTypeTuple;
  vertragsdaten?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
  wertsicherung?: Maybe<Scalars['String']['output']>;
};

export type ObjektInfoFeldListResponse = {
  data: Array<AbstractObjektInfoFeld>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektInfoFeldPerson = AbstractObjektInfoFeld & {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  objektInfoFeldId: Scalars['ID']['output'];
  postIt?: Maybe<PostIt>;
  rechtstraeger: RechtstraegerBase;
  rolle: Scalars['String']['output'];
  status: InfoFeldStatusTuple;
  type: ObjektInfoFeldTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type ObjektInfoFeldResponse = {
  data: AbstractObjektInfoFeld;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum ObjektInfoFeldType {
  Auflage = 'AUFLAGE',
  Grundstuecksdaten = 'GRUNDSTUECKSDATEN',
  Hausbetreuung = 'HAUSBETREUUNG',
  Person = 'PERSON',
  Versicherung = 'VERSICHERUNG',
}

export type ObjektInfoFeldTypeListResponse = {
  data: Array<ObjektInfoFeldTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektInfoFeldTypeTuple = {
  text: Scalars['String']['output'];
  value: ObjektInfoFeldType;
};

export type ObjektInfoFeldVersicherung = AbstractObjektInfoFeld & {
  beginn?: Maybe<Scalars['String']['output']>;
  contactPerson?: Maybe<ContactPerson>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  datumSchaetzung?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  ende?: Maybe<Scalars['String']['output']>;
  erstmalsFaellig?: Maybe<Scalars['String']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  firma?: Maybe<FirmaBase>;
  hauptDueDate?: Maybe<Scalars['String']['output']>;
  kuendigungsfrist?: Maybe<KuendigungsfristTuple>;
  kuendigungsfristDauer?: Maybe<Scalars['Int']['output']>;
  kuendigungsfristJeweilsZum?: Maybe<KuendigungsfristJeweilsZumTuple>;
  makler?: Maybe<RechtstraegerBase>;
  neubauwert?: Maybe<Scalars['Float']['output']>;
  objektInfoFeldId: Scalars['ID']['output'];
  polizze?: Maybe<Scalars['String']['output']>;
  postIt?: Maybe<PostIt>;
  sachbearbeiter?: Maybe<ContactPerson>;
  status: InfoFeldStatusTuple;
  type: ObjektInfoFeldTypeTuple;
  validFrom?: Maybe<Scalars['String']['output']>;
  versicherungsart: Scalars['String']['output'];
  versicherungssumme?: Maybe<Scalars['Float']['output']>;
  warningList: Array<ResponseDataWarning>;
  wertsicherung?: Maybe<Scalars['String']['output']>;
  zahlungsperiode?: Maybe<ZahlungsperiodeTuple>;
};

export type ObjektListEntry = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  erstelltAm: Scalars['String']['output'];
  fotoFileId?: Maybe<Scalars['String']['output']>;
  fotoType?: Maybe<FotoType>;
  kurzBezeichnung: Scalars['String']['output'];
  objektId: Scalars['ID']['output'];
  rechnungsAusstellerId: Scalars['String']['output'];
  status: ObjektStatusTuple;
  verwaltungBis?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type ObjektListEntryPage = {
  contentList: Array<ObjektListEntry>;
  page: Page;
};

export type ObjektListEntryPageResponse = {
  data: ObjektListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektRechnungsAusstellerObjektBankDetails = {
  objektBankDetailsList: Array<ObjektBankDetails>;
  warningList: Array<ResponseDataWarning>;
};

export type ObjektRechnungsAusstellerObjektBankDetailsResponse = {
  data: ObjektRechnungsAusstellerObjektBankDetails;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektResponse = {
  data: Objekt;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum ObjektStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type ObjektStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: ObjektStatus;
};

export type ObjektSummeAnteile = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  historicizedList?: Maybe<Array<ObjektSummeAnteile>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  objektSummeAnteileId: Scalars['ID']['output'];
  summeAnteile: Scalars['Float']['output'];
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type ObjektSummeAnteileInput = {
  kommentar: Scalars['String']['input'];
  summeAnteile: Scalars['Float']['input'];
  validFrom: Scalars['String']['input'];
};

export type ObjektSummeAnteileListResponse = {
  data: Array<ObjektSummeAnteile>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektSummeAnteileResponse = {
  data: ObjektSummeAnteile;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektTopVertrag = {
  bestandseinheit: BestandseinheitBase;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  objekt: ObjektListEntry;
  vertrag: VertragBase;
  warningList: Array<ResponseDataWarning>;
};

export type ObjektUpdateInput = {
  addressList: Array<InputMaybe<ObjektAddressUpdateInput>>;
  kurzBezeichnung: Scalars['String']['input'];
  rechnungsAusstellerBezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId: Scalars['ID']['input'];
  sachbearbeiterId?: InputMaybe<Scalars['ID']['input']>;
  tagList: Array<Scalars['String']['input']>;
  verwaltungBis?: InputMaybe<Scalars['String']['input']>;
  verwaltungSeit: Scalars['String']['input'];
  verwaltungsart: Verwaltungsart;
};

export type ObjektVorschreibungsposition = {
  art: VorschreibungspositionArtTuple;
  bezeichnung: Scalars['String']['output'];
  bezeichnungOriginal: Scalars['String']['output'];
  bezeichnungOverwritten?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  objektVorschreibungspositionId: Scalars['String']['output'];
  objektVorschreibungspositionStatus: ObjektVorschreibungspositionStatusTuple;
  tagList: Array<Scalars['String']['output']>;
  umsatzsteuerkennzeichen?: Maybe<Umsatzsteuerkennzeichen>;
  vertragsartList: Array<VertragsartTuple>;
  vorschreibungspositionId: Scalars['ID']['output'];
  vorschreibungspositionStatus: VorschreibungspositionStatusTuple;
  warningList: Array<ResponseDataWarning>;
};

export type ObjektVorschreibungspositionListResponse = {
  data: Array<ObjektVorschreibungsposition>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum ObjektVorschreibungspositionStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type ObjektVorschreibungspositionStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: ObjektVorschreibungspositionStatus;
};

export type ObjektVorschreibungspositionUpdateInput = {
  bezeichnung?: InputMaybe<Scalars['String']['input']>;
};

export type ObjektVorschreibungspositionsResponse = {
  data: ObjektVorschreibungsposition;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OffenePosten = {
  auszifferungList: Array<Auszifferung>;
  belegDatum: Scalars['String']['output'];
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegSymbol: FibuBelegSymbolTuple;
  belegnummer: Scalars['String']['output'];
  buchungId: Scalars['String']['output'];
  buchungsdatum: Scalars['String']['output'];
  buchungskreisId: Scalars['String']['output'];
  buchungskreisRechtstraegerBezeichnung: Scalars['String']['output'];
  buchungskreisRechtstraegerId: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dataCarrierBelegId?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['String']['output'];
  gesamtBetrag: Scalars['Float']['output'];
  iban?: Maybe<Scalars['String']['output']>;
  konto: Konto;
  letzteMahnstufe: Scalars['Int']['output'];
  offenePostenId: Scalars['ID']['output'];
  offenerBetrag: Scalars['Float']['output'];
  statementNumber?: Maybe<Scalars['String']['output']>;
  vertragspartner: FibuRechtstraeger;
};

export enum OffenePostenKontoType {
  Debitor = 'DEBITOR',
  Kreditor = 'KREDITOR',
}

export type OffenePostenKontoTypeListResponse = {
  data: Array<OffenePostenKontoTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OffenePostenKontoTypeTuple = {
  text: Scalars['String']['output'];
  value: OffenePostenKontoType;
};

export enum OffenePostenOrderBy {
  Belegnummer = 'BELEGNUMMER',
  BelegDatum = 'BELEG_DATUM',
  BelegSymbol = 'BELEG_SYMBOL',
  Buchungsdatum = 'BUCHUNGSDATUM',
  BuchungskreisRechtstraegerKurzbezeichnung = 'BUCHUNGSKREIS_RECHTSTRAEGER_KURZBEZEICHNUNG',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  DueDate = 'DUE_DATE',
  GesamtBetrag = 'GESAMT_BETRAG',
  KontoNummer = 'KONTO_NUMMER',
  LetzteMahnstufe = 'LETZTE_MAHNSTUFE',
  OffenerBetrag = 'OFFENER_BETRAG',
  VertragspatnerKurzbezeichnung = 'VERTRAGSPATNER_KURZBEZEICHNUNG',
}

export type OffenePostenPage = {
  contentList: Array<OffenePosten>;
  page: Page;
};

export type OffenePostenPageResponse = {
  data: OffenePostenPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OffenePostenResponse = {
  data: OffenePosten;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OffenePostenSummary = {
  sumBrutto: Scalars['Float']['output'];
  sumOutstandingAmount: Scalars['Float']['output'];
};

export type OffenePostenSummaryResponse = {
  data: OffenePostenSummary;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OperatingSite = {
  addressesAndContacts: AddressesAndContacts;
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  operatingSiteId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type OperatingSiteActionInput = {
  operatingSiteId?: InputMaybe<Scalars['String']['input']>;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OperatingSiteInput = {
  bezeichnung: Scalars['String']['input'];
};

export type OperatingSiteListResponse = {
  data: Array<OperatingSite>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OperatingSiteResponse = {
  data: OperatingSite;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OrderAddress = {
  addressId: Scalars['ID']['output'];
  city: Scalars['String']['output'];
  cityAdditionalInformation?: Maybe<Scalars['String']['output']>;
  countryCodeIso2: Scalars['String']['output'];
  houseEntranceApartmentNumber?: Maybe<Scalars['String']['output']>;
  postofficeBoxNumber?: Maybe<Scalars['String']['output']>;
  postofficeBoxZipCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  type: AddressType;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type OrderBankDetails = {
  accountHolder: Scalars['String']['output'];
  bankDetailsId: Scalars['ID']['output'];
  bankname: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
};

export type OrderBankDetailsListResponse = {
  data: Array<OrderBankDetails>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OrderBestandseinheit = {
  address: OrderAddress;
  bestandseinheitId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
};

export type OrderBestandseinheitListResponse = {
  data: Array<OrderBestandseinheit>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OrderBuchungsanweisung = {
  buchungsanweisungId: Scalars['ID']['output'];
  buchungsdatum: Scalars['String']['output'];
  buchungszeileList: Array<BuchungszeileWithDeviationInfo>;
  fibuBuchungId?: Maybe<Scalars['String']['output']>;
  fibuStatus: OrderBuchungsanweisungFibuStatusTuple;
  status: OrderBuchungsanweisungStatusTuple;
};

export enum OrderBuchungsanweisungActionType {
  ApplyAllDeviations = 'APPLY_ALL_DEVIATIONS',
}

export enum OrderBuchungsanweisungFibuStatus {
  Erstellt = 'ERSTELLT',
  Initialisiert = 'INITIALISIERT',
  Uebertragen = 'UEBERTRAGEN',
  UebertragenFehler = 'UEBERTRAGEN_FEHLER',
}

export type OrderBuchungsanweisungFibuStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: OrderBuchungsanweisungFibuStatus;
};

export type OrderBuchungsanweisungListEntry = {
  buchungsanweisungId: Scalars['String']['output'];
  fibuStatus: OrderBuchungsanweisungFibuStatusTuple;
  status: OrderBuchungsanweisungStatusTuple;
};

export type OrderBuchungsanweisungResponse = {
  data: OrderBuchungsanweisung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum OrderBuchungsanweisungStatus {
  Invalid = 'INVALID',
  UnderReview = 'UNDER_REVIEW',
  Valid = 'VALID',
}

export type OrderBuchungsanweisungStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: OrderBuchungsanweisungStatus;
};

export type OrderContact = {
  contactId: Scalars['String']['output'];
  type: Scalars['String']['output'];
  wert: Scalars['String']['output'];
};

export type OrderFileInfo = {
  fileId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export enum OrderGenerierlaufType {
  AuftragVerbuchen = 'AUFTRAG_VERBUCHEN',
  Belegausgabe = 'BELEGAUSGABE',
  IncomingInvoiceBookingCreation = 'INCOMING_INVOICE_BOOKING_CREATION',
  PaymentProposalCreation = 'PAYMENT_PROPOSAL_CREATION',
  PaymentProposalExecution = 'PAYMENT_PROPOSAL_EXECUTION',
  VorschreibungMailDeliveryDetermineRecipientList = 'VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST',
  VorschreibungMailDeliverySend = 'VORSCHREIBUNG_MAIL_DELIVERY_SEND',
}

export type OrderKonto = {
  bezeichnung: Scalars['String']['output'];
  kontoId: Scalars['ID']['output'];
  nummer: Scalars['String']['output'];
};

export type OrderKontoListResponse = {
  data: Array<OrderKonto>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OrderMitarbeiter = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenId: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  mitarbeiterId: Scalars['ID']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type OrderMitarbeiterListResponse = {
  data: Array<OrderMitarbeiter>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OrderObjekt = {
  kurzBezeichnung: Scalars['String']['output'];
  objektId: Scalars['ID']['output'];
};

export type OrderObjektListResponse = {
  data: Array<OrderObjekt>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OrderPayment = {
  amount: Scalars['Float']['output'];
  approvalList: Array<OrderPaymentApproval>;
  auftragId?: Maybe<Scalars['String']['output']>;
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegId?: Maybe<Scalars['String']['output']>;
  belegdatum?: Maybe<Scalars['String']['output']>;
  belegnummer?: Maybe<Scalars['String']['output']>;
  buchungsdatum?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deckungspruefungErrorText?: Maybe<Scalars['String']['output']>;
  heVertragId?: Maybe<Scalars['String']['output']>;
  noted: Scalars['Boolean']['output'];
  objekt: OrderObjekt;
  payee: OrderRechtstraeger;
  payeeAccountHolder: Scalars['String']['output'];
  payeeIban: Scalars['String']['output'];
  paymentApprovalStatus?: Maybe<PaymentApprovalStatusTuple>;
  paymentDate: Scalars['String']['output'];
  paymentId: Scalars['ID']['output'];
  paymentProposal?: Maybe<Scalars['String']['output']>;
  paymentProposalId?: Maybe<Scalars['String']['output']>;
  paymentReferenceText?: Maybe<Scalars['String']['output']>;
  paymentTransactionType?: Maybe<PaymentTransactionTypeTuple>;
  rechnungsAussteller: OrderRechtstraeger;
  rechnungsAusstellerAccountHolder: Scalars['String']['output'];
  rechnungsAusstellerFibuKonto: OrderKonto;
  rechnungsAusstellerIban: Scalars['String']['output'];
  sepaCreditorId?: Maybe<Scalars['String']['output']>;
  sepaMandatReference?: Maybe<Scalars['String']['output']>;
  status: OrderPaymentStatusTuple;
  transactionInstructionId?: Maybe<Scalars['String']['output']>;
  type: PaymentTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  verwendungszweck?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type OrderPaymentApproval = {
  mitarbeiterId: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type OrderPaymentResponse = {
  data: OrderPayment;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum OrderPaymentStatus {
  AnBankGesendet = 'AN_BANK_GESENDET',
  AnBankGesendetAbgebrochen = 'AN_BANK_GESENDET_ABGEBROCHEN',
  AnBankGesendetFehler = 'AN_BANK_GESENDET_FEHLER',
  AnBankGesendetInArbeit = 'AN_BANK_GESENDET_IN_ARBEIT',
  AnBankGesendetUnterschriftenFehlen = 'AN_BANK_GESENDET_UNTERSCHRIFTEN_FEHLEN',
  AnBankGesendetUnterschriftenVollstaendig = 'AN_BANK_GESENDET_UNTERSCHRIFTEN_VOLLSTAENDIG',
  DatentraegerErzeugt = 'DATENTRAEGER_ERZEUGT',
  DatentraegerWirdErzeugt = 'DATENTRAEGER_WIRD_ERZEUGT',
  Durchfuehrbar = 'DURCHFUEHRBAR',
  Durchgefuehrt = 'DURCHGEFUEHRT',
  DurchgefuehrtRueckgebucht = 'DURCHGEFUEHRT_RUECKGEBUCHT',
  DurchgefuehrtRueckgebuchtVerbucht = 'DURCHGEFUEHRT_RUECKGEBUCHT_VERBUCHT',
  DurchgefuehrtVerbucht = 'DURCHGEFUEHRT_VERBUCHT',
  Erstellt = 'ERSTELLT',
  NichtDurchfuehrbar = 'NICHT_DURCHFUEHRBAR',
  NichtDurchgefuehrt = 'NICHT_DURCHGEFUEHRT',
  PaymentProposal = 'PAYMENT_PROPOSAL',
}

export type OrderPaymentStatusListResponse = {
  data: Array<OrderPaymentStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OrderPaymentStatusTuple = {
  text: Scalars['String']['output'];
  value: OrderPaymentStatus;
};

export type OrderPaymentTransactionTypeListResponse = {
  data: Array<PaymentTransactionTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OrderPaymentTypeListResponse = {
  data: Array<PaymentTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OrderRechtstraeger = {
  kundennummer: Scalars['String']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  rechtstraegerId: Scalars['ID']['output'];
  type: RechtstraegerTypeTuple;
};

export type OrderRechtstraegerListResponse = {
  data: Array<OrderRechtstraeger>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OrderVertragsartListResponse = {
  data: Array<OrderVertragsartTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type OrderVertragsartTuple = {
  text: Scalars['String']['output'];
  value: Vertragsart;
};

export type OwnMitarbeiterListResponse = {
  data: Array<MitarbeiterWithFirmaInfo>;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type Page = {
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageDataChangeLog = {
  contentList?: Maybe<Array<ChangeLog>>;
  totalElements?: Maybe<Scalars['String']['output']>;
};

export type PageDataChangeLogResponse = {
  data: PageDataChangeLog;
  errorList?: Maybe<Array<ErrorData>>;
  warningList?: Maybe<Array<WarningData>>;
};

export enum Pain001Schema {
  V03 = 'V03',
  V03Stuzza = 'V03_STUZZA',
  V09 = 'V09',
}

export type Pain001SchemaListResponse = {
  data: Array<Pain001SchemaTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Pain001SchemaTuple = {
  text: Scalars['String']['output'];
  value: Pain001Schema;
};

export enum Pain008Schema {
  V02 = 'V02',
  V02Stuzza = 'V02_STUZZA',
  V08 = 'V08',
}

export type Pain008SchemaListResponse = {
  data: Array<Pain008SchemaTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Pain008SchemaTuple = {
  text: Scalars['String']['output'];
  value: Pain008Schema;
};

export type Participant = {
  contact?: Maybe<Contact>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  participantId?: Maybe<Scalars['ID']['output']>;
  rechtstraeger?: Maybe<RechtstraegerBase>;
};

export type ParticipantInput = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  participantId?: InputMaybe<Scalars['String']['input']>;
};

export type PasswordResetInput = {
  email: Scalars['String']['input'];
};

export type PasswordResetInputData = {
  data: PasswordResetInput;
};

export type PaymentActionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
};

export enum PaymentApprovalStatus {
  Approved = 'APPROVED',
  NotApproved = 'NOT_APPROVED',
  PartiallyApproved = 'PARTIALLY_APPROVED',
}

export type PaymentApprovalStatusListResponse = {
  data: Array<PaymentApprovalStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentApprovalStatusTuple = {
  text: Scalars['String']['output'];
  value: PaymentApprovalStatus;
};

export type PaymentAssignmentBulkActionInput = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  booked?: InputMaybe<Scalars['Boolean']['input']>;
  bookingDateFrom?: InputMaybe<Scalars['String']['input']>;
  bookingDateTo?: InputMaybe<Scalars['String']['input']>;
  buchungskreisRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  chargesFrom?: InputMaybe<Scalars['Float']['input']>;
  chargesInSeparateEntry?: InputMaybe<Scalars['Boolean']['input']>;
  chargesTo?: InputMaybe<Scalars['Float']['input']>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  createdByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  creditor?: InputMaybe<Scalars['String']['input']>;
  creditorIban?: InputMaybe<Scalars['String']['input']>;
  dataCarrierName?: InputMaybe<Scalars['String']['input']>;
  debtor?: InputMaybe<Scalars['String']['input']>;
  debtorIban?: InputMaybe<Scalars['String']['input']>;
  endToEndId?: InputMaybe<Scalars['String']['input']>;
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  noted?: InputMaybe<Scalars['Boolean']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  paymentAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  paymentId?: InputMaybe<Scalars['String']['input']>;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  reversal?: InputMaybe<Scalars['Boolean']['input']>;
  statementNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReceivedBankTransactionStatus>;
  transactionType?: InputMaybe<TransactionType>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
  valueDateFrom?: InputMaybe<Scalars['String']['input']>;
  valueDateTo?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentBankDetails = {
  bankDetailsId: Scalars['String']['output'];
  firmendatenId: Scalars['String']['output'];
  firmendatenKurzBezeichnung: Scalars['String']['output'];
  rechtstraegerId?: Maybe<Scalars['String']['output']>;
  rechtstraegerKurzBezeichnung?: Maybe<Scalars['String']['output']>;
};

export enum PaymentBelegSymbol {
  Ruecklastschrift = 'RUECKLASTSCHRIFT',
  Sonstiges = 'SONSTIGES',
  SpesenRuecklastschrift = 'SPESEN_RUECKLASTSCHRIFT',
  StornoRuecklastschrift = 'STORNO_RUECKLASTSCHRIFT',
  StornoSpesenRuecklastschrift = 'STORNO_SPESEN_RUECKLASTSCHRIFT',
  StornoZahlung = 'STORNO_ZAHLUNG',
  Zahlung = 'ZAHLUNG',
}

export type PaymentBelegSymbolListResponse = {
  data: Array<PaymentBelegSymbolTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentBelegSymbolTuple = {
  abbreviation: Scalars['String']['output'];
  text: Scalars['String']['output'];
  value: PaymentBelegSymbol;
};

export enum PaymentBuchungType {
  Ruecklastschrift = 'RUECKLASTSCHRIFT',
  Sonstiges = 'SONSTIGES',
  Spesen = 'SPESEN',
  Zahlung = 'ZAHLUNG',
}

export type PaymentBuchungTypeListResponse = {
  data: Array<PaymentBuchungTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentBuchungTypeTuple = {
  text: Scalars['String']['output'];
  value: PaymentBuchungType;
};

export type PaymentBuchungsanweisung = {
  belegDatum: Scalars['String']['output'];
  belegSymbol: PaymentBelegSymbolTuple;
  belegnummer: Scalars['String']['output'];
  buchungType: PaymentBuchungTypeTuple;
  buchungsanweisungId: Scalars['String']['output'];
  buchungskreisRechtstraeger?: Maybe<PaymentRechtstraeger>;
  buchungszeileList: Array<BookingSuggestionBuchungszeile>;
  currency: CurrencyTuple;
  dataCarrierBelegId: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['String']['output']>;
  erstelltAm: Scalars['String']['output'];
  fibuBuchungId?: Maybe<Scalars['String']['output']>;
  fibuBuchungStatus: PaymentFibuBuchungStatusTuple;
  iban?: Maybe<Scalars['String']['output']>;
  objekt?: Maybe<PaymentObjekt>;
  statementNumber: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export type PaymentBuchungsanweisungInput = {
  belegSymbol: PaymentBelegSymbol;
  buchungskreisRechtstraegerId: Scalars['ID']['input'];
  buchungszeileList?: InputMaybe<Array<BuchungszeileInput>>;
  fibuBuchungId?: InputMaybe<Scalars['ID']['input']>;
};

export type PaymentBulkActionInput = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  approvedByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  belegdatum?: InputMaybe<Scalars['String']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumBis?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumVon?: InputMaybe<Scalars['String']['input']>;
  dataCarrierIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  employeeApproval?: InputMaybe<EmployeeApproval>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeList?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceDateFrom?: InputMaybe<Scalars['String']['input']>;
  invoiceDateTo?: InputMaybe<Scalars['String']['input']>;
  noted?: InputMaybe<Scalars['Boolean']['input']>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  payeeIban?: InputMaybe<Scalars['String']['input']>;
  payeeId?: InputMaybe<Scalars['String']['input']>;
  paymentApprovalStatusList?: InputMaybe<Array<PaymentApprovalStatus>>;
  paymentDateFrom?: InputMaybe<Scalars['String']['input']>;
  paymentDateTo?: InputMaybe<Scalars['String']['input']>;
  paymentProposalId?: InputMaybe<Scalars['String']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
  paymentTypeList?: InputMaybe<Array<PaymentType>>;
  rechnungsAusstellerFibuKontoId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerIban?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  sepaCreditorId?: InputMaybe<Scalars['String']['input']>;
  sepaMandateReference?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<OrderPaymentStatus>>;
};

export type PaymentClearing = {
  availableAmount?: Maybe<Scalars['Float']['output']>;
  buchungskreisId: Scalars['String']['output'];
  buchungskreisRechtstraegerId: Scalars['String']['output'];
  buchungskreisRechtstraegerName: Scalars['String']['output'];
  kontoBezeichnung: Scalars['String']['output'];
  kontoId: Scalars['String']['output'];
  kontoNummer: Scalars['String']['output'];
  kontoVerwendung: KontoVerwendungTuple;
  outstandingAmount: Scalars['Float']['output'];
  vertragspartnerId: Scalars['String']['output'];
  vertragspartnerName: Scalars['String']['output'];
};

export type PaymentClearingBooking = {
  amount: Scalars['Float']['output'];
  availableAmount: Scalars['Float']['output'];
  belegDatum: Scalars['String']['output'];
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegnummer: Scalars['String']['output'];
  buchungId: Scalars['String']['output'];
  buchungsType: FibuBuchungTypeTuple;
  buchungsdatum: Scalars['String']['output'];
  clearedAmount: Scalars['Float']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dataCarrierBelegId?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  statementNumber?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
};

export type PaymentClearingBookingListResponse = {
  data: Array<PaymentClearingBooking>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentClearingListSummary = {
  sumAvailableAmount: Scalars['Float']['output'];
  sumOutstandingAmount: Scalars['Float']['output'];
};

export type PaymentClearingListSummaryResponse = {
  data: PaymentClearingListSummary;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum PaymentClearingOrderBy {
  Buchungskreis = 'BUCHUNGSKREIS',
  KontoVerwendung = 'KONTO_VERWENDUNG',
  OffenerBetrag = 'OFFENER_BETRAG',
  OffenerZahlungsbetrag = 'OFFENER_ZAHLUNGSBETRAG',
  Vertragspartner = 'VERTRAGSPARTNER',
  VertragspartnerFibuKonto = 'VERTRAGSPARTNER_FIBU_KONTO',
}

export type PaymentClearingPage = {
  contentList: Array<PaymentClearing>;
  page: Page;
};

export type PaymentClearingPageResponse = {
  data: PaymentClearingPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentCreateInput = {
  amount: Scalars['Float']['input'];
  auftragId?: InputMaybe<Scalars['String']['input']>;
  belegId: Scalars['String']['input'];
  paymentMethod: PaymentMethod;
  standingInvoicePartialAmountId?: InputMaybe<Scalars['String']['input']>;
};

export enum PaymentFibuBuchungStatus {
  NichtVerbucht = 'NICHT_VERBUCHT',
  Verbucht = 'VERBUCHT',
  WirdVerbucht = 'WIRD_VERBUCHT',
}

export type PaymentFibuBuchungStatusTuple = {
  text: Scalars['String']['output'];
  value: PaymentFibuBuchungStatus;
};

export enum PaymentGenerierlaufType {
  BookingSuggestionCreation = 'BOOKING_SUGGESTION_CREATION',
  BookingSuggestionVerbuchen = 'BOOKING_SUGGESTION_VERBUCHEN',
  Camt = 'CAMT',
  DataCarrierPainCreation = 'DATA_CARRIER_PAIN_CREATION',
  ReceivedBankTransactionVerbuchen = 'RECEIVED_BANK_TRANSACTION_VERBUCHEN',
}

export type PaymentInfo = {
  amount: Scalars['Float']['output'];
  payeeId: Scalars['String']['output'];
  paymentDate: Scalars['String']['output'];
  paymentLinkText: Scalars['String']['output'];
  paymentType: PaymentTypeTuple;
  rechnungsAusstellerId: Scalars['String']['output'];
};

export type PaymentKonto = {
  bezeichnung: Scalars['String']['output'];
  firmendatenId: Scalars['String']['output'];
  klasse: KontoKlasseTuple;
  kontoId: Scalars['ID']['output'];
  nummer: Scalars['String']['output'];
  type: KontoTypeTuple;
  verwendung?: Maybe<KontoVerwendungTuple>;
};

export type PaymentListEntry = {
  amount: Scalars['Float']['output'];
  approvable: Scalars['Boolean']['output'];
  approvalList: Array<OrderPaymentApproval>;
  auftragId?: Maybe<Scalars['String']['output']>;
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegId?: Maybe<Scalars['String']['output']>;
  belegdatum?: Maybe<Scalars['String']['output']>;
  belegnummer?: Maybe<Scalars['String']['output']>;
  bookingDate?: Maybe<Scalars['String']['output']>;
  buchungId?: Maybe<Scalars['String']['output']>;
  changeableToDatentraegerErzeugt: Scalars['Boolean']['output'];
  changeableToDurchfuehrbar: Scalars['Boolean']['output'];
  changeableToNichtDurchfuehrbar: Scalars['Boolean']['output'];
  changeableToNichtDurchgefuehrt: Scalars['Boolean']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable: Scalars['Boolean']['output'];
  dueDate?: Maybe<Scalars['String']['output']>;
  heVertragId?: Maybe<Scalars['String']['output']>;
  noted: Scalars['Boolean']['output'];
  objekt: OrderObjekt;
  payee: OrderRechtstraeger;
  payeeAccountHolder: Scalars['String']['output'];
  payeeIban: Scalars['String']['output'];
  paymentApprovalStatus?: Maybe<PaymentApprovalStatusTuple>;
  paymentDate: Scalars['String']['output'];
  paymentId: Scalars['ID']['output'];
  paymentMethod: PaymentMethodTuple;
  paymentProposal?: Maybe<Scalars['String']['output']>;
  paymentProposalId?: Maybe<Scalars['String']['output']>;
  paymentReferenceText?: Maybe<Scalars['String']['output']>;
  paymentTransactionType?: Maybe<PaymentTransactionTypeTuple>;
  paymentType: PaymentTypeTuple;
  rechnungsAussteller: OrderRechtstraeger;
  rechnungsAusstellerAccountHolder: Scalars['String']['output'];
  rechnungsAusstellerFibuKonto: OrderKonto;
  rechnungsAusstellerIban: Scalars['String']['output'];
  sepaCreditorId?: Maybe<Scalars['String']['output']>;
  sepaMandatReference?: Maybe<Scalars['String']['output']>;
  standingInvoicePartialAmountId?: Maybe<Scalars['String']['output']>;
  status: OrderPaymentStatusTuple;
  statusReasonList: Array<Scalars['String']['output']>;
  transactionInstructionId?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  verwendungszweck?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type PaymentListEntryPage = {
  contentList: Array<PaymentListEntry>;
  page: Page;
};

export type PaymentListEntryPageResponse = {
  data: PaymentListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentListSummary = {
  sumAmount: Scalars['Float']['output'];
};

export type PaymentListSummaryResponse = {
  data: PaymentListSummary;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum PaymentMethod {
  Keine = 'KEINE',
  Lastschrift = 'LASTSCHRIFT',
  Ueberweisung = 'UEBERWEISUNG',
}

export type PaymentMethodListResponse = {
  data: Array<PaymentMethodTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentMethodTuple = {
  text: Scalars['String']['output'];
  value: PaymentMethod;
};

export type PaymentObjekt = {
  kurzBezeichnung: Scalars['String']['output'];
  objektId: Scalars['ID']['output'];
};

export type PaymentObjektListResponse = {
  data: Array<PaymentObjekt>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum PaymentOrderBy {
  Amount = 'AMOUNT',
  Belegdatum = 'BELEGDATUM',
  Belegnummer = 'BELEGNUMMER',
  Buchungsdatum = 'BUCHUNGSDATUM',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  DueDate = 'DUE_DATE',
  HeVertrag = 'HE_VERTRAG',
  Noted = 'NOTED',
  Objekt = 'OBJEKT',
  Payee = 'PAYEE',
  PayeeIban = 'PAYEE_IBAN',
  PaymentApprovalStatus = 'PAYMENT_APPROVAL_STATUS',
  PaymentDate = 'PAYMENT_DATE',
  PaymentProposal = 'PAYMENT_PROPOSAL',
  PaymentReferenceText = 'PAYMENT_REFERENCE_TEXT',
  PaymentTransactionType = 'PAYMENT_TRANSACTION_TYPE',
  PaymentType = 'PAYMENT_TYPE',
  RechnungsAussteller = 'RECHNUNGS_AUSSTELLER',
  RechnungsAusstellerFibuBankAccount = 'RECHNUNGS_AUSSTELLER_FIBU_BANK_ACCOUNT',
  RechnungsAusstellerIban = 'RECHNUNGS_AUSSTELLER_IBAN',
  SepaCreditorId = 'SEPA_CREDITOR_ID',
  SepaMandateReference = 'SEPA_MANDATE_REFERENCE',
  Status = 'STATUS',
  Verwendungszweck = 'VERWENDUNGSZWECK',
}

export type PaymentProposal = {
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  objekt: OrderObjekt;
  paymentDate: Scalars['String']['output'];
  paymentProposalId: Scalars['ID']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type PaymentProposalCreationGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type PaymentProposalCreationGenerierlaufCreateInput = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  belegdatum?: InputMaybe<Scalars['String']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumBis?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumVon?: InputMaybe<Scalars['String']['input']>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  ebicsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  employeeApproval?: InputMaybe<EmployeeApproval>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeList?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceDateFrom?: InputMaybe<Scalars['String']['input']>;
  invoiceDateTo?: InputMaybe<Scalars['String']['input']>;
  noted?: InputMaybe<Scalars['Boolean']['input']>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  payeeIban?: InputMaybe<Scalars['String']['input']>;
  payeeId?: InputMaybe<Scalars['String']['input']>;
  paymentApprovalStatusList?: InputMaybe<Array<PaymentApprovalStatus>>;
  paymentDateFrom?: InputMaybe<Scalars['String']['input']>;
  paymentDateTo?: InputMaybe<Scalars['String']['input']>;
  paymentProposalName?: InputMaybe<Scalars['String']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
  paymentTypeList?: InputMaybe<Array<PaymentType>>;
  rechnungsAusstellerFibuKontoId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerIban?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  sepaCreditorId?: InputMaybe<Scalars['String']['input']>;
  sepaMandateReference?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<OrderPaymentStatus>>;
};

export type PaymentProposalCreationGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<OrderPayment>;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type PaymentProposalCreationGenerierlaufListEntryPage = {
  contentList: Array<PaymentProposalCreationGenerierlaufEntry>;
  page: Page;
};

export type PaymentProposalCreationGenerierlaufListEntryPageResponse = {
  data: PaymentProposalCreationGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentProposalCreationGenerierlaufResponse = {
  data: PaymentProposalCreationGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentProposalExecutionGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type PaymentProposalExecutionGenerierlaufCreateInput = {
  paymentProposalId: Scalars['String']['input'];
};

export type PaymentProposalExecutionGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<OrderPayment>;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  warningList: Array<WarningData>;
};

export type PaymentProposalExecutionGenerierlaufListEntryPage = {
  contentList: Array<PaymentProposalExecutionGenerierlaufEntry>;
  page: Page;
};

export type PaymentProposalExecutionGenerierlaufListEntryPageResponse = {
  data: PaymentProposalExecutionGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentProposalExecutionGenerierlaufResponse = {
  data: PaymentProposalExecutionGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum PaymentProposalGenerierlaufOrderBy {
  Amount = 'AMOUNT',
  Buchungsdatum = 'BUCHUNGSDATUM',
  Objekt = 'OBJEKT',
  Payee = 'PAYEE',
  PayeeIban = 'PAYEE_IBAN',
  PaymentDate = 'PAYMENT_DATE',
  PaymentProposal = 'PAYMENT_PROPOSAL',
  PaymentStatus = 'PAYMENT_STATUS',
  RechnungsAussteller = 'RECHNUNGS_AUSSTELLER',
  RechnungsAusstellerFibuBankAccount = 'RECHNUNGS_AUSSTELLER_FIBU_BANK_ACCOUNT',
  RechnungsAusstellerIban = 'RECHNUNGS_AUSSTELLER_IBAN',
  Status = 'STATUS',
  Verwendungszweck = 'VERWENDUNGSZWECK',
  Zahlungsreferenz = 'ZAHLUNGSREFERENZ',
}

export type PaymentProposalListResponse = {
  data: Array<PaymentProposal>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum PaymentProposalStatus {
  Booked = 'BOOKED',
  Created = 'CREATED',
  Executed = 'EXECUTED',
}

export type PaymentRechtstraeger = {
  buchungskreisId?: Maybe<Scalars['String']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  rechtstraegerId: Scalars['ID']['output'];
};

export type PaymentRechtstraegerListResponse = {
  data: Array<PaymentRechtstraeger>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum PaymentTransactionType {
  Ebics = 'EBICS',
  OnlineBanking = 'ONLINE_BANKING',
}

export type PaymentTransactionTypeListResponse = {
  data: Array<PaymentTransactionTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentTransactionTypeTuple = {
  text: Scalars['String']['output'];
  value: PaymentTransactionType;
};

export enum PaymentType {
  Ausgangsrechnung = 'AUSGANGSRECHNUNG',
  Eingangsrechnung = 'EINGANGSRECHNUNG',
  HeAuszahlung = 'HE_AUSZAHLUNG',
}

export type PaymentTypeListResponse = {
  data: Array<PaymentTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PaymentTypeTuple = {
  text: Scalars['String']['output'];
  value: PaymentType;
};

export type PaymentUpdateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  paymentDate: Scalars['String']['input'];
};

export type Period = {
  amount: Scalars['Int']['output'];
  unit: DateUnit;
};

export enum PeriodUnit {
  Days = 'DAYS',
  Months = 'MONTHS',
  Weeks = 'WEEKS',
  Years = 'YEARS',
}

export type PersonenGemeinschaftCreateInput = {
  communityType: PersonsCommunityType;
  hauptAddress?: InputMaybe<AddressCreateInput>;
  kurzBezeichnung: Scalars['String']['input'];
  name1: Scalars['String']['input'];
  name2?: InputMaybe<Scalars['String']['input']>;
  name3?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sprache: Scalars['String']['input'];
  steuerpflicht: SteuerpflichtInput;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  vatIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PersonenGemeinschaftKurzbezeichnungInput = {
  communityType?: InputMaybe<PersonsCommunityType>;
  name1?: InputMaybe<Scalars['String']['input']>;
  name2?: InputMaybe<Scalars['String']['input']>;
  name3?: InputMaybe<Scalars['String']['input']>;
};

export type PersonenGemeinschaftUpdateInput = {
  communityType: PersonsCommunityType;
  kurzBezeichnung: Scalars['String']['input'];
  name1: Scalars['String']['input'];
  name2?: InputMaybe<Scalars['String']['input']>;
  name3?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  sprache: Scalars['String']['input'];
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  vatIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Personenbezug = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  person: RechtstraegerBase;
  personenbezugId: Scalars['ID']['output'];
  tagList: Array<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type PersonenbezugInput = {
  personId: Scalars['String']['input'];
};

export type PersonenbezugResponse = {
  data: Personenbezug;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PersonenbezugTagListInput = {
  tagList: Array<InputMaybe<Scalars['String']['input']>>;
};

export type Personengemeinschaft = {
  bankDetailsList: Array<Maybe<BankDetails>>;
  communityType: PersonsCommunityTypeTuple;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fibuKontoCreatable: Scalars['Boolean']['output'];
  kundennummer: Scalars['ID']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  name1: Scalars['String']['output'];
  name2?: Maybe<Scalars['String']['output']>;
  name3?: Maybe<Scalars['String']['output']>;
  personenbezugList: Array<Personenbezug>;
  postIt?: Maybe<PostIt>;
  rechtstraegerId: Scalars['ID']['output'];
  rechtstraegerSteuerpflicht?: Maybe<RechtstraegerSteuerpflicht>;
  salutation?: Maybe<SalutationTuple>;
  sprache: EnumData;
  status: RechtstraegerStatusTuple;
  tagList: Array<Scalars['String']['output']>;
  type: RechtstraegerTypeTuple;
  vatIdentificationNumber?: Maybe<Scalars['String']['output']>;
  vertragSummary?: Maybe<VertragSummary>;
  warningList: Array<ResponseDataWarning>;
};

export type PersonengemeinschaftResponse = {
  data: Personengemeinschaft;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum PersonsCommunityType {
  EigentuemerPartnerschaft = 'EIGENTUEMER_PARTNERSCHAFT',
  Hausinhabung = 'HAUSINHABUNG',
  WohnungseigentuemerGemeinschaft = 'WOHNUNGSEIGENTUEMER_GEMEINSCHAFT',
}

export type PersonsCommunityTypeListResponse = {
  data: Array<PersonsCommunityTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PersonsCommunityTypeTuple = {
  text: Scalars['String']['output'];
  value: PersonsCommunityType;
};

export type Platzhalter = {
  example: Scalars['String']['output'];
  platzhalter: Scalars['String']['output'];
};

export type PostIt = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  immerAnzeigen: Scalars['Boolean']['output'];
  postItId: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  titel: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type PostItInput = {
  immerAnzeigen: Scalars['Boolean']['input'];
  text: Scalars['String']['input'];
  titel: Scalars['String']['input'];
};

export type PostItResponse = {
  data: PostIt;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type PubSubNotification = {
  actionList: Array<NotificationAction>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  notificationCategory: NotificationCategory;
  notificationId: Scalars['ID']['output'];
  notificationInfo: NotificationInfo;
  notificationType: NotificationType;
  progress: NotificationProgress;
  status: NotificationStatus;
};

export type Query = {
  _empty?: Maybe<Scalars['String']['output']>;
  authenticateForEmailIntegration: Scalars['String']['output'];
  calculateVertragVposIndexedValueAmount: FloatResponse;
  calculateVertragVposIndexedValueFactor: FloatResponse;
  generatePassword: StringResponse;
  getAbrechnungBezeichnung: StringResponse;
  getAbrechnungList: AbrechnungListEntryPageResponse;
  getAbrechnungTypeList: AbrechnungTypeListResponse;
  getAbrechnungsdefinitionAbrechnungskreisList: AbrechnungskreisListResponse;
  getAbrechnungsdefinitionAbrechnungskreisTemplateList: AbrechnungskreisListResponse;
  getAbrechnungsdefinitionBezeichnung: StringResponse;
  getAbrechnungsdefinitionChangeHistoryList: HistoryListResponse;
  getAbrechnungsdefinitionKontoAbrechnungskreisTemplateList: KontoAbrechnungskreisListResponse;
  getAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList: KontoAbrechnungskreisListResponse;
  getAbrechnungsdefinitionListByFirmendatenId: AbrechnungsdefinitionListEntryPageResponse;
  getAbrechnungsdefinitionListByKundenSystemId: AbrechnungsdefinitionListEntryPageResponse;
  getAbrechnungsdefinitionTemplate: AbrechnungsdefinitionTemplateResponse;
  getAbrechnungsdefinitionTemplateBezeichnung: StringResponse;
  getAbrechnungsdefinitionTemplateList: AbrechnungsdefinitionListResponse;
  getAbrechnungsdefinitionTemplateType: AbrechnungsdefinitionTypeResponse;
  getAbrechnungsdefinitionTemplateVersionAbrechnungskreisList: AbrechnungsdefinitionVersionListResponse;
  getAbrechnungsdefinitionType: AbrechnungsdefinitionTypeResponse;
  getAbrechnungsdefinitionVersionAbrechnungskreisList: AbrechnungsdefinitionVersionListResponse;
  getAbrechnungskreis: AbrechnungskreisResponse;
  getAbrechnungskreisChangeHistoryList: HistoryListResponse;
  getAbrechnungskreisListByFirmendatenId: AbrechnungskreisListResponse;
  getAbrechnungskreisListByKundenSystemId: AbrechnungskreisListResponse;
  getAbrechnungskreisTemplate: AbrechnungskreisTemplateResponse;
  getAbrechnungskreisTemplateChangeHistoryList: HistoryListResponse;
  getAbrechnungskreisTemplateList: AbrechnungskreisTemplateListResponse;
  getAbrechnungskreisTypeList: AbrechnungskreisTypeListResponse;
  getActiveEbicsUserIbanList: EbicsUserIbanListResponse;
  getAddressChangeHistoryList: HistoryListResponse;
  getAppFieldHelpList: FieldHelpPageResponse;
  getApplyVertragVposIndexedValueGenerierlauf: ApplyVertragVposIndexedValueGenerierlaufResponse;
  getApplyVertragVposIndexedValueGenerierlaufEntryList: ApplyVertragVposIndexedValueListEntryPageResponse;
  getAuditLogList: UserRevisionListResponse;
  getAufteilungsschluessel?: Maybe<AufteilungsschluesselResponse>;
  getAufteilungsschluesselChangeHistoryList: HistoryListResponse;
  getAufteilungsschluesselListByFirmendatenId: AufteilungsschluesselPageResponse;
  getAufteilungsschluesselListByKundenSystemId: AufteilungsschluesselPageResponse;
  getAufteilungsschluesselTemplate: AufteilungsschluesselResponse;
  getAufteilungsschluesselTemplateChangeHistoryList: HistoryListResponse;
  getAufteilungsschluesselTemplateList: AufteilungsschluesselTemplateListResponse;
  getAuftrag: AuftragResponse;
  getAuftragDeviationList: AuftragDeviationListResponse;
  getAuftragGenerierlauf: AuftragGenerierlaufResponse;
  getAuftragGenerierlaufEntryList: AuftragGenerierlaufListEntryPageResponse;
  getAuftragLinkList: AuftragLinkListEntryListResponse;
  getAuftragList: AuftragListEntryPageResponse;
  getAuftragListSummary: AuftragListSummaryResponse;
  getAuftragVerbuchenGenerierlauf: AuftragVerbuchenGenerierlaufResponse;
  getAuftragVerbuchenGenerierlaufEntryList: AuftragVerbuchenGenerierlaufListEntryPageResponse;
  getAuftragsart: AuftragsartResponse;
  getAuftragsartGeneralChangeHistoryList: HistoryListResponse;
  getAuftragsartList: AuftragsartBaseListResponse;
  getAuftragsartTemplate: AuftragsartTemplateResponse;
  getAuftragsartTemplateGeneralChangeHistoryList: HistoryListResponse;
  getAuftragsartTemplateList: AuftragsartTemplateListResponse;
  getAuftragsartTemplateTexteChangeHistoryList: HistoryListResponse;
  getAuftragsartTemplates: AuftragsartTemplatesListResponse;
  getAuftragsartTexteChangeHistoryList: HistoryListResponse;
  getAuftragsartTypeList: AuftragsartTypeListResponse;
  getAuszifferungList: AuszifferungListResponse;
  getAuszifferungListWithOffenePosten: AuszifferungListWithOffenePostenResponse;
  getAuthMitarbeiterMe: MitarbeiterResponse;
  getBankAccountChangeHistoryList: HistoryListResponse;
  getBankDetailsList: BankDetailsListResponse;
  getBeAufteilungsschluesselList: BeAufteilungsschluesselListResponse;
  getBeAufteilungsschluesselWertAenderungList: BeAufteilingsschluesselWertListResponse;
  getBeAufteilungsschluesselWertChangeHistoryList: HistoryListResponse;
  getBeInfoFeld: BeInfoFeldResponse;
  getBeInfoFeldList: BeInfoFeldListResponse;
  getBeInfoFeldTypeList: BeInfoFeldTypeListResponse;
  getBeNutzungsArtVersion: BeNutzungsArtResponse;
  getBeNutzungsArtVersionList: BeNutzungsArtListResponse;
  getBeOptionSteuerpflichtVersion: BeOptionSteuerpflichtVersionResponse;
  getBeOptionSteuerpflichtVersionList: BeOptionSteuerpflichtVersionListResponse;
  getBeVertrag: BeVertragResponse;
  getBeVertragBaseList: BeVertragBaseListResponse;
  getBeVertragChangeHistoryList: HistoryListResponse;
  getBeVertragDeviationList: BeVertragDeviationListResponse;
  getBeVertragDokumentenversand: DokumentenversandResponse;
  getBeVertragInfoFeld: VertragInfoFeldResponse;
  getBeVertragInfoFeldList: VertragInfoFeldListResponse;
  getBeVertragKurzBezeichnung: StringResponse;
  getBeVertragList: BeVertragListEntryListResponse;
  getBeVertragVersion: BeVertragVersionResponse;
  getBeVertragVersionList: BeVertragVersionListResponse;
  getBeVertragVorschreibungspositionList: BeVertragVorschreibunspositionListResponse;
  getBeVertragVorschreibungspositionUstKategorieEntry: VorschreibungspositionUstKategorieEntryResponse;
  getBeVertragVorschreibungspositionWertChangeHistoryList: HistoryListResponse;
  getBeVertragVorschreibungspositionWertDeletionHistoryList: HistoryListResponse;
  getBeVertragVorschreibungspositionWertList: BeVertragVorschreibunspositionWertListResponse;
  getBeVertragVposIndexationAgreementDeletionHistory: HistoryListResponse;
  getBelegTexteFirmendaten: BelegTexteResponse;
  getBelegTexteFirmendatenTemplates: BelegTexteTemplateReponse;
  getBelegTexteRechtstraeger: BelegTexteResponse;
  getBelegTexteRechtstraegerChangeHistoryList: HistoryListResponse;
  getBelegTexteRechtstraegerTemplates: BelegTexteTemplateReponse;
  getBelegnummernkreisDefinition: BelegnummernkreisDefinitionResponse;
  getBelegnummernkreisDefinitionChangeHistoryList: HistoryListResponse;
  getBelegnummernkreisDefinitionList: BelegnummernkreisDefinitionListResponse;
  getBelegnummernkreisDefinitionTemplates: BelegnummernkreisDefinitionTemplateListResponse;
  getBestandseinheit: BestandseinheitResponse;
  getBestandseinheitBezeichnung: StringResponse;
  getBestandseinheitChangeHistoryList: HistoryListResponse;
  getBestandseinheitList: BestandseinheitListEntryPageResponse;
  getBestandseinheitNutzflaecheByDate: FloatResponse;
  getBestandseinheitNutzflaecheVersion: BestandseinheitNutzflaecheVersionResponse;
  getBestandseinheitNutzflaecheVersionList: BestandseinheitNutzflaecheVersionListResponse;
  getBestandseinheitNutzung: BestandseinheitNutzungResponse;
  getBestandseinheitNutzungsArtList: BestandseinheitNutzungsArtListResponse;
  getBestandseinheitNutzwertByDate: BestandseinheitNutzwertByDateResponse;
  getBestandseinheitNutzwertVersion: BestandseinheitNutzwertVersionResponse;
  getBestandseinheitNutzwertVersionList: BestandseinheitNutzwertVersionListResponse;
  getBezugsbasisList: BezugsbasisListResponse;
  getBillingChangeHistory: HistoryListResponse;
  getBkAbrechnungsdefinition: BkAbrechnungsdefinitionResponse;
  getBkAbrechnungsdefinitionTemplate: BkAbrechnungsdefinitionResponse;
  getBkAbrechnungsdefinitionTemplateVersion: BkAbrechnungsdefinitionVersionResponse;
  getBkAbrechnungsdefinitionTemplateVersionList: BkAbrechnungsdefinitionVersionListEntryResponse;
  getBkAbrechnungsdefinitionVersion: BkAbrechnungsdefinitionVersionResponse;
  getBkAbrechnungsdefinitionVersionList: BkAbrechnungsdefinitionVersionListEntryResponse;
  getBookingSuggestion: BookingSuggestionResponse;
  getBookingSuggestionBuchungszeileChangeHistoryList: HistoryListResponse;
  getBookingSuggestionCreationGenerierlauf: BookingSuggestionCreationGenerierlaufResponse;
  getBookingSuggestionCreationGenerierlaufEntry: BookingSuggestionCreationGenerierlaufEntryListPageResponse;
  getBookingSuggestionList: BookingSuggestionListResponse;
  getBookingSuggestionVerbuchenGenerierlauf: BookingSuggestionVerbuchenGenerierlaufResponse;
  getBookingSuggestionVerbuchenGenerierlaufEntry: BookingSuggestionVerbuchenGenerierlaufEntryListPageResponse;
  getBuchung: BuchungResponse;
  getBuchungList: BuchungListResponse;
  getBuchungsanweisungDeviationList: BuchungsanweisungDeviationListResponse;
  getBuchungskreisList: BuchungskreisListResponse;
  getBuchungszeileListWithUmbuchung: FibuBuchungszeileResponse;
  getBuchungszeilenWithDeviationInfo: BuchungszeilenResponse;
  getBudgeting: BudgetingResponse;
  getBudgetingAbrDefVPosLinkage: BudgetingAbrDefVPosLinkageResponse;
  getBudgetingAbrDefVPosLinkageChangeHistoryList: HistoryListResponse;
  getBudgetingAbrDefVPosLinkageList: BudgetingAbrDefVPosLinkageListResponse;
  getBudgetingAbrDefVPosLinkageTemplate: BudgetingAbrDefVPosLinkageResponse;
  getBudgetingAbrDefVPosLinkageTemplateChangeHistoryList: HistoryListResponse;
  getBudgetingAbrDefVPosLinkageTemplateList: BudgetingAbrDefVPosLinkageListResponse;
  getBudgetingBase: BudgetingBaseResponse;
  getBudgetingGenerierlauf: BudgetingGenerierlaufResponse;
  getBudgetingList: BudgetingListResponse;
  getBudgetingResult: BudgetingResultResponse;
  getBudgetingResultByVertragLevel: BudgetingResultByVertragLevelResponse;
  getBudgetingVPosAuftschluesselLinkage: BudgetingVPosAuftschluesselLinkageResponse;
  getBudgetingVPosAuftschluesselLinkageChangeHistoryList: HistoryListResponse;
  getBudgetingVPosAuftschluesselLinkageList: BudgetingVPosAuftschluesselLinkageListResponse;
  getBudgetingVPosAuftschluesselLinkageTemplate: BudgetingVPosAuftschluesselLinkageResponse;
  getBudgetingVPosAuftschluesselLinkageTemplateChangeHistoryList: HistoryListResponse;
  getBudgetingVPosAuftschluesselLinkageTemplateList: BudgetingVPosAuftschluesselLinkageListResponse;
  getCallLogList: CallLogListPageResponse;
  getCamtGenerierlauf: CamtGenerierlaufResponse;
  getCamtGenerierlaufEntryList: CamtGenerierlaufEntryListPageResponse;
  getColumnsSettingsList: ColumnSettingsListResponse;
  getContactChangeHistoryList: HistoryListResponse;
  getContactPersonChangeHistoryList: HistoryListResponse;
  getCountryCode: CountryCodeResponse;
  getCountryCodeList: CountryCodeListResponse;
  getDataCarrierCamtIbanList: StringListResponse;
  getDataCarrierCamtList: DataCarrierCamtListEntryPageResponse;
  getDataCarrierCamtNameList: StringListResponse;
  getDataCarrierCamtStatementNumberList: StringListResponse;
  getDataCarrierList: DataCarrierListResponse;
  getDataCarrierPainCreationGenerierlauf: DataCarrierPainCreationGenerierlaufResponse;
  getDataCarrierPainCreationGenerierlaufEntryList: DataCarrierPainCreationGenerierlaufListEntryPageResponse;
  getDataCarrierPainIbanList: StringListResponse;
  getDataCarrierPainList: DataCarrierPainListEntryPageResponse;
  getDataCarrierPainNameList: StringListResponse;
  getDataCarrierPainPaymentProposalNameList: StringListResponse;
  getDataCarrierPainStatusList: DataCarrierPainStatusListResponse;
  getDateUnitList: DateUnitListResponse;
  getEbicsBankConfig: EbicsBankConfigResponse;
  getEbicsBankConfigList: EbicsBankConfigListResponse;
  getEbicsUser: EbicsUserResponse;
  getEbicsUserIbanChangeHistory: HistoryListResponse;
  getEbicsUserIbanList: EbicsUserIbanListResponse;
  getEbicsUserList: EbicsUserPageResponse;
  getEbicsUserStatusList: EbicsUserStatusListResponse;
  getEbicsVersionList: EbicsVersionListResponse;
  getEigenbeleg: EigenbelegResponse;
  getEigenbelegErstellenGenerierlauf: EigenbelegErstellenGenerierlaufResponse;
  getEigenbelegErstellenGenerierlaufEntryList: EigenbelegErstellenListEntryPageResponse;
  getEingangsRechnungVerrechnungsartList: VerrechnungsartListResponse;
  getEmailDeliveryDefinition: EmailDeliveryDefinitionResponse;
  getEmailDeliveryDefinitionChangeHistoryList: HistoryListResponse;
  getEmailDeliveryDefinitionList: EmailDeliveryDefinitionBaseListResponse;
  getEmailDeliveryDefinitionTemplate: EmailDeliveryDefinitionTemplateResponse;
  getEmailDeliveryDefinitionTemplateChangeHistoryList: HistoryListResponse;
  getEmailDeliveryDefinitionTemplateList: EmailDeliveryDefinitionTemplateListResponse;
  getEmailDeliveryDefinitionTemplates: EmailDeliveryTemplateListResponse;
  getEmailDeliveryTypeList: EmailDeliveryTypeResponse;
  getEmailIntegration: EmailIntegrationResponse;
  getFibuBelegSymbolList: FibuBelegSymbolListResponse;
  getFibuBuchungTypeList: FibuBuchungsTypeListResponse;
  getFibuBuchungsanweisungList: FibuBuchungsanweisungListEntryPageResponse;
  getFibuBuchungszeileList: FibuBuchungszeilePageResponse;
  getFibuBuchungszeileListSummary: FibuBuchungszeileListSummaryResponse;
  getFibuEinstellung: FibuEinstellungResponse;
  getFibuFieldHelpList: FieldHelpPageResponse;
  getFileInfoForFirmendaten: FileInfoResponse;
  getFileInfoForMitarbeiter: FileInfoResponse;
  getFileInfoListForFirmendaten: FileInfoPageResponse;
  getFileInfoListForMitarbeiter: FileInfoPageResponse;
  getFilterAuftragAusstellerIbanList: StringListResponse;
  getFilterAuftragBelegnummerList: StringListResponse;
  getFilterAuftragEmpfaengerIbanList: StringListResponse;
  getFilterAuftragEmpfaengerKundennummerList: StringListResponse;
  getFilterAuftragVertragsartList: OrderVertragsartListResponse;
  getFilterBuchungBelegnummerList: StringListResponse;
  getFilterBuchungVertragspartnerList: FibuRechtstraegerListResponse;
  getFilterFibuBuchungsanweisungBelegnummerList: StringListResponse;
  getFilterFibuBuchungsanweisungBestandeinheitList: FibuBestandseinheitListResponse;
  getFilterFibuBuchungsanweisungBuchungskreisRechtstraegerList: FibuRechtstraegerListResponse;
  getFilterFibuBuchungsanweisungObjektList: FibuObjektListResponse;
  getFilterFibuBuchungsanweisungVertragspartnerList: FibuRechtstraegerListResponse;
  getFilterFibuBuchungsanweisungZuStornierendeBuchungList: BuchungListResponse;
  getFilterFibuBzBestandseinheitList: FibuBestandseinheitListResponse;
  getFilterFibuBzBuchungKZList: StringListResponse;
  getFilterFibuBzGegenKontoList: FibuKontoListResponse;
  getFilterFibuBzKontoList: FibuKontoListResponse;
  getFilterFibuBzObjektList: FibuObjektListResponse;
  getFilterFibuBzTexteList: StringListResponse;
  getFilterGenLaufAppBestandseinheitList: BestandseinheitBaseListResponse;
  getFilterGenLaufAppEigentuemerList: RechtstraegerBaseListResponse;
  getFilterGenLaufAppGenerierlaufEntryStatusList: GenerierlaufEntryExitCodeListResponse;
  getFilterGenLaufAppHauseigentuemerList: RechtstraegerBaseListResponse;
  getFilterGenLaufAppIndexSeriesList: IndexSeriesListResponse;
  getFilterGenLaufAppObjektList: ObjektBaseListResponse;
  getFilterGenLaufAppRechnungsAusstellerList: RechtstraegerBaseListResponse;
  getFilterGenLaufAppRechnungsEmpfaengerList: RechtstraegerBaseListResponse;
  getFilterGenLaufAppRecipientEmailAddressList: StringListResponse;
  getFilterGenLaufAppRecipientList: RechtstraegerBaseListResponse;
  getFilterGenLaufAppValuePreservationTypeList: ValuePreservationTypeListResponse;
  getFilterGenLaufAppVertragList: VertragBaseListResponse;
  getFilterGenLaufAppVertragspartnerList: RechtstraegerBaseListResponse;
  getFilterGenLaufAppVorschreibungsperiodeList: VorschreibungsperiodeListResponse;
  getFilterGenLaufAppWeRechnungsAusstellerList: RechtstraegerBaseListResponse;
  getFilterGenLaufFibuGenerierlaufEntryStatusList: GenerierlaufEntryExitCodeListResponse;
  getFilterGenLaufFibuObjektList: FibuObjektListResponse;
  getFilterGenLaufFibuRechnungsAusstellerList: FibuRechtstraegerListResponse;
  getFilterGenLaufOrderBelegnummerList: StringListResponse;
  getFilterGenLaufOrderBestandseinheitList: OrderBestandseinheitListResponse;
  getFilterGenLaufOrderFakturierungsperiodeList: FakturierungsperiodeListResponse;
  getFilterGenLaufOrderGenerierlaufEntryStatusList: GenerierlaufEntryExitCodeListResponse;
  getFilterGenLaufOrderObjektList: OrderObjektListResponse;
  getFilterGenLaufOrderPaymentTransactionTypeList: OrderPaymentTransactionTypeListResponse;
  getFilterGenLaufOrderPaymentTypeList: OrderPaymentTypeListResponse;
  getFilterGenLaufOrderRechnungsAusstellerList: OrderRechtstraegerListResponse;
  getFilterGenLaufOrderRechnungsEmpfaengerList: OrderRechtstraegerListResponse;
  getFilterGenLaufOrderRecipientEmailAddressList: StringListResponse;
  getFilterGenLaufOrderRecipientList: OrderRechtstraegerListResponse;
  getFilterGenLaufOrderVertragspartnerList: OrderRechtstraegerListResponse;
  getFilterGenLaufPaymentBuchungskreisRechtstraegerList: PaymentRechtstraegerListResponse;
  getFilterGenLaufPaymentCreditorList: StringListResponse;
  getFilterGenLaufPaymentDataCarrierNameList: StringListResponse;
  getFilterGenLaufPaymentDataCarrierTypeList: DataCarrierTypeListResponse;
  getFilterGenLaufPaymentDebtorList: StringListResponse;
  getFilterGenLaufPaymentEbicsUserNameList: StringListResponse;
  getFilterGenLaufPaymentEbicsUserUserIdList: StringListResponse;
  getFilterGenLaufPaymentGenerierlaufEntryStatusList: GenerierlaufEntryExitCodeListResponse;
  getFilterGenLaufPaymentIbanList: StringListResponse;
  getFilterGenLaufPaymentMitarbeiterList: EbicsUserMitarbeiterListResponse;
  getFilterGenLaufPaymentObjektList: PaymentObjektListResponse;
  getFilterGenLaufPaymentStatementNumberList: StringListResponse;
  getFilterGenLaufPaymentTransactionTypeList: PaymentTransactionTypeListResponse;
  getFilterGenLaufPaymentTypeList: PaymentTypeListResponse;
  getFilterPaymentApprovedByMitarbeiterList: OrderMitarbeiterListResponse;
  getFilterPaymentBelegnummerList: StringListResponse;
  getFilterPaymentClearingBuchungskreisList: FibuBuchungskreisListResponse;
  getFilterPaymentClearingKontoList: FibuKontoListResponse;
  getFilterPaymentClearingKontoVerwendungList: KontoVerwendungListResponse;
  getFilterPaymentClearingVertragspartnerList: FibuRechtstraegerListResponse;
  getFilterPaymentInvoiceIssuerIbanList: StringListResponse;
  getFilterPaymentPayeeIbanList: StringListResponse;
  getFilterPaymentPurposeOfUseTextList: StringListResponse;
  getFilterPaymentReferenceTextList: StringListResponse;
  getFilterPaymentSepaCreditorIdList: StringListResponse;
  getFilterPaymentSepaMandateReferenceList: StringListResponse;
  getFilterPaymentStatusList: OrderPaymentStatusListResponse;
  getFilterPaymentTransactionTypeList: OrderPaymentTransactionTypeListResponse;
  getFilterPaymentTypeList: OrderPaymentTypeListResponse;
  getFilterReceivedBankTransactionBankstatementIbanList: StringListResponse;
  getFilterReceivedBankTransactionCreditorIbanList: StringListResponse;
  getFilterReceivedBankTransactionCreditorList: StringListResponse;
  getFilterReceivedBankTransactionDebtorIbanList: StringListResponse;
  getFilterReceivedBankTransactionDebtorList: StringListResponse;
  getFilterReceivedBankTransactionEndToEndIdList: StringListResponse;
  getFilterReceivedBankTransactionPaymentReferenceTextList: StringListResponse;
  getFilterReceivedBankTransactionPurposeOfUseTextList: StringListResponse;
  getFilterReceivedBankTransactionStatementNumberList: StringListResponse;
  getFilterReceivedBankTransactionStatusList: ReceivedBankTransactionStatusListResponse;
  getFinancialPlanTexts: FinancialPlanTextsResultResponse;
  getFirma: FirmaResponse;
  getFirmaChangeHistoryList: HistoryListResponse;
  getFirmaContactPersonDeletionHistoryList: HistoryListResponse;
  getFirmaOperatingSite: OperatingSiteResponse;
  getFirmaOperatingSiteChangeHistoryList: HistoryListResponse;
  getFirmaOperatingSiteDeletionHistoryList: HistoryListResponse;
  getFirmaOperatingSiteList: OperatingSiteListResponse;
  getFirmendaten: FirmendatenResponse;
  getFirmendatenAddressesAndContacts: AddressesAndContactsResponse;
  getFirmendatenBankDetails: FirmendatenBankDetailsResponse;
  getFirmendatenBankDetailsChangeHistoryList: HistoryListResponse;
  getFirmendatenBankDetailsList: FirmendatenBankDetailsListResponse;
  getFirmendatenBankSettings: BankSettingsResponse;
  getFirmendatenBankSettingsChangeHistoryList: HistoryListResponse;
  getFirmendatenBuchhaltungEinstellung: FirmendatenBuchhaltungEinstellungResponse;
  getFirmendatenContactPersonDeletionHistoryList: HistoryListResponse;
  getFirmendatenEbicsUserIbanList: StringListResponse;
  getFirmendatenEmailContactDeletionHistoryList: HistoryListResponse;
  getFirmendatenFileInfoBestandseinheitList: FileStorageBestandseinheitListResponse;
  getFirmendatenFileInfoHomeownerList: FileStorageRechtstraegerListResponse;
  getFirmendatenFileInfoInvoiceIssuerList: FileStorageRechtstraegerListResponse;
  getFirmendatenFileInfoInvoiceNumberList: StringListResponse;
  getFirmendatenFileInfoInvoiceRecipientList: FileStorageRechtstraegerListResponse;
  getFirmendatenFileInfoObjektList: FileStorageObjektListResponse;
  getFirmendatenIndexSeries: IndexSeriesResponse;
  getFirmendatenIndexSeriesList: IndexSeriesListResponse;
  getFirmendatenInitializerGenerierlauf: FirmendatenInitializerGenerierlaufResponse;
  getFirmendatenList: FirmendatenListEntryListResponse;
  getFirmendatenObjektAbrechnungList: ObjektAbrechnungListEntryListResponse;
  getFirmendatenObjektVorschreibungspositionNameList: StringListResponse;
  getFirmendatenOperatingSite: OperatingSiteResponse;
  getFirmendatenOperatingSiteChangeHistoryList: HistoryListResponse;
  getFirmendatenOperatingSiteDeletionHistoryList: HistoryListResponse;
  getFirmendatenOperatingSiteList: OperatingSiteListResponse;
  getFirmendatenPhoneContactDeletionHistoryList: HistoryListResponse;
  getFirmendatenPostofficeBoxAddressDeletionHistoryList: HistoryListResponse;
  getFirmendatenStreetAddressDeletionHistoryList: HistoryListResponse;
  getFirmeneinstellung: FirmeneinstellungResponse;
  getGeneralTextbausteinTypeList: GeneralTextbausteinTypeListResponse;
  getGenerierlaufEntryExitCodeList: GenerierlaufEntryExitCodeListResponse;
  getGenerierlaufList: GenerierlaufPageResponse;
  getGenerierlaufStatusList: GenerierlaufStatusResponse;
  getHeAbrechnung: HeAbrechnungResponse;
  getHeAbrechnungDeviationList: HeAbrechnungDeviationListResponse;
  getHeAbrechnungGenerierlauf: HeAbrechnungGenerierlaufResponse;
  getHeAbrechnungGenerierlaufEntryList: HeAbrechnungGenerierlaufListEntryPageResponse;
  getHeAbrechnungHeVertrag: HeAbrechnungHeVertragResponse;
  getHeAbrechnungHeVertragList: HeAbrechnungHeVertragListEntryListResponse;
  getHeAbrechnungList: HeAbrechnungListEntryListResponse;
  getHeAbrechnungsdefinition: HeAbrechnungsdefinitionResponse;
  getHeAbrechnungsdefinitionTemplate: HeAbrechnungsdefinitionResponse;
  getHeAbrechnungsdefinitionTemplateVersion: HeAbrechnungsdefinitionVersionResponse;
  getHeAbrechnungsdefinitionTemplateVersionList: HeAbrechnungsdefinitionVersionListEntryResponse;
  getHeAbrechnungsdefinitionVersion: HeAbrechnungsdefinitionVersionResponse;
  getHeAbrechnungsdefinitionVersionList: HeAbrechnungsdefinitionVersionListEntryResponse;
  getHeVertrag: HeVertragResponse;
  getHeVertragBaseList: HeVertragBaseListResponse;
  getHeVertragChangeHistoryList: HistoryListResponse;
  getHeVertragKurzbezeichnung: StringResponse;
  getHeVertragList: HeVertragListResponse;
  getHeVertragOverviewList: HeVertragOverviewListEntryPageResponse;
  getHeVertragZahlungsregel: HeVertragZahlungsregelResponse;
  getHeVertragZahlungsregelChangeHistoryList: HistoryListResponse;
  getHeVertragZahlungsregelList: HeVertragZahlungsregelListResponse;
  getHeVertragZahlungsregelPeriodeList: HeVertragZahlungsregelPeriodeListResponse;
  getIbanFibuKontoAccountHolderList: StringListResponse;
  getIbanFibuKontoBanknameList: StringListResponse;
  getIbanFibuKontoBicList: StringListResponse;
  getIbanFibuKontoFibuAccountNumberList: StringListResponse;
  getIbanFibuKontoIbanList: StringListResponse;
  getIbanFibuKontoList: IbanListEntryPageResponse;
  getIncomingInvoice: IncomingInvoiceResponse;
  getIncomingInvoiceAusstellerBankDetailsList: OrderBankDetailsListResponse;
  getIncomingInvoiceAusstellerList: OrderRechtstraegerListResponse;
  getIncomingInvoiceBookingCreationGenerierlauf: IncomingInvoiceBookingCreationGenerierlaufResponse;
  getIncomingInvoiceBookingCreationGenerierlaufEntryList: IncomingInvoiceBookingCreationGenerierlaufListEntryPageResponse;
  getIncomingInvoiceInvoiceNumberList: StringListResponse;
  getIncomingInvoiceKontoList: OrderKontoListResponse;
  getIncomingInvoiceKundennummerList: StringListResponse;
  getIncomingInvoiceList: IncomingInvoiceListEntryPageResponse;
  getIncomingInvoiceListSummary: IncomingInvoiceListSummaryResponse;
  getIncomingInvoicePayeeBankDetailsList: OrderBankDetailsListResponse;
  getIncomingInvoicePayeeList: OrderRechtstraegerListResponse;
  getIncomingInvoicePaymentReferenceTextList: StringListResponse;
  getIncomingInvoicePurposeOfUseTextList: StringListResponse;
  getIncomingInvoiceSepaMandateReferenceList: StringListResponse;
  getIndexSeries: IndexSeriesResponse;
  getIndexSeriesEntry: IndexSeriesEntryResponse;
  getIndexSeriesEntryList: IndexSeriesEntryListResponse;
  getIndexSeriesFinalValue: FloatResponse;
  getIndexSeriesList: IndexSeriesListResponse;
  getIndexValueCreationCronJobSettings: IndexValueCreationCronJobSettingsResponse;
  getIndexValueCreationCronJobSettingsChangeHistoryList: HistoryListResponse;
  getInfoMailDelivery: InfoMailDeliveryResponse;
  getInfoMailDeliveryAttachmentList: InfoMailDeliveryWizardAttachmentListResponse;
  getInfoMailDeliveryDetermineRecipientListGenerierlauf: InfoMailDeliveryDetermineRecipientListGenerierlaufResponse;
  getInfoMailDeliveryDetermineRecipientListGenerierlaufEntryList: InfoMailDeliveryDetermineRecipientListGenerierlaufListEntryPageResponse;
  getInfoMailDeliverySendGenerierlauf: InfoMailDeliverySendGenerierlaufResponse;
  getInfoMailDeliverySendGenerierlaufEntryList: InfoMailDeliverySendGenerierlaufListEntryPageResponse;
  getInfoMailDeliveryWizardDetermineRecipientListStep: InfoMailDeliveryWizardDetermineRecipientListStepResponse;
  getInfoMailDeliveryWizardRecipientList: InfoMailDeliveryPageResponse;
  getInfoMailDeliveryWizardRecipientListStep: InfoMailDeliveryWizardRecipientListStepResponse;
  getInfoMailDeliveryWizardTemplateStep: InfoMailDeliveryWizardTemplateStepResponse;
  getKSFirmendatenList?: Maybe<FirmendatenListEntryListResponse>;
  getKontenplan: KontenplanResponse;
  getKontenrahmen: KontenrahmenResponse;
  getKontierungstabelleChangeHistoryList: HistoryListResponse;
  getKontierungstabelleListByFirmendatenId: KontierungstabelleListResponse;
  getKontierungstabelleListByKundenSystemId: KontierungstabelleListResponse;
  getKontierungstabelleTemplate: KontierungstabelleTemplateResponse;
  getKontierungstabelleTemplateChangeHistoryList: HistoryListResponse;
  getKontierungstabelleTemplateList: KontierungstabelleTemplateListResponse;
  getKonto: KontoResponse;
  getKontoChangeHistoryList: HistoryListResponse;
  getKontoKlasseList: KontoKlasseListResponse;
  getKontoList: KontoPageResponse;
  getKontoTemplate: KontoResponse;
  getKontoTemplateChangeHistoryList: HistoryListResponse;
  getKontoTemplateList: KontoListResponse;
  getKontoTypeList: KontoTypeListResponse;
  getKontoVerwendungList: KontoVerwendungListResponse;
  getKuendigungDurchList: KuendigungDurchListResponse;
  getKuendigungsfristJeweilsZumList: KuendigungsfristJeweilsZumListResponse;
  getKuendigungsfristList: KuendigungsfristListResponse;
  getKundenSystem: KundenSystemResponse;
  getKundenSystemChangeLogs: ChangeLogsResponse;
  getKundenSystemList: KundenSystemListResponse;
  getKundensystemEinstellung: KundenSystemEinstellungenResponse;
  getLast5CallLogList: CallLogListResponse;
  getLeerstehungAufwandskonto: FirmendatenLeerstehungAufwandskontoResponse;
  getLeerstehungAufwandskontoList: FirmendatenLeerstehungAufwandskontoListResponse;
  getLeerstehungForderungsKontoChangeHistoryList: HistoryListResponse;
  getLegalFormList: LegalFormListResponse;
  getMahnPositionChangeHistoryList: HistoryListResponse;
  getMahnPositionDeletionHistoryList: HistoryListResponse;
  getMahndefinition: MahndefinitionResponse;
  getMahndefinitionChangeHistoryList: HistoryListResponse;
  getMahndefinitionList: MahndefinitionListResponse;
  getMahndefinitionTemplate: MahndefinitionTemplateResponse;
  getMahndefinitionTemplateChangeHistoryList: HistoryListResponse;
  getMahndefinitionTemplateList: MahndefinitionListResponse;
  getMahndefinitionTemplateSingle: MahndefinitionResponse;
  getMahnsperreChangeHistoryList: HistoryListResponse;
  getMahnstufeChangeHistoryList: HistoryListResponse;
  getMahnstufeTemplateChangeHistoryList: HistoryListResponse;
  getMahnung: MahnungResponse;
  getMahnungAusgebenGenerierlauf: MahnungAusgebenGenerierlaufResponse;
  getMahnungAusgebenGenerierlaufEntryList: MahnungAusgebenGenerierlaufListEntryPageResponse;
  getMahnungChangeHistoryList: HistoryListResponse;
  getMahnungErzeugenGenerierlauf: MahnungErzeugenGenerierlaufResponse;
  getMahnungErzeugenGenerierlaufEntryList: MahnungErzeugenGenerierlaufListEntryPageResponse;
  getMahnungList: MahnungEntryListPageResponse;
  getMahnungListSummary: MahnungListSummaryResponse;
  getMahnungObjektList: MahnungObjektListResponse;
  getMahnungStatusList: MahnungStatusListResponse;
  getMahnungTemplateChangeHistoryList: HistoryListResponse;
  getMahnungTemplateTemplateChangeHistoryList: HistoryListResponse;
  getMahnungVerbuchenGenerierlauf: MahnungVerbuchenGenerierlaufResponse;
  getMahnungVerbuchenGenerierlaufEntryList: MahnungVerbuchenGenerierlaufListEntryPageResponse;
  getMahnungVersendenGenerierlauf: MahnungVersendenGenerierlaufResponse;
  getMahnungVersendenGenerierlaufEntryList: MahnungVersendenGenerierlaufListEntryPageResponse;
  getMail: MailPageResponse;
  getMailList: MailListPageResponse;
  getMailStatusList: MailStatusListResponse;
  getMailUseCaseList: MailUseCaseListResponse;
  getMasseinheitList: MasseinheitListResponse;
  getMe: UserResponse;
  getMitarbeiterDetails: MitarbeiterResponse;
  getMitarbeiterDetailsByFirmendatenId: MitarbeiterResponse;
  getMitarbeiterList: MitarbeiterListResponse;
  getMitarbeiterListByFirmendatenId: MitarbeiterListResponse;
  getMitarbeiterTicketCountList: MitarbeiterTicketCountListResponse;
  getMonthList: MonthListResponse;
  getMyTicketList: TicketListResponse;
  getNatuerlichePerson: NatuerlichePersonResponse;
  getNatuerlichePersonAddress: AddressResponse;
  getNatuerlichePersonChangeHistoryList: HistoryListResponse;
  getNextSepaMandatReference: StringResponse;
  getNotificationList: NotificationPageResponse;
  getObjekt: ObjektResponse;
  getObjektAbrechnung: ObjektAbrechnungResponse;
  getObjektAbrechnungDeviationList: ObjektAbrechnungDeviationListResponse;
  getObjektAbrechnungGenerierlauf: ObjektAbrechnungGenerierlaufResponse;
  getObjektAbrechnungGenerierlaufEntryList: ObjektAbrechnungGenerierlaufListEntryPageResponse;
  getObjektAufteilungsschluesselChangeHistoryList: HistoryListResponse;
  getObjektAufteilungsschluesselList: ObjektAufteilungsschluesselListResponse;
  getObjektBankDetailsChangeHistoryList: HistoryListResponse;
  getObjektBankDetailsList: ObjektRechnungsAusstellerObjektBankDetailsResponse;
  getObjektBankDetailsVerwendungList: ObjektBankDetailsVerwendungListResponse;
  getObjektChangeHistoryList: HistoryListResponse;
  getObjektFinancialPlanGenerierlauf: ObjektFinancialPlanGenerierlaufResponse;
  getObjektInfoFeld: ObjektInfoFeldResponse;
  getObjektInfoFeldList: ObjektInfoFeldListResponse;
  getObjektInfoFeldTypeList: ObjektInfoFeldTypeListResponse;
  getObjektKurzBezeichnung: StringResponse;
  getObjektList: ObjektListEntryPageResponse;
  getObjektSummeAnteile: ObjektSummeAnteileResponse;
  getObjektSummeAnteileList: ObjektSummeAnteileListResponse;
  getObjektVorschreibungspositionChangeHistoryList: HistoryListResponse;
  getObjektVorschreibungspositionList: ObjektVorschreibungspositionListResponse;
  getOffenePosten: OffenePostenResponse;
  getOffenePostenBelegnummerList: StringListResponse;
  getOffenePostenKontoTypeList: OffenePostenKontoTypeListResponse;
  getOffenePostenList: OffenePostenPageResponse;
  getOffenePostenListSummary: OffenePostenSummaryResponse;
  getOrderFieldHelpList: FieldHelpPageResponse;
  getOwnMitarbeiterList: OwnMitarbeiterListResponse;
  getPain001SchemaList: Pain001SchemaListResponse;
  getPain008SchemaList: Pain008SchemaListResponse;
  getPauschalmieteAufwandskonto: FirmendatenPauschalmieteAufwandskontoResponse;
  getPauschalmieteAufwandskontoList: FirmendatenPauschalmieteAufwandskontoListResponse;
  getPauschalmieteForderungsKontoChangeHistoryList: HistoryListResponse;
  getPauschalmieteKontenplan: FirmendatenPauschalmieteKontenplanResponse;
  getPaymentApprovalStatusList: PaymentApprovalStatusListResponse;
  getPaymentBelegSymbolList: PaymentBelegSymbolListResponse;
  getPaymentBuchungTypeList: PaymentBuchungTypeListResponse;
  getPaymentChangeHistoryList: HistoryListResponse;
  getPaymentClearingBookingList: PaymentClearingBookingListResponse;
  getPaymentClearingList: PaymentClearingPageResponse;
  getPaymentClearingListSummary: PaymentClearingListSummaryResponse;
  getPaymentFieldHelpList: FieldHelpPageResponse;
  getPaymentList: PaymentListEntryPageResponse;
  getPaymentListSummary: PaymentListSummaryResponse;
  getPaymentMethodList: PaymentMethodListResponse;
  getPaymentProposalCreationGenerierlauf: PaymentProposalCreationGenerierlaufResponse;
  getPaymentProposalCreationGenerierlaufEntryList: PaymentProposalCreationGenerierlaufListEntryPageResponse;
  getPaymentProposalExecutionGenerierlauf: PaymentProposalExecutionGenerierlaufResponse;
  getPaymentProposalExecutionGenerierlaufEntryList: PaymentProposalExecutionGenerierlaufListEntryPageResponse;
  getPaymentProposalList: PaymentProposalListResponse;
  getPaymentProposalName: StringResponse;
  getPersonenGemeinschaft: PersonengemeinschaftResponse;
  getPersonenGemeinschaftAddress: AddressResponse;
  getPersonenGemeinschaftChangeHistoryList: HistoryListResponse;
  getPersonenbezugChangeHistoryList: HistoryListResponse;
  getPersonsCommunityTypeList: PersonsCommunityTypeListResponse;
  getReceivedBankTransactionList: ReceivedBankTransactionEntryListPageResponse;
  getReceivedBankTransactionListSummary: ReceivedBankTransactionSummaryResponse;
  getReceivedBankTransactionVerbuchenGenerierlauf: ReceivedBankTransactionVerbuchenGenerierlaufResponse;
  getReceivedBankTransactionVerbuchenGenerierlaufEntry: ReceivedBankTransactionVerbuchenGenerierlaufListEntryPageResponse;
  getRechnungsAussteller: RechnungsAusstellerResponse;
  getRechnungsAusstellerBankSettingsChangeHistoryList: HistoryListResponse;
  getRechnungsAusstellerBestandseinheitList: BestandseinheitBaseListResponse;
  getRechnungsAusstellerList: RechtstraegerListResponse;
  getRechnungsAusstellerMahndefinitionChangeHistoryList: HistoryListResponse;
  getRechtstraeger: RechtstraegerResponse;
  getRechtstraegerAddressesAndContacts: AddressesAndContactsResponse;
  getRechtstraegerAndEmailList: RechtstraegerAndEmailListResponse;
  getRechtstraegerBankDetails: BankDetailsResponse;
  getRechtstraegerBankDetailsChangeHistoryList: HistoryListResponse;
  getRechtstraegerBankDetailsList: BankDetailsListResponse;
  getRechtstraegerContactList: RechtstraegerContactListEntryPageResponse;
  getRechtstraegerEmailContactDeletionHistoryList: HistoryListResponse;
  getRechtstraegerInfoFeld: RechtstraegerInfoFeldResponse;
  getRechtstraegerInfoFeldList: RechtstraegerInfoFeldListResponse;
  getRechtstraegerKundennummerList: StringListResponse;
  getRechtstraegerList: RechtstraegerListEntryPageResponse;
  getRechtstraegerPhoneContactDeletionHistoryList: HistoryListResponse;
  getRechtstraegerPostofficeBoxAddressDeletionHistoryList: HistoryListResponse;
  getRechtstraegerRolleList: RechtstraegerRolleListResponse;
  getRechtstraegerSteuerpflicht: RechtstraegerSteuerpflichtResponse;
  getRechtstraegerSteuerpflichtList: RechtstraegerSteuerpflichtListResponse;
  getRechtstraegerStreetAddressDeletionHistoryList: HistoryListResponse;
  getRechtstraegerTagList: StringListResponse;
  getRechtstraegerType: RechtstraegerTypeResponse;
  getRepFondsEinstellungList: RepFondsEinstellungListResponse;
  getRepFondsEinstellungTemplateList: RepFondsEinstellungListResponse;
  getRepFondsErloesKontenZuweisung: RepFondsErloesKontenZuweisungResponse;
  getRepFondsErloesKontenZuweisungChangeHistoryList: HistoryListResponse;
  getRepFondsErloesKontenZuweisungDeletionHistoryList: HistoryListResponse;
  getRepFondsErloesKontenZuweisungList: RepFondsErloesKontenZuweisungListResponse;
  getReportLogos: ReportLogosResponse;
  getSaldenliste: SaldenlistePageResponse;
  getSaldenlisteKontoTypeList: SaldenlisteKontoTypeListResponse;
  getSaldenlisteSummary: SaldenlisteSummaryResponse;
  getSaldo: SaldoResponse;
  getSepaMandatChangeHistoryList: HistoryListResponse;
  getSimilarTicketList: TicketBaseResponse;
  getSingleInvoice: SingleInvoiceResponse;
  getSingleInvoiceChangeHistory: HistoryListResponse;
  getSingleInvoiceForDetailsAndUpdatePage: SingleInvoiceResponse;
  getSpracheList: SpracheListResponse;
  getStandingInvoice: StandingInvoiceResponse;
  getStandingInvoiceChangeHistory: HistoryListResponse;
  getStandingInvoiceForDetailsAndUpdatePage: StandingInvoiceResponse;
  getSteuerpflichtList: SteuerpflichtListResponse;
  getSteuertabelle: SteuertabelleResponse;
  getSubAbrechnung: SubAbrechnungResponse;
  getSubAbrechnungEigentuemer: SubAbrechnungEigentuemerResponse;
  getSubAbrechnungGenerierlauf: SubAbrechnungGenerierlaufResponse;
  getSubAbrechnungGenerierlaufEntryList: SubAbrechnungGenerierlaufListEntryPageResponse;
  getSubAdministration: SubAdministrationResponse;
  getSubAdministrationAbrechnungsdefinition: SubAdministrationAbrechnungsdefinitionResponse;
  getSubAdministrationAbrechnungsdefinitionTemplate: SubAdministrationAbrechnungsdefinitionResponse;
  getSubAdministrationAbrechnungsdefinitionTemplateVersion: SubAdministrationAbrechnungsdefinitionVersionResponse;
  getSubAdministrationAbrechnungsdefinitionTemplateVersionList: SubAdministrationAbrechnungsdefinitionVersionListEntryResponse;
  getSubAdministrationAbrechnungsdefinitionVersion: SubAdministrationAbrechnungsdefinitionVersionResponse;
  getSubAdministrationAbrechnungsdefinitionVersionList: SubAdministrationAbrechnungsdefinitionVersionListEntryResponse;
  getSubAdministrationChangeHistory: HistoryListResponse;
  getSubAdministrationList: SubAdministrationListEntryResponse;
  getSubAdministrationSettingsChangeHistory: HistoryListResponse;
  getSumVerzugszinsen: MahnungSumVerzugszinsenResponse;
  getSummeAnteile: FloatResponse;
  getSyncEbicsUserGenerierlauf: SyncEbicsUserGenerierlaufResponse;
  getTextbaustein: GeneralTextbausteinResponse;
  getTextbausteinChangeHistoryList: HistoryListResponse;
  getTextbausteinList: GeneralTextbausteinListResponse;
  getTextbausteinTemplate: GeneralTextbausteinResponse;
  getTextbausteinTemplateChangeHistoryList: HistoryListResponse;
  getTextbausteinTemplateList: GeneralTextbausteinListResponse;
  getTextbausteinTemplates: TextbausteinResponse;
  getTicket: TicketResponse;
  getTicketChangeEntryList: TicketChangeEntryListResponse;
  getTicketCommentList: TicketCommentListResponse;
  getTicketList: TicketListPageResponse;
  getTicketPriorityList: TicketPriorityListResponse;
  getTicketStatusList: TicketStatusListResponse;
  getTicketTemplate: TicketTemplateResponse;
  getTicketTemplateChangeHistoryList: HistoryListResponse;
  getTicketTemplateList: TicketTemplateListResponse;
  getTicketTemplateTemplate: TicketTemplateTemplateResponse;
  getTicketTemplateTemplateChangeHistoryList: HistoryListResponse;
  getTicketTemplateTemplateList: TicketTemplateTemplateListResponse;
  getTicketTypeNameList: TicketTypeNameListResponse;
  getTopAbrechnung: TopAbrechnungResponse;
  getTopAbrechnungDeviationList: TopAbrechnungDeviationListResponse;
  getTopAbrechnungGenerierlauf: TopAbrechnungGenerierlaufResponse;
  getTopAbrechnungGenerierlaufEntryList: TopAbrechnungGenerierlaufListEntryPageResponse;
  getTopBudgetSumNextYear: TopBudgetSumNextYearResultResponse;
  getTopFinancialPlanGenerierlauf: TopFinancialPlanGenerierlaufResponse;
  getTransactionInstructionList: TransactionInstructionListResponse;
  getTransactionTypeList: TransactionTypeListResponse;
  getUmsatzsteuerkennzeichenList: UmsatzsteuerkennzeichenListResponse;
  getUmsatzsteuerkennzeichenTemplateList: UmsatzsteuerkennzeichenTemplateListResponse;
  getUser: UserResponse;
  getUserChangeLog: PageDataChangeLogResponse;
  getUserName: UserNameData;
  getUsers: UserListResponse;
  getUstKategorie: UstKategorieResponse;
  getUstKategorieChangeHistoryList: HistoryListResponse;
  getUstKategorieEntryInitialList: UstKategorieEntryListResponse;
  getUstKategorieEntryTemplateInitialList: UstKategorieEntryListResponse;
  getUstKategorieListByFirmendatenId: UstKategorieListResponse;
  getUstKategorieListByKundenSystemId: UstKategorieListResponse;
  getUstKategorieTemplate: UstKategorieResponse;
  getUstKategorieTemplateChangeHistoryList: HistoryListResponse;
  getUstKategorieTemplateList: UstKategorieListResponse;
  getUstKategorieTemplateVersion: UstKategorieVersionResponse;
  getUstKategorieTemplateVersionList: UstKategorieVersionListResponse;
  getUstKategorieVersion: UstKategorieVersionResponse;
  getUstKategorieVersionList: UstKategorieVersionListResponse;
  getUstRegelset: UstRegelsetResponse;
  getUstRegelsetListByKundenSystemId: UstRegelsetListResponse;
  getUstRegelsetTemplate: UstRegelsetResponse;
  getUstRegelsetTemplateChangeHistoryList: HistoryListResponse;
  getUstRegelsetTemplateList: UstRegelsetListResponse;
  getUstRegelsetTemplateUstKategorieZuweisung: UstRegelsetUstKategorieZuweisungResponse;
  getUstRegelsetTemplateUstKategorieZuweisungList: UstRegelsetUstKategorieZuweisungListResponse;
  getUstRegelsetUstKategorieZuweisung: UstRegelsetUstKategorieZuweisungResponse;
  getUstRegelsetUstKategorieZuweisungList: UstRegelsetUstKategorieZuweisungListResponse;
  getUstVomAufwand: UstVomAufwandResponse;
  getUstVomAufwandBuchungStatusList: UstVomAufwandBuchungStatusListResponse;
  getUstVomAufwandDeviationList: UstVomAufwandDeviationListResponse;
  getUstVomAufwandGenerierlauf: UstVomAufwandGenerierlaufResponse;
  getUstVomAufwandList: UstVomAufwandListEntryListResponse;
  getValuePreservationTypeList: ValuePreservationTypeListResponse;
  getVerrechnungUstVomAufwandList: VerrechnungUstVomAufwandListResponse;
  getVertragInfoFeldTypeList: VertragInfoFeldTypeListResponse;
  getVertragLevelList: VertragLevelListResponse;
  getVertragPaymentMethodList: VertragPaymentMethodListResponse;
  getVertragVerrechnungsartList: VertragVerrechnungsartListResponse;
  getVertragVposIndexationAgreement?: Maybe<VertragVposIndexationAgreementResponse>;
  getVertragVposIndexationAgreementChangeHistoryList: HistoryListResponse;
  getVertragVposIndexationAgreementList: VertragVposIndexationAgreementListEntryResponse;
  getVertragVposIndexationAgreementVorschreibungspositionList: VertragVposIndexationAgreementVorschreibungspositionListResponse;
  getVertragVposIndexedValueChangeHistoryList: HistoryListResponse;
  getVertragVposIndexedValueGenerierlauf: VertragVposIndexedValueGenerierlaufResponse;
  getVertragVposIndexedValueGenerierlaufEntryList: VertragVposIndexedValueListEntryPageResponse;
  getVertragVposIndexedValueList: VertragVposIndexedValueListResponse;
  getVertragVposIndexedValueListFirmendaten: VertragVposIndexedValueListPageResponse;
  getVertragVposIndexedValueListSummary: VertragVposIndexedValueListSummaryResponse;
  getVertragVposIndexedValueStatusList: VertragVposIndexedValueStatusListResponse;
  getVertragsartList: VertragsartListResponse;
  getVertragspartner: RechtstraegerVertragspartnerResponse;
  getVertragspartnerKontoList: VertragspartnerKontoListResponse;
  getVerwaltungsartList: VerwaltungsartResponse;
  getVorschreibungMailDelivery: VorschreibungMailDeliveryResponse;
  getVorschreibungMailDeliveryAttachmentList: VorschreibungMailDeliveryWizardAttachmentListResponse;
  getVorschreibungMailDeliveryDetermineRecipientListGenerierlauf: VorschreibungMailDeliveryDetermineRecipientListGenerierlaufResponse;
  getVorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryList: VorschreibungMailDeliveryDetermineRecipientGenerierlaufListEntryPageResponse;
  getVorschreibungMailDeliverySendGenerierlauf: VorschreibungMailDeliverySendGenerierlaufResponse;
  getVorschreibungMailDeliverySendGenerierlaufEntryList: VorschreibungMailDeliverySendGenerierlaufListEntryPageResponse;
  getVorschreibungMailDeliveryWizardDetermineRecipientListStep: EmailDeliveryWizardDetermineRecipientListStepResponse;
  getVorschreibungMailDeliveryWizardRecipientList: VorschreibungMailDeliveryPageResponse;
  getVorschreibungMailDeliveryWizardRecipientListStep: EmailDeliveryWizardRecipientListStepResponse;
  getVorschreibungMailDeliveryWizardTemplateStep: VorschreibungMailDeliveryWizardTemplateStepResponse;
  getVorschreibungsposition: VorschreibungspositionResponse;
  getVorschreibungspositionArtList: VorschreibungspositionArtListResponse;
  getVorschreibungspositionChangeHistoryList: HistoryListResponse;
  getVorschreibungspositionListByFirmendatenId: VorschreibungspositionPageResponse;
  getVorschreibungspositionListByKundenSystemId: VorschreibungspositionPageResponse;
  getVorschreibungspositionTemplate: VorschreibungspositionResponse;
  getVorschreibungspositionTemplateChangeHistoryList: HistoryListResponse;
  getVorschreibungspositionTemplateList: VorschreibungspositionListResponse;
  getVorschreibungspositionTemplateVersion: VorschreibungspositionVersionResponse;
  getVorschreibungspositionTemplateVersionList: VorschreibungspositionVersionListResponse;
  getVorschreibungspositionVersion: VorschreibungspositionVersionResponse;
  getVorschreibungspositionVersionList: VorschreibungspositionVersionListResponse;
  getVorsteuerkuerzung: VorsteuerkuerzungResponse;
  getVorsteuerkuerzungBuchungStatusList: VorsteuerkuerzungBuchungStatusListResponse;
  getVorsteuerkuerzungDeviationList: VorsteuerkuerzungDeviationListResponse;
  getVorsteuerkuerzungEinstellungList: VorsteuerkuerzungEinstellungListResponse;
  getVorsteuerkuerzungEinstellungTemplateList: VorsteuerkuerzungEinstellungListResponse;
  getVorsteuerkuerzungGenerierlauf: VorsteuerkuerzungGenerierlaufResponse;
  getVorsteuerkuerzungList: VorsteuerkuerzungListEntryListResponse;
  getWeAbrechnungsdefinition: WeAbrechnungsdefinitionResponse;
  getWeAbrechnungsdefinitionTemplate: WeAbrechnungsdefinitionResponse;
  getWeAbrechnungsdefinitionTemplateVersion: WeAbrechnungsdefinitionVersionResponse;
  getWeAbrechnungsdefinitionTemplateVersionList: WeAbrechnungsdefinitionVersionListEntryResponse;
  getWeAbrechnungsdefinitionVersion: WeAbrechnungsdefinitionVersionResponse;
  getWeAbrechnungsdefinitionVersionList: WeAbrechnungsdefinitionVersionListEntryResponse;
  getWeAndMietVertragList: VertragListEntryPageResponse;
  getWeRechnungsAusstellerList: WeRechnungsAusstellerListResponse;
  getWizardList: WizardPageResponse;
  getWizardTypes: WizardTypesResponse;
  getZahlungsperiodeList: ZahlungsperiodeListResponse;
  getZahlungsverlauf: HeVertragZahlungsverlaufListEntryResponse;
  getZinsliste: ZinslisteResponse;
  getZinslisteDeviationList: ZinslisteDeviationListResponse;
  getZinslisteGenerierlauf: ZinslisteGenerierlaufResponse;
  getZinslisteGenerierlaufEntryList: ZinslisteGenerierlaufEntryListPageResponse;
  getZinslisteListByObjektId: ZinslisteListEntryPageResponse;
  getZinslisteListForFirmendaten: ZinslisteListEntryPageResponse;
  getZinslisteVerarbeitungsstand: ZinslisteVerarbeitungsstandResponse;
  verifyEmailChangeToken: UserResponse;
  verifyPasswordResetToken: ResponseEntity;
};

export type QueryCalculateVertragVposIndexedValueAmountArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  factorPercent: Scalars['Float']['input'];
  indexationAgreementId: Scalars['ID']['input'];
  indexedValueId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryCalculateVertragVposIndexedValueFactorArgs = {
  amount: Scalars['Float']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  indexationAgreementId: Scalars['ID']['input'];
  indexedValueId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetAbrechnungBezeichnungArgs = {
  abrechnungType: AbrechnungType;
  objektId: Scalars['ID']['input'];
};

export type QueryGetAbrechnungListArgs = {
  abrechnungszeitraumFromInclusive?: InputMaybe<Scalars['String']['input']>;
  abrechnungszeitraumToInclusive?: InputMaybe<Scalars['String']['input']>;
  bezeichnung?: InputMaybe<Scalars['String']['input']>;
  erstellDatumFromInclusive?: InputMaybe<Scalars['String']['input']>;
  erstellDatumToInclusive?: InputMaybe<Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  order?: InputMaybe<AbrechnungOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  statusList?: InputMaybe<Array<AbrechnungStatus>>;
  typeList?: InputMaybe<Array<AbrechnungType>>;
};

export type QueryGetAbrechnungsdefinitionAbrechnungskreisListArgs = {
  abrechnungsdefinitionId: Scalars['String']['input'];
};

export type QueryGetAbrechnungsdefinitionAbrechnungskreisTemplateListArgs = {
  abrechnungsdefinitionId: Scalars['String']['input'];
};

export type QueryGetAbrechnungsdefinitionBezeichnungArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetAbrechnungsdefinitionChangeHistoryListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetAbrechnungsdefinitionKontoAbrechnungskreisTemplateListArgs = {
  abrechnungsdefinitionVersionId?: InputMaybe<Scalars['String']['input']>;
  kontoId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListArgs = {
  abrechnungsdefinitionVersionId?: InputMaybe<Scalars['String']['input']>;
  kontoId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetAbrechnungsdefinitionListByFirmendatenIdArgs = {
  firmendatenId: Scalars['String']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAbrechnungsdefinitionListByKundenSystemIdArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  kundenSystemId: Scalars['String']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAbrechnungsdefinitionTemplateArgs = {
  type: AbrechnungsdefinitionTemplateType;
};

export type QueryGetAbrechnungsdefinitionTemplateBezeichnungArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetAbrechnungsdefinitionTemplateTypeArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetAbrechnungsdefinitionTemplateVersionAbrechnungskreisListArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
};

export type QueryGetAbrechnungsdefinitionTypeArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetAbrechnungsdefinitionVersionAbrechnungskreisListArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
};

export type QueryGetAbrechnungskreisArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAbrechnungskreisChangeHistoryListArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
};

export type QueryGetAbrechnungskreisListByFirmendatenIdArgs = {
  firmendatenId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<AbrechnungskreisType>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAbrechnungskreisListByKundenSystemIdArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  kundenSystemId: Scalars['ID']['input'];
  type?: InputMaybe<AbrechnungskreisType>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAbrechnungskreisTemplateArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAbrechnungskreisTemplateChangeHistoryListArgs = {
  abrechnungskreisId: Scalars['ID']['input'];
};

export type QueryGetAbrechnungskreisTemplateListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetActiveEbicsUserIbanListArgs = {
  ibanList: Array<Scalars['String']['input']>;
};

export type QueryGetAddressChangeHistoryListArgs = {
  addressId: Scalars['ID']['input'];
};

export type QueryGetAppFieldHelpListArgs = {
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  fieldHelp?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<FieldHelpOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  selector?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetApplyVertragVposIndexedValueGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetApplyVertragVposIndexedValueGenerierlaufEntryListArgs = {
  bestandseinheitIdList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  indexSeriesId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<VertragVposIndexedValueGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  valuePreservationType?: InputMaybe<ValuePreservationType>;
  vertragId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetAuditLogListArgs = {
  username: Scalars['String']['input'];
};

export type QueryGetAufteilungsschluesselArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
};

export type QueryGetAufteilungsschluesselChangeHistoryListArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
};

export type QueryGetAufteilungsschluesselListByFirmendatenIdArgs = {
  firmendatenId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAufteilungsschluesselListByKundenSystemIdArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  kundenSystemId: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAufteilungsschluesselTemplateArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
};

export type QueryGetAufteilungsschluesselTemplateChangeHistoryListArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
};

export type QueryGetAufteilungsschluesselTemplateListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAuftragArgs = {
  auftragId: Scalars['ID']['input'];
};

export type QueryGetAuftragDeviationListArgs = {
  auftragId: Scalars['ID']['input'];
};

export type QueryGetAuftragGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetAuftragGenerierlaufEntryListArgs = {
  ausstellerId?: InputMaybe<Scalars['String']['input']>;
  empfaengerId?: InputMaybe<Scalars['String']['input']>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<AuftragGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  vorschreibungsperiode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetAuftragLinkListArgs = {
  auftragId: Scalars['ID']['input'];
  includeCurrent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAuftragListArgs = {
  auftragsPositionLabel?: InputMaybe<Scalars['String']['input']>;
  auftragsPositionLabelValue?: InputMaybe<Scalars['String']['input']>;
  auftragsartType?: InputMaybe<AuftragsartType>;
  ausstellerBankDetailsId?: InputMaybe<Scalars['ID']['input']>;
  ausstellerIban?: InputMaybe<Scalars['String']['input']>;
  ausstellerRechtstraegerId?: InputMaybe<Scalars['ID']['input']>;
  belegform?: InputMaybe<Scalars['String']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  bestandseinheitId?: InputMaybe<Scalars['ID']['input']>;
  bruttoFrom?: InputMaybe<Scalars['Float']['input']>;
  bruttoTo?: InputMaybe<Scalars['Float']['input']>;
  buchungsanweisungStatus?: InputMaybe<OrderBuchungsanweisungStatus>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  dueDateVorschreibung?: InputMaybe<Scalars['String']['input']>;
  empfaengerBankDetailsId?: InputMaybe<Scalars['String']['input']>;
  empfaengerBankDetailsIdIsNull?: InputMaybe<Scalars['Boolean']['input']>;
  empfaengerIban?: InputMaybe<Scalars['String']['input']>;
  empfaengerRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  fakturierungsperiodeDateFrom?: InputMaybe<Scalars['String']['input']>;
  fakturierungsperiodeDateTo?: InputMaybe<Scalars['String']['input']>;
  fibuStatus?: InputMaybe<AuftragFibuStatus>;
  generierlaufId?: InputMaybe<Scalars['ID']['input']>;
  includeStorniert?: InputMaybe<Scalars['Boolean']['input']>;
  includeStorno?: InputMaybe<Scalars['Boolean']['input']>;
  kundennummer?: InputMaybe<Scalars['String']['input']>;
  nettoFrom?: InputMaybe<Scalars['Float']['input']>;
  nettoTo?: InputMaybe<Scalars['Float']['input']>;
  objektId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<AuftragOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  rechnungsDatumFrom?: InputMaybe<Scalars['String']['input']>;
  rechnungsDatumTo?: InputMaybe<Scalars['String']['input']>;
  sepaCreditorId?: InputMaybe<Scalars['String']['input']>;
  sepaMandateReference?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AuftragStatus>;
  ustFrom?: InputMaybe<Scalars['Float']['input']>;
  ustTo?: InputMaybe<Scalars['Float']['input']>;
  verrechnungsart?: InputMaybe<Scalars['String']['input']>;
  vertragId?: InputMaybe<Scalars['ID']['input']>;
  vertragsart?: InputMaybe<Vertragsart>;
  vorschreibungspositionId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetAuftragListSummaryArgs = {
  auftragsPositionLabel?: InputMaybe<Scalars['String']['input']>;
  auftragsPositionLabelValue?: InputMaybe<Scalars['String']['input']>;
  auftragsartType?: InputMaybe<AuftragsartType>;
  ausstellerBankDetailsId?: InputMaybe<Scalars['ID']['input']>;
  ausstellerIban?: InputMaybe<Scalars['String']['input']>;
  ausstellerRechtstraegerId?: InputMaybe<Scalars['ID']['input']>;
  belegform?: InputMaybe<Scalars['String']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  bestandseinheitId?: InputMaybe<Scalars['ID']['input']>;
  bruttoFrom?: InputMaybe<Scalars['Float']['input']>;
  bruttoTo?: InputMaybe<Scalars['Float']['input']>;
  buchungsanweisungStatus?: InputMaybe<OrderBuchungsanweisungStatus>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  dueDateVorschreibung?: InputMaybe<Scalars['String']['input']>;
  empfaengerBankDetailsId?: InputMaybe<Scalars['String']['input']>;
  empfaengerBankDetailsIdIsNull?: InputMaybe<Scalars['Boolean']['input']>;
  empfaengerIban?: InputMaybe<Scalars['String']['input']>;
  empfaengerRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  fakturierungsperiodeDateFrom?: InputMaybe<Scalars['String']['input']>;
  fakturierungsperiodeDateTo?: InputMaybe<Scalars['String']['input']>;
  fibuStatus?: InputMaybe<AuftragFibuStatus>;
  generierlaufId?: InputMaybe<Scalars['ID']['input']>;
  includeStorniert?: InputMaybe<Scalars['Boolean']['input']>;
  includeStorno?: InputMaybe<Scalars['Boolean']['input']>;
  kundennummer?: InputMaybe<Scalars['String']['input']>;
  nettoFrom?: InputMaybe<Scalars['Float']['input']>;
  nettoTo?: InputMaybe<Scalars['Float']['input']>;
  objektId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  rechnungsDatumFrom?: InputMaybe<Scalars['String']['input']>;
  rechnungsDatumTo?: InputMaybe<Scalars['String']['input']>;
  sepaCreditorId?: InputMaybe<Scalars['String']['input']>;
  sepaMandateReference?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AuftragStatus>;
  ustFrom?: InputMaybe<Scalars['Float']['input']>;
  ustTo?: InputMaybe<Scalars['Float']['input']>;
  verrechnungsart?: InputMaybe<Scalars['String']['input']>;
  vertragId?: InputMaybe<Scalars['ID']['input']>;
  vertragsart?: InputMaybe<Vertragsart>;
  vorschreibungspositionId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetAuftragVerbuchenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetAuftragVerbuchenGenerierlaufEntryListArgs = {
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  fakturierungsperiode?: InputMaybe<Scalars['String']['input']>;
  generierlaufId: Scalars['ID']['input'];
  order?: InputMaybe<AuftragVerbuchenGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsausstellerId?: InputMaybe<Scalars['String']['input']>;
  rechnungsempfaengerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetAuftragsartArgs = {
  auftragsartId: Scalars['ID']['input'];
};

export type QueryGetAuftragsartGeneralChangeHistoryListArgs = {
  auftragsartId: Scalars['ID']['input'];
};

export type QueryGetAuftragsartListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  stornoForType?: InputMaybe<AuftragsartType>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAuftragsartTemplateArgs = {
  auftragsartId: Scalars['ID']['input'];
};

export type QueryGetAuftragsartTemplateGeneralChangeHistoryListArgs = {
  auftragsartId: Scalars['ID']['input'];
};

export type QueryGetAuftragsartTemplateListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  stornoForType?: InputMaybe<AuftragsartType>;
};

export type QueryGetAuftragsartTemplateTexteChangeHistoryListArgs = {
  auftragsartId: Scalars['ID']['input'];
};

export type QueryGetAuftragsartTemplatesArgs = {
  auftragsartType: AuftragsartType;
};

export type QueryGetAuftragsartTexteChangeHistoryListArgs = {
  auftragsartId: Scalars['ID']['input'];
};

export type QueryGetAuszifferungListArgs = {
  zahlungId: Scalars['String']['input'];
};

export type QueryGetAuszifferungListWithOffenePostenArgs = {
  zahlungId: Scalars['String']['input'];
};

export type QueryGetAuthMitarbeiterMeArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetBankAccountChangeHistoryListArgs = {
  bankDetailsId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetBankDetailsListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetBeAufteilungsschluesselListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
};

export type QueryGetBeAufteilungsschluesselWertAenderungListArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBeAufteilungsschluesselWertChangeHistoryListArgs = {
  aufteilungsschluesselId: Scalars['ID']['input'];
  beAufteilungsschluesselWertId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBeInfoFeldArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBeInfoFeldListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
};

export type QueryGetBeNutzungsArtVersionArgs = {
  beNutzungsArtId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBeNutzungsArtVersionListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBeOptionSteuerpflichtVersionArgs = {
  beOptionSteuerpflichtVersionId: Scalars['ID']['input'];
  bestandseinheitId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
};

export type QueryGetBeOptionSteuerpflichtVersionListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['String']['input'];
};

export type QueryGetBeVertragArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetBeVertragBaseListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  showBestandseinheit?: InputMaybe<Scalars['Boolean']['input']>;
  showObjekt?: InputMaybe<Scalars['Boolean']['input']>;
  showVertragsdauer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetBeVertragChangeHistoryListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type QueryGetBeVertragDeviationListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  showSubsequentDeviations?: InputMaybe<Scalars['Boolean']['input']>;
  vertragId: Scalars['ID']['input'];
};

export type QueryGetBeVertragDokumentenversandArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type QueryGetBeVertragInfoFeldArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type QueryGetBeVertragInfoFeldListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type QueryGetBeVertragKurzBezeichnungArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  showBestandseinheit?: InputMaybe<Scalars['Boolean']['input']>;
  showObjekt?: InputMaybe<Scalars['Boolean']['input']>;
  showVertragsdauer?: InputMaybe<Scalars['Boolean']['input']>;
  vertragId: Scalars['ID']['input'];
};

export type QueryGetBeVertragListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBeVertragVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vertragVersionId: Scalars['ID']['input'];
};

export type QueryGetBeVertragVersionListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetBeVertragVorschreibungspositionListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type QueryGetBeVertragVorschreibungspositionUstKategorieEntryArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  date: Scalars['String']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetBeVertragVorschreibungspositionWertChangeHistoryListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
  vorschreibungspositionWertId: Scalars['ID']['input'];
};

export type QueryGetBeVertragVorschreibungspositionWertDeletionHistoryListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetBeVertragVorschreibungspositionWertListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetBeVertragVposIndexationAgreementDeletionHistoryArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetBelegTexteFirmendatenArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetBelegTexteRechtstraegerArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetBelegTexteRechtstraegerChangeHistoryListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetBelegnummernkreisDefinitionArgs = {
  belegnummernkreisDefinitionId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type QueryGetBelegnummernkreisDefinitionChangeHistoryListArgs = {
  belegnummernkreisDefinitionId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetBelegnummernkreisDefinitionListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetBelegnummernkreisDefinitionTemplatesArgs = {
  rechtstraegerId: Scalars['String']['input'];
};

export type QueryGetBestandseinheitArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetBestandseinheitBezeichnungArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  showObjekt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetBestandseinheitChangeHistoryListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBestandseinheitListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetBestandseinheitNutzflaecheByDateArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  date: Scalars['String']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBestandseinheitNutzflaecheVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  nutzflaecheVersionId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBestandseinheitNutzflaecheVersionListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBestandseinheitNutzungArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  date?: InputMaybe<Scalars['String']['input']>;
  objektId: Scalars['ID']['input'];
};

export type QueryGetBestandseinheitNutzwertByDateArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  date: Scalars['String']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBestandseinheitNutzwertVersionArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  nutzwertVersionId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBestandseinheitNutzwertVersionListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBkAbrechnungsdefinitionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetBkAbrechnungsdefinitionTemplateArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetBkAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  searchInHistory?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetBkAbrechnungsdefinitionTemplateVersionListArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetBkAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  searchInHistory?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetBkAbrechnungsdefinitionVersionListArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetBookingSuggestionArgs = {
  bookingSuggestionId: Scalars['String']['input'];
};

export type QueryGetBookingSuggestionBuchungszeileChangeHistoryListArgs = {
  bookingSuggestionId: Scalars['String']['input'];
  buchungszeileId: Scalars['String']['input'];
};

export type QueryGetBookingSuggestionCreationGenerierlaufArgs = {
  generierlaufId: Scalars['String']['input'];
};

export type QueryGetBookingSuggestionCreationGenerierlaufEntryArgs = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  creditor?: InputMaybe<Scalars['String']['input']>;
  dataCarrierName?: InputMaybe<Scalars['String']['input']>;
  debtor?: InputMaybe<Scalars['String']['input']>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['String']['input'];
  order?: InputMaybe<BookingSuggestionCreationGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  statementNumber?: InputMaybe<Scalars['String']['input']>;
  valueDateFrom?: InputMaybe<Scalars['String']['input']>;
  valueDateTo?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetBookingSuggestionListArgs = {
  receivedBankTransactionId: Scalars['String']['input'];
};

export type QueryGetBookingSuggestionVerbuchenGenerierlaufArgs = {
  generierlaufId: Scalars['String']['input'];
};

export type QueryGetBookingSuggestionVerbuchenGenerierlaufEntryArgs = {
  buchungskreisRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['String']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<BookingSuggestionVerbuchenGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
  paymentType?: InputMaybe<PaymentType>;
};

export type QueryGetBuchungArgs = {
  buchungId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetBuchungListArgs = {
  bezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  stornierteBuchung?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetBuchungsanweisungDeviationListArgs = {
  buchungsanweisungId: Scalars['String']['input'];
};

export type QueryGetBuchungskreisListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetBuchungszeileListWithUmbuchungArgs = {
  buchungIdList: Array<Scalars['String']['input']>;
  includeStorno?: InputMaybe<Scalars['Boolean']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetBuchungszeilenWithDeviationInfoArgs = {
  belegId: Scalars['String']['input'];
  deviationsExists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetBudgetingArgs = {
  budgetingId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
};

export type QueryGetBudgetingAbrDefVPosLinkageArgs = {
  budgetingAbrDefVPosLinkageId: Scalars['ID']['input'];
};

export type QueryGetBudgetingAbrDefVPosLinkageChangeHistoryListArgs = {
  budgetingAbrDefVPosLinkageId: Scalars['ID']['input'];
};

export type QueryGetBudgetingAbrDefVPosLinkageListArgs = {
  firmendatenId?: InputMaybe<Scalars['String']['input']>;
  kundenSystemId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetBudgetingAbrDefVPosLinkageTemplateArgs = {
  budgetingAbrDefVPosLinkageId: Scalars['ID']['input'];
};

export type QueryGetBudgetingAbrDefVPosLinkageTemplateChangeHistoryListArgs = {
  budgetingAbrDefVPosLinkageId: Scalars['ID']['input'];
};

export type QueryGetBudgetingBaseArgs = {
  budgetingId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBudgetingGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetBudgetingListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
};

export type QueryGetBudgetingResultArgs = {
  budgetingResultId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBudgetingResultByVertragLevelArgs = {
  budgetingResultId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetBudgetingVPosAuftschluesselLinkageArgs = {
  budgetingVPosAuftschluesselLinkageId: Scalars['ID']['input'];
};

export type QueryGetBudgetingVPosAuftschluesselLinkageChangeHistoryListArgs = {
  budgetingVPosAuftschluesselLinkageId: Scalars['ID']['input'];
};

export type QueryGetBudgetingVPosAuftschluesselLinkageListArgs = {
  firmendatenId?: InputMaybe<Scalars['String']['input']>;
  kundenSystemId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetBudgetingVPosAuftschluesselLinkageTemplateArgs = {
  budgetingVPosAuftschluesselLinkageId: Scalars['ID']['input'];
};

export type QueryGetBudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListArgs = {
  budgetingVPosAuftschluesselLinkageId: Scalars['ID']['input'];
};

export type QueryGetCallLogListArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetCamtGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetCamtGenerierlaufEntryListArgs = {
  ebicsUserName?: InputMaybe<Scalars['String']['input']>;
  ebicsUserUserId?: InputMaybe<Scalars['String']['input']>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  iban?: InputMaybe<Scalars['String']['input']>;
  mitarbeiterId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<CamtGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetColumnsSettingsListArgs = {
  filterIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetContactChangeHistoryListArgs = {
  contactId: Scalars['ID']['input'];
};

export type QueryGetContactPersonChangeHistoryListArgs = {
  contactPersonId: Scalars['ID']['input'];
};

export type QueryGetCountryCodeArgs = {
  alpha2: Scalars['String']['input'];
};

export type QueryGetDataCarrierCamtListArgs = {
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  createdByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  iban?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<DataCarrierCamtOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
  statementNumber?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetDataCarrierPainCreationGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetDataCarrierPainCreationGenerierlaufEntryListArgs = {
  dataCarrierType?: InputMaybe<DataCarrierType>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  iban?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<DataCarrierPainCreationGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
};

export type QueryGetDataCarrierPainListArgs = {
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  createdByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  dataCarrierType?: InputMaybe<DataCarrierType>;
  iban?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<DataCarrierPainOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paymentProposalName?: InputMaybe<Scalars['String']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
  status?: InputMaybe<DataCarrierPainStatus>;
};

export type QueryGetEbicsBankConfigArgs = {
  ebicsBankConfigId: Scalars['ID']['input'];
};

export type QueryGetEbicsUserArgs = {
  ebicsUserId: Scalars['ID']['input'];
};

export type QueryGetEbicsUserIbanChangeHistoryArgs = {
  ebicsUserIbanId: Scalars['ID']['input'];
  ebicsUserId: Scalars['ID']['input'];
};

export type QueryGetEbicsUserIbanListArgs = {
  ebicsUserId: Scalars['ID']['input'];
};

export type QueryGetEbicsUserListArgs = {
  ebicsBankConfigId?: InputMaybe<Scalars['ID']['input']>;
  ibanList?: InputMaybe<Array<Scalars['String']['input']>>;
  mitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  order?: InputMaybe<EbicsUserOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<EbicsUserStatus>;
};

export type QueryGetEigenbelegArgs = {
  auftragId: Scalars['String']['input'];
};

export type QueryGetEigenbelegErstellenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetEigenbelegErstellenGenerierlaufEntryListArgs = {
  ausstellerId?: InputMaybe<Scalars['String']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  empfaengerId?: InputMaybe<Scalars['String']['input']>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  fakturierungsperiode?: InputMaybe<Scalars['String']['input']>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<EigenbelegErstellenGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetEmailDeliveryDefinitionArgs = {
  emailDeliveryDefinitionId: Scalars['ID']['input'];
};

export type QueryGetEmailDeliveryDefinitionChangeHistoryListArgs = {
  emailDeliveryDefinitionId: Scalars['ID']['input'];
};

export type QueryGetEmailDeliveryDefinitionListArgs = {
  type?: InputMaybe<EmailDeliveryDefinitionType>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetEmailDeliveryDefinitionTemplateArgs = {
  emailDeliveryDefinitionId: Scalars['ID']['input'];
};

export type QueryGetEmailDeliveryDefinitionTemplateChangeHistoryListArgs = {
  emailDeliveryDefinitionId: Scalars['ID']['input'];
};

export type QueryGetEmailDeliveryDefinitionTemplatesArgs = {
  type: EmailDeliveryDefinitionType;
};

export type QueryGetFibuBuchungsanweisungListArgs = {
  belegDatumFrom?: InputMaybe<Scalars['String']['input']>;
  belegDatumTo?: InputMaybe<Scalars['String']['input']>;
  belegSymbolList?: InputMaybe<Array<FibuBelegSymbol>>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  bestandseinheitId?: InputMaybe<Scalars['ID']['input']>;
  buchungsTypeList?: InputMaybe<Array<FibuBuchungType>>;
  buchungsdatumFrom?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumTo?: InputMaybe<Scalars['String']['input']>;
  createByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  fachlichePruefung?: InputMaybe<Scalars['Boolean']['input']>;
  heVertragId?: InputMaybe<Scalars['ID']['input']>;
  objektId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<FibuBuchungsanweisungOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<FibuBuchungsanweisungStatus>;
  sumBruttoFrom?: InputMaybe<Scalars['Float']['input']>;
  sumBruttoTo?: InputMaybe<Scalars['Float']['input']>;
  sumSteuerFrom?: InputMaybe<Scalars['Float']['input']>;
  sumSteuerTo?: InputMaybe<Scalars['Float']['input']>;
  vertragId?: InputMaybe<Scalars['ID']['input']>;
  vertragspartnerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  zuStornierendeBuchungId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetFibuBuchungszeileListArgs = {
  belegId?: InputMaybe<Scalars['String']['input']>;
  belegSymbol?: InputMaybe<FibuBelegSymbol>;
  belegSymbolList?: InputMaybe<Array<FibuBelegSymbol>>;
  belegdatumFrom?: InputMaybe<Scalars['String']['input']>;
  belegdatumTo?: InputMaybe<Scalars['String']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  bestandseinheitId?: InputMaybe<Scalars['String']['input']>;
  bestandseinheitIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  betragFrom?: InputMaybe<Scalars['Float']['input']>;
  betragTo?: InputMaybe<Scalars['Float']['input']>;
  buchungId?: InputMaybe<Scalars['String']['input']>;
  buchungsKZ?: InputMaybe<Scalars['String']['input']>;
  buchungsTypeList?: InputMaybe<Array<FibuBuchungType>>;
  buchungsdatumFrom?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumTo?: InputMaybe<Scalars['String']['input']>;
  buchungskreisId?: InputMaybe<Scalars['String']['input']>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  fachlichePruefung?: InputMaybe<Scalars['Boolean']['input']>;
  gegenKontoId?: InputMaybe<Scalars['String']['input']>;
  heVertragId?: InputMaybe<Scalars['String']['input']>;
  includeStorno?: InputMaybe<Scalars['Boolean']['input']>;
  includeUmbuchung?: InputMaybe<Scalars['Boolean']['input']>;
  kontoId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  offenerOPBetrag?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<BuchungszeileOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  sollHaben?: InputMaybe<SollHaben>;
  source?: InputMaybe<BuchungSource>;
  steuerFrom?: InputMaybe<Scalars['Float']['input']>;
  steuerTo?: InputMaybe<Scalars['Float']['input']>;
  steuersatzFrom?: InputMaybe<Scalars['Int']['input']>;
  steuersatzTo?: InputMaybe<Scalars['Int']['input']>;
  stornierteBuchungId?: InputMaybe<Scalars['String']['input']>;
  storno?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  umbuchung?: InputMaybe<Scalars['Boolean']['input']>;
  ustVomAufwandSteuersatzFrom?: InputMaybe<Scalars['Int']['input']>;
  ustVomAufwandSteuersatzTo?: InputMaybe<Scalars['Int']['input']>;
  vertragId?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
  waehrung?: InputMaybe<Waehrung>;
};

export type QueryGetFibuBuchungszeileListSummaryArgs = {
  belegId?: InputMaybe<Scalars['String']['input']>;
  belegSymbol?: InputMaybe<FibuBelegSymbol>;
  belegSymbolList?: InputMaybe<Array<FibuBelegSymbol>>;
  belegdatumFrom?: InputMaybe<Scalars['String']['input']>;
  belegdatumTo?: InputMaybe<Scalars['String']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  bestandseinheitId?: InputMaybe<Scalars['String']['input']>;
  bestandseinheitIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  betragFrom?: InputMaybe<Scalars['Float']['input']>;
  betragTo?: InputMaybe<Scalars['Float']['input']>;
  buchungId?: InputMaybe<Scalars['String']['input']>;
  buchungsKZ?: InputMaybe<Scalars['String']['input']>;
  buchungsTypeList?: InputMaybe<Array<FibuBuchungType>>;
  buchungsdatumFrom?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumTo?: InputMaybe<Scalars['String']['input']>;
  buchungskreisId?: InputMaybe<Scalars['String']['input']>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  fachlichePruefung?: InputMaybe<Scalars['Boolean']['input']>;
  gegenKontoId?: InputMaybe<Scalars['String']['input']>;
  heVertragId?: InputMaybe<Scalars['String']['input']>;
  includeStorno?: InputMaybe<Scalars['Boolean']['input']>;
  includeUmbuchung?: InputMaybe<Scalars['Boolean']['input']>;
  kontoId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  offenerOPBetrag?: InputMaybe<Scalars['Boolean']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  sollHaben?: InputMaybe<SollHaben>;
  source?: InputMaybe<BuchungSource>;
  steuerFrom?: InputMaybe<Scalars['Float']['input']>;
  steuerTo?: InputMaybe<Scalars['Float']['input']>;
  steuersatzFrom?: InputMaybe<Scalars['Int']['input']>;
  steuersatzTo?: InputMaybe<Scalars['Int']['input']>;
  stornierteBuchungId?: InputMaybe<Scalars['String']['input']>;
  storno?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  umbuchung?: InputMaybe<Scalars['Boolean']['input']>;
  ustVomAufwandSteuersatzFrom?: InputMaybe<Scalars['Int']['input']>;
  ustVomAufwandSteuersatzTo?: InputMaybe<Scalars['Int']['input']>;
  vertragId?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
  waehrung?: InputMaybe<Waehrung>;
};

export type QueryGetFibuFieldHelpListArgs = {
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  fieldHelp?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<FieldHelpOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  selector?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFileInfoForFirmendatenArgs = {
  fileId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
};

export type QueryGetFileInfoForMitarbeiterArgs = {
  fileId: Scalars['ID']['input'];
  mitarbeiterId: Scalars['ID']['input'];
};

export type QueryGetFileInfoListForFirmendatenArgs = {
  auftragId?: InputMaybe<Scalars['ID']['input']>;
  belegId?: InputMaybe<Scalars['ID']['input']>;
  bestandseinheitId?: InputMaybe<Scalars['ID']['input']>;
  budgetingId?: InputMaybe<Scalars['ID']['input']>;
  budgetingYear?: InputMaybe<Scalars['Float']['input']>;
  dunningReferenceDateFromInclusive?: InputMaybe<Scalars['String']['input']>;
  dunningReferenceDateToInclusive?: InputMaybe<Scalars['String']['input']>;
  heAbrechnungHeVertragId?: InputMaybe<Scalars['ID']['input']>;
  heAbrechnungId?: InputMaybe<Scalars['ID']['input']>;
  heVertragId?: InputMaybe<Scalars['ID']['input']>;
  homeownerId?: InputMaybe<Scalars['String']['input']>;
  invoiceDateFromInclusive?: InputMaybe<Scalars['String']['input']>;
  invoiceDateToInclusive?: InputMaybe<Scalars['String']['input']>;
  invoiceIssuerId?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceRecipientId?: InputMaybe<Scalars['String']['input']>;
  mahnungId?: InputMaybe<Scalars['ID']['input']>;
  mailId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  objektAbrechnungId?: InputMaybe<Scalars['ID']['input']>;
  objektId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['ID']['input']>;
  subAbrechnungEigentuemerId?: InputMaybe<Scalars['ID']['input']>;
  subAbrechnungId?: InputMaybe<Scalars['ID']['input']>;
  topAbrechnungId?: InputMaybe<Scalars['ID']['input']>;
  typeList?: InputMaybe<Array<FileInfoType>>;
  vertragId?: InputMaybe<Scalars['ID']['input']>;
  vorschreibungspostionId?: InputMaybe<Scalars['ID']['input']>;
  zinslisteId?: InputMaybe<Scalars['ID']['input']>;
  zinslisteVertragId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetFileInfoListForMitarbeiterArgs = {
  auftragId?: InputMaybe<Scalars['ID']['input']>;
  belegId?: InputMaybe<Scalars['ID']['input']>;
  bestandseinheitId?: InputMaybe<Scalars['ID']['input']>;
  budgetingYear?: InputMaybe<Scalars['Float']['input']>;
  dunningReferenceDateFromInclusive?: InputMaybe<Scalars['String']['input']>;
  dunningReferenceDateToInclusive?: InputMaybe<Scalars['String']['input']>;
  heAbrechnungHeVertragId?: InputMaybe<Scalars['ID']['input']>;
  heAbrechnungId?: InputMaybe<Scalars['ID']['input']>;
  homeownerId?: InputMaybe<Scalars['String']['input']>;
  invoiceDateFromInclusive?: InputMaybe<Scalars['String']['input']>;
  invoiceDateToInclusive?: InputMaybe<Scalars['String']['input']>;
  invoiceIssuerId?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceRecipientId?: InputMaybe<Scalars['String']['input']>;
  mahnungId?: InputMaybe<Scalars['ID']['input']>;
  mitarbeiterId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  objektAbrechnungId?: InputMaybe<Scalars['ID']['input']>;
  objektId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  topAbrechnungId?: InputMaybe<Scalars['ID']['input']>;
  typeList?: InputMaybe<Array<FileInfoType>>;
  vertragId?: InputMaybe<Scalars['ID']['input']>;
  vorschreibungspostionId?: InputMaybe<Scalars['ID']['input']>;
  zinslisteId?: InputMaybe<Scalars['ID']['input']>;
  zinslisteVertragId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetFilterAuftragBelegnummerListArgs = {
  belegnummer?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterBuchungBelegnummerListArgs = {
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterBuchungVertragspartnerListArgs = {
  kurzBezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterFibuBuchungsanweisungBelegnummerListArgs = {
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterFibuBuchungsanweisungBestandeinheitListArgs = {
  bezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterFibuBuchungsanweisungBuchungskreisRechtstraegerListArgs = {
  kurzBezeichnung?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterFibuBuchungsanweisungObjektListArgs = {
  kurzBezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterFibuBuchungsanweisungVertragspartnerListArgs = {
  kurzBezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterFibuBuchungsanweisungZuStornierendeBuchungListArgs = {
  bezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterFibuBzBestandseinheitListArgs = {
  bezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterFibuBzBuchungKzListArgs = {
  buchungsKZ?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterFibuBzGegenKontoListArgs = {
  nummerOrBezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterFibuBzKontoListArgs = {
  nummerOrBezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterFibuBzObjektListArgs = {
  kurzBezeichnung?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFilterFibuBzTexteListArgs = {
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  rechtstraegerIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterGenLaufAppBestandseinheitListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
  objektId: Scalars['ID']['input'];
};

export type QueryGetFilterGenLaufAppEigentuemerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppGenerierlaufEntryStatusListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppHauseigentuemerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppIndexSeriesListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppObjektListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
  rechnungsAusstellerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetFilterGenLaufAppRechnungsAusstellerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppRechnungsEmpfaengerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppRecipientEmailAddressListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppRecipientListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppValuePreservationTypeListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppVertragListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppVertragspartnerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppVorschreibungsperiodeListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufAppWeRechnungsAusstellerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: AppGenerierlaufType;
};

export type QueryGetFilterGenLaufFibuGenerierlaufEntryStatusListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: FibuGenerierlaufType;
};

export type QueryGetFilterGenLaufFibuObjektListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: FibuGenerierlaufType;
  rechnungsAusstellerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetFilterGenLaufFibuRechnungsAusstellerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: FibuGenerierlaufType;
};

export type QueryGetFilterGenLaufOrderBelegnummerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
};

export type QueryGetFilterGenLaufOrderBestandseinheitListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
  objektId: Scalars['ID']['input'];
};

export type QueryGetFilterGenLaufOrderFakturierungsperiodeListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
};

export type QueryGetFilterGenLaufOrderGenerierlaufEntryStatusListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
};

export type QueryGetFilterGenLaufOrderObjektListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
  rechnungsAusstellerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetFilterGenLaufOrderPaymentTransactionTypeListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
};

export type QueryGetFilterGenLaufOrderPaymentTypeListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
};

export type QueryGetFilterGenLaufOrderRechnungsAusstellerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
};

export type QueryGetFilterGenLaufOrderRechnungsEmpfaengerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
};

export type QueryGetFilterGenLaufOrderRecipientEmailAddressListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
};

export type QueryGetFilterGenLaufOrderRecipientListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
};

export type QueryGetFilterGenLaufOrderVertragspartnerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: OrderGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentBuchungskreisRechtstraegerListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentCreditorListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentDataCarrierNameListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentDataCarrierTypeListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentDebtorListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentEbicsUserNameListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentEbicsUserUserIdListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentGenerierlaufEntryStatusListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentIbanListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentMitarbeiterListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentObjektListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentStatementNumberListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentTransactionTypeListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterGenLaufPaymentTypeListArgs = {
  generierlaufId: Scalars['ID']['input'];
  generierlaufType: PaymentGenerierlaufType;
};

export type QueryGetFilterPaymentBelegnummerListArgs = {
  belegnummer?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterPaymentInvoiceIssuerIbanListArgs = {
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterPaymentPayeeIbanListArgs = {
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterPaymentPurposeOfUseTextListArgs = {
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterPaymentReferenceTextListArgs = {
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterReceivedBankTransactionCreditorIbanListArgs = {
  creditor?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterReceivedBankTransactionDebtorIbanListArgs = {
  debtor?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterReceivedBankTransactionEndToEndIdListArgs = {
  endToEndId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterReceivedBankTransactionPaymentReferenceTextListArgs = {
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFilterReceivedBankTransactionPurposeOfUseTextListArgs = {
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFinancialPlanTextsArgs = {
  budgetingId: Scalars['ID']['input'];
  budgetingVPosId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetFirmaArgs = {
  firmaId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetFirmaChangeHistoryListArgs = {
  firmaId: Scalars['ID']['input'];
};

export type QueryGetFirmaContactPersonDeletionHistoryListArgs = {
  firmaId: Scalars['ID']['input'];
};

export type QueryGetFirmaOperatingSiteArgs = {
  firmaId: Scalars['String']['input'];
  operatingSiteId: Scalars['ID']['input'];
};

export type QueryGetFirmaOperatingSiteChangeHistoryListArgs = {
  firmaId: Scalars['ID']['input'];
  operatingSiteId: Scalars['ID']['input'];
};

export type QueryGetFirmaOperatingSiteDeletionHistoryListArgs = {
  firmaId: Scalars['ID']['input'];
};

export type QueryGetFirmaOperatingSiteListArgs = {
  firmaId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
};

export type QueryGetFirmendatenBankDetailsChangeHistoryListArgs = {
  bankDetailsId: Scalars['ID']['input'];
  firmendatenId: Scalars['String']['input'];
};

export type QueryGetFirmendatenBankDetailsListArgs = {
  firmendatenId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetFirmendatenContactPersonDeletionHistoryListArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenEmailContactDeletionHistoryListArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenFileInfoBestandseinheitListArgs = {
  objektId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenFileInfoInvoiceNumberListArgs = {
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFirmendatenIndexSeriesArgs = {
  indexSeriesId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenIndexSeriesListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  indexType?: InputMaybe<IndexType>;
};

export type QueryGetFirmendatenInitializerGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenObjektAbrechnungListArgs = {
  abrechnungTypeList?: InputMaybe<Array<AbrechnungType>>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetFirmendatenOperatingSiteArgs = {
  firmendatenId: Scalars['String']['input'];
  operatingSiteId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenOperatingSiteChangeHistoryListArgs = {
  firmendatenId: Scalars['ID']['input'];
  operatingSiteId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenOperatingSiteDeletionHistoryListArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenOperatingSiteListArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenPhoneContactDeletionHistoryListArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenPostofficeBoxAddressDeletionHistoryListArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetFirmendatenStreetAddressDeletionHistoryListArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetFirmeneinstellungArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetGenerierlaufListArgs = {
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  generierDatumBisInklusive?: InputMaybe<Scalars['String']['input']>;
  generierDatumVonInklusive?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<GenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  statusList?: InputMaybe<Array<GenerierlaufStatus>>;
  typeList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetHeAbrechnungArgs = {
  heAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetHeAbrechnungDeviationListArgs = {
  heAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetHeAbrechnungGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetHeAbrechnungGenerierlaufEntryListArgs = {
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  hauseigentuemerId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<HeAbrechnungGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetHeAbrechnungHeVertragArgs = {
  heAbrechnungHeVertragId: Scalars['ID']['input'];
  heAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetHeAbrechnungHeVertragListArgs = {
  heAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetHeAbrechnungListArgs = {
  objektId: Scalars['ID']['input'];
};

export type QueryGetHeAbrechnungsdefinitionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetHeAbrechnungsdefinitionTemplateArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetHeAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  searchInHistory?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetHeAbrechnungsdefinitionTemplateVersionListArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetHeAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  searchInHistory?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetHeAbrechnungsdefinitionVersionListArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetHeVertragArgs = {
  heVertragId: Scalars['ID']['input'];
  objektId: Scalars['String']['input'];
};

export type QueryGetHeVertragBaseListArgs = {
  objektId: Scalars['ID']['input'];
  showObjekt?: InputMaybe<Scalars['Boolean']['input']>;
  showVertragsdauer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetHeVertragChangeHistoryListArgs = {
  heVertragId: Scalars['ID']['input'];
  objektId: Scalars['String']['input'];
};

export type QueryGetHeVertragKurzbezeichnungArgs = {
  heVertragId: Scalars['ID']['input'];
  objektId: Scalars['String']['input'];
  showObjekt?: InputMaybe<Scalars['Boolean']['input']>;
  showVertragsdauer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetHeVertragListArgs = {
  objektId: Scalars['ID']['input'];
};

export type QueryGetHeVertragOverviewListArgs = {
  order?: InputMaybe<HeVertragOverviewOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['ID']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetHeVertragZahlungsregelArgs = {
  heVertragId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  zahlungsregelId: Scalars['ID']['input'];
};

export type QueryGetHeVertragZahlungsregelChangeHistoryListArgs = {
  heVertragId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  zahlungsregelId: Scalars['ID']['input'];
};

export type QueryGetHeVertragZahlungsregelListArgs = {
  heVertragId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['String']['input'];
};

export type QueryGetIbanFibuKontoListArgs = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  bankname?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  fibuAccountNumber?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  lastBankStatementFrom?: InputMaybe<Scalars['String']['input']>;
  lastBankStatementTo?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<IbanFibuKontoOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
};

export type QueryGetIncomingInvoiceArgs = {
  belegId: Scalars['String']['input'];
};

export type QueryGetIncomingInvoiceBookingCreationGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetIncomingInvoiceBookingCreationGenerierlaufEntryListArgs = {
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  invoiceDateFrom?: InputMaybe<Scalars['String']['input']>;
  invoiceDateTo?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceStatus?: InputMaybe<IncomingInvoiceStatus>;
  issuerId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  payeeId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetIncomingInvoiceInvoiceNumberListArgs = {
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetIncomingInvoiceListArgs = {
  billingType?: InputMaybe<Verrechnungsart>;
  bruttoFrom?: InputMaybe<Scalars['Float']['input']>;
  bruttoTo?: InputMaybe<Scalars['Float']['input']>;
  buchungsdatumFrom?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumTo?: InputMaybe<Scalars['String']['input']>;
  buchungstext?: InputMaybe<Scalars['String']['input']>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  createdByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  incomingInvoiceFilterStatus?: InputMaybe<IncomingInvoiceFilterStatus>;
  invoiceDateFrom?: InputMaybe<Scalars['String']['input']>;
  invoiceDateTo?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  issuerBankDetailsIban?: InputMaybe<Scalars['String']['input']>;
  issuerRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  kontoId?: InputMaybe<Scalars['String']['input']>;
  kundenNummer?: InputMaybe<Scalars['String']['input']>;
  nettoFrom?: InputMaybe<Scalars['Float']['input']>;
  nettoTo?: InputMaybe<Scalars['Float']['input']>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  order?: InputMaybe<IncomingInvoiceOrderAttribute>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  payeeBankDetailsIban?: InputMaybe<Scalars['String']['input']>;
  payeeRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  sepaMandatReference?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IncomingInvoiceStatus>;
  vatFrom?: InputMaybe<Scalars['Float']['input']>;
  vatTo?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetIncomingInvoiceListSummaryArgs = {
  billingType?: InputMaybe<Verrechnungsart>;
  bruttoFrom?: InputMaybe<Scalars['Float']['input']>;
  bruttoTo?: InputMaybe<Scalars['Float']['input']>;
  buchungsdatumFrom?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumTo?: InputMaybe<Scalars['String']['input']>;
  buchungstext?: InputMaybe<Scalars['String']['input']>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  createdByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  incomingInvoiceFilterStatus?: InputMaybe<IncomingInvoiceFilterStatus>;
  invoiceDateFrom?: InputMaybe<Scalars['String']['input']>;
  invoiceDateTo?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  issuerBankDetailsIban?: InputMaybe<Scalars['String']['input']>;
  issuerRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  kontoId?: InputMaybe<Scalars['String']['input']>;
  kundenNummer?: InputMaybe<Scalars['String']['input']>;
  nettoFrom?: InputMaybe<Scalars['Float']['input']>;
  nettoTo?: InputMaybe<Scalars['Float']['input']>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  payeeBankDetailsIban?: InputMaybe<Scalars['String']['input']>;
  payeeRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  sepaMandatReference?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IncomingInvoiceStatus>;
  vatFrom?: InputMaybe<Scalars['Float']['input']>;
  vatTo?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetIncomingInvoicePaymentReferenceTextListArgs = {
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetIncomingInvoicePurposeOfUseTextListArgs = {
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetIncomingInvoiceSepaMandateReferenceListArgs = {
  sepaMandateReference?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetIndexSeriesArgs = {
  indexSeriesId: Scalars['ID']['input'];
};

export type QueryGetIndexSeriesEntryArgs = {
  indexSeriesEntryId: Scalars['ID']['input'];
  indexSeriesId: Scalars['String']['input'];
};

export type QueryGetIndexSeriesEntryListArgs = {
  indexSeriesId: Scalars['ID']['input'];
};

export type QueryGetIndexSeriesFinalValueArgs = {
  indexSeriesId: Scalars['ID']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type QueryGetInfoMailDeliveryArgs = {
  infoMailDeliveryId: Scalars['ID']['input'];
};

export type QueryGetInfoMailDeliveryAttachmentListArgs = {
  infoMailDeliveryId: Scalars['ID']['input'];
  infoMailDeliveryWizardId: Scalars['String']['input'];
};

export type QueryGetInfoMailDeliveryDetermineRecipientListGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetInfoMailDeliveryDetermineRecipientListGenerierlaufEntryListArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<InfoMailDeliveryDetermineRecipientListGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  recipientId?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetInfoMailDeliverySendGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetInfoMailDeliverySendGenerierlaufEntryListArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<InfoMailDeliverySendGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  recipientId?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetInfoMailDeliveryWizardDetermineRecipientListStepArgs = {
  infoMailDeliveryWizardId: Scalars['ID']['input'];
};

export type QueryGetInfoMailDeliveryWizardRecipientListArgs = {
  infoMailDeliveryWizardId: Scalars['ID']['input'];
  order?: InputMaybe<InfoMailDeliveryRecipientListStepOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetInfoMailDeliveryWizardRecipientListStepArgs = {
  infoMailDeliveryWizardId: Scalars['ID']['input'];
};

export type QueryGetInfoMailDeliveryWizardTemplateStepArgs = {
  infoMailDeliveryWizardId: Scalars['ID']['input'];
};

export type QueryGetKsFirmendatenListArgs = {
  kundenSystemId: Scalars['ID']['input'];
};

export type QueryGetKontierungstabelleChangeHistoryListArgs = {
  kontierungstabelleId: Scalars['ID']['input'];
};

export type QueryGetKontierungstabelleListByFirmendatenIdArgs = {
  firmendatenId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetKontierungstabelleListByKundenSystemIdArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  kundenSystemId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetKontierungstabelleTemplateArgs = {
  kontierungstabelleId: Scalars['ID']['input'];
};

export type QueryGetKontierungstabelleTemplateChangeHistoryListArgs = {
  kontierungstabelleId: Scalars['ID']['input'];
};

export type QueryGetKontierungstabelleTemplateListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetKontoArgs = {
  kontoId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetKontoChangeHistoryListArgs = {
  kontoId: Scalars['ID']['input'];
};

export type QueryGetKontoListArgs = {
  hvKonto?: InputMaybe<Scalars['Boolean']['input']>;
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  klasseList?: InputMaybe<Array<KontoKlasse>>;
  order?: InputMaybe<KontoOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<KontoType>;
  verwendung?: InputMaybe<KontoVerwendung>;
};

export type QueryGetKontoTemplateArgs = {
  kontoId: Scalars['ID']['input'];
};

export type QueryGetKontoTemplateChangeHistoryListArgs = {
  kontoId: Scalars['ID']['input'];
};

export type QueryGetKontoTemplateListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  klasseList?: InputMaybe<Array<KontoKlasse>>;
  type?: InputMaybe<KontoType>;
  verwendung?: InputMaybe<KontoVerwendung>;
};

export type QueryGetKundenSystemArgs = {
  kundenSystemId: Scalars['ID']['input'];
};

export type QueryGetKundenSystemChangeLogsArgs = {
  kundenSystemId: Scalars['ID']['input'];
};

export type QueryGetKundensystemEinstellungArgs = {
  kundenSystemId: Scalars['ID']['input'];
};

export type QueryGetLeerstehungAufwandskontoArgs = {
  firmendatenLeerstehungAufwandskontoId: Scalars['ID']['input'];
};

export type QueryGetMahnPositionChangeHistoryListArgs = {
  mahnPositionId: Scalars['ID']['input'];
  mahnungId: Scalars['ID']['input'];
};

export type QueryGetMahnPositionDeletionHistoryListArgs = {
  mahnungId: Scalars['ID']['input'];
};

export type QueryGetMahndefinitionArgs = {
  mahndefinitionId: Scalars['ID']['input'];
};

export type QueryGetMahndefinitionChangeHistoryListArgs = {
  mahndefinitionId: Scalars['ID']['input'];
};

export type QueryGetMahndefinitionListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetMahndefinitionTemplateChangeHistoryListArgs = {
  mahndefinitionId: Scalars['ID']['input'];
};

export type QueryGetMahndefinitionTemplateListArgs = {
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetMahndefinitionTemplateSingleArgs = {
  mahndefinitionId: Scalars['ID']['input'];
};

export type QueryGetMahnsperreChangeHistoryListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetMahnstufeChangeHistoryListArgs = {
  mahndefinitionId: Scalars['ID']['input'];
  mahnstufeIndex: Scalars['Int']['input'];
};

export type QueryGetMahnstufeTemplateChangeHistoryListArgs = {
  mahndefinitionId: Scalars['ID']['input'];
  mahnstufeIndex: Scalars['Int']['input'];
};

export type QueryGetMahnungArgs = {
  mahnungId: Scalars['ID']['input'];
};

export type QueryGetMahnungAusgebenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetMahnungAusgebenGenerierlaufEntryListArgs = {
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<MahnungGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsausstellerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetMahnungChangeHistoryListArgs = {
  mahnungId: Scalars['ID']['input'];
};

export type QueryGetMahnungErzeugenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetMahnungErzeugenGenerierlaufEntryListArgs = {
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<MahnungGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsausstellerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetMahnungListArgs = {
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  mahngebuehrBisInklusive?: InputMaybe<Scalars['Float']['input']>;
  mahngebuehrVonInklusive?: InputMaybe<Scalars['Float']['input']>;
  mahnstichtagBisInklusive?: InputMaybe<Scalars['String']['input']>;
  mahnstichtagVonInklusive?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<MahnungOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<MahnungStatus>>;
  sumMahnbetragBisInklusive?: InputMaybe<Scalars['Float']['input']>;
  sumMahnbetragVonInklusive?: InputMaybe<Scalars['Float']['input']>;
  sumRechnungsbetragBisInklusive?: InputMaybe<Scalars['Float']['input']>;
  sumRechnungsbetragVonInklusive?: InputMaybe<Scalars['Float']['input']>;
  versandEinschreiben?: InputMaybe<Scalars['Boolean']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
  verzugszinsenBisInklusive?: InputMaybe<Scalars['Float']['input']>;
  verzugszinsenVonInklusive?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetMahnungListSummaryArgs = {
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  mahngebuehrBisInklusive?: InputMaybe<Scalars['Float']['input']>;
  mahngebuehrVonInklusive?: InputMaybe<Scalars['Float']['input']>;
  mahnstichtagBisInklusive?: InputMaybe<Scalars['String']['input']>;
  mahnstichtagVonInklusive?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<MahnungStatus>>;
  sumMahnbetragBisInklusive?: InputMaybe<Scalars['Float']['input']>;
  sumMahnbetragVonInklusive?: InputMaybe<Scalars['Float']['input']>;
  sumRechnungsbetragBisInklusive?: InputMaybe<Scalars['Float']['input']>;
  sumRechnungsbetragVonInklusive?: InputMaybe<Scalars['Float']['input']>;
  versandEinschreiben?: InputMaybe<Scalars['Boolean']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
  verzugszinsenBisInklusive?: InputMaybe<Scalars['Float']['input']>;
  verzugszinsenVonInklusive?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetMahnungObjektListArgs = {
  mahnungId: Scalars['ID']['input'];
};

export type QueryGetMahnungTemplateChangeHistoryListArgs = {
  mahndefinitionId: Scalars['ID']['input'];
  mahnstufeIndex: Scalars['Int']['input'];
};

export type QueryGetMahnungTemplateTemplateChangeHistoryListArgs = {
  mahndefinitionId: Scalars['ID']['input'];
  mahnstufeIndex: Scalars['Int']['input'];
};

export type QueryGetMahnungVerbuchenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetMahnungVerbuchenGenerierlaufEntryListArgs = {
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<MahnungGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsausstellerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetMahnungVersendenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetMahnungVersendenGenerierlaufEntryListArgs = {
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<MahnungGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsausstellerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetMailArgs = {
  mailId: Scalars['String']['input'];
};

export type QueryGetMailListArgs = {
  createTsFromInclusive?: InputMaybe<Scalars['String']['input']>;
  createTsToInclusive?: InputMaybe<Scalars['String']['input']>;
  createdByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  firmendatenId: Scalars['String']['input'];
  order?: InputMaybe<MailOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  recipient?: InputMaybe<Scalars['String']['input']>;
  recipientRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<InputMaybe<MailStatus>>>;
  useCaseList?: InputMaybe<Array<MailUseCase>>;
};

export type QueryGetMitarbeiterDetailsArgs = {
  includeUser?: InputMaybe<Scalars['Boolean']['input']>;
  mitarbeiterId: Scalars['ID']['input'];
};

export type QueryGetMitarbeiterDetailsByFirmendatenIdArgs = {
  firmendatenId: Scalars['String']['input'];
  includeUser?: InputMaybe<Scalars['Boolean']['input']>;
  mitarbeiterId: Scalars['ID']['input'];
};

export type QueryGetMitarbeiterListArgs = {
  includeUser?: InputMaybe<Scalars['Boolean']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetMitarbeiterListByFirmendatenIdArgs = {
  firmendatenId: Scalars['String']['input'];
  includeUser?: InputMaybe<Scalars['Boolean']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetMitarbeiterTicketCountListArgs = {
  dueDate?: InputMaybe<Scalars['String']['input']>;
  firmendatenId: Scalars['String']['input'];
};

export type QueryGetNatuerlichePersonArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetNatuerlichePersonAddressArgs = {
  addressId: Scalars['ID']['input'];
  natuerlichePersonId: Scalars['ID']['input'];
};

export type QueryGetNatuerlichePersonChangeHistoryListArgs = {
  natuerlichePersonId: Scalars['ID']['input'];
};

export type QueryGetNextSepaMandatReferenceArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetNotificationListArgs = {
  createTsBisInklusive?: InputMaybe<Scalars['String']['input']>;
  createTsVonInklusive?: InputMaybe<Scalars['String']['input']>;
  dismissed?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<NotificationOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<NotificationType>;
};

export type QueryGetObjektArgs = {
  objektId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetObjektAbrechnungArgs = {
  objektAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetObjektAbrechnungDeviationListArgs = {
  objektAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetObjektAbrechnungGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetObjektAbrechnungGenerierlaufEntryListArgs = {
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<ObjektAbrechnungGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsausstellerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetObjektAufteilungsschluesselChangeHistoryListArgs = {
  objektAufteilungsschluesselId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetObjektAufteilungsschluesselListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetObjektBankDetailsChangeHistoryListArgs = {
  bankDetailsId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetObjektBankDetailsListArgs = {
  objektId: Scalars['ID']['input'];
};

export type QueryGetObjektChangeHistoryListArgs = {
  objektId: Scalars['ID']['input'];
};

export type QueryGetObjektFinancialPlanGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetObjektInfoFeldArgs = {
  infoFeldId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetObjektInfoFeldListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
};

export type QueryGetObjektKurzBezeichnungArgs = {
  objektId: Scalars['ID']['input'];
};

export type QueryGetObjektListArgs = {
  abrechnungType?: InputMaybe<AbrechnungType>;
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  kurzBezeichnung?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetObjektSummeAnteileArgs = {
  objektId: Scalars['String']['input'];
  summeAnteileId: Scalars['ID']['input'];
};

export type QueryGetObjektSummeAnteileListArgs = {
  objektId: Scalars['ID']['input'];
};

export type QueryGetObjektVorschreibungspositionChangeHistoryListArgs = {
  objektId: Scalars['ID']['input'];
  objektVorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetObjektVorschreibungspositionListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetOffenePostenArgs = {
  offenePostenId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetOffenePostenBelegnummerListArgs = {
  belegnummer?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetOffenePostenListArgs = {
  belegDatumFrom?: InputMaybe<Scalars['String']['input']>;
  belegDatumTo?: InputMaybe<Scalars['String']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  buchungsType?: InputMaybe<FibuBuchungType>;
  buchungsdatumFrom?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumTo?: InputMaybe<Scalars['String']['input']>;
  buchungskreisId?: InputMaybe<Scalars['String']['input']>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  createdByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  gesamtBetragFrom?: InputMaybe<Scalars['Float']['input']>;
  gesamtBetragTo?: InputMaybe<Scalars['Float']['input']>;
  includeAusgeziffert?: InputMaybe<Scalars['Boolean']['input']>;
  kontoId?: InputMaybe<Scalars['String']['input']>;
  kontoType?: InputMaybe<OffenePostenKontoType>;
  letzteMahnstufe?: InputMaybe<Scalars['Int']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  offenerBetragFrom?: InputMaybe<Scalars['Float']['input']>;
  offenerBetragTo?: InputMaybe<Scalars['Float']['input']>;
  order?: InputMaybe<OffenePostenOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  vertragsartList?: InputMaybe<Array<Vertragsart>>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetOffenePostenListSummaryArgs = {
  belegDatumFrom?: InputMaybe<Scalars['String']['input']>;
  belegDatumTo?: InputMaybe<Scalars['String']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  buchungsType?: InputMaybe<FibuBuchungType>;
  buchungsdatumFrom?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumTo?: InputMaybe<Scalars['String']['input']>;
  buchungskreisId?: InputMaybe<Scalars['String']['input']>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  createdByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  gesamtBetragFrom?: InputMaybe<Scalars['Float']['input']>;
  gesamtBetragTo?: InputMaybe<Scalars['Float']['input']>;
  includeAusgeziffert?: InputMaybe<Scalars['Boolean']['input']>;
  kontoId?: InputMaybe<Scalars['String']['input']>;
  kontoType?: InputMaybe<OffenePostenKontoType>;
  letzteMahnstufe?: InputMaybe<Scalars['Int']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  offenerBetragFrom?: InputMaybe<Scalars['Float']['input']>;
  offenerBetragTo?: InputMaybe<Scalars['Float']['input']>;
  rechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  vertragsartList?: InputMaybe<Array<Vertragsart>>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetOrderFieldHelpListArgs = {
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  fieldHelp?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<FieldHelpOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  selector?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPauschalmieteAufwandskontoArgs = {
  firmendatenPauschalmieteAufwandskontoId: Scalars['ID']['input'];
};

export type QueryGetPauschalmieteKontenplanArgs = {
  date: Scalars['String']['input'];
};

export type QueryGetPaymentBelegSymbolListArgs = {
  includeStorno?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetPaymentBuchungTypeListArgs = {
  belegSymbol?: InputMaybe<PaymentBelegSymbol>;
};

export type QueryGetPaymentChangeHistoryListArgs = {
  paymentId: Scalars['ID']['input'];
};

export type QueryGetPaymentClearingBookingListArgs = {
  buchungskreisId: Scalars['String']['input'];
  kontoId: Scalars['String']['input'];
  vertragspartnerId: Scalars['String']['input'];
};

export type QueryGetPaymentClearingListArgs = {
  availableAmountFrom?: InputMaybe<Scalars['Float']['input']>;
  availableAmountTo?: InputMaybe<Scalars['Float']['input']>;
  buchungskreisId?: InputMaybe<Scalars['String']['input']>;
  kontoId?: InputMaybe<Scalars['String']['input']>;
  kontoVerwendung?: InputMaybe<KontoVerwendung>;
  order?: InputMaybe<PaymentClearingOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  outstandingAmountFrom?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountTo?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPaymentClearingListSummaryArgs = {
  availableAmountFrom?: InputMaybe<Scalars['Float']['input']>;
  availableAmountTo?: InputMaybe<Scalars['Float']['input']>;
  buchungskreisId?: InputMaybe<Scalars['String']['input']>;
  kontoId?: InputMaybe<Scalars['String']['input']>;
  kontoVerwendung?: InputMaybe<KontoVerwendung>;
  outstandingAmountFrom?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountTo?: InputMaybe<Scalars['Float']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPaymentFieldHelpListArgs = {
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  fieldHelp?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<FieldHelpOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  selector?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPaymentListArgs = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  approvedByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  assignedToPaymentProposal?: InputMaybe<Scalars['Boolean']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumBis?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumVon?: InputMaybe<Scalars['String']['input']>;
  dataCarrierIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  employeeApproval?: InputMaybe<EmployeeApproval>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceDateFrom?: InputMaybe<Scalars['String']['input']>;
  invoiceDateTo?: InputMaybe<Scalars['String']['input']>;
  noted?: InputMaybe<Scalars['Boolean']['input']>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  order?: InputMaybe<PaymentOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  payeeIban?: InputMaybe<Scalars['String']['input']>;
  payeeId?: InputMaybe<Scalars['String']['input']>;
  paymentApprovalStatusList?: InputMaybe<Array<PaymentApprovalStatus>>;
  paymentDateFrom?: InputMaybe<Scalars['String']['input']>;
  paymentDateTo?: InputMaybe<Scalars['String']['input']>;
  paymentProposalId?: InputMaybe<Scalars['String']['input']>;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
  paymentTypeList?: InputMaybe<Array<PaymentType>>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerFibuKontoId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerIban?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  sepaCreditorId?: InputMaybe<Scalars['String']['input']>;
  sepaMandateReference?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<OrderPaymentStatus>>;
};

export type QueryGetPaymentListSummaryArgs = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  assignedToPaymentProposal?: InputMaybe<Scalars['Boolean']['input']>;
  belegnummer?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumBis?: InputMaybe<Scalars['String']['input']>;
  buchungsdatumVon?: InputMaybe<Scalars['String']['input']>;
  employeeApproval?: InputMaybe<EmployeeApproval>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceDateFrom?: InputMaybe<Scalars['String']['input']>;
  invoiceDateTo?: InputMaybe<Scalars['String']['input']>;
  noted?: InputMaybe<Scalars['Boolean']['input']>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  payeeIban?: InputMaybe<Scalars['String']['input']>;
  payeeId?: InputMaybe<Scalars['String']['input']>;
  paymentApprovalStatusList?: InputMaybe<Array<PaymentApprovalStatus>>;
  paymentDateFrom?: InputMaybe<Scalars['String']['input']>;
  paymentDateTo?: InputMaybe<Scalars['String']['input']>;
  paymentProposalId?: InputMaybe<Scalars['String']['input']>;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
  paymentTypeList?: InputMaybe<Array<PaymentType>>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerFibuKontoId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerIban?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  sepaCreditorId?: InputMaybe<Scalars['String']['input']>;
  sepaMandateReference?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<OrderPaymentStatus>>;
};

export type QueryGetPaymentMethodListArgs = {
  selection?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetPaymentProposalCreationGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetPaymentProposalCreationGenerierlaufEntryListArgs = {
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektIdList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  order?: InputMaybe<PaymentProposalGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
  paymentTypeList?: InputMaybe<Array<PaymentType>>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPaymentProposalExecutionGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetPaymentProposalExecutionGenerierlaufEntryListArgs = {
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektIdList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  order?: InputMaybe<PaymentProposalGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paymentTransactionType?: InputMaybe<PaymentTransactionType>;
  paymentTypeList?: InputMaybe<Array<PaymentType>>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPaymentProposalListArgs = {
  mitarbeiterId?: InputMaybe<Scalars['ID']['input']>;
  statusList?: InputMaybe<Array<PaymentProposalStatus>>;
};

export type QueryGetPersonenGemeinschaftArgs = {
  personenGemeinschaftId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetPersonenGemeinschaftAddressArgs = {
  addressId: Scalars['ID']['input'];
  personenGemeinschaftId: Scalars['ID']['input'];
};

export type QueryGetPersonenGemeinschaftChangeHistoryListArgs = {
  personenGemeinschaftId: Scalars['ID']['input'];
};

export type QueryGetPersonenbezugChangeHistoryListArgs = {
  personenbezugId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type QueryGetReceivedBankTransactionListArgs = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  bankStatementIban?: InputMaybe<Scalars['String']['input']>;
  booked?: InputMaybe<Scalars['Boolean']['input']>;
  bookingDateFrom?: InputMaybe<Scalars['String']['input']>;
  bookingDateTo?: InputMaybe<Scalars['String']['input']>;
  buchungskreisRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  chargesFrom?: InputMaybe<Scalars['Float']['input']>;
  chargesInSeparateEntry?: InputMaybe<Scalars['Boolean']['input']>;
  chargesTo?: InputMaybe<Scalars['Float']['input']>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  createdByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  creditor?: InputMaybe<Scalars['String']['input']>;
  creditorIban?: InputMaybe<Scalars['String']['input']>;
  dataCarrierName?: InputMaybe<Scalars['String']['input']>;
  debtor?: InputMaybe<Scalars['String']['input']>;
  debtorIban?: InputMaybe<Scalars['String']['input']>;
  endToEndId?: InputMaybe<Scalars['String']['input']>;
  hasWarning?: InputMaybe<Scalars['Boolean']['input']>;
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  noted?: InputMaybe<Scalars['Boolean']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<ReceivedBankTransactionOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paymentAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  paymentId?: InputMaybe<Scalars['ID']['input']>;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  reversal?: InputMaybe<Scalars['Boolean']['input']>;
  statementNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReceivedBankTransactionStatus>;
  transactionType?: InputMaybe<TransactionType>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
  valueDateFrom?: InputMaybe<Scalars['String']['input']>;
  valueDateTo?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetReceivedBankTransactionListSummaryArgs = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  bankStatementIban?: InputMaybe<Scalars['String']['input']>;
  booked?: InputMaybe<Scalars['Boolean']['input']>;
  bookingDateFrom?: InputMaybe<Scalars['String']['input']>;
  bookingDateTo?: InputMaybe<Scalars['String']['input']>;
  buchungskreisRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  chargesFrom?: InputMaybe<Scalars['Float']['input']>;
  chargesInSeparateEntry?: InputMaybe<Scalars['Boolean']['input']>;
  chargesTo?: InputMaybe<Scalars['Float']['input']>;
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  createdByMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  creditor?: InputMaybe<Scalars['String']['input']>;
  creditorIban?: InputMaybe<Scalars['String']['input']>;
  dataCarrierName?: InputMaybe<Scalars['String']['input']>;
  debtor?: InputMaybe<Scalars['String']['input']>;
  debtorIban?: InputMaybe<Scalars['String']['input']>;
  endToEndId?: InputMaybe<Scalars['String']['input']>;
  hasWarning?: InputMaybe<Scalars['Boolean']['input']>;
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  noted?: InputMaybe<Scalars['Boolean']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<ReceivedBankTransactionOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  paymentAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  paymentId?: InputMaybe<Scalars['ID']['input']>;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  reversal?: InputMaybe<Scalars['Boolean']['input']>;
  statementNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReceivedBankTransactionStatus>;
  transactionType?: InputMaybe<TransactionType>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
  valueDateFrom?: InputMaybe<Scalars['String']['input']>;
  valueDateTo?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetReceivedBankTransactionVerbuchenGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetReceivedBankTransactionVerbuchenGenerierlaufEntryArgs = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  creditor?: InputMaybe<Scalars['String']['input']>;
  dataCarrierCamtName?: InputMaybe<Scalars['String']['input']>;
  debtor?: InputMaybe<Scalars['String']['input']>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<ReceivedBankTransactionVerbuchenGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  statementNumber?: InputMaybe<Scalars['String']['input']>;
  valueDateFrom?: InputMaybe<Scalars['String']['input']>;
  valueDateTo?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetRechnungsAusstellerArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechnungsAusstellerBankSettingsChangeHistoryListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechnungsAusstellerBestandseinheitListArgs = {
  objektId: Scalars['String']['input'];
  rechnungsAusstellerId: Scalars['String']['input'];
};

export type QueryGetRechnungsAusstellerMahndefinitionChangeHistoryListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerAddressesAndContactsArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerBankDetailsArgs = {
  bankDetailsId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['String']['input'];
};

export type QueryGetRechtstraegerBankDetailsChangeHistoryListArgs = {
  bankDetailsId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerBankDetailsListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerContactListArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetRechtstraegerEmailContactDeletionHistoryListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerInfoFeldArgs = {
  infoFeldId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerInfoFeldListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerListArgs = {
  createTsFrom?: InputMaybe<Scalars['String']['input']>;
  createTsTo?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  hasBuchungskreis?: InputMaybe<Scalars['Boolean']['input']>;
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  kundennummer?: InputMaybe<Scalars['String']['input']>;
  kurzBezeichnung?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<RechtstraegerOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
  typeList?: InputMaybe<Array<InputMaybe<RechtstraegerType>>>;
};

export type QueryGetRechtstraegerPhoneContactDeletionHistoryListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerPostofficeBoxAddressDeletionHistoryListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerRolleListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerSteuerpflichtArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  rechtstraegerSteuerpflichtId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetRechtstraegerSteuerpflichtListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetRechtstraegerStreetAddressDeletionHistoryListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRechtstraegerTypeArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRepFondsEinstellungListArgs = {
  onlyCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetRepFondsEinstellungTemplateListArgs = {
  onlyCurrent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetRepFondsErloesKontenZuweisungArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  repFondsErloesKontenZuweisungId: Scalars['ID']['input'];
};

export type QueryGetRepFondsErloesKontenZuweisungChangeHistoryListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
  repFondsErloesKontenZuweisungId: Scalars['ID']['input'];
};

export type QueryGetRepFondsErloesKontenZuweisungDeletionHistoryListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetRepFondsErloesKontenZuweisungListArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetReportLogosArgs = {
  firmendatenId: Scalars['ID']['input'];
};

export type QueryGetSaldenlisteArgs = {
  buchungskreisId: Scalars['String']['input'];
  buchungsmonat: Scalars['String']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<SaldenlisteOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<SaldenlisteKontoType>;
};

export type QueryGetSaldenlisteSummaryArgs = {
  buchungskreisId: Scalars['String']['input'];
  buchungsmonat: Scalars['String']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<SaldenlisteKontoType>;
};

export type QueryGetSaldoArgs = {
  kontoId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetSepaMandatChangeHistoryListArgs = {
  bankDetailsId: Scalars['ID']['input'];
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetSimilarTicketListArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetSingleInvoiceArgs = {
  belegId: Scalars['String']['input'];
};

export type QueryGetSingleInvoiceChangeHistoryArgs = {
  belegId: Scalars['ID']['input'];
};

export type QueryGetSingleInvoiceForDetailsAndUpdatePageArgs = {
  belegId: Scalars['String']['input'];
};

export type QueryGetStandingInvoiceArgs = {
  belegId: Scalars['String']['input'];
};

export type QueryGetStandingInvoiceChangeHistoryArgs = {
  belegId: Scalars['ID']['input'];
};

export type QueryGetStandingInvoiceForDetailsAndUpdatePageArgs = {
  belegId: Scalars['String']['input'];
};

export type QueryGetSteuertabelleArgs = {
  auftragId: Scalars['ID']['input'];
};

export type QueryGetSubAbrechnungArgs = {
  objektId: Scalars['ID']['input'];
  subAbrechnungId: Scalars['ID']['input'];
};

export type QueryGetSubAbrechnungEigentuemerArgs = {
  objektId: Scalars['ID']['input'];
  subAbrechnungEigentuemerId: Scalars['ID']['input'];
  subAbrechnungId: Scalars['ID']['input'];
};

export type QueryGetSubAbrechnungGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetSubAbrechnungGenerierlaufEntryListArgs = {
  eigentuemerId?: InputMaybe<Scalars['String']['input']>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<SubAbrechnungGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetSubAdministrationArgs = {
  objektId: Scalars['ID']['input'];
  subAdministrationId: Scalars['ID']['input'];
};

export type QueryGetSubAdministrationAbrechnungsdefinitionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetSubAdministrationAbrechnungsdefinitionTemplateArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetSubAdministrationAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  searchInHistory?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetSubAdministrationAbrechnungsdefinitionTemplateVersionListArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetSubAdministrationAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  searchInHistory?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetSubAdministrationAbrechnungsdefinitionVersionListArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetSubAdministrationChangeHistoryArgs = {
  objektId: Scalars['ID']['input'];
  subAdministrationId: Scalars['ID']['input'];
};

export type QueryGetSubAdministrationListArgs = {
  objektId: Scalars['ID']['input'];
};

export type QueryGetSubAdministrationSettingsChangeHistoryArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetSumVerzugszinsenArgs = {
  mahnPositionIdList: Array<Scalars['String']['input']>;
  mahnungId: Scalars['ID']['input'];
  verzugszinsenProzent: Scalars['Float']['input'];
};

export type QueryGetSummeAnteileArgs = {
  date: Scalars['String']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetSyncEbicsUserGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetTextbausteinArgs = {
  textbausteinId: Scalars['ID']['input'];
};

export type QueryGetTextbausteinChangeHistoryListArgs = {
  textbausteinId: Scalars['ID']['input'];
};

export type QueryGetTextbausteinListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetTextbausteinTemplateArgs = {
  textbausteinId: Scalars['ID']['input'];
};

export type QueryGetTextbausteinTemplateChangeHistoryListArgs = {
  textbausteinId: Scalars['ID']['input'];
};

export type QueryGetTextbausteinTemplatesArgs = {
  textbausteinType: TextbausteinType;
};

export type QueryGetTicketArgs = {
  includeAttachmentsFromSubTickets?: InputMaybe<Scalars['Boolean']['input']>;
  includeEmailsFromSubTickets?: InputMaybe<Scalars['Boolean']['input']>;
  includeParticipantsFromSubTickets?: InputMaybe<Scalars['Boolean']['input']>;
  ticketId: Scalars['ID']['input'];
};

export type QueryGetTicketChangeEntryListArgs = {
  scope?: InputMaybe<TicketChangeEntryScope>;
  ticketId: Scalars['ID']['input'];
};

export type QueryGetTicketCommentListArgs = {
  ticketId: Scalars['ID']['input'];
};

export type QueryGetTicketListArgs = {
  assigneeMitarbeiterId?: InputMaybe<Array<Scalars['String']['input']>>;
  beInfoFeldId?: InputMaybe<Scalars['ID']['input']>;
  bestandseinheitId?: InputMaybe<Scalars['ID']['input']>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['ID']['input']>;
  objektInfoFeldId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<TicketOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  participantId?: InputMaybe<Array<Scalars['String']['input']>>;
  priority?: InputMaybe<Array<TicketPriority>>;
  rechtstraegerId?: InputMaybe<Scalars['ID']['input']>;
  rechtstraegerInfoFeldId?: InputMaybe<Scalars['ID']['input']>;
  searchInSubTickets?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<TicketStatus>>;
  title?: InputMaybe<Scalars['String']['input']>;
  typeName?: InputMaybe<Array<Scalars['String']['input']>>;
  vertragId?: InputMaybe<Scalars['ID']['input']>;
  vertragInfoFeldId?: InputMaybe<Scalars['ID']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetTicketStatusListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetTicketTemplateArgs = {
  ticketTemplateId: Scalars['ID']['input'];
};

export type QueryGetTicketTemplateChangeHistoryListArgs = {
  ticketTemplateId: Scalars['ID']['input'];
};

export type QueryGetTicketTemplateTemplateArgs = {
  ticketTemplateId: Scalars['ID']['input'];
};

export type QueryGetTicketTemplateTemplateChangeHistoryListArgs = {
  ticketTemplateId: Scalars['ID']['input'];
};

export type QueryGetTopAbrechnungArgs = {
  objektAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  topAbrechnungId: Scalars['ID']['input'];
};

export type QueryGetTopAbrechnungDeviationListArgs = {
  objektAbrechnungId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
};

export type QueryGetTopAbrechnungGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetTopAbrechnungGenerierlaufEntryListArgs = {
  bestandseinheitIdList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TopAbrechnungGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsausstellerId?: InputMaybe<Scalars['String']['input']>;
  rechnungsempfaengerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetTopBudgetSumNextYearArgs = {
  budgetingResultId: Scalars['ID']['input'];
  budgetingResultVertragId?: InputMaybe<Scalars['ID']['input']>;
  objektId: Scalars['ID']['input'];
};

export type QueryGetTopFinancialPlanGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetTransactionInstructionListArgs = {
  fibuBuchungId?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetUserArgs = {
  username: Scalars['String']['input'];
};

export type QueryGetUserChangeLogArgs = {
  changeType?: InputMaybe<Scalars['String']['input']>;
  changedAfter?: InputMaybe<Scalars['String']['input']>;
  changedBefore?: InputMaybe<Scalars['String']['input']>;
  changedBy?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetUserNameArgs = {
  username: Scalars['String']['input'];
};

export type QueryGetUsersArgs = {
  createdBisInklusive?: InputMaybe<Scalars['String']['input']>;
  createdVonInklusive?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<Scalars['String']['input']>>;
  withEmailUnverified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetUstKategorieArgs = {
  ustKategorieId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetUstKategorieChangeHistoryListArgs = {
  ustKategorieId: Scalars['ID']['input'];
};

export type QueryGetUstKategorieListByFirmendatenIdArgs = {
  firmendatenId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetUstKategorieListByKundenSystemIdArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  kundenSystemId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetUstKategorieTemplateArgs = {
  ustKategorieId: Scalars['ID']['input'];
};

export type QueryGetUstKategorieTemplateChangeHistoryListArgs = {
  ustKategorieId: Scalars['ID']['input'];
};

export type QueryGetUstKategorieTemplateVersionArgs = {
  ustKategorieId: Scalars['ID']['input'];
  ustKategorieVersionId: Scalars['ID']['input'];
};

export type QueryGetUstKategorieTemplateVersionListArgs = {
  ustKategorieId: Scalars['ID']['input'];
};

export type QueryGetUstKategorieVersionArgs = {
  ustKategorieId: Scalars['ID']['input'];
  ustKategorieVersionId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetUstKategorieVersionListArgs = {
  ustKategorieId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetUstRegelsetArgs = {
  ustRegelsetId: Scalars['ID']['input'];
};

export type QueryGetUstRegelsetListByKundenSystemIdArgs = {
  kundenSystemId: Scalars['ID']['input'];
};

export type QueryGetUstRegelsetTemplateArgs = {
  ustRegelsetId: Scalars['ID']['input'];
};

export type QueryGetUstRegelsetTemplateChangeHistoryListArgs = {
  ustRegelsetId: Scalars['ID']['input'];
};

export type QueryGetUstRegelsetTemplateUstKategorieZuweisungArgs = {
  ustRegelsetId: Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Scalars['ID']['input'];
};

export type QueryGetUstRegelsetTemplateUstKategorieZuweisungListArgs = {
  ustRegelsetId: Scalars['ID']['input'];
};

export type QueryGetUstRegelsetUstKategorieZuweisungArgs = {
  ustRegelsetId: Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Scalars['ID']['input'];
};

export type QueryGetUstRegelsetUstKategorieZuweisungListArgs = {
  ustRegelsetId: Scalars['ID']['input'];
};

export type QueryGetUstVomAufwandArgs = {
  objektId: Scalars['ID']['input'];
  ustVomAufwandId: Scalars['ID']['input'];
};

export type QueryGetUstVomAufwandDeviationListArgs = {
  objektId: Scalars['ID']['input'];
  ustVomAufwandId: Scalars['ID']['input'];
};

export type QueryGetUstVomAufwandGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetUstVomAufwandListArgs = {
  includeEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  includeZero?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
};

export type QueryGetVertragLevelListArgs = {
  verwaltungsart?: InputMaybe<Verwaltungsart>;
};

export type QueryGetVertragVposIndexationAgreementArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  indexationAgreementId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetVertragVposIndexationAgreementChangeHistoryListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  indexationAgreementId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetVertragVposIndexationAgreementListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetVertragVposIndexationAgreementVorschreibungspositionListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
};

export type QueryGetVertragVposIndexedValueChangeHistoryListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  indexationAgreementId: Scalars['ID']['input'];
  indexedValueId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetVertragVposIndexedValueGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetVertragVposIndexedValueGenerierlaufEntryListArgs = {
  bestandseinheitIdList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  indexSeriesId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<VertragVposIndexedValueGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  valuePreservationType?: InputMaybe<ValuePreservationType>;
  vertragId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetVertragVposIndexedValueListArgs = {
  bestandseinheitId: Scalars['ID']['input'];
  indexationAgreementId: Scalars['ID']['input'];
  objektId: Scalars['ID']['input'];
  vertragId: Scalars['ID']['input'];
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetVertragVposIndexedValueListFirmendatenArgs = {
  bestandseinheitId?: InputMaybe<Scalars['ID']['input']>;
  indexSeriesId?: InputMaybe<Scalars['ID']['input']>;
  objektId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<VertragVposIndexedValueOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['ID']['input']>;
  statusList?: InputMaybe<Array<VertragVposIndexedValueStatus>>;
  validFrom?: InputMaybe<Scalars['String']['input']>;
  validTo?: InputMaybe<Scalars['String']['input']>;
  valuePreservationTypeList?: InputMaybe<Array<ValuePreservationType>>;
  vertragId?: InputMaybe<Scalars['ID']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['ID']['input']>;
  vorschreibungspositionName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetVertragVposIndexedValueListSummaryArgs = {
  bestandseinheitId?: InputMaybe<Scalars['ID']['input']>;
  indexSeriesId?: InputMaybe<Scalars['ID']['input']>;
  objektId?: InputMaybe<Scalars['ID']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['ID']['input']>;
  statusList?: InputMaybe<Array<VertragVposIndexedValueStatus>>;
  validFrom?: InputMaybe<Scalars['String']['input']>;
  validTo?: InputMaybe<Scalars['String']['input']>;
  valuePreservationTypeList?: InputMaybe<Array<ValuePreservationType>>;
  vertragId?: InputMaybe<Scalars['ID']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['ID']['input']>;
  vorschreibungspositionName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetVertragsartListArgs = {
  bestandseinheitId?: InputMaybe<Scalars['ID']['input']>;
  firmendatenId?: InputMaybe<Scalars['ID']['input']>;
  objektId?: InputMaybe<Scalars['ID']['input']>;
  vertragId?: InputMaybe<Scalars['ID']['input']>;
  verwaltungsart?: InputMaybe<Verwaltungsart>;
};

export type QueryGetVertragspartnerArgs = {
  rechtstraegerId: Scalars['ID']['input'];
};

export type QueryGetVertragspartnerKontoListArgs = {
  buchungskreisId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungMailDeliveryArgs = {
  vorschreibungMailDeliveryId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungMailDeliveryAttachmentListArgs = {
  vorschreibungMailDeliveryId: Scalars['ID']['input'];
  vorschreibungMailDeliveryWizardId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungMailDeliveryDetermineRecipientListGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<VorschreibungMailDeliveryDetermineRecipientGenLaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  recipientId?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetVorschreibungMailDeliverySendGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungMailDeliverySendGenerierlaufEntryListArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<VorschreibungMailDeliverySendGenLaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  recipientId?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetVorschreibungMailDeliveryWizardDetermineRecipientListStepArgs = {
  vorschreibungMailDeliveryWizardId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungMailDeliveryWizardRecipientListArgs = {
  order?: InputMaybe<VorschreibungMailDeliveryRecipientListStepOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  vorschreibungMailDeliveryWizardId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungMailDeliveryWizardRecipientListStepArgs = {
  vorschreibungMailDeliveryWizardId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungMailDeliveryWizardTemplateStepArgs = {
  vorschreibungMailDeliveryWizardId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungspositionArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungspositionChangeHistoryListArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungspositionListByFirmendatenIdArgs = {
  firmendatenId: Scalars['ID']['input'];
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetVorschreibungspositionListByKundenSystemIdArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  kundenSystemId: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetVorschreibungspositionTemplateArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungspositionTemplateChangeHistoryListArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
};

export type QueryGetVorschreibungspositionTemplateListArgs = {
  includeArchiviert?: InputMaybe<Scalars['Boolean']['input']>;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetVorschreibungspositionTemplateVersionArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
  vorschreibungspositionVersionId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetVorschreibungspositionTemplateVersionListArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetVorschreibungspositionVersionArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
  vorschreibungspositionVersionId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetVorschreibungspositionVersionListArgs = {
  vorschreibungspositionId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetVorsteuerkuerzungArgs = {
  objektId: Scalars['String']['input'];
  vorsteuerkuerzungId: Scalars['ID']['input'];
};

export type QueryGetVorsteuerkuerzungDeviationListArgs = {
  objektId: Scalars['ID']['input'];
  vorsteuerkuerzungId: Scalars['ID']['input'];
};

export type QueryGetVorsteuerkuerzungEinstellungListArgs = {
  onlyCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  verwaltungsart: Verwaltungsart;
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetVorsteuerkuerzungEinstellungTemplateListArgs = {
  onlyCurrent?: InputMaybe<Scalars['Boolean']['input']>;
  verwaltungsart: Verwaltungsart;
};

export type QueryGetVorsteuerkuerzungGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetVorsteuerkuerzungListArgs = {
  objektId: Scalars['String']['input'];
};

export type QueryGetWeAbrechnungsdefinitionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetWeAbrechnungsdefinitionTemplateArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetWeAbrechnungsdefinitionTemplateVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  searchInHistory?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetWeAbrechnungsdefinitionTemplateVersionListArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
};

export type QueryGetWeAbrechnungsdefinitionVersionArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Scalars['ID']['input'];
  searchInHistory?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetWeAbrechnungsdefinitionVersionListArgs = {
  abrechnungsdefinitionId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetWeAndMietVertragListArgs = {
  objektId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<VertragOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['ID']['input']>;
  vertragsartList?: InputMaybe<Array<InputMaybe<Vertragsart>>>;
  vertragspartnerId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetWeRechnungsAusstellerListArgs = {
  objektId: Scalars['ID']['input'];
};

export type QueryGetWizardListArgs = {
  createTsFromInclusive?: InputMaybe<Scalars['String']['input']>;
  createTsToInclusive?: InputMaybe<Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  order?: InputMaybe<WizardOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  showCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  statusList?: InputMaybe<Array<WizardStatus>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetZahlungsverlaufArgs = {
  heVertragId: Scalars['ID']['input'];
  objektId: Scalars['String']['input'];
};

export type QueryGetZinslisteArgs = {
  objektId: Scalars['ID']['input'];
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
  zinslisteId: Scalars['ID']['input'];
};

export type QueryGetZinslisteDeviationListArgs = {
  objektId: Scalars['ID']['input'];
  zinslisteId: Scalars['ID']['input'];
};

export type QueryGetZinslisteGenerierlaufArgs = {
  generierlaufId: Scalars['ID']['input'];
};

export type QueryGetZinslisteGenerierlaufEntryListArgs = {
  exitCodeList?: InputMaybe<Array<GenerierlaufEntryExitCode>>;
  generierlaufId: Scalars['ID']['input'];
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  order?: InputMaybe<ZinslisteGenerierlaufOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetZinslisteListByObjektIdArgs = {
  objektId: Scalars['ID']['input'];
  order?: InputMaybe<ZinslisteOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetZinslisteListForFirmendatenArgs = {
  bruttoFrom?: InputMaybe<Scalars['Float']['input']>;
  bruttoTo?: InputMaybe<Scalars['Float']['input']>;
  erstellDatumBisInklusive?: InputMaybe<Scalars['String']['input']>;
  erstellDatumVonInklusive?: InputMaybe<Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nettoFrom?: InputMaybe<Scalars['Float']['input']>;
  nettoTo?: InputMaybe<Scalars['Float']['input']>;
  objektId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<ZinslisteOrderBy>;
  orderDir?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ZinslisteStatus>;
  vorschreibungsperiodeFrom?: InputMaybe<Scalars['String']['input']>;
  vorschreibungsperiodeTo?: InputMaybe<Scalars['String']['input']>;
  zinslisteIdList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryGetZinslisteVerarbeitungsstandArgs = {
  objektId: Scalars['ID']['input'];
  zinslisteId: Scalars['ID']['input'];
};

export type QueryVerifyEmailChangeTokenArgs = {
  token: Scalars['String']['input'];
};

export type QueryVerifyPasswordResetTokenArgs = {
  token: Scalars['String']['input'];
};

export type ReceivedBankTransaction = {
  amount: Scalars['Float']['output'];
  booked: Scalars['Boolean']['output'];
  bookingDate?: Maybe<Scalars['String']['output']>;
  bookingSuggestionIdList: Array<Scalars['String']['output']>;
  buchungskreisRechtstraeger?: Maybe<PaymentRechtstraeger>;
  charges?: Maybe<Scalars['Float']['output']>;
  chargesInSeparateEntry?: Maybe<Scalars['Boolean']['output']>;
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  creditor?: Maybe<Scalars['String']['output']>;
  creditorIban?: Maybe<Scalars['String']['output']>;
  dataCarrierId: Scalars['String']['output'];
  dataCarrierName: Scalars['String']['output'];
  debtor?: Maybe<Scalars['String']['output']>;
  debtorIban?: Maybe<Scalars['String']['output']>;
  endToEndId?: Maybe<Scalars['String']['output']>;
  entryReference?: Maybe<Scalars['String']['output']>;
  noted: Scalars['Boolean']['output'];
  payee?: Maybe<PaymentRechtstraeger>;
  paymentReferenceText?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<PaymentTypeTuple>;
  purposeOfUseText?: Maybe<Scalars['String']['output']>;
  receivedBankTransactionId: Scalars['ID']['output'];
  requestedExecutionDate?: Maybe<Scalars['String']['output']>;
  reversal: Scalars['Boolean']['output'];
  statementNumber: Scalars['String']['output'];
  status: ReceivedBankTransactionStatusTuple;
  transactionType: TransactionTypeTuple;
  valid?: Maybe<Scalars['Boolean']['output']>;
  valueDate?: Maybe<Scalars['String']['output']>;
};

export type ReceivedBankTransactionEntryListPage = {
  contentList: Array<ReceivedBankTransactionListEntry>;
  page: Page;
};

export type ReceivedBankTransactionEntryListPageResponse = {
  data: ReceivedBankTransactionEntryListPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ReceivedBankTransactionListEntry = {
  amount: Scalars['Float']['output'];
  assignPaymentAllowed: Scalars['Boolean']['output'];
  availableAmount?: Maybe<Scalars['Float']['output']>;
  bankStatementIban: Scalars['String']['output'];
  booked: Scalars['Boolean']['output'];
  bookingDate?: Maybe<Scalars['String']['output']>;
  bookingSuggestionFibuInfoList: Array<BookingSuggestionFibuInfo>;
  charges?: Maybe<Scalars['Float']['output']>;
  chargesInSeparateEntry?: Maybe<Scalars['Boolean']['output']>;
  countBookingSuggestionClearingSuggestion?: Maybe<Scalars['Int']['output']>;
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  creditor?: Maybe<Scalars['String']['output']>;
  creditorIban?: Maybe<Scalars['String']['output']>;
  dataCarrierId: Scalars['String']['output'];
  dataCarrierName: Scalars['String']['output'];
  debtor?: Maybe<Scalars['String']['output']>;
  debtorIban?: Maybe<Scalars['String']['output']>;
  endToEndId?: Maybe<Scalars['String']['output']>;
  entryReference?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  noted: Scalars['Boolean']['output'];
  payment?: Maybe<PaymentInfo>;
  paymentReferenceText?: Maybe<Scalars['String']['output']>;
  purposeOfUseText?: Maybe<Scalars['String']['output']>;
  receivedBankTransactionId: Scalars['ID']['output'];
  reversal: Scalars['Boolean']['output'];
  statementNumber: Scalars['String']['output'];
  status: ReceivedBankTransactionStatusTuple;
  transactionType: TransactionTypeTuple;
  valid?: Maybe<Scalars['Boolean']['output']>;
  valueDate?: Maybe<Scalars['String']['output']>;
  warning?: Maybe<Scalars['String']['output']>;
};

export enum ReceivedBankTransactionOrderBy {
  Amount = 'AMOUNT',
  AvailableAmount = 'AVAILABLE_AMOUNT',
  BankstatementIban = 'BANKSTATEMENT_IBAN',
  Booked = 'BOOKED',
  BookingDate = 'BOOKING_DATE',
  Charges = 'CHARGES',
  ChargesInSeparateEntry = 'CHARGES_IN_SEPARATE_ENTRY',
  CountBookingSuggestionClearingSuggestion = 'COUNT_BOOKING_SUGGESTION_CLEARING_SUGGESTION',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  Creditor = 'CREDITOR',
  CreditorIban = 'CREDITOR_IBAN',
  DataCarrierName = 'DATA_CARRIER_NAME',
  Debtor = 'DEBTOR',
  DebtorIban = 'DEBTOR_IBAN',
  EndToEndId = 'END_TO_END_ID',
  Index = 'INDEX',
  Noted = 'NOTED',
  Payment = 'PAYMENT',
  PaymentReferenceText = 'PAYMENT_REFERENCE_TEXT',
  PurposeOfUseText = 'PURPOSE_OF_USE_TEXT',
  Reversal = 'REVERSAL',
  StatementNumber = 'STATEMENT_NUMBER',
  Status = 'STATUS',
  TransactionType = 'TRANSACTION_TYPE',
  Valid = 'VALID',
  ValueDate = 'VALUE_DATE',
}

export type ReceivedBankTransactionResponse = {
  data: ReceivedBankTransaction;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum ReceivedBankTransactionStatus {
  Archived = 'ARCHIVED',
  Assigned = 'ASSIGNED',
  Created = 'CREATED',
}

export type ReceivedBankTransactionStatusListResponse = {
  data: Array<ReceivedBankTransactionStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ReceivedBankTransactionStatusTuple = {
  text: Scalars['String']['output'];
  value: ReceivedBankTransactionStatus;
};

export type ReceivedBankTransactionSummary = {
  sumAmount?: Maybe<Scalars['Float']['output']>;
  sumCharges?: Maybe<Scalars['Float']['output']>;
};

export type ReceivedBankTransactionSummaryResponse = {
  data: ReceivedBankTransactionSummary;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ReceivedBankTransactionVerbuchenGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type ReceivedBankTransactionVerbuchenGenerierlaufBookingInfo = {
  buchungType: PaymentBuchungTypeTuple;
  fibuBuchungId?: Maybe<Scalars['String']['output']>;
};

export type ReceivedBankTransactionVerbuchenGenerierlaufCreateInput = {
  buchungsdatum: Scalars['String']['input'];
};

export type ReceivedBankTransactionVerbuchenGenerierlaufListEntry = {
  amount: Scalars['Float']['output'];
  booked: Scalars['Boolean']['output'];
  bookingInfoList: Array<ReceivedBankTransactionVerbuchenGenerierlaufBookingInfo>;
  buchungskreisRechtstraeger: PaymentRechtstraeger;
  charges?: Maybe<Scalars['Float']['output']>;
  chargesInSeparateEntry?: Maybe<Scalars['Boolean']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId: Scalars['String']['output'];
  creditor?: Maybe<Scalars['String']['output']>;
  creditorIban?: Maybe<Scalars['String']['output']>;
  currentStep?: Maybe<Scalars['Int']['output']>;
  dataCarrierCamtId: Scalars['String']['output'];
  dataCarrierCamtName: Scalars['String']['output'];
  debtor?: Maybe<Scalars['String']['output']>;
  debtorIban?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  exitCode: GenerierlaufEntryExitCodeTuple;
  generierlaufEntryId: Scalars['String']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  payee?: Maybe<PaymentRechtstraeger>;
  paymentDate?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentReferenceText?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<PaymentTypeTuple>;
  purposeOfUseText?: Maybe<Scalars['String']['output']>;
  retry: Scalars['Boolean']['output'];
  reversal: Scalars['Boolean']['output'];
  statementNumber: Scalars['String']['output'];
  status: ReceivedBankTransactionStatusTuple;
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  transactionType: TransactionTypeTuple;
  valueDate?: Maybe<Scalars['String']['output']>;
  warningList: Array<WarningData>;
};

export type ReceivedBankTransactionVerbuchenGenerierlaufListEntryPage = {
  contentList: Array<ReceivedBankTransactionVerbuchenGenerierlaufListEntry>;
  page: Page;
};

export type ReceivedBankTransactionVerbuchenGenerierlaufListEntryPageResponse = {
  data: ReceivedBankTransactionVerbuchenGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum ReceivedBankTransactionVerbuchenGenerierlaufOrderBy {
  Amount = 'AMOUNT',
  Booked = 'BOOKED',
  Charges = 'CHARGES',
  ChargesInSeparateEntry = 'CHARGES_IN_SEPARATE_ENTRY',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  Creditor = 'CREDITOR',
  CreditorIban = 'CREDITOR_IBAN',
  DataCarrierName = 'DATA_CARRIER_NAME',
  Debtor = 'DEBTOR',
  DebtorIban = 'DEBTOR_IBAN',
  PaymentReferenceText = 'PAYMENT_REFERENCE_TEXT',
  PaymentType = 'PAYMENT_TYPE',
  PurposeOfUseText = 'PURPOSE_OF_USE_TEXT',
  ReceivedBankTransactionStatus = 'RECEIVED_BANK_TRANSACTION_STATUS',
  Reversal = 'REVERSAL',
  StatementNumber = 'STATEMENT_NUMBER',
  Status = 'STATUS',
  TransactionType = 'TRANSACTION_TYPE',
  ValueDate = 'VALUE_DATE',
}

export type ReceivedBankTransactionVerbuchenGenerierlaufResponse = {
  data: ReceivedBankTransactionVerbuchenGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RechnungsAussteller = {
  bankDetailsList: Array<BankDetails>;
  bankSettings: BankSettings;
  bkAbrechnungsdefinition?: Maybe<BkAbrechnungsdefinition>;
  buchungskreis: Scalars['Boolean']['output'];
  heAbrechnungsdefinition?: Maybe<HeAbrechnungsdefinition>;
  mahndefinition?: Maybe<Mahndefinition>;
  mahndefinitionId?: Maybe<Scalars['String']['output']>;
  objektList: Array<ObjektBase>;
  subAdministrationAbrechnungsdefinition?: Maybe<SubAdministrationAbrechnungsdefinition>;
  subAdministrationSettings?: Maybe<SubAdministrationSettings>;
  weAbrechnungsdefinition?: Maybe<WeAbrechnungsdefinition>;
};

export type RechnungsAusstellerResponse = {
  data: RechnungsAussteller;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Rechtstraeger = {
  bankDetailsList: Array<BankDetails>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fibuKontoCreatable: Scalars['Boolean']['output'];
  kundennummer: Scalars['String']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  personenbezugList: Array<Personenbezug>;
  postIt?: Maybe<PostIt>;
  rechtstraegerId: Scalars['ID']['output'];
  rechtstraegerSteuerpflicht?: Maybe<RechtstraegerSteuerpflicht>;
  salutation?: Maybe<SalutationTuple>;
  sprache: SpracheTuple;
  status: RechtstraegerStatusTuple;
  tagList: Array<Scalars['String']['output']>;
  type: RechtstraegerTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragSummary?: Maybe<VertragSummary>;
  warningList: Array<ResponseDataWarning>;
};

export type RechtstraegerAndEmail = {
  emailContact: Contact;
  rechtstraeger: RechtstraegerBase;
};

export type RechtstraegerAndEmailListResponse = {
  data: Array<RechtstraegerAndEmail>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RechtstraegerAssignmentInput = {
  rechtstraegerId?: InputMaybe<Scalars['ID']['input']>;
};

export type RechtstraegerBankDetailsCreateInput = {
  accountHolder: Scalars['String']['input'];
  bankname: Scalars['String']['input'];
  bic: Scalars['String']['input'];
  createFibuKonto?: InputMaybe<Scalars['Boolean']['input']>;
  iban: Scalars['String']['input'];
};

export type RechtstraegerBankDetailsUpdateInput = {
  accountHolder: Scalars['String']['input'];
  bankname: Scalars['String']['input'];
  bic: Scalars['String']['input'];
  iban: Scalars['String']['input'];
};

export type RechtstraegerBankSettingsUpdateInput = {
  sepaCreditorId?: InputMaybe<Scalars['String']['input']>;
};

export type RechtstraegerBase = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  rechtstraegerId: Scalars['ID']['output'];
  status: RechtstraegerStatusTuple;
  type: RechtstraegerTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type RechtstraegerBaseListResponse = {
  data: Array<RechtstraegerBase>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RechtstraegerContactListEntry = {
  contactList: Array<Contact>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  rechtstraegerId: Scalars['String']['output'];
  status: RechtstraegerStatusTuple;
  type: RechtstraegerTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type RechtstraegerContactListEntryPage = {
  contentList: Array<RechtstraegerContactListEntry>;
  page: Page;
};

export type RechtstraegerContactListEntryPageResponse = {
  data: RechtstraegerContactListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RechtstraegerInfoFeldListResponse = {
  data: Array<AbstractRechtstraegerInfoFeld>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RechtstraegerInfoFeldPersoneninfos = AbstractRechtstraegerInfoFeld & {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  geburtsort?: Maybe<Scalars['String']['output']>;
  identitaetsnachweis?: Maybe<Scalars['String']['output']>;
  kundengruppeList?: Maybe<Array<Scalars['String']['output']>>;
  merkmalList: Array<Scalars['String']['output']>;
  postIt?: Maybe<PostIt>;
  rechtstraegerInfoFeldId: Scalars['ID']['output'];
  staatsbuergerschaft?: Maybe<Scalars['String']['output']>;
  status: InfoFeldStatusTuple;
  type: RechtstraegerInfoFeldTypeTuple;
  warningList: Array<ResponseDataWarning>;
};

export type RechtstraegerInfoFeldResponse = {
  data: AbstractRechtstraegerInfoFeld;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum RechtstraegerInfoFeldType {
  Personeninfos = 'PERSONENINFOS',
}

export type RechtstraegerInfoFeldTypeTuple = {
  text: Scalars['String']['output'];
  value: RechtstraegerInfoFeldType;
};

export type RechtstraegerListEntry = {
  buchungskreisId?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  kundennummer: Scalars['String']['output'];
  kurzBezeichnung: Scalars['String']['output'];
  rechtstraegerId: Scalars['ID']['output'];
  salutation?: Maybe<SalutationTuple>;
  status: RechtstraegerStatusTuple;
  tagList: Array<Scalars['String']['output']>;
  type: RechtstraegerTypeTuple;
  warningList: Array<ResponseDataWarning>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type RechtstraegerListEntryPage = {
  contentList: Array<RechtstraegerListEntry>;
  page: Page;
};

export type RechtstraegerListEntryPageResponse = {
  data: RechtstraegerListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RechtstraegerListResponse = {
  data: Array<Rechtstraeger>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum RechtstraegerOrderBy {
  City = 'CITY',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  Kundennummer = 'KUNDENNUMMER',
  KurzBezeichnung = 'KURZ_BEZEICHNUNG',
  Salutation = 'SALUTATION',
  Type = 'TYPE',
  ZipCode = 'ZIP_CODE',
}

export type RechtstraegerResponse = {
  data: Rechtstraeger;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum RechtstraegerRolle {
  ObjektRechnungsAussteller = 'OBJEKT_RECHNUNGS_AUSSTELLER',
  Vertragspartner = 'VERTRAGSPARTNER',
  WeRechnungsAussteller = 'WE_RECHNUNGS_AUSSTELLER',
}

export type RechtstraegerRolleListResponse = {
  data: Array<RechtstraegerRolle>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum RechtstraegerStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
  Gesperrt = 'GESPERRT',
}

export type RechtstraegerStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: RechtstraegerStatus;
};

export type RechtstraegerSteuerpflicht = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  historicizedList?: Maybe<Array<RechtstraegerSteuerpflicht>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  rechtstraegerSteuerpflichtId: Scalars['ID']['output'];
  steuerpflicht: SteuerpflichtTuple;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type RechtstraegerSteuerpflichtListResponse = {
  data: Array<RechtstraegerSteuerpflicht>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RechtstraegerSteuerpflichtResponse = {
  data: RechtstraegerSteuerpflicht;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum RechtstraegerType {
  Firma = 'FIRMA',
  NatuerlichePerson = 'NATUERLICHE_PERSON',
  PersonenGemeinschaft = 'PERSONEN_GEMEINSCHAFT',
}

export type RechtstraegerTypeResponse = {
  data: RechtstraegerTypeTuple;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RechtstraegerTypeTuple = {
  text: Scalars['String']['output'];
  value: RechtstraegerType;
};

export type RechtstraegerVertragspartner = {
  mahnsperre: Scalars['Boolean']['output'];
  mahnsperreKommentar?: Maybe<Scalars['String']['output']>;
};

export type RechtstraegerVertragspartnerResponse = {
  data: RechtstraegerVertragspartner;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RecipientRechtstraeger = {
  kurzBezeichnung: Scalars['String']['output'];
  rechtstraegerId: Scalars['String']['output'];
};

export type RepFondsEinstellung = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  historicizedList?: Maybe<Array<RepFondsEinstellung>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  kontenZuweisungList: Array<RepFondsEinstellungKontenZuweisung>;
  lastUpdateTs: Scalars['String']['output'];
  repFondsEinstellungId: Scalars['ID']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  versionIndex: Scalars['Int']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type RepFondsEinstellungCreateInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  kontenZuweisungList: Array<RepFondsEinstellungKontenZuweisungCreateInput>;
  validFrom: Scalars['String']['input'];
};

export type RepFondsEinstellungKontenZuweisung = {
  aufteilungsschluessel: Aufteilungsschluessel;
  aufwandsKontoList: Array<AppKonto>;
  repFondsEinstellungKontenZuweisungId: Scalars['String']['output'];
  repFondsKonto: AppKonto;
};

export type RepFondsEinstellungKontenZuweisungCreateInput = {
  aufteilungsschluesselId: Scalars['String']['input'];
  aufwandsKontoIdList: Array<Scalars['String']['input']>;
  repFondsKontoId: Scalars['String']['input'];
};

export type RepFondsEinstellungKontenZuweisungUpdateInput = {
  aufteilungsschluesselId: Scalars['String']['input'];
  aufwandsKontoIdList: Array<Scalars['String']['input']>;
  repFondsEinstellungKontenZuweisungId?: InputMaybe<Scalars['String']['input']>;
  repFondsKontoId: Scalars['String']['input'];
};

export type RepFondsEinstellungListResponse = {
  data: Array<RepFondsEinstellung>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RepFondsEinstellungResponse = {
  data: RepFondsEinstellung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RepFondsEinstellungUpdateInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  kontenZuweisungList: Array<RepFondsEinstellungKontenZuweisungUpdateInput>;
  validFrom: Scalars['String']['input'];
};

export type RepFondsErloesKontenZuweisung = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  erloesKontoList: Array<AppKonto>;
  fibuBankAccount: BankDetails;
  repFondsErloesKontenZuweisungId: Scalars['ID']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type RepFondsErloesKontenZuweisungInput = {
  erloesKontoIdList: Array<Scalars['String']['input']>;
  fibuBankAccountId: Scalars['String']['input'];
};

export type RepFondsErloesKontenZuweisungListResponse = {
  data: Array<RepFondsErloesKontenZuweisung>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type RepFondsErloesKontenZuweisungResponse = {
  data: RepFondsErloesKontenZuweisung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ReportLogos = {
  createTs?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  logoGrossAlignment?: Maybe<DocumentHeaderAndFooterImageAlignmentTuple>;
  logoGrossFileId?: Maybe<Scalars['String']['output']>;
  logoKleinAlignment?: Maybe<DocumentHeaderAndFooterImageAlignmentTuple>;
  logoKleinFileId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type ReportLogosResponse = {
  data: ReportLogos;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ResponseDataWarning = {
  attribute?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ResponseEntity = {
  body?: Maybe<ResponseEntity_Body>;
  statusCode?: Maybe<Scalars['String']['output']>;
  statusCodeValue?: Maybe<Scalars['Int']['output']>;
};

export type ResponseEntity_Body = {
  /** default field */
  empty?: Maybe<Scalars['String']['output']>;
};

export type ResponseWithoutData = {
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Rolle = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rolleId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type SaldenlisteKonto = {
  konto: Konto;
  saldoEB: Scalars['Float']['output'];
  saldoEnd: Scalars['Float']['output'];
  saldoGesamt: SaldoForPeriode;
  saldoMonat: SaldoForPeriode;
};

export enum SaldenlisteKontoType {
  Debitor = 'DEBITOR',
  Kreditor = 'KREDITOR',
  Sachkonto = 'SACHKONTO',
}

export type SaldenlisteKontoTypeListResponse = {
  data: Array<SaldenlisteKontoTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SaldenlisteKontoTypeTuple = {
  text: Scalars['String']['output'];
  value: SaldenlisteKontoType;
};

export enum SaldenlisteOrderBy {
  KontoCreateTs = 'KONTO_CREATE_TS',
  KontoNummer = 'KONTO_NUMMER',
  SaldoEb = 'SALDO_EB',
  SaldoEnd = 'SALDO_END',
  SaldoGesamt = 'SALDO_GESAMT',
  SaldoMonat = 'SALDO_MONAT',
}

export type SaldenlistePage = {
  contentList: Array<SaldenlisteKonto>;
  page: Page;
};

export type SaldenlistePageResponse = {
  data: SaldenlistePage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SaldenlisteSummaryResponse = {
  data: SaldenlisteYearSummary;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SaldenlisteYearSummary = {
  saldoEB: Scalars['Float']['output'];
  saldoForMonatList: Array<SaldoForMonat>;
};

export type Saldo = {
  konto: Konto;
  saldo: Scalars['Float']['output'];
};

export type SaldoForMonat = {
  monat: Scalars['String']['output'];
  saldo: Scalars['Float']['output'];
};

export type SaldoForPeriode = {
  bis: Scalars['String']['output'];
  saldo: Scalars['Float']['output'];
  von: Scalars['String']['output'];
};

export type SaldoResponse = {
  data: Saldo;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum Salutation {
  Company = 'COMPANY',
  Madame = 'MADAME',
  Sir = 'SIR',
  SirAndMadame = 'SIR_AND_MADAME',
}

export type SalutationTuple = {
  letterSalutation: Scalars['String']['output'];
  text: Scalars['String']['output'];
  value: Salutation;
};

export type SepaMandat = {
  fileId?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  firmendaten?: Maybe<FirmendatenBase>;
  rechnungsAussteller?: Maybe<RechtstraegerBase>;
  sepaMandatId: Scalars['String']['output'];
  sepaMandatReference: Scalars['String']['output'];
  signatureDate?: Maybe<Scalars['String']['output']>;
  status: SepaMandatStatusTuple;
  warningList: Array<WarningData>;
};

export type SepaMandatCreateInput = {
  rechnungsAusstellerFirmendatenId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerRechtstraegerId?: InputMaybe<Scalars['String']['input']>;
  sepaMandatReference?: InputMaybe<Scalars['String']['input']>;
};

export type SepaMandatResponse = {
  data: SepaMandat;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export enum SepaMandatStatus {
  Inactive = 'INACTIVE',
  Signed = 'SIGNED',
  Unsigned = 'UNSIGNED',
}

export type SepaMandatStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: SepaMandatStatus;
};

export type SetAbrechnungsdefinitionForRechtstraegerInput = {
  abrechnungsdefinitionId: Scalars['String']['input'];
  type: AbrechnungsdefinitionType;
};

export type SetMahndefinitionForRechtstraegerInput = {
  mahndefinitionId: Scalars['String']['input'];
};

export type SetMahnsperreForRechtstraegerInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  mahnsperre: Scalars['Boolean']['input'];
};

export type SetVerwendungForKontoForRechtstraegerInput = {
  repFonds: Scalars['Boolean']['input'];
};

export type SingleInvoice = {
  abgrenzungsBuchung: Scalars['Boolean']['output'];
  abgrenzungsBuchungsdatum?: Maybe<Scalars['String']['output']>;
  ausstellerBezeichnung: Scalars['String']['output'];
  ausstellerRechtstraegerId: Scalars['String']['output'];
  basis: IncomingInvoiceBasisTuple;
  belegId: Scalars['String']['output'];
  billingType: VerrechnungsartTuple;
  brutto: Scalars['Float']['output'];
  buchungErrorList: Array<ErrorData>;
  buchungIdList: Array<Scalars['String']['output']>;
  buchungPositionList: Array<IncomingInvoiceBuchungPosition>;
  buchungsanweisungIdList: Array<Scalars['String']['output']>;
  buchungsanweisungListEntryDataList: Array<OrderBuchungsanweisungListEntry>;
  buchungsanweisungStatus?: Maybe<OrderBuchungsanweisungStatusTuple>;
  buchungsdatum: Scalars['String']['output'];
  buchungskreisId?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  issuerBankDetailsIban: Scalars['String']['output'];
  issuerBankDetailsId: Scalars['String']['output'];
  kundenNummer?: Maybe<Scalars['String']['output']>;
  netto: Scalars['Float']['output'];
  objektBezeichnung?: Maybe<Scalars['String']['output']>;
  objektId?: Maybe<Scalars['String']['output']>;
  offenerBetrag?: Maybe<Scalars['Float']['output']>;
  paid: Scalars['Boolean']['output'];
  payeeBankDetailsIban: Scalars['String']['output'];
  payeeBankDetailsId: Scalars['String']['output'];
  payeeBezeichnung: Scalars['String']['output'];
  payeeRechtstraegerId: Scalars['String']['output'];
  paymentCreatable: Scalars['Boolean']['output'];
  paymentIdList: Array<Scalars['String']['output']>;
  paymentMethod: PaymentMethodTuple;
  paymentReferenceText?: Maybe<Scalars['String']['output']>;
  purposeOfUseText?: Maybe<Scalars['String']['output']>;
  rechnungsDatum: Scalars['String']['output'];
  rechnungsNummer: Scalars['String']['output'];
  requestedPaymentDate?: Maybe<Scalars['String']['output']>;
  sepaMandatReference?: Maybe<Scalars['String']['output']>;
  status: IncomingInvoiceStatusTuple;
  steuerzeileList: Array<IncomingInvoiceSteuerzeile>;
  type: InvoiceType;
  warningList: Array<WarningData>;
};

export type SingleInvoiceCreateInput = {
  abgrenzungsBuchung: Scalars['Boolean']['input'];
  abgrenzungsBuchungsdatum?: InputMaybe<Scalars['String']['input']>;
  ausstellerRechtstraegerId: Scalars['String']['input'];
  basis: IncomingInvoiceBasis;
  billingType: Verrechnungsart;
  brutto: Scalars['Float']['input'];
  buchungPositionList: Array<IncomingInvoiceBuchungPositionCreateInput>;
  buchungsdatum: Scalars['String']['input'];
  dueDate: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  issuerBankDetailsId: Scalars['String']['input'];
  kundenNummer?: InputMaybe<Scalars['String']['input']>;
  netto: Scalars['Float']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  payeeBankDetailsId: Scalars['String']['input'];
  payeeRechtstraegerId: Scalars['String']['input'];
  paymentMethod: PaymentMethod;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  rechnungsDatum: Scalars['String']['input'];
  rechnungsNummer: Scalars['String']['input'];
  requestedPaymentDate?: InputMaybe<Scalars['String']['input']>;
  sepaMandatReference?: InputMaybe<Scalars['String']['input']>;
  steuerzeileList: Array<IncomingInvoiceSteuerzeileInput>;
  type: InvoiceType;
};

export type SingleInvoiceResponse = {
  data: SingleInvoice;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SingleInvoiceUpdateInput = {
  abgrenzungsBuchung: Scalars['Boolean']['input'];
  abgrenzungsBuchungsdatum?: InputMaybe<Scalars['String']['input']>;
  ausstellerRechtstraegerId: Scalars['String']['input'];
  basis: IncomingInvoiceBasis;
  brutto: Scalars['Float']['input'];
  buchungPositionList: Array<IncomingInvoiceBuchungPositionUpdateInput>;
  buchungsdatum: Scalars['String']['input'];
  dueDate: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  issuerBankDetailsId: Scalars['String']['input'];
  kundenNummer?: InputMaybe<Scalars['String']['input']>;
  netto: Scalars['Float']['input'];
  objektId?: InputMaybe<Scalars['String']['input']>;
  payeeBankDetailsId: Scalars['String']['input'];
  payeeRechtstraegerId: Scalars['String']['input'];
  paymentMethod: PaymentMethod;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  rechnungsDatum: Scalars['String']['input'];
  rechnungsNummer: Scalars['String']['input'];
  requestedPaymentDate?: InputMaybe<Scalars['String']['input']>;
  sepaMandatReference?: InputMaybe<Scalars['String']['input']>;
  steuerzeileList: Array<IncomingInvoiceSteuerzeileInput>;
  storno?: InputMaybe<IncomingInvoiceUpdateStornoInput>;
  type: InvoiceType;
};

export enum SollHaben {
  Haben = 'HABEN',
  Soll = 'SOLL',
}

export type SollHabenTuple = {
  text: Scalars['String']['output'];
  value: SollHaben;
};

export enum Sprache {
  De = 'DE',
  En = 'EN',
}

export type SpracheListResponse = {
  data: Array<SpracheTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SpracheTuple = {
  text: Scalars['String']['output'];
  value: Sprache;
};

export type StandingInvoice = {
  ausstellerBezeichnung: Scalars['String']['output'];
  ausstellerRechtstraegerId: Scalars['String']['output'];
  basis: IncomingInvoiceBasisTuple;
  belegId: Scalars['String']['output'];
  billingType: VerrechnungsartTuple;
  brutto: Scalars['Float']['output'];
  buchungIdList: Array<Scalars['String']['output']>;
  buchungskreisId?: Maybe<Scalars['String']['output']>;
  fileId: Scalars['String']['output'];
  issuerBankDetailsIban: Scalars['String']['output'];
  issuerBankDetailsId: Scalars['String']['output'];
  kundenNummer?: Maybe<Scalars['String']['output']>;
  netto: Scalars['Float']['output'];
  objektBezeichnung?: Maybe<Scalars['String']['output']>;
  objektId?: Maybe<Scalars['String']['output']>;
  offenerBetrag?: Maybe<Scalars['Float']['output']>;
  paid: Scalars['Boolean']['output'];
  partialAmountList: Array<StandingInvoicePartialAmount>;
  payeeBankDetailsIban: Scalars['String']['output'];
  payeeBankDetailsId: Scalars['String']['output'];
  payeeBezeichnung: Scalars['String']['output'];
  payeeRechtstraegerId: Scalars['String']['output'];
  paymentCreatable: Scalars['Boolean']['output'];
  paymentIdList: Array<Scalars['String']['output']>;
  paymentMethod: PaymentMethodTuple;
  paymentReferenceText?: Maybe<Scalars['String']['output']>;
  purposeOfUseText?: Maybe<Scalars['String']['output']>;
  rechnungsDatum: Scalars['String']['output'];
  rechnungsNummer: Scalars['String']['output'];
  sepaMandatReference?: Maybe<Scalars['String']['output']>;
  status: IncomingInvoiceStatusTuple;
  type: InvoiceType;
  vat: Scalars['Float']['output'];
  warningList: Array<WarningData>;
};

export type StandingInvoiceBuchungPreview = {
  buchung: OrderBuchungsanweisung;
  partialAmountIndex: Scalars['Int']['output'];
  partialAmountName: Scalars['String']['output'];
};

export type StandingInvoiceBuchungPreviewResponse = {
  data: Array<StandingInvoiceBuchungPreview>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type StandingInvoiceCreateInput = {
  ausstellerRechtstraegerId: Scalars['String']['input'];
  basis: IncomingInvoiceBasis;
  billingType: Verrechnungsart;
  fileId: Scalars['String']['input'];
  issuerBankDetailsId: Scalars['String']['input'];
  kundenNummer?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  partialAmountList: Array<StandingInvoicePartialAmountCreateInput>;
  payeeBankDetailsId: Scalars['String']['input'];
  payeeRechtstraegerId: Scalars['String']['input'];
  paymentMethod: PaymentMethod;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  rechnungsDatum: Scalars['String']['input'];
  rechnungsNummer: Scalars['String']['input'];
  sepaMandatReference?: InputMaybe<Scalars['String']['input']>;
  type: InvoiceType;
};

export type StandingInvoicePartialAmount = {
  brutto: Scalars['Float']['output'];
  buchungErrorList: Array<ErrorData>;
  buchungIdList: Array<Scalars['String']['output']>;
  buchungPositionList: Array<IncomingInvoiceBuchungPosition>;
  buchungsanweisungIdList: Array<Scalars['String']['output']>;
  buchungsanweisungListEntryDataList: Array<OrderBuchungsanweisungListEntry>;
  buchungsanweisungStatus?: Maybe<OrderBuchungsanweisungStatusTuple>;
  buchungsdatum: Scalars['String']['output'];
  dueDate: Scalars['String']['output'];
  netto: Scalars['Float']['output'];
  offenerBetrag?: Maybe<Scalars['Float']['output']>;
  paid: Scalars['Boolean']['output'];
  partialAmountId: Scalars['ID']['output'];
  paymentCreatable: Scalars['Boolean']['output'];
  paymentIdList: Array<Scalars['String']['output']>;
  requestedPaymentDate?: Maybe<Scalars['String']['output']>;
  steuerzeileList: Array<IncomingInvoiceSteuerzeile>;
};

export type StandingInvoicePartialAmountCreateInput = {
  brutto: Scalars['Float']['input'];
  buchungPositionList: Array<IncomingInvoiceBuchungPositionCreateInput>;
  buchungsdatum?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['String']['input'];
  netto: Scalars['Float']['input'];
  requestedPaymentDate?: InputMaybe<Scalars['String']['input']>;
  steuerzeileList: Array<IncomingInvoiceSteuerzeileInput>;
};

export type StandingInvoicePartialAmountUpdateInput = {
  brutto: Scalars['Float']['input'];
  buchungPositionList: Array<IncomingInvoiceBuchungPositionUpdateInput>;
  buchungsdatum?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['String']['input'];
  netto: Scalars['Float']['input'];
  partialAmountId?: InputMaybe<Scalars['String']['input']>;
  requestedPaymentDate?: InputMaybe<Scalars['String']['input']>;
  steuerzeileList: Array<IncomingInvoiceSteuerzeileInput>;
};

export type StandingInvoicePartialAmountUpdatePreview = {
  partialAmountAction: Scalars['String']['output'];
  partialAmountDetails: Scalars['String']['output'];
  partialAmountIndex: Scalars['Int']['output'];
};

export type StandingInvoicePartialAmountUpdatePreviewResponse = {
  data: Array<StandingInvoicePartialAmountUpdatePreview>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type StandingInvoiceResponse = {
  data: StandingInvoice;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type StandingInvoiceStornoBuchungPreview = {
  korrekturBuchungList: Array<StandingInvoiceBuchungPreview>;
  originalBuchungList: Array<StandingInvoiceBuchungPreview>;
  stornoBuchungList: Array<StandingInvoiceBuchungPreview>;
};

export type StandingInvoiceStornoBuchungPreviewResponse = {
  data: StandingInvoiceStornoBuchungPreview;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type StandingInvoiceUpdateInput = {
  ausstellerRechtstraegerId: Scalars['String']['input'];
  basis: IncomingInvoiceBasis;
  fileId: Scalars['String']['input'];
  issuerBankDetailsId: Scalars['String']['input'];
  kundenNummer?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  partialAmountList: Array<StandingInvoicePartialAmountUpdateInput>;
  payeeBankDetailsId: Scalars['String']['input'];
  payeeRechtstraegerId: Scalars['String']['input'];
  paymentMethod: PaymentMethod;
  paymentReferenceText?: InputMaybe<Scalars['String']['input']>;
  purposeOfUseText?: InputMaybe<Scalars['String']['input']>;
  rechnungsDatum: Scalars['String']['input'];
  rechnungsNummer: Scalars['String']['input'];
  sepaMandatReference?: InputMaybe<Scalars['String']['input']>;
  type: InvoiceType;
};

export enum Steuerpflicht {
  EchtSteuerbefreit = 'ECHT_STEUERBEFREIT',
  Steuerpflichtig = 'STEUERPFLICHTIG',
  UnechtSteuerbefreit = 'UNECHT_STEUERBEFREIT',
}

export type SteuerpflichtInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  steuerpflicht: Steuerpflicht;
  validFrom: Scalars['String']['input'];
};

export type SteuerpflichtListResponse = {
  data: Array<SteuerpflichtTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SteuerpflichtTuple = {
  text: Scalars['String']['output'];
  value: Steuerpflicht;
};

export type Steuertabelle = {
  steuerzeileList: Array<Steuerzeile>;
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  sumUst: Scalars['Float']['output'];
};

export type SteuertabelleResponse = {
  data: Steuertabelle;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Steuerzeile = {
  rechnungskennzeichen: Scalars['String']['output'];
  steuersatz: Scalars['Int']['output'];
  sumBrutto: Scalars['Float']['output'];
  sumNetto: Scalars['Float']['output'];
  sumUst: Scalars['Float']['output'];
};

export type StornoBuchungPreview = {
  korrekturBuchung?: Maybe<OrderBuchungsanweisung>;
  originalBuchungList: Array<OrderBuchungsanweisung>;
  stornoBuchungList: Array<OrderBuchungsanweisung>;
};

export type StornoBuchungPreviewResponse = {
  data: StornoBuchungPreview;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type StringListResponse = {
  data?: Maybe<Array<Scalars['String']['output']>>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type StringResponse = {
  data?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SubAbrAbrechnungskreis = {
  abrechnungskreisId: Scalars['String']['output'];
  abrechnungskreisType: AbrechnungskreisTypeTuple;
  bezeichnung: Scalars['String']['output'];
  kontoList: Array<SubAbrKonto>;
  subAbrechnungsdefinitionId: Scalars['String']['output'];
  subAbrechnungsdefinitionVersionId: Scalars['String']['output'];
  summe: Scalars['Float']['output'];
};

export type SubAbrAbrechnungskreisList = {
  abrechnungskreisList: Array<SubAbrAbrechnungskreis>;
  abrechnungskreisType: AbrechnungskreisTypeTuple;
  summe: Scalars['Float']['output'];
};

export type SubAbrBuchungszeile = {
  auftragId?: Maybe<Scalars['String']['output']>;
  belegDatum?: Maybe<Scalars['String']['output']>;
  belegFileId?: Maybe<Scalars['String']['output']>;
  belegId: Scalars['String']['output'];
  belegnummer?: Maybe<Scalars['String']['output']>;
  betrag: Scalars['Float']['output'];
  buchungIdList: Array<Scalars['String']['output']>;
  buchungskreisRechtstraeger: RechtstraegerBase;
  dueDate?: Maybe<Scalars['String']['output']>;
  sollHaben: SollHabenTuple;
  standingInvoicePartialAmountId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  umbuchung?: Maybe<Scalars['Boolean']['output']>;
  umgebucht?: Maybe<Scalars['Boolean']['output']>;
  ustVomAufwandSteuersatz?: Maybe<Scalars['Int']['output']>;
  vertragspartner?: Maybe<RechtstraegerBase>;
};

export type SubAbrKonto = {
  amountReducingType?: Maybe<AmountReducingTypeTuple>;
  bezeichnung: Scalars['String']['output'];
  buchungszeileList: Array<SubAbrBuchungszeile>;
  kontoId: Scalars['String']['output'];
  summe: Scalars['Float']['output'];
};

export type SubAbrOffenePostenList = {
  offenePostenList: Array<SubAbrOffenerPosten>;
  summe: Scalars['Float']['output'];
};

export type SubAbrOffenerPosten = {
  bestandseinheitList: Array<Bestandseinheit>;
  dueDate: Scalars['String']['output'];
  fibuKontoId: Scalars['String']['output'];
  fibuKontonummer: Scalars['String']['output'];
  letzteBuchungAm?: Maybe<Scalars['String']['output']>;
  offenePostenId: Scalars['String']['output'];
  offenerBetrag: Scalars['Float']['output'];
  vertragspartner: Rechtstraeger;
};

export type SubAbrZahlungsrueckstand = {
  bestandseinheit: BestandseinheitBase;
  konto: Konto;
  letzteBuchungAm?: Maybe<Scalars['String']['output']>;
  offenerBetrag: Scalars['Float']['output'];
  rechtstraeger: RechtstraegerBase;
};

export type SubAbrechnung = {
  abrechnungszeitraumBis: Scalars['String']['output'];
  abrechnungszeitraumVon: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable: Scalars['Boolean']['output'];
  objekt: Objekt;
  status: AbrechnungStatusTuple;
  subAbrechnungEigentuemerListEntryList: Array<SubAbrechnungEigentuemerListEntry>;
  subAbrechnungId: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type SubAbrechnungEigentuemer = {
  abrechnungsdefinitionType: AbrechnungsdefinitionTypeTuple;
  abrechnungszeitraumBis: Scalars['String']['output'];
  abrechnungszeitraumVon: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  buchungskreisId: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  eigentuemer: Rechtstraeger;
  fileId?: Maybe<Scalars['String']['output']>;
  guthaben: Scalars['Boolean']['output'];
  objekt: Objekt;
  offenePosten?: Maybe<SubAbrOffenePostenList>;
  saldo: Scalars['Float']['output'];
  saldovortrag: Scalars['Float']['output'];
  status: SubAbrechnungEigentuemerStatusTuple;
  subAbrechnungEigentuemerBestandseinheitList: Array<SubAbrechnungEigentuemerBestandseinheit>;
  subAbrechnungEigentuemerId: Scalars['String']['output'];
  subAbrechnungEigentuemerObjekt: SubAbrechnungEigentuemerObjekt;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
  zahlungsrueckstandList: Array<SubAbrZahlungsrueckstand>;
};

export type SubAbrechnungEigentuemerBestandseinheit = {
  ausgaben?: Maybe<SubAbrAbrechnungskreisList>;
  bestandseinheit: BestandseinheitBase;
  bestandseinheitErgebnis: Scalars['Float']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  eigentuemerverrechnung?: Maybe<SubAbrAbrechnungskreisList>;
  einnahmen?: Maybe<SubAbrAbrechnungskreisList>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vorsteuerkuerzung?: Maybe<SubAbrAbrechnungskreisList>;
  warningList: Array<ResponseDataWarning>;
};

export type SubAbrechnungEigentuemerListEntry = {
  abrechnungsdefinitionType: AbrechnungsdefinitionTypeTuple;
  abrechnungszeitraumBis: Scalars['String']['output'];
  abrechnungszeitraumVon: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  eigentuemer: Rechtstraeger;
  guthaben: Scalars['Boolean']['output'];
  saldo: Scalars['Float']['output'];
  status: SubAbrechnungEigentuemerStatusTuple;
  subAbrechnungEigentuemerId: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type SubAbrechnungEigentuemerObjekt = {
  ausgaben?: Maybe<SubAbrAbrechnungskreisList>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  eigentuemerverrechnung?: Maybe<SubAbrAbrechnungskreisList>;
  einnahmen?: Maybe<SubAbrAbrechnungskreisList>;
  fileId?: Maybe<Scalars['String']['output']>;
  infoAbrechnungskreisList: Array<AbrInfoAbrechnungskreis>;
  subAbrechnungEigentuemerObjektId: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vorsteuerkuerzung?: Maybe<SubAbrAbrechnungskreisList>;
  warningList: Array<ResponseDataWarning>;
};

export type SubAbrechnungEigentuemerResponse = {
  data: SubAbrechnungEigentuemer;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum SubAbrechnungEigentuemerStatus {
  Invalid = 'INVALID',
  ReviewError = 'REVIEW_ERROR',
  UnderReview = 'UNDER_REVIEW',
  Valid = 'VALID',
}

export type SubAbrechnungEigentuemerStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: SubAbrechnungEigentuemerStatus;
};

export type SubAbrechnungGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  fromInclusive: Scalars['String']['output'];
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  subAbrechnungId?: Maybe<Scalars['String']['output']>;
  toInclusive: Scalars['String']['output'];
  warningList: Array<WarningData>;
};

export type SubAbrechnungGenerierlaufListEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  eigentuemerId: Scalars['String']['output'];
  eigentuemerKurzBezeichnung: Scalars['String']['output'];
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  fromInclusive?: Maybe<Scalars['String']['output']>;
  generierlaufEntryId: Scalars['String']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung: Scalars['String']['output'];
  retry?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<SubAbrechnungEigentuemerStatusTuple>;
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  subAbrechnungDeleted?: Maybe<Scalars['Boolean']['output']>;
  subAbrechnungDeletedBy?: Maybe<Scalars['String']['output']>;
  subAbrechnungDeletedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  subAbrechnungDeletedTs?: Maybe<Scalars['String']['output']>;
  subAbrechnungEigentuemerId?: Maybe<Scalars['String']['output']>;
  subAbrechnungId?: Maybe<Scalars['String']['output']>;
  toInclusive?: Maybe<Scalars['String']['output']>;
  vorabrechnung: Scalars['Boolean']['output'];
  warningList: Array<WarningData>;
};

export type SubAbrechnungGenerierlaufListEntryPage = {
  contentList: Array<SubAbrechnungGenerierlaufListEntry>;
  page: Page;
};

export type SubAbrechnungGenerierlaufListEntryPageResponse = {
  data: SubAbrechnungGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum SubAbrechnungGenerierlaufOrderBy {
  Abrechnungsergebnis = 'ABRECHNUNGSERGEBNIS',
  AbrechnungStatus = 'ABRECHNUNG_STATUS',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  Eigentuemer = 'EIGENTUEMER',
  Objekt = 'OBJEKT',
  Status = 'STATUS',
  Zeitraum = 'ZEITRAUM',
}

export type SubAbrechnungGenerierlaufResponse = {
  data: SubAbrechnungGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SubAbrechnungResponse = {
  data: SubAbrechnung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SubAdministration = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deliveryAddress?: Maybe<Scalars['String']['output']>;
  deliveryAddressHaupt: Scalars['Boolean']['output'];
  deliveryAddressId?: Maybe<Scalars['String']['output']>;
  eigentuemerBezeichnung: Scalars['String']['output'];
  eigentuemerId: Scalars['String']['output'];
  from: Scalars['String']['output'];
  postIt?: Maybe<PostIt>;
  saldouebertrag: Scalars['Float']['output'];
  subAdministrationId: Scalars['String']['output'];
  to?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type SubAdministrationAbrechnungOutputOptions = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  displayKontonummer?: Maybe<Scalars['Boolean']['output']>;
  rueckstaendeAnzeigen: Scalars['Boolean']['output'];
  rueckstaendeInAbrechnungsergebnis: Scalars['Boolean']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type SubAdministrationAbrechnungOutputOptionsInput = {
  displayKontonummer?: InputMaybe<Scalars['Boolean']['input']>;
  rueckstaendeAnzeigen: Scalars['Boolean']['input'];
  rueckstaendeInAbrechnungsergebnis: Scalars['Boolean']['input'];
};

export type SubAdministrationAbrechnungsdefinition = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreis>;
  abrechnungsdefinitionId: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kontoAbrechnungskreisDataList: Array<KontoAbrechnungskreis>;
  status: AbrechnungsdefinitionStatusTuple;
  subAdministrationAbrechnungTextbausteinList: Array<Textbaustein>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type SubAdministrationAbrechnungsdefinitionCreateInput = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreisInput>;
  bezeichnung: Scalars['String']['input'];
  outputOptions: SubAdministrationAbrechnungOutputOptionsInput;
  subAdministrationAbrechnungTextbausteinList: Array<TextbausteinInput>;
  validFrom: Scalars['String']['input'];
};

export type SubAdministrationAbrechnungsdefinitionResponse = {
  data: SubAdministrationAbrechnungsdefinition;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SubAdministrationAbrechnungsdefinitionUpdateInput = {
  bezeichnung: Scalars['String']['input'];
};

export type SubAdministrationAbrechnungsdefinitionVersion = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreis>;
  abrechnungsdefinitionVersionId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kommentar?: Maybe<Scalars['String']['output']>;
  kontoAbrechnungskreisList: Array<KontoAbrechnungskreis>;
  lastUpdateTs: Scalars['String']['output'];
  outputOptions: SubAdministrationAbrechnungOutputOptions;
  subAdministrationAbrechnungTextbausteinList: Array<Textbaustein>;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type SubAdministrationAbrechnungsdefinitionVersionCreateInput = {
  abrechdefAbrechkreisList: Array<InputMaybe<AbrechdefAbrechkreisInput>>;
  kommentar?: InputMaybe<Scalars['String']['input']>;
  outputOptions: SubAdministrationAbrechnungOutputOptionsInput;
  subAdministrationAbrechnungTextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  validFrom: Scalars['String']['input'];
};

export type SubAdministrationAbrechnungsdefinitionVersionListEntry = {
  abrechnungsdefinitionVersionId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  historicizedList?: Maybe<Array<SubAdministrationAbrechnungsdefinitionVersionListEntry>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  textBausteineBefuellt: Scalars['Boolean']['output'];
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type SubAdministrationAbrechnungsdefinitionVersionListEntryResponse = {
  data: Array<SubAdministrationAbrechnungsdefinitionVersionListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SubAdministrationAbrechnungsdefinitionVersionResponse = {
  data: SubAdministrationAbrechnungsdefinitionVersion;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SubAdministrationAbrechnungsdefinitionVersionUpdateInput = {
  abrechdefAbrechkreisList: Array<InputMaybe<AbrechdefAbrechkreisInput>>;
  kommentar?: InputMaybe<Scalars['String']['input']>;
  kontoAbrechnungskreisList: Array<AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput>;
  outputOptions: SubAdministrationAbrechnungOutputOptionsInput;
  subAdministrationAbrechnungTextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  validFrom: Scalars['String']['input'];
};

export type SubAdministrationListEntry = {
  countBestandseinheit: Scalars['Int']['output'];
  countSubAdministration: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deliveryAddress?: Maybe<Scalars['String']['output']>;
  deliveryAddressHaupt: Scalars['Boolean']['output'];
  eigentuemerBezeichnung: Scalars['String']['output'];
  eigentuemerId: Scalars['String']['output'];
  from: Scalars['String']['output'];
  saldouebertrag: Scalars['Float']['output'];
  subAdministrationId: Scalars['String']['output'];
  to?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type SubAdministrationListEntryResponse = {
  data: Array<SubAdministrationListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SubAdministrationResponse = {
  data: SubAdministration;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type SubAdministrationSettings = {
  paymentClearsIncomingInvoice: Scalars['Boolean']['output'];
  subAdministrationSettingsId: Scalars['String']['output'];
};

export type SubAdministrationUpdateInput = {
  deliveryAddressHaupt: Scalars['Boolean']['input'];
  deliveryAddressId?: InputMaybe<Scalars['String']['input']>;
  saldouebertrag: Scalars['Float']['input'];
};

export type Subscription = {
  _empty?: Maybe<Scalars['String']['output']>;
  dataChanged: DataChangedEvent;
  getNotification: PubSubNotification;
};

export type SubscriptionGetNotificationArgs = {
  mitarbeiterId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SyncEbicsUserGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  entryList: Array<SyncEbicsUserGenerierlaufEntry>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type SyncEbicsUserGenerierlaufEntry = {
  ebicsUser: EbicsUser;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  warningList: Array<WarningData>;
};

export type SyncEbicsUserGenerierlaufResponse = {
  data: SyncEbicsUserGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Textbaustein = {
  index: Scalars['Int']['output'];
  platzhalterList: Array<Platzhalter>;
  textbausteinList: Array<Textbaustein>;
  type: TextbausteinTypeTuple;
  value?: Maybe<Scalars['String']['output']>;
};

export type TextbausteinInput = {
  type: TextbausteinType;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type TextbausteinResponse = {
  data: Textbaustein;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum TextbausteinType {
  Abrechnungstitel = 'ABRECHNUNGSTITEL',
  BelegnummernkreisDefinitionPostfix = 'BELEGNUMMERNKREIS_DEFINITION_POSTFIX',
  BelegnummernkreisDefinitionPrefix = 'BELEGNUMMERNKREIS_DEFINITION_PREFIX',
  BelegFuss = 'BELEG_FUSS',
  BelegKopf = 'BELEG_KOPF',
  Einleitungstext = 'EINLEITUNGSTEXT',
  EmailDeliveryDefinitionBody = 'EMAIL_DELIVERY_DEFINITION_BODY',
  EmailDeliveryDefinitionSubject = 'EMAIL_DELIVERY_DEFINITION_SUBJECT',
  Ergebnistext = 'ERGEBNISTEXT',
  GhMitEinzug = 'GH_MIT_EINZUG',
  GhMitUewBankvBekannt = 'GH_MIT_UEW_BANKV_BEKANNT',
  GhMitUewBankvUnbekannt = 'GH_MIT_UEW_BANKV_UNBEKANNT',
  IndexationInformation = 'INDEXATION_INFORMATION',
  Kleinunternehmer = 'KLEINUNTERNEHMER',
  NachfMitEinzug = 'NACHF_MIT_EINZUG',
  NachfMitUew = 'NACHF_MIT_UEW',
  Schlusstext = 'SCHLUSSTEXT',
  Titeltext = 'TITELTEXT',
  VerrechnungsweiseDauerrechnung = 'VERRECHNUNGSWEISE_DAUERRECHNUNG',
  ZahlartLastschrift = 'ZAHLART_LASTSCHRIFT',
  ZahlartUeberweisung = 'ZAHLART_UEBERWEISUNG',
}

export type TextbausteinTypeTuple = {
  text: Scalars['String']['output'];
  value: TextbausteinType;
};

export enum ThresholdType {
  Percent = 'PERCENT',
  Points = 'POINTS',
}

export type ThresholdTypeTuple = {
  text: Scalars['String']['output'];
  value: ThresholdType;
};

export type Ticket = {
  assignee: MitarbeiterBase;
  attachmentCount: Scalars['Int']['output'];
  attachments: Array<TicketAttachment>;
  beInfoFeld?: Maybe<AbstractBeInfoFeld>;
  bestandseinheit?: Maybe<BestandseinheitBase>;
  commentCount: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['String']['output'];
  emailCount: Scalars['Int']['output'];
  emailList: Array<TicketEmail>;
  isSubTicket: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  objekt?: Maybe<ObjektBase>;
  objektInfoFeld?: Maybe<AbstractObjektInfoFeld>;
  parent?: Maybe<TicketBase>;
  participantCount: Scalars['Int']['output'];
  participantList: Array<TicketParticipant>;
  position: Scalars['Int']['output'];
  priority: TicketPriorityTuple;
  rechtstraeger?: Maybe<RechtstraegerBase>;
  rechtstraegerInfoFeld?: Maybe<AbstractRechtstraegerInfoFeld>;
  reminder: Period;
  reminderText: Scalars['String']['output'];
  subTicketList: Array<TicketBase>;
  ticketId: Scalars['String']['output'];
  ticketStatus: TicketStatusTuple;
  title: Scalars['String']['output'];
  type?: Maybe<TicketTemplate>;
  typeName?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertrag?: Maybe<VertragBase>;
  vertragInfoFeld?: Maybe<AbstractVertragInfoFeld>;
  warningList: Array<ResponseDataWarning>;
};

export type TicketActionChangeSubTicketPositionInput = {
  position: Scalars['Int']['input'];
};

export type TicketActionCreateSubTicketInput = {
  assigneeMitarbeiterId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['String']['input'];
  participantList?: InputMaybe<Array<ParticipantInput>>;
  priority: TicketPriority;
  reminder: TicketTemplatePeriodInput;
  title: Scalars['String']['input'];
};

export type TicketActionUpdateStatusInput = {
  status: TicketStatus;
};

export type TicketAttachment = {
  fileInfo: OrderFileInfo;
  ticketId: Scalars['String']['output'];
  ticketTitle: Scalars['String']['output'];
};

export type TicketBase = {
  assignee: MitarbeiterBase;
  attachmentCount: Scalars['Int']['output'];
  beInfoFeld?: Maybe<AbstractBeInfoFeld>;
  bestandseinheit?: Maybe<BestandseinheitBase>;
  commentCount: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['String']['output'];
  emailCount: Scalars['Int']['output'];
  isSubTicket: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  objekt?: Maybe<ObjektBase>;
  objektInfoFeld?: Maybe<AbstractObjektInfoFeld>;
  parent?: Maybe<TicketBase>;
  participantCount: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  priority: TicketPriorityTuple;
  rechtstraeger?: Maybe<RechtstraegerBase>;
  rechtstraegerInfoFeld?: Maybe<AbstractRechtstraegerInfoFeld>;
  reminder: Period;
  reminderText: Scalars['String']['output'];
  ticketId: Scalars['String']['output'];
  ticketStatus: TicketStatusTuple;
  title: Scalars['String']['output'];
  type?: Maybe<TicketTemplate>;
  typeName?: Maybe<Scalars['String']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertrag?: Maybe<VertragBase>;
  vertragInfoFeld?: Maybe<AbstractVertragInfoFeld>;
  warningList: Array<ResponseDataWarning>;
};

export type TicketBaseResponse = {
  data: TicketBase;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TicketChangeEntry = {
  changeType: TicketChangeType;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  newAssigneeKurzBezeichnung?: Maybe<Scalars['String']['output']>;
  newAttachmentList?: Maybe<Array<Scalars['String']['output']>>;
  newComment?: Maybe<Scalars['String']['output']>;
  newDescription?: Maybe<Scalars['String']['output']>;
  newDueDate?: Maybe<Scalars['String']['output']>;
  newEmailList?: Maybe<Array<TicketChangeEntryEmail>>;
  newParticipantList?: Maybe<Array<Scalars['String']['output']>>;
  newPriority?: Maybe<TicketPriorityTuple>;
  newReminder?: Maybe<Period>;
  newReminderText?: Maybe<Scalars['String']['output']>;
  newStatus?: Maybe<TicketStatusTuple>;
  newTitle?: Maybe<Scalars['String']['output']>;
  oldAssigneeKurzBezeichnung?: Maybe<Scalars['String']['output']>;
  oldAttachmentList?: Maybe<Array<Scalars['String']['output']>>;
  oldComment?: Maybe<Scalars['String']['output']>;
  oldDescription?: Maybe<Scalars['String']['output']>;
  oldDueDate?: Maybe<Scalars['String']['output']>;
  oldEmailList?: Maybe<Array<TicketChangeEntryEmail>>;
  oldParticipantList?: Maybe<Array<Scalars['String']['output']>>;
  oldPriority?: Maybe<TicketPriorityTuple>;
  oldReminder?: Maybe<Period>;
  oldReminderText?: Maybe<Scalars['String']['output']>;
  oldStatus?: Maybe<TicketStatusTuple>;
  oldTitle?: Maybe<Scalars['String']['output']>;
  subTicketChangeEntry: Scalars['Boolean']['output'];
  ticketId: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type TicketChangeEntryEmail = {
  sender: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export enum TicketChangeEntryFieldType {
  Date = 'DATE',
  Int = 'INT',
  Period = 'PERIOD',
  Priority = 'PRIORITY',
  Status = 'STATUS',
  String = 'STRING',
  StringList = 'STRING_LIST',
}

export type TicketChangeEntryListResponse = {
  data: Array<TicketChangeEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum TicketChangeEntryScope {
  Self = 'SELF',
  SelfAndSubTicketsMainEvents = 'SELF_AND_SUB_TICKETS_MAIN_EVENTS',
}

export enum TicketChangeType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

export type TicketComment = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  ticketCommentId: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type TicketCommentCreateInput = {
  text: Scalars['String']['input'];
};

export type TicketCommentListResponse = {
  data: Array<TicketComment>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TicketCommentResponse = {
  data: TicketComment;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TicketCommentUpdateInput = {
  text: Scalars['String']['input'];
};

export type TicketCreateInput = {
  assigneeMitarbeiterId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['String']['input'];
  participantList?: InputMaybe<Array<ParticipantInput>>;
  priority: TicketPriority;
  reminder: TicketTemplatePeriodInput;
  ticketTemplateId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type TicketEmail = {
  attachments: Array<TicketAttachment>;
  fileInfo: OrderFileInfo;
  sender?: Maybe<Scalars['String']['output']>;
  sentTs?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  ticketId: Scalars['String']['output'];
  ticketParticipant: TicketParticipant;
  ticketTitle: Scalars['String']['output'];
};

export type TicketListPage = {
  contentList: Array<TicketBase>;
  page: Page;
};

export type TicketListPageResponse = {
  data: TicketListPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TicketListResponse = {
  data: Array<TicketBase>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum TicketOrderBy {
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  Description = 'DESCRIPTION',
  Title = 'TITLE',
}

export type TicketParticipant = {
  participant: Participant;
  ticketId: Scalars['String']['output'];
  ticketTitle: Scalars['String']['output'];
};

export type TicketParticipantActionInput = {
  participantIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  rechtstraegerId?: InputMaybe<Scalars['ID']['input']>;
};

export type TicketParticipantResponse = {
  data: Participant;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum TicketPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export type TicketPriorityListResponse = {
  data: Array<TicketPriorityTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TicketPriorityTuple = {
  text: Scalars['String']['output'];
  value: TicketPriority;
};

export type TicketResponse = {
  data: Ticket;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum TicketStatus {
  Archived = 'ARCHIVED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  ToDo = 'TO_DO',
}

export type TicketStatusListResponse = {
  data: Array<TicketStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TicketStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: TicketStatus;
};

export type TicketTemplate = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Period>;
  dueDateText?: Maybe<Scalars['String']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  isSubTicketTemplate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  priority: TicketPriorityTuple;
  reminder?: Maybe<Period>;
  reminderText?: Maybe<Scalars['String']['output']>;
  subTicketTemplateList?: Maybe<Array<TicketTemplate>>;
  ticketTemplateId: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type TicketTemplateActionChangePositionInput = {
  position: Scalars['Int']['input'];
};

export type TicketTemplateActionCreateSubTicketTemplateInput = {
  dueDate?: InputMaybe<TicketTemplatePeriodInput>;
  name: Scalars['String']['input'];
  priority: TicketPriority;
  reminder?: InputMaybe<TicketTemplatePeriodInput>;
};

export type TicketTemplateCreateInput = {
  dueDate?: InputMaybe<TicketTemplatePeriodInput>;
  name: Scalars['String']['input'];
  priority: TicketPriority;
  reminder?: InputMaybe<TicketTemplatePeriodInput>;
};

export type TicketTemplateListResponse = {
  data: Array<TicketTemplate>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TicketTemplatePeriodInput = {
  amount: Scalars['Int']['input'];
  unit: PeriodUnit;
};

export type TicketTemplateResponse = {
  data: TicketTemplate;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TicketTemplateTemplateListResponse = {
  data: Array<TicketTemplate>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TicketTemplateTemplateResponse = {
  data: TicketTemplate;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TicketTemplateUpdateInput = {
  dueDate?: InputMaybe<TicketTemplatePeriodInput>;
  name: Scalars['String']['input'];
  priority: TicketPriority;
  reminder?: InputMaybe<TicketTemplatePeriodInput>;
};

export type TicketTypeNameListResponse = {
  data: Array<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TicketUpdateInput = {
  assigneeMitarbeiterId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['String']['input'];
  participantList?: InputMaybe<Array<ParticipantInput>>;
  priority: TicketPriority;
  reminder: TicketTemplatePeriodInput;
  title: Scalars['String']['input'];
};

export type TopAbrAbrechnungkreis = {
  abrechnungskreisId: Scalars['ID']['output'];
  abrechnungskreisType: AbrechnungskreisTypeTuple;
  aufteilungsschluesselBezeichnung?: Maybe<Scalars['String']['output']>;
  aufwand: Scalars['Float']['output'];
  aufwandAufteilungList: Array<TopAbrKreisAufwand>;
  aufwandGesamt: Scalars['Float']['output'];
  aufwandProzent?: Maybe<Scalars['Float']['output']>;
  bezeichnung: Scalars['String']['output'];
  brutto: Scalars['Float']['output'];
  einnahmen: Scalars['Float']['output'];
  einnahmenBuchungszeileList: Array<EinnahmenBuchungszeile>;
  netto: Scalars['Float']['output'];
  steuersatz: Scalars['Int']['output'];
  ust: Scalars['Float']['output'];
  ustKategorieEntryId: Scalars['String']['output'];
  ustKategorieId: Scalars['String']['output'];
  ustKategorieStatus: UstKategorieStatusTuple;
  ustKategorieVersionId: Scalars['String']['output'];
  ustVomAufwandAnteilList: Array<TopAbrUstVomAufwandAnteil>;
};

export type TopAbrKonto = {
  bezeichnung: Scalars['String']['output'];
  kontoId: Scalars['String']['output'];
  summe: Scalars['Float']['output'];
};

export type TopAbrKreisAufwand = {
  aufteilungsschluesselBezeichnung: Scalars['String']['output'];
  kontoList: Array<TopAbrKonto>;
  summe: Scalars['Float']['output'];
  zeitscheibeAufwandList: Array<TopAbrKreisZeitscheibeAufwand>;
};

export type TopAbrKreisZeitscheibeAufwand = {
  anteilAufwand: Scalars['Float']['output'];
  aufwand: Scalars['Float']['output'];
  beAufteilungsschluesselWert?: Maybe<Scalars['Float']['output']>;
  bisInklusive: Scalars['String']['output'];
  prozentAufteilungsschluesselAnteil: Scalars['Float']['output'];
  sumAufteilungsschluesselWert: Scalars['Float']['output'];
  vonInklusive: Scalars['String']['output'];
};

export type TopAbrOverviewZahlungsrueckstand = {
  bestandseinheit: BestandseinheitBase;
  konto: AppKonto;
  letzteBuchungAm?: Maybe<Scalars['String']['output']>;
  offenerBetrag: Scalars['Float']['output'];
  rechtstraeger: RechtstraegerBase;
};

export type TopAbrUstVomAufwandAnteil = {
  anteilNetto: Scalars['Float']['output'];
  basis: Scalars['Float']['output'];
  belastung: Scalars['Float']['output'];
  grundlage: VerrechnungUstVomAufwandTuple;
  summe: Scalars['Float']['output'];
  ustVomAufwand: Scalars['Float']['output'];
  ustVomAufwandSteuersatz: Scalars['Int']['output'];
};

export type TopAbrechnung = {
  abrechnungskreisList: Array<TopAbrAbrechnungkreis>;
  aufwand: Scalars['Float']['output'];
  bestandseinheitBezeichnung?: Maybe<Scalars['String']['output']>;
  bestandseinheitId: Scalars['String']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  dueDateDisplayOption?: Maybe<DueDateDisplayOption>;
  dueDateVorschreibung: DueDateVorschreibungTuple;
  einleitungsText: Scalars['String']['output'];
  einnahmen: Scalars['Float']['output'];
  ergebnisText: Scalars['String']['output'];
  fileId?: Maybe<Scalars['String']['output']>;
  fromInclusive: Scalars['String']['output'];
  guthaben: Scalars['Boolean']['output'];
  infoAbrechnungskreisList: Array<AbrInfoAbrechnungskreis>;
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung: Scalars['String']['output'];
  paymentMethod: PaymentMethodTuple;
  rechnungsAussteller: RechtstraegerBase;
  rueckstandBestandnehmer: Scalars['Float']['output'];
  saldo: Scalars['Float']['output'];
  schlussText: Scalars['String']['output'];
  status: TopAbrechnungStatusTuple;
  toInclusive: Scalars['String']['output'];
  topAbrechnungId: Scalars['ID']['output'];
  type: AbrechnungTypeTuple;
  verrechnungsart: VerrechnungsartTuple;
  vertragspartner: RechtstraegerBase;
  warningList: Array<ResponseDataWarning>;
};

export type TopAbrechnungDeviationListResponse = {
  data: Array<Deviation>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TopAbrechnungDeviationSourceEntity = AbstractDeviationSourceEntity & {
  abrechnungType: AbrechnungTypeTuple;
  bestandseinheitBezeichnung: Scalars['String']['output'];
  entityId: Scalars['ID']['output'];
  fromInclusive: Scalars['String']['output'];
  ids?: Maybe<TopAbrechnungDeviationSourceEntityIds>;
  objektBezeichnung: Scalars['String']['output'];
  toInclusive: Scalars['String']['output'];
  type: DeviationSourceEntityTypeTuple;
};

export type TopAbrechnungDeviationSourceEntityIds = {
  linkText: Scalars['String']['output'];
  objektAbrechnungId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  topAbrechnungId: Scalars['String']['output'];
};

export type TopAbrechnungGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  fromInclusive: Scalars['String']['output'];
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  toInclusive: Scalars['String']['output'];
  warningList: Array<WarningData>;
};

export type TopAbrechnungGenerierlaufCreateInput = {
  abrechnungType: AbrechnungType;
  fakturierungsperiode: FakturierungsperiodeInput;
  objektAbrechnungIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  type: AbrechnungGenerierlaufType;
};

export type TopAbrechnungGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  objektAbrechnungId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung: Scalars['String']['output'];
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  topAbrechnung?: Maybe<TopAbrechnung>;
  topAbrechnungDeleted: Scalars['Boolean']['output'];
  topAbrechnungDeletedBy?: Maybe<Scalars['String']['output']>;
  topAbrechnungDeletedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  topAbrechnungDeletedTs?: Maybe<Scalars['String']['output']>;
  topAbrechnungOverview?: Maybe<TopAbrechnungOverview>;
  warningList: Array<WarningData>;
};

export type TopAbrechnungGenerierlaufListEntryPage = {
  contentList: Array<TopAbrechnungGenerierlaufEntry>;
  page: Page;
};

export type TopAbrechnungGenerierlaufListEntryPageResponse = {
  data: TopAbrechnungGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum TopAbrechnungGenerierlaufOrderBy {
  Abrechnungsergebnis = 'ABRECHNUNGSERGEBNIS',
  Aussteller = 'AUSSTELLER',
  Bestandseinheit = 'BESTANDSEINHEIT',
  Empfaenger = 'EMPFAENGER',
  Objekt = 'OBJEKT',
  Status = 'STATUS',
  Zeitraum = 'ZEITRAUM',
}

export type TopAbrechnungGenerierlaufResponse = {
  data: TopAbrechnungGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TopAbrechnungOutputOptions = {
  considerRueckstand: Scalars['Boolean']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  displayDueDate: Scalars['Boolean']['output'];
  displayRueckstandOtherPersons: Scalars['Boolean']['output'];
  displayZahlungsverlauf: Scalars['Boolean']['output'];
  dueDateDisplayOptionEigentuemerGuthaben?: Maybe<DueDateDisplayOption>;
  dueDateDisplayOptionEigentuemerNachzahlung?: Maybe<DueDateDisplayOption>;
  dueDateDisplayOptionMieter?: Maybe<DueDateDisplayOption>;
  rueckstandOtherPersonsThreshold?: Maybe<Scalars['Float']['output']>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type TopAbrechnungOutputOptionsInput = {
  considerRueckstand: Scalars['Boolean']['input'];
  displayDueDate: Scalars['Boolean']['input'];
  displayRueckstandOtherPersons: Scalars['Boolean']['input'];
  displayZahlungsverlauf: Scalars['Boolean']['input'];
  dueDateDisplayOptionEigentuemerGuthaben?: InputMaybe<DueDateDisplayOptionsInput>;
  dueDateDisplayOptionEigentuemerNachzahlung?: InputMaybe<DueDateDisplayOptionsInput>;
  dueDateDisplayOptionMieter?: InputMaybe<DueDateDisplayOptionsInput>;
  rueckstandOtherPersonsThreshold?: InputMaybe<Scalars['Float']['input']>;
};

export type TopAbrechnungOverview = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  fileId?: Maybe<Scalars['String']['output']>;
  fromInclusive: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung: Scalars['String']['output'];
  toInclusive: Scalars['String']['output'];
  topAbrechnungList: Array<TopAbrechnung>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
  zahlungsrueckstandList?: Maybe<Array<TopAbrOverviewZahlungsrueckstand>>;
};

export type TopAbrechnungResponse = {
  data: TopAbrechnung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum TopAbrechnungStatus {
  Invalid = 'INVALID',
  ReviewError = 'REVIEW_ERROR',
  UnderReview = 'UNDER_REVIEW',
  Valid = 'VALID',
}

export type TopAbrechnungStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: TopAbrechnungStatus;
};

export type TopAbrechnungsdefinition = {
  einleitungstext?: Maybe<Scalars['String']['output']>;
  ghMitEinzug?: Maybe<Scalars['String']['output']>;
  ghMitUewBankvBekannt?: Maybe<Scalars['String']['output']>;
  ghMitUewBankvUnbekannt?: Maybe<Scalars['String']['output']>;
  nachfMitEinzug?: Maybe<Scalars['String']['output']>;
  nachfMitUew?: Maybe<Scalars['String']['output']>;
  schlusstext?: Maybe<Scalars['String']['output']>;
};

export type TopBudgetSumNextYearResultResponse = {
  data: Scalars['Float']['output'];
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TopFinancialPlanDeleteInput = {
  bestandseinheitId?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['ID']['input'];
  vertragId?: InputMaybe<Scalars['String']['input']>;
};

export type TopFinancialPlanGenerierlauf = {
  budgeting: BudgetingBase;
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  entryList: Array<TopFinancialPlanGenerierlaufEntry>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['String']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type TopFinancialPlanGenerierlaufEntry = {
  bestandseinheit?: Maybe<BestandseinheitBase>;
  budgetSumNextYear: Scalars['Float']['output'];
  budgetingResultVertragId?: Maybe<Scalars['String']['output']>;
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['String']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  vertrag?: Maybe<VertragBase>;
  warningList: Array<WarningData>;
};

export type TopFinancialPlanGenerierlaufInput = {
  budgetingId: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
};

export type TopFinancialPlanGenerierlaufResponse = {
  data: TopFinancialPlanGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TransactionInstruction = {
  amount: Scalars['Float']['output'];
  buchungskreisRechtstraegerId: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  creditor: Scalars['String']['output'];
  creditorIban: Scalars['String']['output'];
  dataCarrierId?: Maybe<Scalars['String']['output']>;
  dataCarrierName?: Maybe<Scalars['String']['output']>;
  debtor: Scalars['String']['output'];
  debtorIban: Scalars['String']['output'];
  endToEndId?: Maybe<Scalars['String']['output']>;
  invoiceIssuerName: Scalars['String']['output'];
  paymentInformationId?: Maybe<Scalars['String']['output']>;
  paymentReferenceText?: Maybe<Scalars['String']['output']>;
  paymentTransactionType?: Maybe<PaymentTransactionTypeTuple>;
  paymentType: PaymentTypeTuple;
  purposeOfUseText?: Maybe<Scalars['String']['output']>;
  requestedExecutionDate: Scalars['String']['output'];
  sepaCreditorId?: Maybe<Scalars['String']['output']>;
  sepaMandateId?: Maybe<Scalars['String']['output']>;
  transactionInstructionId: Scalars['ID']['output'];
  transactionInstructionType: TransactionInstructionTypeTuple;
  warningList: Array<WarningData>;
};

export type TransactionInstructionListResponse = {
  data: Array<TransactionInstruction>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum TransactionInstructionType {
  CreditTransfer = 'CREDIT_TRANSFER',
  DirectDebit = 'DIRECT_DEBIT',
}

export type TransactionInstructionTypeTuple = {
  text: Scalars['String']['output'];
  value: TransactionInstructionType;
};

export enum TransactionType {
  CreditTransferIncoming = 'CREDIT_TRANSFER_INCOMING',
  CreditTransferOutgoing = 'CREDIT_TRANSFER_OUTGOING',
  DirectDebitIncoming = 'DIRECT_DEBIT_INCOMING',
  DirectDebitOutgoing = 'DIRECT_DEBIT_OUTGOING',
  Other = 'OTHER',
  Reversal = 'REVERSAL',
}

export type TransactionTypeListResponse = {
  data: Array<TransactionTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type TransactionTypeTuple = {
  text: Scalars['String']['output'];
  value: TransactionType;
};

export enum Turnus {
  Halbjaehrlich = 'HALBJAEHRLICH',
  Jaehrlich = 'JAEHRLICH',
  Monatlich = 'MONATLICH',
  Quartalsweise = 'QUARTALSWEISE',
}

export type TurnusTuple = {
  text: Scalars['String']['output'];
  value: Turnus;
};

export type UmbuchungInfo = {
  fakturierungsperiode: Fakturierungsperiode;
  kontoKeyIst: KontoKey;
  kontoKeySoll: KontoKey;
};

export type Umsatzsteuerkennzeichen = {
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  kuerzel: Scalars['String']['output'];
  status: UmsatzsteuerkennzeichenStatusTuple;
  umsatzsteuerkennzeichenId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type UmsatzsteuerkennzeichenListResponse = {
  data: Array<Umsatzsteuerkennzeichen>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum UmsatzsteuerkennzeichenStatus {
  Aktiv = 'AKTIV',
  Geloescht = 'GELOESCHT',
}

export type UmsatzsteuerkennzeichenStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: UmsatzsteuerkennzeichenStatus;
};

export type UmsatzsteuerkennzeichenTemplateListResponse = {
  data: Array<Umsatzsteuerkennzeichen>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UpdateFirmendateneinstellungenInput = {
  dueDateVorschreibung: Scalars['String']['input'];
};

export type UpdateKundenSystemInput = {
  name: Scalars['String']['input'];
};

export type UpdateSubAdministrationSettingsInput = {
  paymentClearsIncomingInvoice?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = {
  contactList?: Maybe<Array<Contact>>;
  createdTs: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  emailUnverified?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  role: UserRole;
  status: UserStatusTuple;
  username: Scalars['String']['output'];
};

export type UserCreateInput = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  contactList?: InputMaybe<Array<ContactCreateInput>>;
  email: Scalars['String']['input'];
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UserListResponse = {
  data?: Maybe<Array<User>>;
  errorList?: Maybe<Array<ErrorData>>;
  warningList?: Maybe<Array<WarningData>>;
};

export type UserName = {
  firstname?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type UserNameData = {
  data?: Maybe<UserName>;
  errorList?: Maybe<Array<ErrorData>>;
  warningList?: Maybe<Array<WarningData>>;
};

export type UserResponse = {
  data: User;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UserRevision = {
  changedBy?: Maybe<Scalars['String']['output']>;
  changedTs?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailUnverified?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  lastPasswordChangeTs?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  status: UserStatusTuple;
};

export type UserRevisionListResponse = {
  data?: Maybe<Array<UserRevision>>;
  errorList?: Maybe<Array<ErrorData>>;
  warningList?: Maybe<Array<WarningData>>;
};

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER',
}

export enum UserStatus {
  Aktiv = 'AKTIV',
  Geloescht = 'GELOESCHT',
  Gesperrt = 'GESPERRT',
  Unverifiziert = 'UNVERIFIZIERT',
}

export type UserStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: UserStatus;
};

export type UserUpdateInput = {
  contactList?: InputMaybe<Array<ContactUpdateInput>>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
};

export type UstKategorie = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  status?: Maybe<UstKategorieStatusTuple>;
  ustKategorieEntryList: Array<UstKategorieEntry>;
  ustKategorieId: Scalars['ID']['output'];
  ustVomAufwand?: Maybe<Scalars['Int']['output']>;
  vstKuerzung?: Maybe<Scalars['Boolean']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type UstKategorieCreateInput = {
  kurzBezeichnung: Scalars['String']['input'];
};

export type UstKategorieEntry = {
  additionKonto: Scalars['Int']['output'];
  buchungsKZ?: Maybe<Scalars['String']['output']>;
  createTs?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  rechnungskennzeichen: Scalars['String']['output'];
  steuersatz: Scalars['Int']['output'];
  ustKategorieEntryId?: Maybe<Scalars['ID']['output']>;
  ustKz: Umsatzsteuerkennzeichen;
  warningList: Array<ResponseDataWarning>;
};

export type UstKategorieEntryInput = {
  additionKonto: Scalars['Int']['input'];
  buchungsKZ: Scalars['String']['input'];
  rechnungskennzeichen: Scalars['String']['input'];
  steuersatz: Scalars['Int']['input'];
};

export type UstKategorieEntryListResponse = {
  data: Array<UstKategorieEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstKategorieEntryResponse = {
  data: UstKategorieEntry;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstKategorieListResponse = {
  data: Array<UstKategorie>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstKategorieResponse = {
  data: UstKategorie;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum UstKategorieStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type UstKategorieStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: UstKategorieStatus;
};

export type UstKategorieUpdateInput = {
  kurzBezeichnung: Scalars['String']['input'];
};

export type UstKategorieVersion = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  historicizedList?: Maybe<Array<UstKategorieVersion>>;
  lastUpdateTs: Scalars['String']['output'];
  ustKategorieEntryList: Array<UstKategorieEntry>;
  ustKategorieVersionId: Scalars['ID']['output'];
  ustVomAufwand: Scalars['Int']['output'];
  validFrom: Scalars['String']['output'];
  versionIndex: Scalars['Int']['output'];
  vstKuerzung: Scalars['Boolean']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type UstKategorieVersionCreateInput = {
  ustKategorieEntryList?: InputMaybe<Array<UstKategorieVersionEntryCreateInput>>;
  ustVomAufwand: Scalars['Int']['input'];
  validFrom: Scalars['String']['input'];
  vstKuerzung: Scalars['Boolean']['input'];
};

export type UstKategorieVersionEntryCreateInput = {
  additionKonto?: InputMaybe<Scalars['Int']['input']>;
  buchungsKZ?: InputMaybe<Scalars['String']['input']>;
  rechnungskennzeichen: Scalars['String']['input'];
  steuersatz?: InputMaybe<Scalars['Int']['input']>;
  umsatzsteuerkennzeichenId: Scalars['String']['input'];
};

export type UstKategorieVersionEntryUpdateInput = {
  additionKonto?: InputMaybe<Scalars['Int']['input']>;
  buchungsKZ?: InputMaybe<Scalars['String']['input']>;
  rechnungskennzeichen: Scalars['String']['input'];
  steuersatz?: InputMaybe<Scalars['Int']['input']>;
  ustKategorieEntryId: Scalars['String']['input'];
};

export type UstKategorieVersionListResponse = {
  data: Array<UstKategorieVersion>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstKategorieVersionResponse = {
  data: UstKategorieVersion;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstKategorieVersionUpdateInput = {
  ustKategorieEntryList?: InputMaybe<Array<UstKategorieVersionEntryUpdateInput>>;
  ustVomAufwand: Scalars['Int']['input'];
  validFrom: Scalars['String']['input'];
  vstKuerzung: Scalars['Boolean']['input'];
};

export type UstRegelset = {
  bezeichnung: Scalars['String']['output'];
  countryCodeIso2: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  eigennutzung: Scalars['Boolean']['output'];
  firmendatenId?: Maybe<Scalars['ID']['output']>;
  land?: Maybe<CountryCode>;
  leerstehung: Scalars['Boolean']['output'];
  nutzungsArt: NutzungsArtTuple;
  optionSteuerpflicht: Scalars['Boolean']['output'];
  steuerpflicht: SteuerpflichtTuple;
  updatedByMitarebeiterId?: Maybe<Scalars['ID']['output']>;
  ustKategorie?: Maybe<UstKategorie>;
  ustKategorieZuweisungList: Array<UstRegelsetUstKategorieZuweisung>;
  ustRegelsetId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type UstRegelsetCreateInput = {
  bezeichnung: Scalars['String']['input'];
  countryCodeIso2: Scalars['String']['input'];
  eigennutzung?: InputMaybe<Scalars['Boolean']['input']>;
  leerstehung?: InputMaybe<Scalars['Boolean']['input']>;
  nutzungsArt: NutzungsArt;
  optionSteuerpflicht?: InputMaybe<Scalars['Boolean']['input']>;
  steuerpflicht: Steuerpflicht;
  ustKategorieId: Scalars['String']['input'];
  validFrom: Scalars['String']['input'];
};

export type UstRegelsetListResponse = {
  data: Array<UstRegelset>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstRegelsetResponse = {
  data: UstRegelset;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstRegelsetUpdateInput = {
  bezeichnung: Scalars['String']['input'];
  countryCodeIso2: Scalars['String']['input'];
  eigennutzung?: InputMaybe<Scalars['Boolean']['input']>;
  leerstehung?: InputMaybe<Scalars['Boolean']['input']>;
  nutzungsArt: NutzungsArt;
  optionSteuerpflicht?: InputMaybe<Scalars['Boolean']['input']>;
  steuerpflicht: Steuerpflicht;
};

export type UstRegelsetUstKategorieZuweisung = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  ustKategorie: UstKategorie;
  ustRegelsetKategorieZuweisungId: Scalars['ID']['output'];
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type UstRegelsetUstKategorieZuweisungCreateInput = {
  ustKategorieId: Scalars['String']['input'];
  validFrom: Scalars['String']['input'];
};

export type UstRegelsetUstKategorieZuweisungListResponse = {
  data: Array<UstRegelsetUstKategorieZuweisung>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstRegelsetUstKategorieZuweisungResponse = {
  data: UstRegelsetUstKategorieZuweisung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstRegelsetUstKategorieZuweisungUpdateInput = {
  ustKategorieId: Scalars['String']['input'];
  validFrom: Scalars['String']['input'];
};

export type UstVomAufwand = {
  belegnummer?: Maybe<Scalars['String']['output']>;
  bisInklusive: Scalars['String']['output'];
  buchungErrorList: Array<ErrorData>;
  buchungStatus: UstVomAufwandBuchungStatusTuple;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  fibuBuchungIdList: Array<Scalars['String']['output']>;
  lastBuchungsdatum?: Maybe<Scalars['String']['output']>;
  objekt: ObjektBase;
  status: UstVomAufwandStatusTuple;
  sumNetto: Scalars['Float']['output'];
  sumSteuer: Scalars['Float']['output'];
  sumUstVomAufwand: Scalars['Float']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  ustVomAufwandId: Scalars['ID']['output'];
  ustVomAufwandVertragList: Array<UstVomAufwandVertrag>;
  vertragsart: VertragsartTuple;
  vonInklusive: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type UstVomAufwandActionApplyDeviationsInput = {
  abgrenzungsBuchung: Scalars['Boolean']['input'];
  stornoAbgrenzung?: InputMaybe<UstVomAufwandActionApplyDeviationsStornoAbgrenzungInput>;
  stornoDirekt?: InputMaybe<UstVomAufwandActionApplyDeviationsStornoDirektInput>;
};

export type UstVomAufwandActionApplyDeviationsStornoAbgrenzungInput = {
  korrekturAbgrenzungsBuchungsdatum: Scalars['String']['input'];
  korrekturBuchungsdatum: Scalars['String']['input'];
  stornoAbgrenzungsBuchungsdatum: Scalars['String']['input'];
  stornoBuchungsdatum: Scalars['String']['input'];
};

export type UstVomAufwandActionApplyDeviationsStornoDirektInput = {
  korrekturBuchungsdatum: Scalars['String']['input'];
  stornoBuchungsdatum: Scalars['String']['input'];
};

export type UstVomAufwandActionFreigebenInput = {
  buchungsdatum: Scalars['String']['input'];
};

export type UstVomAufwandAufteilung = {
  anteilTopAufteilungsschluessel: Scalars['Float']['output'];
  aufteilungsschluessel: Aufteilungsschluessel;
  belegFileId: Scalars['String']['output'];
  belegId: Scalars['String']['output'];
  belegnummer: Scalars['String']['output'];
  buchungId: Scalars['String']['output'];
  buchungsdatum: Scalars['String']['output'];
  einheitskosten: Scalars['Float']['output'];
  gesamtAnteilAufteilungsschluessel: Scalars['Float']['output'];
  lieferant: RechtstraegerBase;
  netto: Scalars['Float']['output'];
  standingInvoicePartialAmountId?: Maybe<Scalars['String']['output']>;
  steuer: Scalars['Float']['output'];
  ustVomAufwandAnteil: Scalars['Float']['output'];
  ustVomAufwandSteuersatz: Scalars['Int']['output'];
};

export type UstVomAufwandAufwandKonto = {
  aufteilungList: Array<UstVomAufwandAufteilung>;
  aufwandsKontoId: Scalars['ID']['output'];
  sumNetto: Scalars['Float']['output'];
  sumSteuer: Scalars['Float']['output'];
  sumUstVomAufwand: Scalars['Float']['output'];
};

export enum UstVomAufwandBuchungStatus {
  NichtVerbucht = 'NICHT_VERBUCHT',
  VerbuchenFehler = 'VERBUCHEN_FEHLER',
  Verbucht = 'VERBUCHT',
  WirdVerbucht = 'WIRD_VERBUCHT',
}

export type UstVomAufwandBuchungStatusListResponse = {
  data: Array<UstVomAufwandBuchungStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstVomAufwandBuchungStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: UstVomAufwandBuchungStatus;
};

export type UstVomAufwandDeviationListResponse = {
  data: Array<Deviation>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstVomAufwandDeviationSourceEntity = AbstractDeviationSourceEntity & {
  bestandseinheitBezeichnung: Scalars['String']['output'];
  entityId: Scalars['ID']['output'];
  ids?: Maybe<UstVomAufwandDeviationSourceEntityIds>;
  objektBezeichnung: Scalars['String']['output'];
  rechnungsAusstellerKurzBezeichnung: Scalars['String']['output'];
  type: DeviationSourceEntityTypeTuple;
  vertragsBeginn: Scalars['String']['output'];
  vertragsEnde?: Maybe<Scalars['String']['output']>;
  vertragspartnerKurzBezeichnung: Scalars['String']['output'];
};

export type UstVomAufwandDeviationSourceEntityIds = {
  bestandseinheitId: Scalars['String']['output'];
  linkText: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  vertragId: Scalars['String']['output'];
};

export type UstVomAufwandGenerierlauf = {
  bisInklusive: Scalars['String']['output'];
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  entryList: Array<UstVomAufwandGenerierlaufEntry>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  objekt: ObjektBase;
  status: GenerierlaufStatusTuple;
  vonInklusive: Scalars['String']['output'];
  warningList: Array<WarningData>;
};

export type UstVomAufwandGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  monat: Scalars['String']['output'];
  objekt: ObjektBase;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  ustVomAufwand?: Maybe<UstVomAufwand>;
  ustVomAufwandByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  ustVomAufwandDeleted: Scalars['Boolean']['output'];
  ustVomAufwandDeletedBy?: Maybe<Scalars['String']['output']>;
  ustVomAufwandDeletedTs?: Maybe<Scalars['String']['output']>;
  warningList: Array<WarningData>;
};

export type UstVomAufwandGenerierlaufInput = {
  bisInklusive: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  vonInklusive: Scalars['String']['input'];
};

export type UstVomAufwandGenerierlaufResponse = {
  data: UstVomAufwandGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstVomAufwandListEntry = {
  bisInklusive: Scalars['String']['output'];
  buchungStatus: UstVomAufwandBuchungStatusTuple;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  status: UstVomAufwandStatusTuple;
  sumUstVomAufwand: Scalars['Float']['output'];
  ustVomAufwandId: Scalars['ID']['output'];
  vertragsart: VertragsartTuple;
  vonInklusive: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type UstVomAufwandListEntryListResponse = {
  data: Array<UstVomAufwandListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type UstVomAufwandRepFondsKonto = {
  aufwandsKontoList: Array<UstVomAufwandAufwandKonto>;
  repFondsKontoId: Scalars['ID']['output'];
  sumNetto: Scalars['Float']['output'];
  sumSteuer: Scalars['Float']['output'];
  sumUstVomAufwand: Scalars['Float']['output'];
};

export type UstVomAufwandResponse = {
  data: UstVomAufwand;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum UstVomAufwandStatus {
  Invalid = 'INVALID',
  ReviewError = 'REVIEW_ERROR',
  UnderReview = 'UNDER_REVIEW',
  Valid = 'VALID',
}

export type UstVomAufwandStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: UstVomAufwandStatus;
};

export type UstVomAufwandVertrag = {
  bestandseinheit: BestandseinheitBase;
  fibuBuchungIdList: Array<Scalars['String']['output']>;
  repFondsKontoList: Array<UstVomAufwandRepFondsKonto>;
  sumNetto: Scalars['Float']['output'];
  sumSteuer: Scalars['Float']['output'];
  sumUstVomAufwand: Scalars['Float']['output'];
  vertrag: VertragBase;
};

export type VPosWertChangeReasonBudgeting = AbstractVPosWertChangeReason & {
  bestandseinheitId: Scalars['String']['output'];
  budgetingId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type: ChangeReason;
  vertragId: Scalars['String']['output'];
  vorschreibungspositionId: Scalars['String']['output'];
};

export type VPosWertChangeReasonIndexedValue = AbstractVPosWertChangeReason & {
  bestandseinheitId: Scalars['String']['output'];
  indexationAgreementId: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type: ChangeReason;
  vertragId: Scalars['String']['output'];
  vertragVposIndexedValueId: Scalars['String']['output'];
  vorschreibungspositionId: Scalars['String']['output'];
};

export type VPosWertChangeReasonUst = AbstractVPosWertChangeReason & {
  text: Scalars['String']['output'];
  type: ChangeReason;
};

export enum ValuePreservationType {
  Category = 'CATEGORY',
  IndexAnnual = 'INDEX_ANNUAL',
  IndexThreshold = 'INDEX_THRESHOLD',
  ReferenceValue = 'REFERENCE_VALUE',
}

export type ValuePreservationTypeListResponse = {
  data: Array<ValuePreservationTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ValuePreservationTypeTuple = {
  text: Scalars['String']['output'];
  value: ValuePreservationType;
};

export type VerrechnungUstVomAufwandListResponse = {
  data: Array<VerrechnungUstVomAufwandTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VerrechnungUstVomAufwandTuple = {
  description: Scalars['String']['output'];
  text: Scalars['String']['output'];
  value: VerrechnungsUstVomAufwand;
};

export enum VerrechnungsUstVomAufwand {
  AllesAusRepFonds = 'ALLES_AUS_REP_FONDS',
  NichtsAusRepFonds = 'NICHTS_AUS_REP_FONDS',
  ZehnProzentAusRepFonds = 'ZEHN_PROZENT_AUS_REP_FONDS',
}

export enum Verrechnungsart {
  Dauerrechnung = 'DAUERRECHNUNG',
  DauerrechnungJaehrlich = 'DAUERRECHNUNG_JAEHRLICH',
  Einzelrechnung = 'EINZELRECHNUNG',
}

export type VerrechnungsartListResponse = {
  data: Array<VerrechnungsartTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VerrechnungsartTuple = {
  text: Scalars['String']['output'];
  value: Verrechnungsart;
};

export type VertragBase = {
  bestandseinheitId: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  status: VertragStatusTuple;
  vertragId: Scalars['ID']['output'];
  vertragsBeginn: Scalars['String']['output'];
  vertragsart: VertragsartTuple;
  vertragspartner: RechtstraegerBase;
  warningList: Array<ResponseDataWarning>;
};

export type VertragBaseListResponse = {
  data: Array<VertragBase>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragInfoFeldKuendigungsinfos = AbstractVertragInfoFeld & {
  bestandnehmerKuendigungsfrist?: Maybe<Scalars['Int']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  gerichtlicheKuendigung?: Maybe<Scalars['Boolean']['output']>;
  kuendigungBis: Scalars['String']['output'];
  kuendigungDurch?: Maybe<KuendigungDurchTuple>;
  kuendigungErfolgtAm?: Maybe<Scalars['String']['output']>;
  kuendigungJeweilsZum?: Maybe<Scalars['String']['output']>;
  kuendigungMoeglichZum?: Maybe<Scalars['String']['output']>;
  kuendigungWirksamZum?: Maybe<Scalars['String']['output']>;
  kuendigungsverzichtBestandgeberBis?: Maybe<Scalars['String']['output']>;
  kuendigungsverzichtBestandnehmerBis?: Maybe<Scalars['String']['output']>;
  postIt?: Maybe<PostIt>;
  status: InfoFeldStatusTuple;
  type: VertragInfoFeldTypeTuple;
  vertragInfoFeldId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type VertragInfoFeldListResponse = {
  data: Array<AbstractVertragInfoFeld>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragInfoFeldResponse = {
  data: AbstractVertragInfoFeld;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum VertragInfoFeldType {
  Kuendigungsinfos = 'KUENDIGUNGSINFOS',
  WeiterePerson = 'WEITERE_PERSON',
}

export type VertragInfoFeldTypeListResponse = {
  data: Array<VertragInfoFeldTypeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragInfoFeldTypeTuple = {
  text: Scalars['String']['output'];
  value: VertragInfoFeldType;
};

export type VertragInfoFeldWeiterePerson = AbstractVertragInfoFeld & {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fileIdList: Array<Scalars['String']['output']>;
  natuerlichePerson: NatuerlichePerson;
  postIt?: Maybe<PostIt>;
  status: InfoFeldStatusTuple;
  text?: Maybe<Scalars['String']['output']>;
  type: VertragInfoFeldTypeTuple;
  vertragInfoFeldId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export enum VertragLevel {
  Mieter = 'MIETER',
  WegEigentuemer = 'WEG_EIGENTUEMER',
  WegMieter = 'WEG_MIETER',
}

export type VertragLevelListResponse = {
  data: Array<VertragLevelTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragLevelTuple = {
  shortName: Scalars['String']['output'];
  text: Scalars['String']['output'];
  value: VertragLevel;
};

export type VertragListEntry = {
  bestandseinheitBezeichnung: Scalars['String']['output'];
  bestandseinheitId: Scalars['String']['output'];
  brutto: Scalars['Float']['output'];
  buchungskreisId?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  debitorKontoId?: Maybe<Scalars['String']['output']>;
  netto: Scalars['Float']['output'];
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung: Scalars['String']['output'];
  rechnungsausstellerList: Array<LocalDateRechtstraegerBaseTuple>;
  status: VertragStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  ust: Scalars['Float']['output'];
  vertragId: Scalars['String']['output'];
  vertragsBeginn: Scalars['String']['output'];
  vertragsEnde?: Maybe<Scalars['String']['output']>;
  vertragsart: VertragsartTuple;
  vertragspartnerId: Scalars['String']['output'];
  vertragspartnerKurzBezeichnung: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type VertragListEntryPage = {
  contentList: Array<VertragListEntry>;
  page: Page;
};

export type VertragListEntryPageResponse = {
  data: VertragListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum VertragOrderBy {
  Bestandseinheit = 'BESTANDSEINHEIT',
  Brutto = 'BRUTTO',
  Netto = 'NETTO',
  Objekt = 'OBJEKT',
  Status = 'STATUS',
  Ust = 'UST',
  Vertragsart = 'VERTRAGSART',
  Vertragspartner = 'VERTRAGSPARTNER',
  VertragsBeginn = 'VERTRAGS_BEGINN',
  VertragsEnde = 'VERTRAGS_ENDE',
}

export type VertragPaymentMethodListResponse = {
  data: Array<PaymentMethodTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum VertragStatus {
  Invalid = 'INVALID',
  PruefungAusgesetzt = 'PRUEFUNG_AUSGESETZT',
  UnderReview = 'UNDER_REVIEW',
  Valid = 'VALID',
}

export type VertragStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: VertragStatus;
};

export type VertragSummary = {
  heVertragCount: Scalars['Int']['output'];
  heVertragVerwaltungCount: Scalars['Int']['output'];
  mietVertragCount: Scalars['Int']['output'];
  mietVertragVerwaltungCount: Scalars['Int']['output'];
  weSubverwaltungCount: Scalars['Int']['output'];
  weVertragCount: Scalars['Int']['output'];
  weVertragVerwaltungCount: Scalars['Int']['output'];
};

export type VertragVerrechnungsartListResponse = {
  data: Array<VerrechnungsartTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragVposIndexationAgreementCategory = AbstractVertragVposIndexationAgreement & {
  basedOnUsableArea: Scalars['Boolean']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  indexOld?: Maybe<Scalars['Float']['output']>;
  indexOldPreliminary: Scalars['Boolean']['output'];
  indexSeries: IndexSeries;
  monthYearOld: Scalars['String']['output'];
  type: ValuePreservationTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  vertragVposIndexationAgreementId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type VertragVposIndexationAgreementCategoryCreateInput = {
  basedOnUsableArea: Scalars['Boolean']['input'];
  indexOld?: InputMaybe<Scalars['Float']['input']>;
  indexSeriesId: Scalars['ID']['input'];
  monthYearOld: IndexSeriesPeriodInput;
  type: Scalars['String']['input'];
  validFrom: IndexSeriesPeriodInput;
};

export type VertragVposIndexationAgreementCategoryUpdateInput = {
  basedOnUsableArea: Scalars['Boolean']['input'];
  indexOld?: InputMaybe<Scalars['Float']['input']>;
  indexSeriesId: Scalars['ID']['input'];
  monthYearOld: IndexSeriesPeriodInput;
  type: Scalars['String']['input'];
  validFrom: IndexSeriesPeriodInput;
};

export type VertragVposIndexationAgreementIndexAnnual = AbstractVertragVposIndexationAgreement & {
  annualAverage: Scalars['Boolean']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  effectiveWithMonth?: Maybe<MonthTuple>;
  indexOld?: Maybe<Scalars['Float']['output']>;
  indexOldPreliminary: Scalars['Boolean']['output'];
  indexSeries: IndexSeries;
  minimumLimit?: Maybe<Scalars['Float']['output']>;
  monthOfIndexPoint: MonthTuple;
  monthYearOld: Scalars['String']['output'];
  shareInPercentToApply?: Maybe<Scalars['Float']['output']>;
  subsequentBilling: Scalars['Boolean']['output'];
  subsequentBillingObjektVorschreibungsposition?: Maybe<ObjektVorschreibungsposition>;
  threshold: Scalars['Float']['output'];
  thresholdType: ThresholdTypeTuple;
  type: ValuePreservationTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  vertragVposIndexationAgreementId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type VertragVposIndexationAgreementIndexAnnualCreateInput = {
  annualAverage: Scalars['Boolean']['input'];
  effectiveWithMonth?: InputMaybe<Month>;
  indexOld?: InputMaybe<Scalars['Float']['input']>;
  indexSeriesId: Scalars['ID']['input'];
  minimumLimit?: InputMaybe<Scalars['Float']['input']>;
  monthOfIndexPoint: Month;
  monthYearOld: IndexSeriesPeriodInput;
  shareInPercentToApply?: InputMaybe<Scalars['Float']['input']>;
  subsequentBilling: Scalars['Boolean']['input'];
  subsequentBillingObjektVorschreibungspositionId?: InputMaybe<Scalars['String']['input']>;
  threshold: Scalars['Float']['input'];
  thresholdType: ThresholdType;
  type: Scalars['String']['input'];
  validFrom: IndexSeriesPeriodInput;
};

export type VertragVposIndexationAgreementIndexAnnualUpdateInput = {
  annualAverage: Scalars['Boolean']['input'];
  effectiveWithMonth?: InputMaybe<Month>;
  indexOld?: InputMaybe<Scalars['Float']['input']>;
  indexSeriesId: Scalars['ID']['input'];
  minimumLimit?: InputMaybe<Scalars['Float']['input']>;
  monthOfIndexPoint: Month;
  monthYearOld: IndexSeriesPeriodInput;
  shareInPercentToApply?: InputMaybe<Scalars['Float']['input']>;
  subsequentBilling: Scalars['Boolean']['input'];
  subsequentBillingObjektVorschreibungspositionId?: InputMaybe<Scalars['String']['input']>;
  threshold: Scalars['Float']['input'];
  thresholdType: ThresholdType;
  type: Scalars['String']['input'];
  validFrom: IndexSeriesPeriodInput;
};

export type VertragVposIndexationAgreementIndexThreshold = AbstractVertragVposIndexationAgreement & {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  effectiveAfterMonths?: Maybe<Scalars['Int']['output']>;
  indexOld?: Maybe<Scalars['Float']['output']>;
  indexOldPreliminary: Scalars['Boolean']['output'];
  indexSeries: IndexSeries;
  minimumLimit?: Maybe<Scalars['Float']['output']>;
  monthYearOld: Scalars['String']['output'];
  shareInPercentToApply?: Maybe<Scalars['Float']['output']>;
  subsequentBilling: Scalars['Boolean']['output'];
  subsequentBillingObjektVorschreibungsposition?: Maybe<ObjektVorschreibungsposition>;
  threshold: Scalars['Float']['output'];
  thresholdType: ThresholdTypeTuple;
  type: ValuePreservationTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  vertragVposIndexationAgreementId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type VertragVposIndexationAgreementIndexThresholdCreateInput = {
  effectiveAfterMonths?: InputMaybe<Scalars['Int']['input']>;
  indexOld?: InputMaybe<Scalars['Float']['input']>;
  indexSeriesId: Scalars['ID']['input'];
  minimumLimit?: InputMaybe<Scalars['Float']['input']>;
  monthYearOld: IndexSeriesPeriodInput;
  shareInPercentToApply?: InputMaybe<Scalars['Float']['input']>;
  subsequentBilling?: InputMaybe<Scalars['Boolean']['input']>;
  subsequentBillingObjektVorschreibungspositionId?: InputMaybe<Scalars['String']['input']>;
  threshold: Scalars['Float']['input'];
  thresholdType: ThresholdType;
  type: Scalars['String']['input'];
  validFrom: IndexSeriesPeriodInput;
};

export type VertragVposIndexationAgreementIndexThresholdUpdateInput = {
  effectiveAfterMonths?: InputMaybe<Scalars['Int']['input']>;
  indexOld?: InputMaybe<Scalars['Float']['input']>;
  indexSeriesId: Scalars['ID']['input'];
  minimumLimit?: InputMaybe<Scalars['Float']['input']>;
  monthYearOld: IndexSeriesPeriodInput;
  shareInPercentToApply?: InputMaybe<Scalars['Float']['input']>;
  subsequentBilling?: InputMaybe<Scalars['Boolean']['input']>;
  subsequentBillingObjektVorschreibungspositionId?: InputMaybe<Scalars['String']['input']>;
  threshold: Scalars['Float']['input'];
  thresholdType: ThresholdType;
  type: Scalars['String']['input'];
  validFrom: IndexSeriesPeriodInput;
};

export type VertragVposIndexationAgreementListEntry = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  indexOld?: Maybe<Scalars['Float']['output']>;
  indexOldPreliminary: Scalars['Boolean']['output'];
  indexSeriesId?: Maybe<Scalars['String']['output']>;
  indexSeriesName?: Maybe<Scalars['String']['output']>;
  indexedValueCount: Scalars['Int']['output'];
  monthYearOld: Scalars['String']['output'];
  name: Scalars['String']['output'];
  objektVorschreibungspositionStatus: ObjektVorschreibungspositionStatusTuple;
  type: ValuePreservationTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  vertragVposIndexationAgreementId: Scalars['ID']['output'];
  vorschreibungspositionId: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type VertragVposIndexationAgreementListEntryResponse = {
  data: Array<VertragVposIndexationAgreementListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragVposIndexationAgreementReferenceValue = AbstractVertragVposIndexationAgreement & {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  indexOld?: Maybe<Scalars['Float']['output']>;
  indexOldPreliminary: Scalars['Boolean']['output'];
  indexSeries: IndexSeries;
  monthYearOld: Scalars['String']['output'];
  type: ValuePreservationTypeTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  vertragVposIndexationAgreementId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type VertragVposIndexationAgreementReferenceValueCreateInput = {
  indexOld?: InputMaybe<Scalars['Float']['input']>;
  indexSeriesId: Scalars['ID']['input'];
  monthYearOld: IndexSeriesPeriodInput;
  type: Scalars['String']['input'];
  validFrom: IndexSeriesPeriodInput;
};

export type VertragVposIndexationAgreementReferenceValueUpdateInput = {
  indexOld?: InputMaybe<Scalars['Float']['input']>;
  indexSeriesId: Scalars['ID']['input'];
  monthYearOld: IndexSeriesPeriodInput;
  type: Scalars['String']['input'];
  validFrom: IndexSeriesPeriodInput;
};

export type VertragVposIndexationAgreementResponse = {
  data: AbstractVertragVposIndexationAgreement;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragVposIndexationAgreementVorschreibungsposition = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  indexOld?: Maybe<Scalars['Float']['output']>;
  indexOldPreliminary?: Maybe<Scalars['Boolean']['output']>;
  indexSeriesId?: Maybe<Scalars['String']['output']>;
  indexSeriesName?: Maybe<Scalars['String']['output']>;
  indexationAgreementCount: Scalars['Int']['output'];
  monthYearOld?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  objektVorschreibungspositionStatus: ObjektVorschreibungspositionStatusTuple;
  type?: Maybe<ValuePreservationTypeTuple>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['String']['output']>;
  vorschreibungspositionId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type VertragVposIndexationAgreementVorschreibungspositionListResponse = {
  data: Array<VertragVposIndexationAgreementVorschreibungsposition>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragVposIndexedValue = {
  amountNew: Scalars['Float']['output'];
  amountOld: Scalars['Float']['output'];
  bestandseinheitBezeichnung: Scalars['String']['output'];
  bestandseinheitId: Scalars['String']['output'];
  calculatedAmountNew: Scalars['Float']['output'];
  calculatedFactorNew: Scalars['Float']['output'];
  calculatedFactorNewPercent: Scalars['Float']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  factorNew: Scalars['Float']['output'];
  factorNewPercent: Scalars['Float']['output'];
  indexNew: Scalars['Float']['output'];
  indexNewPreliminary: Scalars['Boolean']['output'];
  indexOld: Scalars['Float']['output'];
  indexOldPreliminary: Scalars['Boolean']['output'];
  indexSeriesId: Scalars['String']['output'];
  indexSeriesName: Scalars['String']['output'];
  indexationAgreement: AbstractVertragVposIndexationAgreement;
  monthYearNew: Scalars['String']['output'];
  monthYearOld: Scalars['String']['output'];
  name: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung: Scalars['String']['output'];
  rechnungsAusstellerId: Scalars['String']['output'];
  rechnungsAusstellerKurzBezeichnung: Scalars['String']['output'];
  status: VertragVposIndexedValueStatusTuple;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  vertragId: Scalars['String']['output'];
  vertragKurzBezeichnung: Scalars['String']['output'];
  vertragVposIndexedValueId: Scalars['ID']['output'];
  vertragspartnerId: Scalars['String']['output'];
  vertragspartnerKurzBezeichnung: Scalars['String']['output'];
  vorschreibungspositionId: Scalars['String']['output'];
  vposWertNewId?: Maybe<Scalars['String']['output']>;
  vposWertOldId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type VertragVposIndexedValueGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  type: VertragVposIndexedValueGenerierlaufTypeTuple;
  warningList: Array<WarningData>;
};

export type VertragVposIndexedValueGenerierlaufCreateInput = {
  allowPreliminaryValues?: InputMaybe<Scalars['Boolean']['input']>;
  bestandseinheitId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  validFrom?: InputMaybe<Scalars['String']['input']>;
  valuePreservationTypeList?: InputMaybe<Array<ValuePreservationType>>;
  vertragId?: InputMaybe<Scalars['String']['input']>;
  yearMonth?: InputMaybe<IndexSeriesPeriodInput>;
};

export type VertragVposIndexedValueGenerierlaufEntry = {
  bestandseinheitBezeichnung: Scalars['String']['output'];
  bestandseinheitId: Scalars['String']['output'];
  currentStep: Scalars['Int']['output'];
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  indexSeriesId: Scalars['String']['output'];
  indexSeriesName: Scalars['String']['output'];
  indexationAgreementId: Scalars['String']['output'];
  indexedValue?: Maybe<VertragVposIndexedValue>;
  infoMessage?: Maybe<Scalars['String']['output']>;
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung?: Maybe<Scalars['String']['output']>;
  rechnungsAusstellerBezeichnung: Scalars['String']['output'];
  rechnungsAusstellerId: Scalars['String']['output'];
  retry: Scalars['Boolean']['output'];
  stepCount: Scalars['Int']['output'];
  stepList: Array<GenerierlaufAsyncStep>;
  valuePreservationType: ValuePreservationTypeTuple;
  vertragBezeichnung: Scalars['String']['output'];
  vertragId: Scalars['String']['output'];
  vertragspartnerId: Scalars['String']['output'];
  vertragspartnerKurzBezeichnung: Scalars['String']['output'];
  warningList: Array<WarningData>;
};

export enum VertragVposIndexedValueGenerierlaufOrderBy {
  AmountNew = 'AMOUNT_NEW',
  AmountOld = 'AMOUNT_OLD',
  Bestandseinheit = 'BESTANDSEINHEIT',
  CalculatedAmountNew = 'CALCULATED_AMOUNT_NEW',
  CalculatedFactorNew = 'CALCULATED_FACTOR_NEW',
  FactorNew = 'FACTOR_NEW',
  IndexationAgreement = 'INDEXATION_AGREEMENT',
  IndexedValue = 'INDEXED_VALUE',
  IndexNew = 'INDEX_NEW',
  IndexNewPreliminary = 'INDEX_NEW_PRELIMINARY',
  IndexOld = 'INDEX_OLD',
  IndexOldPreliminary = 'INDEX_OLD_PRELIMINARY',
  IndexSeries = 'INDEX_SERIES',
  Objekt = 'OBJEKT',
  RechnungsAussteller = 'RECHNUNGS_AUSSTELLER',
  Status = 'STATUS',
  ValidFrom = 'VALID_FROM',
  ValuePreservationType = 'VALUE_PRESERVATION_TYPE',
  Vertrag = 'VERTRAG',
  Vertragspartner = 'VERTRAGSPARTNER',
  WertStatus = 'WERT_STATUS',
  YearMonthNew = 'YEAR_MONTH_NEW',
  YearMonthOld = 'YEAR_MONTH_OLD',
}

export type VertragVposIndexedValueGenerierlaufResponse = {
  data: VertragVposIndexedValueGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum VertragVposIndexedValueGenerierlaufType {
  Automatically = 'AUTOMATICALLY',
  Manually = 'MANUALLY',
}

export type VertragVposIndexedValueGenerierlaufTypeTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: VertragVposIndexedValueGenerierlaufType;
};

export type VertragVposIndexedValueListEntryPage = {
  contentList: Array<VertragVposIndexedValueGenerierlaufEntry>;
  page: Page;
};

export type VertragVposIndexedValueListEntryPageResponse = {
  data: VertragVposIndexedValueListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragVposIndexedValueListPage = {
  contentList: Array<VertragVposIndexedValue>;
  page: Page;
};

export type VertragVposIndexedValueListPageResponse = {
  data: VertragVposIndexedValueListPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragVposIndexedValueListResponse = {
  data: Array<VertragVposIndexedValue>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragVposIndexedValueListSummary = {
  sumAmountNew: Scalars['Float']['output'];
  sumAmountOld: Scalars['Float']['output'];
};

export type VertragVposIndexedValueListSummaryResponse = {
  data: VertragVposIndexedValueListSummary;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum VertragVposIndexedValueOrderBy {
  AmountNew = 'AMOUNT_NEW',
  AmountOld = 'AMOUNT_OLD',
  Bestandseinheit = 'BESTANDSEINHEIT',
  CalculatedAmountNew = 'CALCULATED_AMOUNT_NEW',
  CalculatedFactorNew = 'CALCULATED_FACTOR_NEW',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  FactorNew = 'FACTOR_NEW',
  IndexationAgreement = 'INDEXATION_AGREEMENT',
  IndexedValue = 'INDEXED_VALUE',
  IndexNew = 'INDEX_NEW',
  IndexNewPreliminary = 'INDEX_NEW_PRELIMINARY',
  IndexOld = 'INDEX_OLD',
  IndexOldPreliminary = 'INDEX_OLD_PRELIMINARY',
  IndexSeries = 'INDEX_SERIES',
  Objekt = 'OBJEKT',
  RechnungsAussteller = 'RECHNUNGS_AUSSTELLER',
  Status = 'STATUS',
  ValidFrom = 'VALID_FROM',
  ValuePreservationType = 'VALUE_PRESERVATION_TYPE',
  Vertrag = 'VERTRAG',
  Vertragspartner = 'VERTRAGSPARTNER',
  YearMonthNew = 'YEAR_MONTH_NEW',
  YearMonthOld = 'YEAR_MONTH_OLD',
}

export type VertragVposIndexedValueResponse = {
  data: VertragVposIndexedValue;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum VertragVposIndexedValueStatus {
  Applied = 'APPLIED',
  NotApplied = 'NOT_APPLIED',
  Submitted = 'SUBMITTED',
}

export type VertragVposIndexedValueStatusListResponse = {
  data: Array<VertragVposIndexedValueStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragVposIndexedValueStatusTuple = {
  text: Scalars['String']['output'];
  value: VertragVposIndexedValueStatus;
};

export type VertragVposIndexedValueUpdateInput = {
  amount: Scalars['Float']['input'];
  factorPercent: Scalars['Float']['input'];
  validFrom: Scalars['String']['input'];
};

export type VertragVposIndexedValueUpdateStatusBulkActionInput = {
  bestandseinheitId?: InputMaybe<Scalars['String']['input']>;
  indexSeriesId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  validFrom?: InputMaybe<Scalars['String']['input']>;
  validTo?: InputMaybe<Scalars['String']['input']>;
  valuePreservationTypeList?: InputMaybe<Array<ValuePreservationType>>;
  vertragId?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
  vorschreibungspositionName?: InputMaybe<Scalars['String']['input']>;
};

export type VertragVposIndexedValueUpdateValidFromBulkActionInput = {
  bestandseinheitId?: InputMaybe<Scalars['String']['input']>;
  indexSeriesId?: InputMaybe<Scalars['String']['input']>;
  objektId?: InputMaybe<Scalars['String']['input']>;
  rechnungsAusstellerId?: InputMaybe<Scalars['String']['input']>;
  statusList?: InputMaybe<Array<VertragVposIndexedValueStatus>>;
  validFrom?: InputMaybe<Scalars['String']['input']>;
  validFromInputMonth?: InputMaybe<Scalars['String']['input']>;
  validFromInputYear?: InputMaybe<Scalars['String']['input']>;
  validTo?: InputMaybe<Scalars['String']['input']>;
  valuePreservationTypeList?: InputMaybe<Array<ValuePreservationType>>;
  vertragId?: InputMaybe<Scalars['String']['input']>;
  vertragspartnerId?: InputMaybe<Scalars['String']['input']>;
  vorschreibungspositionName?: InputMaybe<Scalars['String']['input']>;
};

export enum Vertragsart {
  Eigennutzung = 'EIGENNUTZUNG',
  Leerstehung = 'LEERSTEHUNG',
  Mieter = 'MIETER',
  Pauschalmieter = 'PAUSCHALMIETER',
  WegEigentuemer = 'WEG_EIGENTUEMER',
  WegLeerstehung = 'WEG_LEERSTEHUNG',
  WegMieter = 'WEG_MIETER',
  WegPauschalmieter = 'WEG_PAUSCHALMIETER',
}

export type VertragsartListResponse = {
  data: Array<VertragsartTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VertragsartTuple = {
  supportsExplicitVertragsEnde?: Maybe<Scalars['Boolean']['output']>;
  text: Scalars['String']['output'];
  value: Vertragsart;
};

export type VertragspartnerKonto = {
  konto: Konto;
  kurzBezeichnung: Scalars['String']['output'];
  rechtstraegerId: Scalars['String']['output'];
};

export type VertragspartnerKontoListResponse = {
  data: Array<VertragspartnerKonto>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum Verwaltungsart {
  MietObjekt = 'MIET_OBJEKT',
  WegObjekt = 'WEG_OBJEKT',
}

export type VerwaltungsartResponse = {
  data: Array<VerwaltungsartTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VerwaltungsartTuple = {
  text: Scalars['String']['output'];
  value: Verwaltungsart;
};

export type VidValidation = {
  city?: Maybe<Scalars['String']['output']>;
  countryCodeIso2?: Maybe<Scalars['String']['output']>;
  errorMsg?: Maybe<Scalars['String']['output']>;
  houseEntranceApartmentNumber?: Maybe<Scalars['String']['output']>;
  name1?: Maybe<Scalars['String']['output']>;
  name2?: Maybe<Scalars['String']['output']>;
  name3?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type VidValidationResponse = {
  data: VidValidation;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDelivery = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  emailDeliveryDefinitionBezeichnung: Scalars['String']['output'];
  emailDeliveryDefinitionId: Scalars['String']['output'];
  emailDeliveryStatus: EmailDeliveryStatusTuple;
  fakturierungsperiode: Fakturierungsperiode;
  mailId?: Maybe<Scalars['String']['output']>;
  objektBestandseinheitVertragList: Array<ObjektBestandseinheitVertrag>;
  original: Scalars['Boolean']['output'];
  recipient: VorschreibungRechtstraeger;
  resentDeliveryList?: Maybe<Array<VorschreibungMailDelivery>>;
  sender?: Maybe<Scalars['String']['output']>;
  sentTs?: Maybe<Scalars['String']['output']>;
  shouldNeverSend: Scalars['Boolean']['output'];
  shouldSend: Scalars['Boolean']['output'];
  sourceDelivery?: Maybe<VorschreibungMailDelivery>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragspartner: VorschreibungRechtstraeger;
  vorschreibungMailDeliveryId: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export enum VorschreibungMailDeliveryDetermineRecipientGenLaufOrderBy {
  Email = 'EMAIL',
  Empfaenger = 'EMPFAENGER',
  Objekt = 'OBJEKT',
  Status = 'STATUS',
  Vertragspartner = 'VERTRAGSPARTNER',
}

export type VorschreibungMailDeliveryDetermineRecipientGenerierlaufListEntryPage = {
  contentList: Array<VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntry>;
  page: Page;
};

export type VorschreibungMailDeliveryDetermineRecipientGenerierlaufListEntryPageResponse = {
  data: VorschreibungMailDeliveryDetermineRecipientGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDeliveryDetermineRecipientListGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntry = {
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  retry: Scalars['Boolean']['output'];
  vorschreibungMailDelivery?: Maybe<VorschreibungMailDelivery>;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufResponse = {
  data: VorschreibungMailDeliveryDetermineRecipientListGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDeliveryPage = {
  contentList: Array<VorschreibungMailDelivery>;
  page: Page;
};

export type VorschreibungMailDeliveryPageResponse = {
  data: VorschreibungMailDeliveryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum VorschreibungMailDeliveryRecipientListStepOrderBy {
  Email = 'EMAIL',
  Fakturierungsperiode = 'FAKTURIERUNGSPERIODE',
  OriginalKopie = 'ORIGINAL_KOPIE',
  Recipient = 'RECIPIENT',
  Vertragspartner = 'VERTRAGSPARTNER',
}

export type VorschreibungMailDeliveryResponse = {
  data: VorschreibungMailDelivery;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum VorschreibungMailDeliverySendGenLaufOrderBy {
  Email = 'EMAIL',
  Empfaenger = 'EMPFAENGER',
  Objekt = 'OBJEKT',
  Status = 'STATUS',
  Vertragspartner = 'VERTRAGSPARTNER',
}

export type VorschreibungMailDeliverySendGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDeliverySendGenerierlaufEntry = {
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  vorschreibungMailDelivery?: Maybe<VorschreibungMailDelivery>;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDeliverySendGenerierlaufListEntryPage = {
  contentList: Array<VorschreibungMailDeliverySendGenerierlaufEntry>;
  page: Page;
};

export type VorschreibungMailDeliverySendGenerierlaufListEntryPageResponse = {
  data: VorschreibungMailDeliverySendGenerierlaufListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDeliverySendGenerierlaufResponse = {
  data: VorschreibungMailDeliverySendGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDeliveryWizardAttachmentListResponse = {
  data: Array<VorschreibungMailDeliveryWizardFileInfo>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDeliveryWizardFileInfo = {
  deletable: Scalars['Boolean']['output'];
  fileId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export type VorschreibungMailDeliveryWizardRecipientListStep = {
  data: VorschreibungMailDeliveryWizardRecipientListStep;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDeliveryWizardRecipientListStepResponse = {
  data: VorschreibungMailDeliveryWizardRecipientListStep;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungMailDeliveryWizardTemplateStep = {
  attachmentInfoList: Array<VorschreibungMailDeliveryWizardFileInfo>;
  body?: Maybe<Textbaustein>;
  completed: Scalars['Boolean']['output'];
  currentStep: Scalars['Int']['output'];
  emailDeliveryDefinitionBezeichnung: Scalars['String']['output'];
  emailDeliveryDefinitionId: Scalars['String']['output'];
  stepCount: Scalars['Int']['output'];
  subject: Textbaustein;
  wizardId: Scalars['ID']['output'];
};

export type VorschreibungMailDeliveryWizardTemplateStepResponse = {
  data: VorschreibungMailDeliveryWizardTemplateStep;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungRechtstraeger = {
  kurzBezeichnung: Scalars['String']['output'];
  rechtstraegerId: Scalars['ID']['output'];
  type: RechtstraegerTypeTuple;
};

export type Vorschreibungsperiode = {
  jahr: Scalars['Int']['output'];
  monat: Scalars['Int']['output'];
};

export type VorschreibungsperiodeInput = {
  jahr: Scalars['Int']['input'];
  monat: Scalars['Int']['input'];
};

export type VorschreibungsperiodeListResponse = {
  data: Array<Vorschreibungsperiode>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Vorschreibungsposition = {
  art: VorschreibungspositionArtTuple;
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kontierungstabelle?: Maybe<KontierungstabelleBase>;
  kurzBezeichnung: Scalars['String']['output'];
  status: VorschreibungspositionStatusTuple;
  tagList: Array<Scalars['String']['output']>;
  umsatzsteuerkennzeichen?: Maybe<Umsatzsteuerkennzeichen>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vorschreibungspositionId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export enum VorschreibungspositionArt {
  Abrechnungsergebnis = 'ABRECHNUNGSERGEBNIS',
  Betriebskosten = 'BETRIEBSKOSTEN',
  DotRepFond = 'DOT_REP_FOND',
  Heizkosten = 'HEIZKOSTEN',
  Miete = 'MIETE',
  Nachverrechnung = 'NACHVERRECHNUNG',
}

export type VorschreibungspositionArtListResponse = {
  data: Array<VorschreibungspositionArtTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungspositionArtTuple = {
  text: Scalars['String']['output'];
  value: VorschreibungspositionArt;
};

export enum VorschreibungspositionBasis {
  Brutto = 'BRUTTO',
  Netto = 'NETTO',
}

export type VorschreibungspositionBasisTuple = {
  text: Scalars['String']['output'];
  value: VorschreibungspositionBasis;
};

export type VorschreibungspositionCreateInput = {
  art: Scalars['String']['input'];
  bezeichnung: Scalars['String']['input'];
  kurzBezeichnung: Scalars['String']['input'];
  tagList: Array<Scalars['String']['input']>;
  version?: InputMaybe<VorschreibungspositionVersionCreateInput>;
};

export type VorschreibungspositionListResponse = {
  data: Array<Vorschreibungsposition>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungspositionPage = {
  contentList: Array<Vorschreibungsposition>;
  page: Page;
};

export type VorschreibungspositionPageResponse = {
  data: VorschreibungspositionPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungspositionResponse = {
  data: Vorschreibungsposition;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum VorschreibungspositionStatus {
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
}

export type VorschreibungspositionStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: VorschreibungspositionStatus;
};

export type VorschreibungspositionUpdateInput = {
  art: Scalars['String']['input'];
  bezeichnung: Scalars['String']['input'];
  kurzBezeichnung: Scalars['String']['input'];
  tagList: Array<Scalars['String']['input']>;
};

export type VorschreibungspositionUstKategorieEntry = {
  ustKategorie: UstKategorie;
  ustKategorieEntry: UstKategorieEntry;
};

export type VorschreibungspositionUstKategorieEntryResponse = {
  data?: Maybe<VorschreibungspositionUstKategorieEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungspositionVersion = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  historicizedList?: Maybe<Array<VorschreibungspositionVersion>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  kontierungstabelle: Kontierungstabelle;
  lastUpdateTs: Scalars['String']['output'];
  umsatzsteuerkennzeichen: Umsatzsteuerkennzeichen;
  validFrom: Scalars['String']['output'];
  vorschreibungspositionVersionId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type VorschreibungspositionVersionCreateInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  kontierungstabelleId: Scalars['String']['input'];
  umsatzsteuerkennzeichenId: Scalars['String']['input'];
  validFrom: Scalars['String']['input'];
};

export type VorschreibungspositionVersionListResponse = {
  data: Array<VorschreibungspositionVersion>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungspositionVersionResponse = {
  data: VorschreibungspositionVersion;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorschreibungspositionVersionUpdateInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  kontierungstabelleId: Scalars['String']['input'];
  umsatzsteuerkennzeichenId: Scalars['String']['input'];
  validFrom: Scalars['String']['input'];
};

export enum VorschreibungspositionWertBasis {
  Brutto = 'BRUTTO',
  Netto = 'NETTO',
}

export type VorschreibungspositionWertBasisTuple = {
  text: Scalars['String']['output'];
  value: VorschreibungspositionWertBasis;
};

export type VorschreibungspositionWertInput = {
  basis: Scalars['String']['input'];
  errechneteUst: Scalars['Float']['input'];
  validFrom: Scalars['String']['input'];
  wert: Scalars['Float']['input'];
};

export type Vorsteuerkuerzung = {
  belegnummer?: Maybe<Scalars['String']['output']>;
  bisInklusive: Scalars['String']['output'];
  buchungErrorList: Array<ErrorData>;
  buchungStatus: VorsteuerkuerzungBuchungStatusTuple;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  fibuBuchungIdList: Array<Scalars['String']['output']>;
  lastBuchungsdatum?: Maybe<Scalars['String']['output']>;
  objekt: ObjektBase;
  rechnungsAussteller: RechtstraegerBase;
  status: VorsteuerkuerzungStatusTuple;
  sumBetrag: Scalars['Float']['output'];
  sumVstKuerzung: Scalars['Float']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragsart: VertragsartTuple;
  vonInklusive: Scalars['String']['output'];
  vorsteuerkuerzungId: Scalars['ID']['output'];
  vorsteuerkuerzungVertragList: Array<VorsteuerkuerzungVertrag>;
  warningList: Array<ResponseDataWarning>;
};

export type VorsteuerkuerzungActionApplyDeviationsInput = {
  abgrenzungsBuchung: Scalars['Boolean']['input'];
  stornoAbgrenzung?: InputMaybe<VorsteuerkuerzungActionApplyDeviationsStornoAbgrenzungInput>;
  stornoDirekt?: InputMaybe<VorsteuerkuerzungActionApplyDeviationsStornoDirektInput>;
};

export type VorsteuerkuerzungActionApplyDeviationsStornoAbgrenzungInput = {
  korrekturAbgrenzungsBuchungsdatum: Scalars['String']['input'];
  korrekturBuchungsdatum: Scalars['String']['input'];
  stornoAbgrenzungsBuchungsdatum: Scalars['String']['input'];
  stornoBuchungsdatum: Scalars['String']['input'];
};

export type VorsteuerkuerzungActionApplyDeviationsStornoDirektInput = {
  korrekturBuchungsdatum: Scalars['String']['input'];
  stornoBuchungsdatum: Scalars['String']['input'];
};

export type VorsteuerkuerzungActionFreigebenInput = {
  buchungsdatum: Scalars['String']['input'];
};

export type VorsteuerkuerzungAufteilung = {
  anteilTopAufteilungsschluessel: Scalars['Float']['output'];
  aufteilungsschluessel: Aufteilungsschluessel;
  belegFileId: Scalars['String']['output'];
  belegId: Scalars['String']['output'];
  belegnummer: Scalars['String']['output'];
  buchungId: Scalars['String']['output'];
  buchungsdatum: Scalars['String']['output'];
  einheitskosten: Scalars['Float']['output'];
  gesamtAnteilAufteilungsschluessel: Scalars['Float']['output'];
  lieferant: RechtstraegerBase;
  standingInvoicePartialAmountId?: Maybe<Scalars['String']['output']>;
  steuer: Scalars['Float']['output'];
  vstKuerzungAnteil: Scalars['Float']['output'];
};

export type VorsteuerkuerzungAufwandKonto = {
  aufteilungList: Array<VorsteuerkuerzungAufteilung>;
  aufwandsKontoId: Scalars['ID']['output'];
  sumSteuer: Scalars['Float']['output'];
  sumVstKuerzung: Scalars['Float']['output'];
};

export enum VorsteuerkuerzungBuchungStatus {
  NichtVerbucht = 'NICHT_VERBUCHT',
  VerbuchenFehler = 'VERBUCHEN_FEHLER',
  Verbucht = 'VERBUCHT',
  WirdVerbucht = 'WIRD_VERBUCHT',
}

export type VorsteuerkuerzungBuchungStatusListResponse = {
  data: Array<VorsteuerkuerzungBuchungStatusTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorsteuerkuerzungBuchungStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: VorsteuerkuerzungBuchungStatus;
};

export type VorsteuerkuerzungDeviationListResponse = {
  data: Array<Deviation>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorsteuerkuerzungDeviationSourceEntity = AbstractDeviationSourceEntity & {
  bestandseinheitBezeichnung: Scalars['String']['output'];
  entityId: Scalars['ID']['output'];
  ids?: Maybe<VorsteuerkuerzungDeviationSourceEntityIds>;
  objektBezeichnung: Scalars['String']['output'];
  rechnungsAusstellerKurzBezeichnung: Scalars['String']['output'];
  type: DeviationSourceEntityTypeTuple;
  vertragsBeginn: Scalars['String']['output'];
  vertragsEnde?: Maybe<Scalars['String']['output']>;
  vertragspartnerKurzBezeichnung: Scalars['String']['output'];
};

export type VorsteuerkuerzungDeviationSourceEntityIds = {
  bestandseinheitId: Scalars['String']['output'];
  linkText: Scalars['String']['output'];
  objektId: Scalars['String']['output'];
  vertragId: Scalars['String']['output'];
};

export type VorsteuerkuerzungEinstellung = {
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  historicizedList?: Maybe<Array<VorsteuerkuerzungEinstellung>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  kontenZuweisungList: Array<VorsteuerkuerzungKontenZuweisung>;
  lastUpdateTs: Scalars['String']['output'];
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  versionIndex: Scalars['Int']['output'];
  vorsteuerkuerzungEinstellungId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type VorsteuerkuerzungEinstellungCreateInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  kontenZuweisungList: Array<VorsteuerkuerzungKontenZuweisungCreateInput>;
  validFrom: Scalars['String']['input'];
  verwaltungsart: Verwaltungsart;
};

export type VorsteuerkuerzungEinstellungListResponse = {
  data: Array<VorsteuerkuerzungEinstellung>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorsteuerkuerzungEinstellungResponse = {
  data: VorsteuerkuerzungEinstellung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorsteuerkuerzungEinstellungUpdateInput = {
  kommentar?: InputMaybe<Scalars['String']['input']>;
  kontenZuweisungList: Array<VorsteuerkuerzungKontenZuweisungUpdateInput>;
  validFrom: Scalars['String']['input'];
  verwaltungsart: Verwaltungsart;
};

export type VorsteuerkuerzungGenerierlauf = {
  bisInklusive: Scalars['String']['output'];
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  entryList: Array<VorsteuerkuerzungGenerierlaufEntry>;
  errorList: Array<ErrorData>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  objekt: ObjektBase;
  status: GenerierlaufStatusTuple;
  vonInklusive: Scalars['String']['output'];
  warningList: Array<WarningData>;
};

export type VorsteuerkuerzungGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  monat: Scalars['String']['output'];
  objekt: ObjektBase;
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  vertragsart: VertragsartTuple;
  vorsteuerkuerzung?: Maybe<Vorsteuerkuerzung>;
  vorsteuerkuerzungByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vorsteuerkuerzungDeleted: Scalars['Boolean']['output'];
  vorsteuerkuerzungDeletedBy?: Maybe<Scalars['String']['output']>;
  vorsteuerkuerzungDeletedTs?: Maybe<Scalars['String']['output']>;
  warningList: Array<WarningData>;
};

export type VorsteuerkuerzungGenerierlaufInput = {
  bisInklusive: Scalars['String']['input'];
  objektId: Scalars['String']['input'];
  vonInklusive: Scalars['String']['input'];
};

export type VorsteuerkuerzungGenerierlaufResponse = {
  data: VorsteuerkuerzungGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorsteuerkuerzungKontenZuweisung = {
  aufteilungsschluessel: Aufteilungsschluessel;
  aufwandsKontoList: Array<AppKonto>;
  vorsteuerkuerzungEigennutzungKonto?: Maybe<AppKonto>;
  vorsteuerkuerzungKontenZuweisungId: Scalars['ID']['output'];
  vorsteuerkuerzungKonto: AppKonto;
  vorsteuerkuerzungPauschalmieterKonto?: Maybe<AppKonto>;
  vorsteuerkuerzungWeMieterKonto?: Maybe<AppKonto>;
};

export type VorsteuerkuerzungKontenZuweisungCreateInput = {
  aufteilungsschluesselId: Scalars['String']['input'];
  aufwandsKontoIdList: Array<Scalars['String']['input']>;
  vorsteuerkuerzungEigennutzungKontoId?: InputMaybe<Scalars['String']['input']>;
  vorsteuerkuerzungKontoId: Scalars['String']['input'];
  vorsteuerkuerzungPauschalmieterKontoId?: InputMaybe<Scalars['String']['input']>;
  vorsteuerkuerzungWeMieterKontoId?: InputMaybe<Scalars['String']['input']>;
};

export type VorsteuerkuerzungKontenZuweisungUpdateInput = {
  aufteilungsschluesselId: Scalars['String']['input'];
  aufwandsKontoIdList: Array<Scalars['String']['input']>;
  vorsteuerkuerzungEigennutzungKontoId?: InputMaybe<Scalars['String']['input']>;
  vorsteuerkuerzungKontenZuweisungId?: InputMaybe<Scalars['String']['input']>;
  vorsteuerkuerzungKontoId: Scalars['String']['input'];
  vorsteuerkuerzungPauschalmieterKontoId?: InputMaybe<Scalars['String']['input']>;
  vorsteuerkuerzungWeMieterKontoId?: InputMaybe<Scalars['String']['input']>;
};

export type VorsteuerkuerzungListEntry = {
  bisInklusive: Scalars['String']['output'];
  buchungStatus: VorsteuerkuerzungBuchungStatusTuple;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  rechnungsAussteller: RechtstraegerBase;
  status: VorsteuerkuerzungStatusTuple;
  sumVstKuerzung: Scalars['Float']['output'];
  vertragsart: VertragsartTuple;
  vonInklusive: Scalars['String']['output'];
  vorsteuerkuerzungId: Scalars['ID']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type VorsteuerkuerzungListEntryListResponse = {
  data: Array<VorsteuerkuerzungListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type VorsteuerkuerzungResponse = {
  data: Vorsteuerkuerzung;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum VorsteuerkuerzungStatus {
  Invalid = 'INVALID',
  ReviewError = 'REVIEW_ERROR',
  UnderReview = 'UNDER_REVIEW',
  Valid = 'VALID',
}

export type VorsteuerkuerzungStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: VorsteuerkuerzungStatus;
};

export type VorsteuerkuerzungVertrag = {
  bestandseinheit: BestandseinheitBase;
  fibuBuchungIdList: Array<Scalars['String']['output']>;
  sumSteuer: Scalars['Float']['output'];
  sumVstKuerzung: Scalars['Float']['output'];
  vertrag: VertragBase;
  vorsteuerkuerzungKontoList: Array<VorsteuerkuerzungVstKrzKonto>;
};

export type VorsteuerkuerzungVstKrzKonto = {
  aufwandsKontoList: Array<VorsteuerkuerzungAufwandKonto>;
  sumSteuer: Scalars['Float']['output'];
  sumVstKuerzung: Scalars['Float']['output'];
  vorsteuerkuerzungKontoId: Scalars['ID']['output'];
};

export enum Waehrung {
  Eur = 'EUR',
}

export type WaehrungTuple = {
  text: Scalars['String']['output'];
  value: Waehrung;
};

export type WarningData = {
  message: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type WeAbrechnungsdefinition = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreis>;
  abrechnungsdefinitionId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kontoAbrechnungskreisDataList: Array<KontoAbrechnungskreis>;
  objektAbrechnungTextbausteinList: Array<Textbaustein>;
  status: AbrechnungsdefinitionStatusTuple;
  topAbrechnungTextbausteinList: Array<Textbaustein>;
  updatedByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type WeAbrechnungsdefinitionCreateInput = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreisInput>;
  bezeichnung: Scalars['String']['input'];
  objektAbrechnungOutputOptions: ObjektAbrechnungOutputOptionsInput;
  objektAbrechnungTextbausteinList: Array<TextbausteinInput>;
  topAbrechnungOutputOptions: TopAbrechnungOutputOptionsInput;
  topAbrechnungTextbausteinList: Array<TextbausteinInput>;
  validFrom: Scalars['String']['input'];
};

export type WeAbrechnungsdefinitionResponse = {
  data: WeAbrechnungsdefinition;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type WeAbrechnungsdefinitionUpdateInput = {
  bezeichnung: Scalars['String']['input'];
};

export type WeAbrechnungsdefinitionVersion = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreis>;
  abrechnungsdefinitionVersionId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  kommentar?: Maybe<Scalars['String']['output']>;
  kontoAbrechnungskreisList: Array<KontoAbrechnungskreis>;
  lastUpdateTs: Scalars['String']['output'];
  objektAbrechnungOutputOptions: ObjektAbrechnungOutputOptions;
  objektAbrechnungTextbausteinList: Array<Textbaustein>;
  topAbrechnungOutputOptions: TopAbrechnungOutputOptions;
  topAbrechnungTextbausteinList: Array<Textbaustein>;
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type WeAbrechnungsdefinitionVersionCreateInput = {
  abrechdefAbrechkreisList: Array<InputMaybe<AbrechdefAbrechkreisInput>>;
  kommentar?: InputMaybe<Scalars['String']['input']>;
  objektAbrechnungOutputOptions: ObjektAbrechnungOutputOptionsInput;
  objektAbrechnungTextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  topAbrechnungOutputOptions: TopAbrechnungOutputOptionsInput;
  topAbrechnungTextbausteinList: Array<InputMaybe<TextbausteinInput>>;
  validFrom: Scalars['String']['input'];
};

export type WeAbrechnungsdefinitionVersionListEntry = {
  abrechnungsdefinitionVersionId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  current: Scalars['Boolean']['output'];
  deletable?: Maybe<Scalars['Boolean']['output']>;
  firmendatenId?: Maybe<Scalars['String']['output']>;
  historicizedList?: Maybe<Array<WeAbrechnungsdefinitionVersionListEntry>>;
  kommentar?: Maybe<Scalars['String']['output']>;
  lastUpdateTs: Scalars['String']['output'];
  textBausteineBefuellt: Scalars['Boolean']['output'];
  validFrom: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
};

export type WeAbrechnungsdefinitionVersionListEntryResponse = {
  data: Array<WeAbrechnungsdefinitionVersionListEntry>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type WeAbrechnungsdefinitionVersionResponse = {
  data: WeAbrechnungsdefinitionVersion;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type WeAbrechnungsdefinitionVersionUpdateInput = {
  abrechdefAbrechkreisList: Array<AbrechdefAbrechkreisInput>;
  kommentar?: InputMaybe<Scalars['String']['input']>;
  kontoAbrechnungskreisList: Array<AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput>;
  objektAbrechnungOutputOptions: ObjektAbrechnungOutputOptionsInput;
  objektAbrechnungTextbausteinList: Array<TextbausteinInput>;
  topAbrechnungOutputOptions: TopAbrechnungOutputOptionsInput;
  topAbrechnungTextbausteinList: Array<TextbausteinInput>;
  validFrom: Scalars['String']['input'];
};

export type WeRechnungsAussteller = {
  objektBankDetailsList: Array<ObjektBankDetails>;
  warningList: Array<ResponseDataWarning>;
  weRechnungsAusstellerFibuKontoCreatable: Scalars['Boolean']['output'];
  weRechnungsAusstellerKurzBezeichnung: Scalars['String']['output'];
  weRechnungsAusstellerRechtstraegerId: Scalars['ID']['output'];
};

export type WeRechnungsAusstellerListResponse = {
  data: Array<WeRechnungsAussteller>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Wizard = {
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  currentStep: Scalars['Int']['output'];
  status: WizardStatusTuple;
  stepCount: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  wizardId: Scalars['String']['output'];
};

export type WizardFileInfo = {
  deletable?: Maybe<Scalars['Boolean']['output']>;
  fileId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export enum WizardOrderBy {
  CreatedByMitarbeiterId = 'CREATED_BY_MITARBEITER_ID',
  CreateTs = 'CREATE_TS',
  CurrentStep = 'CURRENT_STEP',
  Status = 'STATUS',
  Type = 'TYPE',
}

export type WizardPage = {
  contentList: Array<Wizard>;
  page: Page;
};

export type WizardPageResponse = {
  data: WizardPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum WizardStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
}

export type WizardStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: WizardStatus;
};

export type WizardTypesResponse = {
  data: Array<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum Zahlungsperiode {
  Jaehrlich = 'JAEHRLICH',
  Monatlich = 'MONATLICH',
}

export type ZahlungsperiodeListResponse = {
  data: Array<ZahlungsperiodeTuple>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ZahlungsperiodeTuple = {
  text: Scalars['String']['output'];
  value: Zahlungsperiode;
};

export type Zinsliste = {
  bestandseinheitList: Array<ZinslisteBestandseinheit>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  kopfdaten: ZinslisteKopfdaten;
  status: ZinslisteStatusTuple;
  sumBrutto?: Maybe<Scalars['Float']['output']>;
  sumNetto?: Maybe<Scalars['Float']['output']>;
  sumUst?: Maybe<Scalars['Float']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export enum ZinslisteActionType {
  ApplyAllDeviations = 'APPLY_ALL_DEVIATIONS',
  AuftragsPositionenZuruecksetzen = 'AUFTRAGS_POSITIONEN_ZURUECKSETZEN',
}

export type ZinslisteBeAufteilungsschluessel = {
  aufteilungsschluesselId: Scalars['ID']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  kurzBezeichnung: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
  wert: Scalars['Float']['output'];
};

export type ZinslisteBeVertrag = {
  ausstellerBankDetailsId?: Maybe<Scalars['String']['output']>;
  ausstellerId: Scalars['String']['output'];
  ausstellerKurzBezeichnung: Scalars['String']['output'];
  belegform: BelegFormTuple;
  consolidationCriteria?: Maybe<Scalars['String']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  dueDateVorschreibung: DueDateVorschreibungTuple;
  paymentMethod: PaymentMethodTuple;
  updatedByMitabeiterId?: Maybe<Scalars['String']['output']>;
  verrechnungsart: VerrechnungsartTuple;
  vertragId: Scalars['ID']['output'];
  vertragLevel: VertragLevelTuple;
  vertragVorschreibungspositionList: Array<ZinslisteVertragVorschreibungsposition>;
  vertragsart: VertragsartTuple;
  vertragspartner: RechtstraegerBase;
  vertragspartnerBankDetailsId?: Maybe<Scalars['String']['output']>;
  warningList: Array<ResponseDataWarning>;
};

export type ZinslisteBestandseinheit = {
  aufteilungsschluesselList: Array<ZinslisteBeAufteilungsschluessel>;
  bestandseinheitId: Scalars['ID']['output'];
  bezeichnung: Scalars['String']['output'];
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  vertragList: Array<ZinslisteBeVertrag>;
  warningList: Array<ResponseDataWarning>;
};

export type ZinslisteDeviationListResponse = {
  data: Array<Deviation>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ZinslisteGenerierlauf = {
  countFehler: Scalars['Int']['output'];
  countNichtVerarbeitet: Scalars['Int']['output'];
  countVerarbeitet: Scalars['Int']['output'];
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierDatum: Scalars['String']['output'];
  generierlaufId: Scalars['ID']['output'];
  status: GenerierlaufStatusTuple;
  vorschreibungsperiodeList: Array<Vorschreibungsperiode>;
  warningList: Array<WarningData>;
};

export type ZinslisteGenerierlaufCreateInput = {
  objektIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  vorschreibungsperiodeList: Array<VorschreibungsperiodeInput>;
};

export type ZinslisteGenerierlaufEntry = {
  currentStep?: Maybe<Scalars['Int']['output']>;
  errorList: Array<ErrorData>;
  exitCode?: Maybe<GenerierlaufEntryExitCodeTuple>;
  generierlaufEntryId: Scalars['ID']['output'];
  infoMessage?: Maybe<Scalars['String']['output']>;
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung: Scalars['String']['output'];
  retry: Scalars['Boolean']['output'];
  stepCount?: Maybe<Scalars['Int']['output']>;
  stepList: Array<GenerierlaufAsyncStep>;
  sumBrutto?: Maybe<Scalars['Float']['output']>;
  sumNetto?: Maybe<Scalars['Float']['output']>;
  vorschreibungsperiode: Vorschreibungsperiode;
  warningList: Array<WarningData>;
  zinsliste?: Maybe<Zinsliste>;
};

export type ZinslisteGenerierlaufEntryListPage = {
  contentList: Array<ZinslisteGenerierlaufEntry>;
  page: Page;
};

export type ZinslisteGenerierlaufEntryListPageResponse = {
  data: ZinslisteGenerierlaufEntryListPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum ZinslisteGenerierlaufOrderBy {
  Brutto = 'BRUTTO',
  Netto = 'NETTO',
  Objekt = 'OBJEKT',
  Status = 'STATUS',
  Vorschreibungsperiode = 'VORSCHREIBUNGSPERIODE',
}

export type ZinslisteGenerierlaufResponse = {
  data: ZinslisteGenerierlauf;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ZinslisteKopfdaten = {
  generierDatum: Scalars['String']['output'];
  generierlaufId?: Maybe<Scalars['String']['output']>;
  objektId: Scalars['String']['output'];
  objektKurzBezeichnung: Scalars['String']['output'];
  vorschreibungsperiode: Vorschreibungsperiode;
  zinslisteId: Scalars['ID']['output'];
};

export type ZinslisteListEntry = {
  countVorschreibungsposition?: Maybe<Scalars['Int']['output']>;
  createTs: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']['output']>;
  generierlaufId?: Maybe<Scalars['ID']['output']>;
  objektId: Scalars['ID']['output'];
  objektKurzbezeichnung: Scalars['String']['output'];
  status: ZinslisteStatusTuple;
  sumBrutto?: Maybe<Scalars['Float']['output']>;
  sumNetto?: Maybe<Scalars['Float']['output']>;
  sumUst?: Maybe<Scalars['Float']['output']>;
  vorschreibungsperiode: Vorschreibungsperiode;
  warningList: Array<ResponseDataWarning>;
  zinslisteId: Scalars['ID']['output'];
};

export type ZinslisteListEntryPage = {
  contentList: Array<ZinslisteListEntry>;
  page: Page;
};

export type ZinslisteListEntryPageResponse = {
  data: ZinslisteListEntryPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export enum ZinslisteOrderBy {
  Brutto = 'BRUTTO',
  CreatedBy = 'CREATED_BY',
  CreateTs = 'CREATE_TS',
  Netto = 'NETTO',
  ObjektKurzBezeichnung = 'OBJEKT_KURZ_BEZEICHNUNG',
  Status = 'STATUS',
  Vorschreibungsperiode = 'VORSCHREIBUNGSPERIODE',
}

export type ZinslisteResponse = {
  data: Zinsliste;
  errorList?: Maybe<Array<ErrorData>>;
  warningList?: Maybe<Array<WarningData>>;
};

export enum ZinslisteStatus {
  Invalid = 'INVALID',
  UnderReview = 'UNDER_REVIEW',
  Valid = 'VALID',
}

export type ZinslisteStatusTuple = {
  description?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  value: ZinslisteStatus;
};

export type ZinslisteVerarbeitungsstand = {
  countAusgegeben: Scalars['Int']['output'];
  countErstellt: Scalars['Int']['output'];
  countFreigegeben: Scalars['Int']['output'];
  countNichtErstellt: Scalars['Int']['output'];
  countUebertragen: Scalars['Int']['output'];
};

export type ZinslisteVerarbeitungsstandResponse = {
  data: ZinslisteVerarbeitungsstand;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ZinslisteVertragVorschreibungsposition = {
  bezeichnung: Scalars['String']['output'];
  brutto?: Maybe<Scalars['Float']['output']>;
  createTs: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByMitarbeiterId?: Maybe<Scalars['String']['output']>;
  netto?: Maybe<Scalars['Float']['output']>;
  performancePeriodFromInclusive: Scalars['String']['output'];
  performancePeriodToInclusive: Scalars['String']['output'];
  steuersatz?: Maybe<Scalars['Int']['output']>;
  ust?: Maybe<Scalars['Float']['output']>;
  vorschreibungspositionId: Scalars['String']['output'];
  warningList: Array<ResponseDataWarning>;
  zinslisteVertragVorschrPosId: Scalars['ID']['output'];
};
