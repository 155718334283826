import React, { FC } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useDeleteBestandseinheitAufteilungsschluesselWertMutation } from '../../gql/BestandseinheitAufteilungsschluesselMutations.types';
import { showSuccessMsgDelete, showWarningMessage } from '../../../../../components/message';
import { useBestandseinheitIds } from '../../../bestandseinheitIdsContext';
import BeAufteilungsschluesselWerteChangeHistoryListingTable from './BeAufteilungsschluesselWerteChangeHistoryListingTable';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import { useToggle } from '../../../../../hooks/useToggle';
import { BeAufteilingsschluesselWert } from '../../../../../types';
import { useEditableTable } from '../../../../../components/Table/EditableTableV2/editableTableContext';

type BeAufteilungsschluesselWertActionsProps = {
  aufteilungsschluesselWert: BeAufteilingsschluesselWert;
  aufteilungsschluesselWertList: BeAufteilingsschluesselWert[];
  aufteilungsschluesselId: string;
  onAction: () => void;
};

const BeAufteilungsschluesselWertActions: FC<BeAufteilungsschluesselWertActionsProps> = ({
  aufteilungsschluesselId,
  aufteilungsschluesselWert,
  aufteilungsschluesselWertList,
  onAction,
}) => {
  const { objektId, bestandseinheitId } = useBestandseinheitIds();

  const [runDelete, { loading }] = useDeleteBestandseinheitAufteilungsschluesselWertMutation({
    variables: {
      objektId,
      bestandseinheitId,
      aufteilungsschluesselId,
      beAufteilungsschluesselWertId: aufteilungsschluesselWert.beAufteilungsschluesselWertId
        ? aufteilungsschluesselWert.beAufteilungsschluesselWertId
        : '',
    },
    onCompleted: () => {
      showSuccessMsgDelete(`Wert`);
      onAction();
    },
    onError: (error) => showWarningMessage(error, 'Wert wurde nicht gelöscht'),
  });

  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const { onUpdate } = useEditableTable<BeAufteilingsschluesselWert>();
  const lastVersionId = aufteilungsschluesselWertList.find((wert) => wert.beAufteilungsschluesselWertId);
  const isLastVersion = lastVersionId
    ? lastVersionId.beAufteilungsschluesselWertId === aufteilungsschluesselWert.beAufteilungsschluesselWertId
    : false;

  // an entry can be manipulated if it's a real aufteilungsschluessel wert (has an beAufteilungsschluesselWertId)
  return (
    <>
      <Tooltip title={isLastVersion ? 'Bearbeiten' : ''}>
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => onUpdate(aufteilungsschluesselWert)}
          disabled={!isLastVersion}
          data-testid="aufteilungsschluesselWert-btn-edit"
        />
      </Tooltip>
      <Popconfirm
        title="Sind Sie sicher?"
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => runDelete()}
        disabled={!isLastVersion}
      >
        <Tooltip title={isLastVersion ? 'Löschen' : ''}>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            disabled={!isLastVersion}
            data-testid="aufteilungsschluesselWert-btn-delete"
            loading={loading}
          />
        </Tooltip>
      </Popconfirm>
      <Tooltip title={isLastVersion ? 'Änderungsverlauf' : ''}>
        <Button
          type="link"
          icon={<HistoryOutlined />}
          onClick={toggleChangeHistoryOpen}
          disabled={!isLastVersion}
          data-testid="aufteilungsschluesselWert-btn-history"
        />
      </Tooltip>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <BeAufteilungsschluesselWerteChangeHistoryListingTable
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          aufteilungsschluesselId={aufteilungsschluesselId}
          beAufteilungsschluesselWertId={aufteilungsschluesselWert.beAufteilungsschluesselWertId ?? ''}
        />
      </HistoryModal>
    </>
  );
};

export default BeAufteilungsschluesselWertActions;
