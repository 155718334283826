import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateKontoTemplateMutationVariables = Types.Exact<{
  input: Types.KontoCreateInput;
}>;

export type CreateKontoTemplateMutation = {
  createKontoTemplate: {
    data: { bezeichnung: string; kontoId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateKontoTemplateMutationVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
  input: Types.KontoUpdateInput;
}>;

export type UpdateKontoTemplateMutation = {
  updateKontoTemplate: {
    data: { kontoId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteKontoTemplateMutationVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
}>;

export type DeleteKontoTemplateMutation = {
  deleteKontoTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveKontoTemplateMutationVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
}>;

export type ArchiveKontoTemplateMutation = {
  actionArchiveKontoTemplate: {
    data: { bezeichnung: string; kontoId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveKontoTemplateMutationVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveKontoTemplateMutation = {
  actionUnarchiveKontoTemplate: {
    data: { bezeichnung: string; kontoId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetKontoTemplateTagListMutationVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
  input: Types.KontoTagListInput;
}>;

export type SetKontoTemplateTagListMutation = {
  actionSetKontoTemplateTagList: {
    data: { bezeichnung: string; kontoId: string; tagList: Array<string> };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateKontoTemplateDocument = gql`
  mutation CreateKontoTemplate($input: KontoCreateInput!) {
    createKontoTemplate(input: $input) {
      data {
        bezeichnung
        kontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateKontoTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateKontoTemplateMutation, CreateKontoTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKontoTemplateMutation, CreateKontoTemplateMutationVariables>(CreateKontoTemplateDocument, options);
}
export type CreateKontoTemplateMutationHookResult = ReturnType<typeof useCreateKontoTemplateMutation>;
export type CreateKontoTemplateMutationResult = Apollo.MutationResult<CreateKontoTemplateMutation>;
export type CreateKontoTemplateMutationOptions = Apollo.BaseMutationOptions<CreateKontoTemplateMutation, CreateKontoTemplateMutationVariables>;
export const UpdateKontoTemplateDocument = gql`
  mutation UpdateKontoTemplate($kontoId: ID!, $input: KontoUpdateInput!) {
    updateKontoTemplate(kontoId: $kontoId, input: $input) {
      data {
        kontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateKontoTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateKontoTemplateMutation, UpdateKontoTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateKontoTemplateMutation, UpdateKontoTemplateMutationVariables>(UpdateKontoTemplateDocument, options);
}
export type UpdateKontoTemplateMutationHookResult = ReturnType<typeof useUpdateKontoTemplateMutation>;
export type UpdateKontoTemplateMutationResult = Apollo.MutationResult<UpdateKontoTemplateMutation>;
export type UpdateKontoTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateKontoTemplateMutation, UpdateKontoTemplateMutationVariables>;
export const DeleteKontoTemplateDocument = gql`
  mutation DeleteKontoTemplate($kontoId: ID!) {
    deleteKontoTemplate(kontoId: $kontoId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteKontoTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteKontoTemplateMutation, DeleteKontoTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteKontoTemplateMutation, DeleteKontoTemplateMutationVariables>(DeleteKontoTemplateDocument, options);
}
export type DeleteKontoTemplateMutationHookResult = ReturnType<typeof useDeleteKontoTemplateMutation>;
export type DeleteKontoTemplateMutationResult = Apollo.MutationResult<DeleteKontoTemplateMutation>;
export type DeleteKontoTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteKontoTemplateMutation, DeleteKontoTemplateMutationVariables>;
export const ArchiveKontoTemplateDocument = gql`
  mutation ArchiveKontoTemplate($kontoId: ID!) {
    actionArchiveKontoTemplate(kontoId: $kontoId) {
      data {
        bezeichnung
        kontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveKontoTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveKontoTemplateMutation, ArchiveKontoTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveKontoTemplateMutation, ArchiveKontoTemplateMutationVariables>(ArchiveKontoTemplateDocument, options);
}
export type ArchiveKontoTemplateMutationHookResult = ReturnType<typeof useArchiveKontoTemplateMutation>;
export type ArchiveKontoTemplateMutationResult = Apollo.MutationResult<ArchiveKontoTemplateMutation>;
export type ArchiveKontoTemplateMutationOptions = Apollo.BaseMutationOptions<ArchiveKontoTemplateMutation, ArchiveKontoTemplateMutationVariables>;
export const UnarchiveKontoTemplateDocument = gql`
  mutation UnarchiveKontoTemplate($kontoId: ID!) {
    actionUnarchiveKontoTemplate(kontoId: $kontoId) {
      data {
        bezeichnung
        kontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveKontoTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveKontoTemplateMutation, UnarchiveKontoTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveKontoTemplateMutation, UnarchiveKontoTemplateMutationVariables>(UnarchiveKontoTemplateDocument, options);
}
export type UnarchiveKontoTemplateMutationHookResult = ReturnType<typeof useUnarchiveKontoTemplateMutation>;
export type UnarchiveKontoTemplateMutationResult = Apollo.MutationResult<UnarchiveKontoTemplateMutation>;
export type UnarchiveKontoTemplateMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveKontoTemplateMutation,
  UnarchiveKontoTemplateMutationVariables
>;
export const SetKontoTemplateTagListDocument = gql`
  mutation SetKontoTemplateTagList($kontoId: ID!, $input: KontoTagListInput!) {
    actionSetKontoTemplateTagList(kontoId: $kontoId, input: $input) {
      data {
        bezeichnung
        kontoId
        tagList
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetKontoTemplateTagListMutation(
  baseOptions?: Apollo.MutationHookOptions<SetKontoTemplateTagListMutation, SetKontoTemplateTagListMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetKontoTemplateTagListMutation, SetKontoTemplateTagListMutationVariables>(SetKontoTemplateTagListDocument, options);
}
export type SetKontoTemplateTagListMutationHookResult = ReturnType<typeof useSetKontoTemplateTagListMutation>;
export type SetKontoTemplateTagListMutationResult = Apollo.MutationResult<SetKontoTemplateTagListMutation>;
export type SetKontoTemplateTagListMutationOptions = Apollo.BaseMutationOptions<
  SetKontoTemplateTagListMutation,
  SetKontoTemplateTagListMutationVariables
>;
