import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useFilterFibuBzObjektListQuery } from '../gql/FibuBuchungszeileQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';

type Props = {
  rechtstraegerIdList?: string[] | null;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'placeholder' | 'size' | 'showSearch'>;

const FibuBzObjektListFormSelect: FC<Props> = ({ rechtstraegerIdList, ...restProps }) => {
  const { loading, data } = useFilterFibuBzObjektListQuery({
    variables: {
      rechtstraegerIdList,
    },
  });
  const objektList = data?.getFilterFibuBzObjektList.data ?? [];

  return (
    <Select {...restProps} size="middle" id={restProps.name} loading={loading} placeholder="" allowClear showSearch filterOption={selectFilterOption}>
      {objektList.map((obj) => (
        <Select.Option key={obj.objektId} value={obj.objektId}>
          <DataWithShortenedText maxTextLength={48} text={obj.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default FibuBzObjektListFormSelect;
