import { Flex } from 'rebass';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { Divider, Space, Spin, Tabs, TabsProps } from 'antd';
import {
  AbrDefAbrKreisListingFormValues,
  mapAbrechnungsdefinitionToAbrKreisListFormValues,
} from '../../../shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import { mapTextbausteinListToFormValues, TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import {
  AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput,
  AbrechnungsdefinitionType,
  BkAbrechnungsdefinitionVersionListEntry,
} from '../../../../../types';
import { mapAbrechnungsdefinitionToKontenZuordnungFormValues } from '../../../shared/abrDefKontenzuordnungMapper';
import { useToggle } from '../../../../../hooks/useToggle';
import { showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import AbrDefFormValidFrom, { formatAbrechnungsdefinitionValidFromValue } from '../../../shared/AbrDefFormValidFrom';
import TooltipTextTab from './shared/TooltipTextTab';
import AbrDefAbrKreisListingForm from '../../../shared/AbrDefAbrKreisListingForm/AbrDefAbrKreisListingForm';
import { URI_SYS_SETTINGS } from '../../../../../constants/configurationUriPaths';
import DefinitionUpdateFormButtons from '../../../../shared/DefinitionUpdateFormButtons';
import {
  getAlreadyTakenValidFromDates,
  onAbrechnungskreisChange,
  onAbrechnungskreisZuweisungDelete,
  updateActiveTabIfTemplateChanged,
} from './shared/abrDefVersionUpdateFormHelpers';
import AbrDefVersionUpdateTabWithButtons from './AbrDefVersionUpdateTabWithButtons';
import AbrDefKontenzuordnung from '../../../shared/AbrDefKontenzuordnung';
import { mapFormValuesToZuordnungList } from '../../../../AbrKontenzuordnung/Form/zuordnungKontoListingFormMapper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import AbrDefVersionFormKommentar from '../../../shared/AbrDefVersionFormKommentar';
import { useUpdateBkAbrechnungsdefinitionVersionMutation } from '../../../gql/BkAbrDef/BkAbrDefVersionMutations.types';
import { mapFormValuesToBkOrWeAbrechnungsdefinitionUpdateTab } from './abrDefVersionUpdateFormMapper';
import BkOrWeObjAbrDefTemplate from '../../../shared/Templates/shared/BkOrWe/BkOrWeObjAbrDefTemplate';
import {
  mapObjAbrDefOutputOptionsToFormValues,
  ObjAbrDefGeneralSettingsFormValues,
} from '../../../shared/Templates/shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import {
  mapTopAbrDefGeneralSettingsToInitialValues,
  TopAbrDefGeneralSettingsFormValues,
} from '../../../shared/Templates/shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import BkOrWeTopAbrDefTemplate from '../../../shared/Templates/shared/BkOrWe/BkOrWeTopAbrDefTemplate';
import { BkAbrechnungsdefinitionVersionFieldsFragment } from '../../../gql/BkAbrDef/BkAbrDefVersionFragments.types';
import { useBkAbrDefVersionFieldHelp } from '../../../abrDefFieldHelpHelpers/useBkAbrDefVersionFieldHelp';

type Props = {
  abrechnungsdefinitionVersionList: BkAbrechnungsdefinitionVersionListEntry[];
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersion: BkAbrechnungsdefinitionVersionFieldsFragment;
  validFrom: string;
  kommentar: string;
  onSuccess: () => void;
  firmendatenId: string;
};

const ABR_DEF_VERSION_TAB_KEYS = {
  abrechnungskreise: {
    text: 'Abrechnungskreise',
    key: '1',
  },
  objektabrechnung: {
    text: 'BK-Objektabrechnung',
    key: '2',
  },
  topabrechnung: {
    text: 'BK-Topabrechnung',
    key: '3',
  },
  kontenzuordnung: {
    text: 'Kontenzuordnung',
    key: '4',
  },
};

const BkAbrDefVersionUpdateForm: FC<Props> = ({
  abrechnungsdefinitionVersionList,
  abrechnungsdefinitionId,
  abrechnungsdefinitionVersion,
  validFrom,
  kommentar,
  onSuccess,
  firmendatenId,
}) => {
  const { fieldHelpAbrDefAbrechkreisList, fieldHelpAbrDefKontoAbrechkreisList, fieldHelpNamesObjekt, fieldHelpNamesTop } =
    useBkAbrDefVersionFieldHelp();

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>();
  const [validFromFormValue, setValidFromFormValue] = useState<string>(validFrom);
  const [isValidFromValid, setIsValidFromValid] = useState<boolean>();
  const [kommentarFormValues, setKommentarFormValues] = useState<string>(kommentar);
  const abrKreisListFormValues = mapAbrechnungsdefinitionToAbrKreisListFormValues(abrechnungsdefinitionVersion.abrechdefAbrechkreisList);
  const [objAbrDefTemplateValues, setObjAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(abrechnungsdefinitionVersion.objektAbrechnungTextbausteinList)
  );
  const [topAbrDefTemplateValues, setTopAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(abrechnungsdefinitionVersion.topAbrechnungTextbausteinList)
  );
  const [kontoAbrechnungskreisValues, setKontoAbrechnungskreisValues] = useState<AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput[]>(
    mapAbrechnungsdefinitionToKontenZuordnungFormValues(abrechnungsdefinitionId, abrechnungsdefinitionVersion)
  );
  const [objAbrDefGeneralSettingsValues, setObjAbrDefGeneralSettingsValues] = useState<ObjAbrDefGeneralSettingsFormValues>(
    mapObjAbrDefOutputOptionsToFormValues(abrechnungsdefinitionVersion.objektAbrechnungOutputOptions)
  );
  const [topAbrDefGeneralSettingsValues, setTopAbrDefGeneralSettingsValues] = useState<TopAbrDefGeneralSettingsFormValues>(
    mapTopAbrDefGeneralSettingsToInitialValues(AbrechnungsdefinitionType.BkAbrechnung, abrechnungsdefinitionVersion.topAbrechnungOutputOptions)
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  // if there are changes in abrechnunskreise then it affects kontenzuordnung
  // and therefore after abrechnunskreis changes kontenzuordnung tab content will be re-rendered which is controlled by this flag
  const [isSubmittingAbrechnungskreisTab, toggleIsSubmittingAbrechnungskreisTab] = useToggle();

  const entity = 'Abrechnungsdefinition';

  const [updateBkAbrDefVersion] = useUpdateBkAbrechnungsdefinitionVersionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSubmit = (freshAbrKreisListFormValues?: AbrDefAbrKreisListingFormValues) => {
    if (!validFromFormValue) {
      setIsValidFromValid(false);
      return;
    }
    if (!isFormValid) return;
    const input = mapFormValuesToBkOrWeAbrechnungsdefinitionUpdateTab(
      freshAbrKreisListFormValues ?? abrKreisListFormValues,
      objAbrDefTemplateValues,
      topAbrDefTemplateValues,
      validFromFormValue,
      kommentarFormValues,
      kontoAbrechnungskreisValues,
      objAbrDefGeneralSettingsValues,
      topAbrDefGeneralSettingsValues
    );

    // console.log('input when subbmitting', input);
    if (activeTab === ABR_DEF_VERSION_TAB_KEYS.abrechnungskreise.key) toggleIsSubmittingAbrechnungskreisTab();
    updateBkAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId: abrechnungsdefinitionVersion.abrechnungsdefinitionVersionId,
        input,
      },
    })
      .then(() => {
        setActiveTab(undefined);
        onSuccess();
      })
      .finally(() => {
        if (activeTab === ABR_DEF_VERSION_TAB_KEYS.abrechnungskreise.key) toggleIsSubmittingAbrechnungskreisTab();
      });
  };

  const onChangeDate = (date: Dayjs | null) => {
    if (date) {
      setIsValidFromValid(true);
      setValidFromFormValue(formatAbrechnungsdefinitionValidFromValue(date));
    } else {
      setIsValidFromValid(false);
    }
  };

  const isTabDisabled = (tabKey: string) => !!(activeTab && activeTab !== tabKey);

  useEffect(() => {
    const selectedTab = Object.values(ABR_DEF_VERSION_TAB_KEYS).find((tab) => tab.key === activeTab);
    selectedTab ? setKommentarFormValues(`${selectedTab.text} geändert`) : setKommentarFormValues(kommentar);
  }, [kommentar, activeTab]);

  const onAbrKreisZuweisungValuesChange = useCallback((isDirty: boolean) => {
    isDirty ? setActiveTab(ABR_DEF_VERSION_TAB_KEYS.abrechnungskreise.key) : setActiveTab(undefined);
  }, []);

  const onKontenzuordnungValuesChange = useCallback((isDirty: boolean) => {
    isDirty ? setActiveTab(ABR_DEF_VERSION_TAB_KEYS.kontenzuordnung.key) : setActiveTab(undefined);
  }, []);

  const items: TabsProps['items'] = [
    {
      key: ABR_DEF_VERSION_TAB_KEYS.abrechnungskreise.key,
      label: (
        <TooltipTextTab
          showTooltip={isTabDisabled(ABR_DEF_VERSION_TAB_KEYS.abrechnungskreise.key)}
          text={ABR_DEF_VERSION_TAB_KEYS.abrechnungskreise.text}
        />
      ),
      children: (
        <AbrDefAbrKreisListingForm
          abrDefType={AbrechnungsdefinitionType.BkAbrechnung}
          values={abrKreisListFormValues}
          firmendatenId={firmendatenId}
          onSubmit={(formValues) => {
            onSubmit(formValues);
          }}
          onBack={() => navigate(URI_SYS_SETTINGS.abrechnungsdefinitionPage)}
          formButtons={(formikProps) => (
            <DefinitionUpdateFormButtons pushPath={URI_SYS_SETTINGS.abrechnungsdefinitionPage} formikProps={formikProps} />
          )}
          onValuesChange={onAbrKreisZuweisungValuesChange}
          onAbrechnungskreisChange={(newAbrechnungskreisId, fieldProps, onSuccess) =>
            onAbrechnungskreisChange(
              abrechnungsdefinitionVersion.kontoAbrechnungskreisList,
              setKontoAbrechnungskreisValues,
              newAbrechnungskreisId,
              fieldProps,
              onSuccess
            )
          }
          onZuweisungDelete={(abrechnungskreisId, onDelete) =>
            onAbrechnungskreisZuweisungDelete(
              abrechnungsdefinitionVersion.kontoAbrechnungskreisList,
              setKontoAbrechnungskreisValues,
              abrechnungskreisId,
              onDelete
            )
          }
          fieldHelp={fieldHelpAbrDefAbrechkreisList}
        />
      ),
      disabled: isTabDisabled(ABR_DEF_VERSION_TAB_KEYS.abrechnungskreise.key),
    },
    {
      key: ABR_DEF_VERSION_TAB_KEYS.objektabrechnung.key,
      label: (
        <TooltipTextTab
          showTooltip={isTabDisabled(ABR_DEF_VERSION_TAB_KEYS.objektabrechnung.key)}
          text={ABR_DEF_VERSION_TAB_KEYS.objektabrechnung.text}
        />
      ),
      children: (
        <AbrDefVersionUpdateTabWithButtons
          tabContent={
            <BkOrWeObjAbrDefTemplate
              objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
              onObjAbrDefGeneralSettingsChange={setObjAbrDefGeneralSettingsValues}
              objektAbrTemplateValues={objAbrDefTemplateValues}
              onObjektAbrDefTemplateChange={(templateValues) => {
                setObjAbrDefTemplateValues(templateValues);
                updateActiveTabIfTemplateChanged(
                  mapTextbausteinListToFormValues(abrechnungsdefinitionVersion.objektAbrechnungTextbausteinList),
                  templateValues,
                  setActiveTab,
                  '2'
                );
              }}
              fieldHelpNames={fieldHelpNamesObjekt}
            />
          }
          onSubmit={onSubmit}
        />
      ),
      disabled: isTabDisabled(ABR_DEF_VERSION_TAB_KEYS.objektabrechnung.key),
    },
    {
      key: ABR_DEF_VERSION_TAB_KEYS.topabrechnung.key,
      label: (
        <TooltipTextTab showTooltip={isTabDisabled(ABR_DEF_VERSION_TAB_KEYS.topabrechnung.key)} text={ABR_DEF_VERSION_TAB_KEYS.topabrechnung.text} />
      ),
      children: (
        <AbrDefVersionUpdateTabWithButtons
          tabContent={
            <BkOrWeTopAbrDefTemplate
              abrDefType={AbrechnungsdefinitionType.BkAbrechnung}
              topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
              onTopAbrDefGeneralSettingsChange={(values) => setTopAbrDefGeneralSettingsValues(values)}
              setIsFormValid={setIsFormValid}
              topAbrTemplateValues={topAbrDefTemplateValues}
              onTopAbrDefTemplateChange={(templateValues) => {
                setTopAbrDefTemplateValues(templateValues);
                updateActiveTabIfTemplateChanged(
                  mapTextbausteinListToFormValues(abrechnungsdefinitionVersion.topAbrechnungTextbausteinList),
                  templateValues,
                  setActiveTab,
                  '3'
                );
              }}
              fieldHelpNames={fieldHelpNamesTop}
            />
          }
          onSubmit={onSubmit}
        />
      ),
      disabled: isTabDisabled(ABR_DEF_VERSION_TAB_KEYS.topabrechnung.key),
    },
    {
      key: ABR_DEF_VERSION_TAB_KEYS.kontenzuordnung.key,
      label: (
        <TooltipTextTab
          showTooltip={isTabDisabled(ABR_DEF_VERSION_TAB_KEYS.kontenzuordnung.key)}
          text={ABR_DEF_VERSION_TAB_KEYS.kontenzuordnung.text}
        />
      ),
      children: isSubmittingAbrechnungskreisTab ? (
        <Spin />
      ) : (
        <AbrDefVersionUpdateTabWithButtons
          tabContent={
            <AbrDefKontenzuordnung
              abrechnungsdefinitionId={abrechnungsdefinitionId}
              abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
              abrDefinitionType={AbrechnungsdefinitionType.BkAbrechnung}
              onValuesChange={onKontenzuordnungValuesChange}
              onSubmit={(formValues) => {
                setKontoAbrechnungskreisValues(mapFormValuesToZuordnungList(formValues).zuordnungList);
                return Promise.resolve();
              }}
              mode="onlyEdit"
              fieldHelp={fieldHelpAbrDefKontoAbrechkreisList}
            />
          }
          onSubmit={onSubmit}
        />
      ),
      disabled: isTabDisabled(ABR_DEF_VERSION_TAB_KEYS.kontenzuordnung.key),
    },
  ];

  return (
    <>
      <Flex>
        <Space>
          <AbrDefFormValidFrom
            isValid={isValidFromValid}
            onChange={onChangeDate}
            initialValue={dayjsCustom(validFrom)}
            disabledDates={getAlreadyTakenValidFromDates(abrechnungsdefinitionVersionList, abrechnungsdefinitionVersion)}
          />
        </Space>
        <Space style={{ marginLeft: '10px' }}>
          <AbrDefVersionFormKommentar onChange={setKommentarFormValues} value={kommentarFormValues} />
        </Space>
      </Flex>
      <Divider />
      <Tabs items={items} />
    </>
  );
};

export default BkAbrDefVersionUpdateForm;
