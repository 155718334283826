import React, { FC, PropsWithChildren } from 'react';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../helpers/apolloHelper';
import { showSuccessMsgCreate, showSuccessMsgDelete, showSuccessMsgOther, showSuccessMsgUpdate } from '../../components/message';

import {
  CreateTicketTemplateMutationHookResult,
  DeleteTicketTemplateMutationHookResult,
  TicketTemplateChangePositionMutationHookResult,
  TicketTemplateCreateSubTicketTemplateMutationHookResult,
  UpdateTicketTemplateMutationHookResult,
  useCreateTicketTemplateMutation,
  useDeleteTicketTemplateMutation,
  useTicketTemplateChangePositionMutation,
  useTicketTemplateCreateSubTicketTemplateMutation,
  useUpdateTicketTemplateMutation,
} from './gql/TicketTemplateMutations.types';
import createCtx from '../../helpers/createCtx';

export type TicketTemplateMutationHookResults = {
  runDelete: DeleteTicketTemplateMutationHookResult;
  runCreate: CreateTicketTemplateMutationHookResult;
  runUpdate: UpdateTicketTemplateMutationHookResult;
  runCreateSubTicketTemplate: TicketTemplateCreateSubTicketTemplateMutationHookResult;
  runChangePosition: TicketTemplateChangePositionMutationHookResult;
};

type Props = {
  refetch: () => void;
};

const [useMutationHookResultsProvider, MutationHookResultsContextProvider] = createCtx<TicketTemplateMutationHookResults>();

const TicketTemplateMutationHookResultProvider: FC<Props & PropsWithChildren> = ({ refetch, children }) => {
  const entity = `Aufgabenart`;

  const runCreate = useCreateTicketTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const runCreateSubTicketTemplate = useTicketTemplateCreateSubTicketTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const runChangePosition = useTicketTemplateChangePositionMutation({
    onCompleted: (data) => {
      const ticketTemplate = data.actionTicketTemplateChangePosition.data;
      showSuccessMsgOther(`Die Position der ${entity} ${ticketTemplate.name} hat sich geändert`);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const runUpdate = useUpdateTicketTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const runDelete = useDeleteTicketTemplateMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <MutationHookResultsContextProvider value={{ runCreate, runUpdate, runDelete, runCreateSubTicketTemplate, runChangePosition }}>
      {children}
    </MutationHookResultsContextProvider>
  );
};

export { MutationHookResultsContextProvider, useMutationHookResultsProvider };

export default TicketTemplateMutationHookResultProvider;
