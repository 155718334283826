import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { VorschreibungspositionVersion, VorschreibungspositionVersionCreateInput, VorschreibungspositionVersionUpdateInput } from '../../../../types';
import { ValuesDescriptionForKommentar } from '../../../../components/Timeline/versionTimelineHelper';

export interface VorschreibungspositionVersionFormValues {
  validFrom: string;
  umsatzsteuerkennzeichenId: string;
  kontierungstabelleId: string;
  kommentar: string;
}

export const vorschreibungspositionVersionFormFields: FormFields<VorschreibungspositionVersionFormValues> = {
  validFrom: 'validFrom',
  umsatzsteuerkennzeichenId: 'umsatzsteuerkennzeichenId',
  kontierungstabelleId: 'kontierungstabelleId',
  kommentar: 'kommentar',
};

export const mapVorschreibungspositionTimeblockToFormValues = (
  timeblock: VorschreibungspositionVersion
): VorschreibungspositionVersionFormValues => ({
  validFrom: timeblock.validFrom,
  umsatzsteuerkennzeichenId: timeblock.umsatzsteuerkennzeichen.umsatzsteuerkennzeichenId,
  kontierungstabelleId: timeblock.kontierungstabelle.kontierungstabelleId,
  kommentar: timeblock.kommentar ?? '',
});

export const mapFormValuesToVorschreibungspositionVersion = (
  values: VorschreibungspositionVersionFormValues
): VorschreibungspositionVersionCreateInput | VorschreibungspositionVersionUpdateInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  umsatzsteuerkennzeichenId: values.umsatzsteuerkennzeichenId,
  kontierungstabelleId: values.kontierungstabelleId,
  kommentar: values.kommentar,
});

export const vorschreibungspositionVersionFormValuesDescriptionsForKommentar: ValuesDescriptionForKommentar<VorschreibungspositionVersionFormValues> =
  {
    validFrom: 'Gültigkeitsdatum',
    kontierungstabelleId: 'Kontierungstabelle',
    umsatzsteuerkennzeichenId: 'Umsatzsteuerkennzeichen',
  };
