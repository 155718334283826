import React, { FC, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'formik-antd';
import { BookingSuggestion } from '../../../../types';
import GeneralInfoWithButtonsBlock from '../GeneralInfoWithButtonsBlock/GeneralInfoWithButtonsBlock';
import BuchungBlock from '../../../BookingSuggestion/shared/BuchungBlock/BuchungBlock';
import RouterPrompt from '../../../../components/Prompt/RouterPrompt';
import { useBookingSuggestionInitialValues } from '../../../BookingSuggestion/shared/Form/useBookingSuggestionInitialValues';
import { useUpdateBookingSuggestionMutation } from '../../../BookingSuggestion/gql/BookingSuggestionMutations.types';
import { isBuchungTypeZahlungOrRuecklastschrift } from '../../../BookingSuggestion/shared/bookingSuggestionHelpers';
import { useToggle } from '../../../../hooks/useToggle';
import BuchungDateModal from './BuchungDateModal';
import { showSuccessMsgOther, showSuccessMsgUpdate } from '../../../../components/message';
import DividerForBooking from '../../shared/DividerForBooking';
import SpaceForBookingBlockAndForm from '../../shared/SpaceForBookingBlockAndForm';
import {
  bookingSuggestionFormFields,
  BookingSuggestionFormValues,
  mapClearingSuggestionListFormValuesToAuszifferungListCreateInput,
  mapFormValuesToBookingSuggestionUpdateInput,
} from '../../../BookingSuggestion/shared/Form/buchungsmaskeFormMapper';
import AuszifferungBlock from '../../../BookingSuggestion/shared/AuszifferungBlock/AuszifferungBlock';
import { bookingSuggestionUpdateFormValidationSchema } from '../../../BookingSuggestion/shared/Form/bookingSuggestionUpdateFormValidationSchema';
import { useCreateAuszifferungListMutation } from '../../gql/AuszifferungMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';

type Props = {
  bookingSuggestion: BookingSuggestion;
  bookingZahlungId?: string | null;
  onCancel: () => void;
  onUpdate: (isBuchungsanweisungChanged: boolean) => void;
};

const BuchungsmaskeForm: FC<Props> = ({ bookingSuggestion, bookingZahlungId, onCancel, onUpdate }) => {
  const [isBuchungDateModalOpen, toggleBuchungDateModalOpen] = useToggle();

  /******** mutations for updating the data ********/
  const [runUpdateBookingSuggestion, { loading: loadingUpdate }] = useUpdateBookingSuggestionMutation({
    onCompleted: () => {
      toggleBuchungDateModalOpen();
      showSuccessMsgOther(`Die Buchung wird korrigiert.`);
      onUpdate(isBuchungsanweisungDirty);
    },
    onError: () => toggleBuchungDateModalOpen(),
  });

  const [runUpdateAuszifferungList] = useCreateAuszifferungListMutation({
    onCompleted: () => showSuccessMsgUpdate('Auszifferungen'),
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  /******** storing information about if the form is dirty ********/
  // We need to fetch the initial values for the form, specially fetch and set the real initial value for offenePostenForPersonenkontoList. Otherwise, formik will always treat the form will as dirty already on the first render. This will trigger showing the unsaved changes warning message when the user tries to navigate away from the page.
  const { initialValues, loading } = useBookingSuggestionInitialValues(bookingSuggestion);

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [initialValuesForTracking, setInitialValuesForTracking] = useState<BookingSuggestionFormValues>(initialValues);
  const [isBuchungsanweisungDirty, setIsisBuchungsanweisungDirty] = useState(false);

  useEffect(() => {
    setInitialValuesForTracking(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) return <Skeleton active />;

  /******** function for handling data update ********/
  const handleUpdate = (values: BookingSuggestionFormValues, formikHelpers: FormikHelpers<BookingSuggestionFormValues>) => {
    const input = mapFormValuesToBookingSuggestionUpdateInput(values);
    setInitialValuesForTracking(values);

    if (isBuchungsanweisungDirty) {
      runUpdateBookingSuggestion({
        variables: {
          bookingSuggestionId: bookingSuggestion.bookingSuggestionId,
          input,
        },
      })
        .then(() => formikHelpers.resetForm({ values })) // Reset formik initial form values on save in order to set back to proper values on clicking the "Reset" (=Zurücksetzen) button
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    } else if (bookingZahlungId && input.clearingSuggestionList?.length) {
      runUpdateAuszifferungList({
        variables: {
          zahlungId: bookingZahlungId,
          input: mapClearingSuggestionListFormValuesToAuszifferungListCreateInput(input.clearingSuggestionList),
        },
      })
        .then(() => formikHelpers.resetForm({ values })) // Reset formik initial form values on save in order to set back to proper values on clicking the "Reset" (=Zurücksetzen) button
        .finally(() => {
          formikHelpers.setSubmitting(false);
          onUpdate(isBuchungsanweisungDirty);
        });
    } else console.error('Booking Suggestions Update: No data to update');
  };

  return (
    <Formik<BookingSuggestionFormValues>
      initialValues={initialValues}
      validationSchema={bookingSuggestionUpdateFormValidationSchema}
      initialTouched={{
        buchungsanweisung: {
          buchungskreisId: true, // setting touched to true to show the error message on the first render
          buchungskreisRechtstraegerId: true, // setting touched to true to show the error message on the first render
        },
      }}
      onSubmit={(values, formikHelpers) => handleUpdate(values, formikHelpers)}
    >
      {(formikProps) => (
        <>
          <Form layout="vertical">
            <BuchungDateModal
              isOpen={isBuchungDateModalOpen}
              isOkDisabled={formikProps.isSubmitting || !formikProps.values.buchungsdatum}
              isOkLoading={loadingUpdate}
              onSuccess={() => {
                formikProps.submitForm();
              }}
              onCancel={() => {
                formikProps.setFieldValue(bookingSuggestionFormFields.buchungsdatum, undefined);
                toggleBuchungDateModalOpen();
              }}
            />
            <SpaceForBookingBlockAndForm>
              {/* block 1  - General Info with Buttons*/}
              <GeneralInfoWithButtonsBlock
                bookingSuggestion={bookingSuggestion}
                formikProps={formikProps}
                onCancel={onCancel}
                onSubmit={(isBuchungsanweisungChanged) => {
                  setIsisBuchungsanweisungDirty(isBuchungsanweisungChanged);
                  isBuchungsanweisungChanged ? toggleBuchungDateModalOpen() : formikProps.submitForm();
                }}
                showNotSavedWarning={isFormDirty}
                initialValuesForTracking={initialValuesForTracking}
                isFormDirty={isFormDirty}
                setIsFormDirty={setIsFormDirty}
              />
              <DividerForBooking />

              {/* block 2 - BuchungBlock and view of Errors coming from BE */}
              <BuchungBlock formikProps={formikProps} bookingSuggestion={bookingSuggestion} isUpdate />

              {/* block 3 - Auszifferung und Offene Posten listings */}
              {isBuchungTypeZahlungOrRuecklastschrift(formikProps.values.buchungsanweisung.buchungType) && (
                <>
                  <DividerForBooking />
                  <AuszifferungBlock auszifferungBlockTitle="Auszifferung" formikProps={formikProps} />
                </>
              )}
            </SpaceForBookingBlockAndForm>
          </Form>
          <RouterPrompt when={isFormDirty} />
        </>
      )}
    </Formik>
  );
};

export default BuchungsmaskeForm;
