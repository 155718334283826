import React from 'react';
import { useShowArchivedQueryParamState } from '../../../hooks/useShowArchivedQueryParamState';
import { useVorschreibungspositionTemplateListQuery } from '../gql/VorschreibungspositionTemplateQueries.types';
import VorschreibungspositionTemplateButtonsAndDrawer from './VorschreibungspositionTemplateButtonsAndDrawer';
import VorschreibungspositionTemplateTable from './Table/VorschreibungspositionTemplateTable';

const VorschreibungspositionTemplateListing = () => {
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();

  const { data, refetch, loading } = useVorschreibungspositionTemplateListQuery({
    variables: {
      withDetails: true,
      includeArchiviert: showArchived,
    },
  });

  const vorschreibungspositionList = data?.getVorschreibungspositionTemplateList.data;

  return (
    <>
      <VorschreibungspositionTemplateButtonsAndDrawer refetch={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
      <VorschreibungspositionTemplateTable loading={loading} refetch={refetch} vorschreibungspositionList={vorschreibungspositionList} />
    </>
  );
};

export default VorschreibungspositionTemplateListing;
