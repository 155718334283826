import { FC } from 'react';
import HistoryListingTable from '../../../../History/HistoryListingTable';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import { useUstKategorieChangeHistoryListQuery } from '../../gql/UstKategorieQueries.types';

type Props = {
  ustKategorieId: string;
};

const UstKategorieChangeHistoryListingTable: FC<Props> = ({ ustKategorieId }) => {
  const { data, loading } = useUstKategorieChangeHistoryListQuery({ variables: { ustKategorieId } });
  const historyList = data?.getUstKategorieChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="ust-kategorie" />;
};

export default UstKategorieChangeHistoryListingTable;
