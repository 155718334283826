import React from 'react';
import { NestedTableColProps } from '../NestedTable/NestedTable';
import { getColumnTitle } from '../TableWithColSelector/tableWithColSelectorHelpers';
import { NestedTableLevel, NestedTableWithColSelectorColProps } from './NestedTableWithColSelector';

export type NestedTableWithColSelectorState = {
  displayLevelCount: number;
  allLevels: NestedTableLevel<any>[];
  selectedColumnsToShowInTable: NestedTableColProps<any>[];
  allUniqueColumns: NestedTableWithColSelectorColProps<any>[];
  allColumnTitles: (string | string[])[];
  columnCount: number;
  rowKeys: string[][];
  isColumnSelectorLoaded?: boolean;
};

export type NestedTableWithColSelectorStateAction =
  | { type: 'SET_COLUMN_SELECTOR_LOADED'; payload: boolean }
  | { type: 'SET_DISPLAY_LEVEL'; payload: number }
  | {
      type: 'ADD_TABLE';
      payload: {
        level: number;
        subLevel: number;
        columns: NestedTableColProps<any>[];
        aligned: boolean;
      };
    }
  | {
      type: 'UPDATE_SELECTED_COLUMNS_SHOW_IN_TABLE';
      payload: NestedTableColProps<any>[];
    }
  | {
      type: 'UPDATE_PARENT_ROW_KEYS';
      payload: {
        parentRowKeys: string[] | undefined;
        visible: boolean;
        level: number;
        subLevel: number;
      };
    };

export const nestedTableWithColSelectorState: NestedTableWithColSelectorState = {
  displayLevelCount: 1,
  allLevels: [],
  selectedColumnsToShowInTable: [],
  allUniqueColumns: [],
  allColumnTitles: [],
  columnCount: 0,
  rowKeys: [],
  isColumnSelectorLoaded: false,
};

export const nestedTableWithColSelectorReducer = (
  state: NestedTableWithColSelectorState,
  action: NestedTableWithColSelectorStateAction
): NestedTableWithColSelectorState => {
  switch (action.type) {
    case 'SET_COLUMN_SELECTOR_LOADED':
      return { ...state, isColumnSelectorLoaded: action.payload };

    case 'ADD_TABLE':
      if (state.allLevels.find((table) => table.level === action.payload.level && table.subLevel === action.payload.subLevel)) {
        return state;
      }

      // eslint-disable-next-line no-case-declarations
      const allTables = [...state.allLevels, action.payload];
      return {
        ...state,
        allLevels: allTables,
        allUniqueColumns: getAllColumns(allTables),
        allColumnTitles: getGroupedTitles(allTables, state.displayLevelCount),
        columnCount: !state.columnCount ? getColumnCount(allTables) : state.columnCount,
      };

    case 'UPDATE_SELECTED_COLUMNS_SHOW_IN_TABLE':
      if (
        state.selectedColumnsToShowInTable.length === action.payload.length &&
        state.selectedColumnsToShowInTable.every((item, index) => item === action.payload[index])
      ) {
        return state;
      }

      return { ...state, selectedColumnsToShowInTable: [...action.payload] };

    case 'UPDATE_PARENT_ROW_KEYS':
      if (!action.payload.parentRowKeys) return state;
      return updateRowKeys(state, action.payload);

    default:
      return state;
  }
};

const getColumnCount = (allTables: NestedTableLevel<any>[]) => {
  return allTables.find((col) => col.level === 1)?.columns.length || 0;
};

const updateRowKeys = (
  state: NestedTableWithColSelectorState,
  payload: Extract<NestedTableWithColSelectorStateAction, { type: 'UPDATE_PARENT_ROW_KEYS' }>['payload']
) => {
  const { parentRowKeys, visible } = payload;
  if (!parentRowKeys?.length) return state;

  const updatedRowKeys = [...state.rowKeys];
  const index = updatedRowKeys.findIndex((keys) => parentRowKeys.every((key) => keys.includes(key)));

  if (visible && index === -1) {
    updatedRowKeys.push([...parentRowKeys]);
  }

  if (!visible && index !== -1) {
    updatedRowKeys.splice(index, 1);
  }

  const displayLevel = (updatedRowKeys.length ? Math.max(...updatedRowKeys.map((keys) => keys.length)) : 0) + 1;

  const allTables = state.allLevels.map((level) => {
    if (level.level === payload.level && level.subLevel === payload.subLevel) {
      const currentRowKeys = level?.parentRowKeys || [];
      let newRowKeys;
      if (parentRowKeys) {
        if (visible) {
          newRowKeys = [...currentRowKeys, parentRowKeys];
        } else {
          newRowKeys = currentRowKeys.filter((keys) => JSON.stringify(keys) !== JSON.stringify(parentRowKeys));
        }
      }
      return { ...level, parentRowKeys: parentRowKeys ? newRowKeys : undefined };
    }
    return level;
  });

  const filteredLevels = allTables.filter((level) => !level.parentRowKeys || level.parentRowKeys.length);

  return {
    ...state,
    allLevels: allTables,
    displayLevelCount: displayLevel,
    allColumnTitles: getGroupedTitles(filteredLevels, displayLevel),
    rowKeys: updatedRowKeys,
  };
};

const getAllColumns = <T>(allLevels: NestedTableLevel<T>[]): NestedTableWithColSelectorColProps<T>[] => allLevels.flatMap(({ columns }) => columns);

const getGroupedTitles = <T>(allLevels: NestedTableLevel<T>[], displayLevelCount: number) => {
  const groupedColumnsByKey = allLevels.reduce(
    (acc: { key: string | React.Key; columns: NestedTableWithColSelectorColProps<T>[] }[], { columns, level }) => {
      if (level > displayLevelCount) return acc;
      columns.forEach((item) => {
        const key = item.key || '';
        const columnWithKey = acc.findIndex((column) => key && key === column.key);
        if (columnWithKey > -1) {
          acc[columnWithKey] = { key, columns: [...acc[columnWithKey].columns, item] };
        } else {
          acc.push({ key, columns: [item] });
        }
      });
      return acc;
    },
    []
  );

  return Array.from(
    new Set(
      groupedColumnsByKey
        .map((item) => {
          const columns = item.columns.map((col) => getColumnTitle(col)).filter((col) => !!col);
          if (columns.length > 1) {
            const cols = Array.from(new Set(columns));
            return cols.length > 1 ? cols : cols[0];
          } else {
            return columns[0];
          }
        })
        .filter((col) => !!col)
    )
  );
};
