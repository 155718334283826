import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNatuerlichePersonMutationVariables = Types.Exact<{
  request: Types.NatuerlichePersonCreateInput;
}>;

export type CreateNatuerlichePersonMutation = {
  createNatuerlichePerson: {
    data: { rechtstraegerId: string; kurzBezeichnung: string; type: { value: Types.RechtstraegerType } };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateNatuerlichePersonMutationVariables = Types.Exact<{
  request: Types.NatuerlichePersonUpdateInput;
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type UpdateNatuerlichePersonMutation = {
  updateNatuerlichePerson: {
    data: { rechtstraegerId: string; kurzBezeichnung: string; type: { value: Types.RechtstraegerType } };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteNatuerlichePersonMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type DeleteNatuerlichePersonMutation = {
  deleteNatuerlichePerson: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export type SperrenNatuerlichePersonMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type SperrenNatuerlichePersonMutation = {
  actionSperrenNatuerlichePerson: {
    data: { rechtstraegerId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type EntsperrenNatuerlichePersonMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type EntsperrenNatuerlichePersonMutation = {
  actionEntsperrenNatuerlichePerson: {
    data: { rechtstraegerId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveNatuerlichePersonMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type ArchiveNatuerlichePersonMutation = {
  actionArchiveNatuerlichePerson: {
    data: { rechtstraegerId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveNatuerlichePersonMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveNatuerlichePersonMutation = {
  actionUnarchiveNatuerlichePerson: {
    data: { rechtstraegerId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateNatuerlichePersonAddressMutationVariables = Types.Exact<{
  natuerlichePersonId: Types.Scalars['ID']['input'];
  addressId: Types.Scalars['ID']['input'];
  request: Types.AddressUpdateInput;
}>;

export type UpdateNatuerlichePersonAddressMutation = {
  updateNatuerlichePersonAddress: {
    data: { addressId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateNatuerlichePersonDocument = gql`
  mutation CreateNatuerlichePerson($request: NatuerlichePersonCreateInput!) {
    createNatuerlichePerson(request: $request) {
      data {
        rechtstraegerId
        kurzBezeichnung
        type {
          value
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateNatuerlichePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNatuerlichePersonMutation, CreateNatuerlichePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNatuerlichePersonMutation, CreateNatuerlichePersonMutationVariables>(CreateNatuerlichePersonDocument, options);
}
export type CreateNatuerlichePersonMutationHookResult = ReturnType<typeof useCreateNatuerlichePersonMutation>;
export type CreateNatuerlichePersonMutationResult = Apollo.MutationResult<CreateNatuerlichePersonMutation>;
export type CreateNatuerlichePersonMutationOptions = Apollo.BaseMutationOptions<
  CreateNatuerlichePersonMutation,
  CreateNatuerlichePersonMutationVariables
>;
export const UpdateNatuerlichePersonDocument = gql`
  mutation UpdateNatuerlichePerson($request: NatuerlichePersonUpdateInput!, $rechtstraegerId: ID!) {
    updateNatuerlichePerson(request: $request, rechtstraegerId: $rechtstraegerId) {
      data {
        rechtstraegerId
        kurzBezeichnung
        type {
          value
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateNatuerlichePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNatuerlichePersonMutation, UpdateNatuerlichePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNatuerlichePersonMutation, UpdateNatuerlichePersonMutationVariables>(UpdateNatuerlichePersonDocument, options);
}
export type UpdateNatuerlichePersonMutationHookResult = ReturnType<typeof useUpdateNatuerlichePersonMutation>;
export type UpdateNatuerlichePersonMutationResult = Apollo.MutationResult<UpdateNatuerlichePersonMutation>;
export type UpdateNatuerlichePersonMutationOptions = Apollo.BaseMutationOptions<
  UpdateNatuerlichePersonMutation,
  UpdateNatuerlichePersonMutationVariables
>;
export const DeleteNatuerlichePersonDocument = gql`
  mutation DeleteNatuerlichePerson($rechtstraegerId: ID!) {
    deleteNatuerlichePerson(rechtstraegerId: $rechtstraegerId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteNatuerlichePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteNatuerlichePersonMutation, DeleteNatuerlichePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNatuerlichePersonMutation, DeleteNatuerlichePersonMutationVariables>(DeleteNatuerlichePersonDocument, options);
}
export type DeleteNatuerlichePersonMutationHookResult = ReturnType<typeof useDeleteNatuerlichePersonMutation>;
export type DeleteNatuerlichePersonMutationResult = Apollo.MutationResult<DeleteNatuerlichePersonMutation>;
export type DeleteNatuerlichePersonMutationOptions = Apollo.BaseMutationOptions<
  DeleteNatuerlichePersonMutation,
  DeleteNatuerlichePersonMutationVariables
>;
export const SperrenNatuerlichePersonDocument = gql`
  mutation SperrenNatuerlichePerson($rechtstraegerId: ID!) {
    actionSperrenNatuerlichePerson(rechtstraegerId: $rechtstraegerId) {
      data {
        rechtstraegerId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useSperrenNatuerlichePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<SperrenNatuerlichePersonMutation, SperrenNatuerlichePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SperrenNatuerlichePersonMutation, SperrenNatuerlichePersonMutationVariables>(SperrenNatuerlichePersonDocument, options);
}
export type SperrenNatuerlichePersonMutationHookResult = ReturnType<typeof useSperrenNatuerlichePersonMutation>;
export type SperrenNatuerlichePersonMutationResult = Apollo.MutationResult<SperrenNatuerlichePersonMutation>;
export type SperrenNatuerlichePersonMutationOptions = Apollo.BaseMutationOptions<
  SperrenNatuerlichePersonMutation,
  SperrenNatuerlichePersonMutationVariables
>;
export const EntsperrenNatuerlichePersonDocument = gql`
  mutation EntsperrenNatuerlichePerson($rechtstraegerId: ID!) {
    actionEntsperrenNatuerlichePerson(rechtstraegerId: $rechtstraegerId) {
      data {
        rechtstraegerId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useEntsperrenNatuerlichePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<EntsperrenNatuerlichePersonMutation, EntsperrenNatuerlichePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EntsperrenNatuerlichePersonMutation, EntsperrenNatuerlichePersonMutationVariables>(
    EntsperrenNatuerlichePersonDocument,
    options
  );
}
export type EntsperrenNatuerlichePersonMutationHookResult = ReturnType<typeof useEntsperrenNatuerlichePersonMutation>;
export type EntsperrenNatuerlichePersonMutationResult = Apollo.MutationResult<EntsperrenNatuerlichePersonMutation>;
export type EntsperrenNatuerlichePersonMutationOptions = Apollo.BaseMutationOptions<
  EntsperrenNatuerlichePersonMutation,
  EntsperrenNatuerlichePersonMutationVariables
>;
export const ArchiveNatuerlichePersonDocument = gql`
  mutation ArchiveNatuerlichePerson($rechtstraegerId: ID!) {
    actionArchiveNatuerlichePerson(rechtstraegerId: $rechtstraegerId) {
      data {
        rechtstraegerId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useArchiveNatuerlichePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveNatuerlichePersonMutation, ArchiveNatuerlichePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveNatuerlichePersonMutation, ArchiveNatuerlichePersonMutationVariables>(ArchiveNatuerlichePersonDocument, options);
}
export type ArchiveNatuerlichePersonMutationHookResult = ReturnType<typeof useArchiveNatuerlichePersonMutation>;
export type ArchiveNatuerlichePersonMutationResult = Apollo.MutationResult<ArchiveNatuerlichePersonMutation>;
export type ArchiveNatuerlichePersonMutationOptions = Apollo.BaseMutationOptions<
  ArchiveNatuerlichePersonMutation,
  ArchiveNatuerlichePersonMutationVariables
>;
export const UnarchiveNatuerlichePersonDocument = gql`
  mutation UnarchiveNatuerlichePerson($rechtstraegerId: ID!) {
    actionUnarchiveNatuerlichePerson(rechtstraegerId: $rechtstraegerId) {
      data {
        rechtstraegerId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveNatuerlichePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveNatuerlichePersonMutation, UnarchiveNatuerlichePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveNatuerlichePersonMutation, UnarchiveNatuerlichePersonMutationVariables>(
    UnarchiveNatuerlichePersonDocument,
    options
  );
}
export type UnarchiveNatuerlichePersonMutationHookResult = ReturnType<typeof useUnarchiveNatuerlichePersonMutation>;
export type UnarchiveNatuerlichePersonMutationResult = Apollo.MutationResult<UnarchiveNatuerlichePersonMutation>;
export type UnarchiveNatuerlichePersonMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveNatuerlichePersonMutation,
  UnarchiveNatuerlichePersonMutationVariables
>;
export const UpdateNatuerlichePersonAddressDocument = gql`
  mutation UpdateNatuerlichePersonAddress($natuerlichePersonId: ID!, $addressId: ID!, $request: AddressUpdateInput!) {
    updateNatuerlichePersonAddress(natuerlichePersonId: $natuerlichePersonId, addressId: $addressId, request: $request) {
      data {
        addressId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateNatuerlichePersonAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNatuerlichePersonAddressMutation, UpdateNatuerlichePersonAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNatuerlichePersonAddressMutation, UpdateNatuerlichePersonAddressMutationVariables>(
    UpdateNatuerlichePersonAddressDocument,
    options
  );
}
export type UpdateNatuerlichePersonAddressMutationHookResult = ReturnType<typeof useUpdateNatuerlichePersonAddressMutation>;
export type UpdateNatuerlichePersonAddressMutationResult = Apollo.MutationResult<UpdateNatuerlichePersonAddressMutation>;
export type UpdateNatuerlichePersonAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateNatuerlichePersonAddressMutation,
  UpdateNatuerlichePersonAddressMutationVariables
>;
