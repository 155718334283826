import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './filtersFormMapper';
import { TPaymentClearingQueryParams } from './filtersQueryParams';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import useFilterWithQueryParams from '../../../shared/useFilterWithQueryParams';
import FiltersWith4OrMoreFields from '../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import PaymentClearingBuchungskreisListFormSelect from './PaymentClearingBuchungskreisListFormSelect';
import PaymentClearingVertragspartnerListFormSelect from './PaymentClearingVertragspartnerListFormSelect';
import PaymentClearingKontoListFormSelect from './PaymentClearingKontoListFormSelect';
import PaymentClearingKontoVerwendungListFormSelect from './PaymentClearingKontoVerwendungListFormSelect';
import FromToFormInputNumber from '../../../shared/FromToForm/FromToFormInputNumber';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  queryParams: TPaymentClearingQueryParams;
};

const ListingFilters: FC<Props> = ({ formikProps, queryParams }) => {
  const defaultSelectedFilterList = [
    filtersFormFields.buchungskreisId,
    filtersFormFields.kontoId,
    filtersFormFields.kontoVerwendung,
    filtersFormFields.availableAmountFrom,
  ];

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.outstandingAmountFrom || filter === filtersFormFields.outstandingAmountTo) {
      return filtersFormFields.outstandingAmountFrom;
    }
    if (filter === filtersFormFields.availableAmountFrom || filter === filtersFormFields.availableAmountTo) {
      return filtersFormFields.availableAmountFrom;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === filtersFormFields.buchungskreisId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.buchungskreisId} label={listingLabelList.buchungskreisId} key={filter}>
          <PaymentClearingBuchungskreisListFormSelect name={filtersFormFields.buchungskreisId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.vertragspartnerId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.vertragspartnerId} label={listingLabelList.vertragspartnerId} key={filter}>
          <PaymentClearingVertragspartnerListFormSelect name={filtersFormFields.vertragspartnerId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.kontoId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.kontoId} label={listingLabelList.kontoId} key={filter}>
          <PaymentClearingKontoListFormSelect name={filtersFormFields.kontoId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.kontoVerwendung) {
      return (
        <FormItemWithoutColon name={filtersFormFields.kontoVerwendung} label={listingLabelList.kontoVerwendung} key={filter}>
          <PaymentClearingKontoVerwendungListFormSelect name={filtersFormFields.kontoVerwendung} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.outstandingAmountFrom) {
      return (
        <FromToFormInputNumber
          name={listingLabelList.outstandingAmountFrom}
          fromName={filtersFormFields.outstandingAmountFrom}
          toName={filtersFormFields.outstandingAmountTo}
          onChange={formikProps.submitForm}
          key={filter}
        />
      );
    }
    if (filter === filtersFormFields.availableAmountFrom) {
      return (
        <FromToFormInputNumber
          name={listingLabelList.availableAmountFrom}
          fromName={filtersFormFields.availableAmountFrom}
          toName={filtersFormFields.availableAmountTo}
          onChange={formikProps.submitForm}
          key={filter}
        />
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.availableAmountFrom)) {
      formikProps.setFieldValue(filtersFormFields.availableAmountTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.outstandingAmountFrom)) {
      formikProps.setFieldValue(filtersFormFields.outstandingAmountTo, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={listingLabelList}
        />
      }
      hideTitle
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};
export default ListingFilters;
