import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Space, Typography } from 'antd';

import { emailTemplateStepFormFields, EmailTemplateStepFormValues, WizardAttachmentFileInfo } from './emailTemplateStepFormMapper';
import { UploadedFileView } from '../../../../shared/UploadedFileView';
import AttachmentUpload from './AttachmentUpload';
import { WizardFileInfo } from '../../../../../types';
import ErrorMessagePanel from '../../../../../components/Error/ErrorMessagePanel';
import { StyledWizardSpaceForTextArea } from '../../../../shared/styled/WizardSpace.style';

type Props = {
  disabled?: boolean;
  formikProps: FormikProps<EmailTemplateStepFormValues>;
  attachmentInfoList: WizardFileInfo[];
};

const AttachmentPart: FC<Props> = ({ formikProps, disabled, attachmentInfoList }) => {
  const attachments: WizardAttachmentFileInfo[] = [
    ...attachmentInfoList
      .filter((file) => !formikProps.values.deletableAttachmentIds.includes(file.fileId))
      .map((file) => ({ fileId: file.fileId, name: file.name, size: file.size })),
    ...formikProps.values.newAttachmentList.map((file) => ({ name: file.filename, file: file.file, size: file.file?.size ?? 0 })),
  ];

  return (
    <>
      <StyledWizardSpaceForTextArea align="start" size={0}>
        <Typography.Text>Anhänge</Typography.Text>

        <AttachmentUpload
          disabled={disabled}
          formikProps={formikProps}
          name={emailTemplateStepFormFields.newAttachmentList}
          attachments={attachments}
        />
      </StyledWizardSpaceForTextArea>
      <StyledWizardSpaceForTextArea size={0}>
        <Typography.Text />
        <Space direction="vertical" style={{ marginTop: '8px' }}>
          {attachments.map((attachment, index) => {
            const onDelete = () => {
              if (disabled) return;
              const deleteableIds = formikProps.values.deletableAttachmentIds;

              if (attachment.fileId) {
                if (!deleteableIds.includes(attachment.fileId)) {
                  formikProps.setFieldValue(emailTemplateStepFormFields.deletableAttachmentIds, [...deleteableIds, attachment.fileId]);
                }
              } else {
                formikProps.setFieldValue(
                  emailTemplateStepFormFields.newAttachmentList,
                  formikProps.values.newAttachmentList.filter((file) => file.file !== attachment.file)
                );
              }
            };
            return (
              <UploadedFileView key={index} filename={attachment.name} filesize={attachment.size} disableDelete={disabled} onDelete={onDelete} />
            );
          })}
          {formikProps.errors.newAttachmentList && <ErrorMessagePanel error={formikProps.errors.newAttachmentList as string} />}
        </Space>
      </StyledWizardSpaceForTextArea>
    </>
  );
};

export default AttachmentPart;
