import React from 'react';
import { Helmet } from 'react-helmet-async';
import RechtstraegerListingForm from '../../features/Rechtstraeger/Listing/RechtstraegerListingForm';
import { PageLayout } from '../../components/LayoutAndromeda';

const RechtstraegerPage = () => {
  return (
    <>
      <Helmet>
        <title>Firmen / Personen</title>
      </Helmet>
      <PageLayout>
        <RechtstraegerListingForm />
      </PageLayout>
    </>
  );
};

export default RechtstraegerPage;
