import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { HeVertrag, HeVertragCreateInput, HeVertragUpdateInput } from '../../../../types';
import { HAUPT_TAG_TEXT } from '../../../../components/Tag/tagHelpers';

export type HeVertragFormValues = {
  vertragspartnerId: string;
  vertragsBeginn?: string | null;
  vertragsEnde?: string | null;
  anteile?: number | null;
  deliveryAddressId?: string | null;
  saldouebertrag: number | null;
  tagList?: string[] | null;
};

export const heVertragFormFields: FormFields<HeVertragFormValues> = {
  vertragspartnerId: 'vertragspartnerId',
  vertragsBeginn: 'vertragsBeginn',
  vertragsEnde: 'vertragsEnde',
  anteile: 'anteile',
  deliveryAddressId: 'deliveryAddressId',
  saldouebertrag: 'saldouebertrag',
  tagList: 'tagList',
};

export const heVertragFormInitialValues: HeVertragFormValues = {
  vertragsBeginn: null,
  vertragspartnerId: '',
  anteile: null,
  saldouebertrag: 0,
};

export const mapHeVertragToFormValues = (heVertrag?: HeVertrag): HeVertragFormValues => {
  if (!heVertrag) {
    return heVertragFormInitialValues;
  }
  return {
    vertragspartnerId: heVertrag.vertragspartner.rechtstraegerId,
    vertragsBeginn: heVertrag.vertragsBeginn,
    vertragsEnde: heVertrag?.vertragsEnde,
    anteile: heVertrag?.anteile,
    deliveryAddressId: heVertrag.deliveryAddress?.haupt ? HAUPT_TAG_TEXT : heVertrag?.deliveryAddress?.addressId,
    saldouebertrag: heVertrag.saldouebertrag,
    tagList: heVertrag.tagList,
  };
};

export const mapFormValuesToCreateHeVertrag = (values: HeVertragFormValues): HeVertragCreateInput => ({
  vertragspartnerId: values.vertragspartnerId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  vertragsBeginn: mapFormDateValueToDateString(values.vertragsBeginn!),
  vertragsEnde: values.vertragsEnde ? mapFormDateValueToDateString(values.vertragsEnde) : undefined,
  anteile: values?.anteile as number,
  deliveryAddressHaupt: values?.deliveryAddressId === HAUPT_TAG_TEXT,
  deliveryAddressId: values?.deliveryAddressId === HAUPT_TAG_TEXT ? null : values?.deliveryAddressId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  saldouebertrag: values.saldouebertrag!,
  tagList: values.tagList,
});

export const mapFormValuesToUpdateHeVertrag = (values: HeVertragFormValues): HeVertragUpdateInput => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  vertragsBeginn: mapFormDateValueToDateString(values.vertragsBeginn!),
  vertragsEnde: values.vertragsEnde ? mapFormDateValueToDateString(values.vertragsEnde) : undefined,
  anteile: values?.anteile as number,
  deliveryAddressHaupt: values?.deliveryAddressId === HAUPT_TAG_TEXT,
  deliveryAddressId: values?.deliveryAddressId === HAUPT_TAG_TEXT ? null : values?.deliveryAddressId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  saldouebertrag: values.saldouebertrag!,
  tagList: values.tagList,
});
