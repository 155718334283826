import { SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { useMahndefinitionListQuery } from '../Mahndefinition/gql/MahndefinitionQueries.types';
import { MahndefinitionListEntryWithStatus } from '../Mahndefinition/mahndefinitonUriPaths';

type Props = SelectProps;

const MahndefinitionSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useMahndefinitionListQuery({
    variables: { includeArchiviert: true },
  });

  const mahndefinitionList = (data?.getMahndefinitionList.data ?? []) as MahndefinitionListEntryWithStatus[];

  return (
    <EntityStatusFormSelect<MahndefinitionListEntryWithStatus>
      list={mahndefinitionList}
      getSelectItemId={(item) => item.mahndefinitionId}
      getSelectItemContent={(item) => item.bezeichnung}
      getSelectItemTooltipTitle={(item) => item.bezeichnung}
      loading={loading}
      placeholder="Mahndefinition auswählen"
      {...restProps}
    />
  );
};

export default MahndefinitionSelect;
