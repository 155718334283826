import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import BudgetingDetailsListing from './Table/BudgetingDetailsListing';
import { Budgeting } from '../../../types';

type Props = {
  budgeting: Budgeting;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => Promise<unknown>;
  isUpdating: boolean;
  updatingRowKey: string | null;
  setUpdatingRowKey: React.Dispatch<React.SetStateAction<string | null>>;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const BudgetingDetailsTab: FC<Props> = ({
  budgeting,
  isLoading,
  setIsLoading,
  refetch,
  isUpdating,
  updatingRowKey,
  setUpdatingRowKey,
  showArchived,
  toggleShowArchived,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Budgetierung',
      children: (
        <BudgetingDetailsListing
          budgeting={budgeting}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          refetch={refetch}
          isUpdating={isUpdating}
          updatingRowKey={updatingRowKey}
          setUpdatingRowKey={setUpdatingRowKey}
          showArchived={showArchived}
          toggleShowArchived={toggleShowArchived}
        />
      ),
    },
  ];
  return <Tabs items={items} />;
};

export default BudgetingDetailsTab;
