import { HeOrSubAbrDefFieldHelpNames, HeOrSubAbrDefOutputOptionsFieldHelpNames } from './abrDefFieldHelpMapper';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

export const useHeAbrDefVersionFieldHelp = (): HeOrSubAbrDefFieldHelpNames => {
  const getFieldHelpText = useGetAppFieldHelpText<'HeAbrechnungsdefinitionVersion'>('HeAbrechnungsdefinitionVersion');

  const fieldHelpAbrDefAbrechkreisList = getFieldHelpText('HeAbrechnungsdefinitionVersion.abrechdefAbrechkreisList');

  const fieldHelpAbrDefKontoAbrechkreisList = getFieldHelpText('HeAbrechnungsdefinitionVersion.kontoAbrechnungskreisList');

  const fieldHelpNamesOutputOptions: HeOrSubAbrDefOutputOptionsFieldHelpNames = {
    displayKontonummer: getFieldHelpText('HeAbrechnungsdefinitionVersion.outputOptions.displayKontonummer'),
    rueckstaendeAnzeigen: getFieldHelpText('HeAbrechnungsdefinitionVersion.outputOptions.rueckstaendeAnzeigen'),
    rueckstaendeInAbrechnungsergebnis: getFieldHelpText('HeAbrechnungsdefinitionVersion.outputOptions.rueckstaendeInAbrechnungsergebnis'),
  };

  return { fieldHelpAbrDefAbrechkreisList, fieldHelpAbrDefKontoAbrechkreisList, fieldHelpNamesOutputOptions };
};
