import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { Flex } from 'rebass';
import { Dayjs } from 'dayjs';
import { Divider, Space, Steps } from 'antd';
import {
  mapTextbausteinListToFormValues,
  TextbausteinFormValues,
  TextbausteinModel,
} from '../../../../../components/Template/PDFTemplates/templateMapper';
import AbrDefFormValidFrom, { formatAbrechnungsdefinitionValidFromValue } from '../../../shared/AbrDefFormValidFrom';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { getAlreadyTakenValidFromDates, getNextAvailableValidFromOrToday, updateCommentOnTemplateChange } from './abrDefVersionCreateFormHelpers';
import AbrDefVersionFormKommentar from '../../../shared/AbrDefVersionFormKommentar';
import {
  AbrDefAbrKreisListingFormValues,
  mapAbrechnungsdefinitionToAbrKreisListFormValues,
} from '../../../shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import { showSuccessMsgCreate } from '../../../../../components/message';
import { URI_SYS_SETTINGS } from '../../../../../constants/configurationUriPaths';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import AbrDefAbrKreisListingForm from '../../../shared/AbrDefAbrKreisListingForm/AbrDefAbrKreisListingForm';
import { AbrechnungsdefinitionType, SubAdministrationAbrechnungsdefinitionVersionListEntry } from '../../../../../types';
import { SubAdministrationAbrechnungsdefinitionVersionFieldsFragment } from '../../../gql/SubAdministrationAbrDef/SubAdministrationAbrDefVersionFragments.types';
import { useSubAbrDefFieldHelp } from '../../../abrDefFieldHelpHelpers/useSubAbrDefFieldHelp';

import { useCreateSubAdministrationAbrechnungsdefinitionVersionMutation } from '../../../gql/SubAdministrationAbrDef/SubAdministrationAbrDefVersionMutations.types';
import { mapFormValuesToSubAdministrationAbrechnungsdefinitionVersionCreate } from './abrDefVersionCreateFormMapper';
import {
  heOrSubAbrDefGeneralSettingsFormInitialValues,
  HeOrSubAbrDefGeneralSettingsFormValues,
  mapHeOrSubAbrDefOutputOptionsToFormValues,
} from '../../../shared/Templates/shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';
import SubAbrDefVersionCreateTabWithTemplate from '../../../shared/Templates/SubTemplates/SubAbrDefVersionCreateTabWithTemplate';

type Props = {
  abrechnungsdefinitionVersionList: SubAdministrationAbrechnungsdefinitionVersionListEntry[];
  abrechnungsdefinitionId: string;
  precedingAbrechnungsdefinitionVersion?: SubAdministrationAbrechnungsdefinitionVersionFieldsFragment;
  firmendatenId: string;
  subAdministrationAbrechnungTextbausteinList: TextbausteinModel[];
};

const SubAbrDefVersionCreateForm: FC<Props> = ({
  abrechnungsdefinitionVersionList,
  abrechnungsdefinitionId,
  precedingAbrechnungsdefinitionVersion,
  firmendatenId,
  subAdministrationAbrechnungTextbausteinList,
}) => {
  const { fieldHelpAbrDefAbrechkreisList, fieldHelpNamesOutputOptions } = useSubAbrDefFieldHelp();

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [validFromFormValue, setValidFromFormValue] = useState<string>(
    getNextAvailableValidFromOrToday(abrechnungsdefinitionVersionList, precedingAbrechnungsdefinitionVersion)
  );

  const [isValidFromValid, setIsValidFromValid] = useState<boolean>();
  const [kommentarFormValues, setKommentarFormValues] = useState<string>('');

  const [subAbrDefGeneralSettingsValues, setSubAbrDefGeneralSettingsValues] = useState<HeOrSubAbrDefGeneralSettingsFormValues>(
    precedingAbrechnungsdefinitionVersion?.outputOptions
      ? mapHeOrSubAbrDefOutputOptionsToFormValues(precedingAbrechnungsdefinitionVersion.outputOptions)
      : heOrSubAbrDefGeneralSettingsFormInitialValues
  );

  const [abrKreisListFormValues, setAbrKreisListFormValues] = useState<AbrDefAbrKreisListingFormValues>(
    mapAbrechnungsdefinitionToAbrKreisListFormValues(precedingAbrechnungsdefinitionVersion?.abrechdefAbrechkreisList)
  );
  const [subAbrDefTemplateValues, setSubAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(subAdministrationAbrechnungTextbausteinList)
  );

  const entity = 'AbrechnungsdefinitionVersion';

  const [createSubAbrDefVersion, { loading: loadingCreate }] = useCreateSubAdministrationAbrechnungsdefinitionVersionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      navigate(URI_SYS_SETTINGS.abrechnungsdefinitionPage);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onAbrKreisListingFormSubmit = (formValues: AbrDefAbrKreisListingFormValues) => {
    const hasAbrechnungskreisZuordnungChanged = !isEqual(
      mapAbrechnungsdefinitionToAbrKreisListFormValues(precedingAbrechnungsdefinitionVersion?.abrechdefAbrechkreisList),
      formValues
    );
    hasAbrechnungskreisZuordnungChanged && setKommentarFormValues('Abrechnungskreise geändert');
    setAbrKreisListFormValues(formValues);
    setActiveStep(1);
  };

  const onAbrDefTemplatesSubmit = () => {
    const abrechnungsdefinitionRequest = mapFormValuesToSubAdministrationAbrechnungsdefinitionVersionCreate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      abrKreisListFormValues!,
      subAbrDefTemplateValues,
      validFromFormValue,
      kommentarFormValues,
      subAbrDefGeneralSettingsValues
    );
    if (!validFromFormValue) {
      setIsValidFromValid(false);
    }
    createSubAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        request: abrechnungsdefinitionRequest,
      },
    });
  };

  let content;
  if (activeStep === 0) {
    content = (
      <AbrDefAbrKreisListingForm
        abrDefType={AbrechnungsdefinitionType.SubAbrechnung}
        values={abrKreisListFormValues}
        firmendatenId={firmendatenId}
        onSubmit={onAbrKreisListingFormSubmit}
        onBack={() => navigate(URI_SYS_SETTINGS.abrechnungsdefinitionPage)}
        backBtnText="Abbrechen"
        fieldHelp={fieldHelpAbrDefAbrechkreisList}
      />
    );
  } else {
    content = // activeStep === 1
      (
        <SubAbrDefVersionCreateTabWithTemplate
          subAbrDefTemplateValues={subAbrDefTemplateValues}
          subAbrDefGeneralSettingsValues={subAbrDefGeneralSettingsValues}
          setSubAbrDefGeneralSettingsValues={setSubAbrDefGeneralSettingsValues}
          onTemplateChange={(formValues) => {
            updateCommentOnTemplateChange(
              mapTextbausteinListToFormValues(subAdministrationAbrechnungTextbausteinList),
              formValues,
              'Subverwaltungsabrechnung',
              setKommentarFormValues
            );
            setSubAbrDefTemplateValues(formValues);
          }}
          onSubmit={onAbrDefTemplatesSubmit}
          isSubmitting={loadingCreate}
          onBack={() => setActiveStep(0)}
          fieldHelpNames={fieldHelpNamesOutputOptions}
        />
      );
  }

  const onChangeDate = (date: Dayjs | null) => {
    if (date) {
      setIsValidFromValid(true);
      setValidFromFormValue(formatAbrechnungsdefinitionValidFromValue(date));
    } else {
      setIsValidFromValid(false);
    }
  };

  return (
    <>
      <Flex>
        <Space>
          <AbrDefFormValidFrom
            isValid={isValidFromValid}
            onChange={onChangeDate}
            initialValue={validFromFormValue ? dayjsCustom(validFromFormValue) : undefined}
            disabledDates={getAlreadyTakenValidFromDates(abrechnungsdefinitionVersionList)}
          />
        </Space>
        <Space style={{ marginLeft: '10px' }}>
          <AbrDefVersionFormKommentar onChange={setKommentarFormValues} value={kommentarFormValues} />
        </Space>
      </Flex>
      <Divider />
      <Steps
        type="navigation"
        size="small"
        current={activeStep}
        className="site-navigation-steps"
        items={[{ title: 'Abrechnungskreise' }, { title: 'Abrechnung' }]}
      />
      {content}
    </>
  );
};

export default SubAbrDefVersionCreateForm;
