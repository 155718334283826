import { Dayjs } from 'dayjs';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { AbrechnungGenerierlaufType, AbrechnungType, HeOrObjektOrSubNamedAbrechnungGenerierlaufCreateInput } from '../../../../types';
import { firstDayOfCurrentYear, firstDayOfLastYear, lastDayOfCurrentYear, lastDayOfLastYear } from '../../../../helpers/dateHelper';
import { isAbrechnungTypeHeAbrechnung, isAbrechnungTypeSubAbrechnung } from '../abrechnungHelpers';

export type AbrechnungCreateFormValues = {
  fromTo: Dayjs[];
  bezeichnung?: string | null;
};

export const abrechnungsCreateFormInitialValues = (abrechnungType: AbrechnungType, abrBezeichnung?: string | null): AbrechnungCreateFormValues => {
  let firstDayOfPreviousYear;
  let lastDayOfPreviousYear;
  if (isAbrechnungTypeHeAbrechnung(abrechnungType)) {
    firstDayOfPreviousYear = firstDayOfLastYear();
    lastDayOfPreviousYear = lastDayOfLastYear();
  } else {
    firstDayOfPreviousYear = firstDayOfCurrentYear().subtract(1, 'year');
    lastDayOfPreviousYear = lastDayOfCurrentYear().subtract(1, 'year');
  }

  return {
    fromTo: [firstDayOfPreviousYear, lastDayOfPreviousYear],
    bezeichnung: abrBezeichnung || null,
  };
};

export const abrechnungsCreateFormFields: FormFields<AbrechnungCreateFormValues> = {
  fromTo: 'fromTo',
  bezeichnung: 'bezeichnung',
};

export const mapFormValuesToCreateNamedAbrechnungInput = (
  values: AbrechnungCreateFormValues,
  abrechnungType: AbrechnungType,
  objektId: string
): HeOrObjektOrSubNamedAbrechnungGenerierlaufCreateInput => ({
  abrechnungType,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  bezeichnung: values.bezeichnung!,
  type: getAbrechnungGenerierlaufType(abrechnungType),
  fromInclusive: mapFormDateValueToDateString(values.fromTo[0]),
  toInclusive: mapFormDateValueToDateString(values.fromTo[1]),
  objektIdList: [objektId],
});

const getAbrechnungGenerierlaufType = (abrechnungType: AbrechnungType): AbrechnungGenerierlaufType => {
  if (isAbrechnungTypeHeAbrechnung(abrechnungType)) {
    return AbrechnungGenerierlaufType.NamedHeAbrechnungGenerierlauf;
  }
  if (isAbrechnungTypeSubAbrechnung(abrechnungType)) {
    return AbrechnungGenerierlaufType.NamedSubAbrechnungGenerierlauf;
  }
  return AbrechnungGenerierlaufType.NamedObjektAbrechnungGenerierlauf;
};
