import React from 'react';
import EntryListingTable from './Table/EntryListingTable';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import LinkageDrawerAndButtons from '../LinkageDrawerAndButtons';
import { useBudgetingAbrDefVPosLinkageListQuery } from '../gql/budgetingAbrDefVPosLinkageQueries.types';

const EntryListing = () => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const { data, loading, refetch } = useBudgetingAbrDefVPosLinkageListQuery({ variables: { kundenSystemId } });

  const entryList = data?.getBudgetingAbrDefVPosLinkageList.data ?? [];
  return (
    <>
      <LinkageDrawerAndButtons linkageEntryList={entryList} onAction={refetch} />
      <EntryListingTable linkageEntryList={entryList} loading={loading} onAction={refetch} />
    </>
  );
};

export default EntryListing;
