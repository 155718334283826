import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { indexedValueListingFiltersFormFields, IndexedValueListingFiltersFormValues } from './indexedValueListingFiltersFormMapper';
import ObjektSelect from '../../../ObjektSelect/ObjektSelect';
import FormItemForFilter from '../../../../components/Form/FormItemForFilter';

type Props = {
  formikProps: FormikProps<IndexedValueListingFiltersFormValues>;
};

const ObjektSelectFormItem: FC<Props> = ({ formikProps }) => (
  <FormItemForFilter name={indexedValueListingFiltersFormFields.objektId}>
    <ObjektSelect
      name={indexedValueListingFiltersFormFields.objektId}
      prefix="Objekt"
      placeholder=""
      size="middle"
      onChange={(value) => {
        if (!value) {
          formikProps.setFieldValue(indexedValueListingFiltersFormFields.bestandseinheitId, '');
          formikProps.setFieldValue(indexedValueListingFiltersFormFields.vertragId, '');
        }
        formikProps.submitForm();
      }}
    />
  </FormItemForFilter>
);

export default ObjektSelectFormItem;
