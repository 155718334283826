import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TicketTemplateFieldsFragmentDoc } from './TicketTemplateFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketTemplateListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TicketTemplateListQuery = {
  getTicketTemplateList: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      dueDateText?: string | null;
      firmendatenId?: string | null;
      isSubTicketTemplate: boolean;
      name: string;
      parentId?: string | null;
      position: number;
      reminderText?: string | null;
      ticketTemplateId: string;
      updatedByMitarbeiterId?: string | null;
      subTicketTemplateList?: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        dueDateText?: string | null;
        firmendatenId?: string | null;
        isSubTicketTemplate: boolean;
        name: string;
        parentId?: string | null;
        position: number;
        reminderText?: string | null;
        ticketTemplateId: string;
        updatedByMitarbeiterId?: string | null;
        dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        priority: { text: string; value: Types.TicketPriority };
        reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      priority: { text: string; value: Types.TicketPriority };
      reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TicketTemplateQueryVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
}>;

export type TicketTemplateQuery = {
  getTicketTemplate: {
    data: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      dueDateText?: string | null;
      firmendatenId?: string | null;
      isSubTicketTemplate: boolean;
      name: string;
      parentId?: string | null;
      position: number;
      reminderText?: string | null;
      ticketTemplateId: string;
      updatedByMitarbeiterId?: string | null;
      subTicketTemplateList?: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        dueDateText?: string | null;
        firmendatenId?: string | null;
        isSubTicketTemplate: boolean;
        name: string;
        parentId?: string | null;
        position: number;
        reminderText?: string | null;
        ticketTemplateId: string;
        updatedByMitarbeiterId?: string | null;
        dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        priority: { text: string; value: Types.TicketPriority };
        reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      priority: { text: string; value: Types.TicketPriority };
      reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TicketTemplateChangeHistoryListQueryVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
}>;

export type TicketTemplateChangeHistoryListQuery = {
  getTicketTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const TicketTemplateListDocument = gql`
  query TicketTemplateList {
    getTicketTemplateList {
      data {
        ...TicketTemplateFields
        subTicketTemplateList {
          ...TicketTemplateFields
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TicketTemplateFieldsFragmentDoc}
`;
export function useTicketTemplateListQuery(baseOptions?: Apollo.QueryHookOptions<TicketTemplateListQuery, TicketTemplateListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketTemplateListQuery, TicketTemplateListQueryVariables>(TicketTemplateListDocument, options);
}
export function useTicketTemplateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketTemplateListQuery, TicketTemplateListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketTemplateListQuery, TicketTemplateListQueryVariables>(TicketTemplateListDocument, options);
}
export function useTicketTemplateListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketTemplateListQuery, TicketTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketTemplateListQuery, TicketTemplateListQueryVariables>(TicketTemplateListDocument, options);
}
export type TicketTemplateListQueryHookResult = ReturnType<typeof useTicketTemplateListQuery>;
export type TicketTemplateListLazyQueryHookResult = ReturnType<typeof useTicketTemplateListLazyQuery>;
export type TicketTemplateListSuspenseQueryHookResult = ReturnType<typeof useTicketTemplateListSuspenseQuery>;
export type TicketTemplateListQueryResult = Apollo.QueryResult<TicketTemplateListQuery, TicketTemplateListQueryVariables>;
export const TicketTemplateDocument = gql`
  query TicketTemplate($ticketTemplateId: ID!) {
    getTicketTemplate(ticketTemplateId: $ticketTemplateId) {
      data {
        ...TicketTemplateFields
        subTicketTemplateList {
          ...TicketTemplateFields
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TicketTemplateFieldsFragmentDoc}
`;
export function useTicketTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<TicketTemplateQuery, TicketTemplateQueryVariables> &
    ({ variables: TicketTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketTemplateQuery, TicketTemplateQueryVariables>(TicketTemplateDocument, options);
}
export function useTicketTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketTemplateQuery, TicketTemplateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketTemplateQuery, TicketTemplateQueryVariables>(TicketTemplateDocument, options);
}
export function useTicketTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketTemplateQuery, TicketTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketTemplateQuery, TicketTemplateQueryVariables>(TicketTemplateDocument, options);
}
export type TicketTemplateQueryHookResult = ReturnType<typeof useTicketTemplateQuery>;
export type TicketTemplateLazyQueryHookResult = ReturnType<typeof useTicketTemplateLazyQuery>;
export type TicketTemplateSuspenseQueryHookResult = ReturnType<typeof useTicketTemplateSuspenseQuery>;
export type TicketTemplateQueryResult = Apollo.QueryResult<TicketTemplateQuery, TicketTemplateQueryVariables>;
export const TicketTemplateChangeHistoryListDocument = gql`
  query TicketTemplateChangeHistoryList($ticketTemplateId: ID!) {
    getTicketTemplateChangeHistoryList(ticketTemplateId: $ticketTemplateId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useTicketTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<TicketTemplateChangeHistoryListQuery, TicketTemplateChangeHistoryListQueryVariables> &
    ({ variables: TicketTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketTemplateChangeHistoryListQuery, TicketTemplateChangeHistoryListQueryVariables>(
    TicketTemplateChangeHistoryListDocument,
    options
  );
}
export function useTicketTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketTemplateChangeHistoryListQuery, TicketTemplateChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketTemplateChangeHistoryListQuery, TicketTemplateChangeHistoryListQueryVariables>(
    TicketTemplateChangeHistoryListDocument,
    options
  );
}
export function useTicketTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TicketTemplateChangeHistoryListQuery, TicketTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketTemplateChangeHistoryListQuery, TicketTemplateChangeHistoryListQueryVariables>(
    TicketTemplateChangeHistoryListDocument,
    options
  );
}
export type TicketTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useTicketTemplateChangeHistoryListQuery>;
export type TicketTemplateChangeHistoryListLazyQueryHookResult = ReturnType<typeof useTicketTemplateChangeHistoryListLazyQuery>;
export type TicketTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useTicketTemplateChangeHistoryListSuspenseQuery>;
export type TicketTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  TicketTemplateChangeHistoryListQuery,
  TicketTemplateChangeHistoryListQueryVariables
>;
