import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { DataCarrierType } from '../../../../../types';

type Props = SelectProps;

const DataCarrierTypeFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const list = [
    {
      text: 'Überweisung',
      value: DataCarrierType.CreditTransfer,
    },
    {
      text: 'Lastschrift',
      value: DataCarrierType.DirectDebit,
    },
  ];

  return (
    <Select name={name} size="small" placeholder="Datenträgertyp auswählen" allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {list.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};
export default DataCarrierTypeFormSelect;
