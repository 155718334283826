import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import useDebounce from '../../../../hooks/useDebounce';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFirmendatenFileInfoInvoiceNumberListQuery } from '../../gql/FirmendatenFileInfoFilterQueries.types';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../../helpers/stringHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  invoiceNumber?: string | null;
} & SelectProps;

const DocumentInvoiceNumberFormSelect: FC<Props> = ({ invoiceNumber, ...restProps }) => {
  const [invoiceNumberText, setInvoiceNumberText] = useState<string>(invoiceNumber ? invoiceNumber.toString() : '');
  const debouncedText = useDebounce(invoiceNumberText);

  const { loading, data } = useFirmendatenFileInfoInvoiceNumberListQuery({
    variables: {
      invoiceNumber: debouncedText as string,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const list = data?.getFirmendatenFileInfoInvoiceNumberList.data ?? [];

  return (
    <>
      <Select
        id={restProps.name}
        loading={loading}
        size="small"
        allowClear
        showSearch
        placeholder="Belegnummer auswählen"
        filterOption={selectFilterOption}
        onSelect={setInvoiceNumberText}
        onSearch={(value) => {
          invoiceNumberText.length === 1 && !value && setInvoiceNumberText(''); // Checking the length of the invoiceNumberText makes the search component editable
          value && setInvoiceNumberText(value);
        }}
        onClear={() => setInvoiceNumberText('')}
        searchValue={invoiceNumberText}
        notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
        {...restProps}
      >
        {list?.map((belegnummer) => (
          <Select.Option key={belegnummer} value={belegnummer}>
            <DataWithShortenedText text={belegnummer} maxTextLength={40} />
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default DocumentInvoiceNumberFormSelect;
