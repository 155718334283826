import React from 'react';
import { useTicketTemplateListQuery } from './gql/TicketTemplateQueries.types';
import TicketTemplateActionButtonsAndDrawer from './TicketTemplateActionButtonsAndDrawer';
import TicketTemplateTable from './Table/TicketTemplateTable';
import TicketTemplateMutationHookResultProvider from './TicketTemplateMutationHookResultProvider';

const TicketTemplateListing = () => {
  const { data, loading, refetch } = useTicketTemplateListQuery();

  const ticketTemplateList = data?.getTicketTemplateList.data;

  return (
    <TicketTemplateMutationHookResultProvider refetch={refetch}>
      <TicketTemplateActionButtonsAndDrawer ticketTemplateList={ticketTemplateList} loading={loading} />
      <TicketTemplateTable ticketTemplateList={ticketTemplateList} loading={loading} />
    </TicketTemplateMutationHookResultProvider>
  );
};

export default TicketTemplateListing;
