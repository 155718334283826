import React from 'react';
import { useMitarbeiterSelectListQuery } from '../../../graphql/MitarbeiterService/MitarbeiterQueries.types';
import { EntityStatusFormSelect } from '../../../components/Status/EntityStatus';
import { Mitarbeiter } from '../../../types';

const ObjektSachbearbeiterSelect = ({ ...props }) => {
  const { loading, data } = useMitarbeiterSelectListQuery();
  const sachbearbeiterList = data?.getMitarbeiterList.data ?? [];

  return (
    <EntityStatusFormSelect<Mitarbeiter>
      list={sachbearbeiterList}
      getSelectItemId={(sachbearbeiter) => sachbearbeiter.mitarbeiterId}
      getSelectItemContent={(sachbearbeiter) => `${sachbearbeiter.firstname} ${sachbearbeiter.lastname}`}
      getSelectItemTooltipTitle={(sachbearbeiter) => `${sachbearbeiter.firstname} ${sachbearbeiter.lastname}`}
      id="sachbearbeiterId"
      name="sachbearbeiterId"
      loading={loading}
      style={{ width: '100%' }}
      placeholder="Sachbearbeiter auswählen"
      {...props}
    />
  );
};

export default ObjektSachbearbeiterSelect;
