import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery } from '../gql/AbrechnungsdefinitionQueries.types';
import { useAssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutation } from '../gql/AbrechnungsdefinitionMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import ZuordnungColumnViewAndEdit from '../shared/ZuordnungColumn/ZuordnungColumnViewAndEdit';
import { mapFormValuesToZuordnungList } from '../Form/zuordnungKontoListingFormMapper';
import { AbrKontenzuordnungFiltersFormValues } from './Filters/abrKontenzuordnungFiltersMapper';
import { AbrechnungsdefinitionListEntry, Konto } from '../../../types';

type Props = {
  abrechDef: AbrechnungsdefinitionListEntry;
  einnahmenKontoList: Konto[];
  ausgabenKontoList: Konto[];
  verrechnungList: Konto[];
  isEinnahmenListOpen: boolean;
  isAusgabenListOpen: boolean;
  isVerrechnungListOpen: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  filters?: AbrKontenzuordnungFiltersFormValues | null;
};

const AbrKontenzuordnungZuordnungColumn: FC<Props> = ({
  abrechDef,
  verrechnungList,
  ausgabenKontoList,
  einnahmenKontoList,
  isEinnahmenListOpen,
  isAusgabenListOpen,
  isVerrechnungListOpen,
  setError,
  filters,
}) => {
  const [editable, setEditable] = useState(false);

  const { data, loading, refetch, error } = useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery({
    variables: { abrechnungsdefinitionVersionId: abrechDef.abrechnungsdefinitionVersion?.abrechnungsdefinitionVersionId },
  });

  const allZuordnungList = data?.getAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList.data;
  const zuordnungList = allZuordnungList && allZuordnungList.length > 0 ? allZuordnungList[0]?.kontoAbrechnungskreisList : [];

  const [assign, { loading: loadingAssign }] = useAssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  useEffect(() => {
    if (error) setError(true);
    if (!loading && !error) setEditable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error]);

  return (
    <>
      <ZuordnungColumnViewAndEdit
        loading={loading}
        editable={editable}
        title={abrechDef.bezeichnung}
        abrechnungsdefinitionId={abrechDef.abrechnungsdefinitionId}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        abrechnungsdefinitionVersionId={abrechDef.abrechnungsdefinitionVersion!.abrechnungsdefinitionVersionId}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        abrDefVersionAbrechkreisList={abrechDef.abrechnungsdefinitionVersion!.abrechdefAbrechkreisList}
        abrDefinitionType={abrechDef.type.value}
        einnahmenKontoList={einnahmenKontoList}
        ausgabenKontoList={ausgabenKontoList}
        verrechnungList={verrechnungList}
        isEinnahmenListOpen={isEinnahmenListOpen}
        isAusgabenListOpen={isAusgabenListOpen}
        isVerrechnungListOpen={isVerrechnungListOpen}
        zuordnungList={zuordnungList}
        onSubmit={(formValues) => assign({ variables: { input: mapFormValuesToZuordnungList(formValues) } }).then(() => refetch())}
        isSubmitting={loadingAssign}
        filters={filters}
        mode="viewAndEdit"
      />
    </>
  );
};

export default AbrKontenzuordnungZuordnungColumn;
