import React from 'react';
import { useParams } from 'react-router';
import { Skeleton } from 'antd';
import { useQueryParams } from '../../../../../../hooks/useStringQueryParams';
import { AbrechnungsdefinitionViewPageQueryParam } from '../../../../../../features/Abrechnungsdefinition/abrDefUriPaths';
import { AbrechnungsdefinitionVersionViewPageRouteParams } from './routes';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import {
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
  isAbrDefTypeWeAbrechnung,
} from '../../../../../../features/Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import SysSettingsWeAbrechnungsdefinitionVersionViewPage from './SysSettingsWeAbrechnungsdefinitionVersionViewPage';
import SysSettingsHeAbrechnungsdefinitionVersionViewPage from './SysSettingsHeAbrechnungsdefinitionVersionViewPage';
import { useAbrechnungsdefinitionTypeQuery } from '../../../../../../features/Abrechnungsdefinition/gql/AbrDefQueries.types';
import SysSettingsBkAbrechnungsdefinitionVersionViewPage from './SysSettingsBkAbrechnungsdefinitionVersionViewPage';
import SysSettingsSubAbrechnungsdefinitionVersionViewPage from './SysSettingsSubAbrechnungsdefinitionVersionViewPage';

const SysSettingsAbrechnungsdefinitionVersionViewPage = () => {
  const { abrechnungsdefinitionVersionId, abrechnungsdefinitionId } = useParams() as AbrechnungsdefinitionVersionViewPageRouteParams;

  const { isHistoricized } = useQueryParams<{ [AbrechnungsdefinitionViewPageQueryParam.IS_HISTORICIZED]: string }>();
  const { activeForFirmendatenId: firmendatenId } = useFDAuthorized();

  const { loading, data } = useAbrechnungsdefinitionTypeQuery({ variables: { abrechnungsdefinitionId } });
  const abrDefType = data?.getAbrechnungsdefinitionType.data;

  if (!abrDefType || loading) {
    return <Skeleton active />;
  } else if (isAbrDefTypeWeAbrechnung(abrDefType.value)) {
    return (
      <SysSettingsWeAbrechnungsdefinitionVersionViewPage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
        isHistoricized={!!isHistoricized}
        firmendatenId={firmendatenId}
      />
    );
  } else if (isAbrDefTypeHeAbrechnung(abrDefType.value)) {
    return (
      <SysSettingsHeAbrechnungsdefinitionVersionViewPage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
        isHistoricized={!!isHistoricized}
        firmendatenId={firmendatenId}
      />
    );
  } else if (isAbrDefTypeSubAbrechnung(abrDefType.value)) {
    return (
      <SysSettingsSubAbrechnungsdefinitionVersionViewPage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
        firmendatenId={firmendatenId}
        isHistoricized={!!isHistoricized}
      />
    );
  } else {
    return (
      <SysSettingsBkAbrechnungsdefinitionVersionViewPage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
        isHistoricized={!!isHistoricized}
        firmendatenId={firmendatenId}
      />
    );
  }
};

export default SysSettingsAbrechnungsdefinitionVersionViewPage;
