import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';

import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import { generatePathToBeVertragDetailsPage } from '../../../Vertrag/BeVertrag/beVertragUriPaths';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import { BeNutzungsArt } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  timeblock: ITimeblock<BeNutzungsArt>;
  objektId: string;
  bestandseinheitId: string;
};

const NutzungsartVersionCardContent: FC<Props> = ({ bestandseinheitId, objektId, timeblock }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      <CustomFormattedDate value={timeblock.validFrom} />
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle="Nutzungsart">
      <Typography.Text>{timeblock.nutzungsArt.text}</Typography.Text>
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle="Vertrag">
      {timeblock.vertragId ? (
        <Link to={generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, timeblock.vertragId)}>{timeblock.vertragKurzBezeichnung}</Link>
      ) : (
        <Typography.Text>{timeblock.vertragKurzBezeichnung}</Typography.Text>
      )}
    </TimelineCardContentInfoRow>
  </Space>
);

export default NutzungsartVersionCardContent;
