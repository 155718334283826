import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { generatePathToUstKategoriePage, UstKategorieWithStatus } from '../../../../../Ust/UstKategorie/ustKategorieUriHelper';
import { Percent } from '../../../../../../components/Number';
import { isStatusArchivedOrGesperrt, IStatus } from '../../../../../../helpers/statusHelper';
import LinkWithStatus from '../../../../../../components/Link/LinkWithStatus';
import { useUstKategorieQuery } from '../../../../../Ust/UstKategorie/gql/UstKategorieQueries.types';

type SteuersatzAdminLinkProps = {
  parentStatus?: IStatus;
  ustKategorie?: UstKategorieWithStatus | null; // provide either ustKategorie or ustKategorieId
  ustKategorieId?: string; // provide either ustKategorie or ustKategorieId
  ustKategorieEntryId?: string | null;
  steuersatz: number;
  openInNewTab?: boolean;
};

const SteuersatzAdminLink: FC<SteuersatzAdminLinkProps> = (props) => {
  return props.ustKategorieId && !props.ustKategorie ? (
    <SteuersatzAdminLinkWithoutUstKategorie ustKategorieId={props.ustKategorieId} {...props} />
  ) : (
    <SteuersatzAdminLinkWithUstKategorie {...props} />
  );
};

type SteuersatzAdminLinkWithoutUstKategorieProps = SteuersatzAdminLinkProps & Required<Pick<SteuersatzAdminLinkProps, 'ustKategorieId'>>;

const SteuersatzAdminLinkWithoutUstKategorie: FC<SteuersatzAdminLinkWithoutUstKategorieProps> = ({
  ustKategorieEntryId,
  ustKategorieId,
  steuersatz,
  openInNewTab,
}) => {
  const { data, loading } = useUstKategorieQuery({ variables: { ustKategorieId } });
  const ustKategorie = data?.getUstKategorie.data as UstKategorieWithStatus;
  return (
    <Skeleton active paragraph={{ rows: 0 }} loading={loading}>
      {ustKategorie ? (
        <SteuersatzAdminLinkWithUstKategorie
          steuersatz={steuersatz}
          ustKategorie={ustKategorie}
          ustKategorieEntryId={ustKategorieEntryId}
          openInNewTab={openInNewTab}
        />
      ) : null}
    </Skeleton>
  );
};

const SteuersatzAdminLinkWithUstKategorie: FC<SteuersatzAdminLinkProps> = ({
  parentStatus,
  ustKategorie,
  ustKategorieEntryId,
  openInNewTab,
  steuersatz,
}) => {
  return ustKategorie && ustKategorieEntryId ? (
    <LinkWithStatus
      text=""
      status={isStatusArchivedOrGesperrt(ustKategorie.status) || !parentStatus ? ustKategorie.status : parentStatus}
      showBadgeDot={isStatusArchivedOrGesperrt(ustKategorie.status)}
      alternativeText={ustKategorie.kurzBezeichnung}
      to={generatePathToUstKategoriePage(ustKategorie.status.value, ustKategorieEntryId, { ustKategorieId: ustKategorie.ustKategorieId })}
      isLinkOnlyForAdmin
      openInNewTab={openInNewTab}
    >
      <Percent value={steuersatz / 100} />
    </LinkWithStatus>
  ) : (
    <Percent value={steuersatz / 100} />
  );
};

export default SteuersatzAdminLink;
