import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { NutzungsArtFieldsFragmentDoc } from './NutzungsartFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBeNutzungsArtMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  input: Types.BeNutzungsArtCreateInput;
}>;

export type CreateBeNutzungsArtMutation = {
  createBeNutzungsArt: {
    data: {
      beNutzungsArtId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      kommentar?: string | null;
      lastUpdateTs: string;
      vertragId?: string | null;
      vertragKurzBezeichnung?: string | null;
      validFrom: string;
      historicizedList?: Array<{
        beNutzungsArtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        validFrom: string;
        vertragId?: string | null;
        vertragKurzBezeichnung?: string | null;
        nutzungsArt: { text: string; value: Types.NutzungsArt };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      nutzungsArt: { text: string; value: Types.NutzungsArt };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBeNutzungsArtMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  beNutzungsArtId: Types.Scalars['ID']['input'];
  input: Types.BeNutzungsArtUpdateInput;
}>;

export type UpdateBeNutzungsArtMutation = {
  updateBeNutzungsArt: {
    data: {
      beNutzungsArtId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      kommentar?: string | null;
      lastUpdateTs: string;
      vertragId?: string | null;
      vertragKurzBezeichnung?: string | null;
      validFrom: string;
      historicizedList?: Array<{
        beNutzungsArtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        validFrom: string;
        vertragId?: string | null;
        vertragKurzBezeichnung?: string | null;
        nutzungsArt: { text: string; value: Types.NutzungsArt };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      nutzungsArt: { text: string; value: Types.NutzungsArt };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBeNutzungsArtMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  beNutzungsArtId: Types.Scalars['ID']['input'];
}>;

export type DeleteBeNutzungsArtMutation = {
  deleteBeNutzungsArt: {
    data: {
      beNutzungsArtId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      kommentar?: string | null;
      lastUpdateTs: string;
      vertragId?: string | null;
      vertragKurzBezeichnung?: string | null;
      validFrom: string;
      historicizedList?: Array<{
        beNutzungsArtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        validFrom: string;
        vertragId?: string | null;
        vertragKurzBezeichnung?: string | null;
        nutzungsArt: { text: string; value: Types.NutzungsArt };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      nutzungsArt: { text: string; value: Types.NutzungsArt };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateBeNutzungsArtDocument = gql`
  mutation CreateBeNutzungsArt($objektId: ID!, $bestandseinheitId: ID!, $input: BeNutzungsArtCreateInput!) {
    createBeNutzungsArt(objektId: $objektId, bestandseinheitId: $bestandseinheitId, input: $input) {
      data {
        ...NutzungsArtFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${NutzungsArtFieldsFragmentDoc}
`;
export function useCreateBeNutzungsArtMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBeNutzungsArtMutation, CreateBeNutzungsArtMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBeNutzungsArtMutation, CreateBeNutzungsArtMutationVariables>(CreateBeNutzungsArtDocument, options);
}
export type CreateBeNutzungsArtMutationHookResult = ReturnType<typeof useCreateBeNutzungsArtMutation>;
export type CreateBeNutzungsArtMutationResult = Apollo.MutationResult<CreateBeNutzungsArtMutation>;
export type CreateBeNutzungsArtMutationOptions = Apollo.BaseMutationOptions<CreateBeNutzungsArtMutation, CreateBeNutzungsArtMutationVariables>;
export const UpdateBeNutzungsArtDocument = gql`
  mutation UpdateBeNutzungsArt($objektId: ID!, $bestandseinheitId: ID!, $beNutzungsArtId: ID!, $input: BeNutzungsArtUpdateInput!) {
    updateBeNutzungsArt(objektId: $objektId, bestandseinheitId: $bestandseinheitId, beNutzungsArtId: $beNutzungsArtId, input: $input) {
      data {
        ...NutzungsArtFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${NutzungsArtFieldsFragmentDoc}
`;
export function useUpdateBeNutzungsArtMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBeNutzungsArtMutation, UpdateBeNutzungsArtMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBeNutzungsArtMutation, UpdateBeNutzungsArtMutationVariables>(UpdateBeNutzungsArtDocument, options);
}
export type UpdateBeNutzungsArtMutationHookResult = ReturnType<typeof useUpdateBeNutzungsArtMutation>;
export type UpdateBeNutzungsArtMutationResult = Apollo.MutationResult<UpdateBeNutzungsArtMutation>;
export type UpdateBeNutzungsArtMutationOptions = Apollo.BaseMutationOptions<UpdateBeNutzungsArtMutation, UpdateBeNutzungsArtMutationVariables>;
export const DeleteBeNutzungsArtDocument = gql`
  mutation DeleteBeNutzungsArt($objektId: ID!, $bestandseinheitId: ID!, $beNutzungsArtId: ID!) {
    deleteBeNutzungsArt(objektId: $objektId, bestandseinheitId: $bestandseinheitId, beNutzungsArtId: $beNutzungsArtId) {
      data {
        ...NutzungsArtFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${NutzungsArtFieldsFragmentDoc}
`;
export function useDeleteBeNutzungsArtMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBeNutzungsArtMutation, DeleteBeNutzungsArtMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBeNutzungsArtMutation, DeleteBeNutzungsArtMutationVariables>(DeleteBeNutzungsArtDocument, options);
}
export type DeleteBeNutzungsArtMutationHookResult = ReturnType<typeof useDeleteBeNutzungsArtMutation>;
export type DeleteBeNutzungsArtMutationResult = Apollo.MutationResult<DeleteBeNutzungsArtMutation>;
export type DeleteBeNutzungsArtMutationOptions = Apollo.BaseMutationOptions<DeleteBeNutzungsArtMutation, DeleteBeNutzungsArtMutationVariables>;
