import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TabPaneContent from '../../../../components/Tabs/TabPaneContent';
import BeVertragTicketListing from './BeVertragTicketListing';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
};

const BeVertragTicketTab: FC<Props> = ({ objektId, bestandseinheitId, vertragId }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Aufgaben',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <BeVertragTicketListing objektId={objektId} bestandseinheitId={bestandseinheitId} vertragId={vertragId} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default BeVertragTicketTab;
