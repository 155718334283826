import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../security/AuthRoute';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../../security/permissionChecks';
import SysSettingsAuftragsartUpdatePage from './SysSettingsAuftragsartUpdatePage';
import URI_SYS_SETTINGS_AUFTRAGSART_PAGE from '../../../../../features/Auftragsart/auftragsartUriPaths';

export type AuftragsartUpdatePageRouteParams = { auftragsartId: string };

const sysSettingsAuftragsartUpdatePageRoute = (
  <Route
    path={URI_SYS_SETTINGS_AUFTRAGSART_PAGE.updatePage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAuftragsartUpdatePage />} />}
  />
);

export default sysSettingsAuftragsartUpdatePageRoute;
