import { generatePath } from 'react-router-dom';
import { URI_SYS_SETTINGS } from '../../../constants/configurationUriPaths';
import { IndexType } from '../../../types';
import { mapToQueryString } from '../../../hooks/useStringQueryParams';
import { IndexSeriesQueryParams, TIndexSeriesQueryParams } from '../../IndexSeries/indexSeriesUriPaths';

export const URI_SYS_SETTINGS_FIRMENDATEN_INDEX_SERIES_DETAILS_PAGE = `${URI_SYS_SETTINGS.firmendatenIndexSeries}/:indexSeriesId/`;

export const generatePathToFirmendatenIndexSeriesDetailsPage = (indexSeriesId: string): string =>
  generatePath(URI_SYS_SETTINGS_FIRMENDATEN_INDEX_SERIES_DETAILS_PAGE, {
    indexSeriesId,
  });

export const updateFirmendatenIndexSeriesQueryParams = (navigate: (path: string) => void, filters: TIndexSeriesQueryParams) =>
  navigate(generatePathToFirmendatenIndexSeriesPage(filters.typ));

export const generatePathToFirmendatenIndexSeriesPage = (typ?: IndexType) =>
  `${generatePath(URI_SYS_SETTINGS.firmendatenIndexSeries)}?${mapToQueryString({
    [IndexSeriesQueryParams.TYP]: typ,
  })}`;
