import { FC } from 'react';
import { useBeVertragChangeHistoryListQuery } from '../gql/BeVertragQueries.types';
import { HistoryType } from '../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../History/HistoryListingTable';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
};

const VertragChangeHistoryListingTable: FC<Props> = ({ objektId, bestandseinheitId, vertragId }) => {
  const { data, loading } = useBeVertragChangeHistoryListQuery({ variables: { objektId, bestandseinheitId, vertragId } });
  const historyList = data?.getBeVertragChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="vertrag" />;
};

export default VertragChangeHistoryListingTable;
