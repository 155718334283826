import styled from 'styled-components';

interface Props {
  width?: number;
  textAlign?: any;
}

export const StyledTableRepFondsUstVomAufwandRows = styled('table')<Props>`
  thead {
    background-color: #fafafa;
    th {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      padding: 4px 4px;
      &:first-child {
        width: ${(props) => (props.width ? `${props.width}%` : '5%')};
        &::before {
          content: unset !important;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: ${(props) => (props.textAlign ? `${props.textAlign}` : 'right')};
        justify-content: flex-end;
      }
      &:last-child td {
        border: 0;
      }
      .ant-empty {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
`;

export const StyledTableRepFondsEinnahmenRows = styled('table')<Props>`
  thead {
    background-color: #fafafa;
    th {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      padding: 4px 4px;

      &:nth-last-child(2) {
        width: 29.2%;
      }
      &:last-child {
        width: 12.6%;
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: ${(props) => (props.textAlign ? `${props.textAlign}` : 'right')};
        justify-content: flex-end;
      }
      &:last-child td {
        border: 0;
      }
      .ant-empty {
        color: rgba(0, 0, 0, 0.25);
      }
      &:nth-last-child(2) {
        width: 29.2%;
      }
      &:last-child {
        width: 12.6%;
      }
    }
  }
`;
