import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateObjektFinancialPlanGenerierlaufMutationVariables = Types.Exact<{
  input: Types.ObjektFinancialPlanGenerierlaufInput;
}>;

export type CreateObjektFinancialPlanGenerierlaufMutation = {
  createObjektFinancialPlanGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RestartObjektFinancialPlanGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartObjektFinancialPlanGenerierlaufMutation = {
  actionRestartObjektFinancialPlanGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateObjektFinancialPlanGenerierlaufDocument = gql`
  mutation CreateObjektFinancialPlanGenerierlauf($input: ObjektFinancialPlanGenerierlaufInput!) {
    createObjektFinancialPlanGenerierlauf(input: $input) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateObjektFinancialPlanGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateObjektFinancialPlanGenerierlaufMutation, CreateObjektFinancialPlanGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateObjektFinancialPlanGenerierlaufMutation, CreateObjektFinancialPlanGenerierlaufMutationVariables>(
    CreateObjektFinancialPlanGenerierlaufDocument,
    options
  );
}
export type CreateObjektFinancialPlanGenerierlaufMutationHookResult = ReturnType<typeof useCreateObjektFinancialPlanGenerierlaufMutation>;
export type CreateObjektFinancialPlanGenerierlaufMutationResult = Apollo.MutationResult<CreateObjektFinancialPlanGenerierlaufMutation>;
export type CreateObjektFinancialPlanGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateObjektFinancialPlanGenerierlaufMutation,
  CreateObjektFinancialPlanGenerierlaufMutationVariables
>;
export const RestartObjektFinancialPlanGenerierlaufDocument = gql`
  mutation RestartObjektFinancialPlanGenerierlauf($generierlaufId: ID!) {
    actionRestartObjektFinancialPlanGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartObjektFinancialPlanGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartObjektFinancialPlanGenerierlaufMutation, RestartObjektFinancialPlanGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartObjektFinancialPlanGenerierlaufMutation, RestartObjektFinancialPlanGenerierlaufMutationVariables>(
    RestartObjektFinancialPlanGenerierlaufDocument,
    options
  );
}
export type RestartObjektFinancialPlanGenerierlaufMutationHookResult = ReturnType<typeof useRestartObjektFinancialPlanGenerierlaufMutation>;
export type RestartObjektFinancialPlanGenerierlaufMutationResult = Apollo.MutationResult<RestartObjektFinancialPlanGenerierlaufMutation>;
export type RestartObjektFinancialPlanGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartObjektFinancialPlanGenerierlaufMutation,
  RestartObjektFinancialPlanGenerierlaufMutationVariables
>;
