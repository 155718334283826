import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteEmailIntegrationMutationVariables = Types.Exact<{ [key: string]: never }>;

export type DeleteEmailIntegrationMutation = {
  deleteEmailIntegration: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const DeleteEmailIntegrationDocument = gql`
  mutation DeleteEmailIntegration {
    deleteEmailIntegration {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteEmailIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEmailIntegrationMutation, DeleteEmailIntegrationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEmailIntegrationMutation, DeleteEmailIntegrationMutationVariables>(DeleteEmailIntegrationDocument, options);
}
export type DeleteEmailIntegrationMutationHookResult = ReturnType<typeof useDeleteEmailIntegrationMutation>;
export type DeleteEmailIntegrationMutationResult = Apollo.MutationResult<DeleteEmailIntegrationMutation>;
export type DeleteEmailIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmailIntegrationMutation,
  DeleteEmailIntegrationMutationVariables
>;
