import React, { FC } from 'react';
import { Skeleton } from 'antd';
import {
  useHeAbrechnungsdefinitionVersionQuery,
  useHeAbrechnungsdefinitionVersionListQuery,
} from '../../../../../../features/Abrechnungsdefinition/gql/HeAbrDef/HeAbrDefVersionQueries.types';
import HeAbrDefVersionUpdateForm from '../../../../../../features/Abrechnungsdefinition/Version/Form/Update/HeAbrDefVersionUpdateForm';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
};

const SysSettingsHeAbrechnungsdefinitionVersionUpdatePage: FC<Props> = ({ abrechnungsdefinitionId, abrechnungsdefinitionVersionId }) => {
  const { data: heAbrDefVersionListData } = useHeAbrechnungsdefinitionVersionListQuery({
    variables: { abrechnungsdefinitionId, withDetails: true },
  });
  const abrechnungsdefinitionVersionList = heAbrDefVersionListData?.getHeAbrechnungsdefinitionVersionList.data;

  const { data: heAbrDefVersion, refetch } = useHeAbrechnungsdefinitionVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId },
  });
  const abrechnungsdefinitionVersion = heAbrDefVersion?.getHeAbrechnungsdefinitionVersion.data;

  if (!abrechnungsdefinitionVersion || !abrechnungsdefinitionVersionList) return <Skeleton active />;

  const firmendatenId = abrechnungsdefinitionVersion.firmendatenId ?? '';

  return (
    <HeAbrDefVersionUpdateForm
      abrechnungsdefinitionVersionList={abrechnungsdefinitionVersionList}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      validFrom={abrechnungsdefinitionVersion.validFrom}
      kommentar={abrechnungsdefinitionVersion.kommentar ?? ''}
      onSuccess={refetch}
      firmendatenId={firmendatenId}
    />
  );
};

export default SysSettingsHeAbrechnungsdefinitionVersionUpdatePage;
