import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { TopFinancialPlanPageRouteParams } from './routes';
import menuListTopFinancialPlan from './menuListTopFinancialPlan';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { TopFinancialPlanGenerierlauf } from '../../../types';
import { useTopFinancialPlanGenerierlaufQuery } from '../../../features/Verarbeitung/gql/TopFinancialPlanGenerierlauf/TopFinancialPlanGenerierlaufQueries.types';
import { useRestartTopFinancialPlanGenerierlaufMutation } from '../../../features/Verarbeitung/gql/TopFinancialPlanGenerierlauf/TopFinancialPlanGenerierlaufMutations.types';

const TopFinancialPlanDetailsPage = () => {
  const { generierlaufId } = useParams() as TopFinancialPlanPageRouteParams;

  const { data, refetch } = useTopFinancialPlanGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getTopFinancialPlanGenerierlauf.data;

  const [runRestart] = useRestartTopFinancialPlanGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<TopFinancialPlanGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.TopFinancialPlan}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.TopFinancialPlan}
          onGenerierlaufRestart={runRestart}
        />
      )}
      isLoading={false}
      sidebarMenuList={menuListTopFinancialPlan}
    />
  );
};

export default TopFinancialPlanDetailsPage;
