import { Empty, PaginationProps } from 'antd';
import React, { FC } from 'react';
import NestedTable from '../../../../../../components/Table/NestedTable/NestedTable';
import { DataCarrierCamtListEntry } from '../../../../../../types';
import dataCarrierCamtTableColumns from './dataCarrierCamtTableColumns';
import { useAuth } from '../../../../../../shared/Auth/authContext';
import BankstatementTable from '../Level2/BankstatementTable';
import { HandleTableSorting } from '../../../../../../shared/typeHelpers';

type Props = {
  loading: boolean;
  dataCarrierCamtList: DataCarrierCamtListEntry[];
  pagination: PaginationProps;
  handleTableSorting: HandleTableSorting;
  onAction: () => void;
};

const DataCarrierCamtTable: FC<Props> = ({ loading, dataCarrierCamtList, pagination, handleTableSorting, onAction }) => {
  const { activeForFirmendatenId: firmendatenId } = useAuth();

  return (
    <NestedTable<DataCarrierCamtListEntry>
      loading={loading}
      level={1}
      colWidth={150}
      locale={{
        emptyText: <Empty description={<span>Keine Datenträger vorhanden</span>} />,
      }}
      dataSource={dataCarrierCamtList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={dataCarrierCamtTableColumns(firmendatenId as string, onAction)}
      expandable={{
        rowExpandable: (record) => !!record.bankStatementList.length,
        expandedRowRender: (record) => (
          <BankstatementTable bankstatementList={record.bankStatementList} dataCarrierId={record.dataCarrierId} onAction={onAction} />
        ),
      }}
      useHighlighting
      rowKey={(record) => record.dataCarrierId}
      expandedRowKey="dataCarrierId"
    />
  );
};

export default DataCarrierCamtTable;
