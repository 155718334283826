import React, { FC, useState } from 'react';
import { useToggle } from '../../../../hooks/useToggle';
import { useCurrentBookingDetails } from '../../shared/useCurrentBookingDetails';
import BookingDetailsWithBookingSuggestionView from './BookingDetailsWithBookingSuggestionView';
import useOnBookingDataChangeEvents from '../../useOnBookingDataChangeEvents';
import BookingDetailsEdit from '../../Edit/BookingDetailsEdit';
import useBookingSuggestion from './useBookingSuggestion';
import { FibuBuchungszeile } from '../../../../types';

type Props = {
  bookingSuggestionId: string;
  buchungszeileList: FibuBuchungszeile[];
};

const BookingDetailsWithBookingSuggestion: FC<Props> = ({ bookingSuggestionId, buchungszeileList }) => {
  const [isUpdate, toggleIsUpdate] = useToggle();
  const [bookingCorrectionInProgress, setBookingCorrectionInProgress] = useState<boolean | 'done'>(false); // will be changed to true when the user clicks on the "Speichern und Verbuchen" button in the Edit mode and comes back to view page

  useOnBookingDataChangeEvents(bookingSuggestionId);

  /******** data for latest (=current) booking ********/
  const {
    amountFromPersonenkonto,
    belegSymbol,
    buchungType,
    buchungskreisRechtstraegerId,
    currentFibuBuchungId,
    isEditAllowedBasedOnBuchungType,
    personenkontoId,
    zahlungId,
  } = useCurrentBookingDetails(buchungszeileList);

  /******** data for booking suggestion with functionality of setting bookingCorrectionInProgress accordingly to buchung state ********/
  const { bookingSuggestion, isBookingSuggestionLoading } = useBookingSuggestion(
    bookingSuggestionId,
    setBookingCorrectionInProgress,
    currentFibuBuchungId
  );

  /******** render booking details view or edit mode after data has been loaded ********/
  return isEditAllowedBasedOnBuchungType && isUpdate ? (
    <BookingDetailsEdit
      bookingSuggestion={bookingSuggestion}
      bookingZahlungId={zahlungId}
      isBookingSuggestionLoading={isBookingSuggestionLoading}
      onCancel={toggleIsUpdate}
      onUpdate={(isBuchungsanweisungChanged) => {
        toggleIsUpdate();
        setBookingCorrectionInProgress(isBuchungsanweisungChanged);
      }}
    />
  ) : (
    <BookingDetailsWithBookingSuggestionView
      amountFromPersonenkonto={amountFromPersonenkonto}
      belegSymbol={belegSymbol}
      bookingCorrectionInProgress={bookingCorrectionInProgress}
      bookingSuggestionId={bookingSuggestionId}
      bookingZahlungId={zahlungId}
      buchungType={buchungType}
      buchungskreisRechtstraegerId={buchungskreisRechtstraegerId}
      buchungszeileList={buchungszeileList}
      isEditAllowedBasedOnBuchungType={isEditAllowedBasedOnBuchungType}
      personenkontoId={personenkontoId}
      toggleIsUpdate={toggleIsUpdate}
    />
  );
};

export default BookingDetailsWithBookingSuggestion;
