import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFields_AbstractAuftragVerbuchenGenerierlaufEntryAuftragDetails_Fragment = {
  auftragId: string;
  belegId: string;
  belegFileId: string;
  belegType: string;
  belegdatum: string;
  belegnummer: string;
  buchungIdList: Array<string>;
  sumBrutto: number;
  sumNetto: number;
  sumUst: number;
  type: Types.AbstractAuftragVerbuchenGenerierlaufEntryDetailsType;
};

export type AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFields_AbstractAuftragVerbuchenGenerierlaufEntrySingleInvoiceDetails_Fragment = {
  belegId: string;
  belegFileId: string;
  belegType: string;
  belegdatum: string;
  belegnummer: string;
  buchungIdList: Array<string>;
  sumBrutto: number;
  sumNetto: number;
  sumUst: number;
  type: Types.AbstractAuftragVerbuchenGenerierlaufEntryDetailsType;
};

export type AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFieldsFragment =
  | AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFields_AbstractAuftragVerbuchenGenerierlaufEntryAuftragDetails_Fragment
  | AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFields_AbstractAuftragVerbuchenGenerierlaufEntrySingleInvoiceDetails_Fragment;

export const AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFieldsFragmentDoc = gql`
  fragment AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFields on AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute {
    belegId
    belegFileId
    belegType
    belegdatum
    belegnummer
    buchungIdList
    sumBrutto
    sumNetto
    sumUst
    type
    ... on AbstractAuftragVerbuchenGenerierlaufEntryAuftragDetails {
      auftragId
    }
  }
`;
