import React, { FC } from 'react';
import { IncomingInvoiceFormValues } from '../../../incomingInvoiceFormMapper';
import { StornoFormValues } from '../../../SingleInvoiceStornoModal/StornoForm/stornoFormMapper';
import DirectBookingPreviewDetails from './DirectBookingPreviewDetails';
import AccrualBookingPreviewDetails from './AccrualBookingPreviewDetails';
import { mapFormValuesToSingleInvoiceUpdate } from '../../../singleInvoiceFormMapper';

type Props = {
  belegId: string;
  incomingInvoiceFormValues: IncomingInvoiceFormValues;
  stornoFormValues: StornoFormValues;
  isSubmitting: boolean;
};

const BookingPreview: FC<Props> = ({ belegId, incomingInvoiceFormValues, stornoFormValues, isSubmitting }) => {
  const isStornoAbgrenzungNotEmpty =
    stornoFormValues.stornoAbgrenzung.stornoBuchungsdatum &&
    stornoFormValues.stornoAbgrenzung.korrekturBuchungsdatum &&
    stornoFormValues.stornoAbgrenzung.stornoAbgrenzungsBuchungsdatum &&
    stornoFormValues.stornoAbgrenzung.korrekturAbgrenzungsBuchungsdatum;

  const isStornoDirektNotEmpty = stornoFormValues.stornoDirekt.stornoBuchungsdatum && stornoFormValues.stornoDirekt.korrekturBuchungsdatum;

  const input =
    isStornoDirektNotEmpty || isStornoAbgrenzungNotEmpty
      ? mapFormValuesToSingleInvoiceUpdate(incomingInvoiceFormValues, stornoFormValues)
      : mapFormValuesToSingleInvoiceUpdate(incomingInvoiceFormValues);

  return (
    <>
      {!stornoFormValues.abgrenzungsBuchung ? (
        <DirectBookingPreviewDetails belegId={belegId} input={input} disabled={!isStornoDirektNotEmpty} isSubmitting={isSubmitting} />
      ) : (
        <AccrualBookingPreviewDetails belegId={belegId} input={input} disabled={!isStornoAbgrenzungNotEmpty} isSubmitting={isSubmitting} />
      )}
    </>
  );
};

export default BookingPreview;
