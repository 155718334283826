import React, { FC } from 'react';
import { Select } from 'formik-antd';
import { ALL_KONTO_TYPE_TUPLE } from './saldenlisteListingFiltersFormMapper';
import { useSaldenlisteKontoTypeListQuery } from '../gql/SaldenlisteQueries.types';

type SaldenlisteKontoTypeSelectProps = {
  name: string;
  onChange: () => void;
};

const SaldenlisteKontoTypeSelect: FC<SaldenlisteKontoTypeSelectProps> = ({ name, onChange }) => {
  const { data, loading } = useSaldenlisteKontoTypeListQuery();
  const kontotypeList = data?.getSaldenlisteKontoTypeList.data || [];

  return (
    <Select name={name} id={name} loading={loading} placeholder="Kontotyp auswählen" onChange={onChange}>
      <Select.Option key={ALL_KONTO_TYPE_TUPLE.value} value={ALL_KONTO_TYPE_TUPLE.value}>
        {ALL_KONTO_TYPE_TUPLE.text}
      </Select.Option>
      {kontotypeList.map((kontotype) => (
        <Select.Option key={kontotype.value} value={kontotype.value}>
          {kontotype.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SaldenlisteKontoTypeSelect;
