import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateHeVertragMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  input: Types.HeVertragCreateInput;
}>;

export type CreateHeVertragMutation = {
  createHeVertrag: {
    data: { heVertragId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateHeVertragMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['ID']['input'];
  input: Types.HeVertragUpdateInput;
}>;

export type UpdateHeVertragMutation = {
  updateHeVertrag: {
    data: { heVertragId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteHeVertragMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['ID']['input'];
}>;

export type DeleteHeVertragMutation = {
  deleteHeVertrag: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type CreateHeVertragPostItMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['ID']['input'];
  input: Types.PostItInput;
}>;

export type CreateHeVertragPostItMutation = {
  createHeVertragPostIt: {
    data: { postItId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateHeVertragDocument = gql`
  mutation CreateHeVertrag($objektId: ID!, $input: HeVertragCreateInput!) {
    createHeVertrag(objektId: $objektId, input: $input) {
      data {
        heVertragId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateHeVertragMutation(baseOptions?: Apollo.MutationHookOptions<CreateHeVertragMutation, CreateHeVertragMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateHeVertragMutation, CreateHeVertragMutationVariables>(CreateHeVertragDocument, options);
}
export type CreateHeVertragMutationHookResult = ReturnType<typeof useCreateHeVertragMutation>;
export type CreateHeVertragMutationResult = Apollo.MutationResult<CreateHeVertragMutation>;
export type CreateHeVertragMutationOptions = Apollo.BaseMutationOptions<CreateHeVertragMutation, CreateHeVertragMutationVariables>;
export const UpdateHeVertragDocument = gql`
  mutation UpdateHeVertrag($objektId: String!, $heVertragId: ID!, $input: HeVertragUpdateInput!) {
    updateHeVertrag(objektId: $objektId, heVertragId: $heVertragId, input: $input) {
      data {
        heVertragId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateHeVertragMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHeVertragMutation, UpdateHeVertragMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateHeVertragMutation, UpdateHeVertragMutationVariables>(UpdateHeVertragDocument, options);
}
export type UpdateHeVertragMutationHookResult = ReturnType<typeof useUpdateHeVertragMutation>;
export type UpdateHeVertragMutationResult = Apollo.MutationResult<UpdateHeVertragMutation>;
export type UpdateHeVertragMutationOptions = Apollo.BaseMutationOptions<UpdateHeVertragMutation, UpdateHeVertragMutationVariables>;
export const DeleteHeVertragDocument = gql`
  mutation DeleteHeVertrag($objektId: String!, $heVertragId: ID!) {
    deleteHeVertrag(objektId: $objektId, heVertragId: $heVertragId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteHeVertragMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHeVertragMutation, DeleteHeVertragMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteHeVertragMutation, DeleteHeVertragMutationVariables>(DeleteHeVertragDocument, options);
}
export type DeleteHeVertragMutationHookResult = ReturnType<typeof useDeleteHeVertragMutation>;
export type DeleteHeVertragMutationResult = Apollo.MutationResult<DeleteHeVertragMutation>;
export type DeleteHeVertragMutationOptions = Apollo.BaseMutationOptions<DeleteHeVertragMutation, DeleteHeVertragMutationVariables>;
export const CreateHeVertragPostItDocument = gql`
  mutation CreateHeVertragPostIt($objektId: String!, $heVertragId: ID!, $input: PostItInput!) {
    createHeVertragPostIt(objektId: $objektId, heVertragId: $heVertragId, input: $input) {
      data {
        postItId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateHeVertragPostItMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateHeVertragPostItMutation, CreateHeVertragPostItMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateHeVertragPostItMutation, CreateHeVertragPostItMutationVariables>(CreateHeVertragPostItDocument, options);
}
export type CreateHeVertragPostItMutationHookResult = ReturnType<typeof useCreateHeVertragPostItMutation>;
export type CreateHeVertragPostItMutationResult = Apollo.MutationResult<CreateHeVertragPostItMutation>;
export type CreateHeVertragPostItMutationOptions = Apollo.BaseMutationOptions<CreateHeVertragPostItMutation, CreateHeVertragPostItMutationVariables>;
