import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Location } from 'history';

type LastLocationType = null | Location;

interface GoBackContextType {
  lastLocation?: LastLocationType;
}

const GoBackContext = createContext<GoBackContextType>({});

export const GoBackProvider: FC<PropsWithChildren> = (props) => {
  const location = useLocation();

  const [currentLocation, setCurrentLocation] = useState<LastLocationType>(null);
  const [lastLocation, setLastLocation] = useState<LastLocationType>(null);

  const contextValues = useMemo(() => ({ lastLocation }), [lastLocation]);

  if (location !== currentLocation) {
    setLastLocation(currentLocation !== null ? { ...currentLocation } : null);
    setCurrentLocation(location);
  }

  return <GoBackContext.Provider value={contextValues}>{props.children}</GoBackContext.Provider>;
};

export const useGoBack = (fallbackPath: string): (() => void) => {
  const context = useContext(GoBackContext);
  const navigate = useNavigate();
  const currentLocation = useLocation();

  if (!context) {
    throw new Error('useLastLocation must be used within GoBackContext');
  }
  const lastLocation = context.lastLocation;

  const isLastLocationAvailableAndIsNotAChildOfCurrentLocation = () =>
    lastLocation && !(lastLocation.pathname.includes(currentLocation.pathname) && lastLocation.pathname.length >= currentLocation.pathname.length);

  const goBackOrToFallbackPath = () => {
    if (isLastLocationAvailableAndIsNotAChildOfCurrentLocation()) {
      navigate(-1);
    } else {
      navigate(fallbackPath);
    }
  };

  return goBackOrToFallbackPath;
};
