import React, { FC, useState } from 'react';
import { Input } from 'formik-antd';
import { useField } from 'formik';
import zxcvbn from 'zxcvbn';
import { Alert } from 'antd';
import styled from 'styled-components';
import { Spacer } from '../../../components/Grid';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';

type NewPasswordFormPartProps = {
  layout?: Record<string, unknown>;
  newPasswordInputName?: string;
  newPasswordPlaceholder?: string;
  newPasswordLabel?: string;
  newPasswordConfirmInputName?: string;
  newPasswordConfirmPlaceholder?: string;
  newPasswordConfirmLabel?: string;
};

const NewPasswordFormPart: FC<NewPasswordFormPartProps> = ({
  newPasswordInputName = 'newPassword',
  newPasswordPlaceholder = 'Passwort',
  newPasswordLabel = 'Passwort',
  newPasswordConfirmInputName = 'newPasswordConfirm',
  newPasswordConfirmPlaceholder = 'Passwort',
  newPasswordConfirmLabel = 'Passwort wiederholen',
}) => {
  const [{ value: password }, passwordMeta] = useField<string>({
    name: newPasswordInputName,
    validate: (password) => {
      const { isValid } = validatePassword(password);
      return isValid ? undefined : '-';
    },
  });
  const [, { error, touched }] = useField<string>({
    name: newPasswordConfirmInputName,
    validate: (passwordConfirm) => (passwordConfirm === password ? undefined : 'Passwort unterscheidet sich'),
  });
  const { touched: isPwTouched } = passwordMeta;

  const [strength, setStrength] = useState<number | null>(null);
  const strengthPositiveCaption = getStrengthPositiveCaption(strength);
  const strengthNegativeCaption = getStrengthNegativeCaption(strength);

  const { pwContainsLetter, pwContainsNumber, pwLongEnough, isValid: isPwValid } = validatePassword(password);
  const shouldShowError = !isPwValid && isPwTouched;
  const shouldShowErrorForConfirm = error && touched;

  const calculateStrength = (password: string) => {
    const { score } = zxcvbn(password);
    setStrength(score);
  };

  const getListItemStyle = (valid: boolean): any => {
    if (valid) {
      return '#d9d9d9';
    }
    return '';
  };

  return (
    <>
      <FormItemWithoutColon
        name={newPasswordInputName}
        label={newPasswordLabel}
        validateStatus={shouldShowError ? 'error' : ''}
        help={isPwValid ? strengthNegativeCaption : 'Ihr neues Passwort'}
      >
        <Input.Password
          onChange={(event) => calculateStrength(event.currentTarget.value)}
          id={newPasswordInputName}
          name={newPasswordInputName}
          placeholder={newPasswordPlaceholder}
        />
      </FormItemWithoutColon>
      <FormItemWithoutColon
        name={newPasswordConfirmInputName}
        label={newPasswordConfirmLabel}
        validateStatus={shouldShowErrorForConfirm ? 'error' : ''}
        help={shouldShowErrorForConfirm ? error : 'Ihr neues Passwort wiederholen'}
      >
        <Input.Password
          disabled={!isPwValid}
          id={newPasswordConfirmInputName}
          name={newPasswordConfirmInputName}
          placeholder={newPasswordConfirmPlaceholder}
        />
      </FormItemWithoutColon>

      <Spacer height={16} />

      {strengthPositiveCaption ? (
        <Alert message="Ihr Passwort ist sicher!" type="success" />
      ) : (
        <StyledUl>
          <ListItem color={getListItemStyle(pwLongEnough)}>mindestens 8 Zeichen</ListItem>
          <ListItem color={getListItemStyle(pwContainsLetter)}>ein Buchstabe</ListItem>
          <ListItem color={getListItemStyle(pwContainsNumber)}>eine Zahl</ListItem>
        </StyledUl>
      )}
    </>
  );
};

const StyledUl = styled.ul`
  padding-inline-start: 8px;
`;

const ListItem = styled.li.attrs((props: any) => ({
  color: props.color || 'rgba(0, 0, 0, 0.65)',
}))`
  color: ${(props) => props.color};
  list-style: none;
  ::before {
    content: '';
    background: ${(props) => props.color};
    transition: all 0.25s ease-in 0s;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 12px;
    border-radius: 8px;
  }
`;

const getStrengthPositiveCaption = (strength: number | null) => {
  switch (strength) {
    case 3:
      return 'Ihr Passwort ist sicher und Sie sind fertig!';
    case 4:
      return 'Ihr Passwort ist sicher und Sie sind fertig!';
    default:
      return null;
  }
};

const getStrengthNegativeCaption = (strength: number | null) => {
  switch (strength) {
    case 0:
      return 'Ihr Passwort ist nicht sicher, aber Sie können fortfahren!';
    case 1:
      return 'Ihr Passwort ist nicht sicher, aber Sie können fortfahren!';
    case 2:
      return 'Ihr Passwort ist nicht sicher, aber Sie können fortfahren!';
    default:
      return null;
  }
};

const validatePassword = (password: string) => {
  const pwContainsLetter = containsLetter(password);
  const pwContainsNumber = containsNumber(password);
  const pwLongEnough = password.length >= 8;
  const isPwValid = pwContainsLetter && pwContainsNumber && pwLongEnough;
  return { isValid: isPwValid, pwContainsLetter, pwContainsNumber, pwLongEnough };
};

const containsLetter = (targetString: string) => /[a-zA-Z]/.test(targetString);
const containsNumber = (targetString: string) => /\d+/.test(targetString);

export default NewPasswordFormPart;
