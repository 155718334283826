import React, { FC } from 'react';
import { Row } from 'antd';
import { FibuBuchungszeile } from '../../../../../../types';
import KtoDetailsCardsBlock from './KtoDetailsCardsBlock';

type Props = {
  selectedRow: FibuBuchungszeile;
};

const BuchungszeileDetailsCardsBlock: FC<Props> = ({ selectedRow }) => {
  return (
    <Row justify="space-between" style={{ padding: selectedRow ? '16px' : '16px 16px 8px 16px' }}>
      <KtoDetailsCardsBlock buchungszeile={selectedRow} />
    </Row>
  );
};

export default BuchungszeileDetailsCardsBlock;
