import React, { FC } from 'react';
import { Select } from 'formik-antd';
import { dayjsCustom } from '../../../../../../../../../helpers/dayjsCustom';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  name: string;
  isDisabled?: boolean;
  onChange: (month: number | null) => void;
  fieldHelp?: string | null;
};

const DueDateMonthSelect: FC<Props> = ({ name, isDisabled, onChange, fieldHelp }) => {
  const months = dayjsCustom.months();

  return (
    <FormItemWithFieldHelp name={name} label="Monat" style={{ width: '200px' }} fieldHelp={fieldHelp}>
      <Select size="small" name={name} id={name} placeholder="Monat auswählen" disabled={isDisabled} onChange={onChange}>
        {months.map((month, index) => (
          <Select.Option key={index} value={index}>
            {month}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithFieldHelp>
  );
};

export default DueDateMonthSelect;
