import React from 'react';
import { CopyOutlined, HomeOutlined, InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  generatePathToBestandseinheitDetailsPage,
  generatePathToBestandseinheitDetailsPageInfoFeldMenu,
} from '../../features/Bestandseinheit/bestandseinheitUriPaths';
import VertragAufteilungsschluesselTab from '../../features/Vertrag/BeVertrag/VertragAufteilungsschluesselTab';
import DocumentTab from '../../features/Document/DocumentTab';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import BeInfoFeldTab from '../../features/Bestandseinheit/InfoFeld/BeInfoFeldTab';
import { Bestandseinheit } from '../../types';
import { DOCUMENT_FILTERS_SETTINGS } from '../../features/Document/Listing/Filters/listingFiltersHelper';
import BestandseinheitTicketTab from '../../features/Bestandseinheit/Ticket/BestandseinheitTicketTab';

const menuListBestandseinheit = (
  objektId: string,
  bestandseinheitId: string,
  bestandseinheitData: Bestandseinheit,
  rechnungsausstellerId: string
): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Vertäge/Aufteilungsschlüssel',
      icon: <HomeOutlined />,
      uri: `${generatePathToBestandseinheitDetailsPage(objektId, bestandseinheitId)}/contracts-allocation-keys`,
      content: (props) => (
        <VertragAufteilungsschluesselTab
          bestandseinheitId={bestandseinheitId}
          objektId={objektId}
          bestandseinheit={bestandseinheitData}
          rechnungsausstellerId={rechnungsausstellerId}
          {...props}
        />
      ),
    },
    {
      title: 'Aufgaben',
      icon: <InboxOutlined />,
      uri: `${generatePathToBestandseinheitDetailsPage(objektId, bestandseinheitId)}/aufgaben`,
      content: () => <BestandseinheitTicketTab objektId={objektId} bestandseinheitId={bestandseinheitId} />,
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: `${generatePathToBestandseinheitDetailsPage(objektId, bestandseinheitId)}/documents`,
      content: (props) => (
        <DocumentTab
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          disabledFilters={DOCUMENT_FILTERS_SETTINGS.bestandseinheit.disabled}
          defaultFilters={DOCUMENT_FILTERS_SETTINGS.bestandseinheit.default}
          {...props}
        />
      ),
    },
    {
      title: 'Infofelder',
      icon: <InfoCircleOutlined />,
      uri: generatePathToBestandseinheitDetailsPageInfoFeldMenu(objektId, bestandseinheitId),
      content: () => <BeInfoFeldTab objektId={objektId} bestandseinheitId={bestandseinheitId} />,
    },
  ],
});

export default menuListBestandseinheit;
