import * as Types from '../../types';

import { gql } from '@apollo/client';
import { MitarbeiterFragmentDoc } from '../../features/Mitarbeiter/gql/MitarbeiterFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MitarbeiterTooltipQueryVariables = Types.Exact<{
  mitarbeiterId: Types.Scalars['ID']['input'];
}>;

export type MitarbeiterTooltipQuery = {
  getMitarbeiterDetails: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileStorageInitialized: boolean;
      firmendatenId: string;
      firstname: string;
      funktionsModulList: Array<string>;
      lastname: string;
      mitarbeiterId: string;
      title?: string | null;
      titleTrailing?: string | null;
      username: string;
      contactPerson?: {
        contactPersonId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        tagList: Array<string>;
        title?: string | null;
        titleTrailing?: string | null;
        contactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rolleList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        name: string;
        rolleId: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
      status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
      user?: {
        email?: string | null;
        emailUnverified?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        role: string;
        username: string;
        status: { text: string; value: string };
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MitarbeiterSelectListQueryVariables = Types.Exact<{
  includeUser?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  userEmail?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type MitarbeiterSelectListQuery = {
  getMitarbeiterList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileStorageInitialized: boolean;
      firmendatenId: string;
      firstname: string;
      funktionsModulList: Array<string>;
      lastname: string;
      mitarbeiterId: string;
      title?: string | null;
      titleTrailing?: string | null;
      username: string;
      contactPerson?: {
        contactPersonId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        tagList: Array<string>;
        title?: string | null;
        titleTrailing?: string | null;
        contactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rolleList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        name: string;
        rolleId: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
      status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
      user?: {
        email?: string | null;
        emailUnverified?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        role: string;
        username: string;
        status: { text: string; value: string };
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MitarbeiterQueryVariables = Types.Exact<{
  mitarbeiterId: Types.Scalars['ID']['input'];
  includeUser?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type MitarbeiterQuery = {
  getMitarbeiterDetails: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileStorageInitialized: boolean;
      firmendatenId: string;
      firstname: string;
      funktionsModulList: Array<string>;
      lastname: string;
      mitarbeiterId: string;
      title?: string | null;
      titleTrailing?: string | null;
      username: string;
      contactPerson?: {
        contactPersonId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        tagList: Array<string>;
        title?: string | null;
        titleTrailing?: string | null;
        contactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rolleList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        name: string;
        rolleId: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
      status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
      user?: {
        email?: string | null;
        emailUnverified?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        role: string;
        username: string;
        status: { text: string; value: string };
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const MitarbeiterTooltipDocument = gql`
  query MitarbeiterTooltip($mitarbeiterId: ID!) {
    getMitarbeiterDetails(mitarbeiterId: $mitarbeiterId) {
      data {
        ...Mitarbeiter
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MitarbeiterFragmentDoc}
`;
export function useMitarbeiterTooltipQuery(
  baseOptions: Apollo.QueryHookOptions<MitarbeiterTooltipQuery, MitarbeiterTooltipQueryVariables> &
    ({ variables: MitarbeiterTooltipQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MitarbeiterTooltipQuery, MitarbeiterTooltipQueryVariables>(MitarbeiterTooltipDocument, options);
}
export function useMitarbeiterTooltipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MitarbeiterTooltipQuery, MitarbeiterTooltipQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MitarbeiterTooltipQuery, MitarbeiterTooltipQueryVariables>(MitarbeiterTooltipDocument, options);
}
export function useMitarbeiterTooltipSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MitarbeiterTooltipQuery, MitarbeiterTooltipQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MitarbeiterTooltipQuery, MitarbeiterTooltipQueryVariables>(MitarbeiterTooltipDocument, options);
}
export type MitarbeiterTooltipQueryHookResult = ReturnType<typeof useMitarbeiterTooltipQuery>;
export type MitarbeiterTooltipLazyQueryHookResult = ReturnType<typeof useMitarbeiterTooltipLazyQuery>;
export type MitarbeiterTooltipSuspenseQueryHookResult = ReturnType<typeof useMitarbeiterTooltipSuspenseQuery>;
export type MitarbeiterTooltipQueryResult = Apollo.QueryResult<MitarbeiterTooltipQuery, MitarbeiterTooltipQueryVariables>;
export const MitarbeiterSelectListDocument = gql`
  query MitarbeiterSelectList($includeUser: Boolean, $userEmail: String) {
    getMitarbeiterList(includeUser: $includeUser, userEmail: $userEmail) {
      data {
        ...Mitarbeiter
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MitarbeiterFragmentDoc}
`;
export function useMitarbeiterSelectListQuery(
  baseOptions?: Apollo.QueryHookOptions<MitarbeiterSelectListQuery, MitarbeiterSelectListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MitarbeiterSelectListQuery, MitarbeiterSelectListQueryVariables>(MitarbeiterSelectListDocument, options);
}
export function useMitarbeiterSelectListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MitarbeiterSelectListQuery, MitarbeiterSelectListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MitarbeiterSelectListQuery, MitarbeiterSelectListQueryVariables>(MitarbeiterSelectListDocument, options);
}
export function useMitarbeiterSelectListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MitarbeiterSelectListQuery, MitarbeiterSelectListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MitarbeiterSelectListQuery, MitarbeiterSelectListQueryVariables>(MitarbeiterSelectListDocument, options);
}
export type MitarbeiterSelectListQueryHookResult = ReturnType<typeof useMitarbeiterSelectListQuery>;
export type MitarbeiterSelectListLazyQueryHookResult = ReturnType<typeof useMitarbeiterSelectListLazyQuery>;
export type MitarbeiterSelectListSuspenseQueryHookResult = ReturnType<typeof useMitarbeiterSelectListSuspenseQuery>;
export type MitarbeiterSelectListQueryResult = Apollo.QueryResult<MitarbeiterSelectListQuery, MitarbeiterSelectListQueryVariables>;
export const MitarbeiterDocument = gql`
  query Mitarbeiter($mitarbeiterId: ID!, $includeUser: Boolean) {
    getMitarbeiterDetails(mitarbeiterId: $mitarbeiterId, includeUser: $includeUser) {
      data {
        ...Mitarbeiter
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MitarbeiterFragmentDoc}
`;
export function useMitarbeiterQuery(
  baseOptions: Apollo.QueryHookOptions<MitarbeiterQuery, MitarbeiterQueryVariables> &
    ({ variables: MitarbeiterQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MitarbeiterQuery, MitarbeiterQueryVariables>(MitarbeiterDocument, options);
}
export function useMitarbeiterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MitarbeiterQuery, MitarbeiterQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MitarbeiterQuery, MitarbeiterQueryVariables>(MitarbeiterDocument, options);
}
export function useMitarbeiterSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MitarbeiterQuery, MitarbeiterQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MitarbeiterQuery, MitarbeiterQueryVariables>(MitarbeiterDocument, options);
}
export type MitarbeiterQueryHookResult = ReturnType<typeof useMitarbeiterQuery>;
export type MitarbeiterLazyQueryHookResult = ReturnType<typeof useMitarbeiterLazyQuery>;
export type MitarbeiterSuspenseQueryHookResult = ReturnType<typeof useMitarbeiterSuspenseQuery>;
export type MitarbeiterQueryResult = Apollo.QueryResult<MitarbeiterQuery, MitarbeiterQueryVariables>;
