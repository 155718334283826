import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Dayjs } from 'dayjs';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { useCreateUstVomAufwandGenerierlaufMutation } from '../../../Verarbeitung/gql/UstVomAufwandGenerierlauf/UstVomAufwandGenerierlaufMutations.types';
import {
  mapFormValuesToUstVomAufwand,
  ustVomAufwandCreateFormFields,
  ustVomAufwandCreateFormInitialValues,
  UstVomAufwandCreateFormValues,
} from './ustVomAufwandCreateFormMapper';
import { ustVomAufwandCreateFormValidationSchema } from './ustVomAufwandCreateFormValidationSchema';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import RangePicker from '../../../../components/DatePicker/RangePicker/RangePicker';

type Props = {
  objektId: string;
  verwaltungSeit: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const UstVomAufwandCreateForm: FC<Props> = ({ objektId, verwaltungSeit, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'UstVomAufwandGenerierlauf'>('UstVomAufwandGenerierlauf');

  const [runCreateUstVomAufwand, { loading }] = useCreateUstVomAufwandGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Ust. vom Aufwand wird für das Objekt berechnet.`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<UstVomAufwandCreateFormValues>
      initialValues={ustVomAufwandCreateFormInitialValues}
      validationSchema={ustVomAufwandCreateFormValidationSchema}
      onSubmit={(values) => {
        const request = mapFormValuesToUstVomAufwand(objektId, values);
        runCreateUstVomAufwand({ variables: { request } });
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={ustVomAufwandCreateFormFields.vonBis}
            label="Zeitraum von – bis"
            fieldHelp={getFieldHelpText('UstVomAufwandGenerierlauf.vonInklusive')}
          >
            <RangePicker
              name={ustVomAufwandCreateFormFields.vonBis}
              placeholder={['von', 'bis']}
              picker="month"
              style={{ width: '100%' }}
              disabledDate={(dates) => dayjsCustom(verwaltungSeit).isAfter(dayjsCustom(dates as unknown as Dayjs))}
            />
          </FormItemWithFieldHelp>
          <FormButtons
            okText="Berechnung erstellen"
            updateMode={false}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default UstVomAufwandCreateForm;
