import { mapContactListToFormValues } from '../../../../shared/components/Contact/contactMapper';
import { ContactType } from '../../../../shared/components/Contact/contactHelper';
import { User } from '../../../../types';

export interface AdminUpdateFormValues {
  firstname: string;
  lastname: string;
  emailList: Array<any>;
  phoneList: Array<any>;
}

export const mapAdminToUpdateFormValues = (admin: User): AdminUpdateFormValues => ({
  firstname: admin.firstname || '',
  lastname: admin.lastname || '',
  emailList: mapContactListToFormValues(admin.contactList ?? [], ContactType.EMAIL),
  phoneList: mapContactListToFormValues(admin.contactList ?? [], ContactType.PHONE),
});
