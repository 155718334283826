import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import { FieldArray, FormikProps } from 'formik';
import { bookingSuggestionFormFields, BookingSuggestionFormValues } from '../../../../Form/buchungsmaskeFormMapper';
import TableWithColSelector from '../../../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { useTableExpandedRowKeys } from '../../../../../../../components/Table/shared/useTableExpandedRowKeys';
import AuszifferungForPersonenkontoTable from '../Level2/AuszifferungForPersonenkontoTable';
import { offenePostenTableColumns } from '../../../shared/Level1/offenePostenTableColumns';

type Props = {
  formikProps: FormikProps<BookingSuggestionFormValues>;
  loading: boolean;
  pagination: PaginationProps;
};

const OffenePostenForPersonenkontoTable: FC<Props> = ({ formikProps, loading, pagination }) => {
  const { expandedRowKeys, toggleExpandedRowKey } = useTableExpandedRowKeys();

  const fieldNamePrefix = bookingSuggestionFormFields.offenePostenForPersonenkontoList;

  return (
    <FieldArray
      name={fieldNamePrefix}
      render={(arrayHelpers) => {
        const toggleExpRowKey = (rowKey: string) => {
          !expandedRowKeys.includes(rowKey) && toggleExpandedRowKey(rowKey);
        };

        return (
          <TableWithColSelector
            size="small"
            style={{ width: '100%' }}
            loading={loading}
            pagination={{
              ...pagination,
              syncWithUri: false,
            }} // syncWithUri is set to false in order to avoid page re-rendering and setting all the formik values to default values upon changing the pagination
            locale={{
              emptyText: <Empty description="Keine Offene Posten vorhanden" />,
            }}
            dataSource={formikProps.values.offenePostenForPersonenkontoList}
            columns={offenePostenTableColumns(fieldNamePrefix, toggleExpRowKey, true)}
            rowKey={(record) => record.offenePostenId}
            expandable={{
              expandedRowKeys,
              onExpand: (expanded, record) => toggleExpandedRowKey(record.offenePostenId),
              expandedRowRender: (record, index) => (
                <AuszifferungForPersonenkontoTable
                  fieldNamePrefix={`${fieldNamePrefix}[${index}]`}
                  offenePosten={record}
                  offenePostenIndex={index}
                  arrayHelpers={arrayHelpers}
                  formikProps={formikProps}
                />
              ),
            }}
            filterIdentifier="offene-posten-for-personenkonto"
          />
        );
      }}
    />
  );
};

export default OffenePostenForPersonenkontoTable;
