import React, { FC } from 'react';
import { Alert, Button, Space } from 'antd';
import { FormikProps } from 'formik';
import { isStatusErstellt } from '../../../../helpers/statusHelper';
import { MahnungUpdateFormValues } from './mahnungUpdateFormMapper';
import { Mahnung } from '../../../../types';

type MahnungUpdateFormButtonsProps = {
  mahnung: Mahnung;
  formikProps: FormikProps<MahnungUpdateFormValues>;
  loadingUpdate: boolean;
};

const MahnungUpdateFormButtons: FC<MahnungUpdateFormButtonsProps> = ({ mahnung, formikProps, loadingUpdate }) => {
  return (
    <Space direction="vertical" style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
      <Space style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <Button onClick={() => formikProps.resetForm()}>Abbrechen</Button>
        <Button htmlType="submit" type="primary" disabled={!isStatusErstellt(mahnung.status) || loadingUpdate} onClick={formikProps.submitForm}>
          Speichern
        </Button>
      </Space>
      <Space style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        {formikProps.dirty ? <Alert message="Daten wurden noch nicht gespeichert" type="warning" showIcon /> : null}
      </Space>
    </Space>
  );
};

export default MahnungUpdateFormButtons;
