import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { UstRegelsetFieldsFragmentDoc } from './UstRegelsetFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstRegelsetListQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
}>;

export type UstRegelsetListQuery = {
  getUstRegelsetListByKundenSystemId: {
    data: Array<{
      bezeichnung: string;
      countryCodeIso2: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      eigennutzung: boolean;
      firmendatenId?: string | null;
      leerstehung: boolean;
      optionSteuerpflicht: boolean;
      ustRegelsetId: string;
      land?: { alpha2: string; alpha3: string; bezeichnung: string } | null;
      nutzungsArt: { text: string; value: Types.NutzungsArt };
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      ustKategorie?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      ustKategorieZuweisungList: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        ustRegelsetKategorieZuweisungId: string;
        validFrom: string;
        ustKategorie: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          ustKategorieId: string;
          ustVomAufwand?: number | null;
          vstKuerzung?: boolean | null;
          status?: { text: string; value: Types.UstKategorieStatus } | null;
          ustKategorieEntryList: Array<{
            additionKonto: number;
            buchungsKZ?: string | null;
            createTs?: string | null;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            rechnungskennzeichen: string;
            steuersatz: number;
            ustKategorieEntryId?: string | null;
            ustKz: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kuerzel: string;
              umsatzsteuerkennzeichenId: string;
              status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UstRegelsetListDocument = gql`
  query UstRegelsetList($kundenSystemId: ID!) {
    getUstRegelsetListByKundenSystemId(kundenSystemId: $kundenSystemId) {
      data {
        ...UstRegelsetFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstRegelsetFieldsFragmentDoc}
`;
export function useUstRegelsetListQuery(
  baseOptions: Apollo.QueryHookOptions<UstRegelsetListQuery, UstRegelsetListQueryVariables> &
    ({ variables: UstRegelsetListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstRegelsetListQuery, UstRegelsetListQueryVariables>(UstRegelsetListDocument, options);
}
export function useUstRegelsetListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UstRegelsetListQuery, UstRegelsetListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstRegelsetListQuery, UstRegelsetListQueryVariables>(UstRegelsetListDocument, options);
}
export function useUstRegelsetListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstRegelsetListQuery, UstRegelsetListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstRegelsetListQuery, UstRegelsetListQueryVariables>(UstRegelsetListDocument, options);
}
export type UstRegelsetListQueryHookResult = ReturnType<typeof useUstRegelsetListQuery>;
export type UstRegelsetListLazyQueryHookResult = ReturnType<typeof useUstRegelsetListLazyQuery>;
export type UstRegelsetListSuspenseQueryHookResult = ReturnType<typeof useUstRegelsetListSuspenseQuery>;
export type UstRegelsetListQueryResult = Apollo.QueryResult<UstRegelsetListQuery, UstRegelsetListQueryVariables>;
