import React from 'react';

import { Tag } from 'antd';
import { Box } from 'rebass';
import { isToday } from '../../helpers/dateHelper';
import { ITimeblock, IVersionable } from './timelineCtx';
import CustomFormattedDate from '../FormattedDate/CustomFormattedDate';

type TimelineDateTitleProps<T extends IVersionable<T>> = {
  timeblock: ITimeblock<IVersionable<T>>;
};

function TimelineDateTitle<T extends IVersionable<T>>({ timeblock }: TimelineDateTitleProps<T>) {
  return (
    <Box mb="8px" fontWeight={timeblock.current ? 500 : 'default'}>
      Änderung gültig ab {isToday(timeblock.validFrom) ? 'heute' : <CustomFormattedDate value={timeblock.validFrom} />}{' '}
      {timeblock.current && <Tag>Aktiv</Tag>}
    </Box>
  );
}

export default TimelineDateTitle;
