import React from 'react';
import { Space, Tag, Typography } from 'antd';
import { TableWithAlignedColsColumnType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { TopAbrKreisAufwand } from '../../../../../../types';
import { EuroAmount } from '../../../../../../components/Number';

const aufwandAufteilungTableColumns = (): TableWithAlignedColsColumnType<TopAbrKreisAufwand>[] => [
  {
    title: '',
    render: (aufwand) => (
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography.Text>Aufwandsaufteilung nach Auft.Schlüssel</Typography.Text>
        <Tag>{aufwand.aufteilungsschluesselBezeichnung}</Tag>
      </Space>
    ),
    align: 'left',
    colSpan: 3,
  },
  {
    title: '',
    render: (aufwand) => <EuroAmount value={aufwand.summe} />,
    align: 'right',
  },
];

export default aufwandAufteilungTableColumns;
