import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { BelegTextePlatzhalter } from '../../../../Belegtext/Form/belegTexteFormTypes';
import { HauseigentuemerAbrechnung } from '../../../../../pages/PDFTemplates/templates/hauseigentuemerAbrechnung/hauseigentuemerAbrechnung-types';
import { findTextbausteinByType, replacePlatzhalterValuesWithExampleValues } from '../../../../../components/Template/PDFTemplates/templateHelpers';
import { BelegTexte, HeAbrechnungOutputOptions, TextbausteinType } from '../../../../../types';
import { HauseigentuemerAbrechnungSelectableSection } from '../../../../../pages/PDFTemplates/templates/hauseigentuemerAbrechnung/HauseigentuemerAbrechnungTemplate';
import {
  getDocumentFooterWithoutTextbaustein,
  getDocumentHeaderWithoutTextbaustein,
} from '../../../../shared/Belegtext/Template/belegTexteWithoutTextbausteinTemplateHelper';
import { HeOrSubAbrDefGeneralSettingsFormValues } from '../shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';

export const createHeAbrechnungPDFTemplateData = (
  textbausteinList: TextbausteinFormValues[],
  abrDefGeneralSettingsValues: HeOrSubAbrDefGeneralSettingsFormValues | HeAbrechnungOutputOptions,
  belegTexte?: BelegTexte | undefined,
  belegTextePlatzhalter?: BelegTextePlatzhalter | undefined,
  firmendatenId?: string | undefined
): HauseigentuemerAbrechnung => {
  const einleitungstext = findTextbausteinByType(textbausteinList, TextbausteinType.Einleitungstext);
  const ergebnistext = findTextbausteinByType(textbausteinList, TextbausteinType.Ergebnistext);
  const schlusstext = findTextbausteinByType(textbausteinList, TextbausteinType.Schlusstext);

  return {
    ...mockHauseigentuemerAbrechnung(abrDefGeneralSettingsValues),
    einleitungstext: replacePlatzhalterValuesWithExampleValues(einleitungstext),
    ergebnistext: replacePlatzhalterValuesWithExampleValues(ergebnistext),
    schlusstext: replacePlatzhalterValuesWithExampleValues(schlusstext),
    header:
      firmendatenId && belegTexte && belegTextePlatzhalter
        ? getDocumentHeaderWithoutTextbaustein(firmendatenId, belegTexte, belegTextePlatzhalter)
        : null,
    footer:
      firmendatenId && belegTexte && belegTextePlatzhalter
        ? getDocumentFooterWithoutTextbaustein(firmendatenId, belegTexte, belegTextePlatzhalter)
        : null,
  };
};

export const mapTextbausteinToHeAbrechnungSelectableSection = (type?: TextbausteinType): HauseigentuemerAbrechnungSelectableSection | undefined => {
  if (!type) {
    return undefined;
  }

  switch (type) {
    case TextbausteinType.Einleitungstext:
      return 'EINLEITUNGSTEXT';
    case TextbausteinType.Ergebnistext:
      return 'ERGEBNISTEXT';
    case TextbausteinType.Schlusstext:
      return 'SCHLUSSTEXT';
    default:
      return undefined;
  }
};

export const mockHauseigentuemerAbrechnung = (
  abrDefGeneralSettingsValues: HeOrSubAbrDefGeneralSettingsFormValues | HeAbrechnungOutputOptions
): HauseigentuemerAbrechnung => ({
  type: 'HAUSEIGENTUEMER_ABRECHNUNG',
  einleitungstext: '{Platzhalter Einleitungstext je nach Use-Case}',
  ergebnistext: '{Platzhalter Ergebnistext je nach Use-Case}',
  schlusstext: '{Platzhalter für Schlusstext je nach Use-Case}',
  absender: { label: 'Abs:', value: 'andromeda software GmnH, Grillgasse 46; 1110 Wien' },
  hauseigentuemer: {
    rechnungsEmpfaenger: {
      rechnungsEmpfaengerShippingLabel: {
        line1: 'Herr',
        line2: 'Valenta Andreas',
        line3: 'Alxingergasse  62-66/ V/21',
        line4: '1060 Wien',
      },
      kundennummer: {
        text: 'Kundennummer:',
        nummer: 'D-23245',
      },
    },
    anteile: { label: 'Anteile:', value: '70/100' },
  },
  abrechnungsDatumLine: 'Wien, am 28.06.2017',
  detailsBlock: {
    positionListHeader: {
      positionText: 'Position',
      objektsummeText: 'Objektsumme',
      anteilText: 'Ihr Anteil',
    },
    saldovortrag: {
      label: 'Saldovortrag',
      value: '82.415,99',
    },
    einnahmen: {
      positionName: 'Einnahmen',
      abrKreisList: [
        {
          abrKreis: { label: 'Einnahmen aus Vermietung', value: '61.974,52' },
          kontoList: [
            {
              konto: {
                label: abrDefGeneralSettingsValues.displayKontonummer ? 'Hauptmietzins 10 % Mwst / S1000' : 'Hauptmietzins 10 % Mwst',
                value: '44.963,16',
              },
            },
            {
              konto: {
                label: abrDefGeneralSettingsValues.displayKontonummer ? 'Hauptmietzins 20 % Mwst / S1000' : 'Hauptmietzins 20 % Mwst',
                value: '17.622,68',
              },
            },
            {
              konto: {
                label: abrDefGeneralSettingsValues.displayKontonummer ? 'Erlösberechtigung 10% / S1000' : 'Erlösberechtigung 10%',
                value: '-611,32',
              },
              buchungszeileList: [
                {
                  buchungsdatum: '31.12.2021',
                  text: 'uneinbringlich Top 11 Fechter',
                  value: '-611,32',
                },
              ],
            },
          ],
        },
        {
          abrKreis: { label: 'Steuer', value: '11.075,42' },
          kontoList: [
            {
              konto: {
                label: abrDefGeneralSettingsValues.displayKontonummer ? 'Umsatzsteuer 10% / S1000' : 'Umsatzsteuer 10%',
                value: '6.779,52',
              },
            },
            {
              konto: {
                label: abrDefGeneralSettingsValues.displayKontonummer ? 'Umsatzsteuer 20% / S1000' : 'Umsatzsteuer 20%',
                value: '4.295,90',
              },
            },
          ],
        },
      ],
      anteilVonSumme: {
        label: 'Ihr Anteil 70/100 (70,00%) von Summe',
        objektSumme: '73.049,94',
        anteil: '51.134,96',
      },
      summePosition: {
        label: 'Summe Einnahmen',
        value: '51.134,96',
      },
    },
    ausgaben: {
      positionName: 'Ausgaben',
      abrKreisList: [
        {
          abrKreis: { label: 'Instandhaltungen', value: '-13.383,59' },
          kontoList: [
            {
              konto: {
                label: abrDefGeneralSettingsValues.displayKontonummer ? 'Baumeister / S1000' : 'Baumeister',
                value: '-11.591,67',
              },
              buchungszeileList: [
                {
                  buchungsdatum: '15.02.2021',
                  text: 'Wieser Rg. 45/2021 Fassadensan. 1.TR.',
                  value: '-4.391,67',
                },
                {
                  buchungsdatum: '15.02.2021',
                  text: 'Wieser Schussrg. 90/2021 Fassadensan.',
                  value: '-7.200,00',
                },
              ],
            },
            {
              konto: {
                label: abrDefGeneralSettingsValues.displayKontonummer ? 'Installateur / S1000' : 'Installateur',
                value: '-1.500,00',
              },
              buchungszeileList: [
                {
                  buchungsdatum: '02.08.2021',
                  text: 'Straka Rg. 123/2021 neue Therme T.1',
                  value: '-1.500,00',
                },
              ],
            },
            {
              konto: {
                label: abrDefGeneralSettingsValues.displayKontonummer ? 'Elektriker / S1000' : 'Elektriker',
                value: '-291,92',
              },
              buchungszeileList: [
                {
                  buchungsdatum: '30.12.2021',
                  text: 'GWS Rg. 154/2021 Prüfung nach Wechsel T.3',
                  value: '-291,92',
                },
              ],
            },
          ],
        },
        {
          abrKreis: { label: 'sonstiger Aufwand', value: '-11.356,18' },
          kontoList: [
            {
              konto: {
                label: abrDefGeneralSettingsValues.displayKontonummer ? 'Vorsteuer / S1000' : 'Vorsteuer',
                value: '-5.890,14',
              },
            },
            {
              konto: {
                label: abrDefGeneralSettingsValues.displayKontonummer ? 'Leerstehungen 0% Mwst. / S1000' : 'Leerstehungen 0% Mwst.',
                value: '-3,400,89',
              },
            },
            {
              konto: {
                label: abrDefGeneralSettingsValues.displayKontonummer ? 'Sonst. Kosten HE / S1000' : 'Sonst. Kosten HE',
                value: '-2,065,15',
              },
            },
          ],
        },
      ],
      anteilVonSumme: {
        label: 'Ihr Anteil 70/100 (70,00%) von Summe',
        objektSumme: '-24.739,77',
        anteil: '-17.317,84',
      },
      summePosition: {
        label: 'Summe Ausgaben',
        value: '-17.317,84',
      },
    },
    zwischensumme: {
      label: 'Zwischensumme',
      value: '33.817,12',
    },
    eigentuemerverrechnung: {
      positionName: 'Eigentümerverrechnung',
      kontoList: [
        {
          kontoName: 'Eigentümerverrechnung',
          buchungszeileList: [
            {
              buchungsdatum: '28.01.2021',
              text: 'Auszahlung lt. Mail 20.01.2021',
              value: '-50.000,00',
            },
          ],
        },
      ],
      anteilVonSumme: {
        label: 'Ihr Anteil 70/100 (70,00%) von Summe',
        value: '-50.000,00',
      },
      summePosition: {
        label: 'Summe Eigentümerverrechnung',
        value: '-50.000,00',
      },
    },
  },
  abrechnungErgebnis: {
    label: 'Ergebnis der Abrechnung',
    value: '66.233,11',
  },
  zahlungsrueckstaendeAnteilHauseigentuemer: abrDefGeneralSettingsValues.rueckstaendeInAbrechnungsergebnis
    ? {
        label: 'Zahlungsrückstände Mieter',
        value: '-2.455,68',
      }
    : null,
  endErgebnis: {
    label: 'Guthaben',
    value: '63.777,43',
  },
  objektZahlungsrueckstaendeBlock: abrDefGeneralSettingsValues.rueckstaendeAnzeigen
    ? {
        zahlungsrueckstaendeListHeader: {
          bezeichnungText: 'Bezeichnung',
          topNummerText: 'Top',
          kontoNummerText: 'Kontonummer',
          letzteBuchungDateText: 'letzte Buchung am',
          dueDateText: 'Fällig am',
          saldoText: 'Saldobetrag',
        },
        zahlungsrueckstaendeList: [
          {
            bezeichnung: 'Klaus Fleischhacker',
            topNummer: '3',
            kontoNummer: 'D00003',
            letzteBuchungDate: '01.12.2021',
            dueDate: '25.12.2021',
            saldo: '-3.508,11',
          },
        ],
        zahlungsrueckstaendeSummary: {
          label: 'Endsumme',
          value: '-3.508,11',
        },
      }
    : null,
  informationAbrechnungskreiseBlock: {
    abrechnungskreisTableBezeichnungText: null,
    abrechnungskreisTableBelegDatumText: null,
    abrechnungskreisTableBelegNummerText: null,
    abrechnungskreisTableVertragspartnerText: null,
    abrechnungskreisList: [
      {
        type: 'INFORMATION',
        bezeichnung: 'Information',
        aufteilungsschluessel: null,
        summeNettoAusgabenText: 'Summe Ausgaben',
        summeNettoAusgaben: '64,99',
        summeNettoEinnahmenText: 'Summe Einnahmen',
        summeNettoEinnahmen: '95,62',
        ergebnisNettoText: 'Ergebnis: Saldo',
        ergebnisNetto: '-30,63',
        buchungEinnahmenListText: 'Einnahmen',
        saldoVortragText: 'Vorsaldo:',
        saldoVortrag: '0,00',
        ausgabenDetailsTitleText: null,
        kontoList: [
          {
            bezeichnung: 'Waschmaschine',
            kontonummer: 'S1000',
            aufteilungsschluessel: null,
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '64,99',
            buchungAusgabenList: [
              {
                bezeichnung: 'Vorschreibung 2023/12',
                buchungsDatum: '13.12.2023',
                belegnummer: '1',
                kurzbezeichnungVertragsparthner: 'Kraker L. u.Heiner G.',
                betragNetto: '64,99',
              },
            ],
          },
        ],
        buchungEinnahmenList: [
          {
            bezeichnung: '2023/12',
            betragNetto: '95,62',
          },
          {
            bezeichnung: '2023/11',
            betragNetto: '95,62',
          },
          {
            bezeichnung: '2023/10',
            betragNetto: '95,62',
          },
          {
            bezeichnung: '2023/9',
            betragNetto: '95,62',
          },
          {
            bezeichnung: '2023/8',
            betragNetto: '95,62',
          },
        ],
        buchungUstVomAufwandList: [],
        summeNettoExpenseReducingText: 'Zwischensumme Aufwandsmindernd',
        summeNettoExpenseReducing: '60,80',
      },
    ],
  },
});
