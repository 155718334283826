import React, { FC } from 'react';
import { Divider } from 'antd';
import { findEmailDeliveryLabel } from '../../emailDeliveryHelpers';
import EmailDeliveryDetailsCard from '../../Card/EmailDeliveryDetailsCard';
import EmailDeliveryActions from '../../Card/EmailDeliveryActions';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import RechtstraegerLink from './RechtstraegerLink';
import ObjektTopLink from './ObjektTopLink';
import { useResendInfoMailDeliveryMutation } from '../gql/InfoMailDeliveryMutations.types';
import ResentDeliveryInfoRow from './ResentDeliveryInfoRow';
import SourceDeliveryInfoRow from './SourceDeliveryInfoRow';
import { MailData } from '../../../../types';

type Props = {
  emailDelivery: MailData;
  refetch: () => void;
};

const InfoMailDeliveryDetailsCard: FC<Props> = ({ emailDelivery, refetch }) => {
  const [runResend] = useResendInfoMailDeliveryMutation();

  const { value: infoMailDeliveryId } = findEmailDeliveryLabel(emailDelivery, 'infoMailDeliveryId') || {};
  if (!infoMailDeliveryId) return null;

  const onSend = (emailAddress: string) => {
    return runResend({ variables: { infoMailDeliveryId, recipient: emailAddress } }).then(() => refetch());
  };

  return (
    <EmailDeliveryDetailsCard emailDelivery={emailDelivery} extra={<EmailDeliveryActions emailDelivery={emailDelivery} onSend={onSend} />}>
      <CardDetailsInfoRow infoRowTitle="an">
        <RechtstraegerLink infoMailDeliveryId={infoMailDeliveryId} />
      </CardDetailsInfoRow>
      <Divider />
      <CardDetailsInfoRow infoRowTitle="Objekt-Top">
        <ObjektTopLink infoMailDeliveryId={infoMailDeliveryId} />
      </CardDetailsInfoRow>
      <ResentDeliveryInfoRow infoMailDeliveryId={infoMailDeliveryId} />
      <SourceDeliveryInfoRow infoMailDeliveryId={infoMailDeliveryId} />
    </EmailDeliveryDetailsCard>
  );
};

export default InfoMailDeliveryDetailsCard;
