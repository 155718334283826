import React, { FC, useEffect, useRef } from 'react';
import { FormattedNumber } from 'react-intl';
import { Spin } from 'antd';
import { FormikProps, useField } from 'formik';
import { useBestandseinheitIds } from '../../../bestandseinheitIdsContext';
import { useBestandseinheitNutzflaecheByDateLazyQuery, useBestandseinheitNutzwertByDateLazyQuery } from '../../../gql/BestandseinheitQueries.types';
import { mapFormDateValueToDateString, updateFormikField, updateFormikFieldError } from '../../../../../helpers/formikHelper';
import { parseFormattedDecimal, toFormattedDecimal } from '../../../../../components/Input-antd';
import {
  beAufteilungsschluesselWertMitBezugFormFields,
  BeAufteilungsschluesselWertMitBezugFormValues,
} from './BeAufteilungsschluesselWertMitBezugMapper';
import { Bezugsbasis } from '../../../../../types';

type BeAufteilungsschluesselWertInputProps = {
  formikProps: FormikProps<BeAufteilungsschluesselWertMitBezugFormValues>;
};

const BeAufteilungsschluesselWertMitBezugWertInput: FC<BeAufteilungsschluesselWertInputProps> = ({ formikProps }) => {
  const { wert: fieldNameWert } = beAufteilungsschluesselWertMitBezugFormFields;
  const { objektId, bestandseinheitId } = useBestandseinheitIds();

  const [getNutzflaecheByDate, { loading: isNutzflaecheByDateLoading }] = useBestandseinheitNutzflaecheByDateLazyQuery({
    onCompleted: (data) => {
      updateFormikFieldError(formikProps, fieldNameWert, undefined);
      updateFormikField(formikProps, fieldNameWert, toFormattedDecimal(data.getBestandseinheitNutzflaecheByDate.data));
    },
    onError: (error) => {
      console.error('getNutzflaecheByDate | getNutzwertByDate ', error);
      updateFormikFieldError(formikProps, fieldNameWert, 'Aktueller Wert konnte nicht geladen werden. Probieren Sie erneut.', false);
    },
  });

  const [getNutzwertByDate, { loading: isNutzwertByDateLoading }] = useBestandseinheitNutzwertByDateLazyQuery({
    onCompleted: (data) => {
      updateFormikFieldError(formikProps, fieldNameWert, undefined);
      updateFormikField(formikProps, fieldNameWert, toFormattedDecimal(data.getBestandseinheitNutzwertByDate.data));
    },
    onError: (error) => {
      console.error('getNutzflaecheByDate | getNutzwertByDate ', error);
      updateFormikFieldError(formikProps, fieldNameWert, 'Aktueller Wert konnte nicht geladen werden. Probieren Sie erneut.', false);
    },
  });

  const getNutzflaecheOrNutzwertByDate = formikProps.values.type === Bezugsbasis.Nutzflaeche ? getNutzflaecheByDate : getNutzwertByDate;

  const previousValidFrom = useRef<string>();

  useEffect(() => {
    if (previousValidFrom.current === formikProps.values?.validFrom) {
      return;
    }
    if (formikProps.values.isUsed) {
      if (formikProps.values.validFrom) {
        getNutzflaecheOrNutzwertByDate({
          variables: { objektId, bestandseinheitId, date: mapFormDateValueToDateString(formikProps.values.validFrom) },
        });
      } else {
        updateFormikField(formikProps, fieldNameWert, undefined);
      }
    }
  });

  const [{ value: wertValue }] = useField(fieldNameWert);
  const formattedDecimal = parseFormattedDecimal(wertValue);

  useEffect(() => {
    previousValidFrom.current = formikProps.values.validFrom;
  });

  if (isNutzwertByDateLoading || isNutzflaecheByDateLoading) {
    return <Spin />;
  }

  return <FormattedNumber value={formattedDecimal} minimumFractionDigits={2} maximumFractionDigits={2} />;
};

export default BeAufteilungsschluesselWertMitBezugWertInput;
