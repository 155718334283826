import React, { FC } from 'react';
import { Divider, Skeleton, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { useZinslisteListQuery } from '../../../Zinsliste/gql/ZinslisteQueries.types';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { generatePathToZinslisteDetailsPageOrDiscrepancies } from '../../../Zinsliste/zinslisteUriPaths';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import { useOnZinslisteDataChangedEvents } from '../../../Zinsliste/useOnZinslisteDataChangedEvents';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { Auftrag, AuftragsPosition, Label, ZinslisteListEntry } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type VorschreibungZinslisteProps = {
  auftrag: Auftrag;
};

const VorschreibungZinsliste: FC<VorschreibungZinslisteProps> = ({ auftrag }) => {
  const { auftragsPositionList } = auftrag;

  const zinslisteIds = getZinslisteIds(auftragsPositionList);
  const isZinlisteIdsEmpty = zinslisteIds.length === 0;

  const { loading, refetch, data } = useZinslisteListQuery({
    variables: { zinslisteIdList: zinslisteIds },
    skip: isZinlisteIdsEmpty,
  });
  const contentList = data?.list.data.contentList ?? [];

  // If contentList is not copied, it can happen that sometimes this error is thrown:
  // TypeError: Cannot assign to read only property '0' of object '[object Array]'
  const zinslisteList = [...contentList];

  const sortyByKurzbezeichnung = (a: ZinslisteListEntry, b: ZinslisteListEntry) => a.objektKurzbezeichnung.localeCompare(b.objektKurzbezeichnung);

  useOnZinslisteDataChangedEvents('vorschreibungZinsliste', refetch, zinslisteIds);

  if (isZinlisteIdsEmpty) return null;

  return (
    <Skeleton loading={loading}>
      <Typography.Title level={5}>Zinslisten</Typography.Title>
      {zinslisteList.sort(sortyByKurzbezeichnung).map((zinsliste, index) => {
        const { objektId, zinslisteId, status, objektKurzbezeichnung, vorschreibungsperiode } = zinsliste;
        return (
          <CardDetailsInfoRow
            key={zinsliste.zinslisteId}
            infoRowTitle={
              <Link key={index} to={generatePathToZinslisteDetailsPageOrDiscrepancies(objektId, zinslisteId, status)}>
                <DataWithShortenedTextAndExtraInfo maxTextLength={15} text={objektKurzbezeichnung}>
                  {(shortenedText) => (
                    <>
                      <span>{shortenedText}</span>
                      <span style={{ marginLeft: '10px' }}>
                        <CustomFormattedDate
                          value={dayjsCustom()
                            .set('month', vorschreibungsperiode.monat - 1) // dayjs index starts at 0
                            .set('year', vorschreibungsperiode.jahr)
                            .format()}
                          year="2-digit"
                          month="2-digit"
                        />
                      </span>
                    </>
                  )}
                </DataWithShortenedTextAndExtraInfo>
              </Link>
            }
          >
            <StatusBadge entityStatus={status} />
          </CardDetailsInfoRow>
        );
      })}
      <Divider dashed />
    </Skeleton>
  );
};

const getZinslisteIds = (auftragsPositionList: AuftragsPosition[]) => {
  return auftragsPositionList
    .map(({ labelList }: AuftragsPosition) => labelList.filter(filterZinsListeId))
    .flat()
    .reduce(removeDuplicates, [])
    .map((auftragsPosition) => auftragsPosition.value);
};

const filterZinsListeId = (value: Label) => value.label === 'zinslisteId';
const removeDuplicates = (acc: Label[], current: Label) => {
  const isNotUniqueZinsliste = acc.find((item) => item.value === current.value);
  if (isNotUniqueZinsliste) {
    return acc;
  }
  return acc.concat([current]);
};

export default VorschreibungZinsliste;
