import React, { FC } from 'react';
import { MenuProps, Modal } from 'antd';
import { EuroCircleOutlined, PlusSquareOutlined, ReadOutlined, SolutionOutlined, WalletOutlined } from '@ant-design/icons';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';
import { AuftragListEntry } from '../../../../types';
import { generatePathToVorschreibungDetailsPageOrDiscrepancies } from '../../vorschreibungUriPaths';
import { generatePathToOffenePostenPage } from '../../../OffenePosten/offenePostenUriPaths';
import { isPaymentMethodLastschrift } from '../../../Payment/paymentHelpers';
import { generatePathToPaymentPage } from '../../../Payment/Listing/Filters/filtersQueryParams';
import PaymentCreateForm from '../../../Payment/Form/Create/PaymentCreateForm';
import { useToggle } from '../../../../hooks/useToggle';
import { generatePathToBookingDetailsPage } from '../../../BookingDetails/bookingDetailsPaths';

type Props = {
  auftrag: AuftragListEntry;
};

const VorschreibungTableActions: FC<Props> = ({ auftrag }) => {
  const [isZahlungCreateOpen, toggleZahlungCreateOpen] = useToggle();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Details ansehen',
      onClick: () => window.open(generatePathToVorschreibungDetailsPageOrDiscrepancies(auftrag.auftragId, auftrag.status)),
      icon: <SolutionOutlined />,
    },
    {
      key: '2',
      label: 'Buchungsdetails ansehen',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onClick: () => window.open(generatePathToBookingDetailsPage(auftrag.buchungIdList!, auftrag.auftragId)),
      icon: <ReadOutlined />,
      disabled: !(auftrag.buchungIdList && auftrag.buchungIdList.length !== 0),
    },
    auftrag.buchungIdList && auftrag.buchungIdList.length > 0
      ? {
          key: '3',
          label: 'Offene Posten anzeigen',
          onClick: () =>
            window.open(
              generatePathToOffenePostenPage({
                dueDateFrom: auftrag.dueDate,
                dueDateTo: auftrag.dueDate,
                belegnummer: auftrag.belegnummer,
                buchungskreisId: auftrag.buchungskreisId,
                vertragspartnerId: auftrag.empfaengerRechtstraegerId,
              })
            ),
          icon: <WalletOutlined />,
        }
      : null,
    ...(isPaymentMethodLastschrift(auftrag.paymentMethod.value)
      ? [
          {
            key: '4',
            label: 'Lastschriften anzeigen',
            onClick: () =>
              window.open(
                generatePathToPaymentPage({
                  dueDateFrom: auftrag.dueDate,
                  dueDateTo: auftrag.dueDate,
                  belegnummer: auftrag.belegnummer,
                  rechnungsAusstellerId: auftrag.ausstellerRechtstraegerId,
                  payeeId: auftrag.empfaengerRechtstraegerId,
                })
              ),
            icon: <EuroCircleOutlined />,
            disabled: !auftrag.paymentCreatable,
          },
          {
            key: '5',
            label: 'Lastschrift erstellen',
            onClick: toggleZahlungCreateOpen,
            icon: <PlusSquareOutlined />,
            disabled: !auftrag.paymentCreatable,
          },
        ]
      : []),
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Modal
        title="Zahlung erstellen"
        onCancel={toggleZahlungCreateOpen}
        open={isZahlungCreateOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <PaymentCreateForm
          onCancel={toggleZahlungCreateOpen}
          onSuccess={toggleZahlungCreateOpen}
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          belegId={auftrag.belegId!}
          paymentMethod={auftrag.paymentMethod.value}
          auftragId={auftrag.auftragId}
          outstandingAmount={auftrag.offenerBetrag}
        />
      </Modal>
    </>
  );
};
export default VorschreibungTableActions;
