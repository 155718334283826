import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import AndromedaSysSettingsAbrechnungskreisPage from './AndromedaSysSettingsAbrechnungskreisPage';
import { isAdmin } from '../../../../security/permissionChecks';

const andromedaSysSettingsAbrechnungskreisPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.abrechnungskreisPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsAbrechnungskreisPage />} />}
  />
);

export default andromedaSysSettingsAbrechnungskreisPageRoute;
