import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../helpers/selectHelper';
import { useOffenePostenBelegnummerListQuery } from '../../../features/OffenePosten/gql/OffenePostenQueries.types';
import useDebounce from '../../../hooks/useDebounce';
import SelectNotFoundContent from '../../../features/shared/SelectNotFoundContent';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../helpers/stringHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';

type Props = {
  belegnummer?: string | null;
} & SelectProps;

const BelegnummerFormSelect: FC<Props> = ({ belegnummer, ...restProps }) => {
  const [belegnummerText, setBelegnummerText] = useState<string>(belegnummer ? belegnummer.toString() : '');
  const debouncedText = useDebounce(belegnummerText);

  const { loading, data } = useOffenePostenBelegnummerListQuery({
    variables: {
      belegnummer: debouncedText as string,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const belegnummerList = data?.getOffenePostenBelegnummerList.data ?? [];

  return (
    <>
      <Select
        id={restProps.name}
        loading={loading}
        size="small"
        allowClear
        showSearch
        placeholder="Belegnummer auswählen"
        filterOption={selectFilterOption}
        onSelect={setBelegnummerText}
        onSearch={(value) => {
          belegnummerText.length === 1 && !value && setBelegnummerText(''); // Checking the length of the belegnummerText makes the search component editable
          value && setBelegnummerText(value);
        }}
        onClear={() => setBelegnummerText('')}
        searchValue={belegnummerText}
        notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
        {...restProps}
      >
        {belegnummerList?.map((belegnummer) => (
          <Select.Option key={belegnummer} value={belegnummer}>
            <DataWithShortenedText text={belegnummer} maxTextLength={40} />
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default BelegnummerFormSelect;
