import React from 'react';
import { Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { VertragVPosWertWarning } from './BudgetingResultWarnings';
import { BudgetingResultVertragVPosWertWarning } from '../../../../../types';

const vertragVPosWerWarningTableColumns: ColumnProps<VertragVPosWertWarning>[] = [
  {
    title: 'Top',
    render: (_, record) => <Typography.Text>{record.bestandseinheitBezeichnung}</Typography.Text>,
    width: 100,
  },
  {
    title: 'Vertag',
    render: (_, record) => (
      <Typography.Text>
        {record.vertragsArt.text} - {record.vertragspartnerKurzBezeichnung}
      </Typography.Text>
    ),
    width: 350,
  },
  {
    title: 'Warnung',
    render: (_, record) => <Typography.Text>{record.warning && getWarningMessage(record.warning, record.vPosBezeichnung)}</Typography.Text>,
  },
];

const getWarningMessage = (message: BudgetingResultVertragVPosWertWarning, vPosBezeichnung: string): React.ReactNode => {
  switch (message) {
    case BudgetingResultVertragVPosWertWarning.VposValueUnchanged:
      return (
        <>
          Für die Vorschreibungsposition <strong>{vPosBezeichnung}</strong> wurden keine Vertragswerte erzeugt, da es keine Erhöhung gab.
        </>
      );
    case BudgetingResultVertragVPosWertWarning.VposValueWithDateExists:
      return <>Im Vertrag konnte die Budgetierung nicht angelegt werden, da mit dem Datum schon ein manueller Wert existiert</>;
    default:
      return <>Vertragswert konnte nicht erzeugt werden</>;
  }
};

export default vertragVPosWerWarningTableColumns;
