import React, { FC, RefObject } from 'react';
import { TBookingJournalQueryParams } from './Filters/filtersQueryParams';
import { useFibuBuchungszeileListSummaryQuery } from '../gql/FibuBuchungszeileQueries.types';
import { StyledListSummarySpace } from '../../shared/ListSummary/styled/StyledListSummarySpace';
import { SummaryText } from '../../shared/ListSummary/SummaryText';
import { LoadingCol } from '../../../components/Loading';
import { mapQueryParamsToBuchungszeileVariables } from './Filters/filtersFormMapper';
import { StyledStickyHeaderDivForFilterOrSummary } from '../../../components/StickyHeader/styled/StickyHeader.styled';

type Props = {
  queryParams: TBookingJournalQueryParams;
  summaryRef: RefObject<HTMLDivElement>;
};
const BookingJournalListSummary: FC<Props> = ({ queryParams, summaryRef }) => {
  const { data } = useFibuBuchungszeileListSummaryQuery({
    variables: {
      ...mapQueryParamsToBuchungszeileVariables(queryParams),
    },
  });

  const summary = data?.getFibuBuchungszeileListSummary.data;

  return (
    <StyledStickyHeaderDivForFilterOrSummary ref={summaryRef}>
      {summary ? (
        <StyledListSummarySpace>
          <SummaryText text="Betrag" value={summary.sumBetrag} />
          <SummaryText text="Steuer" value={summary.sumSteuer} />
        </StyledListSummarySpace>
      ) : (
        <LoadingCol isLoading />
      )}
    </StyledStickyHeaderDivForFilterOrSummary>
  );
};

export default BookingJournalListSummary;
