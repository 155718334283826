import React, { FC } from 'react';
import DeviationTab from '../../../../components/Deviation/Tab/DeviationTab';
import { TopAbrechnungStatusTuple } from '../../../../types';
import { showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useTopAbrechnungDeviationListQuery } from './gql/TopAbrechnungDeviationQueries.types';
import { useTopAbrechnungApplyAllDeviationsMutation } from './gql/TopAbrechnungDeviationMutations.types';
import { pathsToWeAbrechnungDetails } from '../../BkOrWe/weAbrechnungUriPaths';

type Props = {
  objektId: string;
  objektAbrechnungId: string;
  onAction: () => Promise<unknown>;
  status: TopAbrechnungStatusTuple;
};

const BkOrWeTopAbrechnungDeviationTab: FC<Props> = ({ objektId, objektAbrechnungId, onAction, status }) => {
  const { data, refetch } = useTopAbrechnungDeviationListQuery({ variables: { objektId, objektAbrechnungId } });

  const [runApplyAllDeviations] = useTopAbrechnungApplyAllDeviationsMutation({
    variables: {
      objektId,
      objektAbrechnungId,
    },
    onCompleted: () => {
      showSuccessMsgOther('Alle Abweichungen sind korrigiert.');
      onAction().then(() => refetch());
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const topAbrechnungDeviationList = data?.getTopAbrechnungDeviationList.data;
  const loading = !topAbrechnungDeviationList;

  return (
    <DeviationTab
      dataSource={topAbrechnungDeviationList}
      loading={loading}
      status={status}
      runApplyAllDeviations={runApplyAllDeviations}
      showSourceEntity
      reviewErrorUriPath={pathsToWeAbrechnungDetails(objektId, objektAbrechnungId).topAbrTab}
    />
  );
};

export default BkOrWeTopAbrechnungDeviationTab;
