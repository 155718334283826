import { QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { generatePathToIndexedValueListingPage } from '../../indexedValueUriPaths';
import { IndexedValueListingFiltersFormValues } from './indexedValueListingFiltersFormMapper';

export enum IndexedValueQueryParamKey {
  BESTANDSEINHEIT_ID = 'bestandseinheitId',
  INDEX_SERIES_ID = 'indexSeriesId',
  OBJEKT_ID = 'objektId',
  RECHNUNGS_AUSSTELLER_ID = 'rechnungsAusstellerId',
  STATUS_LIST = 'statusList',
  VALID_FROM = 'validFrom',
  VALID_TO = 'validTo',
  VALUE_PRESERVATION_TYPE_LIST = 'valuePreservationTypeList',
  VERTRAGSPARTNER_ID = 'vertragspartnerId',
  VERTRAG_ID = 'vertragId',
  VORSCHREIBUNGSPOSITION_NAME = 'vorschreibungspositionName',
  SET_DEFAULT_STATUS_ON_INIT = 'setDefaultStatusOnInit',
}

export type TIndexedValueQueryParams = QueryParams<IndexedValueListingFiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TIndexedValueQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToIndexedValueListingPage(filters, paginationParams));
