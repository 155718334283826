import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { BudgetingDetailsPageRouteParams } from './routes';
import menuListInfoBudgeting from './menuListInfoBudgeting';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { BudgetingGenerierlauf } from '../../../types';
import { useBudgetingGenerierlaufQuery } from '../../../features/Verarbeitung/gql/BudgetingGenerierlauf/BudgetingGenerierlaufQueries.types';
import { useRestartBudgetingGenerierlaufMutation } from '../../../features/Verarbeitung/gql/BudgetingGenerierlauf/BudgetingGenerierlaufMutations.types';

const BudgetingDetailsPage = () => {
  const { generierlaufId } = useParams() as BudgetingDetailsPageRouteParams;

  const { data, refetch } = useBudgetingGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getBudgetingGenerierlauf.data;

  const [runRestart] = useRestartBudgetingGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<BudgetingGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.Budgeting}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.Budgeting}
          onGenerierlaufRestart={runRestart}
        />
      )}
      isLoading={false}
      sidebarMenuList={menuListInfoBudgeting}
    />
  );
};

export default BudgetingDetailsPage;
