import { FormFields } from '../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { ObjektFinancialPlanVerarbeitungQueryParams } from './filtersQueryParams';

export type ObjektFinancialPlanVerarbeitungEntryFiltersFormValues = {
  budgetingId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
  objektId?: string;
  rechnungsAusstellerId?: string;
};

export const objektFinancialPlanVerarbeitungEntryFiltersFormFields: FormFields<ObjektFinancialPlanVerarbeitungEntryFiltersFormValues> = {
  budgetingId: 'budgetingId',
  exitCodeList: 'exitCodeList',
  objektId: 'objektId',
  rechnungsAusstellerId: 'rechnungsAusstellerId',
};

export const objektFinancialPlanVerarbeitungEntryFiltersFormInitialValues = (
  queryParams: ObjektFinancialPlanVerarbeitungQueryParams
): ObjektFinancialPlanVerarbeitungEntryFiltersFormValues => ({
  budgetingId: queryParams.budgetingId,
  exitCodeList: queryParams.exitCodeList,
  objektId: queryParams.objektId,
  rechnungsAusstellerId: queryParams.rechnungsAusstellerId,
});

export const mapFormValuesToObjektFinancialPlanVerarbeitungFilters = (
  formValues: ObjektFinancialPlanVerarbeitungEntryFiltersFormValues
): ObjektFinancialPlanVerarbeitungQueryParams => ({
  budgetingId: formValues.budgetingId,
  exitCodeList: formValues.exitCodeList,
  objektId: formValues.objektId,
  rechnungsAusstellerId: formValues.rechnungsAusstellerId,
});
