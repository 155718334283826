import { generatePath } from 'react-router-dom';
import { URI_OBJEKT_DETAILS_PAGE } from '../Objekt/objektUriPaths';
import { isStatusInvalid } from '../../helpers/statusHelper';
import { UstVomAufwandStatusTuple } from '../../types';

export const URI_UST_VOM_AUFWAND_DETAILS_PAGE = `${URI_OBJEKT_DETAILS_PAGE}/tops/ust-vom-aufwand/:ustVomAufwandId`;

export const generatePathToUstVomAufwandDetailsPage = (objektId: string, ustVomAufwandId: string) =>
  generatePath(URI_UST_VOM_AUFWAND_DETAILS_PAGE, { objektId, ustVomAufwandId });

export const generatePathToUstVomAufwandDetailsPageOrDiscrepancies = (objektId: string, ustVomAufwandId: string, status: UstVomAufwandStatusTuple) =>
  isStatusInvalid(status)
    ? `${generatePathToUstVomAufwandDetailsPage(objektId, ustVomAufwandId)}/discrepancies`
    : generatePathToUstVomAufwandDetailsPage(objektId, ustVomAufwandId);
