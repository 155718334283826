import React, { FC } from 'react';
import { Table } from 'antd';
import { IndexSeries, IndexSeriesEntry } from '../../../../../types';
import indexSeriesDetailsTableColumns from './indexSeriesDetailsTableColumns';

type Props = {
  indexSeries: IndexSeries;
  onAction: () => void;
  showPreliminary: boolean;
  isAndromedaAdmin: boolean;
};

const IndexSeriesDetailsTable: FC<Props> = ({ indexSeries, onAction, showPreliminary, isAndromedaAdmin }) => (
  <Table<IndexSeriesEntry>
    size="small"
    dataSource={indexSeries.indexSeriesEntryList}
    columns={indexSeriesDetailsTableColumns(indexSeries, onAction, showPreliminary, isAndromedaAdmin, indexSeries.firmendatenId)}
    rowKey={(record) => record.indexSeriesEntryId}
  />
);

export default IndexSeriesDetailsTable;
