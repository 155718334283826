import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { KontoAbrechnungskreisFieldsFragmentDoc } from '../AbrDefFragments.types';
import { TextbausteinFieldsFragmentDoc } from '../../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateHeAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  request: Types.HeAbrechnungsdefinitionVersionCreateInput;
}>;

export type CreateHeAbrechnungsdefinitionVersionMutation = {
  createHeAbrechnungsdefinitionVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      heAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateHeAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
  request: Types.HeAbrechnungsdefinitionVersionUpdateInput;
}>;

export type UpdateHeAbrechnungsdefinitionVersionMutation = {
  updateHeAbrechnungsdefinitionVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      heAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteHeAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteHeAbrechnungsdefinitionVersionMutation = {
  deleteHeAbrechnungsdefinitionVersion: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateHeAbrechnungsdefinitionVersionDocument = gql`
  mutation CreateHeAbrechnungsdefinitionVersion($abrechnungsdefinitionId: ID!, $request: HeAbrechnungsdefinitionVersionCreateInput!) {
    createHeAbrechnungsdefinitionVersion(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $request) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        heAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useCreateHeAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateHeAbrechnungsdefinitionVersionMutation, CreateHeAbrechnungsdefinitionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateHeAbrechnungsdefinitionVersionMutation, CreateHeAbrechnungsdefinitionVersionMutationVariables>(
    CreateHeAbrechnungsdefinitionVersionDocument,
    options
  );
}
export type CreateHeAbrechnungsdefinitionVersionMutationHookResult = ReturnType<typeof useCreateHeAbrechnungsdefinitionVersionMutation>;
export type CreateHeAbrechnungsdefinitionVersionMutationResult = Apollo.MutationResult<CreateHeAbrechnungsdefinitionVersionMutation>;
export type CreateHeAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateHeAbrechnungsdefinitionVersionMutation,
  CreateHeAbrechnungsdefinitionVersionMutationVariables
>;
export const UpdateHeAbrechnungsdefinitionVersionDocument = gql`
  mutation UpdateHeAbrechnungsdefinitionVersion(
    $abrechnungsdefinitionId: ID!
    $abrechnungsdefinitionVersionId: ID!
    $request: HeAbrechnungsdefinitionVersionUpdateInput!
  ) {
    updateHeAbrechnungsdefinitionVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
      input: $request
    ) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        heAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useUpdateHeAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHeAbrechnungsdefinitionVersionMutation, UpdateHeAbrechnungsdefinitionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateHeAbrechnungsdefinitionVersionMutation, UpdateHeAbrechnungsdefinitionVersionMutationVariables>(
    UpdateHeAbrechnungsdefinitionVersionDocument,
    options
  );
}
export type UpdateHeAbrechnungsdefinitionVersionMutationHookResult = ReturnType<typeof useUpdateHeAbrechnungsdefinitionVersionMutation>;
export type UpdateHeAbrechnungsdefinitionVersionMutationResult = Apollo.MutationResult<UpdateHeAbrechnungsdefinitionVersionMutation>;
export type UpdateHeAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateHeAbrechnungsdefinitionVersionMutation,
  UpdateHeAbrechnungsdefinitionVersionMutationVariables
>;
export const DeleteHeAbrechnungsdefinitionVersionDocument = gql`
  mutation DeleteHeAbrechnungsdefinitionVersion($abrechnungsdefinitionId: ID!, $abrechnungsdefinitionVersionId: ID!) {
    deleteHeAbrechnungsdefinitionVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteHeAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteHeAbrechnungsdefinitionVersionMutation, DeleteHeAbrechnungsdefinitionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteHeAbrechnungsdefinitionVersionMutation, DeleteHeAbrechnungsdefinitionVersionMutationVariables>(
    DeleteHeAbrechnungsdefinitionVersionDocument,
    options
  );
}
export type DeleteHeAbrechnungsdefinitionVersionMutationHookResult = ReturnType<typeof useDeleteHeAbrechnungsdefinitionVersionMutation>;
export type DeleteHeAbrechnungsdefinitionVersionMutationResult = Apollo.MutationResult<DeleteHeAbrechnungsdefinitionVersionMutation>;
export type DeleteHeAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteHeAbrechnungsdefinitionVersionMutation,
  DeleteHeAbrechnungsdefinitionVersionMutationVariables
>;
