import React, { FC, PropsWithChildren } from 'react';
import { Badge, Tooltip } from 'antd';

type Props = {
  badgeCount?: number;
  label?: string;
  isTooltipOpen?: boolean;
};

const TicketActionButtonWithTooltip: FC<Props & PropsWithChildren> = ({ badgeCount, label, children, isTooltipOpen }) => (
  <Tooltip title={label} open={isTooltipOpen}>
    <Badge size="small" count={badgeCount}>
      <span style={{ padding: '4px 0' }}>{children}</span>
    </Badge>
  </Tooltip>
);

export default TicketActionButtonWithTooltip;
