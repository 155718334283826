import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import { Col, Divider, Row, Typography } from 'antd';
import { firmaCreateFormValidationSchema, firmaUpdateFormValidationSchema } from './firmaFormValidationSchema';
import { firmaFormFields, FirmaFormValues, mapFirmaToFormValues, mapFormValuesToCreateFirma, mapFormValuesToUpdateFirma } from './firmaFormMapper';
import {
  getFirmaKurzBezeichnungVorschlag,
  getKurzBezeichnungVorschlagFieldsNotToBeDebounced,
  getKurzBezeichnungVorschlagFieldsToBeDebounced,
  updateFirmennameAndAddress,
} from './firmaFormHelper';
import FormButtons from '../../../../components/Button/FormButtons';
import FirmennameFormPart from '../../../../shared/components/Firmenname/form/FirmennameFormPart';
import SpracheSelect from '../../Sprache/SpracheSelect';
import LegalFormSelect from '../../../../shared/components/LegalFormSelect/LegalFormSelect';
import StreetAddressFormPart from '../../../../shared/components/Address/form/StreetAddressFormPart';
import CompanyRegistrationNumberFormPart from '../../../../shared/components/CompanyRegistrationNumber/form/CompanyRegistrationNumberFormPart';
import SalutationSelect from '../../../../shared/components/Salutation/SalutationSelect';
import { useCreateFirmaMutation, useUpdateFirmaMutation } from '../gql/FirmaMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import VatIdNumberFormPart from '../../../../shared/components/VatIdentificationNumber/form/VatIdNumberFormPart';
import KurzBezeichnungFormPart from '../../../../shared/components/KurzBezeichnung/form/KurzBezeichnungFormPart';
import SteuerpflichtSelect from '../../../../shared/components/SteuerpflichtSelect/SteuerpflichtSelect';
import { showSuccessMsgCreateWithPath, showSuccessMsgUpdate } from '../../../../components/message/message';
import { generatePathToRechtstraegerDetailsPage } from '../../rechtstraegerHelper';
import { Firma, RechtstraegerType } from '../../../../types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type FirmaFormProps = {
  firma?: Firma;
  kundenSystemId: string;
  onSuccess: (rechtstraegerId: string, kurzBezeichnung: string, type: { value: RechtstraegerType }) => void;
  onCancel: () => void;
};

const FirmaForm: FC<FirmaFormProps> = ({ firma, kundenSystemId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'RechtstraegerFirma'>('RechtstraegerFirma');

  const initialValues = mapFirmaToFormValues(firma);
  const isUpdate = !!firma;

  const entity = `Firma`;

  const [runCreate, { loading: loadingCreate }] = useCreateFirmaMutation({
    onCompleted: (data) => {
      const { rechtstraegerId, kurzBezeichnung, type } = data.createFirma.data;
      showSuccessMsgCreateWithPath(entity, kurzBezeichnung, generatePathToRechtstraegerDetailsPage(rechtstraegerId));
      onSuccess(rechtstraegerId, kurzBezeichnung, type);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateFirmaMutation({
    onCompleted: (data) => {
      const { rechtstraegerId, kurzBezeichnung, type } = data.updateFirma.data;
      showSuccessMsgUpdate(entity);
      onSuccess(rechtstraegerId, kurzBezeichnung, type);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<FirmaFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={isUpdate ? firmaUpdateFormValidationSchema : firmaCreateFormValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        if (!isUpdate) {
          runCreate({ variables: { request: mapFormValuesToCreateFirma(formValues) } }).finally(() => formikHelpers.setSubmitting(false));
        } else {
          runUpdate({
            variables: { request: mapFormValuesToUpdateFirma(formValues), firmaId: firma.rechtstraegerId },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Einstellungen</Typography.Title>
            </Col>
            <Col span={18}>
              <SpracheSelect fieldHelp={getFieldHelpText('RechtstraegerFirma.sprache')} />
              <FormItemWithFieldHelp label="Merkmale" name={firmaFormFields.tagList} fieldHelp={getFieldHelpText('RechtstraegerFirma.tagList')}>
                <Select id={firmaFormFields.tagList} name={firmaFormFields.tagList} mode="tags" placeholder="z.B. Berufsgruppe, Weihnachskarte" />
              </FormItemWithFieldHelp>
            </Col>
          </Row>
          <Divider />
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Allgemeine Daten</Typography.Title>
            </Col>
            <Col span={18}>
              <VatIdNumberFormPart
                onValidationSuccess={updateFirmennameAndAddress(formikProps)}
                formikProps={formikProps}
                fieldHelp={getFieldHelpText('RechtstraegerFirma.vatIdentificationNumber')}
              />
              <CompanyRegistrationNumberFormPart fieldHelp={getFieldHelpText('RechtstraegerFirma.companyRegistrationNumber')} />
              <LegalFormSelect fieldHelp={getFieldHelpText('RechtstraegerFirma.legalForm')} />
            </Col>
          </Row>
          <Divider />
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Etikett</Typography.Title>
            </Col>
            <Col span={18}>
              <SalutationSelect name="salutation" kundenSystemId={kundenSystemId} fieldHelp={getFieldHelpText('RechtstraegerFirma.salutation')} />
              <FirmennameFormPart
                fieldHelpNames={{
                  name1: getFieldHelpText('RechtstraegerFirma.name1'),
                  name2: getFieldHelpText('RechtstraegerFirma.name2'),
                  name3: getFieldHelpText('RechtstraegerFirma.name3'),
                }}
              />
              <KurzBezeichnungFormPart<FirmaFormValues>
                placeholder="Kurzbezeichnung"
                kurzBezeichnungVorschlagConfig={{
                  getFieldsToBeDebounced: getKurzBezeichnungVorschlagFieldsToBeDebounced,
                  getFieldsNotToBeDebounced: getKurzBezeichnungVorschlagFieldsNotToBeDebounced,
                  getKurzBezeichnungVorschlagFn: getFirmaKurzBezeichnungVorschlag,
                }}
                formikProps={formikProps}
                fieldHelp={getFieldHelpText('RechtstraegerFirma.kurzBezeichnung')}
              />
            </Col>
          </Row>
          {!isUpdate && (
            <>
              <Divider />
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Hauptadresse</Typography.Title>
                </Col>
                <Col span={18}>
                  <StreetAddressFormPart
                    fieldNamePrefix="hauptAddress."
                    fieldHelpNames={{
                      city: getFieldHelpText('RechtstraegerFirma.hauptAddress.city'),
                      cityAdditionalInformation: getFieldHelpText('RechtstraegerFirma.hauptAddress.cityAdditionalInformation'),
                      countryCodeIso2: getFieldHelpText('RechtstraegerFirma.hauptAddress.countryCodeIso2'),
                      houseEntranceApartmentNumber: getFieldHelpText('RechtstraegerFirma.hauptAddress.houseEntranceApartmentNumber'),
                      street: getFieldHelpText('RechtstraegerFirma.hauptAddress.street'),
                      zipCode: getFieldHelpText('RechtstraegerFirma.hauptAddress.zipCode'),
                    }}
                  />
                </Col>
              </Row>
              <Divider />
            </>
          )}
          {!isUpdate && (
            <>
              <Divider />
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Finanz/Steuerparameter</Typography.Title>
                </Col>
                <Col span={18}>
                  <SteuerpflichtSelect fieldHelp={getFieldHelpText('RechtstraegerFirma.steuerpflicht.steuerpflicht')} />
                </Col>
              </Row>
            </>
          )}
          <FormButtons
            updateMode={isUpdate}
            isOkDisabled={formikProps.isSubmitting}
            onCancel={onCancel}
            isOkLoading={loadingUpdate || loadingCreate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default FirmaForm;
