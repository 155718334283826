import React from 'react';
import styled from 'styled-components';

import TableWithPagination, { TableWithPaginationProps } from '../../TableWithPagination/TableWithPagination';

export const StyledTable = styled(TableWithPagination)`
  .ant-table-cell {
    .ant-table {
      margin: 0 !important;

      td,
      th {
        border-radius: 0 !important;
        margin: 0 !important;
      }
    }
  }

  .ant-table-layout-fixed {
    margin-inline: 0 !important;
  }

  .ant-table-ping-left {
    .ant-table-container {
      &:before {
        box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
      }
    }
  }
  th {
    height: 49px;
    &:before {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
    &:first-of-type:before {
      display: none;
    }
  }

  td {
    height: 29px;
  }

  .ant-table-expand-icon-col {
    width: 48px;
  }

  .ant-table-selection-column {
    left: 0 !important;
  }

  .ant-table-expanded-row {
    padding: 0;
    position: relative !important;
    width: calc(100% + 8px) !important;
    overflow: visible !important;

    & > .ant-table-cell:first-child {
      padding: 0 !important;
      border-bottom: 0 !important;
    }
  }

  .ant-table-row-expand-icon {
    float: left;
    position: relative;
  }

  &.nested-table-level-1 {
    .ant-table-row-expand-icon {
      left: -8px;
    }
  }

  .nested-table-level-2 {
    .ant-table-row-expand-icon {
      left: -2px;
    }
    tr:not(.ant-table-measure-row) {
      & > th,
      & > td:nth-child(1) {
        background: hsl(0deg 0% 96%) !important;
        border-bottom: 1px solid hsl(0deg 0% 91%) !important;
      }
      & > td {
        background: hsl(0 0% 99.5% / 1) !important;
        border-bottom: 1px solid hsl(0deg 0% 93%) !important;

        &:nth-child(1) {
          &.ant-table-cell-row-hover {
            background: hsl(0deg 0% 94%) !important;
          }
        }
      }
      & > th:nth-child(2),
      & > td:nth-child(2) {
        padding-left: 16px !important;
      }
    }
  }

  .nested-table-level-3 {
    .ant-table-row-expand-icon {
      left: 4px;
    }
    tr:not(.ant-table-measure-row) {
      & > th,
      & > td:nth-child(1) {
        background: hsl(0deg 0% 94%) !important;
        border-bottom: 1px solid hsl(0deg 0% 89%) !important;
      }

      & > td {
        background: hsl(0 0% 99% / 1) !important;
        border-bottom: 1px solid hsl(0deg 0% 92%) !important;

        &:nth-child(1) {
          &.ant-table-cell-row-hover {
            background: hsl(0deg 0% 92%) !important;
          }
        }
      }

      & > th:nth-child(2),
      & > td:nth-child(2) {
        padding-left: 22px !important;
      }
    }
  }

  .nested-table-level-4 {
    .ant-table-row-expand-icon {
      left: 10px;
    }
    tr:not(.ant-table-measure-row) {
      & > th,
      & > td:nth-child(1) {
        background: hsl(0deg 0% 92%) !important;
        border-bottom: 1px solid hsl(0deg 0% 87%) !important;
      }

      & > td {
        background: hsl(0 0% 98.5% / 1) !important;
        border-bottom: 1px solid hsl(0deg 0% 91%) !important;

        &:nth-child(1) {
          &.ant-table-cell-row-hover {
            background: hsl(0deg 0% 90%) !important;
          }
        }
      }

      & > th:nth-child(2),
      & > td:nth-child(2) {
        padding-left: 28px !important;
      }
    }
  }

  .nested-table-level-5 {
    .ant-table-row-expand-icon {
      left: 16px;
    }
    tr:not(.ant-table-measure-row) {
      & > th,
      & > td:nth-child(1) {
        background: hsl(0deg 0% 90%) !important;
        border-bottom: 1px solid hsl(0deg 0% 85%) !important;
      }
      td {
        background: hsl(0 0% 98% / 1) !important;
        border-bottom: 1px solid hsl(0deg 0% 90%) !important;

        &:nth-child(1) {
          &.ant-table-cell-row-hover {
            background: hsl(0deg 0% 88%) !important;
          }
        }
      }
      & > th:nth-child(2),
      & > td:nth-child(2) {
        padding-left: 34px !important;
      }
    }
  }

  .ant-table-title {
    background: #fafafa;
  }

  /* INFO: Added so rows won't be highlighted when using selection feature for payment listing table */
  .ant-table-row-selected.payment-listing-custom-row-class > td {
    background: #ffffff !important;
  }
` as <T>(props: TableWithPaginationProps<T>) => React.ReactElement;
