import { FC } from 'react';
import { FormikProps } from 'formik';
import { Switch } from 'formik-antd';
import { Space, Typography } from 'antd';
import { topAbrDefGeneralSettingsFormFields, TopAbrDefGeneralSettingsFormValues } from '../../topAbrDefGeneralSettingsFormMapper';
import ParamFormItem from '../../../../../../../../../components/Form/ParamFormItem';
import { AbrechnungsdefinitionType } from '../../../../../../../../../types';
import { isAbrDefTypeBkAbrechnung, isAbrDefTypeWeAbrechnung } from '../../../../../../AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';

type Props = {
  formikProps: FormikProps<TopAbrDefGeneralSettingsFormValues>;
  isDisabled: boolean;
  abrDefType: AbrechnungsdefinitionType;
  onChange: (displayDueDate: boolean) => void;
  fieldHelp?: string | null;
};

const DisplayDueDateFormItem: FC<Props> = ({ formikProps, isDisabled, abrDefType, onChange, fieldHelp }) => (
  <ParamFormItem name={topAbrDefGeneralSettingsFormFields.displayDueDate} label="Fälligkeit ausgeben" marginBottom="8px" fieldHelp={fieldHelp}>
    <Space>
      <Switch
        name={topAbrDefGeneralSettingsFormFields.displayDueDate}
        size="small"
        onChange={(value) => {
          // fields needs to be set to '' for validation to work
          if (isAbrDefTypeBkAbrechnung(abrDefType)) {
            formikProps.setFieldValue(`${topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionMieter}.type`, '');
            formikProps.setFieldValue(`${topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionMieter}.month`, '');
          }
          if (isAbrDefTypeWeAbrechnung(abrDefType)) {
            formikProps.setFieldValue(`${topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionEigentuemerGuthaben}.type`, '');
            formikProps.setFieldValue(`${topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionEigentuemerGuthaben}.month`, '');
            formikProps.setFieldValue(`${topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionEigentuemerNachzahlung}.type`, '');
            formikProps.setFieldValue(`${topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionEigentuemerNachzahlung}.month`, '');
          }
          onChange(value);
        }}
        disabled={isDisabled}
      />
      <Typography.Text>{formikProps.values.displayDueDate ? 'Ja' : 'Nein'}</Typography.Text>
    </Space>
  </ParamFormItem>
);

export default DisplayDueDateFormItem;
