import { useParams } from 'react-router-dom';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { WeAbrDetailsPageRouteParams } from '../../../pages/WeAbrechnungDetailsPage/routes';
import { BkAbrDetailsPageRouteParams } from '../../../pages/BkAbrechnungDetailsPage/routes';

const useOnBkOrWeAbrechnungDataChangedEvents = (key: string, callback: () => Promise<unknown>) => {
  const { objektAbrechnungId } = useParams() as WeAbrDetailsPageRouteParams | BkAbrDetailsPageRouteParams;
  useOnDataChangedEvent([
    {
      filter: {
        //userId: user.username,
        //mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.topAbrechnung,
        params: {
          objektAbrechnungId,
        },
      },
      notificationDescription: 'Topabrechnungen wurden gerade verändert',
      key: `${key}-topAbr`,
      callback,
    },
    {
      filter: {
        //userId: user.username,
        //mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.objektAbrechnung,
        params: {
          objektAbrechnungId,
        },
      },
      notificationDescription: 'Objektabrechnung wurde gerade verändert',
      key: `${key}-objektAbr`,
      callback,
    },
  ]);
};

export { useOnBkOrWeAbrechnungDataChangedEvents };
