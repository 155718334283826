import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePostItMutationVariables = Types.Exact<{
  postItId: Types.Scalars['ID']['input'];
  input: Types.PostItInput;
}>;

export type UpdatePostItMutation = {
  updatePostIt: {
    data: { postItId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeletePostItMutationVariables = Types.Exact<{
  postItId: Types.Scalars['ID']['input'];
}>;

export type DeletePostItMutation = {
  deletePostIt: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const UpdatePostItDocument = gql`
  mutation UpdatePostIt($postItId: ID!, $input: PostItInput!) {
    updatePostIt(postItId: $postItId, input: $input) {
      data {
        postItId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdatePostItMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostItMutation, UpdatePostItMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePostItMutation, UpdatePostItMutationVariables>(UpdatePostItDocument, options);
}
export type UpdatePostItMutationHookResult = ReturnType<typeof useUpdatePostItMutation>;
export type UpdatePostItMutationResult = Apollo.MutationResult<UpdatePostItMutation>;
export type UpdatePostItMutationOptions = Apollo.BaseMutationOptions<UpdatePostItMutation, UpdatePostItMutationVariables>;
export const DeletePostItDocument = gql`
  mutation DeletePostIt($postItId: ID!) {
    deletePostIt(postItId: $postItId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeletePostItMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostItMutation, DeletePostItMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePostItMutation, DeletePostItMutationVariables>(DeletePostItDocument, options);
}
export type DeletePostItMutationHookResult = ReturnType<typeof useDeletePostItMutation>;
export type DeletePostItMutationResult = Apollo.MutationResult<DeletePostItMutation>;
export type DeletePostItMutationOptions = Apollo.BaseMutationOptions<DeletePostItMutation, DeletePostItMutationVariables>;
