import { useBuchungQuery } from '../gql/BuchungQueries.types';
import { FibuBuchungszeile } from '../../../types';
import {
  getBuchungszeileForCurrentBookingWithBankVerwendung,
  getBuchungszeileForCurrentBookingWithPersonenkonto,
  isFibuBuchungTypeRuecklastschrift,
  isFibuBuchungTypeSpesen,
  isFibuBuchungTypeZahlung,
} from '../View/shared/bookingHelpers';

export const useCurrentBookingDetails = (buchungszeileList: FibuBuchungszeile[]) => {
  const buchungszeileForCurrentBookingWithPersonenkonto = getBuchungszeileForCurrentBookingWithPersonenkonto(buchungszeileList);
  const buchungszeileForCurrentBookingWithBankVerwendung = getBuchungszeileForCurrentBookingWithBankVerwendung(buchungszeileList);

  const getBuchungszeileForCurrentBooking = () => {
    // IMPORTANT! this if-check is important and only for the case of correcting a booking:
    // Once the changes are saved and a booking is being corrected in the backend, all buchungszeile list entries are set immediately to storniert = true
    // In this case, if the user opens a page based on old link to the already corrected booking, he sees an error as getting the current booking is based on non-storno entries
    // In order to ensure showing the data in this case, we get the first bookingId from the buchungszeile list
    if (!buchungszeileForCurrentBookingWithBankVerwendung) return buchungszeileList[0];

    return buchungszeileForCurrentBookingWithPersonenkonto ?? buchungszeileForCurrentBookingWithBankVerwendung;
  };

  const buchungszeileForCurrentBooking = getBuchungszeileForCurrentBooking();

  const currentFibuBuchungId = buchungszeileForCurrentBooking.buchungId;
  const belegSymbol = buchungszeileForCurrentBooking.buchungsKopf.belegSymbol;
  const buchungType = buchungszeileForCurrentBooking.buchungsKopf.buchungsType;
  const buchungskreisRechtstraegerId = buchungszeileForCurrentBooking.buchungsKopf.buchungskreisRechtstraegerId;

  const personenkontoId = buchungszeileForCurrentBookingWithPersonenkonto?.konto.kontoId;
  const amountFromPersonenkonto = buchungszeileForCurrentBookingWithPersonenkonto?.betrag ?? 0;

  /** Edit is allowed only in the following cases:
   Rücklastschrift + storniert === false
   Zahlung + storniert  === false
   Spesen  + storniert  === false (and it doesn't have AuszifferungBlock)
   * */
  const isEditAllowedBasedOnBuchungType =
    !buchungszeileForCurrentBooking.storniert &&
    (isFibuBuchungTypeZahlung(buchungType.value) ||
      isFibuBuchungTypeRuecklastschrift(buchungType.value) ||
      isFibuBuchungTypeSpesen(buchungType.value));

  const { data } = useBuchungQuery({
    variables: { buchungId: currentFibuBuchungId, rechtstraegerId: buchungskreisRechtstraegerId },
  });

  const detailsIdsForCurrentBooking = data?.getBuchung.data;

  const zahlungId = detailsIdsForCurrentBooking?.zahlungId;

  return {
    amountFromPersonenkonto,
    belegSymbol,
    buchungType,
    currentFibuBuchungId,
    buchungskreisRechtstraegerId,
    isEditAllowedBasedOnBuchungType,
    personenkontoId,
    zahlungId,
  };
};
