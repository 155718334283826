import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import useDebounce from '../../../../hooks/useDebounce';
import { useFilterFibuBuchungsanweisungObjektListQuery } from '../../gql/BuchungsanweisungQueries.types';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';

type Props = {
  rechtstraegerIdList?: string[] | null;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'placeholder' | 'size' | 'showSearch'>;

const FibuBaObjektSelect: FC<Props> = ({ rechtstraegerIdList, ...restProps }) => {
  const [kurzBezeichnungText, setKurzBezeichnungText] = useState<string>('');
  const debouncedText = useDebounce(kurzBezeichnungText);

  const { loading, data } = useFilterFibuBuchungsanweisungObjektListQuery({
    variables: {
      rechtstraegerIdList,
      kurzBezeichnung: debouncedText as string,
    },
  });
  const objektList = data?.getFilterFibuBuchungsanweisungObjektList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Objekt auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      onSelect={setKurzBezeichnungText}
      onSearch={(value) => {
        kurzBezeichnungText.length === 1 && !value && setKurzBezeichnungText(''); // Checking the length of the belegnummerText makes the search component editable
        value && setKurzBezeichnungText(value);
      }}
      onClear={() => setKurzBezeichnungText('')}
      searchValue={kurzBezeichnungText}
      notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
    >
      {objektList.map((obj) => (
        <Select.Option key={obj.objektId} value={obj.objektId}>
          <DataWithShortenedText maxTextLength={48} text={obj.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default FibuBaObjektSelect;
