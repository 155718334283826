import { TriggerEventHandler } from 'rc-table/lib/interface';
import React, { FC, PropsWithChildren } from 'react';

type ExpandedIconProps<T> = {
  expanded: boolean;
  record: T;
  onExpand: TriggerEventHandler<T>;
};

export function ExpandedIcon<T>({ expanded, onExpand, record }: ExpandedIconProps<T>) {
  return expanded ? (
    <ContainerToPositionTopElement>
      <button
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
        aria-label="Zeile reduzieren"
        aria-expanded="true"
        onClick={(e) => onExpand(record, e)}
      />
    </ContainerToPositionTopElement>
  ) : (
    <ContainerToPositionTopElement>
      <button
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
        aria-label="Zeile erweitern"
        aria-expanded="false"
        onClick={(e) => onExpand(record, e)}
      />
    </ContainerToPositionTopElement>
  );
}

export const ContainerToPositionTopElement: FC<PropsWithChildren> = ({ children }) => {
  return <div>{children}</div>;
};
