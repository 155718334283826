import { generatePath } from 'react-router';
import { FiltersFormValues } from './filtersFormMapper';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { URI_PAYMENT_CLEARING_PAGE } from '../../paymentClearingUriPaths';

export enum QueryParamKey {
  AVAILABLE_AMOUNT_FROM = 'availableAmountFrom',
  AVAILABLE_AMOUNT_TO = 'availableAmountTo',
  BUCHUNGSKREIS_ID = 'buchungskreisId',
  KONTO_ID = 'kontoId',
  KONTO_VERWENDUNG = 'kontoVerwendung',
  OUTSTANDING_AMOUNT_FROM = 'outstandingAmountFrom',
  OUTSTANDING_AMOUNT_TO = 'outstandingAmountTo',
  SET_DEFAULT_VALUES_ON_INIT = 'setDefaultValuesOnInit',
  VERTRAGSPARTNER_ID = 'vertragspartnerId',
}

export type TPaymentClearingQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TPaymentClearingQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToPaymentClearingPage(filters, paginationParams));

export const generatePathToPaymentClearingPage = (filters: TPaymentClearingQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_PAYMENT_CLEARING_PAGE)}?${mapToQueryString({
    [QueryParamKey.AVAILABLE_AMOUNT_FROM]: filters?.availableAmountFrom,
    [QueryParamKey.AVAILABLE_AMOUNT_TO]: filters?.availableAmountTo,
    [QueryParamKey.BUCHUNGSKREIS_ID]: filters?.buchungskreisId,
    [QueryParamKey.KONTO_ID]: filters?.kontoId,
    [QueryParamKey.KONTO_VERWENDUNG]: filters?.kontoVerwendung,
    [QueryParamKey.OUTSTANDING_AMOUNT_FROM]: filters?.outstandingAmountFrom,
    [QueryParamKey.OUTSTANDING_AMOUNT_TO]: filters?.outstandingAmountTo,
    [QueryParamKey.SET_DEFAULT_VALUES_ON_INIT]: filters?.setDefaultValuesOnInit,
    [QueryParamKey.VERTRAGSPARTNER_ID]: filters?.vertragspartnerId,
    ...paginationParams,
  })}`;
