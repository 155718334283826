import { FC } from 'react';
import { Table, Typography } from 'antd';
import { TableWithAlignedColsColumnType } from '../../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { EuroAmount } from '../../../../../../../components/Number';
import TextForEmptyValue from '../../../../../../../components/Text/TextForEmptyValue';
import { HeAbrechnungHeVertrag } from '../../../../../../../types';

type Props = {
  abrechnung: HeAbrechnungHeVertrag;
};

const SaldovortragTable: FC<Props> = ({ abrechnung }) => {
  return (
    <Table<HeAbrechnungHeVertrag>
      size="small"
      dataSource={[abrechnung]}
      columns={saldovortragTableColumns()}
      rowKey={(data) => data.createTs}
      pagination={false}
    />
  );
};

const saldovortragTableColumns = (): TableWithAlignedColsColumnType<HeAbrechnungHeVertrag>[] => [
  {
    title: '',
    width: '32px',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
  {
    title: 'Position',
    render: () => <Typography.Text>Saldovortrag</Typography.Text>,
  },
  {
    title: 'HE-Anteile',
    align: 'right',
    render: (heVertrag) => <EuroAmount value={heVertrag.saldovortrag} />,
  },
];

export default SaldovortragTable;
