import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TransactionTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TransactionTypeListQuery = {
  getTransactionTypeList: {
    data: Array<{ text: string; value: Types.TransactionType }>;
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const TransactionTypeListDocument = gql`
  query TransactionTypeList {
    getTransactionTypeList {
      data {
        text
        value
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useTransactionTypeListQuery(baseOptions?: Apollo.QueryHookOptions<TransactionTypeListQuery, TransactionTypeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionTypeListQuery, TransactionTypeListQueryVariables>(TransactionTypeListDocument, options);
}
export function useTransactionTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TransactionTypeListQuery, TransactionTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionTypeListQuery, TransactionTypeListQueryVariables>(TransactionTypeListDocument, options);
}
export function useTransactionTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TransactionTypeListQuery, TransactionTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TransactionTypeListQuery, TransactionTypeListQueryVariables>(TransactionTypeListDocument, options);
}
export type TransactionTypeListQueryHookResult = ReturnType<typeof useTransactionTypeListQuery>;
export type TransactionTypeListLazyQueryHookResult = ReturnType<typeof useTransactionTypeListLazyQuery>;
export type TransactionTypeListSuspenseQueryHookResult = ReturnType<typeof useTransactionTypeListSuspenseQuery>;
export type TransactionTypeListQueryResult = Apollo.QueryResult<TransactionTypeListQuery, TransactionTypeListQueryVariables>;
