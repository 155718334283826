import React, { FC } from 'react';
import { Space, Spin, Typography } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { Spacer } from '../../../components/Grid';
import { useTimelineOpen } from '../../../components/Timeline/useTimelineOpen';
import VstKuerzungKontenZuweisungTable from './Table/Read/VstKuerzungKontenZuweisungTable';
import VstKuerzungKontenZuweisungListingEmpty from './VstKuerzungKontenZuweisungListingEmpty';
import VstKuerzungKontenZuweisungDrawer from './VstKuerzungKontenZuweisungDrawer';
import { useVorsteuerkuerzungEinstellungListQuery } from '../gql/VorsteuerkuerzungEinstellungQueries.types';
import { Verwaltungsart } from '../../../types';
import theme from '../../../theme';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  verwaltungsart: Verwaltungsart;
};

const VstKuerzungKontenZuweisungTab: FC<Props> = ({ verwaltungsart }) => {
  const { timelineDrawer, openTimelineUpdateDrawer, closeTimelineDrawer, openTimelineCreateDrawer } = useTimelineOpen();

  const { data, refetch, loading } = useVorsteuerkuerzungEinstellungListQuery({
    variables: { verwaltungsart, onlyCurrent: true },
  });

  const vorsteuerkuerzungEinstellungList = data?.getVorsteuerkuerzungEinstellungList.data;

  const vorsteuerkuerzungEinstellung =
    vorsteuerkuerzungEinstellungList && vorsteuerkuerzungEinstellungList.length > 0 ? vorsteuerkuerzungEinstellungList[0] : undefined;
  const isLoading = !vorsteuerkuerzungEinstellung && loading;

  if (isLoading)
    return (
      <Space style={{ width: '100%', justifyContent: 'center' }}>
        <Spin />
      </Space>
    );

  return (
    <>
      {vorsteuerkuerzungEinstellung ? (
        <>
          <Space align="baseline">
            <Typography.Title level={5}>
              Vorsteuerkürzung-Kontenzuweisung ab dem <CustomFormattedDate value={vorsteuerkuerzungEinstellung.validFrom} />
            </Typography.Title>
            <HistoryOutlined onClick={openTimelineUpdateDrawer} style={{ color: theme.colors.blue }} />
          </Space>
          <Spacer height={16} />
          <VstKuerzungKontenZuweisungTable verwaltungsart={verwaltungsart} vorsteuerkuerzung={vorsteuerkuerzungEinstellung} />
        </>
      ) : (
        <VstKuerzungKontenZuweisungListingEmpty
          verwaltungsart={verwaltungsart}
          openTimelineCreateDrawer={openTimelineCreateDrawer}
          openTimelineUpdateDrawer={openTimelineUpdateDrawer}
        />
      )}
      <VstKuerzungKontenZuweisungDrawer
        verwaltungsart={verwaltungsart}
        timelineDrawer={timelineDrawer}
        closeTimelineDrawer={closeTimelineDrawer}
        reloadVstKuerzungCurrentKontenZuweisung={refetch}
      />
    </>
  );
};

export default VstKuerzungKontenZuweisungTab;
