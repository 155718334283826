import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { VUstDetails } from '../../vorschreibung-types';
import VTUstTableColumnTitles from './VTUstTableColumnTitles';
import VTUstTable from './VTUstTable';
import VTUstTableSummary from './VTUstTableSummary';

const VTUstTableBlock: FC<{ data: VUstDetails }> = ({ data }) => (
  <View style={[pdfStyles.row, { marginVertical: '5mm' }]}>
    <Text style={{ width: '25%' }} />
    <View style={[pdfStyles.column, { width: '50%' }]}>
      {/* Column titles */}
      <VTUstTableColumnTitles data={data.ustListHeader} />
      {/* Ust listing */}
      <VTUstTable data={data.ustList} />
      {/* Ust summary */}
      <VTUstTableSummary data={data.summary} />
    </View>
    <Text style={{ width: '25%' }} />
  </View>
);
export default VTUstTableBlock;
