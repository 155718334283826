import React, { FC } from 'react';
import { Formik } from 'formik';
import { Col, Divider, Row, Space, Typography } from 'antd';
import { DatePicker, Form, Input, Switch } from 'formik-antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { UstRegelset } from '../../../../types';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import {
  useCreateUstRegelsetTemplateMutation,
  useUpdateUstRegelsetTemplateMutation,
} from '../../UstRegelsetTemplate/gql/UstRegelsetTemplateMutations.types';
import {
  mapFormValuesToUstRegelsetCreate,
  mapFormValuesToUstRegelsetUpdate,
  mapUstRegelsetToFormValues,
  ustRegelsetFormFields,
  UstRegelsetFormValues,
} from './ustRegelsetTemplateFormMapper';
import CountryCodeSelect from '../../../../shared/components/CountryCode/CountryCodeSelect';
import UstKategorieTemplateSelect from '../../UstKategorieTemplateSelect/UstKategorieTemplateSelect';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import NutzungsartSelect from '../../../Vertrag/BeVertrag/Forms/NutzungsartSelect/NutzungsartSelect';
import SteuerpflichtSelect from '../../../../shared/components/SteuerpflichtSelect/SteuerpflichtSelect';
import { ustRegelsetTemplateFormValidationSchema } from './ustRegelsetTemplateFormValidationSchema';

type Props = {
  ustRegelset?: UstRegelset;
  onSuccess: () => void;
  onCancel: () => void;
};

const UstRegelsetTemplateForm: FC<Props> = ({ ustRegelset, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'UstRegelset'>('UstRegelset');
  const isUpdate = !!ustRegelset;
  const entity = 'USt-Regelsatz';

  const [runCreate, { loading: loadingCreate }] = useCreateUstRegelsetTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateUstRegelsetTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<UstRegelsetFormValues>
      initialValues={mapUstRegelsetToFormValues(ustRegelset)}
      validationSchema={ustRegelsetTemplateFormValidationSchema(isUpdate)}
      onSubmit={(formValues, formikHelpers) => {
        if (!isUpdate) {
          runCreate({ variables: { input: mapFormValuesToUstRegelsetCreate(formValues) } }).finally(() => formikHelpers.setSubmitting(false));
        } else {
          runUpdate({ variables: { ustRegelsetId: ustRegelset?.ustRegelsetId, input: mapFormValuesToUstRegelsetUpdate(formValues) } }).finally(() =>
            formikHelpers.setSubmitting(false)
          );
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Bezeichnung</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                name={ustRegelsetFormFields.bezeichnung}
                label="Bezeichnung"
                fieldHelp={getFieldHelpText('UstRegelset.bezeichnung')}
              >
                <Input id="Bezeichnung" name={ustRegelsetFormFields.bezeichnung} placeholder="z.B. Wohnen-steuerpflichtig" />
              </FormItemWithFieldHelp>
            </Col>
          </Row>
          <Divider />
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Kriterien</Typography.Title>
            </Col>
            <Col span={18}>
              <CountryCodeSelect name={ustRegelsetFormFields.countryCodeIso2} fieldHelp={getFieldHelpText('UstRegelset.countryCodeIso2')} />
              <FormItemWithFieldHelp
                name={ustRegelsetFormFields.nutzungsArt}
                label="Nutzungsart"
                fieldHelp={getFieldHelpText('UstRegelset.nutzungsArt')}
              >
                <NutzungsartSelect
                  size="small"
                  id={ustRegelsetFormFields.nutzungsArt}
                  name={ustRegelsetFormFields.nutzungsArt}
                  style={{ width: '100%' }}
                />
              </FormItemWithFieldHelp>
              <SteuerpflichtSelect fieldHelp={getFieldHelpText('UstRegelset.steuerpflicht')} />
              <FormItemWithFieldHelp
                name={ustRegelsetFormFields.optionSteuerpflicht}
                label="Option zur Steuerpflicht"
                fieldHelp={getFieldHelpText('UstRegelset.optionSteuerpflicht')}
              >
                <Space>
                  <Switch name={ustRegelsetFormFields.optionSteuerpflicht} size="small" />
                  <Typography.Text>{formikProps.values.optionSteuerpflicht ? 'Ja' : 'Nein'}</Typography.Text>
                </Space>
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp
                name={ustRegelsetFormFields.leerstehung}
                label="Leerstehung"
                fieldHelp={getFieldHelpText('UstRegelset.leerstehung')}
              >
                <Space>
                  <Switch name={ustRegelsetFormFields.leerstehung} size="small" />
                  <Typography.Text>{formikProps.values.leerstehung ? 'Ja' : 'Nein'}</Typography.Text>
                </Space>
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp
                name={ustRegelsetFormFields.eigennutzung}
                label="Eigennutzung"
                fieldHelp={getFieldHelpText('UstRegelset.eigennutzung')}
              >
                <Space>
                  <Switch name={ustRegelsetFormFields.eigennutzung} size="small" />
                  <Typography.Text>{formikProps.values.eigennutzung ? 'Ja' : 'Nein'}</Typography.Text>
                </Space>
              </FormItemWithFieldHelp>
            </Col>
          </Row>

          {!isUpdate && (
            <>
              <Divider />
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Ust.-Kategorie</Typography.Title>
                </Col>
                <Col span={18}>
                  <FormItemWithFieldHelp
                    name={ustRegelsetFormFields.validFrom}
                    label="Gültig ab"
                    fieldHelp={getFieldHelpText('UstRegelset.validFrom')}
                  >
                    <DatePicker
                      size="small"
                      id={ustRegelsetFormFields.validFrom}
                      name={ustRegelsetFormFields.validFrom}
                      style={{ width: '100%' }}
                      format={DATE_DISPLAY_FORMAT_DEFAULT}
                    />
                  </FormItemWithFieldHelp>

                  <UstKategorieTemplateSelect
                    name={ustRegelsetFormFields.ustKategorieId}
                    fieldHelp={getFieldHelpText('UstRegelset.ustKategorieId')}
                  />
                </Col>
              </Row>
            </>
          )}

          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default UstRegelsetTemplateForm;
