import React, { FC } from 'react';
import { Select } from 'formik-antd';
import { useCountryCodeListQuery } from './gql/CountryCodeQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

type Props = {
  disabled?: boolean;
  name: string;
  fieldHelp?: string | null;
};

const CountryCodeSelect: FC<Props> = ({ disabled, name, fieldHelp }) => {
  const { loading, data: maybeCountryCodeList } = useCountryCodeListQuery();

  const countryCodeList = maybeCountryCodeList?.getCountryCodeList.data ?? [];

  return (
    <FormItemWithFieldHelp name={name} label="Land" fieldHelp={fieldHelp}>
      <Select
        disabled={disabled}
        id={name}
        name={name}
        loading={loading}
        placeholder="Wählen Sie bitte Ihr Land aus"
        allowClear
        showSearch
        filterOption={selectFilterOption}
      >
        {countryCodeList.map((item) => (
          <Select.Option key={item.alpha2} value={item.alpha2}>
            {item.bezeichnung}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithFieldHelp>
  );
};

export default CountryCodeSelect;
