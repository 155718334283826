import React, { FC } from 'react';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import NutzflaecheVersionCardContent from './NutzflaecheVersionCardContent';
import NutzflaecheVersionForm from './Form/NutzflaecheVersionForm';
import { mapToVersionTimelineDataSourceWithAlwaysDeletableEntries } from '../../../../components/Timeline/versionTimelineHelper';
import { BENutzflaecheVersionTimelineDataSourceEntry } from './nutzflaecheVersionTimelineMapper';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useBestandseinheitNutzflaecheVersionListQuery } from './gql/NutzflaecheVersionQueries.types';
import { useDeleteBestandseinheitNutzflaecheVersionMutation } from './gql/NutzflaecheVersionMutations.types';
import { gqlOperations } from '../../../../graphql/gqlNamedOperations-generated';

type NutzflaecheVersionTimelineProps = {
  objektId: string;
  bestandseinheitId: string;
  reloadBestandseinheit: () => void;
};

const NutzflaecheVersionTimeline: FC<NutzflaecheVersionTimelineProps> = ({ objektId, bestandseinheitId, reloadBestandseinheit }) => {
  const {
    data,
    loading,
    refetch: reloadVersionList,
  } = useBestandseinheitNutzflaecheVersionListQuery({
    variables: {
      objektId,
      bestandseinheitId,
    },
  });
  const versionList = data?.getBestandseinheitNutzflaecheVersionList?.data ?? [];

  const onActionSuccess = () => {
    reloadBestandseinheit();
    reloadVersionList();
  };

  const [runDelete] = useDeleteBestandseinheitNutzflaecheVersionMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Nutzfläche`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<BENutzflaecheVersionTimelineDataSourceEntry>
      dataSource={mapToVersionTimelineDataSourceWithAlwaysDeletableEntries(versionList)}
      versionIdKey="beNutzflaecheId"
      loading={loading}
      defaultNewTimeblock={{
        beNutzflaecheId: '',
        nutzflaeche: 0,
        warningList: [],
      }}
      renderForm={(timeblock) => (
        <NutzflaecheVersionForm timeblock={timeblock} objektId={objektId} bestandseinheitId={bestandseinheitId} onSubmitSuccess={onActionSuccess} />
      )}
      isAllowedToDeleteInitialTimeblock
      onDelete={(timeblock) => {
        return runDelete({
          variables: {
            objektId,
            bestandseinheitId,
            nutzflaecheVersionId: timeblock.beNutzflaecheId,
          },
          refetchQueries: () => [
            gqlOperations.Query.BestandseinheitAufteilungsschluesselList,
            gqlOperations.Query.BestandseinheitAufteilungsschluesselWertAenderungList,
          ],
        });
      }}
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      renderCardContent={(timeblock) => <NutzflaecheVersionCardContent timeblock={timeblock} />}
    />
  );
};

export default NutzflaecheVersionTimeline;
