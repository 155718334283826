import React, { FC } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../hooks/useToggle';
import VersandDrawer from './Drawer/VersandDrawer';

type VersandErstellenBtnAndDrawerProps = {
  onSuccess: () => void;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
};

const VersandErstellenBtnAndDrawer: FC<VersandErstellenBtnAndDrawerProps> = ({ onSuccess, bestandseinheitId, vertragId, objektId }) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();

  return (
    <>
      <Button type="primary" size="middle" icon={<PlusOutlined />} onClick={toggleDrawerOpen}>
        Kopie an
      </Button>
      <VersandDrawer
        title="Dokumentenversand anlegen"
        isDrawerOpen={isDrawerOpen}
        toggleDrawerOpen={toggleDrawerOpen}
        onSuccess={onSuccess}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
      />
    </>
  );
};

export default VersandErstellenBtnAndDrawer;
