import React, { FC, useEffect } from 'react';
import { Empty, PaginationProps } from 'antd';
import objektAbrechnungTableColumns from './objektAbrechnungTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { AbrechnungListEntry, Objekt } from '../../../../types';
import { HandleTableSorting } from '../../../../shared/typeHelpers';

type Props = {
  abrechnungList?: AbrechnungListEntry[];
  objekt: Objekt;
  isRefetchingObjekt: boolean;
  refetch: () => void;
  handleTableSorting: HandleTableSorting;
  pagination: PaginationProps;
};

const ObjektAbrechnungTable: FC<Props> = ({ abrechnungList, objekt, isRefetchingObjekt, pagination, handleTableSorting, refetch }) => {
  useEffect(() => {
    if (isRefetchingObjekt) refetch();
  }, [isRefetchingObjekt, refetch]);

  return (
    <TableWithColSelector<AbrechnungListEntry>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Abrechnungen vorhanden</span>} />,
      }}
      scroll={{ x: 900 }}
      loading={!abrechnungList}
      pagination={pagination}
      columns={objektAbrechnungTableColumns(objekt.objektId)}
      dataSource={abrechnungList}
      onChange={handleTableSorting}
      rowKey={(record) => record.abrechnungId}
      filterIdentifier="we-objekt-abrechnung"
    />
  );
};

export default ObjektAbrechnungTable;
