import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import { HandleTableSorting } from '../../../../../shared/typeHelpers';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import { IbanListEntry } from '../../../../../types';
import ibanFibuKontoTableColumns from './ibanFibuKontoTableColumns';

type Props = {
  loading: boolean;
  ibanList: IbanListEntry[];
  pagination: PaginationProps;
  handleTableSorting: HandleTableSorting;
};

const IbanFibuKontoTable: FC<Props> = ({ loading, ibanList, pagination, handleTableSorting }) => {
  return (
    <NestedTable<IbanListEntry>
      loading={loading}
      colWidth={150}
      locale={{
        emptyText: <Empty description={<span>Keine Iban vorhanden</span>} />,
      }}
      dataSource={ibanList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={ibanFibuKontoTableColumns()}
      expandable={{ rowExpandable: () => false }}
      rowKey={(record) => `${record.accountHolder} - ${record.iban}`}
    />
  );
};

export default IbanFibuKontoTable;
