import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { getRechtstraegerInfoFeldShortName } from '../../Rechtstraeger/InfoFeld/List/rechtstraegerInfoFeldListHelpers';
import { TTicket } from '../ticketHelpers';
import { pathsToRechtstraegerDetails } from '../../Rechtstraeger/rechtstraegerHelper';
import RechtstraegerWithContactsTooltip from '../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

type Props = {
  ticket: TTicket;
};

const TicketRechtstraegerInfoRows: FC<Props> = ({ ticket }) => {
  const { rechtstraeger, rechtstraegerInfoFeld } = ticket;

  return (
    <>
      {rechtstraeger && (
        <CardDetailsInfoRow infoRowTitle="Firma/Person">
          <RechtstraegerWithContactsTooltip
            rechtstraegerId={rechtstraeger.rechtstraegerId}
            rechtstraegerName={rechtstraeger.kurzBezeichnung}
            maxTextLength={25}
          />
        </CardDetailsInfoRow>
      )}

      {rechtstraeger && rechtstraegerInfoFeld && (
        <CardDetailsInfoRow infoRowTitle={rechtstraegerInfoFeld.type.text}>
          <DataWithShortenedText maxTextLength={25} text={getRechtstraegerInfoFeldShortName(rechtstraegerInfoFeld)}>
            {(shortenedText) => (
              <Link target="_blank" to={pathsToRechtstraegerDetails(rechtstraeger.rechtstraegerId).infoFeldMenu}>
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        </CardDetailsInfoRow>
      )}
    </>
  );
};

export default TicketRechtstraegerInfoRows;
