import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Radio } from 'formik-antd';
import { objAbrDefGeneralSettingsFormFields, ObjAbrDefGeneralSettingsFormValues } from './objAbrDefGeneralSettingsFormMapper';
import ParamFormItem from '../../../../../../../components/Form/ParamFormItem';
import { ConsolidateEinnahmenType } from '../../../../../../../types';

type Props = {
  formikProps: FormikProps<ObjAbrDefGeneralSettingsFormValues>;
  isDisabled: boolean;
  onChange?: (formValues: ObjAbrDefGeneralSettingsFormValues) => void;
  fieldHelp?: string | null;
};

const ConsolidateEinnahmenFormItem: FC<Props> = ({ formikProps, isDisabled, onChange, fieldHelp }) => (
  <ParamFormItem
    name={objAbrDefGeneralSettingsFormFields.consolidateEinnahmen}
    label="Einnahmen zusammenfassen"
    marginBottom="16px"
    fieldHelp={fieldHelp}
  >
    <Radio.Group
      name={objAbrDefGeneralSettingsFormFields.consolidateEinnahmen}
      disabled={!formikProps.values.displayEinnahmen || isDisabled}
      onChange={(value) => onChange?.({ ...formikProps.values, consolidateEinnahmen: value.target.value })}
      block
    >
      <Radio name={objAbrDefGeneralSettingsFormFields.consolidateEinnahmen} value={ConsolidateEinnahmenType.MonthlyTotal}>
        Monatssumme
      </Radio>
      <Radio name={objAbrDefGeneralSettingsFormFields.consolidateEinnahmen} value={ConsolidateEinnahmenType.AnnualTotal}>
        Jahressumme
      </Radio>
    </Radio.Group>
  </ParamFormItem>
);

export default ConsolidateEinnahmenFormItem;
