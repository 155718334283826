import { Modal } from 'antd';

export const showConfirmIncomingInvoiceDelete = (runDelete: () => void) => {
  Modal.confirm({
    title: `Möchten Sie die Eingangsrechnung löschen?`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};
