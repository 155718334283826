import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useLegalFormListQuery } from './gql/LegalFormQueries.types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

type Props = {
  fieldHelp?: string | null;
} & Omit<SelectProps, 'name' | 'id' | 'placeholder'>;

const LegalFormSelect: FC<Props> = ({ fieldHelp, ...restProps }) => {
  const { loading, data } = useLegalFormListQuery();
  const legalFormList = data?.getLegalFormList.data ?? [];

  return (
    <FormItemWithFieldHelp name="legalForm" label="Rechtsform" fieldHelp={fieldHelp}>
      <Select {...restProps} id="legalForm" name="legalForm" loading={loading} placeholder="Art der Rechtsform auswählen">
        {legalFormList.map((legalForm) => (
          <Select.Option key={legalForm.value} value={legalForm.value}>
            {legalForm.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithFieldHelp>
  );
};

export default LegalFormSelect;
