import { generatePath } from 'react-router';
import { uri } from '../../constants/appUriPaths';
import { mapToQueryString, QueryParams } from '../../hooks/useStringQueryParams';
import { isNotNil } from '../../helpers/assertionHelper';

export const URI_DASHBOARD_BOOKING_DETAILS_PAGE = `${uri.dashboard}/booking-details`;
export const URI_DASHBOARD_BOOKING_DETAILS_WITH_BOOKING_SUGGESTION_PAGE = `${uri.dashboard}/accounting-mask-with-booking-suggestion`;

/** Booking Details Page without Booking Suggestion - for other than Zahlung, Rücklastschrift and Spesen */

export enum BookingDetailsPageQueryParamKey {
  AUFTRAG_ID = 'auftragId',
  BELEG_ID = 'belegId',
  BUCHUNG_ID_LIST = 'buchungIdList',
  INCLUDE_STORNO = 'includeStorno',
}

export type BookingDetailsPageQueryParams = QueryParams<{
  [BookingDetailsPageQueryParamKey.AUFTRAG_ID]: string;
  [BookingDetailsPageQueryParamKey.BELEG_ID]: string;
  [BookingDetailsPageQueryParamKey.BUCHUNG_ID_LIST]: string[];
  [BookingDetailsPageQueryParamKey.INCLUDE_STORNO]?: boolean;
}>;

export const generatePathToBookingDetailsPage = (buchungIdList: string[], auftragId?: string, belegId?: string, includeStorno?: boolean) =>
  `${generatePath(URI_DASHBOARD_BOOKING_DETAILS_PAGE)}?${mapToQueryString({
    [BookingDetailsPageQueryParamKey.BUCHUNG_ID_LIST]: buchungIdList,
    [BookingDetailsPageQueryParamKey.AUFTRAG_ID]: auftragId,
    [BookingDetailsPageQueryParamKey.BELEG_ID]: belegId,
    [BookingDetailsPageQueryParamKey.INCLUDE_STORNO]: includeStorno,
  })}`;

/** Booking Details Page with Booking Suggestion - for Zahlung, Rücklastschrift and Spesen */
export enum BookingDetailsWithBookingSuggestionPageQueryParamKey {
  BOOKING_SUGGESTION_ID = 'bookingSuggestionId',
  BUCHUNG_ID_LIST = 'buchungIdList',
  SHOW_CORRECTION_WARNING = 'showCorrectionWarning', // if true and booking is not current, it will show the warning message that the booking is or was corrected
}

export type BookingDetailsWithBookingSuggestionPageQueryParams = QueryParams<{
  [BookingDetailsWithBookingSuggestionPageQueryParamKey.BOOKING_SUGGESTION_ID]: string;
  [BookingDetailsWithBookingSuggestionPageQueryParamKey.BUCHUNG_ID_LIST]: string[];
  [BookingDetailsWithBookingSuggestionPageQueryParamKey.SHOW_CORRECTION_WARNING]?: boolean;
}>;

export const generatePathToBookingDetailsWithBookingSuggestionPage = (
  bookingSuggestionId: string,
  buchungIdList: string[],
  showCorrectionWarning?: boolean
) =>
  `${generatePath(URI_DASHBOARD_BOOKING_DETAILS_WITH_BOOKING_SUGGESTION_PAGE)}?${mapToQueryString({
    [BookingDetailsWithBookingSuggestionPageQueryParamKey.BUCHUNG_ID_LIST]: buchungIdList,
    [BookingDetailsWithBookingSuggestionPageQueryParamKey.BOOKING_SUGGESTION_ID]: bookingSuggestionId,
    [BookingDetailsWithBookingSuggestionPageQueryParamKey.SHOW_CORRECTION_WARNING]: isNotNil(showCorrectionWarning) ? showCorrectionWarning : true,
  })}`;
