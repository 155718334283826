import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import { BestandseinheitBaseFieldsFragmentDoc } from '../../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { ObjektBaseFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuftragGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type AuftragGenerierlaufQuery = {
  getAuftragGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      labelList: Array<{ label: string; value: string }>;
      status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  vorschreibungsperiode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ausstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  empfaengerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.AuftragGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type AuftragGenerierlaufEntryListQuery = {
  getAuftragGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        auftragCreateTs?: string | null;
        auftragCreatedBy?: string | null;
        auftragCreatedByMitarbeiterId?: string | null;
        auftragIdList: Array<string>;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        sumBrutto?: number | null;
        sumNetto?: number | null;
        sumUst?: number | null;
        vertragId?: string | null;
        aussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        empfaenger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        fakturierungsperiode: { jahr: number; monat: number };
        objekt: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          updatedByMitarbeiterId?: string | null;
          rechnungsAussteller: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          status: { text: string; value: Types.ObjektStatus; description?: string | null };
          verwaltungsart: { text: string; value: Types.Verwaltungsart };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AuftragGenerierlaufDocument = gql`
  query AuftragGenerierlauf($generierlaufId: ID!) {
    getAuftragGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countVerarbeitet
        countNichtVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        labelList {
          label
          value
        }
        status {
          description
          value
          text
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAuftragGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragGenerierlaufQuery, AuftragGenerierlaufQueryVariables> &
    ({ variables: AuftragGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragGenerierlaufQuery, AuftragGenerierlaufQueryVariables>(AuftragGenerierlaufDocument, options);
}
export function useAuftragGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragGenerierlaufQuery, AuftragGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragGenerierlaufQuery, AuftragGenerierlaufQueryVariables>(AuftragGenerierlaufDocument, options);
}
export function useAuftragGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragGenerierlaufQuery, AuftragGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragGenerierlaufQuery, AuftragGenerierlaufQueryVariables>(AuftragGenerierlaufDocument, options);
}
export type AuftragGenerierlaufQueryHookResult = ReturnType<typeof useAuftragGenerierlaufQuery>;
export type AuftragGenerierlaufLazyQueryHookResult = ReturnType<typeof useAuftragGenerierlaufLazyQuery>;
export type AuftragGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useAuftragGenerierlaufSuspenseQuery>;
export type AuftragGenerierlaufQueryResult = Apollo.QueryResult<AuftragGenerierlaufQuery, AuftragGenerierlaufQueryVariables>;
export const AuftragGenerierlaufEntryListDocument = gql`
  query AuftragGenerierlaufEntryList(
    $generierlaufId: ID!
    $vorschreibungsperiode: String
    $objektId: String
    $ausstellerId: String
    $empfaengerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: AuftragGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getAuftragGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      vorschreibungsperiode: $vorschreibungsperiode
      objektId: $objektId
      ausstellerId: $ausstellerId
      empfaengerId: $empfaengerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          auftragCreateTs
          auftragCreatedBy
          auftragCreatedByMitarbeiterId
          auftragIdList
          aussteller {
            ...RechtstraegerBaseFields
          }
          bestandseinheit {
            ...BestandseinheitBaseFields
          }
          empfaenger {
            ...RechtstraegerBaseFields
          }
          errorList {
            message
            type
          }
          exitCode {
            value
            text
          }
          fakturierungsperiode {
            jahr
            monat
          }
          generierlaufEntryId
          infoMessage
          objekt {
            ...ObjektBase
          }
          sumBrutto
          sumNetto
          sumUst
          vertragId
          warningList {
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
  ${BestandseinheitBaseFieldsFragmentDoc}
  ${ObjektBaseFragmentDoc}
`;
export function useAuftragGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragGenerierlaufEntryListQuery, AuftragGenerierlaufEntryListQueryVariables> &
    ({ variables: AuftragGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragGenerierlaufEntryListQuery, AuftragGenerierlaufEntryListQueryVariables>(
    AuftragGenerierlaufEntryListDocument,
    options
  );
}
export function useAuftragGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragGenerierlaufEntryListQuery, AuftragGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragGenerierlaufEntryListQuery, AuftragGenerierlaufEntryListQueryVariables>(
    AuftragGenerierlaufEntryListDocument,
    options
  );
}
export function useAuftragGenerierlaufEntryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragGenerierlaufEntryListQuery, AuftragGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragGenerierlaufEntryListQuery, AuftragGenerierlaufEntryListQueryVariables>(
    AuftragGenerierlaufEntryListDocument,
    options
  );
}
export type AuftragGenerierlaufEntryListQueryHookResult = ReturnType<typeof useAuftragGenerierlaufEntryListQuery>;
export type AuftragGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useAuftragGenerierlaufEntryListLazyQuery>;
export type AuftragGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<typeof useAuftragGenerierlaufEntryListSuspenseQuery>;
export type AuftragGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  AuftragGenerierlaufEntryListQuery,
  AuftragGenerierlaufEntryListQueryVariables
>;
