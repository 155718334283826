import React, { FC } from 'react';
import { Layout, Row, Col, Spin } from 'antd';
import { useParams } from 'react-router';
import MitarbeiterCard from '../../../features/Mitarbeiter/MitarbeiterCard';
import MitarbeiterStateActions from '../../../features/Mitarbeiter/MitarbeiterStateActions';
import { useMitarbeiterByFirmendatenIdQuery } from '../../../features/Mitarbeiter/gql/MitarbeiterQueries.types';
import { MitarbeiterPageRouteParams } from './routes';

const VerwaltungMitarbeiterDetailsPage: FC = () => {
  const { firmendatenId, mitarbeiterId } = useParams() as MitarbeiterPageRouteParams;

  const { data, refetch } = useMitarbeiterByFirmendatenIdQuery({
    variables: {
      firmendatenId,
      mitarbeiterId,
      includeUser: true,
    },
  });
  const mitarbeiter = data?.getMitarbeiterDetailsByFirmendatenId.data;
  const loading = !mitarbeiter;

  if (loading) return <Spin />;

  return (
    <>
      <MitarbeiterStateActions firmendatenId={firmendatenId} mitarbeiter={mitarbeiter} onActionSuccess={refetch} />
      <Layout.Content style={{ margin: '16px 0px' }}>
        <Row gutter={16}>
          <Col span={12}>
            <MitarbeiterCard mitarbeiter={mitarbeiter} onSuccess={refetch} firmendatenId={firmendatenId} />
          </Col>
          <Col span={12} />
        </Row>
      </Layout.Content>
    </>
  );
};

export default VerwaltungMitarbeiterDetailsPage;
