import React, { FC } from 'react';
import { Button } from 'antd';
import useVVPosUstKategorieEntryWarningAndLoading from '../useVVPosUstKategorieEntryWarningAndLoading';

type Props = {
  cancelEdit: () => void;
  isSubmitLoading: boolean;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  vorschreibungspositionId: string;
};

const ActionButtons: FC<Props> = ({ cancelEdit, isSubmitLoading, objektId, bestandseinheitId, vertragId, vorschreibungspositionId }) => {
  const { vpNoUstKategorieEntryWarning, loadingVPUstKategorieEntry } = useVVPosUstKategorieEntryWarningAndLoading(
    objektId,
    bestandseinheitId,
    vertragId,
    vorschreibungspositionId
  );

  return (
    <span>
      <Button htmlType="submit" type="link" loading={isSubmitLoading || loadingVPUstKategorieEntry} disabled={!!vpNoUstKategorieEntryWarning}>
        Speichern
      </Button>
      <Button size="small" type="link" onClick={cancelEdit}>
        Abbrechen
      </Button>
    </span>
  );
};

export default ActionButtons;
