import { IncomingInvoiceUpdateStornoInput, InvoiceType, SingleInvoiceCreateInput, SingleInvoiceUpdateInput } from '../../../types';
import { calculateBruttoNettoUst } from '../incomingInvoiceHelpers';
import { mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import {
  IncomingInvoiceFormValues,
  mapFormValuesForSharedValuesToInvoice,
  mapFormValuesToBuchungPositionCreate,
  mapFormValuesToBuchungPositionUpdate,
} from './incomingInvoiceFormMapper';
import { StornoFormValues } from './SingleInvoiceStornoModal/StornoForm/stornoFormMapper';

const mapFormValuesToSingleInvoice = (values: IncomingInvoiceFormValues) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { brutto, netto, steuerzeileList } = calculateBruttoNettoUst(values.buchungPositionList!, values.inklusiveSteuer);

  return {
    ...mapFormValuesForSharedValuesToInvoice(values),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    abgrenzungsBuchung: values.abgrenzungsBuchung!,
    abgrenzungsBuchungsdatum: values.abgrenzungsBuchungsdatum && mapFormDateValueToDateString(values.abgrenzungsBuchungsdatum),
    brutto,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    buchungsdatum: mapFormDateValueToDateString(values.buchungsdatum!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dueDate: mapFormDateValueToDateString(values.dueDate!),
    netto,
    requestedPaymentDate: values.requestedPaymentDate && mapFormDateValueToDateString(values.requestedPaymentDate),
    steuerzeileList,
    type: InvoiceType.SingleInvoice,
  };
};

export const mapFormValuesToSingleInvoiceCreate = (values: IncomingInvoiceFormValues): SingleInvoiceCreateInput => {
  return {
    ...mapFormValuesToSingleInvoice(values),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    billingType: values.billingType!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    buchungPositionList: values.buchungPositionList!.map(mapFormValuesToBuchungPositionCreate),
  };
};

export const mapFormValuesToSingleInvoiceUpdate = (
  values: IncomingInvoiceFormValues,
  stornoFormValues?: StornoFormValues
): SingleInvoiceUpdateInput => {
  const singleInvoice = mapFormValuesToSingleInvoice(values);

  if (!stornoFormValues) {
    return {
      ...singleInvoice,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      buchungPositionList: values.buchungPositionList!.map(mapFormValuesToBuchungPositionUpdate),
    };
  }

  const storno: IncomingInvoiceUpdateStornoInput = {
    abgrenzungsBuchung: stornoFormValues.abgrenzungsBuchung,
    ...(!stornoFormValues.abgrenzungsBuchung
      ? {
          stornoDirekt: {
            stornoBuchungsdatum: mapFormDateValueToDateString(stornoFormValues.stornoDirekt.stornoBuchungsdatum),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            korrekturBuchungsdatum: mapFormDateValueToDateString(stornoFormValues.stornoDirekt.korrekturBuchungsdatum!),
          },
        }
      : {
          stornoAbgrenzung: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            stornoBuchungsdatum: mapFormDateValueToDateString(stornoFormValues.stornoAbgrenzung.stornoBuchungsdatum!),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            korrekturBuchungsdatum: mapFormDateValueToDateString(stornoFormValues.stornoAbgrenzung.korrekturBuchungsdatum!),
            stornoAbgrenzungsBuchungsdatum: mapFormDateValueToDateString(stornoFormValues.stornoAbgrenzung.stornoAbgrenzungsBuchungsdatum),
            korrekturAbgrenzungsBuchungsdatum: mapFormDateValueToDateString(stornoFormValues.stornoAbgrenzung.korrekturAbgrenzungsBuchungsdatum),
          },
        }),
  };

  return {
    ...singleInvoice,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    buchungPositionList: values.buchungPositionList!.map(mapFormValuesToBuchungPositionUpdate),
    storno,
  };
};
