import React, { FC } from 'react';
import { PaymentKonto, SollHaben } from '../../../../../types';
import KontoSaldo from './KontoSaldo';
import {
  StyledSpaceForCardContent,
  StyledSpaceForCardContentAndIcon,
} from '../../../../BookingDetails/View/shared/KontoDetailsCard/KontoDetailsCardStyleHelpers';
import KontoDetailsCardIcon from '../../../../BookingDetails/View/shared/KontoDetailsCard/KontoDetailsCardIcon';
import KontoDetails from '../../../../BookingDetails/View/shared/KontoDetailsCard/KontoDetails';

type Props = {
  konto: PaymentKonto;
  kontoType: SollHaben;
  objektId?: string;
};

const KontoDetailsCard: FC<Props> = ({ konto, kontoType, objektId }) => {
  return (
    <StyledSpaceForCardContentAndIcon>
      <KontoDetailsCardIcon konto={konto} />
      <StyledSpaceForCardContent>
        <KontoDetails kontoType={kontoType} konto={konto} />
        <KontoSaldo kontoId={konto.kontoId} objektId={objektId} />
      </StyledSpaceForCardContent>
    </StyledSpaceForCardContentAndIcon>
  );
};

export default KontoDetailsCard;
