import {
  kurzBezeichnungFormPartInitialValues,
  mapKurzBezeichnungToFormValues,
} from '../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartMapper';
import { Vorschreibungsposition, VorschreibungspositionCreateInput, VorschreibungspositionUpdateInput } from '../../../types';
import { FormFields } from '../../../helpers/formikHelper';

export interface VorschreibungspositionFormValues {
  kurzBezeichnung: string;
  bezeichnung: string;
  art: string;
  tagList: string[];
  firmendatenId: string;
  versionValidFrom?: string | null; // can be undefined because does not appear on updateForm, can be null because not initialized on createForm
  umsatzsteuerkennzeichenId?: string | null; // can be undefined because does not appear on updateForm, can be null because not initialized on createForm
  kontierungstabelleId?: string | null; // can be undefined because does not appear on updateForm, can be null because not initialized on createForm
}

export const vorschreibungspositionFormFields: FormFields<VorschreibungspositionFormValues> = {
  kurzBezeichnung: 'kurzBezeichnung',
  bezeichnung: 'bezeichnung',
  art: 'art',
  tagList: 'tagList',
  firmendatenId: 'firmendatenId',
  versionValidFrom: 'versionValidFrom',
  umsatzsteuerkennzeichenId: 'umsatzsteuerkennzeichenId',
  kontierungstabelleId: 'kontierungstabelleId',
};

export const vorschreibungspositionFormInitialValues: VorschreibungspositionFormValues = {
  ...kurzBezeichnungFormPartInitialValues(),
  bezeichnung: '',
  art: '',
  tagList: [],
  firmendatenId: '',
  umsatzsteuerkennzeichenId: null,
  kontierungstabelleId: null,
  versionValidFrom: null,
};

export const mapVorschreibungspositionToFormValues = (vorschreibungsPosition?: Vorschreibungsposition): VorschreibungspositionFormValues => {
  if (!vorschreibungsPosition) {
    return vorschreibungspositionFormInitialValues;
  }
  return {
    ...mapKurzBezeichnungToFormValues(vorschreibungsPosition.kurzBezeichnung),
    bezeichnung: vorschreibungsPosition.bezeichnung,
    art: vorschreibungsPosition.art.value,
    tagList: vorschreibungsPosition.tagList,
    firmendatenId: vorschreibungsPosition.firmendatenId ?? '',
  };
};

export const mapFormValuesToVorschreibungspositionCreate = (values: VorschreibungspositionFormValues): VorschreibungspositionCreateInput => {
  let vpToCreate: VorschreibungspositionCreateInput = {
    kurzBezeichnung: values.kurzBezeichnung,
    bezeichnung: values.bezeichnung,
    art: values.art,
    tagList: values.tagList,
  };

  const isVersionProvided = !!values.versionValidFrom || !!values.umsatzsteuerkennzeichenId || !!values.kontierungstabelleId;

  if (isVersionProvided) {
    vpToCreate = {
      ...vpToCreate,
      version: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        validFrom: values.versionValidFrom!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        kontierungstabelleId: values.kontierungstabelleId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        umsatzsteuerkennzeichenId: values.umsatzsteuerkennzeichenId!,
      },
    };
  }

  return vpToCreate;
};

export const mapFormValuesToVorschreibungspositionUpdate = (values: VorschreibungspositionFormValues): VorschreibungspositionUpdateInput => ({
  kurzBezeichnung: values.kurzBezeichnung,
  bezeichnung: values.bezeichnung,
  art: values.art,
  tagList: values.tagList,
});
