import { buildBelegTexteHauptAddress } from '../Belegtext/Form/belegTexteFormHelpers';
import { OperatingSite } from '../../types';

export const findBelegTexteHauptAddress = (operatingSiteList: OperatingSite[]) => {
  const operatingSiteWithHauptAddress = operatingSiteList.find((betriebstaette) => betriebstaette.addressesAndContacts.hauptAddress);
  return operatingSiteWithHauptAddress ? buildBelegTexteHauptAddress(operatingSiteWithHauptAddress.addressesAndContacts.hauptAddress) : undefined;
};

export const findBelegTextePhoneNumber = (operatingSiteList: OperatingSite[]) => {
  const operatingSiteWithHauptPhoneNumber = operatingSiteList.find((betriebstaette) => betriebstaette.addressesAndContacts.hauptEmailContact);
  return operatingSiteWithHauptPhoneNumber?.addressesAndContacts.hauptPhoneContact?.wert;
};

export const findBelegTexteHauptEmail = (operatingSiteList: OperatingSite[]) => {
  const operatingSiteWithHauptEmail = operatingSiteList.find((betriebstaette) => betriebstaette.addressesAndContacts.hauptEmailContact);
  return operatingSiteWithHauptEmail?.addressesAndContacts.hauptEmailContact?.wert;
};
