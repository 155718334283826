import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteHeAbrechnungMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  heAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type DeleteHeAbrechnungMutation = {
  deleteHeAbrechnung: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type HeAbrechnungMarkUnderReviewMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  heAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type HeAbrechnungMarkUnderReviewMutation = {
  actionHeAbrechnungMarkUnderReview: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const DeleteHeAbrechnungDocument = gql`
  mutation DeleteHeAbrechnung($objektId: ID!, $heAbrechnungId: ID!) {
    deleteHeAbrechnung(objektId: $objektId, heAbrechnungId: $heAbrechnungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteHeAbrechnungMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteHeAbrechnungMutation, DeleteHeAbrechnungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteHeAbrechnungMutation, DeleteHeAbrechnungMutationVariables>(DeleteHeAbrechnungDocument, options);
}
export type DeleteHeAbrechnungMutationHookResult = ReturnType<typeof useDeleteHeAbrechnungMutation>;
export type DeleteHeAbrechnungMutationResult = Apollo.MutationResult<DeleteHeAbrechnungMutation>;
export type DeleteHeAbrechnungMutationOptions = Apollo.BaseMutationOptions<DeleteHeAbrechnungMutation, DeleteHeAbrechnungMutationVariables>;
export const HeAbrechnungMarkUnderReviewDocument = gql`
  mutation HeAbrechnungMarkUnderReview($objektId: ID!, $heAbrechnungId: ID!) {
    actionHeAbrechnungMarkUnderReview(objektId: $objektId, heAbrechnungId: $heAbrechnungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useHeAbrechnungMarkUnderReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<HeAbrechnungMarkUnderReviewMutation, HeAbrechnungMarkUnderReviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HeAbrechnungMarkUnderReviewMutation, HeAbrechnungMarkUnderReviewMutationVariables>(
    HeAbrechnungMarkUnderReviewDocument,
    options
  );
}
export type HeAbrechnungMarkUnderReviewMutationHookResult = ReturnType<typeof useHeAbrechnungMarkUnderReviewMutation>;
export type HeAbrechnungMarkUnderReviewMutationResult = Apollo.MutationResult<HeAbrechnungMarkUnderReviewMutation>;
export type HeAbrechnungMarkUnderReviewMutationOptions = Apollo.BaseMutationOptions<
  HeAbrechnungMarkUnderReviewMutation,
  HeAbrechnungMarkUnderReviewMutationVariables
>;
