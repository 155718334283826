import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VertragsartListQueryVariables = Types.Exact<{
  firmendatenId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  bestandseinheitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  verwaltungsart?: Types.InputMaybe<Types.Verwaltungsart>;
}>;

export type VertragsartListQuery = {
  getVertragsartList: {
    data: Array<{ supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VertragsartListDocument = gql`
  query VertragsartList($firmendatenId: ID, $objektId: ID, $bestandseinheitId: ID, $vertragId: ID, $verwaltungsart: Verwaltungsart) {
    getVertragsartList(
      firmendatenId: $firmendatenId
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      verwaltungsart: $verwaltungsart
    ) {
      data {
        supportsExplicitVertragsEnde
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVertragsartListQuery(baseOptions?: Apollo.QueryHookOptions<VertragsartListQuery, VertragsartListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragsartListQuery, VertragsartListQueryVariables>(VertragsartListDocument, options);
}
export function useVertragsartListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VertragsartListQuery, VertragsartListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragsartListQuery, VertragsartListQueryVariables>(VertragsartListDocument, options);
}
export function useVertragsartListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VertragsartListQuery, VertragsartListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragsartListQuery, VertragsartListQueryVariables>(VertragsartListDocument, options);
}
export type VertragsartListQueryHookResult = ReturnType<typeof useVertragsartListQuery>;
export type VertragsartListLazyQueryHookResult = ReturnType<typeof useVertragsartListLazyQuery>;
export type VertragsartListSuspenseQueryHookResult = ReturnType<typeof useVertragsartListSuspenseQuery>;
export type VertragsartListQueryResult = Apollo.QueryResult<VertragsartListQuery, VertragsartListQueryVariables>;
