import { FormFields } from '../../../../helpers/formikHelper';
import { NotificationType } from '../../../../types';

export type NotificationListingFiltersFormValues = {
  type?: NotificationType;
  createTsBisInklusive?: string;
  createTsVonInklusive?: string;
};

export const notificationListingFiltersFormFields: FormFields<NotificationListingFiltersFormValues> = {
  type: 'type',
  createTsBisInklusive: 'createTsBisInklusive',
  createTsVonInklusive: 'createTsVonInklusive',
};
