import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useEmailDeliveryDefinitionTemplateChangeHistoryListQuery } from '../gql/EmailDeliveryDefinitionTemplateQueries.types';

type Props = {
  emailDeliveryDefinitionId: string;
};

const EmailDeliveryDefinitionTemplateChangeHistoryListingTable: FC<Props> = ({ emailDeliveryDefinitionId }) => {
  const { data, loading } = useEmailDeliveryDefinitionTemplateChangeHistoryListQuery({ variables: { emailDeliveryDefinitionId } });
  const historyList = data?.getEmailDeliveryDefinitionTemplateChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="email-delivery-definition-template"
    />
  );
};

export default EmailDeliveryDefinitionTemplateChangeHistoryListingTable;
