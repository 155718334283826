import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';

export type VstKrzApplyDeviationsFormValues = {
  abgrenzungsBuchung: boolean;
  stornoDirekt: {
    stornoBuchungsdatum: string | null;
    korrekturBuchungsdatum: string | null;
  };
  stornoAbgrenzung: {
    stornoBuchungsdatum: string | null;
    stornoAbgrenzungsBuchungsdatum: string | null;
    korrekturBuchungsdatum: string | null;
    korrekturAbgrenzungsBuchungsdatum: string | null;
  };
};

export const vstKrzApplyDeviationsFormFields: FormFields<VstKrzApplyDeviationsFormValues> = {
  abgrenzungsBuchung: 'abgrenzungsBuchung',
  stornoDirekt: 'stornoDirekt',
  stornoAbgrenzung: 'stornoAbgrenzung',
};

export const vstKrzApplyDeviationsFormInitialValues = (lastBuchungsdatum?: string | null): VstKrzApplyDeviationsFormValues => ({
  abgrenzungsBuchung: false,
  stornoDirekt: {
    stornoBuchungsdatum: lastBuchungsdatum ?? null,
    korrekturBuchungsdatum: null,
  },
  stornoAbgrenzung: {
    stornoBuchungsdatum: null,
    stornoAbgrenzungsBuchungsdatum: lastBuchungsdatum ?? null,
    korrekturBuchungsdatum: null,
    korrekturAbgrenzungsBuchungsdatum: lastBuchungsdatum ?? null,
  },
});

export const stornoDirektFields: FormFields<
  Pick<VstKrzApplyDeviationsFormValues['stornoDirekt'], keyof VstKrzApplyDeviationsFormValues['stornoDirekt']>
> = {
  stornoBuchungsdatum: 'stornoBuchungsdatum',
  korrekturBuchungsdatum: 'korrekturBuchungsdatum',
};

export const stornoAbgrenzungFields: FormFields<
  Pick<VstKrzApplyDeviationsFormValues['stornoAbgrenzung'], keyof VstKrzApplyDeviationsFormValues['stornoAbgrenzung']>
> = {
  stornoBuchungsdatum: 'stornoBuchungsdatum',
  korrekturBuchungsdatum: 'korrekturBuchungsdatum',
  stornoAbgrenzungsBuchungsdatum: 'stornoAbgrenzungsBuchungsdatum',
  korrekturAbgrenzungsBuchungsdatum: 'korrekturAbgrenzungsBuchungsdatum',
};

export const mapFormValuesToVstKrzApplyDeviationsForm = (values: VstKrzApplyDeviationsFormValues) => {
  return {
    abgrenzungsBuchung: values.abgrenzungsBuchung,
    ...(!values.abgrenzungsBuchung
      ? {
          stornoDirekt: {
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            stornoBuchungsdatum: mapFormDateValueToDateString(values.stornoDirekt.stornoBuchungsdatum!),
            korrekturBuchungsdatum: mapFormDateValueToDateString(values.stornoDirekt.korrekturBuchungsdatum!),
            /* eslint-enable @typescript-eslint/no-non-null-assertion */
          },
        }
      : {
          stornoAbgrenzung: {
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            stornoBuchungsdatum: mapFormDateValueToDateString(values.stornoAbgrenzung.stornoBuchungsdatum!),
            stornoAbgrenzungsBuchungsdatum: mapFormDateValueToDateString(values.stornoAbgrenzung.stornoAbgrenzungsBuchungsdatum!),
            korrekturBuchungsdatum: mapFormDateValueToDateString(values.stornoAbgrenzung.korrekturBuchungsdatum!),
            korrekturAbgrenzungsBuchungsdatum: mapFormDateValueToDateString(values.stornoAbgrenzung.korrekturAbgrenzungsBuchungsdatum!),
            /* eslint-enable @typescript-eslint/no-non-null-assertion */
          },
        }),
  };
};
