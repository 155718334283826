import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { BuchungEinnahme } from '../../../../objektAbrechnung-types';

const OAAbrKreisBuchungenEinnahme: FC<{ buchungEinname: BuchungEinnahme; isRepFonds?: boolean }> = ({ buchungEinname, isRepFonds = false }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight]}>
    {isRepFonds ? null : <View style={[pdfStyles.column, { width: '55%' }]} />}
    <View style={[pdfStyles.column, { width: '45%' }]}>
      <View style={[pdfStyles.row]}>
        <Text style={[pdfStyles.textBulletinSmall, { width: '80%' }]}>{buchungEinname.bezeichnung}</Text>
        <Text style={[pdfStyles.textBulletinSmall, { width: '20%', alignItems: 'flex-end', textAlign: 'right' }]}>{buchungEinname.betragNetto}</Text>
      </View>
    </View>
    {isRepFonds ? <View style={[pdfStyles.column, { width: '55%' }]} /> : null}
  </View>
);

export default OAAbrKreisBuchungenEinnahme;
