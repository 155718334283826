import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { RepFondsAbrechnungAusgabenKonto } from '../../../../../topAbrechnung-types';
import pdfStyles from '../../../../../../styles/pdfStyles';

const TARepFondsAusgabenWithoutAusgabenList: FC<{ ausgabenKonto: RepFondsAbrechnungAusgabenKonto }> = ({ ausgabenKonto }) => (
  <View style={[pdfStyles.column, pdfStyles.tablePaddingLeftRight]} wrap={false}>
    {/* Konto Bezeichnung und Ausgaben summe */}
    <View style={[pdfStyles.row]}>
      <Text
        style={[
          pdfStyles.column,
          pdfStyles.textBulletinSmall,
          {
            width: '30%',
            textAlign: 'left',
          },
        ]}
      >
        {ausgabenKonto.kontoBezeichnung}
      </Text>
      <Text
        style={[
          pdfStyles.column,
          pdfStyles.textBulletinSmall,
          {
            width: '20%',
            alignItems: 'flex-end',
            textAlign: 'right',
          },
        ]}
      >
        {ausgabenKonto.summeNettoAusgaben.betrag}
      </Text>
    </View>
  </View>
);

export default TARepFondsAusgabenWithoutAusgabenList;
