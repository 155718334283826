import { Select, SelectProps } from 'formik-antd';
import React, { FC, useState } from 'react';
import useDebounce from '../../../../hooks/useDebounce';
import { useFilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery } from '../../gql/BuchungsanweisungQueries.types';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';

type Props = Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'placeholder' | 'size' | 'showSearch'>;

const FibuBaBuchungskreisRechtstraegerSelect: FC<Props> = ({ ...restProps }) => {
  const [kurzBezeichnungText, setKurzBezeichnungText] = useState<string>('');
  const debouncedText = useDebounce(kurzBezeichnungText);

  const { loading, data } = useFilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery({
    variables: {
      kurzBezeichnung: debouncedText as string,
    },
  });

  const rechtstraegerList = data?.getFilterFibuBuchungsanweisungBuchungskreisRechtstraegerList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Buchungskreis auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      onSelect={setKurzBezeichnungText}
      onSearch={(value) => {
        kurzBezeichnungText.length === 1 && !value && setKurzBezeichnungText(''); // Checking the length of the belegnummerText makes the search component editable
        value && setKurzBezeichnungText(value);
      }}
      onClear={() => setKurzBezeichnungText('')}
      searchValue={kurzBezeichnungText}
      notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
    >
      {rechtstraegerList.map((rechtstraeger) => (
        <Select.Option key={rechtstraeger.rechtstraegerId} value={rechtstraeger.rechtstraegerId}>
          {rechtstraeger.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FibuBaBuchungskreisRechtstraegerSelect;
