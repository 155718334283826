import { FormFields } from '../../../../../../helpers/formikHelper';
import { Kuendigungsfrist, KuendigungsfristJeweilsZum, RechtstraegerType } from '../../../../../../types';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';

export interface HausbetreuungCreateFormValues extends InfoFeldFormValues {
  contactPersonId?: string;
  rechtstraegerType?: RechtstraegerType;
  beauftragungsgrund: string;
  beginn?: string;
  ende?: string;
  kuendigungsfrist: Kuendigungsfrist | null;
  kuendigungsfristDauer: number | null;
  kuendigungsfristJeweilsZum?: KuendigungsfristJeweilsZum;
  rechtstraegerId?: string;
  vertragsdaten?: string;
  wertsicherung?: string;
}

export const hausbetreuungCreateFormFields: FormFields<HausbetreuungCreateFormValues> = {
  contactPersonId: 'contactPersonId',
  rechtstraegerType: 'rechtstraegerType',
  beauftragungsgrund: 'beauftragungsgrund',
  beginn: 'beginn',
  ende: 'ende',
  kuendigungsfrist: 'kuendigungsfrist',
  kuendigungsfristDauer: 'kuendigungsfristDauer',
  kuendigungsfristJeweilsZum: 'kuendigungsfristJeweilsZum',
  rechtstraegerId: 'rechtstraegerId',
  vertragsdaten: 'vertragsdaten',
  wertsicherung: 'wertsicherung',
  documentList: 'documentList',
};

export const hausbetreuungCreateFormInitialValues: HausbetreuungCreateFormValues = {
  documentList: [],
  beauftragungsgrund: '',
  kuendigungsfrist: null,
  kuendigungsfristDauer: null,
};
