import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { Flex } from 'rebass';
import { Dayjs } from 'dayjs';
import { Divider, Space, Steps } from 'antd';
import {
  mapTextbausteinListToFormValues,
  TextbausteinFormValues,
  TextbausteinModel,
} from '../../../../../components/Template/PDFTemplates/templateMapper';
import AbrDefFormValidFrom, { formatAbrechnungsdefinitionValidFromValue } from '../../../shared/AbrDefFormValidFrom';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { getAlreadyTakenValidFromDates, getNextAvailableValidFromOrToday, updateCommentOnTemplateChange } from './abrDefVersionCreateFormHelpers';
import AbrDefVersionFormKommentar from '../../../shared/AbrDefVersionFormKommentar';
import {
  AbrDefAbrKreisListingFormValues,
  mapAbrechnungsdefinitionToAbrKreisListFormValues,
} from '../../../shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import { showSuccessMsgCreate } from '../../../../../components/message';
import { URI_SYS_SETTINGS } from '../../../../../constants/configurationUriPaths';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useCreateHeAbrechnungsdefinitionVersionMutation } from '../../../gql/HeAbrDef/HeAbrDefVersionMutations.types';
import AbrDefAbrKreisListingForm from '../../../shared/AbrDefAbrKreisListingForm/AbrDefAbrKreisListingForm';
import { AbrechnungsdefinitionType, HeAbrechnungsdefinitionVersionListEntry } from '../../../../../types';
import { mapFormValuesToHeAbrechnungsdefinitionVersionCreate } from './abrDefVersionCreateFormMapper';
import HeAbrDefVersionCreateTabWithTemplate from '../../../shared/Templates/HeTemplates/HeAbrDefVersionCreateTabWithTemplate';
import { HeAbrechnungsdefinitionVersionFieldsFragment } from '../../../gql/HeAbrDef/HeAbrDefVersionFragments.types';
import { useHeAbrDefVersionFieldHelp } from '../../../abrDefFieldHelpHelpers/useHeAbrDefVersionFieldHelp';
import {
  heOrSubAbrDefGeneralSettingsFormInitialValues,
  HeOrSubAbrDefGeneralSettingsFormValues,
  mapHeOrSubAbrDefOutputOptionsToFormValues,
} from '../../../shared/Templates/shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';

type Props = {
  abrechnungsdefinitionVersionList: HeAbrechnungsdefinitionVersionListEntry[];
  abrechnungsdefinitionId: string;
  precedingAbrechnungsdefinitionVersion?: HeAbrechnungsdefinitionVersionFieldsFragment;
  firmendatenId: string;
  heAbrechnungTextbausteinList: TextbausteinModel[];
};

const HeAbrDefVersionCreateForm: FC<Props> = ({
  abrechnungsdefinitionVersionList,
  abrechnungsdefinitionId,
  precedingAbrechnungsdefinitionVersion,
  firmendatenId,
  heAbrechnungTextbausteinList,
}) => {
  const { fieldHelpAbrDefAbrechkreisList, fieldHelpNamesOutputOptions } = useHeAbrDefVersionFieldHelp();

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [validFromFormValue, setValidFromFormValue] = useState<string>(
    getNextAvailableValidFromOrToday(abrechnungsdefinitionVersionList, precedingAbrechnungsdefinitionVersion)
  );

  const [isValidFromValid, setIsValidFromValid] = useState<boolean>();
  const [kommentarFormValues, setKommentarFormValues] = useState<string>('');

  const [heAbrDefGeneralSettingsValues, setHeAbrDefGeneralSettingsValues] = useState<HeOrSubAbrDefGeneralSettingsFormValues>(
    precedingAbrechnungsdefinitionVersion?.outputOptions
      ? mapHeOrSubAbrDefOutputOptionsToFormValues(precedingAbrechnungsdefinitionVersion.outputOptions)
      : heOrSubAbrDefGeneralSettingsFormInitialValues
  );

  const [abrKreisListFormValues, setAbrKreisListFormValues] = useState<AbrDefAbrKreisListingFormValues>(
    mapAbrechnungsdefinitionToAbrKreisListFormValues(precedingAbrechnungsdefinitionVersion?.abrechdefAbrechkreisList)
  );
  const [heAbrDefTemplateValues, setHeAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(heAbrechnungTextbausteinList)
  );

  const entity = 'AbrechnungsdefinitionVersion';

  const [createHeAbrDefVersion, { loading: loadingCreate }] = useCreateHeAbrechnungsdefinitionVersionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      navigate(URI_SYS_SETTINGS.abrechnungsdefinitionPage);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onAbrKreisListingFormSubmit = (formValues: AbrDefAbrKreisListingFormValues) => {
    const hasAbrechnungskreisZuordnungChanged = !isEqual(
      mapAbrechnungsdefinitionToAbrKreisListFormValues(precedingAbrechnungsdefinitionVersion?.abrechdefAbrechkreisList),
      formValues
    );
    hasAbrechnungskreisZuordnungChanged && setKommentarFormValues('Abrechnungskreise geändert');
    setAbrKreisListFormValues(formValues);
    setActiveStep(1);
  };

  const onAbrDefTemplatesSubmit = () => {
    const abrechnungsdefinitionRequest = mapFormValuesToHeAbrechnungsdefinitionVersionCreate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      abrKreisListFormValues!,
      heAbrDefTemplateValues,
      validFromFormValue,
      kommentarFormValues,
      heAbrDefGeneralSettingsValues
    );
    if (!validFromFormValue) {
      setIsValidFromValid(false);
    }
    createHeAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        request: abrechnungsdefinitionRequest,
      },
    });
  };

  let content;
  if (activeStep === 0) {
    content = (
      <AbrDefAbrKreisListingForm
        abrDefType={AbrechnungsdefinitionType.HeAbrechnung}
        values={abrKreisListFormValues}
        firmendatenId={firmendatenId}
        onSubmit={onAbrKreisListingFormSubmit}
        onBack={() => navigate(URI_SYS_SETTINGS.abrechnungsdefinitionPage)}
        backBtnText="Abbrechen"
        fieldHelp={fieldHelpAbrDefAbrechkreisList}
      />
    );
  } else {
    content = // activeStep === 1
      (
        <HeAbrDefVersionCreateTabWithTemplate
          heAbrDefTemplateValues={heAbrDefTemplateValues}
          heAbrDefGeneralSettingsValues={heAbrDefGeneralSettingsValues}
          setHeAbrDefGeneralSettingsValues={setHeAbrDefGeneralSettingsValues}
          onTemplateChange={(formValues) => {
            updateCommentOnTemplateChange(
              mapTextbausteinListToFormValues(heAbrechnungTextbausteinList),
              formValues,
              'Hauseigentümerabrechnung',
              setKommentarFormValues
            );
            setHeAbrDefTemplateValues(formValues);
          }}
          onSubmit={onAbrDefTemplatesSubmit}
          isSubmitting={loadingCreate}
          onBack={() => setActiveStep(0)}
          fieldHelpNames={fieldHelpNamesOutputOptions}
        />
      );
  }

  const onChangeDate = (date: Dayjs | null) => {
    if (date) {
      setIsValidFromValid(true);
      setValidFromFormValue(formatAbrechnungsdefinitionValidFromValue(date));
    } else {
      setIsValidFromValid(false);
    }
  };

  return (
    <>
      <Flex>
        <Space>
          <AbrDefFormValidFrom
            isValid={isValidFromValid}
            onChange={onChangeDate}
            initialValue={validFromFormValue ? dayjsCustom(validFromFormValue) : undefined}
            disabledDates={getAlreadyTakenValidFromDates(abrechnungsdefinitionVersionList)}
          />
        </Space>
        <Space style={{ marginLeft: '10px' }}>
          <AbrDefVersionFormKommentar onChange={setKommentarFormValues} value={kommentarFormValues} />
        </Space>
      </Flex>
      <Divider />
      <Steps type="navigation" size="small" current={activeStep} className="site-navigation-steps">
        <Steps.Step title="Abrechnungskreise" />
        <Steps.Step title="Abrechnung" />
      </Steps>
      {content}
    </>
  );
};

export default HeAbrDefVersionCreateForm;
