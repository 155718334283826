import { FormFields } from '../../../../helpers/formikHelper';
import {
  EbicsBankConfig,
  EbicsBankConfigCreateInput,
  EbicsBankConfigUpdateInput,
  EbicsVersion,
  Pain001Schema,
  Pain008Schema,
} from '../../../../types';

export type EbicsBankConfigFormValues = {
  ebicsVersion: EbicsVersion | null;
  hostId: string;
  name: string;
  pain001Schema: Pain001Schema | null;
  pain008Schema: Pain008Schema | null;
  url: string;
  bankStatementBtf?: string | null;
  creditTransferBtf?: string | null;
  creditTransferStatusBtf?: string | null;
  directDebitBtf?: string | null;
  directDebitStatusBtf?: string | null;
  preApprovalBtf?: string | null;
};

export const ebicsBankConfigFormFields: FormFields<EbicsBankConfigFormValues> = {
  ebicsVersion: 'ebicsVersion',
  hostId: 'hostId',
  name: 'name',
  pain001Schema: 'pain001Schema',
  pain008Schema: 'pain008Schema',
  url: 'url',
  bankStatementBtf: 'bankStatementBtf',
  creditTransferBtf: 'creditTransferBtf',
  creditTransferStatusBtf: 'creditTransferStatusBtf',
  directDebitBtf: 'directDebitBtf',
  directDebitStatusBtf: 'directDebitStatusBtf',
  preApprovalBtf: 'preApprovalBtf',
};

export const mapEbicsBankConfigToFormValues = (ebicsBankConfig?: EbicsBankConfig): EbicsBankConfigFormValues => ({
  ebicsVersion: ebicsBankConfig?.ebicsVersion.value ?? null,
  hostId: ebicsBankConfig?.hostId ?? '',
  name: ebicsBankConfig?.name ?? '',
  pain001Schema: ebicsBankConfig?.pain001Schema.value ?? null,
  pain008Schema: ebicsBankConfig?.pain008Schema.value ?? null,
  url: ebicsBankConfig?.url ?? '',
  bankStatementBtf: ebicsBankConfig?.bankStatementBtf ?? null,
  creditTransferBtf: ebicsBankConfig?.creditTransferBtf ?? null,
  creditTransferStatusBtf: ebicsBankConfig?.creditTransferStatusBtf ?? null,
  directDebitBtf: ebicsBankConfig?.directDebitBtf ?? null,
  directDebitStatusBtf: ebicsBankConfig?.directDebitStatusBtf ?? null,
  preApprovalBtf: ebicsBankConfig?.preApprovalBtf ?? null,
});

export const mapFormValuesToEbicsConfig = (values: EbicsBankConfigFormValues): EbicsBankConfigCreateInput | EbicsBankConfigUpdateInput => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  ebicsVersion: values.ebicsVersion!,
  hostId: values.hostId,
  name: values.name,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  pain001Schema: values.pain001Schema!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  pain008Schema: values.pain008Schema!,
  url: values.url,
  bankStatementBtf: values.bankStatementBtf,
  creditTransferBtf: values.creditTransferBtf,
  creditTransferStatusBtf: values.creditTransferStatusBtf,
  directDebitBtf: values.directDebitBtf,
  directDebitStatusBtf: values.directDebitStatusBtf,
  preApprovalBtf: values.preApprovalBtf,
});
