import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEinmalzahlungMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['ID']['input'];
  input: Types.HeVertragAuszahlungCreateInput;
}>;

export type CreateEinmalzahlungMutation = {
  createEinmalzahlung: {
    data: { heVertragAuszahlungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteAuszahlungMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['String']['input'];
  heVertragAuszahlungId: Types.Scalars['ID']['input'];
}>;

export type DeleteAuszahlungMutation = {
  deleteAuszahlung: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateEinmalzahlungDocument = gql`
  mutation CreateEinmalzahlung($objektId: String!, $heVertragId: ID!, $input: HeVertragAuszahlungCreateInput!) {
    createEinmalzahlung(objektId: $objektId, heVertragId: $heVertragId, input: $input) {
      data {
        heVertragAuszahlungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateEinmalzahlungMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEinmalzahlungMutation, CreateEinmalzahlungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEinmalzahlungMutation, CreateEinmalzahlungMutationVariables>(CreateEinmalzahlungDocument, options);
}
export type CreateEinmalzahlungMutationHookResult = ReturnType<typeof useCreateEinmalzahlungMutation>;
export type CreateEinmalzahlungMutationResult = Apollo.MutationResult<CreateEinmalzahlungMutation>;
export type CreateEinmalzahlungMutationOptions = Apollo.BaseMutationOptions<CreateEinmalzahlungMutation, CreateEinmalzahlungMutationVariables>;
export const DeleteAuszahlungDocument = gql`
  mutation DeleteAuszahlung($objektId: String!, $heVertragId: String!, $heVertragAuszahlungId: ID!) {
    deleteAuszahlung(objektId: $objektId, heVertragId: $heVertragId, heVertragAuszahlungId: $heVertragAuszahlungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteAuszahlungMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAuszahlungMutation, DeleteAuszahlungMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAuszahlungMutation, DeleteAuszahlungMutationVariables>(DeleteAuszahlungDocument, options);
}
export type DeleteAuszahlungMutationHookResult = ReturnType<typeof useDeleteAuszahlungMutation>;
export type DeleteAuszahlungMutationResult = Apollo.MutationResult<DeleteAuszahlungMutation>;
export type DeleteAuszahlungMutationOptions = Apollo.BaseMutationOptions<DeleteAuszahlungMutation, DeleteAuszahlungMutationVariables>;
