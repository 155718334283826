import { FormFields } from '../../../../../helpers/formikHelper';

export type AuftragsartCreateParamFormValues = {
  bezeichnung: string;
  showUstBlock: boolean;
  stornoAuftragsartId?: string;
};

export const auftragsartCreateParamFormFields: FormFields<AuftragsartCreateParamFormValues> = {
  bezeichnung: 'bezeichnung',
  showUstBlock: 'showUstBlock',
  stornoAuftragsartId: 'stornoAuftragsartId',
};

export const auftragsartCreateParamFormInitialValues = (paramFormValues?: AuftragsartCreateParamFormValues) => ({
  bezeichnung: paramFormValues?.bezeichnung ?? '',
  showUstBlock: paramFormValues?.showUstBlock ?? true,
  stornoAuftragsartId: paramFormValues?.stornoAuftragsartId ?? undefined,
});
