import * as Yup from 'yup';
import { entityIsRequired } from '../../../../components/message/validationMsg';
import { ustVomAufwandFreigebenModalFormFields } from './ustVomAufwandFreigbenModalFormMapper';

/* eslint-disable no-template-curly-in-string */

export const ustVomAufwandFreigbenModalFormValidationSchema = Yup.object().shape({
  [ustVomAufwandFreigebenModalFormFields.buchungsdatum]: Yup.date().required(entityIsRequired('Buchungsdatum')),
});

/* eslint-enable no-template-curly-in-string */
