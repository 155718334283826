/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import {entityIsRequired, entityMaxCharLength} from '../../../components/message/validationMsg';

export const bicValidationSchema = Yup.string().required(entityIsRequired('BIC')).max(11, entityMaxCharLength('BIC', 11));

export const banknameValidationSchema = Yup.string().required(entityIsRequired('Bankname')).max(100, entityMaxCharLength('Bankname', 100));

export const bankDetailsFormValidationSchema = Yup.object().shape({
  accountHolder: Yup.string().required(entityIsRequired('Kontoinhaber')).max(30, entityMaxCharLength('Kontoinhaber', 30)),
});
/* eslint-enable no-template-curly-in-string */
