import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { UstKategorieFieldsFragmentDoc, UstKategorieEntryFieldsFragmentDoc } from '../../UstKategorie/gql/UstKategorieFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstKategorieTemplateListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UstKategorieTemplateListQuery = {
  getUstKategorieTemplateList: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      ustKategorieId: string;
      ustVomAufwand?: number | null;
      vstKuerzung?: boolean | null;
      status?: { text: string; value: Types.UstKategorieStatus } | null;
      ustKategorieEntryList: Array<{
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstKategorieTemplateQueryVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
}>;

export type UstKategorieTemplateQuery = {
  getUstKategorieTemplate: {
    data: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      ustKategorieId: string;
      ustVomAufwand?: number | null;
      vstKuerzung?: boolean | null;
      status?: { text: string; value: Types.UstKategorieStatus } | null;
      ustKategorieEntryList: Array<{
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstKategorieEntryTemplateInitialListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UstKategorieEntryTemplateInitialListQuery = {
  getUstKategorieEntryTemplateInitialList: {
    data: Array<{
      additionKonto: number;
      buchungsKZ?: string | null;
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      rechnungskennzeichen: string;
      steuersatz: number;
      ustKategorieEntryId?: string | null;
      ustKz: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstKategorieTemplateChangeHistoryListQueryVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
}>;

export type UstKategorieTemplateChangeHistoryListQuery = {
  getUstKategorieTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const UstKategorieTemplateListDocument = gql`
  query UstKategorieTemplateList {
    getUstKategorieTemplateList {
      data {
        ...UstKategorieFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieFieldsFragmentDoc}
`;
export function useUstKategorieTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<UstKategorieTemplateListQuery, UstKategorieTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieTemplateListQuery, UstKategorieTemplateListQueryVariables>(UstKategorieTemplateListDocument, options);
}
export function useUstKategorieTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieTemplateListQuery, UstKategorieTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieTemplateListQuery, UstKategorieTemplateListQueryVariables>(UstKategorieTemplateListDocument, options);
}
export function useUstKategorieTemplateListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstKategorieTemplateListQuery, UstKategorieTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieTemplateListQuery, UstKategorieTemplateListQueryVariables>(UstKategorieTemplateListDocument, options);
}
export type UstKategorieTemplateListQueryHookResult = ReturnType<typeof useUstKategorieTemplateListQuery>;
export type UstKategorieTemplateListLazyQueryHookResult = ReturnType<typeof useUstKategorieTemplateListLazyQuery>;
export type UstKategorieTemplateListSuspenseQueryHookResult = ReturnType<typeof useUstKategorieTemplateListSuspenseQuery>;
export type UstKategorieTemplateListQueryResult = Apollo.QueryResult<UstKategorieTemplateListQuery, UstKategorieTemplateListQueryVariables>;
export const UstKategorieTemplateDocument = gql`
  query UstKategorieTemplate($ustKategorieId: ID!) {
    getUstKategorieTemplate(ustKategorieId: $ustKategorieId) {
      data {
        ...UstKategorieFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieFieldsFragmentDoc}
`;
export function useUstKategorieTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<UstKategorieTemplateQuery, UstKategorieTemplateQueryVariables> &
    ({ variables: UstKategorieTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieTemplateQuery, UstKategorieTemplateQueryVariables>(UstKategorieTemplateDocument, options);
}
export function useUstKategorieTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieTemplateQuery, UstKategorieTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieTemplateQuery, UstKategorieTemplateQueryVariables>(UstKategorieTemplateDocument, options);
}
export function useUstKategorieTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstKategorieTemplateQuery, UstKategorieTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieTemplateQuery, UstKategorieTemplateQueryVariables>(UstKategorieTemplateDocument, options);
}
export type UstKategorieTemplateQueryHookResult = ReturnType<typeof useUstKategorieTemplateQuery>;
export type UstKategorieTemplateLazyQueryHookResult = ReturnType<typeof useUstKategorieTemplateLazyQuery>;
export type UstKategorieTemplateSuspenseQueryHookResult = ReturnType<typeof useUstKategorieTemplateSuspenseQuery>;
export type UstKategorieTemplateQueryResult = Apollo.QueryResult<UstKategorieTemplateQuery, UstKategorieTemplateQueryVariables>;
export const UstKategorieEntryTemplateInitialListDocument = gql`
  query UstKategorieEntryTemplateInitialList {
    getUstKategorieEntryTemplateInitialList {
      data {
        ...UstKategorieEntryFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieEntryFieldsFragmentDoc}
`;
export function useUstKategorieEntryTemplateInitialListQuery(
  baseOptions?: Apollo.QueryHookOptions<UstKategorieEntryTemplateInitialListQuery, UstKategorieEntryTemplateInitialListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieEntryTemplateInitialListQuery, UstKategorieEntryTemplateInitialListQueryVariables>(
    UstKategorieEntryTemplateInitialListDocument,
    options
  );
}
export function useUstKategorieEntryTemplateInitialListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieEntryTemplateInitialListQuery, UstKategorieEntryTemplateInitialListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieEntryTemplateInitialListQuery, UstKategorieEntryTemplateInitialListQueryVariables>(
    UstKategorieEntryTemplateInitialListDocument,
    options
  );
}
export function useUstKategorieEntryTemplateInitialListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UstKategorieEntryTemplateInitialListQuery, UstKategorieEntryTemplateInitialListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieEntryTemplateInitialListQuery, UstKategorieEntryTemplateInitialListQueryVariables>(
    UstKategorieEntryTemplateInitialListDocument,
    options
  );
}
export type UstKategorieEntryTemplateInitialListQueryHookResult = ReturnType<typeof useUstKategorieEntryTemplateInitialListQuery>;
export type UstKategorieEntryTemplateInitialListLazyQueryHookResult = ReturnType<typeof useUstKategorieEntryTemplateInitialListLazyQuery>;
export type UstKategorieEntryTemplateInitialListSuspenseQueryHookResult = ReturnType<typeof useUstKategorieEntryTemplateInitialListSuspenseQuery>;
export type UstKategorieEntryTemplateInitialListQueryResult = Apollo.QueryResult<
  UstKategorieEntryTemplateInitialListQuery,
  UstKategorieEntryTemplateInitialListQueryVariables
>;
export const UstKategorieTemplateChangeHistoryListDocument = gql`
  query UstKategorieTemplateChangeHistoryList($ustKategorieId: ID!) {
    getUstKategorieTemplateChangeHistoryList(ustKategorieId: $ustKategorieId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useUstKategorieTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<UstKategorieTemplateChangeHistoryListQuery, UstKategorieTemplateChangeHistoryListQueryVariables> &
    ({ variables: UstKategorieTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieTemplateChangeHistoryListQuery, UstKategorieTemplateChangeHistoryListQueryVariables>(
    UstKategorieTemplateChangeHistoryListDocument,
    options
  );
}
export function useUstKategorieTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieTemplateChangeHistoryListQuery, UstKategorieTemplateChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieTemplateChangeHistoryListQuery, UstKategorieTemplateChangeHistoryListQueryVariables>(
    UstKategorieTemplateChangeHistoryListDocument,
    options
  );
}
export function useUstKategorieTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UstKategorieTemplateChangeHistoryListQuery, UstKategorieTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieTemplateChangeHistoryListQuery, UstKategorieTemplateChangeHistoryListQueryVariables>(
    UstKategorieTemplateChangeHistoryListDocument,
    options
  );
}
export type UstKategorieTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useUstKategorieTemplateChangeHistoryListQuery>;
export type UstKategorieTemplateChangeHistoryListLazyQueryHookResult = ReturnType<typeof useUstKategorieTemplateChangeHistoryListLazyQuery>;
export type UstKategorieTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useUstKategorieTemplateChangeHistoryListSuspenseQuery>;
export type UstKategorieTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  UstKategorieTemplateChangeHistoryListQuery,
  UstKategorieTemplateChangeHistoryListQueryVariables
>;
