import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAuftragsartMutationVariables = Types.Exact<{
  input: Types.AuftragsartCreateInput;
}>;

export type CreateAuftragsartMutation = {
  createAuftragsart: {
    data: { auftragsartId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateAuftragsartMutationVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
  input: Types.AuftragsartUpdateInput;
}>;

export type UpdateAuftragsartMutation = {
  updateAuftragsart: {
    data: { auftragsartId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteAuftragsartMutationVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
}>;

export type DeleteAuftragsartMutation = {
  deleteAuftragsart: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveAuftragsartMutationVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
}>;

export type ArchiveAuftragsartMutation = {
  actionArchiveAuftragsart: {
    data: { auftragsartId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveAuftragsartMutationVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveAuftragsartMutation = {
  actionUnarchiveAuftragsart: {
    data: { auftragsartId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateAuftragsartDocument = gql`
  mutation createAuftragsart($input: AuftragsartCreateInput!) {
    createAuftragsart(input: $input) {
      data {
        auftragsartId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateAuftragsartMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAuftragsartMutation, CreateAuftragsartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAuftragsartMutation, CreateAuftragsartMutationVariables>(CreateAuftragsartDocument, options);
}
export type CreateAuftragsartMutationHookResult = ReturnType<typeof useCreateAuftragsartMutation>;
export type CreateAuftragsartMutationResult = Apollo.MutationResult<CreateAuftragsartMutation>;
export type CreateAuftragsartMutationOptions = Apollo.BaseMutationOptions<CreateAuftragsartMutation, CreateAuftragsartMutationVariables>;
export const UpdateAuftragsartDocument = gql`
  mutation updateAuftragsart($auftragsartId: ID!, $input: AuftragsartUpdateInput!) {
    updateAuftragsart(auftragsartId: $auftragsartId, input: $input) {
      data {
        auftragsartId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateAuftragsartMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAuftragsartMutation, UpdateAuftragsartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAuftragsartMutation, UpdateAuftragsartMutationVariables>(UpdateAuftragsartDocument, options);
}
export type UpdateAuftragsartMutationHookResult = ReturnType<typeof useUpdateAuftragsartMutation>;
export type UpdateAuftragsartMutationResult = Apollo.MutationResult<UpdateAuftragsartMutation>;
export type UpdateAuftragsartMutationOptions = Apollo.BaseMutationOptions<UpdateAuftragsartMutation, UpdateAuftragsartMutationVariables>;
export const DeleteAuftragsartDocument = gql`
  mutation deleteAuftragsart($auftragsartId: ID!) {
    deleteAuftragsart(auftragsartId: $auftragsartId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteAuftragsartMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAuftragsartMutation, DeleteAuftragsartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAuftragsartMutation, DeleteAuftragsartMutationVariables>(DeleteAuftragsartDocument, options);
}
export type DeleteAuftragsartMutationHookResult = ReturnType<typeof useDeleteAuftragsartMutation>;
export type DeleteAuftragsartMutationResult = Apollo.MutationResult<DeleteAuftragsartMutation>;
export type DeleteAuftragsartMutationOptions = Apollo.BaseMutationOptions<DeleteAuftragsartMutation, DeleteAuftragsartMutationVariables>;
export const ArchiveAuftragsartDocument = gql`
  mutation ArchiveAuftragsart($auftragsartId: ID!) {
    actionArchiveAuftragsart(auftragsartId: $auftragsartId) {
      data {
        auftragsartId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveAuftragsartMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveAuftragsartMutation, ArchiveAuftragsartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveAuftragsartMutation, ArchiveAuftragsartMutationVariables>(ArchiveAuftragsartDocument, options);
}
export type ArchiveAuftragsartMutationHookResult = ReturnType<typeof useArchiveAuftragsartMutation>;
export type ArchiveAuftragsartMutationResult = Apollo.MutationResult<ArchiveAuftragsartMutation>;
export type ArchiveAuftragsartMutationOptions = Apollo.BaseMutationOptions<ArchiveAuftragsartMutation, ArchiveAuftragsartMutationVariables>;
export const UnarchiveAuftragsartDocument = gql`
  mutation UnarchiveAuftragsart($auftragsartId: ID!) {
    actionUnarchiveAuftragsart(auftragsartId: $auftragsartId) {
      data {
        auftragsartId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveAuftragsartMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveAuftragsartMutation, UnarchiveAuftragsartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveAuftragsartMutation, UnarchiveAuftragsartMutationVariables>(UnarchiveAuftragsartDocument, options);
}
export type UnarchiveAuftragsartMutationHookResult = ReturnType<typeof useUnarchiveAuftragsartMutation>;
export type UnarchiveAuftragsartMutationResult = Apollo.MutationResult<UnarchiveAuftragsartMutation>;
export type UnarchiveAuftragsartMutationOptions = Apollo.BaseMutationOptions<UnarchiveAuftragsartMutation, UnarchiveAuftragsartMutationVariables>;
