import React, { FC } from 'react';
import GeneralInfoBlock from './GeneralInfoBlock';
import DividerForBooking from '../../shared/DividerForBooking';
import BuchungszeileListBlock from './BuchungszeileListBlock/BuchungszeileListBlock';
import { ErrorData, FibuBuchungszeile } from '../../../../types';
import BuchungsErrorLogTable from './ErrorLogTable/BuchungsErrorLogTable';
import { Spacer } from '../../../../components/Grid';
import AuszifferungBlock from '../shared/AuszifferungBlock/AuszifferungBlock';
import { isFibuBuchungTypeSpesen } from '../shared/bookingHelpers';
import { useCurrentBookingDetails } from '../../shared/useCurrentBookingDetails';

type Props = {
  belegId?: string | null;
  buchungIdList: string[];
  buchungszeileList: FibuBuchungszeile[];
  errorList?: ErrorData[];
  includeDeviations?: boolean;
  refetchBuchungszeile: () => void;
};

const BookingDetailsContent: FC<Props> = ({
  belegId,
  buchungIdList,
  buchungszeileList,
  errorList,
  includeDeviations = true,
  refetchBuchungszeile,
}) => {
  /******** data for latest (=current) booking ********/
  const { belegSymbol, buchungType, buchungskreisRechtstraegerId, amountFromPersonenkonto, personenkontoId, zahlungId } =
    useCurrentBookingDetails(buchungszeileList);

  return (
    <>
      {/* Block 1 - ErrorListing if errors provided */}
      {errorList && errorList.length > 0 ? (
        <>
          <BuchungsErrorLogTable errorList={errorList} />
          <Spacer height={16} />
        </>
      ) : null}

      {/* Block 2 - General Info without any buttons */}
      <GeneralInfoBlock belegSymbol={belegSymbol} buchungType={buchungType} buchungskreisRechtstraegerId={buchungskreisRechtstraegerId} />
      <DividerForBooking />

      {/* Block 3 - Buchungszeilen with Umbuchungen and DeviationList */}
      <BuchungszeileListBlock
        buchungszeileList={buchungszeileList}
        belegId={belegId}
        belegSymbol={belegSymbol?.value}
        hasOnlyOneBuchung={buchungIdList.length === 1}
        includeDeviations={includeDeviations}
        onAction={refetchBuchungszeile}
      />

      {/* Block 2 - Auszifferung listing - render ONLY if BuchungType = Zahlung or Rücklastschrift, each Auszifferung can be cancelled (=storniert) => synch function */}
      {zahlungId && !isFibuBuchungTypeSpesen(buchungType.value) && (
        <>
          <DividerForBooking />
          <AuszifferungBlock
            amountFromPersonenkonto={amountFromPersonenkonto}
            bookingCorrectionInProgress={false}
            bookingZahlungId={zahlungId}
            buchungType={buchungType.value}
            buchungskreisRechtstraegerId={buchungskreisRechtstraegerId}
            personenkontoId={personenkontoId}
          />
        </>
      )}
    </>
  );
};

export default BookingDetailsContent;
