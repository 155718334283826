import React, { FC } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { CellButton } from './CellButton';

type TableWithAlignedColsHeaderRowProps = {
  isOpen: boolean;
  bezeichnungHeaderText: string;
  sumHeaderText: string[];
  onClick: () => void;
  cellToUseForSumHeaderText: number[];
  startCell: number;
  endCell: number;
  colSpan?: number[];
  positionOfButton?: 1 | 2 | 3;
};

const TableWithAlignedColsHeaderRow: FC<TableWithAlignedColsHeaderRowProps> = ({
  isOpen,
  onClick,
  bezeichnungHeaderText,
  sumHeaderText,
  cellToUseForSumHeaderText,
  startCell,
  endCell,
  colSpan,
  positionOfButton,
}) => {
  const cells = [
    <CellButton key={1} isOpen={isOpen} onClick={onClick} positionOfButton={positionOfButton ?? 1} />,
    <td key={2} style={{ textAlign: 'left' }}>
      <Typography.Text>{bezeichnungHeaderText}</Typography.Text>
    </td>,
  ];

  let counter = 0;

  for (let i = startCell; i < endCell; i += 1) {
    const key = cells[cells.length - 1].key ? Number(cells[cells.length - 1].key) : 2;
    const cellToUse = cellToUseForSumHeaderText[counter];
    const headerText = sumHeaderText[counter];
    const colSpanSize = colSpan?.[counter];

    if (cellToUseForSumHeaderText[counter] === i) {
      counter += 1;
    }

    cells.push(
      i === cellToUse - 1 ? (
        <td key={key + 1} colSpan={colSpanSize}>
          {headerText}
        </td>
      ) : (
        <td key={key + 1} />
      )
    );
  }

  return <StyledRow>{cells}</StyledRow>;
};

const StyledRow = styled('tr')`
  background-color: #fafafa;
  font-weight: 500;
`;

export default TableWithAlignedColsHeaderRow;
