export interface LocaleState {
  locale: Locale;
}

export type LocaleAction = { type: 'SET_LOCALE'; payload: Locale };

export enum Locale {
  DE = 'de-DE',
  US = 'en-US',
}
