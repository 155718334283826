import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { TicketBase } from '../../../types';
import { convertPeriodToDateString } from '../ticketHelpers';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  ticket: TicketBase;
};

const TicketReminder: FC<Props> = ({ ticket }) => (
  <Space size={4} style={{ whiteSpace: 'nowrap' }}>
    <CustomFormattedDate value={convertPeriodToDateString(ticket.reminder, ticket.dueDate, 'subtract')} />
    <Typography.Text style={{ color: 'inherit' }}>({ticket.reminderText})</Typography.Text>
  </Space>
);

export default TicketReminder;
