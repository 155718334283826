import React, { FC } from 'react';
import { Empty } from 'antd';
import vorschreibungAusgebenVerarbeitungEntryTableColumns from './vorschreibungAusgebenVerarbeitungEntryTableColumns';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { EigenbelegErstellenGenerierlaufEntry } from '../../../../../types';
import { TBelegVerarbeitungQueryParams } from './Filters/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { useEigenbelegErstellenGenerierlaufEntryListQuery } from '../../../gql/BelegGenerierlauf/BelegGenerierlaufQueries.types';

type Props = {
  queryParams: TBelegVerarbeitungQueryParams;
  generierlaufId: string;
};

const VorschreibungAusgebenVerarbeitungEntryTable: FC<Props> = ({ queryParams, generierlaufId }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useEigenbelegErstellenGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getEigenbelegErstellenGenerierlaufEntryList.data.contentList || [];

  return (
    <TableWithColSelector<EigenbelegErstellenGenerierlaufEntry>
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      size="small"
      dataSource={entryList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={vorschreibungAusgebenVerarbeitungEntryTableColumns}
      rowKey={(record) => `${record.generierlaufEntryId} - ${record.objektId} - ${record.auftrag?.auftragId}`}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-beleg-erstellen"
    />
  );
};

export default VorschreibungAusgebenVerarbeitungEntryTable;
