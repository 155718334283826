import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstVomAufwandApplyAllDeviationsMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  ustVomAufwandId: Types.Scalars['ID']['input'];
  input?: Types.InputMaybe<Types.UstVomAufwandActionApplyDeviationsInput>;
}>;

export type UstVomAufwandApplyAllDeviationsMutation = {
  actionUstVomAufwandApplyAllDeviations: {
    data: { ustVomAufwandId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UstVomAufwandApplyAllDeviationsDocument = gql`
  mutation UstVomAufwandApplyAllDeviations($objektId: String!, $ustVomAufwandId: ID!, $input: UstVomAufwandActionApplyDeviationsInput) {
    actionUstVomAufwandApplyAllDeviations(objektId: $objektId, ustVomAufwandId: $ustVomAufwandId, input: $input) {
      data {
        ustVomAufwandId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUstVomAufwandApplyAllDeviationsMutation(
  baseOptions?: Apollo.MutationHookOptions<UstVomAufwandApplyAllDeviationsMutation, UstVomAufwandApplyAllDeviationsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UstVomAufwandApplyAllDeviationsMutation, UstVomAufwandApplyAllDeviationsMutationVariables>(
    UstVomAufwandApplyAllDeviationsDocument,
    options
  );
}
export type UstVomAufwandApplyAllDeviationsMutationHookResult = ReturnType<typeof useUstVomAufwandApplyAllDeviationsMutation>;
export type UstVomAufwandApplyAllDeviationsMutationResult = Apollo.MutationResult<UstVomAufwandApplyAllDeviationsMutation>;
export type UstVomAufwandApplyAllDeviationsMutationOptions = Apollo.BaseMutationOptions<
  UstVomAufwandApplyAllDeviationsMutation,
  UstVomAufwandApplyAllDeviationsMutationVariables
>;
