import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { verarbeitungTypes } from '../../verarbeitungHelpers';
import { GenerierlaufStatus } from '../../../../types';
import { TVerarbeitungQueryParams } from './filtersQueryParams';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';

export const VERARBEITUNG_LIST_FOR_SELECT = Object.entries(verarbeitungTypes).map(([_, value]) => value);

export type FiltersFormValues = {
  erstellerMitarbeiterIdList?: string[] | null;
  generierDatumBisInklusive?: string | null;
  generierDatumVonInklusive?: string | null;
  statusList?: GenerierlaufStatus[] | null;
  typeList?: string[];
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  erstellerMitarbeiterIdList: 'erstellerMitarbeiterIdList',
  generierDatumBisInklusive: 'generierDatumBisInklusive',
  generierDatumVonInklusive: 'generierDatumVonInklusive',
  statusList: 'statusList',
  typeList: 'typeList',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TVerarbeitungQueryParams => ({
  erstellerMitarbeiterIdList: formValues.erstellerMitarbeiterIdList,
  generierDatumBisInklusive: formValues.generierDatumBisInklusive ? mapFormDateValueToDateString(formValues.generierDatumBisInklusive) : null,
  generierDatumVonInklusive: formValues.generierDatumVonInklusive ? mapFormDateValueToDateString(formValues.generierDatumVonInklusive) : null,
  statusList: formValues.statusList,
  typeList: formValues.typeList,
});

export const mapQueryParamsToFormValues = (queryParams: TVerarbeitungQueryParams): FiltersFormValues => ({
  erstellerMitarbeiterIdList: queryParams.erstellerMitarbeiterIdList,
  generierDatumBisInklusive: queryParams.generierDatumBisInklusive,
  generierDatumVonInklusive: queryParams.generierDatumVonInklusive,
  statusList: queryParams.statusList,
  typeList: queryParams.typeList,
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'generierDatumBisInklusive'>> = {
  [filtersFormFields.erstellerMitarbeiterIdList]: 'Ersteller',
  [filtersFormFields.generierDatumVonInklusive]: 'Erstellt am',
  [filtersFormFields.statusList]: 'Status',
  [filtersFormFields.typeList]: 'Verarbeitungstyp',
};
