import { FC } from 'react';
import { Col, Layout, Row, Space } from 'antd';
import ObjektListCard from '../../features/Objekt/ObjektListCard';
// @ts-ignore
import InboxWorkSpace from '../../features/WorkSpace/Renderers/InboxWorkSpace';
// @ts-ignore
import Authorized from '../../security/Authorized';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import DashboardTicketCalendar from '../../features/WorkSpace/TicketCalendar/DashboardTicketCalendar';
import { useAuth } from '../../shared/Auth/authContext';
import DashboardRechtstraegerContactCard from '../../features/RechtstraegerContact/DashboardRechtstraegerContactCard';
import DashboardCallLogListCard from '../../features/CallLog/DashboardCallLogListCard';

const { Content } = Layout;

const DashboardWorkspacePage: FC = () => {
  const { mitarbeiter } = useAuth();

  return (
    <Content style={{ padding: '16px 16px' }}>
      <Row gutter={8}>
        <Col span={6}>
          <Authorized hasPermissionFn={isActiveForSomeFirmendaten}>
            <ObjektListCard />
          </Authorized>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ display: 'flex' }}>
            {mitarbeiter ? (
              <Authorized hasPermissionFn={isActiveForSomeFirmendaten}>
                <InboxWorkSpace />
                <DashboardTicketCalendar />
              </Authorized>
            ) : null}
          </Space>
          {/* <Space direction="vertical" size={16}>
               <CalendarWorkSpace />
            </Space> */}
        </Col>
        <Col span={6}>
          <Authorized hasPermissionFn={isActiveForSomeFirmendaten}>
            <DashboardCallLogListCard />
            <DashboardRechtstraegerContactCard />
          </Authorized>
        </Col>
      </Row>
    </Content>
  );
};

export default DashboardWorkspacePage;
