import React from 'react';
import { Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
import { TicketBase } from '../../../../types';
import { generatePathToTicketDetailsPage } from '../../ticketUriPaths';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

const subTicketTooltipContent = (subTicketList: TicketBase[], ticketId: string) => {
  const subTicket = subTicketList.find((subTicket) => subTicket.ticketId === ticketId);
  if (!subTicket) return null;
  return (
    <Link to={generatePathToTicketDetailsPage(subTicket)} target="_blank" style={{ textDecoration: 'underline', color: 'inherit' }}>
      <LinkOutlined style={{ marginRight: 8 }} />
      Aus Unteraufgabe: <DataWithShortenedText maxTextLength={15} text={subTicket.title} />
    </Link>
  );
};

export default subTicketTooltipContent;
