import { Button, Card, Dropdown, MenuProps, Modal, Row, Skeleton, Spin } from 'antd';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import React from 'react';
import { CardProps } from 'antd/lib/card';
import { Box, Image } from 'rebass';
import { Link } from 'react-router-dom';
import { PDFObject } from 'react-pdfobject';
import MitarbeiterTooltip from '../../../components/Card/MitarbeiterTooltip';
import { useGetThumbnailOrOriginalForMitarbeiter, useLazyGetPreviewForMitarbeiter } from '../useFileDownloadAsyncForDocument';
import { StyledBtn, StyledCard } from '../Styled/Document.style';
import { useToggle } from '../../../hooks/useToggle';
import { isAllowedTypeForEingangsrechnung } from '../helpers/eingangsrechnungHelper';
import { FILE_CONTENT_TYPE_PDF, IMAGE_SUPPORTED_FORMATS } from '../../../helpers/fileHelper';
import { showSuccessMsgDelete } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useAuthorizedMitarbeiter } from '../../../shared/Auth/useAuthorized';
import { FileInfo } from '../../../types';
import { useDeleteFileForMitarbeiterMutation } from '../gql/FileStorageMuations.types';
import { generatePathToToIncomingInvoiceCreateInvoicePage } from '../../IncomingInvoice/incomingInvoiceUriPaths';

interface DocumentPreviewCardProps extends CardProps {
  minwidth?: number;
  file: FileInfo;
  firmendatenId?: string;
  onActionSuccess: () => void;
}

const menuItems = (file: FileInfo): MenuProps['items'] => [
  {
    key: 'eingangsrechnung',
    label: isAllowedTypeForEingangsrechnung(file.contentType) ? (
      <Link to={generatePathToToIncomingInvoiceCreateInvoicePage(file.fileId)}>Eingangsrechnung erfassen</Link>
    ) : (
      'Eingangsrechnung erfassen'
    ),
    disabled: !isAllowedTypeForEingangsrechnung(file.contentType),
  },
];

const DocumentPreviewCard = ({ firmendatenId, minwidth, file, onActionSuccess, ...props }: DocumentPreviewCardProps) => {
  const { mitarbeiter } = useAuthorizedMitarbeiter();
  const mitarbeiterId = mitarbeiter.mitarbeiterId;
  const [isCollapsed, onCollapse] = useToggle();

  const [runDelete, { loading: loadingDelete }] = useDeleteFileForMitarbeiterMutation({
    variables: { mitarbeiterId, fileId: file.fileId },
    onCompleted: () => {
      showSuccessMsgDelete(`${file.name}`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const { data, isLoading } = useGetThumbnailOrOriginalForMitarbeiter(mitarbeiterId, file.fileId);
  const { data: dataMedia, isLoading: isLoadingPreview, run: getPreview } = useLazyGetPreviewForMitarbeiter(mitarbeiterId, file.fileId);

  const url = dataMedia ? URL.createObjectURL(dataMedia as any) : undefined;

  let mediaCard = null;
  if (url) {
    if (file.contentType === FILE_CONTENT_TYPE_PDF) {
      mediaCard = (
        <div style={{ height: '700px' }} id="pdfDiv">
          {/* <PdfViewer file={mediaUrl} /> */}
          <PDFObject url={url} containerId="pdfDiv" />
        </div>
      );
    } else if (IMAGE_SUPPORTED_FORMATS.includes(file.contentType)) {
      mediaCard = (
        <Row justify="center">
          <Image p={24} src={url} alt={file.name} />
        </Row>
      );
    } else {
      mediaCard = <Row justify="center">Keine Voransicht verfügbar</Row>;
    }
  }

  return (
    <>
      <StyledCard
        hoverable={false}
        key={file.fileId}
        cover={
          <Box
            p="8px"
            onClick={() => {
              getPreview();
              onCollapse();
            }}
          >
            {isLoading ? (
              <Box p={48}>
                <Spin />
              </Box>
            ) : (
              <Image sx={{ width: '400px', height: '400px', objectFit: 'cover', cursor: 'zoom-in' }} src={data} alt={file.name} />
            )}
          </Box>
        }
        actions={[
          <StyledBtn
            ghost
            icon={<DeleteOutlined data-testid="delete-icon" />}
            size="small"
            shape="circle"
            onClick={() => runDelete()}
            loading={loadingDelete}
            disabled={loadingDelete}
          />,
          <Dropdown menu={{ items: menuItems(file) }}>
            <Button data-testid="ellipsis-icon" key="ellipsis" icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
          </Dropdown>,
        ]}
        {...props}
      >
        <Skeleton loading={isLoading} avatar active>
          <Card.Meta
            avatar={<MitarbeiterTooltip showsInitials userId={file.createdBy} mitarbeiterId={file.createdByMitarbeiterId} />}
            title={file.name}
            description={file.contentType}
          />
        </Skeleton>
      </StyledCard>
      <Modal title={file.name} width={920} centered open={isCollapsed} onCancel={onCollapse} footer={null} destroyOnClose>
        {isLoadingPreview ? (
          <Box p={48}>
            <Spin />
          </Box>
        ) : (
          <>{mediaCard}</>
        )}
      </Modal>
    </>
  );
};

export default DocumentPreviewCard;
