import { ObjektVorschreibungsposition, ObjektVorschreibungspositionUpdateInput } from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';

export interface ObjektVorschreibungspositionFormValues {
  bezeichnung: string;
}

export const objektVorschreibungspositionFormFields: FormFields<ObjektVorschreibungspositionFormValues> = {
  bezeichnung: 'bezeichnung',
};

export const mapObjektVorschreibungspositionToFormValues = (
  objektVorschreibungsposition: ObjektVorschreibungsposition
): ObjektVorschreibungspositionFormValues => ({
  bezeichnung: objektVorschreibungsposition.bezeichnung,
});

export const mapFormValuesToVorschreibungsPosition = (values: ObjektVorschreibungspositionFormValues): ObjektVorschreibungspositionUpdateInput => ({
  bezeichnung: values.bezeichnung,
});
