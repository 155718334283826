import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import RechtstraegerBankSettingsCard from '../../../BankSettings/RechtstraegerBankSettingsCard';
import { RechnungsAusstellerFieldsFragment } from './gql/RechnungsAusstellerFragments.types';
import SubAdministrationSettingsCard from './SubAdministrationSettingsCard';

type Props = {
  rechnungsAussteller: RechnungsAusstellerFieldsFragment;
  rechtstraegerId: string;
  onAction: () => void;
};

const RechnungsAusstellerGeneralInfo: FC<Props> = ({ rechnungsAussteller, rechtstraegerId, onAction }) => {
  return (
    <>
      <Typography.Title level={4}>Allgemein</Typography.Title>
      <br />
      <Space direction="vertical">
        <RechtstraegerBankSettingsCard rechtstraegerId={rechtstraegerId} bankSettings={rechnungsAussteller.bankSettings} onAction={onAction} />
        {rechnungsAussteller.subAdministrationSettings && (
          <SubAdministrationSettingsCard
            rechtstraegerId={rechtstraegerId}
            subAdministrationSettings={rechnungsAussteller.subAdministrationSettings}
            onAction={onAction}
          />
        )}
      </Space>
    </>
  );
};

export default RechnungsAusstellerGeneralInfo;
