import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { PaymentGenerierlaufType } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { useFilterGenLaufPaymentObjektListQuery } from '../../../gql/GenerierlaufFilter/PaymentGenerierlaufFilterQueries.types';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: PaymentGenerierlaufType;
} & SelectProps;

const GenerierlaufObjektFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufPaymentObjektListQuery({ variables: { generierlaufId, generierlaufType } });

  const list = data?.getFilterGenLaufPaymentObjektList.data ?? [];

  return (
    <Select size="small" loading={loading} id={name} name={name} allowClear placeholder="Objekt auswählen" {...restProps}>
      {list.map((objekt) => (
        <Select.Option key={objekt.objektId} value={objekt.objektId}>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <DataWithShortenedText maxTextLength={30} text={objekt.kurzBezeichnung!} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufObjektFormSelect;
