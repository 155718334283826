import React, { FC } from 'react';
import { Skeleton } from 'antd';
import {
  useSubAdministrationAbrechnungsdefinitionTemplateVersionListQuery,
  useSubAdministrationAbrechnungsdefinitionTemplateVersionQuery,
} from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/SubAdministrationAbrDef/SubAdministrationAbrDefTemplateVersionQueries.types';
import SubAbrDefTemplateVersionUpdateForm from '../../../../../../features/AbrechnungsdefinitionTemplate/Version/Form/Update/SubAbrDefTemplateVersionUpdateForm';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
};

const AndromedaSysSettingsSubAbrechnungsdefinitionVersionUpdatePage: FC<Props> = ({ abrechnungsdefinitionId, abrechnungsdefinitionVersionId }) => {
  const { data: subAbrDefVersionListData } = useSubAdministrationAbrechnungsdefinitionTemplateVersionListQuery({
    variables: { abrechnungsdefinitionId },
  });
  const abrechnungsdefinitionVersionList = subAbrDefVersionListData?.getSubAdministrationAbrechnungsdefinitionTemplateVersionList.data;

  const { data: subAbrDefVersion, refetch } = useSubAdministrationAbrechnungsdefinitionTemplateVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId },
  });
  const abrechnungsdefinitionVersion = subAbrDefVersion?.getSubAdministrationAbrechnungsdefinitionTemplateVersion.data;

  if (!abrechnungsdefinitionVersion || !abrechnungsdefinitionVersionList) return <Skeleton active />;

  return (
    <SubAbrDefTemplateVersionUpdateForm
      abrechnungsdefinitionVersionList={abrechnungsdefinitionVersionList}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      validFrom={abrechnungsdefinitionVersion.validFrom}
      kommentar={abrechnungsdefinitionVersion.kommentar ?? ''}
      onSuccess={refetch}
    />
  );
};

export default AndromedaSysSettingsSubAbrechnungsdefinitionVersionUpdatePage;
