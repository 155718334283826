import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { URI_VERARBEITUNG_PAGE } from '../../constants/appUriPaths';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import VerarbeitungPage from './VerarbeitungPage';

const verarbeitungPageRoute = (
  <Route path={URI_VERARBEITUNG_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<VerarbeitungPage />} />} />
);

export default verarbeitungPageRoute;
