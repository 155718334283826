import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import RechtstraegerInfoFeldCreateForm from './Form/RechtstraegerInfoFeldCreateForm';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';

type Props = {
  onSuccess: () => void;
  rechtstraegerId: string;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const RechtstraegerInfoFeldDrawerAndButton: FC<Props> = ({ rechtstraegerId, onSuccess, showArchived, toggleShowArchived }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const onCreateSuccess = () => {
    onSuccess();
    onCollapse();
  };

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button onClick={toggleShowArchived}>Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}</Button>
        <Button type="primary" onClick={onCollapse}>
          <PlusOutlined /> Info Neuanlage
        </Button>
      </ButtonsAligned>
      <Drawer title="Informationen anlegen" width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
        <RechtstraegerInfoFeldCreateForm onCancel={onCollapse} onSuccess={onCreateSuccess} rechtstraegerId={rechtstraegerId} />
      </Drawer>
    </>
  );
};

export default RechtstraegerInfoFeldDrawerAndButton;
