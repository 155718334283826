import React, { FC } from 'react';
import { Empty } from 'antd';
import entryTableColumns from './EntryTableColumns';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import { GenerierlaufEntryExitCode, ZinslisteGenerierlaufEntry } from '../../../../../types';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { useZinslisteGenerierlaufEntryListQuery } from '../../../gql/ZinslisteGenerierlauf/ZinslisteGenerierlaufQueries.types';

type Props = {
  objektId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[] | undefined;
  generierlaufId: string;
};

const EntryTable: FC<Props> = ({ generierlaufId, objektId, exitCodeList }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useZinslisteGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId,
        exitCodeList,
        objektIdList: objektId ? [objektId] : undefined,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getZinslisteGenerierlaufEntryList.data.contentList || [];

  return (
    <>
      <TableWithColSelector<ZinslisteGenerierlaufEntry>
        dataSource={entryList}
        columns={entryTableColumns}
        loading={loading}
        locale={{
          emptyText: <Empty description={<span>Keine Zinsliste vorhanden</span>} />,
        }}
        size="small"
        onChange={handleTableSorting}
        pagination={pagination}
        expandable={{
          expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
          rowExpandable: isVerarbeitungEntryTableRowExpandable,
        }}
        filterIdentifier="verarbeitung-zinsliste"
        rowKey={(record) => record.generierlaufEntryId}
      />
    </>
  );
};

export default EntryTable;
