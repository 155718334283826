import React from 'react';
import { Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { ErrorData, ResponseDataWarning } from '../../../../types';

const verarbeitungErrorAndWarningTableColumns: ColumnProps<ErrorData | ResponseDataWarning>[] = [
  {
    title: 'Type',
    render: (_, record) => <Typography.Text>{record.type}</Typography.Text>,
  },
  {
    title: 'Nachricht',
    render: (_, record) => <Typography.Text>{record.message}</Typography.Text>,
  },
];

export default verarbeitungErrorAndWarningTableColumns;
