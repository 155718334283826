import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../../../security/permissionChecks';
import AuthRoute from '../../../../../../security/AuthRoute';
import SysSettingsAbrechnungsdefinitionVersionViewPage from './SysSettingsAbrechnungsdefinitionVersionViewPage';
import { URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION } from '../../../../../../features/Abrechnungsdefinition/abrDefUriPaths';

export type AbrechnungsdefinitionVersionViewPageRouteParams = { abrechnungsdefinitionVersionId: string; abrechnungsdefinitionId: string };

const sysSettingsAbrechnungsdefinitionVersionViewPageRoute = (
  <Route
    path={URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.viewPage}
    element={
      <AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAbrechnungsdefinitionVersionViewPage />} />
    }
  />
);

export default sysSettingsAbrechnungsdefinitionVersionViewPageRoute;
