import React, { FC } from 'react';
import { EmailDeliveryDetailsPageProps } from '../../../features/EmailDelivery/emailDeliveryHelpers';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import EmailDeliveryDetailsCard from '../../../features/EmailDelivery/Card/EmailDeliveryDetailsCard';
import menuListEmailDelivery from './menuListEmailDelivery';
import { MailData } from '../../../types';

const EmailDeliveryDetailsPage: FC<EmailDeliveryDetailsPageProps> = ({ mail, loading }) => {
  return (
    <DetailsPageTemplate<MailData>
      data={mail}
      pageTitle={() => 'E-mail Versand'}
      isLoading={loading}
      card={(emailDelivery) => <EmailDeliveryDetailsCard emailDelivery={emailDelivery} />}
      sidebarMenuList={(emailDelivery) => menuListEmailDelivery(emailDelivery)}
    />
  );
};

export default EmailDeliveryDetailsPage;
