import React, { FC } from 'react';
import { Typography } from 'antd';
import { useRechtstraegerQuery } from '../gql/RechtsTraegerQueries.types';
import LinkWithStatus from '../../../components/Link/LinkWithStatus';
import { generatePathToRechtstraegerDetailsPage } from '../rechtstraegerHelper';

type Props = {
  rechtstraegerId: string;
  rechtstraegerKurzBezeichnung: string;
  maxTextLength?: number;
  openInNewTab?: boolean;
};

const RechtstraegerWithStatus: FC<Props> = ({ rechtstraegerId, rechtstraegerKurzBezeichnung, maxTextLength, openInNewTab }) => {
  const { data } = useRechtstraegerQuery({ variables: { rechtstraegerId } });

  const status = data?.getRechtstraeger.data.status;

  if (status) {
    return (
      <LinkWithStatus
        status={status}
        text={rechtstraegerKurzBezeichnung}
        to={generatePathToRechtstraegerDetailsPage(rechtstraegerId)}
        maxTextLength={maxTextLength}
        openInNewTab={openInNewTab}
      />
    );
  }

  return <Typography.Text>{rechtstraegerKurzBezeichnung}</Typography.Text>;
};

export default RechtstraegerWithStatus;
