import React, { FC } from 'react';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import infoMailDeliverySendVerarbeitungEntryTableColumns from './InfoMailDeliverySendVerarbeitungEntryTableColumns';
import { InfoMailDeliverySendGenerierlaufEntry } from '../../../../../types';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { useInfoMailDeliverySendGenerierlaufEntryListQuery } from '../../../gql/InfoMailDeliverySendGenerierLauf/InfoMailDeliverySendGenerierlaufQueries.types';
import { filtersQueryParams } from '../../../VorschreibungMailDeliverySend/EntryListing/Filters/filtersQueryParams';

type Props = {
  queryParams: filtersQueryParams;
  generierlaufId: string;
};

const InfoMailDeliverySendVerarbeitungEntryTable: FC<Props> = ({ generierlaufId, queryParams }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useInfoMailDeliverySendGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getInfoMailDeliverySendGenerierlaufEntryList.data.contentList || [];

  return (
    <TableWithColSelector<InfoMailDeliverySendGenerierlaufEntry>
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Empfänger vorhanden</span>} />,
      }}
      size="small"
      dataSource={entryList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={infoMailDeliverySendVerarbeitungEntryTableColumns}
      rowKey={(record) => record.generierlaufEntryId}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-info-mail-delivery-send"
    />
  );
};

export default InfoMailDeliverySendVerarbeitungEntryTable;
