import React, { FC } from 'react';
import { Button, Drawer, Dropdown, MenuProps, Modal, Space, Tabs, TabsProps } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { Box } from 'rebass';
import { useToggle } from '../../../hooks/useToggle';
import AbrDefVersionTimelineWithActions from '../Version/Timeline/AbrDefVersionTimelineWithActions';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { isStatusArchived } from '../../../helpers/statusHelper';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import {
  useArchiveAbrechnungsdefinitionMutation,
  useDeleteAbrechnungsdefinitionMutation,
  useUnarchiveAbrechnungsdefinitionMutation,
} from '../gql/AbrDefMutations.types';
import AbrDefUpdateForm from '../Form/Update/AbrDefUpdateForm';
import { AbrechnungsdefinitionListEntry } from '../../../types';

type Props = {
  abrechnungsdefinition: AbrechnungsdefinitionListEntry;
  onActionSuccess: () => void;
};

const AbrDefTableActions: FC<Props> = ({ abrechnungsdefinition, onActionSuccess }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const entity = 'Abrechnunsdefinition';
  const isArchived = abrechnungsdefinition.status && isStatusArchived(abrechnungsdefinition.status);

  const [runDelete] = useDeleteAbrechnungsdefinitionMutation({
    variables: {
      abrechnungsdefinitionId: abrechnungsdefinition.abrechnungsdefinitionId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveAbrechnungsdefinitionMutation({
    variables: {
      abrechnungsdefinitionId: abrechnungsdefinition.abrechnungsdefinitionId,
    },
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveAbrechnungsdefinitionMutation({
    variables: {
      abrechnungsdefinitionId: abrechnungsdefinition.abrechnungsdefinitionId,
    },
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    {
      key: '2',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(runUnarchive, abrechnungsdefinition) : showConfirmArchive(runArchive, abrechnungsdefinition),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: !!abrechnungsdefinition.deletable,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, abrechnungsdefinition),
      icon: <DeleteOutlined />,
      disabled: !abrechnungsdefinition.deletable,
      danger: true,
    },
  ];

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Space>
          <HistoryOutlined />
          Verlauf
        </Space>
      ),
      children: (
        <Box p={16}>
          <AbrDefVersionTimelineWithActions
            abrechnungsdefinitionId={abrechnungsdefinition.abrechnungsdefinitionId}
            abrDefType={abrechnungsdefinition.type.value}
          />
        </Box>
      ),
    },
    {
      key: '2',
      label: (
        <Space>
          <EditOutlined />
          Bearbeiten
        </Space>
      ),
      children: (
        <Box p={16}>
          <AbrDefUpdateForm
            abrechnungsdefinition={abrechnungsdefinition}
            onSuccess={() => {
              onActionSuccess();
              onCollapse();
            }}
            onCancel={onCollapse}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <Drawer
        title="Bearbeiten"
        width={720}
        onClose={onCollapse}
        open={isCollapsed}
        destroyOnClose
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={onCollapse} type="primary">
              Schließen
            </Button>
          </div>
        }
      >
        <Tabs defaultActiveKey="1" items={tabItems} />
      </Drawer>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, abrechnungsdefinition: AbrechnungsdefinitionListEntry) => () => {
  Modal.confirm({
    title: `Möchten Sie die Abrechnungsdefinition löschen?`,
    content: `${abrechnungsdefinition.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmArchive = (runArchive: () => void, abrechnungsdefinition: AbrechnungsdefinitionListEntry) => () => {
  Modal.confirm({
    title: `Möchten Sie die Abrechnungsdefinition archivieren?`,
    content: `${abrechnungsdefinition.bezeichnung}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (runUnarchive: () => void, abrechnungsdefinition: AbrechnungsdefinitionListEntry) => () => {
  Modal.confirm({
    title: `Möchten Sie die Abrechnungsdefinition reaktivieren?`,
    content: `${abrechnungsdefinition.bezeichnung}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

export default AbrDefTableActions;
