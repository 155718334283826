import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { RepFondsAbrechnung } from '../../topAbrechnung-types';
import TARepFondsAbrechnungUebersichtBlock from './TARepFondsAbrechnungUebersichtBlock';
import TARepFondsAbrechnungDetailBlock from './DetailsBlock/TARepFondsAbrechnungDetailBlock';
import TARepFondsAufwandAufteilungBlock from './AufwandAufteilungBlock/TARepFondsAufwandAufteilungBlock';

const TARepFondsAbrechnungBlock: FC<{ repFondsAbrechnung: RepFondsAbrechnung }> = ({ repFondsAbrechnung }) => (
  <View>
    <Text style={[pdfStyles.textNormal, { fontSize: '11px', fontWeight: 'bold', textAlign: 'center' }]}>
      {repFondsAbrechnung.repFondsAbrechnungTitelText}
    </Text>

    {/* Übersicht block */}
    <TARepFondsAbrechnungUebersichtBlock uebersicht={repFondsAbrechnung.uebersicht} />

    {/* Details block */}
    <TARepFondsAbrechnungDetailBlock details={repFondsAbrechnung.details} />

    {/* AufwandAufteilung block */}
    {repFondsAbrechnung.aufwandAufteilung ? <TARepFondsAufwandAufteilungBlock aufwandAufteilung={repFondsAbrechnung.aufwandAufteilung} /> : null}
  </View>
);

export default TARepFondsAbrechnungBlock;
