import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, MenuProps, Modal } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { TicketBase } from '../../../../types';
import { useToggle } from '../../../../hooks/useToggle';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';
import TicketForm from '../../Form/TicketForm';
import { generatePathToTicketDetailsPage } from '../../ticketUriPaths';
import useTicketStatusMenuItems from '../../shared/useTicketStatusMenuItems';
import { useArchiveTicketMutation, useUnarchiveTicketMutation } from '../../gql/TicketMutations.types';
import { showSuccessMsgArchived, showSuccessMsgUnarchived } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import { isTicketArchived, isTicketParerentArchived } from '../../ticketHelpers';
import { gqlOperations } from '../../../../graphql/gqlNamedOperations-generated';

type Props = {
  ticket: TicketBase;
  onAction: () => void;
};

const TicketTableActions: FC<Props> = ({ ticket, onAction }) => {
  const navigate = useNavigate();
  const [isCollapsed, onCollapse] = useToggle();
  const { statusMenuItems } = useTicketStatusMenuItems(ticket, onAction);
  const entity = `Aufgabe`;

  const isArchived = isTicketArchived(ticket);
  const isParentArchived = isTicketParerentArchived(ticket);

  const [runArchive] = useArchiveTicketMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onAction();
    },
    refetchQueries: () => [gqlOperations.Query.TicketList],
    variables: {
      ticketId: ticket.ticketId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveTicketMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onAction();
    },
    refetchQueries: () => [gqlOperations.Query.TicketList],
    variables: {
      ticketId: ticket.ticketId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Details',
      onClick: () => navigate(generatePathToTicketDetailsPage(ticket)),
      icon: <EyeOutlined />,
    },
    {
      key: '2',
      label: 'Bearbeiten',
      onClick: onCollapse,
      icon: <EditOutlined />,
      disabled: isArchived,
    },
    {
      key: '3',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(runUnarchive, ticket) : showConfirmArchive(runArchive, ticket),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: isParentArchived,
    },
    ...statusMenuItems,
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Drawer title={`Aufgabe "${ticket.title}" bearbeiten`} width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <TicketForm
          ticketId={ticket.ticketId}
          isSubticket={ticket.isSubTicket}
          onSuccess={() => {
            onCollapse(false);
            onAction();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
    </>
  );
};

const showConfirmArchive = (runArchive: () => void, ticket: TicketBase) => () => {
  Modal.confirm({
    title: `Möchten Sie die Aufgabe archivieren?`,
    content: `${ticket.title}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (runUnarchive: () => void, ticket: TicketBase) => () => {
  Modal.confirm({
    title: `Möchten Sie die Aufgabe reaktivieren?`,
    content: `${ticket.title}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

export default TicketTableActions;
