import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { useFilterPaymentPayeeIbanListQuery } from './gql/PaymentFilterQueries.types';

type Props = { rechtstraegerId?: string | null } & SelectProps;

const PaymentPayeeIbanSelect: FC<Props> = ({ rechtstraegerId, ...restProps }) => {
  const { loading, data } = useFilterPaymentPayeeIbanListQuery({ variables: { rechtstraegerId } });
  const list = data?.getFilterPaymentPayeeIbanList.data;

  return (
    <>
      <Select
        id={restProps.name}
        loading={loading}
        size="small"
        placeholder="Bankverbindung auswählen"
        allowClear
        showSearch
        filterOption={selectFilterOption}
        {...restProps}
      >
        {list?.map((iban) => {
          return (
            <Select.Option key={iban} value={iban}>
              <DataWithShortenedText maxTextLength={40} text={`${friendlyFormatIBAN(iban)}`} />
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};

export default PaymentPayeeIbanSelect;
