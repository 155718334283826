import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { MitarbeiterBaseFieldsFragmentDoc } from '../../Mitarbeiter/gql/MitarbeiterFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CallLogListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type CallLogListQuery = {
  getCallLogList: {
    data: {
      contentList: Array<{
        callLogId: string;
        callTs: string;
        phoneNumber: string;
        kommentar?: string | null;
        callee: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          mitarbeiterId: string;
          updatedByMitarbeiterId?: string | null;
          firmendatenBaseData: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        rechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        type: { text: string; value: Types.CallLogType };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type Last5CallLogListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type Last5CallLogListQuery = {
  getLast5CallLogList: {
    data: Array<{
      callLogId: string;
      callTs: string;
      kommentar?: string | null;
      phoneNumber: string;
      callee: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        mitarbeiterId: string;
        updatedByMitarbeiterId?: string | null;
        firmendatenBaseData: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      rechtstraeger: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      type: { text: string; value: Types.CallLogType };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CallLogListDocument = gql`
  query CallLogList($rechtstraegerId: ID!, $page: Int, $pageSize: Int) {
    getCallLogList(rechtstraegerId: $rechtstraegerId, page: $page, pageSize: $pageSize) {
      data {
        contentList {
          callLogId
          callee {
            ...MitarbeiterBaseFields
          }
          callTs
          phoneNumber
          kommentar
          rechtstraeger {
            ...RechtstraegerBaseFields
          }
          type {
            text
            value
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MitarbeiterBaseFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useCallLogListQuery(
  baseOptions: Apollo.QueryHookOptions<CallLogListQuery, CallLogListQueryVariables> &
    ({ variables: CallLogListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallLogListQuery, CallLogListQueryVariables>(CallLogListDocument, options);
}
export function useCallLogListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallLogListQuery, CallLogListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallLogListQuery, CallLogListQueryVariables>(CallLogListDocument, options);
}
export function useCallLogListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CallLogListQuery, CallLogListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CallLogListQuery, CallLogListQueryVariables>(CallLogListDocument, options);
}
export type CallLogListQueryHookResult = ReturnType<typeof useCallLogListQuery>;
export type CallLogListLazyQueryHookResult = ReturnType<typeof useCallLogListLazyQuery>;
export type CallLogListSuspenseQueryHookResult = ReturnType<typeof useCallLogListSuspenseQuery>;
export type CallLogListQueryResult = Apollo.QueryResult<CallLogListQuery, CallLogListQueryVariables>;
export const Last5CallLogListDocument = gql`
  query Last5CallLogList {
    getLast5CallLogList {
      data {
        callLogId
        callee {
          ...MitarbeiterBaseFields
        }
        callTs
        kommentar
        phoneNumber
        rechtstraeger {
          ...RechtstraegerBaseFields
        }
        type {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MitarbeiterBaseFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useLast5CallLogListQuery(baseOptions?: Apollo.QueryHookOptions<Last5CallLogListQuery, Last5CallLogListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Last5CallLogListQuery, Last5CallLogListQueryVariables>(Last5CallLogListDocument, options);
}
export function useLast5CallLogListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Last5CallLogListQuery, Last5CallLogListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Last5CallLogListQuery, Last5CallLogListQueryVariables>(Last5CallLogListDocument, options);
}
export function useLast5CallLogListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Last5CallLogListQuery, Last5CallLogListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Last5CallLogListQuery, Last5CallLogListQueryVariables>(Last5CallLogListDocument, options);
}
export type Last5CallLogListQueryHookResult = ReturnType<typeof useLast5CallLogListQuery>;
export type Last5CallLogListLazyQueryHookResult = ReturnType<typeof useLast5CallLogListLazyQuery>;
export type Last5CallLogListSuspenseQueryHookResult = ReturnType<typeof useLast5CallLogListSuspenseQuery>;
export type Last5CallLogListQueryResult = Apollo.QueryResult<Last5CallLogListQuery, Last5CallLogListQueryVariables>;
