import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVorsteuerkuerzungEinstellungMutationVariables = Types.Exact<{
  input: Types.VorsteuerkuerzungEinstellungCreateInput;
}>;

export type CreateVorsteuerkuerzungEinstellungMutation = {
  createVorsteuerkuerzungEinstellung: {
    data: { validFrom: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateVorsteuerkuerzungEinstellungMutationVariables = Types.Exact<{
  vorsteuerkuerzungEinstellungId: Types.Scalars['ID']['input'];
  input: Types.VorsteuerkuerzungEinstellungUpdateInput;
}>;

export type UpdateVorsteuerkuerzungEinstellungMutation = {
  updateVorsteuerkuerzungEinstellung: {
    data: { validFrom: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteVorsteuerkuerzungEinstellungMutationVariables = Types.Exact<{
  vorsteuerkuerzungEinstellungId: Types.Scalars['ID']['input'];
  verwaltungsart: Types.Verwaltungsart;
}>;

export type DeleteVorsteuerkuerzungEinstellungMutation = {
  deleteVorsteuerkuerzungEinstellung: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateVorsteuerkuerzungEinstellungDocument = gql`
  mutation CreateVorsteuerkuerzungEinstellung($input: VorsteuerkuerzungEinstellungCreateInput!) {
    createVorsteuerkuerzungEinstellung(input: $input) {
      data {
        validFrom
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateVorsteuerkuerzungEinstellungMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVorsteuerkuerzungEinstellungMutation, CreateVorsteuerkuerzungEinstellungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVorsteuerkuerzungEinstellungMutation, CreateVorsteuerkuerzungEinstellungMutationVariables>(
    CreateVorsteuerkuerzungEinstellungDocument,
    options
  );
}
export type CreateVorsteuerkuerzungEinstellungMutationHookResult = ReturnType<typeof useCreateVorsteuerkuerzungEinstellungMutation>;
export type CreateVorsteuerkuerzungEinstellungMutationResult = Apollo.MutationResult<CreateVorsteuerkuerzungEinstellungMutation>;
export type CreateVorsteuerkuerzungEinstellungMutationOptions = Apollo.BaseMutationOptions<
  CreateVorsteuerkuerzungEinstellungMutation,
  CreateVorsteuerkuerzungEinstellungMutationVariables
>;
export const UpdateVorsteuerkuerzungEinstellungDocument = gql`
  mutation UpdateVorsteuerkuerzungEinstellung($vorsteuerkuerzungEinstellungId: ID!, $input: VorsteuerkuerzungEinstellungUpdateInput!) {
    updateVorsteuerkuerzungEinstellung(vorsteuerkuerzungEinstellungId: $vorsteuerkuerzungEinstellungId, input: $input) {
      data {
        validFrom
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateVorsteuerkuerzungEinstellungMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVorsteuerkuerzungEinstellungMutation, UpdateVorsteuerkuerzungEinstellungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVorsteuerkuerzungEinstellungMutation, UpdateVorsteuerkuerzungEinstellungMutationVariables>(
    UpdateVorsteuerkuerzungEinstellungDocument,
    options
  );
}
export type UpdateVorsteuerkuerzungEinstellungMutationHookResult = ReturnType<typeof useUpdateVorsteuerkuerzungEinstellungMutation>;
export type UpdateVorsteuerkuerzungEinstellungMutationResult = Apollo.MutationResult<UpdateVorsteuerkuerzungEinstellungMutation>;
export type UpdateVorsteuerkuerzungEinstellungMutationOptions = Apollo.BaseMutationOptions<
  UpdateVorsteuerkuerzungEinstellungMutation,
  UpdateVorsteuerkuerzungEinstellungMutationVariables
>;
export const DeleteVorsteuerkuerzungEinstellungDocument = gql`
  mutation DeleteVorsteuerkuerzungEinstellung($vorsteuerkuerzungEinstellungId: ID!, $verwaltungsart: Verwaltungsart!) {
    deleteVorsteuerkuerzungEinstellung(vorsteuerkuerzungEinstellungId: $vorsteuerkuerzungEinstellungId, verwaltungsart: $verwaltungsart) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteVorsteuerkuerzungEinstellungMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVorsteuerkuerzungEinstellungMutation, DeleteVorsteuerkuerzungEinstellungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVorsteuerkuerzungEinstellungMutation, DeleteVorsteuerkuerzungEinstellungMutationVariables>(
    DeleteVorsteuerkuerzungEinstellungDocument,
    options
  );
}
export type DeleteVorsteuerkuerzungEinstellungMutationHookResult = ReturnType<typeof useDeleteVorsteuerkuerzungEinstellungMutation>;
export type DeleteVorsteuerkuerzungEinstellungMutationResult = Apollo.MutationResult<DeleteVorsteuerkuerzungEinstellungMutation>;
export type DeleteVorsteuerkuerzungEinstellungMutationOptions = Apollo.BaseMutationOptions<
  DeleteVorsteuerkuerzungEinstellungMutation,
  DeleteVorsteuerkuerzungEinstellungMutationVariables
>;
