import React, { FC } from 'react';
import { Typography } from 'antd';
import { EuroAmount } from '../../../../../../../../components/Number';
import { getFormattedDecimalValueOrZero } from '../../../../../../../../components/Input-antd/formattedDecimalHelper';
import { AuszifferungBlockFormValues, AuszifferungFormValues, OffenePostenForBooking } from '../../../auszifferungFormMapper';
import { getOffenePostenIndexFromMainOPList, isAuszifferungStorniert } from '../../../auszifferungFormHelpers';
import { dayjsCustom } from '../../../../../../../../helpers/dayjsCustom';

type Props = {
  auszifferung: AuszifferungFormValues;
  formikValues: AuszifferungBlockFormValues;
  isOPForPersonenkonto: boolean;
  offenePosten: OffenePostenForBooking;
};

const SaldoOffenerBetragColumn: FC<Props> = ({ auszifferung, formikValues, isOPForPersonenkonto, offenePosten }) => {
  let auszifferungList = offenePosten.auszifferungList;

  if (isOPForPersonenkonto) {
    const opIndex = getOffenePostenIndexFromMainOPList(formikValues, offenePosten);
    if (opIndex >= 0) {
      auszifferungList = formikValues.offenePostenListFromBooking[opIndex].auszifferungList;
    }
  }

  const offenerBetrag = calculateSaldoOffenerBetrag(offenePosten.gesamtBetrag, auszifferungList, auszifferung);

  return (
    <Typography.Text delete={isAuszifferungStorniert(auszifferung)}>
      <EuroAmount value={offenerBetrag} />
    </Typography.Text>
  );
};

export default SaldoOffenerBetragColumn;

const calculateSaldoOffenerBetrag = (opGesamtBetrag: number, opAuszifferungList: AuszifferungFormValues[], auszifferung: AuszifferungFormValues) => {
  const sumOpAzList = opAuszifferungList
    .filter((az) => dayjsCustom(az.createTs).isSameOrBefore(dayjsCustom(auszifferung.createTs)))
    .reduce((acc, currVal, currentIndex) => {
      const isPreviousAZStorniert = opAuszifferungList[currentIndex - 1]?.storniert ?? false;
      if (isPreviousAZStorniert) {
        const previousSaldo = opGesamtBetrag - acc;
        return acc + previousSaldo;
      }
      return acc + getFormattedDecimalValueOrZero(currVal.amount);
    }, 0);
  return opGesamtBetrag - sumOpAzList;
};
