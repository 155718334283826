import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useUmsatzsteuerkennzeichenListQuery } from './gql/UmsatzsteuerkennzeichenQueries.types';

type Props = SelectProps;

const UmsatzsteuerkennzeichenSelect: FC<Props> = ({ ...props }) => {
  const { loading, data } = useUmsatzsteuerkennzeichenListQuery();

  const umsatzsteuerkennzeichenList = data?.getUmsatzsteuerkennzeichenList.data ?? [];

  return (
    <Select
      size="small"
      id={props.name}
      loading={loading}
      placeholder="Umsatzsteuerkennzeichen auswählen"
      allowClear
      showSearch
      optionFilterProp="children"
      {...props}
    >
      {umsatzsteuerkennzeichenList.map((umsatzsteuerkennzeichen) => (
        <Select.Option
          key={umsatzsteuerkennzeichen.umsatzsteuerkennzeichenId}
          value={umsatzsteuerkennzeichen.umsatzsteuerkennzeichenId}
        >{`${umsatzsteuerkennzeichen.kuerzel} - ${umsatzsteuerkennzeichen.bezeichnung}`}</Select.Option>
      ))}
    </Select>
  );
};

export default UmsatzsteuerkennzeichenSelect;
