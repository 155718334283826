import * as Yup from 'yup';
import { formattedDecimalType } from '../../../components/Input-antd';
import { entityHasToBeANumber, entityHasToBeNonNegative, entityIsRequired, entityMaxCharLength } from '../../../components/message/validationMsg';
import { steuersatzValidationSchema } from '../../../shared/BruttoNettoUst/bruttoNettoUstSteuerValidationSchema';
import { incomingInvoiceBuchungPositionFormFields, incomingInvoiceFormFields, partialAmountFormFields } from './incomingInvoiceFormMapper';
import { isBillingTypeEinzelrechnung } from '../../Payment/paymentHelpers';
import { calculateBruttoNettoUst } from '../incomingInvoiceHelpers';

/* eslint-disable no-template-curly-in-string */

const buchungPositionValidationSchema = Yup.object().shape({
  [incomingInvoiceBuchungPositionFormFields.betrag]: formattedDecimalType(entityHasToBeANumber('Betrag'))
    .required(entityIsRequired('Betrag'))
    .min(0, entityHasToBeNonNegative('Betrag')),
  [incomingInvoiceBuchungPositionFormFields.buchungstext]: Yup.string()
    .required(entityIsRequired('Buchungstext'))
    .max(255, entityMaxCharLength('Buchungstext', 50)),
  [incomingInvoiceBuchungPositionFormFields.steuersatz]: steuersatzValidationSchema,
  [incomingInvoiceBuchungPositionFormFields.kontoId]: Yup.string().required(entityIsRequired('Konto')),
});

const partialAmountValidationSchema = Yup.object().shape({
  [partialAmountFormFields.dueDate]: Yup.date().required(entityIsRequired('Fällig am')),
  [partialAmountFormFields.buchungPositionList]: Yup.array().of(buchungPositionValidationSchema).min(1, entityIsRequired('Mindestens eine Position')),
});

export const incomingInvoiceFormUpdateValidationSchema = Yup.object().shape({
  [incomingInvoiceFormFields.abgrenzungsBuchung]: Yup.boolean().when([incomingInvoiceFormFields.billingType], {
    is: (value: any) => isBillingTypeEinzelrechnung(value),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  [incomingInvoiceFormFields.abgrenzungsBuchungsdatum]: Yup.date().when([incomingInvoiceFormFields.abgrenzungsBuchung], {
    is: true,
    then: (schema) => schema.required(entityIsRequired('Abgrenzungsbuchngsdatum')),
    otherwise: (schema) => schema.nullable(),
  }),
  [incomingInvoiceFormFields.ausstellerRechtstraegerId]: Yup.string().required(entityIsRequired('Lieferant')),
  [incomingInvoiceFormFields.buchungsdatum]: Yup.date().when(incomingInvoiceFormFields.billingType, {
    is: (value: any) => isBillingTypeEinzelrechnung(value),
    then: (schema) => schema.required(entityIsRequired('Buchngssdatum')),
    otherwise: (schema) => schema.nullable(),
  }),
  [incomingInvoiceFormFields.payeeRechtstraegerId]: Yup.string().required(entityIsRequired('Empfänger')),
  [incomingInvoiceFormFields.issuerBankDetailsId]: Yup.string().required(entityIsRequired('Lieferant Bankverbindung')),
  [incomingInvoiceFormFields.kundenNummer]: Yup.string().nullable().max(50, entityMaxCharLength('Kundennummer', 50)),
  [incomingInvoiceFormFields.objektId]: Yup.string(),
  [incomingInvoiceFormFields.paymentMethod]: Yup.string().required(entityIsRequired('Zahlungsart')),
  [incomingInvoiceFormFields.paymentReferenceText]: Yup.string().nullable().max(140, entityMaxCharLength('Zahlungsreferenz', 140)),
  [incomingInvoiceFormFields.purposeOfUseText]: Yup.string().nullable().max(140, entityMaxCharLength('Verwendungszweck', 140)),
  [incomingInvoiceFormFields.rechnungsNummer]: Yup.string()
    .required(entityIsRequired('Rechnungsnummer'))
    .max(50, entityMaxCharLength('Rechnungsnummer', 50)),
  [incomingInvoiceFormFields.rechnungsDatum]: Yup.date().required(entityIsRequired('Rechnungsdatum')),
  [incomingInvoiceFormFields.payeeBankDetailsId]: Yup.string().required(entityIsRequired('Rechnungsempfänger Bankverbindung')),
  [incomingInvoiceFormFields.dueDate]: Yup.date().when(incomingInvoiceFormFields.billingType, {
    is: (value: any) => isBillingTypeEinzelrechnung(value),
    then: (schema) => schema.required(entityIsRequired('Fällig am')),
    otherwise: (schema) => schema.nullable(),
  }),
  [incomingInvoiceFormFields.buchungPositionList]: Yup.array().when(incomingInvoiceFormFields.billingType, {
    is: (value: any) => isBillingTypeEinzelrechnung(value),
    then: (schema) => schema.of(buchungPositionValidationSchema).min(1, entityIsRequired('Mindestens eine Position')),
    otherwise: (schema) => schema.nullable(),
  }),
  [incomingInvoiceFormFields.totalAmountOfBuchungsposition]: Yup.number().when(incomingInvoiceFormFields.billingType, {
    is: (value: any) => isBillingTypeEinzelrechnung(value),
    then: (schema) => {
      return schema
        .required('Der Gesamtbetrag Brutto stimmt nicht mit der Summe der Beträge der Buchungspositionen überein.')
        .test(
          'is-total-amount-equal-to-balance',
          'Der Gesamtbetrag Brutto stimmt nicht mit der Summe der Beträge der Buchungspositionen überein.',
          function (value) {
            const { brutto: sumBrutto } = calculateBruttoNettoUst(this.parent.buchungPositionList, this.parent.inklusiveSteuer);

            return !(value !== sumBrutto);
          }
        );
    },
    otherwise: (schema) => schema.nullable(),
  }),
  [incomingInvoiceFormFields.partialAmountList]: Yup.array().when(incomingInvoiceFormFields.billingType, {
    is: (value: any) => !isBillingTypeEinzelrechnung(value),
    then: (schema) => schema.of(partialAmountValidationSchema).min(1, entityIsRequired('Mindestens eine Position')),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const incomingInvoiceFormCreateValidationSchema = incomingInvoiceFormUpdateValidationSchema.shape({
  [incomingInvoiceFormFields.billingType]: Yup.string().required(entityIsRequired('Verrechnungsart')),
});

/* eslint-enable no-template-curly-in-string */
