import React from 'react';
import { Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import { isNil, isNotNil } from '../../../../../helpers/assertionHelper';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { Percent } from '../../../../../components/Number';
import { UstKategorie } from '../../../../../types';
import { NestedTableColProps } from '../../../../../components/Table/NestedTable/NestedTable';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import UstKategorieTemplateTableActions from './UstKategorieTemplateTableActions';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

export const ustKategorieTemplateTableColumns = (onActionSuccess: () => void): NestedTableColProps<UstKategorie>[] => [
  {
    title: 'Kurzbezeichnung',
    width: 200,
    render: (text, record) => <DataWithShortenedText text={record.kurzBezeichnung} maxTextLength={25} />,
  },
  {
    title: 'Vst.-Kürzung',
    render: (text, record) => {
      if (isNil(record.vstKuerzung)) {
        return <TextForEmptyValue textToShow="NV" />;
      } else {
        return record.vstKuerzung ? <Typography.Text>JA</Typography.Text> : <TextForEmptyValue textToShow="minus" />;
      }
    },
  },
  {
    title: 'USt. vom Aufwand',
    render: (text, record) =>
      isNotNil(record.ustVomAufwand) ? <Percent value={record.ustVomAufwand / 100} /> : <TextForEmptyValue textToShow="NV" />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    key: 'action',
    render: (text, record) => <UstKategorieTemplateTableActions onActionSuccess={onActionSuccess} ustKategorie={record} />,
  },
];
