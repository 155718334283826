import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorschreibungspositionArtListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type VorschreibungspositionArtListQuery = {
  getVorschreibungspositionArtList: {
    data: Array<{ text: string; value: Types.VorschreibungspositionArt }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VorschreibungspositionArtListDocument = gql`
  query VorschreibungspositionArtList {
    getVorschreibungspositionArtList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVorschreibungspositionArtListQuery(
  baseOptions?: Apollo.QueryHookOptions<VorschreibungspositionArtListQuery, VorschreibungspositionArtListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungspositionArtListQuery, VorschreibungspositionArtListQueryVariables>(
    VorschreibungspositionArtListDocument,
    options
  );
}
export function useVorschreibungspositionArtListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorschreibungspositionArtListQuery, VorschreibungspositionArtListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungspositionArtListQuery, VorschreibungspositionArtListQueryVariables>(
    VorschreibungspositionArtListDocument,
    options
  );
}
export function useVorschreibungspositionArtListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VorschreibungspositionArtListQuery, VorschreibungspositionArtListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungspositionArtListQuery, VorschreibungspositionArtListQueryVariables>(
    VorschreibungspositionArtListDocument,
    options
  );
}
export type VorschreibungspositionArtListQueryHookResult = ReturnType<typeof useVorschreibungspositionArtListQuery>;
export type VorschreibungspositionArtListLazyQueryHookResult = ReturnType<typeof useVorschreibungspositionArtListLazyQuery>;
export type VorschreibungspositionArtListSuspenseQueryHookResult = ReturnType<typeof useVorschreibungspositionArtListSuspenseQuery>;
export type VorschreibungspositionArtListQueryResult = Apollo.QueryResult<
  VorschreibungspositionArtListQuery,
  VorschreibungspositionArtListQueryVariables
>;
