import React, { FC, PropsWithChildren } from 'react';
import { List } from 'antd';
import { StyledInfoFeldIcon } from '../../../../../shared/InfoFeld/styled/StyledInfoFeldIcon.style';
import { StyledInfoFeldListItemSpace } from '../../../../../shared/InfoFeld/styled/StyledInfoFeldListItemSpace.style';

type Props = {
  icon: React.ReactNode;
  content: { fileIdList: string[]; props?: any };
  actions: React.ReactNode;
};

const InfoFeldListItem: FC<Props & PropsWithChildren> = ({ icon, content, actions, children }) => (
  <>
    <List.Item>
      <StyledInfoFeldListItemSpace>
        <StyledInfoFeldIcon showBorder>{icon}</StyledInfoFeldIcon>
        <StyledInfoFeldListItemSpace {...content.props}>{children}</StyledInfoFeldListItemSpace>
        {actions}
      </StyledInfoFeldListItemSpace>
    </List.Item>
  </>
);

export default InfoFeldListItem;
