import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartAuftragGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartAuftragGenerierlaufMutation = {
  actionRestartAuftragGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateAuftragGenerierlaufMutationVariables = Types.Exact<{
  auftragsGenerierlaufInput: Types.AuftragGenerierlaufInput;
}>;

export type CreateAuftragGenerierlaufMutation = {
  createAuftragGenerierlauf: { warningList: Array<{ type: string; message: string }>; errorList: Array<{ type: string; message: string }> };
};

export const RestartAuftragGenerierlaufDocument = gql`
  mutation RestartAuftragGenerierlauf($generierlaufId: ID!) {
    actionRestartAuftragGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartAuftragGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartAuftragGenerierlaufMutation, RestartAuftragGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartAuftragGenerierlaufMutation, RestartAuftragGenerierlaufMutationVariables>(
    RestartAuftragGenerierlaufDocument,
    options
  );
}
export type RestartAuftragGenerierlaufMutationHookResult = ReturnType<typeof useRestartAuftragGenerierlaufMutation>;
export type RestartAuftragGenerierlaufMutationResult = Apollo.MutationResult<RestartAuftragGenerierlaufMutation>;
export type RestartAuftragGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartAuftragGenerierlaufMutation,
  RestartAuftragGenerierlaufMutationVariables
>;
export const CreateAuftragGenerierlaufDocument = gql`
  mutation CreateAuftragGenerierlauf($auftragsGenerierlaufInput: AuftragGenerierlaufInput!) {
    createAuftragGenerierlauf(request: $auftragsGenerierlaufInput) {
      warningList {
        type
        message
      }
      errorList {
        type
        message
      }
    }
  }
`;
export function useCreateAuftragGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAuftragGenerierlaufMutation, CreateAuftragGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAuftragGenerierlaufMutation, CreateAuftragGenerierlaufMutationVariables>(
    CreateAuftragGenerierlaufDocument,
    options
  );
}
export type CreateAuftragGenerierlaufMutationHookResult = ReturnType<typeof useCreateAuftragGenerierlaufMutation>;
export type CreateAuftragGenerierlaufMutationResult = Apollo.MutationResult<CreateAuftragGenerierlaufMutation>;
export type CreateAuftragGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateAuftragGenerierlaufMutation,
  CreateAuftragGenerierlaufMutationVariables
>;
