import React, { FC } from 'react';
import { Row, Space, Typography } from 'antd';
import { Ticket, TicketAttachment } from '../../../types';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { UploadedFileView } from '../../shared/UploadedFileView';
import subTicketTooltipContent from './Tooltip/subTicketTooltipContent';
import emailTooltipContent from './Tooltip/emailTooltipContent';
import { TicketAttachedEmail } from '../ticketHelpers';
import tooltipFooterContent from './Tooltip/tooltipFooterContent';

type Props = {
  ticket: Ticket;
  refetch: () => void;
};

type TicketAttachmentWithEmail = { email?: TicketAttachedEmail } & TicketAttachment;

const TicketAttachmentsInfoRow: FC<Props> = ({ ticket }) => {
  const attachments: TicketAttachmentWithEmail[] = [
    ...ticket.attachments,
    ...ticket.emailList
      .map((email) =>
        email.attachments.map((attachment) => ({
          ...attachment,
          ...{
            email: {
              sender: email.sender,
              sentTs: email.sentTs,
              subject: email.subject,
            },
          },
        }))
      )
      .flat(),
  ];

  return attachments.length === 0 ? (
    <CardDetailsInfoRow infoRowTitle="Anhänge">
      <TextForEmptyValue textToShow="minus" />
    </CardDetailsInfoRow>
  ) : (
    <>
      <Row style={{ width: '100%', marginBottom: 16 }}>
        <Typography.Text type="secondary">Anhänge</Typography.Text>
      </Row>
      <Space direction="vertical">
        {attachments.map((attachment, index) => {
          return (
            <UploadedFileView
              key={index}
              disableDelete
              filename={attachment.fileInfo.name}
              tooltipFooter={tooltipFooterContent([getTooltipContent(attachment, ticket)])}
              onDelete={() => {}}
              fileId={attachment.fileInfo.fileId}
            />
          );
        })}
      </Space>
    </>
  );
};

const getTooltipContent = (attachment: TicketAttachmentWithEmail, ticket: Ticket) => {
  const isOwnAttachment = attachment.ticketId === ticket.ticketId && !attachment.email;
  const isOwnEmailAttachment = attachment.ticketId === ticket.ticketId && attachment.email;

  if (isOwnAttachment) {
    return null;
  } else if (isOwnEmailAttachment) {
    return emailTooltipContent(attachment.email);
  } else {
    return subTicketTooltipContent(ticket.subTicketList, attachment.ticketId);
  }
};

export default TicketAttachmentsInfoRow;
