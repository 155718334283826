import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartVorschreibungMailDeliverySendGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartVorschreibungMailDeliverySendGenerierlaufMutation = {
  actionRestartVorschreibungMailDeliverySendGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartVorschreibungMailDeliverySendGenerierlaufDocument = gql`
  mutation RestartVorschreibungMailDeliverySendGenerierlauf($generierlaufId: ID!) {
    actionRestartVorschreibungMailDeliverySendGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartVorschreibungMailDeliverySendGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartVorschreibungMailDeliverySendGenerierlaufMutation,
    RestartVorschreibungMailDeliverySendGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestartVorschreibungMailDeliverySendGenerierlaufMutation,
    RestartVorschreibungMailDeliverySendGenerierlaufMutationVariables
  >(RestartVorschreibungMailDeliverySendGenerierlaufDocument, options);
}
export type RestartVorschreibungMailDeliverySendGenerierlaufMutationHookResult = ReturnType<
  typeof useRestartVorschreibungMailDeliverySendGenerierlaufMutation
>;
export type RestartVorschreibungMailDeliverySendGenerierlaufMutationResult =
  Apollo.MutationResult<RestartVorschreibungMailDeliverySendGenerierlaufMutation>;
export type RestartVorschreibungMailDeliverySendGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartVorschreibungMailDeliverySendGenerierlaufMutation,
  RestartVorschreibungMailDeliverySendGenerierlaufMutationVariables
>;
