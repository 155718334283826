import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
export type AbrInfoAbrechnungskreisFieldsFragment = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
  abrechnungskreisId: string;
  bezeichnung: string;
  saldo: number;
  sumAufwand: number;
  vorsaldo: number;
  aufwand: {
    summe: number;
    kontoList: Array<{
      bezeichnung: string;
      kontoId: string;
      summe: number;
      buchungszeileList: Array<{
        auftragId?: string | null;
        belegDatum?: string | null;
        belegFileId?: string | null;
        belegId: string;
        belegnummer?: string | null;
        betrag: number;
        buchungIdList: Array<string>;
        dueDate?: string | null;
        standingInvoicePartialAmountId?: string | null;
        text: string;
        umbuchung?: boolean | null;
        umgebucht?: boolean | null;
        buchungskreisRechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        sollHaben: { text: string; value: Types.SollHaben };
        vertragspartner?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
      }>;
    }>;
  };
  erloes: {
    summe: number;
    kontoList: Array<{
      bezeichnung: string;
      kontoId: string;
      summe: number;
      buchungszeileList: Array<{
        auftragId?: string | null;
        belegDatum?: string | null;
        belegFileId?: string | null;
        belegId: string;
        belegnummer?: string | null;
        betrag: number;
        buchungIdList: Array<string>;
        dueDate?: string | null;
        standingInvoicePartialAmountId?: string | null;
        text: string;
        umbuchung?: boolean | null;
        umgebucht?: boolean | null;
        buchungskreisRechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        sollHaben: { text: string; value: Types.SollHaben };
        vertragspartner?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
      }>;
    }>;
  };
};

export type AbrInfoKontoFieldsFragment = {
  bezeichnung: string;
  kontoId: string;
  summe: number;
  buchungszeileList: Array<{
    auftragId?: string | null;
    belegDatum?: string | null;
    belegFileId?: string | null;
    belegId: string;
    belegnummer?: string | null;
    betrag: number;
    buchungIdList: Array<string>;
    dueDate?: string | null;
    standingInvoicePartialAmountId?: string | null;
    text: string;
    umbuchung?: boolean | null;
    umgebucht?: boolean | null;
    buchungskreisRechtstraeger: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    sollHaben: { text: string; value: Types.SollHaben };
    vertragspartner?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
  }>;
};

export const AbrInfoKontoFieldsFragmentDoc = gql`
  fragment AbrInfoKontoFields on AbrInfoKonto {
    bezeichnung
    buchungszeileList {
      auftragId
      belegDatum
      belegFileId
      belegId
      belegnummer
      betrag
      buchungIdList
      buchungskreisRechtstraeger {
        ...RechtstraegerBaseFields
      }
      dueDate
      sollHaben {
        text
        value
      }
      standingInvoicePartialAmountId
      text
      umbuchung
      umgebucht
      vertragspartner {
        ...RechtstraegerBaseFields
      }
    }
    kontoId
    summe
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export const AbrInfoAbrechnungskreisFieldsFragmentDoc = gql`
  fragment AbrInfoAbrechnungskreisFields on AbrInfoAbrechnungskreis {
    abrechnungsdefinitionId
    abrechnungsdefinitionVersionId
    abrechnungskreisId
    aufwand {
      kontoList {
        ...AbrInfoKontoFields
      }
      summe
    }
    bezeichnung
    erloes {
      kontoList {
        ...AbrInfoKontoFields
      }
      summe
    }
    saldo
    sumAufwand
    vorsaldo
  }
  ${AbrInfoKontoFieldsFragmentDoc}
`;
