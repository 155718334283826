import * as Yup from 'yup';
import { entityIsRequired } from '../../../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */

export const auftragsartTypeSelectFormValidationSchema = Yup.object().shape({
  type: Yup.string().required(entityIsRequired('Auftragsart-Typ')),
});

/* eslint-enable no-template-curly-in-string */
