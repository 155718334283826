import React, { FC } from 'react';
import { showSuccessMsgDelete } from '../../../../../../components/message';
import { useDeleteBeInfoFeldMutation } from '../../../gql/BeInfoFeldMutations.types';
import { BeInfoFeld_BeInfoFeldParkplatz_Fragment } from '../../../gql/BeInfoFeldFragments.types';
import ParkplatzUpdateForm from '../../../Form/Parkplatz/Update/ParkplatzUpdateForm';
import useTicketCount from '../../../../../Ticket/useTicketCount';
import InfoFeldListItemActions from '../InfoFeldListItemActions';

type Props = {
  infoFeld: BeInfoFeld_BeInfoFeldParkplatz_Fragment;
  objektId: string;
  bestandseinheitId: string;
  onSuccess: () => void;
};

const ParkplatzListItemActions: FC<Props> = ({ infoFeld, objektId, bestandseinheitId, onSuccess }) => {
  const { ticketCount, refetchTicketCount } = useTicketCount({ beInfoFeldId: infoFeld.beInfoFeldId });

  const onActionSuccess = () => {
    onSuccess();
    refetchTicketCount();
  };

  const [runDelete] = useDeleteBeInfoFeldMutation({
    variables: { objektId, bestandseinheitId, infoFeldId: infoFeld.beInfoFeldId },
    onCompleted: () => {
      showSuccessMsgDelete(`Parkplatz Infofeld`);
      onSuccess();
    },
  });

  return (
    <InfoFeldListItemActions
      onDelete={runDelete}
      ticketCount={ticketCount}
      objektId={objektId}
      bestandseinheitId={bestandseinheitId}
      beInfoFeldId={infoFeld.beInfoFeldId}
      fileIdList={infoFeld.fileIdList}
      onSuccess={onActionSuccess}
      postIt={infoFeld.postIt}
      deletable={infoFeld.deletable}
      infoFeldStatus={infoFeld.status}
      renderUpdateForm={(closeDrawer, activeKeyTab) => (
        <ParkplatzUpdateForm
          infoFeld={infoFeld}
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          onInfoFeldAction={() => {
            onSuccess();
            closeDrawer();
          }}
          onTicketAction={onSuccess}
          onCancel={closeDrawer}
          activeKeyTab={activeKeyTab}
        />
      )}
    />
  );
};

export default ParkplatzListItemActions;
