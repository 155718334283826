import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Empty, Spin } from 'antd';
import { useInfoMailDeliveryDetermineRecipientListGenerierlaufQuery } from '../../../../Verarbeitung/gql/InfoMailDeliveryDetermineRecipientListGenerierLauf/InfoMailDeliveryDetermineRecipientListQueries.types';
import DetermineRecipientListStepNotificationList from './DetermineRecipientListStepNotificationList';
import { generatePathToVerarbeitungInfoMailDeliveryDetermineRecipientListDetailsPage } from '../../../../Verarbeitung/verarbeitungApiPaths';
import { InfoMailDeliveryWizardDetermineRecipientListStep } from '../../../../../types';

type Props = {
  determineRecipientListData: InfoMailDeliveryWizardDetermineRecipientListStep;
  onComplete: () => void;
  onCancel: () => void;
  onBack: () => void;
};
const DetermineRecipientListGenerierlauf: FC<Props> = ({ determineRecipientListData, onComplete, onBack, onCancel }) => {
  const { generierlaufId, completed, wizardId } = determineRecipientListData;

  const { data, stopPolling } = useInfoMailDeliveryDetermineRecipientListGenerierlaufQuery({
    variables: { generierlaufId },
    pollInterval: 500,
  });

  const generierlaufData = data?.getInfoMailDeliveryDetermineRecipientListGenerierlauf.data;

  if (!generierlaufData || generierlaufData.status.value === 'ERSTELLT') {
    return <Spin />;
  }

  let emptyText = null;

  if (['BEENDET_NO_DATA_FOUND'].includes(generierlaufData.status.value)) {
    emptyText = (
      <Empty description="">
        <Link to={generatePathToVerarbeitungInfoMailDeliveryDetermineRecipientListDetailsPage(generierlaufId)}>Keine Empfänger gefunden</Link>
      </Empty>
    );
  }

  if (['BEENDET', 'BEENDET_FEHLER', 'BEENDET_NO_DATA_FOUND'].includes(generierlaufData.status.value)) {
    stopPolling();
  }

  return (
    <DetermineRecipientListStepNotificationList
      isCompleted={completed}
      generierlaufId={generierlaufId}
      wizardId={wizardId}
      onStepComplete={onComplete}
      onCancel={onCancel}
      onBack={onBack}
      emptyText={emptyText}
    />
  );
};

export default DetermineRecipientListGenerierlauf;
