import React, { FC } from 'react';
import { EmailDeliveryDetailsPageProps } from '../../../features/EmailDelivery/emailDeliveryHelpers';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import VorschreibungMailDeliveryDetailsCard from '../../../features/EmailDelivery/VorschreibungMailDelivery/Card/VorschreibungMailDeliveryDetailsCard';
import menuListEmailDelivery from './menuListEmailDelivery';
import { MailData } from '../../../types';

const VorschreibungMailDeliveryDetailsPage: FC<EmailDeliveryDetailsPageProps> = ({ mail, loading, refetch }) => {
  return (
    <DetailsPageTemplate<MailData>
      data={mail}
      pageTitle={() => 'E-mail Versand'}
      isLoading={loading}
      card={(emailDelivery) => <VorschreibungMailDeliveryDetailsCard emailDelivery={emailDelivery} refetch={refetch} />}
      sidebarMenuList={(emailDelivery) => menuListEmailDelivery(emailDelivery)}
    />
  );
};

export default VorschreibungMailDeliveryDetailsPage;
