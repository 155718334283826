import React, { FC, useRef, useState } from 'react';
import { Skeleton } from 'antd';
import { FormikProps } from 'formik';
import { useTicketQuery } from '../../gql/TicketQueries.types';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { useOnTicketDetailsDataChangedEvents } from '../../useOnTicketDataChangedEvents';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import TicketEmailsForm from '../TicketEmailsForm/TicketEmailsForm';
import { mapFormValuesToTicketEmails, TicketEmailsFormValues } from '../TicketEmailsForm/ticketEmailsFormMapper';
import { handleSyncEmails } from '../TicketEmailsForm/handleSyncEmails';

type Props = {
  ticketId: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const TicketEmailsUpdateForm: FC<Props> = ({ ticketId, onSuccess, onCancel }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data, loading, refetch } = useTicketQuery({ variables: { ticketId: ticketId! }, skip: !ticketId });
  const [isLoading, setIsLoading] = useState(false);

  const ticket = data?.getTicket.data;
  const formRef = useRef<FormikProps<TicketEmailsFormValues>>(null);

  useOnTicketDetailsDataChangedEvents('ticket-emails', refetch, ticketId);

  const handleSubmit = () => {
    setIsLoading(true);
    const syncEmails = () => {
      if (!(formRef.current?.values.newEmailList.length || formRef.current?.values.deletableIds.length)) return Promise.resolve();

      return handleSyncEmails(firmendatenId, ticketId, formRef.current.values, mapFormValuesToTicketEmails(formRef.current.values));
    };

    syncEmails()
      .then(() => {
        showSuccessMsgUpdate('Anhänge');
        onSuccess();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Skeleton active paragraph={{ rows: 10 }} loading={loading}>
      {ticket && (
        <>
          <TicketEmailsForm emailList={ticket.emailList} ref={formRef} />
          <FormButtons updateMode onCancel={onCancel} isOkDisabled={isLoading} isOkLoading={loading || isLoading} onOk={handleSubmit} />
        </>
      )}
    </Skeleton>
  );
};

export default TicketEmailsUpdateForm;
