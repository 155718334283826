import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { VerarbeitungInfoMailDeliveryDetermineDetailsPageRouteParams } from './routes';
import menuListInfoMailDeliveryDetermineRecipientList from './menuListInfoMailDeliveryDetermineRecipientList';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { useInfoMailDeliveryDetermineRecipientListGenerierlaufQuery } from '../../../features/Verarbeitung/gql/InfoMailDeliveryDetermineRecipientListGenerierLauf/InfoMailDeliveryDetermineRecipientListQueries.types';
import { InfoMailDeliveryDetermineRecipientListGenerierlauf } from '../../../types';
import { useRestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutation } from '../../../features/Verarbeitung/gql/InfoMailDeliveryDetermineRecipientListGenerierLauf/InfoMailDeliveryDetermineRecipientListMutations.types';

const InfoMailDeliveryDetermineRecipientListDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungInfoMailDeliveryDetermineDetailsPageRouteParams;

  const { data, refetch } = useInfoMailDeliveryDetermineRecipientListGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getInfoMailDeliveryDetermineRecipientListGenerierlauf.data;

  const [runRestart] = useRestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<InfoMailDeliveryDetermineRecipientListGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.InfoMailDeliveryDetermineRecipientList}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.InfoMailDeliveryDetermineRecipientList}
          onGenerierlaufRestart={runRestart}
        />
      )}
      isLoading={false}
      sidebarMenuList={menuListInfoMailDeliveryDetermineRecipientList}
    />
  );
};

export default InfoMailDeliveryDetermineRecipientListDetailsPage;
