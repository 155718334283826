import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { TopAbrechnung } from '../../../topAbrechnung-types';

const TAAbrKreisSummeRow: FC<{ data: TopAbrechnung }> = ({ data }) => (
  <View style={[pdfStyles.row]}>
    <Text style={[pdfStyles.textNormal, { width: '15%' }]} />
    <Text
      style={[
        pdfStyles.textNormal,
        {
          textAlign: 'right',
          width: '15%',
        },
      ]}
    >
      {data.deckblatt.abrechnungskreisSummary.summe.aufwand}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        {
          textAlign: 'right',
          width: '15%',
        },
      ]}
    >
      {data.deckblatt.abrechnungskreisSummary.summe.vorschreibung}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        {
          textAlign: 'right',
          width: '15%',
        },
      ]}
    >
      {data.deckblatt.abrechnungskreisSummary.summe.netto}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        {
          textAlign: 'right',
          width: '15%',
        },
      ]}
    >
      {data.deckblatt.abrechnungskreisSummary.summe.ust}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        {
          textAlign: 'right',
          width: '15%',
        },
      ]}
    >
      {data.deckblatt.abrechnungskreisSummary.summe.saldo}
    </Text>
  </View>
);

export default TAAbrKreisSummeRow;
