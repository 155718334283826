import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RechtsTraegerRolleListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechtsTraegerRolleListQuery = {
  getRechtstraegerRolleList: {
    data: Array<Types.RechtstraegerRolle>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RechtsTraegerRolleListDocument = gql`
  query RechtsTraegerRolleList($rechtstraegerId: ID!) {
    getRechtstraegerRolleList(rechtstraegerId: $rechtstraegerId) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRechtsTraegerRolleListQuery(
  baseOptions: Apollo.QueryHookOptions<RechtsTraegerRolleListQuery, RechtsTraegerRolleListQueryVariables> &
    ({ variables: RechtsTraegerRolleListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtsTraegerRolleListQuery, RechtsTraegerRolleListQueryVariables>(RechtsTraegerRolleListDocument, options);
}
export function useRechtsTraegerRolleListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtsTraegerRolleListQuery, RechtsTraegerRolleListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtsTraegerRolleListQuery, RechtsTraegerRolleListQueryVariables>(RechtsTraegerRolleListDocument, options);
}
export function useRechtsTraegerRolleListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtsTraegerRolleListQuery, RechtsTraegerRolleListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtsTraegerRolleListQuery, RechtsTraegerRolleListQueryVariables>(RechtsTraegerRolleListDocument, options);
}
export type RechtsTraegerRolleListQueryHookResult = ReturnType<typeof useRechtsTraegerRolleListQuery>;
export type RechtsTraegerRolleListLazyQueryHookResult = ReturnType<typeof useRechtsTraegerRolleListLazyQuery>;
export type RechtsTraegerRolleListSuspenseQueryHookResult = ReturnType<typeof useRechtsTraegerRolleListSuspenseQuery>;
export type RechtsTraegerRolleListQueryResult = Apollo.QueryResult<RechtsTraegerRolleListQuery, RechtsTraegerRolleListQueryVariables>;
