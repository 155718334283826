import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './filtersFormMapper';
import { TRechtstraegerQueryParams } from './filtersQueryParams';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import RechtstraegerBtnAndModal from '../RechtstraegerBtnAndModal';
import RechtstreagerDebounceTextSearchFormInput from './RechtstreagerDebounceTextSearchFormInput';
import RechtstraegerTypeListFormSelect from './RechtstraegerTypeListFormSelect';
import RechtstraegerTagListFormSelect from './RechtstraegerTagListFormSelect';
import RechtstraegerTableBulkActions from '../RechtstraegerTableBulkActions';
import RechtstraegerKundennummerListFormSelect from './RechtstraegerKundennummerListFormSelect';
import useFilterWithQueryParamsNew from '../../../../components/Filters/useFilterWithQueryParamsNew';
import FiltersWithSelectorWrapper from '../../../../components/Filters/FiltersWithSelector/FiltersWithSelectorWrapper';
import FormItemForFilter from '../../../../components/Form/FormItemForFilter';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  queryParams: TRechtstraegerQueryParams;
  onAction: () => void;
};

const ListingFilters: FC<Props> = ({ formikProps, queryParams, onAction }) => {
  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.createTsFrom || filter === filtersFormFields.createTsTo) {
      return filtersFormFields.createTsFrom;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === filtersFormFields.kurzBezeichnung) {
      return (
        <FormItemForFilter name={filtersFormFields.kurzBezeichnung} key={filter}>
          <RechtstreagerDebounceTextSearchFormInput formikProps={formikProps} name={filtersFormFields.kurzBezeichnung} />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.typeList) {
      return (
        <FormItemForFilter name={filtersFormFields.typeList} key={filter}>
          <RechtstraegerTypeListFormSelect
            name={filtersFormFields.typeList}
            mode="multiple"
            onChange={formikProps.submitForm}
            prefix={listingLabelList.typeList}
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.createTsFrom) {
      return (
        <FormItemForFilter name="erstelltAm" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.createTsFrom}
            toName={filtersFormFields.createTsTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${listingLabelList.createTsFrom} - von`}
            toPrefix={`${listingLabelList.createTsFrom} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.erstellerMitarbeiterIdList) {
      return (
        <FormItemForFilter name={filtersFormFields.erstellerMitarbeiterIdList} key={filter}>
          <MitarbeiterSelect
            name={filtersFormFields.erstellerMitarbeiterIdList}
            onChange={formikProps.submitForm}
            mode="multiple"
            prefix={listingLabelList.erstellerMitarbeiterIdList}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.tagList) {
      return (
        <FormItemForFilter name={filtersFormFields.tagList} key={filter}>
          <RechtstraegerTagListFormSelect
            name={filtersFormFields.tagList}
            mode="multiple"
            onChange={formikProps.submitForm}
            prefix={listingLabelList.tagList}
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.kundennummer) {
      return (
        <FormItemForFilter name={filtersFormFields.kundennummer} key={filter}>
          <RechtstraegerKundennummerListFormSelect
            name={filtersFormFields.kundennummer}
            onChange={formikProps.submitForm}
            prefix={listingLabelList.kundennummer}
          />
        </FormItemForFilter>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.createTsFrom) && formikProps.values.createTsTo) {
      formikProps.setFieldValue(filtersFormFields.createTsTo, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, filterList } = useFilterWithQueryParamsNew({
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWithSelectorWrapper
      selectedFilterList={selectedFilterList}
      onSelectedFilterTitleList={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
      allFilterTitles={listingLabelList}
      filters={<>{filterList}</>}
      actionButtons={<RechtstraegerBtnAndModal onAction={onAction} formikProps={formikProps} />}
      filterActions={<RechtstraegerTableBulkActions queryParams={queryParams} />}
    />
  );
};

export default ListingFilters;
