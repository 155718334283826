import { FC } from 'react';
import HistoryListingTable from '../../../History/HistoryListingTable';
import { HistoryType } from '../../../History/shared/HistoryModal';
import { useKontierungstabelleTemplateChangeHistoryListQuery } from '../../gql/KontierungstabelleTemplateQueries.types';

type Props = {
  kontierungstabelleId: string;
};

const KontierungstabelleTemplateChangeHistoryListingTable: FC<Props> = ({ kontierungstabelleId }) => {
  const { data, loading } = useKontierungstabelleTemplateChangeHistoryListQuery({ variables: { kontierungstabelleId } });
  const historyList = data?.getKontierungstabelleTemplateChangeHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="kontierungstabelle-template" />
  );
};

export default KontierungstabelleTemplateChangeHistoryListingTable;
