import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { EigentuemerverrechnungData } from '../../../hauseigentuemerAbrechnung-types';
import pdfStyles from '../../../../styles/pdfStyles';
import { columnWidthsAndAlignment, paddingLeftForRow, widthRowWithoutAnteil } from './details-table-styles';
import HeABuchungszeile from '../../shared/HeABuchungszeile';
import HeABlockSumme from '../../shared/HeABlockSumme';

const HeAEigentuemerverrechnungBlock: FC<{ data: EigentuemerverrechnungData }> = ({ data }) => (
  <View style={[pdfStyles.column, { paddingTop: '5mm' }]}>
    <View style={pdfStyles.row}>
      <Text style={[pdfStyles.textNormal, { fontWeight: 'bold', textDecoration: 'underline' }]}>{data.positionName}</Text>
    </View>
    <View style={paddingLeftForRow.abrKreis}>
      <View style={[paddingLeftForRow.konto, { width: widthRowWithoutAnteil }]}>
        {data.kontoList.map((konto, index) => (
          <View key={index} style={pdfStyles.column}>
            <Text style={[pdfStyles.textNormal, { textDecoration: 'underline' }]}>{konto.kontoName}</Text>
            {konto.buchungszeileList?.map((buchungzeile, index) => (
              <HeABuchungszeile key={index} buchungszeile={buchungzeile} />
            ))}
          </View>
        ))}
      </View>

      {/* Anteil von Summe */}
      <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { paddingTop: '3mm' }]}>
        <Text
          style={[
            pdfStyles.textNormal,
            {
              width: columnWidthsAndAlignment.positionText.width + columnWidthsAndAlignment.objektsummeText.width,
              textAlign: columnWidthsAndAlignment.positionText.textAlign,
              fontWeight: 'bold',
            },
          ]}
        >
          {data.anteilVonSumme.label}
        </Text>
        <Text
          style={[
            pdfStyles.textNormal,
            { width: columnWidthsAndAlignment.anteilText.width, textAlign: columnWidthsAndAlignment.anteilText.textAlign, fontWeight: 'bold' },
          ]}
        >
          {data.anteilVonSumme.value}
        </Text>
      </View>
    </View>
    {/* Summe */}
    <HeABlockSumme data={data.summePosition} />
  </View>
);

export default HeAEigentuemerverrechnungBlock;
