import React, { FC } from 'react';
import { Select, SelectProps } from 'antd';
import { useBeInfoFeldTypeListQuery } from '../gql/BeInfoFeldTypeQueries.types';

const BeInfoFeldTypeFormSelect: FC<SelectProps> = ({ ...restProps }) => {
  const { data, loading } = useBeInfoFeldTypeListQuery();

  const beInfoFeldTypeList = data?.getBeInfoFeldTypeList.data ?? [];

  return (
    <Select placeholder="Typ auswählen" loading={loading} allowClear size="small" style={{ minWidth: '160px' }} {...restProps}>
      {beInfoFeldTypeList.map((beInfoFeldType) => (
        <Select.Option value={beInfoFeldType.value} key={beInfoFeldType.value}>
          {beInfoFeldType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BeInfoFeldTypeFormSelect;
