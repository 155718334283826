import React from 'react';
import { Typography } from 'antd';
import { CheckOutlined, MinusOutlined, WarningTwoTone } from '@ant-design/icons';
import { FormattedNumber } from 'react-intl';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { EuroAmount, Percent } from '../../../components/Number';
import HistoryHtmlAttribute from './HistoryHtmlAttribute';
import { HistoryAttribute, isAttributeFloat, isAttributePercent, isAttributeStringList, mapAttributeToValueBasedOnType } from './historyHelpers';
import { HistoryAttributeName, HistoryAttributeType } from '../../../types';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import theme from '../../../theme';
import { HistoryFieldsFragment } from '../gql/HistoryFragments.types';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';

type AttributeValue = string | number | boolean | React.ReactNode;
type AttributeColumn = HistoryAttributeName & { type: AttributeValue; scaling?: number | null };

const historyListingTableAttributeColumns = (historyList: HistoryFieldsFragment[] | undefined, compareRows?: boolean) => {
  const attributeColumns: AttributeColumn[] = [];

  const mappedHistoryList = (historyList ?? []).map((entry) => {
    const entries = Object.fromEntries(
      entry.attributeList.map((attributeListEntry) => {
        const { value, text } = attributeListEntry.name;

        if (!attributeColumns.some((colum) => colum.value === value)) {
          attributeColumns.push({
            value,
            text,
            type: attributeListEntry.type,
            ...((isAttributeFloat(attributeListEntry) || isAttributePercent(attributeListEntry)) && {
              scaling: attributeListEntry?.scaling,
            }),
            ...(isAttributeStringList(attributeListEntry) && {
              longText: attributeListEntry.longText,
            }),
          });
        }

        return [
          attributeListEntry.name.value,
          {
            type: attributeListEntry.type,
            value: mapAttributeToValueBasedOnType(attributeListEntry),
            ...((isAttributeFloat(attributeListEntry) || isAttributePercent(attributeListEntry)) && {
              scaling: attributeListEntry?.scaling,
            }),
            ...(isAttributeStringList(attributeListEntry) && {
              longText: attributeListEntry.longText,
            }),
          },
        ];
      })
    );

    entries.UNDEFINED = { value: undefined, type: HistoryAttributeType.String };
    return entries;
  });

  const attributeColumnsData: TableWithColSelectorColumnProps<HistoryFieldsFragment>[] = attributeColumns.map((column) => ({
    title: column.text,
    align: [HistoryAttributeType.Amount, HistoryAttributeType.Float, HistoryAttributeType.Percent].includes(column.type as HistoryAttributeType)
      ? 'right'
      : undefined,
    render: (text, record, index) => {
      let value: AttributeValue = (
        <Typography.Text>
          <WarningTwoTone twoToneColor={theme.colors.warning} /> Keine Daten
        </Typography.Text>
      );
      const attribute = mappedHistoryList[index][column.value];
      if (attribute) {
        value = getAttributeValue(attribute);
      }

      return typeof value !== 'string' ? value : <DataWithShortenedText text={value} maxTextLength={18} />;
    },
    ...(compareRows
      ? {
          onCell: (record, index) => {
            const attribute = mappedHistoryList[Number(index)]?.[column.value] ?? mappedHistoryList[Number(index)]?.UNDEFINED;
            const prevAttribute = mappedHistoryList[Number(index) + 1]?.[column.value] ?? mappedHistoryList[Number(index) + 1]?.UNDEFINED;
            const colText = getAttributeValue(attribute, true);
            const prevColText = getAttributeValue(prevAttribute, true);

            return {
              style: {
                background: !!prevAttribute && colText !== prevColText ? theme.tableBackgroundColors.highlighted : 'transparent',
              },
            };
          },
        }
      : {}),
    defaultSelected: true,
  }));

  return attributeColumnsData;
};

export default historyListingTableAttributeColumns;

const getAttributeValue = (attribute: HistoryAttribute | undefined, raw?: boolean): AttributeValue => {
  let attributeValue: AttributeValue = '';
  let separator: string;

  if (!attribute) return attributeValue;

  const { type, value, scaling } = attribute;
  const scalingValue = scaling !== null ? scaling : undefined;

  switch (type) {
    case HistoryAttributeType.Amount:
      attributeValue = typeof value === 'number' ? value : '';
      if (!raw && attributeValue !== '') {
        attributeValue = <EuroAmount value={attributeValue as number} />;
      }
      break;

    case HistoryAttributeType.Boolean:
      if (raw) {
        attributeValue = (value as boolean) ?? '';
      } else {
        attributeValue = value ? <CheckOutlined /> : <MinusOutlined />;
      }
      break;

    case HistoryAttributeType.Date:
      attributeValue = typeof value === 'string' ? value : '';
      if (!raw && attributeValue) {
        attributeValue = <CustomFormattedDate value={attributeValue as string} />;
      }
      break;

    case HistoryAttributeType.Float:
      attributeValue = typeof value === 'number' ? value : '';

      if (!raw && attributeValue !== '') {
        attributeValue = (
          <FormattedNumber value={attributeValue as number} minimumFractionDigits={scalingValue} maximumFractionDigits={scalingValue} />
        );
      }
      break;

    case HistoryAttributeType.Html:
      attributeValue = typeof value === 'string' ? value : '';
      if (!raw && attributeValue) {
        attributeValue = value ? <HistoryHtmlAttribute content={attributeValue as string} /> : '';
      }
      break;

    case HistoryAttributeType.Percent:
      attributeValue = typeof value === 'number' ? Number(value) / 100 : '';

      if (!raw && attributeValue !== '') {
        attributeValue = <Percent value={attributeValue as number} minimumFractionDigits={scalingValue} maximumFractionDigits={scalingValue} />;
      }
      break;

    case HistoryAttributeType.String:
      attributeValue = typeof value === 'string' ? value : '';
      break;

    case HistoryAttributeType.StringList:
      // use unique separator to join the strings
      // i.E Buchungsposition 1: S5102 Dachdecker, Betrag: 20, Steuersatz: 20 -> should be shown in one line -> when using , as separator after each , there is a line break
      // handle case longText
      separator = attribute.longText ? '#' : ',';

      attributeValue = Array.isArray(value) ? value.join(`${separator} `) : '';
      if (!raw && attributeValue) {
        attributeValue = (
          <DataWithShortenedText
            maxTextLength={18}
            text={attributeValue as string}
            longText={attribute.longText ?? false}
            longTextSplitWith={separator}
          />
        );
      }
      break;

    case HistoryAttributeType.Int:
      attributeValue = typeof value === 'number' ? value : '';
      break;
  }

  return attributeValue;
};
