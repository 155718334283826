import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { BankDetailsRechnungsausstellerType } from './Create/sepaMandatCreateFormMapper';
import { useFirmendatenKurzBezeichnungQuery } from '../../../Breadcrumb/gql/KurzbezeichnungQueries.types';
import { useRechnungsAusstellerListQuery } from '../../../Rechnungsaussteller/gql/RechnungsausstellerQueries.types';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';

type Props = {
  onRechnungsausstellerSelect: (type: BankDetailsRechnungsausstellerType) => void;
} & SelectProps;

const FirmendatenOrRechnungsausstellerSelect: FC<Props> = ({ onRechnungsausstellerSelect, ...restProps }) => {
  const { activeForFirmendatenId: firmendatenId } = useFDAuthorized();
  const { data: fdData, loading: loadingFd } = useFirmendatenKurzBezeichnungQuery({ variables: { firmendatenId } });
  const { data: raListData, loading: loadingRaList } = useRechnungsAusstellerListQuery();

  const firmendatenList = fdData?.getFirmendaten.data ? [fdData.getFirmendaten.data] : [];
  const rechnungsAusstellerList = raListData?.getRechnungsAusstellerList.data ?? [];

  return (
    <>
      <Select
        {...restProps}
        loading={loadingFd || loadingRaList}
        placeholder="Rechnungsaussteller auswählen"
        filterOption={selectFilterOption}
        onSelect={(value: string) => {
          onRechnungsausstellerSelect(getRechnungsausstellerType(firmendatenId, value));
        }}
        showSearch
      >
        <Select.OptGroup label="Hausverwaltung">
          {firmendatenList.map((firmendaten) => (
            <Select.Option key={firmendaten.id} value={firmendaten.id}>
              {firmendaten.kurzBezeichnung}
            </Select.Option>
          ))}
        </Select.OptGroup>
        <Select.OptGroup label="Objekt-Rechnungsaussteller">
          {rechnungsAusstellerList.map((rechnungsAussteller) => (
            <Select.Option key={rechnungsAussteller.rechtstraegerId} value={rechnungsAussteller.rechtstraegerId}>
              {rechnungsAussteller.kurzBezeichnung}
            </Select.Option>
          ))}
        </Select.OptGroup>
      </Select>
    </>
  );
};

const getRechnungsausstellerType = (firmendatenId: string, value: string): BankDetailsRechnungsausstellerType => {
  return firmendatenId === value ? BankDetailsRechnungsausstellerType.HAUSVERWALTUNG : BankDetailsRechnungsausstellerType.RECHNUNGS_AUSSTELLER;
};

export default FirmendatenOrRechnungsausstellerSelect;
