import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartBudgetingGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartBudgetingGenerierlaufMutation = {
  actionRestartBudgetingGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartBudgetingGenerierlaufDocument = gql`
  mutation RestartBudgetingGenerierlauf($generierlaufId: ID!) {
    actionRestartBudgetingGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartBudgetingGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartBudgetingGenerierlaufMutation, RestartBudgetingGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartBudgetingGenerierlaufMutation, RestartBudgetingGenerierlaufMutationVariables>(
    RestartBudgetingGenerierlaufDocument,
    options
  );
}
export type RestartBudgetingGenerierlaufMutationHookResult = ReturnType<typeof useRestartBudgetingGenerierlaufMutation>;
export type RestartBudgetingGenerierlaufMutationResult = Apollo.MutationResult<RestartBudgetingGenerierlaufMutation>;
export type RestartBudgetingGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartBudgetingGenerierlaufMutation,
  RestartBudgetingGenerierlaufMutationVariables
>;
