import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFilterPaymentPurposeOfUseTextListQuery } from './gql/PaymentFilterQueries.types';
import useDebounce from '../../../../hooks/useDebounce';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../../helpers/stringHelper';

type Props = {
  purposeOfUseText?: string | null;
} & Omit<
  SelectProps,
  | 'id'
  | 'placeholder'
  | 'size'
  | 'allowClear'
  | 'showSearch'
  | 'filterOption'
  | 'onSelect'
  | 'onSearch'
  | 'onClear'
  | 'searchValue'
  | 'notFoundContent'
>;

const PaymentPurposeOfUseTextSelect: FC<Props> = ({ purposeOfUseText, ...restProps }) => {
  const [text, setText] = useState<string>(purposeOfUseText ? purposeOfUseText.toString() : '');
  const debouncedText = useDebounce(text);

  const { loading, data } = useFilterPaymentPurposeOfUseTextListQuery({
    variables: {
      purposeOfUseText: debouncedText as string,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const list = data?.getFilterPaymentPurposeOfUseTextList.data ?? [];

  return (
    <>
      <Select
        {...restProps}
        id={restProps.name}
        loading={loading}
        size="small"
        allowClear
        showSearch
        placeholder="Verwendungszweck auswählen"
        filterOption={selectFilterOption}
        onSelect={setText}
        onSearch={(value) => {
          text.length === 1 && !value && setText(''); // Checking the length of the text makes the search component editable
          value && setText(value);
        }}
        onClear={() => setText('')}
        searchValue={text}
        notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
      >
        {list?.map((text) => (
          <Select.Option key={text} value={text}>
            <Typography.Text>{text}</Typography.Text>
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default PaymentPurposeOfUseTextSelect;
