import styled from 'styled-components';
import { Table } from 'antd';

export const StyledVPosTable = styled(Table)`
  /* no left border for the table */
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: none;
  }

  /* no top border for the table */
  .ant-table-content > table {
    border-top: none !important;
  }

  /* style for the table summary first (=header) row */
  .ant-table-summary > tr:nth-child(1) {
    background: #fafafa;
    font-weight: 500;
  }
  /* add hovering to the summary rows */
  .ant-table-summary > tr:hover > td {
    background: #fafafa;
  }
  /* add left border to the summary rows */
  .ant-table-summary tr td:nth-child(1) {
    border-left: 1px solid #f0f0f0;
  }

  /* show missing borders for the header part */
  .ant-table-thead th {
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
  }

  /* no borders between the empty table header cells */
  .ant-table-thead tr:first-child th:first-child,
  .ant-table-thead tr:first-child th:nth-child(2),
  .ant-table-thead tr:first-child th:nth-child(3) {
    border: none !important;
    background: none;
  }

  /* remove hovering in body part */
  .ant-table-tbody > .ant-table-row:hover > td {
    background: unset;
  }

  /* hide unnecessary borders from empty table body cells */
  .ant-table-tbody tr:first-child td:first-child,
  .ant-table-tbody tr:first-child td:nth-child(2),
  .ant-table-tbody tr:nth-child(2) td:first-child,
  .ant-table-tbody tr:nth-child(2) td:nth-child(2) {
    border-right: none !important;
    border-bottom: none;
  }
  .ant-table-tbody tr:first-child td:nth-child(3),
  .ant-table-tbody tr:nth-child(2) td:nth-child(3) {
    border-bottom: none;
  }
  .ant-table-tbody tr:nth-child(3) td:first-child,
  .ant-table-tbody tr:nth-child(3) td:nth-child(2) {
    border-right: none !important;
  }
` as any;
