import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { TFPAufwandskontoTable } from '../financialPlan-types';

type Props = {
  aufwandskonten: TFPAufwandskontoTable;
  width?: string;
};

const FPAufwandKontoTable: FC<Props> = ({ aufwandskonten, width }) => (
  <View style={{ width: width ?? '80%', marginTop: '5mm' }} wrap={false}>
    {/* table header */}
    <View style={[pdfStyles.row, pdfStyles.borderBottomSmall]}>
      <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: '33.3%' }]}>{aufwandskonten.headerText.bezeichnungText}</Text>
      <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: '33.3%', textAlign: 'right' }]}>
        {aufwandskonten.headerText.aufteilungsschluesselText}
      </Text>
      <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: '33.3%', textAlign: 'right' }]}>
        {aufwandskonten.headerText.annualAmountNettoText}
      </Text>
    </View>

    {/* table rows */}
    {aufwandskonten.kontoList.map((konto, index) => (
      <View key={index} style={[pdfStyles.row]}>
        <Text style={[pdfStyles.column, pdfStyles.textBulletin, { width: '33.3%' }]}>{konto.bezeichnung}</Text>
        <Text style={[pdfStyles.column, pdfStyles.textBulletin, { width: '33.3%', textAlign: 'right' }]}>{konto.aufteilungsschluessel}</Text>
        <Text style={[pdfStyles.column, pdfStyles.textBulletin, { width: '33.3%', textAlign: 'right' }]}>{konto.annualAmount}</Text>
      </View>
    ))}

    {/* table summary */}
    <View style={[pdfStyles.row, pdfStyles.borderTopSmall, { fontWeight: 'bold' }]}>
      <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: '66.6%' }]}>{aufwandskonten.kontoSummary.annualAmountText}</Text>
      <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: '33.3%', textAlign: 'right' }]}>{aufwandskonten.kontoSummary.annualAmount}</Text>
    </View>
  </View>
);

export default FPAufwandKontoTable;
