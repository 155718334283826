import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { Konto } from '../../../types';
import KontoBezugTab from './KontoTemplateBezug/KontoBezugTab';

export const KontoTabs = {
  BEZUEGE: { name: 'Bezüge', key: '1' },
} as const;

type Props = {
  konto: Konto;
};

const KontoTemplateEinstellungenTabs: FC<Props> = ({ konto }) => {
  const items: TabsProps['items'] = [
    {
      key: KontoTabs.BEZUEGE.key,
      label: KontoTabs.BEZUEGE.name,
      children: <KontoBezugTab konto={konto} />,
    },
  ];

  return <Tabs items={items} />;
};

export default KontoTemplateEinstellungenTabs;
