import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { FibuObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { FibuRechtstraegerFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { MahnungFieldsFragmentDoc } from '../../../Mahnung/gql/MahnungFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MahnungErzeugenGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type MahnungErzeugenGenerierlaufQuery = {
  getMahnungErzeugenGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      objekt?: { kurzBezeichnung: string; objektId: string } | null;
      rechnungsAussteller?: {
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
      } | null;
      status: { value: Types.GenerierlaufStatus; text: string };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungErzeugenGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsausstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.MahnungGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type MahnungErzeugenGenerierlaufEntryListQuery = {
  getMahnungErzeugenGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        mahnungDeletedBy?: string | null;
        mahnungDeletedByMitarbeiterId?: string | null;
        mahnungDeletedTs?: string | null;
        mahnungDeleted: boolean;
        retry: boolean;
        stepCount?: number | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        mahnung?: {
          buchungId?: string | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          fileId?: string | null;
          freigegebenTs?: string | null;
          mahngebuehr: number;
          mahnstichtag: string;
          mahnungEmail?: string | null;
          mahnungId: string;
          opEmail: string;
          rechnungsAusstellerBankDetailsAccountHolder: string;
          rechnungsAusstellerBankDetailsIban: string;
          updatedByMitarbeiterId?: string | null;
          sumMahnbetrag: number;
          sumRechnungsbetrag: number;
          sumVerzugszinsen: number;
          sumZahlungen: number;
          versandEinschreiben: boolean;
          versendetTs?: string | null;
          verzugszinsen: number;
          buchungskreis: {
            buchungskreisId: string;
            rechtstraeger: {
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
            };
          };
          debitorKonto: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            haupt: boolean;
            kontoId: string;
            kontoTemplateId?: string | null;
            nummer: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            klasse: { text: string; value: Types.KontoKlasse };
            status: { description?: string | null; text: string; value: Types.KontoStatus };
            type: { text: string; value: Types.KontoType };
            verwendung?: { text: string; value: Types.KontoVerwendung } | null;
          };
          mahnPositionList: Array<{
            belegDatum: string;
            belegFileId: string;
            belegnummer: string;
            buchungskreisId: string;
            dataCarrierBelegId?: string | null;
            dueDate: string;
            gesamtBetrag: number;
            iban?: string | null;
            letzterMahnstichtag?: string | null;
            mahnPositionId: string;
            mahnstufe: number;
            offenerBetrag: number;
            statementNumber?: string | null;
            verzugszinsen: number;
            verzugszinsenBerechnen: boolean;
            zahlungBetrag: number;
            belegSymbol: { abbreviation: string; text: string; value: Types.FibuBelegSymbol };
          }>;
          mahnungDeliveryAddress?: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          } | null;
          mailDelivery?: { mailId?: string | null; status: { text: string; value: Types.MahnungMailDeliveryStatus } } | null;
          objektList: Array<{ kurzBezeichnung: string; objektId: string }>;
          opDeliveryAddress: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
          opZustellRechtstraeger: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
          rechnungsAussteller: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
          status: { value: Types.MahnungStatus; text: string };
          vertragspartner: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
        } | null;
        rechnungsAussteller: {
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
        };
        vertragspartner: {
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
        };
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { size: number; number: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const MahnungErzeugenGenerierlaufDocument = gql`
  query MahnungErzeugenGenerierlauf($generierlaufId: ID!) {
    getMahnungErzeugenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierlaufId
        objekt {
          ...FibuObjekt
        }
        rechnungsAussteller {
          ...FibuRechtstraeger
        }
        status {
          value
          text
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FibuObjektFragmentDoc}
  ${FibuRechtstraegerFragmentDoc}
`;
export function useMahnungErzeugenGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<MahnungErzeugenGenerierlaufQuery, MahnungErzeugenGenerierlaufQueryVariables> &
    ({ variables: MahnungErzeugenGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungErzeugenGenerierlaufQuery, MahnungErzeugenGenerierlaufQueryVariables>(MahnungErzeugenGenerierlaufDocument, options);
}
export function useMahnungErzeugenGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnungErzeugenGenerierlaufQuery, MahnungErzeugenGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungErzeugenGenerierlaufQuery, MahnungErzeugenGenerierlaufQueryVariables>(
    MahnungErzeugenGenerierlaufDocument,
    options
  );
}
export function useMahnungErzeugenGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnungErzeugenGenerierlaufQuery, MahnungErzeugenGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungErzeugenGenerierlaufQuery, MahnungErzeugenGenerierlaufQueryVariables>(
    MahnungErzeugenGenerierlaufDocument,
    options
  );
}
export type MahnungErzeugenGenerierlaufQueryHookResult = ReturnType<typeof useMahnungErzeugenGenerierlaufQuery>;
export type MahnungErzeugenGenerierlaufLazyQueryHookResult = ReturnType<typeof useMahnungErzeugenGenerierlaufLazyQuery>;
export type MahnungErzeugenGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useMahnungErzeugenGenerierlaufSuspenseQuery>;
export type MahnungErzeugenGenerierlaufQueryResult = Apollo.QueryResult<MahnungErzeugenGenerierlaufQuery, MahnungErzeugenGenerierlaufQueryVariables>;
export const MahnungErzeugenGenerierlaufEntryListDocument = gql`
  query MahnungErzeugenGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $rechnungsausstellerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: MahnungGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getMahnungErzeugenGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      rechnungsausstellerId: $rechnungsausstellerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          currentStep
          errorList {
            message
            type
          }
          exitCode {
            value
            text
          }
          generierlaufEntryId
          infoMessage
          mahnung {
            ...MahnungFields
          }
          mahnungDeletedBy
          mahnungDeletedByMitarbeiterId
          mahnungDeletedTs
          mahnungDeleted
          rechnungsAussteller {
            ...FibuRechtstraeger
          }
          retry
          stepCount
          vertragspartner {
            ...FibuRechtstraeger
          }
          warningList {
            message
            type
          }
        }
        page {
          size
          number
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MahnungFieldsFragmentDoc}
  ${FibuRechtstraegerFragmentDoc}
`;
export function useMahnungErzeugenGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnungErzeugenGenerierlaufEntryListQuery, MahnungErzeugenGenerierlaufEntryListQueryVariables> &
    ({ variables: MahnungErzeugenGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungErzeugenGenerierlaufEntryListQuery, MahnungErzeugenGenerierlaufEntryListQueryVariables>(
    MahnungErzeugenGenerierlaufEntryListDocument,
    options
  );
}
export function useMahnungErzeugenGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnungErzeugenGenerierlaufEntryListQuery, MahnungErzeugenGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungErzeugenGenerierlaufEntryListQuery, MahnungErzeugenGenerierlaufEntryListQueryVariables>(
    MahnungErzeugenGenerierlaufEntryListDocument,
    options
  );
}
export function useMahnungErzeugenGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MahnungErzeugenGenerierlaufEntryListQuery, MahnungErzeugenGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungErzeugenGenerierlaufEntryListQuery, MahnungErzeugenGenerierlaufEntryListQueryVariables>(
    MahnungErzeugenGenerierlaufEntryListDocument,
    options
  );
}
export type MahnungErzeugenGenerierlaufEntryListQueryHookResult = ReturnType<typeof useMahnungErzeugenGenerierlaufEntryListQuery>;
export type MahnungErzeugenGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useMahnungErzeugenGenerierlaufEntryListLazyQuery>;
export type MahnungErzeugenGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<typeof useMahnungErzeugenGenerierlaufEntryListSuspenseQuery>;
export type MahnungErzeugenGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  MahnungErzeugenGenerierlaufEntryListQuery,
  MahnungErzeugenGenerierlaufEntryListQueryVariables
>;
