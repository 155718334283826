import styled from 'styled-components';
import theme from '../../../theme';
import { AuditFooterSize } from '../AuditFooter';

export type StyledAuditFooterProps = {
  size: AuditFooterSize;
  minWidth?: number;
};

export const StyledAuditFooter = styled('div')<StyledAuditFooterProps>`
  display: flex;
  border-radius: 0 0 4px 4px;
  align-items: center;
  font-size: 9.2px;
  background-color: #fafafa;
  color: ${theme.textColor.secondary};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : '100%')};
  padding: ${(props) => FOOTER_SIZE[props.size].padding};
`;

const FOOTER_SIZE = {
  small: {
    padding: '4px',
  },
  large: {
    padding: '16px',
  },
};
