import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubAbrechnungGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type SubAbrechnungGenerierlaufQuery = {
  getSubAbrechnungGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fromInclusive: string;
      generierDatum: string;
      generierlaufId: string;
      subAbrechnungId?: string | null;
      toInclusive: string;
      errorList: Array<{ message: string; type: string }>;
      status: { value: Types.GenerierlaufStatus; text: string };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SubAbrechnungGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  eigentuemerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.SubAbrechnungGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type SubAbrechnungGenerierlaufEntryListQuery = {
  getSubAbrechnungGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        currentStep?: number | null;
        eigentuemerId: string;
        eigentuemerKurzBezeichnung: string;
        fromInclusive?: string | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        objektId: string;
        objektKurzBezeichnung: string;
        retry?: boolean | null;
        stepCount?: number | null;
        subAbrechnungDeleted?: boolean | null;
        subAbrechnungDeletedBy?: string | null;
        subAbrechnungDeletedByMitarbeiterId?: string | null;
        subAbrechnungDeletedTs?: string | null;
        subAbrechnungEigentuemerId?: string | null;
        subAbrechnungId?: string | null;
        toInclusive?: string | null;
        vorabrechnung: boolean;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        status?: { text: string; value: Types.SubAbrechnungEigentuemerStatus } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const SubAbrechnungGenerierlaufDocument = gql`
  query SubAbrechnungGenerierlauf($generierlaufId: ID!) {
    getSubAbrechnungGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        fromInclusive
        generierDatum
        generierlaufId
        status {
          value
          text
        }
        subAbrechnungId
        toInclusive
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSubAbrechnungGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<SubAbrechnungGenerierlaufQuery, SubAbrechnungGenerierlaufQueryVariables> &
    ({ variables: SubAbrechnungGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubAbrechnungGenerierlaufQuery, SubAbrechnungGenerierlaufQueryVariables>(SubAbrechnungGenerierlaufDocument, options);
}
export function useSubAbrechnungGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubAbrechnungGenerierlaufQuery, SubAbrechnungGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubAbrechnungGenerierlaufQuery, SubAbrechnungGenerierlaufQueryVariables>(SubAbrechnungGenerierlaufDocument, options);
}
export function useSubAbrechnungGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubAbrechnungGenerierlaufQuery, SubAbrechnungGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubAbrechnungGenerierlaufQuery, SubAbrechnungGenerierlaufQueryVariables>(SubAbrechnungGenerierlaufDocument, options);
}
export type SubAbrechnungGenerierlaufQueryHookResult = ReturnType<typeof useSubAbrechnungGenerierlaufQuery>;
export type SubAbrechnungGenerierlaufLazyQueryHookResult = ReturnType<typeof useSubAbrechnungGenerierlaufLazyQuery>;
export type SubAbrechnungGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useSubAbrechnungGenerierlaufSuspenseQuery>;
export type SubAbrechnungGenerierlaufQueryResult = Apollo.QueryResult<SubAbrechnungGenerierlaufQuery, SubAbrechnungGenerierlaufQueryVariables>;
export const SubAbrechnungGenerierlaufEntryListDocument = gql`
  query SubAbrechnungGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $eigentuemerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: SubAbrechnungGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getSubAbrechnungGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      eigentuemerId: $eigentuemerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          currentStep
          eigentuemerId
          eigentuemerKurzBezeichnung
          errorList {
            message
            type
          }
          exitCode {
            value
            text
          }
          fromInclusive
          generierlaufEntryId
          infoMessage
          objektId
          objektKurzBezeichnung
          retry
          status {
            text
            value
          }
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          subAbrechnungDeleted
          subAbrechnungDeletedBy
          subAbrechnungDeletedByMitarbeiterId
          subAbrechnungDeletedTs
          subAbrechnungEigentuemerId
          subAbrechnungId
          toInclusive
          vorabrechnung
          warningList {
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function useSubAbrechnungGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<SubAbrechnungGenerierlaufEntryListQuery, SubAbrechnungGenerierlaufEntryListQueryVariables> &
    ({ variables: SubAbrechnungGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubAbrechnungGenerierlaufEntryListQuery, SubAbrechnungGenerierlaufEntryListQueryVariables>(
    SubAbrechnungGenerierlaufEntryListDocument,
    options
  );
}
export function useSubAbrechnungGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubAbrechnungGenerierlaufEntryListQuery, SubAbrechnungGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubAbrechnungGenerierlaufEntryListQuery, SubAbrechnungGenerierlaufEntryListQueryVariables>(
    SubAbrechnungGenerierlaufEntryListDocument,
    options
  );
}
export function useSubAbrechnungGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SubAbrechnungGenerierlaufEntryListQuery, SubAbrechnungGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubAbrechnungGenerierlaufEntryListQuery, SubAbrechnungGenerierlaufEntryListQueryVariables>(
    SubAbrechnungGenerierlaufEntryListDocument,
    options
  );
}
export type SubAbrechnungGenerierlaufEntryListQueryHookResult = ReturnType<typeof useSubAbrechnungGenerierlaufEntryListQuery>;
export type SubAbrechnungGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useSubAbrechnungGenerierlaufEntryListLazyQuery>;
export type SubAbrechnungGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<typeof useSubAbrechnungGenerierlaufEntryListSuspenseQuery>;
export type SubAbrechnungGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  SubAbrechnungGenerierlaufEntryListQuery,
  SubAbrechnungGenerierlaufEntryListQueryVariables
>;
