import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import { FibuRechtstraegerFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
export type OffenePostenFieldsFragment = {
  belegDatum: string;
  belegFileId?: string | null;
  belegnummer: string;
  buchungId: string;
  buchungsdatum: string;
  buchungskreisId: string;
  buchungskreisRechtstraegerId: string;
  buchungskreisRechtstraegerBezeichnung: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  dataCarrierBelegId?: string | null;
  dueDate: string;
  gesamtBetrag: number;
  iban?: string | null;
  letzteMahnstufe: number;
  offenePostenId: string;
  offenerBetrag: number;
  statementNumber?: string | null;
  auszifferungList: Array<{
    auszifferungId: string;
    auszifferungsDatum: string;
    betrag: number;
    bookingSuggestionId?: string | null;
    buchungId: string;
    buchungsdatum: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    offenePostenId: string;
    storniert: boolean;
    storniertByMitarbeiterId?: string | null;
    storniertTs?: string | null;
    stornierteOffenePostenId?: string | null;
    stornoOffenePostenId?: string | null;
    updatedByMitarbeiterId?: string | null;
    zahlungId?: string | null;
    source: { text: string; value: Types.AuszifferungSource };
  }>;
  belegSymbol: { text: string; value: Types.FibuBelegSymbol; abbreviation: string };
  konto: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    haupt: boolean;
    kontoId: string;
    kontoTemplateId?: string | null;
    nummer: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    klasse: { text: string; value: Types.KontoKlasse };
    status: { description?: string | null; text: string; value: Types.KontoStatus };
    type: { text: string; value: Types.KontoType };
    verwendung?: { text: string; value: Types.KontoVerwendung } | null;
  };
  vertragspartner: {
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
  };
};

export const OffenePostenFieldsFragmentDoc = gql`
  fragment OffenePostenFields on OffenePosten {
    auszifferungList {
      auszifferungId
      auszifferungsDatum
      betrag
      bookingSuggestionId
      buchungId
      buchungsdatum
      createTs
      createdBy
      createdByMitarbeiterId
      offenePostenId
      source {
        text
        value
      }
      storniert
      storniertByMitarbeiterId
      storniertTs
      stornierteOffenePostenId
      stornoOffenePostenId
      updatedByMitarbeiterId
      zahlungId
    }
    belegDatum
    belegFileId
    belegSymbol {
      text
      value
      abbreviation
    }
    belegnummer
    buchungId
    buchungsdatum
    buchungskreisId
    buchungskreisRechtstraegerId
    buchungskreisRechtstraegerBezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    dataCarrierBelegId
    dueDate
    gesamtBetrag
    iban
    konto {
      ...KontoFields
    }
    letzteMahnstufe
    offenePostenId
    offenerBetrag
    statementNumber
    vertragspartner {
      ...FibuRechtstraeger
    }
  }
  ${KontoFieldsFragmentDoc}
  ${FibuRechtstraegerFragmentDoc}
`;
