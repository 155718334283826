import React, { FC } from 'react';
import { FieldArray } from 'formik';
import { MahngebuehrFormValues } from '../../mahndefinitionFormMapper';
import MahngebuehrEmpty from './Mahngebuehr/MahngebuehrEmpty';
import Mahngebuehr from './Mahngebuehr/Mahngebuehr';

type Props = {
  name: string;
  mahngebuehrenList: MahngebuehrFormValues[];
  error?: string | Record<string, unknown>;
};

const MahndefinitionParamFormMahngebuehren: FC<Props> = ({ name, mahngebuehrenList, error }) => {
  return (
    <FieldArray
      name={name}
      render={(arrayHelpersForMahngebuehren) => {
        if (mahngebuehrenList.length === 0) {
          return <MahngebuehrEmpty arrayHelpers={arrayHelpersForMahngebuehren} />;
        }
        return (
          <>
            {mahngebuehrenList.map((mahngebuehr, index) => {
              return (
                <Mahngebuehr
                  key={mahngebuehr.mahngebuehrId ?? mahngebuehr.uuid}
                  index={index}
                  name={name}
                  arrayHelpers={arrayHelpersForMahngebuehren}
                />
              );
            })}
            {typeof error === 'string' ? <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div> : null}
          </>
        );
      }}
    />
  );
};

export default MahndefinitionParamFormMahngebuehren;
