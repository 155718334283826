import { Space, Typography } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import InfoFeldListItem from '../InfoFeldListItem';
import PersonListItemActions from './PersonListItemActions';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../Rechtstraeger/rechtstraegerHelper';
import { ObjektInfoFeld_ObjektInfoFeldPerson_Fragment } from '../../../gql/objektInfoFeldFragments.types';
import { StyledSpaceSplitA30 } from '../../../../../../components/Space/styled/Space.style';

type Props = {
  person: ObjektInfoFeld_ObjektInfoFeldPerson_Fragment;
  objektId: string;
  onSuccess: () => void;
};

const PersonListItem: FC<Props> = ({ person, objektId, onSuccess }) => {
  return (
    <InfoFeldListItem
      icon={<UserOutlined />}
      content={{ fileIdList: person.fileIdList, props: { height: 48 } }}
      actions={<PersonListItemActions infoFeld={person} objektId={objektId} onSuccess={onSuccess} />}
    >
      <Space direction="vertical" style={{ width: '220px' }}>
        <Typography.Text type="secondary">Person</Typography.Text>
        <Typography.Text>Rolle {person.rolle}</Typography.Text>
      </Space>
      <StyledSpaceSplitA30>
        <Link to={generatePathToRechtstraegerDetailsPage(person.personRechtstraeger.rechtstraegerId)}>
          {person.personRechtstraeger.kurzBezeichnung}
        </Link>
      </StyledSpaceSplitA30>
    </InfoFeldListItem>
  );
};

export default PersonListItem;
