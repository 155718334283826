import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../../styles/pdfStyles';

const TAAbrKreisSaldo: FC<{ saldo: { text: string; betrag: string } }> = ({ saldo }) => (
  <View style={[pdfStyles.row, { marginTop: '3mm' }]}>
    <View style={[pdfStyles.column, { width: '60%' }]}>
      <Text style={[pdfStyles.column]}>{saldo.text}</Text>
    </View>
    <View style={[pdfStyles.column, { width: '40%' }]}>
      <Text style={[pdfStyles.column, { textAlign: 'right' }]}>{saldo.betrag}</Text>
    </View>
  </View>
);

export default TAAbrKreisSaldo;
