import { BeTableSummaryTypes, ObjektBlockTablesTypes, ObjektSummaryTypes, VPosTableTypes } from './objekt-block-tables-types';

export const beColumnWidthsAndAlignment: ObjektBlockTablesTypes = {
  bestandseinheit: { width: '15%', textAlign: 'left' },
  vPosList: { width: '85%', textAlign: undefined },
};

export const vPosColumnWidthsAndAlignment: VPosTableTypes = {
  vorschreibungsposition: { width: '25%', textAlign: 'left' },
  netto: { width: '22.5%', textAlign: 'right' },
  ustSatz: { width: '17.50%', textAlign: 'right' },
  ustBetrag: { width: '17.50%', textAlign: 'right' },
  brutto: { width: '17.50%', textAlign: 'right' },
};

export const beSummaryColumnWidthsAndAlignment: BeTableSummaryTypes = {
  summeText: { width: '25%', textAlign: 'left' },
  netto: { width: '22.50%', textAlign: 'right' },
  ustBetrag: { width: '35%', textAlign: 'right' }, // width counted as ustSatz + ustBetrag from vPosColumnWidthsAndAlignment
  brutto: { width: '17.50%', textAlign: 'right' },
};

export const objektSummaryColumnWidthsAndAlignment: ObjektSummaryTypes = {
  // widths are counted based on bestandseinheit width from beColumnWidthsAndAlignment and widths from beSummaryColumnWidthsAndAlignment
  // the calculated widths are rounded and a little adjusted to match the alignment of the columns above in the table
  summeText: { width: '36%', textAlign: 'left' },
  netto: { width: '20%', textAlign: 'right' },
  ustBetrag: { width: '29.40%', textAlign: 'right' },
  brutto: { width: '14.60%', textAlign: 'right' },
};
