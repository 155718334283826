import React, { FC } from 'react';
import { List } from 'antd';
import IncomingInvoiceDetailsBuchungPosition from '../IncomingInvoiceDetailsBuchungPosition';
import { IncomingInvoiceBuchungPosition } from '../../../../types';
import { StyledBuchungPositionListStyle } from '../styled/StyledBuchungPositionList.style';

type Props = {
  buchungPositionList: IncomingInvoiceBuchungPosition[];
};

const BuchungPositionContentBlock: FC<Props> = ({ buchungPositionList }) => (
  <StyledBuchungPositionListStyle<IncomingInvoiceBuchungPosition>
    itemLayout="horizontal"
    dataSource={buchungPositionList}
    renderItem={(item, index) => (
      <List.Item key={index}>
        <List.Item.Meta title={`Buchungsposition ${index + 1}`} description={<IncomingInvoiceDetailsBuchungPosition key={index} position={item} />} />
      </List.Item>
    )}
  />
);

export default BuchungPositionContentBlock;
