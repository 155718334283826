import React, { FC, PropsWithChildren } from 'react';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../Button/ButtonsAligned';
import { NestedTableWithColSelectorProvider } from './NestedTableWithColSelectorContext';
import NestedTableWithColSelectorColumnSelector from './NestedTableWithColSelectorColumnSelector';

type Props = {
  filterIdentifier: string;
};

const NestedTableWithColSelectorWrapper: FC<Props & PropsWithChildren> = ({ filterIdentifier, children }) => {
  return (
    <div>
      <NestedTableWithColSelectorProvider>
        <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
          <NestedTableWithColSelectorColumnSelector filterIdentifier={filterIdentifier} />
        </ButtonsAligned>
        {children}
      </NestedTableWithColSelectorProvider>
    </div>
  );
};

export default NestedTableWithColSelectorWrapper;
