import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ContactFieldsFragmentDoc, ContactPersonFieldsFragmentDoc } from '../../Contact/gql/ContactFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RechtstraegerContactListQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type RechtstraegerContactListQuery = {
  getRechtstraegerContactList: {
    data: {
      contentList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        contactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RechtstraegerContactListDocument = gql`
  query RechtstraegerContactList($filter: String, $page: Int, $pageSize: Int) {
    getRechtstraegerContactList(filter: $filter, page: $page, pageSize: $pageSize) {
      data {
        contentList {
          contactList {
            ...ContactFields
          }
          createTs
          createdBy
          createdByMitarbeiterId
          kurzBezeichnung
          rechtstraegerId
          status {
            text
            value
          }
          type {
            text
            value
          }
          warningList {
            attribute
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ContactFieldsFragmentDoc}
`;
export function useRechtstraegerContactListQuery(
  baseOptions?: Apollo.QueryHookOptions<RechtstraegerContactListQuery, RechtstraegerContactListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerContactListQuery, RechtstraegerContactListQueryVariables>(RechtstraegerContactListDocument, options);
}
export function useRechtstraegerContactListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerContactListQuery, RechtstraegerContactListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerContactListQuery, RechtstraegerContactListQueryVariables>(RechtstraegerContactListDocument, options);
}
export function useRechtstraegerContactListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerContactListQuery, RechtstraegerContactListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerContactListQuery, RechtstraegerContactListQueryVariables>(RechtstraegerContactListDocument, options);
}
export type RechtstraegerContactListQueryHookResult = ReturnType<typeof useRechtstraegerContactListQuery>;
export type RechtstraegerContactListLazyQueryHookResult = ReturnType<typeof useRechtstraegerContactListLazyQuery>;
export type RechtstraegerContactListSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerContactListSuspenseQuery>;
export type RechtstraegerContactListQueryResult = Apollo.QueryResult<RechtstraegerContactListQuery, RechtstraegerContactListQueryVariables>;
