import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Col, Divider, Row, Typography } from 'antd';
import FirmendatenBaseFormPart from './FirmendatenBaseFormPart';
import FormButtons from '../../../../components/Button/FormButtons';
import { useUpdateFirmendatenMutation } from '../gql/FirmendatenMutations.types';
import { showSuccessMsgCreate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { FirmendatenFormValues, mapFirmendatenToFormValues, mapFormValuesToFirmendaten } from './fimendatenFormMapper';
import { firmendatenBaseFormPartValidationSchema } from './firmendatenBaseFormPartValidationSchema';
import { Firmendaten } from '../../../../types';

type FirmendatenUpdateProps = {
  firmendaten: Firmendaten;
  onSuccess: () => void;
  onCancel: () => void;
};

const FirmendatenUpdateForm: FC<FirmendatenUpdateProps> = ({ firmendaten, onSuccess, onCancel }) => {
  const initialValues = mapFirmendatenToFormValues(firmendaten);

  const [updateFirmendaten, { loading: loadingUpdateFirmendaten }] = useUpdateFirmendatenMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Firmendaten erfolgreich aktualisiert.`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<FirmendatenFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={firmendatenBaseFormPartValidationSchema}
      onSubmit={(values, formikHelpers) => {
        const firmendatenToUpdate = mapFormValuesToFirmendaten(values);
        updateFirmendaten({ variables: { firmendatenId: firmendaten.firmendatenId, request: firmendatenToUpdate } }).finally(() =>
          formikHelpers.setSubmitting(false)
        );
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Firmenstammdaten</Typography.Title>
            </Col>
            <Col span={18}>
              <FirmendatenBaseFormPart formikProps={formikProps} fieldHelpSelectorEntityName="Firmendaten" />
            </Col>
          </Row>
          <Divider />
          <FormButtons onCancel={onCancel} isOkLoading={loadingUpdateFirmendaten} isOkDisabled={formikProps.isSubmitting} updateMode />
        </Form>
      )}
    </Formik>
  );
};

export default FirmendatenUpdateForm;
