import React, { FC } from 'react';
import { Empty } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { HandleTableSorting } from '../../../../../shared/typeHelpers';
import { PaymentClearing } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import paymentClearingTableColumns from './paymentClearingTableColumns';
import BookingTable from '../Level2/BookingTable';

type Props = {
  loading: boolean;
  pagination: PaginationProps;
  handleTableSorting: HandleTableSorting;
  paymentClearingList?: PaymentClearing[];
};

const PaymentClearingTable: FC<Props> = ({ loading, pagination, handleTableSorting, paymentClearingList }) => {
  return (
    <NestedTable<PaymentClearing>
      level={1}
      columns={paymentClearingTableColumns}
      dataSource={paymentClearingList}
      loading={loading}
      pagination={pagination}
      onChange={handleTableSorting}
      colWidth={180}
      rowKey={(record) => `${record.buchungskreisId} - ${record.vertragspartnerId} - ${record.kontoId}`}
      locale={{
        emptyText: <Empty description={<span>Keine Zahlungsauszifferung vorhanden</span>} />,
      }}
      expandable={{
        expandedRowRender: (record, index, indent, expanded) => (
          <BookingTable
            paymentClearing={record}
            isRowExpanded={expanded}
            vertragspartnerId={record.vertragspartnerId}
            vertragspartnerName={record.vertragspartnerName}
          />
        ),
      }}
    />
  );
};

export default PaymentClearingTable;
