import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeInfoFeldTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BeInfoFeldTypeListQuery = {
  getBeInfoFeldTypeList: {
    data: Array<{ text: string; value: Types.BeInfoFeldType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BeInfoFeldTypeListDocument = gql`
  query BeInfoFeldTypeList {
    getBeInfoFeldTypeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBeInfoFeldTypeListQuery(baseOptions?: Apollo.QueryHookOptions<BeInfoFeldTypeListQuery, BeInfoFeldTypeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeInfoFeldTypeListQuery, BeInfoFeldTypeListQueryVariables>(BeInfoFeldTypeListDocument, options);
}
export function useBeInfoFeldTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeInfoFeldTypeListQuery, BeInfoFeldTypeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeInfoFeldTypeListQuery, BeInfoFeldTypeListQueryVariables>(BeInfoFeldTypeListDocument, options);
}
export function useBeInfoFeldTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeInfoFeldTypeListQuery, BeInfoFeldTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeInfoFeldTypeListQuery, BeInfoFeldTypeListQueryVariables>(BeInfoFeldTypeListDocument, options);
}
export type BeInfoFeldTypeListQueryHookResult = ReturnType<typeof useBeInfoFeldTypeListQuery>;
export type BeInfoFeldTypeListLazyQueryHookResult = ReturnType<typeof useBeInfoFeldTypeListLazyQuery>;
export type BeInfoFeldTypeListSuspenseQueryHookResult = ReturnType<typeof useBeInfoFeldTypeListSuspenseQuery>;
export type BeInfoFeldTypeListQueryResult = Apollo.QueryResult<BeInfoFeldTypeListQuery, BeInfoFeldTypeListQueryVariables>;
