import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { Personenbezug, RechtstraegerType } from '../../types';
import AddressesAndContactsListing from './AddressesAndContacts/AddressesAndContactsListing';
import TabPaneContent from '../../components/Tabs/TabPaneContent';
import PersonenbezugListing from './Personenbezug/PersonenbezugListing';

type AddressesAndContactsAndPersonenbezugListTabsProps = {
  rechtstraegerId: string;
  rechtstraegerType: RechtstraegerType;
  personenbezugList: Personenbezug[];
  onSuccess: () => void;
};

const AddressesAndContactsAndPersonenbezugListTabs: FC<AddressesAndContactsAndPersonenbezugListTabsProps> = ({
  rechtstraegerId,
  rechtstraegerType,
  personenbezugList,
  onSuccess,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Adressen & Kontakte',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <AddressesAndContactsListing rechtstraegerId={rechtstraegerId} rechtstraegerType={rechtstraegerType} />
        </TabPaneContent>
      ),
    },
    {
      key: '2',
      label: 'Personenbezüge',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <PersonenbezugListing rechtstraegerId={rechtstraegerId} personenbezugList={personenbezugList} onSuccess={onSuccess} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default AddressesAndContactsAndPersonenbezugListTabs;
