import React from 'react';
import { Formik } from 'formik';
import { useBudgetingVPosAuftschluesselLinkageTemplateListQuery } from '../gql/budgetingVPosAuftschluesselLinkageTemplateQueries.types';
import EntryListingTable from './Table/EntryListingTable';
import {
  listingFiltersFormInitialValues,
  ListingFiltersFormValues,
} from '../../BudgetingVPosAuftschluesselLinkage/Listing/Filters/listingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';

const EntryListing = () => {
  const { data, refetch, loading } = useBudgetingVPosAuftschluesselLinkageTemplateListQuery();

  const entryList = data?.getBudgetingVPosAuftschluesselLinkageTemplateList.data ?? [];

  return (
    <Formik<ListingFiltersFormValues> initialValues={listingFiltersFormInitialValues} onSubmit={() => {}}>
      {(formikProps) => (
        <>
          <ListingFilters onAction={refetch} linkageEntryList={entryList} formikProps={formikProps} />
          <EntryListingTable linkageEntryList={entryList} loading={loading} formikProps={formikProps} onAction={refetch} />
        </>
      )}
    </Formik>
  );
};

export default EntryListing;
