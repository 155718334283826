import { DefaultOptions } from '@apollo/client/core';

export const apolloClientDefaultOptions: DefaultOptions = {
  // useQuery uses watchQuery hence all of our generated apollo hooks are getting the defaults from watchQuery
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
};
