import React, { FC } from 'react';
import {
  BankOutlined,
  EuroCircleOutlined,
  FallOutlined,
  HomeOutlined,
  RiseOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  ToolOutlined,
  TrophyOutlined,
  TruckOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import theme from '../../../../../theme';
import { styleForIconAndButton } from './KontoDetailsCardStyleHelpers';
import { KontoKlasse, KontoVerwendung } from '../../../../../types';
import { KontoForCard } from './KontoDetails';
import { isKontoVerwendungUmsatzsteuer } from '../../../../Kontenplan/Form/kontoFormHelpers';

type Props = {
  konto: KontoForCard;
};

const KontoDetailsCardIcon: FC<Props> = ({ konto }) => (
  <div
    style={{
      backgroundColor: theme.cardBackgroundColors.blue,
      cursor: 'default',
      padding: '12px 0',
      ...styleForIconAndButton,
    }}
  >
    {getKontoIcon(konto)}
  </div>
);

const getKontoIcon = (konto: KontoForCard) => {
  switch (konto.klasse.value) {
    case KontoKlasse.UmlaufVermoegen:
      return getIconForVerwendung(konto.verwendung?.value);
    case KontoKlasse.AnlageVermoegen:
      return <HomeOutlined />;
    case KontoKlasse.HandelswarenVorrat:
      return <ShopOutlined />;
    case KontoKlasse.Lieferverbindlichkeit:
      return isKontoVerwendungUmsatzsteuer(konto.verwendung?.value) ? <EuroCircleOutlined /> : <TruckOutlined />;
    case KontoKlasse.BetrieblicherErloes:
      return <EuroCircleOutlined />;
    case KontoKlasse.HandelswarenEinsatz:
      return <ShoppingCartOutlined />;
    case KontoKlasse.PersonalAufwand:
      return <TeamOutlined />;
    case KontoKlasse.BetrieblicherAufwand:
      return <ToolOutlined />;
    case KontoKlasse.FinanzErloes:
      return <RiseOutlined />;
    case KontoKlasse.FinanzAufwand:
      return <FallOutlined />;
    case KontoKlasse.Eigenkapital:
      return <TrophyOutlined />;
    default:
      return <EuroCircleOutlined />;
  }
};

const getIconForVerwendung = (verwendung?: KontoVerwendung) => {
  switch (verwendung) {
    case KontoVerwendung.Bank:
      return <BankOutlined />;
    case KontoVerwendung.Debitor:
    case KontoVerwendung.Kreditor:
      return <UserOutlined />;
    case KontoVerwendung.Kassa:
      return <WalletOutlined />;
    case KontoVerwendung.AllgemeineForderung:
    case KontoVerwendung.Vorsteuer:
    default:
      return <EuroCircleOutlined />;
  }
};

export default KontoDetailsCardIcon;
