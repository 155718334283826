import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { VorsteuerkuerzungEinstellungFieldsFragmentDoc } from './VorsteuerkuerzungEinstellungFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorsteuerkuerzungEinstellungListQueryVariables = Types.Exact<{
  verwaltungsart: Types.Verwaltungsart;
  onlyCurrent?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type VorsteuerkuerzungEinstellungListQuery = {
  getVorsteuerkuerzungEinstellungList: {
    data: Array<{
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      vorsteuerkuerzungEinstellungId: string;
      versionIndex: number;
      historicizedList?: Array<{
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        createTs: string;
        current: boolean;
        deletable?: boolean | null;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        updatedByMitarbeiterId?: string | null;
        vorsteuerkuerzungEinstellungId: string;
        versionIndex: number;
        kontenZuweisungList: Array<{
          vorsteuerkuerzungKontenZuweisungId: string;
          vorsteuerkuerzungPauschalmieterKonto?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          vorsteuerkuerzungEigennutzungKonto?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          aufteilungsschluessel: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          aufwandsKontoList: Array<{ bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string }>;
          vorsteuerkuerzungKonto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
          vorsteuerkuerzungWeMieterKonto?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
        }>;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }> | null;
      kontenZuweisungList: Array<{
        vorsteuerkuerzungKontenZuweisungId: string;
        vorsteuerkuerzungPauschalmieterKonto?: {
          bezeichnung: string;
          firmendatenId?: string | null;
          kontoId: string;
          kontoKlasse: string;
          nummer: string;
        } | null;
        vorsteuerkuerzungEigennutzungKonto?: {
          bezeichnung: string;
          firmendatenId?: string | null;
          kontoId: string;
          kontoKlasse: string;
          nummer: string;
        } | null;
        aufteilungsschluessel: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        aufwandsKontoList: Array<{ bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string }>;
        vorsteuerkuerzungKonto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
        vorsteuerkuerzungWeMieterKonto?: {
          bezeichnung: string;
          firmendatenId?: string | null;
          kontoId: string;
          kontoKlasse: string;
          nummer: string;
        } | null;
      }>;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VorsteuerkuerzungEinstellungListDocument = gql`
  query VorsteuerkuerzungEinstellungList($verwaltungsart: Verwaltungsart!, $onlyCurrent: Boolean, $withDetails: Boolean) {
    getVorsteuerkuerzungEinstellungList(verwaltungsart: $verwaltungsart, onlyCurrent: $onlyCurrent, withDetails: $withDetails) {
      data {
        ...VorsteuerkuerzungEinstellungFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorsteuerkuerzungEinstellungFieldsFragmentDoc}
`;
export function useVorsteuerkuerzungEinstellungListQuery(
  baseOptions: Apollo.QueryHookOptions<VorsteuerkuerzungEinstellungListQuery, VorsteuerkuerzungEinstellungListQueryVariables> &
    ({ variables: VorsteuerkuerzungEinstellungListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorsteuerkuerzungEinstellungListQuery, VorsteuerkuerzungEinstellungListQueryVariables>(
    VorsteuerkuerzungEinstellungListDocument,
    options
  );
}
export function useVorsteuerkuerzungEinstellungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorsteuerkuerzungEinstellungListQuery, VorsteuerkuerzungEinstellungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorsteuerkuerzungEinstellungListQuery, VorsteuerkuerzungEinstellungListQueryVariables>(
    VorsteuerkuerzungEinstellungListDocument,
    options
  );
}
export function useVorsteuerkuerzungEinstellungListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VorsteuerkuerzungEinstellungListQuery, VorsteuerkuerzungEinstellungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorsteuerkuerzungEinstellungListQuery, VorsteuerkuerzungEinstellungListQueryVariables>(
    VorsteuerkuerzungEinstellungListDocument,
    options
  );
}
export type VorsteuerkuerzungEinstellungListQueryHookResult = ReturnType<typeof useVorsteuerkuerzungEinstellungListQuery>;
export type VorsteuerkuerzungEinstellungListLazyQueryHookResult = ReturnType<typeof useVorsteuerkuerzungEinstellungListLazyQuery>;
export type VorsteuerkuerzungEinstellungListSuspenseQueryHookResult = ReturnType<typeof useVorsteuerkuerzungEinstellungListSuspenseQuery>;
export type VorsteuerkuerzungEinstellungListQueryResult = Apollo.QueryResult<
  VorsteuerkuerzungEinstellungListQuery,
  VorsteuerkuerzungEinstellungListQueryVariables
>;
