import React, { FC } from 'react';
import { DatePicker, Form } from 'formik-antd';
import { Formik } from 'formik';
import { showSuccessMsgOther } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useCreateBookingSuggestionVerbuchenGenerierlaufMutation } from '../../Verarbeitung/gql/BookingSuggestionVerbuchenGenerierlauf/BookingSuggestionVerbuchenGenerierlaufMutations.types';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import FormButtons from '../../../components/Button/FormButtons';
import {
  bookingSuggestionVerbuchenFormFields,
  BookingSuggestionVerbuchenFormValues,
  bookingSuggestionVerbuchenInitialValues,
  mapFormValuesToBookingSuggestionCreateInput,
} from './formMapper';
import { bookingSuggestionVerbuchenValidationSchema } from './bookingSuggestionVerbuchenValidationSchema';
import PaymentProposalSelect from '../../PaymentProposal/PaymentProposalSelect';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../components/DatePicker/DatePicker_formikAntD';

type Props = {
  onAction: () => void;
};

const BookingSuggestionVerbuchenForm: FC<Props> = ({ onAction }) => {
  const [runVerbuchen] = useCreateBookingSuggestionVerbuchenGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgOther('Zahlungsvorschläge werden verbucht');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<BookingSuggestionVerbuchenFormValues>
      initialValues={bookingSuggestionVerbuchenInitialValues}
      validationSchema={bookingSuggestionVerbuchenValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToBookingSuggestionCreateInput(values);
        runVerbuchen({ variables: { input } }).finally(() => setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon name={bookingSuggestionVerbuchenFormFields.buchungsdatum} label="Buchungsdatum">
            <DatePicker
              id={bookingSuggestionVerbuchenFormFields.buchungsdatum}
              name={bookingSuggestionVerbuchenFormFields.buchungsdatum}
              placeholder="Datum wählen"
              format={DATE_DISPLAY_FORMAT_DEFAULT}
              allowClear={false}
              style={{ width: '100%' }}
              size="small"
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={bookingSuggestionVerbuchenFormFields.paymentProposalId} label="Zahlungsvorschlag">
            <PaymentProposalSelect name={bookingSuggestionVerbuchenFormFields.paymentProposalId} showNotAssignedOption={false} />
          </FormItemWithoutColon>
          <FormButtons onCancel={onAction} updateMode={false} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default BookingSuggestionVerbuchenForm;
