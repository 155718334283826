import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../styles/pdfStyles';
import { Summe } from '../../../../../topAbrechnung-types';

const TAAbrKreisAufwandssummes: FC<{ aufwandSumme: Summe }> = ({ aufwandSumme }) => (
  <View style={[pdfStyles.row]}>
    <Text style={[pdfStyles.column, { textAlign: 'right', width: '50%' }]}>{aufwandSumme.text}</Text>
    <Text style={[pdfStyles.column, { textAlign: 'right', width: '50%' }]}>{aufwandSumme.betrag}</Text>
  </View>
);

export default TAAbrKreisAufwandssummes;
