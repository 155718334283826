import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { findTextbausteinByType, replacePlatzhalterValuesWithExampleValues } from '../../../../../components/Template/PDFTemplates/templateHelpers';
import { BelegTexte, TextbausteinType } from '../../../../../types';
import { Vorschreibung } from '../../../../../pages/PDFTemplates/templates/vorschreibung/vorschreibung-types';
import { mockVorschreibungDauerRg } from '../../../../../pages/PDFTemplates/mocks/vorschreibung/vorschreibungDauerRgMocks';
import { VorschreibungSelectableSection } from '../../../../../pages/PDFTemplates/templates/vorschreibung/VorschreibungTemplate';
import { BelegTextePlatzhalter } from '../../../../Belegtext/Form/belegTexteFormTypes';
import {
  getDocumentFooterWithoutTextbaustein,
  getDocumentHeaderWithoutTextbaustein,
} from '../../../../shared/Belegtext/Template/belegTexteWithoutTextbausteinTemplateHelper';

export const createAuftragsartPDFTemplateData = (
  firmendatenId: string | undefined,
  textbausteinList: TextbausteinFormValues[],
  showUstBlock: boolean,
  belegTexte: BelegTexte | undefined,
  belegTextePlatzhalter: BelegTextePlatzhalter | undefined
): Vorschreibung => {
  const einleitungstext = findTextbausteinByType(textbausteinList, TextbausteinType.Einleitungstext);
  const schlusstext = findTextbausteinByType(textbausteinList, TextbausteinType.Schlusstext);
  const titeltext = findTextbausteinByType(textbausteinList, TextbausteinType.Titeltext);

  // FIXME: mockVorschreibungDauerRg should come from backend
  return {
    ...mockVorschreibungDauerRg,
    ustDetails: showUstBlock ? mockVorschreibungDauerRg.ustDetails : null,
    einleitungstext: replacePlatzhalterValuesWithExampleValues(einleitungstext),
    schlusstext: replacePlatzhalterValuesWithExampleValues(schlusstext),
    titleText: replacePlatzhalterValuesWithExampleValues(titeltext),
    ...(firmendatenId && belegTexte && belegTextePlatzhalter
      ? {
          header: getDocumentHeaderWithoutTextbaustein(firmendatenId, belegTexte, belegTextePlatzhalter),
          footer: getDocumentFooterWithoutTextbaustein(firmendatenId, belegTexte, belegTextePlatzhalter),
        }
      : {}),
  };
};

export const mapTextbausteinToAuftragsartSelectableSection = (type?: TextbausteinType): VorschreibungSelectableSection | undefined => {
  if (!type) {
    return undefined;
  }

  switch (type) {
    case TextbausteinType.Titeltext:
      return 'TITELTEXT';
    case TextbausteinType.Einleitungstext:
      return 'EINLEITUNGSTEXT';
    case TextbausteinType.Schlusstext:
      return 'SCHLUSSTEXT';
    default:
      return undefined;
  }
};
