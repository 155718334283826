import * as Yup from 'yup';
import { entityIsRequired } from '../../../components/message/validationMsg';

export const linkageFormValidationSchema = Yup.object().shape({
  abrechnungsdefinitionId: Yup.string().required(entityIsRequired('Abrechnungsdefinition')),
  abrKreisVPosList: Yup.array().of(
    Yup.object().shape({
      abrechnungskreisId: Yup.string().required(entityIsRequired('Abrechnungkreis')),
      vorschreibungspositionId: Yup.string().required(entityIsRequired('Vorschreibungposition')),
    })
  ),
});
