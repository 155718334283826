import { Select, SelectProps } from 'formik-antd';
import React, { FC, useState } from 'react';
import useDebounce from '../../../../hooks/useDebounce';
import { useFilterFibuBuchungsanweisungVertragspartnerListQuery } from '../../gql/BuchungsanweisungQueries.types';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';

type Props = {
  rechtstraegerIdList?: string[] | null;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'placeholder' | 'size' | 'showSearch'>;

const FibuBaVertragspartnerSelect: FC<Props> = ({ rechtstraegerIdList, ...restProps }) => {
  const [kurzBezeichnungText, setKurzBezeichnungText] = useState<string>('');
  const debouncedText = useDebounce(kurzBezeichnungText);

  const { loading, data } = useFilterFibuBuchungsanweisungVertragspartnerListQuery({
    variables: {
      rechtstraegerIdList,
      kurzBezeichnung: debouncedText as string,
    },
  });

  const vertragspartnerList = data?.getFilterFibuBuchungsanweisungVertragspartnerList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      mode="multiple"
      placeholder="Buchungskreis auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      onSelect={setKurzBezeichnungText}
      onSearch={(value) => {
        kurzBezeichnungText.length === 1 && !value && setKurzBezeichnungText(''); // Checking the length of the belegnummerText makes the search component editable
        value && setKurzBezeichnungText(value);
      }}
      onClear={() => setKurzBezeichnungText('')}
      searchValue={kurzBezeichnungText}
      notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
    >
      {vertragspartnerList.map((vertragspartner) => (
        <Select.Option key={vertragspartner.rechtstraegerId} value={vertragspartner.rechtstraegerId}>
          {vertragspartner.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FibuBaVertragspartnerSelect;
