import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBudgetingVPosAuftschluesselLinkageTemplateMutationVariables = Types.Exact<{
  input: Types.BudgetingVPosAuftschluesselLinkageCreateInput;
}>;

export type CreateBudgetingVPosAuftschluesselLinkageTemplateMutation = {
  createBudgetingVPosAuftschluesselLinkageTemplate: {
    data: { budgetingVPosAuftschluesselLinkageId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBudgetingVPosAuftschluesselLinkageTemplateMutationVariables = Types.Exact<{
  budgetingVPosAuftschluesselLinkageId: Types.Scalars['ID']['input'];
  input: Types.BudgetingVPosAuftschluesselLinkageUpdateInput;
}>;

export type UpdateBudgetingVPosAuftschluesselLinkageTemplateMutation = {
  updateBudgetingVPosAuftschluesselLinkageTemplate: {
    data: { budgetingVPosAuftschluesselLinkageId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBudgetingVPosAuftschluesselLinkageTemplateMutationVariables = Types.Exact<{
  budgetingVPosAuftschluesselLinkageId: Types.Scalars['ID']['input'];
}>;

export type DeleteBudgetingVPosAuftschluesselLinkageTemplateMutation = {
  deleteBudgetingVPosAuftschluesselLinkageTemplate: {
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateBudgetingVPosAuftschluesselLinkageTemplateDocument = gql`
  mutation CreateBudgetingVPosAuftschluesselLinkageTemplate($input: BudgetingVPosAuftschluesselLinkageCreateInput!) {
    createBudgetingVPosAuftschluesselLinkageTemplate(input: $input) {
      data {
        budgetingVPosAuftschluesselLinkageId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateBudgetingVPosAuftschluesselLinkageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBudgetingVPosAuftschluesselLinkageTemplateMutation,
    CreateBudgetingVPosAuftschluesselLinkageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBudgetingVPosAuftschluesselLinkageTemplateMutation,
    CreateBudgetingVPosAuftschluesselLinkageTemplateMutationVariables
  >(CreateBudgetingVPosAuftschluesselLinkageTemplateDocument, options);
}
export type CreateBudgetingVPosAuftschluesselLinkageTemplateMutationHookResult = ReturnType<
  typeof useCreateBudgetingVPosAuftschluesselLinkageTemplateMutation
>;
export type CreateBudgetingVPosAuftschluesselLinkageTemplateMutationResult =
  Apollo.MutationResult<CreateBudgetingVPosAuftschluesselLinkageTemplateMutation>;
export type CreateBudgetingVPosAuftschluesselLinkageTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateBudgetingVPosAuftschluesselLinkageTemplateMutation,
  CreateBudgetingVPosAuftschluesselLinkageTemplateMutationVariables
>;
export const UpdateBudgetingVPosAuftschluesselLinkageTemplateDocument = gql`
  mutation UpdateBudgetingVPosAuftschluesselLinkageTemplate(
    $budgetingVPosAuftschluesselLinkageId: ID!
    $input: BudgetingVPosAuftschluesselLinkageUpdateInput!
  ) {
    updateBudgetingVPosAuftschluesselLinkageTemplate(budgetingVPosAuftschluesselLinkageId: $budgetingVPosAuftschluesselLinkageId, input: $input) {
      data {
        budgetingVPosAuftschluesselLinkageId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateBudgetingVPosAuftschluesselLinkageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBudgetingVPosAuftschluesselLinkageTemplateMutation,
    UpdateBudgetingVPosAuftschluesselLinkageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBudgetingVPosAuftschluesselLinkageTemplateMutation,
    UpdateBudgetingVPosAuftschluesselLinkageTemplateMutationVariables
  >(UpdateBudgetingVPosAuftschluesselLinkageTemplateDocument, options);
}
export type UpdateBudgetingVPosAuftschluesselLinkageTemplateMutationHookResult = ReturnType<
  typeof useUpdateBudgetingVPosAuftschluesselLinkageTemplateMutation
>;
export type UpdateBudgetingVPosAuftschluesselLinkageTemplateMutationResult =
  Apollo.MutationResult<UpdateBudgetingVPosAuftschluesselLinkageTemplateMutation>;
export type UpdateBudgetingVPosAuftschluesselLinkageTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateBudgetingVPosAuftschluesselLinkageTemplateMutation,
  UpdateBudgetingVPosAuftschluesselLinkageTemplateMutationVariables
>;
export const DeleteBudgetingVPosAuftschluesselLinkageTemplateDocument = gql`
  mutation DeleteBudgetingVPosAuftschluesselLinkageTemplate($budgetingVPosAuftschluesselLinkageId: ID!) {
    deleteBudgetingVPosAuftschluesselLinkageTemplate(budgetingVPosAuftschluesselLinkageId: $budgetingVPosAuftschluesselLinkageId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteBudgetingVPosAuftschluesselLinkageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBudgetingVPosAuftschluesselLinkageTemplateMutation,
    DeleteBudgetingVPosAuftschluesselLinkageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBudgetingVPosAuftschluesselLinkageTemplateMutation,
    DeleteBudgetingVPosAuftschluesselLinkageTemplateMutationVariables
  >(DeleteBudgetingVPosAuftschluesselLinkageTemplateDocument, options);
}
export type DeleteBudgetingVPosAuftschluesselLinkageTemplateMutationHookResult = ReturnType<
  typeof useDeleteBudgetingVPosAuftschluesselLinkageTemplateMutation
>;
export type DeleteBudgetingVPosAuftschluesselLinkageTemplateMutationResult =
  Apollo.MutationResult<DeleteBudgetingVPosAuftschluesselLinkageTemplateMutation>;
export type DeleteBudgetingVPosAuftschluesselLinkageTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteBudgetingVPosAuftschluesselLinkageTemplateMutation,
  DeleteBudgetingVPosAuftschluesselLinkageTemplateMutationVariables
>;
