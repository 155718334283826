import React, { FC } from 'react';
import { Empty, Table } from 'antd';
import bookingPreviewTableColumns from './bookingPreviewTableColumns';
import { BuchungszeileWithDeviationInfo, StandingInvoiceBuchungPreview } from '../../../../../../types';

type Props = {
  data: StandingInvoiceBuchungPreview[];
  loading: boolean;
};

export type StandingInvoiceBuchungszeile = {
  partialAmountName: string;
  buchungsdatum: string;
} & BuchungszeileWithDeviationInfo;

const BookingPreviewTable: FC<Props> = ({ data, loading }) => {
  const buchungszeileList: StandingInvoiceBuchungszeile[] = data
    .map((invoice) => {
      return invoice.buchung.buchungszeileList.map((buchungszeile, index) => {
        return {
          ...buchungszeile,
          partialAmountName: invoice.partialAmountName,
          buchungsdatum: invoice.buchung.buchungsdatum,
        };
      });
    })
    .flat();

  return (
    <Table<StandingInvoiceBuchungszeile>
      size="small"
      dataSource={buchungszeileList}
      rowKey={(record) => record.buchungszeileId}
      columns={bookingPreviewTableColumns}
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Buchungen vorhanden</span>} />,
      }}
      pagination={false}
    />
  );
};

export default BookingPreviewTable;
