import React, { FC } from 'react';
import { Divider, Modal, Space, Typography } from 'antd';

import { Link } from 'rebass';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import { useUpdateBeVertragMutation } from '../gql/BeVertragMutations.types';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { getEntityWarningMsgByAttribute } from '../../../../helpers/entityWarningHelper';
import VertragspartnerCardDetailsInfoRow from '../../shared/VertragspartnerCardDetailsInfoRow';
import { BeVertragFragment } from '../gql/BeVertragFragments.types';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import ContextMenu from '../../../../components/Timeline/ContextMenu';

type Props = {
  vertrag: BeVertragFragment;
  objektId: string;
  bestandseinheitId: string;
  onZuruecksetzenSuccess: () => void;
  toggleVertragNutzungDetailsForm: () => void;
};

const VertragNutzungDetails: FC<Props> = ({ vertrag, objektId, bestandseinheitId, onZuruecksetzenSuccess, toggleVertragNutzungDetailsForm }) => {
  const [runUpdate] = useUpdateBeVertragMutation({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId: vertrag.vertragId,
      vertragInput: {
        bankDetailsId: vertrag.bankDetails?.bankDetailsId,
        vertragsBeginn: mapFormDateValueToDateString(vertrag.vertragsBeginn),
        vertragsEnde: vertrag.vertragsEnde ? mapFormDateValueToDateString(vertrag.vertragsEnde) : null,
        vertragspartnerId: vertrag.vertragspartner.rechtstraegerId,
        vertragsart: vertrag.vertragsart.value,
        applyIndexedValues: vertrag.applyIndexedValues,
        tagList: vertrag.tagList,
      },
    },
    onCompleted: () => {
      showSuccessMsgUpdate(`Vertrag`);
      onZuruecksetzenSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <>
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography.Title level={5}>Allgemeine Informationen</Typography.Title>
        <ContextMenu openDrawerCreate={toggleVertragNutzungDetailsForm} />
      </Space>
      <CardDetailsInfoRow infoRowTitle="Vertragsbeginn">
        <CustomFormattedDate value={vertrag.vertragsBeginn} />
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Vertragsende">
        {vertrag.vertragsEnde ? <CustomFormattedDate value={vertrag.vertragsEnde} /> : <TextForEmptyValue textToShow="minus" />}
      </CardDetailsInfoRow>
      <CardDetailsInfoRow
        infoRowTitle={
          <Space direction="vertical">
            <Typography.Text type="secondary">Vertragsart</Typography.Text>
            {getWarningList(vertrag, 'vertragsart')}
          </Space>
        }
      >
        <Typography.Text>{vertrag.vertragsart.text}</Typography.Text>
      </CardDetailsInfoRow>
      <VertragspartnerCardDetailsInfoRow
        rechtstraegerId={vertrag.vertragspartner.rechtstraegerId}
        status={vertrag.vertragspartner.status}
        kurzBezeichnung={vertrag.vertragspartner.kurzBezeichnung}
        warningList={getWarningList(vertrag, 'vertragspartner')}
      />
      <CardDetailsInfoRow
        infoRowTitle={
          <Space direction="vertical">
            <Typography.Text type="secondary">Indexierte Werte</Typography.Text>
          </Space>
        }
      >
        <Typography.Text>{vertrag.applyIndexedValues ? 'Automatisch anwenden' : 'Vorlegen'}</Typography.Text>
      </CardDetailsInfoRow>

      {(hasNutzungsartDifference(vertrag) || hasVertragspartnerDifference(vertrag) || hasVertragsartDifference(vertrag)) && (
        <Space style={{ width: '100%', justifyContent: 'right' }}>
          <Link onClick={() => showConfirm(runUpdate)} style={{ alignSelf: 'flex-end' }}>
            zurücksetzen
          </Link>
        </Space>
      )}
      <Divider dashed />
    </>
  );
};

const getWarningList = (vertrag: BeVertragFragment, attribute: keyof BeVertragFragment) =>
  getEntityWarningMsgByAttribute(vertrag, attribute) ? (
    <Typography.Text type="danger">{getEntityWarningMsgByAttribute(vertrag, attribute)}</Typography.Text>
  ) : null;

const showConfirm = (runUpdate: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Änderungen zurücksetzen?',
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      runUpdate();
    },
  });
};

const VERTRAG_WARNING_NUTZUNGSART_DIFF = 'NUTZUNGS_ART_DIFFERENCE_EXISTS';
const VERTRAG_WARNING_VERTRAGSPARTNER_DIFF = 'VERTRAGSPARTNER_DIFFERENCE_EXISTS';
const VERTRAG_WARNING_VERTRAGSART_DIFF = 'VERTRAGSART_DIFFERENCE_EXISTS';

const hasNutzungsartDifference = (vertrag: BeVertragFragment): boolean =>
  vertrag.warningList.some((warning) => warning.type === VERTRAG_WARNING_NUTZUNGSART_DIFF);

const hasVertragspartnerDifference = (vertrag: BeVertragFragment): boolean =>
  vertrag.warningList.some((warning) => warning.type === VERTRAG_WARNING_VERTRAGSPARTNER_DIFF);

const hasVertragsartDifference = (vertrag: BeVertragFragment): boolean =>
  vertrag.warningList.some((warning) => warning.type === VERTRAG_WARNING_VERTRAGSART_DIFF);

export default VertragNutzungDetails;
