import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from '../filtersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FibuBuchungTypeFormSelect from '../../../../BuchungType/Fibu/FibuBuchungTypeFormSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
};

const BuchungTypeSelectFormItem: FC<Props> = ({ formikProps }) => {
  return (
    <FormItemWithoutColon name={filtersFormFields.buchungsTypeList} label={listingLabelList.buchungsTypeList}>
      <FibuBuchungTypeFormSelect name={filtersFormFields.buchungsTypeList} onChange={formikProps.submitForm} mode="multiple" />
    </FormItemWithoutColon>
  );
};

export default BuchungTypeSelectFormItem;
