import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../styles/pdfStyles';
import { AbrechnungskreisDetailsAbrechnungskreisAufteilungAufwand } from '../../../../../topAbrechnung-types';

const TAAbrKreisNonRepFondsKontoList: FC<{
  aufteilungAufwand: AbrechnungskreisDetailsAbrechnungskreisAufteilungAufwand;
}> = ({ aufteilungAufwand }) => (
  <View>
    {aufteilungAufwand.kontoList.map((konto, index) => (
      <View style={pdfStyles.row} key={index}>
        <Text style={pdfStyles.textBulletin}>{konto.bezeichnung}</Text>
        <Text style={[pdfStyles.textBulletin, { width: 'auto' }]}>{konto.betragNetto}</Text>
      </View>
    ))}

    <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { marginBottom: '1mm' }]} />
  </View>
);

export default TAAbrKreisNonRepFondsKontoList;
