import { generatePath } from 'react-router';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { URI_MAHNUNG_PAGE } from '../../mahnungUriPaths';
import { MahnungListingFiltersFormValues } from '../mahnungListingFiltersFormMapper';

export enum MahnungQueryParamKey {
  BUCHUNGSKREIS_ID = 'buchungskreisId',
  OBJEKT_ID = 'objektId',
  VERTRAGSPARTNER_ID = 'vertragspartnerId',
  STATUS_LIST = 'statusList',
  MAHNSTICHTAG_VON_INKLUSIVE = 'mahnstichtagVonInklusive',
  MAHNSTICHTAG_BIS_INKLUSIVE = 'mahnstichtagBisInklusive',
  ERSTELLER_MITARBEITER_ID_LIST = 'erstellerMitarbeiterIdList',
  RECHNUNGS_AUSSTELLER_ID = 'rechnungsAusstellerId',
  SUM_RECHNUNGSBETRAG_VON_INKLUSIVE = 'sumRechnungsbetragVonInklusive',
  SUM_RECHNUNGSBETRAG_BIS_INKLUSIVE = 'sumRechnungsbetragBisInklusive',
  SUM_MAHNBETRAG_VON_INKLUSIVE = 'sumMahnbetragVonInklusive',
  SUM_MAHNBETRAG_BIS_INKLUSIVE = 'sumMahnbetragBisInklusive',
  MAHNGEBUEHR_VON_INKLUSIVE = 'mahngebuehrVonInklusive',
  MAHNGEBUEHR_BIS_INKLUSIVE = 'mahngebuehrBisInklusive',
  VERZUGSZINSEN_VON_INKLUSIVE = 'verzugszinsenVonInklusive',
  VERZUGSZINSEN_BIS_INKLUSIVE = 'verzugszinsenBisInklusive',
  VERSAND_EINSCHREIBEN = 'versandEinschreiben',
  CREATE_TS_FROM = 'createTsFrom',
  CREATE_TS_TO = 'createTsTo',
}

export type TMahnungQueryParams = QueryParams<MahnungListingFiltersFormValues>;

export const updateQueryParams = (navigate: (path: string) => void, filters: TMahnungQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  navigate(generatePathToMahnungListingPage(filters, paginationParams));

const generatePathToMahnungListingPage = (filters: TMahnungQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_MAHNUNG_PAGE)}?${mapToQueryString({
    [MahnungQueryParamKey.BUCHUNGSKREIS_ID]: filters?.buchungskreisId,
    [MahnungQueryParamKey.OBJEKT_ID]: filters?.objektId,
    [MahnungQueryParamKey.VERTRAGSPARTNER_ID]: filters?.vertragspartnerId,
    [MahnungQueryParamKey.STATUS_LIST]: filters?.statusList,
    [MahnungQueryParamKey.ERSTELLER_MITARBEITER_ID_LIST]: filters?.erstellerMitarbeiterIdList,
    [MahnungQueryParamKey.MAHNSTICHTAG_VON_INKLUSIVE]: filters?.mahnstichtagVonInklusive,
    [MahnungQueryParamKey.MAHNSTICHTAG_BIS_INKLUSIVE]: filters?.mahnstichtagBisInklusive,
    [MahnungQueryParamKey.RECHNUNGS_AUSSTELLER_ID]: filters?.rechnungsAusstellerId,
    [MahnungQueryParamKey.SUM_RECHNUNGSBETRAG_VON_INKLUSIVE]: filters?.sumRechnungsbetragVonInklusive,
    [MahnungQueryParamKey.SUM_RECHNUNGSBETRAG_BIS_INKLUSIVE]: filters?.sumRechnungsbetragBisInklusive,
    [MahnungQueryParamKey.SUM_MAHNBETRAG_BIS_INKLUSIVE]: filters?.sumMahnbetragBisInklusive,
    [MahnungQueryParamKey.SUM_MAHNBETRAG_VON_INKLUSIVE]: filters?.sumMahnbetragVonInklusive,
    [MahnungQueryParamKey.MAHNGEBUEHR_VON_INKLUSIVE]: filters?.mahngebuehrVonInklusive,
    [MahnungQueryParamKey.MAHNGEBUEHR_BIS_INKLUSIVE]: filters?.mahngebuehrBisInklusive,
    [MahnungQueryParamKey.VERZUGSZINSEN_VON_INKLUSIVE]: filters?.verzugszinsenVonInklusive,
    [MahnungQueryParamKey.VERZUGSZINSEN_BIS_INKLUSIVE]: filters?.verzugszinsenBisInklusive,
    [MahnungQueryParamKey.VERSAND_EINSCHREIBEN]: filters?.versandEinschreiben,
    [MahnungQueryParamKey.CREATE_TS_FROM]: filters?.createTsFrom,
    [MahnungQueryParamKey.CREATE_TS_TO]: filters?.createTsTo,
    ...paginationParams,
  })}`;
