import { Empty } from 'antd';
import React, { FC } from 'react';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { FileInfo, FileInfoType } from '../../../../types';
import documentTableColumns from './documentTableColumns';
import TableWithPagination from '../../../../components/Table/TableWithPagination/TableWithPagination';
import { useFileInfoListForFirmendatenQuery } from '../../gql/FileStorageQueries.types';
import { TQueryParams } from '../Filters/documentListingFiltersFormMapper';
import DocumentRow from '../Row';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../../shared/dataChangedSubscription';
import { DocumentTableProps } from './documentTableType';

type Props = {
  queryParams: TQueryParams;
} & DocumentTableProps;

const DocumentTable: FC<Props> = ({ queryParams, ...restProps }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();
  const {
    documentType,
    invoiceIssuerId,
    invoiceRecipientId,
    invoiceNumber,
    homeownerId,
    objektId,
    invoiceDateFromInclusive,
    invoiceDateToInclusive,
    dunningReferenceDateFromInclusive,
    dunningReferenceDateToInclusive,
    bestandseinheitId,
    budgetingYear,
  } = queryParams;

  const { loading, data, pagination, handleTableSorting, refetch } = useQueryWithPagingAndSorting(
    useFileInfoListForFirmendatenQuery,
    {
      variables: {
        // QueryParams ---- START
        typeList: documentType ? [documentType as FileInfoType] : (restProps.typeList ?? DOCUMENT_TYPE_LIST_DEFAULT),
        invoiceIssuerId,
        invoiceRecipientId,
        invoiceNumber: invoiceNumber?.toString(),
        invoiceDateFromInclusive,
        invoiceDateToInclusive,
        dunningReferenceDateFromInclusive,
        dunningReferenceDateToInclusive,
        homeownerId,
        objektId: restProps.objektId ?? objektId,
        bestandseinheitId: restProps.bestandseinheitId ?? bestandseinheitId,
        budgetingYear: budgetingYear ? Number(budgetingYear) : undefined,
        // QueryParams ---- END
        zinslisteId: restProps.zinslisteId,
        zinslisteVertragId: restProps.zinslisteVertragId,
        auftragId: restProps.auftragId,
        belegId: restProps.belegId,
        vorschreibungspostionId: restProps.vorschreibungspostionId,
        rechtstraegerId: restProps.rechtstraegerId,
        vertragId: restProps.vertragId,
        heAbrechnungId: restProps.heAbrechnungId,
        heAbrechnungHeVertragId: restProps.heAbrechnungHeVertragId,
        heVertragId: restProps.heVertragId,
        objektAbrechnungId: restProps.objektAbrechnungId,
        subAbrechnungId: restProps.subAbrechnungId,
        subAbrechnungEigentuemerId: restProps.subAbrechnungEigentuemerId,
        topAbrechnungId: restProps.topAbrechnungId,
        mahnungId: restProps.mahnungId,
        mailId: restProps.mailId,
        budgetingId: restProps.budgetingId,
      },
      fetchPolicy: 'no-cache',
    },
    {
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );
  const fileInfoList = data?.getFileInfoListForFirmendaten.data?.contentList ?? [];

  useOnDataChangedEvent({
    filter: {
      type: dataChangedSubscriptionTypes.firmendatenFile,
      params: {
        type: documentType,
        objektId: restProps.objektId,
        bestandseinheitId: restProps.bestandseinheitId,
        zinslisteId: restProps.zinslisteId,
        zinslisteVertragId: restProps.zinslisteVertragId,
        auftragId: restProps.auftragId,
        belegId: restProps.belegId,
        vorschreibungspostionId: restProps.vorschreibungspostionId,
        invoiceIssuerId,
        invoiceRecipientId,
        rechtstraegerId: restProps.rechtstraegerId,
        vertragId: restProps.vertragId,
        heAbrechnungId: restProps.heAbrechnungId,
        heAbrechnungHeVertragId: restProps.heAbrechnungHeVertragId,
        objektAbrechnungId: restProps.objektAbrechnungId,
        topAbrechnungId: restProps.topAbrechnungId,
        mahnungId: restProps.mahnungId,
      },
    },
    key: 'documents',
    notificationDescription: 'Es gibt neue Dokumente',
    callback: refetch,
  });

  const components = {
    body: {
      row: DocumentRow,
    },
  };

  return (
    <TableWithPagination<FileInfo>
      size="small"
      showHeader={false}
      locale={{
        emptyText: <Empty description={<span>Keine Dokumente vorhanden</span>} />,
      }}
      dataSource={fileInfoList}
      loading={loading}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={documentTableColumns}
      components={components}
      rowKey={(record) => record.fileId}
      // @ts-ignore
      onRow={(record, rowIndex) => ({
        rowindex: rowIndex,
        record,
        rechnungTyp: documentType,
        invoiceIssuerId,
        invoiceRecipientId,
      })}
      scroll={{ x: 900 }}
      {...restProps}
    />
  );
};

const DOCUMENT_TYPE_LIST_DEFAULT = [
  FileInfoType.AusgangsRechnung,
  FileInfoType.IncomingInvoice,
  FileInfoType.HeAbrechnung,
  FileInfoType.Mahnung,
  FileInfoType.BkObjektAbrechnung,
  FileInfoType.WeObjektAbrechnung,
  FileInfoType.BkTopAbrechnung,
  FileInfoType.WeTopAbrechnung,
  FileInfoType.ObjektFinancialPlan,
  FileInfoType.TopFinancialPlan,
  FileInfoType.SubAbrechnungObjekt,
  FileInfoType.SubAbrechnungBestandseinheit,
];

export default DocumentTable;
