import React from 'react';
import { Route } from 'react-router';
import IndexSeriesPage from './IndexSeriesPage';
import AuthRoute from '../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../constants/andromedaSystemSettingsUriPaths';

const andromedaSysSettingsIndexSeriesPageRoute = (
  <Route path={URI_ANDROMEDA_SYS_SETTINGS.indexSeries} element={<AuthRoute element={<IndexSeriesPage />} />} />
);

export default andromedaSysSettingsIndexSeriesPageRoute;
