import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Tabs, TabsProps } from 'antd';
import { handleUpdateSubmit } from '../../shared/handleSubmit';
import { ObjektInfoFeldType, ObjektInfoFeldVersicherung } from '../../../../../../types';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import VersicherungDatenTab from '../Tabs/VersicherungDatenTab';
import { mapToUpdateFormValues, versicherungUpdateFormFields, VersicherungUpdateFormValues } from './versicherungUpdateFormMapper';
import { versicherungValidationSchema } from '../shared/versicherungValidationSchema';
import InfoFeldDocumentTab from '../../../../../shared/InfoFeld/Tabs/InfoFeldDocumentTab';
import ObjektInfoFeldTicketListing from '../../Auflage/Ticket/ObjektInfoFeldTicketListing';
import FormButtonClose from '../../../../../../components/Button/FormButtonClose';
import { InfoFeldUpdateFormTabKey } from '../../../../../InfoFeld/infoFeldTypes';

type Props = {
  objektId: string;
  onCancel: () => void;
  onInfoFeldAction: () => void;
  onTicketAction: () => void;
  infoFeld: ObjektInfoFeldVersicherung;
  activeKeyTab: InfoFeldUpdateFormTabKey;
};

const VersicherungUpdateForm: FC<Props> = ({ objektId, onCancel, onInfoFeldAction, onTicketAction, infoFeld, activeKeyTab }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<VersicherungUpdateFormValues>
      initialValues={mapToUpdateFormValues(infoFeld)}
      validationSchema={versicherungValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleUpdateSubmit(
          firmendatenId,
          objektId,
          infoFeld.objektInfoFeldId,
          ObjektInfoFeldType.Versicherung,
          values,
          setSubmitting,
          onInfoFeldAction
        );
      }}
    >
      {(formikProps) => {
        const items: TabsProps['items'] = [
          {
            key: InfoFeldUpdateFormTabKey.DATA,
            label: 'Daten',
            children: (
              <Form layout="vertical">
                <VersicherungDatenTab formFields={versicherungUpdateFormFields} firmaId={formikProps.values.firmaId} formikProps={formikProps} />

                <InfoFeldUpload
                  name={versicherungUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.TICKET,
            label: 'Fristen/Aufgaben',
            children: (
              <>
                <ObjektInfoFeldTicketListing objektId={objektId} infoFeld={infoFeld} onSuccess={onTicketAction} />
                <FormButtonClose onCancel={onCancel} />
              </>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.DOCUMENT,
            label: 'Dokumente',
            children: (
              <Form layout="vertical">
                <InfoFeldDocumentTab
                  infoFeld={infoFeld}
                  deleteDocumentFileIdList={formikProps.values.deleteDocumentFileIdList}
                  onDocumentDelete={(value) => formikProps.setFieldValue(versicherungUpdateFormFields.deleteDocumentFileIdList, value)}
                  firmendatenId={firmendatenId}
                />
                <InfoFeldUpload
                  name={versicherungUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
        ];

        return <Tabs defaultActiveKey={activeKeyTab} style={{ minHeight: '420px' }} items={items} />;
      }}
    </Formik>
  );
};

export default VersicherungUpdateForm;
