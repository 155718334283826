import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ObjektFinancialPlanGenerierlaufFieldsFragmentDoc } from './ObjektFinancialPlanGenerierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjektFinancialPlanGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type ObjektFinancialPlanGenerierlaufQuery = {
  getObjektFinancialPlanGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      budgeting: {
        bezeichnung: string;
        budgetSumNextYear?: number | null;
        budgetingId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        currentYear: number;
        increase?: number | null;
        prevYear: number;
        updatedByMitarbeiterId?: string | null;
        vertragVPosWertDate?: string | null;
        vorschreibungCurrentYearSum: number;
        vorschreibungPrevYearSum: number;
        restOfCurrentYearSum?: number | null;
        totalCurrentYearSum?: number | null;
        objekt: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          updatedByMitarbeiterId?: string | null;
          rechnungsAussteller: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          status: { text: string; value: Types.ObjektStatus; description?: string | null };
          verwaltungsart: { text: string; value: Types.Verwaltungsart };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
        status: { text: string; value: Types.BudgetingStatus };
        warningList: Array<{ message: string; type: string }>;
      };
      entryList: Array<{
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        retry: boolean;
        stepCount?: number | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ObjektFinancialPlanGenerierlaufDocument = gql`
  query ObjektFinancialPlanGenerierlauf($generierlaufId: ID!) {
    getObjektFinancialPlanGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        ...ObjektFinancialPlanGenerierlaufFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektFinancialPlanGenerierlaufFieldsFragmentDoc}
`;
export function useObjektFinancialPlanGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektFinancialPlanGenerierlaufQuery, ObjektFinancialPlanGenerierlaufQueryVariables> &
    ({ variables: ObjektFinancialPlanGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektFinancialPlanGenerierlaufQuery, ObjektFinancialPlanGenerierlaufQueryVariables>(
    ObjektFinancialPlanGenerierlaufDocument,
    options
  );
}
export function useObjektFinancialPlanGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObjektFinancialPlanGenerierlaufQuery, ObjektFinancialPlanGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektFinancialPlanGenerierlaufQuery, ObjektFinancialPlanGenerierlaufQueryVariables>(
    ObjektFinancialPlanGenerierlaufDocument,
    options
  );
}
export function useObjektFinancialPlanGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ObjektFinancialPlanGenerierlaufQuery, ObjektFinancialPlanGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektFinancialPlanGenerierlaufQuery, ObjektFinancialPlanGenerierlaufQueryVariables>(
    ObjektFinancialPlanGenerierlaufDocument,
    options
  );
}
export type ObjektFinancialPlanGenerierlaufQueryHookResult = ReturnType<typeof useObjektFinancialPlanGenerierlaufQuery>;
export type ObjektFinancialPlanGenerierlaufLazyQueryHookResult = ReturnType<typeof useObjektFinancialPlanGenerierlaufLazyQuery>;
export type ObjektFinancialPlanGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useObjektFinancialPlanGenerierlaufSuspenseQuery>;
export type ObjektFinancialPlanGenerierlaufQueryResult = Apollo.QueryResult<
  ObjektFinancialPlanGenerierlaufQuery,
  ObjektFinancialPlanGenerierlaufQueryVariables
>;
