import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import AddressesAndContactsCreateForm from './Form/AddressesAndContactsCreateForm';
import { RechtstraegerType } from '../../../types';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import RechtstraegerDeletionHistoryDropdown from '../DeletionHistory/RechtstraegerDeletionHistoryDropdown';

type Props = {
  onSuccess: () => void;
  rechtstraegerId: string;
  rechtstraegerType: RechtstraegerType;
};

const AddressesAndContactsBtnAndDrawer: FC<Props> = ({ rechtstraegerId, onSuccess, rechtstraegerType }) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();

  const onCreateSuccess = () => {
    onSuccess();
    toggleDrawerOpen();
  };

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <RechtstraegerDeletionHistoryDropdown rechtstraegerId={rechtstraegerId} rechtstraegerType={rechtstraegerType} />
        <Button icon={<PlusOutlined />} type="primary" onClick={toggleDrawerOpen}>
          Neu
        </Button>
      </ButtonsAligned>
      <Drawer title="Adresse oder Kontakt anlegen" width={720} open={isDrawerOpen} onClose={toggleDrawerOpen} destroyOnClose>
        <AddressesAndContactsCreateForm
          rechtstraegerId={rechtstraegerId}
          onSuccess={onCreateSuccess}
          onCancel={toggleDrawerOpen}
          rechtstraegerType={rechtstraegerType}
        />
      </Drawer>
    </>
  );
};

export default AddressesAndContactsBtnAndDrawer;
