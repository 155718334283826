import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Pain001SchemaListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type Pain001SchemaListQuery = {
  getPain001SchemaList: {
    data: Array<{ text: string; value: Types.Pain001Schema }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type Pain008SchemaListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type Pain008SchemaListQuery = {
  getPain008SchemaList: {
    data: Array<{ text: string; value: Types.Pain008Schema }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const Pain001SchemaListDocument = gql`
  query Pain001SchemaList {
    getPain001SchemaList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function usePain001SchemaListQuery(baseOptions?: Apollo.QueryHookOptions<Pain001SchemaListQuery, Pain001SchemaListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Pain001SchemaListQuery, Pain001SchemaListQueryVariables>(Pain001SchemaListDocument, options);
}
export function usePain001SchemaListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Pain001SchemaListQuery, Pain001SchemaListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Pain001SchemaListQuery, Pain001SchemaListQueryVariables>(Pain001SchemaListDocument, options);
}
export function usePain001SchemaListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Pain001SchemaListQuery, Pain001SchemaListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Pain001SchemaListQuery, Pain001SchemaListQueryVariables>(Pain001SchemaListDocument, options);
}
export type Pain001SchemaListQueryHookResult = ReturnType<typeof usePain001SchemaListQuery>;
export type Pain001SchemaListLazyQueryHookResult = ReturnType<typeof usePain001SchemaListLazyQuery>;
export type Pain001SchemaListSuspenseQueryHookResult = ReturnType<typeof usePain001SchemaListSuspenseQuery>;
export type Pain001SchemaListQueryResult = Apollo.QueryResult<Pain001SchemaListQuery, Pain001SchemaListQueryVariables>;
export const Pain008SchemaListDocument = gql`
  query Pain008SchemaList {
    getPain008SchemaList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function usePain008SchemaListQuery(baseOptions?: Apollo.QueryHookOptions<Pain008SchemaListQuery, Pain008SchemaListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Pain008SchemaListQuery, Pain008SchemaListQueryVariables>(Pain008SchemaListDocument, options);
}
export function usePain008SchemaListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Pain008SchemaListQuery, Pain008SchemaListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Pain008SchemaListQuery, Pain008SchemaListQueryVariables>(Pain008SchemaListDocument, options);
}
export function usePain008SchemaListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Pain008SchemaListQuery, Pain008SchemaListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Pain008SchemaListQuery, Pain008SchemaListQueryVariables>(Pain008SchemaListDocument, options);
}
export type Pain008SchemaListQueryHookResult = ReturnType<typeof usePain008SchemaListQuery>;
export type Pain008SchemaListLazyQueryHookResult = ReturnType<typeof usePain008SchemaListLazyQuery>;
export type Pain008SchemaListSuspenseQueryHookResult = ReturnType<typeof usePain008SchemaListSuspenseQuery>;
export type Pain008SchemaListQueryResult = Apollo.QueryResult<Pain008SchemaListQuery, Pain008SchemaListQueryVariables>;
