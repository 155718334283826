import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUstKategorieVersionMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  input: Types.UstKategorieVersionCreateInput;
}>;

export type CreateUstKategorieVersionMutation = {
  createUstKategorieVersion: {
    data: { ustKategorieVersionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateUstKategorieVersionMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  ustKategorieVersionId: Types.Scalars['ID']['input'];
  input: Types.UstKategorieVersionUpdateInput;
}>;

export type UpdateUstKategorieVersionMutation = {
  updateUstKategorieVersion: {
    data: { ustKategorieVersionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteUstKategorieVersionMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  ustKategorieVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteUstKategorieVersionMutation = {
  deleteUstKategorieVersion: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateUstKategorieVersionDocument = gql`
  mutation CreateUstKategorieVersion($ustKategorieId: ID!, $input: UstKategorieVersionCreateInput!) {
    createUstKategorieVersion(ustKategorieId: $ustKategorieId, input: $input) {
      data {
        ustKategorieVersionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateUstKategorieVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUstKategorieVersionMutation, CreateUstKategorieVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUstKategorieVersionMutation, CreateUstKategorieVersionMutationVariables>(
    CreateUstKategorieVersionDocument,
    options
  );
}
export type CreateUstKategorieVersionMutationHookResult = ReturnType<typeof useCreateUstKategorieVersionMutation>;
export type CreateUstKategorieVersionMutationResult = Apollo.MutationResult<CreateUstKategorieVersionMutation>;
export type CreateUstKategorieVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateUstKategorieVersionMutation,
  CreateUstKategorieVersionMutationVariables
>;
export const UpdateUstKategorieVersionDocument = gql`
  mutation UpdateUstKategorieVersion($ustKategorieId: ID!, $ustKategorieVersionId: ID!, $input: UstKategorieVersionUpdateInput!) {
    updateUstKategorieVersion(ustKategorieId: $ustKategorieId, ustKategorieVersionId: $ustKategorieVersionId, input: $input) {
      data {
        ustKategorieVersionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateUstKategorieVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUstKategorieVersionMutation, UpdateUstKategorieVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUstKategorieVersionMutation, UpdateUstKategorieVersionMutationVariables>(
    UpdateUstKategorieVersionDocument,
    options
  );
}
export type UpdateUstKategorieVersionMutationHookResult = ReturnType<typeof useUpdateUstKategorieVersionMutation>;
export type UpdateUstKategorieVersionMutationResult = Apollo.MutationResult<UpdateUstKategorieVersionMutation>;
export type UpdateUstKategorieVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateUstKategorieVersionMutation,
  UpdateUstKategorieVersionMutationVariables
>;
export const DeleteUstKategorieVersionDocument = gql`
  mutation DeleteUstKategorieVersion($ustKategorieId: ID!, $ustKategorieVersionId: ID!) {
    deleteUstKategorieVersion(ustKategorieId: $ustKategorieId, ustKategorieVersionId: $ustKategorieVersionId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteUstKategorieVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUstKategorieVersionMutation, DeleteUstKategorieVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUstKategorieVersionMutation, DeleteUstKategorieVersionMutationVariables>(
    DeleteUstKategorieVersionDocument,
    options
  );
}
export type DeleteUstKategorieVersionMutationHookResult = ReturnType<typeof useDeleteUstKategorieVersionMutation>;
export type DeleteUstKategorieVersionMutationResult = Apollo.MutationResult<DeleteUstKategorieVersionMutation>;
export type DeleteUstKategorieVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteUstKategorieVersionMutation,
  DeleteUstKategorieVersionMutationVariables
>;
