import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import BeInfoFeldListing from './BeInfoFeldListing';

type BeInfoFeldTabProps = {
  objektId: string;
  bestandseinheitId: string;
};

const BeInfoFeldTab: FC<BeInfoFeldTabProps> = ({ objektId, bestandseinheitId }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Informationen',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <BeInfoFeldListing objektId={objektId} bestandseinheitId={bestandseinheitId} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default BeInfoFeldTab;
