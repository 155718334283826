import { FC, useEffect, useState } from 'react';
import { Space } from 'antd';
import { ReactSortable } from 'react-sortablejs';
import StreetAddressListItem from './ListItem/StreetAddress/StreetAddressListItem';
import { Address, AddressesAndContacts, AddressType, Contact, ContactPerson } from '../../../../types';
import PostofficeBoxAddressListItem from './ListItem/PostofficeBoxAddress/PostofficeBoxAddressListItem';
import { ContactType } from '../../../../shared/components/Contact/contactHelper';
import EmailContactListItem from './ListItem/EmailContact/EmailContactListItem';
import PhoneContactListItem from './ListItem/PhoneContact/PhoneContactListItem';
import { TOperatingSiteList } from '../../../../shared/components/OperatingSite/List/OperatingSiteList';
import ContactPersonFirmaListItem from './ListItem/ContactPerson/Firma/ContactPersonFirmaListItem';
import ContactPersonFirmendatenListItem from './ListItem/ContactPerson/Firmendaten/ContactPersonFirmendatenListItem';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import {
  buildAdresseAndContactListWithId,
  CLASS_DRAG_AND_DROP_HANDLE_REF,
  getHauptAddress,
  getHauptEmailContact,
  getHauptPhoneContact,
  TAdresseContactContactPersonListItem,
  toggleGrabbingClassToAllOperatingSiteLists,
} from './addressAndContactListHelpers';

import './styles/addressAndContactList.css';
import {
  useSetOperatingSiteForAddressMutation,
  useSetOperatingSiteForContactMutation,
  useSetOperatingSiteForFirmaContactPersonMutation,
  useSetOperatingSiteForFirmendatenContactPersonMutation,
} from '../gql/AddressesAndContactsMutations.types';

type AdresseAndContactListProps = {
  operatingSite?: TOperatingSiteList;
  addressesAndContacts?: AddressesAndContacts;
  onSuccess: () => void;
  firmendatenId: string;
  rechtstraegerId?: string;
  operatingSiteId?: string;
  operatingSiteList?: TOperatingSiteList[];
};

export type TAddressOrContactListItem = Contact | Address;

export type TContactPersonAddressOrContactListItem = TAddressOrContactListItem | ContactPerson;

const AddressAndContactList: FC<AdresseAndContactListProps> = ({
  addressesAndContacts,
  onSuccess,
  firmendatenId,
  rechtstraegerId,
  operatingSiteId,
  operatingSiteList,
}) => {
  const adresseAndContactList: TAdresseContactContactPersonListItem[] = buildAdresseAndContactListWithId(addressesAndContacts);
  const operatingSiteListWithoutCurrent = operatingSiteList?.filter((operatingSite) => operatingSite.operatingSiteId !== operatingSiteId);

  const [runSetOperatingSiteForAddress] = useSetOperatingSiteForAddressMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Adresse`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSetOperatingSiteForFDContactPerson] = useSetOperatingSiteForFirmendatenContactPersonMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Ansprechpartner`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSetOperatingSiteForFirmaContactPerson] = useSetOperatingSiteForFirmaContactPersonMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Ansprechpartner`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSetOperatingSiteForContact] = useSetOperatingSiteForContactMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Adresse`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [list, setList] = useState<TAdresseContactContactPersonListItem[]>(adresseAndContactList);

  useEffect(() => {
    setList(buildAdresseAndContactListWithId(addressesAndContacts));
  }, [addressesAndContacts, operatingSiteId]);

  const isDraggable = !!operatingSiteId;

  return (
    <Space direction="vertical" style={{ width: '80%' }} size={8}>
      <ReactSortable<TAdresseContactContactPersonListItem>
        list={list}
        setList={(items) => {
          if (adresseAndContactList.length !== items.length) {
            const newItems = items.filter((item) => !adresseAndContactList.some((adresseAndContact) => adresseAndContact.createTs === item.createTs));
            const newItem = newItems?.[0];
            Promise.resolve()
              .then(() => setList(items))
              .then(() => {
                if (newItem) {
                  if ('contactPersonId' in newItem && firmendatenId && !rechtstraegerId) {
                    runSetOperatingSiteForFDContactPerson({
                      variables: {
                        firmendatenId,
                        contactPersonId: newItem.contactPersonId,
                        input: { operatingSiteId },
                      },
                    });
                  } else if ('contactPersonId' in newItem && rechtstraegerId) {
                    runSetOperatingSiteForFirmaContactPerson({
                      variables: {
                        firmaId: rechtstraegerId,
                        contactPersonId: newItem.contactPersonId,
                        input: { operatingSiteId },
                      },
                    });
                  } else if ('addressId' in newItem) {
                    runSetOperatingSiteForAddress({
                      variables: {
                        firmendatenId,
                        addressId: newItem.addressId,
                        input: { operatingSiteId },
                      },
                    });
                  } else if ('contactId' in newItem) {
                    runSetOperatingSiteForContact({
                      variables: {
                        firmendatenId,
                        contactId: newItem.contactId,
                        input: { operatingSiteId },
                      },
                    });
                  }
                }
              });
          }
        }}
        group="adresseAndContactList" // provide the same groupName to every list to enable dragging between them
        sort={false} // to prevent sorting while dragging
        style={{ display: 'flex', flexWrap: 'wrap' }}
        disabled={!isDraggable}
        handle={CLASS_DRAG_AND_DROP_HANDLE_REF} // className of the list item to tell which element is draggable
        onStart={toggleGrabbingClassToAllOperatingSiteLists}
        onEnd={toggleGrabbingClassToAllOperatingSiteLists}
      >
        {list
          .map((item) => {
            if ('contactPersonId' in item && rechtstraegerId) {
              return (
                <ContactPersonFirmaListItem
                  key={item.contactPersonId}
                  contactPerson={item}
                  onSuccess={onSuccess}
                  firmaId={rechtstraegerId}
                  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                  operatingSiteId={operatingSiteId!}
                  operatingSiteList={operatingSiteListWithoutCurrent}
                />
              );
            }
            if ('contactPersonId' in item && firmendatenId) {
              return (
                <ContactPersonFirmendatenListItem
                  key={item.contactPersonId}
                  contactPerson={item}
                  onSuccess={onSuccess}
                  firmendatenId={firmendatenId}
                  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                  operatingSiteId={operatingSiteId!}
                  operatingSiteList={operatingSiteListWithoutCurrent}
                />
              );
            }
            if ('addressId' in item && item.type === AddressType.Street) {
              return (
                <StreetAddressListItem
                  key={item.addressId}
                  streetAddress={item}
                  firmendatenId={firmendatenId}
                  onSuccess={onSuccess}
                  isHauptInUse={!!getHauptAddress(addressesAndContacts)}
                  operatingSiteList={operatingSiteListWithoutCurrent}
                  isDraggable={isDraggable}
                />
              );
            }
            if ('contactId' in item && item.type === ContactType.EMAIL) {
              return (
                <EmailContactListItem
                  key={item.contactId}
                  emailContact={item}
                  firmendatenId={firmendatenId}
                  onSuccess={onSuccess}
                  isHauptInUse={!!getHauptEmailContact(addressesAndContacts)}
                  operatingSiteList={operatingSiteListWithoutCurrent}
                  isDraggable={isDraggable}
                />
              );
            }
            if ('contactId' in item && item.type === ContactType.PHONE) {
              return (
                <PhoneContactListItem
                  key={item.contactId}
                  phoneContact={item}
                  firmendatenId={firmendatenId}
                  rechtstraegerId={rechtstraegerId}
                  onSuccess={onSuccess}
                  isHauptInUse={!!getHauptPhoneContact(addressesAndContacts)}
                  operatingSiteList={operatingSiteListWithoutCurrent}
                  isDraggable={isDraggable}
                />
              );
            }
            if ('addressId' in item && item.type === AddressType.PostofficeBox) {
              return (
                <PostofficeBoxAddressListItem
                  key={item.addressId}
                  postofficeBoxAddress={item}
                  firmendatenId={firmendatenId}
                  onSuccess={onSuccess}
                  isHauptInUse={!!getHauptAddress(addressesAndContacts)}
                  operatingSiteList={operatingSiteListWithoutCurrent}
                  isDraggable={isDraggable}
                />
              );
            }
            return null;
          })
          .filter((item) => item)}
      </ReactSortable>
    </Space>
  );
};

export default AddressAndContactList;
