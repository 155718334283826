import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router';
import { uri } from '../../constants/appUriPaths';
import { ErrorModel } from '../../helpers/errorAndWarningHelper';
import { LogoutFn } from './authContext';
import { AuthAction } from './auth.model';

export const dispatchResetAppStateActionForUnauthError = (error: ErrorModel, dispatch: Dispatch<AuthAction>) => {
  if (error.unauthorizedError) {
    dispatch({ type: 'RESET_APP_STATE' });
  }
};

// FIXME is it the right place for this fn?
export const navigateToErrorPageIfResourceNotFound = (error: ErrorModel, reason: any, navigate: NavigateFunction, logout: LogoutFn) => {
  if (error.notFound) {
    navigate(uri.error, { state: { reason } });
    logout('unauthenticated');
    return Promise.resolve();
  }
  throw error;
};
