import React, { FC } from 'react';
import FiltersWithMax3Fields from '../../../components/Filters/FiltersWithMax3Fields';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { beInfoFeldListingFiltersFormFields } from './beInfoFeldListingFiltersFormMapper';
import BeInfoFeldTypeFormSelect from './Filters/BeInfoFeldTypeFormSelect';
import BeInfoFeldDrawerAndButton from './BeInfoFeldDrawerAndButton';

type Props = {
  onSuccess: () => void;
  objektId: string;
  bestandseinheitId: string;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const BeInfoFeldListingFilters: FC<Props> = ({ objektId, bestandseinheitId, onSuccess, showArchived, toggleShowArchived }) => (
  <FiltersWithMax3Fields
    hideTitle
    filters={
      <>
        <FormItemWithoutColon name={beInfoFeldListingFiltersFormFields.type} label="Typ">
          <BeInfoFeldTypeFormSelect name={beInfoFeldListingFiltersFormFields.type} />
        </FormItemWithoutColon>
      </>
    }
    actionButtons={
      <BeInfoFeldDrawerAndButton
        onSuccess={onSuccess}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        showArchived={showArchived}
        toggleShowArchived={toggleShowArchived}
      />
    }
  />
);

export default BeInfoFeldListingFilters;
