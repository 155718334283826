import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartObjektAbrechnungGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartObjektAbrechnungGenerierlaufMutation = {
  actionRestartObjektAbrechnungGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartObjektAbrechnungGenerierlaufDocument = gql`
  mutation RestartObjektAbrechnungGenerierlauf($generierlaufId: ID!) {
    actionRestartObjektAbrechnungGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartObjektAbrechnungGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartObjektAbrechnungGenerierlaufMutation, RestartObjektAbrechnungGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartObjektAbrechnungGenerierlaufMutation, RestartObjektAbrechnungGenerierlaufMutationVariables>(
    RestartObjektAbrechnungGenerierlaufDocument,
    options
  );
}
export type RestartObjektAbrechnungGenerierlaufMutationHookResult = ReturnType<typeof useRestartObjektAbrechnungGenerierlaufMutation>;
export type RestartObjektAbrechnungGenerierlaufMutationResult = Apollo.MutationResult<RestartObjektAbrechnungGenerierlaufMutation>;
export type RestartObjektAbrechnungGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartObjektAbrechnungGenerierlaufMutation,
  RestartObjektAbrechnungGenerierlaufMutationVariables
>;
