import React, { FC } from 'react';
import { Table } from 'antd';
import { FormikProps } from 'formik';
import {
  RepFondsKontenZuweisungFormValues,
  RepFondsKontenZuweisungVersionFormValues,
} from '../../Version/Form/repFondsKontenZuweisungVersionFormMapper';
import repFondsKontenZuweisungTableColumns from './repFondsKontenZuweisungTableColumns';

type Props = {
  formikProps: FormikProps<RepFondsKontenZuweisungVersionFormValues>;
  onDelete: (index: number) => void;
};

const RepFondsKontenZuweisungTable: FC<Props> = ({ formikProps, onDelete }) => (
  <Table<RepFondsKontenZuweisungFormValues>
    dataSource={formikProps.values.kontenZuweisungList}
    //name={repFondsKontenZuordnungVersionFormFields.kontenZuweisungList}
    columns={repFondsKontenZuweisungTableColumns(formikProps, onDelete)}
    size="small"
    pagination={false}
  />
);

export default RepFondsKontenZuweisungTable;
