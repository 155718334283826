import { FormFileReference } from '../../../components/FileUpload/formHelpers';
import {
  FibuBelegSymbol,
  FibuBuchungsanweisungBuchungsKopfInput,
  FibuBuchungsanweisungBuchungszeileInput,
  FibuBuchungsanweisungInput,
  FibuBuchungType,
  SollHaben,
} from '../../../types';
import { FormFields, mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { ValuesDescriptions } from '../../../components/Timeline/versionTimelineHelper';

// form values
export type BookingInstructionFormValues = {
  buchungsKopf?: BookingInstructionBuchungsKopfFormValues | null;
  buchungskreisRechtstraegerId: string;
  buchungszeileList: BookingInstructionBuchungszeileFormValues[];
  deliveryAddressId?: string | null;
  fachlichePruefung?: boolean | null;
  inputFile?: FormFileReference | null;
  inputFileId?: string | null;
  tempInputFileId?: string;
  rechnungsAusstellerBankDetailsId?: string | null;
  recipientEmailContactId?: string | null;
  zuStornierendeBuchungId?: string | null;
};

export type BookingInstructionBuchungsKopfFormValues = {
  belegDatum?: string;
  belegFileId?: string;
  belegSymbol?: FibuBelegSymbol | null;
  belegnummer?: string;
  buchungsType?: FibuBuchungType | null;
  buchungsdatum?: string | null;
  dueDate?: string | null;
  vertragspartnerId?: string | null;
};

export type BookingInstructionBuchungszeileFormValues = {
  bestandseinheitId?: string | null;
  betrag?: number | null;
  buchungsKZ?: string | null;
  buchungszeileId?: string | null;
  gegenKontoId?: string | null;
  heVertragId?: string | null;
  kontoId?: string | null;
  objektId?: string | null;
  sollHaben?: SollHaben | null;
  steuer?: number | null;
  steuersatz?: number | null;
  text?: string | null;
  umbuchung?: boolean;
  ustVomAufwandSteuersatz?: number | null;
  vertragId?: string | null;
};

// form fields
export const bookingInstructionFormFields: FormFields<BookingInstructionFormValues> = {
  inputFile: 'inputFile',
  inputFileId: 'inputFileId',
  tempInputFileId: 'tempInputFileId',
  buchungsKopf: 'buchungsKopf',
  buchungskreisRechtstraegerId: 'buchungskreisRechtstraegerId',
  buchungszeileList: 'buchungszeileList',
  deliveryAddressId: 'deliveryAddressId',
  fachlichePruefung: 'fachlichePruefung',
  rechnungsAusstellerBankDetailsId: 'rechnungsAusstellerBankDetailsId',
  recipientEmailContactId: 'recipientEmailContactId',
  zuStornierendeBuchungId: 'zuStornierendeBuchungId',
};

export const bookingInstructionBuchungsKopfFormFields: FormFields<BookingInstructionBuchungsKopfFormValues> = {
  belegDatum: 'belegDatum',
  belegFileId: 'belegFileId',
  belegSymbol: 'belegSymbol',
  belegnummer: 'belegnummer',
  buchungsType: 'buchungsType',
  buchungsdatum: 'buchungsdatum',
  dueDate: 'dueDate',
  vertragspartnerId: 'vertragspartnerId',
};

export const bookingInstructionBuchungszeileFormFields: FormFields<BookingInstructionBuchungszeileFormValues> = {
  bestandseinheitId: 'bestandseinheitId',
  betrag: 'betrag',
  buchungsKZ: 'buchungsKZ',
  buchungszeileId: 'buchungszeileId',
  gegenKontoId: 'gegenKontoId',
  heVertragId: 'heVertragId',
  kontoId: 'kontoId',
  objektId: 'objektId',
  sollHaben: 'sollHaben',
  steuer: 'steuer',
  steuersatz: 'steuersatz',
  text: 'text',
  umbuchung: 'umbuchung',
  ustVomAufwandSteuersatz: 'ustVomAufwandSteuersatz',
  vertragId: 'vertragId',
};

// initial values
export const bookingInstructionFormInitialValues: BookingInstructionFormValues = {
  buchungskreisRechtstraegerId: '',
  buchungszeileList: [],
};

// map form values to buchungsanweisung input
export const mapFormValuesToFibuBuchungsanweisungInput = (values: BookingInstructionFormValues): FibuBuchungsanweisungInput => ({
  buchungsKopf: values.buchungsKopf ? mapBuchungsanweisungBuchungsKopfToInput(values.buchungsKopf) : undefined,
  buchungskreisRechtstraegerId: values.buchungskreisRechtstraegerId,
  buchungszeileList: values.buchungszeileList.map((buchungszeile) => mapBuchungsanweisungBuchungszeileListToInput(buchungszeile)),
  deliveryAddressId: values.deliveryAddressId,
  fachlichePruefung: values.fachlichePruefung,
  rechnungsAusstellerBankDetailsId: values.rechnungsAusstellerBankDetailsId,
  recipientEmailContactId: values.recipientEmailContactId,
  zuStornierendeBuchungId: values.zuStornierendeBuchungId,
});

export const mapBuchungsanweisungBuchungsKopfToInput = (
  values: BookingInstructionBuchungsKopfFormValues
): FibuBuchungsanweisungBuchungsKopfInput => ({
  belegDatum: values.belegDatum ? mapFormDateValueToDateString(values.belegDatum) : undefined,
  belegFileId: values.belegFileId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  belegSymbol: values.belegSymbol!,
  belegnummer: values.belegnummer,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  buchungsType: values.buchungsType!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  buchungsdatum: mapFormDateValueToDateString(values.buchungsdatum!),
  dueDate: values.dueDate ? mapFormDateValueToDateString(values.dueDate) : undefined,
  vertragspartnerId: values.vertragspartnerId,
});

export const mapBuchungsanweisungBuchungszeileListToInput = (
  buchungszeile: BookingInstructionBuchungszeileFormValues
): FibuBuchungsanweisungBuchungszeileInput => ({
  bestandseinheitId: buchungszeile.bestandseinheitId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  betrag: buchungszeile.betrag!,
  buchungsKZ: buchungszeile.buchungsKZ,
  buchungszeileId: buchungszeile.buchungszeileId,
  gegenKontoId: buchungszeile.gegenKontoId,
  heVertragId: buchungszeile.heVertragId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  kontoId: buchungszeile.kontoId!,
  objektId: buchungszeile.objektId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  sollHaben: buchungszeile.sollHaben!,
  steuer: buchungszeile.steuer,
  steuersatz: buchungszeile.steuersatz,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  text: buchungszeile.text!,
  umbuchung: buchungszeile.umbuchung,
  ustVomAufwandSteuersatz: buchungszeile.ustVomAufwandSteuersatz,
});

// labels for form fields
export const BookingInstructionFormValuesDescriptions: ValuesDescriptions<
  Omit<BookingInstructionFormValues, 'buchungsKopf' | 'buchungszeileList' | 'inputFileId' | 'tempInputFileId'>
> = {
  buchungskreisRechtstraegerId: 'Buchungskreis',
  deliveryAddressId: 'Post an',
  fachlichePruefung: 'Fachliche Prüfung',
  inputFile: 'Belegdatei',
  rechnungsAusstellerBankDetailsId: 'Rechnungssteller Bankverbindung',
  recipientEmailContactId: 'E-Mail an',
  zuStornierendeBuchungId: 'Zu stornierende Buchung',
};

export const BookingInstructionBuchungsKopfFormValuesDescriptions: ValuesDescriptions<BookingInstructionBuchungsKopfFormValues> = {
  belegDatum: 'Belegdatum',
  belegFileId: 'Belegdatei',
  belegSymbol: 'Belegsymbol',
  belegnummer: 'Belegnummer',
  buchungsType: 'Buchungstyp',
  buchungsdatum: 'Buchungsdatum',
  dueDate: 'Fälligkeit',
  vertragspartnerId: 'Vertragspartner',
};
