import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { VorschreibungspositionFragmentDoc } from '../../Vorschreibungsposition/gql/VorschreibungspositionFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorschreibungspositionListForSelectByFirmendatenIdQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type VorschreibungspositionListForSelectByFirmendatenIdQuery = {
  getVorschreibungspositionListByFirmendatenId: {
    data: {
      contentList: Array<{
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungspositionListForSelectByKundenSystemIdQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type VorschreibungspositionListForSelectByKundenSystemIdQuery = {
  getVorschreibungspositionListByKundenSystemId: {
    data: {
      contentList: Array<{
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VorschreibungspositionListForSelectByFirmendatenIdDocument = gql`
  query VorschreibungspositionListForSelectByFirmendatenId($firmendatenId: ID!, $includeArchiviert: Boolean) {
    getVorschreibungspositionListByFirmendatenId(firmendatenId: $firmendatenId, includeArchiviert: $includeArchiviert) {
      data {
        contentList {
          ...Vorschreibungsposition
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungspositionFragmentDoc}
`;
export function useVorschreibungspositionListForSelectByFirmendatenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    VorschreibungspositionListForSelectByFirmendatenIdQuery,
    VorschreibungspositionListForSelectByFirmendatenIdQueryVariables
  > &
    ({ variables: VorschreibungspositionListForSelectByFirmendatenIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungspositionListForSelectByFirmendatenIdQuery, VorschreibungspositionListForSelectByFirmendatenIdQueryVariables>(
    VorschreibungspositionListForSelectByFirmendatenIdDocument,
    options
  );
}
export function useVorschreibungspositionListForSelectByFirmendatenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VorschreibungspositionListForSelectByFirmendatenIdQuery,
    VorschreibungspositionListForSelectByFirmendatenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VorschreibungspositionListForSelectByFirmendatenIdQuery,
    VorschreibungspositionListForSelectByFirmendatenIdQueryVariables
  >(VorschreibungspositionListForSelectByFirmendatenIdDocument, options);
}
export function useVorschreibungspositionListForSelectByFirmendatenIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VorschreibungspositionListForSelectByFirmendatenIdQuery,
        VorschreibungspositionListForSelectByFirmendatenIdQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VorschreibungspositionListForSelectByFirmendatenIdQuery,
    VorschreibungspositionListForSelectByFirmendatenIdQueryVariables
  >(VorschreibungspositionListForSelectByFirmendatenIdDocument, options);
}
export type VorschreibungspositionListForSelectByFirmendatenIdQueryHookResult = ReturnType<
  typeof useVorschreibungspositionListForSelectByFirmendatenIdQuery
>;
export type VorschreibungspositionListForSelectByFirmendatenIdLazyQueryHookResult = ReturnType<
  typeof useVorschreibungspositionListForSelectByFirmendatenIdLazyQuery
>;
export type VorschreibungspositionListForSelectByFirmendatenIdSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungspositionListForSelectByFirmendatenIdSuspenseQuery
>;
export type VorschreibungspositionListForSelectByFirmendatenIdQueryResult = Apollo.QueryResult<
  VorschreibungspositionListForSelectByFirmendatenIdQuery,
  VorschreibungspositionListForSelectByFirmendatenIdQueryVariables
>;
export const VorschreibungspositionListForSelectByKundenSystemIdDocument = gql`
  query VorschreibungspositionListForSelectByKundenSystemId($kundenSystemId: ID!, $includeArchiviert: Boolean) {
    getVorschreibungspositionListByKundenSystemId(kundenSystemId: $kundenSystemId, includeArchiviert: $includeArchiviert) {
      data {
        contentList {
          ...Vorschreibungsposition
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungspositionFragmentDoc}
`;
export function useVorschreibungspositionListForSelectByKundenSystemIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    VorschreibungspositionListForSelectByKundenSystemIdQuery,
    VorschreibungspositionListForSelectByKundenSystemIdQueryVariables
  > &
    ({ variables: VorschreibungspositionListForSelectByKundenSystemIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungspositionListForSelectByKundenSystemIdQuery, VorschreibungspositionListForSelectByKundenSystemIdQueryVariables>(
    VorschreibungspositionListForSelectByKundenSystemIdDocument,
    options
  );
}
export function useVorschreibungspositionListForSelectByKundenSystemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VorschreibungspositionListForSelectByKundenSystemIdQuery,
    VorschreibungspositionListForSelectByKundenSystemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VorschreibungspositionListForSelectByKundenSystemIdQuery,
    VorschreibungspositionListForSelectByKundenSystemIdQueryVariables
  >(VorschreibungspositionListForSelectByKundenSystemIdDocument, options);
}
export function useVorschreibungspositionListForSelectByKundenSystemIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VorschreibungspositionListForSelectByKundenSystemIdQuery,
        VorschreibungspositionListForSelectByKundenSystemIdQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VorschreibungspositionListForSelectByKundenSystemIdQuery,
    VorschreibungspositionListForSelectByKundenSystemIdQueryVariables
  >(VorschreibungspositionListForSelectByKundenSystemIdDocument, options);
}
export type VorschreibungspositionListForSelectByKundenSystemIdQueryHookResult = ReturnType<
  typeof useVorschreibungspositionListForSelectByKundenSystemIdQuery
>;
export type VorschreibungspositionListForSelectByKundenSystemIdLazyQueryHookResult = ReturnType<
  typeof useVorschreibungspositionListForSelectByKundenSystemIdLazyQuery
>;
export type VorschreibungspositionListForSelectByKundenSystemIdSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungspositionListForSelectByKundenSystemIdSuspenseQuery
>;
export type VorschreibungspositionListForSelectByKundenSystemIdQueryResult = Apollo.QueryResult<
  VorschreibungspositionListForSelectByKundenSystemIdQuery,
  VorschreibungspositionListForSelectByKundenSystemIdQueryVariables
>;
