import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { friendlyFormatIBAN } from 'ibantools';
import { useFilterPaymentInvoiceIssuerIbanListQuery } from './gql/PaymentFilterQueries.types';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = { rechtstraegerId?: string | null } & SelectProps;

const PaymentInvoiceIssuerIbanSelect: FC<Props> = ({ rechtstraegerId, ...restProps }) => {
  const { loading, data } = useFilterPaymentInvoiceIssuerIbanListQuery({ variables: { rechtstraegerId } });
  const list = data?.getFilterPaymentInvoiceIssuerIbanList.data;

  return (
    <Select
      id={restProps.name}
      loading={loading}
      size="small"
      placeholder="Bankverbindung auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      {...restProps}
    >
      {list?.map((iban) => {
        return (
          <Select.Option key={iban} value={iban}>
            <DataWithShortenedText maxTextLength={40} text={`${friendlyFormatIBAN(iban)}`} />
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default PaymentInvoiceIssuerIbanSelect;
