import React, { FC } from 'react';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { SubAdministrationAbrechnungsdefinitionVersionListEntry } from '../../../../types';
import { useSubAdministrationAbrechnungsdefinitionTemplateVersionListQuery } from '../../gql/SubAdministrationAbrDef/SubAdministrationAbrDefTemplateVersionQueries.types';
import { useDeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutation } from '../../gql/SubAdministrationAbrDef/SubAdministrationAbrDefTemplateVersionMutations.types';
import AbrDefTemplateVersionTimeline from './AbrDefTemplateVersionTimeline';

type Props = {
  abrechnungsdefinitionId: string;
};

const AbrDefTemplateSubVersionTimeline: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data, loading, refetch } = useSubAdministrationAbrechnungsdefinitionTemplateVersionListQuery({
    variables: { abrechnungsdefinitionId },
  });
  const versionList = data?.getSubAdministrationAbrechnungsdefinitionTemplateVersionList.data;

  const [deleteSubAbrDefVersion] = useDeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutation({
    onCompleted: () => {
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onDelete = (version: SubAdministrationAbrechnungsdefinitionVersionListEntry): Promise<unknown> => {
    return deleteSubAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId: version.abrechnungsdefinitionVersionId,
      },
    });
  };

  return (
    <AbrDefTemplateVersionTimeline
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      loading={loading}
      versionList={versionList}
      onDelete={onDelete}
    />
  );
};

export default AbrDefTemplateSubVersionTimeline;
