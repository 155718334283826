import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { VertragInfoFeldFragmentDoc } from './VertragInfoFeldFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeVertragInfoFeldListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BeVertragInfoFeldListQuery = {
  getBeVertragInfoFeldList: {
    data: Array<
      | {
          __typename: 'VertragInfoFeldKuendigungsinfos';
          vertragInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          kuendigungBis: string;
          kuendigungsverzichtBestandgeberBis?: string | null;
          kuendigungsverzichtBestandnehmerBis?: string | null;
          bestandnehmerKuendigungsfrist?: number | null;
          kuendigungJeweilsZum?: string | null;
          kuendigungMoeglichZum?: string | null;
          gerichtlicheKuendigung?: boolean | null;
          kuendigungErfolgtAm?: string | null;
          kuendigungWirksamZum?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.VertragInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
        }
      | {
          __typename: 'VertragInfoFeldWeiterePerson';
          vertragInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          text?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.VertragInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
        }
    >;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BeVertragInfoFeldQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type BeVertragInfoFeldQuery = {
  getBeVertragInfoFeld: {
    data:
      | {
          __typename: 'VertragInfoFeldKuendigungsinfos';
          vertragInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          kuendigungBis: string;
          kuendigungsverzichtBestandgeberBis?: string | null;
          kuendigungsverzichtBestandnehmerBis?: string | null;
          bestandnehmerKuendigungsfrist?: number | null;
          kuendigungJeweilsZum?: string | null;
          kuendigungMoeglichZum?: string | null;
          gerichtlicheKuendigung?: boolean | null;
          kuendigungErfolgtAm?: string | null;
          kuendigungWirksamZum?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.VertragInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
        }
      | {
          __typename: 'VertragInfoFeldWeiterePerson';
          vertragInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          text?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.VertragInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
        };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BeVertragInfoFeldListDocument = gql`
  query BeVertragInfoFeldList($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $includeArchiviert: Boolean) {
    getBeVertragInfoFeldList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      includeArchiviert: $includeArchiviert
    ) {
      data {
        ...VertragInfoFeld
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VertragInfoFeldFragmentDoc}
`;
export function useBeVertragInfoFeldListQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragInfoFeldListQuery, BeVertragInfoFeldListQueryVariables> &
    ({ variables: BeVertragInfoFeldListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragInfoFeldListQuery, BeVertragInfoFeldListQueryVariables>(BeVertragInfoFeldListDocument, options);
}
export function useBeVertragInfoFeldListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeVertragInfoFeldListQuery, BeVertragInfoFeldListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragInfoFeldListQuery, BeVertragInfoFeldListQueryVariables>(BeVertragInfoFeldListDocument, options);
}
export function useBeVertragInfoFeldListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeVertragInfoFeldListQuery, BeVertragInfoFeldListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragInfoFeldListQuery, BeVertragInfoFeldListQueryVariables>(BeVertragInfoFeldListDocument, options);
}
export type BeVertragInfoFeldListQueryHookResult = ReturnType<typeof useBeVertragInfoFeldListQuery>;
export type BeVertragInfoFeldListLazyQueryHookResult = ReturnType<typeof useBeVertragInfoFeldListLazyQuery>;
export type BeVertragInfoFeldListSuspenseQueryHookResult = ReturnType<typeof useBeVertragInfoFeldListSuspenseQuery>;
export type BeVertragInfoFeldListQueryResult = Apollo.QueryResult<BeVertragInfoFeldListQuery, BeVertragInfoFeldListQueryVariables>;
export const BeVertragInfoFeldDocument = gql`
  query BeVertragInfoFeld($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $infoFeldId: ID!) {
    getBeVertragInfoFeld(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, infoFeldId: $infoFeldId) {
      data {
        ...VertragInfoFeld
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VertragInfoFeldFragmentDoc}
`;
export function useBeVertragInfoFeldQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragInfoFeldQuery, BeVertragInfoFeldQueryVariables> &
    ({ variables: BeVertragInfoFeldQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragInfoFeldQuery, BeVertragInfoFeldQueryVariables>(BeVertragInfoFeldDocument, options);
}
export function useBeVertragInfoFeldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeVertragInfoFeldQuery, BeVertragInfoFeldQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragInfoFeldQuery, BeVertragInfoFeldQueryVariables>(BeVertragInfoFeldDocument, options);
}
export function useBeVertragInfoFeldSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeVertragInfoFeldQuery, BeVertragInfoFeldQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragInfoFeldQuery, BeVertragInfoFeldQueryVariables>(BeVertragInfoFeldDocument, options);
}
export type BeVertragInfoFeldQueryHookResult = ReturnType<typeof useBeVertragInfoFeldQuery>;
export type BeVertragInfoFeldLazyQueryHookResult = ReturnType<typeof useBeVertragInfoFeldLazyQuery>;
export type BeVertragInfoFeldSuspenseQueryHookResult = ReturnType<typeof useBeVertragInfoFeldSuspenseQuery>;
export type BeVertragInfoFeldQueryResult = Apollo.QueryResult<BeVertragInfoFeldQuery, BeVertragInfoFeldQueryVariables>;
