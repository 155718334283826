import React, { FC } from 'react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { useAuthorizedMitarbeiter } from '../../../../../../shared/Auth/useAuthorized';
import {
  BookingSuggestionFormValues,
  bsOffenePostenForClearingSuggestionFormFields,
  OffenePostenForClearingSuggestion,
} from '../../../Form/buchungsmaskeFormMapper';
import { getAuszifferungListFormFieldNameFromPersonenkontoOPList } from '../../auszifferungBlockHelpers';
import { createNewAuszifferungForOPExistingInClearingSuggestion } from '../../auszifferungFormMapper';
import { calculateInitialBetragForNewAuszifferung } from '../../shared/Level1/addAuszifferungHelpers';
import { disableAddButtonBasedOnAvailableAmount, isNewAuszifferung } from '../../bookingSuggestionAuszifferungBlockHelpers';

type Props = {
  offenePosten: OffenePostenForClearingSuggestion;
  toggleExpandedRow: () => void;
  fieldNamePrefix: string;
};

const AddAuszifferungColumnForClearingSuggTable: FC<Props> = ({ offenePosten, toggleExpandedRow, fieldNamePrefix }) => {
  const {
    mitarbeiter: { mitarbeiterId },
    user: { username },
  } = useAuthorizedMitarbeiter();

  const { values: formikValues, setFieldValue } = useFormikContext<BookingSuggestionFormValues>();
  const auszifferungFieldName = `${fieldNamePrefix}.${bsOffenePostenForClearingSuggestionFormFields.auszifferungList}`;

  const hasAlreadyNewAuszifferung = offenePosten.auszifferungList.some(isNewAuszifferung);

  const handleAdd = () => {
    toggleExpandedRow();

    const newAuszifferung = createNewAuszifferungForOPExistingInClearingSuggestion(
      offenePosten,
      mitarbeiterId,
      username,
      calculateInitialBetragForNewAuszifferung(formikValues, offenePosten)
    );
    const newAuszifferungList = [...offenePosten.auszifferungList, newAuszifferung];

    setFieldValue(auszifferungFieldName, newAuszifferungList);

    //set field in OP list for Personenkonto if the OP exists on the list (it might not be there if OP list is paginated and the OP is in another page than the visible)
    const auszifferungListFormFieldNameFromPersonenkontoOPList = getAuszifferungListFormFieldNameFromPersonenkontoOPList(formikValues, offenePosten);
    setFieldValue(auszifferungListFormFieldNameFromPersonenkontoOPList, newAuszifferungList);
  };

  return (
    <Button type="primary" disabled={hasAlreadyNewAuszifferung || disableAddButtonBasedOnAvailableAmount(formikValues)} onClick={handleAdd}>
      Auszifferung hinzufügen
    </Button>
  );
};

export default AddAuszifferungColumnForClearingSuggTable;
