import React from 'react';
import { Typography } from 'antd';
import { TableWithAlignedColsColumnType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { EuroAmount } from '../../../../../../components/Number';
import { AbrechnungskreisType, AbrechnungskreisTypeTuple, HeAbrAbrechnungskreisList } from '../../../../../../types';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';

const heAbrHeVertragTableColumns = (): TableWithAlignedColsColumnType<HeAbrAbrechnungskreisList>[] => [
  {
    title: 'Position',
    align: 'left',
    render: (heAbrAbrKreisList) => {
      if (
        isAbrKreisTypeAusgaben(heAbrAbrKreisList.abrechnungskreisType) ||
        isAbrKreisTypeEinnahmen(heAbrAbrKreisList.abrechnungskreisType) ||
        isAbrKreisTypeVstKrz(heAbrAbrKreisList.abrechnungskreisType)
      ) {
        return (
          <Typography.Text>
            {heAbrAbrKreisList.abrechnungskreisType.text} (Anteile {heAbrAbrKreisList.anteile} - {heAbrAbrKreisList.anteilePercent}%)
          </Typography.Text>
        );
      }
      return <Typography.Text>{heAbrAbrKreisList.abrechnungskreisType.text}</Typography.Text>;
    },
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
  {
    title: 'Objektsumme',
    align: 'right',
    render: (heAbrAbrKreisList) => <EuroAmount value={heAbrAbrKreisList.summe} />,
  },
  {
    title: 'HE-Anteile',
    align: 'right',
    render: (heAbrAbrKreisList) => <EuroAmount value={heAbrAbrKreisList.anteil} />,
  },
];

const isAbrKreisTypeAusgaben = (abrKreisType: AbrechnungskreisTypeTuple) => abrKreisType.value === AbrechnungskreisType.Ausgaben;
const isAbrKreisTypeEinnahmen = (abrKreisType: AbrechnungskreisTypeTuple) => abrKreisType.value === AbrechnungskreisType.Einnahmen;
const isAbrKreisTypeVstKrz = (abrKreisType: AbrechnungskreisTypeTuple) => abrKreisType.value === AbrechnungskreisType.Vorsteuerkuerzung;

export default heAbrHeVertragTableColumns;
