import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { InfoMailDeliveryFieldsFragmentDoc } from '../../../Wizard/InfoMailDelivery/gql/InfoMailDeliveryWizardFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InfoMailDeliverySendGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type InfoMailDeliverySendGenerierlaufQuery = {
  getInfoMailDeliverySendGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type InfoMailDeliverySendGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  emailAddress?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  recipientId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.InfoMailDeliverySendGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type InfoMailDeliverySendGenerierlaufEntryListQuery = {
  getInfoMailDeliverySendGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        generierlaufEntryId: string;
        infoMessage?: string | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        infoMailDelivery?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          emailAddress: string;
          emailDeliveryDefinitionBezeichnung: string;
          emailDeliveryDefinitionId: string;
          infoMailDeliveryId: string;
          mailId?: string | null;
          original: boolean;
          sender?: string | null;
          sentTs?: string | null;
          shouldSend: boolean;
          objektTopVertrag: {
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            createTs: string;
            name: string;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              createTs: string;
              objektId: string;
              status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            objekt: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              erstelltAm: string;
              fotoFileId?: string | null;
              fotoType?: Types.FotoType | null;
              kurzBezeichnung: string;
              objektId: string;
              rechnungsAusstellerId: string;
              verwaltungBis?: string | null;
              status: { text: string; value: Types.ObjektStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            vertrag: {
              bestandseinheitId: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              createTs: string;
              kurzBezeichnung: string;
              objektId: string;
              vertragsBeginn: string;
              vertragId: string;
              vertragspartner: {
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                createTs: string;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                type: { text: string; value: Types.RechtstraegerType };
                status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
              status: { description?: string | null; text: string; value: Types.VertragStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          recipient: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const InfoMailDeliverySendGenerierlaufDocument = gql`
  query InfoMailDeliverySendGenerierlauf($generierlaufId: ID!) {
    getInfoMailDeliverySendGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createdBy
        createdByMitarbeiterId
        createTs
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          description
          text
          value
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useInfoMailDeliverySendGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<InfoMailDeliverySendGenerierlaufQuery, InfoMailDeliverySendGenerierlaufQueryVariables> &
    ({ variables: InfoMailDeliverySendGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InfoMailDeliverySendGenerierlaufQuery, InfoMailDeliverySendGenerierlaufQueryVariables>(
    InfoMailDeliverySendGenerierlaufDocument,
    options
  );
}
export function useInfoMailDeliverySendGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InfoMailDeliverySendGenerierlaufQuery, InfoMailDeliverySendGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InfoMailDeliverySendGenerierlaufQuery, InfoMailDeliverySendGenerierlaufQueryVariables>(
    InfoMailDeliverySendGenerierlaufDocument,
    options
  );
}
export function useInfoMailDeliverySendGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InfoMailDeliverySendGenerierlaufQuery, InfoMailDeliverySendGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InfoMailDeliverySendGenerierlaufQuery, InfoMailDeliverySendGenerierlaufQueryVariables>(
    InfoMailDeliverySendGenerierlaufDocument,
    options
  );
}
export type InfoMailDeliverySendGenerierlaufQueryHookResult = ReturnType<typeof useInfoMailDeliverySendGenerierlaufQuery>;
export type InfoMailDeliverySendGenerierlaufLazyQueryHookResult = ReturnType<typeof useInfoMailDeliverySendGenerierlaufLazyQuery>;
export type InfoMailDeliverySendGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useInfoMailDeliverySendGenerierlaufSuspenseQuery>;
export type InfoMailDeliverySendGenerierlaufQueryResult = Apollo.QueryResult<
  InfoMailDeliverySendGenerierlaufQuery,
  InfoMailDeliverySendGenerierlaufQueryVariables
>;
export const InfoMailDeliverySendGenerierlaufEntryListDocument = gql`
  query InfoMailDeliverySendGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $emailAddress: String
    $vertragspartnerId: String
    $recipientId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: InfoMailDeliverySendGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getInfoMailDeliverySendGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      emailAddress: $emailAddress
      vertragspartnerId: $vertragspartnerId
      recipientId: $recipientId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          errorList {
            message
            type
          }
          exitCode {
            text
            value
          }
          generierlaufEntryId
          infoMailDelivery {
            ...InfoMailDeliveryFields
          }
          infoMessage
          warningList {
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${InfoMailDeliveryFieldsFragmentDoc}
`;
export function useInfoMailDeliverySendGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<InfoMailDeliverySendGenerierlaufEntryListQuery, InfoMailDeliverySendGenerierlaufEntryListQueryVariables> &
    ({ variables: InfoMailDeliverySendGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InfoMailDeliverySendGenerierlaufEntryListQuery, InfoMailDeliverySendGenerierlaufEntryListQueryVariables>(
    InfoMailDeliverySendGenerierlaufEntryListDocument,
    options
  );
}
export function useInfoMailDeliverySendGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InfoMailDeliverySendGenerierlaufEntryListQuery, InfoMailDeliverySendGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InfoMailDeliverySendGenerierlaufEntryListQuery, InfoMailDeliverySendGenerierlaufEntryListQueryVariables>(
    InfoMailDeliverySendGenerierlaufEntryListDocument,
    options
  );
}
export function useInfoMailDeliverySendGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InfoMailDeliverySendGenerierlaufEntryListQuery, InfoMailDeliverySendGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InfoMailDeliverySendGenerierlaufEntryListQuery, InfoMailDeliverySendGenerierlaufEntryListQueryVariables>(
    InfoMailDeliverySendGenerierlaufEntryListDocument,
    options
  );
}
export type InfoMailDeliverySendGenerierlaufEntryListQueryHookResult = ReturnType<typeof useInfoMailDeliverySendGenerierlaufEntryListQuery>;
export type InfoMailDeliverySendGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useInfoMailDeliverySendGenerierlaufEntryListLazyQuery>;
export type InfoMailDeliverySendGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<
  typeof useInfoMailDeliverySendGenerierlaufEntryListSuspenseQuery
>;
export type InfoMailDeliverySendGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  InfoMailDeliverySendGenerierlaufEntryListQuery,
  InfoMailDeliverySendGenerierlaufEntryListQueryVariables
>;
