import { Select, SelectProps } from 'formik-antd';
import { FC } from 'react';
import { IndexSeriesSourceType } from '../../../types';

const SourceTypeSelect: FC<SelectProps> = ({ name, ...restProps }) => {
  const options = [
    { text: 'Import', value: IndexSeriesSourceType.Import },
    { text: 'Manuell', value: IndexSeriesSourceType.Manual },
  ];

  return (
    <Select size="small" name={name} id={name} placeholder="Option auswählen" {...restProps}>
      {options.map((sourceType) => (
        <Select.Option key={sourceType.value} value={sourceType.value}>
          {sourceType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SourceTypeSelect;
