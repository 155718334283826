import { Tabs, TabsProps } from 'antd';
import React, { FC } from 'react';
import TabPaneContent from '../../components/Tabs/TabPaneContent';
import DocumentListing from './Listing/DocumentListing';
import { DocumentTableProps } from './Listing/Table/documentTableType';

type Props = DocumentTableProps & { hideFilters?: boolean; disabledFilters?: string[]; defaultFilters?: string[] };

const DocumentTab: FC<Props> = ({ hideFilters, disabledFilters, defaultFilters, ...restProps }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Dokumente',
      children: (
        <TabPaneContent distanceToTopBorder="large">
          <DocumentListing hideFilters={hideFilters} disabledFilters={disabledFilters} defaultFilters={defaultFilters} {...restProps} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs destroyInactiveTabPane items={items} />;
};

export default DocumentTab;
