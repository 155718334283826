import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import FormButtons from '../../../../../components/Button/FormButtons';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import BkOrWeObjAbrDefTemplate from '../shared/BkOrWe/BkOrWeObjAbrDefTemplate';
import { ObjAbrDefGeneralSettingsFormValues } from '../shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import { TopAbrDefGeneralSettingsFormValues } from '../shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import BkOrWeTopAbrDefTemplate from '../shared/BkOrWe/BkOrWeTopAbrDefTemplate';
import { AbrechnungsdefinitionType } from '../../../../../types';
import {
  BkOrWeAbrDefObjektOutputOptionsFieldHelpNames,
  BkOrWeAbrDefTopOutputOptionsFieldHelpNames,
} from '../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';

type Props = {
  bkObjektAbrTemplateValues: TextbausteinFormValues[];
  onBkObjektAbrDefTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  bkTopAbrTemplateValues: TextbausteinFormValues[];
  onBkTopAbrDefTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  objAbrDefGeneralSettingsValues: ObjAbrDefGeneralSettingsFormValues;
  onObjAbrDefGeneralSettingsChange: (values: ObjAbrDefGeneralSettingsFormValues) => void;
  topAbrDefGeneralSettingsValues: TopAbrDefGeneralSettingsFormValues;
  onTopAbrDefGeneralSettingsChange: (values: TopAbrDefGeneralSettingsFormValues) => void;
  setIsFormValid: (isFormValid: boolean) => void;
  onSubmit: () => void;
  isSubmitting?: boolean;
  onBack: () => void;
  fieldHelpNames?: {
    objekt?: BkOrWeAbrDefObjektOutputOptionsFieldHelpNames;
    top?: BkOrWeAbrDefTopOutputOptionsFieldHelpNames;
  };
};

const BkAbrDefTemplates: FC<Props> = ({
  bkObjektAbrTemplateValues,
  onBkObjektAbrDefTemplateChange,
  bkTopAbrTemplateValues,
  onBkTopAbrDefTemplateChange,
  objAbrDefGeneralSettingsValues,
  onObjAbrDefGeneralSettingsChange,
  topAbrDefGeneralSettingsValues,
  onTopAbrDefGeneralSettingsChange,
  setIsFormValid,
  onSubmit,
  isSubmitting,
  onBack,
  fieldHelpNames,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'BK-Objektabrechnung',
      children: (
        <BkOrWeObjAbrDefTemplate
          objektAbrTemplateValues={bkObjektAbrTemplateValues}
          onObjektAbrDefTemplateChange={onBkObjektAbrDefTemplateChange}
          objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
          onObjAbrDefGeneralSettingsChange={onObjAbrDefGeneralSettingsChange}
          fieldHelpNames={fieldHelpNames?.objekt}
        />
      ),
    },
    {
      key: '2',
      label: 'BK-Topabrechnung',
      children: (
        <BkOrWeTopAbrDefTemplate
          abrDefType={AbrechnungsdefinitionType.BkAbrechnung}
          topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
          onTopAbrDefGeneralSettingsChange={onTopAbrDefGeneralSettingsChange}
          topAbrTemplateValues={bkTopAbrTemplateValues}
          onTopAbrDefTemplateChange={onBkTopAbrDefTemplateChange}
          setIsFormValid={setIsFormValid}
          fieldHelpNames={fieldHelpNames?.top}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="BK-Objektabrechnung" items={items} />
      <FormButtons onCancel={onBack} cancelText="Zurück" updateMode={false} isOkLoading={isSubmitting} onOk={onSubmit} okText="Speichern" />
    </>
  );
};

export default BkAbrDefTemplates;
