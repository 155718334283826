import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { useFilterPaymentTransactionTypeListQuery } from './gql/PaymentFilterQueries.types';
import { paymentListingLabelList } from './paymentListingFiltersFormMapper';

type Props = Omit<SelectProps, 'id'>;

const PaymentTransactionTypeFilter: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useFilterPaymentTransactionTypeListQuery();
  const paymentTransactionTypeList = data?.getFilterPaymentTransactionTypeList.data ?? [];

  return (
    <FormItemWithoutColon name={restProps.name} label={paymentListingLabelList.paymentTransactionType}>
      <Select
        {...restProps}
        size="small"
        id={restProps.name}
        loading={loading}
        placeholder="Zahlungsverkehrsart auswählen"
        allowClear
        showSearch
        filterOption={selectFilterOption}
      >
        {paymentTransactionTypeList.map((type) => (
          <Select.Option key={type.value} value={type.value}>
            {type.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithoutColon>
  );
};

export default PaymentTransactionTypeFilter;
