import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import ListingFilters from './Filters/ListingFilters';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { TopFinancialPlanVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { verarbeitungTypes } from '../../verarbeitungHelpers';
import { TopFinancialPlanGenerierlauf } from '../../../../types';
import {
  mapFormValuesToTopFinancialPlanVerarbeitungFilters,
  topFinancialPlanVerarbeitungEntryFiltersFormInitialValues,
  TopFinancialPlanVerarbeitungEntryFiltersFormValues,
} from './Filters/ListingFiltersFormMapper';
import TopFinancialPlanVerarbeitungEntryTable from './Table/TopFinancialPlanVerarbeitungEntryTable';

type Props = {
  generierlauf: TopFinancialPlanGenerierlauf;
};

const TopFinancialPlanVerarbeitungEntryListing: FC<Props> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TopFinancialPlanVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.TopFinancialPlan, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: TopFinancialPlanVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.TopFinancialPlan,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToTopFinancialPlanVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<TopFinancialPlanVerarbeitungEntryFiltersFormValues>
      initialValues={topFinancialPlanVerarbeitungEntryFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlauf={generierlauf} />
          <TopFinancialPlanVerarbeitungEntryTable generierlauf={generierlauf} formikProps={formikProps} />
        </>
      )}
    </Formik>
  );
};

export default TopFinancialPlanVerarbeitungEntryListing;
