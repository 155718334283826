import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import {
  URI_VERARBEITUNG_PAYMENT_PROPOSAL_CREATE_DETAILS_PAGE,
  URI_VERARBEITUNG_PAYMENT_PROPOSAL_EXECUTE_DETAILS_PAGE,
} from '../../../features/Verarbeitung/verarbeitungApiPaths';
import PaymentProposalCreateDetailsPage from './PaymentProposalCreateDetailsPage/PaymentProposalCreateDetailsPage';
import PaymentProposalExecuteDetailsPage from './PaymentProposalExecuteDetailsPage/PaymentProposalExecuteDetailsPage';

export type VerarbeitungPaymentProposalDetailsPageRouteParams = { generierlaufId: string };

const verarbeitungPaymentProposalCreateDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_PAYMENT_PROPOSAL_CREATE_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<PaymentProposalCreateDetailsPage />} />}
  />
);
const verarbeitungPaymentProposalExecuteDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_PAYMENT_PROPOSAL_EXECUTE_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<PaymentProposalExecuteDetailsPage />} />}
  />
);

export { verarbeitungPaymentProposalCreateDetailsPageRoute, verarbeitungPaymentProposalExecuteDetailsPageRoute };
