import React, { FC } from 'react';
import { Skeleton } from 'antd';
import WeAbrDefTemplateVersionUpdateForm from '../../../../../../features/AbrechnungsdefinitionTemplate/Version/Form/Update/WeAbrDefTemplateVersionUpdateForm';
import {
  useWeAbrechnungsdefinitionTemplateVersionListQuery,
  useWeAbrechnungsdefinitionTemplateVersionQuery,
} from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/WeAbrDef/WeAbrDefTemplateVersionQueries.types';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
};
const AndromedaSysSettingsWeAbrechnungsdefinitionVersionUpdatePage: FC<Props> = ({ abrechnungsdefinitionId, abrechnungsdefinitionVersionId }) => {
  const { data: weAbrDefVersionListData } = useWeAbrechnungsdefinitionTemplateVersionListQuery({
    variables: { abrechnungsdefinitionId },
  });
  const abrechnungsdefinitionVersionList = weAbrDefVersionListData?.getWeAbrechnungsdefinitionTemplateVersionList.data;

  const { data: weAbrDefVersion, refetch } = useWeAbrechnungsdefinitionTemplateVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId },
  });
  const abrechnungsdefinitionVersion = weAbrDefVersion?.getWeAbrechnungsdefinitionTemplateVersion.data;

  if (!abrechnungsdefinitionVersion || !abrechnungsdefinitionVersionList) return <Skeleton active />;

  return (
    <WeAbrDefTemplateVersionUpdateForm
      abrechnungsdefinitionVersionList={abrechnungsdefinitionVersionList}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      validFrom={abrechnungsdefinitionVersion.validFrom}
      kommentar={abrechnungsdefinitionVersion.kommentar ?? ''}
      onSuccess={refetch}
    />
  );
};

export default AndromedaSysSettingsWeAbrechnungsdefinitionVersionUpdatePage;
