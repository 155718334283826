import React, { FC } from 'react';
import { Input } from 'formik-antd';
import { emailDeliveryDefinitionFormFields } from './emailDeliveryDefFormMapper';
import { EmailDeliveryDefinitionType } from '../../../types';
import { useEmailDeliveryDefinitionTemplatesLazyQuery } from '../gql/EmailDeliveryDefinitionQueries.types';
import EmailDeliveryTypeSelect from '../Select/EmailDeliveryTypeSelect';
import { EmailDeliveryDefinitionTemplatesFieldsFragment } from '../gql/EmailDeliveryDefinitionFragments.types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

type EmailDeliveryDefGeneralFormItemsProps = {
  onTypeChange: (template: EmailDeliveryDefinitionTemplatesFieldsFragment) => void;
  disabledType?: boolean;
};

const EmailDeliveryDefGeneralFormItems: FC<EmailDeliveryDefGeneralFormItemsProps> = ({ onTypeChange, disabledType }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'EmailDeliveryDefinition'>('EmailDeliveryDefinition');

  const [runTextbausteinTemplatesQuery] = useEmailDeliveryDefinitionTemplatesLazyQuery();

  const handleTypeChange = (type: EmailDeliveryDefinitionType) => {
    runTextbausteinTemplatesQuery({ variables: { type } }).then((res) => {
      const textbausteinTemplate = res.data?.getEmailDeliveryDefinitionTemplates.data;
      if (textbausteinTemplate && textbausteinTemplate.length > 0) {
        onTypeChange(textbausteinTemplate[0]);
      }
    });
  };

  return (
    <>
      <FormItemWithFieldHelp
        name={emailDeliveryDefinitionFormFields.bezeichnung}
        label="Bezeichnung"
        fieldHelp={getFieldHelpText('EmailDeliveryDefinition.bezeichnung')}
      >
        <Input id={emailDeliveryDefinitionFormFields.bezeichnung} name={emailDeliveryDefinitionFormFields.bezeichnung} size="middle" />
      </FormItemWithFieldHelp>
      <FormItemWithFieldHelp name={emailDeliveryDefinitionFormFields.type} label="Typ" fieldHelp={getFieldHelpText('EmailDeliveryDefinition.type')}>
        <EmailDeliveryTypeSelect name={emailDeliveryDefinitionFormFields.type} onChange={handleTypeChange} disabled={disabledType} size="middle" />
      </FormItemWithFieldHelp>
    </>
  );
};

export default EmailDeliveryDefGeneralFormItems;
