import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useAuftragsartTemplateTexteChangeHistoryListQuery } from '../gql/AuftragsartTemplateQueries.types';

type Props = {
  auftragsartId: string;
};

const AuftragsartTemplateTexteChangeHistoryListingTable: FC<Props> = ({ auftragsartId }) => {
  const { data, loading } = useAuftragsartTemplateTexteChangeHistoryListQuery({ variables: { auftragsartId } });
  const historyList = data?.getAuftragsartTemplateTexteChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="auftragsart-texte" />;
};

export default AuftragsartTemplateTexteChangeHistoryListingTable;
