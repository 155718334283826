import {
  UstKategorieEntry,
  UstKategorieVersion,
  UstKategorieVersionCreateInput,
  UstKategorieVersionEntryCreateInput,
  UstKategorieVersionEntryUpdateInput,
  UstKategorieVersionUpdateInput,
} from '../../../../../types';
import { parseFormattedDecimal, toFormattedDecimal } from '../../../../../components/Input-antd';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';

/****** UstKategorieEntryList ******/

type UstKategorieEntryListFormValues = {
  additionKonto: number;
  buchungsKZ: string;
  rechnungskennzeichen: string;
  steuersatz: string;
};

export type UstKategorieEntryListCreateFormValues = UstKategorieEntryListFormValues & {
  umsatzsteuerkennzeichenId: string;
};

export type UstKategorieEntryListUpdateFormValues = UstKategorieEntryListFormValues & {
  ustKategorieEntryId: string;
};

const mapUstKategorieEntryListToCreateFormValues = (ustKategorieInitialEntryList: UstKategorieEntry[]): UstKategorieEntryListCreateFormValues[] =>
  ustKategorieInitialEntryList.map((entry) => ({
    additionKonto: entry.additionKonto,
    buchungsKZ: entry.buchungsKZ ?? '',
    rechnungskennzeichen: entry.rechnungskennzeichen,
    steuersatz: toFormattedDecimal(entry.steuersatz),
    umsatzsteuerkennzeichenId: entry.ustKz.umsatzsteuerkennzeichenId,
  }));

const mapUstKategorieEntryListToUpdateFormValues = (ustKategorieEntryList: UstKategorieEntry[]): UstKategorieEntryListUpdateFormValues[] =>
  ustKategorieEntryList.map((entry) => ({
    additionKonto: entry.additionKonto,
    buchungsKZ: entry.buchungsKZ ?? '',
    rechnungskennzeichen: entry.rechnungskennzeichen,
    steuersatz: toFormattedDecimal(entry.steuersatz),
    ustKategorieEntryId: entry.ustKategorieEntryId ?? '',
  }));

/****** UstKategorieVersion ******/

type UstKategorieVersionFormValues = {
  validFrom: string;
  ustVomAufwand: number;
  vstKuerzung: boolean;
};

export const ustKategorieVersionFormFields: FormFields<UstKategorieVersionCreateFormValues> = {
  validFrom: 'validFrom',
  ustKategorieEntryList: 'ustKategorieEntryList',
  ustVomAufwand: 'ustVomAufwand',
  vstKuerzung: 'vstKuerzung',
};
export const ustKategorieVersionFormFieldsDescriptions = {
  validFrom: 'Gültig ab',
  ustVomAufwand: 'USt. vom Aufwand',
  vstKuerzung: 'VSt.-Kürzung',
};

export const ustKategorieEntryListFormFields: FormFields<UstKategorieEntryListFormValues> = {
  additionKonto: 'additionKonto',
  buchungsKZ: 'buchungsKZ',
  rechnungskennzeichen: 'rechnungskennzeichen',
  steuersatz: 'steuersatz',
};

export type UstKategorieVersionCreateFormValues = UstKategorieVersionFormValues & {
  ustKategorieEntryList: UstKategorieEntryListCreateFormValues[];
};

export type UstKategorieVersionUpdateFormValues = UstKategorieVersionFormValues & {
  ustKategorieEntryList: UstKategorieEntryListUpdateFormValues[];
};

export type TUstKategorieVersionFormValues = UstKategorieVersionCreateFormValues | UstKategorieVersionUpdateFormValues;

export const mapFormValuesToUstKategorieVersionCreate = (values: UstKategorieVersionCreateFormValues): UstKategorieVersionCreateInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  ustVomAufwand: values.ustVomAufwand,
  ustKategorieEntryList: mapUstKategorieEntryListToUstKategorieCreateVersion(values.ustKategorieEntryList),
  vstKuerzung: values.vstKuerzung,
});

export const mapFormValuesToUstKategorieVersionUpdate = (values: UstKategorieVersionUpdateFormValues): UstKategorieVersionUpdateInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  ustVomAufwand: values.ustVomAufwand,
  ustKategorieEntryList: mapUstKategorieEntryListToUstKategorieUpdateVersion(values.ustKategorieEntryList),
  vstKuerzung: values.vstKuerzung,
});

/****** map UstKategorieTimeblock to UstKategorieVersion ******/

export const mapUstKategorieTimeblockToFormValues = (
  isUpdate: boolean,
  ustKategorieEntryInitialList: UstKategorieEntry[],
  timeblock: ITimeblock<UstKategorieVersion>
): TUstKategorieVersionFormValues =>
  ({
    validFrom: timeblock.validFrom,
    ustVomAufwand: timeblock.ustVomAufwand,
    ustKategorieEntryList: !isUpdate
      ? mapUstKategorieEntryListToCreateFormValues(ustKategorieEntryInitialList)
      : mapUstKategorieEntryListToUpdateFormValues(timeblock.ustKategorieEntryList),
    vstKuerzung: timeblock.vstKuerzung,
  }) as UstKategorieVersionCreateFormValues | UstKategorieVersionUpdateFormValues;

/****** UstKategorieEntryList to  ******/

const mapUstKategorieEntryListToUstKategorieCreateVersion = (
  ustKategorieEntryList: UstKategorieEntryListCreateFormValues[]
): UstKategorieVersionEntryCreateInput[] =>
  ustKategorieEntryList.map((entry) => ({
    additionKonto: entry.additionKonto,
    buchungsKZ: entry.buchungsKZ,
    rechnungskennzeichen: entry.rechnungskennzeichen,
    steuersatz: parseFormattedDecimal(entry.steuersatz),
    umsatzsteuerkennzeichenId: entry.umsatzsteuerkennzeichenId,
  }));

const mapUstKategorieEntryListToUstKategorieUpdateVersion = (
  ustKategorieEntryList: UstKategorieEntryListUpdateFormValues[]
): UstKategorieVersionEntryUpdateInput[] =>
  ustKategorieEntryList.map((entry) => ({
    additionKonto: entry.additionKonto,
    buchungsKZ: entry.buchungsKZ,
    rechnungskennzeichen: entry.rechnungskennzeichen,
    steuersatz: parseFormattedDecimal(entry.steuersatz),
    ustKategorieEntryId: entry.ustKategorieEntryId,
  }));
