import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { StyledEmailDeliverySpace } from '../Styled/Space.style';
import { MailData } from '../../../types';

type Props = {
  emailDelivery: MailData;
  style?: React.CSSProperties;
};

const EmailSubjectBody: FC<Props> = ({ emailDelivery: { subject, textPlain }, style }) => {
  return (
    <Space direction="vertical" style={style}>
      <StyledEmailDeliverySpace>
        <Typography.Text>E-Mail Betreff</Typography.Text>
        <Typography.Text>{subject}</Typography.Text>
      </StyledEmailDeliverySpace>

      <StyledEmailDeliverySpace align="start">
        <Typography.Text>Text Körper</Typography.Text>
        <Typography.Text style={{ whiteSpace: 'pre-line' }}>{textPlain}</Typography.Text>
      </StyledEmailDeliverySpace>
    </Space>
  );
};
export default EmailSubjectBody;
