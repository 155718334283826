import React, { FC, useEffect, useState } from 'react';
import { Space } from 'antd';
import { SelectProps } from 'formik-antd';
import * as Yup from 'yup';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { TicketParticipantFormValues } from '../ticketFormMapper';
import { StyledTag } from './Styled/Tag.style';

type Props = {
  selectValue: TicketParticipantFormValues[];
} & Parameters<NonNullable<SelectProps['tagRender']>>[0];

const emailValidationSchema = Yup.string().email('E-Mail-Adresse muss gültig sein');

const ParticipantSelectTag: FC<Props> = ({ label, value, closable, onClose, selectValue }) => {
  const [isValidEmail, setIsValidEmail] = useState<boolean | undefined>(undefined);
  const isMaybeEmail = selectValue.some((item) => item.emailAddress === value);

  useEffect(() => {
    try {
      emailValidationSchema.validateSync(value);
      setIsValidEmail(true);
    } catch {
      setIsValidEmail(false);
    }
  }, [value]);

  const color = isMaybeEmail && isValidEmail === false ? 'red' : undefined;

  let icon = null;
  if (!isMaybeEmail) {
    icon = <UserOutlined />;
  } else if (isValidEmail) {
    icon = <MailOutlined />;
  }

  return (
    <StyledTag closable={closable} onClose={onClose} style={{ marginRight: 3 }} key={value} color={color}>
      <Space size={2} align="baseline">
        {icon} {label}
      </Space>
    </StyledTag>
  );
};

export default ParticipantSelectTag;
