import React, { FC } from 'react';
import { Typography } from 'antd';
import { verarbeitungPageAndMenuListTitles } from '../../verarbeitungHelpers';
import { Spacer } from '../../../../components/Grid';
import VerarbeitungErrorAndWarningTable from './VerarbeitungErrorAndWarningTable';
import NoErrorsTabContent from '../../../../components/Error/NoErrorsTabContent';
import { ErrorData, WarningData } from '../../../../types';
import { isArrayNotEmpty } from '../../../../helpers/arrayHelper';

type Props = {
  errorList: ErrorData[];
  warningList: WarningData[];
};

const VerarbeitungErrorAndWarningListing: FC<Props> = ({ errorList, warningList = [] }) => {
  const ErrorsAndWarningsContent = () => (
    <>
      {isArrayNotEmpty(errorList) && <VerarbeitungErrorAndWarningTable errorOrWarningList={errorList} type="error" />}
      {isArrayNotEmpty(errorList) && isArrayNotEmpty(warningList) && <Spacer />}
      {isArrayNotEmpty(warningList) && <VerarbeitungErrorAndWarningTable errorOrWarningList={warningList} type="warning" />}
    </>
  );

  return (
    <>
      <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.ErrorsAndWarnings}</Typography.Title>
      <Spacer />
      {isArrayNotEmpty(errorList) || isArrayNotEmpty(warningList) ? <ErrorsAndWarningsContent /> : <NoErrorsTabContent />}
    </>
  );
};

export default VerarbeitungErrorAndWarningListing;
