import React, { FC } from 'react';
import { Drawer } from 'antd';
import BankDetailsForm from '../Form/BankDetailsForm';
import { showSuccessMsgCreate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { gqlOperations } from '../../../graphql/gqlNamedOperations-generated';
import { FDAndRechtstrBankDetailsInput } from '../Form/bankDetailsFormMapper';
import { useCreateFirmendatenBankDetailsMutation, useCreateRechtstraegerBankDetailsMutation } from '../gql/BankDetailsMutations.types';

type Props = {
  rechtstraegerId?: string;
  firmendatenId?: string;
  isDrawerOpen: boolean;
  toggleDrawerOpen: () => void;
  onActionSuccess: () => void;
  onSetFieldValue?: (id: string, allowObjektZuweisung?: boolean) => void;
  fibuKontoCreatable?: boolean;
};

const BankDetailsCreateDrawer: FC<Props> = ({
  firmendatenId,
  rechtstraegerId,
  isDrawerOpen,
  toggleDrawerOpen,
  onActionSuccess,
  onSetFieldValue,
  fibuKontoCreatable,
}) => {
  const [runCreateForRechtstraeger, { loading: loadingFormForRechtstraeger }] = useCreateRechtstraegerBankDetailsMutation({
    onCompleted: (data) => {
      showSuccessMsgCreate('Bankverbindung');
      const bankDetailsId = data.createRechtstraegerBankDetails.data?.bankDetailsId;
      bankDetailsId && onSetFieldValue?.(bankDetailsId, true);
      toggleDrawerOpen();
      onActionSuccess();
    },
    refetchQueries: () => [gqlOperations.Query.RechnungsAussteller],
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreateForFirmendaten, { loading: loadingFormForFirmendaten }] = useCreateFirmendatenBankDetailsMutation({
    onCompleted: (data) => {
      showSuccessMsgCreate('Bankverbindung');
      const bankDetailsData = data.createFirmendatenBankDetails.data;
      const bankDetailsId = bankDetailsData?.bankDetailsId;
      bankDetailsId && onSetFieldValue?.(bankDetailsId, bankDetailsData.allowObjektZuweisung ?? undefined);
      toggleDrawerOpen();
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSubmit = (request: FDAndRechtstrBankDetailsInput) => {
    if (rechtstraegerId) {
      runCreateForRechtstraeger({ variables: { input: request, rechtstraegerId } });
    } else if (firmendatenId) {
      runCreateForFirmendaten({ variables: { input: request, firmendatenId } });
    }
  };

  /* FIELD HELP */
  const fieldHelpSelectorEntityName = rechtstraegerId ? 'BankDetails' : 'FirmendatenBankDetails';

  return (
    <Drawer title="Bankverbindung anlegen" width={720} onClose={toggleDrawerOpen} open={isDrawerOpen} destroyOnClose>
      <BankDetailsForm
        firmendatenId={firmendatenId}
        isUpdate={false}
        loading={loadingFormForRechtstraeger || loadingFormForFirmendaten}
        onSubmit={onSubmit}
        onCancel={toggleDrawerOpen}
        fieldHelpSelectorEntityName={fieldHelpSelectorEntityName}
        fibuKontoCreatable={fibuKontoCreatable}
      />
    </Drawer>
  );
};

export default BankDetailsCreateDrawer;
