import { FC } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { FormikProps } from 'formik';
import { mapFormValuesToQueryVariables, TicketListingFiltersFormValues } from './ticketListingFormMapper';
import TicketCalendar from '../../TicketCalender/TicketCalendar';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import { TTicketQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { TicketListingFilterProps } from './TicketListing';

type Props = {
  formikProps: FormikProps<TicketListingFiltersFormValues>;
} & TicketListingFilterProps;

const TicketListingCalendar: FC<Props> = ({ formikProps, ...restProps }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = { ...useQueryParams<TTicketQueryParams>(), ...mapFormValuesToQueryVariables(formikProps.values) };
  const paginationParams = usePaginationQueryParams();

  const { dueDateFrom, dueDateTo, ...restQueryParams } = queryParams;

  return (
    <TicketCalendar
      dueDate={dueDateFrom ?? dueDateTo}
      {...restQueryParams}
      {...restProps}
      onChange={(value) => {
        const from = dayjsCustom(value).startOf('month').format('YYYY-MM-DD');
        const to = dayjsCustom(value).endOf('month').format('YYYY-MM-DD');
        formikProps.setFieldValue('dueDateFrom', from);
        formikProps.setFieldValue('dueDateTo', to);
        const filters = mapFormValuesToQueryVariables({ ...formikProps.values, ...{ dueDateFrom: from }, dueDateTo: to }) as TTicketQueryParams;
        updateQueryParams(location.pathname, navigate, filters, paginationParams);
      }}
    />
  );
};

export default TicketListingCalendar;
