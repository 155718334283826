import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useBezugsbasisListQuery } from './gql/BezugsbasisQueries.types';
import { BezugsbasisTuple } from '../../../types';

const BezugsbasisSelect: FC<SelectProps> = ({ name, ...props }) => {
  const { loading, data } = useBezugsbasisListQuery();

  const bezugsbasisList = data?.getBezugsbasisList.data ?? [];

  return (
    <Select id={name} name={name} loading={loading} placeholder="Bezugsbasis auswählen" {...props}>
      {bezugsbasisList.map((bezugsbasis: BezugsbasisTuple) => (
        <Select.Option key={bezugsbasis.value} value={bezugsbasis.value}>
          {bezugsbasis.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BezugsbasisSelect;
