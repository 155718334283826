import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { ReceivedBankTransactionVerbuchenGenerierlauf } from '../../../types';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { useReceivedBankTransactionVerbuchenGenerierlaufQuery } from '../../../features/Verarbeitung/gql/ReceivedBankTransactionVerbuchenGenerierlauf/ReceivedBankTransactionVerbuchenGenerierlaufQueries.types';
import menuListReceivedBankTransactionVerbuchen from './menuListReceivedBankTransactionVerbuchen';
import { VerarbeitungReceivedBankTransactionVerbuchenDetailsPageRouteParams } from './routes';

const ReceivedBankTransactionVerbuchenDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungReceivedBankTransactionVerbuchenDetailsPageRouteParams;

  const { data } = useReceivedBankTransactionVerbuchenGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getReceivedBankTransactionVerbuchenGenerierlauf.data;

  return (
    <DetailsPageTemplate<ReceivedBankTransactionVerbuchenGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.ReceivedBankTransactionVerbuchen}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard generierlauf={generierlauf} cardTitle={verarbeitungDetailsCardTitles.ReceivedBankTransactionVerbuchen} />
      )}
      sidebarMenuList={(generierlauf) => menuListReceivedBankTransactionVerbuchen(generierlauf)}
    />
  );
};

export default ReceivedBankTransactionVerbuchenDetailsPage;
