import React, { FC } from 'react';
import { Button, Space, Tooltip } from 'antd';
import { LinkNone2Icon } from '@radix-ui/react-icons';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';
import { generatePathToRechtstraegerDetailsPage } from '../../../Rechtstraeger/rechtstraegerHelper';
import subTicketTooltipContent from '../Tooltip/subTicketTooltipContent';
import { RechtstraegerBase, Ticket } from '../../../../types';
import { GroupedParticipant } from './ticketParticipantHelpers';
import { isTicketArchived } from '../../ticketHelpers';
import tooltipFooterContent from '../Tooltip/tooltipFooterContent';
import emailTooltipContent from '../Tooltip/emailTooltipContent';
type Props = {
  ticket: Ticket;
  participant: GroupedParticipant;
  handleUnassignParticipant: (participantIds: string[], rechtstraeger: RechtstraegerBase) => void;
  loadingAssign: boolean;
};

export const ParticipantWithRechtstraeger: FC<Props> = ({ ticket, participant, handleUnassignParticipant, loadingAssign }) => {
  const { rechtstraeger, contact } = participant;
  if (!rechtstraeger || !contact) return null;

  const subTicketList = ticket.subTicketList;
  const ticketIds = participant.ticketParticipants.map((ticketParticipant) => ticketParticipant.ticketId);
  const participantIds = participant.ticketParticipants.map((ticketParticipant) => ticketParticipant.participant.participantId);

  const ownParticipants = participant.ticketParticipants.filter(
    (ticketParticipant) => ticketParticipant.ticketId === ticket.ticketId && !ticketParticipant.email
  );
  const ownEmailParticipants = participant.ticketParticipants.filter(
    (ticketParticipant) => ticketParticipant.ticketId === ticket.ticketId && ticketParticipant.email
  );

  let toolTipContent;
  if (ownParticipants.length) {
    toolTipContent = null;
  } else if (ownEmailParticipants.length) {
    toolTipContent = ownEmailParticipants.map((ticketParticipant) => emailTooltipContent(ticketParticipant.email));
  } else {
    toolTipContent = participant.ticketParticipants
      // Filter out multiple entries for the same ticket
      .filter((ticketParticipant, index, arr) => index === arr.findIndex((other) => other.ticketId === ticketParticipant.ticketId))
      .map((ticketParticipant) => subTicketTooltipContent(subTicketList, ticketParticipant.ticketId));
  }

  return (
    <Space style={{ justifyContent: 'space-between', width: '100%' }}>
      <LinkWithStatus
        to={generatePathToRechtstraegerDetailsPage(rechtstraeger.rechtstraegerId)}
        openInNewTab
        status={rechtstraeger.status}
        text={rechtstraeger.kurzBezeichnung}
        showStatusDescription
        maxTextLength={20}
        tooltipFooter={tooltipFooterContent([contact.wert, ...(toolTipContent ?? [])])}
      />
      {isUnassignEnabled(ticketIds, ticket.ticketId) && (
        <Tooltip title="Zuordnung aufheben">
          <Button
            style={{ height: 20 }}
            icon={<LinkNone2Icon />}
            onClick={() => handleUnassignParticipant(participantIds as string[], rechtstraeger)}
            disabled={isTicketArchived(ticket) || loadingAssign}
            shape="circle"
            size="small"
            type="text"
          />
        </Tooltip>
      )}
    </Space>
  );
};

const isUnassignEnabled = (ticketIds: string[], ticketId: string) => {
  return ticketIds.includes(ticketId);
};

export default ParticipantWithRechtstraeger;
