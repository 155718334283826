import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AbrechnungskreisFieldsFragmentDoc } from '../../Abrechnungskreis/gql/AbrechnungskreisFragments.types';
import { VorschreibungspositionFragmentDoc } from '../../Vorschreibungsposition/gql/VorschreibungspositionFragments.types';
export type BudgetingAbrDefVPosLinkageFieldsFragment = {
  budgetingAbrDefVPosLinkageId: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  firmendatenId?: string | null;
  kundenSystemId?: string | null;
  updatedByMitarbeiterId?: string | null;
  abrKreisVPosList: Array<{
    abrechnungskreis: {
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
      type: { text: string; value: Types.AbrechnungskreisType };
      verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    vorschreibungsposition: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      kontierungstabelle?: {
        basiskonto: number;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kontierungstabelleId: string;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
        subAdministrationExpenseAccount?: {
          bezeichnung: string;
          firmendatenId?: string | null;
          kontoId: string;
          kontoKlasse: string;
          nummer: string;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      } | null;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    };
  }>;
  abrechnungsdefinition: {
    abrechnungsdefinitionId: string;
    bezeichnung: string;
    status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
    type: { text: string; value: Types.AbrechnungsdefinitionType };
  };
  warningList: Array<{ attribute?: string | null; type: string; message: string }>;
};

export const BudgetingAbrDefVPosLinkageFieldsFragmentDoc = gql`
  fragment BudgetingAbrDefVPosLinkageFields on BudgetingAbrDefVPosLinkage {
    abrKreisVPosList {
      abrechnungskreis {
        ...AbrechnungskreisFields
      }
      vorschreibungsposition {
        ...Vorschreibungsposition
      }
    }
    abrechnungsdefinition {
      abrechnungsdefinitionId
      bezeichnung
      bezeichnung
      status {
        description
        text
        value
      }
      type {
        text
        value
      }
    }
    budgetingAbrDefVPosLinkageId
    createTs
    createdBy
    createdByMitarbeiterId
    firmendatenId
    kundenSystemId
    updatedByMitarbeiterId
    warningList {
      attribute
      type
      message
    }
  }
  ${AbrechnungskreisFieldsFragmentDoc}
  ${VorschreibungspositionFragmentDoc}
`;
