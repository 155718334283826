import { FinancialPlanObjekt } from '../templates/financialPlan/Objekt/financialPlanObjekt-types';

const financialPlanObjektMocks: FinancialPlanObjekt = {
  type: 'FINANCIAL_PLAN_OBJEKT',
  title: 'Vorschau 2022',
  erstellungsDatum: 'Wien, 21.07.2020',
  rechnungsAusstellerBezeichnung: 'Objekt WEG 05 Am Kanal 12, 1110 Wien',
  vorschreibungspositionList: [
    {
      bezeichnung: 'Betriebskosten',
      einleitungsText: '{Platzhalter Einleitungstext}',
      aufwandSumExpectedCostBlock: {
        sumAufwand: {
          label: 'Gesamtaufwand 2021',
          value: '19.756,00',
        },
        expectedCostIncrease: { label: 'voraussichtliche Kostensteigerung 2,85%', value: '564,00' },
      },
      expectedCostSum: { label: 'voraussichtliche Kosten 2022', value: '20.320,00' },
      aufwandskontenTable: {
        headerText: {
          bezeichnungText: 'Bezeichnung',
          aufteilungsschluesselText: 'Aufteilungsschlüssel',
          annualAmountNettoText: 'Jahresbetrag netto',
        },
        kontoList: [
          {
            bezeichnung: 'Aufzugwartung',
            aufteilungsschluessel: 'Lift',
            annualAmount: '420,00',
          },
          {
            bezeichnung: 'Schädlingsbekämpfung',
            aufteilungsschluessel: 'Nutzwert',
            annualAmount: '2.000,00',
          },
        ],
        kontoSummary: {
          annualAmount: '2.420,00',
          annualAmountText: 'Summe',
        },
      },
      currentVPosWert: { label: 'Jahrespauschale für Betriebskosten derzeit', value: '20.000,16' },
      newVPosWert: { label: 'Jahrespauschale für Betriebskosten ab Jänner 2022', value: '20.319,96' },
      schlussText: '{Platzhalter Schlusstext}',
    },
    {
      bezeichnung: 'Reparaturfond',
      einleitungsText: '{Platzhalter Einleitungstext}',
      repFondsExpensesTable: {
        headerText: {
          nameTitle: 'Mittelfristig durchzuführende Arbeiten',
          amountTitle: 'Voraussichtliche Kosten(in € exkl. USt)',
        },
        expenseList: [
          {
            name: 'Sanierung Gehweg',
            amount: '32.000,00',
          },
          {
            name: 'Neugestaltung Kinderspielplatz',
            amount: '16.000,00',
          },
        ],
        expensesSummary: {
          name: 'Gesamtkosten',
          amount: '48.000,00',
        },
      },
      expectedCostSum: { label: 'voraussichtliche Kosten 2022', value: '20.320,00' },
      aufwandskontenTable: {
        headerText: {
          bezeichnungText: 'Bezeichnung',
          aufteilungsschluesselText: 'Aufteilungsschlüssel',
          annualAmountNettoText: 'Jahresbetrag netto',
        },
        kontoList: [
          {
            bezeichnung: 'Bankspesen RF',
            aufteilungsschluessel: 'Nutzwert',
            annualAmount: '45,36',
          },
        ],
        kontoSummary: {
          annualAmount: '45,36',
          annualAmountText: 'Summe',
        },
      },
      currentVPosWert: { label: 'Jahrespauschale für Reparaturfond derzeit', value: '1.500,00' },
      newVPosWert: { label: 'Jahrespauschale für Reparaturfond ab Jänner 2022', value: '1.500,00' },
      repFondsExtra: { label: 'Stand Kto. Rep. Rücklage per 31.12.2021', value: '40.000,00' },
      schlussText: '{Platzhalter Schlusstext}',
    },
  ],
};

export default financialPlanObjektMocks;
