import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { FibuRechtstraegerFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { FibuObjektFragmentDoc } from '../../Objekt/gql/ObjektFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FibuBuchungsanweisungListQueryVariables = Types.Exact<{
  belegDatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegDatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegSymbolList?: Types.InputMaybe<Array<Types.FibuBelegSymbol> | Types.FibuBelegSymbol>;
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bestandseinheitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  buchungsTypeList?: Types.InputMaybe<Array<Types.FibuBuchungType> | Types.FibuBuchungType>;
  buchungsdatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fachlichePruefung?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  heVertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  status?: Types.InputMaybe<Types.FibuBuchungsanweisungStatus>;
  sumBruttoFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  sumBruttoTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  sumSteuerFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  sumSteuerTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  vertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragspartnerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  zuStornierendeBuchungId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  order?: Types.InputMaybe<Types.FibuBuchungsanweisungOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type FibuBuchungsanweisungListQuery = {
  getFibuBuchungsanweisungList: {
    data: {
      contentList: Array<{
        buchungId?: string | null;
        buchungsanweisungId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId: string;
        fachlichePruefung: boolean;
        sumBrutto: number;
        sumSteuer: number;
        updatedByMitarbeiterId: string;
        buchungsKopf: {
          belegDatum?: string | null;
          belegFileId?: string | null;
          belegnummer?: string | null;
          buchungsdatum: string;
          dueDate?: string | null;
          belegSymbol: { abbreviation: string; text: string; value: Types.FibuBelegSymbol };
          buchungsType: { text: string; value: Types.FibuBuchungType };
          vertragspartner?: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          } | null;
          waehrung: { text: string; value: Types.Waehrung };
        };
        buchungskreis: {
          buchungskreisId: string;
          rechtstraeger: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
        };
        objekt?: { kurzBezeichnung: string; objektId: string } | null;
        status: { text: string; value: Types.FibuBuchungsanweisungStatus };
        zuStornierendeBuchung: {
          bezeichnung: string;
          bookingSuggestionId?: string | null;
          buchungId: string;
          buchungsanweisungId: string;
          zahlungId?: string | null;
        };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBuchungsanweisungBelegnummerListQueryVariables = Types.Exact<{
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterFibuBuchungsanweisungBelegnummerListQuery = {
  getFilterFibuBuchungsanweisungBelegnummerList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBuchungsanweisungBestandeinheitListQueryVariables = Types.Exact<{
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  bezeichnung?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterFibuBuchungsanweisungBestandeinheitListQuery = {
  getFilterFibuBuchungsanweisungBestandeinheitList: {
    data: Array<{ bestandseinheitId: string; bezeichnung: string; objektKurzBezeichnung?: string | null }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQueryVariables = Types.Exact<{
  kurzBezeichnung?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery = {
  getFilterFibuBuchungsanweisungBuchungskreisRechtstraegerList: {
    data: Array<{
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBuchungsanweisungObjektListQueryVariables = Types.Exact<{
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  kurzBezeichnung?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterFibuBuchungsanweisungObjektListQuery = {
  getFilterFibuBuchungsanweisungObjektList: {
    data: Array<{ kurzBezeichnung: string; objektId: string }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBuchungsanweisungVertragspartnerListQueryVariables = Types.Exact<{
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  kurzBezeichnung?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterFibuBuchungsanweisungVertragspartnerListQuery = {
  getFilterFibuBuchungsanweisungVertragspartnerList: {
    data: Array<{
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBuchungsanweisungZuStornierendeBuchungListQueryVariables = Types.Exact<{
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  bezeichnung?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterFibuBuchungsanweisungZuStornierendeBuchungListQuery = {
  getFilterFibuBuchungsanweisungZuStornierendeBuchungList: {
    data: Array<{
      bezeichnung: string;
      bookingSuggestionId?: string | null;
      buchungId: string;
      buchungsanweisungId: string;
      zahlungId?: string | null;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const FibuBuchungsanweisungListDocument = gql`
  query FibuBuchungsanweisungList(
    $belegDatumFrom: String
    $belegDatumTo: String
    $belegSymbolList: [FibuBelegSymbol!]
    $belegnummer: String
    $bestandseinheitId: ID
    $buchungsTypeList: [FibuBuchungType!]
    $buchungsdatumFrom: String
    $buchungsdatumTo: String
    $createByMitarbeiterIdList: [String!]
    $createTsFrom: String
    $createTsTo: String
    $dueDateFrom: String
    $dueDateTo: String
    $fachlichePruefung: Boolean
    $heVertragId: ID
    $objektId: ID
    $rechtstraegerIdList: [String!]
    $status: FibuBuchungsanweisungStatus
    $sumBruttoFrom: Float
    $sumBruttoTo: Float
    $sumSteuerFrom: Float
    $sumSteuerTo: Float
    $vertragId: ID
    $vertragspartnerIdList: [String!]
    $zuStornierendeBuchungId: ID
    $order: FibuBuchungsanweisungOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getFibuBuchungsanweisungList(
      belegDatumFrom: $belegDatumFrom
      belegDatumTo: $belegDatumTo
      belegSymbolList: $belegSymbolList
      belegnummer: $belegnummer
      bestandseinheitId: $bestandseinheitId
      buchungsTypeList: $buchungsTypeList
      buchungsdatumFrom: $buchungsdatumFrom
      buchungsdatumTo: $buchungsdatumTo
      createByMitarbeiterIdList: $createByMitarbeiterIdList
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      dueDateFrom: $dueDateFrom
      dueDateTo: $dueDateTo
      fachlichePruefung: $fachlichePruefung
      heVertragId: $heVertragId
      objektId: $objektId
      rechtstraegerIdList: $rechtstraegerIdList
      status: $status
      sumBruttoFrom: $sumBruttoFrom
      sumBruttoTo: $sumBruttoTo
      sumSteuerFrom: $sumSteuerFrom
      sumSteuerTo: $sumSteuerTo
      vertragId: $vertragId
      vertragspartnerIdList: $vertragspartnerIdList
      zuStornierendeBuchungId: $zuStornierendeBuchungId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          buchungId
          buchungsKopf {
            belegDatum
            belegFileId
            belegSymbol {
              abbreviation
              text
              value
            }
            belegnummer
            buchungsType {
              text
              value
            }
            buchungsdatum
            dueDate
            vertragspartner {
              ...FibuRechtstraeger
            }
            waehrung {
              text
              value
            }
          }
          buchungsanweisungId
          buchungskreis {
            buchungskreisId
            rechtstraeger {
              ...FibuRechtstraeger
            }
          }
          createTs
          createdBy
          createdByMitarbeiterId
          fachlichePruefung
          objekt {
            kurzBezeichnung
            objektId
          }
          status {
            text
            value
          }
          sumBrutto
          sumSteuer
          updatedByMitarbeiterId
          zuStornierendeBuchung {
            bezeichnung
            bookingSuggestionId
            buchungId
            buchungsanweisungId
            zahlungId
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${FibuRechtstraegerFragmentDoc}
`;
export function useFibuBuchungsanweisungListQuery(
  baseOptions?: Apollo.QueryHookOptions<FibuBuchungsanweisungListQuery, FibuBuchungsanweisungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FibuBuchungsanweisungListQuery, FibuBuchungsanweisungListQueryVariables>(FibuBuchungsanweisungListDocument, options);
}
export function useFibuBuchungsanweisungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FibuBuchungsanweisungListQuery, FibuBuchungsanweisungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FibuBuchungsanweisungListQuery, FibuBuchungsanweisungListQueryVariables>(FibuBuchungsanweisungListDocument, options);
}
export function useFibuBuchungsanweisungListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FibuBuchungsanweisungListQuery, FibuBuchungsanweisungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FibuBuchungsanweisungListQuery, FibuBuchungsanweisungListQueryVariables>(FibuBuchungsanweisungListDocument, options);
}
export type FibuBuchungsanweisungListQueryHookResult = ReturnType<typeof useFibuBuchungsanweisungListQuery>;
export type FibuBuchungsanweisungListLazyQueryHookResult = ReturnType<typeof useFibuBuchungsanweisungListLazyQuery>;
export type FibuBuchungsanweisungListSuspenseQueryHookResult = ReturnType<typeof useFibuBuchungsanweisungListSuspenseQuery>;
export type FibuBuchungsanweisungListQueryResult = Apollo.QueryResult<FibuBuchungsanweisungListQuery, FibuBuchungsanweisungListQueryVariables>;
export const FilterFibuBuchungsanweisungBelegnummerListDocument = gql`
  query FilterFibuBuchungsanweisungBelegnummerList($rechtstraegerIdList: [String!], $belegnummer: String) {
    getFilterFibuBuchungsanweisungBelegnummerList(rechtstraegerIdList: $rechtstraegerIdList, belegnummer: $belegnummer) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterFibuBuchungsanweisungBelegnummerListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterFibuBuchungsanweisungBelegnummerListQuery, FilterFibuBuchungsanweisungBelegnummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterFibuBuchungsanweisungBelegnummerListQuery, FilterFibuBuchungsanweisungBelegnummerListQueryVariables>(
    FilterFibuBuchungsanweisungBelegnummerListDocument,
    options
  );
}
export function useFilterFibuBuchungsanweisungBelegnummerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterFibuBuchungsanweisungBelegnummerListQuery, FilterFibuBuchungsanweisungBelegnummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterFibuBuchungsanweisungBelegnummerListQuery, FilterFibuBuchungsanweisungBelegnummerListQueryVariables>(
    FilterFibuBuchungsanweisungBelegnummerListDocument,
    options
  );
}
export function useFilterFibuBuchungsanweisungBelegnummerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterFibuBuchungsanweisungBelegnummerListQuery, FilterFibuBuchungsanweisungBelegnummerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterFibuBuchungsanweisungBelegnummerListQuery, FilterFibuBuchungsanweisungBelegnummerListQueryVariables>(
    FilterFibuBuchungsanweisungBelegnummerListDocument,
    options
  );
}
export type FilterFibuBuchungsanweisungBelegnummerListQueryHookResult = ReturnType<typeof useFilterFibuBuchungsanweisungBelegnummerListQuery>;
export type FilterFibuBuchungsanweisungBelegnummerListLazyQueryHookResult = ReturnType<typeof useFilterFibuBuchungsanweisungBelegnummerListLazyQuery>;
export type FilterFibuBuchungsanweisungBelegnummerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterFibuBuchungsanweisungBelegnummerListSuspenseQuery
>;
export type FilterFibuBuchungsanweisungBelegnummerListQueryResult = Apollo.QueryResult<
  FilterFibuBuchungsanweisungBelegnummerListQuery,
  FilterFibuBuchungsanweisungBelegnummerListQueryVariables
>;
export const FilterFibuBuchungsanweisungBestandeinheitListDocument = gql`
  query FilterFibuBuchungsanweisungBestandeinheitList($rechtstraegerIdList: [String!], $bezeichnung: String) {
    getFilterFibuBuchungsanweisungBestandeinheitList(rechtstraegerIdList: $rechtstraegerIdList, bezeichnung: $bezeichnung) {
      data {
        bestandseinheitId
        bezeichnung
        objektKurzBezeichnung
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterFibuBuchungsanweisungBestandeinheitListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterFibuBuchungsanweisungBestandeinheitListQuery,
    FilterFibuBuchungsanweisungBestandeinheitListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterFibuBuchungsanweisungBestandeinheitListQuery, FilterFibuBuchungsanweisungBestandeinheitListQueryVariables>(
    FilterFibuBuchungsanweisungBestandeinheitListDocument,
    options
  );
}
export function useFilterFibuBuchungsanweisungBestandeinheitListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterFibuBuchungsanweisungBestandeinheitListQuery,
    FilterFibuBuchungsanweisungBestandeinheitListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterFibuBuchungsanweisungBestandeinheitListQuery, FilterFibuBuchungsanweisungBestandeinheitListQueryVariables>(
    FilterFibuBuchungsanweisungBestandeinheitListDocument,
    options
  );
}
export function useFilterFibuBuchungsanweisungBestandeinheitListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterFibuBuchungsanweisungBestandeinheitListQuery, FilterFibuBuchungsanweisungBestandeinheitListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterFibuBuchungsanweisungBestandeinheitListQuery, FilterFibuBuchungsanweisungBestandeinheitListQueryVariables>(
    FilterFibuBuchungsanweisungBestandeinheitListDocument,
    options
  );
}
export type FilterFibuBuchungsanweisungBestandeinheitListQueryHookResult = ReturnType<typeof useFilterFibuBuchungsanweisungBestandeinheitListQuery>;
export type FilterFibuBuchungsanweisungBestandeinheitListLazyQueryHookResult = ReturnType<
  typeof useFilterFibuBuchungsanweisungBestandeinheitListLazyQuery
>;
export type FilterFibuBuchungsanweisungBestandeinheitListSuspenseQueryHookResult = ReturnType<
  typeof useFilterFibuBuchungsanweisungBestandeinheitListSuspenseQuery
>;
export type FilterFibuBuchungsanweisungBestandeinheitListQueryResult = Apollo.QueryResult<
  FilterFibuBuchungsanweisungBestandeinheitListQuery,
  FilterFibuBuchungsanweisungBestandeinheitListQueryVariables
>;
export const FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListDocument = gql`
  query FilterFibuBuchungsanweisungBuchungskreisRechtstraegerList($kurzBezeichnung: String) {
    getFilterFibuBuchungsanweisungBuchungskreisRechtstraegerList(kurzBezeichnung: $kurzBezeichnung) {
      data {
        ...FibuRechtstraeger
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${FibuRechtstraegerFragmentDoc}
`;
export function useFilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery,
    FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery,
    FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQueryVariables
  >(FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListDocument, options);
}
export function useFilterFibuBuchungsanweisungBuchungskreisRechtstraegerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery,
    FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery,
    FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQueryVariables
  >(FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListDocument, options);
}
export function useFilterFibuBuchungsanweisungBuchungskreisRechtstraegerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery,
        FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery,
    FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQueryVariables
  >(FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListDocument, options);
}
export type FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQueryHookResult = ReturnType<
  typeof useFilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery
>;
export type FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListLazyQueryHookResult = ReturnType<
  typeof useFilterFibuBuchungsanweisungBuchungskreisRechtstraegerListLazyQuery
>;
export type FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterFibuBuchungsanweisungBuchungskreisRechtstraegerListSuspenseQuery
>;
export type FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQueryResult = Apollo.QueryResult<
  FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQuery,
  FilterFibuBuchungsanweisungBuchungskreisRechtstraegerListQueryVariables
>;
export const FilterFibuBuchungsanweisungObjektListDocument = gql`
  query FilterFibuBuchungsanweisungObjektList($rechtstraegerIdList: [String!], $kurzBezeichnung: String) {
    getFilterFibuBuchungsanweisungObjektList(rechtstraegerIdList: $rechtstraegerIdList, kurzBezeichnung: $kurzBezeichnung) {
      data {
        ...FibuObjekt
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${FibuObjektFragmentDoc}
`;
export function useFilterFibuBuchungsanweisungObjektListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterFibuBuchungsanweisungObjektListQuery, FilterFibuBuchungsanweisungObjektListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterFibuBuchungsanweisungObjektListQuery, FilterFibuBuchungsanweisungObjektListQueryVariables>(
    FilterFibuBuchungsanweisungObjektListDocument,
    options
  );
}
export function useFilterFibuBuchungsanweisungObjektListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterFibuBuchungsanweisungObjektListQuery, FilterFibuBuchungsanweisungObjektListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterFibuBuchungsanweisungObjektListQuery, FilterFibuBuchungsanweisungObjektListQueryVariables>(
    FilterFibuBuchungsanweisungObjektListDocument,
    options
  );
}
export function useFilterFibuBuchungsanweisungObjektListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterFibuBuchungsanweisungObjektListQuery, FilterFibuBuchungsanweisungObjektListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterFibuBuchungsanweisungObjektListQuery, FilterFibuBuchungsanweisungObjektListQueryVariables>(
    FilterFibuBuchungsanweisungObjektListDocument,
    options
  );
}
export type FilterFibuBuchungsanweisungObjektListQueryHookResult = ReturnType<typeof useFilterFibuBuchungsanweisungObjektListQuery>;
export type FilterFibuBuchungsanweisungObjektListLazyQueryHookResult = ReturnType<typeof useFilterFibuBuchungsanweisungObjektListLazyQuery>;
export type FilterFibuBuchungsanweisungObjektListSuspenseQueryHookResult = ReturnType<typeof useFilterFibuBuchungsanweisungObjektListSuspenseQuery>;
export type FilterFibuBuchungsanweisungObjektListQueryResult = Apollo.QueryResult<
  FilterFibuBuchungsanweisungObjektListQuery,
  FilterFibuBuchungsanweisungObjektListQueryVariables
>;
export const FilterFibuBuchungsanweisungVertragspartnerListDocument = gql`
  query FilterFibuBuchungsanweisungVertragspartnerList($rechtstraegerIdList: [String!], $kurzBezeichnung: String) {
    getFilterFibuBuchungsanweisungVertragspartnerList(rechtstraegerIdList: $rechtstraegerIdList, kurzBezeichnung: $kurzBezeichnung) {
      data {
        ...FibuRechtstraeger
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${FibuRechtstraegerFragmentDoc}
`;
export function useFilterFibuBuchungsanweisungVertragspartnerListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterFibuBuchungsanweisungVertragspartnerListQuery,
    FilterFibuBuchungsanweisungVertragspartnerListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterFibuBuchungsanweisungVertragspartnerListQuery, FilterFibuBuchungsanweisungVertragspartnerListQueryVariables>(
    FilterFibuBuchungsanweisungVertragspartnerListDocument,
    options
  );
}
export function useFilterFibuBuchungsanweisungVertragspartnerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterFibuBuchungsanweisungVertragspartnerListQuery,
    FilterFibuBuchungsanweisungVertragspartnerListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterFibuBuchungsanweisungVertragspartnerListQuery, FilterFibuBuchungsanweisungVertragspartnerListQueryVariables>(
    FilterFibuBuchungsanweisungVertragspartnerListDocument,
    options
  );
}
export function useFilterFibuBuchungsanweisungVertragspartnerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FilterFibuBuchungsanweisungVertragspartnerListQuery,
        FilterFibuBuchungsanweisungVertragspartnerListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterFibuBuchungsanweisungVertragspartnerListQuery, FilterFibuBuchungsanweisungVertragspartnerListQueryVariables>(
    FilterFibuBuchungsanweisungVertragspartnerListDocument,
    options
  );
}
export type FilterFibuBuchungsanweisungVertragspartnerListQueryHookResult = ReturnType<typeof useFilterFibuBuchungsanweisungVertragspartnerListQuery>;
export type FilterFibuBuchungsanweisungVertragspartnerListLazyQueryHookResult = ReturnType<
  typeof useFilterFibuBuchungsanweisungVertragspartnerListLazyQuery
>;
export type FilterFibuBuchungsanweisungVertragspartnerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterFibuBuchungsanweisungVertragspartnerListSuspenseQuery
>;
export type FilterFibuBuchungsanweisungVertragspartnerListQueryResult = Apollo.QueryResult<
  FilterFibuBuchungsanweisungVertragspartnerListQuery,
  FilterFibuBuchungsanweisungVertragspartnerListQueryVariables
>;
export const FilterFibuBuchungsanweisungZuStornierendeBuchungListDocument = gql`
  query FilterFibuBuchungsanweisungZuStornierendeBuchungList($rechtstraegerIdList: [String!], $bezeichnung: String) {
    getFilterFibuBuchungsanweisungZuStornierendeBuchungList(rechtstraegerIdList: $rechtstraegerIdList, bezeichnung: $bezeichnung) {
      data {
        bezeichnung
        bookingSuggestionId
        buchungId
        buchungsanweisungId
        zahlungId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterFibuBuchungsanweisungZuStornierendeBuchungListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterFibuBuchungsanweisungZuStornierendeBuchungListQuery,
    FilterFibuBuchungsanweisungZuStornierendeBuchungListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FilterFibuBuchungsanweisungZuStornierendeBuchungListQuery,
    FilterFibuBuchungsanweisungZuStornierendeBuchungListQueryVariables
  >(FilterFibuBuchungsanweisungZuStornierendeBuchungListDocument, options);
}
export function useFilterFibuBuchungsanweisungZuStornierendeBuchungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterFibuBuchungsanweisungZuStornierendeBuchungListQuery,
    FilterFibuBuchungsanweisungZuStornierendeBuchungListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FilterFibuBuchungsanweisungZuStornierendeBuchungListQuery,
    FilterFibuBuchungsanweisungZuStornierendeBuchungListQueryVariables
  >(FilterFibuBuchungsanweisungZuStornierendeBuchungListDocument, options);
}
export function useFilterFibuBuchungsanweisungZuStornierendeBuchungListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FilterFibuBuchungsanweisungZuStornierendeBuchungListQuery,
        FilterFibuBuchungsanweisungZuStornierendeBuchungListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FilterFibuBuchungsanweisungZuStornierendeBuchungListQuery,
    FilterFibuBuchungsanweisungZuStornierendeBuchungListQueryVariables
  >(FilterFibuBuchungsanweisungZuStornierendeBuchungListDocument, options);
}
export type FilterFibuBuchungsanweisungZuStornierendeBuchungListQueryHookResult = ReturnType<
  typeof useFilterFibuBuchungsanweisungZuStornierendeBuchungListQuery
>;
export type FilterFibuBuchungsanweisungZuStornierendeBuchungListLazyQueryHookResult = ReturnType<
  typeof useFilterFibuBuchungsanweisungZuStornierendeBuchungListLazyQuery
>;
export type FilterFibuBuchungsanweisungZuStornierendeBuchungListSuspenseQueryHookResult = ReturnType<
  typeof useFilterFibuBuchungsanweisungZuStornierendeBuchungListSuspenseQuery
>;
export type FilterFibuBuchungsanweisungZuStornierendeBuchungListQueryResult = Apollo.QueryResult<
  FilterFibuBuchungsanweisungZuStornierendeBuchungListQuery,
  FilterFibuBuchungsanweisungZuStornierendeBuchungListQueryVariables
>;
