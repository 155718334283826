import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

/* workaround because from formik 2 if you use setFieldValue or some similiar manipulate function from formik
 validation for that field will run on previous value not on current
 https://github.com/jaredpalmer/formik/issues/2083#issuecomment-577890337
 */
const FormikFieldValidate = (props: { valueToWatch: any; children: React.ReactNode }) => {
  const { valueToWatch } = props;
  const { validateForm } = useFormikContext();
  useEffect(() => {
    validateForm();
  }, [valueToWatch, validateForm]);
  return <>{props.children}</>;
};

export default FormikFieldValidate;
