import {
  IncomingInvoiceBuchungPosition,
  IncomingInvoiceBuchungPositionCreateInput,
  IncomingInvoiceSteuerzeile,
  IncomingInvoiceSteuerzeileInput,
  SingleInvoice,
  SingleInvoiceCreateInput,
  StandingInvoice,
  StandingInvoiceCreateInput,
  StandingInvoicePartialAmount,
  StandingInvoicePartialAmountCreateInput,
} from '../../../../../types';
import { isInvoiceTypeSingleInvoice } from '../../incomingInvoiceFormHelpers';
import { TInvoice } from '../../incomingInvoiceFormMapper';

export const mapIncomingInvoiceToInvoiceCreate = (invoice: TInvoice): SingleInvoiceCreateInput | StandingInvoiceCreateInput => {
  const sharedValues = {
    ausstellerRechtstraegerId: invoice.ausstellerRechtstraegerId,
    basis: invoice.basis.value,
    billingType: invoice.billingType.value,
    fileId: invoice.fileId,
    issuerBankDetailsId: invoice.issuerBankDetailsId,
    kundenNummer: invoice.kundenNummer,
    objektId: invoice.objektId,
    payeeBankDetailsId: invoice.payeeBankDetailsId,
    payeeRechtstraegerId: invoice.payeeRechtstraegerId,
    paymentMethod: invoice.paymentMethod.value,
    paymentReferenceText: invoice.paymentReferenceText,
    purposeOfUseText: invoice.purposeOfUseText,
    rechnungsDatum: invoice.rechnungsDatum,
    rechnungsNummer: invoice.rechnungsNummer,
    sepaMandatReference: invoice.sepaMandatReference,
    type: invoice.type,
  };

  if (isInvoiceTypeSingleInvoice(invoice.type)) {
    const incomingInvoice = invoice as SingleInvoice;
    return {
      ...sharedValues,
      abgrenzungsBuchung: incomingInvoice.abgrenzungsBuchung,
      abgrenzungsBuchungsdatum: incomingInvoice.abgrenzungsBuchungsdatum,
      brutto: incomingInvoice.brutto,
      buchungPositionList: incomingInvoice.buchungPositionList.map(mapBuchunungPositionToInvoiceCreate),
      buchungsdatum: incomingInvoice.buchungsdatum,
      dueDate: incomingInvoice.dueDate,
      netto: incomingInvoice.netto,
      requestedPaymentDate: incomingInvoice.requestedPaymentDate,
      steuerzeileList: incomingInvoice.steuerzeileList.map(mapSteuerzeileListToInvoiceCreate),
      type: incomingInvoice.type,
    } as SingleInvoiceCreateInput;
  } else {
    const incomingInvoice = invoice as StandingInvoice;
    return {
      ...sharedValues,
      partialAmountList: incomingInvoice.partialAmountList.map(mapPartialAmountToInvoiceCreate),
    } as StandingInvoiceCreateInput;
  }
};

const mapSteuerzeileListToInvoiceCreate = (steuerzeile: IncomingInvoiceSteuerzeile): IncomingInvoiceSteuerzeileInput => ({
  betrag: steuerzeile.betrag,
  steuersatz: steuerzeile.steuersatz,
});

const mapBuchunungPositionToInvoiceCreate = (buchungPosition: IncomingInvoiceBuchungPosition): IncomingInvoiceBuchungPositionCreateInput => ({
  betrag: buchungPosition.betrag,
  buchungstext: buchungPosition.buchungstext,
  kontoId: buchungPosition.kontoId,
  steuersatz: buchungPosition.steuersatz,
});

const mapPartialAmountToInvoiceCreate = (partialAmount: StandingInvoicePartialAmount): StandingInvoicePartialAmountCreateInput => ({
  brutto: partialAmount.brutto,
  buchungPositionList: partialAmount.buchungPositionList.map(mapBuchunungPositionToInvoiceCreate),
  buchungsdatum: partialAmount.buchungsdatum,
  dueDate: partialAmount.dueDate,
  netto: partialAmount.netto,
  requestedPaymentDate: partialAmount.requestedPaymentDate,
  steuerzeileList: partialAmount.steuerzeileList.map(mapSteuerzeileListToInvoiceCreate),
});
