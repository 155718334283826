import React, { FC } from 'react';
import { FormikProps } from 'formik';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import GenerierlaufEntryExitCodeListSelect from '../../../shared/Filters/GenerierlaufEntryExitCodeListSelect';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import VerarbeitungObjektFormSelect, { VerarbeitungObjektFormSelectProps } from '../../../shared/Filters/VerarbeitungObjektFormSelect';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import VerarbeitungRechtstraegerFormSelect, {
  VerarbeitungRechtstraegerSelectProps,
} from '../../../shared/Filters/VerarbeitungRechtstraegerFormSelect';
import BudgetingSelect, { VerarbeitungBudgetingFormSelectProps } from './BudgetingSelect';
import { topFinancialPlanVerarbeitungEntryFiltersFormFields, TopFinancialPlanVerarbeitungEntryFiltersFormValues } from './ListingFiltersFormMapper';
import { TopFinancialPlanGenerierlauf } from '../../../../../types';
import VerarbeitungBestandseinheitFormSelect, {
  VerarbeitungBestandseinheitFormSelectProps,
} from '../../../shared/Filters/VerarbeitungBestandseinheitFormSelect';

type Props = {
  generierlauf: TopFinancialPlanGenerierlauf;
  formikProps: FormikProps<TopFinancialPlanVerarbeitungEntryFiltersFormValues>;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlauf }) => {
  const rechtstraegerList = generierlauf.entryList
    .reduce((acc: VerarbeitungRechtstraegerSelectProps[]) => {
      const { rechtstraegerId, kurzBezeichnung } = generierlauf.budgeting.objekt.rechnungsAussteller;
      const exists = acc.find((item) => item.rechtstraegerId === rechtstraegerId);
      if (!exists) {
        acc.push({
          rechtstraegerId,
          kurzBezeichnung,
        });
      }
      return acc;
    }, [])
    .sort((a, b) => compareTwoStringsForSorting(a.kurzBezeichnung, b.kurzBezeichnung));

  const budgetingList = generierlauf.entryList.reduce((acc: VerarbeitungBudgetingFormSelectProps[]) => {
    const { budgetingId, bezeichnung } = generierlauf.budgeting;
    const exists = acc.find((item) => item.budgetingId === budgetingId);
    if (!exists) {
      acc.push({
        budgetingId,
        kurzBezeichnung: bezeichnung,
      });
    }
    return acc;
  }, []);

  const objektList = generierlauf.entryList.reduce((acc: VerarbeitungObjektFormSelectProps[]) => {
    const { objektId, kurzBezeichnung } = generierlauf.budgeting.objekt;
    const exists = acc.find((item) => item.objektId === objektId);
    if (!exists) {
      acc.push({
        objektId,
        kurzBezeichnung,
      });
    }
    return acc;
  }, []);

  const bestandseinheitList = generierlauf.entryList.reduce((acc: VerarbeitungBestandseinheitFormSelectProps[], entry) => {
    if (!entry.bestandseinheit) return acc;
    const { bestandseinheitId, bezeichnung } = entry.bestandseinheit;
    const exists = acc.find((item) => item.bestandseinheitId === bestandseinheitId);
    if (!exists) {
      acc.push({
        bestandseinheitId,
        bezeichnung,
      });
    }
    return acc;
  }, []);

  const vertragsPartnerList = generierlauf.entryList.reduce((acc: VerarbeitungRechtstraegerSelectProps[], entry) => {
    if (!entry.vertrag) return acc;
    const { rechtstraegerId, kurzBezeichnung } = entry.vertrag.vertragspartner;
    const exists = acc.find((item) => item.rechtstraegerId === rechtstraegerId);
    if (!exists) {
      acc.push({
        rechtstraegerId,
        kurzBezeichnung,
      });
    }
    return acc;
  }, []);

  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={topFinancialPlanVerarbeitungEntryFiltersFormFields.rechnungsAusstellerId} label="Rechnungsaussteller">
            <VerarbeitungRechtstraegerFormSelect
              name={topFinancialPlanVerarbeitungEntryFiltersFormFields.rechnungsAusstellerId}
              onChange={formikProps.submitForm}
              rechtstraegerList={rechtstraegerList}
              placeholder="Rechnungsaussteller auswählen"
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={topFinancialPlanVerarbeitungEntryFiltersFormFields.vertragspartnerId} label="Vertragspartner">
            <VerarbeitungRechtstraegerFormSelect
              name={topFinancialPlanVerarbeitungEntryFiltersFormFields.vertragspartnerId}
              onChange={formikProps.submitForm}
              rechtstraegerList={vertragsPartnerList}
              placeholder="Vertragspartner auswählen"
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={topFinancialPlanVerarbeitungEntryFiltersFormFields.budgetingId} label="Budgetierung">
            <BudgetingSelect
              name={topFinancialPlanVerarbeitungEntryFiltersFormFields.budgetingId}
              onChange={formikProps.submitForm}
              items={budgetingList}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={topFinancialPlanVerarbeitungEntryFiltersFormFields.objektId} label="Objekt">
            <VerarbeitungObjektFormSelect
              name={topFinancialPlanVerarbeitungEntryFiltersFormFields.objektId}
              id={topFinancialPlanVerarbeitungEntryFiltersFormFields.objektId}
              objektList={objektList}
              onChange={formikProps.submitForm}
              maxTextLength={35}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={topFinancialPlanVerarbeitungEntryFiltersFormFields.bestandseinheitId} label="Bestandseinheit">
            <VerarbeitungBestandseinheitFormSelect
              name={topFinancialPlanVerarbeitungEntryFiltersFormFields.bestandseinheitId}
              onChange={formikProps.submitForm}
              bestandseinheitList={bestandseinheitList}
              objektId={formikProps.values.objektId}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={topFinancialPlanVerarbeitungEntryFiltersFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListSelect
              name={topFinancialPlanVerarbeitungEntryFiltersFormFields.exitCodeList}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
