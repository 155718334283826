import { FormFields } from '../../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../../shared/InfoFeld/infoFeldFormMapper';

export interface WeiterePersonCreateFormValues extends InfoFeldFormValues {
  natuerlichePersonId: string;
  text?: string;
}

export const weiterePersonCreateFormFields: FormFields<WeiterePersonCreateFormValues> = {
  natuerlichePersonId: 'natuerlichePersonId',
  text: 'text',
  documentList: 'documentList',
};

export const weiterePersonCreateFormInitialValues: WeiterePersonCreateFormValues = {
  documentList: [],
  natuerlichePersonId: '',
};
