import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import BkOrWeObjAbrDefTemplate from '../shared/BkOrWe/BkOrWeObjAbrDefTemplate';
import { ObjAbrDefGeneralSettingsFormValues } from '../shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import { TopAbrDefGeneralSettingsFormValues } from '../shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import BkOrWeTopAbrDefTemplate from '../shared/BkOrWe/BkOrWeTopAbrDefTemplate';
import { AbrechnungsdefinitionType } from '../../../../../types';
import {
  BkOrWeAbrDefObjektOutputOptionsFieldHelpNames,
  BkOrWeAbrDefTopOutputOptionsFieldHelpNames,
} from '../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';

type Props = {
  weObjektAbrTemplateValues: TextbausteinFormValues[];
  onWeObjektAbrDefTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  weTopAbrTemplateValues: TextbausteinFormValues[];
  onWeTopAbrDefTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  objAbrDefGeneralSettingsValues: ObjAbrDefGeneralSettingsFormValues;
  onObjAbrDefGeneralSettingsChange: (values: ObjAbrDefGeneralSettingsFormValues) => void;
  topAbrDefGeneralSettingsValues: TopAbrDefGeneralSettingsFormValues;
  onTopAbrDefGeneralSettingsChange: (values: TopAbrDefGeneralSettingsFormValues) => void;
  setIsFormValid: (isFormValid: boolean) => void;
  onSubmit: () => void;
  isSubmitting?: boolean;
  onBack: () => void;
  fieldHelpNames?: {
    objekt?: BkOrWeAbrDefObjektOutputOptionsFieldHelpNames;
    top?: BkOrWeAbrDefTopOutputOptionsFieldHelpNames;
  };
};

const WeAbrDefTemplates: FC<Props> = ({
  weObjektAbrTemplateValues,
  onWeObjektAbrDefTemplateChange,
  weTopAbrTemplateValues,
  onWeTopAbrDefTemplateChange,
  objAbrDefGeneralSettingsValues,
  onObjAbrDefGeneralSettingsChange,
  topAbrDefGeneralSettingsValues,
  onTopAbrDefGeneralSettingsChange,
  setIsFormValid,
  onSubmit,
  isSubmitting,
  onBack,
  fieldHelpNames,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'WE-Objektabrechnung',
      children: (
        <BkOrWeObjAbrDefTemplate
          objektAbrTemplateValues={weObjektAbrTemplateValues}
          onObjektAbrDefTemplateChange={onWeObjektAbrDefTemplateChange}
          objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
          onObjAbrDefGeneralSettingsChange={onObjAbrDefGeneralSettingsChange}
          fieldHelpNames={fieldHelpNames?.objekt}
        />
      ),
    },
    {
      key: '2',
      label: 'WE-Topabrechnung',
      children: (
        <BkOrWeTopAbrDefTemplate
          abrDefType={AbrechnungsdefinitionType.WeAbrechnung}
          topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
          onTopAbrDefGeneralSettingsChange={onTopAbrDefGeneralSettingsChange}
          topAbrTemplateValues={weTopAbrTemplateValues}
          onTopAbrDefTemplateChange={onWeTopAbrDefTemplateChange}
          setIsFormValid={setIsFormValid}
          fieldHelpNames={fieldHelpNames?.top}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="WE-Objektabrechnung" items={items} />
      <FormButtons onCancel={onBack} cancelText="Zurück" updateMode={false} isOkLoading={isSubmitting} onOk={onSubmit} okText="Speichern" />
    </>
  );
};

export default WeAbrDefTemplates;
