import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum InfoMailDeliverySendVerarbeitungQueryParamKey {
  EMAIL_ADDRESS = 'emailAddress',
  EXIT_CODE_LIST = 'exitCodeList',
  OBJEKT_ID = 'objektId',
  RECIPIENT_ID = 'recipientId',
  VERTRAGSPARTNER_ID = 'vertragspartnerId',
}

export type InfoMailDeliverySendVerarbeitungQueryParams = QueryParams<{
  emailAddress: string;
  exitCodeList: GenerierlaufEntryExitCode[];
  objektId: string;
  recipientId: string;
  vertragspartnerId: string;
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: InfoMailDeliverySendVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => {
  navigate(
    generatePathToVerarbeitungInfoMailDeliverySendDetailsPage(
      verarbeitungType,
      generierlaufId,
      filters.vertragspartnerId,
      filters.recipientId,
      filters.emailAddress,
      filters.exitCodeList,
      filters.objektId,
      paginationParams
    )
  );
};

const generatePathToVerarbeitungInfoMailDeliverySendDetailsPage = (
  verarbeitungType: string,
  generierlaufId: string,
  vertragspartnerId?: string,
  recipientId?: string,
  emailAddress?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  objektId?: string,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [InfoMailDeliverySendVerarbeitungQueryParamKey.VERTRAGSPARTNER_ID]: vertragspartnerId,
    [InfoMailDeliverySendVerarbeitungQueryParamKey.RECIPIENT_ID]: recipientId,
    [InfoMailDeliverySendVerarbeitungQueryParamKey.EMAIL_ADDRESS]: emailAddress,
    [InfoMailDeliverySendVerarbeitungQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    [InfoMailDeliverySendVerarbeitungQueryParamKey.OBJEKT_ID]: objektId,
    ...paginationParams,
  })}`;
