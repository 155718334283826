import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../../types';

export const useOnIncomingInvoiceDataChangeEvents = (key: string, callback: () => Promise<unknown>) => {
  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.incomingInvoice,
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Eingangsrechnung wurden gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.incomingInvoice,
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Eingangsrechnung wurden gerade verändert',
      key: `${key}-update`,
      callback,
    },
  ]);
};
