import { generatePath } from 'react-router-dom';
import { URI_MANAGEMENT_PAGE_SYSTEM_FIRMENDATEN_DETAILS_PAGE } from '../../../constants/managementPageUriPaths';

export const generatePathToFirmendatenDetails = (firmendatenId: string) =>
  generatePath(URI_MANAGEMENT_PAGE_SYSTEM_FIRMENDATEN_DETAILS_PAGE, { firmendatenId });

const generatePathToFirmendatenDetailsOperatingSiteMenu = (firmendatenId: string) => `${generatePathToFirmendatenDetails(firmendatenId)}/master-data`;
const generatePathToFirmendatenDetailsOperatingSiteTab = (firmendatenId: string) =>
  `${generatePathToFirmendatenDetailsOperatingSiteMenu(firmendatenId)}/subsidiaries`;

const generatePathToFirmendatenDetailsMitarbeiterTab = (firmendatenId: string) =>
  `${generatePathToFirmendatenDetailsOperatingSiteMenu(firmendatenId)}/employees`;

const generatePathToFirmendatenDetailRechnungAusstellerTab = (firmendatenId: string) =>
  `${generatePathToFirmendatenDetails(firmendatenId)}/invoice-issuer`;

export const generatePathToFirmendatenDetailRechnungAusstellerGeneralTab = (firmendatenId: string) =>
  `${generatePathToFirmendatenDetailRechnungAusstellerTab(firmendatenId)}/general`;

export const generatePathToFirmendatenDetailRechnungAusstellerKontenTab = (firmendatenId: string) =>
  `${generatePathToFirmendatenDetailRechnungAusstellerTab(firmendatenId)}/konten`;

export const generatePathToFirmendatenDetailRechnungAusstellerBelegtexteTab = (firmendatenId: string) =>
  `${generatePathToFirmendatenDetailRechnungAusstellerTab(firmendatenId)}/beleg-texte`;

export const generatePathToFirmendatenDetailRechnungAusstellerReportLogosTab = (firmendatenId: string) =>
  `${generatePathToFirmendatenDetailRechnungAusstellerTab(firmendatenId)}/report-logos`;

export const pathsToFirmendatenDetails = (firmendatenId: string) => {
  return {
    firmendatenDetails: generatePathToFirmendatenDetails(firmendatenId),
    masterDataMenu: generatePathToFirmendatenDetailsOperatingSiteMenu(firmendatenId),
    operatingSiteTab: generatePathToFirmendatenDetailsOperatingSiteTab(firmendatenId),
    mitarbeiterTab: generatePathToFirmendatenDetailsMitarbeiterTab(firmendatenId),
    rechnungAusstellerTab: generatePathToFirmendatenDetailRechnungAusstellerTab(firmendatenId),
  };
};
