import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import BookingInstruction from '../../features/BookingInstruction/BookingInstruction';

const BookingInstructionPage = () => {
  return (
    <>
      <Helmet>
        <title>Buchungsanweisung</title>
      </Helmet>
      <PageLayout>
        <BookingInstruction />
      </PageLayout>
    </>
  );
};

export default BookingInstructionPage;
