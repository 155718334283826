import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useDateUnitListQuery } from './gql/DateUnitQueries.types';

type Props = SelectProps;

const DateUnitSelect: FC<Props> = ({ ...props }) => {
  const { data, loading } = useDateUnitListQuery();

  const unitList = data?.getDateUnitList.data || [];

  return (
    <Select loading={loading} size="small" id={props.name} placeholder="Einheit auswählen" {...props}>
      {unitList.map(({ value, text }) => {
        return (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default DateUnitSelect;
