import { FC, useEffect } from 'react';
import { FormikProps } from 'formik';
import DebouncedTextSearch from '../../../../components/Input-antd/DebouncedTextSearch';
import { FiltersFormValues } from './filtersFormMapper';

type Props = {
  name: string;
  formikProps: FormikProps<FiltersFormValues>;
};

const AbrechnungDebounceTextSearchFormInput: FC<Props> = ({ name, formikProps }) => {
  useEffect(() => {
    formikProps.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikProps.values.bezeichnung]);

  return (
    <DebouncedTextSearch
      style={{ width: '100%' }}
      prefix="Bezeichnung"
      placeholder=""
      onChange={(value) => {
        const trimmedValue = value.trim();
        formikProps.setFieldValue(name, trimmedValue);
      }}
      value={formikProps.values.bezeichnung || ''}
    />
  );
};

export default AbrechnungDebounceTextSearchFormInput;
