import { Formik } from 'formik';
import React, { FC } from 'react';
import { Form, Select } from 'formik-antd';
import { Box } from 'rebass';
import { Divider } from 'antd';
import { getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../components/Timeline/timelineCtx';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { layoutTimelineForm } from '../../../../components/Input-antd/formLayoutHelper';
import TimelineFormKommentar from '../../../../components/Timeline/Form/TimelineFormKommentar';
import TimelineFormButtons from '../../../../components/Timeline/Form/TimelineFormButtons';
import TimelineFormDatePicker from '../../../../components/Timeline/Form/TimelineFormDatePicker';
import {
  mapFormValuesToSteuerpflichtVersion,
  mapTimeblockToFormValues,
  RechtstraegerSteuerpflichtVersionFormValues,
  steuerpflichtVersionFormFields,
  steuerpflichtVersionFormValuesDescriptions,
} from './steuerpflichtVersionTimelineFormMapper';
import { RechtstraegerSteuerpflicht, Steuerpflicht } from '../../../../types';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import {
  useCreateRechtstraegerSteuerpflichtMutation,
  useUpdateRechtstraegerSteuerpflichtMutation,
} from '../gql/RechtstraegerSteuerpflichtMutations.types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type SteuerpflichtVersionFormProps = {
  timeblock: ITimeblock<RechtstraegerSteuerpflicht>;
  rechtstraegerId: string;
  onSubmitSuccess: () => void;
};

const SteuerpflichtVersionForm: FC<SteuerpflichtVersionFormProps> = ({ timeblock, rechtstraegerId, onSubmitSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'RechtstraegerSteuerpflicht'>('RechtstraegerSteuerpflicht');

  const { dataSource, toggleEdit, changeToUpdateMode } = useTimeline<RechtstraegerSteuerpflicht>();
  const initialValues = mapTimeblockToFormValues(timeblock);
  const isUpdate = !!timeblock.rechtstraegerSteuerpflichtId;

  const entity = 'Steuerpflicht';

  const [runUpdate, { loading: loadingUpdate }] = useUpdateRechtstraegerSteuerpflichtMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      toggleEdit(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreate, { loading: loadingCreate }] = useCreateRechtstraegerSteuerpflichtMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      changeToUpdateMode(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<RechtstraegerSteuerpflichtVersionFormValues>
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        if (isUpdate) {
          runUpdate({
            variables: {
              rechtstraegerId,
              rechtstraegerSteuerpflichtId: timeblock.rechtstraegerSteuerpflichtId,
              request: mapFormValuesToSteuerpflichtVersion(values),
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({
            variables: {
              rechtstraegerId,
              request: mapFormValuesToSteuerpflichtVersion(values),
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form {...layoutTimelineForm} layout="horizontal" labelAlign="left">
          <Box width={2 / 3}>
            <FormItemWithFieldHelp
              name={steuerpflichtVersionFormFields.validFrom}
              label="Gültig ab"
              fieldHelp={getFieldHelpText('RechtstraegerSteuerpflicht.validFrom')}
            >
              <TimelineFormDatePicker<RechtstraegerSteuerpflicht> name={steuerpflichtVersionFormFields.validFrom} timeblock={timeblock} />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              name={steuerpflichtVersionFormFields.steuerpflicht}
              label={steuerpflichtVersionFormValuesDescriptions.steuerpflicht}
              fieldHelp={getFieldHelpText('RechtstraegerSteuerpflicht.steuerpflicht')}
            >
              <Select
                id={steuerpflichtVersionFormFields.steuerpflicht}
                name={steuerpflichtVersionFormFields.steuerpflicht}
                placeholder="Select Steuerpflicht"
                size="small"
              >
                <Select.Option value={Steuerpflicht.Steuerpflichtig}>Steuerpflichtig</Select.Option>
                <Select.Option value={Steuerpflicht.EchtSteuerbefreit}>Echt Steuerbefreit</Select.Option>
                <Select.Option value={Steuerpflicht.UnechtSteuerbefreit}>Unecht Steuerbefreit</Select.Option>
              </Select>
            </FormItemWithFieldHelp>
          </Box>

          <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />

          <TimelineFormKommentar
            name={steuerpflichtVersionFormFields.kommentar}
            timeblock={timeblock}
            valuesDescriptions={steuerpflichtVersionFormValuesDescriptions}
          />

          <TimelineFormButtons<RechtstraegerSteuerpflicht>
            timeblock={timeblock}
            isSubmitting={formikProps.isSubmitting}
            isSameAsPreviousVersion={isSameAsPreviousVersion(
              formikProps.values,
              getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
            )}
            submitBtnLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

const isSameAsPreviousVersion = (
  formValues: RechtstraegerSteuerpflichtVersionFormValues,
  previousTimeblock?: ITimeblock<RechtstraegerSteuerpflicht>
) => formValues.steuerpflicht === previousTimeblock?.steuerpflicht.value;

export default SteuerpflichtVersionForm;
