import { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../components/Grid';
import { SyncEbicsUserGenerierlauf } from '../../../types';
import { verarbeitungDetailsCardTitles } from '../verarbeitungHelpers';
import EntryListing from './EntryListing/EntryListing';

type Props = {
  generierlauf: SyncEbicsUserGenerierlauf;
};

const VerarbeitungSyncEbicsUser: FC<Props> = ({ generierlauf }) => (
  <>
    <Typography.Title level={4}>{verarbeitungDetailsCardTitles.SyncEbicsUser}</Typography.Title>
    <Spacer />
    <EntryListing generierlauf={generierlauf} />
  </>
);

export default VerarbeitungSyncEbicsUser;
