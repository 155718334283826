import { TextbausteinModel } from '../../../../components/Template/PDFTemplates/templateMapper';
import { AuftragsartType, TextbausteinInput } from '../../../../types';

export const isVorschreibungEinzelAbrechnungsergebnisGutschrift = (type: AuftragsartType) =>
  type === AuftragsartType.VorschreibungEinzelAbrechnungsergebnisGutschrift;
export const isVorschreibungEinzelAbrechnungsergebnisRechnung = (type: AuftragsartType) =>
  type === AuftragsartType.VorschreibungEinzelAbrechnungsergebnisRechnung;
export const isVorschreibungDauerrechnung = (type: AuftragsartType) => type === AuftragsartType.VorschreibungDauerrechnung;
export const isVorschreibungEinzel = (type: AuftragsartType) => type === AuftragsartType.VorschreibungEinzel;

export const isNotStornoAuftragsart = (type: AuftragsartType) =>
  isVorschreibungEinzelAbrechnungsergebnisGutschrift(type) ||
  isVorschreibungEinzelAbrechnungsergebnisRechnung(type) ||
  isVorschreibungDauerrechnung(type) ||
  isVorschreibungEinzel(type);

export const mapAuftragsartTextbausteinToInput = (auftragsartTextbausteinList: TextbausteinModel[]): TextbausteinInput[] => {
  return auftragsartTextbausteinList.map((textbaustein) => ({
    type: textbaustein.type.value,
    value: textbaustein.value,
  }));
};
