import React from 'react';
import VorschreibungDetails from './VorschreibungDetails';
import { StyledTableVorschreibungRows } from './styled/VorschreibungRows.style';
import EmptyTableRow from '../../../../../components/Table/TableWithAlignedCols/EmptyTableRow';
import { TableWithAlignedColsExpandedRowType } from '../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { createCells, TextWithStyling } from '../../shared/abrechnungTableHelper';
import { TopAbrAbrechnungkreis } from '../../../../../types';
import TableWithAlignedColsHeaderRow from '../../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsHeaderRow';
import AufwandAufteilungRows from './AufwandAufteilung/AufwandAufteilungRows';

const vorschreibungHeaderTexts: TextWithStyling[] = [
  { render: 'Vorschreibung' },
  { render: 'KontoInfo' },
  { render: 'Beleg-Datum' },
  { render: 'Beleg-Nr' },
  { render: 'Vorschreibung (Netto)', style: { textAlign: 'right' } },
  { render: 'OP zum Abrechnungsdatum', style: { textAlign: 'right' } },
  { render: 'Soll/Haben' },
];

const topAbrechnungWithoutRepFondsDetailsTableExpandedRow = (objektId: string): TableWithAlignedColsExpandedRowType<TopAbrAbrechnungkreis>[] => {
  const aufwandAufteilung: TableWithAlignedColsExpandedRowType<TopAbrAbrechnungkreis> = {
    header: (isOpen, setIsOpen) => (
      <TableWithAlignedColsHeaderRow
        isOpen={isOpen}
        bezeichnungHeaderText="Aufwandsaufteilung"
        sumHeaderText={['']}
        cellToUseForSumHeaderText={[4]}
        onClick={setIsOpen}
        startCell={1}
        endCell={4}
      />
    ),
    body: (abrKreis) => <AufwandAufteilungRows abrKreis={abrKreis} />,
  };

  const vorschreibung: TableWithAlignedColsExpandedRowType<TopAbrAbrechnungkreis> = {
    header: () => createCells(vorschreibungHeaderTexts, true),
    body: (abrKreis) => (
      <>
        {abrKreis.einnahmenBuchungszeileList.length > 0 ? (
          abrKreis.einnahmenBuchungszeileList.map((buchungsZeile, index) => {
            return <VorschreibungDetails key={index} buchungszeile={buchungsZeile} objektId={objektId} />;
          })
        ) : (
          <EmptyTableRow colSpan={9} entity="Vorschreibungen" />
        )}
      </>
    ),
    table: {
      colSpanSize: [10],
      positionOfButton: 1,
      StyledComponent: StyledTableVorschreibungRows,
      showCellButton: true,
    },
  };

  return [aufwandAufteilung, vorschreibung];
};

export default topAbrechnungWithoutRepFondsDetailsTableExpandedRow;
