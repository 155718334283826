import { Select, SelectProps } from 'formik-antd';
import { FC } from 'react';
import { Waehrung } from '../../../types';
import { selectFilterOption } from '../../../helpers/selectHelper';

type Props = {
  name: string;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'showSearch'>;
const CurrencyListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const list = [{ value: Waehrung.Eur, text: 'Euro' }];

  return (
    <Select
      size="middle"
      placeholder="Währung auswählen"
      {...restProps}
      name={name}
      id={name}
      filterOption={selectFilterOption}
      allowClear
      showSearch
    >
      {list.map((currency) => (
        <Select.Option key={currency.value} value={currency.value}>
          {currency.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CurrencyListFormSelect;
