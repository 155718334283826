import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useRechtstraegerTypeQuery } from '../../features/Rechtstraeger/gql/RechtsTraegerQueries.types';
import { RechtstraegerType } from '../../types';
import NatuerlichePersonDetailPage from './NatuerlichePersonDetailsPage/NatuerlichePersonDetailsPage';
import PersonenGemeinschaftDetailsPage from './PersonenGemeinschaftDetailsPage/PersonenGemeinschaftDetailsPage';
import FirmaDetailsPage from './FirmaDetailsPage/FirmaDetailsPage';
import { RechtstraegerDetailsPageRouteParams } from './routes';
import { LoadingDetailsPage } from '../../components/Loading';
import { useOnRechtstraegerDataChangeEvent } from '../../features/Rechtstraeger/useOnRechtstraegerDataChangeEvent';

const RechtstraegerDetailsPage: FC = () => {
  const { rechtstraegerId } = useParams() as RechtstraegerDetailsPageRouteParams;
  const { data, refetch } = useRechtstraegerTypeQuery({ variables: { rechtstraegerId } });
  const rechtstraegerType = data?.getRechtstraegerType.data.value;

  useOnRechtstraegerDataChangeEvent('rechtstraegerDetails', refetch, rechtstraegerId);

  const pageToBeRendered = () => {
    switch (rechtstraegerType) {
      case RechtstraegerType.NatuerlichePerson:
        return <NatuerlichePersonDetailPage rechtstraegerId={rechtstraegerId} rechtstraegerType={rechtstraegerType} />;
      case RechtstraegerType.PersonenGemeinschaft:
        return <PersonenGemeinschaftDetailsPage rechtstraegerId={rechtstraegerId} rechtstraegerType={rechtstraegerType} />;
      case RechtstraegerType.Firma:
        return <FirmaDetailsPage rechtstraegerId={rechtstraegerId} />;
      default:
        return null;
    }
  };

  return <LoadingDetailsPage loading={!rechtstraegerType}>{pageToBeRendered()}</LoadingDetailsPage>;
};

export default RechtstraegerDetailsPage;
