import React, { FC } from 'react';
import { DeleteOutlined, EditOutlined, HistoryOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { useToggle } from '../../../../../../../hooks/useToggle';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../helpers/apolloHelper';
import { BeVertragVorschreibungsPositionWertFieldsFragment } from '../../gql/BeVertragVorschreibungspositionFragments.types';
import { useDeleteBeVertragVorschreibunspositionWertMutation } from '../../gql/BeVertragVorschreibungspositionMutations.types';
import { gqlOperations } from '../../../../../../../graphql/gqlNamedOperations-generated';
import HistoryModal, { HistoryType } from '../../../../../../History/shared/HistoryModal';
import VertragVorschreibungsPositionWertChangeHistoryListingTable from './VertragVorschreibungsPositionWertChangeHistoryListingTable';
import { useEditableTable } from '../../../../../../../components/Table/EditableTableV2/editableTableContext';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  vorschreibungspositionId: string;
  vorschreibungsPositionWert: BeVertragVorschreibungsPositionWertFieldsFragment;
  onDeleteSuccess: () => void;
};

const VertragVorschreibungsPositionWertActions: FC<Props> = ({
  objektId,
  bestandseinheitId,
  vertragId,
  vorschreibungspositionId,
  vorschreibungsPositionWert,
  onDeleteSuccess,
}) => {
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runDelete, { loading: isDeleteLoading }] = useDeleteBeVertragVorschreibunspositionWertMutation({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      vorschreibungspositionId,
      vorschreibungspositionWertId: vorschreibungsPositionWert.vertragVorschreibungspositionWertId,
    },
    onCompleted: () => onDeleteSuccess(),
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    refetchQueries: () => [gqlOperations.Query.BeVertragVorschreibungspositionWertList],
  });

  const { selectedRecord, onUpdate } = useEditableTable<BeVertragVorschreibungsPositionWertFieldsFragment>();

  // every action (also deleting and popconfirm component) should be disabled while a new record is created or a different record is being modified
  const actionsDisabled = !!selectedRecord;
  const actionDeleteDisabled = !vorschreibungsPositionWert.deletable || actionsDisabled;

  return (
    <>
      <Tooltip title="Bearbeiten">
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => onUpdate(vorschreibungsPositionWert)}
          disabled={actionsDisabled}
          data-testid="vertragVorschreibungsPositionWert-btn-edit"
        />
      </Tooltip>
      <Popconfirm
        title="Sind Sie sicher?"
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        disabled={actionDeleteDisabled}
        onConfirm={() => runDelete()}
      >
        <Tooltip title="Löschen">
          <Button
            type="link"
            icon={<DeleteOutlined />}
            disabled={actionDeleteDisabled}
            data-testid="vertragVorschreibungsPositionWert-btn-delete"
            loading={isDeleteLoading}
          />
        </Tooltip>
      </Popconfirm>
      <Tooltip title="Änderungslog">
        <Button type="link" icon={<HistoryOutlined />} disabled={actionsDisabled} onClick={toggleChangeHistoryOpen} />
      </Tooltip>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <VertragVorschreibungsPositionWertChangeHistoryListingTable
          objektId={objektId}
          vorschreibungspositionWertId={vorschreibungsPositionWert.vertragVorschreibungspositionWertId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          vorschreibungspositionId={vorschreibungspositionId}
        />
      </HistoryModal>
    </>
  );
};

export default VertragVorschreibungsPositionWertActions;
