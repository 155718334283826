import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';
import { FiltersFormValues } from './listingFiltersFormMapper';

enum QueryParamKey {
  AMOUNT_FROM = 'amountFrom',
  AMOUNT_TO = 'amountTo',
  CREDITOR = 'creditor',
  DATA_CARRIER_CAMT_NAME = 'dataCarrierCamtName',
  DEBTOR = 'debtor',
  EXIT_CODE_LIST = 'exitCodeList',
  OBJEKT_ID = 'objektId',
  STATEMENT_NUMBER = 'statementNumber',
  VALUE_DATE_FROM = 'valueDateFrom',
  VALUE_DATE_TO = 'valueDateTo',
}

export type TReceivedBankTransactionVerbuchenQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  verarbeitungType: string,
  generierlaufId: string,
  filters: TReceivedBankTransactionVerbuchenQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToReceivedBankTransactionVerbuchenListingPage(verarbeitungType, generierlaufId, filters, paginationParams));

export const generatePathToReceivedBankTransactionVerbuchenListingPage = (
  verarbeitungType: string,
  generierlaufId: string,
  filters: TReceivedBankTransactionVerbuchenQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [QueryParamKey.AMOUNT_FROM]: filters?.amountFrom,
    [QueryParamKey.AMOUNT_TO]: filters?.amountTo,
    [QueryParamKey.CREDITOR]: filters?.creditor,
    [QueryParamKey.DATA_CARRIER_CAMT_NAME]: filters?.dataCarrierCamtName,
    [QueryParamKey.DEBTOR]: filters?.debtor,
    [QueryParamKey.EXIT_CODE_LIST]: filters?.exitCodeList,
    [QueryParamKey.OBJEKT_ID]: filters?.objektId,
    [QueryParamKey.STATEMENT_NUMBER]: filters?.statementNumber,
    [QueryParamKey.VALUE_DATE_FROM]: filters?.valueDateFrom,
    [QueryParamKey.VALUE_DATE_TO]: filters?.valueDateTo,
    ...paginationParams,
  })}`;
