import { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useMonthListQuery } from './gql/MonthQueries.types';

type Props = {
  name: string;
} & SelectProps;

const MonthSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useMonthListQuery();
  const monthList = data?.getMonthList.data ?? [];

  return (
    <Select size="small" name={name} id={name} placeholder="Monat auswählen" loading={loading} {...restProps}>
      {monthList.map((month) => (
        <Select.Option key={month.value} value={month.value}>
          {month.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default MonthSelect;
