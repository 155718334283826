import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GeneratePasswordQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GeneratePasswordQuery = {
  generatePassword: {
    data?: string | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const GeneratePasswordDocument = gql`
  query GeneratePassword {
    generatePassword {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useGeneratePasswordQuery(baseOptions?: Apollo.QueryHookOptions<GeneratePasswordQuery, GeneratePasswordQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeneratePasswordQuery, GeneratePasswordQueryVariables>(GeneratePasswordDocument, options);
}
export function useGeneratePasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeneratePasswordQuery, GeneratePasswordQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GeneratePasswordQuery, GeneratePasswordQueryVariables>(GeneratePasswordDocument, options);
}
export function useGeneratePasswordSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GeneratePasswordQuery, GeneratePasswordQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GeneratePasswordQuery, GeneratePasswordQueryVariables>(GeneratePasswordDocument, options);
}
export type GeneratePasswordQueryHookResult = ReturnType<typeof useGeneratePasswordQuery>;
export type GeneratePasswordLazyQueryHookResult = ReturnType<typeof useGeneratePasswordLazyQuery>;
export type GeneratePasswordSuspenseQueryHookResult = ReturnType<typeof useGeneratePasswordSuspenseQuery>;
export type GeneratePasswordQueryResult = Apollo.QueryResult<GeneratePasswordQuery, GeneratePasswordQueryVariables>;
