import React from 'react';
import { useParams } from 'react-router-dom';
import { TicketDetailsPageRouteParams } from '../TicketPage/routes';
import { useTicketQuery } from '../../features/Ticket/gql/TicketQueries.types';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import TicketCard from '../../features/Ticket/Card/TicketCard';
import menuListTicket from './menuListTicket';
import { useOnTicketDetailsDataChangedEvents } from '../../features/Ticket/useOnTicketDataChangedEvents';
import { TTicket } from '../../features/Ticket/ticketHelpers';

const TicketDetailsPage = () => {
  const { ticketId, subTicketId } = useParams() as TicketDetailsPageRouteParams;

  const currentlyShownTicketId = subTicketId ?? ticketId;

  const { data, refetch } = useTicketQuery({
    variables: {
      includeAttachmentsFromSubTickets: true,
      includeParticipantsFromSubTickets: true,
      includeEmailsFromSubTickets: true,
      ticketId: currentlyShownTicketId,
    },
  });
  const ticket = data?.getTicket.data;

  useOnTicketDetailsDataChangedEvents('ticket', refetch, currentlyShownTicketId);

  const isLoading = !ticket;

  return (
    <DetailsPageTemplate<TTicket>
      data={ticket}
      pageTitle={() => 'Aufgabe'}
      isLoading={isLoading}
      card={(ticket) => <TicketCard ticket={ticket} loading={isLoading} refetch={refetch} />}
      sidebarMenuList={(ticket) => menuListTicket(ticket, refetch)}
    />
  );
};

export default TicketDetailsPage;
