import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEbicsBankConfigMutationVariables = Types.Exact<{
  input: Types.EbicsBankConfigCreateInput;
}>;

export type CreateEbicsBankConfigMutation = {
  createEbicsBankConfig: {
    data: { ebicsBankConfigId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateEbicsBankConfigMutationVariables = Types.Exact<{
  ebicsBankConfigId: Types.Scalars['ID']['input'];
  input: Types.EbicsBankConfigUpdateInput;
}>;

export type UpdateEbicsBankConfigMutation = {
  updateEbicsBankConfig: {
    data: { ebicsBankConfigId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteEbicsBankConfigMutationVariables = Types.Exact<{
  ebicsBankConfigId: Types.Scalars['ID']['input'];
}>;

export type DeleteEbicsBankConfigMutation = {
  deleteEbicsBankConfig: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export const CreateEbicsBankConfigDocument = gql`
  mutation CreateEbicsBankConfig($input: EbicsBankConfigCreateInput!) {
    createEbicsBankConfig(input: $input) {
      data {
        ebicsBankConfigId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateEbicsBankConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEbicsBankConfigMutation, CreateEbicsBankConfigMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEbicsBankConfigMutation, CreateEbicsBankConfigMutationVariables>(CreateEbicsBankConfigDocument, options);
}
export type CreateEbicsBankConfigMutationHookResult = ReturnType<typeof useCreateEbicsBankConfigMutation>;
export type CreateEbicsBankConfigMutationResult = Apollo.MutationResult<CreateEbicsBankConfigMutation>;
export type CreateEbicsBankConfigMutationOptions = Apollo.BaseMutationOptions<CreateEbicsBankConfigMutation, CreateEbicsBankConfigMutationVariables>;
export const UpdateEbicsBankConfigDocument = gql`
  mutation UpdateEbicsBankConfig($ebicsBankConfigId: ID!, $input: EbicsBankConfigUpdateInput!) {
    updateEbicsBankConfig(ebicsBankConfigId: $ebicsBankConfigId, input: $input) {
      data {
        ebicsBankConfigId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateEbicsBankConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEbicsBankConfigMutation, UpdateEbicsBankConfigMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEbicsBankConfigMutation, UpdateEbicsBankConfigMutationVariables>(UpdateEbicsBankConfigDocument, options);
}
export type UpdateEbicsBankConfigMutationHookResult = ReturnType<typeof useUpdateEbicsBankConfigMutation>;
export type UpdateEbicsBankConfigMutationResult = Apollo.MutationResult<UpdateEbicsBankConfigMutation>;
export type UpdateEbicsBankConfigMutationOptions = Apollo.BaseMutationOptions<UpdateEbicsBankConfigMutation, UpdateEbicsBankConfigMutationVariables>;
export const DeleteEbicsBankConfigDocument = gql`
  mutation DeleteEbicsBankConfig($ebicsBankConfigId: ID!) {
    deleteEbicsBankConfig(ebicsBankConfigId: $ebicsBankConfigId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteEbicsBankConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEbicsBankConfigMutation, DeleteEbicsBankConfigMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEbicsBankConfigMutation, DeleteEbicsBankConfigMutationVariables>(DeleteEbicsBankConfigDocument, options);
}
export type DeleteEbicsBankConfigMutationHookResult = ReturnType<typeof useDeleteEbicsBankConfigMutation>;
export type DeleteEbicsBankConfigMutationResult = Apollo.MutationResult<DeleteEbicsBankConfigMutation>;
export type DeleteEbicsBankConfigMutationOptions = Apollo.BaseMutationOptions<DeleteEbicsBankConfigMutation, DeleteEbicsBankConfigMutationVariables>;
