import { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TopAbrechnungDetailsTable from './TopAbrechnungDetailsTable';
import { TopAbrechnung } from '../../../../../types';

type Props = {
  objektId: string;
  objektAbrechnungId: string;
  topAbrechnung: TopAbrechnung;
};

const TopAbrechnungDetailsTab: FC<Props> = ({ objektId, objektAbrechnungId, topAbrechnung }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Topabrechnung',
      children: <TopAbrechnungDetailsTable objektId={objektId} objektAbrechnungId={objektAbrechnungId} topAbrechnung={topAbrechnung} />,
    },
  ];
  return <Tabs items={items} />;
};

export default TopAbrechnungDetailsTab;
