import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerInfoFeldFragmentDoc } from './rechtstraegerInfoFeldFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RechtstraegerInfoFeldListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type RechtstraegerInfoFeldListQuery = {
  getRechtstraegerInfoFeldList: {
    data: Array<{
      __typename: 'RechtstraegerInfoFeldPersoneninfos';
      rechtstraegerInfoFeldId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fileIdList: Array<string>;
      geburtsort?: string | null;
      identitaetsnachweis?: string | null;
      kundengruppeList?: Array<string> | null;
      merkmalList: Array<string>;
      staatsbuergerschaft?: string | null;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.InfoFeldStatus };
      type: { text: string; value: Types.RechtstraegerInfoFeldType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RechtstraegerInfoFeldQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type RechtstraegerInfoFeldQuery = {
  getRechtstraegerInfoFeld: {
    data: {
      __typename: 'RechtstraegerInfoFeldPersoneninfos';
      rechtstraegerInfoFeldId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fileIdList: Array<string>;
      geburtsort?: string | null;
      identitaetsnachweis?: string | null;
      kundengruppeList?: Array<string> | null;
      merkmalList: Array<string>;
      staatsbuergerschaft?: string | null;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.InfoFeldStatus };
      type: { text: string; value: Types.RechtstraegerInfoFeldType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RechtstraegerInfoFeldListDocument = gql`
  query RechtstraegerInfoFeldList($rechtstraegerId: ID!, $includeArchiviert: Boolean) {
    getRechtstraegerInfoFeldList(rechtstraegerId: $rechtstraegerId, includeArchiviert: $includeArchiviert) {
      data {
        ...RechtstraegerInfoFeld
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerInfoFeldFragmentDoc}
`;
export function useRechtstraegerInfoFeldListQuery(
  baseOptions: Apollo.QueryHookOptions<RechtstraegerInfoFeldListQuery, RechtstraegerInfoFeldListQueryVariables> &
    ({ variables: RechtstraegerInfoFeldListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerInfoFeldListQuery, RechtstraegerInfoFeldListQueryVariables>(RechtstraegerInfoFeldListDocument, options);
}
export function useRechtstraegerInfoFeldListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerInfoFeldListQuery, RechtstraegerInfoFeldListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerInfoFeldListQuery, RechtstraegerInfoFeldListQueryVariables>(RechtstraegerInfoFeldListDocument, options);
}
export function useRechtstraegerInfoFeldListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerInfoFeldListQuery, RechtstraegerInfoFeldListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerInfoFeldListQuery, RechtstraegerInfoFeldListQueryVariables>(RechtstraegerInfoFeldListDocument, options);
}
export type RechtstraegerInfoFeldListQueryHookResult = ReturnType<typeof useRechtstraegerInfoFeldListQuery>;
export type RechtstraegerInfoFeldListLazyQueryHookResult = ReturnType<typeof useRechtstraegerInfoFeldListLazyQuery>;
export type RechtstraegerInfoFeldListSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerInfoFeldListSuspenseQuery>;
export type RechtstraegerInfoFeldListQueryResult = Apollo.QueryResult<RechtstraegerInfoFeldListQuery, RechtstraegerInfoFeldListQueryVariables>;
export const RechtstraegerInfoFeldDocument = gql`
  query RechtstraegerInfoFeld($rechtstraegerId: ID!, $infoFeldId: ID!) {
    getRechtstraegerInfoFeld(rechtstraegerId: $rechtstraegerId, infoFeldId: $infoFeldId) {
      data {
        ...RechtstraegerInfoFeld
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerInfoFeldFragmentDoc}
`;
export function useRechtstraegerInfoFeldQuery(
  baseOptions: Apollo.QueryHookOptions<RechtstraegerInfoFeldQuery, RechtstraegerInfoFeldQueryVariables> &
    ({ variables: RechtstraegerInfoFeldQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerInfoFeldQuery, RechtstraegerInfoFeldQueryVariables>(RechtstraegerInfoFeldDocument, options);
}
export function useRechtstraegerInfoFeldLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerInfoFeldQuery, RechtstraegerInfoFeldQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerInfoFeldQuery, RechtstraegerInfoFeldQueryVariables>(RechtstraegerInfoFeldDocument, options);
}
export function useRechtstraegerInfoFeldSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerInfoFeldQuery, RechtstraegerInfoFeldQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerInfoFeldQuery, RechtstraegerInfoFeldQueryVariables>(RechtstraegerInfoFeldDocument, options);
}
export type RechtstraegerInfoFeldQueryHookResult = ReturnType<typeof useRechtstraegerInfoFeldQuery>;
export type RechtstraegerInfoFeldLazyQueryHookResult = ReturnType<typeof useRechtstraegerInfoFeldLazyQuery>;
export type RechtstraegerInfoFeldSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerInfoFeldSuspenseQuery>;
export type RechtstraegerInfoFeldQueryResult = Apollo.QueryResult<RechtstraegerInfoFeldQuery, RechtstraegerInfoFeldQueryVariables>;
