import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import menuListPaymentProposalExecute from './menuListPaymentProposalExecute';
import { useRestartPaymentProposalExecutionGenerierlaufMutation } from '../../../../features/Verarbeitung/gql/PaymentProposalGenerierlauf/PaymentProposalGenerierlaufMutations.types';
import { usePaymentProposalExecutionGenerierlaufQuery } from '../../../../features/Verarbeitung/gql/PaymentProposalGenerierlauf/PaymentProposalGenerierlaufQueries.types';
import { PaymentProposalExecutionGenerierlauf } from '../../../../types';
import { VerarbeitungPaymentProposalDetailsPageRouteParams } from '../routes';

const PaymentProposalExecuteDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungPaymentProposalDetailsPageRouteParams;

  const { data, refetch } = usePaymentProposalExecutionGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getPaymentProposalExecutionGenerierlauf.data;

  const [runRestart] = useRestartPaymentProposalExecutionGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<PaymentProposalExecutionGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.PaymentProposalExecute}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.PaymentProposalExecute}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListPaymentProposalExecute(generierlauf)}
    />
  );
};

export default PaymentProposalExecuteDetailsPage;
