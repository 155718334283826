import { FC } from 'react';
import { FileAddOutlined } from '@ant-design/icons';
import { PostItInput } from '../../types';
import PostItCreateForm from './Form/PostItCreateForm';
import theme from '../../theme';

type Props = {
  isPostItOpen: boolean;
  onIconClick: () => void;
  onFormCancel: () => void;
  onFormSubmit: (values: PostItInput) => void;
  showImmerAnzeigen?: boolean;
};

const PostItIconAndCreateMode: FC<Props> = ({ isPostItOpen, onIconClick, onFormCancel, onFormSubmit, showImmerAnzeigen }) => (
  <>
    <FileAddOutlined style={{ color: theme.colors.blue }} onClick={onIconClick} />
    <PostItCreateForm
      isOpen={isPostItOpen}
      onSubmit={(values) => onFormSubmit(values)}
      onCancel={onFormCancel}
      showImmerAnzeigen={showImmerAnzeigen}
    />
  </>
);

export default PostItIconAndCreateMode;
