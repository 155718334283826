import React from 'react';
import { Select } from 'formik-antd';
import { usePersonsCommunityTypeListQuery } from './gql/PersonsCommunityTypeQueries.types';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

const PersonsCommunityTypeSelect = ({ ...props }) => {
  const { fieldHelp, ...restProps } = props;
  const { loading, data } = usePersonsCommunityTypeListQuery();

  const personsCommunityTypeList = data?.getPersonsCommunityTypeList.data ?? [];

  return (
    <FormItemWithFieldHelp name={props.name} label="Art der Personengemeinschaft" fieldHelp={fieldHelp}>
      <Select id={props.name} name={props.name} loading={loading} placeholder="Art der Personengemeinschaft auswählen" {...restProps}>
        {personsCommunityTypeList.map((item) => (
          <Select.Option key={item.value} value={item.value}>
            {item.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithFieldHelp>
  );
};

export default PersonsCommunityTypeSelect;
