import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { FibuObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { FibuRechtstraegerFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { MahnungFieldsFragmentDoc } from '../../../Mahnung/gql/MahnungFragments.types';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MahnungAusgebenGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type MahnungAusgebenGenerierlaufQuery = {
  getMahnungAusgebenGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      erstellerMitarbeiterIdList: Array<string>;
      generierlaufId: string;
      mahnstichtagBisInklusive?: string | null;
      mahnstichtagVonInklusive?: string | null;
      errorList: Array<{ message: string; type: string }>;
      objekt?: { kurzBezeichnung: string; objektId: string } | null;
      rechnungsAussteller?: {
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
      } | null;
      status: { value: Types.GenerierlaufStatus; text: string };
      vertragspartner?: {
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
      } | null;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungAusgebenGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsausstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.MahnungGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type MahnungAusgebenGenerierlaufEntryListQuery = {
  getMahnungAusgebenGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        retry: boolean;
        stepCount?: number | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        mahnung: {
          buchungId?: string | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          fileId?: string | null;
          freigegebenTs?: string | null;
          mahngebuehr: number;
          mahnstichtag: string;
          mahnungEmail?: string | null;
          mahnungId: string;
          opEmail: string;
          rechnungsAusstellerBankDetailsAccountHolder: string;
          rechnungsAusstellerBankDetailsIban: string;
          updatedByMitarbeiterId?: string | null;
          sumMahnbetrag: number;
          sumRechnungsbetrag: number;
          sumVerzugszinsen: number;
          sumZahlungen: number;
          versandEinschreiben: boolean;
          versendetTs?: string | null;
          verzugszinsen: number;
          buchungskreis: {
            buchungskreisId: string;
            rechtstraeger: {
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
            };
          };
          debitorKonto: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            haupt: boolean;
            kontoId: string;
            kontoTemplateId?: string | null;
            nummer: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            klasse: { text: string; value: Types.KontoKlasse };
            status: { description?: string | null; text: string; value: Types.KontoStatus };
            type: { text: string; value: Types.KontoType };
            verwendung?: { text: string; value: Types.KontoVerwendung } | null;
          };
          mahnPositionList: Array<{
            belegDatum: string;
            belegFileId: string;
            belegnummer: string;
            buchungskreisId: string;
            dataCarrierBelegId?: string | null;
            dueDate: string;
            gesamtBetrag: number;
            iban?: string | null;
            letzterMahnstichtag?: string | null;
            mahnPositionId: string;
            mahnstufe: number;
            offenerBetrag: number;
            statementNumber?: string | null;
            verzugszinsen: number;
            verzugszinsenBerechnen: boolean;
            zahlungBetrag: number;
            belegSymbol: { abbreviation: string; text: string; value: Types.FibuBelegSymbol };
          }>;
          mahnungDeliveryAddress?: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          } | null;
          mailDelivery?: { mailId?: string | null; status: { text: string; value: Types.MahnungMailDeliveryStatus } } | null;
          objektList: Array<{ kurzBezeichnung: string; objektId: string }>;
          opDeliveryAddress: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
          opZustellRechtstraeger: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
          rechnungsAussteller: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
          status: { value: Types.MahnungStatus; text: string };
          vertragspartner: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
        };
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { size: number; number: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const MahnungAusgebenGenerierlaufDocument = gql`
  query MahnungAusgebenGenerierlauf($generierlaufId: ID!) {
    getMahnungAusgebenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        erstellerMitarbeiterIdList
        generierlaufId
        mahnstichtagBisInklusive
        mahnstichtagVonInklusive
        objekt {
          ...FibuObjekt
        }
        rechnungsAussteller {
          ...FibuRechtstraeger
        }
        status {
          value
          text
        }
        vertragspartner {
          ...FibuRechtstraeger
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FibuObjektFragmentDoc}
  ${FibuRechtstraegerFragmentDoc}
`;
export function useMahnungAusgebenGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<MahnungAusgebenGenerierlaufQuery, MahnungAusgebenGenerierlaufQueryVariables> &
    ({ variables: MahnungAusgebenGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungAusgebenGenerierlaufQuery, MahnungAusgebenGenerierlaufQueryVariables>(MahnungAusgebenGenerierlaufDocument, options);
}
export function useMahnungAusgebenGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnungAusgebenGenerierlaufQuery, MahnungAusgebenGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungAusgebenGenerierlaufQuery, MahnungAusgebenGenerierlaufQueryVariables>(
    MahnungAusgebenGenerierlaufDocument,
    options
  );
}
export function useMahnungAusgebenGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnungAusgebenGenerierlaufQuery, MahnungAusgebenGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungAusgebenGenerierlaufQuery, MahnungAusgebenGenerierlaufQueryVariables>(
    MahnungAusgebenGenerierlaufDocument,
    options
  );
}
export type MahnungAusgebenGenerierlaufQueryHookResult = ReturnType<typeof useMahnungAusgebenGenerierlaufQuery>;
export type MahnungAusgebenGenerierlaufLazyQueryHookResult = ReturnType<typeof useMahnungAusgebenGenerierlaufLazyQuery>;
export type MahnungAusgebenGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useMahnungAusgebenGenerierlaufSuspenseQuery>;
export type MahnungAusgebenGenerierlaufQueryResult = Apollo.QueryResult<MahnungAusgebenGenerierlaufQuery, MahnungAusgebenGenerierlaufQueryVariables>;
export const MahnungAusgebenGenerierlaufEntryListDocument = gql`
  query MahnungAusgebenGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $rechnungsausstellerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: MahnungGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getMahnungAusgebenGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      rechnungsausstellerId: $rechnungsausstellerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          currentStep
          errorList {
            message
            type
          }
          exitCode {
            value
            text
          }
          generierlaufEntryId
          infoMessage
          mahnung {
            ...MahnungFields
          }
          retry
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          warningList {
            message
            type
          }
        }
        page {
          size
          number
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MahnungFieldsFragmentDoc}
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function useMahnungAusgebenGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnungAusgebenGenerierlaufEntryListQuery, MahnungAusgebenGenerierlaufEntryListQueryVariables> &
    ({ variables: MahnungAusgebenGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungAusgebenGenerierlaufEntryListQuery, MahnungAusgebenGenerierlaufEntryListQueryVariables>(
    MahnungAusgebenGenerierlaufEntryListDocument,
    options
  );
}
export function useMahnungAusgebenGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnungAusgebenGenerierlaufEntryListQuery, MahnungAusgebenGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungAusgebenGenerierlaufEntryListQuery, MahnungAusgebenGenerierlaufEntryListQueryVariables>(
    MahnungAusgebenGenerierlaufEntryListDocument,
    options
  );
}
export function useMahnungAusgebenGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MahnungAusgebenGenerierlaufEntryListQuery, MahnungAusgebenGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungAusgebenGenerierlaufEntryListQuery, MahnungAusgebenGenerierlaufEntryListQueryVariables>(
    MahnungAusgebenGenerierlaufEntryListDocument,
    options
  );
}
export type MahnungAusgebenGenerierlaufEntryListQueryHookResult = ReturnType<typeof useMahnungAusgebenGenerierlaufEntryListQuery>;
export type MahnungAusgebenGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useMahnungAusgebenGenerierlaufEntryListLazyQuery>;
export type MahnungAusgebenGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<typeof useMahnungAusgebenGenerierlaufEntryListSuspenseQuery>;
export type MahnungAusgebenGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  MahnungAusgebenGenerierlaufEntryListQuery,
  MahnungAusgebenGenerierlaufEntryListQueryVariables
>;
