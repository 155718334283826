import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { Form } from 'formik-antd';
import PersonFormPart from '../../shared/components/Person/PersonFormPart';
import { personPartValidationSchema } from '../../shared/components/Person/personFormValidationSchema';
import { personFormPartInitialValues } from '../../shared/components/Person/personHelper';
import FormButtons from '../../components/Button/FormButtons';

const UserUpdateForm = ({ formInitialValues, formValidationSchema, formEnableReinitialize, onSubmit, onCancel }) => (
  <Formik
    initialValues={formInitialValues}
    enableReinitialize={formEnableReinitialize}
    validationSchema={formValidationSchema}
    validateOnChange={false}
    onSubmit={(values, { setSubmitting }) => {
      onSubmit(values);
      setSubmitting(false);
    }}
  >
    {(formikProps) => (
      <Form layout="vertical">
        <PersonFormPart />
        <FormButtons updateMode isOkDisabled={formikProps.isSubmitting} onCancel={onCancel} isOkLoading={false} />
      </Form>
    )}
  </Formik>
);

UserUpdateForm.propTypes = {
  formInitialValues: PropTypes.object.isRequired,
  formValidationSchema: PropTypes.object.isRequired,
  formEnableReinitialize: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

UserUpdateForm.defaultProps = {
  formInitialValues: {
    ...personFormPartInitialValues(),
  },
  formValidationSchema: personPartValidationSchema,
};

export default UserUpdateForm;
