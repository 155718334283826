import { FC } from 'react';
import { useBudgetingVPosAuftschluesselLinkageChangeHistoryListQuery } from '../../gql/budgetingVPosAuftschluesselLinkageQueries.types';
import { HistoryType } from '../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../History/HistoryListingTable';

type Props = {
  budgetingVPosAuftschluesselLinkageId: string;
};

const LinkageChangeHistoryListingTable: FC<Props> = ({ budgetingVPosAuftschluesselLinkageId }) => {
  const { data, loading } = useBudgetingVPosAuftschluesselLinkageChangeHistoryListQuery({
    variables: { budgetingVPosAuftschluesselLinkageId },
  });
  const historyList = data?.getBudgetingVPosAuftschluesselLinkageChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="budgeting-vpos-auftschluesse-linkage"
    />
  );
};

export default LinkageChangeHistoryListingTable;
