import { FC } from 'react';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import VstKuerzungKontenZuweisungVersionCardContent from './VstKuerzungKontenZuweisungVersionCardContent';
import VstKuerzungKontenZuweisungVersionForm from './Form/VstKuerzungKontenZuweisungVersionForm';
import { useVorsteuerkuerzungEinstellungListQuery } from '../../gql/VorsteuerkuerzungEinstellungQueries.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useDeleteVorsteuerkuerzungEinstellungMutation } from '../../gql/VorsteuerkuerzungEinstellungMutations.types';
import { showSuccessMsgDelete } from '../../../../components/message';
import { Verwaltungsart, VorsteuerkuerzungEinstellung } from '../../../../types';

type Props = {
  verwaltungsart: Verwaltungsart;
  reloadVstKuerzungCurrentKontenZuweisung: () => void;
};

const VstKuerzungKontenZuweisungVersionTimeline: FC<Props> = ({ verwaltungsart, reloadVstKuerzungCurrentKontenZuweisung }) => {
  const {
    data,
    loading,
    refetch: reloadVersionList,
  } = useVorsteuerkuerzungEinstellungListQuery({
    variables: {
      verwaltungsart,
      withDetails: true,
    },
  });

  const vorsteuerkuerzungEinstellungList = data?.getVorsteuerkuerzungEinstellungList.data ?? [];

  const onActionSuccess = () => {
    reloadVstKuerzungCurrentKontenZuweisung();
    reloadVersionList();
  };

  const [runDelete] = useDeleteVorsteuerkuerzungEinstellungMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Vorsteuerkürzung-Kontenzuweisung');
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<VorsteuerkuerzungEinstellung>
      dataSource={vorsteuerkuerzungEinstellungList}
      versionIdKey="vorsteuerkuerzungEinstellungId"
      loading={loading}
      defaultNewTimeblock={{
        vorsteuerkuerzungEinstellungId: '',
        kontenZuweisungList: [],
        warningList: [],
        versionIndex: 0,
      }}
      isAllowedToDeleteInitialTimeblock
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      onDelete={(timeblock) => runDelete({ variables: { verwaltungsart, vorsteuerkuerzungEinstellungId: timeblock.vorsteuerkuerzungEinstellungId } })}
      renderCardContent={(timeblock) => <VstKuerzungKontenZuweisungVersionCardContent verwaltungsart={verwaltungsart} timeblock={timeblock} />}
      renderForm={(timeblock) => (
        <VstKuerzungKontenZuweisungVersionForm verwaltungsart={verwaltungsart} onSubmitSuccess={onActionSuccess} timeblock={timeblock} />
      )}
    />
  );
};

export default VstKuerzungKontenZuweisungVersionTimeline;
