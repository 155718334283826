import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { GeneralTextbausteinFieldsFragmentDoc } from './TextbausteinFragments.types';
import { TextbausteinFieldsFragmentDoc } from '../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GeneralTextbausteinTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GeneralTextbausteinTypeListQuery = {
  getGeneralTextbausteinTypeList: {
    data: Array<{ text: string; value: Types.TextbausteinType }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TextbausteinListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TextbausteinListQuery = {
  getTextbausteinList: {
    data: Array<{
      bezeichnung: string;
      createdBy?: string | null;
      createTs: string;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      textbausteinId: string;
      auftragsartList: Array<{
        auftragsartId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        showUstBlock: boolean;
        updatedByMitarbeiterId?: string | null;
        status: { text: string; value: Types.AuftragsartStatus };
        type: { text: string; value: Types.AuftragsartType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      textbaustein: {
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      };
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TextbausteinTemplatesQueryVariables = Types.Exact<{
  textbausteinType: Types.TextbausteinType;
}>;

export type TextbausteinTemplatesQuery = {
  getTextbausteinTemplates: {
    data: {
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.TextbausteinType };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TextbausteinChangeHistoryListQueryVariables = Types.Exact<{
  textbausteinId: Types.Scalars['ID']['input'];
}>;

export type TextbausteinChangeHistoryListQuery = {
  getTextbausteinChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const GeneralTextbausteinTypeListDocument = gql`
  query GeneralTextbausteinTypeList {
    getGeneralTextbausteinTypeList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useGeneralTextbausteinTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<GeneralTextbausteinTypeListQuery, GeneralTextbausteinTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeneralTextbausteinTypeListQuery, GeneralTextbausteinTypeListQueryVariables>(GeneralTextbausteinTypeListDocument, options);
}
export function useGeneralTextbausteinTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GeneralTextbausteinTypeListQuery, GeneralTextbausteinTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GeneralTextbausteinTypeListQuery, GeneralTextbausteinTypeListQueryVariables>(
    GeneralTextbausteinTypeListDocument,
    options
  );
}
export function useGeneralTextbausteinTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GeneralTextbausteinTypeListQuery, GeneralTextbausteinTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GeneralTextbausteinTypeListQuery, GeneralTextbausteinTypeListQueryVariables>(
    GeneralTextbausteinTypeListDocument,
    options
  );
}
export type GeneralTextbausteinTypeListQueryHookResult = ReturnType<typeof useGeneralTextbausteinTypeListQuery>;
export type GeneralTextbausteinTypeListLazyQueryHookResult = ReturnType<typeof useGeneralTextbausteinTypeListLazyQuery>;
export type GeneralTextbausteinTypeListSuspenseQueryHookResult = ReturnType<typeof useGeneralTextbausteinTypeListSuspenseQuery>;
export type GeneralTextbausteinTypeListQueryResult = Apollo.QueryResult<GeneralTextbausteinTypeListQuery, GeneralTextbausteinTypeListQueryVariables>;
export const TextbausteinListDocument = gql`
  query TextbausteinList {
    getTextbausteinList {
      data {
        ...GeneralTextbausteinFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${GeneralTextbausteinFieldsFragmentDoc}
`;
export function useTextbausteinListQuery(baseOptions?: Apollo.QueryHookOptions<TextbausteinListQuery, TextbausteinListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TextbausteinListQuery, TextbausteinListQueryVariables>(TextbausteinListDocument, options);
}
export function useTextbausteinListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TextbausteinListQuery, TextbausteinListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TextbausteinListQuery, TextbausteinListQueryVariables>(TextbausteinListDocument, options);
}
export function useTextbausteinListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TextbausteinListQuery, TextbausteinListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TextbausteinListQuery, TextbausteinListQueryVariables>(TextbausteinListDocument, options);
}
export type TextbausteinListQueryHookResult = ReturnType<typeof useTextbausteinListQuery>;
export type TextbausteinListLazyQueryHookResult = ReturnType<typeof useTextbausteinListLazyQuery>;
export type TextbausteinListSuspenseQueryHookResult = ReturnType<typeof useTextbausteinListSuspenseQuery>;
export type TextbausteinListQueryResult = Apollo.QueryResult<TextbausteinListQuery, TextbausteinListQueryVariables>;
export const TextbausteinTemplatesDocument = gql`
  query TextbausteinTemplates($textbausteinType: TextbausteinType!) {
    getTextbausteinTemplates(textbausteinType: $textbausteinType) {
      data {
        ...TextbausteinFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
export function useTextbausteinTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<TextbausteinTemplatesQuery, TextbausteinTemplatesQueryVariables> &
    ({ variables: TextbausteinTemplatesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TextbausteinTemplatesQuery, TextbausteinTemplatesQueryVariables>(TextbausteinTemplatesDocument, options);
}
export function useTextbausteinTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TextbausteinTemplatesQuery, TextbausteinTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TextbausteinTemplatesQuery, TextbausteinTemplatesQueryVariables>(TextbausteinTemplatesDocument, options);
}
export function useTextbausteinTemplatesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TextbausteinTemplatesQuery, TextbausteinTemplatesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TextbausteinTemplatesQuery, TextbausteinTemplatesQueryVariables>(TextbausteinTemplatesDocument, options);
}
export type TextbausteinTemplatesQueryHookResult = ReturnType<typeof useTextbausteinTemplatesQuery>;
export type TextbausteinTemplatesLazyQueryHookResult = ReturnType<typeof useTextbausteinTemplatesLazyQuery>;
export type TextbausteinTemplatesSuspenseQueryHookResult = ReturnType<typeof useTextbausteinTemplatesSuspenseQuery>;
export type TextbausteinTemplatesQueryResult = Apollo.QueryResult<TextbausteinTemplatesQuery, TextbausteinTemplatesQueryVariables>;
export const TextbausteinChangeHistoryListDocument = gql`
  query TextbausteinChangeHistoryList($textbausteinId: ID!) {
    getTextbausteinChangeHistoryList(textbausteinId: $textbausteinId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useTextbausteinChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<TextbausteinChangeHistoryListQuery, TextbausteinChangeHistoryListQueryVariables> &
    ({ variables: TextbausteinChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TextbausteinChangeHistoryListQuery, TextbausteinChangeHistoryListQueryVariables>(
    TextbausteinChangeHistoryListDocument,
    options
  );
}
export function useTextbausteinChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TextbausteinChangeHistoryListQuery, TextbausteinChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TextbausteinChangeHistoryListQuery, TextbausteinChangeHistoryListQueryVariables>(
    TextbausteinChangeHistoryListDocument,
    options
  );
}
export function useTextbausteinChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TextbausteinChangeHistoryListQuery, TextbausteinChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TextbausteinChangeHistoryListQuery, TextbausteinChangeHistoryListQueryVariables>(
    TextbausteinChangeHistoryListDocument,
    options
  );
}
export type TextbausteinChangeHistoryListQueryHookResult = ReturnType<typeof useTextbausteinChangeHistoryListQuery>;
export type TextbausteinChangeHistoryListLazyQueryHookResult = ReturnType<typeof useTextbausteinChangeHistoryListLazyQuery>;
export type TextbausteinChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useTextbausteinChangeHistoryListSuspenseQuery>;
export type TextbausteinChangeHistoryListQueryResult = Apollo.QueryResult<
  TextbausteinChangeHistoryListQuery,
  TextbausteinChangeHistoryListQueryVariables
>;
