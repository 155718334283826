import {
  BeInfoFeld_BeInfoFeldKellerabteil_Fragment,
  BeInfoFeld_BeInfoFeldParkplatz_Fragment,
  BeInfoFeld_BeInfoFeldZimmer_Fragment,
  BeInfoFeldFragment,
} from '../gql/BeInfoFeldFragments.types';

export const isBeInfoFeldKellerabteil = (attribute: BeInfoFeldFragment): attribute is BeInfoFeld_BeInfoFeldKellerabteil_Fragment =>
  attribute.__typename === 'BeInfoFeldKellerabteil';

export const isBeInfoFeldParkplatz = (attribute: BeInfoFeldFragment): attribute is BeInfoFeld_BeInfoFeldParkplatz_Fragment =>
  attribute.__typename === 'BeInfoFeldParkplatz';

export const isBeInfoFeldZimmer = (attribute: BeInfoFeldFragment): attribute is BeInfoFeld_BeInfoFeldZimmer_Fragment =>
  attribute.__typename === 'BeInfoFeldZimmer';

export const getBeInfoFeldShortName = (beInfoFeld: BeInfoFeldFragment): string => {
  if (isBeInfoFeldKellerabteil(beInfoFeld)) {
    return beInfoFeld.kellerabteilNummer;
  } else if (isBeInfoFeldParkplatz(beInfoFeld)) {
    return beInfoFeld.parkplatzNummer;
  } else if (isBeInfoFeldZimmer(beInfoFeld)) {
    return beInfoFeld.bezeichnung;
  }
  return '';
};
