import React, { FC } from 'react';
import { EyeOutlined, FormOutlined, ReadOutlined } from '@ant-design/icons';
import { MenuProps, Modal } from 'antd';
import { ArchiveIcon, Link1Icon, LinkBreak1Icon } from '@radix-ui/react-icons';
import { showSuccessMsgArchived, showSuccessMsgOther, showSuccessMsgUnarchived } from '../../../../components/message';
import { ReceivedBankTransactionListEntry, ReceivedBankTransactionStatus } from '../../../../types';
import {
  useArchiveReceivedBankTransactionMutation,
  useMarkReceivedBankTransactionAsNotedMutation,
  useRemoveAssignmentReceivedBankTransactionMutation,
  useUnarchiveReceivedBankTransactionMutation,
} from '../../gql/ReceivedBankTransactionMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';
import { useToggle } from '../../../../hooks/useToggle';
import PaymentAssignmentForm from '../Form/PaymentAssignment/PaymentAssignmentForm';
import { generatePathToBookingSuggestionBuchungsmaskeWithTabsPage } from '../../../BookingSuggestion/bookingSuggestionBuchungsmaskePaths';
import BookForm from '../Form/Book/BookForm';
import receivedBankTransactionTableActionForBuchungDetails from '../../shared/ReceivedBankTransactionTableActionForBuchungDetails';

type Props = {
  receivedBankTransaction: ReceivedBankTransactionListEntry;
  onAction: () => void;
};

const ReceivedBankTransactionTableActions: FC<Props> = ({ receivedBankTransaction, onAction }) => {
  const entity = 'Empfangene Bankbuchung';

  const [isPaymentOrderModalOpen, togglePaymentOrderModalOpen] = useToggle();
  const [isBookModalOpen, toggleBookModalOpen] = useToggle();

  const [runMarkAsNoted] = useMarkReceivedBankTransactionAsNotedMutation({
    variables: { receivedBankTransactionId: receivedBankTransaction.receivedBankTransactionId },
    onCompleted: () => {
      showSuccessMsgOther('Empfangene Bankbuchung wurde als gesehen markiert');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveReceivedBankTransactionMutation({
    variables: {
      receivedBankTransactionId: receivedBankTransaction.receivedBankTransactionId,
    },
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveReceivedBankTransactionMutation({
    variables: {
      receivedBankTransactionId: receivedBankTransaction.receivedBankTransactionId,
    },
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runRemoveAssignemt] = useRemoveAssignmentReceivedBankTransactionMutation({
    variables: {
      receivedBankTransactionId: receivedBankTransaction.receivedBankTransactionId,
    },
    onCompleted: () => {
      showSuccessMsgOther('Zahlungszuweisung wurde entfernt');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isArchived = receivedBankTransaction.status.value === ReceivedBankTransactionStatus.Archived;

  const items: MenuProps['items'] = [
    !receivedBankTransaction.noted
      ? {
          key: '1',
          label: 'Als Gesehen markieren',
          icon: <EyeOutlined />,
          onClick: () => {
            runMarkAsNoted();
          },
        }
      : null,
    {
      key: '2',
      label: `Zahlungszuweisung ${receivedBankTransaction.assignPaymentAllowed ? 'hinzufügen' : 'entfernen'}`,
      icon: receivedBankTransaction.assignPaymentAllowed ? <Link1Icon style={radixActionStyles} /> : <LinkBreak1Icon style={radixActionStyles} />,
      onClick: receivedBankTransaction.assignPaymentAllowed ? togglePaymentOrderModalOpen : showConfirmRemoveAssignment(runRemoveAssignemt),
    },
    {
      key: '3',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(runUnarchive) : showConfirmArchive(runArchive),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: receivedBankTransaction.status.value === ReceivedBankTransactionStatus.Assigned,
    },
    receivedBankTransaction.booked
      ? { ...receivedBankTransactionTableActionForBuchungDetails('4', receivedBankTransaction) }
      : {
          key: '4',
          label: 'Buchungsvorschlag bearbeiten',
          onClick: () => window.open(generatePathToBookingSuggestionBuchungsmaskeWithTabsPage(receivedBankTransaction.receivedBankTransactionId)),
          icon: <FormOutlined />,
        },
    {
      key: '5',
      label: 'Verbuchen',
      onClick: toggleBookModalOpen,
      icon: <ReadOutlined />,
      disabled: receivedBankTransaction.booked,
    },
  ];

  const onActionPaymentAssignment = () => {
    onAction();
    togglePaymentOrderModalOpen();
  };

  const onActionBook = () => {
    onAction();
    toggleBookModalOpen();
  };

  return (
    <>
      <ActionDropdown items={items} />
      <Modal
        title="Zahlungszuweisung hinzufügen"
        onCancel={togglePaymentOrderModalOpen}
        open={isPaymentOrderModalOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
        width={1680}
      >
        <PaymentAssignmentForm
          onAction={onActionPaymentAssignment}
          receivedBankTransaction={receivedBankTransaction}
          onCancel={togglePaymentOrderModalOpen}
        />
      </Modal>
      <Modal
        title="Empf. Bankbuchung verbuchen"
        onCancel={toggleBookModalOpen}
        open={isBookModalOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <BookForm
          onAction={onActionBook}
          onCancel={toggleBookModalOpen}
          receivedBankTransactionId={receivedBankTransaction.receivedBankTransactionId}
        />
      </Modal>
    </>
  );
};

const showConfirmRemoveAssignment = (runRemoveAssignment: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Zahlungszuweisung entfernen?`,
    okText: 'Entfernen',
    cancelText: 'Abbrechen',
    onOk() {
      return runRemoveAssignment();
    },
  });
};

const showConfirmUnarchive = (runUnarchive: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Empfangene Bankbuchung reaktivieren?`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

const showConfirmArchive = (runArchive: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Empfangene Bankbuchung archivieren?`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

export default ReceivedBankTransactionTableActions;
