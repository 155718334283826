import React from 'react';
import { ContactsOutlined, CopyOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { FileInfoType, SubAbrechnungEigentuemer } from '../../types';
import DocumentTab from '../../features/Document/DocumentTab';
import { DOCUMENT_FILTERS_SETTINGS } from '../../features/Document/Listing/Filters/listingFiltersHelper';
import { pathsToSubAbrechnungEigentuemerDetails } from '../../features/Abrechnung/Sub/subAbrechnungEigentumerUriPaths';
import SubAbrEigentuemerDetailsTabs from '../../features/Abrechnung/Sub/Details/Eigentuemer/SubAbrEigentuemerDetailsTabs';

const menuListSubAbrechnungEigentuemer = (
  objektId: string,
  subAbrechnungId: string,
  subAbrechnungEigentuemer: SubAbrechnungEigentuemer
): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Abrechnungen',
      icon: <ContactsOutlined />,
      uri: pathsToSubAbrechnungEigentuemerDetails(objektId, subAbrechnungId, subAbrechnungEigentuemer.subAbrechnungEigentuemerId).subAbr,
      content: () => (
        <SubAbrEigentuemerDetailsTabs objektId={objektId} subAbrechnungId={subAbrechnungId} subAbrechnungEigentuemer={subAbrechnungEigentuemer} />
      ),
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: pathsToSubAbrechnungEigentuemerDetails(objektId, subAbrechnungId, subAbrechnungEigentuemer.subAbrechnungEigentuemerId).documents,
      content: (props) => (
        <DocumentTab
          subAbrechnungEigentuemerId={subAbrechnungEigentuemer.subAbrechnungEigentuemerId}
          disabledFilters={DOCUMENT_FILTERS_SETTINGS.subAbrechnung.disabled}
          defaultFilters={DOCUMENT_FILTERS_SETTINGS.subAbrechnung.default}
          typeList={[FileInfoType.SubAbrechnungObjekt, FileInfoType.SubAbrechnungBestandseinheit]}
          {...props}
        />
      ),
    },
  ],
});

export default menuListSubAbrechnungEigentuemer;
