import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Spacer } from '../../../../components/Grid';
import { personPartValidationSchema } from '../../../../shared/components/Person/personFormValidationSchema';
import PersonFormPart from '../../../../shared/components/Person/PersonFormPart';
import NewEmailFormPart from '../../../../shared/components/Email/NewEmailFormPart';
import { validateEmailAsync, validateEmailSync } from '../../../../shared/components/Email/emailValidation';
import { newEmailValidationSchema } from '../../../../shared/components/Email/validationSchema';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgCreate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { mapFormValuesToMitarbeiterCreate, mitarbeiterCreateFormInitialValues, MitarbeiterCreateFormValues } from './mitarbeiterCreateFormMapper';
import MitarbeiterApi from '../../MitarbeiterApi';
import { useCreateMitarbeiterMutation } from '../../gql/MitarbeiterMutations.types';
import { MitarbeiterFragment } from '../../gql/MitarbeiterFragments.types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  kundenSystemId: string;
  firmendatenId: string;
  formValidationSchema?: Record<string, any>;
  onSuccess: () => void;
  onCancel: () => void;
};

const MitarbeiterCreateForm: FC<Props> = ({
  kundenSystemId,
  firmendatenId,
  formValidationSchema = personPartValidationSchema,
  onSuccess,
  onCancel,
}) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Mitarbeiter'>('Mitarbeiter');

  const [createMitarbeiter, { loading: loadingCreateMitarbeiter }] = useCreateMitarbeiterMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Mitarbeiter`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<MitarbeiterCreateFormValues>
      initialValues={mitarbeiterCreateFormInitialValues}
      validationSchema={formValidationSchema}
      validateOnChange={false}
      onSubmit={(formValues, formikHelpers) => {
        createMitarbeiter({
          variables: { firmendatenId, input: mapFormValuesToMitarbeiterCreate(formValues) },
        }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <NewEmailFormPart emailInputName="userEmail" emailInputPlaceholder="E-Mail-Adresse" fieldHelp={getFieldHelpText('Mitarbeiter.userEmail')} />
          <Spacer />
          <PersonFormPart
            kundenSystemId={kundenSystemId}
            fieldHelpNames={{
              firstname: getFieldHelpText('Mitarbeiter.firstname'),
              lastname: getFieldHelpText('Mitarbeiter.lastname'),
              salutation: getFieldHelpText('Mitarbeiter.salutation'),
              title: getFieldHelpText('Mitarbeiter.title'),
              titleTrailing: getFieldHelpText('Mitarbeiter.titleTrailing'),
            }}
          />
          <Spacer />
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loadingCreateMitarbeiter} />
        </Form>
      )}
    </Formik>
  );
};

// ---- unused Code ---- //
// ---- do not delete ---- //
// TODO: no prio, make on a friday
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validateEmailAndCheckIfEmailIsInUse = (email: string, kundenSystemId: string, firmendatenId: string, onCancel: () => void) => {
  const validationError = validateEmailSync(email, newEmailValidationSchema);
  if (validationError) {
    return validationError;
  }
  return validateEmailAsync(email).then(() =>
    getMitarbeiterListWithEmail(firmendatenId, email)
      .then((mitarbeiterList: MitarbeiterFragment[]) => renderMitarbeiterEmailErrorIfEmailIsInUse(mitarbeiterList, kundenSystemId, onCancel))
      .catch((error: Error) => console.error('An error occured during email existence checking.', error))
  );
};

const getMitarbeiterListWithEmail = (firmendatenId: string, email: string) => MitarbeiterApi.getMitarbeiterListWithUserDetails(firmendatenId, email);

const renderMitarbeiterEmailErrorIfEmailIsInUse = (mitarbeiterList: MitarbeiterFragment[], kundenSystemId: string, onCancel: () => void) => {
  if (!isEmpty(mitarbeiterList)) {
    const mitarbeiterUsingEmail = mitarbeiterList[0];
    return <MitarbeiterEmailAlreadyInUseError kundenSystemId={kundenSystemId} mitarbeiter={mitarbeiterUsingEmail} onCancel={onCancel} />;
  }
  return null;
};

// FIXME: onCancel
const MitarbeiterEmailAlreadyInUseError = (props: { mitarbeiter: MitarbeiterFragment; kundenSystemId: string; onCancel: () => void }) => (
  <>
    E-Mail-Adresse existiert schon für
    <Link
      to={{
        pathname: `/dashboard/systemmanagement/${props.kundenSystemId}/companydetails/${props.mitarbeiter.firmendatenId}/employee/${props.mitarbeiter.mitarbeiterId}`,
      }}
      onClick={props.onCancel}
    >
      {props.mitarbeiter.firstname} {props.mitarbeiter.lastname}
    </Link>
  </>
);

export default MitarbeiterCreateForm;
