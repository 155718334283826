import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { SLMZahlungsempfaenger } from './sepaLastschriftMandat-types';
import pdfStyles from '../styles/pdfStyles';
import SLMTwoColumns from './SLMTwoColumns';

type Props = {
  data: SLMZahlungsempfaenger;
};

const SLMZahlungsempfaengerBlock: FC<Props> = ({ data }) => {
  return (
    <View style={pdfStyles.column}>
      <Text
        style={[
          pdfStyles.textNormal,
          {
            marginTop: '10mm',
            fontWeight: 'bold',
          },
        ]}
      >
        {data.zahlungsempfaengerBlockTitle}
      </Text>
      <SLMTwoColumns label={data.name.label} value={data.name.value} />
      <SLMTwoColumns label={data.addressLine.label} value={data.addressLine.value} />
      <SLMTwoColumns label={data.zipCode.label} value={data.zipCode.value} />
      <SLMTwoColumns label={data.city.label} value={data.city.value} />
      <SLMTwoColumns label={data.sepaCreditorId.label} value={data.sepaCreditorId.value} />
    </View>
  );
};

export default SLMZahlungsempfaengerBlock;
