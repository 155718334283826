import { CreateKsFirmendatenInput } from '../../../../types';
import { mapFormValuesToAddress } from '../../../../shared/components/Address/form/addressMapper';
import { mapFormValuesToContactList } from '../../../../shared/components/Contact/contactMapper';
import { mapContactPersonListToFormValues } from '../Firmensitz/firmensitzMapper';

// firmendatenFormValues + mainOperatingSiteFormValues: any because wizard does not accept generic information yet
export const mapFirmendatenWizardValuesToFirmendaten = (firmendatenFormValues: any, firmensitzFormValues: any): CreateKsFirmendatenInput => ({
  ...firmendatenFormValues,
  hauptAddress: mapFormValuesToAddress(firmensitzFormValues.address),
  contactList: mapFormValuesToContactList(firmensitzFormValues.emailList, firmensitzFormValues.phoneList),
  contactPersonList: mapContactPersonListToFormValues(firmensitzFormValues.contactPersonList),
});
