import { EbicsUserIbanStatus, EbicsUserStatus, EbicsVersion } from '../../../types';

export const isEbicsUserStatusActive = (status: EbicsUserStatus) => status === EbicsUserStatus.Active;
export const isEbicsUserStatusInactive = (status: EbicsUserStatus) => status === EbicsUserStatus.Inactive;

export const isEbicsUserIbanStatusActive = (status: EbicsUserIbanStatus) => status === EbicsUserIbanStatus.Active;
export const isEbicsUserIbanStatusInactive = (status: EbicsUserIbanStatus) => status === EbicsUserIbanStatus.Inactive;

export const isEbicsVersionH003 = (version?: EbicsVersion | null) => version === EbicsVersion.H003;
export const isEbicsVersionH004 = (version?: EbicsVersion | null) => version === EbicsVersion.H004;
export const isEbicsVersionH005 = (version?: EbicsVersion | null) => version === EbicsVersion.H005;
