import React, { FC } from 'react';
import { Select } from 'formik-antd';
import { SelectProps } from 'antd';
import { useBeInfoFeldTypeListQuery } from '../gql/BeInfoFeldTypeQueries.types';

type BeInfoFeldTypeFormSelectProps = { name: string } & SelectProps;

const BeInfoFeldTypeFormSelect: FC<BeInfoFeldTypeFormSelectProps> = ({ name, ...restProps }) => {
  const { data, loading } = useBeInfoFeldTypeListQuery();

  const beInfoFeldTypeList = data?.getBeInfoFeldTypeList.data ?? [];

  return (
    <Select name={name} id={name} loading={loading} placeholder="Typ auswählen" allowClear style={{ minWidth: '160px' }} {...restProps}>
      {beInfoFeldTypeList.map((beInfoFeldType) => (
        <Select.Option value={beInfoFeldType.value} key={beInfoFeldType.value}>
          {beInfoFeldType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BeInfoFeldTypeFormSelect;
