import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVorschreibungspositionTemplateMutationVariables = Types.Exact<{
  input: Types.VorschreibungspositionCreateInput;
}>;

export type CreateVorschreibungspositionTemplateMutation = {
  createVorschreibungspositionTemplate: {
    data: { vorschreibungspositionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateVorschreibungspositionTemplateMutationVariables = Types.Exact<{
  input: Types.VorschreibungspositionUpdateInput;
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type UpdateVorschreibungspositionTemplateMutation = {
  updateVorschreibungspositionTemplate: {
    data: { vorschreibungspositionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteVorschreibungspositionTemplateMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type DeleteVorschreibungspositionTemplateMutation = {
  deleteVorschreibungspositionTemplate: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveVorschreibungspositionTemplateMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveVorschreibungspositionTemplateMutation = {
  actionUnarchiveVorschreibungsposition: {
    data: { vorschreibungspositionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveVorschreibungspositionTemplateMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type ArchiveVorschreibungspositionTemplateMutation = {
  actionArchiveVorschreibungspositionTemplate: {
    data: { vorschreibungspositionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateVorschreibungspositionTemplateDocument = gql`
  mutation CreateVorschreibungspositionTemplate($input: VorschreibungspositionCreateInput!) {
    createVorschreibungspositionTemplate(input: $input) {
      data {
        vorschreibungspositionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateVorschreibungspositionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVorschreibungspositionTemplateMutation, CreateVorschreibungspositionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVorschreibungspositionTemplateMutation, CreateVorschreibungspositionTemplateMutationVariables>(
    CreateVorschreibungspositionTemplateDocument,
    options
  );
}
export type CreateVorschreibungspositionTemplateMutationHookResult = ReturnType<typeof useCreateVorschreibungspositionTemplateMutation>;
export type CreateVorschreibungspositionTemplateMutationResult = Apollo.MutationResult<CreateVorschreibungspositionTemplateMutation>;
export type CreateVorschreibungspositionTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateVorschreibungspositionTemplateMutation,
  CreateVorschreibungspositionTemplateMutationVariables
>;
export const UpdateVorschreibungspositionTemplateDocument = gql`
  mutation UpdateVorschreibungspositionTemplate($input: VorschreibungspositionUpdateInput!, $vorschreibungspositionId: ID!) {
    updateVorschreibungspositionTemplate(input: $input, vorschreibungspositionId: $vorschreibungspositionId) {
      data {
        vorschreibungspositionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateVorschreibungspositionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVorschreibungspositionTemplateMutation, UpdateVorschreibungspositionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVorschreibungspositionTemplateMutation, UpdateVorschreibungspositionTemplateMutationVariables>(
    UpdateVorschreibungspositionTemplateDocument,
    options
  );
}
export type UpdateVorschreibungspositionTemplateMutationHookResult = ReturnType<typeof useUpdateVorschreibungspositionTemplateMutation>;
export type UpdateVorschreibungspositionTemplateMutationResult = Apollo.MutationResult<UpdateVorschreibungspositionTemplateMutation>;
export type UpdateVorschreibungspositionTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateVorschreibungspositionTemplateMutation,
  UpdateVorschreibungspositionTemplateMutationVariables
>;
export const DeleteVorschreibungspositionTemplateDocument = gql`
  mutation DeleteVorschreibungspositionTemplate($vorschreibungspositionId: ID!) {
    deleteVorschreibungspositionTemplate(vorschreibungspositionId: $vorschreibungspositionId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteVorschreibungspositionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVorschreibungspositionTemplateMutation, DeleteVorschreibungspositionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVorschreibungspositionTemplateMutation, DeleteVorschreibungspositionTemplateMutationVariables>(
    DeleteVorschreibungspositionTemplateDocument,
    options
  );
}
export type DeleteVorschreibungspositionTemplateMutationHookResult = ReturnType<typeof useDeleteVorschreibungspositionTemplateMutation>;
export type DeleteVorschreibungspositionTemplateMutationResult = Apollo.MutationResult<DeleteVorschreibungspositionTemplateMutation>;
export type DeleteVorschreibungspositionTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteVorschreibungspositionTemplateMutation,
  DeleteVorschreibungspositionTemplateMutationVariables
>;
export const UnarchiveVorschreibungspositionTemplateDocument = gql`
  mutation UnarchiveVorschreibungspositionTemplate($vorschreibungspositionId: ID!) {
    actionUnarchiveVorschreibungsposition(vorschreibungspositionId: $vorschreibungspositionId) {
      data {
        vorschreibungspositionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveVorschreibungspositionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveVorschreibungspositionTemplateMutation, UnarchiveVorschreibungspositionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveVorschreibungspositionTemplateMutation, UnarchiveVorschreibungspositionTemplateMutationVariables>(
    UnarchiveVorschreibungspositionTemplateDocument,
    options
  );
}
export type UnarchiveVorschreibungspositionTemplateMutationHookResult = ReturnType<typeof useUnarchiveVorschreibungspositionTemplateMutation>;
export type UnarchiveVorschreibungspositionTemplateMutationResult = Apollo.MutationResult<UnarchiveVorschreibungspositionTemplateMutation>;
export type UnarchiveVorschreibungspositionTemplateMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveVorschreibungspositionTemplateMutation,
  UnarchiveVorschreibungspositionTemplateMutationVariables
>;
export const ArchiveVorschreibungspositionTemplateDocument = gql`
  mutation ArchiveVorschreibungspositionTemplate($vorschreibungspositionId: ID!) {
    actionArchiveVorschreibungspositionTemplate(vorschreibungspositionId: $vorschreibungspositionId) {
      data {
        vorschreibungspositionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveVorschreibungspositionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveVorschreibungspositionTemplateMutation, ArchiveVorschreibungspositionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveVorschreibungspositionTemplateMutation, ArchiveVorschreibungspositionTemplateMutationVariables>(
    ArchiveVorschreibungspositionTemplateDocument,
    options
  );
}
export type ArchiveVorschreibungspositionTemplateMutationHookResult = ReturnType<typeof useArchiveVorschreibungspositionTemplateMutation>;
export type ArchiveVorschreibungspositionTemplateMutationResult = Apollo.MutationResult<ArchiveVorschreibungspositionTemplateMutation>;
export type ArchiveVorschreibungspositionTemplateMutationOptions = Apollo.BaseMutationOptions<
  ArchiveVorschreibungspositionTemplateMutation,
  ArchiveVorschreibungspositionTemplateMutationVariables
>;
