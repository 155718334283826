import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Space, Typography } from 'antd';
import { BookingInstructionFormValues } from '../bookingInstructionFormMapper';
import GeneralInfoBlock from './GeneralInfoBlock';

type Props = {
  firmendatenId: string;
  formikProps: FormikProps<BookingInstructionFormValues>;
};

const GeneralInfoWithButtonsBlock: FC<Props> = ({ firmendatenId, formikProps }) => {
  return (
    <>
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography.Title level={5}>Buchungsvorschlag</Typography.Title>
        {/*FIXME: This should be with buttons and alert for unsaved changes
                => add these once floating buttons are build in the Booking Suggestion and in Form on BookingDetails page*/}
        <>TODO: Buttons: Speichern und Abbrechen</>
      </Space>
      <GeneralInfoBlock firmendatenId={firmendatenId} formikProps={formikProps} />
    </>
  );
};

export default GeneralInfoWithButtonsBlock;
