import React, { FC } from 'react';
import { Empty } from 'antd';
import { IncomingInvoiceBookingPositionListEntry, IncomingInvoiceListEntry } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import bookingPositionTableColumns from './bookingPositionTableColumns';

type Props = {
  bookingPositionList: IncomingInvoiceBookingPositionListEntry[];
  incomingInvoice: IncomingInvoiceListEntry;
  buchungsdatum?: string | null;
};

const BookingPositionTable: FC<Props> = ({ bookingPositionList, incomingInvoice, buchungsdatum }) => (
  <NestedTable<IncomingInvoiceBookingPositionListEntry>
    level={3}
    colWidth={170}
    locale={{
      emptyText: <Empty description={<span>Keine Buchungspositionen vorhanden</span>} />,
    }}
    dataSource={bookingPositionList}
    columns={bookingPositionTableColumns(incomingInvoice, buchungsdatum)}
    rowKey={(record) => `${record.kontoId}-${record.buchungstext}}`}
  />
);

export default BookingPositionTable;
