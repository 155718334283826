import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { FibuRechtstraegerFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { KontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import { FibuAddressFragmentDoc } from '../../Address/gql/AddressFragments.types';
import { FibuObjektFragmentDoc } from '../../Objekt/gql/ObjektFragments.types';
export type MahnungFieldsFragment = {
  buchungId?: string | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  fileId?: string | null;
  freigegebenTs?: string | null;
  mahngebuehr: number;
  mahnstichtag: string;
  mahnungEmail?: string | null;
  mahnungId: string;
  opEmail: string;
  rechnungsAusstellerBankDetailsAccountHolder: string;
  rechnungsAusstellerBankDetailsIban: string;
  updatedByMitarbeiterId?: string | null;
  sumMahnbetrag: number;
  sumRechnungsbetrag: number;
  sumVerzugszinsen: number;
  sumZahlungen: number;
  versandEinschreiben: boolean;
  versendetTs?: string | null;
  verzugszinsen: number;
  buchungskreis: {
    buchungskreisId: string;
    rechtstraeger: {
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
    };
  };
  debitorKonto: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    haupt: boolean;
    kontoId: string;
    kontoTemplateId?: string | null;
    nummer: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    klasse: { text: string; value: Types.KontoKlasse };
    status: { description?: string | null; text: string; value: Types.KontoStatus };
    type: { text: string; value: Types.KontoType };
    verwendung?: { text: string; value: Types.KontoVerwendung } | null;
  };
  mahnPositionList: Array<{
    belegDatum: string;
    belegFileId: string;
    belegnummer: string;
    buchungskreisId: string;
    dataCarrierBelegId?: string | null;
    dueDate: string;
    gesamtBetrag: number;
    iban?: string | null;
    letzterMahnstichtag?: string | null;
    mahnPositionId: string;
    mahnstufe: number;
    offenerBetrag: number;
    statementNumber?: string | null;
    verzugszinsen: number;
    verzugszinsenBerechnen: boolean;
    zahlungBetrag: number;
    belegSymbol: { abbreviation: string; text: string; value: Types.FibuBelegSymbol };
  }>;
  mahnungDeliveryAddress?: {
    addressId: string;
    city: string;
    cityAdditionalInformation?: string | null;
    countryCodeIso2: string;
    houseEntranceApartmentNumber?: string | null;
    postofficeBoxNumber?: string | null;
    postofficeBoxZipCode?: string | null;
    street?: string | null;
    type: Types.AddressType;
    zipCode?: string | null;
  } | null;
  mailDelivery?: { mailId?: string | null; status: { text: string; value: Types.MahnungMailDeliveryStatus } } | null;
  objektList: Array<{ kurzBezeichnung: string; objektId: string }>;
  opDeliveryAddress: {
    addressId: string;
    city: string;
    cityAdditionalInformation?: string | null;
    countryCodeIso2: string;
    houseEntranceApartmentNumber?: string | null;
    postofficeBoxNumber?: string | null;
    postofficeBoxZipCode?: string | null;
    street?: string | null;
    type: Types.AddressType;
    zipCode?: string | null;
  };
  opZustellRechtstraeger: {
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
  };
  rechnungsAussteller: {
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
  };
  status: { value: Types.MahnungStatus; text: string };
  vertragspartner: {
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
  };
};

export const MahnungFieldsFragmentDoc = gql`
  fragment MahnungFields on Mahnung {
    buchungId
    buchungskreis {
      buchungskreisId
      rechtstraeger {
        ...FibuRechtstraeger
      }
    }
    createTs
    createdBy
    createdByMitarbeiterId
    debitorKonto {
      ...KontoFields
    }
    fileId
    freigegebenTs
    mahnPositionList {
      belegDatum
      belegFileId
      belegSymbol {
        abbreviation
        text
        value
      }
      belegnummer
      buchungskreisId
      dataCarrierBelegId
      dueDate
      gesamtBetrag
      iban
      letzterMahnstichtag
      mahnPositionId
      mahnstufe
      offenerBetrag
      statementNumber
      verzugszinsen
      verzugszinsenBerechnen
      zahlungBetrag
    }
    mahngebuehr
    mahnstichtag
    mahnungEmail
    mahnungId
    mahnungDeliveryAddress {
      ...FibuAddress
    }
    mailDelivery {
      mailId
      status {
        text
        value
      }
    }
    objektList {
      ...FibuObjekt
    }
    opEmail
    opDeliveryAddress {
      ...FibuAddress
    }
    opZustellRechtstraeger {
      ...FibuRechtstraeger
    }
    rechnungsAussteller {
      ...FibuRechtstraeger
    }
    rechnungsAusstellerBankDetailsAccountHolder
    rechnungsAusstellerBankDetailsIban
    status {
      value
      text
    }
    updatedByMitarbeiterId
    sumMahnbetrag
    sumRechnungsbetrag
    sumVerzugszinsen
    sumZahlungen
    versandEinschreiben
    versendetTs
    vertragspartner {
      ...FibuRechtstraeger
    }
    verzugszinsen
  }
  ${FibuRechtstraegerFragmentDoc}
  ${KontoFieldsFragmentDoc}
  ${FibuAddressFragmentDoc}
  ${FibuObjektFragmentDoc}
`;
