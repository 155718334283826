import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateKsFirmendatenMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  request: Types.CreateKsFirmendatenInput;
}>;

export type CreateKsFirmendatenMutation = {
  createKSFirmendaten: {
    data: { createTs: string; createdBy: string; createdByMitarbeiterId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateKsFirmendatenDocument = gql`
  mutation CreateKSFirmendaten($kundenSystemId: ID!, $request: CreateKSFirmendatenInput!) {
    createKSFirmendaten(kundenSystemId: $kundenSystemId, request: $request) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateKsFirmendatenMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateKsFirmendatenMutation, CreateKsFirmendatenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKsFirmendatenMutation, CreateKsFirmendatenMutationVariables>(CreateKsFirmendatenDocument, options);
}
export type CreateKsFirmendatenMutationHookResult = ReturnType<typeof useCreateKsFirmendatenMutation>;
export type CreateKsFirmendatenMutationResult = Apollo.MutationResult<CreateKsFirmendatenMutation>;
export type CreateKsFirmendatenMutationOptions = Apollo.BaseMutationOptions<CreateKsFirmendatenMutation, CreateKsFirmendatenMutationVariables>;
