import { TSubAdministrationAbrechnungObjekt } from '../templates/subAdministrationAbrechnung/Objekt/subAdministrationAbrechnungObjekt-types';

export const mockSubAdministrationAbrechnungObjekt: TSubAdministrationAbrechnungObjekt = {
  type: 'SUB_ABRECHNUNG_OBJEKT',
  einleitungstext: 'einleitungstext',
  ergebnistext: 'ergebnistext',
  schlusstext: 'schlusstext',
  absender: {
    label: 'Abs.: ',
    value: 'kurzbezeichnung_1, Grillgasse 46, 1110 Wien',
  },
  eigentuemer: {
    rechnungsEmpfaengerShippingLabel: {
      line1: 'Herr und Frau',
      line2: 'name 1',
      line3: 'name 2',
      line4: 'name 3',
      line5: 'Grillgasse 46',
      line6: '1110 Wien',
      line7: null,
    },
    kundennummer: {
      text: 'Kundennummer:',
      nummer: '101',
    },
  },
  abrechnungsDatumLine: 'Wien, am 25.03.2025',
  positionListHeader: {
    positionText: 'Position',
  },
  saldovortrag: {
    label: 'Saldovortrag',
    value: '0,00',
  },
  einnahmen: {
    positionName: 'Einnahmen',
    labelValueRequest: {
      label: 'Summe Einnahmen',
      value: '5.000,00',
    },
    abrKreisList: [
      {
        abrKreis: {
          label: 'Allg. Betriebskosten3',
          value: '5.000,00',
        },
        kontoList: [
          {
            konto: {
              label: 'sonstige BK',
              value: '5.000,00',
            },
            buchungszeileList: [],
          },
        ],
      },
    ],
  },
  ausgaben: {
    positionName: 'Ausgaben',
    labelValueRequest: {
      label: 'Summe Ausgaben',
      value: '1.000,00',
    },
    abrKreisList: [
      {
        abrKreis: {
          label: 'Allg. Betriebskosten2',
          value: '1.000,00',
        },
        kontoList: [
          {
            konto: {
              label: 'sonstige BK',
              value: '1.000,00',
            },
            buchungszeileList: [],
          },
          {
            konto: {
              label: 'sonstige BK',
              value: '0,00',
            },
            buchungszeileList: [],
          },
        ],
      },
    ],
  },
  vorsteuerkuerzung: {
    positionName: 'Vorsteuerkürzung',
    labelValueRequest: {
      label: 'Summe Vorsteuerkürzung',
      value: '0,00',
    },
    abrKreisList: [],
  },
  zwischensumme: {
    label: 'Zwischensumme',
    value: '4.000,00',
  },
  eigentuemerverrechnung: {
    positionName: 'Eigentümerverrechnung',
    kontoList: [
      {
        kontoName: 'sonstige BK',
        buchungszeileList: [],
      },
    ],
    summePosition: {
      label: 'Summe Eigentümerverrechnung',
      value: '500,00',
    },
  },
  abrechnungErgebnis: {
    label: 'Ergebnis der Abrechnung',
    value: '3.500,00',
  },
  endErgebnis: {
    label: 'Nachzahlung',
    value: '-500,00',
  },
  objektZahlungsrueckstaendeBlock: {
    zahlungsrueckstaendeListHeader: {
      bezeichnungText: 'Bezeichnung',
      topNummerText: 'Top',
      kontoNummerText: 'Kontonummer',
      letzteBuchungDateText: 'letzte Buchung am',
      saldoText: 'Saldobetrag',
      dueDateText: 'fällig am',
    },
    zahlungsrueckstaendeList: [
      {
        bezeichnung: 'persGem xOzyHCsdIF',
        topNummer: 'Top 1-2',
        kontoNummer: 'S57777',
        letzteBuchungDate: '25.03.2025',
        saldo: '4.000,00',
        dueDate: '01.01.2020',
      },
    ],
    zahlungsrueckstaendeSummary: {
      label: 'Endsumme',
      value: '4.000,00',
    },
  },
  informationAbrechnungskreiseBlock: {
    abrechnungskreisTableBezeichnungText: null,
    abrechnungskreisTableBelegDatumText: null,
    abrechnungskreisTableBelegNummerText: null,
    abrechnungskreisTableVertragspartnerText: null,
    abrechnungskreisList: [
      {
        type: 'INFORMATION',
        bezeichnung: 'Allg. Betriebskosten4',
        aufteilungsschluessel: null,
        summeNettoExpenseReducingText: null,
        summeNettoExpenseReducing: null,
        summeNettoAusgabenText: 'Summe Ausgaben',
        summeNettoAusgaben: '0,00',
        summeNettoEinnahmenText: 'Summe Einnahmen',
        summeNettoEinnahmen: '80,78',
        ergebnisNettoText: 'Ergebnis: Saldo',
        ergebnisNetto: '-80,78',
        buchungEinnahmenListText: 'Einnahmen',
        saldoVortragText: 'Vorsaldo:',
        saldoVortrag: '0,00',
        ausgabenDetailsTitleText: null,
        kontoList: [],
        buchungEinnahmenList: [
          {
            bezeichnung: '2025/03',
            betragNetto: '80,78',
          },
        ],
        buchungUstVomAufwandList: [],
      },
    ],
  },
};
