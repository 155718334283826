import React, { FC, useEffect } from 'react';
import { WithWizard } from 'react-albus';
import { FormikProps } from 'formik';
import { isFirstStep, isLastStep } from './wizardHelper';
import FormButtons from '../Button/FormButtons';

type WizardNavigationProps = {
  formikProps: FormikProps<Record<string, unknown>>;
  onCancel: WizardNavigationOnCancel;
  onPrevious: () => void;
  error?: Error;
};

export type WizardNavigationOnCancel = () => void;

const WizardNavigation: FC<WizardNavigationProps> = ({ formikProps, onCancel, onPrevious, error }) => {
  const { setSubmitting } = formikProps;

  useEffect(() => {
    setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <WithWizard
      render={({ previous, step, steps }) => (
        <>
          <FormButtons
            onCancel={() => {
              if (isFirstStep(steps, step)) {
                onCancel();
              } else {
                onPrevious();
                previous();
              }
            }}
            cancelText={isFirstStep(steps, step) ? 'Abbrechen' : 'Zurück'}
            updateMode={false}
            okText={isLastStep(steps, step) ? 'Absenden' : 'Weiter'}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={formikProps.isSubmitting}
            isCancelDisabled={formikProps.isSubmitting}
          />
        </>
      )}
    />
  );
};

export default WizardNavigation;
