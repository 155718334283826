import { Kontenplan, Konto, KontoVerwendung } from '../../../../types';
import {
  isKontoKlasseLieferverbindlichkeit,
  isKontoKlasseUmlaufVermoegen,
  isKontoTypeSachkonto,
  isKontoVerwendungUmsatzsteuer,
  isKontoVerwendungVorsteuer,
} from '../../Form/kontoFormHelpers';

export const canBeHauptKonto = (konto: Konto): boolean => {
  return (
    isKontoTypeSachkonto(konto.type.value) &&
    !!konto.verwendung &&
    ((isKontoKlasseLieferverbindlichkeit(konto.klasse.value) && isKontoVerwendungUmsatzsteuer(konto.verwendung.value)) ||
      (isKontoKlasseUmlaufVermoegen(konto.klasse.value) && isKontoVerwendungVorsteuer(konto.verwendung.value)))
  );
};

export const doesHauptKontoExist = (kontenplan?: Kontenplan, kontoVerwendung?: KontoVerwendung) => {
  if (kontenplan) {
    if (kontoVerwendung === KontoVerwendung.Umsatzsteuer) {
      return !!kontenplan.hauptUmsatzsteuerKonto;
    } else if (kontoVerwendung === KontoVerwendung.Vorsteuer) {
      return !!kontenplan.hauptVorsteuerKonto;
    }
  }
  return false;
};
