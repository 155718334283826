import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PostItFragmentDoc } from '../../Rechtstraeger/PostIt/gql/PostItFragments.types';
import { RechtstraegerFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { MitarbeiterFragmentDoc } from '../../Mitarbeiter/gql/MitarbeiterFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
export type ObjektListEntryFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  erstelltAm: string;
  fotoFileId?: string | null;
  fotoType?: Types.FotoType | null;
  kurzBezeichnung: string;
  objektId: string;
  rechnungsAusstellerId: string;
  verwaltungBis?: string | null;
  status: { text: string; value: Types.ObjektStatus; description?: string | null };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type ObjektFragment = {
  countryCodeIso2: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fotoFileId?: string | null;
  fotoType?: Types.FotoType | null;
  kurzBezeichnung: string;
  objektId: string;
  rechnungsAusstellerBezeichnung?: string | null;
  rechnungsAusstellerChangeable?: boolean | null;
  subAdministration: boolean;
  sumNutzflaeche: number;
  sumNutzwert: number;
  tagList: Array<string>;
  updatedByMitarbeiterId?: string | null;
  verwaltungBis?: string | null;
  verwaltungSeit: string;
  verwaltungsartChangeable?: boolean | null;
  addressList: Array<{
    addressId: string;
    city: string;
    cityAdditionalInformation?: string | null;
    countryCodeIso2: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    houseEntranceApartmentNumber?: string | null;
    postofficeBoxNumber?: string | null;
    postofficeBoxZipCode?: string | null;
    street?: string | null;
    tagList: Array<string>;
    type: Types.AddressType;
    zipCode?: string | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  objektSummeAnteile?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    current: boolean;
    kommentar?: string | null;
    lastUpdateTs: string;
    objektSummeAnteileId: string;
    summeAnteile: number;
    validFrom: string;
    historicizedList?: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      kommentar?: string | null;
      lastUpdateTs: string;
      objektSummeAnteileId: string;
      summeAnteile: number;
      validFrom: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }> | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  rechnungsAussteller: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    fibuKontoCreatable: boolean;
    kundennummer: string;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    bankDetailsList: Array<{
      accountHolder: string;
      accountLimit?: number | null;
      assignedHeVertragZahlungsregelMap: any;
      assignedObjektMap: any;
      bankDetailsId: string;
      bankname: string;
      bic: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      ebicsCreditTransferUserList: Array<string>;
      ebicsDirectDebitUserList: Array<string>;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      updatedByMitarbeiterId?: string | null;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
      fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
      sepaMandat?: {
        fileId?: string | null;
        fileName?: string | null;
        sepaMandatId: string;
        sepaMandatReference: string;
        signatureDate?: string | null;
        firmendaten?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
        warningList: Array<{ message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    personenbezugList: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      personenbezugId: string;
      tagList: Array<string>;
      person: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    postIt?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      immerAnzeigen: boolean;
      postItId: string;
      text: string;
      titel: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    rechtstraegerSteuerpflicht?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      rechtstraegerSteuerpflichtId: string;
      validFrom: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ message: string; type: string }>;
      }> | null;
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
    sprache: { text: string; value: Types.Sprache };
    status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
    type: { value: Types.RechtstraegerType; text: string };
    vertragSummary?: {
      heVertragCount: number;
      heVertragVerwaltungCount: number;
      mietVertragCount: number;
      mietVertragVerwaltungCount: number;
      weSubverwaltungCount: number;
      weVertragCount: number;
      weVertragVerwaltungCount: number;
    } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  sachbearbeiter?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    fileStorageInitialized: boolean;
    firmendatenId: string;
    firstname: string;
    funktionsModulList: Array<string>;
    lastname: string;
    mitarbeiterId: string;
    title?: string | null;
    titleTrailing?: string | null;
    username: string;
    contactPerson?: {
      contactPersonId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      firstname: string;
      lastname: string;
      tagList: Array<string>;
      title?: string | null;
      titleTrailing?: string | null;
      contactList: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      rechtstraeger?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    rolleList: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      name: string;
      rolleId: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
    status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
    user?: {
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: string;
      username: string;
      status: { text: string; value: string };
    } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.ObjektStatus; description?: string | null };
  verwaltungsart: { text: string; value: Types.Verwaltungsart };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type OrderObjektFragment = { kurzBezeichnung: string; objektId: string };

export type FibuObjektFragment = { kurzBezeichnung: string; objektId: string };

export type PaymentObjektFragment = { kurzBezeichnung: string; objektId: string };

export type ObjektBaseFragment = {
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  kurzBezeichnung: string;
  objektId: string;
  updatedByMitarbeiterId?: string | null;
  rechnungsAussteller: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  status: { text: string; value: Types.ObjektStatus; description?: string | null };
  verwaltungsart: { text: string; value: Types.Verwaltungsart };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const ObjektListEntryFragmentDoc = gql`
  fragment ObjektListEntry on ObjektListEntry {
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    erstelltAm
    fotoFileId
    fotoType
    kurzBezeichnung
    objektId
    rechnungsAusstellerId
    status {
      text
      value
      description
    }
    verwaltungBis
    warningList {
      attribute
      message
      type
    }
  }
`;
export const ObjektFragmentDoc = gql`
  fragment Objekt on Objekt {
    addressList {
      addressId
      city
      cityAdditionalInformation
      countryCodeIso2
      createTs
      createdBy
      createdByMitarbeiterId
      haupt
      houseEntranceApartmentNumber
      postofficeBoxNumber
      postofficeBoxZipCode
      street
      tagList
      type
      warningList {
        attribute
        message
        type
      }
      zipCode
    }
    countryCodeIso2
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    fotoFileId
    fotoType
    kurzBezeichnung
    objektId
    objektSummeAnteile {
      createTs
      createdBy
      createdByMitarbeiterId
      current
      historicizedList {
        createTs
        createdBy
        createdByMitarbeiterId
        current
        kommentar
        lastUpdateTs
        objektSummeAnteileId
        summeAnteile
        validFrom
        warningList {
          attribute
          message
          type
        }
      }
      kommentar
      lastUpdateTs
      objektSummeAnteileId
      summeAnteile
      validFrom
      warningList {
        attribute
        message
        type
      }
    }
    postIt {
      ...PostIt
    }
    rechnungsAussteller {
      ...RechtstraegerFields
    }
    rechnungsAusstellerBezeichnung
    rechnungsAusstellerChangeable
    sachbearbeiter {
      ...Mitarbeiter
    }
    status {
      text
      value
      description
    }
    subAdministration
    sumNutzflaeche
    sumNutzwert
    tagList
    updatedByMitarbeiterId
    verwaltungBis
    verwaltungSeit
    verwaltungsart {
      text
      value
    }
    verwaltungsartChangeable
    warningList {
      attribute
      message
      type
    }
  }
  ${PostItFragmentDoc}
  ${RechtstraegerFieldsFragmentDoc}
  ${MitarbeiterFragmentDoc}
`;
export const OrderObjektFragmentDoc = gql`
  fragment OrderObjekt on OrderObjekt {
    kurzBezeichnung
    objektId
  }
`;
export const FibuObjektFragmentDoc = gql`
  fragment FibuObjekt on FibuObjekt {
    kurzBezeichnung
    objektId
  }
`;
export const PaymentObjektFragmentDoc = gql`
  fragment PaymentObjekt on PaymentObjekt {
    kurzBezeichnung
    objektId
  }
`;
export const ObjektBaseFragmentDoc = gql`
  fragment ObjektBase on ObjektBase {
    createTs
    createdBy
    createdByMitarbeiterId
    kurzBezeichnung
    objektId
    rechnungsAussteller {
      ...RechtstraegerBaseFields
    }
    status {
      text
      value
      description
    }
    updatedByMitarbeiterId
    verwaltungsart {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
