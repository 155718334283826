import React, { FC } from 'react';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = {
  ebicsUserList: string[];
  maxTextLength?: number;
};

const EbicsUserListColumn: FC<Props> = ({ ebicsUserList, maxTextLength }) => {
  if (!ebicsUserList.length) return <TextForEmptyValue textToShow="minus" />;

  const firstEbicsUser = ebicsUserList[0] as string;
  const remainingEbicsUserList = ebicsUserList.slice(1);

  return <DataWithShortenedText maxTextLength={maxTextLength ?? firstEbicsUser.length} text={firstEbicsUser} extraTexts={remainingEbicsUserList} />;
};

export default EbicsUserListColumn;
