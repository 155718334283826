import React, { FC, PropsWithChildren } from 'react';
import { Col, Row, Typography } from 'antd';

type Props = {
  infoRowTitle: string;
};

const VersandCardInfoRow: FC<Props & PropsWithChildren> = ({ infoRowTitle, children }) => (
  <Row style={{ width: '100%' }}>
    <Col span={10}>
      <Typography.Text type="secondary">{infoRowTitle}</Typography.Text>
    </Col>
    <Col span={14}>{children}</Col>
  </Row>
);

export default VersandCardInfoRow;
