import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOrderFieldHelpMutationVariables = Types.Exact<{
  fieldHelpId: Types.Scalars['ID']['input'];
  input: Types.FieldHelpUpdateInput;
}>;

export type UpdateOrderFieldHelpMutation = {
  updateOrderFieldHelp: {
    data: { deprecated: boolean; fieldHelp?: string | null; fieldHelpId: string; selector: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteOrderFieldHelpMutationVariables = Types.Exact<{
  fieldHelpId: Types.Scalars['ID']['input'];
}>;

export type DeleteOrderFieldHelpMutation = {
  deleteOrderFieldHelp: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const UpdateOrderFieldHelpDocument = gql`
  mutation updateOrderFieldHelp($fieldHelpId: ID!, $input: FieldHelpUpdateInput!) {
    updateOrderFieldHelp(fieldHelpId: $fieldHelpId, input: $input) {
      data {
        deprecated
        fieldHelp
        fieldHelpId
        selector
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateOrderFieldHelpMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrderFieldHelpMutation, UpdateOrderFieldHelpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderFieldHelpMutation, UpdateOrderFieldHelpMutationVariables>(UpdateOrderFieldHelpDocument, options);
}
export type UpdateOrderFieldHelpMutationHookResult = ReturnType<typeof useUpdateOrderFieldHelpMutation>;
export type UpdateOrderFieldHelpMutationResult = Apollo.MutationResult<UpdateOrderFieldHelpMutation>;
export type UpdateOrderFieldHelpMutationOptions = Apollo.BaseMutationOptions<UpdateOrderFieldHelpMutation, UpdateOrderFieldHelpMutationVariables>;
export const DeleteOrderFieldHelpDocument = gql`
  mutation deleteOrderFieldHelp($fieldHelpId: ID!) {
    deleteOrderFieldHelp(fieldHelpId: $fieldHelpId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteOrderFieldHelpMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOrderFieldHelpMutation, DeleteOrderFieldHelpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOrderFieldHelpMutation, DeleteOrderFieldHelpMutationVariables>(DeleteOrderFieldHelpDocument, options);
}
export type DeleteOrderFieldHelpMutationHookResult = ReturnType<typeof useDeleteOrderFieldHelpMutation>;
export type DeleteOrderFieldHelpMutationResult = Apollo.MutationResult<DeleteOrderFieldHelpMutation>;
export type DeleteOrderFieldHelpMutationOptions = Apollo.BaseMutationOptions<DeleteOrderFieldHelpMutation, DeleteOrderFieldHelpMutationVariables>;
