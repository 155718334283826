import { FormikProps } from 'formik';
import { updateStreetAddress } from '../../../../shared/components/Address/form/streetAddressFormPartHelper';
import { getFirmennameFields, updateFirmenname } from '../../../../shared/components/Firmenname/form/firmennameFormPartHelper';
import { getLegalFormFields } from '../../../../shared/components/LegalFormSelect/legalFormSelectHelper';
import { URL_KURZBEZEICHNUNG_VORSCHLAG_FIRMA } from '../../../../shared/api/ApiPaths';
import getKurzBezeichnungVorschlagText from '../../../../shared/api/KurzBezeichnungVorschlagApi';
import { FirmaFormValues } from './firmaFormMapper';

export const FIELDNAME_PREFIX_MAIN_OPERATING_SITE_ADDRESS = 'hauptAddress.';

export const getKurzBezeichnungVorschlagFieldsToBeDebounced = (formikProps: FormikProps<FirmaFormValues>) => ({
  ...getFirmennameFields(formikProps),
});

export const getKurzBezeichnungVorschlagFieldsNotToBeDebounced = (formikProps: FormikProps<FirmaFormValues>) => ({
  ...getLegalFormFields(formikProps),
});

export const getFirmaKurzBezeichnungVorschlag = (kurzBezeichnungVorschlagFields: FirmaFormValues) =>
  getKurzBezeichnungVorschlagText(URL_KURZBEZEICHNUNG_VORSCHLAG_FIRMA, kurzBezeichnungVorschlagFields);

export const updateFirmennameAndAddress = (formikProps: FormikProps<FirmaFormValues>) => (data: any) => {
  updateFirmenname(formikProps, data);
  updateStreetAddress(formikProps, FIELDNAME_PREFIX_MAIN_OPERATING_SITE_ADDRESS, data);
};
