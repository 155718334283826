import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { DatePicker } from 'formik-antd';
import { notificationListingFiltersFormFields } from './notificationListingFiltersFormMapper';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';

const Erstelldatum: FC = () => (
  <FormItemWithoutColon name="Erstelldatum" label="Erstelldatum">
    <Space size="small">
      <DatePicker
        id={notificationListingFiltersFormFields.createTsVonInklusive}
        name={notificationListingFiltersFormFields.createTsVonInklusive}
        placeholder="Datum wählen"
        format={DATE_DISPLAY_FORMAT_DEFAULT}
        size="small"
        style={{ width: '122px' }}
      />
      <Typography.Text>-</Typography.Text>
      <DatePicker
        id={notificationListingFiltersFormFields.createTsBisInklusive}
        name={notificationListingFiltersFormFields.createTsBisInklusive}
        placeholder="Datum wählen"
        format={DATE_DISPLAY_FORMAT_DEFAULT}
        size="small"
        style={{ width: '122px' }}
      />
    </Space>
  </FormItemWithoutColon>
);

export default Erstelldatum;
