import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import { IStatus } from '../../../helpers/statusHelper';
import RechtstraegerWithContactsTooltip from '../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

type Props = {
  rechtstraegerId: string;
  status: IStatus;
  kurzBezeichnung: string;
  warningList?: React.ReactNode;
};
const VertragspartnerCardDetailsInfoRow: FC<Props> = ({ rechtstraegerId, status, kurzBezeichnung, warningList }) => {
  return (
    <CardDetailsInfoRow
      infoRowTitle={
        <Space direction="vertical">
          <Typography.Text type="secondary">Vertragspartner</Typography.Text>
          {warningList}
        </Space>
      }
    >
      <RechtstraegerWithContactsTooltip rechtstraegerName={kurzBezeichnung} maxTextLength={22} rechtstraegerId={rechtstraegerId} status={status} />
    </CardDetailsInfoRow>
  );
};

export default VertragspartnerCardDetailsInfoRow;
