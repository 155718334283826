import { generatePath } from 'react-router-dom';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { URI_ZINSLISTE_PAGE } from '../../zinslisteUriPaths';
import { ZinslisteListingFiltersFormValues } from './zinslisteListingFiltersFormMapper';

enum ZinslisteQueryParamKey {
  VORSCHREIBUNGSPERIODE_FROM = 'vorschreibungsperiodeFrom',
  VORSCHREIBUNGSPERIODE_TO = 'vorschreibungsperiodeTo',
  ERSTELLER_LIST = 'erstellerList',
  OBJEKT_ID = 'objektId',
  ERSTELL_DATUM_VON_INKLUSIVE = 'erstellDatumVonInklusive',
  ERSTELL_DATUM_BIS_INKLUSIVE = 'erstellDatumBisInklusive',
  BRUTTO_FROM = 'bruttoFrom',
  BRUTTO_TO = 'bruttoTo',
  NETTO_FROM = 'nettoFrom',
  NETTO_TO = 'nettoTo',
  STATUS = 'status',
}

export type TZinslisteQueryParams = QueryParams<ZinslisteListingFiltersFormValues>;

export const updateQueryParams = (navigate: (path: string) => void, filters: TZinslisteQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  navigate(generatePathToZinslistePage(filters, paginationParams));

const generatePathToZinslistePage = (filters?: TZinslisteQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_ZINSLISTE_PAGE)}?${mapToQueryString({
    [ZinslisteQueryParamKey.VORSCHREIBUNGSPERIODE_FROM]: filters?.vorschreibungsperiodeFrom,
    [ZinslisteQueryParamKey.VORSCHREIBUNGSPERIODE_TO]: filters?.vorschreibungsperiodeTo,
    [ZinslisteQueryParamKey.ERSTELLER_LIST]: filters?.erstellerList,
    [ZinslisteQueryParamKey.OBJEKT_ID]: filters?.objektId,
    [ZinslisteQueryParamKey.ERSTELL_DATUM_VON_INKLUSIVE]: filters?.erstellDatumVonInklusive,
    [ZinslisteQueryParamKey.ERSTELL_DATUM_BIS_INKLUSIVE]: filters?.erstellDatumBisInklusive,
    [ZinslisteQueryParamKey.BRUTTO_FROM]: filters?.bruttoFrom,
    [ZinslisteQueryParamKey.BRUTTO_TO]: filters?.bruttoTo,
    [ZinslisteQueryParamKey.NETTO_FROM]: filters?.nettoFrom,
    [ZinslisteQueryParamKey.NETTO_TO]: filters?.nettoTo,
    [ZinslisteQueryParamKey.STATUS]: filters?.status,
    ...paginationParams,
  })}`;
