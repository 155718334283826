import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import GenerierlaufEntryStatus from '../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { VertragVposIndexedValueGenerierlaufEntry, VertragVposIndexedValueGenerierlaufOrderBy } from '../../../../types';
import { generatePathToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../../Rechtstraeger/rechtstraegerHelper';
import {
  generatePathToBeVertragDetailsPage,
  generatePathToBeVertragDetailsPageVertragsdatenVPosIndexationAgreementTab,
  generatePathToBeVertragDetailsPageVertragsdatenVPosTab,
  pathsToBeVertragDetailsPage,
} from '../../../Vertrag/BeVertrag/beVertragUriPaths';
import { EuroAmount, Percent } from '../../../../components/Number';
import ValuePreservationSettings from '../../../shared/ValuePreservation/ValuePreservationSettings/ValuePreservationSettings';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';
import { getStatusTagColor } from '../../../IndexedValue/shared/indexedValueHelpers';
import { generatePathToFirmendatenIndexSeriesDetailsPage } from '../../../Firmendaten/IndexSeries/firmendatenIndexSeriesUriPaths';
import { generatePathToIndexedValueListingPage } from '../../../IndexedValue/indexedValueUriPaths';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

const entryTableColumns: TableWithColSelectorColumnProps<VertragVposIndexedValueGenerierlaufEntry>[] = [
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.Objekt,
    sorter: true,
    render: (text, record) =>
      record.objektKurzBezeichnung ? (
        <DataWithShortenedText maxTextLength={20} text={record.objektKurzBezeichnung}>
          {(shortenedText) => (
            <Link to={generatePathToObjektDetailsPage(record.objektId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Bestandseinheit',
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.Bestandseinheit,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.bestandseinheitBezeichnung}>
        {(shortenedText) => (
          <Link to={generatePathToBestandseinheitDetailsPage(record.objektId, record.bestandseinheitId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Vertrag',
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.Vertrag,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.vertragBezeichnung}>
        {(shortenedText) => (
          <Link to={generatePathToBeVertragDetailsPage(record.objektId, record.bestandseinheitId, record.vertragId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Rechnungsaussteller',
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.RechnungsAussteller,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.rechnungsAusstellerBezeichnung}>
        {(shortenedText) => (
          <Link to={generatePathToRechtstraegerDetailsPage(record.rechnungsAusstellerId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Vertragspartner',
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.Vertragspartner,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.vertragspartnerKurzBezeichnung}>
        {(shortenedText) => (
          <Link to={generatePathToRechtstraegerDetailsPage(record.vertragspartnerId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Indexierter Wert',
    defaultSelected: true,
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexedValue,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? <Typography.Text>{record.indexedValue.name}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Werststatus',
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.WertStatus,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? (
        <Tag color={getStatusTagColor(record.indexedValue.status.value)}>{record.indexedValue.status.text}</Tag>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'VPos Gültig ab',
    defaultSelected: true,
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.ValidFrom,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? <CustomFormattedDate value={record.indexedValue.validFrom} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Wertsicherungsart',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.valuePreservationType.value, b.valuePreservationType.value),
    // TODO: Link
    render: (text, record) => (
      <Link
        to={pathsToBeVertragDetailsPage(record.objektId, record.bestandseinheitId, record.vertragId).vertragsdatenIndexationAgreementTab}
        target="_blank"
      >
        {record.valuePreservationType.text}
      </Link>
    ),
  },
  {
    title: 'Indexreihe',
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexSeries,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.indexSeriesName}>
        {(shortenedText) => (
          <Link to={generatePathToFirmendatenIndexSeriesDetailsPage(record.indexSeriesId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Wertsicherungseinstellungen',
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexationAgreement,
    sorter: true,
    render: (text, record) =>
      record.indexedValue?.indexationAgreement ? (
        <ValuePreservationSettings indexationAgreement={record.indexedValue?.indexationAgreement} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Mon./Jahr alt',
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.YearMonthOld,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? <Typography.Text>{record.indexedValue.monthYearOld}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Index alt',
    defaultSelected: true,
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexOld,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? <FormattedNumber value={record.indexedValue.indexOld} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Vorläufig Index alt',
    width: 120,
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexOldPreliminary,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? (
        <Typography.Text>{record.indexedValue.indexOldPreliminary ? 'Ja' : 'Nein'}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Mon./Jahr neu',
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.YearMonthNew,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? <Typography.Text>{record.indexedValue.monthYearNew}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Index neu',
    defaultSelected: true,
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexNew,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? <FormattedNumber value={record.indexedValue.indexNew} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Vorläufig Index neu',
    width: 120,
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexNewPreliminary,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? (
        <Typography.Text>{record.indexedValue.indexNewPreliminary ? 'Ja' : 'Nein'}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Errechneter Faktor (%)',
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.CalculatedFactorNew,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? <FormattedNumber value={record.indexedValue.calculatedFactorNew} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Faktor (%)',
    defaultSelected: true,
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.FactorNew,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? <Percent value={record.indexedValue.calculatedFactorNewPercent / 100} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Betrag alt',
    defaultSelected: true,
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.AmountOld,
    sorter: true,
    render: (text, record) => {
      if (!record.indexedValue) {
        return <TextForEmptyValue textToShow="minus" />;
      }

      return record.indexedValue.vposWertOldId ? (
        <Link
          to={generatePathToBeVertragDetailsPageVertragsdatenVPosTab(
            record.objektId,
            record.bestandseinheitId,
            record.vertragId,
            record.indexedValue?.vposWertOldId,
            [record.indexedValue.vorschreibungspositionId]
          )}
          target="_blank"
        >
          <EuroAmount value={record.indexedValue.amountOld} />
        </Link>
      ) : (
        <EuroAmount value={record.indexedValue.amountOld} />
      );
    },
  },
  {
    title: 'Errechneter Betrag neu',
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.CalculatedAmountNew,
    sorter: true,
    render: (text, record) =>
      record.indexedValue ? <EuroAmount value={record.indexedValue.calculatedAmountNew} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Betrag neu',
    defaultSelected: true,
    dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.AmountNew,
    sorter: true,
    render: (text, record) => {
      if (!record.indexedValue) {
        return <TextForEmptyValue textToShow="minus" />;
      }

      return record.indexedValue.vposWertNewId ? (
        <Link
          to={generatePathToBeVertragDetailsPageVertragsdatenVPosTab(
            record.objektId,
            record.bestandseinheitId,
            record.vertragId,
            record.indexedValue?.vposWertNewId,
            [record.indexedValue.vorschreibungspositionId]
          )}
          target="_blank"
        >
          <EuroAmount value={record.indexedValue.amountNew} />
        </Link>
      ) : (
        <EuroAmount value={record.indexedValue.amountNew} />
      );
    },
  },
  {
    title: 'Ersteller',
    render: (text, record) =>
      record.indexedValue ? (
        <MitarbeiterTooltip
          mitarbeiterId={record.indexedValue.createdByMitarbeiterId}
          userId={record.indexedValue.createdBy}
          alignment="left"
          showsInitials
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => {
      const items = [
        {
          key: '1',
          label: 'Wert anzeigen',
          onClick: () =>
            window.open(
              generatePathToIndexedValueListingPage({
                objektId: record.objektId,
                vertragspartnerId: record.vertragspartnerId,
                valuePreservationTypeList: [record.valuePreservationType.value],
                validFrom: record.indexedValue?.validFrom,
                setDefaultStatusOnInit: false,
              }),
              '_blank'
            ),
        },
        {
          key: '2',
          label: 'WS-Vereinbarung anzeigen',
          onClick: () =>
            window.open(
              generatePathToBeVertragDetailsPageVertragsdatenVPosIndexationAgreementTab(
                record.objektId,
                record.bestandseinheitId,
                record.vertragId,
                record.indexationAgreementId,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                [record.indexedValue!.vorschreibungspositionId]
              ),
              '_blank'
            ),
          disabled: !record.indexedValue,
        },
      ];
      return <ActionDropdown items={items} />;
    },
  },
];

export default entryTableColumns;
