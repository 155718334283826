import { FC } from 'react';
import dayjs from 'dayjs';
import { AuszifferungFormValues } from '../../auszifferungFormMapper';
import { EuroAmount } from '../../../../../../components/Number';
import { getFormattedDecimalValueOrZero } from '../../../../../../components/Input-antd/formattedDecimalHelper';
import { BookingSuggestionFormValues, OffenePostenForClearingSuggestion } from '../../../Form/buchungsmaskeFormMapper';
import { getOffenePostenIndexFromMainOPList } from '../../../Form/buchungsmaskeFormHelpers';
import { isExistingAuszifferung } from '../../bookingSuggestionAuszifferungBlockHelpers';

type Props = {
  auszifferung: AuszifferungFormValues;
  formikValues: BookingSuggestionFormValues;
  isOPForPersonenkonto: boolean;
  offenePosten: OffenePostenForClearingSuggestion;
};

const SaldoOffenerBetragColumn: FC<Props> = ({ auszifferung, formikValues, isOPForPersonenkonto, offenePosten }) => {
  let auszifferungList = offenePosten.auszifferungList;

  if (isOPForPersonenkonto) {
    const opIndex = getOffenePostenIndexFromMainOPList(formikValues, offenePosten);
    if (opIndex >= 0) {
      auszifferungList = formikValues.offenePostenForClearingSuggestionList[opIndex].auszifferungList;
    }
  }

  const calculatedAmount = calculateSaldoOffenerBetrag(offenePosten.gesamtBetrag, auszifferungList, auszifferung);

  return <EuroAmount value={calculatedAmount} />;
};

export default SaldoOffenerBetragColumn;

const calculateSaldoOffenerBetrag = (opGesamtBetrag: number, opAuszifferungList: AuszifferungFormValues[], auszifferung: AuszifferungFormValues) => {
  const opAzList = isExistingAuszifferung(auszifferung)
    ? // for the existing (booked) auszifferung entries - we need to exclude the new auszifferung as it is an editable Auszifferung suggestion as it might be created before some of the bookend (existing) auszifferung entries
      // need to use dayjs instead of dayjsCustom in order to ensure correct date comparison
      opAuszifferungList.filter((az) => isExistingAuszifferung(az) && dayjs(az.createTs).isSameOrBefore(dayjs(auszifferung.createTs)))
    : opAuszifferungList;

  const sumOpAzList = opAzList.reduce((acc, currVal, currentIndex) => {
    return acc + getFormattedDecimalValueOrZero(currVal.amount);
  }, 0);

  return opGesamtBetrag - sumOpAzList;
};
