import {
  IncomingInvoiceBookingCreationGenerierlaufCreateInput,
  IncomingInvoiceFilterStatus,
  PaymentMethod,
  QueryGetIncomingInvoiceListArgs,
  Verrechnungsart,
} from '../../../../types';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { TIncomingInvoiceQueryParams } from './filtersQueryParams';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';

export type FiltersFormValues = {
  billingType?: Verrechnungsart | null;
  bruttoFrom?: number | null;
  bruttoTo?: number | null;
  buchungsdatumFrom?: string | null;
  buchungsdatumTo?: string | null;
  createTsFrom?: string | null;
  createTsTo?: string | null;
  createdByMitarbeiterIdList?: string[] | null;
  dueDateFrom?: string | null;
  dueDateTo?: string | null;
  incomingInvoiceFilterStatus?: IncomingInvoiceFilterStatus | null;
  invoiceDateFrom?: string | null;
  invoiceDateTo?: string | null;
  invoiceNumber?: string | null;
  issuerBankDetailsIban?: string | null;
  issuerRechtstraegerId?: string | null;
  kontoId?: string | null;
  kundenNummer?: string | null;
  nettoFrom?: number | null;
  nettoTo?: number | null;
  objektId?: string | null;
  payeeBankDetailsIban?: string | null;
  payeeRechtstraegerId?: string | null;
  paymentMethod?: PaymentMethod | null;
  paymentReferenceText?: string | null;
  purposeOfUseText?: string | null;
  sepaMandatReference?: string | null;
  vatFrom?: number | null;
  vatTo?: number | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  billingType: 'billingType',
  bruttoFrom: 'bruttoFrom',
  bruttoTo: 'bruttoTo',
  buchungsdatumFrom: 'buchungsdatumFrom',
  buchungsdatumTo: 'buchungsdatumTo',
  createTsFrom: 'createTsFrom',
  createTsTo: 'createTsTo',
  createdByMitarbeiterIdList: 'createdByMitarbeiterIdList',
  dueDateFrom: 'dueDateFrom',
  dueDateTo: 'dueDateTo',
  incomingInvoiceFilterStatus: 'incomingInvoiceFilterStatus',
  invoiceDateFrom: 'invoiceDateFrom',
  invoiceDateTo: 'invoiceDateTo',
  invoiceNumber: 'invoiceNumber',
  issuerBankDetailsIban: 'issuerBankDetailsIban',
  issuerRechtstraegerId: 'issuerRechtstraegerId',
  kontoId: 'kontoId',
  kundenNummer: 'kundenNummer',
  nettoFrom: 'nettoFrom',
  nettoTo: 'nettoTo',
  objektId: 'objektId',
  payeeBankDetailsIban: 'payeeBankDetailsIban',
  payeeRechtstraegerId: 'payeeRechtstraegerId',
  paymentMethod: 'paymentMethod',
  paymentReferenceText: 'paymentReferenceText',
  purposeOfUseText: 'purposeOfUseText',
  sepaMandatReference: 'sepaMandatReference',
  vatFrom: 'vatFrom',
  vatTo: 'vatTo',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TIncomingInvoiceQueryParams => ({
  billingType: formValues.billingType,
  bruttoFrom: formValues.bruttoFrom,
  bruttoTo: formValues.bruttoTo,
  buchungsdatumFrom: formValues.buchungsdatumFrom ? mapFormDateValueToDateString(formValues.buchungsdatumFrom) : undefined,
  buchungsdatumTo: formValues.buchungsdatumTo ? mapFormDateValueToDateString(formValues.buchungsdatumTo) : undefined,
  createTsFrom: formValues.createTsFrom ? mapFormDateValueToDateString(formValues.createTsFrom) : undefined,
  createTsTo: formValues.createTsTo ? mapFormDateValueToDateString(formValues.createTsTo) : undefined,
  createdByMitarbeiterIdList: formValues.createdByMitarbeiterIdList,
  dueDateFrom: formValues.dueDateFrom ? mapFormDateValueToDateString(formValues.dueDateFrom) : undefined,
  dueDateTo: formValues.dueDateTo ? mapFormDateValueToDateString(formValues.dueDateTo) : undefined,
  incomingInvoiceFilterStatus: formValues.incomingInvoiceFilterStatus,
  invoiceDateFrom: formValues.invoiceDateFrom ? mapFormDateValueToDateString(formValues.invoiceDateFrom) : undefined,
  invoiceDateTo: formValues.invoiceDateTo ? mapFormDateValueToDateString(formValues.invoiceDateTo) : undefined,
  invoiceNumber: formValues.invoiceNumber,
  issuerBankDetailsIban: formValues.issuerBankDetailsIban,
  issuerRechtstraegerId: formValues.issuerRechtstraegerId,
  kontoId: formValues.kontoId,
  kundenNummer: formValues.kundenNummer,
  nettoFrom: formValues.nettoFrom,
  nettoTo: formValues.nettoTo,
  objektId: formValues.objektId,
  payeeBankDetailsIban: formValues.payeeBankDetailsIban,
  payeeRechtstraegerId: formValues.payeeRechtstraegerId,
  paymentMethod: formValues.paymentMethod,
  paymentReferenceText: formValues.paymentReferenceText,
  purposeOfUseText: formValues.purposeOfUseText,
  sepaMandatReference: formValues.sepaMandatReference,
  vatFrom: formValues.vatFrom,
  vatTo: formValues.vatTo,
});

export const mapFormValuesToBulkAction = (formValues: FiltersFormValues): IncomingInvoiceBookingCreationGenerierlaufCreateInput => {
  const { issuerRechtstraegerId, payeeRechtstraegerId, objektId, invoiceNumber, ...restProps } = formValues;

  return {
    ...mapFormValuesToQueryParams(restProps),
    generierDatum: dayjsCustom().format('YYYY-MM-DD'),
    invoiceNumber: formValues.invoiceNumber?.toString(),
    issuerId: formValues.issuerRechtstraegerId,
    objektIdList: formValues.objektId ? [formValues.objektId] : undefined,
    payeeId: formValues.payeeRechtstraegerId,
  };
};

export const mapQueryParamsToIncomingInvoiceListQueryVariables = (queryParams: TIncomingInvoiceQueryParams): QueryGetIncomingInvoiceListArgs => ({
  ...queryParams,
  invoiceNumber: queryParams.invoiceNumber?.toString(),
  objektIdList: queryParams.objektId ? [queryParams.objektId] : undefined,
});

export const listingLabelList: ObjectOfStrings<
  Omit<FiltersFormValues, 'invoiceDateTo' | 'dueDateTo' | 'bruttoTo' | 'nettoTo' | 'vatTo' | 'buchungsdatumTo' | 'createTsTo'>
> = {
  [filtersFormFields.billingType]: 'Verrechnungsart',
  [filtersFormFields.bruttoFrom]: 'Brutto',
  [filtersFormFields.buchungsdatumFrom]: 'Buchungsdatum',
  [filtersFormFields.createTsFrom]: 'Erstellt am',
  [filtersFormFields.createdByMitarbeiterIdList]: 'Ersteller',
  [filtersFormFields.dueDateFrom]: 'Fällig am',
  [filtersFormFields.invoiceDateFrom]: 'Rechnungsdatum',
  [filtersFormFields.invoiceNumber]: 'Rechnungsnummer',
  [filtersFormFields.issuerBankDetailsIban]: 'Bankverbindung Lieferant',
  [filtersFormFields.issuerRechtstraegerId]: 'Lieferant',
  [filtersFormFields.kontoId]: 'Konto',
  [filtersFormFields.kundenNummer]: 'Kundennummer',
  [filtersFormFields.nettoFrom]: 'Netto',
  [filtersFormFields.objektId]: 'Objekt',
  [filtersFormFields.payeeBankDetailsIban]: 'Bankverbindung Rechnungsempfänger',
  [filtersFormFields.payeeRechtstraegerId]: 'Empfänger',
  [filtersFormFields.paymentMethod]: 'Zahlungsart',
  [filtersFormFields.paymentReferenceText]: 'Zahlungsreferenz',
  [filtersFormFields.purposeOfUseText]: 'Verwendungszweck',
  [filtersFormFields.sepaMandatReference]: 'SEPA-Mandatsreferenz',
  [filtersFormFields.incomingInvoiceFilterStatus]: 'Status',
  [filtersFormFields.vatFrom]: 'USt',
};
