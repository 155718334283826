import React from 'react';
import { ContactsOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../../components/ContextSidebar/ContentWithSidebar';
import { pathsToKontoTemplateDetailsPage } from '../../../../features/Kontenrahmen/kontoTemplateUriPaths';
import { Konto } from '../../../../types';
import KontoTemplateEinstellungenTabs from '../../../../features/Kontenrahmen/KontoTemplateDetails/KontoTemplateEinstellungenTabs';
import KontoTemplateAbrechnungenTabs from '../../../../features/Kontenrahmen/KontoTemplateDetails/KontoTemplateAbrechnungenTabs';

const menuListKonto = (konto: Konto): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Einstellungen',
      icon: <HomeOutlined />,
      uri: `${pathsToKontoTemplateDetailsPage(konto.kontoId).bezuege}`,
      content: (props) => <KontoTemplateEinstellungenTabs konto={konto} {...props} />,
    },
  ],
  contextMenuList: [
    {
      title: 'Abrechnungen',
      icon: <ContactsOutlined />,
      uri: `${pathsToKontoTemplateDetailsPage(konto.kontoId).abrechnungen}`,
      content: (props) => <KontoTemplateAbrechnungenTabs konto={konto} {...props} />,
      contextKey: ['ABRECHNUNGEN'],
    },
  ],
});

export default menuListKonto;
