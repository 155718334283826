import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import { ObjektBaseFragmentDoc } from '../../Objekt/gql/ObjektFragments.types';
import { BestandseinheitBaseFieldsFragmentDoc } from '../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { VertragBaseFieldsFragmentDoc } from '../../Vertrag/gql/VertragFragments.types';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
export type VorsteuerkuerzungListEntryFragment = {
  bisInklusive: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  sumVstKuerzung: number;
  vonInklusive: string;
  vorsteuerkuerzungId: string;
  buchungStatus: { description?: string | null; text: string; value: Types.VorsteuerkuerzungBuchungStatus };
  rechnungsAussteller: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  status: { description?: string | null; text: string; value: Types.VorsteuerkuerzungStatus };
  vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
  warningList: Array<{ message: string; type: string; attribute?: string | null }>;
};

export type VorsteuerkuerzungFragment = {
  belegnummer?: string | null;
  bisInklusive: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  fibuBuchungIdList: Array<string>;
  lastBuchungsdatum?: string | null;
  sumBetrag: number;
  sumVstKuerzung: number;
  updatedByMitarbeiterId?: string | null;
  vonInklusive: string;
  vorsteuerkuerzungId: string;
  buchungErrorList: Array<{ message: string; type: string }>;
  buchungStatus: { description?: string | null; text: string; value: Types.VorsteuerkuerzungBuchungStatus };
  errorList: Array<{ message: string; type: string }>;
  objekt: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    objektId: string;
    updatedByMitarbeiterId?: string | null;
    rechnungsAussteller: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    status: { text: string; value: Types.ObjektStatus; description?: string | null };
    verwaltungsart: { text: string; value: Types.Verwaltungsart };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  rechnungsAussteller: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  status: { description?: string | null; text: string; value: Types.VorsteuerkuerzungStatus };
  vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
  vorsteuerkuerzungVertragList: Array<{
    fibuBuchungIdList: Array<string>;
    sumSteuer: number;
    sumVstKuerzung: number;
    bestandseinheit: {
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      objektId: string;
      status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    vertrag: {
      bestandseinheitId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektId: string;
      vertragId: string;
      vertragsBeginn: string;
      status: { text: string; value: Types.VertragStatus; description?: string | null };
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    vorsteuerkuerzungKontoList: Array<{
      sumSteuer: number;
      sumVstKuerzung: number;
      vorsteuerkuerzungKontoId: string;
      aufwandsKontoList: Array<{
        aufwandsKontoId: string;
        sumSteuer: number;
        sumVstKuerzung: number;
        aufteilungList: Array<{
          anteilTopAufteilungsschluessel: number;
          belegFileId: string;
          belegId: string;
          belegnummer: string;
          buchungId: string;
          buchungsdatum: string;
          einheitskosten: number;
          gesamtAnteilAufteilungsschluessel: number;
          standingInvoicePartialAmountId?: string | null;
          steuer: number;
          vstKuerzungAnteil: number;
          aufteilungsschluessel: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          lieferant: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
        }>;
      }>;
    }>;
  }>;
  warningList: Array<{ message: string; type: string; attribute?: string | null }>;
};

export const VorsteuerkuerzungListEntryFragmentDoc = gql`
  fragment VorsteuerkuerzungListEntry on VorsteuerkuerzungListEntry {
    bisInklusive
    buchungStatus {
      description
      text
      value
    }
    createTs
    createdBy
    createdByMitarbeiterId
    rechnungsAussteller {
      ...RechtstraegerBaseFields
    }
    status {
      description
      text
      value
    }
    sumVstKuerzung
    vertragsart {
      supportsExplicitVertragsEnde
      text
      value
    }
    vonInklusive
    vorsteuerkuerzungId
    warningList {
      message
      type
      attribute
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export const VorsteuerkuerzungFragmentDoc = gql`
  fragment Vorsteuerkuerzung on Vorsteuerkuerzung {
    belegnummer
    bisInklusive
    buchungErrorList {
      message
      type
    }
    buchungStatus {
      description
      text
      value
    }
    createTs
    createdBy
    createdByMitarbeiterId
    errorList {
      message
      type
    }
    fibuBuchungIdList
    lastBuchungsdatum
    objekt {
      ...ObjektBase
    }
    rechnungsAussteller {
      ...RechtstraegerBaseFields
    }
    status {
      description
      text
      value
    }
    sumBetrag
    sumVstKuerzung
    updatedByMitarbeiterId
    vertragsart {
      supportsExplicitVertragsEnde
      text
      value
    }
    vonInklusive
    vorsteuerkuerzungId
    vorsteuerkuerzungVertragList {
      bestandseinheit {
        ...BestandseinheitBaseFields
      }
      fibuBuchungIdList
      sumSteuer
      sumVstKuerzung
      vertrag {
        ...VertragBaseFields
      }
      vorsteuerkuerzungKontoList {
        aufwandsKontoList {
          aufteilungList {
            anteilTopAufteilungsschluessel
            aufteilungsschluessel {
              ...AufteilungsschluesselFields
            }
            belegFileId
            belegId
            belegnummer
            buchungId
            buchungsdatum
            einheitskosten
            gesamtAnteilAufteilungsschluessel
            lieferant {
              ...RechtstraegerBaseFields
            }
            standingInvoicePartialAmountId
            steuer
            vstKuerzungAnteil
          }
          aufwandsKontoId
          sumSteuer
          sumVstKuerzung
        }
        sumSteuer
        sumVstKuerzung
        vorsteuerkuerzungKontoId
      }
    }
    warningList {
      message
      type
      attribute
    }
  }
  ${ObjektBaseFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
  ${BestandseinheitBaseFieldsFragmentDoc}
  ${VertragBaseFieldsFragmentDoc}
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
