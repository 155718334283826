import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { AbrInfoBuchungszeile } from '../../../../../types';
import { NestedTableWithColSelectorColProps } from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { generatePathToBookingDetailsPage } from '../../../../BookingDetails/bookingDetailsPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import BelegnummerWithLinkToPDF from '../../../BkOrWe/Objekt/Table/BelegnummerWithLinkToPDF';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { generatePathToVorschreibungDetailsPage } from '../../../../Vorschreibung/vorschreibungUriPaths';
import SollHabenTag from '../../../../shared/SollHabenTag';
import { EuroAmount } from '../../../../../components/Number';

const einnahmenBuchungszeileTableColumns: NestedTableWithColSelectorColProps<AbrInfoBuchungszeile>[] = [
  {
    title: 'Text',
    width: 200,
    defaultSelected: true,
    key: 'abrechnungskreis',
    render: (text, record) =>
      record.auftragId ? (
        <Link target="_blank" to={generatePathToVorschreibungDetailsPage(record.auftragId)}>
          <DataWithShortenedText text={record.text} maxTextLength={25} />
        </Link>
      ) : (
        <DataWithShortenedText text={record.text} maxTextLength={25} />
      ),
  },
  {
    title: 'Fälligkeit',
    defaultSelected: true,
    align: 'right',
    render: (record) => (record.dueDate ? <CustomFormattedDate value={record.dueDate} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Belegdatum',
    defaultSelected: true,
    align: 'right',
    render: (text, record) => (record.dueDate ? <CustomFormattedDate value={record.dueDate} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Beleg-Nr.',
    defaultSelected: true,
    align: 'right',
    render: (text, record) => <BelegnummerWithLinkToPDF buchungszeile={record} />,
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    align: 'right',
    render: (text, record) =>
      record.vertragspartner ? (
        <DataWithShortenedText maxTextLength={25} text={record.vertragspartner.kurzBezeichnung}>
          {(shortenedText) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Link target="_blank" to={generatePathToRechtstraegerDetailsPage(record.vertragspartner!.rechtstraegerId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: '',
    key: 'ausgaben',
  },
  {
    title: 'Einnahmen',
    key: 'einnahmen',
    align: 'right',
    render: (text, record) => (
      <Space size={0}>
        <SollHabenTag sollHaben={record.sollHaben} />
        <div style={{ width: '100px', display: 'inline-flex', justifyContent: 'end' }}>
          {record.auftragId ? (
            <Link target="_blank" to={generatePathToBookingDetailsPage(record.buchungIdList)}>
              <EuroAmount value={record.betrag} />
            </Link>
          ) : (
            <EuroAmount value={record.betrag} />
          )}
        </div>
      </Space>
    ),
  },
  {
    title: '',
  },
];

export default einnahmenBuchungszeileTableColumns;
