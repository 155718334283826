import React, { FC } from 'react';
import { Divider, Skeleton, Space, Typography } from 'antd';
import { useWeRechnungsAusstellerListQuery } from '../../../BankDetails/gql/WeRechnungsAusstellerQueries.types';
import { Spacer } from '../../../../components/Grid';
import WeRechnungsAusstellerBankDetails from '../../BankDetails/WeRechnungsAusstellerBankDetails';
import EntityWarningList from '../../../../components/message/EntityWarningList';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

type Props = {
  objektId: string;
  rechtstraegerId: string;
};

const WeRechnungsausstellerWithBankDetails: FC<Props> = ({ objektId, rechtstraegerId }) => {
  const { data, refetch } = useWeRechnungsAusstellerListQuery({ variables: { objektId } });

  const weRechnungsAusstellerList = data?.getWeRechnungsAusstellerList.data ?? [];

  if (!weRechnungsAusstellerList) {
    return <Skeleton active />;
  }

  if (weRechnungsAusstellerList.length === 0) {
    return null;
  }

  return (
    <>
      {weRechnungsAusstellerList.map((rechnungsaussteller) => (
        <React.Fragment key={rechnungsaussteller.weRechnungsAusstellerRechtstraegerId}>
          <Space direction="horizontal" style={{ width: '100%', alignItems: 'baseline' }}>
            <Typography.Title level={5}>WE-Rechnungsaussteller</Typography.Title>
            <EntityWarningList warningList={rechnungsaussteller.warningList} />
          </Space>
          <RechtstraegerWithContactsTooltip
            rechtstraegerId={rechnungsaussteller.weRechnungsAusstellerRechtstraegerId}
            rechtstraegerName={rechnungsaussteller.weRechnungsAusstellerKurzBezeichnung}
          />
          <Spacer height={8} />
          <div style={{ paddingLeft: '8px' }}>
            <WeRechnungsAusstellerBankDetails
              objektId={objektId}
              rechtstraegerId={rechtstraegerId}
              weRechnungsAusstellerId={rechnungsaussteller.weRechnungsAusstellerRechtstraegerId}
              weRechnungsAusstellerFibuKontoCreatable={rechnungsaussteller.weRechnungsAusstellerFibuKontoCreatable}
              objektBankDetailsList={rechnungsaussteller.objektBankDetailsList}
              onAction={refetch}
              warningList={rechnungsaussteller.warningList}
            />
          </div>
          <Divider style={{ marginTop: '8px', marginBottom: '8px' }} dashed />
        </React.Fragment>
      ))}
    </>
  );
};

export default WeRechnungsausstellerWithBankDetails;
