import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { BuchungUstVomAufwand } from '../../../../objektAbrechnung-types';

const OAAbrKreisRepFondsUstVomAufwandList: FC<{ buchungUstVomAufwandList: BuchungUstVomAufwand[] }> = ({ buchungUstVomAufwandList }) => (
  <View style={pdfStyles.tablePaddingLeftRight}>
    <View style={[pdfStyles.column, pdfStyles.borderBottomSmall, { width: '45%', marginBottom: '2mm' }]}>
      {buchungUstVomAufwandList.map((buchungUstVomAufwand, index) => (
        <View style={[pdfStyles.row]} key={index}>
          <Text style={[pdfStyles.textBulletinSmall, { width: '80%' }]}>{buchungUstVomAufwand.bezeichnung}</Text>
          <Text style={[pdfStyles.textBulletinSmall, { width: '20%', alignItems: 'flex-end', textAlign: 'right' }]}>
            {buchungUstVomAufwand.betragNetto}
          </Text>
        </View>
      ))}
    </View>

    <View style={[pdfStyles.column, { width: '55%' }]} />
  </View>
);

export default OAAbrKreisRepFondsUstVomAufwandList;
