import { mapToQueryString, QueryParams } from '../../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../../verarbeitungApiPaths';

enum BelegVerarbeitungQueryParamKey {
  FAKTURIERUNGSPERIODE = 'fakturierungsperiode',
  OBJEKT_ID = 'objektId',
  AUSSTELLER_ID = 'ausstellerId',
  EMPFAENGER_ID = 'empfaengerId',
  EXIT_CODE_LIST = 'exitCodeList',
  BELEGNUMMER = 'belegnummer',
}

export type TBelegVerarbeitungQueryParams = QueryParams<{
  fakturierungsperiode: string;
  objektId: string;
  ausstellerId: string;
  empfaengerId: string;
  exitCodeList: GenerierlaufEntryExitCode[];
  belegnummer: string;
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: TBelegVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  navigate(
    generatePathToBelegVerarbeitungPage(
      verarbeitungType,
      generierlaufId,
      filters.fakturierungsperiode,
      filters.objektId,
      filters.ausstellerId,
      filters.empfaengerId,
      filters.exitCodeList,
      filters.belegnummer,
      paginationParams
    )
  );

const generatePathToBelegVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  fakturierungsperiode?: string,
  objektId?: string,
  ausstellerId?: string,
  empfaengerId?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  belegnummer?: string,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [BelegVerarbeitungQueryParamKey.FAKTURIERUNGSPERIODE]: fakturierungsperiode,
    [BelegVerarbeitungQueryParamKey.OBJEKT_ID]: objektId,
    [BelegVerarbeitungQueryParamKey.AUSSTELLER_ID]: ausstellerId,
    [BelegVerarbeitungQueryParamKey.EMPFAENGER_ID]: empfaengerId,
    [BelegVerarbeitungQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    [BelegVerarbeitungQueryParamKey.BELEGNUMMER]: belegnummer,
    ...paginationParams,
  })}`;
