import { Dayjs } from 'dayjs';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { UstVomAufwandGenerierlaufInput } from '../../../../types';
import { firstDayOfCurrentYear, lastDayOfCurrentYear } from '../../../../helpers/dateHelper';

export type UstVomAufwandCreateFormValues = {
  vonBis: Dayjs[];
};

export const ustVomAufwandCreateFormInitialValues: UstVomAufwandCreateFormValues = {
  vonBis: [firstDayOfCurrentYear(), lastDayOfCurrentYear()],
};

export const mapFormValuesToUstVomAufwand = (objektId: string, values: UstVomAufwandCreateFormValues): UstVomAufwandGenerierlaufInput => ({
  objektId,
  vonInklusive: mapFormDateValueToDateString(values.vonBis[0].startOf('month')),
  bisInklusive: mapFormDateValueToDateString(values.vonBis[1].endOf('month')),
});

export const ustVomAufwandCreateFormFields: FormFields<UstVomAufwandCreateFormValues> = {
  vonBis: 'vonBis',
};
