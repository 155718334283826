import { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../components/Grid';
import { ApplyVertragVposIndexedValueGenerierlauf } from '../../../types';
import EntryListing from './EntryListing/EntryListing';
import { verarbeitungDetailsCardTitles } from '../verarbeitungHelpers';

type Props = {
  generierlauf: ApplyVertragVposIndexedValueGenerierlauf;
};

const VerarbeitungApplyVertragVposIndexedValue: FC<Props> = ({ generierlauf }) => (
  <>
    <Typography.Title level={4}>{verarbeitungDetailsCardTitles.ApplyVertragVposIndexedValue}</Typography.Title>
    <Spacer />
    <EntryListing generierlauf={generierlauf} />
  </>
);

export default VerarbeitungApplyVertragVposIndexedValue;
