import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { useInfoMailDeliveryQuery } from '../gql/InfoMailDeliveryQueries.types';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  infoMailDeliveryId: string;
};

const ObjektTopLink: FC<Props> = ({ infoMailDeliveryId }) => {
  const { data, loading } = useInfoMailDeliveryQuery({ variables: { infoMailDeliveryId } });
  const infoMailDelivery = data?.getInfoMailDelivery.data;

  return (
    <Skeleton paragraph={false} active loading={loading}>
      {infoMailDelivery && (
        <DataWithShortenedText text={infoMailDelivery.objektTopVertrag.name} maxTextLength={25}>
          {(shortenedText) => (
            <Link
              to={generatePathToBestandseinheitDetailsPage(
                infoMailDelivery.objektTopVertrag.objekt.objektId,
                infoMailDelivery.objektTopVertrag.bestandseinheit.bestandseinheitId
              )}
            >
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      )}
    </Skeleton>
  );
};

export default ObjektTopLink;
