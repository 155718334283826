import React, { FC } from 'react';
import { SelectProps } from 'formik-antd';
import { FormikProps } from 'formik';
import {
  bookingSuggestionFormFields,
  BookingSuggestionFormValues,
  bSBuchungsanweisungFormFields,
  mapBSClearingSuggestionToOPForClearingSuggestion,
} from '../../../Form/buchungsmaskeFormMapper';
import { useKontoSelectListQuery } from '../../../../../KontoSelect/gql/KontoSelectQueries.types';
import { BookingSuggestion, Konto } from '../../../../../../types';
import { EntityStatusFormSelect } from '../../../../../../components/Status/EntityStatus';
import TextWithTag from '../../../../../../components/Text/TextWithTag';
import { isChosenKontoDifferentFromKontoFromBookingSuggestion } from '../../../Form/buchungsmaskeFormHelpers';
import { isKontoTypePersonenkonto } from '../../../../../Kontenplan/Form/kontoFormHelpers';

type Props = {
  bookingSuggestion: BookingSuggestion;
  bzIndex: number;
  formikProps: FormikProps<BookingSuggestionFormValues>;
} & SelectProps;

const KontoFormSelect: FC<Props> = ({ bookingSuggestion, disabled, name, formikProps, bzIndex, ...restProps }) => {
  const { loading, data } = useKontoSelectListQuery();
  const kontoList = data?.getKontoList?.data.contentList ?? [];

  const handleChange = (kontoId: string) => {
    if (!kontoId) {
      formikProps.setFieldValue(
        `${bookingSuggestionFormFields.buchungsanweisung}.${bSBuchungsanweisungFormFields.buchungszeileList}[${bzIndex}].kontoId`,
        ''
      );
    } else {
      const chosenKonto = kontoList.filter((konto) => konto.kontoId === kontoId);
      formikProps.setFieldValue(
        `${bookingSuggestionFormFields.buchungsanweisung}.${bSBuchungsanweisungFormFields.buchungszeileList}[${bzIndex}].konto`,
        chosenKonto[0]
      );

      if (isKontoTypePersonenkonto(chosenKonto[0].type.value)) {
        formikProps.setFieldValue(
          bookingSuggestionFormFields.offenePostenForClearingSuggestionList,
          isChosenKontoDifferentFromKontoFromBookingSuggestion(chosenKonto[0].kontoId, bookingSuggestion.buchungsanweisung.buchungszeileList)
            ? []
            : bookingSuggestion.clearingSuggestionList.map((clearingSuggestion) =>
                mapBSClearingSuggestionToOPForClearingSuggestion(clearingSuggestion)
              )
        );
      }
    }
  };

  return (
    <EntityStatusFormSelect<Konto>
      list={kontoList}
      getSelectItemId={(konto) => konto.kontoId}
      getSelectItemContent={(konto) => <TextWithTag textContent={konto.nummer} tagContent={konto.bezeichnung} maxTextLength={30} />}
      getSelectItemTooltipTitle={(konto) => `${konto.nummer} - ${konto.bezeichnung}`}
      disabled={disabled}
      placeholder="Konto auswählen"
      name={name}
      loading={loading}
      onChange={handleChange}
      {...restProps}
    />
  );
};

export default KontoFormSelect;
