import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { BookingSuggestionBuchnungsmaskePageRouteParams } from './routes';
import { PageLayout } from '../../components/LayoutAndromeda';
import BookingSuggestionBuchungsmaske from '../../features/BookingSuggestion/BuchungsmaskeSingleBookingSuggestion/BookingSuggestionBuchungsmaske';

const BookingSuggestionBuchungsmaskePage = () => {
  const { bookingSuggestionId } = useParams() as BookingSuggestionBuchnungsmaskePageRouteParams;

  return (
    <>
      <Helmet>
        <title>Buchungsmaske</title>
      </Helmet>
      <PageLayout>
        <BookingSuggestionBuchungsmaske bookingSuggestionId={bookingSuggestionId} />
      </PageLayout>
    </>
  );
};

export default BookingSuggestionBuchungsmaskePage;
