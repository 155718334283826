import React, { CSSProperties, FC } from 'react';
import { Image, ImageProps } from '@react-pdf/renderer';
import { DocumentHeaderAndFooterImageAlignment } from './types';

type VerticalImageAlignment = 'CENTER' | 'TOP' | 'BOTTOM';

type Props = {
  value: string | null;
  horizontalAlignment?: DocumentHeaderAndFooterImageAlignment | null;
  verticalAlignment?: VerticalImageAlignment | null;
  style?: ImageProps['style'];
};

const HeaderAndFooterImage: FC<Props> = ({ value, horizontalAlignment, verticalAlignment, style }) => {
  if (!value) return null;

  const horizontalAlignmentStyles = {
    LEFT: { marginRight: 'auto' },
    CENTER: { marginLeft: 'auto', marginRight: 'auto' },
    RIGHT: { marginLeft: 'auto' },
  };

  const verticalAlignmentStyles: Record<VerticalImageAlignment, CSSProperties> = {
    TOP: { justifyContent: 'flex-start' },
    CENTER: { justifyContent: 'center' },
    BOTTOM: { justifyContent: 'flex-end' },
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        ...(verticalAlignment && verticalAlignmentStyles[verticalAlignment] ? verticalAlignmentStyles[verticalAlignment] : {}),
      }}
    >
      <Image
        source={value}
        style={{
          ...style,
          ...(horizontalAlignment && horizontalAlignmentStyles[horizontalAlignment] ? horizontalAlignmentStyles[horizontalAlignment] : {}),
        }}
      />
    </div>
  );
};

export default HeaderAndFooterImage;
