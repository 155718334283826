import React from 'react';
import { useParams } from 'react-router-dom';
import { VerarbeitungSubAbrechnungDetailsPageRouteParams } from './routes';
import { useSubAbrechnungGenerierlaufQuery } from '../../../features/Verarbeitung/gql/SubAbrechnungGenerierlauf/SubAbrechnungGenerierlaufQueries.types';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { SubAbrechnungGenerierlauf } from '../../../types';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import menuListSubAbrechnung from './menuListSubAbrechnung';

const SubAbrechnungDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungSubAbrechnungDetailsPageRouteParams;

  const { data } = useSubAbrechnungGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getSubAbrechnungGenerierlauf.data;

  return (
    <DetailsPageTemplate<SubAbrechnungGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.SubAbrechnung}
      card={(generierlauf) => <VerarbeitungDetailsPageCard generierlauf={generierlauf} cardTitle={verarbeitungDetailsCardTitles.SubAbrechnung} />}
      sidebarMenuList={(generierlauf) => menuListSubAbrechnung(generierlauf)}
    />
  );
};

export default SubAbrechnungDetailsPage;
