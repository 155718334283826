import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Space } from 'antd';
import TicketEmailFileUpload from './TicketEmailFileUpload';
import { TicketEmail } from '../../../../types';
import { UploadedFileView } from '../../../shared/UploadedFileView';
import ErrorMessagePanel from '../../../../components/Error/ErrorMessagePanel';
import { ticketEmailsFormFields, TicketEmailsFormValues, TicketUnifiedEmail } from './ticketEmailsFormMapper';

type Props = {
  formikProps: FormikProps<TicketEmailsFormValues>;
  emailList: TicketEmail[];
};

const TicketEmailUpload: FC<Props> = ({ formikProps, emailList }) => {
  const emails: TicketUnifiedEmail[] = [
    ...emailList.map(({ fileInfo: { name, size, fileId } }) => ({ name, size, fileId })),
    ...formikProps.values.newEmailList.map((email) => ({ name: email.file.name, size: email.file.size, file: email.file })),
  ].filter((email: TicketUnifiedEmail) => !email.fileId || !formikProps.values.deletableIds.includes(email.fileId));

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <TicketEmailFileUpload name={ticketEmailsFormFields.newEmailList} formikProps={formikProps} emailList={emails} />
      <Space direction="vertical">
        {emails.map((email, index) => {
          const onDelete = () => {
            const deleteableIds = formikProps.values.deletableIds;

            if (email.fileId) {
              if (!deleteableIds.includes(email.fileId)) {
                formikProps.setFieldValue(ticketEmailsFormFields.deletableIds, [...deleteableIds, email.fileId]);
              }
            } else if (email.file) {
              formikProps.setFieldValue(
                ticketEmailsFormFields.newEmailList,
                formikProps.values.newEmailList.filter((file) => file.file !== email.file)
              );
            }
          };
          return <UploadedFileView key={index} filename={email.name} filesize={email.size} onDelete={onDelete} />;
        })}
        {formikProps.errors.newEmailList && <ErrorMessagePanel error={formikProps.errors.newEmailList as string} />}
      </Space>
    </Space>
  );
};

export default TicketEmailUpload;
