import React, { FC, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { isNotNil } from '../../../../../helpers/assertionHelper';
import RecipientTable from './Table/RecipientTable';
import AttachmentPart from './AttachmentPart';
import {
  useInfoMailDeliveryWizardRecipientListQuery,
  useInfoMailDeliveryWizardRecipientListStepQuery,
} from '../../gql/InfoMailDeliveryWizardQueries.types';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { useDeleteInfoMailDeliveryWizardMutation, useSendInfoMailMutation } from '../../gql/InfoMailDeliveryWizardMutations.types';
import { showSuccessMsgDelete, showSuccessMsgOther } from '../../../../../components/message';
import StepButtons from '../../../Shared/StepButtons';
import confirmDeleteWizard from '../../../Shared/confirmDeleteWizard';
import { InfoMailDelivery } from '../../../../../types';

type Props = {
  wizardId: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: () => void;
};

const RecipientListStep: FC<Props> = ({ onCancel, wizardId, onBack, onComplete }) => {
  const [selectedRow, setSelectedRow] = useState<InfoMailDelivery>();
  const { currentPage, pageSize } = usePaginationQueryParams();
  const [inProgress, setInProgress] = useState(true);

  const [runDelete] = useDeleteInfoMailDeliveryWizardMutation();

  const { data, pagination, handleTableSorting, loading, refetch } = useQueryWithPagingAndSorting(
    useInfoMailDeliveryWizardRecipientListQuery,
    {
      variables: {
        infoMailDeliveryWizardId: wizardId,
      },
    },
    {
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : 10,
    }
  );

  useEffect(() => {
    setInProgress(loading);
  }, [loading]);

  const { data: stepData, loading: stepLoading } = useInfoMailDeliveryWizardRecipientListStepQuery({
    variables: {
      infoMailDeliveryWizardId: wizardId,
    },
  });

  const completed = Boolean(stepData?.getInfoMailDeliveryWizardRecipientListStep.data.completed);

  const [runSendInfoMail] = useSendInfoMailMutation({
    variables: {
      infoMailDeliveryWizardId: wizardId,
    },
  });

  const recipientListStep = data?.getInfoMailDeliveryWizardRecipientList.data;

  useEffect(() => {
    if (!recipientListStep?.contentList || !selectedRow) {
      setSelectedRow(undefined);
      return;
    }

    const inactive = recipientListStep.contentList
      .filter((mailDelivery) => !mailDelivery.shouldSend)
      .find((mailDelivery) => mailDelivery.infoMailDeliveryId === selectedRow.infoMailDeliveryId);
    if (inactive) {
      setSelectedRow(undefined);
    }
  }, [recipientListStep, selectedRow]);

  if (!recipientListStep) {
    return <Spin />;
  }

  const onNext = () => {
    if (completed) return;
    setInProgress(true);
    runSendInfoMail()
      .then(() => {
        showSuccessMsgOther(`E-Mails erfolgreich gesendet.`);
        onComplete();
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  const onDelete = () => {
    if (completed) return;
    confirmDeleteWizard(() => {
      setInProgress(true);
      runDelete({ variables: { infoMailDeliveryWizardId: wizardId } })
        .then(() => {
          showSuccessMsgDelete('Assistent');
          onCancel();
        })
        .finally(() => {
          setInProgress(false);
        });
    });
  };

  return (
    <>
      <RecipientTable
        loading={loading || stepLoading}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        pagination={pagination}
        infoMailDeliveryList={recipientListStep?.contentList}
        onUpdate={refetch}
        onChange={handleTableSorting}
        wizardId={wizardId}
        isCompleted={completed}
      />
      {isNotNil(selectedRow) && <AttachmentPart isCompleted={completed} wizardId={wizardId} selectedRow={selectedRow} />}

      <StepButtons
        isDropdownHidden={completed}
        isDropdownDisabled={inProgress}
        isBackDisabled={inProgress}
        onNext={onNext}
        onCancel={onCancel}
        isSaveDisabled
        onDelete={onDelete}
        onBack={onBack}
        isNextDisabled={inProgress}
        isNextHidden={completed}
      />
    </>
  );
};

export default RecipientListStep;
