import React from 'react';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import KontoblattListingFilters from './Filters/KontoblattListingFilters';
import KontoblattTable from './Table/KontoblattTable';
import {
  KontoblattListingFiltersFormValues,
  mapFormValuesToKontoblattListingFilters,
  mapKontoblattListingFiltersToFormValues,
} from './Filters/kontoblattTableFiltersFormMapper';
import { useQueryParams } from '../../hooks/useStringQueryParams';
import { generatePathToKontoblattPage, KontoblattPageQueryParams } from './kontoblattUriPaths';

const KontoblattListing = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<KontoblattPageQueryParams>();
  const { buchungskreisId, kontoId, objektId } = queryParams;

  let result: React.ReactNode;
  if ((buchungskreisId || objektId) && kontoId) {
    result = <KontoblattTable queryParams={queryParams} />;
  } else if (!buchungskreisId && !objektId) {
    result = (
      <Typography.Title level={3} style={{ textAlign: 'center' }}>
        Buchungskreis oder Objekt auswählen
      </Typography.Title>
    );
  } else if ((buchungskreisId || objektId) && !kontoId) {
    result = (
      <Typography.Title level={3} style={{ textAlign: 'center' }}>
        Konto auswählen
      </Typography.Title>
    );
  } else {
    result = (
      <Typography.Title level={3} style={{ textAlign: 'center' }}>
        Keine Kontoblätter gefunden
      </Typography.Title>
    );
  }

  const onChange = (filters: KontoblattPageQueryParams) => updateQueryParams(navigate, filters);

  return (
    <>
      <Formik<KontoblattListingFiltersFormValues>
        initialValues={mapKontoblattListingFiltersToFormValues(queryParams)}
        onSubmit={(values) => onChange(mapFormValuesToKontoblattListingFilters(values))}
      >
        {(formikProps) => <KontoblattListingFilters formikProps={formikProps} queryParams={queryParams} />}
      </Formik>
      {result}
    </>
  );
};

const updateQueryParams = (navigate: (path: string) => void, filters: KontoblattPageQueryParams) => navigate(generatePathToKontoblattPage(filters));

export default KontoblattListing;
