import { FC } from 'react';
import { useRepFondsErloesKontenZuweisungChangeHistoryListQuery } from '../../../../ReparaturFonds/gql/RepFondsErloesKontenZuweisungQueries.types';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../History/HistoryListingTable';

type Props = {
  rechtstraegerId: string;
  repFondsErloesKontenZuweisungId: string;
};

const RepFondsChangeHistoryListingTable: FC<Props> = ({ rechtstraegerId, repFondsErloesKontenZuweisungId }) => {
  const { data, loading } = useRepFondsErloesKontenZuweisungChangeHistoryListQuery({
    variables: { rechtstraegerId, repFondsErloesKontenZuweisungId },
  });
  const historyList = data?.getRepFondsErloesKontenZuweisungChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="rep-fonds" />;
};

export default RepFondsChangeHistoryListingTable;
