import { useParams } from 'react-router-dom';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../shared/Auth/authContext';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { BeVertragDetailsPageRouteParams } from '../../../pages/BeVertragDetailsPage/routes';

export const useOnBeVertragDataChangedEvent = (key: string, callback: () => Promise<unknown>) => {
  const { activeForFirmendatenId, user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  const { objektId, bestandseinheitId, vertragId } = useParams() as BeVertragDetailsPageRouteParams;

  useOnDataChangedEvent({
    filter: {
      userId: user.username,
      mitarbeiterId: mitarbeiter?.mitarbeiterId,
      type: dataChangedSubscriptionTypes.vertrag,
      params: {
        firmendatenId: activeForFirmendatenId,
        objektId,
        bestandseinheitId,
        vertragId,
      },
    },
    notificationDescription: 'Vertrag wurde gerade verändert',
    callback,
    key,
  });
};
