import { FormFields, mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { MahnungStatus } from '../../../types';
import { TMahnungQueryParams } from './Filters/filtersQueryParams';

export type MahnungListingFiltersFormValues = {
  buchungskreisId?: string;
  objektId?: string;
  vertragspartnerId?: string;
  statusList?: MahnungStatus[];
  mahnstichtagVonInklusive?: string;
  mahnstichtagBisInklusive?: string;
  erstellerMitarbeiterIdList?: string[];
  rechnungsAusstellerId?: string;
  sumRechnungsbetragVonInklusive?: number;
  sumRechnungsbetragBisInklusive?: number;
  sumMahnbetragVonInklusive?: number;
  sumMahnbetragBisInklusive?: number;
  mahngebuehrVonInklusive?: number;
  mahngebuehrBisInklusive?: number;
  verzugszinsenVonInklusive?: number;
  verzugszinsenBisInklusive?: number;
  versandEinschreiben?: boolean;
  createTsFrom?: string | null;
  createTsTo?: string | null;
};

export const mahnungListingFiltersFormFields: FormFields<MahnungListingFiltersFormValues> = {
  buchungskreisId: 'buchungskreisId',
  objektId: 'objektId',
  vertragspartnerId: 'vertragspartnerId',
  statusList: 'statusList',
  mahnstichtagVonInklusive: 'mahnstichtagVonInklusive',
  mahnstichtagBisInklusive: 'mahnstichtagBisInklusive',
  erstellerMitarbeiterIdList: 'erstellerMitarbeiterIdList',
  rechnungsAusstellerId: 'rechnungsAusstellerId',
  sumRechnungsbetragVonInklusive: 'sumRechnungsbetragVonInklusive',
  sumRechnungsbetragBisInklusive: 'sumRechnungsbetragBisInklusive',
  sumMahnbetragVonInklusive: 'sumMahnbetragVonInklusive',
  sumMahnbetragBisInklusive: 'sumMahnbetragBisInklusive',
  mahngebuehrVonInklusive: 'mahngebuehrVonInklusive',
  mahngebuehrBisInklusive: 'mahngebuehrBisInklusive',
  verzugszinsenVonInklusive: 'verzugszinsenVonInklusive',
  verzugszinsenBisInklusive: 'verzugszinsenBisInklusive',
  versandEinschreiben: 'versandEinschreiben',
  createTsFrom: 'createTsFrom',
  createTsTo: 'createTsTo',
};

export const mahnungListingFiltersFormInitialValues = (filters: TMahnungQueryParams): MahnungListingFiltersFormValues => ({
  buchungskreisId: filters.buchungskreisId,
  objektId: filters.objektId,
  vertragspartnerId: filters.vertragspartnerId,
  statusList: filters.statusList,
  mahnstichtagVonInklusive: filters.mahnstichtagVonInklusive,
  mahnstichtagBisInklusive: filters.mahnstichtagBisInklusive,
  erstellerMitarbeiterIdList: filters.erstellerMitarbeiterIdList,
  rechnungsAusstellerId: filters.rechnungsAusstellerId,
  sumRechnungsbetragVonInklusive: filters.sumRechnungsbetragVonInklusive,
  sumRechnungsbetragBisInklusive: filters.sumRechnungsbetragBisInklusive,
  sumMahnbetragVonInklusive: filters.sumMahnbetragVonInklusive,
  sumMahnbetragBisInklusive: filters.sumMahnbetragBisInklusive,
  mahngebuehrVonInklusive: filters.mahngebuehrVonInklusive,
  mahngebuehrBisInklusive: filters.mahngebuehrBisInklusive,
  verzugszinsenVonInklusive: filters.verzugszinsenVonInklusive,
  verzugszinsenBisInklusive: filters.verzugszinsenBisInklusive,
  versandEinschreiben: filters.versandEinschreiben,
  createTsFrom: filters.createTsFrom,
  createTsTo: filters.createTsTo,
});

export const mapFormValuesToMahnungListingFilters = (formValues: MahnungListingFiltersFormValues): TMahnungQueryParams => ({
  buchungskreisId: formValues.buchungskreisId,
  objektId: formValues.objektId,
  vertragspartnerId: formValues.vertragspartnerId,
  statusList: formValues.statusList,
  mahnstichtagVonInklusive: formValues.mahnstichtagVonInklusive ? mapFormDateValueToDateString(formValues.mahnstichtagVonInklusive) : undefined,
  mahnstichtagBisInklusive: formValues.mahnstichtagBisInklusive ? mapFormDateValueToDateString(formValues.mahnstichtagBisInklusive) : undefined,
  erstellerMitarbeiterIdList: formValues.erstellerMitarbeiterIdList,
  rechnungsAusstellerId: formValues.rechnungsAusstellerId,
  sumRechnungsbetragVonInklusive: formValues.sumRechnungsbetragVonInklusive,
  sumRechnungsbetragBisInklusive: formValues.sumRechnungsbetragBisInklusive,
  sumMahnbetragVonInklusive: formValues.sumMahnbetragVonInklusive,
  sumMahnbetragBisInklusive: formValues.sumMahnbetragBisInklusive,
  mahngebuehrVonInklusive: formValues.mahngebuehrVonInklusive,
  mahngebuehrBisInklusive: formValues.mahngebuehrBisInklusive,
  verzugszinsenVonInklusive: formValues.verzugszinsenVonInklusive,
  verzugszinsenBisInklusive: formValues.verzugszinsenBisInklusive,
  versandEinschreiben: formValues.versandEinschreiben,
  createTsFrom: formValues.createTsFrom ? mapFormDateValueToDateString(formValues.createTsFrom) : undefined,
  createTsTo: formValues.createTsTo ? mapFormDateValueToDateString(formValues.createTsTo) : undefined,
});

export const mahnungListingLabelList = {
  [mahnungListingFiltersFormFields.buchungskreisId]: 'Buchungskreis',
  [mahnungListingFiltersFormFields.objektId]: 'Objekt',
  [mahnungListingFiltersFormFields.vertragspartnerId]: 'Vertragspartner',
  [mahnungListingFiltersFormFields.statusList]: 'Status',
  [mahnungListingFiltersFormFields.mahnstichtagVonInklusive]: 'Mahnstichtag',
  [mahnungListingFiltersFormFields.erstellerMitarbeiterIdList]: 'Ersteller',
  [mahnungListingFiltersFormFields.sumRechnungsbetragVonInklusive]: 'Summe Rechnungsbetrag',
  [mahnungListingFiltersFormFields.sumMahnbetragVonInklusive]: 'Summe Mahnbetrag',
  [mahnungListingFiltersFormFields.mahngebuehrVonInklusive]: 'Mahngebühr',
  [mahnungListingFiltersFormFields.verzugszinsenVonInklusive]: 'Verzugszinsen',
  [mahnungListingFiltersFormFields.versandEinschreiben]: 'Versand Einschreiben',
  [mahnungListingFiltersFormFields.createTsFrom]: 'Erstellt am',
};
