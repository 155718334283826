import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isAdmin } from '../../security/permissionChecks';
import AndromedaSystemSettingPage from './AndromedaSystemSettingPage';
import { URI_ANDROMEDA_SYSTEM_SETTINGS } from '../../constants/andromedaSystemSettingsUriPaths';
import andromedaSysSettingsVerwaltungBenutzerPageRoute from './Verwaltung/BenutzerPage/routes';
import andromedaSysSettingsVerwaltungBenutzerDetailsPageRoute from './Verwaltung/BenutzerDetailsPage/routes';
import andromedaSysSettingsAdminDetailsPageRoute from './Verwaltung/AdminDetailsPage/routes';
import andromedaSysSettingsVerwaltungAdminPageRoute from './Verwaltung/AdminPage/routes';
import andromedaSysSettingsAufteilungsschluesselPageRoute from './KundenSystem/AufteilungsschluesselPage';
import andromedaSysSettingsAufgabenartPageRoute from './KundenSystem/AufgabenartPage';
import andromedaSysSettingsIndexSeriesDetailsPageRoute from './IndexSeriesDetailsPage';
import andromedaSysSettingsIndexSeriesPageRoute from './IndexSeriesPage';
import andromedaSysSettingsEbicsBankConfigPageRoute from './EbicsBankConfigPage';
import andromedaSysSettingsFieldHelpPageRoute from './FieldHelpPage';
import andromedaSysSettingsTokenPageRoute from './Configuration/TokenPage/routes';
import andromedaSysSettingsKontierungstabellePageRoute from './KundenSystem/KontierungstabellePage';
import andromedaSysSettingsEmailDeliveryDefinitionPageRoute from './KundenSystem/EmailDeliveryDefinitionPage';
import andromedaSysSettingsKundenSystemListPageRoute from './Verwaltung/KundenSystemPage/routes';
import andormedaSysSettingsKundenSystemDetailsPageRoute from './Verwaltung/KundenSystemDetailsPage/routes';
import andromedaSysSettingsAbrechnungskreisPageRoute from './KundenSystem/AbrechnungskreisPage';
import andromedaSysSettingsAuftragsartPageRoute from './KundenSystem/AuftragsartPage/Listing';
import andromedaSysSettingsAuftragsartCreatePageRoute from './KundenSystem/AuftragsartPage/Create';
import andromedaSysSettingsAuftragsartUpdatePageRoute from './KundenSystem/AuftragsartPage/Update';
import andromedaSysSettingsVorschreibunspositionPageRoute from './KundenSystem/VorschreibungspositionPage';
import andromedaSysSettingsUstKategoriePageRoute from './KundenSystem/UstKategoriePage';
import andromedaSysSettingsTextPageRoute from './KundenSystem/TextPage';
import andromedaSysSettingsUstRegelwerkPageRoute from './KundenSystem/UstRegelwerkPage';
import andromedaSysSettingsMahndefinitionPageRoute from './KundenSystem/MahndefinitionPage/Listing';
import andromedaSysSettingsMahndefinitionCreatePageRoute from './KundenSystem/MahndefinitionPage/Create';
import andromedaSysSettingsMahndefinitionUpdatePageRoute from './KundenSystem/MahndefinitionPage/Update';
import andromedaSysSettingsBudgetingVerknuepfungVPosAuftSchluesselPageRoute from './KundenSystem/BudgetingVerknuepfungVPosAuftSchluesselPage';
import andromedaSysSettingsKontenrahmenPageRoute from './KundenSystem/KontenrahmenPage';
import andromedaSysSettingsVorsteuerkuerzungPageRoute from './KundenSystem/VorsteuerkuerzungPage';
import andromedaSysSettingsRepFondsPageRoute from './KundenSystem/RepFondsPage';
import andromedaSysSettingsBudgetingVerknuepfungAbrDefVPosPageRoute from './KundenSystem/BudgetingVerknuepfungAbrDefVPosPage';
import andromedaSysSettingsAbrKontenzuordnungPageRoute from './KundenSystem/AbrKontenzuordnungPage';
import andromedaSysSettingsAbrechnungsdefinitionPageRoute from './KundenSystem/AbrechnungsdefinitionPage/Listing';
import andromedaSysSettingsAbrechnungsdefinitionCreatePageRoute from './KundenSystem/AbrechnungsdefinitionPage/Create';
import andromedaSysSettingsAbrechnungsdefinitionVersionCreatePageRoute from './KundenSystem/AbrechnungsdefinitionPage/Version/Create';
import andromedaAbrechnungsdefinitionDetailsPageRoute from './KundenSystem/AbrechnungsdefinitionPage/Version/Update';
import andromedaSysSettingsAbrechnungsdefinitionVersionViewPageRoute from './KundenSystem/AbrechnungsdefinitionPage/Version/View';

const customSystemSettingsPageRoute = (
  <Route path={URI_ANDROMEDA_SYSTEM_SETTINGS} element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSystemSettingPage />} />}>
    {andromedaSysSettingsVerwaltungBenutzerPageRoute}
    {andromedaSysSettingsVerwaltungBenutzerDetailsPageRoute}
    {andromedaSysSettingsAdminDetailsPageRoute}
    {andromedaSysSettingsVerwaltungAdminPageRoute}
    {andromedaSysSettingsAufteilungsschluesselPageRoute}
    {andromedaSysSettingsVorschreibunspositionPageRoute}
    {andromedaSysSettingsAufgabenartPageRoute}
    {andromedaSysSettingsAuftragsartCreatePageRoute}
    {andromedaSysSettingsAuftragsartUpdatePageRoute}
    {andromedaSysSettingsAuftragsartPageRoute}
    {andromedaSysSettingsAbrechnungskreisPageRoute}
    {andromedaSysSettingsBudgetingVerknuepfungAbrDefVPosPageRoute}
    {andromedaSysSettingsBudgetingVerknuepfungVPosAuftSchluesselPageRoute}
    {andromedaSysSettingsKontierungstabellePageRoute}
    {andromedaSysSettingsEmailDeliveryDefinitionPageRoute}
    {andromedaSysSettingsIndexSeriesDetailsPageRoute}
    {andromedaSysSettingsIndexSeriesPageRoute}
    {andromedaSysSettingsEbicsBankConfigPageRoute}
    {andromedaSysSettingsFieldHelpPageRoute}
    {andromedaSysSettingsTokenPageRoute}
    {andromedaSysSettingsKundenSystemListPageRoute}
    {andormedaSysSettingsKundenSystemDetailsPageRoute}
    {andromedaSysSettingsTextPageRoute}
    {andromedaSysSettingsMahndefinitionPageRoute}
    {andromedaSysSettingsMahndefinitionCreatePageRoute}
    {andromedaSysSettingsMahndefinitionUpdatePageRoute}
    {andromedaSysSettingsUstKategoriePageRoute}
    {andromedaSysSettingsUstRegelwerkPageRoute}
    {andromedaSysSettingsKontenrahmenPageRoute}
    {andromedaSysSettingsVorsteuerkuerzungPageRoute}
    {andromedaSysSettingsRepFondsPageRoute}
    {andromedaSysSettingsAbrKontenzuordnungPageRoute}
    {andromedaSysSettingsAbrechnungsdefinitionPageRoute}
    {andromedaSysSettingsAbrechnungsdefinitionCreatePageRoute}
    {andromedaSysSettingsAbrechnungsdefinitionVersionCreatePageRoute}
    {andromedaAbrechnungsdefinitionDetailsPageRoute}
    {andromedaSysSettingsAbrechnungsdefinitionVersionViewPageRoute}
  </Route>
);

export default customSystemSettingsPageRoute;
