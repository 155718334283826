import * as Yup from 'yup';
import { entityIsRequired, entityMustBeSelected } from '../../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */

export const zinslisteCreateForObjektCreateValidationSchema = Yup.object().shape({
  vorschreibungsperiode: Yup.string().required(entityIsRequired('Vorschreibungsperiode')),
});

export const zinslisteCreateValidationSchema = Yup.object().shape({
  vorschreibungsperiodeList: Yup.array().required(entityMustBeSelected('Vorschreibungsperiode')),
});

/* eslint-enable no-template-curly-in-string */
