import React, { FC, useEffect, useState } from 'react';
import { InputNumberProps } from 'antd';
import { useBudgetingUpdateKontoEstimateMutation } from '../../../gql/BudgetingMutations.types';
import { Budgeting, BudgetingKonto, BudgetingUpdateKontoAction } from '../../../../../types';
import { isNotNil } from '../../../../../helpers/assertionHelper';
import DebouncedFormattedDecimalInput from '../../../../../components/Input-antd/DebouncedFormattedDecimalInput';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { showSuccessMsgOther } from '../../../../../components/message';
import { isStatusArchived, isStatusVerarbeitet } from '../../../../../helpers/statusHelper';

type Props = {
  budgeting: Budgeting;
  budgetingKonto: BudgetingKonto;
  refetch: () => Promise<unknown>;
  isUpdating: boolean;
  updatingRowKey: string | null;
  setUpdatingRowKey: React.Dispatch<React.SetStateAction<string | null>>;
  disabled?: boolean;
};

const BudgetingKontoEstimateRestOfYear: FC<Props> = ({
  budgeting,
  budgetingKonto,
  refetch,
  isUpdating,
  updatingRowKey,
  setUpdatingRowKey,
  disabled,
}) => {
  const {
    budgetingId,
    objekt: { objektId },
  } = budgeting;
  const { estimateRestOfCurrentYear, budgetingKontoId } = budgetingKonto;

  const [status, setStatus] = useState<InputNumberProps<number>['status']>('');
  const [value, setValue] = useState<InputNumberProps['value']>(estimateRestOfCurrentYear);
  const [runUpdate] = useBudgetingUpdateKontoEstimateMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Budgetierung aktualisiert`);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  useEffect(() => {
    setValue(estimateRestOfCurrentYear);
  }, [estimateRestOfCurrentYear]);

  const isUpdatingByCurrentRow = isUpdating && updatingRowKey === budgetingKontoId;
  const isUpdatingByNotRow = isUpdating && updatingRowKey === null;

  const isDisabled =
    disabled || isUpdatingByCurrentRow || isUpdatingByNotRow || isStatusArchived(budgeting.status) || isStatusVerarbeitet(budgeting.status);

  const onChange = (inputValue: InputNumberProps['value']) => {
    if (inputValue === estimateRestOfCurrentYear) {
      setStatus('');
      return;
    }

    if (!isNotNil(inputValue)) {
      setStatus('error');
      return;
    }
    const value = Number(inputValue) || 0;
    if (value < 0) {
      setStatus('error');
      return;
    }

    setStatus('');
    setValue(inputValue);
    setUpdatingRowKey(budgetingKontoId);
    runUpdate({
      variables: { budgetingId, objektId, input: { budgetingKontoId, value, fieldName: BudgetingUpdateKontoAction.EstimateRestOfCurrentYear } },
    }).finally(() => {
      setUpdatingRowKey(null);
    });
  };

  return (
    <DebouncedFormattedDecimalInput
      name="erhoehung"
      disabled={isDisabled}
      status={status}
      controls={false}
      size="small"
      value={value}
      isCurrency
      style={{ width: 110 }}
      onChange={onChange}
    />
  );
};

export default BudgetingKontoEstimateRestOfYear;
