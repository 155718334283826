import { isNil, isNotNil } from '../../../../helpers/assertionHelper';
import {
  flatBestandseinheitList,
  FlattenZinslisteBestandseinheit,
} from '../../DetailsCard/ZinslisteDetails/helpers/bestandseinheitListFlattingHelpers';
import { Zinsliste, ZinslisteVertragVorschreibungsposition } from '../../../../types';
import { TVPosForVertragLevel, TVPosForVertragLevelSummary } from './vPosForVertragLevelList.model';

export const mapZinslisteToVPosGroupedByVertragLevelForListSummary = (zinsliste: Zinsliste) => {
  const bestandseinheitList = flatBestandseinheitList(zinsliste.bestandseinheitList);
  const bestandseinheitListGroupedByVertragLevel: Record<string, any> = {};

  for (let i = 0; i < bestandseinheitList.length; i += 1) {
    const vertragLevel = bestandseinheitList[i].vertrag.vertragLevel.value;
    if (!bestandseinheitListGroupedByVertragLevel[vertragLevel]) {
      bestandseinheitListGroupedByVertragLevel[vertragLevel] = [bestandseinheitList[i]];
    } else if (bestandseinheitListGroupedByVertragLevel[vertragLevel]) {
      bestandseinheitListGroupedByVertragLevel[vertragLevel].push(bestandseinheitList[i]);
    }
  }

  const vposGroupedByVertragLevel: Record<string, any> = {};

  Object.keys(bestandseinheitListGroupedByVertragLevel).forEach((level) => {
    vposGroupedByVertragLevel[level] = bestandseinheitListGroupedByVertragLevel[level]
      .filter((be: FlattenZinslisteBestandseinheit) => be.vertrag)
      .map((be: FlattenZinslisteBestandseinheit) => be.vertrag.vertragVorschreibungspositionList)
      .flatMap((vpWertList: ZinslisteVertragVorschreibungsposition[]) => [...vpWertList])
      .filter(
        (vpWert: ZinslisteVertragVorschreibungsposition) =>
          isNotNil(vpWert.steuersatz) && isNotNil(vpWert.netto) && isNotNil(vpWert.brutto) && vpWert.netto !== 0
      )
      .reduce(groupByVorschreibungsposition, [] as TVPosWertListByBezeichnung[])
      .reduce(calculateSumsByVorschreibungsposition, [] as TVPosForVertragLevel[])
      .sort(sortAscByVorschreibungspositionBezeichnung)
      .reduce(calculateSumsForVertrag, {
        vertragLevel: bestandseinheitListGroupedByVertragLevel[level][0].vertrag.vertragLevel,
        sumNetto: 0,
        sumBrutto: 0,
        vorschreibungspositionList: [],
      } as TVPosForVertragLevelSummary);
  });

  return vposGroupedByVertragLevel;
};

export const mapZinslisteToVPosForVertragsartListSummary = (zinsliste: Zinsliste) => {
  const bestandseinheitList = flatBestandseinheitList(zinsliste.bestandseinheitList);

  return bestandseinheitList
    .filter((be) => be.vertrag)
    .map((be) => be.vertrag.vertragVorschreibungspositionList)
    .flatMap((vpWertList) => [...vpWertList])
    .filter((vpWert) => isNotNil(vpWert.steuersatz) && isNotNil(vpWert.netto) && isNotNil(vpWert.brutto) && vpWert.netto !== 0)
    .reduce(groupByVorschreibungsposition, [] as TVPosWertListByBezeichnung[])
    .reduce(calculateSumsByVorschreibungsposition, [] as TVPosForVertragLevel[])
    .sort(sortAscByVorschreibungspositionBezeichnung)
    .reduce(calculateSumsForVertrag, { sumNetto: 0, sumBrutto: 0, vorschreibungspositionList: [] } as TVPosForVertragLevelSummary);
};

const groupByVorschreibungsposition = (acc: TVPosWertListByBezeichnung[], vpWert: ZinslisteVertragVorschreibungsposition) => {
  const existingVPWertListByBezeichnung = acc.find((vpWertListByBezeichnung) => vpWertListByBezeichnung.bezeichnung === vpWert.bezeichnung);
  if (!existingVPWertListByBezeichnung) {
    acc.push({ bezeichnung: vpWert.bezeichnung, vpWertList: [vpWert] });
  } else {
    existingVPWertListByBezeichnung.vpWertList.push(vpWert);
  }
  return acc;
};

const calculateSumsByVorschreibungsposition = (acc: TVPosForVertragLevel[], vpWertByBezeichnung: TVPosWertListByBezeichnung) => {
  const sumNettoBySteuersatz = calculateSumNettoBySteuersatzAndSortAscBySteuersatz(vpWertByBezeichnung.vpWertList);
  const sumNettoBrutto = calculateSumNettoBruttoForVorschreibungsposition(vpWertByBezeichnung.vpWertList);

  const sumsByVpAndSteuersatz: TVPosForVertragLevel = {
    bezeichnung: vpWertByBezeichnung.bezeichnung,
    sumNetto: sumNettoBrutto.sumNetto,
    sumBrutto: sumNettoBrutto.sumBrutto,
    ustColList: sumNettoBySteuersatz,
  };

  return [...acc, sumsByVpAndSteuersatz];
};

const calculateSumNettoBySteuersatzAndSortAscBySteuersatz = (vpWertList: ZinslisteVertragVorschreibungsposition[]) => {
  return vpWertList
    .reduce(
      (acc, vpWert) => {
        if (isNil(vpWert.steuersatz)) return acc;
        const vpWertBySteuersatz = acc.find((vpWertBySteuersatz) => vpWertBySteuersatz.steuersatz === vpWert.steuersatz);
        const vpWertNetto = vpWert.netto ?? 0;
        if (!vpWertBySteuersatz) {
          acc.push({ steuersatz: vpWert.steuersatz, sumNetto: vpWertNetto });
        } else {
          vpWertBySteuersatz.sumNetto += vpWertNetto;
        }
        return acc;
      },
      [] as { steuersatz: number; sumNetto: number }[]
    )
    .sort((sumBySteuersatz1, sumBySteuersatz2) => sumBySteuersatz1.steuersatz - sumBySteuersatz2.steuersatz);
};

const calculateSumNettoBruttoForVorschreibungsposition = (vpWertList: ZinslisteVertragVorschreibungsposition[]) => {
  return vpWertList.reduce(
    (acc, vpWert) => {
      const netto = vpWert.netto ?? 0;
      const brutto = vpWert.brutto ?? 0;
      acc.sumNetto += netto;
      acc.sumBrutto += brutto;
      return acc;
    },
    { sumNetto: 0, sumBrutto: 0 }
  );
};

const calculateSumsForVertrag = (acc: TVPosForVertragLevelSummary, sumsByVpAndSteuersatz: TVPosForVertragLevel) => {
  return {
    ...acc,
    sumNetto: sumsByVpAndSteuersatz.sumNetto + acc.sumNetto,
    sumBrutto: sumsByVpAndSteuersatz.sumBrutto + acc.sumBrutto,
    vorschreibungspositionList: [...acc.vorschreibungspositionList, sumsByVpAndSteuersatz],
  };
};

const sortAscByVorschreibungspositionBezeichnung = (vp1: TVPosForVertragLevel, vp2: TVPosForVertragLevel) =>
  vp1.bezeichnung >= vp2.bezeichnung ? 1 : -1;

type TVPosWertListByBezeichnung = { bezeichnung: string; vpWertList: ZinslisteVertragVorschreibungsposition[] };
