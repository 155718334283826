import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { AbrechnungskreisTypeTuple, SubAbrKonto } from '../../../../../../../types';
import { NestedTableWithColSelectorColProps } from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { EuroAmount } from '../../../../../../../components/Number';
import { isAbrKreisTypeAusgaben } from '../../../../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { generatePathToKontoblattPage } from '../../../../../../Kontoblatt/kontoblattUriPaths';

const kontoTableColumns = (
  abrechnungskreisType: AbrechnungskreisTypeTuple,
  buchungskreisId?: string,
  objektId?: string,
  abrechnungszeitraumVon?: string,
  abrechnungszeitraumBis?: string
): NestedTableWithColSelectorColProps<SubAbrKonto>[] => [
  {
    title: 'Konto',
    defaultSelected: true,
    render: (text, record) => (
      <Space size={0}>
        <Typography.Text>{record.bezeichnung}</Typography.Text>
        {record.amountReducingType && <Typography.Text type="secondary"> ({record.amountReducingType.text})</Typography.Text>}
      </Space>
    ),
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: isAbrKreisTypeAusgaben(abrechnungskreisType.value) ? 'Ausgaben' : 'Einnahmen',
    key: 'einnahmenAusgaben',
    width: 180,
    align: 'right',
    defaultSelected: true,
    render: (text, record) => (
      <Link
        to={generatePathToKontoblattPage({
          kontoId: record.kontoId,
          objektId,
          buchungsdatumFrom: abrechnungszeitraumVon,
          buchungsdatumTo: abrechnungszeitraumBis,
          buchungskreisId,
        })}
        target="_blank"
      >
        <EuroAmount value={record.summe} />
      </Link>
    ),
  },
  {
    title: '',
    key: 'summe',
  },
  {
    title: '',
    key: 'ergebnis',
  },
];

export default kontoTableColumns;
