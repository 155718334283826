import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNatuerlichePersonAddressMutationVariables = Types.Exact<{
  natuerlichePersonId: Types.Scalars['ID']['input'];
  input: Types.AddressCreateInput;
}>;

export type CreateNatuerlichePersonAddressMutation = {
  createNatuerlichePersonAddress: {
    data: { addressId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateNatuerlichePersonContactMutationVariables = Types.Exact<{
  natuerlichePersonId: Types.Scalars['ID']['input'];
  input: Types.ContactListCreateInput;
}>;

export type CreateNatuerlichePersonContactMutation = {
  createNatuerlichePersonContact: {
    data: Array<{ contactId: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreatePersonenGemeinschaftAddressMutationVariables = Types.Exact<{
  personenGemeinschaftId: Types.Scalars['ID']['input'];
  input: Types.AddressCreateInput;
}>;

export type CreatePersonenGemeinschaftAddressMutation = {
  createPersonenGemeinschaftAddress: {
    data: { addressId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreatePersonenGemeinschaftContactMutationVariables = Types.Exact<{
  personenGemeinschaftId: Types.Scalars['ID']['input'];
  input: Types.ContactListCreateInput;
}>;

export type CreatePersonenGemeinschaftContactMutation = {
  createPersonenGemeinschaftContact: {
    data: Array<{ contactId: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFirmaAddressMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
  input: Types.AddressCreateInput;
}>;

export type CreateFirmaAddressMutation = {
  createFirmaAddress: {
    data: { addressId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFirmaContactMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
  input: Types.ContactListCreateInput;
}>;

export type CreateFirmaContactMutation = {
  createFirmaContact: {
    data: Array<{ contactId: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFirmaContactPersonMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
  input: Types.ContactPersonCreateInput;
}>;

export type CreateFirmaContactPersonMutation = {
  createFirmaContactPerson: {
    data: { contactPersonId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFirmendatenAddressMutationVariables = Types.Exact<{
  input: Types.AddressCreateInput;
}>;

export type CreateFirmendatenAddressMutation = {
  createFirmendatenAddress: {
    data: { addressId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFirmendatenContactMutationVariables = Types.Exact<{
  input: Types.ContactListCreateInput;
}>;

export type CreateFirmendatenContactMutation = {
  createFirmendatenContact: {
    data: Array<{ contactId: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFirmendatenContactPersonMutationVariables = Types.Exact<{
  input: Types.ContactPersonCreateInput;
}>;

export type CreateFirmendatenContactPersonMutation = {
  createFirmendatenContactPerson: {
    data: { contactPersonId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateFirmendatenContactPersonMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  contactPersonId: Types.Scalars['ID']['input'];
  input: Types.ContactPersonUpdateInput;
}>;

export type UpdateFirmendatenContactPersonMutation = {
  updateFirmendatenContactPerson: {
    data: { contactPersonId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateFirmaContactPersonMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['String']['input'];
  contactPersonId: Types.Scalars['ID']['input'];
  input: Types.ContactPersonUpdateInput;
}>;

export type UpdateFirmaContactPersonMutation = {
  updateFirmaContactPerson: {
    data: { contactPersonId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteContactPersonMutationVariables = Types.Exact<{
  contactPersonId: Types.Scalars['ID']['input'];
}>;

export type DeleteContactPersonMutation = {
  deleteContactPerson: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type UpdateAddressMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  addressId: Types.Scalars['ID']['input'];
  input: Types.AddressUpdateInput;
}>;

export type UpdateAddressMutation = {
  updateAddress: {
    data: { addressId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteAddressMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  addressId: Types.Scalars['ID']['input'];
}>;

export type DeleteAddressMutation = {
  deleteAddress: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type UpdateContactMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  contactId: Types.Scalars['ID']['input'];
  input: Types.ContactUpdateInput;
}>;

export type UpdateContactMutation = {
  updateContact: {
    data: { contactId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteContactMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  contactId: Types.Scalars['ID']['input'];
}>;

export type DeleteContactMutation = {
  deleteContact: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type SetAdresseTagListForAddressMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  addressId: Types.Scalars['ID']['input'];
  input: Types.AddressAndContactTagListInput;
}>;

export type SetAdresseTagListForAddressMutation = {
  actionSetAddressTagListForAddress: {
    data: { addressId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetContactTagListForContactMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  contactId: Types.Scalars['ID']['input'];
  input: Types.AddressAndContactTagListInput;
}>;

export type SetContactTagListForContactMutation = {
  actionSetContactTagListForContact: {
    data: { contactId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetOperatingSiteForAddressMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  addressId: Types.Scalars['ID']['input'];
  input: Types.OperatingSiteActionInput;
}>;

export type SetOperatingSiteForAddressMutation = {
  actionSetOperatingSiteForAddress: {
    data: { addressId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetOperatingSiteForContactMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  contactId: Types.Scalars['ID']['input'];
  input: Types.OperatingSiteActionInput;
}>;

export type SetOperatingSiteForContactMutation = {
  actionSetOperatingSiteForContact: {
    data: { contactId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetOperatingSiteForFirmendatenContactPersonMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  contactPersonId: Types.Scalars['ID']['input'];
  input: Types.OperatingSiteActionInput;
}>;

export type SetOperatingSiteForFirmendatenContactPersonMutation = {
  actionSetOperatingSiteForFirmendatenContactPerson: {
    data: { contactPersonId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetOperatingSiteForFirmaContactPersonMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['String']['input'];
  contactPersonId: Types.Scalars['ID']['input'];
  input: Types.OperatingSiteActionInput;
}>;

export type SetOperatingSiteForFirmaContactPersonMutation = {
  actionSetOperatingSiteForFirmaContactPerson: {
    data: { contactPersonId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetContactPersonTagListForFirmendatenContactPersonMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  contactPersonId: Types.Scalars['ID']['input'];
  input: Types.OperatingSiteActionInput;
}>;

export type SetContactPersonTagListForFirmendatenContactPersonMutation = {
  actionSetContactPersonTagListForFirmendatenContactPerson: {
    data: { contactPersonId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetContactPersonTagListForFirmaContactPersonMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['String']['input'];
  contactPersonId: Types.Scalars['ID']['input'];
  input: Types.OperatingSiteActionInput;
}>;

export type SetContactPersonTagListForFirmaContactPersonMutation = {
  actionSetContactPersonTagListForFirmaContactPerson: {
    data: { contactPersonId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateNatuerlichePersonAddressDocument = gql`
  mutation CreateNatuerlichePersonAddress($natuerlichePersonId: ID!, $input: AddressCreateInput!) {
    createNatuerlichePersonAddress(natuerlichePersonId: $natuerlichePersonId, input: $input) {
      data {
        addressId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateNatuerlichePersonAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNatuerlichePersonAddressMutation, CreateNatuerlichePersonAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNatuerlichePersonAddressMutation, CreateNatuerlichePersonAddressMutationVariables>(
    CreateNatuerlichePersonAddressDocument,
    options
  );
}
export type CreateNatuerlichePersonAddressMutationHookResult = ReturnType<typeof useCreateNatuerlichePersonAddressMutation>;
export type CreateNatuerlichePersonAddressMutationResult = Apollo.MutationResult<CreateNatuerlichePersonAddressMutation>;
export type CreateNatuerlichePersonAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateNatuerlichePersonAddressMutation,
  CreateNatuerlichePersonAddressMutationVariables
>;
export const CreateNatuerlichePersonContactDocument = gql`
  mutation CreateNatuerlichePersonContact($natuerlichePersonId: ID!, $input: ContactListCreateInput!) {
    createNatuerlichePersonContact(natuerlichePersonId: $natuerlichePersonId, input: $input) {
      data {
        contactId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateNatuerlichePersonContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNatuerlichePersonContactMutation, CreateNatuerlichePersonContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNatuerlichePersonContactMutation, CreateNatuerlichePersonContactMutationVariables>(
    CreateNatuerlichePersonContactDocument,
    options
  );
}
export type CreateNatuerlichePersonContactMutationHookResult = ReturnType<typeof useCreateNatuerlichePersonContactMutation>;
export type CreateNatuerlichePersonContactMutationResult = Apollo.MutationResult<CreateNatuerlichePersonContactMutation>;
export type CreateNatuerlichePersonContactMutationOptions = Apollo.BaseMutationOptions<
  CreateNatuerlichePersonContactMutation,
  CreateNatuerlichePersonContactMutationVariables
>;
export const CreatePersonenGemeinschaftAddressDocument = gql`
  mutation CreatePersonenGemeinschaftAddress($personenGemeinschaftId: ID!, $input: AddressCreateInput!) {
    createPersonenGemeinschaftAddress(personenGemeinschaftId: $personenGemeinschaftId, input: $input) {
      data {
        addressId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreatePersonenGemeinschaftAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePersonenGemeinschaftAddressMutation, CreatePersonenGemeinschaftAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePersonenGemeinschaftAddressMutation, CreatePersonenGemeinschaftAddressMutationVariables>(
    CreatePersonenGemeinschaftAddressDocument,
    options
  );
}
export type CreatePersonenGemeinschaftAddressMutationHookResult = ReturnType<typeof useCreatePersonenGemeinschaftAddressMutation>;
export type CreatePersonenGemeinschaftAddressMutationResult = Apollo.MutationResult<CreatePersonenGemeinschaftAddressMutation>;
export type CreatePersonenGemeinschaftAddressMutationOptions = Apollo.BaseMutationOptions<
  CreatePersonenGemeinschaftAddressMutation,
  CreatePersonenGemeinschaftAddressMutationVariables
>;
export const CreatePersonenGemeinschaftContactDocument = gql`
  mutation CreatePersonenGemeinschaftContact($personenGemeinschaftId: ID!, $input: ContactListCreateInput!) {
    createPersonenGemeinschaftContact(personenGemeinschaftId: $personenGemeinschaftId, input: $input) {
      data {
        contactId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreatePersonenGemeinschaftContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePersonenGemeinschaftContactMutation, CreatePersonenGemeinschaftContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePersonenGemeinschaftContactMutation, CreatePersonenGemeinschaftContactMutationVariables>(
    CreatePersonenGemeinschaftContactDocument,
    options
  );
}
export type CreatePersonenGemeinschaftContactMutationHookResult = ReturnType<typeof useCreatePersonenGemeinschaftContactMutation>;
export type CreatePersonenGemeinschaftContactMutationResult = Apollo.MutationResult<CreatePersonenGemeinschaftContactMutation>;
export type CreatePersonenGemeinschaftContactMutationOptions = Apollo.BaseMutationOptions<
  CreatePersonenGemeinschaftContactMutation,
  CreatePersonenGemeinschaftContactMutationVariables
>;
export const CreateFirmaAddressDocument = gql`
  mutation CreateFirmaAddress($firmaId: ID!, $input: AddressCreateInput!) {
    createFirmaAddress(firmaId: $firmaId, input: $input) {
      data {
        addressId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmaAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmaAddressMutation, CreateFirmaAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmaAddressMutation, CreateFirmaAddressMutationVariables>(CreateFirmaAddressDocument, options);
}
export type CreateFirmaAddressMutationHookResult = ReturnType<typeof useCreateFirmaAddressMutation>;
export type CreateFirmaAddressMutationResult = Apollo.MutationResult<CreateFirmaAddressMutation>;
export type CreateFirmaAddressMutationOptions = Apollo.BaseMutationOptions<CreateFirmaAddressMutation, CreateFirmaAddressMutationVariables>;
export const CreateFirmaContactDocument = gql`
  mutation CreateFirmaContact($firmaId: ID!, $input: ContactListCreateInput!) {
    createFirmaContact(firmaId: $firmaId, input: $input) {
      data {
        contactId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmaContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmaContactMutation, CreateFirmaContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmaContactMutation, CreateFirmaContactMutationVariables>(CreateFirmaContactDocument, options);
}
export type CreateFirmaContactMutationHookResult = ReturnType<typeof useCreateFirmaContactMutation>;
export type CreateFirmaContactMutationResult = Apollo.MutationResult<CreateFirmaContactMutation>;
export type CreateFirmaContactMutationOptions = Apollo.BaseMutationOptions<CreateFirmaContactMutation, CreateFirmaContactMutationVariables>;
export const CreateFirmaContactPersonDocument = gql`
  mutation CreateFirmaContactPerson($firmaId: ID!, $input: ContactPersonCreateInput!) {
    createFirmaContactPerson(firmaId: $firmaId, input: $input) {
      data {
        contactPersonId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmaContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmaContactPersonMutation, CreateFirmaContactPersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmaContactPersonMutation, CreateFirmaContactPersonMutationVariables>(CreateFirmaContactPersonDocument, options);
}
export type CreateFirmaContactPersonMutationHookResult = ReturnType<typeof useCreateFirmaContactPersonMutation>;
export type CreateFirmaContactPersonMutationResult = Apollo.MutationResult<CreateFirmaContactPersonMutation>;
export type CreateFirmaContactPersonMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmaContactPersonMutation,
  CreateFirmaContactPersonMutationVariables
>;
export const CreateFirmendatenAddressDocument = gql`
  mutation CreateFirmendatenAddress($input: AddressCreateInput!) {
    createFirmendatenAddress(input: $input) {
      data {
        addressId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmendatenAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmendatenAddressMutation, CreateFirmendatenAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmendatenAddressMutation, CreateFirmendatenAddressMutationVariables>(CreateFirmendatenAddressDocument, options);
}
export type CreateFirmendatenAddressMutationHookResult = ReturnType<typeof useCreateFirmendatenAddressMutation>;
export type CreateFirmendatenAddressMutationResult = Apollo.MutationResult<CreateFirmendatenAddressMutation>;
export type CreateFirmendatenAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmendatenAddressMutation,
  CreateFirmendatenAddressMutationVariables
>;
export const CreateFirmendatenContactDocument = gql`
  mutation createFirmendatenContact($input: ContactListCreateInput!) {
    createFirmendatenContact(input: $input) {
      data {
        contactId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmendatenContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmendatenContactMutation, CreateFirmendatenContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmendatenContactMutation, CreateFirmendatenContactMutationVariables>(CreateFirmendatenContactDocument, options);
}
export type CreateFirmendatenContactMutationHookResult = ReturnType<typeof useCreateFirmendatenContactMutation>;
export type CreateFirmendatenContactMutationResult = Apollo.MutationResult<CreateFirmendatenContactMutation>;
export type CreateFirmendatenContactMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmendatenContactMutation,
  CreateFirmendatenContactMutationVariables
>;
export const CreateFirmendatenContactPersonDocument = gql`
  mutation CreateFirmendatenContactPerson($input: ContactPersonCreateInput!) {
    createFirmendatenContactPerson(input: $input) {
      data {
        contactPersonId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmendatenContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmendatenContactPersonMutation, CreateFirmendatenContactPersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmendatenContactPersonMutation, CreateFirmendatenContactPersonMutationVariables>(
    CreateFirmendatenContactPersonDocument,
    options
  );
}
export type CreateFirmendatenContactPersonMutationHookResult = ReturnType<typeof useCreateFirmendatenContactPersonMutation>;
export type CreateFirmendatenContactPersonMutationResult = Apollo.MutationResult<CreateFirmendatenContactPersonMutation>;
export type CreateFirmendatenContactPersonMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmendatenContactPersonMutation,
  CreateFirmendatenContactPersonMutationVariables
>;
export const UpdateFirmendatenContactPersonDocument = gql`
  mutation UpdateFirmendatenContactPerson($firmendatenId: String!, $contactPersonId: ID!, $input: ContactPersonUpdateInput!) {
    updateFirmendatenContactPerson(firmendatenId: $firmendatenId, contactPersonId: $contactPersonId, input: $input) {
      data {
        contactPersonId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateFirmendatenContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFirmendatenContactPersonMutation, UpdateFirmendatenContactPersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmendatenContactPersonMutation, UpdateFirmendatenContactPersonMutationVariables>(
    UpdateFirmendatenContactPersonDocument,
    options
  );
}
export type UpdateFirmendatenContactPersonMutationHookResult = ReturnType<typeof useUpdateFirmendatenContactPersonMutation>;
export type UpdateFirmendatenContactPersonMutationResult = Apollo.MutationResult<UpdateFirmendatenContactPersonMutation>;
export type UpdateFirmendatenContactPersonMutationOptions = Apollo.BaseMutationOptions<
  UpdateFirmendatenContactPersonMutation,
  UpdateFirmendatenContactPersonMutationVariables
>;
export const UpdateFirmaContactPersonDocument = gql`
  mutation updateFirmaContactPerson($firmaId: String!, $contactPersonId: ID!, $input: ContactPersonUpdateInput!) {
    updateFirmaContactPerson(firmaId: $firmaId, contactPersonId: $contactPersonId, input: $input) {
      data {
        contactPersonId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateFirmaContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFirmaContactPersonMutation, UpdateFirmaContactPersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmaContactPersonMutation, UpdateFirmaContactPersonMutationVariables>(UpdateFirmaContactPersonDocument, options);
}
export type UpdateFirmaContactPersonMutationHookResult = ReturnType<typeof useUpdateFirmaContactPersonMutation>;
export type UpdateFirmaContactPersonMutationResult = Apollo.MutationResult<UpdateFirmaContactPersonMutation>;
export type UpdateFirmaContactPersonMutationOptions = Apollo.BaseMutationOptions<
  UpdateFirmaContactPersonMutation,
  UpdateFirmaContactPersonMutationVariables
>;
export const DeleteContactPersonDocument = gql`
  mutation deleteContactPerson($contactPersonId: ID!) {
    deleteContactPerson(contactPersonId: $contactPersonId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContactPersonMutation, DeleteContactPersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteContactPersonMutation, DeleteContactPersonMutationVariables>(DeleteContactPersonDocument, options);
}
export type DeleteContactPersonMutationHookResult = ReturnType<typeof useDeleteContactPersonMutation>;
export type DeleteContactPersonMutationResult = Apollo.MutationResult<DeleteContactPersonMutation>;
export type DeleteContactPersonMutationOptions = Apollo.BaseMutationOptions<DeleteContactPersonMutation, DeleteContactPersonMutationVariables>;
export const UpdateAddressDocument = gql`
  mutation UpdateAddress($firmendatenId: String!, $addressId: ID!, $input: AddressUpdateInput!) {
    updateAddress(firmendatenId: $firmendatenId, addressId: $addressId, input: $input) {
      data {
        addressId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options);
}
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const DeleteAddressDocument = gql`
  mutation deleteAddress($firmendatenId: String!, $addressId: ID!) {
    deleteAddress(firmendatenId: $firmendatenId, addressId: $addressId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteAddressMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAddressMutation, DeleteAddressMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(DeleteAddressDocument, options);
}
export type DeleteAddressMutationHookResult = ReturnType<typeof useDeleteAddressMutation>;
export type DeleteAddressMutationResult = Apollo.MutationResult<DeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<DeleteAddressMutation, DeleteAddressMutationVariables>;
export const UpdateContactDocument = gql`
  mutation UpdateContact($firmendatenId: String!, $contactId: ID!, $input: ContactUpdateInput!) {
    updateContact(firmendatenId: $firmendatenId, contactId: $contactId, input: $input) {
      data {
        contactId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
}
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const DeleteContactDocument = gql`
  mutation DeleteContact($firmendatenId: String!, $contactId: ID!) {
    deleteContact(firmendatenId: $firmendatenId, contactId: $contactId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
}
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const SetAdresseTagListForAddressDocument = gql`
  mutation SetAdresseTagListForAddress($firmendatenId: String!, $addressId: ID!, $input: AddressAndContactTagListInput!) {
    actionSetAddressTagListForAddress(firmendatenId: $firmendatenId, addressId: $addressId, input: $input) {
      data {
        addressId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetAdresseTagListForAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<SetAdresseTagListForAddressMutation, SetAdresseTagListForAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetAdresseTagListForAddressMutation, SetAdresseTagListForAddressMutationVariables>(
    SetAdresseTagListForAddressDocument,
    options
  );
}
export type SetAdresseTagListForAddressMutationHookResult = ReturnType<typeof useSetAdresseTagListForAddressMutation>;
export type SetAdresseTagListForAddressMutationResult = Apollo.MutationResult<SetAdresseTagListForAddressMutation>;
export type SetAdresseTagListForAddressMutationOptions = Apollo.BaseMutationOptions<
  SetAdresseTagListForAddressMutation,
  SetAdresseTagListForAddressMutationVariables
>;
export const SetContactTagListForContactDocument = gql`
  mutation SetContactTagListForContact($firmendatenId: String!, $contactId: ID!, $input: AddressAndContactTagListInput!) {
    actionSetContactTagListForContact(firmendatenId: $firmendatenId, contactId: $contactId, input: $input) {
      data {
        contactId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetContactTagListForContactMutation(
  baseOptions?: Apollo.MutationHookOptions<SetContactTagListForContactMutation, SetContactTagListForContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetContactTagListForContactMutation, SetContactTagListForContactMutationVariables>(
    SetContactTagListForContactDocument,
    options
  );
}
export type SetContactTagListForContactMutationHookResult = ReturnType<typeof useSetContactTagListForContactMutation>;
export type SetContactTagListForContactMutationResult = Apollo.MutationResult<SetContactTagListForContactMutation>;
export type SetContactTagListForContactMutationOptions = Apollo.BaseMutationOptions<
  SetContactTagListForContactMutation,
  SetContactTagListForContactMutationVariables
>;
export const SetOperatingSiteForAddressDocument = gql`
  mutation SetOperatingSiteForAddress($firmendatenId: String!, $addressId: ID!, $input: OperatingSiteActionInput!) {
    actionSetOperatingSiteForAddress(firmendatenId: $firmendatenId, addressId: $addressId, input: $input) {
      data {
        addressId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetOperatingSiteForAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<SetOperatingSiteForAddressMutation, SetOperatingSiteForAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOperatingSiteForAddressMutation, SetOperatingSiteForAddressMutationVariables>(
    SetOperatingSiteForAddressDocument,
    options
  );
}
export type SetOperatingSiteForAddressMutationHookResult = ReturnType<typeof useSetOperatingSiteForAddressMutation>;
export type SetOperatingSiteForAddressMutationResult = Apollo.MutationResult<SetOperatingSiteForAddressMutation>;
export type SetOperatingSiteForAddressMutationOptions = Apollo.BaseMutationOptions<
  SetOperatingSiteForAddressMutation,
  SetOperatingSiteForAddressMutationVariables
>;
export const SetOperatingSiteForContactDocument = gql`
  mutation SetOperatingSiteForContact($firmendatenId: String!, $contactId: ID!, $input: OperatingSiteActionInput!) {
    actionSetOperatingSiteForContact(firmendatenId: $firmendatenId, contactId: $contactId, input: $input) {
      data {
        contactId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetOperatingSiteForContactMutation(
  baseOptions?: Apollo.MutationHookOptions<SetOperatingSiteForContactMutation, SetOperatingSiteForContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOperatingSiteForContactMutation, SetOperatingSiteForContactMutationVariables>(
    SetOperatingSiteForContactDocument,
    options
  );
}
export type SetOperatingSiteForContactMutationHookResult = ReturnType<typeof useSetOperatingSiteForContactMutation>;
export type SetOperatingSiteForContactMutationResult = Apollo.MutationResult<SetOperatingSiteForContactMutation>;
export type SetOperatingSiteForContactMutationOptions = Apollo.BaseMutationOptions<
  SetOperatingSiteForContactMutation,
  SetOperatingSiteForContactMutationVariables
>;
export const SetOperatingSiteForFirmendatenContactPersonDocument = gql`
  mutation SetOperatingSiteForFirmendatenContactPerson($firmendatenId: String!, $contactPersonId: ID!, $input: OperatingSiteActionInput!) {
    actionSetOperatingSiteForFirmendatenContactPerson(firmendatenId: $firmendatenId, contactPersonId: $contactPersonId, input: $input) {
      data {
        contactPersonId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetOperatingSiteForFirmendatenContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOperatingSiteForFirmendatenContactPersonMutation,
    SetOperatingSiteForFirmendatenContactPersonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOperatingSiteForFirmendatenContactPersonMutation, SetOperatingSiteForFirmendatenContactPersonMutationVariables>(
    SetOperatingSiteForFirmendatenContactPersonDocument,
    options
  );
}
export type SetOperatingSiteForFirmendatenContactPersonMutationHookResult = ReturnType<typeof useSetOperatingSiteForFirmendatenContactPersonMutation>;
export type SetOperatingSiteForFirmendatenContactPersonMutationResult = Apollo.MutationResult<SetOperatingSiteForFirmendatenContactPersonMutation>;
export type SetOperatingSiteForFirmendatenContactPersonMutationOptions = Apollo.BaseMutationOptions<
  SetOperatingSiteForFirmendatenContactPersonMutation,
  SetOperatingSiteForFirmendatenContactPersonMutationVariables
>;
export const SetOperatingSiteForFirmaContactPersonDocument = gql`
  mutation SetOperatingSiteForFirmaContactPerson($firmaId: String!, $contactPersonId: ID!, $input: OperatingSiteActionInput!) {
    actionSetOperatingSiteForFirmaContactPerson(firmaId: $firmaId, contactPersonId: $contactPersonId, input: $input) {
      data {
        contactPersonId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetOperatingSiteForFirmaContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<SetOperatingSiteForFirmaContactPersonMutation, SetOperatingSiteForFirmaContactPersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOperatingSiteForFirmaContactPersonMutation, SetOperatingSiteForFirmaContactPersonMutationVariables>(
    SetOperatingSiteForFirmaContactPersonDocument,
    options
  );
}
export type SetOperatingSiteForFirmaContactPersonMutationHookResult = ReturnType<typeof useSetOperatingSiteForFirmaContactPersonMutation>;
export type SetOperatingSiteForFirmaContactPersonMutationResult = Apollo.MutationResult<SetOperatingSiteForFirmaContactPersonMutation>;
export type SetOperatingSiteForFirmaContactPersonMutationOptions = Apollo.BaseMutationOptions<
  SetOperatingSiteForFirmaContactPersonMutation,
  SetOperatingSiteForFirmaContactPersonMutationVariables
>;
export const SetContactPersonTagListForFirmendatenContactPersonDocument = gql`
  mutation SetContactPersonTagListForFirmendatenContactPerson($firmendatenId: String!, $contactPersonId: ID!, $input: OperatingSiteActionInput!) {
    actionSetContactPersonTagListForFirmendatenContactPerson(firmendatenId: $firmendatenId, contactPersonId: $contactPersonId, input: $input) {
      data {
        contactPersonId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetContactPersonTagListForFirmendatenContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetContactPersonTagListForFirmendatenContactPersonMutation,
    SetContactPersonTagListForFirmendatenContactPersonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetContactPersonTagListForFirmendatenContactPersonMutation,
    SetContactPersonTagListForFirmendatenContactPersonMutationVariables
  >(SetContactPersonTagListForFirmendatenContactPersonDocument, options);
}
export type SetContactPersonTagListForFirmendatenContactPersonMutationHookResult = ReturnType<
  typeof useSetContactPersonTagListForFirmendatenContactPersonMutation
>;
export type SetContactPersonTagListForFirmendatenContactPersonMutationResult =
  Apollo.MutationResult<SetContactPersonTagListForFirmendatenContactPersonMutation>;
export type SetContactPersonTagListForFirmendatenContactPersonMutationOptions = Apollo.BaseMutationOptions<
  SetContactPersonTagListForFirmendatenContactPersonMutation,
  SetContactPersonTagListForFirmendatenContactPersonMutationVariables
>;
export const SetContactPersonTagListForFirmaContactPersonDocument = gql`
  mutation SetContactPersonTagListForFirmaContactPerson($firmaId: String!, $contactPersonId: ID!, $input: OperatingSiteActionInput!) {
    actionSetContactPersonTagListForFirmaContactPerson(firmaId: $firmaId, contactPersonId: $contactPersonId, input: $input) {
      data {
        contactPersonId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetContactPersonTagListForFirmaContactPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetContactPersonTagListForFirmaContactPersonMutation,
    SetContactPersonTagListForFirmaContactPersonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetContactPersonTagListForFirmaContactPersonMutation, SetContactPersonTagListForFirmaContactPersonMutationVariables>(
    SetContactPersonTagListForFirmaContactPersonDocument,
    options
  );
}
export type SetContactPersonTagListForFirmaContactPersonMutationHookResult = ReturnType<
  typeof useSetContactPersonTagListForFirmaContactPersonMutation
>;
export type SetContactPersonTagListForFirmaContactPersonMutationResult = Apollo.MutationResult<SetContactPersonTagListForFirmaContactPersonMutation>;
export type SetContactPersonTagListForFirmaContactPersonMutationOptions = Apollo.BaseMutationOptions<
  SetContactPersonTagListForFirmaContactPersonMutation,
  SetContactPersonTagListForFirmaContactPersonMutationVariables
>;
