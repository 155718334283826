import { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  mapFormValuesToObjAbrVerarbeitungFilters,
  ObjAbrVerarbeitungEntryTableFormValues,
  objAbrVerarbEntryListingFiltersFormInitialValues,
} from './Filters/ListingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import ObjAbrVerarbeitungEntryTable from './ObjAbrVerarbeitungEntryTable';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { TObjAbrVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { AppGenerierlaufType, ObjektAbrechnungGenerierlauf } from '../../../../types';

type Props = {
  type: string;
  generierlauf: ObjektAbrechnungGenerierlauf;
  generierlaufType: AppGenerierlaufType;
};

const ObjAbrVerarbeitungEntryListing: FC<Props> = ({ type, generierlauf, generierlaufType }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TObjAbrVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(type, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: ObjAbrVerarbeitungEntryTableFormValues) => {
    updateQueryParams(type, generierlauf.generierlaufId, navigate, mapFormValuesToObjAbrVerarbeitungFilters(values), paginationParams);
  };

  return (
    <Formik<ObjAbrVerarbeitungEntryTableFormValues> initialValues={objAbrVerarbEntryListingFiltersFormInitialValues(queryParams)} onSubmit={onChange}>
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufType={generierlaufType} generierlaufId={generierlauf.generierlaufId} />
          <ObjAbrVerarbeitungEntryTable queryParams={queryParams} generierlauf={generierlauf} type={type} />
        </>
      )}
    </Formik>
  );
};

export default ObjAbrVerarbeitungEntryListing;
