import styled from 'styled-components';
import { Space } from 'antd';

export const StyledSpaceSplitA30 = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 33%;
    display: flex;
    justify-content: flex-start;
  }
`;

export const StyledSpaceSplit60And40 = styled(Space)`
  width: 100%;

  > .ant-space-item {
    &:first-child {
      width: 60%;
    }
    &:last-child {
      width: 40%;
    }
  }
`;
