import { Button, Drawer, Tabs, TabsProps } from 'antd';
import React, { FC } from 'react';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { Box } from 'rebass';
import { useTimelineOpen } from '../../../../components/Timeline/useTimelineOpen';
import UstRegelsetVersionTimeline from '../Version/UstRegelsetVersionTimeline';
import { UstRegelset } from '../../../../types';

type Props = {
  onActionSuccess: () => void;
  ustRegelset: UstRegelset;
};

const UstRegelsetTableActionAndDrawer: FC<Props> = ({ onActionSuccess, ustRegelset }) => {
  const { timelineDrawer, openTimelineCreateDrawer, closeTimelineDrawer } = useTimelineOpen();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined />
          Verlauf
        </span>
      ),
      children: (
        <Box p={16}>
          <UstRegelsetVersionTimeline ustRegelset={ustRegelset} reloadUstRegelset={onActionSuccess} />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Button type="link" icon={<EditOutlined />} onClick={openTimelineCreateDrawer}>
        Bearbeiten
      </Button>
      <Drawer
        title="Bearbeiten"
        width={720}
        open={timelineDrawer.visible}
        onClose={closeTimelineDrawer}
        destroyOnClose
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={closeTimelineDrawer} type="primary">
              Schließen
            </Button>
          </div>
        }
      >
        <Tabs defaultActiveKey="1" items={items} />
      </Drawer>
    </>
  );
};

export default UstRegelsetTableActionAndDrawer;
