import React, { FC } from 'react';
import FirmaCard from '../../../features/Rechtstraeger/Firma/Card/FirmaCard';
import menuListFirma from './menuListFirma';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useFirmaQuery } from '../../../features/Rechtstraeger/Firma/gql/FirmaQueries.types';
import { useRechtsTraegerRolleListQuery } from '../../../features/Rechtstraeger/Rolle/gql/RolleQueries.types';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { Firma } from '../../../types';

type Props = {
  rechtstraegerId: string;
};

const FirmaDetailsPage: FC<Props> = ({ rechtstraegerId }) => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const { data: firmaData, refetch } = useFirmaQuery({ variables: { firmaId: rechtstraegerId, withDetails: true } });
  const firma = firmaData?.getFirma.data;

  const { data: rolleListData } = useRechtsTraegerRolleListQuery({
    variables: { rechtstraegerId },
  });
  const rolleList = rolleListData?.getRechtstraegerRolleList.data;
  const loadingFirma = !firma;

  return (
    <DetailsPageTemplate<Firma>
      data={firma}
      pageTitle={(firma) => firma.kurzBezeichnung}
      card={(firma) => (
        <FirmaCard refetch={refetch} isLoading={loadingFirma} kundenSystemId={kundenSystemId} rechtstraegerId={rechtstraegerId} firma={firma} />
      )}
      sidebarMenuList={(firma) => menuListFirma(rechtstraegerId, firma, refetch)}
      contentContextList={() => rolleList}
    />
  );
};

export default FirmaDetailsPage;
