import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useDataCarrierPainStatusListQuery } from '../../../gql/DataCarrierQueries.types';

type Props = SelectProps;

const PainStatusListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useDataCarrierPainStatusListQuery();
  const list = data?.getDataCarrierPainStatusList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Status auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          <Typography.Text>{status.text}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default PainStatusListFormSelect;
