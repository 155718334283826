import React, { FC } from 'react';
import { TabsProps } from 'antd';
import TabPaneContent from '../../../../components/Tabs/TabPaneContent';
import RouteTabs from '../../../../components/Tabs/RouteTabs';
import FirmendatenKontoListing from './KontoListing/FirmendatenKontoListing';
import {
  generatePathToFirmendatenDetailRechnungAusstellerBelegtexteTab,
  generatePathToFirmendatenDetailRechnungAusstellerGeneralTab,
  generatePathToFirmendatenDetailRechnungAusstellerKontenTab,
  generatePathToFirmendatenDetailRechnungAusstellerReportLogosTab,
} from '../firmendatenUriPaths';
import RechnungsAusstellerBelegtextListing from './Belegtext/RechnungsAusstellerBelegtextListing';
import RechnungsAusstellerReportLogos from './ReportLogo/RechnungsAusstellerReportLogos';
import { Firmendaten } from '../../../../types';
import RechnungsAusstellerGeneralInfo from './RechnungsAusstellerGeneralInfo';

type Props = {
  firmendaten: Firmendaten;
};

const FirmendatenRechnungsAussteller: FC<Props> = ({ firmendaten }) => {
  const items: TabsProps['items'] = [
    {
      key: `${generatePathToFirmendatenDetailRechnungAusstellerGeneralTab(firmendaten.firmendatenId)}`,
      label: 'Allgemein',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechnungsAusstellerGeneralInfo />
        </TabPaneContent>
      ),
    },
    {
      key: `${generatePathToFirmendatenDetailRechnungAusstellerKontenTab(firmendaten.firmendatenId)}`,
      label: 'Konten',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <FirmendatenKontoListing firmendaten={firmendaten} />
        </TabPaneContent>
      ),
    },
    {
      key: `${generatePathToFirmendatenDetailRechnungAusstellerBelegtexteTab(firmendaten.firmendatenId)}`,
      label: 'Beleg Text',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechnungsAusstellerBelegtextListing firmendaten={firmendaten} />
        </TabPaneContent>
      ),
    },
    {
      key: `${generatePathToFirmendatenDetailRechnungAusstellerReportLogosTab(firmendaten.firmendatenId)}`,
      label: 'Report Logos',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechnungsAusstellerReportLogos firmendatenId={firmendaten.firmendatenId} />
        </TabPaneContent>
      ),
    },
  ];

  return <RouteTabs items={items} />;
};

export default FirmendatenRechnungsAussteller;
