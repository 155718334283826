import { Space } from 'antd';
import React, { FC, JSX } from 'react';

type DatenTabColumnsLayoutProps = {
  leftColumn: JSX.Element;
  rightColumn: JSX.Element;
  columnDivider?: JSX.Element;
};

const DatenTabColumnsLayout: FC<DatenTabColumnsLayoutProps> = ({ leftColumn, rightColumn, columnDivider }) => (
  <Space style={{ alignItems: 'start' }} size={16} split={columnDivider}>
    <Space direction="vertical" style={{ width: '300px' }}>
      {leftColumn}
    </Space>
    <Space direction="vertical" style={{ width: '300px' }}>
      {rightColumn}
    </Space>
  </Space>
);

export default DatenTabColumnsLayout;
