import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';

import MahnungTemplate from '../../../pages/PDFTemplates/templates/mahnung/MahnungTemplate';
import { TextbausteinFormValues } from '../../../components/Template/PDFTemplates/templateMapper';
import MahnstufeTemplate from '../../Mahndefinition/Templates/MahnstufeTemplate';
import {
  createMahndefinitionPDFTemplateData,
  mapTextbausteinToMahndefinitionSelectableSection,
} from '../../Mahndefinition/Templates/mahndefinitionTemplateFormMapper';

type Props = {
  mahnstufe1TemplateValues: TextbausteinFormValues[];
  onMahnstufe1TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  mahnstufe2TemplateValues: TextbausteinFormValues[];
  onMahnstufe2TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  mahnstufe3TemplateValues: TextbausteinFormValues[];
  onMahnstufe3TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  mahnstufe4TemplateValues: TextbausteinFormValues[];
  onMahnstufe4TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  isVersandEinschreiben1: boolean;
  isVersandEinschreiben2: boolean;
  isVersandEinschreiben3: boolean;
  isVersandEinschreiben4: boolean;
};

const MahndefinitionTemplateTemplates: FC<Props> = ({
  mahnstufe1TemplateValues,
  onMahnstufe1TemplateValuesChange,
  mahnstufe2TemplateValues,
  onMahnstufe2TemplateValuesChange,
  mahnstufe3TemplateValues,
  onMahnstufe3TemplateValuesChange,
  mahnstufe4TemplateValues,
  onMahnstufe4TemplateValuesChange,
  isVersandEinschreiben1,
  isVersandEinschreiben2,
  isVersandEinschreiben3,
  isVersandEinschreiben4,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Mahnstufe 1',
      children: (
        <MahnstufeTemplate
          templateFormValues={mahnstufe1TemplateValues}
          onTemplateChange={onMahnstufe1TemplateValuesChange}
          renderPreview={({ selectedTextbaustein }) => {
            return (
              <MahnungTemplate
                data={createMahndefinitionPDFTemplateData(mahnstufe1TemplateValues, isVersandEinschreiben1)}
                isInEditMode
                selectedTextbaustein={mapTextbausteinToMahndefinitionSelectableSection(selectedTextbaustein)}
              />
            );
          }}
        />
      ),
    },
    {
      key: '2',
      label: 'Mahnstufe 2',
      children: (
        <MahnstufeTemplate
          templateFormValues={mahnstufe2TemplateValues}
          onTemplateChange={onMahnstufe2TemplateValuesChange}
          renderPreview={({ selectedTextbaustein }) => {
            return (
              <MahnungTemplate
                data={createMahndefinitionPDFTemplateData(mahnstufe2TemplateValues, isVersandEinschreiben2)}
                isInEditMode
                selectedTextbaustein={mapTextbausteinToMahndefinitionSelectableSection(selectedTextbaustein)}
              />
            );
          }}
        />
      ),
    },
    {
      key: '3',
      label: 'Mahnstufe 3',
      children: (
        <MahnstufeTemplate
          templateFormValues={mahnstufe3TemplateValues}
          onTemplateChange={onMahnstufe3TemplateValuesChange}
          renderPreview={({ selectedTextbaustein }) => {
            return (
              <MahnungTemplate
                data={createMahndefinitionPDFTemplateData(mahnstufe3TemplateValues, isVersandEinschreiben3)}
                isInEditMode
                selectedTextbaustein={mapTextbausteinToMahndefinitionSelectableSection(selectedTextbaustein)}
              />
            );
          }}
        />
      ),
    },
    {
      key: '4',
      label: 'Mahnstufe 4',
      children: (
        <MahnstufeTemplate
          templateFormValues={mahnstufe4TemplateValues}
          onTemplateChange={onMahnstufe4TemplateValuesChange}
          renderPreview={({ selectedTextbaustein }) => {
            return (
              <MahnungTemplate
                data={createMahndefinitionPDFTemplateData(mahnstufe4TemplateValues, isVersandEinschreiben4)}
                isInEditMode
                selectedTextbaustein={mapTextbausteinToMahndefinitionSelectableSection(selectedTextbaustein)}
              />
            );
          }}
        />
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default MahndefinitionTemplateTemplates;
