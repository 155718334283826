import React, { FC } from 'react';
import TextbausteinInputTextArea, { TextbausteinInputTextAreaProps } from './TextbausteinInputTextArea';
import FormItemWithFieldHelp from '../../../../Form/FormItemWithFieldHelp';

type TextbausteinFormItemProps = {
  name: string;
  fieldHelp?: string | null;
  label?: string;
} & TextbausteinInputTextAreaProps;

/*
Can be used now only for validation purposes with formik because it is not a full integration with formik. Only the FormItem gets the name
and therefore validation will be shown currently but the value is not set in formik because the Input does get the name for now.

We could adjust the TextbausteinInputTextArea that it works with formik exactly as expected.
 */
const TextbausteinFormItem: FC<TextbausteinFormItemProps> = ({ name, label, fieldHelp, ...restProps }) => {
  return (
    <FormItemWithFieldHelp name={name} fieldHelp={fieldHelp} label={label}>
      <TextbausteinInputTextArea {...restProps} />
    </FormItemWithFieldHelp>
  );
};

export default TextbausteinFormItem;
