import React from 'react';
import { Route } from 'react-router';
import AndromedaSysSettingsKontoDetailsPage from './AndromedaSysSettingsKontoDetailsPage';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS_KONTO_DETAILS_PAGE } from '../../../../features/Kontenrahmen/kontoTemplateUriPaths';
import { isAdmin } from '../../../../security/permissionChecks';

export type KontoDetailsPageRouteParams = { kontoId: string };

const andromedaSysSettingsKontoDetailsPageRoute = (
  <Route
    path={`${URI_ANDROMEDA_SYS_SETTINGS_KONTO_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsKontoDetailsPage />} />}
  />
);

export default andromedaSysSettingsKontoDetailsPageRoute;
