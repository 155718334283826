import React, { FC, useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  mapTextbausteinListToFormValues,
  TextbausteinFormValues,
  TextbausteinModel,
} from '../../../../../components/Template/PDFTemplates/templateMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import { showSuccessMsgCreate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../../constants/andromedaSystemSettingsUriPaths';
import { useCreateSubAdministrationAbrechnungsdefinitionTemplateMutation } from '../../../gql/SubAdministrationAbrDef/SubAdministrationAbrDefTemplateMutations.types';
import { AbrechnungsdefinitionParameterFormValues } from '../../../../Abrechnungsdefinition/Form/Create/abrDefParameterFormMapper';
import { HeOrSubAbrDefOutputOptionsFieldHelpNames } from '../../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/abrDefFieldHelpMapper';
import { AbrDefAbrKreisListingFormValues } from '../../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import {
  heOrSubAbrDefGeneralSettingsFormInitialValues,
  HeOrSubAbrDefGeneralSettingsFormValues,
} from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';
import { mapFormValuesToSubAbrechnungsdefinitionCreate } from '../../../../Abrechnungsdefinition/Form/Create/abrDefCreateFormMapper';
import HeOrSubGeneralSettingsForm from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/HeOrSubGeneralSettingsForm';
import TemplateSubAbrechnung from './TemplateSubAbrechnung';

type Props = {
  parameterFormValues: AbrechnungsdefinitionParameterFormValues;
  abrKreisListFormValues: AbrDefAbrKreisListingFormValues;
  subAdministrationAbrechnungTextbausteinList: TextbausteinModel[];
  validFromFormValue: string;
  setIsValidFromValid: (validFromValid: boolean) => void;
  onBack: () => void;
  fieldHelpNames?: HeOrSubAbrDefOutputOptionsFieldHelpNames;
};

const SubAdministrationAbrDefTemplateForm: FC<Props> = ({
  parameterFormValues,
  abrKreisListFormValues,
  subAdministrationAbrechnungTextbausteinList,
  validFromFormValue,
  setIsValidFromValid,
  onBack,
  fieldHelpNames,
}) => {
  const entity = 'Abrechnungsdefinition';
  const navigate = useNavigate();

  const baseOptions = {
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      navigate(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  };

  const [runCreate, { loading }] = useCreateSubAdministrationAbrechnungsdefinitionTemplateMutation(baseOptions);

  const [subAdministrationAbrDefGeneralSettingsValues, setSubAdministrationAbrDefGeneralSettingsValues] =
    useState<HeOrSubAbrDefGeneralSettingsFormValues>(heOrSubAbrDefGeneralSettingsFormInitialValues);

  const [subAdministrationAbrDefTemplateValues, setSubAdministrationAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(subAdministrationAbrechnungTextbausteinList)
  );

  const onSubmit = () => {
    if (!validFromFormValue) {
      setIsValidFromValid(false);
      return;
    }

    runCreate({
      variables: {
        input: mapFormValuesToSubAbrechnungsdefinitionCreate(
          parameterFormValues,
          abrKreisListFormValues,
          subAdministrationAbrDefTemplateValues,
          validFromFormValue,
          subAdministrationAbrDefGeneralSettingsValues
        ),
      },
    });
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: (
        <HeOrSubGeneralSettingsForm
          values={subAdministrationAbrDefGeneralSettingsValues}
          onSubmit={setSubAdministrationAbrDefGeneralSettingsValues}
          fieldHelpNames={{
            displayKontonummer: fieldHelpNames?.displayKontonummer,
            rueckstaendeAnzeigen: fieldHelpNames?.rueckstaendeAnzeigen,
            rueckstaendeInAbrechnungsergebnis: fieldHelpNames?.rueckstaendeInAbrechnungsergebnis,
          }}
        />
      ),
    },
    {
      key: '2',
      label: 'Texte',
      children: (
        <TemplateSubAbrechnung
          values={subAdministrationAbrDefTemplateValues}
          onTemplateChange={setSubAdministrationAbrDefTemplateValues}
          abrDefGeneralSettingsValues={subAdministrationAbrDefGeneralSettingsValues}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />
      <FormButtons onCancel={onBack} cancelText="Zurück" updateMode={false} isOkLoading={loading} onOk={onSubmit} okText="Speichern" />
    </>
  );
};

export default SubAdministrationAbrDefTemplateForm;
