import React, { FC } from 'react';

import { Space, Typography } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, Input } from 'formik-antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';

import {
  budgetingCreateFormFields,
  budgetingCreateFormInitialValues,
  BudgetingCreateFormValues,
  mapFormValuesToBudgeting,
} from './budgetingCreateFormMapper';
import { DATE_DISPLAY_MONTH_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { budgetingCreateFormValidationSchema } from './budgetingCreateFormValidationSchema';
import { useCreateBudgetingMutation } from '../../gql/BudgetingMutations.types';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  objektId: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const BudgetingCreateForm: FC<Props> = ({ objektId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Budgeting'>('Budgeting');

  const [runCreate, { loading }] = useCreateBudgetingMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Budgetierung wird für das Objekt erstellt.`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<BudgetingCreateFormValues>
      initialValues={budgetingCreateFormInitialValues}
      validationSchema={budgetingCreateFormValidationSchema}
      onSubmit={(values) => {
        const input = mapFormValuesToBudgeting(values);
        runCreate({ variables: { objektId, input } });
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            id={budgetingCreateFormFields.bezeichnung}
            name={budgetingCreateFormFields.bezeichnung}
            label="Bezeichung"
            fieldHelp={getFieldHelpText('Budgeting.bezeichnung')}
          >
            <Input size="middle" id={budgetingCreateFormFields.bezeichnung} name={budgetingCreateFormFields.bezeichnung} />
          </FormItemWithFieldHelp>

          <FormItemWithFieldHelp
            id={budgetingCreateFormFields.considerationPeriodUntil}
            name={budgetingCreateFormFields.considerationPeriodUntil}
            label='Betrachtungszeitraum "bis"-Monat'
            fieldHelp={getFieldHelpText('Budgeting.considerationPeriodUntil')}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <DatePicker
                id={budgetingCreateFormFields.considerationPeriodUntil}
                name={budgetingCreateFormFields.considerationPeriodUntil}
                placeholder="Datum wählen"
                format={DATE_DISPLAY_MONTH_FORMAT_DEFAULT}
                allowClear={false}
                picker="month"
                size="middle"
                style={{ width: '100%' }}
              />
              {formikProps.values.considerationPeriodUntil && (
                <Space size={4}>
                  <CustomFormattedDate value={new Date(new Date().getFullYear(), 0, 1)} />
                  <Typography.Text>-</Typography.Text>
                  <CustomFormattedDate
                    value={mapFormDateValueToDateString(dayjsCustom(formikProps.values.considerationPeriodUntil).endOf('month'))}
                  />
                </Space>
              )}
            </Space>
          </FormItemWithFieldHelp>

          <FormButtons
            okText="Berechnung erstellen"
            updateMode={false}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default BudgetingCreateForm;
