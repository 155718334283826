import React from 'react';
import { Table, TableProps } from 'antd';
import styled from 'styled-components';

export const StyledTableDataCarrierList = styled(Table)`
  & .ant-table {
    margin: 0 !important;
    padding: 0 !important;
  }
` as <T>(props: TableProps<T>) => React.ReactElement;
