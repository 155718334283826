import styled from 'styled-components';
import { Tag, Typography, Collapse, List, Dropdown } from 'antd';
import {
  isDeviationChangeTypeAenderungAndEntryTypeNeu,
  isDeviationChangeTypeEntferntAndEntryTypeNeu,
  isDeviationChangeTypeNeuAndEntryTypeNeu,
} from '../deviationHelpers';
import { TDeviationCell } from '../deviation.model';
import theme from '../../../theme';

export const DeviationColumnTitleStyledText = styled(Typography.Text)`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-align: left;
`;

export const DeviationCellStyledText = styled(Typography.Text)`
  text-align: right;
`;

export const DeviationStyledTag = styled(Tag)`
  border-radius: 999px;
`;

export const DeviationStyledPanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export const SourceEntityStyledPanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    display: flex;
    align-items: center !important;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: 0;
    margin-left: 24px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export const SourceEntityStyledCollapse = styled(Collapse)`
  &&&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export const DeviationStyledDropdownButton = styled(Dropdown.Button)`
  .ant-btn-default {
    color: darkblue;
    border-radius: 999px 0 0 999px !important;
  }
  .ant-dropdown-trigger {
    color: darkred;
    border-radius: 0 999px 999px 0 !important;
  }
`;

export const DeviationStyledListItem = styled(List.Item)`
  &.ant-list-item {
    padding: 0;
  }
`;

export const deviationCellTextColor = (cell: TDeviationCell) => {
  if (isDeviationChangeTypeAenderungAndEntryTypeNeu(cell) && cell.hasChanged) return '#D46B08';
  if (isDeviationChangeTypeEntferntAndEntryTypeNeu(cell)) return '#CF1322';
  if (isDeviationChangeTypeNeuAndEntryTypeNeu(cell)) return '#D48806';
  // default color
  return theme.textColor.primary;
};
