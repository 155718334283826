import React, { FC } from 'react';
import { Dropdown, DropdownProps, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';

type Props = {
  title?: string;
  items: MenuProps['items'];
} & DropdownProps;

const ActionDropdown: FC<Props> = ({ items, title = 'Aktion', ...restProps }) => {
  return (
    <Dropdown menu={{ items }} {...restProps}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        {title} <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default ActionDropdown;
