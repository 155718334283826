import {
  AddressSharedModel,
  PostofficeBoxAddressFormValues,
  PostofficeBoxAddressModel,
  StreetAddressFormValues,
  StreetAddressModel,
} from '../AddressSharedModel';
import { AddressType } from '../../../../types';

const AUSTRIA_ISO2 = 'AT';

export const COUNTRY_CODE_ISO2_DEFAULT = AUSTRIA_ISO2;

const ADDRESS_BASE_FORM_PART_INITIAL_VALUES_DEFAULT = {
  countryCodeIso2: COUNTRY_CODE_ISO2_DEFAULT,
  city: '',
  cityAdditionalInformation: '',
  zipCode: '',
};

export const POSTOFFICE_BOX_ADDRESS_FORM_INITIAL_VALUES_DEFAULT: PostofficeBoxAddressFormValues = {
  ...ADDRESS_BASE_FORM_PART_INITIAL_VALUES_DEFAULT,
  type: AddressType.PostofficeBox,
  postofficeBoxNumber: '',
};

export const STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT: StreetAddressFormValues = {
  ...ADDRESS_BASE_FORM_PART_INITIAL_VALUES_DEFAULT,
  type: AddressType.Street,
  street: '',
  houseEntranceApartmentNumber: '',
};

export const mapAddressToFormValues = (address: AddressSharedModel): StreetAddressFormValues | PostofficeBoxAddressFormValues => {
  if (address.type === AddressType.Street) {
    return mapStreetAddressToFormValues(address as StreetAddressModel);
  }
  return mapPostofficeBoxAddressToFormValues(address as PostofficeBoxAddressModel);
};

const mapStreetAddressToFormValues = (address: StreetAddressModel): StreetAddressFormValues => ({
  addressId: address.addressId,
  type: address.type,
  countryCodeIso2: address.countryCodeIso2,
  city: address.city,
  cityAdditionalInformation: address.cityAdditionalInformation ? address.cityAdditionalInformation : '',
  street: address.street,
  houseEntranceApartmentNumber: address.houseEntranceApartmentNumber,
  zipCode: address.zipCode,
});

const mapPostofficeBoxAddressToFormValues = (address: PostofficeBoxAddressModel): PostofficeBoxAddressFormValues => ({
  addressId: address.addressId,
  type: address.type,
  countryCodeIso2: address.countryCodeIso2,
  city: address.city,
  cityAdditionalInformation: address.cityAdditionalInformation ? address.cityAdditionalInformation : '',
  postofficeBoxNumber: address.postofficeBoxNumber,
  zipCode: address.postofficeBoxZipCode,
});

export const mapFormValuesToAddress = (
  values: StreetAddressFormValues | PostofficeBoxAddressFormValues,
  operatingSiteId?: string
): StreetAddressModel | PostofficeBoxAddressModel => {
  if (values.type === AddressType.Street) {
    return mapStreetAddressFormValuesToStreetAddress(values as StreetAddressFormValues, operatingSiteId);
  }
  return mapPostofficeBoxAddressFormValuesToPostofficeBoxAddress(values as PostofficeBoxAddressFormValues, operatingSiteId);
};

const mapStreetAddressFormValuesToStreetAddress = (values: StreetAddressFormValues, operatingSiteId?: string): StreetAddressModel => ({
  addressId: values.addressId,
  countryCodeIso2: values.countryCodeIso2,
  city: values.city,
  cityAdditionalInformation: values.cityAdditionalInformation,
  type: values.type,
  zipCode: values.zipCode,
  houseEntranceApartmentNumber: values.houseEntranceApartmentNumber,
  street: values.street,
  operatingSiteId: operatingSiteId ?? undefined,
});

const mapPostofficeBoxAddressFormValuesToPostofficeBoxAddress = (
  values: PostofficeBoxAddressFormValues,
  operatingSiteId?: string
): PostofficeBoxAddressModel => ({
  addressId: values.addressId,
  countryCodeIso2: values.countryCodeIso2,
  city: values.city,
  cityAdditionalInformation: values.cityAdditionalInformation,
  postofficeBoxNumber: values.postofficeBoxNumber,
  type: values.type,
  postofficeBoxZipCode: values.zipCode,
  operatingSiteId: operatingSiteId ?? undefined,
});
