import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { DocumentRowProps, findLabelValueByLabelId } from './util';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import { useBudgetingBaseQuery } from '../../../Budgeting/gql/BudgetingQueries.types';
import { EuroAmount } from '../../../../components/Number';
import { pathsToBudgetingDetailsPage } from '../../../Budgeting/budgetingUriPaths';
import { generatePathToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import ObjektFinancialPlanActions from '../../actions/ObjektFinancialPlanActions';
import OutdatedData from '../../../../components/Helpers/OutdatedData';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { isNotNil } from '../../../../helpers/assertionHelper';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';

const RowObjektFinancialPlan: FC<DocumentRowProps> = ({ record, rechnungTyp, invoiceIssuerId, invoiceRecipientId, ...restProps }) => {
  const budgetingId = findLabelValueByLabelId(record.labelList, 'budgetingId');
  const objektId = findLabelValueByLabelId(record.labelList, 'objektId');
  const isOutdated = findLabelValueByLabelId(record.labelList, 'outdated');

  const { loading, data } = useBudgetingBaseQuery({
    variables: { budgetingId, objektId },
  });

  const budgeting = data?.getBudgetingBase.data;
  const documentInfo: DocumentInfo | undefined = budgeting
    ? {
        column1: (
          <OutdatedData isOutdated={isOutdated} isAvatar>
            <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.type} />
          </OutdatedData>
        ),
        column2: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Objekt-Wirtschaftsplan"
              content={
                <DataWithShortenedText text={budgeting.bezeichnung} maxTextLength={25}>
                  {(shortenedText) => (
                    <Link target="_blank" to={pathsToBudgetingDetailsPage(objektId, budgetingId).detailsTab}>
                      {shortenedText}
                    </Link>
                  )}
                </DataWithShortenedText>
              }
            />
          </OutdatedData>
        ),
        column3: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Okjekt"
              content={
                <DataWithShortenedText text={budgeting.objekt.kurzBezeichnung} maxTextLength={25}>
                  {(shortenedText) => (
                    <Link target="_blank" to={generatePathToObjektDetailsPage(objektId)}>
                      {shortenedText}
                    </Link>
                  )}
                </DataWithShortenedText>
              }
            />
          </OutdatedData>
        ),
        column4: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="-" content="-" />
          </OutdatedData>
        ),
        column5: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="Budgetierungsjahr" content={<Typography.Text>{budgeting.currentYear}</Typography.Text>} />
          </OutdatedData>
        ),
        column6: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Jahresbudget Summe"
              content={
                isNotNil(budgeting.budgetSumNextYear) ? <EuroAmount value={budgeting.budgetSumNextYear} /> : <TextForEmptyValue textToShow="minus" />
              }
            />
          </OutdatedData>
        ),
        column7: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="-" content="-" />
          </OutdatedData>
        ),
        column8: (
          <OutdatedData isOutdated={isOutdated}>
            <ObjektFinancialPlanActions record={record} budgeting={budgeting} />
          </OutdatedData>
        ),
      }
    : undefined;

  return (
    <DocumentRowContextProvider value={{ isLoading: loading, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

export default RowObjektFinancialPlan;
