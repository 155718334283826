import React, { FC } from 'react';
import { Typography } from 'antd';
import { DocumentRowProps, findLabelValueByLabelId } from './util';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import { EuroAmount } from '../../../../components/Number';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import { useMahnungQuery } from '../../../Mahnung/gql/MahnungQueries.types';
import MahnungActions from '../../actions/MahnungActions';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const RowMahnung: FC<DocumentRowProps> = ({ record, rechnungTyp, invoiceRecipientId, invoiceIssuerId, ...restProps }) => {
  const mahnungId = findLabelValueByLabelId(record.labelList, 'mahnungId');

  const { loading, data } = useMahnungQuery({
    variables: { mahnungId },
  });

  const mahnung = data?.getMahnung.data;
  const documentInfo: DocumentInfo | undefined = mahnung
    ? {
        column1: <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.type} />,
        column2: (
          <ValueTitle
            small
            title="Rechnungsaussteller"
            content={
              <RechtstraegerWithContactsTooltip
                rechtstraegerId={mahnung.rechnungsAussteller.rechtstraegerId}
                rechtstraegerName={mahnung.rechnungsAussteller.kurzBezeichnung}
              />
            }
          />
        ),
        column3: (
          <ValueTitle
            small
            title="Vertragspartner"
            content={
              <RechtstraegerWithContactsTooltip
                rechtstraegerId={mahnung.vertragspartner.rechtstraegerId}
                rechtstraegerName={mahnung.vertragspartner.kurzBezeichnung}
              />
            }
          />
        ),
        column4: <ValueTitle small title="-" content={<Typography.Text>-</Typography.Text>} />,
        column5: <ValueTitle small title="Mahnstichtag" content={<CustomFormattedDate value={mahnung.mahnstichtag} />} />,
        column6: <ValueTitle small title="Mahnbetrag" content={<EuroAmount value={mahnung.sumMahnbetrag} />} />,
        column7: <ValueTitle small title="-" content={<Typography.Text>-</Typography.Text>} />,
        column8: <MahnungActions record={record} mahnung={mahnung} />,
      }
    : undefined;

  return (
    <DocumentRowContextProvider value={{ isLoading: loading, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

export default RowMahnung;
