import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { SepaMandat } from '../../../types';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { generatePathToRechtstraegerDetailsPage } from '../../Rechtstraeger/rechtstraegerHelper';
import { pathsToFirmendatenDetails } from '../../KundenSystem/Firmendaten/firmendatenUriPaths';

type Props = {
  sepaMandat: SepaMandat;
};

const SepaMandatLinkToRechnungsaussteller: FC<Props> = ({ sepaMandat }) => {
  if (sepaMandat.firmendaten) {
    const { firmendatenId } = sepaMandat.firmendaten;
    return (
      <DataWithShortenedText maxTextLength={20} text={sepaMandat.firmendaten.kurzBezeichnung}>
        {(shortenedText) => (
          <Link target="_blank" to={pathsToFirmendatenDetails(firmendatenId).rechnungAusstellerTab}>
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    );
  }

  if (sepaMandat.rechnungsAussteller) {
    const { rechtstraegerId } = sepaMandat.rechnungsAussteller;
    return (
      <DataWithShortenedText maxTextLength={20} text={sepaMandat.rechnungsAussteller.kurzBezeichnung}>
        {(shortenedText) => (
          <Link target="_blank" to={generatePathToRechtstraegerDetailsPage(rechtstraegerId)}>
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    );
  }

  // only for the case that BE doesn't deliver firmendaten or rechnungsAussteller for any reason
  return null;
};

export default SepaMandatLinkToRechnungsaussteller;
