import React, { FC, useEffect } from 'react';
import { Col, Row, Skeleton, Space, Typography } from 'antd';
import { FieldArray, FormikProps } from 'formik';
import { useAbrechnungsdefinitionAbrechnungskreisSelectListQuery } from '../../AbrDefAbrechnungskreisSelect/gql/AbrDefAbrechnungskreisQueries.types';
import { linkageFormFields, LinkageFormValues } from './linkageFormMapper';
import { VorschreibungspositionArt } from '../../../types';
import VorschreibungspositionSelect from '../../VorschreibungspositionSelect/VorschreibungspositionSelect';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

type Props = {
  abrechnungsdefinitionId: string;
  formikProps: FormikProps<LinkageFormValues>;
  isUpdate: boolean;
  fieldHelp?: string | null;
};

const AbrKreisVPosLinkage: FC<Props> = ({ abrechnungsdefinitionId, formikProps, isUpdate, fieldHelp }) => {
  const { data, loading } = useAbrechnungsdefinitionAbrechnungskreisSelectListQuery({
    variables: { abrechnungsdefinitionId },
    skip: !abrechnungsdefinitionId,
  });

  const abrechnungskreisList = data?.getAbrechnungsdefinitionAbrechnungskreisList.data;

  useEffect(() => {
    if (!isUpdate) {
      formikProps.setFieldValue(linkageFormFields.abrKreisVPosList, []);
    }

    abrechnungskreisList?.forEach((entry, index) => {
      const fieldNamePrefix = `${linkageFormFields.abrKreisVPosList}[${index}]`;
      const existingEntry = formikProps.values.abrKreisVPosList.find((item) => item.abrechnungskreisId === entry.abrechnungskreisId);
      formikProps.setFieldValue(`${fieldNamePrefix}.abrechnungskreisId`, entry.abrechnungskreisId);
      formikProps.setFieldValue(`${fieldNamePrefix}.vorschreibungspositionId`, existingEntry?.vorschreibungspositionId ?? '');
      formikProps.getFieldHelpers(`${fieldNamePrefix}.vorschreibungspositionId`).setTouched(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abrechnungskreisList]);

  // Ensure the correct array entry object structure, to display validation errors
  useEffect(() => {
    formikProps.values.abrKreisVPosList.forEach((entry, index) => {
      const fieldNamePrefix = `${linkageFormFields.abrKreisVPosList}[${index}]`;
      formikProps.setFieldValue(`${fieldNamePrefix}.abrechnungskreisId`, entry.abrechnungskreisId ?? '');

      if (typeof entry.vorschreibungspositionId === 'undefined') {
        formikProps.setFieldValue(`${fieldNamePrefix}.vorschreibungspositionId`, '').then(() => {
          formikProps.getFieldHelpers(`${fieldNamePrefix}.vorschreibungspositionId`).setTouched(true);
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikProps.values.abrKreisVPosList]);

  return (
    <>
      <Skeleton style={{ marginTop: 20 }} loading={loading}>
        <FormItemWithFieldHelp
          name={linkageFormFields.abrKreisVPosList}
          label="Abrechnungskreis-Vorschreibungsposition-Verknüpfung"
          fieldHelp={fieldHelp}
          help={null}
          validateStatus={undefined}
        >
          <FieldArray
            name={linkageFormFields.abrKreisVPosList}
            render={() => {
              return (
                <>
                  {abrechnungskreisList?.map((item, index) => (
                    <Row key={index} gutter={8} style={{ marginTop: !index ? 20 : 0 }}>
                      <Col span={11} style={{ marginTop: 3 }}>
                        <Space direction="horizontal" size={8} style={{ width: '100%', justifyContent: 'space-between' }}>
                          <DataWithShortenedText maxTextLength={30} text={item.bezeichnung}>
                            {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
                          </DataWithShortenedText>
                          {/* This is a separator styling element, not for displaying empty value, so keep it as is :) */}
                          <Typography.Text>-</Typography.Text>
                        </Space>
                      </Col>
                      <Col span={13}>
                        <FormItemWithoutColon name={`${linkageFormFields.abrKreisVPosList}[${index}].vorschreibungspositionId`}>
                          <VorschreibungspositionSelect
                            firmendatenId={formikProps.values.firmendatenId}
                            name={`${linkageFormFields.abrKreisVPosList}[${index}].vorschreibungspositionId`}
                            exludeArts={[VorschreibungspositionArt.Miete]}
                          />
                        </FormItemWithoutColon>
                      </Col>
                    </Row>
                  ))}
                </>
              );
            }}
          />
        </FormItemWithFieldHelp>
      </Skeleton>
    </>
  );
};
export default AbrKreisVPosLinkage;
