import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { BudgetingVPosAuftschluesselLinkageFieldsFragmentDoc } from '../../BudgetingVPosAuftschluesselLinkage/gql/budgetingVPosAuftschluesselLinkageFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BudgetingVPosAuftschluesselLinkageTemplateListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BudgetingVPosAuftschluesselLinkageTemplateListQuery = {
  getBudgetingVPosAuftschluesselLinkageTemplateList: {
    data: Array<{
      budgetingVPosAuftschluesselLinkageId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      kundenSystemId?: string | null;
      updatedByMitarbeiterId?: string | null;
      aufteilungsschluessel: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
      vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
      vorschreibungsposition: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { description?: string | null; text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; type: string; message: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingVPosAuftschluesselLinkageTemplateQueryVariables = Types.Exact<{
  budgetingVPosAuftschluesselLinkageId: Types.Scalars['ID']['input'];
}>;

export type BudgetingVPosAuftschluesselLinkageTemplateQuery = {
  getBudgetingVPosAuftschluesselLinkageTemplate: {
    data: {
      budgetingVPosAuftschluesselLinkageId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      kundenSystemId?: string | null;
      updatedByMitarbeiterId?: string | null;
      aufteilungsschluessel: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
      vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
      vorschreibungsposition: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { description?: string | null; text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; type: string; message: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQueryVariables = Types.Exact<{
  budgetingVPosAuftschluesselLinkageId: Types.Scalars['ID']['input'];
}>;

export type BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQuery = {
  getBudgetingVPosAuftschluesselLinkageTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const BudgetingVPosAuftschluesselLinkageTemplateListDocument = gql`
  query BudgetingVPosAuftschluesselLinkageTemplateList {
    getBudgetingVPosAuftschluesselLinkageTemplateList {
      data {
        ...BudgetingVPosAuftschluesselLinkageFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${BudgetingVPosAuftschluesselLinkageFieldsFragmentDoc}
`;
export function useBudgetingVPosAuftschluesselLinkageTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BudgetingVPosAuftschluesselLinkageTemplateListQuery,
    BudgetingVPosAuftschluesselLinkageTemplateListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingVPosAuftschluesselLinkageTemplateListQuery, BudgetingVPosAuftschluesselLinkageTemplateListQueryVariables>(
    BudgetingVPosAuftschluesselLinkageTemplateListDocument,
    options
  );
}
export function useBudgetingVPosAuftschluesselLinkageTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BudgetingVPosAuftschluesselLinkageTemplateListQuery,
    BudgetingVPosAuftschluesselLinkageTemplateListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingVPosAuftschluesselLinkageTemplateListQuery, BudgetingVPosAuftschluesselLinkageTemplateListQueryVariables>(
    BudgetingVPosAuftschluesselLinkageTemplateListDocument,
    options
  );
}
export function useBudgetingVPosAuftschluesselLinkageTemplateListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BudgetingVPosAuftschluesselLinkageTemplateListQuery,
        BudgetingVPosAuftschluesselLinkageTemplateListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingVPosAuftschluesselLinkageTemplateListQuery, BudgetingVPosAuftschluesselLinkageTemplateListQueryVariables>(
    BudgetingVPosAuftschluesselLinkageTemplateListDocument,
    options
  );
}
export type BudgetingVPosAuftschluesselLinkageTemplateListQueryHookResult = ReturnType<typeof useBudgetingVPosAuftschluesselLinkageTemplateListQuery>;
export type BudgetingVPosAuftschluesselLinkageTemplateListLazyQueryHookResult = ReturnType<
  typeof useBudgetingVPosAuftschluesselLinkageTemplateListLazyQuery
>;
export type BudgetingVPosAuftschluesselLinkageTemplateListSuspenseQueryHookResult = ReturnType<
  typeof useBudgetingVPosAuftschluesselLinkageTemplateListSuspenseQuery
>;
export type BudgetingVPosAuftschluesselLinkageTemplateListQueryResult = Apollo.QueryResult<
  BudgetingVPosAuftschluesselLinkageTemplateListQuery,
  BudgetingVPosAuftschluesselLinkageTemplateListQueryVariables
>;
export const BudgetingVPosAuftschluesselLinkageTemplateDocument = gql`
  query BudgetingVPosAuftschluesselLinkageTemplate($budgetingVPosAuftschluesselLinkageId: ID!) {
    getBudgetingVPosAuftschluesselLinkageTemplate(budgetingVPosAuftschluesselLinkageId: $budgetingVPosAuftschluesselLinkageId) {
      data {
        ...BudgetingVPosAuftschluesselLinkageFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${BudgetingVPosAuftschluesselLinkageFieldsFragmentDoc}
`;
export function useBudgetingVPosAuftschluesselLinkageTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<BudgetingVPosAuftschluesselLinkageTemplateQuery, BudgetingVPosAuftschluesselLinkageTemplateQueryVariables> &
    ({ variables: BudgetingVPosAuftschluesselLinkageTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingVPosAuftschluesselLinkageTemplateQuery, BudgetingVPosAuftschluesselLinkageTemplateQueryVariables>(
    BudgetingVPosAuftschluesselLinkageTemplateDocument,
    options
  );
}
export function useBudgetingVPosAuftschluesselLinkageTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetingVPosAuftschluesselLinkageTemplateQuery, BudgetingVPosAuftschluesselLinkageTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingVPosAuftschluesselLinkageTemplateQuery, BudgetingVPosAuftschluesselLinkageTemplateQueryVariables>(
    BudgetingVPosAuftschluesselLinkageTemplateDocument,
    options
  );
}
export function useBudgetingVPosAuftschluesselLinkageTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BudgetingVPosAuftschluesselLinkageTemplateQuery, BudgetingVPosAuftschluesselLinkageTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingVPosAuftschluesselLinkageTemplateQuery, BudgetingVPosAuftschluesselLinkageTemplateQueryVariables>(
    BudgetingVPosAuftschluesselLinkageTemplateDocument,
    options
  );
}
export type BudgetingVPosAuftschluesselLinkageTemplateQueryHookResult = ReturnType<typeof useBudgetingVPosAuftschluesselLinkageTemplateQuery>;
export type BudgetingVPosAuftschluesselLinkageTemplateLazyQueryHookResult = ReturnType<typeof useBudgetingVPosAuftschluesselLinkageTemplateLazyQuery>;
export type BudgetingVPosAuftschluesselLinkageTemplateSuspenseQueryHookResult = ReturnType<
  typeof useBudgetingVPosAuftschluesselLinkageTemplateSuspenseQuery
>;
export type BudgetingVPosAuftschluesselLinkageTemplateQueryResult = Apollo.QueryResult<
  BudgetingVPosAuftschluesselLinkageTemplateQuery,
  BudgetingVPosAuftschluesselLinkageTemplateQueryVariables
>;
export const BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListDocument = gql`
  query BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryList($budgetingVPosAuftschluesselLinkageId: ID!) {
    getBudgetingVPosAuftschluesselLinkageTemplateChangeHistoryList(budgetingVPosAuftschluesselLinkageId: $budgetingVPosAuftschluesselLinkageId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQuery,
    BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQueryVariables
  > &
    ({ variables: BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQuery,
    BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQueryVariables
  >(BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListDocument, options);
}
export function useBudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQuery,
    BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQuery,
    BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQueryVariables
  >(BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListDocument, options);
}
export function useBudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQuery,
        BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQuery,
    BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQueryVariables
  >(BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListDocument, options);
}
export type BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQueryHookResult = ReturnType<
  typeof useBudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQuery
>;
export type BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useBudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListLazyQuery
>;
export type BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useBudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListSuspenseQuery
>;
export type BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQuery,
  BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryListQueryVariables
>;
