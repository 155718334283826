import React from 'react';
import { Space, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { NestedTableColProps } from '../../../../components/Table/NestedTable/NestedTable';
import { BuchungszeileOrderBy, FibuBuchungszeile } from '../../../../types';
import AvatarBasedOnBelegSymbolWithTooltip from '../../../../components/Avatar/BasedOnBelegSymbol/AvatarBasedOnBelegSymbolWithTooltip';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { generatePathToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import { generatePathToBeVertragDetailsPage } from '../../../Vertrag/BeVertrag/beVertragUriPaths';
import { generatePathToHeVertragDetailsPage } from '../../../Vertrag/HeVertrag/heVertragUriPaths';
import KontoLinkToKontoblatt from '../../../../shared/components/Konto/KontoLinkToKontoblatt';
import { EuroAmount, Percent } from '../../../../components/Number';
import SollHabenTag from '../../../shared/SollHabenTag';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { generatePathToBookingDetailsPage } from '../../../BookingDetails/bookingDetailsPaths';
import { generatePathToOffenePostenPage } from '../../../OffenePosten/offenePostenUriPaths';
import { isNotNil } from '../../../../helpers/assertionHelper';
import BuchungszeileBelegnummer from '../../../BookingDetails/shared/BuchungszeileBelegnummer';
import { NestedTableWithColSelectorColProps } from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

const bookingJournalTableColumns = (buchungszeileList: FibuBuchungszeile[]): NestedTableWithColSelectorColProps<FibuBuchungszeile>[] => [
  {
    title: 'Quelle',
    dataIndex: BuchungszeileOrderBy.Source,
    sorter: true,
    render: (text, record, index) => {
      const previous = buchungszeileList[index - 1];
      if (previous && previous.buchungId !== record.buchungId) {
        return (
          <div style={{ position: 'relative' }}>
            <Tooltip title="Hier beginnt eine neue Buchung">
              <InfoCircleOutlined style={{ position: 'absolute', top: '-4px', left: '-4px', fontSize: 8 }} />
            </Tooltip>
            <Typography.Text>{record.source.text}</Typography.Text>
          </div>
        );
      }

      return <Typography.Text>{record.source.text}</Typography.Text>;
    },
  },
  {
    title: 'Fachliche Prüfung',
    render: (text, record) =>
      isNotNil(record.fachlichePruefung) ? (
        <Typography.Text>{record.fachlichePruefung ? 'Ja' : 'Nein'}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Buchungskreis',
    dataIndex: BuchungszeileOrderBy.BuchungskreisRechtstraeger,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={15} text={record.buchungskreisBezeichnung} />,
  },
  {
    title: 'Belegsymbol',
    width: 100,
    dataIndex: BuchungszeileOrderBy.BelegSymbol,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <AvatarBasedOnBelegSymbolWithTooltip belegSymbol={record.buchungsKopf.belegSymbol} size="small" />,
  },
  {
    title: 'Buchungstyp',
    dataIndex: BuchungszeileOrderBy.BuchungsTyp,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.buchungsKopf.buchungsType.text}</Typography.Text>,
  },
  {
    title: 'Storniert',
    dataIndex: BuchungszeileOrderBy.Storniert,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.storniert ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  {
    title: 'Stornierte Buchung',
    dataIndex: BuchungszeileOrderBy.StornierteBuchung,
    sorter: true,
    render: (text, record) =>
      record.stornierteBuchungBezeichnung ? (
        <DataWithShortenedText maxTextLength={15} text={record.stornierteBuchungBezeichnung} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Buchungsdatum',
    dataIndex: BuchungszeileOrderBy.BuchungsDatum,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <CustomFormattedDate value={record.buchungsKopf.buchungsdatum} />,
  },
  {
    title: 'Belegdatum',
    dataIndex: BuchungszeileOrderBy.BelegDatum,
    sorter: true,
    defaultSelected: true,
    render: (text, record) =>
      record.buchungsKopf.belegDatum ? <CustomFormattedDate value={record.buchungsKopf.belegDatum} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Belegnummer',
    dataIndex: BuchungszeileOrderBy.Belegnummer,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <BuchungszeileBelegnummer buchungszeile={record} />,
  },
  {
    title: 'Fälligkeit',
    dataIndex: BuchungszeileOrderBy.DueDate,
    sorter: true,
    defaultSelected: true,
    render: (text, record) =>
      record.buchungsKopf.dueDate ? <CustomFormattedDate value={record.buchungsKopf.dueDate} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Objekt',
    dataIndex: BuchungszeileOrderBy.Objekt,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => {
      const objekt = record.objekt;

      return objekt ? (
        <DataWithShortenedText maxTextLength={15} text={objekt.kurzBezeichnung}>
          {(shortenedText) => (
            <Link to={generatePathToObjektDetailsPage(objekt.objektId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Bestandseinheit',
    dataIndex: BuchungszeileOrderBy.Bestandseinheit,
    sorter: true,
    render: (text, record) => {
      const objekt = record.objekt;
      const bestandseinheit = record.bestandseinheit;

      if (objekt?.objektId && bestandseinheit?.bestandseinheitId && bestandseinheit.bezeichnung) {
        return (
          <DataWithShortenedText maxTextLength={15} text={bestandseinheit.bezeichnung}>
            {(shortenedText) => (
              <Link to={generatePathToBestandseinheitDetailsPage(objekt.objektId, bestandseinheit.bestandseinheitId)} target="_blank">
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        );
      }

      return bestandseinheit?.bezeichnung ? (
        <Typography.Text>{bestandseinheit.bezeichnung}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Vertrag',
    render: (text, record) => {
      const objekt = record.objekt;
      const bestandseinheit = record.bestandseinheit;
      const vertragId = record.vertragId;

      if (objekt?.objektId && bestandseinheit?.bestandseinheitId && vertragId && record.vertragKurzbezeichnung) {
        return (
          <DataWithShortenedText maxTextLength={15} text={record.vertragKurzbezeichnung}>
            {(shortenedText) => (
              <Link to={generatePathToBeVertragDetailsPage(objekt.objektId, bestandseinheit.bestandseinheitId, vertragId)} target="_blank">
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        );
      }

      return record.vertragKurzbezeichnung ? (
        <Typography.Text>{record.vertragKurzbezeichnung}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'HE-Vertrag',
    render: (text, record) => {
      const objekt = record.objekt;
      const heVertragId = record.heVertragId;

      if (objekt?.objektId && heVertragId && record.heVertragKurzbezeichnung) {
        return (
          <DataWithShortenedText maxTextLength={15} text={record.heVertragKurzbezeichnung}>
            {(shortenedText) => (
              <Link to={generatePathToHeVertragDetailsPage(objekt.objektId, heVertragId)} target="_blank">
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        );
      }

      return record.heVertragKurzbezeichnung ? (
        <Typography.Text>{record.heVertragKurzbezeichnung}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Konto',
    width: 180,
    dataIndex: BuchungszeileOrderBy.KontoNummer,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => (
      <KontoLinkToKontoblatt
        konto={{
          kontoId: record.konto.kontoId,
          nummer: record.konto.nummer,
          bezeichnung: record.konto.bezeichnung,
        }}
        buchungsdatumFrom={record.buchungsKopf.buchungsdatum}
        buchungsdatumTo={record.buchungsKopf.buchungsdatum}
        buchungskreisId={record.buchungskreisId}
        maxBezeichnungLength={12}
      />
    ),
  },
  {
    title: 'Gegenkonto',
    defaultSelected: true,
    width: 180,
    dataIndex: BuchungszeileOrderBy.GegenKontoNummer,
    sorter: true,
    render: (text, record) =>
      record.gegenKonto ? (
        <KontoLinkToKontoblatt
          konto={{
            kontoId: record.gegenKonto.kontoId,
            nummer: record.gegenKonto.nummer,
            bezeichnung: record.gegenKonto.bezeichnung,
          }}
          buchungsdatumFrom={record.buchungsKopf.buchungsdatum}
          buchungsdatumTo={record.buchungsKopf.buchungsdatum}
          buchungskreisId={record.buchungskreisId}
          maxBezeichnungLength={12}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Betrag',
    defaultSelected: true,
    align: 'right',
    dataIndex: BuchungszeileOrderBy.Betrag,
    sorter: true,
    render: (text, record) => <EuroAmount value={record.betrag} />,
  },
  {
    title: 'Währung',
    dataIndex: BuchungszeileOrderBy.Waehrung,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.buchungsKopf.waehrung.text}</Typography.Text>,
  },

  {
    title: 'S/H',
    dataIndex: BuchungszeileOrderBy.SollHaben,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <SollHabenTag sollHaben={record.sollHaben} />,
  },
  {
    title: 'Steuer %',
    align: 'right',
    dataIndex: BuchungszeileOrderBy.Steuersatz,
    sorter: true,
    defaultSelected: true,
    render: (text, record) =>
      record.steuersatz || record.steuersatz === 0 ? <Percent value={record.steuersatz / 100} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Steuer',
    align: 'right',
    dataIndex: BuchungszeileOrderBy.Steuer,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => (record.steuer ? <EuroAmount value={record.steuer} /> : null),
  },
  {
    title: 'Buchungskennzeichen',
    dataIndex: BuchungszeileOrderBy.BuchungsKz,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.buchungsKZ}</Typography.Text>,
  },
  {
    title: 'Buchungstext',
    dataIndex: BuchungszeileOrderBy.Text,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <DataWithShortenedText text={record.text} maxTextLength={18} />,
  },
  {
    title: 'Umbuchung',
    dataIndex: BuchungszeileOrderBy.Umbuchung,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.umbuchung || record.umgebucht ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  {
    title: 'Ust. vom Aufwand Steuersatz',
    align: 'right',
    dataIndex: BuchungszeileOrderBy.UstVomAufwandSteuersatz,
    sorter: true,
    render: (text, record) =>
      record.ustVomAufwandSteuersatz || record.ustVomAufwandSteuersatz === 0 ? (
        <Percent value={record.ustVomAufwandSteuersatz / 100} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: () => (
      <Space>
        <Typography.Text style={{ color: 'inherit' }}>Offener OP-Betrag</Typography.Text>
        <Tooltip title="OP-Beträge werden nur bei Debitoren und Kreditoren angezeigt">
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
    ),
    align: 'right',
    width: 200,
    defaultSelected: true,
    render: (text, record) => {
      return record.offenerOpBetrag ? (
        <Link
          to={generatePathToOffenePostenPage({
            buchungskreisId: record.buchungskreisId,
            fibuKontoId: record.konto.kontoId,
            belegnummer: record.buchungsKopf.belegnummer,
            buchungsdatumFrom: record.buchungsKopf.buchungsdatum,
            buchungsdatumTo: record.buchungsKopf.buchungsdatum,
          })}
          target="_blank"
        >
          <EuroAmount value={record.offenerOpBetrag} />
        </Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Erstellt am',
    dataIndex: BuchungszeileOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    dataIndex: BuchungszeileOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => (
      <Link to={generatePathToBookingDetailsPage([record.buchungId])} target="_blank">
        Details
      </Link>
    ),
  },
];

export default bookingJournalTableColumns;
