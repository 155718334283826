import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Skeleton } from 'antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { showSuccessMsgCreate } from '../../../../components/message';
import {
  AbrechnungCreateFormValues,
  abrechnungsCreateFormFields,
  abrechnungsCreateFormInitialValues,
  mapFormValuesToCreateNamedAbrechnungInput,
} from './abrechnungCreateFormMapper';
import { AbrechnungType, Objekt } from '../../../../types';
import { getAbrTypeName } from '../abrechnungHelpers';
import { namedObjAbrCreateValidationSchema } from './abrechnungCreateValidationSchema';
import { useAbrechnungBezeichnungQuery } from '../../gql/AbrechnungQueries.types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useCreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutation } from '../../gql/AbrechnungGenerierlauf/AbrechnungGenerierlaufMutations.types';
import RangePicker from '../../../../components/DatePicker/RangePicker/RangePicker';

type Props = {
  objekt: Objekt;
  abrechnungType: AbrechnungType;
  onSuccess: () => void;
  onCancel: () => void;
};

const AbrechnungCreateForm: FC<Props> = ({ objekt, abrechnungType, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'NamedObjektAbrechnungGenerierlauf'>('NamedObjektAbrechnungGenerierlauf');

  const entity = 'Abrechnung';

  const { data, loading } = useAbrechnungBezeichnungQuery({
    variables: {
      objektId: objekt.objektId,
      abrechnungType,
    },
  });

  const abrBezeichnung = data?.getAbrechnungBezeichnung.data;

  const [runCreateNameObjAbr, { loading: loadingCreateNamedObjAbr }] = useCreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <Formik<AbrechnungCreateFormValues>
      initialValues={abrechnungsCreateFormInitialValues(abrechnungType, abrBezeichnung)}
      validationSchema={namedObjAbrCreateValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        const input = mapFormValuesToCreateNamedAbrechnungInput(formValues, abrechnungType, objekt.objektId);
        runCreateNameObjAbr({ variables: { input } }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={abrechnungsCreateFormFields.bezeichnung}
            label="Bezeichnung"
            fieldHelp={getFieldHelpText('NamedObjektAbrechnungGenerierlauf.bezeichnung')}
          >
            <Input id={abrechnungsCreateFormFields.bezeichnung} name={abrechnungsCreateFormFields.bezeichnung} placeholder="Bezeichnung" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={abrechnungsCreateFormFields.fromTo}
            label="Abrechnung von – bis"
            fieldHelp={getFieldHelpText('NamedObjektAbrechnungGenerierlauf.fromInclusive')}
          >
            <RangePicker name={abrechnungsCreateFormFields.fromTo} placeholder={['von', 'bis']} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormButtons
            okText={`${getAbrTypeName(abrechnungType)} erstellen`}
            updateMode={false}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreateNamedObjAbr}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AbrechnungCreateForm;
