import {
  PaymentAssignmentBulkActionInput,
  QueryGetReceivedBankTransactionListArgs,
  ReceivedBankTransactionStatus,
  TransactionType,
} from '../../../../types';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { TReceivedBankTransactionQueryParams } from './filtersQueryParams';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';

export type FiltersFormValues = {
  amountFrom?: number | null;
  amountTo?: number | null;
  bankStatementIban?: string | null;
  booked?: boolean | null;
  bookingDateFrom?: string | null;
  bookingDateTo?: string | null;
  chargesFrom?: number | null;
  chargesInSeparateEntry?: boolean | null;
  chargesTo?: number | null;
  createTsFrom?: string | null;
  createTsTo?: string | null;
  createdByMitarbeiterIdList?: string[] | null;
  creditor?: string | null;
  creditorIban?: string | null;
  dataCarrierName?: string | null;
  debtor?: string | null;
  debtorIban?: string | null;
  endToEndId?: string | null;
  hasWarning?: boolean | null;
  noted?: boolean | null;
  paymentAssigned?: boolean | null;
  paymentReferenceText?: string | null;
  purposeOfUseText?: string | null;
  reversal?: boolean | null;
  setDefaultValuesOnInit?: boolean | null;
  showArchived?: boolean | null;
  statementNumber?: string | null;
  status?: ReceivedBankTransactionStatus | null;
  transactionType?: TransactionType | null;
  valid?: boolean | null;
  valueDateFrom?: string | null;
  valueDateTo?: string | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  amountFrom: 'amountFrom',
  amountTo: 'amountTo',
  bankStatementIban: 'bankStatementIban',
  booked: 'booked',
  bookingDateFrom: 'bookingDateFrom',
  bookingDateTo: 'bookingDateTo',
  chargesFrom: 'chargesFrom',
  chargesInSeparateEntry: 'chargesInSeparateEntry',
  chargesTo: 'chargesTo',
  createTsFrom: 'createTsFrom',
  createTsTo: 'createTsTo',
  createdByMitarbeiterIdList: 'createdByMitarbeiterIdList',
  creditor: 'creditor',
  creditorIban: 'creditorIban',
  dataCarrierName: 'dataCarrierName',
  debtor: 'debtor',
  debtorIban: 'debtorIban',
  endToEndId: 'endToEndId',
  hasWarning: 'hasWarning',
  noted: 'noted',
  paymentAssigned: 'paymentAssigned',
  paymentReferenceText: 'paymentReferenceText',
  purposeOfUseText: 'purposeOfUseText',
  reversal: 'reversal',
  setDefaultValuesOnInit: 'setDefaultValuesOnInit',
  showArchived: 'showArchived',
  statementNumber: 'statementNumber',
  status: 'status',
  transactionType: 'transactionType',
  valid: 'valid',
  valueDateFrom: 'valueDateFrom',
  valueDateTo: 'valueDateTo',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TReceivedBankTransactionQueryParams => ({
  amountFrom: formValues.amountFrom,
  amountTo: formValues.amountTo,
  bankStatementIban: formValues.bankStatementIban,
  booked: formValues.booked,
  bookingDateFrom: formValues.bookingDateFrom ? mapFormDateValueToDateString(formValues.bookingDateFrom) : undefined,
  bookingDateTo: formValues.bookingDateTo ? mapFormDateValueToDateString(formValues.bookingDateTo) : undefined,
  chargesFrom: formValues.chargesFrom,
  chargesInSeparateEntry: formValues.chargesInSeparateEntry,
  chargesTo: formValues.chargesTo,
  createTsFrom: formValues.createTsFrom ? mapFormDateValueToDateString(formValues.createTsFrom) : undefined,
  createTsTo: formValues.createTsTo ? mapFormDateValueToDateString(formValues.createTsTo) : undefined,
  createdByMitarbeiterIdList: formValues.createdByMitarbeiterIdList,
  creditor: formValues.creditor,
  creditorIban: formValues.creditorIban,
  dataCarrierName: formValues.dataCarrierName,
  debtor: formValues.debtor,
  debtorIban: formValues.debtorIban,
  endToEndId: formValues.endToEndId,
  hasWarning: formValues.hasWarning,
  noted: formValues.noted,
  paymentAssigned: formValues.paymentAssigned,
  paymentReferenceText: formValues.paymentReferenceText,
  purposeOfUseText: formValues.purposeOfUseText,
  reversal: formValues.reversal,
  showArchived: formValues.showArchived ?? false,
  statementNumber: formValues.statementNumber,
  status: formValues.status,
  transactionType: formValues.transactionType,
  valid: formValues.valid,
  valueDateFrom: formValues.valueDateFrom ? mapFormDateValueToDateString(formValues.valueDateFrom) : undefined,
  valueDateTo: formValues.valueDateTo ? mapFormDateValueToDateString(formValues.valueDateTo) : undefined,
});

export const receivedBankTransactionInitialValues = (queryParams: TReceivedBankTransactionQueryParams): FiltersFormValues => ({
  ...queryParams,
  booked: queryParams.booked ?? (queryParams.setDefaultValuesOnInit ? false : undefined),
  paymentAssigned: queryParams.paymentAssigned ?? (queryParams.setDefaultValuesOnInit ? false : undefined),
});

export const mapQueryParamsToReceivedBankTransactionListQueryVariables = (
  queryParams: TReceivedBankTransactionQueryParams
): QueryGetReceivedBankTransactionListArgs => {
  const { showArchived, ...restUriParams } = queryParams;
  return {
    ...restUriParams,
    includeArchiviert: queryParams.showArchived,
    statementNumber: queryParams.statementNumber?.toString(),
  };
};

export const mapQueryParamsToPaymentAssignmentBulkActionInput = (
  queryParams: TReceivedBankTransactionQueryParams
): PaymentAssignmentBulkActionInput => {
  const { showArchived, ...restUriParams } = queryParams;
  return {
    ...restUriParams,
    includeArchiviert: queryParams.showArchived,
    statementNumber: queryParams.statementNumber?.toString(),
  };
};

export const listingLabelList: ObjectOfStrings<
  Omit<FiltersFormValues, 'valueDateTo' | 'amountTo' | 'chargesTo' | 'createTsTo' | 'bookingDateTo' | 'showArchived' | 'setDefaultValuesOnInit'>
> = {
  [filtersFormFields.amountFrom]: 'Betrag',
  [filtersFormFields.bankStatementIban]: 'Kontoauszug IBAN',
  [filtersFormFields.booked]: 'Verbucht',
  [filtersFormFields.bookingDateFrom]: 'Buchungsdatum',
  [filtersFormFields.chargesFrom]: 'Spesen',
  [filtersFormFields.chargesInSeparateEntry]: 'Spesen in eigener Bankbuchung',
  [filtersFormFields.createTsFrom]: 'Erstellt am',
  [filtersFormFields.createdByMitarbeiterIdList]: 'Ersteller',
  [filtersFormFields.creditorIban]: 'Kreditor Bankverbindung',
  [filtersFormFields.creditor]: 'Kreditor',
  [filtersFormFields.dataCarrierName]: 'Datenträger',
  [filtersFormFields.debtorIban]: 'Debitor Bankverbindung',
  [filtersFormFields.debtor]: 'Debitor',
  [filtersFormFields.endToEndId]: 'TransaktionsanweisungsId',
  [filtersFormFields.hasWarning]: 'Nur mit Warnungen',
  [filtersFormFields.noted]: 'Gesehen',
  [filtersFormFields.paymentAssigned]: 'Zugewiesene Zahlung',
  [filtersFormFields.paymentReferenceText]: 'Zahlungsreferenz',
  [filtersFormFields.purposeOfUseText]: 'Verwendungszweck',
  [filtersFormFields.reversal]: 'Rücklastschrift',
  [filtersFormFields.statementNumber]: 'Kontoauszugsnummer',
  [filtersFormFields.status]: 'Status',
  [filtersFormFields.transactionType]: 'Transaktionsart',
  [filtersFormFields.valid]: 'Buchungsvorschlag Status',
  [filtersFormFields.valueDateFrom]: 'Valutadatum',
};
