import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ZVorschreibungspositionHeaderText, ZVPosSummaryVPos } from '../../zinsliste-types';
import pdfStyles from '../../../styles/pdfStyles';

type Props = {
  vorschreibungspositionList: ZVPosSummaryVPos[];
  vPosHeaderText: ZVorschreibungspositionHeaderText;
};

const ZVPosListVPosList: FC<Props> = ({ vorschreibungspositionList, vPosHeaderText }) => {
  return (
    <View>
      {vorschreibungspositionList.map((vPosForVertragsart, vposIndex) => (
        <View style={{ marginBottom: '5mm' }} key={vposIndex} wrap={false}>
          <View style={[pdfStyles.row]}>
            <View style={{ width: '40%' }} />
            <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { width: '60%' }]}>
              <SummaryTextField value={vPosHeaderText.nettoText} />
              <SummaryTextField value={vPosHeaderText.ustSatzText} />
              <SummaryTextField value={vPosHeaderText.ustBetragText} />
              <SummaryTextField value={vPosHeaderText.bruttoText} />
            </View>
          </View>
          <View style={[pdfStyles.row]}>
            <View style={[pdfStyles.row, { width: '40%' }]}>
              <Text style={[pdfStyles.textBulletin, { width: '30%' }]}>{vPosForVertragsart.kurzBezeichnung}</Text>
              <Text style={[pdfStyles.textBulletin, { width: '70%' }]}>{vPosForVertragsart.bezeichnung}</Text>
            </View>
            <View style={[pdfStyles.row, { width: '60%' }]}>
              <View style={[pdfStyles.column]}>
                {vPosForVertragsart.vorschreibungspositionWertList.map((vorschreibungspositionWert, index) => (
                  <View style={[pdfStyles.row]} key={index}>
                    <SummaryTextField value={vorschreibungspositionWert.netto} />
                    <SummaryTextField value={vorschreibungspositionWert.steuersatz} />
                    <SummaryTextField value={vorschreibungspositionWert.ust} />
                    <SummaryTextField value={vorschreibungspositionWert.brutto} />
                  </View>
                ))}
                <View style={[pdfStyles.row, pdfStyles.borderTopSmall]}>
                  <SummaryTextField value={vPosForVertragsart.sumNetto} />
                  <SummaryTextField />
                  <SummaryTextField value={vPosForVertragsart.sumUst} />
                  <SummaryTextField value={vPosForVertragsart.sumBrutto} />
                </View>
              </View>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
};

type TextFieldProps = {
  value?: string;
};

const SummaryTextField: FC<TextFieldProps> = ({ value }) => (
  <Text style={[pdfStyles.textBulletin, { width: '25%', textAlign: 'right' }]}>{value}</Text>
);

export default ZVPosListVPosList;
