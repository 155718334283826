import * as Yup from 'yup';
import { ebicsBankConfigFormFields } from './ebicsBankConfigFormMapper';
import { entityIsRequired, entityMustBeSelected } from '../../../../components/message/validationMsg';
import { isEbicsVersionH005 } from '../../shared/ebicsHelpers';

export const ebicsBankConfigValidationSchema = Yup.object().shape({
  [ebicsBankConfigFormFields.ebicsVersion]: Yup.string().required(entityMustBeSelected('EBICS Version')),
  [ebicsBankConfigFormFields.pain001Schema]: Yup.string().required(entityMustBeSelected('PAIN001')),
  [ebicsBankConfigFormFields.pain008Schema]: Yup.string().required(entityMustBeSelected('PAIN008')),
  [ebicsBankConfigFormFields.name]: Yup.string().required(entityIsRequired('Bezeichnung')),
  [ebicsBankConfigFormFields.hostId]: Yup.string().required(entityIsRequired('Host ID')),
  [ebicsBankConfigFormFields.url]: Yup.string().required(entityIsRequired('Url')),
  [ebicsBankConfigFormFields.creditTransferBtf]: Yup.string().when([ebicsBankConfigFormFields.ebicsVersion], ([ebicsVersion], schema) =>
    isEbicsVersionH005(ebicsVersion) ? schema.required(entityIsRequired('EBICS 3.0 BTF - Pain001')) : schema.nullable()
  ),
  [ebicsBankConfigFormFields.directDebitBtf]: Yup.string().when([ebicsBankConfigFormFields.ebicsVersion], ([ebicsVersion], schema) =>
    isEbicsVersionH005(ebicsVersion) ? schema.required(entityIsRequired('EBICS 3.0 BTF - Pain008')) : schema.nullable()
  ),
  [ebicsBankConfigFormFields.bankStatementBtf]: Yup.string().when([ebicsBankConfigFormFields.ebicsVersion], ([ebicsVersion], schema) =>
    isEbicsVersionH005(ebicsVersion) ? schema.required(entityIsRequired('EBICS 3.0 BTF - Camt53')) : schema.nullable()
  ),
});
