import React, { FC } from 'react';
import { MenuProps } from 'antd';
import { IbanListEntry } from '../../../../../types';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';
import { pathsToRechtstraegerDetails } from '../../../../Rechtstraeger/rechtstraegerHelper';
import { generatePathToFirmendatenDetailRechnungAusstellerKontenTab } from '../../../../KundenSystem/Firmendaten/firmendatenUriPaths';

type Props = {
  record: IbanListEntry;
};

const DetailsColumn: FC<Props> = ({ record }) => {
  const items: MenuProps['items'] = record.bankDetailsList.map((bankDetails) => {
    if (bankDetails.rechtstraegerId && bankDetails.rechtstraegerKurzBezeichnung) {
      return {
        key: bankDetails.bankDetailsId,
        label: bankDetails.rechtstraegerKurzBezeichnung,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onClick: () => window.open(pathsToRechtstraegerDetails(bankDetails.rechtstraegerId!).rechnungAusstellerKontenTab),
      };
    }

    return {
      key: bankDetails.bankDetailsId,
      label: bankDetails.firmendatenKurzBezeichnung,
      onClick: () => window.open(generatePathToFirmendatenDetailRechnungAusstellerKontenTab(bankDetails.firmendatenId)),
    };
  });

  return <ActionDropdown items={items} />;
};

export default DetailsColumn;
