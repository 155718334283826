/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { entityIsRequired } from '../../../../../components/message/validationMsg';
import { sepaMandatActivateOrUpdateFormFields } from './sepaMandatActivateOrUpdateFormMapper';

export const sepaMandatUpdateFormValidationSchema = Yup.object().shape({
  [sepaMandatActivateOrUpdateFormFields.signatureDate]: Yup.string().required(entityIsRequired('Unterschriftsdatum')),
  [sepaMandatActivateOrUpdateFormFields.signatureFile]: Yup.object().nullable(),
});

export const sepaMandatActivateFormValidationSchema = Yup.object().shape({
  [sepaMandatActivateOrUpdateFormFields.signatureDate]: Yup.string().required(entityIsRequired('Unterschriftsdatum')),
  [sepaMandatActivateOrUpdateFormFields.signatureFile]: Yup.object().required('Unterschriebener SEPA Mandat ist erforderlich'),
});

/* eslint-enable no-template-curly-in-string */
