import React, { FC, useState } from 'react';
import { Transfer } from 'antd';
import { showSuccessMsgOther } from '../../../../../../components/message';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { useFdAufteilungsschluesselListQuery } from '../../../../../Aufteilungsschluessel/gql/AufteilungsschluesselQueries.types';
import { useObjektAufteilungsschluesselListQuery } from '../../../gql/ObjektAufteilungsschluesselQueries.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useSetObjektAufteilungsschluesselListMutation } from '../../../gql/ObjektAufteilungsschluesselMutations.types';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import { getIdList, mapToTransferItems } from './aufteilungSchluesselTransferMapper';
import DataWithStatus from '../../../../../../components/Helpers/DataWithStatus';
import { Bezugsbasis } from '../../../../../../types';

type AufteilungsschluesselTransferProps = {
  objektId: string;
  onOk: () => void;
  onCancel: () => void;
  isWegObjekt: boolean;
};

const AufteilungsschluesselTransfer: FC<AufteilungsschluesselTransferProps> = ({ objektId, onOk, onCancel, isWegObjekt }) => {
  const { activeForFirmendatenId } = useFDAuthorized();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const { data: maybeAufteilungsschluesselList } = useFdAufteilungsschluesselListQuery({
    variables: {
      firmendatenId: activeForFirmendatenId,
      includeArchiviert: true,
    },
  });
  const aufteilungsschluesselList = maybeAufteilungsschluesselList?.getAufteilungsschluesselListByFirmendatenId.data.contentList ?? [];

  const { data: maybeObjektAufteilungsschluesselList } = useObjektAufteilungsschluesselListQuery({
    variables: {
      objektId,
      withDetails: true,
      includeArchiviert: true,
    },
    onCompleted: (data) => setTargetKeys(getIdList(data.getObjektAufteilungsschluesselList.data)),
  });
  const objektAufteilungsschluesselList = maybeObjektAufteilungsschluesselList?.getObjektAufteilungsschluesselList.data ?? [];

  const [setObjektAufteilungsschluesselList, { loading: isUpdateLoading }] = useSetObjektAufteilungsschluesselListMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Zuweisen des Aufteilungsschlüssel war erfolgreich`);
      onOk();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const transferItems = mapToTransferItems(aufteilungsschluesselList, objektAufteilungsschluesselList).filter((aufteilungsschluessel) =>
    !isWegObjekt ? aufteilungsschluessel.bezugsbasis.value !== Bezugsbasis.Nutzwert : true
  );

  return (
    <>
      <Transfer
        dataSource={transferItems}
        titles={['nicht zugewiesen', 'zugewiesen']}
        locale={{ notFoundContent: 'Kein Aufteilungsschlüssel in dieser Liste' }}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={(nextTargetKeys) => {
          setTargetKeys(nextTargetKeys as string[]);
        }}
        onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
          setSelectedKeys([...(sourceSelectedKeys as string[]), ...(targetSelectedKeys as string[])]);
        }}
        listStyle={{
          width: 310,
          height: 300,
        }}
        render={(record) => <DataWithStatus status={record.status} text={record.bezeichnung} badgeOffset={[5, 5]} />}
      />
      <FormButtons
        onOk={() =>
          setObjektAufteilungsschluesselList({
            variables: {
              objektId,
              input: targetKeys,
            },
          })
        }
        onCancel={onCancel}
        updateMode={false}
        isOkDisabled={isUpdateLoading}
        isOkLoading={isUpdateLoading}
      />
    </>
  );
};

export default AufteilungsschluesselTransfer;
