import React, { FC } from 'react';
import { Modal } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import BuchungDeviationUebernehmenForm from './Form/BuchungDeviationUebernehmenForm';
import { FibuBuchungszeile } from '../../../../../../../types';

type Props = {
  buchungsanweisungId: string;
  buchungzeileInUse: FibuBuchungszeile | undefined;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
};

const ConfirmDeviationUebernehmenModal: FC<Props> = ({ buchungsanweisungId, buchungzeileInUse, isOpen, onCancel, onSuccess }) => {
  return (
    <Modal
      open={isOpen}
      title={
        <>
          <QuestionCircleTwoTone twoToneColor="#FAAD14" />
          <span style={{ paddingLeft: '8px' }}>Möchten Sie alle Abweichungen übernehmen?</span>
        </>
      }
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
    >
      <BuchungDeviationUebernehmenForm
        onAction={onSuccess}
        onCancel={onCancel}
        buchungsanweisungId={buchungsanweisungId}
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        defaultBuchungsdatum={buchungzeileInUse!.createTs}
      />
    </Modal>
  );
};

export default ConfirmDeviationUebernehmenModal;
