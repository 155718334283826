import React, { FC } from 'react';
import { Avatar, Button, Card, Divider, Dropdown, MenuProps, Skeleton, Space, Typography } from 'antd';
import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import EmailUpdateWithPasswordForm from '../../shared/components/Email/EmailUpdateForm';
import { useToggle } from '../../hooks/useToggle';
import CardWithFooter from '../../components/Card';
import { useMitarbeiterQuery } from '../../graphql/MitarbeiterService/MitarbeiterQueries.types';
import { personToString } from '../../shared/components/Person/personHelper';
import { concatFirstNameLetters } from '../../helpers/nameHelper';
import { StyledSkeleton } from '../../components/Loading';
import ChangePasswordForm from './Form/ChangePasswordForm';
import MitarbeiterUpdateForm from '../Mitarbeiter/Form/Update/MitarbeiterUpdateForm';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import StatusBadge from '../../components/Status/StatusBadge';
import CardTitle from '../../components/Card/CardTitle';
import ProfileCardDrawer from './ProfileCardDrawer';
import CardDetailsInfoRow from '../../components/Card/CardDetailsInfoRow';
import ContextMenu from '../../components/Timeline/ContextMenu';

type ProfileCardMitarbeiterProps = {
  kundenSystemId: string;
};

const ProfileCardMitarbeiter: FC<ProfileCardMitarbeiterProps> = ({ kundenSystemId }) => {
  const [isMitarbeiterDrawerOpen, toggleMitarbeiterDrawerOpen] = useToggle();
  const [isEmailDrawerOpen, toggleEmailDrawerOpen] = useToggle();
  const [isPasswordDrawerOpen, togglPasswordDrawerOpen] = useToggle();
  const { activeForFirmendatenId } = useFDAuthorized();

  const items: MenuProps['items'] = [{ key: '1', label: 'Passwort ändern', onClick: togglPasswordDrawerOpen, icon: <EditOutlined /> }];

  const { loading, data: maybeUserData, refetch } = useMitarbeiterQuery({ variables: { mitarbeiterId: 'me', includeUser: true } });
  const mitarbeiter = maybeUserData?.getMitarbeiterDetails.data;

  if (!mitarbeiter || !mitarbeiter.user) {
    return <StyledSkeleton loading={loading} active paragraph={{ rows: 4 }} />;
  }

  return (
    <>
      <CardWithFooter
        loading={loading}
        style={{ minWidth: '320px' }}
        title={<CardTitle title={personToString(mitarbeiter)} status={mitarbeiter.status} />}
        extra={
          <Dropdown menu={{ items }}>
            <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
          </Dropdown>
        }
        createTs={mitarbeiter.createTs}
      >
        <Skeleton active loading={loading}>
          <Card.Meta
            avatar={<Avatar>{concatFirstNameLetters(mitarbeiter?.firstname, mitarbeiter?.lastname)}</Avatar>}
            title={mitarbeiter.username}
            description={<StatusBadge entityStatus={mitarbeiter.status} showBadgeText={false} />}
          />
          <Divider dashed />

          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Typography.Title level={5}>Persönliche Daten</Typography.Title>
            <ContextMenu openDrawerCreate={toggleMitarbeiterDrawerOpen} />
          </Space>
          <CardDetailsInfoRow infoRowTitle="Name">
            {(mitarbeiter?.firstname || mitarbeiter?.lastname) && (
              <Typography.Text>
                {mitarbeiter.firstname} {mitarbeiter.lastname}
              </Typography.Text>
            )}
          </CardDetailsInfoRow>
          <CardDetailsInfoRow infoRowTitle="Username">
            <Typography.Text>{mitarbeiter.username}</Typography.Text>
          </CardDetailsInfoRow>
          <Divider dashed />

          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Typography.Title level={5}>E-Mail</Typography.Title>
            <ContextMenu openDrawerCreate={toggleEmailDrawerOpen} />
          </Space>
          <CardDetailsInfoRow infoRowTitle="E-Mail">
            <Typography.Text>{mitarbeiter.user.email}</Typography.Text>
            <StatusBadge entityStatus={mitarbeiter.status} />
          </CardDetailsInfoRow>
        </Skeleton>
      </CardWithFooter>
      <ProfileCardDrawer title="Personönliche Daten bearbeiten" isCollapsed={isMitarbeiterDrawerOpen} onCollapse={toggleMitarbeiterDrawerOpen}>
        <MitarbeiterUpdateForm
          firmendatenId={activeForFirmendatenId}
          mitarbeiter={mitarbeiter}
          onSuccess={() => {
            refetch();
            toggleMitarbeiterDrawerOpen();
          }}
          onCancel={toggleMitarbeiterDrawerOpen}
          kundenSystemId={kundenSystemId}
        />
      </ProfileCardDrawer>
      <ProfileCardDrawer title="E-Mail-Adresse bearbeiten" isCollapsed={isEmailDrawerOpen} onCollapse={toggleEmailDrawerOpen}>
        <EmailUpdateWithPasswordForm
          user={null}
          mitarbeiter={mitarbeiter}
          onCancel={toggleEmailDrawerOpen}
          onSuccess={() => {
            refetch();
            toggleEmailDrawerOpen();
          }}
        />
      </ProfileCardDrawer>
      <ProfileCardDrawer title="Passwort ändern" isCollapsed={isPasswordDrawerOpen} onCollapse={togglPasswordDrawerOpen}>
        <ChangePasswordForm
          onCancel={togglPasswordDrawerOpen}
          onSuccess={() => {
            refetch();
            togglPasswordDrawerOpen();
          }}
        />
      </ProfileCardDrawer>
    </>
  );
};

export default ProfileCardMitarbeiter;
