import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KuendigungsfristJeweilsZumListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type KuendigungsfristJeweilsZumListQuery = {
  getKuendigungsfristJeweilsZumList: {
    data: Array<{ text: string; value: Types.KuendigungsfristJeweilsZum }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const KuendigungsfristJeweilsZumListDocument = gql`
  query KuendigungsfristJeweilsZumList {
    getKuendigungsfristJeweilsZumList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useKuendigungsfristJeweilsZumListQuery(
  baseOptions?: Apollo.QueryHookOptions<KuendigungsfristJeweilsZumListQuery, KuendigungsfristJeweilsZumListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KuendigungsfristJeweilsZumListQuery, KuendigungsfristJeweilsZumListQueryVariables>(
    KuendigungsfristJeweilsZumListDocument,
    options
  );
}
export function useKuendigungsfristJeweilsZumListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KuendigungsfristJeweilsZumListQuery, KuendigungsfristJeweilsZumListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KuendigungsfristJeweilsZumListQuery, KuendigungsfristJeweilsZumListQueryVariables>(
    KuendigungsfristJeweilsZumListDocument,
    options
  );
}
export function useKuendigungsfristJeweilsZumListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KuendigungsfristJeweilsZumListQuery, KuendigungsfristJeweilsZumListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KuendigungsfristJeweilsZumListQuery, KuendigungsfristJeweilsZumListQueryVariables>(
    KuendigungsfristJeweilsZumListDocument,
    options
  );
}
export type KuendigungsfristJeweilsZumListQueryHookResult = ReturnType<typeof useKuendigungsfristJeweilsZumListQuery>;
export type KuendigungsfristJeweilsZumListLazyQueryHookResult = ReturnType<typeof useKuendigungsfristJeweilsZumListLazyQuery>;
export type KuendigungsfristJeweilsZumListSuspenseQueryHookResult = ReturnType<typeof useKuendigungsfristJeweilsZumListSuspenseQuery>;
export type KuendigungsfristJeweilsZumListQueryResult = Apollo.QueryResult<
  KuendigungsfristJeweilsZumListQuery,
  KuendigungsfristJeweilsZumListQueryVariables
>;
