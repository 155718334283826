import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import PersonenbezugCreateForm from './Form/Create/PersonenbezugCreateForm';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import { Personenbezug } from '../../../types';

type Props = {
  rechtstraegerId: string;
  onSuccess: () => void;
  personenbezugList: Personenbezug[];
};

const PersonenbezugBtnAndDrawer: FC<Props> = ({ rechtstraegerId, personenbezugList, onSuccess }) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();

  const rechtstraegerIdList = personenbezugList.map((personenbezug) => personenbezug.person.rechtstraegerId);

  const onCreateSuccess = () => {
    onSuccess();
    toggleDrawerOpen();
  };

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button icon={<PlusOutlined />} type="primary" onClick={toggleDrawerOpen}>
          Bezug anlegen
        </Button>
      </ButtonsAligned>
      <Drawer title="Personenbezüge anlegen" width={720} open={isDrawerOpen} onClose={toggleDrawerOpen} destroyOnClose>
        <PersonenbezugCreateForm rechtstraegerId={rechtstraegerId} onAction={onCreateSuccess} rechtstraegerIdList={rechtstraegerIdList} />
      </Drawer>
    </>
  );
};

export default PersonenbezugBtnAndDrawer;
