import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { BudgetingVPosAuftschluesselLinkageFieldsFragmentDoc } from './budgetingVPosAuftschluesselLinkageFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BudgetingVPosAuftschluesselLinkageListQueryVariables = Types.Exact<{
  kundenSystemId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  firmenDatenId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type BudgetingVPosAuftschluesselLinkageListQuery = {
  getBudgetingVPosAuftschluesselLinkageList: {
    data: Array<{
      budgetingVPosAuftschluesselLinkageId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      kundenSystemId?: string | null;
      updatedByMitarbeiterId?: string | null;
      aufteilungsschluessel: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
      vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
      vorschreibungsposition: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { description?: string | null; text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; type: string; message: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingVPosAuftschluesselLinkageQueryVariables = Types.Exact<{
  budgetingVPosAuftschluesselLinkageId: Types.Scalars['ID']['input'];
}>;

export type BudgetingVPosAuftschluesselLinkageQuery = {
  getBudgetingVPosAuftschluesselLinkage: {
    data: {
      budgetingVPosAuftschluesselLinkageId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      kundenSystemId?: string | null;
      updatedByMitarbeiterId?: string | null;
      aufteilungsschluessel: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
      vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
      vorschreibungsposition: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { description?: string | null; text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; type: string; message: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingVPosAuftschluesselLinkageChangeHistoryListQueryVariables = Types.Exact<{
  budgetingVPosAuftschluesselLinkageId: Types.Scalars['ID']['input'];
}>;

export type BudgetingVPosAuftschluesselLinkageChangeHistoryListQuery = {
  getBudgetingVPosAuftschluesselLinkageChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const BudgetingVPosAuftschluesselLinkageListDocument = gql`
  query BudgetingVPosAuftschluesselLinkageList($kundenSystemId: String, $firmenDatenId: String) {
    getBudgetingVPosAuftschluesselLinkageList(kundenSystemId: $kundenSystemId, firmendatenId: $firmenDatenId) {
      data {
        ...BudgetingVPosAuftschluesselLinkageFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${BudgetingVPosAuftschluesselLinkageFieldsFragmentDoc}
`;
export function useBudgetingVPosAuftschluesselLinkageListQuery(
  baseOptions?: Apollo.QueryHookOptions<BudgetingVPosAuftschluesselLinkageListQuery, BudgetingVPosAuftschluesselLinkageListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingVPosAuftschluesselLinkageListQuery, BudgetingVPosAuftschluesselLinkageListQueryVariables>(
    BudgetingVPosAuftschluesselLinkageListDocument,
    options
  );
}
export function useBudgetingVPosAuftschluesselLinkageListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetingVPosAuftschluesselLinkageListQuery, BudgetingVPosAuftschluesselLinkageListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingVPosAuftschluesselLinkageListQuery, BudgetingVPosAuftschluesselLinkageListQueryVariables>(
    BudgetingVPosAuftschluesselLinkageListDocument,
    options
  );
}
export function useBudgetingVPosAuftschluesselLinkageListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BudgetingVPosAuftschluesselLinkageListQuery, BudgetingVPosAuftschluesselLinkageListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingVPosAuftschluesselLinkageListQuery, BudgetingVPosAuftschluesselLinkageListQueryVariables>(
    BudgetingVPosAuftschluesselLinkageListDocument,
    options
  );
}
export type BudgetingVPosAuftschluesselLinkageListQueryHookResult = ReturnType<typeof useBudgetingVPosAuftschluesselLinkageListQuery>;
export type BudgetingVPosAuftschluesselLinkageListLazyQueryHookResult = ReturnType<typeof useBudgetingVPosAuftschluesselLinkageListLazyQuery>;
export type BudgetingVPosAuftschluesselLinkageListSuspenseQueryHookResult = ReturnType<typeof useBudgetingVPosAuftschluesselLinkageListSuspenseQuery>;
export type BudgetingVPosAuftschluesselLinkageListQueryResult = Apollo.QueryResult<
  BudgetingVPosAuftschluesselLinkageListQuery,
  BudgetingVPosAuftschluesselLinkageListQueryVariables
>;
export const BudgetingVPosAuftschluesselLinkageDocument = gql`
  query BudgetingVPosAuftschluesselLinkage($budgetingVPosAuftschluesselLinkageId: ID!) {
    getBudgetingVPosAuftschluesselLinkage(budgetingVPosAuftschluesselLinkageId: $budgetingVPosAuftschluesselLinkageId) {
      data {
        ...BudgetingVPosAuftschluesselLinkageFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${BudgetingVPosAuftschluesselLinkageFieldsFragmentDoc}
`;
export function useBudgetingVPosAuftschluesselLinkageQuery(
  baseOptions: Apollo.QueryHookOptions<BudgetingVPosAuftschluesselLinkageQuery, BudgetingVPosAuftschluesselLinkageQueryVariables> &
    ({ variables: BudgetingVPosAuftschluesselLinkageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingVPosAuftschluesselLinkageQuery, BudgetingVPosAuftschluesselLinkageQueryVariables>(
    BudgetingVPosAuftschluesselLinkageDocument,
    options
  );
}
export function useBudgetingVPosAuftschluesselLinkageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetingVPosAuftschluesselLinkageQuery, BudgetingVPosAuftschluesselLinkageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingVPosAuftschluesselLinkageQuery, BudgetingVPosAuftschluesselLinkageQueryVariables>(
    BudgetingVPosAuftschluesselLinkageDocument,
    options
  );
}
export function useBudgetingVPosAuftschluesselLinkageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BudgetingVPosAuftschluesselLinkageQuery, BudgetingVPosAuftschluesselLinkageQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingVPosAuftschluesselLinkageQuery, BudgetingVPosAuftschluesselLinkageQueryVariables>(
    BudgetingVPosAuftschluesselLinkageDocument,
    options
  );
}
export type BudgetingVPosAuftschluesselLinkageQueryHookResult = ReturnType<typeof useBudgetingVPosAuftschluesselLinkageQuery>;
export type BudgetingVPosAuftschluesselLinkageLazyQueryHookResult = ReturnType<typeof useBudgetingVPosAuftschluesselLinkageLazyQuery>;
export type BudgetingVPosAuftschluesselLinkageSuspenseQueryHookResult = ReturnType<typeof useBudgetingVPosAuftschluesselLinkageSuspenseQuery>;
export type BudgetingVPosAuftschluesselLinkageQueryResult = Apollo.QueryResult<
  BudgetingVPosAuftschluesselLinkageQuery,
  BudgetingVPosAuftschluesselLinkageQueryVariables
>;
export const BudgetingVPosAuftschluesselLinkageChangeHistoryListDocument = gql`
  query BudgetingVPosAuftschluesselLinkageChangeHistoryList($budgetingVPosAuftschluesselLinkageId: ID!) {
    getBudgetingVPosAuftschluesselLinkageChangeHistoryList(budgetingVPosAuftschluesselLinkageId: $budgetingVPosAuftschluesselLinkageId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBudgetingVPosAuftschluesselLinkageChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    BudgetingVPosAuftschluesselLinkageChangeHistoryListQuery,
    BudgetingVPosAuftschluesselLinkageChangeHistoryListQueryVariables
  > &
    ({ variables: BudgetingVPosAuftschluesselLinkageChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingVPosAuftschluesselLinkageChangeHistoryListQuery, BudgetingVPosAuftschluesselLinkageChangeHistoryListQueryVariables>(
    BudgetingVPosAuftschluesselLinkageChangeHistoryListDocument,
    options
  );
}
export function useBudgetingVPosAuftschluesselLinkageChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BudgetingVPosAuftschluesselLinkageChangeHistoryListQuery,
    BudgetingVPosAuftschluesselLinkageChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BudgetingVPosAuftschluesselLinkageChangeHistoryListQuery,
    BudgetingVPosAuftschluesselLinkageChangeHistoryListQueryVariables
  >(BudgetingVPosAuftschluesselLinkageChangeHistoryListDocument, options);
}
export function useBudgetingVPosAuftschluesselLinkageChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BudgetingVPosAuftschluesselLinkageChangeHistoryListQuery,
        BudgetingVPosAuftschluesselLinkageChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BudgetingVPosAuftschluesselLinkageChangeHistoryListQuery,
    BudgetingVPosAuftschluesselLinkageChangeHistoryListQueryVariables
  >(BudgetingVPosAuftschluesselLinkageChangeHistoryListDocument, options);
}
export type BudgetingVPosAuftschluesselLinkageChangeHistoryListQueryHookResult = ReturnType<
  typeof useBudgetingVPosAuftschluesselLinkageChangeHistoryListQuery
>;
export type BudgetingVPosAuftschluesselLinkageChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useBudgetingVPosAuftschluesselLinkageChangeHistoryListLazyQuery
>;
export type BudgetingVPosAuftschluesselLinkageChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useBudgetingVPosAuftschluesselLinkageChangeHistoryListSuspenseQuery
>;
export type BudgetingVPosAuftschluesselLinkageChangeHistoryListQueryResult = Apollo.QueryResult<
  BudgetingVPosAuftschluesselLinkageChangeHistoryListQuery,
  BudgetingVPosAuftschluesselLinkageChangeHistoryListQueryVariables
>;
