import React, { FC } from 'react';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../History/HistoryListingTable';
import { useHeVertragZahlungsregelChangeHistoryListQuery } from '../../gql/HeVertragQueries.types';

type Props = {
  objektId: string;
  heVertragId: string;
  zahlungsregelId: string;
};

const ZahlungsregelChangeHistoryListingTable: FC<Props> = ({ objektId, heVertragId, zahlungsregelId }) => {
  const { data, loading } = useHeVertragZahlungsregelChangeHistoryListQuery({ variables: { objektId, heVertragId, zahlungsregelId } });
  const historyList = data?.getHeVertragZahlungsregelChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="objekt-he-vartrag-zahlungsregel"
    />
  );
};

export default ZahlungsregelChangeHistoryListingTable;
