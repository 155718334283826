import React from 'react';
import { EuroAmount } from '../../../../../components/Number';
import { InfoAbrKreis } from './InfoAbrKreisTable';
import AbrKreisBezeichnung from '../../AbrKreisBezeichnung';
import { NestedTableWithColSelectorColProps } from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

const infoAbrKreisTableColumns: NestedTableWithColSelectorColProps<InfoAbrKreis>[] = [
  {
    title: 'Abrechnungskreis',
    key: 'abrechnungskreis',
    width: 200,
    defaultSelected: true,
    render: (text, abrKreis) => <AbrKreisBezeichnung abrKreis={abrKreis} isInfoAbrKreis />,
  },
  { title: '' },
  { title: '' },
  { title: '' },
  { title: '' },
  {
    title: 'Ausgaben',
    key: 'ausgaben',
    defaultSelected: true,
    align: 'right',
    render: (text, abrKreis) => <EuroAmount value={abrKreis.sumAufwand} />,
  },
  {
    title: 'Einnahmen',
    key: 'einnahmen',
    defaultSelected: true,
    align: 'right',
    render: (text, abrKreis) => <EuroAmount value={abrKreis.erloes.summe} />,
  },
  {
    title: 'Saldo',
    defaultSelected: true,
    align: 'right',
    render: (text, abrKreis) => <EuroAmount value={abrKreis.saldo} />,
  },
];

export default infoAbrKreisTableColumns;
