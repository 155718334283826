import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { OrderGenerierlaufType } from '../../../../../types';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useFilterGenLaufOrderPaymentTransactionTypeListQuery } from '../../../gql/GenerierlaufFilter/OrderGenerierlaufFilterQueries.types';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: OrderGenerierlaufType;
} & SelectProps;

const GenerierlaufPaymentTransactionTypeFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufOrderPaymentTransactionTypeListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufOrderPaymentTransactionTypeList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Zahlungsverkehrsart auswählen"
      {...restProps}
    >
      {list.map((paymentTransactionType) => (
        <Select.Option key={paymentTransactionType.value} value={paymentTransactionType.value}>
          {paymentTransactionType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufPaymentTransactionTypeFormSelect;
