import React from 'react';
import { FormikProps } from 'formik';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import { InfoFeldFormFileReference } from './infoFeldFormMapper';

type InfoFeldFileUploadProps<T> = {
  formikProps: FormikProps<T>;
  name: string;
  documentList: InfoFeldFormFileReference[];
};

type FileData = {
  filename: string;
  file: RcFile;
  isUpload: boolean;
  ts: string;
};

function InfoFeldFileUpload<T>({ formikProps, name, documentList }: InfoFeldFileUploadProps<T>) {
  return (
    <Upload.Dragger
      beforeUpload={(file, fileList) => {
        let files: FileData[] = [];

        formikProps.setFieldTouched(name, true, false);
        fileList.forEach((file) => {
          if (validateFile(file, formikProps, name)) {
            const data: FileData = {
              filename: file.name,
              file,
              isUpload: true,
              ts: dayjsCustom().format(),
            };
            files = [...files, data];
          }
        });
        files.length > 0 && formikProps.setFieldValue(name, documentList ? [...documentList, ...files] : [...files]);

        return false;
      }}
      multiple
      showUploadList={false}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Dateien hinzufügen</p>
      <p className="ant-upload-hint">Eine oder mehrere Dateien hochladen</p>
    </Upload.Dragger>
  );
}

function validateFile<T>(file: RcFile, formikProps: FormikProps<T>, name: string): boolean {
  const hasNoType = !file.type;
  if (hasNoType) {
    formikProps.setFieldError(name, `${file.name} hat keinen Dateityp. Ordner dürfen nicht hochgeladen werden.`);
    return false;
  }
  return true;
}

export default InfoFeldFileUpload;
