import React from 'react';
import { Route } from 'react-router-dom';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import AndromedaSysSettingsKontierungstabellePage from './AndromedaSysSettingsKontierungstabellePage';
import { isAdmin } from '../../../../security/permissionChecks';

const andromedaSysSettingsKontierungstabellePageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.kontierungstabellePage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsKontierungstabellePage />} />}
  />
);

export default andromedaSysSettingsKontierungstabellePageRoute;
