import { TDeviationCell, TDeviationReferenceParams } from './deviation.model';
import {
  DeviationAttributeType,
  DeviationChangeType,
  DeviationChangeTypeTuple,
  DeviationEntryType,
  DeviationUrsacheType,
  DeviationUrsacheTypeTuple,
} from '../../types';
import {
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdAuftrag_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeNutzungsArt_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBestandseinheit_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeVertrag_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeVertragVorschreibungsposition_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBkAbrechnungsdefinitionVersion_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdEingangsrechnung_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeAbrechnungHeVertragVersion_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeAbrechnungsdefinitionVersion_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeVertrag_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdKontierungstabelle_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdLeerstehungAufwandskonto_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdRechtstraegerSteuerpflicht_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdRepFondsEinstellung_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdTopAbrechnung_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstKategorie_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstKategorieEntry_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstRegelset_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstVomAufwand_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVertragsart_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVertragspartner_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorschreibungsposition_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorschreibungspositionVersion_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorsteuerkuerzung_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorsteuerkuerzungEinstellung_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdWeAbrechnungsdefinitionVersion_Fragment,
  DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdZinsliste_Fragment,
  DeviationEntryAttributeFields_DeviationAttributeAmount_Fragment,
  DeviationEntryAttributeFields_DeviationAttributeDate_Fragment,
  DeviationEntryAttributeFields_DeviationAttributeNumber_Fragment,
  DeviationEntryAttributeFields_DeviationAttributePercent_Fragment,
  DeviationEntryAttributeFields_DeviationAttributeString_Fragment,
  DeviationEntryAttributeFields_DeviationAttributeUriList_Fragment,
  DeviationEntryAttributeFieldsFragment,
  DeviationLinkListIdsFields_DeviationUriIdBeVertrag_Fragment,
  DeviationLinkListIdsFields_DeviationUriIdKontierungstabelle_Fragment,
  DeviationLinkListIdsFields_DeviationUriIdLeerstehungAufwandskonto_Fragment,
  DeviationLinkListIdsFields_DeviationUriIdTopAbrechnung_Fragment,
  DeviationLinkListIdsFields_DeviationUriIdUstKategorieEntry_Fragment,
  DeviationLinkListIdsFields_DeviationUriIdUstVomAufwand_Fragment,
  DeviationLinkListIdsFields_DeviationUriIdVorschreibungspositionVersion_Fragment,
  DeviationLinkListIdsFields_DeviationUriIdZinsliste_Fragment,
  DeviationSourceEntity_AuftragDeviationSourceEntity_Fragment,
  DeviationSourceEntity_BeVertragDeviationSourceEntity_Fragment,
  DeviationSourceEntity_BuchungsanweisungDeviationSourceEntity_Fragment,
  DeviationSourceEntity_HeAbrechnungHeVertragDeviationSourceEntity_Fragment,
  DeviationSourceEntity_ObjektAbrechnungDeviationSourceEntity_Fragment,
  DeviationSourceEntity_TopAbrechnungDeviationSourceEntity_Fragment,
  DeviationSourceEntity_UstVomAufwandDeviationSourceEntity_Fragment,
  DeviationSourceEntity_VorsteuerkuerzungDeviationSourceEntity_Fragment,
  DeviationSourceEntityFragment,
} from './gql/deviationFragments.types';
import { generatePathToVorschreibungspositionenPage } from '../../features/Vorschreibungsposition/vorschreibungspositionUriPaths';
import { generatePathToUstKategoriePage } from '../../features/Ust/UstKategorie/ustKategorieUriHelper';
import { generatePathToBestandseinheitDetailsPage } from '../../features/Bestandseinheit/bestandseinheitUriPaths';
import { generatePathToUstRegelsetPage } from '../../features/Ust/UstRegelset/ustRegelsetUriHelper';
import { generatePathToBeVertragDetailsPage } from '../../features/Vertrag/BeVertrag/beVertragUriPaths';
import { generatePathToZinslisteDetailsPage } from '../../features/Zinsliste/zinslisteUriPaths';
import { generatePathToVorschreibungDetailsPage } from '../../features/Vorschreibung/vorschreibungUriPaths';
import { generatePathToKontierungstabelle } from '../../features/Kontierungstabelle/kontierungstabelleUriHelper';
import {
  generatePathToAbrechnungsdefinitionenPage,
  generatePathToAbrechnungsdefinitionVersionViewPage,
} from '../../features/Abrechnungsdefinition/abrDefUriPaths';
import { generatePathToWeAbrechnungDetailsTopabrechnungDetailsPage } from '../../features/Abrechnung/BkOrWe/weAbrechnungUriPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../features/Rechtstraeger/rechtstraegerHelper';
import { generatePathToVorsteuerkuerzungDetailsPage } from '../../features/Vorsteuerkuerzung/vorsteuerkuerzungUriPaths';
import { URI_SYS_SETTINGS } from '../../constants/configurationUriPaths';
import { generatePathToUstVomAufwandDetailsPage } from '../../features/UstVomAufwand/ustVomAufwandUriPaths';
import { generatePathToAllgemeineEinstellungen } from '../../features/AllgemeineEinstellung/allgemeineEinstellungUriPaths';
import { generatePathToHeAbrechnungDetailsHeVertragDetailsPage } from '../../features/Abrechnung/He/heAbrechnungUriPaths';
import { generatePathToHeVertragDetailsPage } from '../../features/Vertrag/HeVertrag/heVertragUriPaths';
import { generatePathToSingleInvoiceDetailsPage } from '../../features/IncomingInvoice/incomingInvoiceUriPaths';

export const DEVIATION_CELL_EMPTY_VALUE = '-';

export const DEVIATIONS_MENU_LIST_ITEM_TITLE = 'Abweichungen';
export const DEVIATIONS_TAB_TITLE = 'Abweichungen';

export const isDeviationChangeTypeAenderung = (type: DeviationChangeTypeTuple) => type.value === DeviationChangeType.Aenderung;
export const isDeviationChangeTypeEntfernt = (type: DeviationChangeTypeTuple) => type.value === DeviationChangeType.Entfernt;
export const isDeviationChangeTypeNeu = (type: DeviationChangeTypeTuple) => type.value === DeviationChangeType.Neu;

export const isDeviationEntryTypeAlt = (type: DeviationEntryType) => type === DeviationEntryType.Alt;
export const isDeviationEntryTypeNeu = (type: DeviationEntryType) => type === DeviationEntryType.Neu;

export const isDeviationAttributeTypeAmount = (type: DeviationAttributeType) => type === DeviationAttributeType.Amount;
export const isDeviationAttributeTypeDate = (type: DeviationAttributeType) => type === DeviationAttributeType.Date;
export const isDeviationAttributeTypeString = (type: DeviationAttributeType) => type === DeviationAttributeType.String;
export const isDeviationAttributeTypePercent = (type: DeviationAttributeType) => type === DeviationAttributeType.Percent;
export const isDeviationAttributeTypeUri = (type: DeviationAttributeType) => type === DeviationAttributeType.Uri;
export const isDeviationAttributeTypeNumber = (type: DeviationAttributeType) => type === DeviationAttributeType.Number;

/************ type guards ************/
// type guards Source Entity
export const isVertragSourceEntity = (
  sourceEntity: DeviationSourceEntityFragment
): sourceEntity is DeviationSourceEntity_BeVertragDeviationSourceEntity_Fragment => sourceEntity.__typename === 'BeVertragDeviationSourceEntity';
export const isVorschreibungSourceEntity = (
  sourceEntity: DeviationSourceEntityFragment
): sourceEntity is DeviationSourceEntity_AuftragDeviationSourceEntity_Fragment => sourceEntity.__typename === 'AuftragDeviationSourceEntity';
export const isBuchungsanweisungSourceEntity = (
  sourceEntity: DeviationSourceEntityFragment
): sourceEntity is DeviationSourceEntity_BuchungsanweisungDeviationSourceEntity_Fragment =>
  sourceEntity.__typename === 'BuchungsanweisungDeviationSourceEntity';
export const isHeAbrechnungHeVertragSourceEntity = (
  sourceEntity: DeviationSourceEntityFragment
): sourceEntity is DeviationSourceEntity_HeAbrechnungHeVertragDeviationSourceEntity_Fragment =>
  sourceEntity.__typename === 'HeAbrechnungHeVertragDeviationSourceEntity';
export const isObjektAbrechnungSourceEntity = (
  sourceEntity: DeviationSourceEntityFragment
): sourceEntity is DeviationSourceEntity_ObjektAbrechnungDeviationSourceEntity_Fragment =>
  sourceEntity.__typename === 'ObjektAbrechnungDeviationSourceEntity';
export const isTopAbrechnungSourceEntity = (
  sourceEntity: DeviationSourceEntityFragment
): sourceEntity is DeviationSourceEntity_TopAbrechnungDeviationSourceEntity_Fragment =>
  sourceEntity.__typename === 'TopAbrechnungDeviationSourceEntity';
export const isUstVomAufwandSourceEntity = (
  sourceEntity: DeviationSourceEntityFragment
): sourceEntity is DeviationSourceEntity_UstVomAufwandDeviationSourceEntity_Fragment =>
  sourceEntity.__typename === 'UstVomAufwandDeviationSourceEntity';
export const isVorsteuerkuerzungSourceEntity = (
  sourceEntity: DeviationSourceEntityFragment
): sourceEntity is DeviationSourceEntity_VorsteuerkuerzungDeviationSourceEntity_Fragment =>
  sourceEntity.__typename === 'VorsteuerkuerzungDeviationSourceEntity';

// type guards attribute
export const isDeviationAttributeString = (
  attribute: DeviationEntryAttributeFieldsFragment
): attribute is DeviationEntryAttributeFields_DeviationAttributeString_Fragment => attribute.__typename === 'DeviationAttributeString';
export const isDeviationAttributeDate = (
  attribute: DeviationEntryAttributeFieldsFragment
): attribute is DeviationEntryAttributeFields_DeviationAttributeDate_Fragment => attribute.__typename === 'DeviationAttributeDate';
export const isDeviationAttributeAmount = (
  attribute: DeviationEntryAttributeFieldsFragment
): attribute is DeviationEntryAttributeFields_DeviationAttributeAmount_Fragment => attribute.__typename === 'DeviationAttributeAmount';
export const isDeviationAttributePercent = (
  attribute: DeviationEntryAttributeFieldsFragment
): attribute is DeviationEntryAttributeFields_DeviationAttributePercent_Fragment => attribute.__typename === 'DeviationAttributePercent';
export const isDeviationAttributeUri = (
  attribute: DeviationEntryAttributeFieldsFragment
): attribute is DeviationEntryAttributeFields_DeviationAttributeUriList_Fragment => attribute.__typename === 'DeviationAttributeUriList';
export const isDeviationAttributeNumber = (
  attribute: DeviationEntryAttributeFieldsFragment
): attribute is DeviationEntryAttributeFields_DeviationAttributeNumber_Fragment => attribute.__typename === 'DeviationAttributeNumber';

// type guards URIs
// NOTE:  if *DeviationUriId is from App => use DeviationEntryAttributeDeviationAttributeUriListIdsFields_*_Fragment as attribute type
//        if *DeviationUriId is from Order => use DeviationLinkListIdsFields_*_Fragment | DeviationEntryAttributeDeviationAttributeUriListIdsFields_*_Fragment as attribute type - because in Order these types can be used in linkList.ids and uriList.ids
export const isAuftragDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdAuftrag_Fragment =>
  attribute.__typename === 'DeviationUriIdAuftrag';

export const isBeNutzungsArtDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeNutzungsArt_Fragment =>
  attribute.__typename === 'DeviationUriIdBeNutzungsArt';

export const isBeVertragDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is
  | DeviationLinkListIdsFields_DeviationUriIdBeVertrag_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeVertrag_Fragment => attribute.__typename === 'DeviationUriIdBeVertrag';

export const isBeVertragVorschreibungspositionDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeVertragVorschreibungsposition_Fragment =>
  attribute.__typename === 'DeviationUriIdBeVertragVorschreibungsposition';

export const isBestandseinheitDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBestandseinheit_Fragment =>
  attribute.__typename === 'DeviationUriIdBestandseinheit';

export const BkAbrechnungsdefinitionVersionDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBkAbrechnungsdefinitionVersion_Fragment =>
  attribute.__typename === 'DeviationUriIdBkAbrechnungsdefinitionVersion';

export const isEingangsrechnungDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdEingangsrechnung_Fragment =>
  attribute.__typename === 'DeviationUriIdEingangsrechnung';

export const isHeAbrechnungHeVertragVersionUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeAbrechnungHeVertragVersion_Fragment =>
  attribute.__typename === 'DeviationUriIdHeAbrechnungHeVertragVersion';

export const isHeAbrechnungsdefinitionVersionUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeAbrechnungsdefinitionVersion_Fragment =>
  attribute.__typename === 'DeviationUriIdHeAbrechnungsdefinitionVersion';

export const isHeVertragUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeVertrag_Fragment =>
  attribute.__typename === 'DeviationUriIdHeVertrag';

export const isKontierungstabelleDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is
  | DeviationLinkListIdsFields_DeviationUriIdKontierungstabelle_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdKontierungstabelle_Fragment =>
  attribute.__typename === 'DeviationUriIdKontierungstabelle';

export const isLeerstehungAufwandskontoUriId = (
  attribute: TDeviationReferenceParams
): attribute is
  | DeviationLinkListIdsFields_DeviationUriIdLeerstehungAufwandskonto_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdLeerstehungAufwandskonto_Fragment =>
  attribute.__typename === 'DeviationUriIdLeerstehungAufwandskonto';

export const isRechtstraegerSteuerpflichtDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdRechtstraegerSteuerpflicht_Fragment =>
  attribute.__typename === 'DeviationUriIdRechtstraegerSteuerpflicht';

export const isRepFondsEinstellungUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdRepFondsEinstellung_Fragment =>
  attribute.__typename === 'DeviationUriIdRepFondsEinstellung';

export const isTopAbrechnungDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is
  | DeviationLinkListIdsFields_DeviationUriIdTopAbrechnung_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdTopAbrechnung_Fragment =>
  attribute.__typename === 'DeviationUriIdTopAbrechnung';

export const isUstKategorieDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstKategorie_Fragment =>
  attribute.__typename === 'DeviationUriIdUstKategorie';

export const isUstKategorieEntryDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is
  | DeviationLinkListIdsFields_DeviationUriIdUstKategorieEntry_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstKategorieEntry_Fragment =>
  attribute.__typename === 'DeviationUriIdUstKategorieEntry';

export const isUstRegelwerkDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstRegelset_Fragment =>
  attribute.__typename === 'DeviationUriIdUstRegelset';

export const isUstVomAufwandUriId = (
  attribute: TDeviationReferenceParams
): attribute is
  | DeviationLinkListIdsFields_DeviationUriIdUstVomAufwand_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstVomAufwand_Fragment =>
  attribute.__typename === 'DeviationUriIdUstVomAufwand';

export const isVertragsartUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVertragsart_Fragment =>
  attribute.__typename === 'DeviationUriIdVertragsart';

export const isVertragpartnerUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVertragspartner_Fragment =>
  attribute.__typename === 'DeviationUriIdVertragspartner';

export const isVorschreibungspositionDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorschreibungsposition_Fragment =>
  attribute.__typename === 'DeviationUriIdVorschreibungsposition';

export const isVorschreibungspositionVersionDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is
  | DeviationLinkListIdsFields_DeviationUriIdVorschreibungspositionVersion_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorschreibungspositionVersion_Fragment =>
  attribute.__typename === 'DeviationUriIdVorschreibungspositionVersion';

export const isVorsteuerkuerzungDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorsteuerkuerzung_Fragment =>
  attribute.__typename === 'DeviationUriIdVorsteuerkuerzung';

export const isVorsteuerkuerzungEinstellungDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorsteuerkuerzungEinstellung_Fragment =>
  attribute.__typename === 'DeviationUriIdVorsteuerkuerzungEinstellung';

export const isWeAbrechnungsdefinitionVersionDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdWeAbrechnungsdefinitionVersion_Fragment =>
  attribute.__typename === 'DeviationUriIdWeAbrechnungsdefinitionVersion';

export const isZinslisteDeviationUriId = (
  attribute: TDeviationReferenceParams
): attribute is
  | DeviationLinkListIdsFields_DeviationUriIdZinsliste_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdZinsliste_Fragment => attribute.__typename === 'DeviationUriIdZinsliste';

/************ end of type guards ************/

export const isDeviationChangeTypeAenderungAndEntryTypeNeu = (cell: TDeviationCell) =>
  isDeviationChangeTypeAenderung(cell.changeType) && isDeviationEntryTypeNeu(cell.rowType);

export const isDeviationChangeTypeNeuAndEntryTypeNeu = (cell: TDeviationCell) =>
  isDeviationChangeTypeNeu(cell.changeType) && isDeviationEntryTypeNeu(cell.rowType);

export const isDeviationChangeTypeEntferntAndEntryTypeAlt = (cell: TDeviationCell) =>
  isDeviationChangeTypeEntfernt(cell.changeType) && isDeviationEntryTypeAlt(cell.rowType);

export const isDeviationChangeTypeAenderungAndEntryTypeAlt = (cell: TDeviationCell) =>
  isDeviationChangeTypeAenderung(cell.changeType) && isDeviationEntryTypeAlt(cell.rowType);

export const isDeviationChangeTypeNeuAndEntryTypeAlt = (cell: TDeviationCell) =>
  isDeviationChangeTypeNeu(cell.changeType) && isDeviationEntryTypeAlt(cell.rowType);

export const isDeviationChangeTypeEntferntAndEntryTypeNeu = (cell: TDeviationCell) =>
  isDeviationChangeTypeEntfernt(cell.changeType) && isDeviationEntryTypeNeu(cell.rowType);

export const hasDeviationCellEmptyValue = (cell: TDeviationCell) => {
  return cell.value === DEVIATION_CELL_EMPTY_VALUE;
};

export const isDeviationUrsacheNichtErmittelbar = (ursache: DeviationUrsacheTypeTuple) => {
  return (
    ursache.value === DeviationUrsacheType.NutzungsArtNichtErmittelbar ||
    ursache.value === DeviationUrsacheType.SteuerpflichtNichtErmittelbar ||
    ursache.value === DeviationUrsacheType.UstKategorieNichtErmittelbar ||
    ursache.value === DeviationUrsacheType.UstKategorieEntryNichtErmittelbar
  );
};

export const cellsNewAltOrder = (cellsCol: TDeviationCell[]) => {
  const cellsNeu = cellsCol.filter((cell) => cell.rowType === DeviationEntryType.Neu);
  const cellsAlt = cellsCol.filter((cell) => cell.rowType === DeviationEntryType.Alt);
  return [...cellsNeu, ...cellsAlt];
};

export const generateURIforDeviation = (uriParams: TDeviationReferenceParams) => {
  if (isVorschreibungspositionDeviationUriId(uriParams)) {
    return generatePathToVorschreibungspositionenPage(uriParams.vorschreibungspositionId, uriParams.vorschreibungspositionStatus);
  } else if (isUstKategorieDeviationUriId(uriParams)) {
    return generatePathToUstKategoriePage(uriParams.ustKategorieStatus.value, uriParams.ustKategorieDeviationUstKategorieEntryId ?? undefined, {
      ustKategorieId: uriParams.ustKategorieId,
    });
  } else if (isUstRegelwerkDeviationUriId(uriParams)) {
    return generatePathToUstRegelsetPage();
  } else if (
    isBeNutzungsArtDeviationUriId(uriParams) ||
    isBestandseinheitDeviationUriId(uriParams) ||
    isVertragsartUriId(uriParams) ||
    isVertragpartnerUriId(uriParams)
  ) {
    return generatePathToBestandseinheitDetailsPage(uriParams.objektId, uriParams.bestandseinheitId);
  } else if (isBeVertragVorschreibungspositionDeviationUriId(uriParams) || isBeVertragDeviationUriId(uriParams)) {
    return generatePathToBeVertragDetailsPage(uriParams.objektId, uriParams.bestandseinheitId, uriParams.vertragId);
  } else if (isZinslisteDeviationUriId(uriParams)) {
    return generatePathToZinslisteDetailsPage(uriParams.objektId, uriParams.zinslisteId);
  } else if (isRechtstraegerSteuerpflichtDeviationUriId(uriParams)) {
    return generatePathToRechtstraegerDetailsPage(uriParams.rechtstraegerId);
  } else if (isEingangsrechnungDeviationUriId(uriParams)) {
    return generatePathToSingleInvoiceDetailsPage(uriParams.belegFileId, uriParams.belegId);
  } else if (isAuftragDeviationUriId(uriParams)) {
    return generatePathToVorschreibungDetailsPage(uriParams.auftragId);
  } else if (isKontierungstabelleDeviationUriId(uriParams)) {
    return generatePathToKontierungstabelle(uriParams.kontierungstabelleId);
  } else if (isWeAbrechnungsdefinitionVersionDeviationUriId(uriParams)) {
    return generatePathToAbrechnungsdefinitionenPage(uriParams.weAbrechnungsdefinitionId);
  } else if (isTopAbrechnungDeviationUriId(uriParams)) {
    return generatePathToWeAbrechnungDetailsTopabrechnungDetailsPage(uriParams.objektId, uriParams.objektAbrechnungId, uriParams.topAbrechnungId);
  } else if (isVorsteuerkuerzungDeviationUriId(uriParams)) {
    return generatePathToVorsteuerkuerzungDetailsPage(uriParams.objektId, uriParams.vorsteuerkuerzungId);
  } else if (isVorsteuerkuerzungEinstellungDeviationUriId(uriParams)) {
    return URI_SYS_SETTINGS.vorsteuerkuerzungPage;
  } else if (isRepFondsEinstellungUriId(uriParams)) {
    return URI_SYS_SETTINGS.repFondsPage;
  } else if (isUstVomAufwandUriId(uriParams)) {
    return generatePathToUstVomAufwandDetailsPage(uriParams.objektId, uriParams.ustVomAufwandId);
  } else if (isVorschreibungspositionVersionDeviationUriId(uriParams)) {
    return generatePathToVorschreibungspositionenPage(uriParams.vorschreibungspositionId);
  } else if (isLeerstehungAufwandskontoUriId(uriParams)) {
    return generatePathToAllgemeineEinstellungen();
  } else if (isHeAbrechnungHeVertragVersionUriId(uriParams)) {
    return generatePathToHeAbrechnungDetailsHeVertragDetailsPage(uriParams.objektId, uriParams.heAbrechnungId, uriParams.heAbrechnungHeVertragId);
  } else if (isHeAbrechnungsdefinitionVersionUriId(uriParams)) {
    return generatePathToAbrechnungsdefinitionVersionViewPage(uriParams.heAbrechnungsdefinitionId, uriParams.heAbrechnungsdefinitionVersionId, false);
  } else if (isHeVertragUriId(uriParams)) {
    return generatePathToHeVertragDetailsPage(uriParams.objektId, uriParams.heVertragId);
  } else {
    return '';
  }
};
