import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../../security/permissionChecks';
import SysSettingsAbrechnungsdefinitionPage from './SysSettingsAbrechnungsdefinitionPage';
import AuthRoute from '../../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../../constants/configurationUriPaths';

const sysSettingsAbrechnungsdefinitionPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.abrechnungsdefinitionPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAbrechnungsdefinitionPage />} />}
  />
);

export default sysSettingsAbrechnungsdefinitionPageRoute;
