import React from 'react';
import { Layout, Table } from 'antd';
// @ts-ignore
import { userList as data } from '../../../../features/User/dummyData';
// @ts-ignore
import columns from '../../../../features/User/ColumnsUserTable';

const { Content } = Layout;

const VerwaltungBenutzerPage = () => (
  <Content style={{ margin: '0 16px' }}>
    <Table columns={columns} dataSource={data} rowKey={(record: any) => record.username} size="small" />
  </Content>
);

export default VerwaltungBenutzerPage;
