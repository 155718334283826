import React, { FC } from 'react';
import { Empty } from 'antd';
import NestedTableWithColSelector from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { SubAbrOffenerPosten } from '../../../../../../../types';
import subAbrTabOffenePostenDetailsTableColumns from './subAbrTabOffenePostenDetailsTableColumns';

type Props = {
  offenePosten: SubAbrOffenerPosten[];
  objektId: string;
  abrechnungszeitraumVon: string;
  abrechnungszeitraumBis: string;
  parentRowKeys?: string[];
  visible?: boolean;
};

const SubAbrTabOffenePostenDetailsTable: FC<Props> = ({
  offenePosten,
  objektId,
  abrechnungszeitraumBis,
  abrechnungszeitraumVon,
  parentRowKeys,
  visible = true,
}) => {
  return (
    <NestedTableWithColSelector<SubAbrOffenerPosten>
      visible={visible}
      parentRowKeys={parentRowKeys}
      level={2}
      locale={{
        emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
      }}
      dataSource={offenePosten}
      columns={subAbrTabOffenePostenDetailsTableColumns(objektId, abrechnungszeitraumVon, abrechnungszeitraumBis)}
    />
  );
};

export default SubAbrTabOffenePostenDetailsTable;
