import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import vorschreibungTableColumns from './vorschreibungTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { AuftragListEntry } from '../../../../types';
import { HandleTableSorting } from '../../../../shared/typeHelpers';

type Props = {
  loading: boolean;
  handleTableSorting: HandleTableSorting;
  pagination: PaginationProps;
  auftragList?: AuftragListEntry[];
  useDefaultTextLength?: boolean;
  filterIdentifier?: string;
};

const VorschreibungTable: FC<Props> = ({ auftragList, loading, handleTableSorting, pagination, filterIdentifier, useDefaultTextLength = true }) => {
  return (
    <TableWithColSelector<AuftragListEntry>
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      size="small"
      dataSource={auftragList}
      columns={vorschreibungTableColumns(useDefaultTextLength)}
      pagination={pagination}
      onChange={handleTableSorting}
      rowKey={(record) => record.auftragId}
      filterIdentifier={filterIdentifier ?? 'vorschreibung'}
    />
  );
};

export default VorschreibungTable;
