import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { paddingLeftForRow } from '../HeADetailsBlock/DetailsTable/details-table-styles';
import { Buchungszeile } from '../../hauseigentuemerAbrechnung-types';

const HeABuchungszeile: FC<{ buchungszeile: Buchungszeile }> = ({ buchungszeile }) => (
  <View style={[pdfStyles.row, { marginRight: '20%' }]}>
    <Text
      style={[
        pdfStyles.textBulletin,
        paddingLeftForRow.buchungszeile,
        {
          width: '17%',
          textAlign: 'left',
        },
      ]}
    >
      {buchungszeile.buchungsdatum}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        paddingLeftForRow.buchungszeile,
        {
          width: '68%',
          textAlign: 'left',
        },
      ]}
    >
      {buchungszeile.text}
    </Text>

    <Text style={[pdfStyles.textBulletin, { width: '15%', textAlign: 'right' }]}>{buchungszeile.value}</Text>
  </View>
);

export default HeABuchungszeile;
