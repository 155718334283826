import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { ZVorschreibungspositionSumme } from '../../zinsliste-types';
import { zVposColumnWidthsAndAlignment } from '../styles/zVPos-table-styles';

type Props = {
  vorschreibungspositionSumme: ZVorschreibungspositionSumme;
};

const ZVPosTableSumme: FC<Props> = ({ vorschreibungspositionSumme }) => (
  <View style={[pdfStyles.row]}>
    <Text style={[pdfStyles.textBulletin, { width: '42.84%' }]} />
    <View style={[pdfStyles.row, pdfStyles.borderTopSmall, { width: '57.16%' }]}>
      <Text style={[pdfStyles.textBulletin, { ...zVposColumnWidthsAndAlignment.netto }]}>{vorschreibungspositionSumme.netto}</Text>
      <Text style={[{ ...zVposColumnWidthsAndAlignment.ustSatz }]} />
      <Text style={[pdfStyles.textBulletin, { ...zVposColumnWidthsAndAlignment.ustBetrag }]}>{vorschreibungspositionSumme.ustBetrag}</Text>
      <Text style={[pdfStyles.textBulletin, { ...zVposColumnWidthsAndAlignment.brutto }]}>{vorschreibungspositionSumme.brutto}</Text>
    </View>
  </View>
);

export default ZVPosTableSumme;
