import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useRechtstraegerListQuery } from '../../../features/Rechtstraeger/gql/RechtsTraegerQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';

const RechnungsAusstellerRechtstraegerSelect: FC<SelectProps> = ({ name, ...restProps }) => {
  const { data, loading } = useRechtstraegerListQuery({
    variables: {
      hasBuchungskreis: true,
    },
  });
  const rechtstraegerWithBuchungskreisList = data?.getRechtstraegerList.data.contentList || [];

  return (
    <Select
      name={name}
      id={name}
      loading={loading}
      placeholder="Rechnungsaussteller auswählen"
      filterOption={selectFilterOption}
      showSearch
      allowClear
      {...restProps}
    >
      {rechtstraegerWithBuchungskreisList.map((rechtstrager) => (
        <Select.Option key={rechtstrager.rechtstraegerId} value={rechtstrager.rechtstraegerId}>
          {rechtstrager.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RechnungsAusstellerRechtstraegerSelect;
