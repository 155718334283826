import React from 'react';
import { Space } from 'antd';
import styled from 'styled-components';

const StyledSpaceForCardContentAndIcon = styled(Space)`
  align-items: stretch;
  height: 100%;
  width: 100%;
  border: 1px solid #d9d9d9;

  & > :nth-child(2) {
    width: 100%;
    padding-right: 8px;
  }
`;

const StyledSpaceForCardContent = styled(Space)`
  align-items: stretch;
  width: 100%;
  justify-content: space-between;
`;

const styleForIconAndButton: React.CSSProperties = {
  width: '30px',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
};

export { StyledSpaceForCardContentAndIcon, StyledSpaceForCardContent, styleForIconAndButton };
