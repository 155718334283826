import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PostItFragmentDoc } from '../../Rechtstraeger/PostIt/gql/PostItFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubAdministrationListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type SubAdministrationListQuery = {
  getSubAdministrationList: {
    data: Array<{
      countBestandseinheit: number;
      countSubAdministration: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deliveryAddress?: string | null;
      deliveryAddressHaupt: boolean;
      eigentuemerBezeichnung: string;
      eigentuemerId: string;
      from: string;
      saldouebertrag: number;
      subAdministrationId: string;
      to?: string | null;
      updatedByMitarbeiterId?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SubAdministrationQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  subAdministrationId: Types.Scalars['ID']['input'];
}>;

export type SubAdministrationQuery = {
  getSubAdministration: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deliveryAddressId?: string | null;
      deliveryAddress?: string | null;
      deliveryAddressHaupt: boolean;
      eigentuemerBezeichnung: string;
      eigentuemerId: string;
      from: string;
      saldouebertrag: number;
      subAdministrationId: string;
      to?: string | null;
      updatedByMitarbeiterId?: string | null;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SubAdministrationChangeHistoryQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  subAdministrationId: Types.Scalars['ID']['input'];
}>;

export type SubAdministrationChangeHistoryQuery = {
  getSubAdministrationChangeHistory: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const SubAdministrationListDocument = gql`
  query SubAdministrationList($objektId: ID!) {
    getSubAdministrationList(objektId: $objektId) {
      data {
        countBestandseinheit
        countSubAdministration
        createTs
        createdBy
        createdByMitarbeiterId
        deliveryAddress
        deliveryAddressHaupt
        eigentuemerBezeichnung
        eigentuemerId
        from
        saldouebertrag
        subAdministrationId
        to
        updatedByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSubAdministrationListQuery(
  baseOptions: Apollo.QueryHookOptions<SubAdministrationListQuery, SubAdministrationListQueryVariables> &
    ({ variables: SubAdministrationListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubAdministrationListQuery, SubAdministrationListQueryVariables>(SubAdministrationListDocument, options);
}
export function useSubAdministrationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubAdministrationListQuery, SubAdministrationListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubAdministrationListQuery, SubAdministrationListQueryVariables>(SubAdministrationListDocument, options);
}
export function useSubAdministrationListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubAdministrationListQuery, SubAdministrationListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubAdministrationListQuery, SubAdministrationListQueryVariables>(SubAdministrationListDocument, options);
}
export type SubAdministrationListQueryHookResult = ReturnType<typeof useSubAdministrationListQuery>;
export type SubAdministrationListLazyQueryHookResult = ReturnType<typeof useSubAdministrationListLazyQuery>;
export type SubAdministrationListSuspenseQueryHookResult = ReturnType<typeof useSubAdministrationListSuspenseQuery>;
export type SubAdministrationListQueryResult = Apollo.QueryResult<SubAdministrationListQuery, SubAdministrationListQueryVariables>;
export const SubAdministrationDocument = gql`
  query SubAdministration($objektId: ID!, $subAdministrationId: ID!) {
    getSubAdministration(objektId: $objektId, subAdministrationId: $subAdministrationId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        deliveryAddressId
        deliveryAddress
        deliveryAddressHaupt
        eigentuemerBezeichnung
        eigentuemerId
        from
        postIt {
          ...PostIt
        }
        saldouebertrag
        subAdministrationId
        to
        updatedByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${PostItFragmentDoc}
`;
export function useSubAdministrationQuery(
  baseOptions: Apollo.QueryHookOptions<SubAdministrationQuery, SubAdministrationQueryVariables> &
    ({ variables: SubAdministrationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubAdministrationQuery, SubAdministrationQueryVariables>(SubAdministrationDocument, options);
}
export function useSubAdministrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubAdministrationQuery, SubAdministrationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubAdministrationQuery, SubAdministrationQueryVariables>(SubAdministrationDocument, options);
}
export function useSubAdministrationSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubAdministrationQuery, SubAdministrationQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubAdministrationQuery, SubAdministrationQueryVariables>(SubAdministrationDocument, options);
}
export type SubAdministrationQueryHookResult = ReturnType<typeof useSubAdministrationQuery>;
export type SubAdministrationLazyQueryHookResult = ReturnType<typeof useSubAdministrationLazyQuery>;
export type SubAdministrationSuspenseQueryHookResult = ReturnType<typeof useSubAdministrationSuspenseQuery>;
export type SubAdministrationQueryResult = Apollo.QueryResult<SubAdministrationQuery, SubAdministrationQueryVariables>;
export const SubAdministrationChangeHistoryDocument = gql`
  query SubAdministrationChangeHistory($objektId: ID!, $subAdministrationId: ID!) {
    getSubAdministrationChangeHistory(objektId: $objektId, subAdministrationId: $subAdministrationId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useSubAdministrationChangeHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<SubAdministrationChangeHistoryQuery, SubAdministrationChangeHistoryQueryVariables> &
    ({ variables: SubAdministrationChangeHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubAdministrationChangeHistoryQuery, SubAdministrationChangeHistoryQueryVariables>(
    SubAdministrationChangeHistoryDocument,
    options
  );
}
export function useSubAdministrationChangeHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubAdministrationChangeHistoryQuery, SubAdministrationChangeHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubAdministrationChangeHistoryQuery, SubAdministrationChangeHistoryQueryVariables>(
    SubAdministrationChangeHistoryDocument,
    options
  );
}
export function useSubAdministrationChangeHistorySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubAdministrationChangeHistoryQuery, SubAdministrationChangeHistoryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubAdministrationChangeHistoryQuery, SubAdministrationChangeHistoryQueryVariables>(
    SubAdministrationChangeHistoryDocument,
    options
  );
}
export type SubAdministrationChangeHistoryQueryHookResult = ReturnType<typeof useSubAdministrationChangeHistoryQuery>;
export type SubAdministrationChangeHistoryLazyQueryHookResult = ReturnType<typeof useSubAdministrationChangeHistoryLazyQuery>;
export type SubAdministrationChangeHistorySuspenseQueryHookResult = ReturnType<typeof useSubAdministrationChangeHistorySuspenseQuery>;
export type SubAdministrationChangeHistoryQueryResult = Apollo.QueryResult<
  SubAdministrationChangeHistoryQuery,
  SubAdministrationChangeHistoryQueryVariables
>;
