import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { showErrorNotification } from '../components/message';
import { HTTP_STATUS_CODE } from '../helpers/APIUtils';
import { LogoutFn } from '../shared/Auth/authContext';

export const errorHandlerLink = (logout: LogoutFn) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      //@ts-ignore
      const errors = graphQLErrors.flatMap(getValidationErrorsIfAny);
      if (errors.length !== 0) {
        showErrorNotification('Fehler', errors);
      } else if (graphQLErrors.length > 0) {
        showErrorNotification(`Fehler - ${graphQLErrors[0].message} - ${graphQLErrors[0].path}`);
      } else {
        showErrorNotification(`Fehler`);
      }
    }

    // i.e. lost connection to graphql server - stop graphql server
    if (networkError) {
      // @ts-ignore
      const result = networkError.result;
      // @ts-ignore
      const statusCode = networkError.statusCode;
      const statusCodeMessage = statusCode ? `, Code: ${statusCode}` : '';
      const detailedMessage = result && result.errors ? `, ${result.errors[0].message}` : '';
      if (isUnauthorizedError(networkError)) {
        logout('unauthenticated');
        showErrorNotification(
          `Unauthorized - 401`,
          [{ type: `Session abgelaufen`, message: `Ihre Sitzung ist abgelaufen, bitte loggen Sie sich erneut ein.` }],
          'UNAUTHORIZED'
        );
      } else {
        showErrorNotification(`Netzwerk Fehler${statusCodeMessage}`, [
          { type: `${networkError.name}`, message: `${networkError.message} ${detailedMessage}` },
        ]);
      }
    }
  });

const getValidationErrorsIfAny = (graphqlError: GraphQLError) => {
  const extensions: any = graphqlError?.extensions ?? [];
  if (isValidationError(extensions)) {
    return extensions.response.body.errorList;
  }
  return [];
};

const isValidationError = (extensions: { [p: string]: any }) =>
  Object.prototype.hasOwnProperty.call(extensions, 'response') &&
  Object.prototype.hasOwnProperty.call(extensions.response, 'body') &&
  Object.prototype.hasOwnProperty.call(extensions.response.body, 'errorList');

const isUnauthorizedError = (networkError: unknown) =>
  // @ts-ignore
  Object.prototype.hasOwnProperty.call(networkError, 'statusCode') && networkError.statusCode === HTTP_STATUS_CODE.UNAUTHORIZED;
export default errorHandlerLink;
