import { FC } from 'react';
import { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { ReadOutlined, SolutionOutlined, TruckOutlined } from '@ant-design/icons';
import {
  AbstractAuftragVerbuchenGenerierlaufEntryAuftragDetails,
  AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute,
} from '../../../../../../types';
import { generatePathToVorschreibungDetailsPage } from '../../../../../Vorschreibung/vorschreibungUriPaths';
import ActionDropdown from '../../../../../../components/Dropdown/ActionDropdown';
import { generatePathToSingleInvoiceDetailsPage } from '../../../../../IncomingInvoice/incomingInvoiceUriPaths';
import { generatePathToBookingDetailsPage } from '../../../../../BookingDetails/bookingDetailsPaths';

type Props = {
  record: AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute;
};

const EntryDetailsTableActions: FC<Props> = ({ record }) => {
  let items: MenuProps['items'];
  if (isAuftragDetailsType(record)) {
    items = [
      {
        key: '1',
        label: (
          <Link
            to={generatePathToVorschreibungDetailsPage((record as AbstractAuftragVerbuchenGenerierlaufEntryAuftragDetails).auftragId)}
            target="_blank"
          >
            Vorschreibung ansehen
          </Link>
        ),
        icon: <SolutionOutlined />,
      },
      record.buchungIdList.length > 0
        ? {
            key: '2',
            label: (
              <Link to={generatePathToBookingDetailsPage(record.buchungIdList)} target="_blank">
                Buchungsdetails ansehen
              </Link>
            ),
            icon: <ReadOutlined />,
          }
        : null,
    ];
  } else {
    items = [
      {
        key: '1',
        label: (
          <Link to={generatePathToSingleInvoiceDetailsPage(record.belegFileId, record.belegId)} target="_blank">
            Eingangsrechnung ansehen
          </Link>
        ),
        icon: <TruckOutlined />,
      },
      record.buchungIdList.length > 0
        ? {
            key: '2',
            label: (
              <Link to={generatePathToBookingDetailsPage(record.buchungIdList)} target="_blank">
                Buchungsdetails ansehen
              </Link>
            ),
            icon: <ReadOutlined />,
          }
        : null,
    ];
  }

  return <ActionDropdown items={items} />;
};

const isAuftragDetailsType = (record: AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute) =>
  Object.prototype.hasOwnProperty.call(record, 'auftragId');

export default EntryDetailsTableActions;
