import * as Yup from 'yup';
import { spracheValidationSchema } from '../../Sprache/spracheValidationSchema';
import { salutationValidationSchema } from '../../../../shared/components/Person/personFormValidationSchema';
import { firmennameFormPartValidationSchema } from '../../../../shared/components/Firmenname/form/firmennameFormPartValidationSchema';
import { legalFormSelectValidationSchema } from '../../../../shared/components/LegalFormSelect/legalFormSelectValidationSchema';
import { companyRegistrationNumberFormPartValidationSchema } from '../../../../shared/components/CompanyRegistrationNumber/form/companyRegistrationNumberFormPartValidationSchema';
import { kurzBezeichnungFormPartValidationSchema } from '../../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';
import { streetAddressValidationSchema } from '../../../../shared/components/Address/form/addressFieldsValidationSchema';

export const firmaCreateFormValidationSchema = Yup.object()
  .shape({
    sprache: spracheValidationSchema,
    salutation: salutationValidationSchema,
    hauptAddress: streetAddressValidationSchema,
  })
  // @ts-ignore
  .concat(firmennameFormPartValidationSchema)
  // @ts-ignore
  .concat(kurzBezeichnungFormPartValidationSchema)
  // @ts-ignore
  .concat(legalFormSelectValidationSchema)
  // @ts-ignore
  .concat(companyRegistrationNumberFormPartValidationSchema);

export const firmaUpdateFormValidationSchema = Yup.object()
  .shape({
    sprache: spracheValidationSchema,
    salutation: salutationValidationSchema,
  })
  // @ts-ignore
  .concat(firmennameFormPartValidationSchema)
  // @ts-ignore
  .concat(kurzBezeichnungFormPartValidationSchema)
  // @ts-ignore
  .concat(legalFormSelectValidationSchema)
  // @ts-ignore
  .concat(companyRegistrationNumberFormPartValidationSchema);
