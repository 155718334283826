import React, { FC } from 'react';
import Fetcher from '../../../../helpers/fetcher';
import { processAndReturnError } from '../../../../helpers/APIUtils';
import { downloadURL } from '../../../../helpers/fileHelper';
import { showSuccessMsgOther, showWarningMessage } from '../../../../components/message';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  dataCarrierId: string;
  firmendatenId: string;
  xmlFilename: string;
};

const XmlDataCarrierTableColumn: FC<Props> = ({ dataCarrierId, xmlFilename, firmendatenId }) => {
  const onClick = () => {
    downloadDataCarrierXml(firmendatenId, dataCarrierId)
      .then((res: any) => {
        downloadURL(res.url);
        showSuccessMsgOther(`Xml Datenträger erfolgreich exportiert.`);
      })
      .catch((error: any) => {
        showWarningMessage(error, 'Xml Datenträger wurde nicht exportiert.');
      });
  };

  return (
    <DataWithShortenedText maxTextLength={20} text={xmlFilename}>
      {(shortenedText) => <a onClick={onClick}>{shortenedText}</a>}
    </DataWithShortenedText>
  );
};

export const downloadDataCarrierXml = async (firmendatenId: string, dataCarrierId: string) => {
  try {
    const res = await Fetcher.getInstance().fetch(`/api/firmendaten/${firmendatenId}/data-carrier/${dataCarrierId}/xml`);
    return await res;
  } catch (error: any) {
    return await processAndReturnError(error);
  }
};

export default XmlDataCarrierTableColumn;
