import React, { FC } from 'react';
import { Space } from 'antd';
import DocumentStatusTag from '../../../../components/Status/DocumentStatus/DocumentStatusTag';

import { StandingInvoice } from '../../../../types';
import PaidTag from './PaidTag';

type Props = {
  rechnung: StandingInvoice;
};

const RowStandingInvoiceStatus: FC<Props> = ({ rechnung }) => {
  const { paid: isPaid } = rechnung;

  return <Space size={4}>{isPaid ? <PaidTag /> : <DocumentStatusTag documentStatus={rechnung.status} />}</Space>;
};

export default RowStandingInvoiceStatus;
