import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Vertragsart, VertragsartTuple, Verwaltungsart } from '../../../types';
import { useVertragsartListQuery } from '../../VertragsartSelect/gql/VertragsartQueries.types';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';

type Props = {
  verwaltungsart: Verwaltungsart;
  objektId: string;
  bestandseinheitId: string;
  vertragId?: string;
  onChange?: (vertragsart?: Vertragsart | null, supportsExplicitVertragsEnde?: boolean | null) => void;
} & SelectProps;

const VertragVertragsartFormSelect: FC<Props> = ({ verwaltungsart, objektId, bestandseinheitId, vertragId, onChange, ...props }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();
  // firmendatenId, objektId and bestandseinheitId have to be set to determine which Vertragsarten are allowed in the BE
  const { data, loading } = useVertragsartListQuery({ variables: { firmendatenId, objektId, bestandseinheitId, vertragId } });

  const vertragsartList = data?.getVertragsartList.data ?? [];

  return (
    <Select
      size="small"
      id={props.name}
      loading={loading}
      placeholder="Vertragsart auswählen"
      onChange={(vertragsart) => {
        callOnChange(vertragsartList, vertragsart, onChange);
      }}
      {...props}
    >
      {vertragsartList.map((vertragsart) => {
        return (
          <Select.Option key={vertragsart.value} value={vertragsart.value}>
            {vertragsart.text}
          </Select.Option>
        );
      })}
    </Select>
  );
};

const callOnChange = (
  vertragsartList: VertragsartTuple[],
  value: Vertragsart,
  onChange?: (vertragsart?: Vertragsart | null, supportsExplicitVertragsEnde?: boolean | null) => void
) => {
  const vertragsart = vertragsartList.find((vertragsart) => vertragsart.value === value);
  onChange?.(vertragsart?.value, vertragsart?.supportsExplicitVertragsEnde);
};

export default VertragVertragsartFormSelect;
