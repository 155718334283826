import React, { FC } from 'react';
import { DatePicker } from 'formik-antd';
import { Dayjs } from 'dayjs';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../components/DatePicker/DatePicker_formikAntD';
import { findLatestValidFrom } from './beAufteilungsschluesselWertHelper';
import FormItemWithTooltip from '../../../../../components/Form/FormItemWithTooltip';
import { BeAufteilingsschluesselWert } from '../../../../../types';

type Props = {
  validFromFieldName: string;
  selectedRecord: BeAufteilingsschluesselWert;
  beAufteilungsschluesselWertList: BeAufteilingsschluesselWert[];
};

const BeAufteilungsschluesselWertFormValidFrom: FC<Props> = ({ validFromFieldName, selectedRecord, beAufteilungsschluesselWertList }) => {
  const wertListWithoutSelectedRecord = beAufteilungsschluesselWertList.filter(
    (item) => item.beAufteilungsschluesselWertId !== selectedRecord.beAufteilungsschluesselWertId
  );
  const latestValidFrom = findLatestValidFrom(wertListWithoutSelectedRecord);

  return (
    <FormItemWithTooltip name={validFromFieldName} style={{ width: '100%', marginBottom: '0px' }} hasFeedback>
      <DatePicker
        size="small"
        name={validFromFieldName}
        // TODO EC-7903
        disabledDate={(current) => isCurrentAfterLatest(current as unknown as Dayjs, latestValidFrom)}
        style={{ margin: 0 }}
        format={DATE_DISPLAY_FORMAT_DEFAULT}
      />
    </FormItemWithTooltip>
  );
};

const isCurrentAfterLatest = (current: Dayjs, latest: Dayjs | null) => current && !!latest && current < latest.endOf('day');

export default BeAufteilungsschluesselWertFormValidFrom;
