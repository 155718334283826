import { FormFields } from '../../../helpers/formikHelper';
import { BeInfoFeldType } from '../../../types';

export type BeInfoFeldListingFiltersFormValues = {
  type?: BeInfoFeldType;
};

export const beInfoFeldListingFiltersFormFields: FormFields<BeInfoFeldListingFiltersFormValues> = {
  type: 'type',
};
