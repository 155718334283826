import React, { FC, useState } from 'react';
import { FieldArray, Formik } from 'formik';
import { Form, Switch } from 'formik-antd';
import { Button, Col, Row, Space, Typography } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import FormButtons from '../../../../../components/Button/FormButtons';
import {
  budgetingVPosAdjustAufteilungsschluesselFormFields,
  BudgetingVPosAdjustAufteilungsschluesselFormValues,
  createVertragLevelAufteilungsschluesselFormValues,
  mapBudgetingVPosToFormValues,
  mapFormValuesToBudgetingVPosAufteilungsschluessel,
  vertragLevelAufteilungsschluesselFormValues,
} from './budgetingVPosAdjustAufteilungsschluesselFormMapper';
import { Budgeting, BudgetingVPos, VertragLevel } from '../../../../../types';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import VertragLevelSelect from '../../../../VertragLevelSelect/gql/VertragLevelSelect';
import AufteilungsschluesselSelect from '../../../../AufteilungsschluesselSelect/AufteilungsschluesselSelect';
import { useFDAuthorized } from '../../../../../shared/Auth/useAuthorized';
import { Spacer } from '../../../../../components/Grid';
import { budgetingVPosAdjustAufteilungsschluesselFormValidationSchema } from './budgetingVPosAdjustAufteilungsschluesselFormValidationSchema';
import { useBudgetingAdjustAufteilungsschluesselMutation } from '../../../gql/BudgetingMutations.types';
import { showSuccessMsgOther } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { isNotNull } from '../../../../../helpers/assertionHelper';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';
import FieldHelpIconWithTooltip from '../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  budgeting: Budgeting;
  budgetingVPos: BudgetingVPos;
  onSuccess: () => void;
  onCancel: () => void;
};

const BudgetingVPosAdjustAufteilungsschluesselForm: FC<Props> = ({ budgeting, budgetingVPos, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BudgetingAdjustAufteilungsschluesselActionData'>('BudgetingAdjustAufteilungsschluesselActionData');
  const { activeForFirmendatenId } = useFDAuthorized();

  const [vertragLevelCount, setVertragLevelCount] = useState(0);

  const {
    budgetingId,
    objekt: { objektId },
  } = budgeting;

  const [runAction, { loading }] = useBudgetingAdjustAufteilungsschluesselMutation({
    onCompleted: () => {
      showSuccessMsgOther('Budgetierung Vorschreibungsposition aktualisiert');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<BudgetingVPosAdjustAufteilungsschluesselFormValues>
      initialValues={mapBudgetingVPosToFormValues(budgetingVPos)}
      validationSchema={budgetingVPosAdjustAufteilungsschluesselFormValidationSchema}
      onSubmit={(value, { setSubmitting }) => {
        runAction({ variables: { budgetingId, objektId, input: mapFormValuesToBudgetingVPosAufteilungsschluessel(value) } }).finally(() =>
          setSubmitting(false)
        );
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Pauschale</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                name={budgetingVPosAdjustAufteilungsschluesselFormFields.leaveFlatRate}
                label="Pauschale belassen"
                labelAlign="left"
                fieldHelp={getFieldHelpText('BudgetingAdjustAufteilungsschluesselActionData.leaveFlatRate')}
              >
                <Space>
                  <Switch name={budgetingVPosAdjustAufteilungsschluesselFormFields.leaveFlatRate} size="small" />
                  <Typography.Text>{formikProps.values.leaveFlatRate ? 'Ja' : 'Nein'}</Typography.Text>
                </Space>
              </FormItemWithFieldHelp>
            </Col>
          </Row>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>
                Verknüpfung
                <FieldHelpIconWithTooltip
                  fieldHelp={getFieldHelpText('BudgetingAdjustAufteilungsschluesselActionData.vertragLevelAufteilungsschluesselList')}
                />
              </Typography.Title>
            </Col>
            <Col span={18}>
              <FieldArray
                name={budgetingVPosAdjustAufteilungsschluesselFormFields.vertragLevelAufteilungsschluesselList}
                render={(arrayHelpers) => (
                  <>
                    {!formikProps.values.vertragLevelAufteilungsschluesselList.length && (
                      <>
                        <Spacer />
                        <Button
                          icon={<PlusOutlined />}
                          type="dashed"
                          htmlType="button"
                          block
                          onClick={() => arrayHelpers.push(createVertragLevelAufteilungsschluesselFormValues())}
                        >
                          Neu hinzufügen
                        </Button>
                      </>
                    )}
                    {formikProps.values.vertragLevelAufteilungsschluesselList.map((pos, index) => (
                      <Row gutter={16} key={index}>
                        <Col span={10}>
                          <FormItemWithoutColon
                            name={`${budgetingVPosAdjustAufteilungsschluesselFormFields.vertragLevelAufteilungsschluesselList}[${index}].${vertragLevelAufteilungsschluesselFormValues.vertragLevel}`}
                            label="Vertragsebene"
                            labelAlign="left"
                          >
                            <VertragLevelSelect
                              size="middle"
                              allowClear
                              name={`${budgetingVPosAdjustAufteilungsschluesselFormFields.vertragLevelAufteilungsschluesselList}[${index}].${vertragLevelAufteilungsschluesselFormValues.vertragLevel}`}
                              verwaltungsart={budgeting.objekt.verwaltungsart.value}
                              onOptionsChange={(options) => {
                                setVertragLevelCount(options.length);
                              }}
                              excludeLevels={formikProps.values.vertragLevelAufteilungsschluesselList
                                .map(({ vertragLevel }) => vertragLevel as VertragLevel)
                                .filter(isNotNull)}
                            />
                          </FormItemWithoutColon>
                        </Col>
                        <Col span={10}>
                          <FormItemWithoutColon
                            name={`${budgetingVPosAdjustAufteilungsschluesselFormFields.vertragLevelAufteilungsschluesselList}[${index}].${vertragLevelAufteilungsschluesselFormValues.aufteilungsschluesselId}`}
                            label="Aufteilungsschlüssel"
                            labelAlign="left"
                          >
                            <AufteilungsschluesselSelect
                              size="middle"
                              allowClear={false}
                              name={`${budgetingVPosAdjustAufteilungsschluesselFormFields.vertragLevelAufteilungsschluesselList}[${index}].${vertragLevelAufteilungsschluesselFormValues.aufteilungsschluesselId}`}
                              firmendatenId={activeForFirmendatenId}
                            />
                          </FormItemWithoutColon>
                        </Col>
                        <Col span={4}>
                          <Space style={{ marginTop: 27 }}>
                            <Button
                              size="small"
                              shape="circle"
                              icon={<PlusOutlined />}
                              disabled={
                                vertragLevelCount === formikProps.values.vertragLevelAufteilungsschluesselList.length || vertragLevelCount <= 1
                              }
                              onClick={() => {
                                arrayHelpers.insert(index + 1, createVertragLevelAufteilungsschluesselFormValues());
                              }}
                            />
                            <Button
                              size="small"
                              shape="circle"
                              icon={<MinusOutlined />}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          </Space>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              />
            </Col>
          </Row>
          <FormButtons updateMode onCancel={onCancel} isOkLoading={loading} />
        </Form>
      )}
    </Formik>
  );
};
export default BudgetingVPosAdjustAufteilungsschluesselForm;
