import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  mapFormValuesToVorschreibungErstellenVerarbeitungFilters,
  VorschreibungErstellenVerarbeitungEntryFiltersFormValues,
  vorschreibungErstellenVerarbEntryListingFiltersFormInitialValues,
} from './Filters/ListingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import VorschreibungErstellenVerarbeitungEntryTable from './VorschreibungErstellenVerarbeitungEntryTable';
import { useQueryParams } from '../../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { verarbeitungTypes } from '../../../verarbeitungHelpers';
import { TVorschreibungErstellenVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { AuftragGenerierlauf } from '../../../../../types';

type Props = {
  generierlauf: AuftragGenerierlauf;
};

const VorschreibungErstellenVerarbeitungEntryListing: FC<Props> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TVorschreibungErstellenVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.VorschreibungErstellen, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: VorschreibungErstellenVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.VorschreibungErstellen,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToVorschreibungErstellenVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<VorschreibungErstellenVerarbeitungEntryFiltersFormValues>
      initialValues={vorschreibungErstellenVerarbEntryListingFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />
          <VorschreibungErstellenVerarbeitungEntryTable generierlauf={generierlauf} queryParams={queryParams} />
        </>
      )}
    </Formik>
  );
};

export default VorschreibungErstellenVerarbeitungEntryListing;
