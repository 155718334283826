import React, { FC, useState } from 'react';
import { Select, Tag } from 'antd';
import {
  addHauptToTagsIfNeccessary,
  containsHauptTag,
  isTagHauptTag,
  removeHauptTagDuplicatesFromTags,
  getTagColor,
} from '../../../../components/Tag/tagHelpers';
import { capitalizeString } from '../../../../helpers/stringHelper';

type Props = {
  tags: string[];
  loading: boolean;
  canBeHauptKonto: boolean;
  setShouldBeHaupt: React.Dispatch<React.SetStateAction<boolean>>;
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
};
const KontoBezugSelect: FC<Props> = ({ tags, loading, canBeHauptKonto, setShouldBeHaupt, setTags }) => {
  const initialTagsForOptions = addHauptToTagsIfNeccessary(canBeHauptKonto && !containsHauptTag(tags), tags).filter(
    (tag) => isTagHauptTag(tag) || !isTagHauptTag(capitalizeString(tag))
  );
  const [tagsForOptions, setTagsForOptions] = useState<string[]>(initialTagsForOptions);

  const tagRender = (props: { label: React.ReactNode; value: any; onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void }) => {
    const { label, value, onClose } = props;

    const onPreventMouseDown = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
      event.preventDefault();
      event.stopPropagation();
    };

    if (!canBeHauptKonto) {
      return isTagHauptTag(capitalizeString(value)) ? (
        <></>
      ) : (
        <Tag
          onMouseDown={onPreventMouseDown}
          closable
          onClose={onClose}
          style={{
            marginRight: 3,
          }}
        >
          {label}
        </Tag>
      );
    }

    if (value && containsHauptTag(tagsForOptions) && !isTagHauptTag(value) && isTagHauptTag(capitalizeString(value))) {
      return <></>;
    }

    return (
      <Tag
        color={getTagColor(label)}
        onMouseDown={onPreventMouseDown}
        closable
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {isTagHauptTag(capitalizeString(label as string)) ? capitalizeString(label as string) : label}
      </Tag>
    );
  };

  const handleSelectionChange = (tagList: string[]) => {
    const tagsWithoutHauptTagDuplicates = removeHauptTagDuplicatesFromTags(tagList, canBeHauptKonto);
    containsHauptTag(tagsWithoutHauptTagDuplicates) ? setShouldBeHaupt(true) : setShouldBeHaupt(false);
    setTags(tagsWithoutHauptTagDuplicates);
    const newTagsForOptions = addHauptToTagsIfNeccessary(
      canBeHauptKonto && !containsHauptTag(tagsWithoutHauptTagDuplicates),
      tagsWithoutHauptTagDuplicates
    );
    setTagsForOptions(newTagsForOptions);
  };

  return (
    <Select
      id="bezug"
      mode="tags"
      defaultValue={tags}
      tagRender={tagRender}
      loading={loading}
      value={tags}
      style={{ minWidth: '300px' }}
      onChange={handleSelectionChange}
    >
      {tagsForOptions.map((bezug) => (
        <Select.Option key={bezug} value={bezug} label={bezug}>
          {bezug}
        </Select.Option>
      ))}
    </Select>
  );
};

export default KontoBezugSelect;
