import React, { FC } from 'react';
import { Tag } from 'antd';
import { getTagColor, HAUPT_TAG_TEXT } from '../../../../../../components/Tag/tagHelpers';

const HauptTag: FC = () => {
  const onPreventMouseDown = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={getTagColor(HAUPT_TAG_TEXT)}
      onMouseDown={onPreventMouseDown}
      style={{
        margin: 2,
      }}
    >
      HAUPT
    </Tag>
  );
};
export default HauptTag;
