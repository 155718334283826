import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import { useObjektListQuery } from '../Objekt/gql/ObjektQueries.types';
import { selectFilterOption } from '../../helpers/selectHelper';
import DataWithShortenedText from '../../components/Helpers/DataWithShortenedText';
import { ObjektListEntry } from '../../types';

/**
 * <h2>Usage</h2>
 * As listing filter or in Create/Update form where a Object has to be chosen from a dropdown list.
 *
 */

type Props = {
  rechnungsAusstellerId?: string | null;
  maxTextLength?: number;
  width?: string;
  onListChange?: (objektList: ObjektListEntry[]) => void;
  includeArchiviert?: boolean;
} & SelectProps;

const ObjektSelect: FC<Props> = ({ rechnungsAusstellerId, maxTextLength, width, includeArchiviert, onListChange, prefix, size, ...restProps }) => {
  const { data, loading } = useObjektListQuery({
    variables: {
      rechnungsAusstellerId,
      includeArchiviert,
    },
    onCompleted: (data) => {
      onListChange?.(data.getObjektList.data.contentList);
    },
  });
  const objektList = data?.getObjektList.data.contentList || [];

  return (
    <Select
      id={restProps.name}
      loading={loading}
      placeholder="Objekt auswählen"
      showSearch
      filterOption={selectFilterOption}
      allowClear
      style={{ width: width || '100%' }}
      size={size ?? 'small'}
      prefix={prefix}
      {...restProps}
    >
      {objektList.map((objekt) => (
        <Select.Option key={objekt.objektId} value={objekt.objektId}>
          {maxTextLength ? (
            <DataWithShortenedText maxTextLength={maxTextLength} text={objekt.kurzBezeichnung} />
          ) : (
            <Typography.Text>{objekt.kurzBezeichnung}</Typography.Text>
          )}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ObjektSelect;
