import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { UstKategorieFieldsFragmentDoc, UstKategorieEntryFieldsFragmentDoc } from './UstKategorieFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstKategorieListQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type UstKategorieListQuery = {
  getUstKategorieListByKundenSystemId: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      ustKategorieId: string;
      ustVomAufwand?: number | null;
      vstKuerzung?: boolean | null;
      status?: { text: string; value: Types.UstKategorieStatus } | null;
      ustKategorieEntryList: Array<{
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstKategorieQueryVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type UstKategorieQuery = {
  getUstKategorie: {
    data: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      ustKategorieId: string;
      ustVomAufwand?: number | null;
      vstKuerzung?: boolean | null;
      status?: { text: string; value: Types.UstKategorieStatus } | null;
      ustKategorieEntryList: Array<{
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstKategorieEntryInitialListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UstKategorieEntryInitialListQuery = {
  getUstKategorieEntryInitialList: {
    data: Array<{
      additionKonto: number;
      buchungsKZ?: string | null;
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      rechnungskennzeichen: string;
      steuersatz: number;
      ustKategorieEntryId?: string | null;
      ustKz: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstKategorieChangeHistoryListQueryVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
}>;

export type UstKategorieChangeHistoryListQuery = {
  getUstKategorieChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const UstKategorieListDocument = gql`
  query UstKategorieList($kundenSystemId: ID!, $withDetails: Boolean, $includeArchiviert: Boolean) {
    getUstKategorieListByKundenSystemId(kundenSystemId: $kundenSystemId, withDetails: $withDetails, includeArchiviert: $includeArchiviert) {
      data {
        ...UstKategorieFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieFieldsFragmentDoc}
`;
export function useUstKategorieListQuery(
  baseOptions: Apollo.QueryHookOptions<UstKategorieListQuery, UstKategorieListQueryVariables> &
    ({ variables: UstKategorieListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieListQuery, UstKategorieListQueryVariables>(UstKategorieListDocument, options);
}
export function useUstKategorieListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieListQuery, UstKategorieListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieListQuery, UstKategorieListQueryVariables>(UstKategorieListDocument, options);
}
export function useUstKategorieListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstKategorieListQuery, UstKategorieListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieListQuery, UstKategorieListQueryVariables>(UstKategorieListDocument, options);
}
export type UstKategorieListQueryHookResult = ReturnType<typeof useUstKategorieListQuery>;
export type UstKategorieListLazyQueryHookResult = ReturnType<typeof useUstKategorieListLazyQuery>;
export type UstKategorieListSuspenseQueryHookResult = ReturnType<typeof useUstKategorieListSuspenseQuery>;
export type UstKategorieListQueryResult = Apollo.QueryResult<UstKategorieListQuery, UstKategorieListQueryVariables>;
export const UstKategorieDocument = gql`
  query UstKategorie($ustKategorieId: ID!, $withDetails: Boolean) {
    getUstKategorie(ustKategorieId: $ustKategorieId, withDetails: $withDetails) {
      data {
        ...UstKategorieFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieFieldsFragmentDoc}
`;
export function useUstKategorieQuery(
  baseOptions: Apollo.QueryHookOptions<UstKategorieQuery, UstKategorieQueryVariables> &
    ({ variables: UstKategorieQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieQuery, UstKategorieQueryVariables>(UstKategorieDocument, options);
}
export function useUstKategorieLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieQuery, UstKategorieQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieQuery, UstKategorieQueryVariables>(UstKategorieDocument, options);
}
export function useUstKategorieSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstKategorieQuery, UstKategorieQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieQuery, UstKategorieQueryVariables>(UstKategorieDocument, options);
}
export type UstKategorieQueryHookResult = ReturnType<typeof useUstKategorieQuery>;
export type UstKategorieLazyQueryHookResult = ReturnType<typeof useUstKategorieLazyQuery>;
export type UstKategorieSuspenseQueryHookResult = ReturnType<typeof useUstKategorieSuspenseQuery>;
export type UstKategorieQueryResult = Apollo.QueryResult<UstKategorieQuery, UstKategorieQueryVariables>;
export const UstKategorieEntryInitialListDocument = gql`
  query UstKategorieEntryInitialList {
    getUstKategorieEntryInitialList {
      data {
        ...UstKategorieEntryFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieEntryFieldsFragmentDoc}
`;
export function useUstKategorieEntryInitialListQuery(
  baseOptions?: Apollo.QueryHookOptions<UstKategorieEntryInitialListQuery, UstKategorieEntryInitialListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieEntryInitialListQuery, UstKategorieEntryInitialListQueryVariables>(
    UstKategorieEntryInitialListDocument,
    options
  );
}
export function useUstKategorieEntryInitialListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieEntryInitialListQuery, UstKategorieEntryInitialListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieEntryInitialListQuery, UstKategorieEntryInitialListQueryVariables>(
    UstKategorieEntryInitialListDocument,
    options
  );
}
export function useUstKategorieEntryInitialListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstKategorieEntryInitialListQuery, UstKategorieEntryInitialListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieEntryInitialListQuery, UstKategorieEntryInitialListQueryVariables>(
    UstKategorieEntryInitialListDocument,
    options
  );
}
export type UstKategorieEntryInitialListQueryHookResult = ReturnType<typeof useUstKategorieEntryInitialListQuery>;
export type UstKategorieEntryInitialListLazyQueryHookResult = ReturnType<typeof useUstKategorieEntryInitialListLazyQuery>;
export type UstKategorieEntryInitialListSuspenseQueryHookResult = ReturnType<typeof useUstKategorieEntryInitialListSuspenseQuery>;
export type UstKategorieEntryInitialListQueryResult = Apollo.QueryResult<
  UstKategorieEntryInitialListQuery,
  UstKategorieEntryInitialListQueryVariables
>;
export const UstKategorieChangeHistoryListDocument = gql`
  query UstKategorieChangeHistoryList($ustKategorieId: ID!) {
    getUstKategorieChangeHistoryList(ustKategorieId: $ustKategorieId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useUstKategorieChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<UstKategorieChangeHistoryListQuery, UstKategorieChangeHistoryListQueryVariables> &
    ({ variables: UstKategorieChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieChangeHistoryListQuery, UstKategorieChangeHistoryListQueryVariables>(
    UstKategorieChangeHistoryListDocument,
    options
  );
}
export function useUstKategorieChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieChangeHistoryListQuery, UstKategorieChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieChangeHistoryListQuery, UstKategorieChangeHistoryListQueryVariables>(
    UstKategorieChangeHistoryListDocument,
    options
  );
}
export function useUstKategorieChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstKategorieChangeHistoryListQuery, UstKategorieChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieChangeHistoryListQuery, UstKategorieChangeHistoryListQueryVariables>(
    UstKategorieChangeHistoryListDocument,
    options
  );
}
export type UstKategorieChangeHistoryListQueryHookResult = ReturnType<typeof useUstKategorieChangeHistoryListQuery>;
export type UstKategorieChangeHistoryListLazyQueryHookResult = ReturnType<typeof useUstKategorieChangeHistoryListLazyQuery>;
export type UstKategorieChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useUstKategorieChangeHistoryListSuspenseQuery>;
export type UstKategorieChangeHistoryListQueryResult = Apollo.QueryResult<
  UstKategorieChangeHistoryListQuery,
  UstKategorieChangeHistoryListQueryVariables
>;
