import {
  mapAddressToFormValues,
  mapFormValuesToAddress,
  STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT,
} from '../../../shared/components/Address/form/addressMapper';
import { AddressSharedModel, PostofficeBoxAddressFormValues, StreetAddressFormValues } from '../../../shared/components/Address/AddressSharedModel';

export const addressListFormPartInitialValues = (initiallyOpen: boolean) =>
  initiallyOpen
    ? {
        addressList: [],
        tempShowAddressFormPartItem: true,
        tempAddress: STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT,
      }
    : {
        addressList: [],
        tempShowAddressFormPartItem: false,
      };

export const mapAddressListToFormValues = (addressList: AddressSharedModel[] = [], initiallyOpen = false) => ({
  addressList: addressList.map(mapAddressToFormValues),
  tempShowAddressFormPartItem: initiallyOpen,
});

export const mapFormValuesToAddressList = (addressList: Array<StreetAddressFormValues | PostofficeBoxAddressFormValues> = []) =>
  addressList.map((address) => mapFormValuesToAddress(address));
