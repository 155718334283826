import React, { FC } from 'react';
import { Button, Divider, Drawer, Dropdown, MenuProps, Modal, Typography } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import AddressAndContactList from '../../../../features/Rechtstraeger/AddressesAndContacts/List/AddressAndContactList';
import { StyledOperatingSiteSpace } from './styled/OperatingSite.style';
import AddressesAndContactsCreateForm from '../../../../features/Rechtstraeger/AddressesAndContacts/Form/AddressesAndContactsCreateForm';
import { OperatingSite, RechtstraegerType } from '../../../../types';
import { useToggle } from '../../../../hooks/useToggle';
import ContactPersonForm from '../../../../features/Rechtstraeger/AddressesAndContacts/Form/ContactPerson/ContactPersonForm';
import { showSuccessMsgDelete } from '../../../../components/message';
import OperatingSiteModal from '../OperatingSiteModal';
import { CLASS_OPERATING_SITE_LIST } from '../../../../features/Rechtstraeger/AddressesAndContacts/List/addressAndContactListHelpers';
import HistoryModal, { HistoryType } from '../../../../features/History/shared/HistoryModal';
import FirmaOperatingSiteChangeHistoryListingTable from '../../../../features/Rechtstraeger/Firma/OperatingSite/FirmaOperatingSiteChangeHistoryListingTable';
import { useDeleteFirmendatenOperatingSiteMutation } from '../../../../features/KundenSystem/Firmendaten/gql/FirmendatenOperatingSiteMutations.types';
import { useDeleteFirmaOperatingSiteMutation } from '../../../../features/Rechtstraeger/Firma/gql/FirmaMutations.types';

export type TOperatingSiteList = OperatingSite;

type Props = {
  operatingSiteList: TOperatingSiteList[];
  operatingSite: TOperatingSiteList;
  onSuccess: () => void;
  firmendatenId: string;
  firmaId?: string;
};

const OperatingSiteList: FC<Props> = ({ operatingSiteList, operatingSite, firmendatenId, firmaId, onSuccess }) => {
  const [isAddressContactInfoDrawerOpen, toggleAddressContactInfoDrawerOpen] = useToggle();
  const [isContactPersonDrawerOpen, toggleContactPersonDrawerOpen] = useToggle();
  const [isBsUpdateModalOpen, toggleBsUpdateModalOpen] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const bsEntity = 'Betriebsstätte';

  const onCreateAdresseContactInfoSuccess = () => {
    onSuccess();
    toggleAddressContactInfoDrawerOpen();
  };

  const onCreateContactPersonSuccess = () => {
    onSuccess();
    toggleContactPersonDrawerOpen();
  };

  const [runDeleteFirmaBs] = useDeleteFirmaOperatingSiteMutation();
  const [runDeleteFirmendatenBs] = useDeleteFirmendatenOperatingSiteMutation();

  const onDelete = () => {
    if (firmaId) {
      runDeleteFirmaBs({
        variables: {
          firmaId,
          operatingSiteId: operatingSite.operatingSiteId,
        },
      }).then(() => {
        showSuccessMsgDelete(bsEntity);
        onSuccess();
      });
    } else if (firmendatenId) {
      runDeleteFirmendatenBs({
        variables: {
          firmendatenId,
          operatingSiteId: operatingSite.operatingSiteId,
        },
      }).then(() => {
        showSuccessMsgDelete(bsEntity);
        onSuccess();
      });
    }
  };

  const items: MenuProps['items'] = [
    operatingSite.bezeichnung !== 'Firmensitz' ? { key: '1', label: 'Betriebsstätte bearbeiten', onClick: () => toggleBsUpdateModalOpen() } : null,
    operatingSite.bezeichnung !== 'Firmensitz' && firmaId
      ? {
          key: '3',
          label: 'Betriebsstätte Änderungsverlauf',
          onClick: toggleChangeHistoryOpen,
        }
      : null,
    operatingSite.bezeichnung !== 'Firmensitz'
      ? { key: '4', label: 'Betriebsstätte löschen', onClick: () => showConfirmDelete(onDelete), danger: true }
      : null,

    { key: '5', label: 'Adresse/Kontakt anlegen', onClick: () => toggleAddressContactInfoDrawerOpen() },
    { key: '6', label: 'Ansprechpartner anlegen', onClick: () => toggleContactPersonDrawerOpen() },
  ];

  return (
    <>
      <StyledOperatingSiteSpace direction="vertical" className={CLASS_OPERATING_SITE_LIST}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: '100%',
          }}
        >
          <Typography.Title level={5} style={{ whiteSpace: 'nowrap' }}>
            {operatingSite.bezeichnung}
          </Typography.Title>
          <Divider style={{ minWidth: 'auto', margin: '0 5px' }} />
          <Dropdown menu={{ items }}>
            <Button data-testid="card-menu-dropdown" icon={<EllipsisOutlined />} shape="circle" size="small" />
          </Dropdown>
        </div>

        <AddressAndContactList
          addressesAndContacts={operatingSite.addressesAndContacts}
          operatingSiteId={operatingSite.operatingSiteId}
          firmendatenId={firmendatenId}
          rechtstraegerId={firmaId}
          onSuccess={onSuccess}
          operatingSiteList={operatingSiteList}
        />
      </StyledOperatingSiteSpace>

      <OperatingSiteModal
        firmaId={firmaId}
        firmendatenId={firmendatenId}
        operatingSite={operatingSite}
        isModalOpen={isBsUpdateModalOpen}
        toggleModalOpen={toggleBsUpdateModalOpen}
        onSuccess={onSuccess}
      />

      <Drawer
        title="Adresse oder Kontakt anlegen"
        width={720}
        open={isAddressContactInfoDrawerOpen}
        onClose={toggleAddressContactInfoDrawerOpen}
        destroyOnClose
      >
        <AddressesAndContactsCreateForm
          rechtstraegerId={firmaId}
          onSuccess={onCreateAdresseContactInfoSuccess}
          onCancel={toggleAddressContactInfoDrawerOpen}
          rechtstraegerType={RechtstraegerType.Firma}
          operatingSiteId={operatingSite.operatingSiteId}
        />
      </Drawer>

      <Drawer title="Ansprechpartner anlegen" width={720} open={isContactPersonDrawerOpen} onClose={toggleContactPersonDrawerOpen} destroyOnClose>
        <ContactPersonForm
          onCancel={toggleContactPersonDrawerOpen}
          onSuccess={onCreateContactPersonSuccess}
          firmaId={firmaId}
          firmendatenId={firmendatenId}
          operatingSiteId={operatingSite.operatingSiteId}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        {firmaId && <FirmaOperatingSiteChangeHistoryListingTable firmaId={firmaId as string} operatingSiteId={operatingSite.operatingSiteId} />}
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void) => {
  Modal.confirm({
    title: 'Sind Sie sicher das Sie diese Betriebsstätte löschen möchten?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default OperatingSiteList;
