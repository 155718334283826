import React, { FC, PropsWithChildren } from 'react';
import { Table } from 'antd';
import { isColumnSorted, SortOrder } from './sortingHelpers';

type TableSummaryCellContentWithSortingProps = {
  index: number;
  colSpan: number;
  align?: 'left' | 'center' | 'right';
  sortedColumnIndex: number;
  sortOrder: SortOrder;
};

const TableSummaryCellContentWithSorting: FC<TableSummaryCellContentWithSortingProps & PropsWithChildren> = ({
  index,
  colSpan,
  align = 'left',
  sortedColumnIndex,
  sortOrder,
  children,
}) => (
  <Table.Summary.Cell
    index={index}
    colSpan={colSpan}
    className={`${isColumnSorted(index, sortedColumnIndex, sortOrder) ? 'ant-table-column-sort' : ''}`}
    align={align}
  >
    {children}
  </Table.Summary.Cell>
);

export default TableSummaryCellContentWithSorting;
