import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { MailStatus, MailUseCase, QueryGetMailListArgs } from '../../../../types';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';
import { TEmailDeliveryListingQueryParams } from './filtersQueryParams';

export type FiltersFormValues = {
  createTsFromInclusive?: string | null;
  createTsToInclusive?: string | null;
  createdByMitarbeiterIdList?: string[] | null;
  recipient?: string | null;
  recipientRechtstraegerId?: string | null;
  statusList?: MailStatus[] | null;
  useCaseList?: MailUseCase[] | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  createTsFromInclusive: 'createTsFromInclusive',
  createTsToInclusive: 'createTsToInclusive',
  createdByMitarbeiterIdList: 'createdByMitarbeiterIdList',
  recipient: 'recipient',
  recipientRechtstraegerId: 'recipientRechtstraegerId',
  statusList: 'statusList',
  useCaseList: 'useCaseList',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TEmailDeliveryListingQueryParams => ({
  createTsFromInclusive: formValues.createTsFromInclusive ? mapFormDateValueToDateString(formValues.createTsFromInclusive) : null,
  createTsToInclusive: formValues.createTsToInclusive ? mapFormDateValueToDateString(formValues.createTsToInclusive) : null,
  createdByMitarbeiterIdList: formValues.createdByMitarbeiterIdList,
  recipient: formValues.recipient,
  recipientRechtstraegerId: formValues.recipientRechtstraegerId,
  statusList: formValues.statusList,
  useCaseList: formValues.useCaseList,
});

export const mapQueryParamsToFormValues = (queryParams: TEmailDeliveryListingQueryParams): FiltersFormValues => ({
  createTsFromInclusive: queryParams.createTsFromInclusive,
  createTsToInclusive: queryParams.createTsToInclusive,
  createdByMitarbeiterIdList: queryParams.createdByMitarbeiterIdList,
  recipient: queryParams.recipient,
  recipientRechtstraegerId: queryParams.recipientRechtstraegerId,
  statusList: queryParams.statusList,
  useCaseList: queryParams.useCaseList,
});

export const mapQueryParamsToMailListQueryVariables = (
  firmendatenId: string,
  queryParams: TEmailDeliveryListingQueryParams
): QueryGetMailListArgs => ({
  ...queryParams,
  firmendatenId,
  recipient: queryParams.recipient?.toString(),
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'createTsToInclusive'>> = {
  [filtersFormFields.createTsFromInclusive]: 'Erstelldatum',
  [filtersFormFields.createdByMitarbeiterIdList]: 'Ersteller',
  [filtersFormFields.recipientRechtstraegerId]: 'Empfänger',
  [filtersFormFields.recipient]: 'Empfänger Email',
  [filtersFormFields.statusList]: 'Status',
  [filtersFormFields.useCaseList]: 'Anwendungsfall',
};
