import { message } from 'antd';
import Fetcher from '../../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../../helpers/APIUtils';
import { showSuccessMsgOther } from '../../../../components/message';
import { mapErrorToErrorMessage } from '../../../../helpers/errorAndWarningHelper';
import { OnlineBankingFormValues } from './OnlineBankingForm/bankstatementOnlineBankingFormMapper';
import { PaymentTransactionType } from '../../../../types';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';

export const IMPORT_BANKSTATEMENT_EBICS_START_TYPE = 'importBankstatementEbicsStart';
export const IMPORT_BANKSTATEMENT_EBICS_END_TYPE = 'importBankstatementEbicsEnd';

export const handleImportBankstatementEbicsSubmit = (firmendatenId: string, onSuccess: () => void) => {
  const formData = new FormData();
  formData.append(
    'request',
    new Blob(
      [
        JSON.stringify({
          data: {
            paymentTransactionType: PaymentTransactionType.Ebics,
          },
        }),
      ],
      { type: 'application/json' }
    )
  );

  Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/camt-generierlauf`, {
      method: 'POST',
      body: formData,
    })
    .then(processResponse)
    .then((response: any) => {
      // @ts-ignore
      if (response.data.data) {
        showSuccessMsgOther(`EBICS Kontoauszüge wurden erfolgreich importiert.`);
        onSuccess();
        // @ts-ignore
      } else if (response && response.data.warningList.length > 0) {
        message.warning(`${response.data.warningList[0].message}`);
      }
    })
    .catch(processAndReturnError)
    .catch((error) => {
      return message.error({
        content: `EBICS Kontoauszüge konnte nicht importiert werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};

export const handleImportBankstatementOnlineBankingSubmit = (firmendatenId: string, values: OnlineBankingFormValues, closeModal: () => void) => {
  const formData = new FormData();
  formData.append(
    'request',
    new Blob(
      [
        JSON.stringify({
          data: {
            inputFileId: values.tempInputFileId,
            paymentTransactionType: PaymentTransactionType.OnlineBanking,
          },
        }),
      ],
      { type: 'application/json' }
    )
  );

  if (values.inputFile && !values.tempInputFileId) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append('inputFile', values.inputFile.file!, values.inputFile.filename);
  }

  const createTs = dayjsCustom().format();
  if (values.inputFile && values.inputFile.file) {
    const fileSizeInMB = values.inputFile.file.size / 1024 / 1024;
    if (fileSizeInMB > 20) {
      document.dispatchEvent(new CustomEvent(IMPORT_BANKSTATEMENT_EBICS_START_TYPE, { detail: { createTs, hasFinished: false } }));
    }
  }

  closeModal();
  Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/camt-generierlauf`, {
      method: 'POST',
      body: formData,
    })
    .then(processResponse)
    .then(() => {
      document.dispatchEvent(new CustomEvent(IMPORT_BANKSTATEMENT_EBICS_END_TYPE, { detail: { createTs, hasFinished: true } }));
      showSuccessMsgOther(`Kontoauszug Onlinebanking wurde erfolgreich importiert.`);
    })
    .catch(processAndReturnError)
    .catch((error) => {
      return message.error({
        content: `Kontoauszug Onlinebanking konnte nicht importiert werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};
