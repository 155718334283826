import { FormikProps } from 'formik';
import { FibuBuchungszeile } from '../../../../../../../types';
import { FiltersFormValues } from './buchungszeileListingFiltersFormMapper';
import { dayjsCustom } from '../../../../../../../helpers/dayjsCustom';
import { isFibuBuchungBelegSymbolStorno } from '../../../../shared/bookingHelpers';

export const filterForStorno = (buchungszeile: FibuBuchungszeile, hasOnlyOneBuchung: boolean, showStorno: boolean) => {
  if (hasOnlyOneBuchung || showStorno) {
    return buchungszeile;
  }
  return !(buchungszeile.storniert || isFibuBuchungBelegSymbolStorno(buchungszeile.buchungsKopf.belegSymbol.value));
};

export const filterBuchungszeileList = (
  buchungszeileList: FibuBuchungszeile[],
  formikProps: FormikProps<FiltersFormValues>,
  hasOnlyOneBuchung: boolean,
  showStorno: boolean,
  showUmbuchungen: boolean
) => {
  const filterForUmbuchungen = (buchungszeile: FibuBuchungszeile) => (!showUmbuchungen ? !buchungszeile.umbuchung : buchungszeile);

  const filterForBuchungskreisId = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.buchungkreisId ? buchungszeile.buchungskreisId === formikProps.values.buchungkreisId : buchungszeile;

  const filterForBelegSymbol = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.belegsymbol ? buchungszeile.buchungsKopf.belegSymbol.value === formikProps.values.belegsymbol : buchungszeile;

  const filterForBelegnummer = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.belegnummer ? buchungszeile.buchungsKopf.belegnummer === formikProps.values.belegnummer : buchungszeile;

  const filterForBelegdatumVon = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.belegdatumVon
      ? dayjsCustom(formikProps.values.belegdatumVon).isBefore(dayjsCustom(buchungszeile.buchungsKopf.belegDatum))
      : buchungszeile;

  const filterForBelegdatumBis = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.belegdatumBis
      ? dayjsCustom(formikProps.values.belegdatumBis).isAfter(dayjsCustom(buchungszeile.buchungsKopf.belegDatum))
      : buchungszeile;

  const filterForBuchungsdatumVon = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.buchungsdatumVon
      ? dayjsCustom(formikProps.values.buchungsdatumVon).isBefore(dayjsCustom(buchungszeile.buchungsKopf.buchungsdatum))
      : buchungszeile;

  const filterForBuchungsdatumBis = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.buchungsdatumBis
      ? dayjsCustom(formikProps.values.buchungsdatumBis).isAfter(dayjsCustom(buchungszeile.buchungsKopf.buchungsdatum))
      : buchungszeile;

  const filterForBuchungsKZ = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.buchungskennzeichen ? buchungszeile.buchungsKZ === formikProps.values.buchungskennzeichen : buchungszeile;

  const filterForObjekt = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.objektId ? buchungszeile.objekt?.objektId === formikProps.values.objektId : buchungszeile;

  const filterForBestandseinheit = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.bestandseinheitId ? buchungszeile.bestandseinheit?.bestandseinheitId === formikProps.values.bestandseinheitId : buchungszeile;

  const filterForVertrag = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.vertragId ? buchungszeile.vertragId === formikProps.values.vertragId : buchungszeile;

  const filterForKonto = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.kontoId ? buchungszeile.konto.kontoId === formikProps.values.kontoId : buchungszeile;

  const filterForGegenkonto = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.gegenKontoId ? buchungszeile.gegenKonto?.kontoId === formikProps.values.gegenKontoId : buchungszeile;

  const filterForSollHaben = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.sollHaben ? buchungszeile.sollHaben.value === formikProps.values.sollHaben : buchungszeile;

  const filterForErsteller = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.ersteller ? buchungszeile.createdBy === formikProps.values.ersteller : buchungszeile;

  const filterForBuchungsTypeList = (buchungszeile: FibuBuchungszeile) =>
    formikProps.values.buchungsTypeList && formikProps.values.buchungsTypeList?.length > 0
      ? formikProps.values.buchungsTypeList.includes(buchungszeile.buchungsKopf.buchungsType.value)
      : buchungszeile;

  return buchungszeileList
    .filter((bz) => filterForStorno(bz, hasOnlyOneBuchung, showStorno))
    .filter(filterForUmbuchungen)
    .filter(filterForBelegSymbol)
    .filter(filterForBelegdatumBis)
    .filter(filterForBelegdatumVon)
    .filter(filterForBelegnummer)
    .filter(filterForBestandseinheit)
    .filter(filterForBuchungsKZ)
    .filter(filterForBuchungsTypeList)
    .filter(filterForBuchungsdatumBis)
    .filter(filterForBuchungsdatumVon)
    .filter(filterForBuchungskreisId)
    .filter(filterForErsteller)
    .filter(filterForGegenkonto)
    .filter(filterForKonto)
    .filter(filterForObjekt)
    .filter(filterForSollHaben)
    .filter(filterForVertrag);
};
