import { useEffect } from 'react';
import { LISTING_FILTER_HAS_CHANGED } from './useOnEventsSetStickyFilterSummary';

const useOnEventsSetStickyTableHeader = (
  filterRef: React.RefObject<HTMLDivElement>,
  summaryRef: React.RefObject<HTMLDivElement>,
  columnSelectorRef: React.RefObject<HTMLDivElement>
) => {
  useEffect(() => {
    const onScroll = () => setStylesForStickyTableHeader(filterRef, summaryRef, columnSelectorRef);
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onChange = () => setStylesForStickyTableHeader(filterRef, summaryRef, columnSelectorRef);
    document.addEventListener(LISTING_FILTER_HAS_CHANGED, onChange);
    return () => {
      document.removeEventListener(LISTING_FILTER_HAS_CHANGED, onChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const setStylesForStickyTableHeader = (
  filterRef: React.RefObject<HTMLDivElement>,
  summaryRef: React.RefObject<HTMLDivElement>,
  columnSelectorRef: React.RefObject<HTMLDivElement>
) => {
  if (filterRef.current && summaryRef.current && columnSelectorRef.current) {
    const filterElementClientRect = filterRef.current.getBoundingClientRect();
    const summaryElementClientRect = summaryRef.current.getBoundingClientRect();
    const columnSelectorElementClientRect = columnSelectorRef.current.getBoundingClientRect();

    const stickyHolder = document.getElementsByClassName('ant-table-sticky-holder')[0] as HTMLElement;

    stickyHolder.style.top =
      columnSelectorElementClientRect.top <= summaryElementClientRect.height + filterElementClientRect.height
        ? `${columnSelectorElementClientRect.height + filterElementClientRect.height + summaryElementClientRect.height}px`
        : '0px';
  }
};

export default useOnEventsSetStickyTableHeader;
