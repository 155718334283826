import React, { FC } from 'react';
import { StyledListSummarySpace } from '../../shared/ListSummary/styled/StyledListSummarySpace';
import { SummaryText } from '../../shared/ListSummary/SummaryText';
import { LoadingCol } from '../../../components/Loading';
import { useAuftragListSummaryQuery } from '../gql/AuftragQueries.types';
import { mapQueryParamsToAuftragQueryVariables } from './Filters/vorschreibungListingFiltersFormMapper';
import { TVorschreibungQueryParams } from './Filters/filtersQueryParams';

type Props = {
  queryParams: TVorschreibungQueryParams;
};

const VorschreibungListSummary: FC<Props> = ({ queryParams }) => {
  const { data: summaryData } = useAuftragListSummaryQuery({
    variables: {
      ...mapQueryParamsToAuftragQueryVariables(queryParams),
    },
  });

  const summary = summaryData?.getAuftragListSummary.data;

  return (
    <>
      {summary ? (
        <StyledListSummarySpace>
          <SummaryText text="Netto" value={summary.sumNetto} />
          <SummaryText text="Ust." value={summary.sumUst} />
          <SummaryText text="Brutto" value={summary.sumBrutto} />
        </StyledListSummarySpace>
      ) : (
        <LoadingCol isLoading />
      )}
    </>
  );
};

export default VorschreibungListSummary;
