import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { TopAbrechnung } from '../../../topAbrechnung-types';

const TAAbrKreisColumnTitles: FC<{ data: TopAbrechnung }> = ({ data }) => {
  const abrechnungskreisText = data.deckblatt.abrechnungskreisSummary.header.abrechnungskreisText;

  return (
    <View
      style={[
        pdfStyles.row,
        pdfStyles.textNormal,
        pdfStyles.borderBottomSmall,
        {
          paddingTop: '10mm',
          fontWeight: 'bold',
        },
      ]}
      // fixed
    >
      <Text
        style={[
          pdfStyles.column,
          {
            width: '15%',
            textAlign: 'right',
          },
        ]}
      >
        {abrechnungskreisText ?? null}
      </Text>
      <Text
        style={[
          pdfStyles.column,
          {
            textAlign: 'right',
            width: '15%',
          },
        ]}
      >
        {data.deckblatt.abrechnungskreisSummary.header.aufwandText}
      </Text>
      <Text
        style={[
          pdfStyles.column,
          {
            textAlign: 'right',
            width: '15%',
          },
        ]}
      >
        {data.deckblatt.abrechnungskreisSummary.header.vorschreibungText}
      </Text>
      <Text
        style={[
          pdfStyles.column,
          {
            textAlign: 'right',
            width: '15%',
          },
        ]}
      >
        {data.deckblatt.abrechnungskreisSummary.header.nettoText}
      </Text>
      <Text
        style={[
          pdfStyles.column,
          {
            textAlign: 'right',
            width: '15%',
          },
        ]}
      >
        {data.deckblatt.abrechnungskreisSummary.header.ustText}
      </Text>
      <Text
        style={[
          pdfStyles.column,
          {
            textAlign: 'right',
            width: '15%',
          },
        ]}
      >
        {data.deckblatt.abrechnungskreisSummary.header.saldoText}
      </Text>
    </View>
  );
};

export default TAAbrKreisColumnTitles;
