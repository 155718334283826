import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { BudgetingBaseFieldsFragmentDoc, BudgetingFieldsFragmentDoc, BudgetingResultFieldsFragmentDoc } from './BudgetingFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BudgetingListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BudgetingListQuery = {
  getBudgetingList: {
    data: Array<{
      bezeichnung: string;
      budgetSumNextYear?: number | null;
      budgetingId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      currentYear: number;
      increase?: number | null;
      prevYear: number;
      updatedByMitarbeiterId?: string | null;
      vertragVPosWertDate?: string | null;
      vorschreibungCurrentYearSum: number;
      vorschreibungPrevYearSum: number;
      restOfCurrentYearSum?: number | null;
      totalCurrentYearSum?: number | null;
      objekt: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
      status: { text: string; value: Types.BudgetingStatus };
      warningList: Array<{ message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingQueryVariables = Types.Exact<{
  budgetingId: Types.Scalars['ID']['input'];
  objektId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BudgetingQuery = {
  getBudgeting: {
    data: {
      bezeichnung: string;
      budgetSumNextYear?: number | null;
      budgetingId: string;
      budgetingResultId?: string | null;
      considerationPeriodUntil: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      currentYear: number;
      increase?: number | null;
      prevYear: number;
      updatedByMitarbeiterId?: string | null;
      vertragVPosWertDate?: string | null;
      vorschreibungCurrentYearSum: number;
      vorschreibungPrevYearSum: number;
      restOfCurrentYearSum?: number | null;
      totalCurrentYearSum?: number | null;
      budgetingVPosList: Array<{
        bezeichnung: string;
        budgetingVPosId: string;
        estimateNextYear: number;
        increase: number;
        leaveFlatRate: boolean;
        vorschreibungCurrentYear: number;
        vorschreibungPrevYear: number;
        vorschreibungRestOfCurrentYear: number;
        vorschreibungTotalCurrentYear: number;
        vorschreibungspositionId?: string | null;
        art: { text: string; value: Types.VorschreibungspositionArt };
        budgetingAbrKreisList: Array<{
          estimateNextYear: number;
          estimateRestOfCurrentYear: number;
          estimateTotalCurrentYear: number;
          gebuchtCurrentYear: number;
          gebuchtPrevYear: number;
          increase: number;
          abrechnungskreis: {
            abrechnungskreisId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
            type: { text: string; value: Types.AbrechnungskreisType };
            verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          budgetingKontoList: Array<{
            budgetingKontoId: string;
            estimateNextYear: number;
            estimateRestOfCurrentYear: number;
            estimateTotalCurrentYear: number;
            gebuchtCurrentYear: number;
            gebuchtPrevYear: number;
            increase: number;
            budgetingAufteilungsschluesselList: Array<{
              aufteilungsschluessel: {
                aufteilungsschluesselId: string;
                bezeichnung: string;
                bezugsbasisEditable?: boolean | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                firmendatenId?: string | null;
                kurzBezeichnung: string;
                updatedByMitarbeiterId?: string | null;
                bezugsbasis: { text: string; value: Types.Bezugsbasis };
                masseinheit: { text: string; value: Types.Masseinheit };
                status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
            }>;
            budgetingBuchungszeileList: Array<{
              ausgabenCurrentYear?: number | null;
              ausgabenPrevYear?: number | null;
              belegDatum: string;
              belegFileId?: string | null;
              belegnummer?: string | null;
              buchungId?: string | null;
              buchungsdatum?: string | null;
              steuer?: number | null;
              text: string;
              vertragspartner?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }>;
            konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
          }>;
        }>;
        budgetingAufteilungsschluesselList: Array<{
          aufteilungsschluessel: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
        }>;
        status: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
      }>;
      eigentuemerAbrechnungsdefinition?: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
        type: { text: string; value: Types.AbrechnungsdefinitionType };
      } | null;
      mieterAbrechnungsdefinition?: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
        type: { text: string; value: Types.AbrechnungsdefinitionType };
      } | null;
      objekt: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
      status: { text: string; value: Types.BudgetingStatus };
      rechnungsAussteller?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingBaseQueryVariables = Types.Exact<{
  budgetingId: Types.Scalars['ID']['input'];
  objektId: Types.Scalars['ID']['input'];
}>;

export type BudgetingBaseQuery = {
  getBudgetingBase: {
    data: {
      bezeichnung: string;
      budgetSumNextYear?: number | null;
      budgetingId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      currentYear: number;
      increase?: number | null;
      prevYear: number;
      updatedByMitarbeiterId?: string | null;
      vertragVPosWertDate?: string | null;
      vorschreibungCurrentYearSum: number;
      vorschreibungPrevYearSum: number;
      restOfCurrentYearSum?: number | null;
      totalCurrentYearSum?: number | null;
      objekt: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
      status: { text: string; value: Types.BudgetingStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingResultQueryVariables = Types.Exact<{
  budgetingResultId: Types.Scalars['ID']['input'];
  objektId: Types.Scalars['ID']['input'];
}>;

export type BudgetingResultQuery = {
  getBudgetingResult: {
    data: {
      budgetingResultId: string;
      budgetingResultBestandseinheitList: Array<{
        nutzflaeche: number;
        nutzflaecheAnteil: number;
        nutzwert: number;
        nutzwertAnteil: number;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        budgetingResultVertragList: Array<{
          annualNetto: number;
          monthlyNetto: number;
          budgetingResultVertragVPosWertList: Array<{
            annualNetto: number;
            monthlyNetto: number;
            validFrom: string;
            warning?: Types.BudgetingResultVertragVPosWertWarning | null;
            budgetingResultAufteilungsschluesselList: Array<{
              aufteilungsschluesselAnteil: number;
              aufteilungsschluesselBasis: number;
              budgetingWertAnteil: number;
              kurzBezeichnung: string;
              estimateNextYear: number;
              name: string;
              unitValue: number;
              budgetingResultBudgetingVPos?: {
                estimateNextYear: number;
                increase: number;
                vorschreibungCurrentYear: number;
                budgetingVPos: {
                  bezeichnung: string;
                  budgetingVPosId: string;
                  estimateNextYear: number;
                  increase: number;
                  leaveFlatRate: boolean;
                  vorschreibungCurrentYear: number;
                  vorschreibungPrevYear: number;
                  vorschreibungRestOfCurrentYear: number;
                  vorschreibungTotalCurrentYear: number;
                  vorschreibungspositionId?: string | null;
                  art: { text: string; value: Types.VorschreibungspositionArt };
                  status: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
                };
              } | null;
              budgetingResultBudgetingKontoList: Array<{
                estimateNextYear: number;
                increase: number;
                vorschreibungCurrentYear: number;
                budgetingKonto: {
                  budgetingKontoId: string;
                  estimateNextYear: number;
                  estimateRestOfCurrentYear: number;
                  estimateTotalCurrentYear: number;
                  gebuchtCurrentYear: number;
                  gebuchtPrevYear: number;
                  increase: number;
                  konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
                };
              }>;
            }>;
            vertragVorschreibungsposition: {
              bezeichnung: string;
              brutto?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              validFrom?: string | null;
              netto?: number | null;
              steuersatz: number;
              ust?: number | null;
              vorschreibungspositionId: string;
              art: { text: string; value: Types.VorschreibungspositionArt };
              basis?: { text: string; value: Types.VorschreibungspositionBasis } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
          }>;
          vertrag: {
            bestandseinheitId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            objektId: string;
            vertragId: string;
            vertragsBeginn: string;
            status: { text: string; value: Types.VertragStatus; description?: string | null };
            vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
            vertragspartner: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
        }>;
        nutzungsArt: { text: string; value: Types.NutzungsArt };
      }>;
      warningList: Array<{ text: string; type: Types.BudgetingResultWarning }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingResultByVertragLevelQueryVariables = Types.Exact<{
  budgetingResultId: Types.Scalars['ID']['input'];
  objektId: Types.Scalars['ID']['input'];
}>;

export type BudgetingResultByVertragLevelQuery = {
  getBudgetingResultByVertragLevel: {
    data: Array<{
      sumNetto: number;
      vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
      vorschreibungspositionList: Array<{ sumNetto: number; vorschreibungsposition: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FinancialPlanTextsQueryVariables = Types.Exact<{
  budgetingId: Types.Scalars['ID']['input'];
  budgetingVPosId: Types.Scalars['ID']['input'];
  objektId: Types.Scalars['ID']['input'];
}>;

export type FinancialPlanTextsQuery = {
  getFinancialPlanTexts: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      einleitungsText?: string | null;
      schlussText?: string | null;
      updatedByMitarbeiterId?: string | null;
      expenseList: Array<{ amount: number; name: string }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type TopBudgetSumNextYearQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingResultId: Types.Scalars['ID']['input'];
  budgetingResultVertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type TopBudgetSumNextYearQuery = {
  getTopBudgetSumNextYear: {
    data: number;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BudgetingListDocument = gql`
  query BudgetingList($objektId: ID!, $includeArchiviert: Boolean) {
    getBudgetingList(objektId: $objektId, includeArchiviert: $includeArchiviert) {
      data {
        ...BudgetingBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BudgetingBaseFieldsFragmentDoc}
`;
export function useBudgetingListQuery(
  baseOptions: Apollo.QueryHookOptions<BudgetingListQuery, BudgetingListQueryVariables> &
    ({ variables: BudgetingListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingListQuery, BudgetingListQueryVariables>(BudgetingListDocument, options);
}
export function useBudgetingListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetingListQuery, BudgetingListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingListQuery, BudgetingListQueryVariables>(BudgetingListDocument, options);
}
export function useBudgetingListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BudgetingListQuery, BudgetingListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingListQuery, BudgetingListQueryVariables>(BudgetingListDocument, options);
}
export type BudgetingListQueryHookResult = ReturnType<typeof useBudgetingListQuery>;
export type BudgetingListLazyQueryHookResult = ReturnType<typeof useBudgetingListLazyQuery>;
export type BudgetingListSuspenseQueryHookResult = ReturnType<typeof useBudgetingListSuspenseQuery>;
export type BudgetingListQueryResult = Apollo.QueryResult<BudgetingListQuery, BudgetingListQueryVariables>;
export const BudgetingDocument = gql`
  query Budgeting($budgetingId: ID!, $objektId: ID!, $includeArchiviert: Boolean) {
    getBudgeting(budgetingId: $budgetingId, objektId: $objektId, includeArchiviert: $includeArchiviert) {
      data {
        ...BudgetingFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BudgetingFieldsFragmentDoc}
`;
export function useBudgetingQuery(
  baseOptions: Apollo.QueryHookOptions<BudgetingQuery, BudgetingQueryVariables> &
    ({ variables: BudgetingQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingQuery, BudgetingQueryVariables>(BudgetingDocument, options);
}
export function useBudgetingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetingQuery, BudgetingQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingQuery, BudgetingQueryVariables>(BudgetingDocument, options);
}
export function useBudgetingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BudgetingQuery, BudgetingQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingQuery, BudgetingQueryVariables>(BudgetingDocument, options);
}
export type BudgetingQueryHookResult = ReturnType<typeof useBudgetingQuery>;
export type BudgetingLazyQueryHookResult = ReturnType<typeof useBudgetingLazyQuery>;
export type BudgetingSuspenseQueryHookResult = ReturnType<typeof useBudgetingSuspenseQuery>;
export type BudgetingQueryResult = Apollo.QueryResult<BudgetingQuery, BudgetingQueryVariables>;
export const BudgetingBaseDocument = gql`
  query BudgetingBase($budgetingId: ID!, $objektId: ID!) {
    getBudgetingBase(budgetingId: $budgetingId, objektId: $objektId) {
      data {
        ...BudgetingBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BudgetingBaseFieldsFragmentDoc}
`;
export function useBudgetingBaseQuery(
  baseOptions: Apollo.QueryHookOptions<BudgetingBaseQuery, BudgetingBaseQueryVariables> &
    ({ variables: BudgetingBaseQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingBaseQuery, BudgetingBaseQueryVariables>(BudgetingBaseDocument, options);
}
export function useBudgetingBaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetingBaseQuery, BudgetingBaseQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingBaseQuery, BudgetingBaseQueryVariables>(BudgetingBaseDocument, options);
}
export function useBudgetingBaseSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BudgetingBaseQuery, BudgetingBaseQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingBaseQuery, BudgetingBaseQueryVariables>(BudgetingBaseDocument, options);
}
export type BudgetingBaseQueryHookResult = ReturnType<typeof useBudgetingBaseQuery>;
export type BudgetingBaseLazyQueryHookResult = ReturnType<typeof useBudgetingBaseLazyQuery>;
export type BudgetingBaseSuspenseQueryHookResult = ReturnType<typeof useBudgetingBaseSuspenseQuery>;
export type BudgetingBaseQueryResult = Apollo.QueryResult<BudgetingBaseQuery, BudgetingBaseQueryVariables>;
export const BudgetingResultDocument = gql`
  query BudgetingResult($budgetingResultId: ID!, $objektId: ID!) {
    getBudgetingResult(budgetingResultId: $budgetingResultId, objektId: $objektId) {
      data {
        ...BudgetingResultFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BudgetingResultFieldsFragmentDoc}
`;
export function useBudgetingResultQuery(
  baseOptions: Apollo.QueryHookOptions<BudgetingResultQuery, BudgetingResultQueryVariables> &
    ({ variables: BudgetingResultQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingResultQuery, BudgetingResultQueryVariables>(BudgetingResultDocument, options);
}
export function useBudgetingResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetingResultQuery, BudgetingResultQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingResultQuery, BudgetingResultQueryVariables>(BudgetingResultDocument, options);
}
export function useBudgetingResultSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BudgetingResultQuery, BudgetingResultQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingResultQuery, BudgetingResultQueryVariables>(BudgetingResultDocument, options);
}
export type BudgetingResultQueryHookResult = ReturnType<typeof useBudgetingResultQuery>;
export type BudgetingResultLazyQueryHookResult = ReturnType<typeof useBudgetingResultLazyQuery>;
export type BudgetingResultSuspenseQueryHookResult = ReturnType<typeof useBudgetingResultSuspenseQuery>;
export type BudgetingResultQueryResult = Apollo.QueryResult<BudgetingResultQuery, BudgetingResultQueryVariables>;
export const BudgetingResultByVertragLevelDocument = gql`
  query BudgetingResultByVertragLevel($budgetingResultId: ID!, $objektId: ID!) {
    getBudgetingResultByVertragLevel(budgetingResultId: $budgetingResultId, objektId: $objektId) {
      data {
        sumNetto
        vertragLevel {
          shortName
          text
          value
        }
        vorschreibungspositionList {
          sumNetto
          vorschreibungsposition
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingResultByVertragLevelQuery(
  baseOptions: Apollo.QueryHookOptions<BudgetingResultByVertragLevelQuery, BudgetingResultByVertragLevelQueryVariables> &
    ({ variables: BudgetingResultByVertragLevelQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingResultByVertragLevelQuery, BudgetingResultByVertragLevelQueryVariables>(
    BudgetingResultByVertragLevelDocument,
    options
  );
}
export function useBudgetingResultByVertragLevelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetingResultByVertragLevelQuery, BudgetingResultByVertragLevelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingResultByVertragLevelQuery, BudgetingResultByVertragLevelQueryVariables>(
    BudgetingResultByVertragLevelDocument,
    options
  );
}
export function useBudgetingResultByVertragLevelSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BudgetingResultByVertragLevelQuery, BudgetingResultByVertragLevelQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingResultByVertragLevelQuery, BudgetingResultByVertragLevelQueryVariables>(
    BudgetingResultByVertragLevelDocument,
    options
  );
}
export type BudgetingResultByVertragLevelQueryHookResult = ReturnType<typeof useBudgetingResultByVertragLevelQuery>;
export type BudgetingResultByVertragLevelLazyQueryHookResult = ReturnType<typeof useBudgetingResultByVertragLevelLazyQuery>;
export type BudgetingResultByVertragLevelSuspenseQueryHookResult = ReturnType<typeof useBudgetingResultByVertragLevelSuspenseQuery>;
export type BudgetingResultByVertragLevelQueryResult = Apollo.QueryResult<
  BudgetingResultByVertragLevelQuery,
  BudgetingResultByVertragLevelQueryVariables
>;
export const FinancialPlanTextsDocument = gql`
  query FinancialPlanTexts($budgetingId: ID!, $budgetingVPosId: ID!, $objektId: ID!) {
    getFinancialPlanTexts(budgetingId: $budgetingId, budgetingVPosId: $budgetingVPosId, objektId: $objektId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        einleitungsText
        expenseList {
          amount
          name
        }
        schlussText
        updatedByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFinancialPlanTextsQuery(
  baseOptions: Apollo.QueryHookOptions<FinancialPlanTextsQuery, FinancialPlanTextsQueryVariables> &
    ({ variables: FinancialPlanTextsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FinancialPlanTextsQuery, FinancialPlanTextsQueryVariables>(FinancialPlanTextsDocument, options);
}
export function useFinancialPlanTextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinancialPlanTextsQuery, FinancialPlanTextsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FinancialPlanTextsQuery, FinancialPlanTextsQueryVariables>(FinancialPlanTextsDocument, options);
}
export function useFinancialPlanTextsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FinancialPlanTextsQuery, FinancialPlanTextsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FinancialPlanTextsQuery, FinancialPlanTextsQueryVariables>(FinancialPlanTextsDocument, options);
}
export type FinancialPlanTextsQueryHookResult = ReturnType<typeof useFinancialPlanTextsQuery>;
export type FinancialPlanTextsLazyQueryHookResult = ReturnType<typeof useFinancialPlanTextsLazyQuery>;
export type FinancialPlanTextsSuspenseQueryHookResult = ReturnType<typeof useFinancialPlanTextsSuspenseQuery>;
export type FinancialPlanTextsQueryResult = Apollo.QueryResult<FinancialPlanTextsQuery, FinancialPlanTextsQueryVariables>;
export const TopBudgetSumNextYearDocument = gql`
  query TopBudgetSumNextYear($objektId: ID!, $budgetingResultId: ID!, $budgetingResultVertragId: ID) {
    getTopBudgetSumNextYear(objektId: $objektId, budgetingResultId: $budgetingResultId, budgetingResultVertragId: $budgetingResultVertragId) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useTopBudgetSumNextYearQuery(
  baseOptions: Apollo.QueryHookOptions<TopBudgetSumNextYearQuery, TopBudgetSumNextYearQueryVariables> &
    ({ variables: TopBudgetSumNextYearQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopBudgetSumNextYearQuery, TopBudgetSumNextYearQueryVariables>(TopBudgetSumNextYearDocument, options);
}
export function useTopBudgetSumNextYearLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopBudgetSumNextYearQuery, TopBudgetSumNextYearQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopBudgetSumNextYearQuery, TopBudgetSumNextYearQueryVariables>(TopBudgetSumNextYearDocument, options);
}
export function useTopBudgetSumNextYearSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TopBudgetSumNextYearQuery, TopBudgetSumNextYearQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TopBudgetSumNextYearQuery, TopBudgetSumNextYearQueryVariables>(TopBudgetSumNextYearDocument, options);
}
export type TopBudgetSumNextYearQueryHookResult = ReturnType<typeof useTopBudgetSumNextYearQuery>;
export type TopBudgetSumNextYearLazyQueryHookResult = ReturnType<typeof useTopBudgetSumNextYearLazyQuery>;
export type TopBudgetSumNextYearSuspenseQueryHookResult = ReturnType<typeof useTopBudgetSumNextYearSuspenseQuery>;
export type TopBudgetSumNextYearQueryResult = Apollo.QueryResult<TopBudgetSumNextYearQuery, TopBudgetSumNextYearQueryVariables>;
