const colors = {
  background: '#F3F3F3',
  grey: 'rgba(171, 186, 194, 0.2)',
  overlay: 'rgba(51, 51, 51, 0.8)',
  dark: '#333333',
  white: '#FFFFFF',
  default: '#F4F8F9',
  noise: '#ABBAC2',
  purple: '#7460ee',
  lightPurple: '#a195ea',
  black: '#000000',
  blue: '#1677ff',
  danger: '#db3d47',
  success: '#72dbbc',
  completed: '#248e6f',
  warning: '#F2994A',
  error: '#ff4d4f',
};

const cardBackgroundColors = {
  blue: '#91d5ff',
  white: '#FFFFFF',
  grey: 'rgba(171, 186, 194, 0.2)',
};

const tableBackgroundColors = {
  active: '#e6f7ff',
  highlighted: '#e6f7ff',
  deviations: '#fff1f0',
};

const textColor = {
  primary: 'rgba(0, 0, 0, 0.85)', //#262626
  secondary: 'rgba(0, 0, 0, 0.45)', // #8C8C8C
  disabled: 'rgba(0, 0, 0, 0.25)', // #BFBFBF
  danger: '#db3d47',
  success: '#72dbbc',
  warning: '#F2994A',
};

const linkColor = {
  default: '#1677ff',
  primary: 'rgba(0, 0, 0, 0.85)', //#262626
  secondary: 'rgba(0, 0, 0, 0.45)', // #8C8C8C
};

const fonts = {
  sans: 'Roboto, Helvetica',
  mono: 'Menlo, monospace',
};

const fontSizes = {
  Versalita: '11px',
  BodyArtikel: '20px',
  BodyArtikelSmall: '16px',
  BodySmall: '13px',
  ToggleText: '11px',
  ToggleTextSub: '9px',
  Akzent: '16px',
};

const fontWeights = {
  light: 300,
  regular: 400,
  normal: 500,
  bold: 700,
};

const theme = {
  colors,
  cardBackgroundColors,
  tableBackgroundColors,
  textColor,
  linkColor,
  fonts,
  fontSizes,
  fontWeights,
};

export default theme;
