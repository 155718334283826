import React, { FC } from 'react';
import { View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import PositionBlock from '../../shared/DetailsTable/PositionBlock';
import Zwischensumme from '../../shared/DetailsTable/Zwischensumme';
import EigentuemerverrechnungBlock from '../../shared/DetailsTable/EigentuemerverrechnungBlock';
import LabelValue from '../../../shared/LabelValue';
import { SubAdminAbrBeObjekt } from '../subAdministrationAbrechnungTop-types';

const BeObjektListSummary: FC<{ beObjektListSummary: SubAdminAbrBeObjekt }> = ({ beObjektListSummary }) => (
  <View style={[pdfStyles.tablePaddingLeftRight, { marginTop: '3mm' }]}>
    {/* Einnahmen, Ausgaben and VstKuerzung */}
    {beObjektListSummary.einnahmen ? <PositionBlock data={beObjektListSummary.einnahmen} /> : null}
    {beObjektListSummary.ausgaben ? <PositionBlock data={beObjektListSummary.ausgaben} /> : null}
    {beObjektListSummary.vorsteuerkuerzung ? <PositionBlock data={beObjektListSummary.vorsteuerkuerzung} /> : null}

    {/* Zwischensumme */}
    {beObjektListSummary.einnahmen || beObjektListSummary.ausgaben || beObjektListSummary.vorsteuerkuerzung ? (
      <Zwischensumme data={beObjektListSummary.zwischensumme} />
    ) : null}

    {/* Eigentuemerverrechnung */}
    {beObjektListSummary.eigentuemerverrechnung ? <EigentuemerverrechnungBlock data={beObjektListSummary.eigentuemerverrechnung} /> : null}

    {/* Allgemeine/ Bestandseinheit Abrechnung */}
    <View style={[pdfStyles.column, { marginTop: '5mm' }]}>
      <LabelValue
        valueTuple={beObjektListSummary.objektErgebnis}
        viewStyle={{ ...pdfStyles.borderBottomSmall, borderBottomWidth: 2 }}
        labelStyle={{ width: '70%', fontWeight: 'bold' }}
        valueStyle={{ width: '30%', textAlign: 'right', fontWeight: 'bold' }}
      />
    </View>
  </View>
);

export default BeObjektListSummary;
