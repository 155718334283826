import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { ApplyVertragVposIndexedValueDetailsPageRouteParams } from './routes';
import menuListApplyVertragVposIndexedValue from './menuListApplyVertragVposIndexedValue';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { ApplyVertragVposIndexedValueGenerierlauf } from '../../../types';
import { useApplyVertragVposIndexedValueGenerierlaufQuery } from '../../../features/Verarbeitung/gql/ApplyVertragVposIndexedValueGenerierlauf/ApplyVertragVposIndexedValueGenerierlaufQueries.types';
import { useRestartApplyVertragVposIndexedValueGenerierlaufMutation } from '../../../features/Verarbeitung/gql/ApplyVertragVposIndexedValueGenerierlauf/ApplyVertragVposIndexedValueGenerierlaufMutations.types';

const ApplyVertragVposIndexedValueDetailsPage = () => {
  const { generierlaufId } = useParams() as ApplyVertragVposIndexedValueDetailsPageRouteParams;

  const { data, refetch } = useApplyVertragVposIndexedValueGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getApplyVertragVposIndexedValueGenerierlauf.data;

  const [runRestart] = useRestartApplyVertragVposIndexedValueGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<ApplyVertragVposIndexedValueGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.ApplyVertragVposIndexedValue}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.ApplyVertragVposIndexedValue}
          onGenerierlaufRestart={runRestart}
        />
      )}
      isLoading={false}
      sidebarMenuList={menuListApplyVertragVposIndexedValue}
    />
  );
};

export default ApplyVertragVposIndexedValueDetailsPage;
