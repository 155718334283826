import React, { FC, useState } from 'react';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { Space, Typography } from 'antd';
import {
  mapFormValuesIntoTemplateStepInput,
  mapTemplateStepToFormValues,
  emailTemplateStepFormFields,
  EmailTemplateStepFormValues,
} from './emailTemplateStepFormMapper';
import EmailDeliveryTextbausteinFormItem from '../../../../EmailDeliveryDefinition/Form/EmailDeliveryTextbausteinFormItem';
import AttachmentPart from './AttachmentPart';
import { handleUpdateSubmit } from './handleSubmit';
import { useFDAuthorized } from '../../../../../shared/Auth/useAuthorized';
import { emailTemplateStepFormValidationSchema } from './emailTemplateStepFormValidationSchema';
import { WizardFileInfo } from '../../../../../types';
import EmailDeliveryDefinitionTextOrLink from '../../../../EmailDelivery/EmailDeliveryDefinitionTextOrLink';
import { DeleteWizard } from '../../../wizardHelpers';
import StepButtons from '../../../Shared/StepButtons';
import { StyledWizardSpaceForTextArea } from '../../../../shared/styled/WizardSpace.style';

type Props = {
  attachmentInfoList: WizardFileInfo[];
  emailDeliveryDefinitionBezeichnung: string;
  emailDeliveryDefinitionId: string;
  formData: EmailTemplateStepFormValues;
  isCompleted?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  onDelete: DeleteWizard;
  wizardId: string;
  loading?: boolean;
};

const EmailTemplateStepForm: FC<Props> = ({
  emailDeliveryDefinitionBezeichnung,
  emailDeliveryDefinitionId,
  formData,
  isCompleted,
  onCancel,
  onSubmit,
  onDelete,
  wizardId,
  attachmentInfoList,
  loading,
}) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  const [inProgress, setInProgress] = useState(loading);

  return (
    <Formik<EmailTemplateStepFormValues>
      initialValues={mapTemplateStepToFormValues(formData)}
      validationSchema={emailTemplateStepFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setInProgress(true);
        const input = mapFormValuesIntoTemplateStepInput(values, true);
        handleUpdateSubmit(firmendatenId, wizardId, values, input, setSubmitting, onSubmit).finally(() => {
          setInProgress(false);
        });
      }}
    >
      {(formikProps) => {
        const onSave = () => {
          // TODO: EC-7366 CR: use formikProps.validate instead of errors.subject?
          if (isCompleted || formikProps.errors.subject || formikProps.isSubmitting) return;
          setInProgress(true);
          const input = mapFormValuesIntoTemplateStepInput(formikProps.values, false);
          handleUpdateSubmit(firmendatenId, wizardId, formikProps.values, input, formikProps.setSubmitting, onCancel).finally(() => {
            setInProgress(false);
          });
        };
        const onNext = () => {
          // TODO: EC-7366 CR: I don't think we have to check the validity because submitForm should do it for us
          if (formikProps.errors.subject || formikProps.isSubmitting) return;
          if (isCompleted) {
            onSubmit();
            return;
          }
          formikProps.submitForm();
        };

        return (
          <Form>
            <Space direction="vertical" size="large" style={{ maxWidth: 800 }}>
              <StyledWizardSpaceForTextArea style={{ marginTop: '40px' }} align="start" size={0}>
                <Typography.Text>Texte aus Vorlage</Typography.Text>
                <EmailDeliveryDefinitionTextOrLink
                  bezeichnung={emailDeliveryDefinitionBezeichnung}
                  emailDeliveryDefinitionId={emailDeliveryDefinitionId}
                />
              </StyledWizardSpaceForTextArea>
              <EmailDeliveryTextbausteinFormItem
                disabled={isCompleted || formikProps.isSubmitting}
                name={emailTemplateStepFormFields.subject}
                label="E-Mail Betreff"
              />
              <EmailDeliveryTextbausteinFormItem
                disabled={isCompleted || formikProps.isSubmitting}
                name={emailTemplateStepFormFields.body}
                label="Text Körper"
              />
              <AttachmentPart attachmentInfoList={attachmentInfoList} disabled={isCompleted || formikProps.isSubmitting} formikProps={formikProps} />
            </Space>

            <StepButtons
              isBackHidden
              isDropdownDisabled={inProgress}
              isDropdownHidden={isCompleted}
              onNext={onNext}
              isNextDisabled={inProgress}
              onCancel={onCancel}
              onSave={onSave}
              onDelete={() => onDelete(!!isCompleted, setInProgress)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmailTemplateStepForm;
