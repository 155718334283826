import { generatePath } from 'react-router-dom';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { TicketListingFiltersFormValues } from '../ticketListingFormMapper';

export enum TicketQueryParamKey {
  DUE_DATE_FROM = 'dueDateFrom',
  DUE_DATE_TO = 'dueDateTo',
  ASSIGNEE_MITARBEITER_ID = 'assigneeMitarbeiterId',
  PARTICIPANT_ID = 'participantId',
  TYPE_NAME = 'typeName',
  PRIORITY = 'priority',
  TITLE = 'title',
  STATUS = 'status',
  SHOW_ARCHIVED = 'showArchived',
  SEARCH_IN_SUB_TICKETS = 'searchInSubTickets',
  OBJEKT_ID = 'objektId',
  BESTANDSEINHEIT_ID = 'bestandseinheitId',
  VERTRAG_ID = 'vertragId',
  RECHTSTRAEGER_ID = 'rechtstraegerId',
}

export type TTicketQueryParams = QueryParams<TicketListingFiltersFormValues>;

export const updateQueryParams = (
  pageUri: string,
  navigate: (path: string) => void,
  filters: TTicketQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToPageWithFilters(pageUri, filters, paginationParams));

export const generatePathToPageWithFilters = (pageUri: string, filters: TTicketQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(pageUri)}?${mapToQueryString({
    [TicketQueryParamKey.DUE_DATE_FROM]: filters?.dueDateFrom,
    [TicketQueryParamKey.DUE_DATE_TO]: filters?.dueDateTo,
    [TicketQueryParamKey.ASSIGNEE_MITARBEITER_ID]: filters?.assigneeMitarbeiterId,
    [TicketQueryParamKey.PARTICIPANT_ID]: filters?.participantId,
    [TicketQueryParamKey.TYPE_NAME]: filters?.typeName,
    [TicketQueryParamKey.PRIORITY]: filters?.priority,
    [TicketQueryParamKey.TITLE]: filters?.title,
    [TicketQueryParamKey.STATUS]: filters?.status,
    [TicketQueryParamKey.SHOW_ARCHIVED]: filters?.showArchived,
    [TicketQueryParamKey.SEARCH_IN_SUB_TICKETS]: filters?.searchInSubTickets,
    [TicketQueryParamKey.OBJEKT_ID]: filters?.objektId,
    [TicketQueryParamKey.BESTANDSEINHEIT_ID]: filters?.bestandseinheitId,
    [TicketQueryParamKey.VERTRAG_ID]: filters?.vertragId,
    [TicketQueryParamKey.RECHTSTRAEGER_ID]: filters?.rechtstraegerId,
    ...paginationParams,
  })}`;
