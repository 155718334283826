import * as Yup from 'yup';
import { indexedValueFormFields } from './indexedValuesCreateFormMapper';
import { entityIsRequired, entityMustBeSelected } from '../../../../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */
export const createFormValidationSchema = Yup.object().shape({
  [indexedValueFormFields.yearMonth]: Yup.string().required(entityIsRequired('Monat/Jahr neu')),
  [indexedValueFormFields.valuePreservationTypeList]: Yup.array().of(Yup.string()).min(1, entityMustBeSelected('Mindestens eine Wertsicherungsart')),
});
/* eslint-enable no-template-curly-in-string */
