import { AuftragsartUpdateInput } from '../../../../types';
import { mapAuftragsartTextbausteinToInput } from '../shared/auftragsartFormHelpers';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { AuftragsartUpdateParamFormValues } from './ParameterForm/auftragsartUpdateParamFormMapper';

export const mapFormValuesToAuftragsart = (
  textbausteinList: TextbausteinFormValues[],
  paramFormValues: AuftragsartUpdateParamFormValues
): AuftragsartUpdateInput => ({
  textbausteinList: mapAuftragsartTextbausteinToInput(textbausteinList),
  bezeichnung: paramFormValues.bezeichnung,
  showUstBlock: paramFormValues.showUstBlock,
  stornoAuftragsartId: paramFormValues.stornoAuftragsartId || undefined,
});
