import React, { FC, PropsWithChildren } from 'react';
import { Drawer } from 'antd';

type ProfileCardDrawerProps = {
  title: string;
  isCollapsed: boolean;
  onCollapse: () => void;
};

const ProfileCardDrawer: FC<ProfileCardDrawerProps & PropsWithChildren> = ({ title, isCollapsed, onCollapse, children }) => {
  return (
    <Drawer width={720} title={title} open={isCollapsed} onClose={onCollapse} destroyOnClose>
      {children}
    </Drawer>
  );
};

export default ProfileCardDrawer;
