import { FormFields } from '../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';
import { BeInfoFeld_BeInfoFeldZimmer_Fragment } from '../../../gql/BeInfoFeldFragments.types';

export interface ZimmerUpdateFormValues extends InfoFeldFormValues {
  bezeichnung: string;
  anmerkung?: string;
  bodenbelag?: string;
  breite?: number;
  energiekennzahl?: string;
  flaechentyp?: string;
  heizung?: string;
  hoehe?: number;
  initialQuadratmeterBetrag?: number;
  initialQuadratmeterPreis?: number;
  kubikmeter?: number;
  laenge?: number;
  nummer?: string;
  nutzflaeche?: number;
  nutzung?: boolean;
  quadratmeter?: number;
  quadratmeterBetrag?: number;
  quadratmeterPreis?: number;
  objektVorschreibungspositionId?: string;
  deleteDocumentFileIdList: string[];
}

export const zimmerUpdateFormFields: FormFields<ZimmerUpdateFormValues> = {
  bezeichnung: 'bezeichnung',
  anmerkung: 'anmerkung',
  bodenbelag: 'bodenbelag',
  breite: 'breite',
  energiekennzahl: 'energiekennzahl',
  flaechentyp: 'flaechentyp',
  heizung: 'heizung',
  hoehe: 'hoehe',
  initialQuadratmeterBetrag: 'initialQuadratmeterBetrag',
  initialQuadratmeterPreis: 'initialQuadratmeterPreis',
  kubikmeter: 'kubikmeter',
  laenge: 'laenge',
  nummer: 'nummer',
  nutzflaeche: 'nutzflaeche',
  nutzung: 'nutzung',
  quadratmeter: 'quadratmeter',
  quadratmeterBetrag: 'quadratmeterBetrag',
  quadratmeterPreis: 'quadratmeterPreis',
  objektVorschreibungspositionId: 'objektVorschreibungspositionId',
  documentList: 'documentList',
  deleteDocumentFileIdList: 'deleteDocumentFileIdList',
};

export const mapToUpdateFormValues = (zimmer: BeInfoFeld_BeInfoFeldZimmer_Fragment): ZimmerUpdateFormValues => ({
  documentList: [],
  deleteDocumentFileIdList: [],
  bezeichnung: zimmer.bezeichnung,
  anmerkung: zimmer.anmerkung ?? undefined,
  bodenbelag: zimmer.bodenbelag ?? undefined,
  breite: zimmer.breite ?? undefined,
  energiekennzahl: zimmer.energiekennzahl ?? undefined,
  flaechentyp: zimmer.flaechentyp ?? undefined,
  heizung: zimmer.heizung ?? undefined,
  hoehe: zimmer.hoehe ?? undefined,
  initialQuadratmeterBetrag: zimmer.initialQuadratmeterBetrag ?? undefined,
  initialQuadratmeterPreis: zimmer.initialQuadratmeterPreis ?? undefined,
  kubikmeter: zimmer.kubikmeter ?? undefined,
  laenge: zimmer.laenge ?? undefined,
  nummer: zimmer.zimmerNummer ?? undefined,
  nutzflaeche: zimmer.nutzflaeche ?? undefined,
  nutzung: zimmer.nutzung ?? undefined,
  quadratmeter: zimmer.quadratmeter ?? undefined,
  quadratmeterBetrag: zimmer.quadratmeterBetrag ?? undefined,
  quadratmeterPreis: zimmer.quadratmeterPreis ?? undefined,
  objektVorschreibungspositionId: zimmer.vorschreibungsposition?.objektVorschreibungspositionId ?? undefined,
});
