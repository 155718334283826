import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { SortOrder, tooltipTitleForSorting } from './sortingHelpers';
import SortCarets from './SortCarets';

type TableHeadCellWithSortCaretsProps = {
  index: number;
  columnTitle: string;
  sortedColumnIndex: number;
  sortOrder: SortOrder;
  handleSorting: (index: number) => void;
  align?: 'left' | 'right' | 'center';
  colSpan?: number;
  width?: string;
};

const TableHeadCellWithSortCarets: FC<TableHeadCellWithSortCaretsProps> = ({
  index,
  columnTitle,
  sortedColumnIndex,
  sortOrder,
  handleSorting,
  align = 'left',
  colSpan,
  width,
}) => (
  <th className="ant-table-cell ant-table-column-has-sorters" style={{ textAlign: align, width: `${width}` }} colSpan={colSpan}>
    <Tooltip title={tooltipTitleForSorting(index, sortedColumnIndex, sortOrder)}>
      <div className="ant-table-column-sorters" onClick={() => handleSorting(index)} data-testid="table-column-sorters">
        <span className="ant-table-column-title">{columnTitle}</span>
        <SortCarets columnIndex={index} sortedColumnIndex={sortedColumnIndex} sortOrder={sortOrder} />
      </div>
    </Tooltip>
  </th>
);

export default TableHeadCellWithSortCarets;
