import React, { FC } from 'react';
import { Divider } from 'antd';
import { Formik } from 'formik';
import { Form, Switch } from 'formik-antd';
import { Box } from 'rebass';
import { getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../../components/Timeline/timelineCtx';
import {
  beOptionSteuerpflichtVersionFormFields,
  BeOptionSteuerpflichtVersionFormValues,
  mapFormValuesToBeOptionSteuerpflichtVersion,
  mapTimeblockToFormValues,
  steuerpflichtVersionFormValuesDescriptionsForKommentar,
} from './beOptionSteuerpflichtVersionFormMapper';
import { layoutTimelineForm } from '../../../../../components/Input-antd/formLayoutHelper';
import TimelineFormDatePicker from '../../../../../components/Timeline/Form/TimelineFormDatePicker';
import TimelineFormKommentar from '../../../../../components/Timeline/Form/TimelineFormKommentar';
import TimelineFormButtons from '../../../../../components/Timeline/Form/TimelineFormButtons';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import {
  useCreateBeOptionSteuerpflichtVersionMutation,
  useUpdateBeOptionSteuerpflichtVersionMutation,
} from '../../gql/BeOptionSteuerpflichtMutations.types';
import { BeOptionSteuerpflichtVersion } from '../../../../../types';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';

type BeOptionSteuerpflichtVersionFormProps = {
  timeblock: ITimeblock<BeOptionSteuerpflichtVersion>;
  objektId: string;
  bestandseinheitId: string;
  onActionSuccess: () => void;
};

const BeOptionSteuerpflichtVersionForm: FC<BeOptionSteuerpflichtVersionFormProps> = ({ timeblock, objektId, bestandseinheitId, onActionSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeOptionSteuerpflicht'>('BeOptionSteuerpflicht');

  const { dataSource, toggleEdit, changeToUpdateMode } = useTimeline<BeOptionSteuerpflichtVersion>();
  const initialValues = mapTimeblockToFormValues(timeblock);
  const isUpdate = !!timeblock.beOptionSteuerpflichtId;

  const entity = 'Steuerpflicht';

  const [runCreate, { loading: loadingCreate }] = useCreateBeOptionSteuerpflichtVersionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      changeToUpdateMode(timeblock.uuid);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateBeOptionSteuerpflichtVersionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      toggleEdit(timeblock.uuid);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<BeOptionSteuerpflichtVersionFormValues>
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToBeOptionSteuerpflichtVersion(values);
        if (isUpdate) {
          runUpdate({
            variables: {
              objektId,
              bestandseinheitId,
              beOptionSteuerpflichtVersionId: timeblock.beOptionSteuerpflichtId,
              input,
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({
            variables: {
              objektId,
              bestandseinheitId,
              input,
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form {...layoutTimelineForm} layout="horizontal" labelAlign="left">
          <Box width={2 / 3}>
            <FormItemWithFieldHelp
              name={beOptionSteuerpflichtVersionFormFields.validFrom}
              label="Gültig ab"
              fieldHelp={getFieldHelpText('BeOptionSteuerpflicht.validFrom')}
            >
              <TimelineFormDatePicker<BeOptionSteuerpflichtVersion>
                id={beOptionSteuerpflichtVersionFormFields.validFrom}
                name={beOptionSteuerpflichtVersionFormFields.validFrom}
                timeblock={timeblock}
              />
            </FormItemWithFieldHelp>

            <FormItemWithFieldHelp
              name={beOptionSteuerpflichtVersionFormFields.steuerpflicht}
              label="Steuerpflicht"
              style={{ margin: 0 }}
              fieldHelp={getFieldHelpText('BeOptionSteuerpflicht.optionSteuerpflicht')}
            >
              <Switch id={beOptionSteuerpflichtVersionFormFields.steuerpflicht} name={beOptionSteuerpflichtVersionFormFields.steuerpflicht} />
            </FormItemWithFieldHelp>
          </Box>

          <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />

          <TimelineFormKommentar
            name={beOptionSteuerpflichtVersionFormFields.kommentar}
            timeblock={timeblock}
            valuesDescriptions={steuerpflichtVersionFormValuesDescriptionsForKommentar}
          />

          <TimelineFormButtons<BeOptionSteuerpflichtVersion>
            timeblock={timeblock}
            isSubmitting={formikProps.isSubmitting}
            isSameAsPreviousVersion={isSameAsPreviousVersion(
              formikProps.values,
              getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
            )}
            submitBtnLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

const isSameAsPreviousVersion = (formValues: BeOptionSteuerpflichtVersionFormValues, previousTimeblock?: ITimeblock<BeOptionSteuerpflichtVersion>) =>
  formValues.steuerpflicht === previousTimeblock?.optionSteuerpflicht;

export default BeOptionSteuerpflichtVersionForm;
