import { generatePath } from 'react-router-dom';
import { URI_OBJEKT_DETAILS_PAGE } from '../Objekt/objektUriPaths';
import { mapToQueryString, QUERY_PARAM_KEY } from '../../hooks/useStringQueryParams';

export const URI_BUDGETING_DETAILS_PAGE = `${URI_OBJEKT_DETAILS_PAGE}/tops/budgetierung/:budgetingId`;

export const generatePathToBudgetingDetailsPage = (
  objektId: string,
  budgetingId: string,
  tab?: string,
  highlightedRowKey?: string,
  expandedRowKeys?: Record<string, string | string[]>
) => {
  tab = tab ? `/${tab}` : '';
  const queryString = mapToQueryString({
    [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: highlightedRowKey,
    [QUERY_PARAM_KEY.EXPANDED_ROW_KEYS]: expandedRowKeys ? JSON.stringify(expandedRowKeys) : undefined,
  });

  return `${generatePath(URI_BUDGETING_DETAILS_PAGE, { objektId, budgetingId })}${tab}${queryString ? `?${queryString}` : ''}`;
};

export const pathsToBudgetingDetailsPage = (
  objektId: string,
  budgetingId: string,
  highlightedRowKey?: string,
  expandedRowKeys?: Record<string, string | string[]>
) => ({
  detailsTab: `${generatePathToBudgetingDetailsPage(objektId, budgetingId, 'details', highlightedRowKey, expandedRowKeys)}`,
  resultsTab: `${generatePathToBudgetingDetailsPage(objektId, budgetingId, 'results', highlightedRowKey, expandedRowKeys)}`,
  documentsTab: `${generatePathToBudgetingDetailsPage(objektId, budgetingId, 'documents', highlightedRowKey, expandedRowKeys)}`,
});
