import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TextbausteinFieldsFragmentDoc } from '../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
export type BelegnummernkreisDefinitionFieldsFragment = {
  belegnummernkreisDefinitionId: string;
  bezeichnung: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  createTs: string;
  jaehrlichNeu: boolean;
  nummernteil: string;
  belegnummernkreisList: Array<{
    belegnummernkreisId: string;
    bezeichnung: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    jahr: number;
    nextBelegnummer: string;
    status: { text: string; value: Types.BelegnummernkreisStatus };
    verwendung: { text: string; value: Types.BelegnummernkreisVerwendung };
    warningList: Array<{ type: string; message: string; attribute?: string | null }>;
  }>;
  prefix?: {
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  } | null;
  postfix?: {
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  } | null;
  status: { value: Types.BelegnummernkreisDefinitionStatus; text: string };
  warningList: Array<{ type: string; message: string; attribute?: string | null }>;
};

export type BelegnummernkreisDefinitionTemplatesFieldsFragment = {
  prefix: {
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  };
  postfix: {
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  };
};

export const BelegnummernkreisDefinitionFieldsFragmentDoc = gql`
  fragment BelegnummernkreisDefinitionFields on BelegnummernkreisDefinition {
    belegnummernkreisDefinitionId
    belegnummernkreisList {
      belegnummernkreisId
      bezeichnung
      createTs
      createdBy
      createdByMitarbeiterId
      jahr
      nextBelegnummer
      status {
        text
        value
      }
      verwendung {
        text
        value
      }
      warningList {
        type
        message
        attribute
      }
    }
    bezeichnung
    createdBy
    createdByMitarbeiterId
    createTs
    createdByMitarbeiterId
    jaehrlichNeu
    nummernteil
    prefix {
      ...TextbausteinFields
    }
    postfix {
      ...TextbausteinFields
    }
    status {
      value
      text
    }
    warningList {
      type
      message
      attribute
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
export const BelegnummernkreisDefinitionTemplatesFieldsFragmentDoc = gql`
  fragment BelegnummernkreisDefinitionTemplatesFields on BelegnummernkreisDefinitionTemplate {
    prefix {
      ...TextbausteinFields
    }
    postfix {
      ...TextbausteinFields
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
