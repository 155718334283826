import * as Yup from 'yup';
import { entityIsRequired } from '../../../../../components/message/validationMsg';

export const budgetingVPosAdjustAufteilungsschluesselFormValidationSchema = Yup.object().shape({
  budgetingVPosId: Yup.string().required(entityIsRequired('Budgeting Vorschreibungposition')),
  vertragLevelAufteilungsschluesselList: Yup.array().of(
    Yup.object().shape({
      aufteilungsschluesselId: Yup.string().required(entityIsRequired('Aufteilungsschlüssel')),
      vertragLevel: Yup.string().required(entityIsRequired('Vertragsebene')),
    })
  ),
});
