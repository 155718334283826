import { FC } from 'react';
import { FormikProps } from 'formik';
import { Select, SelectProps } from 'formik-antd';
import { VstKuerzungVerarbeitungEntryFiltersFormValues } from './ListingFiltersFormMapper';
import { isVstKuerzungBuchungStatusNichtVerbucht } from '../../../../../helpers/statusHelper';
import { isNotNil } from '../../../../../helpers/assertionHelper';
import { getUniqueList } from '../../../../shared/getUniqueList';
import { VorsteuerkuerzungBuchungStatusTuple, VorsteuerkuerzungGenerierlaufEntry } from '../../../../../types';

type VstKuerzungBuchungStatusSelectProps = {
  formikProps: FormikProps<VstKuerzungVerarbeitungEntryFiltersFormValues>;
  entryList: VorsteuerkuerzungGenerierlaufEntry[];
  width?: string;
} & SelectProps;

const VstKuerzungBuchungStatusSelect: FC<VstKuerzungBuchungStatusSelectProps> = ({ formikProps, entryList, width, ...restProps }) => {
  const vstKrzStatusList = entryList.map((entry) => entry.vorsteuerkuerzung?.buchungStatus).filter(isNotNil) as VorsteuerkuerzungBuchungStatusTuple[];

  const uniqueVstKrzStatusList = getUniqueList(statusList(vstKrzStatusList), (a, b) => a.value === b.value);

  return (
    <Select
      id={restProps.name}
      allowClear
      mode="multiple"
      placeholder={restProps.placeholder ?? 'Buchungstatus auswählen'}
      {...restProps}
      style={{ width: width || '100%' }}
    >
      {uniqueVstKrzStatusList.map((buchungStatus) => (
        <Select.Option key={buchungStatus.value} value={buchungStatus.value}>
          {buchungStatus.text}
        </Select.Option>
      ))}
    </Select>
  );
};

const statusList = (data: VorsteuerkuerzungBuchungStatusTuple[] = []) =>
  data.map((buchungStatus) =>
    isVstKuerzungBuchungStatusNichtVerbucht(buchungStatus)
      ? {
          ...buchungStatus,
          text: 'erstellt',
        }
      : buchungStatus
  );

export default VstKuerzungBuchungStatusSelect;
