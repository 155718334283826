import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerFieldsFragmentDoc, RechtstraegerListEntryFragmentDoc } from './RechtsTraegerFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from './RechtsTraegerBaseFragments.types';
import { ContactFieldsFragmentDoc, ContactPersonFieldsFragmentDoc } from '../../Contact/gql/ContactFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RechtstraegerQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechtstraegerQuery = {
  getRechtstraeger: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fibuKontoCreatable: boolean;
      kundennummer: string;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      personenbezugList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        personenbezugId: string;
        tagList: Array<string>;
        person: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerSteuerpflicht?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ message: string; type: string }>;
        }> | null;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
      sprache: { text: string; value: Types.Sprache };
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { value: Types.RechtstraegerType; text: string };
      vertragSummary?: {
        heVertragCount: number;
        heVertragVerwaltungCount: number;
        mietVertragCount: number;
        mietVertragVerwaltungCount: number;
        weSubverwaltungCount: number;
        weVertragCount: number;
        weVertragVerwaltungCount: number;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RechtstraegerListQueryVariables = Types.Exact<{
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  emailAddress?: Types.InputMaybe<Types.Scalars['String']['input']>;
  tagList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  hasBuchungskreis?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  kurzBezeichnung?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kundennummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.RechtstraegerOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  typeList?: Types.InputMaybe<Array<Types.InputMaybe<Types.RechtstraegerType>> | Types.InputMaybe<Types.RechtstraegerType>>;
}>;

export type RechtstraegerListQuery = {
  getRechtstraegerList: {
    data: {
      contentList: Array<{
        buchungskreisId?: string | null;
        city?: string | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        zipCode?: string | null;
        salutation?: { text: string; value: Types.Salutation; letterSalutation: string } | null;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RechtstraegerTypeQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechtstraegerTypeQuery = {
  getRechtstraegerType: {
    data: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ContactPersonChangeHistorListQueryVariables = Types.Exact<{
  contactPersonId: Types.Scalars['ID']['input'];
}>;

export type ContactPersonChangeHistorListQuery = {
  getContactPersonChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type RechtstraegerEmailContactDeletionHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechtstraegerEmailContactDeletionHistoryListQuery = {
  getRechtstraegerEmailContactDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type RechtstraegerPhoneContactDeletionHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechtstraegerPhoneContactDeletionHistoryListQuery = {
  getRechtstraegerPhoneContactDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type RechtstraegerPostofficeBoxAddressDeletionHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechtstraegerPostofficeBoxAddressDeletionHistoryListQuery = {
  getRechtstraegerPostofficeBoxAddressDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type RechtstraegerStreetAddressDeletionHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechtstraegerStreetAddressDeletionHistoryListQuery = {
  getRechtstraegerStreetAddressDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type RechtstraegerAndEmailListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type RechtstraegerAndEmailListQuery = {
  getRechtstraegerAndEmailList: {
    data: Array<{
      rechtstraeger: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      emailContact: {
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RechtstraegerTagListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type RechtstraegerTagListQuery = {
  getRechtstraegerTagList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RechtstraegerKundennummerListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type RechtstraegerKundennummerListQuery = {
  getRechtstraegerKundennummerList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RechtstraegerDocument = gql`
  query Rechtstraeger($rechtstraegerId: ID!) {
    getRechtstraeger(rechtstraegerId: $rechtstraegerId) {
      data {
        ...RechtstraegerFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerFieldsFragmentDoc}
`;
export function useRechtstraegerQuery(
  baseOptions: Apollo.QueryHookOptions<RechtstraegerQuery, RechtstraegerQueryVariables> &
    ({ variables: RechtstraegerQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerQuery, RechtstraegerQueryVariables>(RechtstraegerDocument, options);
}
export function useRechtstraegerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerQuery, RechtstraegerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerQuery, RechtstraegerQueryVariables>(RechtstraegerDocument, options);
}
export function useRechtstraegerSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerQuery, RechtstraegerQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerQuery, RechtstraegerQueryVariables>(RechtstraegerDocument, options);
}
export type RechtstraegerQueryHookResult = ReturnType<typeof useRechtstraegerQuery>;
export type RechtstraegerLazyQueryHookResult = ReturnType<typeof useRechtstraegerLazyQuery>;
export type RechtstraegerSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerSuspenseQuery>;
export type RechtstraegerQueryResult = Apollo.QueryResult<RechtstraegerQuery, RechtstraegerQueryVariables>;
export const RechtstraegerListDocument = gql`
  query RechtstraegerList(
    $createTsFrom: String
    $createTsTo: String
    $emailAddress: String
    $tagList: [String!]
    $erstellerMitarbeiterIdList: [String!]
    $hasBuchungskreis: Boolean
    $includeArchiviert: Boolean
    $kurzBezeichnung: String
    $kundennummer: String
    $order: RechtstraegerOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
    $typeList: [RechtstraegerType]
  ) {
    getRechtstraegerList(
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      emailAddress: $emailAddress
      tagList: $tagList
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      hasBuchungskreis: $hasBuchungskreis
      includeArchiviert: $includeArchiviert
      kurzBezeichnung: $kurzBezeichnung
      kundennummer: $kundennummer
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
      typeList: $typeList
    ) {
      data {
        contentList {
          ...RechtstraegerListEntry
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerListEntryFragmentDoc}
`;
export function useRechtstraegerListQuery(baseOptions?: Apollo.QueryHookOptions<RechtstraegerListQuery, RechtstraegerListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerListQuery, RechtstraegerListQueryVariables>(RechtstraegerListDocument, options);
}
export function useRechtstraegerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerListQuery, RechtstraegerListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerListQuery, RechtstraegerListQueryVariables>(RechtstraegerListDocument, options);
}
export function useRechtstraegerListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerListQuery, RechtstraegerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerListQuery, RechtstraegerListQueryVariables>(RechtstraegerListDocument, options);
}
export type RechtstraegerListQueryHookResult = ReturnType<typeof useRechtstraegerListQuery>;
export type RechtstraegerListLazyQueryHookResult = ReturnType<typeof useRechtstraegerListLazyQuery>;
export type RechtstraegerListSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerListSuspenseQuery>;
export type RechtstraegerListQueryResult = Apollo.QueryResult<RechtstraegerListQuery, RechtstraegerListQueryVariables>;
export const RechtstraegerTypeDocument = gql`
  query RechtstraegerType($rechtstraegerId: ID!) {
    getRechtstraegerType(rechtstraegerId: $rechtstraegerId) {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRechtstraegerTypeQuery(
  baseOptions: Apollo.QueryHookOptions<RechtstraegerTypeQuery, RechtstraegerTypeQueryVariables> &
    ({ variables: RechtstraegerTypeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerTypeQuery, RechtstraegerTypeQueryVariables>(RechtstraegerTypeDocument, options);
}
export function useRechtstraegerTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerTypeQuery, RechtstraegerTypeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerTypeQuery, RechtstraegerTypeQueryVariables>(RechtstraegerTypeDocument, options);
}
export function useRechtstraegerTypeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerTypeQuery, RechtstraegerTypeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerTypeQuery, RechtstraegerTypeQueryVariables>(RechtstraegerTypeDocument, options);
}
export type RechtstraegerTypeQueryHookResult = ReturnType<typeof useRechtstraegerTypeQuery>;
export type RechtstraegerTypeLazyQueryHookResult = ReturnType<typeof useRechtstraegerTypeLazyQuery>;
export type RechtstraegerTypeSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerTypeSuspenseQuery>;
export type RechtstraegerTypeQueryResult = Apollo.QueryResult<RechtstraegerTypeQuery, RechtstraegerTypeQueryVariables>;
export const ContactPersonChangeHistorListDocument = gql`
  query ContactPersonChangeHistorList($contactPersonId: ID!) {
    getContactPersonChangeHistoryList(contactPersonId: $contactPersonId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useContactPersonChangeHistorListQuery(
  baseOptions: Apollo.QueryHookOptions<ContactPersonChangeHistorListQuery, ContactPersonChangeHistorListQueryVariables> &
    ({ variables: ContactPersonChangeHistorListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactPersonChangeHistorListQuery, ContactPersonChangeHistorListQueryVariables>(
    ContactPersonChangeHistorListDocument,
    options
  );
}
export function useContactPersonChangeHistorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContactPersonChangeHistorListQuery, ContactPersonChangeHistorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactPersonChangeHistorListQuery, ContactPersonChangeHistorListQueryVariables>(
    ContactPersonChangeHistorListDocument,
    options
  );
}
export function useContactPersonChangeHistorListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ContactPersonChangeHistorListQuery, ContactPersonChangeHistorListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContactPersonChangeHistorListQuery, ContactPersonChangeHistorListQueryVariables>(
    ContactPersonChangeHistorListDocument,
    options
  );
}
export type ContactPersonChangeHistorListQueryHookResult = ReturnType<typeof useContactPersonChangeHistorListQuery>;
export type ContactPersonChangeHistorListLazyQueryHookResult = ReturnType<typeof useContactPersonChangeHistorListLazyQuery>;
export type ContactPersonChangeHistorListSuspenseQueryHookResult = ReturnType<typeof useContactPersonChangeHistorListSuspenseQuery>;
export type ContactPersonChangeHistorListQueryResult = Apollo.QueryResult<
  ContactPersonChangeHistorListQuery,
  ContactPersonChangeHistorListQueryVariables
>;
export const RechtstraegerEmailContactDeletionHistoryListDocument = gql`
  query RechtstraegerEmailContactDeletionHistoryList($rechtstraegerId: ID!) {
    getRechtstraegerEmailContactDeletionHistoryList(rechtstraegerId: $rechtstraegerId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useRechtstraegerEmailContactDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    RechtstraegerEmailContactDeletionHistoryListQuery,
    RechtstraegerEmailContactDeletionHistoryListQueryVariables
  > &
    ({ variables: RechtstraegerEmailContactDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerEmailContactDeletionHistoryListQuery, RechtstraegerEmailContactDeletionHistoryListQueryVariables>(
    RechtstraegerEmailContactDeletionHistoryListDocument,
    options
  );
}
export function useRechtstraegerEmailContactDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RechtstraegerEmailContactDeletionHistoryListQuery,
    RechtstraegerEmailContactDeletionHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerEmailContactDeletionHistoryListQuery, RechtstraegerEmailContactDeletionHistoryListQueryVariables>(
    RechtstraegerEmailContactDeletionHistoryListDocument,
    options
  );
}
export function useRechtstraegerEmailContactDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RechtstraegerEmailContactDeletionHistoryListQuery, RechtstraegerEmailContactDeletionHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerEmailContactDeletionHistoryListQuery, RechtstraegerEmailContactDeletionHistoryListQueryVariables>(
    RechtstraegerEmailContactDeletionHistoryListDocument,
    options
  );
}
export type RechtstraegerEmailContactDeletionHistoryListQueryHookResult = ReturnType<typeof useRechtstraegerEmailContactDeletionHistoryListQuery>;
export type RechtstraegerEmailContactDeletionHistoryListLazyQueryHookResult = ReturnType<
  typeof useRechtstraegerEmailContactDeletionHistoryListLazyQuery
>;
export type RechtstraegerEmailContactDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useRechtstraegerEmailContactDeletionHistoryListSuspenseQuery
>;
export type RechtstraegerEmailContactDeletionHistoryListQueryResult = Apollo.QueryResult<
  RechtstraegerEmailContactDeletionHistoryListQuery,
  RechtstraegerEmailContactDeletionHistoryListQueryVariables
>;
export const RechtstraegerPhoneContactDeletionHistoryListDocument = gql`
  query RechtstraegerPhoneContactDeletionHistoryList($rechtstraegerId: ID!) {
    getRechtstraegerPhoneContactDeletionHistoryList(rechtstraegerId: $rechtstraegerId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useRechtstraegerPhoneContactDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    RechtstraegerPhoneContactDeletionHistoryListQuery,
    RechtstraegerPhoneContactDeletionHistoryListQueryVariables
  > &
    ({ variables: RechtstraegerPhoneContactDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerPhoneContactDeletionHistoryListQuery, RechtstraegerPhoneContactDeletionHistoryListQueryVariables>(
    RechtstraegerPhoneContactDeletionHistoryListDocument,
    options
  );
}
export function useRechtstraegerPhoneContactDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RechtstraegerPhoneContactDeletionHistoryListQuery,
    RechtstraegerPhoneContactDeletionHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerPhoneContactDeletionHistoryListQuery, RechtstraegerPhoneContactDeletionHistoryListQueryVariables>(
    RechtstraegerPhoneContactDeletionHistoryListDocument,
    options
  );
}
export function useRechtstraegerPhoneContactDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RechtstraegerPhoneContactDeletionHistoryListQuery, RechtstraegerPhoneContactDeletionHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerPhoneContactDeletionHistoryListQuery, RechtstraegerPhoneContactDeletionHistoryListQueryVariables>(
    RechtstraegerPhoneContactDeletionHistoryListDocument,
    options
  );
}
export type RechtstraegerPhoneContactDeletionHistoryListQueryHookResult = ReturnType<typeof useRechtstraegerPhoneContactDeletionHistoryListQuery>;
export type RechtstraegerPhoneContactDeletionHistoryListLazyQueryHookResult = ReturnType<
  typeof useRechtstraegerPhoneContactDeletionHistoryListLazyQuery
>;
export type RechtstraegerPhoneContactDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useRechtstraegerPhoneContactDeletionHistoryListSuspenseQuery
>;
export type RechtstraegerPhoneContactDeletionHistoryListQueryResult = Apollo.QueryResult<
  RechtstraegerPhoneContactDeletionHistoryListQuery,
  RechtstraegerPhoneContactDeletionHistoryListQueryVariables
>;
export const RechtstraegerPostofficeBoxAddressDeletionHistoryListDocument = gql`
  query RechtstraegerPostofficeBoxAddressDeletionHistoryList($rechtstraegerId: ID!) {
    getRechtstraegerPostofficeBoxAddressDeletionHistoryList(rechtstraegerId: $rechtstraegerId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useRechtstraegerPostofficeBoxAddressDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    RechtstraegerPostofficeBoxAddressDeletionHistoryListQuery,
    RechtstraegerPostofficeBoxAddressDeletionHistoryListQueryVariables
  > &
    ({ variables: RechtstraegerPostofficeBoxAddressDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RechtstraegerPostofficeBoxAddressDeletionHistoryListQuery,
    RechtstraegerPostofficeBoxAddressDeletionHistoryListQueryVariables
  >(RechtstraegerPostofficeBoxAddressDeletionHistoryListDocument, options);
}
export function useRechtstraegerPostofficeBoxAddressDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RechtstraegerPostofficeBoxAddressDeletionHistoryListQuery,
    RechtstraegerPostofficeBoxAddressDeletionHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RechtstraegerPostofficeBoxAddressDeletionHistoryListQuery,
    RechtstraegerPostofficeBoxAddressDeletionHistoryListQueryVariables
  >(RechtstraegerPostofficeBoxAddressDeletionHistoryListDocument, options);
}
export function useRechtstraegerPostofficeBoxAddressDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RechtstraegerPostofficeBoxAddressDeletionHistoryListQuery,
        RechtstraegerPostofficeBoxAddressDeletionHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RechtstraegerPostofficeBoxAddressDeletionHistoryListQuery,
    RechtstraegerPostofficeBoxAddressDeletionHistoryListQueryVariables
  >(RechtstraegerPostofficeBoxAddressDeletionHistoryListDocument, options);
}
export type RechtstraegerPostofficeBoxAddressDeletionHistoryListQueryHookResult = ReturnType<
  typeof useRechtstraegerPostofficeBoxAddressDeletionHistoryListQuery
>;
export type RechtstraegerPostofficeBoxAddressDeletionHistoryListLazyQueryHookResult = ReturnType<
  typeof useRechtstraegerPostofficeBoxAddressDeletionHistoryListLazyQuery
>;
export type RechtstraegerPostofficeBoxAddressDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useRechtstraegerPostofficeBoxAddressDeletionHistoryListSuspenseQuery
>;
export type RechtstraegerPostofficeBoxAddressDeletionHistoryListQueryResult = Apollo.QueryResult<
  RechtstraegerPostofficeBoxAddressDeletionHistoryListQuery,
  RechtstraegerPostofficeBoxAddressDeletionHistoryListQueryVariables
>;
export const RechtstraegerStreetAddressDeletionHistoryListDocument = gql`
  query RechtstraegerStreetAddressDeletionHistoryList($rechtstraegerId: ID!) {
    getRechtstraegerStreetAddressDeletionHistoryList(rechtstraegerId: $rechtstraegerId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useRechtstraegerStreetAddressDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    RechtstraegerStreetAddressDeletionHistoryListQuery,
    RechtstraegerStreetAddressDeletionHistoryListQueryVariables
  > &
    ({ variables: RechtstraegerStreetAddressDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerStreetAddressDeletionHistoryListQuery, RechtstraegerStreetAddressDeletionHistoryListQueryVariables>(
    RechtstraegerStreetAddressDeletionHistoryListDocument,
    options
  );
}
export function useRechtstraegerStreetAddressDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RechtstraegerStreetAddressDeletionHistoryListQuery,
    RechtstraegerStreetAddressDeletionHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerStreetAddressDeletionHistoryListQuery, RechtstraegerStreetAddressDeletionHistoryListQueryVariables>(
    RechtstraegerStreetAddressDeletionHistoryListDocument,
    options
  );
}
export function useRechtstraegerStreetAddressDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RechtstraegerStreetAddressDeletionHistoryListQuery, RechtstraegerStreetAddressDeletionHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerStreetAddressDeletionHistoryListQuery, RechtstraegerStreetAddressDeletionHistoryListQueryVariables>(
    RechtstraegerStreetAddressDeletionHistoryListDocument,
    options
  );
}
export type RechtstraegerStreetAddressDeletionHistoryListQueryHookResult = ReturnType<typeof useRechtstraegerStreetAddressDeletionHistoryListQuery>;
export type RechtstraegerStreetAddressDeletionHistoryListLazyQueryHookResult = ReturnType<
  typeof useRechtstraegerStreetAddressDeletionHistoryListLazyQuery
>;
export type RechtstraegerStreetAddressDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useRechtstraegerStreetAddressDeletionHistoryListSuspenseQuery
>;
export type RechtstraegerStreetAddressDeletionHistoryListQueryResult = Apollo.QueryResult<
  RechtstraegerStreetAddressDeletionHistoryListQuery,
  RechtstraegerStreetAddressDeletionHistoryListQueryVariables
>;
export const RechtstraegerAndEmailListDocument = gql`
  query RechtstraegerAndEmailList {
    getRechtstraegerAndEmailList {
      data {
        rechtstraeger {
          ...RechtstraegerBaseFields
        }
        emailContact {
          ...ContactFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
  ${ContactFieldsFragmentDoc}
`;
export function useRechtstraegerAndEmailListQuery(
  baseOptions?: Apollo.QueryHookOptions<RechtstraegerAndEmailListQuery, RechtstraegerAndEmailListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerAndEmailListQuery, RechtstraegerAndEmailListQueryVariables>(RechtstraegerAndEmailListDocument, options);
}
export function useRechtstraegerAndEmailListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerAndEmailListQuery, RechtstraegerAndEmailListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerAndEmailListQuery, RechtstraegerAndEmailListQueryVariables>(RechtstraegerAndEmailListDocument, options);
}
export function useRechtstraegerAndEmailListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerAndEmailListQuery, RechtstraegerAndEmailListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerAndEmailListQuery, RechtstraegerAndEmailListQueryVariables>(RechtstraegerAndEmailListDocument, options);
}
export type RechtstraegerAndEmailListQueryHookResult = ReturnType<typeof useRechtstraegerAndEmailListQuery>;
export type RechtstraegerAndEmailListLazyQueryHookResult = ReturnType<typeof useRechtstraegerAndEmailListLazyQuery>;
export type RechtstraegerAndEmailListSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerAndEmailListSuspenseQuery>;
export type RechtstraegerAndEmailListQueryResult = Apollo.QueryResult<RechtstraegerAndEmailListQuery, RechtstraegerAndEmailListQueryVariables>;
export const RechtstraegerTagListDocument = gql`
  query RechtstraegerTagList {
    getRechtstraegerTagList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRechtstraegerTagListQuery(baseOptions?: Apollo.QueryHookOptions<RechtstraegerTagListQuery, RechtstraegerTagListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerTagListQuery, RechtstraegerTagListQueryVariables>(RechtstraegerTagListDocument, options);
}
export function useRechtstraegerTagListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerTagListQuery, RechtstraegerTagListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerTagListQuery, RechtstraegerTagListQueryVariables>(RechtstraegerTagListDocument, options);
}
export function useRechtstraegerTagListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerTagListQuery, RechtstraegerTagListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerTagListQuery, RechtstraegerTagListQueryVariables>(RechtstraegerTagListDocument, options);
}
export type RechtstraegerTagListQueryHookResult = ReturnType<typeof useRechtstraegerTagListQuery>;
export type RechtstraegerTagListLazyQueryHookResult = ReturnType<typeof useRechtstraegerTagListLazyQuery>;
export type RechtstraegerTagListSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerTagListSuspenseQuery>;
export type RechtstraegerTagListQueryResult = Apollo.QueryResult<RechtstraegerTagListQuery, RechtstraegerTagListQueryVariables>;
export const RechtstraegerKundennummerListDocument = gql`
  query RechtstraegerKundennummerList {
    getRechtstraegerKundennummerList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRechtstraegerKundennummerListQuery(
  baseOptions?: Apollo.QueryHookOptions<RechtstraegerKundennummerListQuery, RechtstraegerKundennummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerKundennummerListQuery, RechtstraegerKundennummerListQueryVariables>(
    RechtstraegerKundennummerListDocument,
    options
  );
}
export function useRechtstraegerKundennummerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerKundennummerListQuery, RechtstraegerKundennummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerKundennummerListQuery, RechtstraegerKundennummerListQueryVariables>(
    RechtstraegerKundennummerListDocument,
    options
  );
}
export function useRechtstraegerKundennummerListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerKundennummerListQuery, RechtstraegerKundennummerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerKundennummerListQuery, RechtstraegerKundennummerListQueryVariables>(
    RechtstraegerKundennummerListDocument,
    options
  );
}
export type RechtstraegerKundennummerListQueryHookResult = ReturnType<typeof useRechtstraegerKundennummerListQuery>;
export type RechtstraegerKundennummerListLazyQueryHookResult = ReturnType<typeof useRechtstraegerKundennummerListLazyQuery>;
export type RechtstraegerKundennummerListSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerKundennummerListSuspenseQuery>;
export type RechtstraegerKundennummerListQueryResult = Apollo.QueryResult<
  RechtstraegerKundennummerListQuery,
  RechtstraegerKundennummerListQueryVariables
>;
