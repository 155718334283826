import React, { FC } from 'react';
import { Document, Page, View } from '@react-pdf/renderer';
import pdfStyles from '../../styles/pdfStyles';
import Header from '../../shared/Header';
import { isNotNil } from '../../helpers/assertionHelper';
import ObjektZahlungsrueckstaendeTable from '../../shared/ObjektZahlungsrueckstaendeTable/ObjektZahlungsrueckstaendeTable';
import InfoAbrechnungskreisBlock from '../../shared/Abrechnungskreis/InfoAbrechnungskreisBlock';
import Footer from '../../shared/Footer';
import { TSubAdministrationAbrechnungObjekt } from './subAdministrationAbrechnungObjekt-types';
import Ergebnistext from '../../shared/Ergebnistext';
import Einleitungstext from '../../shared/Einleitungstext';
import Schlusstext from '../../shared/Schlusstext';
import SubAdminAbrAdressorAndDateBlock from '../shared/SubAdminAbrAdressorAndDateBlock';
import SubAdminAbrSummary from '../shared/SubAdminAbrSummary';
import SubAdminAbrObjDetailsBlock from './components/SubAdminAbrObjDetailsBlock';

type Props = {
  data: TSubAdministrationAbrechnungObjekt;
  isInEditMode?: boolean;
  selectedTextbaustein?: SubAdministrationObjAbrechnungSelectableSection;
};

export type SubAdministrationObjAbrechnungSelectableSection = 'EINLEITUNGSTEXT' | 'ERGEBNISTEXT' | 'SCHLUSSTEXT';

const SubAdministrationAbrechnungObjektTemplate: FC<Props> = ({ data, isInEditMode, selectedTextbaustein }) => (
  <Document>
    <Page size="A4" style={[pdfStyles.body, { paddingBottom: data.footer?.value ? '32mm' : pdfStyles.body.paddingBottom }]}>
      <View>
        {/* header */}
        <Header header={data.header} />
      </View>

      {/* HauseigentuemerBlock: (Absender + Rechnungsempfänger + Kunden-Nr + Anteile) + Datum + City */}
      <SubAdminAbrAdressorAndDateBlock
        absender={data.absender}
        rechnungsEmpfaenger={data.eigentuemer}
        abrechnungsDatumLine={data.abrechnungsDatumLine}
      />

      {/* Einleitungstext with Textbaustein */}
      {isNotNil(data.einleitungstext) ? (
        <Einleitungstext text={data.einleitungstext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      {/* Sub-Admin-Obj-Abrechnung Details Block */}
      <SubAdminAbrObjDetailsBlock data={data} />

      {/* Summary */}
      <SubAdminAbrSummary endErgebnis={data.endErgebnis} />

      {/* Objekt Zahlungsrueckstände Table */}
      {data.objektZahlungsrueckstaendeBlock ? <ObjektZahlungsrueckstaendeTable data={data.objektZahlungsrueckstaendeBlock} /> : null}

      {/* Ergebnistext with Textbaustein */}
      {isNotNil(data.ergebnistext) ? (
        <Ergebnistext text={data.ergebnistext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      {/* Schlusstext with Textbaustein */}
      {isNotNil(data.schlusstext) ? (
        <Schlusstext text={data.schlusstext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      {/* InfoAbrechnungskreisblock */}
      {data.informationAbrechnungskreiseBlock && data.informationAbrechnungskreiseBlock.abrechnungskreisList.length > 0 && (
        <InfoAbrechnungskreisBlock informationAbrechnungskreiseBlock={data.informationAbrechnungskreiseBlock} />
      )}

      {/* Footer */}
      {data.footer?.value && <Footer footer={data.footer} />}
    </Page>
  </Document>
);

export default SubAdministrationAbrechnungObjektTemplate;
