import { FC } from 'react';
import { useToggle } from '../../../../hooks/useToggle';
import { PostIt, PostItInput } from '../../../../types';
import { showSuccessMsgCreate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import PostItIconAndCreateMode from '../../../../components/PostIt/PostItIconAndCreateMode';
import PostItIconAndViewAndUpdateMode from '../../../../components/PostIt/PostItIconAndViewAndUpdateMode';
import { useCreateHeVertragPostItMutation } from '../gql/HeVertragMutations.types';

type Props = {
  objektId: string;
  heVertragId: string;
  postIt?: PostIt | null;
  onSuccess: () => void;
};

const HeVertragPostIt: FC<Props> = ({ objektId, heVertragId, postIt, onSuccess }) => {
  const [isPostItOpen, toggleIsPostItOpen] = useToggle(false);

  const [runCreate] = useCreateHeVertragPostItMutation({
    onCompleted: () => {
      showSuccessMsgCreate('Post-It');
      toggleIsPostItOpen();
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleCreateSubmit = (values: PostItInput) => {
    runCreate({
      variables: {
        objektId,
        heVertragId,
        input: {
          immerAnzeigen: values.immerAnzeigen,
          titel: values.titel,
          text: values.text,
        },
      },
    });
  };

  const handleCreateCancel = () => {
    toggleIsPostItOpen();
  };

  return postIt ? (
    <PostItIconAndViewAndUpdateMode postIt={postIt} refresh={onSuccess} />
  ) : (
    <PostItIconAndCreateMode
      isPostItOpen={isPostItOpen}
      onIconClick={toggleIsPostItOpen}
      onFormCancel={handleCreateCancel}
      onFormSubmit={handleCreateSubmit}
    />
  );
};

export default HeVertragPostIt;
