import { uriObjekt } from '../Objekt/ObjektApiPaths';

export const uriZinslisten = (firmendatenId: string, objektId: string) => `${uriObjekt(firmendatenId, objektId)}/zinslisten`;

export const uriZinsliste = (firmendatenId: string, objektId: string, zinslisteId: string) =>
  `${uriZinslisten(firmendatenId, objektId)}/${zinslisteId}`;

export const uriZinslisteExcelExport = (firmendatenId: string, objektId: string, zinslisteId: string) =>
  `${uriZinsliste(firmendatenId, objektId, zinslisteId)}/xls`;

export const uriZinslistePdfExport = (firmendatenId: string, objektId: string, zinslisteId: string) =>
  `${uriZinsliste(firmendatenId, objektId, zinslisteId)}/pdf`;
