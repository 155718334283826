import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { OrderGenerierlaufType } from '../../../../../types';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useFilterGenLaufOrderFakturierungsperiodeListQuery } from '../../../gql/GenerierlaufFilter/OrderGenerierlaufFilterQueries.types';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: OrderGenerierlaufType;
} & SelectProps;

const GenerierlaufFakturierungsperiodeFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufOrderFakturierungsperiodeListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufOrderFakturierungsperiodeList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Fakturierungsperiode auswählen"
      {...restProps}
    >
      {list.map((fakturierungsperiode) => {
        const value = dayjsCustom()
          .month(fakturierungsperiode.monat - 1) // month is zero based, january = 0 in dayjs
          .year(fakturierungsperiode.jahr)
          .format('YYYY-MM');
        const text = `${fakturierungsperiode.monat}/${fakturierungsperiode.jahr}`;
        return (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default GenerierlaufFakturierungsperiodeFormSelect;
