import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import BookingSuggestionBuchungsmaskePage from './BookingSuggestionBuchungsmaskePage';
import { URI_DASHBOARD_BOOKING_SUGGESTION_BUCHUNGSMASKE_PAGE } from '../../features/BookingSuggestion/bookingSuggestionBuchungsmaskePaths';

export type BookingSuggestionBuchnungsmaskePageRouteParams = { bookingSuggestionId: string };

const bookingSuggestionBuchungsmaskePageRoute = (
  <Route
    path={URI_DASHBOARD_BOOKING_SUGGESTION_BUCHUNGSMASKE_PAGE}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BookingSuggestionBuchungsmaskePage />} />}
  />
);

export default bookingSuggestionBuchungsmaskePageRoute;
