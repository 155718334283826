import { FC, useEffect } from 'react';
import { useBlocker } from 'react-router';
import { Modal, ModalProps } from 'antd';

type RouterPromptProps = {
  when?: boolean;
  message?: string;
} & Pick<ModalProps, 'title'>;

const TITLE_DEFAULT = 'Nicht gespeicherte Änderungen';
const MSG_DEFAULT = 'Möchten Sie die Seite verlassen ohne die Daten zu speichern?';

const RouterPrompt: FC<RouterPromptProps> = ({ when, title = TITLE_DEFAULT, message = MSG_DEFAULT }) => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => !!when && currentLocation.pathname !== nextLocation.pathname);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      Modal.confirm({
        title,
        content: message,
        onOk: blocker.proceed,
        okText: 'Ja',
        onCancel: blocker.reset,
        cancelText: 'Nein',
        closable: true,
      });
    }
  }, [blocker, title, message]);

  return null;
};

export default RouterPrompt;
