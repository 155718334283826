import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Card, Divider, Dropdown, Modal } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import EmailVerified from '../../shared/components/Email/EmailVerified';
import UserUpdateForm from './UserUpdateForm';
import EmailUpdateForm from '../../shared/components/Email/EmailUpdateForm';
import { useToggle } from '../../hooks/useToggle';
import CardTitle from '../../components/Card/CardTitle';

const { Meta } = Card;

// TODO: Passwort zurücksetzen

const menuItems = (onCollapseEnitityUpdate, onCollapseEmailUpdate) => {
  return [
    {
      key: '1',
      label: 'Benutzer bearbeiten',
      onClick: onCollapseEnitityUpdate,
    },
    {
      key: '2',
      label: 'Passwort zurücksetzen',
    },
    {
      key: '3',
      label: 'E-Mail-Adresse ändern',
      onClick: onCollapseEmailUpdate,
      danger: true,
    },
  ];
};

// TODO: confirmLoading={confirmLoading} async

const UserCard = ({ user }) => {
  const [isCollapsedEnitityUpdate, onCollapseEnitityUpdate] = useToggle();
  const [isCollapsedEmailUpdate, onCollapseEmailUpdate] = useToggle();

  return (
    <Card
      title={<CardTitle title={user.title} status={user.status} />}
      extra={
        <Dropdown menu={{ items: menuItems(onCollapseEnitityUpdate, onCollapseEmailUpdate) }}>
          <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
        </Dropdown>
      }
    >
      <Modal
        title="Profil bearbeiten"
        open={isCollapsedEnitityUpdate}
        onCancel={onCollapseEnitityUpdate}
        footer={null}
        destroyOnClose
        maskClosable={false}
      >
        <UserUpdateForm user={user} onSubmit={() => onCollapseEnitityUpdate} onCancel={onCollapseEnitityUpdate} />
      </Modal>
      <Modal
        title="E-Mail-Adresse ändern"
        open={isCollapsedEmailUpdate}
        onCancel={onCollapseEmailUpdate}
        footer={null}
        destroyOnClose
        maskClosable={false}
      >
        <EmailUpdateForm
          formInitialValues={{
            email: '',
            currentEmail: user.email,
          }}
          onSubmit={() => onCollapseEmailUpdate}
          onCancel={onCollapseEmailUpdate}
        />
      </Modal>
      <Meta
        avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
        title={user.username}
        description={user.status.text}
      />
      <Divider dashed />
      <b>Username:</b> {user.username}
      <br />
      <b>Name:</b> {user.firstname ? `${user.firstname} ${user.lastname}` : 'Vorname Nachname nicht angegeben'}
      <br />
      <b>E-Mail:</b> <EmailVerified email={user.email} emailUnverified={user.emailUnverified} />
      <br />
      <b>Status:</b> {user.status.text}
      <br />
    </Card>
  );
};

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserCard;
