import React, { FC } from 'react';
import { Empty, Typography } from 'antd';
import { Budgeting, BudgetingResultBestandseinheit, BudgetingResult } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import budgetingResultBestandseinheitTableColumns from './budgetingResultBestandseinheitTableColumns';
import BudgetingResultVertragTable from '../Level2/BudgetingResultVertragTable';
import { Spacer } from '../../../../../components/Grid';
import BudgetingResultWarnings from './BudgetingResultWarnings';

type Props = {
  budgeting: Budgeting;
  budgetingResult: BudgetingResult | undefined;
  loading: boolean;
};

const BudgetingResultBestandseinheitTable: FC<Props> = ({ budgeting, budgetingResult, loading }) => {
  const budgetingResultBestandseinheitList = budgetingResult?.budgetingResultBestandseinheitList ?? [];

  const vertragVposWertWarningList = budgetingResult
    ? budgetingResult.budgetingResultBestandseinheitList.flatMap((bestandseinheit) =>
        bestandseinheit.budgetingResultVertragList.flatMap((vertrag) =>
          vertrag.budgetingResultVertragVPosWertList
            .filter((vertragVPosWert) => vertragVPosWert.warning)
            .map((vertragVPosWert) => ({
              bestandseinheitBezeichnung: bestandseinheit.bestandseinheit.bezeichnung,
              bestandseinheitId: bestandseinheit.bestandseinheit.bestandseinheitId,
              rechtstraegerId: vertrag.vertrag.vertragspartner.rechtstraegerId,
              vertragspartnerKurzBezeichnung: vertrag.vertrag.vertragspartner.kurzBezeichnung,
              vertragsArt: vertrag.vertrag.vertragsart,
              vPosBezeichnung: vertragVPosWert.vertragVorschreibungsposition.bezeichnung,
              warning: vertragVPosWert.warning,
            }))
        )
      )
    : [];

  const warningList = budgetingResult?.warningList ?? [];

  return (
    <>
      {(warningList.length > 0 || vertragVposWertWarningList.length > 0) && (
        <>
          <BudgetingResultWarnings warningList={warningList} vertragVposWertWarningList={vertragVposWertWarningList} loading={loading} />
          <Spacer height={16} />
          <Typography.Title level={5}>Erstellte Vertragswerte</Typography.Title>
        </>
      )}
      <Spacer height={8} />
      <NestedTable<BudgetingResultBestandseinheit>
        colWidth={100}
        loading={loading}
        level={1}
        locale={{
          emptyText: <Empty description={<span>Keine Vertragswerte vorhanden</span>} />,
        }}
        dataSource={budgetingResultBestandseinheitList}
        columns={budgetingResultBestandseinheitTableColumns(budgeting)}
        rowKey={(record) => record.bestandseinheit.bestandseinheitId}
        useHighlighting
        pagination={false}
        expandedRowKey="bestandseinheitId"
        expandable={{
          rowExpandable: (record) => !!record.budgetingResultVertragList.length,
          expandedRowRender: (record) => <BudgetingResultVertragTable budgeting={budgeting} budgetingResultBestandseiheit={record} />,
        }}
      />
    </>
  );
};
export default BudgetingResultBestandseinheitTable;
