import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { Input } from 'formik-antd';
import { FieldArrayInputRowProps } from './FieldArrayInputProps';
import ButtonsToRemoveOrAddTextInput from './ButtonsToRemoveOrAddTextInput';
import FormItemWithoutColon from '../Form/FormItemWithoutColon';

export const FieldArrayInputRow: FC<FieldArrayInputRowProps> = ({ name, validate, label, placeholder, isAddAllowed, add, remove }) => (
  <Row gutter={8} align="middle">
    <Col span={20}>
      <FormItemWithoutColon name={name} label={label}>
        <Input id={name} name={name} validate={validate} placeholder={placeholder} />
      </FormItemWithoutColon>
    </Col>
    <Col span={4}>
      <ButtonsToRemoveOrAddTextInput isAddAllowed={isAddAllowed} add={add} remove={remove} />
    </Col>
  </Row>
);

export default FieldArrayInputRow;
