import { FC } from 'react';
import { FormikProps } from 'formik';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { HeAbrechnungVerarbeitungEntryFiltersFormValues, heAbrechnungVerarbEntryListingFiltersFormFields } from './ListingFiltersFormMapper';
import FiltersWithMax3Fields from '../../../../../components/Filters/FiltersWithMax3Fields';
import { AppGenerierlaufType } from '../../../../../types';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/App/GenerierlaufObjektFormSelect';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/App/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufHauseigentuemerFormSelect from '../../../shared/Filters/App/GenerierlaufHauseigentuemerFormSelect';

type Props = {
  formikProps: FormikProps<HeAbrechnungVerarbeitungEntryFiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => (
  <FiltersWithMax3Fields
    filters={
      <>
        <FormItemWithoutColon name={heAbrechnungVerarbEntryListingFiltersFormFields.objektId} label="Objekt">
          <GenerierlaufObjektFormSelect
            name={heAbrechnungVerarbEntryListingFiltersFormFields.objektId}
            generierlaufId={generierlaufId}
            generierlaufType={AppGenerierlaufType.HeAbrechnungErstellung}
            onChange={formikProps.submitForm}
            style={{ width: '200px' }}
          />
        </FormItemWithoutColon>
        <FormItemWithoutColon name={heAbrechnungVerarbEntryListingFiltersFormFields.hauseigentuemerId} label="Hauseigentümer">
          <GenerierlaufHauseigentuemerFormSelect
            name={heAbrechnungVerarbEntryListingFiltersFormFields.hauseigentuemerId}
            generierlaufId={generierlaufId}
            generierlaufType={AppGenerierlaufType.HeAbrechnungErstellung}
            onChange={formikProps.submitForm}
            style={{ width: '200px' }}
          />
        </FormItemWithoutColon>
        <FormItemWithoutColon name={heAbrechnungVerarbEntryListingFiltersFormFields.exitCodeList} label="Status">
          <GenerierlaufEntryExitCodeListFormSelect
            name={heAbrechnungVerarbEntryListingFiltersFormFields.exitCodeList}
            generierlaufId={generierlaufId}
            generierlaufType={AppGenerierlaufType.HeAbrechnungErstellung}
            onChange={formikProps.submitForm}
            style={{ width: '200px' }}
          />
        </FormItemWithoutColon>
      </>
    }
  />
);

export default ListingFilters;
