import React, { FC } from 'react';
import { Col, Row, Space, Switch, Typography } from 'antd';
import { useUpdateFibuEinstellungMutation } from './gql/FibuEinstellungMutations.types';
import { showSuccessMsgUpdate } from '../../components/message';

type Props = {
  negierteHabenBetraege: boolean;
  onSuccess: () => void;
};

const NegierteHabenBetraege: FC<Props> = ({ negierteHabenBetraege, onSuccess }) => {
  const [runUpdateFibuEinstellung] = useUpdateFibuEinstellungMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Allgemeine Einstellungen');
      onSuccess();
    },
  });

  return (
    <Row>
      <Col span={4}>
        <Typography.Text>Negierte Haben Beträge</Typography.Text>
      </Col>
      <Col span={14}>
        <Space>
          <Switch
            defaultChecked={negierteHabenBetraege}
            title="Negierte Haben Beträge"
            onChange={(checked) => {
              runUpdateFibuEinstellung({
                variables: {
                  request: { negierteHabenBetraege: checked },
                },
              });
            }}
            size="small"
          />
          <Typography.Text>{negierteHabenBetraege ? 'Aktiviert' : 'Deaktiviert'}</Typography.Text>
        </Space>
      </Col>
    </Row>
  );
};

export default NegierteHabenBetraege;
