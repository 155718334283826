import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../styles/pdfStyles';
import { AbrechnungskreisDetailsAbrechnungskreisKonto } from '../../../../../topAbrechnung-types';

const TAAbrKreisRepFondsKontoList: FC<{
  kontoListRepFonds: AbrechnungskreisDetailsAbrechnungskreisKonto[];
}> = ({ kontoListRepFonds }) => (
  <View>
    {kontoListRepFonds.map((konto, index) => (
      <View style={pdfStyles.row} key={index}>
        <Text style={[pdfStyles.textBulletin, { width: '40%' }]}>{konto.bezeichnung}</Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              textAlign: 'right',
              width: '20%',
            },
          ]}
        >
          {konto.betragNetto}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              textAlign: 'right',
              width: '20%',
            },
          ]}
        >
          {konto.betragUst}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              textAlign: 'right',
              width: '20%',
            },
          ]}
        >
          {konto.betragBelastung}
        </Text>
      </View>
    ))}
  </View>
);

export default TAAbrKreisRepFondsKontoList;
