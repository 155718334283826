import { Dayjs } from 'dayjs';
import { FormFields } from '../../../../../../../../../helpers/formikHelper';
import {
  Month,
  ThresholdType,
  ValuePreservationType,
  VertragVposIndexationAgreementIndexAnnual,
  VertragVposIndexationAgreementIndexAnnualCreateInput,
  VertragVposIndexationAgreementIndexAnnualUpdateInput,
} from '../../../../../../../../../types';
import { mapDateFormValueToMonthYear, mapMonthYearToDateFormat } from '../indexationAgreementHelpers';

export interface IndexationAgreementIndexAnnualFormValues {
  annualAverage: boolean;
  effectiveWithMonth?: Month;
  indexOld?: number | null;
  indexSeriesId: string;
  minimumLimit?: number | null;
  monthOfIndexPoint?: Month;
  monthYearOld: Dayjs | string;
  shareInPercentToApply?: number | null;
  subsequentBilling: boolean;
  subsequentBillingObjektVorschreibungspositionId?: string;
  threshold: number;
  thresholdType: ThresholdType;
  type: ValuePreservationType;
  validFrom: Dayjs | string;
}

export const indAgreeIndexAnnualFormFields: FormFields<IndexationAgreementIndexAnnualFormValues> = {
  annualAverage: 'annualAverage',
  effectiveWithMonth: 'effectiveWithMonth',
  indexOld: 'indexOld',
  indexSeriesId: 'indexSeriesId',
  minimumLimit: 'minimumLimit',
  monthOfIndexPoint: 'monthOfIndexPoint',
  monthYearOld: 'monthYearOld',
  shareInPercentToApply: 'shareInPercentToApply',
  subsequentBilling: 'subsequentBilling',
  subsequentBillingObjektVorschreibungspositionId: 'subsequentBillingObjektVorschreibungspositionId',
  threshold: 'threshold',
  thresholdType: 'thresholdType',
  type: 'type',
  validFrom: 'validFrom',
};

export const indexAnnualFormInitialValues: IndexationAgreementIndexAnnualFormValues = {
  annualAverage: false,
  effectiveWithMonth: undefined,
  indexOld: null,
  indexSeriesId: '',
  minimumLimit: undefined,
  monthOfIndexPoint: Month.January,
  monthYearOld: '',
  shareInPercentToApply: undefined,
  subsequentBilling: false,
  subsequentBillingObjektVorschreibungspositionId: '',
  threshold: 0,
  thresholdType: ThresholdType.Percent,
  type: ValuePreservationType.ReferenceValue,
  validFrom: '',
};

export const mapIndexAnnualToFormValues = (referenceValue?: VertragVposIndexationAgreementIndexAnnual): IndexationAgreementIndexAnnualFormValues => {
  if (!referenceValue) return indexAnnualFormInitialValues;
  return {
    annualAverage: referenceValue.annualAverage,
    effectiveWithMonth: referenceValue.effectiveWithMonth?.value,
    indexOld: referenceValue.indexOld,
    indexSeriesId: referenceValue.indexSeries?.indexSeriesId,
    minimumLimit: referenceValue.minimumLimit,
    monthOfIndexPoint: referenceValue.monthOfIndexPoint.value,
    monthYearOld: mapMonthYearToDateFormat(referenceValue.monthYearOld),
    shareInPercentToApply: referenceValue.shareInPercentToApply,
    subsequentBilling: referenceValue.subsequentBilling,
    subsequentBillingObjektVorschreibungspositionId: referenceValue.subsequentBillingObjektVorschreibungsposition?.objektVorschreibungspositionId,
    threshold: referenceValue.threshold,
    thresholdType: referenceValue.thresholdType.value,
    type: ValuePreservationType.ReferenceValue,
    validFrom: mapMonthYearToDateFormat(referenceValue.validFrom),
  };
};

export const mapFormValuesToIndexAnnual = (
  formValues: IndexationAgreementIndexAnnualFormValues
): VertragVposIndexationAgreementIndexAnnualCreateInput | VertragVposIndexationAgreementIndexAnnualUpdateInput => ({
  annualAverage: formValues.annualAverage,
  effectiveWithMonth: formValues.effectiveWithMonth,
  indexOld: formValues.indexOld,
  indexSeriesId: formValues.indexSeriesId,
  minimumLimit: formValues.minimumLimit,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  monthOfIndexPoint: formValues.monthOfIndexPoint!,
  monthYearOld: mapDateFormValueToMonthYear(formValues.monthYearOld),
  shareInPercentToApply: formValues.shareInPercentToApply,
  subsequentBilling: formValues.subsequentBilling,
  subsequentBillingObjektVorschreibungspositionId: formValues.subsequentBillingObjektVorschreibungspositionId,
  threshold: formValues.threshold,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  thresholdType: formValues.thresholdType!,
  type: ValuePreservationType.IndexAnnual,
  validFrom: mapDateFormValueToMonthYear(formValues.validFrom),
});
