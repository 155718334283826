import { Dayjs, ManipulateType } from 'dayjs';
import { dayjsCustom } from '../../helpers/dayjsCustom';

export const getDifferenceBetweenTwoMonths = (start: string, end: string) => {
  const startMonth = dayjsCustom(start);
  const endMonth = dayjsCustom(end);

  return dayjsRange(startMonth, endMonth, 'month').map((dayjs) => ({
    monat: dayjsCustom(dayjs).month() + 1,
    jahr: dayjsCustom(dayjs).year(),
  }));
};

function dayjsRange(start: Dayjs, end: Dayjs, unit: ManipulateType) {
  const range = [];
  let current = start;
  while (!current.isAfter(end)) {
    range.push(current);
    current = current.add(1, unit);
  }
  return range;
}
