import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { Verwaltungsart, VorsteuerkuerzungKontenZuweisung } from '../../../../../types';
import VstKuerzungTemplateKonto from './VstKuerzungTemplateKonto';
import { vstKuerzungKontenZuweisungFormValuesDescriptions } from '../../../../Vorsteuerkuerzung/KontenZuweisung/Version/Form/vstKuerzungKontenZuweisungVersionFormMapper';
import { generatePathToAufteilunsschluesselTemplatePage } from '../../../../AufteilungsschluesselTemplate/aufteilungsschluesselTemplateUriPaths';

// TODO: generatePathToKontoDetails - change Kontoplan Table to highlighted Table

const vstKuerzungTemplateKontenZuweisungWeTableColumns = (
  isInCardContent: boolean,
  verwaltungsart: Verwaltungsart
): ColumnProps<VorsteuerkuerzungKontenZuweisung>[] => [
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).aufwandsKontoIdList,
    render: (text, record) => (
      <Space direction="vertical" size={2}>
        {record.aufwandsKontoList.map((konto) => (
          <VstKuerzungTemplateKonto isInCardContent={isInCardContent} konto={konto} />
        ))}
      </Space>
    ),
  },
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).aufteilungsschluesselId,
    width: isInCardContent ? 220 : undefined,
    render: (text, record) => (
      <Link
        to={generatePathToAufteilunsschluesselTemplatePage(record.aufteilungsschluessel.aufteilungsschluesselId, record.aufteilungsschluessel.status)}
      >
        {record.aufteilungsschluessel.bezeichnung}
      </Link>
    ),
  },
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungKontoId,
    width: isInCardContent ? 290 : undefined,
    render: (text, record) => <VstKuerzungTemplateKonto isInCardContent={isInCardContent} konto={record.vorsteuerkuerzungKonto} />,
  },
];

export default vstKuerzungTemplateKontenZuweisungWeTableColumns;
