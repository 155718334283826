import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';
import UstKategorieTemplateForm from '../Form/UstKategorieTemplateForm';

type Props = {
  isCollapsed: boolean;
  onCollapse: () => void;
  refetch: () => void;
};

const UstKategorieTemplateButtonsAndDrawer: FC<Props> = ({ isCollapsed, onCollapse, refetch }) => {
  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button type="primary" onClick={onCollapse}>
          <PlusOutlined />
          USt.-Kategorie anlegen
        </Button>
      </ButtonsAligned>
      <Drawer title="USt.-Kategorie anlegen" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <UstKategorieTemplateForm
          onSuccess={() => {
            onCollapse();
            refetch();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
    </>
  );
};

export default UstKategorieTemplateButtonsAndDrawer;
