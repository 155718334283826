import { Space, Typography } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { TruckOutlined } from '@ant-design/icons';
import HausbetreuungListItemActions from './HausbetreuungListItemActions';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../Rechtstraeger/rechtstraegerHelper';
import InfoFeldListItem from '../InfoFeldListItem';
import ListItemContact from '../../../../../shared/InfoFeld/ListItem/ListItemContact';
import { ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment } from '../../../gql/objektInfoFeldFragments.types';
import { StyledSpaceSplitA30 } from '../../../../../../components/Space/styled/Space.style';

type Props = {
  hausbetreuung: ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment;
  objektId: string;
  onSuccess: () => void;
};

const HausbetreuungListItem: FC<Props> = ({ hausbetreuung, objektId, onSuccess }) => {
  return (
    <InfoFeldListItem
      icon={<TruckOutlined />}
      content={{ fileIdList: hausbetreuung.fileIdList, props: { height: 48 } }}
      actions={<HausbetreuungListItemActions infoFeld={hausbetreuung} objektId={objektId} onSuccess={onSuccess} />}
    >
      <Space direction="vertical" style={{ width: '220px' }}>
        <Typography.Text type="secondary">Hausbetreuung</Typography.Text>
        <Typography.Text>Beauftragungsgrund {hausbetreuung.beauftragungsgrund}</Typography.Text>
      </Space>
      <StyledSpaceSplitA30>
        {hausbetreuung.hausbetreuungRechtstraeger ? (
          <Link to={generatePathToRechtstraegerDetailsPage(hausbetreuung.hausbetreuungRechtstraeger.rechtstraegerId)}>
            {hausbetreuung.hausbetreuungRechtstraeger.kurzBezeichnung}
          </Link>
        ) : (
          <Typography.Text />
        )}
        {hausbetreuung.contactPerson ? (
          <ListItemContact
            contactList={hausbetreuung.contactPerson.contactList}
            firstname={hausbetreuung.contactPerson.firstname}
            lastname={hausbetreuung.contactPerson.lastname}
          />
        ) : (
          <Typography.Text />
        )}
        {hausbetreuung.kuendigungsfrist ? (
          <Typography>
            Kündigungsfrist {hausbetreuung.kuendigungsfristDauer} {hausbetreuung.kuendigungsfrist.text}
          </Typography>
        ) : (
          <Typography.Text />
        )}
      </StyledSpaceSplitA30>
    </InfoFeldListItem>
  );
};

export default HausbetreuungListItem;
