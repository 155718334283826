import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AbrechDefAbrechKreisFieldsFragmentDoc, KontoAbrechnungskreisFieldsFragmentDoc } from '../AbrDefFragments.types';
import { TextbausteinFieldsFragmentDoc } from '../../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
export type HeAbrechnungsdefinitionFieldsFragment = {
  abrechnungsdefinitionId: string;
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  firmendatenId?: string | null;
  updatedByMitarbeiterId?: string | null;
  validFrom: string;
  abrechdefAbrechkreisList: Array<{
    abrechnungskreis: {
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
      type: { text: string; value: Types.AbrechnungskreisType };
      verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    aufteilungsschluessel?: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    vorschreibungsposition?: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      kontierungstabelle?: {
        basiskonto: number;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kontierungstabelleId: string;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
        subAdministrationExpenseAccount?: {
          bezeichnung: string;
          firmendatenId?: string | null;
          kontoId: string;
          kontoKlasse: string;
          nummer: string;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      } | null;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    } | null;
  }>;
  heAbrechnungTextbausteinList: Array<{
    index: number;
    value?: string | null;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
    }>;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    type: { text: string; value: Types.TextbausteinType };
  }>;
  kontoAbrechnungskreisDataList: Array<{
    abrechnen: boolean;
    buchungszeilenAnzeigen: boolean;
    expenseReducing: boolean;
    ustVomAufwand: boolean;
    abrechnungskreis?: {
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
      type: { text: string; value: Types.AbrechnungskreisType };
      verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    aufteilungsschluessel?: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
  }>;
  status: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const HeAbrechnungsdefinitionFieldsFragmentDoc = gql`
  fragment HeAbrechnungsdefinitionFields on HeAbrechnungsdefinition {
    abrechdefAbrechkreisList {
      ...AbrechDefAbrechKreisFields
    }
    abrechnungsdefinitionId
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    firmendatenId
    heAbrechnungTextbausteinList {
      ...TextbausteinFields
      textbausteinList {
        ...TextbausteinFields
      }
    }
    kontoAbrechnungskreisDataList {
      ...KontoAbrechnungskreisFields
    }
    status {
      description
      text
      value
    }
    updatedByMitarbeiterId
    validFrom
    warningList {
      attribute
      message
      type
    }
  }
  ${AbrechDefAbrechKreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
`;
