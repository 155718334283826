import React, { FC, useEffect, useState } from 'react';
import { Divider, Space, Steps } from 'antd';
import { Dayjs } from 'dayjs';
import { AbrechnungsdefinitionType } from '../../../../types';
import { TextbausteinModel } from '../../../../components/Template/PDFTemplates/templateMapper';
import {
  AbrDefAbrKreisListingFormValues,
  mapAbrechnungsdefinitionToAbrKreisListFormValues,
} from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import {
  abrechnungsdefinitionParameterFormInitialValues,
  AbrechnungsdefinitionParameterFormValues,
} from '../../../Abrechnungsdefinition/Form/Create/abrDefParameterFormMapper';
import AbrDefFormValidFrom, { formatAbrechnungsdefinitionValidFromValue } from '../../../Abrechnungsdefinition/shared/AbrDefFormValidFrom';
import { useBkAbrDefFieldHelp } from '../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/useBkAbrDefFieldHelp';
import { useWeAbrDefFieldHelp } from '../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/useWeAbrDefFieldHelp';
import { useHeAbrDefFieldHelp } from '../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/useHeAbrDefFieldHelp';
import { useSubAbrDefFieldHelp } from '../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/useSubAbrDefFieldHelp';
import { isAbrDefTypeBkAbrechnung } from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { getFieldHelpTextBaseOnAbrDefType } from '../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/abrDefFieldHelpMapper';
import HeAbrDefTemplateForm from '../../shared/Templates/HeTemplates/HeAbrDefTemplateForm';
import AbrDefTemplateParameterForm from './AbrDefTemplateParameterForm';
import AbrDefTemplateAbrKreisListingForm from '../../shared/AbrDefAbrKreisListingForm/AbrDefTemplateAbrKreisListingForm';
import SubAdministrationAbrDefTemplateForm from '../../shared/Templates/SubTemplates/SubAbrDefTemplateForm';
import BkOrWeAbrDefTemplatesForm from '../../shared/Templates/shared/BkOrWe/BkOrWeAbrDefTemplatesForm';

type Props = {
  objektAbrechnungTextbausteinList: TextbausteinModel[];
  topAbrechnungTextbausteinList: TextbausteinModel[];
  heAbrechnungTextbausteinList: TextbausteinModel[];
  subAbrechnungTextbausteinList: TextbausteinModel[];
};

const AbrDefTemplateCreateForm: FC<Props> = ({
  objektAbrechnungTextbausteinList,
  topAbrechnungTextbausteinList,
  heAbrechnungTextbausteinList,
  subAbrechnungTextbausteinList,
}) => {
  /* FIELD HELP */
  const {
    fieldHelpAbrDefAbrechkreisList: fieldHelpBkAbrDefAbrechkreisList,
    fieldHelpNamesObjekt: fieldHelpNamesBkObjekt,
    fieldHelpNamesTop: fieldHelpNamesBkTop,
  } = useBkAbrDefFieldHelp();

  const {
    fieldHelpAbrDefAbrechkreisList: fieldHelpWeAbrDefAbrechkreisList,
    fieldHelpNamesObjekt: fieldHelpNamesWeObjekt,
    fieldHelpNamesTop: fieldHelpNamesWeTop,
  } = useWeAbrDefFieldHelp();

  const { fieldHelpAbrDefAbrechkreisList: fieldHelpHeAbrDefAbrechkreisList, fieldHelpNamesOutputOptions: fieldHelpNamesHeOutputOptions } =
    useHeAbrDefFieldHelp();

  const { fieldHelpAbrDefAbrechkreisList: fieldHelpSubAbrDefAbrechkreisList, fieldHelpNamesOutputOptions: fieldHelpNamesSubOutputOptions } =
    useSubAbrDefFieldHelp();
  /* END OF FIELD HELP */

  const [activeStep, setActiveStep] = useState(0);
  const [parameterFormValues, setParameterFormValues] = useState<AbrechnungsdefinitionParameterFormValues>(
    abrechnungsdefinitionParameterFormInitialValues
  );

  const [validFromFormValue, setValidFromFormValue] = useState<string>('');
  const [isValidFromValid, setIsValidFromValid] = useState<boolean>();

  const [abrKreisListFormValues, setAbrKreisListFormValues] = useState<AbrDefAbrKreisListingFormValues>(
    mapAbrechnungsdefinitionToAbrKreisListFormValues()
  );

  useEffect(() => {
    setAbrKreisListFormValues(mapAbrechnungsdefinitionToAbrKreisListFormValues());
  }, [parameterFormValues.type]);

  let content;
  const getContentForAbrechnung = (abrDefType: AbrechnungsdefinitionType) => {
    switch (abrDefType) {
      case AbrechnungsdefinitionType.SubAbrechnung:
        return (
          <SubAdministrationAbrDefTemplateForm
            parameterFormValues={parameterFormValues}
            abrKreisListFormValues={abrKreisListFormValues}
            subAdministrationAbrechnungTextbausteinList={subAbrechnungTextbausteinList}
            validFromFormValue={validFromFormValue}
            setIsValidFromValid={setIsValidFromValid}
            onBack={() => setActiveStep(1)}
            fieldHelpNames={fieldHelpNamesSubOutputOptions}
          />
        );
      case AbrechnungsdefinitionType.HeAbrechnung:
        return (
          <HeAbrDefTemplateForm
            parameterFormValues={parameterFormValues}
            abrKreisListFormValues={abrKreisListFormValues}
            heAbrechnungTextbausteinList={heAbrechnungTextbausteinList}
            validFromFormValue={validFromFormValue}
            setIsValidFromValid={setIsValidFromValid}
            onBack={() => setActiveStep(1)}
            fieldHelpNames={fieldHelpNamesHeOutputOptions}
          />
        );
      case AbrechnungsdefinitionType.BkAbrechnung:
      case AbrechnungsdefinitionType.WeAbrechnung:
      default:
        return (
          <BkOrWeAbrDefTemplatesForm
            parameterFormValues={parameterFormValues}
            abrKreisListFormValues={abrKreisListFormValues}
            objektAbrechnungTextbausteinList={objektAbrechnungTextbausteinList}
            topAbrechnungTextbausteinList={topAbrechnungTextbausteinList}
            validFromFormValue={validFromFormValue}
            setIsValidFromValid={setIsValidFromValid}
            onBack={() => setActiveStep(1)}
            fieldHelpNames={
              isAbrDefTypeBkAbrechnung(abrDefType)
                ? { objekt: fieldHelpNamesBkObjekt, top: fieldHelpNamesBkTop }
                : { objekt: fieldHelpNamesWeObjekt, top: fieldHelpNamesWeTop }
            }
          />
        );
    }
  };

  if (activeStep === 0) {
    content = (
      <AbrDefTemplateParameterForm
        values={parameterFormValues}
        onSubmit={(formValues) => {
          setParameterFormValues(formValues);
          setActiveStep(1);
        }}
      />
    );
  } else if (activeStep === 1) {
    content = (
      <AbrDefTemplateAbrKreisListingForm
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        abrDefType={parameterFormValues.type!}
        values={abrKreisListFormValues}
        onSubmit={(formValues) => {
          setAbrKreisListFormValues(formValues);
          setActiveStep(2);
        }}
        onBack={() => setActiveStep(0)}
        fieldHelp={getFieldHelpTextBaseOnAbrDefType(
          parameterFormValues.type,
          fieldHelpHeAbrDefAbrechkreisList,
          fieldHelpBkAbrDefAbrechkreisList,
          fieldHelpWeAbrDefAbrechkreisList,
          fieldHelpSubAbrDefAbrechkreisList
        )}
      />
    );
  } else {
    // (activeStep === 2)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    content = getContentForAbrechnung(parameterFormValues.type!);
  }

  const onChangeDate = (date: Dayjs | null) => {
    if (date) {
      setIsValidFromValid(true);
      setValidFromFormValue(formatAbrechnungsdefinitionValidFromValue(date));
    } else {
      setIsValidFromValid(false);
    }
  };

  return (
    <>
      <Space>
        <AbrDefFormValidFrom isValid={isValidFromValid} onChange={onChangeDate} />
      </Space>
      <Divider />
      <Steps type="navigation" size="small" current={activeStep} className="site-navigation-steps">
        <Steps.Step title="Allgemeine Parameter" />
        <Steps.Step title="Abrechnungskreise" />
        <Steps.Step title="Abrechnung" />
      </Steps>
      {content}
    </>
  );
};

export default AbrDefTemplateCreateForm;
