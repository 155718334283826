import React, { FC } from 'react';
import TableWithAlignedColsBody from '../../../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsBody';
import ustVomAufwandKontoTableColumns from './ustVomAufwandKontoTableColumns';
import ustVomAufwandKontoTableExpandedRow from './ustVomAufwandKontoTableExpandedRow';
import { ObjAbrKonto } from '../../../../../../types';

type Props = {
  konto: ObjAbrKonto;
  fromInclusive: string;
  toInclusive: string;
  objektId: string;
  buchungskreisId: string;
};

const UstVomAufwandKontoTable: FC<Props> = ({ konto, fromInclusive, toInclusive, objektId, buchungskreisId }) => {
  return (
    <TableWithAlignedColsBody<ObjAbrKonto>
      dataSource={konto}
      columns={ustVomAufwandKontoTableColumns(objektId, fromInclusive, toInclusive, buchungskreisId)}
      expandedRow={() => ustVomAufwandKontoTableExpandedRow}
      positionOfButton={2}
    />
  );
};

export default UstVomAufwandKontoTable;
