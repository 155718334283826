import { Input, InputProps } from 'formik-antd';
import React, { FC } from 'react';
import { useField } from 'formik';
import { FormikAntDValidateStatuses } from '../../helpers/formikHelper';
import FormItemWithFieldHelp from '../../components/Form/FormItemWithFieldHelp';

type UniqueBezeichnungInputProps = {
  existingBezeichnungList: string[];
  fieldHelp?: string | null;
} & InputProps;

const UniqueBezeichnungInput: FC<UniqueBezeichnungInputProps> = ({
  placeholder = 'z.B. Bezeichnung XY',
  name,
  existingBezeichnungList,
  fieldHelp,
  ...restProps
}) => {
  const [{ value: bezeichnung }, { error, touched, initialValue: initialBezeichnung }, { setTouched }] = useField<string>({
    name,
    validate: (bezeichnung: string) => {
      if (bezeichnung && notAllowedBezeichnungList.includes(bezeichnung.trim())) {
        if (!touched) setTouched(true);
        return 'Diese Bezeichnung existiert bereits';
      }
      return undefined;
    },
  });
  const notAllowedBezeichnungList = existingBezeichnungList.filter((item) => item !== initialBezeichnung);

  return (
    <FormItemWithFieldHelp
      tooltip="Die Bezeichnung muss eindeutig sein"
      name={name}
      label="Bezeichnung"
      hasFeedback
      validateStatus={determineValidateStatusAndHelpMsg(bezeichnung, touched, error).validateStatus}
      help={determineValidateStatusAndHelpMsg(bezeichnung, touched, error).msg}
      fieldHelp={fieldHelp}
    >
      <Input id={name} name={name} placeholder={placeholder} {...restProps} />
    </FormItemWithFieldHelp>
  );
};

const determineValidateStatusAndHelpMsg = (
  value: string,
  touched: boolean,
  error?: string
): { validateStatus: FormikAntDValidateStatuses; msg: React.ReactNode } => {
  if (value === '') {
    return { validateStatus: '', msg: null };
  }
  if (error && touched) {
    return { validateStatus: 'error', msg: error };
  }
  return { validateStatus: 'success', msg: 'Die Bezeichnung ist eindeutig' };
};

export default UniqueBezeichnungInput;
