import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SpracheListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SpracheListQuery = {
  getSpracheList: {
    data: Array<{ text: string; value: Types.Sprache }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const SpracheListDocument = gql`
  query SpracheList {
    getSpracheList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSpracheListQuery(baseOptions?: Apollo.QueryHookOptions<SpracheListQuery, SpracheListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpracheListQuery, SpracheListQueryVariables>(SpracheListDocument, options);
}
export function useSpracheListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpracheListQuery, SpracheListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpracheListQuery, SpracheListQueryVariables>(SpracheListDocument, options);
}
export function useSpracheListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SpracheListQuery, SpracheListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SpracheListQuery, SpracheListQueryVariables>(SpracheListDocument, options);
}
export type SpracheListQueryHookResult = ReturnType<typeof useSpracheListQuery>;
export type SpracheListLazyQueryHookResult = ReturnType<typeof useSpracheListLazyQuery>;
export type SpracheListSuspenseQueryHookResult = ReturnType<typeof useSpracheListSuspenseQuery>;
export type SpracheListQueryResult = Apollo.QueryResult<SpracheListQuery, SpracheListQueryVariables>;
