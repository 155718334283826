import React, { FC } from 'react';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import heVertragTableColumns from './heVertragTableColumns';
import { HeVertragListEntry } from '../../../../../types';

type Props = {
  loading: boolean;
  heVertragList: HeVertragListEntry[];
  objektId: string;
};

const HeVertragTable: FC<Props> = ({ loading, heVertragList, objektId }) => {
  return (
    <TableWithColSelector<HeVertragListEntry>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Hauseigentümerverträge vorhanden</span>} />,
      }}
      loading={loading}
      dataSource={heVertragList}
      columns={heVertragTableColumns(objektId)}
      pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
      rowKey={(record) => record.heVertragId}
      filterIdentifier="he-vertrag-table"
    />
  );
};

export default HeVertragTable;
