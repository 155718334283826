import React, { FC } from 'react';
import { Col, Divider } from 'antd';
import { PaymentKonto, SollHaben } from '../../../../../types';
import KontoDetailsCard from './KontoDetailsCard';

type Props = {
  habenKonto?: PaymentKonto | null;
  sollKonto?: PaymentKonto | null;
  objektId?: string;
};

const KontoDetailsCards: FC<Props> = ({ habenKonto, sollKonto, objektId }) => {
  return (
    <>
      <Col span={11}>{sollKonto && <KontoDetailsCard konto={sollKonto} kontoType={SollHaben.Soll} objektId={objektId} />}</Col>
      <Col>{sollKonto && habenKonto && <Divider type="vertical" style={{ height: '100%' }} />}</Col>
      <Col span={11}>{habenKonto && <KontoDetailsCard konto={habenKonto} kontoType={SollHaben.Haben} objektId={objektId} />}</Col>
    </>
  );
};

export default KontoDetailsCards;
