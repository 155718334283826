import { generatePath } from 'react-router-dom';
import { URI_SYS_SETTINGS } from '../../constants/configurationUriPaths';

export const URI_SYS_SETTINGS_KONTO_DETAILS_PAGE = `${URI_SYS_SETTINGS.kontenplanPage}/:kontoId`;

export const pathsToKontoDetailsPage = (kontoId: string) => ({
  abrechnungen: `${generatePathToKontoDetailsPage(kontoId)}/abrechnungen`,
  bezuege: `${generatePathToKontoDetailsPage(kontoId)}/bezuege`,
});

export const generatePathToKontoDetailsPage = (kontoId: string): string => generatePath(URI_SYS_SETTINGS_KONTO_DETAILS_PAGE, { kontoId });
