import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BestandseinheitBaseFieldsFragmentDoc } from '../../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import { VertragBaseFieldsFragmentDoc } from '../../../Vertrag/gql/VertragFragments.types';
import { BudgetingBaseFieldsFragmentDoc } from '../../../Budgeting/gql/BudgetingFragments.types';
export type TopFinancialPlanGenerierlaufEntryFieldsFragment = {
  budgetSumNextYear: number;
  budgetingResultVertragId?: string | null;
  currentStep?: number | null;
  generierlaufEntryId: string;
  infoMessage?: string | null;
  retry: boolean;
  stepCount?: number | null;
  bestandseinheit?: {
    bestandseinheitId: string;
    bezeichnung: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    objektId: string;
    status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  errorList: Array<{ message: string; type: string }>;
  exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
  stepList: Array<{
    designation: string;
    receivingApplication?: string | null;
    stepId: string;
    type?: string | null;
    status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
  }>;
  vertrag?: {
    bestandseinheitId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    objektId: string;
    vertragId: string;
    vertragsBeginn: string;
    status: { text: string; value: Types.VertragStatus; description?: string | null };
    vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
    vertragspartner: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  warningList: Array<{ message: string; type: string }>;
};

export type TopFinancialPlanGenerierlaufFieldsFragment = {
  countFehler: number;
  countVerarbeitet: number;
  countNichtVerarbeitet: number;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  generierDatum: string;
  generierlaufId: string;
  budgeting: {
    bezeichnung: string;
    budgetSumNextYear?: number | null;
    budgetingId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    currentYear: number;
    increase?: number | null;
    prevYear: number;
    updatedByMitarbeiterId?: string | null;
    vertragVPosWertDate?: string | null;
    vorschreibungCurrentYearSum: number;
    vorschreibungPrevYearSum: number;
    restOfCurrentYearSum?: number | null;
    totalCurrentYearSum?: number | null;
    objekt: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektId: string;
      updatedByMitarbeiterId?: string | null;
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { text: string; value: Types.ObjektStatus; description?: string | null };
      verwaltungsart: { text: string; value: Types.Verwaltungsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
    status: { text: string; value: Types.BudgetingStatus };
    warningList: Array<{ message: string; type: string }>;
  };
  entryList: Array<{
    budgetSumNextYear: number;
    budgetingResultVertragId?: string | null;
    currentStep?: number | null;
    generierlaufEntryId: string;
    infoMessage?: string | null;
    retry: boolean;
    stepCount?: number | null;
    bestandseinheit?: {
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      objektId: string;
      status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    errorList: Array<{ message: string; type: string }>;
    exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
    stepList: Array<{
      designation: string;
      receivingApplication?: string | null;
      stepId: string;
      type?: string | null;
      status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
    }>;
    vertrag?: {
      bestandseinheitId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektId: string;
      vertragId: string;
      vertragsBeginn: string;
      status: { text: string; value: Types.VertragStatus; description?: string | null };
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    warningList: Array<{ message: string; type: string }>;
  }>;
  errorList: Array<{ message: string; type: string }>;
  status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
  warningList: Array<{ message: string; type: string }>;
};

export const TopFinancialPlanGenerierlaufEntryFieldsFragmentDoc = gql`
  fragment TopFinancialPlanGenerierlaufEntryFields on TopFinancialPlanGenerierlaufEntry {
    bestandseinheit {
      ...BestandseinheitBaseFields
    }
    budgetSumNextYear
    budgetingResultVertragId
    currentStep
    errorList {
      message
      type
    }
    exitCode {
      value
      text
    }
    generierlaufEntryId
    infoMessage
    retry
    stepCount
    stepList {
      ...GenerierlaufAsyncStepFields
    }
    vertrag {
      ...VertragBaseFields
    }
    warningList {
      message
      type
    }
  }
  ${BestandseinheitBaseFieldsFragmentDoc}
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
  ${VertragBaseFieldsFragmentDoc}
`;
export const TopFinancialPlanGenerierlaufFieldsFragmentDoc = gql`
  fragment TopFinancialPlanGenerierlaufFields on TopFinancialPlanGenerierlauf {
    budgeting {
      ...BudgetingBaseFields
    }
    countFehler
    countVerarbeitet
    countNichtVerarbeitet
    createTs
    createdBy
    createdByMitarbeiterId
    entryList {
      ...TopFinancialPlanGenerierlaufEntryFields
    }
    errorList {
      message
      type
    }
    generierDatum
    generierlaufId
    status {
      description
      value
      text
    }
    warningList {
      message
      type
    }
  }
  ${BudgetingBaseFieldsFragmentDoc}
  ${TopFinancialPlanGenerierlaufEntryFieldsFragmentDoc}
`;
