import { validateEmailAsync, validateEmailSync } from '../Email/emailValidation';
import { newEmailLengthValidationSchema } from '../Email/validationSchema';

export const validateNewEmailNotRequired = (email?: string) => {
  if (!email) {
    return undefined;
  }
  const validationError = validateEmailSync(email, newEmailLengthValidationSchema);
  if (validationError) {
    return validationError;
  }
  return validateEmailAsync(email);
};
