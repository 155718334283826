import styled from 'styled-components';
import { Card } from 'antd';
import theme from '../../../../../theme';

export const StyledCard = styled(Card)<{ isOriginal: boolean }>`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 450px;
  .ant-card-head {
    border-bottom: none;
    text-align: center;
    ${(props) => `background-color: ${props.isOriginal ? theme.cardBackgroundColors.blue : theme.cardBackgroundColors.grey}`}
  }
  .ant-card-body {
    padding: 0;
    & > div:first-child {
      padding: 12px;
    }
  }
`;
