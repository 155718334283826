import styled from 'styled-components';

export const StyledInfoFeldIcon = styled('div')<{ width?: number; height?: number; showBorder?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => `width: ${props.width ? props.width : 28}px;`}
  ${(props) => `height: ${props.height ? props.height : 28}px;`}
  margin: 0 4px;
  ${(props) => props.showBorder && `border: 1px solid rgba(0, 0, 0, 0.45);border-radius: 2px;`}
  & span {
    width: 100%;
    height: 100%;
    padding: 4px;
    > svg {
      width: 100%;
      height: 100%;
    }
  }
`;
