import { useContext } from 'react';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../types';
import { IndexationAgreementContext } from '../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/Table/Level2/IndexationAgreementContext';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { useAuth } from '../../shared/Auth/authContext';

const useOnIndexedValueDataChangedEvents = (key: string, callback: () => Promise<unknown>) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.vertragVposIndexedValue,
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Indexierte Werte wurden gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.vertragVposIndexedValue,
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Indexierte Werte wurden gerade verändert',
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.vertragVposIndexedValue,
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Indexierte Werte wurden gerade verändert',
      key: `${key}-delete`,
      callback,
    },
  ]);
};
const useOnIndexedValueDetailsDataChangedEvents = (key: string, callback: () => Promise<unknown>, indexationAgreementId: string) => {
  const { bestandseinheitId, objektId, vertragId } = useContext(IndexationAgreementContext);

  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.vertragVposIndexedValue,
        changeType: DataChangedChangeType.Create,
        params: {
          objektId,
          bestandseinheitId,
          vertragId,
        },
      },
      notificationDescription: 'Indexierte Werte wurden gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.vertragVposIndexedValue,
        changeType: DataChangedChangeType.Update,
        params: {
          objektId,
          bestandseinheitId,
          vertragId,
          vertragVposIndexationAgreementId: indexationAgreementId,
        },
      },
      notificationDescription: 'Indexierte Werte wurden gerade verändert',
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.vertragVposIndexedValue,
        changeType: DataChangedChangeType.Delete,
        params: {
          objektId,
          bestandseinheitId,
          vertragId,
          vertragVposIndexationAgreementId: indexationAgreementId,
        },
      },
      notificationDescription: 'Indexierte Werte wurden gerade verändert',
      key: `${key}-delete`,
      callback,
    },
  ]);
};

export { useOnIndexedValueDataChangedEvents, useOnIndexedValueDetailsDataChangedEvents };
