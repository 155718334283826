import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { RepFondsAbrechnungAusgabenKonto } from '../../../../../topAbrechnung-types';
import TARepFondsAusgabenWithAusgabenList from './TARepFondsAusgabenWithAusgabenList';
import TARepFondsAusgabenWithoutAusgabenList from './TARepFondsAusgabenWithoutAusgabenList';

const TARepFondsAbrechnungAusgabenKontoList: FC<{ ausgabenKontoList: RepFondsAbrechnungAusgabenKonto[] }> = ({ ausgabenKontoList }) => (
  <View>
    {ausgabenKontoList.map((ausgabenKonto, index) =>
      ausgabenKonto.ausgabenList && ausgabenKonto.ausgabenList.length > 0 ? (
        <TARepFondsAusgabenWithAusgabenList
          key={index}
          ausgabenKonto={{
            kontoBezeichnung: ausgabenKonto.kontoBezeichnung,
            ausgabenList: ausgabenKonto.ausgabenList,
            summeNettoAusgaben: ausgabenKonto.summeNettoAusgaben,
          }}
        />
      ) : (
        <TARepFondsAusgabenWithoutAusgabenList key={index} ausgabenKonto={ausgabenKonto} />
      )
    )}
  </View>
);

export default TARepFondsAbrechnungAusgabenKontoList;
