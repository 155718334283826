import React, { FC } from 'react';
import { View } from '@react-pdf/renderer';
import { VValuePreservationIndexationInformation } from '../../vorschreibung-types';
import IndexationInformation from './IndexationInformation';
import ValuePreservationInformation from './ValuePreservationInformation';

type Props = {
  bestandseinheit: string;
  valuePreservationIndexationInformationList: VValuePreservationIndexationInformation[];
};

const ValuePreservationIndexationInformationTable: FC<Props> = ({ bestandseinheit, valuePreservationIndexationInformationList }) => (
  <View>
    {valuePreservationIndexationInformationList.map((indexationInfo, index) => (
      <View key={index}>
        <ValuePreservationInformation bestandseinheit={bestandseinheit} index={index} valuePreservationInformation={indexationInfo} />
        <IndexationInformation indexationInformation={indexationInfo.indexationInformation} />
      </View>
    ))}
  </View>
);

export default ValuePreservationIndexationInformationTable;
