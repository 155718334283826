import { forwardRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { TicketEmail } from '../../../../types';
import { ticketEmailsFormInitialValues, TicketEmailsFormValues } from './ticketEmailsFormMapper';
import TicketEmailUpload from './TicketEmailUpload';

type Props = {
  emailList: TicketEmail[];
};

const TicketEmailsForm = forwardRef<FormikProps<TicketEmailsFormValues>, Props>(({ emailList }, ref) => (
  <Formik<TicketEmailsFormValues> initialValues={ticketEmailsFormInitialValues} innerRef={ref} onSubmit={() => {}}>
    {(formikProps) => <TicketEmailUpload formikProps={formikProps} emailList={emailList} />}
  </Formik>
));

export default TicketEmailsForm;
