import React, { FC } from 'react';
import Html from 'react-pdf-html';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';
import { DocumentHeaderAndFooter, DocumentHeaderAndFooterType } from './types';
import HeaderAndFooterImage from './HeaderAndFooterImage';

type Props = {
  header?: DocumentHeaderAndFooter | null;
};

const Header: FC<Props> = ({ header }) => {
  let content = null;

  if (header && header.value) {
    if (header.type === DocumentHeaderAndFooterType.IMAGE) {
      content = (
        <HeaderAndFooterImage
          value={header.value.value}
          horizontalAlignment={header.value.alignment}
          verticalAlignment="TOP"
          style={{ maxHeight: 98 }}
        />
      );
    } else {
      content = (
        <Text>
          <Html collapse={false}>{header.value}</Html>
        </Text>
      );
    }
  }

  return <View style={[pdfStyles.row, { height: '33mm' }]}>{content}</View>;
};

export default Header;
