import React, { FC } from 'react';
import { Formik } from 'formik';
import { Typography } from 'antd';
import { Form, Input } from 'formik-antd';
import NewEmailFormPart from '../../../../shared/components/Email/NewEmailFormPart';
import NewUsernameFormPart from '../../username/NewUsernameFormPart';
import { adminCreateFormValidationSchema } from '../../adminFormValidationSchema';
import FormButtons from '../../../../components/Button/FormButtons';
import { adminCreateFormInitialValues, AdminCreateFormValues } from './adminCreateFormMapper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import ContactListFormPart from '../../../../shared/components/Contact/ContactListFormPart';

interface AdminCreateFormInterface {
  loading: boolean;
  onSubmit: (values: AdminCreateFormValues) => void;
  onCancel: () => void;
}

const AdminCreateForm: FC<AdminCreateFormInterface> = ({ loading, onSubmit, onCancel }) => (
  <Formik<AdminCreateFormValues>
    initialValues={adminCreateFormInitialValues}
    validateOnChange={false}
    validationSchema={adminCreateFormValidationSchema}
    onSubmit={(values: AdminCreateFormValues, { setSubmitting }) => {
      onSubmit(values);
      setSubmitting(false);
    }}
  >
    {(formikProps) => (
      <Form>
        <NewUsernameFormPart usernameInputLabel="Benutzername" usernameInputPlaceholder="Benutzername" usernameInputName="username" />
        <NewEmailFormPart emailInputName="email" emailInputPlaceholder="E-Mail-Adresse" />
        <FormItemWithoutColon name="firstname" label="Vorname">
          <Input id="firstname" name="firstname" placeholder="Vorname" />
        </FormItemWithoutColon>
        <FormItemWithoutColon name="lastname" label="Nachname">
          <Input id="lastname" name="lastname" placeholder="Nachname" />
        </FormItemWithoutColon>
        <Typography.Title level={5}>Kontakt</Typography.Title>
        <ContactListFormPart />
        <FormButtons isOkDisabled={formikProps.isSubmitting} onCancel={onCancel} updateMode={false} isOkLoading={loading} />
      </Form>
    )}
  </Formik>
);

export default AdminCreateForm;
