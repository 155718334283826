import { FC, PropsWithChildren, ReactNode } from 'react';
import { Col, Row, Typography } from 'antd';

type Props = {
  infoRowTitle: string | ReactNode;
};

const TimelineCardContentInfoRow: FC<Props & PropsWithChildren> = ({ infoRowTitle, children }) => (
  <Row style={{ width: '100%' }}>
    <Col span={10}>{typeof infoRowTitle === 'string' ? <Typography.Text type="secondary">{infoRowTitle}</Typography.Text> : infoRowTitle}</Col>
    <Col span={14}>{children}</Col>
  </Row>
);

export default TimelineCardContentInfoRow;
