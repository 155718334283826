import React from 'react';
import { useParams } from 'react-router-dom';
import menuListKonto from './menuListKonto';
import KontoCard from '../../../../features/Kontenplan/KontoDetails/KontoCard';
import { useKontoQuery } from '../../../../features/Kontenplan/gql/KontenplanQueries.types';
import DetailsPageTemplate from '../../../../components/Template/DetailsPageTemplate';
import { hasKontoklasseAbrechnungen } from '../../../../features/Kontenplan/Form/kontoFormHelpers';
import { KontoDetailsPageRouteParams } from './routes';
import { Konto } from '../../../../types';

const SysSettingsKontoDetailsPage = () => {
  const { kontoId } = useParams() as KontoDetailsPageRouteParams;

  const { data, refetch, loading } = useKontoQuery({
    variables: {
      kontoId,
      withDetails: true,
    },
    notifyOnNetworkStatusChange: true,
  });
  const konto = data?.getKonto.data;
  // const loading = !konto;

  return (
    <DetailsPageTemplate<Konto>
      data={konto}
      isLoading={loading}
      pageTitle={(konto) => konto.bezeichnung}
      card={(konto) => <KontoCard konto={konto} loading={loading} onSuccess={refetch} />}
      sidebarMenuList={(konto) => menuListKonto(konto)}
      contentContextList={(konto) => createKontoplanContextList(konto)}
    />
  );
};

const createKontoplanContextList = (konto: Konto) => (hasKontoklasseAbrechnungen(konto.klasse.value) ? ['ABRECHNUNGEN'] : []);

export default SysSettingsKontoDetailsPage;
