import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FiltersFormValues, mapFormValuesToQueryParams, mapQueryParamsToFormValues } from './Filters/listingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import VerarbeitungTable from './Table/VerarbeitungTable';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { useGenerierlaufListQuery } from '../gql/GenerierlaufInfo/GenerierlaufInfoQueries.types';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { TVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';

const VerarbeitungListingForm = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  const { data, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useGenerierlaufListQuery,
    { variables: { ...queryParams } },
    {
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const generierlaufList = data?.getGenerierlaufList.data.contentList;

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  return (
    <>
      <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => <ListingFilters formikProps={formikProps} queryParams={queryParams} />}
      </Formik>
      <VerarbeitungTable generierlaufList={generierlaufList} pagination={pagination} handleTableSorting={handleTableSorting} />
    </>
  );
};

export default VerarbeitungListingForm;
