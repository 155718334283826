import * as Yup from 'yup';
import { entityIsRequired, entityMustBeSelected } from '../../../components/message/validationMsg';
import { textbausteinFormFields } from './textbausteinFormMapper';

/* eslint-disable no-template-curly-in-string */
export const textbausteinValidationSchema = Yup.object().shape({
  [textbausteinFormFields.bezeichnung]: Yup.string().required(entityIsRequired('Bezeichnung')),
  [textbausteinFormFields.type]: Yup.string().required(entityMustBeSelected('Typ')),
  [textbausteinFormFields.textbaustein]: Yup.string().required(entityIsRequired('Text')),
});

/* eslint-enable no-template-curly-in-string */
