import React from 'react';
import { Route } from 'react-router';
import { isAdmin } from '../../../../security/permissionChecks';
import AuthRoute from '../../../../security/AuthRoute';
import AndromedaSysSettingsRepFondsPage from './AndromedaSysSettingsRepFondsPage';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';

const andromedaSysSettingsRepFondsPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.repFondsPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsRepFondsPage />} />}
  />
);

export default andromedaSysSettingsRepFondsPageRoute;
