import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendVorschreibungMailDeliveryMutationVariables = Types.Exact<{
  vorschreibungMailDeliveryId: Types.Scalars['ID']['input'];
  recipient: Types.Scalars['String']['input'];
}>;

export type ResendVorschreibungMailDeliveryMutation = { actionResendVorschreibungMailDelivery: { data: { vorschreibungMailDeliveryId: string } } };

export const ResendVorschreibungMailDeliveryDocument = gql`
  mutation ResendVorschreibungMailDelivery($vorschreibungMailDeliveryId: ID!, $recipient: String!) {
    actionResendVorschreibungMailDelivery(vorschreibungMailDeliveryId: $vorschreibungMailDeliveryId, recipient: $recipient) {
      data {
        vorschreibungMailDeliveryId
      }
    }
  }
`;
export function useResendVorschreibungMailDeliveryMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendVorschreibungMailDeliveryMutation, ResendVorschreibungMailDeliveryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendVorschreibungMailDeliveryMutation, ResendVorschreibungMailDeliveryMutationVariables>(
    ResendVorschreibungMailDeliveryDocument,
    options
  );
}
export type ResendVorschreibungMailDeliveryMutationHookResult = ReturnType<typeof useResendVorschreibungMailDeliveryMutation>;
export type ResendVorschreibungMailDeliveryMutationResult = Apollo.MutationResult<ResendVorschreibungMailDeliveryMutation>;
export type ResendVorschreibungMailDeliveryMutationOptions = Apollo.BaseMutationOptions<
  ResendVorschreibungMailDeliveryMutation,
  ResendVorschreibungMailDeliveryMutationVariables
>;
