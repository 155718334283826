import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { UstRegelsetUstKategorieTimelineDataSourceEntry } from '../../UstRegelset/Version/ustRegelsetVersionTimelineMapper';

type Props = {
  timeblock: ITimeblock<UstRegelsetUstKategorieTimelineDataSourceEntry>;
};

const UstRegelsetTemplateVersionCardContent: FC<Props> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      <CustomFormattedDate value={timeblock.validFrom} />
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle="USt.-Kategorie">
      <Typography.Text>{timeblock.ustKategorie.kurzBezeichnung}</Typography.Text>
    </TimelineCardContentInfoRow>
  </Space>
);

export default UstRegelsetTemplateVersionCardContent;
