import React from 'react';
import { Route } from 'react-router';
import { isAdmin } from '../../../../security/permissionChecks';
import AuthRoute from '../../../../security/AuthRoute';
import AndromedaSysSettingsBudgetingVerknuepfungVPosAuftSchluesselPage from './AndromedaSysSettingsBudgetingVerknuepfungVPosAuftSchluesselPage';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';

const andromediaSysSettingsBudgetingVerknuepfungVPosAuftSchluesselPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.budgetingVerknuepfungVPosAuftSchluesselPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsBudgetingVerknuepfungVPosAuftSchluesselPage />} />}
  />
);

export default andromediaSysSettingsBudgetingVerknuepfungVPosAuftSchluesselPageRoute;
