import React, { FC } from 'react';
import { Tag, Tooltip, TagProps } from 'antd';
import { PresetColorType } from 'antd/lib/_util/colors';
import { hasStatusDescription, IStatus, StatusEnum, TCollectedStatus } from '../../../helpers/statusHelper';

type DocumentStatusTagProps = {
  documentStatus: IStatus;
} & TagProps;

/**
 * <h2>Usage</h2>
 * Used to show a document status
 */
const DocumentStatusTag: FC<DocumentStatusTagProps> = ({ documentStatus, ...restProps }) => {
  const tag = (
    <Tag color={mapBuchungStatusToTag(documentStatus.value)} {...restProps}>
      {documentStatus.text.toUpperCase()}
    </Tag>
  );

  return hasStatusDescription(documentStatus) ? <Tooltip title={documentStatus.description}>{tag}</Tooltip> : <>{tag}</>;
};

export const mapBuchungStatusToTag = (status: TCollectedStatus): PresetColorType | '' => {
  switch (status) {
    // Dokumente
    case StatusEnum.Geloescht:
      return 'red';
    case StatusEnum.Freigegeben:
    case StatusEnum.UmbuchungFreigegeben:
      return 'lime';
    case StatusEnum.Ausgegeben:
      return 'gold';
    case StatusEnum.Uebertragen:
    case StatusEnum.Verbucht:
      return 'blue';
    case StatusEnum.Erstellt:
    case StatusEnum.UebertragenFehler:
    case StatusEnum.NichtVerbucht:
    case StatusEnum.WirdVerbucht:
    default:
      return '';
  }
};

export default DocumentStatusTag;
