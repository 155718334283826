import { onError } from '@apollo/client/link/error';

const errorLoggerLink = onError(({ operation, graphQLErrors, networkError }) => {
  let errorMsg = `Error during GQL operation: ${operation.operationName} with vars ${JSON.stringify(
    replaceSensitiveVariableValues(operation.variables)
  )}.\n`;
  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      const extensions: any = graphQLError?.extensions ?? [];
      if (isValidationError(extensions)) {
        const validationErrorList = extensions.response.body.errorList as { type: string; message: string }[];
        const validationErrorMsgs = validationErrorList.map((valError) => `${valError.type} - ${valError.message}\n`);
        errorMsg = `${errorMsg}[GraphQL error]: Message: ${graphQLError.message}, Path: ${graphQLError.path}\n${validationErrorMsgs}`;
      } else {
        errorMsg = `${errorMsg}[GraphQL error]: Message: ${graphQLError.message}, Path: ${graphQLError.path}\n`;
      }
    });
  }
  if (networkError) {
    // @ts-ignore
    errorMsg = `${errorMsg}[Network error]: Message: ${networkError.message}, StatusCode: ${networkError.statusCode}`;
  }
  // show errors only in devMode
  if (process.env.NODE_ENV !== 'production') {
    console.error(errorMsg);
  }
});

const replaceSensitiveVariableValues = (variables: Record<string, unknown>) => {
  const sensitiveVars = ['password', 'passwort', 'pw'];
  return Object.entries(variables).map(([key, value]) => {
    const isSensitiveVar = !!sensitiveVars.find((sensitiveVar) => key.toLowerCase().includes(sensitiveVar));
    return isSensitiveVar ? [key, '***'] : [key, value];
  });
};

export const isValidationError = (extensions: { [p: string]: unknown }) =>
  Object.prototype.hasOwnProperty.call(extensions, 'response') &&
  Object.prototype.hasOwnProperty.call(extensions.response, 'body') &&
  // @ts-ignore
  Object.prototype.hasOwnProperty.call(extensions.response.body, 'errorList');

export default errorLoggerLink;
