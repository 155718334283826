import React, { FC } from 'react';
import { URI_SYS_SETTINGS } from '../../../../../constants/configurationUriPaths';
import DefinitionUpdateFormButtons from '../../../../shared/DefinitionUpdateFormButtons';

type Props = {
  tabContent: React.ReactElement;
  onSubmit: () => void;
};

const AbrDefVersionUpdateTabWithButtons: FC<Props> = ({ tabContent, onSubmit }) => {
  return (
    <>
      {tabContent}
      <DefinitionUpdateFormButtons pushPath={URI_SYS_SETTINGS.abrechnungsdefinitionPage} onSubmit={onSubmit} />
    </>
  );
};

export default AbrDefVersionUpdateTabWithButtons;
