import React, { FC } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, Select, Switch } from 'formik-antd';
import { Dayjs } from 'dayjs';
import FormButtons from '../../../../components/Button/FormButtons';
import { useUpdateBeVertragMutation } from '../gql/BeVertragMutations.types';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import {
  mapVertragNutzungDetailsFormValuesToVertragUpdate,
  mapVertragToVertragNutzungDetailsFormValues,
  vertragNutzungDetailsFormFields,
  VertragNutzungDetailsFormValues,
} from './vertragNutzungDetailsFormMapper';
import { vertragNutzungDetailsFormValidationSchema } from './vertragNutzungDetailsFormValidationSchema';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import RechtstraegerSelect from '../../../../shared/Rechtstraeger/RechtstraegerSelect';
import { Vertragsart, Verwaltungsart } from '../../../../types';
import { isVertragsartTypeLeerstehung, isVertragsartTypeWegEigentuemer, isVertragsartTypeWegLeerstehung } from '../../shared/vertragsartHelpers';
import { BeVertragFragment } from '../gql/BeVertragFragments.types';
import VertragVertragsartFormSelect from '../../shared/VertragVertragsartFormSelect';
import { vertragCreateFormFields } from './vertragCreateFormMapper';
import { getRechnungsAusstellerSubadministration } from '../../shared/getRechnungsAusstellerSubadministration';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertrag: BeVertragFragment;
  onCancel: () => void;
  onSuccess: () => void;
  rechnungsausstellerId: string;
};

const VertragNutzungDetailsForm: FC<Props> = ({ onCancel, vertrag, objektId, bestandseinheitId, onSuccess, rechnungsausstellerId }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeVertrag'>('BeVertrag');

  const { activeForFirmendatenId } = useFDAuthorized();

  const [runUpdate, { loading: loadingUpdate }] = useUpdateBeVertragMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Vertrag`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<VertragNutzungDetailsFormValues>
      initialValues={mapVertragToVertragNutzungDetailsFormValues(vertrag)}
      validationSchema={vertragNutzungDetailsFormValidationSchema}
      onSubmit={(values, formikHelpers) => {
        runUpdate({
          variables: {
            objektId,
            bestandseinheitId,
            vertragId: vertrag.vertragId,
            vertragInput: mapVertragNutzungDetailsFormValuesToVertragUpdate(values, vertrag),
          },
        }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={vertragNutzungDetailsFormFields.vertragspartnerId}
            label="Vertragspartner"
            fieldHelp={getFieldHelpText('BeVertrag.vertragspartnerId')}
          >
            <RechtstraegerSelect
              id={vertragNutzungDetailsFormFields.vertragspartnerId}
              name={vertragNutzungDetailsFormFields.vertragspartnerId}
              placeholder="Vertragspartner auswählen"
              onNewItemCreated={(rechtstraegerId: string) =>
                formikProps.setFieldValue(vertragNutzungDetailsFormFields.vertragspartnerId, rechtstraegerId)
              }
              onNewItemCreateSelected={() => formikProps.setFieldValue(vertragNutzungDetailsFormFields.vertragspartnerId, '')}
              disabled={isLeerstehung(formikProps.values.vertragsart)}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={vertragNutzungDetailsFormFields.vertragsart}
            label="Vertragsart"
            fieldHelp={getFieldHelpText('BeVertrag.vertragsart')}
          >
            <VertragVertragsartFormSelect
              name={vertragNutzungDetailsFormFields.vertragsart}
              verwaltungsart={isMietObjekt(vertrag.vertragsart.value) ? Verwaltungsart.MietObjekt : Verwaltungsart.WegObjekt}
              onChange={(vertragsart, supportsExplicitVertragsEnde) => {
                !supportsExplicitVertragsEnde && formikProps.setFieldValue(vertragCreateFormFields.vertragsEnde, null);
                formikProps.setFieldValue(vertragCreateFormFields.supportsExplicitVertragsEnde, supportsExplicitVertragsEnde);
                isVertragsartTypeLeerstehung(vertragsart) &&
                  formikProps.setFieldValue(vertragCreateFormFields.vertragspartnerId, rechnungsausstellerId);
                if (isVertragsartTypeWegLeerstehung(vertragsart)) {
                  getRechnungsAusstellerSubadministration(
                    activeForFirmendatenId,
                    objektId,
                    bestandseinheitId,
                    (rechtstraegerId) => {
                      formikProps.setFieldValue(vertragCreateFormFields.vertragspartnerId, rechtstraegerId);
                    },
                    vertragsart,
                    formikProps.values.vertragsBeginn
                  );
                }
              }}
              objektId={objektId}
              bestandseinheitId={bestandseinheitId}
              vertragId={!isVertragsartTypeWegEigentuemer(vertrag.vertragsart.value) ? vertrag.vertragId : undefined}
              disabled={isVertragsartTypeWegEigentuemer(vertrag.vertragsart.value)}
            />
          </FormItemWithFieldHelp>
          <Row align="top" gutter={8}>
            <Col span={formikProps.values.supportsExplicitVertragsEnde ? 12 : 24}>
              <FormItemWithFieldHelp
                name={vertragNutzungDetailsFormFields.vertragsBeginn}
                label="Vertragsbeginn"
                fieldHelp={getFieldHelpText('BeVertrag.vertragsBeginn')}
              >
                <DatePicker
                  size="small"
                  id={vertragNutzungDetailsFormFields.vertragsBeginn}
                  name={vertragNutzungDetailsFormFields.vertragsBeginn}
                  style={{ width: '100%' }}
                  format={DATE_DISPLAY_FORMAT_DEFAULT}
                  allowClear={false}
                  onChange={(date) => {
                    if (isVertragsartTypeWegLeerstehung(formikProps.values.vertragsart)) {
                      getRechnungsAusstellerSubadministration(
                        activeForFirmendatenId,
                        objektId,
                        bestandseinheitId,
                        (rechtstraegerId) => {
                          formikProps.setFieldValue(vertragCreateFormFields.vertragspartnerId, rechtstraegerId);
                        },
                        formikProps.values.vertragsart,
                        date as unknown as Dayjs
                      );
                    }
                  }}
                />
              </FormItemWithFieldHelp>
            </Col>
            {formikProps.values.supportsExplicitVertragsEnde && (
              <Col span={12}>
                <FormItemWithFieldHelp
                  name={vertragNutzungDetailsFormFields.vertragsEnde}
                  label="Vertragsende"
                  fieldHelp={getFieldHelpText('BeVertrag.vertragsEnde')}
                >
                  <DatePicker
                    size="small"
                    id={vertragNutzungDetailsFormFields.vertragsEnde}
                    name={vertragNutzungDetailsFormFields.vertragsEnde}
                    style={{ width: '100%' }}
                    format={DATE_DISPLAY_FORMAT_DEFAULT}
                  />
                </FormItemWithFieldHelp>
              </Col>
            )}
          </Row>
          <Row align="top">
            <Col span={24}>
              <FormItemWithFieldHelp label="Merkmale" name={vertragCreateFormFields.tagList} fieldHelp={getFieldHelpText('BeVertrag.tagList')}>
                <Select id={vertragCreateFormFields.tagList} name={vertragCreateFormFields.tagList} mode="tags" placeholder="z.B. WE, BK" />
              </FormItemWithFieldHelp>
            </Col>
          </Row>
          <FormItemWithFieldHelp
            name={vertragNutzungDetailsFormFields.applyIndexedValues}
            label="Wertsicherung anwenden"
            fieldHelp={getFieldHelpText('BeVertrag.applyIndexedValues')}
          >
            <Space>
              <Switch name={vertragNutzungDetailsFormFields.applyIndexedValues} size="small" />
              <Typography.Text>
                {formikProps.values.applyIndexedValues ? 'Indexierte Werte automatisch anwenden' : 'Indexierte Werte vorlegen'}
              </Typography.Text>
            </Space>
          </FormItemWithFieldHelp>
          <FormButtons isOkLoading={loadingUpdate} onCancel={onCancel} updateMode />
        </Form>
      )}
    </Formik>
  );
};

const isLeerstehung = (vertragsart: Vertragsart) => isVertragsartTypeLeerstehung(vertragsart) || isVertragsartTypeWegLeerstehung(vertragsart);

const isMietObjekt = (vertragsart: Vertragsart) => MIET_OBJEKT_VERTRAGSART.includes(vertragsart);

const MIET_OBJEKT_VERTRAGSART = [Vertragsart.Leerstehung, Vertragsart.Mieter, Vertragsart.Eigennutzung, Vertragsart.Pauschalmieter];

export default VertragNutzungDetailsForm;
