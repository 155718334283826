import { FC } from 'react';
import { FormikProps } from 'formik';
import { useObjektInfoFeldListQuery } from './gql/ObjektInfoFeldQueries.types';
import { ObjektInfoFeldListingFiltersFormValues } from './objektInfoFeldListingFiltersFormMapper';
import ObjektInfoFeldListingFilters from './ObjektInfoFeldListingFilters';
import ObjektInfoFeldList from './List/ObjektInfoFeldList';
import { ObjektInfoFeldFragment } from './gql/objektInfoFeldFragments.types';
import { useToggle } from '../../../hooks/useToggle';

type Props = {
  formikProps: FormikProps<ObjektInfoFeldListingFiltersFormValues>;
  objektId: string;
};

const ObjektInfoFeldListingForm: FC<Props> = ({ formikProps, objektId }) => {
  const [showArchived, toggleShowArchived] = useToggle();

  const { data, loading, refetch } = useObjektInfoFeldListQuery({ variables: { objektId, includeArchiviert: showArchived } });
  const infoFeldList = data?.getObjektInfoFeldList.data ?? [];
  const infoFeldListForType = filterInfoFeldListByType(infoFeldList, formikProps);

  return (
    <>
      <ObjektInfoFeldListingFilters onSuccess={refetch} objektId={objektId} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
      <ObjektInfoFeldList infoFeldList={infoFeldListForType} loading={loading} objektId={objektId} onSuccess={refetch} />
    </>
  );
};

const filterInfoFeldListByType = (objektInfoFeldList: ObjektInfoFeldFragment[], formikProps: FormikProps<ObjektInfoFeldListingFiltersFormValues>) =>
  objektInfoFeldList.filter((infoFeld) => (formikProps.values.type ? infoFeld.type.value === formikProps.values.type : true));

export default ObjektInfoFeldListingForm;
