import { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { FibuBuchungsanweisungStatus } from '../../../../types';

type Props = Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'placeholder' | 'size' | 'showSearch'>;

const FibuBaStatusSelect: FC<Props> = ({ ...restProps }) => {
  return (
    <Select {...restProps} size="small" id={restProps.name} placeholder="Status auswählen" allowClear showSearch>
      <Select.Option value={FibuBuchungsanweisungStatus.Offen}>Offen</Select.Option>
      <Select.Option value={FibuBuchungsanweisungStatus.Verbucht}>Verbucht</Select.Option>
    </Select>
  );
};

export default FibuBaStatusSelect;
