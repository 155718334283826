import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { EuroAmount } from '../../../../components/Number';
import StatusBadge from '../../../../components/Status/StatusBadge';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { generatePathToZinslisteDetailsPageOrDiscrepancies } from '../../zinslisteUriPaths';
import { Zinsliste, ZinslisteListEntry, ZinslisteOrderBy, ZinslisteVerarbeitungsstand } from '../../../../types';
import { calculateVerarbeitungsstandVerbucht } from '../../zinslisteCalculations';
import { LoadingCol } from '../../../../components/Loading';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';

export interface RowContextInterface {
  isLoading: boolean;
  verarbeitungsstand?: ZinslisteVerarbeitungsstand;
  zinsliste?: Zinsliste;
}

export const RowContextZinsliste = React.createContext<RowContextInterface>({
  isLoading: true,
});

const LoadingDeviationListAndVerarbeitungsstand: FC<{
  children: (props: RowContextInterface) => React.ReactNode;
}> = (props) => (
  <RowContextZinsliste.Consumer>
    {(rowProps) => <LoadingCol isLoading={rowProps.isLoading}>{props.children(rowProps)}</LoadingCol>}
  </RowContextZinsliste.Consumer>
);

const zinslisteTableColumns: TableWithColSelectorColumnProps<ZinslisteListEntry>[] = [
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: ZinslisteOrderBy.ObjektKurzBezeichnung,
    sorter: true,
    render: (text, record) => <DataWithShortenedTextAndExtraInfo text={record.objektKurzbezeichnung} maxTextLength={30} />,
  },
  {
    title: 'Vorschreibungsperiode',
    defaultSelected: true,
    dataIndex: ZinslisteOrderBy.Vorschreibungsperiode,
    align: 'right',
    sorter: true,
    render: (text, record) => (
      <Typography.Text>
        {record.vorschreibungsperiode.monat}/{record.vorschreibungsperiode.jahr}
      </Typography.Text>
    ),
  },
  {
    title: 'Brutto',
    defaultSelected: true,
    dataIndex: ZinslisteOrderBy.Brutto,
    align: 'right',
    sorter: true,
    render: (text, record) => <EuroAmount value={record.sumBrutto ?? 0} />,
  },
  {
    title: 'Netto',
    defaultSelected: true,
    dataIndex: ZinslisteOrderBy.Netto,
    align: 'right',
    sorter: true,
    render: (text, record) => <EuroAmount value={record.sumNetto ?? 0} />,
  },

  {
    key: 'nichtErstellt',
    defaultSelected: true,
    title: 'nicht erstellt',
    align: 'right',
    render: () => (
      <LoadingDeviationListAndVerarbeitungsstand>{(props) => props.verarbeitungsstand?.countNichtErstellt}</LoadingDeviationListAndVerarbeitungsstand>
    ),
  },
  {
    key: 'erstellt',
    defaultSelected: true,
    title: 'erstellt',
    align: 'right',
    render: () => (
      <LoadingDeviationListAndVerarbeitungsstand>{(props) => props.verarbeitungsstand?.countErstellt}</LoadingDeviationListAndVerarbeitungsstand>
    ),
  },
  {
    key: 'ausgegeben',
    defaultSelected: true,
    title: 'ausgegeben',
    align: 'right',
    render: () => (
      <LoadingDeviationListAndVerarbeitungsstand>{(props) => props.verarbeitungsstand?.countAusgegeben}</LoadingDeviationListAndVerarbeitungsstand>
    ),
  },
  {
    key: 'verbucht',
    defaultSelected: true,
    title: 'verbucht',
    align: 'right',
    render: () => (
      <LoadingDeviationListAndVerarbeitungsstand>
        {(props) => (props.verarbeitungsstand ? calculateVerarbeitungsstandVerbucht(props.verarbeitungsstand) : null)}
      </LoadingDeviationListAndVerarbeitungsstand>
    ),
  },

  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: ZinslisteOrderBy.Status,
    sorter: true,
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },

  {
    title: 'Erstellt am',
    dataIndex: ZinslisteOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    dataIndex: ZinslisteOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    key: 'action',
    render: (text, record) => (
      <Link key={record.zinslisteId} to={generatePathToZinslisteDetailsPageOrDiscrepancies(record.objektId, record.zinslisteId, record.status)}>
        Details
      </Link>
    ),
  },
];

export default zinslisteTableColumns;
