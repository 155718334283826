import { Text } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { LabeledValue } from './types';

const Absender: FC<{ absender: LabeledValue }> = ({ absender }) => (
  <Text style={[{ fontSize: 6 }]}>
    {absender.label} {absender.value}
  </Text>
);

export default Absender;
