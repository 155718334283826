import React, { FC } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../styles/pdfStyles';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import { FinancialPlanTop } from './financialPlanTop-types';
import Absender from '../../shared/Absender';
import RechnungsEmpfaengerBlock from '../../shared/RechnungsEmpfaengerBlock';
import FPVPosList from '../shared/components/FPVPosList';
import LabelValue from '../../shared/LabelValue';

type Props = {
  data: FinancialPlanTop;
  isInEditMode?: boolean;
  selectedTextbaustein?: FinancialPlanSelectableSection;
};

export type FinancialPlanSelectableSection = 'EINLEITUNGSTEXT' | 'AUSGABENTABELLE' | 'SCHLUSSTEXT';

const FinancialPlanTopTemplate: FC<Props> = ({ data, isInEditMode, selectedTextbaustein }) => (
  <Document>
    {data.topList.map((top, index) => (
      <Page size="A4" style={[pdfStyles.body, { paddingBottom: data.footer?.value ? '32mm' : pdfStyles.body.paddingBottom }]} key={index}>
        <View>
          {/* Header */}
          <Header header={data.header} />
        </View>

        <View style={[pdfStyles.row]}>
          <View style={[pdfStyles.column, pdfStyles.addressor]}>
            <Absender absender={top.sender} />
            <View style={[{ marginBottom: '7mm' }]} />
            <RechnungsEmpfaengerBlock rechnungsEmpfaengerShippingLabel={top.rechnungsEmpfaenger} />
          </View>
        </View>

        <View style={[pdfStyles.row]}>
          <Text style={[pdfStyles.textNormal]}>{top.recipientEmailAddress}</Text>
          <Text style={[pdfStyles.textNormal, { textAlign: 'right' }]}>{top.erstellungsDatum}</Text>
        </View>

        {/* Titeltext */}
        <Text style={[{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }]}>{top.title}</Text>

        {/* Rechnungsausstellerbezeichnung + Top Bezeichnung */}
        <Text style={[pdfStyles.textNormal, { marginTop: '2mm', textAlign: 'center', fontWeight: 'bold' }]}>
          {top.rechnungsAusstellerBezeichnung} / {top.topBezeichnung}
        </Text>

        <Text style={[pdfStyles.textNormal, { textAlign: 'center' }]}>{top.letterSalutation}</Text>

        <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { marginTop: '5mm', fontWeight: 'bold' }]}>
          <Text style={[pdfStyles.textNormal, { width: '85%', textAlign: 'right' }]}>{top.objektSumText}</Text>
          <Text style={[pdfStyles.textNormal, { width: '15%', textAlign: 'right' }]}>{top.anteilText}</Text>
        </View>

        {/* Vorschreibungspositionen */}
        <FPVPosList
          vorschreibungspositionList={top.vorschreibungspositionList}
          isInEditMode={isInEditMode}
          selectedTextbaustein={selectedTextbaustein}
          isNotFullWidth
        />

        {top.vorschreibungspositionSum && (
          <LabelValue
            valueTuple={top.vorschreibungspositionSum}
            viewStyle={{ marginTop: '5mm', width: '85%', fontWeight: 'bold' }}
            labelStyle={{ fontSize: '16px' }}
            valueStyle={{ fontSize: '16px' }}
          />
        )}

        {/* Footer */}
        {data.footer?.value && <Footer footer={data.footer} />}
      </Page>
    ))}
  </Document>
);

export default FinancialPlanTopTemplate;
