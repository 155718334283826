import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BestandseinheitNutzwertVersionListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
}>;

export type BestandseinheitNutzwertVersionListQuery = {
  getBestandseinheitNutzwertVersionList: {
    data: Array<{
      beNutzwertId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      kommentar?: string | null;
      lastUpdateTs: string;
      nutzwert: number;
      validFrom: string;
      historicizedList?: Array<{
        beNutzwertId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        nutzwert: number;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BestandseinheitNutzwertVersionQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  nutzwertVersionId: Types.Scalars['ID']['input'];
}>;

export type BestandseinheitNutzwertVersionQuery = {
  getBestandseinheitNutzwertVersion: {
    data: {
      beNutzwertId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      nutzwert: number;
      historicizedList?: Array<{
        beNutzwertId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        nutzwert: number;
      }> | null;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const BestandseinheitNutzwertVersionListDocument = gql`
  query BestandseinheitNutzwertVersionList($objektId: ID!, $bestandseinheitId: ID!) {
    getBestandseinheitNutzwertVersionList(objektId: $objektId, bestandseinheitId: $bestandseinheitId) {
      data {
        beNutzwertId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        historicizedList {
          beNutzwertId
          createTs
          createdBy
          createdByMitarbeiterId
          current
          validFrom
          kommentar
          lastUpdateTs
          nutzwert
          warningList {
            attribute
            message
            type
          }
        }
        kommentar
        lastUpdateTs
        nutzwert
        validFrom
        warningList {
          attribute
          message
          type
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useBestandseinheitNutzwertVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitNutzwertVersionListQuery, BestandseinheitNutzwertVersionListQueryVariables> &
    ({ variables: BestandseinheitNutzwertVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitNutzwertVersionListQuery, BestandseinheitNutzwertVersionListQueryVariables>(
    BestandseinheitNutzwertVersionListDocument,
    options
  );
}
export function useBestandseinheitNutzwertVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitNutzwertVersionListQuery, BestandseinheitNutzwertVersionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitNutzwertVersionListQuery, BestandseinheitNutzwertVersionListQueryVariables>(
    BestandseinheitNutzwertVersionListDocument,
    options
  );
}
export function useBestandseinheitNutzwertVersionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BestandseinheitNutzwertVersionListQuery, BestandseinheitNutzwertVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitNutzwertVersionListQuery, BestandseinheitNutzwertVersionListQueryVariables>(
    BestandseinheitNutzwertVersionListDocument,
    options
  );
}
export type BestandseinheitNutzwertVersionListQueryHookResult = ReturnType<typeof useBestandseinheitNutzwertVersionListQuery>;
export type BestandseinheitNutzwertVersionListLazyQueryHookResult = ReturnType<typeof useBestandseinheitNutzwertVersionListLazyQuery>;
export type BestandseinheitNutzwertVersionListSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitNutzwertVersionListSuspenseQuery>;
export type BestandseinheitNutzwertVersionListQueryResult = Apollo.QueryResult<
  BestandseinheitNutzwertVersionListQuery,
  BestandseinheitNutzwertVersionListQueryVariables
>;
export const BestandseinheitNutzwertVersionDocument = gql`
  query BestandseinheitNutzwertVersion($objektId: ID!, $bestandseinheitId: ID!, $nutzwertVersionId: ID!) {
    getBestandseinheitNutzwertVersion(objektId: $objektId, bestandseinheitId: $bestandseinheitId, nutzwertVersionId: $nutzwertVersionId) {
      data {
        beNutzwertId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        validFrom
        historicizedList {
          beNutzwertId
          createTs
          createdBy
          createdByMitarbeiterId
          validFrom
          kommentar
          lastUpdateTs
          nutzwert
        }
        kommentar
        lastUpdateTs
        nutzwert
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useBestandseinheitNutzwertVersionQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitNutzwertVersionQuery, BestandseinheitNutzwertVersionQueryVariables> &
    ({ variables: BestandseinheitNutzwertVersionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitNutzwertVersionQuery, BestandseinheitNutzwertVersionQueryVariables>(
    BestandseinheitNutzwertVersionDocument,
    options
  );
}
export function useBestandseinheitNutzwertVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitNutzwertVersionQuery, BestandseinheitNutzwertVersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitNutzwertVersionQuery, BestandseinheitNutzwertVersionQueryVariables>(
    BestandseinheitNutzwertVersionDocument,
    options
  );
}
export function useBestandseinheitNutzwertVersionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BestandseinheitNutzwertVersionQuery, BestandseinheitNutzwertVersionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitNutzwertVersionQuery, BestandseinheitNutzwertVersionQueryVariables>(
    BestandseinheitNutzwertVersionDocument,
    options
  );
}
export type BestandseinheitNutzwertVersionQueryHookResult = ReturnType<typeof useBestandseinheitNutzwertVersionQuery>;
export type BestandseinheitNutzwertVersionLazyQueryHookResult = ReturnType<typeof useBestandseinheitNutzwertVersionLazyQuery>;
export type BestandseinheitNutzwertVersionSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitNutzwertVersionSuspenseQuery>;
export type BestandseinheitNutzwertVersionQueryResult = Apollo.QueryResult<
  BestandseinheitNutzwertVersionQuery,
  BestandseinheitNutzwertVersionQueryVariables
>;
