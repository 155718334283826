import * as Yup from 'yup';
import { validateGeneralBezeichnung } from '../../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';
import { ustKategorieEntryVersionValidationSchema } from './ustKategorieEntryValidationSchema';
import { ustKategorieFormFields } from '../Form/ustKategorieFormMapper';
import { ustKategorieVersionFormFields } from '../Version/Form/ustKategorieVersionMapper';

/* eslint-disable no-template-curly-in-string */
export const ustKategorieFormValidationSchema = Yup.object({
  [ustKategorieFormFields.kurzBezeichnung]: validateGeneralBezeichnung(50),
});
/* eslint-enable no-template-curly-in-string */

/* eslint-disable no-template-curly-in-string */
export const ustKategorieVersionFormValidationSchema = Yup.object({
  [ustKategorieVersionFormFields.ustKategorieEntryList]: Yup.array().of(ustKategorieEntryVersionValidationSchema),
});
/* eslint-enable no-template-curly-in-string */
