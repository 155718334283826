import { FC } from 'react';
import { Modal } from 'antd';
import ChangeToNichtDurchgefuehrtForm from './ChangeToNichtDurchgefuehrtForm';

type Props = {
  isOpen: boolean;
  paymentId: string;
  onCancel: () => void;
  onSubmit: () => void;
};

const ChangeToNichtDurchgefuehrtModal: FC<Props> = ({ isOpen, paymentId, onCancel, onSubmit }) => {
  return (
    <Modal
      open={isOpen}
      okText="Speichern"
      cancelText="Abbrechen"
      width="500px"
      footer={null}
      onCancel={onCancel}
      title="Überweisung auf „Nicht durchgeführt“ ändern"
    >
      <ChangeToNichtDurchgefuehrtForm onCancel={onCancel} onSubmit={onSubmit} paymentId={paymentId} />
    </Modal>
  );
};

export default ChangeToNichtDurchgefuehrtModal;
