import * as Yup from 'yup';
import { formFormFields } from './formMapper';
import { entityMustBeSelected } from '../../../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */

export const bookValidationSchema = Yup.object().shape({
  [formFormFields.buchungsdatum]: Yup.string().required(entityMustBeSelected('Buchungsdatum')),
});

/* eslint-enable no-template-curly-in-string */
