import React from 'react';

import { Link } from 'react-router-dom';
import { FilePdfOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { TableWithAlignedColsExpandedRowType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { createCells, createRowWithCells, TextWithStyling } from '../../../../BkOrWe/shared/abrechnungTableHelper';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount } from '../../../../../../components/Number';
import { HeAbrKonto } from '../../../../../../types';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { StyledHeAbrHeVertragKontoRowTable } from './heAbrHeVertragKontoRowTable.styled';
import { generatePathToVorschreibungDetailsPage } from '../../../../../Vorschreibung/vorschreibungUriPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../Rechtstraeger/rechtstraegerHelper';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';
import { generatePathToStandingOrSingleInvoiceDetailsPage } from '../../../../../IncomingInvoice/incomingInvoiceUriPaths';
import SollHabenTag from '../../../../../shared/SollHabenTag';
import { generatePathToBookingDetailsPage } from '../../../../../BookingDetails/bookingDetailsPaths';

const heAbrHeVertragKontoRowTable = (abrKreisType: string): TableWithAlignedColsExpandedRowType<HeAbrKonto>[] => {
  const headerTexts: TextWithStyling[] = [
    { render: 'Text' },
    { render: 'Belegdatum' },
    { render: 'Beleg-Nr.' },
    { render: 'Vertragspartner' },
    { render: abrKreisType },
    { render: 'Soll/Haben' },
    { render: '' },
  ];

  return [
    {
      header: () => createCells(headerTexts, true),
      table: {
        colSpanSize: [5],
        StyledComponent: StyledHeAbrHeVertragKontoRowTable,
        positionOfButton: 3,
        showCellButton: false,
      },
      body: (konto) => (
        <>
          {konto.buchungszeileList.map((bz) => {
            const texts: TextWithStyling[] = [
              {
                render: bz.auftragId ? (
                  <DataWithShortenedText maxTextLength={20} text={bz.text}>
                    {(shortenedText) => (
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      <Link to={generatePathToVorschreibungDetailsPage(bz.auftragId!)} target="_blank">
                        {shortenedText}
                      </Link>
                    )}
                  </DataWithShortenedText>
                ) : (
                  <DataWithShortenedText maxTextLength={20} text={bz.text} />
                ),
                style: { justifyContent: 'left', textAlign: 'left' },
              },
              {
                render: bz.belegDatum ? <CustomFormattedDate value={bz.belegDatum} /> : <TextForEmptyValue textToShow="minus" />,
              },
              {
                render: bz.belegFileId ? (
                  <Link
                    to={generatePathToStandingOrSingleInvoiceDetailsPage(bz.belegFileId, bz.belegId, bz.standingInvoicePartialAmountId)}
                    target="_blank"
                  >
                    {bz.belegnummer} <FilePdfOutlined />
                  </Link>
                ) : (
                  <Typography.Text>{bz.belegnummer}</Typography.Text>
                ),
              },
              {
                render: bz.vertragspartner ? (
                  <DataWithShortenedText maxTextLength={20} text={bz.vertragspartner?.kurzBezeichnung}>
                    {(shortenedText) => (
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      <Link to={generatePathToRechtstraegerDetailsPage(bz.vertragspartner!.rechtstraegerId)} target="_blank">
                        {shortenedText}
                      </Link>
                    )}
                  </DataWithShortenedText>
                ) : (
                  <TextForEmptyValue textToShow="minus" />
                ),
              },
              {
                render: (
                  <Link to={generatePathToBookingDetailsPage(bz.buchungIdList)} target="_blank">
                    <EuroAmount value={bz.betrag} />
                  </Link>
                ),
              },
              {
                render: <SollHabenTag sollHaben={bz.sollHaben} />,
              },
              { render: '' },
            ];

            return createRowWithCells(texts, false);
          })}
        </>
      ),
    },
  ];
};

export default heAbrHeVertragKontoRowTable;
