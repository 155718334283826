import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { EuroAmount } from '../../../../components/Number';
import MitarbeiterTooltip from '../../../../components/Card/MitarbeiterTooltip';
import { calculateSaldoOffenerBetrag } from '../../opOffenerBetragSaldoCalculator';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { Auszifferung, OffenePosten } from '../../../../types';
import StorniertStatus from '../../../shared/StorniertStatus';
import { generatePathToBookingDetailsPage, generatePathToBookingDetailsWithBookingSuggestionPage } from '../../../BookingDetails/bookingDetailsPaths';
import AuszifferungActionColumn from './AuszifferungActionColumn';

export const offenePostenAuszifferungTableColumns = (offenePosten: OffenePosten, onAction: () => void): ColumnProps<Auszifferung>[] => [
  {
    title: 'Betrag',
    dataIndex: 'betrag',
    width: 491, // calculated based on sum of first four columns in offenePostenTableColumns + 21px margin-left
    align: 'right',
    render: (text, record) => (
      <Typography.Text delete={!!record.storniertTs}>
        <EuroAmount value={record.betrag} />
      </Typography.Text>
    ),
  },
  {
    title: 'Saldo offener Betrag',
    dataIndex: 'offenerBetrag',
    width: 150, // this width should be same as 'Offener Betrag' column width in offenePostenTableColumns.tsx
    align: 'right',
    render: (text, record) => {
      const offenerBetrag = calculateSaldoOffenerBetrag(offenePosten.gesamtBetrag, offenePosten.auszifferungList, record);
      return (
        <Typography.Text delete={!!record.storniertTs}>
          <EuroAmount value={offenerBetrag} />
        </Typography.Text>
      );
    },
  },
  {
    title: 'Auszifferung',
    dataIndex: 'auszifferung',
    width: 280,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Erfasst von',
    dataIndex: 'erfasstVon',
    width: 100,
    align: 'left',
    render: (text, record) => <MitarbeiterTooltip showsInitials mitarbeiterId={record.createdByMitarbeiterId} alignment="left" />,
  },
  {
    dataIndex: 'zahlung',
    render: (text, record) => {
      const linkPath = record.bookingSuggestionId
        ? generatePathToBookingDetailsWithBookingSuggestionPage(record.bookingSuggestionId, [record.buchungId], false)
        : generatePathToBookingDetailsPage([record.buchungId]);

      return (
        <Link to={linkPath} target="_blank">
          {record.source.text}
        </Link>
      );
    },
  },
  {
    dataIndex: 'stornoStatus',
    width: 300,
    render: (text, record) => {
      if (record.storniertTs && record.storniertByMitarbeiterId) {
        return <StorniertStatus storniertByMitarbeiterId={record.storniertByMitarbeiterId} storniertTs={record.storniertTs} />;
      }
      return null;
    },
  },
  {
    title: 'Aktion',
    dataIndex: 'stornoAuszifferung',
    align: 'right',
    width: 90,
    render: (text, record) => {
      return !record.storniert && record.zahlungId ? (
        <AuszifferungActionColumn
          offenePosten={offenePosten}
          auszifferungId={record.auszifferungId}
          zahlungId={record.zahlungId}
          onAction={onAction}
        />
      ) : null;
    },
  },
];
