import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  BelegVerarbeitungEntryFiltersFormValues,
  belegVerarbEntryListingFiltersFormInitialValues,
  mapFormValuesToBelegVerarbeitungFilters,
} from './Filters/ListingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import VorschreibungAusgebenVerarbeitungEntryTable from './VorschreibungAusgebenVerarbeitungEntryTable';
import { useQueryParams } from '../../../../../hooks/useStringQueryParams';
import { TBelegVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { verarbeitungTypes } from '../../../verarbeitungHelpers';
import { EigenbelegErstellenGenerierlauf } from '../../../../../types';

type Props = {
  generierlauf: EigenbelegErstellenGenerierlauf;
};

const VorschreibungAusgebenVerarbeitungEntryListing: FC<Props> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TBelegVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.VorschreibungAusgeben, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: BelegVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.VorschreibungAusgeben,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToBelegVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<BelegVerarbeitungEntryFiltersFormValues> initialValues={belegVerarbEntryListingFiltersFormInitialValues(queryParams)} onSubmit={onChange}>
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />
          <VorschreibungAusgebenVerarbeitungEntryTable queryParams={queryParams} generierlaufId={generierlauf.generierlaufId} />
        </>
      )}
    </Formik>
  );
};

export default VorschreibungAusgebenVerarbeitungEntryListing;
