import styled from 'styled-components';
import { Space } from 'antd';

export const StyledSpaceForItemContent = styled(Space)`
  align-items: stretch;
  height: 100%;
  width: 460px;
  border: 1px solid #d9d9d9;
`;

export const StyledSpaceForSettingsDetails = styled(Space)`
  width: 380px;
  padding: 10px 0 20px;
`;
