import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import ContactPersonFormListItem from './ContactPersonFormListItem';
import { IContactPersonCreateOrUpdateInput } from './TContactPerson';

type Props = {
  contactPersonList: IContactPersonCreateOrUpdateInput[];
  // feels weird
  removeContactPerson: (index: number) => () => void;
  getEditFocusOnContactPerson: (contactPerson: IContactPersonCreateOrUpdateInput, index: number) => () => void;
};

const ContactPersonFormList: FC<Props> = ({ contactPersonList = [], removeContactPerson, getEditFocusOnContactPerson }) => {
  return (
    <>
      {!isEmpty(contactPersonList)
        ? contactPersonList.map((contactPerson, index) => (
            <ContactPersonFormListItem
              key={contactPerson.contactPersonId || index}
              contactPerson={contactPerson}
              removeContactPerson={removeContactPerson(index)}
              getEditFocusOnContactPerson={getEditFocusOnContactPerson(contactPerson, index)}
            />
          ))
        : null}
    </>
  );
};

export default ContactPersonFormList;
