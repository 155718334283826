import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import SaldenlisteListing from '../../features/Saldenliste/SaldenlisteListing';

const SaldenlistePage = () => {
  return (
    <>
      <Helmet>
        <title>Saldenliste</title>
      </Helmet>
      <PageLayout>
        <SaldenlisteListing />
      </PageLayout>
    </>
  );
};

export default SaldenlistePage;
