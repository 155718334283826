import React, { FC } from 'react';
import { Input } from 'formik-antd';
import ParamFormItem from '../../../../../../components/Form/ParamFormItem';

type Props = {
  name: string;
  placeholder: string;
};

const ParamFormItemBezeichnung: FC<Props> = ({ name, placeholder }) => (
  <ParamFormItem name={name} label="Bezeichnung">
    <Input name={name} id={name} placeholder={placeholder} style={{ width: '100%' }} size="small" />
  </ParamFormItem>
);

export default ParamFormItemBezeichnung;
