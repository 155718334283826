import React, { FC } from 'react';
import { Checkbox, Space } from 'antd';
import { StyledCheckboxForFilter } from './styled/StyledTableWithFilter.style';
import TruncatedTextWithTooltip from '../../Helpers/TruncatedTextWithTooltip';
import { ColumnSelectorSetting } from './tableWithColSelectorHelpers';

type ColumnSelectorCheckboxFilterProps = {
  allColumnTitles: (string | string[])[];
  defaultSelectedColumnsTitles: string[];
  onSelectionChange: (selectedColumnSettings: ColumnSelectorSetting[]) => void;
  selectedColumns: string[];
};

const ColumnSelectorCheckboxFilter: FC<ColumnSelectorCheckboxFilterProps> = ({
  allColumnTitles,
  defaultSelectedColumnsTitles,
  onSelectionChange,
  selectedColumns,
}) => {
  const titleList = allColumnTitles.map((title) => (Array.isArray(title) ? title : [title]));

  return (
    <StyledCheckboxForFilter
      defaultValue={defaultSelectedColumnsTitles}
      onChange={(selectedColumns) =>
        onSelectionChange(
          titleList
            .flatMap((title) => title)
            .map((title) => ({
              title,
              selected: selectedColumns.includes(title),
            }))
        )
      }
      value={selectedColumns}
    >
      {titleList.map((titles) => {
        const filterTitle = titles[0];

        return (
          <Checkbox key={filterTitle} value={filterTitle} disabled={isLastSelected(selectedColumns, filterTitle)}>
            <Space size={0} direction="vertical" style={{ width: '100%' }}>
              {titles.map((title, index) => (
                <TruncatedTextWithTooltip tooltipProps={{ placement: 'topLeft' }} text={title} key={index} />
              ))}
            </Space>
          </Checkbox>
        );
      })}
    </StyledCheckboxForFilter>
  );
};

const isLastSelected = (selectedColumns: string[], column: string) => selectedColumns.length === 1 && selectedColumns[0] === column;

export default ColumnSelectorCheckboxFilter;
