import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePersonenGemeinschaftMutationVariables = Types.Exact<{
  request: Types.PersonenGemeinschaftCreateInput;
}>;

export type CreatePersonenGemeinschaftMutation = {
  createPersonenGemeinschaft: {
    data: { rechtstraegerId: string; kurzBezeichnung: string; type: { value: Types.RechtstraegerType } };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdatePersonenGemeinschaftMutationVariables = Types.Exact<{
  personenGemeinschaftId: Types.Scalars['ID']['input'];
  request: Types.PersonenGemeinschaftUpdateInput;
}>;

export type UpdatePersonenGemeinschaftMutation = {
  updatePersonenGemeinschaft: {
    data: { rechtstraegerId: string; kurzBezeichnung: string; type: { value: Types.RechtstraegerType } };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeletePersonenGemeinschaftMutationVariables = Types.Exact<{
  personenGemeinschaftId: Types.Scalars['ID']['input'];
}>;

export type DeletePersonenGemeinschaftMutation = {
  deletePersonenGemeinschaft: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export type SperrenPersonenGemeinschaftMutationVariables = Types.Exact<{
  personenGemeinschaftId: Types.Scalars['ID']['input'];
}>;

export type SperrenPersonenGemeinschaftMutation = {
  actionSperrenPersonenGemeinschaft: {
    data: { rechtstraegerId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type EntsperrenPersonenGemeinschaftMutationVariables = Types.Exact<{
  personenGemeinschaftId: Types.Scalars['ID']['input'];
}>;

export type EntsperrenPersonenGemeinschaftMutation = {
  actionEntsperrenPersonenGemeinschaft: {
    data: { rechtstraegerId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type ArchivePersonenGemeinschaftMutationVariables = Types.Exact<{
  personenGemeinschaftId: Types.Scalars['ID']['input'];
}>;

export type ArchivePersonenGemeinschaftMutation = {
  actionArchivePersonenGemeinschaft: {
    data: { rechtstraegerId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UnarchivePersonenGemeinschaftMutationVariables = Types.Exact<{
  personenGemeinschaftId: Types.Scalars['ID']['input'];
}>;

export type UnarchivePersonenGemeinschaftMutation = {
  actionUnarchivePersonenGemeinschaft: {
    data: { rechtstraegerId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdatePersonenGemeinschaftAddressMutationVariables = Types.Exact<{
  personenGemeinschaftId: Types.Scalars['ID']['input'];
  addressId: Types.Scalars['ID']['input'];
  request: Types.AddressUpdateInput;
}>;

export type UpdatePersonenGemeinschaftAddressMutation = {
  updatePersonenGemeinschaftAddress: {
    data: { addressId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreatePersonenGemeinschaftDocument = gql`
  mutation CreatePersonenGemeinschaft($request: PersonenGemeinschaftCreateInput!) {
    createPersonenGemeinschaft(request: $request) {
      data {
        rechtstraegerId
        kurzBezeichnung
        type {
          value
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreatePersonenGemeinschaftMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePersonenGemeinschaftMutation, CreatePersonenGemeinschaftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePersonenGemeinschaftMutation, CreatePersonenGemeinschaftMutationVariables>(
    CreatePersonenGemeinschaftDocument,
    options
  );
}
export type CreatePersonenGemeinschaftMutationHookResult = ReturnType<typeof useCreatePersonenGemeinschaftMutation>;
export type CreatePersonenGemeinschaftMutationResult = Apollo.MutationResult<CreatePersonenGemeinschaftMutation>;
export type CreatePersonenGemeinschaftMutationOptions = Apollo.BaseMutationOptions<
  CreatePersonenGemeinschaftMutation,
  CreatePersonenGemeinschaftMutationVariables
>;
export const UpdatePersonenGemeinschaftDocument = gql`
  mutation UpdatePersonenGemeinschaft($personenGemeinschaftId: ID!, $request: PersonenGemeinschaftUpdateInput!) {
    updatePersonenGemeinschaft(personenGemeinschaftId: $personenGemeinschaftId, request: $request) {
      data {
        rechtstraegerId
        kurzBezeichnung
        type {
          value
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdatePersonenGemeinschaftMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePersonenGemeinschaftMutation, UpdatePersonenGemeinschaftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePersonenGemeinschaftMutation, UpdatePersonenGemeinschaftMutationVariables>(
    UpdatePersonenGemeinschaftDocument,
    options
  );
}
export type UpdatePersonenGemeinschaftMutationHookResult = ReturnType<typeof useUpdatePersonenGemeinschaftMutation>;
export type UpdatePersonenGemeinschaftMutationResult = Apollo.MutationResult<UpdatePersonenGemeinschaftMutation>;
export type UpdatePersonenGemeinschaftMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonenGemeinschaftMutation,
  UpdatePersonenGemeinschaftMutationVariables
>;
export const DeletePersonenGemeinschaftDocument = gql`
  mutation DeletePersonenGemeinschaft($personenGemeinschaftId: ID!) {
    deletePersonenGemeinschaft(personenGemeinschaftId: $personenGemeinschaftId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeletePersonenGemeinschaftMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePersonenGemeinschaftMutation, DeletePersonenGemeinschaftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePersonenGemeinschaftMutation, DeletePersonenGemeinschaftMutationVariables>(
    DeletePersonenGemeinschaftDocument,
    options
  );
}
export type DeletePersonenGemeinschaftMutationHookResult = ReturnType<typeof useDeletePersonenGemeinschaftMutation>;
export type DeletePersonenGemeinschaftMutationResult = Apollo.MutationResult<DeletePersonenGemeinschaftMutation>;
export type DeletePersonenGemeinschaftMutationOptions = Apollo.BaseMutationOptions<
  DeletePersonenGemeinschaftMutation,
  DeletePersonenGemeinschaftMutationVariables
>;
export const SperrenPersonenGemeinschaftDocument = gql`
  mutation SperrenPersonenGemeinschaft($personenGemeinschaftId: ID!) {
    actionSperrenPersonenGemeinschaft(personenGemeinschaftId: $personenGemeinschaftId) {
      data {
        rechtstraegerId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useSperrenPersonenGemeinschaftMutation(
  baseOptions?: Apollo.MutationHookOptions<SperrenPersonenGemeinschaftMutation, SperrenPersonenGemeinschaftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SperrenPersonenGemeinschaftMutation, SperrenPersonenGemeinschaftMutationVariables>(
    SperrenPersonenGemeinschaftDocument,
    options
  );
}
export type SperrenPersonenGemeinschaftMutationHookResult = ReturnType<typeof useSperrenPersonenGemeinschaftMutation>;
export type SperrenPersonenGemeinschaftMutationResult = Apollo.MutationResult<SperrenPersonenGemeinschaftMutation>;
export type SperrenPersonenGemeinschaftMutationOptions = Apollo.BaseMutationOptions<
  SperrenPersonenGemeinschaftMutation,
  SperrenPersonenGemeinschaftMutationVariables
>;
export const EntsperrenPersonenGemeinschaftDocument = gql`
  mutation EntsperrenPersonenGemeinschaft($personenGemeinschaftId: ID!) {
    actionEntsperrenPersonenGemeinschaft(personenGemeinschaftId: $personenGemeinschaftId) {
      data {
        rechtstraegerId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useEntsperrenPersonenGemeinschaftMutation(
  baseOptions?: Apollo.MutationHookOptions<EntsperrenPersonenGemeinschaftMutation, EntsperrenPersonenGemeinschaftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EntsperrenPersonenGemeinschaftMutation, EntsperrenPersonenGemeinschaftMutationVariables>(
    EntsperrenPersonenGemeinschaftDocument,
    options
  );
}
export type EntsperrenPersonenGemeinschaftMutationHookResult = ReturnType<typeof useEntsperrenPersonenGemeinschaftMutation>;
export type EntsperrenPersonenGemeinschaftMutationResult = Apollo.MutationResult<EntsperrenPersonenGemeinschaftMutation>;
export type EntsperrenPersonenGemeinschaftMutationOptions = Apollo.BaseMutationOptions<
  EntsperrenPersonenGemeinschaftMutation,
  EntsperrenPersonenGemeinschaftMutationVariables
>;
export const ArchivePersonenGemeinschaftDocument = gql`
  mutation ArchivePersonenGemeinschaft($personenGemeinschaftId: ID!) {
    actionArchivePersonenGemeinschaft(personenGemeinschaftId: $personenGemeinschaftId) {
      data {
        rechtstraegerId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useArchivePersonenGemeinschaftMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchivePersonenGemeinschaftMutation, ArchivePersonenGemeinschaftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchivePersonenGemeinschaftMutation, ArchivePersonenGemeinschaftMutationVariables>(
    ArchivePersonenGemeinschaftDocument,
    options
  );
}
export type ArchivePersonenGemeinschaftMutationHookResult = ReturnType<typeof useArchivePersonenGemeinschaftMutation>;
export type ArchivePersonenGemeinschaftMutationResult = Apollo.MutationResult<ArchivePersonenGemeinschaftMutation>;
export type ArchivePersonenGemeinschaftMutationOptions = Apollo.BaseMutationOptions<
  ArchivePersonenGemeinschaftMutation,
  ArchivePersonenGemeinschaftMutationVariables
>;
export const UnarchivePersonenGemeinschaftDocument = gql`
  mutation UnarchivePersonenGemeinschaft($personenGemeinschaftId: ID!) {
    actionUnarchivePersonenGemeinschaft(personenGemeinschaftId: $personenGemeinschaftId) {
      data {
        rechtstraegerId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUnarchivePersonenGemeinschaftMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchivePersonenGemeinschaftMutation, UnarchivePersonenGemeinschaftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchivePersonenGemeinschaftMutation, UnarchivePersonenGemeinschaftMutationVariables>(
    UnarchivePersonenGemeinschaftDocument,
    options
  );
}
export type UnarchivePersonenGemeinschaftMutationHookResult = ReturnType<typeof useUnarchivePersonenGemeinschaftMutation>;
export type UnarchivePersonenGemeinschaftMutationResult = Apollo.MutationResult<UnarchivePersonenGemeinschaftMutation>;
export type UnarchivePersonenGemeinschaftMutationOptions = Apollo.BaseMutationOptions<
  UnarchivePersonenGemeinschaftMutation,
  UnarchivePersonenGemeinschaftMutationVariables
>;
export const UpdatePersonenGemeinschaftAddressDocument = gql`
  mutation UpdatePersonenGemeinschaftAddress($personenGemeinschaftId: ID!, $addressId: ID!, $request: AddressUpdateInput!) {
    updatePersonenGemeinschaftAddress(personenGemeinschaftId: $personenGemeinschaftId, addressId: $addressId, request: $request) {
      data {
        addressId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdatePersonenGemeinschaftAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePersonenGemeinschaftAddressMutation, UpdatePersonenGemeinschaftAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePersonenGemeinschaftAddressMutation, UpdatePersonenGemeinschaftAddressMutationVariables>(
    UpdatePersonenGemeinschaftAddressDocument,
    options
  );
}
export type UpdatePersonenGemeinschaftAddressMutationHookResult = ReturnType<typeof useUpdatePersonenGemeinschaftAddressMutation>;
export type UpdatePersonenGemeinschaftAddressMutationResult = Apollo.MutationResult<UpdatePersonenGemeinschaftAddressMutation>;
export type UpdatePersonenGemeinschaftAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonenGemeinschaftAddressMutation,
  UpdatePersonenGemeinschaftAddressMutationVariables
>;
