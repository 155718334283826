import React from 'react';
import { Link } from 'react-router-dom';
import { StatusEnum } from '../../../../helpers/statusHelper';
import { MitarbeiterTooltip } from '../../../../components/Card';
import StatusBadge from '../../../../components/Status/StatusBadge';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import { generatePathToKontoDetailsPage } from '../../kontoUriPaths';
import { Konto, KontoOrderBy } from '../../../../types';
import KontoBezug from '../../KontoDetails/KontoBezug/KontoBezug';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { EMPTY_VALUE_WITH_MINUS } from '../../../../components/Text/TextForEmptyValue';

const kontoTableColumns: TableWithColSelectorColumnProps<Konto>[] = [
  {
    title: 'Nummer',
    defaultSelected: true,
    dataIndex: KontoOrderBy.Nummer,
    sorter: true,
    width: 80,
    render: (text, record) => <DataWithStatus text={record.nummer} status={record.status} showBadgeDot={false} showStatusDescription={false} />,
  },
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    dataIndex: KontoOrderBy.Bezeichnung,
    sorter: true,
    width: 150,
    render: (text, record) => <DataWithStatus status={record.status} text={record.bezeichnung} maxTextLength={20} />,
  },
  {
    title: 'Klasse',
    defaultSelected: true,
    dataIndex: KontoOrderBy.Klasse,
    sorter: true,
    width: 150,
    render: (text, record) => (
      <DataWithStatus text={record.klasse.text} maxTextLength={20} status={record.status} showBadgeDot={false} showStatusDescription={false} />
    ),
  },
  {
    title: 'Typ',
    defaultSelected: true,
    dataIndex: KontoOrderBy.Type,
    sorter: true,
    width: 130,
    render: (text, record) => <DataWithStatus text={record.type.text} status={record.status} showBadgeDot={false} showStatusDescription={false} />,
  },
  {
    title: 'Verwendung',
    defaultSelected: true,
    dataIndex: KontoOrderBy.Verwendung,
    sorter: true,
    width: 120,
    render: (text, record) => (
      <DataWithStatus
        text={record.verwendung?.text ?? EMPTY_VALUE_WITH_MINUS}
        status={record.status}
        showBadgeDot={false}
        showStatusDescription={false}
      />
    ),
  },
  {
    title: 'Bezug',
    defaultSelected: true,
    render: (text, record) => <KontoBezug konto={record} />,
  },
  {
    title: 'Status',
    defaultSelected: true,
    filters: [
      { text: 'Aktiv', value: StatusEnum.Aktiv },
      { text: 'Archiviert', value: StatusEnum.Archiviert },
    ],
    onFilter: (value, record) => record.status.value.includes(value as string),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    dataIndex: KontoOrderBy.CreateTs,
    sorter: true,
    width: 100,
    render: (text, record) => (
      <DataWithStatus text={record.createTs} status={record.status} showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    width: 70,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <Link to={generatePathToKontoDetailsPage(record.kontoId)}>Details</Link>,
  },
];

export default kontoTableColumns;
