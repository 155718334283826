import { Typography } from 'antd';
import React, { FC } from 'react';
import LinkToBelegFile from '../../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { ObjAbrBuchungszeile } from '../../../../../types';

type Props = {
  buchungszeile: ObjAbrBuchungszeile;
};

const BelegnummerWithLinkToPDF: FC<Props> = ({ buchungszeile }) => {
  if (buchungszeile.belegnummer) {
    return buchungszeile.belegFileId ? (
      <LinkToBelegFile fileId={buchungszeile.belegFileId} belegnummer={buchungszeile.belegnummer} />
    ) : (
      <Typography.Text>{Number(buchungszeile.belegnummer)}</Typography.Text>
    );
  } else return <TextForEmptyValue textToShow="minus" />;
};

export default BelegnummerWithLinkToPDF;
