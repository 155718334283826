import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { KontierungstabelleFieldsFragmentDoc } from '../../../Kontierungstabelle/gql/KontierungstabelleFragments.types';
export type VorschreibungspositionVersionFragment = {
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  current: boolean;
  deletable?: boolean | null;
  kommentar?: string | null;
  lastUpdateTs: string;
  validFrom: string;
  vorschreibungspositionVersionId: string;
  historicizedList?: Array<{
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    current: boolean;
    deletable?: boolean | null;
    kommentar?: string | null;
    lastUpdateTs: string;
    validFrom: string;
    vorschreibungspositionVersionId: string;
    kontierungstabelle: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    umsatzsteuerkennzeichen: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kuerzel: string;
      umsatzsteuerkennzeichenId: string;
      status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    };
    warningList: Array<{ message: string; type: string; attribute?: string | null }>;
  }> | null;
  kontierungstabelle: {
    basiskonto: number;
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    kontierungstabelleId: string;
    updatedByMitarbeiterId?: string | null;
    errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
    status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
    subAdministrationExpenseAccount?: {
      bezeichnung: string;
      firmendatenId?: string | null;
      kontoId: string;
      kontoKlasse: string;
      nummer: string;
    } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  umsatzsteuerkennzeichen: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    kuerzel: string;
    umsatzsteuerkennzeichenId: string;
    status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
    warningList: Array<{ message: string; type: string; attribute?: string | null }>;
  };
  warningList: Array<{ message: string; type: string; attribute?: string | null }>;
};

export const VorschreibungspositionVersionFragmentDoc = gql`
  fragment VorschreibungspositionVersion on VorschreibungspositionVersion {
    createTs
    createdBy
    createdByMitarbeiterId
    current
    deletable
    historicizedList {
      createTs
      createdBy
      createdByMitarbeiterId
      current
      deletable
      kontierungstabelle {
        ...KontierungstabelleFields
      }
      kommentar
      lastUpdateTs
      umsatzsteuerkennzeichen {
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        kuerzel
        status {
          text
          value
        }
        umsatzsteuerkennzeichenId
        warningList {
          message
          type
          attribute
        }
      }
      validFrom
      vorschreibungspositionVersionId
      warningList {
        message
        type
        attribute
      }
    }
    kontierungstabelle {
      ...KontierungstabelleFields
    }
    kommentar
    lastUpdateTs
    umsatzsteuerkennzeichen {
      bezeichnung
      createTs
      createdBy
      createdByMitarbeiterId
      kuerzel
      status {
        text
        value
      }
      umsatzsteuerkennzeichenId
      warningList {
        message
        type
        attribute
      }
    }
    validFrom
    vorschreibungspositionVersionId
    warningList {
      message
      type
      attribute
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
