import * as Yup from 'yup';
import { streetAddressValidationSchema } from '../../../shared/components/Address/form/addressFieldsValidationSchema';

export const tempHauptAddressValidationSchemaOptional = Yup.lazy((value) => {
  if (value !== undefined) {
    return tempHauptAddressValidationSchema;
  }
  return Yup.mixed().notRequired();
});

export const tempHauptAddressValidationSchema = streetAddressValidationSchema;
