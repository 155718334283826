import React, { FC } from 'react';
import { Button, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EllipsisOutlined, FileSearchOutlined, ReadOutlined } from '@ant-design/icons';
import { showSuccessMsgDelete, showSuccessMsgOther } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useGoBack } from '../../../shared/GoBack/GoBackContext';
import { URI_DASHBOARD_WORKSPACE_PAGE } from '../../../constants/appUriPaths';
import { isStatusValid, isUstVomAufwandBuchungStatusVerbucht, isUstVomAufwandBuchungStatusWirdVerbucht } from '../../../helpers/statusHelper';
import { useDeleteUstVomAufwandMutation, useUstVomAufwandMarkUnderReviewMutation } from '../gql/UstVomAufwandMutations.types';
import { useToggle } from '../../../hooks/useToggle';
import UstVomAufwandFreigebenModal from './FreigebenModal/UstVomAufwandFreigebenModal';
import { UstVomAufwand } from '../../../types';

type Props = {
  ustVomAufwand: UstVomAufwand;
  objektId: string;
  onAction: () => void;
};

const UstVomAufwandActions: FC<Props> = ({ ustVomAufwand, objektId, onAction }) => {
  const goBack = useGoBack(URI_DASHBOARD_WORKSPACE_PAGE);

  const entity = `Ust. vom Aufwand`;

  const [runDelete] = useDeleteUstVomAufwandMutation({
    variables: { ustVomAufwandId: ustVomAufwand.ustVomAufwandId, objektId },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      goBack();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runMarkUnderReview] = useUstVomAufwandMarkUnderReviewMutation({
    variables: { ustVomAufwandId: ustVomAufwand.ustVomAufwandId, objektId },
    onCompleted: () => {
      showSuccessMsgOther(`${entity} wird überprüft`);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [isCollapsedModal, onCollapseModal] = useToggle();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Verbuchen',
      onClick: onCollapseModal,
      icon: <ReadOutlined />,
      disabled:
        isUstVomAufwandBuchungStatusVerbucht(ustVomAufwand.buchungStatus) ||
        isUstVomAufwandBuchungStatusWirdVerbucht(ustVomAufwand.buchungStatus) ||
        !isStatusValid(ustVomAufwand.status) ||
        ustVomAufwand.sumUstVomAufwand === 0,
    },
    {
      key: '2',
      label: 'Prüfen',
      onClick: () => showConfirmMarkUnderReview(ustVomAufwand, runMarkUnderReview),
      icon: <FileSearchOutlined />,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: () => showConfirmDelete(ustVomAufwand, runDelete),
      icon: <DeleteOutlined />,
      danger: true,
      disabled:
        isUstVomAufwandBuchungStatusVerbucht(ustVomAufwand.buchungStatus) || isUstVomAufwandBuchungStatusWirdVerbucht(ustVomAufwand.buchungStatus),
    },
  ];

  const onActionSuccess = () => {
    onAction();
    onCollapseModal();
  };

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
      <UstVomAufwandFreigebenModal ustVomAufwand={ustVomAufwand} onAction={onActionSuccess} visible={isCollapsedModal} objektId={objektId} />
    </>
  );
};

const showConfirmDelete = (ustVomAufwand: UstVomAufwand, runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie diese Ust. vom Aufwand löschen?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmMarkUnderReview = (ustVomAufwand: UstVomAufwand, runMarkUnderReview: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie diese Ust. vom Aufwand prüfen?',
    okText: 'Prüfen',
    cancelText: 'Abbrechen',
    onOk() {
      return runMarkUnderReview();
    },
  });
};

export default UstVomAufwandActions;
