import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { ContactFieldsFragmentDoc, ContactPersonFieldsFragmentDoc } from '../../../../Contact/gql/ContactFragments.types';
import { AddressFieldsFragmentDoc } from '../../../../Address/gql/AddressFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
export type DokumentenversandEntryFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  dokumentenversandEntryId: string;
  emailAktiv: boolean;
  emailHaupt: boolean;
  postAktiv: boolean;
  postHaupt: boolean;
  emailContact?: {
    contactId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    tagList: Array<string>;
    type: string;
    wert: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  missingEmailContactReason?: { text: string; value: Types.MissingContactReason } | null;
  missingPostAddressReason?: { text: string; value: Types.MissingContactReason } | null;
  postAddress?: {
    addressId: string;
    city: string;
    cityAdditionalInformation?: string | null;
    countryCodeIso2: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    houseEntranceApartmentNumber?: string | null;
    postofficeBoxNumber?: string | null;
    postofficeBoxZipCode?: string | null;
    street?: string | null;
    tagList: Array<string>;
    type: Types.AddressType;
    zipCode?: string | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  rechtstraeger: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const DokumentenversandEntryFragmentDoc = gql`
  fragment dokumentenversandEntry on DokumentenversandEntry {
    createTs
    createdBy
    createdByMitarbeiterId
    dokumentenversandEntryId
    emailAktiv
    emailContact {
      ...ContactFields
    }
    emailHaupt
    missingEmailContactReason {
      text
      value
    }
    missingPostAddressReason {
      text
      value
    }
    postAddress {
      ...AddressFields
    }
    postAktiv
    postHaupt
    rechtstraeger {
      ...RechtstraegerBaseFields
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${ContactFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
