import React, { FC } from 'react';
import styled from 'styled-components';
import { Box as BoxBase } from 'rebass';

interface SpacerProps {
  height?: number;
}

interface InnerColumnProps {
  color?: string;
}

interface StyledSpaceProps {
  height?: number;
}

const StyledInnerColumn = styled(BoxBase)`
  margin: ${(props) => (props.margin ? '10px' : '')};
  background-color: transparent;
`;

const InnerColumn: FC<InnerColumnProps> = ({ color, ...props }) => <StyledInnerColumn color={color} {...props} />;

const StyledSpace = styled(InnerColumn)<StyledSpaceProps>`
  height: ${(props) => (props.height ? `${props.height}px` : '8px')};
  border: none;
`;

const Spacer: FC<SpacerProps> = ({ height, ...props }) => <StyledSpace height={height} {...props} />;

export { Spacer };
