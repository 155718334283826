import React, { FC } from 'react';
import { Empty } from 'antd';
import { BestandseinheitBase, Budgeting, BudgetingResultVertrag, BudgetingResultVertragVPosWert } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import budgetingResultVertragVPosWertTableColumns from './budgetingResultVertragVPosWertTableColumns';
import BudgetingResultAufteilungsschluesselTable from '../Level4/BudgetingResultAufteilungsschluesselTable';

type Props = {
  budgeting: Budgeting;
  bestandseinheit: BestandseinheitBase;
  budgetingResultVertrag: BudgetingResultVertrag;
};

const BudgetingResultVertragVPosWertTable: FC<Props> = ({ budgeting, bestandseinheit, budgetingResultVertrag }) => {
  const { budgetingResultVertragVPosWertList, vertrag } = budgetingResultVertrag;

  return (
    <NestedTable<BudgetingResultVertragVPosWert>
      colWidth={100}
      level={3}
      locale={{
        emptyText: <Empty description={<span>Keine Vertragswerte vorhanden</span>} />,
      }}
      dataSource={budgetingResultVertragVPosWertList}
      columns={budgetingResultVertragVPosWertTableColumns(budgeting, bestandseinheit, vertrag)}
      rowKey={(record) => record.vertragVorschreibungsposition.vorschreibungspositionId}
      expandedRowKey="vorschreibungspositionId"
      useHighlighting
      expandable={{
        rowExpandable: (record) => !!record.budgetingResultAufteilungsschluesselList.length,
        expandedRowRender: (record) => (
          <BudgetingResultAufteilungsschluesselTable
            budgeting={budgeting}
            bestandseinheit={bestandseinheit}
            budgetingResultAufteilungsschluesselList={record.budgetingResultAufteilungsschluesselList}
          />
        ),
      }}
    />
  );
};
export default BudgetingResultVertragVPosWertTable;
