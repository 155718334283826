import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { VValuePreservationInformation } from '../../vorschreibung-types';
import ValuePreservationInformationColumnTitles from './ValuePreservationInformationColumnTitles';
import ValuePreservationIndexationInformationTable from './ValuePreservationIndexationInformationTable';

type Props = {
  valuePreservationInformation: VValuePreservationInformation;
};

const ValuePreservationInformationBlock: FC<Props> = ({ valuePreservationInformation }) => (
  <View style={{ margin: '5mm 0' }}>
    {valuePreservationInformation.indexationInformationText && (
      <Text style={[pdfStyles.textNormal, { marginBottom: '5mm' }]}>{valuePreservationInformation.indexationInformationText}</Text>
    )}

    <ValuePreservationInformationColumnTitles listHeader={valuePreservationInformation.valuePreservationListHeader} />

    {valuePreservationInformation.valuePreservationList.map((be, index) => (
      <ValuePreservationIndexationInformationTable
        key={index}
        valuePreservationIndexationInformationList={be.valuePreservationIndexationInformationList}
        bestandseinheit={be.bestandseinheit}
      />
    ))}
  </View>
);

export default ValuePreservationInformationBlock;
