import { get } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Input } from 'formik-antd';
import { FormikProps } from 'formik';

import { validateVatIdNumber } from './vatIdNumberValidation';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import useDebounce from '../../../../hooks/useDebounce';
import { ErrorData } from '../../../../types';
import { FirmaFormValues } from '../../../../features/Rechtstraeger/Firma/Form/firmaFormMapper';
import { FirmendatenFormValues } from '../../../../features/KundenSystem/Firmendaten/form/fimendatenFormMapper';
import { PersonGemeinschaftFormValues } from '../../../../features/Rechtstraeger/PersonenGemeinschaft/Form/personenGemeinschaftFormMapper';

type Props = {
  onValidationSuccess: (data: any) => void;
  formikProps: FormikProps<FirmaFormValues> | FormikProps<FirmendatenFormValues> | FormikProps<PersonGemeinschaftFormValues>;
  fieldHelp?: string | null;
};

const VatIdNumberFormPart: FC<Props> = ({ onValidationSuccess, formikProps, fieldHelp }) => {
  const currentVatIdNumber = get(formikProps.values, 'vatIdentificationNumber');

  const [vatIdNumber, setVatIdNumber] = useState(currentVatIdNumber);
  const debouncedVatIdNumber = useDebounce(vatIdNumber);

  const [lastValidatedVatIdNumber, setLastValidatedVatIdNumber] = useState<string>(currentVatIdNumber);

  const handleValidation = (vatIdNumber: string) => {
    // prevent unnecessary API calls and unwanted updates on via onValidationSuccess updatable fields after manual modification
    vatIdNumber && setLastValidatedVatIdNumber(vatIdNumber);
    if (!vatIdNumber || lastValidatedVatIdNumber === vatIdNumber) {
      return;
    }
    validateVatIdNumber(vatIdNumber)
      .then(callValidationSuccessCallback(onValidationSuccess))
      .catch((error) => onError(error, formikProps));
  };

  useEffect(() => {
    handleValidation(debouncedVatIdNumber as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedVatIdNumber]);

  return (
    <FormItemWithFieldHelp name="vatIdentificationNumber" label="UID" fieldHelp={fieldHelp}>
      <Input
        id="vatIdentificationNumber"
        name="vatIdentificationNumber"
        placeholder="UID"
        onChange={(event) => {
          setVatIdNumber(event.target.value);
        }}
      />
    </FormItemWithFieldHelp>
  );
};

const onError = (error: ErrorData, formikProps: FormikProps<any>) => {
  formikProps.setFieldError('vatIdentificationNumber', error.message);
};

const callValidationSuccessCallback = (onValidationSuccess: (data: any) => void) => (dataAndWarningList: any) => {
  if (get(dataAndWarningList, 'data')) {
    onValidationSuccess && onValidationSuccess(dataAndWarningList.data);
  }
  return Promise.resolve();
};

export default VatIdNumberFormPart;
