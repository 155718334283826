import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartVorsteuerkuerzungGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartVorsteuerkuerzungGenerierlaufMutation = {
  actionRestartVorsteuerkuerzungGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateVorsteuerkuerzungGenerierlaufMutationVariables = Types.Exact<{
  request: Types.VorsteuerkuerzungGenerierlaufInput;
}>;

export type CreateVorsteuerkuerzungGenerierlaufMutation = {
  createVorsteuerkuerzungGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartVorsteuerkuerzungGenerierlaufDocument = gql`
  mutation RestartVorsteuerkuerzungGenerierlauf($generierlaufId: ID!) {
    actionRestartVorsteuerkuerzungGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartVorsteuerkuerzungGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartVorsteuerkuerzungGenerierlaufMutation, RestartVorsteuerkuerzungGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartVorsteuerkuerzungGenerierlaufMutation, RestartVorsteuerkuerzungGenerierlaufMutationVariables>(
    RestartVorsteuerkuerzungGenerierlaufDocument,
    options
  );
}
export type RestartVorsteuerkuerzungGenerierlaufMutationHookResult = ReturnType<typeof useRestartVorsteuerkuerzungGenerierlaufMutation>;
export type RestartVorsteuerkuerzungGenerierlaufMutationResult = Apollo.MutationResult<RestartVorsteuerkuerzungGenerierlaufMutation>;
export type RestartVorsteuerkuerzungGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartVorsteuerkuerzungGenerierlaufMutation,
  RestartVorsteuerkuerzungGenerierlaufMutationVariables
>;
export const CreateVorsteuerkuerzungGenerierlaufDocument = gql`
  mutation CreateVorsteuerkuerzungGenerierlauf($request: VorsteuerkuerzungGenerierlaufInput!) {
    createVorsteuerkuerzungGenerierlauf(request: $request) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateVorsteuerkuerzungGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVorsteuerkuerzungGenerierlaufMutation, CreateVorsteuerkuerzungGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVorsteuerkuerzungGenerierlaufMutation, CreateVorsteuerkuerzungGenerierlaufMutationVariables>(
    CreateVorsteuerkuerzungGenerierlaufDocument,
    options
  );
}
export type CreateVorsteuerkuerzungGenerierlaufMutationHookResult = ReturnType<typeof useCreateVorsteuerkuerzungGenerierlaufMutation>;
export type CreateVorsteuerkuerzungGenerierlaufMutationResult = Apollo.MutationResult<CreateVorsteuerkuerzungGenerierlaufMutation>;
export type CreateVorsteuerkuerzungGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateVorsteuerkuerzungGenerierlaufMutation,
  CreateVorsteuerkuerzungGenerierlaufMutationVariables
>;
