import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useAuftragsartTexteChangeHistoryListQuery } from '../gql/AuftragsartQueries.types';

type Props = {
  auftragsartId: string;
};

const AuftragsartTexteChangeHistoryListingTable: FC<Props> = ({ auftragsartId }) => {
  const { data, loading } = useAuftragsartTexteChangeHistoryListQuery({ variables: { auftragsartId } });
  const historyList = data?.getAuftragsartTexteChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="auftragsart-texte" />;
};

export default AuftragsartTexteChangeHistoryListingTable;
