import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useBestandseinheitNutzungsArtListQuery } from './gql/BestandseinheitNutzungsArtQueries.types';

type NutzungsartSelectProps = SelectProps;

const NutzungsartSelect: FC<NutzungsartSelectProps> = ({ ...props }) => {
  const { loading, data } = useBestandseinheitNutzungsArtListQuery({
    notifyOnNetworkStatusChange: true,
  });
  const nutzungsartList = data?.getBestandseinheitNutzungsArtList.data ?? [];

  return (
    <Select size="small" id={props.name} loading={loading} placeholder="Nutzungsart auswählen" {...props}>
      {nutzungsartList.map((nutzungsart) => (
        <Select.Option key={nutzungsart.value} value={nutzungsart.value}>
          {nutzungsart.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default NutzungsartSelect;
