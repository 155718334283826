import * as Types from '../../../types';

import { gql } from '@apollo/client';
import {
  EmailDeliveryDefinitionBaseFieldsFragmentDoc,
  EmailDeliveryDefinitionFieldsFragmentDoc,
} from '../../EmailDeliveryDefinition/gql/EmailDeliveryDefinitionFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmailDeliveryDefinitionTemplateListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type EmailDeliveryDefinitionTemplateListQuery = {
  getEmailDeliveryDefinitionTemplateList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      emailDeliveryDefinitionId: string;
      type: { text: string; value: Types.EmailDeliveryDefinitionType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EmailDeliveryDefinitionTemplateQueryVariables = Types.Exact<{
  emailDeliveryDefinitionId: Types.Scalars['ID']['input'];
}>;

export type EmailDeliveryDefinitionTemplateQuery = {
  getEmailDeliveryDefinitionTemplate: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      emailDeliveryDefinitionId: string;
      body?: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      } | null;
      subject: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      };
      type: { text: string; value: Types.EmailDeliveryDefinitionType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EmailDeliveryDefinitionTemplateChangeHistoryListQueryVariables = Types.Exact<{
  emailDeliveryDefinitionId: Types.Scalars['ID']['input'];
}>;

export type EmailDeliveryDefinitionTemplateChangeHistoryListQuery = {
  getEmailDeliveryDefinitionTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const EmailDeliveryDefinitionTemplateListDocument = gql`
  query EmailDeliveryDefinitionTemplateList {
    getEmailDeliveryDefinitionTemplateList {
      data {
        ...EmailDeliveryDefinitionBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${EmailDeliveryDefinitionBaseFieldsFragmentDoc}
`;
export function useEmailDeliveryDefinitionTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<EmailDeliveryDefinitionTemplateListQuery, EmailDeliveryDefinitionTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailDeliveryDefinitionTemplateListQuery, EmailDeliveryDefinitionTemplateListQueryVariables>(
    EmailDeliveryDefinitionTemplateListDocument,
    options
  );
}
export function useEmailDeliveryDefinitionTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailDeliveryDefinitionTemplateListQuery, EmailDeliveryDefinitionTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailDeliveryDefinitionTemplateListQuery, EmailDeliveryDefinitionTemplateListQueryVariables>(
    EmailDeliveryDefinitionTemplateListDocument,
    options
  );
}
export function useEmailDeliveryDefinitionTemplateListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<EmailDeliveryDefinitionTemplateListQuery, EmailDeliveryDefinitionTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmailDeliveryDefinitionTemplateListQuery, EmailDeliveryDefinitionTemplateListQueryVariables>(
    EmailDeliveryDefinitionTemplateListDocument,
    options
  );
}
export type EmailDeliveryDefinitionTemplateListQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionTemplateListQuery>;
export type EmailDeliveryDefinitionTemplateListLazyQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionTemplateListLazyQuery>;
export type EmailDeliveryDefinitionTemplateListSuspenseQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionTemplateListSuspenseQuery>;
export type EmailDeliveryDefinitionTemplateListQueryResult = Apollo.QueryResult<
  EmailDeliveryDefinitionTemplateListQuery,
  EmailDeliveryDefinitionTemplateListQueryVariables
>;
export const EmailDeliveryDefinitionTemplateDocument = gql`
  query EmailDeliveryDefinitionTemplate($emailDeliveryDefinitionId: ID!) {
    getEmailDeliveryDefinitionTemplate(emailDeliveryDefinitionId: $emailDeliveryDefinitionId) {
      data {
        ...EmailDeliveryDefinitionFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${EmailDeliveryDefinitionFieldsFragmentDoc}
`;
export function useEmailDeliveryDefinitionTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<EmailDeliveryDefinitionTemplateQuery, EmailDeliveryDefinitionTemplateQueryVariables> &
    ({ variables: EmailDeliveryDefinitionTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailDeliveryDefinitionTemplateQuery, EmailDeliveryDefinitionTemplateQueryVariables>(
    EmailDeliveryDefinitionTemplateDocument,
    options
  );
}
export function useEmailDeliveryDefinitionTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailDeliveryDefinitionTemplateQuery, EmailDeliveryDefinitionTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailDeliveryDefinitionTemplateQuery, EmailDeliveryDefinitionTemplateQueryVariables>(
    EmailDeliveryDefinitionTemplateDocument,
    options
  );
}
export function useEmailDeliveryDefinitionTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<EmailDeliveryDefinitionTemplateQuery, EmailDeliveryDefinitionTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmailDeliveryDefinitionTemplateQuery, EmailDeliveryDefinitionTemplateQueryVariables>(
    EmailDeliveryDefinitionTemplateDocument,
    options
  );
}
export type EmailDeliveryDefinitionTemplateQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionTemplateQuery>;
export type EmailDeliveryDefinitionTemplateLazyQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionTemplateLazyQuery>;
export type EmailDeliveryDefinitionTemplateSuspenseQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionTemplateSuspenseQuery>;
export type EmailDeliveryDefinitionTemplateQueryResult = Apollo.QueryResult<
  EmailDeliveryDefinitionTemplateQuery,
  EmailDeliveryDefinitionTemplateQueryVariables
>;
export const EmailDeliveryDefinitionTemplateChangeHistoryListDocument = gql`
  query EmailDeliveryDefinitionTemplateChangeHistoryList($emailDeliveryDefinitionId: ID!) {
    getEmailDeliveryDefinitionTemplateChangeHistoryList(emailDeliveryDefinitionId: $emailDeliveryDefinitionId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useEmailDeliveryDefinitionTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmailDeliveryDefinitionTemplateChangeHistoryListQuery,
    EmailDeliveryDefinitionTemplateChangeHistoryListQueryVariables
  > &
    ({ variables: EmailDeliveryDefinitionTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailDeliveryDefinitionTemplateChangeHistoryListQuery, EmailDeliveryDefinitionTemplateChangeHistoryListQueryVariables>(
    EmailDeliveryDefinitionTemplateChangeHistoryListDocument,
    options
  );
}
export function useEmailDeliveryDefinitionTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailDeliveryDefinitionTemplateChangeHistoryListQuery,
    EmailDeliveryDefinitionTemplateChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailDeliveryDefinitionTemplateChangeHistoryListQuery, EmailDeliveryDefinitionTemplateChangeHistoryListQueryVariables>(
    EmailDeliveryDefinitionTemplateChangeHistoryListDocument,
    options
  );
}
export function useEmailDeliveryDefinitionTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EmailDeliveryDefinitionTemplateChangeHistoryListQuery,
        EmailDeliveryDefinitionTemplateChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EmailDeliveryDefinitionTemplateChangeHistoryListQuery,
    EmailDeliveryDefinitionTemplateChangeHistoryListQueryVariables
  >(EmailDeliveryDefinitionTemplateChangeHistoryListDocument, options);
}
export type EmailDeliveryDefinitionTemplateChangeHistoryListQueryHookResult = ReturnType<
  typeof useEmailDeliveryDefinitionTemplateChangeHistoryListQuery
>;
export type EmailDeliveryDefinitionTemplateChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useEmailDeliveryDefinitionTemplateChangeHistoryListLazyQuery
>;
export type EmailDeliveryDefinitionTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useEmailDeliveryDefinitionTemplateChangeHistoryListSuspenseQuery
>;
export type EmailDeliveryDefinitionTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  EmailDeliveryDefinitionTemplateChangeHistoryListQuery,
  EmailDeliveryDefinitionTemplateChangeHistoryListQueryVariables
>;
