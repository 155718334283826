import { FormFields, TFormattedDecimal } from '../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';
import { ObjektInfoFeldGrundstuecksdaten } from '../../../../../../types';

export interface GrundstueckdsdatenUpdateFormValues extends InfoFeldFormValues {
  anzahlGeschosseUeberNiveau?: number;
  anzahlGeschosseUnterNiveau?: number;
  bauflaeche?: TFormattedDecimal;
  baujahr?: number;
  bebauteFlaeche?: TFormattedDecimal;
  benuetzungsbewilligung?: string;
  einlagezahl?: string;
  grundstuecksnummer: string;
  katastralgemeinde?: string;
  deleteDocumentFileIdList: string[];
}

export const grundstuecksdatenUpdateFormFields: FormFields<GrundstueckdsdatenUpdateFormValues> = {
  anzahlGeschosseUeberNiveau: 'anzahlGeschosseUeberNiveau',
  anzahlGeschosseUnterNiveau: 'anzahlGeschosseUnterNiveau',
  bauflaeche: 'bauflaeche',
  baujahr: 'baujahr',
  bebauteFlaeche: 'bebauteFlaeche',
  benuetzungsbewilligung: 'benuetzungsbewilligung',
  einlagezahl: 'einlagezahl',
  grundstuecksnummer: 'grundstuecksnummer',
  katastralgemeinde: 'katastralgemeinde',
  documentList: 'documentList',
  deleteDocumentFileIdList: 'deleteDocumentFileIdList',
};

export const mapToUpdateFormValues = (infofeld: ObjektInfoFeldGrundstuecksdaten): GrundstueckdsdatenUpdateFormValues => ({
  anzahlGeschosseUeberNiveau: infofeld.anzahlGeschosseUeberNiveau ?? undefined,
  anzahlGeschosseUnterNiveau: infofeld.anzahlGeschosseUnterNiveau ?? undefined,
  bauflaeche: infofeld.bauflaeche ?? undefined,
  baujahr: infofeld.baujahr ?? undefined,
  bebauteFlaeche: infofeld.bebauteFlaeche ?? undefined,
  benuetzungsbewilligung: infofeld.benuetzungsbewilligung ?? undefined,
  einlagezahl: infofeld.einlagezahl ?? undefined,
  grundstuecksnummer: infofeld.grundstuecksnummer,
  katastralgemeinde: infofeld.katastralgemeinde ?? undefined,
  documentList: [],
  deleteDocumentFileIdList: [],
});
