import React from 'react';
import { FormikErrors } from 'formik';

interface IFormikErrors {
  [field: string]: string | string[];
}

type ErrorsProps<FormErrors extends IFormikErrors> = {
  errors: string | string[] | FormikErrors<FormErrors>[];
  values: string[];
};

/**
 * <h2>Usage</h2>
 * For showing form validation error when the build-in field based validation from Formik cannot be applied.
 * This component should be rendered mostly under the form in case where one of the following applies:
 *   <ul>
 *     <li>there are multiple rows and all fields specified in values prop should be filled.
 *     In this case the component will list the errors from the array.</li>
 *     <li>at least one field in the form should be filled, but it is not specified which one</li>
 *   </ul>
 *
 */
export default function Errors<FormErrors extends IFormikErrors>({ errors, values }: ErrorsProps<FormErrors>) {
  return Array.isArray(errors) ? (
    <div className="ant-form-item-has-error ant-form-item-explain" role="alert">
      {errors.map((data: any, index: number) => {
        if (typeof data === 'string') {
          return (
            <div style={{ color: 'red' }} key={index}>
              Zuweisung {index + 1}: {data}
            </div>
          );
        }
        return data ? (
          <div style={{ color: 'red' }} key={index}>
            Zuweisung {index + 1}:{' '}
            {values
              .filter((value) => Object.keys(data).includes(value))
              .map((value) => {
                return data[value];
              })
              .join(', ')}
          </div>
        ) : null;
      })}
    </div>
  ) : (
    <div className="ant-form-item-has-error ant-form-item-explain" role="alert">
      <pre style={{ color: 'red' }}>{errors}</pre>
    </div>
  );
}
