import React, { FC } from 'react';
import { Modal } from 'antd';
import SepaMandatCreateForm from './SepaMandatCreateForm';

type Props = {
  isCreateModalOpen: boolean;
  toggleCreateModalOpen: () => void;
  bankDetailsId: string;
  rechtstraegerIdForSepaMandat: string;
  onAction: () => void;
};

const SepaMandatCreateModal: FC<Props> = ({ isCreateModalOpen, toggleCreateModalOpen, bankDetailsId, rechtstraegerIdForSepaMandat, onAction }) => {
  return (
    <Modal title="SEPA Mandat anlegen" open={isCreateModalOpen} onCancel={toggleCreateModalOpen} footer={null} destroyOnClose maskClosable={false}>
      <SepaMandatCreateForm
        bankDetailsId={bankDetailsId}
        rechtstraegerId={rechtstraegerIdForSepaMandat}
        onSuccess={() => {
          toggleCreateModalOpen();
          onAction();
        }}
        onCancel={toggleCreateModalOpen}
      />
    </Modal>
  );
};

export default SepaMandatCreateModal;
