import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Space, Tooltip, Typography } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';
import { EuroAmount, Percent } from '../../../components/Number';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { BuchungszeileBestandseinheit, BuchungszeileOrderBy, FibuBuchungszeile, FibuObjekt, KontoVerwendung } from '../../../types';
import KontoblattTableColumnGegenKonto from './KontoblattTableColumnGegenKonto';
import { pathsToObjektDetailsPage } from '../../Objekt/objektUriPaths';
import { generatePathToBestandseinheitDetailsPage } from '../../Bestandseinheit/bestandseinheitUriPaths';
import { isNotNil } from '../../../helpers/assertionHelper';
import SollHabenTag from '../../shared/SollHabenTag';
import KontoblattTableColumnBetrag from './KontoblattTableColumnBetrag';
import RechtstraegerName from '../../../components/Rechtstraeger/RechtstraegerName';
import { useBestandseinheitQuery } from '../../Bestandseinheit/gql/BestandseinheitQueries.types';
import KontoblattTableColumnSaldo from './KontoblattTableColumnSaldo';
import TextForEmptyValue, { EMPTY_VALUE_WITH_N_V } from '../../../components/Text/TextForEmptyValue';
import AvatarBasedOnBelegSymbolWithTooltip from '../../../components/Avatar/BasedOnBelegSymbol/AvatarBasedOnBelegSymbolWithTooltip';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../components/Card';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import BuchungszeileBelegnummer from '../../BookingDetails/shared/BuchungszeileBelegnummer';

const kontoblattTableColumns = (buchungszeileList?: FibuBuchungszeile[]): TableWithColSelectorColumnProps<FibuBuchungszeile>[] => [
  {
    title: 'Belegdatum',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.BelegDatum,
    sorter: true,
    render: (text, record) =>
      record.buchungsKopf.belegDatum ? <CustomFormattedDate value={record.buchungsKopf.belegDatum} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'BS',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.BelegSymbol,
    sorter: true,
    render: (text, record) => <AvatarBasedOnBelegSymbolWithTooltip belegSymbol={record.buchungsKopf.belegSymbol} size="small" />,
  },
  {
    title: 'Buchungstyp',
    render: (text, record) => <Typography.Text>{record.buchungsKopf.buchungsType.text}</Typography.Text>,
  },
  {
    title: 'Storniert',
    render: (text, record) => <Typography.Text>{record.storniert ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  {
    title: 'Buchungsdatum',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.BuchungsDatum,
    sorter: true,
    render: (text, record) => <CustomFormattedDate value={record.buchungsKopf.buchungsdatum} />,
  },
  {
    title: 'Belegnr',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.Belegnummer,
    sorter: true,
    render: (text, record) => <BuchungszeileBelegnummer buchungszeile={record} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.Objekt,
    sorter: true,
    render: (text, record) => {
      return record.objekt ? (
        <Link to={pathsToObjektDetailsPage(record.objekt.objektId).objektDetails}>{record.objekt.kurzBezeichnung}</Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Bestandseinheit',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.Bestandseinheit,
    sorter: true,
    render: (text, record) => {
      if (record.objekt && record.bestandseinheit) {
        return record.bestandseinheit.bezeichnung ? (
          <Link to={generatePathToBestandseinheitDetailsPage(record.objekt.objektId, record.bestandseinheit.bestandseinheitId)}>
            {record.bestandseinheit.bezeichnung}
          </Link>
        ) : (
          <LinkToBestandseinheitWithGetQuery objekt={record.objekt} bestandseinheit={record.bestandseinheit} />
        );
      }

      return <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Gegenkonto',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.GegenKontoNummer,
    sorter: true,
    render: (text, record) => <KontoblattTableColumnGegenKonto buchungId={record.buchungId} gegenKonto={record.gegenKonto} />,
  },
  {
    title: 'WAE',
    render: () => <Typography.Text>Euro</Typography.Text>,
  },
  {
    title: () => {
      const isDebitorOrKreditor = isKontoblattForDebitorOrKreditor(buchungszeileList);

      return isNotNil(isDebitorOrKreditor) ? (
        <Space>
          <Typography.Text>Betrag</Typography.Text>
          <Typography.Text style={{ color: 'red' }}>{isDebitorOrKreditor ? 'Brutto' : 'Netto'}</Typography.Text>
        </Space>
      ) : (
        'Betrag'
      );
    },
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.Betrag,
    sorter: true,
    align: 'right',
    render: (text, record) => <KontoblattTableColumnBetrag buchungszeile={record} />,
  },
  {
    title: 'S/H',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.SollHaben,
    sorter: true,
    render: (text, record) => <SollHabenTag sollHaben={record.sollHaben} />,
  },
  {
    title: 'Ust.-Proz',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.Steuersatz,
    sorter: true,
    align: 'right',
    render: (text, record) => (record.steuersatz ? <Percent value={record.steuersatz / 100} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Ust.-Betrag',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.Steuer,
    sorter: true,
    align: 'right',
    render: (text, record) => (record.steuer ? <EuroAmount value={record.steuer} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Saldo Betrag',
    defaultSelected: true,
    align: 'right',
    render: (text, record, index) => <KontoblattTableColumnSaldo index={index} buchungszeileList={buchungszeileList} />,
  },
  {
    title: 'Buchungstext',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.Text,
    sorter: true,
    render: (text, record) => <DataWithShortenedText text={record.text} maxTextLength={18} />,
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.Vertragspartner,
    sorter: true,
    render: (text, record) =>
      record.buchungsKopf.vertragspartnerId ? (
        <RechtstraegerName rechtstraegerId={record.buchungsKopf.vertragspartnerId} isLink maxLength={30} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: () => (
      <Space>
        <Typography.Text>Offener OP-Betrag</Typography.Text>
        <Tooltip title="OP-Beträge werden nur bei Debitoren und Kreditoren angezeigt">
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
    ),
    defaultSelected: true,
    dataIndex: BuchungszeileOrderBy.OffenerOpBetrag,
    sorter: true,
    render: (text, record) => {
      return record.offenerOpBetrag ? <EuroAmount value={record.offenerOpBetrag} /> : <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Saldo OP-Betrag',
    defaultSelected: true,
    align: 'right',
    render: (text, record, index) => {
      return <EuroAmount value={calculateSaldoOPBetrag(record, index, buchungszeileList)} />;
    },
  },
  {
    title: 'Erstellt am',
    dataIndex: BuchungszeileOrderBy.CreateTs,
    sorter: true,
    width: 100,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    width: 70,
    dataIndex: BuchungszeileOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
];

const calculateSaldoOPBetrag = (record: FibuBuchungszeile, index: number, buchungszeileList?: FibuBuchungszeile[]) => {
  if (!buchungszeileList) {
    return 0;
  }
  return buchungszeileList.slice(0, index + 1).reduce((acc, curVal) => {
    return acc + (curVal.offenerOpBetrag ?? 0);
  }, 0);
};

const isKontoblattForDebitorOrKreditor = (kontoblatt?: FibuBuchungszeile[]) => {
  return kontoblatt && kontoblatt.length > 0
    ? kontoblatt[0].konto.verwendung?.value === KontoVerwendung.Debitor || kontoblatt[0].konto.verwendung?.value === KontoVerwendung.Kreditor
    : undefined;
};

const LinkToBestandseinheitWithGetQuery: FC<{
  objekt: FibuObjekt;
  bestandseinheit: BuchungszeileBestandseinheit;
}> = ({ objekt, bestandseinheit }) => {
  const { data } = useBestandseinheitQuery({
    variables: {
      objektId: objekt.objektId,
      bestandseinheitId: bestandseinheit.bestandseinheitId,
    },
  });
  const bezeichnung = data?.getBestandseinheit.data.bezeichnung ?? EMPTY_VALUE_WITH_N_V;

  return <Link to={generatePathToBestandseinheitDetailsPage(objekt.objektId, bestandseinheit.bestandseinheitId)}>{bezeichnung}</Link>;
};

export default kontoblattTableColumns;
