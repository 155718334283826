import React, { FC, useContext } from 'react';
import { Formik } from 'formik';
import { Divider } from 'antd';
import { Form } from 'formik-antd';
import { IndexationAgreementContext } from '../../IndexationAgreementContext';
import { showSuccessMsgCreate } from '../../../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../../../helpers/apolloHelper';
import {
  useCreateVertragVposIndexationAgreementIndexThresholdMutation,
  useUpdateVertragVposIndexationAgreementIndexThresholdMutation,
} from '../../../../gql/VPosIndexationAgreementMutations.types';
import {
  indAgreeIndexThresholdFormFields,
  IndexationAgreementIndexThresholdFormValues,
  mapFormValuesToIndexThreshold,
  mapIndexThresholdToFormValues,
} from './indexThresholdFormMapper';
import { indexThresholdFormValidationSchema } from './indexThresholdFormValidationSchema';
import FormButtons from '../../../../../../../../../components/Button/FormButtons';
import IndexBlock from './IndexBlock';
import { ValuePreservationType, VertragVposIndexationAgreementIndexThreshold } from '../../../../../../../../../types';
import SubsequentBillingBlock from '../shared/SubsequentBillingBlock';
import ThresholdBlock from '../shared/ThresholdBlock';
import { useSetIndexOld } from '../shared/useSetIndexOld';
import LastValueBaseBlock from '../shared/LastValueBaseBlock';
import { useGetAppFieldHelpText } from '../../../../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  indexationAgreement?: VertragVposIndexationAgreementIndexThreshold;
  onCancel: () => void;
  onSuccess: () => void;
};

const IndexThresholdForm: FC<Props> = ({ indexationAgreement, onCancel, onSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'VertragVposIndexationAgreementIndexThreshold'>('VertragVposIndexationAgreementIndexThreshold');

  const isUpdate = !!indexationAgreement;
  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);
  const entity = 'Index Schwellenwert';

  const [runCreate, { loading: loadingCreate }] = useCreateVertragVposIndexationAgreementIndexThresholdMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateVertragVposIndexationAgreementIndexThresholdMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const { handleIndexSeriesChange, handleMonthYearChange } = useSetIndexOld();

  return (
    <Formik<IndexationAgreementIndexThresholdFormValues>
      initialValues={mapIndexThresholdToFormValues(indexationAgreement)}
      validationSchema={indexThresholdFormValidationSchema}
      onSubmit={(values, formikHelpers) => {
        isUpdate
          ? runUpdate({
              variables: {
                objektId,
                bestandseinheitId,
                vertragId,
                vorschreibungspositionId,
                indexationAgreementId: indexationAgreement.vertragVposIndexationAgreementId,
                input: mapFormValuesToIndexThreshold(values),
              },
            }).finally(() => formikHelpers.setSubmitting(false))
          : runCreate({
              variables: {
                objektId,
                bestandseinheitId,
                vertragId,
                vorschreibungspositionId,
                input: mapFormValuesToIndexThreshold(values),
              },
            }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <IndexBlock
            formikProps={formikProps}
            onIndexSeriesChange={(indexSeriesId) => handleIndexSeriesChange(indAgreeIndexThresholdFormFields.indexOld, indexSeriesId, formikProps)}
            fieldHelpNames={{
              validFrom: getFieldHelpText('VertragVposIndexationAgreementIndexThreshold.validFrom.year'),
              indexSeriesId: getFieldHelpText('VertragVposIndexationAgreementIndexThreshold.indexSeriesId'),
              effectiveAfterMonths: getFieldHelpText('VertragVposIndexationAgreementIndexThreshold.effectiveAfterMonths'),
            }}
          />
          <Divider />
          <LastValueBaseBlock
            monthYearName={indAgreeIndexThresholdFormFields.monthYearOld}
            onMonthYearChange={(month, year) => handleMonthYearChange(indAgreeIndexThresholdFormFields.indexOld, month, year, formikProps)}
            indexOldName={indAgreeIndexThresholdFormFields.indexOld}
            valuePreservationType={ValuePreservationType.IndexThreshold}
            isIndexOldPreliminary={!!indexationAgreement?.indexOldPreliminary}
            fieldHelpNames={{
              monthYearOld: getFieldHelpText('VertragVposIndexationAgreementIndexThreshold.monthYearOld.year'),
              indexOld: getFieldHelpText('VertragVposIndexationAgreementIndexThreshold.indexOld'),
            }}
          />
          <Divider />
          <ThresholdBlock
            formikProps={formikProps}
            thresholdName={indAgreeIndexThresholdFormFields.threshold}
            thresholdTypeName={indAgreeIndexThresholdFormFields.thresholdType}
            shareInPercentToApplyName={indAgreeIndexThresholdFormFields.shareInPercentToApply}
            minimumLimitName={indAgreeIndexThresholdFormFields.minimumLimit}
            fieldHelpNames={{
              threshold: getFieldHelpText('VertragVposIndexationAgreementIndexThreshold.threshold'),
              thresholdType: getFieldHelpText('VertragVposIndexationAgreementIndexThreshold.thresholdType'),
              shareInPercentToApply: getFieldHelpText('VertragVposIndexationAgreementIndexThreshold.shareInPercentToApply'),
              minimumLimit: getFieldHelpText('VertragVposIndexationAgreementIndexThreshold.minimumLimit'),
            }}
          />
          <Divider />
          <SubsequentBillingBlock
            formikProps={formikProps}
            subsequentBillingName={indAgreeIndexThresholdFormFields.subsequentBilling}
            subsequentBillingObjektVPosName={indAgreeIndexThresholdFormFields.subsequentBillingObjektVorschreibungspositionId}
            fieldHelpNames={{
              subsequentBilling: getFieldHelpText('VertragVposIndexationAgreementIndexThreshold.subsequentBilling'),
              subsequentBillingObjektVorschreibungspositionId: getFieldHelpText(
                'VertragVposIndexationAgreementIndexThreshold.subsequentBillingObjektVorschreibungspositionId'
              ),
            }}
          />
          <FormButtons
            updateMode={isUpdate}
            isOkLoading={loadingCreate || loadingUpdate}
            isOkDisabled={formikProps.isSubmitting}
            onCancel={onCancel}
          />
        </Form>
      )}
    </Formik>
  );
};

export default IndexThresholdForm;
