import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import {
  MutationCreateReceivedBankTransactionVerbuchenGenerierlaufArgs,
  ReceivedBankTransactionVerbuchenGenerierlaufCreateInput,
} from '../../../../../types';
import { TReceivedBankTransactionQueryParams } from '../../Filters/filtersQueryParams';

export interface FilterFormValues {
  buchungsdatum: string;
}

export const formInitialValues: FilterFormValues = {
  buchungsdatum: dayjsCustom().format(),
};

export const formFormFields: FormFields<FilterFormValues> = {
  buchungsdatum: 'buchungsdatum',
};

export const mapFormValuesToCreateInput = (values: FilterFormValues): ReceivedBankTransactionVerbuchenGenerierlaufCreateInput => ({
  buchungsdatum: mapFormDateValueToDateString(values.buchungsdatum),
});

export const mapQueryParamsAndFormValuesToArgs = (
  queryParams: TReceivedBankTransactionQueryParams,
  input: ReceivedBankTransactionVerbuchenGenerierlaufCreateInput
): MutationCreateReceivedBankTransactionVerbuchenGenerierlaufArgs => {
  const { booked, valid, paymentAssigned, ...restQueryParams } = queryParams;
  return {
    ...restQueryParams,
    statementNumber: queryParams.statementNumber?.toString(),
    input,
  };
};
