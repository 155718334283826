import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useEbicsBankConfigListQuery } from '../../../features/PaymentTransaction/Ebics/gql/EbicsBankConfigQueries.types';
import EbicsBankConfigListing from '../../../features/PaymentTransaction/Ebics/Listing/EbicsBankConfigListing';

const EbicsBankConfigPage: FC = () => {
  const { data, refetch } = useEbicsBankConfigListQuery();

  const ebicsBankConfigList = data?.getEbicsBankConfigList.data;

  if (!ebicsBankConfigList) return <Skeleton active />;

  return <EbicsBankConfigListing ebicsBankConfigList={ebicsBankConfigList} onAction={refetch} />;
};

export default EbicsBankConfigPage;
