import React from 'react';
import { CopyOutlined, SlidersOutlined, SolutionOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { Budgeting, FileInfoType } from '../../types';
import { pathsToBudgetingDetailsPage } from '../../features/Budgeting/budgetingUriPaths';
import BudgetingDetailsTab from '../../features/Budgeting/BudgetingDetails/BudgetingDetailsTab';
import BudgetingResultTab from '../../features/Budgeting/BudgetingResult/BudgetingResultTab';
import DocumentTab from '../../features/Document/DocumentTab';
import { DOCUMENT_FILTERS_SETTINGS } from '../../features/Document/Listing/Filters/listingFiltersHelper';

const menuListBudgeting = (
  objektId: string,
  budgeting: Budgeting,
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  refetch: () => Promise<unknown>,
  isUpdating: boolean,
  updatingRowKey: string | null,
  setUpdatingRowKey: React.Dispatch<React.SetStateAction<string | null>>,
  showArchived: boolean,
  toggleShowArchived: () => void
): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: 'Budgetierung',
        icon: <SlidersOutlined />,
        uri: pathsToBudgetingDetailsPage(objektId, budgeting.budgetingId).detailsTab,
        content: () => (
          <BudgetingDetailsTab
            budgeting={budgeting}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            refetch={refetch}
            isUpdating={isUpdating}
            updatingRowKey={updatingRowKey}
            setUpdatingRowKey={setUpdatingRowKey}
            showArchived={showArchived}
            toggleShowArchived={toggleShowArchived}
          />
        ),
      },
      {
        title: 'Erstellte Vertagswerte',
        icon: <SolutionOutlined />,
        uri: pathsToBudgetingDetailsPage(objektId, budgeting.budgetingId).resultsTab,
        content: () => <BudgetingResultTab budgeting={budgeting} />,
      },
      {
        title: 'Dokumente',
        icon: <CopyOutlined />,
        uri: pathsToBudgetingDetailsPage(objektId, budgeting.budgetingId).documentsTab,
        content: () => (
          <DocumentTab
            budgetingId={budgeting.budgetingId}
            typeList={[FileInfoType.ObjektFinancialPlan, FileInfoType.TopFinancialPlan]}
            disabledFilters={DOCUMENT_FILTERS_SETTINGS.budgeting.disabled}
            defaultFilters={DOCUMENT_FILTERS_SETTINGS.budgeting.default}
            objektId={objektId}
          />
        ),
      },
    ],
  };
};

export default menuListBudgeting;
