import React, { FC } from 'react';
import { DeviationDataFragment } from '../gql/deviationFragments.types';
import { AbrechnungStatusTuple, TopAbrechnungStatusTuple } from '../../../types';
import { EntityWithDeviationStatus } from '../deviation.model';
import LoadingDeviationTab from './LoadingDeviationTab';
import DeviationTabContent from './DeviationTabContent';
import TabPaneContent from '../../Tabs/TabPaneContent';

type DeviationTabProps = {
  dataSource: DeviationDataFragment[] | undefined;
  loading: boolean;
  status: EntityWithDeviationStatus | AbrechnungStatusTuple | TopAbrechnungStatusTuple;
  runApplyAllDeviations: () => Promise<unknown> | void;
  runPruefungAussetzen?: () => Promise<unknown> | void;
  showSourceEntity?: boolean;
  showSubsequentDeviationsButton?: boolean;
  showSubsequentDeviations?: boolean;
  onShowFolgeDeviationsButtonClick?: () => void;
  altTextForMultipleReferenceOfCause?: string;
  reviewErrorUriPath?: string;
};

const DeviationTab: FC<DeviationTabProps> = ({
  dataSource,
  loading,
  status,
  runApplyAllDeviations,
  runPruefungAussetzen,
  showSourceEntity,
  showSubsequentDeviationsButton,
  showSubsequentDeviations,
  onShowFolgeDeviationsButtonClick,
  altTextForMultipleReferenceOfCause,
  reviewErrorUriPath,
}) => {
  if (!dataSource) {
    return <LoadingDeviationTab />;
  }

  return (
    <TabPaneContent distanceToTopBorder="large">
      <DeviationTabContent
        dataSource={dataSource}
        status={status}
        loading={loading}
        runApplyAllDeviations={runApplyAllDeviations}
        runPruefungAussetzen={runPruefungAussetzen}
        showSourceEntity={showSourceEntity}
        showSubsequentDeviationsButton={showSubsequentDeviationsButton}
        showSubsequentDeviations={showSubsequentDeviations}
        onShowFolgeDeviationsButtonClick={onShowFolgeDeviationsButtonClick}
        altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
        reviewErrorUriPath={reviewErrorUriPath}
      />
    </TabPaneContent>
  );
};

export default DeviationTab;
