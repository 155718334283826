import React from 'react';
import { Typography } from 'antd';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import MitarbeiterTooltip from '../../../../components/Card/MitarbeiterTooltip';
import { compareTwoDatesForSorting } from '../../../../helpers/dateHelper';
import IndexSeriesTableActions from './IndexSeriesTableActions';
import { IndexSeries } from '../../../../types';
import IndexSeriesVerwaltungColumn from './IndexSeriesVerwaltungColumn';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';

const indexSeriesTableColumns = (onAction: () => void): TableWithColSelectorColumnProps<IndexSeries>[] => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.name, b.name),
    render: (text, record) => <DataWithShortenedTextAndExtraInfo maxTextLength={20} text={record.name} />,
  },
  {
    title: 'Indextyp',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.indexType.text, b.indexType.text),
    render: (text, record) => <Typography.Text>{record.indexType.text}</Typography.Text>,
  },
  {
    title: 'Datenquelle',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.sourceType.text, b.sourceType.text),
    render: (text, record) => <Typography.Text>{record.sourceType.text}</Typography.Text>,
  },
  {
    title: 'Verwaltung',
    defaultSelected: true,
    sorter: true,
    render: (text, record) => <IndexSeriesVerwaltungColumn firmendatenId={record.firmendatenId} status={record.status} />,
  },
  {
    title: 'Erstellt am',
    width: 100,
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip alignment="left" mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <IndexSeriesTableActions indexSeries={record} onAction={onAction} />,
  },
];

export default indexSeriesTableColumns;
