import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useEmailDeliveryTypeListQuery } from '../gql/EmailDeliveryDefinitionQueries.types';

type EmailDeliveryTypeSelectProps = SelectProps;

const EmailDeliveryTypeSelect: FC<EmailDeliveryTypeSelectProps> = ({ name, ...restProps }) => {
  const { data, loading } = useEmailDeliveryTypeListQuery();

  const emailDeliveryTypeList = data?.getEmailDeliveryTypeList.data ?? [];

  return (
    <Select loading={loading} id={name} name={name} placeholder="Typ auswählen" size="small" allowClear={false} {...restProps}>
      {emailDeliveryTypeList.map((emailDeliveryType) => (
        <Select.Option key={emailDeliveryType.value} value={emailDeliveryType.value}>
          {emailDeliveryType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default EmailDeliveryTypeSelect;
