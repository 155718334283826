import React from 'react';
import { Space, Tag } from 'antd';
import { Link } from 'react-router-dom';
import KontierungstabelleTableActions from './KontierungstabelleTableActions';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import { Kontierungstabelle } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import FirmendatenKurzBezeichnung from '../../../shared/FirmendatenKurzBezeichnung';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import { archivedOrNormalStyle, StatusEnum } from '../../../../helpers/statusHelper';
import StatusBadge from '../../../../components/Status/StatusBadge';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { generatePathToKontenplanPage } from '../../../../pages/SysSettingsPage/KundenSystem/KontenplanPage/routes';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { NestedTableWithColSelectorColProps } from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

export const kontierungstabelleTableColumns = (refetch: () => void): NestedTableWithColSelectorColProps<Kontierungstabelle>[] => [
  {
    title: 'Bezeichnung',
    key: 'bezeichnung',
    width: 200,
    defaultSelected: true,
    defaultSortOrder: 'ascend',
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    render: (value, record) => (
      <DataWithStatus status={record.status} text={record.bezeichnung} showStatusDescription={false} showBadgeDot={false} maxTextLength={30} />
    ),
  },
  {
    title: 'Basiskonto',
    key: 'basiskonto',
    defaultSelected: true,
    sorter: (a, b) => a.basiskonto - b.basiskonto,
    render: (value, record) => (
      <Tag key={record.basiskonto} style={archivedOrNormalStyle(record.status)}>
        {record.basiskonto}
      </Tag>
    ),
  },
  {
    title: '',
  },
  {
    title: 'Firma',
    key: 'firma',
    defaultSelected: true,
    render: (text, record) =>
      record.firmendatenId ? (
        <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} />
      ) : (
        <Tag style={archivedOrNormalStyle(record.status)}>System</Tag>
      ),
  },
  {
    title: 'Aufwandskonto (Subverwaltung)',
    key: 'aufwandskonto',
    width: 215,
    defaultSelected: true,
    render: (_, record) => {
      const subAdministrationExpenseAccount = record.subAdministrationExpenseAccount;

      return subAdministrationExpenseAccount ? (
        <DataWithShortenedText maxTextLength={20} text={subAdministrationExpenseAccount.bezeichnung}>
          {(shortenedText) => (
            <Space size="small">
              <Link to={generatePathToKontenplanPage(subAdministrationExpenseAccount.kontoKlasse)}>{subAdministrationExpenseAccount.nummer}</Link>
              <Tag>{shortenedText}</Tag>
            </Space>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Status',
    defaultSelected: true,
    filters: [
      { text: 'Aktiv', value: StatusEnum.Aktiv },
      { text: 'Archiviert', value: StatusEnum.Archiviert },
    ],
    onFilter: (value, record) => record.status.value.includes(value as string),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    key: 'erstelltAm',
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    key: 'ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    key: 'action',
    defaultSelected: true,
    render: (text, record) => <KontierungstabelleTableActions kontierungstabelle={record} refetch={refetch} />,
  },
];
