import React, { FC } from 'react';
import { Button } from 'antd';
import { Box, Flex } from 'rebass';
import Contact from './Contact';
import { useToggle } from '../../../hooks/useToggle';
import { ContactModel } from './contactMapper';

type Props = {
  contactList: ContactModel[];
  isMore?: boolean;
};

const ContactList: FC<Props> = ({ contactList = [], isMore = false }) => {
  const [isCollapsed, onCollapse] = useToggle();

  if (contactList.length > 0) {
    return (
      <Flex>
        <Box>
          {!isCollapsed && <Contact contact={contactList[0]} key={contactList[0].addressId} />}
          {isCollapsed && contactList.map((item, index: number) => <Contact contact={item} key={item.addressId || index} />)}
        </Box>
        {isMore && (
          <Box alignSelf="top" ml="auto" py={2}>
            <Button size="small" type="link" onClick={onCollapse}>
              …
            </Button>
          </Box>
        )}
      </Flex>
    );
  }
  return null;
};

export default ContactList;
