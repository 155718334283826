import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input, Switch } from 'formik-antd';
import { Col, Row, Space, Spin, Typography } from 'antd';
import FormButtons from '../../../../../../components/Button/FormButtons';
import {
  belegnummernkreisFormFields,
  belegnummernkreisFormInitialValues,
  BelegnummernkreisFormValues,
  mapBelegnummernkreisToFormValues,
  mapFormValuesToBelegnummernkreisDefinition,
} from './belegnummnernkreisFormMapper';
import BelegnummernkreisTextbausteinFormItem from './BelegnummernkreisTextbausteinFormItem';
import { belegnummernkreisFormValidationSchema } from './belegnummernkreisFormValidationSchema';
import {
  useCreateBelegnummernkreisDefinitionMutation,
  useUpdateBelegnummernkreisDefinitionMutation,
} from '../../../../../BelegnummernkreisDefinition/gql/BelegnummernkreisDefinitionMutations.types';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useBelegnummernkreisDefinitionTemplatesQuery } from '../../../../../BelegnummernkreisDefinition/gql/BelegnummernkreisDefinitionQueries.types';
import { BelegnummernkreisDefinitionFieldsFragment } from '../../../../../BelegnummernkreisDefinition/gql/BelegnummernkreisDefinitionFragments.types';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';

type BelegnummernkreisFormProps = {
  onSuccess: () => void;
  onCancel: () => void;
  rechtstraegerId: string;
  belegnummernkreisDefinition?: BelegnummernkreisDefinitionFieldsFragment;
};

const BelegnummernkreisForm: FC<BelegnummernkreisFormProps> = ({ belegnummernkreisDefinition, onSuccess, onCancel, rechtstraegerId }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BelegnummernkreisDefinition'>('BelegnummernkreisDefinition');

  const isUpdate = !!belegnummernkreisDefinition;

  const [runCreate, { loading: loadingCreate }] = useCreateBelegnummernkreisDefinitionMutation({
    onCompleted: () => {
      showSuccessMsgCreate('Belegnummernkreisdefinition');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateBelegnummernkreisDefinitionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Belegnummernkreisdefinition');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const { data } = useBelegnummernkreisDefinitionTemplatesQuery({ variables: { rechtstraegerId } });
  const template = data?.getBelegnummernkreisDefinitionTemplates.data[0];

  if (!template) {
    return <Spin />;
  }

  return (
    <Formik<BelegnummernkreisFormValues>
      initialValues={
        isUpdate ? mapBelegnummernkreisToFormValues(belegnummernkreisDefinition, template) : belegnummernkreisFormInitialValues(template)
      }
      validationSchema={belegnummernkreisFormValidationSchema}
      onSubmit={(request, { setSubmitting }) => {
        const input = mapFormValuesToBelegnummernkreisDefinition(request);
        if (isUpdate) {
          runUpdate({
            variables: { rechtstraegerId, belegnummernkreisDefinitionId: belegnummernkreisDefinition.belegnummernkreisDefinitionId, input },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({ variables: { rechtstraegerId, input } }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Allgemein</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                name={belegnummernkreisFormFields.bezeichnung}
                label="Bezeichnung"
                fieldHelp={getFieldHelpText('BelegnummernkreisDefinition.bezeichnung')}
              >
                <Input size="small" id={belegnummernkreisFormFields.bezeichnung} name={belegnummernkreisFormFields.bezeichnung} />
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp
                name={belegnummernkreisFormFields.jaehrlichNeu}
                label="Nummernkreis jährlich neu starten"
                fieldHelp={getFieldHelpText('BelegnummernkreisDefinition.jaehrlichNeu')}
              >
                <Space>
                  <Switch id={belegnummernkreisFormFields.jaehrlichNeu} name={belegnummernkreisFormFields.jaehrlichNeu} />
                  <Typography.Text>{formikProps.values.jaehrlichNeu ? 'Ja' : 'Nein'}</Typography.Text>
                </Space>
              </FormItemWithFieldHelp>
            </Col>
          </Row>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Nummernkreis</Typography.Title>
            </Col>
            <Col span={18}>
              <BelegnummernkreisTextbausteinFormItem
                name={belegnummernkreisFormFields.prefix}
                label="Prefix"
                fieldHelp={getFieldHelpText('BelegnummernkreisDefinition.prefix')}
              />
              <FormItemWithFieldHelp
                name={belegnummernkreisFormFields.nummernteil}
                label="Nummernteil"
                fieldHelp={getFieldHelpText('BelegnummernkreisDefinition.nummernteil')}
              >
                <Input size="small" id={belegnummernkreisFormFields.nummernteil} name={belegnummernkreisFormFields.nummernteil} />
              </FormItemWithFieldHelp>
              <BelegnummernkreisTextbausteinFormItem
                name={belegnummernkreisFormFields.postfix}
                label="Postfix"
                fieldHelp={getFieldHelpText('BelegnummernkreisDefinition.postfix')}
              />
            </Col>
          </Row>
          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default BelegnummernkreisForm;
