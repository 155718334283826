import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import RechtstraegerTicketListing from './RechtstraegerTicketListing';

type Props = {
  rechtstraegerId: string;
};

const RechtstraegerTicketTab: FC<Props> = ({ rechtstraegerId }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Aufgaben',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechtstraegerTicketListing rechtstraegerId={rechtstraegerId} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default RechtstraegerTicketTab;
