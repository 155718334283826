import React, { FC } from 'react';
import { FormikProps } from 'formik';
import {
  objektFinancialPlanVerarbeitungEntryFiltersFormFields,
  ObjektFinancialPlanVerarbeitungEntryFiltersFormValues,
} from './ListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import GenerierlaufEntryExitCodeListSelect from '../../../shared/Filters/GenerierlaufEntryExitCodeListSelect';
import { ObjektFinancialPlanGenerierlauf } from '../../../../../types';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import VerarbeitungObjektFormSelect, { VerarbeitungObjektFormSelectProps } from '../../../shared/Filters/VerarbeitungObjektFormSelect';
import VerarbeitungRechtstraegerFormSelect, {
  VerarbeitungRechtstraegerSelectProps,
} from '../../../shared/Filters/VerarbeitungRechtstraegerFormSelect';
import BudgetingSelect, { VerarbeitungBudgetingFormSelectProps } from './BudgetingSelect';

type Props = {
  generierlauf: ObjektFinancialPlanGenerierlauf;
  formikProps: FormikProps<ObjektFinancialPlanVerarbeitungEntryFiltersFormValues>;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlauf }) => {
  const rechtstraegerList = generierlauf.entryList.reduce((acc: VerarbeitungRechtstraegerSelectProps[]) => {
    const { rechtstraegerId, kurzBezeichnung } = generierlauf.budgeting.objekt.rechnungsAussteller;
    const exists = acc.find((item) => item.rechtstraegerId === rechtstraegerId);
    if (!exists) {
      acc.push({
        rechtstraegerId,
        kurzBezeichnung,
      });
    }
    return acc;
  }, []);

  const budgetingList = generierlauf.entryList.reduce((acc: VerarbeitungBudgetingFormSelectProps[]) => {
    const { budgetingId, bezeichnung } = generierlauf.budgeting;
    const exists = acc.find((item) => item.budgetingId === budgetingId);
    if (!exists) {
      acc.push({
        budgetingId,
        kurzBezeichnung: bezeichnung,
      });
    }
    return acc;
  }, []);

  const objektList = generierlauf.entryList.reduce((acc: VerarbeitungObjektFormSelectProps[]) => {
    const { objektId, kurzBezeichnung } = generierlauf.budgeting.objekt;
    const exists = acc.find((item) => item.objektId === objektId);
    if (!exists) {
      acc.push({
        objektId,
        kurzBezeichnung,
      });
    }
    return acc;
  }, []);

  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={objektFinancialPlanVerarbeitungEntryFiltersFormFields.rechnungsAusstellerId} label="Rechnungsaussteller">
            <VerarbeitungRechtstraegerFormSelect
              name={objektFinancialPlanVerarbeitungEntryFiltersFormFields.rechnungsAusstellerId}
              onChange={formikProps.submitForm}
              rechtstraegerList={rechtstraegerList}
              placeholder="Rechnungsaussteller auswählen"
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={objektFinancialPlanVerarbeitungEntryFiltersFormFields.budgetingId} label="Budgetierung">
            <BudgetingSelect
              name={objektFinancialPlanVerarbeitungEntryFiltersFormFields.budgetingId}
              onChange={formikProps.submitForm}
              items={budgetingList}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={objektFinancialPlanVerarbeitungEntryFiltersFormFields.objektId} label="Objekt">
            <VerarbeitungObjektFormSelect
              name={objektFinancialPlanVerarbeitungEntryFiltersFormFields.objektId}
              id={objektFinancialPlanVerarbeitungEntryFiltersFormFields.objektId}
              objektList={objektList}
              onChange={formikProps.submitForm}
              maxTextLength={35}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={objektFinancialPlanVerarbeitungEntryFiltersFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListSelect
              name={objektFinancialPlanVerarbeitungEntryFiltersFormFields.exitCodeList}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
