import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBookingSuggestionVerbuchenGenerierlaufMutationVariables = Types.Exact<{
  input: Types.BookingSuggestionVerbuchenGenerierlaufCreateInput;
}>;

export type CreateBookingSuggestionVerbuchenGenerierlaufMutation = {
  createBookingSuggestionVerbuchenGenerierlauf: {
    data: { generierlaufId: string };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateBookingSuggestionVerbuchenGenerierlaufDocument = gql`
  mutation CreateBookingSuggestionVerbuchenGenerierlauf($input: BookingSuggestionVerbuchenGenerierlaufCreateInput!) {
    createBookingSuggestionVerbuchenGenerierlauf(input: $input) {
      data {
        generierlaufId
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateBookingSuggestionVerbuchenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBookingSuggestionVerbuchenGenerierlaufMutation,
    CreateBookingSuggestionVerbuchenGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBookingSuggestionVerbuchenGenerierlaufMutation, CreateBookingSuggestionVerbuchenGenerierlaufMutationVariables>(
    CreateBookingSuggestionVerbuchenGenerierlaufDocument,
    options
  );
}
export type CreateBookingSuggestionVerbuchenGenerierlaufMutationHookResult = ReturnType<
  typeof useCreateBookingSuggestionVerbuchenGenerierlaufMutation
>;
export type CreateBookingSuggestionVerbuchenGenerierlaufMutationResult = Apollo.MutationResult<CreateBookingSuggestionVerbuchenGenerierlaufMutation>;
export type CreateBookingSuggestionVerbuchenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateBookingSuggestionVerbuchenGenerierlaufMutation,
  CreateBookingSuggestionVerbuchenGenerierlaufMutationVariables
>;
