import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Dropdown, MenuProps, Row, Space } from 'antd';
import { FormikProps } from 'formik';

type Props = {
  pushPath: string;
  formikProps?: FormikProps<any>;
  onSubmit?: () => void;
};

const DefinitionUpdateFormButtons: FC<Props> = ({ pushPath, formikProps, onSubmit }) => {
  const navigate = useNavigate();

  const submit = (closeAfterSubmit: boolean) => {
    if (formikProps) {
      formikProps.submitForm().then(() => {
        // should not be necessary to check validity because promise should be rejected in case of val.errors
        // but: https://github.com/jaredpalmer/formik/issues/1580
        if (closeAfterSubmit && formikProps.isValid) navigate(pushPath);
      });
    } else if (onSubmit) {
      onSubmit();
      if (closeAfterSubmit) {
        navigate(pushPath);
      }
    } else console.warn('Either formikProps or onSubmit must be provided');
  };

  const items: MenuProps['items'] = [
    { key: '1', label: 'Speichern', onClick: () => submit(false) },
    { key: '2', label: 'Speichern und schließen', onClick: () => submit(true) },
  ];

  return (
    <Row style={{ justifyContent: 'end' }}>
      <Col>
        <Space>
          <Button onClick={() => navigate(pushPath)}>Schließen</Button>
          <Dropdown.Button menu={{ items }} type="primary" htmlType={formikProps ? 'submit' : 'button'} onClick={() => !formikProps && onSubmit?.()}>
            Speichern
          </Dropdown.Button>
        </Space>
      </Col>
    </Row>
  );
};

export default DefinitionUpdateFormButtons;
