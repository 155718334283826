import { FC } from 'react';
import { Typography } from 'antd';
import TopAbrVerarbeitungEntryListing from './EntryListing/TopAbrVerarbeitungEntryListing';
import { Spacer } from '../../../components/Grid';
import { AppGenerierlaufType, TopAbrechnungGenerierlauf } from '../../../types';

type Props = {
  title: string;
  type: string;
  generierlauf: TopAbrechnungGenerierlauf;
  generierlaufType: AppGenerierlaufType;
};

const VerarbeitungTopAbrechnung: FC<Props> = ({ generierlauf, title, type, generierlaufType }) => (
  <>
    <Typography.Title level={4}>{title}</Typography.Title>
    <Spacer />
    <TopAbrVerarbeitungEntryListing generierlauf={generierlauf} type={type} generierlaufType={generierlaufType} />
  </>
);

export default VerarbeitungTopAbrechnung;
