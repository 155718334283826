import React from 'react';
import { Input } from 'formik-antd';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

const KundenSystemBaseFormPart = () => {
  const getFieldHelpText = useGetAppFieldHelpText<'KundenSystem'>('KundenSystem');

  return (
    <FormItemWithFieldHelp name="name" label="Bezeichnung" fieldHelp={getFieldHelpText('KundenSystem.name')}>
      <Input id="name" name="name" type="text" placeholder="Systemname" />
    </FormItemWithFieldHelp>
  );
};

export default KundenSystemBaseFormPart;
