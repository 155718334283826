import React, { FC } from 'react';
import { RouteProps, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router';
import Authorized from './Authorized';
import { useAuth } from '../shared/Auth/authContext';
import { HasPermissionFunction } from './permissionChecks';
import { URI_LOGIN_PAGE } from '../constants/loginUriPaths';

type AuthRouteProps = {
  hasPermissionFn?: HasPermissionFunction;
} & Pick<RouteProps, 'element'>;

const AuthRoute: FC<AuthRouteProps> = ({ element: routeComponent, hasPermissionFn }) => {
  const { user } = useAuth();
  const location = useLocation();
  if (!user) {
    // state.from is used to redirect back to the page the user wanted to access after login when session is expired
    return <Navigate to={{ pathname: URI_LOGIN_PAGE }} replace state={{ from: location }} />;
  }
  if (hasPermissionFn) {
    return <Authorized hasPermissionFn={hasPermissionFn}>{routeComponent}</Authorized>;
  }

  return <>{routeComponent}</>;
};

export default AuthRoute;
