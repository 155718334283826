import React, { FC } from 'react';
import { Skeleton } from 'antd';
import BankDetailsCard from './BankDetailsCard';
import { Spacer } from '../../../components/Grid';
import { BankDetails } from '../../../types';

type Props = {
  loading: boolean;
  bankDetailsList: BankDetails[];
  onAction: () => any;
  rechtstraegerId?: string;
  firmendatenId?: string;
};

const BankDetailsCardListing: FC<Props> = ({ loading, bankDetailsList, rechtstraegerId, firmendatenId, onAction, ...restProps }) => {
  return (
    <Skeleton active loading={loading} {...restProps}>
      {bankDetailsList.map((bankDetails) => (
        <React.Fragment key={bankDetails.bankDetailsId}>
          <BankDetailsCard rechtstraegerId={rechtstraegerId} firmendatenId={firmendatenId} bankDetails={bankDetails} onAction={onAction} />
          {bankDetailsList.length > 1 ? <Spacer /> : null}
        </React.Fragment>
      ))}
    </Skeleton>
  );
};

export default BankDetailsCardListing;
