import React, { FC } from 'react';
import { AbrechnungskreisNichtAbrechnen } from '../AbrKontenzuordnung/Form/zuordnungKontoListingFormMapper';
import { Abrechnungskreis, AbrechnungskreisStatus, AbrechnungskreisType, KontoKlasse, KontoVerwendung } from '../../types';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';

import {
  isAbrKreisTypeBewirtschaftungskosten,
  isAbrKreisTypeRepFonds,
  isAbrKreisTypeVstKuerzung,
} from '../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { getZuordnungAbrKreisName } from '../AbrKontenzuordnung/shared/AbrKreisKontenzuordnungHelpers';

import { useAbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery } from '../AbrechnungsdefinitionTemplate/gql/AbrDefTemplateVersionQueries.types';
import { abrechnungskreisListFilteredByKlasseAndVerwendung } from '../AbrDefAbrechnungskreisSelect/abrDefAbrechnungskreisSelectFilterHelpers';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
  abrDefKontoKlasseAndVerwendung: { klasse?: KontoKlasse; verwendung?: KontoVerwendung };
  disabled?: boolean;
  name: string;
  isAufwandskonto: boolean;
  isUstVomAufwand: boolean;
  onChange: (isAbrKreisRepFonds: boolean, isAbrKreisBewirtschaftungskosten: boolean, selectedAbrKreisName: string) => void;
};

const AbrDefTemplateAbrechnungskreisSelect: FC<Props> = ({
  abrechnungsdefinitionId,
  abrechnungsdefinitionVersionId,
  abrDefKontoKlasseAndVerwendung,
  disabled,
  name,
  isAufwandskonto,
  isUstVomAufwand,
  onChange,
}) => {
  const { loading, data: maybeData } = useAbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery({
    variables: {
      abrechnungsdefinitionId,
      abrechnungsdefinitionVersionId,
    },
  });
  const abrechnungskreisList = maybeData?.getAbrechnungsdefinitionTemplateVersionAbrechnungskreisList.data ?? [];

  const listFilteredByKlasseAndVerwendung = abrechnungskreisListFilteredByKlasseAndVerwendung(
    abrechnungskreisList,
    abrDefKontoKlasseAndVerwendung.klasse,
    abrDefKontoKlasseAndVerwendung.verwendung
  );
  const itemNichtAbrechnen: Abrechnungskreis = {
    abrechnungskreisId: AbrechnungskreisNichtAbrechnen.id,
    bezeichnung: AbrechnungskreisNichtAbrechnen.text,
    status: { value: AbrechnungskreisStatus.Aktiv, text: 'aktiv' },
    type: { value: AbrechnungskreisType.Bewirtschaftungskosten, text: 'Bewirtschaftungskosten' },
    createdBy: '',
    createdByMitarbeiterId: undefined,
    createTs: '',
    deletable: false,
    firmendatenId: undefined,
    updatedByMitarbeiterId: undefined,
    verrechnungUstVomAufwand: undefined,
    warningList: [],
  };
  const abrechnungskreisListWithCustomValues = [...listFilteredByKlasseAndVerwendung, itemNichtAbrechnen];

  const abrechnungskreisListWithOrWithoutVstKuerzung = isAufwandskonto
    ? abrechnungskreisListWithCustomValues
    : abrechnungskreisListWithCustomValues.filter((abrKreis) => !isAbrKreisTypeVstKuerzung(abrKreis.type.value));

  const isAbrKreisRepFonds = (abrKreisId: string) => {
    const selectedAbrKreis = abrechnungskreisListWithOrWithoutVstKuerzung.find((abrKreis) => abrKreis.abrechnungskreisId === abrKreisId);
    return isAufwandskonto && selectedAbrKreis ? isAbrKreisTypeRepFonds(selectedAbrKreis.type.value) : false;
  };

  const isAbrKreisBewirtschaftungskosten = (abrKreisId: string) => {
    const selectedAbrKreis = abrechnungskreisListWithOrWithoutVstKuerzung.find(
      (abrKreis) => abrKreis.abrechnungskreisId === abrKreisId && abrKreisId !== AbrechnungskreisNichtAbrechnen.id
    );
    return selectedAbrKreis ? isAbrKreisTypeBewirtschaftungskosten(selectedAbrKreis.type.value) : false;
  };

  const selectedAbrKreisName = (abrKreisId: string) =>
    abrechnungskreisListWithOrWithoutVstKuerzung.find((item) => item.abrechnungskreisId === abrKreisId)?.bezeichnung;

  return (
    <>
      <EntityStatusFormSelect<Abrechnungskreis>
        list={abrechnungskreisListWithOrWithoutVstKuerzung}
        getSelectItemId={(abrechnungskreis) => abrechnungskreis.abrechnungskreisId}
        getSelectItemContent={(abrechnungskreis) =>
          getZuordnungAbrKreisName(
            abrechnungskreis.bezeichnung,
            isAufwandskonto,
            isAbrKreisTypeRepFonds(abrechnungskreis.type.value),
            isUstVomAufwand
          )
        }
        getSelectItemTooltipTitle={(abrechnungskreis) =>
          getZuordnungAbrKreisName(
            abrechnungskreis.bezeichnung,
            isAufwandskonto,
            isAbrKreisTypeRepFonds(abrechnungskreis.type.value),
            isUstVomAufwand
          )
        }
        size="small"
        disabled={disabled}
        name={name}
        loading={loading}
        placeholder="Abrechnungskreis für Konto auswählen"
        onChange={(value) => {
          onChange(isAbrKreisRepFonds(value), isAbrKreisBewirtschaftungskosten(value), selectedAbrKreisName(value) ?? 'Keine Zuordnung');
        }}
      />
    </>
  );
};

export default AbrDefTemplateAbrechnungskreisSelect;
