import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../shared/Auth/authContext';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { DataChangedChangeType, MahnungStatus } from '../../../types';
import { dayjsCustom } from '../../../helpers/dayjsCustom';

export const useOnMahnungDataChangeEventForTable = (
  key: string,
  callback: () => Promise<unknown>,
  mahnungIds: string | string[],
  objektId?: string,
  statusList?: MahnungStatus[],
  mahnstichtagVonInklusive?: string,
  mahnstichtagBisInklusive?: string,
  erstellerMitarbeiterIdList?: string[],
  rechnungsAusstellerId?: string,
  vertragspartnerId?: string
) => {
  const filterParams = {
    rechnungsAusstellerId,
    vertragspartnerId,
    mahnstichtag: (mahnstichtag: string) => {
      if (mahnstichtagVonInklusive && mahnstichtagBisInklusive) {
        const vonInklusive = dayjsCustom(mahnstichtagVonInklusive);
        const bisInklusive = dayjsCustom(mahnstichtagBisInklusive);
        return dayjsCustom(mahnstichtag).isBetween(vonInklusive, bisInklusive);
      }
      if (mahnstichtagVonInklusive) {
        return dayjsCustom(mahnstichtag).isSameOrAfter(dayjsCustom(mahnstichtagVonInklusive));
      }
      if (mahnstichtagBisInklusive) {
        return dayjsCustom(mahnstichtag).isSameOrBefore(dayjsCustom(mahnstichtagBisInklusive));
      }
      return true;
    },
    createdByMitarbeiterId: erstellerMitarbeiterIdList,
    status: statusList,
    objektId,
  };

  useOnDataChangedEvent([
    {
      filter: {
        //userId: user.username,
        //mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.mahnung,
        params: {
          ...filterParams,
          mahnungId: mahnungIds,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Mahnung wurden gerade verändert',
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        //userId: user.username,
        //mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.mahnung,
        params: {
          ...filterParams,
        },
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Mahnung wurde gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        //userId: user.username,
        //mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.mahnung,
        params: {
          ...filterParams,
          mahnungId: mahnungIds,
        },
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Mahnung wurde gerade gelöscht',
      key: `${key}-delete`,
      callback,
    },
  ]);
};

export const useOnMahnungDataChangeEventForDetails = (key: string, callback: () => Promise<unknown>, mahnungId: string) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.mahnung,
        params: {
          mahnungId,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Mahnung wurden gerade verändert',
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.mahnung,
        params: {
          mahnungId,
        },
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Mahnung wurde gerade gelöscht',
      key: `${key}-delete`,
      callback,
    },
  ]);
};
