import { Formik } from 'formik';
import React, { FC } from 'react';
import { Form, Select } from 'formik-antd';
import { Box } from 'rebass';
import { Divider } from 'antd';
import { Dayjs } from 'dayjs';
import { getFirstTimeblock, getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../../components/Timeline/timelineCtx';
import {
  mapFormValuesToBeVertragVersion,
  mapTimeblockToFormValues,
  vertragVersionFormFields,
  VertragVersionFormValues,
  vertragVersionFormValuesDescriptions,
  vertragVersionFormValuesDescriptionsForKommentar,
} from './vertragVersionFormMapper';
import { useCreateBeVertragVersionMutation, useUpdateBeVertragVersionMutation } from '../gql/BeVertragVersionMutations.types';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { BeVertragVersion, DueDateVorschreibungArt } from '../../../../../types';
import VertragPaymentMethodSelect from '../../VertragPaymentMethodSelect';
import VertragVerrechnungsartSelect from '../../VertragVerrechnungsartSelect';
import TimelineFormKommentar from '../../../../../components/Timeline/Form/TimelineFormKommentar';
import TimelineFormDatePicker from '../../../../../components/Timeline/Form/TimelineFormDatePicker';
import TimelineFormButtons from '../../../../../components/Timeline/Form/TimelineFormButtons';
import { layoutTimelineForm } from '../../../../../components/Input-antd/formLayoutHelper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';

type VertragVersionFormProps = {
  timeblock: ITimeblock<BeVertragVersion>;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  onSubmitSuccess: () => void;
};

const VertragVersionForm: FC<VertragVersionFormProps> = ({ timeblock, objektId, bestandseinheitId, vertragId, onSubmitSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeVertragVersion'>('BeVertragVersion');

  const { dataSource, toggleEdit, changeToUpdateMode } = useTimeline<BeVertragVersion>();
  const initialValues = mapTimeblockToFormValues(timeblock);
  const isUpdate = !!timeblock.vertragVersionId;

  const entity = 'Vertrag';

  const [runUpdate, { loading: loadingUpdate }] = useUpdateBeVertragVersionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      toggleEdit(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreate, { loading: loadingCreate }] = useCreateBeVertragVersionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      changeToUpdateMode(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<VertragVersionFormValues>
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        if (isUpdate) {
          runUpdate({
            variables: {
              objektId,
              bestandseinheitId,
              vertragId,
              vertragVersionId: timeblock.vertragVersionId,
              request: mapFormValuesToBeVertragVersion(values),
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({
            variables: {
              objektId,
              bestandseinheitId,
              vertragId,
              request: mapFormValuesToBeVertragVersion(values),
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form {...layoutTimelineForm} layout="horizontal" labelAlign="left">
          <Box width={2 / 3}>
            <FormItemWithFieldHelp
              name={vertragVersionFormFields.validFrom}
              label="Gültig ab"
              fieldHelp={getFieldHelpText('BeVertragVersion.validFrom')}
            >
              <TimelineFormDatePicker<BeVertragVersion>
                id={vertragVersionFormFields.validFrom}
                name={vertragVersionFormFields.validFrom}
                timeblock={timeblock}
                disabledDates={(date) => isBeforeFirstTimeblock(dataSource, date as unknown as Dayjs)}
              />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={vertragVersionFormFields.dueDateVorschreibung}
              label={vertragVersionFormValuesDescriptions.dueDateVorschreibung}
              fieldHelp={getFieldHelpText('BeVertragVersion.dueDateVorschreibung')}
            >
              <Select
                id={vertragVersionFormFields.dueDateVorschreibung}
                name={vertragVersionFormFields.dueDateVorschreibung}
                placeholder="Fälligkeit auswählen"
                size="small"
              >
                <Select.Option value={DueDateVorschreibungArt.MonatsErster}>Monatserster</Select.Option>
                <Select.Option value={DueDateVorschreibungArt.MonatsFuenfter}>Monatsfünfter</Select.Option>
              </Select>
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={vertragVersionFormFields.verrechnungsart}
              label={vertragVersionFormValuesDescriptions.verrechnungsart}
              fieldHelp={getFieldHelpText('BeVertragVersion.verrechnungsart')}
            >
              <VertragVerrechnungsartSelect
                id={vertragVersionFormFields.verrechnungsart}
                name={vertragVersionFormFields.verrechnungsart}
                size="small"
              />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={vertragVersionFormFields.paymentMethod}
              label={vertragVersionFormValuesDescriptions.paymentMethod}
              fieldHelp={getFieldHelpText('BeVertragVersion.paymentMethod')}
            >
              <VertragPaymentMethodSelect id={vertragVersionFormFields.paymentMethod} name={vertragVersionFormFields.paymentMethod} size="small" />
            </FormItemWithFieldHelp>
          </Box>

          <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />

          <TimelineFormKommentar
            name={vertragVersionFormFields.kommentar}
            timeblock={timeblock}
            valuesDescriptions={vertragVersionFormValuesDescriptionsForKommentar}
          />

          <TimelineFormButtons<BeVertragVersion>
            timeblock={timeblock}
            isSubmitting={formikProps.isSubmitting}
            isSameAsPreviousVersion={isSameAsPreviousVersion(
              formikProps.values,
              getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
            )}
            submitBtnLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

const isSameAsPreviousVersion = (formValues: VertragVersionFormValues, previousTimeblock?: ITimeblock<BeVertragVersion>) =>
  formValues.dueDateVorschreibung === previousTimeblock?.dueDateVorschreibung.value &&
  formValues.verrechnungsart === previousTimeblock?.verrechnungsart.value &&
  formValues.paymentMethod === previousTimeblock?.paymentMethod.value;

const isBeforeFirstTimeblock = (dataSource: ITimeblock<BeVertragVersion>[], current?: Dayjs) =>
  !!current && current < dayjsCustom(getFirstTimeblock(dataSource).validFrom).endOf('day');

export default VertragVersionForm;
