import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { ApplyVertragVposIndexedValueGenerierlauf } from '../../../../types';
import EntryTable from './EntryTable';
import ListingFilters from './Filters/ListingFilters';
import { verarbeitungTypes } from '../../verarbeitungHelpers';
import { TVertagVposIndexedValueVerarbeitungQueryParams, updateQueryParams } from '../../shared/VertragVposIndexedValue/filtersQueryParams';
import {
  mapFormValuesToVertragVposIndexedValueVerarbeitungFilters,
  VertragVposIndexedValueVerarbeitungEntryFiltersFormValues,
  vertragVposIndexedValueVerarbEntryListingFiltersFormInitialValues,
} from '../../shared/VertragVposIndexedValue/ListingFiltersFormMapper';

type Props = {
  generierlauf: ApplyVertragVposIndexedValueGenerierlauf;
};

const EntryListing: FC<Props> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TVertagVposIndexedValueVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.ApplyVertragVposIndexedValue, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: VertragVposIndexedValueVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.ApplyVertragVposIndexedValue,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToVertragVposIndexedValueVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<VertragVposIndexedValueVerarbeitungEntryFiltersFormValues>
      initialValues={vertragVposIndexedValueVerarbEntryListingFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />
          <EntryTable queryParams={queryParams} generierlaufId={generierlauf.generierlaufId} />
        </>
      )}
    </Formik>
  );
};

export default EntryListing;
