import { FC } from 'react';
import { useField } from 'formik';
import { RadioGroupProps } from 'formik-antd';
import { Radio } from 'antd';
import { ZuordnungKontoVerwendung } from '../zuordnungKontoListingFormMapper';

type Props = {
  name: string;
  verwendung: ZuordnungKontoVerwendung;
} & RadioGroupProps;

const VerwendungButtons: FC<Props> = ({ name, verwendung, ...restProps }) => {
  const [, { value }, { setValue: setUstVomAufwand }] = useField(name);

  return (
    <Radio.Group
      {...restProps}
      block
      name={name}
      value={value}
      onChange={(value) => {
        setUstVomAufwand(value.target.value);
      }}
    >
      <Radio.Button value={ZuordnungKontoVerwendung.NOT_UST_VOM_AUFWAND} style={{ width: '130px', textAlign: 'center' }}>
        Ausgaben
      </Radio.Button>
      <Radio.Button value={ZuordnungKontoVerwendung.UST_VOM_AUFWAND} style={{ width: '120px' }}>
        Ust. vom Aufwand
      </Radio.Button>
    </Radio.Group>
  );
};

export default VerwendungButtons;
