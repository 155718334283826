import React, { FC } from 'react';
import { FormikProps } from 'formik';
import {
  vorschreibungListingFiltersFormFields,
  VorschreibungListingFiltersFormValues,
  vorschreibungListingLabelList,
} from './vorschreibungListingFiltersFormMapper';
import { TVorschreibungQueryParams } from './filtersQueryParams';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import RechnungsausstellerSelect from '../../../Rechnungsaussteller/RechnungsausstellerSelect';
import AuftragsartTypeSelect from '../../../Auftragsart/shared/AuftragsartTypeSelect';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import AuftragStatusFormSelect from './AuftragStatusFormSelect';
import SepaCreditorIdListFormSelect from '../../../Payment/shared/SepaCreditorIdListFormSelect';
import SepaMandateReferenceListFormSelect from '../../../Payment/shared/SepaMandateReferenceListFormSelect';
import VorschreibungActions from '../VorschreibungActions';
import PaymentMethodListFormSelect from '../../../PaymentMethodSelect/PaymentMethodListFormSelect';
import EmpfaengerKundennummerListFormSelect from './EmpfaengerKundennummerListFormSelect';
import RechtstraegerSelect from '../../../../shared/Rechtstraeger/RechtstraegerSelect';
import ObjektSelect from '../../../ObjektSelect/ObjektSelect';
import AusstellerIbanListFormSelect from './AusstellerIbanListFormSelect';
import EmpfaengerIbanListFormSelect from './EmpfaengerIbanListFormSelect';
import AuftragBelegnummerFormSelect from './AuftragBelegnummerFormSelect';
import FiltersWithSelectorWrapper from '../../../../components/Filters/FiltersWithSelector/FiltersWithSelectorWrapper';
import useFilterWithQueryParamsNew from '../../../../components/Filters/useFilterWithQueryParamsNew';
import FromToFormInputNumberForFilter from '../../../shared/FromToForm/FromToFormInputNumberForFilter';
import FormItemForFilter from '../../../../components/Form/FormItemForFilter';
import VertragsartListFormSelect from './VertragsartListFormSelect';

type Props = {
  formikProps: FormikProps<VorschreibungListingFiltersFormValues>;
  queryParams: TVorschreibungQueryParams;
};

const VorschreibungListingFilters: FC<Props> = ({ formikProps, queryParams }) => {
  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === vorschreibungListingFiltersFormFields.nettoFrom || filter === vorschreibungListingFiltersFormFields.nettoTo) {
      return vorschreibungListingFiltersFormFields.nettoFrom;
    }
    if (filter === vorschreibungListingFiltersFormFields.bruttoFrom || filter === vorschreibungListingFiltersFormFields.bruttoTo) {
      return vorschreibungListingFiltersFormFields.bruttoFrom;
    }
    if (filter === vorschreibungListingFiltersFormFields.ustFrom || filter === vorschreibungListingFiltersFormFields.ustTo) {
      return vorschreibungListingFiltersFormFields.ustFrom;
    }
    if (filter === vorschreibungListingFiltersFormFields.createTsFrom || filter === vorschreibungListingFiltersFormFields.createTsTo) {
      return vorschreibungListingFiltersFormFields.createTsFrom;
    }
    if (
      filter === vorschreibungListingFiltersFormFields.fakturierungsperiodeDateFrom ||
      filter === vorschreibungListingFiltersFormFields.fakturierungsperiodeDateTo
    ) {
      return vorschreibungListingFiltersFormFields.fakturierungsperiodeDateFrom;
    }
    if (filter === vorschreibungListingFiltersFormFields.rechnungsDatumFrom || filter === vorschreibungListingFiltersFormFields.rechnungsDatumTo) {
      return vorschreibungListingFiltersFormFields.rechnungsDatumFrom;
    }
    if (filter === vorschreibungListingFiltersFormFields.dueDateFrom || filter === vorschreibungListingFiltersFormFields.dueDateTo) {
      return vorschreibungListingFiltersFormFields.dueDateFrom;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === vorschreibungListingFiltersFormFields.ausstellerRechtstraegerId) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.ausstellerRechtstraegerId} key={filter}>
          <RechnungsausstellerSelect
            name={vorschreibungListingFiltersFormFields.ausstellerRechtstraegerId}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.ausstellerRechtstraegerId}
            style={{ minWidth: '260px' }}
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.fakturierungsperiodeDateFrom) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.fakturierungsperiodeDateFrom} key={filter}>
          <FromToFormDatePicker
            format="month"
            fromName={vorschreibungListingFiltersFormFields.fakturierungsperiodeDateFrom}
            toName={vorschreibungListingFiltersFormFields.fakturierungsperiodeDateTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${vorschreibungListingLabelList.fakturierungsperiodeDateFrom} - von`}
            toPrefix={`${vorschreibungListingLabelList.fakturierungsperiodeDateFrom} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.auftragsartType) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.auftragsartType} key={filter}>
          <AuftragsartTypeSelect
            name={vorschreibungListingFiltersFormFields.auftragsartType}
            allowClear
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.auftragsartType}
            placeholder=""
            style={{ minWidth: '260px' }}
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.empfaengerRechtstraegerId) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.empfaengerRechtstraegerId} key={filter}>
          <RechtstraegerSelect
            name={vorschreibungListingFiltersFormFields.empfaengerRechtstraegerId}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.empfaengerRechtstraegerId}
            placeholder=""
            size="middle"
            style={{ minWidth: '260px' }}
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.objektId) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.objektId} key={filter}>
          <ObjektSelect
            name={vorschreibungListingFiltersFormFields.objektId}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.objektId}
            size="middle"
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.nettoFrom) {
      return (
        <FromToFormInputNumberForFilter
          name="Netto"
          fromName={vorschreibungListingFiltersFormFields.nettoFrom}
          toName={vorschreibungListingFiltersFormFields.nettoTo}
          onChange={formikProps.submitForm}
          key={filter}
          fromPrefix={`${vorschreibungListingLabelList.nettoFrom} - von`}
          toPrefix={`${vorschreibungListingLabelList.nettoFrom} - bis`}
        />
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.bruttoFrom) {
      return (
        <FromToFormInputNumberForFilter
          name="Brutto"
          fromName={vorschreibungListingFiltersFormFields.bruttoFrom}
          toName={vorschreibungListingFiltersFormFields.bruttoTo}
          onChange={formikProps.submitForm}
          key={filter}
          fromPrefix={`${vorschreibungListingLabelList.bruttoFrom} - von`}
          toPrefix={`${vorschreibungListingLabelList.bruttoFrom} - bis`}
        />
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.ustFrom) {
      return (
        <FromToFormInputNumberForFilter
          name="Ust."
          fromName={vorschreibungListingFiltersFormFields.ustFrom}
          toName={vorschreibungListingFiltersFormFields.ustTo}
          onChange={formikProps.submitForm}
          key={filter}
          fromPrefix={`${vorschreibungListingLabelList.ustFrom} - von`}
          toPrefix={`${vorschreibungListingLabelList.ustFrom} - bis`}
        />
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.erstellerMitarbeiterIdList) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.erstellerMitarbeiterIdList} key={filter}>
          <MitarbeiterSelect
            mode="multiple"
            name={vorschreibungListingFiltersFormFields.erstellerMitarbeiterIdList}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.erstellerMitarbeiterIdList}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.createTsFrom) {
      return (
        <FormItemForFilter name="erstelltAm" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={vorschreibungListingFiltersFormFields.createTsFrom}
            toName={vorschreibungListingFiltersFormFields.createTsTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${vorschreibungListingLabelList.createTsFrom} - von`}
            toPrefix={`${vorschreibungListingLabelList.createTsFrom} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.belegnummer) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.belegnummer} key={filter}>
          <AuftragBelegnummerFormSelect
            name={vorschreibungListingFiltersFormFields.belegnummer}
            belegnummer={formikProps.values.belegnummer}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.belegnummer}
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.status) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.status} key={filter}>
          <AuftragStatusFormSelect
            name={vorschreibungListingFiltersFormFields.status}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.status}
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.sepaCreditorId) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.sepaCreditorId} key={filter}>
          <SepaCreditorIdListFormSelect
            name={vorschreibungListingFiltersFormFields.sepaCreditorId}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.sepaCreditorId}
          />
        </FormItemForFilter>
      );
    }

    if (filter === vorschreibungListingFiltersFormFields.sepaMandateReference) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.sepaMandateReference} key={filter}>
          <SepaMandateReferenceListFormSelect
            name={vorschreibungListingFiltersFormFields.sepaMandateReference}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.sepaMandateReference}
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.paymentMethod) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.paymentMethod} key={filter}>
          <PaymentMethodListFormSelect
            name={vorschreibungListingFiltersFormFields.paymentMethod}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.paymentMethod}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.kundennummer) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.kundennummer} key={filter}>
          <EmpfaengerKundennummerListFormSelect
            name={vorschreibungListingFiltersFormFields.kundennummer}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.kundennummer}
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.rechnungsDatumFrom) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.rechnungsDatumFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={vorschreibungListingFiltersFormFields.rechnungsDatumFrom}
            toName={vorschreibungListingFiltersFormFields.rechnungsDatumTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${vorschreibungListingLabelList.rechnungsDatumFrom} - von`}
            toPrefix={`${vorschreibungListingLabelList.rechnungsDatumFrom} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.dueDateFrom) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.dueDateFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={vorschreibungListingFiltersFormFields.dueDateFrom}
            toName={vorschreibungListingFiltersFormFields.dueDateTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${vorschreibungListingLabelList.dueDateFrom} - von`}
            toPrefix={`${vorschreibungListingLabelList.dueDateFrom} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.ausstellerIban) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.ausstellerIban} key={filter}>
          <AusstellerIbanListFormSelect
            name={vorschreibungListingFiltersFormFields.ausstellerIban}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.ausstellerIban}
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.empfaengerIban) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.empfaengerIban} key={filter}>
          <EmpfaengerIbanListFormSelect
            name={vorschreibungListingFiltersFormFields.empfaengerIban}
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.empfaengerIban}
          />
        </FormItemForFilter>
      );
    }
    if (filter === vorschreibungListingFiltersFormFields.vertragsart) {
      return (
        <FormItemForFilter name={vorschreibungListingFiltersFormFields.vertragsart} key={filter}>
          <VertragsartListFormSelect
            name={vorschreibungListingFiltersFormFields.vertragsart}
            allowClear
            onChange={formikProps.submitForm}
            prefix={vorschreibungListingLabelList.vertragsart}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(vorschreibungListingFiltersFormFields.createTsFrom) && formikProps.values.createTsTo) {
      formikProps.setFieldValue(vorschreibungListingFiltersFormFields.createTsTo, undefined);
    }
    if (!selectedFilterList.includes(vorschreibungListingFiltersFormFields.bruttoFrom) && formikProps.values.bruttoTo) {
      formikProps.setFieldValue(vorschreibungListingFiltersFormFields.bruttoTo, undefined);
    }
    if (!selectedFilterList.includes(vorschreibungListingFiltersFormFields.nettoFrom) && formikProps.values.nettoTo) {
      formikProps.setFieldValue(vorschreibungListingFiltersFormFields.nettoTo, undefined);
    }
    if (!selectedFilterList.includes(vorschreibungListingFiltersFormFields.ustFrom) && formikProps.values.ustTo) {
      formikProps.setFieldValue(vorschreibungListingFiltersFormFields.ustTo, undefined);
    }
    if (
      !selectedFilterList.includes(vorschreibungListingFiltersFormFields.fakturierungsperiodeDateFrom) &&
      formikProps.values.fakturierungsperiodeDateTo
    ) {
      formikProps.setFieldValue(vorschreibungListingFiltersFormFields.fakturierungsperiodeDateTo, undefined);
    }
    if (!selectedFilterList.includes(vorschreibungListingFiltersFormFields.rechnungsDatumFrom) && formikProps.values.rechnungsDatumTo) {
      formikProps.setFieldValue(vorschreibungListingFiltersFormFields.rechnungsDatumTo, undefined);
    }
    if (!selectedFilterList.includes(vorschreibungListingFiltersFormFields.dueDateFrom) && formikProps.values.dueDateTo) {
      formikProps.setFieldValue(vorschreibungListingFiltersFormFields.dueDateTo, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, filterList } = useFilterWithQueryParamsNew({
    updateFilterValues,
    filters,
    labelList: vorschreibungListingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWithSelectorWrapper
      selectedFilterList={selectedFilterList}
      onSelectedFilterTitleList={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
      allFilterTitles={vorschreibungListingLabelList}
      filters={<>{filterList}</>}
      actionButtons={<VorschreibungActions />}
    />
  );
};

export default VorschreibungListingFilters;
