import { FC } from 'react';
import { FormikProps } from 'formik';
import { AuszifferungBlockFormValues, AuszifferungFormValues, OffenePostenForBooking } from '../../../../auszifferungFormMapper';
import { isExistingAuszifferung } from '../../../../auszifferungFormHelpers';
import StornoExistingAuszifferung from './StornoExistingAuszifferung';
import RemoveNewAuszifferung from './RemoveNewAuszifferung';

type Props = {
  bookingZahlungId: string;
  formikProps: FormikProps<AuszifferungBlockFormValues>;
  offenePosten: OffenePostenForBooking;
  record: AuszifferungFormValues;
};

const AuszifferungActionColumn: FC<Props> = ({ bookingZahlungId, formikProps, offenePosten, record }) => {
  return isExistingAuszifferung(record) && record.zahlungId ? (
    <StornoExistingAuszifferung
      auszifferungId={record.auszifferungId}
      bookingZahlungId={bookingZahlungId}
      formikProps={formikProps}
      offenePosten={offenePosten}
      zahlungId={record.zahlungId}
    />
  ) : (
    <RemoveNewAuszifferung bookingZahlungId={bookingZahlungId} formikProps={formikProps} offenePosten={offenePosten} record={record} />
  );
};

export default AuszifferungActionColumn;
