import { FC } from 'react';
import { FormikProps } from 'formik';
import { UstVomAufwandVerarbeitungEntryFiltersFormValues, ustVomAufwandVerarbEntryListingFiltersFormFields } from './ListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FiltersWithMax3Fields from '../../../../../components/Filters/FiltersWithMax3Fields';
import UstVomAufwandBuchungStatusSelect from './UstVomAufwandBuchungStatusSelect';
import VerarbeitungObjektFormSelect, { VerarbeitungObjektFormSelectProps } from '../../../shared/Filters/VerarbeitungObjektFormSelect';
import { getUniqueList } from '../../../../shared/getUniqueList';
import { UstVomAufwandGenerierlaufEntry } from '../../../../../types';

type ListingFiltersProps = {
  formikProps: FormikProps<UstVomAufwandVerarbeitungEntryFiltersFormValues>;
  entryList: UstVomAufwandGenerierlaufEntry[];
};

const ListingFilters: FC<ListingFiltersProps> = ({ formikProps, entryList }) => {
  const objektList: VerarbeitungObjektFormSelectProps[] = entryList.map((entry) => ({
    objektId: entry.objekt.objektId,
    kurzBezeichnung: entry.objekt.kurzBezeichnung,
  }));

  const uniqueObjektList = getUniqueList(objektList, (a, b) => a.objektId === b.objektId);

  return (
    <FiltersWithMax3Fields
      hideTitle
      filters={
        <>
          <FormItemWithoutColon name={ustVomAufwandVerarbEntryListingFiltersFormFields.objektId} label="Objekt">
            <VerarbeitungObjektFormSelect
              name={ustVomAufwandVerarbEntryListingFiltersFormFields.objektId}
              onChange={formikProps.submitForm}
              objektList={uniqueObjektList}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={ustVomAufwandVerarbEntryListingFiltersFormFields.buchungStatus} label="Ust.-vom-Aufwand-Status">
            <UstVomAufwandBuchungStatusSelect
              name={ustVomAufwandVerarbEntryListingFiltersFormFields.buchungStatus}
              placeholder="Status auswählen"
              formikProps={formikProps}
              width="200px"
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
