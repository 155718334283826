import React from 'react';
import EntryListingTable from './Table/EntryListingTable';
import LinkageDrawerAndButtons from '../LinkageDrawerAndButtons';
import { useBudgetingAbrDefVPosLinkageTemplateListQuery } from '../gql/budgetingAbrDefVPosLinkageTemplateQueries.types';

const EntryListing = () => {
  const { data, loading, refetch } = useBudgetingAbrDefVPosLinkageTemplateListQuery();

  const entryList = data?.getBudgetingAbrDefVPosLinkageTemplateList.data ?? [];
  return (
    <>
      <LinkageDrawerAndButtons linkageEntryList={entryList} onAction={refetch} />
      <EntryListingTable linkageEntryList={entryList} loading={loading} onAction={refetch} />
    </>
  );
};

export default EntryListing;
