import { Text } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles, { selectableStyles } from '../../../styles/pdfStyles';
import { VorschreibungSelectableSection } from '../../VorschreibungTemplate';

const VTTitleText: FC<{ titleText: string; isInEditMode?: boolean; selectedTextbaustein?: VorschreibungSelectableSection }> = ({
  titleText,
  isInEditMode,
  selectedTextbaustein,
}) => {
  // if in edit mode then we need to render the platzhalter also if it is an empty string so that the user see where the textbaustein will occur in the PDF
  if (isInEditMode) {
    return (
      <Text
        style={[
          pdfStyles.textNormal,
          {
            marginTop: '10mm',
            textAlign: 'center',
            fontWeight: 'bold',
          },
          selectableStyles(isInEditMode, selectedTextbaustein === 'TITELTEXT'),
        ]}
      >
        {titleText}
      </Text>
    );
  }

  return titleText === '' ? null : (
    <Text
      style={[
        pdfStyles.textNormal,
        {
          marginTop: '10mm',
          textAlign: 'center',
          fontWeight: 'bold',
        },
      ]}
    >
      {titleText}
    </Text>
  );
};

export default VTTitleText;
