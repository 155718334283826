import React, { FC } from 'react';
import { Space } from 'antd';
import { paymentListingFiltersFormFields, paymentListingLabelList } from './paymentListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import RechnungsausstellerSelect from '../../../Rechnungsaussteller/RechnungsausstellerSelect';
import PaymentInvoiceIssuerIbanSelect from './PaymentInvoiceIssuerIbanSelect';

type Props = {
  onChange: () => void;
  showBankDetails: boolean;
  onChangeIban: () => void;
  rechnungsausstellerId?: string | null;
};

const PaymentBuchungskreisFilter: FC<Props> = ({ showBankDetails, onChange, onChangeIban, rechnungsausstellerId }) => {
  if (showBankDetails) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <FormItemWithoutColon name={paymentListingFiltersFormFields.rechnungsAusstellerId} label={paymentListingLabelList.rechnungsAusstellerId}>
          <RechnungsausstellerSelect
            name={paymentListingFiltersFormFields.rechnungsAusstellerId}
            onChange={onChange}
            placeholder="Buchungskreis auswählen"
          />
        </FormItemWithoutColon>
        <FormItemWithoutColon name={paymentListingFiltersFormFields.rechnungsAusstellerIban} label={paymentListingLabelList.rechnungsAusstellerIban}>
          <PaymentInvoiceIssuerIbanSelect
            name={paymentListingFiltersFormFields.rechnungsAusstellerIban}
            onChange={onChangeIban}
            rechtstraegerId={rechnungsausstellerId}
          />
        </FormItemWithoutColon>
      </Space>
    );
  }

  return (
    <FormItemWithoutColon name={paymentListingFiltersFormFields.rechnungsAusstellerId} label={paymentListingLabelList.rechnungsAusstellerId}>
      <RechnungsausstellerSelect
        name={paymentListingFiltersFormFields.rechnungsAusstellerId}
        onChange={onChange}
        placeholder="Buchungskreis auswählen"
      />
    </FormItemWithoutColon>
  );
};

export default PaymentBuchungskreisFilter;
