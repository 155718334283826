import React, { FC } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { FinancialPlanObjekt } from './financialPlanObjekt-types';
import pdfStyles from '../../styles/pdfStyles';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import FPVPosList from '../shared/components/FPVPosList';

type Props = {
  data: FinancialPlanObjekt;
  isInEditMode?: boolean;
  selectedTextbaustein?: FinancialPlanSelectableSection;
};

export type FinancialPlanSelectableSection = 'EINLEITUNGSTEXT' | 'AUSGABENTABELLE' | 'SCHLUSSTEXT';

const FinancialPlanObjektTemplate: FC<Props> = ({ data, isInEditMode, selectedTextbaustein }) => (
  <Document>
    <Page size="A4" style={[pdfStyles.body]}>
      <View>
        {/* Header */}
        <Header header={data.header} />
      </View>

      <Text style={[pdfStyles.textNormal, { marginTop: '5mm', textAlign: 'right' }]}>{data.erstellungsDatum}</Text>

      {/* Titeltext */}
      <Text style={[{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }]}>{data.title}</Text>

      {/* Rechnungsausstellerbezeichnung */}
      <Text style={[pdfStyles.textNormal, { textAlign: 'center', fontWeight: 'bold' }]}>{data.rechnungsAusstellerBezeichnung}</Text>

      <FPVPosList
        vorschreibungspositionList={data.vorschreibungspositionList}
        isInEditMode={isInEditMode}
        selectedTextbaustein={selectedTextbaustein}
      />

      {/* Footer */}
      {data.footer?.value && <Footer footer={data.footer} />}
    </Page>
  </Document>
);

export default FinancialPlanObjektTemplate;
