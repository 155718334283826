import React from 'react';
import { Tooltip } from 'antd';
import { TableWithAlignedColsColumnType } from '../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import KontoWithQuery from '../../../../shared/components/Konto/KontoWithQuery';
import { EuroAmount } from '../../../../components/Number';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { UstVomAufwandAufwandKonto } from '../../../../types';

const aufwandsKontoRowColumns = (
  objektId: string,
  vonInklusive: string,
  bisInklusive: string
): TableWithAlignedColsColumnType<UstVomAufwandAufwandKonto>[] => [
  {
    title: '',
    align: 'left',
    render: (konto) => {
      return <KontoWithQuery kontoId={konto.aufwandsKontoId} objektId={objektId} buchungsdatumFrom={vonInklusive} buchungsdatumTo={bisInklusive} />;
    },
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
  {
    title: '',
    colSpan: 3,
    render: (konto) => <EuroAmount value={konto.sumNetto} />,
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },

  {
    title: '',
    colSpan: 2,
    render: (konto) => (
      <Tooltip title={<EuroAmount value={konto.sumUstVomAufwand} minimumFractionDigits={4} maximumFractionDigits={4} />}>
        <>
          <EuroAmount value={konto.sumUstVomAufwand} minimumFractionDigits={2} maximumFractionDigits={2} />
        </>
      </Tooltip>
    ),
  },
];

export default aufwandsKontoRowColumns;
