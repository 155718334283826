import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../types';

const useOnReceivedBankTransactionDataChangedEvents = (key: string, callback: () => Promise<unknown>) => {
  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.receivedBankTransaction,
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Bankbuchungen wurden gerade erstellt',
      key: `${key}-received-bank-transaction-create`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.receivedBankTransaction,
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Bankbuchungen wurden gerade verändert',
      key: `${key}-received-bank-transaction-update`,
      callback,
    },
  ]);
};

export default useOnReceivedBankTransactionDataChangedEvents;
