import { RechtstraegerInfoFeldType } from '../../../types';
import { FormFields } from '../../../helpers/formikHelper';

export type RechtstraegerInfoFeldListingFiltersFormValues = {
  type?: RechtstraegerInfoFeldType;
};

export const rechtstraegerInfoFeldListingFiltersFormFields: FormFields<RechtstraegerInfoFeldListingFiltersFormValues> = {
  type: 'type',
};
