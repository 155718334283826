import * as Yup from 'yup';
import { entityIsRequired } from '../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */
export const bezugsbasisValidationSchema = Yup.string().required(entityIsRequired('Bezugsbasis'));

export const bezugsbasisSelectFormPartValidationSchema = Yup.object().shape({
  bezugsbasis: bezugsbasisValidationSchema,
});

/* eslint-enable no-template-curly-in-string */
