import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ContactPersonFieldsFragmentDoc, ContactFieldsFragmentDoc } from '../../Contact/gql/ContactFragments.types';
import { FirmendatenBaseFieldsFragmentDoc } from '../../Firmendaten/gql/FirmendatenFragments.types';
export type MitarbeiterFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  fileStorageInitialized: boolean;
  firmendatenId: string;
  firstname: string;
  funktionsModulList: Array<string>;
  lastname: string;
  mitarbeiterId: string;
  title?: string | null;
  titleTrailing?: string | null;
  username: string;
  contactPerson?: {
    contactPersonId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firstname: string;
    lastname: string;
    tagList: Array<string>;
    title?: string | null;
    titleTrailing?: string | null;
    contactList: Array<{
      contactId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      tagList: Array<string>;
      type: string;
      wert: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    rechtstraeger?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  rolleList: Array<{
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    name: string;
    rolleId: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
  status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
  user?: {
    email?: string | null;
    emailUnverified?: string | null;
    firstname?: string | null;
    lastname?: string | null;
    role: string;
    username: string;
    status: { text: string; value: string };
  } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type MitarbeiterUserFragment = {
  email?: string | null;
  emailUnverified?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  role: string;
  username: string;
  status: { text: string; value: string };
};

export type MitarbeiterBaseFieldsFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  firstname: string;
  lastname: string;
  mitarbeiterId: string;
  updatedByMitarbeiterId?: string | null;
  firmendatenBaseData: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firmendatenId: string;
    kundenSystemId: string;
    kurzBezeichnung: string;
    name1: string;
    name2?: string | null;
    name3?: string | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const MitarbeiterUserFragmentDoc = gql`
  fragment MitarbeiterUser on MitarbeiterUser {
    email
    emailUnverified
    firstname
    lastname
    role
    status {
      text
      value
    }
    username
  }
`;
export const MitarbeiterFragmentDoc = gql`
  fragment Mitarbeiter on Mitarbeiter {
    contactPerson {
      ...ContactPersonFields
    }
    createTs
    createdBy
    createdByMitarbeiterId
    fileStorageInitialized
    firmendatenId
    firstname
    funktionsModulList
    lastname
    mitarbeiterId
    rolleList {
      createTs
      createdBy
      createdByMitarbeiterId
      name
      rolleId
      warningList {
        attribute
        message
        type
      }
    }
    salutation {
      letterSalutation
      value
      text
    }
    status {
      description
      text
      value
    }
    title
    titleTrailing
    user {
      ...MitarbeiterUser
    }
    username
    warningList {
      attribute
      message
      type
    }
  }
  ${ContactPersonFieldsFragmentDoc}
  ${MitarbeiterUserFragmentDoc}
`;
export const MitarbeiterBaseFieldsFragmentDoc = gql`
  fragment MitarbeiterBaseFields on MitarbeiterBase {
    createTs
    createdBy
    createdByMitarbeiterId
    firstname
    firmendatenBaseData {
      ...FirmendatenBaseFields
    }
    lastname
    mitarbeiterId
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
  ${FirmendatenBaseFieldsFragmentDoc}
`;
