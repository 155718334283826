import styled from 'styled-components';
import { Space } from 'antd';

export const StyledStickyHeaderDivForFilterOrSummary = styled('div')`
  position: sticky;
  z-index: 4;
  &.sticky {
    background-color: white;
  }
`;

export const StyledStickyHeaderDivForNestedTable = styled(Space)`
  width: 100%;
  position: sticky;
  z-index: 4;
  &.sticky {
    background-color: white;
  }
  & > :first-child {
    display: flex;
    justify-content: end;
  }
`;
