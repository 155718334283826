import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { RechnungsAusstellerFieldsFragmentDoc } from './RechnungsAusstellerFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RechnungsAusstellerQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechnungsAusstellerQuery = {
  getRechnungsAussteller: {
    data: {
      buchungskreis: boolean;
      mahndefinitionId?: string | null;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      bankSettings: { bankSettingsId?: string | null; sepaCreditorId?: string | null };
      objektList: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      weAbrechnungsdefinition?: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        firmendatenId?: string | null;
        validFrom: string;
        abrechdefAbrechkreisList: Array<{
          abrechnungskreis: {
            abrechnungskreisId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
            type: { text: string; value: Types.AbrechnungskreisType };
            verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          aufteilungsschluessel?: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          vorschreibungsposition?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            vorschreibungspositionId: string;
            art: { text: string; value: Types.VorschreibungspositionArt };
            kontierungstabelle?: {
              basiskonto: number;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kontierungstabelleId: string;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
              subAdministrationExpenseAccount?: {
                bezeichnung: string;
                firmendatenId?: string | null;
                kontoId: string;
                kontoKlasse: string;
                nummer: string;
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
            umsatzsteuerkennzeichen?: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kuerzel: string;
              umsatzsteuerkennzeichenId: string;
              status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            } | null;
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
        }>;
        kontoAbrechnungskreisDataList: Array<{
          abrechnen: boolean;
          buchungszeilenAnzeigen: boolean;
          expenseReducing: boolean;
          ustVomAufwand: boolean;
          abrechnungskreis?: {
            abrechnungskreisId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
            type: { text: string; value: Types.AbrechnungskreisType };
            verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          aufteilungsschluessel?: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
        }>;
        objektAbrechnungTextbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        status: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus };
        topAbrechnungTextbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      bkAbrechnungsdefinition?: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        firmendatenId?: string | null;
        validFrom: string;
        abrechdefAbrechkreisList: Array<{
          abrechnungskreis: {
            abrechnungskreisId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
            type: { text: string; value: Types.AbrechnungskreisType };
            verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          aufteilungsschluessel?: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          vorschreibungsposition?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            vorschreibungspositionId: string;
            art: { text: string; value: Types.VorschreibungspositionArt };
            kontierungstabelle?: {
              basiskonto: number;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kontierungstabelleId: string;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
              subAdministrationExpenseAccount?: {
                bezeichnung: string;
                firmendatenId?: string | null;
                kontoId: string;
                kontoKlasse: string;
                nummer: string;
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
            umsatzsteuerkennzeichen?: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kuerzel: string;
              umsatzsteuerkennzeichenId: string;
              status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            } | null;
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
        }>;
        kontoAbrechnungskreisDataList: Array<{
          abrechnen: boolean;
          buchungszeilenAnzeigen: boolean;
          expenseReducing: boolean;
          ustVomAufwand: boolean;
          abrechnungskreis?: {
            abrechnungskreisId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
            type: { text: string; value: Types.AbrechnungskreisType };
            verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          aufteilungsschluessel?: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
        }>;
        objektAbrechnungTextbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        status: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus };
        topAbrechnungTextbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      heAbrechnungsdefinition?: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        validFrom: string;
        abrechdefAbrechkreisList: Array<{
          abrechnungskreis: {
            abrechnungskreisId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
            type: { text: string; value: Types.AbrechnungskreisType };
            verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          aufteilungsschluessel?: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          vorschreibungsposition?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            vorschreibungspositionId: string;
            art: { text: string; value: Types.VorschreibungspositionArt };
            kontierungstabelle?: {
              basiskonto: number;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kontierungstabelleId: string;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
              subAdministrationExpenseAccount?: {
                bezeichnung: string;
                firmendatenId?: string | null;
                kontoId: string;
                kontoKlasse: string;
                nummer: string;
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
            umsatzsteuerkennzeichen?: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kuerzel: string;
              umsatzsteuerkennzeichenId: string;
              status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            } | null;
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
        }>;
        heAbrechnungTextbausteinList: Array<{
          index: number;
          value?: string | null;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
            textbausteinList: Array<{
              index: number;
              value?: string | null;
              platzhalterList: Array<{ example: string; platzhalter: string }>;
              type: { text: string; value: Types.TextbausteinType };
            }>;
          }>;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        kontoAbrechnungskreisDataList: Array<{
          abrechnen: boolean;
          buchungszeilenAnzeigen: boolean;
          expenseReducing: boolean;
          ustVomAufwand: boolean;
          abrechnungskreis?: {
            abrechnungskreisId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
            type: { text: string; value: Types.AbrechnungskreisType };
            verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          aufteilungsschluessel?: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
        }>;
        status: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      mahndefinition?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        mahndefinitionId: string;
        mahnfreieTage: number;
        textBausteineBefuellt: boolean;
        verzugszinsen: number;
        mahnstufe1: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          minBetrag: number;
          naechsteMahnungNachTage: number;
          updatedByMitarbeiterId?: string | null;
          versandEinschreiben: boolean;
          verzugszinsenBerechnen: boolean;
          mahngebuehrList: Array<{
            ab: number;
            betrag: number;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            mahngebuehrId: string;
          }>;
        };
        mahnstufe1TextbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        mahnstufe2: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          minBetrag: number;
          naechsteMahnungNachTage: number;
          updatedByMitarbeiterId?: string | null;
          versandEinschreiben: boolean;
          verzugszinsenBerechnen: boolean;
          mahngebuehrList: Array<{
            ab: number;
            betrag: number;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            mahngebuehrId: string;
          }>;
        };
        mahnstufe2TextbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        mahnstufe3: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          minBetrag: number;
          naechsteMahnungNachTage: number;
          updatedByMitarbeiterId?: string | null;
          versandEinschreiben: boolean;
          verzugszinsenBerechnen: boolean;
          mahngebuehrList: Array<{
            ab: number;
            betrag: number;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            mahngebuehrId: string;
          }>;
        };
        mahnstufe3TextbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        mahnstufe4: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          minBetrag: number;
          naechsteMahnungNachTage: number;
          updatedByMitarbeiterId?: string | null;
          versandEinschreiben: boolean;
          verzugszinsenBerechnen: boolean;
          mahngebuehrList: Array<{
            ab: number;
            betrag: number;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            mahngebuehrId: string;
          }>;
        };
        mahnstufe4TextbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        status?: { text: string; value: Types.MahndefinitonStatus } | null;
      } | null;
      subAdministrationAbrechnungsdefinition?: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        validFrom: string;
        abrechdefAbrechkreisList: Array<{
          abrechnungskreis: {
            abrechnungskreisId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
            type: { text: string; value: Types.AbrechnungskreisType };
            verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          aufteilungsschluessel?: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          vorschreibungsposition?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            vorschreibungspositionId: string;
            art: { text: string; value: Types.VorschreibungspositionArt };
            kontierungstabelle?: {
              basiskonto: number;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kontierungstabelleId: string;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
              subAdministrationExpenseAccount?: {
                bezeichnung: string;
                firmendatenId?: string | null;
                kontoId: string;
                kontoKlasse: string;
                nummer: string;
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
            umsatzsteuerkennzeichen?: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kuerzel: string;
              umsatzsteuerkennzeichenId: string;
              status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            } | null;
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
        }>;
        subAdministrationAbrechnungTextbausteinList: Array<{
          index: number;
          value?: string | null;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
            textbausteinList: Array<{
              index: number;
              value?: string | null;
              platzhalterList: Array<{ example: string; platzhalter: string }>;
              type: { text: string; value: Types.TextbausteinType };
            }>;
          }>;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        kontoAbrechnungskreisDataList: Array<{
          abrechnen: boolean;
          buchungszeilenAnzeigen: boolean;
          expenseReducing: boolean;
          ustVomAufwand: boolean;
          abrechnungskreis?: {
            abrechnungskreisId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
            type: { text: string; value: Types.AbrechnungskreisType };
            verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          aufteilungsschluessel?: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
        }>;
        status: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      subAdministrationSettings?: { paymentClearsIncomingInvoice: boolean; subAdministrationSettingsId: string } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RechnungsAusstellerMahndefinitionChangeHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechnungsAusstellerMahndefinitionChangeHistoryListQuery = {
  getRechnungsAusstellerMahndefinitionChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type AbrechnungsdefinitionChangeHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type AbrechnungsdefinitionChangeHistoryListQuery = {
  getAbrechnungsdefinitionChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type RechnungsAusstellerBankSettingsChangeHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechnungsAusstellerBankSettingsChangeHistoryListQuery = {
  getRechnungsAusstellerBankSettingsChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type RepFondsErloesKontenZuweisungDeletionHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RepFondsErloesKontenZuweisungDeletionHistoryListQuery = {
  getRepFondsErloesKontenZuweisungDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type SubAdministrationSettingsChangeHistoryQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type SubAdministrationSettingsChangeHistoryQuery = {
  getSubAdministrationSettingsChangeHistory: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const RechnungsAusstellerDocument = gql`
  query RechnungsAussteller($rechtstraegerId: ID!) {
    getRechnungsAussteller(rechtstraegerId: $rechtstraegerId) {
      data {
        ...RechnungsAusstellerFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechnungsAusstellerFieldsFragmentDoc}
`;
export function useRechnungsAusstellerQuery(
  baseOptions: Apollo.QueryHookOptions<RechnungsAusstellerQuery, RechnungsAusstellerQueryVariables> &
    ({ variables: RechnungsAusstellerQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechnungsAusstellerQuery, RechnungsAusstellerQueryVariables>(RechnungsAusstellerDocument, options);
}
export function useRechnungsAusstellerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechnungsAusstellerQuery, RechnungsAusstellerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechnungsAusstellerQuery, RechnungsAusstellerQueryVariables>(RechnungsAusstellerDocument, options);
}
export function useRechnungsAusstellerSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechnungsAusstellerQuery, RechnungsAusstellerQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechnungsAusstellerQuery, RechnungsAusstellerQueryVariables>(RechnungsAusstellerDocument, options);
}
export type RechnungsAusstellerQueryHookResult = ReturnType<typeof useRechnungsAusstellerQuery>;
export type RechnungsAusstellerLazyQueryHookResult = ReturnType<typeof useRechnungsAusstellerLazyQuery>;
export type RechnungsAusstellerSuspenseQueryHookResult = ReturnType<typeof useRechnungsAusstellerSuspenseQuery>;
export type RechnungsAusstellerQueryResult = Apollo.QueryResult<RechnungsAusstellerQuery, RechnungsAusstellerQueryVariables>;
export const RechnungsAusstellerMahndefinitionChangeHistoryListDocument = gql`
  query RechnungsAusstellerMahndefinitionChangeHistoryList($rechtstraegerId: ID!) {
    getRechnungsAusstellerMahndefinitionChangeHistoryList(rechtstraegerId: $rechtstraegerId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useRechnungsAusstellerMahndefinitionChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    RechnungsAusstellerMahndefinitionChangeHistoryListQuery,
    RechnungsAusstellerMahndefinitionChangeHistoryListQueryVariables
  > &
    ({ variables: RechnungsAusstellerMahndefinitionChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechnungsAusstellerMahndefinitionChangeHistoryListQuery, RechnungsAusstellerMahndefinitionChangeHistoryListQueryVariables>(
    RechnungsAusstellerMahndefinitionChangeHistoryListDocument,
    options
  );
}
export function useRechnungsAusstellerMahndefinitionChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RechnungsAusstellerMahndefinitionChangeHistoryListQuery,
    RechnungsAusstellerMahndefinitionChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RechnungsAusstellerMahndefinitionChangeHistoryListQuery,
    RechnungsAusstellerMahndefinitionChangeHistoryListQueryVariables
  >(RechnungsAusstellerMahndefinitionChangeHistoryListDocument, options);
}
export function useRechnungsAusstellerMahndefinitionChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RechnungsAusstellerMahndefinitionChangeHistoryListQuery,
        RechnungsAusstellerMahndefinitionChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RechnungsAusstellerMahndefinitionChangeHistoryListQuery,
    RechnungsAusstellerMahndefinitionChangeHistoryListQueryVariables
  >(RechnungsAusstellerMahndefinitionChangeHistoryListDocument, options);
}
export type RechnungsAusstellerMahndefinitionChangeHistoryListQueryHookResult = ReturnType<
  typeof useRechnungsAusstellerMahndefinitionChangeHistoryListQuery
>;
export type RechnungsAusstellerMahndefinitionChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useRechnungsAusstellerMahndefinitionChangeHistoryListLazyQuery
>;
export type RechnungsAusstellerMahndefinitionChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useRechnungsAusstellerMahndefinitionChangeHistoryListSuspenseQuery
>;
export type RechnungsAusstellerMahndefinitionChangeHistoryListQueryResult = Apollo.QueryResult<
  RechnungsAusstellerMahndefinitionChangeHistoryListQuery,
  RechnungsAusstellerMahndefinitionChangeHistoryListQueryVariables
>;
export const AbrechnungsdefinitionChangeHistoryListDocument = gql`
  query AbrechnungsdefinitionChangeHistoryList($rechtstraegerId: ID!) {
    getAbrechnungsdefinitionChangeHistoryList(rechtstraegerId: $rechtstraegerId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungsdefinitionChangeHistoryListQuery, AbrechnungsdefinitionChangeHistoryListQueryVariables> &
    ({ variables: AbrechnungsdefinitionChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungsdefinitionChangeHistoryListQuery, AbrechnungsdefinitionChangeHistoryListQueryVariables>(
    AbrechnungsdefinitionChangeHistoryListDocument,
    options
  );
}
export function useAbrechnungsdefinitionChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungsdefinitionChangeHistoryListQuery, AbrechnungsdefinitionChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungsdefinitionChangeHistoryListQuery, AbrechnungsdefinitionChangeHistoryListQueryVariables>(
    AbrechnungsdefinitionChangeHistoryListDocument,
    options
  );
}
export function useAbrechnungsdefinitionChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AbrechnungsdefinitionChangeHistoryListQuery, AbrechnungsdefinitionChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungsdefinitionChangeHistoryListQuery, AbrechnungsdefinitionChangeHistoryListQueryVariables>(
    AbrechnungsdefinitionChangeHistoryListDocument,
    options
  );
}
export type AbrechnungsdefinitionChangeHistoryListQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionChangeHistoryListQuery>;
export type AbrechnungsdefinitionChangeHistoryListLazyQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionChangeHistoryListLazyQuery>;
export type AbrechnungsdefinitionChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionChangeHistoryListSuspenseQuery>;
export type AbrechnungsdefinitionChangeHistoryListQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionChangeHistoryListQuery,
  AbrechnungsdefinitionChangeHistoryListQueryVariables
>;
export const RechnungsAusstellerBankSettingsChangeHistoryListDocument = gql`
  query RechnungsAusstellerBankSettingsChangeHistoryList($rechtstraegerId: ID!) {
    getRechnungsAusstellerBankSettingsChangeHistoryList(rechtstraegerId: $rechtstraegerId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useRechnungsAusstellerBankSettingsChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    RechnungsAusstellerBankSettingsChangeHistoryListQuery,
    RechnungsAusstellerBankSettingsChangeHistoryListQueryVariables
  > &
    ({ variables: RechnungsAusstellerBankSettingsChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechnungsAusstellerBankSettingsChangeHistoryListQuery, RechnungsAusstellerBankSettingsChangeHistoryListQueryVariables>(
    RechnungsAusstellerBankSettingsChangeHistoryListDocument,
    options
  );
}
export function useRechnungsAusstellerBankSettingsChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RechnungsAusstellerBankSettingsChangeHistoryListQuery,
    RechnungsAusstellerBankSettingsChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechnungsAusstellerBankSettingsChangeHistoryListQuery, RechnungsAusstellerBankSettingsChangeHistoryListQueryVariables>(
    RechnungsAusstellerBankSettingsChangeHistoryListDocument,
    options
  );
}
export function useRechnungsAusstellerBankSettingsChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RechnungsAusstellerBankSettingsChangeHistoryListQuery,
        RechnungsAusstellerBankSettingsChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RechnungsAusstellerBankSettingsChangeHistoryListQuery,
    RechnungsAusstellerBankSettingsChangeHistoryListQueryVariables
  >(RechnungsAusstellerBankSettingsChangeHistoryListDocument, options);
}
export type RechnungsAusstellerBankSettingsChangeHistoryListQueryHookResult = ReturnType<
  typeof useRechnungsAusstellerBankSettingsChangeHistoryListQuery
>;
export type RechnungsAusstellerBankSettingsChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useRechnungsAusstellerBankSettingsChangeHistoryListLazyQuery
>;
export type RechnungsAusstellerBankSettingsChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useRechnungsAusstellerBankSettingsChangeHistoryListSuspenseQuery
>;
export type RechnungsAusstellerBankSettingsChangeHistoryListQueryResult = Apollo.QueryResult<
  RechnungsAusstellerBankSettingsChangeHistoryListQuery,
  RechnungsAusstellerBankSettingsChangeHistoryListQueryVariables
>;
export const RepFondsErloesKontenZuweisungDeletionHistoryListDocument = gql`
  query RepFondsErloesKontenZuweisungDeletionHistoryList($rechtstraegerId: ID!) {
    getRepFondsErloesKontenZuweisungDeletionHistoryList(rechtstraegerId: $rechtstraegerId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useRepFondsErloesKontenZuweisungDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    RepFondsErloesKontenZuweisungDeletionHistoryListQuery,
    RepFondsErloesKontenZuweisungDeletionHistoryListQueryVariables
  > &
    ({ variables: RepFondsErloesKontenZuweisungDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RepFondsErloesKontenZuweisungDeletionHistoryListQuery, RepFondsErloesKontenZuweisungDeletionHistoryListQueryVariables>(
    RepFondsErloesKontenZuweisungDeletionHistoryListDocument,
    options
  );
}
export function useRepFondsErloesKontenZuweisungDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RepFondsErloesKontenZuweisungDeletionHistoryListQuery,
    RepFondsErloesKontenZuweisungDeletionHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RepFondsErloesKontenZuweisungDeletionHistoryListQuery, RepFondsErloesKontenZuweisungDeletionHistoryListQueryVariables>(
    RepFondsErloesKontenZuweisungDeletionHistoryListDocument,
    options
  );
}
export function useRepFondsErloesKontenZuweisungDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RepFondsErloesKontenZuweisungDeletionHistoryListQuery,
        RepFondsErloesKontenZuweisungDeletionHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RepFondsErloesKontenZuweisungDeletionHistoryListQuery,
    RepFondsErloesKontenZuweisungDeletionHistoryListQueryVariables
  >(RepFondsErloesKontenZuweisungDeletionHistoryListDocument, options);
}
export type RepFondsErloesKontenZuweisungDeletionHistoryListQueryHookResult = ReturnType<
  typeof useRepFondsErloesKontenZuweisungDeletionHistoryListQuery
>;
export type RepFondsErloesKontenZuweisungDeletionHistoryListLazyQueryHookResult = ReturnType<
  typeof useRepFondsErloesKontenZuweisungDeletionHistoryListLazyQuery
>;
export type RepFondsErloesKontenZuweisungDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useRepFondsErloesKontenZuweisungDeletionHistoryListSuspenseQuery
>;
export type RepFondsErloesKontenZuweisungDeletionHistoryListQueryResult = Apollo.QueryResult<
  RepFondsErloesKontenZuweisungDeletionHistoryListQuery,
  RepFondsErloesKontenZuweisungDeletionHistoryListQueryVariables
>;
export const SubAdministrationSettingsChangeHistoryDocument = gql`
  query SubAdministrationSettingsChangeHistory($rechtstraegerId: ID!) {
    getSubAdministrationSettingsChangeHistory(rechtstraegerId: $rechtstraegerId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useSubAdministrationSettingsChangeHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<SubAdministrationSettingsChangeHistoryQuery, SubAdministrationSettingsChangeHistoryQueryVariables> &
    ({ variables: SubAdministrationSettingsChangeHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubAdministrationSettingsChangeHistoryQuery, SubAdministrationSettingsChangeHistoryQueryVariables>(
    SubAdministrationSettingsChangeHistoryDocument,
    options
  );
}
export function useSubAdministrationSettingsChangeHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubAdministrationSettingsChangeHistoryQuery, SubAdministrationSettingsChangeHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubAdministrationSettingsChangeHistoryQuery, SubAdministrationSettingsChangeHistoryQueryVariables>(
    SubAdministrationSettingsChangeHistoryDocument,
    options
  );
}
export function useSubAdministrationSettingsChangeHistorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SubAdministrationSettingsChangeHistoryQuery, SubAdministrationSettingsChangeHistoryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubAdministrationSettingsChangeHistoryQuery, SubAdministrationSettingsChangeHistoryQueryVariables>(
    SubAdministrationSettingsChangeHistoryDocument,
    options
  );
}
export type SubAdministrationSettingsChangeHistoryQueryHookResult = ReturnType<typeof useSubAdministrationSettingsChangeHistoryQuery>;
export type SubAdministrationSettingsChangeHistoryLazyQueryHookResult = ReturnType<typeof useSubAdministrationSettingsChangeHistoryLazyQuery>;
export type SubAdministrationSettingsChangeHistorySuspenseQueryHookResult = ReturnType<typeof useSubAdministrationSettingsChangeHistorySuspenseQuery>;
export type SubAdministrationSettingsChangeHistoryQueryResult = Apollo.QueryResult<
  SubAdministrationSettingsChangeHistoryQuery,
  SubAdministrationSettingsChangeHistoryQueryVariables
>;
