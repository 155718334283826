import React, { FC } from 'react';
import { Empty } from 'antd';
import { Budgeting, BudgetingAbrKreis } from '../../../../../types';
import budgetingAbrKreisTableColumns from './budgetingAbrKreisTableColumns';
import BudgetingKontoTable from '../Level3/BudgetingKontoTable';
import { IStatus } from '../../../../../helpers/statusHelper';
import NestedTableWithColSelector from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

type Props = {
  budgeting: Budgeting;
  abrechnungKreisList: BudgetingAbrKreis[];
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => Promise<unknown>;
  isUpdating: boolean;
  updatingRowKey: string | null;
  setUpdatingRowKey: React.Dispatch<React.SetStateAction<string | null>>;
  budgetingVPosStatus: IStatus;
  parentRowKeys?: string[];
  visible?: boolean;
};

const BudgetingAbrKreisTable: FC<Props> = ({
  budgeting,
  abrechnungKreisList,
  isLoading,
  setIsLoading,
  refetch,
  isUpdating,
  updatingRowKey,
  setUpdatingRowKey,
  budgetingVPosStatus,
  parentRowKeys,
  visible = true,
}) => {
  const getRowKey = (record: BudgetingAbrKreis) => record.abrechnungskreis.abrechnungskreisId;

  return (
    <NestedTableWithColSelector<BudgetingAbrKreis>
      parentRowKeys={parentRowKeys}
      visible={visible}
      level={2}
      locale={{
        emptyText: <Empty description={<span>Keine Budgetierung vorhanden</span>} />,
      }}
      dataSource={abrechnungKreisList}
      columns={budgetingAbrKreisTableColumns(budgeting, budgetingVPosStatus)}
      rowKey={getRowKey}
      expandable={{
        rowExpandable: (record) => !!record.budgetingKontoList?.length,
        expandedRowRender: (record, index, indent, expanded) => {
          return (
            <BudgetingKontoTable
              parentRowKeys={[...(parentRowKeys ?? []), getRowKey(record)]}
              visible={visible && expanded}
              budgeting={budgeting}
              kontoList={record.budgetingKontoList ?? []}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              refetch={refetch}
              isUpdating={isUpdating}
              updatingRowKey={updatingRowKey}
              setUpdatingRowKey={setUpdatingRowKey}
              budgetingVPosStatus={budgetingVPosStatus}
            />
          );
        },
      }}
    />
  );
};
export default BudgetingAbrKreisTable;
