import React, { FC } from 'react';
import { Empty } from 'antd';
import objAbrVerarbeitungEntryTableColumns from './objAbrVerarbeitungEntryTableColumns';
import { isVerarbeitungEntryTableRowExpandable } from '../../verarbeitungHelpers';
import GenerierlaufStatusExpandedRow from '../../shared/GenerierlaufStatusExpandedRow';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { ObjektAbrechnungGenerierlauf, ObjektAbrechnungGenerierlaufEntry } from '../../../../types';
import { TObjAbrVerarbeitungQueryParams } from './Filters/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { useObjektAbrechnungGenerierlaufEntryListQuery } from '../../gql/ObjektAbrechnungGenerierlauf/ObjAbrGenerierlaufQueries.types';

type Props = {
  generierlauf: ObjektAbrechnungGenerierlauf;
  queryParams: TObjAbrVerarbeitungQueryParams;
  type: string;
};

const ObjAbrVerarbeitungEntryTable: FC<Props> = ({ generierlauf, queryParams, type }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useObjektAbrechnungGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId: generierlauf.generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getObjektAbrechnungGenerierlaufEntryList.data.contentList || [];

  return (
    <>
      <TableWithColSelector<ObjektAbrechnungGenerierlaufEntry>
        loading={loading}
        dataSource={entryList}
        pagination={pagination}
        onChange={handleTableSorting}
        columns={objAbrVerarbeitungEntryTableColumns(generierlauf.fromInclusive, generierlauf.toInclusive)}
        locale={{
          emptyText: <Empty description={<span>Keine Objektabrechnungen vorhanden</span>} />,
        }}
        size="small"
        expandable={{
          expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
          rowExpandable: isVerarbeitungEntryTableRowExpandable,
        }}
        filterIdentifier={`verarbeitung-${type}`}
        rowKey={(record) => record.generierlaufEntryId}
      />
    </>
  );
};

export default ObjAbrVerarbeitungEntryTable;
