import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { LoadingDetailsPage } from '../../../../components/Loading';
import AdminCard from '../../../../features/Admin/AdminCard';
import AdminChangeLogListing from '../../../../features/Admin/ChangeLogListing/AdminChangeLogListing';
import AdminAuditLogListing from '../../../../features/Admin/AuditLogListing/AdminAuditLogListing';
import { useUserQuery } from '../../../../graphql/Authentication/UserQueries.types';
import { Spacer } from '../../../../components/Grid';
import { AdminDetailsPageRouteParams } from './routes';

const VerwaltungAdminDetailsPage = () => {
  const { username } = useParams() as AdminDetailsPageRouteParams;

  const { loading, data: maybeUserData, refetch } = useUserQuery({ variables: { username } });
  const admin = maybeUserData?.getUser.data;

  if (!admin) return <LoadingDetailsPage loading={loading} />;

  return (
    <Row gutter={16}>
      <Col span={8}>
        <AdminCard admin={admin} onAction={refetch} />
      </Col>
      <Col span={16}>
        <AdminChangeLogListing />
        <Spacer height={32} />
        <AdminAuditLogListing />
      </Col>
    </Row>
  );
};

export default VerwaltungAdminDetailsPage;
