import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useVertragPaymentMethodListQuery } from './gql/BeVertragQueries.types';

type Props = SelectProps;

const VertragPaymentMethodSelect: FC<Props> = (props) => {
  const { loading, data } = useVertragPaymentMethodListQuery();

  const paymentMethodList = data?.getVertragPaymentMethodList.data ?? [];

  return (
    <Select id="Zahlungsart" placeholder="Zahlungsart auswählen" loading={loading} {...props}>
      {paymentMethodList.map((paymentMethod) => (
        <Select.Option key={paymentMethod.value} value={paymentMethod.value}>
          {paymentMethod.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default VertragPaymentMethodSelect;
