import React, { FC } from 'react';
import { Divider, Space, Typography } from 'antd';

import DeviationChangeTypeTag from '../../../DeviationChangeTypeTag';
import { DeviationColumnTitleStyledText } from '../../../Styled/Deviation.style';
import DataWithShortenedTextAndExtraInfo from '../../../../Helpers/DataWithShortenedTextAndExtraInfo';
import { DeviationChangeTypeTuple, ObjektAbrechnungDeviationSourceEntity } from '../../../../../types';
import { isAbrechnungTypeBkAbrechnung } from '../../../../../features/Abrechnung/shared/abrechnungHelpers';
import CustomFormattedDate from '../../../../FormattedDate/CustomFormattedDate';

type ObjektAbrechnungSourceEntityHeaderProps = {
  changeType: DeviationChangeTypeTuple;
  sourceEntity: ObjektAbrechnungDeviationSourceEntity;
};

const ObjektAbrechnungSourceEntityHeader: FC<ObjektAbrechnungSourceEntityHeaderProps> = ({ changeType, sourceEntity }) => {
  const sourceEntityAbrTypeText = isAbrechnungTypeBkAbrechnung(sourceEntity.abrechnungType.value) ? 'BK' : 'WE';

  return (
    <Space align="baseline" size={8} split={<Divider type="vertical" />}>
      <Space size={4} align="baseline">
        <DeviationChangeTypeTag changeType={changeType} />
        <DeviationColumnTitleStyledText type="secondary">
          {sourceEntityAbrTypeText} - {sourceEntity.type.text}
        </DeviationColumnTitleStyledText>
      </Space>
      <Space size="large">
        <Space size={4} style={{ width: '200px' }}>
          <DataWithShortenedTextAndExtraInfo maxTextLength={30} text={`${sourceEntity.objektBezeichnung} / ${sourceEntity.abrechnungBezeichnung}`} />
        </Space>
        <Space size={8}>
          <Space size={4}>
            <Typography.Text type="secondary">von</Typography.Text>
            <CustomFormattedDate value={sourceEntity.fromInclusive} />
          </Space>
          <Space size={4}>
            <Typography.Text type="secondary">bis</Typography.Text>
            <CustomFormattedDate value={sourceEntity.toInclusive} />
          </Space>
        </Space>
      </Space>
    </Space>
  );
};

export default ObjektAbrechnungSourceEntityHeader;
