import { Zinsliste } from '../templates/zinsliste/zinsliste-types';

export const mockZinslisteData: Zinsliste = {
  zeitraumText: 'Zinsliste 1/2025',
  objektBezeichnung: 'Grillgasse 46',
  vorschreibungspositionHeaderText: { anteilText: 'Anteil', nettoText: 'Netto', ustSatzText: '%', ustBetragText: 'Ust.', bruttoText: 'Brutto' },
  vorschreibungspositionSummaryHeaderText: { vertragsartText: 'Vertragsart', nettoSumText: 'Netto Summe', bruttoSumText: 'Brutto Summe' },
  vorschreibungspositionSummary: [
    {
      vertragsart: 'Mieter',
      sumNetto: '5.837,69',
      sumBrutto: '6.309,91',
      vorschreibungspositionList: [
        {
          bezeichnung: 'Pauschalmietzins',
          kurzBezeichnung: 'Miet-P',
          sumNetto: '0,00',
          sumBrutto: '0,00',
          sumUst: '0,00',
          vorschreibungspositionWertList: [{ steuersatz: '0', netto: '0,00', ust: '0,00', brutto: '0,00' }],
        },
        {
          bezeichnung: 'Hauptmietzins',
          kurzBezeichnung: 'Miet',
          sumNetto: '4.420,71',
          sumBrutto: '4.765,08',
          sumUst: '344,37',
          vorschreibungspositionWertList: [
            { steuersatz: '0', netto: '977,11', ust: '0,00', brutto: '977,11' },
            { steuersatz: '10', netto: '3.443,60', ust: '344,37', brutto: '3.787,97' },
          ],
        },
        {
          bezeichnung: 'Betriebskosten',
          kurzBezeichnung: 'BK',
          sumNetto: '1.416,98',
          sumBrutto: '1.544,83',
          sumUst: '127,85',
          vorschreibungspositionWertList: [
            { steuersatz: '0', netto: '138,51', ust: '0,00', brutto: '138,51' },
            { steuersatz: '10', netto: '1.278,47', ust: '127,85', brutto: '1.406,32' },
          ],
        },
      ],
    },
    {
      vertragsart: 'Leerstehung',
      sumNetto: '455,37',
      sumBrutto: '455,37',
      vorschreibungspositionList: [
        {
          bezeichnung: 'Pauschalmietzins',
          kurzBezeichnung: 'Miet-P',
          sumNetto: '0,00',
          sumBrutto: '0,00',
          sumUst: '0,00',
          vorschreibungspositionWertList: [{ steuersatz: '0', netto: '0,00', ust: '0,00', brutto: '0,00' }],
        },
        {
          bezeichnung: 'Hauptmietzins',
          kurzBezeichnung: 'Miet',
          sumNetto: '0,00',
          sumBrutto: '0,00',
          sumUst: '0,00',
          vorschreibungspositionWertList: [{ steuersatz: '0', netto: '0,00', ust: '0,00', brutto: '0,00' }],
        },
        {
          bezeichnung: 'Betriebskosten',
          kurzBezeichnung: 'BK',
          sumNetto: '455,37',
          sumBrutto: '455,37',
          sumUst: '0,00',
          vorschreibungspositionWertList: [{ steuersatz: '0', netto: '455,37', ust: '0,00', brutto: '455,37' }],
        },
      ],
    },
    {
      vertragsart: 'Pauschalmieter',
      sumNetto: '582,33',
      sumBrutto: '640,56',
      vorschreibungspositionList: [
        {
          bezeichnung: 'Pauschalmietzins',
          kurzBezeichnung: 'Miet-P',
          sumNetto: '582,33',
          sumBrutto: '640,56',
          sumUst: '58,23',
          vorschreibungspositionWertList: [{ steuersatz: '10', netto: '582,33', ust: '58,23', brutto: '640,56' }],
        },
        {
          bezeichnung: 'Hauptmietzins',
          kurzBezeichnung: 'Miet',
          sumNetto: '0,00',
          sumBrutto: '0,00',
          sumUst: '0,00',
          vorschreibungspositionWertList: [{ steuersatz: '0', netto: '0,00', ust: '0,00', brutto: '0,00' }],
        },
        {
          bezeichnung: 'Betriebskosten',
          kurzBezeichnung: 'BK',
          sumNetto: '0,00',
          sumBrutto: '0,00',
          sumUst: '0,00',
          vorschreibungspositionWertList: [{ steuersatz: '0', netto: '0,00', ust: '0,00', brutto: '0,00' }],
        },
      ],
    },
    {
      vertragsart: 'Eigennutzung',
      sumNetto: '267,46',
      sumBrutto: '267,46',
      vorschreibungspositionList: [
        {
          bezeichnung: 'Pauschalmietzins',
          kurzBezeichnung: 'Miet-P',
          sumNetto: '0,00',
          sumBrutto: '0,00',
          sumUst: '0,00',
          vorschreibungspositionWertList: [{ steuersatz: '0', netto: '0,00', ust: '0,00', brutto: '0,00' }],
        },
        {
          bezeichnung: 'Hauptmietzins',
          kurzBezeichnung: 'Miet',
          sumNetto: '0,00',
          sumBrutto: '0,00',
          sumUst: '0,00',
          vorschreibungspositionWertList: [{ steuersatz: '0', netto: '0,00', ust: '0,00', brutto: '0,00' }],
        },
        {
          bezeichnung: 'Betriebskosten',
          kurzBezeichnung: 'BK',
          sumNetto: '267,46',
          sumBrutto: '267,46',
          sumUst: '0,00',
          vorschreibungspositionWertList: [{ steuersatz: '0', netto: '267,46', ust: '0,00', brutto: '267,46' }],
        },
      ],
    },
  ],
  bestandseinheitVertragList: [
    {
      bestandseinheitBezeichnung: 'TOP8',
      vertragsart: 'Eigennutzung',
      termLimitation: 'unbefristet',
      nutzungsArt: 'Wohnen',
      nutzflaecheText: 'Nfl.',
      nutzwertText: 'Nw.',
      nutzflaeche: '96,55',
      nutzwert: null,
      vertragspartnerBezeichnung: 'Brunner, Wolfgang',
      vorschreibungspositionSummeList: { netto: '267,46', ustBetrag: '0,00', brutto: '267,46' },
      vorschreibungspositionList: [
        { bezeichnung: 'Betriebskosten', kurzbezeichnung: 'BK', netto: '267,46', ustSatz: '0', ustBetrag: '0,00', brutto: '267,46' },
      ],
    },
    {
      bestandseinheitBezeichnung: 'TOP1',
      vertragsart: 'Mieter',
      termLimitation: 'unbefristet',
      nutzungsArt: 'Nicht Wohnen',
      nutzflaecheText: 'Nfl.',
      nutzflaeche: '50,00',
      nutzwert: null,
      vertragspartnerBezeichnung: 'Checker, Thomas',
      vorschreibungspositionSummeList: { netto: '1.115,62', ustBetrag: '0,00', brutto: '1.115,62' },
      vorschreibungspositionList: [
        { bezeichnung: 'Betriebskosten', kurzbezeichnung: 'BK', netto: '138,51', ustSatz: '0', ustBetrag: '0,00', brutto: '138,51' },
        { bezeichnung: 'Hauptmietzins', kurzbezeichnung: 'Miet', netto: '977,11', ustSatz: '0', ustBetrag: '0,00', brutto: '977,11' },
      ],
    },
    {
      bestandseinheitBezeichnung: 'TOP11',
      vertragsart: 'Mieter',
      termLimitation: 'unbefristet',
      nutzungsArt: 'Wohnen',
      nutzflaecheText: 'Nfl.',
      nutzflaeche: '135,00',
      vertragspartnerBezeichnung: 'Checker, Thomas',
      vorschreibungspositionSummeList: { netto: '1.124,54', ustBetrag: '112,46', brutto: '1.237,00' },
      vorschreibungspositionList: [
        { bezeichnung: 'Betriebskosten', kurzbezeichnung: 'BK', netto: '373,97', ustSatz: '10', ustBetrag: '37,40', brutto: '411,37' },
        { bezeichnung: 'Hauptmietzins', kurzbezeichnung: 'Miet', netto: '750,57', ustSatz: '10', ustBetrag: '75,06', brutto: '825,63' },
      ],
    },
    {
      bestandseinheitBezeichnung: 'TOP7',
      vertragsart: 'Pauschalmieter',
      termLimitation: 'unbefristet',
      nutzungsArt: 'Wohnen',
      nutzflaecheText: 'Nfl.',
      nutzflaeche: '67,95',
      vertragspartnerBezeichnung: 'Feldbauer, Patrik ',
      vorschreibungspositionSummeList: { netto: '582,33', ustBetrag: '58,23', brutto: '640,56' },
      vorschreibungspositionList: [
        { bezeichnung: 'Pauschalmietzins', kurzbezeichnung: 'Miet-P', netto: '582,33', ustSatz: '10', ustBetrag: '58,23', brutto: '640,56' },
      ],
    },
    {
      bestandseinheitBezeichnung: 'TOP10',
      vertragsart: 'Mieter',
      termLimitation: 'unbefristet',
      nutzungsArt: 'Wohnen',
      nutzflaecheText: 'Nfl.',
      nutzflaeche: '87,30',
      vertragspartnerBezeichnung: 'Fink, Michael',
      vorschreibungspositionSummeList: { netto: '1.350,70', ustBetrag: '135,07', brutto: '1.485,77' },
      vorschreibungspositionList: [
        { bezeichnung: 'Betriebskosten', kurzbezeichnung: 'BK', netto: '241,84', ustSatz: '10', ustBetrag: '24,18', brutto: '266,02' },
        { bezeichnung: 'Hauptmietzins', kurzbezeichnung: 'Miet', netto: '1.108,86', ustSatz: '10', ustBetrag: '110,89', brutto: '1.219,75' },
      ],
    },
    {
      bestandseinheitBezeichnung: 'TOP4',
      vertragsart: 'Mieter',
      termLimitation: 'unbefristet',
      nutzungsArt: 'Wohnen',
      nutzflaecheText: 'Nfl.',
      nutzflaeche: '73,00',
      vertragspartnerBezeichnung: 'Langer, Assad',
      vorschreibungspositionSummeList: { netto: '780,85', ustBetrag: '78,08', brutto: '858,93' },
      vorschreibungspositionList: [
        { bezeichnung: 'Betriebskosten', kurzbezeichnung: 'BK', netto: '202,22', ustSatz: '10', ustBetrag: '20,22', brutto: '222,44' },
        { bezeichnung: 'Hauptmietzins', kurzbezeichnung: 'Miet', netto: '578,63', ustSatz: '10', ustBetrag: '57,86', brutto: '636,49' },
      ],
    },
    {
      bestandseinheitBezeichnung: 'TOP3',
      vertragsart: 'Mieter',
      termLimitation: 'unbefristet',
      nutzungsArt: 'Wohnen',
      nutzflaecheText: 'Nfl.',
      nutzflaeche: '43,40',
      vertragspartnerBezeichnung: 'Ringl, Michaela u Dominik',
      vorschreibungspositionSummeList: { netto: '313,45', ustBetrag: '31,34', brutto: '344,79' },
      vorschreibungspositionList: [
        { bezeichnung: 'Betriebskosten', kurzbezeichnung: 'BK', netto: '120,23', ustSatz: '10', ustBetrag: '12,02', brutto: '132,25' },
        { bezeichnung: 'Hauptmietzins', kurzbezeichnung: 'Miet', netto: '193,22', ustSatz: '10', ustBetrag: '19,32', brutto: '212,54' },
      ],
    },
    {
      bestandseinheitBezeichnung: 'TOP5',
      vertragsart: 'Mieter',
      termLimitation: 'unbefristet',
      nutzungsArt: 'Wohnen',
      nutzflaecheText: 'Nfl.',
      nutzflaeche: '45,00',
      vertragspartnerBezeichnung: 'Santner, Andreas',
      vorschreibungspositionSummeList: { netto: '523,91', ustBetrag: '52,40', brutto: '576,31' },
      vorschreibungspositionList: [
        { bezeichnung: 'Betriebskosten', kurzbezeichnung: 'BK', netto: '124,66', ustSatz: '10', ustBetrag: '12,47', brutto: '137,13' },
        { bezeichnung: 'Hauptmietzins', kurzbezeichnung: 'Miet', netto: '399,25', ustSatz: '10', ustBetrag: '39,93', brutto: '439,18' },
      ],
    },
    {
      bestandseinheitBezeichnung: 'TOP6',
      vertragsart: 'Mieter',
      termLimitation: 'unbefristet',
      nutzungsArt: 'Wohnen',
      nutzflaecheText: 'Nfl.',
      nutzflaeche: '77,81',
      vertragspartnerBezeichnung: 'Wagner, Alexander',
      vorschreibungspositionSummeList: { netto: '628,62', ustBetrag: '62,87', brutto: '691,49' },
      vorschreibungspositionList: [
        { bezeichnung: 'Betriebskosten', kurzbezeichnung: 'BK', netto: '215,55', ustSatz: '10', ustBetrag: '21,56', brutto: '237,11' },
        { bezeichnung: 'Hauptmietzins', kurzbezeichnung: 'Miet', netto: '413,07', ustSatz: '10', ustBetrag: '41,31', brutto: '454,38' },
      ],
    },
    {
      bestandseinheitBezeichnung: 'TOP2',
      vertragsart: 'Leerstehung',
      termLimitation: 'unbefristet',
      nutzungsArt: 'Nicht Wohnen',
      nutzflaecheText: 'Nfl.',
      nutzflaeche: '70,66',
      vertragspartnerBezeichnung: 'Hausinhabung Grillg. 46',
      vorschreibungspositionSummeList: { netto: '173,53', ustBetrag: '0,00', brutto: '173,53' },
      vorschreibungspositionList: [
        { bezeichnung: 'Betriebskosten', kurzbezeichnung: 'BK', netto: '173,53', ustSatz: '0', ustBetrag: '0,00', brutto: '173,53' },
      ],
    },
    {
      bestandseinheitBezeichnung: 'TOP9',
      vertragsart: 'Leerstehung',
      termLimitation: 'unbefristet',
      nutzungsArt: 'Wohnen',
      nutzflaecheText: 'Nfl.',
      nutzflaeche: '101,65',
      vertragspartnerBezeichnung: 'Hausinhabung Grillg. 46',
      vorschreibungspositionSummeList: { netto: '281,84', ustBetrag: '0,00', brutto: '281,84' },
      vorschreibungspositionList: [
        { bezeichnung: 'Betriebskosten', kurzbezeichnung: 'BK', netto: '281,84', ustSatz: '0', ustBetrag: '0,00', brutto: '281,84' },
      ],
    },
  ],
};
