import React, { FC } from 'react';
import { FieldArray, FormikProps } from 'formik';
import { Col, Row, Typography } from 'antd';
import { indexSeriesEntryFormFields, IndexSeriesEntryFormValues } from './indexSeriesEntryFormMapper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import FormattedDecimalFormInput from '../../../../components/Input-antd/FormattedDecimalFormInput';
import { isIndexSeriesIndexTypeIndex } from '../../../IndexSeries/indexSeriesHelpers';
import { IndexType } from '../../../../types';

type Props = {
  formikProps: FormikProps<IndexSeriesEntryFormValues>;
  indexSeriesType: IndexType;
};

const IndexPointListFieldArray: FC<Props> = ({ formikProps, indexSeriesType }) => {
  return (
    <FieldArray
      name={indexSeriesEntryFormFields.indexPointList}
      render={() => (
        <>
          {formikProps.values.indexPointList.map((value, index) => {
            const finalValueName = `${indexSeriesEntryFormFields.indexPointList}[${index}].finalValue`;
            const preliminaryValueName = `${indexSeriesEntryFormFields.indexPointList}[${index}].preliminaryValue`;
            return (
              <Row style={{ width: '100%', alignItems: 'baseline' }} key={index}>
                <Col span={6}>
                  <Typography.Text>{value.month}</Typography.Text>
                </Col>
                <Col span={6}>
                  <FormItemWithoutColon name={finalValueName}>
                    <FormattedDecimalFormInput name={finalValueName} min={0} fractionDigits={8} style={{ width: '100%' }} />
                  </FormItemWithoutColon>
                </Col>
                {isIndexSeriesIndexTypeIndex(indexSeriesType) && (
                  <Col span={6} style={{ marginLeft: '10px' }}>
                    <FormItemWithoutColon name={preliminaryValueName}>
                      <FormattedDecimalFormInput name={preliminaryValueName} min={0} fractionDigits={8} style={{ width: '100%' }} />
                    </FormItemWithoutColon>
                  </Col>
                )}
              </Row>
            );
          })}
        </>
      )}
    />
  );
};

export default IndexPointListFieldArray;
