import { Route } from 'react-router';
import { URI_VERARBEITUNG_SUB_ABRECHNUNG_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import SubAbrechnungDetailsPage from './SubAbrechnungDetailsPage';

export type VerarbeitungSubAbrechnungDetailsPageRouteParams = { generierlaufId: string };

export const verarbeitungSubAbrechnungDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_SUB_ABRECHNUNG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<SubAbrechnungDetailsPage />} />}
  />
);
