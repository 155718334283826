import React, { FC } from 'react';
import { Col, Drawer, MenuProps, Modal, Row } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { useDeleteBudgetingVPosAuftschluesselLinkageMutation } from '../../gql/budgetingVPosAuftschluesselLinkageMutations.types';
import { BudgetingVPosAuftschluesselLinkage } from '../../../../types';
import { useToggle } from '../../../../hooks/useToggle';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import LinkageForm from '../../Form/LinkageForm';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import LinkageChangeHistoryListingTable from './LinkageChangeHistoryListingTable';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';

type AufteilungsschluesselTableActionsProps = {
  budgetingVPosAuftschluesselLinkage: BudgetingVPosAuftschluesselLinkage;
  linkageEntryList: BudgetingVPosAuftschluesselLinkage[];
  onAction: () => void;
};

const EntryListingTableActions: FC<AufteilungsschluesselTableActionsProps> = ({ budgetingVPosAuftschluesselLinkage, onAction, linkageEntryList }) => {
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runDelete] = useDeleteBudgetingVPosAuftschluesselLinkageMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Verknüpfung`);
      onAction();
    },
    variables: {
      budgetingVPosAuftschluesselLinkageId: budgetingVPosAuftschluesselLinkage.budgetingVPosAuftschluesselLinkageId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Bearbeiten',
      onClick: toggleIsDrawerOpen,
      icon: <EditOutlined />,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, budgetingVPosAuftschluesselLinkage),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Drawer width={720} title="Verknüpfung bearbeiten" onClose={toggleIsDrawerOpen} open={isDrawerOpen} destroyOnClose>
        <LinkageForm
          linkage={budgetingVPosAuftschluesselLinkage}
          linkageEntryList={linkageEntryList}
          onCancel={toggleIsDrawerOpen}
          onSuccess={() => {
            toggleIsDrawerOpen();
            onAction();
          }}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <LinkageChangeHistoryListingTable
          budgetingVPosAuftschluesselLinkageId={budgetingVPosAuftschluesselLinkage.budgetingVPosAuftschluesselLinkageId}
        />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, budgetingVPosAuftschluesselLinkage: BudgetingVPosAuftschluesselLinkage) => () => {
  Modal.confirm({
    title: `Möchten Sie die Verknüpfung löschen?`,
    content: (
      <>
        <Row gutter={8} style={{ marginTop: 20 }}>
          <Col span={11}>Verwaltungsart:</Col>
          <Col span={13}>{budgetingVPosAuftschluesselLinkage.objektVerwaltungsart.text}</Col>
        </Row>
        <Row gutter={8}>
          <Col span={11}>Vertragsebene:</Col>
          <Col span={13}>{budgetingVPosAuftschluesselLinkage.vertragLevel.text}</Col>
        </Row>
        <Row gutter={8}>
          <Col span={11}>Vorschreibungsposition:</Col>
          <Col span={13}>{budgetingVPosAuftschluesselLinkage.vorschreibungsposition.bezeichnung}</Col>
        </Row>
        <Row gutter={8}>
          <Col span={11}>Aufteilungsschlüssel:</Col>
          <Col span={13}>{budgetingVPosAuftschluesselLinkage.aufteilungsschluessel.kurzBezeichnung}</Col>
        </Row>
      </>
    ),
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default EntryListingTableActions;
