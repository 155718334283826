import React from 'react';
import { FormikProps } from 'formik';
import { Badge, Button, Space } from 'antd';
import { FormattedNumber } from 'react-intl';
import { Checkbox } from 'formik-antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isNil } from 'lodash';
import BeAufteilungsschluesselWertActions from '../shared/BeAufteilungsschluesselWertActions';
import { EditableTableColumnProps } from '../../../../../components/Table/EditableTableOld/EditableTableProps';
import { updateFormikField } from '../../../../../helpers/formikHelper';
import { validateSyncWithSchemaAndReturnErrorIfAny } from '../../../../../helpers/validationHelper';
import { formattedDecimalType } from '../../../../../components/Input-antd';
import {
  beAufteilungsschluesselWertOhneBezugFormFields,
  BeAufteilungsschluesselWertOhneBezugFormValues,
} from './BeAufteilungsschluesselWertOhneBezugMapper';
import BeAufteilungsschluesselWertFormValidFrom from '../shared/BeAufteilungsschluesselWertFormValidFrom';
import BeAufteilungsschluesselWertOhneBezugValidFrombWithInfo from './BeAufteilungsschluesselWertOhneBezugValidFrombWithInfo';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';
import FormItemWithTooltip from '../../../../../components/Form/FormItemWithTooltip';
import { BeAufteilingsschluesselWert } from '../../../../../types';

const beAufteilungsschluesselWertOhneBezugTableColumns = (
  aufteilungsschluesselId: string,
  wertList: BeAufteilingsschluesselWert[],
  onAction: () => void
): EditableTableColumnProps<BeAufteilingsschluesselWert, BeAufteilungsschluesselWertOhneBezugFormValues>[] => [
  {
    title: 'Gültig ab',
    editable: true,
    render: (text, record) => <BeAufteilungsschluesselWertOhneBezugValidFrombWithInfo record={record} />,
    renderInEditMode: ({ selectedRecord }) => (
      <BeAufteilungsschluesselWertFormValidFrom
        validFromFieldName={beAufteilungsschluesselWertOhneBezugFormFields.validFrom}
        beAufteilungsschluesselWertList={wertList}
        selectedRecord={selectedRecord}
      />
    ),
  },
  {
    title: 'Wert',
    editable: true,
    align: 'right',
    render: (text, record) => <FormattedNumber value={record.wert} minimumFractionDigits={2} maximumFractionDigits={9} />,
    renderInEditMode: ({ records, selectedRecord }, formikProps) => (
      <FormItemWithTooltip name={beAufteilungsschluesselWertOhneBezugFormFields.wert} style={{ margin: 0 }}>
        <FormattedDecimalFormInput
          name={beAufteilungsschluesselWertOhneBezugFormFields.wert}
          fractionDigits={9}
          size="small"
          disabled={!formikProps.values.isUsed}
          style={{ margin: 0, width: '100%' }}
          validate={(value) => validateFormattedDecimal(value, records, selectedRecord)}
        />
      </FormItemWithTooltip>
    ),
  },
  {
    title: 'Status',
    editable: true,
    render: (text, record) => <Badge status={record.aktiv ? 'success' : 'default'} text={record.aktiv ? 'angewendet' : 'nicht angewendet'} />,
    renderInEditMode: (_, formikProps) => (
      <FormItemWithTooltip name={beAufteilungsschluesselWertOhneBezugFormFields.isUsed} style={{ marginBottom: '0px' }}>
        <Checkbox
          id={beAufteilungsschluesselWertOhneBezugFormFields.isUsed}
          name={beAufteilungsschluesselWertOhneBezugFormFields.isUsed}
          onChange={(e) => onChangeSwitch(e, formikProps)}
        >
          angewendet
        </Checkbox>
      </FormItemWithTooltip>
    ),
  },
  {
    title: 'Aktion',
    key: 'action',
    render: (text, record) => (
      <BeAufteilungsschluesselWertActions
        aufteilungsschluesselId={aufteilungsschluesselId}
        onAction={onAction}
        aufteilungsschluesselWert={record}
        aufteilungsschluesselWertList={wertList}
      />
    ),
    editable: true,
    renderInEditMode: ({ cancelEdit, isSubmitLoading }) => (
      <Space>
        <Button htmlType="submit" type="link" loading={isSubmitLoading}>
          Speichern
        </Button>
        <Button size="small" type="link" onClick={cancelEdit}>
          Abbrechen
        </Button>
      </Space>
    ),
  },
];

const validateFormattedDecimal = (value: any, records: BeAufteilingsschluesselWert[], selectedRecord: BeAufteilingsschluesselWert) => {
  const notAllowedWert = findNotAllowedWert(records, selectedRecord);
  if (!isNil(notAllowedWert)) {
    return validateSyncWithSchemaAndReturnErrorIfAny(
      formattedDecimalType('Wert muss eine Nummer sein').notOneOf([notAllowedWert], 'Wert muss sich von dem vorherigen Wert unterscheiden'),
      value
    );
  }
  return undefined;
};

const findNotAllowedWert = (records: BeAufteilingsschluesselWert[], selectedRecord: BeAufteilingsschluesselWert) => {
  const editedWertIndex = records.findIndex((record) => record.beAufteilungsschluesselWertId === selectedRecord.beAufteilungsschluesselWertId);
  if (editedWertIndex !== -1 && editedWertIndex !== records.length - 1) {
    return records[editedWertIndex + 1].wert;
  }
  return undefined;
};

const onChangeSwitch = (isChecked: CheckboxChangeEvent, formikProps: FormikProps<any>) => {
  if (!isChecked) {
    updateFormikField(formikProps, beAufteilungsschluesselWertOhneBezugFormFields.lastWert, formikProps.values.wert);
    updateFormikField(formikProps, beAufteilungsschluesselWertOhneBezugFormFields.wert, 0);
  } else {
    updateFormikField(formikProps, beAufteilungsschluesselWertOhneBezugFormFields.wert, formikProps.values.lastWert);
  }
};

export default beAufteilungsschluesselWertOhneBezugTableColumns;
