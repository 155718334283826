import React, { FC } from 'react';
import InnerCardAddress from './InnerCardAddress';
import { Address } from '../../../../types';

type Props = {
  addressList: Address[];
};

const InnerCardsAddressList: FC<Props> = ({ addressList }) => (
  <>
    {addressList.map((address) => (
      <div key={address.addressId} data-testid={`address-${address.addressId}`}>
        <InnerCardAddress address={address} />
      </div>
    ))}
  </>
);

export default InnerCardsAddressList;
