import { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import VstKuerzungDetailsListing from './VstKuerzungDetailsListing';
import { Vorsteuerkuerzung } from '../../../types';

type Props = {
  vstKuerzung: Vorsteuerkuerzung;
};

const VstKuerzungDetailsTab: FC<Props> = ({ vstKuerzung }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Vorsteuerkürzung',
      children: <VstKuerzungDetailsListing vstKuerzung={vstKuerzung} />,
    },
  ];
  return <Tabs items={items} />;
};

export default VstKuerzungDetailsTab;
