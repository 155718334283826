import React from 'react';
import { Badge } from 'antd';
import { Select } from 'formik-antd';
import { isStatusActive, isStatusArchived, isStatusGesperrt } from '../../../../helpers/statusHelper';
import { messageEntityArchived, messageEntityGesperrt } from '../../../message/message';
import { EntityStatusFormSelectProps, ISelectItemWithStatus } from './EntityStatusProps';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useCreateNewItemInSelect } from '../../../Select/useCreateNewItemInSelect';

// Supports elements without a status prop, treating them as active elements
function EntityStatusFormSelect<SelectItem extends ISelectItemWithStatus>({
  list,
  getSelectItemId,
  getSelectItemContent,
  getSelectItemTooltipTitle,
  onNewItemCreateSelected,
  newItemText,
  ...restProps
}: EntityStatusFormSelectProps<SelectItem>) {
  const { handleSelect, handleSearch, renderCreateNewItem } = useCreateNewItemInSelect(list, onNewItemCreateSelected, newItemText);

  const activeList = list?.filter((item) => !item.status || isStatusActive(item.status)) ?? [];
  const archivedList = list?.filter((item) => item.status && isStatusArchived(item.status)) ?? [];
  const gesperrtList = list?.filter((item) => item.status && isStatusGesperrt(item.status)) ?? [];
  return (
    <Select allowClear showSearch filterOption={selectFilterOption} onSelect={handleSelect} onSearch={handleSearch} {...restProps}>
      {activeList.map((item) => {
        return (
          <Select.Option key={getSelectItemId(item)} value={getSelectItemId(item)} title={getSelectItemTooltipTitle(item)}>
            {getSelectItemContent(item)}
          </Select.Option>
        );
      })}
      {archivedList.length > 0 ? (
        <Select.OptGroup label="Archiviert">
          {archivedList.map((item) => (
            <Select.Option
              key={getSelectItemId(item)}
              value={getSelectItemId(item)}
              title={`${getSelectItemTooltipTitle(item)} \n${messageEntityArchived()}`}
            >
              {getSelectItemContent(item)}
              <Badge dot status="error" style={{ top: '-6px' }} />
            </Select.Option>
          ))}
        </Select.OptGroup>
      ) : null}
      {gesperrtList.length > 0 ? (
        <Select.OptGroup label="Gesperrt">
          {gesperrtList.map((item) => (
            <Select.Option
              key={getSelectItemId(item)}
              value={getSelectItemId(item)}
              title={`${getSelectItemTooltipTitle(item)} \n${messageEntityGesperrt()}`}
            >
              {getSelectItemContent(item)}
              <Badge dot status="error" style={{ top: '-6px' }} />
            </Select.Option>
          ))}
        </Select.OptGroup>
      ) : null}
      {renderCreateNewItem()}
    </Select>
  );
}

export default EntityStatusFormSelect;
