import { Tabs, TabsProps } from 'antd';
import React, { FC } from 'react';
import { Spacer } from '../../../components/Grid';
import VertragTable from './Table/VertragTable';
import { BestandseinheitAufteilungsschluesselTable } from '../../Bestandseinheit/Aufteilungsschluessel';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import { Bestandseinheit } from '../../../types';

type Props = {
  bestandseinheitId: string;
  objektId: string;
  bestandseinheit: Bestandseinheit;
  rechnungsausstellerId: string;
};

const VertragAufteilungsschluesselTab: FC<Props> = ({ bestandseinheitId, objektId, bestandseinheit, rechnungsausstellerId }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Verträge/Aufteilungsschlüssel',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <VertragTable
            bestandseinheit={bestandseinheit}
            objektId={objektId}
            bestandseinheitId={bestandseinheitId}
            rechnungsausstellerId={rechnungsausstellerId}
          />
          <Spacer height={16} />
          <BestandseinheitAufteilungsschluesselTable objektId={objektId} bestandseinheitId={bestandseinheitId} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default VertragAufteilungsschluesselTab;
