import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { Tag } from 'antd';

const buchungsmaskeErrorLogTableColumns: ColumnProps<any>[] = [
  {
    title: 'Status',
    render: (text, record) => <Tag>{record.type}</Tag>,
  },
  {
    title: 'Erklärung',
    align: 'right',
    render: (text, record) => record.message,
  },
  {
    title: 'Korrekturmöglichkeiten',
    align: 'right',
    render: () => <div />,
  },
];

export default buchungsmaskeErrorLogTableColumns;
