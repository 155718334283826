import { FormFields } from '../../../helpers/formikHelper';
import { PostItInput } from '../../../types';
import { ValuesDescriptions } from '../../Timeline/versionTimelineHelper';

export interface PostItFormValues {
  immerAnzeigen: boolean;
  text: string;
  titel: string;
}

export const postItFormInitialValues = (postIt?: PostItFormValues): PostItFormValues => ({
  immerAnzeigen: postIt?.immerAnzeigen ?? false,
  text: postIt?.text ?? '',
  titel: postIt?.titel ?? '',
});

export const postItFormFields: FormFields<PostItFormValues> = {
  immerAnzeigen: 'immerAnzeigen',
  text: 'text',
  titel: 'titel',
};

export const mapFormValuesToPostIt = (formValues: PostItFormValues): PostItInput => ({
  immerAnzeigen: formValues.immerAnzeigen,
  text: formValues.text,
  titel: formValues.titel,
});

export const postItFormValuesDescriptions: ValuesDescriptions<PostItFormValues> = {
  immerAnzeigen: 'Immer anzeigen',
  text: 'Post-It-Text',
  titel: 'Titel',
};
