import React from 'react';
import { Link } from 'react-router-dom';
import { generatePathToBeVertragDetailsPageOrDiscrepancies } from '../beVertragUriPaths';
import { EuroAmount } from '../../../../components/Number';
import StatusBadge from '../../../../components/Status/StatusBadge';
import CurrentBasedData from '../../../../components/Helpers/CurrentBasedData';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import { compareTwoDatesForSorting } from '../../../../helpers/dateHelper';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { BeVertragListEntry } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const vertragTableColumns = (objektId: string, bestandseinheitId: string): TableWithColSelectorColumnProps<BeVertragListEntry>[] => [
  {
    title: 'Vertrag',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.vertragsart.text, b.vertragsart.text),
    render: (text, record) => (
      <CurrentBasedData
        text={record.vertragsart.text}
        status={record.status}
        isCurrent={record.current}
        activeFrom={record.vertragsBeginn}
        warningList={record.warningList}
      />
    ),
  },
  {
    title: 'Person',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.vertragspartner.kurzBezeichnung, b.vertragspartner.kurzBezeichnung),
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.vertragspartner.rechtstraegerId}
        rechtstraegerName={record.vertragspartner.kurzBezeichnung}
        isCurrent={record.current}
        status={record.vertragspartner.status}
      />
    ),
  },
  {
    title: 'Vertragsbeginn',
    defaultSelected: true,
    sorter: (a, b) => compareTwoDatesForSorting(a.vertragsBeginn, b.vertragsBeginn),
    render: (text, record) => (
      <CurrentBasedData text="" status={record.status} isCurrent={record.current}>
        <CustomFormattedDate value={record.vertragsBeginn} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Netto',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => a.sumNetto - b.sumNetto,
    render: (text, record) => (
      <CurrentBasedData text="" status={record.status} isCurrent={record.current}>
        <EuroAmount value={record.sumNetto} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'USt.',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => a.sumUst - b.sumUst,
    render: (text, record) => (
      <CurrentBasedData text="" status={record.status} isCurrent={record.current}>
        <EuroAmount value={record.sumUst} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Brutto',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => a.sumBrutto - b.sumBrutto,
    render: (text, record) => (
      <CurrentBasedData text="" status={record.status} isCurrent={record.current}>
        <EuroAmount value={record.sumBrutto} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.status.text, b.status.text),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    key: 'action',
    render: (text, record) => (
      <Link
        key={record.vertragId}
        to={generatePathToBeVertragDetailsPageOrDiscrepancies(objektId, bestandseinheitId, record.vertragId, record.status)}
      >
        Details
      </Link>
    ),
  },
];

export default vertragTableColumns;
