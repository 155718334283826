import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../helpers/selectHelper';
import { PaymentTransactionType } from '../../../types';

type Props = SelectProps;

const PaymentTransactionTypeFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const list = [
    {
      text: 'EBICS',
      value: PaymentTransactionType.Ebics,
    },
    {
      text: 'Onlinebanking',
      value: PaymentTransactionType.OnlineBanking,
    },
  ];

  return (
    <Select
      name={name}
      size="small"
      placeholder="Zahlungsverkehrsart auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      {...restProps}
    >
      {list.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};
export default PaymentTransactionTypeFormSelect;
