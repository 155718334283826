import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useTicketStatusListQuery } from '../gql/TicketQueries.types';

type Props = {
  includeArchived?: boolean;
} & SelectProps;

const TicketStatusSelect: FC<Props> = ({ includeArchived, ...props }) => {
  const { data, loading } = useTicketStatusListQuery({ variables: { includeArchiviert: includeArchived } });

  const statusesList = data?.getTicketStatusList.data || [];

  return (
    <Select loading={loading} size="small" id={props.name} placeholder="Status auswählen" {...props}>
      {statusesList.map(({ text, value }) => {
        return (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default TicketStatusSelect;
