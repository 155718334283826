import React, { FC } from 'react';
import { Checkbox } from 'formik-antd';
import { postItFormFields, postItFormValuesDescriptions } from '../PostItFormMapper';
import FormItemWithFieldHelp from '../../../Form/FormItemWithFieldHelp';

type Props = {
  checked: boolean;
  fieldHelp?: string | null;
};

const PostItFormItemImmerAnzeigen: FC<Props> = ({ checked, fieldHelp }) => (
  <FormItemWithFieldHelp name={postItFormFields.immerAnzeigen} label={postItFormValuesDescriptions.immerAnzeigen} fieldHelp={fieldHelp}>
    <Checkbox id={postItFormFields.immerAnzeigen} name={postItFormFields.immerAnzeigen} checked={checked} />
  </FormItemWithFieldHelp>
);

export default PostItFormItemImmerAnzeigen;
