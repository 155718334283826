import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useLeerstehungForderungsKontoChangeHistoryListQuery } from '../gql/FirmendatenBuchhaltungEinstellungQueries.types';

const LeerstehungForderungsKontoChangeHistoryListingTable: FC = () => {
  const { data, loading } = useLeerstehungForderungsKontoChangeHistoryListQuery();
  const historyList = data?.getLeerstehungForderungsKontoChangeHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="leerstehung-forderungs-konto" />
  );
};

export default LeerstehungForderungsKontoChangeHistoryListingTable;
