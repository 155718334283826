import { StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const pdfStyles = StyleSheet.create({
  body: { fontFamily: 'Roboto', paddingTop: 34, paddingBottom: 65, paddingHorizontal: 35 },
  footer: { position: 'absolute', left: 0, bottom: 0, width: '100%', marginBottom: '5mm', paddingLeft: '12.5mm', paddingRight: '12.5mm' },
  column: { display: 'flex', flexDirection: 'column' },
  row: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
  tablePaddingLeftRight: { paddingLeft: '3mm', paddingRight: '3mm' },
  textNormal: { fontSize: 10, lineHeight: 1.5, minHeight: 16.6 },
  textMarginLeft: { fontSize: 11, lineHeight: 1.6, minHeight: 17.6, marginLeft: '3mm' },
  textBulletin: { fontSize: 9, lineHeight: 1.6, minHeight: 14.4 },
  textBulletinSmall: { fontSize: 8, lineHeight: 1.6, minHeight: 14.4 },
  textBulletinXSmall: { fontSize: 7, lineHeight: 1.6, minHeight: 14.4 },
  textList: { fontSize: 9, lineHeight: 1.6, minHeight: 14.4 },
  textUppercase: { fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: '0.7px' },
  borderBottomSmall: { borderBottomWidth: 1, borderBottomColor: '#333', borderBottomStyle: 'solid' },
  borderTopSmall: { borderTopWidth: 1, borderTopColor: '#333', borderTopStyle: 'solid' },
  boxShadow: {
    borderWidth: 1,
    borderColor: '#333',
    borderStyle: 'solid',
    padding: '1mm',
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderRightWidth: 3,
    borderRightStyle: 'solid',
  },
  addressor: { width: '120mm', height: '40mm', paddingLeft: '14.5mm' },
  lineBlock: { fontSize: 9, minHeight: 12.5 },
  buchungSumPerAccount: { marginTop: '5mm', marginBottom: '0mm' },
  displayBuchungszeilen: {
    textDecoration: 'underline',
    textAlign: 'left',
    marginTop: '5mm',
    marginBottom: '0mm',
  },
});

export const selectableStyles = (isInEditMode?: boolean, isSelected?: boolean): Style => {
  if (!isInEditMode) return {};
  if (isSelected) {
    return {
      backgroundColor: '#1677ff',
      color: '#FFF',
      borderRadius: '16px',
      border: 'border: 1px solid #1677ff',
      padding: '8px',
    };
  } else {
    return { padding: '8px', backgroundColor: '#fafafa', borderRadius: '16px', opacity: 0.9, border: '1px dashed #1677ff' };
  }
};

export default pdfStyles;
