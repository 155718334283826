import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input, InputNumber } from 'formik-antd';
import { Spacer } from '../../../components/Grid';
import FormButtons from '../../../components/Button/FormButtons';
import { showSuccessMsgCreate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { Kontierungstabelle, KontoKlasse } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import {
  useCreateKontierungstabelleTemplateMutation,
  useUpdateKontierungstabelleTemplateMutation,
} from '../gql/KontierungstabelleTemplateMutations.types';
import { kontierungstabelleFormValidationSchema } from '../../Kontierungstabelle/Form/kontierungstabelleFormValidationSchema';
import {
  kontierungstabelleFormFields,
  KontierungstabelleFormValues,
  mapFormValuesToKontierungstabelle,
  mapKontierungstabelleToFormValues,
} from '../../Kontierungstabelle/Form/kontierungstabelleFormMapper';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import AufwandskontenSelect from '../../shared/AufwandskontenSelect';

type Props = {
  kontierungstabelle?: Kontierungstabelle;
  onSuccess: () => void;
  onCancel: () => void;
};

const KontierungstabelleTemplateForm: FC<Props> = ({ kontierungstabelle, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Kontierungstabelle'>('Kontierungstabelle');

  const isUpdate = !!kontierungstabelle;

  const entity = 'Kontierungstabelle';

  const [runCreate, { loading: loadingCreate }] = useCreateKontierungstabelleTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateKontierungstabelleTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<KontierungstabelleFormValues>
      initialValues={mapKontierungstabelleToFormValues(kontierungstabelle)}
      validationSchema={kontierungstabelleFormValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        const input = mapFormValuesToKontierungstabelle(formValues);
        if (!kontierungstabelle) {
          runCreate({
            variables: { input },
          }).finally(() => setSubmitting(false));
        } else {
          runUpdate({
            variables: {
              kontierungstabelleId: kontierungstabelle.kontierungstabelleId,
              input,
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={kontierungstabelleFormFields.bezeichnung}
            label="Bezeichnung"
            fieldHelp={getFieldHelpText('Kontierungstabelle.bezeichnung')}
          >
            <Input id="bezeichnung" name={kontierungstabelleFormFields.bezeichnung} placeholder='z.B. Kontierungstabelle "leicht zu merken"' />
          </FormItemWithFieldHelp>

          <FormItemWithFieldHelp
            name={kontierungstabelleFormFields.basiskonto}
            label="Basiskonto Erlös"
            fieldHelp={getFieldHelpText('Kontierungstabelle.basiskonto')}
          >
            <InputNumber
              style={{ width: '100%' }}
              id={kontierungstabelleFormFields.basiskonto}
              name={kontierungstabelleFormFields.basiskonto}
              placeholder="z.B. 49990"
            />
          </FormItemWithFieldHelp>

          <FormItemWithFieldHelp
            name={kontierungstabelleFormFields.subAdministrationExpenseAccountId}
            label="Aufwandskonto (Subverwaltung)"
            fieldHelp={getFieldHelpText('Kontierungstabelle.subAdministrationExpenseAccountId')}
          >
            <AufwandskontenSelect
              name={kontierungstabelleFormFields.subAdministrationExpenseAccountId}
              showKontoNummerAsTag
              klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.BetrieblicherAufwand, KontoKlasse.PersonalAufwand, KontoKlasse.FinanzAufwand]}
              placeholder="Aufwandskonto auswählen"
            />
          </FormItemWithFieldHelp>

          <Spacer height={16} />

          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default KontierungstabelleTemplateForm;
