import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { EuroAmount } from '../../../../components/Number';
import LinkToBelegFile from '../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import { OffenePosten, OffenePostenOrderBy } from '../../../../types';
import KontoLinkToKontoblatt from '../../../../shared/components/Konto/KontoLinkToKontoblatt';
import AvatarBasedOnBelegSymbolWithTooltip from '../../../../components/Avatar/BasedOnBelegSymbol/AvatarBasedOnBelegSymbolWithTooltip';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../../components/Card';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { generatePathToBankstatementListingPage } from '../../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';
import { generatePathToBookingDetailsPage } from '../../../BookingDetails/bookingDetailsPaths';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

export const offenePostenTableColumns: TableWithColSelectorColumnProps<OffenePosten>[] = [
  {
    title: 'Buchungskreis',
    dataIndex: OffenePostenOrderBy.BuchungskreisRechtstraegerKurzbezeichnung,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.buchungskreisRechtstraegerId}
        rechtstraegerName={record.buchungskreisRechtstraegerBezeichnung}
        maxTextLength={25}
      />
    ),
  },
  {
    title: 'Beleg-Nr',
    defaultSelected: true,
    dataIndex: OffenePostenOrderBy.Belegnummer,
    width: 80,
    sorter: true,
    render: (text, record) => {
      let content = <DataWithShortenedText text={record.belegnummer} maxTextLength={15} />;

      if (record.dataCarrierBelegId) {
        content = (
          <DataWithShortenedText maxTextLength={15} text={record.belegnummer}>
            {(shortenedText) => (
              <Link
                to={generatePathToBankstatementListingPage({
                  iban: record.iban,
                  statementNumber: record.statementNumber,
                })}
                target="_blank"
              >
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        );
      } else if (record.belegFileId) {
        content = <LinkToBelegFile fileId={record.belegFileId} belegnummer={record.belegnummer} hideIcon />;
      }

      return (
        <Space size="small">
          <AvatarBasedOnBelegSymbolWithTooltip belegSymbol={record.belegSymbol} size="small" />
          {content}
        </Space>
      );
    },
  },
  {
    title: 'Belegdatum',
    defaultSelected: true,
    dataIndex: OffenePostenOrderBy.BelegDatum,
    width: 120,
    sorter: true,
    align: 'right',
    render: (text, record) => <CustomFormattedDate value={record.belegDatum} />,
  },
  {
    title: 'Buchungsdatum',
    defaultSelected: true,
    dataIndex: OffenePostenOrderBy.Buchungsdatum,
    width: 120,
    sorter: true,
    align: 'right',
    render: (text, record) => <CustomFormattedDate value={record.buchungsdatum} />,
  },
  {
    title: 'Gesamtbetrag Brutto',
    defaultSelected: true,
    dataIndex: OffenePostenOrderBy.GesamtBetrag,
    align: 'right',
    width: 150,
    sorter: true,
    render: (text, record) => (
      <Link to={generatePathToBookingDetailsPage([record.buchungId])}>
        <EuroAmount value={record.gesamtBetrag} />
      </Link>
    ),
  },
  {
    title: 'Offener Betrag',
    defaultSelected: true,
    dataIndex: OffenePostenOrderBy.OffenerBetrag,
    align: 'right',
    width: 150, // if this width is changed, update the width of 'Saldo offener Betrag' column in offenePostenAuszifferungTableColumns as their width should be the same
    sorter: true,
    render: (text, record) => <EuroAmount value={record.offenerBetrag} />,
  },
  {
    title: 'Fälligkeit',
    defaultSelected: true,
    dataIndex: OffenePostenOrderBy.DueDate,
    width: 120,
    sorter: true,
    align: 'right',
    render: (text, record) => <CustomFormattedDate value={record.dueDate} />,
  },
  {
    title: 'Letzte Mahnstufe',
    defaultSelected: true,
    dataIndex: OffenePostenOrderBy.LetzteMahnstufe,
    align: 'right',
    width: 140,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.letzteMahnstufe}</Typography.Text>,
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    dataIndex: OffenePostenOrderBy.VertragspatnerKurzbezeichnung,
    width: 160,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.vertragspartner.rechtstraegerId}
        rechtstraegerName={record.vertragspartner.kurzBezeichnung}
        maxTextLength={25}
      />
    ),
  },
  {
    title: 'Fibu-Konto',
    defaultSelected: true,
    dataIndex: OffenePostenOrderBy.KontoNummer,
    sorter: true,
    render: (text, record) => (
      <KontoLinkToKontoblatt
        konto={record.konto}
        buchungskreisId={record.buchungskreisId}
        buchungsdatumFrom={record.buchungsdatum}
        vertragspartnerId={record.vertragspartner.rechtstraegerId}
        maxBezeichnungLength={null}
      />
    ),
  },
  {
    title: 'Erstellt am',
    dataIndex: OffenePostenOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    dataIndex: OffenePostenOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
];
