import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { AuszifferungBlockFormValues, AuszifferungFormValues, OffenePostenForBooking } from '../../../auszifferungFormMapper';
import { auszifferungTableColumns } from '../../shared/Level2/auszifferungTableColumns';
import { isNewAuszifferung } from '../../../auszifferungFormHelpers';
import { StyledTableForEntriesFromThisZahlung } from '../../shared/Level2/StyledTableForThisZahlung.style';
import { belongsAuszifferungToAnotherZahlung, isAuszifferungSourceStorno } from '../../../../../../shared/auszifferungHelpers';

type Props = {
  bookingZahlungId: string;
  formikProps: FormikProps<AuszifferungBlockFormValues>;
  offenePosten: OffenePostenForBooking;
};

const AuszifferungListTableFromBooking: FC<Props> = ({ bookingZahlungId, formikProps, offenePosten }) => {
  const isRowEntryFormThisZahlung = (record: AuszifferungFormValues) =>
    !isAuszifferungSourceStorno(record.source?.value) &&
    (!belongsAuszifferungToAnotherZahlung(bookingZahlungId, record.zahlungId) || isNewAuszifferung(record));

  return (
    <StyledTableForEntriesFromThisZahlung<AuszifferungFormValues>
      rowKey={(record) => record.auszifferungId}
      columns={auszifferungTableColumns(offenePosten, formikProps, bookingZahlungId, false)}
      dataSource={offenePosten.auszifferungList}
      pagination={false}
      rowClassName={(record) => (isRowEntryFormThisZahlung(record) ? 'row-entry-from-this-zahlung' : '')}
    />
  );
};

export default AuszifferungListTableFromBooking;
