import { Select, SelectProps } from 'formik-antd';
import { FC } from 'react';
import { AuftragStatus } from '../../../../types';

type Props = {
  name: string;
} & SelectProps;

const AuftragStatusFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const auftragStatusList = [
    { value: AuftragStatus.Valid, text: 'Gültig' },
    { value: AuftragStatus.Invalid, text: 'Ungültig' },
  ];

  return (
    <Select size="middle" name={name} id={name} placeholder="" style={{ minWidth: '220px' }} {...restProps} allowClear>
      {auftragStatusList.map((auftragStatus) => (
        <Select.Option key={auftragStatus.value} value={auftragStatus.value}>
          {auftragStatus.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AuftragStatusFormSelect;
