import { useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import {
  DataCarrierCamtIbanListDocument,
  DataCarrierCamtListDocument,
  DataCarrierCamtNameListDocument,
  DataCarrierCamtStatementNumberListDocument,
  useDataCarrierCamtListQuery,
} from '../../gql/DataCarrierQueries.types';
import { TBankstatementQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import {
  FiltersFormValues,
  mapFormValuesToQueryParams,
  mapQueryParamsToFormValues,
  mapQueryParamsToListQueryVariables,
} from './Filters/filtersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import DataCarrierCamtTable from './Table/Level1/DataCarrierCamtTable';
import { useOnBankstatementDataChangeEvents } from './useOnBankstatementDataChangeEvents';

const BankstatementListing = () => {
  const navigate = useNavigate();
  // @ts-ignore
  const queryParams = useQueryParams<TBankstatementQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  const { data, loading, pagination, handleTableSorting, refetch } = useQueryWithPagingAndSorting(
    useDataCarrierCamtListQuery,
    {
      variables: {
        ...mapQueryParamsToListQueryVariables(queryParams),
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const dataCarrierCamtList = data?.getDataCarrierCamtList.data.contentList ?? [];

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  const client = useApolloClient();
  useOnBankstatementDataChangeEvents('bankstatement', async () => {
    await client.refetchQueries({
      include: [
        DataCarrierCamtListDocument,
        DataCarrierCamtIbanListDocument,
        DataCarrierCamtNameListDocument,
        DataCarrierCamtStatementNumberListDocument,
      ],
    });
  });

  return (
    <>
      <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => <ListingFilters queryParams={queryParams} formikProps={formikProps} onAction={refetch} />}
      </Formik>
      <DataCarrierCamtTable
        dataCarrierCamtList={dataCarrierCamtList}
        loading={loading}
        handleTableSorting={handleTableSorting}
        pagination={pagination}
        onAction={refetch}
      />
    </>
  );
};

export default BankstatementListing;
