import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';
import { FormFields } from '../../../../../../helpers/formikHelper';
import { RechtstraegerInfoFeldFragment } from '../../../gql/rechtstraegerInfoFeldFragments.types';

export interface PersoneninfosUpdateFormValues extends InfoFeldFormValues {
  merkmalList: string[];
  geburtsort?: string;
  staatsbuergerschaft?: string;
  kundengruppeList?: string[];
  identitaetsnachweis?: string;
  deleteDocumentFileIdList: string[];
}

export const personeninfosUpdateFormFields: FormFields<PersoneninfosUpdateFormValues> = {
  merkmalList: 'merkmalList',
  geburtsort: 'geburtsort',
  staatsbuergerschaft: 'staatsbuergerschaft',
  kundengruppeList: 'kundengruppeList',
  identitaetsnachweis: 'identitaetsnachweis',
  documentList: 'documentList',
  deleteDocumentFileIdList: 'deleteDocumentFileIdList',
};

export const mapToUpdateFormValues = (infofeld: RechtstraegerInfoFeldFragment): PersoneninfosUpdateFormValues => ({
  documentList: [],
  deleteDocumentFileIdList: [],
  merkmalList: infofeld.merkmalList,
  geburtsort: infofeld.geburtsort ?? undefined,
  staatsbuergerschaft: infofeld.staatsbuergerschaft ?? undefined,
  identitaetsnachweis: infofeld.identitaetsnachweis ?? undefined,
  kundengruppeList: infofeld.kundengruppeList ?? undefined,
});
