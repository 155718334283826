import * as Types from '../../../types';

import { gql } from '@apollo/client';
import {
  AbrechnungsdefinitionListEntryFieldsFragmentDoc,
  KontoAbrechnungskreisFieldsFragmentDoc,
} from '../../Abrechnungsdefinition/gql/AbrDefFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungsdefinitionTemplateListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AbrechnungsdefinitionTemplateListQuery = {
  getAbrechnungsdefinitionTemplateList: {
    data: Array<{
      abrechnungsdefinitionId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      textBausteineBefuellt: boolean;
      abrechnungsdefinitionVersion?: {
        abrechnungsdefinitionVersionId: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        updatedByMitarbeiterId?: string | null;
        validFrom: string;
        abrechdefAbrechkreisList: Array<{
          abrechnungskreis: {
            abrechnungskreisId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
            type: { text: string; value: Types.AbrechnungskreisType };
            verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          aufteilungsschluessel?: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          vorschreibungsposition?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            vorschreibungspositionId: string;
            art: { text: string; value: Types.VorschreibungspositionArt };
            kontierungstabelle?: {
              basiskonto: number;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kontierungstabelleId: string;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
              subAdministrationExpenseAccount?: {
                bezeichnung: string;
                firmendatenId?: string | null;
                kontoId: string;
                kontoKlasse: string;
                nummer: string;
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
            umsatzsteuerkennzeichen?: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kuerzel: string;
              umsatzsteuerkennzeichenId: string;
              status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            } | null;
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
        }>;
        kontoAbrechnungskreisList: Array<{
          abrechnen: boolean;
          buchungszeilenAnzeigen: boolean;
          expenseReducing: boolean;
          ustVomAufwand: boolean;
          abrechnungskreis?: {
            abrechnungskreisId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
            type: { text: string; value: Types.AbrechnungskreisType };
            verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          aufteilungsschluessel?: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
      type: { text: string; value: Types.AbrechnungsdefinitionType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQueryVariables = Types.Exact<{
  abrechnungsdefinitionVersionId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQuery = {
  getAbrechnungsdefinitionKontoAbrechnungskreisTemplateList: {
    data: Array<{
      abrechnungsdefinitionVersionId: string;
      abrechnungsdefinition: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        textBausteineBefuellt: boolean;
        abrechnungsdefinitionVersion?: {
          abrechnungsdefinitionVersionId: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          abrechdefAbrechkreisList: Array<{
            abrechnungskreis: {
              abrechnungskreisId: string;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
              type: { text: string; value: Types.AbrechnungskreisType };
              verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            aufteilungsschluessel?: {
              aufteilungsschluesselId: string;
              bezeichnung: string;
              bezugsbasisEditable?: boolean | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              updatedByMitarbeiterId?: string | null;
              bezugsbasis: { text: string; value: Types.Bezugsbasis };
              masseinheit: { text: string; value: Types.Masseinheit };
              status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            vorschreibungsposition?: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              vorschreibungspositionId: string;
              art: { text: string; value: Types.VorschreibungspositionArt };
              kontierungstabelle?: {
                basiskonto: number;
                bezeichnung: string;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                kontierungstabelleId: string;
                updatedByMitarbeiterId?: string | null;
                status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
                subAdministrationExpenseAccount?: {
                  bezeichnung: string;
                  firmendatenId?: string | null;
                  kontoId: string;
                  kontoKlasse: string;
                  nummer: string;
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
              umsatzsteuerkennzeichen?: {
                bezeichnung: string;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                kuerzel: string;
                umsatzsteuerkennzeichenId: string;
                status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
                warningList: Array<{ message: string; type: string; attribute?: string | null }>;
              } | null;
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            } | null;
          }>;
          kontoAbrechnungskreisList: Array<{
            abrechnen: boolean;
            buchungszeilenAnzeigen: boolean;
            expenseReducing: boolean;
            ustVomAufwand: boolean;
            abrechnungskreis?: {
              abrechnungskreisId: string;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
              type: { text: string; value: Types.AbrechnungskreisType };
              verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            aufteilungsschluessel?: {
              aufteilungsschluesselId: string;
              bezeichnung: string;
              bezugsbasisEditable?: boolean | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              updatedByMitarbeiterId?: string | null;
              bezugsbasis: { text: string; value: Types.Bezugsbasis };
              masseinheit: { text: string; value: Types.Masseinheit };
              status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
          }>;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
        type: { text: string; value: Types.AbrechnungsdefinitionType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungsdefinitionTemplateTypeQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
}>;

export type AbrechnungsdefinitionTemplateTypeQuery = {
  getAbrechnungsdefinitionTemplateType: { data: { text: string; value: Types.AbrechnungsdefinitionType } };
};

export const AbrechnungsdefinitionTemplateListDocument = gql`
  query AbrechnungsdefinitionTemplateList {
    getAbrechnungsdefinitionTemplateList {
      data {
        ...AbrechnungsdefinitionListEntryFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${AbrechnungsdefinitionListEntryFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<AbrechnungsdefinitionTemplateListQuery, AbrechnungsdefinitionTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungsdefinitionTemplateListQuery, AbrechnungsdefinitionTemplateListQueryVariables>(
    AbrechnungsdefinitionTemplateListDocument,
    options
  );
}
export function useAbrechnungsdefinitionTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungsdefinitionTemplateListQuery, AbrechnungsdefinitionTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungsdefinitionTemplateListQuery, AbrechnungsdefinitionTemplateListQueryVariables>(
    AbrechnungsdefinitionTemplateListDocument,
    options
  );
}
export function useAbrechnungsdefinitionTemplateListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AbrechnungsdefinitionTemplateListQuery, AbrechnungsdefinitionTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungsdefinitionTemplateListQuery, AbrechnungsdefinitionTemplateListQueryVariables>(
    AbrechnungsdefinitionTemplateListDocument,
    options
  );
}
export type AbrechnungsdefinitionTemplateListQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTemplateListQuery>;
export type AbrechnungsdefinitionTemplateListLazyQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTemplateListLazyQuery>;
export type AbrechnungsdefinitionTemplateListSuspenseQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTemplateListSuspenseQuery>;
export type AbrechnungsdefinitionTemplateListQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionTemplateListQuery,
  AbrechnungsdefinitionTemplateListQueryVariables
>;
export const AbrechnungsdefinitionKontoAbrechnungskreisTemplateListDocument = gql`
  query AbrechnungsdefinitionKontoAbrechnungskreisTemplateList($abrechnungsdefinitionVersionId: String, $kontoId: String) {
    getAbrechnungsdefinitionKontoAbrechnungskreisTemplateList(abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId, kontoId: $kontoId) {
      data {
        abrechnungsdefinition {
          ...AbrechnungsdefinitionListEntryFields
        }
        abrechnungsdefinitionVersionId
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AbrechnungsdefinitionListEntryFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionKontoAbrechnungskreisTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQueryVariables
  >(AbrechnungsdefinitionKontoAbrechnungskreisTemplateListDocument, options);
}
export function useAbrechnungsdefinitionKontoAbrechnungskreisTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQueryVariables
  >(AbrechnungsdefinitionKontoAbrechnungskreisTemplateListDocument, options);
}
export function useAbrechnungsdefinitionKontoAbrechnungskreisTemplateListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQuery,
        AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQueryVariables
  >(AbrechnungsdefinitionKontoAbrechnungskreisTemplateListDocument, options);
}
export type AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionKontoAbrechnungskreisTemplateListQuery
>;
export type AbrechnungsdefinitionKontoAbrechnungskreisTemplateListLazyQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionKontoAbrechnungskreisTemplateListLazyQuery
>;
export type AbrechnungsdefinitionKontoAbrechnungskreisTemplateListSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionKontoAbrechnungskreisTemplateListSuspenseQuery
>;
export type AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQuery,
  AbrechnungsdefinitionKontoAbrechnungskreisTemplateListQueryVariables
>;
export const AbrechnungsdefinitionTemplateTypeDocument = gql`
  query AbrechnungsdefinitionTemplateType($abrechnungsdefinitionId: ID!) {
    getAbrechnungsdefinitionTemplateType(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      data {
        text
        value
      }
    }
  }
`;
export function useAbrechnungsdefinitionTemplateTypeQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungsdefinitionTemplateTypeQuery, AbrechnungsdefinitionTemplateTypeQueryVariables> &
    ({ variables: AbrechnungsdefinitionTemplateTypeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungsdefinitionTemplateTypeQuery, AbrechnungsdefinitionTemplateTypeQueryVariables>(
    AbrechnungsdefinitionTemplateTypeDocument,
    options
  );
}
export function useAbrechnungsdefinitionTemplateTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungsdefinitionTemplateTypeQuery, AbrechnungsdefinitionTemplateTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungsdefinitionTemplateTypeQuery, AbrechnungsdefinitionTemplateTypeQueryVariables>(
    AbrechnungsdefinitionTemplateTypeDocument,
    options
  );
}
export function useAbrechnungsdefinitionTemplateTypeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AbrechnungsdefinitionTemplateTypeQuery, AbrechnungsdefinitionTemplateTypeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungsdefinitionTemplateTypeQuery, AbrechnungsdefinitionTemplateTypeQueryVariables>(
    AbrechnungsdefinitionTemplateTypeDocument,
    options
  );
}
export type AbrechnungsdefinitionTemplateTypeQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTemplateTypeQuery>;
export type AbrechnungsdefinitionTemplateTypeLazyQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTemplateTypeLazyQuery>;
export type AbrechnungsdefinitionTemplateTypeSuspenseQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTemplateTypeSuspenseQuery>;
export type AbrechnungsdefinitionTemplateTypeQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionTemplateTypeQuery,
  AbrechnungsdefinitionTemplateTypeQueryVariables
>;
