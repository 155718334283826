import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { DataCarrierType, GenerierlaufEntryExitCode, PaymentTransactionType } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum QueryParamKey {
  DATA_CARRIER_TYPE = 'dataCarrierType',
  NAME = 'name',
  PAYMENT_TRANSACTION_TYPE = 'paymentTransactionType',
  IBAN = 'iban',
  EXIT_CODE_LIST = 'exitCodeList',
}

export type TDataCarrierPainCreationQueryParams = QueryParams<{
  dataCarrierType?: DataCarrierType | null;
  name?: string | null;
  paymentTransactionType?: PaymentTransactionType | null;
  iban?: string | null;
  exitCodeList?: GenerierlaufEntryExitCode[] | null;
}>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  verarbeitungType: string,
  generierlaufId: string,
  filters: TDataCarrierPainCreationQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToDirectDebitOrBankWireListingPage(verarbeitungType, generierlaufId, filters, paginationParams));

export const generatePathToDirectDebitOrBankWireListingPage = (
  verarbeitungType: string,
  generierlaufId: string,
  filters: TDataCarrierPainCreationQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [QueryParamKey.DATA_CARRIER_TYPE]: filters?.dataCarrierType,
    [QueryParamKey.NAME]: filters?.name,
    [QueryParamKey.PAYMENT_TRANSACTION_TYPE]: filters?.paymentTransactionType,
    [QueryParamKey.IBAN]: filters?.iban,
    [QueryParamKey.EXIT_CODE_LIST]: filters?.exitCodeList,
    ...paginationParams,
  })}`;
