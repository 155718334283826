import React, { FC, useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  mapTextbausteinListToFormValues,
  TextbausteinFormValues,
  TextbausteinModel,
} from '../../../../../components/Template/PDFTemplates/templateMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import TemplateHeAbrechnung from './TemplateHeAbrechnung';
import {
  useCreateHeAbrechnungsdefinitionForFdMutation,
  useCreateHeAbrechnungsdefinitionForKsMutation,
} from '../../../gql/HeAbrDef/HeAbrDefMutations.types';
import { showSuccessMsgCreate } from '../../../../../components/message';
import { URI_SYS_SETTINGS } from '../../../../../constants/configurationUriPaths';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { mapFormValuesToHeAbrechnungsdefinitionCreate } from '../../../Form/Create/abrDefCreateFormMapper';
import { AbrechnungsdefinitionParameterFormValues } from '../../../Form/Create/abrDefParameterFormMapper';
import { AbrDefAbrKreisListingFormValues } from '../../AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import { HeOrSubAbrDefOutputOptionsFieldHelpNames } from '../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';
import HeOrSubGeneralSettingsForm from '../shared/HeOrSub/GeneralSettingForm/HeOrSubGeneralSettingsForm';
import {
  heOrSubAbrDefGeneralSettingsFormInitialValues,
  HeOrSubAbrDefGeneralSettingsFormValues,
} from '../shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';

type Props = {
  kundenSystemId: string;
  parameterFormValues: AbrechnungsdefinitionParameterFormValues;
  abrKreisListFormValues: AbrDefAbrKreisListingFormValues;
  heAbrechnungTextbausteinList: TextbausteinModel[];
  validFromFormValue: string;
  setIsValidFromValid: (validFromValid: boolean) => void;
  onBack: () => void;
  fieldHelpNames?: HeOrSubAbrDefOutputOptionsFieldHelpNames;
};

const HeAbrDefTemplateForm: FC<Props> = ({
  kundenSystemId,
  parameterFormValues,
  abrKreisListFormValues,
  heAbrechnungTextbausteinList,
  validFromFormValue,
  setIsValidFromValid,
  onBack,
  fieldHelpNames,
}) => {
  const entity = 'Abrechnungsdefinition';
  const navigate = useNavigate();

  const baseOptions = {
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      navigate(URI_SYS_SETTINGS.abrechnungsdefinitionPage);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  };

  const [createHeForFD, { loading: loadingCreateHeForFD }] = useCreateHeAbrechnungsdefinitionForFdMutation(baseOptions);
  const [createHeForKS, { loading: loadingCreateHeForKS }] = useCreateHeAbrechnungsdefinitionForKsMutation(baseOptions);

  const [heAbrDefGeneralSettingsValues, setHeAbrDefGeneralSettingsValues] = useState<HeOrSubAbrDefGeneralSettingsFormValues>(
    heOrSubAbrDefGeneralSettingsFormInitialValues
  );

  const [heAbrDefTemplateValues, setHeAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(heAbrechnungTextbausteinList)
  );

  const onSubmit = () => {
    const input = mapFormValuesToHeAbrechnungsdefinitionCreate(
      parameterFormValues,
      abrKreisListFormValues,
      heAbrDefTemplateValues,
      validFromFormValue,
      heAbrDefGeneralSettingsValues
    );
    if (!validFromFormValue) {
      setIsValidFromValid(false);
      return;
    }
    if (parameterFormValues?.firmendatenId) {
      createHeForFD({ variables: { input, firmendatenId: parameterFormValues.firmendatenId } });
    } else {
      createHeForKS({ variables: { input, kundenSystemId } });
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: (
        <HeOrSubGeneralSettingsForm
          values={heAbrDefGeneralSettingsValues}
          onSubmit={setHeAbrDefGeneralSettingsValues}
          fieldHelpNames={{
            displayKontonummer: fieldHelpNames?.displayKontonummer,
            rueckstaendeAnzeigen: fieldHelpNames?.rueckstaendeAnzeigen,
            rueckstaendeInAbrechnungsergebnis: fieldHelpNames?.rueckstaendeInAbrechnungsergebnis,
          }}
        />
      ),
    },
    {
      key: '2',
      label: 'Texte',
      children: (
        <TemplateHeAbrechnung
          values={heAbrDefTemplateValues}
          onTemplateChange={setHeAbrDefTemplateValues}
          abrDefGeneralSettingsValues={heAbrDefGeneralSettingsValues}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />
      <FormButtons
        onCancel={onBack}
        cancelText="Zurück"
        updateMode={false}
        isOkLoading={loadingCreateHeForFD || loadingCreateHeForKS}
        onOk={onSubmit}
        okText="Speichern"
      />
    </>
  );
};

export default HeAbrDefTemplateForm;
