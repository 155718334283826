import React, { FC } from 'react';
import { Drawer, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../../../../hooks/useToggle';
import BelegnummernkreisForm from '../../Form/BelegnummernkreisForm';
import { showSuccessMsgDelete } from '../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../helpers/apolloHelper';
import { useDeleteBelegnummernkreisDefinitionMutation } from '../../../../../../BelegnummernkreisDefinition/gql/BelegnummernkreisDefinitionMutations.types';
import HistoryModal, { HistoryType } from '../../../../../../History/shared/HistoryModal';
import RechnungsAusstellerBelegnummernkreisChangeHistoryListingTable from '../../RechnungsAusstellerBelegnummernkreisChangeHistoryListingTable';
import { radixActionStyles } from '../../../../../../../helpers/radixIconsStyles';
import { BelegnummernkreisDefinitionFieldsFragment } from '../../../../../../BelegnummernkreisDefinition/gql/BelegnummernkreisDefinitionFragments.types';

type BelegnummernkreisDefinitionTableActionsProps = {
  belegnummernkreisDefinition: BelegnummernkreisDefinitionFieldsFragment;
  onAction: () => void;
  rechtstraegerId: string;
};

const BelegnummernkreisDefinitionTableActions: FC<BelegnummernkreisDefinitionTableActionsProps> = ({
  onAction,
  belegnummernkreisDefinition,
  rechtstraegerId,
}) => {
  const [isCollapsed, onCollapse] = useToggle();
  const entity = 'Belegnummernkreisdefinition';
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const onSuccess = () => {
    onCollapse();
    onAction();
  };

  const [runDelete] = useDeleteBelegnummernkreisDefinitionMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    variables: {
      rechtstraegerId,
      belegnummernkreisDefinitionId: belegnummernkreisDefinition.belegnummernkreisDefinitionId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });
  /*
  const [runArchive] = useArchiveBelegnummernkreisDefinitionMutation({
    variables: {
      rechtstraegerId,
      belegnummernkreisDefinitionId: belegnummernkreisDefinition.belegnummernkreisDefinitionId,
    },
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveBelegnummernkreisDefinitionMutation({
    variables: {
      rechtstraegerId,
      belegnummernkreisDefinitionId: belegnummernkreisDefinition.belegnummernkreisDefinitionId,
    },
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });
  */

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    /*    {
      key: '2',
      label: !isStatusActive(belegnummernkreisDefinition.status) ? 'Reaktivieren' : 'Archivieren',
      onClick: showConfirmArchive(runArchive, runUnarchive, belegnummernkreisDefinition),
      icon: <ArchiveIcon style={radixActionStyles} />,
    },*/
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: () => showConfirmDelete(belegnummernkreisDefinition, runDelete),
      disabled: belegnummernkreisDefinition.belegnummernkreisList.length > 0,
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <Drawer title="Belegnummernkreis Definition bearbeiten" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <BelegnummernkreisForm
          onSuccess={onSuccess}
          rechtstraegerId={rechtstraegerId}
          onCancel={onCollapse}
          belegnummernkreisDefinition={belegnummernkreisDefinition}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <RechnungsAusstellerBelegnummernkreisChangeHistoryListingTable
          rechtstraegerId={rechtstraegerId}
          belegnummernkreisDefinitionId={belegnummernkreisDefinition.belegnummernkreisDefinitionId}
        />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (belegnummernkreisDefinition: BelegnummernkreisDefinitionFieldsFragment, runDelete: () => void) => {
  Modal.confirm({
    title: `Möchten Sie die Belegnummernkreis Definition löschen?`,
    content: `${belegnummernkreisDefinition.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};
/*
const showConfirmArchive =
  (runArchive: () => void, runUnarchive: () => void, belegnummernkreisDefinition: BelegnummernkreisDefinitionFieldsFragment) => () => {
    Modal.confirm({
      title: !isStatusActive(belegnummernkreisDefinition.status)
        ? `Möchten Sie die Belegnummernkreis Definition reaktivieren?`
        : `Möchten Sie die Belegnummernkreis Definition archivieren?`,
      content: belegnummernkreisDefinition.bezeichnung,
      okText: !isStatusActive(belegnummernkreisDefinition.status) ? 'Reaktivieren' : 'Archivieren',
      cancelText: 'Nein',
      onOk() {
        if (isStatusActive(belegnummernkreisDefinition.status)) {
          return runArchive();
        } else {
          return runUnarchive();
        }
      },
    });
  };
*/
export default BelegnummernkreisDefinitionTableActions;
