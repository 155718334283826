import { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useEbicsVersionListQuery } from '../Ebics/gql/EbicsVersionQueries.types';

type Props = {
  name: string;
} & Omit<SelectProps, 'name' | 'id' | 'loading'>;

const EbicsVersionFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useEbicsVersionListQuery();
  const versionList = data?.getEbicsVersionList.data ?? [];

  return (
    <Select {...restProps} size="small" name={name} id={name} loading={loading}>
      {versionList.map((version) => (
        <Select.Option key={version.value} value={version.value}>
          {version.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default EbicsVersionFormSelect;
