import React, { FC } from 'react';
import { Empty, Tabs, TabsProps } from 'antd';
import vorschreibungPositionTableColumns from './vorschreibungPositionTableColumns';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { Auftrag, AuftragsPosition } from '../../../types';

type Props = {
  auftrag: Auftrag;
  loading: boolean;
};

const VorschreibungPositionListingTabs: FC<Props> = ({ auftrag, loading }) => {
  const sortedAuftragsPositionList = [...auftrag.auftragsPositionList].sort(sortByMultipleCriteria);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Positionen',
      children: (
        <TabPaneContent distanceToTopBorder="large">
          <TableWithColSelector<AuftragsPosition>
            loading={loading}
            locale={{
              emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
            }}
            size="small"
            dataSource={sortedAuftragsPositionList}
            columns={vorschreibungPositionTableColumns(auftrag.auftragsKopf.aussteller.rechtstraegerId, auftrag.buchungIdList)}
            rowKey={(record) => record.auftragsPositionId}
            filterIdentifier="vorschreibungposition"
          />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

const sortByMultipleCriteria = (a: AuftragsPosition, b: AuftragsPosition) => {
  if (a.objekt.kurzBezeichnung === b.objekt.kurzBezeichnung) {
    if (a.bestandseinheit.bezeichnung === b.bestandseinheit.bezeichnung) {
      return a.vorschreibungspositionBezeichnung.localeCompare(b.vorschreibungspositionBezeichnung);
    }
    return a.bestandseinheit.bezeichnung.localeCompare(b.bestandseinheit.bezeichnung, undefined, { numeric: true, sensitivity: 'base' });
  }
  return a.objekt.kurzBezeichnung.localeCompare(b.objekt.kurzBezeichnung);
};

export default VorschreibungPositionListingTabs;
