import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import VorschreibungListingForm from '../../features/Vorschreibung/Listing/VorschreibungListingForm';

const VorschreibungPage = () => {
  return (
    <>
      <Helmet>
        <title>Vorschreibungen</title>
      </Helmet>
      <PageLayout>
        <VorschreibungListingForm />
      </PageLayout>
    </>
  );
};

export default VorschreibungPage;
