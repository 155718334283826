import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { Konto } from '../../../../types';
import KontoBezug from './KontoBezug';
import { Spacer } from '../../../../components/Grid';
import TabPaneContent from '../../../../components/Tabs/TabPaneContent';

type Props = {
  konto: Konto;
};

const KontoBezugTab: FC<Props> = ({ konto }) => (
  <TabPaneContent distanceToTopBorder="small">
    <Typography.Title level={4} style={{ marginRight: 8 }}>
      Bezüge
    </Typography.Title>
    <Spacer />
    <Space align="start" style={{ width: '100%' }}>
      <KontoBezug konto={konto} />
    </Space>
  </TabPaneContent>
);

export default KontoBezugTab;
