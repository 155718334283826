import React, { FC } from 'react';
import { Tag } from 'antd';
import { useFirmendatenKurzBezeichnungQuery } from '../Breadcrumb/gql/KurzbezeichnungQueries.types';
import { LoadingCol } from '../../components/Loading';
import DataWithShortenedText from '../../components/Helpers/DataWithShortenedText';

type Props = {
  firmendatenId: string;
  maxLength?: number;
};

const FirmendatenKurzBezeichnung: FC<Props> = ({ firmendatenId, maxLength }) => {
  const { data, loading } = useFirmendatenKurzBezeichnungQuery({ variables: { firmendatenId } });
  const kurzbezeichnung = data?.getFirmendaten.data.kurzBezeichnung;

  return (
    <LoadingCol isLoading={loading}>
      {kurzbezeichnung ? <DataWithShortenedText text={kurzbezeichnung} maxTextLength={maxLength ?? kurzbezeichnung.length} /> : <Tag>System</Tag>}
    </LoadingCol>
  );
};

export default FirmendatenKurzBezeichnung;
