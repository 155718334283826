import React, { FC } from 'react';
import { ValuePreservationType } from '../../../../../../../../../types';
import { isValuePreservationTypeIndexAnnual, isValuePreservationTypeIndexThreshold } from '../indexationAgreementHelpers';
import FormattedDecimalFormInput from '../../../../../../../../../components/Input-antd/FormattedDecimalFormInput';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  name: string;
  valuePreservationType: ValuePreservationType;
  isIndexOldPreliminary: boolean;
  fieldHelp?: string | null;
};

const IndexOldFormItem: FC<Props> = ({ name, valuePreservationType, isIndexOldPreliminary, fieldHelp }) => {
  const showPreliminaryLabel =
    (isValuePreservationTypeIndexThreshold(valuePreservationType) || isValuePreservationTypeIndexAnnual(valuePreservationType)) &&
    isIndexOldPreliminary;

  const label = showPreliminaryLabel ? `Index alt (Vorläufiger Wert)` : 'Index alt';

  return (
    <FormItemWithFieldHelp name={name} label={label} fieldHelp={fieldHelp}>
      <FormattedDecimalFormInput name={name} size="small" controls={false} min={0} style={{ width: '100%' }} fractionDigits={8} />
    </FormItemWithFieldHelp>
  );
};

export default IndexOldFormItem;
