import React, { FC } from 'react';
import { Badge, Tag, Typography } from 'antd';
import { BankDetailsFibuVerwendung, BankDetailsFibuVerwendungTuple } from '../../../../../../types';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';

type KontoWithNonRepFondsVerwendungBadgeProps = {
  kontoBezeichnung: string;
  kontoNummer: string;
  verwendung: BankDetailsFibuVerwendungTuple;
  isInSelect: boolean;
  /** mandatpry as bank names can be very long */
  maxBankNameLength: number;
};

const KontoWithNonRepFondsVerwendungBadge: FC<KontoWithNonRepFondsVerwendungBadgeProps> = ({
  kontoBezeichnung,
  kontoNummer,
  verwendung,
  isInSelect,
  maxBankNameLength,
}) => {
  const isVerwendungRepFonds = verwendung.value === BankDetailsFibuVerwendung.RepFonds;

  const kontoNummerAndName = (name: string) => (
    <Typography.Text>
      <Tag>{kontoNummer}</Tag> {name}
    </Typography.Text>
  );
  return (
    <DataWithShortenedText
      maxTextLength={maxBankNameLength}
      text={kontoBezeichnung}
      extraTexts={!isVerwendungRepFonds ? ['Konto nicht für Rep.Fonds-Verwendung freigegeben'] : undefined}
    >
      {(shortenedText) =>
        isVerwendungRepFonds ? (
          kontoNummerAndName(shortenedText)
        ) : (
          <Badge dot status="warning" offset={isInSelect ? [5, 5] : undefined}>
            {kontoNummerAndName(shortenedText)}
          </Badge>
        )
      }
    </DataWithShortenedText>
  );
};

export default KontoWithNonRepFondsVerwendungBadge;
