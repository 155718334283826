import React, { FC } from 'react';
import { Typography } from 'antd';
import { useToggle } from '../../../../hooks/useToggle';

type Props = {
  text: string;
  maxLength?: number;
};

const TicketHistoryExpandableText: FC<Props> = ({ text, maxLength }) => {
  const [isShortened, toggleIsShortened] = useToggle(true);
  const shortenedText = typeof maxLength === 'number' ? text.slice(0, maxLength) : text;
  const shouldBeShortened = shortenedText !== text;

  return (
    <>
      <Typography.Text style={{ whiteSpace: 'pre-line' }}>{isShortened ? shortenedText : text}</Typography.Text>
      {shouldBeShortened && (
        <>
          {' '}
          <a
            onClick={(e) => {
              toggleIsShortened();
              e.preventDefault();
            }}
          >
            [...]
          </a>
        </>
      )}
    </>
  );
};

export default TicketHistoryExpandableText;
