import * as Types from '../../../../../../types';

import { gql } from '@apollo/client';
import { BeVertragVorschreibungsPositionWertFieldsFragmentDoc } from './BeVertragVorschreibungspositionFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBeVertragVorschreibungspositionWertMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  vorschreibungspositionWert: Types.VorschreibungspositionWertInput;
}>;

export type CreateBeVertragVorschreibungspositionWertMutation = {
  createBeVertragVorschreibungspositionWert: {
    data: {
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      brutto: number;
      current: boolean;
      validFrom: string;
      netto: number;
      steuersatz: number;
      ust: number;
      deletable: boolean;
      vertragVorschreibungspositionWertId: string;
      basis: { text: string; value: Types.VorschreibungspositionBasis };
      ustKategorie?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      ustKategorieEntry?: { ustKategorieEntryId?: string | null } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      changeReason?:
        | {
            __typename: 'VPosWertChangeReasonBudgeting';
            budgetingId: string;
            bestandseinheitId: string;
            objektId: string;
            vertragId: string;
            vorschreibungspositionId: string;
            text: string;
            type: Types.ChangeReason;
          }
        | {
            __typename: 'VPosWertChangeReasonIndexedValue';
            bestandseinheitId: string;
            indexationAgreementId: string;
            objektId: string;
            vertragId: string;
            vertragVposIndexedValueId: string;
            vorschreibungspositionId: string;
            text: string;
            type: Types.ChangeReason;
          }
        | { __typename: 'VPosWertChangeReasonUst'; text: string; type: Types.ChangeReason }
        | null;
    };
  };
};

export type UpdateBeVertragVorschreibungspositionWertMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  vorschreibungspositionWertId: Types.Scalars['ID']['input'];
  vorschreibungspositionWert: Types.VorschreibungspositionWertInput;
}>;

export type UpdateBeVertragVorschreibungspositionWertMutation = {
  updateBeVertragVorschreibungspositionWert: {
    data: {
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      brutto: number;
      current: boolean;
      validFrom: string;
      netto: number;
      steuersatz: number;
      ust: number;
      deletable: boolean;
      vertragVorschreibungspositionWertId: string;
      basis: { text: string; value: Types.VorschreibungspositionBasis };
      ustKategorie?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      ustKategorieEntry?: { ustKategorieEntryId?: string | null } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      changeReason?:
        | {
            __typename: 'VPosWertChangeReasonBudgeting';
            budgetingId: string;
            bestandseinheitId: string;
            objektId: string;
            vertragId: string;
            vorschreibungspositionId: string;
            text: string;
            type: Types.ChangeReason;
          }
        | {
            __typename: 'VPosWertChangeReasonIndexedValue';
            bestandseinheitId: string;
            indexationAgreementId: string;
            objektId: string;
            vertragId: string;
            vertragVposIndexedValueId: string;
            vorschreibungspositionId: string;
            text: string;
            type: Types.ChangeReason;
          }
        | { __typename: 'VPosWertChangeReasonUst'; text: string; type: Types.ChangeReason }
        | null;
    };
  };
};

export type DeleteBeVertragVorschreibunspositionWertMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  vorschreibungspositionWertId: Types.Scalars['ID']['input'];
}>;

export type DeleteBeVertragVorschreibunspositionWertMutation = {
  deleteBeVertragVorschreibungspositionWert: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateBeVertragVorschreibungspositionWertDocument = gql`
  mutation CreateBeVertragVorschreibungspositionWert(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $vorschreibungspositionWert: VorschreibungspositionWertInput!
  ) {
    createBeVertragVorschreibungspositionWert(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      vorschreibungspositionWert: $vorschreibungspositionWert
    ) {
      data {
        ...BeVertragVorschreibungsPositionWertFields
      }
    }
  }
  ${BeVertragVorschreibungsPositionWertFieldsFragmentDoc}
`;
export function useCreateBeVertragVorschreibungspositionWertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBeVertragVorschreibungspositionWertMutation,
    CreateBeVertragVorschreibungspositionWertMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBeVertragVorschreibungspositionWertMutation, CreateBeVertragVorschreibungspositionWertMutationVariables>(
    CreateBeVertragVorschreibungspositionWertDocument,
    options
  );
}
export type CreateBeVertragVorschreibungspositionWertMutationHookResult = ReturnType<typeof useCreateBeVertragVorschreibungspositionWertMutation>;
export type CreateBeVertragVorschreibungspositionWertMutationResult = Apollo.MutationResult<CreateBeVertragVorschreibungspositionWertMutation>;
export type CreateBeVertragVorschreibungspositionWertMutationOptions = Apollo.BaseMutationOptions<
  CreateBeVertragVorschreibungspositionWertMutation,
  CreateBeVertragVorschreibungspositionWertMutationVariables
>;
export const UpdateBeVertragVorschreibungspositionWertDocument = gql`
  mutation UpdateBeVertragVorschreibungspositionWert(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $vorschreibungspositionWertId: ID!
    $vorschreibungspositionWert: VorschreibungspositionWertInput!
  ) {
    updateBeVertragVorschreibungspositionWert(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      vorschreibungspositionWertId: $vorschreibungspositionWertId
      vorschreibungspositionWert: $vorschreibungspositionWert
    ) {
      data {
        ...BeVertragVorschreibungsPositionWertFields
      }
    }
  }
  ${BeVertragVorschreibungsPositionWertFieldsFragmentDoc}
`;
export function useUpdateBeVertragVorschreibungspositionWertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBeVertragVorschreibungspositionWertMutation,
    UpdateBeVertragVorschreibungspositionWertMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBeVertragVorschreibungspositionWertMutation, UpdateBeVertragVorschreibungspositionWertMutationVariables>(
    UpdateBeVertragVorschreibungspositionWertDocument,
    options
  );
}
export type UpdateBeVertragVorschreibungspositionWertMutationHookResult = ReturnType<typeof useUpdateBeVertragVorschreibungspositionWertMutation>;
export type UpdateBeVertragVorschreibungspositionWertMutationResult = Apollo.MutationResult<UpdateBeVertragVorschreibungspositionWertMutation>;
export type UpdateBeVertragVorschreibungspositionWertMutationOptions = Apollo.BaseMutationOptions<
  UpdateBeVertragVorschreibungspositionWertMutation,
  UpdateBeVertragVorschreibungspositionWertMutationVariables
>;
export const DeleteBeVertragVorschreibunspositionWertDocument = gql`
  mutation DeleteBeVertragVorschreibunspositionWert(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $vorschreibungspositionWertId: ID!
  ) {
    deleteBeVertragVorschreibungspositionWert(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      vorschreibungspositionWertId: $vorschreibungspositionWertId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBeVertragVorschreibunspositionWertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBeVertragVorschreibunspositionWertMutation,
    DeleteBeVertragVorschreibunspositionWertMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBeVertragVorschreibunspositionWertMutation, DeleteBeVertragVorschreibunspositionWertMutationVariables>(
    DeleteBeVertragVorschreibunspositionWertDocument,
    options
  );
}
export type DeleteBeVertragVorschreibunspositionWertMutationHookResult = ReturnType<typeof useDeleteBeVertragVorschreibunspositionWertMutation>;
export type DeleteBeVertragVorschreibunspositionWertMutationResult = Apollo.MutationResult<DeleteBeVertragVorschreibunspositionWertMutation>;
export type DeleteBeVertragVorschreibunspositionWertMutationOptions = Apollo.BaseMutationOptions<
  DeleteBeVertragVorschreibunspositionWertMutation,
  DeleteBeVertragVorschreibunspositionWertMutationVariables
>;
