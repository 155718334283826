import { FormFileReference } from '../../../../components/FileUpload/formHelpers';
import {
  DocumentHeaderAndFooterImage,
  DocumentHeaderAndFooterImageValue,
  DocumentHeaderAndFooterType,
} from '../../../../pages/PDFTemplates/templates/shared/types';

import { DocumentHeaderAndFooterImageAlignment } from '../../../../types';

export const getBelegTexteValue = (
  belegTextAsHtml?: any,
  belegBildFileIdURL?: string | null | DocumentHeaderAndFooterImageValue,
  belegBildURL?: string | null | DocumentHeaderAndFooterImageValue
) => {
  if (belegBildURL) {
    return belegBildURL;
  }
  if (belegBildFileIdURL) {
    return belegBildFileIdURL;
  }
  if (belegTextAsHtml) {
    return belegTextAsHtml;
  }
  return undefined;
};

export const getBelegTexteWithTypeBild = (value?: DocumentHeaderAndFooterImageValue): DocumentHeaderAndFooterImage | undefined => {
  return { value, type: DocumentHeaderAndFooterType.IMAGE };
};

export const createURLForBelegTexteFile = (
  firmendatenId: string,
  belegKopfFuss?: FormFileReference,
  alignment?: DocumentHeaderAndFooterImageAlignment | undefined | null
) => {
  if (belegKopfFuss?.file) {
    return {
      value: URL.createObjectURL(belegKopfFuss.file),
      alignment,
    };
  }

  return getURLToBelegTexteBildFileId(firmendatenId, belegKopfFuss?.fileId, alignment);
};

export const getURLToBelegTexteBildFileId = (
  firmendatenId: string,
  fileId?: string | null,
  alignment?: DocumentHeaderAndFooterImageAlignment | undefined | null
) => {
  if (!fileId) return fileId;
  return {
    value: getFirmendatenDownloadURL(firmendatenId, fileId),
    alignment,
  };
};

export const getFirmendatenDownloadURL = (firmendatenId: string, fileId: string) => `/api/firmendaten/${firmendatenId}/files/${fileId}/download`;
