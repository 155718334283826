import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from '../filtersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FibuBaStatusSelect from '../../../../BookingInstruction/shared/Filters/FibuBaStatusSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
};

const StatusSelectFormItem: FC<Props> = ({ formikProps }) => {
  return (
    <FormItemWithoutColon name={filtersFormFields.status} label={listingLabelList.status}>
      <FibuBaStatusSelect name={filtersFormFields.status} onChange={formikProps.submitForm} />
    </FormItemWithoutColon>
  );
};

export default StatusSelectFormItem;
