import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { generateQueryStringsForDocumentFilter } from '../../helpers/documentHelper';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';

export type DocumentListingFiltersFormValues = {
  documentType?: string;
  invoiceIssuerId?: string;
  invoiceRecipientId?: string;
  invoiceNumber?: string;
  invoiceDateFromInclusive?: string;
  invoiceDateToInclusive?: string;
  dunningReferenceDateFromInclusive?: string;
  dunningReferenceDateToInclusive?: string;
  homeownerId?: string;
  objektId?: string;
  bestandseinheitId?: string;
  budgetingYear?: string;
};

export type TQueryParams = QueryParams<DocumentListingFiltersFormValues>;

export const mapQueryParamsToFormValues = (values: TQueryParams): DocumentListingFiltersFormValues => ({
  documentType: values.documentType,
  invoiceIssuerId: values.invoiceIssuerId,
  invoiceRecipientId: values.invoiceRecipientId,
  invoiceNumber: values.invoiceNumber,
  invoiceDateFromInclusive: values.invoiceDateFromInclusive,
  invoiceDateToInclusive: values.invoiceDateToInclusive,
  dunningReferenceDateFromInclusive: values.dunningReferenceDateFromInclusive,
  dunningReferenceDateToInclusive: values.dunningReferenceDateToInclusive,
  homeownerId: values.homeownerId,
  objektId: values.objektId,
  bestandseinheitId: values.bestandseinheitId,
  budgetingYear: values.budgetingYear ? dayjsCustom().year(Number(values.budgetingYear)).toString() : undefined,
});

export const mapFormValuesToQueryParams = (values: DocumentListingFiltersFormValues): TQueryParams => {
  return {
    documentType: values.documentType,
    invoiceIssuerId: values.invoiceIssuerId,
    invoiceRecipientId: values.invoiceRecipientId,
    invoiceNumber: values.invoiceNumber,
    invoiceDateFromInclusive: values.invoiceDateFromInclusive ? mapFormDateValueToDateString(values.invoiceDateFromInclusive) : undefined,
    invoiceDateToInclusive: values.invoiceDateToInclusive ? mapFormDateValueToDateString(values.invoiceDateToInclusive) : undefined,
    dunningReferenceDateFromInclusive: values.dunningReferenceDateFromInclusive
      ? mapFormDateValueToDateString(values.dunningReferenceDateFromInclusive)
      : undefined,
    dunningReferenceDateToInclusive: values.dunningReferenceDateToInclusive
      ? mapFormDateValueToDateString(values.dunningReferenceDateToInclusive)
      : undefined,
    homeownerId: values.homeownerId,
    objektId: values.objektId,
    bestandseinheitId: values.bestandseinheitId,
    budgetingYear: values.budgetingYear ? dayjsCustom(values.budgetingYear).year().toString() : undefined,
  };
};

export const documentListingFiltersFormFields: FormFields<DocumentListingFiltersFormValues> = {
  documentType: 'documentType',
  invoiceIssuerId: 'invoiceIssuerId',
  invoiceRecipientId: 'invoiceRecipientId',
  invoiceNumber: 'invoiceNumber',
  invoiceDateFromInclusive: 'invoiceDateFromInclusive',
  invoiceDateToInclusive: 'invoiceDateToInclusive',
  dunningReferenceDateFromInclusive: 'dunningReferenceDateFromInclusive',
  dunningReferenceDateToInclusive: 'dunningReferenceDateToInclusive',
  homeownerId: 'homeownerId',
  objektId: 'objektId',
  bestandseinheitId: 'bestandseinheitId',
  budgetingYear: 'budgetingYear',
};

export const updateQueryParams = (
  navigate: (path: string) => void,
  pathname: string,
  filters: TQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  navigate(
    `${pathname}${generateQueryStringsForDocumentFilter(
      filters.documentType,
      filters.invoiceIssuerId,
      filters.invoiceRecipientId,
      filters.invoiceNumber,
      filters.invoiceDateFromInclusive,
      filters.invoiceDateToInclusive,
      filters.dunningReferenceDateFromInclusive,
      filters.dunningReferenceDateToInclusive,
      filters.homeownerId,
      filters.objektId,
      filters.bestandseinheitId,
      filters.budgetingYear,
      paginationParams?.currentPage,
      paginationParams?.pageSize
    )}`
  );
