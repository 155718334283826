import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum filtersQueryParamKey {
  EMAIL_ADDRESS = 'emailAddress',
  EXIT_CODE_LIST = 'exitCodeList',
  OBJEKT_ID = 'objektId',
  RECIPIENT_ID = 'recipientId',
  VERTRAGSPARTNER_ID = 'vertragspartnerId',
}

export type filtersQueryParams = QueryParams<{
  emailAddress: string;
  exitCodeList: GenerierlaufEntryExitCode[];
  objektId: string;
  recipientId: string;
  vertragspartnerId: string;
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: filtersQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => {
  navigate(generatePathToDetailsPage(verarbeitungType, generierlaufId, filters, paginationParams));
};

const generatePathToDetailsPage = (
  verarbeitungType: string,
  generierlaufId: string,
  filters: filtersQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [filtersQueryParamKey.EMAIL_ADDRESS]: filters?.emailAddress,
    [filtersQueryParamKey.EXIT_CODE_LIST]: filters?.exitCodeList,
    [filtersQueryParamKey.OBJEKT_ID]: filters?.objektId,
    [filtersQueryParamKey.RECIPIENT_ID]: filters?.recipientId,
    [filtersQueryParamKey.VERTRAGSPARTNER_ID]: filters?.vertragspartnerId,
    ...paginationParams,
  })}`;
