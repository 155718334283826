import React from 'react';
import { Helmet } from 'react-helmet-async';
import KontoblattListing from '../../features/Kontoblatt/KontoblattListing';
import { PageLayout } from '../../components/LayoutAndromeda';

const KontoblattPage = () => {
  return (
    <>
      <Helmet>
        <title>Kontoblatt</title>
      </Helmet>
      <PageLayout>
        <KontoblattListing />
      </PageLayout>
    </>
  );
};

export default KontoblattPage;
