import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import {
  BkOrWeAbrDefFieldHelpNames,
  BkOrWeAbrDefObjektOutputOptionsFieldHelpNames,
  BkOrWeAbrDefTopOutputOptionsFieldHelpNames,
} from './abrDefFieldHelpMapper';

export const useWeAbrDefFieldHelp = (): BkOrWeAbrDefFieldHelpNames => {
  const getFieldHelpText = useGetAppFieldHelpText<'WeAbrechnungsdefinition'>('WeAbrechnungsdefinition');

  const fieldHelpAbrDefAbrechkreisList = getFieldHelpText('WeAbrechnungsdefinition.abrechdefAbrechkreisList');

  const fieldHelpNamesObjekt: BkOrWeAbrDefObjektOutputOptionsFieldHelpNames = {
    buchungsdetails: getFieldHelpText('WeAbrechnungsdefinition.objektAbrechnungOutputOptions.buchungsdetails'),
    consolidateEinnahmen: getFieldHelpText('WeAbrechnungsdefinition.objektAbrechnungOutputOptions.consolidateEinnahmen'),
    displayEinnahmen: getFieldHelpText('WeAbrechnungsdefinition.objektAbrechnungOutputOptions.displayEinnahmen'),
    displayKontonummer: getFieldHelpText('WeAbrechnungsdefinition.objektAbrechnungOutputOptions.displayKontonummer'),
  };

  const fieldHelpNamesTop: BkOrWeAbrDefTopOutputOptionsFieldHelpNames = {
    considerRueckstand: getFieldHelpText('WeAbrechnungsdefinition.topAbrechnungOutputOptions.considerRueckstand'),
    displayDueDate: getFieldHelpText('WeAbrechnungsdefinition.topAbrechnungOutputOptions.displayDueDate'),
    displayRueckstandOtherPersons: getFieldHelpText('WeAbrechnungsdefinition.topAbrechnungOutputOptions.displayRueckstandOtherPersons'),
    displayZahlungsverlauf: getFieldHelpText('WeAbrechnungsdefinition.topAbrechnungOutputOptions.displayZahlungsverlauf'),
    dueDateDisplayOptionMieter: {
      month: getFieldHelpText('WeAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionMieter.month'),
      type: getFieldHelpText('WeAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionMieter.type'),
    },
    dueDateDisplayOptionEigentuemerGuthaben: {
      month: getFieldHelpText('WeAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerGuthaben.month'),
      type: getFieldHelpText('WeAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerGuthaben.type'),
    },
    dueDateDisplayOptionEigentuemerNachzahlung: {
      month: getFieldHelpText('WeAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerNachzahlung.month'),
      type: getFieldHelpText('WeAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerNachzahlung.type'),
    },
    rueckstandOtherPersonsThreshold: getFieldHelpText('WeAbrechnungsdefinition.topAbrechnungOutputOptions.rueckstandOtherPersonsThreshold'),
  };

  return { fieldHelpAbrDefAbrechkreisList, fieldHelpNamesObjekt, fieldHelpNamesTop };
};
