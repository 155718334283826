import { generatePath } from 'react-router-dom';
import { URI_SYS_SETTINGS } from '../../constants/configurationUriPaths';
import { MahndefinitionListEntry } from '../../types';

export type MahndefinitionListEntryWithStatus = MahndefinitionListEntry & { status: NonNullable<MahndefinitionListEntry['status']> };

export const URI_SYS_SETTINGS_MAHNDEFINITION_PAGE = {
  createPage: `${URI_SYS_SETTINGS.mahndefinitionPage}/new`,
  updatePage: `${URI_SYS_SETTINGS.mahndefinitionPage}/:mahndefinitionId`,
};

export const generatePathToMahndefinitionUpdatePage = (mahndefinitionId: string) =>
  generatePath(URI_SYS_SETTINGS_MAHNDEFINITION_PAGE.updatePage, { mahndefinitionId });

export default URI_SYS_SETTINGS_MAHNDEFINITION_PAGE;
