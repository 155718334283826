import React, { FC } from 'react';
import { Input } from 'formik-antd';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  fieldHelp?: string | null;
};

const CompanyRegistrationNumberFormPart: FC<Props> = ({ fieldHelp }) => (
  <FormItemWithFieldHelp name="companyRegistrationNumber" label="Firmenbuchnummer" fieldHelp={fieldHelp}>
    <Input id="companyRegistrationNumber" name="companyRegistrationNumber" placeholder="Firmenbuchnummer" />
  </FormItemWithFieldHelp>
);

export default CompanyRegistrationNumberFormPart;
