import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, Flex } from 'rebass';
import { CommentOutlined, MailOutlined, MenuOutlined, PaperClipOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Collapse, CollapsePanelProps, Divider, Drawer, Tooltip, Typography } from 'antd';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TicketPriorityIcon from '../../shared/TicketPriorityIcon';
import OutdatedData from '../../../../components/Helpers/OutdatedData';
import StatusBadge from '../../../../components/Status/StatusBadge';
import MitarbeiterTooltip from '../../../../components/Card/MitarbeiterTooltip';
import TicketSubTicketListingActions from './TicketSubTicketListingActions';
import { TicketBase } from '../../../../types';
import TicketReminder from '../../shared/TicketReminder';
import MitarbeiterOrUserName from '../../../../components/Card/MitarbeiterOrUserName';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { useToggle } from '../../../../hooks/useToggle';
import TicketParticipantsUpdateForm from '../../Form/TicketParticipantsUpdateForm/TicketParticipantsUpdateForm';
import TicketCommentListing from '../Comment/TicketCommentListing';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';
import TicketAttachmentsUpdateForm from '../../Form/TicketAttachmentsUpdateForm/TicketAttachmentsUpdateForm';
import TicketActionButton from '../../shared/TicketActionButton';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { isTicketArchived } from '../../ticketHelpers';
import TicketEmailsUpdateForm from '../../Form/TicketEmailsUpdateForm/TicketEmailsUpdateForm';

type Props = {
  index: number;
  ticket: TicketBase;
  activeKeys: string[];
  setActiveKeys: Dispatch<SetStateAction<string[]>>;
  refetch: () => Promise<unknown>;
  key: string;
} & Omit<CollapsePanelProps, 'header'>;

const TicketSubTicketListingCollapsePanel: FC<Props> = ({ key, ticket, index, activeKeys, setActiveKeys, refetch, ...panelProps }) => {
  const [isParticipantsDrawerOpen, toggleIsParticipantsDrawerOpen] = useToggle();
  const [isCommentsDrawerOpen, toggleIsCommentsDrawerOpen] = useToggle();
  const [isAttachmentsDrawerOpen, toggleIsAttachmentsDrawerOpen] = useToggle();
  const [isEmailsDrawerOpen, toggleIsEmailsDrawerOpen] = useToggle();

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({ id: ticket.ticketId });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const classes = 'collapse-header-draggable-wrap' + (isDragging ? ' is-dragging' : '');

  const isArchived = isTicketArchived(ticket);

  return (
    <div className={classes} ref={setNodeRef} style={style} {...attributes}>
      <Collapse.Panel
        {...panelProps}
        key={key}
        showArrow={false}
        collapsible="icon"
        header={
          <Flex style={{ gap: 8, paddingInline: 4 }}>
            <Box minWidth="auto">
              <OutdatedData isOutdated={isArchived}>
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ ...(!isArchived ? { touchAction: 'none', cursor: 'move' } : {}) }}
                  {...(!isArchived ? listeners : {})}
                />
              </OutdatedData>
            </Box>
            {/*Clickable area to toggle collapse*/}
            <Flex
              style={{ gap: 8, flex: '1', ...(!isArchived ? { cursor: 'pointer' } : {}) }}
              onClick={() => {
                if (isArchived) return;
                setActiveKeys((keys) =>
                  keys.includes(ticket.ticketId) ? keys.filter((key) => key !== ticket.ticketId) : [...keys, ticket.ticketId]
                );
              }}
            >
              <Box minWidth="20px" style={{ textAlign: 'right' }}>
                <OutdatedData isOutdated={isArchived}>
                  <Typography.Text style={{ color: 'rgba(0,0,0,0.45)' }}>{index + 1}.</Typography.Text>
                </OutdatedData>
              </Box>
              <Box minWidth="auto">
                <OutdatedData isOutdated={isArchived}>
                  <TicketPriorityIcon priority={ticket.priority} />
                </OutdatedData>
              </Box>
              <Box width="100%">
                <OutdatedData isOutdated={isArchived}>
                  <Typography.Text style={{ flex: '1', width: '100%' }}>{ticket.title}</Typography.Text> -{' '}
                  <Typography.Text type="secondary">
                    Fällig <Tooltip title={<CustomFormattedDate value={ticket.dueDate} />}>{relativeTime(ticket.dueDate)}</Tooltip>
                  </Typography.Text>
                </OutdatedData>
              </Box>
            </Flex>
            <Flex style={{ gap: 8 }} alignItems="center">
              <Box width="100px">
                <StatusBadge entityStatus={ticket.ticketStatus} showStatusDescriptionInTooltip={false} />
              </Box>

              {/* Participants */}
              <TicketActionButton
                label="Beteiligte"
                icon={<TeamOutlined />}
                onClick={toggleIsParticipantsDrawerOpen}
                disabled={isArchived}
                badgeCount={ticket.participantCount}
              >
                <Drawer
                  width={720}
                  title={`Beteiligte der Unteraufgabe "${ticket.title}" bearbeiten`}
                  onClose={toggleIsParticipantsDrawerOpen}
                  open={isParticipantsDrawerOpen}
                  destroyOnClose
                >
                  <TicketParticipantsUpdateForm
                    ticketId={ticket.ticketId}
                    onSuccess={() => {
                      toggleIsParticipantsDrawerOpen();
                      refetch();
                    }}
                    onCancel={toggleIsParticipantsDrawerOpen}
                  />
                </Drawer>
              </TicketActionButton>

              {/* Attachments */}
              <TicketActionButton
                label="Anhänge"
                icon={<PaperClipOutlined />}
                onClick={toggleIsAttachmentsDrawerOpen}
                disabled={isArchived}
                badgeCount={ticket.attachmentCount}
              >
                <Drawer
                  width={720}
                  title={`Anhänge der Unteraufgabe "${ticket.title}" bearbeiten`}
                  onClose={toggleIsAttachmentsDrawerOpen}
                  open={isAttachmentsDrawerOpen}
                  destroyOnClose
                >
                  <TicketAttachmentsUpdateForm
                    ticketId={ticket.ticketId}
                    onSuccess={() => {
                      toggleIsAttachmentsDrawerOpen();
                      refetch();
                    }}
                    onCancel={toggleIsAttachmentsDrawerOpen}
                  />
                </Drawer>
              </TicketActionButton>

              {/* Comments */}
              <TicketActionButton
                label="Kommentare"
                icon={<CommentOutlined />}
                onClick={toggleIsCommentsDrawerOpen}
                disabled={isArchived}
                badgeCount={ticket.commentCount}
              >
                <Drawer
                  width={720}
                  title={`Kommentare der Unteraufgabe "${ticket.title}" bearbeiten`}
                  onClose={toggleIsCommentsDrawerOpen}
                  open={isCommentsDrawerOpen}
                  destroyOnClose
                >
                  <TicketCommentListing
                    ticket={ticket}
                    onSuccess={refetch}
                    footer={
                      <div style={{ background: '#ffffff', marginTop: -16, paddingBottom: 16 }}>
                        <Divider style={{ margin: '16px 0' }} />
                        <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
                          <Button onClick={toggleIsCommentsDrawerOpen}>Schließen</Button>
                        </ButtonsAligned>
                      </div>
                    }
                  />
                </Drawer>
              </TicketActionButton>

              {/* Email */}
              <TicketActionButton
                label="Kommunikation"
                icon={<MailOutlined />}
                onClick={toggleIsEmailsDrawerOpen}
                disabled={isArchived}
                badgeCount={ticket.emailCount}
              >
                <Drawer
                  width={720}
                  title={`Kommunikation der Unteraufgabe "${ticket.title}" bearbeiten`}
                  onClose={toggleIsEmailsDrawerOpen}
                  open={isEmailsDrawerOpen}
                  destroyOnClose
                >
                  <TicketEmailsUpdateForm
                    ticketId={ticket.ticketId}
                    onSuccess={() => {
                      toggleIsEmailsDrawerOpen();
                      refetch();
                    }}
                    onCancel={toggleIsEmailsDrawerOpen}
                  />
                </Drawer>
              </TicketActionButton>

              {/* Assignee */}
              <MitarbeiterTooltip mitarbeiterId={ticket.assignee.mitarbeiterId} showsInitials />
              <TicketSubTicketListingActions ticket={ticket} onAction={refetch} />
            </Flex>
          </Flex>
        }
      >
        <Flex flexDirection="column">
          <Flex>
            <Box width="100px">
              <Typography.Text>Fälligkeit</Typography.Text>
            </Box>
            <Box>
              <CustomFormattedDate value={ticket.dueDate} />
            </Box>
          </Flex>
          <Flex>
            <Box width="100px">
              <Typography.Text>Erinnerung</Typography.Text>
            </Box>
            <Box>
              <TicketReminder ticket={ticket} />
            </Box>
          </Flex>
          <Flex>
            <Box width="100px">
              <Typography.Text>Bearbeiter</Typography.Text>
            </Box>
            <Box>
              <MitarbeiterOrUserName mitarbeiterId={ticket.assignee.mitarbeiterId} />
            </Box>
          </Flex>
        </Flex>
        <Flex flexDirection="column" style={{ marginTop: 16 }}>
          <Typography.Text strong>{ticket.description ? 'Beschreibung' : <TextForEmptyValue textToShow="minus" />}</Typography.Text>
        </Flex>
      </Collapse.Panel>
    </div>
  );
};

const relativeTime = (dateString: string) => {
  const date = dayjsCustom(dateString);
  const today = dayjsCustom();
  return date.isSame(today, 'day') ? 'heute' : dayjsCustom(date).startOf('day').fromNow();
};

export default TicketSubTicketListingCollapsePanel;
