import React, { FC, ReactNode } from 'react';
import { FormikProps } from 'formik';
import FiltersWithMax3Fields from '../../../../components/Filters/FiltersWithMax3Fields';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import IndexTypeSelect from '../IndexTypeSelect';
import { indexSeriesListingFiltersFormFields, IndexSeriesListingFiltersFormValues } from './listingFiltersFormMapper';

type Props = {
  formikProps: FormikProps<IndexSeriesListingFiltersFormValues>;
  actionButtons: ReactNode;
};

const ListingFilters: FC<Props> = ({ formikProps, actionButtons }) => (
  <FiltersWithMax3Fields
    filters={
      <FormItemWithoutColon name={indexSeriesListingFiltersFormFields.typ} label="Typ">
        <IndexTypeSelect name={indexSeriesListingFiltersFormFields.typ} onChange={formikProps.submitForm} style={{ minWidth: '200px' }} allowClear />
      </FormItemWithoutColon>
    }
    actionButtons={actionButtons}
  />
);

export default ListingFilters;
