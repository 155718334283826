import React, { FC, PropsWithChildren } from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import StyledTypographyText from './styled/StyledTypographyText.style';

type OutdatedDataProps = {
  isOutdated: string | boolean;
  isAvatar?: boolean;
};

const OutdatedData: FC<OutdatedDataProps & PropsWithChildren> = ({ isOutdated, isAvatar, children }) => {
  if (isOutdated) {
    return (
      <StyledTypographyText isAvatar={isAvatar}>
        {children} {isAvatar && <InfoCirlce />}
      </StyledTypographyText>
    );
  }
  return <>{children}</>;
};

const InfoCirlce = () => (
  <Tooltip placement="top" title="veraltet">
    <InfoCircleOutlined style={{ cursor: 'help', marginLeft: '2px', position: 'relative', top: 1 }} />
  </Tooltip>
);

export default OutdatedData;
