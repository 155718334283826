import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { showSuccessMsgUpdate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useUpdateContactMutation } from '../../../gql/AddressesAndContactsMutations.types';
import { emailContactUpdateFormFields, EmailContactUpdateFormValues, mapEmailContactToFormValues } from './emailContactUpdateFormMapper';
import { validateEmailAsync } from '../../../../../../shared/components/Email/emailValidation';
import { Contact } from '../../../../../../types';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithoutColon from '../../../../../../components/Form/FormItemWithoutColon';
import FieldHelpIconWithTooltip from '../../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  item: Contact;
  firmendatenId: string;
  onSuccess: () => void;
  onCancel: () => void;
};

/* No field help for this form as the email address contact field is self-explanatory*/
const EmailContactUpdateForm: FC<Props> = ({ item, firmendatenId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ContactEmail'>('ContactEmail');

  const [updateContact, { loading }] = useUpdateContactMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Kontakt`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<EmailContactUpdateFormValues>
      initialValues={mapEmailContactToFormValues(item)}
      onSubmit={(values, formikHelpers) => {
        return validateEmailAsync(values.wert)
          .then(() =>
            updateContact({
              variables: { firmendatenId, contactId: item.contactId, input: values },
            }).finally(() => formikHelpers.setSubmitting(false))
          )
          .catch((error: any) => {
            formikHelpers.setFieldError(emailContactUpdateFormFields.wert, error);
            formikHelpers.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>
                E-Mail-Adresse <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('ContactEmail.wert')} />
              </Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithoutColon name={emailContactUpdateFormFields.wert}>
                <Input name={emailContactUpdateFormFields.wert} id={emailContactUpdateFormFields.wert} maxLength={100} />
              </FormItemWithoutColon>
            </Col>
          </Row>
          <FormButtons updateMode onCancel={onCancel} isOkLoading={loading} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default EmailContactUpdateForm;
