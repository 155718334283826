import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import { useAbrechnungsdefinitionTemplateListQuery } from './gql/AbrDefTemplateQueries.types';
import { URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION } from './abrDefTemplateUriPaths';
import AbrDefTemplateTable from './Table/AbrDefTemplateTable';

const AbrechnungsdefinitionTemplateListing = () => {
  const navigate = useNavigate();

  const { data, refetch } = useAbrechnungsdefinitionTemplateListQuery();
  const abrechnungsdefinitionList = data?.getAbrechnungsdefinitionTemplateList.data;
  const loading = !abrechnungsdefinitionList;

  const abrechnungsdefinitionButtons = (
    <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
      <Button icon={<PlusOutlined />} type="primary" onClick={() => navigate(URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION.createPage)}>
        Abrechnungsdefinition anlegen
      </Button>
    </ButtonsAligned>
  );

  return (
    <>
      {abrechnungsdefinitionButtons}
      <AbrDefTemplateTable abrechnungsdefinitionList={abrechnungsdefinitionList} loading={loading} refetch={refetch} />
    </>
  );
};

export default AbrechnungsdefinitionTemplateListing;
