import React, { FC } from 'react';
import { FormikProps } from 'formik';
import ObjektSelect from '../../../ObjektSelect/ObjektSelect';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import AbrechnungTypeSelect from '../../shared/AbrechnungTypeSelect';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import { TAbrechnungQueryParams } from './filtersQueryParams';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './filtersFormMapper';
import AbrechnungStatusListFormSelect from './AbrechnungStatusListFormSelect';
import AbrechnungDebounceTextSearchFormInput from './AbrechnungDebounceTextSearchFormInput';
import useFilterWithQueryParamsNew from '../../../../components/Filters/useFilterWithQueryParamsNew';
import FiltersWithSelectorWrapper from '../../../../components/Filters/FiltersWithSelector/FiltersWithSelectorWrapper';
import FormItemForFilter from '../../../../components/Form/FormItemForFilter';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import AbrechnungCreateBtnAndModal from '../AbrechnungCreateBtnAndModal';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  queryParams: TAbrechnungQueryParams;
  onAction: () => Promise<any>;
};

const ListingFilters: FC<Props> = ({ formikProps, queryParams, onAction }) => {
  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.erstellDatumFromInclusive || filter === filtersFormFields.erstellDatumToInclusive) {
      return filtersFormFields.erstellDatumFromInclusive;
    }
    if (filter === filtersFormFields.abrechnungszeitraumFromInclusive || filter === filtersFormFields.abrechnungszeitraumToInclusive) {
      return filtersFormFields.abrechnungszeitraumFromInclusive;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === filtersFormFields.bezeichnung) {
      return (
        <FormItemForFilter name={filtersFormFields.bezeichnung} key={filter}>
          <AbrechnungDebounceTextSearchFormInput name={filtersFormFields.bezeichnung} formikProps={formikProps} />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.statusList) {
      return (
        <FormItemForFilter name={filtersFormFields.statusList} key={filter}>
          <AbrechnungStatusListFormSelect name={filtersFormFields.statusList} onChange={formikProps.submitForm} mode="multiple" placeholder="" />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.typeList) {
      return (
        <FormItemForFilter name={filtersFormFields.typeList} key={filter}>
          <AbrechnungTypeSelect mode="multiple" name={filtersFormFields.typeList} onChange={formikProps.submitForm} placeholder="" />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.objektIdList) {
      return (
        <FormItemForFilter name={filtersFormFields.objektIdList} key={filter}>
          <ObjektSelect
            name={filtersFormFields.objektIdList}
            onChange={formikProps.submitForm}
            mode="multiple"
            prefix="Objekt"
            size="middle"
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.erstellDatumFromInclusive) {
      return (
        <FormItemForFilter name="Erstelldatum" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.erstellDatumFromInclusive}
            toName={filtersFormFields.erstellDatumToInclusive}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix="Erstelldatum - von"
            toPrefix="Erstelldatum - bis"
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.abrechnungszeitraumFromInclusive) {
      return (
        <FormItemForFilter name="Zeitraum" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.abrechnungszeitraumFromInclusive}
            toName={filtersFormFields.abrechnungszeitraumToInclusive}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix="Zeitraum - von"
            toPrefix="Zeitraum - bis"
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.erstellerMitarbeiterIdList) {
      return (
        <FormItemForFilter name={filtersFormFields.erstellerMitarbeiterIdList} key={filter}>
          <MitarbeiterSelect
            name={filtersFormFields.erstellerMitarbeiterIdList}
            onChange={formikProps.submitForm}
            mode="multiple"
            size="middle"
            prefix="Ersteller"
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.erstellDatumFromInclusive) && formikProps.values.erstellDatumToInclusive) {
      formikProps.setFieldValue(filtersFormFields.erstellDatumToInclusive, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.abrechnungszeitraumFromInclusive) && formikProps.values.abrechnungszeitraumToInclusive) {
      formikProps.setFieldValue(filtersFormFields.abrechnungszeitraumToInclusive, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, filterList } = useFilterWithQueryParamsNew({
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWithSelectorWrapper
      selectedFilterList={selectedFilterList}
      onSelectedFilterTitleList={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
      allFilterTitles={listingLabelList}
      filters={<>{filterList}</>}
      actionButtons={<AbrechnungCreateBtnAndModal onCreateSuccess={onAction} />}
    />
  );
};

export default ListingFilters;
