import React from 'react';
import { Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { EuroAmount } from '../../../../../../components/Number';
import { isNotNil } from '../../../../../../helpers/assertionHelper';
import SteuersatzAdminLink from './SteuersatzAdminLink';
import DataWithStatus from '../../../../../../components/Helpers/DataWithStatus';
import { compareTwoDatesForSorting } from '../../../../../../helpers/dateHelper';
import { compareTwoStringsForSorting } from '../../../../../../helpers/stringHelper';
import { compareTwoNumbersForSorting } from '../../../../../../helpers/numberHelper';
import { MitarbeiterTooltip } from '../../../../../../components/Card';
import { BeVertragVorschreibunsposition } from '../../../../../../types';
import { NestedTableColProps } from '../../../../../../components/Table/NestedTable/NestedTable';
import FormattedDateTime from '../../../../../../components/FormattedDateTime/FormattedDateTime';
import VertragVorschreibungspositionTableActions from './VertragVorschreibungspositionTableActions';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';
import { UstKategorieWithStatus } from '../../../../../Ust/UstKategorie/ustKategorieUriHelper';

export const vertragVorschreibungspositionTableColumns = (
  objektId: string,
  bestandseinheitId: string,
  vertragId: string,
  toggleExpandedRowKey: (rowKey: string) => void,
  expandedRowKeys: string[]
): NestedTableColProps<BeVertragVorschreibunsposition>[] => [
  /* FIXME - Try to omit the non-null-assertions. The check (isNotNil or if sth does exist) is ignored when using TextWithStatusBagde around a component */
  {
    title: 'Bezeichnung',
    key: 'bezeichnung',
    width: 210,
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    defaultSortOrder: 'ascend',
    render: (text, record) => {
      return (
        <Space>
          <DataWithStatus text={record.bezeichnung} maxTextLength={30} status={record.objektVorschreibungspositionStatus} showBadgeDot={false} />
          {!record.validFrom ? (
            <Tooltip placement="top" title="Noch nicht aktiv" styles={{ root: { minWidth: '200px', maxWidth: '400px' } }}>
              <InfoCircleOutlined style={{ cursor: 'help' }} />
            </Tooltip>
          ) : null}
        </Space>
      );
    },
  },
  {
    title: 'Gültig ab',
    key: 'gueltigAb',
    width: 140,
    sorter: (a, b) => compareTwoDatesForSorting(a.validFrom, b.validFrom),
    render: (text, record) =>
      record.validFrom ? (
        <DataWithStatus text="Gültigkeit" status={record.objektVorschreibungspositionStatus} showBadgeDot={false}>
          {() => (
            /*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/
            <CustomFormattedDate value={record.validFrom!} />
          )}
        </DataWithStatus>
      ) : null,
  },
  {
    title: 'Basis',
    key: 'basis',
    width: 110,
    sorter: (a, b) => compareTwoStringsForSorting(a.basis?.text, b.basis?.text),
    render: (text, record) =>
      record.basis && <DataWithStatus text={record.basis.text} status={record.objektVorschreibungspositionStatus} showBadgeDot={false} />,
  },
  {
    title: 'Netto',
    key: 'netto',
    width: 90,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.netto, b.netto),
    render: (text, record) =>
      isNotNil(record.netto) ? (
        <DataWithStatus text="Netto" status={record.objektVorschreibungspositionStatus} showBadgeDot={false}>
          {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
          {() => <EuroAmount value={record.netto!} />}
        </DataWithStatus>
      ) : null,
  },
  {
    title: 'Steuersatz',
    key: 'steuersatz',
    width: 90,
    align: 'right',
    sorter: (a, b) => a.steuersatz - b.steuersatz,
    render: (text, record) => (
      <div style={{ justifyContent: 'right' }}>
        <SteuersatzAdminLink
          parentStatus={record.objektVorschreibungspositionStatus}
          steuersatz={record.steuersatz}
          ustKategorie={record.ustKategorie as UstKategorieWithStatus}
          ustKategorieEntryId={record.ustKategorieEntry?.ustKategorieEntryId}
          openInNewTab
        />
      </div>
    ),
  },
  {
    title: 'USt.',
    key: 'ust',
    width: 90,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.ust, b.ust),
    render: (text, record) =>
      isNotNil(record.ust) ? (
        <DataWithStatus text="Ust." status={record.objektVorschreibungspositionStatus} showBadgeDot={false}>
          {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
          {() => <EuroAmount value={record.ust!} />}
        </DataWithStatus>
      ) : null,
  },
  {
    title: 'Brutto',
    key: 'brutto',
    width: 90,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.brutto, b.brutto),
    render: (text, record) =>
      isNotNil(record.brutto) ? (
        <DataWithStatus text="Brutto" status={record.objektVorschreibungspositionStatus} showBadgeDot={false}>
          {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
          {() => <EuroAmount value={record.brutto!} />}
        </DataWithStatus>
      ) : null,
  },
  {
    title: 'Erstellt am',
    width: 120,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    width: 80,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    key: 'action',
    width: 100,
    render: (text, record) => (
      <VertragVorschreibungspositionTableActions
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
        toggleExpandedRowKey={toggleExpandedRowKey}
        expandedRowKeys={expandedRowKeys}
        vorschreibungsposition={record}
      />
    ),
  },
];
