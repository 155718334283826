import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { OrderGenerierlaufType } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useFilterGenLaufOrderVertragspartnerListQuery } from '../../../gql/GenerierlaufFilter/OrderGenerierlaufFilterQueries.types';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: OrderGenerierlaufType;
  maxTextLength?: number;
} & SelectProps;

const GenerierlaufVertragspartnerFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, maxTextLength, ...restProps }) => {
  const { data, loading } = useFilterGenLaufOrderVertragspartnerListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufOrderVertragspartnerList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Vertragspartner auswählen"
      {...restProps}
    >
      {list.map((vertragspartner) => (
        <Select.Option key={vertragspartner.rechtstraegerId} value={vertragspartner.rechtstraegerId}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? vertragspartner.kurzBezeichnung.length} text={vertragspartner.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufVertragspartnerFormSelect;
