import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { HeAbrAbrechnungskreisListFieldFragmentDoc } from '../../../Abrechnung/He/gql/HeAbrechnungFragments.types';
import { RechtstraegerFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { PostItFragmentDoc } from '../../../Rechtstraeger/PostIt/gql/PostItFragments.types';
import { AddressFieldsFragmentDoc } from '../../../Address/gql/AddressFragments.types';
import { AbrInfoAbrechnungskreisFieldsFragmentDoc } from '../../../Abrechnung/gql/AbrechnungFragments.types';
import { ObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { BestandseinheitFieldsFragmentDoc, BestandseinheitBaseFieldsFragmentDoc } from '../../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeAbrechnungGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type HeAbrechnungGenerierlaufQuery = {
  getHeAbrechnungGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierlaufId: string;
      generierDatum: string;
      heAbrechnungId?: string | null;
      fromInclusive: string;
      toInclusive: string;
      vorabrechnung?: boolean | null;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type HeAbrechnungGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  hauseigentuemerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.HeAbrechnungGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type HeAbrechnungGenerierlaufEntryListQuery = {
  getHeAbrechnungGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        currentStep?: number | null;
        generierlaufEntryId: string;
        heAbrechnungDeleted: boolean;
        heAbrechnungDeletedBy?: string | null;
        heAbrechnungDeletedByMitarbeiterId?: string | null;
        heAbrechnungDeletedTs?: string | null;
        infoMessage?: string | null;
        objektKurzBezeichnung: string;
        retry: boolean;
        stepCount?: number | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        heAbrechnungHeVertrag?: {
          abrechnungszeitraumBis: string;
          abrechnungszeitraumVon: string;
          anteile: string;
          anteilePercent: number;
          bezeichnung: string;
          buchungskreisId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          fileId?: string | null;
          guthaben: boolean;
          heAbrechnungHeVertragId: string;
          saldo: number;
          saldovortrag: number;
          updatedByMitarbeiterId?: string | null;
          abrechnungsdefinitionType: { text: string; value: Types.AbrechnungsdefinitionType };
          ausgaben?: {
            anteil: number;
            anteile?: string | null;
            anteilePercent?: number | null;
            summe: number;
            abrechnungskreisType: { value: Types.AbrechnungskreisType; text: string };
            abrechnungskreisList: Array<{
              abrechnungskreisId: string;
              bezeichnung: string;
              heAbrechnungsdefinitionId: string;
              heAbrechnungsdefinitionVersionId: string;
              summe: number;
              abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
              kontoList: Array<{
                bezeichnung: string;
                kontoId: string;
                summe: number;
                amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
                buchungszeileList: Array<{
                  auftragId?: string | null;
                  belegDatum?: string | null;
                  belegFileId?: string | null;
                  belegId: string;
                  belegnummer?: string | null;
                  betrag: number;
                  buchungIdList: Array<string>;
                  dueDate?: string | null;
                  standingInvoicePartialAmountId?: string | null;
                  text: string;
                  umbuchung?: boolean | null;
                  umgebucht?: boolean | null;
                  ustVomAufwandSteuersatz?: number | null;
                  buchungskreisRechtstraeger: {
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    createTs: string;
                    rechtstraegerId: string;
                    kurzBezeichnung: string;
                    warningList: Array<{ message: string; type: string }>;
                    type: { text: string; value: Types.RechtstraegerType };
                    status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
                  };
                  sollHaben: { text: string; value: Types.SollHaben };
                  vertragspartner?: {
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    createTs: string;
                    rechtstraegerId: string;
                    kurzBezeichnung: string;
                    warningList: Array<{ message: string; type: string }>;
                    type: { text: string; value: Types.RechtstraegerType };
                    status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
                  } | null;
                }>;
              }>;
            }>;
          } | null;
          eigentuemerverrechnung?: {
            anteil: number;
            anteile?: string | null;
            anteilePercent?: number | null;
            summe: number;
            abrechnungskreisType: { value: Types.AbrechnungskreisType; text: string };
            abrechnungskreisList: Array<{
              abrechnungskreisId: string;
              bezeichnung: string;
              heAbrechnungsdefinitionId: string;
              heAbrechnungsdefinitionVersionId: string;
              summe: number;
              abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
              kontoList: Array<{
                bezeichnung: string;
                kontoId: string;
                summe: number;
                amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
                buchungszeileList: Array<{
                  auftragId?: string | null;
                  belegDatum?: string | null;
                  belegFileId?: string | null;
                  belegId: string;
                  belegnummer?: string | null;
                  betrag: number;
                  buchungIdList: Array<string>;
                  dueDate?: string | null;
                  standingInvoicePartialAmountId?: string | null;
                  text: string;
                  umbuchung?: boolean | null;
                  umgebucht?: boolean | null;
                  ustVomAufwandSteuersatz?: number | null;
                  buchungskreisRechtstraeger: {
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    createTs: string;
                    rechtstraegerId: string;
                    kurzBezeichnung: string;
                    warningList: Array<{ message: string; type: string }>;
                    type: { text: string; value: Types.RechtstraegerType };
                    status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
                  };
                  sollHaben: { text: string; value: Types.SollHaben };
                  vertragspartner?: {
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    createTs: string;
                    rechtstraegerId: string;
                    kurzBezeichnung: string;
                    warningList: Array<{ message: string; type: string }>;
                    type: { text: string; value: Types.RechtstraegerType };
                    status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
                  } | null;
                }>;
              }>;
            }>;
          } | null;
          einnahmen?: {
            anteil: number;
            anteile?: string | null;
            anteilePercent?: number | null;
            summe: number;
            abrechnungskreisType: { value: Types.AbrechnungskreisType; text: string };
            abrechnungskreisList: Array<{
              abrechnungskreisId: string;
              bezeichnung: string;
              heAbrechnungsdefinitionId: string;
              heAbrechnungsdefinitionVersionId: string;
              summe: number;
              abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
              kontoList: Array<{
                bezeichnung: string;
                kontoId: string;
                summe: number;
                amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
                buchungszeileList: Array<{
                  auftragId?: string | null;
                  belegDatum?: string | null;
                  belegFileId?: string | null;
                  belegId: string;
                  belegnummer?: string | null;
                  betrag: number;
                  buchungIdList: Array<string>;
                  dueDate?: string | null;
                  standingInvoicePartialAmountId?: string | null;
                  text: string;
                  umbuchung?: boolean | null;
                  umgebucht?: boolean | null;
                  ustVomAufwandSteuersatz?: number | null;
                  buchungskreisRechtstraeger: {
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    createTs: string;
                    rechtstraegerId: string;
                    kurzBezeichnung: string;
                    warningList: Array<{ message: string; type: string }>;
                    type: { text: string; value: Types.RechtstraegerType };
                    status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
                  };
                  sollHaben: { text: string; value: Types.SollHaben };
                  vertragspartner?: {
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    createTs: string;
                    rechtstraegerId: string;
                    kurzBezeichnung: string;
                    warningList: Array<{ message: string; type: string }>;
                    type: { text: string; value: Types.RechtstraegerType };
                    status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
                  } | null;
                }>;
              }>;
            }>;
          } | null;
          errorList: Array<{ type: string; message: string }>;
          hauseigentuemer: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fibuKontoCreatable: boolean;
            kundennummer: string;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            bankDetailsList: Array<{
              accountHolder: string;
              accountLimit?: number | null;
              assignedHeVertragZahlungsregelMap: any;
              assignedObjektMap: any;
              bankDetailsId: string;
              bankname: string;
              bic: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              ebicsCreditTransferUserList: Array<string>;
              ebicsDirectDebitUserList: Array<string>;
              fibuKontoId?: string | null;
              fibuKontonummer?: string | null;
              iban: string;
              updatedByMitarbeiterId?: string | null;
              fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
              fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
              sepaMandat?: {
                fileId?: string | null;
                fileName?: string | null;
                sepaMandatId: string;
                sepaMandatReference: string;
                signatureDate?: string | null;
                firmendaten?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firmendatenId: string;
                  kundenSystemId: string;
                  kurzBezeichnung: string;
                  name1: string;
                  name2?: string | null;
                  name3?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechnungsAussteller?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            personenbezugList: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              personenbezugId: string;
              tagList: Array<string>;
              person: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechtstraegerSteuerpflicht?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              historicizedList?: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ message: string; type: string }>;
              }> | null;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
            sprache: { text: string; value: Types.Sprache };
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { value: Types.RechtstraegerType; text: string };
            vertragSummary?: {
              heVertragCount: number;
              heVertragVerwaltungCount: number;
              mietVertragCount: number;
              mietVertragVerwaltungCount: number;
              weSubverwaltungCount: number;
              weVertragCount: number;
              weVertragVerwaltungCount: number;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          heVertrag: {
            anteile: number;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable: boolean;
            deliveryAddressHaupt: boolean;
            heVertragId: string;
            kurzBezeichnung: string;
            objektSummeAnteile: number;
            objektVerwaltungSeit: string;
            saldouebertrag: number;
            updatedByMitarbeiterId?: string | null;
            vertragsBeginn: string;
            vertragsEnde?: string | null;
            deliveryAddress?: {
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              tagList: Array<string>;
              type: Types.AddressType;
              zipCode?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            vertragspartner: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fibuKontoCreatable: boolean;
              kundennummer: string;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              bankDetailsList: Array<{
                accountHolder: string;
                accountLimit?: number | null;
                assignedHeVertragZahlungsregelMap: any;
                assignedObjektMap: any;
                bankDetailsId: string;
                bankname: string;
                bic: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                ebicsCreditTransferUserList: Array<string>;
                ebicsDirectDebitUserList: Array<string>;
                fibuKontoId?: string | null;
                fibuKontonummer?: string | null;
                iban: string;
                updatedByMitarbeiterId?: string | null;
                fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
                fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
                sepaMandat?: {
                  fileId?: string | null;
                  fileName?: string | null;
                  sepaMandatId: string;
                  sepaMandatReference: string;
                  signatureDate?: string | null;
                  firmendaten?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    firmendatenId: string;
                    kundenSystemId: string;
                    kurzBezeichnung: string;
                    name1: string;
                    name2?: string | null;
                    name3?: string | null;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  rechnungsAussteller?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              personenbezugList: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                personenbezugId: string;
                tagList: Array<string>;
                person: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechtstraegerSteuerpflicht?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                historicizedList?: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  rechtstraegerSteuerpflichtId: string;
                  validFrom: string;
                  steuerpflicht: { text: string; value: Types.Steuerpflicht };
                  warningList: Array<{ message: string; type: string }>;
                }> | null;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
              sprache: { text: string; value: Types.Sprache };
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              type: { value: Types.RechtstraegerType; text: string };
              vertragSummary?: {
                heVertragCount: number;
                heVertragVerwaltungCount: number;
                mietVertragCount: number;
                mietVertragVerwaltungCount: number;
                weSubverwaltungCount: number;
                weVertragCount: number;
                weVertragVerwaltungCount: number;
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          infoAbrechnungskreisList: Array<{
            abrechnungsdefinitionId: string;
            abrechnungsdefinitionVersionId: string;
            abrechnungskreisId: string;
            bezeichnung: string;
            saldo: number;
            sumAufwand: number;
            vorsaldo: number;
            aufwand: {
              summe: number;
              kontoList: Array<{
                bezeichnung: string;
                kontoId: string;
                summe: number;
                buchungszeileList: Array<{
                  auftragId?: string | null;
                  belegDatum?: string | null;
                  belegFileId?: string | null;
                  belegId: string;
                  belegnummer?: string | null;
                  betrag: number;
                  buchungIdList: Array<string>;
                  dueDate?: string | null;
                  standingInvoicePartialAmountId?: string | null;
                  text: string;
                  umbuchung?: boolean | null;
                  umgebucht?: boolean | null;
                  buchungskreisRechtstraeger: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                  sollHaben: { text: string; value: Types.SollHaben };
                  vertragspartner?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                }>;
              }>;
            };
            erloes: {
              summe: number;
              kontoList: Array<{
                bezeichnung: string;
                kontoId: string;
                summe: number;
                buchungszeileList: Array<{
                  auftragId?: string | null;
                  belegDatum?: string | null;
                  belegFileId?: string | null;
                  belegId: string;
                  belegnummer?: string | null;
                  betrag: number;
                  buchungIdList: Array<string>;
                  dueDate?: string | null;
                  standingInvoicePartialAmountId?: string | null;
                  text: string;
                  umbuchung?: boolean | null;
                  umgebucht?: boolean | null;
                  buchungskreisRechtstraeger: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                  sollHaben: { text: string; value: Types.SollHaben };
                  vertragspartner?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                }>;
              }>;
            };
          }>;
          objekt: {
            countryCodeIso2: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fotoFileId?: string | null;
            fotoType?: Types.FotoType | null;
            kurzBezeichnung: string;
            objektId: string;
            rechnungsAusstellerBezeichnung?: string | null;
            rechnungsAusstellerChangeable?: boolean | null;
            subAdministration: boolean;
            sumNutzflaeche: number;
            sumNutzwert: number;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            verwaltungBis?: string | null;
            verwaltungSeit: string;
            verwaltungsartChangeable?: boolean | null;
            addressList: Array<{
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              tagList: Array<string>;
              type: Types.AddressType;
              zipCode?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            objektSummeAnteile?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              kommentar?: string | null;
              lastUpdateTs: string;
              objektSummeAnteileId: string;
              summeAnteile: number;
              validFrom: string;
              historicizedList?: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                kommentar?: string | null;
                lastUpdateTs: string;
                objektSummeAnteileId: string;
                summeAnteile: number;
                validFrom: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }> | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fibuKontoCreatable: boolean;
              kundennummer: string;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              bankDetailsList: Array<{
                accountHolder: string;
                accountLimit?: number | null;
                assignedHeVertragZahlungsregelMap: any;
                assignedObjektMap: any;
                bankDetailsId: string;
                bankname: string;
                bic: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                ebicsCreditTransferUserList: Array<string>;
                ebicsDirectDebitUserList: Array<string>;
                fibuKontoId?: string | null;
                fibuKontonummer?: string | null;
                iban: string;
                updatedByMitarbeiterId?: string | null;
                fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
                fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
                sepaMandat?: {
                  fileId?: string | null;
                  fileName?: string | null;
                  sepaMandatId: string;
                  sepaMandatReference: string;
                  signatureDate?: string | null;
                  firmendaten?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    firmendatenId: string;
                    kundenSystemId: string;
                    kurzBezeichnung: string;
                    name1: string;
                    name2?: string | null;
                    name3?: string | null;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  rechnungsAussteller?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              personenbezugList: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                personenbezugId: string;
                tagList: Array<string>;
                person: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechtstraegerSteuerpflicht?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                historicizedList?: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  rechtstraegerSteuerpflichtId: string;
                  validFrom: string;
                  steuerpflicht: { text: string; value: Types.Steuerpflicht };
                  warningList: Array<{ message: string; type: string }>;
                }> | null;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
              sprache: { text: string; value: Types.Sprache };
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              type: { value: Types.RechtstraegerType; text: string };
              vertragSummary?: {
                heVertragCount: number;
                heVertragVerwaltungCount: number;
                mietVertragCount: number;
                mietVertragVerwaltungCount: number;
                weSubverwaltungCount: number;
                weVertragCount: number;
                weVertragVerwaltungCount: number;
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            sachbearbeiter?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              fileStorageInitialized: boolean;
              firmendatenId: string;
              firstname: string;
              funktionsModulList: Array<string>;
              lastname: string;
              mitarbeiterId: string;
              title?: string | null;
              titleTrailing?: string | null;
              username: string;
              contactPerson?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firstname: string;
                lastname: string;
                tagList: Array<string>;
                title?: string | null;
                titleTrailing?: string | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                rechtstraeger?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rolleList: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                name: string;
                rolleId: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
              status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
              user?: {
                email?: string | null;
                emailUnverified?: string | null;
                firstname?: string | null;
                lastname?: string | null;
                role: string;
                username: string;
                status: { text: string; value: string };
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.ObjektStatus; description?: string | null };
            verwaltungsart: { text: string; value: Types.Verwaltungsart };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          offenePosten?: {
            anteil?: number | null;
            summe: number;
            offenePostenList: Array<{
              dueDate: string;
              fibuKontoId: string;
              fibuKontonummer: string;
              letzteBuchungAm?: string | null;
              offenePostenId: string;
              offenerBetrag: number;
              bestandseinheitList: Array<{
                aktivSeit?: string | null;
                bauteil?: string | null;
                bestandseinheitId: string;
                bezeichnung: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                geschoss?: string | null;
                inaktivSeit?: string | null;
                nutzflaeche?: number | null;
                nutzwert?: number | null;
                stiege?: string | null;
                tagList: Array<string>;
                address: {
                  addressId: string;
                  city: string;
                  cityAdditionalInformation?: string | null;
                  countryCodeIso2: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  houseEntranceApartmentNumber?: string | null;
                  postofficeBoxNumber?: string | null;
                  postofficeBoxZipCode?: string | null;
                  street?: string | null;
                  tagList: Array<string>;
                  type: Types.AddressType;
                  zipCode?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
                optionSteuerpflicht?: {
                  beOptionSteuerpflichtId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  optionSteuerpflicht: boolean;
                  validFrom: string;
                  historicizedList?: Array<{
                    beOptionSteuerpflichtId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    current: boolean;
                    deletable?: boolean | null;
                    kommentar?: string | null;
                    lastUpdateTs: string;
                    optionSteuerpflicht: boolean;
                    validFrom: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }> | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              vertragspartner: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fibuKontoCreatable: boolean;
                kundennummer: string;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                tagList: Array<string>;
                updatedByMitarbeiterId?: string | null;
                bankDetailsList: Array<{
                  accountHolder: string;
                  accountLimit?: number | null;
                  assignedHeVertragZahlungsregelMap: any;
                  assignedObjektMap: any;
                  bankDetailsId: string;
                  bankname: string;
                  bic: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  deletable?: boolean | null;
                  ebicsCreditTransferUserList: Array<string>;
                  ebicsDirectDebitUserList: Array<string>;
                  fibuKontoId?: string | null;
                  fibuKontonummer?: string | null;
                  iban: string;
                  updatedByMitarbeiterId?: string | null;
                  fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
                  fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
                  sepaMandat?: {
                    fileId?: string | null;
                    fileName?: string | null;
                    sepaMandatId: string;
                    sepaMandatReference: string;
                    signatureDate?: string | null;
                    firmendaten?: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      firmendatenId: string;
                      kundenSystemId: string;
                      kurzBezeichnung: string;
                      name1: string;
                      name2?: string | null;
                      name3?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    } | null;
                    rechnungsAussteller?: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      kurzBezeichnung: string;
                      rechtstraegerId: string;
                      status: { text: string; value: Types.RechtstraegerStatus };
                      type: { text: string; value: Types.RechtstraegerType };
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    } | null;
                    status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                    warningList: Array<{ message: string; type: string }>;
                  } | null;
                  status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                personenbezugList: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  personenbezugId: string;
                  tagList: Array<string>;
                  person: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechtstraegerSteuerpflicht?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  rechtstraegerSteuerpflichtId: string;
                  validFrom: string;
                  historicizedList?: Array<{
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    current: boolean;
                    deletable?: boolean | null;
                    kommentar?: string | null;
                    lastUpdateTs: string;
                    rechtstraegerSteuerpflichtId: string;
                    validFrom: string;
                    steuerpflicht: { text: string; value: Types.Steuerpflicht };
                    warningList: Array<{ message: string; type: string }>;
                  }> | null;
                  steuerpflicht: { text: string; value: Types.Steuerpflicht };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
                sprache: { text: string; value: Types.Sprache };
                status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
                type: { value: Types.RechtstraegerType; text: string };
                vertragSummary?: {
                  heVertragCount: number;
                  heVertragVerwaltungCount: number;
                  mietVertragCount: number;
                  mietVertragVerwaltungCount: number;
                  weSubverwaltungCount: number;
                  weVertragCount: number;
                  weVertragVerwaltungCount: number;
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
            }>;
          } | null;
          status: { text: string; value: Types.HeAbrechnungHeVertragStatus; description?: string | null };
          vorsteuerkuerzung?: {
            anteil: number;
            anteile?: string | null;
            anteilePercent?: number | null;
            summe: number;
            abrechnungskreisType: { value: Types.AbrechnungskreisType; text: string };
            abrechnungskreisList: Array<{
              abrechnungskreisId: string;
              bezeichnung: string;
              heAbrechnungsdefinitionId: string;
              heAbrechnungsdefinitionVersionId: string;
              summe: number;
              abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
              kontoList: Array<{
                bezeichnung: string;
                kontoId: string;
                summe: number;
                amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
                buchungszeileList: Array<{
                  auftragId?: string | null;
                  belegDatum?: string | null;
                  belegFileId?: string | null;
                  belegId: string;
                  belegnummer?: string | null;
                  betrag: number;
                  buchungIdList: Array<string>;
                  dueDate?: string | null;
                  standingInvoicePartialAmountId?: string | null;
                  text: string;
                  umbuchung?: boolean | null;
                  umgebucht?: boolean | null;
                  ustVomAufwandSteuersatz?: number | null;
                  buchungskreisRechtstraeger: {
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    createTs: string;
                    rechtstraegerId: string;
                    kurzBezeichnung: string;
                    warningList: Array<{ message: string; type: string }>;
                    type: { text: string; value: Types.RechtstraegerType };
                    status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
                  };
                  sollHaben: { text: string; value: Types.SollHaben };
                  vertragspartner?: {
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    createTs: string;
                    rechtstraegerId: string;
                    kurzBezeichnung: string;
                    warningList: Array<{ message: string; type: string }>;
                    type: { text: string; value: Types.RechtstraegerType };
                    status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
                  } | null;
                }>;
              }>;
            }>;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const HeAbrechnungGenerierlaufDocument = gql`
  query HeAbrechnungGenerierlauf($generierlaufId: ID!) {
    getHeAbrechnungGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countVerarbeitet
        countNichtVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        generierlaufId
        generierDatum
        errorList {
          message
          type
        }
        heAbrechnungId
        fromInclusive
        toInclusive
        vorabrechnung
        status {
          description
          value
          text
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useHeAbrechnungGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<HeAbrechnungGenerierlaufQuery, HeAbrechnungGenerierlaufQueryVariables> &
    ({ variables: HeAbrechnungGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeAbrechnungGenerierlaufQuery, HeAbrechnungGenerierlaufQueryVariables>(HeAbrechnungGenerierlaufDocument, options);
}
export function useHeAbrechnungGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeAbrechnungGenerierlaufQuery, HeAbrechnungGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeAbrechnungGenerierlaufQuery, HeAbrechnungGenerierlaufQueryVariables>(HeAbrechnungGenerierlaufDocument, options);
}
export function useHeAbrechnungGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeAbrechnungGenerierlaufQuery, HeAbrechnungGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeAbrechnungGenerierlaufQuery, HeAbrechnungGenerierlaufQueryVariables>(HeAbrechnungGenerierlaufDocument, options);
}
export type HeAbrechnungGenerierlaufQueryHookResult = ReturnType<typeof useHeAbrechnungGenerierlaufQuery>;
export type HeAbrechnungGenerierlaufLazyQueryHookResult = ReturnType<typeof useHeAbrechnungGenerierlaufLazyQuery>;
export type HeAbrechnungGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useHeAbrechnungGenerierlaufSuspenseQuery>;
export type HeAbrechnungGenerierlaufQueryResult = Apollo.QueryResult<HeAbrechnungGenerierlaufQuery, HeAbrechnungGenerierlaufQueryVariables>;
export const HeAbrechnungGenerierlaufEntryListDocument = gql`
  query HeAbrechnungGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $hauseigentuemerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: HeAbrechnungGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getHeAbrechnungGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      hauseigentuemerId: $hauseigentuemerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          currentStep
          errorList {
            message
            type
          }
          exitCode {
            value
            text
          }
          generierlaufEntryId
          heAbrechnungDeleted
          heAbrechnungDeletedBy
          heAbrechnungDeletedByMitarbeiterId
          heAbrechnungDeletedTs
          heAbrechnungHeVertrag {
            abrechnungsdefinitionType {
              text
              value
            }
            abrechnungszeitraumBis
            abrechnungszeitraumVon
            anteile
            anteilePercent
            ausgaben {
              ...HeAbrAbrechnungskreisListField
            }
            bezeichnung
            buchungskreisId
            createTs
            createdBy
            createdByMitarbeiterId
            eigentuemerverrechnung {
              ...HeAbrAbrechnungskreisListField
            }
            einnahmen {
              ...HeAbrAbrechnungskreisListField
            }
            errorList {
              type
              message
            }
            fileId
            guthaben
            hauseigentuemer {
              ...RechtstraegerFields
            }
            heAbrechnungHeVertragId
            heVertrag {
              anteile
              createTs
              createdBy
              createdByMitarbeiterId
              deletable
              deliveryAddress {
                ...AddressFields
              }
              deliveryAddressHaupt
              heVertragId
              kurzBezeichnung
              objektSummeAnteile
              objektVerwaltungSeit
              postIt {
                ...PostIt
              }
              saldouebertrag
              updatedByMitarbeiterId
              vertragsBeginn
              vertragsEnde
              vertragspartner {
                ...RechtstraegerFields
              }
              warningList {
                attribute
                message
                type
              }
            }
            infoAbrechnungskreisList {
              ...AbrInfoAbrechnungskreisFields
            }
            objekt {
              ...Objekt
            }
            offenePosten {
              anteil
              offenePostenList {
                bestandseinheitList {
                  ...BestandseinheitFields
                }
                dueDate
                fibuKontoId
                fibuKontonummer
                letzteBuchungAm
                offenePostenId
                offenerBetrag
                vertragspartner {
                  ...RechtstraegerFields
                }
              }
              summe
            }
            saldo
            saldovortrag
            status {
              text
              value
              description
            }
            updatedByMitarbeiterId
            vorsteuerkuerzung {
              ...HeAbrAbrechnungskreisListField
            }
            warningList {
              attribute
              message
              type
            }
          }
          infoMessage
          objektKurzBezeichnung
          retry
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          warningList {
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${HeAbrAbrechnungskreisListFieldFragmentDoc}
  ${RechtstraegerFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${PostItFragmentDoc}
  ${AbrInfoAbrechnungskreisFieldsFragmentDoc}
  ${ObjektFragmentDoc}
  ${BestandseinheitFieldsFragmentDoc}
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function useHeAbrechnungGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<HeAbrechnungGenerierlaufEntryListQuery, HeAbrechnungGenerierlaufEntryListQueryVariables> &
    ({ variables: HeAbrechnungGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeAbrechnungGenerierlaufEntryListQuery, HeAbrechnungGenerierlaufEntryListQueryVariables>(
    HeAbrechnungGenerierlaufEntryListDocument,
    options
  );
}
export function useHeAbrechnungGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeAbrechnungGenerierlaufEntryListQuery, HeAbrechnungGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeAbrechnungGenerierlaufEntryListQuery, HeAbrechnungGenerierlaufEntryListQueryVariables>(
    HeAbrechnungGenerierlaufEntryListDocument,
    options
  );
}
export function useHeAbrechnungGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HeAbrechnungGenerierlaufEntryListQuery, HeAbrechnungGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeAbrechnungGenerierlaufEntryListQuery, HeAbrechnungGenerierlaufEntryListQueryVariables>(
    HeAbrechnungGenerierlaufEntryListDocument,
    options
  );
}
export type HeAbrechnungGenerierlaufEntryListQueryHookResult = ReturnType<typeof useHeAbrechnungGenerierlaufEntryListQuery>;
export type HeAbrechnungGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useHeAbrechnungGenerierlaufEntryListLazyQuery>;
export type HeAbrechnungGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<typeof useHeAbrechnungGenerierlaufEntryListSuspenseQuery>;
export type HeAbrechnungGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  HeAbrechnungGenerierlaufEntryListQuery,
  HeAbrechnungGenerierlaufEntryListQueryVariables
>;
