import React, { FC } from 'react';
import { Popconfirm, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { OffenePosten } from '../../../../types';
import { useAuszifferungStornierenMutation } from '../../../BookingDetails/gql/AuszifferungMutations.types';
import { showSuccessMsgStornieren } from '../../../../components/message/message';

type Props = {
  auszifferungId: string;
  offenePosten: OffenePosten;
  zahlungId: string;
  onAction: () => void;
};

const AuszifferungActionColumn: FC<Props> = ({ auszifferungId, offenePosten, onAction, zahlungId }) => {
  const [runStorno] = useAuszifferungStornierenMutation({
    variables: {
      auszifferungId,
      zahlungId,
    },
    onCompleted: (data) => {
      showSuccessMsgStornieren('Auszifferung');
      onAction();
    },
  });

  return (
    <Popconfirm title="Sind Sie sicher?" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => runStorno()}>
      <Typography.Text style={{ color: '#1677ff', cursor: 'pointer' }}>Stornieren</Typography.Text>
    </Popconfirm>
  );
};

export default AuszifferungActionColumn;
