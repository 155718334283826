import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { showSuccessMsgDelete } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { AuftragsartBase } from '../../../types';
import { useToggle } from '../../../hooks/useToggle';
import AuftragsartTemplateChangeHistoryModal from '../ChangeHistory/AuftragsartTemplateChangeHistoryModal';
import { useDeleteAuftragsartTemplateMutation } from '../gql/AuftragsartTemplateMutations.types';
import { generatePathToAuftragsartTemplateUpdatePage } from '../auftragsartTemplateUriPaths';

type Props = {
  auftragsart: AuftragsartBase;
  onAction: () => void;
};

const AuftragsartTemplateTableActions: FC<Props> = ({ auftragsart, onAction }) => {
  const entity = `Auftragsart`;
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runDelete] = useDeleteAuftragsartTemplateMutation({
    variables: {
      auftragsartId: auftragsart.auftragsartId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link to={generatePathToAuftragsartTemplateUpdatePage(auftragsart.auftragsartId)}>Bearbeiten</Link>,
      icon: <EditOutlined />,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Löschen',
      disabled: !auftragsart.deletable,
      onClick: showConfirmDelete(runDelete, auftragsart),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <AuftragsartTemplateChangeHistoryModal
        auftragsartId={auftragsart.auftragsartId}
        open={isChangeHistoryOpen}
        onCancel={toggleChangeHistoryOpen}
      />
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, auftragsart: AuftragsartBase) => () => {
  Modal.confirm({
    title: `Möchten Sie die Auftragsart löschen?`,
    content: auftragsart.bezeichnung,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default AuftragsartTemplateTableActions;
