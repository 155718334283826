import React, { FC } from 'react';
import { Empty } from 'antd';
import buchungszeileTableColumns from './buchungszeileTableColumns';
import { FibuBuchungszeile } from '../../../../../../../types';
import TableWithColSelector from '../../../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { hasBuchungszeileDeviations } from '../buchungszeileListingHelpers';
import { useBuchungszeilenWithDeviationsQuery } from '../../../../../gql/BuchungQueries.types';

type Props = {
  belegId?: string | null;
  buchungszeileList: FibuBuchungszeile[];
  includeDeviations: boolean;
  setDeviationBuchungsanweisungId: React.Dispatch<React.SetStateAction<string>>;
  selectedRow: FibuBuchungszeile | undefined;
  setSelectedRow: React.Dispatch<React.SetStateAction<FibuBuchungszeile | undefined>>;
  setShowDeviations: (shouldShowDeviations: boolean) => void;
};

const BuchungszeileTable: FC<Props> = ({
  belegId,
  buchungszeileList,
  includeDeviations,
  selectedRow,
  setSelectedRow,
  setShowDeviations,
  setDeviationBuchungsanweisungId,
}) => {
  const { data: maybeBuchungszeilenWithDeviations } = useBuchungszeilenWithDeviationsQuery({
    skip: !belegId || !includeDeviations,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      belegId: belegId!,
    },
  });

  const buchungszeilenWithDeviations =
    maybeBuchungszeilenWithDeviations?.getBuchungszeilenWithDeviationInfo.data?.map((buchungszeile) => buchungszeile.buchungszeileId) || [];

  return (
    <TableWithColSelector<FibuBuchungszeile>
      size="small"
      dataSource={buchungszeileList}
      // Required to render cells with buchungszeilenWithDeviations correcly, and remove the warning signs after applying the deviations
      key={JSON.stringify([...buchungszeileList, ...buchungszeilenWithDeviations])}
      rowKey={(record) => record.buchungszeileId}
      columns={buchungszeileTableColumns(buchungszeilenWithDeviations, (shouldShowDeviations, buchungsanweisungId) => {
        setShowDeviations(shouldShowDeviations);
        setDeviationBuchungsanweisungId(buchungsanweisungId);
      })}
      rowClassName={(record) => (hasBuchungszeileDeviations(buchungszeilenWithDeviations, record) ? 'row-with-deviations' : 'row-without-deviations')}
      filterIdentifier="buchungsmaske-buchungszeile"
      rowSelection={{
        type: 'radio',
        selectedRowKeys: selectedRow ? [selectedRow.buchungszeileId] : [],
        onSelect: (record) => setSelectedRow(record),
      }}
      locale={{
        emptyText: <Empty description={<span>Keine Buchungen vorhanden</span>} />,
      }}
      pagination={{ showSizeChanger: true, defaultPageSize: 30, pageSizeOptions: [10, 20, 30, 50, 100] }}
    />
  );
};

export default BuchungszeileTable;
