import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AbrechnungskreisFieldsFragmentDoc } from '../../Abrechnungskreis/gql/AbrechnungskreisFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['String']['input'];
}>;

export type AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQuery = {
  getAbrechnungsdefinitionAbrechnungskreisTemplateList: {
    data: Array<{
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
      type: { text: string; value: Types.AbrechnungskreisType };
      verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AbrechnungsdefinitionTemplateAbrechnungskreisSelectListDocument = gql`
  query AbrechnungsdefinitionTemplateAbrechnungskreisSelectList($abrechnungsdefinitionId: String!) {
    getAbrechnungsdefinitionAbrechnungskreisTemplateList(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      data {
        ...AbrechnungskreisFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AbrechnungskreisFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionTemplateAbrechnungskreisSelectListQuery(
  baseOptions: Apollo.QueryHookOptions<
    AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQuery,
    AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQueryVariables
  > &
    ({ variables: AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQuery,
    AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQueryVariables
  >(AbrechnungsdefinitionTemplateAbrechnungskreisSelectListDocument, options);
}
export function useAbrechnungsdefinitionTemplateAbrechnungskreisSelectListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQuery,
    AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQuery,
    AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQueryVariables
  >(AbrechnungsdefinitionTemplateAbrechnungskreisSelectListDocument, options);
}
export function useAbrechnungsdefinitionTemplateAbrechnungskreisSelectListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQuery,
        AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQuery,
    AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQueryVariables
  >(AbrechnungsdefinitionTemplateAbrechnungskreisSelectListDocument, options);
}
export type AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionTemplateAbrechnungskreisSelectListQuery
>;
export type AbrechnungsdefinitionTemplateAbrechnungskreisSelectListLazyQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionTemplateAbrechnungskreisSelectListLazyQuery
>;
export type AbrechnungsdefinitionTemplateAbrechnungskreisSelectListSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionTemplateAbrechnungskreisSelectListSuspenseQuery
>;
export type AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQuery,
  AbrechnungsdefinitionTemplateAbrechnungskreisSelectListQueryVariables
>;
