/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../../../../components/message/validationMsg';
import { belegnummernkreisFormFields } from './belegnummnernkreisFormMapper';

export const belegnummernkreisFormValidationSchema = Yup.object().shape({
  [belegnummernkreisFormFields.bezeichnung]: Yup.string().required(entityIsRequired('Bezeichnung')).max(50, entityMaxCharLength('Bezeichnung', 50)),
  [belegnummernkreisFormFields.nummernteil]: Yup.string()
    .required(entityIsRequired('Nummernteil'))
    .matches(/^[0-9]+$/, 'Nummernteil darf nur Ziffern enthalten')
    .max(20, entityMaxCharLength('Nummernteil', 20)),
});
/* eslint-enable no-template-curly-in-string */
