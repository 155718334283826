import { opDebitorListSummaryTypes, opDebitorTableListTypes } from './op-debitor-table-types';

const NON_BEZEICHNUNG_COLUMN_WIDTH = '9.7%';
const BEZEICHNUNG_COLUMN_WIDTH = '16%';

export const opDebitorTableListColumnWidthAndAlignment: opDebitorTableListTypes = {
  belegnummer: { width: NON_BEZEICHNUNG_COLUMN_WIDTH, textAlign: 'left' },
  bezeichnung: { width: BEZEICHNUNG_COLUMN_WIDTH, textAlign: 'left' },
  buchungstext: { width: BEZEICHNUNG_COLUMN_WIDTH, textAlign: 'left' },
  buchungsdatum: { width: '9.7%', textAlign: 'right' },
  dueDate: { width: NON_BEZEICHNUNG_COLUMN_WIDTH, textAlign: 'right' },
  brutto: { width: NON_BEZEICHNUNG_COLUMN_WIDTH, textAlign: 'right' },
  zahlungsbetrag: { width: NON_BEZEICHNUNG_COLUMN_WIDTH, textAlign: 'right' },
  offenerBetrag: { width: NON_BEZEICHNUNG_COLUMN_WIDTH, textAlign: 'right' },
  mahnstufe: { width: NON_BEZEICHNUNG_COLUMN_WIDTH, textAlign: 'right' },
};

export const opDebitorListSummaryColumnWidthAndAlignment: opDebitorListSummaryTypes = {
  emptyFirstCol: { width: '51.4%' },
  sumText: { width: NON_BEZEICHNUNG_COLUMN_WIDTH, textAlign: 'right' },
  sumBrutto: opDebitorTableListColumnWidthAndAlignment.brutto,
  sumZahlungsbetrag: opDebitorTableListColumnWidthAndAlignment.zahlungsbetrag,
  sumOffenerBetrag: opDebitorTableListColumnWidthAndAlignment.offenerBetrag,
  emptyLastCol: { width: NON_BEZEICHNUNG_COLUMN_WIDTH },
};
