import { useAsync } from 'react-async';
import Fetcher from '../../helpers/fetcher';
import { processAndReturnError } from '../../helpers/APIUtils';
import { FETCH_OPTIONS_GET_DEFAULT } from '../../helpers/fetchHelper';
import { FieldHelpService } from './useFieldHelpList';

export const useExportFieldHelpAsJson = (onSuccess: (response: any) => void, onError: (error: any) => void) => {
  return useAsync({
    deferFn: exportAsJson,
    onResolve: onSuccess,
    onReject: onError,
  });
};

const exportAsJson = async (_args: any) => {
  const [service] = _args;

  let serviceUri;
  switch (service) {
    case FieldHelpService.App:
      serviceUri = 'app';
      break;
    case FieldHelpService.Fibu:
      serviceUri = 'fibu';
      break;
    case FieldHelpService.Order:
      serviceUri = 'order';
      break;
    case FieldHelpService.Payment:
      serviceUri = 'payment';
      break;
  }

  const serviceName = Object.entries(FieldHelpService).find(([, value]) => value === service)?.[0];

  try {
    // Extend Response with service
    return Object.assign(await Fetcher.getInstance().fetch(`/api/${serviceUri}-field-help/json`, FETCH_OPTIONS_GET_DEFAULT as RequestInit), {
      service: serviceName,
    });
  } catch (error: any) {
    return await processAndReturnError(error);
  }
};
