import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePaymentFieldHelpMutationVariables = Types.Exact<{
  fieldHelpId: Types.Scalars['ID']['input'];
  input: Types.FieldHelpUpdateInput;
}>;

export type UpdatePaymentFieldHelpMutation = {
  updatePaymentFieldHelp: {
    data: { deprecated: boolean; fieldHelp?: string | null; fieldHelpId: string; selector: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeletePaymentFieldHelpMutationVariables = Types.Exact<{
  fieldHelpId: Types.Scalars['ID']['input'];
}>;

export type DeletePaymentFieldHelpMutation = {
  deletePaymentFieldHelp: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const UpdatePaymentFieldHelpDocument = gql`
  mutation updatePaymentFieldHelp($fieldHelpId: ID!, $input: FieldHelpUpdateInput!) {
    updatePaymentFieldHelp(fieldHelpId: $fieldHelpId, input: $input) {
      data {
        deprecated
        fieldHelp
        fieldHelpId
        selector
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdatePaymentFieldHelpMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePaymentFieldHelpMutation, UpdatePaymentFieldHelpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePaymentFieldHelpMutation, UpdatePaymentFieldHelpMutationVariables>(UpdatePaymentFieldHelpDocument, options);
}
export type UpdatePaymentFieldHelpMutationHookResult = ReturnType<typeof useUpdatePaymentFieldHelpMutation>;
export type UpdatePaymentFieldHelpMutationResult = Apollo.MutationResult<UpdatePaymentFieldHelpMutation>;
export type UpdatePaymentFieldHelpMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentFieldHelpMutation,
  UpdatePaymentFieldHelpMutationVariables
>;
export const DeletePaymentFieldHelpDocument = gql`
  mutation deletePaymentFieldHelp($fieldHelpId: ID!) {
    deletePaymentFieldHelp(fieldHelpId: $fieldHelpId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeletePaymentFieldHelpMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePaymentFieldHelpMutation, DeletePaymentFieldHelpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePaymentFieldHelpMutation, DeletePaymentFieldHelpMutationVariables>(DeletePaymentFieldHelpDocument, options);
}
export type DeletePaymentFieldHelpMutationHookResult = ReturnType<typeof useDeletePaymentFieldHelpMutation>;
export type DeletePaymentFieldHelpMutationResult = Apollo.MutationResult<DeletePaymentFieldHelpMutation>;
export type DeletePaymentFieldHelpMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentFieldHelpMutation,
  DeletePaymentFieldHelpMutationVariables
>;
