import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { VValuePreservationIndexationInformation } from '../../vorschreibung-types';
import { valuePreservationInfoColumnWidthsAndAlignment } from './value-preservation-info-block-tables-styles';
import pdfStyles from '../../../styles/pdfStyles';

type Props = {
  bestandseinheit: string;
  index: number;
  valuePreservationInformation: VValuePreservationIndexationInformation;
};

const valuePreservationInformation: FC<Props> = ({ bestandseinheit, index, valuePreservationInformation }) => {
  return (
    <View style={[pdfStyles.row]} key={index}>
      {index === 0 ? (
        <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.bestandseinheit }]}>{bestandseinheit}</Text>
      ) : (
        <Text style={[{ ...valuePreservationInfoColumnWidthsAndAlignment.bestandseinheit }]} />
      )}
      <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.position }]}>
        {valuePreservationInformation.vorschreibungsposition}
      </Text>
      <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.valueOld }]}>
        {valuePreservationInformation.valueOld}
      </Text>
      <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.valueNew }]}>
        {valuePreservationInformation.valueNew}
      </Text>
      <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.valueFactor }]}>
        {valuePreservationInformation.valueFactor}
      </Text>
      <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.amountOld }]}>
        {valuePreservationInformation.amountOld}
      </Text>
      <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.amountNew }]}>
        {valuePreservationInformation.amountNew}
      </Text>
      <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.amountFactor }]}>
        {valuePreservationInformation.amountFactor}
      </Text>
      <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.amountDifference }]}>
        {valuePreservationInformation.amountDifference}
      </Text>
    </View>
  );
};

export default valuePreservationInformation;
