import { message } from 'antd';
import { RcFile } from 'antd/es/upload';
import Fetcher from '../../../../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../../../../helpers/APIUtils';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { mapErrorToErrorMessage } from '../../../../../../helpers/errorAndWarningHelper';

export type FileData = {
  filename: string;
  file: RcFile;
};

export const handleAddAttachments = (
  firmendatenId: string,
  wizardId: string,
  infoMailDeliveryId: string,
  attachmentList: FileData[],
  onSuccess: () => void
) => {
  const formData = new FormData();

  attachmentList.forEach((attachment) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append('attachmentList', attachment.file!, attachment.filename);
  });

  Fetcher.getInstance()
    .fetch(
      `/api/firmendaten/${firmendatenId}/info-mail-delivery-wizards/${wizardId}/recipient-list-step/info-mail-deliveries/${infoMailDeliveryId}/attachments`,
      {
        method: 'POST',
        body: formData,
      }
    )
    .then(processResponse)
    .then(() => {
      showSuccessMsgOther(`Anhang wurde erfolgreich hinzugefügt`);
      onSuccess();
    })
    .catch(processAndReturnError)
    .catch((error) => {
      return message.error({
        content: `Anhang konnte nicht hinzugefügt werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};
