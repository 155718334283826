import React, { FC } from 'react';
import { Collapse, Typography } from 'antd';
import { Box, Flex } from 'rebass';
import { StyledCollapsePanel } from './styled/StyledCollapsePanel.style';
import { BudgetingResultByVertragLevel } from '../../../../types';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { EuroAmount } from '../../../../components/Number';
import BudgetingResultByVertragLevelVPosList from './BudgetingResultByVertragLevelVPosList';

type Props = {
  vertragLevelList: BudgetingResultByVertragLevel[];
};

const BudgetingResultByVertragLevelList: FC<Props> = ({ vertragLevelList }) => (
  <>
    {vertragLevelList.map((vertragLevel, index) => (
      <Collapse expandIconPosition="end" bordered={false} key={index}>
        <StyledCollapsePanel
          header={
            <Flex width={['100%']}>
              <Box width={['70%']}>
                <DataWithShortenedTextAndExtraInfo text={vertragLevel.vertragLevel.text} maxTextLength={20}>
                  {(shortenedText) => <Typography.Text strong>{shortenedText}</Typography.Text>}
                </DataWithShortenedTextAndExtraInfo>
              </Box>
              <Box width={['30%']} display="flex" textAlign="right">
                <Box width={['100%']}>
                  <Typography.Text strong>
                    <EuroAmount value={vertragLevel.sumNetto} />
                  </Typography.Text>
                </Box>
              </Box>
            </Flex>
          }
          key={index}
        >
          <BudgetingResultByVertragLevelVPosList vertragLevelVPosList={vertragLevel.vorschreibungspositionList} />
        </StyledCollapsePanel>
      </Collapse>
    ))}
  </>
);

export default BudgetingResultByVertragLevelList;
