import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateKontoMutationVariables = Types.Exact<{
  input: Types.KontoCreateInput;
}>;

export type CreateKontoMutation = {
  createKonto: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      kontoId: string;
      nummer: string;
      tagList: Array<string>;
      klasse: { text: string; value: Types.KontoKlasse };
      status: { description?: string | null; text: string; value: Types.KontoStatus };
      type: { text: string; value: Types.KontoType };
      verwendung?: { text: string; value: Types.KontoVerwendung } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateKontoMutationVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
  input: Types.KontoUpdateInput;
}>;

export type UpdateKontoMutation = {
  updateKonto: {
    data: { kontoId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteKontoMutationVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
}>;

export type DeleteKontoMutation = {
  deleteKonto: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveKontoMutationVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
}>;

export type ArchiveKontoMutation = {
  actionArchiveKonto: {
    data: { bezeichnung: string; kontoId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveKontoMutationVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveKontoMutation = {
  actionUnarchiveKonto: {
    data: { bezeichnung: string; kontoId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetKontoTagListMutationVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
  input: Types.KontoTagListInput;
}>;

export type SetKontoTagListMutation = {
  actionSetKontoTagList: {
    data: { bezeichnung: string; kontoId: string; tagList: Array<string> };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateKontoDocument = gql`
  mutation CreateKonto($input: KontoCreateInput!) {
    createKonto(input: $input) {
      data {
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        deletable
        klasse {
          text
          value
        }
        kontoId
        nummer
        status {
          description
          text
          value
        }
        tagList
        type {
          text
          value
        }
        verwendung {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateKontoMutation(baseOptions?: Apollo.MutationHookOptions<CreateKontoMutation, CreateKontoMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKontoMutation, CreateKontoMutationVariables>(CreateKontoDocument, options);
}
export type CreateKontoMutationHookResult = ReturnType<typeof useCreateKontoMutation>;
export type CreateKontoMutationResult = Apollo.MutationResult<CreateKontoMutation>;
export type CreateKontoMutationOptions = Apollo.BaseMutationOptions<CreateKontoMutation, CreateKontoMutationVariables>;
export const UpdateKontoDocument = gql`
  mutation UpdateKonto($kontoId: ID!, $input: KontoUpdateInput!) {
    updateKonto(kontoId: $kontoId, input: $input) {
      data {
        kontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateKontoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKontoMutation, UpdateKontoMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateKontoMutation, UpdateKontoMutationVariables>(UpdateKontoDocument, options);
}
export type UpdateKontoMutationHookResult = ReturnType<typeof useUpdateKontoMutation>;
export type UpdateKontoMutationResult = Apollo.MutationResult<UpdateKontoMutation>;
export type UpdateKontoMutationOptions = Apollo.BaseMutationOptions<UpdateKontoMutation, UpdateKontoMutationVariables>;
export const DeleteKontoDocument = gql`
  mutation DeleteKonto($kontoId: ID!) {
    deleteKonto(kontoId: $kontoId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteKontoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKontoMutation, DeleteKontoMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteKontoMutation, DeleteKontoMutationVariables>(DeleteKontoDocument, options);
}
export type DeleteKontoMutationHookResult = ReturnType<typeof useDeleteKontoMutation>;
export type DeleteKontoMutationResult = Apollo.MutationResult<DeleteKontoMutation>;
export type DeleteKontoMutationOptions = Apollo.BaseMutationOptions<DeleteKontoMutation, DeleteKontoMutationVariables>;
export const ArchiveKontoDocument = gql`
  mutation ArchiveKonto($kontoId: ID!) {
    actionArchiveKonto(kontoId: $kontoId) {
      data {
        bezeichnung
        kontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveKontoMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveKontoMutation, ArchiveKontoMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveKontoMutation, ArchiveKontoMutationVariables>(ArchiveKontoDocument, options);
}
export type ArchiveKontoMutationHookResult = ReturnType<typeof useArchiveKontoMutation>;
export type ArchiveKontoMutationResult = Apollo.MutationResult<ArchiveKontoMutation>;
export type ArchiveKontoMutationOptions = Apollo.BaseMutationOptions<ArchiveKontoMutation, ArchiveKontoMutationVariables>;
export const UnarchiveKontoDocument = gql`
  mutation UnarchiveKonto($kontoId: ID!) {
    actionUnarchiveKonto(kontoId: $kontoId) {
      data {
        bezeichnung
        kontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveKontoMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveKontoMutation, UnarchiveKontoMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveKontoMutation, UnarchiveKontoMutationVariables>(UnarchiveKontoDocument, options);
}
export type UnarchiveKontoMutationHookResult = ReturnType<typeof useUnarchiveKontoMutation>;
export type UnarchiveKontoMutationResult = Apollo.MutationResult<UnarchiveKontoMutation>;
export type UnarchiveKontoMutationOptions = Apollo.BaseMutationOptions<UnarchiveKontoMutation, UnarchiveKontoMutationVariables>;
export const SetKontoTagListDocument = gql`
  mutation SetKontoTagList($kontoId: ID!, $input: KontoTagListInput!) {
    actionSetKontoTagList(kontoId: $kontoId, input: $input) {
      data {
        bezeichnung
        kontoId
        tagList
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetKontoTagListMutation(baseOptions?: Apollo.MutationHookOptions<SetKontoTagListMutation, SetKontoTagListMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetKontoTagListMutation, SetKontoTagListMutationVariables>(SetKontoTagListDocument, options);
}
export type SetKontoTagListMutationHookResult = ReturnType<typeof useSetKontoTagListMutation>;
export type SetKontoTagListMutationResult = Apollo.MutationResult<SetKontoTagListMutation>;
export type SetKontoTagListMutationOptions = Apollo.BaseMutationOptions<SetKontoTagListMutation, SetKontoTagListMutationVariables>;
