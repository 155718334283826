import React, { FC, useEffect } from 'react';
import { Space, Spin, Typography } from 'antd';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { StandingInvoiceCreateInput } from '../../../../../types';
import { useStandingInvoiceBuchungPreviewMutation } from '../../../gql/StandingInvoiceMutations.types';
import BookingPreviewTable from '../shared/StandingInvoice/BookingPreviewTable';

type Props = {
  input: StandingInvoiceCreateInput;
};

const StandingInvoiceBookingPreviewDetails: FC<Props> = ({ input }) => {
  const [runGetBookingPreview, { loading, data }] = useStandingInvoiceBuchungPreviewMutation({
    variables: { input },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const buchungPreview = data?.standingInvoiceBuchungPreview.data;

  useEffect(() => {
    runGetBookingPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  return !buchungPreview ? (
    <Spin />
  ) : (
    <Space direction="vertical" size={30} style={{ width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={5}>Buchung</Typography.Title>
        <BookingPreviewTable data={buchungPreview} loading={loading} />
      </Space>
    </Space>
  );
};

export default StandingInvoiceBookingPreviewDetails;
