import React, { FC } from 'react';
import { Empty } from 'antd';
import NestedTableWithColSelector from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { AbrechnungskreisTypeTuple, SubAbrKonto } from '../../../../../../../types';
import kontoTableColumns from './kontoTableColumns';
import BuchungszeileTable from '../Level5/BuchungszeileTable';

type Props = {
  abrechnungskreisType: AbrechnungskreisTypeTuple;
  kontoList: SubAbrKonto[];
  parentRowKeys?: string[];
  visible?: boolean;
  subLevel?: number;
  buchungskreisId?: string;
  abrechnungszeitraumVon?: string;
  abrechnungszeitraumBis?: string;
  objektId?: string;
};

const KontoTable: FC<Props> = ({
  abrechnungskreisType,
  kontoList,
  parentRowKeys,
  subLevel,
  abrechnungszeitraumVon,
  abrechnungszeitraumBis,
  buchungskreisId,
  objektId,
  visible = true,
}) => {
  return (
    <NestedTableWithColSelector<SubAbrKonto>
      visible={visible}
      parentRowKeys={parentRowKeys}
      level={4}
      subLevel={subLevel}
      rowKey={getRowKey}
      locale={{
        emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
      }}
      dataSource={kontoList}
      columns={kontoTableColumns(abrechnungskreisType, buchungskreisId, objektId, abrechnungszeitraumVon, abrechnungszeitraumBis)}
      expandable={{
        rowExpandable: (record) => !!record.buchungszeileList.length,
        expandedRowRender: (record, index, indent, expanded) => (
          <BuchungszeileTable
            abrechnungskreisType={abrechnungskreisType}
            subLevel={subLevel}
            parentRowKeys={[...(parentRowKeys ?? []), getRowKey(record)]}
            visible={visible && expanded}
            buchungszeileList={record.buchungszeileList}
          />
        ),
      }}
    />
  );
};

const getRowKey = (record: SubAbrKonto) => record.kontoId;

export default KontoTable;
