import React, { FC, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { Button, Modal, ModalProps, Space } from 'antd';
import { TicketFormValues } from '../ticketFormMapper';
import { TicketListQueryVariables } from '../../gql/TicketQueries.types';
import { TicketStatus } from '../../../../types';
import { mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import TicketFormCalendar from './TicketFormCalendar';
import MitarbeiterDropdown from './MitarbeiterDropdown';
import { useMitarbeiterListByFirmendatenIdQuery } from '../../../Mitarbeiter/gql/MitarbeiterQueries.types';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';

type Props = {
  formikProps: FormikProps<TicketFormValues>;
  assigneeMitarbeiterId: string | null;
  onOk?: () => void;
  onCancel?: () => void;
} & Omit<ModalProps, 'onOk'>;

const TicketFormCalendarModal: FC<Props> = ({ formikProps, assigneeMitarbeiterId, onOk, ...modalProps }) => {
  const [status, setStatus] = useState<TicketListQueryVariables['status']>([TicketStatus.InProgress, TicketStatus.ToDo]);
  const [priority, setPriority] = useState<TicketListQueryVariables['priority']>();
  const [assigneeMitarbeiterIds, setAssigneeMitarbeiterIds] = useState<string[]>([]);
  const [dueDate, setDueDate] = useState<string | undefined | null>();

  const { activeForFirmendatenId } = useFDAuthorized();
  const { data, loading } = useMitarbeiterListByFirmendatenIdQuery({
    variables: { firmendatenId: activeForFirmendatenId },
  });
  const mitarbeiterList = data?.getMitarbeiterListByFirmendatenId.data ?? [];
  const selectedMitarbeiterList = assigneeMitarbeiterIds.length
    ? mitarbeiterList.filter((mitarbeiter) => assigneeMitarbeiterIds.includes(mitarbeiter.mitarbeiterId))
    : mitarbeiterList;

  useEffect(() => {
    setDueDate(formikProps.values.dueDate ? mapFormDateValueToDateString(formikProps.values.dueDate) : undefined);
  }, [formikProps.values]);

  useEffect(() => {
    setAssigneeMitarbeiterIds(assigneeMitarbeiterId ? [assigneeMitarbeiterId] : []);
  }, [assigneeMitarbeiterId]);

  const handleSelect = (mitarbeiterId: string) => {
    formikProps.setFieldValue('dueDate', dueDate);
    formikProps.setFieldValue('assigneeMitarbeiterId', mitarbeiterId);
    setDueDate(formikProps.values.dueDate);
    setAssigneeMitarbeiterIds((prev) => prev.filter((assignee) => assignee === mitarbeiterId));
    onOk?.();
  };

  const handleCancel = () => {
    setDueDate(formikProps.values.dueDate);
    setAssigneeMitarbeiterIds(formikProps.values.assigneeMitarbeiterId ? [formikProps.values.assigneeMitarbeiterId] : []);
    modalProps.onCancel?.();
  };

  return (
    <Modal
      title="Kalender"
      width={1120}
      {...modalProps}
      destroyOnClose
      onCancel={handleCancel}
      footer={
        <Space>
          <Button onClick={handleCancel}>Abbrechen</Button>
          {assigneeMitarbeiterIds.length === 1 ? (
            <Button disabled={!assigneeMitarbeiterIds.length} type="primary" onClick={() => handleSelect(assigneeMitarbeiterIds?.[0])}>
              Übernehmen
            </Button>
          ) : (
            <MitarbeiterDropdown loading={loading} mitarbeiterList={selectedMitarbeiterList} onSelect={handleSelect} />
          )}
        </Space>
      }
    >
      <TicketFormCalendar
        status={status}
        setStatus={setStatus}
        priority={priority}
        setPriority={setPriority}
        assigneeMitarbeiterIds={assigneeMitarbeiterIds}
        setAssignees={setAssigneeMitarbeiterIds}
        dueDate={dueDate}
        setDueDate={setDueDate}
      />
    </Modal>
  );
};

export default TicketFormCalendarModal;
