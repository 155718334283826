import React, { FC } from 'react';
import { useFirmendatenOperatingSiteListQuery } from '../gql/FirmendatenOperatingSiteQueries.types';
import OperatingSiteList from '../../../../shared/components/OperatingSite/List/OperatingSiteList';
import OperatingSiteBtnAndModal from '../../../../shared/components/OperatingSite/OperatingSiteBtnAndModal';

type Props = {
  firmendatenId: string;
};

const OperatingSiteListing: FC<Props> = ({ firmendatenId }) => {
  const { data, refetch } = useFirmendatenOperatingSiteListQuery({ variables: { firmendatenId } });
  const operatingSiteList = data?.getFirmendatenOperatingSiteList.data ?? [];

  return (
    <>
      <OperatingSiteBtnAndModal firmendatenId={firmendatenId} onSuccess={refetch} />
      {operatingSiteList.map((operatingSite) => (
        <OperatingSiteList
          operatingSiteList={operatingSiteList}
          key={operatingSite.operatingSiteId}
          operatingSite={operatingSite}
          firmendatenId={firmendatenId}
          onSuccess={refetch}
        />
      ))}
    </>
  );
};

export default OperatingSiteListing;
