import { useAsync } from 'react-async';
import Fetcher from '../../helpers/fetcher';
import { FETCH_OPTIONS_GET_DEFAULT } from '../../helpers/fetchHelper';
import { HTTP_STATUS_CODE } from '../../helpers/APIUtils';
import { uriObjektFileOriginal, uriObjektFileThumbnail } from './ObjektApiPaths';

const useGetObjektFotoThumbnailOrOriginal = (objektId: string, fileId: string) =>
  useAsync<string | undefined>({
    promiseFn: loadObjektFotoThumbnailOrOriginal,
    objektId,
    fileId,
    watch: fileId,
  });

const loadObjektFotoThumbnailOrOriginal = async (args: any): Promise<string | undefined> => {
  try {
    let res = await Fetcher.getInstance().fetch(uriObjektFileThumbnail(args.objektId, args.fileId), FETCH_OPTIONS_GET_DEFAULT as RequestInit);
    // thumnail not created yet so download original as a fallback
    if (res.status === HTTP_STATUS_CODE.NOT_FOUND) {
      res = await Fetcher.getInstance().fetch(uriObjektFileOriginal(args.objektId, args.fileId), FETCH_OPTIONS_GET_DEFAULT as RequestInit);
    }
    if (!res.ok) {
      return Promise.resolve(undefined);
    }
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    // return await processAndReturnError(error);
    console.error('error while loadObjektFotoThumbnailOrOriginal', error);
    // just ignore the error because if error occurs we just can't show the image but it is not critical
    return Promise.resolve(undefined);
  }
};

export { useGetObjektFotoThumbnailOrOriginal };
