import React, { FC } from 'react';
import { StyledListSummarySpace } from '../../shared/ListSummary/styled/StyledListSummarySpace';
import { SummaryText } from '../../shared/ListSummary/SummaryText';
import { LoadingCol } from '../../../components/Loading';
import { TPaymentClearingQueryParams } from './Filters/filtersQueryParams';
import { usePaymentClearingListSummaryQuery } from '../gql/PaymentClearingQueries.types';

type Props = {
  queryParams: TPaymentClearingQueryParams;
};

const PaymentClearingListSummary: FC<Props> = ({ queryParams }) => {
  const { data: summaryData } = usePaymentClearingListSummaryQuery({
    variables: {
      ...queryParams,
    },
  });

  const summary = summaryData?.getPaymentClearingListSummary.data;

  return (
    <>
      {summary ? (
        <StyledListSummarySpace>
          <SummaryText text="Verfügbarer Zahlungsbetrag" value={summary.sumAvailableAmount} />
          <SummaryText text="Offener Betrag der OPs" value={summary.sumOutstandingAmount} />
        </StyledListSummarySpace>
      ) : (
        <LoadingCol isLoading />
      )}
    </>
  );
};

export default PaymentClearingListSummary;
