import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Drawer } from 'antd';
import { HAUPT_TAG_TEXT } from '../../../../../components/Tag/tagHelpers';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import VersandCardPostAddress from '../../../../Vertrag/BeVertrag/Versand/Card/VersandCardPostAddress';
import HauptTag from '../../../../Vertrag/BeVertrag/Versand/Form/shared/HauptTag';
import { Address, RechtstraegerType } from '../../../../../types';
import { useCreateNewItemInSelect } from '../../../../../components/Select/useCreateNewItemInSelect';
import { useToggle } from '../../../../../hooks/useToggle';
import AddressCreateForm from '../../../../Vertrag/shared/AddressCreateForm';

type Props = {
  disabled: boolean;
  postList: (Address | null | undefined)[];
  onNewItemCreated: (addressId: string) => void;
  onNewItemCreateSelected: () => void;
  rechtstraegerType: RechtstraegerType | undefined;
  rechtstraegerId: string;
} & SelectProps;

const ContactPostSelect: FC<Props> = ({
  disabled,
  postList,
  onNewItemCreated,
  onNewItemCreateSelected,
  rechtstraegerType,
  rechtstraegerId,
  ...restProps
}) => {
  const [isCreateNewDrawerOpen, toggleCreateNewDrawer] = useToggle();

  const onNewItemCreateSelectedFn = () => {
    onNewItemCreateSelected();
    toggleCreateNewDrawer();
  };

  const { handleSelect, handleSearch, renderCreateNewItem } = useCreateNewItemInSelect(
    postList,
    onNewItemCreateSelectedFn,
    'Neue Post-Adresse anlegen'
  );

  const onSuccess = (addresseId: string) => {
    onNewItemCreated(addresseId);
    toggleCreateNewDrawer();
  };

  const selectOptions = (
    <Select
      id={restProps.name}
      placeholder="Post-Adresse auswählen"
      allowClear
      showSearch
      loading={restProps.loading}
      filterOption={selectFilterOption}
      onSelect={handleSelect}
      onSearch={handleSearch}
      {...restProps}
      disabled={disabled}
    >
      <Select.Option key={HAUPT_TAG_TEXT} value={HAUPT_TAG_TEXT}>
        <HauptTag />
      </Select.Option>
      {postList.map((item) => {
        return item ? (
          <Select.Option key={item.addressId} value={item.addressId}>
            <VersandCardPostAddress postAddress={item} />
          </Select.Option>
        ) : null;
      })}

      {renderCreateNewItem()}
    </Select>
  );

  return (
    <>
      {selectOptions}
      {rechtstraegerType && (
        <Drawer mask={false} title="E-Mail anlegen" width={720} open={isCreateNewDrawerOpen} onClose={toggleCreateNewDrawer} destroyOnClose>
          <AddressCreateForm
            onSuccess={(addresseId) => onSuccess(addresseId)}
            onCancel={toggleCreateNewDrawer}
            rechtstraegerType={rechtstraegerType}
            rechtstraegerId={rechtstraegerId}
          />
        </Drawer>
      )}
    </>
  );
};

export default ContactPostSelect;
