import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Skeleton, Space, Typography } from 'antd';

import CardWithFooter from '../../../../../components/Card';
import CardTitle from '../../../../../components/Card/CardTitle';
import CardDetailsInfoRow from '../../../../../components/Card/CardDetailsInfoRow';
import LinkWithStatus from '../../../../../components/Link/LinkWithStatus';
import { pathsToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import { generatePathToHeVertragDetailsPage } from '../../../../Vertrag/HeVertrag/heVertragUriPaths';
import { Percent } from '../../../../../components/Number';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { HeAbrechnungHeVertrag } from '../../../../../types';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  abrechnung: HeAbrechnungHeVertrag;
  loading: boolean;
};

const HeAbrechnungHeVertragCard: FC<Props> = ({ abrechnung, loading }) => {
  return (
    <>
      <CardWithFooter
        loading={loading}
        style={{ minWidth: '320px' }}
        size="default"
        bordered={false}
        title={<CardTitle title={abrechnung.bezeichnung} status={abrechnung.status} />}
        createTs={abrechnung.createTs}
        userId={abrechnung.createdBy}
        mitarbeiterId={abrechnung.createdByMitarbeiterId}
      >
        <Skeleton active loading={loading}>
          <Space direction="horizontal">
            <Space align="baseline">
              <Typography.Text type="secondary">von</Typography.Text>
              <CustomFormattedDate value={abrechnung.abrechnungszeitraumVon} />
            </Space>
            <Space align="baseline">
              <Typography.Text type="secondary">bis</Typography.Text>
              <CustomFormattedDate value={abrechnung.abrechnungszeitraumBis} />
            </Space>
          </Space>
          <Divider dashed />
          <CardDetailsInfoRow infoRowTitle="Objekt">
            <LinkWithStatus
              status={abrechnung.objekt.status}
              text={abrechnung.objekt.kurzBezeichnung}
              to={pathsToObjektDetailsPage(abrechnung.objekt.objektId).objektDetails}
              maxTextLength={25}
            />
          </CardDetailsInfoRow>
          <Divider dashed />
          <CardDetailsInfoRow infoRowTitle="Hauseigentümer">
            <LinkWithStatus
              status={abrechnung.hauseigentuemer.status}
              text={abrechnung.hauseigentuemer.kurzBezeichnung}
              to={generatePathToRechtstraegerDetailsPage(abrechnung.hauseigentuemer.rechtstraegerId)}
              maxTextLength={25}
            />
          </CardDetailsInfoRow>
          <CardDetailsInfoRow infoRowTitle="Vertrag">
            <Link to={generatePathToHeVertragDetailsPage(abrechnung.objekt.objektId, abrechnung.heVertrag.heVertragId)}>
              <DataWithShortenedText maxTextLength={20} text={abrechnung.heVertrag.kurzBezeichnung} />
            </Link>
          </CardDetailsInfoRow>
          <CardDetailsInfoRow infoRowTitle="Anteile">
            <Percent value={abrechnung.anteilePercent / 100} /> - <Typography.Text>{abrechnung.anteile}</Typography.Text>
          </CardDetailsInfoRow>
        </Skeleton>
      </CardWithFooter>
    </>
  );
};

export default HeAbrechnungHeVertragCard;
