import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BudgetingGenerierlaufFieldsFragmentDoc } from './BudgetingGenerierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BudgetingGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type BudgetingGenerierlaufQuery = {
  getBudgetingGenerierlauf: {
    data: {
      bezeichnung: string;
      considerationPeriodUntil: string;
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      updatedByMitarbeiterId?: string | null;
      entryList: Array<{
        generierlaufEntryId: string;
        infoMessage?: string | null;
        budgeting?: {
          bezeichnung: string;
          budgetSumNextYear?: number | null;
          budgetingId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          currentYear: number;
          increase?: number | null;
          prevYear: number;
          updatedByMitarbeiterId?: string | null;
          vertragVPosWertDate?: string | null;
          vorschreibungCurrentYearSum: number;
          vorschreibungPrevYearSum: number;
          restOfCurrentYearSum?: number | null;
          totalCurrentYearSum?: number | null;
          objekt: {
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            objektId: string;
            updatedByMitarbeiterId?: string | null;
            rechnungsAussteller: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            status: { text: string; value: Types.ObjektStatus; description?: string | null };
            verwaltungsart: { text: string; value: Types.Verwaltungsart };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
          status: { text: string; value: Types.BudgetingStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        warningList: Array<{ message: string; type: string }>;
      }>;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BudgetingGenerierlaufDocument = gql`
  query BudgetingGenerierlauf($generierlaufId: ID!) {
    getBudgetingGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        ...BudgetingGenerierlaufFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BudgetingGenerierlaufFieldsFragmentDoc}
`;
export function useBudgetingGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<BudgetingGenerierlaufQuery, BudgetingGenerierlaufQueryVariables> &
    ({ variables: BudgetingGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingGenerierlaufQuery, BudgetingGenerierlaufQueryVariables>(BudgetingGenerierlaufDocument, options);
}
export function useBudgetingGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetingGenerierlaufQuery, BudgetingGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingGenerierlaufQuery, BudgetingGenerierlaufQueryVariables>(BudgetingGenerierlaufDocument, options);
}
export function useBudgetingGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BudgetingGenerierlaufQuery, BudgetingGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingGenerierlaufQuery, BudgetingGenerierlaufQueryVariables>(BudgetingGenerierlaufDocument, options);
}
export type BudgetingGenerierlaufQueryHookResult = ReturnType<typeof useBudgetingGenerierlaufQuery>;
export type BudgetingGenerierlaufLazyQueryHookResult = ReturnType<typeof useBudgetingGenerierlaufLazyQuery>;
export type BudgetingGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useBudgetingGenerierlaufSuspenseQuery>;
export type BudgetingGenerierlaufQueryResult = Apollo.QueryResult<BudgetingGenerierlaufQuery, BudgetingGenerierlaufQueryVariables>;
