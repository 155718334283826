import React, { useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { vorschreibungsPositionWertFormFields, VorschreibungspositionWertFormValues } from '../vertragVorschreibungsPositionWertMapper';
import { isBruttoValid, isUstValid } from '../vertragVorschreibungsPositionWertSchema';
import { calculateNetto } from '../../../../../../../../shared/BruttoNettoUst/bruttoNettoCalculator';
import { VorschreibungspositionBasis } from '../../../../../../../../types';
import FormattedDecimalFormInput from '../../../../../../../../components/Input-antd/FormattedDecimalFormInput';
import { TFormattedDecimal } from '../../../../../../../../helpers/formikHelper';
import FormItemWithTooltip from '../../../../../../../../components/Form/FormItemWithTooltip';
import SkeletonIfSteuersatzLoading from '../SkeletonIfSteuersatzLoading';

const NettoFormItem = () => {
  const fieldNameNetto = vorschreibungsPositionWertFormFields.netto;
  const [, , { setValue, setTouched }] = useField<TFormattedDecimal>(fieldNameNetto);
  const { values } = useFormikContext<VorschreibungspositionWertFormValues>();

  const currentBasis = values.basis;
  const currentBrutto = values.brutto;
  const currentUst = values.ust;

  const previousUstRef = useRef<number | null>();

  const previousUst = previousUstRef.current;

  useEffect(() => {
    // if basis brutto and brutto or ust changes => update netto
    // not directly dependent on steuersatz because if steuersatz changes ust will be changed firstly and that will trigger changing of brutto
    if (currentBasis === VorschreibungspositionBasis.Brutto) {
      if (previousUst === currentUst) {
        return;
      }
      if (!isBruttoValid(currentBrutto) || !isUstValid(currentUst)) {
        return;
      }
      const netto = calculateNetto(currentBrutto as number, currentUst);
      setValue(netto);
      setTouched(true);
    }
  });

  useEffect(() => {
    previousUstRef.current = currentUst;
  });

  if (currentBasis === VorschreibungspositionBasis.Netto) {
    return (
      <SkeletonIfSteuersatzLoading>
        <FormItemWithTooltip name={vorschreibungsPositionWertFormFields.netto} style={{ margin: 0 }}>
          <FormattedDecimalFormInput name={vorschreibungsPositionWertFormFields.netto} isCurrency style={{ width: '100%' }} />
        </FormItemWithTooltip>
      </SkeletonIfSteuersatzLoading>
    );
  } else {
    return (
      <SkeletonIfSteuersatzLoading>
        <FormItemWithTooltip name={vorschreibungsPositionWertFormFields.netto} style={{ margin: 0 }}>
          <FormattedDecimalFormInput name={vorschreibungsPositionWertFormFields.netto} isCurrency style={{ width: '100%' }} disabled />
        </FormItemWithTooltip>
      </SkeletonIfSteuersatzLoading>
    );
  }
};

export default NettoFormItem;
