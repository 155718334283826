import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type EbicsUserFieldsFragment = {
  bankname: string;
  camtImportEnabled: boolean;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable: boolean;
  ebicsBankConfigId: string;
  ebicsUserId: string;
  editable: boolean;
  initializationDocumentFileId?: string | null;
  mitarbeiterId: string;
  mitarbeiterName: string;
  name?: string | null;
  partnerId: string;
  password: string;
  userId: string;
  orderTypeList: Array<{ description?: string | null; text: string; value: string }>;
  status: { text: string; value: Types.EbicsUserStatus };
};

export const EbicsUserFieldsFragmentDoc = gql`
  fragment EbicsUserFields on EbicsUser {
    bankname
    camtImportEnabled
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    ebicsBankConfigId
    ebicsUserId
    editable
    initializationDocumentFileId
    mitarbeiterId
    mitarbeiterName
    name
    orderTypeList {
      description
      text
      value
    }
    partnerId
    password
    status {
      text
      value
    }
    userId
  }
`;
