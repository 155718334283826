import React, { FC } from 'react';
import { Dropdown, MenuProps, Modal } from 'antd';
import { CheckSquareOutlined, CloseSquareOutlined, DownOutlined, HistoryOutlined } from '@ant-design/icons';
import { isEbicsUserIbanStatusActive, isEbicsUserIbanStatusInactive } from '../../../../shared/ebicsHelpers';
import { EbicsUserIban } from '../../../../../../types';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useActivateEbicsUserIbanMutation, useDeactivateEbicsUserIbanMutation } from '../../../gql/EbicsUserIbanMutations.types';
import { radixActionStyles } from '../../../../../../helpers/radixIconsStyles';
import { useToggle } from '../../../../../../hooks/useToggle';
import HistoryModal, { HistoryType } from '../../../../../History/shared/HistoryModal';
import EbicsUserIbanChangeHistoryListingTable from './EbicsUserIbanChangeHistoryListingTable';

type Props = {
  ebicsUserId: string;
  ebicsUserIban: EbicsUserIban;
  onAction: () => void;
};

const EbicsUserIbanActions: FC<Props> = ({ ebicsUserId, ebicsUserIban, onAction }) => {
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const entity = 'IBAN';

  const [runActivate] = useActivateEbicsUserIbanMutation({
    onCompleted: () => {
      showSuccessMsgOther(`${entity} aktiviert`);
      onAction();
    },
    variables: { ebicsUserId, ebicsUserIbanId: ebicsUserIban.ebicsUserIbanId },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDeactivate] = useDeactivateEbicsUserIbanMutation({
    onCompleted: () => {
      showSuccessMsgOther(`${entity} deaktiviert`);
      onAction();
    },
    variables: { ebicsUserId, ebicsUserIbanId: ebicsUserIban.ebicsUserIbanId },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'IBAN Aktivieren',
      onClick: showConfirmActivate(runActivate, ebicsUserIban),
      icon: <CheckSquareOutlined />,
      disabled: isEbicsUserIbanStatusActive(ebicsUserIban.status.value),
    },
    {
      key: '2',
      label: 'IBAN Deaktivieren',
      onClick: showConfirmDeactivate(runDeactivate, ebicsUserIban),
      icon: <CloseSquareOutlined />,
      disabled: isEbicsUserIbanStatusInactive(ebicsUserIban.status.value),
    },
    {
      key: '3',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <EbicsUserIbanChangeHistoryListingTable ebicsUserId={ebicsUserId} ebicsUserIbanId={ebicsUserIban.ebicsUserIbanId} />
      </HistoryModal>
    </>
  );
};

const showConfirmActivate = (runActivate: () => void, ebicsUserIban: EbicsUserIban) => () => {
  Modal.confirm({
    title: `Möchten Sie den IBAN aktivieren?`,
    content: `${ebicsUserIban.iban}`,
    okText: 'Aktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runActivate();
    },
  });
};

const showConfirmDeactivate = (runDeactivate: () => void, ebicsUserIban: EbicsUserIban) => () => {
  Modal.confirm({
    title: `Möchten Sie den IBAN deaktivieren?`,
    content: `${ebicsUserIban.iban}`,
    okText: 'Deaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runDeactivate();
    },
  });
};

export default EbicsUserIbanActions;
