import React, { FC, useState } from 'react';
import { Flex } from 'rebass';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Space, Tabs, TabsProps, Typography } from 'antd';

import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import { createObjektAbrechnungTemplatePDFData } from '../../shared/Templates/WeTemplates/weAbrDefTemplateFormObjAbrMapper';
import AbrDefVersionViewAbrKreisList from './AbrDefVersionViewAbrKreisList';
import { Spacer } from '../../../../components/Grid';
import AbrDefKontenzuordnung from '../../shared/AbrDefKontenzuordnung';
import { generatePathToAbrechnungsdefinitionVersionUpdatePage } from '../../abrDefUriPaths';
import { mapTextbausteinListToFormValues, TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { BelegTextePlatzhalter } from '../../../Belegtext/Form/belegTexteFormTypes';
import { AbrechnungsdefinitionType, BelegTexte, BkAbrechnungsdefinitionVersion } from '../../../../types';
import { isAbrDefTypeWeAbrechnung } from '../../shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import {
  mapObjAbrDefOutputOptionsToFormValues,
  ObjAbrDefGeneralSettingsFormValues,
} from '../../shared/Templates/shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import BkOrWeObjAbrDefVersionView from './BkOrWeObjAbrDefVersionView';
import BkOrWeTopAbrDefVersionView from './BkOrWeTopAbrDefVersionView';
import {
  mapTopAbrDefGeneralSettingsToInitialValues,
  TopAbrDefGeneralSettingsFormValues,
} from '../../shared/Templates/shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import { WeAbrechnungsdefinitionVersionFieldsFragment } from '../../gql/WeAbrDef/WeAbrDefVersionFragments.types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  abrDefType: AbrechnungsdefinitionType;
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersion: WeAbrechnungsdefinitionVersionFieldsFragment | BkAbrechnungsdefinitionVersion;
  firmendatenId: string;
  isHistoricized: boolean;
  belegTexte: BelegTexte;
  belegTextePlatzhalter: BelegTextePlatzhalter;
};

const BkOrWeAbrDefVersionView: FC<Props> = ({
  abrDefType,
  abrechnungsdefinitionId,
  abrechnungsdefinitionVersion,
  isHistoricized,
  belegTexte,
  belegTextePlatzhalter,
  firmendatenId,
}) => {
  const navigate = useNavigate();
  const [objAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(abrechnungsdefinitionVersion.objektAbrechnungTextbausteinList)
  );
  const [topAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(abrechnungsdefinitionVersion.topAbrechnungTextbausteinList)
  );
  const [objAbrDefGeneralSettingsValues] = useState<ObjAbrDefGeneralSettingsFormValues>(
    mapObjAbrDefOutputOptionsToFormValues(abrechnungsdefinitionVersion.objektAbrechnungOutputOptions)
  );

  const [topAbrDefGeneralSettingsValues] = useState<TopAbrDefGeneralSettingsFormValues>(
    mapTopAbrDefGeneralSettingsToInitialValues(abrDefType, abrechnungsdefinitionVersion.topAbrechnungOutputOptions)
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Abrechnungskreise',
      children: (
        <>
          <Spacer height={16} />
          <AbrDefVersionViewAbrKreisList abrechdefAbrechkreisList={abrechnungsdefinitionVersion.abrechdefAbrechkreisList} abrDefType={abrDefType} />
        </>
      ),
    },
    // TODO: Add allgemeine einstellungen tab
    {
      key: '2',
      label: isAbrDefTypeWeAbrechnung(abrDefType) ? 'WE-Objektabrechnung' : 'BK-Objektabrechnung',
      children: (
        <BkOrWeObjAbrDefVersionView
          objAbrDefTemplateValues={objAbrDefTemplateValues}
          objAbrData={createObjektAbrechnungTemplatePDFData(
            objAbrDefTemplateValues,
            objAbrDefGeneralSettingsValues,
            belegTexte,
            belegTextePlatzhalter,
            firmendatenId
          )}
          objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
        />
      ),
    },
    {
      key: '3',
      label: isAbrDefTypeWeAbrechnung(abrDefType) ? 'WE-Topabrechnung' : 'BK-Topabrechnung',
      children: (
        <>
          <BkOrWeTopAbrDefVersionView
            abrDefType={abrDefType}
            topAbrDefTemplateValues={topAbrDefTemplateValues}
            topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
            firmendatenId={firmendatenId}
            belegTexte={belegTexte}
            belegTextePlatzhalter={belegTextePlatzhalter}
          />
        </>
      ),
    },
    {
      key: '4',
      label: 'Kontenzuordnung',
      children: (
        <AbrDefKontenzuordnung
          abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
          abrechnungsdefinitionId={abrechnungsdefinitionId}
          abrDefinitionType={abrDefType}
          mode="onlyView"
        />
      ),
    },
  ];

  return (
    <>
      <Flex>
        <Space>
          <Typography.Text strong>Gültig ab</Typography.Text>
          <Typography.Text>
            <CustomFormattedDate value={abrechnungsdefinitionVersion.validFrom} />
          </Typography.Text>
        </Space>
        <Space style={{ marginLeft: '10px' }}>
          <Typography.Text strong>Kommentar</Typography.Text>
          <Typography.Text>{abrechnungsdefinitionVersion.kommentar}</Typography.Text>
        </Space>
      </Flex>
      <Divider />
      <Tabs items={items} />
      <Spacer height={16} />
      <Space style={{ width: '100%', justifyContent: 'end' }}>
        <Button onClick={() => navigate(URI_SYS_SETTINGS.abrechnungsdefinitionPage)}>Schließen</Button>
        <Button
          type="primary"
          disabled={isHistoricized}
          onClick={() =>
            navigate(
              generatePathToAbrechnungsdefinitionVersionUpdatePage(
                abrechnungsdefinitionId,
                abrechnungsdefinitionVersion.abrechnungsdefinitionVersionId
              )
            )
          }
        >
          Bearbeiten
        </Button>
      </Space>
    </>
  );
};

export default BkOrWeAbrDefVersionView;
