import React, { FC } from 'react';
import { Divider, Skeleton, Space, Typography } from 'antd';
import CardWithFooter from '../../../../../components/Card';
import { SubAbrechnungEigentuemer } from '../../../../../types';
import CardTitle from '../../../../../components/Card/CardTitle';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import CardDetailsInfoRow from '../../../../../components/Card/CardDetailsInfoRow';
import LinkWithStatus from '../../../../../components/Link/LinkWithStatus';
import { pathsToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import RechtstraegerWithContactsTooltip from '../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

type Props = {
  subAbrechnungEigentuemer: SubAbrechnungEigentuemer;
  loading: boolean;
  objektId: string;
};

const SubAbrechnungEigentuemerCard: FC<Props> = ({ subAbrechnungEigentuemer, loading, objektId }) => (
  <>
    <CardWithFooter
      loading={loading}
      style={{ minWidth: '320px' }}
      size="default"
      bordered={false}
      title={<CardTitle title={subAbrechnungEigentuemer.bezeichnung} status={subAbrechnungEigentuemer.status} />}
      //extra={<SubAbrechnungCardActions abrechnung={subAbrechnungEigentuemer} objektId={objektId} />}
      createTs={subAbrechnungEigentuemer.createTs}
      userId={subAbrechnungEigentuemer.createdBy}
      mitarbeiterId={subAbrechnungEigentuemer.createdByMitarbeiterId}
    >
      <Skeleton active loading={loading}>
        <Space direction="horizontal">
          <Space align="baseline">
            <Typography.Text type="secondary">von</Typography.Text>
            <CustomFormattedDate value={subAbrechnungEigentuemer.abrechnungszeitraumVon} />
          </Space>
          <Space align="baseline">
            <Typography.Text type="secondary">bis</Typography.Text>
            <CustomFormattedDate value={subAbrechnungEigentuemer.abrechnungszeitraumBis} />
          </Space>
        </Space>
        <Divider dashed />
        <CardDetailsInfoRow infoRowTitle="Objekt">
          <LinkWithStatus
            status={subAbrechnungEigentuemer.objekt.status}
            text={subAbrechnungEigentuemer.objekt.kurzBezeichnung}
            to={pathsToObjektDetailsPage(subAbrechnungEigentuemer.objekt.objektId).objektDetails}
            maxTextLength={25}
          />
        </CardDetailsInfoRow>
        <Divider dashed />
        <CardDetailsInfoRow infoRowTitle="Eigentümer">
          <RechtstraegerWithContactsTooltip
            rechtstraegerId={subAbrechnungEigentuemer.eigentuemer.rechtstraegerId}
            rechtstraegerName={subAbrechnungEigentuemer.eigentuemer.kurzBezeichnung}
          />
        </CardDetailsInfoRow>
      </Skeleton>
    </CardWithFooter>
  </>
);

export default SubAbrechnungEigentuemerCard;
