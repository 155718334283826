import { FC, useContext } from 'react';
import { useVertragVposIndexationAgreementChangeHistoryListQuery } from '../../gql/VPosIndexationAgreementQueries.types';
import { IndexationAgreementContext } from './IndexationAgreementContext';
import { HistoryType } from '../../../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../../../History/HistoryListingTable';

type Props = {
  indexationAgreementId: string;
};

const IndexationAgreementChangeHistoryListingTable: FC<Props> = ({ indexationAgreementId }) => {
  const { objektId, bestandseinheitId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);

  const { data, loading } = useVertragVposIndexationAgreementChangeHistoryListQuery({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      vorschreibungspositionId,
      indexationAgreementId,
    },
  });

  const historyList = data?.getVertragVposIndexationAgreementChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="vertrag" />;
};

export default IndexationAgreementChangeHistoryListingTable;
