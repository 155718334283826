import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../constants/andromedaSystemSettingsUriPaths';
import EbicsBankConfigPage from './EbicsBankConfigPage';

const andromedaSysSettingsEbicsBankConfigPageRoute = (
  <Route path={URI_ANDROMEDA_SYS_SETTINGS.ebicsBankConfig} element={<AuthRoute element={<EbicsBankConfigPage />} />} />
);

export default andromedaSysSettingsEbicsBankConfigPageRoute;
