import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../helpers/selectHelper';
import { useFilterPaymentSepaCreditorIdListQuery } from '../Listing/Filters/gql/PaymentFilterQueries.types';

type Props = SelectProps;

const SepaCreditorIdListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useFilterPaymentSepaCreditorIdListQuery();
  const sepaCreditorIdList = data?.getFilterPaymentSepaCreditorIdList.data ?? [];

  return (
    <Select
      size="middle"
      placeholder=""
      style={{ minWidth: '260px' }}
      {...restProps}
      id={restProps.name}
      loading={loading}
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {sepaCreditorIdList.map((id) => (
        <Select.Option key={id} value={id}>
          {id}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SepaCreditorIdListFormSelect;
