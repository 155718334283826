import React, { FC } from 'react';
import { Drawer } from 'antd';
import VersandForm from '../Form/VersandForm';
import { DokumentenversandEntryFragment } from '../gql/BeVertragDokumentenVersandFragments.types';

type VersandDrawerProps = {
  title: string;
  isDrawerOpen: boolean;
  toggleDrawerOpen: () => void;
  onSuccess: () => void;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  isOriginal?: boolean;
  dokumentenversandEntry?: DokumentenversandEntryFragment;
};

const VersandDrawer: FC<VersandDrawerProps> = ({
  title,
  isDrawerOpen,
  toggleDrawerOpen,
  onSuccess,
  objektId,
  bestandseinheitId,
  vertragId,
  isOriginal,
  dokumentenversandEntry,
}) => {
  return (
    <Drawer title={title} width={720} onClose={toggleDrawerOpen} open={isDrawerOpen} destroyOnClose>
      <VersandForm
        onSuccess={() => {
          onSuccess();
          toggleDrawerOpen();
        }}
        onCancel={toggleDrawerOpen}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        dokumentenversandEntry={dokumentenversandEntry}
        vertragId={vertragId}
        isOriginal={isOriginal}
      />
    </Drawer>
  );
};

export default VersandDrawer;
