import React, { FC } from 'react';
import { Dayjs } from 'dayjs';
import { dayjsCustom } from '../../../../../../../../helpers/dayjsCustom';
import FormItemWithoutColon from '../../../../../../../../components/Form/FormItemWithoutColon';
import { BeVertragVorschreibungsPositionWertFieldsFragment } from '../../../gql/BeVertragVorschreibungspositionFragments.types';
import { vorschreibungsPositionWertFormFields } from '../vertragVorschreibungsPositionWertMapper';
import MonthPicker from '../../../../../../../../components/DatePicker/MonthPicker/MonthPicker';

type Props = {
  vertragsBeginn: string;
  wertList: BeVertragVorschreibungsPositionWertFieldsFragment[];
};

const ValidFrom: FC<Props> = ({ vertragsBeginn, wertList }) => {
  return (
    <FormItemWithoutColon
      name={vorschreibungsPositionWertFormFields.validFrom}
      hasFeedback
      style={{ width: '100%', marginBottom: '0px' }}
      help={null}
    >
      <MonthPicker
        name={vorschreibungsPositionWertFormFields.validFrom}
        style={{ width: '100%' }}
        allowClear={false}
        // TODO EC-7903
        disabledDate={(date) => isBeforeVertragsBeginnOrAlreadyInUse(date as unknown as Dayjs, vertragsBeginn, wertList)}
      />
    </FormItemWithoutColon>
  );
};

const isBeforeVertragsBeginnOrAlreadyInUse = (date: Dayjs, vertragsBeginn: string, wertList: BeVertragVorschreibungsPositionWertFieldsFragment[]) => {
  const vertragsBeginnDate = dayjsCustom(vertragsBeginn);
  return isBeforeVertragsBeginn(date, vertragsBeginnDate) || isAlreadyInUse(date, wertList);
};

const isBeforeVertragsBeginn = (date: Dayjs, vertragsBeginn: Dayjs) => dayjsCustom(date).startOf('month').isBefore(vertragsBeginn.startOf('month'));

const isAlreadyInUse = (date: Dayjs, wertList: BeVertragVorschreibungsPositionWertFieldsFragment[]) => {
  return wertList
    .filter(isManuallyCreatedWertOrAutomaticallyCreatedWertWithAValidFromOnTheFirstDayOfMonth)
    .some((wert) => dayjsCustom(wert.validFrom).startOf('month').isSame(date.startOf('month')));
};

const isManuallyCreatedWertOrAutomaticallyCreatedWertWithAValidFromOnTheFirstDayOfMonth = (
  wert: BeVertragVorschreibungsPositionWertFieldsFragment
) => {
  return !wert.changeReason || (!!wert.changeReason && dayjsCustom(wert.validFrom).date() === 1);
};

export default ValidFrom;
