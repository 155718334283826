import { Typography } from 'antd';
import { FC } from 'react';
import { Dayjs } from 'dayjs';
import { Turnus } from '../../../../../types';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../components/DatePicker/DatePicker_formikAntD';

type Props = {
  periode: Turnus | null;
  durchfuehrenAm: number | null;
  beendetAm?: string | null;
};

const NextPaymentText: FC<Props> = ({ periode, durchfuehrenAm, beendetAm }) => {
  return (
    <Typography.Text style={{ paddingLeft: '4px', paddingRight: '4px' }}>
      Nächste Zahlung am {periode && durchfuehrenAm ? `${sliceDates(getZahlungenDates(periode, durchfuehrenAm, beendetAm))}` : '...'}
    </Typography.Text>
  );
};

const sliceDates = (dates: string[]) => {
  const datesToString = dates.join(', ');
  return datesToString.length > 40 ? `${datesToString.slice(0, 40)}...` : datesToString;
};

const getZahlungenDates = (periode: Turnus, date: number, beendetAm?: string | null): string[] => {
  // start index: jan => 0, ..., dec => 11
  const quarterPeriod = [0, 3, 6, 9];
  const halfyearPeriod = [0, 6];

  // returns jan => 0, ..., dec => 11
  const getMonth = dayjsCustom().get('month');
  const nextPaymentDates = [...Array(3)];

  const addMonth = dayjsCustom().get('date') > date ? 1 : 0;

  switch (periode) {
    case Turnus.Monatlich:
      return nextPaymentDates
        .map((_, index) => getDay(date, getMonth + addMonth + index))
        .filter((day) => filterAfterBeendetAm(day, beendetAm))
        .map(formatDay);
    case Turnus.Quartalsweise:
      return nextPaymentDates
        .map((value, index) => {
          const { numberIndex, nextMonthToUse } = getNextHighestIndex(quarterPeriod, getMonth + addMonth, 3);
          return getDay(date, quarterPeriod[numberIndex] + nextMonthToUse + index * 3);
        })
        .filter((day) => filterAfterBeendetAm(day, beendetAm))
        .map(formatDay);
    case Turnus.Halbjaehrlich:
      return nextPaymentDates
        .map((value, index) => {
          const { numberIndex, nextMonthToUse } = getNextHighestIndex(halfyearPeriod, getMonth + addMonth, 6);
          return getDay(date, halfyearPeriod[numberIndex] + nextMonthToUse + index * 6);
        })
        .filter((day) => filterAfterBeendetAm(day, beendetAm))
        .map(formatDay);
    case Turnus.Jaehrlich:
    default:
      return nextPaymentDates
        .map((value, index) => {
          const addMonthForYear = getMonth > 0 || (getMonth === 0 && dayjsCustom().get('date') > date) ? 1 : 0;
          return getDay(date, (index + addMonthForYear) * 12);
        })
        .filter((day) => filterAfterBeendetAm(day, beendetAm))
        .map(formatDay);
  }
};

const getDay = (date: number, month: number) => dayjsCustom().set('date', date).set('month', month);

const getNextHighestIndex = (arr: number[], number: number, nextMonthToUse: number) => {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i] >= number) {
      return { numberIndex: i, nextMonthToUse: 0 };
    } else if (number >= arr[arr.length - 1]) {
      return { numberIndex: arr.length - 1, nextMonthToUse };
    }
  }
  return { numberIndex: 0, nextMonthToUse: 0 };
};

const filterAfterBeendetAm = (day: Dayjs, beendetAm?: string | null) => {
  if (beendetAm) {
    return !dayjsCustom(beendetAm).isBefore(day);
  }
  return true;
};

const formatDay = (day: Dayjs) => day.format(DATE_DISPLAY_FORMAT_DEFAULT);

export default NextPaymentText;
