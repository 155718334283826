import { FC } from 'react';
import { usePersonenGemeinschaftChangeHistoryListQuery } from '../gql/PersonenGemeinschaftQueries.types';
import { HistoryType } from '../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../History/HistoryListingTable';

type Props = {
  personenGemeinschaftId: string;
};

const PersonenGemeinschaftChangeHistoryListingTable: FC<Props> = ({ personenGemeinschaftId }) => {
  const { data, loading } = usePersonenGemeinschaftChangeHistoryListQuery({ variables: { personenGemeinschaftId } });
  const historyList = data?.getPersonenGemeinschaftChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="personen-gemeinschaft" />;
};

export default PersonenGemeinschaftChangeHistoryListingTable;
