import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePauschalmieteAufwandskontoMutationVariables = Types.Exact<{
  input: Types.FirmendatenPauschalmieteAufwandskontoInput;
}>;

export type CreatePauschalmieteAufwandskontoMutation = {
  createPauschalmieteAufwandskonto: {
    data: { firmendatenPauschalmieteAufwandskontoId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdatePauschalmieteAufwandskontoMutationVariables = Types.Exact<{
  firmendatenPauschalmieteAufwandskontoId: Types.Scalars['ID']['input'];
  input: Types.FirmendatenPauschalmieteAufwandskontoInput;
}>;

export type UpdatePauschalmieteAufwandskontoMutation = {
  updatePauschalmieteAufwandskonto: {
    data: { firmendatenPauschalmieteAufwandskontoId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdatePauschalmieteForderungskontoMutationVariables = Types.Exact<{
  firmendatenPauschalmieteForderungskontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type UpdatePauschalmieteForderungskontoMutation = {
  updatePauschalmieteForderungskonto: {
    data: { pauschalmieteAufwandskontoId?: string | null; pauschalmieteForderungskontoId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeletePauschalmieteAufwandskontoMutationVariables = Types.Exact<{
  firmendatenPauschalmieteAufwandskontoId: Types.Scalars['ID']['input'];
}>;

export type DeletePauschalmieteAufwandskontoMutation = {
  deletePauschalmieteAufwandskonto: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreatePauschalmieteAufwandskontoDocument = gql`
  mutation CreatePauschalmieteAufwandskonto($input: FirmendatenPauschalmieteAufwandskontoInput!) {
    createPauschalmieteAufwandskonto(input: $input) {
      data {
        firmendatenPauschalmieteAufwandskontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreatePauschalmieteAufwandskontoMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePauschalmieteAufwandskontoMutation, CreatePauschalmieteAufwandskontoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePauschalmieteAufwandskontoMutation, CreatePauschalmieteAufwandskontoMutationVariables>(
    CreatePauschalmieteAufwandskontoDocument,
    options
  );
}
export type CreatePauschalmieteAufwandskontoMutationHookResult = ReturnType<typeof useCreatePauschalmieteAufwandskontoMutation>;
export type CreatePauschalmieteAufwandskontoMutationResult = Apollo.MutationResult<CreatePauschalmieteAufwandskontoMutation>;
export type CreatePauschalmieteAufwandskontoMutationOptions = Apollo.BaseMutationOptions<
  CreatePauschalmieteAufwandskontoMutation,
  CreatePauschalmieteAufwandskontoMutationVariables
>;
export const UpdatePauschalmieteAufwandskontoDocument = gql`
  mutation UpdatePauschalmieteAufwandskonto($firmendatenPauschalmieteAufwandskontoId: ID!, $input: FirmendatenPauschalmieteAufwandskontoInput!) {
    updatePauschalmieteAufwandskonto(firmendatenPauschalmieteAufwandskontoId: $firmendatenPauschalmieteAufwandskontoId, input: $input) {
      data {
        firmendatenPauschalmieteAufwandskontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdatePauschalmieteAufwandskontoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePauschalmieteAufwandskontoMutation, UpdatePauschalmieteAufwandskontoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePauschalmieteAufwandskontoMutation, UpdatePauschalmieteAufwandskontoMutationVariables>(
    UpdatePauschalmieteAufwandskontoDocument,
    options
  );
}
export type UpdatePauschalmieteAufwandskontoMutationHookResult = ReturnType<typeof useUpdatePauschalmieteAufwandskontoMutation>;
export type UpdatePauschalmieteAufwandskontoMutationResult = Apollo.MutationResult<UpdatePauschalmieteAufwandskontoMutation>;
export type UpdatePauschalmieteAufwandskontoMutationOptions = Apollo.BaseMutationOptions<
  UpdatePauschalmieteAufwandskontoMutation,
  UpdatePauschalmieteAufwandskontoMutationVariables
>;
export const UpdatePauschalmieteForderungskontoDocument = gql`
  mutation UpdatePauschalmieteForderungskonto($firmendatenPauschalmieteForderungskontoId: String) {
    updatePauschalmieteForderungskonto(firmendatenPauschalmieteForderungskontoId: $firmendatenPauschalmieteForderungskontoId) {
      data {
        pauschalmieteAufwandskontoId
        pauschalmieteForderungskontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdatePauschalmieteForderungskontoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePauschalmieteForderungskontoMutation, UpdatePauschalmieteForderungskontoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePauschalmieteForderungskontoMutation, UpdatePauschalmieteForderungskontoMutationVariables>(
    UpdatePauschalmieteForderungskontoDocument,
    options
  );
}
export type UpdatePauschalmieteForderungskontoMutationHookResult = ReturnType<typeof useUpdatePauschalmieteForderungskontoMutation>;
export type UpdatePauschalmieteForderungskontoMutationResult = Apollo.MutationResult<UpdatePauschalmieteForderungskontoMutation>;
export type UpdatePauschalmieteForderungskontoMutationOptions = Apollo.BaseMutationOptions<
  UpdatePauschalmieteForderungskontoMutation,
  UpdatePauschalmieteForderungskontoMutationVariables
>;
export const DeletePauschalmieteAufwandskontoDocument = gql`
  mutation DeletePauschalmieteAufwandskonto($firmendatenPauschalmieteAufwandskontoId: ID!) {
    deletePauschalmieteAufwandskonto(firmendatenPauschalmieteAufwandskontoId: $firmendatenPauschalmieteAufwandskontoId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeletePauschalmieteAufwandskontoMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePauschalmieteAufwandskontoMutation, DeletePauschalmieteAufwandskontoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePauschalmieteAufwandskontoMutation, DeletePauschalmieteAufwandskontoMutationVariables>(
    DeletePauschalmieteAufwandskontoDocument,
    options
  );
}
export type DeletePauschalmieteAufwandskontoMutationHookResult = ReturnType<typeof useDeletePauschalmieteAufwandskontoMutation>;
export type DeletePauschalmieteAufwandskontoMutationResult = Apollo.MutationResult<DeletePauschalmieteAufwandskontoMutation>;
export type DeletePauschalmieteAufwandskontoMutationOptions = Apollo.BaseMutationOptions<
  DeletePauschalmieteAufwandskontoMutation,
  DeletePauschalmieteAufwandskontoMutationVariables
>;
