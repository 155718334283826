import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { InfoAbrechnungskreis } from '../../../objektAbrechnung/objektAbrechnung-types';

const InfoAbrKreisVorsaldo: FC<{ abrechKreis: InfoAbrechnungskreis }> = ({ abrechKreis }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight, { marginTop: '5mm' }]}>
    <View style={[pdfStyles.row, { width: '45%' }]}>
      <Text style={[pdfStyles.column, pdfStyles.textBulletin, { width: '80%', fontWeight: 'bold' }]}>{abrechKreis.saldoVortragText}</Text>
      <Text style={[pdfStyles.column, pdfStyles.textBulletin, { width: '20%', alignItems: 'flex-end', textAlign: 'right', fontWeight: 'bold' }]}>
        {abrechKreis.saldoVortrag}
      </Text>
    </View>
    <View style={[pdfStyles.column, { width: '55%' }]} />
  </View>
);

export default InfoAbrKreisVorsaldo;
