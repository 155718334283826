import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { TemplatePreviewProps } from '../../../../../components/Template/PDFTemplates/PDFTemplatePage';
import { SubAdministrationAbrechnungOutputOptions } from '../../../../../types';
import { mapHeOrSubAbrDefOutputOptionsToFormValues } from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';
import HeOrSubGeneralSettingsForm from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/HeOrSubGeneralSettingsForm';
import AbrDefVersionViewTemplate from '../../../../Abrechnungsdefinition/Version/View/AbrDefVersionViewTemplate';

type Props = {
  outputOptions: SubAdministrationAbrechnungOutputOptions;
  subAbrDefTemplateValues: TextbausteinFormValues[];
  renderTemplatePreview: (props: TemplatePreviewProps) => React.ReactNode;
};

const SubAbrDefTemplateVersionViewTabWithTemplate: FC<Props> = ({ outputOptions, subAbrDefTemplateValues, renderTemplatePreview }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: <HeOrSubGeneralSettingsForm values={mapHeOrSubAbrDefOutputOptionsToFormValues(outputOptions)} />,
    },
    {
      key: '2',
      label: 'Texte',
      children: <AbrDefVersionViewTemplate values={subAbrDefTemplateValues} renderPreview={renderTemplatePreview} />,
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />
    </>
  );
};

export default SubAbrDefTemplateVersionViewTabWithTemplate;
