import { generatePath } from 'react-router-dom';
import { URI_OBJEKT_DETAILS_PAGE } from '../../Objekt/objektUriPaths';

const URI_WE_ABRECHNUNG_DETAILS_PAGE = `${URI_OBJEKT_DETAILS_PAGE}/we-abrechnung/:objektAbrechnungId`;
const URI_WE_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE = `${URI_WE_ABRECHNUNG_DETAILS_PAGE}/abrechnung/topabrechnungen/:topAbrechnungId`;

const generatePathToWeAbrechnungDetailsPage = (objektId: string, objektAbrechnungId: string) =>
  generatePath(URI_WE_ABRECHNUNG_DETAILS_PAGE, { objektId, objektAbrechnungId });

const generatePathToWeAbrechnungDetailsPageAbrechnungTab = (objektId: string, objektAbrechnungId: string) =>
  `${generatePathToWeAbrechnungDetailsPage(objektId, objektAbrechnungId)}/abrechnung`;

export const generatePathToWeAbrechnungDetailsTopabrechnungDetailsPage = (objektId: string, objektAbrechnungId: string, topAbrechnungId: string) =>
  generatePath(URI_WE_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE, { objektId, objektAbrechnungId, topAbrechnungId });

export const pathsToWeAbrechnungDetails = (objektId: string, objektAbrechnungId: string) => ({
  abrechnungDetails: generatePathToWeAbrechnungDetailsPage(objektId, objektAbrechnungId),
  objAbr: generatePathToWeAbrechnungDetailsPageAbrechnungTab(objektId, objektAbrechnungId),
  objAbrTab: `${generatePathToWeAbrechnungDetailsPageAbrechnungTab(objektId, objektAbrechnungId)}/objektabrechnung`,
  objAbrTab2: `${generatePathToWeAbrechnungDetailsPageAbrechnungTab(objektId, objektAbrechnungId)}/objektabrechnung2`,
  topAbrTab: `${generatePathToWeAbrechnungDetailsPageAbrechnungTab(objektId, objektAbrechnungId)}/topabrechnungen`,
  zahlungsrueckstandTab: `${generatePathToWeAbrechnungDetailsPageAbrechnungTab(objektId, objektAbrechnungId)}/zahlungsrueckstand`,
  deviations: `${generatePathToWeAbrechnungDetailsPage(objektId, objektAbrechnungId)}/deviations`,
});

export { generatePathToWeAbrechnungDetailsPage, URI_WE_ABRECHNUNG_DETAILS_PAGE, URI_WE_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE };
