import { Modal } from 'antd';

export const showConfirmApply = (runApply: () => Promise<unknown>) => {
  Modal.confirm({
    title: `Möchten Sie die indexierten Werte anwenden?`,
    okText: 'Anwenden',
    cancelText: 'Abbrechen',
    onOk() {
      return runApply();
    },
  });
};

export const showConfirmDoNotApply = (runDoNotApply: () => Promise<unknown>) => {
  Modal.confirm({
    title: `Möchten Sie die indexierten Werte nicht anwenden?`,
    okText: 'Nicht Anwenden',
    cancelText: 'Abbrechen',
    onOk() {
      return runDoNotApply();
    },
  });
};

export const showConfirmSubmit = (runSubmit: () => Promise<unknown>) => {
  Modal.confirm({
    title: `Möchten Sie die indexierten Werte vorlegen?`,
    okText: 'Vorlegen',
    cancelText: 'Abbrechen',
    onOk() {
      return runSubmit();
    },
  });
};
