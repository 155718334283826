import React from 'react';
import { Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ObjAbrKonto } from '../../../../../../../types';
import { EuroAmount } from '../../../../../../../components/Number';
import { generatePathToKontoblattPage } from '../../../../../../Kontoblatt/kontoblattUriPaths';
import { NestedTableWithColSelectorColProps } from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

const objektAbrechnungUstVomAufwandKontoTableColumns = (
  objektId: string,
  fromInclusive: string,
  toInclusive: string,
  buchungskreisId: string
): NestedTableWithColSelectorColProps<ObjAbrKonto>[] => {
  return [
    {
      title: 'USt vom Aufwand',
      key: 'abrechnungskreis',
      width: 200,
      render: (text, record) => (
        <Space>
          <Typography.Text>{record.bezeichnung}</Typography.Text>
          {record.expenseReducing && <Typography.Text type="secondary"> (aufwandsmindernd)</Typography.Text>}
        </Space>
      ),
    },

    {
      title: '',
      width: 100,
    },
    {
      title: '',
      width: 100,
    },
    {
      title: '',
      width: 100,
    },

    {
      title: 'Aufteilungsschlüssel',
      key: 'aufteilungsschluessel',
      width: 200,
      align: 'right',
    },
    {
      title: 'Ausgaben',
      align: 'right',
      key: 'ausgaben',
      defaultSelected: true,
      width: 180,
      render: (text, record) => (
        <Link
          target="_blank"
          to={generatePathToKontoblattPage({
            kontoId: record.kontoId,
            objektId,
            buchungsdatumFrom: fromInclusive,
            buchungsdatumTo: toInclusive,
            buchungskreisId,
          })}
        >
          <EuroAmount value={record.summe} />
        </Link>
      ),
    },
    {
      key: 'einnahmen',
      width: 180,
    },
    {
      key: 'saldo',
      width: 120,
    },
  ];
};

export default objektAbrechnungUstVomAufwandKontoTableColumns;
