import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontenplanFieldsFragmentDoc, KontoFieldsFragmentDoc } from './KontenplanFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KontenplanQueryVariables = Types.Exact<{ [key: string]: never }>;

export type KontenplanQuery = {
  getKontenplan: {
    data: {
      createdBy?: string | null;
      createTs: string;
      createdByMitarbeiterId?: string | null;
      kontenplanId: string;
      hauptUmsatzsteuerKonto?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      } | null;
      hauptVorsteuerKonto?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type KontoListQueryVariables = Types.Exact<{
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  klasseList?: Types.InputMaybe<Array<Types.KontoKlasse> | Types.KontoKlasse>;
  order?: Types.InputMaybe<Types.KontoOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  type?: Types.InputMaybe<Types.KontoType>;
  verwendung?: Types.InputMaybe<Types.KontoVerwendung>;
  hvKonto?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type KontoListQuery = {
  getKontoList: {
    data: {
      contentList: Array<{
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type KontoQueryVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type KontoQuery = {
  getKonto: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      haupt: boolean;
      kontoId: string;
      kontoTemplateId?: string | null;
      nummer: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      klasse: { text: string; value: Types.KontoKlasse };
      status: { description?: string | null; text: string; value: Types.KontoStatus };
      type: { text: string; value: Types.KontoType };
      verwendung?: { text: string; value: Types.KontoVerwendung } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type KontoChangeHistoryListQueryVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
}>;

export type KontoChangeHistoryListQuery = {
  getKontoChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const KontenplanDocument = gql`
  query Kontenplan {
    getKontenplan {
      data {
        ...KontenplanFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontenplanFieldsFragmentDoc}
`;
export function useKontenplanQuery(baseOptions?: Apollo.QueryHookOptions<KontenplanQuery, KontenplanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontenplanQuery, KontenplanQueryVariables>(KontenplanDocument, options);
}
export function useKontenplanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KontenplanQuery, KontenplanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontenplanQuery, KontenplanQueryVariables>(KontenplanDocument, options);
}
export function useKontenplanSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontenplanQuery, KontenplanQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontenplanQuery, KontenplanQueryVariables>(KontenplanDocument, options);
}
export type KontenplanQueryHookResult = ReturnType<typeof useKontenplanQuery>;
export type KontenplanLazyQueryHookResult = ReturnType<typeof useKontenplanLazyQuery>;
export type KontenplanSuspenseQueryHookResult = ReturnType<typeof useKontenplanSuspenseQuery>;
export type KontenplanQueryResult = Apollo.QueryResult<KontenplanQuery, KontenplanQueryVariables>;
export const KontoListDocument = gql`
  query KontoList(
    $includeArchiviert: Boolean
    $klasseList: [KontoKlasse!]
    $order: KontoOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
    $rechtstraegerId: String
    $type: KontoType
    $verwendung: KontoVerwendung
    $hvKonto: Boolean
  ) {
    getKontoList(
      includeArchiviert: $includeArchiviert
      klasseList: $klasseList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
      rechtstraegerId: $rechtstraegerId
      type: $type
      verwendung: $verwendung
      hvKonto: $hvKonto
    ) {
      data {
        contentList {
          ...KontoFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontoFieldsFragmentDoc}
`;
export function useKontoListQuery(baseOptions?: Apollo.QueryHookOptions<KontoListQuery, KontoListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoListQuery, KontoListQueryVariables>(KontoListDocument, options);
}
export function useKontoListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KontoListQuery, KontoListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoListQuery, KontoListQueryVariables>(KontoListDocument, options);
}
export function useKontoListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoListQuery, KontoListQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoListQuery, KontoListQueryVariables>(KontoListDocument, options);
}
export type KontoListQueryHookResult = ReturnType<typeof useKontoListQuery>;
export type KontoListLazyQueryHookResult = ReturnType<typeof useKontoListLazyQuery>;
export type KontoListSuspenseQueryHookResult = ReturnType<typeof useKontoListSuspenseQuery>;
export type KontoListQueryResult = Apollo.QueryResult<KontoListQuery, KontoListQueryVariables>;
export const KontoDocument = gql`
  query Konto($kontoId: ID!, $withDetails: Boolean) {
    getKonto(kontoId: $kontoId, withDetails: $withDetails) {
      data {
        ...KontoFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontoFieldsFragmentDoc}
`;
export function useKontoQuery(
  baseOptions: Apollo.QueryHookOptions<KontoQuery, KontoQueryVariables> & ({ variables: KontoQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoQuery, KontoQueryVariables>(KontoDocument, options);
}
export function useKontoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KontoQuery, KontoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoQuery, KontoQueryVariables>(KontoDocument, options);
}
export function useKontoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoQuery, KontoQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoQuery, KontoQueryVariables>(KontoDocument, options);
}
export type KontoQueryHookResult = ReturnType<typeof useKontoQuery>;
export type KontoLazyQueryHookResult = ReturnType<typeof useKontoLazyQuery>;
export type KontoSuspenseQueryHookResult = ReturnType<typeof useKontoSuspenseQuery>;
export type KontoQueryResult = Apollo.QueryResult<KontoQuery, KontoQueryVariables>;
export const KontoChangeHistoryListDocument = gql`
  query KontoChangeHistoryList($kontoId: ID!) {
    getKontoChangeHistoryList(kontoId: $kontoId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useKontoChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<KontoChangeHistoryListQuery, KontoChangeHistoryListQueryVariables> &
    ({ variables: KontoChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoChangeHistoryListQuery, KontoChangeHistoryListQueryVariables>(KontoChangeHistoryListDocument, options);
}
export function useKontoChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KontoChangeHistoryListQuery, KontoChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoChangeHistoryListQuery, KontoChangeHistoryListQueryVariables>(KontoChangeHistoryListDocument, options);
}
export function useKontoChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoChangeHistoryListQuery, KontoChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoChangeHistoryListQuery, KontoChangeHistoryListQueryVariables>(KontoChangeHistoryListDocument, options);
}
export type KontoChangeHistoryListQueryHookResult = ReturnType<typeof useKontoChangeHistoryListQuery>;
export type KontoChangeHistoryListLazyQueryHookResult = ReturnType<typeof useKontoChangeHistoryListLazyQuery>;
export type KontoChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useKontoChangeHistoryListSuspenseQuery>;
export type KontoChangeHistoryListQueryResult = Apollo.QueryResult<KontoChangeHistoryListQuery, KontoChangeHistoryListQueryVariables>;
