import styled from 'styled-components';

export const StyledObjAbrKontoEinnahmenDetailsTable = styled('table')`
  thead {
    tr {
      th {
        &:nth-child(1) {
          text-align: left !important;
        }
        text-align: right !important;
        &:nth-last-child(2) {
          width: 10%;
        }
        &:last-child {
          width: 5.5%;
        }
      }
    }
  }
  tbody {
    text-align: right !important;
    tr {
      td {
        &:nth-child(1) {
          text-align: left !important;
        }
        justify-content: flex-end;
        &:nth-last-child(2) {
          width: 10%;
        }
        &:last-child {
          width: 5.5%;
        }
      }
      &:last-child td {
        border: 0;
      }
    }
  }
`;

export const StyledObjAbrKontoAusgabenDetailsTable = styled('table')`
  thead {
    th {
      &:nth-child(1) {
        text-align: left !important;
      }
      text-align: right !important;
      &:nth-last-child(2) {
        width: 10%;
      }
      &:last-child {
        width: 23%;
      }
    }
  }
  tbody {
    text-align: right !important;
    tr {
      td {
        &:nth-child(1) {
          text-align: left !important;
        }
        &:nth-last-child(2) {
          width: 10%;
        }
        &:last-child {
          width: 23%;
        }
        justify-content: flex-end;
      }
      &:last-child td {
        border: 0;
      }
    }
  }
`;
