import React, { FC } from 'react';
import { Select, Spin } from 'antd';
import { useRechtstraegerListQuery } from '../../../../../Rechtstraeger/gql/RechtsTraegerQueries.types';

type Props = {
  buchungskreisRechtstraegerId: string;
};

const BuchungskreisSelect: FC<Props> = ({ buchungskreisRechtstraegerId }) => {
  const { data, loading } = useRechtstraegerListQuery({
    variables: {
      hasBuchungskreis: true,
    },
  });

  const rechtstraegerWithBuchungskreisList = data?.getRechtstraegerList.data.contentList || [];

  const rechtstraegerData = rechtstraegerWithBuchungskreisList
    .filter((rechtstraeger) => rechtstraeger.rechtstraegerId === buchungskreisRechtstraegerId)
    .map((rt) => ({ text: rt.kurzBezeichnung, value: rt.buchungskreisId }));

  return loading ? (
    <Spin />
  ) : (
    <Select
      loading={loading}
      disabled
      defaultValue={rechtstraegerData[0].text}
      style={{ width: '100%' }}
      size="small"
      options={[rechtstraegerData]}
    />
  );
};

export default BuchungskreisSelect;
