import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { Notification, PubSubNotification } from '../../../types';
import { capitalizedNotificationType, isNotificationTypeBuchungsanweisung } from './notificationHelpers';
import { generatePathFromNotificationToBuchungsmaskeOrGenerierlauf } from '../notificationUriPaths';

type Props = {
  notification: Notification | PubSubNotification;
};
const NotificationTypeLink: FC<Props> = ({ notification }) => {
  const notificationType = notification.notificationType;
  return isNotificationTypeBuchungsanweisung(notificationType) ? (
    <Link to={generatePathFromNotificationToBuchungsmaskeOrGenerierlauf(notification)}>{capitalizedNotificationType(notificationType)}</Link>
  ) : (
    <Typography.Text>{capitalizedNotificationType(notificationType)}</Typography.Text>
  );
};

export default NotificationTypeLink;
