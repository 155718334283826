import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import useDebounce from '../../../../hooks/useDebounce';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';
import { useFilterReceivedBankTransactionEndToEndIdListQuery } from '../../gql/ReceivedBankTransactionQueries.types';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../../helpers/stringHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  endToEndId?: string | null;
} & Omit<
  SelectProps,
  | 'id'
  | 'placeholder'
  | 'size'
  | 'allowClear'
  | 'showSearch'
  | 'filterOption'
  | 'onSelect'
  | 'onSearch'
  | 'onClear'
  | 'searchValue'
  | 'notFoundContent'
>;

const ReceivedBankTransactionEndToEndIdSelect: FC<Props> = ({ endToEndId, ...restProps }) => {
  const [text, setText] = useState<string>(endToEndId ? endToEndId.toString() : '');
  const debouncedText = useDebounce(text);

  const { loading, data } = useFilterReceivedBankTransactionEndToEndIdListQuery({
    variables: {
      endToEndId: debouncedText as string,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const endToEndIdList = data?.getFilterReceivedBankTransactionEndToEndIdList.data ?? [];

  return (
    <Select
      {...restProps}
      id={restProps.name}
      loading={loading}
      size="small"
      allowClear
      showSearch
      placeholder="Text auswählen"
      filterOption={selectFilterOption}
      onSelect={setText}
      onSearch={(value) => {
        text.length === 1 && !value && setText('');
        value && setText(value);
      }}
      onClear={() => setText('')}
      searchValue={text}
      notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
    >
      {endToEndIdList?.map((endToEndId) => (
        <Select.Option key={endToEndId} value={endToEndId}>
          <DataWithShortenedText text={endToEndId} maxTextLength={40} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default ReceivedBankTransactionEndToEndIdSelect;
