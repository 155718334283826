import React, { FC, JSX } from 'react';
import { Divider, Space } from 'antd';

/*
 * Component used only within FiltersWithMax3Fields and FiltersWith4OrMoreFields
 * It should not be used in other components as mentioned above
 * */

type FiltersWithoutTitleAndActionsProps = {
  styledFilters: JSX.Element;
};

const FiltersWithoutTitleAndActions: FC<FiltersWithoutTitleAndActionsProps> = ({ styledFilters }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    {styledFilters}
    <Divider style={{ marginTop: '8px', marginBottom: '24px' }} />
  </Space>
);
export default FiltersWithoutTitleAndActions;
