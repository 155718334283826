import React, { FC } from 'react';
import { Drawer, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import { TicketTemplate } from '../../../types';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import TicketTemplateForm from '../Form/TicketTemplateForm';
import { useMutationHookResultsProvider } from '../TicketTemplateMutationHookResultProvider';
import TicketTemplateChangeHistoryListingTable from './TicketTemplateChangeHistoryListingTable';
import ActionDropdown from '../../../components/Dropdown/ActionDropdown';

type Props = {
  ticketTemplate: TicketTemplate;
  ticketTemplateList?: TicketTemplate[];
  parentTicketTemplate?: TicketTemplate;
};

const TicketTemplateTableActions: FC<Props> = ({ ticketTemplate, ticketTemplateList, parentTicketTemplate }) => {
  const [isCollapsed, toggleIsCollapsed] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const {
    runDelete: [runDelete],
  } = useMutationHookResultsProvider();

  const runDeleteAction = () => runDelete({ variables: { ticketTemplateId: ticketTemplate.ticketTemplateId } });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Bearbeiten',
      onClick: toggleIsCollapsed,
      icon: <EditOutlined />,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: showConfirmDelete(runDeleteAction, ticketTemplate),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Drawer
        title={`${!ticketTemplate.isSubTicketTemplate ? 'Aufgabenart' : 'Unteraufgabenart'} "${ticketTemplate.name}" bearbeiten`}
        width={720}
        onClose={toggleIsCollapsed}
        open={isCollapsed}
        destroyOnClose
      >
        <TicketTemplateForm
          parentTicketTemplate={parentTicketTemplate}
          ticketTemplate={ticketTemplate}
          ticketTemplateList={ticketTemplateList}
          onSuccess={() => toggleIsCollapsed(false)}
          onCancel={toggleIsCollapsed}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <TicketTemplateChangeHistoryListingTable ticketTemplateId={ticketTemplate.ticketTemplateId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, ticketTemplate: TicketTemplate) => () => {
  Modal.confirm({
    title: `Möchten Sie die Aufgabenart löschen?`,
    content: `${ticketTemplate.name}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default TicketTemplateTableActions;
