import React from 'react';
import { Space, Tag } from 'antd';
import ObjektVorschreibungPositionTableActions from './ObjektVorschreibungPositionTableActions';
import { generatePathToVorschreibungspositionenPage } from '../../../Vorschreibungsposition/vorschreibungspositionUriPaths';
import StatusBadge from '../../../../components/Status/StatusBadge';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { ObjektVorschreibungsposition, Verwaltungsart } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';

const objektVorschreibungPositionTableColumns = (
  objektId: string,
  onAction: () => void,
  verwaltungsart: Verwaltungsart
): TableWithColSelectorColumnProps<ObjektVorschreibungsposition>[] => [
  {
    title: 'Kurzbezeichnung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.kurzBezeichnung, b.kurzBezeichnung),
    render: (text, record) => (
      <DataWithStatus
        status={record.objektVorschreibungspositionStatus}
        text={record.kurzBezeichnung}
        showBadgeDot={false}
        showStatusDescription={false}
      />
    ),
  },
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    width: '250px',
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    render: (text, record) => (
      <LinkWithStatus
        status={record.vorschreibungspositionStatus}
        text={record.bezeichnung}
        to={generatePathToVorschreibungspositionenPage(record.vorschreibungspositionId, record.vorschreibungspositionStatus)}
        isLinkOnlyForAdmin
        maxTextLength={30}
      />
    ),
  },
  {
    title: 'VS-Pos. Art',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.art.text, b.art.text),
    render: (text, record) => (
      <DataWithStatus status={record.objektVorschreibungspositionStatus} text={record.art.text} showBadgeDot={false} showStatusDescription={false} />
    ),
  },
  {
    title: 'Merkmale',
    defaultSelected: true,
    render: (text, record) => (
      <Space>
        {record.tagList.map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </Space>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.objektVorschreibungspositionStatus.text, b.objektVorschreibungspositionStatus.text),
    render: (text, record) => <StatusBadge entityStatus={record.objektVorschreibungspositionStatus} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => (
      <DataWithStatus text={record.createTs} status={record.objektVorschreibungspositionStatus} showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    key: 'action',
    render: (text, record) => (
      <ObjektVorschreibungPositionTableActions
        vorschreibungsposition={record}
        objektId={objektId}
        onAction={onAction}
        verwaltungsart={verwaltungsart}
      />
    ),
  },
];

export default objektVorschreibungPositionTableColumns;
