import React, { FC } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../components/DatePicker/DatePicker_formikAntD';
import FormButtons from '../../../../../components/Button/FormButtons';
import { FilterFormValues, formFormFields, formInitialValues, mapFormValuesToCreateInput, mapQueryParamsAndFormValuesToArgs } from './formMapper';
import { bookValidationSchema } from './bookValidationSchema';
import { showSuccessMsgOther } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import {
  useBookReceivedBankTransactionMutation,
  useCreateReceivedBankTransactionVerbuchenGenerierlaufMutation,
} from '../../../gql/ReceivedBankTransactionMutations.types';
import { TReceivedBankTransactionQueryParams } from '../../Filters/filtersQueryParams';

type Props = {
  onAction: () => void;
  onCancel: () => void;
  // Either one has to be defined
  queryParams?: TReceivedBankTransactionQueryParams; // For Auf Liste verwenden - verbuchen
  receivedBankTransactionId?: string; // For single item verbuchen
};

const BookForm: FC<Props> = ({ onAction, queryParams, onCancel, receivedBankTransactionId }) => {
  const [runVerbuchen] = useCreateReceivedBankTransactionVerbuchenGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgOther('Empf. Bankbuchung werden verbucht');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runBook] = useBookReceivedBankTransactionMutation({
    onCompleted: () => {
      showSuccessMsgOther('Empf. Bankbuchung wurde verbucht');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<FilterFormValues>
      initialValues={formInitialValues}
      validationSchema={bookValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToCreateInput(values);
        if (receivedBankTransactionId) {
          runBook({ variables: { buchungsdatum: input.buchungsdatum, receivedBankTransactionId } }).finally(() => setSubmitting(false));
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          runVerbuchen({ variables: { ...mapQueryParamsAndFormValuesToArgs(queryParams!, input) } }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon name={formFormFields.buchungsdatum} label="Buchungsdatum">
            <DatePicker
              id={formFormFields.buchungsdatum}
              name={formFormFields.buchungsdatum}
              placeholder="Datum wählen"
              format={DATE_DISPLAY_FORMAT_DEFAULT}
              allowClear={false}
              style={{ width: '100%' }}
              size="small"
            />
          </FormItemWithoutColon>
          <FormButtons onCancel={onCancel} updateMode={false} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default BookForm;
