import { GenerierlaufEntryExitCode, PaymentTransactionType, PaymentType } from '../../../../../types';
import { TBookingSuggestionVerbuchenQueryParams } from './filtersQueryParams';
import { FormFields } from '../../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../../shared/typeHelpers';

export type FiltersFormValues = {
  objektId?: string | null;
  paymentType?: PaymentType | null;
  paymentTransactionType?: PaymentTransactionType | null;
  buchungskreisRechtstraegerId?: string | null;
  exitCodeList?: GenerierlaufEntryExitCode[] | null;
};

export const listingFiltersFormFields: FormFields<FiltersFormValues> = {
  objektId: 'objektId',
  paymentType: 'paymentType',
  paymentTransactionType: 'paymentTransactionType',
  buchungskreisRechtstraegerId: 'buchungskreisRechtstraegerId',
  exitCodeList: 'exitCodeList',
};

export const mapQueryParamsToFormValues = (queryParams: TBookingSuggestionVerbuchenQueryParams): FiltersFormValues => ({
  objektId: queryParams.objektId,
  paymentType: queryParams.paymentType,
  paymentTransactionType: queryParams.paymentTransactionType,
  buchungskreisRechtstraegerId: queryParams.buchungskreisRechtstraegerId,
  exitCodeList: queryParams.exitCodeList,
});

export const listingLabelList: ObjectOfStrings<FiltersFormValues> = {
  objektId: 'Objekt',
  paymentType: 'Zahlungsart',
  paymentTransactionType: 'Zahlungsverkehrsart',
  buchungskreisRechtstraegerId: 'Rechnungsaussteller',
  exitCodeList: 'Status',
};
