import React, { FC } from 'react';
import { Checkbox, DatePicker, Input, InputNumber } from 'formik-antd';
import KuendigungDurchSelect from '../shared/KuendigungDurchSelect';
import { FormFields } from '../../../../../../../helpers/formikHelper';
import { KuendigungsinfosCreateFormValues } from '../Create/kuendigungsinfosCreateFormMapper';
import DatenTabColumnsLayout from '../../../../../../Objekt/InfoFeld/Form/shared/DatenTabColumnsLayout';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../../../components/DatePicker/DatePicker_formikAntD';
import { KuendigungsinfosUpdateFormValues } from '../Update/kuendigungsinfosUpdateFormMapper';
import FormItemWithFieldHelp from '../../../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formFields: FormFields<KuendigungsinfosCreateFormValues | KuendigungsinfosUpdateFormValues>;
};

const KuendigungsinfosDatenTab: FC<Props> = ({ formFields }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'VertragInfoFeldKuendigungsinfos'>('VertragInfoFeldKuendigungsinfos');

  return (
    <DatenTabColumnsLayout
      leftColumn={
        <>
          <FormItemWithFieldHelp
            name={formFields.kuendigungBis}
            label="Kündigung bis"
            fieldHelp={getFieldHelpText('VertragInfoFeldKuendigungsinfos.kuendigungBis')}
          >
            <DatePicker
              size="small"
              id={formFields.kuendigungBis}
              name={formFields.kuendigungBis}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.kuendigungsverzichtBestandgeberBis}
            label="Kündigungsverzicht Bestandgeber bis"
            fieldHelp={getFieldHelpText('VertragInfoFeldKuendigungsinfos.kuendigungsverzichtBestandgeberBis')}
          >
            <DatePicker
              size="small"
              id={formFields.kuendigungsverzichtBestandgeberBis}
              name={formFields.kuendigungsverzichtBestandgeberBis}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.kuendigungsverzichtBestandnehmerBis}
            label="Kündigungsverzicht Bestandnehmer bis"
            fieldHelp={getFieldHelpText('VertragInfoFeldKuendigungsinfos.kuendigungsverzichtBestandnehmerBis')}
          >
            <DatePicker
              size="small"
              id={formFields.kuendigungsverzichtBestandnehmerBis}
              name={formFields.kuendigungsverzichtBestandnehmerBis}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.bestandnehmerKuendigungsfrist}
            label="Bestandnehmer Kündigungsfrist (in Monaten)"
            fieldHelp={getFieldHelpText('VertragInfoFeldKuendigungsinfos.bestandnehmerKuendigungsfrist')}
          >
            <InputNumber
              name={formFields.bestandnehmerKuendigungsfrist}
              size="small"
              min={1}
              max={12}
              style={{ width: '100%' }}
              placeholder="Monat"
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.kuendigungDurch}
            label="Kündigung durch"
            fieldHelp={getFieldHelpText('VertragInfoFeldKuendigungsinfos.kuendigungDurch')}
          >
            <KuendigungDurchSelect name={formFields.kuendigungDurch} />
          </FormItemWithFieldHelp>
        </>
      }
      rightColumn={
        <>
          <FormItemWithFieldHelp
            name={formFields.kuendigungJeweilsZum}
            label="Kündigung jeweils zum"
            fieldHelp={getFieldHelpText('VertragInfoFeldKuendigungsinfos.kuendigungJeweilsZum')}
          >
            <DatePicker
              size="small"
              id={formFields.kuendigungJeweilsZum}
              name={formFields.kuendigungJeweilsZum}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.kuendigungMoeglichZum}
            label="Kündigung möglich zum"
            fieldHelp={getFieldHelpText('VertragInfoFeldKuendigungsinfos.kuendigungMoeglichZum')}
          >
            <Input id={formFields.kuendigungMoeglichZum} name={formFields.kuendigungMoeglichZum} size="small" placeholder="Monat, Quartal, Jahr" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.kuendigungErfolgtAm}
            label="Kündigung erfolgt am"
            fieldHelp={getFieldHelpText('VertragInfoFeldKuendigungsinfos.kuendigungErfolgtAm')}
          >
            <DatePicker
              size="small"
              id={formFields.kuendigungErfolgtAm}
              name={formFields.kuendigungErfolgtAm}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.kuendigungWirksamZum}
            label="Kündigung wirksam zum"
            fieldHelp={getFieldHelpText('VertragInfoFeldKuendigungsinfos.kuendigungWirksamZum')}
          >
            <DatePicker
              size="small"
              id={formFields.kuendigungWirksamZum}
              name={formFields.kuendigungWirksamZum}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.gerichtlicheKuendigung}
            label="Gerichtliche Kündigung"
            fieldHelp={getFieldHelpText('VertragInfoFeldKuendigungsinfos.gerichtlicheKuendigung')}
          >
            <Checkbox name={formFields.gerichtlicheKuendigung} id={formFields.gerichtlicheKuendigung} />
          </FormItemWithFieldHelp>
        </>
      }
    />
  );
};

export default KuendigungsinfosDatenTab;
