import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { Radio } from 'formik-antd';
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined } from '@ant-design/icons';
import { DocumentHeaderAndFooterImageAlignment } from '../../../../../../types';
import FieldHelpIconWithTooltip from '../../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  name: string;
  fieldHelp?: string | null;
};

const ReportLogosImageAlignment: FC<Props> = ({ name, fieldHelp }) => {
  const fieldName = `${name}Alignment`;

  return (
    <Space>
      <Typography.Text>
        Ausrichtung <FieldHelpIconWithTooltip fieldHelp={fieldHelp} />
      </Typography.Text>
      <Radio.Group name={fieldName} size="small" block>
        <Radio.Button value={DocumentHeaderAndFooterImageAlignment.Left}>
          <AlignLeftOutlined />
        </Radio.Button>
        <Radio.Button value={DocumentHeaderAndFooterImageAlignment.Center}>
          <AlignCenterOutlined />
        </Radio.Button>
        <Radio.Button value={DocumentHeaderAndFooterImageAlignment.Right}>
          <AlignRightOutlined />
        </Radio.Button>
      </Radio.Group>
    </Space>
  );
};

export default ReportLogosImageAlignment;
