import React from 'react';
import { SubAbrAbrechnungskreisList } from '../../../../../../../types';
import { NestedTableWithColSelectorColProps } from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import DataWithShortenedText from '../../../../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount } from '../../../../../../../components/Number';

const einnahmenTableColumns: NestedTableWithColSelectorColProps<SubAbrAbrechnungskreisList>[] = [
  {
    title: 'Position',
    defaultSelected: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={25} text={record.abrechnungskreisType.text} />,
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
    key: 'einnahmenAusgaben',
    width: 180,
  },
  {
    title: '',
    key: 'summe',
  },
  {
    title: 'Summe',
    key: 'ergebnis',
    align: 'right',
    defaultSelected: true,
    render: (text, record) => <EuroAmount value={record.summe} />,
  },
];

export default einnahmenTableColumns;
