import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { TEbicsUserQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import {
  ebicsUserListingFiltersFormInitialValues,
  EbicsUserListingFiltersFormValues,
  mapFormValuesToEbicsUserListingFilters,
} from './Filters/ebicsUserListingFiltersFormMapper';
import EbicsUserListingForm from './EbicsUserListingForm';

const EbicsUserListing: FC = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TEbicsUserQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: EbicsUserListingFiltersFormValues) => {
    const filters = mapFormValuesToEbicsUserListingFilters(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  return (
    <Formik<EbicsUserListingFiltersFormValues> initialValues={ebicsUserListingFiltersFormInitialValues(queryParams)} onSubmit={onChange}>
      {(formikProps) => <EbicsUserListingForm queryParams={queryParams} formikProps={formikProps} />}
    </Formik>
  );
};

export default EbicsUserListing;
