import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import {
  HeVertragZahlungsregel,
  HeVertragZahlungsregelCreateInput,
  HeVertragZahlungsregelType,
  HeVertragZahlungsregelUpdateInput,
  Turnus,
} from '../../../../../types';

export type ZahlungsregelFormValues = {
  beendetAm?: string | null;
  bezeichnung: string;
  durchfuehrenAm: number | null;
  fixbetrag?: number | null;
  heAbrechnungsdefinitionId?: string | null;
  periode: Turnus | null;
  prozentsatz?: number | null;
  turnus?: Turnus | null;
  type: HeVertragZahlungsregelType;
  verwendungszweck?: string | null;
  zahlungsempfaengerBankDetailsId?: string | null;
  zahlungsempfaengerId: string;
  zahlungsreferenz?: string | null;
};

export const zahlungsregelFormFields: FormFields<ZahlungsregelFormValues> = {
  beendetAm: 'beendetAm',
  bezeichnung: 'bezeichnung',
  durchfuehrenAm: 'durchfuehrenAm',
  fixbetrag: 'fixbetrag',
  heAbrechnungsdefinitionId: 'heAbrechnungsdefinitionId',
  periode: 'periode',
  prozentsatz: 'prozentsatz',
  turnus: 'turnus',
  type: 'type',
  verwendungszweck: 'verwendungszweck',
  zahlungsempfaengerBankDetailsId: 'zahlungsempfaengerBankDetailsId',
  zahlungsempfaengerId: 'zahlungsempfaengerId',
  zahlungsreferenz: 'zahlungsreferenz',
};

const zahlungsregelFormInitialValues = (vertragspartnerId?: string): ZahlungsregelFormValues => ({
  bezeichnung: '',
  type: HeVertragZahlungsregelType.Fixbetrag,
  zahlungsempfaengerId: vertragspartnerId ?? '',
  zahlungsempfaengerBankDetailsId: '',
  periode: null,
  durchfuehrenAm: null,
  verwendungszweck: null,
  fixbetrag: null,
  prozentsatz: null,
  turnus: null,
});

export const mapZahlungsregelToFormValues = (zahlungsregel?: HeVertragZahlungsregel, vertragspartnerId?: string): ZahlungsregelFormValues => {
  if (!zahlungsregel) {
    return zahlungsregelFormInitialValues(vertragspartnerId);
  }

  return {
    bezeichnung: zahlungsregel?.bezeichnung,
    type: zahlungsregel.type.value,
    zahlungsempfaengerId: zahlungsregel.zahlungsempfaenger.rechtstraegerId,
    zahlungsempfaengerBankDetailsId: zahlungsregel.zahlungsempfaengerBankDetails?.bankDetailsId,
    periode: zahlungsregel.periode.value,
    durchfuehrenAm: zahlungsregel.durchfuehrenAm,
    beendetAm: zahlungsregel.beendetAm,
    verwendungszweck: zahlungsregel.verwendungszweck,
    zahlungsreferenz: zahlungsregel.zahlungsreferenz,
    prozentsatz: zahlungsregel.prozentsatz,
    fixbetrag: zahlungsregel.fixbetrag,
    turnus: zahlungsregel.turnus?.value,
    heAbrechnungsdefinitionId: zahlungsregel.heAbrechnungsdefinitionId,
  };
};

export const mapFormValuesToZahlungsregel = (
  values: ZahlungsregelFormValues
): HeVertragZahlungsregelCreateInput | HeVertragZahlungsregelUpdateInput => ({
  bezeichnung: values.bezeichnung,
  type: values.type,
  zahlungsempfaengerId: values.zahlungsempfaengerId,
  zahlungsempfaengerBankDetailsId: values.zahlungsempfaengerBankDetailsId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  periode: values.periode!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  durchfuehrenAm: values.durchfuehrenAm!,
  beendetAm: values.beendetAm ? mapFormDateValueToDateString(values.beendetAm) : undefined,
  verwendungszweck: values.verwendungszweck,
  zahlungsreferenz: values.zahlungsreferenz,
  prozentsatz: values.prozentsatz,
  fixbetrag: values.fixbetrag,
  turnus: values.turnus,
});
