import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import { Col, Divider, Row, Typography } from 'antd';
import PersonFormPart from '../../../../shared/components/Person/PersonFormPart';
import SpracheSelect from '../../Sprache/SpracheSelect';
import { natuerlichePersonCreateFormValidationSchema, natuerlichePersonUpdateFormValidationSchema } from './natuerlichePersonFormValidationSchema';
import {
  mapFormValuesToNatuerlichePersonCreate,
  mapFormValuesToNatuerlichePersonUpdate,
  mapNatuerlichePersonToFormValues,
  natuerlichePersonFormFields,
  NatuerlichePersonFormValues,
} from './natuerlichePersonFormMapper';
import { getKurzBezeichnungVorschlagFieldsToBeDebounced, getNatuerlichePersonKurzBezeichnungVorschlag } from './natuerlichePersonFormHelper';
import FormButtons from '../../../../components/Button/FormButtons';
import { useCreateNatuerlichePersonMutation, useUpdateNatuerlichePersonMutation } from '../gql/NatuerlichePersonMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import KurzBezeichnungFormPart from '../../../../shared/components/KurzBezeichnung/form/KurzBezeichnungFormPart';
import SteuerpflichtSelect from '../../../../shared/components/SteuerpflichtSelect/SteuerpflichtSelect';
import { showSuccessMsgCreateWithPath, showSuccessMsgUpdate } from '../../../../components/message/message';
import { generatePathToRechtstraegerDetailsPage } from '../../rechtstraegerHelper';
import HauptAddressOptionalFormPart from '../../HauptAddress/HauptAddressOptionalFormPart';
import { NatuerlichePerson, RechtstraegerType } from '../../../../types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  natuerlichePerson?: NatuerlichePerson;
  kundenSystemId: string;
  onSuccess: (rechtstraegerId: string, kurzBezeichnung: string, type: { value: RechtstraegerType }) => void;
  onCancel: () => void;
};

const NatuerlichePersonForm: FC<Props> = ({ natuerlichePerson, kundenSystemId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'RechtstraegerNatuerlichePerson'>('RechtstraegerNatuerlichePerson');

  const initialValues = mapNatuerlichePersonToFormValues(natuerlichePerson);
  const isUpdate = !!natuerlichePerson;

  const entity = `Natürliche Person`;

  const [runCreate, { loading: loadingCreate }] = useCreateNatuerlichePersonMutation({
    onCompleted: (data) => {
      const { kurzBezeichnung, rechtstraegerId, type } = data.createNatuerlichePerson.data;
      showSuccessMsgCreateWithPath(entity, kurzBezeichnung, generatePathToRechtstraegerDetailsPage(rechtstraegerId));
      onSuccess(rechtstraegerId, kurzBezeichnung, type);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateNatuerlichePersonMutation({
    onCompleted: (data) => {
      const { rechtstraegerId, kurzBezeichnung, type } = data.updateNatuerlichePerson.data;
      showSuccessMsgUpdate(entity);
      onSuccess(rechtstraegerId, kurzBezeichnung, type);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<NatuerlichePersonFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={isUpdate ? natuerlichePersonUpdateFormValidationSchema : natuerlichePersonCreateFormValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        if (!isUpdate) {
          runCreate({ variables: { request: mapFormValuesToNatuerlichePersonCreate(formValues) } }).finally(() => formikHelpers.setSubmitting(false));
        } else {
          runUpdate({
            variables: { request: mapFormValuesToNatuerlichePersonUpdate(formValues), rechtstraegerId: natuerlichePerson.rechtstraegerId },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical" autoComplete="off">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Einstellungen</Typography.Title>
            </Col>
            <Col span={18}>
              <SpracheSelect fieldHelp={getFieldHelpText('RechtstraegerNatuerlichePerson.sprache')} />
              <FormItemWithFieldHelp
                label="Merkmale"
                name={natuerlichePersonFormFields.tagList}
                fieldHelp={getFieldHelpText('RechtstraegerNatuerlichePerson.tagList')}
              >
                <Select
                  id={natuerlichePersonFormFields.tagList}
                  name={natuerlichePersonFormFields.tagList}
                  mode="tags"
                  placeholder="z.B. Berufsgruppe, Weihnachskarte"
                />
              </FormItemWithFieldHelp>
            </Col>
          </Row>
          <Divider />
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Persönliche Daten</Typography.Title>
            </Col>
            <Col span={18}>
              <PersonFormPart
                kundenSystemId={kundenSystemId}
                fieldHelpNames={{
                  firstname: getFieldHelpText('RechtstraegerNatuerlichePerson.firstname'),
                  lastname: getFieldHelpText('RechtstraegerNatuerlichePerson.lastname'),
                  salutation: getFieldHelpText('RechtstraegerNatuerlichePerson.salutation'),
                  title: getFieldHelpText('RechtstraegerNatuerlichePerson.title'),
                  titleTrailing: getFieldHelpText('RechtstraegerNatuerlichePerson.titleTrailing'),
                }}
              />
              <KurzBezeichnungFormPart<NatuerlichePersonFormValues>
                placeholder="Kurzbezeichnung"
                kurzBezeichnungVorschlagConfig={{
                  getFieldsToBeDebounced: getKurzBezeichnungVorschlagFieldsToBeDebounced,
                  getKurzBezeichnungVorschlagFn: getNatuerlichePersonKurzBezeichnungVorschlag,
                }}
                formikProps={formikProps}
                fieldHelp={getFieldHelpText('RechtstraegerNatuerlichePerson.kurzBezeichnung')}
              />
            </Col>
          </Row>
          {!isUpdate && (
            <>
              <Divider />
              <Row align="top">
                <Col span={6}>
                  <h3>Hauptadresse</h3>
                </Col>
                <Col span={18}>
                  <HauptAddressOptionalFormPart
                    fieldHelpNames={{
                      city: getFieldHelpText('RechtstraegerNatuerlichePerson.hauptAddress.city'),
                      cityAdditionalInformation: getFieldHelpText('RechtstraegerNatuerlichePerson.hauptAddress.cityAdditionalInformation'),
                      countryCodeIso2: getFieldHelpText('RechtstraegerNatuerlichePerson.hauptAddress.countryCodeIso2'),
                      houseEntranceApartmentNumber: getFieldHelpText('RechtstraegerNatuerlichePerson.hauptAddress.houseEntranceApartmentNumber'),
                      street: getFieldHelpText('RechtstraegerNatuerlichePerson.hauptAddress.street'),
                      zipCode: getFieldHelpText('RechtstraegerNatuerlichePerson.hauptAddress.zipCode'),
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          {!isUpdate && (
            <>
              <Divider />
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Finanz/Steuerparameter</Typography.Title>
                </Col>
                <Col span={18}>
                  <SteuerpflichtSelect fieldHelp={getFieldHelpText('RechtstraegerNatuerlichePerson.steuerpflicht.steuerpflicht')} />
                </Col>
              </Row>
            </>
          )}
          <FormButtons
            updateMode={isUpdate}
            isOkDisabled={formikProps.isSubmitting}
            onCancel={onCancel}
            isOkLoading={loadingUpdate || loadingCreate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default NatuerlichePersonForm;
