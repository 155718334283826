import React, { FC } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import {
  mapFormValuesToTopAbrechnungCreateInput,
  TopAbrechnungCreateFormValues,
  topAbrechnungsCreateFormInitialValues,
} from './topAbrechnungCreateFormMapper';
import { topAbrechnungCreateValidationSchema } from './topAbrechnungCreateValidationSchema';
import { DATE_DISPLAY_MONTH_FORMAT_DEFAULT } from '../../../../../../components/DatePicker/DatePicker_formikAntD';
import { ObjektAbrechnung } from '../../../../../../types';
import { useCreateFirmendatenTopAbrechnungGenerierlaufMutation } from '../../../../gql/AbrechnungGenerierlauf/AbrechnungGenerierlaufMutations.types';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  objektId: string;
  abrechnung: ObjektAbrechnung;
  onSuccess: () => void;
  onCancel: () => void;
};

const TopAbrechnungCreateForm: FC<Props> = ({ abrechnung, objektId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'TopAbrechnungGenerierlauf'>('TopAbrechnungGenerierlauf');

  const [runCreateTopAbrechnung, { loading }] = useCreateFirmendatenTopAbrechnungGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Topabrechnungen werden angelegt.`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<TopAbrechnungCreateFormValues>
      initialValues={topAbrechnungsCreateFormInitialValues}
      validationSchema={topAbrechnungCreateValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        const input = mapFormValuesToTopAbrechnungCreateInput(abrechnung, formValues, objektId);
        runCreateTopAbrechnung({
          variables: { input },
        }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name="fakturierungsperiode"
            label="Fakturierungsperiode"
            fieldHelp={getFieldHelpText('TopAbrechnungGenerierlauf.fakturierungsperiode.jahr')}
          >
            <DatePicker
              id="fakturierungsperiode"
              name="fakturierungsperiode"
              format={DATE_DISPLAY_MONTH_FORMAT_DEFAULT}
              picker="month"
              style={{ width: '100%' }}
            />
          </FormItemWithFieldHelp>
          <FormButtons
            okText="Topabrechnung erstellen"
            updateMode={false}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TopAbrechnungCreateForm;
