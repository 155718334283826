import React, { FC } from 'react';
import { Space, Tooltip } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { ResponseDataWarning } from '../../types';
import theme from '../../theme';

type Props = {
  warningList: ResponseDataWarning[];
  icon?: React.ReactNode;
};

const EntityWarningList: FC<Props> = ({ warningList, icon }) => {
  return warningList.length > 0 ? (
    <Tooltip
      placement="top"
      title={
        <Space direction="vertical" size={4}>
          {warningList.map((warning) => (
            <React.Fragment key={warning.type}>{warning.message}</React.Fragment>
          ))}
        </Space>
      }
      styles={{ root: { minWidth: '200px', maxWidth: '400px' } }}
    >
      {icon ?? <WarningTwoTone style={{ cursor: 'help' }} twoToneColor={theme.colors.warning} />}
    </Tooltip>
  ) : null;
};

export default EntityWarningList;
