import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { Abrechnungskreis } from '../../../../objektAbrechnung-types';

const OAAbrKreisRepFondsEinnahmenListText: FC<{ abrechKreis: Abrechnungskreis }> = ({ abrechKreis }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight, { marginBottom: '2mm' }]}>
    <View style={[pdfStyles.row, { width: '45%' }]}>
      <Text
        style={[
          pdfStyles.column,
          pdfStyles.textNormal,
          pdfStyles.borderBottomSmall,
          {
            width: '100%',
            textAlign: 'left',
            marginTop: '5mm',
            marginBottom: '2mm',
          },
        ]}
      >
        {abrechKreis.buchungEinnahmenListText}
      </Text>
    </View>
    <View style={[pdfStyles.column, { width: '55%' }]} />
  </View>
);

export default OAAbrKreisRepFondsEinnahmenListText;
