import React from 'react';
import { Route } from 'react-router';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_OBJEKT_DETAILS_PAGE } from '../../features/Objekt/objektUriPaths';
import ObjektDetailsPage from './ObjektDetailsPage';
import AuthRoute from '../../security/AuthRoute';

export type ObjektDetailsPageRouteParams = { objektId: string };

const objektDetailsPageRoute = (
  <Route path={`${URI_OBJEKT_DETAILS_PAGE}/*`} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<ObjektDetailsPage />} />} />
);

export default objektDetailsPageRoute;
