import { FormFields } from '../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';

export interface AuflageCreateFormValues extends InfoFeldFormValues {
  amtlicheQuadratmeterFestsetzung: boolean;
  assanierungsgebiet: boolean;
  bebaut: boolean;
  denkmalschutz: boolean;
  gruenderzeitviertel: boolean;
  naturschutz: boolean;
  schutzzone: boolean;
  vollanwendungMRG: boolean;
  wohnzone: boolean;
}

export const auflageCreateFormFields: FormFields<AuflageCreateFormValues> = {
  amtlicheQuadratmeterFestsetzung: 'amtlicheQuadratmeterFestsetzung',
  assanierungsgebiet: 'assanierungsgebiet',
  bebaut: 'bebaut',
  denkmalschutz: 'denkmalschutz',
  gruenderzeitviertel: 'gruenderzeitviertel',
  naturschutz: 'naturschutz',
  schutzzone: 'schutzzone',
  vollanwendungMRG: 'vollanwendungMRG',
  wohnzone: 'wohnzone',
  documentList: 'documentList',
};

export const auflageCreateFormInitialValues: AuflageCreateFormValues = {
  amtlicheQuadratmeterFestsetzung: false,
  assanierungsgebiet: false,
  bebaut: false,
  denkmalschutz: false,
  gruenderzeitviertel: false,
  naturschutz: false,
  schutzzone: false,
  vollanwendungMRG: false,
  wohnzone: false,
  documentList: [],
};
