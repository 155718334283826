import React, { FC } from 'react';
import EinnahmenKontoTable from './EinnahmenKontoTable';
import { ObjAbrKonto } from '../../../../../../types';

type Props = {
  kontoList: ObjAbrKonto[];
  fromInclusive: string;
  toInclusive: string;
  objektId: string;
  buchungskreisId: string;
};

const EinnahmenRows: FC<Props> = ({ kontoList, fromInclusive, toInclusive, objektId, buchungskreisId }) => {
  return (
    <>
      {kontoList
        .filter((konto) => konto.summe)
        .map((konto, index) => (
          <EinnahmenKontoTable
            key={index}
            konto={konto}
            toInclusive={toInclusive}
            fromInclusive={fromInclusive}
            objektId={objektId}
            buchungskreisId={buchungskreisId}
          />
        ))}
    </>
  );
};

export default EinnahmenRows;
