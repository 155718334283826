import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { Radio } from 'formik-antd';
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined } from '@ant-design/icons';
import { DocumentHeaderAndFooterImageAlignment } from '../../../../../types';

type Props = {
  name: string;
};

const TextbausteinImageAlignment: FC<Props> = ({ name }) => {
  const fieldName = `${name}BildAlignment`;

  return (
    <Space>
      <Typography.Text>Ausrichtung</Typography.Text>
      <Radio.Group name={fieldName} size="small" block>
        <Radio.Button value={DocumentHeaderAndFooterImageAlignment.Left}>
          <AlignLeftOutlined />
        </Radio.Button>
        <Radio.Button value={DocumentHeaderAndFooterImageAlignment.Center}>
          <AlignCenterOutlined />
        </Radio.Button>
        <Radio.Button value={DocumentHeaderAndFooterImageAlignment.Right}>
          <AlignRightOutlined />
        </Radio.Button>
      </Radio.Group>
    </Space>
  );
};

export default TextbausteinImageAlignment;
