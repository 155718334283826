import * as Yup from 'yup';
import { entityMustBeSelected } from '../../../../../components/message/validationMsg';
import { vorschreibungErstellenFormFields } from './formMapper';

/* eslint-disable no-template-curly-in-string */

export const vorschreibungErstellenValidationSchema = Yup.object().shape({
  [vorschreibungErstellenFormFields.vorschreibungsperiodeList]: Yup.array().required(entityMustBeSelected('Vorschreibungsperiode')),
});

/* eslint-enable no-template-curly-in-string */
