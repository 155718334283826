import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useBestandseinheitListQuery } from '../../Bestandseinheit/gql/BestandseinheitQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';

type Props = {
  objektId?: string | null;
  width?: string;
  maxTextLength?: number;
} & SelectProps;

const BestandseinheitFormSelect: FC<Props> = ({ objektId, width, maxTextLength, ...restProps }) => {
  const { data, loading } = useBestandseinheitListQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objektId: objektId!,
    },
    skip: !objektId,
  });

  const bestandseinheitList = data?.getBestandseinheitList.data ?? [];

  return (
    <Select
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Bestandseinheit auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      style={{ width: width || '100%' }}
      disabled={!objektId}
      {...restProps}
    >
      {bestandseinheitList.map((bestandseinheit) => (
        <Select.Option key={bestandseinheit.bestandseinheitId} value={bestandseinheit.bestandseinheitId}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? bestandseinheit.bezeichnung.length} text={bestandseinheit.bezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default BestandseinheitFormSelect;
