import React, { FC } from 'react';
import { Drawer } from 'antd';
import BankDetailsForm from '../Form/BankDetailsForm';
import { showSuccessMsgCreate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { gqlOperations } from '../../../graphql/gqlNamedOperations-generated';
import { FDAndRechtstrBankDetailsInput } from '../Form/bankDetailsFormMapper';
import { useUpdateFirmendatenBankDetailsMutation, useUpdateRechtstraegerBankDetailsMutation } from '../gql/BankDetailsMutations.types';
import { BankDetails } from '../../../types';

type Props = {
  isCollapsed: boolean;
  onCollapse: () => void;
  bankDetails: BankDetails;
  onActionSuccess: () => Record<string, unknown>;
  rechtstraegerId?: string;
  firmendatenId?: string;
};

const BankDetailsUpdateDrawer: FC<Props> = ({ isCollapsed, onCollapse, bankDetails, onActionSuccess, rechtstraegerId, firmendatenId }) => {
  const [runUpdateForRechtstraeger, { loading: loadingForRechtstraeger }] = useUpdateRechtstraegerBankDetailsMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Bankverbindung`);
      onCollapse();
      onActionSuccess();
    },
    refetchQueries: () => [gqlOperations.Query.RechnungsAussteller],
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdateForFirmendaten, { loading: loadingForFirmendaten }] = useUpdateFirmendatenBankDetailsMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Bankverbindung`);
      onCollapse();
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSubmit = (request: FDAndRechtstrBankDetailsInput) => {
    if (rechtstraegerId) {
      runUpdateForRechtstraeger({
        variables: {
          input: request,
          rechtstraegerId,
          bankDetailsId: bankDetails.bankDetailsId,
        },
      });
    } else if (firmendatenId) {
      runUpdateForFirmendaten({
        variables: {
          input: request,
          firmendatenId,
          bankDetailsId: bankDetails.bankDetailsId,
        },
      });
    }
  };

  /* FIELD HELP */
  const fieldHelpSelectorEntityName = rechtstraegerId ? 'BankDetails' : 'FirmendatenBankDetails';

  return (
    <Drawer title="Bankverbindung bearbeiten" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
      <BankDetailsForm
        isUpdate
        loading={loadingForRechtstraeger || loadingForFirmendaten}
        firmendatenId={firmendatenId}
        bankDetails={bankDetails}
        onSubmit={onSubmit}
        onCancel={onCollapse}
        fieldHelpSelectorEntityName={fieldHelpSelectorEntityName}
      />
    </Drawer>
  );
};

export default BankDetailsUpdateDrawer;
