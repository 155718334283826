import { useCallback, useMemo } from 'react';
import { useColumnsSettingsListQuery } from '../../../features/Usability/ColumnSettings/gql/ColumnSettingsQueries.types';
import { useCreateOrUpdateColumnSettingsMutation } from '../../../features/Usability/ColumnSettings/gql/ColumnSettingsMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { ColumnSelectorSetting } from './tableWithColSelectorHelpers';

const useUserColumnSettings = (filterIdentifier: string) => {
  const { data, refetch, loading } = useColumnsSettingsListQuery({ variables: { filterIdentifier }, skip: !filterIdentifier });
  const columnSettingsList = data?.getColumnsSettingsList.data;

  const userColumnSettings = columnSettingsList?.find((colSettings) => colSettings.filterIdentifier === filterIdentifier);
  const memoizedUserColumnSettings = useMemo<ColumnSelectorSetting[] | undefined>(() => {
    try {
      return userColumnSettings?.columns ? JSON.parse(userColumnSettings?.columns) : undefined;
    } catch (e) {
      /**/
    }
    return undefined;
  }, [userColumnSettings]);

  const [runAddOrUpdateUserColumnSettings] = useCreateOrUpdateColumnSettingsMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });
  const addOrUpdateUserColumnSettings = useCallback(
    (columnTitles: string) => {
      runAddOrUpdateUserColumnSettings({ variables: { input: { filterIdentifier, columns: columnTitles } } }).then(() => refetch());
    },
    [filterIdentifier, runAddOrUpdateUserColumnSettings, refetch]
  );

  return {
    userColumnSettings: memoizedUserColumnSettings,
    addOrUpdateUserColumnSettings,
    refetchColumnSettings: refetch,
    loadingColumnSettings: loading,
  };
};

export default useUserColumnSettings;
