import React, { FC } from 'react';
import { Card, Col, Row, Space, Typography } from 'antd';
import { IndexValueCreationCronJobSettings } from '../../types';
import { useTimelineOpen } from '../../components/Timeline/useTimelineOpen';
import theme from '../../theme';
import IndexValueCreationCronJobSettingsDrawer from './IndexValueCreationCronJobSettingsDrawer';
import HistoryModal, { HistoryType } from '../History/shared/HistoryModal';
import IndexValueCreationCronJobSettingsCardActions from './IndexValueCreationCronJobSettingsCardActions';
import IndexValueCreationCronJobSettingsChangeHistoryListingTable from './IndexValueCreationCronJobSettingsChangeHistoryListingTable';
import { useToggle } from '../../hooks/useToggle';

type Props = {
  indexValueCreationCronJobSettings: IndexValueCreationCronJobSettings;
  onAction: () => void;
};

const IndexValueCreationCronJobSettingsCard: FC<Props> = ({ indexValueCreationCronJobSettings, onAction }) => {
  const { timelineDrawer, openTimelineUpdateDrawer, closeTimelineDrawer } = useTimelineOpen();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  return (
    <>
      <Card
        style={{ width: '450px', border: '1px solid #d9d9d9' }}
        headStyle={{ backgroundColor: theme.cardBackgroundColors.grey }}
        size="default"
        bordered
        title="Einstellung automatische Erstellung"
        extra={
          <IndexValueCreationCronJobSettingsCardActions onClickEdit={openTimelineUpdateDrawer} onClickChangeHistoryOpen={toggleChangeHistoryOpen} />
        }
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Row gutter={16}>
            <Col span={8}>
              <Typography.Text>Index Schwellenwert</Typography.Text>
            </Col>
            <Col span={16}>
              <Typography.Text>{indexValueCreationCronJobSettings.indexThreshold ? 'Ja' : 'Nein'}</Typography.Text>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Typography.Text>Index jährlich</Typography.Text>
            </Col>
            <Col span={16}>
              <Typography.Text>{indexValueCreationCronJobSettings.indexAnnual ? 'Ja' : 'Nein'}</Typography.Text>
            </Col>
          </Row>
        </Space>
      </Card>
      <IndexValueCreationCronJobSettingsDrawer
        timelineDrawer={timelineDrawer}
        closeTimelineDrawer={closeTimelineDrawer}
        onSuccess={onAction}
        indexValueCreationCronJobSettings={indexValueCreationCronJobSettings}
      />
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <IndexValueCreationCronJobSettingsChangeHistoryListingTable />
      </HistoryModal>
    </>
  );
};

export default IndexValueCreationCronJobSettingsCard;
