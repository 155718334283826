import { $getRoot, EditorState } from 'lexical';
import { AddressesAndContacts, AddressType } from '../../../types';

export const buildBelegTexteHauptAddress = (address: AddressesAndContacts['hauptAddress']) => {
  if (address && address.type === AddressType.Street) {
    return `${address.street} ${address.houseEntranceApartmentNumber}, ${address.city} ${address.zipCode}`;
  }
  if (address && address.type === AddressType.PostofficeBox) {
    return `Postfach ${address.postofficeBoxNumber}, ${address.postofficeBoxZipCode}, ${address.city}`;
  }
  return undefined;
};

// Function to check if editorState is empty
export const isEditorStateEmpty = (editorState?: EditorState) => {
  let isEmpty = true;
  // Use the read method to access the editor state
  editorState?.read(() => {
    const root = $getRoot();
    // Check if the root has any children nodes
    isEmpty = root.getFirstChild<any>().isEmpty() && root.getChildrenSize() === 1;
  });

  return isEmpty;
};
