import React from 'react';
import { Route } from 'react-router';
import PDFPage from './PDFPage';
import {
  URI_APP_PDF,
  URI_APP_PDF_FINANCIAL_PLAN_OBJEKT,
  URI_APP_PDF_FINANCIAL_PLAN_TOPS,
  URI_APP_PDF_HAUSEIGENTUMER_ABRECHNUNG,
  URI_APP_PDF_MAHNUNG,
  URI_APP_PDF_OBJEKT_ABRECHNUNG,
  URI_APP_PDF_OFFENE_POSTEN_LIST_DEBITOREN,
  URI_APP_PDF_SEPA_LASTSCHRIFT_MANDAT,
  URI_APP_PDF_SUB_ADMINISTRATION_OBJEKT_ABRECHNUNG,
  URI_APP_PDF_SUB_ADMINISTRATION_TOP_ABRECHNUNG,
  URI_APP_PDF_TOP_ABRECHNUNG,
  URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG,
  URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG_STORNO,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT_STORNO,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG_STORNO,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_STORNO,
  URI_APP_PDF_ZINSLISTE,
} from '../../constants/pdfUriPaths';
import PDFPageObjektAbrechnung from './PDFPageObjektAbrechnung';
import PDFPageTopAbrechnung from './PDFPageTopAbrechnung';
import PDFPageMahnung from './PDFPageMahnung';
import PDFPageHauseigentuemerAbrechnung from './PDFPageHauseigentuemerAbrechnung';
import PDFPageVorschreibungDauerRg from './PDFPageVorschreibungDauerRg';
import PDFPageVorschreibungDauerRgStorno from './PDFPageVorschreibungDauerRgStorno';
import PDFPageVorschreibungEinzel from './PDFPageVorschreibungEinzel';
import PDFPageVorschreibungEinzelStorno from './PDFPageVorschreibungEinzelStorno';
import PDFPageVorschreibungEinzelAbrErgRechnung from './PDFPageVorschreibungEinzelAbrErgRechnung';
import PDFPageVorschreibungEinzelAbrErgRechnungStorno from './PDFPageVorschreibungEinzelAbrErgRechnungStorno';
import PDFPageVorschreibungEinzelAbrErgGutschrift from './PDFPageVorschreibungEinzelAbrErgGutschrift';
import PDFPageVorschreibungEinzelAbrErgGutschriftStorno from './PDFPageVorschreibungEinzelAbrErgGutschriftStorno';
import PDFPageZinsliste from './PDFPageZinsliste';
import PDFPageFinancialPlanObjekt from './PDFPageFinancialPlanObjekt';
import PDFPageFinancialPlanTops from './PDFPageFinancialPlanTops';
import PDFPageOPListDebitoren from './PDFPageOPListDebitoren';
import PDFPageSEPALastschriftMandat from './PDFPageSEPALastschriftMandat';
import PDFPageSubAdminAbrechnungObjekt from './PDFPageSubAdminAbrechnungObjekt';
import PDFPageSubAdminAbrechnungTop from './PDFPageSubAdminAbrechnungTop';

const pdfPageRoute = (
  <Route path={URI_APP_PDF} element={<PDFPage />}>
    <Route path={URI_APP_PDF_OBJEKT_ABRECHNUNG} element={<PDFPageObjektAbrechnung />} />
    <Route path={URI_APP_PDF_TOP_ABRECHNUNG} element={<PDFPageTopAbrechnung />} />
    <Route path={URI_APP_PDF_MAHNUNG} element={<PDFPageMahnung />} />
    <Route path={URI_APP_PDF_HAUSEIGENTUMER_ABRECHNUNG} element={<PDFPageHauseigentuemerAbrechnung />} />
    <Route path={URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG} element={<PDFPageVorschreibungDauerRg />} />
    <Route path={URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG_STORNO} element={<PDFPageVorschreibungDauerRgStorno />} />
    <Route path={URI_APP_PDF_VORSCHREIBUNG_EINZEL} element={<PDFPageVorschreibungEinzel />} />
    <Route path={URI_APP_PDF_VORSCHREIBUNG_EINZEL_STORNO} element={<PDFPageVorschreibungEinzelStorno />} />
    <Route path={URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG} element={<PDFPageVorschreibungEinzelAbrErgRechnung />} />
    <Route path={URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG_STORNO} element={<PDFPageVorschreibungEinzelAbrErgRechnungStorno />} />
    <Route path={URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT} element={<PDFPageVorschreibungEinzelAbrErgGutschrift />} />
    <Route path={URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT_STORNO} element={<PDFPageVorschreibungEinzelAbrErgGutschriftStorno />} />
    <Route path={URI_APP_PDF_ZINSLISTE} element={<PDFPageZinsliste />} />
    <Route path={URI_APP_PDF_FINANCIAL_PLAN_OBJEKT} element={<PDFPageFinancialPlanObjekt />} />
    <Route path={URI_APP_PDF_FINANCIAL_PLAN_TOPS} element={<PDFPageFinancialPlanTops />} />
    <Route path={URI_APP_PDF_OFFENE_POSTEN_LIST_DEBITOREN} element={<PDFPageOPListDebitoren />} />
    <Route path={URI_APP_PDF_SEPA_LASTSCHRIFT_MANDAT} element={<PDFPageSEPALastschriftMandat />} />
    <Route path={URI_APP_PDF_SUB_ADMINISTRATION_OBJEKT_ABRECHNUNG} element={<PDFPageSubAdminAbrechnungObjekt />} />
    <Route path={URI_APP_PDF_SUB_ADMINISTRATION_TOP_ABRECHNUNG} element={<PDFPageSubAdminAbrechnungTop />} />
  </Route>
);

export default pdfPageRoute;
