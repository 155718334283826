import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { URI_VERARBEITUNG_BELEG_ERSTELLEN_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import BelegErstellenDetailsPage from './BelegErstellenDetailsPage/BelegErstellenDetailsPage';

export type VerarbeitungBelegErstellenDetailsPageRouteParams = { generierlaufId: string };

export const verarbeitungBelegErstellenDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_BELEG_ERSTELLEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BelegErstellenDetailsPage />} />}
  />
);
