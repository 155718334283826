import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { Box } from 'rebass';
import { MessageTwoTone, PhoneTwoTone } from '@ant-design/icons';
import { Contact as TContact } from '../../types';
import { compareTwoStringsForSorting } from '../../helpers/stringHelper';
import { compareTwoBooleansForSorting } from '../../helpers/booleanHelper';
import { ContactType } from '../../shared/components/Contact/contactHelper';
import { useCreateOutgoingCallLogMutation } from '../CallLog/gql/CallLogMutations.types';

type Props = {
  rechtstraegerId: string;
  contactList: TContact[];
  isOpen: boolean;
};

const ContactList: FC<Props> = ({ rechtstraegerId, isOpen, contactList = [] }) => {
  if (contactList.length > 0) {
    return (
      <Space direction="vertical" size={4}>
        {contactList
          // Info you'll need to copy the array before sorting it otherwise you'll get an error when sorting the array
          .slice()
          .sort((a, b) => {
            if (a.type !== b.type) {
              return compareTwoStringsForSorting(a.type, b.type);
            }
            return compareTwoBooleansForSorting(a.haupt, b.haupt);
          })
          .filter((contact) => (isOpen ? true : contact.haupt))
          .map((contact) => (
            <Contact key={contact.contactId} rechtstraegerId={rechtstraegerId} contact={contact} />
          ))}
      </Space>
    );
  }
  return null;
};

type ContactProps = {
  rechtstraegerId: string;
  contact: TContact;
};

const Contact: FC<ContactProps> = ({ rechtstraegerId, contact }) => {
  const [runOutgoingCallLog] = useCreateOutgoingCallLogMutation({
    variables: {
      rechtstraegerId,
      contactId: contact.contactId,
    },
  });

  return (
    <Box py={0}>
      <Space>
        {contact.type === ContactType.PHONE ? (
          <>
            <a
              href={`tel:${contact.wert}`}
              onMouseUp={() => {
                runOutgoingCallLog();
              }}
            >
              <PhoneTwoTone style={{ paddingRight: '6px' }} />
              <Typography.Text type="secondary">{contact.wert}</Typography.Text>
            </a>
          </>
        ) : (
          <a href={`mailto:${contact.wert}`}>
            <MessageTwoTone style={{ paddingRight: '6px' }} />
            <Typography.Text type="secondary">{contact.wert}</Typography.Text>
          </a>
        )}
      </Space>
    </Box>
  );
};

export default ContactList;
