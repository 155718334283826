import React, { FC } from 'react';
import { Tag, TagProps } from 'antd';
import { PresetColorType } from 'antd/lib/_util/colors';
import { DataCarrierPainStatus, DataCarrierPainStatusTuple } from '../../../../../types';

type Props = {
  status: DataCarrierPainStatusTuple;
} & TagProps;

const DirectDebitOrBankWireStatusBadge: FC<Props> = ({ status, ...restProps }) => {
  return (
    <Tag color={mapStatusToTag(status.value)} {...restProps}>
      {status.text.toUpperCase()}
    </Tag>
  );
};

const mapStatusToTag = (status: DataCarrierPainStatus): PresetColorType | '' => {
  switch (status) {
    case DataCarrierPainStatus.Processed:
    case DataCarrierPainStatus.Uploaded:
      return 'green';
    case DataCarrierPainStatus.Error:
      return 'red';
    case DataCarrierPainStatus.Created:
    case DataCarrierPainStatus.UploadManually:
    default:
      return '';
  }
};

export default DirectDebitOrBankWireStatusBadge;
