import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { BookingSuggestionCreationGenerierlaufEntry, BookingSuggestionCreationGenerierlaufOrderBy } from '../../../../types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../components/Card';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import GenerierlaufEntryStatus from '../../shared/GenerierlaufEntryStatus';
import GenerierlaufEntryStep from '../../shared/GenerierlaufEntryStep';
import ReceivedBankTransactionTableColumn from './ReceivedBankTransactionTableColumn';
import { generatePathToBankstatementListingPage } from '../../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';

const bookingSuggestionCreationEntryTableColumns: TableWithColSelectorColumnProps<BookingSuggestionCreationGenerierlaufEntry>[] = [
  {
    title: 'Status',
    defaultSelected: true,
    render: (text, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Schritte',
    render: (text, record) => <GenerierlaufEntryStep stepList={record.stepList} currentStep={record.currentStep} stepCount={record.stepCount} />,
  },
  {
    title: 'Datenträger',
    dataIndex: BookingSuggestionCreationGenerierlaufOrderBy.DataCarrierName,
    sorter: true,
    render: (text, record) => {
      if (!record.receivedBankTransactionZahlung) return <TextForEmptyValue textToShow="minus" />;

      const receivedBankTransaction = record.receivedBankTransactionZahlung;

      return (
        <DataWithShortenedText text={receivedBankTransaction.dataCarrierName} maxTextLength={20}>
          {(shortenedText) => (
            <Link
              to={generatePathToBankstatementListingPage({
                name: receivedBankTransaction.dataCarrierName,
              })}
              target="_blank"
            >
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      );
    },
  },
  {
    title: 'Kontoauszugsnummer',
    defaultSelected: true,
    dataIndex: BookingSuggestionCreationGenerierlaufOrderBy.StatementNumber,
    sorter: true,
    render: (text, record) =>
      record.receivedBankTransactionZahlung ? (
        <Typography.Text>{record.receivedBankTransactionZahlung.statementNumber}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Empfangene Bankbuchungen',
    defaultSelected: true,
    render: (text, record) => <ReceivedBankTransactionTableColumn bookingSuggestionEntry={record} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) =>
      record.receivedBankTransactionZahlung ? (
        <FormattedDateTime createdTs={record.receivedBankTransactionZahlung.createTs} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Ersteller',
    defaultSelected: true,
    render: (text, record) =>
      record.receivedBankTransactionZahlung && record.receivedBankTransactionZahlung.createdByMitarbeiterId ? (
        <MitarbeiterTooltip mitarbeiterId={record.receivedBankTransactionZahlung.createdByMitarbeiterId} alignment="left" showsInitials />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
];

export default bookingSuggestionCreationEntryTableColumns;
