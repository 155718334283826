/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength, entityMinCharLength } from '../../../components/message/validationMsg';

export const newUsernameValidationSchema = Yup.string()
  .required(entityIsRequired('Benutzername'))
  .min(8, entityMinCharLength('Benutzername', 8))
  .max(64, entityMaxCharLength('Benutzername', 64))
  .matches(/^[A-Za-z0-9_-]+$/, 'Benutzername darf nur Buchstaben, Nummern, Bindestrich und Unterstrich enthalten');

/* eslint-enable no-template-curly-in-string */
