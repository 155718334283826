import React from 'react';
import { Route } from 'react-router';
import { isAdmin } from '../../../../../../security/permissionChecks';
import AndromedaSysSettingsAbrechnungsdefinitionVersionCreatePage from './AndromedaSysSettingsAbrechnungsdefinitionVersionCreatePage';
import AuthRoute from '../../../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION } from '../../../../../../features/AbrechnungsdefinitionTemplate/abrDefTemplateUriPaths';

const andromedaSysSettingsAbrechnungsdefinitionVersionCreatePageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.createPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsAbrechnungsdefinitionVersionCreatePage />} />}
  />
);

export default andromedaSysSettingsAbrechnungsdefinitionVersionCreatePageRoute;
