import React, { FC, JSX } from 'react';
import { Space, Typography } from 'antd';
import { BaseType } from 'antd/lib/typography/Base';
import DataWithShortenedTextAndExtraInfo from '../Helpers/DataWithShortenedTextAndExtraInfo';
import StatusBadge from '../Status/StatusBadge';
import { isStatusArchivedOrGesperrt, IStatus } from '../../helpers/statusHelper';
import EntityWarningList from '../message/EntityWarningList';
import { ResponseDataWarning } from '../../types';

type Props = {
  title: string;
  titleMaxLength?: number;
  extraInfo?: string;
  status?: IStatus;
  warningList?: ResponseDataWarning[];
  extraTextForStatusBadgeTooltip?: JSX.Element | string;
  onStatusBadgeTooltipOpen?: (open: boolean) => void;
};

/**
 *
 * <h2>Usage</h2>
 * Used in Cards on Details Pages to show the Entity name and (if available) Status and Warning list the page refers to.
 *
 * <h2>Component info</h2>
 * <ul>
 *   <li>Used within CardWithFooter component</li>
 *   <li>The font weight and margins are controlled by the Card antd component default styles used within CardWithFooter component</li>
 * </ul>
 *
 */
const CardTitle: FC<Props> = ({
  title,
  titleMaxLength = DEFAULT_TITLE_MAX_LENGTH,
  extraInfo,
  status,
  warningList,
  extraTextForStatusBadgeTooltip,
  onStatusBadgeTooltipOpen,
}) => {
  return status ? (
    <Space>
      <DataWithShortenedTextAndExtraInfo maxTextLength={titleMaxLength} text={title} fontWeight="bold">
        {(shortenedText) => <Typography.Text type={textType(status)}>{shortenedText}</Typography.Text>}
      </DataWithShortenedTextAndExtraInfo>

      {extraInfo && <Typography.Text type="secondary">{extraInfo}</Typography.Text>}

      <StatusBadge
        entityStatus={status}
        showStatusDescriptionInTooltip={false}
        extraTextForBadgeTooltip={extraTextForStatusBadgeTooltip}
        onBadgeTooltipOpen={onStatusBadgeTooltipOpen}
      />
      {warningList && <EntityWarningList warningList={warningList} />}
    </Space>
  ) : (
    <Space>
      <DataWithShortenedTextAndExtraInfo maxTextLength={titleMaxLength} text={title} fontWeight="bold" />
      {extraInfo && <Typography.Text type="secondary">{extraInfo}</Typography.Text>}
      {warningList && <EntityWarningList warningList={warningList} />}
    </Space>
  );
};

const DEFAULT_TITLE_MAX_LENGTH = 30;

const textType = (status: IStatus): BaseType | undefined => (isStatusArchivedOrGesperrt(status) ? 'secondary' : undefined);

export default CardTitle;
