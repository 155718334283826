import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useAbrechnungsdefinitionTemplateQuery } from '../../../../../../features/Abrechnungsdefinition/gql/AbrDefQueries.types';
import { AbrechnungsdefinitionTemplateType } from '../../../../../../types';
import {
  findPrecedingVersionId,
  hasPrecedingVersion,
  isPrecedingVersionAvailable,
} from '../../../../../SysSettingsPage/KundenSystem/AbrechnungsdefinitionPage/Version/Create/createPageHelpers';
import {
  useSubAdministrationAbrechnungsdefinitionTemplateVersionListQuery,
  useSubAdministrationAbrechnungsdefinitionTemplateVersionQuery,
} from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/SubAdministrationAbrDef/SubAdministrationAbrDefTemplateVersionQueries.types';
import SubAbrDefTemplateVersionCreateForm from '../../../../../../features/AbrechnungsdefinitionTemplate/Version/Form/Create/SubAbrDefTemplateVersionCreateForm';

/*
When creating new version the forms have to be prefilled with the values of preceding version for the next available date from today
ex. 1: today: 01.01.2022., version 1: 01.01.2021. version 2: 01.01.2023 => preceding version: version 1
ex. 2: today: 01.01.2022., version 1: 01.01.2021. version 2: 02.01.2022 => preceding version: version 2
 */

type Props = {
  abrechnungsdefinitionId: string;
};
const AndromedaSysSettingsSubAdministrationAbrechnungsdefinitionVersionCreatePage: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data: subAbrDefVersionListData } = useSubAdministrationAbrechnungsdefinitionTemplateVersionListQuery({
    variables: { abrechnungsdefinitionId },
  });
  const versionList = subAbrDefVersionListData?.getSubAdministrationAbrechnungsdefinitionTemplateVersionList.data;
  const precedingVersionId = versionList ? findPrecedingVersionId(versionList) : undefined;

  const { data: subAbrDefVersionData } = useSubAdministrationAbrechnungsdefinitionTemplateVersionQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId: precedingVersionId! },
    skip: !precedingVersionId,
  });
  const precedingVersion = subAbrDefVersionData?.getSubAdministrationAbrechnungsdefinitionTemplateVersion.data;

  const { data: templateSubData } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.SubAbrechnung },
    skip: isPrecedingVersionAvailable(versionList, precedingVersion),
  });
  const subAdministrationAbrechnungTextbausteinList = templateSubData?.getAbrechnungsdefinitionTemplate.data.textbausteinList;

  // Show loading state until initial data is available: if there is a precedingVersion we get the textbausteinLists from it otherwise we have to load default templates
  if (
    !versionList ||
    (hasPrecedingVersion(versionList) && !precedingVersion) ||
    (hasPrecedingVersion(versionList) === false && !subAdministrationAbrechnungTextbausteinList)
  ) {
    return <Skeleton active />;
  }

  return (
    <SubAbrDefTemplateVersionCreateForm
      abrechnungsdefinitionVersionList={versionList}
      precedingAbrechnungsdefinitionVersion={precedingVersion}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      subAdministrationAbrechnungTextbausteinList={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        precedingVersion?.subAdministrationAbrechnungTextbausteinList ?? subAdministrationAbrechnungTextbausteinList!
      }
    />
  );
};

export default AndromedaSysSettingsSubAdministrationAbrechnungsdefinitionVersionCreatePage;
