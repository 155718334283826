import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type BeOptionSteuerpflichtVersionFragment = {
  beOptionSteuerpflichtId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  current: boolean;
  deletable?: boolean | null;
  kommentar?: string | null;
  lastUpdateTs: string;
  optionSteuerpflicht: boolean;
  validFrom: string;
  historicizedList?: Array<{
    beOptionSteuerpflichtId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    current: boolean;
    deletable?: boolean | null;
    kommentar?: string | null;
    lastUpdateTs: string;
    optionSteuerpflicht: boolean;
    validFrom: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }> | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const BeOptionSteuerpflichtVersionFragmentDoc = gql`
  fragment BeOptionSteuerpflichtVersion on BeOptionSteuerpflichtVersion {
    beOptionSteuerpflichtId
    createTs
    createdBy
    createdByMitarbeiterId
    current
    deletable
    historicizedList {
      beOptionSteuerpflichtId
      createTs
      createdBy
      createdByMitarbeiterId
      current
      deletable
      kommentar
      lastUpdateTs
      optionSteuerpflicht
      validFrom
      warningList {
        attribute
        message
        type
      }
    }
    kommentar
    lastUpdateTs
    optionSteuerpflicht
    validFrom
    warningList {
      attribute
      message
      type
    }
  }
`;
