import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { DeviationDataFragmentDoc } from '../../../../../components/Deviation/gql/deviationFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeAbrechnungDeviationListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  heAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type HeAbrechnungDeviationListQuery = {
  getHeAbrechnungDeviationList: {
    data: Array<{
      order?: number | null;
      type: Types.DeviationType;
      entryList: Array<{
        attributeList: Array<
          | {
              __typename: 'DeviationAttributeAmount';
              hasChanged: boolean;
              attributeId: number;
              type: Types.DeviationAttributeType;
              valueFloat?: number | null;
              name: { text: string; value: Types.DeviationAttributeNameType };
            }
          | {
              __typename: 'DeviationAttributeDate';
              hasChanged: boolean;
              attributeId: number;
              type: Types.DeviationAttributeType;
              valueDate?: string | null;
              name: { text: string; value: Types.DeviationAttributeNameType };
            }
          | {
              __typename: 'DeviationAttributeNumber';
              hasChanged: boolean;
              attributeId: number;
              type: Types.DeviationAttributeType;
              valueFloat?: number | null;
              name: { text: string; value: Types.DeviationAttributeNameType };
            }
          | {
              __typename: 'DeviationAttributePercent';
              hasChanged: boolean;
              attributeId: number;
              type: Types.DeviationAttributeType;
              valuePercent?: number | null;
              name: { text: string; value: Types.DeviationAttributeNameType };
            }
          | {
              __typename: 'DeviationAttributeString';
              hasChanged: boolean;
              attributeId: number;
              type: Types.DeviationAttributeType;
              valueString?: string | null;
              name: { text: string; value: Types.DeviationAttributeNameType };
            }
          | {
              __typename: 'DeviationAttributeUriList';
              hasChanged: boolean;
              attributeId: number;
              type: Types.DeviationAttributeType;
              uriList: Array<{
                text: string;
                ids?:
                  | { __typename: 'DeviationUriIdAuftrag'; auftragId: string; uriType: Types.DeviationUriIdType }
                  | { __typename: 'DeviationUriIdBeNutzungsArt'; bestandseinheitId: string; objektId: string; uriType: Types.DeviationUriIdType }
                  | {
                      __typename: 'DeviationUriIdBeOptionSteuerpflicht';
                      objektId: string;
                      beOptionSteuerpflichtBestandseinheitId?: string | null;
                      uriType: Types.DeviationUriIdType;
                    }
                  | {
                      __typename: 'DeviationUriIdBeVertrag';
                      bestandseinheitId: string;
                      objektId: string;
                      vertragId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | {
                      __typename: 'DeviationUriIdBeVertragVorschreibungsposition';
                      bestandseinheitId: string;
                      vertragId: string;
                      objektId: string;
                      vorschreibungspositionId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | { __typename: 'DeviationUriIdBestandseinheit'; objektId: string; bestandseinheitId: string; uriType: Types.DeviationUriIdType }
                  | {
                      __typename: 'DeviationUriIdBkAbrechnungsdefinitionVersion';
                      bkAbrechnungsdefinitionId: string;
                      bkAbrechnungsdefinitionVersionId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | { __typename: 'DeviationUriIdEingangsrechnung'; belegFileId: string; belegId: string; uriType: Types.DeviationUriIdType }
                  | {
                      __typename: 'DeviationUriIdHeAbrechnungHeVertragVersion';
                      heAbrechnungId: string;
                      heAbrechnungHeVertragId: string;
                      objektId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | {
                      __typename: 'DeviationUriIdHeAbrechnungsdefinitionVersion';
                      heAbrechnungsdefinitionId: string;
                      heAbrechnungsdefinitionVersionId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | { __typename: 'DeviationUriIdHeVertrag'; objektId: string; heVertragId: string; uriType: Types.DeviationUriIdType }
                  | { __typename: 'DeviationUriIdKontierungstabelle'; kontierungstabelleId: string; uriType: Types.DeviationUriIdType }
                  | {
                      __typename: 'DeviationUriIdLeerstehungAufwandskonto';
                      firmendatenId: string;
                      firmendatenLeerstehungAufwandskontoId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | {
                      __typename: 'DeviationUriIdPauschalmieteAufwandskonto';
                      firmendatenId: string;
                      firmendatenPauschalmieteAufwandskontoId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | {
                      __typename: 'DeviationUriIdRechtstraegerSteuerpflicht';
                      rechtstraegerId: string;
                      uriType: Types.DeviationUriIdType;
                      rechtstraegerType: { text: string; value: Types.RechtstraegerType };
                    }
                  | { __typename: 'DeviationUriIdRepFondsEinstellung'; repFondsEinstellungId: string; uriType: Types.DeviationUriIdType }
                  | {
                      __typename: 'DeviationUriIdTopAbrechnung';
                      objektAbrechnungId: string;
                      objektId: string;
                      topAbrechnungId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | {
                      __typename: 'DeviationUriIdUstKategorie';
                      ustKategorieId: string;
                      ustKategorieDeviationUstKategorieEntryId?: string | null;
                      uriType: Types.DeviationUriIdType;
                      ustKategorieStatus: { text: string; value: Types.UstKategorieStatus };
                    }
                  | {
                      __typename: 'DeviationUriIdUstKategorieEntry';
                      ustKategorieId: string;
                      ustKategorieVersionId: string;
                      ustKategorieEntryDeviationUstKategorieEntryId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | { __typename: 'DeviationUriIdUstRegelset'; ustRegelsetId: string; uriType: Types.DeviationUriIdType }
                  | { __typename: 'DeviationUriIdUstVomAufwand'; objektId: string; ustVomAufwandId: string; uriType: Types.DeviationUriIdType }
                  | {
                      __typename: 'DeviationUriIdVertragsart';
                      objektId: string;
                      bestandseinheitId: string;
                      vertragId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | {
                      __typename: 'DeviationUriIdVertragspartner';
                      objektId: string;
                      bestandseinheitId: string;
                      vertragId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | {
                      __typename: 'DeviationUriIdVorschreibungsposition';
                      vorschreibungspositionId: string;
                      uriType: Types.DeviationUriIdType;
                      vorschreibungspositionStatus: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
                    }
                  | {
                      __typename: 'DeviationUriIdVorschreibungspositionVersion';
                      vorschreibungspositionId: string;
                      vorschreibungspositionVersionId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | {
                      __typename: 'DeviationUriIdVorsteuerkuerzung';
                      objektId: string;
                      vorsteuerkuerzungId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | {
                      __typename: 'DeviationUriIdVorsteuerkuerzungEinstellung';
                      vorsteuerkuerzungEinstellungId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | {
                      __typename: 'DeviationUriIdWeAbrechnungsdefinitionVersion';
                      weAbrechnungsdefinitionId: string;
                      weAbrechnungsdefinitionVersionId: string;
                      uriType: Types.DeviationUriIdType;
                    }
                  | { __typename: 'DeviationUriIdZinsliste'; objektId: string; zinslisteId: string; uriType: Types.DeviationUriIdType }
                  | null;
              }>;
              name: { text: string; value: Types.DeviationAttributeNameType };
            }
        >;
        rowType: { text: string; value: Types.DeviationEntryType };
      }>;
      group?: { id: string; text: string } | null;
      linkList?: Array<{
        text: string;
        ids?:
          | { __typename: 'DeviationUriIdAuftrag'; auftragId: string; uriType: Types.DeviationUriIdType }
          | { __typename: 'DeviationUriIdBeNutzungsArt'; uriType: Types.DeviationUriIdType }
          | { __typename: 'DeviationUriIdBeOptionSteuerpflicht'; uriType: Types.DeviationUriIdType }
          | {
              __typename: 'DeviationUriIdBeVertrag';
              bestandseinheitId: string;
              vertragId: string;
              objektId: string;
              uriType: Types.DeviationUriIdType;
            }
          | { __typename: 'DeviationUriIdBeVertragVorschreibungsposition'; uriType: Types.DeviationUriIdType }
          | { __typename: 'DeviationUriIdBestandseinheit'; uriType: Types.DeviationUriIdType }
          | {
              __typename: 'DeviationUriIdBkAbrechnungsdefinitionVersion';
              bkAbrechnungsdefinitionId: string;
              bkAbrechnungsdefinitionVersionId: string;
              uriType: Types.DeviationUriIdType;
            }
          | { __typename: 'DeviationUriIdEingangsrechnung'; belegFileId: string; belegId: string; uriType: Types.DeviationUriIdType }
          | {
              __typename: 'DeviationUriIdHeAbrechnungHeVertragVersion';
              heAbrechnungId: string;
              heAbrechnungHeVertragId: string;
              objektId: string;
              uriType: Types.DeviationUriIdType;
            }
          | {
              __typename: 'DeviationUriIdHeAbrechnungsdefinitionVersion';
              heAbrechnungsdefinitionId: string;
              heAbrechnungsdefinitionVersionId: string;
              uriType: Types.DeviationUriIdType;
            }
          | { __typename: 'DeviationUriIdHeVertrag'; objektId: string; heVertragId: string; uriType: Types.DeviationUriIdType }
          | { __typename: 'DeviationUriIdKontierungstabelle'; kontierungstabelleId: string; uriType: Types.DeviationUriIdType }
          | {
              __typename: 'DeviationUriIdLeerstehungAufwandskonto';
              firmendatenId: string;
              firmendatenLeerstehungAufwandskontoId: string;
              uriType: Types.DeviationUriIdType;
            }
          | { __typename: 'DeviationUriIdPauschalmieteAufwandskonto'; uriType: Types.DeviationUriIdType }
          | { __typename: 'DeviationUriIdRechtstraegerSteuerpflicht'; uriType: Types.DeviationUriIdType }
          | { __typename: 'DeviationUriIdRepFondsEinstellung'; repFondsEinstellungId: string; uriType: Types.DeviationUriIdType }
          | {
              __typename: 'DeviationUriIdTopAbrechnung';
              objektAbrechnungId: string;
              objektId: string;
              topAbrechnungId: string;
              uriType: Types.DeviationUriIdType;
            }
          | { __typename: 'DeviationUriIdUstKategorie'; uriType: Types.DeviationUriIdType }
          | {
              __typename: 'DeviationUriIdUstKategorieEntry';
              ustKategorieEntryId: string;
              ustKategorieId: string;
              ustKategorieVersionId: string;
              uriType: Types.DeviationUriIdType;
            }
          | { __typename: 'DeviationUriIdUstRegelset'; uriType: Types.DeviationUriIdType }
          | { __typename: 'DeviationUriIdUstVomAufwand'; objektId: string; ustVomAufwandId: string; uriType: Types.DeviationUriIdType }
          | {
              __typename: 'DeviationUriIdVertragsart';
              objektId: string;
              bestandseinheitId: string;
              vertragId: string;
              uriType: Types.DeviationUriIdType;
            }
          | {
              __typename: 'DeviationUriIdVertragspartner';
              objektId: string;
              bestandseinheitId: string;
              vertragId: string;
              uriType: Types.DeviationUriIdType;
            }
          | { __typename: 'DeviationUriIdVorschreibungsposition'; uriType: Types.DeviationUriIdType }
          | {
              __typename: 'DeviationUriIdVorschreibungspositionVersion';
              vorschreibungspositionId: string;
              vorschreibungspositionVersionId: string;
              uriType: Types.DeviationUriIdType;
            }
          | { __typename: 'DeviationUriIdVorsteuerkuerzung'; objektId: string; vorsteuerkuerzungId: string; uriType: Types.DeviationUriIdType }
          | { __typename: 'DeviationUriIdVorsteuerkuerzungEinstellung'; vorsteuerkuerzungEinstellungId: string; uriType: Types.DeviationUriIdType }
          | {
              __typename: 'DeviationUriIdWeAbrechnungsdefinitionVersion';
              weAbrechnungsdefinitionId: string;
              weAbrechnungsdefinitionVersionId: string;
              uriType: Types.DeviationUriIdType;
            }
          | { __typename: 'DeviationUriIdZinsliste'; objektId: string; zinslisteId: string; uriType: Types.DeviationUriIdType }
          | null;
      }> | null;
      sourceEntity:
        | {
            __typename: 'AuftragDeviationSourceEntity';
            entityId: string;
            belegDatum: string;
            belegnummer: string;
            rechnungsausstellerId: string;
            rechnungsempfaengerId: string;
            sumBrutto: number;
            sumNetto: number;
            type: { text: string; value: Types.DeviationSourceEntityType };
          }
        | {
            __typename: 'BeVertragDeviationSourceEntity';
            entityId: string;
            bestandseinheitBezeichnung: string;
            objektBezeichnung: string;
            rechnungsAusstellerKurzBezeichnung: string;
            vertragsBeginn: string;
            vertragsEnde?: string | null;
            vertragspartnerKurzBezeichnung: string;
            type: { text: string; value: Types.DeviationSourceEntityType };
            ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
          }
        | {
            __typename: 'BuchungsanweisungDeviationSourceEntity';
            entityId: string;
            belegnummer: string;
            gesamtBetrag: number;
            type: { text: string; value: Types.DeviationSourceEntityType };
            auftragsartType?: { text: string; value: Types.AuftragsartType } | null;
            gegenKonto?: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } } | null;
          }
        | {
            __typename: 'HeAbrechnungHeVertragDeviationSourceEntity';
            entityId: string;
            objektBezeichnung: string;
            vertragspartnerKurzBezeichnung: string;
            vertragsEnde?: string | null;
            vertragsBeginn: string;
            type: { text: string; value: Types.DeviationSourceEntityType };
            ids?: { heVertragId: string; linkText: string; objektId: string } | null;
          }
        | {
            __typename: 'ObjektAbrechnungDeviationSourceEntity';
            entityId: string;
            abrechnungBezeichnung?: string | null;
            fromInclusive: string;
            objektBezeichnung: string;
            toInclusive: string;
            type: { text: string; value: Types.DeviationSourceEntityType };
            abrechnungType: { description?: string | null; text: string; value: Types.AbrechnungType };
            ids?: { linkText: string; objektAbrechnungId: string; objektId: string } | null;
          }
        | {
            __typename: 'TopAbrechnungDeviationSourceEntity';
            entityId: string;
            fromInclusive: string;
            bestandseinheitBezeichnung: string;
            objektBezeichnung: string;
            toInclusive: string;
            type: { text: string; value: Types.DeviationSourceEntityType };
            abrechnungType: { description?: string | null; text: string; value: Types.AbrechnungType };
            ids?: { objektId: string; linkText: string; objektAbrechnungId: string; topAbrechnungId: string } | null;
          }
        | {
            __typename: 'UstVomAufwandDeviationSourceEntity';
            entityId: string;
            bestandseinheitBezeichnung: string;
            objektBezeichnung: string;
            rechnungsAusstellerKurzBezeichnung: string;
            vertragsBeginn: string;
            vertragsEnde?: string | null;
            vertragspartnerKurzBezeichnung: string;
            type: { text: string; value: Types.DeviationSourceEntityType };
            ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
          }
        | {
            __typename: 'VorsteuerkuerzungDeviationSourceEntity';
            entityId: string;
            bestandseinheitBezeichnung: string;
            objektBezeichnung: string;
            rechnungsAusstellerKurzBezeichnung: string;
            vertragsBeginn: string;
            vertragsEnde?: string | null;
            vertragspartnerKurzBezeichnung: string;
            type: { text: string; value: Types.DeviationSourceEntityType };
            ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
          };
      changeType: { text: string; value: Types.DeviationChangeType };
      ursache: { text: string; value: Types.DeviationUrsacheType };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const HeAbrechnungDeviationListDocument = gql`
  query HeAbrechnungDeviationList($objektId: ID!, $heAbrechnungId: ID!) {
    getHeAbrechnungDeviationList(objektId: $objektId, heAbrechnungId: $heAbrechnungId) {
      data {
        ...DeviationData
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${DeviationDataFragmentDoc}
`;
export function useHeAbrechnungDeviationListQuery(
  baseOptions: Apollo.QueryHookOptions<HeAbrechnungDeviationListQuery, HeAbrechnungDeviationListQueryVariables> &
    ({ variables: HeAbrechnungDeviationListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeAbrechnungDeviationListQuery, HeAbrechnungDeviationListQueryVariables>(HeAbrechnungDeviationListDocument, options);
}
export function useHeAbrechnungDeviationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeAbrechnungDeviationListQuery, HeAbrechnungDeviationListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeAbrechnungDeviationListQuery, HeAbrechnungDeviationListQueryVariables>(HeAbrechnungDeviationListDocument, options);
}
export function useHeAbrechnungDeviationListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeAbrechnungDeviationListQuery, HeAbrechnungDeviationListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeAbrechnungDeviationListQuery, HeAbrechnungDeviationListQueryVariables>(HeAbrechnungDeviationListDocument, options);
}
export type HeAbrechnungDeviationListQueryHookResult = ReturnType<typeof useHeAbrechnungDeviationListQuery>;
export type HeAbrechnungDeviationListLazyQueryHookResult = ReturnType<typeof useHeAbrechnungDeviationListLazyQuery>;
export type HeAbrechnungDeviationListSuspenseQueryHookResult = ReturnType<typeof useHeAbrechnungDeviationListSuspenseQuery>;
export type HeAbrechnungDeviationListQueryResult = Apollo.QueryResult<HeAbrechnungDeviationListQuery, HeAbrechnungDeviationListQueryVariables>;
