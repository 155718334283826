import React, { FC } from 'react';
import { Skeleton } from 'antd';
import IndexSeriesListing from '../../../features/IndexSeries/Listing/IndexSeriesListing';
import { useIndexSeriesListQuery } from '../../../features/IndexSeries/gql/IndexSeriesQueries.types';

const IndexSeriesPage: FC = () => {
  const { data, refetch } = useIndexSeriesListQuery();

  const indexSeriesList = data?.getIndexSeriesList.data;

  if (!indexSeriesList) return <Skeleton active />;

  return <IndexSeriesListing indexSeriesList={indexSeriesList} onAction={refetch} />;
};

export default IndexSeriesPage;
