import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import SingleInvoiceDetailsPage from './SingleInvoiceDetailsPage';
import { incomingInvoicePaths } from '../../../features/IncomingInvoice/incomingInvoiceUriPaths';

const singleInvoiceDetailsPageRoute = (
  <Route
    path={incomingInvoicePaths.singleInvoiceDetails}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<SingleInvoiceDetailsPage />} />}
  />
);

export default singleInvoiceDetailsPageRoute;
