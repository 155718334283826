import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReceivedBankTransactionVerbuchenGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type ReceivedBankTransactionVerbuchenGenerierlaufQuery = {
  getReceivedBankTransactionVerbuchenGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      generierlaufId: string;
      generierDatum: string;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ReceivedBankTransactionVerbuchenGenerierlaufEntryQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dataCarrierCamtName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statementNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  valueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  valueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  creditor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  debtor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  amountFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  amountTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.ReceivedBankTransactionVerbuchenGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type ReceivedBankTransactionVerbuchenGenerierlaufEntryQuery = {
  getReceivedBankTransactionVerbuchenGenerierlaufEntry: {
    data: {
      contentList: Array<{
        amount: number;
        booked: boolean;
        charges?: number | null;
        chargesInSeparateEntry?: boolean | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId: string;
        creditor?: string | null;
        creditorIban?: string | null;
        currentStep?: number | null;
        dataCarrierCamtId: string;
        dataCarrierCamtName: string;
        debtor?: string | null;
        debtorIban?: string | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        paymentDate?: string | null;
        paymentId?: string | null;
        paymentReferenceText?: string | null;
        purposeOfUseText?: string | null;
        retry: boolean;
        reversal: boolean;
        statementNumber: string;
        stepCount?: number | null;
        valueDate?: string | null;
        buchungskreisRechtstraeger: { kurzBezeichnung: string; rechtstraegerId: string };
        bookingInfoList: Array<{ fibuBuchungId?: string | null; buchungType: { text: string; value: Types.PaymentBuchungType } }>;
        errorList: Array<{ message: string; type: string }>;
        exitCode: { text: string; value: Types.GenerierlaufEntryExitCode };
        payee?: { kurzBezeichnung: string; rechtstraegerId: string } | null;
        paymentType?: { text: string; value: Types.PaymentType } | null;
        status: { text: string; value: Types.ReceivedBankTransactionStatus };
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        transactionType: { text: string; value: Types.TransactionType };
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { size: number; number: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ReceivedBankTransactionVerbuchenGenerierlaufDocument = gql`
  query ReceivedBankTransactionVerbuchenGenerierlauf($generierlaufId: ID!) {
    getReceivedBankTransactionVerbuchenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierlaufId
        generierDatum
        status {
          text
          value
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useReceivedBankTransactionVerbuchenGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReceivedBankTransactionVerbuchenGenerierlaufQuery,
    ReceivedBankTransactionVerbuchenGenerierlaufQueryVariables
  > &
    ({ variables: ReceivedBankTransactionVerbuchenGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReceivedBankTransactionVerbuchenGenerierlaufQuery, ReceivedBankTransactionVerbuchenGenerierlaufQueryVariables>(
    ReceivedBankTransactionVerbuchenGenerierlaufDocument,
    options
  );
}
export function useReceivedBankTransactionVerbuchenGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReceivedBankTransactionVerbuchenGenerierlaufQuery,
    ReceivedBankTransactionVerbuchenGenerierlaufQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReceivedBankTransactionVerbuchenGenerierlaufQuery, ReceivedBankTransactionVerbuchenGenerierlaufQueryVariables>(
    ReceivedBankTransactionVerbuchenGenerierlaufDocument,
    options
  );
}
export function useReceivedBankTransactionVerbuchenGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ReceivedBankTransactionVerbuchenGenerierlaufQuery, ReceivedBankTransactionVerbuchenGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReceivedBankTransactionVerbuchenGenerierlaufQuery, ReceivedBankTransactionVerbuchenGenerierlaufQueryVariables>(
    ReceivedBankTransactionVerbuchenGenerierlaufDocument,
    options
  );
}
export type ReceivedBankTransactionVerbuchenGenerierlaufQueryHookResult = ReturnType<typeof useReceivedBankTransactionVerbuchenGenerierlaufQuery>;
export type ReceivedBankTransactionVerbuchenGenerierlaufLazyQueryHookResult = ReturnType<
  typeof useReceivedBankTransactionVerbuchenGenerierlaufLazyQuery
>;
export type ReceivedBankTransactionVerbuchenGenerierlaufSuspenseQueryHookResult = ReturnType<
  typeof useReceivedBankTransactionVerbuchenGenerierlaufSuspenseQuery
>;
export type ReceivedBankTransactionVerbuchenGenerierlaufQueryResult = Apollo.QueryResult<
  ReceivedBankTransactionVerbuchenGenerierlaufQuery,
  ReceivedBankTransactionVerbuchenGenerierlaufQueryVariables
>;
export const ReceivedBankTransactionVerbuchenGenerierlaufEntryDocument = gql`
  query ReceivedBankTransactionVerbuchenGenerierlaufEntry(
    $generierlaufId: ID!
    $objektId: String
    $dataCarrierCamtName: String
    $statementNumber: String
    $valueDateFrom: String
    $valueDateTo: String
    $creditor: String
    $debtor: String
    $amountFrom: Float
    $amountTo: Float
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getReceivedBankTransactionVerbuchenGenerierlaufEntry(
      generierlaufId: $generierlaufId
      objektId: $objektId
      dataCarrierCamtName: $dataCarrierCamtName
      statementNumber: $statementNumber
      valueDateFrom: $valueDateFrom
      valueDateTo: $valueDateTo
      creditor: $creditor
      debtor: $debtor
      amountFrom: $amountFrom
      amountTo: $amountTo
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          amount
          booked
          buchungskreisRechtstraeger {
            kurzBezeichnung
            rechtstraegerId
          }
          bookingInfoList {
            buchungType {
              text
              value
            }
            fibuBuchungId
          }
          charges
          chargesInSeparateEntry
          createTs
          createdBy
          createdByMitarbeiterId
          creditor
          creditorIban
          currentStep
          dataCarrierCamtId
          dataCarrierCamtName
          debtor
          debtorIban
          errorList {
            message
            type
          }
          exitCode {
            text
            value
          }
          generierlaufEntryId
          infoMessage
          payee {
            kurzBezeichnung
            rechtstraegerId
          }
          paymentDate
          paymentId
          paymentReferenceText
          paymentType {
            text
            value
          }
          purposeOfUseText
          retry
          reversal
          statementNumber
          status {
            text
            value
          }
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          transactionType {
            text
            value
          }
          valueDate
          warningList {
            message
            type
          }
        }
        page {
          size
          number
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function useReceivedBankTransactionVerbuchenGenerierlaufEntryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReceivedBankTransactionVerbuchenGenerierlaufEntryQuery,
    ReceivedBankTransactionVerbuchenGenerierlaufEntryQueryVariables
  > &
    ({ variables: ReceivedBankTransactionVerbuchenGenerierlaufEntryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReceivedBankTransactionVerbuchenGenerierlaufEntryQuery, ReceivedBankTransactionVerbuchenGenerierlaufEntryQueryVariables>(
    ReceivedBankTransactionVerbuchenGenerierlaufEntryDocument,
    options
  );
}
export function useReceivedBankTransactionVerbuchenGenerierlaufEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReceivedBankTransactionVerbuchenGenerierlaufEntryQuery,
    ReceivedBankTransactionVerbuchenGenerierlaufEntryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReceivedBankTransactionVerbuchenGenerierlaufEntryQuery, ReceivedBankTransactionVerbuchenGenerierlaufEntryQueryVariables>(
    ReceivedBankTransactionVerbuchenGenerierlaufEntryDocument,
    options
  );
}
export function useReceivedBankTransactionVerbuchenGenerierlaufEntrySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReceivedBankTransactionVerbuchenGenerierlaufEntryQuery,
        ReceivedBankTransactionVerbuchenGenerierlaufEntryQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReceivedBankTransactionVerbuchenGenerierlaufEntryQuery,
    ReceivedBankTransactionVerbuchenGenerierlaufEntryQueryVariables
  >(ReceivedBankTransactionVerbuchenGenerierlaufEntryDocument, options);
}
export type ReceivedBankTransactionVerbuchenGenerierlaufEntryQueryHookResult = ReturnType<
  typeof useReceivedBankTransactionVerbuchenGenerierlaufEntryQuery
>;
export type ReceivedBankTransactionVerbuchenGenerierlaufEntryLazyQueryHookResult = ReturnType<
  typeof useReceivedBankTransactionVerbuchenGenerierlaufEntryLazyQuery
>;
export type ReceivedBankTransactionVerbuchenGenerierlaufEntrySuspenseQueryHookResult = ReturnType<
  typeof useReceivedBankTransactionVerbuchenGenerierlaufEntrySuspenseQuery
>;
export type ReceivedBankTransactionVerbuchenGenerierlaufEntryQueryResult = Apollo.QueryResult<
  ReceivedBankTransactionVerbuchenGenerierlaufEntryQuery,
  ReceivedBankTransactionVerbuchenGenerierlaufEntryQueryVariables
>;
