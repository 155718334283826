import React from 'react';
import { ContactsOutlined, CopyOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { FileInfoType, SubAbrechnung } from '../../types';
import DocumentTab from '../../features/Document/DocumentTab';
import { DOCUMENT_FILTERS_SETTINGS } from '../../features/Document/Listing/Filters/listingFiltersHelper';
import { pathsToSubAbrechnungDetails } from '../../features/Abrechnung/Sub/subAbrechnungUriPaths';
import SubAbrDetailsTabs from '../../features/Abrechnung/Sub/Details/SubAbrDetailsTabs';

const menuListSubAbrechnung = (objektId: string, subAbrechnung: SubAbrechnung): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Abrechnungen',
      icon: <ContactsOutlined />,
      uri: pathsToSubAbrechnungDetails(objektId, subAbrechnung.subAbrechnungId).subAbr,
      content: (props) => <SubAbrDetailsTabs abrechnung={subAbrechnung} objektId={objektId} />,
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: pathsToSubAbrechnungDetails(objektId, subAbrechnung.subAbrechnungId).documents,
      content: (props) => (
        <DocumentTab
          subAbrechnungId={subAbrechnung.subAbrechnungId}
          disabledFilters={DOCUMENT_FILTERS_SETTINGS.subAbrechnung.disabled}
          defaultFilters={DOCUMENT_FILTERS_SETTINGS.subAbrechnung.default}
          typeList={[FileInfoType.SubAbrechnungObjekt, FileInfoType.SubAbrechnungBestandseinheit]}
          {...props}
        />
      ),
    },
  ],
});

export default menuListSubAbrechnung;
