import React, { FC, useState } from 'react';
import { Divider, Space, Typography } from 'antd';
import { RechtstraegerInfoFeldType } from '../../../../types';
import RechtstraegerInfoFeldTypeSelect from './RechtstraegerInfoFeldTypeSelect';
import PersoneninfosCreateForm from './Personeninfos/Create/PersoneninfosCreateForm';

type Props = {
  rechtstraegerId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const RechtstraegerInfoFeldCreateForm: FC<Props> = ({ rechtstraegerId, onCancel, onSuccess }) => {
  const [type, setType] = useState<RechtstraegerInfoFeldType>();

  let content;

  if (type === RechtstraegerInfoFeldType.Personeninfos) {
    content = <PersoneninfosCreateForm onCancel={onCancel} onSuccess={onSuccess} rechtstraegerId={rechtstraegerId} />;
  }

  return (
    <>
      <Space style={{ marginBottom: '16px' }}>
        <Typography.Text>Art der Information</Typography.Text>
        <RechtstraegerInfoFeldTypeSelect onChange={setType} />
      </Space>
      <Divider />
      {content}
    </>
  );
};

export default RechtstraegerInfoFeldCreateForm;
