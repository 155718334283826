import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontierungstabelleBaseFieldsFragmentDoc } from '../../Kontierungstabelle/gql/KontierungstabelleFragments.types';
export type VorschreibungspositionFragment = {
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  firmendatenId?: string | null;
  kurzBezeichnung: string;
  tagList: Array<string>;
  updatedByMitarbeiterId?: string | null;
  vorschreibungspositionId: string;
  art: { text: string; value: Types.VorschreibungspositionArt };
  kontierungstabelle?: {
    basiskonto: number;
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    kontierungstabelleId: string;
    updatedByMitarbeiterId?: string | null;
    status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
    subAdministrationExpenseAccount?: {
      bezeichnung: string;
      firmendatenId?: string | null;
      kontoId: string;
      kontoKlasse: string;
      nummer: string;
    } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
  umsatzsteuerkennzeichen?: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    kuerzel: string;
    umsatzsteuerkennzeichenId: string;
    status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
    warningList: Array<{ message: string; type: string; attribute?: string | null }>;
  } | null;
  warningList: Array<{ message: string; type: string; attribute?: string | null }>;
};

export const VorschreibungspositionFragmentDoc = gql`
  fragment Vorschreibungsposition on Vorschreibungsposition {
    art {
      text
      value
    }
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    firmendatenId
    kurzBezeichnung
    kontierungstabelle {
      ...KontierungstabelleBaseFields
    }
    status {
      text
      value
      description
    }
    tagList
    umsatzsteuerkennzeichen {
      bezeichnung
      createTs
      createdBy
      createdByMitarbeiterId
      kuerzel
      status {
        text
        value
      }
      umsatzsteuerkennzeichenId
      warningList {
        message
        type
        attribute
      }
    }
    updatedByMitarbeiterId
    vorschreibungspositionId
    warningList {
      message
      type
      attribute
    }
  }
  ${KontierungstabelleBaseFieldsFragmentDoc}
`;
