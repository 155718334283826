import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';

type Props = Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'showSearch'>;

const ActiveOrDeactivatedFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const options = [
    { label: 'Aktiviert', value: true },
    { label: 'Deaktiviert', value: false },
  ];

  return (
    <Select placeholder="Aktiviert/Deaktiviert auswählen" {...restProps} id={name} name={name} allowClear>
      {options.map((option) => (
        <Select.Option key={option.label} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ActiveOrDeactivatedFormSelect;
