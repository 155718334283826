import React, { FC } from 'react';
import { Box } from 'rebass';
import FormattedDateTime from '../FormattedDateTime/FormattedDateTime';
import MitarbeiterTooltip from '../Card/MitarbeiterTooltip';
import { StyledAuditFooter } from './styled/AuditFooter.style';

type AuditFooterProps = {
  createTs: string;
  userId?: string | null;
  mitarbeiterId?: string | null;
  size?: AuditFooterSize;
  minWidth?: number;
};

export type AuditFooterSize = 'small' | 'large';

/**
 *
 * <h2>Usage</h2>
 * Used in Cards to extend it with a Footer.
 *
 * <h2>Component info</h2>
 * <ul>
 *   <li>Used within Card component to display the creator of the Card</li>
 * </ul>
 *
 */
const AuditFooter: FC<AuditFooterProps> = ({ createTs, userId, size = 'small', minWidth, mitarbeiterId }) => {
  return (
    <StyledAuditFooter size={size} minWidth={minWidth}>
      <Box>
        erstellt am<span style={{ paddingLeft: '4px', paddingRight: '4px' }}>•</span>
        <FormattedDateTime createdTs={createTs} />
      </Box>
      <Box mx="auto" />
      <MitarbeiterTooltip showsInitials userId={userId} mitarbeiterId={mitarbeiterId} alignment="right" />
    </StyledAuditFooter>
  );
};

export default AuditFooter;
