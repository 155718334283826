import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import { URI_VERARBEITUNG_ZINSLISTE_ERSTELLEN_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import ZinslisteDetailsPage from './ZinslisteDetailsPage';

export type VerarbeitungZinslisteDetailsPageRouteParams = { generierlaufId: string };

const verarbeitungZinslisteDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_ZINSLISTE_ERSTELLEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<ZinslisteDetailsPage />} />}
  />
);

export default verarbeitungZinslisteDetailsPageRoute;
