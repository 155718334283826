import React, { FC } from 'react';
import { Form, Input, Typography } from 'antd';

type AbrDefVersionFormKommentarProps = {
  value: string;
  onChange: (kommentar: string) => void;
};

const AbrDefVersionFormKommentar: FC<AbrDefVersionFormKommentarProps> = ({ onChange, value }) => (
  <Form>
    <Form.Item label={<Typography.Text strong>Kommentar</Typography.Text>}>
      <Input.TextArea rows={1} cols={36} onChange={(e) => onChange(e.target.value)} value={value} />
    </Form.Item>
  </Form>
);

export default AbrDefVersionFormKommentar;
