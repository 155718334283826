import React, { FC } from 'react';
import { Space, Typography } from 'antd';

import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import { BeOptionSteuerpflichtVersion } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type BeOptionSteuerpflichtVersionCardContentProps = {
  timeblock: ITimeblock<BeOptionSteuerpflichtVersion>;
};

const BeOptionSteuerpflichtVersionCardContent: FC<BeOptionSteuerpflichtVersionCardContentProps> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      <CustomFormattedDate value={timeblock.validFrom} />
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle="Option zur Steuerpflicht">
      <Typography.Text>{timeblock.optionSteuerpflicht ? 'ja' : 'nein'}</Typography.Text>
    </TimelineCardContentInfoRow>
  </Space>
);

export default BeOptionSteuerpflichtVersionCardContent;
