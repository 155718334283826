import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from 'antd';
import { EuroAmount } from '../../../../components/Number';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { generatePathToVorsteuerkuerzungDetailsPageOrDiscrepancies } from '../../vorsteuerkuerzungUriPaths';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { compareTwoDatesForSorting } from '../../../../helpers/dateHelper';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import { compareTwoNumbersForSorting } from '../../../../helpers/numberHelper';
import DocumentStatusTag from '../../../../components/Status/DocumentStatus/DocumentStatusTag';
import { VorsteuerkuerzungListEntry } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

const vstKuerzungTableColumns = (objektId: string): TableWithColSelectorColumnProps<VorsteuerkuerzungListEntry>[] => [
  {
    title: 'Zeitraum',
    defaultSelected: true,
    sorter: (a, b) => compareTwoDatesForSorting(a.vonInklusive, b.bisInklusive),
    render: (text, record) => <CustomFormattedDate value={record.vonInklusive} year="numeric" month="2-digit" day={undefined} />,
  },
  {
    title: 'Vertragsart',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.vertragsart.text, b.vertragsart.text),
    render: (text, record) => <Typography.Text>{record.vertragsart.text}</Typography.Text>,
  },
  {
    title: 'Rechnungsaussteller',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.rechnungsAussteller.kurzBezeichnung, b.rechnungsAussteller.kurzBezeichnung),
    render: (text, record) => <DataWithShortenedText text={record.rechnungsAussteller.kurzBezeichnung} maxTextLength={25} />,
  },
  {
    title: 'Fibu Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.buchungStatus.text, b.buchungStatus.text),
    render: (text, record) => <DocumentStatusTag documentStatus={record.buchungStatus} />,
  },
  {
    title: 'Vst.Kürz-Betrag',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.sumVstKuerzung, b.sumVstKuerzung),
    render: (text, record) => <EuroAmount value={record.sumVstKuerzung} />,
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.status.text, b.status.text),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => (
      <Link to={generatePathToVorsteuerkuerzungDetailsPageOrDiscrepancies(objektId, record.vorsteuerkuerzungId, record.status)}>Details</Link>
    ),
  },
];

export default vstKuerzungTableColumns;
