import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { AuftragsartCreateInput, AuftragsartType } from '../../../../types';
import { AuftragsartCreateParamFormValues } from './ParameterForm/auftragsartCreateParamFormMapper';
import { mapAuftragsartTextbausteinToInput } from '../shared/auftragsartFormHelpers';

export const mapCreateFormValuesToAuftragsart = (
  auftragsartType: AuftragsartType,
  auftragsartParamForm: AuftragsartCreateParamFormValues,
  auftragsartTextbausteinList: TextbausteinFormValues[]
): AuftragsartCreateInput => {
  return {
    type: auftragsartType,
    bezeichnung: auftragsartParamForm.bezeichnung,
    showUstBlock: auftragsartParamForm.showUstBlock,
    stornoAuftragsartId: auftragsartParamForm.stornoAuftragsartId ?? null,
    textbausteinList: mapAuftragsartTextbausteinToInput(auftragsartTextbausteinList),
  };
};
