import React, { FC } from 'react';
import { Empty, Table } from 'antd';
import { Verwaltungsart, VorsteuerkuerzungEinstellung, VorsteuerkuerzungKontenZuweisung } from '../../../../../types';
import vstKuerzungTemplateKontenZuweisungMieteTableColumns from './vstKuerzungTemplateKontenZuweisungMieteTableColumns';
import vstKuerzungTemplateKontenZuweisungWeTableColumns from './vstKuerzungTemplateKontenZuweisungWeTableColumns';
import { isWegObjekt } from '../../../../Objekt/objektHelper';

type VstKuerzungKontenZuweisungTableProps = {
  verwaltungsart: Verwaltungsart;
  vorsteuerkuerzung: VorsteuerkuerzungEinstellung;
  isInCardContent?: boolean;
};

const VstKuerzungTemplateKontenZuweisungTable: FC<VstKuerzungKontenZuweisungTableProps> = ({
  verwaltungsart,
  vorsteuerkuerzung,
  isInCardContent = false,
}) => (
  <Table<VorsteuerkuerzungKontenZuweisung>
    dataSource={vorsteuerkuerzung.kontenZuweisungList}
    columns={
      isWegObjekt(verwaltungsart)
        ? vstKuerzungTemplateKontenZuweisungWeTableColumns(isInCardContent, verwaltungsart)
        : vstKuerzungTemplateKontenZuweisungMieteTableColumns(isInCardContent, verwaltungsart)
    }
    locale={{
      emptyText: <Empty description={<span>Keine Vorsteuerkürzungen vorhanden</span>} />,
    }}
    pagination={false}
    size={isInCardContent ? 'small' : 'large'}
  />
);

export default VstKuerzungTemplateKontenZuweisungTable;
