import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { EuroAmount } from '../../../../../components/Number';
import { generatePathToVorschreibungDetailsPage } from '../../../../Vorschreibung/vorschreibungUriPaths';
import LinkToBelegFile from '../../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import KontoLinkToKontoblatt from '../../../../../shared/components/Konto/KontoLinkToKontoblatt';
import { generatePathToOffenePostenPage } from '../../../../OffenePosten/offenePostenUriPaths';
import { EinnahmenBuchungszeile, OffenePostenKontoType } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import SollHabenTag from '../../../../shared/SollHabenTag';
import { generatePathToBookingDetailsPage } from '../../../../BookingDetails/bookingDetailsPaths';

type Props = {
  buchungszeile: EinnahmenBuchungszeile;
  objektId: string;
};

const VorschreibungDetails: FC<Props> = ({ buchungszeile, objektId }) => (
  <tr>
    <td />
    <td style={{ justifyContent: 'left' }}>
      <DataWithShortenedText maxTextLength={15} text={buchungszeile.text}>
        {(shortenedText) => (
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          <Link target="_blank" to={generatePathToVorschreibungDetailsPage(buchungszeile.auftragId!)}>
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    </td>
    <td>
      <KontoLinkToKontoblatt
        konto={{
          kontoId: buchungszeile.kontoId,
          bezeichnung: buchungszeile.kontoBezeichnung,
          nummer: buchungszeile.kontoNummer,
        }}
        buchungskreisId={buchungszeile.buchungskreisId}
        objektId={objektId}
        belegdatumFrom={buchungszeile.belegDatum}
      />
    </td>
    <td>
      <CustomFormattedDate value={buchungszeile.belegDatum} />
    </td>
    <td>
      {buchungszeile.belegFileId && buchungszeile.belegnummer ? (
        <LinkToBelegFile fileId={buchungszeile.belegFileId} belegnummer={buchungszeile.belegnummer} />
      ) : (
        buchungszeile.belegnummer
      )}
    </td>
    <td style={{ textAlign: 'right' }}>
      {buchungszeile.auftragId ? (
        <Link target="_blank" to={generatePathToBookingDetailsPage([buchungszeile.buchungId], buchungszeile.auftragId)}>
          <EuroAmount value={buchungszeile.betrag} />
        </Link>
      ) : (
        <EuroAmount value={buchungszeile.betrag} />
      )}
    </td>
    <td style={{ textAlign: 'right' }}>
      <Link
        target="_blank"
        to={generatePathToOffenePostenPage({
          kontoType: [OffenePostenKontoType.Debitor],
          buchungskreisId: buchungszeile.buchungskreisId,
          vertragspartnerId: buchungszeile.vertragspartner.rechtstraegerId,
          belegnummer: buchungszeile.belegnummer,
          dueDateFrom: buchungszeile.dueDate,
          dueDateTo: buchungszeile.dueDate,
        })}
      >
        <EuroAmount value={buchungszeile.offenerBetrag} />
      </Link>
    </td>
    <td style={{ textAlign: 'left' }}>
      <SollHabenTag sollHaben={buchungszeile.sollHaben} />
    </td>
  </tr>
);

export default VorschreibungDetails;
