import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFibuBuchungsanweisungMutationVariables = Types.Exact<{
  buchungsanweisungId: Types.Scalars['ID']['input'];
}>;

export type DeleteFibuBuchungsanweisungMutation = {
  deleteFibuBuchungsanweisung: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export const DeleteFibuBuchungsanweisungDocument = gql`
  mutation DeleteFibuBuchungsanweisung($buchungsanweisungId: ID!) {
    deleteFibuBuchungsanweisung(buchungsanweisungId: $buchungsanweisungId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteFibuBuchungsanweisungMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFibuBuchungsanweisungMutation, DeleteFibuBuchungsanweisungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFibuBuchungsanweisungMutation, DeleteFibuBuchungsanweisungMutationVariables>(
    DeleteFibuBuchungsanweisungDocument,
    options
  );
}
export type DeleteFibuBuchungsanweisungMutationHookResult = ReturnType<typeof useDeleteFibuBuchungsanweisungMutation>;
export type DeleteFibuBuchungsanweisungMutationResult = Apollo.MutationResult<DeleteFibuBuchungsanweisungMutation>;
export type DeleteFibuBuchungsanweisungMutationOptions = Apollo.BaseMutationOptions<
  DeleteFibuBuchungsanweisungMutation,
  DeleteFibuBuchungsanweisungMutationVariables
>;
