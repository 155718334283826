import React, { FC } from 'react';
import { FormattedNumber } from 'react-intl';
import { calculateVerarbeitungsstandVerbucht } from '../../zinslisteCalculations';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import { ZinslisteVerarbeitungsstand } from '../../../../types';

type VerarbeitungsinfoProps = {
  verarbeitungsstand: ZinslisteVerarbeitungsstand;
};

const VerarbeitungInfo: FC<VerarbeitungsinfoProps> = ({ verarbeitungsstand }) => (
  <>
    <CardDetailsInfoRow infoRowTitle="Erstellte">
      <FormattedNumber value={verarbeitungsstand.countErstellt} />
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Nicht erstellte">
      <FormattedNumber value={verarbeitungsstand.countNichtErstellt} />
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Ausgegeben">
      <FormattedNumber value={verarbeitungsstand.countAusgegeben} />
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Verbucht">
      <FormattedNumber value={calculateVerarbeitungsstandVerbucht(verarbeitungsstand)} />
    </CardDetailsInfoRow>
  </>
);

export default VerarbeitungInfo;
