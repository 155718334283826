import { FormFields } from '../../../../../helpers/formikHelper';

export interface PersonenbezugCreateFormValues {
  rechtstraegerId: string;
}

export const personenbezugCreateFormFields: FormFields<PersonenbezugCreateFormValues> = {
  rechtstraegerId: 'rechtstraegerId',
};

export const personenbezugCreateFormInitialValues: PersonenbezugCreateFormValues = {
  rechtstraegerId: '',
};
