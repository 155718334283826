import React, { FC } from 'react';
import entryDetailsTableColumns from './entryDetailsTableColumns';
import { StyledTableForAuftragDetailsList } from '../styled/AuftragDetailsListTable.style';
import { AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute } from '../../../../../../types';

type Props = {
  auftragDetailsList: AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute[];
  rechtstreagerId: string;
};

const EntryDetailsTable: FC<Props> = ({ auftragDetailsList, rechtstreagerId }) => {
  return <StyledTableForAuftragDetailsList dataSource={auftragDetailsList} columns={entryDetailsTableColumns(rechtstreagerId)} pagination={false} />;
};

export default EntryDetailsTable;
