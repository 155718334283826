import { FC, useEffect } from 'react';
import { FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { TopAbrDefGeneralSettingsFormValues } from './topAbrDefGeneralSettingsFormMapper';
import DisplayPaymentHistoryFormItem from './FormItems/DisplayPaymentHistoryFormItem';
import ConsiderRueckstandFormItem from './FormItems/ConsiderRueckstandFormItem';
import DueDateFormItems from './FormItems/DueDate/DueDateFormItems';
import RueckstandOtherPersonsFormItems from './FormItems/RueckstandOtherPersons/RueckstandOtherPersonsFormItems';
import { AbrechnungsdefinitionType, DueDateDisplayOptionsInput } from '../../../../../../../types';
import { isAbrDefTypeWeAbrechnung } from '../../../../AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { BkOrWeAbrDefTopOutputOptionsFieldHelpNames } from '../../../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';
import { StyledWizardSpaceForContent } from '../../../../../../shared/styled/WizardSpace.style';

type Props = {
  abrDefType: AbrechnungsdefinitionType;
  isDisabled: boolean;
  onDisplayPaymentHistoryChange: (value: boolean) => void;
  onConsiderRueckstandChange: (value: boolean) => void;
  onRueckstandOtherPersonsChange: (displayRueckstandOtherPersons: boolean, rueckstandOtherPersonsThreshold: number | null) => void;
  onDueDateOptionsChange: (
    displayDueDate: boolean,
    dueDateDisplayOptionEigentuemerGuthaben?: DueDateDisplayOptionsInput | null,
    dueDateDisplayOptionEigentuemerNachzahlung?: DueDateDisplayOptionsInput | null,
    dueDateDisplayOptionMieter?: DueDateDisplayOptionsInput | null
  ) => void;
  setIsFormValid?: (isFormValid: boolean) => void;
  formikProps: FormikProps<TopAbrDefGeneralSettingsFormValues>;
  fieldHelpNames?: BkOrWeAbrDefTopOutputOptionsFieldHelpNames;
};

const TopAbrDefGeneralSettingsForm: FC<Props> = ({
  abrDefType,
  isDisabled,
  onDisplayPaymentHistoryChange,
  onConsiderRueckstandChange,
  onDueDateOptionsChange,
  onRueckstandOtherPersonsChange,
  setIsFormValid,
  formikProps,
  fieldHelpNames,
}) => {
  useEffect(() => {
    if (setIsFormValid) {
      setIsFormValid(formikProps.isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikProps.isValid]);
  return (
    <Form>
      <StyledWizardSpaceForContent direction="vertical">
        <DisplayPaymentHistoryFormItem
          formikProps={formikProps}
          isDisabled={isDisabled}
          onChange={onDisplayPaymentHistoryChange}
          fieldHelp={fieldHelpNames?.displayZahlungsverlauf}
        />
        <ConsiderRueckstandFormItem
          formikProps={formikProps}
          isDisabled={isDisabled}
          onChange={onConsiderRueckstandChange}
          fieldHelp={fieldHelpNames?.considerRueckstand}
        />
        {isAbrDefTypeWeAbrechnung(abrDefType) && (
          <RueckstandOtherPersonsFormItems
            formikProps={formikProps}
            isDisabled={isDisabled}
            onChange={onRueckstandOtherPersonsChange}
            fieldHelpNames={{
              displayRueckstandOtherPersons: fieldHelpNames?.displayRueckstandOtherPersons,
              rueckstandOtherPersonsThreshold: fieldHelpNames?.rueckstandOtherPersonsThreshold,
            }}
          />
        )}
        <DueDateFormItems
          formikProps={formikProps}
          abrDefType={abrDefType}
          isDisabled={isDisabled}
          onValuesChange={(
            displayDueDate,
            dueDateDisplayOptionEigentuemerGuthaben,
            dueDateDisplayOptionEigentuemerNachzahlung,
            dueDateDisplayOptionMieter
          ) => {
            onDueDateOptionsChange(
              displayDueDate,
              dueDateDisplayOptionEigentuemerGuthaben,
              dueDateDisplayOptionEigentuemerNachzahlung,
              dueDateDisplayOptionMieter
            );
            setTimeout(() => formikProps.submitForm());
          }}
          fieldHelpNames={{
            displayDueDate: fieldHelpNames?.displayDueDate,
            dueDateDisplayOptionEigentuemerGuthaben: fieldHelpNames?.dueDateDisplayOptionEigentuemerGuthaben,
            dueDateDisplayOptionEigentuemerNachzahlung: fieldHelpNames?.dueDateDisplayOptionEigentuemerNachzahlung,
            dueDateDisplayOptionMieter: fieldHelpNames?.dueDateDisplayOptionMieter,
          }}
        />
      </StyledWizardSpaceForContent>
    </Form>
  );
};

export default TopAbrDefGeneralSettingsForm;
