import { generatePath } from 'react-router-dom';
import { URI_OBJEKT_BESTANDSEINHEIT_DETAILS_PAGE } from '../../Bestandseinheit/bestandseinheitUriPaths';
import { VertragStatus } from '../../../types';
import { mapToQueryString, QUERY_PARAM_KEY } from '../../../hooks/useStringQueryParams';

export const URI_DASHBOARD_OBJEKT_BE_VERTRAG_DETAILS_PAGE = `${URI_OBJEKT_BESTANDSEINHEIT_DETAILS_PAGE}/contract/:vertragId`;

export const generatePathToBeVertragDetailsPage = (objektId: string, bestandseinheitId: string, vertragId: string) =>
  generatePath(URI_DASHBOARD_OBJEKT_BE_VERTRAG_DETAILS_PAGE, { objektId, bestandseinheitId, vertragId });

export const generatePathToBeVertragDetailsPageOrDiscrepancies = (
  objektId: string,
  bestandseinheitId: string,
  vertragId: string,
  status: { text: string; value: VertragStatus }
) =>
  status.value === VertragStatus.Invalid
    ? `${generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId)}/discrepancies`
    : generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId);

export const generatePathToBeVertragDetailsPageVertragsdaten = (objektId: string, bestandseinheitId: string, vertragId: string) =>
  `${generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId)}/pre-position`;

export const generatePathToBeVertragDetailsPageInfoFeldMenu = (objektId: string, bestandseinheitId: string, vertragId: string) =>
  `${generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId)}/info-felder`;

export const pathsToBeVertragDetailsPage = (objektId: string, bestandseinheitId: string, vertragId: string) => ({
  beVertragDetails: generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId),
  vertragsdaten: generatePathToBeVertragDetailsPageVertragsdaten(objektId, bestandseinheitId, vertragId),
  vertragsdatenVPosTab: `${generatePathToBeVertragDetailsPageVertragsdaten(objektId, bestandseinheitId, vertragId)}/vorschreibungspositionen`,
  vertragsdatenIndexationAgreementTab: `${generatePathToBeVertragDetailsPageVertragsdaten(objektId, bestandseinheitId, vertragId)}/indexation-agreements`,
  vertragDetailsInfoFeldMenu: generatePathToBeVertragDetailsPageInfoFeldMenu(objektId, bestandseinheitId, vertragId),
});

export const generatePathToBeVertragDetailsPageVertragsdatenVPosTab = (
  objektId: string,
  bestandseinheitId: string,
  vertragId: string,
  highlightedRowKey?: string | null,
  expRowIds?: string[]
): string =>
  `${pathsToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId).vertragsdatenVPosTab}?${mapToQueryString({
    [QUERY_PARAM_KEY.EXP_ROW_ID_LVL1]: expRowIds?.[0],
    [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: highlightedRowKey,
  })}`;

export const generatePathToBeVertragDetailsPageVertragsdatenVPosIndexationAgreementTab = (
  objektId: string,
  bestandseinheitId: string,
  vertragId: string,
  highlightedRowKey?: string | null,
  expRowIds?: string[]
): string =>
  `${pathsToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId).vertragsdatenIndexationAgreementTab}?${mapToQueryString({
    [QUERY_PARAM_KEY.EXP_ROW_ID_LVL1]: expRowIds?.[0],
    [QUERY_PARAM_KEY.EXP_ROW_ID_LVL2]: expRowIds?.[1],
    [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: highlightedRowKey,
  })}`;
