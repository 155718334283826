import { FC } from 'react';
import { FormikProps } from 'formik';
import { Typography } from 'antd';
import {
  bookingSuggestionFormFields,
  BookingSuggestionFormValues,
  OffenePostenForClearingSuggestion,
} from '../../../../Form/buchungsmaskeFormMapper';
import { AuszifferungFormValues } from '../../../auszifferungFormMapper';
import { getOffenePostenIndexFromMainOPList } from '../../../../Form/buchungsmaskeFormHelpers';
import { getAuszifferungIndex, isNewAuszifferung } from '../../../bookingSuggestionAuszifferungBlockHelpers';

type Props = {
  auszifferungListFieldName: string;
  formikProps: FormikProps<BookingSuggestionFormValues>;
  offenePosten: OffenePostenForClearingSuggestion;
  record: AuszifferungFormValues;
};

const DeleteAuszifferungColumnForPersonenkonto: FC<Props> = ({ auszifferungListFieldName, formikProps, offenePosten, record }) => {
  const removeAuszifferung = () => {
    // Actions for the Personenkonto OP list: remove the deleted Auszifferung, but keep the rest of the Auszifferung entries in the OP list
    const auszifferungIndex = getAuszifferungIndex(record, offenePosten);
    const newOffenePostenAzList = offenePosten.auszifferungList.toSpliced(auszifferungIndex, 1);
    formikProps.setFieldValue(auszifferungListFieldName, newOffenePostenAzList);

    // For the main OP list with booking suggestions -  if an Auszifferung suggestion is deleted, the entire Offene Posten entry should be deleted from
    const offenePostenIndexFromMainOPList = getOffenePostenIndexFromMainOPList(formikProps.values, offenePosten);
    formikProps.setFieldValue(
      bookingSuggestionFormFields.offenePostenForClearingSuggestionList,
      formikProps.values.offenePostenForClearingSuggestionList.toSpliced(offenePostenIndexFromMainOPList, 1)
    );
  };

  return isNewAuszifferung(record) ? (
    <Typography.Text style={{ color: '#1677ff', cursor: 'pointer' }} onClick={removeAuszifferung}>
      Entfernen
    </Typography.Text>
  ) : null;
};

export default DeleteAuszifferungColumnForPersonenkonto;
