import React, { FC } from 'react';
import { Button, Space, Spin, Typography } from 'antd';
import { HistoryOutlined, PlusOutlined } from '@ant-design/icons';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import { useVorsteuerkuerzungEinstellungListQuery } from '../gql/VorsteuerkuerzungEinstellungQueries.types';
import { Verwaltungsart } from '../../../types';

type Props = {
  verwaltungsart: Verwaltungsart;
  openTimelineCreateDrawer: () => void;
  openTimelineUpdateDrawer: () => void;
};

const VstKuerzungKontenZuweisungListingEmpty: FC<Props> = ({ verwaltungsart, openTimelineCreateDrawer, openTimelineUpdateDrawer }) => {
  const { data, loading } = useVorsteuerkuerzungEinstellungListQuery({ variables: { verwaltungsart } });

  const futureVorsteuerkuerzungEinstellung = data?.getVorsteuerkuerzungEinstellungList.data;

  return (
    <Space direction="vertical" align="center" style={{ width: '100%' }}>
      {loading ? (
        <Spin />
      ) : (
        <>
          <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
            <Button onClick={openTimelineCreateDrawer} size="small">
              <PlusOutlined />
              Neue Gültigkeit anlegen
            </Button>
          </ButtonsAligned>

          {futureVorsteuerkuerzungEinstellung && futureVorsteuerkuerzungEinstellung.length > 0 && (
            <Space align="baseline">
              <Typography.Title level={5}>Es existieren in der Zukunft liegende Vorsteuerkürzung-Kontenzuweisungen</Typography.Title>
              <HistoryOutlined onClick={openTimelineUpdateDrawer} />
            </Space>
          )}
        </>
      )}
    </Space>
  );
};

export default VstKuerzungKontenZuweisungListingEmpty;
