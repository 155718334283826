import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import RechtstraegerInfoFeldListing from './RechtstraegerInfoFeldListing';

type RechtstraegerInfoFeldTabProps = {
  rechtstraegerId: string;
};

const RechtstraegerInfoFeldTab: FC<RechtstraegerInfoFeldTabProps> = ({ rechtstraegerId }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Informationen',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechtstraegerInfoFeldListing rechtstraegerId={rechtstraegerId} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default RechtstraegerInfoFeldTab;
