import { Empty, PaginationProps } from 'antd';
import React, { FC } from 'react';
import directDebitOrBankWireColumns from './directDebitOrBankWireColumns';
import { DataCarrierPainListEntry } from '../../../../../types';
import { useAuth } from '../../../../../shared/Auth/authContext';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import { HandleTableSorting } from '../../../../../shared/typeHelpers';

type Props = {
  loading: boolean;
  dataCarrierPainList: DataCarrierPainListEntry[];
  pagination: PaginationProps;
  handleTableSorting: HandleTableSorting;
  onAction: () => void;
};

const DirectDebitOrBankWireTable: FC<Props> = ({ loading, dataCarrierPainList, pagination, handleTableSorting, onAction }) => {
  const { activeForFirmendatenId: firmendatenId } = useAuth();

  return (
    <NestedTable<DataCarrierPainListEntry>
      loading={loading}
      colWidth={150}
      locale={{
        emptyText: <Empty description={<span>Keine Datenträger vorhanden</span>} />,
      }}
      dataSource={dataCarrierPainList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={directDebitOrBankWireColumns(firmendatenId as string, onAction)}
      expandable={{ rowExpandable: () => false }}
      rowKey={(record) => record.dataCarrierId}
    />
  );
};

export default DirectDebitOrBankWireTable;
