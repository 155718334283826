import { BookingSuggestion, PaymentBuchungType, SollHaben } from '../../../types';

// ---- buchungType ----
export const isBuchungTypeZahlung = (buchungType: PaymentBuchungType) => buchungType === PaymentBuchungType.Zahlung;
export const isBuchungTypeRuecklastschrift = (buchungType: PaymentBuchungType) => buchungType === PaymentBuchungType.Ruecklastschrift;
export const isBuchungTypeSpesen = (buchungType: PaymentBuchungType) => buchungType === PaymentBuchungType.Spesen;
export const isBuchungTypeZahlungOrRuecklastschrift = (buchungType: PaymentBuchungType) =>
  isBuchungTypeZahlung(buchungType) || isBuchungTypeRuecklastschrift(buchungType);

// ---- BookingSuggestion ----
export const isBookingSuggestionZahlung = (bookingSuggestion: BookingSuggestion) => {
  return bookingSuggestion.buchungsanweisung.buchungType.value === PaymentBuchungType.Zahlung;
};

export const isBookingSuggestionRuecklastschrift = (bookingSuggestion: BookingSuggestion) => {
  return bookingSuggestion.buchungsanweisung.buchungType.value === PaymentBuchungType.Ruecklastschrift;
};

export const isBookingSuggestionSpesen = (bookingSuggestion: BookingSuggestion) => {
  return bookingSuggestion.buchungsanweisung.buchungType.value === PaymentBuchungType.Spesen;
};

export const isBookingSuggestionZahlungOrRuecklastschrift = (bookingSuggestion: BookingSuggestion) =>
  isBookingSuggestionZahlung(bookingSuggestion) || isBookingSuggestionRuecklastschrift(bookingSuggestion);

// ---- Buchungszeile ----
export const isBuchungszeileKontoTypeSoll = (sollHaben: SollHaben) => sollHaben === SollHaben.Soll;
