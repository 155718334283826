import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { FormikProps } from 'formik';
import { Input } from 'formik-antd';
import { pathsToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import DataWithShortenedTextAndExtraInfo from '../../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import SollHabenTag from '../../../../shared/SollHabenTag';
import KontoLinkToKontoblatt from '../../../../../shared/components/Konto/KontoLinkToKontoblatt';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import { compareTwoNumbersForSorting } from '../../../../../helpers/numberHelper';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import PaymentHeVertragColumn from '../../../../Payment/shared/PaymentHeVertragColumn';
import { BookingSuggestion } from '../../../../../types';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import Betrag from './Columns/Betrag';
import BuchungszeileTableActions from './buchungszeileTableActions';
import KontoFormSelect from './Columns/KontoFormSelect';
import { BookingSuggestionFormValues, bSBuchungszeileFormFields, BSBuchungszeileForTable } from '../../Form/buchungsmaskeFormMapper';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';
import { generatePathToBankstatementListingPage } from '../../../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';
import AvatarBasedOnBelegSymbolWithTooltip from '../../../../../components/Avatar/BasedOnBelegSymbol/AvatarBasedOnBelegSymbolWithTooltip';
import FormItemWithoutColonForBuchungszeile from '../../../../../components/Form/FormItemWithoutColonForBuchungszeile';
import { handleRowDelete } from './buchungszeileTableHelper';

const buchungszeileTableColumns = (
  fieldNamePrefix: string,
  bookingSuggestion: BookingSuggestion,
  formikProps: FormikProps<BookingSuggestionFormValues>,
  onRowDelete: (index: number) => void
): TableWithColSelectorColumnProps<BSBuchungszeileForTable>[] => {
  const { belegDatum, belegnummer, belegSymbol, currency, iban, statementNumber } = bookingSuggestion.buchungsanweisung;

  return [
    {
      title: 'Belegdatum',
      defaultSelected: true,
      align: 'right',
      render: () => <CustomFormattedDate value={belegDatum} />,
    },
    {
      title: 'Belegnummer',
      defaultSelected: true,
      align: 'right',
      render: (text, record) => {
        const content = (
          <DataWithShortenedText maxTextLength={15} text={belegnummer}>
            {(shortenedText) => (
              <Link
                to={generatePathToBankstatementListingPage({
                  iban,
                  statementNumber,
                })}
                target="_blank"
              >
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        );

        return (
          <Space size="small">
            <AvatarBasedOnBelegSymbolWithTooltip belegSymbol={belegSymbol} size="small" />
            {content}
          </Space>
        );
      },
    },
    {
      title: 'Objekt',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.objekt?.kurzBezeichnung, b.objekt?.kurzBezeichnung),
      render: (text, record) => {
        const kurzBezeichnung = record.objekt?.kurzBezeichnung;

        return record.objekt && kurzBezeichnung ? (
          <DataWithShortenedTextAndExtraInfo maxTextLength={12} text={kurzBezeichnung}>
            {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
            {(shortenedText) => <Link to={pathsToObjektDetailsPage(record.objekt!.objektId).objektDetails}>{shortenedText}</Link>}
          </DataWithShortenedTextAndExtraInfo>
        ) : (
          <TextForEmptyValue textToShow="none" />
        );
      },
    },
    {
      title: 'HE-Vertrag',
      defaultSelected: true,
      render: (text, record) => {
        const objektId = record.objekt?.objektId;
        const heVertragId = record.heVertragId;

        return objektId && heVertragId ? (
          <PaymentHeVertragColumn objektId={objektId} heVertragId={heVertragId} />
        ) : (
          <TextForEmptyValue textToShow="minus" />
        );
      },
    },
    {
      title: 'Konto',
      defaultSelected: true,
      width: 200,
      sorter: (a, b) => compareTwoStringsForSorting(a.konto.nummer, b.konto.nummer),
      render: (text, record, index) =>
        record.konto && !record.editable ? (
          <KontoLinkToKontoblatt
            konto={record.konto}
            buchungskreisId={formikProps.values.buchungsanweisung.buchungskreisId}
            objektId={record?.objekt?.objektId}
          />
        ) : (
          <FormItemWithoutColonForBuchungszeile name={`${fieldNamePrefix}[${index}].${bSBuchungszeileFormFields.kontoId}`}>
            <KontoFormSelect
              name={`${fieldNamePrefix}[${index}].${bSBuchungszeileFormFields.kontoId}`}
              bookingSuggestion={bookingSuggestion}
              formikProps={formikProps}
              bzIndex={index}
            />
          </FormItemWithoutColonForBuchungszeile>
        ),
    },
    {
      title: 'Gegenkonto',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.gegenKonto?.nummer, b.gegenKonto?.nummer),
      render: (text, record) =>
        record.gegenKonto ? (
          <KontoLinkToKontoblatt
            konto={record.gegenKonto}
            buchungskreisId={formikProps.values.buchungsanweisung.buchungskreisId}
            withBezeichnung={false}
          />
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: 'Betrag',
      defaultSelected: true,
      align: 'right',
      width: 200,
      sorter: (a, b) => compareTwoNumbersForSorting(a.amount, b.amount),
      render: (text, record, index) =>
        !record.editable ? (
          <Betrag betrag={record.amount} sollHaben={record.sollHaben.value} />
        ) : (
          <FormItemWithoutColonForBuchungszeile name={`${fieldNamePrefix}[${index}].${bSBuchungszeileFormFields.amount}`}>
            <FormattedDecimalFormInput name={`${fieldNamePrefix}[${index}].${bSBuchungszeileFormFields.amount}`} isCurrency />
          </FormItemWithoutColonForBuchungszeile>
        ),
    },
    {
      title: 'Währung',
      defaultSelected: true,
      render: (text, record) => <Typography.Text>{currency.text}</Typography.Text>,
    },
    {
      title: 'S/H',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.sollHaben.value, b.sollHaben.value),
      render: (text, record) => <SollHabenTag sollHaben={record.sollHaben} />,
    },
    {
      title: 'Buchungstext',
      defaultSelected: true,
      width: 250,
      sorter: (a, b) => compareTwoStringsForSorting(a.text, b.text),
      render: (text, record, index) =>
        !record.editable ? (
          <DataWithShortenedTextAndExtraInfo maxTextLength={35} text={record.text} />
        ) : (
          <FormItemWithoutColonForBuchungszeile name={`${fieldNamePrefix}[${index}].${bSBuchungszeileFormFields.text}`}>
            <Input name={`${fieldNamePrefix}[${index}].${bSBuchungszeileFormFields.text}`} />
          </FormItemWithoutColonForBuchungszeile>
        ),
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (text, record, index) => (
        <BuchungszeileTableActions
          onRowDelete={() => handleRowDelete(index, record, bookingSuggestion, formikProps, onRowDelete)}
          romDeleteDisabled={!record.deletable}
          bookingSuggestionId={bookingSuggestion.bookingSuggestionId}
          buchungszeileId={record.buchungszeileId}
        />
      ),
    },
  ];
};

export default buchungszeileTableColumns;
