import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AbrechnungskreisFieldsFragmentDoc } from '../../Abrechnungskreis/gql/AbrechnungskreisFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungskreisListForSelectByFirmendatenIdQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AbrechnungskreisListForSelectByFirmendatenIdQuery = {
  getAbrechnungskreisListByFirmendatenId: {
    data: Array<{
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
      type: { text: string; value: Types.AbrechnungskreisType };
      verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungskreisListForSelectByKundenSystemIdQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AbrechnungskreisListForSelectByKundenSystemIdQuery = {
  getAbrechnungskreisListByKundenSystemId: {
    data: Array<{
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
      type: { text: string; value: Types.AbrechnungskreisType };
      verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AbrechnungskreisListForSelectByFirmendatenIdDocument = gql`
  query AbrechnungskreisListForSelectByFirmendatenId($firmendatenId: ID!, $includeArchiviert: Boolean) {
    getAbrechnungskreisListByFirmendatenId(firmendatenId: $firmendatenId, includeArchiviert: $includeArchiviert) {
      data {
        ...AbrechnungskreisFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AbrechnungskreisFieldsFragmentDoc}
`;
export function useAbrechnungskreisListForSelectByFirmendatenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AbrechnungskreisListForSelectByFirmendatenIdQuery,
    AbrechnungskreisListForSelectByFirmendatenIdQueryVariables
  > &
    ({ variables: AbrechnungskreisListForSelectByFirmendatenIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungskreisListForSelectByFirmendatenIdQuery, AbrechnungskreisListForSelectByFirmendatenIdQueryVariables>(
    AbrechnungskreisListForSelectByFirmendatenIdDocument,
    options
  );
}
export function useAbrechnungskreisListForSelectByFirmendatenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrechnungskreisListForSelectByFirmendatenIdQuery,
    AbrechnungskreisListForSelectByFirmendatenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungskreisListForSelectByFirmendatenIdQuery, AbrechnungskreisListForSelectByFirmendatenIdQueryVariables>(
    AbrechnungskreisListForSelectByFirmendatenIdDocument,
    options
  );
}
export function useAbrechnungskreisListForSelectByFirmendatenIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AbrechnungskreisListForSelectByFirmendatenIdQuery, AbrechnungskreisListForSelectByFirmendatenIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungskreisListForSelectByFirmendatenIdQuery, AbrechnungskreisListForSelectByFirmendatenIdQueryVariables>(
    AbrechnungskreisListForSelectByFirmendatenIdDocument,
    options
  );
}
export type AbrechnungskreisListForSelectByFirmendatenIdQueryHookResult = ReturnType<typeof useAbrechnungskreisListForSelectByFirmendatenIdQuery>;
export type AbrechnungskreisListForSelectByFirmendatenIdLazyQueryHookResult = ReturnType<
  typeof useAbrechnungskreisListForSelectByFirmendatenIdLazyQuery
>;
export type AbrechnungskreisListForSelectByFirmendatenIdSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungskreisListForSelectByFirmendatenIdSuspenseQuery
>;
export type AbrechnungskreisListForSelectByFirmendatenIdQueryResult = Apollo.QueryResult<
  AbrechnungskreisListForSelectByFirmendatenIdQuery,
  AbrechnungskreisListForSelectByFirmendatenIdQueryVariables
>;
export const AbrechnungskreisListForSelectByKundenSystemIdDocument = gql`
  query AbrechnungskreisListForSelectByKundenSystemId($kundenSystemId: ID!, $includeArchiviert: Boolean) {
    getAbrechnungskreisListByKundenSystemId(kundenSystemId: $kundenSystemId, includeArchiviert: $includeArchiviert) {
      data {
        ...AbrechnungskreisFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AbrechnungskreisFieldsFragmentDoc}
`;
export function useAbrechnungskreisListForSelectByKundenSystemIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AbrechnungskreisListForSelectByKundenSystemIdQuery,
    AbrechnungskreisListForSelectByKundenSystemIdQueryVariables
  > &
    ({ variables: AbrechnungskreisListForSelectByKundenSystemIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungskreisListForSelectByKundenSystemIdQuery, AbrechnungskreisListForSelectByKundenSystemIdQueryVariables>(
    AbrechnungskreisListForSelectByKundenSystemIdDocument,
    options
  );
}
export function useAbrechnungskreisListForSelectByKundenSystemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrechnungskreisListForSelectByKundenSystemIdQuery,
    AbrechnungskreisListForSelectByKundenSystemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungskreisListForSelectByKundenSystemIdQuery, AbrechnungskreisListForSelectByKundenSystemIdQueryVariables>(
    AbrechnungskreisListForSelectByKundenSystemIdDocument,
    options
  );
}
export function useAbrechnungskreisListForSelectByKundenSystemIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AbrechnungskreisListForSelectByKundenSystemIdQuery, AbrechnungskreisListForSelectByKundenSystemIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungskreisListForSelectByKundenSystemIdQuery, AbrechnungskreisListForSelectByKundenSystemIdQueryVariables>(
    AbrechnungskreisListForSelectByKundenSystemIdDocument,
    options
  );
}
export type AbrechnungskreisListForSelectByKundenSystemIdQueryHookResult = ReturnType<typeof useAbrechnungskreisListForSelectByKundenSystemIdQuery>;
export type AbrechnungskreisListForSelectByKundenSystemIdLazyQueryHookResult = ReturnType<
  typeof useAbrechnungskreisListForSelectByKundenSystemIdLazyQuery
>;
export type AbrechnungskreisListForSelectByKundenSystemIdSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungskreisListForSelectByKundenSystemIdSuspenseQuery
>;
export type AbrechnungskreisListForSelectByKundenSystemIdQueryResult = Apollo.QueryResult<
  AbrechnungskreisListForSelectByKundenSystemIdQuery,
  AbrechnungskreisListForSelectByKundenSystemIdQueryVariables
>;
