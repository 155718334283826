import styled from 'styled-components';
import { Collapse } from 'antd';

export const StyledCollapsePanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    background: #fafafa;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;
