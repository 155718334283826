import { ObjektAbrechnung } from '../templates/objektAbrechnung/objektAbrechnung-types';

export const mockObjektAbrechnungData: ObjektAbrechnung = {
  type: 'OBJEKT_ABRECHNUNG',
  abrechnungsTitel: 'Abrechnung von Betriebskosten, besonderen Aufwendungen und sonstigen Kosten der Bewirtschaftung',
  kopf: {
    addressListText: 'für die Abrechnungseinheit:',
    addressList: [
      {
        countryCodeIso2: 'AT',
        city: 'Wien',
        street: 'Karmarschgasse',
        houseEntranceApartmentNumber: '51',
        zipCode: '1100',
      },
      {
        countryCodeIso2: 'AT',
        city: 'Wien',
        street: 'Neilreichgasse',
        houseEntranceApartmentNumber: '78',
        zipCode: '1100',
      },
    ],
    zeitraumText: 'für den Zeitraum:',
    zeitraumVon: '01.01.2019',
    zeitraumBis: '31.12.2019',
    erstellungsDatum: '01.07.2020',
  },
  abrechnungskreiseBlock: {
    abrechnungskreisList: [
      {
        type: 'BEWIRTSCHAFTUNGSKOSTEN',
        bezeichnung: 'Betriebskosten',
        aufteilungsschluessel: {
          aufteilungsschluesselText: 'Aufteilungsschlüssel:',
          aufteilungsschluessel: 'Nutzwert',
        },
        kontoList: [
          {
            bezeichnung: 'Wasser-/Abwassergebühr',
            kontonummer: 'S1000',
            buchungAusgabenList: [
              {
                bezeichnung: 'Wasser-/Abwassergebühr 01-03/2019',
                buchungsDatum: '28.01.2019',
                belegnummer: 'ER136/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '2.156,49',
              },
              {
                bezeichnung: 'Wasser-/Abwassergebühr 04-06/2019',
                buchungsDatum: '29.04.2019',
                belegnummer: 'ER611/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '2.156,49',
              },
              {
                bezeichnung: 'Wasser-/Abwassergebühr 07-09/2019',
                buchungsDatum: '21.07.2019',
                belegnummer: 'ER1267/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '2.156,49',
              },
              {
                bezeichnung: 'Wasser-/Abwassergebühr 10-12/2019',
                buchungsDatum: '14.10.2019',
                belegnummer: 'ER1389/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '4.226,42',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '10.722,89',
            aufteilungsschluessel: {
              aufteilungsschluesselText: 'Abweichender Aufteilungsschlüssel:',
              aufteilungsschluessel: 'Nutzfläche',
            },
          },
          {
            bezeichnung: 'Hausverwaltung',
            kontonummer: 'S1000',
            buchungAusgabenList: [
              {
                bezeichnung: 'Honorar 01/2019',
                buchungsDatum: '05.01.2019',
                belegnummer: 'HON 0030',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 02/2019',
                buchungsDatum: '05.02.2019',
                belegnummer: 'HON 0085',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 03/2019',
                buchungsDatum: '05.03.2019',
                belegnummer: 'HON 0130',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 04/2019',
                buchungsDatum: '05.04.2019',
                belegnummer: 'HON 0177',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 05/2019',
                buchungsDatum: '05.05.2019',
                belegnummer: 'HON 0198',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 06/2019',
                buchungsDatum: '05.06.2019',
                belegnummer: 'HON 0270',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 07/2019',
                buchungsDatum: '05.07.2019',
                belegnummer: 'HON 0345',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 08/2019',
                buchungsDatum: '05.08.2019',
                belegnummer: 'HON 0499',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 09/2019',
                buchungsDatum: '05.09.2019',
                belegnummer: 'HON 0518',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 10/2019',
                buchungsDatum: '05.10.2019',
                belegnummer: 'HON 0571',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 11/2019',
                buchungsDatum: '05.11.2019',
                belegnummer: 'HON 0690',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 12/2019',
                buchungsDatum: '05.12.2019',
                belegnummer: 'HON 0801',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '8.886,48',
          },
          {
            bezeichnung: 'Hausbetreuung',
            kontonummer: 'S1000',
            buchungAusgabenList: [
              {
                bezeichnung: 'Hausreinigung 01/2019',
                buchungsDatum: '02.01.2019',
                belegnummer: 'ER293/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 02/2019',
                buchungsDatum: '02.02.2019',
                belegnummer: 'ER529/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 03/2019',
                buchungsDatum: '02.03.2019',
                belegnummer: 'ER1099/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 04/2019',
                buchungsDatum: '02.04.2019',
                belegnummer: 'ER2011/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 05/2019',
                buchungsDatum: '02.05.2019',
                belegnummer: 'ER3162/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 06/2019',
                buchungsDatum: '02.06.2019',
                belegnummer: 'ER4921/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 07/2019',
                buchungsDatum: '02.07.2019',
                belegnummer: 'ER5130/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 08/2019',
                buchungsDatum: '02.08.2019',
                belegnummer: 'ER6782/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 09/2019',
                buchungsDatum: '02.09.2019',
                belegnummer: 'ER7899/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 10/2019',
                buchungsDatum: '02.10.2019',
                belegnummer: 'ER9012/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 11/2019',
                buchungsDatum: '02.11.2019',
                belegnummer: 'ER10902/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 12/2019',
                buchungsDatum: '02.12.2019',
                belegnummer: 'ER12000/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '6.202.64',
            aufteilungsschluessel: {
              aufteilungsschluesselText: 'Abweichender Aufteilungsschlüssel:',
              aufteilungsschluessel: 'Nutzfläche-Aufzug',
            },
          },
          {
            bezeichnung: 'Strom allgemein',
            kontonummer: 'S1000',
            buchungAusgabenList: [
              {
                bezeichnung: 'Teilbetrag',
                buchungsDatum: '02.01.2019',
                belegnummer: 'ER77/2019',
                kurzbezeichnungVertragsparthner: 'EVN AG',
                betragNetto: '320,83',
              },
              {
                bezeichnung: 'Teilbetrag',
                buchungsDatum: '27.03.2019',
                belegnummer: 'ER458/2019',
                kurzbezeichnungVertragsparthner: 'EVN AG',
                betragNetto: '320,83',
              },
              {
                bezeichnung: 'Teilbetrag',
                buchungsDatum: '26.06.2019',
                belegnummer: 'ER983/2019',
                kurzbezeichnungVertragsparthner: 'EVN AG',
                betragNetto: '320,83',
              },
              {
                bezeichnung: 'Jahresrechnung',
                buchungsDatum: '16.09.2019',
                belegnummer: 'ER1289/2019',
                kurzbezeichnungVertragsparthner: 'EVN AG',
                betragNetto: '1001,40',
              },
              {
                bezeichnung: 'Teilbetrag',
                buchungsDatum: '31.12.2019',
                belegnummer: 'ER1289/2019',
                kurzbezeichnungVertragsparthner: 'EVN AG',
                betragNetto: '262,50',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '2.226,39',
          },
        ],
        summeNettoAusgabenText: 'Summe Ausgaben',
        summeNettoAusgaben: '28.038,40',
        buchungEinnahmenListText: 'Einnahmen:',
        buchungEinnahmenList: [
          {
            bezeichnung: 'Betriebskosten Haus 1/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 2/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 3/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 4/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 5/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 6/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 7/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 8/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 9/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 10/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 11/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 12/2019',
            betragNetto: '500,00',
          },
        ],
        summeNettoEinnahmenText: 'Summe Einnahmen',
        summeNettoEinnahmen: '6.000,00',
        ergebnisNettoText: 'Ergebnis: Saldo',
        ergebnisNetto: '-22.038,40',
        summeNettoExpenseReducingText: 'Zwischensumme Aufwandsmindernd',
        summeNettoExpenseReducing: '2350,00',
        expenseReducingKontoList: [
          {
            bezeichnung: 'Wegerecht Huber',
            kontonummer: null,
            aufteilungsschluessel: null,
            betragNettoSummeBuchungenText: null,
            betragNettoSummeBuchungen: '450,00',
            buchungAusgabenList: [],
          },
          {
            bezeichnung: 'Dubiöse Einnahmen',
            kontonummer: null,
            aufteilungsschluessel: null,
            betragNettoSummeBuchungenText: null,
            betragNettoSummeBuchungen: '1900,00',
            buchungAusgabenList: [],
          },
        ],
      },
      {
        type: 'BEWIRTSCHAFTUNGSKOSTEN',
        bezeichnung: 'Betriebskosten',
        aufteilungsschluessel: {
          aufteilungsschluesselText: 'Aufteilungsschlüssel:',
          aufteilungsschluessel: 'Nutzfläche',
        },
        kontoList: [
          {
            bezeichnung: 'Wasser-/Abwassergebühr',
            kontonummer: 'S1000',
            buchungAusgabenList: [
              {
                bezeichnung: 'Wasser-/Abwassergebühr 01-03/2019',
                buchungsDatum: '28.01.2019',
                belegnummer: 'ER136/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '2.156,49',
              },
              {
                bezeichnung: 'Wasser-/Abwassergebühr 04-06/2019',
                buchungsDatum: '29.04.2019',
                belegnummer: 'ER611/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '2.156,49',
              },
              {
                bezeichnung: 'Wasser-/Abwassergebühr 07-09/2019',
                buchungsDatum: '21.07.2019',
                belegnummer: 'ER1267/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '2.156,49',
              },
              {
                bezeichnung: 'Wasser-/Abwassergebühr 10-12/2019',
                buchungsDatum: '14.10.2019',
                belegnummer: 'ER1389/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '4.226,42',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '10.722,89',
          },
          {
            bezeichnung: 'Hausverwaltung',
            kontonummer: 'S1000',
            buchungAusgabenList: [
              {
                bezeichnung: 'Honorar 01/2019',
                buchungsDatum: '05.01.2019',
                belegnummer: 'HON 0030',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 02/2019',
                buchungsDatum: '05.02.2019',
                belegnummer: 'HON 0085',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 03/2019',
                buchungsDatum: '05.03.2019',
                belegnummer: 'HON 0130',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 04/2019',
                buchungsDatum: '05.04.2019',
                belegnummer: 'HON 0177',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 05/2019',
                buchungsDatum: '05.05.2019',
                belegnummer: 'HON 0198',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 06/2019',
                buchungsDatum: '05.06.2019',
                belegnummer: 'HON 0270',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 07/2019',
                buchungsDatum: '05.07.2019',
                belegnummer: 'HON 0345',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 08/2019',
                buchungsDatum: '05.08.2019',
                belegnummer: 'HON 0499',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 09/2019',
                buchungsDatum: '05.09.2019',
                belegnummer: 'HON 0518',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 10/2019',
                buchungsDatum: '05.10.2019',
                belegnummer: 'HON 0571',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 11/2019',
                buchungsDatum: '05.11.2019',
                belegnummer: 'HON 0690',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
              {
                bezeichnung: 'Honorar 12/2019',
                buchungsDatum: '05.12.2019',
                belegnummer: 'HON 0801',
                kurzbezeichnungVertragsparthner: 'Homebase Immobilienverwaltungs GmbH',
                betragNetto: '740,54',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '8.886,48',
            aufteilungsschluessel: {
              aufteilungsschluesselText: 'Abweichender Aufteilungsschlüssel:',
              aufteilungsschluessel: 'Nutzwert',
            },
          },
          {
            bezeichnung: 'Hausbetreuung',
            kontonummer: 'S1000',
            buchungAusgabenList: [
              {
                bezeichnung: 'Hausreinigung 01/2019',
                buchungsDatum: '02.01.2019',
                belegnummer: 'ER293/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 02/2019',
                buchungsDatum: '02.02.2019',
                belegnummer: 'ER529/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 03/2019',
                buchungsDatum: '02.03.2019',
                belegnummer: 'ER1099/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 04/2019',
                buchungsDatum: '02.04.2019',
                belegnummer: 'ER2011/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 05/2019',
                buchungsDatum: '02.05.2019',
                belegnummer: 'ER3162/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 06/2019',
                buchungsDatum: '02.06.2019',
                belegnummer: 'ER4921/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 07/2019',
                buchungsDatum: '02.07.2019',
                belegnummer: 'ER5130/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 08/2019',
                buchungsDatum: '02.08.2019',
                belegnummer: 'ER6782/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 09/2019',
                buchungsDatum: '02.09.2019',
                belegnummer: 'ER7899/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 10/2019',
                buchungsDatum: '02.10.2019',
                belegnummer: 'ER9012/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 11/2019',
                buchungsDatum: '02.11.2019',
                belegnummer: 'ER10902/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
              {
                bezeichnung: 'Hausreinigung 12/2019',
                buchungsDatum: '02.12.2019',
                belegnummer: 'ER12000/2019',
                kurzbezeichnungVertragsparthner: 'PDS - Pfanner e.U. Gebäudereiningung',
                betragNetto: '516,89',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '6.202.64',
          },
          {
            bezeichnung: 'Strom allgemein',
            kontonummer: 'S1000',
            buchungAusgabenList: [
              {
                bezeichnung: 'Teilbetrag',
                buchungsDatum: '02.01.2019',
                belegnummer: 'ER77/2019',
                kurzbezeichnungVertragsparthner: 'EVN AG',
                betragNetto: '320,83',
              },
              {
                bezeichnung: 'Teilbetrag',
                buchungsDatum: '27.03.2019',
                belegnummer: 'ER458/2019',
                kurzbezeichnungVertragsparthner: 'EVN AG',
                betragNetto: '320,83',
              },
              {
                bezeichnung: 'Teilbetrag',
                buchungsDatum: '26.06.2019',
                belegnummer: 'ER983/2019',
                kurzbezeichnungVertragsparthner: 'EVN AG',
                betragNetto: '320,83',
              },
              {
                bezeichnung: 'Jahresrechnung',
                buchungsDatum: '16.09.2019',
                belegnummer: 'ER1289/2019',
                kurzbezeichnungVertragsparthner: 'EVN AG',
                betragNetto: '1001,40',
              },
              {
                bezeichnung: 'Teilbetrag',
                buchungsDatum: '31.12.2019',
                belegnummer: 'ER1289/2019',
                kurzbezeichnungVertragsparthner: 'EVN AG',
                betragNetto: '262,50',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '2.226,39',
          },
        ],
        summeNettoAusgabenText: 'Summe Ausgaben',
        summeNettoAusgaben: '28.038,40',
        buchungEinnahmenListText: 'Einnahmen:',
        buchungEinnahmenList: [
          {
            bezeichnung: 'Betriebskosten Haus 1/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 2/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 3/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 4/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 5/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 6/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 7/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 8/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 9/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 10/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 11/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Betriebskosten Haus 12/2019',
            betragNetto: '500,00',
          },
        ],
        summeNettoEinnahmenText: 'Summe Einnahmen',
        summeNettoEinnahmen: '6.000,00',
        ergebnisNettoText: 'Ergebnis: Saldo',
        ergebnisNetto: '-22.038,40',
        summeNettoExpenseReducingText: 'Zwischensumme Aufwandsmindernd',
        summeNettoExpenseReducing: '60,80',
        expenseReducingKontoList: [
          {
            bezeichnung: 'Waschmaschine',
            kontonummer: null,
            aufteilungsschluessel: null,
            betragNettoSummeBuchungenText: null,
            betragNettoSummeBuchungen: '60,80',
            buchungAusgabenList: [],
          },
        ],
      },
      {
        type: 'VORSTEUERKUERZUNG',
        bezeichnung: 'Vorsteuerkürzung',
        kontoList: [
          {
            bezeichnung: 'Vst-Kürzung Verr- Mieter/WE',
            kontonummer: 'S1000',

            aufteilungsschluessel: {
              aufteilungsschluesselText: 'Aufteilungsschlüssel:',
              aufteilungsschluessel: 'Nutzfläche',
            },
            buchungAusgabenList: [
              {
                bezeichnung: 'Vst-Kürzung 01.2022',
                buchungsDatum: '31.01.2022',
                betragNetto: '5,24',
              },
              {
                bezeichnung: 'Vst-Kürzung 02.2022',
                buchungsDatum: '28.02.2022',
                betragNetto: '8,10',
              },
              {
                bezeichnung: 'Vst-Kürzung 03.2022',
                buchungsDatum: '31.03.2022',
                betragNetto: '5,77',
              },
              {
                bezeichnung: 'Vst-Kürzung 04.2022',
                buchungsDatum: '30.04.2022',
                betragNetto: '12,57',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '31,68',
          },
          {
            bezeichnung: 'Vst-Kürzung Rep. Fonds/Verr. WE',
            kontonummer: 'S1000',

            aufteilungsschluessel: {
              aufteilungsschluesselText: 'Aufteilungsschlüssel:',
              aufteilungsschluessel: 'Nutzfläche, Nutzwert',
            },
            buchungAusgabenList: [
              {
                bezeichnung: 'Vst-Kürzung 01.2022',
                buchungsDatum: '31.01.2022',
                betragNetto: '59,97',
              },
              {
                bezeichnung: 'Vst-Kürzung 02.2022',
                buchungsDatum: '28.02.2022',
                betragNetto: '9,52',
              },
              {
                bezeichnung: 'Vst-Kürzung 03.2022',
                buchungsDatum: '31.03.2022',
                betragNetto: '71,28',
              },
              {
                bezeichnung: 'Vst-Kürzung 04.2022',
                buchungsDatum: '30.04.2022',
                betragNetto: '20,22',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '110,99',
          },
        ],
        summeNettoAusgabenText: 'Summe Ausgaben',
        summeNettoAusgaben: '142,67',
        buchungEinnahmenListText: 'Einnahmen:',
        buchungEinnahmenList: [],
        summeNettoEinnahmenText: 'Summe Einnahmen',
        summeNettoEinnahmen: '0,00',
        ergebnisNettoText: 'Ergebnis: Saldo',
        ergebnisNetto: '-142,67',
        summeNettoExpenseReducingText: 'Zwischensumme Aufwandsmindernd',
        summeNettoExpenseReducing: '60,80',
        expenseReducingKontoList: [
          {
            bezeichnung: 'Waschmaschine',
            kontonummer: null,
            aufteilungsschluessel: null,
            betragNettoSummeBuchungenText: null,
            betragNettoSummeBuchungen: '60,80',
            buchungAusgabenList: [],
          },
        ],
      },
      {
        type: 'REPARATUR_FONDS',
        bezeichnung: 'Rep. Fonds',
        kontoList: [
          {
            bezeichnung: 'Aufwand detailliert / 39',
            kontonummer: 'S1000',
            buchungAusgabenList: [
              {
                bezeichnung: 'def. Ventil',
                buchungsDatum: '28.01.2019',
                belegnummer: 'ER136/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '26,49',
              },
              {
                bezeichnung: 'Haustor einstellen',
                buchungsDatum: '29.04.2019',
                belegnummer: 'ER611/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '226,49',
              },
              {
                bezeichnung: 'Keller def.Lichschalter',
                buchungsDatum: '21.07.2019',
                belegnummer: 'ER1267/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '2.156,49',
              },
              {
                bezeichnung: 'def. Ventil',
                buchungsDatum: '14.10.2019',
                belegnummer: 'ER1389/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '226,42',
              },
              {
                bezeichnung: 'Haustor einstellen',
                buchungsDatum: '14.12.2019',
                belegnummer: 'ER1389/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '226,42',
              },
              {
                bezeichnung: 'Keller def.Lichschalter',
                buchungsDatum: '21.07.2019',
                belegnummer: 'ER1267/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '56,49',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '4730,25',
          },
          {
            bezeichnung: 'Bankspesen',
            kontonummer: 'S1000',
            buchungAusgabenList: [
              {
                bezeichnung: 'Bankspesen 1. Quartal',
                buchungsDatum: '31.03.2019',
                belegnummer: 'HON 0030',
                betragNetto: '72,42',
              },
              {
                bezeichnung: 'Bankspesen 2. Quartal',
                buchungsDatum: '30.06.2019',
                belegnummer: 'HON 0085',
                betragNetto: '72,30',
              },
              {
                bezeichnung: 'Bankspesen 3. Quartal',
                buchungsDatum: '30.09.2019',
                belegnummer: 'HON 0130',
                betragNetto: '76,38',
              },
              {
                bezeichnung: 'Bankspesen 4. Quartal',
                buchungsDatum: '29.12.2019',
                belegnummer: 'HON 0177',
                betragNetto: '68,63',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '289,73',
          },
        ],
        summeNettoAusgabenText: 'Summe Ausgaben',
        summeNettoAusgaben: '28.038,40',
        buchungEinnahmenListText: 'Einnahmen:',
        buchungEinnahmenList: [
          {
            bezeichnung: 'Abr. 2018 Rep. Fonds',
            betragNetto: '14,00',
          },
          {
            bezeichnung: 'Habenzinsen abzgl. KEST',
            betragNetto: '25,93',
          },
          {
            bezeichnung: 'Rücklage | allg. 1/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 2/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 3/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 4/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 5/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 6/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 7/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 8/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 9/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 10/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 11/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 12/2019',
            betragNetto: '500,00',
          },
        ],
        buchungUstVomAufwandList: [
          {
            bezeichnung: '+10% Umsatzsteuer vom Aufwand',
            betragNetto: '14,00',
          },
          {
            bezeichnung: '+20% Umsatzsteuer vom Aufwand',
            betragNetto: '25,93',
          },
        ],
        summeNettoEinnahmenText: 'Summe Einnahmen',
        summeNettoEinnahmen: '6.000,00',
        ergebnisNettoText: 'Ergebnis: Nachforderung',
        ergebnisNetto: '-21998,47',
        saldoVortragText: 'Saldovortrag',
        saldoVortrag: '47.823,86',
        ausgabenDetailsTitleText: 'Ausgaben detailliert:',
        summeNettoExpenseReducingText: 'Zwischensumme Aufwandsmindernd',
        summeNettoExpenseReducing: '60,80',
        expenseReducingKontoList: [
          {
            bezeichnung: 'Waschmaschine',
            kontonummer: null,
            aufteilungsschluessel: null,
            betragNettoSummeBuchungenText: null,
            betragNettoSummeBuchungen: '60,80',
            buchungAusgabenList: [],
          },
        ],
      },
      {
        type: 'REPARATUR_FONDS',
        bezeichnung: 'Rep. Fonds Aufzug',
        kontoList: [
          {
            bezeichnung: 'Aufwand detailliert / 39',
            kontonummer: 'S1000',
            buchungAusgabenList: [
              {
                bezeichnung: 'def. Ventil',
                buchungsDatum: '28.01.2019',
                belegnummer: 'ER136/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '26,49',
              },
              {
                bezeichnung: 'Haustor einstellen',
                buchungsDatum: '29.04.2019',
                belegnummer: 'ER611/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '226,49',
              },
              {
                bezeichnung: 'Keller def.Lichschalter',
                buchungsDatum: '21.07.2019',
                belegnummer: 'ER1267/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '2.156,49',
              },
              {
                bezeichnung: 'def. Ventil',
                buchungsDatum: '14.10.2019',
                belegnummer: 'ER1389/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '226,42',
              },
              {
                bezeichnung: 'Haustor einstellen',
                buchungsDatum: '14.12.2019',
                belegnummer: 'ER1389/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '226,42',
              },
              {
                bezeichnung: 'Keller def.Lichschalter',
                buchungsDatum: '21.07.2019',
                belegnummer: 'ER1267/2019',
                kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                betragNetto: '56,49',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '4730,25',
          },
          {
            bezeichnung: 'Bankspesen',
            kontonummer: 'S1000',

            buchungAusgabenList: [
              {
                bezeichnung: 'Bankspesen 1. Quartal',
                buchungsDatum: '31.03.2019',
                belegnummer: 'HON 0030',
                betragNetto: '72,42',
              },
              {
                bezeichnung: 'Bankspesen 2. Quartal',
                buchungsDatum: '30.06.2019',
                belegnummer: 'HON 0085',
                betragNetto: '72,30',
              },
              {
                bezeichnung: 'Bankspesen 3. Quartal',
                buchungsDatum: '30.09.2019',
                belegnummer: 'HON 0130',
                betragNetto: '76,38',
              },
              {
                bezeichnung: 'Bankspesen 4. Quartal',
                buchungsDatum: '29.12.2019',
                belegnummer: 'HON 0177',
                betragNetto: '68,63',
              },
            ],
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '289,73',
          },
        ],
        summeNettoAusgabenText: 'Summe Ausgaben',
        summeNettoAusgaben: '28.038,40',
        buchungEinnahmenListText: 'Einnahmen:',
        buchungEinnahmenList: [
          {
            bezeichnung: 'Abr. 2018 Rep. Fonds',
            betragNetto: '14,00',
          },
          {
            bezeichnung: 'Habenzinsen abzgl. KEST',
            betragNetto: '25,93',
          },
          {
            bezeichnung: 'Rücklage | allg. 1/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 2/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 3/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 4/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 5/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 6/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 7/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 8/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 9/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 10/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 11/2019',
            betragNetto: '500,00',
          },
          {
            bezeichnung: 'Rücklage | allg. 12/2019',
            betragNetto: '500,00',
          },
        ],
        summeNettoEinnahmenText: 'Summe Einnahmen',
        summeNettoEinnahmen: '6.000,00',
        ergebnisNettoText: 'Ergebnis: Guthaben',
        ergebnisNetto: '-22.038,40',
        saldoVortragText: 'Saldovortrag',
        saldoVortrag: '47.823,86',
        ausgabenDetailsTitleText: 'Ausgaben detailliert:',
        summeNettoExpenseReducingText: 'Zwischensumme Aufwandsmindernd',
        summeNettoExpenseReducing: '60,80',
        expenseReducingKontoList: [
          {
            bezeichnung: 'Waschmaschine',
            kontonummer: null,
            aufteilungsschluessel: null,
            betragNettoSummeBuchungenText: null,
            betragNettoSummeBuchungen: '60,80',
            buchungAusgabenList: [],
          },
        ],
      },
    ],
    abrechnungskreisTableBezeichnungText: 'Bezeichnung / Text',
    abrechnungskreisTableBelegDatumText: 'Datum',
    abrechnungskreisTableBelegNummerText: 'Beleg',
    abrechnungskreisTableVertragspartnerText: 'Vertragspartner',
  },
  informationAbrechnungskreiseBlock: {
    abrechnungskreisTableBezeichnungText: null,
    abrechnungskreisTableBelegDatumText: null,
    abrechnungskreisTableBelegNummerText: null,
    abrechnungskreisTableVertragspartnerText: null,
    abrechnungskreisList: [
      {
        type: 'INFORMATION',
        bezeichnung: 'Information',
        aufteilungsschluessel: null,
        summeNettoAusgabenText: 'Summe Ausgaben',
        summeNettoAusgaben: '64,99',
        summeNettoEinnahmenText: 'Summe Einnahmen',
        summeNettoEinnahmen: '95,62',
        ergebnisNettoText: 'Ergebnis: Saldo',
        ergebnisNetto: '-30,63',
        buchungEinnahmenListText: 'Einnahmen',
        saldoVortragText: 'Vorsaldo:',
        saldoVortrag: '0,00',
        ausgabenDetailsTitleText: null,
        kontoList: [
          {
            bezeichnung: 'Waschmaschine',
            kontonummer: 'S1000',
            aufteilungsschluessel: null,
            betragNettoSummeBuchungenText: 'Zwischensumme',
            betragNettoSummeBuchungen: '64,99',
            buchungAusgabenList: [
              {
                bezeichnung: 'Vorschreibung 2023/12',
                buchungsDatum: '13.12.2023',
                belegnummer: '1',
                kurzbezeichnungVertragsparthner: 'Kraker L. u.Heiner G.',
                betragNetto: '64,99',
              },
            ],
          },
        ],
        buchungEinnahmenList: [
          {
            bezeichnung: '2023/12',
            betragNetto: '95,62',
          },
          {
            bezeichnung: '2023/11',
            betragNetto: '95,62',
          },
          {
            bezeichnung: '2023/10',
            betragNetto: '95,62',
          },
          {
            bezeichnung: '2023/9',
            betragNetto: '95,62',
          },
          {
            bezeichnung: '2023/8',
            betragNetto: '95,62',
          },
        ],
        buchungUstVomAufwandList: [],
        summeNettoExpenseReducingText: 'Zwischensumme Aufwandsmindernd',
        summeNettoExpenseReducing: '60,80',
      },
    ],
  },
};
