import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import AttachmentUpload from './Upload/AttachmentUpload';
import AttachmentPartUploadView from './Upload/AttachmentPartUploadView';
import { useInfoMailDeliveryAttachmentListQuery } from '../../gql/InfoMailDeliveryWizardQueries.types';
import { InfoMailDelivery } from '../../../../../types';

type Props = {
  wizardId: string;
  isCompleted?: boolean;
  selectedRow: InfoMailDelivery;
};

const AttachmentPart: FC<Props> = ({ selectedRow, wizardId, isCompleted }) => {
  const { data, refetch } = useInfoMailDeliveryAttachmentListQuery({
    variables: {
      infoMailDeliveryWizardId: wizardId,
      infoMailDeliveryId: selectedRow.infoMailDeliveryId,
    },
  });

  const attachmentList = data?.getInfoMailDeliveryAttachmentList.data ?? [];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Text>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        Anhänge von {selectedRow.objektTopVertrag.name} - {selectedRow.vertragspartner.kurzBezeichnung} ({selectedRow.emailAddress})
      </Typography.Text>
      <Space direction="vertical" style={{ width: '40%' }}>
        <AttachmentUpload
          key={selectedRow.infoMailDeliveryId}
          disabled={isCompleted}
          attachmentList={attachmentList}
          onAction={refetch}
          wizardId={wizardId}
          infoMailDeliveryId={selectedRow.infoMailDeliveryId}
        />
        <AttachmentPartUploadView
          isCompleted={isCompleted}
          attachmentList={attachmentList}
          onAction={refetch}
          infoMailDeliveryWizardId={wizardId}
          infoMailDeliveryId={selectedRow.infoMailDeliveryId}
        />
      </Space>
    </Space>
  );
};

export default AttachmentPart;
