import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { isNotNull } from '../../../../../../../helpers/assertionHelper';
import { FibuBuchungszeile, Konto } from '../../../../../../../types';
import { getUniqueList } from '../../../../../../shared/getUniqueList';
import TextWithTag from '../../../../../../../components/Text/TextWithTag';
import { selectFilterOption } from '../../../../../../../helpers/selectHelper';

type Props = {
  name: string;
  buchungszeileList: FibuBuchungszeile[];
  getItemForSelect: (data: FibuBuchungszeile) => any;
} & SelectProps;

const BzKontoSelect: FC<Props> = ({ name, buchungszeileList, getItemForSelect, ...restProps }) => {
  const uniqueList: Konto[] = getUniqueList(buchungszeileList.map(getItemForSelect).filter(isNotNull), (a, b) => a.kontoId === b.kontoId);

  return (
    <Select name={name} size="small" allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {uniqueList.map((konto) => (
        <Select.Option key={konto.kontoId} value={konto.kontoId}>
          <TextWithTag textContent={konto.nummer} tagContent={konto.bezeichnung} maxTextLength={25} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default BzKontoSelect;
