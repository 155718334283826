import React, { FC } from 'react';
import { Drawer, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, DownOutlined, StockOutlined, TableOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ArchiveIcon } from '@radix-ui/react-icons';
import {
  useArchiveFirmendatenIndexSeriesMutation,
  useDeleteFirmendatenIndexSeriesMutation,
  useUnarchiveFirmendatenIndexSeriesMutation,
} from '../../gql/FirmendatenIndexSeriesMutations.types';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { IndexSeries } from '../../../../../types';
import { useToggle } from '../../../../../hooks/useToggle';
import IndexSeriesForm from '../../Form/IndexSeriesForm';
import { generatePathToFirmendatenIndexSeriesDetailsPage } from '../../firmendatenIndexSeriesUriPaths';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import { isStatusArchived } from '../../../../../helpers/statusHelper';

type Props = {
  indexSeries: IndexSeries;
  onAction: () => void;
};

const IndexSeriesTableActions: FC<Props> = ({ indexSeries, onAction }) => {
  const navigate = useNavigate();
  const [isIndexSeriesDrawerOpen, toggleIndexSeriesDrawer] = useToggle();
  const entity = 'Indexreihe';

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isArchived = isStatusArchived(indexSeries.status!);

  const [runDelete] = useDeleteFirmendatenIndexSeriesMutation({
    variables: {
      indexSeriesId: indexSeries.indexSeriesId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveFirmendatenIndexSeriesMutation({
    variables: {
      indexSeriesId: indexSeries.indexSeriesId,
    },
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveFirmendatenIndexSeriesMutation({
    variables: {
      indexSeriesId: indexSeries.indexSeriesId,
    },
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Indexreihe anzeigen',
      onClick: () => navigate(generatePathToFirmendatenIndexSeriesDetailsPage(indexSeries.indexSeriesId)),
      icon: <TableOutlined />,
    },
    indexSeries.firmendatenId
      ? {
          key: '2',
          label: 'Indexreihe bearbeiten',
          onClick: toggleIndexSeriesDrawer,
          icon: <StockOutlined />,
        }
      : null,
    {
      key: '3',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(runUnarchive, indexSeries) : showConfirmArchive(runArchive, indexSeries),
      icon: <ArchiveIcon style={radixActionStyles} />,
    },
    indexSeries.firmendatenId
      ? {
          key: '4',
          label: 'Löschen',
          onClick: showConfirmDelete(runDelete, indexSeries),
          icon: <DeleteOutlined />,
          disabled: !indexSeries.deletable,
          danger: true,
        }
      : null,
  ];

  const onActionSuccess = () => {
    toggleIndexSeriesDrawer();
    onAction();
  };

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <Drawer title="Indexreihe bearbeiten" width={720} open={isIndexSeriesDrawerOpen} onClose={toggleIndexSeriesDrawer} destroyOnClose>
        <IndexSeriesForm indexSeries={indexSeries} onAction={onActionSuccess} onCancel={toggleIndexSeriesDrawer} />
      </Drawer>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, indexSeries: IndexSeries) => () => {
  Modal.confirm({
    title: `Möchten Sie die Indexreihe löschen?`,
    content: `${indexSeries.name}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmArchive = (runArchive: () => void, indexSeries: IndexSeries) => () => {
  Modal.confirm({
    title: `Möchten Sie die Indexreihe archivieren?`,
    content: `${indexSeries.name}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (runUnarchive: () => void, indexSeries: IndexSeries) => () => {
  Modal.confirm({
    title: `Möchten Sie die Indexreihe reaktivieren?`,
    content: `${indexSeries.name}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

export default IndexSeriesTableActions;
