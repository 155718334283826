import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useAbrechnungsdefinitionTemplateQuery } from '../../../../../../features/Abrechnungsdefinition/gql/AbrDefQueries.types';
import { AbrechnungsdefinitionTemplateType } from '../../../../../../types';
import {
  findPrecedingVersionId,
  hasPrecedingVersion,
  isPrecedingVersionAvailable,
} from '../../../../../SysSettingsPage/KundenSystem/AbrechnungsdefinitionPage/Version/Create/createPageHelpers';
import {
  useHeAbrechnungsdefinitionTemplateVersionListQuery,
  useHeAbrechnungsdefinitionTemplateVersionQuery,
} from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/HeAbrDef/HeAbrDefTemplateVersionQueries.types';
import HeAbrDefTemplateVersionCreateForm from '../../../../../../features/AbrechnungsdefinitionTemplate/Version/Form/Create/HeAbrDefTemplateVersionCreateForm';

/*
When creating new version the forms have to be prefilled with the values of preceding version for the next available date from today
ex. 1: today: 01.01.2022., version 1: 01.01.2021. version 2: 01.01.2023 => preceding version: version 1
ex. 2: today: 01.01.2022., version 1: 01.01.2021. version 2: 02.01.2022 => preceding version: version 2
 */

type Props = {
  abrechnungsdefinitionId: string;
};
const AndromedaSysSettingsHeAbrechnungsdefinitionVersionCreatePage: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data: heAbrDefVersionListData } = useHeAbrechnungsdefinitionTemplateVersionListQuery({
    variables: { abrechnungsdefinitionId },
  });
  const versionList = heAbrDefVersionListData?.getHeAbrechnungsdefinitionTemplateVersionList.data;
  const precedingVersionId = versionList ? findPrecedingVersionId(versionList) : undefined;

  const { data: heAbrDefVersionData } = useHeAbrechnungsdefinitionTemplateVersionQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId: precedingVersionId! },
    skip: !precedingVersionId,
  });
  const precedingVersion = heAbrDefVersionData?.getHeAbrechnungsdefinitionTemplateVersion.data;

  const { data: templateHeData } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.ObjektAbrechnung },
    skip: isPrecedingVersionAvailable(versionList, precedingVersion),
  });
  const heAbrechnungTextbausteinList = templateHeData?.getAbrechnungsdefinitionTemplate.data.textbausteinList;

  // Show loading state until initial data is available: if there is a precedingVersion we get the textbausteinLists from it otherwise we have to load default templates
  if (
    !versionList ||
    (hasPrecedingVersion(versionList) && !precedingVersion) ||
    (hasPrecedingVersion(versionList) === false && !heAbrechnungTextbausteinList)
  ) {
    return <Skeleton active />;
  }

  return (
    <HeAbrDefTemplateVersionCreateForm
      abrechnungsdefinitionVersionList={versionList}
      precedingAbrechnungsdefinitionVersion={precedingVersion}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      heAbrechnungTextbausteinList={precedingVersion?.heAbrechnungTextbausteinList ?? heAbrechnungTextbausteinList!}
    />
  );
};

export default AndromedaSysSettingsHeAbrechnungsdefinitionVersionCreatePage;
