import React, { FC } from 'react';
import { Drawer, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, DownOutlined, StockOutlined, TableOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useToggle } from '../../../../hooks/useToggle';
import IndexSeriesForm from '../../Form/IndexSeriesForm';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { IndexSeries } from '../../../../types';
import { useDeleteIndexSeriesMutation } from '../../gql/IndexSeriesMutations.types';
import { generatePathToAndromedaSystemSettingsIndexSeriesDetailPage } from '../../indexSeriesUriPaths';

type Props = {
  indexSeries: IndexSeries;
  onAction: () => void;
};

const IndexSeriesTableActions: FC<Props> = ({ indexSeries, onAction }) => {
  const navigate = useNavigate();
  const [isIndexSeriesDrawerOpen, toggleIndexSeriesDrawer] = useToggle();
  const entity = 'Indexreihe';

  const [runDelete] = useDeleteIndexSeriesMutation({
    variables: {
      indexSeriesId: indexSeries.indexSeriesId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Indexreihe anzeigen',
      onClick: () => navigate(generatePathToAndromedaSystemSettingsIndexSeriesDetailPage(indexSeries.indexSeriesId)),
      icon: <TableOutlined />,
    },
    { key: '2', label: 'Indexreihe bearbeiten', onClick: toggleIndexSeriesDrawer, icon: <StockOutlined /> },
    {
      key: '3',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, indexSeries),
      icon: <DeleteOutlined />,
      disabled: !indexSeries.deletable,
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <Drawer title="Indexreihe bearbeiten" width={720} open={isIndexSeriesDrawerOpen} onClose={toggleIndexSeriesDrawer} destroyOnClose>
        <IndexSeriesForm indexSeries={indexSeries} onAction={onAction} onCancel={toggleIndexSeriesDrawer} />
      </Drawer>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, indexSeriesDef: IndexSeries) => () => {
  Modal.confirm({
    title: `Möchten Sie die Indexreihe löschen?`,
    content: `${indexSeriesDef.name}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default IndexSeriesTableActions;
