import { FormFields } from '../../../../../../helpers/formikHelper';
import { ContactType } from '../../../../../../shared/components/Contact/contactHelper';

export type EmailContactCreateFormValues = {
  type: ContactType.EMAIL;
  wertList: string[];
};

export const emailContactCreateFormFields: FormFields<EmailContactCreateFormValues> = {
  type: 'type',
  wertList: 'wertList',
};

export const emailContactCreateFormInitialValues: EmailContactCreateFormValues = {
  type: ContactType.EMAIL,
  wertList: [''],
};
