import React from 'react';
import { TableColumnProps, Typography } from 'antd';
import { Percent } from '../../../../../components/Number';
import { UstKategorieEntry } from '../../../../../types';

export const ustKategorieEntryVersionTableColumnsRead = (): TableColumnProps<UstKategorieEntry>[] => [
  {
    title: 'USt-Kennzeichen',
    dataIndex: ['ustKz', 'bezeichnung'],
  },
  {
    title: 'Kennzeichen für Rechnung',
    dataIndex: 'rechnungskennzeichen',
  },
  {
    title: 'USt %',
    dataIndex: 'steuersatz',
    render: (text) => <Percent value={text / 100} />,
  },
  {
    title: 'Addition Konto',
    dataIndex: 'additionKonto',
  },
  {
    title: 'Buchungskennzeichen',
    dataIndex: 'buchungsKZ',
    render: (text) => <Typography.Text>{text}</Typography.Text>,
  },
];
