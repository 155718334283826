import React from 'react';
import { RcFile, UploadProps } from 'antd/lib/upload/interface';
import { Button, List, message, Upload } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Box, Flex } from 'rebass';
import Fetcher from '../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../helpers/APIUtils';
import { StyledDragger } from '../Styled/Document.style';
import DocumentPreviewCard from './DocumentPreviewCard';
import { mapErrorToErrorMessage } from '../../../helpers/errorAndWarningHelper';
import { useAuth } from '../../../shared/Auth/authContext';
import { useFileInfoListForMitarbeiterQuery } from '../gql/FileStorageQueries.types';
import { FileInfo } from '../../../types';

// FIXME ISSUE: https://github.com/ant-design/ant-design/issues/28713
export type RcCustomRequestOptions<T = any> = Parameters<Exclude<UploadProps<T>['customRequest'], undefined>>[0];

const DocumentUpload = () => {
  const { mitarbeiter } = useAuth();
  const mitarbeiterId = mitarbeiter?.mitarbeiterId;

  const {
    loading: loadingFileList,
    data: maybeFile,
    refetch,
  } = useFileInfoListForMitarbeiterQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      mitarbeiterId: mitarbeiterId!,
    },
    skip: !mitarbeiterId,
  });

  const fileDataForMitarbeiter = maybeFile?.getFileInfoListForMitarbeiter.data;
  const contentList = fileDataForMitarbeiter?.contentList ?? [];

  const handleUpload = (options: RcCustomRequestOptions) => {
    const formData = new FormData();
    const jsonData = { data: { type: 'UNKNOWN' } };
    formData.append(
      'fileInfo',
      new Blob([JSON.stringify(jsonData)], { type: 'application/json' }),
      // @ts-ignore
      options.file.name
    );

    formData.append(
      'payload',
      options.file as RcFile,
      // @ts-ignore
      options.file.name
    );

    // @ts-ignore
    const msgKey = `msg-upload-${options.file.name}`;
    const MSG_LOADING_DURATION = 120; // message about loading will be removed after response comes so 120 sec should be long enough to get the response
    message.loading({
      // @ts-ignore
      content: `${options.file.name} wird hochgeladen`,
      key: msgKey,
      duration: MSG_LOADING_DURATION,
    });
    Fetcher.getInstance()
      .fetch(`/api/mitarbeiter/${mitarbeiterId}/files`, {
        method: 'POST',
        body: formData,
      })
      .then((response) => {
        // FIXME ISSUE: https://github.com/ant-design/ant-design/issues/28713
        // @ts-ignore
        options.onSuccess(response, options.file);
        message.success({
          // @ts-ignore
          content: `${options.file.name} wurde erfolgreich hochgeladen`,
          duration: 10,
          key: msgKey,
        });
        refetch().then((res) => res.data);
        return processResponse(response);
      })
      .catch((error) => {
        // FIXME ISSUE: https://github.com/ant-design/ant-design/issues/28713
        // @ts-ignore
        options.onError(error);
        return processAndReturnError(error);
      })
      .catch((error) => {
        message.error({
          // @ts-ignore
          content: `${options.file.name} konnte nicht hochgeladen werden: ${mapErrorToErrorMessage(error)}`,
          key: msgKey,
        });
      });
  };

  const uploadProps = {
    multiple: true,
    showUploadList: false,
    customRequest: handleUpload,
    beforeUpload: (file: RcFile) => {
      const hasNoType = !file.type;
      if (hasNoType) {
        message.error(`${file.name} hat keinen Dateityp. Ordner dürfen nicht hochgeladen werden.`);
      }
      const fileSizeInMB = file.size / 1024 / 1024;
      const isTooLarge = fileSizeInMB > 20;
      if (isTooLarge) {
        message.error(`${file.name} ist zu groß. Dateien müssen kleiner als 20 MB sein.`);
      }
      return !hasNoType && !isTooLarge;
    },
  };

  return contentList.length === 0 && !loadingFileList ? (
    <Flex>
      <Box pt="8px" pb={24} px={24} width={[1]}>
        <Upload.Dragger {...uploadProps} openFileDialogOnClick>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Dateien hinzufügen</p>
          <p className="ant-upload-hint">Eine oder mehrere Dateien hochladen</p>
        </Upload.Dragger>
      </Box>
    </Flex>
  ) : (
    <>
      <Flex pb={16} pr={24}>
        <Box mx="auto" />
        <Upload {...uploadProps}>
          <Button>
            <UploadOutlined /> Datei hochladen
          </Button>
        </Upload>
      </Flex>
      <Flex>
        <Box pb={24} width={[1]}>
          <StyledDragger {...uploadProps} openFileDialogOnClick={false}>
            <List<FileInfo>
              style={{ paddingLeft: '24px', paddingRight: '24px' }}
              loading={loadingFileList}
              grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 6, xxl: 4 }}
              dataSource={contentList}
              renderItem={(file) => (
                <List.Item>
                  <DocumentPreviewCard onActionSuccess={refetch} file={file} />
                </List.Item>
              )}
            />
          </StyledDragger>
        </Box>
      </Flex>
    </>
  );
};

export default DocumentUpload;
