import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { BookingInstructionFormValues } from '../../bookingInstructionFormMapper';
import { useRechtstraegerAddressesAndContactsQuery } from '../../../../Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsQueries.types';
import { useRechtstraegerTypeQuery } from '../../../../Rechtstraeger/gql/RechtsTraegerQueries.types';
import ContactEmailSelect from '../../../../Rechtstraeger/AddressesAndContacts/Form/shared/ContactEmailSelect';

type Props = {
  name: string;
  formikProps: FormikProps<BookingInstructionFormValues>;
  rechtstraegerId: string;
};

const BookingInstructionContactEmailSelect: FC<Props> = ({ name, formikProps, rechtstraegerId }) => {
  const {
    data,
    loading: loadingContactList,
    refetch: refetchContactList,
  } = useRechtstraegerAddressesAndContactsQuery({
    variables: {
      rechtstraegerId,
    },
  });

  const contactData = data?.getRechtstraegerAddressesAndContacts.data;
  const emailListForSelect = contactData ? [contactData.hauptEmailContact, ...contactData.emailContactList] : [];

  const { data: rechtstreagerTypeData, loading: loadingRechtstraegerType } = useRechtstraegerTypeQuery({
    variables: { rechtstraegerId },
  });

  const rechtstraegerType = rechtstreagerTypeData?.getRechtstraegerType.data.value;

  return (
    <ContactEmailSelect
      disabled={!rechtstraegerId}
      emailList={emailListForSelect}
      name={name}
      loading={loadingContactList || loadingRechtstraegerType}
      onNewItemCreated={(emailContactId) => refetchContactList().then(() => formikProps.setFieldValue(name, emailContactId))}
      onNewItemCreateSelected={() => formikProps.setFieldValue(name, undefined)}
      rechtstraegerType={rechtstraegerType}
      rechtstraegerId={rechtstraegerId}
    />
  );
};

export default BookingInstructionContactEmailSelect;
