import React, { FC, useState } from 'react';
import { List, Typography } from 'antd';
import HeVertragButtonsAndDrawer from './HeVertragButtonsAndDrawer';
import { useHeVertragListQuery } from '../gql/HeVertragQueries.types';
import HeVertragTable from './Table/HeVertragTable';
import { useToggle } from '../../../../hooks/useToggle';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { ErrorData, HeVertragListEntry } from '../../../../types';

type Props = {
  objektId: string;
  objektVerwaltungSeit: string;
};

const HeVertragListing: FC<Props> = ({ objektId, objektVerwaltungSeit }) => {
  const [selectedVertragspartnerId, setSelectedVertragspartnerId] = useState<string>();
  const [showPastContract, togglePastContract] = useToggle(false);

  const { data, loading, refetch } = useHeVertragListQuery({ variables: { objektId } });

  const heVertragList = data?.getHeVertragList.data.heVertragList ?? [];
  const heVertragErrorList = data?.getHeVertragList.data.errorList;

  const filterByVertragspartnerId = (vertrag: HeVertragListEntry) =>
    selectedVertragspartnerId ? vertrag.vertragspartner.rechtstraegerId === selectedVertragspartnerId : true;

  const vertragspartnerList = heVertragList.map((vertrag) => ({
    rechtstraegerId: vertrag.vertragspartner.rechtstraegerId,
    kurzBezeichnung: vertrag.vertragspartner.kurzBezeichnung,
  }));

  const allContractList = heVertragList
    .filter((vertrag) => {
      if (!showPastContract && vertrag.vertragsEnde) {
        return !isInactiveContract(vertrag.vertragsEnde);
      }
      return vertrag;
    })
    .filter(filterByVertragspartnerId);

  return (
    <>
      <HeVertragButtonsAndDrawer
        showPastContract={showPastContract}
        togglePastContract={togglePastContract}
        objektId={objektId}
        onSuccess={refetch}
        selectedVertragspartnerId={selectedVertragspartnerId}
        setSelectedVertragspartnerId={setSelectedVertragspartnerId}
        objektVerwaltungSeit={objektVerwaltungSeit}
        vertragspartnerList={vertragspartnerList}
      />
      {heVertragErrorList?.length !== 0 && (
        <List<ErrorData>
          header={<Typography.Text>Fehlende Verträge nach Gesamtanteil:</Typography.Text>}
          dataSource={heVertragErrorList}
          renderItem={(item) => (
            <List.Item>
              <Typography.Text type="danger" key={item.message}>
                {item.message}
              </Typography.Text>
            </List.Item>
          )}
        />
      )}
      <HeVertragTable loading={loading} heVertragList={allContractList} objektId={objektId} />
    </>
  );
};

export const isInactiveContract = (date: string) => dayjsCustom(dayjsCustom(date)).isBefore(dayjsCustom());

export default HeVertragListing;
