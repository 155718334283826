import { AbrechnungsdefinitionVersionListData } from './abrDefTypes';

export const sortVersionsDescendingByValidFrom = (versionList: AbrechnungsdefinitionVersionListData[]) => {
  return [...versionList].sort(compareVersionsForDescendingSortByValidFrom);
};

const compareVersionsForDescendingSortByValidFrom = (a: AbrechnungsdefinitionVersionListData, b: AbrechnungsdefinitionVersionListData) => {
  if (a.validFrom < b.validFrom) {
    return 1;
  }
  if (a.validFrom > b.validFrom) {
    return -1;
  }
  return 0;
};
