import React, { FC } from 'react';
import { Col, Divider, Row } from 'antd';
import { DatePicker, Form, Radio } from 'formik-antd';
import { Formik } from 'formik';
import { IncomingInvoiceFormValues } from '../../incomingInvoiceFormMapper';
import { stornoAbgrenzungFields, stornoDirektFields, stornoFormFields, stornoFormInitialValues, StornoFormValues } from './stornoFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../components/DatePicker/DatePicker_formikAntD';
import { stornoFormValidationSchema } from './stornoFormValidationSchema';
import FormButtons from '../../../../../components/Button/FormButtons';
import BookingPreview from '../../Preview/StornoBookingPreview/SingleInvoice/BookingPreview';

type Props = {
  onSubmit: (values: StornoFormValues) => void;
  loading: boolean;
  onCancel: () => void;
  incomingInvoiceFormValues: IncomingInvoiceFormValues;
  belegId: string;
};

const StornoForm: FC<Props> = ({ onSubmit, loading, onCancel, belegId, incomingInvoiceFormValues }) => {
  return (
    <Formik<StornoFormValues>
      initialValues={stornoFormInitialValues(incomingInvoiceFormValues)}
      validationSchema={stornoFormValidationSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(formikProps) => {
        return (
          <Form layout="vertical" colon={false}>
            <Row>
              <Col style={{ width: '100%' }}>
                <Radio.Group name={stornoFormFields.abgrenzungsBuchung} buttonStyle="solid" block>
                  <Radio.Button value={false}>Korrektur direkt</Radio.Button>
                  <Radio.Button value>Korrektur mit Abgrenzung</Radio.Button>
                </Radio.Group>
                {!formikProps.values.abgrenzungsBuchung ? (
                  <>
                    <Row style={{ marginTop: 10 }}>
                      <Col style={{ width: '100%' }}>
                        <FormItemWithoutColon
                          name={`${stornoFormFields.stornoDirekt}.${stornoDirektFields.stornoBuchungsdatum}`}
                          label="Storno - Buchungsdatum"
                        >
                          <DatePicker
                            size="middle"
                            id={`${stornoFormFields.stornoDirekt}.${stornoDirektFields.stornoBuchungsdatum}`}
                            name={`${stornoFormFields.stornoDirekt}.${stornoDirektFields.stornoBuchungsdatum}`}
                            style={{ width: 'calc(min(50%, 236px) - 8px)' }}
                            format={DATE_DISPLAY_FORMAT_DEFAULT}
                          />
                        </FormItemWithoutColon>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ width: '100%' }}>
                        <FormItemWithoutColon
                          name={`${stornoFormFields.stornoDirekt}.${stornoDirektFields.korrekturBuchungsdatum}`}
                          label="Korrektur - Buchungsdatum"
                        >
                          <DatePicker
                            size="middle"
                            id={`${stornoFormFields.stornoDirekt}.${stornoDirektFields.korrekturBuchungsdatum}`}
                            name={`${stornoFormFields.stornoDirekt}.${stornoDirektFields.korrekturBuchungsdatum}`}
                            style={{ width: 'calc(min(50%, 236px) - 8px)' }}
                            format={DATE_DISPLAY_FORMAT_DEFAULT}
                          />
                        </FormItemWithoutColon>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Divider>Storno</Divider>
                    <Row style={{ marginTop: 10 }}>
                      <Col style={{ width: '100%' }}>
                        <FormItemWithoutColon
                          name={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoBuchungsdatum}`}
                          label="Stornobuchung mit Aufwand Abgrenzung - Buchungsdatum"
                        >
                          <DatePicker
                            size="middle"
                            id={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoBuchungsdatum}`}
                            name={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoBuchungsdatum}`}
                            style={{ width: 'calc(min(50%, 236px) - 8px)' }}
                            format={DATE_DISPLAY_FORMAT_DEFAULT}
                          />
                        </FormItemWithoutColon>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 10 }}>
                      <Col style={{ width: '100%' }}>
                        <FormItemWithoutColon
                          name={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoAbgrenzungsBuchungsdatum}`}
                          label="Aufwand Stornobuchung der Aufwand Abgrenzung - Buchungsdatum"
                        >
                          <DatePicker
                            size="middle"
                            id={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoAbgrenzungsBuchungsdatum}`}
                            name={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoAbgrenzungsBuchungsdatum}`}
                            style={{ width: 'calc(min(50%, 236px) - 8px)' }}
                            format={DATE_DISPLAY_FORMAT_DEFAULT}
                          />
                        </FormItemWithoutColon>
                      </Col>
                    </Row>

                    <Divider>Korrektur</Divider>
                    <Row style={{ marginTop: 10 }}>
                      <Col style={{ width: '100%' }}>
                        <FormItemWithoutColon
                          name={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturBuchungsdatum}`}
                          label="Korrekturbuchung mit Aufwand Abgrenzung - Buchungsdatum"
                        >
                          <DatePicker
                            size="middle"
                            id={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturBuchungsdatum}`}
                            name={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturBuchungsdatum}`}
                            style={{ width: 'calc(min(50%, 236px) - 8px)' }}
                            format={DATE_DISPLAY_FORMAT_DEFAULT}
                          />
                        </FormItemWithoutColon>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 10 }}>
                      <Col style={{ width: '100%' }}>
                        <FormItemWithoutColon
                          name={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturAbgrenzungsBuchungsdatum}`}
                          label="Aufwand Korrekturbuchung der Aufwand Abgrenzung - Buchungsdatum"
                        >
                          <DatePicker
                            size="middle"
                            id={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturAbgrenzungsBuchungsdatum}`}
                            name={`${stornoFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturAbgrenzungsBuchungsdatum}`}
                            style={{ width: 'calc(min(50%, 236px) - 8px)' }}
                            format={DATE_DISPLAY_FORMAT_DEFAULT}
                          />
                        </FormItemWithoutColon>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <BookingPreview
              belegId={belegId}
              incomingInvoiceFormValues={incomingInvoiceFormValues}
              stornoFormValues={formikProps.values}
              isSubmitting={formikProps.isSubmitting}
            />
            <FormButtons okText="Speichern" onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} updateMode={false} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default StornoForm;
