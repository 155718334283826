import { FC } from 'react';
import { FormikProps } from 'formik';
import { VstKuerzungVerarbeitungEntryFiltersFormValues, vstKuerzungVerarbEntryListingFiltersFormFields } from './ListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FiltersWithMax3Fields from '../../../../../components/Filters/FiltersWithMax3Fields';
import VstKuerzungBuchungStatusSelect from './VstKuerzungBuchungStatusSelect';
import VerarbeitungObjektFormSelect, { VerarbeitungObjektFormSelectProps } from '../../../shared/Filters/VerarbeitungObjektFormSelect';
import { getUniqueList } from '../../../../shared/getUniqueList';
import { VorsteuerkuerzungGenerierlaufEntry } from '../../../../../types';

type ListingFiltersProps = {
  formikProps: FormikProps<VstKuerzungVerarbeitungEntryFiltersFormValues>;
  entryList: VorsteuerkuerzungGenerierlaufEntry[];
};

const ListingFilters: FC<ListingFiltersProps> = ({ formikProps, entryList }) => {
  const objektList: VerarbeitungObjektFormSelectProps[] = entryList.map((entry) => ({
    objektId: entry.objekt.objektId,
    kurzBezeichnung: entry.objekt.kurzBezeichnung,
  }));

  const uniqueObjektList = getUniqueList(objektList, (a, b) => a.objektId === b.objektId);

  return (
    <FiltersWithMax3Fields
      hideTitle
      filters={
        <>
          <FormItemWithoutColon name={vstKuerzungVerarbEntryListingFiltersFormFields.objektId} label="Objekt">
            <VerarbeitungObjektFormSelect
              name={vstKuerzungVerarbEntryListingFiltersFormFields.objektId}
              onChange={formikProps.submitForm}
              objektList={uniqueObjektList}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={vstKuerzungVerarbEntryListingFiltersFormFields.buchungStatus} label="Vorsteuerkürzung-Status">
            <VstKuerzungBuchungStatusSelect
              name={vstKuerzungVerarbEntryListingFiltersFormFields.buchungStatus}
              entryList={entryList}
              placeholder="Status auswählen"
              formikProps={formikProps}
              width="200px"
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
