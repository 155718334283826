import React, { FC } from 'react';
import { Select, Typography } from 'antd';
import { isNotNil } from '../../../helpers/assertionHelper';
import { StyledFiltersSelectSpace } from '../../../components/Filters/styled/Filters.style';
import { AbrechnungsdefinitionListEntry } from '../../../types';

type Props = {
  abrechnungsdefinitionList?: AbrechnungsdefinitionListEntry[];
  selectedAbrechnungsdefinitionVersionIdList: string[];
  setSelectedAbrechnungsDefinitionVersionIdList: React.Dispatch<React.SetStateAction<string[]>>;
};

const AbrKontenzuordnungColumnSelect: FC<Props> = ({
  abrechnungsdefinitionList,
  selectedAbrechnungsdefinitionVersionIdList,
  setSelectedAbrechnungsDefinitionVersionIdList,
}) => {
  const abrechnungsdefinitionWithCurrentVersionList = abrechnungsdefinitionList
    ? abrechnungsdefinitionList.filter((abrechDef) => isNotNil(abrechDef.abrechnungsdefinitionVersion?.abrechnungsdefinitionVersionId))
    : [];

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <StyledFiltersSelectSpace direction="vertical">
        <Typography.Text strong>Spalten</Typography.Text>
        <Select
          mode="multiple"
          allowClear
          placeholder="Filtern Sie nach Abrechnungsdefinitionen"
          style={{ width: '100%' }}
          value={selectedAbrechnungsdefinitionVersionIdList}
          onChange={setSelectedAbrechnungsDefinitionVersionIdList}
        >
          {abrechnungsdefinitionWithCurrentVersionList.map((abrechDef) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Select.Option key={abrechDef.abrechnungsdefinitionId} value={abrechDef.abrechnungsdefinitionVersion!.abrechnungsdefinitionVersionId}>
              {abrechDef.bezeichnung}
            </Select.Option>
          ))}
        </Select>
      </StyledFiltersSelectSpace>
    </div>
  );
};
export default AbrKontenzuordnungColumnSelect;
