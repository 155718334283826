import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFilterPaymentClearingBuchungskreisListQuery } from '../../gql/PaymentClearingQueries.types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'loading' | 'allowClear' | 'showSearch' | 'filterOption'>;

const PaymentClearingBuchungskreisListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useFilterPaymentClearingBuchungskreisListQuery();

  const list = data?.getFilterPaymentClearingBuchungskreisList.data ?? [];

  return (
    <Select
      size="small"
      placeholder="Buchungskreis auswählen"
      loading={loading}
      name={name}
      id={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      {...restProps}
    >
      {list.map((buchungskreis) => (
        <Select.Option key={buchungskreis.buchungskreisId} value={buchungskreis.buchungskreisId}>
          <DataWithShortenedText maxTextLength={40} text={buchungskreis.rechtstraeger.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaymentClearingBuchungskreisListFormSelect;
