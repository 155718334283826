import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { InboxOutlined } from '@ant-design/icons';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { EMAIL_SUPPORTED_FORMATS } from '../../../../helpers/fileHelper';
import { TicketEmailFileData, TicketEmailsFormValues, TicketUnifiedEmail } from './ticketEmailsFormMapper';

type Props = {
  name: string;
  formikProps: FormikProps<TicketEmailsFormValues>;
  emailList: TicketUnifiedEmail[];
};

const TicketEmailFileUpload: FC<Props> = ({ formikProps, emailList, name }) => {
  return (
    <Upload.Dragger
      beforeUpload={(file, fileList) => {
        let files: TicketEmailFileData[] = [];
        fileList.forEach((file) => {
          if (validateFile(file, fileList, emailList, formikProps, name)) {
            const data = {
              filename: file.name,
              file,
              isUpload: true,
              ts: dayjsCustom().format(),
            };
            files = [...files, data];
          }

          files.length > 0 && formikProps.setFieldValue(name, [...formikProps.values.newEmailList, ...files]);
        });
        return false;
      }}
      multiple
      showUploadList={false}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Dateien hinzufügen</p>
      <p className="ant-upload-hint">Eine oder mehrere Dateien hochladen</p>
    </Upload.Dragger>
  );
};

function validateFile<T>(
  currentFile: RcFile,
  currentFileList: RcFile[],
  emailList: TicketUnifiedEmail[],
  formikProps: FormikProps<T>,
  name: string
): boolean {
  const hasNoType = !currentFile.type;
  if (hasNoType) {
    formikProps.setFieldError(name, `${currentFile.name} hat keinen Dateityp. Ordner dürfen nicht hochgeladen werden.`);
    return false;
  }

  if (!EMAIL_SUPPORTED_FORMATS.includes(currentFile.type)) {
    formikProps.setFieldError(
      name,
      `Das Format der Beleg Datei ${currentFile.type} wird nicht unterstützt, unterstützte Formate sind: ${EMAIL_SUPPORTED_FORMATS}`
    );
    return false;
  }

  const maxSizeMB = 20;
  const maxSizeBytes = maxSizeMB * 1024 * 1024;
  const totalSize = currentFileList.reduce((prev, current) => prev + (current?.size || 0), 0);
  const isTooLarge = totalSize > maxSizeBytes;

  if (isTooLarge) {
    formikProps.setFieldError(name, `Dateien müssen kleiner als ${maxSizeMB} MB sein.`);
    return false;
  }

  return true;
}

export default TicketEmailFileUpload;
