import React, { FC } from 'react';
import { Tag, TagProps, Tooltip } from 'antd';
import { PresetColorType } from 'antd/lib/_util/colors';
import { hasStatusDescription, IStatus, StatusEnum, TCollectedStatus } from '../../../helpers/statusHelper';

type Props = {
  documentStatus: IStatus;
} & TagProps;

const BudgetingStatusTag: FC<Props> = ({ documentStatus, ...restProps }) => {
  const tag = (
    <Tag color={mapBudgetingStatusToTag(documentStatus.value)} {...restProps}>
      {documentStatus.text.toUpperCase()}
    </Tag>
  );

  return hasStatusDescription(documentStatus) ? <Tooltip title={documentStatus.description}>{tag}</Tooltip> : <>{tag}</>;
};

export const mapBudgetingStatusToTag = (status: TCollectedStatus): PresetColorType | '' => {
  switch (status) {
    case StatusEnum.Verarbeitet:
      return 'blue';
    case StatusEnum.Erstellt:
    default:
      return '';
  }
};

export default BudgetingStatusTag;
