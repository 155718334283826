import { FormikProps } from 'formik';
import {
  AuszifferungBlockFormValues,
  auszifferungFormFields,
  AuszifferungFormValues,
  OffenePostenForBooking,
} from '../../../../auszifferungFormMapper';
import {
  filterOutStornoSourceAndAzBelongingToAnotherZahlungFromAuszifferungList,
  getAuszifferungIndex,
  getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex,
  getAuszifferungListFormFieldNameFromPersonenkontoOPListBasedOnIndex,
  getOffenePostenIndexFromMainOPList,
  getOffenePostenIndexFromPersonenkontoOPList,
} from '../../../../auszifferungFormHelpers';

export const removeAuszifferung = (
  bookingZahlungId: string,
  formikProps: FormikProps<AuszifferungBlockFormValues>,
  offenePosten: OffenePostenForBooking,
  record: AuszifferungFormValues
) => {
  // 1. for Booking: find auszifferung index in formikProps.values.offenePostenListFromBooking[offenePostenIndex].auszifferungList
  // 2. check if the offenePosten has any other auszifferung belonging to the booking,
  //        if so, then remove only the auszifferung from the list
  //        otherwise remove the offenePosten from the main OP list
  const opIndexInBookingOPList = getOffenePostenIndexFromMainOPList(formikProps.values, offenePosten);

  const filteredAuszifferungList = filterOutStornoSourceAndAzBelongingToAnotherZahlungFromAuszifferungList(
    bookingZahlungId,
    offenePosten.auszifferungList
  ).filter((auszifferung) => auszifferung.auszifferungId !== record.auszifferungId);

  filteredAuszifferungList.length === 0
    ? formikProps.setFieldValue(
        auszifferungFormFields.offenePostenListFromBooking,
        formikProps.values.offenePostenListFromBooking.toSpliced(opIndexInBookingOPList, 1)
      )
    : removeAuszifferungFromList(
        getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex(opIndexInBookingOPList),
        opIndexInBookingOPList,
        formikProps.values.offenePostenListFromBooking,
        formikProps,
        record
      );

  // 3. for Personenkonto: find offenePosten index in formik.values.offenePostenForPersonenkontoList based on offenePostenId
  // 4. remove this auszifferung from the list in 4. => use .toSpliced(auszifferungIndex, 1)
  const opIndexInPersonenkontoOPList = getOffenePostenIndexFromPersonenkontoOPList(formikProps.values, offenePosten);
  removeAuszifferungFromList(
    getAuszifferungListFormFieldNameFromPersonenkontoOPListBasedOnIndex(opIndexInPersonenkontoOPList),
    opIndexInPersonenkontoOPList,
    formikProps.values.offenePostenForPersonenkontoList,
    formikProps,
    record
  );
};

const removeAuszifferungFromList = (
  fieldName: string,
  opIndex: number,
  opListFromFormik: OffenePostenForBooking[],
  formikProps: FormikProps<AuszifferungBlockFormValues>,
  record: AuszifferungFormValues
) => {
  const auszifferungIndex = getAuszifferungIndex(record, opListFromFormik[opIndex]);

  const auszifferungListFromFormik: AuszifferungFormValues[] = opListFromFormik[opIndex].auszifferungList;
  const newAuszifferungList = auszifferungListFromFormik.toSpliced(auszifferungIndex, 1);

  formikProps.setFieldValue(fieldName, newAuszifferungList);
};
