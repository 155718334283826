import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import OffenePostenPage from './OffenePostenPage';
import { URI_OFFENE_POSTEN_PAGE } from '../../features/OffenePosten/offenePostenUriPaths';

const offenePostenPageRoute = (
  <Route path={URI_OFFENE_POSTEN_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<OffenePostenPage />} />} />
);

export default offenePostenPageRoute;
