import { Avatar, List, Space, Typography } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { TableListEmpty } from '../../components/Helpers/TableListEmpty';
import { pathsToObjektDetailsPage } from './objektUriPaths';
import { toTitleCase } from '../../helpers/nameHelper';
import '../../components/Cards/styles/cardStyles.css';
import { useGetObjektFotoThumbnailOrOriginal } from './useFileDownloadAsyncForObjekt';
import LinkWithStatus from '../../components/Link/LinkWithStatus';
import { isObjektAbgegeben } from './objektHelper';
import { ObjektListEntry } from '../../types';
import CustomFormattedDate from '../../components/FormattedDate/CustomFormattedDate';

type ObjektListProps = {
  objektList?: ObjektListEntry[];
  onClickCreate: () => void;
  loading: boolean;
};

const ObjektList: FC<ObjektListProps> = ({ onClickCreate, loading, objektList }) => (
  <div style={{ height: 596, overflowY: 'scroll', padding: '8px 16px' }}>
    <List
      dataSource={objektList}
      locale={{
        emptyText: <TableListEmpty onClick={onClickCreate} btnTxt="Objekt anlegen" emptyTxt="Keine Objekte vorhanden" />,
      }}
      loading={loading}
      renderItem={(objekt) => (
        <List.Item key={objekt.objektId}>
          <List.Item.Meta
            avatar={
              <Link to={pathsToObjektDetailsPage(objekt.objektId).objektDetails}>
                {objekt.fotoFileId ? (
                  <PhotoAvatar kurzBezeichnung={objekt.kurzBezeichnung} objektId={objekt.objektId} fotoFileId={objekt.fotoFileId} />
                ) : (
                  <Avatar shape="square" size="large" style={{ backgroundColor: '#36CFC9' }}>
                    {toTitleCase(objekt.kurzBezeichnung)}
                  </Avatar>
                )}
              </Link>
            }
            title={
              <Space>
                <LinkWithStatus
                  status={objekt.status}
                  text={objekt.kurzBezeichnung}
                  to={pathsToObjektDetailsPage(objekt.objektId).objektDetails}
                  linkToSelfDetails
                  showBadgeDot
                  badgeOffset={[5, 0]}
                />
                <AbgegebenText verwaltungBis={objekt.verwaltungBis} />
              </Space>
            }
            description={
              <Typography.Text type="secondary">
                erstellt am
                <span style={{ float: 'right' }}>
                  <CustomFormattedDate value={objekt.erstelltAm} />
                </span>
              </Typography.Text>
            }
          />
        </List.Item>
      )}
    />
  </div>
);

type AbgegebenTextProps = {
  verwaltungBis?: string | null;
};

const AbgegebenText: FC<AbgegebenTextProps> = ({ verwaltungBis }) => {
  return isObjektAbgegeben(verwaltungBis) ? <Typography.Text type="secondary">(abgegeben)</Typography.Text> : null;
};

type PhotoAvatarProps = { kurzBezeichnung: string; objektId: string; fotoFileId: string };

const PhotoAvatar: FC<PhotoAvatarProps> = ({ kurzBezeichnung, objektId, fotoFileId }) => {
  const { data } = useGetObjektFotoThumbnailOrOriginal(objektId, fotoFileId);

  return (
    <Avatar src={data} shape="square" size="large" style={{ backgroundColor: '#36CFC9' }}>
      {toTitleCase(kurzBezeichnung)}
    </Avatar>
  );
};

export default ObjektList;
