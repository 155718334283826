import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useBuchungszeileListWithOrWithoutUmbuchungen } from '../shared/BuchungszeileListBlock/useBuchungszeileListWithOrWithoutUmbuchungen';
import { ErrorData } from '../../../../types';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { BookingDetailsPageQueryParams } from '../../bookingDetailsPaths';
import BookingDetailsContent from './BookingDetailsContent';

type Props = {
  belegId?: string | null;
  buchungIdList: string[];
  errorList?: ErrorData[];
  includeDeviations?: boolean;
};

const BookingDetails: FC<Props> = ({ belegId, buchungIdList, errorList, includeDeviations = true }) => {
  const { includeStorno } = useQueryParams<BookingDetailsPageQueryParams>();

  const { buchungszeileList, refetchBuchungszeile } = useBuchungszeileListWithOrWithoutUmbuchungen(buchungIdList, includeStorno);

  /******** render spin if no data is being loaded ********/
  return !buchungszeileList.length ? (
    <Skeleton active />
  ) : (
    <BookingDetailsContent
      belegId={belegId}
      buchungIdList={buchungIdList}
      buchungszeileList={buchungszeileList}
      errorList={errorList}
      includeDeviations={includeDeviations}
      refetchBuchungszeile={refetchBuchungszeile}
    />
  );
};

export default BookingDetails;
