import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { sepaMandatActivateOrUpdateFormFields, SepaMandatActivateOrUpdateFormValues } from './sepaMandatActivateOrUpdateFormMapper';
import { FileInfo } from '../../../../../types';
import FileUploadArea, { ValidateFileProps } from '../../../../../components/FileUpload/FileUploadArea';
import { FILE_CONTENT_TYPE_PDF, IMAGE_SUPPORTED_FORMATS } from '../../../../../helpers/fileHelper';

type Props = {
  formikProps: FormikProps<SepaMandatActivateOrUpdateFormValues>;
};

const SepaMandatFileSelect: FC<Props> = ({ formikProps }) => {
  const onSelectFile = (data: FileInfo) => {
    formikProps.setFieldValue(sepaMandatActivateOrUpdateFormFields.signatureFile, {
      fileId: data.fileId,
      filename: data.name,
      isUpload: false,
    });
  };

  return (
    <FileUploadArea<SepaMandatActivateOrUpdateFormValues>
      name={sepaMandatActivateOrUpdateFormFields.signatureFile}
      formikProps={formikProps}
      onSelectFileSetFieldValue={onSelectFile}
      validateFile={(file) => validateFile(file, formikProps)}
    />
  );
};

const validateFile = (file: ValidateFileProps, formikProps: FormikProps<SepaMandatActivateOrUpdateFormValues>): boolean => {
  const hasNoType = !file.type;
  if (hasNoType) {
    formikProps.setFieldError(
      sepaMandatActivateOrUpdateFormFields.signatureFile,
      `${file.name} hat keinen Dateityp. SEPA Mandat darf nicht hochgeladen werden.`
    );
    return false;
  }

  const allowedFileContentTypes = [FILE_CONTENT_TYPE_PDF, ...IMAGE_SUPPORTED_FORMATS];

  if (!allowedFileContentTypes.includes(file.type)) {
    formikProps.setFieldError(
      sepaMandatActivateOrUpdateFormFields.signatureFile,
      `Das Format der Beleg Datei ${file.type} wird nicht unterstützt, unterstützte Formate sind: ${allowedFileContentTypes}`
    );
    return false;
  }

  const fileSizeInMB = file.size / 1024 / 1024;
  const isTooLarge = fileSizeInMB > 20;
  if (isTooLarge) {
    formikProps.setFieldError(sepaMandatActivateOrUpdateFormFields.signatureFile, `${file.name} ist zu groß. Dateien müssen kleiner als 20 MB sein.`);
    return false;
  }
  return true;
};

export default SepaMandatFileSelect;
