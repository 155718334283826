import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BuchungsanweisungApplyAllDeviationsMutationVariables = Types.Exact<{
  buchungsanweisungId: Types.Scalars['String']['input'];
  buchungsdatum: Types.Scalars['String']['input'];
}>;

export type BuchungsanweisungApplyAllDeviationsMutation = {
  actionBuchungsanweisungApplyAllDeviations: {
    data: { buchungsanweisungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BuchungsanweisungApplyAllDeviationsDocument = gql`
  mutation BuchungsanweisungApplyAllDeviations($buchungsanweisungId: String!, $buchungsdatum: String!) {
    actionBuchungsanweisungApplyAllDeviations(buchungsanweisungId: $buchungsanweisungId, buchungsdatum: $buchungsdatum) {
      data {
        buchungsanweisungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBuchungsanweisungApplyAllDeviationsMutation(
  baseOptions?: Apollo.MutationHookOptions<BuchungsanweisungApplyAllDeviationsMutation, BuchungsanweisungApplyAllDeviationsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuchungsanweisungApplyAllDeviationsMutation, BuchungsanweisungApplyAllDeviationsMutationVariables>(
    BuchungsanweisungApplyAllDeviationsDocument,
    options
  );
}
export type BuchungsanweisungApplyAllDeviationsMutationHookResult = ReturnType<typeof useBuchungsanweisungApplyAllDeviationsMutation>;
export type BuchungsanweisungApplyAllDeviationsMutationResult = Apollo.MutationResult<BuchungsanweisungApplyAllDeviationsMutation>;
export type BuchungsanweisungApplyAllDeviationsMutationOptions = Apollo.BaseMutationOptions<
  BuchungsanweisungApplyAllDeviationsMutation,
  BuchungsanweisungApplyAllDeviationsMutationVariables
>;
