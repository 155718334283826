import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../security/AuthRoute';
import { isAdmin } from '../../../../../security/permissionChecks';
import AndromedaSysSettingsMahndefinitionUpdatePage from './AndromedaSysSettingsMahndefinitionUpdatePage';
import URI_ANDROMEDA_SYS_SETTINGS_MAHNDEFINITION_PAGE from '../../../../../features/MahndefinitionTemplate/mahndefinitonTemplateUriPaths';

const andromedaSysSettingsMahndefinitionUpdatePageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS_MAHNDEFINITION_PAGE.updatePage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsMahndefinitionUpdatePage />} />}
  />
);

export default andromedaSysSettingsMahndefinitionUpdatePageRoute;
