const URI_ANDROMEDA_SYSTEM_SETTINGS = '/dashboard/andromeda-system-settings';

const URI_ANDROMEDA_SYS_SETTINGS = {
  abrechnungKontenZuordnungPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/abrechnungskontenzuordnung/:abrechnungsdefinitionId?/:kontoType?`,
  abrechnungsdefinitionPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/abrechnungsdefinitionen`,
  abrechnungskreisPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/abrechnungskreise`,
  adminPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/admin`,
  aufgabenartPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/aufgabenarten`,
  auftragsartPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/auftragsarten`,
  kundenSystemPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/kundensystem`,
  aufteilungsschluesselPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/aufteilungsschluessel`,
  budgetingVerknuepfungAbrDefVPosPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/budgetierung-verknuepfung-abrdef-vpos`,
  budgetingVerknuepfungVPosAuftSchluesselPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/budgetierung-verknuepfung-vpos-auftschluessel`,
  kontierungstabellePage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/account-distribution`,
  emailDeliveryDefinitionPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/email-delivery-definition`,
  mahndefinitionPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/mahndefinition`,
  vorschreibungspositionPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/vorschreibungspositionen`,
  vorsteuerkuerzungPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/vorsteuerkuerzung`,
  repFondsPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/reparaturFonds`,
  kontenRahmenPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/kontenrahmen`,
  indexSeries: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/indexreihen`,
  ebicsBankConfig: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/ebics-banken`,
  fieldHelp: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/feldhilfe`,
  token: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/token`,
  textPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/texte`,
  user: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/user`,
  ustKategoriePage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/ust-category`,
  ustRegelsetPage: `${URI_ANDROMEDA_SYSTEM_SETTINGS}/ust-ruleset`,
};

const URI_ANDROMEDA_SYS_SETTINGS_USER_DETAILS_PAGE = `${URI_ANDROMEDA_SYS_SETTINGS.user}/:userId`;
const URI_ANDROMEDA_SYS_SETTINGS_ADMIN_DETAILS_PAGE = `${URI_ANDROMEDA_SYS_SETTINGS.adminPage}/:username`;
const URI_ANDROMEDA_SYS_SETTINGS_KUNDEN_SYSTEM_DETAILS_PAGE = `${URI_ANDROMEDA_SYS_SETTINGS.kundenSystemPage}/:kundenSystemId`;

export {
  URI_ANDROMEDA_SYSTEM_SETTINGS,
  URI_ANDROMEDA_SYS_SETTINGS,
  URI_ANDROMEDA_SYS_SETTINGS_ADMIN_DETAILS_PAGE,
  URI_ANDROMEDA_SYS_SETTINGS_USER_DETAILS_PAGE,
  URI_ANDROMEDA_SYS_SETTINGS_KUNDEN_SYSTEM_DETAILS_PAGE,
};
