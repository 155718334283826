import { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../components/Grid';
import { verarbeitungPageAndMenuListTitles } from '../verarbeitungHelpers';
import { BudgetingGenerierlauf } from '../../../types';
import BudgetingVerarbeitungEntryListing from './EntryListing/BudgetingVerarbeitungEntryListing';

type Props = {
  generierlauf: BudgetingGenerierlauf;
};

const VerarbeitungBudgeting: FC<Props> = ({ generierlauf }) => {
  return (
    <>
      <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.Budgeting}</Typography.Title>
      <Spacer />
      <BudgetingVerarbeitungEntryListing generierlauf={generierlauf} />
    </>
  );
};

export default VerarbeitungBudgeting;
