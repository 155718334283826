import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungsdefinitionKurzBezeichnungQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
}>;

export type AbrechnungsdefinitionKurzBezeichnungQuery = { getAbrechnungsdefinitionBezeichnung: { data?: string | null; id?: string | null } };

export type AbrechnungsdefinitionTemplateKurzBezeichnungQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
}>;

export type AbrechnungsdefinitionTemplateKurzBezeichnungQuery = {
  getAbrechnungsdefinitionTemplateBezeichnung: { data?: string | null; id?: string | null };
};

export type ObjektKurzBezeichnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type ObjektKurzBezeichnungQuery = { getObjektKurzBezeichnung: { data?: string | null; id?: string | null } };

export type BestandseinheitKurzBezeichnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
}>;

export type BestandseinheitKurzBezeichnungQuery = { getBestandseinheitBezeichnung: { data?: string | null; id?: string | null } };

export type BeVertragKurzBezeichnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  showVertragsdauer?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  showObjekt?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  showBestandseinheit?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BeVertragKurzBezeichnungQuery = { getBeVertragKurzBezeichnung: { data?: string | null; id?: string | null } };

export type HeVertragKurzBezeichnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['ID']['input'];
  showObjekt?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  showVertragsdauer?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type HeVertragKurzBezeichnungQuery = { getHeVertragKurzbezeichnung: { data?: string | null; id?: string | null } };

export type KundenSystemKurzBezeichnungQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
}>;

export type KundenSystemKurzBezeichnungQuery = { getKundenSystem: { data: { name: string; id: string } } };

export type FirmendatenKurzBezeichnungQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenKurzBezeichnungQuery = { getFirmendaten: { data: { kurzBezeichnung: string; id: string } } };

export type KontoKurzBezeichnungQueryVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
}>;

export type KontoKurzBezeichnungQuery = { getKonto: { data: { bezeichnung: string; id: string } } };

export type KontoTemplateKurzBezeichnungQueryVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
}>;

export type KontoTemplateKurzBezeichnungQuery = { getKontoTemplate: { data: { bezeichnung: string; id: string } } };

export type WeAbrechnungsdefinitionKurzBezeichnungQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
}>;

export type WeAbrechnungsdefinitionKurzBezeichnungQuery = { getWeAbrechnungsdefinition: { data: { bezeichnung: string; id: string } } };

export type SingleInvoiceKurzBezeichnungQueryVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type SingleInvoiceKurzBezeichnungQuery = { getSingleInvoice: { data: { rechnungsNummer: string; id: string } } };

export type StandingInvoiceKurzBezeichnungQueryVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type StandingInvoiceKurzBezeichnungQuery = { getStandingInvoice: { data: { rechnungsNummer: string; id: string } } };

export type ObjektAbrKurzBezeichnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type ObjektAbrKurzBezeichnungQuery = { getObjektAbrechnung: { data: { bezeichnung: string; id: string } } };

export type RechtstraegerKurzBezeichnungQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechtstraegerKurzBezeichnungQuery = { getRechtstraeger: { data: { kurzBezeichnung: string; id: string } } };

export type AuftragKurzBezeichnungQueryVariables = Types.Exact<{
  auftragId: Types.Scalars['ID']['input'];
}>;

export type AuftragKurzBezeichnungQuery = {
  getAuftrag: {
    data: {
      auftragId: string;
      id: string;
      auftragsKopf: { fakturierungsperiode: { jahr: number; monat: number } };
      eigenbeleg?: { bezeichnung: string } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type TopAbrechnungKurzBezeichnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAbrechnungId: Types.Scalars['ID']['input'];
  topAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type TopAbrechnungKurzBezeichnungQuery = {
  getTopAbrechnung: { data: { bezeichnung: string; id: string; vertragspartner: { rechtstraegerId: string; kurzBezeichnung: string } } };
};

export type HeAbrechnungKurzBezeichnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  heAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type HeAbrechnungKurzBezeichnungQuery = { getHeAbrechnung: { data: { bezeichnung: string; id: string } } };

export type HeAbrechnungHeVertragKurzBezeichnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  heAbrechnungId: Types.Scalars['ID']['input'];
  heAbrechnungHeVertragId: Types.Scalars['ID']['input'];
}>;

export type HeAbrechnungHeVertragKurzBezeichnungQuery = {
  getHeAbrechnungHeVertrag: { data: { bezeichnung: string; id: string; hauseigentuemer: { kurzBezeichnung: string } } };
};

export const AbrechnungsdefinitionKurzBezeichnungDocument = gql`
  query AbrechnungsdefinitionKurzBezeichnung($abrechnungsdefinitionId: ID!) {
    getAbrechnungsdefinitionBezeichnung(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      id: data
      data
    }
  }
`;
export function useAbrechnungsdefinitionKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungsdefinitionKurzBezeichnungQuery, AbrechnungsdefinitionKurzBezeichnungQueryVariables> &
    ({ variables: AbrechnungsdefinitionKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungsdefinitionKurzBezeichnungQuery, AbrechnungsdefinitionKurzBezeichnungQueryVariables>(
    AbrechnungsdefinitionKurzBezeichnungDocument,
    options
  );
}
export function useAbrechnungsdefinitionKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungsdefinitionKurzBezeichnungQuery, AbrechnungsdefinitionKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungsdefinitionKurzBezeichnungQuery, AbrechnungsdefinitionKurzBezeichnungQueryVariables>(
    AbrechnungsdefinitionKurzBezeichnungDocument,
    options
  );
}
export function useAbrechnungsdefinitionKurzBezeichnungSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AbrechnungsdefinitionKurzBezeichnungQuery, AbrechnungsdefinitionKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungsdefinitionKurzBezeichnungQuery, AbrechnungsdefinitionKurzBezeichnungQueryVariables>(
    AbrechnungsdefinitionKurzBezeichnungDocument,
    options
  );
}
export type AbrechnungsdefinitionKurzBezeichnungQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionKurzBezeichnungQuery>;
export type AbrechnungsdefinitionKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionKurzBezeichnungLazyQuery>;
export type AbrechnungsdefinitionKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionKurzBezeichnungSuspenseQuery>;
export type AbrechnungsdefinitionKurzBezeichnungQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionKurzBezeichnungQuery,
  AbrechnungsdefinitionKurzBezeichnungQueryVariables
>;
export const AbrechnungsdefinitionTemplateKurzBezeichnungDocument = gql`
  query AbrechnungsdefinitionTemplateKurzBezeichnung($abrechnungsdefinitionId: ID!) {
    getAbrechnungsdefinitionTemplateBezeichnung(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      id: data
      data
    }
  }
`;
export function useAbrechnungsdefinitionTemplateKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<
    AbrechnungsdefinitionTemplateKurzBezeichnungQuery,
    AbrechnungsdefinitionTemplateKurzBezeichnungQueryVariables
  > &
    ({ variables: AbrechnungsdefinitionTemplateKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungsdefinitionTemplateKurzBezeichnungQuery, AbrechnungsdefinitionTemplateKurzBezeichnungQueryVariables>(
    AbrechnungsdefinitionTemplateKurzBezeichnungDocument,
    options
  );
}
export function useAbrechnungsdefinitionTemplateKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrechnungsdefinitionTemplateKurzBezeichnungQuery,
    AbrechnungsdefinitionTemplateKurzBezeichnungQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungsdefinitionTemplateKurzBezeichnungQuery, AbrechnungsdefinitionTemplateKurzBezeichnungQueryVariables>(
    AbrechnungsdefinitionTemplateKurzBezeichnungDocument,
    options
  );
}
export function useAbrechnungsdefinitionTemplateKurzBezeichnungSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AbrechnungsdefinitionTemplateKurzBezeichnungQuery, AbrechnungsdefinitionTemplateKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungsdefinitionTemplateKurzBezeichnungQuery, AbrechnungsdefinitionTemplateKurzBezeichnungQueryVariables>(
    AbrechnungsdefinitionTemplateKurzBezeichnungDocument,
    options
  );
}
export type AbrechnungsdefinitionTemplateKurzBezeichnungQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTemplateKurzBezeichnungQuery>;
export type AbrechnungsdefinitionTemplateKurzBezeichnungLazyQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionTemplateKurzBezeichnungLazyQuery
>;
export type AbrechnungsdefinitionTemplateKurzBezeichnungSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionTemplateKurzBezeichnungSuspenseQuery
>;
export type AbrechnungsdefinitionTemplateKurzBezeichnungQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionTemplateKurzBezeichnungQuery,
  AbrechnungsdefinitionTemplateKurzBezeichnungQueryVariables
>;
export const ObjektKurzBezeichnungDocument = gql`
  query ObjektKurzBezeichnung($objektId: ID!) {
    getObjektKurzBezeichnung(objektId: $objektId) {
      id: data
      data
    }
  }
`;
export function useObjektKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektKurzBezeichnungQuery, ObjektKurzBezeichnungQueryVariables> &
    ({ variables: ObjektKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektKurzBezeichnungQuery, ObjektKurzBezeichnungQueryVariables>(ObjektKurzBezeichnungDocument, options);
}
export function useObjektKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObjektKurzBezeichnungQuery, ObjektKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektKurzBezeichnungQuery, ObjektKurzBezeichnungQueryVariables>(ObjektKurzBezeichnungDocument, options);
}
export function useObjektKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektKurzBezeichnungQuery, ObjektKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektKurzBezeichnungQuery, ObjektKurzBezeichnungQueryVariables>(ObjektKurzBezeichnungDocument, options);
}
export type ObjektKurzBezeichnungQueryHookResult = ReturnType<typeof useObjektKurzBezeichnungQuery>;
export type ObjektKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useObjektKurzBezeichnungLazyQuery>;
export type ObjektKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useObjektKurzBezeichnungSuspenseQuery>;
export type ObjektKurzBezeichnungQueryResult = Apollo.QueryResult<ObjektKurzBezeichnungQuery, ObjektKurzBezeichnungQueryVariables>;
export const BestandseinheitKurzBezeichnungDocument = gql`
  query BestandseinheitKurzBezeichnung($objektId: ID!, $bestandseinheitId: ID!) {
    getBestandseinheitBezeichnung(objektId: $objektId, bestandseinheitId: $bestandseinheitId) {
      id: data
      data
    }
  }
`;
export function useBestandseinheitKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitKurzBezeichnungQuery, BestandseinheitKurzBezeichnungQueryVariables> &
    ({ variables: BestandseinheitKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitKurzBezeichnungQuery, BestandseinheitKurzBezeichnungQueryVariables>(
    BestandseinheitKurzBezeichnungDocument,
    options
  );
}
export function useBestandseinheitKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitKurzBezeichnungQuery, BestandseinheitKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitKurzBezeichnungQuery, BestandseinheitKurzBezeichnungQueryVariables>(
    BestandseinheitKurzBezeichnungDocument,
    options
  );
}
export function useBestandseinheitKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BestandseinheitKurzBezeichnungQuery, BestandseinheitKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitKurzBezeichnungQuery, BestandseinheitKurzBezeichnungQueryVariables>(
    BestandseinheitKurzBezeichnungDocument,
    options
  );
}
export type BestandseinheitKurzBezeichnungQueryHookResult = ReturnType<typeof useBestandseinheitKurzBezeichnungQuery>;
export type BestandseinheitKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useBestandseinheitKurzBezeichnungLazyQuery>;
export type BestandseinheitKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitKurzBezeichnungSuspenseQuery>;
export type BestandseinheitKurzBezeichnungQueryResult = Apollo.QueryResult<
  BestandseinheitKurzBezeichnungQuery,
  BestandseinheitKurzBezeichnungQueryVariables
>;
export const BeVertragKurzBezeichnungDocument = gql`
  query BeVertragKurzBezeichnung(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $showVertragsdauer: Boolean
    $showObjekt: Boolean
    $showBestandseinheit: Boolean
  ) {
    getBeVertragKurzBezeichnung(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      showVertragsdauer: $showVertragsdauer
      showObjekt: $showObjekt
      showBestandseinheit: $showBestandseinheit
    ) {
      id: data
      data
    }
  }
`;
export function useBeVertragKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragKurzBezeichnungQuery, BeVertragKurzBezeichnungQueryVariables> &
    ({ variables: BeVertragKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragKurzBezeichnungQuery, BeVertragKurzBezeichnungQueryVariables>(BeVertragKurzBezeichnungDocument, options);
}
export function useBeVertragKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeVertragKurzBezeichnungQuery, BeVertragKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragKurzBezeichnungQuery, BeVertragKurzBezeichnungQueryVariables>(BeVertragKurzBezeichnungDocument, options);
}
export function useBeVertragKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeVertragKurzBezeichnungQuery, BeVertragKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragKurzBezeichnungQuery, BeVertragKurzBezeichnungQueryVariables>(BeVertragKurzBezeichnungDocument, options);
}
export type BeVertragKurzBezeichnungQueryHookResult = ReturnType<typeof useBeVertragKurzBezeichnungQuery>;
export type BeVertragKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useBeVertragKurzBezeichnungLazyQuery>;
export type BeVertragKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useBeVertragKurzBezeichnungSuspenseQuery>;
export type BeVertragKurzBezeichnungQueryResult = Apollo.QueryResult<BeVertragKurzBezeichnungQuery, BeVertragKurzBezeichnungQueryVariables>;
export const HeVertragKurzBezeichnungDocument = gql`
  query HeVertragKurzBezeichnung($objektId: String!, $heVertragId: ID!, $showObjekt: Boolean, $showVertragsdauer: Boolean) {
    getHeVertragKurzbezeichnung(objektId: $objektId, heVertragId: $heVertragId, showObjekt: $showObjekt, showVertragsdauer: $showVertragsdauer) {
      id: data
      data
    }
  }
`;
export function useHeVertragKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<HeVertragKurzBezeichnungQuery, HeVertragKurzBezeichnungQueryVariables> &
    ({ variables: HeVertragKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeVertragKurzBezeichnungQuery, HeVertragKurzBezeichnungQueryVariables>(HeVertragKurzBezeichnungDocument, options);
}
export function useHeVertragKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeVertragKurzBezeichnungQuery, HeVertragKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeVertragKurzBezeichnungQuery, HeVertragKurzBezeichnungQueryVariables>(HeVertragKurzBezeichnungDocument, options);
}
export function useHeVertragKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeVertragKurzBezeichnungQuery, HeVertragKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeVertragKurzBezeichnungQuery, HeVertragKurzBezeichnungQueryVariables>(HeVertragKurzBezeichnungDocument, options);
}
export type HeVertragKurzBezeichnungQueryHookResult = ReturnType<typeof useHeVertragKurzBezeichnungQuery>;
export type HeVertragKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useHeVertragKurzBezeichnungLazyQuery>;
export type HeVertragKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useHeVertragKurzBezeichnungSuspenseQuery>;
export type HeVertragKurzBezeichnungQueryResult = Apollo.QueryResult<HeVertragKurzBezeichnungQuery, HeVertragKurzBezeichnungQueryVariables>;
export const KundenSystemKurzBezeichnungDocument = gql`
  query KundenSystemKurzBezeichnung($kundenSystemId: ID!) {
    getKundenSystem(kundenSystemId: $kundenSystemId) {
      data {
        id: name
        name
      }
    }
  }
`;
export function useKundenSystemKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<KundenSystemKurzBezeichnungQuery, KundenSystemKurzBezeichnungQueryVariables> &
    ({ variables: KundenSystemKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KundenSystemKurzBezeichnungQuery, KundenSystemKurzBezeichnungQueryVariables>(KundenSystemKurzBezeichnungDocument, options);
}
export function useKundenSystemKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KundenSystemKurzBezeichnungQuery, KundenSystemKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KundenSystemKurzBezeichnungQuery, KundenSystemKurzBezeichnungQueryVariables>(
    KundenSystemKurzBezeichnungDocument,
    options
  );
}
export function useKundenSystemKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KundenSystemKurzBezeichnungQuery, KundenSystemKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KundenSystemKurzBezeichnungQuery, KundenSystemKurzBezeichnungQueryVariables>(
    KundenSystemKurzBezeichnungDocument,
    options
  );
}
export type KundenSystemKurzBezeichnungQueryHookResult = ReturnType<typeof useKundenSystemKurzBezeichnungQuery>;
export type KundenSystemKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useKundenSystemKurzBezeichnungLazyQuery>;
export type KundenSystemKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useKundenSystemKurzBezeichnungSuspenseQuery>;
export type KundenSystemKurzBezeichnungQueryResult = Apollo.QueryResult<KundenSystemKurzBezeichnungQuery, KundenSystemKurzBezeichnungQueryVariables>;
export const FirmendatenKurzBezeichnungDocument = gql`
  query FirmendatenKurzBezeichnung($firmendatenId: ID!) {
    getFirmendaten(firmendatenId: $firmendatenId) {
      data {
        id: firmendatenId
        kurzBezeichnung
      }
    }
  }
`;
export function useFirmendatenKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenKurzBezeichnungQuery, FirmendatenKurzBezeichnungQueryVariables> &
    ({ variables: FirmendatenKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenKurzBezeichnungQuery, FirmendatenKurzBezeichnungQueryVariables>(FirmendatenKurzBezeichnungDocument, options);
}
export function useFirmendatenKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenKurzBezeichnungQuery, FirmendatenKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenKurzBezeichnungQuery, FirmendatenKurzBezeichnungQueryVariables>(FirmendatenKurzBezeichnungDocument, options);
}
export function useFirmendatenKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenKurzBezeichnungQuery, FirmendatenKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenKurzBezeichnungQuery, FirmendatenKurzBezeichnungQueryVariables>(
    FirmendatenKurzBezeichnungDocument,
    options
  );
}
export type FirmendatenKurzBezeichnungQueryHookResult = ReturnType<typeof useFirmendatenKurzBezeichnungQuery>;
export type FirmendatenKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useFirmendatenKurzBezeichnungLazyQuery>;
export type FirmendatenKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useFirmendatenKurzBezeichnungSuspenseQuery>;
export type FirmendatenKurzBezeichnungQueryResult = Apollo.QueryResult<FirmendatenKurzBezeichnungQuery, FirmendatenKurzBezeichnungQueryVariables>;
export const KontoKurzBezeichnungDocument = gql`
  query KontoKurzBezeichnung($kontoId: ID!) {
    getKonto(kontoId: $kontoId) {
      data {
        id: kontoId
        bezeichnung
      }
    }
  }
`;
export function useKontoKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<KontoKurzBezeichnungQuery, KontoKurzBezeichnungQueryVariables> &
    ({ variables: KontoKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoKurzBezeichnungQuery, KontoKurzBezeichnungQueryVariables>(KontoKurzBezeichnungDocument, options);
}
export function useKontoKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KontoKurzBezeichnungQuery, KontoKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoKurzBezeichnungQuery, KontoKurzBezeichnungQueryVariables>(KontoKurzBezeichnungDocument, options);
}
export function useKontoKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoKurzBezeichnungQuery, KontoKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoKurzBezeichnungQuery, KontoKurzBezeichnungQueryVariables>(KontoKurzBezeichnungDocument, options);
}
export type KontoKurzBezeichnungQueryHookResult = ReturnType<typeof useKontoKurzBezeichnungQuery>;
export type KontoKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useKontoKurzBezeichnungLazyQuery>;
export type KontoKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useKontoKurzBezeichnungSuspenseQuery>;
export type KontoKurzBezeichnungQueryResult = Apollo.QueryResult<KontoKurzBezeichnungQuery, KontoKurzBezeichnungQueryVariables>;
export const KontoTemplateKurzBezeichnungDocument = gql`
  query KontoTemplateKurzBezeichnung($kontoId: ID!) {
    getKontoTemplate(kontoId: $kontoId) {
      data {
        id: kontoId
        bezeichnung
      }
    }
  }
`;
export function useKontoTemplateKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<KontoTemplateKurzBezeichnungQuery, KontoTemplateKurzBezeichnungQueryVariables> &
    ({ variables: KontoTemplateKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoTemplateKurzBezeichnungQuery, KontoTemplateKurzBezeichnungQueryVariables>(
    KontoTemplateKurzBezeichnungDocument,
    options
  );
}
export function useKontoTemplateKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KontoTemplateKurzBezeichnungQuery, KontoTemplateKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoTemplateKurzBezeichnungQuery, KontoTemplateKurzBezeichnungQueryVariables>(
    KontoTemplateKurzBezeichnungDocument,
    options
  );
}
export function useKontoTemplateKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoTemplateKurzBezeichnungQuery, KontoTemplateKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoTemplateKurzBezeichnungQuery, KontoTemplateKurzBezeichnungQueryVariables>(
    KontoTemplateKurzBezeichnungDocument,
    options
  );
}
export type KontoTemplateKurzBezeichnungQueryHookResult = ReturnType<typeof useKontoTemplateKurzBezeichnungQuery>;
export type KontoTemplateKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useKontoTemplateKurzBezeichnungLazyQuery>;
export type KontoTemplateKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useKontoTemplateKurzBezeichnungSuspenseQuery>;
export type KontoTemplateKurzBezeichnungQueryResult = Apollo.QueryResult<
  KontoTemplateKurzBezeichnungQuery,
  KontoTemplateKurzBezeichnungQueryVariables
>;
export const WeAbrechnungsdefinitionKurzBezeichnungDocument = gql`
  query WeAbrechnungsdefinitionKurzBezeichnung($abrechnungsdefinitionId: ID!) {
    getWeAbrechnungsdefinition(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      data {
        id: abrechnungsdefinitionId
        bezeichnung
      }
    }
  }
`;
export function useWeAbrechnungsdefinitionKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<WeAbrechnungsdefinitionKurzBezeichnungQuery, WeAbrechnungsdefinitionKurzBezeichnungQueryVariables> &
    ({ variables: WeAbrechnungsdefinitionKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WeAbrechnungsdefinitionKurzBezeichnungQuery, WeAbrechnungsdefinitionKurzBezeichnungQueryVariables>(
    WeAbrechnungsdefinitionKurzBezeichnungDocument,
    options
  );
}
export function useWeAbrechnungsdefinitionKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WeAbrechnungsdefinitionKurzBezeichnungQuery, WeAbrechnungsdefinitionKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WeAbrechnungsdefinitionKurzBezeichnungQuery, WeAbrechnungsdefinitionKurzBezeichnungQueryVariables>(
    WeAbrechnungsdefinitionKurzBezeichnungDocument,
    options
  );
}
export function useWeAbrechnungsdefinitionKurzBezeichnungSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<WeAbrechnungsdefinitionKurzBezeichnungQuery, WeAbrechnungsdefinitionKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WeAbrechnungsdefinitionKurzBezeichnungQuery, WeAbrechnungsdefinitionKurzBezeichnungQueryVariables>(
    WeAbrechnungsdefinitionKurzBezeichnungDocument,
    options
  );
}
export type WeAbrechnungsdefinitionKurzBezeichnungQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionKurzBezeichnungQuery>;
export type WeAbrechnungsdefinitionKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionKurzBezeichnungLazyQuery>;
export type WeAbrechnungsdefinitionKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionKurzBezeichnungSuspenseQuery>;
export type WeAbrechnungsdefinitionKurzBezeichnungQueryResult = Apollo.QueryResult<
  WeAbrechnungsdefinitionKurzBezeichnungQuery,
  WeAbrechnungsdefinitionKurzBezeichnungQueryVariables
>;
export const SingleInvoiceKurzBezeichnungDocument = gql`
  query SingleInvoiceKurzBezeichnung($belegId: String!) {
    getSingleInvoice(belegId: $belegId) {
      data {
        id: belegId
        rechnungsNummer
      }
    }
  }
`;
export function useSingleInvoiceKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<SingleInvoiceKurzBezeichnungQuery, SingleInvoiceKurzBezeichnungQueryVariables> &
    ({ variables: SingleInvoiceKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SingleInvoiceKurzBezeichnungQuery, SingleInvoiceKurzBezeichnungQueryVariables>(
    SingleInvoiceKurzBezeichnungDocument,
    options
  );
}
export function useSingleInvoiceKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SingleInvoiceKurzBezeichnungQuery, SingleInvoiceKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SingleInvoiceKurzBezeichnungQuery, SingleInvoiceKurzBezeichnungQueryVariables>(
    SingleInvoiceKurzBezeichnungDocument,
    options
  );
}
export function useSingleInvoiceKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SingleInvoiceKurzBezeichnungQuery, SingleInvoiceKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SingleInvoiceKurzBezeichnungQuery, SingleInvoiceKurzBezeichnungQueryVariables>(
    SingleInvoiceKurzBezeichnungDocument,
    options
  );
}
export type SingleInvoiceKurzBezeichnungQueryHookResult = ReturnType<typeof useSingleInvoiceKurzBezeichnungQuery>;
export type SingleInvoiceKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useSingleInvoiceKurzBezeichnungLazyQuery>;
export type SingleInvoiceKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useSingleInvoiceKurzBezeichnungSuspenseQuery>;
export type SingleInvoiceKurzBezeichnungQueryResult = Apollo.QueryResult<
  SingleInvoiceKurzBezeichnungQuery,
  SingleInvoiceKurzBezeichnungQueryVariables
>;
export const StandingInvoiceKurzBezeichnungDocument = gql`
  query StandingInvoiceKurzBezeichnung($belegId: String!) {
    getStandingInvoice(belegId: $belegId) {
      data {
        id: belegId
        rechnungsNummer
      }
    }
  }
`;
export function useStandingInvoiceKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<StandingInvoiceKurzBezeichnungQuery, StandingInvoiceKurzBezeichnungQueryVariables> &
    ({ variables: StandingInvoiceKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StandingInvoiceKurzBezeichnungQuery, StandingInvoiceKurzBezeichnungQueryVariables>(
    StandingInvoiceKurzBezeichnungDocument,
    options
  );
}
export function useStandingInvoiceKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StandingInvoiceKurzBezeichnungQuery, StandingInvoiceKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StandingInvoiceKurzBezeichnungQuery, StandingInvoiceKurzBezeichnungQueryVariables>(
    StandingInvoiceKurzBezeichnungDocument,
    options
  );
}
export function useStandingInvoiceKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StandingInvoiceKurzBezeichnungQuery, StandingInvoiceKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StandingInvoiceKurzBezeichnungQuery, StandingInvoiceKurzBezeichnungQueryVariables>(
    StandingInvoiceKurzBezeichnungDocument,
    options
  );
}
export type StandingInvoiceKurzBezeichnungQueryHookResult = ReturnType<typeof useStandingInvoiceKurzBezeichnungQuery>;
export type StandingInvoiceKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useStandingInvoiceKurzBezeichnungLazyQuery>;
export type StandingInvoiceKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useStandingInvoiceKurzBezeichnungSuspenseQuery>;
export type StandingInvoiceKurzBezeichnungQueryResult = Apollo.QueryResult<
  StandingInvoiceKurzBezeichnungQuery,
  StandingInvoiceKurzBezeichnungQueryVariables
>;
export const ObjektAbrKurzBezeichnungDocument = gql`
  query ObjektAbrKurzBezeichnung($objektId: ID!, $objektAbrechnungId: ID!) {
    getObjektAbrechnung(objektId: $objektId, objektAbrechnungId: $objektAbrechnungId) {
      data {
        id: objektAbrechnungId
        bezeichnung
      }
    }
  }
`;
export function useObjektAbrKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektAbrKurzBezeichnungQuery, ObjektAbrKurzBezeichnungQueryVariables> &
    ({ variables: ObjektAbrKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektAbrKurzBezeichnungQuery, ObjektAbrKurzBezeichnungQueryVariables>(ObjektAbrKurzBezeichnungDocument, options);
}
export function useObjektAbrKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObjektAbrKurzBezeichnungQuery, ObjektAbrKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektAbrKurzBezeichnungQuery, ObjektAbrKurzBezeichnungQueryVariables>(ObjektAbrKurzBezeichnungDocument, options);
}
export function useObjektAbrKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektAbrKurzBezeichnungQuery, ObjektAbrKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektAbrKurzBezeichnungQuery, ObjektAbrKurzBezeichnungQueryVariables>(ObjektAbrKurzBezeichnungDocument, options);
}
export type ObjektAbrKurzBezeichnungQueryHookResult = ReturnType<typeof useObjektAbrKurzBezeichnungQuery>;
export type ObjektAbrKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useObjektAbrKurzBezeichnungLazyQuery>;
export type ObjektAbrKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useObjektAbrKurzBezeichnungSuspenseQuery>;
export type ObjektAbrKurzBezeichnungQueryResult = Apollo.QueryResult<ObjektAbrKurzBezeichnungQuery, ObjektAbrKurzBezeichnungQueryVariables>;
export const RechtstraegerKurzBezeichnungDocument = gql`
  query RechtstraegerKurzBezeichnung($rechtstraegerId: ID!) {
    getRechtstraeger(rechtstraegerId: $rechtstraegerId) {
      data {
        id: rechtstraegerId
        kurzBezeichnung
      }
    }
  }
`;
export function useRechtstraegerKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<RechtstraegerKurzBezeichnungQuery, RechtstraegerKurzBezeichnungQueryVariables> &
    ({ variables: RechtstraegerKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerKurzBezeichnungQuery, RechtstraegerKurzBezeichnungQueryVariables>(
    RechtstraegerKurzBezeichnungDocument,
    options
  );
}
export function useRechtstraegerKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerKurzBezeichnungQuery, RechtstraegerKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerKurzBezeichnungQuery, RechtstraegerKurzBezeichnungQueryVariables>(
    RechtstraegerKurzBezeichnungDocument,
    options
  );
}
export function useRechtstraegerKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerKurzBezeichnungQuery, RechtstraegerKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerKurzBezeichnungQuery, RechtstraegerKurzBezeichnungQueryVariables>(
    RechtstraegerKurzBezeichnungDocument,
    options
  );
}
export type RechtstraegerKurzBezeichnungQueryHookResult = ReturnType<typeof useRechtstraegerKurzBezeichnungQuery>;
export type RechtstraegerKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useRechtstraegerKurzBezeichnungLazyQuery>;
export type RechtstraegerKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerKurzBezeichnungSuspenseQuery>;
export type RechtstraegerKurzBezeichnungQueryResult = Apollo.QueryResult<
  RechtstraegerKurzBezeichnungQuery,
  RechtstraegerKurzBezeichnungQueryVariables
>;
export const AuftragKurzBezeichnungDocument = gql`
  query AuftragKurzBezeichnung($auftragId: ID!) {
    getAuftrag(auftragId: $auftragId) {
      data {
        id: auftragId
        auftragId
        auftragsKopf {
          fakturierungsperiode {
            jahr
            monat
          }
        }
        eigenbeleg {
          bezeichnung
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAuftragKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragKurzBezeichnungQuery, AuftragKurzBezeichnungQueryVariables> &
    ({ variables: AuftragKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragKurzBezeichnungQuery, AuftragKurzBezeichnungQueryVariables>(AuftragKurzBezeichnungDocument, options);
}
export function useAuftragKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragKurzBezeichnungQuery, AuftragKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragKurzBezeichnungQuery, AuftragKurzBezeichnungQueryVariables>(AuftragKurzBezeichnungDocument, options);
}
export function useAuftragKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragKurzBezeichnungQuery, AuftragKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragKurzBezeichnungQuery, AuftragKurzBezeichnungQueryVariables>(AuftragKurzBezeichnungDocument, options);
}
export type AuftragKurzBezeichnungQueryHookResult = ReturnType<typeof useAuftragKurzBezeichnungQuery>;
export type AuftragKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useAuftragKurzBezeichnungLazyQuery>;
export type AuftragKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useAuftragKurzBezeichnungSuspenseQuery>;
export type AuftragKurzBezeichnungQueryResult = Apollo.QueryResult<AuftragKurzBezeichnungQuery, AuftragKurzBezeichnungQueryVariables>;
export const TopAbrechnungKurzBezeichnungDocument = gql`
  query TopAbrechnungKurzBezeichnung($objektId: ID!, $objektAbrechnungId: ID!, $topAbrechnungId: ID!) {
    getTopAbrechnung(objektId: $objektId, objektAbrechnungId: $objektAbrechnungId, topAbrechnungId: $topAbrechnungId) {
      data {
        id: topAbrechnungId
        bezeichnung
        vertragspartner {
          rechtstraegerId
          kurzBezeichnung
        }
      }
    }
  }
`;
export function useTopAbrechnungKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<TopAbrechnungKurzBezeichnungQuery, TopAbrechnungKurzBezeichnungQueryVariables> &
    ({ variables: TopAbrechnungKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopAbrechnungKurzBezeichnungQuery, TopAbrechnungKurzBezeichnungQueryVariables>(
    TopAbrechnungKurzBezeichnungDocument,
    options
  );
}
export function useTopAbrechnungKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopAbrechnungKurzBezeichnungQuery, TopAbrechnungKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopAbrechnungKurzBezeichnungQuery, TopAbrechnungKurzBezeichnungQueryVariables>(
    TopAbrechnungKurzBezeichnungDocument,
    options
  );
}
export function useTopAbrechnungKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TopAbrechnungKurzBezeichnungQuery, TopAbrechnungKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TopAbrechnungKurzBezeichnungQuery, TopAbrechnungKurzBezeichnungQueryVariables>(
    TopAbrechnungKurzBezeichnungDocument,
    options
  );
}
export type TopAbrechnungKurzBezeichnungQueryHookResult = ReturnType<typeof useTopAbrechnungKurzBezeichnungQuery>;
export type TopAbrechnungKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useTopAbrechnungKurzBezeichnungLazyQuery>;
export type TopAbrechnungKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useTopAbrechnungKurzBezeichnungSuspenseQuery>;
export type TopAbrechnungKurzBezeichnungQueryResult = Apollo.QueryResult<
  TopAbrechnungKurzBezeichnungQuery,
  TopAbrechnungKurzBezeichnungQueryVariables
>;
export const HeAbrechnungKurzBezeichnungDocument = gql`
  query HeAbrechnungKurzBezeichnung($objektId: ID!, $heAbrechnungId: ID!) {
    getHeAbrechnung(objektId: $objektId, heAbrechnungId: $heAbrechnungId) {
      data {
        id: heAbrechnungId
        bezeichnung
      }
    }
  }
`;
export function useHeAbrechnungKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<HeAbrechnungKurzBezeichnungQuery, HeAbrechnungKurzBezeichnungQueryVariables> &
    ({ variables: HeAbrechnungKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeAbrechnungKurzBezeichnungQuery, HeAbrechnungKurzBezeichnungQueryVariables>(HeAbrechnungKurzBezeichnungDocument, options);
}
export function useHeAbrechnungKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeAbrechnungKurzBezeichnungQuery, HeAbrechnungKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeAbrechnungKurzBezeichnungQuery, HeAbrechnungKurzBezeichnungQueryVariables>(
    HeAbrechnungKurzBezeichnungDocument,
    options
  );
}
export function useHeAbrechnungKurzBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeAbrechnungKurzBezeichnungQuery, HeAbrechnungKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeAbrechnungKurzBezeichnungQuery, HeAbrechnungKurzBezeichnungQueryVariables>(
    HeAbrechnungKurzBezeichnungDocument,
    options
  );
}
export type HeAbrechnungKurzBezeichnungQueryHookResult = ReturnType<typeof useHeAbrechnungKurzBezeichnungQuery>;
export type HeAbrechnungKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useHeAbrechnungKurzBezeichnungLazyQuery>;
export type HeAbrechnungKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useHeAbrechnungKurzBezeichnungSuspenseQuery>;
export type HeAbrechnungKurzBezeichnungQueryResult = Apollo.QueryResult<HeAbrechnungKurzBezeichnungQuery, HeAbrechnungKurzBezeichnungQueryVariables>;
export const HeAbrechnungHeVertragKurzBezeichnungDocument = gql`
  query HeAbrechnungHeVertragKurzBezeichnung($objektId: ID!, $heAbrechnungId: ID!, $heAbrechnungHeVertragId: ID!) {
    getHeAbrechnungHeVertrag(objektId: $objektId, heAbrechnungId: $heAbrechnungId, heAbrechnungHeVertragId: $heAbrechnungHeVertragId) {
      data {
        id: heAbrechnungHeVertragId
        bezeichnung
        hauseigentuemer {
          kurzBezeichnung
        }
      }
    }
  }
`;
export function useHeAbrechnungHeVertragKurzBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<HeAbrechnungHeVertragKurzBezeichnungQuery, HeAbrechnungHeVertragKurzBezeichnungQueryVariables> &
    ({ variables: HeAbrechnungHeVertragKurzBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeAbrechnungHeVertragKurzBezeichnungQuery, HeAbrechnungHeVertragKurzBezeichnungQueryVariables>(
    HeAbrechnungHeVertragKurzBezeichnungDocument,
    options
  );
}
export function useHeAbrechnungHeVertragKurzBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeAbrechnungHeVertragKurzBezeichnungQuery, HeAbrechnungHeVertragKurzBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeAbrechnungHeVertragKurzBezeichnungQuery, HeAbrechnungHeVertragKurzBezeichnungQueryVariables>(
    HeAbrechnungHeVertragKurzBezeichnungDocument,
    options
  );
}
export function useHeAbrechnungHeVertragKurzBezeichnungSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HeAbrechnungHeVertragKurzBezeichnungQuery, HeAbrechnungHeVertragKurzBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeAbrechnungHeVertragKurzBezeichnungQuery, HeAbrechnungHeVertragKurzBezeichnungQueryVariables>(
    HeAbrechnungHeVertragKurzBezeichnungDocument,
    options
  );
}
export type HeAbrechnungHeVertragKurzBezeichnungQueryHookResult = ReturnType<typeof useHeAbrechnungHeVertragKurzBezeichnungQuery>;
export type HeAbrechnungHeVertragKurzBezeichnungLazyQueryHookResult = ReturnType<typeof useHeAbrechnungHeVertragKurzBezeichnungLazyQuery>;
export type HeAbrechnungHeVertragKurzBezeichnungSuspenseQueryHookResult = ReturnType<typeof useHeAbrechnungHeVertragKurzBezeichnungSuspenseQuery>;
export type HeAbrechnungHeVertragKurzBezeichnungQueryResult = Apollo.QueryResult<
  HeAbrechnungHeVertragKurzBezeichnungQuery,
  HeAbrechnungHeVertragKurzBezeichnungQueryVariables
>;
