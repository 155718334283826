import React from 'react';
import { Badge, Button, Space } from 'antd';
import { FormattedNumber } from 'react-intl';
import BeAufteilungsschluesselWertActions from '../shared/BeAufteilungsschluesselWertActions';
import BeAufteilungsschluesselWertMitBezugWertInput from './BeAufteilungsschluesselWertMitBezugWertInput';
import { EditableTableColumnProps } from '../../../../../components/Table/EditableTableOld/EditableTableProps';
import {
  beAufteilungsschluesselWertMitBezugFormFields,
  BeAufteilungsschluesselWertMitBezugFormValues,
} from './BeAufteilungsschluesselWertMitBezugMapper';
import BeAufteilungsschluesselWertFormValidFrom from '../shared/BeAufteilungsschluesselWertFormValidFrom';
import BeAufteilungsschluesselWertMitBezugValidFromWithInfo from './BeAufteilungsschluesselWertMitBezugValidFromWithInfo';
import { BeAufteilingsschluesselWert, BezugsbasisTuple } from '../../../../../types';

const beAufteilungsschluesselWertMitBezugTableColumns = (
  aufteilungsschluesselId: string,
  wertList: BeAufteilingsschluesselWert[],
  bezugsbasis: BezugsbasisTuple,
  onAction: () => void
): EditableTableColumnProps<BeAufteilingsschluesselWert, BeAufteilungsschluesselWertMitBezugFormValues>[] => [
  {
    title: 'Gültig ab',
    render: (text, record) => <BeAufteilungsschluesselWertMitBezugValidFromWithInfo record={record} bezugsbasis={bezugsbasis} />,
    editable: true,
    renderInEditMode: ({ selectedRecord }) => (
      <BeAufteilungsschluesselWertFormValidFrom
        validFromFieldName={beAufteilungsschluesselWertMitBezugFormFields.validFrom}
        selectedRecord={selectedRecord}
        beAufteilungsschluesselWertList={wertList}
      />
    ),
  },
  {
    title: 'Wert',
    editable: true,
    align: 'right',
    render: (text, record) => <FormattedNumber value={record.wert} minimumFractionDigits={2} maximumFractionDigits={2} />,
    renderInEditMode: (_, formikProps) => <BeAufteilungsschluesselWertMitBezugWertInput formikProps={formikProps} />,
  },
  {
    title: 'Status',
    render: (text, record) => <Badge status={record.aktiv ? 'success' : 'default'} text={record.aktiv ? 'angewendet' : 'nicht angewendet'} />,
  },
  {
    title: 'Aktion',
    key: 'action',
    render: (text, record) => (
      <BeAufteilungsschluesselWertActions
        aufteilungsschluesselId={aufteilungsschluesselId}
        onAction={onAction}
        aufteilungsschluesselWert={record}
        aufteilungsschluesselWertList={wertList}
      />
    ),
    editable: true,
    renderInEditMode: ({ cancelEdit, isSubmitLoading }) => (
      <Space>
        <Button htmlType="submit" type="link" loading={isSubmitLoading}>
          Speichern
        </Button>
        <Button size="small" type="link" onClick={cancelEdit}>
          Abbrechen
        </Button>
      </Space>
    ),
  },
];

export default beAufteilungsschluesselWertMitBezugTableColumns;
