import { FC } from 'react';
import { useFirmaOperatingSiteChangeHistoryListQuery } from '../gql/FirmaOperatingSiteQueries.types';
import { HistoryType } from '../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../History/HistoryListingTable';

type Props = {
  firmaId: string;
  operatingSiteId: string;
};

const FirmaOperatingSiteChangeHistoryListingTable: FC<Props> = ({ firmaId, operatingSiteId }) => {
  const { data, loading } = useFirmaOperatingSiteChangeHistoryListQuery({ variables: { firmaId, operatingSiteId } });
  const historyList = data?.getFirmaOperatingSiteChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="firma-operating-site" />;
};

export default FirmaOperatingSiteChangeHistoryListingTable;
