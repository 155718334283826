import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { VBestandseinheit, VVorschreibungspositionListHeader } from '../../../vorschreibung-types';
import VTBestandseinheitListColumnTitles from './VTBestandseinheitListColumnTitles';
import VTBestandseinheitList from './VTBestandseinheitList';

const VTBestandseinheitTableBlock: FC<{ bestandseinheitList: VBestandseinheit[]; vPosListHeader: VVorschreibungspositionListHeader }> = ({
  bestandseinheitList,
  vPosListHeader,
}) => (
  <View style={[pdfStyles.column, { paddingLeft: '5mm' }]}>
    {/* column names */}
    <VTBestandseinheitListColumnTitles titles={vPosListHeader} />

    {/* Bestandseinheit list with VPos */}
    <VTBestandseinheitList bestandseinheitList={bestandseinheitList} />
  </View>
);

export default VTBestandseinheitTableBlock;
