import { DataCarrierType, GenerierlaufEntryExitCode, PaymentTransactionType } from '../../../../../types';
import { TDataCarrierPainCreationQueryParams } from './filtersQueryParams';
import { FormFields } from '../../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../../shared/typeHelpers';

export type FiltersFormValues = {
  dataCarrierType?: DataCarrierType | null;
  name?: string | null;
  paymentTransactionType?: PaymentTransactionType | null;
  iban?: string | null;
  exitCodeList?: GenerierlaufEntryExitCode[] | null;
};

export const listingFiltersFormFields: FormFields<FiltersFormValues> = {
  dataCarrierType: 'dataCarrierType',
  name: 'name',
  paymentTransactionType: 'paymentTransactionType',
  iban: 'iban',
  exitCodeList: 'exitCodeList',
};

export const mapQueryParamsToFormValues = (queryParams: TDataCarrierPainCreationQueryParams): FiltersFormValues => ({
  dataCarrierType: queryParams.dataCarrierType,
  name: queryParams.name,
  paymentTransactionType: queryParams.paymentTransactionType,
  iban: queryParams.iban,
  exitCodeList: queryParams.exitCodeList,
});

export const listingLabelList: ObjectOfStrings<FiltersFormValues> = {
  dataCarrierType: 'Datenträgertyp',
  name: 'Bezeichnung (des Datenträgers)',
  paymentTransactionType: 'Zahlungsverkehrsart: EBICS / Onlinebanking',
  iban: 'Bankverbindung',
  exitCodeList: 'Exit Code Status',
};
