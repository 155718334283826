import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PersonenbezugChangeHistorListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  personenbezugId: Types.Scalars['ID']['input'];
}>;

export type PersonenbezugChangeHistorListQuery = {
  getPersonenbezugChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const PersonenbezugChangeHistorListDocument = gql`
  query PersonenbezugChangeHistorList($rechtstraegerId: String!, $personenbezugId: ID!) {
    getPersonenbezugChangeHistoryList(rechtstraegerId: $rechtstraegerId, personenbezugId: $personenbezugId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function usePersonenbezugChangeHistorListQuery(
  baseOptions: Apollo.QueryHookOptions<PersonenbezugChangeHistorListQuery, PersonenbezugChangeHistorListQueryVariables> &
    ({ variables: PersonenbezugChangeHistorListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonenbezugChangeHistorListQuery, PersonenbezugChangeHistorListQueryVariables>(
    PersonenbezugChangeHistorListDocument,
    options
  );
}
export function usePersonenbezugChangeHistorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonenbezugChangeHistorListQuery, PersonenbezugChangeHistorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonenbezugChangeHistorListQuery, PersonenbezugChangeHistorListQueryVariables>(
    PersonenbezugChangeHistorListDocument,
    options
  );
}
export function usePersonenbezugChangeHistorListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PersonenbezugChangeHistorListQuery, PersonenbezugChangeHistorListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PersonenbezugChangeHistorListQuery, PersonenbezugChangeHistorListQueryVariables>(
    PersonenbezugChangeHistorListDocument,
    options
  );
}
export type PersonenbezugChangeHistorListQueryHookResult = ReturnType<typeof usePersonenbezugChangeHistorListQuery>;
export type PersonenbezugChangeHistorListLazyQueryHookResult = ReturnType<typeof usePersonenbezugChangeHistorListLazyQuery>;
export type PersonenbezugChangeHistorListSuspenseQueryHookResult = ReturnType<typeof usePersonenbezugChangeHistorListSuspenseQuery>;
export type PersonenbezugChangeHistorListQueryResult = Apollo.QueryResult<
  PersonenbezugChangeHistorListQuery,
  PersonenbezugChangeHistorListQueryVariables
>;
