import { FormFields } from '../../../../../../../helpers/formikHelper';
import {
  BuchungsdetailsType,
  ConsolidateEinnahmenType,
  ObjektAbrechnungOutputOptions,
  ObjektAbrechnungOutputOptionsInput,
} from '../../../../../../../types';

export type ObjAbrDefGeneralSettingsFormValues = {
  displayEinnahmen: boolean;
  consolidateEinnahmen?: ConsolidateEinnahmenType | null;
  displayKontonummer: boolean;
  buchungsdetails: BuchungsdetailsType;
};

export const objAbrDefGeneralSettingsFormFields: FormFields<ObjAbrDefGeneralSettingsFormValues> = {
  displayEinnahmen: 'displayEinnahmen',
  consolidateEinnahmen: 'consolidateEinnahmen',
  displayKontonummer: 'displayKontonummer',
  buchungsdetails: 'buchungsdetails',
};

export const objAbrDefGeneralSettingsFormInitialValues: ObjAbrDefGeneralSettingsFormValues = {
  displayEinnahmen: true,
  consolidateEinnahmen: ConsolidateEinnahmenType.MonthlyTotal,
  displayKontonummer: false,
  buchungsdetails: BuchungsdetailsType.DisplayBuchungszeilen,
};

export const mapObjAbrDefOutputOptionsToFormValues = (
  objektAbrechnungOutputOptions: ObjektAbrechnungOutputOptions
): ObjektAbrechnungOutputOptionsInput => ({
  displayEinnahmen: objektAbrechnungOutputOptions.displayEinnahmen,
  consolidateEinnahmen: objektAbrechnungOutputOptions.consolidateEinnahmen?.value ?? ConsolidateEinnahmenType.MonthlyTotal,
  displayKontonummer: objektAbrechnungOutputOptions.displayKontonummer,
  buchungsdetails: objektAbrechnungOutputOptions.buchungsdetails.value,
});

export const mapObjAbrDefGenSetFormValuesToOutputOptions = (
  generalSettingsValues: ObjAbrDefGeneralSettingsFormValues
): ObjektAbrechnungOutputOptionsInput => ({
  displayEinnahmen: generalSettingsValues.displayEinnahmen,
  consolidateEinnahmen: generalSettingsValues.displayEinnahmen ? generalSettingsValues.consolidateEinnahmen : null,
  displayKontonummer: generalSettingsValues.displayKontonummer,
  buchungsdetails: generalSettingsValues.buchungsdetails,
});
