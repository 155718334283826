import React, { FC } from 'react';
import { Spacer } from '../../../components/Grid';
import { Address } from '../../../types';
import AddressWithActionsCard from '../../../shared/components/Address/form/AddressDynamicFormItem/AddressWithActionsCard';

type Props = {
  addressList: Address[];
  removeAddress: (index: number) => () => void;
  getEditFocusOnAddress: (address: Address, index: number) => () => void;
};

const AddressWithActionsCardList: FC<Props> = ({ addressList = [], removeAddress, getEditFocusOnAddress }) => (
  <>
    {addressList.map((address, index) => (
      <React.Fragment key={address.addressId || index}>
        <AddressWithActionsCard
          address={address}
          removeAddress={removeAddress(index)}
          getEditFocusOnAddress={getEditFocusOnAddress(address, index)}
        />
        <Spacer height={16} />
      </React.Fragment>
    ))}
  </>
);

export default AddressWithActionsCardList;
