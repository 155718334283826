export const ROOT_URL_FIRMENDATEN = '/api/firmendaten';
export const ROOT_URL_MITARBEITER = '/api/mitarbeiter';
export const ROOT_URL_FIRMENDATEN_VALIDATION = '/api/firmendaten-validation';
export const ROOT_URL_VALIDATION = '/api/validation';
const ROOT_URL_KURZBEZEICHNUNG_VORSCHLAG = '/api/kurzbezeichnung-vorschlag';
export const URL_KURZBEZEICHNUNG_VORSCHLAG_FIRMENDATEN = `${ROOT_URL_KURZBEZEICHNUNG_VORSCHLAG}/firmendaten`;
export const URL_KURZBEZEICHNUNG_VORSCHLAG_NATUERLICHE_PERSON = `${ROOT_URL_KURZBEZEICHNUNG_VORSCHLAG}/natuerliche-person`;
export const URL_KURZBEZEICHNUNG_VORSCHLAG_FIRMA = `${ROOT_URL_KURZBEZEICHNUNG_VORSCHLAG}/firma`;
export const URL_KURZBEZEICHNUNG_VORSCHLAG_PERSONEN_GEMEINSCHAFT = `${ROOT_URL_KURZBEZEICHNUNG_VORSCHLAG}/personen-gemeinschaft`;

export const ROOT_URL_PASSWORD_RESET = '/api/password-reset';
export const ROOT_URL_EMAIL_VERIFICATION = '/api/email-verification';

export const rootUrlEmailVerification = (token: string) => `${ROOT_URL_EMAIL_VERIFICATION}/${token}`;
export const rootUrlRegistration = (token: string) => `/api/registration/${token}`;
export const rootUrlPasswordResetToken = (passwordResetToken: string) => `/api/password-reset/${passwordResetToken}`;

export const rootUrlRechtstraeger = (firmendatenId: string) => `${ROOT_URL_FIRMENDATEN}/${firmendatenId}/rechtstraeger`;

// ##### MITARBEITER #####
export const rootUrlMitarbeiter = (firmendatenId: string) => `${ROOT_URL_FIRMENDATEN}/${firmendatenId}/mitarbeiter`;
export const urlMitarbeiter = (firmendatenId: string, mitarbeiterId: string, includeUser?: any) =>
  `${rootUrlMitarbeiter(firmendatenId)}/${mitarbeiterId}${addParameterIncludeUserIfDefined(includeUser)}`;
export const urlMitarbeiterMe = (firmendatenId: string, includeUser?: any) =>
  `${rootUrlMitarbeiter(firmendatenId)}/me${addParameterIncludeUserIfDefined(includeUser)}`;
export const addParameterIncludeUserIfDefined = (includeUser: any) => (includeUser ? '?includeUser=true' : '');

// ##### FIRMENDATEN #####
export const rootUriFirmendaten = (firmendatenId: string) => `${ROOT_URL_FIRMENDATEN}/${firmendatenId}`;

// ##### FIRMENDATEN-OPERATING_SITE #####
export const rootUriOperatingSite = (firmendatenId: string) => `${rootUriFirmendaten(firmendatenId)}/betriebsstaetten`;
export const uriOperatingSite = (firmendatenId: string, operatingSiteId: string) => `${rootUriOperatingSite(firmendatenId)}/${operatingSiteId}`;

// ##### NATUERLICHE PERSON #####
export const rootUriRechtstraegerNatuerlichePerson = (firmendatenId: string) => `${ROOT_URL_FIRMENDATEN}/${firmendatenId}/natuerliche-person`;

export const uriRechtstraegerNatuerlichePerson = (firmendatenId: string, natuerlichePersonId: string) =>
  `${rootUriRechtstraegerNatuerlichePerson(firmendatenId)}/${natuerlichePersonId}`;

export const rootRechtstraegerNatuerlichePersonAddress = (firmendatenId: string, rechtstraegerId: string) =>
  `${rootUriFirmendaten(firmendatenId)}/natuerliche-person/${rechtstraegerId}/adressen`;

export const uriRechtstraegerNatuerlichePersonAddress = (firmendatenId: string, rechtstraegerId: string, addressId: string) =>
  `${rootRechtstraegerNatuerlichePersonAddress(firmendatenId, rechtstraegerId)}/${addressId}`;

// ##### PERSONENGEMEINSCHAFT #####
export const rootUriRechtstraegerPersonenGemeinschaft = (firmendatenId: string) => `${ROOT_URL_FIRMENDATEN}/${firmendatenId}/personen-gemeinschaft`;

export const uriRechtstraegerPersonenGemeinschaft = (firmendatenId: string, rechtstraegerId: string) =>
  `${rootUriRechtstraegerPersonenGemeinschaft(firmendatenId)}/${rechtstraegerId}`;

export const rootRechtstraegerPersonenGemeinschaftAddress = (firmendatenId: string, rechtstraegerId: string) =>
  `${rootUriFirmendaten(firmendatenId)}/personen-gemeinschaft/${rechtstraegerId}/adressen`;

export const uriRechtstraegerPersonenGemeinschaftAddress = (firmendatenId: string, rechtstraegerId: string, addressId: string) =>
  `${rootRechtstraegerPersonenGemeinschaftAddress(firmendatenId, rechtstraegerId)}/${addressId}`;

// ##### FIRMA #####
export const rootUriRechtstraegerFirma = (firmendatenId: string) => `${ROOT_URL_FIRMENDATEN}/${firmendatenId}/firma`;

export const uriRechtstraegerFirma = (firmendatenId: string, rechtstraegerId: string) =>
  `${rootUriRechtstraegerFirma(firmendatenId)}/${rechtstraegerId}`;

export const rootRechtstraegerFirmaOperatingSite = (firmendatenId: string, rechtstraegerId: string) =>
  `${rootUriFirmendaten(firmendatenId)}/firma/${rechtstraegerId}/betriebsstaetten`;

export const uriRechtstraegerFirmaOperatingSite = (firmendatenId: string, rechtstraegerId: string, operatingSiteId: string) =>
  `${rootRechtstraegerFirmaOperatingSite(firmendatenId, rechtstraegerId)}/${operatingSiteId}`;
