import { Dayjs } from 'dayjs';
import { FormFields, mapFormDateValueToDateString } from '../../../../../../helpers/formikHelper';
import { PaymentListEntry, PaymentUpdateInput } from '../../../../../../types';

// Define the form values
export type PaymentUpdateFormValues = {
  amount?: number | null;
  paymentDate: Dayjs | string;
  paymentId: string;
};

// Define the form fields
export const paymentUpdateFormFields: FormFields<PaymentUpdateFormValues> = {
  amount: 'amount',
  paymentDate: 'paymentDate',
  paymentId: 'paymentId',
};

// Define the mapper functions
export const mapPaymentToUpdateFormValues = (payment: PaymentListEntry): PaymentUpdateFormValues => ({
  amount: payment.amount,
  paymentDate: payment.paymentDate,
  paymentId: payment.paymentId,
});

export const mapFormValuesToPayment = (values: PaymentUpdateFormValues): PaymentUpdateInput => ({
  amount: values.amount,
  paymentDate: mapFormDateValueToDateString(values.paymentDate),
});
