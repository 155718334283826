import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import {
  GrundstueckdsdatenCreateFormValues,
  grundstuecksdatenCreateFormFields,
  grundstuecksdatenCreateFormInitialValues,
} from './grundstueckdsdatenCreateFormMapper';
import FormButtons from '../../../../../../components/Button/FormButtons';
import GrundstuecksdatenDatenTab from '../Tabs/GrundstuecksdatenDatenTab';
import { ObjektInfoFeldType } from '../../../../../../types';
import { handleCreateSubmit } from '../../shared/handleSubmit';
import { grundstuecksdatenValidationSchema } from '../shared/grundstuecksdatenValidationSchema';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';

type Props = {
  objektId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const GrundstuecksdatenCreateForm: FC<Props> = ({ objektId, onCancel, onSuccess }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<GrundstueckdsdatenCreateFormValues>
      initialValues={grundstuecksdatenCreateFormInitialValues}
      validationSchema={grundstuecksdatenValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleCreateSubmit(firmendatenId, objektId, ObjektInfoFeldType.Grundstuecksdaten, values, setSubmitting, onSuccess);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <GrundstuecksdatenDatenTab formFields={grundstuecksdatenCreateFormFields} />
          <InfoFeldUpload
            name={grundstuecksdatenCreateFormFields.documentList}
            formikProps={formikProps}
            documentList={formikProps.values.documentList}
          />
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default GrundstuecksdatenCreateForm;
