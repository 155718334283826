import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../../styles/pdfStyles';
import { paddingLeftForRow, widthRowWithoutAnteil } from './details-table-styles';
import { PositionData } from '../../../hauseigentuemerAbrechnung-types';
import PositionKontoListing from '../../shared/PositionKontoListing';
import AnteilVonSumme from './AnteilVonSumme';
import HeABlockSumme from '../../shared/HeABlockSumme';
import LabelValue from '../../../../shared/LabelValue';

const HeAPositionBlock: FC<{ data: PositionData }> = ({ data }) => (
  <View style={[pdfStyles.column, { paddingTop: '5mm' }]}>
    <View style={pdfStyles.row}>
      <Text style={[pdfStyles.textNormal, { fontWeight: 'bold', textDecoration: 'underline' }]}>{data.positionName}</Text>
    </View>

    {/* AbrKreis Listing */}
    <View style={paddingLeftForRow.abrKreis}>
      {data.abrKreisList.map((abrKreis, index) => (
        <View key={index} style={[pdfStyles.column, { width: widthRowWithoutAnteil }]}>
          <LabelValue valueTuple={abrKreis.abrKreis} viewStyle={{ fontWeight: 'bold' }} />
          <PositionKontoListing kontoList={abrKreis.kontoList} />
        </View>
      ))}
      {/* Anteil von Summe */}
      <AnteilVonSumme data={data.anteilVonSumme} />
    </View>

    {/* Summe */}
    <HeABlockSumme data={data.summePosition} />
  </View>
);

export default HeAPositionBlock;
