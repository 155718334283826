import React, { FC } from 'react';
import { Descriptions, Space, Typography } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';

import { Box } from 'rebass';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import theme from '../../../theme';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import { Spacer } from '../../../components/Grid';
import ErrorMessagePanel from '../../../components/Error/ErrorMessagePanel';
import { useToggle } from '../../../hooks/useToggle';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import { TInvoice } from '../Form/incomingInvoiceFormMapper';
import { isInvoiceTypeSingleInvoice } from '../Form/incomingInvoiceFormHelpers';
import { SingleInvoice, StandingInvoice } from '../../../types';
import SingleInvoiceChangeHistoryListingTable from '../Form/shared/SingleInvoiceChangeHistoryListingTable';
import StandingInvoiceChangeHistoryListingTable from '../Form/shared/StandingInvoiceChangeHistoryListingTable';
import DocumentStatusTag from '../../../components/Status/DocumentStatus/DocumentStatusTag';

type Props = {
  incomingInvoice: TInvoice;
  belegId: string;
};

const IncomingInvoiceDetailsRechnungBlock: FC<Props> = ({ incomingInvoice, belegId }) => {
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  let singleInvoiceContent;

  if (isInvoiceTypeSingleInvoice(incomingInvoice.type)) {
    const singleInvoice = incomingInvoice as SingleInvoice;
    singleInvoiceContent = (
      <>
        <Descriptions.Item label="Fällig am">
          <Box mx="auto" />
          <CustomFormattedDate value={singleInvoice.dueDate} />
        </Descriptions.Item>
        {singleInvoice.abgrenzungsBuchung ? (
          <>
            <Descriptions.Item label="Buchung mit Aufwand Abgrenzung - Buchungsdatum">
              <Box mx="auto" />
              <CustomFormattedDate value={singleInvoice.buchungsdatum} />
            </Descriptions.Item>
            <Descriptions.Item label="Aufwand Buchung der Aufwand Abgrenzung - Buchungsdatum">
              <Box mx="auto" />
              {singleInvoice.abgrenzungsBuchungsdatum ? <CustomFormattedDate value={singleInvoice.abgrenzungsBuchungsdatum} /> : ''}
            </Descriptions.Item>
          </>
        ) : (
          <Descriptions.Item label="Buchungsdatum">
            <Box mx="auto" />
            <CustomFormattedDate value={singleInvoice.buchungsdatum} />
          </Descriptions.Item>
        )}
      </>
    );
  }

  return (
    <Descriptions
      labelStyle={{ color: theme.textColor.secondary }}
      bordered={false}
      colon={false}
      column={1}
      layout="horizontal"
      title={
        <Space style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography.Text>
            Rechnungsdetails <DocumentStatusTag documentStatus={incomingInvoice.status} />
          </Typography.Text>
          <HistoryOutlined style={radixActionStyles} onClick={toggleChangeHistoryOpen} />
          <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
            {isInvoiceTypeSingleInvoice(incomingInvoice.type) ? (
              <SingleInvoiceChangeHistoryListingTable belegId={belegId} />
            ) : (
              <StandingInvoiceChangeHistoryListingTable belegId={belegId} />
            )}
          </HistoryModal>
        </Space>
      }
      size="small"
    >
      {getBuchungErrorList(incomingInvoice).length > 0 && (
        <Descriptions.Item>
          <Spacer />
          <ErrorMessagePanel error={getBuchungErrorList(incomingInvoice)} />
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Verrechnungsart">
        <Box mx="auto" />
        {incomingInvoice.billingType.text}
      </Descriptions.Item>
      <Descriptions.Item label="Lieferant">
        <Box mx="auto" />
        {incomingInvoice.ausstellerBezeichnung}
      </Descriptions.Item>
      <Descriptions.Item label="Empfänger">
        <Box mx="auto" />
        {incomingInvoice.payeeBezeichnung}
      </Descriptions.Item>
      <Descriptions.Item label="Objekt">
        <Box mx="auto" />
        {incomingInvoice.objektBezeichnung}
      </Descriptions.Item>
      <Descriptions.Item label="Kunden Nr.">
        <Box mx="auto" />
        {incomingInvoice.kundenNummer}
      </Descriptions.Item>
      <Descriptions.Item label="Rechnungs Nr.">
        <Box mx="auto" />
        {incomingInvoice.rechnungsNummer}
      </Descriptions.Item>
      <Descriptions.Item label="Rechnungsdatum">
        <Box mx="auto" />
        <CustomFormattedDate value={incomingInvoice.rechnungsDatum} />
      </Descriptions.Item>
      {singleInvoiceContent}
    </Descriptions>
  );
};

const getBuchungErrorList = (rechnung: TInvoice) => {
  if (isInvoiceTypeSingleInvoice(rechnung.type)) {
    return (rechnung as SingleInvoice).buchungErrorList;
  }
  return (rechnung as StandingInvoice).partialAmountList.map((partialAmount) => partialAmount.buchungErrorList).flat();
};

export default IncomingInvoiceDetailsRechnungBlock;
