import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { useHeAbrechnungDeviationListQuery } from './gql/HeAbrechnungDeviationQueries.types';
import {
  useOnHeAbrechnungHeVertragDataChangedEvents,
  useOnHeAbrechnungHeVertragDetailsDataChangedEvents,
} from '../../He/useOnHeAbrechnungDataChangeEvent';
import { showSuccessMsgDeviationsUebernehmen } from '../../../../components/message/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useHeAbrechnungApplyAllDeviationsMutation } from './gql/HeAbrechnungDeviationMutations.types';
import { DEVIATIONS_TAB_TITLE } from '../../../../components/Deviation/deviationHelpers';
import DeviationTab from '../../../../components/Deviation/Tab/DeviationTab';
import { pathsToHeAbrechnungDetails } from '../../He/heAbrechnungUriPaths';
import { AbrechnungStatusTuple } from '../../../../types';

type Props = {
  objektId: string;
  heAbrechnungId: string;
  status: AbrechnungStatusTuple;
  onAction: () => void;
};

const HeAbrechnungDeviationList: FC<Props> = ({ objektId, heAbrechnungId, status, onAction }) => {
  const { data, refetch } = useHeAbrechnungDeviationListQuery({
    variables: {
      objektId,
      heAbrechnungId,
    },
  });

  const [runApplyAllDeviations] = useHeAbrechnungApplyAllDeviationsMutation({
    onCompleted: () => {
      showSuccessMsgDeviationsUebernehmen();
      refetch();
      onAction();
    },
    variables: {
      objektId,
      heAbrechnungId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const heAbrechnungDeviationList = data?.getHeAbrechnungDeviationList.data;
  const loading = !heAbrechnungDeviationList;

  useOnHeAbrechnungHeVertragDataChangedEvents('heAbrechnungDeviationList', refetch);
  useOnHeAbrechnungHeVertragDetailsDataChangedEvents('heAbrechnungDeviationList', refetch);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: DEVIATIONS_TAB_TITLE,
      children: (
        <DeviationTab
          dataSource={heAbrechnungDeviationList}
          loading={loading}
          status={status}
          showSourceEntity
          runApplyAllDeviations={runApplyAllDeviations}
          reviewErrorUriPath={pathsToHeAbrechnungDetails(objektId, heAbrechnungId).heAbrTab}
        />
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default HeAbrechnungDeviationList;
