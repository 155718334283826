import React, { FC } from 'react';
import { Divider, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { TicketBase } from '../../../types';
import CardWithFooter from '../../../components/Card/CardWithFooter';
import CardTitle from '../../../components/Card/CardTitle';
import TicketCardActions from './TicketCardActions';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import TicketPriorityTag from '../shared/TicketPriorityTag';
import TicketReminder from '../shared/TicketReminder';
import MitarbeiterOrUserName from '../../../components/Card/MitarbeiterOrUserName';
import TicketObjektInfoRows from './TicketObjektInfoRows';
import TicketRechtstraegerInfoRows from './TicketRechtstraegerInfoRows';
import TicketAttachmentsInfoRow from './TicketAttachmentsInfoRow';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { generatePathToTicketDetailsPage } from '../ticketUriPaths';
import { TTicket } from '../ticketHelpers';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import TicketParticipantsInfoRow from './TicketParticipantsInfoRow/TicketParticipantsInfoRow';
import TicketCommunicationInfoRow from './TicketCommunicationInfoRow';

type Props = {
  ticket: TTicket;
  loading: boolean;
  refetch: () => void;
};

const TicketCard: FC<Props> = ({ ticket, loading, refetch }) => {
  return (
    <CardWithFooter
      loading={loading}
      style={{ minWidth: '320px' }}
      size="default"
      createTs={ticket.createTs}
      userId={ticket.createdBy}
      mitarbeiterId={ticket.createdByMitarbeiterId}
      title={<CardTitle title={ticket.title} status={ticket.ticketStatus} />}
      extra={<TicketCardActions ticket={ticket} onAction={refetch} />}
    >
      {ticket.parent && (
        <>
          <CardDetailsInfoRow infoRowTitle="Hauptaufgabe">
            <DataWithShortenedText maxTextLength={25} text={ticket.parent.title}>
              {(shortenedText) => (
                <Link target="_blank" to={generatePathToTicketDetailsPage(ticket.parent as TicketBase)}>
                  {shortenedText}
                </Link>
              )}
            </DataWithShortenedText>
          </CardDetailsInfoRow>
          <Divider dashed />
        </>
      )}

      <CardDetailsInfoRow infoRowTitle="Typ">
        <Typography.Text>{!ticket.isSubTicket ? 'Hauptaufgabe' : 'Unteraufgabe'}</Typography.Text>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Art">
        {ticket.typeName ? <DataWithShortenedText text={ticket.typeName} maxTextLength={15} /> : <TextForEmptyValue textToShow="minus" />}
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Priorität">
        <TicketPriorityTag priority={ticket.priority} />
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Fälligkeit">
        <CustomFormattedDate value={ticket.dueDate} />
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Erinnerung">
        <TicketReminder ticket={ticket} />
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Bearbeiter">
        <MitarbeiterOrUserName mitarbeiterId={ticket.assignee.mitarbeiterId} />
      </CardDetailsInfoRow>
      <Divider dashed />
      {ticket.objekt && (
        <>
          <TicketObjektInfoRows ticket={ticket} />
          <Divider dashed />
        </>
      )}
      {ticket.rechtstraeger && (
        <>
          <TicketRechtstraegerInfoRows ticket={ticket} />
          <Divider dashed />
        </>
      )}
      <TicketParticipantsInfoRow ticket={ticket} refetch={refetch} />
      <Divider dashed />

      <TicketCommunicationInfoRow ticket={ticket} refetch={refetch} />
      <Divider dashed />

      <TicketAttachmentsInfoRow ticket={ticket} refetch={refetch} />
    </CardWithFooter>
  );
};

export default TicketCard;
