import React, { FC } from 'react';
import { Space, Tag } from 'antd';
import { getTagColor } from '../../../../components/Tag/tagHelpers';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  tagList: string[];
};
const KontoBezugOutputTags: FC<Props> = ({ tagList }) => {
  const tagElem = (shortenedText: string, fullText: string) => (
    <Tag key={fullText} closable={false} color={getTagColor(fullText)}>
      {shortenedText}
    </Tag>
  );

  const tags = tagList.map((tag) => (
    <DataWithShortenedText maxTextLength={10} text={tag} key={tag}>
      {(shortenedText) => tagElem(shortenedText, tag)}
    </DataWithShortenedText>
  ));

  return <Space size={4}>{tags}</Space>;
};

export default KontoBezugOutputTags;
