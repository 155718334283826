import styled from 'styled-components';

export const StyledHeAbrHeVertragKontoRowTable = styled('table')`
  thead {
    th {
      &:not(:first-child) {
        text-align: right !important;
      }
      &:nth-last-child(2) {
        width: 10.5%;
      }
      &:last-child {
        width: 22%;
      }
    }
  }
  tbody {
    tr {
      & td {
        &:nth-last-child(2) {
          width: 10.5%;
        }
        &:last-child {
          width: 22%;
        }
      }
      &:last-child td {
        border: 0;
      }
    }
  }
`;
