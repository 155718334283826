import { NutzungsArt, Steuerpflicht, UstRegelset, UstRegelsetCreateInput, UstRegelsetUpdateInput } from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';

export interface UstRegelsetFormValues {
  bezeichnung: string;
  countryCodeIso2: string;
  eigennutzung?: boolean;
  leerstehung?: boolean;
  nutzungsArt: NutzungsArt | null;
  optionSteuerpflicht?: boolean;
  steuerpflicht: Steuerpflicht | null;
  ustKategorieId: string;
  validFrom: string;
}

export const ustRegelsetFormFields: FormFields<UstRegelsetFormValues> = {
  bezeichnung: 'bezeichnung',
  countryCodeIso2: 'countryCodeIso2',
  eigennutzung: 'eigennutzung',
  leerstehung: 'leerstehung',
  nutzungsArt: 'nutzungsArt',
  optionSteuerpflicht: 'optionSteuerpflicht',
  steuerpflicht: 'steuerpflicht',
  ustKategorieId: 'ustKategorieId',
  validFrom: 'validFrom',
};

const ustRegelsetFormInitialValues: UstRegelsetFormValues = {
  bezeichnung: '',
  countryCodeIso2: 'AT',
  eigennutzung: false,
  leerstehung: false,
  nutzungsArt: null,
  optionSteuerpflicht: false,
  steuerpflicht: null,
  ustKategorieId: '',
  validFrom: '',
};

export const mapUstRegelsetToFormValues = (ustRegelset?: UstRegelset): UstRegelsetFormValues => {
  if (!ustRegelset) {
    return ustRegelsetFormInitialValues;
  }

  return {
    bezeichnung: ustRegelset.bezeichnung,
    countryCodeIso2: ustRegelset.countryCodeIso2,
    eigennutzung: ustRegelset.eigennutzung,
    leerstehung: ustRegelset.leerstehung,
    nutzungsArt: ustRegelset.nutzungsArt.value,
    optionSteuerpflicht: ustRegelset.optionSteuerpflicht,
    steuerpflicht: ustRegelset.steuerpflicht.value,
    ustKategorieId: '',
    validFrom: '',
  };
};

export const mapFormValuesToUstRegelsetCreate = (values: UstRegelsetFormValues): UstRegelsetCreateInput => ({
  ...mapFormValuesToUstRegelsetUpdate(values),
  ustKategorieId: values.ustKategorieId,
  validFrom: values.validFrom,
});

export const mapFormValuesToUstRegelsetUpdate = (values: UstRegelsetFormValues): UstRegelsetUpdateInput => ({
  bezeichnung: values.bezeichnung,
  countryCodeIso2: values.countryCodeIso2,
  eigennutzung: values.eigennutzung,
  leerstehung: values.leerstehung,
  nutzungsArt: values.nutzungsArt as NutzungsArt,
  optionSteuerpflicht: values.optionSteuerpflicht,
  steuerpflicht: values.steuerpflicht as Steuerpflicht,
});
