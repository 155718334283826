import React, { FC } from 'react';
import { Typography } from 'antd';
import { Select, SelectProps } from 'formik-antd';
import { useField } from 'formik';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { isNil } from '../../../helpers/assertionHelper';
import { AbrechdefAbrechkreis } from '../../../types';
import { useAufteilungsschluesselTemplateListQuery } from '../../AufteilungsschluesselTemplate/gql/AufteilungsschluesselTemplateQueries.types';

type Props = {
  abrechnungskreisId: string;
  nameForAufteilungsschluesselBezeichnung: string;
  abrDefVersionAbrechkreisList: AbrechdefAbrechkreis[];
} & SelectProps;

const AbrKontenzuordnungAufteilungsschluesselTemplateSelect: FC<Props> = ({
  name,
  abrechnungskreisId,
  abrDefVersionAbrechkreisList,
  nameForAufteilungsschluesselBezeichnung,
  ...restProps
}) => {
  const { loading, data } = useAufteilungsschluesselTemplateListQuery({
    variables: {
      includeArchiviert: true,
    },
  });

  const aufteilungsschluesselList = data?.getAufteilungsschluesselTemplateList.data ?? [];

  const [, , { setValue }] = useField(name);
  const [, , { setValue: setBezeichnungValue }] = useField(nameForAufteilungsschluesselBezeichnung);

  const onChange = (value: string) => {
    if (isNil(value)) {
      setValue(DEFAULT_AUFTEILUNGSSCHLUESSEL);
    } else {
      setValue(value);
      const aufteilungsschluessel = aufteilungsschluesselList.find((auftSchluessel) => auftSchluessel.aufteilungsschluesselId === value);
      setBezeichnungValue(aufteilungsschluessel?.kurzBezeichnung);
    }
  };

  const currentAbrDefVersion = abrDefVersionAbrechkreisList.find(
    (abrDefVersion) => abrDefVersion.abrechnungskreis.abrechnungskreisId === abrechnungskreisId
  );

  const currentAbrDefVersionAufteilungsschluessel = aufteilungsschluesselList.find(
    (aufteilungsschluessel) => aufteilungsschluessel.aufteilungsschluesselId === currentAbrDefVersion?.aufteilungsschluessel?.aufteilungsschluesselId
  );

  const defaultAufteilungsschluessel = {
    aufteilungsschluesselId: currentAbrDefVersionAufteilungsschluessel?.aufteilungsschluesselId,
    kurzBezeichnung: currentAbrDefVersionAufteilungsschluessel?.kurzBezeichnung,
  };

  return (
    <Select
      name={name}
      loading={loading}
      size="small"
      allowClear
      showSearch
      onChange={onChange}
      defaultValue={DEFAULT_AUFTEILUNGSSCHLUESSEL}
      {...restProps}
    >
      <Select.Option key={DEFAULT_AUFTEILUNGSSCHLUESSEL} value={DEFAULT_AUFTEILUNGSSCHLUESSEL}>
        <Typography.Text>{`${defaultAufteilungsschluessel.kurzBezeichnung} (aus Abrechnungskreis)`}</Typography.Text>
      </Select.Option>
      {aufteilungsschluesselList
        ?.filter((aufteilungsschluessel) => defaultAufteilungsschluessel.aufteilungsschluesselId !== aufteilungsschluessel.aufteilungsschluesselId)
        .map((aufteilungsschluessel) => {
          return (
            <Select.Option key={aufteilungsschluessel.aufteilungsschluesselId} value={aufteilungsschluessel.aufteilungsschluesselId}>
              <DataWithShortenedText maxTextLength={40} text={aufteilungsschluessel.kurzBezeichnung} />
            </Select.Option>
          );
        })}
    </Select>
  );
};

export const DEFAULT_AUFTEILUNGSSCHLUESSEL = 'defaultAufteilungsschluessel';

export default AbrKontenzuordnungAufteilungsschluesselTemplateSelect;
