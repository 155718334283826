import React, { FC, useEffect } from 'react';
import { FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import { Spacer } from '../../../../../components/Grid';
import { AuszifferungBlockFormValues } from './auszifferungFormMapper';
import AuszifferungSaveButton from './AuszifferungSaveButton';
import AvailableAmount from './AvailableAmount';
import OffenePostenListTableFromBooking from './Listings/AzListingFromBooking/Level1/OffenePostenListTableFromBooking';
import OffenePostenForPersonenkontoListing from './Listings/OffenePostenListingForPersonenkonto/OffenePostenForPersonenkontoListing';
import { FibuBuchungType } from '../../../../../types';
import { hasOPListExistingNotStorniertAuszifferung, hasOPListNewAuszifferung } from './auszifferungFormHelpers';
import DividerForBooking from '../../../shared/DividerForBooking';
import SpaceForBookingBlockAndForm from '../../../shared/SpaceForBookingBlockAndForm';

type Props = {
  amountFromPersonenkonto: number;
  bookingCorrectionInProgress: boolean | 'done';
  bookingZahlungId: string;
  buchungskreisRechtstraegerId?: string;
  buchungType: FibuBuchungType;
  formikProps: FormikProps<AuszifferungBlockFormValues>;
  setHasBookingExistingAuszifferung?: React.Dispatch<React.SetStateAction<boolean>>;
  personenkontoId?: string;
};

const AuszifferungForm: FC<Props> = ({
  amountFromPersonenkonto,
  bookingCorrectionInProgress,
  bookingZahlungId,
  buchungskreisRechtstraegerId,
  buchungType,
  formikProps,
  setHasBookingExistingAuszifferung,
  personenkontoId,
}) => {
  useEffect(() => {
    setHasBookingExistingAuszifferung?.(hasOPListExistingNotStorniertAuszifferung(bookingZahlungId, formikProps.values.offenePostenListFromBooking));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikProps.values.offenePostenListFromBooking]);

  return (
    <Form layout="vertical">
      <SpaceForBookingBlockAndForm>
        {/* block 1 - Block title and Button for saving new Auszifferung list */}
        <Row align="stretch">
          <Col span={18}>
            <Typography.Title level={5}>Auszifferung</Typography.Title>
          </Col>
          <Col span={6}>
            <AuszifferungSaveButton
              onSubmit={formikProps.submitForm}
              disabled={!hasOPListNewAuszifferung(formikProps.values.offenePostenListFromBooking)}
              // showNotSavedWarning={isFormDirty} // TODO
            />
          </Col>
        </Row>
        <Spacer height={8} />

        {/* block 2 - Available Amount */}
        <AvailableAmount amountFromPersonenkonto={amountFromPersonenkonto} bookingZahlungId={bookingZahlungId} formikProps={formikProps} />

        {/* block 3 - Auszifferung listing from Booking, Auszifferung listing for new entries  */}
        <OffenePostenListTableFromBooking bookingZahlungId={bookingZahlungId} buchungType={buchungType} formikProps={formikProps} />

        {/* block 4 - Offene Posten listing for Personenkonto - render this only if available amount > 0 */}
        {!bookingCorrectionInProgress &&
          (formikProps.values.availableAmount !== 0 || hasOPListNewAuszifferung(formikProps.values.offenePostenListFromBooking)) && (
            <>
              <Spacer height={16} />
              <DividerForBooking dashed />

              <Typography.Title level={5}>Offene Posten</Typography.Title>
              {/* Filter and Table - data fetched separately from BE */}
              {personenkontoId && buchungskreisRechtstraegerId ? (
                <OffenePostenForPersonenkontoListing
                  bookingZahlungId={bookingZahlungId}
                  buchungskreisRechtstraegerId={buchungskreisRechtstraegerId}
                  buchungType={buchungType}
                  formikProps={formikProps}
                  personenkontoId={personenkontoId}
                />
              ) : (
                <Typography.Text type="danger">Kein Personenkonto in Buchungszeilen gefunden!</Typography.Text>
              )}
            </>
          )}
      </SpaceForBookingBlockAndForm>
    </Form>
  );
};

export default AuszifferungForm;
