import * as Yup from 'yup';
import { spracheValidationSchema } from '../../Sprache/spracheValidationSchema';
import { personsCommunityTypeValidationSchema } from '../PersonsCommunityTypeSelect/personsCommunityTypeValidationSchema';
import { salutationValidationSchema } from '../../../../shared/components/Person/personFormValidationSchema';
import { tempHauptAddressValidationSchemaOptional } from '../../HauptAddress/hauptAddressOptionalFormPartValidationSchema';
import { firmennameFormPartValidationSchema } from '../../../../shared/components/Firmenname/form/firmennameFormPartValidationSchema';
import { kurzBezeichnungFormPartValidationSchema } from '../../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';

const personenGemeinschaftFormFieldsValidationSchema = Yup.object({
  sprache: spracheValidationSchema,
  communityType: personsCommunityTypeValidationSchema,
  salutation: salutationValidationSchema,
  tempHauptAddress: tempHauptAddressValidationSchemaOptional,
});

export const personenGemeinschaftFormValidationSchema = personenGemeinschaftFormFieldsValidationSchema
  // @ts-ignore
  .concat(firmennameFormPartValidationSchema)
  // @ts-ignore
  .concat(kurzBezeichnungFormPartValidationSchema);
