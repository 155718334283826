import React, { FC } from 'react';
import { Space, Spin } from 'antd';
import VersandCard from './Card/VersandCard';
import VersandErstellenBtnAndDrawer from './VersandErstellenBtnAndDrawer';
import TabPaneContent from '../../../../components/Tabs/TabPaneContent';
import { Spacer } from '../../../../components/Grid';
import { useOnVertragspartnerAdresseDataChangedEvents, useOnVertragspartnerEmailDataChangedEvent } from '../useOnVertragspartnerDataChangedEvents';
import { Dokumentenversand } from '../../../../types';

type VertragDokumentenVersandProps = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  onSuccess: () => void;
  vertragspartnerId: string;
  dokumentenversand?: Dokumentenversand;
};

const VertragDokumentenVersand: FC<VertragDokumentenVersandProps> = ({
  objektId,
  bestandseinheitId,
  vertragId,
  dokumentenversand,
  onSuccess,
  vertragspartnerId,
}) => {
  useOnVertragspartnerAdresseDataChangedEvents('vertragspartnerAdresseInDokumentVersand', onSuccess as any, vertragspartnerId);
  useOnVertragspartnerEmailDataChangedEvent('vertragspartnerEmailInDokumentVersand', onSuccess as any, vertragspartnerId);

  if (!dokumentenversand) {
    return (
      <Space style={{ width: '100%', justifyContent: 'center' }}>
        <Spin />
      </Space>
    );
  }

  return (
    <TabPaneContent distanceToTopBorder="small">
      <Space direction="vertical" style={{ width: '100%' }}>
        <VersandCard
          isOriginal
          dokumentenversandEntry={dokumentenversand.original}
          onSuccess={onSuccess}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          objektId={objektId}
        />
        <Spacer height={16} />
        <VersandErstellenBtnAndDrawer onSuccess={onSuccess} objektId={objektId} bestandseinheitId={bestandseinheitId} vertragId={vertragId} />
        {dokumentenversand.kopieList.length > 0 ? (
          <>
            <Space wrap align="baseline" size="middle">
              {dokumentenversand.kopieList.map((item) => {
                return (
                  <VersandCard
                    key={item.dokumentenversandEntryId}
                    isOriginal={false}
                    dokumentenversandEntry={item}
                    onSuccess={onSuccess}
                    bestandseinheitId={bestandseinheitId}
                    vertragId={vertragId}
                    objektId={objektId}
                  />
                );
              })}
            </Space>
          </>
        ) : null}
      </Space>
    </TabPaneContent>
  );
};

export default VertragDokumentenVersand;
