import { FormFields } from '../../../../../../helpers/formikHelper';
import { AbrechnungsdefinitionType, SetAbrechnungsdefinitionForRechtstraegerInput } from '../../../../../../types';

export type RechnungsAusstellerAbrDefZuweisenFormValues = {
  abrechnungsdefinitionId: string;
  type?: AbrechnungsdefinitionType | null;
};

export const rechnungsAusstellerAbrDefZuweisenFormValuesDefault: RechnungsAusstellerAbrDefZuweisenFormValues = {
  abrechnungsdefinitionId: '',
  type: null,
};

export const rechnungsAusstellerAbrDefZuweisenFormFields: FormFields<RechnungsAusstellerAbrDefZuweisenFormValues> = {
  abrechnungsdefinitionId: 'abrechnungsdefinitionId',
  type: 'type',
};

export const mapFormValuesToAbrDefZuweisen = (
  values: RechnungsAusstellerAbrDefZuweisenFormValues
): SetAbrechnungsdefinitionForRechtstraegerInput => ({
  abrechnungsdefinitionId: values.abrechnungsdefinitionId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  type: values.type!,
});
