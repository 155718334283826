import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { KontoVerwendung } from '../../../../../types';

type Props = {
  name: string;
  loading: boolean;
  kontoVerwendungList: { text: string; value: KontoVerwendung }[];
} & SelectProps;

const KontoVerwendungSelect: FC<Props> = ({ name, loading, kontoVerwendungList, ...restProps }) => (
  <Select {...restProps} name={name} allowClear loading={loading} placeholder="Kontoverwendung auswählen">
    {kontoVerwendungList.map((item) => (
      <Select.Option key={item.text} value={item.value}>
        {item.text}
      </Select.Option>
    ))}
  </Select>
);

export default KontoVerwendungSelect;
