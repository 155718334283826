import { FC } from 'react';
import ObjektBankDetailsButtonAndDrawer from './ObjektBankDetailsButtonAndDrawer';
import ObjektBankDetailsListing from './ObjektBankDetailsListing';
import { ObjektBankDetails, ResponseDataWarning } from '../../../types';

type Props = {
  objektId: string;
  rechtstraegerId: string;
  weRechnungsAusstellerId: string;
  objektBankDetailsList: ObjektBankDetails[];
  onAction: () => void;
  warningList: ResponseDataWarning[];
  weRechnungsAusstellerFibuKontoCreatable: boolean;
};

const WeRechnungsAusstellerBankDetails: FC<Props> = ({
  objektId,
  rechtstraegerId,
  weRechnungsAusstellerId,
  objektBankDetailsList,
  onAction,
  warningList,
  weRechnungsAusstellerFibuKontoCreatable,
}) => {
  const verwendungListTypeInUse = objektBankDetailsList
    .map((objBankDetails) => objBankDetails.verwendungList)
    .flat()
    .map((verwendung) => verwendung.value);

  return (
    <>
      <ObjektBankDetailsButtonAndDrawer
        objektId={objektId}
        onSuccess={onAction}
        rechtstraegerId={rechtstraegerId}
        weRechnungsAusstellerId={weRechnungsAusstellerId}
        verwendungListTypeInUse={verwendungListTypeInUse}
        weRechnungsAusstellerFibuKontoCreatable={weRechnungsAusstellerFibuKontoCreatable}
      />
      <ObjektBankDetailsListing
        objektBankDetailsList={objektBankDetailsList}
        objektId={objektId}
        rechtstraegerId={rechtstraegerId}
        weRechnungsAusstellerId={weRechnungsAusstellerId}
        onAction={onAction}
        verwendungListTypeInUse={verwendungListTypeInUse}
        warningList={warningList}
        weRechnungsAusstellerFibuKontoCreatable={weRechnungsAusstellerFibuKontoCreatable}
      />
    </>
  );
};

export default WeRechnungsAusstellerBankDetails;
