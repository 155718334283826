import React, { FC } from 'react';
import { EuroAmount } from '../../../components/Number';
import { FibuBuchungszeile, SollHaben } from '../../../types';

type Props = {
  index: number;
  buchungszeileList?: FibuBuchungszeile[];
};

const KontoblattTableColumnSaldo: FC<Props> = ({ index, buchungszeileList }) => {
  return <EuroAmount value={sumAllBetragTilCurrentRow(index, buchungszeileList)} />;
};

const sumAllBetragTilCurrentRow = (untilIndexInclusive: number, buchungszeileList?: FibuBuchungszeile[]) => {
  if (!buchungszeileList) {
    return 0;
  }

  return buchungszeileList.reduce((acc, currentBz, currentIndex) => {
    if (currentIndex <= untilIndexInclusive) {
      acc = currentBz.sollHaben.value === SollHaben.Soll ? acc + currentBz.betrag : acc - currentBz.betrag;
    }
    return acc;
  }, 0);
};

export default KontoblattTableColumnSaldo;
