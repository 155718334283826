import { FC } from 'react';
import { useNatuerlichePersonChangeHistoryListQuery } from '../gql/NatuerlichePersonQueries.types';
import { HistoryType } from '../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../History/HistoryListingTable';

type Props = {
  natuerlichePersonId: string;
};

const NatuerlichePersonChangeHistoryListingTable: FC<Props> = ({ natuerlichePersonId }) => {
  const { data, loading } = useNatuerlichePersonChangeHistoryListQuery({ variables: { natuerlichePersonId } });
  const historyList = data?.getNatuerlichePersonChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="natuerliche-person" />;
};

export default NatuerlichePersonChangeHistoryListingTable;
