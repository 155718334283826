import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../styles/pdfStyles';
import { LabeledValue } from '../../shared/types';

const TADueDate: FC<{ dueDate: LabeledValue }> = ({ dueDate }) => (
  <View>
    <Text style={[pdfStyles.textNormal, { width: '100%', textAlign: 'left', paddingTop: '5mm' }]}>
      {dueDate.label} {dueDate.value}
    </Text>
  </View>
);

export default TADueDate;
