import * as Types from '../../../types';

import { gql } from '@apollo/client';
import {
  BelegnummernkreisDefinitionFieldsFragmentDoc,
  BelegnummernkreisDefinitionTemplatesFieldsFragmentDoc,
} from './BelegnummernkreisDefinitionFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BelegnummernkreisDefinitionListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type BelegnummernkreisDefinitionListQuery = {
  getBelegnummernkreisDefinitionList: {
    data: Array<{
      belegnummernkreisDefinitionId: string;
      bezeichnung: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      jaehrlichNeu: boolean;
      nummernteil: string;
      belegnummernkreisList: Array<{
        belegnummernkreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        jahr: number;
        nextBelegnummer: string;
        status: { text: string; value: Types.BelegnummernkreisStatus };
        verwendung: { text: string; value: Types.BelegnummernkreisVerwendung };
        warningList: Array<{ type: string; message: string; attribute?: string | null }>;
      }>;
      prefix?: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      } | null;
      postfix?: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      } | null;
      status: { value: Types.BelegnummernkreisDefinitionStatus; text: string };
      warningList: Array<{ type: string; message: string; attribute?: string | null }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BelegnummernkreisDefinitionQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  belegnummernkreisDefinitionId: Types.Scalars['ID']['input'];
}>;

export type BelegnummernkreisDefinitionQuery = {
  getBelegnummernkreisDefinition: {
    data: {
      belegnummernkreisDefinitionId: string;
      bezeichnung: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      jaehrlichNeu: boolean;
      nummernteil: string;
      belegnummernkreisList: Array<{
        belegnummernkreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        jahr: number;
        nextBelegnummer: string;
        status: { text: string; value: Types.BelegnummernkreisStatus };
        verwendung: { text: string; value: Types.BelegnummernkreisVerwendung };
        warningList: Array<{ type: string; message: string; attribute?: string | null }>;
      }>;
      prefix?: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      } | null;
      postfix?: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      } | null;
      status: { value: Types.BelegnummernkreisDefinitionStatus; text: string };
      warningList: Array<{ type: string; message: string; attribute?: string | null }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BelegnummernkreisDefinitionTemplatesQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
}>;

export type BelegnummernkreisDefinitionTemplatesQuery = {
  getBelegnummernkreisDefinitionTemplates: {
    data: Array<{
      prefix: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      };
      postfix: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BelegnummernkreisDefinitionChangeHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  belegnummernkreisDefinitionId: Types.Scalars['ID']['input'];
}>;

export type BelegnummernkreisDefinitionChangeHistoryListQuery = {
  getBelegnummernkreisDefinitionChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const BelegnummernkreisDefinitionListDocument = gql`
  query BelegnummernkreisDefinitionList($rechtstraegerId: ID!) {
    getBelegnummernkreisDefinitionList(rechtstraegerId: $rechtstraegerId) {
      data {
        ...BelegnummernkreisDefinitionFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BelegnummernkreisDefinitionFieldsFragmentDoc}
`;
export function useBelegnummernkreisDefinitionListQuery(
  baseOptions: Apollo.QueryHookOptions<BelegnummernkreisDefinitionListQuery, BelegnummernkreisDefinitionListQueryVariables> &
    ({ variables: BelegnummernkreisDefinitionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BelegnummernkreisDefinitionListQuery, BelegnummernkreisDefinitionListQueryVariables>(
    BelegnummernkreisDefinitionListDocument,
    options
  );
}
export function useBelegnummernkreisDefinitionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BelegnummernkreisDefinitionListQuery, BelegnummernkreisDefinitionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BelegnummernkreisDefinitionListQuery, BelegnummernkreisDefinitionListQueryVariables>(
    BelegnummernkreisDefinitionListDocument,
    options
  );
}
export function useBelegnummernkreisDefinitionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BelegnummernkreisDefinitionListQuery, BelegnummernkreisDefinitionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BelegnummernkreisDefinitionListQuery, BelegnummernkreisDefinitionListQueryVariables>(
    BelegnummernkreisDefinitionListDocument,
    options
  );
}
export type BelegnummernkreisDefinitionListQueryHookResult = ReturnType<typeof useBelegnummernkreisDefinitionListQuery>;
export type BelegnummernkreisDefinitionListLazyQueryHookResult = ReturnType<typeof useBelegnummernkreisDefinitionListLazyQuery>;
export type BelegnummernkreisDefinitionListSuspenseQueryHookResult = ReturnType<typeof useBelegnummernkreisDefinitionListSuspenseQuery>;
export type BelegnummernkreisDefinitionListQueryResult = Apollo.QueryResult<
  BelegnummernkreisDefinitionListQuery,
  BelegnummernkreisDefinitionListQueryVariables
>;
export const BelegnummernkreisDefinitionDocument = gql`
  query BelegnummernkreisDefinition($rechtstraegerId: String!, $belegnummernkreisDefinitionId: ID!) {
    getBelegnummernkreisDefinition(rechtstraegerId: $rechtstraegerId, belegnummernkreisDefinitionId: $belegnummernkreisDefinitionId) {
      data {
        ...BelegnummernkreisDefinitionFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BelegnummernkreisDefinitionFieldsFragmentDoc}
`;
export function useBelegnummernkreisDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<BelegnummernkreisDefinitionQuery, BelegnummernkreisDefinitionQueryVariables> &
    ({ variables: BelegnummernkreisDefinitionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BelegnummernkreisDefinitionQuery, BelegnummernkreisDefinitionQueryVariables>(BelegnummernkreisDefinitionDocument, options);
}
export function useBelegnummernkreisDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BelegnummernkreisDefinitionQuery, BelegnummernkreisDefinitionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BelegnummernkreisDefinitionQuery, BelegnummernkreisDefinitionQueryVariables>(
    BelegnummernkreisDefinitionDocument,
    options
  );
}
export function useBelegnummernkreisDefinitionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BelegnummernkreisDefinitionQuery, BelegnummernkreisDefinitionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BelegnummernkreisDefinitionQuery, BelegnummernkreisDefinitionQueryVariables>(
    BelegnummernkreisDefinitionDocument,
    options
  );
}
export type BelegnummernkreisDefinitionQueryHookResult = ReturnType<typeof useBelegnummernkreisDefinitionQuery>;
export type BelegnummernkreisDefinitionLazyQueryHookResult = ReturnType<typeof useBelegnummernkreisDefinitionLazyQuery>;
export type BelegnummernkreisDefinitionSuspenseQueryHookResult = ReturnType<typeof useBelegnummernkreisDefinitionSuspenseQuery>;
export type BelegnummernkreisDefinitionQueryResult = Apollo.QueryResult<BelegnummernkreisDefinitionQuery, BelegnummernkreisDefinitionQueryVariables>;
export const BelegnummernkreisDefinitionTemplatesDocument = gql`
  query BelegnummernkreisDefinitionTemplates($rechtstraegerId: String!) {
    getBelegnummernkreisDefinitionTemplates(rechtstraegerId: $rechtstraegerId) {
      data {
        ...BelegnummernkreisDefinitionTemplatesFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BelegnummernkreisDefinitionTemplatesFieldsFragmentDoc}
`;
export function useBelegnummernkreisDefinitionTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<BelegnummernkreisDefinitionTemplatesQuery, BelegnummernkreisDefinitionTemplatesQueryVariables> &
    ({ variables: BelegnummernkreisDefinitionTemplatesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BelegnummernkreisDefinitionTemplatesQuery, BelegnummernkreisDefinitionTemplatesQueryVariables>(
    BelegnummernkreisDefinitionTemplatesDocument,
    options
  );
}
export function useBelegnummernkreisDefinitionTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BelegnummernkreisDefinitionTemplatesQuery, BelegnummernkreisDefinitionTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BelegnummernkreisDefinitionTemplatesQuery, BelegnummernkreisDefinitionTemplatesQueryVariables>(
    BelegnummernkreisDefinitionTemplatesDocument,
    options
  );
}
export function useBelegnummernkreisDefinitionTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BelegnummernkreisDefinitionTemplatesQuery, BelegnummernkreisDefinitionTemplatesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BelegnummernkreisDefinitionTemplatesQuery, BelegnummernkreisDefinitionTemplatesQueryVariables>(
    BelegnummernkreisDefinitionTemplatesDocument,
    options
  );
}
export type BelegnummernkreisDefinitionTemplatesQueryHookResult = ReturnType<typeof useBelegnummernkreisDefinitionTemplatesQuery>;
export type BelegnummernkreisDefinitionTemplatesLazyQueryHookResult = ReturnType<typeof useBelegnummernkreisDefinitionTemplatesLazyQuery>;
export type BelegnummernkreisDefinitionTemplatesSuspenseQueryHookResult = ReturnType<typeof useBelegnummernkreisDefinitionTemplatesSuspenseQuery>;
export type BelegnummernkreisDefinitionTemplatesQueryResult = Apollo.QueryResult<
  BelegnummernkreisDefinitionTemplatesQuery,
  BelegnummernkreisDefinitionTemplatesQueryVariables
>;
export const BelegnummernkreisDefinitionChangeHistoryListDocument = gql`
  query BelegnummernkreisDefinitionChangeHistoryList($rechtstraegerId: ID!, $belegnummernkreisDefinitionId: ID!) {
    getBelegnummernkreisDefinitionChangeHistoryList(
      rechtstraegerId: $rechtstraegerId
      belegnummernkreisDefinitionId: $belegnummernkreisDefinitionId
    ) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBelegnummernkreisDefinitionChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    BelegnummernkreisDefinitionChangeHistoryListQuery,
    BelegnummernkreisDefinitionChangeHistoryListQueryVariables
  > &
    ({ variables: BelegnummernkreisDefinitionChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BelegnummernkreisDefinitionChangeHistoryListQuery, BelegnummernkreisDefinitionChangeHistoryListQueryVariables>(
    BelegnummernkreisDefinitionChangeHistoryListDocument,
    options
  );
}
export function useBelegnummernkreisDefinitionChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BelegnummernkreisDefinitionChangeHistoryListQuery,
    BelegnummernkreisDefinitionChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BelegnummernkreisDefinitionChangeHistoryListQuery, BelegnummernkreisDefinitionChangeHistoryListQueryVariables>(
    BelegnummernkreisDefinitionChangeHistoryListDocument,
    options
  );
}
export function useBelegnummernkreisDefinitionChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BelegnummernkreisDefinitionChangeHistoryListQuery, BelegnummernkreisDefinitionChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BelegnummernkreisDefinitionChangeHistoryListQuery, BelegnummernkreisDefinitionChangeHistoryListQueryVariables>(
    BelegnummernkreisDefinitionChangeHistoryListDocument,
    options
  );
}
export type BelegnummernkreisDefinitionChangeHistoryListQueryHookResult = ReturnType<typeof useBelegnummernkreisDefinitionChangeHistoryListQuery>;
export type BelegnummernkreisDefinitionChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useBelegnummernkreisDefinitionChangeHistoryListLazyQuery
>;
export type BelegnummernkreisDefinitionChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useBelegnummernkreisDefinitionChangeHistoryListSuspenseQuery
>;
export type BelegnummernkreisDefinitionChangeHistoryListQueryResult = Apollo.QueryResult<
  BelegnummernkreisDefinitionChangeHistoryListQuery,
  BelegnummernkreisDefinitionChangeHistoryListQueryVariables
>;
