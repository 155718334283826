import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { VorschreibungspositionFragmentDoc } from '../../Vorschreibungsposition/gql/VorschreibungspositionFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorschreibungspositionTemplateListQueryVariables = Types.Exact<{
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type VorschreibungspositionTemplateListQuery = {
  getVorschreibungspositionTemplateList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      kontierungstabelle?: {
        basiskonto: number;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kontierungstabelleId: string;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
        subAdministrationExpenseAccount?: {
          bezeichnung: string;
          firmendatenId?: string | null;
          kontoId: string;
          kontoKlasse: string;
          nummer: string;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      } | null;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungspositionTemplateQueryVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungspositionTemplateQuery = {
  getVorschreibungspositionTemplate: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      kontierungstabelle?: {
        basiskonto: number;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kontierungstabelleId: string;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
        subAdministrationExpenseAccount?: {
          bezeichnung: string;
          firmendatenId?: string | null;
          kontoId: string;
          kontoKlasse: string;
          nummer: string;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      } | null;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungspositionTemplateChangeHistoryListQueryVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungspositionTemplateChangeHistoryListQuery = {
  getVorschreibungspositionTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const VorschreibungspositionTemplateListDocument = gql`
  query VorschreibungspositionTemplateList($withDetails: Boolean, $includeArchiviert: Boolean) {
    getVorschreibungspositionTemplateList(withDetails: $withDetails, includeArchiviert: $includeArchiviert) {
      data {
        ...Vorschreibungsposition
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungspositionFragmentDoc}
`;
export function useVorschreibungspositionTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<VorschreibungspositionTemplateListQuery, VorschreibungspositionTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungspositionTemplateListQuery, VorschreibungspositionTemplateListQueryVariables>(
    VorschreibungspositionTemplateListDocument,
    options
  );
}
export function useVorschreibungspositionTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorschreibungspositionTemplateListQuery, VorschreibungspositionTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungspositionTemplateListQuery, VorschreibungspositionTemplateListQueryVariables>(
    VorschreibungspositionTemplateListDocument,
    options
  );
}
export function useVorschreibungspositionTemplateListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VorschreibungspositionTemplateListQuery, VorschreibungspositionTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungspositionTemplateListQuery, VorschreibungspositionTemplateListQueryVariables>(
    VorschreibungspositionTemplateListDocument,
    options
  );
}
export type VorschreibungspositionTemplateListQueryHookResult = ReturnType<typeof useVorschreibungspositionTemplateListQuery>;
export type VorschreibungspositionTemplateListLazyQueryHookResult = ReturnType<typeof useVorschreibungspositionTemplateListLazyQuery>;
export type VorschreibungspositionTemplateListSuspenseQueryHookResult = ReturnType<typeof useVorschreibungspositionTemplateListSuspenseQuery>;
export type VorschreibungspositionTemplateListQueryResult = Apollo.QueryResult<
  VorschreibungspositionTemplateListQuery,
  VorschreibungspositionTemplateListQueryVariables
>;
export const VorschreibungspositionTemplateDocument = gql`
  query VorschreibungspositionTemplate($vorschreibungspositionId: ID!) {
    getVorschreibungspositionTemplate(vorschreibungspositionId: $vorschreibungspositionId) {
      data {
        ...Vorschreibungsposition
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungspositionFragmentDoc}
`;
export function useVorschreibungspositionTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<VorschreibungspositionTemplateQuery, VorschreibungspositionTemplateQueryVariables> &
    ({ variables: VorschreibungspositionTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungspositionTemplateQuery, VorschreibungspositionTemplateQueryVariables>(
    VorschreibungspositionTemplateDocument,
    options
  );
}
export function useVorschreibungspositionTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorschreibungspositionTemplateQuery, VorschreibungspositionTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungspositionTemplateQuery, VorschreibungspositionTemplateQueryVariables>(
    VorschreibungspositionTemplateDocument,
    options
  );
}
export function useVorschreibungspositionTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VorschreibungspositionTemplateQuery, VorschreibungspositionTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungspositionTemplateQuery, VorschreibungspositionTemplateQueryVariables>(
    VorschreibungspositionTemplateDocument,
    options
  );
}
export type VorschreibungspositionTemplateQueryHookResult = ReturnType<typeof useVorschreibungspositionTemplateQuery>;
export type VorschreibungspositionTemplateLazyQueryHookResult = ReturnType<typeof useVorschreibungspositionTemplateLazyQuery>;
export type VorschreibungspositionTemplateSuspenseQueryHookResult = ReturnType<typeof useVorschreibungspositionTemplateSuspenseQuery>;
export type VorschreibungspositionTemplateQueryResult = Apollo.QueryResult<
  VorschreibungspositionTemplateQuery,
  VorschreibungspositionTemplateQueryVariables
>;
export const VorschreibungspositionTemplateChangeHistoryListDocument = gql`
  query VorschreibungspositionTemplateChangeHistoryList($vorschreibungspositionId: ID!) {
    getVorschreibungspositionTemplateChangeHistoryList(vorschreibungspositionId: $vorschreibungspositionId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useVorschreibungspositionTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    VorschreibungspositionTemplateChangeHistoryListQuery,
    VorschreibungspositionTemplateChangeHistoryListQueryVariables
  > &
    ({ variables: VorschreibungspositionTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungspositionTemplateChangeHistoryListQuery, VorschreibungspositionTemplateChangeHistoryListQueryVariables>(
    VorschreibungspositionTemplateChangeHistoryListDocument,
    options
  );
}
export function useVorschreibungspositionTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VorschreibungspositionTemplateChangeHistoryListQuery,
    VorschreibungspositionTemplateChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungspositionTemplateChangeHistoryListQuery, VorschreibungspositionTemplateChangeHistoryListQueryVariables>(
    VorschreibungspositionTemplateChangeHistoryListDocument,
    options
  );
}
export function useVorschreibungspositionTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VorschreibungspositionTemplateChangeHistoryListQuery,
        VorschreibungspositionTemplateChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungspositionTemplateChangeHistoryListQuery, VorschreibungspositionTemplateChangeHistoryListQueryVariables>(
    VorschreibungspositionTemplateChangeHistoryListDocument,
    options
  );
}
export type VorschreibungspositionTemplateChangeHistoryListQueryHookResult = ReturnType<
  typeof useVorschreibungspositionTemplateChangeHistoryListQuery
>;
export type VorschreibungspositionTemplateChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useVorschreibungspositionTemplateChangeHistoryListLazyQuery
>;
export type VorschreibungspositionTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungspositionTemplateChangeHistoryListSuspenseQuery
>;
export type VorschreibungspositionTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  VorschreibungspositionTemplateChangeHistoryListQuery,
  VorschreibungspositionTemplateChangeHistoryListQueryVariables
>;
