import React from 'react';
import {
  AuditOutlined,
  CopyOutlined,
  IdcardOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  PhoneOutlined,
  SignatureOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import DocumentTab from '../../../features/Document/DocumentTab';
import RechnungsAussteller from '../../../features/Rechtstraeger/Rolle/RechnungsAussteller/RechnungsAussteller';
import { RechtsTraegerRolleMenuItemTitle } from '../../../features/Rechtstraeger/Rolle/rolleHelper';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { NatuerlichePerson, RechtstraegerRolle, RechtstraegerType } from '../../../types';
import {
  generatePathToRechtstraegerDetailsPage,
  generatePathToRechtstraegerDetailsRechnungAusstellerMenu,
  generatePathToRechtstraegerDetailsVertraegeMenu,
  generatePathToRechtstraegerDetailsVertragspartnerMenu,
  pathsToRechtstraegerDetails,
} from '../../../features/Rechtstraeger/rechtstraegerHelper';
import Vertragspartner from '../../../features/Rechtstraeger/Rolle/Vertragspartner/Vertragspartner';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import RechtstraegerInfoFeldTab from '../../../features/Rechtstraeger/InfoFeld/RechtstraegerInfoFeldTab';
import AddressesAndContactsAndPersonenbezugListTabs from '../../../features/Rechtstraeger/AddressesAndContactsAndPersonenbezugListTabs';
import { getVertragspartnerWarningState } from '../../../helpers/entityWarningHelper';
import VertragOverviewList from '../../../features/Rechtstraeger/Rolle/Vertrag/VertragOverviewList';
import RechtstraegerTicketTab from '../../../features/Rechtstraeger/Ticket/RechtstraegerTicketTab';
import CallLogTab from '../../../features/Rechtstraeger/CallLog/CallLogTab';

const menuListNatuerlichePerson = (
  rechtstraegerId: string,
  natuerlichePerson: NatuerlichePerson,
  refetch: () => void,
  rechtstraegerType: RechtstraegerType
): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Adressen, Kontakte und Personenbezüge',
      icon: <IdcardOutlined />,
      uri: `${generatePathToRechtstraegerDetailsPage(rechtstraegerId)}/adressen-kontakte-personenbezuege`,
      content: (_) => (
        <AddressesAndContactsAndPersonenbezugListTabs
          rechtstraegerId={rechtstraegerId}
          rechtstraegerType={rechtstraegerType}
          personenbezugList={natuerlichePerson.personenbezugList}
          onSuccess={refetch}
        />
      ),
    },
    {
      title: 'Aufgaben',
      icon: <InboxOutlined />,
      uri: `${generatePathToRechtstraegerDetailsPage(rechtstraegerId)}/aufgaben`,
      content: () => <RechtstraegerTicketTab rechtstraegerId={rechtstraegerId} />,
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: `${generatePathToRechtstraegerDetailsPage(rechtstraegerId)}/documents`,
      content: () => <DocumentTab rechtstraegerId={rechtstraegerId} />,
    },
    {
      title: 'Infofelder',
      icon: <InfoCircleOutlined />,
      uri: pathsToRechtstraegerDetails(rechtstraegerId).infoFeldMenu,
      content: () => <RechtstraegerInfoFeldTab rechtstraegerId={rechtstraegerId} />,
    },
    {
      title: 'Anrufprotokoll',
      icon: <PhoneOutlined />,
      uri: pathsToRechtstraegerDetails(rechtstraegerId).callLogMenu,
      content: () => <CallLogTab rechtstraegerId={rechtstraegerId} />,
    },
  ],
  contextMenuList: [
    {
      title: RechtsTraegerRolleMenuItemTitle.Rechnungsaussteller,
      icon: <AuditOutlined />,
      uri: generatePathToRechtstraegerDetailsRechnungAusstellerMenu(rechtstraegerId),
      content: () => <RechnungsAussteller rechtstraeger={natuerlichePerson} onSuccess={refetch} />,
      contextKey: [RechtstraegerRolle.ObjektRechnungsAussteller, RechtstraegerRolle.WeRechnungsAussteller],
    },
    {
      title: RechtsTraegerRolleMenuItemTitle.Vertragspartner,
      icon: <TeamOutlined />,
      state: getVertragspartnerWarningState(natuerlichePerson),
      uri: generatePathToRechtstraegerDetailsVertragspartnerMenu(rechtstraegerId),
      content: () => (
        <TabPaneContent distanceToTopBorder="small">
          <Vertragspartner rechtstraeger={natuerlichePerson} onSuccess={refetch} />
        </TabPaneContent>
      ),
      contextKey: [RechtstraegerRolle.Vertragspartner],
    },
    {
      title: 'Verträge',
      icon: <SignatureOutlined />,
      uri: generatePathToRechtstraegerDetailsVertraegeMenu(rechtstraegerId),
      content: () => (
        <TabPaneContent distanceToTopBorder="small">
          <VertragOverviewList rechtstraeger={natuerlichePerson} />
        </TabPaneContent>
      ),
      contextKey: [RechtstraegerRolle.ObjektRechnungsAussteller, RechtstraegerRolle.WeRechnungsAussteller, RechtstraegerRolle.Vertragspartner],
    },
  ],
});

export default menuListNatuerlichePerson;
