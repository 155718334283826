import React from 'react';
import { Route } from 'react-router';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import AuthRoute from '../../security/AuthRoute';
import { URI_BK_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE } from '../../features/Abrechnung/BkOrWe/bkAbrechnungUriPaths';
import BkTopAbrechnungDetailsPage from './BkTopAbrechnungDetailsPage';

export type BkTopAbrechnungDetailsPageRouteParams = { objektId: string; objektAbrechnungId: string; topAbrechnungId: string };

const bkTopAbrechnungDetailsPageRoute = (
  <Route
    path={`${URI_BK_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BkTopAbrechnungDetailsPage />} />}
  />
);

export default bkTopAbrechnungDetailsPageRoute;
