import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../../components/message/validationMsg';
/* eslint-disable no-template-curly-in-string */

// ADDRESS_LAND_ISO2_LENGTH = 2;
export const countryCodeIso2ValidationSchema = Yup.string()
  .required(entityIsRequired('Land'))
  .min(2, 'Das ausgewählte Land ist nicht gültig')
  .max(2, 'Das ausgewählte Land ist nicht gültig');

// ADDRESS_ZIP_CODE_LENGTH = 8;
export const zipCodeValidationSchema = Yup.string().required(entityIsRequired('Postleitzahl')).max(8, entityMaxCharLength('Postleitzahl', 8));

// ADDRESS_POSTOFFICE_BOX_ZIP_CODE_LENGTH = 8;
export const postofficeBoxZipCodeValidationSchema = Yup.string()
  .required(entityIsRequired('Postleitzahl'))
  .max(8, entityMaxCharLength('Postleitzahl', 8));

// ADDRESS_POSTOFFICE_BOX_NUMBER_LENGTH = 20;
export const postofficeBoxNumberValidationSchema = Yup.string()
  .required(entityIsRequired('Postfachnummer'))
  .max(20, entityMaxCharLength('Postfachnummer', 8));

// ADDRESS_CITY_LENGTH = 40;
export const cityValidationSchema = Yup.string().required(entityIsRequired('Ort')).max(40, entityMaxCharLength('Ort', 40));

// ADDRESS_CITY_ADDITIONAL_INFORMATION_LENGTH = 40;
export const cityAdditionalInfoValidationSchema = Yup.string().nullable().max(40, entityMaxCharLength('Ortzusatz', 40));

// ADDRESS_STREET_LENGTH = 30;
export const streetValidationSchema = Yup.string().required(entityIsRequired('Straße')).max(30, entityMaxCharLength('Straße', 30));

// ADDRESS_HAUSNUMMER_STIEGE_TUER_LENGTH = 20;
export const houseEntranceApartmentNumberValidationSchema = Yup.string()
  .required(entityIsRequired('Hausnummer/Stiege/Tür'))
  .max(20, entityMaxCharLength('Hausnummer/Stiege/Tür', 20));

export const postofficeBoxAddressValidationSchema = Yup.object().shape({
  countryCodeIso2: countryCodeIso2ValidationSchema,
  zipCode: postofficeBoxZipCodeValidationSchema,
  postofficeBoxNumber: postofficeBoxNumberValidationSchema,
  city: cityValidationSchema,
  cityAdditionalInformation: cityAdditionalInfoValidationSchema,
});

export const streetAddressValidationSchema = Yup.object().shape({
  countryCodeIso2: countryCodeIso2ValidationSchema,
  zipCode: zipCodeValidationSchema,
  city: cityValidationSchema,
  cityAdditionalInformation: cityAdditionalInfoValidationSchema,
  street: streetValidationSchema,
  houseEntranceApartmentNumber: houseEntranceApartmentNumberValidationSchema,
});

export const streetAddressValidationSchemaOptional = Yup.lazy((value) => {
  if (value !== undefined) {
    return streetAddressValidationSchema;
  }
  return Yup.mixed().notRequired();
});

/* eslint-enable no-template-curly-in-string */
