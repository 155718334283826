import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = {
  list: SyncEbicsUserMitarbeiterFormSelectProps[];
  width?: string;
} & SelectProps;

export interface SyncEbicsUserMitarbeiterFormSelectProps {
  mitarbeiterId: string;
  kurzBezeichnung: string;
}

const SyncEbicsUserMitarbeiterFormSelect: FC<Props> = ({ list, name, width, ...restProps }) => {
  return (
    <Select
      size="small"
      placeholder="Mitarbeiter auswählen"
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      style={{ width: width || '100%' }}
      {...restProps}
    >
      {list.map((mitarbeiter) => (
        <Select.Option key={mitarbeiter.mitarbeiterId} value={mitarbeiter.mitarbeiterId}>
          <DataWithShortenedText maxTextLength={25} text={mitarbeiter.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default SyncEbicsUserMitarbeiterFormSelect;
