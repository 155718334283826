import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { FormattedNumber } from 'react-intl';
import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import { BENutzwertVersionTimelineDataSourceEntry } from './nutzwertVersionTimelineMapper';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type NutzwertVersionCardContentProps = {
  timeblock: ITimeblock<BENutzwertVersionTimelineDataSourceEntry>;
};

const NutzwertVersionCardContent: FC<NutzwertVersionCardContentProps> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      <CustomFormattedDate value={timeblock.validFrom} />
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle="Nutzwert">
      <Typography.Text>
        <FormattedNumber value={timeblock.nutzwert} />
      </Typography.Text>
    </TimelineCardContentInfoRow>
  </Space>
);

export default NutzwertVersionCardContent;
