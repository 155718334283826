import React, { FC } from 'react';
import { Empty } from 'antd';
import { BankStatement } from '../../../../../../types';
import NestedTable from '../../../../../../components/Table/NestedTable/NestedTable';
import bankstatementTableColumns from './bankstatementTableColumns';

type Props = {
  bankstatementList: BankStatement[];
  dataCarrierId: string;
  onAction: () => void;
};

const BankstatementTable: FC<Props> = ({ bankstatementList, dataCarrierId, onAction }) => {
  return (
    <NestedTable<BankStatement>
      locale={{
        emptyText: <Empty description={<span>Keine Kontoauszüge vorhanden</span>} />,
      }}
      level={2}
      dataSource={bankstatementList}
      columns={bankstatementTableColumns(dataCarrierId, onAction)}
      expandable={{ rowExpandable: (record) => false }}
      // Use unique rowKey
      rowKey={(record) => `${dataCarrierId}_${record.statementNumber}`}
      useHighlighting
    />
  );
};

export default BankstatementTable;
