import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import {
  mapFormValuesToVorschreibungListingFilters,
  mapQueryParamsToAuftragQueryVariables,
  mapQueryParamsToFormValues,
  VorschreibungListingFiltersFormValues,
} from './Filters/vorschreibungListingFiltersFormMapper';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { TVorschreibungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { AuftragListDocument, AuftragListSummaryDocument, useAuftragListQuery } from '../gql/AuftragQueries.types';
import { useOnAuftragDataChangedEvent } from './useOnAuftragDataChangedEvent';
import VorschreibungListingFilters from './Filters/VorschreibungListingFilters';
import VorschreibungListSummary from './VorschreibungListSummary';
import VorschreibungTable from './Table/VorschreibungTable';

const VorschreibungListingForm = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TVorschreibungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    const filters = mapQueryParamsToFormValues(queryParams);
    updateQueryParams(navigate, mapFormValuesToVorschreibungListingFilters(filters), paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: VorschreibungListingFiltersFormValues) => {
    const filters = mapFormValuesToVorschreibungListingFilters(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  const {
    data: auftragListData,
    pagination,
    handleTableSorting,
  } = useQueryWithPagingAndSorting(
    useAuftragListQuery,
    {
      variables: {
        ...mapQueryParamsToAuftragQueryVariables(queryParams),
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const auftragList = auftragListData?.getAuftragList.data?.contentList;
  const loading = !auftragList;

  const client = useApolloClient();
  useOnAuftragDataChangedEvent('vorschreibung', async () => {
    await client.refetchQueries({
      include: [AuftragListDocument, AuftragListSummaryDocument],
    });
  });

  return (
    <>
      <Formik<VorschreibungListingFiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => <VorschreibungListingFilters formikProps={formikProps} queryParams={queryParams} />}
      </Formik>
      <VorschreibungListSummary queryParams={queryParams} />
      <VorschreibungTable
        auftragList={auftragList}
        loading={loading}
        handleTableSorting={handleTableSorting}
        pagination={pagination}
        filterIdentifier="main-vorschreibung"
      />
    </>
  );
};

export default VorschreibungListingForm;
