import React, { FC } from 'react';
import { Dropdown, MenuProps, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, DownOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { MutationFunctionOptions } from '@apollo/client';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { showSuccessMsgDelete } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { MahndefinitionListEntry } from '../../../types';
import { useToggle } from '../../../hooks/useToggle';
import {
  DeleteMahndefinitionTemplateMutation,
  DeleteMahndefinitionTemplateMutationVariables,
  useDeleteMahndefinitionTemplateMutation,
} from '../gql/MahndefinitionTemplateMutations.types';
import MahndefintionTemplateChangeHistoryModal from '../ChangeHistory/MahndefintionTemplateChangeHistoryModal';
import { generatePathToMahndefinitionTemplateUpdatePage } from '../mahndefinitonTemplateUriPaths';

type Props = {
  mahndefinition: MahndefinitionListEntry;
  onActionSuccess: () => void;
};

const MahndefinitionTableActions: FC<Props> = ({ mahndefinition, onActionSuccess }) => {
  const entity = 'Mahndefinition';
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runDelete] = useDeleteMahndefinitionTemplateMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link to={generatePathToMahndefinitionTemplateUpdatePage(mahndefinition.mahndefinitionId)}>Bearbeiten</Link>,
      icon: <EditOutlined />,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Löschen',
      icon: <DeleteOutlined />,
      onClick: showConfirmDelete(runDelete, mahndefinition),
      disabled: !mahndefinition.deletable,
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <MahndefintionTemplateChangeHistoryModal
        mahndefinitionId={mahndefinition.mahndefinitionId}
        open={isChangeHistoryOpen}
        onCancel={toggleChangeHistoryOpen}
      />
    </>
  );
};

const showConfirmDelete =
  (
    runDelete: (
      options?: MutationFunctionOptions<DeleteMahndefinitionTemplateMutation, DeleteMahndefinitionTemplateMutationVariables>
    ) => Promise<any>,
    mahndefinition: MahndefinitionListEntry
  ) =>
  () => {
    Modal.confirm({
      title: `Möchten Sie die Mahndefinition löschen?`,
      content: `${mahndefinition.bezeichnung}`,
      okButtonProps: { danger: true },
      okText: 'Löschen',
      cancelText: 'Abbrechen',
      onOk() {
        return runDelete({ variables: { mahndefinitionId: mahndefinition.mahndefinitionId } });
      },
    });
  };

export default MahndefinitionTableActions;
