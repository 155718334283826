import React, { FC } from 'react';
import { FileInfoType } from '../../../../types';
import RowUnknown from './RowUnknown';
import RowEigenbeleg from './RowEigenbeleg';
import RowObjektAbrechnung from './RowObjektAbrechnung';
import RowTopAbrechnung from './RowTopAbrechnung';
import { DocumentRowProps, findLabelValueByLabelId } from './util';
import RowMahnung from './RowMahnung';
import RowMailAttachment from './RowMailAttachment';
import RowHeAbrechnungForDeletedHeVertrag from './RowHeAbrechnungForDeletedHeVertrag';
import RowHeAbrechnungWithHeVertrag from './RowHeAbrechnungWithHeVertrag';
import RowObjektFinancialPlan from './RowObjektFinancialPlan';
import RowTopFinancialPlan from './RowTopFinancialPlan';
import RowIncomingInvoice from './RowIncomingInvoice';
import RowSubAbrechnung from './RowSubAbrechnung';

const DocumentRow: FC<DocumentRowProps> = (props) => {
  if (!props.record) {
    return <tr {...props} />;
  }
  return <DocumentRecordRow {...props} />;
};

const DocumentRecordRow: FC<DocumentRowProps> = (props) => {
  const recordType = props.record.type.value;
  const isHeAbrechnungHeVertragDeleted = findLabelValueByLabelId(props.record.labelList, 'heAbrechnungHeVertragDeleted');

  switch (recordType) {
    case FileInfoType.AusgangsRechnung:
      return <RowEigenbeleg {...props} />;
    case FileInfoType.IncomingInvoice:
      return <RowIncomingInvoice {...props} />;
    case FileInfoType.HeAbrechnung:
      return isHeAbrechnungHeVertragDeleted ? <RowHeAbrechnungForDeletedHeVertrag {...props} /> : <RowHeAbrechnungWithHeVertrag {...props} />;
    case FileInfoType.BkObjektAbrechnung:
    case FileInfoType.WeObjektAbrechnung:
      return <RowObjektAbrechnung {...props} />;
    case FileInfoType.BkTopAbrechnung:
    case FileInfoType.WeTopAbrechnung:
      return <RowTopAbrechnung {...props} />;
    case FileInfoType.Mahnung:
      return <RowMahnung {...props} />;
    case FileInfoType.MailAttachment:
      return <RowMailAttachment {...props} />;
    case FileInfoType.ObjektFinancialPlan:
      return <RowObjektFinancialPlan {...props} />;
    case FileInfoType.TopFinancialPlan:
      return <RowTopFinancialPlan {...props} />;
    case FileInfoType.SubAbrechnungObjekt:
    case FileInfoType.SubAbrechnungBestandseinheit:
      return <RowSubAbrechnung {...props} />;
    default:
      return <RowUnknown {...props} />;
  }
};

export default DocumentRow;
