import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { VBestandseinheit } from '../../../vorschreibung-types';
import { beColumnWidthsAndAlignment } from '../objekt-block-tables-styles';
import VTVPosTable from './VTVPosTable';
import VTBestandseinheitSummary from './VTBestandseinheitSummary';

const VTBestandseinheitList: FC<{ bestandseinheitList: VBestandseinheit[] }> = ({ bestandseinheitList }) => (
  <View style={[pdfStyles.column]}>
    {bestandseinheitList.map((bestandseinheit, index) => (
      <View key={index} style={[pdfStyles.row, { paddingTop: '3mm' }]}>
        {/* Bestandseinheit bezeichnung */}
        <Text
          style={[
            pdfStyles.textBulletin,
            { width: beColumnWidthsAndAlignment.bestandseinheit.width, textAlign: beColumnWidthsAndAlignment.bestandseinheit.textAlign },
          ]}
        >
          {bestandseinheit.bestandseinheit}
        </Text>

        {/* VPos Table and Bestandseinheit Summary */}
        <View style={[pdfStyles.column, pdfStyles.textBulletin, { width: beColumnWidthsAndAlignment.vPosList.width }]}>
          <VTVPosTable vPosList={bestandseinheit.vorschreibungspositionList} />
          <VTBestandseinheitSummary data={bestandseinheit.summary} />
        </View>
      </View>
    ))}
  </View>
);

export default VTBestandseinheitList;
