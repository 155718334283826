import React, { FC } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { ButtonsToRemoveOrAddTextInputProps } from './FieldArrayInputProps';

export const ButtonsToRemoveOrAddTextInput: FC<ButtonsToRemoveOrAddTextInputProps> = ({ isAddAllowed, add, remove }) => (
  <Space>
    {isAddAllowed && <Button size="small" shape="circle" icon={<PlusOutlined />} onClick={add} />}
    <Button size="small" shape="circle" icon={<MinusOutlined />} onClick={remove} />
  </Space>
);

export default ButtonsToRemoveOrAddTextInput;
