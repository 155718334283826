import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import UstKategorieForm from '../Form/UstKategorieForm';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';

type Props = {
  isCollapsed: boolean;
  onCollapse: () => void;
  refetch: () => void;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const UstKategorieButtonsAndDrawer: FC<Props> = ({ isCollapsed, onCollapse, refetch, showArchived, toggleShowArchived }) => {
  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button onClick={toggleShowArchived}>Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}</Button>
        <Button type="primary" onClick={onCollapse}>
          <PlusOutlined />
          USt.-Kategorie anlegen
        </Button>
      </ButtonsAligned>
      <Drawer title="USt.-Kategorie anlegen" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <UstKategorieForm
          onSuccess={() => {
            onCollapse();
            refetch();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
    </>
  );
};

export default UstKategorieButtonsAndDrawer;
