import Fetcher from '../../../../helpers/fetcher';
import { processAndReturnError } from '../../../../helpers/APIUtils';
import { buildUri } from '../../../../shared/uriHelpers';

export const runExportOPDebitorListAsPdf = async (props: any, abortController: AbortController) => {
  // Obtain a reference to the AbortSignal
  const signal = abortController.signal;
  try {
    const res = await Fetcher.getInstance().fetch(
      uriOffenePostenDebitorPdfExport(props.firmendatenId, props.rechnungsAusstellerId, props.vertragspartnerId, props.dueDateTo),
      { signal }
    );
    return await res;
  } catch (error: any) {
    return await processAndReturnError(error);
  }
};

const uriOffenePosten = (firmendatenId: string) => `/api/firmendaten/${firmendatenId}/offene-posten/pdf`;

export const uriOffenePostenDebitorPdfExport = (
  firmendatenId: string,
  rechnungsAusstellerId?: string,
  vertragspartnerId?: string,
  dueDateTo?: string
) => `${buildUri(uriOffenePosten(firmendatenId), { rechnungsAusstellerId, vertragspartnerId, dueDateTo })}`;
