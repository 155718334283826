import React, { FC } from 'react';
import styled from 'styled-components';

const HtmlAttribute: FC<{ content: string; className?: string }> = ({ content, className }) => {
  // eslint-disable-next-line react/no-danger
  return <div className={className} dangerouslySetInnerHTML={{ __html: content }} />;
};

const HistoryHtmlAttribute = styled(HtmlAttribute)`
  p {
    margin-bottom: 0;
  }
`;

export default HistoryHtmlAttribute;
