import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjektSummeAnteileListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type ObjektSummeAnteileListQuery = {
  getObjektSummeAnteileList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      validFrom: string;
      lastUpdateTs: string;
      kommentar?: string | null;
      objektSummeAnteileId: string;
      summeAnteile: number;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        validFrom: string;
        lastUpdateTs: string;
        kommentar?: string | null;
        objektSummeAnteileId: string;
        summeAnteile: number;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ObjektSummeAnteileQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  summeAnteileId: Types.Scalars['ID']['input'];
}>;

export type ObjektSummeAnteileQuery = {
  getObjektSummeAnteile: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      validFrom: string;
      lastUpdateTs: string;
      objektSummeAnteileId: string;
      summeAnteile: number;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ObjektSummeAnteileListDocument = gql`
  query ObjektSummeAnteileList($objektId: ID!) {
    getObjektSummeAnteileList(objektId: $objektId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        current
        validFrom
        lastUpdateTs
        kommentar
        objektSummeAnteileId
        summeAnteile
        historicizedList {
          createTs
          createdBy
          createdByMitarbeiterId
          current
          validFrom
          lastUpdateTs
          kommentar
          objektSummeAnteileId
          summeAnteile
          warningList {
            attribute
            message
            type
          }
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useObjektSummeAnteileListQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektSummeAnteileListQuery, ObjektSummeAnteileListQueryVariables> &
    ({ variables: ObjektSummeAnteileListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektSummeAnteileListQuery, ObjektSummeAnteileListQueryVariables>(ObjektSummeAnteileListDocument, options);
}
export function useObjektSummeAnteileListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObjektSummeAnteileListQuery, ObjektSummeAnteileListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektSummeAnteileListQuery, ObjektSummeAnteileListQueryVariables>(ObjektSummeAnteileListDocument, options);
}
export function useObjektSummeAnteileListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektSummeAnteileListQuery, ObjektSummeAnteileListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektSummeAnteileListQuery, ObjektSummeAnteileListQueryVariables>(ObjektSummeAnteileListDocument, options);
}
export type ObjektSummeAnteileListQueryHookResult = ReturnType<typeof useObjektSummeAnteileListQuery>;
export type ObjektSummeAnteileListLazyQueryHookResult = ReturnType<typeof useObjektSummeAnteileListLazyQuery>;
export type ObjektSummeAnteileListSuspenseQueryHookResult = ReturnType<typeof useObjektSummeAnteileListSuspenseQuery>;
export type ObjektSummeAnteileListQueryResult = Apollo.QueryResult<ObjektSummeAnteileListQuery, ObjektSummeAnteileListQueryVariables>;
export const ObjektSummeAnteileDocument = gql`
  query ObjektSummeAnteile($objektId: String!, $summeAnteileId: ID!) {
    getObjektSummeAnteile(objektId: $objektId, summeAnteileId: $summeAnteileId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        current
        validFrom
        lastUpdateTs
        objektSummeAnteileId
        summeAnteile
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useObjektSummeAnteileQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektSummeAnteileQuery, ObjektSummeAnteileQueryVariables> &
    ({ variables: ObjektSummeAnteileQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektSummeAnteileQuery, ObjektSummeAnteileQueryVariables>(ObjektSummeAnteileDocument, options);
}
export function useObjektSummeAnteileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjektSummeAnteileQuery, ObjektSummeAnteileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektSummeAnteileQuery, ObjektSummeAnteileQueryVariables>(ObjektSummeAnteileDocument, options);
}
export function useObjektSummeAnteileSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektSummeAnteileQuery, ObjektSummeAnteileQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektSummeAnteileQuery, ObjektSummeAnteileQueryVariables>(ObjektSummeAnteileDocument, options);
}
export type ObjektSummeAnteileQueryHookResult = ReturnType<typeof useObjektSummeAnteileQuery>;
export type ObjektSummeAnteileLazyQueryHookResult = ReturnType<typeof useObjektSummeAnteileLazyQuery>;
export type ObjektSummeAnteileSuspenseQueryHookResult = ReturnType<typeof useObjektSummeAnteileSuspenseQuery>;
export type ObjektSummeAnteileQueryResult = Apollo.QueryResult<ObjektSummeAnteileQuery, ObjektSummeAnteileQueryVariables>;
