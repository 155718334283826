import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { showSuccessMsgCreate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useUpdateContactMutation } from '../../../gql/AddressesAndContactsMutations.types';
import { validatePhoneNumberAsync } from '../../../../../../shared/components/Contact/phoneNumberValidation';
import { mapPhoneContactToFormValues, phoneContactUpdateFormFields, PhoneContactUpdateFormValues } from './phoneContactUpdateFormMapper';
import { Contact } from '../../../../../../types';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FieldHelpIconWithTooltip from '../../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';
import FormItemWithoutColon from '../../../../../../components/Form/FormItemWithoutColon';

type Props = {
  item: Contact;
  firmendatenId: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const PhoneContactUpdateForm: FC<Props> = ({ item, firmendatenId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ContactPhone'>('ContactPhone');

  const [updateContact, { loading }] = useUpdateContactMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Kontakt`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<PhoneContactUpdateFormValues>
      initialValues={mapPhoneContactToFormValues(item)}
      onSubmit={(values, formikHelpers) => {
        return validatePhoneNumberAsync(values.wert)
          .then(() => {
            updateContact({
              variables: { firmendatenId, contactId: item.contactId, input: values },
            }).finally(() => formikHelpers.setSubmitting(false));
          })
          .catch((error: any) => {
            formikHelpers.setFieldError(phoneContactUpdateFormFields.wert, error);
            formikHelpers.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>
                Telefonnummer <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('ContactPhone.wert')} />
              </Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithoutColon name={phoneContactUpdateFormFields.wert}>
                <Input name={phoneContactUpdateFormFields.wert} id={phoneContactUpdateFormFields.wert} />
              </FormItemWithoutColon>
            </Col>
          </Row>
          <FormButtons updateMode onCancel={onCancel} isOkLoading={loading} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default PhoneContactUpdateForm;
