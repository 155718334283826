import React, { FC } from 'react';
import NestedTableWithColSelector from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { AbrInfoKonto } from '../../../../../types';
import einnahmenTableColumns from './einnahmenTableColumns';
import BuchungszeileTable from '../Level3/BuchungszeileTable';
import einnahmenBuchungszeileTableColumns from '../Level3/einnahmenBuchungszeileTableColumns';

type Props = {
  kontoList: AbrInfoKonto[];
  parentRowKeys?: string[];
  visible?: boolean;
};

const EinnahmenTable: FC<Props> = ({ kontoList, parentRowKeys, visible = true }) => {
  return (
    <NestedTableWithColSelector<AbrInfoKonto>
      level={2}
      parentRowKeys={parentRowKeys}
      visible={visible}
      dataSource={kontoList}
      columns={einnahmenTableColumns}
      rowKey={getRowKey}
      expandable={{
        rowExpandable: (record) => !!record.buchungszeileList.length,
        expandedRowRender: (record, index, indent, expanded) => (
          <BuchungszeileTable
            parentRowKeys={[...(parentRowKeys ?? []), getRowKey(record)]}
            visible={visible && expanded}
            columns={einnahmenBuchungszeileTableColumns}
            buchungszeileList={record.buchungszeileList}
          />
        ),
      }}
    />
  );
};

const getRowKey = (record: AbrInfoKonto) => record.kontoId;

export default EinnahmenTable;
