import { FormFields } from '../../../helpers/formikHelper';
import { BudgetingAbrDefVPosLinkage, BudgetingAbrDefVPosLinkageCreateInput } from '../../../types';

export type LinkageFormValues = {
  abrKreisVPosList: AbrKreisVPosListFormValues[];
  firmendatenId: string;
  abrechnungsdefinitionId: string;
};

export type AbrKreisVPosListFormValues = {
  abrechnungskreisId: string;
  vorschreibungspositionId: string;
};

export const linkageFormFields: FormFields<LinkageFormValues> = {
  firmendatenId: 'firmendatenId',
  abrKreisVPosList: 'abrKreisVPosList',
  abrechnungsdefinitionId: 'abrechnungsdefinitionId',
};

export const linkageFormInitialValues: LinkageFormValues = {
  firmendatenId: '',
  abrKreisVPosList: [],
  abrechnungsdefinitionId: '',
};

export const mapLinkageToFormValues = (linkage?: BudgetingAbrDefVPosLinkage): LinkageFormValues => {
  if (!linkage) {
    return linkageFormInitialValues;
  }

  return {
    firmendatenId: linkage.firmendatenId ?? '',
    abrKreisVPosList: linkage.abrKreisVPosList.map((pos) => ({
      abrechnungskreisId: pos.abrechnungskreis.abrechnungskreisId,
      vorschreibungspositionId: pos.vorschreibungsposition.vorschreibungspositionId,
    })),
    abrechnungsdefinitionId: linkage.abrechnungsdefinition.abrechnungsdefinitionId,
  };
};

export const mapFormValuesToLinkage = (formValues: LinkageFormValues): BudgetingAbrDefVPosLinkageCreateInput => ({
  abrKreisVPosList: [...formValues.abrKreisVPosList],
  abrechnungsdefinitionId: formValues.abrechnungsdefinitionId,
});
