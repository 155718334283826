import { AufwandAufteilungTableTypes } from './aufwandAufteilung-table-types';

export const columnWidthsAndAlignment: AufwandAufteilungTableTypes = {
  basis: { width: '10%', textAlign: 'right' },
  anteilNetto: { width: '15%', textAlign: 'right' },
  ustProzent: { width: '10%', textAlign: 'right' },
  ust: { width: '10%', textAlign: 'right' },
  summe: { width: '15%', textAlign: 'right' },
  grundlage: { width: '25%', textAlign: 'left' },
  belastung: { width: '15%', textAlign: 'right' },
};

export const grundlageColumnPaddingLeft = '3mm';
