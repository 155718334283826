import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { EbicsUserFieldsFragmentDoc } from '../../../PaymentTransaction/EbicsUser/gql/EbicsUserFragments.types';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CamtGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type CamtGenerierlaufQuery = {
  getCamtGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CamtGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  iban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  mitarbeiterId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ebicsUserUserId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ebicsUserName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.CamtGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type CamtGenerierlaufEntryListQuery = {
  getCamtGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        currentStep?: number | null;
        fromInclusive?: string | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        retry: boolean;
        stepCount?: number | null;
        toInclusive?: string | null;
        dataCarrierList: Array<{
          camtGenerierlaufEntryId?: string | null;
          dataCarrierId: string;
          ibanImportedList: Array<string>;
          ibanNotImportedList: Array<string>;
          invoiceIssuerName?: string | null;
          name: string;
          paymentProposalName?: string | null;
          xmlFilename: string;
          dataCarrierDetailsList: Array<{
            bankStatementId: string;
            closingBalance?: number | null;
            countBankStatements: number;
            countTransactionsCredited?: number | null;
            countTransactionsDebited?: number | null;
            createdByGenerierlaufId?: string | null;
            createdOn: string;
            iban: string;
            openingBalance?: number | null;
            statementNumber: string;
            sumTransactions?: number | null;
          }>;
          dataCarrierType: { text: string; value: Types.DataCarrierType };
          errorList: Array<{ message: string; type: string }>;
          paymentTransactionType: { text: string; value: Types.PaymentTransactionType };
          status: { text: string; value: Types.DataCarrierCamtStatus };
          warningList: Array<{ message: string; type: string }>;
        }>;
        ebicsUser?: {
          bankname: string;
          camtImportEnabled: boolean;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable: boolean;
          ebicsBankConfigId: string;
          ebicsUserId: string;
          editable: boolean;
          initializationDocumentFileId?: string | null;
          mitarbeiterId: string;
          mitarbeiterName: string;
          name?: string | null;
          partnerId: string;
          password: string;
          userId: string;
          orderTypeList: Array<{ description?: string | null; text: string; value: string }>;
          status: { text: string; value: Types.EbicsUserStatus };
        } | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CamtGenerierlaufDocument = gql`
  query CamtGenerierlauf($generierlaufId: ID!) {
    getCamtGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          description
          text
          value
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCamtGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<CamtGenerierlaufQuery, CamtGenerierlaufQueryVariables> &
    ({ variables: CamtGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CamtGenerierlaufQuery, CamtGenerierlaufQueryVariables>(CamtGenerierlaufDocument, options);
}
export function useCamtGenerierlaufLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CamtGenerierlaufQuery, CamtGenerierlaufQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CamtGenerierlaufQuery, CamtGenerierlaufQueryVariables>(CamtGenerierlaufDocument, options);
}
export function useCamtGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CamtGenerierlaufQuery, CamtGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CamtGenerierlaufQuery, CamtGenerierlaufQueryVariables>(CamtGenerierlaufDocument, options);
}
export type CamtGenerierlaufQueryHookResult = ReturnType<typeof useCamtGenerierlaufQuery>;
export type CamtGenerierlaufLazyQueryHookResult = ReturnType<typeof useCamtGenerierlaufLazyQuery>;
export type CamtGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useCamtGenerierlaufSuspenseQuery>;
export type CamtGenerierlaufQueryResult = Apollo.QueryResult<CamtGenerierlaufQuery, CamtGenerierlaufQueryVariables>;
export const CamtGenerierlaufEntryListDocument = gql`
  query CamtGenerierlaufEntryList(
    $generierlaufId: ID!
    $iban: String
    $mitarbeiterId: String
    $ebicsUserUserId: String
    $ebicsUserName: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: CamtGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getCamtGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      iban: $iban
      mitarbeiterId: $mitarbeiterId
      ebicsUserUserId: $ebicsUserUserId
      ebicsUserName: $ebicsUserName
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          createTs
          createdBy
          createdByMitarbeiterId
          currentStep
          dataCarrierList {
            camtGenerierlaufEntryId
            dataCarrierDetailsList {
              bankStatementId
              closingBalance
              countBankStatements
              countTransactionsCredited
              countTransactionsDebited
              createdByGenerierlaufId
              createdOn
              iban
              openingBalance
              statementNumber
              sumTransactions
            }
            dataCarrierId
            dataCarrierType {
              text
              value
            }
            errorList {
              message
              type
            }
            ibanImportedList
            ibanNotImportedList
            invoiceIssuerName
            name
            paymentProposalName
            paymentTransactionType {
              text
              value
            }
            status {
              text
              value
            }
            warningList {
              message
              type
            }
            xmlFilename
          }
          ebicsUser {
            ...EbicsUserFields
          }
          errorList {
            message
            type
          }
          exitCode {
            value
            text
          }
          fromInclusive
          generierlaufEntryId
          infoMessage
          retry
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          toInclusive
          warningList {
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${EbicsUserFieldsFragmentDoc}
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function useCamtGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<CamtGenerierlaufEntryListQuery, CamtGenerierlaufEntryListQueryVariables> &
    ({ variables: CamtGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CamtGenerierlaufEntryListQuery, CamtGenerierlaufEntryListQueryVariables>(CamtGenerierlaufEntryListDocument, options);
}
export function useCamtGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CamtGenerierlaufEntryListQuery, CamtGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CamtGenerierlaufEntryListQuery, CamtGenerierlaufEntryListQueryVariables>(CamtGenerierlaufEntryListDocument, options);
}
export function useCamtGenerierlaufEntryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CamtGenerierlaufEntryListQuery, CamtGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CamtGenerierlaufEntryListQuery, CamtGenerierlaufEntryListQueryVariables>(CamtGenerierlaufEntryListDocument, options);
}
export type CamtGenerierlaufEntryListQueryHookResult = ReturnType<typeof useCamtGenerierlaufEntryListQuery>;
export type CamtGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useCamtGenerierlaufEntryListLazyQuery>;
export type CamtGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<typeof useCamtGenerierlaufEntryListSuspenseQuery>;
export type CamtGenerierlaufEntryListQueryResult = Apollo.QueryResult<CamtGenerierlaufEntryListQuery, CamtGenerierlaufEntryListQueryVariables>;
