import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { Firma, NatuerlichePerson, Personengemeinschaft, Vertragsart } from '../../../../types';
import WeAndMietVertragTable from './WeAndMietVertragTable/WeAndMietVertragTable';
import HeVertragTable from './HeVertragTable/HeVertragTable';

type Props = {
  rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft;
};

const VertragOverviewList: FC<Props> = ({ rechtstraeger }) => {
  const items: TabsProps['items'] = [
    rechtstraeger.vertragSummary?.mietVertragVerwaltungCount && {
      key: '1',
      label: 'Mietverträge Verwaltung',
      children: (
        <WeAndMietVertragTable
          rechnungsAusstellerId={rechtstraeger.rechtstraegerId}
          vertragsartList={[Vertragsart.Eigennutzung, Vertragsart.Leerstehung, Vertragsart.Mieter, Vertragsart.Pauschalmieter]}
          filterIdentifier="mietvertraege-verwaltung-table"
        />
      ),
    },
    rechtstraeger.vertragSummary?.mietVertragCount && {
      key: '2',
      label: 'Mietverträge',
      children: (
        <WeAndMietVertragTable
          vertragspartnerId={rechtstraeger.rechtstraegerId}
          vertragsartList={[Vertragsart.Eigennutzung, Vertragsart.Leerstehung, Vertragsart.Mieter, Vertragsart.Pauschalmieter]}
          filterIdentifier="mietvertraege-table"
        />
      ),
    },
    rechtstraeger.vertragSummary?.heVertragVerwaltungCount && {
      key: '3',
      label: 'HE-Verträge Verwaltung',
      children: <HeVertragTable rechnungsAusstellerId={rechtstraeger.rechtstraegerId} filterIdentifier="he-verwaltung-overview-table" />,
    },
    rechtstraeger.vertragSummary?.heVertragCount && {
      key: '4',
      label: 'HE-Verträge',
      children: <HeVertragTable vertragspartnerId={rechtstraeger.rechtstraegerId} filterIdentifier="he-overview-table" />,
    },
    rechtstraeger.vertragSummary?.weVertragVerwaltungCount && {
      key: '5',
      label: 'WE Eigentümerverträge Verwaltung',
      children: (
        <WeAndMietVertragTable
          rechnungsAusstellerId={rechtstraeger.rechtstraegerId}
          vertragsartList={[Vertragsart.WegEigentuemer]}
          filterIdentifier="we-eigentuemer-verwaltung-table"
        />
      ),
    },
    rechtstraeger.vertragSummary?.weVertragCount && {
      key: '6',
      label: 'WE Verträge',
      children: (
        <WeAndMietVertragTable
          vertragspartnerId={rechtstraeger.rechtstraegerId}
          vertragsartList={[Vertragsart.WegEigentuemer, Vertragsart.WegMieter, Vertragsart.WegPauschalmieter, Vertragsart.WegLeerstehung]}
          filterIdentifier="we-vertraege-table"
        />
      ),
    },
    rechtstraeger.vertragSummary?.weSubverwaltungCount && {
      key: '7',
      label: 'WE Subverwaltungen',
      children: (
        <WeAndMietVertragTable
          rechnungsAusstellerId={rechtstraeger.rechtstraegerId}
          vertragsartList={[Vertragsart.WegMieter, Vertragsart.WegPauschalmieter, Vertragsart.WegLeerstehung]}
          filterIdentifier="we-subverwaltungen-table"
        />
      ),
    },
  ] as TabsProps['items'];

  return <Tabs items={items} />;
};

export default VertragOverviewList;
