import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from './AufteilungsschluesselFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KsAufteilungsschluesselListQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  order?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type KsAufteilungsschluesselListQuery = {
  getAufteilungsschluesselListByKundenSystemId: {
    data: {
      contentList: Array<{
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FdAufteilungsschluesselListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  order?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type FdAufteilungsschluesselListQuery = {
  getAufteilungsschluesselListByFirmendatenId: {
    data: {
      contentList: Array<{
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AufteilungsschluesselQueryVariables = Types.Exact<{
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type AufteilungsschluesselQuery = {
  getAufteilungsschluessel?: {
    data: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  } | null;
};

export type AufteilungsschluesselChangeHistoryListQueryVariables = Types.Exact<{
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type AufteilungsschluesselChangeHistoryListQuery = {
  getAufteilungsschluesselChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const KsAufteilungsschluesselListDocument = gql`
  query KSAufteilungsschluesselList(
    $kundenSystemId: ID!
    $order: String
    $orderDir: String
    $page: Int
    $pageSize: Int
    $withDetails: Boolean
    $includeArchiviert: Boolean
  ) {
    getAufteilungsschluesselListByKundenSystemId(
      kundenSystemId: $kundenSystemId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
      withDetails: $withDetails
      includeArchiviert: $includeArchiviert
    ) {
      data {
        contentList {
          ...AufteilungsschluesselFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useKsAufteilungsschluesselListQuery(
  baseOptions: Apollo.QueryHookOptions<KsAufteilungsschluesselListQuery, KsAufteilungsschluesselListQueryVariables> &
    ({ variables: KsAufteilungsschluesselListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KsAufteilungsschluesselListQuery, KsAufteilungsschluesselListQueryVariables>(KsAufteilungsschluesselListDocument, options);
}
export function useKsAufteilungsschluesselListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KsAufteilungsschluesselListQuery, KsAufteilungsschluesselListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KsAufteilungsschluesselListQuery, KsAufteilungsschluesselListQueryVariables>(
    KsAufteilungsschluesselListDocument,
    options
  );
}
export function useKsAufteilungsschluesselListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KsAufteilungsschluesselListQuery, KsAufteilungsschluesselListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KsAufteilungsschluesselListQuery, KsAufteilungsschluesselListQueryVariables>(
    KsAufteilungsschluesselListDocument,
    options
  );
}
export type KsAufteilungsschluesselListQueryHookResult = ReturnType<typeof useKsAufteilungsschluesselListQuery>;
export type KsAufteilungsschluesselListLazyQueryHookResult = ReturnType<typeof useKsAufteilungsschluesselListLazyQuery>;
export type KsAufteilungsschluesselListSuspenseQueryHookResult = ReturnType<typeof useKsAufteilungsschluesselListSuspenseQuery>;
export type KsAufteilungsschluesselListQueryResult = Apollo.QueryResult<KsAufteilungsschluesselListQuery, KsAufteilungsschluesselListQueryVariables>;
export const FdAufteilungsschluesselListDocument = gql`
  query FDAufteilungsschluesselList(
    $firmendatenId: ID!
    $order: String
    $orderDir: String
    $page: Int
    $pageSize: Int
    $withDetails: Boolean
    $includeArchiviert: Boolean
  ) {
    getAufteilungsschluesselListByFirmendatenId(
      firmendatenId: $firmendatenId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
      withDetails: $withDetails
      includeArchiviert: $includeArchiviert
    ) {
      data {
        contentList {
          ...AufteilungsschluesselFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useFdAufteilungsschluesselListQuery(
  baseOptions: Apollo.QueryHookOptions<FdAufteilungsschluesselListQuery, FdAufteilungsschluesselListQueryVariables> &
    ({ variables: FdAufteilungsschluesselListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FdAufteilungsschluesselListQuery, FdAufteilungsschluesselListQueryVariables>(FdAufteilungsschluesselListDocument, options);
}
export function useFdAufteilungsschluesselListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FdAufteilungsschluesselListQuery, FdAufteilungsschluesselListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FdAufteilungsschluesselListQuery, FdAufteilungsschluesselListQueryVariables>(
    FdAufteilungsschluesselListDocument,
    options
  );
}
export function useFdAufteilungsschluesselListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FdAufteilungsschluesselListQuery, FdAufteilungsschluesselListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FdAufteilungsschluesselListQuery, FdAufteilungsschluesselListQueryVariables>(
    FdAufteilungsschluesselListDocument,
    options
  );
}
export type FdAufteilungsschluesselListQueryHookResult = ReturnType<typeof useFdAufteilungsschluesselListQuery>;
export type FdAufteilungsschluesselListLazyQueryHookResult = ReturnType<typeof useFdAufteilungsschluesselListLazyQuery>;
export type FdAufteilungsschluesselListSuspenseQueryHookResult = ReturnType<typeof useFdAufteilungsschluesselListSuspenseQuery>;
export type FdAufteilungsschluesselListQueryResult = Apollo.QueryResult<FdAufteilungsschluesselListQuery, FdAufteilungsschluesselListQueryVariables>;
export const AufteilungsschluesselDocument = gql`
  query Aufteilungsschluessel($aufteilungsschluesselId: ID!) {
    getAufteilungsschluessel(aufteilungsschluesselId: $aufteilungsschluesselId) {
      data {
        ...AufteilungsschluesselFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useAufteilungsschluesselQuery(
  baseOptions: Apollo.QueryHookOptions<AufteilungsschluesselQuery, AufteilungsschluesselQueryVariables> &
    ({ variables: AufteilungsschluesselQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AufteilungsschluesselQuery, AufteilungsschluesselQueryVariables>(AufteilungsschluesselDocument, options);
}
export function useAufteilungsschluesselLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AufteilungsschluesselQuery, AufteilungsschluesselQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AufteilungsschluesselQuery, AufteilungsschluesselQueryVariables>(AufteilungsschluesselDocument, options);
}
export function useAufteilungsschluesselSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AufteilungsschluesselQuery, AufteilungsschluesselQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AufteilungsschluesselQuery, AufteilungsschluesselQueryVariables>(AufteilungsschluesselDocument, options);
}
export type AufteilungsschluesselQueryHookResult = ReturnType<typeof useAufteilungsschluesselQuery>;
export type AufteilungsschluesselLazyQueryHookResult = ReturnType<typeof useAufteilungsschluesselLazyQuery>;
export type AufteilungsschluesselSuspenseQueryHookResult = ReturnType<typeof useAufteilungsschluesselSuspenseQuery>;
export type AufteilungsschluesselQueryResult = Apollo.QueryResult<AufteilungsschluesselQuery, AufteilungsschluesselQueryVariables>;
export const AufteilungsschluesselChangeHistoryListDocument = gql`
  query AufteilungsschluesselChangeHistoryList($aufteilungsschluesselId: ID!) {
    getAufteilungsschluesselChangeHistoryList(aufteilungsschluesselId: $aufteilungsschluesselId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useAufteilungsschluesselChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<AufteilungsschluesselChangeHistoryListQuery, AufteilungsschluesselChangeHistoryListQueryVariables> &
    ({ variables: AufteilungsschluesselChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AufteilungsschluesselChangeHistoryListQuery, AufteilungsschluesselChangeHistoryListQueryVariables>(
    AufteilungsschluesselChangeHistoryListDocument,
    options
  );
}
export function useAufteilungsschluesselChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AufteilungsschluesselChangeHistoryListQuery, AufteilungsschluesselChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AufteilungsschluesselChangeHistoryListQuery, AufteilungsschluesselChangeHistoryListQueryVariables>(
    AufteilungsschluesselChangeHistoryListDocument,
    options
  );
}
export function useAufteilungsschluesselChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AufteilungsschluesselChangeHistoryListQuery, AufteilungsschluesselChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AufteilungsschluesselChangeHistoryListQuery, AufteilungsschluesselChangeHistoryListQueryVariables>(
    AufteilungsschluesselChangeHistoryListDocument,
    options
  );
}
export type AufteilungsschluesselChangeHistoryListQueryHookResult = ReturnType<typeof useAufteilungsschluesselChangeHistoryListQuery>;
export type AufteilungsschluesselChangeHistoryListLazyQueryHookResult = ReturnType<typeof useAufteilungsschluesselChangeHistoryListLazyQuery>;
export type AufteilungsschluesselChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useAufteilungsschluesselChangeHistoryListSuspenseQuery>;
export type AufteilungsschluesselChangeHistoryListQueryResult = Apollo.QueryResult<
  AufteilungsschluesselChangeHistoryListQuery,
  AufteilungsschluesselChangeHistoryListQueryVariables
>;
