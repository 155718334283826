import _ from 'lodash';
import {
  VorsteuerkuerzungKontenZuweisungVersionFormValues,
  vstKuerzungKontenZuweisungDescriptionsForKommentar,
} from './vstKuerzungKontenZuweisungVersionFormMapper';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { VorsteuerkuerzungEinstellung } from '../../../../../types';

export const isSameAsPreviousVersion = (
  formValues: VorsteuerkuerzungKontenZuweisungVersionFormValues,
  previousTimeblock?: ITimeblock<VorsteuerkuerzungEinstellung>
) => {
  const formAufwandskonten = formValues.kontenZuweisungList.map((konto) => konto.aufwandsKontoIdList).flat();
  const prevTimeblockAufwandskonten = previousTimeblock?.kontenZuweisungList
    .map((konto) => konto.aufwandsKontoList)
    .flat()
    .map((konto) => konto.kontoId);

  const formVorsteuerkuerzungKonto = formValues.kontenZuweisungList.map((konto) => konto.vorsteuerkuerzungKontoId).flat();
  const prevTimeblockVorsteuerkuerzungKonto = previousTimeblock?.kontenZuweisungList.map((konto) => konto.vorsteuerkuerzungKonto.kontoId).flat();

  const formAufteilungsschluessel = formValues.kontenZuweisungList.map((konto) => konto.aufteilungsschluesselId).flat();
  const prevTimeblockAufteilungsschluessel = previousTimeblock?.kontenZuweisungList
    .map((konto) => konto.aufteilungsschluessel.aufteilungsschluesselId)
    .flat();

  const formVorsteuerkuerzungEigennutzungKonto = formValues.kontenZuweisungList.map((konto) => konto.vorsteuerkuerzungEigennutzungKontoId).flat();
  const prevTimeblockVorsteuerkuerzungEigennutzungKonto = previousTimeblock?.kontenZuweisungList
    .map((konto) => konto.vorsteuerkuerzungEigennutzungKonto?.kontoId)
    .flat();

  const formVorsteuerkuerzungPauschalmieterKonto = formValues.kontenZuweisungList.map((konto) => konto.vorsteuerkuerzungPauschalmieterKontoId).flat();
  const prevTimeblockVorsteuerkuerzungPauschalmieterKonto = previousTimeblock?.kontenZuweisungList
    .map((konto) => konto.vorsteuerkuerzungPauschalmieterKonto?.kontoId)
    .flat();

  return (
    _.isEqual(formAufwandskonten, prevTimeblockAufwandskonten) &&
    _.isEqual(formVorsteuerkuerzungKonto, prevTimeblockVorsteuerkuerzungKonto) &&
    _.isEqual(formAufteilungsschluessel, prevTimeblockAufteilungsschluessel) &&
    _.isEqual(formVorsteuerkuerzungEigennutzungKonto, prevTimeblockVorsteuerkuerzungEigennutzungKonto) &&
    _.isEqual(formVorsteuerkuerzungPauschalmieterKonto, prevTimeblockVorsteuerkuerzungPauschalmieterKonto)
  );
};

export const buildKommentarText = (
  restInitialValues: VorsteuerkuerzungKontenZuweisungVersionFormValues,
  restValues: VorsteuerkuerzungKontenZuweisungVersionFormValues
) => {
  const changedValuesDescriptions: string[] = [];

  const initialKontenZuweisungList = restInitialValues.kontenZuweisungList;
  const kontenZuweisungList = restValues.kontenZuweisungList;

  const deleteItem = (name: string) => {
    const itemToDelete = changedValuesDescriptions.indexOf(name);
    changedValuesDescriptions.splice(itemToDelete);
  };

  if (kontenZuweisungList.length > 0) {
    const initialValuesAufwandskonten = initialKontenZuweisungList.map((konto) => konto.aufwandsKontoIdList);
    const valuesAufwandskonten = kontenZuweisungList.map((konto) => konto.aufwandsKontoIdList);

    const initialValuesVorsteuerkuerzungKonto = initialKontenZuweisungList.map((konto) => konto.vorsteuerkuerzungKontoId);
    const valuesVorsteuerkuerzungKonto = kontenZuweisungList.map((konto) => konto.vorsteuerkuerzungKontoId);

    const initialValuesAufteilungsschluessel = initialKontenZuweisungList.map((konto) => konto.aufteilungsschluesselId);
    const valuesAufteilungsschluessel = kontenZuweisungList.map((konto) => konto.aufteilungsschluesselId);

    const initialValuesVorsteuerkuerzungEigennutzungKonto = initialKontenZuweisungList.map((konto) => konto.vorsteuerkuerzungEigennutzungKontoId);
    const valuesVorsteuerkuerzungEigennutzungKonto = kontenZuweisungList.map((konto) => konto.vorsteuerkuerzungEigennutzungKontoId);

    const initialValuesVorsteuerkuerzungPauschalmieterKonto = initialKontenZuweisungList.map((konto) => konto.vorsteuerkuerzungPauschalmieterKontoId);
    const valuesVorsteuerkuerzungPauschalmieterKonto = kontenZuweisungList.map((konto) => konto.vorsteuerkuerzungPauschalmieterKontoId);

    const initialValuesVorsteuerkuerzungWeMieterKonto = initialKontenZuweisungList.map((konto) => konto.vorsteuerkuerzungWeMieterKontoId);
    const valuesVorsteuerkuerzungWeMieterKonto = kontenZuweisungList.map((konto) => konto.vorsteuerkuerzungWeMieterKontoId);

    const isAufwandskontenEqual = _.isEqual(initialValuesAufwandskonten, valuesAufwandskonten);
    const isAufteilungschluesselEqual = _.isEqual(initialValuesAufteilungsschluessel, valuesAufteilungsschluessel);
    const isVorsteuerkuerzungKontoEqual = _.isEqual(initialValuesVorsteuerkuerzungKonto, valuesVorsteuerkuerzungKonto);
    const isVorsteuerkuerzungEigennutzungKontoEqual = _.isEqual(
      initialValuesVorsteuerkuerzungEigennutzungKonto,
      valuesVorsteuerkuerzungEigennutzungKonto
    );
    const isVorsteuerkuerzungPauschalmieterKontoEqual = _.isEqual(
      initialValuesVorsteuerkuerzungPauschalmieterKonto,
      valuesVorsteuerkuerzungPauschalmieterKonto
    );

    const isVorsteuerkuerzungWeMieterKontoEqual = _.isEqual(initialValuesVorsteuerkuerzungWeMieterKonto, valuesVorsteuerkuerzungWeMieterKonto);

    if (!isAufteilungschluesselEqual) {
      changedValuesDescriptions.push(vstKuerzungKontenZuweisungDescriptionsForKommentar.aufteilungsschluesselId);
    } else if (changedValuesDescriptions.includes(vstKuerzungKontenZuweisungDescriptionsForKommentar.aufteilungsschluesselId)) {
      deleteItem(vstKuerzungKontenZuweisungDescriptionsForKommentar.aufteilungsschluesselId);
    }

    if (!isAufwandskontenEqual) {
      changedValuesDescriptions.push(vstKuerzungKontenZuweisungDescriptionsForKommentar.aufwandsKontoIdList);
    } else if (changedValuesDescriptions.includes(vstKuerzungKontenZuweisungDescriptionsForKommentar.aufwandsKontoIdList)) {
      deleteItem(vstKuerzungKontenZuweisungDescriptionsForKommentar.aufwandsKontoIdList);
    }

    if (!isVorsteuerkuerzungKontoEqual) {
      changedValuesDescriptions.push(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungKontoId);
    } else if (changedValuesDescriptions.includes(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungKontoId)) {
      deleteItem(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungKontoId);
    }

    if (!isVorsteuerkuerzungEigennutzungKontoEqual) {
      changedValuesDescriptions.push(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungEigennutzungKontoId);
    } else if (changedValuesDescriptions.includes(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungEigennutzungKontoId)) {
      deleteItem(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungEigennutzungKontoId);
    }

    if (!isVorsteuerkuerzungPauschalmieterKontoEqual) {
      changedValuesDescriptions.push(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungPauschalmieterKontoId);
    } else if (changedValuesDescriptions.includes(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungPauschalmieterKontoId)) {
      deleteItem(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungPauschalmieterKontoId);
    }

    if (!isVorsteuerkuerzungWeMieterKontoEqual) {
      changedValuesDescriptions.push(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungWeMieterKontoId);
    } else if (changedValuesDescriptions.includes(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungWeMieterKontoId)) {
      deleteItem(vstKuerzungKontenZuweisungDescriptionsForKommentar.vorsteuerkuerzungWeMieterKontoId);
    }
  }
  return changedValuesDescriptions.length !== 0 ? changedValuesDescriptions.join(', ').concat(' geändert') : '';
};
