import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { auftragsartCreateParamFormValidationSchema } from '../shared/ParamForm/Schema/auftragsartCreateParamFormValidationSchema';
import { AuftragsartUpdateParamFormValues, mapAuftragsartToFormValues } from './ParameterForm/auftragsartUpdateParamFormMapper';
import { useUpdateAuftragsartMutation } from '../../gql/AuftragsartMutations.types';
import { mapFormValuesToAuftragsart } from './auftragsartUpdateFormMapper';
import { showSuccessMsgUpdate } from '../../../../components/message';
import AuftragsartUpdateFormContent from './AuftragsartUpdateFormContent';
import { mapTextbausteinListToFormValues, TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { AuftragsartFieldsFragment } from '../../gql/AuftragsartFragments.types';

type Props = { auftragsart: AuftragsartFieldsFragment; onSuccess: () => void };

const AuftragsartUpdateForm: FC<Props> = ({ auftragsart, onSuccess }) => {
  const initialValues = mapAuftragsartToFormValues(auftragsart);

  const [auftragsartTemplateValues, setAuftragsartTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(auftragsart.textbausteinList)
  );

  const [runUpdate] = useUpdateAuftragsartMutation();

  const onUpdateAuftragsart = (values: AuftragsartUpdateParamFormValues) => {
    const input = mapFormValuesToAuftragsart(auftragsartTemplateValues, values);
    runUpdate({
      variables: {
        auftragsartId: auftragsart.auftragsartId,
        input,
      },
    }).then(() => {
      showSuccessMsgUpdate('Auftragsart');
      onSuccess();
    });
  };

  return (
    <Formik<AuftragsartUpdateParamFormValues>
      initialValues={initialValues}
      onSubmit={(formValues) => {
        onUpdateAuftragsart(formValues);
      }}
      validationSchema={auftragsartCreateParamFormValidationSchema}
    >
      {(formikProps) => (
        <AuftragsartUpdateFormContent
          auftragsart={auftragsart}
          formikProps={formikProps}
          auftragsartTemplateValues={auftragsartTemplateValues}
          setAuftragsartTemplateValues={setAuftragsartTemplateValues}
          onSubmit={onUpdateAuftragsart}
        />
      )}
    </Formik>
  );
};

export default AuftragsartUpdateForm;
