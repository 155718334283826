import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetObjektVorschreibungspositionListMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  input: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type SetObjektVorschreibungspositionListMutation = {
  setObjektVorschreibungspositionList: {
    data: Array<{
      bezeichnung: string;
      bezeichnungOriginal: string;
      bezeichnungOverwritten?: string | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektVorschreibungspositionId: string;
      tagList: Array<string>;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      objektVorschreibungspositionStatus: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      } | null;
      vorschreibungspositionStatus: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateObjektVorschreibungspositionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektVorschreibungspositionId: Types.Scalars['ID']['input'];
  input: Types.ObjektVorschreibungspositionUpdateInput;
}>;

export type UpdateObjektVorschreibungspositionMutation = {
  updateObjektVorschreibungsposition: {
    data: {
      bezeichnung: string;
      bezeichnungOriginal: string;
      bezeichnungOverwritten?: string | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektVorschreibungspositionId: string;
      tagList: Array<string>;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      objektVorschreibungspositionStatus: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      } | null;
      vorschreibungspositionStatus: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveObjektVorschreibungspositionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektVorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type ArchiveObjektVorschreibungspositionMutation = {
  actionArchiveObjektVorschreibungsposition: {
    data: { objektVorschreibungspositionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveObjektVorschreibungspositionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektVorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveObjektVorschreibungspositionMutation = {
  actionUnarchiveObjektVorschreibungsposition: {
    data: { objektVorschreibungspositionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetObjektVorschreibungspositionVertragsartListMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektVorschreibungspositionId: Types.Scalars['ID']['input'];
  vertragsartList: Array<Types.Vertragsart> | Types.Vertragsart;
}>;

export type SetObjektVorschreibungspositionVertragsartListMutation = {
  setObjektVorschreibungspositionVertragsartList: {
    data: {
      bezeichnung: string;
      bezeichnungOriginal: string;
      bezeichnungOverwritten?: string | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektVorschreibungspositionId: string;
      tagList: Array<string>;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      objektVorschreibungspositionStatus: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      } | null;
      vertragsartList: Array<{ supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart }>;
      vorschreibungspositionStatus: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const SetObjektVorschreibungspositionListDocument = gql`
  mutation SetObjektVorschreibungspositionList($objektId: ID!, $input: [String!]!) {
    setObjektVorschreibungspositionList(objektId: $objektId, input: $input) {
      data {
        art {
          text
          value
        }
        bezeichnung
        bezeichnungOriginal
        bezeichnungOverwritten
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        objektVorschreibungspositionId
        objektVorschreibungspositionStatus {
          description
          text
          value
        }
        tagList
        umsatzsteuerkennzeichen {
          bezeichnung
          createTs
          createdBy
          createdByMitarbeiterId
          kuerzel
          status {
            text
            value
          }
          umsatzsteuerkennzeichenId
        }
        vorschreibungspositionId
        vorschreibungspositionStatus {
          description
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetObjektVorschreibungspositionListMutation(
  baseOptions?: Apollo.MutationHookOptions<SetObjektVorschreibungspositionListMutation, SetObjektVorschreibungspositionListMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetObjektVorschreibungspositionListMutation, SetObjektVorschreibungspositionListMutationVariables>(
    SetObjektVorschreibungspositionListDocument,
    options
  );
}
export type SetObjektVorschreibungspositionListMutationHookResult = ReturnType<typeof useSetObjektVorschreibungspositionListMutation>;
export type SetObjektVorschreibungspositionListMutationResult = Apollo.MutationResult<SetObjektVorschreibungspositionListMutation>;
export type SetObjektVorschreibungspositionListMutationOptions = Apollo.BaseMutationOptions<
  SetObjektVorschreibungspositionListMutation,
  SetObjektVorschreibungspositionListMutationVariables
>;
export const UpdateObjektVorschreibungspositionDocument = gql`
  mutation UpdateObjektVorschreibungsposition(
    $objektId: ID!
    $objektVorschreibungspositionId: ID!
    $input: ObjektVorschreibungspositionUpdateInput!
  ) {
    updateObjektVorschreibungsposition(objektId: $objektId, objektVorschreibungspositionId: $objektVorschreibungspositionId, input: $input) {
      data {
        art {
          text
          value
        }
        bezeichnung
        bezeichnungOriginal
        bezeichnungOverwritten
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        objektVorschreibungspositionId
        objektVorschreibungspositionStatus {
          description
          text
          value
        }
        tagList
        umsatzsteuerkennzeichen {
          bezeichnung
          createTs
          createdBy
          createdByMitarbeiterId
          kuerzel
          status {
            text
            value
          }
          umsatzsteuerkennzeichenId
        }
        vorschreibungspositionId
        vorschreibungspositionStatus {
          description
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateObjektVorschreibungspositionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateObjektVorschreibungspositionMutation, UpdateObjektVorschreibungspositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateObjektVorschreibungspositionMutation, UpdateObjektVorschreibungspositionMutationVariables>(
    UpdateObjektVorschreibungspositionDocument,
    options
  );
}
export type UpdateObjektVorschreibungspositionMutationHookResult = ReturnType<typeof useUpdateObjektVorschreibungspositionMutation>;
export type UpdateObjektVorschreibungspositionMutationResult = Apollo.MutationResult<UpdateObjektVorschreibungspositionMutation>;
export type UpdateObjektVorschreibungspositionMutationOptions = Apollo.BaseMutationOptions<
  UpdateObjektVorschreibungspositionMutation,
  UpdateObjektVorschreibungspositionMutationVariables
>;
export const ArchiveObjektVorschreibungspositionDocument = gql`
  mutation archiveObjektVorschreibungsposition($objektId: ID!, $objektVorschreibungspositionId: ID!) {
    actionArchiveObjektVorschreibungsposition(objektId: $objektId, objektVorschreibungspositionId: $objektVorschreibungspositionId) {
      data {
        objektVorschreibungspositionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveObjektVorschreibungspositionMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveObjektVorschreibungspositionMutation, ArchiveObjektVorschreibungspositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveObjektVorschreibungspositionMutation, ArchiveObjektVorschreibungspositionMutationVariables>(
    ArchiveObjektVorschreibungspositionDocument,
    options
  );
}
export type ArchiveObjektVorschreibungspositionMutationHookResult = ReturnType<typeof useArchiveObjektVorschreibungspositionMutation>;
export type ArchiveObjektVorschreibungspositionMutationResult = Apollo.MutationResult<ArchiveObjektVorschreibungspositionMutation>;
export type ArchiveObjektVorschreibungspositionMutationOptions = Apollo.BaseMutationOptions<
  ArchiveObjektVorschreibungspositionMutation,
  ArchiveObjektVorschreibungspositionMutationVariables
>;
export const UnarchiveObjektVorschreibungspositionDocument = gql`
  mutation unarchiveObjektVorschreibungsposition($objektId: ID!, $objektVorschreibungspositionId: ID!) {
    actionUnarchiveObjektVorschreibungsposition(objektId: $objektId, objektVorschreibungspositionId: $objektVorschreibungspositionId) {
      data {
        objektVorschreibungspositionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveObjektVorschreibungspositionMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveObjektVorschreibungspositionMutation, UnarchiveObjektVorschreibungspositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveObjektVorschreibungspositionMutation, UnarchiveObjektVorschreibungspositionMutationVariables>(
    UnarchiveObjektVorschreibungspositionDocument,
    options
  );
}
export type UnarchiveObjektVorschreibungspositionMutationHookResult = ReturnType<typeof useUnarchiveObjektVorschreibungspositionMutation>;
export type UnarchiveObjektVorschreibungspositionMutationResult = Apollo.MutationResult<UnarchiveObjektVorschreibungspositionMutation>;
export type UnarchiveObjektVorschreibungspositionMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveObjektVorschreibungspositionMutation,
  UnarchiveObjektVorschreibungspositionMutationVariables
>;
export const SetObjektVorschreibungspositionVertragsartListDocument = gql`
  mutation SetObjektVorschreibungspositionVertragsartList($objektId: ID!, $objektVorschreibungspositionId: ID!, $vertragsartList: [Vertragsart!]!) {
    setObjektVorschreibungspositionVertragsartList(
      objektId: $objektId
      objektVorschreibungspositionId: $objektVorschreibungspositionId
      vertragsartList: $vertragsartList
    ) {
      data {
        art {
          text
          value
        }
        bezeichnung
        bezeichnungOriginal
        bezeichnungOverwritten
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        objektVorschreibungspositionId
        objektVorschreibungspositionStatus {
          description
          text
          value
        }
        tagList
        umsatzsteuerkennzeichen {
          bezeichnung
          createTs
          createdBy
          createdByMitarbeiterId
          kuerzel
          status {
            text
            value
          }
          umsatzsteuerkennzeichenId
        }
        vertragsartList {
          supportsExplicitVertragsEnde
          text
          value
        }
        vorschreibungspositionId
        vorschreibungspositionStatus {
          description
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetObjektVorschreibungspositionVertragsartListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetObjektVorschreibungspositionVertragsartListMutation,
    SetObjektVorschreibungspositionVertragsartListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetObjektVorschreibungspositionVertragsartListMutation, SetObjektVorschreibungspositionVertragsartListMutationVariables>(
    SetObjektVorschreibungspositionVertragsartListDocument,
    options
  );
}
export type SetObjektVorschreibungspositionVertragsartListMutationHookResult = ReturnType<
  typeof useSetObjektVorschreibungspositionVertragsartListMutation
>;
export type SetObjektVorschreibungspositionVertragsartListMutationResult =
  Apollo.MutationResult<SetObjektVorschreibungspositionVertragsartListMutation>;
export type SetObjektVorschreibungspositionVertragsartListMutationOptions = Apollo.BaseMutationOptions<
  SetObjektVorschreibungspositionVertragsartListMutation,
  SetObjektVorschreibungspositionVertragsartListMutationVariables
>;
