import React, { FC } from 'react';
import { TopAbrAbrechnungkreis } from '../../../../../../types';
import AufwandAufteilungTable from './AufwandAufteilungTable';

type Props = {
  abrKreis: TopAbrAbrechnungkreis;
};

const AufwandAufteilungRows: FC<Props> = ({ abrKreis }) => (
  <>
    {abrKreis.aufwandAufteilungList.map((aufwandAufteilung, index) => (
      <AufwandAufteilungTable aufwandAufteilung={aufwandAufteilung} key={aufwandAufteilung.aufteilungsschluesselBezeichnung} />
    ))}
  </>
);

export default AufwandAufteilungRows;
