import { Dayjs } from 'dayjs';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { AuftragVerbuchenGenerierlaufInput } from '../../../../../types';
import { ALLE_TAG_TEXT } from '../../../../../shared/ObjektSelect/ObjektSelectWithAllTag';

export type VorschreibungVerbuchenModalFormValues = {
  buchungsdatum: Dayjs;
  generierDatum: Dayjs;
  objektIdList?: string[];
  dueDate?: Dayjs | null;
};

export const vorschreibungVerbuchenModalFormFields: FormFields<VorschreibungVerbuchenModalFormValues> = {
  buchungsdatum: 'buchungsdatum',
  generierDatum: 'generierDatum',
  objektIdList: 'objektIdList',
  dueDate: 'dueDate',
};

export const mapFormValuesToVorschreibungVerbuchen = (values: VorschreibungVerbuchenModalFormValues): AuftragVerbuchenGenerierlaufInput => ({
  buchungsdatum: mapFormDateValueToDateString(values.buchungsdatum),
  generierDatum: mapFormDateValueToDateString(values.generierDatum),
  objektIdList: values.objektIdList?.includes(ALLE_TAG_TEXT) ? null : values.objektIdList,
  dueDate: values.dueDate ? mapFormDateValueToDateString(values.dueDate) : null,
});
