import { Space } from 'antd';
import styled from 'styled-components';

export const StyledListSummarySpace = styled(Space)`
  width: 100%;
  gap: 32px !important;

  align-items: center;
  justify-content: center;

  padding: 8px;
  margin-bottom: 12px;
`;

export const StyledListSummaryText = styled(Space)`
  & > :first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
