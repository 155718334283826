import { GenerierlaufEntryExitCode, PaymentTransactionType, PaymentType } from '../../../../../types';
import { FormFields } from '../../../../../helpers/formikHelper';
import { TPaymentProposalVerarbeitungQueryParams } from './filtersQueryParams';

export type FiltersFormValues = {
  exitCodeList?: GenerierlaufEntryExitCode[];
  objektIdList?: string[];
  paymentTransactionType?: PaymentTransactionType;
  paymentTypeList?: PaymentType[];
  rechnungsAusstellerId?: string;
};

export const listingFiltersFormFields: FormFields<FiltersFormValues> = {
  exitCodeList: 'exitCodeList',
  objektIdList: 'objektIdList',
  paymentTransactionType: 'paymentTransactionType',
  paymentTypeList: 'paymentTypeList',
  rechnungsAusstellerId: 'rechnungsAusstellerId',
};

export const mapQueryParamsToFormValues = (queryParams: TPaymentProposalVerarbeitungQueryParams): FiltersFormValues => ({
  objektIdList: queryParams.objektIdList,
  exitCodeList: queryParams.exitCodeList,
  rechnungsAusstellerId: queryParams.rechnungsAusstellerId,
  paymentTypeList: queryParams.paymentTypeList,
  paymentTransactionType: queryParams.paymentTransactionType,
});

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TPaymentProposalVerarbeitungQueryParams => ({
  exitCodeList: formValues.exitCodeList,
  objektIdList: formValues.objektIdList,
  paymentTransactionType: formValues.paymentTransactionType,
  paymentTypeList: formValues.paymentTypeList,
  rechnungsAusstellerId: formValues.rechnungsAusstellerId,
});
