import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../styles/pdfStyles';
import { KontoListHeader } from '../../../../../topAbrechnung-types';

const TAAbrKreisKontoListColumnTitles: FC<{
  kontoListHeader: KontoListHeader;
}> = ({ kontoListHeader }) => (
  <View>
    <View style={[pdfStyles.row, pdfStyles.textNormal]}>
      <View style={[pdfStyles.column, { width: '40%' }]} />
      <Text
        style={[
          pdfStyles.column,
          {
            width: '20%',
            textAlign: 'right',
          },
        ]}
      >
        {kontoListHeader.betragNettoText}
      </Text>
      <Text
        style={[
          pdfStyles.column,
          {
            width: '20%',
            textAlign: 'right',
          },
        ]}
      >
        {kontoListHeader.betragUstText}
      </Text>
      <Text
        style={[
          pdfStyles.column,
          {
            width: '20%',
            textAlign: 'right',
          },
        ]}
      >
        {kontoListHeader.betragBelastungText}
      </Text>
    </View>
  </View>
);

export default TAAbrKreisKontoListColumnTitles;
