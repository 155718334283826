import { Dayjs } from 'dayjs';
import { FormFields } from '../../../../../helpers/formikHelper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { AuftragGenerierlaufInput } from '../../../../../types';
import { ALLE_TAG_TEXT } from '../../../../../shared/ObjektSelect/ObjektSelectWithAllTag';
import { getDifferenceBetweenTwoMonths } from '../../../../shared/getDifferenceBetweenTwoMonths';

export interface VorschreibungErstellenFormValues {
  generierDatum: Dayjs;
  objektIdList?: string[];
  vorschreibungsperiodeList?: string[] | null;
}

export const vorschreibungErstellenVerarbeitungInitialValues = {
  generierDatum: dayjsCustom(),
  vorschreibungsperiodeList: null,
};

export const vorschreibungErstellenFormFields: FormFields<VorschreibungErstellenFormValues> = {
  generierDatum: 'generierDatum',
  objektIdList: 'objektIdList',
  vorschreibungsperiodeList: 'vorschreibungsperiodeList',
};

export const mapFormValuesToVorschreibung = (values: VorschreibungErstellenFormValues): AuftragGenerierlaufInput => ({
  generierDatum: values.generierDatum.format('YYYY-MM-DD'),
  objektIdList: values.objektIdList?.includes(ALLE_TAG_TEXT) ? null : values.objektIdList,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  vorschreibungsperiodeList: getDifferenceBetweenTwoMonths(values.vorschreibungsperiodeList![0], values.vorschreibungsperiodeList![1]),
});
