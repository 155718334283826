import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import WizardListingForm from '../../features/Wizard/Listing/WizardListingForm';

const WizardPage = () => {
  return (
    <>
      <Helmet>
        <title>Assistenten</title>
      </Helmet>
      <PageLayout>
        <WizardListingForm />
      </PageLayout>
    </>
  );
};

export default WizardPage;
