import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { RechtstraegerType } from '../../../../types';

type Props = SelectProps;

const RechtstraegerTypeListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const list = [
    {
      text: 'Firma',
      value: RechtstraegerType.Firma,
    },
    {
      text: 'Personengemeinschaft',
      value: RechtstraegerType.PersonenGemeinschaft,
    },
    {
      text: 'Natürliche Person',
      value: RechtstraegerType.NatuerlichePerson,
    },
  ];

  return (
    <Select name={name} size="middle" placeholder="" allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {list.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RechtstraegerTypeListFormSelect;
