import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../security/AuthRoute';
import AndromedaSysSettingsAbrechnungsdefinitionCreatePage from './AndromedaSysSettingsAbrechnungsdefinitionCreatePage';
import { isAdmin } from '../../../../../security/permissionChecks';
import { URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION } from '../../../../../features/AbrechnungsdefinitionTemplate/abrDefTemplateUriPaths';

const andromedaSysSettingsAbrechnungsdefinitionCreatePageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION.createPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsAbrechnungsdefinitionCreatePage />} />}
  />
);

export default andromedaSysSettingsAbrechnungsdefinitionCreatePageRoute;
