import React, { FC } from 'react';
import { Typography } from 'antd';
import { EuroAmount, Percent } from '../../../../../components/Number';
import { createRowWithCells, TextWithStyling } from '../../shared/abrechnungTableHelper';
import { TopAbrUstVomAufwandAnteil } from '../../../../../types';

type UstVomAufwandDetailsProps = {
  zeitScheibe: TopAbrUstVomAufwandAnteil;
};

const UstVomAufwandDetails: FC<UstVomAufwandDetailsProps> = ({ zeitScheibe }) => {
  const texts: TextWithStyling[] = [
    { render: '' },
    { render: <EuroAmount value={zeitScheibe.basis} /> },
    { render: <EuroAmount value={zeitScheibe.anteilNetto} /> },
    { render: <Percent value={zeitScheibe.ustVomAufwandSteuersatz / 100} /> },
    { render: <EuroAmount value={zeitScheibe.ustVomAufwand} /> },
    { render: <EuroAmount value={zeitScheibe.summe} /> },
    { render: <Typography.Text>{zeitScheibe.grundlage.text}</Typography.Text> },
    { render: <EuroAmount value={zeitScheibe.belastung} /> },
  ];

  return createRowWithCells(texts, false);
};

export default UstVomAufwandDetails;
