import React from 'react';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { useShowArchivedQueryParamState } from '../../hooks/useShowArchivedQueryParamState';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../hooks/useQueryWithPagingAndSorting';
import { useKsAufteilungsschluesselListQuery } from './gql/AufteilungsschluesselQueries.types';
import AufteilungschluesselActionButtonsAndDrawer from './AufteilungschluesselActionButtonsAndDrawer';
import AufteilungsschluesselTable from './Table/AufteilungsschluesselTable';
import { usePaginationQueryParams } from '../../hooks/usePaginationQueryParams';

const AufteilungsschluesselListing = () => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, refetch, pagination } = useQueryWithPagingAndSorting(
    useKsAufteilungsschluesselListQuery,
    {
      variables: {
        kundenSystemId,
        withDetails: true,
        includeArchiviert: showArchived,
      },
    },
    {
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );
  const aufteilungsschluesselList = data?.getAufteilungsschluesselListByKundenSystemId.data?.contentList;
  const loading = !aufteilungsschluesselList;

  return (
    <>
      <AufteilungschluesselActionButtonsAndDrawer refetch={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
      <AufteilungsschluesselTable aufteilungsschluesselList={aufteilungsschluesselList} loading={loading} pagination={pagination} refetch={refetch} />
    </>
  );
};

export default AufteilungsschluesselListing;
