import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { generatePathToBankstatementListingPage } from '../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';
import LinkToBelegFile from '../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { FibuBuchungszeile } from '../../../types';

type Props = {
  buchungszeile: FibuBuchungszeile;
};

const BuchungszeileBelegnummer: FC<Props> = ({ buchungszeile }) => {
  if (buchungszeile.buchungsKopf.dataCarrierBelegId && buchungszeile.buchungsKopf.belegnummer) {
    return (
      <DataWithShortenedText maxTextLength={20} text={buchungszeile.buchungsKopf.belegnummer}>
        {(shortenedText) => (
          <Link
            target="_blank"
            to={generatePathToBankstatementListingPage({
              iban: buchungszeile.buchungsKopf.iban,
              statementNumber: buchungszeile.buchungsKopf.statementNumber,
            })}
          >
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    );
  }

  if (buchungszeile.buchungsKopf.belegFileId && buchungszeile.buchungsKopf.belegnummer) {
    return <LinkToBelegFile fileId={buchungszeile.buchungsKopf.belegFileId} belegnummer={buchungszeile.buchungsKopf.belegnummer} />;
  }

  return <TextForEmptyValue textToShow="minus" />;
};

export default BuchungszeileBelegnummer;
