import React, { FC } from 'react';
import { Skeleton, Typography } from 'antd';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import { Konto } from '../../../types';

type Props = {
  konto: Konto;
  loading: boolean;
};

const KontoCardContent: FC<Props> = ({ loading, konto }) => {
  return (
    <Skeleton active loading={loading}>
      <Typography.Title level={5}>Allgemeine Information</Typography.Title>
      <CardDetailsInfoRow infoRowTitle="Kontoklasse">
        <Typography.Text>{konto.klasse.text}</Typography.Text>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Nummer">
        <Typography.Text>{konto.nummer}</Typography.Text>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Kontotyp">
        <Typography.Text>{konto.type.text}</Typography.Text>
      </CardDetailsInfoRow>
      {konto.verwendung && (
        <CardDetailsInfoRow infoRowTitle="Kontoverwendung">
          <Typography.Text>{konto.verwendung.text}</Typography.Text>
        </CardDetailsInfoRow>
      )}
    </Skeleton>
  );
};

export default KontoCardContent;
