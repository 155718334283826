import React, { FC } from 'react';
import { showSuccessMsgDelete } from '../../../../../../../components/message';
import WeiterePersonUpdateForm from '../../../Form/WeiterePerson/Update/WeiterePersonUpdateForm';
import { useDeleteBeVertragInfoFeldMutation } from '../../../gql/VertragInfoFeldMutations.types';
import { VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment } from '../../../gql/VertragInfoFeldFragments.types';
import InfoFeldListItemActions from '../InfoFeldListItemActions';
import useTicketCount from '../../../../../../Ticket/useTicketCount';

type Props = {
  infoFeld: VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  onSuccess: () => void;
};

const WeiterePersonListItemActions: FC<Props> = ({ infoFeld, objektId, bestandseinheitId, vertragId, onSuccess }) => {
  const { ticketCount, refetchTicketCount } = useTicketCount({ vertragInfoFeldId: infoFeld.vertragInfoFeldId });

  const onActionSuccess = () => {
    onSuccess();
    refetchTicketCount();
  };

  const [runDelete] = useDeleteBeVertragInfoFeldMutation({
    variables: { objektId, bestandseinheitId, vertragId, infoFeldId: infoFeld.vertragInfoFeldId },
    onCompleted: () => {
      showSuccessMsgDelete(`Weitere Person Infofeld`);
      onActionSuccess();
    },
  });

  return (
    <InfoFeldListItemActions
      onDelete={runDelete}
      ticketCount={ticketCount}
      fileIdList={infoFeld.fileIdList}
      postIt={infoFeld.postIt}
      objektId={objektId}
      bestandseinheitId={bestandseinheitId}
      vertragId={vertragId}
      vertragInfoFeldId={infoFeld.vertragInfoFeldId}
      onSuccess={onActionSuccess}
      deletable={infoFeld.deletable}
      infoFeldStatus={infoFeld.status}
      renderUpdateForm={(closeDrawer, activeKeyTab) => (
        <WeiterePersonUpdateForm
          infoFeld={infoFeld}
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          onInfoFeldAction={() => {
            onActionSuccess();
            closeDrawer();
          }}
          onTicketAction={onActionSuccess}
          onCancel={closeDrawer}
          activeKeyTab={activeKeyTab}
        />
      )}
    />
  );
};

export default WeiterePersonListItemActions;
