import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import { useEingangsRechnungVerrechnungsartListQuery } from '../../gql/VerrechnungsartQueries.types';
import { selectFilterOption } from '../../../../helpers/selectHelper';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const VerrechnungsartListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useEingangsRechnungVerrechnungsartListQuery();
  const verrechnungsartList = data?.getEingangsRechnungVerrechnungsartList.data ?? [];

  return (
    <Select
      {...restProps}
      id={restProps.name}
      size="small"
      loading={loading}
      placeholder="Kundennummer"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {verrechnungsartList.map((verrechnungsart) => (
        <Select.Option key={verrechnungsart.value} value={verrechnungsart.value}>
          <Typography.Text>{verrechnungsart.text}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default VerrechnungsartListFormSelect;
