import React, { FC } from 'react';
import { SelectProps } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import BankDetailsCreateDrawer from '../Drawer/BankDetailsCreateDrawer';
import { useToggle } from '../../../hooks/useToggle';
import { useRechtstraegerBankDetailsListQuery } from '../gql/BankDetailsQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import EntityStatusFormSelect from '../../../components/Status/EntityStatus/MainTypes/EntityStatusFormSelect';
import { BankDetails } from '../../../types';

type Props = {
  rechtstraegerId: string | undefined;
  onHasOnlyOneBankDetails?: (id: string) => void;
  onNewItemCreateSelected?: () => void;
} & SelectProps;

const RechtstraegerBankDetailsSelect: FC<Props> = ({ rechtstraegerId, onHasOnlyOneBankDetails, onNewItemCreateSelected, ...restProps }) => {
  const [isCreateNewDrawerOpen, toggleCreateNewDrawer] = useToggle();
  const onNewItemCreateSelectedOpenDrawer = () => {
    onNewItemCreateSelected?.();
    toggleCreateNewDrawer();
  };

  const { loading, data, refetch } = useRechtstraegerBankDetailsListQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      rechtstraegerId: rechtstraegerId!,
      includeArchiviert: true,
    },
    skip: !rechtstraegerId,
    onCompleted: (data) => {
      const list = data.getRechtstraegerBankDetailsList.data;
      if (list.length === 1) {
        onHasOnlyOneBankDetails?.(list[0].bankDetailsId);
      }
    },
  });

  const bankDetailsList = data?.getRechtstraegerBankDetailsList.data;

  return (
    <>
      <EntityStatusFormSelect<BankDetails>
        size="small"
        list={bankDetailsList}
        getSelectItemId={(item) => item.bankDetailsId}
        getSelectItemContent={(item) => `${item.bankname} ${friendlyFormatIBAN(item.iban)}`}
        getSelectItemTooltipTitle={(item) => `${item.bankname} ${friendlyFormatIBAN(item.iban)}`}
        {...restProps}
        id={restProps.name}
        loading={loading}
        placeholder="Bankverbindung auswählen"
        filterOption={selectFilterOption}
        onNewItemCreateSelected={onNewItemCreateSelectedOpenDrawer}
        newItemText="Neue Bankverbindung anlegen"
      />
      {onNewItemCreateSelected && (
        <BankDetailsCreateDrawer
          isDrawerOpen={isCreateNewDrawerOpen}
          toggleDrawerOpen={toggleCreateNewDrawer}
          rechtstraegerId={rechtstraegerId}
          onActionSuccess={refetch}
          onSetFieldValue={onHasOnlyOneBankDetails}
        />
      )}
    </>
  );
};

export default RechtstraegerBankDetailsSelect;
