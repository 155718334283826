import React, { FC } from 'react';
import { Empty } from 'antd';
import GenerierlaufStatusExpandedRow from '../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../verarbeitungHelpers';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { VertragVposIndexedValue } from '../../../../types';
import entryTableColumns from './entryTableColumns';
import { findEntryIndexByBestandseinheitId } from './entryTableHelpers';
import { TVertagVposIndexedValueVerarbeitungQueryParams } from '../../shared/VertragVposIndexedValue/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { useApplyVertragVposIndexedValueGenerierlaufEntryListQuery } from '../../gql/ApplyVertragVposIndexedValueGenerierlauf/ApplyVertragVposIndexedValueGenerierlaufQueries.types';

type Props = {
  queryParams: TVertagVposIndexedValueVerarbeitungQueryParams;
  generierlaufId: string;
};

const EntryTable: FC<Props> = ({ queryParams, generierlaufId }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useApplyVertragVposIndexedValueGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getApplyVertragVposIndexedValueGenerierlaufEntryList.data.contentList || [];

  const indexedValueList = entryList.flatMap((entry) => entry.indexedValue);

  return (
    <TableWithColSelector<VertragVposIndexedValue>
      loading={loading}
      size="small"
      dataSource={indexedValueList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={entryTableColumns(entryList)}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      rowKey={(record) => record.bestandseinheitId}
      expandable={{
        expandedRowRender: (record) => {
          const entry = entryList[findEntryIndexByBestandseinheitId(entryList, record.bestandseinheitId)];
          return <GenerierlaufStatusExpandedRow generierlaufEntryList={entry} />;
        },
        rowExpandable: (record) => {
          const entry = entryList[findEntryIndexByBestandseinheitId(entryList, record.bestandseinheitId)];
          return isVerarbeitungEntryTableRowExpandable(entry);
        },
      }}
      filterIdentifier="verarbeitung-vertrag-vpos-indexed-value"
    />
  );
};

export default EntryTable;
