import Fetcher from '../../helpers/fetcher';
import appFieldHelpSelectors from './data/appFieldHelpSelectors.json';
import fibuFieldHelpSelectors from './data/fibuFieldHelpSelectors.json';
import orderFieldHelpSelectors from './data/orderFieldHelpSelectors.json';
import paymentFieldHelpSelectors from './data/paymentFieldHelpSelectors.json';
// import fieldHelpSelectors_old from './data/fieldHelpSelectors_old.json'; // DO NOT DELETE THIS IMPORT even if it's commented out

// Function to fetch JSON data from the API using Fetcher
const fetchJsonFromApi = async (url: string): Promise<any> => {
  const fetcher = Fetcher.getInstance();
  const response = await fetcher.fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch data from ${url}`);
  }
  return response.json();
};

// Function to compare two JSON objects and log differences
const compareJsonFiles = (localJson: any, remoteJson: any, serviceName: string): void => {
  const fieldsInLocalJsonNotInRemoteJson: string[] = [];
  const fieldsInRemoteJsonNotInLocalJson: string[] = [];
  const secondLevelFieldsInLocalJsonNotInRemoteJson: string[] = [];
  const secondLevelFieldsInRemoteJsonNotInLocalJson: string[] = [];

  const compare = (local: any, remote: any, parentKey = '', arrayLevel1: string[], arrayLevel2: string[]): void => {
    const keys1 = new Set(Object.keys(local));
    const keys2 = new Set(Object.keys(remote));

    keys1.forEach((key) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      if (!keys2.has(key)) {
        if (parentKey) {
          arrayLevel2.push(fullKey);
        } else {
          arrayLevel1.push(fullKey);
        }
      } else if (typeof local[key] === 'object' && local[key] !== null) {
        compare(local[key], remote[key], fullKey, arrayLevel1, arrayLevel2);
      }
    });
  };

  compare(localJson, remoteJson, '', fieldsInLocalJsonNotInRemoteJson, secondLevelFieldsInLocalJsonNotInRemoteJson);
  compare(remoteJson, localJson, '', fieldsInRemoteJsonNotInLocalJson, secondLevelFieldsInRemoteJsonNotInLocalJson);

  // Disabling eslint console warnings to keep the output clean, as these assertions are for debugging purposes and need to be visible
  // eslint-disable-next-line no-console
  console.assert(
    fieldsInLocalJsonNotInRemoteJson.length === 0,
    `${serviceName} Field help - selectors list discrepancies: Fields in locale file but not in file on server:`,
    fieldsInLocalJsonNotInRemoteJson
  );
  // eslint-disable-next-line no-console
  console.assert(
    fieldsInRemoteJsonNotInLocalJson.length === 0,
    `${serviceName} Field help - selectors list discrepancies: Fields in file on server but not in locale file:`,
    fieldsInRemoteJsonNotInLocalJson
  );
  // eslint-disable-next-line no-console
  console.assert(
    secondLevelFieldsInLocalJsonNotInRemoteJson.length === 0,
    `${serviceName} Field help - selectors list discrepancies: Second level fields in locale file but not in file on server:`,
    secondLevelFieldsInLocalJsonNotInRemoteJson
  );
  // eslint-disable-next-line no-console
  console.assert(
    secondLevelFieldsInRemoteJsonNotInLocalJson.length === 0,
    `${serviceName} Field help - selectors list discrepancies: Second level fields in file on server but not in locale file:`,
    secondLevelFieldsInRemoteJsonNotInLocalJson
  );
  // eslint-enable no-console
};

// Main function to execute the comparison
const compareFieldHelpFiles = async (): Promise<void> => {
  // If two local files should be compared (the actual version of fieldHelpSelectors, and it's old version), use the below commented out constants but do not commit changes in this file.
  // IMPORTANT! On the master branch, there always should be comparison of the local file with the file fetched from the API!

  // APP
  try {
    const localJson = appFieldHelpSelectors;
    const apiJson = await fetchJsonFromApi('/api/app-field-help/json');
    // const localJson = fieldHelpSelectors_old; // To use it inline the import of fieldHelpSelectors_old that is commented out above
    // const apiJson = fieldHelpSelectors;
    compareJsonFiles(localJson, apiJson, 'App');
  } catch (error) {
    console.error('App Field help error:', error);
  }

  // FIBU
  try {
    const localJson = fibuFieldHelpSelectors;
    const apiJson = await fetchJsonFromApi('/api/fibu-field-help/json');
    // const localJson = fieldHelpSelectors_old; // To use it inline the import of fieldHelpSelectors_old that is commented out above
    // const apiJson = fieldHelpSelectors;
    compareJsonFiles(localJson, apiJson, 'Fibu');
  } catch (error) {
    console.error('Fibu Field help error:', error);
  }

  // ORDER
  try {
    const localJson = orderFieldHelpSelectors;
    const apiJson = await fetchJsonFromApi('/api/order-field-help/json');
    // const localJson = fieldHelpSelectors_old; // To use it inline the import of fieldHelpSelectors_old that is commented out above
    // const apiJson = fieldHelpSelectors;
    compareJsonFiles(localJson, apiJson, 'Order');
  } catch (error) {
    console.error('Order Field help error:', error);
  }

  // PAYMENT
  try {
    const localJson = paymentFieldHelpSelectors;
    const apiJson = await fetchJsonFromApi('/api/payment-field-help/json');
    // const localJson = fieldHelpSelectors_old; // To use it inline the import of fieldHelpSelectors_old that is commented out above
    // const apiJson = fieldHelpSelectors;
    compareJsonFiles(localJson, apiJson, 'Payment');
  } catch (error) {
    console.error('Payment Field help error:', error);
  }
};

export default compareFieldHelpFiles;
