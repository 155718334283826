import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { useField } from 'formik';
import TextbausteinFormItem from '../../../../../../components/Template/PDFTemplates/TextbausteinList/TextbausteinListElements/TextbausteinFormItem';
import { mapTextbausteinToFormValues, TextbausteinFormValues } from '../../../../../../components/Template/PDFTemplates/templateMapper';
import FieldHelpIconWithTooltip from '../../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  label: string;
  name: string;
  fieldHelp?: string | null;
};

const BelegnummernkreisTextbausteinFormItem: FC<Props> = ({ name, label, fieldHelp }) => {
  const [{ value: textbaustein }, , { setValue: setTextbausteinValue }] = useField<TextbausteinFormValues>(name);
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Text>
        {label} <FieldHelpIconWithTooltip fieldHelp={fieldHelp} />
      </Typography.Text>
      <TextbausteinFormItem
        name={name}
        value={textbaustein.value}
        onChange={(newValue) => {
          const updatedValue = mapTextbausteinToFormValues({
            ...textbaustein,
            value: newValue?.trim(),
          });
          setTextbausteinValue(updatedValue);
        }}
        platzhalterList={textbaustein.platzhalterList}
      />
    </Space>
  );
};

export default BelegnummernkreisTextbausteinFormItem;
