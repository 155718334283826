import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import MahnungErstellenDetailsPage from './MahnungErstellenDetailsPage/MahnungErstellenDetailsPage';
import {
  URI_VERARBEITUNG_MAHNUNG_AUSGEBEN_DETAILS_PAGE,
  URI_VERARBEITUNG_MAHNUNG_ERSTELLEN_DETAILS_PAGE,
  URI_VERARBEITUNG_MAHNUNG_VERBUCHEN_DETAILS_PAGE,
  URI_VERARBEITUNG_MAHNUNG_VERSENDEN_DETAILS_PAGE,
} from '../../../features/Verarbeitung/verarbeitungApiPaths';
import MahnungAusgebenDetailsPage from './MahnungAusgebenDetailsPage/MahnungAusgebenDetailsPage';
import MahnungVersendenDetailsPage from './MahnungVersandtDetailsPage/MahnungVersendenDetailsPage';
import MahnungVerbuchenDetailsPage from './MahnungVerbuchenDetailsPage/MahnungVerbuchenDetailsPage';

export type VerarbeitungMahnungDetailsPageRouteParams = { generierlaufId: string };

const verarbeitungMahnungAusgebenDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_MAHNUNG_AUSGEBEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<MahnungAusgebenDetailsPage />} />}
  />
);

const verarbeitungMahnungErstellenDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_MAHNUNG_ERSTELLEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<MahnungErstellenDetailsPage />} />}
  />
);

const verarbeitungMahnungVersendenDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_MAHNUNG_VERSENDEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<MahnungVersendenDetailsPage />} />}
  />
);

const verarbeitungMahnungVerbuchenDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_MAHNUNG_VERBUCHEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<MahnungVerbuchenDetailsPage />} />}
  />
);

export {
  verarbeitungMahnungAusgebenDetailsPageRoute,
  verarbeitungMahnungErstellenDetailsPageRoute,
  verarbeitungMahnungVersendenDetailsPageRoute,
  verarbeitungMahnungVerbuchenDetailsPageRoute,
};
