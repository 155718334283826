import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useBillingChangeHistoryQuery } from '../gql/FirmendatenBuchhaltungEinstellungQueries.types';

const BillingAccountChangeHistoryListingTable: FC = () => {
  const { data, loading } = useBillingChangeHistoryQuery();
  const historyList = data?.getBillingChangeHistory.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="billing-account" />;
};

export default BillingAccountChangeHistoryListingTable;
