import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { useMahnungListQuery, useMahnungListSummaryQuery } from '../gql/MahnungQueries.types';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { useOnMahnungDataChangeEventForTable } from './useOnMahnungDataChangeEvent';
import MahnungListingFilters from './MahnungListingFilters';
import { MahnungListingFiltersFormValues } from './mahnungListingFiltersFormMapper';
import MahnungTable from './Table/MahnungTable';
import { PaginationQueryParamsProps } from '../../../hooks/usePaginationQueryParams';
import { MahnungListEntry } from '../../../types';
import { TMahnungQueryParams } from './Filters/filtersQueryParams';
import MahnungListSummary from './MahnungListSummary';

type Props = {
  formikProps: FormikProps<MahnungListingFiltersFormValues>;
  queryParams: TMahnungQueryParams;
  paginationParams: PaginationQueryParamsProps;
};

const MahnungListingForm: FC<Props> = ({ formikProps, queryParams, paginationParams }) => {
  const { currentPage, pageSize } = paginationParams;

  const {
    erstellerMitarbeiterIdList,
    mahnstichtagBisInklusive,
    mahnstichtagVonInklusive,
    objektId,
    rechnungsAusstellerId,
    vertragspartnerId,
    statusList,
  } = formikProps.values;
  const { data, loading, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useMahnungListQuery,
    {
      variables: {
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const mahnungList = data?.getMahnungList.data.contentList ?? [];

  const { data: summaryData, refetch: summaryRefetch } = useMahnungListSummaryQuery({
    variables: {
      ...queryParams,
    },
  });

  const summary = summaryData?.getMahnungListSummary.data;

  useOnMahnungDataChangeEventForTable(
    'mahnungList',
    () => refetch().then(() => summaryRefetch()),
    collectMahnungIds(mahnungList),
    objektId,
    statusList,
    mahnstichtagVonInklusive,
    mahnstichtagBisInklusive,
    erstellerMitarbeiterIdList,
    rechnungsAusstellerId,
    vertragspartnerId
  );

  return (
    <>
      <MahnungListingFilters formikProps={formikProps} refetch={refetch} queryParams={queryParams} />
      <MahnungListSummary summary={summary} />
      <MahnungTable mahnungList={mahnungList} pagination={pagination} loading={loading} handleTableSorting={handleTableSorting} />
    </>
  );
};

const collectMahnungIds = (mahnungList: MahnungListEntry[]) => mahnungList.map((mahnung) => mahnung.mahnungId);

export default MahnungListingForm;
