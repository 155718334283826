import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import FormButtons from '../../../../components/Button/FormButtons';
import {
  mapFormValuesToCreateObjektBankDetails,
  mapFormValuesToUpdateObjektBankDetails,
  mapObjektBankDetailsToFormValues,
  objektBankDetailsFormFields,
  objektBankDetailsFormInitialValues,
  ObjektBankDetailsFormValues,
} from './objektBankDetailsFormMapper';
import FirmendatenOrRechnungsAusstellerBankDetailsSelect from './FirmendatenOrRechnungsAusstellerBankDetailsSelect';
import { objektBankDetailsValidationSchema } from './objektBankDetailsValidationSchema';
import ObjBankDetailsVerwendungCheckbox from './ObjBankDetailsVerwendungCheckbox';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import BankDetailsAccountInfo from './BankDetailsAccountInfo';
import { ObjektBankDetails, ObjektBankDetailsVerwendung } from '../../../../types';
import { Spacer } from '../../../../components/Grid';
import { useCreateObjektBankDetailsMutation, useUpdateObjektBankDetailsMutation } from '../gql/ObjektBankDetailsMutations.types';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  objektId: string;
  firmendatenId: string;
  verwendungListTypeInUse: ObjektBankDetailsVerwendung[];
  rechtstraegerId: string;
  rechtstraegerFibuKontoCreatable?: boolean;
  weRechnungsAusstellerId?: string;
  weRechnungsAusstellerFibuKontoCreatable?: boolean;
  objektBankDetails?: ObjektBankDetails;
};

const ObjektBankDetailsForm: FC<Props> = ({
  objektBankDetails,
  objektId,
  rechtstraegerId,
  weRechnungsAusstellerId,
  firmendatenId,
  onSuccess,
  onCancel,
  verwendungListTypeInUse,
  rechtstraegerFibuKontoCreatable,
  weRechnungsAusstellerFibuKontoCreatable,
}) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ObjektBankDetails'>('ObjektBankDetails');

  const isUpdate = !!objektBankDetails;

  const [runCreate, { loading: loadingCreate }] = useCreateObjektBankDetailsMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`BankDetails`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateObjektBankDetailsMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`BankDetails`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const currentVerwendungList = objektBankDetails?.verwendungList.map((verwendung) => verwendung.value);

  return (
    <Formik<ObjektBankDetailsFormValues>
      initialValues={objektBankDetails ? mapObjektBankDetailsToFormValues(objektBankDetails) : objektBankDetailsFormInitialValues}
      validationSchema={objektBankDetailsValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (isUpdate) {
          runUpdate({
            variables: {
              objektId,
              objektBankDetailsId: objektBankDetails.objektBankDetailsId,
              input: mapFormValuesToUpdateObjektBankDetails(formValues),
            },
          }).finally(() => {
            setSubmitting(false);
          });
        } else {
          runCreate({ variables: { objektId, input: mapFormValuesToCreateObjektBankDetails(formValues, weRechnungsAusstellerId) } }).finally(() => {
            setSubmitting(false);
          });
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Bezeichnung</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                name={objektBankDetailsFormFields.bezeichnung}
                label="Bezeichnung"
                fieldHelp={getFieldHelpText('ObjektBankDetails.bezeichnung')}
              >
                <Input
                  id={objektBankDetailsFormFields.bezeichnung}
                  name={objektBankDetailsFormFields.bezeichnung}
                  placeholder="Bezeichnung"
                  size="small"
                />
              </FormItemWithFieldHelp>
            </Col>
          </Row>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Bankverbindung</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                name={objektBankDetailsFormFields.bankDetailsId}
                label="Bankverbindung"
                fieldHelp={getFieldHelpText('ObjektBankDetails.bankDetailsId')}
              >
                <FirmendatenOrRechnungsAusstellerBankDetailsSelect
                  name={objektBankDetailsFormFields.bankDetailsId}
                  rechtstraegerId={rechtstraegerId}
                  firmendatenId={firmendatenId}
                  onNewItemCreateSelected={() => formikProps.setFieldValue(objektBankDetailsFormFields.bankDetailsId, '')}
                  onSetNewCreatedBankDetailsId={(id, allowObjektZuweisung) => {
                    if (allowObjektZuweisung) {
                      formikProps.setFieldValue(objektBankDetailsFormFields.bankDetailsId, id);
                    } else {
                      formikProps.setFieldValue(objektBankDetailsFormFields.bankDetailsId, '');
                    }
                  }}
                  onBankDetailsSelect={(rechnungsaussteller) =>
                    formikProps.setFieldValue(objektBankDetailsFormFields.tempRechnungsausstellerType, rechnungsaussteller)
                  }
                  allowClear={false}
                  size="small"
                  weRechnungsAusstellerId={weRechnungsAusstellerId}
                  rechtstraegerFibuKontoCreatable={rechtstraegerFibuKontoCreatable}
                  weRechnungsAusstellerFibuKontoCreatable={weRechnungsAusstellerFibuKontoCreatable}
                />
              </FormItemWithFieldHelp>
              <BankDetailsAccountInfo
                tempRechnungsausstellerType={formikProps.values.tempRechnungsausstellerType}
                bankDetailsId={formikProps.values.bankDetailsId}
                rechtstraegerId={rechtstraegerId}
                firmendatenId={firmendatenId}
                objBankDetails={objektBankDetails?.bankDetails}
                weRechnungsAusstellerId={weRechnungsAusstellerId}
              />
            </Col>
          </Row>
          <Spacer height={16} />
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Verwendung</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                name={objektBankDetailsFormFields.verwendungList}
                label="Verwendung"
                fieldHelp={getFieldHelpText('ObjektBankDetails.verwendungList')}
              >
                <ObjBankDetailsVerwendungCheckbox
                  name={objektBankDetailsFormFields.verwendungList}
                  verwendungListTypeInUse={verwendungListTypeInUse}
                  currentVerwendungList={currentVerwendungList}
                  weRechnungsAusstellerId={weRechnungsAusstellerId}
                />
              </FormItemWithFieldHelp>
            </Col>
          </Row>
          <FormButtons
            updateMode={isUpdate}
            okText="Zuweisen"
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ObjektBankDetailsForm;
