import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { FileInfoFieldsFragmentDoc } from './FileInfoFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FileInfoListForFirmendatenQueryVariables = Types.Exact<{
  zinslisteId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  zinslisteVertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  auftragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  belegId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vorschreibungspostionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  heAbrechnungId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  heAbrechnungHeVertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  heVertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  objektAbrechnungId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  subAbrechnungId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  subAbrechnungEigentuemerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  topAbrechnungId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  mahnungId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  mailId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  budgetingId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceIssuerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceRecipientId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceDateFromInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceDateToInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dunningReferenceDateFromInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dunningReferenceDateToInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  bestandseinheitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  homeownerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  budgetingYear?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  typeList?: Types.InputMaybe<Array<Types.FileInfoType> | Types.FileInfoType>;
  order?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type FileInfoListForFirmendatenQuery = {
  getFileInfoListForFirmendaten: {
    data?: {
      contentList: Array<{
        budgetingYear?: number | null;
        contentType: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dunningReferenceDate?: string | null;
        fileId: string;
        geloescht: boolean;
        homeownerId?: string | null;
        invoiceDate?: string | null;
        invoiceIssuerId?: string | null;
        invoiceNumber?: string | null;
        invoiceRecipientId?: string | null;
        name: string;
        size: string;
        bestandseinheitList: Array<{ bestandseinheitId: string; bezeichnung: string }>;
        labelList: Array<{ label: string; value: string }>;
        objektList: Array<{ objektId: string; kurzBezeichnung: string }>;
        type: { text: string; value: Types.FileInfoType };
        dimensions?: { height: number; width: number } | null;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    } | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FileInfoForFirmendatenQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  fileId: Types.Scalars['ID']['input'];
}>;

export type FileInfoForFirmendatenQuery = {
  getFileInfoForFirmendaten: {
    data: {
      budgetingYear?: number | null;
      contentType: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      dunningReferenceDate?: string | null;
      fileId: string;
      geloescht: boolean;
      homeownerId?: string | null;
      invoiceDate?: string | null;
      invoiceIssuerId?: string | null;
      invoiceNumber?: string | null;
      invoiceRecipientId?: string | null;
      name: string;
      size: string;
      bestandseinheitList: Array<{ bestandseinheitId: string; bezeichnung: string }>;
      labelList: Array<{ label: string; value: string }>;
      objektList: Array<{ objektId: string; kurzBezeichnung: string }>;
      type: { text: string; value: Types.FileInfoType };
      dimensions?: { height: number; width: number } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FileInfoListForMitarbeiterQueryVariables = Types.Exact<{
  mitarbeiterId: Types.Scalars['ID']['input'];
  zinslisteId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  zinslisteVertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  auftragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  belegId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vorschreibungspostionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  heAbrechnungId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  objektAbrechnungId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  topAbrechnungId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  mahnungId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceIssuerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceRecipientId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceDateFromInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceDateToInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dunningReferenceDateFromInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dunningReferenceDateToInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  bestandseinheitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  homeownerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  budgetingYear?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  typeList?: Types.InputMaybe<Array<Types.FileInfoType> | Types.FileInfoType>;
  order?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type FileInfoListForMitarbeiterQuery = {
  getFileInfoListForMitarbeiter: {
    data?: {
      contentList: Array<{
        budgetingYear?: number | null;
        contentType: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dunningReferenceDate?: string | null;
        fileId: string;
        geloescht: boolean;
        homeownerId?: string | null;
        invoiceDate?: string | null;
        invoiceIssuerId?: string | null;
        invoiceNumber?: string | null;
        invoiceRecipientId?: string | null;
        name: string;
        size: string;
        bestandseinheitList: Array<{ bestandseinheitId: string; bezeichnung: string }>;
        labelList: Array<{ label: string; value: string }>;
        objektList: Array<{ objektId: string; kurzBezeichnung: string }>;
        type: { text: string; value: Types.FileInfoType };
        dimensions?: { height: number; width: number } | null;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    } | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FileInfoForMitarbeiterQueryVariables = Types.Exact<{
  mitarbeiterId: Types.Scalars['ID']['input'];
  fileId: Types.Scalars['ID']['input'];
}>;

export type FileInfoForMitarbeiterQuery = {
  getFileInfoForMitarbeiter: {
    data: {
      budgetingYear?: number | null;
      contentType: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      dunningReferenceDate?: string | null;
      fileId: string;
      geloescht: boolean;
      homeownerId?: string | null;
      invoiceDate?: string | null;
      invoiceIssuerId?: string | null;
      invoiceNumber?: string | null;
      invoiceRecipientId?: string | null;
      name: string;
      size: string;
      bestandseinheitList: Array<{ bestandseinheitId: string; bezeichnung: string }>;
      labelList: Array<{ label: string; value: string }>;
      objektList: Array<{ objektId: string; kurzBezeichnung: string }>;
      type: { text: string; value: Types.FileInfoType };
      dimensions?: { height: number; width: number } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FileInfoListForFirmendatenDocument = gql`
  query FileInfoListForFirmendaten(
    $zinslisteId: ID
    $zinslisteVertragId: ID
    $auftragId: ID
    $belegId: ID
    $vorschreibungspostionId: ID
    $rechtstraegerId: ID
    $vertragId: ID
    $heAbrechnungId: ID
    $heAbrechnungHeVertragId: ID
    $heVertragId: ID
    $objektAbrechnungId: ID
    $subAbrechnungId: ID
    $subAbrechnungEigentuemerId: ID
    $topAbrechnungId: ID
    $mahnungId: ID
    $mailId: ID
    $budgetingId: ID
    $name: String
    $invoiceIssuerId: String
    $invoiceRecipientId: String
    $invoiceNumber: String
    $invoiceDateFromInclusive: String
    $invoiceDateToInclusive: String
    $dunningReferenceDateFromInclusive: String
    $dunningReferenceDateToInclusive: String
    $objektId: ID
    $bestandseinheitId: ID
    $homeownerId: String
    $budgetingYear: Float
    $typeList: [FileInfoType!]
    $order: String
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getFileInfoListForFirmendaten(
      zinslisteId: $zinslisteId
      zinslisteVertragId: $zinslisteVertragId
      auftragId: $auftragId
      belegId: $belegId
      vorschreibungspostionId: $vorschreibungspostionId
      vertragId: $vertragId
      rechtstraegerId: $rechtstraegerId
      heAbrechnungId: $heAbrechnungId
      heAbrechnungHeVertragId: $heAbrechnungHeVertragId
      heVertragId: $heVertragId
      objektAbrechnungId: $objektAbrechnungId
      subAbrechnungId: $subAbrechnungId
      subAbrechnungEigentuemerId: $subAbrechnungEigentuemerId
      topAbrechnungId: $topAbrechnungId
      mahnungId: $mahnungId
      mailId: $mailId
      budgetingId: $budgetingId
      name: $name
      invoiceIssuerId: $invoiceIssuerId
      invoiceRecipientId: $invoiceRecipientId
      invoiceNumber: $invoiceNumber
      invoiceDateFromInclusive: $invoiceDateFromInclusive
      invoiceDateToInclusive: $invoiceDateToInclusive
      dunningReferenceDateFromInclusive: $dunningReferenceDateFromInclusive
      dunningReferenceDateToInclusive: $dunningReferenceDateToInclusive
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      homeownerId: $homeownerId
      budgetingYear: $budgetingYear
      typeList: $typeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...FileInfoFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FileInfoFieldsFragmentDoc}
`;
export function useFileInfoListForFirmendatenQuery(
  baseOptions?: Apollo.QueryHookOptions<FileInfoListForFirmendatenQuery, FileInfoListForFirmendatenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileInfoListForFirmendatenQuery, FileInfoListForFirmendatenQueryVariables>(FileInfoListForFirmendatenDocument, options);
}
export function useFileInfoListForFirmendatenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FileInfoListForFirmendatenQuery, FileInfoListForFirmendatenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileInfoListForFirmendatenQuery, FileInfoListForFirmendatenQueryVariables>(FileInfoListForFirmendatenDocument, options);
}
export function useFileInfoListForFirmendatenSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FileInfoListForFirmendatenQuery, FileInfoListForFirmendatenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FileInfoListForFirmendatenQuery, FileInfoListForFirmendatenQueryVariables>(
    FileInfoListForFirmendatenDocument,
    options
  );
}
export type FileInfoListForFirmendatenQueryHookResult = ReturnType<typeof useFileInfoListForFirmendatenQuery>;
export type FileInfoListForFirmendatenLazyQueryHookResult = ReturnType<typeof useFileInfoListForFirmendatenLazyQuery>;
export type FileInfoListForFirmendatenSuspenseQueryHookResult = ReturnType<typeof useFileInfoListForFirmendatenSuspenseQuery>;
export type FileInfoListForFirmendatenQueryResult = Apollo.QueryResult<FileInfoListForFirmendatenQuery, FileInfoListForFirmendatenQueryVariables>;
export const FileInfoForFirmendatenDocument = gql`
  query FileInfoForFirmendaten($firmendatenId: String!, $fileId: ID!) {
    getFileInfoForFirmendaten(firmendatenId: $firmendatenId, fileId: $fileId) {
      data {
        ...FileInfoFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FileInfoFieldsFragmentDoc}
`;
export function useFileInfoForFirmendatenQuery(
  baseOptions: Apollo.QueryHookOptions<FileInfoForFirmendatenQuery, FileInfoForFirmendatenQueryVariables> &
    ({ variables: FileInfoForFirmendatenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileInfoForFirmendatenQuery, FileInfoForFirmendatenQueryVariables>(FileInfoForFirmendatenDocument, options);
}
export function useFileInfoForFirmendatenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FileInfoForFirmendatenQuery, FileInfoForFirmendatenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileInfoForFirmendatenQuery, FileInfoForFirmendatenQueryVariables>(FileInfoForFirmendatenDocument, options);
}
export function useFileInfoForFirmendatenSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FileInfoForFirmendatenQuery, FileInfoForFirmendatenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FileInfoForFirmendatenQuery, FileInfoForFirmendatenQueryVariables>(FileInfoForFirmendatenDocument, options);
}
export type FileInfoForFirmendatenQueryHookResult = ReturnType<typeof useFileInfoForFirmendatenQuery>;
export type FileInfoForFirmendatenLazyQueryHookResult = ReturnType<typeof useFileInfoForFirmendatenLazyQuery>;
export type FileInfoForFirmendatenSuspenseQueryHookResult = ReturnType<typeof useFileInfoForFirmendatenSuspenseQuery>;
export type FileInfoForFirmendatenQueryResult = Apollo.QueryResult<FileInfoForFirmendatenQuery, FileInfoForFirmendatenQueryVariables>;
export const FileInfoListForMitarbeiterDocument = gql`
  query FileInfoListForMitarbeiter(
    $mitarbeiterId: ID!
    $zinslisteId: ID
    $zinslisteVertragId: ID
    $auftragId: ID
    $belegId: ID
    $vorschreibungspostionId: ID
    $vertragId: ID
    $heAbrechnungId: ID
    $objektAbrechnungId: ID
    $topAbrechnungId: ID
    $mahnungId: ID
    $name: String
    $invoiceIssuerId: String
    $invoiceRecipientId: String
    $invoiceNumber: String
    $invoiceDateFromInclusive: String
    $invoiceDateToInclusive: String
    $dunningReferenceDateFromInclusive: String
    $dunningReferenceDateToInclusive: String
    $objektId: ID
    $bestandseinheitId: ID
    $homeownerId: String
    $budgetingYear: Float
    $typeList: [FileInfoType!]
    $order: String
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getFileInfoListForMitarbeiter(
      mitarbeiterId: $mitarbeiterId
      zinslisteId: $zinslisteId
      zinslisteVertragId: $zinslisteVertragId
      auftragId: $auftragId
      belegId: $belegId
      vorschreibungspostionId: $vorschreibungspostionId
      vertragId: $vertragId
      heAbrechnungId: $heAbrechnungId
      objektAbrechnungId: $objektAbrechnungId
      topAbrechnungId: $topAbrechnungId
      mahnungId: $mahnungId
      name: $name
      invoiceIssuerId: $invoiceIssuerId
      invoiceRecipientId: $invoiceRecipientId
      invoiceNumber: $invoiceNumber
      invoiceDateFromInclusive: $invoiceDateFromInclusive
      invoiceDateToInclusive: $invoiceDateToInclusive
      dunningReferenceDateFromInclusive: $dunningReferenceDateFromInclusive
      dunningReferenceDateToInclusive: $dunningReferenceDateToInclusive
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      homeownerId: $homeownerId
      budgetingYear: $budgetingYear
      typeList: $typeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...FileInfoFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FileInfoFieldsFragmentDoc}
`;
export function useFileInfoListForMitarbeiterQuery(
  baseOptions: Apollo.QueryHookOptions<FileInfoListForMitarbeiterQuery, FileInfoListForMitarbeiterQueryVariables> &
    ({ variables: FileInfoListForMitarbeiterQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileInfoListForMitarbeiterQuery, FileInfoListForMitarbeiterQueryVariables>(FileInfoListForMitarbeiterDocument, options);
}
export function useFileInfoListForMitarbeiterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FileInfoListForMitarbeiterQuery, FileInfoListForMitarbeiterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileInfoListForMitarbeiterQuery, FileInfoListForMitarbeiterQueryVariables>(FileInfoListForMitarbeiterDocument, options);
}
export function useFileInfoListForMitarbeiterSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FileInfoListForMitarbeiterQuery, FileInfoListForMitarbeiterQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FileInfoListForMitarbeiterQuery, FileInfoListForMitarbeiterQueryVariables>(
    FileInfoListForMitarbeiterDocument,
    options
  );
}
export type FileInfoListForMitarbeiterQueryHookResult = ReturnType<typeof useFileInfoListForMitarbeiterQuery>;
export type FileInfoListForMitarbeiterLazyQueryHookResult = ReturnType<typeof useFileInfoListForMitarbeiterLazyQuery>;
export type FileInfoListForMitarbeiterSuspenseQueryHookResult = ReturnType<typeof useFileInfoListForMitarbeiterSuspenseQuery>;
export type FileInfoListForMitarbeiterQueryResult = Apollo.QueryResult<FileInfoListForMitarbeiterQuery, FileInfoListForMitarbeiterQueryVariables>;
export const FileInfoForMitarbeiterDocument = gql`
  query FileInfoForMitarbeiter($mitarbeiterId: ID!, $fileId: ID!) {
    getFileInfoForMitarbeiter(mitarbeiterId: $mitarbeiterId, fileId: $fileId) {
      data {
        ...FileInfoFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FileInfoFieldsFragmentDoc}
`;
export function useFileInfoForMitarbeiterQuery(
  baseOptions: Apollo.QueryHookOptions<FileInfoForMitarbeiterQuery, FileInfoForMitarbeiterQueryVariables> &
    ({ variables: FileInfoForMitarbeiterQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileInfoForMitarbeiterQuery, FileInfoForMitarbeiterQueryVariables>(FileInfoForMitarbeiterDocument, options);
}
export function useFileInfoForMitarbeiterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FileInfoForMitarbeiterQuery, FileInfoForMitarbeiterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileInfoForMitarbeiterQuery, FileInfoForMitarbeiterQueryVariables>(FileInfoForMitarbeiterDocument, options);
}
export function useFileInfoForMitarbeiterSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FileInfoForMitarbeiterQuery, FileInfoForMitarbeiterQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FileInfoForMitarbeiterQuery, FileInfoForMitarbeiterQueryVariables>(FileInfoForMitarbeiterDocument, options);
}
export type FileInfoForMitarbeiterQueryHookResult = ReturnType<typeof useFileInfoForMitarbeiterQuery>;
export type FileInfoForMitarbeiterLazyQueryHookResult = ReturnType<typeof useFileInfoForMitarbeiterLazyQuery>;
export type FileInfoForMitarbeiterSuspenseQueryHookResult = ReturnType<typeof useFileInfoForMitarbeiterSuspenseQuery>;
export type FileInfoForMitarbeiterQueryResult = Apollo.QueryResult<FileInfoForMitarbeiterQuery, FileInfoForMitarbeiterQueryVariables>;
