import { groupBy, round } from 'lodash';
import { calculateUstBasedOnBrutto, calculateUstBasedOnNetto } from '../../shared/BruttoNettoUst/bruttoNettoCalculator';
import { IncomingInvoiceBuchungPositionFormValues } from './Form/incomingInvoiceFormMapper';
import { TFormattedDecimal } from '../../helpers/formikHelper';
import { IncomingInvoiceStatus } from '../../types';

/**
 * e.g.: buchungPositionList:  [{ steuersatz: '20', kontoId: '1', betrag: '500' },
                         { steuersatz: '10', kontoId: '1', betrag: '100' },
                      { steuersatz: '10', kontoId: '2', betrag: '200' },],
        inklusiveSteuer: false
 ----
 buchungPositionListsBySteuersatz: {
    '10': [{ steuersatz: '10', kontoId: '1', betrag: '100' }, { steuersatz: '10', kontoId: '2', betrag: '200' }],
    '20': [{ steuersatz: '20', kontoId: '1', betrag: '500' }]
 }
 ----
 steuerzeileList: [{steuersatz: 10, betrag: 30}, {steuersatz: 20, betrag: 100}]
 */
const calculateBruttoNettoUst = (buchungPositionList: IncomingInvoiceBuchungPositionFormValues[], inklusiveSteuer: boolean) => {
  const buchungPositionListsBySteuersatz = groupBy(buchungPositionList, (pos) => pos.steuersatz);

  const steuerzeileList = Object.keys(buchungPositionListsBySteuersatz)
    .map((steuersatz) => {
      const steuersatzNormalized = normalizeAndParseValue(steuersatz as TFormattedDecimal);
      const sumUst = calculateSumUst(steuersatzNormalized, buchungPositionListsBySteuersatz[steuersatz], inklusiveSteuer);
      return { steuersatz: steuersatzNormalized, betrag: sumUst };
    })
    .filter((sz) => sz.steuersatz !== 0)
    .sort((sz) => sz.steuersatz);

  const sumBetrag = buchungPositionList.reduce((acc, pos) => acc + normalizeAndParseValue(pos.betrag), 0);
  const sumUst = steuerzeileList.reduce((acc, sz) => acc + sz.betrag, 0);

  let netto;
  let brutto;
  if (!inklusiveSteuer) {
    netto = sumBetrag;
    brutto = netto + sumUst;
  } else {
    brutto = sumBetrag;
    netto = brutto - sumUst;
  }

  return { netto: round(netto, 2), brutto: round(brutto, 2), steuerzeileList };
};

const calculateSumUst = (
  steuersatz: number,
  buchungPositionListForSteuersatz: IncomingInvoiceBuchungPositionFormValues[],
  inklusiveSteuer: boolean
) => {
  const sumBetrag = buchungPositionListForSteuersatz.reduce((acc, pos) => acc + normalizeAndParseValue(pos.betrag), 0);
  return !inklusiveSteuer ? calculateUstBasedOnNetto(sumBetrag, steuersatz) : calculateUstBasedOnBrutto(sumBetrag, steuersatz);
};

const normalizeAndParseValue = (value: TFormattedDecimal) => (isValidNotNegativeNumber(value) ? Number(value) : 0);

const isValidNotNegativeNumber = (formattedNumber: TFormattedDecimal) => formattedNumber !== '' && formattedNumber >= 0;

const isIncomingInvoiceUpdateAllowed = (status: IncomingInvoiceStatus) =>
  [IncomingInvoiceStatus.Erstellt, IncomingInvoiceStatus.Uebertragen].includes(status);

const isIncomingInvoiceFreigebenAllowed = (status: IncomingInvoiceStatus) => status === IncomingInvoiceStatus.Erstellt;

export { calculateBruttoNettoUst, normalizeAndParseValue, isIncomingInvoiceUpdateAllowed, isIncomingInvoiceFreigebenAllowed };
