import { FormFields } from '../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { InfoMailDeliveryDetermineRecipientListVerarbeitungQueryParams } from './filtersQueryParams';

export type InfoMailDeliveryDetermineRecipientListVerarbeitungEntryFiltersFormValues = {
  emailAddress?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
  objektId?: string;
  recipientId?: string;
  vertragspartnerId?: string;
};

export const infoMailDeliveryDetermineRecipientListVerarbeitungEntryFiltersFormFields: FormFields<InfoMailDeliveryDetermineRecipientListVerarbeitungEntryFiltersFormValues> =
  {
    emailAddress: 'emailAddress',
    exitCodeList: 'exitCodeList',
    objektId: 'objektId',
    recipientId: 'recipientId',
    vertragspartnerId: 'vertragspartnerId',
  };

export const infoMailDeliveryDeterminceRecipientVerarbeitungEntryFiltersFormInitialValues = (
  queryParams: InfoMailDeliveryDetermineRecipientListVerarbeitungQueryParams
): InfoMailDeliveryDetermineRecipientListVerarbeitungEntryFiltersFormValues => ({
  emailAddress: queryParams.emailAddress,
  exitCodeList: queryParams.exitCodeList,
  recipientId: queryParams.recipientId,
  vertragspartnerId: queryParams.vertragspartnerId,
});

export const mapFormValuesToInfoMailDeliveryDeterminceRecipientVerarbeitungFilters = (
  formValues: InfoMailDeliveryDetermineRecipientListVerarbeitungEntryFiltersFormValues
): InfoMailDeliveryDetermineRecipientListVerarbeitungQueryParams => ({
  emailAddress: formValues.emailAddress,
  exitCodeList: formValues.exitCodeList,
  objektId: formValues.objektId,
  recipientId: formValues.recipientId,
  vertragspartnerId: formValues.vertragspartnerId,
});
