import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useAbrechnungskreisChangeHistoryListQuery } from '../gql/AbrechnungskreisQueries.types';

type Props = {
  abrechnungskreisId: string;
};

const AbrechnungskreisChangeHistoryListingTable: FC<Props> = ({ abrechnungskreisId }) => {
  const { data, loading } = useAbrechnungskreisChangeHistoryListQuery({ variables: { abrechnungskreisId } });
  const historyList = data?.getAbrechnungskreisChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="abrechnungskreis" />;
};

export default AbrechnungskreisChangeHistoryListingTable;
