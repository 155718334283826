import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { OrderGenerierlaufType } from '../../../../../types';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useFilterGenLaufOrderPaymentTypeListQuery } from '../../../gql/GenerierlaufFilter/OrderGenerierlaufFilterQueries.types';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: OrderGenerierlaufType;
} & SelectProps;

const GenerierlaufPaymentTypeFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufOrderPaymentTypeListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufOrderPaymentTypeList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Zahlungstyp auswählen"
      {...restProps}
    >
      {list.map((paymentType) => (
        <Select.Option key={paymentType.value} value={paymentType.value}>
          {paymentType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufPaymentTypeFormSelect;
