import { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { BuchungSource } from '../../../types';

type Props = Omit<SelectProps, 'id' | 'allowClear'>;

const SourceTypeListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const list = [
    { value: BuchungSource.Manual, text: 'Manuell' },
    { value: BuchungSource.System, text: 'System' },
  ];

  return (
    <Select size="middle" placeholder="Quelle auswählen" {...restProps} name={name} id={name} allowClear>
      {list.map((source) => (
        <Select.Option key={source.value} value={source.value}>
          {source.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SourceTypeListFormSelect;
