import React, { FC } from 'react';
import { Empty } from 'antd';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import { TCamtVerarbeitungQueryParams } from '../Filters/filtersQueryParams';
import onlineBankingDataCarrierDetailsTableColumns from './onlineBankingDataCarrierDetailsTableColumns';
import { TDataCarrierCamt } from '../shared/dataCarrierTypes';
import { useAuth } from '../../../../../shared/Auth/authContext';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { useQueryWithPaginationAndSortingForCamtGenLaufEntryList } from '../shared/useQueryWithPaginationAndSortingForCamtGenLaufEntryList';

type Props = {
  queryParams: TCamtVerarbeitungQueryParams;
  generierlaufId: string;
};

const OnlineBankingDataCarrierDetailsTable: FC<Props> = ({ generierlaufId, queryParams }) => {
  const { activeForFirmendatenId } = useAuth();

  const { entryList, loading, pagination, handleTableSorting } = useQueryWithPaginationAndSortingForCamtGenLaufEntryList(generierlaufId, queryParams);

  const flattenedDataCarrierCamt: TDataCarrierCamt[] = entryList
    .map((entry) => {
      if (entry.dataCarrierList.length === 0) {
        return {
          createTs: entry.createTs,
          createdBy: entry.createdBy,
          createdByMitarbeiterId: entry.createdByMitarbeiterId,
          exitCode: entry.exitCode,
          errorList: entry.errorList,
          generierlaufEntryId: entry.generierlaufEntryId,
          infoMessage: entry.infoMessage,
          warningList: entry.warningList,
        };
      }

      return entry.dataCarrierList.map((dataCarrier) => {
        return {
          ...dataCarrier,
          createTs: entry.createTs,
          createdBy: entry.createdBy,
          createdByMitarbeiterId: entry.createdByMitarbeiterId,
          exitCode: entry.exitCode,
          errorList: [...entry.errorList, ...dataCarrier.errorList],
          generierlaufEntryId: entry.generierlaufEntryId,
          infoMessage: entry.infoMessage,
          warningList: [...entry.warningList, ...dataCarrier.warningList],
        };
      });
    })
    .flat();

  return (
    <TableWithColSelector<TDataCarrierCamt>
      dataSource={flattenedDataCarrierCamt}
      columns={onlineBankingDataCarrierDetailsTableColumns(activeForFirmendatenId as string)}
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine EBICS Bankteilnehmer vorhanden</span>} />,
      }}
      size="small"
      pagination={pagination}
      onChange={handleTableSorting}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-data-carrier-online-banking"
      rowKey={(record) => record.dataCarrierId ?? record.createTs}
    />
  );
};

export default OnlineBankingDataCarrierDetailsTable;
