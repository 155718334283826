import React, { FC } from 'react';
import { Space } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { AddressSharedModel, PostofficeBoxAddressModel, StreetAddressModel } from './AddressSharedModel';
import StreetAddress from './StreetAddress';
import PostofficeBoxAddress from './PostofficeBoxAddress';
import { AddressType } from '../../../types';

type Props = {
  address: AddressSharedModel;
  oneLine?: boolean;
};

const AddressText: FC<Props> = ({ address, oneLine = false }) => (
  <Space align="start">
    <HomeOutlined />
    {address.type === AddressType.Street ? (
      <StreetAddress streetAddress={address as StreetAddressModel} oneLine={oneLine} />
    ) : (
      <PostofficeBoxAddress postofficeBox={address as PostofficeBoxAddressModel} />
    )}
  </Space>
);

export default AddressText;
