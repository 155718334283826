import React, { FC } from 'react';
import { Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, DownOutlined, FormOutlined, HistoryOutlined, ReadOutlined } from '@ant-design/icons';
import { FibuBuchungsanweisungListEntry } from '../../../types';
import { useDeleteFibuBuchungsanweisungMutation } from '../gql/BuchungsanweisungMutations.types';
import { showSuccessMsgDelete } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { isFibuBuchungsanweisungVerbucht } from '../shared/fibuBuchungsanweisungHelpers';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { useToggle } from '../../../hooks/useToggle';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import BookingInstructionChangeHistoryListingTable from './BookingInstructionChangeHistoryListingTable';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';
import { generatePathToBookingInstruction } from '../bookingInstructionUriPaths';

type Props = {
  buchungsanweisung: FibuBuchungsanweisungListEntry;
  onActionSuccess: () => void;
};

const BookingInstructionTableActions: FC<Props> = ({ buchungsanweisung, onActionSuccess }) => {
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const entity = 'Buchungsanweisung';

  const [runDelete] = useDeleteFibuBuchungsanweisungMutation({
    variables: { buchungsanweisungId: buchungsanweisung.buchungsanweisungId },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'TODO: Verbuchen',
      disabled: isFibuBuchungsanweisungVerbucht(buchungsanweisung.status.value),
      onClick: () => console.log('TODO: create verbuchen Generierlauf'),
      icon: <ReadOutlined />,
    },
    {
      key: '2',
      label: isFibuBuchungsanweisungVerbucht(buchungsanweisung.status.value) ? 'Buchungsdetails ansehen' : 'TODO!!: Buchungsanweisung bearbeiten',
      onClick: () =>
        // NOTE: if buchungsanweisung has status 'VERBUCHT', then buchungId is always provided
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        isFibuBuchungsanweisungVerbucht(buchungsanweisung.status.value)
          ? generatePathToBookingDetailsPage([buchungsanweisung.buchungId!])
          : generatePathToBookingInstruction(), // FIXME: generatePathToBookingInstruction should contain at least the buchungsanweisungId in order to get initial values from BE for the existing buchungsanweisung
      icon: isFibuBuchungsanweisungVerbucht(buchungsanweisung.status.value) ? <ReadOutlined /> : <FormOutlined />,
    },
    {
      key: '3',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '4',
      label: 'Löschen',
      onClick: showConfirmDelete(entity, runDelete),
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <BookingInstructionChangeHistoryListingTable buchungsanweisungId={buchungsanweisung.buchungsanweisungId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (entityName: string, runDelete: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die ${entityName} löschen?`,
    content: entityName,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};
export default BookingInstructionTableActions;
