import React from 'react';
import { useParams } from 'react-router-dom';
import ZinslisteCard from '../../features/Zinsliste/Card/ZinslisteCard';
import { useZinslisteQuery, useZinslisteVerarbeitungsstandQuery } from '../../features/Zinsliste/gql/ZinslisteQueries.types';
import { useOnZinslisteDataChangedEvents } from '../../features/Zinsliste/useOnZinslisteDataChangedEvents';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import menuListZinsliste from './menuListZinsliste';
import { ZinslisteDetailsPageRouteParams } from './routes';
import { Zinsliste } from '../../types';

const ZinslisteDetailsPage = () => {
  const { zinslisteId, objektId } = useParams() as ZinslisteDetailsPageRouteParams;

  const { data: zinslisteData, refetch } = useZinslisteQuery({
    variables: {
      objektId,
      zinslisteId,
      withDetails: true,
    },
  });
  const zinsliste = zinslisteData?.getZinsliste.data;

  const { data: verarbeitungsstandData, refetch: refetchVerarbeitungsstand } = useZinslisteVerarbeitungsstandQuery({
    variables: { objektId, zinslisteId },
  });
  const verarbeitungsstand = verarbeitungsstandData?.getZinslisteVerarbeitungsstand.data;

  useOnZinslisteDataChangedEvents('zinslisteAndVerarbeitungsstand', () => {
    return refetch().then(() => {
      refetchVerarbeitungsstand();
    });
  });
  const loading = !zinsliste || !verarbeitungsstand;

  return (
    <DetailsPageTemplate<Zinsliste>
      data={zinsliste}
      isLoading={loading}
      pageTitle={() => `Zinsliste`}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      card={(zinsliste) => <ZinslisteCard zinsliste={zinsliste} isLoading={loading} verarbeitungsstand={verarbeitungsstand!} />}
      sidebarMenuList={(zinsliste) => menuListZinsliste(zinsliste, objektId, zinslisteId, refetch)}
    />
  );
};

export default ZinslisteDetailsPage;
