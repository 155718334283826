import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_MAHNUNG_DETAILS_PAGE } from '../../features/Mahnung/mahnungUriPaths';
import MahnungDetailsPage from './MahnungDetailsPage';

export type MahnungDetailsPageRouteParams = { mahnungId: string };

const mahnungDetailsPageRoute = (
  <Route
    path={`${URI_MAHNUNG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<MahnungDetailsPage />} />}
  />
);

export default mahnungDetailsPageRoute;
