import React from 'react';
import { Route } from 'react-router';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import AuthRoute from '../../security/AuthRoute';
import WeAbrechnungDetailsPage from './WeAbrechnungDetailsPage';
import { URI_WE_ABRECHNUNG_DETAILS_PAGE } from '../../features/Abrechnung/BkOrWe/weAbrechnungUriPaths';

export type WeAbrDetailsPageRouteParams = { objektId: string; objektAbrechnungId: string };

const weAbrechnungDetailsPageRoute = (
  <Route
    path={`${URI_WE_ABRECHNUNG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<WeAbrechnungDetailsPage />} />}
  />
);

export default weAbrechnungDetailsPageRoute;
