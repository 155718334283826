import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { paymentListingLabelList } from './paymentListingFiltersFormMapper';
import { useFilterPaymentStatusListQuery } from './gql/PaymentFilterQueries.types';
import { OrderPaymentStatus } from '../../../../types';

type Props = { allowedStatusList?: OrderPaymentStatus[] } & Omit<SelectProps, 'id'>;

const PaymentStatusFilter: FC<Props> = ({ allowedStatusList, ...restProps }) => {
  const { loading, data } = useFilterPaymentStatusListQuery();
  const paymentStatusList = data?.getFilterPaymentStatusList.data ?? [];

  const statusList = paymentStatusList.filter((status) => allowedStatusList?.includes(status.value) ?? true);

  return (
    <FormItemWithoutColon name={restProps.name} label={paymentListingLabelList.statusList}>
      <Select
        {...restProps}
        size="small"
        id={restProps.name}
        loading={loading}
        placeholder="Zahlung Status auswählen"
        mode="multiple"
        allowClear
        showSearch
        filterOption={selectFilterOption}
      >
        {statusList.map((status) => (
          <Select.Option key={status.value} value={status.value}>
            {status.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithoutColon>
  );
};

export default PaymentStatusFilter;
