import React, { FC } from 'react';
import { Button, Dropdown, MenuProps, Modal, Typography } from 'antd';
import { DeleteOutlined, EllipsisOutlined, ExportOutlined, FilePdfOutlined, ImportOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgOther, showSuccessMsgUnarchived } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useGoBack } from '../../../shared/GoBack/GoBackContext';
import { Budgeting } from '../../../types';
import {
  useBudgetingArchivierenMutation,
  useBudgetingDeleteVPosValuesMutation,
  useBudgetingReaktivierenMutation,
  useDeleteBudgetingMutation,
} from '../gql/BudgetingMutations.types';
import { pathsToObjektDetailsPage } from '../../Objekt/objektUriPaths';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { isStatusArchived, isStatusErstellt, isStatusVerarbeitet } from '../../../helpers/statusHelper';
import BudgetingCreateVPosValuesFromVPosModal from './BudgetingCreateVPosValuesFromVPosModal';
import { useToggle } from '../../../hooks/useToggle';
import BudgetingCreateVPosValuesFromAufwandModal from './BudgetingCreateVPosValuesFromAufwandModal';
import { pathsToBudgetingDetailsPage } from '../budgetingUriPaths';
import { useCreateObjektFinancialPlanGenerierlaufMutation } from '../../Verarbeitung/gql/ObjektFinancialPlanGenerierlauf/ObjektFinancialPlanGenerierlaufMutations.types';
import { useCreateTopFinancialPlanGenerierlaufMutation } from '../../Verarbeitung/gql/TopFinancialPlanGenerierlauf/TopFinancialPlanGenerierlaufMutations.types';

type Props = {
  budgeting: Budgeting;
  objektId: string;
  onAction: () => Promise<unknown>;
  isUpdating: boolean;
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const BudgetingActions: FC<Props> = ({ budgeting, objektId, onAction, isUpdating, setIsUpdating, isLoading, setIsLoading }) => {
  const navigate = useNavigate();
  const goBack = useGoBack(pathsToObjektDetailsPage(objektId).budgeting);
  const [isCreateVPosFromVPosModalOpen, toggleCreateVPosFromVPosModalOpen] = useToggle();
  const [isCreateVPosFromAufwandModalOpen, toggleCreateVPosFromAufwandModalOpen] = useToggle();

  const entity = `Budgetierung`;

  const onActionWithLoading = () => {
    setIsLoading(true);
    return onAction().finally(() => setIsLoading(false));
  };

  const [runDelete] = useDeleteBudgetingMutation({
    variables: { budgetingId: budgeting.budgetingId, objektId },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      goBack();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDeleteVPosValues] = useBudgetingDeleteVPosValuesMutation({
    variables: { budgetingId: budgeting.budgetingId, objektId },
    onCompleted: async () => {
      showSuccessMsgOther('VPos-Werte wurden erfolgreich aus den Budgetierungs-Vorschreibungs-Werten gelöscht');
      await onActionWithLoading();
      navigate(pathsToBudgetingDetailsPage(objektId, budgeting.budgetingId).detailsTab);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useBudgetingArchivierenMutation({
    variables: { budgetingId: budgeting.budgetingId, objektId },
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onActionWithLoading();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useBudgetingReaktivierenMutation({
    variables: { budgetingId: budgeting.budgetingId, objektId },
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onActionWithLoading();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreateObjektFinancialPlan] = useCreateObjektFinancialPlanGenerierlaufMutation({
    variables: { input: { objektId, budgetingId: budgeting.budgetingId } },
    onCompleted: () => {
      showSuccessMsgOther('Objekt-Wirtschaftsplan wird ausgegeben');
      onActionWithLoading();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreateTopFinancialPlan] = useCreateTopFinancialPlanGenerierlaufMutation({
    variables: { input: { objektId, budgetingId: budgeting.budgetingId } },
    onCompleted: () => {
      showSuccessMsgOther('Top-Wirtschaftsplan wird ausgegeben');
      onActionWithLoading();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    ...(isStatusErstellt(budgeting.status)
      ? [
          {
            key: '1',
            label: (
              <Typography.Text>
                VPos-Werte aus <strong>Budgetierungs-Vorschreibungs-Werten</strong> erstellen
              </Typography.Text>
            ),
            onClick: toggleCreateVPosFromVPosModalOpen,
            icon: <ExportOutlined />,
          },
          {
            key: '2',
            label: (
              <Typography.Text>
                VPos-Werte aus <strong>Budgetierungs-Aufwänden</strong> erstellen
              </Typography.Text>
            ),
            onClick: toggleCreateVPosFromAufwandModalOpen,
            icon: <ExportOutlined />,
          },
        ]
      : []),
    ...(isStatusVerarbeitet(budgeting.status)
      ? [
          {
            key: '1',
            label: 'VPos-Werte aus Budgetierung löschen',
            onClick: () => showConfirmDeleteVPosValue(runDeleteVPosValues, setIsUpdating),
            icon: <ImportOutlined />,
          },
          {
            key: '2',
            label: 'Objekt-Wirtschaftsplan ausgeben',
            onClick: () => showConfirmCreateObjektFinancialPlan(runCreateObjektFinancialPlan, setIsUpdating),
            icon: <FilePdfOutlined />,
          },
          {
            key: '3',
            label: 'Top-Wirtschaftsplan ausgeben',
            onClick: () => showConfirmCreateTopFinancialPlan(runCreateTopFinancialPlan, setIsUpdating),
            icon: <FilePdfOutlined />,
          },
        ]
      : []),
    {
      key: '4',
      label: isStatusArchived(budgeting.status) ? 'Reaktivieren' : 'Archivieren',
      onClick: isStatusArchived(budgeting.status)
        ? showConfirmUnarchive(runUnarchive, setIsUpdating, budgeting)
        : showConfirmArchive(runArchive, setIsUpdating, budgeting),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: isStatusErstellt(budgeting.status),
    },
    {
      key: '5',
      label: 'Löschen',
      onClick: () => showConfirmDelete(runDelete, budgeting),
      icon: <DeleteOutlined />,
      danger: true,
      disabled: !isStatusErstellt(budgeting.status),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} disabled={isLoading || isUpdating}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
      <BudgetingCreateVPosValuesFromVPosModal
        budgeting={budgeting}
        open={isCreateVPosFromVPosModalOpen}
        onCancel={toggleCreateVPosFromVPosModalOpen}
        onSuccess={async () => {
          toggleCreateVPosFromVPosModalOpen();
          await onAction();
          navigate(pathsToBudgetingDetailsPage(objektId, budgeting.budgetingId).resultsTab);
        }}
      />
      <BudgetingCreateVPosValuesFromAufwandModal
        budgeting={budgeting}
        open={isCreateVPosFromAufwandModalOpen}
        onCancel={toggleCreateVPosFromAufwandModalOpen}
        onSuccess={async () => {
          toggleCreateVPosFromAufwandModalOpen();
          await onAction();
          navigate(pathsToBudgetingDetailsPage(objektId, budgeting.budgetingId).resultsTab);
        }}
      />
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, budgeting: Budgeting) => {
  Modal.confirm({
    title: 'Möchten Sie die Budgetierung löschen?',
    content: `${budgeting.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmDeleteVPosValue = (runDeleteVPosValues: () => Promise<unknown>, setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>) => {
  Modal.confirm({
    title: 'Möchten Sie die VPos Werte in den Verträgen, die über diese Budgetierung erzeugt wurden, löschen?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    async onOk() {
      setIsUpdating(true);
      try {
        return await runDeleteVPosValues();
      } finally {
        setIsUpdating(false);
      }
    },
  });
};

const showConfirmArchive =
  (runArchive: () => Promise<unknown>, setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>, budgeting: Budgeting) => () => {
    Modal.confirm({
      title: `Möchten Sie die Budgetierung archivieren?`,
      content: `${budgeting.bezeichnung}`,
      okText: 'Archivieren',
      cancelText: 'Abbrechen',
      async onOk() {
        setIsUpdating(true);
        try {
          return await runArchive();
        } finally {
          setIsUpdating(false);
        }
      },
    });
  };

const showConfirmUnarchive =
  (runUnarchive: () => Promise<unknown>, setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>, budgeting: Budgeting) => () => {
    Modal.confirm({
      title: `Möchten Sie die Budgetierung reaktivieren?`,
      content: `${budgeting.bezeichnung}`,
      okText: 'Reaktivieren',
      cancelText: 'Abbrechen',
      async onOk() {
        setIsUpdating(true);
        try {
          return await runUnarchive();
        } finally {
          setIsUpdating(false);
        }
      },
    });
  };

const showConfirmCreateObjektFinancialPlan = (
  runCreateObjektFinancialPlan: () => Promise<unknown>,
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>
) => {
  Modal.confirm({
    title: 'Möchten Sie den Objekt-Wirtschaftsplan ausgeben?',
    okText: 'Ja',
    cancelText: 'Abbrechen',
    async onOk() {
      setIsUpdating(true);
      try {
        return await runCreateObjektFinancialPlan();
      } finally {
        setIsUpdating(false);
      }
    },
  });
};

const showConfirmCreateTopFinancialPlan = (
  runCreateTopFinancialPlan: () => Promise<unknown>,
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>
) => {
  Modal.confirm({
    title: 'Möchten Sie den Top-Wirtschaftsplan ausgeben?',
    okText: 'Ja',
    cancelText: 'Abbrechen',
    async onOk() {
      setIsUpdating(true);
      try {
        return await runCreateTopFinancialPlan();
      } finally {
        setIsUpdating(false);
      }
    },
  });
};

export default BudgetingActions;
