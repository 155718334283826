import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FibuEinstellungQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FibuEinstellungQuery = {
  getFibuEinstellung: {
    data: { createTs: string; createdByMitarbeiterId?: string | null; createdBy: string; negierteHabenBetraege: boolean };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FibuEinstellungDocument = gql`
  query FibuEinstellung {
    getFibuEinstellung {
      data {
        createTs
        createdByMitarbeiterId
        createdBy
        negierteHabenBetraege
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFibuEinstellungQuery(baseOptions?: Apollo.QueryHookOptions<FibuEinstellungQuery, FibuEinstellungQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FibuEinstellungQuery, FibuEinstellungQueryVariables>(FibuEinstellungDocument, options);
}
export function useFibuEinstellungLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FibuEinstellungQuery, FibuEinstellungQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FibuEinstellungQuery, FibuEinstellungQueryVariables>(FibuEinstellungDocument, options);
}
export function useFibuEinstellungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FibuEinstellungQuery, FibuEinstellungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FibuEinstellungQuery, FibuEinstellungQueryVariables>(FibuEinstellungDocument, options);
}
export type FibuEinstellungQueryHookResult = ReturnType<typeof useFibuEinstellungQuery>;
export type FibuEinstellungLazyQueryHookResult = ReturnType<typeof useFibuEinstellungLazyQuery>;
export type FibuEinstellungSuspenseQueryHookResult = ReturnType<typeof useFibuEinstellungSuspenseQuery>;
export type FibuEinstellungQueryResult = Apollo.QueryResult<FibuEinstellungQuery, FibuEinstellungQueryVariables>;
