import React from 'react';
import { Link } from 'react-router-dom';
import { NestedTableWithColSelectorColProps } from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { SubAbrechnungEigentuemerListEntry } from '../../../../types';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import StatusBadge from '../../../../components/Status/StatusBadge';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import { compareTwoDatesForSorting } from '../../../../helpers/dateHelper';
import { compareTwoNumbersForSorting } from '../../../../helpers/numberHelper';
import { generatePathToSubAbrechnungEigentuemerDetailsPage } from '../subAbrechnungEigentumerUriPaths';
import { Gesamtergebnis } from '../../shared/Gesamtergebnis';

const subAbrechnungTableColumns = (
  objektId: string,
  subAbrechnungId: string
): NestedTableWithColSelectorColProps<SubAbrechnungEigentuemerListEntry>[] => [
  {
    title: 'Eigentümer',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.eigentuemer.kurzBezeichnung, b.eigentuemer.kurzBezeichnung),
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip rechtstraegerId={record.eigentuemer.rechtstraegerId} rechtstraegerName={record.eigentuemer.kurzBezeichnung} />
    ),
  },
  {
    title: 'Zeitraum',
    defaultSelected: true,
    sorter: (a, b) => compareTwoDatesForSorting(a.abrechnungszeitraumVon, b.abrechnungszeitraumVon),
    render: (text, record) => (
      <>
        <CustomFormattedDate value={record.abrechnungszeitraumVon} /> – <CustomFormattedDate value={record.abrechnungszeitraumBis} />
      </>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.status.text, b.status.text),
    render: (text, record) => <StatusBadge entityStatus={record.status} showBadgeText />,
  },
  {
    title: 'Abrechnungsergebnis',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.saldo, b.saldo),
    render: (text, record) => <Gesamtergebnis guthaben={record.guthaben} saldo={record.saldo} />,
  },
  {
    title: 'Erstellt am',
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => (
      <Link to={generatePathToSubAbrechnungEigentuemerDetailsPage(objektId, subAbrechnungId, record.subAbrechnungEigentuemerId)} target="_blank">
        Details
      </Link>
    ),
  },
];

export default subAbrechnungTableColumns;
