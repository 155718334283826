import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { generatePathToVerarbeitungInfoMailDeliverySendDetailsPage } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungErrorAndWarningListing from '../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import VerarbeitungInfoMailDeliverySend from '../../../features/Verarbeitung/InfoMailDeliverySend/VerarbeitungInfoMailDeliverySend';
import { InfoMailDeliverySendGenerierlauf } from '../../../types';

const menuListInfoMailDeliverySend = (generierlauf: InfoMailDeliverySendGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.InfoMailDeliverySend,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungInfoMailDeliverySendDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: () => <VerarbeitungInfoMailDeliverySend generierlauf={generierlauf} />,
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungInfoMailDeliverySendDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListInfoMailDeliverySend;
