import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
export type HeAbrAbrechnungskreisListFieldFragment = {
  anteil: number;
  anteile?: string | null;
  anteilePercent?: number | null;
  summe: number;
  abrechnungskreisType: { value: Types.AbrechnungskreisType; text: string };
  abrechnungskreisList: Array<{
    abrechnungskreisId: string;
    bezeichnung: string;
    heAbrechnungsdefinitionId: string;
    heAbrechnungsdefinitionVersionId: string;
    summe: number;
    abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
    kontoList: Array<{
      bezeichnung: string;
      kontoId: string;
      summe: number;
      amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
      buchungszeileList: Array<{
        auftragId?: string | null;
        belegDatum?: string | null;
        belegFileId?: string | null;
        belegId: string;
        belegnummer?: string | null;
        betrag: number;
        buchungIdList: Array<string>;
        dueDate?: string | null;
        standingInvoicePartialAmountId?: string | null;
        text: string;
        umbuchung?: boolean | null;
        umgebucht?: boolean | null;
        ustVomAufwandSteuersatz?: number | null;
        buchungskreisRechtstraeger: {
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          createTs: string;
          rechtstraegerId: string;
          kurzBezeichnung: string;
          warningList: Array<{ message: string; type: string }>;
          type: { text: string; value: Types.RechtstraegerType };
          status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
        };
        sollHaben: { text: string; value: Types.SollHaben };
        vertragspartner?: {
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          createTs: string;
          rechtstraegerId: string;
          kurzBezeichnung: string;
          warningList: Array<{ message: string; type: string }>;
          type: { text: string; value: Types.RechtstraegerType };
          status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
        } | null;
      }>;
    }>;
  }>;
};

export type HeAbrechnungListEntryFieldsFragment = {
  abrechnungszeitraumBis: string;
  abrechnungszeitraumVon: string;
  bezeichnung: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  firmendatenId: string;
  generierlaufId: string;
  heAbrechnungId: string;
  updatedByMitarbeiterId?: string | null;
  abrechnungsdefinitionType: { text: string; value: Types.AbrechnungsdefinitionType };
  status: { text: string; value: Types.AbrechnungStatus };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type HeAbrechnungHeVertragListEntryFieldsFragment = {
  abrechnungszeitraumBis: string;
  abrechnungszeitraumVon: string;
  anteile: string;
  anteilePercent: number;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  fileId?: string | null;
  guthaben: boolean;
  heAbrechnungHeVertragId: string;
  saldo: number;
  updatedByMitarbeiterId?: string | null;
  abrechnungsdefinitionType: { text: string; value: Types.AbrechnungsdefinitionType };
  hauseigentuemer: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    fibuKontoCreatable: boolean;
    kundennummer: string;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    bankDetailsList: Array<{
      accountHolder: string;
      accountLimit?: number | null;
      assignedHeVertragZahlungsregelMap: any;
      assignedObjektMap: any;
      bankDetailsId: string;
      bankname: string;
      bic: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      ebicsCreditTransferUserList: Array<string>;
      ebicsDirectDebitUserList: Array<string>;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      updatedByMitarbeiterId?: string | null;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
      fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
      sepaMandat?: {
        fileId?: string | null;
        fileName?: string | null;
        sepaMandatId: string;
        sepaMandatReference: string;
        signatureDate?: string | null;
        firmendaten?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
        warningList: Array<{ message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    personenbezugList: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      personenbezugId: string;
      tagList: Array<string>;
      person: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    postIt?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      immerAnzeigen: boolean;
      postItId: string;
      text: string;
      titel: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    rechtstraegerSteuerpflicht?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      rechtstraegerSteuerpflichtId: string;
      validFrom: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ message: string; type: string }>;
      }> | null;
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
    sprache: { text: string; value: Types.Sprache };
    status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
    type: { value: Types.RechtstraegerType; text: string };
    vertragSummary?: {
      heVertragCount: number;
      heVertragVerwaltungCount: number;
      mietVertragCount: number;
      mietVertragVerwaltungCount: number;
      weSubverwaltungCount: number;
      weVertragCount: number;
      weVertragVerwaltungCount: number;
    } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  status: { text: string; value: Types.HeAbrechnungHeVertragStatus };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const HeAbrAbrechnungskreisListFieldFragmentDoc = gql`
  fragment HeAbrAbrechnungskreisListField on HeAbrAbrechnungskreisList {
    abrechnungskreisType {
      value
      text
    }
    anteil
    anteile
    anteilePercent
    summe
    abrechnungskreisList {
      abrechnungskreisId
      abrechnungskreisType {
        text
        value
      }
      bezeichnung
      heAbrechnungsdefinitionId
      heAbrechnungsdefinitionVersionId
      kontoList {
        amountReducingType {
          text
          value
        }
        bezeichnung
        buchungszeileList {
          auftragId
          belegDatum
          belegFileId
          belegId
          belegnummer
          betrag
          buchungIdList
          buchungskreisRechtstraeger {
            createdBy
            createdByMitarbeiterId
            createTs
            warningList {
              message
              type
            }
            type {
              text
              value
            }
            rechtstraegerId
            kurzBezeichnung
            status {
              text
              value
              description
            }
          }
          dueDate
          sollHaben {
            text
            value
          }
          standingInvoicePartialAmountId
          text
          umbuchung
          umgebucht
          ustVomAufwandSteuersatz
          vertragspartner {
            createdBy
            createdByMitarbeiterId
            createTs
            warningList {
              message
              type
            }
            type {
              text
              value
            }
            rechtstraegerId
            kurzBezeichnung
            status {
              text
              value
              description
            }
          }
        }
        kontoId
        summe
      }
      summe
    }
  }
`;
export const HeAbrechnungListEntryFieldsFragmentDoc = gql`
  fragment HeAbrechnungListEntryFields on HeAbrechnungListEntry {
    abrechnungsdefinitionType {
      text
      value
    }
    abrechnungszeitraumBis
    abrechnungszeitraumVon
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    firmendatenId
    generierlaufId
    heAbrechnungId
    status {
      text
      value
    }
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
`;
export const HeAbrechnungHeVertragListEntryFieldsFragmentDoc = gql`
  fragment HeAbrechnungHeVertragListEntryFields on HeAbrechnungHeVertragListEntry {
    abrechnungsdefinitionType {
      text
      value
    }
    abrechnungszeitraumBis
    abrechnungszeitraumVon
    anteile
    anteilePercent
    createTs
    createdBy
    createdByMitarbeiterId
    fileId
    guthaben
    hauseigentuemer {
      ...RechtstraegerFields
    }
    heAbrechnungHeVertragId
    saldo
    status {
      text
      value
    }
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
  ${RechtstraegerFieldsFragmentDoc}
`;
