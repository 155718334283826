import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { useKontoVerwendungListQuery } from '../../gql/KontoVerwendungQueries.types';

type Props = {
  name: string;
  onChange: () => void;
} & SelectProps;

const KontoVerwendung: FC<Props> = ({ name, onChange, ...restProps }) => {
  const { loading, data } = useKontoVerwendungListQuery();

  const kontoVerwendungList = data?.getKontoVerwendungList?.data || [];

  return (
    <FormItemWithoutColon name={name} label="Verwendung">
      <Select id={name} name={name} loading={loading} allowClear onChange={onChange} placeholder="Kontoverwendung auswählen" {...restProps}>
        {kontoVerwendungList.map((kontoverwendung) => (
          <Select.Option key={kontoverwendung.text} value={kontoverwendung.value}>
            {kontoverwendung.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithoutColon>
  );
};

export default KontoVerwendung;
