import React from 'react';
import { CarryOutOutlined, SolutionOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { generatePathToZinslisteDetailsPage } from '../../features/Zinsliste/zinslisteUriPaths';
import ZinslisteVorschreibung from '../../features/Zinsliste/DetailsCard/ZinslisteVorschreibung/ZinslisteVorschreibung';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import VPosListTab from '../../features/Zinsliste/DetailsCard/ZinslisteDetails/VPosListTab';
import ZinslisteDeviationList from '../../features/Zinsliste/Deviation/ZinslisteDeviationList';
import { Zinsliste, ZinslisteStatus } from '../../types';
import { DEVIATIONS_MENU_LIST_ITEM_TITLE } from '../../components/Deviation/deviationHelpers';

const menuListZinsliste = (zinsliste: Zinsliste, objektId: string, zinslisteId: string, refetch: () => void): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Zinsliste',
      icon: <CarryOutOutlined />,
      uri: `${generatePathToZinslisteDetailsPage(objektId, zinslisteId)}/zinsliste-information`,
      content: () => <VPosListTab zinsliste={zinsliste} objektId={objektId} />,
    },
    {
      title: 'Vorschreibungen',
      icon: <SolutionOutlined />,
      uri: `${generatePathToZinslisteDetailsPage(objektId, zinslisteId)}/data`,
      content: () => <ZinslisteVorschreibung zinslisteId={zinslisteId} />,
    },
    {
      state: zinsliste.status.value === ZinslisteStatus.Invalid ? 'error' : undefined,
      title: DEVIATIONS_MENU_LIST_ITEM_TITLE,
      icon: <ThunderboltOutlined />,
      uri: `${generatePathToZinslisteDetailsPage(objektId, zinslisteId)}/discrepancies`,
      content: () => <ZinslisteDeviationList objektId={objektId} zinslisteId={zinslisteId} status={zinsliste.status} onAction={refetch} />,
    },
  ],
});

export default menuListZinsliste;
