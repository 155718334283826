import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentProposalListQueryVariables = Types.Exact<{
  mitarbeiterId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  statusList?: Types.InputMaybe<Array<Types.PaymentProposalStatus> | Types.PaymentProposalStatus>;
}>;

export type PaymentProposalListQuery = {
  getPaymentProposalList: {
    data: Array<{ bezeichnung: string; paymentDate: string; paymentProposalId: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type PaymentProposalNameQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PaymentProposalNameQuery = {
  getPaymentProposalName: {
    data?: string | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const PaymentProposalListDocument = gql`
  query PaymentProposalList($mitarbeiterId: ID, $statusList: [PaymentProposalStatus!]) {
    getPaymentProposalList(mitarbeiterId: $mitarbeiterId, statusList: $statusList) {
      data {
        bezeichnung
        paymentDate
        paymentProposalId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePaymentProposalListQuery(baseOptions?: Apollo.QueryHookOptions<PaymentProposalListQuery, PaymentProposalListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentProposalListQuery, PaymentProposalListQueryVariables>(PaymentProposalListDocument, options);
}
export function usePaymentProposalListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentProposalListQuery, PaymentProposalListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentProposalListQuery, PaymentProposalListQueryVariables>(PaymentProposalListDocument, options);
}
export function usePaymentProposalListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentProposalListQuery, PaymentProposalListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentProposalListQuery, PaymentProposalListQueryVariables>(PaymentProposalListDocument, options);
}
export type PaymentProposalListQueryHookResult = ReturnType<typeof usePaymentProposalListQuery>;
export type PaymentProposalListLazyQueryHookResult = ReturnType<typeof usePaymentProposalListLazyQuery>;
export type PaymentProposalListSuspenseQueryHookResult = ReturnType<typeof usePaymentProposalListSuspenseQuery>;
export type PaymentProposalListQueryResult = Apollo.QueryResult<PaymentProposalListQuery, PaymentProposalListQueryVariables>;
export const PaymentProposalNameDocument = gql`
  query PaymentProposalName {
    getPaymentProposalName {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePaymentProposalNameQuery(baseOptions?: Apollo.QueryHookOptions<PaymentProposalNameQuery, PaymentProposalNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentProposalNameQuery, PaymentProposalNameQueryVariables>(PaymentProposalNameDocument, options);
}
export function usePaymentProposalNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentProposalNameQuery, PaymentProposalNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentProposalNameQuery, PaymentProposalNameQueryVariables>(PaymentProposalNameDocument, options);
}
export function usePaymentProposalNameSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentProposalNameQuery, PaymentProposalNameQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentProposalNameQuery, PaymentProposalNameQueryVariables>(PaymentProposalNameDocument, options);
}
export type PaymentProposalNameQueryHookResult = ReturnType<typeof usePaymentProposalNameQuery>;
export type PaymentProposalNameLazyQueryHookResult = ReturnType<typeof usePaymentProposalNameLazyQuery>;
export type PaymentProposalNameSuspenseQueryHookResult = ReturnType<typeof usePaymentProposalNameSuspenseQuery>;
export type PaymentProposalNameQueryResult = Apollo.QueryResult<PaymentProposalNameQuery, PaymentProposalNameQueryVariables>;
