import React from 'react';
import { Link } from 'react-router-dom';
import { SubAbrechnungEigentuemerBestandseinheit } from '../../../../../../../types';
import { NestedTableWithColSelectorColProps } from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import DataWithShortenedText from '../../../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToBestandseinheitDetailsPage } from '../../../../../../Bestandseinheit/bestandseinheitUriPaths';
import { EuroAmount } from '../../../../../../../components/Number';

const subAbrTabBestandseinheitTableColumns: NestedTableWithColSelectorColProps<SubAbrechnungEigentuemerBestandseinheit>[] = [
  {
    title: 'Bestandseinheit',
    key: 'bestandseinheit',
    defaultSelected: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={25} text={record.bestandseinheit.bezeichnung}>
        {(shortenedText) => (
          <Link to={generatePathToBestandseinheitDetailsPage(record.bestandseinheit.objektId, record.bestandseinheit.bestandseinheitId)}>
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
    key: 'einnahmenAusgaben',
  },
  {
    title: '',
    key: 'summe',
  },
  {
    title: 'Ergebnis',
    key: 'ergebnis',
    align: 'right',
    defaultSelected: true,
    render: (text, record) => <EuroAmount value={record.bestandseinheitErgebnis} />,
  },
];

export default subAbrTabBestandseinheitTableColumns;
