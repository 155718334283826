import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TextbausteinFieldsFragmentDoc } from '../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
export type BelegTexteTemplateFieldsFragment = {
  textbausteinList: Array<{
    index: number;
    value?: string | null;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
    }>;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    type: { text: string; value: Types.TextbausteinType };
  }>;
};

export const BelegTexteTemplateFieldsFragmentDoc = gql`
  fragment BelegTexteTemplateFields on BelegTexteTemplate {
    textbausteinList {
      ...TextbausteinFields
      textbausteinList {
        ...TextbausteinFields
      }
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
