import React from 'react';
import AbrKontenzuordnungListing from '../../../../features/AbrKontenzuordnung/Listing/AbrKontenzuordnungListing';

const SysSettingsAbrKontenzuordnungPage = () => {
  return (
    <div style={{ paddingTop: '8px' }}>
      <AbrKontenzuordnungListing />
    </div>
  );
};

export default SysSettingsAbrKontenzuordnungPage;
