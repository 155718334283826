import React, { FC } from 'react';
import { Empty } from 'antd';
import { SubAbrechnung, SubAbrechnungEigentuemerListEntry } from '../../../../types';
import { Spacer } from '../../../../components/Grid';
import NestedTableWithColSelectorWrapper from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';
import NestedTableWithColSelector from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import subAbrechnungTableColumns from './subAbrechnungTableColumns';

type Props = {
  objektId: string;
  abrechnung: SubAbrechnung;
};

const SubAbrechnungTable: FC<Props> = ({ objektId, abrechnung }) => {
  return (
    <>
      <Spacer height={16} />
      <NestedTableWithColSelectorWrapper filterIdentifier="objekt-sub-abrechnung">
        <NestedTableWithColSelector<SubAbrechnungEigentuemerListEntry>
          locale={{
            emptyText: <Empty description={<span>Keine Subverwaltungsabrechnungen vorhanden</span>} />,
          }}
          dataSource={abrechnung.subAbrechnungEigentuemerListEntryList}
          pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
          rowKey={(record) => record.subAbrechnungEigentuemerId}
          columns={subAbrechnungTableColumns(objektId, abrechnung.subAbrechnungId)}
          expandable={{
            rowExpandable: () => false,
          }}
        />
      </NestedTableWithColSelectorWrapper>
    </>
  );
};

export default SubAbrechnungTable;
