import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteObjektAbrechnungMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type DeleteObjektAbrechnungMutation = {
  deleteObjektAbrechnung: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ObjektAbrechnungMarkUnderReviewMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type ObjektAbrechnungMarkUnderReviewMutation = {
  actionObjektAbrechnungMarkUnderReview: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const DeleteObjektAbrechnungDocument = gql`
  mutation DeleteObjektAbrechnung($objektId: ID!, $objektAbrechnungId: ID!) {
    deleteObjektAbrechnung(objektId: $objektId, objektAbrechnungId: $objektAbrechnungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteObjektAbrechnungMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteObjektAbrechnungMutation, DeleteObjektAbrechnungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteObjektAbrechnungMutation, DeleteObjektAbrechnungMutationVariables>(DeleteObjektAbrechnungDocument, options);
}
export type DeleteObjektAbrechnungMutationHookResult = ReturnType<typeof useDeleteObjektAbrechnungMutation>;
export type DeleteObjektAbrechnungMutationResult = Apollo.MutationResult<DeleteObjektAbrechnungMutation>;
export type DeleteObjektAbrechnungMutationOptions = Apollo.BaseMutationOptions<
  DeleteObjektAbrechnungMutation,
  DeleteObjektAbrechnungMutationVariables
>;
export const ObjektAbrechnungMarkUnderReviewDocument = gql`
  mutation ObjektAbrechnungMarkUnderReview($objektId: ID!, $objektAbrechnungId: ID!) {
    actionObjektAbrechnungMarkUnderReview(objektId: $objektId, objektAbrechnungId: $objektAbrechnungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useObjektAbrechnungMarkUnderReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<ObjektAbrechnungMarkUnderReviewMutation, ObjektAbrechnungMarkUnderReviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ObjektAbrechnungMarkUnderReviewMutation, ObjektAbrechnungMarkUnderReviewMutationVariables>(
    ObjektAbrechnungMarkUnderReviewDocument,
    options
  );
}
export type ObjektAbrechnungMarkUnderReviewMutationHookResult = ReturnType<typeof useObjektAbrechnungMarkUnderReviewMutation>;
export type ObjektAbrechnungMarkUnderReviewMutationResult = Apollo.MutationResult<ObjektAbrechnungMarkUnderReviewMutation>;
export type ObjektAbrechnungMarkUnderReviewMutationOptions = Apollo.BaseMutationOptions<
  ObjektAbrechnungMarkUnderReviewMutation,
  ObjektAbrechnungMarkUnderReviewMutationVariables
>;
