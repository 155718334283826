import { KundenSystem, UpdateKundenSystemInput } from '../../../../types';

export type KundenSystemUpdateFormValues = {
  name: string;
};

export const kundenSystemFormInitialValues: KundenSystemUpdateFormValues = { name: '' };

export const mapKundenSystemToFormValues = (kundenSystem?: KundenSystem): KundenSystemUpdateFormValues => {
  if (!kundenSystem) {
    return kundenSystemFormInitialValues;
  }
  return {
    name: kundenSystem.name,
  };
};

export const mapFormValuesToKundenSystem = (values: KundenSystemUpdateFormValues): UpdateKundenSystemInput => ({
  name: values.name,
});
