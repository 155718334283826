import { FC } from 'react';
import { FormikProps } from 'formik';
import { topAbrVerarbeitungEntryTableFormFields, TopAbrVerarbeitungEntryTableFormValues } from './ListingFiltersFormMapper';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { AppGenerierlaufType } from '../../../../../types';
import GenerierlaufBestandseinheitFormSelect from '../../../shared/Filters/App/GenerierlaufBestandseinheitFormSelect';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/App/GenerierlaufObjektFormSelect';
import GenerierlaufRechnungsEmpfaengerFormSelect from '../../../shared/Filters/App/GenerierlaufRechnungsEmpfaengerFormSelect';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/App/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufWeRechnungsAusstellerFormSelect from '../../../shared/Filters/App/GenerierlaufWeRechnungsAusstellerFormSelect';

type Props = {
  formikProps: FormikProps<TopAbrVerarbeitungEntryTableFormValues>;
  generierlaufId: string;
  generierlaufType: AppGenerierlaufType;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufType, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={topAbrVerarbeitungEntryTableFormFields.rechnungsausstellerId} label="Rechnungsaussteller">
            <GenerierlaufWeRechnungsAusstellerFormSelect
              name={topAbrVerarbeitungEntryTableFormFields.rechnungsausstellerId}
              generierlaufId={generierlaufId}
              generierlaufType={generierlaufType}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>

          <FormItemWithoutColon name={topAbrVerarbeitungEntryTableFormFields.rechnungsempfaengerId} label="Rechnungsempfänger">
            <GenerierlaufRechnungsEmpfaengerFormSelect
              name={topAbrVerarbeitungEntryTableFormFields.rechnungsempfaengerId}
              generierlaufId={generierlaufId}
              generierlaufType={generierlaufType}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={topAbrVerarbeitungEntryTableFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListFormSelect
              name={topAbrVerarbeitungEntryTableFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={generierlaufType}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={topAbrVerarbeitungEntryTableFormFields.objektId} label="Objekt">
            <GenerierlaufObjektFormSelect
              name={topAbrVerarbeitungEntryTableFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={generierlaufType}
              rechnungsAusstellerId={formikProps.values.rechnungsausstellerId}
              onChange={() => {
                formikProps.setFieldValue(topAbrVerarbeitungEntryTableFormFields.bestandseinheitIdList, []);
                formikProps.submitForm();
              }}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={topAbrVerarbeitungEntryTableFormFields.bestandseinheitIdList} label="Bestandseinheit">
            <GenerierlaufBestandseinheitFormSelect
              name={topAbrVerarbeitungEntryTableFormFields.bestandseinheitIdList}
              mode="multiple"
              generierlaufId={generierlaufId}
              generierlaufType={generierlaufType}
              objektId={formikProps.values.objektId}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
