import React, { FC, useEffect } from 'react';
import { Col, Row, Skeleton, Space, Typography } from 'antd';
import { useStandingInvoiceUpdatePreviewMutation } from '../../../../gql/StandingInvoiceMutations.types';
import { IncomingInvoiceFormValues } from '../../../incomingInvoiceFormMapper';
import { mapFormValuesToStandingInvoiceUpdate } from '../../../standingInvoiceFormMapper';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import StornoBookingPreviewDetails from './StornoBookingPreviewDetails';

type Props = {
  belegId: string;
  values: IncomingInvoiceFormValues;
};

const BookingPreview: FC<Props> = ({ belegId, values }) => {
  const input = mapFormValuesToStandingInvoiceUpdate(values);

  const [runStandingInvoiceStorno, { data }] = useStandingInvoiceUpdatePreviewMutation({
    variables: { belegId, input },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  useEffect(() => {
    runStandingInvoiceStorno();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previewData = data?.standingInvoiceUpdatePreview.data;

  if (!previewData) {
    return <Skeleton active />;
  }

  return (
    <Space direction="vertical">
      <Typography.Text>
        Durch die Änderung würden folgende Teilbeträge der Eingangsrechnung storniert und/oder mit dem angegeben Buchungsdatum neugebucht werden
      </Typography.Text>
      {previewData.map((partialAmount, index) => {
        const isPartialAmountSame = partialAmount.partialAmountAction.includes('unverändert');

        return (
          <Row key={index}>
            <Col span={6}>
              <Typography.Text>{partialAmount.partialAmountAction}</Typography.Text>
            </Col>
            <Col span={16}>{!isPartialAmountSame && <Typography.Text>{partialAmount.partialAmountDetails}</Typography.Text>}</Col>
          </Row>
        );
      })}
      <StornoBookingPreviewDetails belegId={belegId} input={input} />
    </Space>
  );
};

export default BookingPreview;
