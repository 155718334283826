import Fetcher from '../../../helpers/fetcher';
import { processAndReturnError } from '../../../helpers/APIUtils';
import { buildUri } from '../../../shared/uriHelpers';
import { QueryGetRechtstraegerListArgs } from '../../../types';

export const runExportRechtstraegerListAsExcel = async (props: any) => {
  try {
    const res = await Fetcher.getInstance().fetch(uriRechtstraegerListAsExcelExport(props.firmendatenId, props.queryParams));
    return await res;
  } catch (error: any) {
    return await processAndReturnError(error);
  }
};

const uriRechtstraegerListXls = (firmendatenId: string) => `/api/firmendaten/${firmendatenId}/rechtstraeger/xls`;

const uriRechtstraegerListAsExcelExport = (firmendatenId: string, queryParams: QueryGetRechtstraegerListArgs) =>
  `${buildUri(uriRechtstraegerListXls(firmendatenId), queryParams)}`;
