import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import WizardUpdatePage from './WizardUpdatePage';
import { URI_WIZARD_UPDATE_PAGE } from '../../features/Wizard/wizardApiPaths';

export const wizardUpdatePageRoute = (
  <Route path={URI_WIZARD_UPDATE_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<WizardUpdatePage />} />} />
);

export default wizardUpdatePageRoute;
