import { Empty } from 'antd';
import React, { FC } from 'react';
import { VertragListEntry, Vertragsart } from '../../../../../types';
import { useWeAndMietVertragListQuery } from '../../../../Vertrag/BeVertrag/gql/BeVertragQueries.types';
import weAndMietVertragTableColumns from './weAndMietVertragTableColumns';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { useFirmendatenBuchhaltungEinstellungQuery } from '../../../../AllgemeineEinstellung/gql/FirmendatenBuchhaltungEinstellungQueries.types';

type Props = {
  filterIdentifier: string;
  vertragsartList: Vertragsart[];
  rechnungsAusstellerId?: string;
  vertragspartnerId?: string;
};

const WeAndMietVertragTable: FC<Props> = ({ filterIdentifier, rechnungsAusstellerId, vertragspartnerId, vertragsartList }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useWeAndMietVertragListQuery,
    {
      variables: {
        rechnungsAusstellerId,
        vertragspartnerId,
        vertragsartList,
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const vertragList = data?.getWeAndMietVertragList.data.contentList || [];

  const { data: firmendatenBuchhaltungEinstellungData } = useFirmendatenBuchhaltungEinstellungQuery();

  const firmendatenBuchhaltungEinstellung = firmendatenBuchhaltungEinstellungData?.getFirmendatenBuchhaltungEinstellung.data;

  return (
    <TableWithColSelector<VertragListEntry>
      size="small"
      loading={loading}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={weAndMietVertragTableColumns(firmendatenBuchhaltungEinstellung?.leerstehungForderungskontoId)}
      dataSource={vertragList}
      rowKey={(record) => record.vertragId}
      filterIdentifier={filterIdentifier}
      locale={{
        emptyText: <Empty description={<span>Keine Verträge vorhanden</span>} />,
      }}
    />
  );
};

export default WeAndMietVertragTable;
