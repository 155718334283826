import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */
export const name1ValidationSchema = Yup.string().required(entityIsRequired('Firmenname')).max(50, entityMaxCharLength('Firmenname', 50));

export const name2ValidationSchema = Yup.string().nullable().max(50, entityMaxCharLength('Name 2', 50));

export const name3ValidationSchema = Yup.string().nullable().max(50, entityMaxCharLength('Name 3', 50));

export const firmennameFormPartValidationSchema = Yup.object({
  name1: name1ValidationSchema,
  name2: name2ValidationSchema,
  name3: name3ValidationSchema,
});

/* eslint-enable no-template-curly-in-string */
