import React from 'react';
import { MenuProps, Modal } from 'antd';
import { useTicketStatusListQuery } from '../gql/TicketQueries.types';
import { useTicketUpdateStatusMutation } from '../gql/TicketMutations.types';
import { showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { TicketBase, TicketStatus, TicketStatusTuple } from '../../../types';
import StatusBadge from '../../../components/Status/StatusBadge';
import { isTicketArchived } from '../ticketHelpers';

const useTicketStatusMenuItems = (ticket: TicketBase, onAction: () => void) => {
  const isArchived = isTicketArchived(ticket);

  const { data, loading: statusLoading } = useTicketStatusListQuery({ skip: isArchived });
  const statusList = data?.getTicketStatusList.data;

  const [runUpdate] = useTicketUpdateStatusMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  if (isArchived) return { statusLoading: false, statusMenuItems: [] };

  const updateStatus = async (status: TicketStatus) => {
    const response = await runUpdate({ variables: { ticketId: ticket.ticketId, input: { status } } });
    const ticketId = response.data?.actionTicketUpdateStatus.data.ticketId;
    if (!ticketId) return;
    showSuccessMsgUpdate(`Aufgabe Status`);
    onAction();
    const warningList = response.data?.actionTicketUpdateStatus.warningList || [];
    const allSubticketsDone = warningList.find((warning) => warning.type === 'ALL_SUB_TICKETS_OF_PARENT_DONE');
    if (!allSubticketsDone || !ticket.parent?.ticketId) return;
    const runUpdateParent = async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = await runUpdate({ variables: { ticketId: ticket.parent!.ticketId, input: { status: TicketStatus.Done } } });
      const ticketId = response.data?.actionTicketUpdateStatus.data.ticketId;
      if (!ticketId) return;
      showSuccessMsgUpdate(`Aufgabe Status`);
      onAction();
    };
    showConfirmCloseParentTicket(runUpdateParent)();
  };

  const statusMenuItems = (statusList?: TicketStatusTuple[]): MenuProps['items'] => {
    if (!statusList) return [];

    const statusItems = statusList.map((status, index) => ({
      key: `${status.value}-${index}`,
      label: `${status.text}`,
      onClick: () => {
        if (ticket.ticketStatus.value === status.value) return;
        updateStatus(status.value);
      },
      icon: <StatusBadge entityStatus={status} showBadgeText={false} style={{ marginLeft: 3, marginRight: 5 }} />,
      disabled: isArchived || status.value === ticket.ticketStatus.value,
      style: { cursor: 'pointer' },
    }));

    if (!statusItems.length) return [];

    return [{ type: 'divider' }, ...statusItems];
  };

  return {
    statusLoading,
    statusMenuItems: statusMenuItems(statusList) || [],
  };
};

const showConfirmCloseParentTicket = (runUpdate: () => void) => () => {
  Modal.confirm({
    title: `Hauptaufgabe als erledigt markieren?`,
    content: 'Alle Unteraufgaben sind für diese Aufgabe erledigt. Möchten Sie die Hauptaufgabe ebenfalls als erledigt markieren?',
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return runUpdate();
    },
  });
};

export default useTicketStatusMenuItems;
