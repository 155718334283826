import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from '../filtersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FibuBelegSymbolFormSelect from '../../../../BelegSymbol/Fibu/FibuBelegSymbolFormSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
};

const BelegSymbolSelectFormItem: FC<Props> = ({ formikProps }) => {
  return (
    <FormItemWithoutColon name={filtersFormFields.belegSymbolList} label={listingLabelList.belegSymbolList}>
      <FibuBelegSymbolFormSelect name={filtersFormFields.belegSymbolList} onChange={formikProps.submitForm} mode="multiple" />
    </FormItemWithoutColon>
  );
};

export default BelegSymbolSelectFormItem;
