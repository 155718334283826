import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../helpers/selectHelper';
import { useSpracheListQuery } from './gql/SpracheQueries.types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

type Props = {
  fieldHelp?: string | null;
} & Omit<SelectProps, 'name' | 'id' | 'placeholder'>;

const SpracheSelect: FC<Props> = ({ fieldHelp, ...restProps }) => {
  const { loading, data } = useSpracheListQuery();

  const spracheList = data?.getSpracheList.data ?? [];

  return (
    <FormItemWithFieldHelp name="sprache" label="Sprache" fieldHelp={fieldHelp}>
      <Select
        {...restProps}
        id="sprache"
        name="sprache"
        loading={loading}
        placeholder="Sprache auswählen"
        showSearch
        filterOption={selectFilterOption}
      >
        {spracheList.map((sprache) => (
          <Select.Option key={sprache.value} value={sprache.value}>
            {sprache.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithFieldHelp>
  );
};

export default SpracheSelect;
