import { FC } from 'react';
import { Space, Tag, Typography } from 'antd';
import { AuditOutlined } from '@ant-design/icons';
import { chunk } from 'lodash';
import AuflageListItemActions from './AuflageListItemActions';
import InfoFeldListItem from '../InfoFeldListItem';
import { ObjektInfoFeldAuflage } from '../../../../../../types';

type Props = {
  auflage: ObjektInfoFeldAuflage;
  objektId: string;
  onSuccess: () => void;
};

const AuflageListItem: FC<Props> = ({ auflage, objektId, onSuccess }) => {
  const auflageList = createAuflageFieldList(auflage);

  return (
    <InfoFeldListItem
      icon={<AuditOutlined />}
      content={{ fileIdList: auflage.fileIdList, props: { direction: 'vertical' } }}
      actions={<AuflageListItemActions infoFeld={auflage} objektId={objektId} onSuccess={onSuccess} />}
    >
      <Typography.Text type="secondary">Auflagen</Typography.Text>
      <Space>
        <Space direction="vertical">
          {/*http://jira.andromeda.intern/browse/EC-8678 see img for problem that is caused when to many attributes*/}
          {chunk(auflageList, 5).map((auflage, index) => {
            return (
              <Space key={index}>
                {auflage.map((a) => (
                  <Tag key={a}>{a}</Tag>
                ))}
              </Space>
            );
          })}
        </Space>
      </Space>
    </InfoFeldListItem>
  );
};

export const createAuflageFieldList = (auflage: ObjektInfoFeldAuflage) => {
  const auflageList: string[] = [];

  if (auflage.bebaut) {
    auflageList.push('Bebaut');
  }
  if (auflage.amtlicheQuadratmeterFestsetzung) {
    auflageList.push('Amtliche m² Festsetzung');
  }
  if (auflage.assanierungsgebiet) {
    auflageList.push('Assanierungsgebiet');
  }
  if (auflage.gruenderzeitviertel) {
    auflageList.push('Gründerzeitviertel');
  }
  if (auflage.denkmalschutz) {
    auflageList.push('Denkmalschutz');
  }
  if (auflage.naturschutz) {
    auflageList.push('Naturschutz');
  }
  if (auflage.wohnzone) {
    auflageList.push('Wohnzone');
  }
  if (auflage.vollanwendungMRG) {
    auflageList.push('Vollanwendung MRG');
  }
  if (auflage.schutzzone) {
    auflageList.push('Schutzzone');
  }
  return auflageList;
};

export default AuflageListItem;
