import { HistoryAttributeType } from '../../../types';
import {
  HistoryAttributeFields_HistoryAmountAttribute_Fragment,
  HistoryAttributeFields_HistoryBooleanAttribute_Fragment,
  HistoryAttributeFields_HistoryDateAttribute_Fragment,
  HistoryAttributeFields_HistoryFloatAttribute_Fragment,
  HistoryAttributeFields_HistoryHtmlAttribute_Fragment,
  HistoryAttributeFields_HistoryIntAttribute_Fragment,
  HistoryAttributeFields_HistoryPercentAttribute_Fragment,
  HistoryAttributeFields_HistoryStringAttribute_Fragment,
  HistoryAttributeFields_HistoryStringListAttribute_Fragment,
  HistoryAttributeFieldsFragment,
} from '../gql/HistoryFragments.types';

export const isAttributeAmount = (attribute: HistoryAttributeFieldsFragment): attribute is HistoryAttributeFields_HistoryAmountAttribute_Fragment =>
  attribute.__typename === 'HistoryAmountAttribute';

export const isAttributeBoolean = (attribute: HistoryAttributeFieldsFragment): attribute is HistoryAttributeFields_HistoryBooleanAttribute_Fragment =>
  attribute.__typename === 'HistoryBooleanAttribute';

export const isAttributeDate = (attribute: HistoryAttributeFieldsFragment): attribute is HistoryAttributeFields_HistoryDateAttribute_Fragment =>
  attribute.__typename === 'HistoryDateAttribute';

export const isAttributeFloat = (attribute: HistoryAttributeFieldsFragment): attribute is HistoryAttributeFields_HistoryFloatAttribute_Fragment =>
  attribute.__typename === 'HistoryFloatAttribute';

export const isAttributeHtml = (attribute: HistoryAttributeFieldsFragment): attribute is HistoryAttributeFields_HistoryHtmlAttribute_Fragment =>
  attribute.__typename === 'HistoryHtmlAttribute';

export const isAttributePercent = (attribute: HistoryAttributeFieldsFragment): attribute is HistoryAttributeFields_HistoryPercentAttribute_Fragment =>
  attribute.__typename === 'HistoryPercentAttribute';

export const isAttributeString = (attribute: HistoryAttributeFieldsFragment): attribute is HistoryAttributeFields_HistoryStringAttribute_Fragment =>
  attribute.__typename === 'HistoryStringAttribute';

export const isAttributeStringList = (
  attribute: HistoryAttributeFieldsFragment
): attribute is HistoryAttributeFields_HistoryStringListAttribute_Fragment => attribute.__typename === 'HistoryStringListAttribute';

export const isAttributeInt = (attribute: HistoryAttributeFieldsFragment): attribute is HistoryAttributeFields_HistoryIntAttribute_Fragment =>
  attribute.__typename === 'HistoryIntAttribute';

export type HistoryAttribute =
  | { type: HistoryAttributeType; value: HistoryAttributeValue; scaling?: number | null; longText?: boolean | null }
  | undefined;

export type HistoryAttributeValue = number | string | string[] | boolean | null | undefined;

export const mapAttributeToValueBasedOnType = (attribute: HistoryAttributeFieldsFragment): HistoryAttributeValue => {
  if (isAttributeAmount(attribute)) {
    return attribute.valueAmount;
  }
  if (isAttributeBoolean(attribute)) {
    return attribute.valueBoolean;
  }
  if (isAttributeDate(attribute)) {
    return attribute.valueDate;
  }
  if (isAttributeFloat(attribute)) {
    return attribute.valueFloat;
  }
  if (isAttributeHtml(attribute)) {
    return attribute.valueHtml;
  }
  if (isAttributePercent(attribute)) {
    return attribute.valuePercent;
  }
  if (isAttributeString(attribute)) {
    return attribute.valueString;
  }
  if (isAttributeStringList(attribute)) {
    return attribute.valueStringList;
  }
  if (isAttributeInt(attribute)) {
    return attribute.valueInt;
  }
  return '';
};
