import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import IndexSeriesTable from './Table/IndexSeriesTable';
import { IndexSeries } from '../../../../types';
import ListingFilters from '../../../IndexSeries/shared/Filters/ListingFilters';
import {
  IndexSeriesListingFiltersFormValues,
  mapFormValuesToIndexSeriesListingFilters,
  mapQueryParamsToFormValues,
} from '../../../IndexSeries/shared/Filters/listingFiltersFormMapper';
import IndexSeriesBtnAndDrawer from './IndexSeriesBtnAndDrawer';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { updateFirmendatenIndexSeriesQueryParams } from '../firmendatenIndexSeriesUriPaths';
import { TIndexSeriesQueryParams } from '../../../IndexSeries/indexSeriesUriPaths';

type Props = {
  indexSeriesList: IndexSeries[];
  onAction: () => void;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const IndexSeriesListing: FC<Props> = ({ indexSeriesList, onAction, showArchived, toggleShowArchived }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TIndexSeriesQueryParams>();

  const setInitialQueryParams = () => {
    const filters = mapQueryParamsToFormValues(queryParams);
    updateFirmendatenIndexSeriesQueryParams(navigate, mapFormValuesToIndexSeriesListingFilters(filters));
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik<IndexSeriesListingFiltersFormValues>
      initialValues={mapQueryParamsToFormValues(queryParams)}
      onSubmit={(values) => {
        const filters = mapFormValuesToIndexSeriesListingFilters(values);
        updateFirmendatenIndexSeriesQueryParams(navigate, filters);
      }}
    >
      {(formikProps) => (
        <>
          <ListingFilters
            formikProps={formikProps}
            actionButtons={<IndexSeriesBtnAndDrawer onSuccess={onAction} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />}
          />
          <IndexSeriesTable formikProps={formikProps} indexSeriesList={indexSeriesList} onAction={onAction} />
        </>
      )}
    </Formik>
  );
};

export default IndexSeriesListing;
