import React, { FC } from 'react';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { Button, Drawer, Dropdown, MenuProps } from 'antd';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, HistoryOutlined } from '@ant-design/icons';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../components/message';
import { useToggle } from '../../../hooks/useToggle';
import CardWithFooter from '../../../components/Card';
import { useGoBack } from '../../../shared/GoBack/GoBackContext';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { isStatusActive } from '../../../helpers/statusHelper';
import CardTitle from '../../../components/Card/CardTitle';
import { Konto } from '../../../types';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import KontoTemplateForm from '../Form/KontoTemplateForm';
import {
  useArchiveKontoTemplateMutation,
  useDeleteKontoTemplateMutation,
  useUnarchiveKontoTemplateMutation,
} from '../gql/KontenrahmenMutations.types';
import KontoTemplateChangeHistoryListingTable from '../Listing/Table/KontoTemplateChangeHistoryListingTable';
import { showKontoConfirmArchive, showKontoConfirmDelete } from '../../Kontenplan/shared/kontoConfirmModals';
import KontoCardContent from '../../Kontenplan/shared/KontoCardContent';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../constants/andromedaSystemSettingsUriPaths';

type Props = {
  konto: Konto;
  loading: boolean;
  onSuccess: () => void;
};

const KontoTemplateCard: FC<Props> = ({ konto, loading, onSuccess }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const goBack = useGoBack(URI_ANDROMEDA_SYS_SETTINGS.kontenRahmenPage);

  const entity = 'Konto';

  const [runDelete] = useDeleteKontoTemplateMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      goBack();
    },
    variables: {
      kontoId: konto.kontoId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveKontoTemplateMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onSuccess();
    },
    variables: {
      kontoId: konto.kontoId,
    },
  });

  const [runUnarchive] = useUnarchiveKontoTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onSuccess();
    },
    variables: {
      kontoId: konto.kontoId,
    },
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: !isStatusActive(konto.status) ? 'Reaktivieren' : 'Archivieren',
      onClick: showKontoConfirmArchive(runUnarchive, runArchive, konto),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: !!konto.deletable,
    },
    {
      key: '4',
      label: 'Löschen',
      onClick: showKontoConfirmDelete(konto, runDelete),
      icon: <DeleteOutlined />,
      disabled: !konto.deletable,
      danger: true,
    },
  ];

  return (
    <>
      <CardWithFooter
        loading={loading}
        style={{ minWidth: '320px' }}
        size="default"
        bordered={false}
        title={<CardTitle title={konto.bezeichnung} status={konto.status} />}
        extra={
          <Dropdown menu={{ items }}>
            <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
          </Dropdown>
        }
        createTs={konto.createTs}
        userId={konto.createdBy}
        mitarbeiterId={konto.createdByMitarbeiterId}
      >
        <KontoCardContent konto={konto} loading={loading} />
      </CardWithFooter>
      {/* Drawer & Modals */}
      <Drawer title="Konto bearbeiten" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <KontoTemplateForm
          konto={konto}
          onSuccess={() => {
            onCollapse();
            onSuccess();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <KontoTemplateChangeHistoryListingTable kontoId={konto.kontoId} />
      </HistoryModal>
    </>
  );
};
export default KontoTemplateCard;
