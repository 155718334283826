import React, { FC } from 'react';
import { FormikProps } from 'formik';
import {
  bookingSuggestionFormFields,
  BookingSuggestionFormValues,
  BSBuchungszeileForTable,
  bSOffenePostenFilterFormFields,
} from '../../Form/buchungsmaskeFormMapper';
import { useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { useOffenePostenListQuery } from '../../../../OffenePosten/gql/OffenePostenQueries.types';
import OffenePostenForPersonenkontoTable from './Table/Level1/OffenePostenForPersonenkontoTable';
import { mapOffenePostenAuszifferungListToFormValues } from '../auszifferungFormMapper';
import { isBuchungTypeRuecklastschrift } from '../../bookingSuggestionHelpers';
import SpaceForBookingBlockAndForm from '../../../../BookingDetails/shared/SpaceForBookingBlockAndForm';
import FiltersForOPListForPersonenkonto from '../../../../BookingDetails/shared/FiltersForOPListForPersonenkonto';

type Props = {
  formikProps: FormikProps<BookingSuggestionFormValues>;
  personenkonto: BSBuchungszeileForTable;
};

const OffenePostenForPersonenkontoListing: FC<Props> = ({ formikProps, personenkonto }) => {
  const { loading, pagination } = useQueryWithPagingAndSorting(
    useOffenePostenListQuery,
    {
      variables: {
        includeAusgeziffert: isBuchungTypeRuecklastschrift(formikProps.values.buchungsanweisung.buchungType), //Rücklastschrift => true, Zahlung => false
        kontoId: personenkonto.kontoId,
        belegnummer: formikProps.values.offenePostenFilter?.belegnummer,
        gesamtBetragFrom: formikProps.values.offenePostenFilter?.totalAmountFrom,
        gesamtBetragTo: formikProps.values.offenePostenFilter?.totalAmountTo,
        rechtstraegerId: formikProps.values.buchungsanweisung.buchungskreisRechtstraegerId,
      },
      // set formik values here instead of using useEffect to avoid endless loop while changing the pagination
      onCompleted: (data) => {
        formikProps.setFieldValue(
          bookingSuggestionFormFields.offenePostenForPersonenkontoList,
          mapOffenePostenAuszifferungListToFormValues(data?.getOffenePostenList.data?.contentList ?? [])
        );
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
    }
  );

  return (
    <SpaceForBookingBlockAndForm>
      <FiltersForOPListForPersonenkonto
        belegerNummerFormFieldName={`${bookingSuggestionFormFields.offenePostenFilter}.${bSOffenePostenFilterFormFields.belegnummer}`}
        totalAmountFromFormFieldName={`${bookingSuggestionFormFields.offenePostenFilter}.${bSOffenePostenFilterFormFields.totalAmountFrom}`}
        totalAmountToFormFieldName={`${bookingSuggestionFormFields.offenePostenFilter}.${bSOffenePostenFilterFormFields.totalAmountTo}`}
      />
      <OffenePostenForPersonenkontoTable formikProps={formikProps} loading={loading} pagination={pagination} />
    </SpaceForBookingBlockAndForm>
  );
};

export default OffenePostenForPersonenkontoListing;
