import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartMahnungVerbuchenGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartMahnungVerbuchenGenerierlaufMutation = {
  actionRestartMahnungVerbuchenGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateMahnungVerbuchenGenerierlaufMutationVariables = Types.Exact<{
  input: Types.MahnungGenerierlaufInput;
}>;

export type CreateMahnungVerbuchenGenerierlaufMutation = {
  createMahnungVerbuchenGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartMahnungVerbuchenGenerierlaufDocument = gql`
  mutation RestartMahnungVerbuchenGenerierlauf($generierlaufId: ID!) {
    actionRestartMahnungVerbuchenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartMahnungVerbuchenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartMahnungVerbuchenGenerierlaufMutation, RestartMahnungVerbuchenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartMahnungVerbuchenGenerierlaufMutation, RestartMahnungVerbuchenGenerierlaufMutationVariables>(
    RestartMahnungVerbuchenGenerierlaufDocument,
    options
  );
}
export type RestartMahnungVerbuchenGenerierlaufMutationHookResult = ReturnType<typeof useRestartMahnungVerbuchenGenerierlaufMutation>;
export type RestartMahnungVerbuchenGenerierlaufMutationResult = Apollo.MutationResult<RestartMahnungVerbuchenGenerierlaufMutation>;
export type RestartMahnungVerbuchenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartMahnungVerbuchenGenerierlaufMutation,
  RestartMahnungVerbuchenGenerierlaufMutationVariables
>;
export const CreateMahnungVerbuchenGenerierlaufDocument = gql`
  mutation CreateMahnungVerbuchenGenerierlauf($input: MahnungGenerierlaufInput!) {
    createMahnungVerbuchenGenerierlauf(input: $input) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateMahnungVerbuchenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMahnungVerbuchenGenerierlaufMutation, CreateMahnungVerbuchenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMahnungVerbuchenGenerierlaufMutation, CreateMahnungVerbuchenGenerierlaufMutationVariables>(
    CreateMahnungVerbuchenGenerierlaufDocument,
    options
  );
}
export type CreateMahnungVerbuchenGenerierlaufMutationHookResult = ReturnType<typeof useCreateMahnungVerbuchenGenerierlaufMutation>;
export type CreateMahnungVerbuchenGenerierlaufMutationResult = Apollo.MutationResult<CreateMahnungVerbuchenGenerierlaufMutation>;
export type CreateMahnungVerbuchenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateMahnungVerbuchenGenerierlaufMutation,
  CreateMahnungVerbuchenGenerierlaufMutationVariables
>;
