import { isEmpty } from 'lodash';
import { BeAufteilingsschluesselWert, BeAufteilingsschluesselWertOhneBezugInput } from '../../../../../types';
import { createValidFromForNewEntry, initialValuesBestandseinheitAufteilungsschluesselWert } from '../shared/beAufteilungsschluesselWertHelper';
import { FormFields, TFormattedDecimal } from '../../../../../helpers/formikHelper';
import { mapFormattedDecimalOrThrowIfEmpty } from '../../../../../components/Input-antd/formattedDecimalHelper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';

export type BeAufteilungsschluesselWertOhneBezugFormValues = {
  beAufteilungsschluesselWertId?: string | null;
  validFrom: string;
  isUsed: boolean;
  lastWert?: number;
  wert: TFormattedDecimal;
};

export const beAufteilungsschluesselWertOhneBezugFormFields: FormFields<BeAufteilungsschluesselWertOhneBezugFormValues> = {
  beAufteilungsschluesselWertId: 'beAufteilungsschluesselWertId',
  validFrom: 'validFrom',
  isUsed: 'isUsed',
  lastWert: 'lastWert',
  wert: 'wert',
};

export const mapOhneBezugWertToFormValues = (
  wertList: BeAufteilingsschluesselWert[],
  aufteilungsschluesselWert: BeAufteilingsschluesselWert
): BeAufteilungsschluesselWertOhneBezugFormValues => ({
  validFrom: aufteilungsschluesselWert.validFrom ? aufteilungsschluesselWert.validFrom : dayjsCustom().format('YYYY-MM-DD'),
  wert: aufteilungsschluesselWert.wert,
  isUsed: aufteilungsschluesselWert.aktiv,
  lastWert: 0,
  beAufteilungsschluesselWertId: aufteilungsschluesselWert.beAufteilungsschluesselWertId,
});

export const mapFormValuesToWertOhneBezug = (values: BeAufteilungsschluesselWertOhneBezugFormValues): BeAufteilingsschluesselWertOhneBezugInput => ({
  validFrom: dayjsCustom(values.validFrom).format('YYYY-MM-DD'),
  wert: mapFormattedDecimalOrThrowIfEmpty(values.wert),
});

export const createDefaultNewEntryForWertOhneBezug = (wertList: BeAufteilingsschluesselWert[]): BeAufteilingsschluesselWert => {
  if (!isEmpty(wertList)) {
    return {
      ...wertList[0],
      validFrom: createValidFromForNewEntry(wertList),
      beAufteilungsschluesselWertId: '',
    };
  }
  return initialValuesBestandseinheitAufteilungsschluesselWert;
};
