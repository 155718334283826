import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutation = {
  actionRestartVorschreibungMailDeliveryDetermineRecipientListGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufDocument = gql`
  mutation RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlauf($generierlaufId: ID!) {
    actionRestartVorschreibungMailDeliveryDetermineRecipientListGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutation,
    RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutation,
    RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutationVariables
  >(RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufDocument, options);
}
export type RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutationHookResult = ReturnType<
  typeof useRestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutation
>;
export type RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutationResult =
  Apollo.MutationResult<RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutation>;
export type RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutation,
  RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutationVariables
>;
