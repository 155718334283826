import React, { FC } from 'react';
import { Space } from 'antd';
import StatusBadge from '../../../../components/Status/StatusBadge';
import DocumentStatusTag from '../../../../components/Status/DocumentStatus/DocumentStatusTag';

import { SingleInvoice, IncomingInvoiceStatus } from '../../../../types';
import PaidTag from './PaidTag';

type Props = {
  rechnung: SingleInvoice;
};

const RowSingleInvoiceStatus: FC<Props> = ({ rechnung }) => {
  const { paid: isPaid } = rechnung;

  return (
    <Space size={4}>
      {isPaid ? <PaidTag /> : <DocumentStatusTag documentStatus={rechnung.status} />}
      {rechnung.status.value === IncomingInvoiceStatus.Uebertragen && rechnung.buchungsanweisungStatus && (
        <StatusBadge entityStatus={rechnung.buchungsanweisungStatus} />
      )}
    </Space>
  );
};

export default RowSingleInvoiceStatus;
