import React, { FC } from 'react';
import { Button } from 'antd';

type Props = {
  showArchived: boolean;
  toggleArchived: () => void;
};

const ReceivedBankTransactionListingActionButtons: FC<Props> = ({ showArchived, toggleArchived }) => {
  return <Button onClick={toggleArchived}>Archivierte Bankbuchungen {showArchived ? 'ausblenden' : 'anzeigen'}</Button>;
};

export default ReceivedBankTransactionListingActionButtons;
