import React, { FC } from 'react';
import { showSuccessMsgDelete } from '../../../../../../components/message';
import PersoneninfosUpdateForm from '../../../Form/Personeninfos/Update/PersoneninfosUpdateForm';
import { useDeleteRechtstraegerInfoFeldMutation } from '../../../gql/RechtstraegerInfoFeldMutations.types';
import { RechtstraegerInfoFeldFragment } from '../../../gql/rechtstraegerInfoFeldFragments.types';
import useTicketCount from '../../../../../Ticket/useTicketCount';
import InfoFeldListItemActions from '../InfoFeldListItemActions';

type Props = {
  infoFeld: RechtstraegerInfoFeldFragment;
  rechtstraegerId: string;
  onSuccess: () => void;
};

const PersoneninfosListItemActions: FC<Props> = ({ infoFeld, rechtstraegerId, onSuccess }) => {
  const { ticketCount, refetchTicketCount } = useTicketCount({ rechtstraegerInfoFeldId: infoFeld.rechtstraegerInfoFeldId });

  const onActionSuccess = () => {
    onSuccess();
    refetchTicketCount();
  };

  const [runDelete] = useDeleteRechtstraegerInfoFeldMutation({
    variables: { rechtstraegerId, infoFeldId: infoFeld.rechtstraegerInfoFeldId },
    onCompleted: () => {
      showSuccessMsgDelete(`Personeninfos Infofeld`);
      onActionSuccess();
    },
  });

  return (
    <InfoFeldListItemActions
      onDelete={runDelete}
      ticketCount={ticketCount}
      rechtstraegerId={rechtstraegerId}
      fileIdList={infoFeld.fileIdList}
      onSuccess={onActionSuccess}
      postIt={infoFeld.postIt}
      rechtstraegerInfoFeldId={infoFeld.rechtstraegerInfoFeldId}
      deletable={infoFeld.deletable}
      infoFeldStatus={infoFeld.status}
      renderUpdateForm={(closeDrawer, activeKeyTab) => (
        <PersoneninfosUpdateForm
          rechtstraegerId={rechtstraegerId}
          onInfoFeldAction={() => {
            onActionSuccess();
            closeDrawer();
          }}
          onTicketAction={onActionSuccess}
          onCancel={closeDrawer}
          infoFeld={infoFeld}
          activeKeyTab={activeKeyTab}
        />
      )}
    />
  );
};

export default PersoneninfosListItemActions;
