import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import ObjektInfoFeldListing from './ObjektInfoFeldListing';
import { Objekt } from '../../../types';

type ObjektInfoFeldTabProps = {
  objekt: Objekt;
};

const ObjektInfoFeldTab: FC<ObjektInfoFeldTabProps> = ({ objekt }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Informationen',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <ObjektInfoFeldListing objekt={objekt} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default ObjektInfoFeldTab;
