import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { FibuBuchungszeileFieldsFragmentDoc } from '../../BookingJournal/gql/FibuBuchungszeileFragments.types';
import { FibuRechtstraegerFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BuchungQueryVariables = Types.Exact<{
  buchungId: Types.Scalars['ID']['input'];
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type BuchungQuery = {
  getBuchung: {
    data: { bezeichnung: string; bookingSuggestionId?: string | null; buchungId: string; buchungsanweisungId: string; zahlungId?: string | null };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BuchungListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  bezeichnung?: Types.InputMaybe<Types.Scalars['String']['input']>;
  stornierteBuchung?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BuchungListQuery = {
  getBuchungList: {
    data: Array<{
      bezeichnung: string;
      bookingSuggestionId?: string | null;
      buchungId: string;
      buchungsanweisungId: string;
      zahlungId?: string | null;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BuchungszeilenWithDeviationsQueryVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type BuchungszeilenWithDeviationsQuery = {
  getBuchungszeilenWithDeviationInfo: {
    data?: Array<{ buchungszeileId: string }> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BuchungszeileListWithUmbuchungenQueryVariables = Types.Exact<{
  buchungIdList: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  includeStorno?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BuchungszeileListWithUmbuchungenQuery = {
  getBuchungszeileListWithUmbuchung: {
    data: Array<{
      ausgezifferterBetrag?: number | null;
      betrag: number;
      buchungId: string;
      buchungsKZ?: string | null;
      buchungsanweisungId: string;
      buchungskreisBezeichnung: string;
      buchungskreisId: string;
      buchungszeileId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fachlichePruefung?: boolean | null;
      heVertragId?: string | null;
      offenePostenId?: string | null;
      offenerBetrag?: string | null;
      offenerOpBetrag?: number | null;
      steuer?: number | null;
      steuersatz?: number | null;
      storniert: boolean;
      stornierteBuchungBezeichnung?: string | null;
      stornierteBuchungId?: string | null;
      text: string;
      umbuchung?: boolean | null;
      umgebucht?: boolean | null;
      updatedByMitarbeiterId?: string | null;
      ustVomAufwandSteuersatz?: number | null;
      vertragId?: string | null;
      vertragKurzbezeichnung?: string | null;
      heVertragKurzbezeichnung?: string | null;
      bestandseinheit?: { bestandseinheitId: string; bezeichnung?: string | null } | null;
      buchungsKopf: {
        auftragId?: string | null;
        belegDatum?: string | null;
        belegFileId?: string | null;
        belegId?: string | null;
        belegnummer?: string | null;
        buchungsdatum: string;
        buchungskreisRechtstraegerId: string;
        dataCarrierBelegId?: string | null;
        dueDate?: string | null;
        iban?: string | null;
        standingInvoicePartialAmountId?: string | null;
        statementNumber?: string | null;
        vertragspartnerId?: string | null;
        belegSymbol: { abbreviation: string; text: string; value: Types.FibuBelegSymbol };
        buchungsType: { text: string; value: Types.FibuBuchungType };
        waehrung: { text: string; value: Types.Waehrung };
      };
      gegenKonto?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      } | null;
      konto: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      };
      objekt?: { kurzBezeichnung: string; objektId: string } | null;
      sollHaben: { text: string; value: Types.SollHaben };
      source: { text: string; value: Types.BuchungSource };
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterBuchungBelegnummerListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterBuchungBelegnummerListQuery = {
  getFilterBuchungBelegnummerList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterBuchungVertragspartnerListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;

export type FilterBuchungVertragspartnerListQuery = {
  getFilterBuchungVertragspartnerList: {
    data: Array<{
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const BuchungDocument = gql`
  query Buchung($buchungId: ID!, $rechtstraegerId: ID!) {
    getBuchung(buchungId: $buchungId, rechtstraegerId: $rechtstraegerId) {
      data {
        bezeichnung
        bookingSuggestionId
        buchungId
        buchungsanweisungId
        zahlungId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useBuchungQuery(
  baseOptions: Apollo.QueryHookOptions<BuchungQuery, BuchungQueryVariables> &
    ({ variables: BuchungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuchungQuery, BuchungQueryVariables>(BuchungDocument, options);
}
export function useBuchungLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuchungQuery, BuchungQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuchungQuery, BuchungQueryVariables>(BuchungDocument, options);
}
export function useBuchungSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BuchungQuery, BuchungQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BuchungQuery, BuchungQueryVariables>(BuchungDocument, options);
}
export type BuchungQueryHookResult = ReturnType<typeof useBuchungQuery>;
export type BuchungLazyQueryHookResult = ReturnType<typeof useBuchungLazyQuery>;
export type BuchungSuspenseQueryHookResult = ReturnType<typeof useBuchungSuspenseQuery>;
export type BuchungQueryResult = Apollo.QueryResult<BuchungQuery, BuchungQueryVariables>;
export const BuchungListDocument = gql`
  query BuchungList($rechtstraegerId: String, $rechtstraegerIdList: [String!], $bezeichnung: String, $stornierteBuchung: Boolean) {
    getBuchungList(
      rechtstraegerId: $rechtstraegerId
      rechtstraegerIdList: $rechtstraegerIdList
      bezeichnung: $bezeichnung
      stornierteBuchung: $stornierteBuchung
    ) {
      data {
        bezeichnung
        bookingSuggestionId
        buchungId
        buchungsanweisungId
        zahlungId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useBuchungListQuery(baseOptions?: Apollo.QueryHookOptions<BuchungListQuery, BuchungListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuchungListQuery, BuchungListQueryVariables>(BuchungListDocument, options);
}
export function useBuchungListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuchungListQuery, BuchungListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuchungListQuery, BuchungListQueryVariables>(BuchungListDocument, options);
}
export function useBuchungListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BuchungListQuery, BuchungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BuchungListQuery, BuchungListQueryVariables>(BuchungListDocument, options);
}
export type BuchungListQueryHookResult = ReturnType<typeof useBuchungListQuery>;
export type BuchungListLazyQueryHookResult = ReturnType<typeof useBuchungListLazyQuery>;
export type BuchungListSuspenseQueryHookResult = ReturnType<typeof useBuchungListSuspenseQuery>;
export type BuchungListQueryResult = Apollo.QueryResult<BuchungListQuery, BuchungListQueryVariables>;
export const BuchungszeilenWithDeviationsDocument = gql`
  query BuchungszeilenWithDeviations($belegId: String!) {
    getBuchungszeilenWithDeviationInfo(belegId: $belegId, deviationsExists: true) {
      data {
        buchungszeileId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useBuchungszeilenWithDeviationsQuery(
  baseOptions: Apollo.QueryHookOptions<BuchungszeilenWithDeviationsQuery, BuchungszeilenWithDeviationsQueryVariables> &
    ({ variables: BuchungszeilenWithDeviationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuchungszeilenWithDeviationsQuery, BuchungszeilenWithDeviationsQueryVariables>(
    BuchungszeilenWithDeviationsDocument,
    options
  );
}
export function useBuchungszeilenWithDeviationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuchungszeilenWithDeviationsQuery, BuchungszeilenWithDeviationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuchungszeilenWithDeviationsQuery, BuchungszeilenWithDeviationsQueryVariables>(
    BuchungszeilenWithDeviationsDocument,
    options
  );
}
export function useBuchungszeilenWithDeviationsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BuchungszeilenWithDeviationsQuery, BuchungszeilenWithDeviationsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BuchungszeilenWithDeviationsQuery, BuchungszeilenWithDeviationsQueryVariables>(
    BuchungszeilenWithDeviationsDocument,
    options
  );
}
export type BuchungszeilenWithDeviationsQueryHookResult = ReturnType<typeof useBuchungszeilenWithDeviationsQuery>;
export type BuchungszeilenWithDeviationsLazyQueryHookResult = ReturnType<typeof useBuchungszeilenWithDeviationsLazyQuery>;
export type BuchungszeilenWithDeviationsSuspenseQueryHookResult = ReturnType<typeof useBuchungszeilenWithDeviationsSuspenseQuery>;
export type BuchungszeilenWithDeviationsQueryResult = Apollo.QueryResult<
  BuchungszeilenWithDeviationsQuery,
  BuchungszeilenWithDeviationsQueryVariables
>;
export const BuchungszeileListWithUmbuchungenDocument = gql`
  query BuchungszeileListWithUmbuchungen($buchungIdList: [String!]!, $rechtstraegerId: String, $includeStorno: Boolean) {
    getBuchungszeileListWithUmbuchung(buchungIdList: $buchungIdList, rechtstraegerId: $rechtstraegerId, includeStorno: $includeStorno) {
      data {
        ...FibuBuchungszeileFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${FibuBuchungszeileFieldsFragmentDoc}
`;
export function useBuchungszeileListWithUmbuchungenQuery(
  baseOptions: Apollo.QueryHookOptions<BuchungszeileListWithUmbuchungenQuery, BuchungszeileListWithUmbuchungenQueryVariables> &
    ({ variables: BuchungszeileListWithUmbuchungenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuchungszeileListWithUmbuchungenQuery, BuchungszeileListWithUmbuchungenQueryVariables>(
    BuchungszeileListWithUmbuchungenDocument,
    options
  );
}
export function useBuchungszeileListWithUmbuchungenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuchungszeileListWithUmbuchungenQuery, BuchungszeileListWithUmbuchungenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuchungszeileListWithUmbuchungenQuery, BuchungszeileListWithUmbuchungenQueryVariables>(
    BuchungszeileListWithUmbuchungenDocument,
    options
  );
}
export function useBuchungszeileListWithUmbuchungenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BuchungszeileListWithUmbuchungenQuery, BuchungszeileListWithUmbuchungenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BuchungszeileListWithUmbuchungenQuery, BuchungszeileListWithUmbuchungenQueryVariables>(
    BuchungszeileListWithUmbuchungenDocument,
    options
  );
}
export type BuchungszeileListWithUmbuchungenQueryHookResult = ReturnType<typeof useBuchungszeileListWithUmbuchungenQuery>;
export type BuchungszeileListWithUmbuchungenLazyQueryHookResult = ReturnType<typeof useBuchungszeileListWithUmbuchungenLazyQuery>;
export type BuchungszeileListWithUmbuchungenSuspenseQueryHookResult = ReturnType<typeof useBuchungszeileListWithUmbuchungenSuspenseQuery>;
export type BuchungszeileListWithUmbuchungenQueryResult = Apollo.QueryResult<
  BuchungszeileListWithUmbuchungenQuery,
  BuchungszeileListWithUmbuchungenQueryVariables
>;
export const FilterBuchungBelegnummerListDocument = gql`
  query FilterBuchungBelegnummerList($rechtstraegerId: String, $rechtstraegerIdList: [String!], $belegnummer: String) {
    getFilterBuchungBelegnummerList(rechtstraegerId: $rechtstraegerId, rechtstraegerIdList: $rechtstraegerIdList, belegnummer: $belegnummer) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterBuchungBelegnummerListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterBuchungBelegnummerListQuery, FilterBuchungBelegnummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterBuchungBelegnummerListQuery, FilterBuchungBelegnummerListQueryVariables>(
    FilterBuchungBelegnummerListDocument,
    options
  );
}
export function useFilterBuchungBelegnummerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterBuchungBelegnummerListQuery, FilterBuchungBelegnummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterBuchungBelegnummerListQuery, FilterBuchungBelegnummerListQueryVariables>(
    FilterBuchungBelegnummerListDocument,
    options
  );
}
export function useFilterBuchungBelegnummerListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterBuchungBelegnummerListQuery, FilterBuchungBelegnummerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterBuchungBelegnummerListQuery, FilterBuchungBelegnummerListQueryVariables>(
    FilterBuchungBelegnummerListDocument,
    options
  );
}
export type FilterBuchungBelegnummerListQueryHookResult = ReturnType<typeof useFilterBuchungBelegnummerListQuery>;
export type FilterBuchungBelegnummerListLazyQueryHookResult = ReturnType<typeof useFilterBuchungBelegnummerListLazyQuery>;
export type FilterBuchungBelegnummerListSuspenseQueryHookResult = ReturnType<typeof useFilterBuchungBelegnummerListSuspenseQuery>;
export type FilterBuchungBelegnummerListQueryResult = Apollo.QueryResult<
  FilterBuchungBelegnummerListQuery,
  FilterBuchungBelegnummerListQueryVariables
>;
export const FilterBuchungVertragspartnerListDocument = gql`
  query FilterBuchungVertragspartnerList($rechtstraegerId: String, $rechtstraegerIdList: [String!]) {
    getFilterBuchungVertragspartnerList(rechtstraegerId: $rechtstraegerId, rechtstraegerIdList: $rechtstraegerIdList) {
      data {
        ...FibuRechtstraeger
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${FibuRechtstraegerFragmentDoc}
`;
export function useFilterBuchungVertragspartnerListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterBuchungVertragspartnerListQuery, FilterBuchungVertragspartnerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterBuchungVertragspartnerListQuery, FilterBuchungVertragspartnerListQueryVariables>(
    FilterBuchungVertragspartnerListDocument,
    options
  );
}
export function useFilterBuchungVertragspartnerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterBuchungVertragspartnerListQuery, FilterBuchungVertragspartnerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterBuchungVertragspartnerListQuery, FilterBuchungVertragspartnerListQueryVariables>(
    FilterBuchungVertragspartnerListDocument,
    options
  );
}
export function useFilterBuchungVertragspartnerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterBuchungVertragspartnerListQuery, FilterBuchungVertragspartnerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterBuchungVertragspartnerListQuery, FilterBuchungVertragspartnerListQueryVariables>(
    FilterBuchungVertragspartnerListDocument,
    options
  );
}
export type FilterBuchungVertragspartnerListQueryHookResult = ReturnType<typeof useFilterBuchungVertragspartnerListQuery>;
export type FilterBuchungVertragspartnerListLazyQueryHookResult = ReturnType<typeof useFilterBuchungVertragspartnerListLazyQuery>;
export type FilterBuchungVertragspartnerListSuspenseQueryHookResult = ReturnType<typeof useFilterBuchungVertragspartnerListSuspenseQuery>;
export type FilterBuchungVertragspartnerListQueryResult = Apollo.QueryResult<
  FilterBuchungVertragspartnerListQuery,
  FilterBuchungVertragspartnerListQueryVariables
>;
