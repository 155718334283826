import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Col, Row, Space, Typography } from 'antd';
import styled from 'styled-components';
import { TopAbrDefGeneralSettingsFormValues } from '../../topAbrDefGeneralSettingsFormMapper';
import DueDateDisplayOptionSelect from './DueDateDisplayOptionSelect';
import DueDateMonthSelect from './DueDateMonthSelect';
import { DueDateDisplayOptionsInput } from '../../../../../../../../../types';
import { isNotNil } from '../../../../../../../../../helpers/assertionHelper';
import { isDueDateOptionTypeVerrechnungsperiode } from '../../../dueDateHelpers';
import FieldHelpIconWithTooltip from '../../../../../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  name: string;
  label: string;
  formikProps: FormikProps<TopAbrDefGeneralSettingsFormValues>;
  isDisabled: boolean;
  isMonthPickerDisabled: boolean;
  onChange: (value: DueDateDisplayOptionsInput | null) => void;
  fieldHelpNames?: { month?: string | null; type?: string | null };
};

const DueDateDisplayOptions: FC<Props> = ({ name, label, formikProps, isDisabled, isMonthPickerDisabled, onChange, fieldHelpNames }) => {
  const typeFormItemName = `${name}.type`;
  const monthFormItemName = `${name}.month`;

  return (
    <StyledSpace align="baseline" size={0}>
      <Typography.Text>
        {label} <FieldHelpIconWithTooltip fieldHelp={fieldHelpNames?.type} />
      </Typography.Text>
      <Row gutter={16}>
        <Col>
          <DueDateDisplayOptionSelect
            name={typeFormItemName}
            isDisabled={isDisabled}
            onChange={(value) => {
              formikProps.setFieldValue(monthFormItemName, isDueDateOptionTypeVerrechnungsperiode(value) ? '' : null); // needs to be set to '' for validation to work
              onChange(isNotNil(value) ? { type: value, month: null } : null);
            }}
            fieldHelp={fieldHelpNames?.type}
          />
        </Col>
        <Col>
          <DueDateMonthSelect
            name={monthFormItemName}
            isDisabled={isMonthPickerDisabled}
            onChange={(month) => {
              const type = formikProps.values[name as keyof TopAbrDefGeneralSettingsFormValues];
              onChange({ type: (type as DueDateDisplayOptionsInput).type, month });
            }}
            fieldHelp={fieldHelpNames?.month}
          />
        </Col>
      </Row>
    </StyledSpace>
  );
};

const StyledSpace = styled(Space)`
  width: 100%;
  display: flex;
  .ant-space-item:first-child {
    width: 33%;
    text-align: left;
    padding-left: 14px;
  }
`;
export default DueDateDisplayOptions;
