import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontierungstabelleFieldsFragmentDoc } from './KontierungstabelleFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KontierungstabelleListForSelectByKundenSystemIdQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type KontierungstabelleListForSelectByKundenSystemIdQuery = {
  getKontierungstabelleListByKundenSystemId: {
    data: Array<{ bezeichnung: string; kontierungstabelleId: string; firmendatenId?: string | null }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type KontierungstabelleListForSelectByFirmendatenIdQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type KontierungstabelleListForSelectByFirmendatenIdQuery = {
  getKontierungstabelleListByFirmendatenId: {
    data: Array<{ bezeichnung: string; kontierungstabelleId: string; firmendatenId?: string | null }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type KontierungstabelleListQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type KontierungstabelleListQuery = {
  getKontierungstabelleListByKundenSystemId: {
    data: Array<{
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type KontierungstabelleChangeHistoryListQueryVariables = Types.Exact<{
  kontierungstabelleId: Types.Scalars['ID']['input'];
}>;

export type KontierungstabelleChangeHistoryListQuery = {
  getKontierungstabelleChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const KontierungstabelleListForSelectByKundenSystemIdDocument = gql`
  query KontierungstabelleListForSelectByKundenSystemId($kundenSystemId: ID!, $includeArchiviert: Boolean, $withDetails: Boolean) {
    getKontierungstabelleListByKundenSystemId(kundenSystemId: $kundenSystemId, includeArchiviert: $includeArchiviert, withDetails: $withDetails) {
      data {
        bezeichnung
        kontierungstabelleId
        firmendatenId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useKontierungstabelleListForSelectByKundenSystemIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    KontierungstabelleListForSelectByKundenSystemIdQuery,
    KontierungstabelleListForSelectByKundenSystemIdQueryVariables
  > &
    ({ variables: KontierungstabelleListForSelectByKundenSystemIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontierungstabelleListForSelectByKundenSystemIdQuery, KontierungstabelleListForSelectByKundenSystemIdQueryVariables>(
    KontierungstabelleListForSelectByKundenSystemIdDocument,
    options
  );
}
export function useKontierungstabelleListForSelectByKundenSystemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KontierungstabelleListForSelectByKundenSystemIdQuery,
    KontierungstabelleListForSelectByKundenSystemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontierungstabelleListForSelectByKundenSystemIdQuery, KontierungstabelleListForSelectByKundenSystemIdQueryVariables>(
    KontierungstabelleListForSelectByKundenSystemIdDocument,
    options
  );
}
export function useKontierungstabelleListForSelectByKundenSystemIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        KontierungstabelleListForSelectByKundenSystemIdQuery,
        KontierungstabelleListForSelectByKundenSystemIdQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontierungstabelleListForSelectByKundenSystemIdQuery, KontierungstabelleListForSelectByKundenSystemIdQueryVariables>(
    KontierungstabelleListForSelectByKundenSystemIdDocument,
    options
  );
}
export type KontierungstabelleListForSelectByKundenSystemIdQueryHookResult = ReturnType<
  typeof useKontierungstabelleListForSelectByKundenSystemIdQuery
>;
export type KontierungstabelleListForSelectByKundenSystemIdLazyQueryHookResult = ReturnType<
  typeof useKontierungstabelleListForSelectByKundenSystemIdLazyQuery
>;
export type KontierungstabelleListForSelectByKundenSystemIdSuspenseQueryHookResult = ReturnType<
  typeof useKontierungstabelleListForSelectByKundenSystemIdSuspenseQuery
>;
export type KontierungstabelleListForSelectByKundenSystemIdQueryResult = Apollo.QueryResult<
  KontierungstabelleListForSelectByKundenSystemIdQuery,
  KontierungstabelleListForSelectByKundenSystemIdQueryVariables
>;
export const KontierungstabelleListForSelectByFirmendatenIdDocument = gql`
  query KontierungstabelleListForSelectByFirmendatenId($firmendatenId: ID!, $includeArchiviert: Boolean, $withDetails: Boolean) {
    getKontierungstabelleListByFirmendatenId(firmendatenId: $firmendatenId, includeArchiviert: $includeArchiviert, withDetails: $withDetails) {
      data {
        bezeichnung
        kontierungstabelleId
        firmendatenId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useKontierungstabelleListForSelectByFirmendatenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    KontierungstabelleListForSelectByFirmendatenIdQuery,
    KontierungstabelleListForSelectByFirmendatenIdQueryVariables
  > &
    ({ variables: KontierungstabelleListForSelectByFirmendatenIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontierungstabelleListForSelectByFirmendatenIdQuery, KontierungstabelleListForSelectByFirmendatenIdQueryVariables>(
    KontierungstabelleListForSelectByFirmendatenIdDocument,
    options
  );
}
export function useKontierungstabelleListForSelectByFirmendatenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KontierungstabelleListForSelectByFirmendatenIdQuery,
    KontierungstabelleListForSelectByFirmendatenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontierungstabelleListForSelectByFirmendatenIdQuery, KontierungstabelleListForSelectByFirmendatenIdQueryVariables>(
    KontierungstabelleListForSelectByFirmendatenIdDocument,
    options
  );
}
export function useKontierungstabelleListForSelectByFirmendatenIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        KontierungstabelleListForSelectByFirmendatenIdQuery,
        KontierungstabelleListForSelectByFirmendatenIdQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontierungstabelleListForSelectByFirmendatenIdQuery, KontierungstabelleListForSelectByFirmendatenIdQueryVariables>(
    KontierungstabelleListForSelectByFirmendatenIdDocument,
    options
  );
}
export type KontierungstabelleListForSelectByFirmendatenIdQueryHookResult = ReturnType<typeof useKontierungstabelleListForSelectByFirmendatenIdQuery>;
export type KontierungstabelleListForSelectByFirmendatenIdLazyQueryHookResult = ReturnType<
  typeof useKontierungstabelleListForSelectByFirmendatenIdLazyQuery
>;
export type KontierungstabelleListForSelectByFirmendatenIdSuspenseQueryHookResult = ReturnType<
  typeof useKontierungstabelleListForSelectByFirmendatenIdSuspenseQuery
>;
export type KontierungstabelleListForSelectByFirmendatenIdQueryResult = Apollo.QueryResult<
  KontierungstabelleListForSelectByFirmendatenIdQuery,
  KontierungstabelleListForSelectByFirmendatenIdQueryVariables
>;
export const KontierungstabelleListDocument = gql`
  query KontierungstabelleList($kundenSystemId: ID!, $includeArchiviert: Boolean, $withDetails: Boolean) {
    getKontierungstabelleListByKundenSystemId(kundenSystemId: $kundenSystemId, includeArchiviert: $includeArchiviert, withDetails: $withDetails) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useKontierungstabelleListQuery(
  baseOptions: Apollo.QueryHookOptions<KontierungstabelleListQuery, KontierungstabelleListQueryVariables> &
    ({ variables: KontierungstabelleListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontierungstabelleListQuery, KontierungstabelleListQueryVariables>(KontierungstabelleListDocument, options);
}
export function useKontierungstabelleListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KontierungstabelleListQuery, KontierungstabelleListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontierungstabelleListQuery, KontierungstabelleListQueryVariables>(KontierungstabelleListDocument, options);
}
export function useKontierungstabelleListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontierungstabelleListQuery, KontierungstabelleListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontierungstabelleListQuery, KontierungstabelleListQueryVariables>(KontierungstabelleListDocument, options);
}
export type KontierungstabelleListQueryHookResult = ReturnType<typeof useKontierungstabelleListQuery>;
export type KontierungstabelleListLazyQueryHookResult = ReturnType<typeof useKontierungstabelleListLazyQuery>;
export type KontierungstabelleListSuspenseQueryHookResult = ReturnType<typeof useKontierungstabelleListSuspenseQuery>;
export type KontierungstabelleListQueryResult = Apollo.QueryResult<KontierungstabelleListQuery, KontierungstabelleListQueryVariables>;
export const KontierungstabelleChangeHistoryListDocument = gql`
  query KontierungstabelleChangeHistoryList($kontierungstabelleId: ID!) {
    getKontierungstabelleChangeHistoryList(kontierungstabelleId: $kontierungstabelleId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useKontierungstabelleChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<KontierungstabelleChangeHistoryListQuery, KontierungstabelleChangeHistoryListQueryVariables> &
    ({ variables: KontierungstabelleChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontierungstabelleChangeHistoryListQuery, KontierungstabelleChangeHistoryListQueryVariables>(
    KontierungstabelleChangeHistoryListDocument,
    options
  );
}
export function useKontierungstabelleChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KontierungstabelleChangeHistoryListQuery, KontierungstabelleChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontierungstabelleChangeHistoryListQuery, KontierungstabelleChangeHistoryListQueryVariables>(
    KontierungstabelleChangeHistoryListDocument,
    options
  );
}
export function useKontierungstabelleChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<KontierungstabelleChangeHistoryListQuery, KontierungstabelleChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontierungstabelleChangeHistoryListQuery, KontierungstabelleChangeHistoryListQueryVariables>(
    KontierungstabelleChangeHistoryListDocument,
    options
  );
}
export type KontierungstabelleChangeHistoryListQueryHookResult = ReturnType<typeof useKontierungstabelleChangeHistoryListQuery>;
export type KontierungstabelleChangeHistoryListLazyQueryHookResult = ReturnType<typeof useKontierungstabelleChangeHistoryListLazyQuery>;
export type KontierungstabelleChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useKontierungstabelleChangeHistoryListSuspenseQuery>;
export type KontierungstabelleChangeHistoryListQueryResult = Apollo.QueryResult<
  KontierungstabelleChangeHistoryListQuery,
  KontierungstabelleChangeHistoryListQueryVariables
>;
