import React from 'react';
import { Route } from 'react-router-dom';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import AndromedaSysSettingsEmailDeliveryDefinitionPage from './AndromedaSysSettingsEmailDeliveryDefinitionPage';
import { isAdmin } from '../../../../security/permissionChecks';

const andromedaSysSettingsEmailDeliveryDefinitionPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.emailDeliveryDefinitionPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsEmailDeliveryDefinitionPage />} />}
  />
);

export default andromedaSysSettingsEmailDeliveryDefinitionPageRoute;
