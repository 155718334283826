import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import TableWithAlignedCols from '../../../../../../../components/Table/TableWithAlignedCols/TableWithAlignedCols';
import heVertragOffenePostenTableColumns from './heVertragOffenePostenTableColumns';
import offenePostenZahlungsrueckstaendeRowTable from './offenePostenZahlungsrueckstaendeRowTable';
import { HE_ABR_ABRECHNUNGSKREIS_LIST_TABLE_ID } from '../HeAbrHeVertragTable';
import { HeAbrechnungHeVertrag, HeAbrOffenePostenList } from '../../../../../../../types';

type Props = {
  abrechnung: HeAbrechnungHeVertrag;
};

const HeVertragOffenePostenTable: FC<Props> = ({ abrechnung }) => {
  const { offenePosten } = abrechnung;

  const offenPostenList = offenePosten && [offenePosten];

  const [heAnteileColWidth, setHeAnteileColWidth] = useState<number>();

  useLayoutEffect(() => {
    getColHeAnteileWidth().then((val) => setHeAnteileColWidth(val as number));
  }, []);

  useEffect(() => {
    const onRowToggle = async () => await getColHeAnteileWidth().then((val) => setHeAnteileColWidth(val as number));

    window.addEventListener('onRowToggle', onRowToggle);
    return () => window.removeEventListener('onRowToggle', onRowToggle);
  }, []);

  return offenPostenList ? (
    <TableWithAlignedCols<HeAbrOffenePostenList>
      dataSource={offenPostenList}
      columns={heVertragOffenePostenTableColumns(heAnteileColWidth)}
      expandedRow={() =>
        offenePostenZahlungsrueckstaendeRowTable(abrechnung.objekt.objektId, abrechnung.abrechnungszeitraumVon, abrechnung.abrechnungszeitraumBis)
      }
    />
  ) : (
    <></>
  );
};

const getColHeAnteileWidth = async () => {
  const heAbrAbrechnungskreisListTable = document.getElementById(HE_ABR_ABRECHNUNGSKREIS_LIST_TABLE_ID);
  const row = heAbrAbrechnungskreisListTable?.firstChild?.firstChild;

  return new Promise((resolve) => {
    setTimeout(() => {
      // @ts-ignore
      resolve(row?.childNodes[row.childNodes.length - 1].offsetWidth);
    }, 0);
  });
};
export default HeVertragOffenePostenTable;
