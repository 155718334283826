import React from 'react';
import { Space, Tooltip } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Budgeting, BudgetingVPos } from '../../../../../types';
import { EuroAmount } from '../../../../../components/Number';
import DistributionKeyList from '../../../shared/DistributionKeyList';
import { pathsToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import TableColumnHeadingWithPeriod from '../../../shared/TableColumnHeadingWithPeriod';
import BudgetingVPosIncreaseInput from './BudgetingVPosIncreaseInput';
import BudgetingVPosAnnualBudgetInput from './BudgetingVPosAnnualBudgetInput';
import BudgetingVPosTableColumnActions from './BudgetingVPosTableColumnActions';
import { isStatusArchived } from '../../../../../helpers/statusHelper';
import DataWithStatus from '../../../../../components/Helpers/DataWithStatus';
import LinkWithStatus from '../../../../../components/Link/LinkWithStatus';
import { NestedTableWithColSelectorColProps } from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

const budgetingVPosTableColumns = (
  budgeting: Budgeting,
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  refetch: () => Promise<unknown>,
  isUpdating: boolean,
  updatingRowKey: string | null,
  setUpdatingRowKey: React.Dispatch<React.SetStateAction<string | null>>
): NestedTableWithColSelectorColProps<BudgetingVPos>[] => {
  return [
    {
      title: 'Vorschreibungsposition',
      width: 200,
      defaultSelected: true,
      key: 'vpos',
      render: (text, record) => {
        return (
          <Space size={4}>
            {record.leaveFlatRate && (
              <Tooltip title="Pauschale belassen">
                <CloseCircleOutlined style={{ color: 'red' }} />
              </Tooltip>
            )}
            <LinkWithStatus
              status={record.status}
              text={record.bezeichnung}
              to={pathsToObjektDetailsPage(budgeting.objekt.objektId).vorgabenTab}
              maxTextLength={30}
              showBadgeDot={false}
              openInNewTab
            />
          </Space>
        );
      },
    },
    {
      title: <TableColumnHeadingWithPeriod text="Vorschreibung Vorjahr" date={budgeting.prevYear.toString()} />,
      colSelectorTitle: `Vorschreibung Vorjahr`,
      defaultSelected: true,
      key: 'prev-year',
      width: 170,
      align: 'right',
      render: (text, record) => (
        <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
          {() => <EuroAmount value={record.vorschreibungPrevYear} />}
        </DataWithStatus>
      ),
    },
    {
      title: <TableColumnHeadingWithPeriod text="Vorschreibung aktuell" date={budgeting.considerationPeriodUntil} endOf="month" />,
      colSelectorTitle: `Vorschreibung aktuell`,
      defaultSelected: true,
      key: 'current-year',
      width: 170,
      align: 'right',
      render: (text, record) => (
        <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
          {() => <EuroAmount value={record.vorschreibungCurrentYear} />}
        </DataWithStatus>
      ),
    },
    {
      title: 'Aufteilungsschlüssel',
      key: 'aufteilungsschluessel',
      defaultSelected: true,
      width: 200,
      align: 'right',
      render: (text, record) => (
        <DistributionKeyList disabled={isStatusArchived(record.status)} aufteilungsschluesselList={record.budgetingAufteilungsschluesselList} />
      ),
    },
    {
      title: 'Hochrechnung Restjahr',
      key: 'restjahr',
      defaultSelected: true,
      align: 'right',
      render: (text, record) => {
        return (
          <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
            {() => <EuroAmount value={record.vorschreibungRestOfCurrentYear} />}
          </DataWithStatus>
        );
      },
    },
    {
      title: 'Basisbetrag',
      defaultSelected: true,
      key: 'basisbetrag',
      align: 'right',
      render: (text, record) => (
        <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
          {() => <EuroAmount value={record.vorschreibungTotalCurrentYear} />}
        </DataWithStatus>
      ),
    },
    {
      title: 'Erhöhung %',
      defaultSelected: true,
      key: 'erhoehung',
      align: 'right',
      render: (text, record) => (
        <BudgetingVPosIncreaseInput
          budgeting={budgeting}
          budgetingVPos={record}
          refetch={refetch}
          isUpdating={isUpdating}
          updatingRowKey={updatingRowKey}
          setUpdatingRowKey={setUpdatingRowKey}
          disabled={isStatusArchived(record.status)}
        />
      ),
    },
    {
      title: 'Jahresbudget Summe',
      defaultSelected: true,
      key: 'jahresbudget-summe',
      align: 'right',
      render: (text, record) => (
        <BudgetingVPosAnnualBudgetInput
          budgeting={budgeting}
          budgetingVPos={record}
          refetch={refetch}
          isUpdating={isUpdating}
          updatingRowKey={updatingRowKey}
          setUpdatingRowKey={setUpdatingRowKey}
          disabled={isStatusArchived(record.status)}
        />
      ),
    },
    {
      title: 'Aktion',
      width: 100,
      align: 'center',
      defaultSelected: true,
      key: 'action',
      render: (text, record) => (
        <BudgetingVPosTableColumnActions
          budgeting={budgeting}
          budgetingVPos={record}
          refetch={refetch}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isUpdating={isUpdating}
          updatingRowKey={updatingRowKey}
          setUpdatingRowKey={setUpdatingRowKey}
          budgetingVPosStatus={record.status}
        />
      ),
    },
  ];
};

export default budgetingVPosTableColumns;
