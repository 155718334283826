import { Space, Spin, Typography } from 'antd';
import React from 'react';

const PDFCreatingStateView = () => {
  return (
    <Space direction="vertical" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spin />
      <Typography.Text>PDF Datei wird erstellt...</Typography.Text>
    </Space>
  );
};

export default PDFCreatingStateView;
