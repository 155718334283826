import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { usePaymentApprovalStatusListQuery } from '../../gql/Order/PaymentQueries.types';

type PaymentApprovalStatusSelectProps = Omit<SelectProps, 'id'>;

const PaymentApprovalStatusSelect: FC<PaymentApprovalStatusSelectProps> = ({ ...restProps }) => {
  const { loading, data } = usePaymentApprovalStatusListQuery();
  const statusList = data?.getPaymentApprovalStatusList.data ?? [];

  return (
    <Select
      {...restProps}
      mode="multiple"
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Status auswählen"
      allowClear
      style={{ minWidth: '200px', height: 22 }}
      showSearch
      filterOption={selectFilterOption}
    >
      {statusList.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaymentApprovalStatusSelect;
