import React, { FC } from 'react';
import { Button, Dropdown, MenuProps, Modal } from 'antd';
import { EllipsisOutlined, SendOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import SendAgainForm from './Form/SendAgainForm';
import { showSuccessMsgOther } from '../../../components/message';
import { MailData } from '../../../types';

type EmailDeliveryActionsProps = {
  emailDelivery: MailData;
  onSend: (emailAddress: string) => Promise<unknown>;
};

const EmailDeliveryActions: FC<EmailDeliveryActionsProps> = ({ emailDelivery, onSend }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const items: MenuProps['items'] = [{ key: '1', label: 'Erneut versenden', onClick: onCollapse, icon: <SendOutlined /> }];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
      <Modal title="Erneut versenden" onCancel={onCollapse} open={isCollapsed} destroyOnClose maskClosable={false} footer={false}>
        <SendAgainForm
          emailDelivery={emailDelivery}
          onSend={(formValues) => {
            onSend(formValues.emailAddress)
              .then(() => {
                showSuccessMsgOther('E-Mail erfolgreich gesendet');
              })
              .catch(() => {})
              .finally(() => {
                onCollapse();
              });
          }}
          onCancel={onCollapse}
        />
      </Modal>
    </>
  );
};

export default EmailDeliveryActions;
