import { FormFields } from '../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';
import { ObjektInfoFeldAuflage } from '../../../../../../types';

export interface AuflageUpdateFormValues extends InfoFeldFormValues {
  amtlicheQuadratmeterFestsetzung: boolean;
  assanierungsgebiet: boolean;
  bebaut: boolean;
  denkmalschutz: boolean;
  gruenderzeitviertel: boolean;
  naturschutz: boolean;
  schutzzone: boolean;
  vollanwendungMRG: boolean;
  wohnzone: boolean;
  deleteDocumentFileIdList: string[];
}

export const auflageUpdateFormFields: FormFields<AuflageUpdateFormValues> = {
  amtlicheQuadratmeterFestsetzung: 'amtlicheQuadratmeterFestsetzung',
  assanierungsgebiet: 'assanierungsgebiet',
  bebaut: 'bebaut',
  denkmalschutz: 'denkmalschutz',
  gruenderzeitviertel: 'gruenderzeitviertel',
  naturschutz: 'naturschutz',
  schutzzone: 'schutzzone',
  vollanwendungMRG: 'vollanwendungMRG',
  wohnzone: 'wohnzone',
  documentList: 'documentList',
  deleteDocumentFileIdList: 'deleteDocumentFileIdList',
};

export const mapToUpdateFormValues = (infoFeld: ObjektInfoFeldAuflage): AuflageUpdateFormValues => ({
  amtlicheQuadratmeterFestsetzung: infoFeld.amtlicheQuadratmeterFestsetzung,
  assanierungsgebiet: infoFeld.assanierungsgebiet,
  bebaut: infoFeld.bebaut,
  denkmalschutz: infoFeld.denkmalschutz,
  gruenderzeitviertel: infoFeld.gruenderzeitviertel,
  naturschutz: infoFeld.naturschutz,
  schutzzone: infoFeld.schutzzone,
  vollanwendungMRG: infoFeld.vollanwendungMRG,
  wohnzone: infoFeld.wohnzone,
  documentList: [],
  deleteDocumentFileIdList: [],
});
