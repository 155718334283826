import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import HeVertragDetailsPage from './HeVertragDetailsPage';
import { URI_DASHBOARD_OBJEKT_HE_VERTRAG_DETAILS_PAGE } from '../../features/Vertrag/HeVertrag/heVertragUriPaths';

export type HeVertragDetailsPageRouteParams = { objektId: string; heVertragId: string };

const heVertragDetailsPageRoute = (
  <Route
    path={`${URI_DASHBOARD_OBJEKT_HE_VERTRAG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<HeVertragDetailsPage />} />}
  />
);

export default heVertragDetailsPageRoute;
