import React from 'react';
import { Typography } from 'antd';
import { SubAbrOffenePostenList } from '../../../../../../../types';
import { NestedTableWithColSelectorColProps } from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { EuroAmount } from '../../../../../../../components/Number';

const subAbrTabOffenePostenTableColumns: NestedTableWithColSelectorColProps<SubAbrOffenePostenList>[] = [
  {
    title: 'Position',
    defaultSelected: true,
    align: 'left',
    render: () => <Typography.Text>Zahlungsrückstände</Typography.Text>,
  },
  {
    title: 'Objektsumme',
    defaultSelected: true,
    align: 'right',
    render: (op) => <EuroAmount value={op.summe} />,
  },
];

export default subAbrTabOffenePostenTableColumns;
