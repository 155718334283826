import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    min-height: 250px;
  }
  .ant-modal-close {
    width: 65px;
  }
`;
