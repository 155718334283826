import React from 'react';
import { useToggle } from '../../../../hooks/useToggle';
import { useShowArchivedQueryParamState } from '../../../../hooks/useShowArchivedQueryParamState';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { useUstKategorieListQuery } from '../gql/UstKategorieQueries.types';
import UstKategorieButtonsAndDrawer from './UstKategorieButtonsAndDrawer';
import UstKategorieTable from './Table/UstKategorieTable';
import { UstKategorieWithStatus } from '../ustKategorieUriHelper';

const UstKategorieListing = () => {
  const [isCollapsed, onCollapse] = useToggle();
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();

  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const { data, loading, refetch } = useUstKategorieListQuery({
    variables: {
      kundenSystemId,
      withDetails: true,
      includeArchiviert: showArchived,
    },
  });
  const ustKategorieList = data?.getUstKategorieListByKundenSystemId.data as UstKategorieWithStatus[] | undefined;

  return (
    <>
      <UstKategorieButtonsAndDrawer
        isCollapsed={isCollapsed}
        onCollapse={onCollapse}
        refetch={refetch}
        showArchived={showArchived}
        toggleShowArchived={toggleShowArchived}
      />
      <UstKategorieTable loading={loading} onCollapse={onCollapse} refetch={refetch} ustKategorieList={ustKategorieList} />
    </>
  );
};

export default UstKategorieListing;
