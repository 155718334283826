import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useIncomingInvoiceKontoListQuery } from '../../gql/IncomingInvoiceFilterQueries.types';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import TextWithTag from '../../../../components/Text/TextWithTag';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const KontoFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useIncomingInvoiceKontoListQuery();
  const kontoList = data?.getIncomingInvoiceKontoList?.data ?? [];

  return (
    <Select
      {...restProps}
      id={restProps.name}
      size="small"
      loading={loading}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Konto auswählen"
    >
      {kontoList.map((konto) => (
        <Select.Option key={konto.kontoId} value={konto.kontoId}>
          <TextWithTag textContent={konto.nummer} tagContent={konto.bezeichnung} maxTextLength={30} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default KontoFormSelect;
