import * as Types from '../../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportLogosQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type ReportLogosQuery = {
  getReportLogos: {
    data: {
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      logoGrossFileId?: string | null;
      logoKleinFileId?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      logoGrossAlignment?: { text: string; value: Types.DocumentHeaderAndFooterImageAlignment } | null;
      logoKleinAlignment?: { text: string; value: Types.DocumentHeaderAndFooterImageAlignment } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ReportLogosDocument = gql`
  query ReportLogos($firmendatenId: ID!) {
    getReportLogos(firmendatenId: $firmendatenId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
        logoGrossFileId
        logoGrossAlignment {
          text
          value
        }
        logoKleinFileId
        logoKleinAlignment {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useReportLogosQuery(
  baseOptions: Apollo.QueryHookOptions<ReportLogosQuery, ReportLogosQueryVariables> &
    ({ variables: ReportLogosQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportLogosQuery, ReportLogosQueryVariables>(ReportLogosDocument, options);
}
export function useReportLogosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportLogosQuery, ReportLogosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportLogosQuery, ReportLogosQueryVariables>(ReportLogosDocument, options);
}
export function useReportLogosSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReportLogosQuery, ReportLogosQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReportLogosQuery, ReportLogosQueryVariables>(ReportLogosDocument, options);
}
export type ReportLogosQueryHookResult = ReturnType<typeof useReportLogosQuery>;
export type ReportLogosLazyQueryHookResult = ReturnType<typeof useReportLogosLazyQuery>;
export type ReportLogosSuspenseQueryHookResult = ReturnType<typeof useReportLogosSuspenseQuery>;
export type ReportLogosQueryResult = Apollo.QueryResult<ReportLogosQuery, ReportLogosQueryVariables>;
