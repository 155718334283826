import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import { BudgetingDetailsPageRouteParams } from './routes';
import BudgetingCard from '../../features/Budgeting/Card/BudgetingCard';
import menuListBudgeting from './menuListBudgeting';
import { useBudgetingQuery } from '../../features/Budgeting/gql/BudgetingQueries.types';
import { Budgeting } from '../../types';
import { useShowArchivedQueryParamState } from '../../hooks/useShowArchivedQueryParamState';

const BudgetingDetailsPage = () => {
  const { objektId, budgetingId } = useParams() as BudgetingDetailsPageRouteParams;
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();
  const { data, refetch } = useBudgetingQuery({ variables: { objektId, budgetingId, includeArchiviert: showArchived } });

  // State to track all updates
  const [isUpdating, setIsUpdating] = useState(false);

  // State to track all updates triggered by a row input/action
  const [updatingRowKey, setUpdatingRowKey] = useState<string | null>(null);

  // State to track loading state manually (show loading spinner for the table)
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsUpdating(updatingRowKey !== null);
  }, [updatingRowKey]);

  const budgeting = data?.getBudgeting.data;

  return (
    <DetailsPageTemplate<Budgeting>
      pageTitle={() => 'Budgetierung'}
      data={budgeting}
      card={(budgeting) => (
        <BudgetingCard
          budgeting={budgeting}
          objektId={objektId}
          refetch={refetch}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      sidebarMenuList={(budgeting) =>
        menuListBudgeting(
          objektId,
          budgeting,
          isLoading,
          setIsLoading,
          refetch,
          isUpdating,
          updatingRowKey,
          setUpdatingRowKey,
          showArchived,
          toggleShowArchived
        )
      }
    />
  );
};

export default BudgetingDetailsPage;
