import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { TopAbrechnungFieldsFragmentDoc, TopAbrechnungOverviewFieldsFragmentDoc } from './TopAbrechnungFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TopAbrechnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAbrechnungId: Types.Scalars['ID']['input'];
  topAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type TopAbrechnungQuery = {
  getTopAbrechnung: {
    data: {
      aufwand: number;
      bestandseinheitBezeichnung?: string | null;
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      dueDate?: string | null;
      einleitungsText: string;
      einnahmen: number;
      ergebnisText: string;
      fileId?: string | null;
      fromInclusive: string;
      guthaben: boolean;
      objektId: string;
      objektKurzBezeichnung: string;
      rueckstandBestandnehmer: number;
      saldo: number;
      schlussText: string;
      toInclusive: string;
      topAbrechnungId: string;
      abrechnungskreisList: Array<{
        abrechnungskreisId: string;
        aufteilungsschluesselBezeichnung?: string | null;
        aufwand: number;
        aufwandGesamt: number;
        aufwandProzent?: number | null;
        bezeichnung: string;
        brutto: number;
        einnahmen: number;
        netto: number;
        steuersatz: number;
        ust: number;
        ustKategorieEntryId: string;
        ustKategorieId: string;
        ustKategorieVersionId: string;
        abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
        aufwandAufteilungList: Array<{
          aufteilungsschluesselBezeichnung: string;
          summe: number;
          kontoList: Array<{ bezeichnung: string; kontoId: string; summe: number }>;
          zeitscheibeAufwandList: Array<{
            anteilAufwand: number;
            aufwand: number;
            beAufteilungsschluesselWert?: number | null;
            bisInklusive: string;
            prozentAufteilungsschluesselAnteil: number;
            sumAufteilungsschluesselWert: number;
            vonInklusive: string;
          }>;
        }>;
        einnahmenBuchungszeileList: Array<{
          auftragId?: string | null;
          belegDatum: string;
          belegFileId?: string | null;
          belegId?: string | null;
          belegnummer: string;
          betrag: number;
          buchungId: string;
          buchungskreisId: string;
          dueDate: string;
          kontoBezeichnung: string;
          kontoId: string;
          kontoNummer: string;
          offenerBetrag: number;
          text: string;
          buchungskreisRechtstraeger: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          sollHaben: { text: string; value: Types.SollHaben };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
        }>;
        ustKategorieStatus: { description?: string | null; text: string; value: Types.UstKategorieStatus };
        ustVomAufwandAnteilList: Array<{
          anteilNetto: number;
          basis: number;
          belastung: number;
          summe: number;
          ustVomAufwand: number;
          ustVomAufwandSteuersatz: number;
          grundlage: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand };
        }>;
      }>;
      dueDateDisplayOption?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
      dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
      infoAbrechnungskreisList: Array<{
        abrechnungsdefinitionId: string;
        abrechnungsdefinitionVersionId: string;
        abrechnungskreisId: string;
        bezeichnung: string;
        saldo: number;
        sumAufwand: number;
        vorsaldo: number;
        aufwand: {
          summe: number;
          kontoList: Array<{
            bezeichnung: string;
            kontoId: string;
            summe: number;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              umbuchung?: boolean | null;
              umgebucht?: boolean | null;
              buchungskreisRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }>;
          }>;
        };
        erloes: {
          summe: number;
          kontoList: Array<{
            bezeichnung: string;
            kontoId: string;
            summe: number;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              umbuchung?: boolean | null;
              umgebucht?: boolean | null;
              buchungskreisRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }>;
          }>;
        };
      }>;
      paymentMethod: { text: string; value: Types.PaymentMethod };
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { description?: string | null; text: string; value: Types.TopAbrechnungStatus };
      type: { text: string; value: Types.AbrechnungType };
      verrechnungsart: { text: string; value: Types.Verrechnungsart };
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const TopAbrechnungDocument = gql`
  query TopAbrechnung($objektId: ID!, $objektAbrechnungId: ID!, $topAbrechnungId: ID!) {
    getTopAbrechnung(objektId: $objektId, objektAbrechnungId: $objektAbrechnungId, topAbrechnungId: $topAbrechnungId) {
      data {
        ...TopAbrechnungFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${TopAbrechnungFieldsFragmentDoc}
`;
export function useTopAbrechnungQuery(
  baseOptions: Apollo.QueryHookOptions<TopAbrechnungQuery, TopAbrechnungQueryVariables> &
    ({ variables: TopAbrechnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopAbrechnungQuery, TopAbrechnungQueryVariables>(TopAbrechnungDocument, options);
}
export function useTopAbrechnungLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopAbrechnungQuery, TopAbrechnungQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopAbrechnungQuery, TopAbrechnungQueryVariables>(TopAbrechnungDocument, options);
}
export function useTopAbrechnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TopAbrechnungQuery, TopAbrechnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TopAbrechnungQuery, TopAbrechnungQueryVariables>(TopAbrechnungDocument, options);
}
export type TopAbrechnungQueryHookResult = ReturnType<typeof useTopAbrechnungQuery>;
export type TopAbrechnungLazyQueryHookResult = ReturnType<typeof useTopAbrechnungLazyQuery>;
export type TopAbrechnungSuspenseQueryHookResult = ReturnType<typeof useTopAbrechnungSuspenseQuery>;
export type TopAbrechnungQueryResult = Apollo.QueryResult<TopAbrechnungQuery, TopAbrechnungQueryVariables>;
