import { FormFields } from '../../../../helpers/formikHelper';
import { MahnungErzeugenGenerierlaufInput } from '../../../../types';

export type MahnungCreateFormValues = {
  rechnungsAusstellerId: string;
  objektId: string;
};

export const mahnungCreateFormInitialValues: MahnungCreateFormValues = {
  rechnungsAusstellerId: '',
  objektId: '',
};

export const mapFormValuesToMahnungCreate = (formValues: MahnungCreateFormValues): MahnungErzeugenGenerierlaufInput => ({
  rechnungsAusstellerId: formValues.rechnungsAusstellerId ? formValues.rechnungsAusstellerId : undefined,
  objektId: formValues.objektId ? formValues.objektId : undefined,
});

export const mahnungCreateFormFields: FormFields<MahnungCreateFormValues> = {
  rechnungsAusstellerId: 'rechnungsAusstellerId',
  objektId: 'objektId',
};
