import React from 'react';
import { Space, Tag, Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../components/Card';
import { archivedOrNormalStyle } from '../../../helpers/statusHelper';
import { AbrechnungsdefinitionListEntry } from '../../../types';
import { NestedTableColProps } from '../../../components/Table/NestedTable/NestedTable';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import TemplateIncompleteWarning from '../../Abrechnungsdefinition/shared/TemplateIncompleteWarning';
import AbrDefTemplateTableActions from './AbrDefTemplateTableActions';

const abrDefTemplateTableColumns = (onActionSuccess: () => void): NestedTableColProps<AbrechnungsdefinitionListEntry>[] => [
  {
    title: 'Bezeichnung',
    render: (text, record) => (
      <Space>
        <Typography.Text>{record.bezeichnung}</Typography.Text>
        <TemplateIncompleteWarning textBausteineBefuellt={record.textBausteineBefuellt} />
      </Space>
    ),
  },
  {
    title: 'Abrechnungstyp',
    render: (text, record) => <Tag style={record.status ? archivedOrNormalStyle(record.status) : {}}>{record.type.text}</Tag>,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    render: (text, record) => <AbrDefTemplateTableActions abrechnungsdefinition={record} onActionSuccess={onActionSuccess} />,
  },
];

export default abrDefTemplateTableColumns;
