import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Input } from 'formik-antd';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { PaymentProposalCreateFormValues, paymentProposalFormFields } from './createFormMapper';
import { usePaymentProposalNameQuery } from '../../../../PaymentProposal/gql/PaymentProposalQueries.types';
import { LoadingCol } from '../../../../../components/Loading';

type Props = {
  formikProps: FormikProps<PaymentProposalCreateFormValues>;
};

const PaymentProposalNameFormItem: FC<Props> = ({ formikProps }) => {
  const { loading } = usePaymentProposalNameQuery({
    onCompleted: (res) => {
      const paymentProposalName = res?.getPaymentProposalName.data;
      formikProps.setFieldValue(paymentProposalFormFields.paymentProposalName, paymentProposalName);
    },
  });

  return (
    <FormItemWithoutColon name={paymentProposalFormFields.paymentProposalName} label="Bezeichnung">
      <LoadingCol isLoading={loading}>
        <Input
          id={paymentProposalFormFields.paymentProposalName}
          name={paymentProposalFormFields.paymentProposalName}
          size="small"
          placeholder="z.B. 20240501 - Max Mustermann"
          allowClear
        />
      </LoadingCol>
    </FormItemWithoutColon>
  );
};

export default PaymentProposalNameFormItem;
