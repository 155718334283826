import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { HauseigentuemerAbrechnung } from '../hauseigentuemerAbrechnung-types';
import pdfStyles from '../../styles/pdfStyles';
import RechnungsEmpfaengerBlock from '../../shared/RechnungsEmpfaengerBlock';
import Absender from '../../shared/Absender';

const HeAHauseigentuemerAndDatumBlock: FC<{ data: HauseigentuemerAbrechnung }> = ({ data }) => (
  <View style={[pdfStyles.row, { marginTop: '3mm' }]}>
    <View style={[pdfStyles.column, pdfStyles.addressor]}>
      <Absender absender={data.absender} />
      <View style={[{ marginBottom: '7mm' }]} />
      {/* Rechnungsempfänger */}
      <RechnungsEmpfaengerBlock rechnungsEmpfaengerShippingLabel={data.hauseigentuemer.rechnungsEmpfaenger.rechnungsEmpfaengerShippingLabel} />
    </View>
    <View style={[pdfStyles.column, { width: '100%', textAlign: 'right' }]}>
      <Text style={[pdfStyles.textNormal]}>
        {data.hauseigentuemer.rechnungsEmpfaenger.kundennummer.text} {data.hauseigentuemer.rechnungsEmpfaenger.kundennummer.nummer}
      </Text>
      <Text style={[pdfStyles.textNormal]}>
        {data.hauseigentuemer.anteile.label} {data.hauseigentuemer.anteile.value}
      </Text>
      {/* City+Datum */}
      <Text style={[pdfStyles.textNormal]}>{data.abrechnungsDatumLine}</Text>
    </View>
  </View>
);

export default HeAHauseigentuemerAndDatumBlock;
