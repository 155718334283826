import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import SingleInvoiceUpdatePage from './SingleInvoiceUpdatePage';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import { incomingInvoicePaths } from '../../../features/IncomingInvoice/incomingInvoiceUriPaths';

const singleInvoiceUpdatePageRoute = (
  <Route
    path={incomingInvoicePaths.singleInvoiceUpdate}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<SingleInvoiceUpdatePage />} />}
  />
);

export default singleInvoiceUpdatePageRoute;
