import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TemplateHeAbrechnung from './TemplateHeAbrechnung';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import { HeOrSubAbrDefOutputOptionsFieldHelpNames } from '../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';
import { HeOrSubAbrDefGeneralSettingsFormValues } from '../shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';
import HeOrSubGeneralSettingsForm from '../shared/HeOrSub/GeneralSettingForm/HeOrSubGeneralSettingsForm';

type Props = {
  heAbrDefTemplateValues: TextbausteinFormValues[];
  heAbrDefGeneralSettingsValues: HeOrSubAbrDefGeneralSettingsFormValues;
  setHeAbrDefGeneralSettingsValues: (values: HeOrSubAbrDefGeneralSettingsFormValues) => void;
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  onSubmit: () => void;
  isSubmitting?: boolean;
  onBack: () => void;
  fieldHelpNames: HeOrSubAbrDefOutputOptionsFieldHelpNames;
};

const HeAbrDefVersionCreateTabWithTemplate: FC<Props> = ({
  heAbrDefGeneralSettingsValues,
  setHeAbrDefGeneralSettingsValues,
  heAbrDefTemplateValues,
  onTemplateChange,
  onSubmit,
  isSubmitting,
  onBack,
  fieldHelpNames,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: (
        <HeOrSubGeneralSettingsForm
          values={heAbrDefGeneralSettingsValues}
          onSubmit={setHeAbrDefGeneralSettingsValues}
          fieldHelpNames={fieldHelpNames}
        />
      ),
    },
    {
      key: '2',
      label: 'Texte',
      children: (
        <TemplateHeAbrechnung
          values={heAbrDefTemplateValues}
          onTemplateChange={onTemplateChange}
          abrDefGeneralSettingsValues={heAbrDefGeneralSettingsValues}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />
      <FormButtons onCancel={onBack} cancelText="Zurück" updateMode={false} isOkLoading={isSubmitting} onOk={onSubmit} okText="Speichern" />
    </>
  );
};

export default HeAbrDefVersionCreateTabWithTemplate;
