import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartMahnungErzeugenGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartMahnungErzeugenGenerierlaufMutation = {
  actionRestartMahnungErzeugenGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateMahnungErzeugenGenerierlaufMutationVariables = Types.Exact<{
  input: Types.MahnungErzeugenGenerierlaufInput;
}>;

export type CreateMahnungErzeugenGenerierlaufMutation = {
  createMahnungErzeugenGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartMahnungErzeugenGenerierlaufDocument = gql`
  mutation RestartMahnungErzeugenGenerierlauf($generierlaufId: ID!) {
    actionRestartMahnungErzeugenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartMahnungErzeugenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartMahnungErzeugenGenerierlaufMutation, RestartMahnungErzeugenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartMahnungErzeugenGenerierlaufMutation, RestartMahnungErzeugenGenerierlaufMutationVariables>(
    RestartMahnungErzeugenGenerierlaufDocument,
    options
  );
}
export type RestartMahnungErzeugenGenerierlaufMutationHookResult = ReturnType<typeof useRestartMahnungErzeugenGenerierlaufMutation>;
export type RestartMahnungErzeugenGenerierlaufMutationResult = Apollo.MutationResult<RestartMahnungErzeugenGenerierlaufMutation>;
export type RestartMahnungErzeugenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartMahnungErzeugenGenerierlaufMutation,
  RestartMahnungErzeugenGenerierlaufMutationVariables
>;
export const CreateMahnungErzeugenGenerierlaufDocument = gql`
  mutation CreateMahnungErzeugenGenerierlauf($input: MahnungErzeugenGenerierlaufInput!) {
    createMahnungErzeugenGenerierlauf(input: $input) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateMahnungErzeugenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMahnungErzeugenGenerierlaufMutation, CreateMahnungErzeugenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMahnungErzeugenGenerierlaufMutation, CreateMahnungErzeugenGenerierlaufMutationVariables>(
    CreateMahnungErzeugenGenerierlaufDocument,
    options
  );
}
export type CreateMahnungErzeugenGenerierlaufMutationHookResult = ReturnType<typeof useCreateMahnungErzeugenGenerierlaufMutation>;
export type CreateMahnungErzeugenGenerierlaufMutationResult = Apollo.MutationResult<CreateMahnungErzeugenGenerierlaufMutation>;
export type CreateMahnungErzeugenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateMahnungErzeugenGenerierlaufMutation,
  CreateMahnungErzeugenGenerierlaufMutationVariables
>;
