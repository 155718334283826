import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { TopAbrechnung } from '../../topAbrechnung-types';
import TAAbsender from './TAAbsender';
import { TopAbrechnungSelectableSection } from '../../TopAbrechnungTemplate';
import TADeckblattAbrechnungskreisBlock from './AbrechnungskreisBlock/TADeckblattAbrechnungskreisBlock';
import Einleitungstext from '../../../shared/Einleitungstext';

type TADeckblattBlockProps = {
  data: TopAbrechnung;
  isInEditMode?: boolean;
  selectedTextbaustein?: TopAbrechnungSelectableSection;
};

const TADeckblattBlock: FC<TADeckblattBlockProps> = ({ data, isInEditMode, selectedTextbaustein }) => (
  <View>
    {/* Absender */}
    <TAAbsender data={data} />

    {/* Einleitungstext with Textbaustein */}
    <Einleitungstext text={data.deckblatt.einleitungsText} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />

    {/* Abrechnungskreis summary block */}
    <TADeckblattAbrechnungskreisBlock data={data} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
  </View>
);

export default TADeckblattBlock;
