import React, { FC } from 'react';
import { FormikProps } from 'formik';
import {
  infoMailDeliverySendVerarbeitungEntryFiltersFormFields,
  InfoMailDeliverySendVerarbeitungEntryFiltersFormValues,
} from './ListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import GenerierlaufEntryExitCodeListSelect from '../../../shared/Filters/GenerierlaufEntryExitCodeListSelect';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import GenerierlaufRecipientEmailAddressFormSelect from '../../../shared/Filters/App/GenerierlaufRecipientEmailAddressFormSelect';
import { AppGenerierlaufType } from '../../../../../types';
import GenerierlaufRecipientFormSelect from '../../../shared/Filters/App/GenerierlaufRecipientFormSelect';
import GenerierlaufVertragspartnerFormSelect from '../../../shared/Filters/App/GenerierlaufVertragspartnerFormSelect';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/App/GenerierlaufObjektFormSelect';

type ListingFiltersProps = {
  formikProps: FormikProps<InfoMailDeliverySendVerarbeitungEntryFiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<ListingFiltersProps> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={infoMailDeliverySendVerarbeitungEntryFiltersFormFields.vertragspartnerId} label="Vertragspartner">
            <GenerierlaufVertragspartnerFormSelect
              name={infoMailDeliverySendVerarbeitungEntryFiltersFormFields.vertragspartnerId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.InfoMailDeliverySend}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={infoMailDeliverySendVerarbeitungEntryFiltersFormFields.objektId} label="Objekt">
            <GenerierlaufObjektFormSelect
              name={infoMailDeliverySendVerarbeitungEntryFiltersFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.InfoMailDeliverySend}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={infoMailDeliverySendVerarbeitungEntryFiltersFormFields.exitCodeList} label="Status">
            <GenerierlaufEntryExitCodeListSelect
              name={infoMailDeliverySendVerarbeitungEntryFiltersFormFields.exitCodeList}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={infoMailDeliverySendVerarbeitungEntryFiltersFormFields.recipientId} label="Empfänger">
            <GenerierlaufRecipientFormSelect
              name={infoMailDeliverySendVerarbeitungEntryFiltersFormFields.recipientId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.InfoMailDeliverySend}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={infoMailDeliverySendVerarbeitungEntryFiltersFormFields.emailAddress} label="An">
            <GenerierlaufRecipientEmailAddressFormSelect
              name={infoMailDeliverySendVerarbeitungEntryFiltersFormFields.emailAddress}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.InfoMailDeliverySend}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
