import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { OrderGenerierlaufType } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useFilterGenLaufOrderObjektListQuery } from '../../../gql/GenerierlaufFilter/OrderGenerierlaufFilterQueries.types';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: OrderGenerierlaufType;
  rechnungsAusstellerId?: string;
  maxTextLength?: number;
} & SelectProps;

const GenerierlaufObjektFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, maxTextLength, rechnungsAusstellerId, ...restProps }) => {
  const { data, loading } = useFilterGenLaufOrderObjektListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
      rechnungsAusstellerId,
    },
  });

  const list = data?.getFilterGenLaufOrderObjektList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Objekt auswählen"
      {...restProps}
    >
      {list.map((objekt) => (
        <Select.Option key={objekt.objektId} value={objekt.objektId}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? objekt.kurzBezeichnung.length} text={objekt.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufObjektFormSelect;
