import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum MahnungVerarbeitungQueryParamKey {
  OBJEKT_ID = 'objektId',
  EXIT_CODE_LIST = 'exitCodeList',
  RECHNUNGSAUSSTELLER_ID = 'rechnungsausstellerId',
}

export type TMahnungVerarbeitungQueryParams = QueryParams<{
  objektId: string;
  exitCodeList: GenerierlaufEntryExitCode[];
  rechnungsausstellerId: string;
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: TMahnungVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  navigate(
    generatePathToMahnungVerarbeitungPage(
      verarbeitungType,
      generierlaufId,
      filters.objektId,
      filters.exitCodeList,
      filters.rechnungsausstellerId,
      paginationParams
    )
  );

const generatePathToMahnungVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  objektId?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  rechnungsausstellerId?: string,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [MahnungVerarbeitungQueryParamKey.OBJEKT_ID]: objektId,
    [MahnungVerarbeitungQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    [MahnungVerarbeitungQueryParamKey.RECHNUNGSAUSSTELLER_ID]: rechnungsausstellerId,
    ...paginationParams,
  })}`;
