import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EbicsVersionListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type EbicsVersionListQuery = {
  getEbicsVersionList: {
    data: Array<{ text: string; value: Types.EbicsVersion }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const EbicsVersionListDocument = gql`
  query EbicsVersionList {
    getEbicsVersionList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useEbicsVersionListQuery(baseOptions?: Apollo.QueryHookOptions<EbicsVersionListQuery, EbicsVersionListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EbicsVersionListQuery, EbicsVersionListQueryVariables>(EbicsVersionListDocument, options);
}
export function useEbicsVersionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EbicsVersionListQuery, EbicsVersionListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EbicsVersionListQuery, EbicsVersionListQueryVariables>(EbicsVersionListDocument, options);
}
export function useEbicsVersionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EbicsVersionListQuery, EbicsVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EbicsVersionListQuery, EbicsVersionListQueryVariables>(EbicsVersionListDocument, options);
}
export type EbicsVersionListQueryHookResult = ReturnType<typeof useEbicsVersionListQuery>;
export type EbicsVersionListLazyQueryHookResult = ReturnType<typeof useEbicsVersionListLazyQuery>;
export type EbicsVersionListSuspenseQueryHookResult = ReturnType<typeof useEbicsVersionListSuspenseQuery>;
export type EbicsVersionListQueryResult = Apollo.QueryResult<EbicsVersionListQuery, EbicsVersionListQueryVariables>;
