import React from 'react';
import { Link } from 'react-router-dom';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import { BudgetingBase, ObjektFinancialPlanGenerierlaufEntry } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { pathsToBudgetingDetailsPage } from '../../../../Budgeting/budgetingUriPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import { generatePathToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import { EuroAmount } from '../../../../../components/Number';
import { isNotNil } from '../../../../../helpers/assertionHelper';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';

const objektFinancialPlanVerarbeitungEntryTableColumns = (
  budgeting: BudgetingBase
): TableWithColSelectorColumnProps<ObjektFinancialPlanGenerierlaufEntry>[] => {
  return [
    {
      title: 'Status',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
      render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
    },
    {
      title: 'Budgetierung',
      defaultSelected: true,
      render: () => (
        <DataWithShortenedText text={budgeting.bezeichnung} maxTextLength={25}>
          {(shortenedText) => (
            <Link to={pathsToBudgetingDetailsPage(budgeting.objekt.objektId, budgeting.objekt.objektId).detailsTab} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'Rechnungsaussteller',
      defaultSelected: true,
      render: () => (
        <DataWithShortenedText text={budgeting.objekt.rechnungsAussteller.kurzBezeichnung} maxTextLength={25}>
          {(shortenedText) => (
            <Link to={generatePathToRechtstraegerDetailsPage(budgeting.objekt.rechnungsAussteller.rechtstraegerId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'Objekt',
      defaultSelected: true,
      render: (_) => (
        <DataWithShortenedText text={budgeting.objekt.kurzBezeichnung} maxTextLength={25}>
          {(shortenedText) => (
            <Link to={generatePathToObjektDetailsPage(budgeting.objekt.objektId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'Jahresbudget Summe',
      align: 'right',
      defaultSelected: true,
      render: (_) =>
        isNotNil(budgeting.budgetSumNextYear) ? <EuroAmount value={budgeting.budgetSumNextYear} /> : <TextForEmptyValue textToShow="minus" />,
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (_) => (
        <Link to={pathsToBudgetingDetailsPage(budgeting.objekt.objektId, budgeting.budgetingId).resultsTab} target="_blank">
          Details
        </Link>
      ),
    },
  ];
};
export default objektFinancialPlanVerarbeitungEntryTableColumns;
