import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import OffenePostenListing from '../../features/OffenePosten/OffenePostenListing';

const OffenePostenPage = () => {
  return (
    <>
      <Helmet>
        <title>Offene Posten</title>
      </Helmet>
      <PageLayout>
        <OffenePostenListing />
      </PageLayout>
    </>
  );
};

export default OffenePostenPage;
