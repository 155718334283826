import * as Yup from 'yup';
import { kommentarValidationSchema } from '../../../../../components/Timeline/Form/timelineFormKommentarValidationSchema';
import { nutzwertVersionFormFields } from './nutzwertVersionFormMapper';
import { formattedDecimalType } from '../../../../../components/Input-antd';
import { entityIsRequired } from '../../../../../components/message/validationMsg';

export const nutzwertVersionFormValidationSchema = Yup.object().shape({
  [nutzwertVersionFormFields.kommentar]: kommentarValidationSchema,
  [nutzwertVersionFormFields.nutzwert]: formattedDecimalType().required(entityIsRequired('Nutzwert')),
});
