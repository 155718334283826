import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import CallLogIncomingPage from './CallLogIncomingPage';

export type CallLogIncomingPageRouteParams = {
  phoneNumber: string;
};

export const URI_CALL_LOG_INCOMING_PAGE = `/dashboard/call-log-incoming/:phoneNumber`;

const callLogIncomingPageRoute = (
  <Route
    path={`${URI_CALL_LOG_INCOMING_PAGE}/*`}
    element={<AuthRoute element={<CallLogIncomingPage />} hasPermissionFn={isActiveForSomeFirmendaten} />}
  />
);

export default callLogIncomingPageRoute;
