import { isEqual } from 'lodash';
import { BookingSuggestionFormValues, BSBuchungsanweisungFormValues, OffenePostenForClearingSuggestion } from './buchungsmaskeFormMapper';
import { isKontoTypePersonenkonto } from '../../../Kontenplan/Form/kontoFormHelpers';
import { BookingSuggestionBuchungszeile } from '../../../../types';

export const getBuchungszeileWithPersonenkontoFromBookingSuggestion = (
  buchungszeileList: BookingSuggestionBuchungszeile[]
): BookingSuggestionBuchungszeile | null => {
  const buchungszeileListWithPersonenkonto = buchungszeileList.filter((bz) => isKontoTypePersonenkonto(bz.konto?.type.value));
  return buchungszeileListWithPersonenkonto.length ? buchungszeileListWithPersonenkonto[0] : null;
};

export const isChosenKontoDifferentFromKontoFromBookingSuggestion = (
  chosenKontoId: string,
  bookingSuggestionBuchungszeileList: BookingSuggestionBuchungszeile[]
): boolean => {
  const buchungszeile = getBuchungszeileWithPersonenkontoFromBookingSuggestion(bookingSuggestionBuchungszeileList);
  const kontoId = buchungszeile?.konto?.kontoId;
  return chosenKontoId !== kontoId;
};

export const getOffenePostenIndexFromMainOPList = (formikValues: BookingSuggestionFormValues, offenePosten: OffenePostenForClearingSuggestion) =>
  formikValues.offenePostenForClearingSuggestionList.findIndex((op) => op.offenePostenId === offenePosten.offenePostenId);

export const isBuchungsanweisungDirty = (
  initialBuchungsanweisung: BSBuchungsanweisungFormValues,
  currentBuchungsanweisung: BSBuchungsanweisungFormValues
) => !isEqual(initialBuchungsanweisung, currentBuchungsanweisung);
