import React from 'react';
import { ThunderboltOutlined, ToolOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { DEVIATIONS_MENU_LIST_ITEM_TITLE } from '../../components/Deviation/deviationHelpers';
import { generatePathToUstVomAufwandDetailsPage } from '../../features/UstVomAufwand/ustVomAufwandUriPaths';
import { UstVomAufwand, UstVomAufwandStatus } from '../../types';
import UstVomAufwandDeviationList from '../../features/UstVomAufwand/Deviation/UstVomAufwandDeviationList';
import UstVomAufwandDetailsTab from '../../features/UstVomAufwand/UstVomAufwandDetailsListing/UstVomAufwandDetailsTab';

const menuListUstVomAufwand = (objektId: string, ustVomAufwand: UstVomAufwand, refetch: () => void): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Berechnung',
      icon: <ToolOutlined />,
      uri: `${generatePathToUstVomAufwandDetailsPage(objektId, ustVomAufwand.ustVomAufwandId)}/overview`,
      content: () => <UstVomAufwandDetailsTab ustVomAufwand={ustVomAufwand} />,
    },
    {
      state: ustVomAufwand.status.value === UstVomAufwandStatus.Invalid ? 'error' : undefined,
      title: DEVIATIONS_MENU_LIST_ITEM_TITLE,
      icon: <ThunderboltOutlined />,
      uri: `${generatePathToUstVomAufwandDetailsPage(objektId, ustVomAufwand.ustVomAufwandId)}/discrepancies`,
      content: () => <UstVomAufwandDeviationList objektId={objektId} ustVomAufwand={ustVomAufwand} onAction={refetch} />,
    },
  ],
});

export default menuListUstVomAufwand;
