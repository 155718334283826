import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { FirmendatenBaseFieldsFragmentDoc } from '../../../Firmendaten/gql/FirmendatenFragments.types';
export type FirmendatenInitializerGenerierlaufFieldsFragment = {
  countFehler: number;
  countVerarbeitet: number;
  countNichtVerarbeitet: number;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  generierDatum: string;
  generierlaufId: string;
  errorList: Array<{ message: string; type: string }>;
  firmendaten: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firmendatenId: string;
    kundenSystemId: string;
    kurzBezeichnung: string;
    name1: string;
    name2?: string | null;
    name3?: string | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
  warningList: Array<{ message: string; type: string }>;
};

export const FirmendatenInitializerGenerierlaufFieldsFragmentDoc = gql`
  fragment FirmendatenInitializerGenerierlaufFields on FirmendatenInitializerGenerierlauf {
    countFehler
    countVerarbeitet
    countNichtVerarbeitet
    createTs
    createdBy
    createdByMitarbeiterId
    errorList {
      message
      type
    }
    firmendaten {
      ...FirmendatenBaseFields
    }
    generierDatum
    generierlaufId
    status {
      description
      value
      text
    }
    warningList {
      message
      type
    }
  }
  ${FirmendatenBaseFieldsFragmentDoc}
`;
