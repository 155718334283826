import { Modal } from 'antd';

export const showConfirmMarkAsNoted = (markAsNoted: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie alle Zahlungen als gesehen markieren?',
    okText: 'Markieren',
    cancelText: 'Abbrechen',
    onOk() {
      return markAsNoted();
    },
  });
};

export const showConfirmReleaseAll = (releaseAll: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie alle Zahlungen freigeben?',
    okText: 'Freigeben',
    cancelText: 'Abbrechen',
    onOk() {
      return releaseAll();
    },
  });
};
