import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'antd';
import { useCreateMahndefinitionMutation } from '../../gql/MahndefinitionMutations.types';
import { showSuccessMsgCreate } from '../../../../components/message';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import MahndefinitionCreateParamForm from '../../ParameterForm/Create/MahndefinitionCreateParamForm';
import {
  mapTextbausteinListToFormValues,
  TextbausteinFormValues,
  TextbausteinModel,
} from '../../../../components/Template/PDFTemplates/templateMapper';
import { MahndefinitionParamFormValues, mapFormValuesToMahndefinition, mapMahndefinitionToParamFormValues } from '../../mahndefinitionFormMapper';
import MahndefinitionCreateTemplateForm from './MahndefinitionCreateTemplateForm';

type Props = {
  mahndefinitionTextbausteinList: TextbausteinModel[];
};
const MahndefinitionCreateForm: FC<Props> = ({ mahndefinitionTextbausteinList }) => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [parameterFormValues, setParameterFormValues] = useState<MahndefinitionParamFormValues>(mapMahndefinitionToParamFormValues());

  const [mahnstufe1TemplateValues, setMahnstufe1TemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(mahndefinitionTextbausteinList)
  );
  const [mahnstufe2TemplateValues, setMahnstufe2TemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(mahndefinitionTextbausteinList)
  );
  const [mahnstufe3TemplateValues, setMahnstufe3TemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(mahndefinitionTextbausteinList)
  );
  const [mahnstufe4TemplateValues, setMahnstufe4TemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(mahndefinitionTextbausteinList)
  );

  const [runCreate] = useCreateMahndefinitionMutation({
    onCompleted: () => {
      showSuccessMsgCreate('Mahndefinition');
      navigate(URI_SYS_SETTINGS.mahndefinitionPage);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });
  let content = (
    <MahndefinitionCreateParamForm
      setActiveStep={setActiveStep}
      parameterFormValues={parameterFormValues}
      setParameterFormValues={setParameterFormValues}
    />
  );
  if (activeStep === 1) {
    content = (
      <MahndefinitionCreateTemplateForm
        mahnstufe1TemplateValues={mahnstufe1TemplateValues}
        onMahnstufe1TemplateValuesChange={setMahnstufe1TemplateValues}
        mahnstufe2TemplateValues={mahnstufe2TemplateValues}
        onMahnstufe2TemplateValuesChange={setMahnstufe2TemplateValues}
        mahnstufe3TemplateValues={mahnstufe3TemplateValues}
        onMahnstufe3TemplateValuesChange={setMahnstufe3TemplateValues}
        mahnstufe4TemplateValues={mahnstufe4TemplateValues}
        onMahnstufe4TemplateValuesChange={setMahnstufe4TemplateValues}
        onBack={() => setActiveStep(0)}
        onSubmit={() => onSubmit()}
        isVersandEinschreiben1={parameterFormValues.mahnstufeList[0].versandEinschreiben}
        isVersandEinschreiben2={parameterFormValues.mahnstufeList[1].versandEinschreiben}
        isVersandEinschreiben3={parameterFormValues.mahnstufeList[2].versandEinschreiben}
        isVersandEinschreiben4={parameterFormValues.mahnstufeList[3].versandEinschreiben}
      />
    );
  }

  const onSubmit = () => {
    const mahndefinitionCreateInputValues = mapFormValuesToMahndefinition(
      parameterFormValues,
      mahnstufe1TemplateValues,
      mahnstufe2TemplateValues,
      mahnstufe3TemplateValues,
      mahnstufe4TemplateValues
    );
    runCreate({
      variables: {
        input: mahndefinitionCreateInputValues,
      },
    });
  };

  return (
    <>
      <Steps type="navigation" size="small" current={activeStep} className="site-navigation-steps">
        <Steps.Step title="Allgemeine Parameter" />
        <Steps.Step title="Mahnungen" />
      </Steps>
      {content}
    </>
  );
};

export default MahndefinitionCreateForm;
