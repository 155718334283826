import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ContactFieldsFragmentDoc, ContactPersonFieldsFragmentDoc } from '../../../Contact/gql/ContactFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import { PostItFragmentDoc } from '../../../Rechtstraeger/PostIt/gql/PostItFragments.types';
export type ObjektInfoFeldAuflageFragment = {
  amtlicheQuadratmeterFestsetzung: boolean;
  assanierungsgebiet: boolean;
  bebaut: boolean;
  denkmalschutz: boolean;
  gruenderzeitviertel: boolean;
  naturschutz: boolean;
  schutzzone: boolean;
  vollanwendungMRG: boolean;
  wohnzone: boolean;
};

export type ObjektInfoFeldGrundstuecksdatenFragment = {
  anzahlGeschosseUeberNiveau?: number | null;
  anzahlGeschosseUnterNiveau?: number | null;
  bauflaeche?: number | null;
  baujahr?: number | null;
  bebauteFlaeche?: number | null;
  benuetzungsbewilligung?: string | null;
  einlagezahl?: string | null;
  grundstuecksnummer: string;
  katastralgemeinde?: string | null;
};

export type ObjektInfoFeldHausbetreuungFragment = {
  beauftragungsgrund: string;
  beginn?: string | null;
  ende?: string | null;
  kuendigungsfristDauer?: number | null;
  vertragsdaten?: string | null;
  wertsicherung?: string | null;
  contactPerson?: {
    contactPersonId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    lastname: string;
    title?: string | null;
    firstname: string;
    salutation?: { text: string; value: Types.Salutation } | null;
    contactList: Array<{
      contactId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      tagList: Array<string>;
      type: string;
      wert: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
  } | null;
  kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
  kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
  hausbetreuungRechtstraeger?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
};

export type ObjektInfoFeldVersicherungFragment = {
  beginn?: string | null;
  datumSchaetzung?: string | null;
  ende?: string | null;
  erstmalsFaellig?: string | null;
  validFrom?: string | null;
  hauptDueDate?: string | null;
  kuendigungsfristDauer?: number | null;
  neubauwert?: number | null;
  polizze?: string | null;
  versicherungsart: string;
  versicherungssumme?: number | null;
  wertsicherung?: string | null;
  contactPerson?: {
    contactPersonId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    lastname: string;
    title?: string | null;
    firstname: string;
    salutation?: { text: string; value: Types.Salutation } | null;
    contactList: Array<{
      contactId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      tagList: Array<string>;
      type: string;
      wert: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
  } | null;
  firma?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    name1: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ message: string; type: string }>;
  } | null;
  kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
  kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
  makler?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    rechtstraegerId: string;
    kurzBezeichnung: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ message: string; type: string }>;
  } | null;
  sachbearbeiter?: {
    contactPersonId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firstname: string;
    lastname: string;
    tagList: Array<string>;
    title?: string | null;
    titleTrailing?: string | null;
    contactList: Array<{
      contactId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      tagList: Array<string>;
      type: string;
      wert: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    rechtstraeger?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
};

export type ObjektInfoFeldPersonFragment = {
  rolle: string;
  personRechtstraeger: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
};

export type ObjektInfoFeld_ObjektInfoFeldAuflage_Fragment = {
  __typename: 'ObjektInfoFeldAuflage';
  objektInfoFeldId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fileIdList: Array<string>;
  amtlicheQuadratmeterFestsetzung: boolean;
  assanierungsgebiet: boolean;
  bebaut: boolean;
  denkmalschutz: boolean;
  gruenderzeitviertel: boolean;
  naturschutz: boolean;
  schutzzone: boolean;
  vollanwendungMRG: boolean;
  wohnzone: boolean;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.InfoFeldStatus };
  type: { text: string; value: Types.ObjektInfoFeldType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type ObjektInfoFeld_ObjektInfoFeldGrundstuecksdaten_Fragment = {
  __typename: 'ObjektInfoFeldGrundstuecksdaten';
  objektInfoFeldId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fileIdList: Array<string>;
  anzahlGeschosseUeberNiveau?: number | null;
  anzahlGeschosseUnterNiveau?: number | null;
  bauflaeche?: number | null;
  baujahr?: number | null;
  bebauteFlaeche?: number | null;
  benuetzungsbewilligung?: string | null;
  einlagezahl?: string | null;
  grundstuecksnummer: string;
  katastralgemeinde?: string | null;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.InfoFeldStatus };
  type: { text: string; value: Types.ObjektInfoFeldType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment = {
  __typename: 'ObjektInfoFeldHausbetreuung';
  objektInfoFeldId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fileIdList: Array<string>;
  beauftragungsgrund: string;
  beginn?: string | null;
  ende?: string | null;
  kuendigungsfristDauer?: number | null;
  vertragsdaten?: string | null;
  wertsicherung?: string | null;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.InfoFeldStatus };
  type: { text: string; value: Types.ObjektInfoFeldType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  contactPerson?: {
    contactPersonId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    lastname: string;
    title?: string | null;
    firstname: string;
    salutation?: { text: string; value: Types.Salutation } | null;
    contactList: Array<{
      contactId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      tagList: Array<string>;
      type: string;
      wert: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
  } | null;
  kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
  kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
  hausbetreuungRechtstraeger?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
};

export type ObjektInfoFeld_ObjektInfoFeldPerson_Fragment = {
  __typename: 'ObjektInfoFeldPerson';
  objektInfoFeldId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fileIdList: Array<string>;
  rolle: string;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.InfoFeldStatus };
  type: { text: string; value: Types.ObjektInfoFeldType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  personRechtstraeger: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
};

export type ObjektInfoFeld_ObjektInfoFeldVersicherung_Fragment = {
  __typename: 'ObjektInfoFeldVersicherung';
  objektInfoFeldId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fileIdList: Array<string>;
  beginn?: string | null;
  datumSchaetzung?: string | null;
  ende?: string | null;
  erstmalsFaellig?: string | null;
  validFrom?: string | null;
  hauptDueDate?: string | null;
  kuendigungsfristDauer?: number | null;
  neubauwert?: number | null;
  polizze?: string | null;
  versicherungsart: string;
  versicherungssumme?: number | null;
  wertsicherung?: string | null;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.InfoFeldStatus };
  type: { text: string; value: Types.ObjektInfoFeldType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  contactPerson?: {
    contactPersonId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    lastname: string;
    title?: string | null;
    firstname: string;
    salutation?: { text: string; value: Types.Salutation } | null;
    contactList: Array<{
      contactId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      tagList: Array<string>;
      type: string;
      wert: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
  } | null;
  firma?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    name1: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ message: string; type: string }>;
  } | null;
  kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
  kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
  makler?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    rechtstraegerId: string;
    kurzBezeichnung: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ message: string; type: string }>;
  } | null;
  sachbearbeiter?: {
    contactPersonId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firstname: string;
    lastname: string;
    tagList: Array<string>;
    title?: string | null;
    titleTrailing?: string | null;
    contactList: Array<{
      contactId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      tagList: Array<string>;
      type: string;
      wert: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    rechtstraeger?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
};

export type ObjektInfoFeldFragment =
  | ObjektInfoFeld_ObjektInfoFeldAuflage_Fragment
  | ObjektInfoFeld_ObjektInfoFeldGrundstuecksdaten_Fragment
  | ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment
  | ObjektInfoFeld_ObjektInfoFeldPerson_Fragment
  | ObjektInfoFeld_ObjektInfoFeldVersicherung_Fragment;

export const ObjektInfoFeldAuflageFragmentDoc = gql`
  fragment ObjektInfoFeldAuflage on ObjektInfoFeldAuflage {
    amtlicheQuadratmeterFestsetzung
    assanierungsgebiet
    bebaut
    denkmalschutz
    gruenderzeitviertel
    naturschutz
    schutzzone
    vollanwendungMRG
    wohnzone
  }
`;
export const ObjektInfoFeldGrundstuecksdatenFragmentDoc = gql`
  fragment ObjektInfoFeldGrundstuecksdaten on ObjektInfoFeldGrundstuecksdaten {
    anzahlGeschosseUeberNiveau
    anzahlGeschosseUnterNiveau
    bauflaeche
    baujahr
    bebauteFlaeche
    benuetzungsbewilligung
    einlagezahl
    grundstuecksnummer
    katastralgemeinde
  }
`;
export const ObjektInfoFeldHausbetreuungFragmentDoc = gql`
  fragment ObjektInfoFeldHausbetreuung on ObjektInfoFeldHausbetreuung {
    contactPerson {
      salutation {
        text
        value
      }
      contactPersonId
      createTs
      createdBy
      createdByMitarbeiterId
      contactList {
        ...ContactFields
      }
      lastname
      title
      firstname
      warningList {
        message
        type
      }
    }
    beauftragungsgrund
    beginn
    ende
    kuendigungsfrist {
      text
      value
    }
    kuendigungsfristDauer
    kuendigungsfristJeweilsZum {
      text
      value
    }
    hausbetreuungRechtstraeger: rechtstraeger {
      ...RechtstraegerBaseFields
    }
    vertragsdaten
    wertsicherung
  }
  ${ContactFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export const ObjektInfoFeldVersicherungFragmentDoc = gql`
  fragment ObjektInfoFeldVersicherung on ObjektInfoFeldVersicherung {
    contactPerson {
      salutation {
        text
        value
      }
      contactPersonId
      createTs
      createdBy
      createdByMitarbeiterId
      contactList {
        ...ContactFields
      }
      lastname
      title
      firstname
      warningList {
        message
        type
      }
    }
    beginn
    datumSchaetzung
    ende
    erstmalsFaellig
    firma {
      createTs
      createdBy
      createdByMitarbeiterId
      kurzBezeichnung
      name1
      rechtstraegerId
      status {
        text
        value
      }
      type {
        text
        value
      }
      warningList {
        message
        type
      }
    }
    validFrom
    hauptDueDate
    kuendigungsfrist {
      text
      value
    }
    kuendigungsfristDauer
    kuendigungsfristJeweilsZum {
      text
      value
    }
    makler {
      createTs
      createdBy
      createdByMitarbeiterId
      rechtstraegerId
      kurzBezeichnung
      status {
        text
        value
      }
      type {
        text
        value
      }
      warningList {
        message
        type
      }
    }
    neubauwert
    polizze
    sachbearbeiter {
      ...ContactPersonFields
    }
    versicherungsart
    versicherungssumme
    wertsicherung
    zahlungsperiode {
      text
      value
    }
  }
  ${ContactFieldsFragmentDoc}
  ${ContactPersonFieldsFragmentDoc}
`;
export const ObjektInfoFeldPersonFragmentDoc = gql`
  fragment ObjektInfoFeldPerson on ObjektInfoFeldPerson {
    rolle
    personRechtstraeger: rechtstraeger {
      ...RechtstraegerBaseFields
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export const ObjektInfoFeldFragmentDoc = gql`
  fragment ObjektInfoFeld on AbstractObjektInfoFeld {
    __typename
    objektInfoFeldId
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    fileIdList
    postIt {
      ...PostIt
    }
    status {
      text
      value
    }
    type {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
    ... on ObjektInfoFeldAuflage {
      ...ObjektInfoFeldAuflage
    }
    ... on ObjektInfoFeldGrundstuecksdaten {
      ...ObjektInfoFeldGrundstuecksdaten
    }
    ... on ObjektInfoFeldHausbetreuung {
      ...ObjektInfoFeldHausbetreuung
    }
    ... on ObjektInfoFeldVersicherung {
      ...ObjektInfoFeldVersicherung
    }
    ... on ObjektInfoFeldPerson {
      ...ObjektInfoFeldPerson
    }
  }
  ${PostItFragmentDoc}
  ${ObjektInfoFeldAuflageFragmentDoc}
  ${ObjektInfoFeldGrundstuecksdatenFragmentDoc}
  ${ObjektInfoFeldHausbetreuungFragmentDoc}
  ${ObjektInfoFeldVersicherungFragmentDoc}
  ${ObjektInfoFeldPersonFragmentDoc}
`;
