import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { FormikProps } from 'formik';
import IndexSeriesSelect from '../../../../../../../../IndexSeries/shared/IndexSeriesSelect';
import { IndexType } from '../../../../../../../../../types';
import DrawerRow from '../../../../../../../../../components/Drawer/DrawerRow';
import { indAgreeIndexThresholdFormFields, IndexationAgreementIndexThresholdFormValues } from './indexThresholdFormMapper';
import FormattedDecimalFormInput from '../../../../../../../../../components/Input-antd/FormattedDecimalFormInput';
import MonthYearFormItem from '../shared/MonthYearFormItem';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  formikProps: FormikProps<IndexationAgreementIndexThresholdFormValues>;
  onIndexSeriesChange: (indexSeriesId: string) => void;
  fieldHelpNames?: {
    validFrom?: string | null;
    indexSeriesId?: string | null;
    effectiveAfterMonths?: string | null;
  };
};

const IndexBlock: FC<Props> = ({ formikProps, onIndexSeriesChange, fieldHelpNames }) => {
  const content = (
    <>
      <Row gutter={8}>
        <Col span={24}>
          <MonthYearFormItem
            name={indAgreeIndexThresholdFormFields.validFrom}
            label="Gültig ab"
            placeholder="Gültig ab auswählen"
            fieldHelp={fieldHelpNames?.validFrom}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemWithFieldHelp name={indAgreeIndexThresholdFormFields.indexSeriesId} label="Indexreihe" fieldHelp={fieldHelpNames?.indexSeriesId}>
            <IndexSeriesSelect
              name={indAgreeIndexThresholdFormFields.indexSeriesId}
              indexType={IndexType.Index}
              onSelectChange={onIndexSeriesChange}
            />
          </FormItemWithFieldHelp>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemWithFieldHelp
            name={indAgreeIndexThresholdFormFields.effectiveAfterMonths}
            label="Wirksam nach"
            fieldHelp={fieldHelpNames?.effectiveAfterMonths}
          >
            <FormattedDecimalFormInput
              name={indAgreeIndexThresholdFormFields.effectiveAfterMonths}
              id={indAgreeIndexThresholdFormFields.effectiveAfterMonths}
              size="small"
              addonAfter={formikProps.values.effectiveAfterMonths === 1 ? 'Monat' : 'Monate'}
              min={0}
              style={{ width: '100%' }}
              fractionDigits={0}
            />
          </FormItemWithFieldHelp>
        </Col>
      </Row>
    </>
  );

  return <DrawerRow rowTitle="Index" content={content} />;
};

export default IndexBlock;
