import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { RepFondsAbrechnungDetails } from '../../../topAbrechnung-types';
import TARepFondsAbrechnungBlockTitle from '../TARepFondsAbrechnungBlockTitle';
import TARepFondsAbrEinnahmenBlock from './EinnahmenBlock/TARepFondsAbrEinnahmenBlock';
import TARepFondsAbrAufwandBlock from './AufwandBlock/TARepFondsAbrAufwandBlock';

const TARepFondsAbrechnungDetailBlock: FC<{ details: RepFondsAbrechnungDetails }> = ({ details }) => (
  <View>
    {/* DetailBlock Title */}
    <TARepFondsAbrechnungBlockTitle titleText={details.repFondsAbrechnungDetailText} />

    {/* Aufwand block */}
    <TARepFondsAbrAufwandBlock details={details} />

    {/* Einnahmen block */}
    <TARepFondsAbrEinnahmenBlock details={details} />
  </View>
);

export default TARepFondsAbrechnungDetailBlock;
