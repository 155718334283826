import * as Types from '../../../types';

import { gql } from '@apollo/client';
import {
  BestandseinheitFieldsFragmentDoc,
  BestandseinheitBaseFieldsFragmentDoc,
  BestandseinheitListEntryFieldsFragmentDoc,
} from './BestandseinheitFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import { RechtstraegerFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BestandseinheitQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BestandseinheitQuery = {
  getBestandseinheit: {
    data: {
      aktivSeit?: string | null;
      bauteil?: string | null;
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      geschoss?: string | null;
      inaktivSeit?: string | null;
      nutzflaeche?: number | null;
      nutzwert?: number | null;
      stiege?: string | null;
      tagList: Array<string>;
      address: {
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
      optionSteuerpflicht?: {
        beOptionSteuerpflichtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        optionSteuerpflicht: boolean;
        validFrom: string;
        historicizedList?: Array<{
          beOptionSteuerpflichtId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          optionSteuerpflicht: boolean;
          validFrom: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BestandseinheitChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
}>;

export type BestandseinheitChangeHistoryListQuery = {
  getBestandseinheitChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type BestandseinheitListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BestandseinheitListQuery = {
  getBestandseinheitList: {
    data: Array<{
      aktivSeit?: string | null;
      bauteil?: string | null;
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      geschoss?: string | null;
      inaktivSeit?: string | null;
      position: number;
      stiege?: string | null;
      vertragspartnerId?: string | null;
      vertragspartnerKurzBezeichnung?: string | null;
      optionSteuerpflicht?: {
        beOptionSteuerpflichtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        optionSteuerpflicht: boolean;
        validFrom: string;
        historicizedList?: Array<{
          beOptionSteuerpflichtId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          optionSteuerpflicht: boolean;
          validFrom: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
      status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
      vertragspartnerStatus?: { text: string; value: Types.RechtstraegerStatus } | null;
      vertragspartnerType?: { text: string; value: Types.RechtstraegerType } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BestandseinheitBezeichnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  showObjekt?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BestandseinheitBezeichnungQuery = {
  getBestandseinheitBezeichnung: {
    data?: string | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BestandseinheitNutzflaecheByDateQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  date: Types.Scalars['String']['input'];
}>;

export type BestandseinheitNutzflaecheByDateQuery = {
  getBestandseinheitNutzflaecheByDate: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BestandseinheitNutzwertByDateQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  date: Types.Scalars['String']['input'];
}>;

export type BestandseinheitNutzwertByDateQuery = {
  getBestandseinheitNutzwertByDate: {
    data: number;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BestandseinheitNutzungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  date?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type BestandseinheitNutzungQuery = {
  getBestandseinheitNutzung: {
    data: { nutzflaeche?: number | null; nutzflaecheAnteil: number; nutzwert?: number | null; nutzwertAnteil: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BeVertragListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
}>;

export type BeVertragListQuery = {
  getBeVertragList: {
    data: Array<{
      aktivIn?: number | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      sumBrutto: number;
      sumNetto: number;
      sumUst: number;
      vertragId: string;
      vertragKurzBezeichnung: string;
      vertragsBeginn: string;
      aktivInUnit?: { text: string; value: string } | null;
      status: { description?: string | null; text: string; value: Types.VertragStatus };
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BestandseinheitDocument = gql`
  query Bestandseinheit($objektId: ID!, $bestandseinheitId: ID!, $withDetails: Boolean) {
    getBestandseinheit(objektId: $objektId, bestandseinheitId: $bestandseinheitId, withDetails: $withDetails) {
      data {
        ...BestandseinheitFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BestandseinheitFieldsFragmentDoc}
`;
export function useBestandseinheitQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitQuery, BestandseinheitQueryVariables> &
    ({ variables: BestandseinheitQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitQuery, BestandseinheitQueryVariables>(BestandseinheitDocument, options);
}
export function useBestandseinheitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitQuery, BestandseinheitQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitQuery, BestandseinheitQueryVariables>(BestandseinheitDocument, options);
}
export function useBestandseinheitSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BestandseinheitQuery, BestandseinheitQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitQuery, BestandseinheitQueryVariables>(BestandseinheitDocument, options);
}
export type BestandseinheitQueryHookResult = ReturnType<typeof useBestandseinheitQuery>;
export type BestandseinheitLazyQueryHookResult = ReturnType<typeof useBestandseinheitLazyQuery>;
export type BestandseinheitSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitSuspenseQuery>;
export type BestandseinheitQueryResult = Apollo.QueryResult<BestandseinheitQuery, BestandseinheitQueryVariables>;
export const BestandseinheitChangeHistoryListDocument = gql`
  query BestandseinheitChangeHistoryList($objektId: ID!, $bestandseinheitId: ID!) {
    getBestandseinheitChangeHistoryList(objektId: $objektId, bestandseinheitId: $bestandseinheitId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBestandseinheitChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitChangeHistoryListQuery, BestandseinheitChangeHistoryListQueryVariables> &
    ({ variables: BestandseinheitChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitChangeHistoryListQuery, BestandseinheitChangeHistoryListQueryVariables>(
    BestandseinheitChangeHistoryListDocument,
    options
  );
}
export function useBestandseinheitChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitChangeHistoryListQuery, BestandseinheitChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitChangeHistoryListQuery, BestandseinheitChangeHistoryListQueryVariables>(
    BestandseinheitChangeHistoryListDocument,
    options
  );
}
export function useBestandseinheitChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BestandseinheitChangeHistoryListQuery, BestandseinheitChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitChangeHistoryListQuery, BestandseinheitChangeHistoryListQueryVariables>(
    BestandseinheitChangeHistoryListDocument,
    options
  );
}
export type BestandseinheitChangeHistoryListQueryHookResult = ReturnType<typeof useBestandseinheitChangeHistoryListQuery>;
export type BestandseinheitChangeHistoryListLazyQueryHookResult = ReturnType<typeof useBestandseinheitChangeHistoryListLazyQuery>;
export type BestandseinheitChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitChangeHistoryListSuspenseQuery>;
export type BestandseinheitChangeHistoryListQueryResult = Apollo.QueryResult<
  BestandseinheitChangeHistoryListQuery,
  BestandseinheitChangeHistoryListQueryVariables
>;
export const BestandseinheitListDocument = gql`
  query BestandseinheitList($objektId: ID!, $withDetails: Boolean, $includeArchiviert: Boolean) {
    getBestandseinheitList(objektId: $objektId, withDetails: $withDetails, includeArchiviert: $includeArchiviert) {
      data {
        ...BestandseinheitListEntryFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BestandseinheitListEntryFieldsFragmentDoc}
`;
export function useBestandseinheitListQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitListQuery, BestandseinheitListQueryVariables> &
    ({ variables: BestandseinheitListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitListQuery, BestandseinheitListQueryVariables>(BestandseinheitListDocument, options);
}
export function useBestandseinheitListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitListQuery, BestandseinheitListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitListQuery, BestandseinheitListQueryVariables>(BestandseinheitListDocument, options);
}
export function useBestandseinheitListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BestandseinheitListQuery, BestandseinheitListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitListQuery, BestandseinheitListQueryVariables>(BestandseinheitListDocument, options);
}
export type BestandseinheitListQueryHookResult = ReturnType<typeof useBestandseinheitListQuery>;
export type BestandseinheitListLazyQueryHookResult = ReturnType<typeof useBestandseinheitListLazyQuery>;
export type BestandseinheitListSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitListSuspenseQuery>;
export type BestandseinheitListQueryResult = Apollo.QueryResult<BestandseinheitListQuery, BestandseinheitListQueryVariables>;
export const BestandseinheitBezeichnungDocument = gql`
  query BestandseinheitBezeichnung($objektId: ID!, $bestandseinheitId: ID!, $showObjekt: Boolean) {
    getBestandseinheitBezeichnung(objektId: $objektId, bestandseinheitId: $bestandseinheitId, showObjekt: $showObjekt) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBestandseinheitBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitBezeichnungQuery, BestandseinheitBezeichnungQueryVariables> &
    ({ variables: BestandseinheitBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitBezeichnungQuery, BestandseinheitBezeichnungQueryVariables>(BestandseinheitBezeichnungDocument, options);
}
export function useBestandseinheitBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitBezeichnungQuery, BestandseinheitBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitBezeichnungQuery, BestandseinheitBezeichnungQueryVariables>(BestandseinheitBezeichnungDocument, options);
}
export function useBestandseinheitBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BestandseinheitBezeichnungQuery, BestandseinheitBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitBezeichnungQuery, BestandseinheitBezeichnungQueryVariables>(
    BestandseinheitBezeichnungDocument,
    options
  );
}
export type BestandseinheitBezeichnungQueryHookResult = ReturnType<typeof useBestandseinheitBezeichnungQuery>;
export type BestandseinheitBezeichnungLazyQueryHookResult = ReturnType<typeof useBestandseinheitBezeichnungLazyQuery>;
export type BestandseinheitBezeichnungSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitBezeichnungSuspenseQuery>;
export type BestandseinheitBezeichnungQueryResult = Apollo.QueryResult<BestandseinheitBezeichnungQuery, BestandseinheitBezeichnungQueryVariables>;
export const BestandseinheitNutzflaecheByDateDocument = gql`
  query BestandseinheitNutzflaecheByDate($objektId: ID!, $bestandseinheitId: ID!, $date: String!) {
    getBestandseinheitNutzflaecheByDate(objektId: $objektId, bestandseinheitId: $bestandseinheitId, date: $date) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBestandseinheitNutzflaecheByDateQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitNutzflaecheByDateQuery, BestandseinheitNutzflaecheByDateQueryVariables> &
    ({ variables: BestandseinheitNutzflaecheByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitNutzflaecheByDateQuery, BestandseinheitNutzflaecheByDateQueryVariables>(
    BestandseinheitNutzflaecheByDateDocument,
    options
  );
}
export function useBestandseinheitNutzflaecheByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitNutzflaecheByDateQuery, BestandseinheitNutzflaecheByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitNutzflaecheByDateQuery, BestandseinheitNutzflaecheByDateQueryVariables>(
    BestandseinheitNutzflaecheByDateDocument,
    options
  );
}
export function useBestandseinheitNutzflaecheByDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BestandseinheitNutzflaecheByDateQuery, BestandseinheitNutzflaecheByDateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitNutzflaecheByDateQuery, BestandseinheitNutzflaecheByDateQueryVariables>(
    BestandseinheitNutzflaecheByDateDocument,
    options
  );
}
export type BestandseinheitNutzflaecheByDateQueryHookResult = ReturnType<typeof useBestandseinheitNutzflaecheByDateQuery>;
export type BestandseinheitNutzflaecheByDateLazyQueryHookResult = ReturnType<typeof useBestandseinheitNutzflaecheByDateLazyQuery>;
export type BestandseinheitNutzflaecheByDateSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitNutzflaecheByDateSuspenseQuery>;
export type BestandseinheitNutzflaecheByDateQueryResult = Apollo.QueryResult<
  BestandseinheitNutzflaecheByDateQuery,
  BestandseinheitNutzflaecheByDateQueryVariables
>;
export const BestandseinheitNutzwertByDateDocument = gql`
  query BestandseinheitNutzwertByDate($objektId: ID!, $bestandseinheitId: ID!, $date: String!) {
    getBestandseinheitNutzwertByDate(objektId: $objektId, bestandseinheitId: $bestandseinheitId, date: $date) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBestandseinheitNutzwertByDateQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitNutzwertByDateQuery, BestandseinheitNutzwertByDateQueryVariables> &
    ({ variables: BestandseinheitNutzwertByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitNutzwertByDateQuery, BestandseinheitNutzwertByDateQueryVariables>(
    BestandseinheitNutzwertByDateDocument,
    options
  );
}
export function useBestandseinheitNutzwertByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitNutzwertByDateQuery, BestandseinheitNutzwertByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitNutzwertByDateQuery, BestandseinheitNutzwertByDateQueryVariables>(
    BestandseinheitNutzwertByDateDocument,
    options
  );
}
export function useBestandseinheitNutzwertByDateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BestandseinheitNutzwertByDateQuery, BestandseinheitNutzwertByDateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitNutzwertByDateQuery, BestandseinheitNutzwertByDateQueryVariables>(
    BestandseinheitNutzwertByDateDocument,
    options
  );
}
export type BestandseinheitNutzwertByDateQueryHookResult = ReturnType<typeof useBestandseinheitNutzwertByDateQuery>;
export type BestandseinheitNutzwertByDateLazyQueryHookResult = ReturnType<typeof useBestandseinheitNutzwertByDateLazyQuery>;
export type BestandseinheitNutzwertByDateSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitNutzwertByDateSuspenseQuery>;
export type BestandseinheitNutzwertByDateQueryResult = Apollo.QueryResult<
  BestandseinheitNutzwertByDateQuery,
  BestandseinheitNutzwertByDateQueryVariables
>;
export const BestandseinheitNutzungDocument = gql`
  query BestandseinheitNutzung($objektId: ID!, $bestandseinheitId: ID!, $date: String) {
    getBestandseinheitNutzung(objektId: $objektId, bestandseinheitId: $bestandseinheitId, date: $date) {
      data {
        nutzflaeche
        nutzflaecheAnteil
        nutzwert
        nutzwertAnteil
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBestandseinheitNutzungQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitNutzungQuery, BestandseinheitNutzungQueryVariables> &
    ({ variables: BestandseinheitNutzungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitNutzungQuery, BestandseinheitNutzungQueryVariables>(BestandseinheitNutzungDocument, options);
}
export function useBestandseinheitNutzungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitNutzungQuery, BestandseinheitNutzungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitNutzungQuery, BestandseinheitNutzungQueryVariables>(BestandseinheitNutzungDocument, options);
}
export function useBestandseinheitNutzungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BestandseinheitNutzungQuery, BestandseinheitNutzungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitNutzungQuery, BestandseinheitNutzungQueryVariables>(BestandseinheitNutzungDocument, options);
}
export type BestandseinheitNutzungQueryHookResult = ReturnType<typeof useBestandseinheitNutzungQuery>;
export type BestandseinheitNutzungLazyQueryHookResult = ReturnType<typeof useBestandseinheitNutzungLazyQuery>;
export type BestandseinheitNutzungSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitNutzungSuspenseQuery>;
export type BestandseinheitNutzungQueryResult = Apollo.QueryResult<BestandseinheitNutzungQuery, BestandseinheitNutzungQueryVariables>;
export const BeVertragListDocument = gql`
  query BeVertragList($objektId: ID!, $bestandseinheitId: ID!) {
    getBeVertragList(objektId: $objektId, bestandseinheitId: $bestandseinheitId) {
      data {
        aktivIn
        aktivInUnit {
          text
          value
        }
        createTs
        createdBy
        createdByMitarbeiterId
        current
        status {
          description
          text
          value
        }
        sumBrutto
        sumNetto
        sumUst
        vertragId
        vertragKurzBezeichnung
        vertragsBeginn
        vertragsart {
          supportsExplicitVertragsEnde
          text
          value
        }
        vertragspartner {
          ...RechtstraegerFields
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerFieldsFragmentDoc}
`;
export function useBeVertragListQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragListQuery, BeVertragListQueryVariables> &
    ({ variables: BeVertragListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragListQuery, BeVertragListQueryVariables>(BeVertragListDocument, options);
}
export function useBeVertragListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeVertragListQuery, BeVertragListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragListQuery, BeVertragListQueryVariables>(BeVertragListDocument, options);
}
export function useBeVertragListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeVertragListQuery, BeVertragListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragListQuery, BeVertragListQueryVariables>(BeVertragListDocument, options);
}
export type BeVertragListQueryHookResult = ReturnType<typeof useBeVertragListQuery>;
export type BeVertragListLazyQueryHookResult = ReturnType<typeof useBeVertragListLazyQuery>;
export type BeVertragListSuspenseQueryHookResult = ReturnType<typeof useBeVertragListSuspenseQuery>;
export type BeVertragListQueryResult = Apollo.QueryResult<BeVertragListQuery, BeVertragListQueryVariables>;
