import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../types';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';

const useOnWizardListDataChangedEvents = (key: string, callback: () => Promise<unknown>, wizardId?: string | string[]) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.wizard,
        changeType: DataChangedChangeType.Create,
        params: {
          firmendatenId,
        },
      },
      notificationDescription: 'Assistent wurde gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.wizard,
        changeType: DataChangedChangeType.Update,
        params: {
          firmendatenId,
          completed: 'false',
          wizardId,
        },
      },
      notificationDescription: 'Assistent wurde gerade verändert',
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.wizard,
        changeType: DataChangedChangeType.Update,
        params: {
          firmendatenId,
          completed: 'true',
          wizardId,
        },
      },
      notificationDescription: 'Assistent wurde gerade abgeschlossen',
      key: `${key}-complete`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.wizard,
        changeType: DataChangedChangeType.Delete,
        params: {
          firmendatenId,
          wizardId,
        },
      },
      notificationDescription: 'Assistent wurde gerade gelöscht',
      key: `${key}-delete`,
      callback,
    },
  ]);
};

const useOnWizardDetailsDataChangedEvents = (key: string, callback: () => Promise<unknown>, wizardId?: string | string[]) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.wizard,
        changeType: DataChangedChangeType.Update,
        params: {
          firmendatenId,
          completed: 'false',
          wizardId,
        },
      },
      notificationDescription: 'Assistent wurde gerade verändert',
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.wizard,
        changeType: DataChangedChangeType.Update,
        params: {
          firmendatenId,
          completed: 'true',
          wizardId,
        },
      },
      notificationDescription: 'Assistent wurde gerade abgeschlossen',
      key: `${key}-complete`,
      callback,
    },
  ]);
};

export { useOnWizardListDataChangedEvents, useOnWizardDetailsDataChangedEvents };
