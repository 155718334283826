import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { generatePathToVerarbeitungCamtOnlineBankingManuallyDetailsPage } from '../../../verarbeitungApiPaths';
import { TDataCarrierCamt } from '../shared/dataCarrierTypes';
import DataCarrierIbanListColumn from '../../../../DataCarrier/Listing/shared/DataCarrierIbanListColumn';
import XmlDataCarrierTableColumn from '../../../../DataCarrier/Listing/shared/XmlDataCarrierTableColumn';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import TruncatedTextWithTooltip from '../../../../../components/Helpers/TruncatedTextWithTooltip';

const onlineBankingDataCarrierDetailsTableColumns = (firmendatenId: string): TableWithColSelectorColumnProps<TDataCarrierCamt>[] => [
  {
    title: 'Status',
    defaultSelected: true,
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Bezeichnung (des Datenträgers)',
    defaultSelected: true,
    render: (text, record) => (record.name ? <TruncatedTextWithTooltip text={record.name} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Importierte IBAN',
    defaultSelected: true,
    render: (text, record) =>
      record.ibanImportedList ? (
        <DataCarrierIbanListColumn ibanList={record.ibanImportedList} maxTextLength={20} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Nicht importierte IBAN',
    defaultSelected: true,
    render: (text, record) =>
      record.ibanNotImportedList ? (
        <DataCarrierIbanListColumn ibanList={record.ibanNotImportedList} maxTextLength={20} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'XML Datenträger',
    defaultSelected: true,
    render: (text, record) =>
      record.dataCarrierId && record.xmlFilename ? (
        <XmlDataCarrierTableColumn dataCarrierId={record.dataCarrierId} xmlFilename={record.xmlFilename} firmendatenId={firmendatenId} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Verarbeitet',
    render: (_, record) => {
      if (!record.dataCarrierDetailsList) {
        return <TextForEmptyValue textToShow="minus" />;
      }
      const firstDataCarrierDetails = record.dataCarrierDetailsList[0];
      if (firstDataCarrierDetails?.createdByGenerierlaufId) {
        return (
          <Link to={generatePathToVerarbeitungCamtOnlineBankingManuallyDetailsPage(firstDataCarrierDetails.createdByGenerierlaufId)} target="_blank">
            Importiert durch Generierlauf
          </Link>
        );
      }

      return <Typography.Text>Verarbeitet</Typography.Text>;
    },
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
];

export default onlineBankingDataCarrierDetailsTableColumns;
