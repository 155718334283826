import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import { IncomingInvoiceListEntry } from '../../../../../types';
import incomingInvoiceTableColumns from './incomingInvoiceTableColumns';
import { HandleTableSorting } from '../../../../../shared/typeHelpers';
import PartialAmountTable from '../Level2/PartialAmountTable';

type Props = {
  handleTableSorting: HandleTableSorting;
  loading: boolean;
  pagination: PaginationProps;
  onAction: () => void;
  incomingInvoiceList?: IncomingInvoiceListEntry[];
};

const IncomingInvoiceTable: FC<Props> = ({ loading, onAction, handleTableSorting, pagination, incomingInvoiceList }) => {
  return (
    <NestedTable<IncomingInvoiceListEntry>
      loading={loading}
      level={1}
      colWidth={170}
      locale={{
        emptyText: <Empty description={<span>Keine Eingangsrechnungen vorhanden</span>} />,
      }}
      dataSource={incomingInvoiceList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={incomingInvoiceTableColumns(onAction)}
      expandable={{
        rowExpandable: (record) => !!record.partialAmountList.length,
        expandedRowRender: (record) => <PartialAmountTable incomingInvoice={record} />,
      }}
      useHighlighting
      rowKey={(record) => `${record.belegId}-${record.rechnungsNummer}`}
      expandedRowKey="dataCarrierId"
    />
  );
};

export default IncomingInvoiceTable;
