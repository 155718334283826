import { FormFields } from '../../../../../../helpers/formikHelper';
import { mapTextbausteinToFormValues, TextbausteinFormValues } from '../../../../../../components/Template/PDFTemplates/templateMapper';
import { BelegnummernkreisDefinitionInput } from '../../../../../../types';
import {
  BelegnummernkreisDefinitionFieldsFragment,
  BelegnummernkreisDefinitionTemplatesFieldsFragment,
} from '../../../../../BelegnummernkreisDefinition/gql/BelegnummernkreisDefinitionFragments.types';

export type BelegnummernkreisFormValues = {
  bezeichnung: string;
  jaehrlichNeu: boolean;
  nummernteil: string;
  prefix: TextbausteinFormValues;
  postfix: TextbausteinFormValues;
};

export const belegnummernkreisFormInitialValues = (template: BelegnummernkreisDefinitionTemplatesFieldsFragment): BelegnummernkreisFormValues => ({
  bezeichnung: '',
  jaehrlichNeu: false,
  nummernteil: '',
  prefix: mapTextbausteinToFormValues(template.prefix),
  postfix: mapTextbausteinToFormValues(template.postfix),
});

export const mapBelegnummernkreisToFormValues = (
  belegnummernkreis: BelegnummernkreisDefinitionFieldsFragment,
  template: BelegnummernkreisDefinitionTemplatesFieldsFragment
): BelegnummernkreisFormValues => ({
  bezeichnung: belegnummernkreis.bezeichnung,
  jaehrlichNeu: belegnummernkreis.jaehrlichNeu,
  nummernteil: belegnummernkreis.nummernteil,
  prefix: belegnummernkreis.prefix ? mapTextbausteinToFormValues(belegnummernkreis.prefix) : mapTextbausteinToFormValues(template.prefix),
  postfix: belegnummernkreis.postfix ? mapTextbausteinToFormValues(belegnummernkreis.postfix) : mapTextbausteinToFormValues(template.postfix),
});

export const belegnummernkreisFormFields: FormFields<BelegnummernkreisFormValues> = {
  bezeichnung: 'bezeichnung',
  jaehrlichNeu: 'jaehrlichNeu',
  nummernteil: 'nummernteil',
  prefix: 'prefix',
  postfix: 'postfix',
};

export const mapFormValuesToBelegnummernkreisDefinition = (values: BelegnummernkreisFormValues): BelegnummernkreisDefinitionInput => ({
  bezeichnung: values.bezeichnung,
  jaehrlichNeu: values.jaehrlichNeu,
  nummernteil: values.nummernteil,
  prefix: values.prefix.value,
  postfix: values.postfix.value,
});
