import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TextbausteinFieldsFragmentDoc } from '../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import { AuftragsartBaseFieldsFragmentDoc, AuftragsartFieldsFragmentDoc } from './AuftragsartFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuftragsartTemplatesQueryVariables = Types.Exact<{
  auftragsartType: Types.AuftragsartType;
}>;

export type AuftragsartTemplatesQuery = {
  getAuftragsartTemplates: {
    data: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragsartListQueryVariables = Types.Exact<{
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AuftragsartListQuery = {
  getAuftragsartList: {
    data: Array<{
      auftragsartId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      showUstBlock: boolean;
      updatedByMitarbeiterId?: string | null;
      status: { text: string; value: Types.AuftragsartStatus };
      type: { text: string; value: Types.AuftragsartType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragsartListForStornoSelectQueryVariables = Types.Exact<{
  stornoForType?: Types.InputMaybe<Types.AuftragsartType>;
}>;

export type AuftragsartListForStornoSelectQuery = {
  getAuftragsartList: {
    data: Array<{ auftragsartId: string; bezeichnung: string; status: { text: string; value: Types.AuftragsartStatus } }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragsartQueryVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
}>;

export type AuftragsartQuery = {
  getAuftragsart: {
    data: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      auftragsartId: string;
      bezeichnung: string;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      showUstBlock: boolean;
      updatedByMitarbeiterId?: string | null;
      status: { text: string; value: Types.AuftragsartStatus };
      stornoAuftragsart?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        auftragsartId: string;
        bezeichnung: string;
        showUstBlock: boolean;
        status: { text: string; value: Types.AuftragsartStatus };
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.AuftragsartType };
      } | null;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.AuftragsartType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragsartKurzbezeichnungQueryVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
}>;

export type AuftragsartKurzbezeichnungQuery = {
  getAuftragsart: {
    data: { bezeichnung: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragsartTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AuftragsartTypeListQuery = {
  getAuftragsartTypeList: {
    data: Array<{ text: string; value: Types.AuftragsartType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragsartGeneralChangeHistoryListQueryVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
}>;

export type AuftragsartGeneralChangeHistoryListQuery = {
  getAuftragsartGeneralChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type AuftragsartTexteChangeHistoryListQueryVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
}>;

export type AuftragsartTexteChangeHistoryListQuery = {
  getAuftragsartTexteChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const AuftragsartTemplatesDocument = gql`
  query AuftragsartTemplates($auftragsartType: AuftragsartType!) {
    getAuftragsartTemplates(auftragsartType: $auftragsartType) {
      data {
        index
        platzhalterList {
          example
          platzhalter
        }
        textbausteinList {
          ...TextbausteinFields
        }
        type {
          text
          value
        }
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
export function useAuftragsartTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragsartTemplatesQuery, AuftragsartTemplatesQueryVariables> &
    ({ variables: AuftragsartTemplatesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartTemplatesQuery, AuftragsartTemplatesQueryVariables>(AuftragsartTemplatesDocument, options);
}
export function useAuftragsartTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartTemplatesQuery, AuftragsartTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartTemplatesQuery, AuftragsartTemplatesQueryVariables>(AuftragsartTemplatesDocument, options);
}
export function useAuftragsartTemplatesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragsartTemplatesQuery, AuftragsartTemplatesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartTemplatesQuery, AuftragsartTemplatesQueryVariables>(AuftragsartTemplatesDocument, options);
}
export type AuftragsartTemplatesQueryHookResult = ReturnType<typeof useAuftragsartTemplatesQuery>;
export type AuftragsartTemplatesLazyQueryHookResult = ReturnType<typeof useAuftragsartTemplatesLazyQuery>;
export type AuftragsartTemplatesSuspenseQueryHookResult = ReturnType<typeof useAuftragsartTemplatesSuspenseQuery>;
export type AuftragsartTemplatesQueryResult = Apollo.QueryResult<AuftragsartTemplatesQuery, AuftragsartTemplatesQueryVariables>;
export const AuftragsartListDocument = gql`
  query AuftragsartList($includeArchiviert: Boolean, $withDetails: Boolean) {
    getAuftragsartList(includeArchiviert: $includeArchiviert, withDetails: $withDetails) {
      data {
        ...AuftragsartBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AuftragsartBaseFieldsFragmentDoc}
`;
export function useAuftragsartListQuery(baseOptions?: Apollo.QueryHookOptions<AuftragsartListQuery, AuftragsartListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartListQuery, AuftragsartListQueryVariables>(AuftragsartListDocument, options);
}
export function useAuftragsartListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartListQuery, AuftragsartListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartListQuery, AuftragsartListQueryVariables>(AuftragsartListDocument, options);
}
export function useAuftragsartListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragsartListQuery, AuftragsartListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartListQuery, AuftragsartListQueryVariables>(AuftragsartListDocument, options);
}
export type AuftragsartListQueryHookResult = ReturnType<typeof useAuftragsartListQuery>;
export type AuftragsartListLazyQueryHookResult = ReturnType<typeof useAuftragsartListLazyQuery>;
export type AuftragsartListSuspenseQueryHookResult = ReturnType<typeof useAuftragsartListSuspenseQuery>;
export type AuftragsartListQueryResult = Apollo.QueryResult<AuftragsartListQuery, AuftragsartListQueryVariables>;
export const AuftragsartListForStornoSelectDocument = gql`
  query AuftragsartListForStornoSelect($stornoForType: AuftragsartType) {
    getAuftragsartList(includeArchiviert: true, withDetails: false, stornoForType: $stornoForType) {
      data {
        auftragsartId
        bezeichnung
        status {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAuftragsartListForStornoSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<AuftragsartListForStornoSelectQuery, AuftragsartListForStornoSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartListForStornoSelectQuery, AuftragsartListForStornoSelectQueryVariables>(
    AuftragsartListForStornoSelectDocument,
    options
  );
}
export function useAuftragsartListForStornoSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartListForStornoSelectQuery, AuftragsartListForStornoSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartListForStornoSelectQuery, AuftragsartListForStornoSelectQueryVariables>(
    AuftragsartListForStornoSelectDocument,
    options
  );
}
export function useAuftragsartListForStornoSelectSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragsartListForStornoSelectQuery, AuftragsartListForStornoSelectQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartListForStornoSelectQuery, AuftragsartListForStornoSelectQueryVariables>(
    AuftragsartListForStornoSelectDocument,
    options
  );
}
export type AuftragsartListForStornoSelectQueryHookResult = ReturnType<typeof useAuftragsartListForStornoSelectQuery>;
export type AuftragsartListForStornoSelectLazyQueryHookResult = ReturnType<typeof useAuftragsartListForStornoSelectLazyQuery>;
export type AuftragsartListForStornoSelectSuspenseQueryHookResult = ReturnType<typeof useAuftragsartListForStornoSelectSuspenseQuery>;
export type AuftragsartListForStornoSelectQueryResult = Apollo.QueryResult<
  AuftragsartListForStornoSelectQuery,
  AuftragsartListForStornoSelectQueryVariables
>;
export const AuftragsartDocument = gql`
  query Auftragsart($auftragsartId: ID!) {
    getAuftragsart(auftragsartId: $auftragsartId) {
      data {
        ...AuftragsartFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AuftragsartFieldsFragmentDoc}
`;
export function useAuftragsartQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragsartQuery, AuftragsartQueryVariables> &
    ({ variables: AuftragsartQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartQuery, AuftragsartQueryVariables>(AuftragsartDocument, options);
}
export function useAuftragsartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartQuery, AuftragsartQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartQuery, AuftragsartQueryVariables>(AuftragsartDocument, options);
}
export function useAuftragsartSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragsartQuery, AuftragsartQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartQuery, AuftragsartQueryVariables>(AuftragsartDocument, options);
}
export type AuftragsartQueryHookResult = ReturnType<typeof useAuftragsartQuery>;
export type AuftragsartLazyQueryHookResult = ReturnType<typeof useAuftragsartLazyQuery>;
export type AuftragsartSuspenseQueryHookResult = ReturnType<typeof useAuftragsartSuspenseQuery>;
export type AuftragsartQueryResult = Apollo.QueryResult<AuftragsartQuery, AuftragsartQueryVariables>;
export const AuftragsartKurzbezeichnungDocument = gql`
  query AuftragsartKurzbezeichnung($auftragsartId: ID!) {
    getAuftragsart(auftragsartId: $auftragsartId) {
      data {
        bezeichnung
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAuftragsartKurzbezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragsartKurzbezeichnungQuery, AuftragsartKurzbezeichnungQueryVariables> &
    ({ variables: AuftragsartKurzbezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartKurzbezeichnungQuery, AuftragsartKurzbezeichnungQueryVariables>(AuftragsartKurzbezeichnungDocument, options);
}
export function useAuftragsartKurzbezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartKurzbezeichnungQuery, AuftragsartKurzbezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartKurzbezeichnungQuery, AuftragsartKurzbezeichnungQueryVariables>(AuftragsartKurzbezeichnungDocument, options);
}
export function useAuftragsartKurzbezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragsartKurzbezeichnungQuery, AuftragsartKurzbezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartKurzbezeichnungQuery, AuftragsartKurzbezeichnungQueryVariables>(
    AuftragsartKurzbezeichnungDocument,
    options
  );
}
export type AuftragsartKurzbezeichnungQueryHookResult = ReturnType<typeof useAuftragsartKurzbezeichnungQuery>;
export type AuftragsartKurzbezeichnungLazyQueryHookResult = ReturnType<typeof useAuftragsartKurzbezeichnungLazyQuery>;
export type AuftragsartKurzbezeichnungSuspenseQueryHookResult = ReturnType<typeof useAuftragsartKurzbezeichnungSuspenseQuery>;
export type AuftragsartKurzbezeichnungQueryResult = Apollo.QueryResult<AuftragsartKurzbezeichnungQuery, AuftragsartKurzbezeichnungQueryVariables>;
export const AuftragsartTypeListDocument = gql`
  query AuftragsartTypeList {
    getAuftragsartTypeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAuftragsartTypeListQuery(baseOptions?: Apollo.QueryHookOptions<AuftragsartTypeListQuery, AuftragsartTypeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartTypeListQuery, AuftragsartTypeListQueryVariables>(AuftragsartTypeListDocument, options);
}
export function useAuftragsartTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartTypeListQuery, AuftragsartTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartTypeListQuery, AuftragsartTypeListQueryVariables>(AuftragsartTypeListDocument, options);
}
export function useAuftragsartTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragsartTypeListQuery, AuftragsartTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartTypeListQuery, AuftragsartTypeListQueryVariables>(AuftragsartTypeListDocument, options);
}
export type AuftragsartTypeListQueryHookResult = ReturnType<typeof useAuftragsartTypeListQuery>;
export type AuftragsartTypeListLazyQueryHookResult = ReturnType<typeof useAuftragsartTypeListLazyQuery>;
export type AuftragsartTypeListSuspenseQueryHookResult = ReturnType<typeof useAuftragsartTypeListSuspenseQuery>;
export type AuftragsartTypeListQueryResult = Apollo.QueryResult<AuftragsartTypeListQuery, AuftragsartTypeListQueryVariables>;
export const AuftragsartGeneralChangeHistoryListDocument = gql`
  query AuftragsartGeneralChangeHistoryList($auftragsartId: ID!) {
    getAuftragsartGeneralChangeHistoryList(auftragsartId: $auftragsartId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useAuftragsartGeneralChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragsartGeneralChangeHistoryListQuery, AuftragsartGeneralChangeHistoryListQueryVariables> &
    ({ variables: AuftragsartGeneralChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartGeneralChangeHistoryListQuery, AuftragsartGeneralChangeHistoryListQueryVariables>(
    AuftragsartGeneralChangeHistoryListDocument,
    options
  );
}
export function useAuftragsartGeneralChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartGeneralChangeHistoryListQuery, AuftragsartGeneralChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartGeneralChangeHistoryListQuery, AuftragsartGeneralChangeHistoryListQueryVariables>(
    AuftragsartGeneralChangeHistoryListDocument,
    options
  );
}
export function useAuftragsartGeneralChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AuftragsartGeneralChangeHistoryListQuery, AuftragsartGeneralChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartGeneralChangeHistoryListQuery, AuftragsartGeneralChangeHistoryListQueryVariables>(
    AuftragsartGeneralChangeHistoryListDocument,
    options
  );
}
export type AuftragsartGeneralChangeHistoryListQueryHookResult = ReturnType<typeof useAuftragsartGeneralChangeHistoryListQuery>;
export type AuftragsartGeneralChangeHistoryListLazyQueryHookResult = ReturnType<typeof useAuftragsartGeneralChangeHistoryListLazyQuery>;
export type AuftragsartGeneralChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useAuftragsartGeneralChangeHistoryListSuspenseQuery>;
export type AuftragsartGeneralChangeHistoryListQueryResult = Apollo.QueryResult<
  AuftragsartGeneralChangeHistoryListQuery,
  AuftragsartGeneralChangeHistoryListQueryVariables
>;
export const AuftragsartTexteChangeHistoryListDocument = gql`
  query AuftragsartTexteChangeHistoryList($auftragsartId: ID!) {
    getAuftragsartTexteChangeHistoryList(auftragsartId: $auftragsartId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useAuftragsartTexteChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragsartTexteChangeHistoryListQuery, AuftragsartTexteChangeHistoryListQueryVariables> &
    ({ variables: AuftragsartTexteChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartTexteChangeHistoryListQuery, AuftragsartTexteChangeHistoryListQueryVariables>(
    AuftragsartTexteChangeHistoryListDocument,
    options
  );
}
export function useAuftragsartTexteChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartTexteChangeHistoryListQuery, AuftragsartTexteChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartTexteChangeHistoryListQuery, AuftragsartTexteChangeHistoryListQueryVariables>(
    AuftragsartTexteChangeHistoryListDocument,
    options
  );
}
export function useAuftragsartTexteChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AuftragsartTexteChangeHistoryListQuery, AuftragsartTexteChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartTexteChangeHistoryListQuery, AuftragsartTexteChangeHistoryListQueryVariables>(
    AuftragsartTexteChangeHistoryListDocument,
    options
  );
}
export type AuftragsartTexteChangeHistoryListQueryHookResult = ReturnType<typeof useAuftragsartTexteChangeHistoryListQuery>;
export type AuftragsartTexteChangeHistoryListLazyQueryHookResult = ReturnType<typeof useAuftragsartTexteChangeHistoryListLazyQuery>;
export type AuftragsartTexteChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useAuftragsartTexteChangeHistoryListSuspenseQuery>;
export type AuftragsartTexteChangeHistoryListQueryResult = Apollo.QueryResult<
  AuftragsartTexteChangeHistoryListQuery,
  AuftragsartTexteChangeHistoryListQueryVariables
>;
