import React from 'react';
import { Route } from 'react-router';
import { isAdmin } from '../../../../security/permissionChecks';
import UsersPage from './index';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';

const andromedaSysSettingsVerwaltungBenutzerPageRoute = (
  <Route path={URI_ANDROMEDA_SYS_SETTINGS.user} element={<AuthRoute hasPermissionFn={isAdmin} element={<UsersPage />} />} />
);

export default andromedaSysSettingsVerwaltungBenutzerPageRoute;
