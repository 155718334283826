import React from 'react';
import { Tag } from 'antd';
import { ErrechnetesErloesKonto } from '../../../../types';
import { NestedTableWithColSelectorColProps } from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

const kontierungstabelleTemplateErloesKontoTableColumns: NestedTableWithColSelectorColProps<ErrechnetesErloesKonto>[] = [
  {
    title: 'USt %',
    key: 'bezeichnung',
    width: 200,
    render: (value, record) => record.steuersatz,
    defaultSelected: true,
  },
  {
    title: 'Erlöskonto',
    key: 'basiskonto',
    defaultSelected: true,
    render: (value, record) => <Tag>{`S${record.erloeskonto}`}</Tag>,
  },
  {
    title: 'Buchungskennzeichen',
    key: 'aufwandskonto',
    defaultSelected: true,
    render: (value, record) => record.buchungsKZ ?? '',
  },
  {
    title: '',
    key: 'erstelltAm',
  },
  {
    title: '',
    key: 'ersteller',
  },
  {
    title: '',
    key: 'action',
  },
];

export default kontierungstabelleTemplateErloesKontoTableColumns;
