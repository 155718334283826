import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import { useToggle } from '../../../hooks/useToggle';
import { useBestandseinheitListQuery } from '../gql/BestandseinheitQueries.types';
import { useOnBestandseinheitDataChangedEvents } from './useOnBestandseinheitDataChangedEvents';
import BestandseinheitCreateForm from '../Form/Create/BestandseinheitCreateForm';
import BestandseinheitTable from './BestandseinheitTable';
import { BestandseinheitListEntry } from '../../../types';

type Props = {
  objektId: string;
  isWegObjekt: boolean;
  refetchCard: () => Promise<unknown>;
};

const BestandseinheitListing: FC<Props> = ({ objektId, isWegObjekt, refetchCard }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [showArchived, toggleShowArchived] = useToggle();

  const { loading, data, refetch } = useBestandseinheitListQuery({
    variables: {
      objektId,
      includeArchiviert: showArchived,
    },
  });

  const bestandseinheitListData: BestandseinheitListEntry[] = data?.getBestandseinheitList.data ?? [];
  const callback = () => refetchCard().then(() => refetch());

  useOnBestandseinheitDataChangedEvents('bestandseinheit', callback, collectBestanseinheitIds(bestandseinheitListData));

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button size="small" onClick={toggleShowArchived}>
          Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}
        </Button>
        <Button icon={<PlusOutlined />} type="primary" size="small" onClick={onCollapse}>
          Bestandseinheit erstellen
        </Button>
      </ButtonsAligned>
      <BestandseinheitTable
        objektId={objektId}
        isWegObjekt={isWegObjekt}
        refetch={refetch}
        bestandseinheitListData={bestandseinheitListData}
        loading={loading}
        onCollapse={onCollapse}
        onActionSuccess={callback}
      />
      <Drawer title="Neue Bestandseinheit erstellen" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <BestandseinheitCreateForm
          objektId={objektId}
          onSubmit={() => {
            onCollapse();
            refetch();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
    </>
  );
};

const collectBestanseinheitIds = (bestandseinheitList: BestandseinheitListEntry[]) =>
  bestandseinheitList.map((bestandseinheit) => bestandseinheit.bestandseinheitId);

export default BestandseinheitListing;
