import React from 'react';
import AusgabenRows from './Ausgaben/AusgabenRows';
import EinnahmenRows from './Einnahmen/EinnahmenRows';
import { TableWithAlignedColsExpandedRowType } from '../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import TableWithAlignedColsHeaderRow from '../../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsHeaderRow';
import UstVomAufwandRows from './UstVomAufwand/UstVomAufwandRows';
import { AbrechnungTypeTuple, ObjektAbrechnungkreis } from '../../../../../types';
import { isAbrKreisTypeRepFonds } from '../../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { isAbrechnungTypeBkAbrechnung } from '../../../shared/abrechnungHelpers';

const objektAbrechnungTableExpandedRow = (
  weAbrKreis: ObjektAbrechnungkreis,
  fromInclusive: string,
  toInclusive: string,
  objektId: string,
  buchungskreisId: string,
  abrechnungType: AbrechnungTypeTuple
): TableWithAlignedColsExpandedRowType<ObjektAbrechnungkreis>[] => {
  const ausgaben: TableWithAlignedColsExpandedRowType<ObjektAbrechnungkreis> = {
    header: (isOpen, setIsOpen) => (
      <TableWithAlignedColsHeaderRow
        isOpen={isOpen}
        bezeichnungHeaderText="Ausgaben"
        sumHeaderText={['Ausgaben']}
        cellToUseForSumHeaderText={[4]}
        onClick={setIsOpen}
        startCell={1}
        endCell={7}
      />
    ),
    body: (abrKreis) => (
      <AusgabenRows
        kontoList={abrKreis.aufwand.kontoList}
        isAbrKreisRepFonds={isAbrKreisTypeRepFonds(abrKreis.abrechnungskreisType.value)}
        fromInclusive={fromInclusive}
        toInclusive={toInclusive}
        objektId={objektId}
        buchungskreisId={buchungskreisId}
      />
    ),
  };

  const ustVomAufwand: TableWithAlignedColsExpandedRowType<ObjektAbrechnungkreis> = {
    header: (isOpen, setIsOpen) => (
      <TableWithAlignedColsHeaderRow
        isOpen={isOpen}
        bezeichnungHeaderText="Ust vom Aufwand"
        sumHeaderText={['Ausgaben']}
        cellToUseForSumHeaderText={[4]}
        onClick={setIsOpen}
        startCell={1}
        endCell={7}
      />
    ),
    body: (abrKreis) => (
      <UstVomAufwandRows
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        kontoList={abrKreis.ustVomAufwand!.kontoList}
        fromInclusive={fromInclusive}
        toInclusive={toInclusive}
        objektId={objektId}
        buchungskreisId={buchungskreisId}
      />
    ),
  };

  const einnahmenHeaderText = isAbrechnungTypeBkAbrechnung(abrechnungType.value) ? `Einnahmen (aus Subverwaltung)` : `Einnahmen`;
  const einnahmen: TableWithAlignedColsExpandedRowType<ObjektAbrechnungkreis> = {
    header: (isOpen, setIsOpen) => (
      <TableWithAlignedColsHeaderRow
        isOpen={isOpen}
        bezeichnungHeaderText={einnahmenHeaderText}
        sumHeaderText={['Einnahmen']}
        cellToUseForSumHeaderText={[6]}
        onClick={setIsOpen}
        startCell={1}
        endCell={7}
      />
    ),
    body: (abrKreis) => (
      <EinnahmenRows
        kontoList={abrKreis.erloes.kontoList}
        fromInclusive={fromInclusive}
        toInclusive={toInclusive}
        objektId={objektId}
        buchungskreisId={buchungskreisId}
      />
    ),
  };

  const data: TableWithAlignedColsExpandedRowType<ObjektAbrechnungkreis>[] = [];
  data.push(ausgaben);
  if (weAbrKreis.ustVomAufwand) {
    data.push(ustVomAufwand);
  }
  data.push(einnahmen);

  return data;
};

export default objektAbrechnungTableExpandedRow;
