import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { BeNutzungsArt, BeNutzungsArtCreateInput, BeNutzungsArtUpdateInput, NutzungsArt } from '../../../../../types';
import { ValuesDescriptionForKommentar } from '../../../../../components/Timeline/versionTimelineHelper';

type TBeNutzungsArtInput = BeNutzungsArtCreateInput | BeNutzungsArtUpdateInput;

export type NutzungsArtVersionFormValues = {
  validFrom: string;
  kommentar: string;
  nutzungsArt: NutzungsArt;
};

export const nutzungsArtVersionFormFields: FormFields<NutzungsArtVersionFormValues> = {
  validFrom: 'validFrom',
  kommentar: 'kommentar',
  nutzungsArt: 'nutzungsArt',
};

export const mapTimeblockToFormValues = (timeblock: ITimeblock<BeNutzungsArt>): NutzungsArtVersionFormValues => ({
  validFrom: timeblock.validFrom,
  kommentar: timeblock.kommentar ?? '',
  nutzungsArt: timeblock.nutzungsArt.value,
});

export const mapFormValuesToBestandseinheitVersion = (values: NutzungsArtVersionFormValues): TBeNutzungsArtInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  kommentar: values.kommentar,
  nutzungsArt: values.nutzungsArt,
});

export const nutzungsArtVersionFormValuesDescriptionsForKommentar: ValuesDescriptionForKommentar<NutzungsArtVersionFormValues> = {
  validFrom: 'Gültigkeitsdatum',
  nutzungsArt: 'Nutzungsart',
};
