import React, { FC } from 'react';
import { Card, Space } from 'antd';
import theme from '../../../theme';
import { useTimelineOpen } from '../../../components/Timeline/useTimelineOpen';
import PaulschalmieteDrawer from './PaulschalmieteDrawer';
import AllgemeineEinstellungKonto from '../shared/AllgemeineEinstellungKonto';
import { FirmendatenBuchhaltungEinstellung } from '../../../types';
import { useToggle } from '../../../hooks/useToggle';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import PauschalmieteForderungsKontoChangeHistoryListingTable from './PauschalmieteForderungsKontoChangeHistoryListingTable';
import AllgemeineEinstellungenCardActions from '../shared/AllgemeineEinstellungenCardActions';

type Props = {
  firmendatenBuchhaltungEinstellung: FirmendatenBuchhaltungEinstellung;
  onSuccess: () => void;
};

const PaulschalmieteCard: FC<Props> = ({ firmendatenBuchhaltungEinstellung, onSuccess }) => {
  const { timelineDrawer, openTimelineUpdateDrawer, closeTimelineDrawer } = useTimelineOpen();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  return (
    <>
      <Card
        style={{ width: '450px', border: '1px solid #d9d9d9' }}
        headStyle={{ backgroundColor: theme.cardBackgroundColors.grey }}
        size="default"
        bordered
        title="Paulschalmiete"
        extra={<AllgemeineEinstellungenCardActions onClickEdit={openTimelineUpdateDrawer} onClickChangeHistoryOpen={toggleChangeHistoryOpen} />}
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <AllgemeineEinstellungKonto kontoId={firmendatenBuchhaltungEinstellung?.pauschalmieteForderungskontoId} text="Forderung-Konto" />
          <AllgemeineEinstellungKonto kontoId={firmendatenBuchhaltungEinstellung?.pauschalmieteAufwandskontoId} text="Aufwand-Konto" />
        </Space>
      </Card>
      <PaulschalmieteDrawer
        timelineDrawer={timelineDrawer}
        closeTimelineDrawer={closeTimelineDrawer}
        pauschalmieteForderungskontoId={firmendatenBuchhaltungEinstellung?.pauschalmieteForderungskontoId}
        onSuccess={onSuccess}
      />
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <PauschalmieteForderungsKontoChangeHistoryListingTable />
      </HistoryModal>
    </>
  );
};

export default PaulschalmieteCard;
