import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { useQueryParams } from '../../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { verarbeitungTypes } from '../../../verarbeitungHelpers';
import { TVorschreibungVerbuchenVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import {
  mapFormValuesToVorschreibungVerbuchenVerarbeitungFilters,
  VorschreibungVerbuchenVerarbeitungEntryFiltersFormValues,
  vorschreibungVerbuchenVerarbEntryListingFiltersFormInitialValues,
} from './Filters/ListingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import VorschreibungVerbuchenVerarbeitungEntryTable from './VorschreibungVerbuchenVerarbeitungEntryTable';
import { AuftragVerbuchenGenerierlauf } from '../../../../../types';

type Props = {
  generierlauf: AuftragVerbuchenGenerierlauf;
  isCardCollapsed: boolean;
};

const VorschreibungVerbuchenVerarbeitungEntryListing: FC<Props> = ({ generierlauf, isCardCollapsed }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TVorschreibungVerbuchenVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.VorschreibungVerbuchen, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: VorschreibungVerbuchenVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.VorschreibungVerbuchen,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToVorschreibungVerbuchenVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<VorschreibungVerbuchenVerarbeitungEntryFiltersFormValues>
      initialValues={vorschreibungVerbuchenVerarbEntryListingFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />
          <VorschreibungVerbuchenVerarbeitungEntryTable generierlauf={generierlauf} queryParams={queryParams} isCardCollapsed={isCardCollapsed} />
        </>
      )}
    </Formik>
  );
};

export default VorschreibungVerbuchenVerarbeitungEntryListing;
