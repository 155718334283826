import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { isBuchungszeileKontoTypeSoll } from '../../bookingSuggestionHelpers';
import KontoDetailsCards from './KontoDetailsCards';
import { BookingSuggestionFormValues, BSBuchungszeileForTable } from '../../Form/buchungsmaskeFormMapper';

type Props = {
  buchungszeile: BSBuchungszeileForTable;
};

const KontoDetailsCardsBlock: FC<Props> = ({ buchungszeile }) => {
  const { values: formikValues } = useFormikContext<BookingSuggestionFormValues>();
  const buchungszeileListFromFormik = formikValues.buchungsanweisung.buchungszeileList;

  const manuallySelectedKonto = buchungszeileListFromFormik.filter((bz) => bz.buchungszeileId === buchungszeile.buchungszeileId)[0].konto;

  return isBuchungszeileKontoTypeSoll(buchungszeile.sollHaben.value) ? (
    <KontoDetailsCards
      sollKonto={manuallySelectedKonto ?? buchungszeile.konto}
      habenKonto={buchungszeile.gegenKonto}
      objektId={buchungszeile.objekt?.objektId}
    />
  ) : (
    <KontoDetailsCards
      sollKonto={buchungszeile.gegenKonto}
      habenKonto={manuallySelectedKonto ?? buchungszeile.konto}
      objektId={buchungszeile.objekt?.objektId}
    />
  );
};

export default KontoDetailsCardsBlock;
