import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFirmendatenBankSettingsMutationVariables = Types.Exact<{
  input: Types.FirmendatenBankSettingsUpdateInput;
}>;

export type UpdateFirmendatenBankSettingsMutation = {
  updateFirmendatenBankSettings: {
    data: { bankSettingsId?: string | null; sepaCreditorId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UpdateFirmendatenBankSettingsDocument = gql`
  mutation UpdateFirmendatenBankSettings($input: FirmendatenBankSettingsUpdateInput!) {
    updateFirmendatenBankSettings(input: $input) {
      data {
        bankSettingsId
        sepaCreditorId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateFirmendatenBankSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFirmendatenBankSettingsMutation, UpdateFirmendatenBankSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmendatenBankSettingsMutation, UpdateFirmendatenBankSettingsMutationVariables>(
    UpdateFirmendatenBankSettingsDocument,
    options
  );
}
export type UpdateFirmendatenBankSettingsMutationHookResult = ReturnType<typeof useUpdateFirmendatenBankSettingsMutation>;
export type UpdateFirmendatenBankSettingsMutationResult = Apollo.MutationResult<UpdateFirmendatenBankSettingsMutation>;
export type UpdateFirmendatenBankSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateFirmendatenBankSettingsMutation,
  UpdateFirmendatenBankSettingsMutationVariables
>;
