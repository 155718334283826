import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToKontoDetailsPage } from '../../../../Kontenplan/kontoUriPaths';
import { AppKonto } from '../../../../../types';

type Props = {
  isInCardContent: boolean;
  konto: AppKonto;
};

const VstKuerzungKonto: FC<Props> = ({ isInCardContent, konto }) => {
  const title = `${konto.nummer} - ${konto.bezeichnung}`;
  return isInCardContent ? (
    <DataWithShortenedText text={title} maxTextLength={30}>
      {(shortenedText) => <Link to={generatePathToKontoDetailsPage(konto.kontoId)}>{shortenedText}</Link>}
    </DataWithShortenedText>
  ) : (
    <Link to={generatePathToKontoDetailsPage(konto.kontoId)}>{title}</Link>
  );
};

export default VstKuerzungKonto;
