import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { Tabs, TabsProps } from 'antd';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import FormButtons from '../../../../../../components/Button/FormButtons';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';
import PersoneninfosDatenTab from '../Tabs/PersoneninfosDatenTab';
import { RechtstraegerInfoFeldType } from '../../../../../../types';
import { handleUpdateSubmit } from '../../shared/handleSubmit';
import { personeninfosValidationSchema } from '../shared/personeninfosValidationSchema';
import { mapToUpdateFormValues, personeninfosUpdateFormFields, PersoneninfosUpdateFormValues } from './personeninfosUpdateFormMapper';
import { hausbetreuungUpdateFormFields } from '../../../../../Objekt/InfoFeld/Form/Hausbetreuung/Update/hausbetreuungUpdateFormMapper';
import InfoFeldDocumentTab from '../../../../../shared/InfoFeld/Tabs/InfoFeldDocumentTab';
import { RechtstraegerInfoFeldFragment } from '../../../gql/rechtstraegerInfoFeldFragments.types';
import FormButtonClose from '../../../../../../components/Button/FormButtonClose';
import RechtstraegerInfoFeldTicketListing from '../../../Ticket/RechtstraegerInfoFeldTicketListing';
import { InfoFeldUpdateFormTabKey } from '../../../../../InfoFeld/infoFeldTypes';

type Props = {
  rechtstraegerId: string;
  onCancel: () => void;
  onInfoFeldAction: () => void;
  onTicketAction: () => void;
  infoFeld: RechtstraegerInfoFeldFragment;
  activeKeyTab: InfoFeldUpdateFormTabKey;
};

const PersoneninfosUpdateForm: FC<Props> = ({ rechtstraegerId, onCancel, onInfoFeldAction, onTicketAction, infoFeld, activeKeyTab }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<PersoneninfosUpdateFormValues>
      initialValues={mapToUpdateFormValues(infoFeld)}
      validationSchema={personeninfosValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleUpdateSubmit(
          firmendatenId,
          rechtstraegerId,
          infoFeld.rechtstraegerInfoFeldId,
          RechtstraegerInfoFeldType.Personeninfos,
          values,
          setSubmitting,
          onInfoFeldAction
        );
      }}
    >
      {(formikProps) => {
        const items: TabsProps['items'] = [
          {
            key: InfoFeldUpdateFormTabKey.DATA,
            label: 'Daten',
            children: (
              <Form layout="vertical">
                <PersoneninfosDatenTab formFields={personeninfosUpdateFormFields} />
                <InfoFeldUpload
                  name={personeninfosUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.TICKET,
            label: 'Fristen/Aufgaben',
            children: (
              <>
                <RechtstraegerInfoFeldTicketListing rechtstraegerId={rechtstraegerId} infoFeld={infoFeld} onSuccess={onTicketAction} />
                <FormButtonClose onCancel={onCancel} />
              </>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.DOCUMENT,
            label: 'Dokumente',
            children: (
              <Form layout="vertical">
                <InfoFeldDocumentTab
                  infoFeld={infoFeld}
                  deleteDocumentFileIdList={formikProps.values.deleteDocumentFileIdList}
                  onDocumentDelete={(value) => formikProps.setFieldValue(hausbetreuungUpdateFormFields.deleteDocumentFileIdList, value)}
                  firmendatenId={firmendatenId}
                />
                <InfoFeldUpload
                  name={personeninfosUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
        ];

        return <Tabs defaultActiveKey={activeKeyTab} style={{ minHeight: '420px' }} items={items} />;
      }}
    </Formik>
  );
};

export default PersoneninfosUpdateForm;
