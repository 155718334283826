import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { LabelWithOptionalValue } from '../shared/types';
import pdfStyles from '../styles/pdfStyles';

type Props = {
  mandatReference: LabelWithOptionalValue;
};

const SLMMandatsreferenz: FC<Props> = ({ mandatReference }) => {
  return (
    <View style={pdfStyles.column}>
      <Text
        style={[
          pdfStyles.textNormal,
          {
            marginTop: '10mm',
            fontWeight: 'bold',
          },
        ]}
      >
        {mandatReference.label}
      </Text>
      <Text
        style={[
          pdfStyles.textNormal,
          pdfStyles.borderBottomSmall,
          {
            color: '#db3d47',
            width: '100%',
          },
        ]}
      >
        {mandatReference.value}
      </Text>
    </View>
  );
};

export default SLMMandatsreferenz;
