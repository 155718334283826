import React from 'react';
import { TableWithAlignedColsExpandedRowType } from '../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import TableWithAlignedColsHeaderRow from '../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsHeaderRow';
import TableWithAlignedColsBody from '../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsBody';
import aufwandsKontoRowColumns from './aufwandsKontoRowColumns';
import kontoAufteilungRow from './kontoAufteilungRow';
import { UstVomAufwandAufwandKonto, UstVomAufwandRepFondsKonto } from '../../../../types';

const aufwandsKontoRow = (
  objektId: string,
  rechtstraegerId: string,
  bestandseinheitId: string,
  vonInklusive: string,
  bisInklusive: string
): TableWithAlignedColsExpandedRowType<UstVomAufwandRepFondsKonto>[] => [
  {
    header: (isOpen, setIsOpen) => (
      <TableWithAlignedColsHeaderRow
        isOpen={isOpen}
        bezeichnungHeaderText="Fibu-Konto"
        sumHeaderText={['Summe Aufwand Rep. Fonds', 'Summe-Ust.-vom-Aufwand']}
        cellToUseForSumHeaderText={[5, 7]}
        onClick={setIsOpen}
        startCell={2}
        endCell={7}
        colSpan={[3, 2]}
        positionOfButton={3}
      />
    ),
    body: (ustVomAufwand) => (
      <>
        {ustVomAufwand.aufwandsKontoList.map((konto, index) => (
          <TableWithAlignedColsBody<UstVomAufwandAufwandKonto>
            key={index}
            dataSource={konto}
            columns={aufwandsKontoRowColumns(objektId, vonInklusive, bisInklusive)}
            expandedRow={() => kontoAufteilungRow(objektId, rechtstraegerId, bestandseinheitId)}
            positionOfButton={3}
          />
        ))}
      </>
    ),
  },
];

export default aufwandsKontoRow;
