import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FiltersFormValues, mapFormValuesToQueryParams, mapQueryParamsToFormValues } from './Filters/listingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import WizardListTable from './Table/WizardListingTable';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { useWizardListQuery } from './gql/wizardQueries.types';
import { useOnWizardListDataChangedEvents } from '../useOnWizardDataChangedEvents';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { TWizardQueryParams, updateQueryParams } from './Filters/filtersQueryParams';

const WizardListingForm = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TWizardQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  const { data, pagination, loading, handleTableSorting, refetch } = useQueryWithPagingAndSorting(
    useWizardListQuery,
    { variables: { ...queryParams } },
    {
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const wizardList = data?.getWizardList.data.contentList;

  const wizardIds = wizardList?.map((wizard) => wizard.wizardId);

  useOnWizardListDataChangedEvents('wizardList', refetch, wizardIds);

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  return (
    <>
      <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => <ListingFilters formikProps={formikProps} queryParams={queryParams} />}
      </Formik>
      <WizardListTable pagination={pagination} handleTableSorting={handleTableSorting} wizardList={wizardList} loading={loading} onAction={refetch} />
    </>
  );
};

export default WizardListingForm;
