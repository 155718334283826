import React, { FC } from 'react';
import { FormikProps } from 'formik';
import Errors from '../../../../../shared/Errors';

type AuflageFormErrorsProps = {
  formikProps: FormikProps<any>;
};

const AuflageFormErrors: FC<AuflageFormErrorsProps> = ({ formikProps }) =>
  formikProps.errors.formError ? <Errors errors={formikProps.errors.formError as string} values={[]} /> : null;

export default AuflageFormErrors;
