import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerrechnungUstVomAufwandListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type VerrechnungUstVomAufwandListQuery = {
  getVerrechnungUstVomAufwandList: {
    data: Array<{ description: string; text: string; value: Types.VerrechnungsUstVomAufwand }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VerrechnungUstVomAufwandListDocument = gql`
  query VerrechnungUstVomAufwandList {
    getVerrechnungUstVomAufwandList {
      data {
        description
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVerrechnungUstVomAufwandListQuery(
  baseOptions?: Apollo.QueryHookOptions<VerrechnungUstVomAufwandListQuery, VerrechnungUstVomAufwandListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerrechnungUstVomAufwandListQuery, VerrechnungUstVomAufwandListQueryVariables>(
    VerrechnungUstVomAufwandListDocument,
    options
  );
}
export function useVerrechnungUstVomAufwandListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VerrechnungUstVomAufwandListQuery, VerrechnungUstVomAufwandListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VerrechnungUstVomAufwandListQuery, VerrechnungUstVomAufwandListQueryVariables>(
    VerrechnungUstVomAufwandListDocument,
    options
  );
}
export function useVerrechnungUstVomAufwandListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VerrechnungUstVomAufwandListQuery, VerrechnungUstVomAufwandListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VerrechnungUstVomAufwandListQuery, VerrechnungUstVomAufwandListQueryVariables>(
    VerrechnungUstVomAufwandListDocument,
    options
  );
}
export type VerrechnungUstVomAufwandListQueryHookResult = ReturnType<typeof useVerrechnungUstVomAufwandListQuery>;
export type VerrechnungUstVomAufwandListLazyQueryHookResult = ReturnType<typeof useVerrechnungUstVomAufwandListLazyQuery>;
export type VerrechnungUstVomAufwandListSuspenseQueryHookResult = ReturnType<typeof useVerrechnungUstVomAufwandListSuspenseQuery>;
export type VerrechnungUstVomAufwandListQueryResult = Apollo.QueryResult<
  VerrechnungUstVomAufwandListQuery,
  VerrechnungUstVomAufwandListQueryVariables
>;
