import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { LabeledValue } from '../../shared/types';
import LabelValue from '../../shared/LabelValue';
import pdfStyles from '../../styles/pdfStyles';

const SubAdminAbrSummary: FC<{ endErgebnis: LabeledValue }> = ({ endErgebnis }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight, { marginTop: '10mm' }]}>
    <View style={[pdfStyles.column, { width: '45%' }]} />

    <View style={[pdfStyles.column, { width: '55%' }]}>
      {/* ErgebnisTotal */}
      <LabelValue
        valueTuple={endErgebnis}
        labelStyle={{ width: '70%', fontWeight: 'bold' }}
        valueStyle={{ width: '30%', fontWeight: 'bold', alignItems: 'flex-end', textAlign: 'right' }}
      />
    </View>
  </View>
);

export default SubAdminAbrSummary;
