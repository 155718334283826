import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungskreisTemplateListQueryVariables = Types.Exact<{
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AbrechnungskreisTemplateListQuery = {
  getAbrechnungskreisTemplateList: {
    data: Array<{
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      deletable?: boolean | null;
      status: { description?: string | null; value: Types.AbrechnungskreisStatus; text: string };
      type: { value: Types.AbrechnungskreisType; text: string };
      verrechnungUstVomAufwand?: { description: string; value: Types.VerrechnungsUstVomAufwand; text: string } | null;
      warningList: Array<{ message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungskreisTemplateQueryVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
}>;

export type AbrechnungskreisTemplateQuery = {
  getAbrechnungskreisTemplate: {
    data: {
      abrechnungskreisId: string;
      bezeichnung: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      status: { description?: string | null; value: Types.AbrechnungskreisStatus; text: string };
      type: { value: Types.AbrechnungskreisType; text: string };
      verrechnungUstVomAufwand?: { description: string; value: Types.VerrechnungsUstVomAufwand; text: string } | null;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungskreisTemplateChangeHistoryListQueryVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
}>;

export type AbrechnungskreisTemplateChangeHistoryListQuery = {
  getAbrechnungskreisTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const AbrechnungskreisTemplateListDocument = gql`
  query AbrechnungskreisTemplateList($withDetails: Boolean, $includeArchiviert: Boolean) {
    getAbrechnungskreisTemplateList(withDetails: $withDetails, includeArchiviert: $includeArchiviert) {
      data {
        abrechnungskreisId
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        firmendatenId
        deletable
        status {
          description
          value
          text
        }
        type {
          value
          text
        }
        verrechnungUstVomAufwand {
          description
          value
          text
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAbrechnungskreisTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<AbrechnungskreisTemplateListQuery, AbrechnungskreisTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungskreisTemplateListQuery, AbrechnungskreisTemplateListQueryVariables>(
    AbrechnungskreisTemplateListDocument,
    options
  );
}
export function useAbrechnungskreisTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungskreisTemplateListQuery, AbrechnungskreisTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungskreisTemplateListQuery, AbrechnungskreisTemplateListQueryVariables>(
    AbrechnungskreisTemplateListDocument,
    options
  );
}
export function useAbrechnungskreisTemplateListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AbrechnungskreisTemplateListQuery, AbrechnungskreisTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungskreisTemplateListQuery, AbrechnungskreisTemplateListQueryVariables>(
    AbrechnungskreisTemplateListDocument,
    options
  );
}
export type AbrechnungskreisTemplateListQueryHookResult = ReturnType<typeof useAbrechnungskreisTemplateListQuery>;
export type AbrechnungskreisTemplateListLazyQueryHookResult = ReturnType<typeof useAbrechnungskreisTemplateListLazyQuery>;
export type AbrechnungskreisTemplateListSuspenseQueryHookResult = ReturnType<typeof useAbrechnungskreisTemplateListSuspenseQuery>;
export type AbrechnungskreisTemplateListQueryResult = Apollo.QueryResult<
  AbrechnungskreisTemplateListQuery,
  AbrechnungskreisTemplateListQueryVariables
>;
export const AbrechnungskreisTemplateDocument = gql`
  query AbrechnungskreisTemplate($abrechnungskreisId: ID!) {
    getAbrechnungskreisTemplate(abrechnungskreisId: $abrechnungskreisId) {
      data {
        abrechnungskreisId
        bezeichnung
        createdBy
        createdByMitarbeiterId
        deletable
        firmendatenId
        status {
          description
          value
          text
        }
        type {
          value
          text
        }
        verrechnungUstVomAufwand {
          description
          value
          text
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAbrechnungskreisTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungskreisTemplateQuery, AbrechnungskreisTemplateQueryVariables> &
    ({ variables: AbrechnungskreisTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungskreisTemplateQuery, AbrechnungskreisTemplateQueryVariables>(AbrechnungskreisTemplateDocument, options);
}
export function useAbrechnungskreisTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungskreisTemplateQuery, AbrechnungskreisTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungskreisTemplateQuery, AbrechnungskreisTemplateQueryVariables>(AbrechnungskreisTemplateDocument, options);
}
export function useAbrechnungskreisTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AbrechnungskreisTemplateQuery, AbrechnungskreisTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungskreisTemplateQuery, AbrechnungskreisTemplateQueryVariables>(AbrechnungskreisTemplateDocument, options);
}
export type AbrechnungskreisTemplateQueryHookResult = ReturnType<typeof useAbrechnungskreisTemplateQuery>;
export type AbrechnungskreisTemplateLazyQueryHookResult = ReturnType<typeof useAbrechnungskreisTemplateLazyQuery>;
export type AbrechnungskreisTemplateSuspenseQueryHookResult = ReturnType<typeof useAbrechnungskreisTemplateSuspenseQuery>;
export type AbrechnungskreisTemplateQueryResult = Apollo.QueryResult<AbrechnungskreisTemplateQuery, AbrechnungskreisTemplateQueryVariables>;
export const AbrechnungskreisTemplateChangeHistoryListDocument = gql`
  query AbrechnungskreisTemplateChangeHistoryList($abrechnungskreisId: ID!) {
    getAbrechnungskreisTemplateChangeHistoryList(abrechnungskreisId: $abrechnungskreisId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useAbrechnungskreisTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungskreisTemplateChangeHistoryListQuery, AbrechnungskreisTemplateChangeHistoryListQueryVariables> &
    ({ variables: AbrechnungskreisTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungskreisTemplateChangeHistoryListQuery, AbrechnungskreisTemplateChangeHistoryListQueryVariables>(
    AbrechnungskreisTemplateChangeHistoryListDocument,
    options
  );
}
export function useAbrechnungskreisTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungskreisTemplateChangeHistoryListQuery, AbrechnungskreisTemplateChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungskreisTemplateChangeHistoryListQuery, AbrechnungskreisTemplateChangeHistoryListQueryVariables>(
    AbrechnungskreisTemplateChangeHistoryListDocument,
    options
  );
}
export function useAbrechnungskreisTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AbrechnungskreisTemplateChangeHistoryListQuery, AbrechnungskreisTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungskreisTemplateChangeHistoryListQuery, AbrechnungskreisTemplateChangeHistoryListQueryVariables>(
    AbrechnungskreisTemplateChangeHistoryListDocument,
    options
  );
}
export type AbrechnungskreisTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useAbrechnungskreisTemplateChangeHistoryListQuery>;
export type AbrechnungskreisTemplateChangeHistoryListLazyQueryHookResult = ReturnType<typeof useAbrechnungskreisTemplateChangeHistoryListLazyQuery>;
export type AbrechnungskreisTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungskreisTemplateChangeHistoryListSuspenseQuery
>;
export type AbrechnungskreisTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  AbrechnungskreisTemplateChangeHistoryListQuery,
  AbrechnungskreisTemplateChangeHistoryListQueryVariables
>;
