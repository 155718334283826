import React, { FC } from 'react';
import { useObjektAbrechnungDeviationListQuery } from './gql/ObjektAbrechnungDeviationQueries.types';
import { AbrechnungStatusTuple } from '../../../../types';
import { showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import DeviationTab from '../../../../components/Deviation/Tab/DeviationTab';
import { useObjektAbrechnungApplyAllDeviationsMutation } from './gql/ObjektAbrechnungDeviationMutations.types';
import { pathsToWeAbrechnungDetails } from '../../BkOrWe/weAbrechnungUriPaths';
import { useOnBkOrWeAbrechnungDataChangedEvents } from '../../BkOrWe/useOnBkOrWeAbrechnungDataChangeEvent';

type Props = {
  objektId: string;
  objektAbrechnungId: string;
  onAction: () => Promise<unknown>;
  status: AbrechnungStatusTuple;
};

const BkOrWeObjektAbrechnungDeviationTab: FC<Props> = ({ objektId, objektAbrechnungId, onAction, status }) => {
  const { data, refetch } = useObjektAbrechnungDeviationListQuery({ variables: { objektId, objektAbrechnungId } });

  const [runApplyAllDeviations] = useObjektAbrechnungApplyAllDeviationsMutation({
    variables: {
      objektId,
      objektAbrechnungId,
    },
    onCompleted: () => {
      showSuccessMsgOther('Alle Abweichungen sind korrigiert.');
      onAction().then(() => refetch());
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const objektAbrechnungDeviationList = data?.getObjektAbrechnungDeviationList.data;
  const loading = !objektAbrechnungDeviationList;

  useOnBkOrWeAbrechnungDataChangedEvents('ObjektAbrechnungDeviationList', refetch);

  return (
    <DeviationTab
      dataSource={objektAbrechnungDeviationList}
      loading={loading}
      status={status}
      runApplyAllDeviations={runApplyAllDeviations}
      showSourceEntity
      reviewErrorUriPath={pathsToWeAbrechnungDetails(objektId, objektAbrechnungId).objAbrTab}
    />
  );
};

export default BkOrWeObjektAbrechnungDeviationTab;
