import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import StandingInvoiceUpdatePage from './StandingInvoiceUpdatePage';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import { incomingInvoicePaths } from '../../../features/IncomingInvoice/incomingInvoiceUriPaths';

const standingInvoiceUpdatePageRoute = (
  <Route
    path={incomingInvoicePaths.standingInvoiceUpdate}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<StandingInvoiceUpdatePage />} />}
  />
);

export default standingInvoiceUpdatePageRoute;
