import React, { FC, useContext } from 'react';
import { Button, Empty } from 'antd';
import { useVertragVposIndexationAgreementListQuery } from '../../gql/VPosIndexationAgreementQueries.types';
import { VertragVposIndexationAgreementListEntry } from '../../../../../../../types';
import indexationAgreementTableColumns from './indexationAgreementTableColumns';
import IndexedValueTable from '../Level3/IndexedValueTable';
import { useToggle } from '../../../../../../../hooks/useToggle';
import { IndexationAgreementContext } from './IndexationAgreementContext';
import CreateIndexationAgreementDrawer from './CreateUpdateIndexationAgreement/CreateIndexationAgreementDrawer';
import NestedTable from '../../../../../../../components/Table/NestedTable/NestedTable';
import { QUERY_PARAM_KEY, useQueryParams } from '../../../../../../../hooks/useStringQueryParams';
import { useTableExpandedRowKeys } from '../../../../../../../components/Table/shared/useTableExpandedRowKeys';

type Props = {
  onListChange: () => void;
};

const IndexationAgreementTable: FC<Props> = ({ onListChange }) => {
  const { expRowIdLvl2: indexationAgreementId } = useQueryParams<{ [QUERY_PARAM_KEY.EXP_ROW_ID_LVL2]: string }>();

  const { expandedRowKeys, toggleExpandedRowKey } = useTableExpandedRowKeys(indexationAgreementId ? [indexationAgreementId] : undefined);

  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);
  const [isCreateDrawerOpen, toggleCreateDrawerOpen] = useToggle();

  const { data, loading, refetch } = useVertragVposIndexationAgreementListQuery({
    variables: {
      bestandseinheitId,
      objektId,
      vertragId,
      vorschreibungspositionId,
    },
  });

  const indexationAgreementList = data?.getVertragVposIndexationAgreementList.data;

  const tableActions = (
    <>
      <Button type="primary" size="small" onClick={toggleCreateDrawerOpen}>
        Vereinbarung hinzufügen
      </Button>
      <CreateIndexationAgreementDrawer
        isDrawerOpen={isCreateDrawerOpen}
        onCancel={toggleCreateDrawerOpen}
        onSuccess={() => {
          onListChange();
          refetch();
          toggleCreateDrawerOpen();
        }}
      />
    </>
  );

  return (
    <>
      <NestedTable<VertragVposIndexationAgreementListEntry>
        colWidth={150}
        level={2}
        locale={{
          emptyText: <Empty description="Keine Wertsicherungsvereinbarungen vorhanden" />,
        }}
        loading={loading}
        dataSource={indexationAgreementList}
        columns={indexationAgreementTableColumns(bestandseinheitId, objektId, vertragId, vorschreibungspositionId, () => {
          onListChange();
          refetch();
        })}
        rowKey={(record) => record.vertragVposIndexationAgreementId}
        expandable={{
          expandedRowKeys,
          onExpand: (expanded, record) => toggleExpandedRowKey(record.vertragVposIndexationAgreementId),
          expandedRowRender: (record) => <IndexedValueTable indexationAgreementId={record.vertragVposIndexationAgreementId} />,
        }}
        actions={tableActions}
        useHighlighting
      />
    </>
  );
};

export default IndexationAgreementTable;
