import React, { FC } from 'react';
import { Select, SelectProps } from 'antd';
import { RechtstraegerInfoFeldType } from '../../../../types';

const RechtstraegerInfoFeldTypeSelect: FC<SelectProps> = ({ ...restProps }) => {
  return (
    <Select placeholder="Typ auswählen" allowClear size="small" style={{ minWidth: '160px' }} {...restProps}>
      {Object.entries(RechtstraegerInfoFeldType).map(([text, value]) => (
        <Select.Option value={value} key={value}>
          {text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RechtstraegerInfoFeldTypeSelect;
