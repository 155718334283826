import React, { FC } from 'react';
import { objektInfoFeldListingFiltersFormFields } from './objektInfoFeldListingFiltersFormMapper';
import FiltersWithMax3Fields from '../../../components/Filters/FiltersWithMax3Fields';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import ObjektInfoFeldTypeFormSelect from './Filters/ObjektInfoFeldTypeFormSelect';
import ObjektInfoFeldDrawerAndButton from './ObjektInfoFeldDrawerAndButton';

type Props = {
  onSuccess: () => void;
  objektId: string;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const ObjektInfoFeldListingFilters: FC<Props> = ({ objektId, onSuccess, showArchived, toggleShowArchived }) => (
  <FiltersWithMax3Fields
    hideTitle
    filters={
      <>
        <FormItemWithoutColon name={objektInfoFeldListingFiltersFormFields.type} label="Typ">
          <ObjektInfoFeldTypeFormSelect name={objektInfoFeldListingFiltersFormFields.type} />
        </FormItemWithoutColon>
      </>
    }
    actionButtons={
      <ObjektInfoFeldDrawerAndButton onSuccess={onSuccess} objektId={objektId} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
    }
  />
);

export default ObjektInfoFeldListingFilters;
