import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import theme from '../../../theme';

type MenuItemIconLinkProps = {
  uri: string;
  title: string;
  icon: React.ReactNode;
  state?: MenuItemIconLinkState;
};

export type MenuItemIconLinkState = 'error' | 'warning';

/**
 *
 * <h2>Usage</h2>
 * Used in ContextSidebar to show menu item with icon, badge or both
 *
 * <h2>Component Info</h2>
 *  <ul>
 *    <li>The component itself is used as prop in ContextSidebar and should never be used directly in any other component.</li>
 *  </ul>
 *
 * <h3>Extra-Info</h3>
 *  This component is documented in Storybook only to show the appearance on the UI.
 */

const MenuItemIconLink: FC<MenuItemIconLinkProps> = ({ title, icon, uri, state }) => (
  <>
    {state ? <IconWithDot icon={icon} state={state} /> : <>{icon}</>}
    <Link data-testid={`context-menuItem-${title}`} to={uri} />
  </>
);

type IconWithDotProps = {
  icon: React.ReactNode;
  state: MenuItemIconLinkState;
};

const IconWithDot: FC<IconWithDotProps> = ({ icon, state }) => (
  <Badge dot offset={[5, 5]} color={mapStateToColor(state)}>
    <div>{icon}</div>
  </Badge>
);

const mapStateToColor = (state: MenuItemIconLinkState) => {
  switch (state) {
    case 'error':
      return theme.colors.danger;
    case 'warning':
      return theme.colors.warning;
    default:
      return theme.colors.danger;
  }
};

export default MenuItemIconLink;
