import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import { ObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { BestandseinheitFieldsFragmentDoc, BestandseinheitBaseFieldsFragmentDoc } from '../../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { SubAbrAbrechnungskreisListFieldsFragmentDoc } from './SubAbrechnungEigentuemerFragements.types';
import { AbrInfoAbrechnungskreisFieldsFragmentDoc } from '../../gql/AbrechnungFragments.types';
import { KontoFieldsFragmentDoc } from '../../../Kontenplan/gql/KontenplanFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubAbrechnungEigentuemerQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  subAbrechnungId: Types.Scalars['ID']['input'];
  subAbrechnungEigentuemerId: Types.Scalars['ID']['input'];
}>;

export type SubAbrechnungEigentuemerQuery = {
  getSubAbrechnungEigentuemer: {
    data: {
      abrechnungszeitraumBis: string;
      abrechnungszeitraumVon: string;
      bezeichnung: string;
      buchungskreisId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileId?: string | null;
      guthaben: boolean;
      saldo: number;
      saldovortrag: number;
      subAbrechnungEigentuemerId: string;
      updatedByMitarbeiterId?: string | null;
      abrechnungsdefinitionType: { text: string; value: Types.AbrechnungsdefinitionType };
      eigentuemer: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      objekt: {
        countryCodeIso2: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fotoFileId?: string | null;
        fotoType?: Types.FotoType | null;
        kurzBezeichnung: string;
        objektId: string;
        rechnungsAusstellerBezeichnung?: string | null;
        rechnungsAusstellerChangeable?: boolean | null;
        subAdministration: boolean;
        sumNutzflaeche: number;
        sumNutzwert: number;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        verwaltungBis?: string | null;
        verwaltungSeit: string;
        verwaltungsartChangeable?: boolean | null;
        addressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        objektSummeAnteile?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          kommentar?: string | null;
          lastUpdateTs: string;
          objektSummeAnteileId: string;
          summeAnteile: number;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            kommentar?: string | null;
            lastUpdateTs: string;
            objektSummeAnteileId: string;
            summeAnteile: number;
            validFrom: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        sachbearbeiter?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          fileStorageInitialized: boolean;
          firmendatenId: string;
          firstname: string;
          funktionsModulList: Array<string>;
          lastname: string;
          mitarbeiterId: string;
          title?: string | null;
          titleTrailing?: string | null;
          username: string;
          contactPerson?: {
            contactPersonId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firstname: string;
            lastname: string;
            tagList: Array<string>;
            title?: string | null;
            titleTrailing?: string | null;
            contactList: Array<{
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            rechtstraeger?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rolleList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            name: string;
            rolleId: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
          user?: {
            email?: string | null;
            emailUnverified?: string | null;
            firstname?: string | null;
            lastname?: string | null;
            role: string;
            username: string;
            status: { text: string; value: string };
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      offenePosten?: {
        summe: number;
        offenePostenList: Array<{
          dueDate: string;
          fibuKontoId: string;
          fibuKontonummer: string;
          letzteBuchungAm?: string | null;
          offenePostenId: string;
          offenerBetrag: number;
          bestandseinheitList: Array<{
            aktivSeit?: string | null;
            bauteil?: string | null;
            bestandseinheitId: string;
            bezeichnung: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            geschoss?: string | null;
            inaktivSeit?: string | null;
            nutzflaeche?: number | null;
            nutzwert?: number | null;
            stiege?: string | null;
            tagList: Array<string>;
            address: {
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              tagList: Array<string>;
              type: Types.AddressType;
              zipCode?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
            optionSteuerpflicht?: {
              beOptionSteuerpflichtId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              optionSteuerpflicht: boolean;
              validFrom: string;
              historicizedList?: Array<{
                beOptionSteuerpflichtId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                optionSteuerpflicht: boolean;
                validFrom: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }> | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fibuKontoCreatable: boolean;
            kundennummer: string;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            bankDetailsList: Array<{
              accountHolder: string;
              accountLimit?: number | null;
              assignedHeVertragZahlungsregelMap: any;
              assignedObjektMap: any;
              bankDetailsId: string;
              bankname: string;
              bic: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              ebicsCreditTransferUserList: Array<string>;
              ebicsDirectDebitUserList: Array<string>;
              fibuKontoId?: string | null;
              fibuKontonummer?: string | null;
              iban: string;
              updatedByMitarbeiterId?: string | null;
              fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
              fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
              sepaMandat?: {
                fileId?: string | null;
                fileName?: string | null;
                sepaMandatId: string;
                sepaMandatReference: string;
                signatureDate?: string | null;
                firmendaten?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firmendatenId: string;
                  kundenSystemId: string;
                  kurzBezeichnung: string;
                  name1: string;
                  name2?: string | null;
                  name3?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechnungsAussteller?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            personenbezugList: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              personenbezugId: string;
              tagList: Array<string>;
              person: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechtstraegerSteuerpflicht?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              historicizedList?: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ message: string; type: string }>;
              }> | null;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
            sprache: { text: string; value: Types.Sprache };
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { value: Types.RechtstraegerType; text: string };
            vertragSummary?: {
              heVertragCount: number;
              heVertragVerwaltungCount: number;
              mietVertragCount: number;
              mietVertragVerwaltungCount: number;
              weSubverwaltungCount: number;
              weVertragCount: number;
              weVertragVerwaltungCount: number;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
        }>;
      } | null;
      status: { text: string; value: Types.SubAbrechnungEigentuemerStatus };
      subAbrechnungEigentuemerBestandseinheitList: Array<{
        bestandseinheitErgebnis: number;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        updatedByMitarbeiterId?: string | null;
        ausgaben?: {
          summe: number;
          abrechnungskreisList: Array<{
            abrechnungskreisId: string;
            bezeichnung: string;
            subAbrechnungsdefinitionId: string;
            subAbrechnungsdefinitionVersionId: string;
            summe: number;
            abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
            kontoList: Array<{
              bezeichnung: string;
              kontoId: string;
              summe: number;
              amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
              buchungszeileList: Array<{
                auftragId?: string | null;
                belegDatum?: string | null;
                belegFileId?: string | null;
                belegId: string;
                belegnummer?: string | null;
                betrag: number;
                buchungIdList: Array<string>;
                dueDate?: string | null;
                standingInvoicePartialAmountId?: string | null;
                text: string;
                umbuchung?: boolean | null;
                umgebucht?: boolean | null;
                ustVomAufwandSteuersatz?: number | null;
                buchungskreisRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sollHaben: { text: string; value: Types.SollHaben };
                vertragspartner?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }>;
            }>;
          }>;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
        } | null;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        eigentuemerverrechnung?: {
          summe: number;
          abrechnungskreisList: Array<{
            abrechnungskreisId: string;
            bezeichnung: string;
            subAbrechnungsdefinitionId: string;
            subAbrechnungsdefinitionVersionId: string;
            summe: number;
            abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
            kontoList: Array<{
              bezeichnung: string;
              kontoId: string;
              summe: number;
              amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
              buchungszeileList: Array<{
                auftragId?: string | null;
                belegDatum?: string | null;
                belegFileId?: string | null;
                belegId: string;
                belegnummer?: string | null;
                betrag: number;
                buchungIdList: Array<string>;
                dueDate?: string | null;
                standingInvoicePartialAmountId?: string | null;
                text: string;
                umbuchung?: boolean | null;
                umgebucht?: boolean | null;
                ustVomAufwandSteuersatz?: number | null;
                buchungskreisRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sollHaben: { text: string; value: Types.SollHaben };
                vertragspartner?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }>;
            }>;
          }>;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
        } | null;
        einnahmen?: {
          summe: number;
          abrechnungskreisList: Array<{
            abrechnungskreisId: string;
            bezeichnung: string;
            subAbrechnungsdefinitionId: string;
            subAbrechnungsdefinitionVersionId: string;
            summe: number;
            abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
            kontoList: Array<{
              bezeichnung: string;
              kontoId: string;
              summe: number;
              amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
              buchungszeileList: Array<{
                auftragId?: string | null;
                belegDatum?: string | null;
                belegFileId?: string | null;
                belegId: string;
                belegnummer?: string | null;
                betrag: number;
                buchungIdList: Array<string>;
                dueDate?: string | null;
                standingInvoicePartialAmountId?: string | null;
                text: string;
                umbuchung?: boolean | null;
                umgebucht?: boolean | null;
                ustVomAufwandSteuersatz?: number | null;
                buchungskreisRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sollHaben: { text: string; value: Types.SollHaben };
                vertragspartner?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }>;
            }>;
          }>;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
        } | null;
        vorsteuerkuerzung?: {
          summe: number;
          abrechnungskreisList: Array<{
            abrechnungskreisId: string;
            bezeichnung: string;
            subAbrechnungsdefinitionId: string;
            subAbrechnungsdefinitionVersionId: string;
            summe: number;
            abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
            kontoList: Array<{
              bezeichnung: string;
              kontoId: string;
              summe: number;
              amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
              buchungszeileList: Array<{
                auftragId?: string | null;
                belegDatum?: string | null;
                belegFileId?: string | null;
                belegId: string;
                belegnummer?: string | null;
                betrag: number;
                buchungIdList: Array<string>;
                dueDate?: string | null;
                standingInvoicePartialAmountId?: string | null;
                text: string;
                umbuchung?: boolean | null;
                umgebucht?: boolean | null;
                ustVomAufwandSteuersatz?: number | null;
                buchungskreisRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sollHaben: { text: string; value: Types.SollHaben };
                vertragspartner?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }>;
            }>;
          }>;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      subAbrechnungEigentuemerObjekt: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        fileId?: string | null;
        subAbrechnungEigentuemerObjektId: string;
        updatedByMitarbeiterId?: string | null;
        ausgaben?: {
          summe: number;
          abrechnungskreisList: Array<{
            abrechnungskreisId: string;
            bezeichnung: string;
            subAbrechnungsdefinitionId: string;
            subAbrechnungsdefinitionVersionId: string;
            summe: number;
            abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
            kontoList: Array<{
              bezeichnung: string;
              kontoId: string;
              summe: number;
              amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
              buchungszeileList: Array<{
                auftragId?: string | null;
                belegDatum?: string | null;
                belegFileId?: string | null;
                belegId: string;
                belegnummer?: string | null;
                betrag: number;
                buchungIdList: Array<string>;
                dueDate?: string | null;
                standingInvoicePartialAmountId?: string | null;
                text: string;
                umbuchung?: boolean | null;
                umgebucht?: boolean | null;
                ustVomAufwandSteuersatz?: number | null;
                buchungskreisRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sollHaben: { text: string; value: Types.SollHaben };
                vertragspartner?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }>;
            }>;
          }>;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
        } | null;
        eigentuemerverrechnung?: {
          summe: number;
          abrechnungskreisList: Array<{
            abrechnungskreisId: string;
            bezeichnung: string;
            subAbrechnungsdefinitionId: string;
            subAbrechnungsdefinitionVersionId: string;
            summe: number;
            abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
            kontoList: Array<{
              bezeichnung: string;
              kontoId: string;
              summe: number;
              amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
              buchungszeileList: Array<{
                auftragId?: string | null;
                belegDatum?: string | null;
                belegFileId?: string | null;
                belegId: string;
                belegnummer?: string | null;
                betrag: number;
                buchungIdList: Array<string>;
                dueDate?: string | null;
                standingInvoicePartialAmountId?: string | null;
                text: string;
                umbuchung?: boolean | null;
                umgebucht?: boolean | null;
                ustVomAufwandSteuersatz?: number | null;
                buchungskreisRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sollHaben: { text: string; value: Types.SollHaben };
                vertragspartner?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }>;
            }>;
          }>;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
        } | null;
        einnahmen?: {
          summe: number;
          abrechnungskreisList: Array<{
            abrechnungskreisId: string;
            bezeichnung: string;
            subAbrechnungsdefinitionId: string;
            subAbrechnungsdefinitionVersionId: string;
            summe: number;
            abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
            kontoList: Array<{
              bezeichnung: string;
              kontoId: string;
              summe: number;
              amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
              buchungszeileList: Array<{
                auftragId?: string | null;
                belegDatum?: string | null;
                belegFileId?: string | null;
                belegId: string;
                belegnummer?: string | null;
                betrag: number;
                buchungIdList: Array<string>;
                dueDate?: string | null;
                standingInvoicePartialAmountId?: string | null;
                text: string;
                umbuchung?: boolean | null;
                umgebucht?: boolean | null;
                ustVomAufwandSteuersatz?: number | null;
                buchungskreisRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sollHaben: { text: string; value: Types.SollHaben };
                vertragspartner?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }>;
            }>;
          }>;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
        } | null;
        infoAbrechnungskreisList: Array<{
          abrechnungsdefinitionId: string;
          abrechnungsdefinitionVersionId: string;
          abrechnungskreisId: string;
          bezeichnung: string;
          saldo: number;
          sumAufwand: number;
          vorsaldo: number;
          aufwand: {
            summe: number;
            kontoList: Array<{
              bezeichnung: string;
              kontoId: string;
              summe: number;
              buchungszeileList: Array<{
                auftragId?: string | null;
                belegDatum?: string | null;
                belegFileId?: string | null;
                belegId: string;
                belegnummer?: string | null;
                betrag: number;
                buchungIdList: Array<string>;
                dueDate?: string | null;
                standingInvoicePartialAmountId?: string | null;
                text: string;
                umbuchung?: boolean | null;
                umgebucht?: boolean | null;
                buchungskreisRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sollHaben: { text: string; value: Types.SollHaben };
                vertragspartner?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }>;
            }>;
          };
          erloes: {
            summe: number;
            kontoList: Array<{
              bezeichnung: string;
              kontoId: string;
              summe: number;
              buchungszeileList: Array<{
                auftragId?: string | null;
                belegDatum?: string | null;
                belegFileId?: string | null;
                belegId: string;
                belegnummer?: string | null;
                betrag: number;
                buchungIdList: Array<string>;
                dueDate?: string | null;
                standingInvoicePartialAmountId?: string | null;
                text: string;
                umbuchung?: boolean | null;
                umgebucht?: boolean | null;
                buchungskreisRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sollHaben: { text: string; value: Types.SollHaben };
                vertragspartner?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }>;
            }>;
          };
        }>;
        vorsteuerkuerzung?: {
          summe: number;
          abrechnungskreisList: Array<{
            abrechnungskreisId: string;
            bezeichnung: string;
            subAbrechnungsdefinitionId: string;
            subAbrechnungsdefinitionVersionId: string;
            summe: number;
            abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
            kontoList: Array<{
              bezeichnung: string;
              kontoId: string;
              summe: number;
              amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
              buchungszeileList: Array<{
                auftragId?: string | null;
                belegDatum?: string | null;
                belegFileId?: string | null;
                belegId: string;
                belegnummer?: string | null;
                betrag: number;
                buchungIdList: Array<string>;
                dueDate?: string | null;
                standingInvoicePartialAmountId?: string | null;
                text: string;
                umbuchung?: boolean | null;
                umgebucht?: boolean | null;
                ustVomAufwandSteuersatz?: number | null;
                buchungskreisRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sollHaben: { text: string; value: Types.SollHaben };
                vertragspartner?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }>;
            }>;
          }>;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      zahlungsrueckstandList: Array<{
        letzteBuchungAm?: string | null;
        offenerBetrag: number;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        konto: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          haupt: boolean;
          kontoId: string;
          kontoTemplateId?: string | null;
          nummer: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          klasse: { text: string; value: Types.KontoKlasse };
          status: { description?: string | null; text: string; value: Types.KontoStatus };
          type: { text: string; value: Types.KontoType };
          verwendung?: { text: string; value: Types.KontoVerwendung } | null;
        };
        rechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const SubAbrechnungEigentuemerDocument = gql`
  query SubAbrechnungEigentuemer($objektId: ID!, $subAbrechnungId: ID!, $subAbrechnungEigentuemerId: ID!) {
    getSubAbrechnungEigentuemer(objektId: $objektId, subAbrechnungId: $subAbrechnungId, subAbrechnungEigentuemerId: $subAbrechnungEigentuemerId) {
      data {
        abrechnungsdefinitionType {
          text
          value
        }
        abrechnungszeitraumBis
        abrechnungszeitraumVon
        bezeichnung
        buchungskreisId
        createTs
        createdBy
        createdByMitarbeiterId
        eigentuemer {
          ...RechtstraegerFields
        }
        fileId
        guthaben
        objekt {
          ...Objekt
        }
        offenePosten {
          offenePostenList {
            bestandseinheitList {
              ...BestandseinheitFields
            }
            dueDate
            fibuKontoId
            fibuKontonummer
            letzteBuchungAm
            offenePostenId
            offenerBetrag
            vertragspartner {
              ...RechtstraegerFields
            }
          }
          summe
        }
        saldo
        saldovortrag
        status {
          text
          value
        }
        subAbrechnungEigentuemerBestandseinheitList {
          ausgaben {
            ...SubAbrAbrechnungskreisListFields
          }
          bestandseinheit {
            ...BestandseinheitBaseFields
          }
          bestandseinheitErgebnis
          createTs
          createdBy
          createdByMitarbeiterId
          eigentuemerverrechnung {
            ...SubAbrAbrechnungskreisListFields
          }
          einnahmen {
            ...SubAbrAbrechnungskreisListFields
          }
          updatedByMitarbeiterId
          vorsteuerkuerzung {
            ...SubAbrAbrechnungskreisListFields
          }
          warningList {
            attribute
            message
            type
          }
        }
        subAbrechnungEigentuemerId
        subAbrechnungEigentuemerObjekt {
          ausgaben {
            ...SubAbrAbrechnungskreisListFields
          }
          createTs
          createdBy
          createdByMitarbeiterId
          eigentuemerverrechnung {
            ...SubAbrAbrechnungskreisListFields
          }
          einnahmen {
            ...SubAbrAbrechnungskreisListFields
          }
          fileId
          infoAbrechnungskreisList {
            ...AbrInfoAbrechnungskreisFields
          }
          subAbrechnungEigentuemerObjektId
          updatedByMitarbeiterId
          vorsteuerkuerzung {
            ...SubAbrAbrechnungskreisListFields
          }
          warningList {
            attribute
            message
            type
          }
        }
        updatedByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
        zahlungsrueckstandList {
          bestandseinheit {
            ...BestandseinheitBaseFields
          }
          konto {
            ...KontoFields
          }
          letzteBuchungAm
          offenerBetrag
          rechtstraeger {
            ...RechtstraegerBaseFields
          }
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerFieldsFragmentDoc}
  ${ObjektFragmentDoc}
  ${BestandseinheitFieldsFragmentDoc}
  ${SubAbrAbrechnungskreisListFieldsFragmentDoc}
  ${BestandseinheitBaseFieldsFragmentDoc}
  ${AbrInfoAbrechnungskreisFieldsFragmentDoc}
  ${KontoFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useSubAbrechnungEigentuemerQuery(
  baseOptions: Apollo.QueryHookOptions<SubAbrechnungEigentuemerQuery, SubAbrechnungEigentuemerQueryVariables> &
    ({ variables: SubAbrechnungEigentuemerQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubAbrechnungEigentuemerQuery, SubAbrechnungEigentuemerQueryVariables>(SubAbrechnungEigentuemerDocument, options);
}
export function useSubAbrechnungEigentuemerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubAbrechnungEigentuemerQuery, SubAbrechnungEigentuemerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubAbrechnungEigentuemerQuery, SubAbrechnungEigentuemerQueryVariables>(SubAbrechnungEigentuemerDocument, options);
}
export function useSubAbrechnungEigentuemerSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubAbrechnungEigentuemerQuery, SubAbrechnungEigentuemerQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubAbrechnungEigentuemerQuery, SubAbrechnungEigentuemerQueryVariables>(SubAbrechnungEigentuemerDocument, options);
}
export type SubAbrechnungEigentuemerQueryHookResult = ReturnType<typeof useSubAbrechnungEigentuemerQuery>;
export type SubAbrechnungEigentuemerLazyQueryHookResult = ReturnType<typeof useSubAbrechnungEigentuemerLazyQuery>;
export type SubAbrechnungEigentuemerSuspenseQueryHookResult = ReturnType<typeof useSubAbrechnungEigentuemerSuspenseQuery>;
export type SubAbrechnungEigentuemerQueryResult = Apollo.QueryResult<SubAbrechnungEigentuemerQuery, SubAbrechnungEigentuemerQueryVariables>;
