import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import DocumentListing from '../../features/Document/Listing/DocumentListing';

const DocumentPage = () => {
  return (
    <>
      <Helmet>
        <title>Dokumente</title>
      </Helmet>
      <PageLayout>
        <DocumentListing />
      </PageLayout>
    </>
  );
};

export default DocumentPage;
