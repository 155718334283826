import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { TopAbrDefGeneralSettingsFormValues } from '../../shared/Templates/shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import TopAbrDefGeneralSettings from '../../shared/Templates/shared/BkOrWe/TopAbrDefGeneralSettingsForm/TopAbrDefGeneralSettings';
import AbrDefVersionViewTemplate from './AbrDefVersionViewTemplate';
import TopAbrechnungTemplate from '../../../../pages/PDFTemplates/templates/topAbrechnung/TopAbrechnungTemplate';
import {
  createTopAbrechnungTemplatePDFData,
  mapTextbausteinToTopAbrechnungSelectableSection,
} from '../../shared/Templates/WeTemplates/weAbrDefTemplateFormTopAbrMapper';
import { BelegTextePlatzhalter } from '../../../Belegtext/Form/belegTexteFormTypes';
import { AbrechnungsdefinitionType, BelegTexte } from '../../../../types';

type Props = {
  topAbrDefTemplateValues: TextbausteinFormValues[];
  topAbrDefGeneralSettingsValues: TopAbrDefGeneralSettingsFormValues;
  belegTexte?: BelegTexte | undefined;
  belegTextePlatzhalter?: BelegTextePlatzhalter | undefined;
  firmendatenId?: string | undefined;
  abrDefType: AbrechnungsdefinitionType;
};

const BkOrWeTopAbrDefVersionView: FC<Props> = ({
  topAbrDefTemplateValues,
  topAbrDefGeneralSettingsValues,
  belegTexte,
  belegTextePlatzhalter,
  firmendatenId,
  abrDefType,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: <TopAbrDefGeneralSettings abrDefType={abrDefType} values={topAbrDefGeneralSettingsValues} />,
    },
    {
      key: '2',
      label: 'Texte',
      children: (
        <AbrDefVersionViewTemplate
          values={topAbrDefTemplateValues}
          renderPreview={({ selectedTextbaustein }) => (
            <TopAbrechnungTemplate
              data={createTopAbrechnungTemplatePDFData(
                topAbrDefTemplateValues,
                topAbrDefGeneralSettingsValues,
                selectedTextbaustein,
                belegTexte,
                belegTextePlatzhalter,
                firmendatenId
              )}
              selectedTextbaustein={mapTextbausteinToTopAbrechnungSelectableSection(selectedTextbaustein)}
            />
          )}
        />
      ),
    },
  ];

  return <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />;
};

export default BkOrWeTopAbrDefVersionView;
