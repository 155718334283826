import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../styles/pdfStyles';
import { VRechnungSummary } from '../vorschreibung-types';
import LabelValue from '../../shared/LabelValue';

const VTSummaryBlock: FC<{ data: VRechnungSummary }> = ({ data }) => (
  <View style={[pdfStyles.column, { marginTop: '10mm' }]}>
    <LabelValue
      valueTuple={data.summeNetto}
      labelStyle={{ width: '70%', fontSize: 9 }}
      valueStyle={{ width: '30%', textAlign: 'right', fontSize: 9 }}
    />
    <LabelValue
      valueTuple={data.summeUst}
      labelStyle={{ width: '70%', fontSize: 9 }}
      valueStyle={{ width: '30%', textAlign: 'right', fontSize: 9 }}
    />
    <LabelValue
      valueTuple={data.summeBrutto}
      viewStyle={{ fontWeight: 'bold' }}
      labelStyle={{ width: '70%' }}
      valueStyle={{ width: '30%', textAlign: 'right' }}
    />
  </View>
);

export default VTSummaryBlock;
