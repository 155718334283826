import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useNavigate } from 'react-router-dom';
import FormButtons from '../../../../components/Button/FormButtons';
import { filterFormFields, FiltersFormValues } from './formMapper';
import FileUploadView from '../../../../components/FileUpload/FileUploadView';
import IncomingInvoiceUploadSelect from './IncomingInvoiceUploadSelect';
import { useAuth } from '../../../../shared/Auth/authContext';
import { handleSaveFile } from './handleSubmit';
import { incomingInvoiceUploadFormValidationSchema } from './incomingInvoiceUploadFormValidationSchema';
import ErrorMessagePanel from '../../../../components/Error/ErrorMessagePanel';
import { generatePathToToIncomingInvoiceCreateInvoicePage } from '../../incomingInvoiceUriPaths';

type Props = {
  onCancel: () => void;
};

const IncomingInvoiceFormUpload: FC<Props> = ({ onCancel }) => {
  const navigate = useNavigate();

  const { mitarbeiter } = useAuth();
  const mitarbeiterId = mitarbeiter?.mitarbeiterId;

  return (
    <Formik<FiltersFormValues>
      initialValues={{}}
      validationSchema={incomingInvoiceUploadFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (values.incomingInvoice === null) {
          setSubmitting(false);
        }

        const onSuccess = (fileId: string) => navigate(generatePathToToIncomingInvoiceCreateInvoicePage(fileId));
        if (values.incomingInvoice?.fileId) {
          onSuccess(values.incomingInvoice.fileId);
        } else if (mitarbeiterId && values.incomingInvoice?.file) {
          handleSaveFile(mitarbeiterId, values.incomingInvoice.file, onSuccess, setSubmitting);
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          {formikProps.values.incomingInvoice ? (
            <FileUploadView
              filename={formikProps.values.incomingInvoice.filename}
              onDelete={() => formikProps.setFieldValue(filterFormFields.incomingInvoice, null)}
            />
          ) : (
            <IncomingInvoiceUploadSelect formikProps={formikProps} />
          )}
          {formikProps.errors.incomingInvoice && <ErrorMessagePanel error={formikProps.errors.incomingInvoice} />}
          <FormButtons onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} okText="Weiter" updateMode onOk={formikProps.submitForm} />
        </Form>
      )}
    </Formik>
  );
};

export default IncomingInvoiceFormUpload;
