import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { BelegTextePlatzhalter } from '../../../Belegtext/Form/belegTexteFormTypes';

export const findAndReplacePlatzhalterWithTextbaustein = (
  text: string,
  belegTextePlatzhalter: BelegTextePlatzhalter,
  textbausteinValue: TextbausteinFormValues
) => {
  let newValue = text;

  textbausteinValue.platzhalterList.forEach((platzhalter) => {
    const hasPlatzhalter = newValue.includes(platzhalter.platzhalter);
    const defaultPlatzhalter = '${' + platzhalter.platzhalter + '}';

    const getValueForReplacingPlatzhalter = () => {
      switch (platzhalter.platzhalter) {
        case 'Rechnungsaussteller.Hauptadresse':
        case 'Hausverwaltung.Hauptadresse':
          return belegTextePlatzhalter.hauptAddress ?? defaultPlatzhalter;
        case 'Rechnungsaussteller.Uid':
        case 'Hausverwaltung.Uid':
          return belegTextePlatzhalter.vatIdentificationNumber ?? defaultPlatzhalter;
        case 'Rechnungsaussteller.Firmenbuchnummer':
        case 'Hausverwaltung.Firmenbuchnummer':
          return belegTextePlatzhalter.companyRegistrationNumber ?? defaultPlatzhalter;
        case 'Rechnungsaussteller.Telefonnummer':
        case 'Hausverwaltung.Telefonnummer':
          return belegTextePlatzhalter.phoneNumber ?? defaultPlatzhalter;
        case 'Rechnungsaussteller.E-Mail':
        case 'Hausverwaltung.E-Mail':
          return belegTextePlatzhalter.email ?? defaultPlatzhalter;
        default:
          return platzhalter.platzhalter;
      }
    };

    if (hasPlatzhalter) {
      newValue = newValue.replaceAll(defaultPlatzhalter, getValueForReplacingPlatzhalter());
    }
  });
  return newValue;
};
