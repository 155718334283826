import React, { FC } from 'react';
import { Formik, FormikProps } from 'formik';
import { Spacer } from '../../../../components/Grid';
import { FormikFieldHookProps } from '../../../../helpers/formikHelper';
import { AbrechnungsdefinitionType } from '../../../../types';
import { AbrDefAbrKreisListingFormValues } from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import {
  bkAndWeAbrDefAbrKreisListFormValidationSchema,
  heAndSubAbrDefAbrKreisListFormValidationSchema,
} from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListFormValidationSchema';
import AbrDefTemplateAbrKreisListingFormContent from './AbrDefTemplateAbrKreisListingFormContent';

type Props = {
  abrDefType: AbrechnungsdefinitionType;
  values: AbrDefAbrKreisListingFormValues;
  onSubmit: (formValues: AbrDefAbrKreisListingFormValues) => void;
  onBack: () => void;
  backBtnText?: string;
  formButtons?: (formikProps: FormikProps<AbrDefAbrKreisListingFormValues>) => React.ReactNode;
  onValuesChange?: (isDirty: boolean) => void;
  onAbrechnungskreisChange?: (newAbrechnungskreisId: string, fieldProps: FormikFieldHookProps<string>, onSuccess: () => void) => void;
  onZuweisungDelete?: (abrechnungskreisId: string, onDelete: () => void) => void;
  fieldHelp?: string | null;
};

const AbrDefTemplateAbrKreisListingForm: FC<Props> = ({
  abrDefType,
  onValuesChange,
  values,
  onSubmit,
  onBack,
  backBtnText,
  formButtons,
  onAbrechnungskreisChange,
  onZuweisungDelete,
  fieldHelp,
}) => {
  const selectedValidationSchema = (abrDefType: AbrechnungsdefinitionType) => {
    switch (abrDefType) {
      case AbrechnungsdefinitionType.HeAbrechnung:
      case AbrechnungsdefinitionType.SubAbrechnung:
        return heAndSubAbrDefAbrKreisListFormValidationSchema;
      case AbrechnungsdefinitionType.BkAbrechnung:
      default:
        return bkAndWeAbrDefAbrKreisListFormValidationSchema;
    }
  };

  return (
    <>
      <Spacer height={16} />
      <Formik<AbrDefAbrKreisListingFormValues>
        initialValues={values}
        enableReinitialize // to reinitialize dirty as well and get tabs disablement to work
        validationSchema={selectedValidationSchema(abrDefType)}
        onSubmit={(formValues) => {
          onSubmit(formValues);
        }}
      >
        {(formikProps) => (
          <AbrDefTemplateAbrKreisListingFormContent
            abrDefType={abrDefType}
            formikProps={formikProps}
            onBack={onBack}
            backBtnText={backBtnText}
            formButtons={formButtons}
            onValuesChange={onValuesChange}
            onAbrechnungskreisChange={onAbrechnungskreisChange}
            onZuweisungDelete={onZuweisungDelete}
            fieldHelp={fieldHelp}
          />
        )}
      </Formik>
    </>
  );
};

export default AbrDefTemplateAbrKreisListingForm;
