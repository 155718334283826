import React, { FC } from 'react';
import { Document, Page, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';
import { Vorschreibung } from './vorschreibung-types';
import Header from '../shared/Header';
import VTObjektList from './components/ObjektListBlock/VTObjektList';
import VTSummaryBlock from './components/VTSummaryBlock';
import VTUstTableBlock from './components/UstTableBlock/VTUstTableBlock';
import VTZahlenart from './components/Placeholders/VTZahlenart';
import VTVerrechnungsweise from './components/Placeholders/VTVerrechnungsweise';
import VTUstRegelset from './components/Placeholders/VTUstRegelset';
import VTTitleText from './components/Placeholders/VTTitleText';
import VTRechnungAusstellerBlock from './components/RechnungBasicInfo/VTRechnungAusstellerBlock';
import VTRechnungEmpfaengerBlock from './components/RechnungBasicInfo/VTRechnungEmpfaengerBlock';
import VTAddressAndAbsenderAndBelegDetails from './components/RechnungBasicInfo/VTAddressAndAbsenderAndBelegDetails';
import { isNotNil } from '../helpers/assertionHelper';
import Footer from '../shared/Footer';
import Schlusstext from '../shared/Schlusstext';
import Einleitungstext from '../shared/Einleitungstext';

type VorschreibungTemplateProps = {
  data: Vorschreibung;
  isInEditMode?: boolean;
  selectedTextbaustein?: VorschreibungSelectableSection;
};

export type VorschreibungSelectableSection = 'TITELTEXT' | 'EINLEITUNGSTEXT' | 'SCHLUSSTEXT' | 'HEADER' | 'FOOTER';

const VorschreibungTemplate: FC<VorschreibungTemplateProps> = ({ data, isInEditMode, selectedTextbaustein }) => (
  <Document>
    <Page size="A4" style={[pdfStyles.body, { paddingBottom: data.footer?.value ? '32mm' : pdfStyles.body.paddingBottom }]}>
      <View>
        {/* header */}
        <Header header={data.header} />
      </View>

      {/*  Rechnungsadresse + Beleg Nr., Date + Address */}
      <VTAddressAndAbsenderAndBelegDetails
        belegnummer={data.belegnummer}
        belegOrtUndDatum={data.belegOrtUndDatum}
        billingAddress={data.billingAddress}
        absender={data.absender}
      />

      {/*  placeholder TitleText with Textbaustein */}
      {isNotNil(data.titleText) ? (
        <VTTitleText titleText={data.titleText} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      {/*  Rechnungsaussteller: name + address + UID-Nr */}
      <VTRechnungAusstellerBlock rechnungAussteller={data.rechnungAussteller} />

      {/*  Rechnungsempfänger: name + address + Kunden-Nr. + UID */}
      <VTRechnungEmpfaengerBlock rechnungEmpfaenger={data.rechnungEmpfaenger} />

      {/*  placeholder Einleitungstext with Textbaustein */}
      {isNotNil(data.einleitungstext) ? (
        <Einleitungstext text={data.einleitungstext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      {/*  Table of Objekts with details */}
      <VTObjektList objektList={data.objektList} vPosListHeader={data.vorschreibungspositionListHeader} />

      {/*  Vorschreibung Summary */}
      <VTSummaryBlock data={data.rechnungSummary} />

      {/*  Table of Ust */}
      {data.ustDetails ? <VTUstTableBlock data={data.ustDetails} /> : null}

      {/*  placeholder Zahlenart text */}
      {data.zahlart ? <VTZahlenart zahlart={data.zahlart} /> : null}
      {/*  placeholder Verrechnungsweise text */}
      {data.verrechnungsweiseText ? <VTVerrechnungsweise verrechnungsweiseText={data.verrechnungsweiseText} /> : null}
      {/*  placeholder Ust.Regelset text */}
      {data.ustRegelsetText ? <VTUstRegelset ustRegelsetText={data.ustRegelsetText} /> : null}
      {/*  placeholder Schlusstext with Textbaustein */}
      {isNotNil(data.schlusstext) ? (
        <Schlusstext text={data.schlusstext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      {data.footer?.value && <Footer footer={data.footer} />}
    </Page>
  </Document>
);

export default VorschreibungTemplate;
