import { FormikProps } from 'formik';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateHook';
import { getKurzBezeichnungVorschlag, KurzBezeichnungVorschlagConfigProps, toValuesForUpdateCondition } from './kurzBezeichnungVorschlagHelpers';

export const useKurzBezeichnungVorschlag = <T>(formikProps: FormikProps<T>, config?: KurzBezeichnungVorschlagConfigProps<T>) => {
  const fieldsToBeDebounced = config?.getFieldsToBeDebounced ? config.getFieldsToBeDebounced(formikProps) : {};
  const fieldsNotToBeDebounced = config?.getFieldsNotToBeDebounced ? config.getFieldsNotToBeDebounced(formikProps) : {};

  const kurzBezeichnungVorschlagFields = {
    ...fieldsToBeDebounced,
    ...fieldsNotToBeDebounced,
  };

  const kurzBezeichnungVorschlagFn = config?.getKurzBezeichnungVorschlagFn ? config.getKurzBezeichnungVorschlagFn : () => Promise.resolve('');

  useDidUpdateEffect(
    getKurzBezeichnungVorschlag(kurzBezeichnungVorschlagFn, kurzBezeichnungVorschlagFields, formikProps),
    toValuesForUpdateCondition(fieldsToBeDebounced, fieldsNotToBeDebounced)
  );
};
