import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { OrderObjektFragmentDoc, ObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { OrderRechtstraegerFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { OrderKontoFragmentDoc } from '../../../Kontenplan/gql/KontenplanFragments.types';
export type OrderPaymentFieldsFragment = {
  auftragId?: string | null;
  belegFileId?: string | null;
  belegId?: string | null;
  belegdatum?: string | null;
  belegnummer?: string | null;
  amount: number;
  buchungsdatum?: string | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deckungspruefungErrorText?: string | null;
  heVertragId?: string | null;
  noted: boolean;
  paymentDate: string;
  paymentId: string;
  paymentProposal?: string | null;
  paymentProposalId?: string | null;
  payeeAccountHolder: string;
  payeeIban: string;
  paymentReferenceText?: string | null;
  rechnungsAusstellerAccountHolder: string;
  rechnungsAusstellerIban: string;
  sepaCreditorId?: string | null;
  sepaMandatReference?: string | null;
  transactionInstructionId?: string | null;
  updatedByMitarbeiterId?: string | null;
  verwendungszweck?: string | null;
  approvalList: Array<{ mitarbeiterId: string; text: string }>;
  objekt: { kurzBezeichnung: string; objektId: string };
  paymentApprovalStatus?: { text: string; value: Types.PaymentApprovalStatus } | null;
  payee: { kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
  paymentTransactionType?: { text: string; value: Types.PaymentTransactionType } | null;
  rechnungsAussteller: {
    kurzBezeichnung: string;
    kundennummer: string;
    rechtstraegerId: string;
    type: { text: string; value: Types.RechtstraegerType };
  };
  rechnungsAusstellerFibuKonto: { bezeichnung: string; kontoId: string; nummer: string };
  status: { text: string; value: Types.OrderPaymentStatus };
  type: { text: string; value: Types.PaymentType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const OrderPaymentFieldsFragmentDoc = gql`
  fragment OrderPaymentFields on OrderPayment {
    approvalList {
      mitarbeiterId
      text
    }
    auftragId
    belegFileId
    belegId
    belegdatum
    belegnummer
    amount
    buchungsdatum
    createTs
    createdBy
    createdByMitarbeiterId
    deckungspruefungErrorText
    heVertragId
    noted
    objekt {
      ...OrderObjekt
    }
    paymentApprovalStatus {
      text
      value
    }
    paymentDate
    paymentId
    paymentProposal
    paymentProposalId
    payee {
      ...OrderRechtstraeger
    }
    payeeAccountHolder
    payeeIban
    paymentReferenceText
    paymentTransactionType {
      text
      value
    }
    rechnungsAussteller {
      ...OrderRechtstraeger
    }
    rechnungsAusstellerAccountHolder
    rechnungsAusstellerFibuKonto {
      ...OrderKonto
    }
    rechnungsAusstellerIban
    sepaCreditorId
    sepaMandatReference
    status {
      text
      value
    }
    transactionInstructionId
    type {
      text
      value
    }
    updatedByMitarbeiterId
    verwendungszweck
    warningList {
      attribute
      message
      type
    }
  }
  ${OrderObjektFragmentDoc}
  ${OrderRechtstraegerFragmentDoc}
  ${OrderKontoFragmentDoc}
`;
