import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateApplyVertragVposIndexedValueGenerierlaufMutationVariables = Types.Exact<{
  input: Types.ApplyVertragVposIndexedValueGenerierlaufCreateInput;
}>;

export type CreateApplyVertragVposIndexedValueGenerierlaufMutation = {
  createApplyVertragVposIndexedValueGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus; description?: string | null };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RestartApplyVertragVposIndexedValueGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartApplyVertragVposIndexedValueGenerierlaufMutation = {
  actionRestartApplyVertragVposIndexedValueGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus; description?: string | null };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateApplyVertragVposIndexedValueGenerierlaufDocument = gql`
  mutation CreateApplyVertragVposIndexedValueGenerierlauf($input: ApplyVertragVposIndexedValueGenerierlaufCreateInput!) {
    createApplyVertragVposIndexedValueGenerierlauf(input: $input) {
      data {
        countFehler
        countVerarbeitet
        countNichtVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          text
          value
          description
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateApplyVertragVposIndexedValueGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplyVertragVposIndexedValueGenerierlaufMutation,
    CreateApplyVertragVposIndexedValueGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApplyVertragVposIndexedValueGenerierlaufMutation, CreateApplyVertragVposIndexedValueGenerierlaufMutationVariables>(
    CreateApplyVertragVposIndexedValueGenerierlaufDocument,
    options
  );
}
export type CreateApplyVertragVposIndexedValueGenerierlaufMutationHookResult = ReturnType<
  typeof useCreateApplyVertragVposIndexedValueGenerierlaufMutation
>;
export type CreateApplyVertragVposIndexedValueGenerierlaufMutationResult =
  Apollo.MutationResult<CreateApplyVertragVposIndexedValueGenerierlaufMutation>;
export type CreateApplyVertragVposIndexedValueGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateApplyVertragVposIndexedValueGenerierlaufMutation,
  CreateApplyVertragVposIndexedValueGenerierlaufMutationVariables
>;
export const RestartApplyVertragVposIndexedValueGenerierlaufDocument = gql`
  mutation RestartApplyVertragVposIndexedValueGenerierlauf($generierlaufId: ID!) {
    actionRestartApplyVertragVposIndexedValueGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countVerarbeitet
        countNichtVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          text
          value
          description
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartApplyVertragVposIndexedValueGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartApplyVertragVposIndexedValueGenerierlaufMutation,
    RestartApplyVertragVposIndexedValueGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestartApplyVertragVposIndexedValueGenerierlaufMutation,
    RestartApplyVertragVposIndexedValueGenerierlaufMutationVariables
  >(RestartApplyVertragVposIndexedValueGenerierlaufDocument, options);
}
export type RestartApplyVertragVposIndexedValueGenerierlaufMutationHookResult = ReturnType<
  typeof useRestartApplyVertragVposIndexedValueGenerierlaufMutation
>;
export type RestartApplyVertragVposIndexedValueGenerierlaufMutationResult =
  Apollo.MutationResult<RestartApplyVertragVposIndexedValueGenerierlaufMutation>;
export type RestartApplyVertragVposIndexedValueGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartApplyVertragVposIndexedValueGenerierlaufMutation,
  RestartApplyVertragVposIndexedValueGenerierlaufMutationVariables
>;
