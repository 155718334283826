import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import useDebounce from '../../../../hooks/useDebounce';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../../helpers/stringHelper';
import { useFilterAuftragBelegnummerListQuery } from '../../gql/AuftragQueries.types';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  belegnummer?: string | null;
} & SelectProps;

const AuftragBelegnummerFormSelect: FC<Props> = ({ belegnummer, ...restProps }) => {
  const [belegnummerText, setBelegnummerText] = useState<string>(belegnummer ? belegnummer.toString() : '');
  const debouncedText = useDebounce(belegnummerText);

  const { loading, data } = useFilterAuftragBelegnummerListQuery({
    variables: {
      belegnummer: debouncedText as string,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const belegnummerList = data?.getFilterAuftragBelegnummerList.data ?? [];

  return (
    <>
      <Select
        size="middle"
        placeholder=""
        style={{ minWidth: '260px' }}
        id={restProps.name}
        loading={loading}
        allowClear
        showSearch
        filterOption={selectFilterOption}
        onSelect={setBelegnummerText}
        onSearch={(value) => {
          belegnummerText.length === 1 && !value && setBelegnummerText(''); // Checking the length of the belegnummerText makes the search component editable
          value && setBelegnummerText(value);
        }}
        onClear={() => setBelegnummerText('')}
        searchValue={belegnummerText}
        notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
        {...restProps}
      >
        {belegnummerList?.map((belegnummer) => (
          <Select.Option key={belegnummer} value={belegnummer}>
            <DataWithShortenedText text={belegnummer} maxTextLength={40} />
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default AuftragBelegnummerFormSelect;
