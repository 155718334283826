import React, { FC } from 'react';
import { Formik } from 'formik';
import { Skeleton } from 'antd';
import { useAuszifferungListWithOffenePostenQuery } from '../../../gql/AuszifferungQueries.types';
import {
  AuszifferungBlockFormValues,
  mapAuszifferungListToInitialFormValues,
  mapFormValuesToAuszifferungListCreateInput,
} from './auszifferungFormMapper';
import { FibuBuchungType } from '../../../../../types';
import { useCreateAuszifferungListMutation } from '../../../gql/AuszifferungMutations.types';
import { showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import AuszifferungForm from './AuszifferungForm';

type Props = {
  amountFromPersonenkonto: number;
  bookingCorrectionInProgress: boolean | 'done';
  bookingZahlungId: string;
  buchungskreisRechtstraegerId?: string;
  buchungType: FibuBuchungType;
  setHasBookingExistingAuszifferung?: React.Dispatch<React.SetStateAction<boolean>>;
  personenkontoId?: string;
};

const AuszifferungBlock: FC<Props> = ({
  amountFromPersonenkonto,
  bookingCorrectionInProgress,
  bookingZahlungId,
  buchungskreisRechtstraegerId,
  buchungType,
  setHasBookingExistingAuszifferung,
  personenkontoId,
}) => {
  const { data, loading, refetch } = useAuszifferungListWithOffenePostenQuery({ variables: { zahlungId: bookingZahlungId } });
  const auszifferungList = data?.getAuszifferungListWithOffenePosten.data;

  const [runUpdate] = useCreateAuszifferungListMutation({
    onCompleted: () => {
      refetch().then(showSuccessMsgUpdate('Auszifferungen'));
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleUpdate = (values: AuszifferungBlockFormValues) => {
    runUpdate({
      variables: {
        zahlungId: bookingZahlungId,
        input: mapFormValuesToAuszifferungListCreateInput(values.offenePostenListFromBooking),
      },
    });
  };

  if (loading) return <Skeleton active />;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const initialValues = mapAuszifferungListToInitialFormValues(amountFromPersonenkonto, auszifferungList!, bookingZahlungId);

  return (
    <>
      <Formik<AuszifferungBlockFormValues>
        initialValues={initialValues}
        onSubmit={(values) => {
          handleUpdate(values);
        }}
      >
        {(formikProps) => (
          <>
            <AuszifferungForm
              amountFromPersonenkonto={amountFromPersonenkonto}
              bookingCorrectionInProgress={bookingCorrectionInProgress}
              bookingZahlungId={bookingZahlungId}
              buchungskreisRechtstraegerId={buchungskreisRechtstraegerId}
              buchungType={buchungType}
              formikProps={formikProps}
              setHasBookingExistingAuszifferung={setHasBookingExistingAuszifferung}
              personenkontoId={personenkontoId}
            />
            {/*<RouterPrompt when={isFormDirty} />*/}
          </>
        )}
      </Formik>
    </>
  );
};

export default AuszifferungBlock;
