import React from 'react';
import { Link } from 'react-router-dom';
import { TableWithAlignedColsExpandedRowType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { createCells, createRowWithCells, TextWithStyling } from '../../../shared/abrechnungTableHelper';
import { StyledObjAbrKontoEinnahmenDetailsTable } from '../../Styled/ObjObjektAbrechnungTable.style';
import { EuroAmount } from '../../../../../../components/Number';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import BelegnummerWithLinkToPDF from '../BelegnummerWithLinkToPDF';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { generatePathToVorschreibungDetailsPage } from '../../../../../Vorschreibung/vorschreibungUriPaths';
import { ObjAbrKonto } from '../../../../../../types';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';
import SollHabenTag from '../../../../../shared/SollHabenTag';
import { generatePathToBookingDetailsPage } from '../../../../../BookingDetails/bookingDetailsPaths';
import RechtstraegerWithContactsTooltip from '../../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const headerTexts: TextWithStyling[] = [
  { render: 'Text' },
  { render: 'Fälligkeit' },
  { render: 'Belegdatum' },
  { render: 'Beleg-Nr.' },
  { render: 'Vertragspartner' },
  { render: 'Einnahmen' },
  { render: 'Soll/Haben' },
  { render: '' },
];

const einnahmenKontoTableExpandedRow: TableWithAlignedColsExpandedRowType<ObjAbrKonto>[] = [
  {
    header: () => createCells(headerTexts, true),
    table: {
      colSpanSize: [8],
      StyledComponent: StyledObjAbrKontoEinnahmenDetailsTable,
      positionOfButton: 2,
      showCellButton: false,
    },
    body: (konto) => {
      return (
        <>
          {konto.buchungszeileList.map((buchungszeile) => {
            const { text, dueDate, belegDatum, vertragspartner, betrag, auftragId } = buchungszeile;
            const betragLinkToBuchungsmaske = auftragId ? (
              <Link target="_blank" to={generatePathToBookingDetailsPage(buchungszeile.buchungIdList)}>
                <EuroAmount value={betrag} />
              </Link>
            ) : (
              <EuroAmount value={betrag} />
            );

            const textToVorschreibung = auftragId ? (
              <Link target="_blank" to={generatePathToVorschreibungDetailsPage(auftragId)}>
                <DataWithShortenedText text={text} maxTextLength={18} />
              </Link>
            ) : (
              <DataWithShortenedText text={text} maxTextLength={18} />
            );

            const texts: TextWithStyling[] = [
              { render: textToVorschreibung, style: { justifyContent: 'left', textAlign: 'left' } },
              { render: dueDate ? <CustomFormattedDate value={dueDate} /> : <TextForEmptyValue textToShow="minus" /> },
              { render: belegDatum ? <CustomFormattedDate value={belegDatum} /> : <TextForEmptyValue textToShow="minus" /> },
              { render: <BelegnummerWithLinkToPDF buchungszeile={buchungszeile} /> },
              {
                render: vertragspartner ? (
                  <RechtstraegerWithContactsTooltip
                    rechtstraegerId={vertragspartner.rechtstraegerId}
                    rechtstraegerName={vertragspartner.kurzBezeichnung}
                    maxTextLength={18}
                  />
                ) : (
                  <TextForEmptyValue textToShow="minus" />
                ),
              },
              { render: betragLinkToBuchungsmaske },
              { render: <SollHabenTag sollHaben={buchungszeile.sollHaben} /> },
              { render: '' },
            ];
            return createRowWithCells(texts, false);
          })}
        </>
      );
    },
  },
];

export default einnahmenKontoTableExpandedRow;
