import { FC } from 'react';
import ObjektBankDetailsListing from './ObjektBankDetailsListing';
import ObjektBankDetailsButtonAndDrawer from './ObjektBankDetailsButtonAndDrawer';
import { ObjektBankDetails, ResponseDataWarning } from '../../../types';

type Props = {
  objektId: string;
  rechtstraegerId: string;
  objektBankDetailsList: ObjektBankDetails[];
  objektBankDetailsWarningList: ResponseDataWarning[];
  onAction: () => void;
  rechtstraegerFibuKontoCreatable?: boolean;
};

const ObjektRechnungsAusstellerBankDetails: FC<Props> = ({
  objektId,
  rechtstraegerId,
  objektBankDetailsList,
  objektBankDetailsWarningList,
  onAction,
  rechtstraegerFibuKontoCreatable,
}) => {
  const verwendungListTypeInUse = objektBankDetailsList
    .map((objBankDetails) => objBankDetails.verwendungList)
    .flat()
    .map((verwendung) => verwendung.value);

  return (
    <>
      <ObjektBankDetailsButtonAndDrawer
        objektId={objektId}
        onSuccess={onAction}
        rechtstraegerId={rechtstraegerId}
        verwendungListTypeInUse={verwendungListTypeInUse}
        rechtstraegerFibuKontoCreatable={rechtstraegerFibuKontoCreatable}
      />
      <ObjektBankDetailsListing
        objektBankDetailsList={objektBankDetailsList}
        objektId={objektId}
        rechtstraegerId={rechtstraegerId}
        onAction={onAction}
        verwendungListTypeInUse={verwendungListTypeInUse}
        warningList={objektBankDetailsWarningList}
        rechtstraegerFibuKontoCreatable={rechtstraegerFibuKontoCreatable}
      />
    </>
  );
};

export default ObjektRechnungsAusstellerBankDetails;
