import { LocaleAction, LocaleState } from './locale.model';
import { DEFAULT_LOCALE } from './localeMapper';

const initialState: LocaleState = {
  locale: DEFAULT_LOCALE,
};

export const localeReducer = (state = initialState, action: LocaleAction): LocaleState => {
  switch (action.type) {
    case 'SET_LOCALE':
      return {
        ...state,
        locale: action.payload,
      };
    default:
      throw new Error(`unsupported locale action: ${action}`);
  }
};
