import React, { FC } from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { DatePicker, Input, Radio } from 'formik-antd';
import { FormikProps } from 'formik';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import RechtstraegerSelect from '../../../shared/Rechtstraeger/RechtstraegerSelect';
import ObjektSelect from '../../ObjektSelect/ObjektSelect';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../components/DatePicker/DatePicker_formikAntD';
import { Verrechnungsart } from '../../../types';
import { Spacer } from '../../../components/Grid';
import {
  createIncomingInvoiceBuchungPositionFormValues,
  createPartialAmountFormValues,
  incomingInvoiceFormFields,
  IncomingInvoiceFormValues,
  TInvoice,
} from './incomingInvoiceFormMapper';
import { isBillingTypeEinzelrechnung } from '../../Payment/paymentHelpers';
import { isNotNil } from '../../../helpers/assertionHelper';
import FormBlockRow from './shared/FormBlockRow';
import useOnSetBankDetailsForIncomingInvoice from './useOnSetBankDetailsForIncomingInvoice';

type Props = {
  formikProps: FormikProps<IncomingInvoiceFormValues>;
  shouldRestore: boolean;
  isUpdate: boolean;
  incomingInvoice?: TInvoice;
};

const IncomingInvoiceDetailsBlockForm: FC<Props> = ({ formikProps, shouldRestore, isUpdate, incomingInvoice }) => {
  const onResetStandingInvoiceFieldValues = () => {
    formikProps.values.partialAmountList && formikProps.setFieldValue(incomingInvoiceFormFields.partialAmountList, [createPartialAmountFormValues()]);
  };

  const onResetSingleInvoiceFieldValues = () => {
    formikProps.values.dueDate && formikProps.setFieldValue(incomingInvoiceFormFields.dueDate, undefined);
    formikProps.values.requestedPaymentDate && formikProps.setFieldValue(incomingInvoiceFormFields.requestedPaymentDate, undefined);
    formikProps.values.buchungsdatum && formikProps.setFieldValue(incomingInvoiceFormFields.buchungsdatum, undefined);
    isNotNil(formikProps.values.abgrenzungsBuchung) && formikProps.setFieldValue(incomingInvoiceFormFields.abgrenzungsBuchung, false);
    formikProps.values.abgrenzungsBuchungsdatum && formikProps.setFieldValue(incomingInvoiceFormFields.abgrenzungsBuchungsdatum, undefined);
    formikProps.values.buchungPositionList &&
      formikProps.setFieldValue(incomingInvoiceFormFields.buchungPositionList, [createIncomingInvoiceBuchungPositionFormValues()]);
  };

  useOnSetBankDetailsForIncomingInvoice(formikProps);

  return (
    <>
      <FormBlockRow
        rowTitle="Rechnung"
        content={
          <>
            <Row gutter={16}>
              <Col span={12}>
                {!incomingInvoice ? (
                  <Radio.Group name={incomingInvoiceFormFields.billingType} disabled={isUpdate} block>
                    <Radio.Button value={Verrechnungsart.Einzelrechnung} onChange={onResetStandingInvoiceFieldValues}>
                      Einzelrechnung
                    </Radio.Button>
                    <Radio.Button value={Verrechnungsart.Dauerrechnung} onChange={onResetSingleInvoiceFieldValues}>
                      Dauerrechnung
                    </Radio.Button>
                  </Radio.Group>
                ) : (
                  <Typography.Text>{incomingInvoice.billingType.text}</Typography.Text>
                )}
              </Col>
            </Row>
            <Spacer height={16} />
            <Row gutter={16}>
              <Col span={12}>
                <FormItemWithoutColon name={incomingInvoiceFormFields.ausstellerRechtstraegerId} label="Lieferant" labelAlign="left">
                  <RechtstraegerSelect
                    disabled={shouldRestore}
                    allowClear={false}
                    size="middle"
                    name={incomingInvoiceFormFields.ausstellerRechtstraegerId}
                    placeholder="Lieferant auswählen"
                    onNewItemCreateSelected={() => formikProps.setFieldValue(incomingInvoiceFormFields.ausstellerRechtstraegerId, '')}
                    onNewItemCreated={(id) => formikProps.setFieldValue(incomingInvoiceFormFields.ausstellerRechtstraegerId, id)}
                  />
                </FormItemWithoutColon>
              </Col>
              <Col span={12}>
                <FormItemWithoutColon name={incomingInvoiceFormFields.payeeRechtstraegerId} label="Empfänger" labelAlign="left">
                  <RechtstraegerSelect
                    disabled={shouldRestore}
                    allowClear={false}
                    size="middle"
                    name={incomingInvoiceFormFields.payeeRechtstraegerId}
                    placeholder="Empfänger auswählen"
                    onChange={() => {
                      formikProps.setFieldValue(incomingInvoiceFormFields.objektId, '');
                      formikProps.setFieldValue(incomingInvoiceFormFields.payeeBankDetailsId, '');
                    }}
                    onNewItemCreateSelected={() => formikProps.setFieldValue(incomingInvoiceFormFields.payeeRechtstraegerId, '')}
                    onNewItemCreated={(id) => formikProps.setFieldValue(incomingInvoiceFormFields.payeeRechtstraegerId, id)}
                  />
                </FormItemWithoutColon>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItemWithoutColon name={incomingInvoiceFormFields.objektId} label="Objekt">
                  <ObjektSelect
                    disabled={shouldRestore}
                    name={incomingInvoiceFormFields.objektId}
                    rechnungsAusstellerId={formikProps.values.payeeRechtstraegerId}
                    onChange={() => {
                      formikProps.setFieldValue(incomingInvoiceFormFields.payeeBankDetailsId, '');
                    }}
                    onListChange={(objektList) => {
                      if (objektList.length === 1) {
                        formikProps.setFieldValue(incomingInvoiceFormFields.objektId, objektList[0].objektId);
                      }
                    }}
                    size="middle"
                  />
                </FormItemWithoutColon>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormItemWithoutColon name={incomingInvoiceFormFields.kundenNummer} label="Kundennummer">
                  <Input
                    size="middle"
                    disabled={shouldRestore}
                    id={incomingInvoiceFormFields.kundenNummer}
                    name={incomingInvoiceFormFields.kundenNummer}
                  />
                </FormItemWithoutColon>
              </Col>
              <Col span={12}>
                <FormItemWithoutColon name={incomingInvoiceFormFields.rechnungsNummer} label="Rechnungsnummer">
                  <Input
                    disabled={shouldRestore}
                    size="middle"
                    id={incomingInvoiceFormFields.rechnungsNummer}
                    name={incomingInvoiceFormFields.rechnungsNummer}
                  />
                </FormItemWithoutColon>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormItemWithoutColon name={incomingInvoiceFormFields.rechnungsDatum} label="Rechnungsdatum">
                  <DatePicker
                    disabled={shouldRestore}
                    size="middle"
                    id={incomingInvoiceFormFields.rechnungsDatum}
                    name={incomingInvoiceFormFields.rechnungsDatum}
                    style={{ width: '100%' }}
                    format={DATE_DISPLAY_FORMAT_DEFAULT}
                  />
                </FormItemWithoutColon>
              </Col>
              {isBillingTypeEinzelrechnung(formikProps.values.billingType) && (
                <Col span={12}>
                  <FormItemWithoutColon name={incomingInvoiceFormFields.dueDate} label="Fällig am">
                    <DatePicker
                      disabled={shouldRestore}
                      size="middle"
                      id={incomingInvoiceFormFields.dueDate}
                      name={incomingInvoiceFormFields.dueDate}
                      style={{ width: '100%' }}
                      format={DATE_DISPLAY_FORMAT_DEFAULT}
                    />
                  </FormItemWithoutColon>
                </Col>
              )}
            </Row>
          </>
        }
      />
      <Divider />
    </>
  );
};

export default IncomingInvoiceDetailsBlockForm;
