import React, { FC, useEffect, useMemo } from 'react';
import { Empty, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import zinslisteTableColumns, { RowContextZinsliste } from './zinslisteTableColumns';
import { useObjektZinslisteQuery } from '../../Objekt/gql/ObjektQueries.types';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { useZinslisteQuery, useZinslisteVerarbeitungsstandQuery } from '../gql/ZinslisteQueries.types';
import { useToggle } from '../../../hooks/useToggle';
import { useOnZinslisteDataChangedEvents } from '../useOnZinslisteDataChangedEvents';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import ObjektZinslisteCreateModal from '../Form/Create/ObjektZinslisteCreateModal';
import { ZinslisteListEntry } from '../../../types';

type ZinslisteTableProps = {
  objektId: string;
  isRefetchingObjekt: boolean;
};

const ZinslisteTable: FC<ZinslisteTableProps> = ({ objektId, isRefetchingObjekt }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();
  const [isCollapsedForZinslisteModal, onCollapseForZinslisteModal] = useToggle();

  const { data, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useObjektZinslisteQuery,
    {
      variables: {
        objektId,
      },
      //FIXME: Remove fetchPolicy
      // When a zinsliste is deleted, cached data causes this error: http://jira.andromeda.intern/browse/EC-6233
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const zinslisteList = data?.getZinslisteListByObjektId.data?.contentList;
  const zinslisteIds = zinslisteList ? zinslisteList.map((list) => list.zinslisteId) : [];

  const loading = !zinslisteList;

  const components = {
    body: {
      row: CustomRow,
    },
  };

  useEffect(() => {
    if (isRefetchingObjekt) refetch();
  }, [isRefetchingObjekt, refetch]);

  useOnZinslisteDataChangedEvents('zinslisteList', refetch, zinslisteIds);

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button type="primary" size="small" icon={<PlusOutlined />} onClick={onCollapseForZinslisteModal}>
          Zinsliste erstellen
        </Button>
      </ButtonsAligned>
      <TableWithColSelector<ZinslisteListEntry>
        size="small"
        tableLayout="auto"
        locale={{
          emptyText: <Empty description={<span>Keine Zinslisten vorhanden</span>} />,
        }}
        loading={loading}
        scroll={{ x: 900 }}
        pagination={pagination}
        components={components}
        columns={zinslisteTableColumns(objektId)}
        dataSource={zinslisteList}
        onChange={handleTableSorting}
        filterIdentifier="objekt-zinsliste"
        rowKey={(record) => record.zinslisteId}
        // @ts-ignore
        onRow={(record, rowIndex) => ({
          rowindex: rowIndex,
          record,
          objektId,
        })}
      />
      <ObjektZinslisteCreateModal
        objektId={objektId}
        onCreate={() => {
          onCollapseForZinslisteModal();
          refetch();
        }}
        visible={isCollapsedForZinslisteModal}
        onCancel={onCollapseForZinslisteModal}
      />
    </>
  );
};

const CustomRow = (props: any) => {
  if (!props.record) {
    return <tr {...props} />;
  }
  return <ZinslisteTableRow {...props} />;
};

const ZinslisteTableRow = (props: any) => {
  const { objektId, ...restProps } = props;

  const { data: dataZinsliste, loading: isDeviationListLoading } = useZinslisteQuery({
    variables: { objektId, zinslisteId: props.record.zinslisteId },
  });

  const { data: dataVerarbeitungsstand, loading: isVerarbeitungsstandLoading } = useZinslisteVerarbeitungsstandQuery({
    variables: { objektId, zinslisteId: props.record.zinslisteId },
  });

  const zinsliste = dataZinsliste?.getZinsliste.data;
  const verarbeitungsstand = dataVerarbeitungsstand?.getZinslisteVerarbeitungsstand.data;
  const isLoading = isVerarbeitungsstandLoading || isDeviationListLoading;

  const contextValues = useMemo(
    () => ({
      isLoading,
      verarbeitungsstand,
      zinsliste,
    }),
    [isLoading, verarbeitungsstand, zinsliste]
  );

  return (
    <RowContextZinsliste.Provider value={contextValues}>
      <tr {...restProps} />
    </RowContextZinsliste.Provider>
  );
};

export default ZinslisteTable;
