import React from 'react';
import { Link } from 'react-router-dom';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import MitarbeiterTooltip from '../../../../../components/Card/MitarbeiterTooltip';
import { EuroAmount } from '../../../../../components/Number';
import { generatePathToZinslisteDetailsPage } from '../../../../Zinsliste/zinslisteUriPaths';
import { ZinslisteGenerierlaufEntry, ZinslisteGenerierlaufOrderBy } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';

const entryTableColumns: TableWithColSelectorColumnProps<ZinslisteGenerierlaufEntry>[] = [
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: ZinslisteGenerierlaufOrderBy.Status,
    sorter: true,
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: ZinslisteGenerierlaufOrderBy.Objekt,
    sorter: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={30} text={record.objektKurzBezeichnung} />,
  },

  {
    title: 'Vorschreibungsperiode',
    dataIndex: ZinslisteGenerierlaufOrderBy.Vorschreibungsperiode,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => (
      <span>
        {record.vorschreibungsperiode.monat}/{record.vorschreibungsperiode.jahr}
      </span>
    ),
  },
  {
    title: 'Brutto',
    dataIndex: ZinslisteGenerierlaufOrderBy.Brutto,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => (record.sumBrutto ? <EuroAmount value={record.sumBrutto} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Netto',
    dataIndex: ZinslisteGenerierlaufOrderBy.Netto,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => (record.sumNetto ? <EuroAmount value={record.sumNetto} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.zinsliste?.createTs ?? null} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip
        mitarbeiterId={record.zinsliste?.createdByMitarbeiterId}
        userId={record.zinsliste?.createdBy}
        alignment="left"
        showsInitials
      />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) =>
      record.zinsliste?.kopfdaten.zinslisteId ? (
        <Link to={generatePathToZinslisteDetailsPage(record.objektId, record.zinsliste.kopfdaten.zinslisteId)} target="_blank">
          Details
        </Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
];

export default entryTableColumns;
