import { FC, useEffect } from 'react';
import { useTicketChangeEntryListQuery } from '../../gql/TicketQueries.types';
import { Ticket, TicketChangeEntryScope } from '../../../../types';
import TicketChangeEntryList from './TicketChangeEntryList';

type Props = {
  ticket: Ticket;
};

const TicketChangeEntryListing: FC<Props> = ({ ticket }) => {
  const { data, refetch } = useTicketChangeEntryListQuery({
    variables: { ticketId: ticket.ticketId, scope: TicketChangeEntryScope.SelfAndSubTicketsMainEvents },
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  const changeEntryList = data?.getTicketChangeEntryList.data;

  return <TicketChangeEntryList ticket={ticket} changeEntryList={changeEntryList} loading={!changeEntryList} />;
};

export default TicketChangeEntryListing;
