import React, { FC } from 'react';
import { Divider } from 'antd';
import { SaldenlisteKontoType } from '../../../types';
import SaldenlisteKontoTable from './SaldenlisteKontoTable';
import SaldenlisteSummaryTable from './SaldenlisteSummaryTable';

type SaldenlisteTablesProps = {
  buchungskreisId: string;
  objektId?: string;
  date: string;
  kontoType?: SaldenlisteKontoType;
};

const SaldenlisteTables: FC<SaldenlisteTablesProps> = ({ buchungskreisId, objektId, date, kontoType }) => {
  return (
    <>
      <SaldenlisteKontoTable buchungskreisId={buchungskreisId} date={date} objektId={objektId} kontoType={kontoType} />
      <Divider />
      <SaldenlisteSummaryTable buchungskreisId={buchungskreisId} date={date} objektId={objektId} kontoType={kontoType} />
    </>
  );
};

export default SaldenlisteTables;
