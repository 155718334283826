import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MailListQueryVariables = Types.Exact<{
  createdByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  createTsFromInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsToInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  firmendatenId: Types.Scalars['String']['input'];
  order?: Types.InputMaybe<Types.MailOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  recipient?: Types.InputMaybe<Types.Scalars['String']['input']>;
  recipientRechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statusList?: Types.InputMaybe<Array<Types.InputMaybe<Types.MailStatus>> | Types.InputMaybe<Types.MailStatus>>;
  useCaseList?: Types.InputMaybe<Array<Types.MailUseCase> | Types.MailUseCase>;
}>;

export type MailListQuery = {
  getMailList: {
    data: {
      contentList: Array<{
        attachmentCount: number;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        createTs: string;
        mailId: string;
        recipient: string;
        sender: string;
        sentTs?: string | null;
        subject: string;
        labelList: Array<{ label: string; value: string }>;
        recipientRechtstraeger?: { kurzBezeichnung: string; rechtstraegerId: string } | null;
        status: { text: string; value: Types.MailStatus; description?: string | null };
        useCase: { text: string; value: Types.MailUseCase };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MailStatusListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MailStatusListQuery = {
  getMailStatusList: {
    data: Array<{ description?: string | null; text: string; value: Types.MailStatus }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MailQueryVariables = Types.Exact<{
  mailId: Types.Scalars['String']['input'];
}>;

export type MailQuery = {
  getMail: {
    data: {
      attachmentCount: number;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      mailId: string;
      recipient: string;
      sender: string;
      sentTs?: string | null;
      subject: string;
      textHtml?: string | null;
      textPlain?: string | null;
      labelList: Array<{ label: string; value: string }>;
      recipientRechtstraeger?: { kurzBezeichnung: string; rechtstraegerId: string } | null;
      status: { value: Types.MailStatus; text: string };
      useCase: { text: string; value: Types.MailUseCase };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MailUseCaseListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MailUseCaseListQuery = {
  getMailUseCaseList: {
    data: Array<{ text: string; value: Types.MailUseCase }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const MailListDocument = gql`
  query MailList(
    $createdByMitarbeiterIdList: [String!]
    $createTsFromInclusive: String
    $createTsToInclusive: String
    $firmendatenId: String!
    $order: MailOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
    $recipient: String
    $recipientRechtstraegerId: String
    $statusList: [MailStatus]
    $useCaseList: [MailUseCase!]
  ) {
    getMailList(
      createdByMitarbeiterIdList: $createdByMitarbeiterIdList
      createTsFromInclusive: $createTsFromInclusive
      createTsToInclusive: $createTsToInclusive
      firmendatenId: $firmendatenId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
      recipient: $recipient
      recipientRechtstraegerId: $recipientRechtstraegerId
      statusList: $statusList
      useCaseList: $useCaseList
    ) {
      data {
        contentList {
          attachmentCount
          createdBy
          createdByMitarbeiterId
          createTs
          labelList {
            label
            value
          }
          mailId
          recipient
          recipientRechtstraeger {
            kurzBezeichnung
            rechtstraegerId
          }
          sender
          sentTs
          status {
            text
            value
            description
          }
          subject
          useCase {
            text
            value
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMailListQuery(
  baseOptions: Apollo.QueryHookOptions<MailListQuery, MailListQueryVariables> &
    ({ variables: MailListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MailListQuery, MailListQueryVariables>(MailListDocument, options);
}
export function useMailListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MailListQuery, MailListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MailListQuery, MailListQueryVariables>(MailListDocument, options);
}
export function useMailListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MailListQuery, MailListQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MailListQuery, MailListQueryVariables>(MailListDocument, options);
}
export type MailListQueryHookResult = ReturnType<typeof useMailListQuery>;
export type MailListLazyQueryHookResult = ReturnType<typeof useMailListLazyQuery>;
export type MailListSuspenseQueryHookResult = ReturnType<typeof useMailListSuspenseQuery>;
export type MailListQueryResult = Apollo.QueryResult<MailListQuery, MailListQueryVariables>;
export const MailStatusListDocument = gql`
  query MailStatusList {
    getMailStatusList {
      data {
        description
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMailStatusListQuery(baseOptions?: Apollo.QueryHookOptions<MailStatusListQuery, MailStatusListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MailStatusListQuery, MailStatusListQueryVariables>(MailStatusListDocument, options);
}
export function useMailStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MailStatusListQuery, MailStatusListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MailStatusListQuery, MailStatusListQueryVariables>(MailStatusListDocument, options);
}
export function useMailStatusListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MailStatusListQuery, MailStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MailStatusListQuery, MailStatusListQueryVariables>(MailStatusListDocument, options);
}
export type MailStatusListQueryHookResult = ReturnType<typeof useMailStatusListQuery>;
export type MailStatusListLazyQueryHookResult = ReturnType<typeof useMailStatusListLazyQuery>;
export type MailStatusListSuspenseQueryHookResult = ReturnType<typeof useMailStatusListSuspenseQuery>;
export type MailStatusListQueryResult = Apollo.QueryResult<MailStatusListQuery, MailStatusListQueryVariables>;
export const MailDocument = gql`
  query Mail($mailId: String!) {
    getMail(mailId: $mailId) {
      data {
        attachmentCount
        createdBy
        createdByMitarbeiterId
        createTs
        labelList {
          label
          value
        }
        mailId
        recipient
        recipientRechtstraeger {
          kurzBezeichnung
          rechtstraegerId
        }
        sender
        sentTs
        status {
          value
          text
        }
        subject
        textHtml
        textPlain
        useCase {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMailQuery(
  baseOptions: Apollo.QueryHookOptions<MailQuery, MailQueryVariables> & ({ variables: MailQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MailQuery, MailQueryVariables>(MailDocument, options);
}
export function useMailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MailQuery, MailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MailQuery, MailQueryVariables>(MailDocument, options);
}
export function useMailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MailQuery, MailQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MailQuery, MailQueryVariables>(MailDocument, options);
}
export type MailQueryHookResult = ReturnType<typeof useMailQuery>;
export type MailLazyQueryHookResult = ReturnType<typeof useMailLazyQuery>;
export type MailSuspenseQueryHookResult = ReturnType<typeof useMailSuspenseQuery>;
export type MailQueryResult = Apollo.QueryResult<MailQuery, MailQueryVariables>;
export const MailUseCaseListDocument = gql`
  query MailUseCaseList {
    getMailUseCaseList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMailUseCaseListQuery(baseOptions?: Apollo.QueryHookOptions<MailUseCaseListQuery, MailUseCaseListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MailUseCaseListQuery, MailUseCaseListQueryVariables>(MailUseCaseListDocument, options);
}
export function useMailUseCaseListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MailUseCaseListQuery, MailUseCaseListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MailUseCaseListQuery, MailUseCaseListQueryVariables>(MailUseCaseListDocument, options);
}
export function useMailUseCaseListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MailUseCaseListQuery, MailUseCaseListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MailUseCaseListQuery, MailUseCaseListQueryVariables>(MailUseCaseListDocument, options);
}
export type MailUseCaseListQueryHookResult = ReturnType<typeof useMailUseCaseListQuery>;
export type MailUseCaseListLazyQueryHookResult = ReturnType<typeof useMailUseCaseListLazyQuery>;
export type MailUseCaseListSuspenseQueryHookResult = ReturnType<typeof useMailUseCaseListSuspenseQuery>;
export type MailUseCaseListQueryResult = Apollo.QueryResult<MailUseCaseListQuery, MailUseCaseListQueryVariables>;
