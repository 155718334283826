import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';
import { FiltersFormValues } from './listingFiltersFormMapper';

enum QueryParamKey {
  OBJEKT_ID = 'objektId',
  EIGENTUEMER_ID = 'eigentuemerId',
  EXIT_CODE_LIST = 'exitCodeList',
}

export type TSubAbrechnungQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  verarbeitungType: string,
  generierlaufId: string,
  filters: TSubAbrechnungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToSubAbrechnungListingPage(verarbeitungType, generierlaufId, filters, paginationParams));

export const generatePathToSubAbrechnungListingPage = (
  verarbeitungType: string,
  generierlaufId: string,
  filters: TSubAbrechnungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [QueryParamKey.OBJEKT_ID]: filters?.objektId,
    [QueryParamKey.EIGENTUEMER_ID]: filters?.eigentuemerId,
    [QueryParamKey.EXIT_CODE_LIST]: filters?.exitCodeList,
    ...paginationParams,
  })}`;
