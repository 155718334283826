import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../../components/message/validationMsg';
import { abrechnungsdefinitionParameterFormFields } from './abrDefParameterFormMapper';

/* eslint-disable no-template-curly-in-string */
export const abrDefParameterFormValidationSchema = Yup.object().shape({
  [abrechnungsdefinitionParameterFormFields.type]: Yup.string().required(entityIsRequired('Abrechnungstyp')),
  [abrechnungsdefinitionParameterFormFields.bezeichnung]: Yup.string()
    .required(entityIsRequired('Bezeichnung'))
    .max(50, entityMaxCharLength('Bezeichnung', 50)),
});
/* eslint-enable no-template-curly-in-string */
