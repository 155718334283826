import React, { FC, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { Space } from 'antd';
import { BelegTexteFormValues } from '../../belegTexteFormMapper';
import { DocumentHeaderAndFooterImageAlignment, FileInfo, OrderFileInfo, TextbausteinType } from '../../../../../types';
import FileUploadView from '../../../../../components/FileUpload/FileUploadView';
import FileUploadArea from '../../../../../components/FileUpload/FileUploadArea';
import { loadPreviewForMitarbeiter, LoadPreviewForMitarbeiterResponse } from '../../../../Document/useFileDownloadAsyncForDocument';
import { validateFile } from './textbausteinUploadAreaHelpers';
import { useAuth } from '../../../../../shared/Auth/authContext';
import usePermission from '../../../../../shared/Auth/usePermission';
import { FormFileReference } from '../../../../../components/FileUpload/formHelpers';
import TextbausteinImageAlignment from './TextbausteinImageAlignment';

type Props = {
  name: string;
  nameForTempBildFileId: string;
  formikProps: FormikProps<BelegTexteFormValues>;
  textbausteinType: TextbausteinType;
  file?: FormFileReference;
  fileInfo?: OrderFileInfo | null;
  tempFileId?: string | null;
  disabled?: boolean;
};

const TextbausteinUploadArea: FC<Props> = ({ formikProps, name, nameForTempBildFileId, file, fileInfo, tempFileId, disabled, textbausteinType }) => {
  const { isAdmin } = usePermission();
  const { mitarbeiter } = useAuth();

  const [fileInfoData, setFileInfoData] = useState(fileInfo);

  const onDelete = () => {
    setFileInfoData(undefined);
    formikProps.setFieldValue(name, undefined);
    formikProps.setFieldValue(nameForTempBildFileId, undefined);
    formikProps.setFieldValue(`${name}BildFileId`, undefined);
    formikProps.setFieldValue(`${name}BildAlignment`, undefined);
  };

  useEffect(() => {
    if (tempFileId && !isAdmin) {
      loadPreviewForMitarbeiter({ mitarbeiterId: mitarbeiter?.mitarbeiterId, fileId: tempFileId }).then((maybePreview) => {
        const preview = maybePreview as LoadPreviewForMitarbeiterResponse;
        const headerContentDisposition = preview.headers.get('Content-Disposition');
        const regexPattern = /filename="([^"]+)"/;
        const matchFilename = headerContentDisposition?.match(regexPattern);

        const filename = matchFilename?.[1] ?? 'filename';
        if (validateFile({ name: filename, size: preview.blob.size, type: preview.blob.type }, formikProps, name, textbausteinType)) {
          formikProps.setFieldValue(name, { filename, file: preview.blob, isUpload: true });
        } else {
          formikProps.setFieldValue(nameForTempBildFileId, undefined, false);
        }
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFileId]);

  const onSelectFile = (data: FileInfo) => {
    formikProps.setFieldValue(nameForTempBildFileId, data.fileId);
    formikProps.setFieldValue(`${name}BildAlignment`, DocumentHeaderAndFooterImageAlignment.Left);
  };

  let fileView;
  if ((file && file?.filename) || (fileInfoData && fileInfoData?.name)) {
    const filename = file?.filename || fileInfoData?.name;
    fileView = (
      <Space direction="vertical">
        {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
        <FileUploadView filename={filename!} onDelete={onDelete} />
        <TextbausteinImageAlignment name={name} />
      </Space>
    );
  }

  return fileView ? (
    <>{fileView}</>
  ) : (
    <>
      <FileUploadArea<BelegTexteFormValues>
        formikProps={formikProps}
        name={name}
        disabled={disabled}
        validateFile={(file) => validateFile(file, formikProps, name, textbausteinType)}
        onSelectFileSetFieldValue={onSelectFile}
        onBeforeUpload={() => formikProps.setFieldValue(`${name}BildAlignment`, DocumentHeaderAndFooterImageAlignment.Left)}
      />
    </>
  );
};
export default TextbausteinUploadArea;
