import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { FibuBelegSymbolTuple, FibuBuchungTypeTuple } from '../../../../types';
import GeneralInfoBlockContent from '../shared/GeneralInfoBlockContent/GeneralInfoBlockContent';

type Props = {
  belegSymbol: FibuBelegSymbolTuple;
  bookingSuggestionId?: string | null;
  buchungType: FibuBuchungTypeTuple;
  buchungskreisRechtstraegerId: string;
};

const GeneralInfoBlock: FC<Props> = ({ belegSymbol, buchungType, buchungskreisRechtstraegerId }) => {
  return (
    <Row align="stretch" style={{ marginTop: '24px' }}>
      <Col span={18}>
        <GeneralInfoBlockContent belegSymbol={belegSymbol} buchungType={buchungType} buchungskreisRechtstraegerId={buchungskreisRechtstraegerId} />
      </Col>
      <Col span={6} />
    </Row>
  );
};

export default GeneralInfoBlock;
