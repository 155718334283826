import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useAuftragsartTemplateGeneralChangeHistoryListQuery } from '../gql/AuftragsartTemplateQueries.types';

type Props = {
  auftragsartId: string;
};

const AuftragsartTemplateGeneralChangeHistoryListingTable: FC<Props> = ({ auftragsartId }) => {
  const { data, loading } = useAuftragsartTemplateGeneralChangeHistoryListQuery({ variables: { auftragsartId } });
  const historyList = data?.getAuftragsartTemplateGeneralChangeHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="auftragsart-template-general" />
  );
};

export default AuftragsartTemplateGeneralChangeHistoryListingTable;
