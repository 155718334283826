import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { FormFields } from '../../../../helpers/formikHelper';
import { VorschreibungsperiodeInput } from '../../../../types';

export interface ObjektZinslisteCreateFormValues {
  vorschreibungsperiode: string;
  objektId: string;
}

export const objektZinslisteInitialValues = (objektId: string): ObjektZinslisteCreateFormValues => ({
  vorschreibungsperiode: dayjsCustom().format(),
  objektId,
});

export const objektZinslisteFormFields: FormFields<ObjektZinslisteCreateFormValues> = {
  vorschreibungsperiode: 'vorschreibungsperiode',
  objektId: 'objektId',
};

export const mapVorschreibungFormValueToObjektZinslisteCreate = (formValues: ObjektZinslisteCreateFormValues): VorschreibungsperiodeInput => ({
  monat: dayjsCustom(formValues.vorschreibungsperiode).month() + 1, // month is zero based, january = 0 in dayjs?!?!
  jahr: dayjsCustom(formValues.vorschreibungsperiode).year(),
});
