import { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import UstVomAufwandDetailsListing from './UstVomAufwandDetailsListing';
import { UstVomAufwand } from '../../../types';

type Props = {
  ustVomAufwand: UstVomAufwand;
};

const UstVomAufwandDetailsTab: FC<Props> = ({ ustVomAufwand }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Ust.vom Aufwand',
      children: <UstVomAufwandDetailsListing ustVomAufwand={ustVomAufwand} />,
    },
  ];
  return <Tabs items={items} />;
};

export default UstVomAufwandDetailsTab;
