import React, { FC } from 'react';
import { FieldArray, FormikProps } from 'formik';
import { Button, Divider, Typography } from 'antd';
import {
  VorsteuerkuerzungKontenZuweisungVersionFormValues,
  vstKuerzungKontenZuordnungVersionFormFields,
  vstKuerzungKontenZuweisungFormInitialValues,
} from './vstKuerzungKontenZuweisungVersionFormMapper';
import VstKuerzungKontenZuweisungTable from '../../Table/Edit/VstKuerzungKontenZuweisungTable';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../../components/Button/ButtonsAligned';
import { Verwaltungsart } from '../../../../../types';
import FieldHelpIconWithTooltip from '../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  formikProps: FormikProps<VorsteuerkuerzungKontenZuweisungVersionFormValues>;
  verwaltungsart: Verwaltungsart;
  fieldHelp?: string | null;
};

const VstKuerzungKontenZuweisungListingForm: FC<Props> = ({ formikProps, verwaltungsart, fieldHelp }) => (
  <FieldArray
    name={vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}
    render={(arrayHelpers) => {
      const onDelete = (index: number) => {
        arrayHelpers.remove(index);
      };
      return (
        <>
          <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />
          <Typography.Title level={5}>
            Kontenzuweisungen
            <FieldHelpIconWithTooltip fieldHelp={fieldHelp} />
          </Typography.Title>
          <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
            <Button
              onClick={() => {
                arrayHelpers.push({ ...vstKuerzungKontenZuweisungFormInitialValues });
              }}
            >
              Zuweisung hinzufügen
            </Button>
          </ButtonsAligned>
          <VstKuerzungKontenZuweisungTable formikProps={formikProps} onDelete={onDelete} verwaltungsart={verwaltungsart} />
        </>
      );
    }}
  />
);

export default VstKuerzungKontenZuweisungListingForm;
