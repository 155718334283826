import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { handleCreateSubmit } from '../../shared/handleSubmit';
import { ObjektInfoFeldType } from '../../../../../../types';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import { personCreateFormFields, personCreateFormInitialValues, PersonCreateFormValues } from './personCreateFormMapper';
import { personValidationSchema } from '../shared/personValidationSchema';
import PersonenDatenTab from '../Tabs/PersonDatenTab';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';

type Props = {
  objektId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const PersonCreateForm: FC<Props> = ({ objektId, onCancel, onSuccess }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<PersonCreateFormValues>
      initialValues={personCreateFormInitialValues}
      validationSchema={personValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleCreateSubmit(firmendatenId, objektId, ObjektInfoFeldType.Person, values, setSubmitting, onSuccess);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <PersonenDatenTab formFields={personCreateFormFields} formikProps={formikProps} />
          <InfoFeldUpload name={personCreateFormFields.documentList} formikProps={formikProps} documentList={formikProps.values.documentList} />
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default PersonCreateForm;
