import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import vorschreibungspositionTableColumns from './vorschreibungspositionTableColumns';
import { Vorschreibungsposition } from '../../../../types';
import NestedTable from '../../../../components/Table/NestedTable/NestedTable';

type Props = {
  pagination: PaginationProps;
  refetch: () => void;
  vorschreibungspositionList?: Vorschreibungsposition[];
};
const VorschreibungspositionTable: FC<Props> = ({ pagination, refetch, vorschreibungspositionList }) => {
  const loading = !vorschreibungspositionList;

  return (
    <NestedTable<Vorschreibungsposition>
      locale={{
        emptyText: <Empty description={<span>Keine Vorschreibungsposition vorhanden</span>} />,
      }}
      loading={loading}
      scroll={{ x: 900 }}
      pagination={pagination}
      dataSource={vorschreibungspositionList}
      columns={vorschreibungspositionTableColumns(refetch)}
      rowKey={(record) => record.vorschreibungspositionId}
      expandable={{
        rowExpandable: () => false,
      }}
      useHighlighting
    />
  );
};

export default VorschreibungspositionTable;
