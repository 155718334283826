import React from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import ZinslisteTemplate from './templates/zinsliste/ZinslisteTemplate';
import { mockZinslisteData } from './mocks/zinslisteMocks';

const PDFPageZinsliste = () => (
  <Row>
    <PDFPageCol pdfTemplate={<ZinslisteTemplate data={mockZinslisteData} />} />
  </Row>
);

export default PDFPageZinsliste;
