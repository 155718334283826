import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useAbrechnungsdefinitionTemplateQuery } from '../../../../../../features/Abrechnungsdefinition/gql/AbrDefQueries.types';
import { AbrechnungsdefinitionTemplateType } from '../../../../../../types';
import { findPrecedingVersionId, getFirmendatenId, hasPrecedingVersion, isPrecedingVersionAvailable } from './createPageHelpers';
import {
  useSubAdministrationAbrechnungsdefinitionVersionListQuery,
  useSubAdministrationAbrechnungsdefinitionVersionQuery,
} from '../../../../../../features/Abrechnungsdefinition/gql/SubAdministrationAbrDef/SubAdministrationAbrDefVersionQueries.types';
import SubAbrDefVersionCreateForm from '../../../../../../features/Abrechnungsdefinition/Version/Form/Create/SubAbrDefVersionCreateForm';

/*
When creating new version the forms have to be prefilled with the values of preceding version for the next available date from today
ex. 1: today: 01.01.2022., version 1: 01.01.2021. version 2: 01.01.2023 => preceding version: version 1
ex. 2: today: 01.01.2022., version 1: 01.01.2021. version 2: 02.01.2022 => preceding version: version 2
 */

type Props = {
  abrechnungsdefinitionId: string;
};
const SysSettingsSubAdministrationAbrechnungsdefinitionVersionCreatePage: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data: subAbrDefVersionListData } = useSubAdministrationAbrechnungsdefinitionVersionListQuery({
    variables: { abrechnungsdefinitionId },
  });
  const versionList = subAbrDefVersionListData?.getSubAdministrationAbrechnungsdefinitionVersionList.data;
  const precedingVersionId = versionList ? findPrecedingVersionId(versionList) : undefined;

  const { data: subAbrDefVersionData } = useSubAdministrationAbrechnungsdefinitionVersionQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId: precedingVersionId! },
    skip: !precedingVersionId,
  });
  const precedingVersion = subAbrDefVersionData?.getSubAdministrationAbrechnungsdefinitionVersion.data;

  const { data: templateSubData } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.SubAbrechnung },
    skip: isPrecedingVersionAvailable(versionList, precedingVersion),
  });
  const subAdministrationAbrechnungTextbausteinList = templateSubData?.getAbrechnungsdefinitionTemplate.data.textbausteinList;

  // Show loading state until initial data is available: if there is a precedingVersion we get the textbausteinLists from it otherwise we have to load default templates
  if (
    !versionList ||
    (hasPrecedingVersion(versionList) && !precedingVersion) ||
    (hasPrecedingVersion(versionList) === false && !subAdministrationAbrechnungTextbausteinList)
  ) {
    return <Skeleton active />;
  }

  return (
    <SubAbrDefVersionCreateForm
      abrechnungsdefinitionVersionList={versionList}
      precedingAbrechnungsdefinitionVersion={precedingVersion}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      firmendatenId={getFirmendatenId(versionList)}
      subAdministrationAbrechnungTextbausteinList={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        precedingVersion?.subAdministrationAbrechnungTextbausteinList ?? subAdministrationAbrechnungTextbausteinList!
      }
    />
  );
};

export default SysSettingsSubAdministrationAbrechnungsdefinitionVersionCreatePage;
