import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { InfoAbrechnungskreis } from '../../../objektAbrechnung/objektAbrechnung-types';

const InfoAbrKreisSummeEinnahmen: FC<{ abrechKreis: InfoAbrechnungskreis }> = ({ abrechKreis }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight, { marginBottom: '2mm' }]}>
    <View style={[pdfStyles.column, { width: '55%' }]} />

    <View style={[pdfStyles.row, pdfStyles.borderTopSmall, { width: '45%', borderTopWidth: 2 }]}>
      <Text style={[pdfStyles.column, pdfStyles.textBulletin, { width: '80%', marginTop: '2mm', fontWeight: 'bold' }]}>
        {abrechKreis.summeNettoEinnahmenText}
      </Text>
      <Text
        style={[
          pdfStyles.column,
          pdfStyles.textBulletin,
          { width: '20%', marginTop: '2mm', alignItems: 'flex-end', textAlign: 'right', fontWeight: 'bold' },
        ]}
      >
        {abrechKreis.summeNettoEinnahmen}
      </Text>
    </View>
  </View>
);

export default InfoAbrKreisSummeEinnahmen;
