import { FormFields } from '../../../../helpers/formikHelper';

export type TicketParticipantAssignFormValues = {
  rechtstraegerId: string | undefined | null;
};

export const ticketParticipantAssignFormFields: FormFields<TicketParticipantAssignFormValues> = {
  rechtstraegerId: 'rechtstraegerId',
};

export const ticketParticipantAssignFormInitialValues: TicketParticipantAssignFormValues = {
  rechtstraegerId: null,
};
