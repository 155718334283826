import React from 'react';
import { Space } from 'antd';
import { MitarbeiterTooltip } from '../../../components/Card';
import TemplateIncompleteWarning from '../../Abrechnungsdefinition/shared/TemplateIncompleteWarning';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MahndefinitionListEntry } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import MahndefinitionTemplateTableActions from './MahndefinitionTemplateTableActions';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';

const mahndefinitionTemplateTableColumns: (onActionSuccess: () => void) => TableWithColSelectorColumnProps<MahndefinitionListEntry>[] = (
  onActionSuccess
) => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    render: (text, record) => (
      <Space>
        <DataWithShortenedText text={record.bezeichnung} maxTextLength={100} />
        <TemplateIncompleteWarning textBausteineBefuellt={record.textBausteineBefuellt} />
      </Space>
    ),
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <MahndefinitionTemplateTableActions mahndefinition={record} onActionSuccess={onActionSuccess} />,
  },
];

export default mahndefinitionTemplateTableColumns;
