import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import VorsteuerkuerzungDetailsPage from './VorsteuerkuerzungDetailsPage';
import { URI_VORSTEUERKUERZUNG_DETAILS_PAGE } from '../../features/Vorsteuerkuerzung/vorsteuerkuerzungUriPaths';

export type VstKuerzungDetailsPageRouteParams = {
  objektId: string;
  vorsteuerkuerzungId: string;
};

const vorsteuerkuerzungDetailsPageRoute = (
  <Route
    path={`${URI_VORSTEUERKUERZUNG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<VorsteuerkuerzungDetailsPage />} />}
  />
);

export default vorsteuerkuerzungDetailsPageRoute;
