import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useIncomingInvoiceKundennummerListQuery } from '../../gql/IncomingInvoiceFilterQueries.types';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const KundennummerListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useIncomingInvoiceKundennummerListQuery();
  const kundennummerList = data?.getIncomingInvoiceKundennummerList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Kundennummer"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {kundennummerList.map((kundennummer) => (
        <Select.Option key={kundennummer} value={kundennummer}>
          <Typography.Text>{kundennummer}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default KundennummerListFormSelect;
