import { get } from 'lodash';
import { FormikProps } from 'formik';
import { updateFormikField } from '../../../../helpers/formikHelper';
import { stringOrEmpty } from '../../../../helpers/stringHelper';
import { FirmaFormValues } from '../../../../features/Rechtstraeger/Firma/Form/firmaFormMapper';
import { FirmendatenFormValues } from '../../../../features/KundenSystem/Firmendaten/form/fimendatenFormMapper';
import { PersonGemeinschaftFormValues } from '../../../../features/Rechtstraeger/PersonenGemeinschaft/Form/personenGemeinschaftFormMapper';

export const getFirmennameFields = (
  formikProps: FormikProps<FirmaFormValues> | FormikProps<FirmendatenFormValues> | FormikProps<PersonGemeinschaftFormValues>
) => ({
  name1: get(formikProps, 'values.name1'),
  name2: get(formikProps, 'values.name2'),
  name3: get(formikProps, 'values.name3'),
});

export const updateFirmenname = (formikProps: FormikProps<any>, data: any) => {
  if (data && data.name1) {
    updateFormikField(formikProps, 'name1', data.name1);
    updateFormikField(formikProps, 'name2', stringOrEmpty(data.name2));
    updateFormikField(formikProps, 'name3', stringOrEmpty(data.name3));
  }
};
