import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { useAuth } from '../../shared/Auth/authContext';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../types';
import { TicketListingFilterProps } from './Listing/TicketListing';

export const useOnTicketListDataChangedEvents = (
  key: string,
  callback: () => Promise<unknown>,
  ticketId?: string | string[],
  referenceIdFilters?: TicketListingFilterProps
) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  useOnDataChangedEvent([
    // when ticket is updated
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.ticket,
        params: {
          ticketId,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Aufgabe wurde gerade verändert',
      key: `${key}-update`,
      callback,
    },
    // when ticket is created
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.ticket,
        params: {
          ...referenceIdFilters,
        },
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Eine Aufgabe wurde gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    // when ticket is deleted
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.ticket,
        params: {
          ...referenceIdFilters,
        },
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Aufgabe wurde gerade gelöscht',
      key: `${key}-delete`,
      callback,
    },
  ]);
};

export const useOnTicketDetailsDataChangedEvents = (key: string, callback: () => Promise<unknown>, ticketId: string) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  useOnDataChangedEvent([
    // when main ticket (parent) is updated
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.ticket,
        params: {
          ticketId,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Aufgabe wurde gerade verändert',
      key: `${key}-parent-update`,
      callback,
    },
    // when subticket is created
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.ticket,
        params: {
          parentTicketId: ticketId,
        },
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Eine Unteraufgabe wurde gerade erstellt',
      key: `${key}-sub-create`,
      callback,
    },
    // when subticket is updated
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.ticket,
        params: {
          parentTicketId: ticketId,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Eine Unteraufgabe wurde gerade verändert',
      key: `${key}-sub-update`,
      callback,
    },
    // when main ticket (parent) is deleted
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.ticket,
        params: {
          ticketId,
        },
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Aufgabe wurde gerade gelöscht',
      key: `${key}-parent-delete`,
      callback,
    },
    // when subticket is deleted
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.ticket,
        params: {
          parentTicketId: ticketId,
        },
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Eine Unteraufgabe wurde gerade gelöscht',
      key: `${key}-sub-delete`,
      callback,
    },
  ]);
};
