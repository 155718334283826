import React from 'react';
import { TableWithAlignedColsExpandedRowType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { TopAbrKreisAufwand } from '../../../../../../types';
import AufwandAufteilungKonto from './AufwandAufteilungKonto';
import AufwandZeitscheibeDetails from './AufwandZeitscheibeDetails';
import { StyledTableAufwandAufteilungRows } from './styled/AufwandAufteilungRows.style';

const aufwandAufteilungTableExpandedRow: TableWithAlignedColsExpandedRowType<TopAbrKreisAufwand>[] = [
  {
    header: () => <></>,
    table: {
      colSpanSize: [5],
      StyledComponent: StyledTableAufwandAufteilungRows,
      positionOfButton: 2,
      showCellButton: false,
    },
    body: (aufwandAufteilung) => (
      <>
        <>
          {aufwandAufteilung.kontoList.map((konto, index) => (
            <AufwandAufteilungKonto key={konto.kontoId} konto={konto} />
          ))}
        </>
        <>
          {aufwandAufteilung.zeitscheibeAufwandList.map((zeitscheibe, index) => (
            <AufwandZeitscheibeDetails key={index} zeitscheibe={zeitscheibe} />
          ))}
        </>
      </>
    ),
  },
];

export default aufwandAufteilungTableExpandedRow;
