import { FormFields } from '../../../../../../helpers/formikHelper';
import { ValuesDescriptions } from '../../../../../../components/Timeline/versionTimelineHelper';

export interface ChangeToNichtDurchgefuehrtFormValues {
  comment?: string | null;
}

export const changeToNichtDurchgefuehrtFormInitialValues = (): ChangeToNichtDurchgefuehrtFormValues => ({
  comment: '',
});

export const changeToNichtDurchgefuehrtFormFields: FormFields<ChangeToNichtDurchgefuehrtFormValues> = {
  comment: 'comment',
};

export const mapFormValuesToChangeToNichtDurchgefuehrt = (
  formValues: ChangeToNichtDurchgefuehrtFormValues
): ChangeToNichtDurchgefuehrtFormValues => ({
  comment: formValues.comment,
});

export const changeToNichtDurchgefuehrtFormValuesDescriptions: ValuesDescriptions<ChangeToNichtDurchgefuehrtFormValues> = {
  comment: 'Kommentar',
};
