import { TRechtstraegerQueryParams } from './filtersQueryParams';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';
import { QueryGetRechtstraegerListArgs, RechtstraegerType } from '../../../../types';

export type FiltersFormValues = {
  createTsFrom?: string | null;
  createTsTo?: string | null;
  erstellerMitarbeiterIdList?: string[] | null;
  kundennummer?: string | null;
  kurzBezeichnung?: string | null;
  showArchived?: boolean | null;
  tagList?: string[] | null;
  typeList?: RechtstraegerType[] | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  createTsFrom: 'createTsFrom',
  createTsTo: 'createTsTo',
  erstellerMitarbeiterIdList: 'erstellerMitarbeiterIdList',
  kundennummer: 'kundennummer',
  kurzBezeichnung: 'kurzBezeichnung',
  showArchived: 'showArchived',
  tagList: 'tagList',
  typeList: 'typeList',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TRechtstraegerQueryParams => ({
  createTsFrom: formValues.createTsFrom ? mapFormDateValueToDateString(formValues.createTsFrom) : null,
  createTsTo: formValues.createTsTo ? mapFormDateValueToDateString(formValues.createTsTo) : null,
  erstellerMitarbeiterIdList: formValues.erstellerMitarbeiterIdList,
  kundennummer: formValues.kundennummer,
  kurzBezeichnung: formValues.kurzBezeichnung,
  showArchived: formValues.showArchived,
  tagList: formValues.tagList,
  typeList: formValues.typeList,
});

export const mapQueryParamsToFormValues = (queryParams: TRechtstraegerQueryParams): FiltersFormValues => ({
  createTsFrom: queryParams.createTsFrom,
  createTsTo: queryParams.createTsTo,
  erstellerMitarbeiterIdList: queryParams.erstellerMitarbeiterIdList,
  kundennummer: queryParams.kundennummer,
  kurzBezeichnung: queryParams.kurzBezeichnung,
  showArchived: queryParams.showArchived,
  tagList: queryParams.tagList,
  typeList: queryParams.typeList,
});

export const mapQueryParamsToRechtstreagerListQueryVariables = (queryParams: TRechtstraegerQueryParams): QueryGetRechtstraegerListArgs => ({
  ...queryParams,
  includeArchiviert: queryParams.showArchived,
  kundennummer: queryParams.kundennummer?.toString(),
  kurzBezeichnung: queryParams.kurzBezeichnung?.toString(),
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'createTsTo' | 'showArchived' | 'emailAddress'>> = {
  [filtersFormFields.createTsFrom]: 'Erstellt am',
  [filtersFormFields.erstellerMitarbeiterIdList]: 'Ersteller',
  [filtersFormFields.kundennummer]: 'Kundennummer',
  [filtersFormFields.kurzBezeichnung]: 'Bezeichnung',
  [filtersFormFields.tagList]: 'Merkmale',
  [filtersFormFields.typeList]: 'Typ',
};
