import { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { EmployeeApproval } from '../../../../types';
import { EmployeeApprovalAll, paymentListingLabelList, TEmployeeApproval } from './paymentListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';

type EmployeeApprovalFilterProps = Omit<SelectProps, 'id'>;

type EmployeeApprovalOption = { text: string; value: TEmployeeApproval };

const options: EmployeeApprovalOption[] = [
  { text: 'Von mir freizugeben', value: EmployeeApproval.ToApproveByMe },
  { text: 'Von mir freigegeben', value: EmployeeApproval.ApprovedByMe },
  { text: 'Alle anzeigen', value: EmployeeApprovalAll.All },
];

const EmployeeApprovalFilter: FC<EmployeeApprovalFilterProps> = ({ ...restProps }) => {
  return (
    <FormItemWithoutColon name={restProps.name} label={paymentListingLabelList.employeeApproval}>
      <Select {...restProps} id={restProps.name} name={restProps.name} placeholder="Freigabe auswählen" allowClear>
        {options.map((option) => (
          <Select.Option key={option.text} value={option.value}>
            {option.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithoutColon>
  );
};

export default EmployeeApprovalFilter;
