import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { OrderBuchungsanweisungFieldsFragmentDoc } from './SingleInvoiceFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateStandingInvoiceMutationVariables = Types.Exact<{
  input: Types.StandingInvoiceCreateInput;
}>;

export type CreateStandingInvoiceMutation = {
  createStandingInvoice: {
    data: { belegId: string; fileId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateStandingInvoiceMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
  input: Types.StandingInvoiceUpdateInput;
}>;

export type UpdateStandingInvoiceMutation = {
  updateStandingInvoice: {
    data: { belegId: string; fileId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteStandingInvoiceMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type DeleteStandingInvoiceMutation = {
  deleteStandingInvoice: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type StandingInvoiceFreigebenMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type StandingInvoiceFreigebenMutation = {
  actionStandingInvoiceFreigeben: {
    data: { belegId: string; ausstellerRechtstraegerId: string; payeeRechtstraegerId: string; objektId?: string | null; rechnungsNummer: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type StandingInvoiceRestoreMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type StandingInvoiceRestoreMutation = {
  actionStandingInvoiceRestore: {
    data: { belegId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type StandingInvoiceBuchungPreviewMutationVariables = Types.Exact<{
  input: Types.StandingInvoiceCreateInput;
}>;

export type StandingInvoiceBuchungPreviewMutation = {
  standingInvoiceBuchungPreview: {
    data: Array<{
      partialAmountIndex: number;
      partialAmountName: string;
      buchung: {
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type StandingInvoiceStornoBuchungPreviewMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
  input: Types.StandingInvoiceUpdateInput;
}>;

export type StandingInvoiceStornoBuchungPreviewMutation = {
  standingInvoiceStornoBuchungPreview: {
    data: {
      korrekturBuchungList: Array<{
        partialAmountIndex: number;
        partialAmountName: string;
        buchung: {
          buchungsdatum: string;
          buchungsanweisungId: string;
          fibuBuchungId?: string | null;
          buchungszeileList: Array<{
            betrag: number;
            buchungszeileId: string;
            kontoId?: string | null;
            steuer?: number | null;
            steuercode?: string | null;
            steuersatz?: number | null;
            objektId?: string | null;
            text: string;
            gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
            kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
            sollHaben: { text: string; value: Types.SollHaben };
          }>;
          fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
          status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
        };
      }>;
      originalBuchungList: Array<{
        partialAmountIndex: number;
        partialAmountName: string;
        buchung: {
          buchungsdatum: string;
          buchungsanweisungId: string;
          fibuBuchungId?: string | null;
          buchungszeileList: Array<{
            betrag: number;
            buchungszeileId: string;
            kontoId?: string | null;
            steuer?: number | null;
            steuercode?: string | null;
            steuersatz?: number | null;
            objektId?: string | null;
            text: string;
            gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
            kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
            sollHaben: { text: string; value: Types.SollHaben };
          }>;
          fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
          status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
        };
      }>;
      stornoBuchungList: Array<{
        partialAmountIndex: number;
        partialAmountName: string;
        buchung: {
          buchungsdatum: string;
          buchungsanweisungId: string;
          fibuBuchungId?: string | null;
          buchungszeileList: Array<{
            betrag: number;
            buchungszeileId: string;
            kontoId?: string | null;
            steuer?: number | null;
            steuercode?: string | null;
            steuersatz?: number | null;
            objektId?: string | null;
            text: string;
            gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
            kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
            sollHaben: { text: string; value: Types.SollHaben };
          }>;
          fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
          status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
        };
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type StandingInvoiceUpdatePreviewMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
  input: Types.StandingInvoiceUpdateInput;
}>;

export type StandingInvoiceUpdatePreviewMutation = {
  standingInvoiceUpdatePreview: {
    data: Array<{ partialAmountAction: string; partialAmountDetails: string; partialAmountIndex: number }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateStandingInvoiceDocument = gql`
  mutation CreateStandingInvoice($input: StandingInvoiceCreateInput!) {
    createStandingInvoice(input: $input) {
      data {
        belegId
        fileId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateStandingInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateStandingInvoiceMutation, CreateStandingInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateStandingInvoiceMutation, CreateStandingInvoiceMutationVariables>(CreateStandingInvoiceDocument, options);
}
export type CreateStandingInvoiceMutationHookResult = ReturnType<typeof useCreateStandingInvoiceMutation>;
export type CreateStandingInvoiceMutationResult = Apollo.MutationResult<CreateStandingInvoiceMutation>;
export type CreateStandingInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateStandingInvoiceMutation, CreateStandingInvoiceMutationVariables>;
export const UpdateStandingInvoiceDocument = gql`
  mutation UpdateStandingInvoice($belegId: String!, $input: StandingInvoiceUpdateInput!) {
    updateStandingInvoice(belegId: $belegId, input: $input) {
      data {
        belegId
        fileId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateStandingInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateStandingInvoiceMutation, UpdateStandingInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStandingInvoiceMutation, UpdateStandingInvoiceMutationVariables>(UpdateStandingInvoiceDocument, options);
}
export type UpdateStandingInvoiceMutationHookResult = ReturnType<typeof useUpdateStandingInvoiceMutation>;
export type UpdateStandingInvoiceMutationResult = Apollo.MutationResult<UpdateStandingInvoiceMutation>;
export type UpdateStandingInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateStandingInvoiceMutation, UpdateStandingInvoiceMutationVariables>;
export const DeleteStandingInvoiceDocument = gql`
  mutation DeleteStandingInvoice($belegId: String!) {
    deleteStandingInvoice(belegId: $belegId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteStandingInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteStandingInvoiceMutation, DeleteStandingInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteStandingInvoiceMutation, DeleteStandingInvoiceMutationVariables>(DeleteStandingInvoiceDocument, options);
}
export type DeleteStandingInvoiceMutationHookResult = ReturnType<typeof useDeleteStandingInvoiceMutation>;
export type DeleteStandingInvoiceMutationResult = Apollo.MutationResult<DeleteStandingInvoiceMutation>;
export type DeleteStandingInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteStandingInvoiceMutation, DeleteStandingInvoiceMutationVariables>;
export const StandingInvoiceFreigebenDocument = gql`
  mutation StandingInvoiceFreigeben($belegId: String!) {
    actionStandingInvoiceFreigeben(belegId: $belegId) {
      data {
        belegId
        ausstellerRechtstraegerId
        payeeRechtstraegerId
        objektId
        rechnungsNummer
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useStandingInvoiceFreigebenMutation(
  baseOptions?: Apollo.MutationHookOptions<StandingInvoiceFreigebenMutation, StandingInvoiceFreigebenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StandingInvoiceFreigebenMutation, StandingInvoiceFreigebenMutationVariables>(StandingInvoiceFreigebenDocument, options);
}
export type StandingInvoiceFreigebenMutationHookResult = ReturnType<typeof useStandingInvoiceFreigebenMutation>;
export type StandingInvoiceFreigebenMutationResult = Apollo.MutationResult<StandingInvoiceFreigebenMutation>;
export type StandingInvoiceFreigebenMutationOptions = Apollo.BaseMutationOptions<
  StandingInvoiceFreigebenMutation,
  StandingInvoiceFreigebenMutationVariables
>;
export const StandingInvoiceRestoreDocument = gql`
  mutation StandingInvoiceRestore($belegId: String!) {
    actionStandingInvoiceRestore(belegId: $belegId) {
      data {
        belegId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useStandingInvoiceRestoreMutation(
  baseOptions?: Apollo.MutationHookOptions<StandingInvoiceRestoreMutation, StandingInvoiceRestoreMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StandingInvoiceRestoreMutation, StandingInvoiceRestoreMutationVariables>(StandingInvoiceRestoreDocument, options);
}
export type StandingInvoiceRestoreMutationHookResult = ReturnType<typeof useStandingInvoiceRestoreMutation>;
export type StandingInvoiceRestoreMutationResult = Apollo.MutationResult<StandingInvoiceRestoreMutation>;
export type StandingInvoiceRestoreMutationOptions = Apollo.BaseMutationOptions<
  StandingInvoiceRestoreMutation,
  StandingInvoiceRestoreMutationVariables
>;
export const StandingInvoiceBuchungPreviewDocument = gql`
  mutation StandingInvoiceBuchungPreview($input: StandingInvoiceCreateInput!) {
    standingInvoiceBuchungPreview(input: $input) {
      data {
        buchung {
          ...OrderBuchungsanweisungFields
        }
        partialAmountIndex
        partialAmountName
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderBuchungsanweisungFieldsFragmentDoc}
`;
export function useStandingInvoiceBuchungPreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<StandingInvoiceBuchungPreviewMutation, StandingInvoiceBuchungPreviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StandingInvoiceBuchungPreviewMutation, StandingInvoiceBuchungPreviewMutationVariables>(
    StandingInvoiceBuchungPreviewDocument,
    options
  );
}
export type StandingInvoiceBuchungPreviewMutationHookResult = ReturnType<typeof useStandingInvoiceBuchungPreviewMutation>;
export type StandingInvoiceBuchungPreviewMutationResult = Apollo.MutationResult<StandingInvoiceBuchungPreviewMutation>;
export type StandingInvoiceBuchungPreviewMutationOptions = Apollo.BaseMutationOptions<
  StandingInvoiceBuchungPreviewMutation,
  StandingInvoiceBuchungPreviewMutationVariables
>;
export const StandingInvoiceStornoBuchungPreviewDocument = gql`
  mutation StandingInvoiceStornoBuchungPreview($belegId: String!, $input: StandingInvoiceUpdateInput!) {
    standingInvoiceStornoBuchungPreview(belegId: $belegId, input: $input) {
      data {
        korrekturBuchungList {
          buchung {
            ...OrderBuchungsanweisungFields
          }
          partialAmountIndex
          partialAmountName
        }
        originalBuchungList {
          buchung {
            ...OrderBuchungsanweisungFields
          }
          partialAmountIndex
          partialAmountName
        }
        stornoBuchungList {
          buchung {
            ...OrderBuchungsanweisungFields
          }
          partialAmountIndex
          partialAmountName
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderBuchungsanweisungFieldsFragmentDoc}
`;
export function useStandingInvoiceStornoBuchungPreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<StandingInvoiceStornoBuchungPreviewMutation, StandingInvoiceStornoBuchungPreviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StandingInvoiceStornoBuchungPreviewMutation, StandingInvoiceStornoBuchungPreviewMutationVariables>(
    StandingInvoiceStornoBuchungPreviewDocument,
    options
  );
}
export type StandingInvoiceStornoBuchungPreviewMutationHookResult = ReturnType<typeof useStandingInvoiceStornoBuchungPreviewMutation>;
export type StandingInvoiceStornoBuchungPreviewMutationResult = Apollo.MutationResult<StandingInvoiceStornoBuchungPreviewMutation>;
export type StandingInvoiceStornoBuchungPreviewMutationOptions = Apollo.BaseMutationOptions<
  StandingInvoiceStornoBuchungPreviewMutation,
  StandingInvoiceStornoBuchungPreviewMutationVariables
>;
export const StandingInvoiceUpdatePreviewDocument = gql`
  mutation StandingInvoiceUpdatePreview($belegId: String!, $input: StandingInvoiceUpdateInput!) {
    standingInvoiceUpdatePreview(belegId: $belegId, input: $input) {
      data {
        partialAmountAction
        partialAmountDetails
        partialAmountIndex
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useStandingInvoiceUpdatePreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<StandingInvoiceUpdatePreviewMutation, StandingInvoiceUpdatePreviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StandingInvoiceUpdatePreviewMutation, StandingInvoiceUpdatePreviewMutationVariables>(
    StandingInvoiceUpdatePreviewDocument,
    options
  );
}
export type StandingInvoiceUpdatePreviewMutationHookResult = ReturnType<typeof useStandingInvoiceUpdatePreviewMutation>;
export type StandingInvoiceUpdatePreviewMutationResult = Apollo.MutationResult<StandingInvoiceUpdatePreviewMutation>;
export type StandingInvoiceUpdatePreviewMutationOptions = Apollo.BaseMutationOptions<
  StandingInvoiceUpdatePreviewMutation,
  StandingInvoiceUpdatePreviewMutationVariables
>;
