import React from 'react';
import { MenuProps, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { EuroCircleOutlined, ReadOutlined } from '@ant-design/icons';
import { BookingSuggestionVerbuchenGenerierlaufEntry, BookingSuggestionVerbuchenGenerierlaufEntryDetails } from '../../../../types';
import { EuroAmount } from '../../../../components/Number';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import AvatarBasedOnBelegSymbolWithTooltip from '../../../../components/Avatar/BasedOnBelegSymbol/AvatarBasedOnBelegSymbolWithTooltip';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';
import { generatePathToPaymentPage } from '../../../Payment/Listing/Filters/filtersQueryParams';
import { NestedTableColProps } from '../../../../components/Table/NestedTable/NestedTable';
import { generatePathToBankstatementListingPage } from '../../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';
import { isNotNil } from '../../../../helpers/assertionHelper';
import { generatePathToBookingDetailsPage } from '../../../BookingDetails/bookingDetailsPaths';

const receivedBankTransactionTableColumns = (
  entry: BookingSuggestionVerbuchenGenerierlaufEntry
): NestedTableColProps<BookingSuggestionVerbuchenGenerierlaufEntryDetails>[] => [
  {
    title: 'Transaktionsart',
    render: (text, record) => <Typography.Text>{record.transactionType.text}</Typography.Text>,
  },
  {
    title: 'Datenträger',
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.dataCarrierCamtName}>
        {(shortenedText) => (
          <Link
            target="_blank"
            to={generatePathToBankstatementListingPage({
              name: record.dataCarrierCamtName,
              statementNumber: record.statementNumber,
            })}
          >
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Kontoauszugsnummer',
    render: (text, record) => <DataWithShortenedText text={record.statementNumber} maxTextLength={15} />,
  },
  {
    title: 'Valutadatum',
    render: (text, record) => (record.valueDate ? <CustomFormattedDate value={record.valueDate} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Rücklastschrift',
    render: (text, record) => {
      return <Typography.Text>{record.reversal ? 'JA' : 'NEIN'}</Typography.Text>;
    },
  },
  {
    title: 'Betrag',
    render: (text, record) => <EuroAmount value={record.amount} />,
  },
  {
    title: 'Spesen in eigener Bankbuchung',
    render: (text, record) =>
      isNotNil(record.chargesInSeparateEntry) ? (
        <Typography.Text>{record.chargesInSeparateEntry ? 'Ja' : 'Nein'}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Belegsymbol',
    render: (text, record) => <AvatarBasedOnBelegSymbolWithTooltip belegSymbol={record.belegSymbol} size="small" />,
  },
  {
    title: 'Buchungstyp',
    render: (text, record) => <Typography.Text>{record.buchungType.text}</Typography.Text>,
  },
  {
    title: 'Aktion',
    render: (text, record) => {
      const items: MenuProps['items'] = [
        {
          key: '1',
          label: (
            <Link
              to={generatePathToPaymentPage({
                objektIdList: [entry.objekt.objektId],
                paymentTypeList: [entry.paymentType.value],
                paymentTransactionType: entry.paymentTransactionType.value,
                rechnungsAusstellerId: entry.buchungskreisRechtstraeger.rechtstraegerId,
                payeeId: entry.payee.rechtstraegerId,
                paymentProposalId: entry.paymentProposalId,
                paymentDateFrom: record.valueDate,
              })}
              target="_blank"
            >
              Zahlung ansehen
            </Link>
          ),
          icon: <EuroCircleOutlined />,
        },
        {
          key: '2',
          label: (
            <Link to={generatePathToBookingDetailsPage(record.fibuBuchungId ? [record.fibuBuchungId] : [])} target="_blank">
              Buchungsdetails ansehen
            </Link>
          ),
          icon: <ReadOutlined />,
          disabled: !record.fibuBuchungId,
        },
      ];

      return <ActionDropdown items={items} />;
    },
  },
];

export default receivedBankTransactionTableColumns;
