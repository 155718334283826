import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../../../../components/message/validationMsg';
import { kellerabteilCreateFormFields } from '../Create/kellerabteilCreateFormMapper';

/* eslint-disable no-template-curly-in-string */
export const kellerabteilValidationSchema = Yup.object().shape({
  [kellerabteilCreateFormFields.nummer]: Yup.string().required(entityIsRequired('Nummer')).max(10, entityMaxCharLength('Nummer', 10)),
  [kellerabteilCreateFormFields.text]: Yup.string().max(50, entityMaxCharLength('Text', 50)),
});
/* eslint-enable no-template-curly-in-string */
