import React from 'react';
import { useParams } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import ObjektCard from '../../features/Objekt/Card/ObjektCard';
import menuListObjekt from './menuListObjekt';
import { useObjektQuery } from '../../features/Objekt/gql/ObjektQueries.types';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import { ObjektDetailsPageRouteParams } from './routes';
import { Objekt } from '../../types';
import { useOnObjektDataChangedEvent } from '../../features/Objekt/useOnObjektDataChangedEvent';

const ObjektDetailsPage = () => {
  const { objektId } = useParams() as ObjektDetailsPageRouteParams;

  const { data, refetch, networkStatus } = useObjektQuery({
    variables: {
      objektId,
      withDetails: true,
    },
    notifyOnNetworkStatusChange: true,
  });
  const objekt = data?.getObjekt.data;
  const isRefetchingObjekt = networkStatus === NetworkStatus.refetch;
  const loading = !objekt;

  useOnObjektDataChangedEvent('Objekt', refetch);

  return (
    <DetailsPageTemplate<Objekt>
      data={objekt}
      pageTitle={(objekt) => objekt.kurzBezeichnung}
      card={(objekt) => <ObjektCard objekt={objekt} loading={loading} onSuccess={refetch} />}
      sidebarMenuList={(objekt) => menuListObjekt(objekt, refetch, isRefetchingObjekt)}
    />
  );
};

export default ObjektDetailsPage;
