import React, { forwardRef, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  color?: string;
  to: string;
  openLinkInNewTab?: boolean;
};

const TicketPreviewLink = forwardRef<HTMLAnchorElement, PropsWithChildren<Props>>(
  ({ color = 'inherit', to, openLinkInNewTab, children, ...props }, ref) => (
    <Link
      ref={ref}
      style={{ color }}
      to={to}
      target={openLinkInNewTab ? '_blank' : '_self'}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...props}
    >
      {children}
    </Link>
  )
);

export default TicketPreviewLink;
