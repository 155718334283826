import { useParams } from 'react-router-dom';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { useAuth } from '../../shared/Auth/authContext';
import { ObjektDetailsPageRouteParams } from '../../pages/ObjektDetailsPage/routes';

export const useOnObjektDataChangedEvent = (key: string, callback: () => Promise<unknown>) => {
  const {
    activeForFirmendaten: { kundenSystemId },
    user,
  } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  const { objektId } = useParams() as ObjektDetailsPageRouteParams;

  useOnDataChangedEvent({
    filter: {
      userId: user.username,
      mitarbeiterId: mitarbeiter?.mitarbeiterId,
      type: dataChangedSubscriptionTypes.objekt,
      params: {
        kundenSystemId,
        objektId,
      },
    },
    notificationDescription: 'Objekt wurde gerade verändert',
    callback,
    key,
  });
};
