import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from '../filtersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FibuBaBestandseinheitSelect from '../../../../BookingInstruction/shared/Filters/FibuBaBestandseinheitSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
};

const BestandseinheitSelectFormItem: FC<Props> = ({ formikProps }) => {
  return (
    <FormItemWithoutColon name={filtersFormFields.bestandseinheitId} label={listingLabelList.bestandseinheitId}>
      <FibuBaBestandseinheitSelect
        name={filtersFormFields.bestandseinheitId}
        onChange={formikProps.submitForm}
        rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
      />
    </FormItemWithoutColon>
  );
};

export default BestandseinheitSelectFormItem;
