import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Switch } from 'formik-antd';
import { Space, Typography } from 'antd';
import { heOrSubAbrDefGeneralSettingsFormFields, HeOrSubAbrDefGeneralSettingsFormValues } from './heOrSubAbrDefGeneralSettingsFormMapper';
import { StyledParamFormItemForWizard } from '../../../../../../shared/styled/WizardSpace.style';

type Props = {
  formikProps: FormikProps<HeOrSubAbrDefGeneralSettingsFormValues>;
  isDisabled: boolean;
  onChange?: (formValues: HeOrSubAbrDefGeneralSettingsFormValues) => void;
  fieldHelp?: string | null;
};

const RueckstaendeInAbrechnungsergebnisFormItem: FC<Props> = ({ formikProps, isDisabled, onChange, fieldHelp }) => (
  <StyledParamFormItemForWizard
    name={heOrSubAbrDefGeneralSettingsFormFields.rueckstaendeInAbrechnungsergebnis}
    label="Rückstände der Abrechnungsperiode in Abrechnungsergebnis berücksichtigen"
    fieldHelp={fieldHelp}
  >
    <Space>
      <Switch
        name={heOrSubAbrDefGeneralSettingsFormFields.rueckstaendeInAbrechnungsergebnis}
        size="small"
        onChange={(value) => onChange?.({ ...formikProps.values, rueckstaendeInAbrechnungsergebnis: value })}
        disabled={isDisabled || !formikProps.values.rueckstaendeAnzeigen}
      />
      <Typography.Text>{formikProps.values.rueckstaendeInAbrechnungsergebnis ? 'Ja' : 'Nein'}</Typography.Text>
    </Space>
  </StyledParamFormItemForWizard>
);

export default RueckstaendeInAbrechnungsergebnisFormItem;
