import React, { FC } from 'react';
import { DatePicker, Form, Input } from 'formik-antd';
import { Formik } from 'formik';
import { Col, Row, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { showSuccessMsgCreate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useCreateEinmalzahlungMutation } from '../gql/HeVertragAuszahlungMutations.types';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import RechtstraegerSelect from '../../../../../shared/Rechtstraeger/RechtstraegerSelect';
import RechtstraegerBankDetailsSelect from '../../../../BankDetails/Select/RechtstraegerBankDetailsSelect';
import {
  mapFormValuesToZahlungsregel,
  zahlungsverlaufFormFields,
  zahlungsverlaufFormInitialValues,
  ZahlungsverlaufFormValues,
} from './zahlungsverlaufFormMapper';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../components/DatePicker/DatePicker_formikAntD';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';
import FormButtons from '../../../../../components/Button/FormButtons';
import { zahlungsverlaufFormValidationSchema } from './zahlungsverlaufFormValidationSchema';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';

type Props = {
  objektId: string;
  heVertragId: string;
  onSuccess: () => void;
  onCancel: () => void;
  vertragspartnerId?: string;
};

const ZahlungsverlaufForm: FC<Props> = ({ objektId, heVertragId, onSuccess, onCancel, vertragspartnerId }) => {
  const entity = 'Einmalzahlung';

  const [runCreate] = useCreateEinmalzahlungMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<ZahlungsverlaufFormValues>
      initialValues={zahlungsverlaufFormInitialValues(vertragspartnerId)}
      validationSchema={zahlungsverlaufFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToZahlungsregel(values);
        runCreate({ variables: { objektId, heVertragId, input } }).finally(() => setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Empfänger</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithoutColon name={zahlungsverlaufFormFields.zahlungsempfaengerId} label="Empfänger">
                <RechtstraegerSelect
                  name={zahlungsverlaufFormFields.zahlungsempfaengerId}
                  onNewItemCreated={(rechtstraegerId) => formikProps.setFieldValue(zahlungsverlaufFormFields.zahlungsempfaengerId, rechtstraegerId)}
                  onNewItemCreateSelected={() => formikProps.setFieldValue(zahlungsverlaufFormFields.zahlungsempfaengerId, '')}
                  onChange={() => formikProps.setFieldValue(zahlungsverlaufFormFields.zahlungsempfaengerBankDetailsId, undefined)}
                  placeholder="Empfänger auswählen"
                />
              </FormItemWithoutColon>
              <FormItemWithoutColon name={zahlungsverlaufFormFields.zahlungsempfaengerBankDetailsId} label="Bankverbindung">
                <RechtstraegerBankDetailsSelect
                  name={zahlungsverlaufFormFields.zahlungsempfaengerBankDetailsId}
                  rechtstraegerId={formikProps.values.zahlungsempfaengerId}
                  onHasOnlyOneBankDetails={(id) => {
                    formikProps.setFieldValue(zahlungsverlaufFormFields.zahlungsempfaengerBankDetailsId, id);
                  }}
                  onNewItemCreateSelected={() => formikProps.setFieldValue(zahlungsverlaufFormFields.zahlungsempfaengerBankDetailsId, '')}
                />
              </FormItemWithoutColon>
            </Col>
          </Row>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Durchführung</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithoutColon name={zahlungsverlaufFormFields.zahlungsdatum} label="Zahlungsdatum">
                <DatePicker
                  size="small"
                  id={zahlungsverlaufFormFields.zahlungsdatum}
                  name={zahlungsverlaufFormFields.zahlungsdatum}
                  style={{ width: '100%' }}
                  format={DATE_DISPLAY_FORMAT_DEFAULT}
                  placeholder="Datum auswählen"
                  disabledDate={(date) => dayjsCustom().isAfter(date as unknown as Dayjs, 'day')}
                />
              </FormItemWithoutColon>
              <FormItemWithoutColon name={zahlungsverlaufFormFields.verwendungszweck} label="Verwendungszweck">
                <Input
                  id={zahlungsverlaufFormFields.verwendungszweck}
                  name={zahlungsverlaufFormFields.verwendungszweck}
                  size="small"
                  placeholder="Verwendungszweck eingeben"
                  disabled={!!formikProps.values.zahlungsreferenz}
                />
              </FormItemWithoutColon>
              <FormItemWithoutColon name={zahlungsverlaufFormFields.zahlungsreferenz} label="Zahlungsreferenz">
                <Input
                  id={zahlungsverlaufFormFields.zahlungsreferenz}
                  name={zahlungsverlaufFormFields.zahlungsreferenz}
                  size="small"
                  placeholder="Zahlungsreferenz eingeben"
                  disabled={!!formikProps.values.verwendungszweck}
                />
              </FormItemWithoutColon>
            </Col>
          </Row>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Durchführung</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithoutColon name={zahlungsverlaufFormFields.betrag} label="Betrag">
                <FormattedDecimalFormInput
                  id={zahlungsverlaufFormFields.betrag}
                  name={zahlungsverlaufFormFields.betrag}
                  placeholder="z.B. 1.000,00"
                  size="small"
                  style={{ width: '100%' }}
                  min={0}
                  isCurrency
                />
              </FormItemWithoutColon>
            </Col>
          </Row>
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default ZahlungsverlaufForm;
