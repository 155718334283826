import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from '../../../graphql/Authentication/UserFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AdminListQuery = {
  getUsers: {
    data?: Array<{
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    }> | null;
  };
};

export type AuditLogListForAdminQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;

export type AuditLogListForAdminQuery = {
  getAuditLogList: {
    data?: Array<{
      changedBy?: string | null;
      changedTs?: string | null;
      email?: string | null;
      emailUnverified?: string | null;
      lastPasswordChangeTs?: string | null;
      lastname?: string | null;
      firstname?: string | null;
      status: { description?: string | null; text: string; value: Types.UserStatus };
    }> | null;
  };
};

export type UserChangeLogListForAdminQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;

export type UserChangeLogListForAdminQuery = {
  getUserChangeLog: {
    data: {
      totalElements?: string | null;
      contentList?: Array<{
        changeType?: string | null;
        changedBy?: string | null;
        changedTs?: string | null;
        newValue?: string | null;
        oldValue?: string | null;
        type?: string | null;
      }> | null;
    };
  };
};

export const AdminListDocument = gql`
  query AdminList {
    getUsers(role: "ADMIN") {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function useAdminListQuery(baseOptions?: Apollo.QueryHookOptions<AdminListQuery, AdminListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListQuery, AdminListQueryVariables>(AdminListDocument, options);
}
export function useAdminListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminListQuery, AdminListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminListQuery, AdminListQueryVariables>(AdminListDocument, options);
}
export function useAdminListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminListQuery, AdminListQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminListQuery, AdminListQueryVariables>(AdminListDocument, options);
}
export type AdminListQueryHookResult = ReturnType<typeof useAdminListQuery>;
export type AdminListLazyQueryHookResult = ReturnType<typeof useAdminListLazyQuery>;
export type AdminListSuspenseQueryHookResult = ReturnType<typeof useAdminListSuspenseQuery>;
export type AdminListQueryResult = Apollo.QueryResult<AdminListQuery, AdminListQueryVariables>;
export const AuditLogListForAdminDocument = gql`
  query AuditLogListForAdmin($username: String!) {
    getAuditLogList(username: $username) {
      data {
        changedBy
        changedTs
        email
        emailUnverified
        lastPasswordChangeTs
        lastname
        status {
          description
          text
          value
        }
        firstname
      }
    }
  }
`;
export function useAuditLogListForAdminQuery(
  baseOptions: Apollo.QueryHookOptions<AuditLogListForAdminQuery, AuditLogListForAdminQueryVariables> &
    ({ variables: AuditLogListForAdminQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditLogListForAdminQuery, AuditLogListForAdminQueryVariables>(AuditLogListForAdminDocument, options);
}
export function useAuditLogListForAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuditLogListForAdminQuery, AuditLogListForAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditLogListForAdminQuery, AuditLogListForAdminQueryVariables>(AuditLogListForAdminDocument, options);
}
export function useAuditLogListForAdminSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuditLogListForAdminQuery, AuditLogListForAdminQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuditLogListForAdminQuery, AuditLogListForAdminQueryVariables>(AuditLogListForAdminDocument, options);
}
export type AuditLogListForAdminQueryHookResult = ReturnType<typeof useAuditLogListForAdminQuery>;
export type AuditLogListForAdminLazyQueryHookResult = ReturnType<typeof useAuditLogListForAdminLazyQuery>;
export type AuditLogListForAdminSuspenseQueryHookResult = ReturnType<typeof useAuditLogListForAdminSuspenseQuery>;
export type AuditLogListForAdminQueryResult = Apollo.QueryResult<AuditLogListForAdminQuery, AuditLogListForAdminQueryVariables>;
export const UserChangeLogListForAdminDocument = gql`
  query UserChangeLogListForAdmin($username: String!) {
    getUserChangeLog(username: $username) {
      data {
        contentList {
          changeType
          changedBy
          changedTs
          newValue
          oldValue
          type
        }
        totalElements
      }
    }
  }
`;
export function useUserChangeLogListForAdminQuery(
  baseOptions: Apollo.QueryHookOptions<UserChangeLogListForAdminQuery, UserChangeLogListForAdminQueryVariables> &
    ({ variables: UserChangeLogListForAdminQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserChangeLogListForAdminQuery, UserChangeLogListForAdminQueryVariables>(UserChangeLogListForAdminDocument, options);
}
export function useUserChangeLogListForAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserChangeLogListForAdminQuery, UserChangeLogListForAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserChangeLogListForAdminQuery, UserChangeLogListForAdminQueryVariables>(UserChangeLogListForAdminDocument, options);
}
export function useUserChangeLogListForAdminSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserChangeLogListForAdminQuery, UserChangeLogListForAdminQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserChangeLogListForAdminQuery, UserChangeLogListForAdminQueryVariables>(UserChangeLogListForAdminDocument, options);
}
export type UserChangeLogListForAdminQueryHookResult = ReturnType<typeof useUserChangeLogListForAdminQuery>;
export type UserChangeLogListForAdminLazyQueryHookResult = ReturnType<typeof useUserChangeLogListForAdminLazyQuery>;
export type UserChangeLogListForAdminSuspenseQueryHookResult = ReturnType<typeof useUserChangeLogListForAdminSuspenseQuery>;
export type UserChangeLogListForAdminQueryResult = Apollo.QueryResult<UserChangeLogListForAdminQuery, UserChangeLogListForAdminQueryVariables>;
