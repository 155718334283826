import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeVertragVersionListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BeVertragVersionListQuery = {
  getBeVertragVersionList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      vertragVersionId: string;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        vertragVersionId: string;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
        paymentMethod: { text: string; value: Types.PaymentMethod };
        verrechnungsart: { text: string; value: Types.Verrechnungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      paymentMethod: { text: string; value: Types.PaymentMethod };
      verrechnungsart: { text: string; value: Types.Verrechnungsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BeVertragVersionListDocument = gql`
  query BeVertragVersionList($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $withDetails: Boolean) {
    getBeVertragVersionList(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, withDetails: $withDetails) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        vertragVersionId
        dueDateVorschreibung {
          text
          value
        }
        validFrom
        historicizedList {
          createTs
          createdBy
          createdByMitarbeiterId
          current
          vertragVersionId
          dueDateVorschreibung {
            text
            value
          }
          validFrom
          kommentar
          lastUpdateTs
          paymentMethod {
            text
            value
          }
          verrechnungsart {
            text
            value
          }
          warningList {
            attribute
            message
            type
          }
        }
        kommentar
        lastUpdateTs
        paymentMethod {
          text
          value
        }
        verrechnungsart {
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBeVertragVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragVersionListQuery, BeVertragVersionListQueryVariables> &
    ({ variables: BeVertragVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragVersionListQuery, BeVertragVersionListQueryVariables>(BeVertragVersionListDocument, options);
}
export function useBeVertragVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeVertragVersionListQuery, BeVertragVersionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragVersionListQuery, BeVertragVersionListQueryVariables>(BeVertragVersionListDocument, options);
}
export function useBeVertragVersionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeVertragVersionListQuery, BeVertragVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragVersionListQuery, BeVertragVersionListQueryVariables>(BeVertragVersionListDocument, options);
}
export type BeVertragVersionListQueryHookResult = ReturnType<typeof useBeVertragVersionListQuery>;
export type BeVertragVersionListLazyQueryHookResult = ReturnType<typeof useBeVertragVersionListLazyQuery>;
export type BeVertragVersionListSuspenseQueryHookResult = ReturnType<typeof useBeVertragVersionListSuspenseQuery>;
export type BeVertragVersionListQueryResult = Apollo.QueryResult<BeVertragVersionListQuery, BeVertragVersionListQueryVariables>;
