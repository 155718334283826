import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { Abrechnungskreis } from '../../../../objektAbrechnung-types';
import OAAbrKreisSummeAusgaben from '../shared/OAAbrKreisSummeAusgaben';
import OAAbrKreisBuchungenEinnahme from '../shared/OAAbrKreisBuchungenEinnahme';
import OAAbrKreisSummeEinnahmen from '../shared/OAAbrKreisSummeEinnahmen';
import OAAbrKreisSummeErgebnis from '../shared/OAAbrKreisSummeErgebnis';
import OAAbrKreisKontoList from '../shared/OAAbrKreisKontoList';
import OAAbrKreisExpenseReducingKontoList from '../shared/OAAbrKreisExpenseReducingKontoList';

const OAAbrKreisNonRepFonds: FC<{ abrechKreis: Abrechnungskreis }> = ({ abrechKreis }) => (
  <View>
    {/* Ausgaben-Konten-Auflistung */}
    <OAAbrKreisKontoList abrechKreis={abrechKreis} />

    {/* Ausgaben-Konten-Auflistung - Reduzierende Konten */}
    {abrechKreis.expenseReducingKontoList.length > 0 && <OAAbrKreisExpenseReducingKontoList abrechKreis={abrechKreis} />}

    {/* Summe Ausgaben */}
    <OAAbrKreisSummeAusgaben abrechKreis={abrechKreis} />

    {abrechKreis.buchungEinnahmenList.length > 0 && (
      <>
        {/* Buchungen Einnahmen */}
        {abrechKreis.buchungEinnahmenList.map((buchungEinname, index) => (
          <OAAbrKreisBuchungenEinnahme buchungEinname={buchungEinname} key={index} />
        ))}
        {/* Summe Einnahmen */}
        <OAAbrKreisSummeEinnahmen abrechKreis={abrechKreis} />
        {/* Summe Ergebnis - SALDO */}
        <OAAbrKreisSummeErgebnis abrechKreis={abrechKreis} />
      </>
    )}
  </View>
);

export default OAAbrKreisNonRepFonds;
