import React, { FC } from 'react';
import { Drawer, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { useToggle } from '../../../hooks/useToggle';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import {
  useArchiveAbrechnungskreisMutation,
  useDeleteAbrechnungskreisMutation,
  useUnarchiveAbrechnungskreisMutation,
} from '../gql/AbrechnungskeisMutations.types';
import AbrechnungskreisForm from '../Form/AbrechnungskreisForm';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { isStatusArchived } from '../../../helpers/statusHelper';
import { Abrechnungskreis } from '../../../types';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import AbrechnungskreisChangeHistoryListingTable from './AbrechnungskreisChangeHistoryListingTable';
import ActionDropdown from '../../../components/Dropdown/ActionDropdown';

type Props = {
  abrechnungskreis: Abrechnungskreis;
  onSuccess: () => void;
};

const AbrechnungskreisTableActions: FC<Props> = ({ abrechnungskreis, onSuccess }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const entity = `Abrechungskreis`;
  const isArchived = isStatusArchived(abrechnungskreis.status);

  const [runDelete] = useDeleteAbrechnungskreisMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onSuccess();
    },
    variables: {
      abrechnungskreisId: abrechnungskreis.abrechnungskreisId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveAbrechnungskreisMutation({
    variables: {
      abrechnungskreisId: abrechnungskreis.abrechnungskreisId,
    },
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveAbrechnungskreisMutation({
    variables: {
      abrechnungskreisId: abrechnungskreis.abrechnungskreisId,
    },
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(runUnarchive, abrechnungskreis) : showConfirmArchive(runArchive, abrechnungskreis),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: !!abrechnungskreis.deletable,
    },
    {
      key: '4',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, abrechnungskreis),
      icon: <DeleteOutlined />,
      disabled: !abrechnungskreis.deletable,
      danger: true,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Drawer title="Abrechnungskreis bearbeiten" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <AbrechnungskreisForm
          onSuccess={() => {
            onSuccess();
            onCollapse();
          }}
          onCancel={onCollapse}
          abrechnungskreis={abrechnungskreis}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <AbrechnungskreisChangeHistoryListingTable abrechnungskreisId={abrechnungskreis.abrechnungskreisId} />
      </HistoryModal>
    </>
  );
};

const showConfirmArchive = (runArchive: () => void, abrechnungskreis: Abrechnungskreis) => () => {
  Modal.confirm({
    title: `Möchten Sie den Abrechungskreis archivieren?`,
    content: `${abrechnungskreis.bezeichnung}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (runUnarchive: () => void, abrechnungskreis: Abrechnungskreis) => () => {
  Modal.confirm({
    title: `Möchten Sie den Abrechungskreis reaktivieren?`,
    content: `${abrechnungskreis.bezeichnung}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};
const showConfirmDelete = (runDelete: () => void, abrechnungskreis: Abrechnungskreis) => () => {
  Modal.confirm({
    title: `Möchten Sie den Abrechungskreis löschen?`,
    content: `${abrechnungskreis.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default AbrechnungskreisTableActions;
