import { Form } from 'formik-antd';
import React, { FC } from 'react';
import { Formik } from 'formik';
import { Space, Typography } from 'antd';
import ErrorMessagePanel from '../../../../../components/Error/ErrorMessagePanel';
import FormButtons from '../../../../../components/Button/FormButtons';
import { onlineBankingFormFields, OnlineBankingFormValues } from './bankstatementOnlineBankingFormMapper';
import { useAuth } from '../../../../../shared/Auth/authContext';
import OnlineBankingFormUpload from './OnlineBankingFormUpload';
import { handleImportBankstatementOnlineBankingSubmit } from '../handleSubmit';

type Props = {
  onAction: () => void;
};

const BankstatementOnlineBankingForm: FC<Props> = ({ onAction }) => {
  const { activeForFirmendatenId } = useAuth();

  return (
    <Formik<OnlineBankingFormValues>
      initialValues={{}}
      onSubmit={(values, { setSubmitting }) => {
        handleImportBankstatementOnlineBankingSubmit(activeForFirmendatenId as string, values, onAction);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Space direction="vertical" size={8}>
            <Typography.Text>Datenträger hochladen (Format: xml, zip)</Typography.Text>
            <OnlineBankingFormUpload
              firmendatenId={activeForFirmendatenId as string}
              formikProps={formikProps}
              name={onlineBankingFormFields.inputFile}
              nameForTempBildFileId={onlineBankingFormFields.tempInputFileId}
              fileId={formikProps.values.inputFileId}
            />
            {formikProps.errors.inputFile && <ErrorMessagePanel error={formikProps.errors.inputFile} />}
          </Space>
          <FormButtons onCancel={onAction} isOkDisabled={formikProps.isSubmitting} updateMode okText="Abschicken" />
        </Form>
      )}
    </Formik>
  );
};

export default BankstatementOnlineBankingForm;
