import React, { FC } from 'react';
import { Divider, Empty, Table } from 'antd';
import { Spacer } from '../../../../../components/Grid';
import { useHeAbrechnungHeVertragListQuery } from '../../gql/HeAbrechnungQueries.types';
import HeAbrHeVertragTable from './HeAbrHeVertragTable';
import errorLogTableColumns from '../../../shared/errorLogTableColumns';
import { ErrorData } from '../../../../../types';

type Props = {
  objektId: string;
  heAbrechnungId: string;
  loading: boolean;
  heAbrErrorList: ErrorData[];
};

const HeAbrHeVertragListingTab: FC<Props> = ({ objektId, heAbrechnungId, loading, heAbrErrorList }) => {
  const { data, loading: loadingHeAbrHeVertragList } = useHeAbrechnungHeVertragListQuery({ variables: { objektId, heAbrechnungId } });

  const heAbrechnungHeVertragList = data?.getHeAbrechnungHeVertragList.data ?? [];

  return (
    <>
      <Spacer height={16} />
      {heAbrErrorList.length > 0 && (
        <>
          <Table<ErrorData>
            size="small"
            pagination={false}
            dataSource={heAbrErrorList}
            rowKey={(record) => record.type}
            columns={errorLogTableColumns}
            locale={{
              emptyText: <Empty description={<span>Keine Fehler vorhanden</span>} />,
            }}
          />
          <Divider />
        </>
      )}
      <HeAbrHeVertragTable
        loading={loading || loadingHeAbrHeVertragList}
        objektId={objektId}
        heAbrechnungId={heAbrechnungId}
        heAbrechnungHeVertragList={heAbrechnungHeVertragList}
      />
    </>
  );
};

export default HeAbrHeVertragListingTab;
