import { SepaLastschriftMandat } from '../templates/sepaLastschriftMandat/sepaLastschriftMandat-types';

export const mockSepaLastschriftMandatWithValues: SepaLastschriftMandat = {
  type: 'SEPA_LASTSCHRIFT_MANDAT',
  title: 'SEPA Lastschrift Mandat (Ermächtigung)',
  mandatReference: {
    label: 'SEPA Mandatsreferenz',
    value: 'f32k1530v01',
  },
  zahlungsempfaengerBlock: {
    zahlungsempfaengerBlockTitle: 'Zahlungsempfänger',
    name: { label: 'Name', value: 'WEG Döblinger Straße' },
    addressLine: { label: 'Anschrift', value: 'Döblinger Hauptstraße 44' },
    zipCode: { label: 'Postleitzahl', value: '1190 ' },
    city: { label: 'Ort', value: 'Wien' },
    sepaCreditorId: { label: 'SEPA Creditor-ID', value: 'AT31ZZZ00000004810' },
  },
  ermaechtigungTextLine1:
    'Ich ermächtige/ Wir ermächtigen den Zahlungsempfänger Zahlungen von meinem/ unserem Konto mittels SEPA - Lastschrift einzuziehen. Zugleich weise ich mein/ unser Kreditinstitut an, die von dem Zahlungsempfänger auf mein/ unser Konto gezogenen SEPA – Lastschriften einzulösen.',
  ermaechtigungTextLine2:
    'Ich kann/ Wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem/ unserem Kreditinstitut vereinbarten Bedingungen.',
  zahlungspflichtigerBlock: {
    zahlungspflichtigerBlockTitle: 'Zahlungspflichtiger',
    name: { label: 'Name', value: 'Maxine Musterfrau' },
    addressLine: { label: 'Anschrift', value: 'Schönbrunner Schloßstraße 15/5/52' },
    zipCode: { label: 'Postleitzahl', value: '1120' },
    city: { label: 'Ort', value: 'Wien' },
    iban: { label: 'IBAN', value: 'AT611904300234573201' },
    bic: { label: 'BIC', value: 'ABCDATWW ' },
  },
  cityAndDateText: 'Ort, Datum',
  signatureText: 'Unterschrift',
};

export const mockSepaLastschriftMandatWithoutValues: SepaLastschriftMandat = {
  type: 'SEPA_LASTSCHRIFT_MANDAT',
  title: 'SEPA Lastschrift Mandat (Ermächtigung)',
  mandatReference: {
    label: 'SEPA Mandatsreferenz',
  },
  zahlungsempfaengerBlock: {
    zahlungsempfaengerBlockTitle: 'Zahlungsempfänger',
    name: { label: 'Name' },
    addressLine: { label: 'Anschrift' },
    zipCode: { label: 'Postleitzahl' },
    city: { label: 'Ort' },
    sepaCreditorId: { label: 'SEPA Creditor-ID' },
  },
  ermaechtigungTextLine1:
    'Ich ermächtige/ Wir ermächtigen den Zahlungsempfänger Zahlungen von meinem/ unserem Konto mittels SEPA - Lastschrift einzuziehen. Zugleich weise ich mein/ unser Kreditinstitut an, die von dem Zahlungsempfänger auf mein/ unser Konto gezogenen SEPA – Lastschriften einzulösen.',
  ermaechtigungTextLine2:
    'Ich kann/ Wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem/ unserem Kreditinstitut vereinbarten Bedingungen.',
  zahlungspflichtigerBlock: {
    zahlungspflichtigerBlockTitle: 'Zahlungspflichtiger',
    name: { label: 'Name' },
    addressLine: { label: 'Anschrift' },
    zipCode: { label: 'Postleitzahl' },
    city: { label: 'Ort' },
    iban: { label: 'IBAN' },
    bic: { label: 'BIC' },
  },
  cityAndDateText: 'Ort, Datum',
  signatureText: 'Unterschrift',
};
