import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Typography } from 'antd';
import { generatePathToRechtstraegerDetailsPage } from '../../Rechtstraeger/rechtstraegerHelper';
import { shortenTextIfNeeded } from '../../../helpers/stringHelper';
import theme from '../../../theme';
import { isNotNil } from '../../../helpers/assertionHelper';
import { isStatusArchivedOrGesperrt, IStatus } from '../../../helpers/statusHelper';
import StatusBadge from '../../../components/Status/StatusBadge';
import ContactsTooltipContent from './ContactsTooltipContent';

type Props = {
  rechtstraegerId: string;
  rechtstraegerName: string;
  maxTextLength?: number;
  linkTo?: string;
  fontWeight?: 'normal' | 'bold';
  isCurrent?: boolean;
  status?: IStatus;
};

const RechtstraegerWithContactsTooltip: FC<Props> = ({
  rechtstraegerId,
  rechtstraegerName,
  linkTo,
  fontWeight,
  isCurrent,
  maxTextLength,
  status,
}) => {
  const textLength = maxTextLength ?? rechtstraegerName.length;
  const shortenedText = shortenTextIfNeeded(rechtstraegerName, textLength);

  const linkColor = isNotNil(isCurrent) ? getColor(isCurrent) : '';

  const content =
    status && isStatusArchivedOrGesperrt(status) ? (
      <StatusBadge dot entityStatus={status} showBadgeText={false} showStatusDescriptionInTooltip={false}>
        <Link target="_blank" to={linkTo ?? generatePathToRechtstraegerDetailsPage(rechtstraegerId)}>
          <Typography.Text style={{ color: theme.textColor.secondary, fontWeight }}>{shortenedText}</Typography.Text>
        </Link>
      </StatusBadge>
    ) : (
      <Link target="_blank" to={linkTo ?? generatePathToRechtstraegerDetailsPage(rechtstraegerId)} style={{ fontWeight, color: linkColor }}>
        {shortenedText}
      </Link>
    );

  return (
    <Tooltip
      styles={{ root: { maxWidth: '800px' } }}
      title={<ContactsTooltipContent rechtstraegerName={rechtstraegerName} rechtstraegerId={rechtstraegerId} status={status} />}
    >
      {content}
    </Tooltip>
  );
};

const getColor = (isCurrent: boolean) => (isCurrent ? theme.linkColor.default : theme.linkColor.secondary);

export default RechtstraegerWithContactsTooltip;
