import { FC } from 'react';
import { BookingSuggestionVerbuchenGenerierlaufEntry } from '../../../../types';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { StyledTableReceivedBankTransaction } from '../../shared/styled/ReceivedBankTransactionTable.style';
import receivedBankTransactionTableColumns from './receivedBankTransactionTableColumns';

type Props = {
  record: BookingSuggestionVerbuchenGenerierlaufEntry;
};

const ReceivedBankTransactionColumn: FC<Props> = ({ record }) => {
  if (record.detailsList.length === 0) {
    return <TextForEmptyValue textToShow="minus" />;
  }

  return (
    <StyledTableReceivedBankTransaction
      dataSource={record.detailsList}
      columns={receivedBankTransactionTableColumns(record)}
      pagination={false}
      expandable={{ rowExpandable: () => false }}
    />
  );
};

export default ReceivedBankTransactionColumn;
