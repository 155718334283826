import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BeInfoFeldFragmentDoc } from './BeInfoFeldFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeInfoFeldListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BeInfoFeldListQuery = {
  getBeInfoFeldList: {
    data: Array<
      | {
          __typename: 'BeInfoFeldKellerabteil';
          beInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          text?: string | null;
          kellerabteilNummer: string;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.BeInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'BeInfoFeldParkplatz';
          beInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          text?: string | null;
          parkplatzNummer: string;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.BeInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
        }
      | {
          __typename: 'BeInfoFeldZimmer';
          beInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          anmerkung?: string | null;
          bezeichnung: string;
          bodenbelag?: string | null;
          breite?: number | null;
          energiekennzahl?: string | null;
          flaechentyp?: string | null;
          heizung?: string | null;
          hoehe?: number | null;
          initialQuadratmeterBetrag?: number | null;
          initialQuadratmeterPreis?: number | null;
          kubikmeter?: number | null;
          laenge?: number | null;
          nutzflaeche?: number | null;
          nutzung?: boolean | null;
          quadratmeter?: number | null;
          quadratmeterBetrag?: number | null;
          quadratmeterPreis?: number | null;
          zimmerNummer?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.BeInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          vorschreibungsposition?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            kurzBezeichnung: string;
            objektVorschreibungspositionId: string;
            vorschreibungspositionId: string;
            art: { text: string; value: Types.VorschreibungspositionArt };
            objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
            vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
          } | null;
        }
    >;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BeInfoFeldQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type BeInfoFeldQuery = {
  getBeInfoFeld: {
    data:
      | {
          __typename: 'BeInfoFeldKellerabteil';
          beInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          text?: string | null;
          kellerabteilNummer: string;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.BeInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'BeInfoFeldParkplatz';
          beInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          text?: string | null;
          parkplatzNummer: string;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.BeInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
        }
      | {
          __typename: 'BeInfoFeldZimmer';
          beInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          anmerkung?: string | null;
          bezeichnung: string;
          bodenbelag?: string | null;
          breite?: number | null;
          energiekennzahl?: string | null;
          flaechentyp?: string | null;
          heizung?: string | null;
          hoehe?: number | null;
          initialQuadratmeterBetrag?: number | null;
          initialQuadratmeterPreis?: number | null;
          kubikmeter?: number | null;
          laenge?: number | null;
          nutzflaeche?: number | null;
          nutzung?: boolean | null;
          quadratmeter?: number | null;
          quadratmeterBetrag?: number | null;
          quadratmeterPreis?: number | null;
          zimmerNummer?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.BeInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          vorschreibungsposition?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            kurzBezeichnung: string;
            objektVorschreibungspositionId: string;
            vorschreibungspositionId: string;
            art: { text: string; value: Types.VorschreibungspositionArt };
            objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
            vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
          } | null;
        };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BeInfoFeldListDocument = gql`
  query BeInfoFeldList($objektId: ID!, $bestandseinheitId: ID!, $includeArchiviert: Boolean) {
    getBeInfoFeldList(objektId: $objektId, bestandseinheitId: $bestandseinheitId, includeArchiviert: $includeArchiviert) {
      data {
        ...BeInfoFeld
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BeInfoFeldFragmentDoc}
`;
export function useBeInfoFeldListQuery(
  baseOptions: Apollo.QueryHookOptions<BeInfoFeldListQuery, BeInfoFeldListQueryVariables> &
    ({ variables: BeInfoFeldListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeInfoFeldListQuery, BeInfoFeldListQueryVariables>(BeInfoFeldListDocument, options);
}
export function useBeInfoFeldListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeInfoFeldListQuery, BeInfoFeldListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeInfoFeldListQuery, BeInfoFeldListQueryVariables>(BeInfoFeldListDocument, options);
}
export function useBeInfoFeldListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeInfoFeldListQuery, BeInfoFeldListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeInfoFeldListQuery, BeInfoFeldListQueryVariables>(BeInfoFeldListDocument, options);
}
export type BeInfoFeldListQueryHookResult = ReturnType<typeof useBeInfoFeldListQuery>;
export type BeInfoFeldListLazyQueryHookResult = ReturnType<typeof useBeInfoFeldListLazyQuery>;
export type BeInfoFeldListSuspenseQueryHookResult = ReturnType<typeof useBeInfoFeldListSuspenseQuery>;
export type BeInfoFeldListQueryResult = Apollo.QueryResult<BeInfoFeldListQuery, BeInfoFeldListQueryVariables>;
export const BeInfoFeldDocument = gql`
  query BeInfoFeld($objektId: ID!, $bestandseinheitId: ID!, $infoFeldId: ID!) {
    getBeInfoFeld(objektId: $objektId, bestandseinheitId: $bestandseinheitId, infoFeldId: $infoFeldId) {
      data {
        ...BeInfoFeld
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BeInfoFeldFragmentDoc}
`;
export function useBeInfoFeldQuery(
  baseOptions: Apollo.QueryHookOptions<BeInfoFeldQuery, BeInfoFeldQueryVariables> &
    ({ variables: BeInfoFeldQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeInfoFeldQuery, BeInfoFeldQueryVariables>(BeInfoFeldDocument, options);
}
export function useBeInfoFeldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeInfoFeldQuery, BeInfoFeldQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeInfoFeldQuery, BeInfoFeldQueryVariables>(BeInfoFeldDocument, options);
}
export function useBeInfoFeldSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeInfoFeldQuery, BeInfoFeldQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeInfoFeldQuery, BeInfoFeldQueryVariables>(BeInfoFeldDocument, options);
}
export type BeInfoFeldQueryHookResult = ReturnType<typeof useBeInfoFeldQuery>;
export type BeInfoFeldLazyQueryHookResult = ReturnType<typeof useBeInfoFeldLazyQuery>;
export type BeInfoFeldSuspenseQueryHookResult = ReturnType<typeof useBeInfoFeldSuspenseQuery>;
export type BeInfoFeldQueryResult = Apollo.QueryResult<BeInfoFeldQuery, BeInfoFeldQueryVariables>;
