import React, { FC } from 'react';
import { Button, ButtonProps } from 'antd';
import { FormikProps, useFormikContext } from 'formik';
import { PlusOutlined } from '@ant-design/icons';
import { validateObjectSyncWithSchemaAndReturnErrorsIfAny } from '../../../helpers/validationHelper';
import { STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT } from '../../../shared/components/Address/form/addressMapper';
import { updateStreetAddress } from '../../../shared/components/Address/form/streetAddressFormPartHelper';
import AddressWithActionsCard from '../../../shared/components/Address/form/AddressDynamicFormItem/AddressWithActionsCard';
import AddressFormAddEditItem from '../../../shared/components/Address/form/AddressDynamicFormItem/AddressFormAddEditItem';
import { PersonGemeinschaftFormValues } from '../PersonenGemeinschaft/Form/personenGemeinschaftFormMapper';
import { StreetAddressFormValues } from '../../../shared/components/Address/AddressSharedModel';
import { tempHauptAddressValidationSchema } from './hauptAddressOptionalFormPartValidationSchema';

type Props = {
  fieldNamePrefix?: string;
  fieldHelpNames?: {
    city?: string | null;
    cityAdditionalInformation?: string | null;
    countryCodeIso2?: string | null;
    houseEntranceApartmentNumber?: string | null;
    street?: string | null;
    zipCode?: string | null;
  };
};

export const HauptAddressOptionalFormPart: FC<Props> = ({ fieldNamePrefix = '', fieldHelpNames }) => {
  const formikProps = useFormikContext<PersonGemeinschaftFormValues>();

  const tempShowHauptAddressFormPartItem = formikProps.values.tempShowHauptAddressFormPartItem;
  const tempHauptAddress = formikProps.values.tempHauptAddress;
  const hauptAddress = formikProps.values.hauptAddress;

  return (
    <>
      <AddressWithActionsCard
        // @ts-ignore
        address={hauptAddress}
        removeAdresse={removeHauptAddress(fieldNamePrefix, formikProps)}
        getEditFocusOnAdresse={selectHauptAddress(fieldNamePrefix, formikProps, hauptAddress)}
      />
      <AddHauptAddressButton
        isVisible={isHauptAddressButtonVisible(tempShowHauptAddressFormPartItem, hauptAddress)}
        onClick={openHauptAddressFormAddEditItem(fieldNamePrefix, formikProps)}
      />
      <AddressFormAddEditItem
        fieldNamePrefix={`${tempHauptAddressValuesPath(fieldNamePrefix)}.`}
        isVisible={tempShowHauptAddressFormPartItem}
        saveAddress={saveHauptAddress(fieldNamePrefix, formikProps, tempHauptAddress)}
        resetAddress={resetHauptAddress(fieldNamePrefix, formikProps)}
        fieldHelpNames={fieldHelpNames}
      />
    </>
  );
};

export const isHauptAddressButtonVisible = (tempShowHauptAddressFormPartItem: boolean, hauptAddress?: StreetAddressFormValues) =>
  !hauptAddress && !tempShowHauptAddressFormPartItem;

type AddHauptAddressButtonProps = {
  isVisible: boolean;
} & ButtonProps;

const AddHauptAddressButton: FC<AddHauptAddressButtonProps> = ({ isVisible, ...props }) =>
  isVisible ? (
    <Button type="dashed" size="middle" htmlType="button" block {...props}>
      <PlusOutlined /> Hauptadresse hinzufügen
    </Button>
  ) : null;

export const initHauptAddress = (fieldNamePrefix: string, formikProps: FormikProps<PersonGemeinschaftFormValues>) => {
  const { setFieldValue } = formikProps;
  setFieldValue(tempHauptAddressValuesPath(fieldNamePrefix), { ...STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT });
};

export const openHauptAddressFormAddEditItem = (fieldNamePrefix: string, formikProps: FormikProps<PersonGemeinschaftFormValues>) => () => {
  const { setFieldValue } = formikProps;
  initHauptAddress(fieldNamePrefix, formikProps);
  setFieldValue(tempShowHauptAddressFormPartItemValuesPath(fieldNamePrefix), true);
};

export const removeHauptAddress = (fieldNamePrefix: string, formikProps: FormikProps<PersonGemeinschaftFormValues>) => () => {
  const { setFieldValue } = formikProps;
  setFieldValue(hauptAddressValuesPath(fieldNamePrefix), undefined);
};

export const selectHauptAddress =
  (fieldNamePrefix: string, formikProps: FormikProps<PersonGemeinschaftFormValues>, hauptAddress?: StreetAddressFormValues) => () => {
    const { setFieldValue } = formikProps;
    resetHauptAddress(fieldNamePrefix, formikProps)();

    setFieldValue(tempHauptAddressValuesPath(fieldNamePrefix), hauptAddress);
    setFieldValue(tempShowHauptAddressFormPartItemValuesPath(fieldNamePrefix), true);
  };

export const saveHauptAddress =
  (fieldNamePrefix: string, formikProps: FormikProps<PersonGemeinschaftFormValues>, tempHauptAddress?: StreetAddressFormValues) => () => {
    const { setFieldValue } = formikProps;
    setFieldTouchedForHauptAddressFormSelected(`${tempHauptAddressValuesPath(fieldNamePrefix)}.`, formikProps);

    if (isHauptAddressValid(tempHauptAddress)) {
      setFieldValue(hauptAddressValuesPath(fieldNamePrefix), { ...tempHauptAddress });
      resetHauptAddress(fieldNamePrefix, formikProps)();
    }
  };

const setFieldTouchedForHauptAddressFormSelected = (fieldNamePrefix: string, formikProps: FormikProps<PersonGemeinschaftFormValues>) => {
  const { setFieldTouched } = formikProps;
  setFieldTouched(`${fieldNamePrefix}countryCodeIso2`);
  setFieldTouched(`${fieldNamePrefix}city`);
  setFieldTouched(`${fieldNamePrefix}cityAdditionalInformation`);
  setFieldTouched(`${fieldNamePrefix}zipCode`);
  setFieldTouched(`${fieldNamePrefix}street`);
  setFieldTouched(`${fieldNamePrefix}houseEntranceApartmentNumber`);
};

export const resetHauptAddress = (fieldNamePrefix: string, formikProps: FormikProps<PersonGemeinschaftFormValues>) => () => {
  const { setFieldTouched, setFieldError, setFieldValue } = formikProps;

  setFieldValue(tempHauptAddressValuesPath(fieldNamePrefix), undefined);
  setFieldTouched(tempHauptAddressValuesPath(fieldNamePrefix), false);
  setFieldError(tempHauptAddressValuesPath(fieldNamePrefix), undefined);

  setFieldValue(tempShowHauptAddressFormPartItemValuesPath(fieldNamePrefix), false);
};

export const isHauptAddressValid = (hauptAddress?: StreetAddressFormValues) =>
  !validateObjectSyncWithSchemaAndReturnErrorsIfAny({
    validationSchema: tempHauptAddressValidationSchema,
    valueToValidate: hauptAddress,
  });

// FIXME should be extracted to helper but it would cause a circular dep due to the call to openHauptAddressFormAddEditItem
export const updateTempHauptAddress = (formikProps: FormikProps<PersonGemeinschaftFormValues>, fieldNamePrefix: string, data: any) => {
  updateStreetAddress(
    formikProps,
    `${tempHauptAddressValuesPath(fieldNamePrefix)}.`,
    data,
    openHauptAddressFormAddEditItem(fieldNamePrefix, formikProps)
  );
};

export const tempShowHauptAddressFormPartItemValuesPath = (fieldNamePrefix: string) => `${fieldNamePrefix}tempShowHauptAddressFormPartItem`;
export const tempHauptAddressValuesPath = (fieldNamePrefix: string) => `${fieldNamePrefix}tempHauptAddress`;
export const hauptAddressValuesPath = (fieldNamePrefix: string) => `${fieldNamePrefix}hauptAddress`;

export default HauptAddressOptionalFormPart;
