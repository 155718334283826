import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type StandingInvoiceFieldsFragment = {
  ausstellerBezeichnung: string;
  ausstellerRechtstraegerId: string;
  belegId: string;
  buchungIdList: Array<string>;
  brutto: number;
  buchungskreisId?: string | null;
  fileId: string;
  issuerBankDetailsIban: string;
  issuerBankDetailsId: string;
  kundenNummer?: string | null;
  netto: number;
  objektBezeichnung?: string | null;
  objektId?: string | null;
  offenerBetrag?: number | null;
  paid: boolean;
  payeeBezeichnung: string;
  payeeBankDetailsIban: string;
  payeeBankDetailsId: string;
  payeeRechtstraegerId: string;
  paymentCreatable: boolean;
  paymentIdList: Array<string>;
  paymentReferenceText?: string | null;
  purposeOfUseText?: string | null;
  rechnungsDatum: string;
  rechnungsNummer: string;
  sepaMandatReference?: string | null;
  type: Types.InvoiceType;
  vat: number;
  basis: { text: string; value: Types.IncomingInvoiceBasis };
  billingType: { text: string; value: Types.Verrechnungsart };
  partialAmountList: Array<{
    brutto: number;
    buchungIdList: Array<string>;
    buchungsanweisungIdList: Array<string>;
    buchungsdatum: string;
    dueDate: string;
    netto: number;
    offenerBetrag?: number | null;
    paid: boolean;
    partialAmountId: string;
    paymentCreatable: boolean;
    paymentIdList: Array<string>;
    requestedPaymentDate?: string | null;
    buchungErrorList: Array<{ message: string; type: string }>;
    buchungPositionList: Array<{
      betrag: number;
      bestandseinheitId?: string | null;
      bestandseinheitBezeichnung?: string | null;
      buchungstext: string;
      incomingInvoiceBuchungPositionId: string;
      kontoBezeichnung: string;
      kontoId: string;
      kontoNummer: string;
      steuersatz: number;
    }>;
    buchungsanweisungListEntryDataList: Array<{
      buchungsanweisungId: string;
      fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
      status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
    }>;
    buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
    steuerzeileList: Array<{ betrag: number; steuersatz: number }>;
  }>;
  paymentMethod: { text: string; value: Types.PaymentMethod };
  status: { text: string; value: Types.IncomingInvoiceStatus };
  warningList: Array<{ message: string; type: string }>;
};

export type StandingInvoicePartialAmountFieldsFragment = {
  brutto: number;
  buchungIdList: Array<string>;
  buchungsanweisungIdList: Array<string>;
  buchungsdatum: string;
  dueDate: string;
  netto: number;
  offenerBetrag?: number | null;
  paid: boolean;
  partialAmountId: string;
  paymentCreatable: boolean;
  paymentIdList: Array<string>;
  requestedPaymentDate?: string | null;
  buchungErrorList: Array<{ message: string; type: string }>;
  buchungPositionList: Array<{
    betrag: number;
    bestandseinheitId?: string | null;
    bestandseinheitBezeichnung?: string | null;
    buchungstext: string;
    incomingInvoiceBuchungPositionId: string;
    kontoBezeichnung: string;
    kontoId: string;
    kontoNummer: string;
    steuersatz: number;
  }>;
  buchungsanweisungListEntryDataList: Array<{
    buchungsanweisungId: string;
    fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
    status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
  }>;
  buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
  steuerzeileList: Array<{ betrag: number; steuersatz: number }>;
};

export const StandingInvoicePartialAmountFieldsFragmentDoc = gql`
  fragment StandingInvoicePartialAmountFields on StandingInvoicePartialAmount {
    brutto
    buchungErrorList {
      message
      type
    }
    buchungIdList
    buchungPositionList {
      betrag
      bestandseinheitId
      bestandseinheitBezeichnung
      buchungstext
      incomingInvoiceBuchungPositionId
      kontoBezeichnung
      kontoId
      kontoNummer
      steuersatz
    }
    buchungsanweisungIdList
    buchungsanweisungListEntryDataList {
      buchungsanweisungId
      fibuStatus {
        description
        text
        value
      }
      status {
        description
        text
        value
      }
    }
    buchungsanweisungStatus {
      description
      text
      value
    }
    buchungsdatum
    dueDate
    netto
    offenerBetrag
    paid
    partialAmountId
    paymentCreatable
    paymentIdList
    requestedPaymentDate
    steuerzeileList {
      betrag
      steuersatz
    }
  }
`;
export const StandingInvoiceFieldsFragmentDoc = gql`
  fragment StandingInvoiceFields on StandingInvoice {
    ausstellerBezeichnung
    ausstellerRechtstraegerId
    basis {
      text
      value
    }
    belegId
    billingType {
      text
      value
    }
    buchungIdList
    brutto
    buchungskreisId
    fileId
    issuerBankDetailsIban
    issuerBankDetailsId
    kundenNummer
    netto
    objektBezeichnung
    objektId
    offenerBetrag
    paid
    partialAmountList {
      ...StandingInvoicePartialAmountFields
    }
    payeeBezeichnung
    payeeBankDetailsIban
    payeeBankDetailsId
    payeeRechtstraegerId
    paymentCreatable
    paymentIdList
    paymentMethod {
      text
      value
    }
    paymentReferenceText
    purposeOfUseText
    rechnungsDatum
    rechnungsNummer
    sepaMandatReference
    status {
      text
      value
    }
    type
    vat
    warningList {
      message
      type
    }
  }
  ${StandingInvoicePartialAmountFieldsFragmentDoc}
`;
