import styled from 'styled-components';
import { Tabs } from 'antd';

export const StyledMahnstufeTabs = styled(Tabs)`
  .ant-tabs-nav-wrap {
    width: 100%;
    .ant-tabs-nav-list {
      width: 100%;
      justify-content: space-around;
    }
  }
`;
