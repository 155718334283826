import React, { FC } from 'react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { useAuthorizedMitarbeiter } from '../../../../../../../shared/Auth/useAuthorized';
import {
  bookingSuggestionFormFields,
  BookingSuggestionFormValues,
  bsOffenePostenForPersonenkontoFormFields,
  OffenePostenForClearingSuggestion,
} from '../../../../Form/buchungsmaskeFormMapper';
import { getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex } from '../../../auszifferungBlockHelpers';
import { createNewAuszifferungForOPExistingInClearingSuggestion } from '../../../auszifferungFormMapper';
import { getOffenePostenIndexFromMainOPList } from '../../../../Form/buchungsmaskeFormHelpers';
import { calculateInitialBetragForNewAuszifferung } from '../../../shared/Level1/addAuszifferungHelpers';
import { disableAddButtonBasedOnAvailableAmount, isNewAuszifferung } from '../../../bookingSuggestionAuszifferungBlockHelpers';

type Props = {
  offenePostenForPersonenkonto: OffenePostenForClearingSuggestion;
  toggleExpandedRow: () => void;
  fieldNamePrefix: string;
};

const AddAuszifferungColumnForOPTableForPersonenkonto: FC<Props> = ({ offenePostenForPersonenkonto, toggleExpandedRow, fieldNamePrefix }) => {
  const {
    mitarbeiter: { mitarbeiterId },
    user: { username },
  } = useAuthorizedMitarbeiter();

  const { values: formikValues, setFieldValue } = useFormikContext<BookingSuggestionFormValues>();
  const auszifferungFieldName = `${fieldNamePrefix}.${bsOffenePostenForPersonenkontoFormFields.auszifferungList}`;

  // Get index from OP List for ClearingSuggestion to check if OP already exists in that list
  let opIndexFromClearingSuggestionList = getOffenePostenIndexFromMainOPList(formikValues, offenePostenForPersonenkonto);

  const handleAdd = () => {
    toggleExpandedRow();

    // Step 1. If OP doesn't exist in the OP List for ClearingSuggestion, then add it and update the OP index for that list

    if (opIndexFromClearingSuggestionList === -1) {
      const newOPList = [...formikValues.offenePostenForClearingSuggestionList, offenePostenForPersonenkonto];
      opIndexFromClearingSuggestionList = formikValues.offenePostenForClearingSuggestionList.length;
      setFieldValue(bookingSuggestionFormFields.offenePostenForClearingSuggestionList, newOPList);
    }

    // Step 2. add new Auszifferung to the OP from the OPListForClearingSuggestionList

    const newAuszifferung = createNewAuszifferungForOPExistingInClearingSuggestion(
      offenePostenForPersonenkonto,
      mitarbeiterId,
      username,
      calculateInitialBetragForNewAuszifferung(formikValues, offenePostenForPersonenkonto)
    );
    const newAuszifferungList = [...offenePostenForPersonenkonto.auszifferungList, newAuszifferung];
    const auszifferungListForOPForClearingSuggestionFormFieldName =
      getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex(opIndexFromClearingSuggestionList);

    setFieldValue(auszifferungListForOPForClearingSuggestionFormFieldName, newAuszifferungList);
    setFieldValue(auszifferungFieldName, newAuszifferungList);
  };

  // as the Auszifferung list in OP list with clearing suggestions is the main list, we need to check if it already has a new Auszifferung
  const hasAlreadyNewAuszifferung =
    opIndexFromClearingSuggestionList >= 0 &&
    formikValues.offenePostenForClearingSuggestionList[opIndexFromClearingSuggestionList].auszifferungList.some(isNewAuszifferung);

  return (
    <Button type="primary" disabled={hasAlreadyNewAuszifferung || disableAddButtonBasedOnAvailableAmount(formikValues)} onClick={handleAdd}>
      Auszifferung hinzufügen
    </Button>
  );
};

export default AddAuszifferungColumnForOPTableForPersonenkonto;
