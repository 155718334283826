import React from 'react';
import { Tag } from 'antd';
import { archivedOrNormalStyle, StatusEnum } from '../../../helpers/statusHelper';
import AufteilungsschluesselTableActions from './AufteilungsschluesselTableActions';
import { MitarbeiterTooltip } from '../../../components/Card';
import StatusBadge from '../../../components/Status/StatusBadge';
import DataWithStatus from '../../../components/Helpers/DataWithStatus';
import { Aufteilungsschluessel } from '../../../types';
import { NestedTableColProps } from '../../../components/Table/NestedTable/NestedTable';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import FirmendatenKurzBezeichnung from '../../shared/FirmendatenKurzBezeichnung';

const aufteilungsschluesselTableColumns = (onAction: () => void): NestedTableColProps<Aufteilungsschluessel>[] => [
  {
    title: 'Bezeichnung',
    ellipsis: {
      showTitle: false,
    },
    render: (text, record) => (
      <DataWithStatus
        status={record.status}
        text={record.bezeichnung}
        showStatusDescription={false}
        showBadgeDot={false}
        alternativeText={record.kurzBezeichnung}
        maxTextLength={20}
      />
    ),
  },
  {
    title: 'Bezugsbasis',
    render: (text, record) => (
      <DataWithStatus status={record.status} text={record.bezugsbasis.text} showStatusDescription={false} showBadgeDot={false} />
    ),
  },
  {
    title: 'Maßeinheit',
    render: (text, record) => (
      <DataWithStatus status={record.status} text={record.masseinheit.text} showStatusDescription={false} showBadgeDot={false} />
    ),
  },
  {
    title: 'Firma',
    render: (text, record) =>
      record.firmendatenId ? (
        <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} />
      ) : (
        <Tag style={archivedOrNormalStyle(record.status)}>System</Tag>
      ),
  },
  {
    title: 'Status',
    filters: [
      { text: 'Aktiv', value: StatusEnum.Aktiv },
      { text: 'Archiviert', value: StatusEnum.Archiviert },
    ],
    onFilter: (value, record) => record.status.value.includes(value as string),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    key: 'action',
    render: (text, record) => <AufteilungsschluesselTableActions aufteilungsschluessel={record} onAction={onAction} />,
  },
];

export default aufteilungsschluesselTableColumns;
