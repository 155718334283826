import React, { FC } from 'react';
import { Empty } from 'antd';
import topAbrechnungTableColumns from './topAbrechnungTableColumns';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { AbrechnungType, TopAbrechnung } from '../../../../../types';

type Props = {
  topAbrechnungList?: TopAbrechnung[];
  loading: boolean;
  objektId: string;
  objektAbrechnungId: string;
  abrechnungType: AbrechnungType;
};

const TopAbrechnungTable: FC<Props> = ({ objektId, objektAbrechnungId, abrechnungType, loading, topAbrechnungList }) => {
  return (
    <TableWithColSelector<TopAbrechnung>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Topabrechnung vorhanden</span>} />,
      }}
      scroll={{ x: 900 }}
      loading={loading}
      pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
      dataSource={topAbrechnungList}
      columns={topAbrechnungTableColumns(objektId, objektAbrechnungId, abrechnungType)}
      rowKey={(record) => record.topAbrechnungId}
      filterIdentifier="objekt-top-abrechnung"
    />
  );
};

export default TopAbrechnungTable;
