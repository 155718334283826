import { v4 as uuid4 } from 'uuid';
import { TFormattedDecimal } from '../../../../helpers/formikHelper';
import { AuszifferungSourceTuple, OffenePosten } from '../../../../types';
import { mapOPForPersonenkontoToOPForClearingSuggestion, OffenePostenForClearingSuggestion } from '../Form/buchungsmaskeFormMapper';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';

export type AuszifferungFormValues = {
  // ClearingSuggestionInput
  amount: TFormattedDecimal;
  clearingSuggestionId?: string | null; // = auszifferungId; // if Auszifferung is coming from ClearingSuggestion, it has clearingSuggestionID, and it should not be deletable; if it's added manually, it should be deletable
  offenePostenId: string;
  // other fields needed for AuszifferungTable
  auszifferungId: string;
  auszifferungsDatum?: string | null;
  buchungId?: string | null;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  source?: AuszifferungSourceTuple | null;
  storniert: boolean;
  storniertByMitarbeiterId?: string | null;
  storniertTs?: string | null;
  zahlungId?: string | null;
};

export const createNewAuszifferungForOPExistingInClearingSuggestion = (
  offenePosten: OffenePostenForClearingSuggestion,
  mitarbeiterId: string,
  username: string,
  initialBetragForNewAuszifferung: number
): AuszifferungFormValues => ({
  amount: initialBetragForNewAuszifferung,
  auszifferungId: uuid4(),
  buchungId: undefined,
  createTs: dayjsCustom().format(),
  createdBy: username,
  createdByMitarbeiterId: mitarbeiterId,
  offenePostenId: offenePosten.offenePostenId,
  source: undefined,
  storniert: false,
});

export const mapOffenePostenAuszifferungListToFormValues = (offenePostenList: OffenePosten[]): OffenePostenForClearingSuggestion[] => {
  return offenePostenList.map((offenePosten) => mapOPForPersonenkontoToOPForClearingSuggestion(offenePosten));
};

export const mapOffenePostenForPersonenKontoToAuszifferungFormValues = (
  offenePosten: OffenePostenForClearingSuggestion
): AuszifferungFormValues[] => {
  return offenePosten.auszifferungList.map((auszifferung) => ({
    amount: auszifferung.amount,
    auszifferungId: auszifferung.auszifferungId,
    auszifferungsDatum: auszifferung.auszifferungsDatum,
    buchungId: auszifferung.buchungId,
    createTs: auszifferung.createTs,
    createdBy: auszifferung.createdBy,
    createdByMitarbeiterId: auszifferung.createdByMitarbeiterId,
    offenePostenId: offenePosten.offenePostenId,
    source: auszifferung.source,
    storniert: auszifferung.storniert,
    storniertByMitarbeiterId: auszifferung.storniertByMitarbeiterId,
    storniertTs: auszifferung.storniertTs,
    zahlungId: auszifferung.zahlungId,
  }));
};
