import React, { FC } from 'react';
import { Tooltip, Typography } from 'antd';
import { shortenTextIfNeeded, shouldTextBeShortened } from '../../helpers/stringHelper';
import DataWithShortenedText from '../Helpers/DataWithShortenedText';
import { TOOLTIP_CHILD_TEXT } from '../../constants/testIds';

type MultipleTextsProps = {
  texts: string[];
  maxFirstTextLength: number;
};

// For data rendered like ObjektTopContent when only an array of strings is provided and the rendered text depends on the first array element
const MultipleTexts: FC<MultipleTextsProps> = ({ maxFirstTextLength, texts }) => {
  const firstText = texts[0];

  return texts.length === 1 ? (
    <DataWithShortenedText text={firstText} maxTextLength={maxFirstTextLength} />
  ) : (
    <Tooltip
      title={texts.map((text, index) => {
        return <div key={index}>{text}</div>;
      })}
    >
      {shouldTextBeShortened(firstText, maxFirstTextLength) ? (
        <Typography.Text data-testid={TOOLTIP_CHILD_TEXT}>{shortenTextIfNeeded(firstText, maxFirstTextLength)}</Typography.Text>
      ) : (
        <Typography.Text data-testid={TOOLTIP_CHILD_TEXT}>{`${firstText}…`}</Typography.Text>
      )}
    </Tooltip>
  );
};

export default MultipleTexts;
