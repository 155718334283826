import React from 'react';
import { useToggle } from '../../../hooks/useToggle';
import { CellButton } from './CellButton';
import { QUERY_PARAM_KEY, useQueryParams } from '../../../hooks/useStringQueryParams';
import TableCellContentWithSorting from '../TableSorting/TableCellContentWithSorting';
import TableWithAlignedColsRow from './TableWithAlignedColsRow';
import { SortOrder } from '../TableSorting/sortingHelpers';
import { TableWithAlignedColsColumnType, TableWithAlignedColsExpandedRowType } from './tableWithAlignedColsProps';

type TableWithAlignedColsBodyProps<T> = {
  dataSource: T;
  columns: TableWithAlignedColsColumnType<T>[];
  expandedRow: (data: T) => TableWithAlignedColsExpandedRowType<T>[];
  positionOfButton: 0 | 1 | 2 | 3;
  rowKey?: (data: T) => string;
  sorting?: {
    sortedColumnIndex: number;
    sortOrder: SortOrder;
  };
};

function TableWithAlignedColsBody<T>({ dataSource, expandedRow, columns, positionOfButton, rowKey, sorting }: TableWithAlignedColsBodyProps<T>) {
  const { highlightedRowKey } = useQueryParams<{ [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: string }>();
  const [showDetails, setShowDetails] = useToggle();

  return (
    <>
      <tr className={highlightedRowKey && highlightedRowKey === rowKey?.(dataSource) ? 'ant-table-row-selected' : ''}>
        <CellButton isOpen={showDetails} onClick={setShowDetails} positionOfButton={positionOfButton} />
        {columns.map((col, index) => {
          if (col.sorting && sorting) {
            return (
              <TableCellContentWithSorting
                key={index}
                index={col.sorting.index}
                sortedColumnIndex={sorting.sortedColumnIndex}
                sortOrder={sorting.sortOrder}
                align={col.align}
                colSpan={col.colSpan}
              >
                {col.render(dataSource)}
              </TableCellContentWithSorting>
            );
          }
          return (
            <td key={index} style={{ textAlign: col.align ?? 'right' }} colSpan={col.colSpan}>
              {col.render(dataSource)}
            </td>
          );
        })}
      </tr>
      {showDetails && expandedRow(dataSource).map((row, index) => <TableWithAlignedColsRow key={index} dataSource={dataSource} rowItem={row} />)}
    </>
  );
}

export default TableWithAlignedColsBody;
