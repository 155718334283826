import React, { FC, useState } from 'react';
import { useRechtsTraegerVertragspartnerLazyQuery } from '../../Rechtstraeger/Rolle/Vertragspartner/gql/VertragspartnerQueries.types';
import CardTitle from '../../../components/Card/CardTitle';
import { isStatusGesperrt, IStatus } from '../../../helpers/statusHelper';

type MahnungCardTitleProps = {
  status: IStatus;
  vertragspartnerId: string;
};

const MahnungCardTitle: FC<MahnungCardTitleProps> = ({ status, vertragspartnerId }) => {
  const [kommentar, setKommentar] = useState<string>('');
  const [runQuery] = useRechtsTraegerVertragspartnerLazyQuery({
    variables: {
      rechtstraegerId: vertragspartnerId,
    },
  });

  const onTooltipOpenChange = (open: boolean) => {
    if (open && isStatusGesperrt(status)) {
      runQuery().then((res) => {
        const mahnsperreKommentar = res.data?.getVertragspartner.data.mahnsperreKommentar;
        setKommentar(mahnsperreKommentar ?? 'Kein Grund für Sperre angegeben');
      });
    }
  };

  const kommentarForTooltip = isStatusGesperrt(status) ? (
    <div style={{ whiteSpace: 'pre' }}>{kommentar !== '' ? kommentar : 'Laden...'}</div>
  ) : undefined;

  return (
    <CardTitle title="Mahnung" status={status} extraTextForStatusBadgeTooltip={kommentarForTooltip} onStatusBadgeTooltipOpen={onTooltipOpenChange} />
  );
};

export default MahnungCardTitle;
