import React, { FC } from 'react';
import { Button, Drawer, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, HistoryOutlined } from '@ant-design/icons';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { useToggle } from '../../../../hooks/useToggle';
import ObjektBankDetailsForm from '../Form/ObjektBankDetailsForm';
import { showSuccessMsgDelete } from '../../../../components/message';
import { ObjektBankDetails, ObjektBankDetailsVerwendung } from '../../../../types';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import ObjektBankDetailsChangeHistoryListingTable from '../ObjektBankDetailsChangeHistoryListingTable';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import { useDeleteObjektBankDetailsMutation } from '../gql/ObjektBankDetailsMutations.types';

type Props = {
  objektBankDetails: ObjektBankDetails;
  objektId: string;
  onAction: () => void;
  verwendungListTypeInUse: ObjektBankDetailsVerwendung[];
  rechtstraegerId: string;
  weRechnungsAusstellerId?: string;
  rechtstraegerFibuKontoCreatable?: boolean;
  weRechnungsAusstellerFibuKontoCreatable?: boolean;
};

const ObjektBankDetailsListItemActions: FC<Props> = ({
  objektBankDetails,
  objektId,
  rechtstraegerId,
  weRechnungsAusstellerId,
  onAction,
  verwendungListTypeInUse,
  rechtstraegerFibuKontoCreatable,
  weRechnungsAusstellerFibuKontoCreatable,
}) => {
  const { activeForFirmendatenId } = useFDAuthorized();
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runDelete] = useDeleteObjektBankDetailsMutation({ variables: { objektId, objektBankDetailsId: objektBankDetails.objektBankDetailsId } });

  const onUpdateSuccess = () => {
    onCollapse();
    onAction();
  };

  const onDelete = () => {
    runDelete().then(() => {
      showSuccessMsgDelete(`Bankverbindung-Zuordnung`);
      onAction();
    });
  };

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    { key: '3', label: 'Löschen', onClick: () => showConfirmDelete(objektBankDetails, onDelete), danger: true, icon: <DeleteOutlined /> },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
      <Drawer title="Bankverbindung-Zuordnung bearbeiten" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <ObjektBankDetailsForm
          onSuccess={onUpdateSuccess}
          onCancel={onCollapse}
          objektBankDetails={objektBankDetails}
          objektId={objektId}
          rechtstraegerId={rechtstraegerId}
          weRechnungsAusstellerId={weRechnungsAusstellerId}
          firmendatenId={activeForFirmendatenId}
          verwendungListTypeInUse={verwendungListTypeInUse}
          rechtstraegerFibuKontoCreatable={rechtstraegerFibuKontoCreatable}
          weRechnungsAusstellerFibuKontoCreatable={weRechnungsAusstellerFibuKontoCreatable}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <ObjektBankDetailsChangeHistoryListingTable objektId={objektId} bankDetailsId={objektBankDetails.objektBankDetailsId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (objBankDetails: ObjektBankDetails, runDelete: () => void) => {
  Modal.confirm({
    title: `Möchten Sie die Zuordnung löschen?`,
    content: `${objBankDetails.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default ObjektBankDetailsListItemActions;
