import React from 'react';
import { ContactsOutlined, CopyOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import DocumentTab from '../../features/Document/DocumentTab';
import { pathsToHeAbrechnungHeVertragDetails } from '../../features/Abrechnung/He/heAbrechnungUriPaths';
import HeAbrHeVertragDetailsTabs from '../../features/Abrechnung/He/Vertrag/HeAbrHeVertragDetailsTabs';
import { HeAbrechnungHeVertrag } from '../../types';
import { DOCUMENT_FILTERS_SETTINGS } from '../../features/Document/Listing/Filters/listingFiltersHelper';

const menuListHeAbrHeVertrag = (
  abrechnung: HeAbrechnungHeVertrag,
  heAbrechnungId: string,
  refetch: () => Promise<any>,
  loading: boolean
): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Hauseigentümerabrechnungen',
      icon: <ContactsOutlined />,
      uri: pathsToHeAbrechnungHeVertragDetails(abrechnung.objekt.objektId, heAbrechnungId, abrechnung.heAbrechnungHeVertragId).heAbr,
      content: (props) => (
        <HeAbrHeVertragDetailsTabs abrechnung={abrechnung} heAbrechnungId={heAbrechnungId} loading={loading} onAction={refetch} {...props} />
      ),
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: `${
        pathsToHeAbrechnungHeVertragDetails(abrechnung.objekt.objektId, heAbrechnungId, abrechnung.heAbrechnungHeVertragId).abrechnungDetails
      }/documents`,
      content: (props) => (
        <DocumentTab
          heAbrechnungHeVertragId={abrechnung.heAbrechnungHeVertragId}
          disabledFilters={DOCUMENT_FILTERS_SETTINGS.heVertrag.disabled}
          defaultFilters={DOCUMENT_FILTERS_SETTINGS.heVertrag.default}
          {...props}
        />
      ),
    },
  ],
});

export default menuListHeAbrHeVertrag;
