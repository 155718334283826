import { FC } from 'react';
import { FormikProps } from 'formik';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { useEbicsUserListQuery } from '../gql/EbicsUserQueries.types';
import { TEbicsUserQueryParams } from './Filters/filtersQueryParams';
import { EbicsUserListingFiltersFormValues } from './Filters/ebicsUserListingFiltersFormMapper';
import EbicsUserListingFilters from './Filters/EbicsUserListingFilters';
import EbicsUserCreateBtnAndDrawer from './EbicsUserCreateBtnAndDrawer';
import EbicsUserTable from './Table/Level1/EbicsUserTable';

type Props = {
  queryParams: TEbicsUserQueryParams;
  formikProps: FormikProps<EbicsUserListingFiltersFormValues>;
};

const EbicsUserListingForm: FC<Props> = ({ queryParams, formikProps }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useEbicsUserListQuery,
    {
      variables: {
        ...queryParams,
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const ebicsUserList = data?.getEbicsUserList.data?.contentList ?? [];

  return (
    <>
      <EbicsUserListingFilters formikProps={formikProps} actionButtons={<EbicsUserCreateBtnAndDrawer onAction={refetch} />} />
      <EbicsUserTable
        ebicsUserList={ebicsUserList}
        onAction={refetch}
        pagination={pagination}
        loading={loading}
        handleTableSorting={handleTableSorting}
      />
    </>
  );
};

export default EbicsUserListingForm;
