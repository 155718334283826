import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { verarbeitungPageAndMenuListTitles, verarbeitungTypes } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { BookingSuggestionVerbuchenGenerierlauf } from '../../../types';
import { generatePathToVerarbeitungBookingSuggestionVerbuchenDetailsPage } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungErrorAndWarningListing from '../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import VerarbeitungBookingSuggestionVerbuchen from '../../../features/Verarbeitung/BookingSuggestionVerbuchen/VerarbeitungBookingSuggestionVerbuchen';

const menuListBookingSuggestionVerbuchen = (generierlauf: BookingSuggestionVerbuchenGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.BookingSuggestionVerbuchen,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungBookingSuggestionVerbuchenDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: () => (
          <VerarbeitungBookingSuggestionVerbuchen
            generierlauf={generierlauf}
            title={verarbeitungPageAndMenuListTitles.BookingSuggestionVerbuchen}
            verarbeitungType={verarbeitungTypes.BookingSuggestionVerbuchen}
          />
        ),
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungBookingSuggestionVerbuchenDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListBookingSuggestionVerbuchen;
