import { verarbeitungTypes } from '../../../verarbeitungHelpers';

export const avatarColorForVerarbeitungType = (verarbeitungType: string) => {
  switch (verarbeitungType) {
    case verarbeitungTypes.Budgeting:
      return '#fa9d4c';
    case verarbeitungTypes.InfoMailDeliveryDetermineRecipientList:
      return '#39acf3';
    case verarbeitungTypes.InfoMailDeliverySend:
      return '#0c88d4';
    case verarbeitungTypes.VorschreibungMailDeliveryDetermineRecipientList:
      return '#01b8d7';
    case verarbeitungTypes.VorschreibungMailDeliverySend:
      return '#01a0bc';
    case verarbeitungTypes.MahnungAusgeben:
      return '#F98404';
    case verarbeitungTypes.MahnungErzeugen:
      return '#F9B208';
    case verarbeitungTypes.MahnungVersenden:
      return '#FC5404';
    case verarbeitungTypes.MahnungVerbuchen:
      return '#992e02';
    case verarbeitungTypes.VorschreibungAusgeben:
      return '#83BD75';
    case verarbeitungTypes.VorschreibungErstellen:
      return '#6C5CE7';
    case verarbeitungTypes.VorschreibungVerbuchen:
      return '#bf39f3';
    case verarbeitungTypes.WeObjektAbrechnung:
    case verarbeitungTypes.HeAbrechnung:
    case verarbeitungTypes.BkObjektAbrechnung:
      return '#064ACB';
    case verarbeitungTypes.WeTopAbrechnung:
    case verarbeitungTypes.BkTopAbrechnung:
    case verarbeitungTypes.SubAbrechnung:
      return '#448EF6';
    case verarbeitungTypes.VorsteuerkuerzungErzeugen:
      return '#876445';
    case verarbeitungTypes.UstVomAufwand:
      return '#674747';
    case verarbeitungTypes.PaymentProposalCreate:
      return '#0c5e54';
    case verarbeitungTypes.PaymentProposalExecute:
      return '#118476';
    case verarbeitungTypes.DataCarrierPainCreation:
      return '#1ad0b9';
    case verarbeitungTypes.Zinsliste:
      return '#6300a1';
    case verarbeitungTypes.VertragVposIndexedValueManually:
      return '#9b9a93';
    case verarbeitungTypes.VertragVposIndexedValueAutomatically:
      return '#525250';
    case verarbeitungTypes.ApplyVertragVposIndexedValue:
      return '#676766';
    case verarbeitungTypes.ObjektFinancialPlan:
      return '#109837';
    case verarbeitungTypes.TopFinancialPlan:
      return '#cf2855';
    case verarbeitungTypes.SyncEbicsUser:
      return '#483f3f';
    case verarbeitungTypes.Camt:
      return '#7a6b6b';
    case verarbeitungTypes.CamtEbicsManually:
      return '#4f4949';
    case verarbeitungTypes.CamtOnlineBankingManually:
      return '#706b6b';
    case verarbeitungTypes.BookingSuggestionCreation:
      return '#107e03';
    case verarbeitungTypes.BookingSuggestionVerbuchen:
      return '#094803';
    case verarbeitungTypes.IncomingInvoiceBookingCreation:
      return '#8727b0';
    case verarbeitungTypes.FirmendatenInitializer:
      return '#e379e2';
    case verarbeitungTypes.ReceivedBankTransactionVerbuchen:
      return '#2477bd';
    default:
      return '#ccc';
  }
};
