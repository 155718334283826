import React, { FC, useState } from 'react';
import { Col, Radio, Row, Typography } from 'antd';
import { RechtstraegerType } from '../../../types';
import { Spacer } from '../../../components/Grid';
import NatuerlichePersonForm from '../NatuerlichePerson/Form/NatuerlichePersonForm';
import FirmaForm from '../Firma/Form/FirmaForm';
import PersonenGemeinschaftForm from '../PersonenGemeinschaft/Form/PersonenGemeinschaftForm';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';

type Props = {
  onSuccess: (rechtstraegerId: string, kurzBezeichnung: string, type: { value: RechtstraegerType }) => void;
  onCollapse: () => void;
  typeList?: RechtstraegerType[];
};

const RechtsTraegerCreateForm: FC<Props> = ({ typeList, onCollapse, onSuccess }) => {
  const activeType = typeList?.length === 1 ? typeList[0] : undefined;
  const [rechtstraeger, setRechtstraeger] = useState<RechtstraegerType | undefined>(activeType);
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const handleClick = (e: any) => {
    !isTypeNotAllowed(e.target.value as RechtstraegerType) && setRechtstraeger(e.target.value);
  };

  const isTypeNotAllowed = (value: RechtstraegerType) => (typeList ? !typeList.includes(value) : false);

  return (
    <>
      <Row align="top">
        <Col span={6}>
          <Typography.Title level={5}>Form der Person</Typography.Title>
        </Col>
        <Col span={18}>
          <Radio.Group buttonStyle="solid" onChange={handleClick} defaultValue={activeType} block>
            <Radio.Button value={RechtstraegerType.NatuerlichePerson} disabled={isTypeNotAllowed(RechtstraegerType.NatuerlichePerson)}>
              Natürliche Person
            </Radio.Button>
            <Radio.Button value={RechtstraegerType.Firma} disabled={isTypeNotAllowed(RechtstraegerType.Firma)}>
              Firma / Organisation
            </Radio.Button>
            <Radio.Button value={RechtstraegerType.PersonenGemeinschaft} disabled={isTypeNotAllowed(RechtstraegerType.PersonenGemeinschaft)}>
              Personengemeinschaft
            </Radio.Button>
          </Radio.Group>
          <Spacer height={16} />
          {!rechtstraeger && <Typography.Title level={5}>Bitte wählen Sie eine Form der Person aus.</Typography.Title>}
        </Col>
      </Row>
      <Spacer height={16} />
      {rechtstraeger === RechtstraegerType.NatuerlichePerson && (
        <NatuerlichePersonForm
          onCancel={onCollapse}
          kundenSystemId={kundenSystemId}
          onSuccess={(rechtstraegerId, kurzBezeichnung, type) => {
            onSuccess(rechtstraegerId, kurzBezeichnung, type);
            onCollapse();
          }}
        />
      )}

      {rechtstraeger === RechtstraegerType.Firma && (
        <FirmaForm
          onCancel={onCollapse}
          kundenSystemId={kundenSystemId}
          onSuccess={(rechtstraegerId, kurzBezeichnung, type) => {
            rechtstraegerId && onSuccess(rechtstraegerId, kurzBezeichnung, type);
            onCollapse();
          }}
        />
      )}
      {rechtstraeger === RechtstraegerType.PersonenGemeinschaft && (
        <PersonenGemeinschaftForm
          onCancel={onCollapse}
          kundenSystemId={kundenSystemId}
          onSuccess={(rechtstraegerId, kurzBezeichnung, type) => {
            rechtstraegerId && onSuccess(rechtstraegerId, kurzBezeichnung, type);
            onCollapse();
          }}
        />
      )}
    </>
  );
};

export default RechtsTraegerCreateForm;
