import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { RouteTabsProps } from './RouteTabsProps';

const useChangeRouteTabs = (tabs: RouteTabsProps, defaultTabKey?: string | undefined) => {
  const { pathname } = useLocation();
  const [activeTabKey, setActiveTabKey] = useState<string | undefined>(defaultTabKey);

  const changeTab = (activeKey?: string) => {
    setActiveTabKey(activeKey);
  };

  useEffect(() => {
    const activeTab = findActiveTabByPath(tabs, pathname);
    if (activeTab?.key) {
      // user clicks on tab
      setActiveTabKey(activeTab.key);
    } else {
      // if no uri matches in tabs but we want to show default tab
      defaultTabKey && setActiveTabKey(defaultTabKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return [activeTabKey, changeTab] as const;
};

const findActiveTabByPath = (tabs: RouteTabsProps, path: string) => {
  let foundTab: any | undefined;
  tabs?.forEach((tab) => {
    if (tab.key === path) {
      foundTab = tab;
    }
  });
  return foundTab;
};

export default useChangeRouteTabs;
