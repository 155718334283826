import React, { FC } from 'react';
import { Empty } from 'antd';
import { useAuftragLinkListQuery } from '../gql/AuftragQueries.types';
import auftragsketteTableColumns from './auftragsketteTableColumns';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../shared/Auth/authContext';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { AuftragLinkListEntry } from '../../../types';

type AuftragsketteProps = {
  auftragId: string;
};

const Auftragskette: FC<AuftragsketteProps> = ({ auftragId }) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();
  const { loading, data, refetch } = useAuftragLinkListQuery({ variables: { auftragId, includeCurrent: true }, fetchPolicy: 'no-cache' });
  const auftragLinkList = data?.getAuftragLinkList.data ?? [];
  const auftragIds = auftragLinkList.map((auftrag) => auftrag.auftragId);

  useOnDataChangedEvent({
    filter: {
      type: dataChangedSubscriptionTypes.auftrag,
      userId: user.username,
      mitarbeiterId: mitarbeiter?.mitarbeiterId,
      params: { auftragId: auftragIds },
    },
    notificationDescription: 'Vorschreibungen wurden gerade verändert',
    key: 'vorschreibungAuftragLinkList',
    callback: refetch,
  });

  return (
    <TableWithColSelector<AuftragLinkListEntry>
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Vorschreibungen vorhanden</span>} />,
      }}
      size="small"
      dataSource={auftragLinkList}
      columns={auftragsketteTableColumns}
      rowKey={(record) => record.auftragId}
      filterIdentifier="vorschreibung-auftragskette"
    />
  );
};

export default Auftragskette;
