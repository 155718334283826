import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { MenuProps } from 'antd';
import PDFTemplatePage from '../../../components/Template/PDFTemplates/PDFTemplatePage';
import { BelegTexte, TextbausteinType } from '../../../types';
import { BelegTexteFormValues, mapBelegTexteToFormValues } from './belegTexteFormMapper';
import { TextbausteinListWithUpload } from './TextbausteinListWithUpload/TextbausteinListWithUpload';
import FormButtons from '../../../components/Button/FormButtons';
import { handleCreateFirmendatenSubmit, handleCreateRechtstraegerSubmit } from './handleSubmit';
import { mapTextbausteinListToFormValues, TextbausteinFormValues } from '../../../components/Template/PDFTemplates/templateMapper';
import VorschreibungTemplate from '../../../pages/PDFTemplates/templates/vorschreibung/VorschreibungTemplate';
import { createBelegTextePDFTemplate, mapTextbausteinToBelegTexteSelectableSection } from './Template/belegTexteTemplateFormMapper';
import { BelegTextePlatzhalter } from './belegTexteFormTypes';
import { BelegTexteTemplateFieldsFragment } from '../gql/BelegtexteFragments.types';

type Props = {
  firmendatenId: string;
  rechtstraegerId?: string;
  loading: boolean;
  onSuccess: () => void;
  belegTexteTemplate: BelegTexteTemplateFieldsFragment;
  belegTexte: BelegTexte;
  belegTextePlatzhalter: BelegTextePlatzhalter;
  menuItems?: MenuProps['items'];
};

const BelegTexteForm: FC<Props> = ({
  firmendatenId,
  rechtstraegerId,
  belegTexteTemplate,
  belegTexte,
  onSuccess,
  loading,
  belegTextePlatzhalter,
  menuItems,
}) => {
  const [selectedTextbaustein, setSelectedTextbaustein] = useState<TextbausteinType>();
  const setSelectedTextbausteinToNewOrDefaultValue = (newValue: TextbausteinType) => {
    setSelectedTextbaustein((currentSelected) => (currentSelected !== newValue ? newValue : undefined));
  };

  const [templateFormValues] = useState<TextbausteinFormValues[]>(mapTextbausteinListToFormValues(belegTexteTemplate.textbausteinList));

  return (
    <Formik<BelegTexteFormValues>
      initialValues={mapBelegTexteToFormValues(belegTexte)}
      onSubmit={(values, { setSubmitting }) => {
        if (rechtstraegerId) {
          handleCreateRechtstraegerSubmit(firmendatenId, rechtstraegerId, values, setSubmitting, onSuccess);
        } else {
          handleCreateFirmendatenSubmit(firmendatenId, values, setSubmitting, onSuccess);
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <PDFTemplatePage
            menuItems={menuItems}
            textbausteinList={
              <TextbausteinListWithUpload
                belegTexte={belegTexte}
                formikProps={formikProps}
                textbausteinList={templateFormValues}
                onSelectTextbaustein={setSelectedTextbausteinToNewOrDefaultValue}
                level={0}
              />
            }
            renderPreview={() => (
              <VorschreibungTemplate
                data={createBelegTextePDFTemplate(templateFormValues, belegTextePlatzhalter, formikProps.values, firmendatenId, rechtstraegerId)}
                isInEditMode
                selectedTextbaustein={mapTextbausteinToBelegTexteSelectableSection(selectedTextbaustein)}
              />
            )}
            selectedTextbaustein={selectedTextbaustein}
            dimensions={{ width: '100%', height: '800px' }}
          />
          <FormButtons
            onCancel={formikProps.resetForm}
            isOkLoading={loading}
            isOkDisabled={formikProps.isSubmitting}
            okText="Speichern"
            updateMode
            onOk={formikProps.submitForm}
          />
        </Form>
      )}
    </Formik>
  );
};

export default BelegTexteForm;
