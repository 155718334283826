import { FormFields } from '../../../../helpers/formikHelper';
import { AbrechnungsdefinitionType } from '../../../../types';

export type AbrechnungsdefinitionParameterFormValues = {
  type: AbrechnungsdefinitionType | null;
  bezeichnung: string;
  firmendatenId: string;
};

export const abrechnungsdefinitionParameterFormFields: FormFields<AbrechnungsdefinitionParameterFormValues> = {
  type: 'type',
  bezeichnung: 'bezeichnung',
  firmendatenId: 'firmendatenId',
};

export const abrechnungsdefinitionParameterFormInitialValues: AbrechnungsdefinitionParameterFormValues = {
  type: null,
  bezeichnung: '',
  firmendatenId: '',
};
