import { FormFields, TFormattedDecimal } from '../../../../helpers/formikHelper';
import { FibuBelegSymbolTuple, MahnPosition, MahnPositionInput, Mahnung, MahnungUpdateInput } from '../../../../types';
import { mapFormattedDecimalOrThrowIfEmpty } from '../../../../components/Input-antd/formattedDecimalHelper';

export type MahnungUpdateFormValues = {
  mahngebuehr: TFormattedDecimal;
  verzugszinsen: TFormattedDecimal;
  versandEinschreiben: boolean;
  mahnPositionList: MahnpositionFormValues[];
};

export type MahnpositionFormValues = {
  belegDatum: string;
  belegFileId: string;
  belegnummer: string;
  belegSymbol: FibuBelegSymbolTuple;
  buchungskreisId: string;
  dataCarrierBelegId?: string | null;
  dueDate: string;
  gesamtBetrag: number;
  iban?: string | null;
  letzterMahnstichtag?: string | null;
  mahnPositionId: string;
  mahnstufe: number;
  offenerBetrag: number;
  statementNumber?: string | null;
  verzugszinsen: number;
  verzugszinsenBerechnen: boolean;
  zahlungBetrag: number;
};

export const mahnungUpdateFormFields: FormFields<MahnungUpdateFormValues> = {
  mahngebuehr: 'mahngebuehr',
  verzugszinsen: 'verzugszinsen',
  versandEinschreiben: 'versandEinschreiben',
  mahnPositionList: 'mahnPositionList',
};

export const mahnpositionFormFields: FormFields<MahnpositionFormValues> = {
  belegDatum: 'belegDatum',
  belegFileId: 'belegFileId',
  belegSymbol: 'belegSymbol',
  belegnummer: 'belegnummer',
  buchungskreisId: 'buchungskreisId',
  dataCarrierBelegId: 'dataCarrierBelegId',
  dueDate: 'dueDate',
  gesamtBetrag: 'gesamtBetrag',
  iban: 'iban',
  letzterMahnstichtag: 'letzterMahnstichtag',
  mahnPositionId: 'mahnPositionId',
  mahnstufe: 'mahnstufe',
  offenerBetrag: 'offenerBetrag',
  statementNumber: 'statementNumber',
  verzugszinsen: 'verzugszinsen',
  verzugszinsenBerechnen: 'verzugszinsenBerechnen',
  zahlungBetrag: 'zahlungBetrag',
};

export const mapMahnungToFormValues = (mahnung: Mahnung): MahnungUpdateFormValues => {
  return {
    mahngebuehr: mahnung.mahngebuehr,
    verzugszinsen: mahnung.verzugszinsen,
    versandEinschreiben: mahnung.versandEinschreiben,
    mahnPositionList: mapMahnpositionListingToFormValues(mahnung.mahnPositionList),
  };
};

const mapMahnpositionListingToFormValues = (mahnPositionen: MahnPosition[]): MahnpositionFormValues[] => {
  return mahnPositionen.map((mahnPosition) => ({
    mahnPositionId: mahnPosition.mahnPositionId,
    belegSymbol: mahnPosition.belegSymbol,
    belegFileId: mahnPosition.belegFileId,
    belegnummer: mahnPosition.belegnummer,
    belegDatum: mahnPosition.belegDatum,
    buchungskreisId: mahnPosition.buchungskreisId,
    dueDate: mahnPosition.dueDate,
    dataCarrierBelegId: mahnPosition.dataCarrierBelegId,
    gesamtBetrag: mahnPosition.gesamtBetrag,
    iban: mahnPosition.iban,
    letzterMahnstichtag: mahnPosition.letzterMahnstichtag,
    mahnstufe: mahnPosition.mahnstufe,
    offenerBetrag: mahnPosition.offenerBetrag,
    statementNumber: mahnPosition.statementNumber,
    verzugszinsen: mahnPosition.verzugszinsen,
    verzugszinsenBerechnen: mahnPosition.verzugszinsenBerechnen,
    zahlungBetrag: mahnPosition.zahlungBetrag,
  }));
};

export const mapFormValuesToMahnungUpdate = (mahnung: MahnungUpdateFormValues): MahnungUpdateInput => {
  return {
    mahngebuehr: mapFormattedDecimalOrThrowIfEmpty(mahnung.mahngebuehr),
    verzugszinsen: mapFormattedDecimalOrThrowIfEmpty(mahnung.verzugszinsen),
    versandEinschreiben: mahnung.versandEinschreiben,
    mahnPositionList: mapFormValuesToMahnpositionListing(mahnung.mahnPositionList),
  };
};

const mapFormValuesToMahnpositionListing = (mahnPositionList: MahnpositionFormValues[]): MahnPositionInput[] => {
  return mahnPositionList.map((mahnPosition) => {
    return {
      mahnPositionId: mahnPosition.mahnPositionId,
      mahnstufe: mahnPosition.mahnstufe,
      verzugszinsenBerechnen: mahnPosition.verzugszinsenBerechnen,
    };
  });
};
