import { Input } from 'formik-antd';
import { FormikProps } from 'formik';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useKurzBezeichnungVorschlag } from '../useKurzBezeichnungVorschlag';
import { KurzBezeichnungVorschlagConfigProps } from '../kurzBezeichnungVorschlagHelpers';

type Props<T> = {
  formikProps: FormikProps<T>;
  kurzBezeichnungVorschlagConfig?: KurzBezeichnungVorschlagConfigProps<T>;
  placeholder?: string;
  fieldHelp?: string | null;
};

const KurzBezeichnungFormPart = <T,>({ kurzBezeichnungVorschlagConfig, placeholder, formikProps, fieldHelp }: Props<T>) => {
  useKurzBezeichnungVorschlag(formikProps, kurzBezeichnungVorschlagConfig);

  return (
    <FormItemWithFieldHelp name="kurzBezeichnung" label="Kurzbezeichnung" fieldHelp={fieldHelp}>
      <Input id="kurzBezeichnung" name="kurzBezeichnung" placeholder={placeholder} />
    </FormItemWithFieldHelp>
  );
};

export default KurzBezeichnungFormPart;
