import { FormikProps } from 'formik';
import { updateFormikField } from '../../../../helpers/formikHelper';
import { stringOrEmpty } from '../../../../helpers/stringHelper';

export const updateStreetAddress = (formikProps: FormikProps<any>, fieldNamePrefix: string, streetAddress: any, preUpdateFn?: () => void) => {
  if (
    streetAddress &&
    (streetAddress.countryCodeIso2 ||
      streetAddress.zipCode ||
      streetAddress.city ||
      streetAddress.street ||
      streetAddress.houseEntranceApartmentNumber)
  ) {
    if (preUpdateFn) preUpdateFn();
    updateFormikField(formikProps, `${fieldNamePrefix}countryCodeIso2`, stringOrEmpty(streetAddress.countryCodeIso2));
    updateFormikField(formikProps, `${fieldNamePrefix}zipCode`, stringOrEmpty(streetAddress.zipCode));
    updateFormikField(formikProps, `${fieldNamePrefix}city`, stringOrEmpty(streetAddress.city));
    updateFormikField(formikProps, `${fieldNamePrefix}street`, stringOrEmpty(streetAddress.street));
    updateFormikField(formikProps, `${fieldNamePrefix}houseEntranceApartmentNumber`, stringOrEmpty(streetAddress.houseEntranceApartmentNumber));
  }
};
