import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { FILE_CONTENT_TYPE_PDF, IMAGE_SUPPORTED_FORMATS } from '../../../../../helpers/fileHelper';
import { EmailTemplateStepFormValues, FileData, WizardAttachmentFileInfo } from './emailTemplateStepFormMapper';

type Props = {
  formikProps: FormikProps<EmailTemplateStepFormValues>;
  name: string;
  attachments: WizardAttachmentFileInfo[];
  disabled?: boolean;
};

const AttachmentUpload: FC<Props> = ({ formikProps, name, attachments, disabled }) => (
  <Upload.Dragger
    disabled={disabled}
    beforeUpload={(_, fileList) => {
      formikProps.setFieldTouched(name, true, false);

      let files: FileData[] = [];

      fileList.forEach((file) => {
        if (validateFile(file, fileList, attachments, formikProps, name)) {
          const data: FileData = {
            filename: file.name,
            file,
          };
          files = [...files, data];
        }

        files.length > 0 && formikProps.setFieldValue(name, [...formikProps.values.newAttachmentList, ...files]);
      });
      return false;
    }}
    multiple
    showUploadList={false}
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Dateien hinzufügen</p>
    <p className="ant-upload-hint">Eine oder mehrere Dateien hochladen</p>
  </Upload.Dragger>
);

function validateFile<T>(
  currentFile: RcFile,
  currentFileList: RcFile[],
  attachments: WizardAttachmentFileInfo[],
  formikProps: FormikProps<T>,
  name: string
): boolean {
  const hasNoType = !currentFile.type;
  if (hasNoType) {
    formikProps.setFieldError(name, `${currentFile.name} hat keinen Dateityp. Ordner dürfen nicht hochgeladen werden.`);
    return false;
  }

  const allowedFileContentTypes = [FILE_CONTENT_TYPE_PDF, ...IMAGE_SUPPORTED_FORMATS];

  if (!allowedFileContentTypes.includes(currentFile.type)) {
    formikProps.setFieldError(
      name,
      `Das Format der Beleg Datei ${currentFile.type} wird nicht unterstützt, unterstützte Formate sind: ${allowedFileContentTypes}`
    );
    return false;
  }

  const maxSizeMB = 10;
  const maxSizeBytes = maxSizeMB * 1024 * 1024;
  const totalSize = [...attachments, ...currentFileList].reduce((prev, current) => prev + (current?.size || 0), 0);
  const isTooLarge = totalSize > maxSizeBytes;
  if (isTooLarge) {
    formikProps.setFieldError(name, `Dateien müssen kleiner als 10 MB sein.`);
    return false;
  }

  return true;
}

export default AttachmentUpload;
