import { AddressSharedModel } from './AddressSharedModel';
import { AddressType } from '../../../types';

export const addressToString = (address: AddressSharedModel): string => {
  if (address.type === AddressType.Street) {
    return `${address.street} ${address.houseEntranceApartmentNumber}, ${address.city} ${address.zipCode}`;
  } else {
    return `${address.postofficeBoxNumber}, ${address.postofficeBoxZipCode}, ${address.city}`;
  }
};
