import React from 'react';
import { Link } from 'react-router-dom';
import { friendlyFormatIBAN } from 'ibantools';
import { Space, TableColumnProps, Tooltip } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { ReceivedBankTransactionListEntry, ReceivedBankTransactionOrderBy } from '../../../../types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { EuroAmount } from '../../../../components/Number';
import StatusBadge from '../../../../components/Status/StatusBadge';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { fontWeightForNotedRecord, TextAndFontWeightForNotedRecord } from '../../../Payment/shared/TextAndFontWeightForNotedRecord';
import { generatePathToBankstatementListingPage } from '../../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';
import { generatePathToPaymentPage } from '../../../Payment/Listing/Filters/filtersQueryParams';
import ReceivedBankTransactionTableActions from './ReceivedBankTransactionTableActions';
import { isStatusArchived, StatusEnum } from '../../../../helpers/statusHelper';
import { isNil, isNotNil } from '../../../../helpers/assertionHelper';
import CurrentBasedData from '../../../../components/Helpers/CurrentBasedData';
import theme from '../../../../theme';

const receivedBankTransactionTableColumns = (onAction: () => void): TableColumnProps<ReceivedBankTransactionListEntry>[] => [
  {
    title: 'Gesehen',
    dataIndex: ReceivedBankTransactionOrderBy.Noted,
    sorter: true,
    render: (text, record) => (
      <Space>
        {record.warning && (
          <Tooltip placement="top" title={record.warning} styles={{ root: { minWidth: '200px', maxWidth: '400px' } }}>
            <WarningTwoTone style={{ cursor: 'help' }} twoToneColor={theme.colors.warning} />
          </Tooltip>
        )}
        <TextAndFontWeightForNotedRecord
          noted={record.noted}
          content={record.noted ? 'Gesehen' : 'Neu'}
          isCurrent={!isStatusArchived(record.status)}
        />
      </Space>
    ),
  },
  {
    title: 'Datenträger',
    dataIndex: ReceivedBankTransactionOrderBy.DataCarrierName,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.dataCarrierName}>
        {(shortenedText) => (
          <Link target="_blank" to={generatePathToBankstatementListingPage({ name: record.dataCarrierName })}>
            <TextAndFontWeightForNotedRecord noted={record.noted} content={shortenedText} />
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Kontoauszugsnummer',
    dataIndex: ReceivedBankTransactionOrderBy.StatementNumber,
    sorter: true,
    render: (text, record) => (
      <TextAndFontWeightForNotedRecord noted={record.noted} content={record.statementNumber} isCurrent={!isStatusArchived(record.status)} />
    ),
  },
  {
    title: 'Kontoauszug IBAN',
    dataIndex: ReceivedBankTransactionOrderBy.BankstatementIban,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={`${friendlyFormatIBAN(record.bankStatementIban)}`}>
        {(shortenedText) => (
          <TextAndFontWeightForNotedRecord noted={record.noted} content={shortenedText} isCurrent={!isStatusArchived(record.status)} />
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Kontoauszugindex',
    dataIndex: ReceivedBankTransactionOrderBy.Index,
    sorter: true,
    render: (text, record) =>
      isNotNil(record.index) ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.index} isCurrent={!isStatusArchived(record.status)} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'TransaktionsanweisungsId',
    dataIndex: ReceivedBankTransactionOrderBy.EndToEndId,
    sorter: true,
    render: (text, record) =>
      record.endToEndId ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.endToEndId} isCurrent={!isStatusArchived(record.status)} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Transaktionsart',
    dataIndex: ReceivedBankTransactionOrderBy.TransactionType,
    sorter: true,
    render: (text, record) => (
      <TextAndFontWeightForNotedRecord noted={record.noted} content={record.transactionType.text} isCurrent={!isStatusArchived(record.status)} />
    ),
  },
  {
    title: 'Valutadatum',
    dataIndex: ReceivedBankTransactionOrderBy.ValueDate,
    sorter: true,
    render: (text, record) =>
      record.valueDate ? (
        <TextAndFontWeightForNotedRecord
          noted={record.noted}
          content={<CustomFormattedDate value={record.valueDate} />}
          isCurrent={!isStatusArchived(record.status)}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Rücklastschrift',
    dataIndex: ReceivedBankTransactionOrderBy.Reversal,
    sorter: true,
    render: (text, record) => (
      <TextAndFontWeightForNotedRecord noted={record.noted} content={record.reversal ? 'Ja' : 'Nein'} isCurrent={!isStatusArchived(record.status)} />
    ),
  },
  {
    title: 'Kreditor',
    dataIndex: ReceivedBankTransactionOrderBy.Creditor,
    sorter: true,
    render: (text, record) =>
      record.creditor ? (
        <DataWithShortenedText maxTextLength={20} text={record.creditor}>
          {(shortenedText) => (
            <TextAndFontWeightForNotedRecord noted={record.noted} content={shortenedText} isCurrent={!isStatusArchived(record.status)} />
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Kreditor Bankverbindung',
    dataIndex: ReceivedBankTransactionOrderBy.CreditorIban,
    sorter: true,
    render: (text, record) =>
      record.creditorIban ? (
        <DataWithShortenedText maxTextLength={20} text={`${friendlyFormatIBAN(record.creditorIban)}`}>
          {(shortenedText) => (
            <TextAndFontWeightForNotedRecord noted={record.noted} content={shortenedText} isCurrent={!isStatusArchived(record.status)} />
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Debitor',
    dataIndex: ReceivedBankTransactionOrderBy.Debtor,
    sorter: true,
    render: (text, record) =>
      record.debtor ? (
        <DataWithShortenedText maxTextLength={20} text={record.debtor}>
          {(shortenedText) => (
            <TextAndFontWeightForNotedRecord noted={record.noted} content={shortenedText} isCurrent={!isStatusArchived(record.status)} />
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Debitor Bankverbindung',
    dataIndex: ReceivedBankTransactionOrderBy.DebtorIban,
    sorter: true,
    render: (text, record) =>
      record.debtorIban ? (
        <DataWithShortenedText maxTextLength={20} text={`${friendlyFormatIBAN(record.debtorIban)}`}>
          {(shortenedText) => (
            <TextAndFontWeightForNotedRecord noted={record.noted} content={shortenedText} isCurrent={!isStatusArchived(record.status)} />
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Betrag',
    dataIndex: ReceivedBankTransactionOrderBy.Amount,
    sorter: true,
    align: 'right',
    render: (text, record) => (
      <TextAndFontWeightForNotedRecord
        noted={record.noted}
        content={<EuroAmount value={record.amount} />}
        isCurrent={!isStatusArchived(record.status)}
      />
    ),
  },
  {
    title: 'Spesen',
    dataIndex: ReceivedBankTransactionOrderBy.Charges,
    sorter: true,
    align: 'right',
    render: (text, record) =>
      record.charges ? (
        <TextAndFontWeightForNotedRecord
          noted={record.noted}
          content={<EuroAmount value={record.charges} />}
          isCurrent={!isStatusArchived(record.status)}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Spesen in eigener Bankbuchung',
    dataIndex: ReceivedBankTransactionOrderBy.ChargesInSeparateEntry,
    sorter: true,
    render: (text, record) =>
      isNil(record.chargesInSeparateEntry) ? (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ) : (
        <TextAndFontWeightForNotedRecord
          noted={record.noted}
          content={record.chargesInSeparateEntry ? 'Ja' : 'Nein'}
          isCurrent={!isStatusArchived(record.status)}
        />
      ),
  },
  {
    title: 'Verwendungszweck',
    dataIndex: ReceivedBankTransactionOrderBy.PurposeOfUseText,
    sorter: true,
    render: (text, record) =>
      record.purposeOfUseText ? (
        <DataWithShortenedText maxTextLength={20} text={record.purposeOfUseText}>
          {(shortenedText) => (
            <TextAndFontWeightForNotedRecord noted={record.noted} content={shortenedText} isCurrent={!isStatusArchived(record.status)} />
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Zahlungsreferenz',
    dataIndex: ReceivedBankTransactionOrderBy.PaymentReferenceText,
    sorter: true,
    render: (text, record) =>
      record.paymentReferenceText ? (
        <DataWithShortenedText maxTextLength={20} text={record.paymentReferenceText}>
          {(shortenedText) => (
            <TextAndFontWeightForNotedRecord noted={record.noted} content={shortenedText} isCurrent={!isStatusArchived(record.status)} />
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Verbucht',
    dataIndex: ReceivedBankTransactionOrderBy.Booked,
    sorter: true,
    render: (text, record) => (
      <TextAndFontWeightForNotedRecord noted={record.noted} content={record.booked ? 'Ja' : 'Nein'} isCurrent={!isStatusArchived(record.status)} />
    ),
  },
  {
    title: 'Buchungsdatum',
    dataIndex: ReceivedBankTransactionOrderBy.BookingDate,
    sorter: true,
    render: (text, record) =>
      record.bookingDate ? (
        <TextAndFontWeightForNotedRecord
          noted={record.noted}
          content={<CustomFormattedDate value={record.bookingDate} />}
          isCurrent={!isStatusArchived(record.status)}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Status',
    dataIndex: ReceivedBankTransactionOrderBy.Status,
    sorter: true,
    render: (text, record) => (
      <StatusBadge
        entityStatus={record.status}
        badgeTextStyle={{
          color: isStatusArchived(record.status) ? theme.textColor.disabled : undefined,
          fontWeight: fontWeightForNotedRecord(record.noted),
        }}
      />
    ),
  },
  {
    title: 'Buchungsvorschlag Status',
    dataIndex: ReceivedBankTransactionOrderBy.Valid,
    sorter: true,
    render: (text, record) => {
      if (isNil(record.valid)) {
        return (
          <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
        );
      }

      const status = {
        text: record.valid ? 'Gültig' : 'Ungültig',
        value: record.valid ? StatusEnum.Valid : StatusEnum.Invalid,
      };

      return (
        <StatusBadge
          entityStatus={status}
          badgeTextStyle={{
            color: isStatusArchived(record.status) ? theme.textColor.disabled : undefined,
            fontWeight: fontWeightForNotedRecord(record.noted),
          }}
        />
      );
    },
  },
  {
    title: 'Warnung',
    render: (text, record) =>
      record.warning ? (
        <DataWithShortenedText maxTextLength={20} text={record.warning}>
          {(shortenedText) => (
            <TextAndFontWeightForNotedRecord noted={record.noted} content={shortenedText} isCurrent={!isStatusArchived(record.status)} />
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Auszifferungsvorschläge Anzahl',
    dataIndex: ReceivedBankTransactionOrderBy.CountBookingSuggestionClearingSuggestion,
    sorter: true,
    render: (_, record) =>
      isNotNil(record.countBookingSuggestionClearingSuggestion) ? (
        <TextAndFontWeightForNotedRecord
          noted={record.noted}
          content={record.countBookingSuggestionClearingSuggestion}
          isCurrent={!isStatusArchived(record.status)}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Verfügbarer Betrag',
    dataIndex: ReceivedBankTransactionOrderBy.AvailableAmount,
    sorter: true,
    align: 'right',
    render: (_, record) =>
      isNotNil(record.availableAmount) ? (
        <TextAndFontWeightForNotedRecord
          noted={record.noted}
          content={<EuroAmount value={record.availableAmount} />}
          isCurrent={!isStatusArchived(record.status)}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Zugewiesene Zahlung',
    dataIndex: ReceivedBankTransactionOrderBy.Payment,
    sorter: true,
    render: (text, record) => {
      const payment = record.payment;

      return payment ? (
        <DataWithShortenedText maxTextLength={20} text={payment.paymentLinkText}>
          {(shortenedText) => (
            <Link
              target="_blank"
              to={generatePathToPaymentPage({
                rechnungsAusstellerId: payment.rechnungsAusstellerId,
                payeeId: payment.payeeId,
                paymentTypeList: payment.paymentType ? [payment.paymentType.value] : undefined,
                paymentDateFrom: payment.paymentDate,
                paymentDateTo: payment.paymentDate,
                amountFrom: payment.amount,
                amountTo: payment.amount,
              })}
              style={{ fontWeight: fontWeightForNotedRecord(record.noted) }}
            >
              <TextAndFontWeightForNotedRecord noted={record.noted} content={shortenedText} isCurrent={!isStatusArchived(record.status)} />
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      );
    },
  },
  {
    title: 'Erstellt am',
    dataIndex: ReceivedBankTransactionOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData isCurrent={!isStatusArchived(record.status)} text="">
        <TextAndFontWeightForNotedRecord noted={record.noted} content={<FormattedDateTime createdTs={record.createTs} />} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Ersteller',
    dataIndex: ReceivedBankTransactionOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    render: (text, record) => <ReceivedBankTransactionTableActions receivedBankTransaction={record} onAction={onAction} />,
  },
];

export default receivedBankTransactionTableColumns;
