import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Col, Divider, Row, Space, Typography } from 'antd';
import { Switch } from 'formik-antd';
import { incomingInvoiceFormFields, IncomingInvoiceFormValues } from './incomingInvoiceFormMapper';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import PartialAmountListFormFieldArray from './PartialAmountListFormFieldArray';
import FormBlockRow from './shared/FormBlockRow';

type Props = {
  formikProps: FormikProps<IncomingInvoiceFormValues>;
  shouldRestore: boolean;
};

const StandingInvoiceBlockForm: FC<Props> = ({ shouldRestore, formikProps }) => {
  return (
    <>
      <FormBlockRow
        rowTitle="Dauerrechnung"
        content={
          <>
            <Row gutter={16} style={{ marginTop: 0 }}>
              <Col span={16}>
                <FormItemWithoutColon name={incomingInvoiceFormFields.inklusiveSteuer} label="Umsatzsteuer">
                  <Space>
                    <Switch disabled={shouldRestore} size="default" name={incomingInvoiceFormFields.inklusiveSteuer} />
                    <Typography.Text>{formikProps.values.inklusiveSteuer ? 'inklusive' : 'exklusive'}</Typography.Text>
                  </Space>
                </FormItemWithoutColon>
              </Col>
            </Row>
          </>
        }
      />
      <PartialAmountListFormFieldArray name={incomingInvoiceFormFields.partialAmountList} formikProps={formikProps} shouldRestore={shouldRestore} />
      <Divider />
    </>
  );
};

export default StandingInvoiceBlockForm;
