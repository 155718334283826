import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { VVPosSummary } from '../../vorschreibung-types';
import { objektSummaryColumnWidthsAndAlignment } from './objekt-block-tables-styles';

const VTObjektSummary: FC<{ data: VVPosSummary }> = ({ data }) => (
  <View style={[pdfStyles.row, { fontWeight: 'bold', paddingTop: '3mm' }]}>
    <Text
      style={[
        pdfStyles.textNormal,
        {
          width: objektSummaryColumnWidthsAndAlignment.summeText.width + '3mm',
          textAlign: objektSummaryColumnWidthsAndAlignment.summeText.textAlign,
        },
      ]}
    >
      {data.summeText}
    </Text>
    <Text style={{ width: '3mm' }} />
    <Text
      style={[
        pdfStyles.textNormal,
        {
          width: objektSummaryColumnWidthsAndAlignment.netto.width,
          textAlign: objektSummaryColumnWidthsAndAlignment.netto.textAlign,
        },
      ]}
    >
      {data.netto}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        {
          width: objektSummaryColumnWidthsAndAlignment.ustBetrag.width,
          textAlign: objektSummaryColumnWidthsAndAlignment.ustBetrag.textAlign,
        },
      ]}
    >
      {data.ustBetrag}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        {
          width: objektSummaryColumnWidthsAndAlignment.brutto.width,
          textAlign: objektSummaryColumnWidthsAndAlignment.brutto.textAlign,
        },
      ]}
    >
      {data.brutto}
    </Text>
  </View>
);

export default VTObjektSummary;
