import React, { FC, PropsWithChildren } from 'react';
import { Skeleton } from 'antd';
import { useField } from 'formik';
import { vorschreibungsPositionWertFormFields } from './vertragVorschreibungsPositionWertMapper';

const SkeletonIfSteuersatzLoading: FC<PropsWithChildren> = ({ children }) => {
  const [{ value: isLoadingSteuersatz }] = useField<boolean>(vorschreibungsPositionWertFormFields.isLoadingSteuersatz);

  return isLoadingSteuersatz ? <Skeleton active loading paragraph={false} /> : <>{children}</>;
};

export default SkeletonIfSteuersatzLoading;
