import React, { FC } from 'react';
import { Col, Divider, Modal, Row } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Formik } from 'formik';
import { DatePicker, Form, Radio } from 'formik-antd';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import FormButtons from '../../../../components/Button/FormButtons';
import { useVorsteuerkuerzungApplyAllDeviationsMutation } from '../gql/VorsteuerkuerzungDeviationMutations.types';
import { vstKrzApplyDeviationsFormValidationSchema } from './vstKrzApplyDeviationsFormValidationSchema';
import {
  mapFormValuesToVstKrzApplyDeviationsForm,
  stornoAbgrenzungFields,
  stornoDirektFields,
  vstKrzApplyDeviationsFormFields,
  vstKrzApplyDeviationsFormInitialValues,
  VstKrzApplyDeviationsFormValues,
} from './vstKrzApplyDeviationsFormMapper';

type Props = {
  objektId: string;
  vorsteuerkuerzungId: string;
  lastBuchungsdatum: string | null | undefined;
  visible: boolean;
  onSuccess: () => void;
  onCancel: () => void;
};

const VstKrzApplyDeviationsModal: FC<Props> = ({ objektId, vorsteuerkuerzungId, lastBuchungsdatum, visible, onSuccess, onCancel }) => {
  const [runApplyAllDeviations, { loading }] = useVorsteuerkuerzungApplyAllDeviationsMutation({
    onCompleted: () => {
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Modal
      open={visible}
      title={
        <>
          <QuestionCircleTwoTone twoToneColor="#FAAD14" />
          <span style={{ paddingLeft: '8px' }}>Abweichungen übernehmen</span>
        </>
      }
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
    >
      <Formik<VstKrzApplyDeviationsFormValues>
        initialValues={vstKrzApplyDeviationsFormInitialValues(lastBuchungsdatum)}
        validationSchema={vstKrzApplyDeviationsFormValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const input = mapFormValuesToVstKrzApplyDeviationsForm(values);
          runApplyAllDeviations({
            variables: {
              objektId,
              vorsteuerkuerzungId,
              input,
            },
          }).finally(() => {
            setSubmitting(false);
          });
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <Radio.Group name={vstKrzApplyDeviationsFormFields.abgrenzungsBuchung} buttonStyle="solid" block>
              <Radio.Button value={false}>Korrektur direkt</Radio.Button>
              <Radio.Button value>Korrektur mit Abgrenzung</Radio.Button>
            </Radio.Group>

            {!formikProps.values.abgrenzungsBuchung ? (
              <Row style={{ marginTop: 10 }}>
                <Col style={{ width: '100%' }}>
                  <FormItemWithoutColon
                    name={`${vstKrzApplyDeviationsFormFields.stornoDirekt}.${stornoDirektFields.stornoBuchungsdatum}`}
                    label="Storno - Buchungsdatum"
                  >
                    <DatePicker
                      id={`${vstKrzApplyDeviationsFormFields.stornoDirekt}.${stornoDirektFields.stornoBuchungsdatum}`}
                      name={`${vstKrzApplyDeviationsFormFields.stornoDirekt}.${stornoDirektFields.stornoBuchungsdatum}`}
                      placeholder="Datum wählen"
                      format={DATE_DISPLAY_FORMAT_DEFAULT}
                      allowClear={false}
                      size="middle"
                      style={{ width: '180px' }}
                    />
                  </FormItemWithoutColon>
                </Col>
                <Col style={{ width: '100%' }}>
                  <FormItemWithoutColon
                    name={`${vstKrzApplyDeviationsFormFields.stornoDirekt}.${stornoDirektFields.korrekturBuchungsdatum}`}
                    label="Korrektur - Buchungsdatum"
                  >
                    <DatePicker
                      id={`${vstKrzApplyDeviationsFormFields.stornoDirekt}.${stornoDirektFields.korrekturBuchungsdatum}`}
                      name={`${vstKrzApplyDeviationsFormFields.stornoDirekt}.${stornoDirektFields.korrekturBuchungsdatum}`}
                      placeholder="Datum wählen"
                      format={DATE_DISPLAY_FORMAT_DEFAULT}
                      allowClear={false}
                      size="middle"
                      style={{ width: '180px' }}
                    />
                  </FormItemWithoutColon>
                </Col>
              </Row>
            ) : (
              <>
                <Divider>Storno</Divider>
                <Row style={{ marginTop: 10 }}>
                  <Col style={{ width: '100%' }}>
                    <FormItemWithoutColon
                      name={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoBuchungsdatum}`}
                      label="Stornobuchung mit Aufwand Abgrenzung - Buchungsdatum"
                    >
                      <DatePicker
                        id={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoBuchungsdatum}`}
                        name={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoBuchungsdatum}`}
                        placeholder="Datum wählen"
                        format={DATE_DISPLAY_FORMAT_DEFAULT}
                        allowClear={false}
                        size="middle"
                        style={{ width: '180px' }}
                      />
                    </FormItemWithoutColon>
                  </Col>
                  <Col style={{ width: '100%' }}>
                    <FormItemWithoutColon
                      name={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoAbgrenzungsBuchungsdatum}`}
                      label="Aufwand Stornobuchung der Aufwand Abgrenzung - Buchungsdatum"
                    >
                      <DatePicker
                        id={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoAbgrenzungsBuchungsdatum}`}
                        name={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.stornoAbgrenzungsBuchungsdatum}`}
                        placeholder="Datum wählen"
                        format={DATE_DISPLAY_FORMAT_DEFAULT}
                        allowClear={false}
                        size="middle"
                        style={{ width: '180px' }}
                      />
                    </FormItemWithoutColon>
                  </Col>

                  <Divider>Korrektur</Divider>
                  <Col style={{ width: '100%' }}>
                    <FormItemWithoutColon
                      name={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturBuchungsdatum}`}
                      label="Korrekturbuchung mit Aufwand Abgrenzung - Buchungsdatum"
                    >
                      <DatePicker
                        id={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturBuchungsdatum}`}
                        name={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturBuchungsdatum}`}
                        placeholder="Datum wählen"
                        format={DATE_DISPLAY_FORMAT_DEFAULT}
                        allowClear={false}
                        size="middle"
                        style={{ width: '180px' }}
                      />
                    </FormItemWithoutColon>
                  </Col>
                  <Col style={{ width: '100%' }}>
                    <FormItemWithoutColon
                      name={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturAbgrenzungsBuchungsdatum}`}
                      label="Aufwand Korrekturbuchung der Aufwand Abgrenzung - Buchungsdatum"
                    >
                      <DatePicker
                        id={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturAbgrenzungsBuchungsdatum}`}
                        name={`${vstKrzApplyDeviationsFormFields.stornoAbgrenzung}.${stornoAbgrenzungFields.korrekturAbgrenzungsBuchungsdatum}`}
                        placeholder="Datum wählen"
                        format={DATE_DISPLAY_FORMAT_DEFAULT}
                        allowClear={false}
                        size="middle"
                        style={{ width: '180px' }}
                      />
                    </FormItemWithoutColon>
                  </Col>
                </Row>
              </>
            )}
            <FormButtons okText="Übernehmen" onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} updateMode={false} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default VstKrzApplyDeviationsModal;
