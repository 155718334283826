import { FormFields } from '../../../../../../helpers/formikHelper';
import { AddressType } from '../../../../../../types';

export interface PostofficeBoxAddressCreateFormValues {
  type: AddressType.PostofficeBox;
  countryCodeIso2: string;
  city: string;
  cityAdditionalInformation: string;
  postofficeBoxNumber: string;
  zipCode: string;
}

export const postofficeBoxAddressCreateFormFields: FormFields<PostofficeBoxAddressCreateFormValues> = {
  type: 'type',
  countryCodeIso2: 'countryCodeIso2',
  city: 'city',
  cityAdditionalInformation: 'cityAdditionalInformation',
  postofficeBoxNumber: 'postofficeBoxNumber',
  zipCode: 'zipCode',
};

export const postofficeBoxAddressCreateFormInitialValues: PostofficeBoxAddressCreateFormValues = {
  type: AddressType.PostofficeBox,
  countryCodeIso2: '',
  city: '',
  cityAdditionalInformation: '',
  postofficeBoxNumber: '',
  zipCode: '',
};
