import React, { FC } from 'react';
import { Empty, Table } from 'antd';
import { offenePostenAuszifferungTableColumns } from './offenePostenAuszifferungTableColumns';
import { Auszifferung, OffenePosten } from '../../../../types';

type OffenePostenAuszifferungTableProps = {
  offenePosten: OffenePosten;
  onAction: () => void;
};

const OffenePostenAuszifferungTable: FC<OffenePostenAuszifferungTableProps> = ({ offenePosten, onAction }) => {
  return (
    <Table<Auszifferung>
      rowKey={(record) => record.auszifferungId}
      columns={offenePostenAuszifferungTableColumns(offenePosten, onAction)}
      dataSource={offenePosten.auszifferungList}
      pagination={false}
      locale={{
        emptyText: <Empty description="Keine Auszifferungen vorhanden" />,
      }}
    />
  );
};

export default OffenePostenAuszifferungTable;
