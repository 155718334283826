import React, { FC } from 'react';
import { Typography } from 'antd';
import { verarbeitungPageAndMenuListTitles } from '../verarbeitungHelpers';
import { Spacer } from '../../../components/Grid';
import HeAbrechnungErstellenVerarbeitungEntryListing from './Erstellen/EntryListing/HeAbrechnungErstellenVerarbeitungEntryListing';
import { HeAbrechnungGenerierlauf } from '../../../types';

type Props = {
  generierlauf: HeAbrechnungGenerierlauf;
};

const VerarbeitungHeAbrechnungErstellen: FC<Props> = ({ generierlauf }) => (
  <>
    <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.HeAbrechnung}</Typography.Title>
    <Spacer />
    <HeAbrechnungErstellenVerarbeitungEntryListing generierlauf={generierlauf} />
  </>
);

export default VerarbeitungHeAbrechnungErstellen;
