import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type EbicsUserIbanFieldsFragment = {
  accountHolder: string;
  accountNumber?: string | null;
  activationTs?: string | null;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  ebicsUserIbanId: string;
  iban: string;
  lastBankStatementFetchDate?: string | null;
  mitarbeiterName: string;
  retrieveBankStatement: boolean;
  creditTransferPermission?: { text: string; value: Types.EbicsIbanPermission } | null;
  directDebitPermission?: { text: string; value: Types.EbicsIbanPermission } | null;
  status: { text: string; value: Types.EbicsUserIbanStatus };
};

export const EbicsUserIbanFieldsFragmentDoc = gql`
  fragment EbicsUserIbanFields on EbicsUserIban {
    accountHolder
    accountNumber
    activationTs
    createTs
    createdBy
    createdByMitarbeiterId
    creditTransferPermission {
      text
      value
    }
    directDebitPermission {
      text
      value
    }
    ebicsUserIbanId
    iban
    lastBankStatementFetchDate
    mitarbeiterName
    retrieveBankStatement
    status {
      text
      value
    }
  }
`;
