import React, { FC } from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import VorschreibungTemplate, { VorschreibungSelectableSection } from './templates/vorschreibung/VorschreibungTemplate';
import { mockVorschreibungDauerRg } from './mocks/vorschreibung/vorschreibungDauerRgMocks';

type PDFPageVorschreibungDauerRgProps = {
  isInEditMode?: boolean;
  selectedTextbaustein?: VorschreibungSelectableSection;
};

const PDFPageVorschreibungDauerRg: FC<PDFPageVorschreibungDauerRgProps> = ({ isInEditMode, selectedTextbaustein }) => (
  <Row>
    <PDFPageCol
      pdfTemplate={<VorschreibungTemplate data={mockVorschreibungDauerRg} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />}
    />
  </Row>
);

export default PDFPageVorschreibungDauerRg;
