import { FormFields } from '../../../helpers/formikHelper';
import {
  EmailDeliveryDefinitionCreateInput,
  EmailDeliveryDefinitionType,
  EmailDeliveryDefinitionUpdateInput,
  TextbausteinType,
} from '../../../types';
import { mapTextbausteinToFormValues, TextbausteinFormValues } from '../../../components/Template/PDFTemplates/templateMapper';
import { EmailDeliveryDefinitionFieldsFragment } from '../gql/EmailDeliveryDefinitionFragments.types';

export type EmailDeliveryDefinitionFormValues = {
  bezeichnung: string;
  type?: EmailDeliveryDefinitionType;
  // subject and body are only used for validation purposes
  subject: TextbausteinFormValues;
  body: TextbausteinFormValues;
};

export const textbausteinInitialValues = (type: TextbausteinType): TextbausteinFormValues => ({
  type: { text: '', value: type },
  value: '',
  index: 0,
  platzhalterList: [],
});

export const emailDeliveryDefFormInitialValues: EmailDeliveryDefinitionFormValues = {
  bezeichnung: '',
  type: undefined,
  subject: textbausteinInitialValues(TextbausteinType.EmailDeliveryDefinitionSubject),
  body: textbausteinInitialValues(TextbausteinType.EmailDeliveryDefinitionBody),
};

export const mapEmailDeliveryDefinitionToFormValues = (
  emailDeliveryDefinition: EmailDeliveryDefinitionFieldsFragment
): EmailDeliveryDefinitionFormValues => ({
  bezeichnung: emailDeliveryDefinition.bezeichnung,
  type: emailDeliveryDefinition.type.value,
  subject: mapTextbausteinToFormValues(emailDeliveryDefinition.subject),
  body: emailDeliveryDefinition.body
    ? mapTextbausteinToFormValues(emailDeliveryDefinition.body)
    : textbausteinInitialValues(TextbausteinType.EmailDeliveryDefinitionBody),
});

export const mapFormValuesToCreateEmailDeliveryDefinition = (formValues: EmailDeliveryDefinitionFormValues): EmailDeliveryDefinitionCreateInput => ({
  ...mapFormValuesToUpdateEmailDeliveryDefinition(formValues),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  type: formValues.type!,
});

export const mapFormValuesToUpdateEmailDeliveryDefinition = (formValues: EmailDeliveryDefinitionFormValues): EmailDeliveryDefinitionUpdateInput => ({
  bezeichnung: formValues.bezeichnung,
  subject: {
    type: TextbausteinType.EmailDeliveryDefinitionSubject,
    value: formValues.subject.value,
  },
  body: formValues.body.value
    ? {
        type: TextbausteinType.EmailDeliveryDefinitionBody,
        value: formValues.body.value,
      }
    : undefined,
});

export const emailDeliveryDefinitionFormFields: FormFields<EmailDeliveryDefinitionFormValues> = {
  bezeichnung: 'bezeichnung',
  type: 'type',
  subject: 'subject',
  body: 'body',
};
