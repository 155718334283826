import { FC } from 'react';
import { useFirmaChangeHistoryListQuery } from '../gql/FirmaQueries.types';
import { HistoryType } from '../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../History/HistoryListingTable';

type Props = {
  firmaId: string;
};

const FirmaChangeHistoryListingTable: FC<Props> = ({ firmaId }) => {
  const { data, loading } = useFirmaChangeHistoryListQuery({ variables: { firmaId } });
  const historyList = data?.getFirmaChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="firma" />;
};

export default FirmaChangeHistoryListingTable;
