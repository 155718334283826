import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { ObjektAbrechnung } from '../../../objektAbrechnung-types';
import OAAbrechnungskreis from './OAAbrechnungskreis';

const OAAbrechnungskreisBlock: FC<{ data: ObjektAbrechnung }> = ({ data }) => (
  <View style={[pdfStyles.column]}>
    {data.abrechnungskreiseBlock.abrechnungskreisList.map((abrechKreis, index) => (
      <OAAbrechnungskreis abrechKreis={abrechKreis} key={index} />
    ))}
  </View>
);

export default OAAbrechnungskreisBlock;
