import React, { FC } from 'react';
import { TopAbrKreisAufwand } from '../../../../../../types';
import TableWithAlignedColsBody from '../../../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsBody';
import aufwandAufteilungTableColumns from './aufwandAufteilungTableColumns';
import aufwandAufteilungTableExpandedRow from './aufwandAufteilungTableExpandedRow';

type Props = {
  aufwandAufteilung: TopAbrKreisAufwand;
};

const AufwandAufteilungTable: FC<Props> = ({ aufwandAufteilung }) => {
  return (
    <TableWithAlignedColsBody<TopAbrKreisAufwand>
      dataSource={aufwandAufteilung}
      columns={aufwandAufteilungTableColumns()}
      expandedRow={() => aufwandAufteilungTableExpandedRow}
      positionOfButton={2}
    />
  );
};

export default AufwandAufteilungTable;
