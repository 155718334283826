import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormikConfig, FormikProps } from 'formik';
import { useToggle } from '../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import TicketForm from './Form/TicketForm';
import { TicketFormValues } from './Form/ticketFormMapper';
import { ticketListingFiltersFormFields, TicketListingFiltersFormValues } from './Listing/ticketListingFormMapper';
import { TicketAndParentId } from './ticketUriPaths';

type Props = {
  refetch?: () => void;
  allowCreate?: boolean;
  loadingCreate?: boolean;
  onFormSubmitCreate?: (...args: Parameters<FormikConfig<TicketFormValues>['onSubmit']>) => Promise<TicketAndParentId | undefined>;
  formikProps: FormikProps<TicketListingFiltersFormValues>;
};

const TicketTemplateActionButtonsAndDrawer: FC<Props> = ({ allowCreate, refetch, loadingCreate, onFormSubmitCreate, formikProps }) => {
  const [isCollapsed, toggleIsCollapsed] = useToggle();

  const showArchived = formikProps.values[ticketListingFiltersFormFields.showArchived];
  const searchInSubTickets = formikProps.values[ticketListingFiltersFormFields.searchInSubTickets];

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button
          size="small"
          onClick={() => {
            formikProps.setFieldValue(ticketListingFiltersFormFields.showArchived, !showArchived);
            formikProps.submitForm();
          }}
        >
          Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}
        </Button>
        <Button
          size="small"
          onClick={() => {
            formikProps.setFieldValue(ticketListingFiltersFormFields.searchInSubTickets, !searchInSubTickets);
            formikProps.submitForm();
          }}
        >
          Unteraufgaben {searchInSubTickets ? 'ausblenden' : 'anzeigen'}
        </Button>
        {allowCreate && (
          <Button size="small" icon={<PlusOutlined />} type="primary" onClick={toggleIsCollapsed}>
            Aufgabe anlegen
          </Button>
        )}
      </ButtonsAligned>
      {allowCreate && (
        <Drawer title="Aufgabe anlegen" width={720} onClose={toggleIsCollapsed} open={isCollapsed} destroyOnClose>
          <TicketForm
            loadingCreate={loadingCreate}
            onFormSubmitCreate={onFormSubmitCreate}
            onSuccess={() => {
              toggleIsCollapsed(false);
              refetch?.();
            }}
            onCancel={toggleIsCollapsed}
          />
        </Drawer>
      )}
    </>
  );
};

export default TicketTemplateActionButtonsAndDrawer;
