import React, { FC } from 'react';
import { Button, Col } from 'antd';
import { Box } from 'rebass';
import { useDoNotificationActionMutation } from '../gql/NotificationMutation.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { Notification, NotificationAction, NotificationType, NotificationTypeOperation, PubSubNotification } from '../../../types';
import { EasyNotificationAction } from '../NotificationArea/notificationTypes';

type Props = {
  notification: Notification | PubSubNotification;
};

const TaskButtons: FC<Props> = ({ notification }) => {
  return (
    <Box>
      <Col span={24}>
        {mapActions(notification.actionList).flatMap((action, index) => (
          <ActionButton key={index} action={action} notificationType={notification.notificationType} />
        ))}
      </Col>
    </Box>
  );
};

const mapActions = (actionList: Array<NotificationAction>) =>
  actionList.map((action) => {
    let operationText;
    switch (action.typeOperation) {
      case NotificationTypeOperation.Restart:
        operationText = 'Wiederaufnehmen';
        break;
      default:
        throw new Error('Found unmatched new Operationtype in Notification!');
    }
    return {
      ...action,
      operationText,
    };
  });

const ActionButton = (props: { action: EasyNotificationAction; notificationType: NotificationType }) => {
  const [runDoAction, { loading }] = useDoNotificationActionMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Button
      block
      loading={loading}
      onClick={() => {
        runDoAction({
          variables: {
            link: props.action.link,
            method: props.action.method,
            body: props.action.body,
            notificationType: props.notificationType,
          },
        });
      }}
      style={{ marginTop: '8px', marginBottom: '2px', width: '100%' }}
      size="small"
    >
      {props.action.operationText}
    </Button>
  );
};

export default TaskButtons;
