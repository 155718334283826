import React, { FC } from 'react';
import { Empty } from 'antd';
import rechnungsAusstellerMahnDefTableColumns from './rechnungsAusstellerMahnDefTableColumns';
import TableWithColSelector from '../../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { RechnungsAusstellerFieldsFragment } from '../../gql/RechnungsAusstellerFragments.types';
import { MahndefinitionFieldsFragment } from '../../../../../Mahndefinition/gql/MahndefinitionFragments.types';

type Props = {
  rechnungsAussteller: RechnungsAusstellerFieldsFragment;
  rechtstraegerId: string;
  onSuccess: () => void;
};

const RechnungsAusstellerMahnDefTable: FC<Props> = ({ rechnungsAussteller, rechtstraegerId, onSuccess }) => {
  const mahndefinitionList = rechnungsAussteller?.mahndefinition ? [rechnungsAussteller.mahndefinition] : [];

  return (
    <TableWithColSelector<MahndefinitionFieldsFragment>
      locale={{
        emptyText: <Empty description={<span>Keine Mahndefinitionen zugewiesen</span>} />,
      }}
      pagination={false}
      dataSource={mahndefinitionList}
      columns={rechnungsAusstellerMahnDefTableColumns(rechtstraegerId, onSuccess)}
      rowKey={(record) => record.createTs}
      filterIdentifier="rechnungsaussteller-mahndefinition"
    />
  );
};

export default RechnungsAusstellerMahnDefTable;
