import { processResponse, returnData } from '../../helpers/APIUtils';

export const callLogin = async (values: any[]) => {
  const email = values[0];
  const password = values[1];
  const onCompleted = values[2];
  const config: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      username: email,
      password,
    }),
  };
  return fetch('/api/login', config).then(processResponse).then(returnData).finally(onCompleted);
};

export const callLogout = async (): Promise<Response> => {
  const config: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    credentials: 'same-origin',
  };
  return fetch('/api/logout', config);
};
