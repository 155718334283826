import React from 'react';
import { get } from 'lodash';
import { FieldArray } from 'formik';
import FieldArrayInputRows from './FieldArrayInputRows';
import { FieldArrayInputContentProps } from './FieldArrayInputProps';

export function FieldArrayInputContent<T>({
  formikValues,
  formikErrors,
  arrayName,
  arrayObjectAttribute,
  newFieldValue,
  validateOnChange = true,
  fieldValidation,
  label,
  placeholder,
}: FieldArrayInputContentProps<T>) {
  const arrayForInput = get(formikValues, arrayName, []) as any[];
  const arrayValidationError = get(formikErrors, arrayName);

  return (
    <FieldArray
      name={arrayName}
      validateOnChange={validateOnChange}
      render={(arrayHelpers) => (
        <FieldArrayInputRows
          label={label}
          placeholder={placeholder}
          arrayForInput={arrayForInput}
          arrayHelpers={arrayHelpers}
          newFieldValue={newFieldValue}
          arrayName={arrayName}
          arrayObjectAttribute={arrayObjectAttribute}
          fieldValidation={fieldValidation}
          arrayValidationError={arrayValidationError}
        />
      )}
    />
  );
}

export default FieldArrayInputContent;
