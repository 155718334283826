import React, { FC } from 'react';
import { Button, Space } from 'antd';
import PersonFormPart from '../../Person/PersonFormPart';
import ContactListFormPart from '../../Contact/ContactListFormPart';

type Props = {
  kundenSystemId?: string;
  fieldNamePrefix: string;
  isVisible: boolean;
  saveContactPerson: () => void;
  resetContactPerson: () => void;
};

const ContactPersonFormAddEditItem: FC<Props> = ({
  kundenSystemId,
  isVisible = false,
  saveContactPerson,
  fieldNamePrefix = '',
  resetContactPerson,
}) => {
  return isVisible ? (
    <Space direction="vertical">
      <PersonFormPart kundenSystemId={kundenSystemId} fieldNamePrefix={fieldNamePrefix} />
      <ContactListFormPart fieldNamePrefix={fieldNamePrefix} />
      <Button style={{ marginRight: 8 }} type="primary" htmlType="button" onClick={saveContactPerson}>
        Fertig
      </Button>
      <Button style={{ float: 'right' }} type="default" htmlType="button" onClick={resetContactPerson}>
        Abbrechen
      </Button>
    </Space>
  ) : null;
};

export default ContactPersonFormAddEditItem;
