import React, { FC } from 'react';
import { useField } from 'formik';
import { Typography } from 'antd';
import { mapTextbausteinToFormValues, TextbausteinFormValues } from '../../../components/Template/PDFTemplates/templateMapper';
import TextbausteinFormItem from '../../../components/Template/PDFTemplates/TextbausteinList/TextbausteinListElements/TextbausteinFormItem';
import FieldHelpIconWithTooltip from '../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';
import { StyledWizardSpaceForTextArea } from '../../shared/styled/WizardSpace.style';

type Props = {
  label: string;
  name: string;
  id?: string;
  disabled?: boolean;
  direction?: 'vertical' | 'horizontal';
  fieldHelp?: string | null;
};

const EmailDeliveryTextbausteinFormItem: FC<Props> = ({ name, id, label, disabled, direction = 'horizontal', fieldHelp }) => {
  const [{ value: textbaustein }, , { setValue: setTextbausteinValue, setTouched: setTextbausteinTouched }] = useField<TextbausteinFormValues>(name);

  return (
    <StyledWizardSpaceForTextArea align="start" direction={direction} size={0}>
      {direction === 'horizontal' && (
        <Typography.Text>
          {label}
          <FieldHelpIconWithTooltip fieldHelp={fieldHelp} />
        </Typography.Text>
      )}
      <TextbausteinFormItem
        label={direction === 'vertical' ? label : undefined}
        id={id}
        disabled={disabled}
        name={name}
        value={textbaustein.value}
        onChange={(newValue) => {
          const updatedValue = mapTextbausteinToFormValues({
            ...textbaustein,
            value: newValue?.trim(),
          });
          setTextbausteinTouched(updatedValue.value !== textbaustein.value);
          setTextbausteinValue(updatedValue);
        }}
        onBlur={() => {
          setTextbausteinTouched(true);
        }}
        platzhalterList={textbaustein.platzhalterList}
        fieldHelp={fieldHelp}
      />
    </StyledWizardSpaceForTextArea>
  );
};

export default EmailDeliveryTextbausteinFormItem;
