import React, { FC } from 'react';
import { Space, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { GenerierlaufAsyncStep } from '../../../types';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { isNil } from '../../../helpers/assertionHelper';

type Props = {
  currentStep?: number | null;
  stepCount?: number | null;
  stepList?: GenerierlaufAsyncStep[];
};

const GenerierlaufEntryStep: FC<Props> = ({ currentStep, stepCount, stepList }) => {
  if ((isNil(currentStep) && isNil(stepCount)) || (stepCount && Number(stepCount) === 1)) return <TextForEmptyValue textToShow="minus" />;

  if (stepList && stepList.length > 0) {
    return (
      <Space>
        <Typography.Text>{`${currentStep}/${stepCount}`}</Typography.Text>
        <Tooltip
          placement="top"
          styles={{ root: { maxWidth: '280px' } }}
          title={stepList.map((step, index) => (
            <div>{`${index + 1}. Schritt - ${step.status.text}: ${step.designation}`}</div>
          ))}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
    );
  }

  return <Typography.Text>{`${currentStep}/${stepCount}`}</Typography.Text>;
};

export default GenerierlaufEntryStep;
