import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RechtsTraegerVertragspartnerQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechtsTraegerVertragspartnerQuery = {
  getVertragspartner: {
    data: { mahnsperre: boolean; mahnsperreKommentar?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnsperreChangeHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type MahnsperreChangeHistoryListQuery = {
  getMahnsperreChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const RechtsTraegerVertragspartnerDocument = gql`
  query RechtsTraegerVertragspartner($rechtstraegerId: ID!) {
    getVertragspartner(rechtstraegerId: $rechtstraegerId) {
      data {
        mahnsperre
        mahnsperreKommentar
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRechtsTraegerVertragspartnerQuery(
  baseOptions: Apollo.QueryHookOptions<RechtsTraegerVertragspartnerQuery, RechtsTraegerVertragspartnerQueryVariables> &
    ({ variables: RechtsTraegerVertragspartnerQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtsTraegerVertragspartnerQuery, RechtsTraegerVertragspartnerQueryVariables>(
    RechtsTraegerVertragspartnerDocument,
    options
  );
}
export function useRechtsTraegerVertragspartnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtsTraegerVertragspartnerQuery, RechtsTraegerVertragspartnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtsTraegerVertragspartnerQuery, RechtsTraegerVertragspartnerQueryVariables>(
    RechtsTraegerVertragspartnerDocument,
    options
  );
}
export function useRechtsTraegerVertragspartnerSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtsTraegerVertragspartnerQuery, RechtsTraegerVertragspartnerQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtsTraegerVertragspartnerQuery, RechtsTraegerVertragspartnerQueryVariables>(
    RechtsTraegerVertragspartnerDocument,
    options
  );
}
export type RechtsTraegerVertragspartnerQueryHookResult = ReturnType<typeof useRechtsTraegerVertragspartnerQuery>;
export type RechtsTraegerVertragspartnerLazyQueryHookResult = ReturnType<typeof useRechtsTraegerVertragspartnerLazyQuery>;
export type RechtsTraegerVertragspartnerSuspenseQueryHookResult = ReturnType<typeof useRechtsTraegerVertragspartnerSuspenseQuery>;
export type RechtsTraegerVertragspartnerQueryResult = Apollo.QueryResult<
  RechtsTraegerVertragspartnerQuery,
  RechtsTraegerVertragspartnerQueryVariables
>;
export const MahnsperreChangeHistoryListDocument = gql`
  query MahnsperreChangeHistoryList($rechtstraegerId: ID!) {
    getMahnsperreChangeHistoryList(rechtstraegerId: $rechtstraegerId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useMahnsperreChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnsperreChangeHistoryListQuery, MahnsperreChangeHistoryListQueryVariables> &
    ({ variables: MahnsperreChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnsperreChangeHistoryListQuery, MahnsperreChangeHistoryListQueryVariables>(MahnsperreChangeHistoryListDocument, options);
}
export function useMahnsperreChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnsperreChangeHistoryListQuery, MahnsperreChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnsperreChangeHistoryListQuery, MahnsperreChangeHistoryListQueryVariables>(
    MahnsperreChangeHistoryListDocument,
    options
  );
}
export function useMahnsperreChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnsperreChangeHistoryListQuery, MahnsperreChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnsperreChangeHistoryListQuery, MahnsperreChangeHistoryListQueryVariables>(
    MahnsperreChangeHistoryListDocument,
    options
  );
}
export type MahnsperreChangeHistoryListQueryHookResult = ReturnType<typeof useMahnsperreChangeHistoryListQuery>;
export type MahnsperreChangeHistoryListLazyQueryHookResult = ReturnType<typeof useMahnsperreChangeHistoryListLazyQuery>;
export type MahnsperreChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useMahnsperreChangeHistoryListSuspenseQuery>;
export type MahnsperreChangeHistoryListQueryResult = Apollo.QueryResult<MahnsperreChangeHistoryListQuery, MahnsperreChangeHistoryListQueryVariables>;
