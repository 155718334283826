import React, { FC, useState } from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { ApartmentOutlined, CalculatorOutlined, DownOutlined, GoldOutlined, HomeOutlined, TeamOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import HeOrObjektOrSubAbrechnungCreateModal from './HeOrObjektOrSubAbrechnungForm/HeOrObjektOrSubAbrechnungCreateModal';
import { AbrechnungType } from '../../../types';
import TopAbrechnungCreateModal from './TopAbrechnungForm/TopAbrechnungCreateModal';

type Props = {
  onCreateSuccess: () => Promise<any>;
};
const AbrechnungCreateBtnAndModal: FC<Props> = ({ onCreateSuccess }) => {
  const [isOpenCreateHeObjektSubAbrechnungModal, toggleCreateHeObjektSubAbrechnungModal] = useToggle();
  const [isOpenCreateTopAbrechnungModal, toggleCreateTopAbrechnungModal] = useToggle();
  const [abrechnungType, setAbrechnungType] = useState<AbrechnungType>(AbrechnungType.WeAbrechnung);

  const onHeOrObjektOrSubAbrMenuItemClick = (type: AbrechnungType) => {
    setAbrechnungType(type);
    toggleCreateHeObjektSubAbrechnungModal();
  };

  const onTopAbrMenuItemClick = (type: AbrechnungType) => {
    setAbrechnungType(type);
    toggleCreateTopAbrechnungModal();
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'WE-Abrechnung erstellen',
      onClick: () => onHeOrObjektOrSubAbrMenuItemClick(AbrechnungType.WeAbrechnung),
      icon: <TeamOutlined />,
    },
    {
      key: '2',
      label: 'BK-Abrechnung erstellen',
      onClick: () => onHeOrObjektOrSubAbrMenuItemClick(AbrechnungType.BkAbrechnung),
      icon: <CalculatorOutlined />,
    },
    {
      key: '3',
      label: 'HE-Abrechnung erstellen',
      onClick: () => onHeOrObjektOrSubAbrMenuItemClick(AbrechnungType.HeAbrechnung),
      icon: <HomeOutlined />,
    },
    {
      key: '4',
      label: 'SUB-Abrechnung erstellen',
      onClick: () => onHeOrObjektOrSubAbrMenuItemClick(AbrechnungType.SubAbrechnung),
      icon: <GoldOutlined />,
    },
    {
      key: '5',
      label: 'WE-Topabrechnungen erstellen',
      onClick: () => onTopAbrMenuItemClick(AbrechnungType.WeTopAbrechnung),
      icon: <ApartmentOutlined />,
    },
    {
      key: '6',
      label: 'BK-Topabrechnungen erstellen',
      onClick: () => onTopAbrMenuItemClick(AbrechnungType.BkTopAbrechnung),
      icon: <ApartmentOutlined />,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button type="primary">
          Abrechnungen erstellen
          <DownOutlined />
        </Button>
      </Dropdown>
      <HeOrObjektOrSubAbrechnungCreateModal
        type={abrechnungType}
        onCreate={() => {
          toggleCreateHeObjektSubAbrechnungModal();
          onCreateSuccess();
        }}
        onCancel={toggleCreateHeObjektSubAbrechnungModal}
        visible={isOpenCreateHeObjektSubAbrechnungModal}
      />
      <TopAbrechnungCreateModal
        type={abrechnungType}
        onCreate={() => {
          toggleCreateTopAbrechnungModal();
          onCreateSuccess();
        }}
        onCancel={toggleCreateTopAbrechnungModal}
        visible={isOpenCreateTopAbrechnungModal}
      />
    </>
  );
};

export default AbrechnungCreateBtnAndModal;
