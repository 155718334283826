import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { TextbausteinFormValues } from '../../../../../../components/Template/PDFTemplates/templateMapper';
import { TopAbrDefGeneralSettingsFormValues } from './TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import TemplateTopAbrechnung from '../TemplateTopAbrechnung';
import TopAbrDefGeneralSettings from './TopAbrDefGeneralSettingsForm/TopAbrDefGeneralSettings';
import { AbrechnungsdefinitionType } from '../../../../../../types';
import { BkOrWeAbrDefTopOutputOptionsFieldHelpNames } from '../../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';

type Props = {
  abrDefType: AbrechnungsdefinitionType;
  topAbrTemplateValues: TextbausteinFormValues[];
  onTopAbrDefTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  topAbrDefGeneralSettingsValues: TopAbrDefGeneralSettingsFormValues;
  onTopAbrDefGeneralSettingsChange: (values: TopAbrDefGeneralSettingsFormValues) => void;
  setIsFormValid: (isFormValid: boolean) => void;
  fieldHelpNames?: BkOrWeAbrDefTopOutputOptionsFieldHelpNames;
};

const BkOrWeTopAbrDefTemplate: FC<Props> = ({
  abrDefType,
  topAbrTemplateValues,
  onTopAbrDefTemplateChange,
  topAbrDefGeneralSettingsValues,
  onTopAbrDefGeneralSettingsChange,
  setIsFormValid,
  fieldHelpNames,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: (
        <TopAbrDefGeneralSettings
          abrDefType={abrDefType}
          values={topAbrDefGeneralSettingsValues}
          onSubmit={onTopAbrDefGeneralSettingsChange}
          setIsFormValid={setIsFormValid}
          fieldHelpNames={fieldHelpNames}
        />
      ),
    },
    {
      key: '2',
      label: 'Texte',
      children: (
        <TemplateTopAbrechnung
          values={topAbrTemplateValues}
          onTemplateChange={onTopAbrDefTemplateChange}
          topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
        />
      ),
    },
  ];

  return <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />;
};

export default BkOrWeTopAbrDefTemplate;
