import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { EbicsUserIbanFieldsFragmentDoc } from './EbicsUserIbanFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveEbicsUserIbanListQueryVariables = Types.Exact<{
  ibanList: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type ActiveEbicsUserIbanListQuery = {
  getActiveEbicsUserIbanList: {
    data: Array<{
      accountHolder: string;
      accountNumber?: string | null;
      activationTs?: string | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      ebicsUserIbanId: string;
      iban: string;
      lastBankStatementFetchDate?: string | null;
      mitarbeiterName: string;
      retrieveBankStatement: boolean;
      creditTransferPermission?: { text: string; value: Types.EbicsIbanPermission } | null;
      directDebitPermission?: { text: string; value: Types.EbicsIbanPermission } | null;
      status: { text: string; value: Types.EbicsUserIbanStatus };
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type EbicsUserIbanListQueryVariables = Types.Exact<{
  ebicsUserId: Types.Scalars['ID']['input'];
}>;

export type EbicsUserIbanListQuery = {
  getEbicsUserIbanList: {
    data: Array<{
      accountHolder: string;
      accountNumber?: string | null;
      activationTs?: string | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      ebicsUserIbanId: string;
      iban: string;
      lastBankStatementFetchDate?: string | null;
      mitarbeiterName: string;
      retrieveBankStatement: boolean;
      creditTransferPermission?: { text: string; value: Types.EbicsIbanPermission } | null;
      directDebitPermission?: { text: string; value: Types.EbicsIbanPermission } | null;
      status: { text: string; value: Types.EbicsUserIbanStatus };
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenEbicsUserIbanListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FirmendatenEbicsUserIbanListQuery = {
  getFirmendatenEbicsUserIbanList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type EbicsUserIbanChangeHistoryQueryVariables = Types.Exact<{
  ebicsUserId: Types.Scalars['ID']['input'];
  ebicsUserIbanId: Types.Scalars['ID']['input'];
}>;

export type EbicsUserIbanChangeHistoryQuery = {
  getEbicsUserIbanChangeHistory: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const ActiveEbicsUserIbanListDocument = gql`
  query ActiveEbicsUserIbanList($ibanList: [String!]!) {
    getActiveEbicsUserIbanList(ibanList: $ibanList) {
      data {
        ...EbicsUserIbanFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${EbicsUserIbanFieldsFragmentDoc}
`;
export function useActiveEbicsUserIbanListQuery(
  baseOptions: Apollo.QueryHookOptions<ActiveEbicsUserIbanListQuery, ActiveEbicsUserIbanListQueryVariables> &
    ({ variables: ActiveEbicsUserIbanListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveEbicsUserIbanListQuery, ActiveEbicsUserIbanListQueryVariables>(ActiveEbicsUserIbanListDocument, options);
}
export function useActiveEbicsUserIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveEbicsUserIbanListQuery, ActiveEbicsUserIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveEbicsUserIbanListQuery, ActiveEbicsUserIbanListQueryVariables>(ActiveEbicsUserIbanListDocument, options);
}
export function useActiveEbicsUserIbanListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ActiveEbicsUserIbanListQuery, ActiveEbicsUserIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ActiveEbicsUserIbanListQuery, ActiveEbicsUserIbanListQueryVariables>(ActiveEbicsUserIbanListDocument, options);
}
export type ActiveEbicsUserIbanListQueryHookResult = ReturnType<typeof useActiveEbicsUserIbanListQuery>;
export type ActiveEbicsUserIbanListLazyQueryHookResult = ReturnType<typeof useActiveEbicsUserIbanListLazyQuery>;
export type ActiveEbicsUserIbanListSuspenseQueryHookResult = ReturnType<typeof useActiveEbicsUserIbanListSuspenseQuery>;
export type ActiveEbicsUserIbanListQueryResult = Apollo.QueryResult<ActiveEbicsUserIbanListQuery, ActiveEbicsUserIbanListQueryVariables>;
export const EbicsUserIbanListDocument = gql`
  query EbicsUserIbanList($ebicsUserId: ID!) {
    getEbicsUserIbanList(ebicsUserId: $ebicsUserId) {
      data {
        ...EbicsUserIbanFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${EbicsUserIbanFieldsFragmentDoc}
`;
export function useEbicsUserIbanListQuery(
  baseOptions: Apollo.QueryHookOptions<EbicsUserIbanListQuery, EbicsUserIbanListQueryVariables> &
    ({ variables: EbicsUserIbanListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EbicsUserIbanListQuery, EbicsUserIbanListQueryVariables>(EbicsUserIbanListDocument, options);
}
export function useEbicsUserIbanListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EbicsUserIbanListQuery, EbicsUserIbanListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EbicsUserIbanListQuery, EbicsUserIbanListQueryVariables>(EbicsUserIbanListDocument, options);
}
export function useEbicsUserIbanListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EbicsUserIbanListQuery, EbicsUserIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EbicsUserIbanListQuery, EbicsUserIbanListQueryVariables>(EbicsUserIbanListDocument, options);
}
export type EbicsUserIbanListQueryHookResult = ReturnType<typeof useEbicsUserIbanListQuery>;
export type EbicsUserIbanListLazyQueryHookResult = ReturnType<typeof useEbicsUserIbanListLazyQuery>;
export type EbicsUserIbanListSuspenseQueryHookResult = ReturnType<typeof useEbicsUserIbanListSuspenseQuery>;
export type EbicsUserIbanListQueryResult = Apollo.QueryResult<EbicsUserIbanListQuery, EbicsUserIbanListQueryVariables>;
export const FirmendatenEbicsUserIbanListDocument = gql`
  query FirmendatenEbicsUserIbanList {
    getFirmendatenEbicsUserIbanList {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenEbicsUserIbanListQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenEbicsUserIbanListQuery, FirmendatenEbicsUserIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenEbicsUserIbanListQuery, FirmendatenEbicsUserIbanListQueryVariables>(
    FirmendatenEbicsUserIbanListDocument,
    options
  );
}
export function useFirmendatenEbicsUserIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenEbicsUserIbanListQuery, FirmendatenEbicsUserIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenEbicsUserIbanListQuery, FirmendatenEbicsUserIbanListQueryVariables>(
    FirmendatenEbicsUserIbanListDocument,
    options
  );
}
export function useFirmendatenEbicsUserIbanListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenEbicsUserIbanListQuery, FirmendatenEbicsUserIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenEbicsUserIbanListQuery, FirmendatenEbicsUserIbanListQueryVariables>(
    FirmendatenEbicsUserIbanListDocument,
    options
  );
}
export type FirmendatenEbicsUserIbanListQueryHookResult = ReturnType<typeof useFirmendatenEbicsUserIbanListQuery>;
export type FirmendatenEbicsUserIbanListLazyQueryHookResult = ReturnType<typeof useFirmendatenEbicsUserIbanListLazyQuery>;
export type FirmendatenEbicsUserIbanListSuspenseQueryHookResult = ReturnType<typeof useFirmendatenEbicsUserIbanListSuspenseQuery>;
export type FirmendatenEbicsUserIbanListQueryResult = Apollo.QueryResult<
  FirmendatenEbicsUserIbanListQuery,
  FirmendatenEbicsUserIbanListQueryVariables
>;
export const EbicsUserIbanChangeHistoryDocument = gql`
  query EbicsUserIbanChangeHistory($ebicsUserId: ID!, $ebicsUserIbanId: ID!) {
    getEbicsUserIbanChangeHistory(ebicsUserId: $ebicsUserId, ebicsUserIbanId: $ebicsUserIbanId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useEbicsUserIbanChangeHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<EbicsUserIbanChangeHistoryQuery, EbicsUserIbanChangeHistoryQueryVariables> &
    ({ variables: EbicsUserIbanChangeHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EbicsUserIbanChangeHistoryQuery, EbicsUserIbanChangeHistoryQueryVariables>(EbicsUserIbanChangeHistoryDocument, options);
}
export function useEbicsUserIbanChangeHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EbicsUserIbanChangeHistoryQuery, EbicsUserIbanChangeHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EbicsUserIbanChangeHistoryQuery, EbicsUserIbanChangeHistoryQueryVariables>(EbicsUserIbanChangeHistoryDocument, options);
}
export function useEbicsUserIbanChangeHistorySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EbicsUserIbanChangeHistoryQuery, EbicsUserIbanChangeHistoryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EbicsUserIbanChangeHistoryQuery, EbicsUserIbanChangeHistoryQueryVariables>(
    EbicsUserIbanChangeHistoryDocument,
    options
  );
}
export type EbicsUserIbanChangeHistoryQueryHookResult = ReturnType<typeof useEbicsUserIbanChangeHistoryQuery>;
export type EbicsUserIbanChangeHistoryLazyQueryHookResult = ReturnType<typeof useEbicsUserIbanChangeHistoryLazyQuery>;
export type EbicsUserIbanChangeHistorySuspenseQueryHookResult = ReturnType<typeof useEbicsUserIbanChangeHistorySuspenseQuery>;
export type EbicsUserIbanChangeHistoryQueryResult = Apollo.QueryResult<EbicsUserIbanChangeHistoryQuery, EbicsUserIbanChangeHistoryQueryVariables>;
