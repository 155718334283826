import React, { FC } from 'react';
import { Empty } from 'antd';
import { FormikProps } from 'formik';
import { useNotificationListQuery } from '../../gql/NotificationQuery.types';
import notificationTableColumns from './notificationTableColumns';
import { useAuth } from '../../../../shared/Auth/authContext';
import { useGetNotificationSubscription } from '../../gql/NotificationSubscription.types';
import { useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { NotificationListingFiltersFormValues } from '../Filters/notificationListingFiltersFormMapper';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { Notification } from '../../../../types';

type NotificationTableProps = {
  formikProps: FormikProps<NotificationListingFiltersFormValues>;
};

const NotificationTable: FC<NotificationTableProps> = ({ formikProps }) => {
  const { user, mitarbeiter } = useAuth();
  const username = user?.username;
  const mitarbeiterId = mitarbeiter?.mitarbeiterId;

  const createTsVonInklusive = formikProps.values.createTsVonInklusive
    ? dayjsCustom(formikProps.values.createTsVonInklusive).format('YYYY-MM-DD')
    : undefined;

  const createTsBisInklusive = formikProps.values.createTsBisInklusive
    ? dayjsCustom(formikProps.values.createTsBisInklusive).format('YYYY-MM-DD')
    : undefined;

  const type = formikProps.values.type;

  const { data, pagination, refetch, handleTableSorting } = useQueryWithPagingAndSorting(
    useNotificationListQuery,
    {
      variables: {
        type,
        createTsVonInklusive,
        createTsBisInklusive,
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
    }
  );

  const notificationList = data?.getNotificationList.data.contentList;
  const loading = !notificationList;

  useGetNotificationSubscription({
    onData: (options) => {
      const maybeNotification = options.data.data?.getNotification;

      if (maybeNotification) {
        refetch();
      }
    },
    variables: {
      userId: username,
      mitarbeiterId,
    },
  });

  return (
    <TableWithColSelector<Notification>
      locale={{
        emptyText: <Empty description={<span>Keine Notifications vorhanden</span>} />,
      }}
      loading={loading}
      pagination={pagination}
      onChange={handleTableSorting}
      dataSource={notificationList}
      columns={notificationTableColumns({ width: '600px' })}
      rowKey={(record) => record.notificationId}
      size="small"
      filterIdentifier="notification"
    />
  );
};

export default NotificationTable;
