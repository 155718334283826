import React, { FC } from 'react';
import { Avatar, Button, Space, Tooltip, Typography } from 'antd';
import { Comment } from '@ant-design/compatible';
import { DownloadOutlined, EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import { CallLog } from '../../../types';
import { capitalizeString } from '../../../helpers/stringHelper';
import { isCallLogTypeOutgoing } from './callLogHelper';
import { mitarbeiterInitials } from '../../../components/Card/MitarbeiterTooltip';

type Props = {
  rechtstraegerId: string;
  callLog: CallLog;
  onEdit: (callLog: CallLog) => void;
};

const CallLogComment: FC<Props> = ({ callLog, onEdit }) => {
  const actions = [
    <Tooltip title={`Kommentar ${callLog.kommentar ? 'bearbeiten' : 'hinzufügen'}`}>
      <Button type="link" icon={callLog.kommentar ? <EditOutlined /> : <PlusOutlined />} onClick={() => onEdit(callLog)} />
    </Tooltip>,
  ];

  const mitarbeiter = callLog.callee;

  return (
    <Comment
      author={`${capitalizeString(callLog.type.text)} ${callLog.phoneNumber} - ${mitarbeiter.firstname} ${mitarbeiter.lastname}`}
      avatar={
        <Space>
          {isCallLogTypeOutgoing(callLog.type.value) ? (
            <UploadOutlined style={{ color: 'green', fontSize: '14px' }} />
          ) : (
            <DownloadOutlined style={{ color: 'red', fontSize: '14px' }} />
          )}
          <Avatar style={{ backgroundColor: '#f77e59', verticalAlign: 'middle' }} size="small">
            {mitarbeiterInitials(undefined, mitarbeiter)}
          </Avatar>
        </Space>
      }
      content={<Typography.Text style={{ whiteSpace: 'pre-line' }}>{callLog.kommentar}</Typography.Text>}
      datetime={<Tooltip title={<FormattedDateTime createdTs={callLog.callTs} />}>{dayjsCustom(callLog.callTs).fromNow()}</Tooltip>}
      actions={actions}
    />
  );
};

export default CallLogComment;
