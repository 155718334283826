import * as Types from '../../../../../../types';

import { gql } from '@apollo/client';
import {
  BeVertragVorschreibungspositionFieldsFragmentDoc,
  BeVertragVorschreibungsPositionWertFieldsFragmentDoc,
} from './BeVertragVorschreibungspositionFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../../../History/gql/HistoryFragments.types';
import { UstKategorieFieldsFragmentDoc, UstKategorieEntryFieldsFragmentDoc } from '../../../../../Ust/UstKategorie/gql/UstKategorieFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeVertragVorschreibungspositionListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BeVertragVorschreibungspositionListQuery = {
  getBeVertragVorschreibungspositionList: {
    data: Array<{
      bezeichnung: string;
      brutto?: number | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      validFrom?: string | null;
      netto?: number | null;
      steuersatz: number;
      ust?: number | null;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      basis?: { text: string; value: Types.VorschreibungspositionBasis } | null;
      objektVorschreibungspositionStatus: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
      ustKategorie?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      ustKategorieEntry?: {
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
  };
};

export type BeVertragVorschreibungspositionWertListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type BeVertragVorschreibungspositionWertListQuery = {
  getBeVertragVorschreibungspositionWertList: {
    data: Array<{
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      brutto: number;
      current: boolean;
      validFrom: string;
      netto: number;
      steuersatz: number;
      ust: number;
      deletable: boolean;
      vertragVorschreibungspositionWertId: string;
      basis: { text: string; value: Types.VorschreibungspositionBasis };
      ustKategorie?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      ustKategorieEntry?: { ustKategorieEntryId?: string | null } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      changeReason?:
        | {
            __typename: 'VPosWertChangeReasonBudgeting';
            budgetingId: string;
            bestandseinheitId: string;
            objektId: string;
            vertragId: string;
            vorschreibungspositionId: string;
            text: string;
            type: Types.ChangeReason;
          }
        | {
            __typename: 'VPosWertChangeReasonIndexedValue';
            bestandseinheitId: string;
            indexationAgreementId: string;
            objektId: string;
            vertragId: string;
            vertragVposIndexedValueId: string;
            vorschreibungspositionId: string;
            text: string;
            type: Types.ChangeReason;
          }
        | { __typename: 'VPosWertChangeReasonUst'; text: string; type: Types.ChangeReason }
        | null;
    }>;
  };
};

export type BeVertragVorschreibungspositionWertChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  vorschreibungspositionWertId: Types.Scalars['ID']['input'];
}>;

export type BeVertragVorschreibungspositionWertChangeHistoryListQuery = {
  getBeVertragVorschreibungspositionWertChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type BeVertragVorschreibungspositionUstKategorieEntryQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  date: Types.Scalars['String']['input'];
}>;

export type BeVertragVorschreibungspositionUstKategorieEntryQuery = {
  getBeVertragVorschreibungspositionUstKategorieEntry: {
    data?: {
      ustKategorie: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      ustKategorieEntry: {
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
    } | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BeVertragVorschreibungspositionWertDeletionHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type BeVertragVorschreibungspositionWertDeletionHistoryListQuery = {
  getBeVertragVorschreibungspositionWertDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type BeVertragVposIndexationAgreementDeletionHistoryQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type BeVertragVposIndexationAgreementDeletionHistoryQuery = {
  getBeVertragVposIndexationAgreementDeletionHistory: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const BeVertragVorschreibungspositionListDocument = gql`
  query BeVertragVorschreibungspositionList($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $includeArchiviert: Boolean) {
    getBeVertragVorschreibungspositionList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      includeArchiviert: $includeArchiviert
    ) {
      data {
        ...BeVertragVorschreibungspositionFields
      }
    }
  }
  ${BeVertragVorschreibungspositionFieldsFragmentDoc}
`;
export function useBeVertragVorschreibungspositionListQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragVorschreibungspositionListQuery, BeVertragVorschreibungspositionListQueryVariables> &
    ({ variables: BeVertragVorschreibungspositionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragVorschreibungspositionListQuery, BeVertragVorschreibungspositionListQueryVariables>(
    BeVertragVorschreibungspositionListDocument,
    options
  );
}
export function useBeVertragVorschreibungspositionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeVertragVorschreibungspositionListQuery, BeVertragVorschreibungspositionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragVorschreibungspositionListQuery, BeVertragVorschreibungspositionListQueryVariables>(
    BeVertragVorschreibungspositionListDocument,
    options
  );
}
export function useBeVertragVorschreibungspositionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BeVertragVorschreibungspositionListQuery, BeVertragVorschreibungspositionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragVorschreibungspositionListQuery, BeVertragVorschreibungspositionListQueryVariables>(
    BeVertragVorschreibungspositionListDocument,
    options
  );
}
export type BeVertragVorschreibungspositionListQueryHookResult = ReturnType<typeof useBeVertragVorschreibungspositionListQuery>;
export type BeVertragVorschreibungspositionListLazyQueryHookResult = ReturnType<typeof useBeVertragVorschreibungspositionListLazyQuery>;
export type BeVertragVorschreibungspositionListSuspenseQueryHookResult = ReturnType<typeof useBeVertragVorschreibungspositionListSuspenseQuery>;
export type BeVertragVorschreibungspositionListQueryResult = Apollo.QueryResult<
  BeVertragVorschreibungspositionListQuery,
  BeVertragVorschreibungspositionListQueryVariables
>;
export const BeVertragVorschreibungspositionWertListDocument = gql`
  query BeVertragVorschreibungspositionWertList($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $vorschreibungspositionId: ID!) {
    getBeVertragVorschreibungspositionWertList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
    ) {
      data {
        ...BeVertragVorschreibungsPositionWertFields
      }
    }
  }
  ${BeVertragVorschreibungsPositionWertFieldsFragmentDoc}
`;
export function useBeVertragVorschreibungspositionWertListQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragVorschreibungspositionWertListQuery, BeVertragVorschreibungspositionWertListQueryVariables> &
    ({ variables: BeVertragVorschreibungspositionWertListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragVorschreibungspositionWertListQuery, BeVertragVorschreibungspositionWertListQueryVariables>(
    BeVertragVorschreibungspositionWertListDocument,
    options
  );
}
export function useBeVertragVorschreibungspositionWertListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeVertragVorschreibungspositionWertListQuery, BeVertragVorschreibungspositionWertListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragVorschreibungspositionWertListQuery, BeVertragVorschreibungspositionWertListQueryVariables>(
    BeVertragVorschreibungspositionWertListDocument,
    options
  );
}
export function useBeVertragVorschreibungspositionWertListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BeVertragVorschreibungspositionWertListQuery, BeVertragVorschreibungspositionWertListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragVorschreibungspositionWertListQuery, BeVertragVorschreibungspositionWertListQueryVariables>(
    BeVertragVorschreibungspositionWertListDocument,
    options
  );
}
export type BeVertragVorschreibungspositionWertListQueryHookResult = ReturnType<typeof useBeVertragVorschreibungspositionWertListQuery>;
export type BeVertragVorschreibungspositionWertListLazyQueryHookResult = ReturnType<typeof useBeVertragVorschreibungspositionWertListLazyQuery>;
export type BeVertragVorschreibungspositionWertListSuspenseQueryHookResult = ReturnType<
  typeof useBeVertragVorschreibungspositionWertListSuspenseQuery
>;
export type BeVertragVorschreibungspositionWertListQueryResult = Apollo.QueryResult<
  BeVertragVorschreibungspositionWertListQuery,
  BeVertragVorschreibungspositionWertListQueryVariables
>;
export const BeVertragVorschreibungspositionWertChangeHistoryListDocument = gql`
  query BeVertragVorschreibungspositionWertChangeHistoryList(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $vorschreibungspositionWertId: ID!
  ) {
    getBeVertragVorschreibungspositionWertChangeHistoryList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      vorschreibungspositionWertId: $vorschreibungspositionWertId
    ) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBeVertragVorschreibungspositionWertChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    BeVertragVorschreibungspositionWertChangeHistoryListQuery,
    BeVertragVorschreibungspositionWertChangeHistoryListQueryVariables
  > &
    ({ variables: BeVertragVorschreibungspositionWertChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BeVertragVorschreibungspositionWertChangeHistoryListQuery,
    BeVertragVorschreibungspositionWertChangeHistoryListQueryVariables
  >(BeVertragVorschreibungspositionWertChangeHistoryListDocument, options);
}
export function useBeVertragVorschreibungspositionWertChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BeVertragVorschreibungspositionWertChangeHistoryListQuery,
    BeVertragVorschreibungspositionWertChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BeVertragVorschreibungspositionWertChangeHistoryListQuery,
    BeVertragVorschreibungspositionWertChangeHistoryListQueryVariables
  >(BeVertragVorschreibungspositionWertChangeHistoryListDocument, options);
}
export function useBeVertragVorschreibungspositionWertChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BeVertragVorschreibungspositionWertChangeHistoryListQuery,
        BeVertragVorschreibungspositionWertChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BeVertragVorschreibungspositionWertChangeHistoryListQuery,
    BeVertragVorschreibungspositionWertChangeHistoryListQueryVariables
  >(BeVertragVorschreibungspositionWertChangeHistoryListDocument, options);
}
export type BeVertragVorschreibungspositionWertChangeHistoryListQueryHookResult = ReturnType<
  typeof useBeVertragVorschreibungspositionWertChangeHistoryListQuery
>;
export type BeVertragVorschreibungspositionWertChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useBeVertragVorschreibungspositionWertChangeHistoryListLazyQuery
>;
export type BeVertragVorschreibungspositionWertChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useBeVertragVorschreibungspositionWertChangeHistoryListSuspenseQuery
>;
export type BeVertragVorschreibungspositionWertChangeHistoryListQueryResult = Apollo.QueryResult<
  BeVertragVorschreibungspositionWertChangeHistoryListQuery,
  BeVertragVorschreibungspositionWertChangeHistoryListQueryVariables
>;
export const BeVertragVorschreibungspositionUstKategorieEntryDocument = gql`
  query BeVertragVorschreibungspositionUstKategorieEntry(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $date: String!
  ) {
    getBeVertragVorschreibungspositionUstKategorieEntry(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      date: $date
    ) {
      data {
        ustKategorie {
          ...UstKategorieFields
        }
        ustKategorieEntry {
          ...UstKategorieEntryFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieFieldsFragmentDoc}
  ${UstKategorieEntryFieldsFragmentDoc}
`;
export function useBeVertragVorschreibungspositionUstKategorieEntryQuery(
  baseOptions: Apollo.QueryHookOptions<
    BeVertragVorschreibungspositionUstKategorieEntryQuery,
    BeVertragVorschreibungspositionUstKategorieEntryQueryVariables
  > &
    ({ variables: BeVertragVorschreibungspositionUstKategorieEntryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragVorschreibungspositionUstKategorieEntryQuery, BeVertragVorschreibungspositionUstKategorieEntryQueryVariables>(
    BeVertragVorschreibungspositionUstKategorieEntryDocument,
    options
  );
}
export function useBeVertragVorschreibungspositionUstKategorieEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BeVertragVorschreibungspositionUstKategorieEntryQuery,
    BeVertragVorschreibungspositionUstKategorieEntryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragVorschreibungspositionUstKategorieEntryQuery, BeVertragVorschreibungspositionUstKategorieEntryQueryVariables>(
    BeVertragVorschreibungspositionUstKategorieEntryDocument,
    options
  );
}
export function useBeVertragVorschreibungspositionUstKategorieEntrySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BeVertragVorschreibungspositionUstKategorieEntryQuery,
        BeVertragVorschreibungspositionUstKategorieEntryQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BeVertragVorschreibungspositionUstKategorieEntryQuery,
    BeVertragVorschreibungspositionUstKategorieEntryQueryVariables
  >(BeVertragVorschreibungspositionUstKategorieEntryDocument, options);
}
export type BeVertragVorschreibungspositionUstKategorieEntryQueryHookResult = ReturnType<
  typeof useBeVertragVorschreibungspositionUstKategorieEntryQuery
>;
export type BeVertragVorschreibungspositionUstKategorieEntryLazyQueryHookResult = ReturnType<
  typeof useBeVertragVorschreibungspositionUstKategorieEntryLazyQuery
>;
export type BeVertragVorschreibungspositionUstKategorieEntrySuspenseQueryHookResult = ReturnType<
  typeof useBeVertragVorschreibungspositionUstKategorieEntrySuspenseQuery
>;
export type BeVertragVorschreibungspositionUstKategorieEntryQueryResult = Apollo.QueryResult<
  BeVertragVorschreibungspositionUstKategorieEntryQuery,
  BeVertragVorschreibungspositionUstKategorieEntryQueryVariables
>;
export const BeVertragVorschreibungspositionWertDeletionHistoryListDocument = gql`
  query BeVertragVorschreibungspositionWertDeletionHistoryList(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
  ) {
    getBeVertragVorschreibungspositionWertDeletionHistoryList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
    ) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBeVertragVorschreibungspositionWertDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    BeVertragVorschreibungspositionWertDeletionHistoryListQuery,
    BeVertragVorschreibungspositionWertDeletionHistoryListQueryVariables
  > &
    ({ variables: BeVertragVorschreibungspositionWertDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BeVertragVorschreibungspositionWertDeletionHistoryListQuery,
    BeVertragVorschreibungspositionWertDeletionHistoryListQueryVariables
  >(BeVertragVorschreibungspositionWertDeletionHistoryListDocument, options);
}
export function useBeVertragVorschreibungspositionWertDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BeVertragVorschreibungspositionWertDeletionHistoryListQuery,
    BeVertragVorschreibungspositionWertDeletionHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BeVertragVorschreibungspositionWertDeletionHistoryListQuery,
    BeVertragVorschreibungspositionWertDeletionHistoryListQueryVariables
  >(BeVertragVorschreibungspositionWertDeletionHistoryListDocument, options);
}
export function useBeVertragVorschreibungspositionWertDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BeVertragVorschreibungspositionWertDeletionHistoryListQuery,
        BeVertragVorschreibungspositionWertDeletionHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BeVertragVorschreibungspositionWertDeletionHistoryListQuery,
    BeVertragVorschreibungspositionWertDeletionHistoryListQueryVariables
  >(BeVertragVorschreibungspositionWertDeletionHistoryListDocument, options);
}
export type BeVertragVorschreibungspositionWertDeletionHistoryListQueryHookResult = ReturnType<
  typeof useBeVertragVorschreibungspositionWertDeletionHistoryListQuery
>;
export type BeVertragVorschreibungspositionWertDeletionHistoryListLazyQueryHookResult = ReturnType<
  typeof useBeVertragVorschreibungspositionWertDeletionHistoryListLazyQuery
>;
export type BeVertragVorschreibungspositionWertDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useBeVertragVorschreibungspositionWertDeletionHistoryListSuspenseQuery
>;
export type BeVertragVorschreibungspositionWertDeletionHistoryListQueryResult = Apollo.QueryResult<
  BeVertragVorschreibungspositionWertDeletionHistoryListQuery,
  BeVertragVorschreibungspositionWertDeletionHistoryListQueryVariables
>;
export const BeVertragVposIndexationAgreementDeletionHistoryDocument = gql`
  query BeVertragVposIndexationAgreementDeletionHistory($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $vorschreibungspositionId: ID!) {
    getBeVertragVposIndexationAgreementDeletionHistory(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
    ) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBeVertragVposIndexationAgreementDeletionHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    BeVertragVposIndexationAgreementDeletionHistoryQuery,
    BeVertragVposIndexationAgreementDeletionHistoryQueryVariables
  > &
    ({ variables: BeVertragVposIndexationAgreementDeletionHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragVposIndexationAgreementDeletionHistoryQuery, BeVertragVposIndexationAgreementDeletionHistoryQueryVariables>(
    BeVertragVposIndexationAgreementDeletionHistoryDocument,
    options
  );
}
export function useBeVertragVposIndexationAgreementDeletionHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BeVertragVposIndexationAgreementDeletionHistoryQuery,
    BeVertragVposIndexationAgreementDeletionHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragVposIndexationAgreementDeletionHistoryQuery, BeVertragVposIndexationAgreementDeletionHistoryQueryVariables>(
    BeVertragVposIndexationAgreementDeletionHistoryDocument,
    options
  );
}
export function useBeVertragVposIndexationAgreementDeletionHistorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BeVertragVposIndexationAgreementDeletionHistoryQuery,
        BeVertragVposIndexationAgreementDeletionHistoryQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragVposIndexationAgreementDeletionHistoryQuery, BeVertragVposIndexationAgreementDeletionHistoryQueryVariables>(
    BeVertragVposIndexationAgreementDeletionHistoryDocument,
    options
  );
}
export type BeVertragVposIndexationAgreementDeletionHistoryQueryHookResult = ReturnType<
  typeof useBeVertragVposIndexationAgreementDeletionHistoryQuery
>;
export type BeVertragVposIndexationAgreementDeletionHistoryLazyQueryHookResult = ReturnType<
  typeof useBeVertragVposIndexationAgreementDeletionHistoryLazyQuery
>;
export type BeVertragVposIndexationAgreementDeletionHistorySuspenseQueryHookResult = ReturnType<
  typeof useBeVertragVposIndexationAgreementDeletionHistorySuspenseQuery
>;
export type BeVertragVposIndexationAgreementDeletionHistoryQueryResult = Apollo.QueryResult<
  BeVertragVposIndexationAgreementDeletionHistoryQuery,
  BeVertragVposIndexationAgreementDeletionHistoryQueryVariables
>;
