import React, { FC } from 'react';
import { currentEmailValidationSchema } from './currentEmailFormPartValidationSchema';
import EmailFormPart from './EmailFormPart';
import { validateEmailSync } from './emailValidation';

type CurrentEmailFormPartProps = {
  emailInputName?: string;
  emailInputPlaceholder?: string;
  emailInputLabel?: string;
  emailInputValidation?: any;
};

const CurrentEmailFormPart: FC<CurrentEmailFormPartProps> = ({ emailInputName, emailInputPlaceholder, emailInputLabel, ...restProps }) => (
  <EmailFormPart
    emailInputName={emailInputName}
    emailInputLabel={emailInputLabel}
    emailInputPlaceholder={emailInputPlaceholder}
    emailInputValidation={validateCurrentEmail}
    {...restProps}
  />
);

const validateCurrentEmail = (email: string) => validateEmailSync(email, currentEmailValidationSchema);

export default CurrentEmailFormPart;
