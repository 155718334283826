import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useGeneralTextbausteinTypeListQuery } from '../gql/TextbausteinQueries.types';

type Props = {
  name: string;
} & SelectProps;

const TextbausteinTypeSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useGeneralTextbausteinTypeListQuery();
  const generalTextbausteinTypeList = data?.getGeneralTextbausteinTypeList.data ?? [];

  return (
    <Select {...restProps} size="small" name={name} id={name} placeholder="Typ auswählen" allowClear loading={loading}>
      {generalTextbausteinTypeList.map((type) => (
        <Select.Option key={type.value} value={type.value}>
          {type.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TextbausteinTypeSelect;
