import React from 'react';
import { TableWithAlignedColsExpandedRowType } from '../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import TableWithAlignedColsHeaderRow from '../../../components/Table/TableWithAlignedCols/TableWithAlignedColsHeaderRow';
import TableWithAlignedColsBody from '../../../components/Table/TableWithAlignedCols/TableWithAlignedColsBody';
import aufwandsKontoRowColumns from './aufwandsKontoRowColumns';
import kontoAufteilungRow from './kontoAufteilungRow';
import { VorsteuerkuerzungAufwandKonto, VorsteuerkuerzungVstKrzKonto } from '../../../types';

const aufwandsKontoRow = (
  objektId: string,
  rechtstraegerId: string,
  bestandseinheitId: string,
  vonInklusive: string,
  bisInklusive: string
): TableWithAlignedColsExpandedRowType<VorsteuerkuerzungVstKrzKonto>[] => [
  {
    header: (isOpen, setIsOpen) => (
      <TableWithAlignedColsHeaderRow
        isOpen={isOpen}
        bezeichnungHeaderText="Fibu-Konto"
        sumHeaderText={['Summe Vst-Buchungsbetrag', 'Summe-Vst.Kürzung']}
        cellToUseForSumHeaderText={[5, 7]}
        onClick={setIsOpen}
        startCell={3}
        endCell={7}
        colSpan={[4, 3]}
        positionOfButton={3}
      />
    ),
    body: (vstKuerzung) => (
      <>
        {vstKuerzung.aufwandsKontoList.map((konto, index) => (
          <TableWithAlignedColsBody<VorsteuerkuerzungAufwandKonto>
            key={index}
            dataSource={konto}
            columns={aufwandsKontoRowColumns(objektId, vonInklusive, bisInklusive)}
            expandedRow={() => kontoAufteilungRow(objektId, rechtstraegerId, bestandseinheitId)}
            positionOfButton={3}
          />
        ))}
      </>
    ),
  },
];

export default aufwandsKontoRow;
