import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import FormButtons from '../../../../../../components/Button/FormButtons';
import {
  mapFormValuesToRepFonds,
  mapRechnungsAusstellerRepFondsToFormValues,
  rechnungsAusstellerRepFondsZuweisungFormFields,
  RechnungsAusstellerRepFondsZuweisungFormValues,
  rechnungsAusstellerRepFondsZuweisungFormValuesDefault,
} from './rechnAusstellerRepFondsKontoZuweisungFormMapper';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import {
  useCreateRepFondsErloesKontenZuweisungMutation,
  useUpdateRepFondsErloesKontenZuweisungMutation,
} from '../../../../../ReparaturFonds/gql/RepFondsErloesKontenZuweisungMutations.types';
import RechnungsAusstellerFibuBankAccountSelect from './RechnungsAusstellerFibuBankAccountSelect';
import { repFondsKontoZuweisungValidationSchema } from './repFondsKontoZuweisungValidationSchema';
import { isNotNil } from '../../../../../../helpers/assertionHelper';
import AufwandskontenSelect from '../../../../../shared/AufwandskontenSelect';
import { KontoKlasse, RepFondsErloesKontenZuweisung } from '../../../../../../types';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  rechtstraegerId: string;
  onSuccess: () => void;
  onCancel: () => void;
  repFondsErloesKontenZuweisung?: RepFondsErloesKontenZuweisung;
  repFondsErloesKontenZuweisungList?: RepFondsErloesKontenZuweisung[];
};

const RechnAusstellerRepFondsKontoZuweisungForm: FC<Props> = ({
  rechtstraegerId,
  onSuccess,
  onCancel,
  repFondsErloesKontenZuweisung,
  repFondsErloesKontenZuweisungList,
}) => {
  const getFieldHelpText = useGetAppFieldHelpText<'RepFondsErloesKontenZuweisung'>('RepFondsErloesKontenZuweisung');

  const isUpdate = !!repFondsErloesKontenZuweisung;
  const entity = 'Reparaturfonds-Erlöskontenzuweisung';
  const [createRepFondsZuweisung, { loading: createLoading }] = useCreateRepFondsErloesKontenZuweisungMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [updateRepFondsZuweisung, { loading: updateLoading }] = useUpdateRepFondsErloesKontenZuweisungMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const formInitialValues = repFondsErloesKontenZuweisung
    ? mapRechnungsAusstellerRepFondsToFormValues(repFondsErloesKontenZuweisung)
    : rechnungsAusstellerRepFondsZuweisungFormValuesDefault;

  const currentKontoIds = (repFondsErloesKontenZuweisung?.erloesKontoList || []).map((konto) => konto.kontoId);
  const allSelectedKontoIds = (repFondsErloesKontenZuweisungList || [])
    .map((bank) => bank.erloesKontoList)
    .flat()
    .map((konto) => konto.kontoId);

  const excludedKontoIds = allSelectedKontoIds.filter((kontoId) => !currentKontoIds.includes(kontoId));

  return (
    <Formik<RechnungsAusstellerRepFondsZuweisungFormValues>
      initialValues={formInitialValues}
      validationSchema={repFondsKontoZuweisungValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        const request = mapFormValuesToRepFonds(formValues);
        if (isUpdate) {
          updateRepFondsZuweisung({
            variables: {
              rechtstraegerId,
              repFondsErloesKontenZuweisungId: repFondsErloesKontenZuweisung.repFondsErloesKontenZuweisungId,
              request,
            },
          }).finally(() => {
            formikHelpers.setSubmitting(false);
          });
        } else {
          createRepFondsZuweisung({
            variables: { rechtstraegerId, request },
          }).finally(() => {
            formikHelpers.setSubmitting(false);
          });
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={rechnungsAusstellerRepFondsZuweisungFormFields.erloesKontoIdList}
            label="Erlöskonto"
            fieldHelp={getFieldHelpText('RepFondsErloesKontenZuweisung.erloesKontoIdList')}
          >
            <AufwandskontenSelect
              name={rechnungsAusstellerRepFondsZuweisungFormFields.erloesKontoIdList}
              mode="multiple"
              placeholder="Erlöskonten auswählen"
              klasseList={[KontoKlasse.BetrieblicherErloes, KontoKlasse.FinanzErloes]}
              excludedKontenIds={excludedKontoIds}
              showKontoNummerAsTag
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={rechnungsAusstellerRepFondsZuweisungFormFields.fibuBankAccountId}
            label="Fibu-Bankkonto"
            fieldHelp={getFieldHelpText('RepFondsErloesKontenZuweisung.fibuBankAccountId')}
          >
            <RechnungsAusstellerFibuBankAccountSelect
              name={rechnungsAusstellerRepFondsZuweisungFormFields.fibuBankAccountId}
              rechtstraegerId={rechtstraegerId}
              excludedFibuBankAccountIds={findFibuKontoIdsToExclude(repFondsErloesKontenZuweisung, repFondsErloesKontenZuweisungList)}
              assignedFibuKontoId={formInitialValues.fibuBankAccountId}
            />
          </FormItemWithFieldHelp>
          <FormButtons
            updateMode={false}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={createLoading || updateLoading}
            okText="Rep.Fonds zuweisen"
          />
        </Form>
      )}
    </Formik>
  );
};

const findFibuKontoIdsToExclude = (
  repFondsErloesKontenZuweisung?: RepFondsErloesKontenZuweisung,
  repFondsErloesKontenZuweisungList?: RepFondsErloesKontenZuweisung[]
): string[] => {
  return repFondsErloesKontenZuweisungList
    ? repFondsErloesKontenZuweisungList
        .filter((zuweisung) => zuweisung.repFondsErloesKontenZuweisungId !== repFondsErloesKontenZuweisung?.repFondsErloesKontenZuweisungId)
        .map((zuweisung) => zuweisung.fibuBankAccount.fibuKontoId)
        .filter(isNotNil)
    : [];
};

export default RechnAusstellerRepFondsKontoZuweisungForm;
