import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { OffenePostenKontoType, SubAbrechnungEigentuemer, SubAbrZahlungsrueckstand } from '../../../../../types';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToBestandseinheitDetailsPage } from '../../../../Bestandseinheit/bestandseinheitUriPaths';
import RechtstraegerWithContactsTooltip from '../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';
import KontoLinkToKontoblatt from '../../../../../shared/components/Konto/KontoLinkToKontoblatt';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import { compareTwoNumbersForSorting } from '../../../../../helpers/numberHelper';
import { generatePathToOffenePostenPage } from '../../../../OffenePosten/offenePostenUriPaths';
import { EuroAmount } from '../../../../../components/Number';

const zahlungsrueckstandTableColumns = (abrechnung: SubAbrechnungEigentuemer, objektId: string): ColumnProps<SubAbrZahlungsrueckstand>[] => [
  {
    title: 'Vertragspartner',
    sorter: (a, b) => compareTwoStringsForSorting(a.rechtstraeger.kurzBezeichnung, b.rechtstraeger.kurzBezeichnung),
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.rechtstraeger.rechtstraegerId}
        rechtstraegerName={record.rechtstraeger.kurzBezeichnung}
        maxTextLength={40}
        status={record.rechtstraeger.status}
      />
    ),
  },
  {
    title: 'Bestandseinheit',
    sorter: (a, b) => compareTwoStringsForSorting(a.bestandseinheit.bezeichnung, b.bestandseinheit.bezeichnung),
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={30} text={record.bestandseinheit.bezeichnung}>
        {(shortenedText) => (
          <Link
            to={generatePathToBestandseinheitDetailsPage(record.bestandseinheit.objektId, record.bestandseinheit.bestandseinheitId)}
            target="_blank"
          >
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Fibu-Konto',
    sorter: (a, b) => compareTwoStringsForSorting(a.konto.bezeichnung, b.konto.bezeichnung),
    render: (text, record) => (
      <KontoLinkToKontoblatt
        konto={{
          kontoId: record.konto.kontoId,
          nummer: record.konto.nummer,
          bezeichnung: record.konto.bezeichnung,
        }}
        buchungsdatumFrom={abrechnung.abrechnungszeitraumVon}
        buchungsdatumTo={abrechnung.abrechnungszeitraumBis}
        objektId={objektId}
        vertragspartnerId={record.rechtstraeger.rechtstraegerId}
      />
    ),
  },
  {
    title: 'Letzte Zahlungsbuchung am',
    sorter: (a, b) => compareTwoStringsForSorting(a.letzteBuchungAm, b.letzteBuchungAm),
    render: (text, record) => (record.letzteBuchungAm ? <CustomFormattedDate value={record.letzteBuchungAm} /> : null),
  },
  {
    title: 'Offener Betrag',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.offenerBetrag, b.offenerBetrag),
    render: (text, record) => {
      return (
        <Link
          target="_blank"
          to={generatePathToOffenePostenPage({
            kontoType: [OffenePostenKontoType.Debitor],
            objektId: abrechnung.objekt.objektId,
            vertragspartnerId: record.rechtstraeger.rechtstraegerId,
            buchungsdatumFrom: abrechnung.abrechnungszeitraumVon,
            buchungsdatumTo: abrechnung.abrechnungszeitraumBis,
          })}
        >
          <EuroAmount value={record.offenerBetrag} />
        </Link>
      );
    },
  },
];

export default zahlungsrueckstandTableColumns;
