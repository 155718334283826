import React, { FC } from 'react';
import { Input } from 'formik-antd';
import { FormFields } from '../../../../../../helpers/formikHelper';
import { ParkplatzCreateFormValues } from '../Create/parkplatzCreateFormMapper';
import DatenTabColumnsLayout from '../../../../../Objekt/InfoFeld/Form/shared/DatenTabColumnsLayout';
import BestandseinheitFormSelect from '../../../../../shared/Bestandseinheit/BestandseinheitFormSelect';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formFields: FormFields<ParkplatzCreateFormValues>;
  objektId: string;
};

const ParkplatzDatenTab: FC<Props> = ({ formFields, objektId }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeInfoFeldParkplatz'>('BeInfoFeldParkplatz');

  return (
    <DatenTabColumnsLayout
      leftColumn={
        <>
          <FormItemWithFieldHelp name={formFields.nummer} label="Nummer" fieldHelp={getFieldHelpText('BeInfoFeldParkplatz.nummer')}>
            <Input id={formFields.nummer} name={formFields.nummer} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.bestandseinheitId}
            label="Verweis auf Bestandseinheit"
            fieldHelp={getFieldHelpText('BeInfoFeldParkplatz.bestandseinheitId')}
          >
            <BestandseinheitFormSelect name={formFields.bestandseinheitId} objektId={objektId} />
          </FormItemWithFieldHelp>
        </>
      }
      rightColumn={
        <FormItemWithFieldHelp name={formFields.text} label="Text" fieldHelp={getFieldHelpText('BeInfoFeldParkplatz.text')}>
          <Input id={formFields.text} name={formFields.text} size="small" />
        </FormItemWithFieldHelp>
      }
    />
  );
};

export default ParkplatzDatenTab;
