import { round } from 'lodash';
import { BookingSuggestion } from '../../../../types';
import { BookingSuggestionFormValues, OffenePostenForClearingSuggestion } from '../Form/buchungsmaskeFormMapper';
import { isBuchungTypeZahlung } from '../bookingSuggestionHelpers';
import { AuszifferungFormValues } from './auszifferungFormMapper';
import { getFormattedDecimalValueOrZero } from '../../../../components/Input-antd/formattedDecimalHelper';
import { getBuchungszeileWithPersonenkontoFromBookingSuggestion } from '../Form/buchungsmaskeFormHelpers';
import { isKontoTypePersonenkonto } from '../../../Kontenplan/Form/kontoFormHelpers';

export const disableAddButtonBasedOnAvailableAmount = (values: BookingSuggestionFormValues) =>
  isBuchungTypeZahlung(values.buchungsanweisung.buchungType) ? values.availableAmount <= 0 : values.availableAmount >= 0;

export const isNewAuszifferung = (auszifferung: AuszifferungFormValues) => !auszifferung.auszifferungsDatum;

export const isExistingAuszifferung = (auszifferung: AuszifferungFormValues) => !isNewAuszifferung(auszifferung);

export const sumAllNewAuszifferung = (offenePostenForClearingSuggestionList: OffenePostenForClearingSuggestion[]): number => {
  return offenePostenForClearingSuggestionList.reduce((total, op) => {
    const sumAuszifferungAmounts = op.auszifferungList.reduce((sum, auszifferung) => {
      return isNewAuszifferung(auszifferung) ? sum + getFormattedDecimalValueOrZero(auszifferung.amount) : sum;
    }, 0);
    return total + sumAuszifferungAmounts;
  }, 0);
};

export const calculateInitialAvailableAmount = (bookingSuggestion: BookingSuggestion): number => {
  // 1. find buchungszeile with personenkonto
  const buchungszeileWithPersonenKonto = getBuchungszeileWithPersonenkontoFromBookingSuggestion(
    bookingSuggestion.buchungsanweisung.buchungszeileList
  );

  //OR booking Suggestion type is not  Zahlung or Ruecklastschrift!
  if (!buchungszeileWithPersonenKonto) return 0; // if there is no buchungszeile with personenkonto, we won't even show the availableAmount, hence 0 is fine

  // 2. get the amount from this buchungszeile
  const amountFromPersonenKonto = buchungszeileWithPersonenKonto.betrag;

  // 3. Sum up the auszifferungen coming from clearing suggestion list provided by BE
  const sumOFAuszifferungenAmountFromClearingSuggestionList = bookingSuggestion.clearingSuggestionList.reduce(
    (acc, clearSugg) => acc + clearSugg.amount,
    0
  );

  // 4. return the result - subtract the calsulated sum from the amount from Personenkonto  (result should be negative for Rücklastschrift, positive for Zahlung
  // use round from lodash to avoid floating point issues in JS resulting from the subtraction of float numbers
  return round(amountFromPersonenKonto - sumOFAuszifferungenAmountFromClearingSuggestionList, 2);
};

export const calculateAvailableAmountFromFormik = (formikValues: BookingSuggestionFormValues): number => {
  // 1. find buchungszeile with personenkonto
  const buchungszeileWithPersonenkontoList = formikValues.buchungsanweisung.buchungszeileList.filter((bz) =>
    isKontoTypePersonenkonto(bz.konto?.type.value)
  );
  // there is always one buchungszeile with personenkonto, as the auszifferungBlock is rendered only if there is a Buchungszeile with Personenkonto
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const buchungszeileWithPersonenkonto = buchungszeileWithPersonenkontoList[0]!;

  // 2. get the amount from this buchungszeile
  const amountFromPersonenKonto = buchungszeileWithPersonenkonto.amount;

  // 3. check the auszifferungen, sum them up and return the result- Negativ for Rücklastschrift, positiv for Zahlung
  const sumAuszifferungenAmounts = sumAllNewAuszifferung(formikValues.offenePostenForClearingSuggestionList);
  const availableAmount = amountFromPersonenKonto - sumAuszifferungenAmounts;

  // use round from lodash to avoid floating point issues in JS resulting from the subtraction of float numbers
  return round(availableAmount, 2);
};

export const getAuszifferungIndex = (auszifferung: AuszifferungFormValues, offenePosten: OffenePostenForClearingSuggestion) =>
  offenePosten.auszifferungList.findIndex((az) => az.auszifferungId === auszifferung.auszifferungId);
