import React, { FC } from 'react';
import { Avatar, Tooltip } from 'antd';
import { StyledCol } from './Styled/Card.style';
import { useMitarbeiterTooltipQuery } from '../../graphql/MitarbeiterService/MitarbeiterQueries.types';
import { useUserNameQuery } from '../../graphql/Authentication/UserQueries.types';
import { capitalizeFirstLetter } from '../../helpers/stringHelper';
import { UserName } from '../../types';
import { MitarbeiterFragment } from '../../features/Mitarbeiter/gql/MitarbeiterFragments.types';
import { LoadingCol } from '../Loading';

type Props = {
  userId?: string | null;
  mitarbeiterId?: string | null;
  showsInitials?: boolean;
  alignment?: 'left' | 'right' | 'center';
  fontWeight?: 'normal' | 'bold';
};

const MitarbeiterTooltip: FC<Props> = ({ userId, mitarbeiterId, showsInitials, alignment, fontWeight = 'normal' }) => {
  const { loading: loadingUser, data: maybeUserNameData } = useUserNameQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { username: userId! },
    skip: !!mitarbeiterId || !userId,
  });

  const { loading: loadingMitarbeiter, data: maybeMitarbeiterData } = useMitarbeiterTooltipQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { mitarbeiterId: mitarbeiterId! },
    skip: !mitarbeiterId,
  });

  const user = maybeUserNameData?.getUserName.data;
  const mitarbeiter = maybeMitarbeiterData?.getMitarbeiterDetails.data;

  const tooltipText = mitarbeiterToolTipText(user, mitarbeiter);
  const initials = mitarbeiterInitials(user, mitarbeiter);

  return (
    <LoadingCol isLoading={loadingMitarbeiter || loadingUser}>
      <StyledCol alignment={alignment || 'center'} span={9}>
        <Tooltip placement="top" title={<span>{tooltipText}</span>}>
          {showsInitials && initials ? (
            <Avatar style={{ backgroundColor: '#f77e59', verticalAlign: 'middle', fontWeight }} size="small">
              {initials}
            </Avatar>
          ) : (
            <Avatar style={{ backgroundColor: 'rgb(57 169 2)', verticalAlign: 'middle', fontWeight }} size="small">
              S
            </Avatar>
          )}
        </Tooltip>
      </StyledCol>
    </LoadingCol>
  );
};

const mitarbeiterToolTipText = (user?: UserName | null, mitarbeiter?: MitarbeiterFragment | null) => {
  if (mitarbeiter) {
    return `${mitarbeiter.firstname} ${mitarbeiter.lastname}`;
  } else if (user) {
    return `${user.firstname} ${user.lastname}`;
  } else {
    return 'System';
  }
};

export const mitarbeiterInitials = (
  user?: UserName | null,
  mitarbeiter?: {
    firstname: string;
    lastname: string;
  } | null
) => {
  if (mitarbeiter) {
    return `${capitalizeFirstLetter(mitarbeiter.firstname)}${capitalizeFirstLetter(mitarbeiter.lastname)}`;
  } else if (user && user.firstname && user.lastname) {
    return `${capitalizeFirstLetter(user.firstname)}${capitalizeFirstLetter(user.lastname)}`;
  } else {
    return null;
  }
};

export default MitarbeiterTooltip;
