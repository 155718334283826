import { ROOT_URL_FIRMENDATEN } from '../../shared/api/ApiPaths';

/* ---------------------------------------- app/objekt ---------------------------------------- */

export const rootUriObjekt = (firmendatenId: string) => `${ROOT_URL_FIRMENDATEN}/${firmendatenId}/objekte`;
export const uriObjekt = (firmendatenId: string, objektId: string) => `${rootUriObjekt(firmendatenId)}/${objektId}`;
/* ---------------------------------------- filestorage/objekt ---------------------------------------- */

const rootUriObjektFiles = (objektId: string) => `/api/objekte/${objektId}/files`;

export const uriObjektFileInfo = (objektId: string, fileId: string) => `${rootUriObjektFiles(objektId)}/${fileId}`;

export const uriObjektFileOriginal = (objektId: string, fileId: string) => `${uriObjektFileInfo(objektId, fileId)}/download`;

export const uriObjektFileThumbnail = (objektId: string, fileId: string) => `${uriObjektFileInfo(objektId, fileId)}/thumbnail`;
