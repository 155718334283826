import React, { FC } from 'react';
import { Typography } from 'antd';

import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import { Firmendaten } from '../../../../types';

type FirmendatenDetailedInfoProps = {
  firmendaten: Firmendaten;
};
const FirmendatenDetailedInfo: FC<FirmendatenDetailedInfoProps> = ({ firmendaten }) => (
  <>
    <Typography.Title level={5}>Information</Typography.Title>
    <CardDetailsInfoRow infoRowTitle="Rechtsform">
      <Typography.Text>{firmendaten.legalForm.text}</Typography.Text>
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="UID">
      <Typography.Text>{firmendaten.vatIdentificationNumber ?? '-'}</Typography.Text>
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Firmenbuchnummer">
      <Typography.Text>{firmendaten.companyRegistrationNumber ?? '-'}</Typography.Text>
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Fälligkeitsvorschreibung">
      <Typography.Text>{firmendaten.firmeneinstellung.dueDateVorschreibung.text}</Typography.Text>
    </CardDetailsInfoRow>
  </>
);

export default FirmendatenDetailedInfo;
