import { useParams } from 'react-router-dom';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { useAuth } from '../../shared/Auth/authContext';

type RouteParams = { auftragId: string };

export const useOnVorschreibungDataChangedEvent = (key: string, callback: () => Promise<unknown>) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();
  const { auftragId } = useParams() as RouteParams;

  useOnDataChangedEvent({
    filter: {
      type: dataChangedSubscriptionTypes.auftrag,
      userId: user.username,
      mitarbeiterId: mitarbeiter?.mitarbeiterId,
      params: { auftragId },
    },
    notificationDescription: 'Vorschreibungen wurden gerade verändert',
    key,
    callback,
  });
};
