import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from 'antd';
import { friendlyFormatIBAN } from 'ibantools';
import GenerierlaufEntryStatus from '../../shared/GenerierlaufEntryStatus';
import { generatePathToRechtstraegerDetailsPage } from '../../../Rechtstraeger/rechtstraegerHelper';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { PaymentProposalGenerierlaufEntryList } from '../../verarbeitungHelpers';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount } from '../../../../components/Number';
import { generatePathToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import { generatePathToPaymentPage } from '../../../Payment/Listing/Filters/filtersQueryParams';
import KontoLinkToKontoblatt from '../../../../shared/components/Konto/KontoLinkToKontoblatt';
import { PaymentProposalGenerierlaufOrderBy } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { isPaymentTransactionTypeEbics } from '../../../Payment/paymentHelpers';
import PaymentMitarbeiterApprovalColumn from '../../../Payment/shared/PaymentMitarbeiterApprovalColumn';
import LinkToBelegFile from '../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import GenerierlaufEntryStep from '../../shared/GenerierlaufEntryStep';

const paymentProposalVerarbeitungEntryTableColumns = (
  showStepsColumn: boolean
): TableWithColSelectorColumnProps<PaymentProposalGenerierlaufEntryList>[] => [
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: PaymentProposalGenerierlaufOrderBy.Status,
    sorter: true,
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  ...(showStepsColumn
    ? [
        {
          title: 'Schritte',
          defaultSelected: false,
          render: (_: any, record: PaymentProposalGenerierlaufEntryList) => (
            <GenerierlaufEntryStep currentStep={record.currentStep} stepCount={record.stepCount} stepList={record.stepList} />
          ),
        },
      ]
    : []),
  {
    title: 'Gesehen',
    defaultSelected: false,
    render: (text, record) => {
      if (record.payment) {
        return <Typography>{record.payment.noted ? 'Gesehen' : 'Neu'}</Typography>;
      }
      return <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: PaymentProposalGenerierlaufOrderBy.Objekt,
    sorter: true,
    render: (text, record) =>
      record.payment ? (
        <DataWithShortenedText maxTextLength={20} text={record.payment.objekt.kurzBezeichnung}>
          {(shortenedText) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Link target="_blank" to={generatePathToObjektDetailsPage(record.payment!.objekt.objektId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Zahlungstyp',
    defaultSelected: true,
    render: (text, record) => {
      return record.payment ? <Typography.Text>{record.payment.type.text}</Typography.Text> : <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Zahlungsverkehrsart',
    defaultSelected: true,
    render: (text, record) => {
      return record.payment && record.payment.paymentTransactionType ? (
        <Typography.Text>{record.payment.paymentTransactionType.text}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Zahlungsdatum',
    defaultSelected: true,
    dataIndex: PaymentProposalGenerierlaufOrderBy.PaymentDate,
    sorter: true,
    render: (text, record) =>
      record.payment ? <CustomFormattedDate value={record.payment.paymentDate} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Zahlungsvorschlag',
    defaultSelected: false,
    dataIndex: PaymentProposalGenerierlaufOrderBy.PaymentProposal,
    sorter: true,
    render: (text, record) =>
      record.payment && record.payment.paymentProposal ? (
        <Typography.Text>{record.payment.paymentProposal}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Rechnungsaussteller',
    defaultSelected: true,
    dataIndex: PaymentProposalGenerierlaufOrderBy.RechnungsAussteller,
    sorter: true,
    render: (text, record) => {
      return record.payment?.rechnungsAussteller.kurzBezeichnung ? (
        <DataWithShortenedText maxTextLength={20} text={record.payment.rechnungsAussteller.kurzBezeichnung}>
          {(shortenedText) => (
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            <Link target="_blank" to={generatePathToRechtstraegerDetailsPage(record.payment!.rechnungsAussteller.rechtstraegerId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Rechnungsaussteller Bankverbindung',
    defaultSelected: false,
    dataIndex: PaymentProposalGenerierlaufOrderBy.RechnungsAusstellerIban,
    sorter: true,
    render: (text, record) =>
      record.payment ? (
        <DataWithShortenedText
          maxTextLength={20}
          text={`${record.payment.rechnungsAusstellerAccountHolder} - ${friendlyFormatIBAN(record.payment.rechnungsAusstellerIban)}`}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Zahlender/Zahlungsempfänger',
    defaultSelected: false,
    dataIndex: PaymentProposalGenerierlaufOrderBy.Payee,
    sorter: true,
    render: (text, record) => {
      return record.payment?.payee.kurzBezeichnung ? (
        <DataWithShortenedText maxTextLength={20} text={record.payment.payee.kurzBezeichnung}>
          {(shortenedText) => (
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            <Link target="_blank" to={generatePathToRechtstraegerDetailsPage(record.payment!.payee.rechtstraegerId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Zahlender/Zahlungsempfänger Bankverbindung',
    defaultSelected: false,
    dataIndex: PaymentProposalGenerierlaufOrderBy.PayeeIban,
    sorter: true,
    render: (text, record) =>
      record.payment ? (
        <DataWithShortenedText maxTextLength={20} text={`${record.payment.payeeAccountHolder} - ${friendlyFormatIBAN(record.payment.payeeIban)}`} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Belegnummer',
    defaultSelected: false,
    render: (text, record) => {
      if (record.payment && record.payment.belegnummer) {
        return record.payment.belegFileId ? (
          <LinkToBelegFile fileId={record.payment.belegFileId} belegnummer={record.payment.belegnummer} hideIcon />
        ) : (
          <Typography.Text>{record.payment.belegnummer}</Typography.Text>
        );
      }
      return <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Belegdatum',
    defaultSelected: false,
    render: (text, record) =>
      record.payment && record.payment.belegdatum ? (
        <CustomFormattedDate value={record.payment.belegdatum} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Betrag',
    defaultSelected: true,
    align: 'right',
    dataIndex: PaymentProposalGenerierlaufOrderBy.Amount,
    sorter: true,
    render: (text, record) => (record.payment ? <EuroAmount value={record.payment.amount} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Verwendungszweck',
    defaultSelected: false,
    dataIndex: PaymentProposalGenerierlaufOrderBy.Verwendungszweck,
    sorter: true,
    render: (text, record) =>
      record.payment ? <Typography.Text>{record.payment.verwendungszweck}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Zahlungsreferenz',
    defaultSelected: false,
    dataIndex: PaymentProposalGenerierlaufOrderBy.Zahlungsreferenz,
    sorter: true,
    render: (text, record) =>
      record.payment ? <Typography.Text>{record.payment.paymentReferenceText}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Fibu-Bankkonto',
    defaultSelected: false,
    dataIndex: PaymentProposalGenerierlaufOrderBy.RechnungsAusstellerFibuBankAccount,
    sorter: true,
    render: (text, record) =>
      record.payment ? (
        <KontoLinkToKontoblatt
          maxBezeichnungLength={20}
          konto={record.payment.rechnungsAusstellerFibuKonto}
          buchungsdatumFrom={record.payment.buchungsdatum}
          objektId={record.payment.objekt.objektId}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Buchungsdatum',
    defaultSelected: false,
    dataIndex: PaymentProposalGenerierlaufOrderBy.Buchungsdatum,
    sorter: true,
    render: (text, record) => {
      return record.payment && record.payment.buchungsdatum ? (
        <CustomFormattedDate value={record.payment.buchungsdatum} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Freigabe Status',
    defaultSelected: true,
    render: (text, record) =>
      record.payment && record.payment.paymentApprovalStatus ? (
        <Typography.Text>{record.payment.paymentApprovalStatus.text}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Freigabe Mitarbeiter',
    defaultSelected: true,
    render: (text, record) => {
      // The tooltip with approval list should be shown only if the payment is an EBICS payment -> EC-13327
      return !record.payment ||
        !isPaymentTransactionTypeEbics(record.payment.paymentTransactionType?.value) ||
        record.payment.approvalList.length === 0 ? (
        <TextForEmptyValue textToShow="minus" />
      ) : (
        <PaymentMitarbeiterApprovalColumn approvalList={record.payment.approvalList} />
      );
    },
  },
  {
    title: 'Zahlung Status',
    defaultSelected: true,
    dataIndex: PaymentProposalGenerierlaufOrderBy.PaymentStatus,
    sorter: true,
    render: (text, record) =>
      record.payment ? <Typography.Text>{record.payment.status.text}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Erstellt am',
    defaultSelected: false,
    render: (text, record) => (record.payment ? <FormattedDateTime createdTs={record.payment.createTs} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Ersteller',
    defaultSelected: false,
    render: (text, record) =>
      record.payment ? (
        <MitarbeiterTooltip userId={record.payment.createdBy} mitarbeiterId={record.payment.createdByMitarbeiterId} showsInitials alignment="left" />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => {
      return (
        <Link
          target="_blank"
          to={generatePathToPaymentPage({
            belegnummer: record.payment?.belegnummer ?? undefined,
            objektIdList: record.payment ? [record.payment.objekt.objektId] : undefined,
            payeeId: record.payment?.payee.rechtstraegerId,
            paymentDateFrom: record.payment?.paymentDate,
            paymentDateTo: record.payment?.paymentDate,
            paymentProposalId: record.payment?.paymentProposalId ?? undefined,
            rechnungsAusstellerId: record.payment?.rechnungsAussteller.rechtstraegerId,
          })}
        >
          Details
        </Link>
      );
    },
  },
];

export default paymentProposalVerarbeitungEntryTableColumns;
