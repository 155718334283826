import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WizardListQueryVariables = Types.Exact<{
  createTsFromInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsToInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.WizardOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  showCompleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  statusList?: Types.InputMaybe<Array<Types.WizardStatus> | Types.WizardStatus>;
  type?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type WizardListQuery = {
  getWizardList: {
    data: {
      contentList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        currentStep: number;
        stepCount: number;
        type: string;
        wizardId: string;
        status: { description?: string | null; text: string; value: Types.WizardStatus };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const WizardListDocument = gql`
  query WizardList(
    $createTsFromInclusive: String
    $createTsToInclusive: String
    $erstellerMitarbeiterIdList: [String!]
    $order: WizardOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
    $showCompleted: Boolean
    $statusList: [WizardStatus!]
    $type: String
  ) {
    getWizardList(
      createTsFromInclusive: $createTsFromInclusive
      createTsToInclusive: $createTsToInclusive
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
      showCompleted: $showCompleted
      statusList: $statusList
      type: $type
    ) {
      data {
        contentList {
          createTs
          createdBy
          createdByMitarbeiterId
          currentStep
          status {
            description
            text
            value
          }
          stepCount
          type
          wizardId
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useWizardListQuery(baseOptions?: Apollo.QueryHookOptions<WizardListQuery, WizardListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WizardListQuery, WizardListQueryVariables>(WizardListDocument, options);
}
export function useWizardListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WizardListQuery, WizardListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WizardListQuery, WizardListQueryVariables>(WizardListDocument, options);
}
export function useWizardListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WizardListQuery, WizardListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WizardListQuery, WizardListQueryVariables>(WizardListDocument, options);
}
export type WizardListQueryHookResult = ReturnType<typeof useWizardListQuery>;
export type WizardListLazyQueryHookResult = ReturnType<typeof useWizardListLazyQuery>;
export type WizardListSuspenseQueryHookResult = ReturnType<typeof useWizardListSuspenseQuery>;
export type WizardListQueryResult = Apollo.QueryResult<WizardListQuery, WizardListQueryVariables>;
