import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../styles/pdfStyles';
import { Zeitscheibe } from '../topAbrechnung-types';

type TATAufwandZeitscheibeProps = {
  zeitscheibe: Zeitscheibe;
  fontSize?: string;
};

const TATAufwandZeitscheibe: FC<TATAufwandZeitscheibeProps> = ({ zeitscheibe, fontSize }) => (
  <View style={[pdfStyles.row, { marginTop: '3mm', fontSize }]}>
    <View style={[pdfStyles.column, { width: '80%' }]}>
      <Text style={[pdfStyles.column]}>{zeitscheibe.zeitraumText}</Text>
      <Text style={[pdfStyles.column]}>{zeitscheibe.anteilProzentText}</Text>
      {zeitscheibe.anteilText ? <Text style={[pdfStyles.column]}>{zeitscheibe.anteilText}</Text> : null}
    </View>
    <View style={[pdfStyles.column, { width: '20%' }]}>
      <Text style={[pdfStyles.column]}> </Text>
      {zeitscheibe.anteilText ? <Text style={[pdfStyles.column]}> </Text> : null}
      <Text style={[pdfStyles.column, { textAlign: 'right' }]}>{zeitscheibe.anteilBetrag}</Text>
    </View>
  </View>
);

export default TATAufwandZeitscheibe;
