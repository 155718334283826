import { FormFields } from '../../../../helpers/formikHelper';
import { BankDetailsChangeAccountLimitInput } from '../../../../types';

export type AccountLimitFormValues = {
  accountLimit?: number | null;
};

export const accountLimitFormFields: FormFields<AccountLimitFormValues> = {
  accountLimit: 'accountLimit',
};

export const accountLimitFormInitialValue = (accountLimit?: number | null): AccountLimitFormValues => ({
  accountLimit,
});

export const mapFormValuesToAccountLimit = (values: AccountLimitFormValues): BankDetailsChangeAccountLimitInput => ({
  accountLimit: values.accountLimit ?? 0,
});
