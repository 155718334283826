import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { TopAbrechnung } from '../../../topAbrechnung-types';

const TAAbrKreisDetailsGesamtErgebnis: FC<{ data: TopAbrechnung }> = ({ data }) => (
  <View style={[pdfStyles.row, { marginTop: '5mm' }]}>
    <Text style={[pdfStyles.column, { fontSize: '12px', fontWeight: 'bold', width: '50%' }]}>{data.abrechnungskreisDetails.gesamtErgebnis.text}</Text>
    <Text
      style={[
        pdfStyles.column,
        pdfStyles.textNormal,
        pdfStyles.boxShadow,
        {
          fontWeight: 'bold',
          textAlign: 'right',
          width: '15%',
        },
      ]}
    >
      {data.abrechnungskreisDetails.gesamtErgebnis.betrag}
    </Text>
  </View>
);

export default TAAbrKreisDetailsGesamtErgebnis;
