import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { VorschreibungMailDeliveryFieldsFragmentDoc } from './VorschreibungMailDeliveryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorschreibungMailDeliveryQueryVariables = Types.Exact<{
  vorschreibungMailDeliveryId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungMailDeliveryQuery = {
  getVorschreibungMailDelivery: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      emailAddress: string;
      emailDeliveryDefinitionBezeichnung: string;
      emailDeliveryDefinitionId: string;
      mailId?: string | null;
      original: boolean;
      sender?: string | null;
      sentTs?: string | null;
      shouldNeverSend: boolean;
      shouldSend: boolean;
      updatedByMitarbeiterId?: string | null;
      vorschreibungMailDeliveryId: string;
      resentDeliveryList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        emailAddress: string;
        emailDeliveryDefinitionBezeichnung: string;
        emailDeliveryDefinitionId: string;
        mailId?: string | null;
        original: boolean;
        sender?: string | null;
        sentTs?: string | null;
        shouldNeverSend: boolean;
        shouldSend: boolean;
        updatedByMitarbeiterId?: string | null;
        vorschreibungMailDeliveryId: string;
        emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
        fakturierungsperiode: { jahr: number; monat: number };
        objektBestandseinheitVertragList: Array<{
          vertragId: string;
          bestandseinheit: {
            bestandseinheitId: string;
            bezeichnung: string;
            address: {
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              type: Types.AddressType;
              zipCode?: string | null;
            };
          };
          objekt: { kurzBezeichnung: string; objektId: string };
        }>;
        recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
        vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      sourceDelivery?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        emailAddress: string;
        emailDeliveryDefinitionBezeichnung: string;
        emailDeliveryDefinitionId: string;
        mailId?: string | null;
        original: boolean;
        sender?: string | null;
        sentTs?: string | null;
        shouldNeverSend: boolean;
        shouldSend: boolean;
        updatedByMitarbeiterId?: string | null;
        vorschreibungMailDeliveryId: string;
        emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
        fakturierungsperiode: { jahr: number; monat: number };
        objektBestandseinheitVertragList: Array<{
          vertragId: string;
          bestandseinheit: {
            bestandseinheitId: string;
            bezeichnung: string;
            address: {
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              type: Types.AddressType;
              zipCode?: string | null;
            };
          };
          objekt: { kurzBezeichnung: string; objektId: string };
        }>;
        recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
        vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
      fakturierungsperiode: { jahr: number; monat: number };
      objektBestandseinheitVertragList: Array<{
        vertragId: string;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          address: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
        };
        objekt: { kurzBezeichnung: string; objektId: string };
      }>;
      recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
  };
};

export const VorschreibungMailDeliveryDocument = gql`
  query VorschreibungMailDelivery($vorschreibungMailDeliveryId: ID!) {
    getVorschreibungMailDelivery(vorschreibungMailDeliveryId: $vorschreibungMailDeliveryId) {
      data {
        ...VorschreibungMailDeliveryFields
        resentDeliveryList {
          ...VorschreibungMailDeliveryFields
        }
        sourceDelivery {
          ...VorschreibungMailDeliveryFields
        }
      }
    }
  }
  ${VorschreibungMailDeliveryFieldsFragmentDoc}
`;
export function useVorschreibungMailDeliveryQuery(
  baseOptions: Apollo.QueryHookOptions<VorschreibungMailDeliveryQuery, VorschreibungMailDeliveryQueryVariables> &
    ({ variables: VorschreibungMailDeliveryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungMailDeliveryQuery, VorschreibungMailDeliveryQueryVariables>(VorschreibungMailDeliveryDocument, options);
}
export function useVorschreibungMailDeliveryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorschreibungMailDeliveryQuery, VorschreibungMailDeliveryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungMailDeliveryQuery, VorschreibungMailDeliveryQueryVariables>(VorschreibungMailDeliveryDocument, options);
}
export function useVorschreibungMailDeliverySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VorschreibungMailDeliveryQuery, VorschreibungMailDeliveryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungMailDeliveryQuery, VorschreibungMailDeliveryQueryVariables>(VorschreibungMailDeliveryDocument, options);
}
export type VorschreibungMailDeliveryQueryHookResult = ReturnType<typeof useVorschreibungMailDeliveryQuery>;
export type VorschreibungMailDeliveryLazyQueryHookResult = ReturnType<typeof useVorschreibungMailDeliveryLazyQuery>;
export type VorschreibungMailDeliverySuspenseQueryHookResult = ReturnType<typeof useVorschreibungMailDeliverySuspenseQuery>;
export type VorschreibungMailDeliveryQueryResult = Apollo.QueryResult<VorschreibungMailDeliveryQuery, VorschreibungMailDeliveryQueryVariables>;
