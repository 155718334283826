import React, { FC } from 'react';
import ErrorMessagePanel from '../../../components/Error/ErrorMessagePanel';
import { SingleInvoice, StandingInvoice } from '../../../types';
import { isInvoiceTypeSingleInvoice } from './incomingInvoiceFormHelpers';

type Props = {
  incomingInvoice?: SingleInvoice | StandingInvoice;
};

const BuchungErrorList: FC<Props> = ({ incomingInvoice }) => {
  if (!incomingInvoice) {
    return null;
  }

  if (isInvoiceTypeSingleInvoice(incomingInvoice.type)) {
    const buchungErrorList = (incomingInvoice as SingleInvoice).buchungErrorList;
    return buchungErrorList.length > 0 ? <ErrorMessagePanel error={buchungErrorList} /> : null;
  }

  // TODO check
  const buchungErrorList = (incomingInvoice as StandingInvoice).partialAmountList.map((partialAmount) => partialAmount.buchungErrorList).flat();
  return buchungErrorList.length > 0 ? <ErrorMessagePanel error={buchungErrorList} /> : null;
};

export default BuchungErrorList;
