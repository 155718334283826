import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { isNotNil } from '../../../../../../../helpers/assertionHelper';
import { BuchungszeileBestandseinheit, FibuBuchungszeile } from '../../../../../../../types';
import { selectFilterOption } from '../../../../../../../helpers/selectHelper';

type Props = {
  name: string;
  buchungszeileList: FibuBuchungszeile[];
} & SelectProps;

const BzBestandseinheitSelect: FC<Props> = ({ name, buchungszeileList, ...restProps }) => {
  const uniqueBeList: BuchungszeileBestandseinheit[] = buchungszeileList
    .map((bz) => bz.bestandseinheit)
    .filter(isNotNil)
    .reduce(removeDuplicates, []);

  return (
    <Select name={name} size="small" allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {uniqueBeList.map((bestandseinheit) => (
        <Select.Option key={bestandseinheit.bestandseinheitId} value={bestandseinheit.bestandseinheitId} title={bestandseinheit.bezeichnung}>
          {bestandseinheit.bezeichnung ?? bestandseinheit.bestandseinheitId}
        </Select.Option>
      ))}
    </Select>
  );
};

const removeDuplicates = (acc: BuchungszeileBestandseinheit[], current: BuchungszeileBestandseinheit) => {
  const isNotUnique = acc.find((item) => item.bestandseinheitId === current.bestandseinheitId);
  if (isNotUnique) {
    return acc;
  }
  return acc.concat([current]);
};
export default BzBestandseinheitSelect;
