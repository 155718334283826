import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';

type Props = SelectProps;

const ExpenseReducingSelect: FC<Props> = ({ ...restProps }) => {
  const data = [
    { value: true, text: 'Ja' },
    { value: false, text: 'Nein' },
  ];

  return (
    <Select id={restProps.name} allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {data.map((expenseReducing, index) => (
        <Select.Option key={index} value={expenseReducing.value}>
          {expenseReducing.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ExpenseReducingSelect;
