import { FormFields } from '../../../../helpers/formikHelper';
import { ZinslisteGenerierlaufCreateInput } from '../../../../types';
import { ALLE_TAG_TEXT } from '../../../../shared/ObjektSelect/ObjektSelectWithAllTag';
import { getDifferenceBetweenTwoMonths } from '../../../shared/getDifferenceBetweenTwoMonths';

export interface ZinslisteFormValues {
  vorschreibungsperiodeList: string[] | null;
  objektIdList?: string[] | null;
}

export const zinslisteFormInitialValues: ZinslisteFormValues = { vorschreibungsperiodeList: null, objektIdList: null };

export const zinslisteFormFields: FormFields<ZinslisteFormValues> = {
  vorschreibungsperiodeList: 'vorschreibungsperiodeList',
  objektIdList: 'objektIdList',
};

export const mapFormValuesToZinslisteCreateInput = (values: ZinslisteFormValues): ZinslisteGenerierlaufCreateInput => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  vorschreibungsperiodeList: getDifferenceBetweenTwoMonths(values.vorschreibungsperiodeList![0], values.vorschreibungsperiodeList![1]),
  objektIdList: values.objektIdList?.includes(ALLE_TAG_TEXT) ? null : values.objektIdList,
});
