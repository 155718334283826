import React, { FC } from 'react';
import { View } from '@react-pdf/renderer';
import { FPVorschreibungsposition } from '../financialPlan-types';
import LabelValue from '../../../shared/LabelValue';

type Props = {
  vpos: FPVorschreibungsposition;
};

const FPCurrentAndNewVPosWertBlock: FC<Props> = ({ vpos }) => (
  <View style={{ marginTop: '5mm' }}>
    <LabelValue valueTuple={vpos.currentVPosWert} />
    {vpos.newVPosWert && <LabelValue valueTuple={vpos.newVPosWert} />}
  </View>
);

export default FPCurrentAndNewVPosWertBlock;
