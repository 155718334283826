import React, { FC } from 'react';
import { Dropdown } from 'antd';
import { DocumentRowProps } from './util';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';

const RowUnknown: FC<DocumentRowProps> = ({ record, rechnungTyp, invoiceIssuerId, invoiceRecipientId, ...restProps }) => {
  const documentInfo: DocumentInfo = {
    column1: <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.type} />,
    column2: <ValueTitle small title="-" content="-" />,
    column3: <ValueTitle small title="-" content="-" />,
    column4: <ValueTitle small title="-" content="-" />,
    column5: <ValueTitle small title="-" content="-" />,
    column6: <ValueTitle small title="-" content="-" />,
    column7: null,
    column8: <Dropdown.Button disabled>Öffnen</Dropdown.Button>,
  };

  return (
    <DocumentRowContextProvider value={{ isLoading: false, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

export default RowUnknown;
