import React, { FC } from 'react';

type TableHeadCellProps = {
  title: string;
  align?: 'left' | 'right' | 'center';
  colSpan?: number;
  width?: string;
};

const TableHeadCell: FC<TableHeadCellProps> = ({ title, align = 'left', colSpan, width }) => (
  <th className="ant-table-cell" style={{ textAlign: align, width: `${width}` }} colSpan={colSpan}>
    {title}
  </th>
);

export default TableHeadCell;
