import { FormikProps } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { chunk } from 'lodash';
import { filtersFormFields, FiltersFormValues, listingLabelList } from '../paymentAssignmentFormMapper';
import FiltersWith4OrMoreFields from '../../../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../../../../components/Filters/FiltersHelpers';
import FromToFormInputNumber from '../../../../../shared/FromToForm/FromToFormInputNumber';
import FormItemWithoutColon from '../../../../../../components/Form/FormItemWithoutColon';
import FromToFormDatePicker from '../../../../../shared/FromToForm/FromToFormDatePicker';
import PaymentProposalSelect from '../../../../../PaymentProposal/PaymentProposalSelect';
import RechnungsausstellerSelect from '../../../../../Rechnungsaussteller/RechnungsausstellerSelect';
import KontoFormSelect from '../../../../../KontoSelect/KontoFormSelect';
import { KontoKlasse, KontoVerwendung } from '../../../../../../types';
import RechtstraegerSelect from '../../../../../../shared/Rechtstraeger/RechtstraegerSelect';
import PaymentStatusFilter from '../../../../../Payment/Listing/Filters/PaymentStatusFilter';
import PaymentAssignmentPayeeIbanSelect from './PaymentAssignmentPayeeIbanSelect';
import PaymentAssignmentInvoiceIssuerIbanSelect from './PaymentAssignmentInvoiceIssuerIbanSelect';
import PaymentBelegnummerSelect from '../../../../../Payment/Listing/Filters/PaymentBelegnummerSelect';
import PaymentPurposeOfUseTextSelect from '../../../../../Payment/Listing/Filters/PaymentPurposeOfUseTextSelect';
import PaymentReferenceTextSelect from '../../../../../Payment/Listing/Filters/PaymentReferenceTextSelect';
import { ALLOWED_STATUS_LIST_FOR_PAYMENT_ASSIGMENT } from '../PaymentAssignmentForm';
import { paymentListingLabelList } from '../../../../../Payment/Listing/Filters/paymentListingFiltersFormMapper';
import PaymentTypeSelect from '../../../../../Payment/Listing/Filters/PaymentTypeSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
};

const FormFilters: FC<Props> = ({ formikProps }) => {
  const defaultSelectedFilterList = getDefaultFilterList(formikProps.values);

  const [selectedFilterList, setSelectedFilterList] = useState<string[]>(defaultSelectedFilterList);
  const filterList = selectedFilterList
    .map((filter) => {
      if (filter === filtersFormFields.amountFrom) {
        return (
          <FromToFormInputNumber
            key={filter}
            name={listingLabelList.amountFrom}
            fromName={filtersFormFields.amountFrom}
            toName={filtersFormFields.amountTo}
          />
        );
      }
      if (filter === filtersFormFields.paymentDateFrom) {
        return (
          <FormItemWithoutColon key={filter} name="paymentDate" label={listingLabelList.paymentDateFrom}>
            <FromToFormDatePicker format="day" fromName={filtersFormFields.paymentDateFrom} toName={filtersFormFields.paymentDateTo} />
          </FormItemWithoutColon>
        );
      }
      if (filter === filtersFormFields.paymentProposalId) {
        return (
          <FormItemWithoutColon key={filter} name={filtersFormFields.paymentProposalId} label={listingLabelList.paymentProposalId}>
            <PaymentProposalSelect name={filtersFormFields.paymentProposalId} showNotAssignedOption={false} />
          </FormItemWithoutColon>
        );
      }
      if (filter === filtersFormFields.rechnungsAusstellerId) {
        return (
          <FormItemWithoutColon key={filter} name={filtersFormFields.rechnungsAusstellerId} label={listingLabelList.rechnungsAusstellerId}>
            <RechnungsausstellerSelect name={filtersFormFields.rechnungsAusstellerId} />
          </FormItemWithoutColon>
        );
      }
      if (filter === filtersFormFields.rechnungsAusstellerIban) {
        return (
          <FormItemWithoutColon key={filter} name={filtersFormFields.rechnungsAusstellerIban} label={listingLabelList.rechnungsAusstellerIban}>
            <PaymentAssignmentInvoiceIssuerIbanSelect
              name={filtersFormFields.rechnungsAusstellerIban}
              rechtstraegerId={formikProps.values.rechnungsAusstellerId}
            />
          </FormItemWithoutColon>
        );
      }
      if (filter === filtersFormFields.rechnungsAusstellerFibuKontoId) {
        return (
          <FormItemWithoutColon
            key={filter}
            name={filtersFormFields.rechnungsAusstellerFibuKontoId}
            label={listingLabelList.rechnungsAusstellerFibuKontoId}
          >
            <KontoFormSelect
              name={filtersFormFields.rechnungsAusstellerFibuKontoId}
              kontoKlasse={[KontoKlasse.UmlaufVermoegen]}
              kontoVerwendung={KontoVerwendung.Bank}
              placeholder="Fibu Bankkonto auswählen"
            />
          </FormItemWithoutColon>
        );
      }
      if (filter === filtersFormFields.payeeId) {
        return (
          <FormItemWithoutColon name={filtersFormFields.payeeId} label={listingLabelList.payeeId}>
            <RechtstraegerSelect name={filtersFormFields.payeeId} placeholder="Zahlenden/Zahlungsempfänger auswählen" />
          </FormItemWithoutColon>
        );
      }
      if (filter === filtersFormFields.payeeIban) {
        return (
          <FormItemWithoutColon key={filter} name={filtersFormFields.payeeIban} label={listingLabelList.payeeIban}>
            <PaymentAssignmentPayeeIbanSelect name={filtersFormFields.payeeIban} rechtstraegerId={formikProps.values.payeeId} />
          </FormItemWithoutColon>
        );
      }
      if (filter === filtersFormFields.statusList) {
        return <PaymentStatusFilter key={filter} name={filtersFormFields.statusList} allowedStatusList={ALLOWED_STATUS_LIST_FOR_PAYMENT_ASSIGMENT} />;
      }
      if (filter === filtersFormFields.belegnummer) {
        return (
          <FormItemWithoutColon key={filter} name={filtersFormFields.belegnummer} label={listingLabelList.belegnummer}>
            <PaymentBelegnummerSelect name={filtersFormFields.belegnummer} belegnummer={formikProps.values.belegnummer} />
          </FormItemWithoutColon>
        );
      }
      if (filter === filtersFormFields.purposeOfUseText) {
        return (
          <FormItemWithoutColon key={filter} name={filtersFormFields.purposeOfUseText} label={listingLabelList.purposeOfUseText}>
            <PaymentPurposeOfUseTextSelect name={filtersFormFields.purposeOfUseText} purposeOfUseText={formikProps.values.purposeOfUseText} />
          </FormItemWithoutColon>
        );
      }
      if (filter === filtersFormFields.paymentReferenceText) {
        return (
          <FormItemWithoutColon key={filter} name={filtersFormFields.paymentReferenceText} label={listingLabelList.paymentReferenceText}>
            <PaymentReferenceTextSelect
              name={filtersFormFields.paymentReferenceText}
              paymentReferenceText={formikProps.values.paymentReferenceText}
            />
          </FormItemWithoutColon>
        );
      }
      if (filter === filtersFormFields.paymentTypeList) {
        return (
          <FormItemWithoutColon name={filtersFormFields.paymentTypeList} label={paymentListingLabelList.paymentTypeList}>
            <PaymentTypeSelect key={filter} name={filtersFormFields.paymentTypeList} />
          </FormItemWithoutColon>
        );
      }
      return undefined;
    })
    .filter((x) => !!x);

  const chunkFilterList = chunk(filterList, Math.round(filterList.length / 2));

  useEffect(() => {
    if (!selectedFilterList.includes(filtersFormFields.paymentDateFrom) && formikProps.values.paymentDateTo) {
      formikProps.setFieldValue(filtersFormFields.paymentDateTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.amountFrom) && formikProps.values.amountTo) {
      formikProps.setFieldValue(filtersFormFields.amountTo, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  return (
    <>
      <FiltersWith4OrMoreFields
        filtersSelect={
          <FiltersSelect
            defaultSelectedFilterList={defaultSelectedFilterList}
            selectedFilterList={selectedFilterList}
            onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
            labelList={listingLabelList}
          />
        }
        hideTitle
        leftColumn={<>{chunkFilterList[0]}</>}
        rightColumn={<>{chunkFilterList[1]}</>}
      />
    </>
  );
};

const getDefaultFilterList = (values: FiltersFormValues) => {
  const defaultList: string[] = [
    filtersFormFields.rechnungsAusstellerId,
    filtersFormFields.payeeId,
    filtersFormFields.purposeOfUseText,
    filtersFormFields.belegnummer,
    filtersFormFields.paymentDateFrom,
    filtersFormFields.amountFrom,
  ];

  if (values.rechnungsAusstellerIban) {
    defaultList.push(filtersFormFields.rechnungsAusstellerIban);
  }

  if (values.payeeIban) {
    defaultList.push(filtersFormFields.payeeIban);
  }

  return defaultList;
};

export default FormFilters;
