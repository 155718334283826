import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import PaymentListing from '../../features/Payment/Listing/PaymentListing';

const PaymentPage = () => {
  return (
    <>
      <Helmet>
        <title>Zahlungen</title>
      </Helmet>
      <PageLayout>
        <PaymentListing />
      </PageLayout>
    </>
  );
};

export default PaymentPage;
