import { Dayjs } from 'dayjs';
import { ALLE_TAG_TEXT } from '../../../shared/ObjektSelect/ObjektSelectWithAllTag';
import { ValuePreservationType, VertragVposIndexedValueGenerierlaufCreateInput } from '../../../types';
import { FormFields, mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import { mapDateFormValueToMonthYear } from '../../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/Table/Level2/CreateUpdateIndexationAgreement/indexationAgreementHelpers';

export interface IndexedValueFormValues {
  allowPreliminaryValues: boolean;
  objektIdList?: string[] | null;
  validFrom?: Dayjs | string;
  valuePreservationTypeList: ValuePreservationType[] | null;
  yearMonth: Dayjs | string;
}

export const indexedValueFormInitialValues: IndexedValueFormValues = {
  allowPreliminaryValues: false,
  objektIdList: null,
  validFrom: '',
  valuePreservationTypeList: [ValuePreservationType.IndexThreshold, ValuePreservationType.IndexAnnual],
  yearMonth: '',
};

export const indexedValueFormFields: FormFields<IndexedValueFormValues> = {
  allowPreliminaryValues: 'allowPreliminaryValues',
  objektIdList: 'objektIdList',
  validFrom: 'validFrom',
  valuePreservationTypeList: 'valuePreservationTypeList',
  yearMonth: 'yearMonth',
};

export const mapFormValuesToIndexedValue = (values: IndexedValueFormValues): VertragVposIndexedValueGenerierlaufCreateInput => ({
  allowPreliminaryValues: values.allowPreliminaryValues,
  objektIdList: values.objektIdList?.includes(ALLE_TAG_TEXT) ? null : values.objektIdList, // BE expects either an array of objektIDs or an empty array, otherwise it throws an error
  validFrom: !values.validFrom ? null : mapFormDateValueToDateString(dayjsCustom(values.validFrom).startOf('month')),
  valuePreservationTypeList: values.valuePreservationTypeList,
  yearMonth: mapDateFormValueToMonthYear(values.yearMonth),
});
