import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../../components/ContextSidebar/ContentWithSidebar';
import { generatePathToVerarbeitungVorschreibungVerbuchenDetailsPage } from '../../../../features/Verarbeitung/verarbeitungApiPaths';
import { verarbeitungPageAndMenuListTitles } from '../../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungErrorAndWarningListing from '../../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import VerarbeitungVorschreibungVerbuchen from '../../../../features/Verarbeitung/Vorschreibung/Verbuchen/VerarbeitungVorschreibungVerbuchen';
import { AuftragVerbuchenGenerierlauf } from '../../../../types';

const menuListVorschreibungVerbuchen = (generierlauf: AuftragVerbuchenGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.AuftraegeVerbuchen,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungVorschreibungVerbuchenDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: (props, isCardCollapsed) => <VerarbeitungVorschreibungVerbuchen generierlauf={generierlauf} isCardCollapsed={isCardCollapsed} />,
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungVorschreibungVerbuchenDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListVorschreibungVerbuchen;
