import React, { FC } from 'react';
import { Input } from 'antd';
import FormItemWithoutColon from '../../../../../../components/Form/FormItemWithoutColon';
import { zahlungsregelFormFields } from '../zahlungsregelFormMapper';
import ZahlungsregelPeriodeSelect from '../ZahlungsregelPeriodeSelect';
import FormattedDecimalFormInput from '../../../../../../components/Input-antd/FormattedDecimalFormInput';
import { HeAbrechnungsdefinitionFieldsFragment } from '../../../../../Abrechnungsdefinition/gql/HeAbrDef/HeAbrDefFragments.types';

type Props = {
  heAbrechnungsdefinition?: HeAbrechnungsdefinitionFieldsFragment | null;
};

const ProzentsatzHeAbrechnungFormItem: FC<Props> = ({ heAbrechnungsdefinition }) => {
  return (
    <>
      {/* TODO Will be replaced in future Select - not yet implemented by BE */}
      <FormItemWithoutColon name={zahlungsregelFormFields.heAbrechnungsdefinitionId} label="Abrechnungsdefinition">
        {heAbrechnungsdefinition?.bezeichnung ? (
          <Input size="small" value={heAbrechnungsdefinition.bezeichnung} disabled />
        ) : (
          <Input size="small" value="Keine Auswahl" disabled />
        )}
      </FormItemWithoutColon>
      <FormItemWithoutColon name={zahlungsregelFormFields.turnus} label="Turnus">
        <ZahlungsregelPeriodeSelect name={zahlungsregelFormFields.turnus} size="small" placeholder="Zahlungsintervall" />
      </FormItemWithoutColon>
      <FormItemWithoutColon name={zahlungsregelFormFields.prozentsatz} label="Prozentsatz des Guthaben">
        <FormattedDecimalFormInput
          id={zahlungsregelFormFields.prozentsatz}
          name={zahlungsregelFormFields.prozentsatz}
          placeholder="z.B. 10,00"
          size="small"
          min={0}
          style={{ width: '100%' }}
          isPercentage
        />
      </FormItemWithoutColon>
    </>
  );
};

export default ProzentsatzHeAbrechnungFormItem;
