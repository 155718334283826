import { Button, Dropdown, MenuProps, Modal } from 'antd';
import React, { FC } from 'react';
import { CopyOutlined, DownOutlined, InfoOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import VorschreibungCreateForm from '../../Wizard/VorschrebungMailDelivery/Create/CreateForm';
import InfoMailWizardCreateForm from '../../Wizard/InfoMailDelivery/Create/CreateForm';

type Props = {
  label: string;
};

const EmailDeliveryActionButtons: FC<Props> = ({ label }) => {
  const [isVorschreibungCollapsed, onToggleVorschreibung] = useToggle();
  const [isInfoMailCollapsed, onToggleInfoMail] = useToggle();

  const items: MenuProps['items'] = [
    { key: '1', label: 'Vorschreibungen versenden', onClick: onToggleVorschreibung, icon: <CopyOutlined /> },
    { key: '2', label: 'Informationsschreiben versenden', onClick: onToggleInfoMail, icon: <InfoOutlined /> },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button type="primary">
          {label}
          <DownOutlined />
        </Button>
      </Dropdown>
      <Modal
        title="Vorschreibungen versenden"
        onCancel={onToggleVorschreibung}
        open={isVorschreibungCollapsed}
        destroyOnClose
        maskClosable={false}
        footer={false}
        width="860px"
      >
        <VorschreibungCreateForm onCancel={onToggleVorschreibung} />
      </Modal>
      <Modal
        title="Informationsschreiben versenden"
        onCancel={onToggleInfoMail}
        open={isInfoMailCollapsed}
        destroyOnClose
        maskClosable={false}
        footer={false}
        width="860px"
      >
        <InfoMailWizardCreateForm onCancel={onToggleInfoMail} />
      </Modal>
    </>
  );
};

export default EmailDeliveryActionButtons;
