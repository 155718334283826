import React, { FC } from 'react';
import { Divider, Space, Tooltip, Typography } from 'antd';
import DeviationChangeTypeTag from '../../../DeviationChangeTypeTag';
import { BuchungsanweisungDeviationSourceEntity, DeviationChangeTypeTuple } from '../../../../../types';
import { DeviationColumnTitleStyledText } from '../../../Styled/Deviation.style';
import { EuroAmount } from '../../../../Number';

type BuchungsanweisungSourceEntityHeaderProps = {
  changeType: DeviationChangeTypeTuple;
  sourceEntity: BuchungsanweisungDeviationSourceEntity;
};

const BuchungsanweisungSourceEntityHeader: FC<BuchungsanweisungSourceEntityHeaderProps> = ({ changeType, sourceEntity }) => {
  return (
    <Space align="baseline" size={8} split={<Divider type="vertical" />}>
      <Space size={4} align="baseline">
        <DeviationChangeTypeTag changeType={changeType} />
        <DeviationColumnTitleStyledText type="secondary">{sourceEntity.type.text}</DeviationColumnTitleStyledText>
      </Space>
      <Space size="large">
        {sourceEntity.auftragsartType && <Typography.Text> {sourceEntity.auftragsartType.text}</Typography.Text>}
        <Tooltip title="Belegnummer">
          <Typography.Text>{sourceEntity.belegnummer}</Typography.Text>
        </Tooltip>
        {sourceEntity.gegenKonto && <Typography.Text>{sourceEntity.gegenKonto.nummer}</Typography.Text>}
        <EuroAmount value={sourceEntity.gesamtBetrag} />
      </Space>
    </Space>
  );
};

export default BuchungsanweisungSourceEntityHeader;
