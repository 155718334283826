import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RechnungsAusstellerBestandseinheitListQueryVariables = Types.Exact<{
  rechnungsAusstellerId: Types.Scalars['String']['input'];
  objektId: Types.Scalars['String']['input'];
}>;

export type RechnungsAusstellerBestandseinheitListQuery = {
  getRechnungsAusstellerBestandseinheitList: {
    data: Array<{
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      objektId: string;
      updatedByMitarbeiterId?: string | null;
      status: { text: string; value: Types.BestandseinheitStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RechnungsAusstellerBestandseinheitListDocument = gql`
  query RechnungsAusstellerBestandseinheitList($rechnungsAusstellerId: String!, $objektId: String!) {
    getRechnungsAusstellerBestandseinheitList(rechnungsAusstellerId: $rechnungsAusstellerId, objektId: $objektId) {
      data {
        bestandseinheitId
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        objektId
        status {
          text
          value
        }
        updatedByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRechnungsAusstellerBestandseinheitListQuery(
  baseOptions: Apollo.QueryHookOptions<RechnungsAusstellerBestandseinheitListQuery, RechnungsAusstellerBestandseinheitListQueryVariables> &
    ({ variables: RechnungsAusstellerBestandseinheitListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechnungsAusstellerBestandseinheitListQuery, RechnungsAusstellerBestandseinheitListQueryVariables>(
    RechnungsAusstellerBestandseinheitListDocument,
    options
  );
}
export function useRechnungsAusstellerBestandseinheitListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechnungsAusstellerBestandseinheitListQuery, RechnungsAusstellerBestandseinheitListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechnungsAusstellerBestandseinheitListQuery, RechnungsAusstellerBestandseinheitListQueryVariables>(
    RechnungsAusstellerBestandseinheitListDocument,
    options
  );
}
export function useRechnungsAusstellerBestandseinheitListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RechnungsAusstellerBestandseinheitListQuery, RechnungsAusstellerBestandseinheitListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechnungsAusstellerBestandseinheitListQuery, RechnungsAusstellerBestandseinheitListQueryVariables>(
    RechnungsAusstellerBestandseinheitListDocument,
    options
  );
}
export type RechnungsAusstellerBestandseinheitListQueryHookResult = ReturnType<typeof useRechnungsAusstellerBestandseinheitListQuery>;
export type RechnungsAusstellerBestandseinheitListLazyQueryHookResult = ReturnType<typeof useRechnungsAusstellerBestandseinheitListLazyQuery>;
export type RechnungsAusstellerBestandseinheitListSuspenseQueryHookResult = ReturnType<typeof useRechnungsAusstellerBestandseinheitListSuspenseQuery>;
export type RechnungsAusstellerBestandseinheitListQueryResult = Apollo.QueryResult<
  RechnungsAusstellerBestandseinheitListQuery,
  RechnungsAusstellerBestandseinheitListQueryVariables
>;
