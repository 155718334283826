import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { showSuccessMsgCreate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import FormButtons from '../../../../components/Button/FormButtons';
import { mapFormValuesToPayment, paymentCreateFormFields, paymentCreateFormInitialValues, PaymentCreateFormValues } from './paymentCreateFormMapper';
import { PaymentMethod } from '../../../../types';
import { paymentCreateValidationSchema } from './paymentCreateValidationSchema';
import FormattedDecimalFormInput from '../../../../components/Input-antd/FormattedDecimalFormInput';
import { useCreatePaymentMutation } from '../../gql/Order/PaymentMutations.types';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  belegId: string;
  paymentMethod: PaymentMethod;
  auftragId?: string;
  outstandingAmount?: number | null;
  standingInvoicePartialAmountId?: string | null;
};

const PaymentCreateForm: FC<Props> = ({
  onCancel,
  onSuccess,
  belegId,
  paymentMethod,
  auftragId,
  outstandingAmount,
  standingInvoicePartialAmountId,
}) => {
  const [runCreate, { loading }] = useCreatePaymentMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Zahlung`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<PaymentCreateFormValues>
      initialValues={paymentCreateFormInitialValues(outstandingAmount)}
      validationSchema={paymentCreateValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToPayment(values, belegId, paymentMethod, auftragId, standingInvoicePartialAmountId);
        runCreate({ variables: { input } }).finally(() => setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon name={paymentCreateFormFields.amount} label="Zahlungsbetrag">
            <FormattedDecimalFormInput name={paymentCreateFormFields.amount} isCurrency min={0} />
          </FormItemWithoutColon>
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} />
        </Form>
      )}
    </Formik>
  );
};

export default PaymentCreateForm;
