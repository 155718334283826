import React, { FC } from 'react';
import { View } from '@react-pdf/renderer';
import { Mahnung } from '../../mahnung-types';
import pdfStyles from '../../../styles/pdfStyles';
import ColumnTitles from './ColumnTitles';
import MahnungLineList from './MahnungLineList';
import Summary from './Summary';

const MahnungTableBlock: FC<{ data: Mahnung }> = ({ data }) => (
  <View style={[pdfStyles.column, pdfStyles.tablePaddingLeftRight]}>
    <ColumnTitles titles={data.mahnungBlock.mahnungTableTitles} />

    <MahnungLineList mahnungLineList={data.mahnungBlock.mahnungRowList} />

    <Summary data={data.mahnungBlock} />
  </View>
);

export default MahnungTableBlock;
