import styled from 'styled-components';
import { Skeleton } from 'antd';
import { SkeletonProps } from 'antd/lib/skeleton/Skeleton';

export const StyledSkeletonList = styled(Skeleton)<SkeletonProps>`
  &&& {
    width: 50%;
    margin: 0 auto;
    .ant-skeleton-title {
      width: 50%;
      margin: 0 auto;
      margin-top: 16px;
    }
    .ant-skeleton-paragraph > li {
      width: 100% !important;
    }
  }
`;

export const StyledSkeleton = styled(Skeleton)<SkeletonProps>`
  &&& {
    padding: 16px;
    background: white;
    border-radius: 4px;
  }
`;

export const StyledSkeletonLayout = styled(Skeleton)<SkeletonProps>`
  &&& {
    padding: 16px;
    background: white;
    border-radius: 4px;
  }
`;
