import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { useKontoKlasseListQuery } from '../../gql/KontoKlasseQueries.types';

type Props = {
  name: string;
  onChange: () => void;
} & SelectProps;

const KontoKlasse: FC<Props> = ({ name, onChange, ...restProps }) => {
  const { loading, data } = useKontoKlasseListQuery();

  const kontoKlasseList = data?.getKontoKlasseList?.data ?? [];

  return (
    <FormItemWithoutColon name={name} label="Klasse">
      <Select id={name} name={name} loading={loading} allowClear onChange={onChange} placeholder="Kontoklasse auswählen" {...restProps}>
        {kontoKlasseList.map((kontoklasse) => (
          <Select.Option key={kontoklasse.text} value={kontoklasse.value}>
            {kontoklasse.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithoutColon>
  );
};

export default KontoKlasse;
