import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { FirmendatenBaseFieldsFragmentDoc } from '../../../Firmendaten/gql/FirmendatenFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
export type SepaMandatFieldsFragment = {
  fileId?: string | null;
  fileName?: string | null;
  sepaMandatId: string;
  sepaMandatReference: string;
  signatureDate?: string | null;
  firmendaten?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firmendatenId: string;
    kundenSystemId: string;
    kurzBezeichnung: string;
    name1: string;
    name2?: string | null;
    name3?: string | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  rechnungsAussteller?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
  warningList: Array<{ message: string; type: string }>;
};

export const SepaMandatFieldsFragmentDoc = gql`
  fragment SepaMandatFields on SepaMandat {
    fileId
    fileName
    firmendaten {
      ...FirmendatenBaseFields
    }
    rechnungsAussteller {
      ...RechtstraegerBaseFields
    }
    sepaMandatId
    sepaMandatReference
    signatureDate
    status {
      description
      text
      value
    }
    warningList {
      message
      type
    }
  }
  ${FirmendatenBaseFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
