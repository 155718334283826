import { Layout } from 'antd';
import { FC, PropsWithChildren } from 'react';

type Props = {
  distanceToTopBorder: 'small' | 'large';
};

/**
 * <h2>Usage</h2>
 * <ul>
 *   <li>Content wrapper within Tabs.TabPane</li>
 *   <li>Used for design consistency</li>
 *
 */
const TabPaneContent: FC<Props & PropsWithChildren> = ({ distanceToTopBorder = 'small', children }) => (
  <Layout.Content style={{ backgroundColor: '#fff', padding: distanceToTopBorder === 'large' ? '16px 8px 0' : '0 8px' }}>{children}</Layout.Content>
);

export default TabPaneContent;
