import React, { FC } from 'react';
import { Box, Flex } from 'rebass';
import { Divider, Typography } from 'antd';
import ZuordnungTableHeader from '../shared/ZuordnungTableHeader';
import ZuordnungKontoListViewAndEdit from '../ViewAndEdit/ZuordnungKontoListViewAndEdit';
import { ZuordnungKontoKlasseType, zuordnungListHeight } from '../shared/AbrKreisKontenzuordnungHelpers';
import { AbrKontenzuordnungFiltersFormValues } from './Filters/abrKontenzuordnungFiltersMapper';
import { Konto } from '../../../types';

type Props = {
  einnahmenKontoList: Konto[];
  ausgabenKontoList: Konto[];
  verrechnungList: Konto[];
  isEinnahmenListOpen: boolean;
  isAusgabenListOpen: boolean;
  isVerrechnungListOpen: boolean;
  toggleEinnahmenKontoList: () => void;
  toggleAusgabenList: () => void;
  toggleVerrechnungList: () => void;
  filters?: AbrKontenzuordnungFiltersFormValues | null;
};

const AbrKontenzuordnungKontoListingColumn: FC<Props> = ({
  verrechnungList,
  ausgabenKontoList,
  einnahmenKontoList,
  isAusgabenListOpen,
  toggleAusgabenList,
  isEinnahmenListOpen,
  toggleEinnahmenKontoList,
  isVerrechnungListOpen,
  toggleVerrechnungList,
  filters,
}) => {
  return (
    <Flex>
      <Box width={zuordnungListHeight.left}>
        <ZuordnungTableHeader>
          <Typography.Title level={5} style={{ paddingLeft: '16px' }}>
            Konto
          </Typography.Title>
        </ZuordnungTableHeader>
        <ZuordnungKontoListViewAndEdit
          title="Einnahmen"
          zuordnungKontoKlasseType={ZuordnungKontoKlasseType.EINNAHMEN}
          list={einnahmenKontoList}
          isCollapsed={isEinnahmenListOpen}
          collapse={toggleEinnahmenKontoList}
          keyForCollapse="1"
          filters={filters}
        />
        <Box height="16px" />
        <ZuordnungKontoListViewAndEdit
          title="Ausgaben"
          zuordnungKontoKlasseType={ZuordnungKontoKlasseType.AUSGABEN}
          list={ausgabenKontoList}
          isCollapsed={isAusgabenListOpen}
          collapse={toggleAusgabenList}
          keyForCollapse="2"
          filters={filters}
        />
        <Box height="16px" />
        <ZuordnungKontoListViewAndEdit
          title="Verrechnung"
          zuordnungKontoKlasseType={ZuordnungKontoKlasseType.VERRECHNUNG}
          list={verrechnungList}
          isCollapsed={isVerrechnungListOpen}
          collapse={toggleVerrechnungList}
          keyForCollapse="3"
          filters={filters}
        />
      </Box>
      <Divider style={{ height: '100%', padding: 0, margin: 0 }} type="vertical" />
    </Flex>
  );
};

export default AbrKontenzuordnungKontoListingColumn;
