import React, { FC } from 'react';
import { MenuProps, Modal } from 'antd';
import { EuroCircleOutlined, ExclamationCircleOutlined, HistoryOutlined, PlusOutlined, ToolOutlined } from '@ant-design/icons';
import { showSuccessMsgCreate, showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import {
  useAddKontoForRechtstraegerBankDetailsMutation,
  useSetVerwendungForKontoForRechtstraegerBankDetailsMutation,
} from '../../gql/RechnungsAusstellerMutations.types';
import { BankDetails, BankDetailsFibuVerwendung, Firma, NatuerlichePerson, Personengemeinschaft } from '../../../../../../types';
import { useToggle } from '../../../../../../hooks/useToggle';
import HistoryModal, { HistoryType } from '../../../../../History/shared/HistoryModal';
import { radixActionStyles } from '../../../../../../helpers/radixIconsStyles';
import BankAccountChangeHistoryListingTable from '../BankAccountChangeHistoryListingTable';
import {
  isBankDetailsFibuStatusAngelegt,
  isBankDetailsFibuStatusNichtAngelegt,
  isBankDetailsFibuStatusWirdAngelegt,
} from '../../../../../BankDetails/bankDetailsHelpers';
import AccountLimitFormModal from '../../../../../BankDetails/AccountLimit/AccountLimitFormModal';
import ActionDropdown from '../../../../../../components/Dropdown/ActionDropdown';

type Props = {
  bankDetails: BankDetails;
  rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft;
  onAction: () => void;
};

const RechnungsAusstellerKontoTableActions: FC<Props> = ({ bankDetails, rechtstraeger, onAction }) => {
  const [isVerwendungRepFonds, setIsVerwendungRepFonds] = useToggle(bankDetails.fibuVerwendung.value === BankDetailsFibuVerwendung.RepFonds);
  const [isAccountLimitModalOpen, toggleAccountLimitModalOpen] = useToggle();

  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runCreateKonto] = useAddKontoForRechtstraegerBankDetailsMutation({
    onCompleted: () => {
      onAction();
      showSuccessMsgCreate(`Konto`);
    },
    variables: {
      rechtstraegerId: rechtstraeger.rechtstraegerId,
      bankDetailsId: bankDetails.bankDetailsId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSetKontoVerwendung] = useSetVerwendungForKontoForRechtstraegerBankDetailsMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Verwendung Rep.Fonds ${isVerwendungRepFonds ? 'entfernt' : 'hinzugefügt'}`);
      setIsVerwendungRepFonds();
      onAction();
    },
    variables: {
      rechtstraegerId: rechtstraeger.rechtstraegerId,
      bankDetailsId: bankDetails.bankDetailsId,
      input: {
        repFonds: !isVerwendungRepFonds,
      },
    },
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'FIBU Konto erstellen',
      onClick: () => showConfirm(bankDetails, rechtstraeger, runCreateKonto),
      icon: <PlusOutlined />,
      disabled: isBankDetailsFibuStatusAngelegt(bankDetails.fibuStatus.value) || isBankDetailsFibuStatusWirdAngelegt(bankDetails.fibuStatus.value),
    },
    {
      key: '2',
      label: 'Überziehungsrahmen ändern',
      onClick: toggleAccountLimitModalOpen,
      icon: <EuroCircleOutlined />,
      disabled: !isBankDetailsFibuStatusAngelegt(bankDetails.fibuStatus.value),
    },
    {
      key: '3',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
      disabled: isBankDetailsFibuStatusNichtAngelegt(bankDetails.fibuStatus.value),
    },
    {
      key: '4',
      label: `Verwendung Rep.Fonds ${isVerwendungRepFonds ? 'entfernen' : 'hinzufügen'}`,
      onClick: () => showConfirmChangeKontoVerwendung(runSetKontoVerwendung, isVerwendungRepFonds, bankDetails.accountHolder),
      icon: <ToolOutlined />,
      disabled: isBankDetailsFibuStatusNichtAngelegt(bankDetails.fibuStatus.value),
    },
  ];

  const onActionAccountLimitSuccess = () => {
    onAction();
    toggleAccountLimitModalOpen();
  };

  return (
    <>
      <ActionDropdown items={items} />
      <AccountLimitFormModal
        isAccountLimitModalOpen={isAccountLimitModalOpen}
        onSuccess={onActionAccountLimitSuccess}
        onCancel={toggleAccountLimitModalOpen}
        bankDetailsId={bankDetails.bankDetailsId}
        accountLimit={bankDetails.accountLimit}
        rechtstraegerId={rechtstraeger.rechtstraegerId}
        fibuKontonummer={bankDetails.fibuKontonummer}
      />
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <BankAccountChangeHistoryListingTable rechtstraegerId={rechtstraeger.rechtstraegerId} bankDetailsId={bankDetails.bankDetailsId} />
      </HistoryModal>
    </>
  );
};

const showConfirm = (bankDetails: BankDetails, rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft, runCreateKonto: () => void) => {
  Modal.confirm({
    title: `Möchten Sie ein FIBU Konto erstellen?`,
    icon: <ExclamationCircleOutlined />,
    content: `${rechtstraeger.kurzBezeichnung} – ${bankDetails.accountHolder}`,
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return runCreateKonto();
    },
  });
};

const showConfirmChangeKontoVerwendung = (runSetKontoVerwendung: () => Promise<unknown>, isVerwendungRepFonds: boolean, kontoBezeichnung: string) =>
  Modal.confirm({
    title: `Möchten Sie Verwendung Rep.Fonds ${isVerwendungRepFonds ? 'entfernen' : 'hinzufügen'}?`,
    icon: <ExclamationCircleOutlined />,
    content: `${kontoBezeichnung}`,
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return runSetKontoVerwendung();
    },
  });

export default RechnungsAusstellerKontoTableActions;
