import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import AndromedaSysSettingsAufgabenartPage from './AndromedaSysSettingsAufgabenartPage';
import { isAdmin } from '../../../../security/permissionChecks';

const andromedaSysSettingsAufgabenartPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.aufgabenartPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsAufgabenartPage />} />}
  />
);

export default andromedaSysSettingsAufgabenartPageRoute;
