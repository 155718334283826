import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { RepFondsAbrechnungUebersicht } from '../../topAbrechnung-types';

const TARepFondsAbrechnungUebersichtTable: FC<{ uebersicht: RepFondsAbrechnungUebersicht }> = ({ uebersicht }) => (
  <View>
    <View style={pdfStyles.row}>
      <Text style={pdfStyles.textBulletin}>{uebersicht.saldoVortragText}</Text>
      <Text style={[pdfStyles.textBulletin, { width: 'auto' }]}>{uebersicht.saldoVortrag}</Text>
    </View>
    <View style={pdfStyles.row}>
      <Text style={pdfStyles.textBulletin}>{uebersicht.ausgabenText}</Text>
      <Text style={[pdfStyles.textBulletin, { width: 'auto' }]}>{uebersicht.ausgaben}</Text>
    </View>
    <View style={pdfStyles.row}>
      <Text style={pdfStyles.textBulletin}>{uebersicht.einnahmenText}</Text>
      <Text style={[pdfStyles.textBulletin, { width: 'auto' }]}>{uebersicht.einnahmen}</Text>
    </View>
    <View style={pdfStyles.row}>
      <Text style={pdfStyles.textBulletin}>{uebersicht.saldoText}</Text>
      <Text style={[pdfStyles.textBulletin, { width: 'auto' }]}>{uebersicht.saldo}</Text>
    </View>

    <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { marginBottom: '1mm' }]} />
  </View>
);

export default TARepFondsAbrechnungUebersichtTable;
