import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { useFilterBuchungVertragspartnerListQuery } from '../../BookingDetails/gql/BuchungQueries.types';

type Props = {
  rechtstraegerIdList?: string[] | null;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'showSearch'>;

const FibuBuchungVertragspartnerListFormSelect: FC<Props> = ({ rechtstraegerIdList, ...restProps }) => {
  const { loading, data } = useFilterBuchungVertragspartnerListQuery({
    variables: {
      rechtstraegerIdList,
    },
  });
  const list = data?.getFilterBuchungVertragspartnerList.data ?? [];

  return (
    <Select
      size="middle"
      placeholder="Vertragspartner auswählen"
      {...restProps}
      id={restProps.name}
      loading={loading}
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((vp) => (
        <Select.Option key={vp.rechtstraegerId} value={vp.rechtstraegerId}>
          <DataWithShortenedText maxTextLength={48} text={vp.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default FibuBuchungVertragspartnerListFormSelect;
