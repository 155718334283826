import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../../hooks/useToggle';
import BelegnummernkreisForm from './Form/BelegnummernkreisForm';
import ListingTitleWithButtons from '../../../../../components/Listing/ListingTitleWithButtons';
import { BelegnummernkreisDefinitionFieldsFragment } from '../../../../BelegnummernkreisDefinition/gql/BelegnummernkreisDefinitionFragments.types';

type RechnungsAusstellerBelegnummernkreisBtnAndDrawerProps = {
  onSuccess: () => void;
  rechtstraegerId: string;
  belegnummernkreisDefinitionList: BelegnummernkreisDefinitionFieldsFragment[];
};

const RechnungsAusstellerBelegnummernkreisBtnAndDrawer: FC<RechnungsAusstellerBelegnummernkreisBtnAndDrawerProps> = ({
  rechtstraegerId,
  onSuccess,
  belegnummernkreisDefinitionList,
}) => {
  const [isCollapsed, onCollapse] = useToggle();

  const onActionSuccess = () => {
    onCollapse();
    onSuccess();
  };

  return (
    <>
      <ListingTitleWithButtons
        title="Belegnummernkreisdefinition"
        buttons={
          <Button icon={<PlusOutlined />} type="primary" onClick={onCollapse} disabled={belegnummernkreisDefinitionList.length > 0}>
            Belegnummernkreis Definition anlegen
          </Button>
        }
      />
      <Drawer title="Neue Belegnummernkreis Definition anlegen" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <BelegnummernkreisForm onSuccess={onActionSuccess} rechtstraegerId={rechtstraegerId} onCancel={onCollapse} />
      </Drawer>
    </>
  );
};

export default RechnungsAusstellerBelegnummernkreisBtnAndDrawer;
