import React, { FC, useContext } from 'react';
import { Empty } from 'antd';
import indexedValueTableColumns from './indexedValueTableColumns';
import { useVertragVposIndexedValueListQuery } from '../../../../../../IndexedValue/gql/VertragVposIndexedValueQueries.types';
import { IndexationAgreementContext } from '../Level2/IndexationAgreementContext';
import { VertragVposIndexedValue } from '../../../../../../../types';
import {
  mapFormValuesToVertragVposIndexedValue,
  mapVertragVposIndexedValueToFormValues,
  VertragVposIndexedValueFormValues,
} from './indexedValueMapper';
import { useUpdateVertragVposIndexedValueMutation } from '../../../../../../IndexedValue/gql/VertragVposIndexedValueMutations.types';
import { showSuccessMsgUpdate, showWarningMessage } from '../../../../../../../components/message';
import { useOnIndexedValueDetailsDataChangedEvents } from '../../../../../../IndexedValue/useOnIndexedValueDataChangedEvents';
import EditableTable from '../../../../../../../components/Table/EditableTableV2/EditableTable';

type Props = {
  indexationAgreementId: string;
};

const IndexedValueTable: FC<Props> = ({ indexationAgreementId }) => {
  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);

  const { data, loading, refetch } = useVertragVposIndexedValueListQuery({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      vorschreibungspositionId,
      indexationAgreementId,
    },
  });

  const indexedValueList = data?.getVertragVposIndexedValueList.data ?? [];

  useOnIndexedValueDetailsDataChangedEvents('indexedValue' + indexationAgreementId, refetch, indexationAgreementId);

  const [updateValue] = useUpdateVertragVposIndexedValueMutation();

  return (
    <EditableTable<VertragVposIndexedValue, VertragVposIndexedValueFormValues>
      level={3}
      colWidth={150}
      getRowKey={(record) => record.vertragVposIndexedValueId}
      expanded //FIXME
      locale={{
        emptyText: <Empty description="Keine Wertsicherungsvereinbarungen vorhanden" />,
      }}
      loading={loading}
      dataSource={indexedValueList}
      columns={indexedValueTableColumns(vorschreibungspositionId, refetch)}
      mapToFormValues={mapVertragVposIndexedValueToFormValues}
      useHighlighting
      onSubmit={(formValues) => {
        return updateValue({
          variables: {
            objektId,
            bestandseinheitId,
            vertragId,
            vorschreibungspositionId,
            indexationAgreementId,
            indexedValueId: formValues.vertragVposIndexedValueId,
            input: mapFormValuesToVertragVposIndexedValue(formValues),
          },
        })
          .then((response) => {
            showSuccessMsgUpdate(`Wert`);
            refetch();
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return response.data!.updateVertragVposIndexedValue.data;
          })
          .catch((error) => {
            showWarningMessage(error, 'Wert wurde nicht aktualisiert');
            // error has to be thrown to reject promise
            throw error;
          });
      }}
    />
  );
};

export default IndexedValueTable;
