import React, { useEffect, useState } from 'react';
import { chunk } from 'lodash';
import { isNotNil } from '../../helpers/assertionHelper';

type Props = {
  defaultSelectedFilterList?: string[] | null;
  filters: (filter: string, selectedFilterList: string[]) => React.ReactNode;
  labelList: Record<string, string>;
  mapFilterLabelToQueryFilterList?: (filter: string) => string;
  updateFilterValues?: (selectedFilterList: string[]) => void;
  queryParams: Record<string, any | null>;
};

const useFilterWithQueryParams = ({
  defaultSelectedFilterList,
  queryParams,
  labelList,
  mapFilterLabelToQueryFilterList,
  filters,
  updateFilterValues,
}: Props) => {
  const defaultSelectedList = defaultSelectedFilterList ?? [];

  const queryFilterList = (Object.keys(queryParams) as Array<keyof typeof queryParams>)
    .filter((key) => isNotNil(queryParams[key]))
    .map((filter) => mapFilterLabelToQueryFilterList?.(filter) ?? filter);

  const uniqueFilterList = Array.from(new Set(queryFilterList)).filter((key) => Object.keys(labelList).includes(key));

  const [selectedFilterList, setSelectedFilterList] = useState<string[]>(uniqueFilterList.length >= 1 ? uniqueFilterList : defaultSelectedList);

  const filterList = selectedFilterList.map((key) => filters(key, selectedFilterList)).filter((x) => !!x);

  const chunkFilterList = chunk(filterList, Math.round(filterList.length / 2));

  // Use useEffect in order to update query params when a filter gets removed from selected filters
  useEffect(() => {
    updateFilterValues?.(selectedFilterList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  return { selectedFilterList, setSelectedFilterList, leftColumn: chunkFilterList[0], rightColumn: chunkFilterList[1] };
};

export default useFilterWithQueryParams;
