import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSubAdministrationAbrechnungsdefinitionForKsMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  input: Types.SubAdministrationAbrechnungsdefinitionCreateInput;
}>;

export type CreateSubAdministrationAbrechnungsdefinitionForKsMutation = {
  createSubAdministrationAbrechnungsdefinitionForKS: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateSubAdministrationAbrechnungsdefinitionForFdMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  input: Types.SubAdministrationAbrechnungsdefinitionCreateInput;
}>;

export type CreateSubAdministrationAbrechnungsdefinitionForFdMutation = {
  createSubAdministrationAbrechnungsdefinitionForFD: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateSubAdministrationAbrechnungsdefinitionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  input: Types.SubAdministrationAbrechnungsdefinitionUpdateInput;
}>;

export type UpdateSubAdministrationAbrechnungsdefinitionMutation = {
  updateSubAdministrationAbrechnungsdefinition: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateSubAdministrationAbrechnungsdefinitionForKsDocument = gql`
  mutation CreateSubAdministrationAbrechnungsdefinitionForKS($kundenSystemId: ID!, $input: SubAdministrationAbrechnungsdefinitionCreateInput!) {
    createSubAdministrationAbrechnungsdefinitionForKS(kundenSystemId: $kundenSystemId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateSubAdministrationAbrechnungsdefinitionForKsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubAdministrationAbrechnungsdefinitionForKsMutation,
    CreateSubAdministrationAbrechnungsdefinitionForKsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSubAdministrationAbrechnungsdefinitionForKsMutation,
    CreateSubAdministrationAbrechnungsdefinitionForKsMutationVariables
  >(CreateSubAdministrationAbrechnungsdefinitionForKsDocument, options);
}
export type CreateSubAdministrationAbrechnungsdefinitionForKsMutationHookResult = ReturnType<
  typeof useCreateSubAdministrationAbrechnungsdefinitionForKsMutation
>;
export type CreateSubAdministrationAbrechnungsdefinitionForKsMutationResult =
  Apollo.MutationResult<CreateSubAdministrationAbrechnungsdefinitionForKsMutation>;
export type CreateSubAdministrationAbrechnungsdefinitionForKsMutationOptions = Apollo.BaseMutationOptions<
  CreateSubAdministrationAbrechnungsdefinitionForKsMutation,
  CreateSubAdministrationAbrechnungsdefinitionForKsMutationVariables
>;
export const CreateSubAdministrationAbrechnungsdefinitionForFdDocument = gql`
  mutation CreateSubAdministrationAbrechnungsdefinitionForFD($firmendatenId: ID!, $input: SubAdministrationAbrechnungsdefinitionCreateInput!) {
    createSubAdministrationAbrechnungsdefinitionForFD(firmendatenId: $firmendatenId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateSubAdministrationAbrechnungsdefinitionForFdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubAdministrationAbrechnungsdefinitionForFdMutation,
    CreateSubAdministrationAbrechnungsdefinitionForFdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSubAdministrationAbrechnungsdefinitionForFdMutation,
    CreateSubAdministrationAbrechnungsdefinitionForFdMutationVariables
  >(CreateSubAdministrationAbrechnungsdefinitionForFdDocument, options);
}
export type CreateSubAdministrationAbrechnungsdefinitionForFdMutationHookResult = ReturnType<
  typeof useCreateSubAdministrationAbrechnungsdefinitionForFdMutation
>;
export type CreateSubAdministrationAbrechnungsdefinitionForFdMutationResult =
  Apollo.MutationResult<CreateSubAdministrationAbrechnungsdefinitionForFdMutation>;
export type CreateSubAdministrationAbrechnungsdefinitionForFdMutationOptions = Apollo.BaseMutationOptions<
  CreateSubAdministrationAbrechnungsdefinitionForFdMutation,
  CreateSubAdministrationAbrechnungsdefinitionForFdMutationVariables
>;
export const UpdateSubAdministrationAbrechnungsdefinitionDocument = gql`
  mutation UpdateSubAdministrationAbrechnungsdefinition($abrechnungsdefinitionId: ID!, $input: SubAdministrationAbrechnungsdefinitionUpdateInput!) {
    updateSubAdministrationAbrechnungsdefinition(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateSubAdministrationAbrechnungsdefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubAdministrationAbrechnungsdefinitionMutation,
    UpdateSubAdministrationAbrechnungsdefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubAdministrationAbrechnungsdefinitionMutation, UpdateSubAdministrationAbrechnungsdefinitionMutationVariables>(
    UpdateSubAdministrationAbrechnungsdefinitionDocument,
    options
  );
}
export type UpdateSubAdministrationAbrechnungsdefinitionMutationHookResult = ReturnType<
  typeof useUpdateSubAdministrationAbrechnungsdefinitionMutation
>;
export type UpdateSubAdministrationAbrechnungsdefinitionMutationResult = Apollo.MutationResult<UpdateSubAdministrationAbrechnungsdefinitionMutation>;
export type UpdateSubAdministrationAbrechnungsdefinitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubAdministrationAbrechnungsdefinitionMutation,
  UpdateSubAdministrationAbrechnungsdefinitionMutationVariables
>;
