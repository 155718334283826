import { FC, useContext } from 'react';
import { Formik } from 'formik';
import { Divider } from 'antd';
import { Form } from 'formik-antd';
import { IndexationAgreementContext } from '../../IndexationAgreementContext';
import { showSuccessMsgCreate } from '../../../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../../../helpers/apolloHelper';
import {
  useCreateVertragVposIndexationAgreementReferenceValueMutation,
  useUpdateVertragVposIndexationAgreementReferenceValueMutation,
} from '../../../../gql/VPosIndexationAgreementMutations.types';
import {
  indAgreeReferenceValueFormFields,
  IndexationAgreementReferenceValueFormValues,
  mapFormValuesToReferenceValue,
  mapReferenceValueToFormValues,
} from './referenceValueFormMapper';
import { referenceValueFormValidationSchema } from './referenceValueFormValidationSchema';
import FormButtons from '../../../../../../../../../components/Button/FormButtons';
import IndexBlock from './IndexBlock';
import { ValuePreservationType, VertragVposIndexationAgreementReferenceValue } from '../../../../../../../../../types';
import { useSetIndexOld } from '../shared/useSetIndexOld';
import LastValueBaseBlock from '../shared/LastValueBaseBlock';
import { useGetAppFieldHelpText } from '../../../../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  indexationAgreement?: VertragVposIndexationAgreementReferenceValue;
  onCancel: () => void;
  onSuccess: () => void;
};

const ReferenceValueForm: FC<Props> = ({ indexationAgreement, onCancel, onSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'VertragVposIndexationAgreementReferenceValue'>('VertragVposIndexationAgreementReferenceValue');

  const isUpdate = !!indexationAgreement;
  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);
  const entity = 'Richtwert';

  const [runCreate, { loading: loadingCreate }] = useCreateVertragVposIndexationAgreementReferenceValueMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateVertragVposIndexationAgreementReferenceValueMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const { handleIndexSeriesChange, handleMonthYearChange } = useSetIndexOld();

  return (
    <Formik<IndexationAgreementReferenceValueFormValues>
      initialValues={mapReferenceValueToFormValues(indexationAgreement)}
      validationSchema={referenceValueFormValidationSchema}
      onSubmit={(values, formikHelpers) => {
        isUpdate
          ? runUpdate({
              variables: {
                objektId,
                bestandseinheitId,
                vertragId,
                vorschreibungspositionId,
                indexationAgreementId: indexationAgreement.vertragVposIndexationAgreementId,
                input: mapFormValuesToReferenceValue(values),
              },
            }).finally(() => formikHelpers.setSubmitting(false))
          : runCreate({
              variables: {
                objektId,
                bestandseinheitId,
                vertragId,
                vorschreibungspositionId,
                input: mapFormValuesToReferenceValue(values),
              },
            }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <IndexBlock
            onIndexSeriesChange={(indexSeriesId) => handleIndexSeriesChange(indAgreeReferenceValueFormFields.indexOld, indexSeriesId, formikProps)}
            fieldHelpNames={{
              validFrom: getFieldHelpText('VertragVposIndexationAgreementReferenceValue.validFrom.year'),
              indexSeriesId: getFieldHelpText('VertragVposIndexationAgreementReferenceValue.indexSeriesId'),
            }}
          />
          <Divider />
          <LastValueBaseBlock
            monthYearName={indAgreeReferenceValueFormFields.monthYearOld}
            onMonthYearChange={(month, year) => handleMonthYearChange(indAgreeReferenceValueFormFields.indexOld, month, year, formikProps)}
            indexOldName={indAgreeReferenceValueFormFields.indexOld}
            valuePreservationType={ValuePreservationType.ReferenceValue}
            fieldHelpNames={{
              indexOld: getFieldHelpText('VertragVposIndexationAgreementReferenceValue.indexOld'),
              monthYearOld: getFieldHelpText('VertragVposIndexationAgreementReferenceValue.monthYearOld.year'),
            }}
          />
          <FormButtons
            updateMode={isUpdate}
            isOkLoading={loadingCreate || loadingUpdate}
            isOkDisabled={formikProps.isSubmitting}
            onCancel={onCancel}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ReferenceValueForm;
