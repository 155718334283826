import React, { FC } from 'react';
import { Col, Row } from 'antd';
import BookingGeneralButtonAndWarning from './BookingGeneralButtonAndWarning';
import { FibuBelegSymbolTuple, FibuBuchungTypeTuple } from '../../../../types';
import GeneralInfoBlockContent from '../shared/GeneralInfoBlockContent/GeneralInfoBlockContent';

type Props = {
  belegSymbol: FibuBelegSymbolTuple;
  bookingSuggestionId: string;
  buchungType: FibuBuchungTypeTuple;
  buchungskreisRechtstraegerId: string;
  bookingCorrectionInProgress: boolean | 'done';
  isEditAllowed: boolean;
  toggleIsUpdate: () => void;
};

const GeneralInfoBlockWithEditButtonOrWarning: FC<Props> = ({
  belegSymbol,
  bookingSuggestionId,
  buchungType,
  buchungskreisRechtstraegerId,
  bookingCorrectionInProgress,
  isEditAllowed,
  toggleIsUpdate,
}) => {
  return (
    <Row align="stretch" style={{ marginTop: '24px' }}>
      <Col span={18}>
        <GeneralInfoBlockContent belegSymbol={belegSymbol} buchungType={buchungType} buchungskreisRechtstraegerId={buchungskreisRechtstraegerId} />
      </Col>
      <Col span={6}>
        <BookingGeneralButtonAndWarning
          bookingSuggestionId={bookingSuggestionId}
          bookingCorrectionInProgress={bookingCorrectionInProgress}
          isEditAllowed={isEditAllowed}
          toggleIsUpdate={toggleIsUpdate}
        />
      </Col>
    </Row>
  );
};

export default GeneralInfoBlockWithEditButtonOrWarning;
