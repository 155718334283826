import React, { FC, PropsWithChildren } from 'react';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../helpers/apolloHelper';
import { showSuccessMsgCreate, showSuccessMsgDelete, showSuccessMsgOther, showSuccessMsgUpdate } from '../../components/message';

import {
  CreateTicketTemplateTemplateMutationHookResult,
  DeleteTicketTemplateTemplateMutationHookResult,
  TicketTemplateTemplateChangePositionMutationHookResult,
  TicketTemplateTemplateCreateSubTicketTemplateMutationHookResult,
  UpdateTicketTemplateTemplateMutationHookResult,
  useCreateTicketTemplateTemplateMutation,
  useDeleteTicketTemplateTemplateMutation,
  useTicketTemplateTemplateChangePositionMutation,
  useTicketTemplateTemplateCreateSubTicketTemplateMutation,
  useUpdateTicketTemplateTemplateMutation,
} from './gql/TicketTemplateTemplateMutations.types';
import createCtx from '../../helpers/createCtx';

export type TicketTemplateTemplateMutationHookResults = {
  runDelete: DeleteTicketTemplateTemplateMutationHookResult;
  runCreate: CreateTicketTemplateTemplateMutationHookResult;
  runUpdate: UpdateTicketTemplateTemplateMutationHookResult;
  runCreateSubTicketTemplate: TicketTemplateTemplateCreateSubTicketTemplateMutationHookResult;
  runChangePosition: TicketTemplateTemplateChangePositionMutationHookResult;
};

type Props = {
  refetch: () => void;
};

const [useMutationHookResultsProvider, MutationHookResultsContextProvider] = createCtx<TicketTemplateTemplateMutationHookResults>();

const TicketTemplateTemplateMutationHookResultProvider: FC<Props & PropsWithChildren> = ({ refetch, children }) => {
  const entity = `Aufgabenart`;

  const runCreate = useCreateTicketTemplateTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const runCreateSubTicketTemplate = useTicketTemplateTemplateCreateSubTicketTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const runChangePosition = useTicketTemplateTemplateChangePositionMutation({
    onCompleted: (data) => {
      const ticketTemplate = data.actionTicketTemplateTemplateChangePosition.data;
      showSuccessMsgOther(`Die Position der ${entity} ${ticketTemplate.name} hat sich geändert`);

      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const runUpdate = useUpdateTicketTemplateTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const runDelete = useDeleteTicketTemplateTemplateMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <MutationHookResultsContextProvider value={{ runCreate, runUpdate, runDelete, runCreateSubTicketTemplate, runChangePosition }}>
      {children}
    </MutationHookResultsContextProvider>
  );
};

export { MutationHookResultsContextProvider, useMutationHookResultsProvider };

export default TicketTemplateTemplateMutationHookResultProvider;
