import { RcFile } from 'antd/lib/upload/interface';
import { FormFields } from '../../../../helpers/formikHelper';

export type TicketSyncAttachmentsInput = {
  deletableIds: string[];
};

export type TicketAttachmentFileData = {
  filename: string;
  file: RcFile;
  isUpload: boolean;
  ts: string;
};

// Unified type from uploader and stored attachments
export type TicketUnifiedAttachment = {
  fileId?: string;
  name: string;
  size: number;
  file?: File;
};

export type TicketAttachmentsFormValues = {
  deletableIds: string[];
  newAttachmentList: TicketAttachmentFileData[];
};

export const ticketAttachmentsFormFields: FormFields<TicketAttachmentsFormValues> = {
  deletableIds: 'deletableIds',
  newAttachmentList: 'newAttachmentList',
};

export const ticketAttachmentsFormInitialValues: TicketAttachmentsFormValues = {
  deletableIds: [],
  newAttachmentList: [],
};

export const mapFormValuesToTicketAttachment = (formValues: TicketAttachmentsFormValues) => ({
  deletableIds: formValues.deletableIds,
});
