import React from 'react';
import { Route } from 'react-router';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import AuthRoute from '../../security/AuthRoute';
import WeTopAbrechnungDetailsPage from './WeTopAbrechnungDetailsPage';
import { URI_WE_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE } from '../../features/Abrechnung/BkOrWe/weAbrechnungUriPaths';

export type WeTopAbrechnungDetailsPageRouteParams = { objektId: string; objektAbrechnungId: string; topAbrechnungId: string };

const weTopAbrechnungDetailsPageRoute = (
  <Route
    path={`${URI_WE_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<WeTopAbrechnungDetailsPage />} />}
  />
);

export default weTopAbrechnungDetailsPageRoute;
