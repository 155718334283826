import { OrderPaymentStatus, PaymentMethod, PaymentTransactionType, PaymentType, Verrechnungsart } from '../../types';
import { EmployeeApprovalAll, TEmployeeApproval } from './Listing/Filters/paymentListingFiltersFormMapper';

export const isPaymentMethodLastschrift = (zahlungsart?: PaymentMethod | null) => zahlungsart === PaymentMethod.Lastschrift;
export const isPaymentMethodUeberweisung = (zahlungsart?: PaymentMethod | null) => zahlungsart === PaymentMethod.Ueberweisung;
export const isPaymentMethodKeine = (zahlungsart?: PaymentMethod | null) => zahlungsart === PaymentMethod.Keine;

export const isBillingTypeDauerrechnung = (verrechnungsart?: Verrechnungsart | null) => verrechnungsart === Verrechnungsart.Dauerrechnung;
export const isBillingTypeDauerrechnungJaehrlich = (verrechnungsart?: Verrechnungsart | null) =>
  verrechnungsart === Verrechnungsart.DauerrechnungJaehrlich;
export const isBillingTypeEinzelrechnung = (verrechnungsart?: Verrechnungsart | null) => verrechnungsart === Verrechnungsart.Einzelrechnung;

export const isPaymentTypeAusgangsrechnung = (paymentType: PaymentType) => paymentType === PaymentType.Ausgangsrechnung;
export const isPaymentTypeEingangsrechnung = (paymentType: PaymentType) => paymentType === PaymentType.Eingangsrechnung;
export const isPaymentTypeHeAuszahlung = (paymentType: PaymentType) => paymentType === PaymentType.HeAuszahlung;

export const isPaymentTransactionTypeEbics = (paymentTransactionType?: PaymentTransactionType) =>
  paymentTransactionType === PaymentTransactionType.Ebics;

export const isPaymentTransactionTypeOnlineBanking = (paymentTransactionType?: PaymentTransactionType) =>
  paymentTransactionType === PaymentTransactionType.OnlineBanking;

export const isPaymentStatusDurchfuehrbar = (status: OrderPaymentStatus) => status === OrderPaymentStatus.Durchfuehrbar;
export const isPaymentStatusErstellt = (status: OrderPaymentStatus) => status === OrderPaymentStatus.Erstellt;
export const isPaymentStatusNichtDurchfuehrbar = (status: OrderPaymentStatus) => status === OrderPaymentStatus.NichtDurchfuehrbar;
export const isPaymentStatusNichtDurchgefuehrt = (status: OrderPaymentStatus) => status === OrderPaymentStatus.NichtDurchgefuehrt;
export const isPaymentStatusPaymentProposal = (status: OrderPaymentStatus) => status === OrderPaymentStatus.PaymentProposal;
export const isPaymentStatusDatentraegerErzeugt = (status: OrderPaymentStatus) => status === OrderPaymentStatus.DatentraegerErzeugt;

export const isPaymentEmployeeApprovalAll = (employeeApproval?: TEmployeeApproval) => employeeApproval === EmployeeApprovalAll.All;
