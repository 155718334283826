import { isEmpty } from 'lodash';
import { VorschreibungspositionBasis, VorschreibungspositionWertInput } from '../../../../../../../types';
import { FormFields, mapDateStringToFormDateValue, mapFormDateValueToDateString, TFormattedDecimal } from '../../../../../../../helpers/formikHelper';
import { mapFormattedDecimalOrThrowIfEmpty } from '../../../../../../../components/Input-antd/formattedDecimalHelper';
import { dayjsCustom } from '../../../../../../../helpers/dayjsCustom';
import { BeVertragVorschreibungsPositionWertFieldsFragment } from '../../gql/BeVertragVorschreibungspositionFragments.types';

export type VorschreibungspositionWertFormValues = {
  vertragVorschreibungspositionWertId: string;
  validFrom: string;
  basis: VorschreibungspositionBasis;
  netto: TFormattedDecimal;
  steuersatz: number;
  ust: number;
  brutto: TFormattedDecimal;
  isLoadingSteuersatz: boolean;
};

export const vorschreibungsPositionWertFormFields: FormFields<VorschreibungspositionWertFormValues> = {
  vertragVorschreibungspositionWertId: 'vertragVorschreibungspositionWertId',
  validFrom: 'validFrom',
  basis: 'basis',
  netto: 'netto',
  steuersatz: 'steuersatz',
  ust: 'ust',
  brutto: 'brutto',
  isLoadingSteuersatz: 'isLoadingSteuersatz',
};

export const mapVorschreibungsPositionWertToFormValues = (
  vpWert: BeVertragVorschreibungsPositionWertFieldsFragment
): VorschreibungspositionWertFormValues => ({
  vertragVorschreibungspositionWertId: vpWert.vertragVorschreibungspositionWertId,
  validFrom: vpWert.validFrom,
  basis: vpWert.basis.value,
  netto: vpWert.netto,
  steuersatz: vpWert.steuersatz,
  ust: vpWert.ust,
  brutto: vpWert.brutto,
  isLoadingSteuersatz: true,
});

export const createDefaultNewEntryForVorschreibungsPositionWert = (
  vorschreibungsPositionWertList: BeVertragVorschreibungsPositionWertFieldsFragment[],
  vertragsBeginn: string
): BeVertragVorschreibungsPositionWertFieldsFragment => {
  if (!isEmpty(vorschreibungsPositionWertList)) {
    return {
      ...vorschreibungsPositionWertList[0],
      current: false,
      validFrom: findNextValidFrom(vorschreibungsPositionWertList).toISOString(),
      steuersatz: 0,
      vertragVorschreibungspositionWertId: '',
    };
  }
  return {
    createTs: '',
    current: false,
    validFrom: vertragsBeginn,
    basis: {
      value: VorschreibungspositionBasis.Netto,
      text: 'Netto',
    },
    netto: 0,
    steuersatz: 0,
    ust: 0,
    brutto: 0,
    deletable: false,
    vertragVorschreibungspositionWertId: '',
    warningList: [
      {
        attribute: '',
        message: '',
        type: '',
      },
    ],
  };
};

/**
 * Returns
 *  - next available month if latest valid ab of vorschreibungsPositionWertList is this month or later
 *  - this month if
 *    - latest valid ab of vorschreibungsPositionWertList is before this month or
 *    - vorschreibungsPositionWertList is empty
 */
const findNextValidFrom = (vorschreibungsPositionWertList: BeVertragVorschreibungsPositionWertFieldsFragment[]) => {
  const validFromList = vorschreibungsPositionWertList.map((wert) => mapDateStringToFormDateValue(wert.validFrom ? wert.validFrom : ''));
  const latestValidFrom = dayjsCustom.max(validFromList);
  return latestValidFrom?.isSameOrAfter(dayjsCustom(), 'month') ? latestValidFrom.add(1, 'month').startOf('month') : dayjsCustom();
};

export const mapFormValuesToVorschreibungsPositionWert = (
  vorschreibungsPositionWertList: BeVertragVorschreibungsPositionWertFieldsFragment[],
  values: VorschreibungspositionWertFormValues,
  vertragsBeginn: string
): VorschreibungspositionWertInput => {
  const wertForValidFrom = mapFormDateValueToDateString(
    isVertragsBeginAfterChosenValidFrom(vertragsBeginn, values.validFrom)
      ? vertragsBeginn
      : getDateOrStartOfMonthDate(vorschreibungsPositionWertList, values.validFrom)
  );

  const wert = values.basis === VorschreibungspositionBasis.Netto ? values.netto : values.brutto;
  return {
    validFrom: wertForValidFrom,
    wert: mapFormattedDecimalOrThrowIfEmpty(wert),
    basis: values.basis,
    errechneteUst: values.ust,
  };
};

const isVertragsBeginAfterChosenValidFrom = (vertragsBeginn: string, validFrom: string) =>
  dayjsCustom(vertragsBeginn).isSameOrAfter(mapFormDateValueToDateString(validFrom));

const getDateOrStartOfMonthDate = (vorschreibungsPositionWertList: BeVertragVorschreibungsPositionWertFieldsFragment[], validFrom: string) => {
  const validFromList = vorschreibungsPositionWertList.map((wert) => wert.validFrom);
  const isSameDateUsed = validFromList.some((wert) => wert === validFrom);
  return isSameDateUsed ? validFrom : getValidFromStarfOfTheMonth(validFrom);
};

const getValidFromStarfOfTheMonth = (validFrom: string) => dayjsCustom(validFrom).startOf('month');
