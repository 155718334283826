import { ParsedQueryValueType, mapToQueryString } from '../../../hooks/useStringQueryParams';

export const generateQueryStringsForDocumentFilter = (
  documentType?: ParsedQueryValueType,
  invoiceIssuerId?: ParsedQueryValueType,
  invoiceRecipientId?: ParsedQueryValueType,
  invoiceNumber?: ParsedQueryValueType,
  invoiceDateFromInclusive?: ParsedQueryValueType,
  invoiceDateToInclusive?: ParsedQueryValueType,
  dunningReferenceDateFromInclusive?: ParsedQueryValueType,
  dunningReferenceDateToInclusive?: ParsedQueryValueType,
  homeownerId?: ParsedQueryValueType,
  objektId?: ParsedQueryValueType,
  bestandseinheitId?: ParsedQueryValueType,
  budgetingYear?: ParsedQueryValueType,
  currentPage?: string,
  pageSize?: string
) => {
  const searchString = mapToQueryString({
    documentType,
    invoiceIssuerId,
    invoiceRecipientId,
    invoiceNumber,
    invoiceDateFromInclusive,
    invoiceDateToInclusive,
    dunningReferenceDateFromInclusive,
    dunningReferenceDateToInclusive,
    homeownerId,
    objektId,
    bestandseinheitId,
    budgetingYear,
    currentPage,
    pageSize,
  });
  return `?${searchString}`;
};
