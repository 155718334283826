import styled from 'styled-components';

export const StyledTableAufwandAufteilungRows = styled('table')`
  thead {
    background-color: #fafafa;
    th {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      padding: 4px 4px;
      &:first-child {
        width: 5.876%;
        &::before {
          content: unset !important;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: left;
        justify-content: flex-end;
      }
      &:last-child td {
        border: 0;
      }
      .ant-empty {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
`;
