import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { findEmailDeliveryLabel } from '../emailDeliveryHelpers';
import InfoMailDeliveryDefinition from './InfoMailDeliveryDefinition';
import EmailSubjectBody from '../shared/EmailSubjectBody';
import { MailData } from '../../../types';

type EmailDeliveryEmailViewProps = {
  emailDelivery: MailData;
};

const InfoMailDeliveryEmailView: FC<EmailDeliveryEmailViewProps> = ({ emailDelivery }) => {
  const { value: infoMailDeliveryId } = findEmailDeliveryLabel(emailDelivery, 'infoMailDeliveryId') || {};
  if (!infoMailDeliveryId) return null;

  return (
    <>
      <Typography.Title level={5}>E-Mail</Typography.Title>
      <Space direction="vertical" style={{ marginTop: '20px' }}>
        <InfoMailDeliveryDefinition infoMailDeliveryId={infoMailDeliveryId} />
        <EmailSubjectBody emailDelivery={emailDelivery} style={{ marginTop: '20px' }} />
      </Space>
    </>
  );
};

export default InfoMailDeliveryEmailView;
