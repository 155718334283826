import React, { FC } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { FormikProps } from 'formik';
import { Switch } from 'formik-antd';
import IndexSeriesSelect from '../../../../../../../../IndexSeries/shared/IndexSeriesSelect';
import { IndexType } from '../../../../../../../../../types';
import DrawerRow from '../../../../../../../../../components/Drawer/DrawerRow';
import { indAgreeCategoryFormFields, IndexationAgreementCategoryFormValues } from './categoryFormMapper';
import MonthYearFormItem from '../shared/MonthYearFormItem';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  formikProps: FormikProps<IndexationAgreementCategoryFormValues>;
  onIndexSeriesChange: (indexSeriesId: string) => void;
  fieldHelpNames?: {
    validFrom?: string | null;
    indexSeriesId?: string | null;
    basedOnUsableArea?: string | null;
  };
};

const IndexBlock: FC<Props> = ({ formikProps, onIndexSeriesChange, fieldHelpNames }) => {
  const content = (
    <>
      <Row gutter={8}>
        <Col span={24}>
          <MonthYearFormItem
            name={indAgreeCategoryFormFields.validFrom}
            label="Gültig ab"
            placeholder="Gültig ab auswählen"
            fieldHelp={fieldHelpNames?.validFrom}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemWithFieldHelp name={indAgreeCategoryFormFields.indexSeriesId} label="Indexreihe" fieldHelp={fieldHelpNames?.indexSeriesId}>
            <IndexSeriesSelect name={indAgreeCategoryFormFields.indexSeriesId} indexType={IndexType.Category} onSelectChange={onIndexSeriesChange} />
          </FormItemWithFieldHelp>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemWithFieldHelp
            name={indAgreeCategoryFormFields.basedOnUsableArea}
            label="Berechnnen auf Basis m2"
            fieldHelp={fieldHelpNames?.basedOnUsableArea}
          >
            <Space>
              <Switch id={indAgreeCategoryFormFields.basedOnUsableArea} name={indAgreeCategoryFormFields.basedOnUsableArea} />
              <Typography.Text>{formikProps.values.basedOnUsableArea ? 'Ja' : 'Nein'}</Typography.Text>
            </Space>
          </FormItemWithFieldHelp>
        </Col>
      </Row>
    </>
  );

  return <DrawerRow rowTitle="Index" content={content} />;
};

export default IndexBlock;
