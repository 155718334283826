import React, { FC, useEffect } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useField } from 'formik';
import { UstKategorie } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useUstKategorieTemplateListQuery } from '../UstKaterogieTemplate/gql/UstKategorieTemplateQueries.types';

type Props = {
  disabled?: boolean;
  name: string;
  fieldHelp?: string | null;
} & SelectProps;

const UstKategorieTemplateSelect: FC<Props> = ({ disabled, name, fieldHelp, ...restProps }) => {
  const [{ value: selectedId }, , { setValue: setSelectedId }] = useField<string>(name);

  const { loading, data } = useUstKategorieTemplateListQuery();

  const list = data?.getUstKategorieTemplateList.data;

  // f_id changes => list changes => if there was already a selected entity it may not be available in the scope of newly selected f_id => clear entity
  useEffect(() => {
    if (!loading && selectedId && !list?.map((item) => item.ustKategorieId).includes(selectedId)) {
      setSelectedId('');
    }
  }, [loading, list, selectedId, setSelectedId]);

  return (
    <FormItemWithFieldHelp name={name} label="USt.-Kategorie" fieldHelp={fieldHelp}>
      <Select disabled={disabled} id={name} name={name} loading={loading} placeholder="USt-Kategorie zuweisen" size="small" {...restProps}>
        {list?.map((ustKategorie: UstKategorie) => (
          <Select.Option key={ustKategorie.ustKategorieId} value={ustKategorie.ustKategorieId}>
            {ustKategorie.kurzBezeichnung}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithFieldHelp>
  );
};

export default UstKategorieTemplateSelect;
