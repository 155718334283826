import React, { ReactElement } from 'react';
import { ColumnProps } from 'antd/es/table';
import { TableComponents } from 'rc-table/lib/interface';
import { TableProps } from 'antd';
import { StyledTable } from './styled/StyledTable.style';
import TableRowWithHighlighting, { getRowKey } from './TableRowWithHighlighting';
import NestedTableActionButtons from './NestedTableActionButtons';
import { useQueryTableExpandedRowKeys } from '../shared/useQueryTableExpandedRowKeys';
import { TableWithPaginationProps } from '../TableWithPagination/TableWithPagination';
import TruncatedTextWithTooltip from '../../Helpers/TruncatedTextWithTooltip';
import TruncatedText from '../../Helpers/TruncatedText';

export type NestedTableColProps<T> = ColumnProps<T>;

export type NestedTableProps<T> = {
  columns: NestedTableColProps<T>[];
  scroll?: TableProps<T>['scroll'];
  expandable?: TableProps<T>['expandable'];
  /** Default width for columns
   * It should be provided for:
   ** rendering truncated header text based on the colWidth
   ** calculating the scroll width based on the colWidth
   * */
  colWidth?: number;
  level?: number;
  useHighlighting?: boolean;
  expandedRowKey?: string;
  actions?: ReactElement;
  /** Size of the table
   ** Default: 'small'
   ** Use this prop only if table size should be different from 'small'
   * */
  size?: 'middle' | 'large';
  hiddenIndexes?: NestedTableColProps<T>[];
} & Omit<TableWithPaginationProps<T>, 'columns' | 'scroll' | 'expandable' | 'size'>;

const NestedTable = <T extends Record<string, unknown>>({
  columns,
  scroll,
  expandable,
  colWidth,
  actions,
  level = 1,
  useHighlighting = false,
  expandedRowKey = undefined,
  size,
  rowKey,
  ...restProps
}: NestedTableProps<T>) => {
  const levelClassName = `nested-table-level-${level}`;
  const scrollWidth = calculateScrollWidth(columns, colWidth);
  const components = getComponents<T>(useHighlighting, restProps.components);
  const expandableProps = useQueryTableExpandedRowKeys(expandedRowKey, expandable, rowKey);

  const columnsWithTooltipInHeader = columns.map((column: NestedTableColProps<T>) => {
    const title = typeof column.title === 'function' ? column.title({}) : (column.title as React.ReactNode);
    const width = column.width ? Number(column.width) : colWidth;

    return column.sorter
      ? {
          // Overwrite the column header with the sorter - show a custom tooltip with truncated text and the sorting message, and disable the default sorter tooltip
          ...column,
          // Substract 26px from the maxWidth to make space for the sorter icon. Without it the text won't be truncated correctly.
          title: <TruncatedText text={<>{title}</>} style={{ maxWidth: width ? width - 26 : undefined }} />,
          showSorterTooltip: {
            title: (
              <>
                Klicken zur Sortierung-Änderung
                <br />
                {title}
              </>
            ),
          },
        }
      : {
          // Overwrite the column header without the sorter - show only truncated text and tooltip
          ...column,
          title: <TruncatedTextWithTooltip text={<>{title}</>} />,
        };
  });

  return (
    <>
      {actions && <NestedTableActionButtons buttonsWithActions={actions} />}
      <StyledTable<T>
        rowKey={rowKey}
        size={size ?? 'small'}
        tableLayout="fixed"
        pagination={level > 1 ? false : restProps.pagination}
        // @ts-ignore
        onRow={(record) => ({
          rowKey: getRowKey(record, rowKey as any),
        })}
        {...restProps}
        components={components}
        columns={!expandable ? [{ title: '', width: 48 }, ...columnsWithTooltipInHeader] : columnsWithTooltipInHeader}
        scroll={!expandable ? scroll : { ...scroll, ...{ x: scrollWidth } }}
        expandable={expandableProps}
        className={[levelClassName, restProps.className ?? ''].filter((className) => className).join(' ')}
      />
    </>
  );
};

function calculateScrollWidth<T>(columns: NestedTableColProps<T>[], colWidth: number | undefined) {
  return columns.reduce((acc, current) => {
    acc += Number(current.width ?? colWidth) || 120;
    return acc;
  }, 48);
}

function getComponents<RecordType>(useHighlighting: boolean, components?: TableComponents<RecordType>) {
  if (useHighlighting) {
    return {
      ...(components ?? {}),
      body: {
        row: TableRowWithHighlighting,
        ...(components?.body ?? {}),
      },
    };
  }
  return components;
}

export default NestedTable;
