import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { LoadingCol } from '../../../../components/Loading';
import { DocumentRowContextProps, useDocumentRow } from '../Row/documentRowContext';
import { FileInfo } from '../../../../types';

const LoadingColDocument = (props: { isAvatar?: boolean; children: (props: DocumentRowContextProps) => React.ReactNode }) => {
  const rowProps = useDocumentRow();

  return (
    <LoadingCol isLoading={rowProps.isLoading} isAvatar={props.isAvatar}>
      {props.children(rowProps)}
    </LoadingCol>
  );
};

const documentTableColumns: ColumnProps<FileInfo>[] = [
  {
    width: 56,
    render: () => <LoadingColDocument isAvatar>{({ documentInfo }) => documentInfo?.column1 ?? null}</LoadingColDocument>,
  },
  {
    align: 'left',
    render: () => <LoadingColDocument>{({ documentInfo }) => documentInfo?.column2 ?? null}</LoadingColDocument>,
  },
  {
    align: 'left',
    render: () => <LoadingColDocument>{({ documentInfo }) => documentInfo?.column3 ?? null}</LoadingColDocument>,
  },
  {
    align: 'left',
    render: () => <LoadingColDocument>{({ documentInfo }) => documentInfo?.column4 ?? null}</LoadingColDocument>,
  },
  {
    align: 'left',
    render: () => <LoadingColDocument>{({ documentInfo }) => documentInfo?.column5 ?? null}</LoadingColDocument>,
  },
  {
    render: () => <LoadingColDocument>{({ documentInfo }) => documentInfo?.column6 ?? null}</LoadingColDocument>,
  },
  {
    width: 250,
    title: 'Status',
    render: () => <LoadingColDocument>{({ documentInfo }) => documentInfo?.column7 ?? null}</LoadingColDocument>,
  },
  {
    width: 90,
    render: () => <LoadingColDocument>{({ documentInfo }) => documentInfo?.column8 ?? null}</LoadingColDocument>,
  },
];

export default documentTableColumns;
