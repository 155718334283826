import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Abrechnungskreis } from '../../../../objektAbrechnung-types';
import pdfStyles from '../../../../../styles/pdfStyles';

const OAAbrKreisExpenseReducingKontoList: FC<{ abrechKreis: Abrechnungskreis }> = ({ abrechKreis }) => (
  <>
    {abrechKreis.expenseReducingKontoList.map((konto, index) => (
      <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight]} key={index}>
        <View style={[pdfStyles.column, { width: '55%' }]} />
        <View style={[pdfStyles.column, { width: '45%' }]}>
          <View style={[pdfStyles.row, { borderTopWidth: index === 0 ? 1 : undefined, paddingTop: index === 0 ? '2mm' : undefined }]}>
            <Text style={[pdfStyles.textBulletinSmall, { width: '80%' }]}>{konto.bezeichnung}</Text>
            <Text style={[pdfStyles.textBulletinSmall, { width: '20%', alignItems: 'flex-end', textAlign: 'right' }]}>
              {konto.betragNettoSummeBuchungen}
            </Text>
          </View>
        </View>
      </View>
    ))}
    <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight]}>
      <View style={[pdfStyles.column, { width: '55%' }]} />
      <View style={[pdfStyles.row, pdfStyles.borderTopSmall, { width: '45%', borderTopWidth: 1 }]}>
        <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '80%', marginTop: '2mm', fontWeight: 'bold' }]}>
          {abrechKreis.summeNettoExpenseReducingText}
        </Text>
        <Text
          style={[
            pdfStyles.column,
            pdfStyles.textBulletinSmall,
            { width: '20%', marginTop: '2mm', alignItems: 'flex-end', textAlign: 'right', fontWeight: 'bold' },
          ]}
        >
          {abrechKreis.summeNettoExpenseReducing}
        </Text>
      </View>
    </View>
  </>
);

export default OAAbrKreisExpenseReducingKontoList;
