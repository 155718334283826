import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUstRegelsetUstKategorieZuweisungMutationVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
  input: Types.UstRegelsetUstKategorieZuweisungCreateInput;
}>;

export type CreateUstRegelsetUstKategorieZuweisungMutation = {
  createUstRegelsetUstKategorieZuweisung: {
    data: { validFrom: string; ustRegelsetKategorieZuweisungId: string; ustKategorie: { ustKategorieId: string } };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateUstRegelsetUstKategorieZuweisungMutationVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Types.Scalars['ID']['input'];
  input: Types.UstRegelsetUstKategorieZuweisungUpdateInput;
}>;

export type UpdateUstRegelsetUstKategorieZuweisungMutation = {
  updateUstRegelsetUstKategorieZuweisung: {
    data: { validFrom: string; ustRegelsetKategorieZuweisungId: string; ustKategorie: { ustKategorieId: string } };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteUstRegelsetUstKategorieZuweisungMutationVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Types.Scalars['ID']['input'];
}>;

export type DeleteUstRegelsetUstKategorieZuweisungMutation = {
  deleteUstRegelsetUstKategorieZuweisung: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateUstRegelsetUstKategorieZuweisungDocument = gql`
  mutation CreateUstRegelsetUstKategorieZuweisung($ustRegelsetId: ID!, $input: UstRegelsetUstKategorieZuweisungCreateInput!) {
    createUstRegelsetUstKategorieZuweisung(ustRegelsetId: $ustRegelsetId, input: $input) {
      data {
        validFrom
        ustKategorie {
          ustKategorieId
        }
        ustRegelsetKategorieZuweisungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateUstRegelsetUstKategorieZuweisungMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUstRegelsetUstKategorieZuweisungMutation, CreateUstRegelsetUstKategorieZuweisungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUstRegelsetUstKategorieZuweisungMutation, CreateUstRegelsetUstKategorieZuweisungMutationVariables>(
    CreateUstRegelsetUstKategorieZuweisungDocument,
    options
  );
}
export type CreateUstRegelsetUstKategorieZuweisungMutationHookResult = ReturnType<typeof useCreateUstRegelsetUstKategorieZuweisungMutation>;
export type CreateUstRegelsetUstKategorieZuweisungMutationResult = Apollo.MutationResult<CreateUstRegelsetUstKategorieZuweisungMutation>;
export type CreateUstRegelsetUstKategorieZuweisungMutationOptions = Apollo.BaseMutationOptions<
  CreateUstRegelsetUstKategorieZuweisungMutation,
  CreateUstRegelsetUstKategorieZuweisungMutationVariables
>;
export const UpdateUstRegelsetUstKategorieZuweisungDocument = gql`
  mutation UpdateUstRegelsetUstKategorieZuweisung(
    $ustRegelsetId: ID!
    $ustRegelsetUstKategorieZuweisungId: ID!
    $input: UstRegelsetUstKategorieZuweisungUpdateInput!
  ) {
    updateUstRegelsetUstKategorieZuweisung(
      ustRegelsetId: $ustRegelsetId
      ustRegelsetUstKategorieZuweisungId: $ustRegelsetUstKategorieZuweisungId
      input: $input
    ) {
      data {
        validFrom
        ustKategorie {
          ustKategorieId
        }
        ustRegelsetKategorieZuweisungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateUstRegelsetUstKategorieZuweisungMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUstRegelsetUstKategorieZuweisungMutation, UpdateUstRegelsetUstKategorieZuweisungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUstRegelsetUstKategorieZuweisungMutation, UpdateUstRegelsetUstKategorieZuweisungMutationVariables>(
    UpdateUstRegelsetUstKategorieZuweisungDocument,
    options
  );
}
export type UpdateUstRegelsetUstKategorieZuweisungMutationHookResult = ReturnType<typeof useUpdateUstRegelsetUstKategorieZuweisungMutation>;
export type UpdateUstRegelsetUstKategorieZuweisungMutationResult = Apollo.MutationResult<UpdateUstRegelsetUstKategorieZuweisungMutation>;
export type UpdateUstRegelsetUstKategorieZuweisungMutationOptions = Apollo.BaseMutationOptions<
  UpdateUstRegelsetUstKategorieZuweisungMutation,
  UpdateUstRegelsetUstKategorieZuweisungMutationVariables
>;
export const DeleteUstRegelsetUstKategorieZuweisungDocument = gql`
  mutation DeleteUstRegelsetUstKategorieZuweisung($ustRegelsetId: ID!, $ustRegelsetUstKategorieZuweisungId: ID!) {
    deleteUstRegelsetUstKategorieZuweisung(ustRegelsetId: $ustRegelsetId, ustRegelsetUstKategorieZuweisungId: $ustRegelsetUstKategorieZuweisungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteUstRegelsetUstKategorieZuweisungMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUstRegelsetUstKategorieZuweisungMutation, DeleteUstRegelsetUstKategorieZuweisungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUstRegelsetUstKategorieZuweisungMutation, DeleteUstRegelsetUstKategorieZuweisungMutationVariables>(
    DeleteUstRegelsetUstKategorieZuweisungDocument,
    options
  );
}
export type DeleteUstRegelsetUstKategorieZuweisungMutationHookResult = ReturnType<typeof useDeleteUstRegelsetUstKategorieZuweisungMutation>;
export type DeleteUstRegelsetUstKategorieZuweisungMutationResult = Apollo.MutationResult<DeleteUstRegelsetUstKategorieZuweisungMutation>;
export type DeleteUstRegelsetUstKategorieZuweisungMutationOptions = Apollo.BaseMutationOptions<
  DeleteUstRegelsetUstKategorieZuweisungMutation,
  DeleteUstRegelsetUstKategorieZuweisungMutationVariables
>;
