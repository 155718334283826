import { FC } from 'react';
import generateCalendar from 'antd/lib/calendar/generateCalendar';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { CalendarProps } from 'antd/lib/calendar';
import { useLocale } from '../../shared/Locale/LocaleProvider';
import { mapLocaleToAntDesignLocale } from '../../shared/Locale/localeMapper';

const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig);

const CalendarDayjs: FC<CalendarProps<Dayjs>> = (props) => {
  const { locale } = useLocale();
  return <Calendar locale={mapLocaleToAntDesignLocale(locale).Calendar as PickerLocale} {...props} />;
};

export default CalendarDayjs;
