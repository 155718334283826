import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { PaymentGenerierlaufType } from '../../../../../types';
import { useFilterGenLaufPaymentTransactionTypeListQuery } from '../../../gql/GenerierlaufFilter/PaymentGenerierlaufFilterQueries.types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: PaymentGenerierlaufType;
} & SelectProps;

const GenerierlaufPaymentTransactionTypeFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufPaymentTransactionTypeListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufPaymentTransactionTypeList.data ?? [];

  return (
    <Select size="small" loading={loading} id={name} name={name} allowClear placeholder="Zahlungsverkehrsart auswählen" {...restProps}>
      {list.map((transactionType) => (
        <Select.Option key={transactionType.value} value={transactionType.value}>
          <DataWithShortenedText maxTextLength={30} text={transactionType.text} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufPaymentTransactionTypeFormSelect;
