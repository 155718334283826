import { MitarbeiterWithFirmaInfo, UserRole } from '../../types';
import { AuthAction, AuthContextState } from './auth.model';

export const authReducer = (state: AuthContextState, action: AuthAction): AuthContextState => {
  switch (action.type) {
    case 'RESET_APP_STATE':
      return {
        ...state,
        authState: 'UNAUTHENTICATED',
      };
    case 'LOAD_USER_SUCCESS':
      return {
        ...state,
        user: action.payload,
        authState: action.payload.role === UserRole.Admin ? 'AUTHORIZED' : 'PENDING',
      };
    case 'CLEAR_STATE':
      return { authState: 'UNAUTHENTICATED' };
    case 'LOAD_MITARBEITER_LIST_SUCCESS':
      return {
        ...state,
        activeForFirmendaten: getFirmendatenIfUserHasOnlyOneMitarbeiter(action.payload),
        authState: nextAuthStateBasedOnUsersMitarbeiterList(action.payload),
      };
    case 'LOAD_MITARBEITER_SUCCESS':
      return { ...state, mitarbeiter: action.payload, authState: 'AUTHORIZED' };
    case 'SET_ACTIVE_FOR_FIRMENDATEN':
      return { ...state, activeForFirmendaten: action.payload, authState: 'AUTHORIZED' };
    case 'CLEAR_ACTIVE_FOR_FIRMENDATEN':
      return { ...state, activeForFirmendaten: undefined };
    default:
      throw new Error(`unsupported auth action: ${action}`);
  }
};

const getFirmendatenIfUserHasOnlyOneMitarbeiter = (mitarbeiterList: MitarbeiterWithFirmaInfo[]) =>
  mitarbeiterList.length === 1
    ? {
        firmendatenId: mitarbeiterList[0].firmendatenBaseData.firmendatenId,
        kundenSystemId: mitarbeiterList[0].firmendatenBaseData.kundenSystemId,
      }
    : undefined;

const nextAuthStateBasedOnUsersMitarbeiterList = (mitarbeiterList: MitarbeiterWithFirmaInfo[]) => {
  if (mitarbeiterList.length === 1) return 'PENDING';
  else if (mitarbeiterList.length > 1) return 'AUTH_CHOOSE_FIRMENDATEN';
  return 'AUTH_NO_MITARBEITER_INFO';
};
