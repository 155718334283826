import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';

const useOnAbrechnungListingDataChangeEvent = (key: string, callback: () => Promise<unknown>) => {
  useOnDataChangedEvent([
    {
      filter: {
        //userId: user.username,
        //mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.topAbrechnung,
        params: {},
      },
      notificationDescription: 'Topabrechnungen wurden gerade verändert',
      key: `${key}`,
      callback,
    },
    {
      filter: {
        //userId: user.username,
        //mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.objektAbrechnung,
        params: {},
      },
      notificationDescription: 'Objektabrechnung wurde gerade verändert',
      key: `${key}-objektAbr`,
      callback,
    },
    {
      filter: {
        //userId: user.username,
        //mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.heAbrechnungHeVertrag,
        params: {},
      },
      notificationDescription: 'Hauseigentümerabrechnung wurde gerade verändert',
      key: `${key}-he-abr-he-vertrag`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.heAbrechnung,
        params: {},
      },
      notificationDescription: 'Hauseigentümerabrechnung wurde gerade verändert',
      key: `${key}-heAbr`,
      callback,
    },
  ]);
};

export { useOnAbrechnungListingDataChangeEvent };
