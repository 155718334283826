import { FC } from 'react';
import { useToggle } from '../../../../hooks/useToggle';
import { PostIt, PostItInput } from '../../../../types';
import { showSuccessMsgCreate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import PostItIconAndCreateMode from '../../../../components/PostIt/PostItIconAndCreateMode';
import PostItIconAndViewAndUpdateMode from '../../../../components/PostIt/PostItIconAndViewAndUpdateMode';
import { useCreateBeVertragPostItMutation } from '../gql/BeVertragMutations.types';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  postIt?: PostIt | null;
  refresh: () => void;
};

const BeVertragPostIt: FC<Props> = ({ objektId, bestandseinheitId, vertragId, postIt, refresh }) => {
  const [isPostItOpen, toggleIsPostItOpen] = useToggle(false);

  const [runCreate] = useCreateBeVertragPostItMutation({
    onCompleted: () => {
      showSuccessMsgCreate('Post-It');
      toggleIsPostItOpen();
      refresh();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleCreateSubmit = (values: PostItInput) => {
    runCreate({
      variables: {
        objektId,
        bestandseinheitId,
        vertragId,
        input: {
          immerAnzeigen: values.immerAnzeigen,
          titel: values.titel,
          text: values.text,
        },
      },
    });
  };

  const handleCreateCancel = () => {
    toggleIsPostItOpen();
  };

  return postIt ? (
    <PostItIconAndViewAndUpdateMode postIt={postIt} refresh={refresh} />
  ) : (
    <PostItIconAndCreateMode
      isPostItOpen={isPostItOpen}
      onIconClick={toggleIsPostItOpen}
      onFormCancel={handleCreateCancel}
      onFormSubmit={handleCreateSubmit}
    />
  );
};

export default BeVertragPostIt;
