import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { UstRegelsetFieldsFragmentDoc } from '../../UstRegelset/gql/UstRegelsetFragments.types';
import { HistoryFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstRegelsetTemplateListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UstRegelsetTemplateListQuery = {
  getUstRegelsetTemplateList: {
    data: Array<{
      bezeichnung: string;
      countryCodeIso2: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      eigennutzung: boolean;
      firmendatenId?: string | null;
      leerstehung: boolean;
      optionSteuerpflicht: boolean;
      ustRegelsetId: string;
      land?: { alpha2: string; alpha3: string; bezeichnung: string } | null;
      nutzungsArt: { text: string; value: Types.NutzungsArt };
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      ustKategorie?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      ustKategorieZuweisungList: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        ustRegelsetKategorieZuweisungId: string;
        validFrom: string;
        ustKategorie: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          ustKategorieId: string;
          ustVomAufwand?: number | null;
          vstKuerzung?: boolean | null;
          status?: { text: string; value: Types.UstKategorieStatus } | null;
          ustKategorieEntryList: Array<{
            additionKonto: number;
            buchungsKZ?: string | null;
            createTs?: string | null;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            rechnungskennzeichen: string;
            steuersatz: number;
            ustKategorieEntryId?: string | null;
            ustKz: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kuerzel: string;
              umsatzsteuerkennzeichenId: string;
              status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstRegelsetTemplateQueryVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
}>;

export type UstRegelsetTemplateQuery = {
  getUstRegelsetTemplate: {
    data: {
      bezeichnung: string;
      countryCodeIso2: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      eigennutzung: boolean;
      firmendatenId?: string | null;
      leerstehung: boolean;
      optionSteuerpflicht: boolean;
      ustRegelsetId: string;
      land?: { alpha2: string; alpha3: string; bezeichnung: string } | null;
      nutzungsArt: { text: string; value: Types.NutzungsArt };
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      ustKategorie?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      ustKategorieZuweisungList: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        ustRegelsetKategorieZuweisungId: string;
        validFrom: string;
        ustKategorie: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          ustKategorieId: string;
          ustVomAufwand?: number | null;
          vstKuerzung?: boolean | null;
          status?: { text: string; value: Types.UstKategorieStatus } | null;
          ustKategorieEntryList: Array<{
            additionKonto: number;
            buchungsKZ?: string | null;
            createTs?: string | null;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            rechnungskennzeichen: string;
            steuersatz: number;
            ustKategorieEntryId?: string | null;
            ustKz: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              kuerzel: string;
              umsatzsteuerkennzeichenId: string;
              status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstRegelsetTemplateChangeHistoryListQueryVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
}>;

export type UstRegelsetTemplateChangeHistoryListQuery = {
  getUstRegelsetTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UstRegelsetTemplateListDocument = gql`
  query UstRegelsetTemplateList {
    getUstRegelsetTemplateList {
      data {
        ...UstRegelsetFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstRegelsetFieldsFragmentDoc}
`;
export function useUstRegelsetTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<UstRegelsetTemplateListQuery, UstRegelsetTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstRegelsetTemplateListQuery, UstRegelsetTemplateListQueryVariables>(UstRegelsetTemplateListDocument, options);
}
export function useUstRegelsetTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstRegelsetTemplateListQuery, UstRegelsetTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstRegelsetTemplateListQuery, UstRegelsetTemplateListQueryVariables>(UstRegelsetTemplateListDocument, options);
}
export function useUstRegelsetTemplateListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstRegelsetTemplateListQuery, UstRegelsetTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstRegelsetTemplateListQuery, UstRegelsetTemplateListQueryVariables>(UstRegelsetTemplateListDocument, options);
}
export type UstRegelsetTemplateListQueryHookResult = ReturnType<typeof useUstRegelsetTemplateListQuery>;
export type UstRegelsetTemplateListLazyQueryHookResult = ReturnType<typeof useUstRegelsetTemplateListLazyQuery>;
export type UstRegelsetTemplateListSuspenseQueryHookResult = ReturnType<typeof useUstRegelsetTemplateListSuspenseQuery>;
export type UstRegelsetTemplateListQueryResult = Apollo.QueryResult<UstRegelsetTemplateListQuery, UstRegelsetTemplateListQueryVariables>;
export const UstRegelsetTemplateDocument = gql`
  query UstRegelsetTemplate($ustRegelsetId: ID!) {
    getUstRegelsetTemplate(ustRegelsetId: $ustRegelsetId) {
      data {
        ...UstRegelsetFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstRegelsetFieldsFragmentDoc}
`;
export function useUstRegelsetTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<UstRegelsetTemplateQuery, UstRegelsetTemplateQueryVariables> &
    ({ variables: UstRegelsetTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstRegelsetTemplateQuery, UstRegelsetTemplateQueryVariables>(UstRegelsetTemplateDocument, options);
}
export function useUstRegelsetTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstRegelsetTemplateQuery, UstRegelsetTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstRegelsetTemplateQuery, UstRegelsetTemplateQueryVariables>(UstRegelsetTemplateDocument, options);
}
export function useUstRegelsetTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstRegelsetTemplateQuery, UstRegelsetTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstRegelsetTemplateQuery, UstRegelsetTemplateQueryVariables>(UstRegelsetTemplateDocument, options);
}
export type UstRegelsetTemplateQueryHookResult = ReturnType<typeof useUstRegelsetTemplateQuery>;
export type UstRegelsetTemplateLazyQueryHookResult = ReturnType<typeof useUstRegelsetTemplateLazyQuery>;
export type UstRegelsetTemplateSuspenseQueryHookResult = ReturnType<typeof useUstRegelsetTemplateSuspenseQuery>;
export type UstRegelsetTemplateQueryResult = Apollo.QueryResult<UstRegelsetTemplateQuery, UstRegelsetTemplateQueryVariables>;
export const UstRegelsetTemplateChangeHistoryListDocument = gql`
  query UstRegelsetTemplateChangeHistoryList($ustRegelsetId: ID!) {
    getUstRegelsetTemplateChangeHistoryList(ustRegelsetId: $ustRegelsetId) {
      data {
        ...HistoryFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${HistoryFieldsFragmentDoc}
`;
export function useUstRegelsetTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<UstRegelsetTemplateChangeHistoryListQuery, UstRegelsetTemplateChangeHistoryListQueryVariables> &
    ({ variables: UstRegelsetTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstRegelsetTemplateChangeHistoryListQuery, UstRegelsetTemplateChangeHistoryListQueryVariables>(
    UstRegelsetTemplateChangeHistoryListDocument,
    options
  );
}
export function useUstRegelsetTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstRegelsetTemplateChangeHistoryListQuery, UstRegelsetTemplateChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstRegelsetTemplateChangeHistoryListQuery, UstRegelsetTemplateChangeHistoryListQueryVariables>(
    UstRegelsetTemplateChangeHistoryListDocument,
    options
  );
}
export function useUstRegelsetTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UstRegelsetTemplateChangeHistoryListQuery, UstRegelsetTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstRegelsetTemplateChangeHistoryListQuery, UstRegelsetTemplateChangeHistoryListQueryVariables>(
    UstRegelsetTemplateChangeHistoryListDocument,
    options
  );
}
export type UstRegelsetTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useUstRegelsetTemplateChangeHistoryListQuery>;
export type UstRegelsetTemplateChangeHistoryListLazyQueryHookResult = ReturnType<typeof useUstRegelsetTemplateChangeHistoryListLazyQuery>;
export type UstRegelsetTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useUstRegelsetTemplateChangeHistoryListSuspenseQuery>;
export type UstRegelsetTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  UstRegelsetTemplateChangeHistoryListQuery,
  UstRegelsetTemplateChangeHistoryListQueryVariables
>;
