import React, { FC } from 'react';
import { MenuProps, Modal } from 'antd';
import { ReadOutlined } from '@ant-design/icons';
import ActionDropdown from '../../../components/Dropdown/ActionDropdown';
import { BookingFolderQueryParams } from './Filters/filtersQueryParams';

type Props = {
  onAction: () => void;
  queryParams: BookingFolderQueryParams;
};

const BookingFolderTableBulkActions: FC<Props> = ({ onAction, queryParams }) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Alle verbuchen',
      onClick: showConfirmVerbuchen(() => {
        console.log('Alle verbuchen geklicked');
      }),
      icon: <ReadOutlined />,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} title="Auf Liste anwenden" />
    </>
  );
};

const showConfirmVerbuchen = (runVerbuchen: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Buchungsvorschläge anhand der Filterkriterien verbuchen?`,
    okText: 'Verbuchen',
    cancelText: 'Abbrechen',
    onOk() {
      return runVerbuchen();
    },
  });
};

export default BookingFolderTableBulkActions;
