import React, { FC } from 'react';
import { Divider, Skeleton, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import CardWithFooter from '../../../components/Card';
import MahnungActions from './MahnungActions';
import MahnungSums from './MahnungSums';
import MahnungDates from './MahnungDates';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import { pathsToObjektDetailsPage } from '../../Objekt/objektUriPaths';
import KontoLinkToKontoblatt from '../../../shared/components/Konto/KontoLinkToKontoblatt';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import MahnungCardTitle from './MahnungCardTitle';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import AddressInfoRow from './AddressInfoRow';
import { Mahnung } from '../../../types';
import MahnungMailDeliveryInfoRow from './MahnungMailDeliveryInfoRow';
import RechtstraegerWithContactsTooltip from '../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

type Props = {
  loading: boolean;
  mahnung: Mahnung;
  onActionSuccess: () => void;
};

const MahnungCard: FC<Props> = ({ loading, mahnung, onActionSuccess }) => (
  <CardWithFooter
    loading={loading}
    style={{ minWidth: '320px' }}
    size="default"
    bordered={false}
    title={<MahnungCardTitle status={mahnung.status} vertragspartnerId={mahnung.vertragspartner.rechtstraegerId} />}
    extra={<MahnungActions mahnung={mahnung} onActionSuccess={onActionSuccess} />}
    createTs={mahnung.createTs}
    userId={mahnung.createdBy}
    mitarbeiterId={mahnung.createdByMitarbeiterId}
  >
    <Skeleton loading={loading}>
      <Typography.Title level={5}>Allgemeine Informationen</Typography.Title>
      <CardDetailsInfoRow infoRowTitle="von">
        <RechtstraegerWithContactsTooltip
          rechtstraegerId={mahnung.rechnungsAussteller.rechtstraegerId}
          rechtstraegerName={mahnung.rechnungsAussteller.kurzBezeichnung}
          status={mahnung.rechnungsAussteller.status}
          maxTextLength={25}
        />
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="an">
        <RechtstraegerWithContactsTooltip
          rechtstraegerId={mahnung.vertragspartner.rechtstraegerId}
          rechtstraegerName={mahnung.vertragspartner.kurzBezeichnung}
          status={mahnung.vertragspartner.status}
          maxTextLength={25}
        />
      </CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="Objekt">
        <Space direction="vertical">
          {mahnung.objektList.length > 0 ? (
            mahnung.objektList.map((objekt) => (
              <Link key={objekt.objektId} to={pathsToObjektDetailsPage(objekt.objektId).objektDetails}>
                {objekt.kurzBezeichnung}
              </Link>
            ))
          ) : (
            <TextForEmptyValue textToShow="minus" />
          )}
        </Space>
      </CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="Objekt-Bankverbindung">
        <DataWithShortenedText
          maxTextLength={25}
          text={`${mahnung.rechnungsAusstellerBankDetailsAccountHolder} - ${mahnung.rechnungsAusstellerBankDetailsIban}`}
        />
      </CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="Fibu-Konto">
        <KontoLinkToKontoblatt konto={mahnung.debitorKonto} buchungskreisId={mahnung.buchungskreis.buchungskreisId} />
      </CardDetailsInfoRow>
      <Divider style={{ marginTop: '16px', marginBottom: '16px' }} dashed />

      <Typography.Title level={5}>Zustellung</Typography.Title>
      <AddressInfoRow title="Zustelladresse OPs" address={mahnung.opDeliveryAddress} name={mahnung.opZustellRechtstraeger.kurzBezeichnung} />
      <CardDetailsInfoRow infoRowTitle="E-Mail-Adresse OPs">
        <DataWithShortenedText maxTextLength={25} text={mahnung.opEmail}>
          {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
        </DataWithShortenedText>
      </CardDetailsInfoRow>
      <Divider style={{ marginTop: '16px', marginBottom: '16px' }} dashed />

      <AddressInfoRow title="Zustelladresse Mahnung" address={mahnung.mahnungDeliveryAddress} />
      {mahnung.mahnungEmail ? (
        <MahnungMailDeliveryInfoRow email={mahnung.mahnungEmail} mailDelivery={mahnung.mailDelivery} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      )}
      <Divider style={{ marginTop: '16px', marginBottom: '16px' }} dashed />

      <Typography.Title level={5}>Zahlungsrückstand</Typography.Title>
      <MahnungSums mahnung={mahnung} />
      <MahnungDates mahnung={mahnung} />
    </Skeleton>
  </CardWithFooter>
);

export default MahnungCard;
