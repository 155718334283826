import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../shared/Auth/authContext';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../../types';

const useOnHeAbrDataChangeEvent = (key: string, callback: () => Promise<unknown>, objektId?: string) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.heAbrechnungHeVertrag,
        params: {
          objektId,
        },
      },
      notificationDescription: 'Hauseigentümerabrechnung wurde gerade verändert',
      key: `${key}-heAbr`,
      callback,
    },
    {
      filter: {
        changeType: DataChangedChangeType.Update,
        type: dataChangedSubscriptionTypes.heAbrechnung,
        params: {
          objektId,
        },
      },
      notificationDescription: 'Hauseigentümerabrechnung wurde gerade verändert',
      key: `${key}-update-heAbr`,
      callback,
    },
  ]);
};

export { useOnHeAbrDataChangeEvent };
