import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { OrderBuchungsanweisungFieldsFragmentDoc } from './SingleInvoiceFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSingleInvoiceMutationVariables = Types.Exact<{
  input: Types.SingleInvoiceCreateInput;
}>;

export type CreateSingleInvoiceMutation = {
  createSingleInvoice: {
    data: { belegId: string; fileId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateSingleInvoiceMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
  input: Types.SingleInvoiceUpdateInput;
}>;

export type UpdateSingleInvoiceMutation = {
  updateSingleInvoice: {
    data: { belegId: string; fileId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteSingleInvoiceMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type DeleteSingleInvoiceMutation = {
  deleteSingleInvoice: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type SingleInvoiceFreigebenMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type SingleInvoiceFreigebenMutation = {
  actionSingleInvoiceFreigeben: {
    data: { belegId: string; ausstellerRechtstraegerId: string; payeeRechtstraegerId: string; objektId?: string | null; rechnungsNummer: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SingleInvoiceRestoreMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type SingleInvoiceRestoreMutation = {
  actionSingleInvoiceRestore: {
    data: { belegId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SingleInvoiceAbgrenzungsBuchungPreviewMutationVariables = Types.Exact<{
  input: Types.SingleInvoiceCreateInput;
}>;

export type SingleInvoiceAbgrenzungsBuchungPreviewMutation = {
  singleInvoiceAbgrenzungsBuchungPreview: {
    data: {
      aufwandBuchungDerAufwandAbgrenzung: {
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      };
      buchungMitAufwandAbgrenzung: {
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SingleInvoiceAbgrenzungsStornoBuchungPreviewMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
  input: Types.SingleInvoiceUpdateInput;
}>;

export type SingleInvoiceAbgrenzungsStornoBuchungPreviewMutation = {
  singleInvoiceAbgrenzungsStornoBuchungPreview: {
    data: {
      aufwandKorrekturBuchungDerAufwandAbgrenzung: {
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      };
      aufwandStornoBuchungDerAufwandAbgrenzung: {
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      };
      korrekturBuchungMitAufwandAbgrenzung: {
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      };
      originalBuchungList: Array<{
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      }>;
      stornoBuchungMitAufwandAbgrenzung: {
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SingleInvoiceBuchungPreviewMutationVariables = Types.Exact<{
  input: Types.SingleInvoiceCreateInput;
}>;

export type SingleInvoiceBuchungPreviewMutation = {
  singleInvoiceBuchungPreview: {
    data: {
      buchung: {
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SingleInvoiceStornoBuchungPreviewMutationVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
  input: Types.SingleInvoiceUpdateInput;
}>;

export type SingleInvoiceStornoBuchungPreviewMutation = {
  singleInvoiceStornoBuchungPreview: {
    data: {
      korrekturBuchung?: {
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      } | null;
      originalBuchungList: Array<{
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      }>;
      stornoBuchungList: Array<{
        buchungsdatum: string;
        buchungsanweisungId: string;
        fibuBuchungId?: string | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungszeileId: string;
          kontoId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          steuersatz?: number | null;
          objektId?: string | null;
          text: string;
          gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
          kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateSingleInvoiceDocument = gql`
  mutation CreateSingleInvoice($input: SingleInvoiceCreateInput!) {
    createSingleInvoice(input: $input) {
      data {
        belegId
        fileId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateSingleInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSingleInvoiceMutation, CreateSingleInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSingleInvoiceMutation, CreateSingleInvoiceMutationVariables>(CreateSingleInvoiceDocument, options);
}
export type CreateSingleInvoiceMutationHookResult = ReturnType<typeof useCreateSingleInvoiceMutation>;
export type CreateSingleInvoiceMutationResult = Apollo.MutationResult<CreateSingleInvoiceMutation>;
export type CreateSingleInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateSingleInvoiceMutation, CreateSingleInvoiceMutationVariables>;
export const UpdateSingleInvoiceDocument = gql`
  mutation UpdateSingleInvoice($belegId: String!, $input: SingleInvoiceUpdateInput!) {
    updateSingleInvoice(belegId: $belegId, input: $input) {
      data {
        belegId
        fileId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateSingleInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSingleInvoiceMutation, UpdateSingleInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSingleInvoiceMutation, UpdateSingleInvoiceMutationVariables>(UpdateSingleInvoiceDocument, options);
}
export type UpdateSingleInvoiceMutationHookResult = ReturnType<typeof useUpdateSingleInvoiceMutation>;
export type UpdateSingleInvoiceMutationResult = Apollo.MutationResult<UpdateSingleInvoiceMutation>;
export type UpdateSingleInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateSingleInvoiceMutation, UpdateSingleInvoiceMutationVariables>;
export const DeleteSingleInvoiceDocument = gql`
  mutation DeleteSingleInvoice($belegId: String!) {
    deleteSingleInvoice(belegId: $belegId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteSingleInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSingleInvoiceMutation, DeleteSingleInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSingleInvoiceMutation, DeleteSingleInvoiceMutationVariables>(DeleteSingleInvoiceDocument, options);
}
export type DeleteSingleInvoiceMutationHookResult = ReturnType<typeof useDeleteSingleInvoiceMutation>;
export type DeleteSingleInvoiceMutationResult = Apollo.MutationResult<DeleteSingleInvoiceMutation>;
export type DeleteSingleInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteSingleInvoiceMutation, DeleteSingleInvoiceMutationVariables>;
export const SingleInvoiceFreigebenDocument = gql`
  mutation SingleInvoiceFreigeben($belegId: String!) {
    actionSingleInvoiceFreigeben(belegId: $belegId) {
      data {
        belegId
        ausstellerRechtstraegerId
        payeeRechtstraegerId
        objektId
        rechnungsNummer
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSingleInvoiceFreigebenMutation(
  baseOptions?: Apollo.MutationHookOptions<SingleInvoiceFreigebenMutation, SingleInvoiceFreigebenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SingleInvoiceFreigebenMutation, SingleInvoiceFreigebenMutationVariables>(SingleInvoiceFreigebenDocument, options);
}
export type SingleInvoiceFreigebenMutationHookResult = ReturnType<typeof useSingleInvoiceFreigebenMutation>;
export type SingleInvoiceFreigebenMutationResult = Apollo.MutationResult<SingleInvoiceFreigebenMutation>;
export type SingleInvoiceFreigebenMutationOptions = Apollo.BaseMutationOptions<
  SingleInvoiceFreigebenMutation,
  SingleInvoiceFreigebenMutationVariables
>;
export const SingleInvoiceRestoreDocument = gql`
  mutation SingleInvoiceRestore($belegId: String!) {
    actionSingleInvoiceRestore(belegId: $belegId) {
      data {
        belegId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSingleInvoiceRestoreMutation(
  baseOptions?: Apollo.MutationHookOptions<SingleInvoiceRestoreMutation, SingleInvoiceRestoreMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SingleInvoiceRestoreMutation, SingleInvoiceRestoreMutationVariables>(SingleInvoiceRestoreDocument, options);
}
export type SingleInvoiceRestoreMutationHookResult = ReturnType<typeof useSingleInvoiceRestoreMutation>;
export type SingleInvoiceRestoreMutationResult = Apollo.MutationResult<SingleInvoiceRestoreMutation>;
export type SingleInvoiceRestoreMutationOptions = Apollo.BaseMutationOptions<SingleInvoiceRestoreMutation, SingleInvoiceRestoreMutationVariables>;
export const SingleInvoiceAbgrenzungsBuchungPreviewDocument = gql`
  mutation SingleInvoiceAbgrenzungsBuchungPreview($input: SingleInvoiceCreateInput!) {
    singleInvoiceAbgrenzungsBuchungPreview(input: $input) {
      data {
        aufwandBuchungDerAufwandAbgrenzung {
          ...OrderBuchungsanweisungFields
        }
        buchungMitAufwandAbgrenzung {
          ...OrderBuchungsanweisungFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderBuchungsanweisungFieldsFragmentDoc}
`;
export function useSingleInvoiceAbgrenzungsBuchungPreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<SingleInvoiceAbgrenzungsBuchungPreviewMutation, SingleInvoiceAbgrenzungsBuchungPreviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SingleInvoiceAbgrenzungsBuchungPreviewMutation, SingleInvoiceAbgrenzungsBuchungPreviewMutationVariables>(
    SingleInvoiceAbgrenzungsBuchungPreviewDocument,
    options
  );
}
export type SingleInvoiceAbgrenzungsBuchungPreviewMutationHookResult = ReturnType<typeof useSingleInvoiceAbgrenzungsBuchungPreviewMutation>;
export type SingleInvoiceAbgrenzungsBuchungPreviewMutationResult = Apollo.MutationResult<SingleInvoiceAbgrenzungsBuchungPreviewMutation>;
export type SingleInvoiceAbgrenzungsBuchungPreviewMutationOptions = Apollo.BaseMutationOptions<
  SingleInvoiceAbgrenzungsBuchungPreviewMutation,
  SingleInvoiceAbgrenzungsBuchungPreviewMutationVariables
>;
export const SingleInvoiceAbgrenzungsStornoBuchungPreviewDocument = gql`
  mutation SingleInvoiceAbgrenzungsStornoBuchungPreview($belegId: String!, $input: SingleInvoiceUpdateInput!) {
    singleInvoiceAbgrenzungsStornoBuchungPreview(belegId: $belegId, input: $input) {
      data {
        aufwandKorrekturBuchungDerAufwandAbgrenzung {
          ...OrderBuchungsanweisungFields
        }
        aufwandStornoBuchungDerAufwandAbgrenzung {
          ...OrderBuchungsanweisungFields
        }
        korrekturBuchungMitAufwandAbgrenzung {
          ...OrderBuchungsanweisungFields
        }
        originalBuchungList {
          ...OrderBuchungsanweisungFields
        }
        stornoBuchungMitAufwandAbgrenzung {
          ...OrderBuchungsanweisungFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderBuchungsanweisungFieldsFragmentDoc}
`;
export function useSingleInvoiceAbgrenzungsStornoBuchungPreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SingleInvoiceAbgrenzungsStornoBuchungPreviewMutation,
    SingleInvoiceAbgrenzungsStornoBuchungPreviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SingleInvoiceAbgrenzungsStornoBuchungPreviewMutation, SingleInvoiceAbgrenzungsStornoBuchungPreviewMutationVariables>(
    SingleInvoiceAbgrenzungsStornoBuchungPreviewDocument,
    options
  );
}
export type SingleInvoiceAbgrenzungsStornoBuchungPreviewMutationHookResult = ReturnType<
  typeof useSingleInvoiceAbgrenzungsStornoBuchungPreviewMutation
>;
export type SingleInvoiceAbgrenzungsStornoBuchungPreviewMutationResult = Apollo.MutationResult<SingleInvoiceAbgrenzungsStornoBuchungPreviewMutation>;
export type SingleInvoiceAbgrenzungsStornoBuchungPreviewMutationOptions = Apollo.BaseMutationOptions<
  SingleInvoiceAbgrenzungsStornoBuchungPreviewMutation,
  SingleInvoiceAbgrenzungsStornoBuchungPreviewMutationVariables
>;
export const SingleInvoiceBuchungPreviewDocument = gql`
  mutation SingleInvoiceBuchungPreview($input: SingleInvoiceCreateInput!) {
    singleInvoiceBuchungPreview(input: $input) {
      data {
        buchung {
          ...OrderBuchungsanweisungFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderBuchungsanweisungFieldsFragmentDoc}
`;
export function useSingleInvoiceBuchungPreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<SingleInvoiceBuchungPreviewMutation, SingleInvoiceBuchungPreviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SingleInvoiceBuchungPreviewMutation, SingleInvoiceBuchungPreviewMutationVariables>(
    SingleInvoiceBuchungPreviewDocument,
    options
  );
}
export type SingleInvoiceBuchungPreviewMutationHookResult = ReturnType<typeof useSingleInvoiceBuchungPreviewMutation>;
export type SingleInvoiceBuchungPreviewMutationResult = Apollo.MutationResult<SingleInvoiceBuchungPreviewMutation>;
export type SingleInvoiceBuchungPreviewMutationOptions = Apollo.BaseMutationOptions<
  SingleInvoiceBuchungPreviewMutation,
  SingleInvoiceBuchungPreviewMutationVariables
>;
export const SingleInvoiceStornoBuchungPreviewDocument = gql`
  mutation SingleInvoiceStornoBuchungPreview($belegId: String!, $input: SingleInvoiceUpdateInput!) {
    singleInvoiceStornoBuchungPreview(belegId: $belegId, input: $input) {
      data {
        korrekturBuchung {
          ...OrderBuchungsanweisungFields
        }
        originalBuchungList {
          ...OrderBuchungsanweisungFields
        }
        stornoBuchungList {
          ...OrderBuchungsanweisungFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderBuchungsanweisungFieldsFragmentDoc}
`;
export function useSingleInvoiceStornoBuchungPreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<SingleInvoiceStornoBuchungPreviewMutation, SingleInvoiceStornoBuchungPreviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SingleInvoiceStornoBuchungPreviewMutation, SingleInvoiceStornoBuchungPreviewMutationVariables>(
    SingleInvoiceStornoBuchungPreviewDocument,
    options
  );
}
export type SingleInvoiceStornoBuchungPreviewMutationHookResult = ReturnType<typeof useSingleInvoiceStornoBuchungPreviewMutation>;
export type SingleInvoiceStornoBuchungPreviewMutationResult = Apollo.MutationResult<SingleInvoiceStornoBuchungPreviewMutation>;
export type SingleInvoiceStornoBuchungPreviewMutationOptions = Apollo.BaseMutationOptions<
  SingleInvoiceStornoBuchungPreviewMutation,
  SingleInvoiceStornoBuchungPreviewMutationVariables
>;
