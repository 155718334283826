import React from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import SubAdministrationAbrechnungTopTemplate from './templates/subAdministrationAbrechnung/Top/SubAdministrationAbrechnungTopTemplate';
import { mockSubAdministrationAbrechnungTop } from './mocks/subAdminAbrechnungTopMocks';

const PDFPageSubAdminAbrechnungTop = () => (
  <Row>
    <PDFPageCol pdfTemplate={<SubAdministrationAbrechnungTopTemplate data={mockSubAdministrationAbrechnungTop} />} />
  </Row>
);

export default PDFPageSubAdminAbrechnungTop;
