import theme from '../../../theme';
import { Abrechnungskreis, Aufteilungsschluessel, Konto } from '../../../types';

export type ZuordnungColumnMode = 'viewAndEdit' | 'onlyView' | 'onlyEdit';

export interface ZuordnungViewModel {
  abrechnungsdefinitionId: string;
  konto: Konto;
  abrechnungskreis?: Abrechnungskreis | null | undefined;
  ustVomAufwand: boolean;
  buchungszeilenAnzeigen: boolean;
  aufteilungsschluessel?: Aufteilungsschluessel | null | undefined;
  expenseReducing: boolean;
}

export const zuordnungListHeight = {
  left: 280,
  right: 320,
};

export const repFondVerwendungText = (ustVomAufwand: boolean) => (ustVomAufwand ? ' (Ust. vom Aufwand)' : ' (Ausgaben)');

export enum BuchungszeilenAnzeigenTuple {
  ANZEIGEN = 'Anzeigen',
  AUSBLENDEN = 'Ausblenden',
}

export enum VerwendungVonRepFondsTuple {
  UST_VOM_AUFWAND = 'ustVomAufwand',
  AUSGABEN = 'ausgaben',
}

export enum ZuordnungKontoKlasseType {
  EINNAHMEN = 'Einnahmen',
  AUSGABEN = 'Ausgaben',
  VERRECHNUNG = 'Verrechnung',
}

export const isZuordnungKontoKlasseTypeEinnahmen = (zuordnungKontoKlasseType: ZuordnungKontoKlasseType) =>
  zuordnungKontoKlasseType === ZuordnungKontoKlasseType.EINNAHMEN;
export const isZuordnungKontoKlasseTypeAusgaben = (zuordnungKontoKlasseType: ZuordnungKontoKlasseType) =>
  zuordnungKontoKlasseType === ZuordnungKontoKlasseType.AUSGABEN;
export const isZuordnungKontoKlasseTypeVerrechnung = (zuordnungKontoKlasseType: ZuordnungKontoKlasseType) =>
  zuordnungKontoKlasseType === ZuordnungKontoKlasseType.VERRECHNUNG;

export const getZuordnungAbrKreisName = (name: string, isAufwandskonto: boolean, isAbrKreisRepFonds: boolean, isUstVomAufwand: boolean) =>
  isAufwandskonto && isAbrKreisRepFonds ? `${name} ${repFondVerwendungText(isUstVomAufwand)}` : `${name}`;

export const zuordnungFormFieldArrayListItemStyle = (
  informationNumber: number,
  isActiveBuchungszeilen?: boolean,
  isActiveRepFonds?: boolean,
  isActiveDivergentDistributionKey?: boolean,
  isExpenseReducing?: boolean
) => ({
  height: getZuordnungKontoListItemHeight(informationNumber),
  background:
    isActiveBuchungszeilen || isActiveRepFonds || isActiveDivergentDistributionKey || isExpenseReducing
      ? theme.tableBackgroundColors.highlighted
      : 'inherit',
});

export const getZuordnungKontoListItemHeight = (filtersCount: number) => {
  if (filtersCount === 1) {
    return '70px';
  } else if (filtersCount === 2) {
    return '90px';
  } else if (filtersCount === 3) {
    return '110px';
  } else if (filtersCount === 4) {
    return '130px';
  }
  return '58px';
};

export const getBuchungszeilenAnzeigenText = (buchungszeilenAnzeigen: boolean) => {
  if (buchungszeilenAnzeigen) {
    return BuchungszeilenAnzeigenTuple.ANZEIGEN;
  }
  return BuchungszeilenAnzeigenTuple.AUSBLENDEN;
};

export const getVerwendungVonRepFondsText = (verwendungVonRepFonds: boolean) => {
  if (verwendungVonRepFonds) {
    return VerwendungVonRepFondsTuple.UST_VOM_AUFWAND;
  }
  return VerwendungVonRepFondsTuple.AUSGABEN;
};
