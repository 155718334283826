import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { VorschreibungspositionVersionFragmentDoc } from './VorschreibungspositionFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorschreibungspositionVersionListQueryVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type VorschreibungspositionVersionListQuery = {
  getVorschreibungspositionVersionList: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      validFrom: string;
      vorschreibungspositionVersionId: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        validFrom: string;
        vorschreibungspositionVersionId: string;
        kontierungstabelle: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        umsatzsteuerkennzeichen: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }> | null;
      kontierungstabelle: {
        basiskonto: number;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kontierungstabelleId: string;
        updatedByMitarbeiterId?: string | null;
        errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
        status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
        subAdministrationExpenseAccount?: {
          bezeichnung: string;
          firmendatenId?: string | null;
          kontoId: string;
          kontoKlasse: string;
          nummer: string;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      umsatzsteuerkennzeichen: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      };
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VorschreibungspositionVersionListDocument = gql`
  query VorschreibungspositionVersionList($vorschreibungspositionId: ID!, $withDetails: Boolean) {
    getVorschreibungspositionVersionList(vorschreibungspositionId: $vorschreibungspositionId, withDetails: $withDetails) {
      data {
        ...VorschreibungspositionVersion
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungspositionVersionFragmentDoc}
`;
export function useVorschreibungspositionVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<VorschreibungspositionVersionListQuery, VorschreibungspositionVersionListQueryVariables> &
    ({ variables: VorschreibungspositionVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungspositionVersionListQuery, VorschreibungspositionVersionListQueryVariables>(
    VorschreibungspositionVersionListDocument,
    options
  );
}
export function useVorschreibungspositionVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorschreibungspositionVersionListQuery, VorschreibungspositionVersionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungspositionVersionListQuery, VorschreibungspositionVersionListQueryVariables>(
    VorschreibungspositionVersionListDocument,
    options
  );
}
export function useVorschreibungspositionVersionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VorschreibungspositionVersionListQuery, VorschreibungspositionVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungspositionVersionListQuery, VorschreibungspositionVersionListQueryVariables>(
    VorschreibungspositionVersionListDocument,
    options
  );
}
export type VorschreibungspositionVersionListQueryHookResult = ReturnType<typeof useVorschreibungspositionVersionListQuery>;
export type VorschreibungspositionVersionListLazyQueryHookResult = ReturnType<typeof useVorschreibungspositionVersionListLazyQuery>;
export type VorschreibungspositionVersionListSuspenseQueryHookResult = ReturnType<typeof useVorschreibungspositionVersionListSuspenseQuery>;
export type VorschreibungspositionVersionListQueryResult = Apollo.QueryResult<
  VorschreibungspositionVersionListQuery,
  VorschreibungspositionVersionListQueryVariables
>;
