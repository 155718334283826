import React, { FC } from 'react';
import { useAsync } from 'react-async';
import { Col, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { LoginPageLayout as Layout } from '../../components/LayoutAndromeda';
import { ROUTE_STATE_ERROR_REASON_TOKEN_INVALID } from '../../helpers/routerHelper';
import { verifyTokenAndAcceptEmailChange } from '../../shared/api/UserApi';
import { navigateToErrorPageIfResourceNotFound } from '../../shared/Auth/authActions';
import { ErrorModel } from '../../helpers/errorAndWarningHelper';
import { User } from '../../types';
import { showSuccessMsgOther } from '../../components/message';
import { URI_LOGIN_PAGE } from '../../constants/loginUriPaths';
import { EmailVerificationPageRouteParams } from './routes';
import useLogout from '../../shared/Auth/useLogout';

const EmailVerificationPage: FC = () => {
  const navigate = useNavigate();
  const { token } = useParams() as EmailVerificationPageRouteParams;
  const { logout } = useLogout();

  const { isLoading } = useAsync({
    promiseFn: verifyTokenAndAcceptEmailChange,
    token,
    onResolve: (data: User) => {
      showSuccessMsgOther(`Ihre E-Mail-Adresse '${data.email}' wurde bestätigt.`);
      navigate(URI_LOGIN_PAGE);
      logout('unauthenticated');
    },
    onReject: (error) => {
      navigateToErrorPageIfResourceNotFound(error as ErrorModel, ROUTE_STATE_ERROR_REASON_TOKEN_INVALID, navigate, logout);
    },
  });

  return (
    <>
      <Helmet>
        <title>E-Mail bestätigen</title>
      </Helmet>
      <Layout>
        <Col xs={20} sm={16} md={16} lg={8} xl={6}>
          {isLoading ? <Spin spinning={isLoading} /> : null}
        </Col>
      </Layout>
    </>
  );
};

export default EmailVerificationPage;
