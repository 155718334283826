import React, { FC } from 'react';
import KontoBezugSelect from './KontoBezugSelect';
import KontoBezugOutputTags from './KontoBezugOutputTags';

type Props = {
  tags: string[];
  loading: boolean;
  isInEditMode: boolean;
  canBeHauptKonto: boolean;
  setShouldBeHaupt: React.Dispatch<React.SetStateAction<boolean>>;
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
};

const KontoBezugSelectAndTags: FC<Props> = ({ tags, loading, isInEditMode, canBeHauptKonto, setShouldBeHaupt, setTags }) => {
  return isInEditMode ? (
    <KontoBezugSelect tags={tags} loading={loading} canBeHauptKonto={canBeHauptKonto} setShouldBeHaupt={setShouldBeHaupt} setTags={setTags} />
  ) : (
    <KontoBezugOutputTags tagList={tags} />
  );
};

export default KontoBezugSelectAndTags;
