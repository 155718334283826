import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ZahlungsrueckstaendeListHeader } from '../../hauseigentuemerAbrechnung/hauseigentuemerAbrechnung-types';
import pdfStyles from '../../styles/pdfStyles';
import { columnWidthsAndAlignment } from './zr-table-styles';

type ZrColumnTitlesProps = {
  titles: ZahlungsrueckstaendeListHeader;
};
const ZrColumnTitles: FC<ZrColumnTitlesProps> = ({ titles }) => (
  <View
    style={[
      pdfStyles.row,
      pdfStyles.textNormal,
      pdfStyles.borderBottomSmall,
      {
        paddingTop: '10mm',
        fontWeight: 'bold',
      },
    ]}
  >
    <Text
      style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.bezeichnung.width, textAlign: columnWidthsAndAlignment.bezeichnung.textAlign }]}
    >
      {titles.bezeichnungText}
    </Text>
    <Text
      style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.topNummer.width, textAlign: columnWidthsAndAlignment.topNummer.textAlign }]}
    >
      {titles.topNummerText}
    </Text>
    <Text
      style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.kontoNummer.width, textAlign: columnWidthsAndAlignment.kontoNummer.textAlign }]}
    >
      {titles.kontoNummerText}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        { width: columnWidthsAndAlignment.letzteBuchungDate.width, textAlign: columnWidthsAndAlignment.letzteBuchungDate.textAlign },
      ]}
    >
      {titles.letzteBuchungDateText}
    </Text>
    <Text style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.dueDate.width, textAlign: columnWidthsAndAlignment.dueDate.textAlign }]}>
      {titles.dueDateText}
    </Text>
    <Text style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.saldo.width, textAlign: columnWidthsAndAlignment.saldo.textAlign }]}>
      {titles.saldoText}
    </Text>
  </View>
);

export default ZrColumnTitles;
