import { generatePath } from 'react-router-dom';
import { mapToQueryString, QUERY_PARAM_KEY, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { EbicsUserStatus } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { URI_SYS_SETTINGS } from '../../../../../constants/configurationUriPaths';

enum EbicsUserQueryParamKey {
  EBICS_BANK_CONFIG_ID = 'ebicsBankConfigId',
  STATUS = 'status',
  IBAN_LIST = 'ibanList',
  MITARBEITER_ID_LIST = 'mitarbeiterIdList',
}

export type TEbicsUserQueryParams = QueryParams<{
  ebicsBankConfigId?: string | null;
  status?: EbicsUserStatus | null;
  ibanList?: string[] | null;
  mitarbeiterIdList?: string[] | null;
  highlightedRowKey?: string | null;
}>;

export const updateQueryParams = (navigate: (path: string) => void, filters: TEbicsUserQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  navigate(generatePathToEbicsUserPage(filters, paginationParams));

export const generatePathToEbicsUserPage = (filters?: TEbicsUserQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_SYS_SETTINGS.ebicsUserPage)}?${mapToQueryString({
    [EbicsUserQueryParamKey.EBICS_BANK_CONFIG_ID]: filters?.ebicsBankConfigId,
    [EbicsUserQueryParamKey.STATUS]: filters?.status,
    [EbicsUserQueryParamKey.IBAN_LIST]: filters?.ibanList,
    [EbicsUserQueryParamKey.MITARBEITER_ID_LIST]: filters?.mitarbeiterIdList,
    [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: filters?.highlightedRowKey,
    ...paginationParams,
  })}`;
