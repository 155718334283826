import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';

import { isAdmin } from '../../../../security/permissionChecks';
import EasyCloudKonfiguration from './index';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';

const andromedaSysSettingsTokenPageRoute = (
  <Route path={URI_ANDROMEDA_SYS_SETTINGS.token} element={<AuthRoute hasPermissionFn={isAdmin} element={<EasyCloudKonfiguration />} />} />
);

export default andromedaSysSettingsTokenPageRoute;
