import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin, Steps } from 'antd';
import { DeleteWizard, WizardPageProps, wizardTypes } from '../wizardHelpers';
import EmailTemplateStep from './Steps/EmailTemplateStep/EmailTemplateStep';
import { generatePathToWizardUpdatePage } from '../wizardApiPaths';
import DetermineRecipientListStep from './Steps/DetermineRecipientListStep/DetermineRecipientListStep';
import RecipientListStep from './Steps/RecipientListStep/RecipientListStep';
import { useDeleteVorschreibungMailDeliveryWizardMutation } from './gql/VorschreibungMailDeliveryWizardMutations.types';
import { showSuccessMsgDelete } from '../../../components/message';
import confirmDeleteWizard from '../Shared/confirmDeleteWizard';

const VorschreibungMailDeliveryWizard: FC<WizardPageProps> = ({ wizardId, activeStep, onCancel }) => {
  let content = <Spin />;

  const navigate = useNavigate();
  const [runDelete] = useDeleteVorschreibungMailDeliveryWizardMutation();

  const onDelete: DeleteWizard = (isCompleted, setInProgress) => {
    if (isCompleted) return;
    confirmDeleteWizard(() => {
      if (setInProgress) setInProgress(true);
      runDelete({ variables: { vorschreibungMailDeliveryWizardId: wizardId } })
        .then(() => {
          showSuccessMsgDelete('Assistent');
          onCancel();
        })
        .finally(() => {
          if (setInProgress) setInProgress(false);
        });
    });
  };

  if (activeStep === 1) {
    content = (
      <EmailTemplateStep
        onCancel={onCancel}
        onComplete={() => {
          navigate(generatePathToWizardUpdatePage(wizardTypes.VorschreibungMailDelivery, wizardId, 2));
        }}
        onDelete={onDelete}
        wizardId={wizardId}
      />
    );
  }

  if (activeStep === 2) {
    content = (
      <DetermineRecipientListStep
        onCancel={onCancel}
        onComplete={() => {
          navigate(generatePathToWizardUpdatePage(wizardTypes.VorschreibungMailDelivery, wizardId, 3));
        }}
        onBack={() => {
          navigate(generatePathToWizardUpdatePage(wizardTypes.VorschreibungMailDelivery, wizardId, 1));
        }}
        onDelete={onDelete}
        wizardId={wizardId}
      />
    );
  }

  if (activeStep === 3) {
    content = (
      <RecipientListStep
        onCancel={onCancel}
        onComplete={onCancel}
        onBack={() => {
          navigate(generatePathToWizardUpdatePage(wizardTypes.VorschreibungMailDelivery, wizardId, 2));
        }}
        onDelete={onDelete}
        wizardId={wizardId}
      />
    );
  }

  return (
    <>
      <Steps type="navigation" size="small" current={activeStep - 1} className="site-navigation-steps">
        <Steps.Step title="Text und Anhänge" />
        <Steps.Step title="Empfänger ermitteln" />
        <Steps.Step title="Versand" />
      </Steps>
      {content}
    </>
  );
};

export default VorschreibungMailDeliveryWizard;
