import { AuftragGenerierlaufInput, Vorschreibungsperiode } from '../../../../types';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';

export const mapValuesForZinslisteCard = (objektId: string, vorschreibungsperiode: Vorschreibungsperiode): AuftragGenerierlaufInput => ({
  generierDatum: dayjsCustom().format('YYYY-MM-DD'),
  objektIdList: [objektId],
  vorschreibungsperiodeList: [
    {
      jahr: vorschreibungsperiode.jahr,
      monat: vorschreibungsperiode.monat,
    },
  ],
});
