import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentBuchungTypeListQueryVariables = Types.Exact<{
  belegSymbol?: Types.InputMaybe<Types.PaymentBelegSymbol>;
}>;

export type PaymentBuchungTypeListQuery = {
  getPaymentBuchungTypeList: {
    data: Array<{ text: string; value: Types.PaymentBuchungType }>;
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const PaymentBuchungTypeListDocument = gql`
  query PaymentBuchungTypeList($belegSymbol: PaymentBelegSymbol) {
    getPaymentBuchungTypeList(belegSymbol: $belegSymbol) {
      data {
        text
        value
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function usePaymentBuchungTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentBuchungTypeListQuery, PaymentBuchungTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentBuchungTypeListQuery, PaymentBuchungTypeListQueryVariables>(PaymentBuchungTypeListDocument, options);
}
export function usePaymentBuchungTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentBuchungTypeListQuery, PaymentBuchungTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentBuchungTypeListQuery, PaymentBuchungTypeListQueryVariables>(PaymentBuchungTypeListDocument, options);
}
export function usePaymentBuchungTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentBuchungTypeListQuery, PaymentBuchungTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentBuchungTypeListQuery, PaymentBuchungTypeListQueryVariables>(PaymentBuchungTypeListDocument, options);
}
export type PaymentBuchungTypeListQueryHookResult = ReturnType<typeof usePaymentBuchungTypeListQuery>;
export type PaymentBuchungTypeListLazyQueryHookResult = ReturnType<typeof usePaymentBuchungTypeListLazyQuery>;
export type PaymentBuchungTypeListSuspenseQueryHookResult = ReturnType<typeof usePaymentBuchungTypeListSuspenseQuery>;
export type PaymentBuchungTypeListQueryResult = Apollo.QueryResult<PaymentBuchungTypeListQuery, PaymentBuchungTypeListQueryVariables>;
