import { FormFields } from '../../../../../../helpers/formikHelper';
import { SubAdministrationSettings } from '../../../../../../types';

export interface SubSettingsFormValues {
  paymentClearsIncomingInvoice: boolean;
}

export const subSettingsFormValuesInitialValues = (subAdministrationSettings: SubAdministrationSettings): SubSettingsFormValues => ({
  paymentClearsIncomingInvoice: subAdministrationSettings.paymentClearsIncomingInvoice ?? false,
});

export const subSettingsFormFields: FormFields<SubSettingsFormValues> = {
  paymentClearsIncomingInvoice: 'paymentClearsIncomingInvoice',
};
