import React, { FC } from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { generatePath } from 'react-router';
import {
  DeleteOutlined,
  EllipsisOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  HistoryOutlined,
  LockOutlined,
  ReadOutlined,
  SendOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { showSuccessMsgDelete, showSuccessMsgEntsperrt, showSuccessMsgGesperrt, showSuccessMsgOther } from '../../../components/message';
import {
  useDeleteMahnungMutation,
  useEntsperrenMahnungMutation,
  useFreigebenMahnungMutation,
  useSperrenMahnungMutation,
} from '../gql/MahnungMutations.types';
import { isStatusGesperrt } from '../../../helpers/statusHelper';
import { useGoBack } from '../../../shared/GoBack/GoBackContext';
import { URI_MAHNUNG_PAGE } from '../mahnungUriPaths';
import { Mahnung, MahnungStatus } from '../../../types';
import { showSuccessMsgFreigegeben } from '../../../components/message/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useCreateMahnungAusgebenGenerierlaufMutation } from '../../Verarbeitung/gql/MahnungGenerierlauf/MahnungAusgebenGenerierlaufMutations.types';
import { useCreateMahnungVersendenGenerierlaufMutation } from '../../Verarbeitung/gql/MahnungGenerierlauf/MahnungVersendenGenerierlaufMutations.types';
import {
  showConfirmAusgeben,
  showConfirmDelete,
  showConfirmEntsperren,
  showConfirmFreigeben,
  showConfirmSperren,
  showConfirmVerbuchen,
  showConfirmVersenden,
} from '../shared/mahnungConfirmMessages';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import MahnungChangeHistoryListingTable from '../Listing/Table/MahnungChangeHistoryListingTable';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { useToggle } from '../../../hooks/useToggle';
import { useCreateMahnungVerbuchenGenerierlaufMutation } from '../../Verarbeitung/gql/MahnungGenerierlauf/MahnungVerbuchenGenerierlaufMutations.types';

type MahnungActionsProps = {
  mahnung: Mahnung;
  onActionSuccess: () => void;
};

const MahnungActions: FC<MahnungActionsProps> = ({ mahnung, onActionSuccess }) => {
  const { mahnungId } = mahnung;
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const entity = 'Mahnung';
  const goBack = useGoBack(generatePath(URI_MAHNUNG_PAGE));

  const [runAusgeben] = useCreateMahnungAusgebenGenerierlaufMutation({
    variables: {
      input: { mahnungId },
    },
    onCompleted: () => {
      showSuccessMsgOther(`${entity} wird ausgegeben.`);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDelete] = useDeleteMahnungMutation({
    variables: { mahnungId },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      goBack();
    },
  });

  const [runEntsperren] = useEntsperrenMahnungMutation({
    variables: { mahnungId },
    onCompleted: () => {
      showSuccessMsgEntsperrt(entity);
      onActionSuccess();
    },
  });

  const [runFreigeben] = useFreigebenMahnungMutation({
    variables: { mahnungId },
    onCompleted: () => {
      showSuccessMsgFreigegeben(entity);
      onActionSuccess();
    },
  });

  const [runSperren] = useSperrenMahnungMutation({
    variables: { mahnungId },
    onCompleted: () => {
      showSuccessMsgGesperrt(entity);
      onActionSuccess();
    },
  });

  const [runVersenden] = useCreateMahnungVersendenGenerierlaufMutation({
    variables: {
      input: { mahnungId, startVerbuchenGenerierlauf: true },
    },
    onCompleted: () => {
      showSuccessMsgOther(`${entity} wird versandt.`);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runVerbuchen] = useCreateMahnungVerbuchenGenerierlaufMutation({
    variables: {
      input: { mahnungId },
    },
    onCompleted: () => {
      showSuccessMsgOther(`${entity} wird verbucht.`);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Freigeben',
      onClick: () => showConfirmFreigeben(runFreigeben, true),
      icon: <FileDoneOutlined />,
      disabled: mahnung.status.value !== MahnungStatus.Erstellt,
    },
    {
      key: '2',
      label: 'Ausgeben',
      onClick: () => showConfirmAusgeben(runAusgeben, true),
      icon: <FilePdfOutlined />,
      disabled: mahnung.status.value !== MahnungStatus.Freigegeben,
    },
    {
      key: '3',
      label: 'Versenden',
      onClick: () => showConfirmVersenden(runVersenden, true),
      icon: <SendOutlined />,
      disabled: mahnung.status.value !== MahnungStatus.Ausgegeben,
    },
    {
      key: '4',
      label: 'Verbuchen',
      onClick: () => showConfirmVerbuchen(runVerbuchen, true),
      icon: <ReadOutlined />,
      disabled: mahnung.status.value !== MahnungStatus.Versandt,
    },
    {
      key: '5',
      label: isStatusGesperrt(mahnung.status) ? 'Entsperren' : 'Sperren',
      onClick: () => (isStatusGesperrt(mahnung.status) ? showConfirmEntsperren(runEntsperren) : showConfirmSperren(runSperren)),
      icon: isStatusGesperrt(mahnung.status) ? <UnlockOutlined /> : <LockOutlined />,
      disabled: ![MahnungStatus.Erstellt, MahnungStatus.Freigegeben, MahnungStatus.Gesperrt].includes(mahnung.status.value),
    },
    {
      key: '6',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '7',
      label: 'Löschen',
      onClick: () => showConfirmDelete(runDelete, true),
      icon: <DeleteOutlined />,
      disabled: ![MahnungStatus.Erstellt, MahnungStatus.Freigegeben].includes(mahnung.status.value),
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <MahnungChangeHistoryListingTable mahnungId={mahnungId} />
      </HistoryModal>
    </>
  );
};

export default MahnungActions;
