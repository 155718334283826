import { FC } from 'react';
import { DataCarrierCamt } from '../../../../../types';
import { StyledTableDataCarrierList } from './styled/DataCarrierListTable.style';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import ebicsDataCarrierDetailsTableColumns from './ebicsDataCarrierDetailsTableColumns';
import { useAuth } from '../../../../../shared/Auth/authContext';

type Props = {
  dataCarrierList: DataCarrierCamt[];
};

const EbicsDataCarrierDetailsTable: FC<Props> = ({ dataCarrierList }) => {
  const { activeForFirmendatenId } = useAuth();

  return dataCarrierList.length > 0 ? (
    <StyledTableDataCarrierList
      dataSource={dataCarrierList}
      pagination={false}
      columns={ebicsDataCarrierDetailsTableColumns(activeForFirmendatenId as string)}
    />
  ) : (
    <TextForEmptyValue textToShow="minus" />
  );
};

export default EbicsDataCarrierDetailsTable;
