import React, { FC } from 'react';
import { Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ErrorData } from '../../types';

type Props = {
  error: string | string[] | ErrorData | ErrorData[];
};

const ErrorMessagePanel: FC<Props> = ({ error }) => {
  const messages = (Array.isArray(error) ? error : [error]).map((message) => {
    return typeof message === 'object' && 'message' in message ? message.message : message;
  });

  return (
    <Space
      style={{ marginBottom: 12, color: 'red', alignItems: 'flex-start', width: '100%' }}
      className="ant-form-item-has-error ant-form-item-explain"
      role="alert"
    >
      <ExclamationCircleOutlined style={{ fontSize: 14, position: 'relative', top: 1 }} />
      <Space role="alert" direction="vertical" size={2}>
        {messages.map((message, index) => {
          return (
            <span style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }} key={index}>
              {message}
            </span>
          );
        })}
      </Space>
    </Space>
  );
};
export default ErrorMessagePanel;
