import React, { FC } from 'react';
import classnames from 'classnames';
import { TABLE_ALIGNED_COLS_CLASSNAMES } from './tableWithAlignedColsClassnames';

type CellButtonProps = {
  isOpen: boolean;
  onClick: () => void;
  positionOfButton: 0 | 1 | 2 | 3;
  isHeaderCell?: boolean;
};

const POSSIBLE_MARGIN_LEFT_PERCENTAGE = ['0%', '12.5%', '25%', '50%'];

const event = new Event('onRowToggle');

export const CellButton: FC<CellButtonProps> = ({ isOpen, onClick, positionOfButton, isHeaderCell }) => {
  const content = (
    <button
      type="button"
      onClick={() => {
        window.dispatchEvent(event);
        onClick();
      }}
      data-testid={`button-level-${positionOfButton}`}
      className={classnames(
        TABLE_ALIGNED_COLS_CLASSNAMES[`level${positionOfButton}`],
        ` ant-table-row-expand-icon ${isOpen ? 'ant-table-row-expand-icon-expanded' : 'ant-table-row-expand-icon-collapsed'}`
      )}
      style={{ marginLeft: POSSIBLE_MARGIN_LEFT_PERCENTAGE[positionOfButton] }}
    />
  );
  return isHeaderCell ? (
    <th className="ant-table-cell" style={{ position: 'relative' }}>
      {content}
    </th>
  ) : (
    <td className="ant-table-cell" style={{ position: 'relative' }}>
      {content}
    </td>
  );
};
