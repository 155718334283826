import React, { FC } from 'react';
import { TabsProps } from 'antd';
import TicketChangeEntryListing from './ChangeEntryListing/TicketChangeEntryListing';
import { Ticket } from '../../../types';
import TicketCommentListing from './CommentListing/TicketCommentListing';
import RouteTabs from '../../../components/Tabs/RouteTabs';
import { pathsToTicketDetailsPage } from '../ticketUriPaths';

type Props = {
  ticket: Ticket;
};

const TicketDetailsChangeEntryTab: FC<Props> = ({ ticket }) => {
  const paths = pathsToTicketDetailsPage(ticket);

  const items: TabsProps['items'] = [
    {
      key: paths.activityCommon,
      label: 'Verlauf',
      children: <TicketChangeEntryListing ticket={ticket} />,
    },
    {
      key: paths.activityComments,
      label: 'Kommentar',
      children: <TicketCommentListing ticket={ticket} />,
    },
  ];
  return <RouteTabs items={items} />;
};

export default TicketDetailsChangeEntryTab;
