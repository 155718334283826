import React, { FC } from 'react';
import { Spin } from 'antd';
import { useVorschreibungMailDeliveryWizardDetermineRecipientListStepQuery } from '../../gql/VorschreibungMailDeliveryWizardQueries.types';
import { DeleteWizard } from '../../../wizardHelpers';
import DetermineRecipientListGenerierlauf from './DetermineRecipientListGenerierlauf';

type Props = {
  onBack: () => void;
  onCancel: () => void;
  onComplete: () => void;
  onDelete: DeleteWizard;
  wizardId: string;
};

const DetermineRecipientListStep: FC<Props> = ({ wizardId, onComplete, onCancel, onBack, onDelete }) => {
  const { data } = useVorschreibungMailDeliveryWizardDetermineRecipientListStepQuery({
    variables: { vorschreibungMailDeliveryWizardId: wizardId },
  });

  const determineRecipientListData = data?.getVorschreibungMailDeliveryWizardDetermineRecipientListStep.data;

  if (!determineRecipientListData) {
    return <Spin />;
  }

  return (
    <DetermineRecipientListGenerierlauf
      determineRecipientListData={determineRecipientListData}
      onBack={onBack}
      onCancel={onCancel}
      onComplete={onComplete}
      onDelete={onDelete}
    />
  );
};

export default DetermineRecipientListStep;
