import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, MenuProps, Typography } from 'antd';
import { TDeviationCell, TDeviationReference } from '../../../deviation.model';
import { generateURIforDeviation } from '../../../deviationHelpers';
import { DeviationCellStyledText, deviationCellTextColor } from '../../../Styled/Deviation.style';
import DataWithShortenedText from '../../../../Helpers/DataWithShortenedText';

type Props = {
  cell: TDeviationCell;
  referenceOfCauseList: TDeviationReference[];
  altTextForMultipleReferenceOfCause?: string;
};

const ColWithMultipleReferenceOfCause: FC<Props> = ({ cell, referenceOfCauseList, altTextForMultipleReferenceOfCause }) => {
  const items: MenuProps['items'] = referenceOfCauseList.map((referenceOfCause, index) => ({
    key: index,
    label: (
      <DataWithShortenedText text={referenceOfCause.text} maxTextLength={10}>
        {(shortenedText) =>
          referenceOfCause.uriParams ? (
            <Link to={generateURIforDeviation(referenceOfCause.uriParams)} target="_blank">
              {shortenedText}
            </Link>
          ) : (
            <Typography.Text>{shortenedText}</Typography.Text>
          )
        }
      </DataWithShortenedText>
    ),
  }));

  return (
    <Dropdown menu={{ items, style: { position: 'absolute', left: '-44px', width: '104px' } }}>
      <DeviationCellStyledText underline strong={cell.hasChanged} style={{ color: deviationCellTextColor(cell) }}>
        {altTextForMultipleReferenceOfCause ?? 'Bezüge...'}
      </DeviationCellStyledText>
    </Dropdown>
  );
};

export default ColWithMultipleReferenceOfCause;
