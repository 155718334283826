import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_EMAIL_VERSAND_DETAILS_PAGE } from '../../features/EmailDelivery/emailDeliveryUriPaths';
import EmailVersandDetailsPage from './EmailVersandDetailsPage';

export type EmailVersandDetailsPageRouteParams = {
  mailId: string;
};

const emailVersandDetailsPageRoute = (
  <Route
    path={`${URI_EMAIL_VERSAND_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<EmailVersandDetailsPage />} />}
  />
);

export default emailVersandDetailsPageRoute;
