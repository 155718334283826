import { message } from 'antd';
import Fetcher from '../../../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../../../helpers/APIUtils';
import { showSuccessMsgOther } from '../../../../../components/message';
import { mapErrorToErrorMessage } from '../../../../../helpers/errorAndWarningHelper';
import { EmailTemplateStepFormValues, TemplateStepInput } from './emailTemplateStepFormMapper';

export const handleUpdateSubmit = (
  firmendatenId: string,
  wizardId: string,
  values: EmailTemplateStepFormValues,
  input: TemplateStepInput,
  setSubmitting: (isSubmitting: boolean) => void,
  onSuccess: () => void
) => {
  const formData = new FormData();
  formData.append(
    'vorschreibungMail',
    new Blob([JSON.stringify({ data: { ...input } })], {
      type: 'application/json',
    }),
    'payload'
  );

  if (values.newAttachmentList.length > 0) {
    values.newAttachmentList.forEach((attachment) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      formData.append('newAttachmentList', attachment.file!, attachment.filename);
    });
  }

  setSubmitting(true);
  return Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/vorschreibung-mail-delivery-wizards/${wizardId}/template-step`, {
      method: 'PUT',
      body: formData,
    })
    .then(processResponse)
    .then(() => {
      if (input.startNextStep) {
        showSuccessMsgOther(`Assistent wurde erfolgreich erstellt`);
      } else {
        showSuccessMsgOther(`Assistent wurde erfolgreich gespeichert`);
      }
      onSuccess();
    })
    .catch(processAndReturnError)
    .catch((error) => {
      return message.error({
        content: `Assistent konnte nicht erstellt werden: ${mapErrorToErrorMessage(error)}`,
      });
    })
    .finally(() => {
      setSubmitting(false);
    });
};
