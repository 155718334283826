import { isEmpty } from 'lodash';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { toFormattedDecimal } from '../../../../../components/Input-antd';
import { BeAufteilingsschluesselWert, BeAufteilingsschluesselWertMitBezugInput, EnumData } from '../../../../../types';
import { createValidFromForNewEntry, initialValuesBestandseinheitAufteilungsschluesselWert } from '../shared/beAufteilungsschluesselWertHelper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';

export type BeAufteilungsschluesselWertMitBezugFormValues = {
  beAufteilungsschluesselWertId?: string | null;
  validFrom: string;
  isUsed: boolean;
  lastWert?: number;
  type: string;
  wert?: string;
};

export const beAufteilungsschluesselWertMitBezugFormFields: FormFields<BeAufteilungsschluesselWertMitBezugFormValues> = {
  beAufteilungsschluesselWertId: 'beAufteilungsschluesselWertId',
  validFrom: 'validFrom',
  isUsed: 'isUsed',
  lastWert: 'lastWert',
  type: 'type',
  wert: 'wert',
};

export const mapWertMitBezugToFormValues = (
  bezugsBasisType: EnumData,
  wertList: BeAufteilingsschluesselWert[],
  aufteilungsschluesselWert: BeAufteilingsschluesselWert
): BeAufteilungsschluesselWertMitBezugFormValues => {
  return {
    validFrom: aufteilungsschluesselWert.validFrom ? aufteilungsschluesselWert.validFrom : dayjsCustom().format('YYYY-MM-DD'),
    wert: toFormattedDecimal(aufteilungsschluesselWert.wert) ?? toFormattedDecimal(0),
    isUsed: aufteilungsschluesselWert.aktiv,
    lastWert: 0,
    beAufteilungsschluesselWertId: aufteilungsschluesselWert.beAufteilungsschluesselWertId,
    type: bezugsBasisType.value,
  };
};

export const mapFormValuesToWertMitBezug = (values: BeAufteilungsschluesselWertMitBezugFormValues): BeAufteilingsschluesselWertMitBezugInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  status: values.isUsed ? 'AKTIV' : 'INAKTIV',
});

export const createDefaultNewEntryForWertMitBezug = (wertList: BeAufteilingsschluesselWert[]): BeAufteilingsschluesselWert => {
  if (!isEmpty(wertList)) {
    return {
      ...wertList[0],
      validFrom: createValidFromForNewEntry(wertList),
      beAufteilungsschluesselWertId: '',
      aktiv: !wertList[0].aktiv,
      wert: wertList[0].aktiv ? 0 : wertList[0].wert,
    };
  }
  return initialValuesBestandseinheitAufteilungsschluesselWert;
};
