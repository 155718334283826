import {
  AbrechnungsdefinitionListEntry,
  BkAbrechnungsdefinitionUpdateInput,
  HeAbrechnungsdefinitionUpdateInput,
  WeAbrechnungsdefinitionUpdateInput,
} from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';

export type TAbrechnungsdefinitionUpdateInput =
  | WeAbrechnungsdefinitionUpdateInput
  | BkAbrechnungsdefinitionUpdateInput
  | HeAbrechnungsdefinitionUpdateInput;

export type AbrechnungsdefinitionUpdateFormValues = {
  bezeichnung: string;
  firmendatenId?: string | null;
};

export const abrechnungsdefinitionUpdateFormFields: FormFields<AbrechnungsdefinitionUpdateFormValues> = {
  bezeichnung: 'bezeichnung',
  firmendatenId: 'firmendatenId',
};

export const mapAbrechnunsdefinitionToFormValues = (
  abrechnungsdefinition: AbrechnungsdefinitionListEntry
): AbrechnungsdefinitionUpdateFormValues => ({
  bezeichnung: abrechnungsdefinition.bezeichnung,
  firmendatenId: abrechnungsdefinition.firmendatenId,
});

export const mapFormValuesToAbrechnungsdefinitonUpdate = (formValues: WeAbrechnungsdefinitionUpdateInput): TAbrechnungsdefinitionUpdateInput => ({
  bezeichnung: formValues.bezeichnung,
});
