import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { VUstData } from '../../vorschreibung-types';
import pdfStyles from '../../../styles/pdfStyles';
import { ustColumnWidthsAndAlignment } from './ust-table-styles';

const VTUstTable: FC<{ data: VUstData[] }> = ({ data }) => (
  <View style={pdfStyles.borderBottomSmall}>
    {data.map((ustData, index) => (
      <View style={pdfStyles.row} key={index}>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: ustColumnWidthsAndAlignment.ustSatz.width,
              textAlign: ustColumnWidthsAndAlignment.ustSatz.textAlign,
            },
          ]}
        >
          {ustData.ustSatz}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: ustColumnWidthsAndAlignment.netto.width,
              textAlign: ustColumnWidthsAndAlignment.netto.textAlign,
            },
          ]}
        >
          {ustData.netto}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: ustColumnWidthsAndAlignment.ust.width,
              textAlign: ustColumnWidthsAndAlignment.ust.textAlign,
            },
          ]}
        >
          {ustData.ust}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: ustColumnWidthsAndAlignment.brutto.width,
              textAlign: ustColumnWidthsAndAlignment.brutto.textAlign,
            },
          ]}
        >
          {ustData.brutto}
        </Text>
      </View>
    ))}
  </View>
);

export default VTUstTable;
