export const filterOptionForParticipantSelect = (input: string, option: any) => {
  const optionTextValue = getOptionTextContent(option);
  if (optionTextValue) {
    const textValueWithoutSpaces = optionTextValue.replace(/\s/g, '');
    const inputWithoutSpaces = input.replace(/\s/g, '');
    return textValueWithoutSpaces.toLowerCase().includes(inputWithoutSpaces.toLowerCase());
  }
  return false;
};

const getOptionTextContent = (option: any) => {
  if (option.label && typeof option.label === 'string') {
    return option.label;
  }
  if (option.props?.children && Array.isArray(option.props.children)) {
    const allTextInOption = option.props.children.reduce((acc: string, item: any) => {
      const textValue = getOptionTextContent(item);
      return textValue ? acc.concat(textValue) : acc;
    }, '');
    return allTextInOption ?? null;
  }
  if (option.label?.props?.children && Array.isArray(option.label.props.children)) {
    const allTextInOption = option.label.props.children.reduce((acc: string, item: any) => {
      const textValue = getOptionTextContent(item);
      return textValue ? acc.concat(textValue) : acc;
    }, '');
    return allTextInOption ?? null;
  }
  return null;
};
