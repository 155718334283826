import React from 'react';
import { ContactsOutlined, CopyOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import DocumentTab from '../../features/Document/DocumentTab';
import { DEVIATIONS_MENU_LIST_ITEM_TITLE } from '../../components/Deviation/deviationHelpers';
import { pathsToHeAbrechnungDetails } from '../../features/Abrechnung/He/heAbrechnungUriPaths';
import HeAbrechnungDeviationList from '../../features/Abrechnung/Deviation/HeAbrechnung/HeAbrechnungDeviationList';
import HeAbrDetailsTabs from '../../features/Abrechnung/He/Details/HeAbrDetailsTabs';
import { AbrechnungStatus, HeAbrechnung } from '../../types';
import { DOCUMENT_FILTERS_SETTINGS } from '../../features/Document/Listing/Filters/listingFiltersHelper';

const menuListHeAbr = (abrechnung: HeAbrechnung, refetch: () => Promise<any>, objektId: string, loading: boolean): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Abrechnungen',
      icon: <ContactsOutlined />,
      uri: pathsToHeAbrechnungDetails(objektId, abrechnung.heAbrechnungId).heAbr,
      content: (props) => <HeAbrDetailsTabs abrechnung={abrechnung} objektId={objektId} loading={loading} {...props} />,
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: `${pathsToHeAbrechnungDetails(objektId, abrechnung.heAbrechnungId).abrechnungDetails}/documents`,
      content: (props) => (
        <DocumentTab
          heAbrechnungId={abrechnung.heAbrechnungId}
          disabledFilters={DOCUMENT_FILTERS_SETTINGS.heAbrechnung.disabled}
          defaultFilters={DOCUMENT_FILTERS_SETTINGS.heAbrechnung.default}
          {...props}
        />
      ),
    },
    {
      state: abrechnung.status.value === AbrechnungStatus.Invalid ? 'error' : undefined,
      title: DEVIATIONS_MENU_LIST_ITEM_TITLE,
      icon: <ThunderboltOutlined />,
      uri: pathsToHeAbrechnungDetails(objektId, abrechnung.heAbrechnungId).deviations,
      content: () => (
        <HeAbrechnungDeviationList objektId={objektId} heAbrechnungId={abrechnung.heAbrechnungId} status={abrechnung.status} onAction={refetch} />
      ),
    },
  ],
});

export default menuListHeAbr;
