import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AufteilungsschluesselTemplateListQueryVariables = Types.Exact<{
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AufteilungsschluesselTemplateListQuery = {
  getAufteilungsschluesselTemplateList: {
    data: Array<{
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AufteilungsschluesselTemplateQueryVariables = Types.Exact<{
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type AufteilungsschluesselTemplateQuery = {
  getAufteilungsschluesselTemplate: {
    data: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AufteilungsschluesselTemplateChangeHistoryListQueryVariables = Types.Exact<{
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type AufteilungsschluesselTemplateChangeHistoryListQuery = {
  getAufteilungsschluesselTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const AufteilungsschluesselTemplateListDocument = gql`
  query AufteilungsschluesselTemplateList($withDetails: Boolean, $includeArchiviert: Boolean) {
    getAufteilungsschluesselTemplateList(withDetails: $withDetails, includeArchiviert: $includeArchiviert) {
      data {
        ...AufteilungsschluesselFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useAufteilungsschluesselTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<AufteilungsschluesselTemplateListQuery, AufteilungsschluesselTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AufteilungsschluesselTemplateListQuery, AufteilungsschluesselTemplateListQueryVariables>(
    AufteilungsschluesselTemplateListDocument,
    options
  );
}
export function useAufteilungsschluesselTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AufteilungsschluesselTemplateListQuery, AufteilungsschluesselTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AufteilungsschluesselTemplateListQuery, AufteilungsschluesselTemplateListQueryVariables>(
    AufteilungsschluesselTemplateListDocument,
    options
  );
}
export function useAufteilungsschluesselTemplateListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AufteilungsschluesselTemplateListQuery, AufteilungsschluesselTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AufteilungsschluesselTemplateListQuery, AufteilungsschluesselTemplateListQueryVariables>(
    AufteilungsschluesselTemplateListDocument,
    options
  );
}
export type AufteilungsschluesselTemplateListQueryHookResult = ReturnType<typeof useAufteilungsschluesselTemplateListQuery>;
export type AufteilungsschluesselTemplateListLazyQueryHookResult = ReturnType<typeof useAufteilungsschluesselTemplateListLazyQuery>;
export type AufteilungsschluesselTemplateListSuspenseQueryHookResult = ReturnType<typeof useAufteilungsschluesselTemplateListSuspenseQuery>;
export type AufteilungsschluesselTemplateListQueryResult = Apollo.QueryResult<
  AufteilungsschluesselTemplateListQuery,
  AufteilungsschluesselTemplateListQueryVariables
>;
export const AufteilungsschluesselTemplateDocument = gql`
  query AufteilungsschluesselTemplate($aufteilungsschluesselId: ID!) {
    getAufteilungsschluesselTemplate(aufteilungsschluesselId: $aufteilungsschluesselId) {
      data {
        ...AufteilungsschluesselFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useAufteilungsschluesselTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<AufteilungsschluesselTemplateQuery, AufteilungsschluesselTemplateQueryVariables> &
    ({ variables: AufteilungsschluesselTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AufteilungsschluesselTemplateQuery, AufteilungsschluesselTemplateQueryVariables>(
    AufteilungsschluesselTemplateDocument,
    options
  );
}
export function useAufteilungsschluesselTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AufteilungsschluesselTemplateQuery, AufteilungsschluesselTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AufteilungsschluesselTemplateQuery, AufteilungsschluesselTemplateQueryVariables>(
    AufteilungsschluesselTemplateDocument,
    options
  );
}
export function useAufteilungsschluesselTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AufteilungsschluesselTemplateQuery, AufteilungsschluesselTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AufteilungsschluesselTemplateQuery, AufteilungsschluesselTemplateQueryVariables>(
    AufteilungsschluesselTemplateDocument,
    options
  );
}
export type AufteilungsschluesselTemplateQueryHookResult = ReturnType<typeof useAufteilungsschluesselTemplateQuery>;
export type AufteilungsschluesselTemplateLazyQueryHookResult = ReturnType<typeof useAufteilungsschluesselTemplateLazyQuery>;
export type AufteilungsschluesselTemplateSuspenseQueryHookResult = ReturnType<typeof useAufteilungsschluesselTemplateSuspenseQuery>;
export type AufteilungsschluesselTemplateQueryResult = Apollo.QueryResult<
  AufteilungsschluesselTemplateQuery,
  AufteilungsschluesselTemplateQueryVariables
>;
export const AufteilungsschluesselTemplateChangeHistoryListDocument = gql`
  query AufteilungsschluesselTemplateChangeHistoryList($aufteilungsschluesselId: ID!) {
    getAufteilungsschluesselTemplateChangeHistoryList(aufteilungsschluesselId: $aufteilungsschluesselId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useAufteilungsschluesselTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    AufteilungsschluesselTemplateChangeHistoryListQuery,
    AufteilungsschluesselTemplateChangeHistoryListQueryVariables
  > &
    ({ variables: AufteilungsschluesselTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AufteilungsschluesselTemplateChangeHistoryListQuery, AufteilungsschluesselTemplateChangeHistoryListQueryVariables>(
    AufteilungsschluesselTemplateChangeHistoryListDocument,
    options
  );
}
export function useAufteilungsschluesselTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AufteilungsschluesselTemplateChangeHistoryListQuery,
    AufteilungsschluesselTemplateChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AufteilungsschluesselTemplateChangeHistoryListQuery, AufteilungsschluesselTemplateChangeHistoryListQueryVariables>(
    AufteilungsschluesselTemplateChangeHistoryListDocument,
    options
  );
}
export function useAufteilungsschluesselTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AufteilungsschluesselTemplateChangeHistoryListQuery,
        AufteilungsschluesselTemplateChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AufteilungsschluesselTemplateChangeHistoryListQuery, AufteilungsschluesselTemplateChangeHistoryListQueryVariables>(
    AufteilungsschluesselTemplateChangeHistoryListDocument,
    options
  );
}
export type AufteilungsschluesselTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useAufteilungsschluesselTemplateChangeHistoryListQuery>;
export type AufteilungsschluesselTemplateChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useAufteilungsschluesselTemplateChangeHistoryListLazyQuery
>;
export type AufteilungsschluesselTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useAufteilungsschluesselTemplateChangeHistoryListSuspenseQuery
>;
export type AufteilungsschluesselTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  AufteilungsschluesselTemplateChangeHistoryListQuery,
  AufteilungsschluesselTemplateChangeHistoryListQueryVariables
>;
