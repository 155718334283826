import { FC } from 'react';
import { useBestandseinheitChangeHistoryListQuery } from '../gql/BestandseinheitQueries.types';
import { HistoryType } from '../../History/shared/HistoryModal';
import HistoryListingTable from '../../History/HistoryListingTable';

type Props = {
  objektId: string;
  bestandseinheitId: string;
};

const BestandseinheitChangeHistoryListingTable: FC<Props> = ({ objektId, bestandseinheitId }) => {
  const { data, loading } = useBestandseinheitChangeHistoryListQuery({ variables: { objektId, bestandseinheitId } });
  const historyList = data?.getBestandseinheitChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="bestanseinheit" />;
};

export default BestandseinheitChangeHistoryListingTable;
