import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTicketTemplateMutationVariables = Types.Exact<{
  input: Types.TicketTemplateCreateInput;
}>;

export type CreateTicketTemplateMutation = {
  createTicketTemplate: {
    data: { ticketTemplateId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateTicketTemplateMutationVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
  input: Types.TicketTemplateUpdateInput;
}>;

export type UpdateTicketTemplateMutation = {
  updateTicketTemplate: {
    data: { ticketTemplateId: string; dueDateText?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteTicketTemplateMutationVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
}>;

export type DeleteTicketTemplateMutation = {
  deleteTicketTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type TicketTemplateCreateSubTicketTemplateMutationVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
  input: Types.TicketTemplateActionCreateSubTicketTemplateInput;
}>;

export type TicketTemplateCreateSubTicketTemplateMutation = {
  actionTicketTemplateCreateSubTicketTemplate: {
    data: { ticketTemplateId: string; dueDateText?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type TicketTemplateChangePositionMutationVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
  input: Types.TicketTemplateActionChangePositionInput;
}>;

export type TicketTemplateChangePositionMutation = {
  actionTicketTemplateChangePosition: {
    data: { name: string; ticketTemplateId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateTicketTemplateDocument = gql`
  mutation CreateTicketTemplate($input: TicketTemplateCreateInput!) {
    createTicketTemplate(input: $input) {
      data {
        ticketTemplateId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateTicketTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTicketTemplateMutation, CreateTicketTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTicketTemplateMutation, CreateTicketTemplateMutationVariables>(CreateTicketTemplateDocument, options);
}
export type CreateTicketTemplateMutationHookResult = ReturnType<typeof useCreateTicketTemplateMutation>;
export type CreateTicketTemplateMutationResult = Apollo.MutationResult<CreateTicketTemplateMutation>;
export type CreateTicketTemplateMutationOptions = Apollo.BaseMutationOptions<CreateTicketTemplateMutation, CreateTicketTemplateMutationVariables>;
export const UpdateTicketTemplateDocument = gql`
  mutation UpdateTicketTemplate($ticketTemplateId: ID!, $input: TicketTemplateUpdateInput!) {
    updateTicketTemplate(ticketTemplateId: $ticketTemplateId, input: $input) {
      data {
        ticketTemplateId
        dueDateText
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateTicketTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTicketTemplateMutation, UpdateTicketTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTicketTemplateMutation, UpdateTicketTemplateMutationVariables>(UpdateTicketTemplateDocument, options);
}
export type UpdateTicketTemplateMutationHookResult = ReturnType<typeof useUpdateTicketTemplateMutation>;
export type UpdateTicketTemplateMutationResult = Apollo.MutationResult<UpdateTicketTemplateMutation>;
export type UpdateTicketTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateTicketTemplateMutation, UpdateTicketTemplateMutationVariables>;
export const DeleteTicketTemplateDocument = gql`
  mutation DeleteTicketTemplate($ticketTemplateId: ID!) {
    deleteTicketTemplate(ticketTemplateId: $ticketTemplateId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteTicketTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTicketTemplateMutation, DeleteTicketTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTicketTemplateMutation, DeleteTicketTemplateMutationVariables>(DeleteTicketTemplateDocument, options);
}
export type DeleteTicketTemplateMutationHookResult = ReturnType<typeof useDeleteTicketTemplateMutation>;
export type DeleteTicketTemplateMutationResult = Apollo.MutationResult<DeleteTicketTemplateMutation>;
export type DeleteTicketTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTicketTemplateMutation, DeleteTicketTemplateMutationVariables>;
export const TicketTemplateCreateSubTicketTemplateDocument = gql`
  mutation TicketTemplateCreateSubTicketTemplate($ticketTemplateId: ID!, $input: TicketTemplateActionCreateSubTicketTemplateInput!) {
    actionTicketTemplateCreateSubTicketTemplate(ticketTemplateId: $ticketTemplateId, input: $input) {
      data {
        ticketTemplateId
        dueDateText
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useTicketTemplateCreateSubTicketTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<TicketTemplateCreateSubTicketTemplateMutation, TicketTemplateCreateSubTicketTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TicketTemplateCreateSubTicketTemplateMutation, TicketTemplateCreateSubTicketTemplateMutationVariables>(
    TicketTemplateCreateSubTicketTemplateDocument,
    options
  );
}
export type TicketTemplateCreateSubTicketTemplateMutationHookResult = ReturnType<typeof useTicketTemplateCreateSubTicketTemplateMutation>;
export type TicketTemplateCreateSubTicketTemplateMutationResult = Apollo.MutationResult<TicketTemplateCreateSubTicketTemplateMutation>;
export type TicketTemplateCreateSubTicketTemplateMutationOptions = Apollo.BaseMutationOptions<
  TicketTemplateCreateSubTicketTemplateMutation,
  TicketTemplateCreateSubTicketTemplateMutationVariables
>;
export const TicketTemplateChangePositionDocument = gql`
  mutation TicketTemplateChangePosition($ticketTemplateId: ID!, $input: TicketTemplateActionChangePositionInput!) {
    actionTicketTemplateChangePosition(ticketTemplateId: $ticketTemplateId, input: $input) {
      data {
        name
        ticketTemplateId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useTicketTemplateChangePositionMutation(
  baseOptions?: Apollo.MutationHookOptions<TicketTemplateChangePositionMutation, TicketTemplateChangePositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TicketTemplateChangePositionMutation, TicketTemplateChangePositionMutationVariables>(
    TicketTemplateChangePositionDocument,
    options
  );
}
export type TicketTemplateChangePositionMutationHookResult = ReturnType<typeof useTicketTemplateChangePositionMutation>;
export type TicketTemplateChangePositionMutationResult = Apollo.MutationResult<TicketTemplateChangePositionMutation>;
export type TicketTemplateChangePositionMutationOptions = Apollo.BaseMutationOptions<
  TicketTemplateChangePositionMutation,
  TicketTemplateChangePositionMutationVariables
>;
