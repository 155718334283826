import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import KontoTemplateForm from '../Form/KontoTemplateForm';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';

type Props = {
  showArchived: boolean;
  toggleShowArchived: () => void;
  refetch: () => Promise<any>;
};

const KontenrahmenActionButtonsAndDrawer: FC<Props> = ({ showArchived, toggleShowArchived, refetch }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
      <Button onClick={toggleShowArchived} size="small">
        Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}
      </Button>
      <Button icon={<PlusOutlined />} type="primary" onClick={onCollapse} size="small">
        Konto anlegen
      </Button>
      <Drawer title="Konto anlegen" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <KontoTemplateForm
          onSuccess={() => {
            refetch();
            onCollapse();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
    </ButtonsAligned>
  );
};

export default KontenrahmenActionButtonsAndDrawer;
