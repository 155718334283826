import {
  isAbrKreisTypeAusgaben,
  isAbrKreisTypeEigentuemerverrechnung,
  isAbrKreisTypeEinnahmen,
} from '../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import {
  isKontoKlasseEigenkapital,
  isKontoKlasseLieferverbindlichkeit,
  isKontoKlasseUmlaufVermoegen,
  isKontoVerwendungUmsatzsteuer,
  isKontoVerwendungVorsteuer,
} from '../Kontenplan/Form/kontoFormHelpers';
import { Abrechnungskreis, KontoKlasse, KontoVerwendung } from '../../types';

export const abrechnungskreisListFilteredByKlasseAndVerwendung = (
  abrechnungskreisList: Abrechnungskreis[],
  klasse?: KontoKlasse,
  verwendung?: KontoVerwendung
) => {
  const abrKreisListForUst = abrechnungskreisList.filter((abrKreis) => isAbrKreisTypeEinnahmen(abrKreis.type.value));
  const abrKreisListForVorsteuer = abrechnungskreisList.filter((abrKreis) => isAbrKreisTypeAusgaben(abrKreis.type.value));

  const abrKreisListForEigenkapital = abrechnungskreisList.filter((abrKreis) => isAbrKreisTypeEigentuemerverrechnung(abrKreis.type.value));

  if (isKontoKlasseLieferverbindlichkeit(klasse) && verwendung && isKontoVerwendungUmsatzsteuer(verwendung)) {
    return abrKreisListForUst;
  } else if (isKontoKlasseUmlaufVermoegen(klasse) && verwendung && isKontoVerwendungVorsteuer(verwendung)) {
    return abrKreisListForVorsteuer;
  } else if (isKontoKlasseEigenkapital(klasse)) {
    return abrKreisListForEigenkapital;
  } else return abrechnungskreisList;
};
