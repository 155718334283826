import React from 'react';
import { useParams } from 'react-router';
import { Skeleton } from 'antd';
import AndromedaSysSettingsWeAbrechnungsdefinitionVersionCreatePage from './AndromedaSysSettingsWeAbrechnungsdefinitionVersionCreatePage';
import {
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
  isAbrDefTypeWeAbrechnung,
} from '../../../../../../features/Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import AndromedaSysSettingsHeAbrechnungsdefinitionVersionCreatePage from './AndromedaSysSettingsHeAbrechnungsdefinitionVersionCreatePage';
import AndromedaSysSettingsBkAbrechnungsdefinitionVersionCreatePage from './AndromedaSysSettingsBkAbrechnungsdefinitionVersionCreatePage';
import AndromedaSysSettingsSubAdministrationAbrechnungsdefinitionVersionCreatePage from './AndromedaSysSettingsSubAdministrationAbrechnungsdefinitionVersionCreatePage';
import { useAbrechnungsdefinitionTemplateTypeQuery } from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/AbrDefTemplateQueries.types';
import { AbrechnungsdefinitionVersionCreatePageRouteParams } from '../../../../../SysSettingsPage/KundenSystem/AbrechnungsdefinitionPage/Version/Create/routes';

const AndromedaSysSettingsAbrechnungsdefinitionVersionCreatePage = () => {
  const { abrechnungsdefinitionId } = useParams() as AbrechnungsdefinitionVersionCreatePageRouteParams;

  const { loading, data } = useAbrechnungsdefinitionTemplateTypeQuery({ variables: { abrechnungsdefinitionId } });
  const abrDefType = data?.getAbrechnungsdefinitionTemplateType.data;

  if (!abrDefType || loading) {
    return <Skeleton active />;
  } else if (isAbrDefTypeWeAbrechnung(abrDefType.value)) {
    return <AndromedaSysSettingsWeAbrechnungsdefinitionVersionCreatePage abrechnungsdefinitionId={abrechnungsdefinitionId} />;
  } else if (isAbrDefTypeHeAbrechnung(abrDefType.value)) {
    return <AndromedaSysSettingsHeAbrechnungsdefinitionVersionCreatePage abrechnungsdefinitionId={abrechnungsdefinitionId} />;
  } else if (isAbrDefTypeSubAbrechnung(abrDefType.value)) {
    return <AndromedaSysSettingsSubAdministrationAbrechnungsdefinitionVersionCreatePage abrechnungsdefinitionId={abrechnungsdefinitionId} />;
  } else {
    return <AndromedaSysSettingsBkAbrechnungsdefinitionVersionCreatePage abrechnungsdefinitionId={abrechnungsdefinitionId} />;
  }
};

export default AndromedaSysSettingsAbrechnungsdefinitionVersionCreatePage;
