import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePersonenbezugMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  input: Types.PersonenbezugInput;
}>;

export type CreatePersonenbezugMutation = {
  createPersonenbezug: {
    data: { personenbezugId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type SetPersonenbezugTagListMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  personenbezugId: Types.Scalars['ID']['input'];
  input: Types.PersonenbezugTagListInput;
}>;

export type SetPersonenbezugTagListMutation = {
  actionSetPersonenbezugTagList: {
    data: { personenbezugId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeletePersonenbezugMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  personenbezugId: Types.Scalars['ID']['input'];
}>;

export type DeletePersonenbezugMutation = {
  deletePersonenbezug: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export const CreatePersonenbezugDocument = gql`
  mutation CreatePersonenbezug($rechtstraegerId: String!, $input: PersonenbezugInput!) {
    createPersonenbezug(rechtstraegerId: $rechtstraegerId, input: $input) {
      data {
        personenbezugId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreatePersonenbezugMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePersonenbezugMutation, CreatePersonenbezugMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePersonenbezugMutation, CreatePersonenbezugMutationVariables>(CreatePersonenbezugDocument, options);
}
export type CreatePersonenbezugMutationHookResult = ReturnType<typeof useCreatePersonenbezugMutation>;
export type CreatePersonenbezugMutationResult = Apollo.MutationResult<CreatePersonenbezugMutation>;
export type CreatePersonenbezugMutationOptions = Apollo.BaseMutationOptions<CreatePersonenbezugMutation, CreatePersonenbezugMutationVariables>;
export const SetPersonenbezugTagListDocument = gql`
  mutation SetPersonenbezugTagList($rechtstraegerId: String!, $personenbezugId: ID!, $input: PersonenbezugTagListInput!) {
    actionSetPersonenbezugTagList(rechtstraegerId: $rechtstraegerId, personenbezugId: $personenbezugId, input: $input) {
      data {
        personenbezugId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useSetPersonenbezugTagListMutation(
  baseOptions?: Apollo.MutationHookOptions<SetPersonenbezugTagListMutation, SetPersonenbezugTagListMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetPersonenbezugTagListMutation, SetPersonenbezugTagListMutationVariables>(SetPersonenbezugTagListDocument, options);
}
export type SetPersonenbezugTagListMutationHookResult = ReturnType<typeof useSetPersonenbezugTagListMutation>;
export type SetPersonenbezugTagListMutationResult = Apollo.MutationResult<SetPersonenbezugTagListMutation>;
export type SetPersonenbezugTagListMutationOptions = Apollo.BaseMutationOptions<
  SetPersonenbezugTagListMutation,
  SetPersonenbezugTagListMutationVariables
>;
export const DeletePersonenbezugDocument = gql`
  mutation deletePersonenbezug($rechtstraegerId: String!, $personenbezugId: ID!) {
    deletePersonenbezug(rechtstraegerId: $rechtstraegerId, personenbezugId: $personenbezugId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeletePersonenbezugMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePersonenbezugMutation, DeletePersonenbezugMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePersonenbezugMutation, DeletePersonenbezugMutationVariables>(DeletePersonenbezugDocument, options);
}
export type DeletePersonenbezugMutationHookResult = ReturnType<typeof useDeletePersonenbezugMutation>;
export type DeletePersonenbezugMutationResult = Apollo.MutationResult<DeletePersonenbezugMutation>;
export type DeletePersonenbezugMutationOptions = Apollo.BaseMutationOptions<DeletePersonenbezugMutation, DeletePersonenbezugMutationVariables>;
