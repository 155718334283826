import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { VertragVposIndexedValueFieldsFragmentDoc } from './VertragVposIndexedValueFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VertragVposIndexedValueListFirmendatenQueryVariables = Types.Exact<{
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  bestandseinheitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vorschreibungspositionName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statusList?: Types.InputMaybe<Array<Types.VertragVposIndexedValueStatus> | Types.VertragVposIndexedValueStatus>;
  validFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  validTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  valuePreservationTypeList?: Types.InputMaybe<Array<Types.ValuePreservationType> | Types.ValuePreservationType>;
  indexSeriesId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  order?: Types.InputMaybe<Types.VertragVposIndexedValueOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type VertragVposIndexedValueListFirmendatenQuery = {
  getVertragVposIndexedValueListFirmendaten: {
    data: {
      contentList: Array<{
        amountNew: number;
        amountOld: number;
        bestandseinheitBezeichnung: string;
        bestandseinheitId: string;
        calculatedAmountNew: number;
        calculatedFactorNew: number;
        calculatedFactorNewPercent: number;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        factorNew: number;
        factorNewPercent: number;
        indexNew: number;
        indexNewPreliminary: boolean;
        indexOld: number;
        indexOldPreliminary: boolean;
        indexSeriesId: string;
        indexSeriesName: string;
        monthYearNew: string;
        monthYearOld: string;
        name: string;
        objektId: string;
        objektKurzBezeichnung: string;
        rechnungsAusstellerId: string;
        rechnungsAusstellerKurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        validFrom: string;
        vertragId: string;
        vertragKurzBezeichnung: string;
        vertragVposIndexedValueId: string;
        vertragspartnerId: string;
        vertragspartnerKurzBezeichnung: string;
        vorschreibungspositionId: string;
        vposWertNewId?: string | null;
        vposWertOldId?: string | null;
        indexationAgreement:
          | {
              __typename: 'VertragVposIndexationAgreementCategory';
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              indexOld?: number | null;
              indexOldPreliminary: boolean;
              monthYearOld: string;
              updatedByMitarbeiterId?: string | null;
              validFrom: string;
              vertragVposIndexationAgreementId: string;
              basedOnUsableArea: boolean;
              indexSeries: {
                chainingFactor?: number | null;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                csvColumnImportId?: string | null;
                csvColumnIndexPoint?: string | null;
                csvColumnMonthYear?: string | null;
                csvRowImportId?: string | null;
                deletable: boolean;
                firmendatenId?: string | null;
                importUrl?: string | null;
                indexSeriesId: string;
                name: string;
                updatedByMitarbeiterId?: string | null;
                indexSeriesEntryList: Array<{
                  annualAverageFinal?: number | null;
                  annualAveragePreliminary?: number | null;
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  indexSeriesEntryId: string;
                  updatedByMitarbeiterId?: string | null;
                  year: number;
                  indexPointList: Array<{
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    finalValue?: number | null;
                    indexPointId: string;
                    month: string;
                    preliminaryValue?: number | null;
                    updatedByMitarbeiterId?: string | null;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                indexType: { text: string; value: Types.IndexType };
                sourceType: { text: string; value: Types.IndexSeriesSourceType };
                status?: { text: string; value: Types.IndexSeriesStatus } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              type: { text: string; value: Types.ValuePreservationType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'VertragVposIndexationAgreementIndexAnnual';
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              indexOld?: number | null;
              indexOldPreliminary: boolean;
              monthYearOld: string;
              updatedByMitarbeiterId?: string | null;
              validFrom: string;
              vertragVposIndexationAgreementId: string;
              annualAverage: boolean;
              minimumLimit?: number | null;
              shareInPercentToApply?: number | null;
              subsequentBilling: boolean;
              threshold: number;
              indexSeries: {
                chainingFactor?: number | null;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                csvColumnImportId?: string | null;
                csvColumnIndexPoint?: string | null;
                csvColumnMonthYear?: string | null;
                csvRowImportId?: string | null;
                deletable: boolean;
                firmendatenId?: string | null;
                importUrl?: string | null;
                indexSeriesId: string;
                name: string;
                updatedByMitarbeiterId?: string | null;
                indexSeriesEntryList: Array<{
                  annualAverageFinal?: number | null;
                  annualAveragePreliminary?: number | null;
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  indexSeriesEntryId: string;
                  updatedByMitarbeiterId?: string | null;
                  year: number;
                  indexPointList: Array<{
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    finalValue?: number | null;
                    indexPointId: string;
                    month: string;
                    preliminaryValue?: number | null;
                    updatedByMitarbeiterId?: string | null;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                indexType: { text: string; value: Types.IndexType };
                sourceType: { text: string; value: Types.IndexSeriesSourceType };
                status?: { text: string; value: Types.IndexSeriesStatus } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              type: { text: string; value: Types.ValuePreservationType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              effectiveWithMonth?: { text: string; value: Types.Month } | null;
              monthOfIndexPoint: { text: string; value: Types.Month };
              subsequentBillingObjektVorschreibungsposition?: {
                bezeichnung: string;
                objektVorschreibungspositionId: string;
                kurzBezeichnung: string;
              } | null;
              thresholdType: { text: string; value: Types.ThresholdType };
            }
          | {
              __typename: 'VertragVposIndexationAgreementIndexThreshold';
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              indexOld?: number | null;
              indexOldPreliminary: boolean;
              monthYearOld: string;
              updatedByMitarbeiterId?: string | null;
              validFrom: string;
              vertragVposIndexationAgreementId: string;
              effectiveAfterMonths?: number | null;
              minimumLimit?: number | null;
              shareInPercentToApply?: number | null;
              subsequentBilling: boolean;
              threshold: number;
              indexSeries: {
                chainingFactor?: number | null;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                csvColumnImportId?: string | null;
                csvColumnIndexPoint?: string | null;
                csvColumnMonthYear?: string | null;
                csvRowImportId?: string | null;
                deletable: boolean;
                firmendatenId?: string | null;
                importUrl?: string | null;
                indexSeriesId: string;
                name: string;
                updatedByMitarbeiterId?: string | null;
                indexSeriesEntryList: Array<{
                  annualAverageFinal?: number | null;
                  annualAveragePreliminary?: number | null;
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  indexSeriesEntryId: string;
                  updatedByMitarbeiterId?: string | null;
                  year: number;
                  indexPointList: Array<{
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    finalValue?: number | null;
                    indexPointId: string;
                    month: string;
                    preliminaryValue?: number | null;
                    updatedByMitarbeiterId?: string | null;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                indexType: { text: string; value: Types.IndexType };
                sourceType: { text: string; value: Types.IndexSeriesSourceType };
                status?: { text: string; value: Types.IndexSeriesStatus } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              type: { text: string; value: Types.ValuePreservationType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              subsequentBillingObjektVorschreibungsposition?: {
                bezeichnung: string;
                objektVorschreibungspositionId: string;
                kurzBezeichnung: string;
              } | null;
              thresholdType: { text: string; value: Types.ThresholdType };
            }
          | {
              __typename: 'VertragVposIndexationAgreementReferenceValue';
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              indexOld?: number | null;
              indexOldPreliminary: boolean;
              monthYearOld: string;
              updatedByMitarbeiterId?: string | null;
              validFrom: string;
              vertragVposIndexationAgreementId: string;
              indexSeries: {
                chainingFactor?: number | null;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                csvColumnImportId?: string | null;
                csvColumnIndexPoint?: string | null;
                csvColumnMonthYear?: string | null;
                csvRowImportId?: string | null;
                deletable: boolean;
                firmendatenId?: string | null;
                importUrl?: string | null;
                indexSeriesId: string;
                name: string;
                updatedByMitarbeiterId?: string | null;
                indexSeriesEntryList: Array<{
                  annualAverageFinal?: number | null;
                  annualAveragePreliminary?: number | null;
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  indexSeriesEntryId: string;
                  updatedByMitarbeiterId?: string | null;
                  year: number;
                  indexPointList: Array<{
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    finalValue?: number | null;
                    indexPointId: string;
                    month: string;
                    preliminaryValue?: number | null;
                    updatedByMitarbeiterId?: string | null;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                indexType: { text: string; value: Types.IndexType };
                sourceType: { text: string; value: Types.IndexSeriesSourceType };
                status?: { text: string; value: Types.IndexSeriesStatus } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              type: { text: string; value: Types.ValuePreservationType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
        status: { text: string; value: Types.VertragVposIndexedValueStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VertragVposIndexedValueListSummaryQueryVariables = Types.Exact<{
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  bestandseinheitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vorschreibungspositionName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statusList?: Types.InputMaybe<Array<Types.VertragVposIndexedValueStatus> | Types.VertragVposIndexedValueStatus>;
  validFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  validTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  valuePreservationTypeList?: Types.InputMaybe<Array<Types.ValuePreservationType> | Types.ValuePreservationType>;
  indexSeriesId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type VertragVposIndexedValueListSummaryQuery = {
  getVertragVposIndexedValueListSummary: {
    data: { sumAmountNew: number; sumAmountOld: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VertragVposIndexedValueListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  indexationAgreementId: Types.Scalars['ID']['input'];
}>;

export type VertragVposIndexedValueListQuery = {
  getVertragVposIndexedValueList: {
    data: Array<{
      amountNew: number;
      amountOld: number;
      bestandseinheitBezeichnung: string;
      bestandseinheitId: string;
      calculatedAmountNew: number;
      calculatedFactorNew: number;
      calculatedFactorNewPercent: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      factorNew: number;
      factorNewPercent: number;
      indexNew: number;
      indexNewPreliminary: boolean;
      indexOld: number;
      indexOldPreliminary: boolean;
      indexSeriesId: string;
      indexSeriesName: string;
      monthYearNew: string;
      monthYearOld: string;
      name: string;
      objektId: string;
      objektKurzBezeichnung: string;
      rechnungsAusstellerId: string;
      rechnungsAusstellerKurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      validFrom: string;
      vertragId: string;
      vertragKurzBezeichnung: string;
      vertragVposIndexedValueId: string;
      vertragspartnerId: string;
      vertragspartnerKurzBezeichnung: string;
      vorschreibungspositionId: string;
      vposWertNewId?: string | null;
      vposWertOldId?: string | null;
      indexationAgreement:
        | {
            __typename: 'VertragVposIndexationAgreementCategory';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            basedOnUsableArea: boolean;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'VertragVposIndexationAgreementIndexAnnual';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            annualAverage: boolean;
            minimumLimit?: number | null;
            shareInPercentToApply?: number | null;
            subsequentBilling: boolean;
            threshold: number;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            effectiveWithMonth?: { text: string; value: Types.Month } | null;
            monthOfIndexPoint: { text: string; value: Types.Month };
            subsequentBillingObjektVorschreibungsposition?: {
              bezeichnung: string;
              objektVorschreibungspositionId: string;
              kurzBezeichnung: string;
            } | null;
            thresholdType: { text: string; value: Types.ThresholdType };
          }
        | {
            __typename: 'VertragVposIndexationAgreementIndexThreshold';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            effectiveAfterMonths?: number | null;
            minimumLimit?: number | null;
            shareInPercentToApply?: number | null;
            subsequentBilling: boolean;
            threshold: number;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            subsequentBillingObjektVorschreibungsposition?: {
              bezeichnung: string;
              objektVorschreibungspositionId: string;
              kurzBezeichnung: string;
            } | null;
            thresholdType: { text: string; value: Types.ThresholdType };
          }
        | {
            __typename: 'VertragVposIndexationAgreementReferenceValue';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
      status: { text: string; value: Types.VertragVposIndexedValueStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CalculateVertragVposIndexedValueAmountQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  indexationAgreementId: Types.Scalars['ID']['input'];
  indexedValueId: Types.Scalars['ID']['input'];
  factorPercent: Types.Scalars['Float']['input'];
}>;

export type CalculateVertragVposIndexedValueAmountQuery = {
  calculateVertragVposIndexedValueAmount: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CalculateVertragVposIndexedValueFactorQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  indexationAgreementId: Types.Scalars['ID']['input'];
  indexedValueId: Types.Scalars['ID']['input'];
  amount: Types.Scalars['Float']['input'];
}>;

export type CalculateVertragVposIndexedValueFactorQuery = {
  calculateVertragVposIndexedValueFactor: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VertragVposIndexedValueChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  indexationAgreementId: Types.Scalars['ID']['input'];
  indexedValueId: Types.Scalars['ID']['input'];
}>;

export type VertragVposIndexedValueChangeHistoryListQuery = {
  getVertragVposIndexedValueChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const VertragVposIndexedValueListFirmendatenDocument = gql`
  query VertragVposIndexedValueListFirmendaten(
    $objektId: ID
    $bestandseinheitId: ID
    $vertragId: ID
    $rechnungsAusstellerId: ID
    $vertragspartnerId: ID
    $vorschreibungspositionName: String
    $statusList: [VertragVposIndexedValueStatus!]
    $validFrom: String
    $validTo: String
    $valuePreservationTypeList: [ValuePreservationType!]
    $indexSeriesId: ID
    $order: VertragVposIndexedValueOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getVertragVposIndexedValueListFirmendaten(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      rechnungsAusstellerId: $rechnungsAusstellerId
      vertragspartnerId: $vertragspartnerId
      vorschreibungspositionName: $vorschreibungspositionName
      statusList: $statusList
      validFrom: $validFrom
      validTo: $validTo
      valuePreservationTypeList: $valuePreservationTypeList
      indexSeriesId: $indexSeriesId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...VertragVposIndexedValueFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VertragVposIndexedValueFieldsFragmentDoc}
`;
export function useVertragVposIndexedValueListFirmendatenQuery(
  baseOptions?: Apollo.QueryHookOptions<VertragVposIndexedValueListFirmendatenQuery, VertragVposIndexedValueListFirmendatenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragVposIndexedValueListFirmendatenQuery, VertragVposIndexedValueListFirmendatenQueryVariables>(
    VertragVposIndexedValueListFirmendatenDocument,
    options
  );
}
export function useVertragVposIndexedValueListFirmendatenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragVposIndexedValueListFirmendatenQuery, VertragVposIndexedValueListFirmendatenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragVposIndexedValueListFirmendatenQuery, VertragVposIndexedValueListFirmendatenQueryVariables>(
    VertragVposIndexedValueListFirmendatenDocument,
    options
  );
}
export function useVertragVposIndexedValueListFirmendatenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VertragVposIndexedValueListFirmendatenQuery, VertragVposIndexedValueListFirmendatenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragVposIndexedValueListFirmendatenQuery, VertragVposIndexedValueListFirmendatenQueryVariables>(
    VertragVposIndexedValueListFirmendatenDocument,
    options
  );
}
export type VertragVposIndexedValueListFirmendatenQueryHookResult = ReturnType<typeof useVertragVposIndexedValueListFirmendatenQuery>;
export type VertragVposIndexedValueListFirmendatenLazyQueryHookResult = ReturnType<typeof useVertragVposIndexedValueListFirmendatenLazyQuery>;
export type VertragVposIndexedValueListFirmendatenSuspenseQueryHookResult = ReturnType<typeof useVertragVposIndexedValueListFirmendatenSuspenseQuery>;
export type VertragVposIndexedValueListFirmendatenQueryResult = Apollo.QueryResult<
  VertragVposIndexedValueListFirmendatenQuery,
  VertragVposIndexedValueListFirmendatenQueryVariables
>;
export const VertragVposIndexedValueListSummaryDocument = gql`
  query VertragVposIndexedValueListSummary(
    $objektId: ID
    $bestandseinheitId: ID
    $vertragId: ID
    $rechnungsAusstellerId: ID
    $vertragspartnerId: ID
    $vorschreibungspositionName: String
    $statusList: [VertragVposIndexedValueStatus!]
    $validFrom: String
    $validTo: String
    $valuePreservationTypeList: [ValuePreservationType!]
    $indexSeriesId: ID
  ) {
    getVertragVposIndexedValueListSummary(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      rechnungsAusstellerId: $rechnungsAusstellerId
      vertragspartnerId: $vertragspartnerId
      vorschreibungspositionName: $vorschreibungspositionName
      statusList: $statusList
      validFrom: $validFrom
      validTo: $validTo
      valuePreservationTypeList: $valuePreservationTypeList
      indexSeriesId: $indexSeriesId
    ) {
      data {
        sumAmountNew
        sumAmountOld
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVertragVposIndexedValueListSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<VertragVposIndexedValueListSummaryQuery, VertragVposIndexedValueListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragVposIndexedValueListSummaryQuery, VertragVposIndexedValueListSummaryQueryVariables>(
    VertragVposIndexedValueListSummaryDocument,
    options
  );
}
export function useVertragVposIndexedValueListSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragVposIndexedValueListSummaryQuery, VertragVposIndexedValueListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragVposIndexedValueListSummaryQuery, VertragVposIndexedValueListSummaryQueryVariables>(
    VertragVposIndexedValueListSummaryDocument,
    options
  );
}
export function useVertragVposIndexedValueListSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VertragVposIndexedValueListSummaryQuery, VertragVposIndexedValueListSummaryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragVposIndexedValueListSummaryQuery, VertragVposIndexedValueListSummaryQueryVariables>(
    VertragVposIndexedValueListSummaryDocument,
    options
  );
}
export type VertragVposIndexedValueListSummaryQueryHookResult = ReturnType<typeof useVertragVposIndexedValueListSummaryQuery>;
export type VertragVposIndexedValueListSummaryLazyQueryHookResult = ReturnType<typeof useVertragVposIndexedValueListSummaryLazyQuery>;
export type VertragVposIndexedValueListSummarySuspenseQueryHookResult = ReturnType<typeof useVertragVposIndexedValueListSummarySuspenseQuery>;
export type VertragVposIndexedValueListSummaryQueryResult = Apollo.QueryResult<
  VertragVposIndexedValueListSummaryQuery,
  VertragVposIndexedValueListSummaryQueryVariables
>;
export const VertragVposIndexedValueListDocument = gql`
  query VertragVposIndexedValueList(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $indexationAgreementId: ID!
  ) {
    getVertragVposIndexedValueList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
    ) {
      data {
        ...VertragVposIndexedValueFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VertragVposIndexedValueFieldsFragmentDoc}
`;
export function useVertragVposIndexedValueListQuery(
  baseOptions: Apollo.QueryHookOptions<VertragVposIndexedValueListQuery, VertragVposIndexedValueListQueryVariables> &
    ({ variables: VertragVposIndexedValueListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragVposIndexedValueListQuery, VertragVposIndexedValueListQueryVariables>(VertragVposIndexedValueListDocument, options);
}
export function useVertragVposIndexedValueListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragVposIndexedValueListQuery, VertragVposIndexedValueListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragVposIndexedValueListQuery, VertragVposIndexedValueListQueryVariables>(
    VertragVposIndexedValueListDocument,
    options
  );
}
export function useVertragVposIndexedValueListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VertragVposIndexedValueListQuery, VertragVposIndexedValueListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragVposIndexedValueListQuery, VertragVposIndexedValueListQueryVariables>(
    VertragVposIndexedValueListDocument,
    options
  );
}
export type VertragVposIndexedValueListQueryHookResult = ReturnType<typeof useVertragVposIndexedValueListQuery>;
export type VertragVposIndexedValueListLazyQueryHookResult = ReturnType<typeof useVertragVposIndexedValueListLazyQuery>;
export type VertragVposIndexedValueListSuspenseQueryHookResult = ReturnType<typeof useVertragVposIndexedValueListSuspenseQuery>;
export type VertragVposIndexedValueListQueryResult = Apollo.QueryResult<VertragVposIndexedValueListQuery, VertragVposIndexedValueListQueryVariables>;
export const CalculateVertragVposIndexedValueAmountDocument = gql`
  query CalculateVertragVposIndexedValueAmount(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $indexationAgreementId: ID!
    $indexedValueId: ID!
    $factorPercent: Float!
  ) {
    calculateVertragVposIndexedValueAmount(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      indexedValueId: $indexedValueId
      factorPercent: $factorPercent
    ) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCalculateVertragVposIndexedValueAmountQuery(
  baseOptions: Apollo.QueryHookOptions<CalculateVertragVposIndexedValueAmountQuery, CalculateVertragVposIndexedValueAmountQueryVariables> &
    ({ variables: CalculateVertragVposIndexedValueAmountQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalculateVertragVposIndexedValueAmountQuery, CalculateVertragVposIndexedValueAmountQueryVariables>(
    CalculateVertragVposIndexedValueAmountDocument,
    options
  );
}
export function useCalculateVertragVposIndexedValueAmountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalculateVertragVposIndexedValueAmountQuery, CalculateVertragVposIndexedValueAmountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalculateVertragVposIndexedValueAmountQuery, CalculateVertragVposIndexedValueAmountQueryVariables>(
    CalculateVertragVposIndexedValueAmountDocument,
    options
  );
}
export function useCalculateVertragVposIndexedValueAmountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CalculateVertragVposIndexedValueAmountQuery, CalculateVertragVposIndexedValueAmountQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CalculateVertragVposIndexedValueAmountQuery, CalculateVertragVposIndexedValueAmountQueryVariables>(
    CalculateVertragVposIndexedValueAmountDocument,
    options
  );
}
export type CalculateVertragVposIndexedValueAmountQueryHookResult = ReturnType<typeof useCalculateVertragVposIndexedValueAmountQuery>;
export type CalculateVertragVposIndexedValueAmountLazyQueryHookResult = ReturnType<typeof useCalculateVertragVposIndexedValueAmountLazyQuery>;
export type CalculateVertragVposIndexedValueAmountSuspenseQueryHookResult = ReturnType<typeof useCalculateVertragVposIndexedValueAmountSuspenseQuery>;
export type CalculateVertragVposIndexedValueAmountQueryResult = Apollo.QueryResult<
  CalculateVertragVposIndexedValueAmountQuery,
  CalculateVertragVposIndexedValueAmountQueryVariables
>;
export const CalculateVertragVposIndexedValueFactorDocument = gql`
  query CalculateVertragVposIndexedValueFactor(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $indexationAgreementId: ID!
    $indexedValueId: ID!
    $amount: Float!
  ) {
    calculateVertragVposIndexedValueFactor(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      indexedValueId: $indexedValueId
      amount: $amount
    ) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCalculateVertragVposIndexedValueFactorQuery(
  baseOptions: Apollo.QueryHookOptions<CalculateVertragVposIndexedValueFactorQuery, CalculateVertragVposIndexedValueFactorQueryVariables> &
    ({ variables: CalculateVertragVposIndexedValueFactorQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalculateVertragVposIndexedValueFactorQuery, CalculateVertragVposIndexedValueFactorQueryVariables>(
    CalculateVertragVposIndexedValueFactorDocument,
    options
  );
}
export function useCalculateVertragVposIndexedValueFactorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalculateVertragVposIndexedValueFactorQuery, CalculateVertragVposIndexedValueFactorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalculateVertragVposIndexedValueFactorQuery, CalculateVertragVposIndexedValueFactorQueryVariables>(
    CalculateVertragVposIndexedValueFactorDocument,
    options
  );
}
export function useCalculateVertragVposIndexedValueFactorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CalculateVertragVposIndexedValueFactorQuery, CalculateVertragVposIndexedValueFactorQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CalculateVertragVposIndexedValueFactorQuery, CalculateVertragVposIndexedValueFactorQueryVariables>(
    CalculateVertragVposIndexedValueFactorDocument,
    options
  );
}
export type CalculateVertragVposIndexedValueFactorQueryHookResult = ReturnType<typeof useCalculateVertragVposIndexedValueFactorQuery>;
export type CalculateVertragVposIndexedValueFactorLazyQueryHookResult = ReturnType<typeof useCalculateVertragVposIndexedValueFactorLazyQuery>;
export type CalculateVertragVposIndexedValueFactorSuspenseQueryHookResult = ReturnType<typeof useCalculateVertragVposIndexedValueFactorSuspenseQuery>;
export type CalculateVertragVposIndexedValueFactorQueryResult = Apollo.QueryResult<
  CalculateVertragVposIndexedValueFactorQuery,
  CalculateVertragVposIndexedValueFactorQueryVariables
>;
export const VertragVposIndexedValueChangeHistoryListDocument = gql`
  query VertragVposIndexedValueChangeHistoryList(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $indexationAgreementId: ID!
    $indexedValueId: ID!
  ) {
    getVertragVposIndexedValueChangeHistoryList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      indexedValueId: $indexedValueId
    ) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useVertragVposIndexedValueChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<VertragVposIndexedValueChangeHistoryListQuery, VertragVposIndexedValueChangeHistoryListQueryVariables> &
    ({ variables: VertragVposIndexedValueChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragVposIndexedValueChangeHistoryListQuery, VertragVposIndexedValueChangeHistoryListQueryVariables>(
    VertragVposIndexedValueChangeHistoryListDocument,
    options
  );
}
export function useVertragVposIndexedValueChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragVposIndexedValueChangeHistoryListQuery, VertragVposIndexedValueChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragVposIndexedValueChangeHistoryListQuery, VertragVposIndexedValueChangeHistoryListQueryVariables>(
    VertragVposIndexedValueChangeHistoryListDocument,
    options
  );
}
export function useVertragVposIndexedValueChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VertragVposIndexedValueChangeHistoryListQuery, VertragVposIndexedValueChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragVposIndexedValueChangeHistoryListQuery, VertragVposIndexedValueChangeHistoryListQueryVariables>(
    VertragVposIndexedValueChangeHistoryListDocument,
    options
  );
}
export type VertragVposIndexedValueChangeHistoryListQueryHookResult = ReturnType<typeof useVertragVposIndexedValueChangeHistoryListQuery>;
export type VertragVposIndexedValueChangeHistoryListLazyQueryHookResult = ReturnType<typeof useVertragVposIndexedValueChangeHistoryListLazyQuery>;
export type VertragVposIndexedValueChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useVertragVposIndexedValueChangeHistoryListSuspenseQuery
>;
export type VertragVposIndexedValueChangeHistoryListQueryResult = Apollo.QueryResult<
  VertragVposIndexedValueChangeHistoryListQuery,
  VertragVposIndexedValueChangeHistoryListQueryVariables
>;
