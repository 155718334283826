import React, { FC } from 'react';
import { Switch } from 'antd';
import ParamFormItem from '../../../../../../components/Form/ParamFormItem';

type Props = {
  name: string;
  defaultChecked: boolean;
  onChange: (checked: boolean) => void;
  shouldShowUstBlock: boolean;
};

const ParamFormItemUstBlockSwitch: FC<Props> = ({ name, defaultChecked, onChange, shouldShowUstBlock }) => (
  <ParamFormItem name={name} label={`Ust.Block ${shouldShowUstBlock ? 'einblenden' : 'ausblenden'}`}>
    <Switch id={name} defaultChecked={defaultChecked} onChange={(checked) => onChange(checked)} size="small" />
  </ParamFormItem>
);

export default ParamFormItemUstBlockSwitch;
