import React, { FC } from 'react';
import { SelectProps } from 'formik-antd';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { useToggle } from '../../hooks/useToggle';
import RechtsTraegerCreateDrawer from '../../features/Rechtstraeger/Listing/RechtsTraegerCreateDrawer';
import { RechtstraegerType, RechtstraegerListEntry } from '../../types';
import { useRechtstraegerListQuery } from '../../features/Rechtstraeger/gql/RechtsTraegerQueries.types';

type Props = {
  excludeRechtstraegerId?: string;
  onNewItemCreated?: (rechtstraegerId: string) => void;
  onNewItemCreateSelected?: () => void;
  typeList?: RechtstraegerType[];
  onTypeChange?: (rechtstraeger?: RechtstraegerListEntry) => void;
} & SelectProps;

/**
 * <h2>Usage</h2>
 * As listing filter or in Create/Update form where a Rechtströger has to be chosen from a dropdown list.
 *
 * It can be related to e.g. Rechnungsaustteller or Vertragspartner
 *
 */
const RechtstraegerSelect: FC<Props> = ({
  excludeRechtstraegerId,
  onNewItemCreated,
  onNewItemCreateSelected,
  typeList,
  onTypeChange,
  ...restProps
}) => {
  const isNewItemCreateAllowed = onNewItemCreated && onNewItemCreateSelected;
  const [isCreateNewDrawerOpen, toggleCreateNewDrawer] = useToggle();
  const { loading, data, refetch } = useRechtstraegerListQuery({
    variables: {
      typeList: typeList ?? undefined,
      includeArchiviert: true,
    },
  });
  const rechtstraegerList = data?.getRechtstraegerList.data.contentList;
  const rechtstraegerListWithoutExcludedId = rechtstraegerList?.filter((rechtstraeger) => rechtstraeger.rechtstraegerId !== excludeRechtstraegerId);

  const onSuccess = (rechtstraegerId: string) => {
    onNewItemCreated?.(rechtstraegerId);
    refetch();
  };

  const onNewItemCreateSelectedFn = isNewItemCreateAllowed
    ? () => {
        onNewItemCreateSelected();
        toggleCreateNewDrawer();
      }
    : undefined;

  return (
    <>
      <EntityStatusFormSelect<RechtstraegerListEntry>
        list={rechtstraegerListWithoutExcludedId}
        getSelectItemId={(rechtstraeger) => rechtstraeger.rechtstraegerId}
        getSelectItemContent={(rechtstraeger) => rechtstraeger.kurzBezeichnung}
        getSelectItemTooltipTitle={(rechtstraeger) => rechtstraeger.kurzBezeichnung}
        loading={loading}
        id={restProps.name}
        style={{ width: '100%' }}
        size="small"
        onNewItemCreateSelected={onNewItemCreateSelectedFn}
        newItemText="Neue Person anlegen"
        onChange={(rechtstraegerId) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onTypeChange && callOnChange(rechtstraegerListWithoutExcludedId!, rechtstraegerId, onTypeChange);
        }}
        {...restProps}
      />
      {isNewItemCreateAllowed && (
        <RechtsTraegerCreateDrawer onSuccess={onSuccess} isCollapsed={isCreateNewDrawerOpen} onCollapse={toggleCreateNewDrawer} typeList={typeList} />
      )}
    </>
  );
};

const callOnChange = (
  rechtstraegerListWithoutExcludedId: RechtstraegerListEntry[],
  rechtstraegerId: string,
  onTypeChange: (rechtstraeger?: RechtstraegerListEntry) => void
) => {
  const rechtstrager = rechtstraegerListWithoutExcludedId.find((rechtstrager) => rechtstrager.rechtstraegerId === rechtstraegerId);
  onTypeChange(rechtstrager);
};

export default RechtstraegerSelect;
