import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { BelegTexteTemplateFieldsFragmentDoc } from './BelegtexteFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BelegTexteRechtstraegerQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type BelegTexteRechtstraegerQuery = {
  getBelegTexteRechtstraeger: {
    data: {
      belegFussText?: any | null;
      belegFussTextAsHtml?: string | null;
      belegKopfText?: any | null;
      belegKopfTextAsHtml?: any | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      createTs?: string | null;
      updatedByMitarbeiterId?: string | null;
      belegFussBildAlignment?: { text: string; value: Types.DocumentHeaderAndFooterImageAlignment } | null;
      belegFussBildFileInfo?: { fileId: string; name: string; size: number } | null;
      belegKopfBildAlignment?: { text: string; value: Types.DocumentHeaderAndFooterImageAlignment } | null;
      belegKopfBildFileInfo?: { fileId: string; name: string; size: number } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BelegTexteFirmendatenQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type BelegTexteFirmendatenQuery = {
  getBelegTexteFirmendaten: {
    data: {
      belegFussText?: any | null;
      belegFussTextAsHtml?: string | null;
      belegKopfText?: any | null;
      belegKopfTextAsHtml?: any | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      createTs?: string | null;
      updatedByMitarbeiterId?: string | null;
      belegFussBildAlignment?: { text: string; value: Types.DocumentHeaderAndFooterImageAlignment } | null;
      belegFussBildFileInfo?: { fileId: string; name: string; size: number } | null;
      belegKopfBildAlignment?: { text: string; value: Types.DocumentHeaderAndFooterImageAlignment } | null;
      belegKopfBildFileInfo?: { fileId: string; name: string; size: number } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BelegTexteRechtstraegerTemplatesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BelegTexteRechtstraegerTemplatesQuery = {
  getBelegTexteRechtstraegerTemplates: {
    data: {
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BelegTexteFirmendatenTemplatesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BelegTexteFirmendatenTemplatesQuery = {
  getBelegTexteFirmendatenTemplates: {
    data: {
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BelegTexteRechtstraegerChangeHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type BelegTexteRechtstraegerChangeHistoryListQuery = {
  getBelegTexteRechtstraegerChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const BelegTexteRechtstraegerDocument = gql`
  query BelegTexteRechtstraeger($rechtstraegerId: ID!) {
    getBelegTexteRechtstraeger(rechtstraegerId: $rechtstraegerId) {
      data {
        belegFussBildAlignment {
          text
          value
        }
        belegFussBildFileInfo {
          fileId
          name
          size
        }
        belegFussText
        belegFussTextAsHtml
        belegKopfBildAlignment {
          text
          value
        }
        belegKopfBildFileInfo {
          fileId
          name
          size
        }
        belegKopfText
        belegKopfTextAsHtml
        createdBy
        createdByMitarbeiterId
        createTs
        updatedByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBelegTexteRechtstraegerQuery(
  baseOptions: Apollo.QueryHookOptions<BelegTexteRechtstraegerQuery, BelegTexteRechtstraegerQueryVariables> &
    ({ variables: BelegTexteRechtstraegerQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BelegTexteRechtstraegerQuery, BelegTexteRechtstraegerQueryVariables>(BelegTexteRechtstraegerDocument, options);
}
export function useBelegTexteRechtstraegerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BelegTexteRechtstraegerQuery, BelegTexteRechtstraegerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BelegTexteRechtstraegerQuery, BelegTexteRechtstraegerQueryVariables>(BelegTexteRechtstraegerDocument, options);
}
export function useBelegTexteRechtstraegerSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BelegTexteRechtstraegerQuery, BelegTexteRechtstraegerQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BelegTexteRechtstraegerQuery, BelegTexteRechtstraegerQueryVariables>(BelegTexteRechtstraegerDocument, options);
}
export type BelegTexteRechtstraegerQueryHookResult = ReturnType<typeof useBelegTexteRechtstraegerQuery>;
export type BelegTexteRechtstraegerLazyQueryHookResult = ReturnType<typeof useBelegTexteRechtstraegerLazyQuery>;
export type BelegTexteRechtstraegerSuspenseQueryHookResult = ReturnType<typeof useBelegTexteRechtstraegerSuspenseQuery>;
export type BelegTexteRechtstraegerQueryResult = Apollo.QueryResult<BelegTexteRechtstraegerQuery, BelegTexteRechtstraegerQueryVariables>;
export const BelegTexteFirmendatenDocument = gql`
  query BelegTexteFirmendaten($firmendatenId: ID!) {
    getBelegTexteFirmendaten(firmendatenId: $firmendatenId) {
      data {
        belegFussBildAlignment {
          text
          value
        }
        belegFussBildFileInfo {
          fileId
          name
          size
        }
        belegFussText
        belegFussTextAsHtml
        belegKopfBildAlignment {
          text
          value
        }
        belegKopfBildFileInfo {
          fileId
          name
          size
        }
        belegKopfText
        belegKopfTextAsHtml
        createdBy
        createdByMitarbeiterId
        createTs
        updatedByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBelegTexteFirmendatenQuery(
  baseOptions: Apollo.QueryHookOptions<BelegTexteFirmendatenQuery, BelegTexteFirmendatenQueryVariables> &
    ({ variables: BelegTexteFirmendatenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BelegTexteFirmendatenQuery, BelegTexteFirmendatenQueryVariables>(BelegTexteFirmendatenDocument, options);
}
export function useBelegTexteFirmendatenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BelegTexteFirmendatenQuery, BelegTexteFirmendatenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BelegTexteFirmendatenQuery, BelegTexteFirmendatenQueryVariables>(BelegTexteFirmendatenDocument, options);
}
export function useBelegTexteFirmendatenSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BelegTexteFirmendatenQuery, BelegTexteFirmendatenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BelegTexteFirmendatenQuery, BelegTexteFirmendatenQueryVariables>(BelegTexteFirmendatenDocument, options);
}
export type BelegTexteFirmendatenQueryHookResult = ReturnType<typeof useBelegTexteFirmendatenQuery>;
export type BelegTexteFirmendatenLazyQueryHookResult = ReturnType<typeof useBelegTexteFirmendatenLazyQuery>;
export type BelegTexteFirmendatenSuspenseQueryHookResult = ReturnType<typeof useBelegTexteFirmendatenSuspenseQuery>;
export type BelegTexteFirmendatenQueryResult = Apollo.QueryResult<BelegTexteFirmendatenQuery, BelegTexteFirmendatenQueryVariables>;
export const BelegTexteRechtstraegerTemplatesDocument = gql`
  query BelegTexteRechtstraegerTemplates {
    getBelegTexteRechtstraegerTemplates {
      data {
        ...BelegTexteTemplateFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BelegTexteTemplateFieldsFragmentDoc}
`;
export function useBelegTexteRechtstraegerTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<BelegTexteRechtstraegerTemplatesQuery, BelegTexteRechtstraegerTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BelegTexteRechtstraegerTemplatesQuery, BelegTexteRechtstraegerTemplatesQueryVariables>(
    BelegTexteRechtstraegerTemplatesDocument,
    options
  );
}
export function useBelegTexteRechtstraegerTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BelegTexteRechtstraegerTemplatesQuery, BelegTexteRechtstraegerTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BelegTexteRechtstraegerTemplatesQuery, BelegTexteRechtstraegerTemplatesQueryVariables>(
    BelegTexteRechtstraegerTemplatesDocument,
    options
  );
}
export function useBelegTexteRechtstraegerTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BelegTexteRechtstraegerTemplatesQuery, BelegTexteRechtstraegerTemplatesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BelegTexteRechtstraegerTemplatesQuery, BelegTexteRechtstraegerTemplatesQueryVariables>(
    BelegTexteRechtstraegerTemplatesDocument,
    options
  );
}
export type BelegTexteRechtstraegerTemplatesQueryHookResult = ReturnType<typeof useBelegTexteRechtstraegerTemplatesQuery>;
export type BelegTexteRechtstraegerTemplatesLazyQueryHookResult = ReturnType<typeof useBelegTexteRechtstraegerTemplatesLazyQuery>;
export type BelegTexteRechtstraegerTemplatesSuspenseQueryHookResult = ReturnType<typeof useBelegTexteRechtstraegerTemplatesSuspenseQuery>;
export type BelegTexteRechtstraegerTemplatesQueryResult = Apollo.QueryResult<
  BelegTexteRechtstraegerTemplatesQuery,
  BelegTexteRechtstraegerTemplatesQueryVariables
>;
export const BelegTexteFirmendatenTemplatesDocument = gql`
  query BelegTexteFirmendatenTemplates {
    getBelegTexteFirmendatenTemplates {
      data {
        ...BelegTexteTemplateFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BelegTexteTemplateFieldsFragmentDoc}
`;
export function useBelegTexteFirmendatenTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<BelegTexteFirmendatenTemplatesQuery, BelegTexteFirmendatenTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BelegTexteFirmendatenTemplatesQuery, BelegTexteFirmendatenTemplatesQueryVariables>(
    BelegTexteFirmendatenTemplatesDocument,
    options
  );
}
export function useBelegTexteFirmendatenTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BelegTexteFirmendatenTemplatesQuery, BelegTexteFirmendatenTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BelegTexteFirmendatenTemplatesQuery, BelegTexteFirmendatenTemplatesQueryVariables>(
    BelegTexteFirmendatenTemplatesDocument,
    options
  );
}
export function useBelegTexteFirmendatenTemplatesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BelegTexteFirmendatenTemplatesQuery, BelegTexteFirmendatenTemplatesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BelegTexteFirmendatenTemplatesQuery, BelegTexteFirmendatenTemplatesQueryVariables>(
    BelegTexteFirmendatenTemplatesDocument,
    options
  );
}
export type BelegTexteFirmendatenTemplatesQueryHookResult = ReturnType<typeof useBelegTexteFirmendatenTemplatesQuery>;
export type BelegTexteFirmendatenTemplatesLazyQueryHookResult = ReturnType<typeof useBelegTexteFirmendatenTemplatesLazyQuery>;
export type BelegTexteFirmendatenTemplatesSuspenseQueryHookResult = ReturnType<typeof useBelegTexteFirmendatenTemplatesSuspenseQuery>;
export type BelegTexteFirmendatenTemplatesQueryResult = Apollo.QueryResult<
  BelegTexteFirmendatenTemplatesQuery,
  BelegTexteFirmendatenTemplatesQueryVariables
>;
export const BelegTexteRechtstraegerChangeHistoryListDocument = gql`
  query BelegTexteRechtstraegerChangeHistoryList($rechtstraegerId: ID!) {
    getBelegTexteRechtstraegerChangeHistoryList(rechtstraegerId: $rechtstraegerId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBelegTexteRechtstraegerChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<BelegTexteRechtstraegerChangeHistoryListQuery, BelegTexteRechtstraegerChangeHistoryListQueryVariables> &
    ({ variables: BelegTexteRechtstraegerChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BelegTexteRechtstraegerChangeHistoryListQuery, BelegTexteRechtstraegerChangeHistoryListQueryVariables>(
    BelegTexteRechtstraegerChangeHistoryListDocument,
    options
  );
}
export function useBelegTexteRechtstraegerChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BelegTexteRechtstraegerChangeHistoryListQuery, BelegTexteRechtstraegerChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BelegTexteRechtstraegerChangeHistoryListQuery, BelegTexteRechtstraegerChangeHistoryListQueryVariables>(
    BelegTexteRechtstraegerChangeHistoryListDocument,
    options
  );
}
export function useBelegTexteRechtstraegerChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BelegTexteRechtstraegerChangeHistoryListQuery, BelegTexteRechtstraegerChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BelegTexteRechtstraegerChangeHistoryListQuery, BelegTexteRechtstraegerChangeHistoryListQueryVariables>(
    BelegTexteRechtstraegerChangeHistoryListDocument,
    options
  );
}
export type BelegTexteRechtstraegerChangeHistoryListQueryHookResult = ReturnType<typeof useBelegTexteRechtstraegerChangeHistoryListQuery>;
export type BelegTexteRechtstraegerChangeHistoryListLazyQueryHookResult = ReturnType<typeof useBelegTexteRechtstraegerChangeHistoryListLazyQuery>;
export type BelegTexteRechtstraegerChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useBelegTexteRechtstraegerChangeHistoryListSuspenseQuery
>;
export type BelegTexteRechtstraegerChangeHistoryListQueryResult = Apollo.QueryResult<
  BelegTexteRechtstraegerChangeHistoryListQuery,
  BelegTexteRechtstraegerChangeHistoryListQueryVariables
>;
