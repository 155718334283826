import React, { FC } from 'react';
import { Empty } from 'antd';
import NestedTableWithColSelector from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { AbrechnungskreisTypeTuple, SubAbrBuchungszeile } from '../../../../../../../types';
import buchungszeileTableColumns from './buchungszeileTableColumns';

type Props = {
  abrechnungskreisType: AbrechnungskreisTypeTuple;
  buchungszeileList: SubAbrBuchungszeile[];
  parentRowKeys?: string[];
  visible?: boolean;
  subLevel?: number;
};

const BuchungszeileTable: FC<Props> = ({ abrechnungskreisType, buchungszeileList, parentRowKeys, subLevel, visible = true }) => {
  return (
    <NestedTableWithColSelector<SubAbrBuchungszeile>
      visible={visible}
      parentRowKeys={parentRowKeys}
      level={4}
      subLevel={subLevel}
      locale={{
        emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
      }}
      dataSource={buchungszeileList}
      columns={buchungszeileTableColumns(abrechnungskreisType)}
    />
  );
};

export default BuchungszeileTable;
