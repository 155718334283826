import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Select } from 'formik-antd';
import { Col, Row } from 'antd';
import { ThresholdType, ThresholdTypeTuple } from '../../../../../../../../../types';
import DrawerRow from '../../../../../../../../../components/Drawer/DrawerRow';
import FormattedDecimalFormInput from '../../../../../../../../../components/Input-antd/FormattedDecimalFormInput';
import { IndexationAgreementIndexAnnualFormValues } from '../IndexAnnual/indexAnnualFormMapper';
import { IndexationAgreementIndexThresholdFormValues } from '../IndexThreshold/indexThresholdFormMapper';
import { isThresholdTypePercent } from './indexationAgreementFormHelpers';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

const indexThresholdTypeOptions: ThresholdTypeTuple[] = [
  {
    text: '%',
    value: ThresholdType.Percent,
  },
  {
    text: 'Punkte',
    value: ThresholdType.Points,
  },
];

type Props = {
  formikProps: FormikProps<IndexationAgreementIndexAnnualFormValues> | FormikProps<IndexationAgreementIndexThresholdFormValues>;
  thresholdName: string;
  thresholdTypeName: string;
  shareInPercentToApplyName: string;
  minimumLimitName: string;
  fieldHelpNames?: {
    threshold?: string | null;
    thresholdType?: string | null;
    shareInPercentToApply?: string | null;
    minimumLimit?: string | null;
  };
};

const ThresholdBlock: FC<Props> = ({
  formikProps,
  thresholdName,
  thresholdTypeName,
  shareInPercentToApplyName,
  minimumLimitName,
  fieldHelpNames,
}) => {
  const content = (
    <>
      <Row gutter={8} align="bottom">
        <Col span={16}>
          <FormItemWithFieldHelp name={thresholdName} label="Schwellenwert" fieldHelp={fieldHelpNames?.threshold}>
            <FormattedDecimalFormInput
              name={thresholdName}
              id={thresholdName}
              style={{ width: '100%' }}
              step={isThresholdTypePercent(formikProps.values.thresholdType) ? '0.01' : '1'}
            />
          </FormItemWithFieldHelp>
        </Col>
        <Col span={8}>
          <FormItemWithFieldHelp name={thresholdTypeName}>
            <Select
              name={thresholdTypeName}
              size="small"
              showSearch
              onChange={(value) => {
                if (value && formikProps.values.thresholdType && formikProps.touched.thresholdType) {
                  formikProps.setFieldValue(thresholdName, null);
                }
              }}
            >
              {indexThresholdTypeOptions.map((indexThresholdType) => (
                <Select.Option key={indexThresholdType.value} value={indexThresholdType.value}>
                  {indexThresholdType.text}
                </Select.Option>
              ))}
            </Select>
          </FormItemWithFieldHelp>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemWithFieldHelp
            name={shareInPercentToApplyName}
            label="Anteil (teilweise Wirksamkeit)"
            fieldHelp={fieldHelpNames?.shareInPercentToApply}
          >
            <FormattedDecimalFormInput
              name={shareInPercentToApplyName}
              id={shareInPercentToApplyName}
              isPercentage
              style={{ width: '100%' }}
              step="0.01"
            />
          </FormItemWithFieldHelp>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemWithFieldHelp name={minimumLimitName} label="Bagatellgrenze" fieldHelp={fieldHelpNames?.minimumLimit}>
            <FormattedDecimalFormInput name={minimumLimitName} id={minimumLimitName} isCurrency min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
        </Col>
      </Row>
    </>
  );

  return <DrawerRow rowTitle="Schwellenwert" content={content} />;
};

export default ThresholdBlock;
