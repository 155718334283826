import React, { FC } from 'react';
import { Table } from 'antd';
import { FormikProps } from 'formik';
import repFondsTemplateKontenZuweisungTableColumns from './repFondsTemplateKontenZuweisungTableColumns';
import {
  RepFondsKontenZuweisungFormValues,
  RepFondsKontenZuweisungVersionFormValues,
} from '../../../../ReparaturFonds/KontenZuweisung/Version/Form/repFondsKontenZuweisungVersionFormMapper';

type RepFondsKontenZuweisungTableProps = {
  formikProps: FormikProps<RepFondsKontenZuweisungVersionFormValues>;
  onDelete: (index: number) => void;
};

const RepFondsTemplateKontenZuweisungTable: FC<RepFondsKontenZuweisungTableProps> = ({ formikProps, onDelete }) => (
  <Table<RepFondsKontenZuweisungFormValues>
    dataSource={formikProps.values.kontenZuweisungList}
    //name={repFondsKontenZuordnungVersionFormFields.kontenZuweisungList}
    columns={repFondsTemplateKontenZuweisungTableColumns(formikProps, onDelete)}
    size="small"
    pagination={false}
  />
);

export default RepFondsTemplateKontenZuweisungTable;
