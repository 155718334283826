import React, { FC } from 'react';
import { Drawer, Tabs, TabsProps } from 'antd';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { Box } from 'rebass';
import { ITimelineState } from '../../../components/Timeline/useTimelineOpen';
import LeerstehungAufwandskontoVersionTimeline from './Version/LeerstehungAufwandskontoVersionTimeline';
import LeerstehungForderungskontoForm from './Form/LeerstehungForderungskontoForm';

type Props = {
  timelineDrawer: ITimelineState;
  closeTimelineDrawer: () => void;
  onSuccess: () => void;
  leerstehungForderungskontoId?: string | null;
};

const LeerstehungDrawer: FC<Props> = ({ timelineDrawer, closeTimelineDrawer, onSuccess, leerstehungForderungskontoId }) => {
  const onActionSuccess = () => {
    onSuccess();
    closeTimelineDrawer();
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined />
          Verlauf
        </span>
      ),
      children: <LeerstehungAufwandskontoVersionTimeline onSuccess={onSuccess} />,
    },
    {
      key: '2',
      label: (
        <span>
          <EditOutlined />
          Bearbeiten
        </span>
      ),
      children: (
        <Box p={16}>
          <LeerstehungForderungskontoForm
            leerstehungForderungskontoId={leerstehungForderungskontoId}
            onSuccess={onActionSuccess}
            onCancel={closeTimelineDrawer}
          />
        </Box>
      ),
    },
  ];

  return (
    <Drawer title="Leerstehung" width={720} open={timelineDrawer.visible} onClose={closeTimelineDrawer} destroyOnClose>
      <Tabs defaultActiveKey="2" items={items} />
    </Drawer>
  );
};

export default LeerstehungDrawer;
