import React, { FC } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import theme from '../../../../../../theme';
import { styleForIconAndButton } from './ContactListItemStyleHelpers';
import { StyledDraggableContainer } from './styled/AddressAndContactList.style';
import { CLASS_DRAG_AND_DROP_HANDLE } from '../../addressAndContactListHelpers';

type Props = {
  isHaupt: boolean;
  isDraggable: boolean;
  icon: React.ReactNode;
};

const ContactListItemIcon: FC<Props> = ({ isHaupt, isDraggable, icon }) => (
  <div
    style={{
      backgroundColor: isHaupt ? theme.cardBackgroundColors.blue : theme.cardBackgroundColors.grey,
      cursor: isDraggable ? 'grab' : 'default',
      padding: '12px 0',
      ...styleForIconAndButton,
    }}
    className={CLASS_DRAG_AND_DROP_HANDLE}
  >
    {icon}
    {isDraggable && (
      <StyledDraggableContainer>
        <MoreOutlined />
        <MoreOutlined />
        <MoreOutlined />
      </StyledDraggableContainer>
    )}
  </div>
);

export default ContactListItemIcon;
