import React, { FC } from 'react';
import { Space } from 'antd';
import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import { UstRegelsetUstKategorieTimelineDataSourceEntry } from './ustRegelsetVersionTimelineMapper';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { UstKategorieStatusTuple } from '../../../../types';

type Props = {
  timeblock: ITimeblock<UstRegelsetUstKategorieTimelineDataSourceEntry>;
};

const UstRegelsetVersionCardContent: FC<Props> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      <CustomFormattedDate value={timeblock.validFrom} />
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle="USt.-Kategorie">
      <DataWithStatus status={timeblock.ustKategorie.status as UstKategorieStatusTuple} text={timeblock.ustKategorie.kurzBezeichnung} />
    </TimelineCardContentInfoRow>
  </Space>
);

export default UstRegelsetVersionCardContent;
