import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { App as AntdApp } from 'antd';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

import App from './router/App';
import ErrorFallback from './components/Error/GeneralError';
import AuthApolloProvider from './graphql/AuthApolloProvider';
import MainApolloProvider from './graphql/MainApolloProvider';
import { LocaleProvider } from './shared/Locale/LocaleProvider';
import { AuthProvider } from './shared/Auth/authContext';
import { DataChangedEventProvider } from './shared/dataChangedSubscription';
import FieldHelpComparison from './features/FieldHelp/FieldHelpComparison';

import 'antd/dist/reset.css';

const SPA = document.getElementById('root');

const AppRoute = () => {
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <AuthApolloProvider>
        <AuthProvider>
          <MainApolloProvider>
            <FieldHelpComparison />
            <LocaleProvider>
              <DataChangedEventProvider>
                <HelmetProvider>
                  <AntdApp>
                    <App />
                  </AntdApp>
                </HelmetProvider>
              </DataChangedEventProvider>
            </LocaleProvider>
          </MainApolloProvider>
        </AuthProvider>
      </AuthApolloProvider>
    </ErrorBoundary>
  );
};

const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<AppRoute />} />));

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(SPA!);
root.render(<RouterProvider router={router} />);
