import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AuftragsartTable from './Table/AuftragsartTable';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import URI_SYS_SETTINGS_AUFTRAGSART_PAGE from './auftragsartUriPaths';
import { useToggle } from '../../hooks/useToggle';

const AuftragsartListing = () => {
  const navigate = useNavigate();

  const [showArchived, toggleShowArchived] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button onClick={toggleShowArchived}>Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}</Button>
        <Button icon={<PlusOutlined />} type="primary" onClick={() => navigate(URI_SYS_SETTINGS_AUFTRAGSART_PAGE.createPage)}>
          Auftragsart anlegen
        </Button>
      </ButtonsAligned>

      <AuftragsartTable showArchived={showArchived} />
    </>
  );
};

export default AuftragsartListing;
