import React, { FC } from 'react';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import zahlungsregelTableColumns from './zahlungsregelTableColumns';
import { HeVertragZahlungsregel } from '../../../../../types';
import { HeAbrechnungsdefinitionFieldsFragment } from '../../../../Abrechnungsdefinition/gql/HeAbrDef/HeAbrDefFragments.types';

type Props = {
  objektId: string;
  heVertragId: string;
  loading: boolean;
  onSuccess: () => void;
  zahlungsregelList?: HeVertragZahlungsregel[];
  heAbrechnungsdefinition?: HeAbrechnungsdefinitionFieldsFragment | null;
};

const ZahlungsregelTable: FC<Props> = ({ objektId, heVertragId, loading, onSuccess, zahlungsregelList, heAbrechnungsdefinition }) => {
  return (
    <TableWithColSelector<HeVertragZahlungsregel>
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      size="small"
      pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
      dataSource={zahlungsregelList}
      columns={zahlungsregelTableColumns(objektId, heVertragId, onSuccess, heAbrechnungsdefinition)}
      rowKey={(record) => record.zahlungsregelId}
      filterIdentifier="he-vertrag-zahlungsregel"
    />
  );
};

export default ZahlungsregelTable;
