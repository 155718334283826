import React, { FC } from 'react';
import { StyledListSummarySpace } from '../../shared/ListSummary/styled/StyledListSummarySpace';
import { SummaryText } from '../../shared/ListSummary/SummaryText';
import { LoadingCol } from '../../../components/Loading';
import { usePaymentListSummaryQuery } from '../gql/Order/PaymentQueries.types';
import { mapQueryParamsToPaymentListQueryVariables } from './Filters/paymentListingFiltersFormMapper';
import { TPaymentQueryParams } from './Filters/filtersQueryParams';

type Props = {
  queryParams: TPaymentQueryParams;
};

const PaymentListSummary: FC<Props> = ({ queryParams }) => {
  const { data: summaryData } = usePaymentListSummaryQuery({
    variables: {
      ...mapQueryParamsToPaymentListQueryVariables(queryParams),
    },
  });

  const summary = summaryData?.getPaymentListSummary.data;

  return (
    <>
      {summary ? (
        <StyledListSummarySpace>
          <SummaryText text="Betrag" value={summary.sumAmount} />
        </StyledListSummarySpace>
      ) : (
        <LoadingCol isLoading />
      )}
    </>
  );
};

export default PaymentListSummary;
