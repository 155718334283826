import React from 'react';
import { Empty } from 'antd';
import GenerierlaufStatusExpandedRow from '../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../verarbeitungHelpers';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { mapQueryParamsToSubAbrechnungGenEntryListQueryVariables } from './Filters/listingFiltersFormMapper';
import { TSubAbrechnungQueryParams } from './Filters/filtersQueryParams';
import { useSubAbrechnungGenerierlaufEntryListQuery } from '../../gql/SubAbrechnungGenerierlauf/SubAbrechnungGenerierlaufQueries.types';
import { SubAbrechnungGenerierlaufListEntry } from '../../../../types';
import NestedTableWithColSelector from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import NestedTableWithColSelectorWrapper from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';
import subAbrechnungEntryTableColumns from './subAbrechnungEntryTableColumns';

type Props = {
  queryParams: TSubAbrechnungQueryParams;
  generierlaufId: string;
};

const SubAbrechnungEntryTable: React.FC<Props> = ({ generierlaufId, queryParams }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useSubAbrechnungGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId,
        ...mapQueryParamsToSubAbrechnungGenEntryListQueryVariables(queryParams),
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getSubAbrechnungGenerierlaufEntryList.data.contentList || [];

  return (
    <NestedTableWithColSelectorWrapper filterIdentifier="verarbeitung-sub-abrechnung">
      <NestedTableWithColSelector<SubAbrechnungGenerierlaufListEntry>
        locale={{
          emptyText: <Empty description={<span>Keine Subverwaltungsabrechnungen vorhanden</span>} />,
        }}
        level={1}
        colWidth={150}
        loading={loading}
        pagination={pagination}
        dataSource={entryList}
        columns={subAbrechnungEntryTableColumns}
        onChange={handleTableSorting}
        expandable={{
          expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
          rowExpandable: isVerarbeitungEntryTableRowExpandable,
        }}
        rowKey={(record) => record.generierlaufEntryId}
      />
    </NestedTableWithColSelectorWrapper>
  );
};

export default SubAbrechnungEntryTable;
