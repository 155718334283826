import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUstKategorieTemplateVersionMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  input: Types.UstKategorieVersionCreateInput;
}>;

export type CreateUstKategorieTemplateVersionMutation = {
  createUstKategorieTemplateVersion: {
    data: { ustKategorieVersionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateUstKategorieTemplateVersionMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  ustKategorieVersionId: Types.Scalars['ID']['input'];
  input: Types.UstKategorieVersionUpdateInput;
}>;

export type UpdateUstKategorieTemplateVersionMutation = {
  updateUstKategorieTemplateVersion: {
    data: { ustKategorieVersionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteUstKategorieTemplateVersionMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  ustKategorieVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteUstKategorieTemplateVersionMutation = {
  deleteUstKategorieTemplateVersion: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateUstKategorieTemplateVersionDocument = gql`
  mutation CreateUstKategorieTemplateVersion($ustKategorieId: ID!, $input: UstKategorieVersionCreateInput!) {
    createUstKategorieTemplateVersion(ustKategorieId: $ustKategorieId, input: $input) {
      data {
        ustKategorieVersionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateUstKategorieTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUstKategorieTemplateVersionMutation, CreateUstKategorieTemplateVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUstKategorieTemplateVersionMutation, CreateUstKategorieTemplateVersionMutationVariables>(
    CreateUstKategorieTemplateVersionDocument,
    options
  );
}
export type CreateUstKategorieTemplateVersionMutationHookResult = ReturnType<typeof useCreateUstKategorieTemplateVersionMutation>;
export type CreateUstKategorieTemplateVersionMutationResult = Apollo.MutationResult<CreateUstKategorieTemplateVersionMutation>;
export type CreateUstKategorieTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateUstKategorieTemplateVersionMutation,
  CreateUstKategorieTemplateVersionMutationVariables
>;
export const UpdateUstKategorieTemplateVersionDocument = gql`
  mutation UpdateUstKategorieTemplateVersion($ustKategorieId: ID!, $ustKategorieVersionId: ID!, $input: UstKategorieVersionUpdateInput!) {
    updateUstKategorieTemplateVersion(ustKategorieId: $ustKategorieId, ustKategorieVersionId: $ustKategorieVersionId, input: $input) {
      data {
        ustKategorieVersionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateUstKategorieTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUstKategorieTemplateVersionMutation, UpdateUstKategorieTemplateVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUstKategorieTemplateVersionMutation, UpdateUstKategorieTemplateVersionMutationVariables>(
    UpdateUstKategorieTemplateVersionDocument,
    options
  );
}
export type UpdateUstKategorieTemplateVersionMutationHookResult = ReturnType<typeof useUpdateUstKategorieTemplateVersionMutation>;
export type UpdateUstKategorieTemplateVersionMutationResult = Apollo.MutationResult<UpdateUstKategorieTemplateVersionMutation>;
export type UpdateUstKategorieTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateUstKategorieTemplateVersionMutation,
  UpdateUstKategorieTemplateVersionMutationVariables
>;
export const DeleteUstKategorieTemplateVersionDocument = gql`
  mutation DeleteUstKategorieTemplateVersion($ustKategorieId: ID!, $ustKategorieVersionId: ID!) {
    deleteUstKategorieTemplateVersion(ustKategorieId: $ustKategorieId, ustKategorieVersionId: $ustKategorieVersionId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteUstKategorieTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUstKategorieTemplateVersionMutation, DeleteUstKategorieTemplateVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUstKategorieTemplateVersionMutation, DeleteUstKategorieTemplateVersionMutationVariables>(
    DeleteUstKategorieTemplateVersionDocument,
    options
  );
}
export type DeleteUstKategorieTemplateVersionMutationHookResult = ReturnType<typeof useDeleteUstKategorieTemplateVersionMutation>;
export type DeleteUstKategorieTemplateVersionMutationResult = Apollo.MutationResult<DeleteUstKategorieTemplateVersionMutation>;
export type DeleteUstKategorieTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteUstKategorieTemplateVersionMutation,
  DeleteUstKategorieTemplateVersionMutationVariables
>;
