import React, { FC } from 'react';
import { Button, Drawer, Dropdown, MenuProps } from 'antd';
import { EditOutlined, EllipsisOutlined, HistoryOutlined } from '@ant-design/icons';
import { SubAdministration } from '../../../types';
import { useToggle } from '../../../hooks/useToggle';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import SubAdministrationChangeHistoryListingTable from './SubAdministrationChangeHistoryListingTable';
import SubAdministrationUpdateForm from '../Form/Update/SubAdministrationUpdateForm';

type Props = {
  subAdministration: SubAdministration;
  objektId: string;
  onAction: () => void;
};

const SubAdministrationActions: FC<Props> = ({ subAdministration, onAction, objektId }) => {
  const [isChangeHistoryOpen, toggleChangehistoryOpen] = useToggle();
  const [isEditDrawerOpen, toggleEditDrawer] = useToggle();

  const onSuccess = () => {
    onAction();
    toggleEditDrawer();
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Bearbeiten',
      onClick: toggleEditDrawer,
      icon: <EditOutlined />,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangehistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
      <Drawer title="Bearbeiten" width={720} open={isEditDrawerOpen} onClose={toggleEditDrawer} destroyOnClose>
        <SubAdministrationUpdateForm subAdministration={subAdministration} objektId={objektId} onCancel={toggleEditDrawer} onSuccess={onSuccess} />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangehistoryOpen}>
        <SubAdministrationChangeHistoryListingTable objektId={objektId} subAdministrationId={subAdministration.subAdministrationId} />
      </HistoryModal>
    </>
  );
};

export default SubAdministrationActions;
