import { round } from 'lodash';
import { AuszifferungBlockFormValues, OffenePostenForBooking } from './auszifferungFormMapper';
import { getFormattedDecimalValueOrZero } from '../../../../../components/Input-antd/formattedDecimalHelper';
import { isAuszifferungStorniert, isNewAuszifferung } from './auszifferungFormHelpers';
import { AuszifferungWithOffenePosten } from '../../../../../types';
import { belongsAuszifferungToAnotherZahlung, isAuszifferungSourceStorno } from '../../../shared/auszifferungHelpers';

/** ---------- initial available amount calculation ---------- */
export const calculateInitialAvailableAmount = (
  amountFromPersonenkonto: number,
  auszifferungList: AuszifferungWithOffenePosten[],
  bookingZahlungId: string
): number => {
  // Sum up the betrag values coming from Auszifferung list provided by BE
  const sumAuszifferungenAmounts = auszifferungList.reduce((sum, auszifferung) => {
    if (isAuszifferungSourceStorno(auszifferung.source?.value)) return sum;

    return !auszifferung.storniert && !belongsAuszifferungToAnotherZahlung(bookingZahlungId, auszifferung.zahlungId)
      ? sum + getFormattedDecimalValueOrZero(auszifferung.betrag)
      : sum;
  }, 0);

  // return the result - subtract the calculated sum from the amount from Personenkonto  (result should be negative for Rücklastschrift, positive for Zahlung)
  // use round from lodash to avoid floating point issues in JS resulting from the subtraction of float numbers
  return round(amountFromPersonenkonto - sumAuszifferungenAmounts, 2);
};
/** ---------- END OF initial available amount calculation ---------- */

/** ---------- available amount calculation for present Auszifferung listing entries ---------- */
const sumAllAuszifferung = (offenePostenForListForBooking: OffenePostenForBooking[], bookingZahlungId: string): number => {
  return offenePostenForListForBooking.reduce((total, op) => {
    const sumAuszifferungAmounts = op.auszifferungList.reduce((sum, auszifferung) => {
      if (isAuszifferungSourceStorno(auszifferung.source?.value)) return sum;

      if (
        isNewAuszifferung(auszifferung) ||
        (!isAuszifferungStorniert(auszifferung) && !belongsAuszifferungToAnotherZahlung(bookingZahlungId, auszifferung.zahlungId))
      ) {
        return sum + getFormattedDecimalValueOrZero(auszifferung.amount);
      }

      return sum;
    }, 0);

    return total + sumAuszifferungAmounts;
  }, 0);
};

export const calculateAvailableAmountFromFormik = (
  amountFromPersonenkonto: number,
  bookingZahlungId: string,
  formikValues: AuszifferungBlockFormValues
): number => {
  // check the auszifferungen, sum them up and return the result- Negativ for Rücklastschrift, positiv for Zahlung
  const sumAuszifferungenAmounts = sumAllAuszifferung(formikValues.offenePostenListFromBooking, bookingZahlungId);
  const availableAmount = amountFromPersonenkonto - sumAuszifferungenAmounts;

  // use round from lodash to avoid floating point issues in JS resulting from the subtraction of float numbers
  return round(availableAmount, 2);
};
/** ---------- END OF available amount calculation for present Auszifferung listing entries ---------- */
