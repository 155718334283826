import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../types';

const useOnPaymentDataChangedEvents = (key: string, callback: () => Promise<unknown>) => {
  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.paymentProposal,
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Zahlungen wurden gerade verändert',
      key: `${key}-payment-proposal-create`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.paymentProposal,
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Zahlungen wurden gerade verändert',
      key: `${key}-payment-proposal-update`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.paymentProposal,
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Zahlungen wurden gerade verändert',
      key: `${key}-payment-proposal-delete`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.payment,
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Zahlung wurde gerade verändert',
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.payment,
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Zahlung wurde gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.payment,
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Zahlung wurde gerade gelöscht',
      key: `${key}-delete`,
      callback,
    },
  ]);
};

export default useOnPaymentDataChangedEvents;
