import React, { FC } from 'react';
import { Spacer } from '../../../../../components/Grid';
import DefinitionUpdateFormButtons from '../../../../shared/DefinitionUpdateFormButtons';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { AuftragsartFieldsFragment } from '../../../../Auftragsart/gql/AuftragsartFragments.types';
import AuftragsartTemplate from '../../../../Auftragsart/Form/shared/Template/AuftragsartTemplate';

type Props = {
  auftragsart: AuftragsartFieldsFragment;
  auftragsartTemplateValues: TextbausteinFormValues[];
  setAuftragsartTemplateValues: React.Dispatch<React.SetStateAction<TextbausteinFormValues[]>>;
  onSubmit: () => void;
  closePath: string;
};

const AuftragsartTemplateUpdateTemplateForm: FC<Props> = ({
  auftragsart,
  auftragsartTemplateValues,
  setAuftragsartTemplateValues,
  onSubmit,
  closePath,
}) => {
  return (
    <>
      <Spacer height={16} />
      <AuftragsartTemplate
        templateFormValues={auftragsartTemplateValues}
        onTemplateChange={setAuftragsartTemplateValues}
        showUstBlock={auftragsart.showUstBlock ?? false}
      />
      <DefinitionUpdateFormButtons onSubmit={onSubmit} pushPath={closePath} />
    </>
  );
};

export default AuftragsartTemplateUpdateTemplateForm;
