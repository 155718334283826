import { RcFile } from 'antd/lib/upload/interface';
import { FormFields } from '../../../../../helpers/formikHelper';
import { TextbausteinModel } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { TextbausteinInput, TextbausteinType } from '../../../../../types';

// type for newly added attachments
export type FileData = {
  filename: string;
  file?: RcFile;
};

// shared type for newly added attachments and attachments that were already uploaded before, used for showing the attachment list
export type WizardAttachmentFileInfo = {
  fileId?: string;
  name: string;
  file?: RcFile;
  size: number;
};

export type EmailTemplateStepFormValues = {
  deletableAttachmentIds: string[];
  newAttachmentList: FileData[];
  subject: TextbausteinModel;
  body?: TextbausteinModel | null;
};

export type TemplateStepInput = {
  deletableAttachmentIds: string[];
  subject: TextbausteinInput;
  body?: TextbausteinInput | null;
  startNextStep?: boolean | null;
};

export const mapTemplateStepToFormValues = (values: EmailTemplateStepFormValues): EmailTemplateStepFormValues => ({
  deletableAttachmentIds: [],
  newAttachmentList: [],
  body: values.body,
  subject: values.subject,
});

export const emailTemplateStepFormFields: FormFields<EmailTemplateStepFormValues> = {
  deletableAttachmentIds: 'deletableAttachmentIds',
  newAttachmentList: 'newAttachmentList',
  subject: 'subject',
  body: 'body',
};

export const mapFormValuesIntoTemplateStepInput = (values: EmailTemplateStepFormValues, startNextStep: boolean): TemplateStepInput => ({
  deletableAttachmentIds: values.deletableAttachmentIds,
  body: values.body
    ? {
        type: TextbausteinType.EmailDeliveryDefinitionBody,
        value: values.body.value,
      }
    : undefined,
  subject: {
    type: TextbausteinType.EmailDeliveryDefinitionSubject,
    value: values.subject.value,
  },
  startNextStep,
});
