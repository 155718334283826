import React, { FC } from 'react';
import { FieldArrayRenderProps } from 'formik';
import { Button, Col, Row, Space, Typography } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { createNewMahngebuehr, mahngebuehrenFormFields } from '../../../mahndefinitionFormMapper';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';

type Props = {
  index: number;
  name: string;
  arrayHelpers: FieldArrayRenderProps;
};

const Mahngebuehr: FC<Props> = ({ index, name, arrayHelpers }) => {
  const manhngebuehrFieldName = (fieldName: string) => `${name}.${index}.${fieldName}`;
  return (
    <Row key={index} align="middle" style={{ width: '100%' }}>
      <Col span={8}>
        <FormItemWithoutColon name={manhngebuehrFieldName(mahngebuehrenFormFields.ab)} label="ab" style={{ margin: 0 }}>
          <Space size={8} style={{ width: '100%' }}>
            <FormattedDecimalFormInput
              id={manhngebuehrFieldName(mahngebuehrenFormFields.ab)}
              name={manhngebuehrFieldName(mahngebuehrenFormFields.ab)}
              size="small"
              style={{ width: '115px' }}
              min={0}
              controls={false}
            />
            <Typography.Text>€</Typography.Text>
          </Space>
        </FormItemWithoutColon>
      </Col>
      <Col span={12}>
        <FormItemWithoutColon name={manhngebuehrFieldName(mahngebuehrenFormFields.betrag)} label="Mahngebühr" style={{ margin: 0, width: '100%' }}>
          <Space size={8} style={{ width: '100%' }}>
            <FormattedDecimalFormInput
              id={manhngebuehrFieldName(mahngebuehrenFormFields.betrag)}
              name={manhngebuehrFieldName(mahngebuehrenFormFields.betrag)}
              size="small"
              style={{ width: '115px' }}
              min={0}
              controls={false}
            />
            <Typography.Text>€</Typography.Text>
          </Space>
        </FormItemWithoutColon>
      </Col>
      <Col span={4}>
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button
            size="small"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
              arrayHelpers.insert(index + 1, createNewMahngebuehr());
            }}
          />
          <Button
            size="small"
            shape="circle"
            icon={<MinusOutlined />}
            onClick={() => {
              arrayHelpers.remove(index);
            }}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default Mahngebuehr;
