import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { Tag, Typography } from 'antd';
import { EditableTableColumnProps } from '../../../../components/Table/EditableTableOld/EditableTableProps';
import { VertragVposIndexedValue, VertragVposIndexedValueOrderBy } from '../../../../types';
import { vertragVposIndexedValueFormFields, VertragVposIndexedValueFormValues } from './indexedValueMapper';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import MonthPicker from '../../../../components/DatePicker/MonthPicker/MonthPicker';
import { EuroAmount, Percent } from '../../../../components/Number';
import FactorPercentFormItem from './TableColumnsInEditMode/FactorPercentFormItem';
import {
  generatePathToBeVertragDetailsPage,
  generatePathToBeVertragDetailsPageVertragsdatenVPosIndexationAgreementTab,
  generatePathToBeVertragDetailsPageVertragsdatenVPosTab,
} from '../../../Vertrag/BeVertrag/beVertragUriPaths';
import AmountFormItem from './TableColumnsInEditMode/AmountFormItem';
import { MitarbeiterTooltip } from '../../../../components/Card';
import IndexedValueTableActions from '../../../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/Table/Level3/TableActions/IndexedValueTableActions';
import ActionButtonsInEditMode from '../../../../components/Table/EditableTableOld/ActionButtonsInEditMode';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { getStatusTagColor, isVertragVposIndexedValueStatusApplied } from '../../shared/indexedValueHelpers';
import { pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import { generatePathToFirmendatenIndexSeriesDetailsPage } from '../../../Firmendaten/IndexSeries/firmendatenIndexSeriesUriPaths';
import { IndexationAgreementProvider } from '../../../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/Table/Level2/IndexationAgreementContext';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import ValuePreservationSettings from '../../../shared/ValuePreservation/ValuePreservationSettings/ValuePreservationSettings';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const indexedValueTableColumns = (onAction: () => void): EditableTableColumnProps<VertragVposIndexedValue, VertragVposIndexedValueFormValues>[] => {
  return [
    {
      title: 'Objekt',
      dataIndex: VertragVposIndexedValueOrderBy.Objekt,
      key: VertragVposIndexedValueOrderBy.Objekt,
      editable: false,
      sorter: true,
      render: (text, record) => (
        <DataWithShortenedTextAndExtraInfo text={record.objektKurzBezeichnung} maxTextLength={15}>
          {(shortenedText) => (
            <Link target="_blank" to={pathsToObjektDetailsPage(record.objektId).objektDetails}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedTextAndExtraInfo>
      ),
    },
    {
      title: 'Bestandseinheit',
      dataIndex: VertragVposIndexedValueOrderBy.Bestandseinheit,
      key: VertragVposIndexedValueOrderBy.Bestandseinheit,
      editable: false,
      sorter: true,
      render: (text, record) => (
        <DataWithShortenedTextAndExtraInfo maxTextLength={15} text={record.bestandseinheitBezeichnung}>
          {(shortenedText) => (
            <Link target="_blank" to={generatePathToBestandseinheitDetailsPage(record.objektId, record.bestandseinheitId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedTextAndExtraInfo>
      ),
    },
    {
      title: 'Vertrag',
      dataIndex: VertragVposIndexedValueOrderBy.Vertrag,
      key: VertragVposIndexedValueOrderBy.Vertrag,
      editable: false,
      sorter: true,
      render: (text, record) => (
        <DataWithShortenedTextAndExtraInfo maxTextLength={15} text={record.vertragKurzBezeichnung}>
          {(shortenedText) => (
            <Link target="_blank" to={generatePathToBeVertragDetailsPage(record.objektId, record.bestandseinheitId, record.vertragId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedTextAndExtraInfo>
      ),
    },
    {
      title: 'Rechnungsaussteller',
      dataIndex: VertragVposIndexedValueOrderBy.RechnungsAussteller,
      key: VertragVposIndexedValueOrderBy.RechnungsAussteller,
      width: 150,
      editable: false,
      sorter: true,
      render: (text, record) => (
        <RechtstraegerWithContactsTooltip
          rechtstraegerId={record.rechnungsAusstellerId}
          rechtstraegerName={record.rechnungsAusstellerKurzBezeichnung}
          maxTextLength={20}
        />
      ),
    },
    {
      title: 'Vertragspartner',
      dataIndex: VertragVposIndexedValueOrderBy.Vertragspartner,
      key: VertragVposIndexedValueOrderBy.Vertragspartner,
      editable: false,
      sorter: true,
      render: (text, record) => (
        <RechtstraegerWithContactsTooltip
          rechtstraegerId={record.vertragspartnerId}
          rechtstraegerName={record.vertragspartnerKurzBezeichnung}
          maxTextLength={20}
        />
      ),
    },
    {
      title: 'Indexierter Wert',
      dataIndex: VertragVposIndexedValueOrderBy.IndexedValue,
      key: VertragVposIndexedValueOrderBy.IndexedValue,
      editable: false,
      sorter: (a, b) => compareTwoStringsForSorting(a.name, b.name),
      render: (text, record) => <DataWithShortenedText maxTextLength={15} text={record.name} />,
    },
    {
      title: 'Status',
      dataIndex: VertragVposIndexedValueOrderBy.Status,
      key: VertragVposIndexedValueOrderBy.Status,
      editable: false,
      sorter: true,
      render: (text, record) => <Tag color={getStatusTagColor(record.status.value)}>{record.status.text}</Tag>,
    },
    {
      title: 'Wertsicherungsart',
      dataIndex: VertragVposIndexedValueOrderBy.ValuePreservationType,
      key: VertragVposIndexedValueOrderBy.ValuePreservationType,
      editable: false,
      sorter: true,
      render: (text, record) => (
        <DataWithShortenedText maxTextLength={20} text={record.indexationAgreement.type.text}>
          {(shortenedText) => (
            <Link
              to={generatePathToBeVertragDetailsPageVertragsdatenVPosIndexationAgreementTab(
                record.objektId,
                record.bestandseinheitId,
                record.vertragId,
                record.vertragVposIndexedValueId,
                [record.vorschreibungspositionId, record.indexationAgreement.vertragVposIndexationAgreementId]
              )}
              target="_blank"
            >
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'Indexreihe',
      dataIndex: VertragVposIndexedValueOrderBy.IndexSeries,
      key: VertragVposIndexedValueOrderBy.IndexSeries,
      editable: false,
      sorter: true,
      render: (text, record) => (
        <DataWithShortenedText maxTextLength={20} text={record.indexationAgreement.indexSeries.name}>
          {(shortenedText) => (
            <Link to={generatePathToFirmendatenIndexSeriesDetailsPage(record.indexationAgreement.indexSeries.indexSeriesId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'WS-Einst.',
      key: 'valuePreservationSettings',
      editable: false,
      sorter: false,
      render: (text, record) => <ValuePreservationSettings indexationAgreement={record.indexationAgreement} />,
    },
    {
      title: 'VPos gültig ab',
      dataIndex: VertragVposIndexedValueOrderBy.ValidFrom,
      key: VertragVposIndexedValueOrderBy.ValidFrom,
      editable: true,
      sorter: true,
      render: (text, record) => <CustomFormattedDate value={record.validFrom} day={undefined} />,
      renderInEditMode: () => (
        <FormItemWithoutColon name={vertragVposIndexedValueFormFields.validFrom} style={{ width: '100%', marginBottom: '0px' }}>
          <MonthPicker name={vertragVposIndexedValueFormFields.validFrom} placeholder="Gültig ab auswählen" allowClear={false} />
        </FormItemWithoutColon>
      ),
    },
    {
      title: 'Mon/Jahr alt',
      dataIndex: VertragVposIndexedValueOrderBy.YearMonthOld,
      key: VertragVposIndexedValueOrderBy.YearMonthOld,
      editable: false,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.monthYearOld}</Typography.Text>,
    },
    {
      title: 'Index alt',
      dataIndex: VertragVposIndexedValueOrderBy.IndexOld,
      key: VertragVposIndexedValueOrderBy.IndexOld,
      width: 100,
      align: 'right',
      editable: false,
      sorter: true,
      render: (text, record) => <FormattedNumber value={record.indexOld} />,
    },

    {
      title: 'Vorläufig Index alt',
      dataIndex: VertragVposIndexedValueOrderBy.IndexOldPreliminary,
      key: VertragVposIndexedValueOrderBy.IndexOldPreliminary,
      width: 140,
      editable: false,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.indexOldPreliminary ? 'Ja' : 'Nein'}</Typography.Text>,
    },
    {
      title: 'Mon/Jahr neu',
      dataIndex: VertragVposIndexedValueOrderBy.YearMonthNew,
      key: VertragVposIndexedValueOrderBy.YearMonthNew,
      editable: false,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.monthYearNew}</Typography.Text>,
    },
    {
      title: 'Index neu',
      dataIndex: VertragVposIndexedValueOrderBy.IndexNew,
      key: VertragVposIndexedValueOrderBy.IndexNew,
      width: 100,
      align: 'right',
      editable: false,
      sorter: true,
      render: (text, record) => <FormattedNumber value={record.indexNew} />,
    },
    {
      title: 'Vorläufig Index neu',
      dataIndex: VertragVposIndexedValueOrderBy.IndexNewPreliminary,
      key: VertragVposIndexedValueOrderBy.IndexNewPreliminary,
      width: 140,
      editable: false,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.indexNewPreliminary ? 'Ja' : 'Nein'}</Typography.Text>,
    },
    {
      title: 'Errechneter Faktor (%)',
      dataIndex: VertragVposIndexedValueOrderBy.CalculatedFactorNew,
      key: VertragVposIndexedValueOrderBy.CalculatedFactorNew,
      width: 160,
      align: 'right',
      editable: false,
      sorter: true,
      render: (text, record) => (
        <>
          <FormattedNumber value={record.calculatedFactorNew} /> (<Percent value={record.calculatedFactorNewPercent / 100} />)
        </>
      ),
    },
    {
      title: 'Faktor (%)',
      dataIndex: VertragVposIndexedValueOrderBy.FactorNew,
      key: VertragVposIndexedValueOrderBy.FactorNew,
      width: 100,
      align: 'right',
      editable: true,
      sorter: true,
      render: (text, record) => (
        <>
          <FormattedNumber value={record.factorNew} /> (<Percent value={record.factorNewPercent / 100} />)
        </>
      ),
      renderInEditMode: ({ selectedRecord }, formikProps) => (
        <FactorPercentFormItem
          name={vertragVposIndexedValueFormFields.factorPercent}
          indexationAgreementId={selectedRecord.indexationAgreement.vertragVposIndexationAgreementId}
          indexedValueId={selectedRecord.vertragVposIndexedValueId}
          bestandseinheitId={selectedRecord.bestandseinheitId}
          objektId={selectedRecord.objektId}
          vertragId={selectedRecord.vertragId}
          vorschreibungspositionId={selectedRecord.vorschreibungspositionId}
          formikProps={formikProps}
        />
      ),
    },
    {
      title: 'Betrag alt',
      dataIndex: VertragVposIndexedValueOrderBy.AmountOld,
      key: VertragVposIndexedValueOrderBy.AmountOld,
      width: 100,
      align: 'right',
      editable: false,
      sorter: true,
      render: (text, record) => {
        return record.vposWertOldId ? (
          <Link
            target="_blank"
            to={generatePathToBeVertragDetailsPageVertragsdatenVPosTab(
              record.objektId,
              record.bestandseinheitId,
              record.vertragId,
              record.vposWertOldId,
              [record.vorschreibungspositionId]
            )}
          >
            <EuroAmount value={record.amountOld} />
          </Link>
        ) : (
          <EuroAmount value={record.amountOld} />
        );
      },
    },
    {
      title: 'Errechneter Betrag neu',
      dataIndex: VertragVposIndexedValueOrderBy.CalculatedAmountNew,
      key: VertragVposIndexedValueOrderBy.CalculatedAmountNew,
      width: 160,
      align: 'right',
      editable: false,
      render: (text, record) => <EuroAmount value={record.calculatedAmountNew} />,
    },
    {
      title: 'Betrag neu',
      dataIndex: VertragVposIndexedValueOrderBy.AmountNew,
      key: VertragVposIndexedValueOrderBy.AmountNew,
      width: 100,
      align: 'right',
      editable: true,
      sorter: true,
      render: (text, record) =>
        isVertragVposIndexedValueStatusApplied(record.status.value) && record.vposWertNewId ? (
          <Link
            target="_blank"
            to={generatePathToBeVertragDetailsPageVertragsdatenVPosTab(
              record.objektId,
              record.bestandseinheitId,
              record.vertragId,
              record.vposWertNewId,
              [record.vorschreibungspositionId]
            )}
          >
            <EuroAmount value={record.amountNew} />
          </Link>
        ) : (
          <EuroAmount value={record.amountNew} />
        ),
      renderInEditMode: ({ selectedRecord }, formikProps) => (
        <AmountFormItem
          name={vertragVposIndexedValueFormFields.amount}
          indexationAgreementId={selectedRecord.indexationAgreement.vertragVposIndexationAgreementId}
          indexedValueId={selectedRecord.vertragVposIndexedValueId}
          bestandseinheitId={selectedRecord.bestandseinheitId}
          objektId={selectedRecord.objektId}
          vertragId={selectedRecord.vertragId}
          vorschreibungspositionId={selectedRecord.vorschreibungspositionId}
          formikProps={formikProps}
        />
      ),
    },
    {
      title: 'Erstellt am',
      width: 80,
      dataIndex: VertragVposIndexedValueOrderBy.CreateTs,
      sorter: true,
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      width: 80,
      dataIndex: VertragVposIndexedValueOrderBy.CreatedBy,
      sorter: true,
      render: (text, record) => (
        <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
      ),
    },
    {
      title: 'Aktion',
      key: 'action',
      width: 180,
      editable: true,
      render: (text, record) => (
        <IndexationAgreementProvider
          bestandseinheitId={record.bestandseinheitId}
          objektId={record.objektId}
          vertragId={record.vertragId}
          vorschreibungspositionId={record.vorschreibungspositionId}
        >
          <IndexedValueTableActions indexedValue={record} onAction={onAction} />
        </IndexationAgreementProvider>
      ),
      renderInEditMode: ({ cancelEdit, isSubmitLoading }) => <ActionButtonsInEditMode cancelEdit={cancelEdit} isSubmitLoading={isSubmitLoading} />,
    },
  ];
};

export default indexedValueTableColumns;
