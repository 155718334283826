import { Space, Typography } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { CarOutlined } from '@ant-design/icons';
import InfoFeldListItem from '../InfoFeldListItem';
import { BeInfoFeld_BeInfoFeldParkplatz_Fragment } from '../../../gql/BeInfoFeldFragments.types';
import ParkplatzListItemActions from './ParkplatzListItemActions';
import { generatePathToBestandseinheitDetailsPage } from '../../../../bestandseinheitUriPaths';
import { StyledSpaceSplitA30 } from '../../../../../../components/Space/styled/Space.style';

type Props = {
  parkplatz: BeInfoFeld_BeInfoFeldParkplatz_Fragment;
  objektId: string;
  bestandseinheitId: string;
  onSuccess: () => void;
};

const ParkplatzListItem: FC<Props> = ({ parkplatz, objektId, bestandseinheitId, onSuccess }) => {
  return (
    <InfoFeldListItem
      icon={<CarOutlined />}
      content={{ fileIdList: parkplatz.fileIdList, props: { height: 48 } }}
      actions={<ParkplatzListItemActions infoFeld={parkplatz} objektId={objektId} bestandseinheitId={bestandseinheitId} onSuccess={onSuccess} />}
    >
      <Space direction="vertical" style={{ width: '220px' }}>
        <Typography.Text type="secondary">Parkplatz</Typography.Text>
        <Typography.Text>Nummer {parkplatz.parkplatzNummer}</Typography.Text>
      </Space>
      <StyledSpaceSplitA30>
        {parkplatz.bestandseinheit && (
          <Link to={generatePathToBestandseinheitDetailsPage(objektId, bestandseinheitId)}>{parkplatz.bestandseinheit.bezeichnung}</Link>
        )}
      </StyledSpaceSplitA30>
    </InfoFeldListItem>
  );
};

export default ParkplatzListItem;
