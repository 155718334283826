import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { HeOrSubAbrDefGeneralSettingsFormValues } from './heOrSubAbrDefGeneralSettingsFormMapper';
import RueckstaendeAnzeigenFormItem from './RueckstaendeAnzeigenFormItem';
import RueckstaendeInAbrechnungsergebnisFormItem from './RueckstaendeInAbrechnungsergebnisFormItem';
import DisplayKontonummerFormItem from './DisplayKontonummerFormItem';
import { HeOrSubAbrDefOutputOptionsFieldHelpNames } from '../../../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';

type Props = {
  values: HeOrSubAbrDefGeneralSettingsFormValues;
  onSubmit?: (values: HeOrSubAbrDefGeneralSettingsFormValues) => void;
  fieldHelpNames?: HeOrSubAbrDefOutputOptionsFieldHelpNames;
};

const HeOrSubGeneralSettingsForm: FC<Props> = ({ values, onSubmit, fieldHelpNames }) => {
  const isDisabled = !onSubmit;
  return (
    <Formik<HeOrSubAbrDefGeneralSettingsFormValues> initialValues={values} onSubmit={() => {}}>
      {(formikProps) => (
        <Form>
          <RueckstaendeAnzeigenFormItem
            formikProps={formikProps}
            isDisabled={isDisabled}
            onChange={onSubmit}
            fieldHelp={fieldHelpNames?.rueckstaendeAnzeigen}
          />
          <RueckstaendeInAbrechnungsergebnisFormItem
            formikProps={formikProps}
            isDisabled={isDisabled}
            onChange={onSubmit}
            fieldHelp={fieldHelpNames?.rueckstaendeInAbrechnungsergebnis}
          />
          <DisplayKontonummerFormItem
            formikProps={formikProps}
            isDisabled={isDisabled}
            onChange={onSubmit}
            fieldHelp={fieldHelpNames?.displayKontonummer}
          />
        </Form>
      )}
    </Formik>
  );
};

export default HeOrSubGeneralSettingsForm;
