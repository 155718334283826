import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useKontoQuery } from '../../../features/Kontenplan/gql/KontenplanQueries.types';
import KontoLinkToKontoblatt from './KontoLinkToKontoblatt';
import { KontoblattPageQueryParams } from '../../../features/Kontoblatt/kontoblattUriPaths';

type KontoProps = {
  kontoId: string;
  width?: string;
  maxTextLength?: number;
} & Omit<KontoblattPageQueryParams, 'kontoId'>;

const KontoWithQuery: FC<KontoProps> = ({ kontoId, width, maxTextLength, ...restProps }) => {
  const { data } = useKontoQuery({ variables: { kontoId } });
  const konto = data?.getKonto.data;

  return konto ? <KontoLinkToKontoblatt konto={konto} maxBezeichnungLength={maxTextLength} {...restProps} /> : <Skeleton.Input active size="small" />;
};

export default KontoWithQuery;
