import React, { FC } from 'react';
import { Divider, Empty, Table } from 'antd';
import { Spacer } from '../../../../components/Grid';
import errorLogTableColumns from '../../shared/errorLogTableColumns';
import { ErrorData, ObjektAbrechnung } from '../../../../types';
import TopAbrechnungTable from './Table/TopAbrechnungTable';

type Props = {
  abrechnung: ObjektAbrechnung;
  loading: boolean;
  objektId: string;
  objektAbrechnungId: string;
};

const TopAbrechnungTab: FC<Props> = ({ abrechnung, loading, objektId, objektAbrechnungId }) => {
  const { topAbrechnungOverview } = abrechnung;

  return (
    <>
      <Spacer height={16} />
      {topAbrechnungOverview?.errorList && topAbrechnungOverview.errorList.length > 0 && (
        <>
          <Table<ErrorData>
            size="small"
            pagination={false}
            dataSource={topAbrechnungOverview.errorList}
            rowKey={(record) => record.type}
            columns={errorLogTableColumns}
            locale={{
              emptyText: <Empty description={<span>Keine Fehler vorhanden</span>} />,
            }}
          />
          <Divider />
        </>
      )}
      <TopAbrechnungTable
        loading={loading}
        objektId={objektId}
        objektAbrechnungId={objektAbrechnungId}
        topAbrechnungList={topAbrechnungOverview?.topAbrechnungList}
        abrechnungType={abrechnung.type.value}
      />
    </>
  );
};

export default TopAbrechnungTab;
