import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUstRegelsetTemplateMutationVariables = Types.Exact<{
  input: Types.UstRegelsetCreateInput;
}>;

export type CreateUstRegelsetTemplateMutation = {
  createUstRegelsetTemplate: {
    data: { ustRegelsetId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateUstRegelsetTemplateMutationVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
  input: Types.UstRegelsetUpdateInput;
}>;

export type UpdateUstRegelsetTemplateMutation = {
  updateUstRegelsetTemplate: {
    data: { ustRegelsetId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteUstRegelsetTemplateMutationVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
}>;

export type DeleteUstRegelsetTemplateMutation = {
  deleteUstRegelsetTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateUstRegelsetTemplateDocument = gql`
  mutation CreateUstRegelsetTemplate($input: UstRegelsetCreateInput!) {
    createUstRegelsetTemplate(input: $input) {
      data {
        ustRegelsetId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateUstRegelsetTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUstRegelsetTemplateMutation, CreateUstRegelsetTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUstRegelsetTemplateMutation, CreateUstRegelsetTemplateMutationVariables>(
    CreateUstRegelsetTemplateDocument,
    options
  );
}
export type CreateUstRegelsetTemplateMutationHookResult = ReturnType<typeof useCreateUstRegelsetTemplateMutation>;
export type CreateUstRegelsetTemplateMutationResult = Apollo.MutationResult<CreateUstRegelsetTemplateMutation>;
export type CreateUstRegelsetTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateUstRegelsetTemplateMutation,
  CreateUstRegelsetTemplateMutationVariables
>;
export const UpdateUstRegelsetTemplateDocument = gql`
  mutation UpdateUstRegelsetTemplate($ustRegelsetId: ID!, $input: UstRegelsetUpdateInput!) {
    updateUstRegelsetTemplate(ustRegelsetId: $ustRegelsetId, input: $input) {
      data {
        ustRegelsetId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateUstRegelsetTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUstRegelsetTemplateMutation, UpdateUstRegelsetTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUstRegelsetTemplateMutation, UpdateUstRegelsetTemplateMutationVariables>(
    UpdateUstRegelsetTemplateDocument,
    options
  );
}
export type UpdateUstRegelsetTemplateMutationHookResult = ReturnType<typeof useUpdateUstRegelsetTemplateMutation>;
export type UpdateUstRegelsetTemplateMutationResult = Apollo.MutationResult<UpdateUstRegelsetTemplateMutation>;
export type UpdateUstRegelsetTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateUstRegelsetTemplateMutation,
  UpdateUstRegelsetTemplateMutationVariables
>;
export const DeleteUstRegelsetTemplateDocument = gql`
  mutation DeleteUstRegelsetTemplate($ustRegelsetId: ID!) {
    deleteUstRegelsetTemplate(ustRegelsetId: $ustRegelsetId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteUstRegelsetTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUstRegelsetTemplateMutation, DeleteUstRegelsetTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUstRegelsetTemplateMutation, DeleteUstRegelsetTemplateMutationVariables>(
    DeleteUstRegelsetTemplateDocument,
    options
  );
}
export type DeleteUstRegelsetTemplateMutationHookResult = ReturnType<typeof useDeleteUstRegelsetTemplateMutation>;
export type DeleteUstRegelsetTemplateMutationResult = Apollo.MutationResult<DeleteUstRegelsetTemplateMutation>;
export type DeleteUstRegelsetTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteUstRegelsetTemplateMutation,
  DeleteUstRegelsetTemplateMutationVariables
>;
