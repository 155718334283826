import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFirmendatenFileInfoInvoiceIssuerListQuery } from '../../gql/FirmendatenFileInfoFilterQueries.types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = SelectProps;

const DocumentInvoiceIssuerListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useFirmendatenFileInfoInvoiceIssuerListQuery();
  const list = data?.getFirmendatenFileInfoInvoiceIssuerList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Rechnungsaussteller auswählen"
      filterOption={selectFilterOption}
      allowClear
      showSearch
    >
      {list.map((invoiceIssuer) => (
        <Select.Option key={invoiceIssuer.rechtstraegerId} value={invoiceIssuer.rechtstraegerId}>
          <DataWithShortenedText maxTextLength={35} text={invoiceIssuer.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default DocumentInvoiceIssuerListFormSelect;
