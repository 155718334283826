import React, { FC } from 'react';
import { Button, Divider, Modal, Skeleton, Space } from 'antd';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { IncomingInvoiceStatus, SingleInvoice, StandingInvoice } from '../../../types';
import { isStatusErstellt, isStatusUebertragenFehler } from '../../../helpers/statusHelper';
import SingleInvoiceBuchungPositionBlock from './SingleInvoiceBuchungPositionBlock';
import SingleInvoiceSummeBlock from './SingleInvoiceSummeBlock';
import IncomingInvoiceDetailsRechnungBlock from './IncomingInvoiceDetailsRechnungBlock';
import IncomingInvoiceDetailsPaymentBlock from './IncomingInvoiceDetailsPaymentBlock';
import { TInvoice } from '../Form/incomingInvoiceFormMapper';
import { isInvoiceTypeSingleInvoice, isInvoiceTypeStandingInvoice } from '../Form/incomingInvoiceFormHelpers';
import StandingInvoicePartialAmountBlock from './StandingInvoicePartialAmountBlock';
import theme from '../../../theme';
import { useToggle } from '../../../hooks/useToggle';
import BookingPreviewForDetailsPage from '../Form/Preview/BookingPreview/BookingPreviewForDetailsPage';
import { Spacer } from '../../../components/Grid';
import { generatePathToIncomingInvoiceListingPage } from '../Listing/Filters/filtersQueryParams';
import { isIncomingInvoiceFreigebenAllowed, isIncomingInvoiceUpdateAllowed } from '../incomingInvoiceHelpers';
import { incomingInvoicePaths } from '../incomingInvoiceUriPaths';

type Props = {
  belegId: string;
  actionFreigeben: () => void;
  incomingInvoice?: TInvoice;
};

const IncomingInvoiceDetails: FC<Props> = ({ belegId, actionFreigeben, incomingInvoice }) => {
  const [isBookingPreviewModalOpen, toggleBookingPreviewModal] = useToggle();

  const navigate = useNavigate();

  if (!incomingInvoice) {
    return <Skeleton active paragraph />;
  }

  const path = isInvoiceTypeSingleInvoice(incomingInvoice.type)
    ? incomingInvoicePaths.singleInvoiceUpdate
    : incomingInvoicePaths.standingInvoiceUpdate;

  return (
    <div>
      <IncomingInvoiceDetailsRechnungBlock incomingInvoice={incomingInvoice} belegId={belegId} />
      <IncomingInvoiceDetailsPaymentBlock incomingInvoice={incomingInvoice} />
      {isInvoiceTypeSingleInvoice(incomingInvoice.type) && (
        <>
          <SingleInvoiceBuchungPositionBlock incomingInvoice={incomingInvoice as SingleInvoice} />
          <SingleInvoiceSummeBlock incomingInvoice={incomingInvoice as SingleInvoice} />
        </>
      )}
      {isInvoiceTypeStandingInvoice(incomingInvoice.type) && (
        <StandingInvoicePartialAmountBlock incomingInvoice={incomingInvoice as StandingInvoice} />
      )}

      <Divider style={{ borderColor: theme.colors.black }} />

      <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
        <Button
          type="link"
          onClick={toggleBookingPreviewModal}
          style={{ paddingInline: 0, marginBottom: '4px' }}
          disabled={!isStatusErstellt(incomingInvoice.status)}
        >
          Buchungen Vorschau
        </Button>
        <Button type="link" onClick={() => navigate(generatePathToIncomingInvoiceListingPage({}))} style={{ paddingInline: 0, marginBottom: '4px' }}>
          Zu den Eingangsrechnungen
        </Button>
      </Space>

      <Spacer height={16} />

      <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
        <Button
          loading={incomingInvoice.status.value === IncomingInvoiceStatus.Freigegeben}
          onClick={() => navigate(generatePath(path, { fileId: incomingInvoice.fileId, belegId }))}
          disabled={!(isIncomingInvoiceUpdateAllowed(incomingInvoice.status.value) || isStatusUebertragenFehler(incomingInvoice.status))}
        >
          Rechnung bearbeiten
        </Button>
        <Button type="primary" onClick={() => actionFreigeben()} disabled={!isIncomingInvoiceFreigebenAllowed(incomingInvoice.status.value)}>
          Rechnung freigeben
        </Button>
      </Space>
      <Modal
        title="Eingangsrechnung Buchungsvorschau"
        open={isBookingPreviewModalOpen}
        onCancel={toggleBookingPreviewModal}
        footer={null}
        destroyOnClose
        maskClosable={false}
        width={976}
      >
        <BookingPreviewForDetailsPage incomingInvoice={incomingInvoice} toggleBookingPreviewModal={toggleBookingPreviewModal} />
      </Modal>
    </div>
  );
};

export default IncomingInvoiceDetails;
