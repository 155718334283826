import React from 'react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { isNewTimeblock, ITimeblock, IVersionable, IVersionableFormValues, useTimeline } from '../timelineCtx';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../Button/ButtonsAligned';

type TimelineFormButtonsProps<T extends IVersionable<T>> = {
  timeblock: ITimeblock<T>;
  isSubmitting: boolean;
  isSameAsPreviousVersion: boolean;
  submitBtnLoading?: boolean;
};

function TimelineFormButtons<T extends IVersionable<T>>({
  timeblock,
  isSubmitting,
  isSameAsPreviousVersion,
  submitBtnLoading,
}: TimelineFormButtonsProps<T>) {
  const { toggleEdit, changeToUpdateMode, onValidFromChange } = useTimeline();

  const { initialValues } = useFormikContext<IVersionableFormValues>();
  const initialValidFrom = initialValues.validFrom;

  return (
    <ButtonsAligned usage={ButtonsAlignedUsage.drawerCardFooter}>
      <Button
        ghost
        onClick={() => {
          toggleEdit(timeblock.uuid);
          if (isNewTimeblock(timeblock)) {
            changeToUpdateMode(timeblock.uuid);
          } else {
            onValidFromChange(timeblock.uuid, dayjsCustom(initialValidFrom));
          }
        }}
      >
        Abbrechen
      </Button>
      <Button htmlType="submit" type="primary" disabled={isSubmitting || isSameAsPreviousVersion} loading={submitBtnLoading}>
        Änderung speichern
      </Button>
    </ButtonsAligned>
  );
}

export default TimelineFormButtons;
