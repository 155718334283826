import React, { FC } from 'react';
import { Tag } from 'antd';
import { SollHaben, SollHabenTuple } from '../../types';

type Props = {
  sollHaben: SollHabenTuple;
};

const SollHabenTag: FC<Props> = ({ sollHaben }) =>
  sollHaben.value === SollHaben.Soll ? <Tag color="red">{sollHaben.text}</Tag> : <Tag color="green">{sollHaben.text}</Tag>;

export default SollHabenTag;
