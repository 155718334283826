import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type PostItFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  immerAnzeigen: boolean;
  postItId: string;
  text: string;
  titel: string;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const PostItFragmentDoc = gql`
  fragment PostIt on PostIt {
    createTs
    createdBy
    createdByMitarbeiterId
    immerAnzeigen
    postItId
    text
    titel
    warningList {
      attribute
      message
      type
    }
  }
`;
