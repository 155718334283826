import React, { FC } from 'react';
import { CardProps } from 'antd/lib/card';
import { Card } from 'antd';
import AbrDefTemplateVersionTimelineCardExtra from './AbrDefTemplateVersionTimelineCardExtra';
import { AbrechnungsdefinitionVersionListData } from '../../../Abrechnungsdefinition/shared/abrDefTypes';
import AbrDefVersionTimelineCardTitle from '../../../Abrechnungsdefinition/Version/Timeline/AbrDefVersionTimelineCardTitle';

type AbrDefVersionTimelineCardProps = {
  version: AbrechnungsdefinitionVersionListData;
  abrechnungsdefinitionId: string;
  isHistoricized?: boolean;
  onDelete?: (version: AbrechnungsdefinitionVersionListData) => Promise<unknown>;
  isDeleteAllowed?: boolean;
} & CardProps;

const AbrDefTemplateVersionTimelineCard: FC<AbrDefVersionTimelineCardProps> = ({
  version,
  abrechnungsdefinitionId,
  isHistoricized,
  onDelete,
  isDeleteAllowed = true,
  ...restProps
}) => {
  return (
    <Card
      hoverable
      title={<AbrDefVersionTimelineCardTitle version={version} isHistoricized={isHistoricized} />}
      extra={
        <AbrDefTemplateVersionTimelineCardExtra
          version={version}
          abrechnungsdefinitionId={abrechnungsdefinitionId}
          onDelete={onDelete}
          isDeleteAllowed={isDeleteAllowed}
          isHistoricized={isHistoricized}
        />
      }
      headStyle={isHistoricized ? { paddingLeft: '24px' } : undefined}
      bodyStyle={{ padding: 0 }}
      style={{ zIndex: isHistoricized ? 0 : 1 }}
      {...restProps}
    >
      <></>
    </Card>
  );
};

export default AbrDefTemplateVersionTimelineCard;
