import React, { FC } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { useToggle } from '../../../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';
import IndexSeriesEntryForm from '../../../IndexSeriesEntry/IndexSeriesEntryForm';
import { IndexSeries } from '../../../../types';
import { isIndexSeriesSourceTypeImport, isIndexSeriesSourceTypeManual } from '../../indexSeriesHelpers';
import FirmendatenIndexSeriesEntryForm from '../../../Firmendaten/IndexSeriesEntry/FirmendatenIndexSeriesEntryForm';

type Props = {
  onSuccess: () => void;
  indexSeries: IndexSeries;
  showPreliminary: boolean;
  toggleShowPreliminary: () => void;
};

const IndexSeriesDetailsBtnAndDrawer: FC<Props> = ({ onSuccess, indexSeries, showPreliminary, toggleShowPreliminary }) => {
  const [isDrawerOpen, toggleDrawer] = useToggle();

  const onAction = () => {
    toggleDrawer();
    onSuccess();
  };

  return (
    <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
      {isIndexSeriesSourceTypeImport(indexSeries.sourceType.value) && (
        <Button onClick={toggleShowPreliminary}>Vorläufige Werte {showPreliminary ? 'nicht anzeigen' : 'anzeigen'}</Button>
      )}
      {(isIndexSeriesSourceTypeManual(indexSeries.sourceType.value) || indexSeries.firmendatenId) && (
        <Button icon={<PlusOutlined />} type="primary" onClick={toggleDrawer}>
          Jahr anlegen
        </Button>
      )}
      <Drawer title="Jahr anlegen" width={720} open={isDrawerOpen} onClose={toggleDrawer} destroyOnClose>
        {indexSeries.firmendatenId ? (
          <FirmendatenIndexSeriesEntryForm
            onAction={onAction}
            indexSeriesId={indexSeries.indexSeriesId}
            indexSeriesType={indexSeries.indexType.value}
          />
        ) : (
          <IndexSeriesEntryForm onAction={onAction} indexSeriesId={indexSeries.indexSeriesId} indexSeriesType={indexSeries.indexType.value} />
        )}
      </Drawer>
    </ButtonsAligned>
  );
};

export default IndexSeriesDetailsBtnAndDrawer;
