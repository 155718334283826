import React, { FC } from 'react';
import { Typography } from 'antd';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';

type Props = {
  companyRegistrationNumber?: string | null;
};

const CompanyRegistrationNumber: FC<Props> = ({ companyRegistrationNumber }) => (
  <CardDetailsInfoRow infoRowTitle="Firmenbuchnummer">
    <Typography.Text>{companyRegistrationNumber || 'keine Firmenbuchnummer angegeben'}</Typography.Text>
  </CardDetailsInfoRow>
);

export default CompanyRegistrationNumber;
