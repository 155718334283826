import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { useApolloClient } from '@apollo/client';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { TIncomingInvoiceQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { FiltersFormValues, mapFormValuesToQueryParams, mapQueryParamsToIncomingInvoiceListQueryVariables } from './Filters/filtersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import IncomingInvoiceTable from './Table/Level1/IncomingInvoiceTable';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import {
  IncomingInvoiceListDocument,
  IncomingInvoiceListSummaryDocument,
  useIncomingInvoiceListQuery,
  useIncomingInvoiceListSummaryQuery,
} from '../gql/IncomingInvoiceQueries.types';
import ListSummary from './ListSummary';
import { useOnIncomingInvoiceDataChangeEvents } from './useOnIncomingInvoiceDataChangeEvents';
import {
  IncomingInvoiceAusstellerBankDetailsListDocument,
  IncomingInvoiceAusstellerListDocument,
  IncomingInvoiceInvoiceNumberListDocument,
  IncomingInvoiceKontoListDocument,
  IncomingInvoiceKundennummerListDocument,
  IncomingInvoicePayeeBankDetailsListDocument,
  IncomingInvoicePayeeListDocument,
  IncomingInvoicePaymentReferenceTextListDocument,
  IncomingInvoicePurposeOfUseTextListDocument,
  IncomingInvoiceSepaMandateReferenceListDocument,
} from '../gql/IncomingInvoiceFilterQueries.types';
import { ObjektListDocument } from '../../Objekt/gql/ObjektQueries.types';

const IncomingInvoiceListing = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TIncomingInvoiceQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  const { data, loading, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useIncomingInvoiceListQuery,
    {
      variables: {
        ...mapQueryParamsToIncomingInvoiceListQueryVariables(queryParams),
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const incomingInvoiceList = data?.getIncomingInvoiceList.data?.contentList;

  const { data: summaryData, refetch: summaryRefetch } = useIncomingInvoiceListSummaryQuery({
    variables: {
      ...mapQueryParamsToIncomingInvoiceListQueryVariables(queryParams),
    },
  });

  const summary = summaryData?.getIncomingInvoiceListSummary.data;

  const onAction = () => refetch().then(() => summaryRefetch());

  const client = useApolloClient();
  useOnIncomingInvoiceDataChangeEvents('incoming-invoice-listing', async () => {
    await client.refetchQueries({
      include: [
        IncomingInvoiceListDocument,
        IncomingInvoiceListSummaryDocument,
        IncomingInvoiceAusstellerListDocument,
        IncomingInvoicePayeeListDocument,
        ObjektListDocument,
        IncomingInvoiceInvoiceNumberListDocument,
        IncomingInvoiceAusstellerBankDetailsListDocument,
        IncomingInvoicePayeeBankDetailsListDocument,
        IncomingInvoiceKontoListDocument,
        IncomingInvoiceKundennummerListDocument,
        IncomingInvoicePaymentReferenceTextListDocument,
        IncomingInvoicePurposeOfUseTextListDocument,
        IncomingInvoiceSepaMandateReferenceListDocument,
      ],
    });
  });

  return (
    <>
      <Formik<FiltersFormValues> initialValues={queryParams} onSubmit={onChange}>
        {(formikProps) => <ListingFilters queryParams={queryParams} formikProps={formikProps} onAction={onAction} />}
      </Formik>
      <ListSummary summary={summary} />
      <IncomingInvoiceTable
        onAction={onAction}
        incomingInvoiceList={incomingInvoiceList}
        loading={loading}
        pagination={pagination}
        handleTableSorting={handleTableSorting}
      />
    </>
  );
};

export default IncomingInvoiceListing;
