import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { Abrechnungskreis } from '../../../objektAbrechnung-types';
import OAAbrKreisBezeichnung from './OAAbrKreisBezeichnung';
import OAAbrKreisNonRepFonds from './NonRepFonds/OAAbrKreisNonRepFonds';
import OAAbrKreisRepFonds from './RepFonds/OAAbrKreisRepFonds';

const OAAbrechnungskreis: FC<{ abrechKreis: Abrechnungskreis }> = ({ abrechKreis }) => (
  <View style={[pdfStyles.column, { paddingTop: '5mm' }]}>
    <OAAbrKreisBezeichnung abrechKreis={abrechKreis} />

    {abrechKreis.type === 'REPARATUR_FONDS' ? <OAAbrKreisRepFonds abrechKreis={abrechKreis} /> : <OAAbrKreisNonRepFonds abrechKreis={abrechKreis} />}
  </View>
);

export default OAAbrechnungskreis;
