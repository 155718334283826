import React, { FC } from 'react';
import { generatePath } from 'react-router';
import { Button, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { SubAbrechnung } from '../../../../types';
import { useDeleteSubAbrechnungMutation } from '../gql/SubAbrechnungMutations.types';
import { showSuccessMsgDelete } from '../../../../components/message';
import { URI_OBJEKT_DETAILS_PAGE } from '../../../Objekt/objektUriPaths';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';

type Props = {
  abrechnung: SubAbrechnung;
  objektId: string;
};
const SubAbrechnungCardActions: FC<Props> = ({ abrechnung, objektId }) => {
  const navigate = useNavigate();

  const [runDelete] = useDeleteSubAbrechnungMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Subverwaltungsabrechnung');
      navigate(generatePath(URI_OBJEKT_DETAILS_PAGE, { objektId }), { replace: true });
    },
    variables: {
      objektId,
      subAbrechnungId: abrechnung.subAbrechnungId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Löschen',
      onClick: () => showConfirmDelete(abrechnung, runDelete),
      icon: <DeleteOutlined />,
      danger: true,
      disabled: !abrechnung.deletable,
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
    </Dropdown>
  );
};

const showConfirmDelete = (abrechnung: SubAbrechnung, runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Subverwaltungsabrechnung löschen?',
    content: `${abrechnung.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default SubAbrechnungCardActions;
