import { FC } from 'react';
import { useToggle } from '../../../../../../hooks/useToggle';
import { showSuccessMsgCreate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import PostItIconAndViewAndUpdateMode from '../../../../../../components/PostIt/PostItIconAndViewAndUpdateMode';
import PostItIconAndCreateMode from '../../../../../../components/PostIt/PostItIconAndCreateMode';
import { PostIt, PostItInput } from '../../../../../../types';
import { useCreateBeVertragInfoFeldPostItMutation } from '../../gql/VertragInfoFeldMutations.types';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  vertragInfoFeldId: string;
  postIt?: PostIt | null;
  refresh: () => void;
};

const VertragInfoFeldPostIt: FC<Props> = ({ objektId, bestandseinheitId, vertragId, vertragInfoFeldId, postIt, refresh }) => {
  const [isPostItOpen, toggleIsPostItOpen] = useToggle(false);

  const [runCreate] = useCreateBeVertragInfoFeldPostItMutation({
    onCompleted: () => {
      showSuccessMsgCreate('Post-It');
      toggleIsPostItOpen();
      refresh();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleCreateSubmit = (values: PostItInput) => {
    runCreate({
      variables: {
        objektId,
        bestandseinheitId,
        vertragId,
        vertragInfoFeldId,
        input: {
          immerAnzeigen: false,
          titel: values.titel,
          text: values.text,
        },
      },
    });
  };

  const handleCreateCancel = () => {
    toggleIsPostItOpen();
  };

  return postIt ? (
    <PostItIconAndViewAndUpdateMode postIt={postIt} refresh={refresh} showImmerAnzeigenInUpdateMode={false} />
  ) : (
    <PostItIconAndCreateMode
      isPostItOpen={isPostItOpen}
      onIconClick={toggleIsPostItOpen}
      onFormCancel={handleCreateCancel}
      onFormSubmit={handleCreateSubmit}
      showImmerAnzeigen={false}
    />
  );
};

export default VertragInfoFeldPostIt;
