import { EbicsUserStatus } from '../../../../../types';
import { TEbicsUserQueryParams } from './filtersQueryParams';
import { FormFields } from '../../../../../helpers/formikHelper';

// INFO: Found 2 elements with non-unique id #ebicsBankConfigId: (More info: https://goo.gl/9p2vKq)
// ebicsBankConfigId in filters + form drawer
// ebicsBankConfigId === banknameId
export type EbicsUserListingFiltersFormValues = {
  banknameId?: string | null;
  status?: EbicsUserStatus | null;
  ibanList?: string[] | null;
  mitarbeiterIdList?: string[] | null;
};

export const ebicsUserListingFiltersFormInitialValues = (filters: TEbicsUserQueryParams): EbicsUserListingFiltersFormValues => ({
  banknameId: filters.ebicsBankConfigId,
  status: filters.status,
  ibanList: filters.ibanList,
  mitarbeiterIdList: filters.mitarbeiterIdList,
});

export const ebicsUserListingFiltersFormFields: FormFields<EbicsUserListingFiltersFormValues> = {
  banknameId: 'banknameId',
  status: 'status',
  ibanList: 'ibanList',
  mitarbeiterIdList: 'mitarbeiterIdList',
};

export const mapFormValuesToEbicsUserListingFilters = (formValues: EbicsUserListingFiltersFormValues): TEbicsUserQueryParams => ({
  ebicsBankConfigId: formValues.banknameId,
  status: formValues.status,
  ibanList: formValues.ibanList,
  mitarbeiterIdList: formValues.mitarbeiterIdList,
});
