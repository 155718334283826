import { FormFields } from '../../../helpers/formikHelper';
import {
  BudgetingVPosAuftschluesselLinkage,
  BudgetingVPosAuftschluesselLinkageCreateInput,
  BudgetingVPosAuftschluesselLinkageUpdateInput,
  VertragLevel,
  Verwaltungsart,
} from '../../../types';

export type LinkageFormValues = {
  aufteilungsschluesselId: string;
  firmendatenId: string | null;
  objektVerwaltungsart: Verwaltungsart | null;
  vertragLevel: VertragLevel | null;
  vorschreibungspositionId: string;
};

export const linkageFormFields: FormFields<LinkageFormValues> = {
  aufteilungsschluesselId: 'aufteilungsschluesselId',
  firmendatenId: 'firmendatenId',
  objektVerwaltungsart: 'objektVerwaltungsart',
  vertragLevel: 'vertragLevel',
  vorschreibungspositionId: 'vorschreibungspositionId',
};

export const linkageFormInitialValues: LinkageFormValues = {
  aufteilungsschluesselId: '',
  firmendatenId: null,
  objektVerwaltungsart: null,
  vertragLevel: null,
  vorschreibungspositionId: '',
};

export const mapLinkageToFormValues = (linkage?: BudgetingVPosAuftschluesselLinkage): LinkageFormValues => {
  if (!linkage) {
    return linkageFormInitialValues;
  }
  return {
    aufteilungsschluesselId: linkage.aufteilungsschluessel.aufteilungsschluesselId,
    firmendatenId: linkage.firmendatenId ?? null,
    objektVerwaltungsart: linkage.objektVerwaltungsart.value,
    vertragLevel: linkage.vertragLevel.value,
    vorschreibungspositionId: linkage.vorschreibungsposition.vorschreibungspositionId,
  };
};

export const mapFormValuesToLinkage = (
  formValues: LinkageFormValues
): BudgetingVPosAuftschluesselLinkageCreateInput | BudgetingVPosAuftschluesselLinkageUpdateInput => ({
  aufteilungsschluesselId: formValues.aufteilungsschluesselId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  objektVerwaltungsart: formValues.objektVerwaltungsart!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  vertragLevel: formValues.vertragLevel!,
  vorschreibungspositionId: formValues.vorschreibungspositionId,
});
