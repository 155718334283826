import React, { FC } from 'react';
import { useOffenePostenListSummaryQuery } from './gql/OffenePostenQueries.types';
import { StyledListSummarySpace } from '../shared/ListSummary/styled/StyledListSummarySpace';
import { SummaryText } from '../shared/ListSummary/SummaryText';
import { TOffenePostenQueryParams } from './offenePostenUriPaths';
import { mapQueryParamsToOffenePostenQueryVariables } from './Filters/offenePostenListingFiltersFormMapper';
import { LoadingCol } from '../../components/Loading';

type Props = {
  queryParams: TOffenePostenQueryParams;
  showAusgeziffert: boolean;
};

const OffenePostenListSummary: FC<Props> = ({ queryParams, showAusgeziffert }) => {
  const { data } = useOffenePostenListSummaryQuery({
    variables: {
      ...mapQueryParamsToOffenePostenQueryVariables(queryParams, showAusgeziffert),
    },
  });

  const summary = data?.getOffenePostenListSummary.data;

  return (
    <>
      {summary ? (
        <StyledListSummarySpace>
          <SummaryText text="Brutto" value={summary.sumBrutto} />
          <SummaryText text="Offener Betrag" value={summary.sumOutstandingAmount} />
        </StyledListSummarySpace>
      ) : (
        <LoadingCol isLoading />
      )}
    </>
  );
};

export default OffenePostenListSummary;
