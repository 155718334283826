import React, { FC } from 'react';
import { SelectProps } from 'formik-antd';
import { Space, Tag, Typography } from 'antd';
import { useKontoListQuery } from '../Kontenplan/gql/KontenplanQueries.types';
import { Konto, KontoKlasse } from '../../types';
import { selectFilterOption } from '../../helpers/selectHelper';
import EntityStatusFormSelect from '../../components/Status/EntityStatus/MainTypes/EntityStatusFormSelect';

type Props = {
  excludedKontenIds?: string[];
  showKontoNummerAsTag: boolean;
  klasseList: KontoKlasse[];
} & SelectProps;

const AufwandskontenSelect: FC<Props> = ({ excludedKontenIds, klasseList, showKontoNummerAsTag, ...restProps }) => {
  const { data, loading } = useKontoListQuery({
    variables: {
      klasseList,
      includeArchiviert: true,
    },
  });
  const kontoList = data?.getKontoList.data.contentList || [];
  const exculudedKontoList = kontoList.filter((konto) => !excludedKontenIds?.includes(konto.kontoId));

  const content = (konto: Konto) =>
    showKontoNummerAsTag ? (
      <Space size="small">
        <Tag>{konto.nummer}</Tag>
        <Typography.Text>{konto.bezeichnung}</Typography.Text>
      </Space>
    ) : (
      <Typography.Text>
        {konto.nummer} - {konto.bezeichnung}
      </Typography.Text>
    );

  return (
    <EntityStatusFormSelect<Konto>
      list={exculudedKontoList}
      getSelectItemId={(item) => item.kontoId}
      getSelectItemTooltipTitle={(item) => item.bezeichnung}
      getSelectItemContent={(item) => content(item)}
      loading={loading}
      placeholder={restProps.placeholder ?? 'Aufwandskonten auswählen'}
      data-testid="select-aufwandskonten"
      showSearch
      filterOption={selectFilterOption}
      {...restProps}
    />
  );
};

export default AufwandskontenSelect;
