import React, { FC } from 'react';
import { Empty, Tabs, TabsProps, Typography } from 'antd';
import kontenZuordnungTableColumns from './kontenZuordnungTableColumns';
import { Spacer } from '../../../components/Grid';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { AbrechnungsdefinitionKontoAbrechnungskreis, Konto } from '../../../types';
import { useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery } from '../../Abrechnungsdefinition/gql/AbrDefQueries.types';

export const KontoTabs = {
  ABRECHNUNGEN: { name: 'Abrechnungen', key: '1' },
} as const;

type Props = {
  konto: Konto;
};

const KontoAbrechnungenTabs: FC<Props> = ({ konto }) => {
  const { loading, data: maybeData } = useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery({
    variables: {
      kontoId: konto.kontoId,
    },
  });

  const zuordnungList = maybeData?.getAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList.data ?? [];

  const { name: label, key } = KontoTabs.ABRECHNUNGEN;

  const items: TabsProps['items'] = [
    {
      key,
      label,
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <Typography.Title level={4}>Abrechnungszuordnungen</Typography.Title>
          <Spacer />
          <TableWithColSelector<AbrechnungsdefinitionKontoAbrechnungskreis>
            size="small"
            pagination={false}
            locale={{
              emptyText: <Empty description={<span>Dieses Konto ist zu keiner Abrechnungsdefinition zugeordnet</span>} />,
            }}
            loading={loading}
            dataSource={zuordnungList}
            rowKey={(record) => record.abrechnungsdefinition.abrechnungsdefinitionId}
            columns={kontenZuordnungTableColumns}
            filterIdentifier="sys-settings-kontenplan-konto-abrechnung"
          />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default KontoAbrechnungenTabs;
