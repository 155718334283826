import React, { FC } from 'react';
import { Select } from 'antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';

type Props = {
  eigentuemerList: EigentuemerAttributes[];
  selectedEigentuemerId?: string;
  setSelectedEigentuemerId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export interface EigentuemerAttributes {
  rechtstraegerId: string;
  kurzBezeichnung: string;
}

const ObjSubAdministrationEigentuemerSelect: FC<Props> = ({ eigentuemerList, selectedEigentuemerId, setSelectedEigentuemerId }) => {
  return (
    <Select
      placeholder="Eigentümer auswählen"
      allowClear
      size="small"
      style={{ minWidth: '160px' }}
      defaultValue={selectedEigentuemerId}
      showSearch
      filterOption={selectFilterOption}
      onChange={(value) => setSelectedEigentuemerId(value)}
    >
      {eigentuemerList.map((rechtstraeger) => (
        <Select.Option value={rechtstraeger.rechtstraegerId} key={rechtstraeger.rechtstraegerId}>
          {rechtstraeger.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ObjSubAdministrationEigentuemerSelect;
