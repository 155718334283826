export type SelectableSelection = 'TITELTEXT' | 'EINLEITUNGSTEXT' | 'ERGEBNISTEXT' | 'SCHLUSSTEXT' | 'AUSGABENTABELLE' | 'HEADER' | 'FOOTER';

export type BankDetails = {
  titel: string;
  iban: string;
  bic: string;
  accountHolder: string;
  accountHolderText: string;
  bankname: string;
  bankText: string;
};

export type RechnungsAussteller = {
  kurzBezeichnung: string;
  kurzBezeichnungText: string;
  vatIdentificationNumber?: string | null;
  vatIdentificationNumberText: string;
  bankDetails?: BankDetails | null;
};

export type RechnungsEmpfaenger = {
  rechnungsEmpfaengerShippingLabel: RechnungsEmpfaengerShippingLabel;
  kundennummer: KundenNummer;
};

export type RechnungsEmpfaengerShippingLabel = {
  line1: string;
  line2: string;
  line3: string;
  line4?: string | null;
  line5?: string | null;
  line6?: string | null;
  line7?: string | null;
};

export type KundenNummer = {
  text: string;
  nummer: string;
};

export type SachbearbeiterType = {
  heading: string;
  title?: string | null;
  firstname: string;
  lastname: string;
  titleTrailing?: string | null;
  email?: string | null;
  emailText: string;
  phone?: string | null;
  phoneText: string;
};

export type DocumentHeaderAndFooterImageAlignment = 'CENTER' | 'LEFT' | 'RIGHT';

export type DocumentBase = {
  header?: DocumentHeaderAndFooter | null;
  footer?: DocumentHeaderAndFooter | null;
};

export type DocumentHeaderAndFooter = DocumentHeaderAndFooterImage | DocumentHeaderAndFooterText;

export enum DocumentHeaderAndFooterType {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

export type DocumentHeaderAndFooterImageValue = {
  value: string | null;
  alignment?: DocumentHeaderAndFooterImageAlignment | null;
};

export type DocumentHeaderAndFooterImage = {
  value?: DocumentHeaderAndFooterImageValue | null;
  type: DocumentHeaderAndFooterType.IMAGE;
};

export type DocumentHeaderAndFooterText = {
  value?: string | null;
  type: DocumentHeaderAndFooterType.TEXT;
};

export type LabeledValue = {
  label: string;
  value: string;
};

export type LabelWithOptionalValue = {
  label: string;
  value?: string | null;
};
