import React, { FC } from 'react';
import { useField } from 'formik';
import { Col, Row } from 'antd';
import { InputNumber, InputNumberProps, SelectProps } from 'formik-antd';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import DateUnitSelect from '../../DateUnitSelect/DateUnitSelect';

type Props = {
  name: string;
  amountProps?: InputNumberProps;
  unitProps?: SelectProps;
  fieldHelp?: {
    unit: string | null | undefined;
    amount: string | null | undefined;
  };
  allowClear?: boolean;
  onChange?: (value: { amount: number; unit: string }) => void;
};

const TicketPeriod: FC<Props> = ({ name, amountProps, unitProps, fieldHelp, allowClear, onChange }) => {
  const amount = `${name}.amount`;
  const unit = `${name}.unit`;

  const [, { value }] = useField(name);
  const [, , { setValue: setUnitValue }] = useField(unit);

  return (
    <Row gutter={16}>
      <Col flex="1">
        <FormItemWithFieldHelp name={amount} label="Anzahl" fieldHelp={fieldHelp?.amount}>
          <InputNumber
            id={amount}
            name={amount}
            placeholder="Anzahl"
            style={{ width: '100%' }}
            {...amountProps}
            onChange={(amount) => {
              onChange?.({ ...value, amount });
            }}
          />
        </FormItemWithFieldHelp>
      </Col>
      <Col flex="1">
        <FormItemWithFieldHelp name={unit} label="Einheit" fieldHelp={fieldHelp?.unit}>
          <DateUnitSelect
            allowClear={allowClear}
            id={unit}
            name={unit}
            size="middle"
            style={{ width: '100%' }}
            {...unitProps}
            onSelect={(unit) => {
              onChange?.({ ...value, unit });
            }}
            onChange={(value) => {
              setUnitValue(value || null);
            }}
          />
        </FormItemWithFieldHelp>
      </Col>
    </Row>
  );
};

export default TicketPeriod;
