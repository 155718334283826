import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontierungstabelleFieldsFragmentDoc } from '../../Kontierungstabelle/gql/KontierungstabelleFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KontierungstabelleTemplateListQueryVariables = Types.Exact<{
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type KontierungstabelleTemplateListQuery = {
  getKontierungstabelleTemplateList: {
    data: Array<{
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type KontierungstabelleTemplateQueryVariables = Types.Exact<{
  kontierungstabelleId: Types.Scalars['ID']['input'];
}>;

export type KontierungstabelleTemplateQuery = {
  getKontierungstabelleTemplate: {
    data: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type KontierungstabelleTemplateChangeHistoryListQueryVariables = Types.Exact<{
  kontierungstabelleId: Types.Scalars['ID']['input'];
}>;

export type KontierungstabelleTemplateChangeHistoryListQuery = {
  getKontierungstabelleTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const KontierungstabelleTemplateListDocument = gql`
  query KontierungstabelleTemplateList($includeArchiviert: Boolean) {
    getKontierungstabelleTemplateList(includeArchiviert: $includeArchiviert) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useKontierungstabelleTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<KontierungstabelleTemplateListQuery, KontierungstabelleTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontierungstabelleTemplateListQuery, KontierungstabelleTemplateListQueryVariables>(
    KontierungstabelleTemplateListDocument,
    options
  );
}
export function useKontierungstabelleTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KontierungstabelleTemplateListQuery, KontierungstabelleTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontierungstabelleTemplateListQuery, KontierungstabelleTemplateListQueryVariables>(
    KontierungstabelleTemplateListDocument,
    options
  );
}
export function useKontierungstabelleTemplateListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontierungstabelleTemplateListQuery, KontierungstabelleTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontierungstabelleTemplateListQuery, KontierungstabelleTemplateListQueryVariables>(
    KontierungstabelleTemplateListDocument,
    options
  );
}
export type KontierungstabelleTemplateListQueryHookResult = ReturnType<typeof useKontierungstabelleTemplateListQuery>;
export type KontierungstabelleTemplateListLazyQueryHookResult = ReturnType<typeof useKontierungstabelleTemplateListLazyQuery>;
export type KontierungstabelleTemplateListSuspenseQueryHookResult = ReturnType<typeof useKontierungstabelleTemplateListSuspenseQuery>;
export type KontierungstabelleTemplateListQueryResult = Apollo.QueryResult<
  KontierungstabelleTemplateListQuery,
  KontierungstabelleTemplateListQueryVariables
>;
export const KontierungstabelleTemplateDocument = gql`
  query KontierungstabelleTemplate($kontierungstabelleId: ID!) {
    getKontierungstabelleTemplate(kontierungstabelleId: $kontierungstabelleId) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useKontierungstabelleTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<KontierungstabelleTemplateQuery, KontierungstabelleTemplateQueryVariables> &
    ({ variables: KontierungstabelleTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontierungstabelleTemplateQuery, KontierungstabelleTemplateQueryVariables>(KontierungstabelleTemplateDocument, options);
}
export function useKontierungstabelleTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KontierungstabelleTemplateQuery, KontierungstabelleTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontierungstabelleTemplateQuery, KontierungstabelleTemplateQueryVariables>(KontierungstabelleTemplateDocument, options);
}
export function useKontierungstabelleTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontierungstabelleTemplateQuery, KontierungstabelleTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontierungstabelleTemplateQuery, KontierungstabelleTemplateQueryVariables>(
    KontierungstabelleTemplateDocument,
    options
  );
}
export type KontierungstabelleTemplateQueryHookResult = ReturnType<typeof useKontierungstabelleTemplateQuery>;
export type KontierungstabelleTemplateLazyQueryHookResult = ReturnType<typeof useKontierungstabelleTemplateLazyQuery>;
export type KontierungstabelleTemplateSuspenseQueryHookResult = ReturnType<typeof useKontierungstabelleTemplateSuspenseQuery>;
export type KontierungstabelleTemplateQueryResult = Apollo.QueryResult<KontierungstabelleTemplateQuery, KontierungstabelleTemplateQueryVariables>;
export const KontierungstabelleTemplateChangeHistoryListDocument = gql`
  query KontierungstabelleTemplateChangeHistoryList($kontierungstabelleId: ID!) {
    getKontierungstabelleTemplateChangeHistoryList(kontierungstabelleId: $kontierungstabelleId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useKontierungstabelleTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<KontierungstabelleTemplateChangeHistoryListQuery, KontierungstabelleTemplateChangeHistoryListQueryVariables> &
    ({ variables: KontierungstabelleTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontierungstabelleTemplateChangeHistoryListQuery, KontierungstabelleTemplateChangeHistoryListQueryVariables>(
    KontierungstabelleTemplateChangeHistoryListDocument,
    options
  );
}
export function useKontierungstabelleTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KontierungstabelleTemplateChangeHistoryListQuery,
    KontierungstabelleTemplateChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontierungstabelleTemplateChangeHistoryListQuery, KontierungstabelleTemplateChangeHistoryListQueryVariables>(
    KontierungstabelleTemplateChangeHistoryListDocument,
    options
  );
}
export function useKontierungstabelleTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<KontierungstabelleTemplateChangeHistoryListQuery, KontierungstabelleTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontierungstabelleTemplateChangeHistoryListQuery, KontierungstabelleTemplateChangeHistoryListQueryVariables>(
    KontierungstabelleTemplateChangeHistoryListDocument,
    options
  );
}
export type KontierungstabelleTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useKontierungstabelleTemplateChangeHistoryListQuery>;
export type KontierungstabelleTemplateChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useKontierungstabelleTemplateChangeHistoryListLazyQuery
>;
export type KontierungstabelleTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useKontierungstabelleTemplateChangeHistoryListSuspenseQuery
>;
export type KontierungstabelleTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  KontierungstabelleTemplateChangeHistoryListQuery,
  KontierungstabelleTemplateChangeHistoryListQueryVariables
>;
