import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungBezeichnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  abrechnungType: Types.AbrechnungType;
}>;

export type AbrechnungBezeichnungQuery = {
  getAbrechnungBezeichnung: {
    data?: string | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungListQueryVariables = Types.Exact<{
  abrechnungszeitraumFromInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  abrechnungszeitraumToInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bezeichnung?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellDatumFromInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellDatumToInclusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  objektIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.AbrechnungOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  statusList?: Types.InputMaybe<Array<Types.AbrechnungStatus> | Types.AbrechnungStatus>;
  typeList?: Types.InputMaybe<Array<Types.AbrechnungType> | Types.AbrechnungType>;
}>;

export type AbrechnungListQuery = {
  getAbrechnungList: {
    data: {
      contentList: Array<{
        abrechnungId: string;
        bezeichnung: string;
        fromInclusive: string;
        countAuftragCreated?: number | null;
        countAuftragToCreateTotal?: number | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        generierlaufId: string;
        objektAbrechnungId?: string | null;
        objektKurzBezeichnung: string;
        objektId: string;
        toInclusive: string;
        auftragCreationStatus: { description?: string | null; value: Types.AuftragCreationStatus; text: string };
        status: { description?: string | null; value: Types.AbrechnungStatus; text: string };
        type: { value: Types.AbrechnungType; text: string };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { totalPages: number; totalElements: number; size: number; number: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AbrechnungTypeListQuery = {
  getAbrechnungTypeList: {
    data: Array<{ value: Types.AbrechnungType; text: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AbrechnungBezeichnungDocument = gql`
  query AbrechnungBezeichnung($objektId: ID!, $abrechnungType: AbrechnungType!) {
    getAbrechnungBezeichnung(objektId: $objektId, abrechnungType: $abrechnungType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAbrechnungBezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungBezeichnungQuery, AbrechnungBezeichnungQueryVariables> &
    ({ variables: AbrechnungBezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungBezeichnungQuery, AbrechnungBezeichnungQueryVariables>(AbrechnungBezeichnungDocument, options);
}
export function useAbrechnungBezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungBezeichnungQuery, AbrechnungBezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungBezeichnungQuery, AbrechnungBezeichnungQueryVariables>(AbrechnungBezeichnungDocument, options);
}
export function useAbrechnungBezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AbrechnungBezeichnungQuery, AbrechnungBezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungBezeichnungQuery, AbrechnungBezeichnungQueryVariables>(AbrechnungBezeichnungDocument, options);
}
export type AbrechnungBezeichnungQueryHookResult = ReturnType<typeof useAbrechnungBezeichnungQuery>;
export type AbrechnungBezeichnungLazyQueryHookResult = ReturnType<typeof useAbrechnungBezeichnungLazyQuery>;
export type AbrechnungBezeichnungSuspenseQueryHookResult = ReturnType<typeof useAbrechnungBezeichnungSuspenseQuery>;
export type AbrechnungBezeichnungQueryResult = Apollo.QueryResult<AbrechnungBezeichnungQuery, AbrechnungBezeichnungQueryVariables>;
export const AbrechnungListDocument = gql`
  query AbrechnungList(
    $abrechnungszeitraumFromInclusive: String
    $abrechnungszeitraumToInclusive: String
    $bezeichnung: String
    $erstellDatumFromInclusive: String
    $erstellDatumToInclusive: String
    $erstellerMitarbeiterIdList: [String!]
    $objektIdList: [String!]
    $order: AbrechnungOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
    $statusList: [AbrechnungStatus!]
    $typeList: [AbrechnungType!]
  ) {
    getAbrechnungList(
      abrechnungszeitraumFromInclusive: $abrechnungszeitraumFromInclusive
      abrechnungszeitraumToInclusive: $abrechnungszeitraumToInclusive
      bezeichnung: $bezeichnung
      erstellDatumFromInclusive: $erstellDatumFromInclusive
      erstellDatumToInclusive: $erstellDatumToInclusive
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      objektIdList: $objektIdList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
      statusList: $statusList
      typeList: $typeList
    ) {
      data {
        contentList {
          abrechnungId
          auftragCreationStatus {
            description
            value
            text
          }
          bezeichnung
          fromInclusive
          countAuftragCreated
          countAuftragToCreateTotal
          createTs
          createdBy
          createdByMitarbeiterId
          generierlaufId
          objektAbrechnungId
          objektKurzBezeichnung
          objektId
          status {
            description
            value
            text
          }
          type {
            value
            text
          }
          toInclusive
          warningList {
            attribute
            message
            type
          }
        }
        page {
          totalPages
          totalElements
          size
          number
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAbrechnungListQuery(baseOptions?: Apollo.QueryHookOptions<AbrechnungListQuery, AbrechnungListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungListQuery, AbrechnungListQueryVariables>(AbrechnungListDocument, options);
}
export function useAbrechnungListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungListQuery, AbrechnungListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungListQuery, AbrechnungListQueryVariables>(AbrechnungListDocument, options);
}
export function useAbrechnungListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AbrechnungListQuery, AbrechnungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungListQuery, AbrechnungListQueryVariables>(AbrechnungListDocument, options);
}
export type AbrechnungListQueryHookResult = ReturnType<typeof useAbrechnungListQuery>;
export type AbrechnungListLazyQueryHookResult = ReturnType<typeof useAbrechnungListLazyQuery>;
export type AbrechnungListSuspenseQueryHookResult = ReturnType<typeof useAbrechnungListSuspenseQuery>;
export type AbrechnungListQueryResult = Apollo.QueryResult<AbrechnungListQuery, AbrechnungListQueryVariables>;
export const AbrechnungTypeListDocument = gql`
  query AbrechnungTypeList {
    getAbrechnungTypeList {
      data {
        value
        text
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAbrechnungTypeListQuery(baseOptions?: Apollo.QueryHookOptions<AbrechnungTypeListQuery, AbrechnungTypeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungTypeListQuery, AbrechnungTypeListQueryVariables>(AbrechnungTypeListDocument, options);
}
export function useAbrechnungTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungTypeListQuery, AbrechnungTypeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungTypeListQuery, AbrechnungTypeListQueryVariables>(AbrechnungTypeListDocument, options);
}
export function useAbrechnungTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AbrechnungTypeListQuery, AbrechnungTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungTypeListQuery, AbrechnungTypeListQueryVariables>(AbrechnungTypeListDocument, options);
}
export type AbrechnungTypeListQueryHookResult = ReturnType<typeof useAbrechnungTypeListQuery>;
export type AbrechnungTypeListLazyQueryHookResult = ReturnType<typeof useAbrechnungTypeListLazyQuery>;
export type AbrechnungTypeListSuspenseQueryHookResult = ReturnType<typeof useAbrechnungTypeListSuspenseQuery>;
export type AbrechnungTypeListQueryResult = Apollo.QueryResult<AbrechnungTypeListQuery, AbrechnungTypeListQueryVariables>;
