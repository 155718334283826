import React, { FC } from 'react';
import { Empty } from 'antd';
import { Budgeting, BudgetingResultBudgetingKonto } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import budgetingResultBudgetingKontoTableColumns from './budgetingResultBudgetingKontoTableColumns';

type Props = {
  budgeting: Budgeting;
  budgetingResultBudgetingKontoList: BudgetingResultBudgetingKonto[];
};

const BudgetingResultBudgetingKontoTable: FC<Props> = ({ budgeting, budgetingResultBudgetingKontoList }) => (
  <NestedTable<BudgetingResultBudgetingKonto>
    colWidth={100}
    level={5}
    locale={{
      emptyText: <Empty description={<span>Keine Vertragswerte vorhanden</span>} />,
    }}
    dataSource={budgetingResultBudgetingKontoList}
    columns={budgetingResultBudgetingKontoTableColumns(budgeting)}
    rowKey={(record) => record.budgetingKonto.budgetingKontoId}
  />
);
export default BudgetingResultBudgetingKontoTable;
