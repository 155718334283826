import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Empty, Table } from 'antd';
import { IndexSeries } from '../../../../types';
import indexSeriesTableColumns from './indexSeriesTableColumns';
import { IndexSeriesListingFiltersFormValues } from '../../shared/Filters/listingFiltersFormMapper';

type Props = {
  indexSeriesList: IndexSeries[];
  formikProps: FormikProps<IndexSeriesListingFiltersFormValues>;
  onAction: () => void;
};

const IndexSeriesTable: FC<Props> = ({ indexSeriesList, onAction, formikProps }) => {
  const filterForType = (indexSeries: IndexSeries) => (formikProps.values.typ ? indexSeries.indexType.value === formikProps.values.typ : true);

  const filteredIndexSeriesList = indexSeriesList.filter(filterForType);

  return (
    <Table<IndexSeries>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Indexreihen vorhanden</span>} />,
      }}
      dataSource={filteredIndexSeriesList}
      columns={indexSeriesTableColumns(onAction)}
      pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
      rowKey={(record) => record.indexSeriesId}
    />
  );
};

export default IndexSeriesTable;
