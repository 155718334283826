import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Input } from 'formik-antd';
import { FormFields } from '../../../../../../helpers/formikHelper';
import { PersonCreateFormValues } from '../Create/personCreateFormMapper';
import RechtstraegerSelect from '../../../../../../shared/Rechtstraeger/RechtstraegerSelect';
import { personUpdateFormFields, PersonUpdateFormValues } from '../Update/personUpdateFormMapper';
import DatenTabColumnsLayout from '../../shared/DatenTabColumnsLayout';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formFields: FormFields<PersonCreateFormValues> | FormFields<PersonUpdateFormValues>;
  formikProps: FormikProps<PersonCreateFormValues> | FormikProps<PersonUpdateFormValues>;
};

const PersonenDatenTab: FC<Props> = ({ formFields, formikProps }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ObjektInfoFeldPerson'>('ObjektInfoFeldPerson');

  return (
    <DatenTabColumnsLayout
      leftColumn={
        <FormItemWithFieldHelp name={formFields.rolle} label="Rolle" fieldHelp={getFieldHelpText('ObjektInfoFeldPerson.rolle')}>
          <Input id={formFields.rolle} name={formFields.rolle} size="small" />
        </FormItemWithFieldHelp>
      }
      rightColumn={
        <FormItemWithFieldHelp name={formFields.rechtstraegerId} label="Person" fieldHelp={getFieldHelpText('ObjektInfoFeldPerson.rechtstraegerId')}>
          <RechtstraegerSelect
            name={formFields.rechtstraegerId}
            onNewItemCreated={(rechtstraegerId) => formikProps.setFieldValue(personUpdateFormFields.rechtstraegerId, rechtstraegerId)}
            onNewItemCreateSelected={() => formikProps.setFieldValue(personUpdateFormFields.rechtstraegerId, '')}
            onClear={() => {
              formikProps.setFieldTouched(formFields.rechtstraegerId, true);
            }}
          />
        </FormItemWithFieldHelp>
      }
    />
  );
};

export default PersonenDatenTab;
