import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { useCamtGenerierlaufEntryListQuery } from '../../../gql/CamtGenerierlauf/CamtGenerierlaufQueries.types';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { TCamtVerarbeitungQueryParams } from '../Filters/filtersQueryParams';

export const useQueryWithPaginationAndSortingForCamtGenLaufEntryList = (generierlaufId: string, queryParams: TCamtVerarbeitungQueryParams) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useCamtGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getCamtGenerierlaufEntryList.data.contentList || [];

  return { entryList, loading, pagination, handleTableSorting };
};
