import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../../styles/pdfStyles';
import { DebitorListHeader } from '../../op-list-debitor-types';
import { opDebitorTableListColumnWidthAndAlignment } from './op-debitor-table-styles';

type Props = {
  debitorenListHeaderText: DebitorListHeader;
};

const OPDebitorTableHeader: FC<Props> = ({ debitorenListHeaderText }) => (
  <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { marginTop: '2mm' }]}>
    <Text style={[pdfStyles.column, pdfStyles.textBulletin, opDebitorTableListColumnWidthAndAlignment.belegnummer]}>
      {debitorenListHeaderText.belegnummerText}
    </Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletin, opDebitorTableListColumnWidthAndAlignment.bezeichnung]}>
      {debitorenListHeaderText.bezeichnungText}
    </Text>
    <Text style={[pdfStyles.column, opDebitorTableListColumnWidthAndAlignment.buchungstext]} />
    <Text style={[pdfStyles.column, pdfStyles.textBulletin, opDebitorTableListColumnWidthAndAlignment.buchungsdatum]}>
      {debitorenListHeaderText.buchungsdatumText}
    </Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletin, opDebitorTableListColumnWidthAndAlignment.dueDate]}>
      {debitorenListHeaderText.dueDateText}
    </Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletin, opDebitorTableListColumnWidthAndAlignment.brutto]}>
      {debitorenListHeaderText.sumBruttoText}
    </Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletin, opDebitorTableListColumnWidthAndAlignment.zahlungsbetrag]}>
      {debitorenListHeaderText.zahlungsbetragText}
    </Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletin, opDebitorTableListColumnWidthAndAlignment.offenerBetrag]}>
      {debitorenListHeaderText.offenerBetragText}
    </Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletin, opDebitorTableListColumnWidthAndAlignment.mahnstufe]}>
      {debitorenListHeaderText.mahnstufeText}
    </Text>
  </View>
);

export default OPDebitorTableHeader;
