import React, { FC } from 'react';
import { BankOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, MenuProps } from 'antd';
import { useToggle } from '../../../hooks/useToggle';
import ObjektBankDetailsForm from './Form/ObjektBankDetailsForm';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { ObjektBankDetailsVerwendung } from '../../../types';

type Props = {
  objektId: string;
  rechtstraegerId: string;
  onSuccess: () => void;
  verwendungListTypeInUse: ObjektBankDetailsVerwendung[];
  rechtstraegerFibuKontoCreatable?: boolean;
  weRechnungsAusstellerId?: string;
  weRechnungsAusstellerFibuKontoCreatable?: boolean;
};

const ObjektBankDetailsActions: FC<Props> = ({
  objektId,
  rechtstraegerId,
  onSuccess,
  verwendungListTypeInUse,
  weRechnungsAusstellerId,
  rechtstraegerFibuKontoCreatable,
  weRechnungsAusstellerFibuKontoCreatable,
}) => {
  const { activeForFirmendatenId } = useFDAuthorized();
  const [isCollapsed, onCollapse] = useToggle();

  const onCreateSuccess = () => {
    onCollapse();
    onSuccess();
  };

  const items: MenuProps['items'] = [{ key: '1', label: 'Bankverbindung-Zuordnung anlegen', onClick: onCollapse, icon: <BankOutlined /> }];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
      <Drawer title="Bankverbindung-Zuordnung anlegen" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <ObjektBankDetailsForm
          onSuccess={onCreateSuccess}
          onCancel={onCollapse}
          objektId={objektId}
          rechtstraegerId={rechtstraegerId}
          weRechnungsAusstellerId={weRechnungsAusstellerId}
          firmendatenId={activeForFirmendatenId}
          verwendungListTypeInUse={verwendungListTypeInUse}
          rechtstraegerFibuKontoCreatable={rechtstraegerFibuKontoCreatable}
          weRechnungsAusstellerFibuKontoCreatable={weRechnungsAusstellerFibuKontoCreatable}
        />
      </Drawer>
    </>
  );
};

export default ObjektBankDetailsActions;
