import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { FormikProps } from 'formik';
import TabPaneContent from '../../../../components/Tabs/TabPaneContent';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import { AuftragsartFieldsFragment } from '../../../Auftragsart/gql/AuftragsartFragments.types';
import { AuftragsartUpdateParamFormValues } from '../../../Auftragsart/Form/Update/ParameterForm/auftragsartUpdateParamFormMapper';
import AuftragsartTemplateUpdateParamForm from './ParameterForm/AuftragsartTemplateUpdateParamForm';
import AuftragsartTemplateUpdateTemplateForm from './Template/AuftragsartTemplateUpdateTemplateForm';

type Props = {
  auftragsart: AuftragsartFieldsFragment;
  auftragsartTemplateValues: TextbausteinFormValues[];
  setAuftragsartTemplateValues: React.Dispatch<React.SetStateAction<TextbausteinFormValues[]>>;
  formikProps: FormikProps<AuftragsartUpdateParamFormValues>;
  onSubmit: (values: AuftragsartUpdateParamFormValues) => void;
};

const AuftragsartTemplateUpdateFormContent: FC<Props> = ({
  auftragsart,
  formikProps,
  auftragsartTemplateValues,
  setAuftragsartTemplateValues,
  onSubmit,
}) => {
  const runOnSubmit = () => {
    onSubmit(formikProps.values);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Parameter',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <AuftragsartTemplateUpdateParamForm
            auftragsart={auftragsart}
            formikProps={formikProps}
            onSubmit={runOnSubmit}
            closePath={URI_ANDROMEDA_SYS_SETTINGS.auftragsartPage}
          />
        </TabPaneContent>
      ),
    },
    {
      key: '2',
      label: auftragsart.type.text,
      children: (
        <AuftragsartTemplateUpdateTemplateForm
          auftragsart={auftragsart}
          auftragsartTemplateValues={auftragsartTemplateValues}
          setAuftragsartTemplateValues={setAuftragsartTemplateValues}
          onSubmit={runOnSubmit}
          closePath={URI_ANDROMEDA_SYS_SETTINGS.auftragsartPage}
        />
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default AuftragsartTemplateUpdateFormContent;
