import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { FibuBuchungszeileFieldsFragmentDoc } from './FibuBuchungszeileFragments.types';
import { FibuKontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import { FibuObjektFragmentDoc } from '../../Objekt/gql/ObjektFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FibuBuchungszeileListQueryVariables = Types.Exact<{
  buchungskreisId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  buchungId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  betragFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  betragTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  steuersatzFrom?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  steuersatzTo?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  ustVomAufwandSteuersatzFrom?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  ustVomAufwandSteuersatzTo?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  steuerFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  steuerTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  buchungsdatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  heVertragId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsTypeList?: Types.InputMaybe<Array<Types.FibuBuchungType> | Types.FibuBuchungType>;
  sollHaben?: Types.InputMaybe<Types.SollHaben>;
  belegSymbol?: Types.InputMaybe<Types.FibuBelegSymbol>;
  belegSymbolList?: Types.InputMaybe<Array<Types.FibuBelegSymbol> | Types.FibuBelegSymbol>;
  waehrung?: Types.InputMaybe<Types.Waehrung>;
  buchungsKZ?: Types.InputMaybe<Types.Scalars['String']['input']>;
  text?: Types.InputMaybe<Types.Scalars['String']['input']>;
  source?: Types.InputMaybe<Types.BuchungSource>;
  belegdatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegdatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bestandseinheitId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bestandseinheitIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  gegenKontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  offenerOPBetrag?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeStorno?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeUmbuchung?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  stornierteBuchungId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  storno?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  umbuchung?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  fachlichePruefung?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.BuchungszeileOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type FibuBuchungszeileListQuery = {
  getFibuBuchungszeileList: {
    data: {
      contentList: Array<{
        ausgezifferterBetrag?: number | null;
        betrag: number;
        buchungId: string;
        buchungsKZ?: string | null;
        buchungsanweisungId: string;
        buchungskreisBezeichnung: string;
        buchungskreisId: string;
        buchungszeileId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        fachlichePruefung?: boolean | null;
        heVertragId?: string | null;
        offenePostenId?: string | null;
        offenerBetrag?: string | null;
        offenerOpBetrag?: number | null;
        steuer?: number | null;
        steuersatz?: number | null;
        storniert: boolean;
        stornierteBuchungBezeichnung?: string | null;
        stornierteBuchungId?: string | null;
        text: string;
        umbuchung?: boolean | null;
        umgebucht?: boolean | null;
        updatedByMitarbeiterId?: string | null;
        ustVomAufwandSteuersatz?: number | null;
        vertragId?: string | null;
        vertragKurzbezeichnung?: string | null;
        heVertragKurzbezeichnung?: string | null;
        bestandseinheit?: { bestandseinheitId: string; bezeichnung?: string | null } | null;
        buchungsKopf: {
          auftragId?: string | null;
          belegDatum?: string | null;
          belegFileId?: string | null;
          belegId?: string | null;
          belegnummer?: string | null;
          buchungsdatum: string;
          buchungskreisRechtstraegerId: string;
          dataCarrierBelegId?: string | null;
          dueDate?: string | null;
          iban?: string | null;
          standingInvoicePartialAmountId?: string | null;
          statementNumber?: string | null;
          vertragspartnerId?: string | null;
          belegSymbol: { abbreviation: string; text: string; value: Types.FibuBelegSymbol };
          buchungsType: { text: string; value: Types.FibuBuchungType };
          waehrung: { text: string; value: Types.Waehrung };
        };
        gegenKonto?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          haupt: boolean;
          kontoId: string;
          kontoTemplateId?: string | null;
          nummer: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          klasse: { text: string; value: Types.KontoKlasse };
          status: { description?: string | null; text: string; value: Types.KontoStatus };
          type: { text: string; value: Types.KontoType };
          verwendung?: { text: string; value: Types.KontoVerwendung } | null;
        } | null;
        konto: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          haupt: boolean;
          kontoId: string;
          kontoTemplateId?: string | null;
          nummer: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          klasse: { text: string; value: Types.KontoKlasse };
          status: { description?: string | null; text: string; value: Types.KontoStatus };
          type: { text: string; value: Types.KontoType };
          verwendung?: { text: string; value: Types.KontoVerwendung } | null;
        };
        objekt?: { kurzBezeichnung: string; objektId: string } | null;
        sollHaben: { text: string; value: Types.SollHaben };
        source: { text: string; value: Types.BuchungSource };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FibuBuchungszeileListSummaryQueryVariables = Types.Exact<{
  buchungskreisId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  buchungId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  betragFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  betragTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  steuersatzFrom?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  steuersatzTo?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  ustVomAufwandSteuersatzFrom?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  ustVomAufwandSteuersatzTo?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  steuerFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  steuerTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  buchungsdatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  heVertragId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsTypeList?: Types.InputMaybe<Array<Types.FibuBuchungType> | Types.FibuBuchungType>;
  sollHaben?: Types.InputMaybe<Types.SollHaben>;
  belegSymbol?: Types.InputMaybe<Types.FibuBelegSymbol>;
  belegSymbolList?: Types.InputMaybe<Array<Types.FibuBelegSymbol> | Types.FibuBelegSymbol>;
  waehrung?: Types.InputMaybe<Types.Waehrung>;
  buchungsKZ?: Types.InputMaybe<Types.Scalars['String']['input']>;
  text?: Types.InputMaybe<Types.Scalars['String']['input']>;
  source?: Types.InputMaybe<Types.BuchungSource>;
  belegdatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegdatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bestandseinheitId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bestandseinheitIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  gegenKontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  offenerOPBetrag?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeStorno?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeUmbuchung?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  stornierteBuchungId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  storno?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  umbuchung?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  fachlichePruefung?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FibuBuchungszeileListSummaryQuery = {
  getFibuBuchungszeileListSummary: {
    data: { sumBetrag: number; sumSteuer: number };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBzBestandseinheitListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;

export type FilterFibuBzBestandseinheitListQuery = {
  getFilterFibuBzBestandseinheitList: {
    data: Array<{ bestandseinheitId: string; bezeichnung: string; objektKurzBezeichnung?: string | null }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBzBuchungKzListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;

export type FilterFibuBzBuchungKzListQuery = {
  getFilterFibuBzBuchungKZList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBzGegenKontoListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;

export type FilterFibuBzGegenKontoListQuery = {
  getFilterFibuBzGegenKontoList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      haupt: boolean;
      kontoId: string;
      kontoTemplateId?: string | null;
      nummer: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      klasse: { text: string; value: Types.KontoKlasse };
      status: { description?: string | null; text: string; value: Types.KontoStatus };
      type: { text: string; value: Types.KontoType };
      verwendung?: { text: string; value: Types.KontoVerwendung } | null;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBzKontoListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;

export type FilterFibuBzKontoListQuery = {
  getFilterFibuBzKontoList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      haupt: boolean;
      kontoId: string;
      kontoTemplateId?: string | null;
      nummer: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      klasse: { text: string; value: Types.KontoKlasse };
      status: { description?: string | null; text: string; value: Types.KontoStatus };
      type: { text: string; value: Types.KontoType };
      verwendung?: { text: string; value: Types.KontoVerwendung } | null;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBzObjektListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;

export type FilterFibuBzObjektListQuery = {
  getFilterFibuBzObjektList: {
    data: Array<{ kurzBezeichnung: string; objektId: string }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterFibuBzTexteListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  text?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterFibuBzTexteListQuery = {
  getFilterFibuBzTexteList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const FibuBuchungszeileListDocument = gql`
  query FibuBuchungszeileList(
    $buchungskreisId: String
    $rechtstraegerId: String
    $rechtstraegerIdList: [String!]
    $buchungId: String
    $belegId: String
    $betragFrom: Float
    $betragTo: Float
    $steuersatzFrom: Int
    $steuersatzTo: Int
    $ustVomAufwandSteuersatzFrom: Int
    $ustVomAufwandSteuersatzTo: Int
    $steuerFrom: Float
    $steuerTo: Float
    $buchungsdatumFrom: String
    $buchungsdatumTo: String
    $dueDateFrom: String
    $dueDateTo: String
    $kontoId: String
    $objektId: String
    $vertragId: String
    $heVertragId: String
    $buchungsTypeList: [FibuBuchungType!]
    $sollHaben: SollHaben
    $belegSymbol: FibuBelegSymbol
    $belegSymbolList: [FibuBelegSymbol!]
    $waehrung: Waehrung
    $buchungsKZ: String
    $text: String
    $source: BuchungSource
    $belegdatumFrom: String
    $belegdatumTo: String
    $bestandseinheitId: String
    $bestandseinheitIdList: [String!]
    $gegenKontoId: String
    $belegnummer: String
    $vertragspartnerId: String
    $erstellerMitarbeiterIdList: [String!]
    $offenerOPBetrag: Boolean
    $includeStorno: Boolean
    $includeUmbuchung: Boolean
    $stornierteBuchungId: String
    $storno: Boolean
    $umbuchung: Boolean
    $fachlichePruefung: Boolean
    $createTsFrom: String
    $createTsTo: String
    $order: BuchungszeileOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getFibuBuchungszeileList(
      buchungskreisId: $buchungskreisId
      rechtstraegerId: $rechtstraegerId
      rechtstraegerIdList: $rechtstraegerIdList
      buchungId: $buchungId
      belegId: $belegId
      betragFrom: $betragFrom
      betragTo: $betragTo
      steuersatzFrom: $steuersatzFrom
      steuersatzTo: $steuersatzTo
      ustVomAufwandSteuersatzFrom: $ustVomAufwandSteuersatzFrom
      ustVomAufwandSteuersatzTo: $ustVomAufwandSteuersatzTo
      steuerFrom: $steuerFrom
      steuerTo: $steuerTo
      buchungsdatumFrom: $buchungsdatumFrom
      buchungsdatumTo: $buchungsdatumTo
      dueDateFrom: $dueDateFrom
      dueDateTo: $dueDateTo
      kontoId: $kontoId
      objektId: $objektId
      vertragId: $vertragId
      heVertragId: $heVertragId
      buchungsTypeList: $buchungsTypeList
      sollHaben: $sollHaben
      belegSymbol: $belegSymbol
      belegSymbolList: $belegSymbolList
      waehrung: $waehrung
      buchungsKZ: $buchungsKZ
      text: $text
      source: $source
      belegdatumFrom: $belegdatumFrom
      belegdatumTo: $belegdatumTo
      bestandseinheitId: $bestandseinheitId
      bestandseinheitIdList: $bestandseinheitIdList
      gegenKontoId: $gegenKontoId
      belegnummer: $belegnummer
      vertragspartnerId: $vertragspartnerId
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      offenerOPBetrag: $offenerOPBetrag
      includeStorno: $includeStorno
      includeUmbuchung: $includeUmbuchung
      stornierteBuchungId: $stornierteBuchungId
      storno: $storno
      umbuchung: $umbuchung
      fachlichePruefung: $fachlichePruefung
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...FibuBuchungszeileFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${FibuBuchungszeileFieldsFragmentDoc}
`;
export function useFibuBuchungszeileListQuery(
  baseOptions?: Apollo.QueryHookOptions<FibuBuchungszeileListQuery, FibuBuchungszeileListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FibuBuchungszeileListQuery, FibuBuchungszeileListQueryVariables>(FibuBuchungszeileListDocument, options);
}
export function useFibuBuchungszeileListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FibuBuchungszeileListQuery, FibuBuchungszeileListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FibuBuchungszeileListQuery, FibuBuchungszeileListQueryVariables>(FibuBuchungszeileListDocument, options);
}
export function useFibuBuchungszeileListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FibuBuchungszeileListQuery, FibuBuchungszeileListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FibuBuchungszeileListQuery, FibuBuchungszeileListQueryVariables>(FibuBuchungszeileListDocument, options);
}
export type FibuBuchungszeileListQueryHookResult = ReturnType<typeof useFibuBuchungszeileListQuery>;
export type FibuBuchungszeileListLazyQueryHookResult = ReturnType<typeof useFibuBuchungszeileListLazyQuery>;
export type FibuBuchungszeileListSuspenseQueryHookResult = ReturnType<typeof useFibuBuchungszeileListSuspenseQuery>;
export type FibuBuchungszeileListQueryResult = Apollo.QueryResult<FibuBuchungszeileListQuery, FibuBuchungszeileListQueryVariables>;
export const FibuBuchungszeileListSummaryDocument = gql`
  query FibuBuchungszeileListSummary(
    $buchungskreisId: String
    $rechtstraegerId: String
    $rechtstraegerIdList: [String!]
    $buchungId: String
    $belegId: String
    $betragFrom: Float
    $betragTo: Float
    $steuersatzFrom: Int
    $steuersatzTo: Int
    $ustVomAufwandSteuersatzFrom: Int
    $ustVomAufwandSteuersatzTo: Int
    $steuerFrom: Float
    $steuerTo: Float
    $buchungsdatumFrom: String
    $buchungsdatumTo: String
    $dueDateFrom: String
    $dueDateTo: String
    $kontoId: String
    $objektId: String
    $vertragId: String
    $heVertragId: String
    $buchungsTypeList: [FibuBuchungType!]
    $sollHaben: SollHaben
    $belegSymbol: FibuBelegSymbol
    $belegSymbolList: [FibuBelegSymbol!]
    $waehrung: Waehrung
    $buchungsKZ: String
    $text: String
    $source: BuchungSource
    $belegdatumFrom: String
    $belegdatumTo: String
    $bestandseinheitId: String
    $bestandseinheitIdList: [String!]
    $gegenKontoId: String
    $belegnummer: String
    $vertragspartnerId: String
    $erstellerMitarbeiterIdList: [String!]
    $offenerOPBetrag: Boolean
    $includeStorno: Boolean
    $includeUmbuchung: Boolean
    $stornierteBuchungId: String
    $storno: Boolean
    $umbuchung: Boolean
    $fachlichePruefung: Boolean
    $createTsFrom: String
    $createTsTo: String
  ) {
    getFibuBuchungszeileListSummary(
      buchungskreisId: $buchungskreisId
      rechtstraegerId: $rechtstraegerId
      rechtstraegerIdList: $rechtstraegerIdList
      buchungId: $buchungId
      belegId: $belegId
      betragFrom: $betragFrom
      betragTo: $betragTo
      steuersatzFrom: $steuersatzFrom
      steuersatzTo: $steuersatzTo
      ustVomAufwandSteuersatzFrom: $ustVomAufwandSteuersatzFrom
      ustVomAufwandSteuersatzTo: $ustVomAufwandSteuersatzTo
      steuerFrom: $steuerFrom
      steuerTo: $steuerTo
      buchungsdatumFrom: $buchungsdatumFrom
      buchungsdatumTo: $buchungsdatumTo
      dueDateFrom: $dueDateFrom
      dueDateTo: $dueDateTo
      kontoId: $kontoId
      objektId: $objektId
      vertragId: $vertragId
      heVertragId: $heVertragId
      buchungsTypeList: $buchungsTypeList
      sollHaben: $sollHaben
      belegSymbol: $belegSymbol
      belegSymbolList: $belegSymbolList
      waehrung: $waehrung
      buchungsKZ: $buchungsKZ
      text: $text
      source: $source
      belegdatumFrom: $belegdatumFrom
      belegdatumTo: $belegdatumTo
      bestandseinheitId: $bestandseinheitId
      bestandseinheitIdList: $bestandseinheitIdList
      gegenKontoId: $gegenKontoId
      belegnummer: $belegnummer
      vertragspartnerId: $vertragspartnerId
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      offenerOPBetrag: $offenerOPBetrag
      includeStorno: $includeStorno
      includeUmbuchung: $includeUmbuchung
      stornierteBuchungId: $stornierteBuchungId
      storno: $storno
      umbuchung: $umbuchung
      fachlichePruefung: $fachlichePruefung
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
    ) {
      data {
        sumBetrag
        sumSteuer
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFibuBuchungszeileListSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<FibuBuchungszeileListSummaryQuery, FibuBuchungszeileListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FibuBuchungszeileListSummaryQuery, FibuBuchungszeileListSummaryQueryVariables>(
    FibuBuchungszeileListSummaryDocument,
    options
  );
}
export function useFibuBuchungszeileListSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FibuBuchungszeileListSummaryQuery, FibuBuchungszeileListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FibuBuchungszeileListSummaryQuery, FibuBuchungszeileListSummaryQueryVariables>(
    FibuBuchungszeileListSummaryDocument,
    options
  );
}
export function useFibuBuchungszeileListSummarySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FibuBuchungszeileListSummaryQuery, FibuBuchungszeileListSummaryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FibuBuchungszeileListSummaryQuery, FibuBuchungszeileListSummaryQueryVariables>(
    FibuBuchungszeileListSummaryDocument,
    options
  );
}
export type FibuBuchungszeileListSummaryQueryHookResult = ReturnType<typeof useFibuBuchungszeileListSummaryQuery>;
export type FibuBuchungszeileListSummaryLazyQueryHookResult = ReturnType<typeof useFibuBuchungszeileListSummaryLazyQuery>;
export type FibuBuchungszeileListSummarySuspenseQueryHookResult = ReturnType<typeof useFibuBuchungszeileListSummarySuspenseQuery>;
export type FibuBuchungszeileListSummaryQueryResult = Apollo.QueryResult<
  FibuBuchungszeileListSummaryQuery,
  FibuBuchungszeileListSummaryQueryVariables
>;
export const FilterFibuBzBestandseinheitListDocument = gql`
  query FilterFibuBzBestandseinheitList($rechtstraegerId: String, $rechtstraegerIdList: [String!]) {
    getFilterFibuBzBestandseinheitList(rechtstraegerId: $rechtstraegerId, rechtstraegerIdList: $rechtstraegerIdList) {
      data {
        bestandseinheitId
        bezeichnung
        objektKurzBezeichnung
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterFibuBzBestandseinheitListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterFibuBzBestandseinheitListQuery, FilterFibuBzBestandseinheitListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterFibuBzBestandseinheitListQuery, FilterFibuBzBestandseinheitListQueryVariables>(
    FilterFibuBzBestandseinheitListDocument,
    options
  );
}
export function useFilterFibuBzBestandseinheitListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterFibuBzBestandseinheitListQuery, FilterFibuBzBestandseinheitListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterFibuBzBestandseinheitListQuery, FilterFibuBzBestandseinheitListQueryVariables>(
    FilterFibuBzBestandseinheitListDocument,
    options
  );
}
export function useFilterFibuBzBestandseinheitListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterFibuBzBestandseinheitListQuery, FilterFibuBzBestandseinheitListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterFibuBzBestandseinheitListQuery, FilterFibuBzBestandseinheitListQueryVariables>(
    FilterFibuBzBestandseinheitListDocument,
    options
  );
}
export type FilterFibuBzBestandseinheitListQueryHookResult = ReturnType<typeof useFilterFibuBzBestandseinheitListQuery>;
export type FilterFibuBzBestandseinheitListLazyQueryHookResult = ReturnType<typeof useFilterFibuBzBestandseinheitListLazyQuery>;
export type FilterFibuBzBestandseinheitListSuspenseQueryHookResult = ReturnType<typeof useFilterFibuBzBestandseinheitListSuspenseQuery>;
export type FilterFibuBzBestandseinheitListQueryResult = Apollo.QueryResult<
  FilterFibuBzBestandseinheitListQuery,
  FilterFibuBzBestandseinheitListQueryVariables
>;
export const FilterFibuBzBuchungKzListDocument = gql`
  query FilterFibuBzBuchungKZList($rechtstraegerId: String, $rechtstraegerIdList: [String!]) {
    getFilterFibuBzBuchungKZList(rechtstraegerId: $rechtstraegerId, rechtstraegerIdList: $rechtstraegerIdList) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterFibuBzBuchungKzListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterFibuBzBuchungKzListQuery, FilterFibuBzBuchungKzListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterFibuBzBuchungKzListQuery, FilterFibuBzBuchungKzListQueryVariables>(FilterFibuBzBuchungKzListDocument, options);
}
export function useFilterFibuBzBuchungKzListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterFibuBzBuchungKzListQuery, FilterFibuBzBuchungKzListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterFibuBzBuchungKzListQuery, FilterFibuBzBuchungKzListQueryVariables>(FilterFibuBzBuchungKzListDocument, options);
}
export function useFilterFibuBzBuchungKzListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterFibuBzBuchungKzListQuery, FilterFibuBzBuchungKzListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterFibuBzBuchungKzListQuery, FilterFibuBzBuchungKzListQueryVariables>(FilterFibuBzBuchungKzListDocument, options);
}
export type FilterFibuBzBuchungKzListQueryHookResult = ReturnType<typeof useFilterFibuBzBuchungKzListQuery>;
export type FilterFibuBzBuchungKzListLazyQueryHookResult = ReturnType<typeof useFilterFibuBzBuchungKzListLazyQuery>;
export type FilterFibuBzBuchungKzListSuspenseQueryHookResult = ReturnType<typeof useFilterFibuBzBuchungKzListSuspenseQuery>;
export type FilterFibuBzBuchungKzListQueryResult = Apollo.QueryResult<FilterFibuBzBuchungKzListQuery, FilterFibuBzBuchungKzListQueryVariables>;
export const FilterFibuBzGegenKontoListDocument = gql`
  query FilterFibuBzGegenKontoList($rechtstraegerId: String, $rechtstraegerIdList: [String!]) {
    getFilterFibuBzGegenKontoList(rechtstraegerId: $rechtstraegerId, rechtstraegerIdList: $rechtstraegerIdList) {
      data {
        ...FibuKontoFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${FibuKontoFieldsFragmentDoc}
`;
export function useFilterFibuBzGegenKontoListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterFibuBzGegenKontoListQuery, FilterFibuBzGegenKontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterFibuBzGegenKontoListQuery, FilterFibuBzGegenKontoListQueryVariables>(FilterFibuBzGegenKontoListDocument, options);
}
export function useFilterFibuBzGegenKontoListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterFibuBzGegenKontoListQuery, FilterFibuBzGegenKontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterFibuBzGegenKontoListQuery, FilterFibuBzGegenKontoListQueryVariables>(FilterFibuBzGegenKontoListDocument, options);
}
export function useFilterFibuBzGegenKontoListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterFibuBzGegenKontoListQuery, FilterFibuBzGegenKontoListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterFibuBzGegenKontoListQuery, FilterFibuBzGegenKontoListQueryVariables>(
    FilterFibuBzGegenKontoListDocument,
    options
  );
}
export type FilterFibuBzGegenKontoListQueryHookResult = ReturnType<typeof useFilterFibuBzGegenKontoListQuery>;
export type FilterFibuBzGegenKontoListLazyQueryHookResult = ReturnType<typeof useFilterFibuBzGegenKontoListLazyQuery>;
export type FilterFibuBzGegenKontoListSuspenseQueryHookResult = ReturnType<typeof useFilterFibuBzGegenKontoListSuspenseQuery>;
export type FilterFibuBzGegenKontoListQueryResult = Apollo.QueryResult<FilterFibuBzGegenKontoListQuery, FilterFibuBzGegenKontoListQueryVariables>;
export const FilterFibuBzKontoListDocument = gql`
  query FilterFibuBzKontoList($rechtstraegerId: String, $rechtstraegerIdList: [String!]) {
    getFilterFibuBzKontoList(rechtstraegerId: $rechtstraegerId, rechtstraegerIdList: $rechtstraegerIdList) {
      data {
        ...FibuKontoFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${FibuKontoFieldsFragmentDoc}
`;
export function useFilterFibuBzKontoListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterFibuBzKontoListQuery, FilterFibuBzKontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterFibuBzKontoListQuery, FilterFibuBzKontoListQueryVariables>(FilterFibuBzKontoListDocument, options);
}
export function useFilterFibuBzKontoListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterFibuBzKontoListQuery, FilterFibuBzKontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterFibuBzKontoListQuery, FilterFibuBzKontoListQueryVariables>(FilterFibuBzKontoListDocument, options);
}
export function useFilterFibuBzKontoListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterFibuBzKontoListQuery, FilterFibuBzKontoListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterFibuBzKontoListQuery, FilterFibuBzKontoListQueryVariables>(FilterFibuBzKontoListDocument, options);
}
export type FilterFibuBzKontoListQueryHookResult = ReturnType<typeof useFilterFibuBzKontoListQuery>;
export type FilterFibuBzKontoListLazyQueryHookResult = ReturnType<typeof useFilterFibuBzKontoListLazyQuery>;
export type FilterFibuBzKontoListSuspenseQueryHookResult = ReturnType<typeof useFilterFibuBzKontoListSuspenseQuery>;
export type FilterFibuBzKontoListQueryResult = Apollo.QueryResult<FilterFibuBzKontoListQuery, FilterFibuBzKontoListQueryVariables>;
export const FilterFibuBzObjektListDocument = gql`
  query FilterFibuBzObjektList($rechtstraegerId: String, $rechtstraegerIdList: [String!]) {
    getFilterFibuBzObjektList(rechtstraegerId: $rechtstraegerId, rechtstraegerIdList: $rechtstraegerIdList) {
      data {
        ...FibuObjekt
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${FibuObjektFragmentDoc}
`;
export function useFilterFibuBzObjektListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterFibuBzObjektListQuery, FilterFibuBzObjektListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterFibuBzObjektListQuery, FilterFibuBzObjektListQueryVariables>(FilterFibuBzObjektListDocument, options);
}
export function useFilterFibuBzObjektListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterFibuBzObjektListQuery, FilterFibuBzObjektListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterFibuBzObjektListQuery, FilterFibuBzObjektListQueryVariables>(FilterFibuBzObjektListDocument, options);
}
export function useFilterFibuBzObjektListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterFibuBzObjektListQuery, FilterFibuBzObjektListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterFibuBzObjektListQuery, FilterFibuBzObjektListQueryVariables>(FilterFibuBzObjektListDocument, options);
}
export type FilterFibuBzObjektListQueryHookResult = ReturnType<typeof useFilterFibuBzObjektListQuery>;
export type FilterFibuBzObjektListLazyQueryHookResult = ReturnType<typeof useFilterFibuBzObjektListLazyQuery>;
export type FilterFibuBzObjektListSuspenseQueryHookResult = ReturnType<typeof useFilterFibuBzObjektListSuspenseQuery>;
export type FilterFibuBzObjektListQueryResult = Apollo.QueryResult<FilterFibuBzObjektListQuery, FilterFibuBzObjektListQueryVariables>;
export const FilterFibuBzTexteListDocument = gql`
  query FilterFibuBzTexteList($rechtstraegerId: String, $rechtstraegerIdList: [String!], $text: String) {
    getFilterFibuBzTexteList(rechtstraegerId: $rechtstraegerId, rechtstraegerIdList: $rechtstraegerIdList, text: $text) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterFibuBzTexteListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterFibuBzTexteListQuery, FilterFibuBzTexteListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterFibuBzTexteListQuery, FilterFibuBzTexteListQueryVariables>(FilterFibuBzTexteListDocument, options);
}
export function useFilterFibuBzTexteListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterFibuBzTexteListQuery, FilterFibuBzTexteListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterFibuBzTexteListQuery, FilterFibuBzTexteListQueryVariables>(FilterFibuBzTexteListDocument, options);
}
export function useFilterFibuBzTexteListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterFibuBzTexteListQuery, FilterFibuBzTexteListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterFibuBzTexteListQuery, FilterFibuBzTexteListQueryVariables>(FilterFibuBzTexteListDocument, options);
}
export type FilterFibuBzTexteListQueryHookResult = ReturnType<typeof useFilterFibuBzTexteListQuery>;
export type FilterFibuBzTexteListLazyQueryHookResult = ReturnType<typeof useFilterFibuBzTexteListLazyQuery>;
export type FilterFibuBzTexteListSuspenseQueryHookResult = ReturnType<typeof useFilterFibuBzTexteListSuspenseQuery>;
export type FilterFibuBzTexteListQueryResult = Apollo.QueryResult<FilterFibuBzTexteListQuery, FilterFibuBzTexteListQueryVariables>;
