import React, { FC } from 'react';
import { FormattedTime } from 'react-intl';
import { Space, Typography } from 'antd';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import MitarbeiterTooltip from '../../../../components/Card/MitarbeiterTooltip';
import TemplateIncompleteWarning from '../../shared/TemplateIncompleteWarning';
import { AbrechnungsdefinitionVersionListData } from '../../shared/abrDefTypes';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type AbrDefVersionTimelineCardTitleProps = {
  version: AbrechnungsdefinitionVersionListData;
  isHistoricized?: boolean;
};

const AbrDefVersionTimelineCardTitle: FC<AbrDefVersionTimelineCardTitleProps> = ({ version, isHistoricized }) => {
  return (
    <Space direction="vertical">
      {version.kommentar && (
        <DataWithShortenedTextAndExtraInfo maxTextLength={98} text={version.kommentar ?? ''}>
          {(shortenedText) => (
            <Typography.Text strong type={isHistoricized ? 'secondary' : undefined}>
              {shortenedText}
            </Typography.Text>
          )}
        </DataWithShortenedTextAndExtraInfo>
      )}
      <Space size={4}>
        <MitarbeiterTooltip showsInitials userId={version.createdBy} mitarbeiterId={version.createdByMitarbeiterId} alignment="right" />
        <Typography.Text style={{ fontWeight: 'normal' }} type={isHistoricized ? 'secondary' : undefined}>
          hat eine Änderung am <CustomFormattedDate value={version.lastUpdateTs} /> um <FormattedTime value={version.lastUpdateTs} /> Uhr gemacht{' '}
          <TemplateIncompleteWarning textBausteineBefuellt={version.textBausteineBefuellt} />
        </Typography.Text>
      </Space>
    </Space>
  );
};

export default AbrDefVersionTimelineCardTitle;
