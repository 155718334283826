import React, { FC, useState } from 'react';
import { List, Affix } from 'antd';
import { useTicketCommentListQuery } from '../../gql/TicketQueries.types';
import { TicketBase } from '../../../../types';
import { TicketCommentFieldsFragment } from '../../gql/TicketFragments.types';
import TicketCommentForm from './Form/TicketCommentForm';
import TicketComment from './TicketComment';
import { Spacer } from '../../../../components/Grid';
import { useOnTicketDetailsDataChangedEvents } from '../../useOnTicketDataChangedEvents';
import { isTicketArchived } from '../../ticketHelpers';

type Props = {
  ticket: TicketBase;
  onSuccess?: () => void;
  footer?: React.ReactNode;
};

const TicketCommentListing: FC<Props> = ({ ticket, onSuccess, footer }) => {
  const { loading, data, refetch } = useTicketCommentListQuery({
    variables: {
      ticketId: ticket.ticketId,
    },
  });
  const comments = data?.getTicketCommentList.data ?? [];
  const sortedComments = [...comments].sort((a, b) => b.createTs.localeCompare(a.createTs));

  const [editedComment, setEditedComment] = useState<TicketCommentFieldsFragment>();

  useOnTicketDetailsDataChangedEvents(`ticketCommentList-${ticket.ticketId}`, refetch, ticket.ticketId);

  const isArchived = isTicketArchived(ticket);

  return (
    <>
      <List
        loading={loading}
        dataSource={sortedComments}
        itemLayout="horizontal"
        renderItem={(comment) => (
          <TicketComment
            ticket={ticket}
            comment={comment}
            onDelete={() => {
              onSuccess?.();
              refetch();
            }}
            onUpdate={setEditedComment}
          />
        )}
        locale={{
          emptyText: 'Keine Kommentare vorhanden',
        }}
      />
      {!isArchived && (
        <>
          <Spacer height={8} />
          <Affix offsetBottom={0}>
            <div
              style={{
                backgroundColor: '#fafafa',
                padding: '12px 20px',
                borderTop: '1px solid #f0f0f0',
                borderBlock: '16px solid #ffffff',
              }}
            >
              <TicketCommentForm
                ticketId={ticket.ticketId}
                onSuccess={() => {
                  setEditedComment(undefined);
                  onSuccess?.();
                  refetch();
                }}
                onCancel={() => setEditedComment(undefined)}
                comment={editedComment}
              />
            </div>
            {footer}
          </Affix>
        </>
      )}
    </>
  );
};

export default TicketCommentListing;
