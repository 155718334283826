import React from 'react';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { Flex } from 'rebass';
import { EuroAmount } from '../../../../../components/Number';
import { generatePathToBestandseinheitDetailsPage } from '../../../../Bestandseinheit/bestandseinheitUriPaths';
import StatusBadge from '../../../../../components/Status/StatusBadge';
import { generatePathToWeAbrechnungDetailsTopabrechnungDetailsPage } from '../../weAbrechnungUriPaths';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import { compareTwoDatesForSorting } from '../../../../../helpers/dateHelper';
import { compareTwoNumbersForSorting } from '../../../../../helpers/numberHelper';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import { isAbrechnungTypeBkAbrechnung } from '../../../shared/abrechnungHelpers';
import { generatePathToBkAbrechnungDetailsTopabrechnungDetailsPage } from '../../bkAbrechnungUriPaths';
import { AbrechnungType, TopAbrechnung } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import RechtstraegerWithContactsTooltip from '../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const topAbrechnungTableColumns = (
  objektId: string,
  objektAbrechnungId: string,
  abrechnungType: AbrechnungType
): TableWithColSelectorColumnProps<TopAbrechnung>[] => [
  {
    title: 'Top',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    render: (text, record) => (
      <Link to={generatePathToBestandseinheitDetailsPage(objektId, record.bestandseinheitId)} target="_blank">
        {record.bezeichnung}
      </Link>
    ),
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.vertragspartner.kurzBezeichnung, b.vertragspartner.kurzBezeichnung),
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.vertragspartner.rechtstraegerId}
        rechtstraegerName={record.vertragspartner.kurzBezeichnung}
        status={record.vertragspartner.status}
        maxTextLength={40}
      />
    ),
  },
  {
    title: 'Zeitraum',
    defaultSelected: true,
    sorter: (a, b) => compareTwoDatesForSorting(a.fromInclusive, b.toInclusive),
    render: (text, record) => (
      <>
        <CustomFormattedDate value={record.fromInclusive} /> – <CustomFormattedDate value={record.toInclusive} />
      </>
    ),
  },
  {
    title: 'Abrechnungsergebnis',
    defaultSelected: true,
    width: '120px',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.saldo, b.saldo),
    render: (text, record) => (
      <>
        {record.guthaben ? (
          <Flex justifyContent="space-between">
            <Tag color="green">Guthaben</Tag> <EuroAmount value={record.saldo} />
          </Flex>
        ) : (
          <Flex justifyContent="space-between">
            <Tag color="red">Nachzahlung</Tag> <EuroAmount value={record.saldo} />
          </Flex>
        )}
      </>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.status.text, b.status.text),
    render: (text, record) => <StatusBadge entityStatus={record.status} showBadgeText />,
  },
  {
    title: 'Erstellt am',
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) =>
      isAbrechnungTypeBkAbrechnung(abrechnungType) ? (
        <Link to={generatePathToBkAbrechnungDetailsTopabrechnungDetailsPage(objektId, objektAbrechnungId, record.topAbrechnungId)} target="_blank">
          Details
        </Link>
      ) : (
        <Link to={generatePathToWeAbrechnungDetailsTopabrechnungDetailsPage(objektId, objektAbrechnungId, record.topAbrechnungId)} target="_blank">
          Details
        </Link>
      ),
  },
];

export default topAbrechnungTableColumns;
