import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';

export type VstKrzFreigbenModalValues = {
  buchungsdatum?: string | null;
};

export const vstKrzFreigebenModalFormFields: FormFields<VstKrzFreigbenModalValues> = {
  buchungsdatum: 'buchungsdatum',
};

export const vstKrzFreigebenModalFormInitialValues: VstKrzFreigbenModalValues = {
  buchungsdatum: null,
};

export const mapFormValuesToVstKrzFreigeben = (values: VstKrzFreigbenModalValues) => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  buchungsdatum: mapFormDateValueToDateString(values.buchungsdatum!),
});
