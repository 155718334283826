import React, { FC } from 'react';
import { Dropdown, MenuProps, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, DownOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { MutationFunctionOptions } from '@apollo/client';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { isStatusArchived } from '../../../helpers/statusHelper';
import { generatePathToMahndefinitionUpdatePage, MahndefinitionListEntryWithStatus } from '../mahndefinitonUriPaths';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import {
  ArchiveMahndefinitionMutation,
  ArchiveMahndefinitionMutationVariables,
  DeleteMahndefinitionMutation,
  DeleteMahndefinitionMutationVariables,
  UnarchiveMahndefinitionMutation,
  UnarchiveMahndefinitionMutationVariables,
  useArchiveMahndefinitionMutation,
  useDeleteMahndefinitionMutation,
  useUnarchiveMahndefinitionMutation,
} from '../gql/MahndefinitionMutations.types';
import { MahndefinitionListEntry } from '../../../types';
import { useToggle } from '../../../hooks/useToggle';
import MahndefintionChangeHistoryModal from '../ChangeHistory/MahndefintionChangeHistoryModal';

type Props = {
  mahndefinition: MahndefinitionListEntryWithStatus;
  onActionSuccess: () => void;
};

const MahndefinitionTableActions: FC<Props> = ({ mahndefinition, onActionSuccess }) => {
  const entity = 'Mahndefinition';
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const isArchived = isStatusArchived(mahndefinition.status);

  const [runUnarchive] = useUnarchiveMahndefinitionMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveMahndefinitionMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDelete] = useDeleteMahndefinitionMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: <Link to={generatePathToMahndefinitionUpdatePage(mahndefinition.mahndefinitionId)}>Bearbeiten</Link>, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(runUnarchive, mahndefinition) : showConfirmArchive(runArchive, mahndefinition),
      icon: <ArchiveIcon style={radixActionStyles} />,
    },
    {
      key: '4',
      label: 'Löschen',
      icon: <DeleteOutlined />,
      onClick: showConfirmDelete(runDelete, mahndefinition),
      disabled: !mahndefinition.deletable,
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <MahndefintionChangeHistoryModal
        mahndefinitionId={mahndefinition.mahndefinitionId}
        open={isChangeHistoryOpen}
        onCancel={toggleChangeHistoryOpen}
      />
    </>
  );
};

const showConfirmDelete =
  (
    runDelete: (options?: MutationFunctionOptions<DeleteMahndefinitionMutation, DeleteMahndefinitionMutationVariables>) => Promise<any>,
    mahndefinition: MahndefinitionListEntry
  ) =>
  () => {
    Modal.confirm({
      title: `Möchten Sie die Mahndefinition löschen?`,
      content: `${mahndefinition.bezeichnung}`,
      okButtonProps: { danger: true },
      okText: 'Löschen',
      cancelText: 'Abbrechen',
      onOk() {
        return runDelete({ variables: { mahndefinitionId: mahndefinition.mahndefinitionId } });
      },
    });
  };

const showConfirmArchive =
  (
    runArchive: (options?: MutationFunctionOptions<ArchiveMahndefinitionMutation, ArchiveMahndefinitionMutationVariables>) => Promise<any>,
    mahndefinition: MahndefinitionListEntry
  ) =>
  () => {
    Modal.confirm({
      title: `Möchten Sie die Mahndefinition archivieren?`,
      content: `${mahndefinition.bezeichnung}`,
      okText: 'Archivieren',
      cancelText: 'Abbrechen',
      onOk() {
        return runArchive({ variables: { mahndefinitionId: mahndefinition.mahndefinitionId } });
      },
    });
  };

const showConfirmUnarchive =
  (
    runUnarchive: (options?: MutationFunctionOptions<UnarchiveMahndefinitionMutation, UnarchiveMahndefinitionMutationVariables>) => Promise<any>,
    mahndefinition: MahndefinitionListEntry
  ) =>
  () => {
    Modal.confirm({
      title: `Möchten Sie die Mahndefinition reaktivieren?`,
      content: `${mahndefinition.bezeichnung}`,
      okText: 'Reaktivieren',
      cancelText: 'Abbrechen',
      onOk() {
        return runUnarchive({ variables: { mahndefinitionId: mahndefinition.mahndefinitionId } });
      },
    });
  };

export default MahndefinitionTableActions;
