import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import { HandleTableSorting } from '../../../../shared/typeHelpers';
import NestedTable from '../../../../components/Table/NestedTable/NestedTable';
import { ReceivedBankTransactionListEntry } from '../../../../types';
import receivedBankTransactionTableColumns from './receivedBankTransactionTableColumns';

type Props = {
  handleTableSorting: HandleTableSorting;
  loading: boolean;
  pagination: PaginationProps;
  onAction: () => void;
  receivedBankTransactionList?: ReceivedBankTransactionListEntry[];
};

const ReceivedBankTransactionTable: FC<Props> = ({ loading, onAction, handleTableSorting, pagination, receivedBankTransactionList }) => {
  return (
    <NestedTable<ReceivedBankTransactionListEntry>
      loading={loading}
      level={1}
      colWidth={170}
      locale={{
        emptyText: <Empty description={<span>Keine Empfangene Bankbuchungen vorhanden</span>} />,
      }}
      dataSource={receivedBankTransactionList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={receivedBankTransactionTableColumns(onAction)}
      expandable={{ rowExpandable: () => false }}
      useHighlighting
      rowKey={(record) => record.receivedBankTransactionId}
      expandedRowKey="dataCarrierId"
    />
  );
};

export default ReceivedBankTransactionTable;
