import React, { FC } from 'react';
import { Divider } from 'antd';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import EmailDelivery from './EmailDelivery';
import { useInfoMailDeliveryQuery } from '../gql/InfoMailDeliveryQueries.types';

type Props = {
  infoMailDeliveryId: string;
};

const SourceDeliveryInfoRow: FC<Props> = ({ infoMailDeliveryId }) => {
  const { data } = useInfoMailDeliveryQuery({ variables: { infoMailDeliveryId } });
  const infoMailDelivery = data?.getInfoMailDelivery.data;

  if (!infoMailDelivery?.sourceDelivery) return null;

  return (
    <>
      <Divider />

      <CardDetailsInfoRow infoRowTitle="Versendet aus (Quelle)">
        <EmailDelivery emailDelivery={infoMailDelivery.sourceDelivery} />
      </CardDetailsInfoRow>
    </>
  );
};

export default SourceDeliveryInfoRow;
