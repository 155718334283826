import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useField } from 'formik';
import { useAbrechnungskreisTypeListQuery } from '../gql/AbrechnungskreisTypeQueries.types';
import { abrechnungskreisFormFields } from './abrechnungskreisFormMapper';

const AbrechnungskreisTypeSelect: FC<SelectProps> = (props) => {
  const { data, loading } = useAbrechnungskreisTypeListQuery();
  const [, , { setValue: setVerrechnungUstVomAufwandToUndefined }] = useField<undefined>(abrechnungskreisFormFields.verrechnungUstVomAufwand);

  const abrechnungskreisTypeList = data?.getAbrechnungskreisTypeList.data ?? [];

  return (
    <Select loading={loading} placeholder="Type auswählen" onChange={() => setVerrechnungUstVomAufwandToUndefined(undefined)} {...props}>
      {abrechnungskreisTypeList.map((abrechnungskreisType) => (
        <Select.Option key={abrechnungskreisType.value} value={abrechnungskreisType.value}>
          {abrechnungskreisType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AbrechnungskreisTypeSelect;
