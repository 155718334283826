import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { generatePathToVerarbeitungBkObjAbrDetailsPage } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungObjektAbrechnung from '../../../features/Verarbeitung/ObjektAbrechnung/VerarbeitungObjektAbrechnung';
import { verarbeitungPageAndMenuListTitles, verarbeitungTypes } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungErrorAndWarningListing from '../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import { AppGenerierlaufType, ObjektAbrechnungGenerierlauf } from '../../../types';

const menuListBkObjAbr = (objAbrId: string, generierlauf: ObjektAbrechnungGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.BkObjektAbrechnung,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungBkObjAbrDetailsPage(objAbrId)}/entries`,
        content: () => (
          <VerarbeitungObjektAbrechnung
            title={verarbeitungPageAndMenuListTitles.BkObjektAbrechnung}
            type={verarbeitungTypes.BkObjektAbrechnung}
            generierlaufType={AppGenerierlaufType.BkObjektAbrechnungErstellung}
            generierlauf={generierlauf}
          />
        ),
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungBkObjAbrDetailsPage(objAbrId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListBkObjAbr;
