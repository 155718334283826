import { OffenePostenKontoType } from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';

export type PdfExportOPListFormValues = {
  rechnungsausstellerId?: string | null;
  vertragspartnerId?: string | null;
  kontotyp: OffenePostenKontoType;
  dueDateTo?: string | null;
};

export const pdfExportOPListFormFields: FormFields<PdfExportOPListFormValues> = {
  rechnungsausstellerId: 'rechnungsausstellerId',
  vertragspartnerId: 'vertragspartnerId',
  kontotyp: 'kontotyp',
  dueDateTo: 'dueDateTo',
};

export const pdfExportOPListFormInitialValues: PdfExportOPListFormValues = {
  kontotyp: OffenePostenKontoType.Debitor,
  dueDateTo: dayjsCustom().format('YYYY-MM-DD'),
};
