import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import SysSettingsUstRegelwerkPage from './SysSettingsUstRegelwerkPage';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';

const sysSettingsUstRegelwerkPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.ustRegelsetPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsUstRegelwerkPage />} />}
  />
);

export default sysSettingsUstRegelwerkPageRoute;
