import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { FormikProps, useField } from 'formik';
import { Radio, RadioGroupProps } from 'formik-antd';
import { KontoType } from '../../../../../types';
import { kontoFormFields, KontoFormValues } from '../../kontoFormMapper';
import { disabledPersonenkontoAndNoKontoverwendung } from '../../kontoFormHelpers';
import { useKontoTypeListQuery } from '../../../gql/KontoTypeQueries.types';

type Props = {
  name: string;
  formikProps: FormikProps<KontoFormValues>;
} & RadioGroupProps;

const KontoTypeButtons: FC<Props> = ({ name, formikProps, ...restProps }) => {
  const { loading, data } = useKontoTypeListQuery();
  const [, , { setValue: setKontoVerwendungToUndefined }] = useField<undefined>(kontoFormFields.verwendung);

  if (loading)
    return (
      <>
        <Skeleton.Button active />
        <Skeleton.Button active />
      </>
    );

  const kontoTypeList = data?.getKontoTypeList?.data;

  const sachkontoType = kontoTypeList?.find((kontoType) => kontoType.value === KontoType.Sachkonto);
  const personenkontoType = kontoTypeList?.find((kontoType) => kontoType.value === KontoType.Personenkonto);

  return (
    <Radio.Group {...restProps} name={name} onChange={() => setKontoVerwendungToUndefined(undefined)} block>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <Radio.Button value={sachkontoType!.value}>{sachkontoType!.text}</Radio.Button>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <Radio.Button value={personenkontoType!.value} disabled={disabledPersonenkontoAndNoKontoverwendung(formikProps.values.klasse)}>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {personenkontoType!.text}
      </Radio.Button>
    </Radio.Group>
  );
};

export default KontoTypeButtons;
