import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KundenSystemEinstellungQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
}>;

export type KundenSystemEinstellungQuery = {
  getKundensystemEinstellung: {
    data: { salutationList: Array<{ text: string; value: Types.Salutation }> };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const KundenSystemEinstellungDocument = gql`
  query KundenSystemEinstellung($kundenSystemId: ID!) {
    getKundensystemEinstellung(kundenSystemId: $kundenSystemId) {
      data {
        salutationList {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useKundenSystemEinstellungQuery(
  baseOptions: Apollo.QueryHookOptions<KundenSystemEinstellungQuery, KundenSystemEinstellungQueryVariables> &
    ({ variables: KundenSystemEinstellungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KundenSystemEinstellungQuery, KundenSystemEinstellungQueryVariables>(KundenSystemEinstellungDocument, options);
}
export function useKundenSystemEinstellungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KundenSystemEinstellungQuery, KundenSystemEinstellungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KundenSystemEinstellungQuery, KundenSystemEinstellungQueryVariables>(KundenSystemEinstellungDocument, options);
}
export function useKundenSystemEinstellungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KundenSystemEinstellungQuery, KundenSystemEinstellungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KundenSystemEinstellungQuery, KundenSystemEinstellungQueryVariables>(KundenSystemEinstellungDocument, options);
}
export type KundenSystemEinstellungQueryHookResult = ReturnType<typeof useKundenSystemEinstellungQuery>;
export type KundenSystemEinstellungLazyQueryHookResult = ReturnType<typeof useKundenSystemEinstellungLazyQuery>;
export type KundenSystemEinstellungSuspenseQueryHookResult = ReturnType<typeof useKundenSystemEinstellungSuspenseQuery>;
export type KundenSystemEinstellungQueryResult = Apollo.QueryResult<KundenSystemEinstellungQuery, KundenSystemEinstellungQueryVariables>;
