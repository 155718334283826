import React from 'react';
import { Route } from 'react-router';
import FirmendatenIndexSeriesPage from './FirmendatenIndexSeriesPage';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';

const sysSettingsFirmendatenIndexSeriesPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.firmendatenIndexSeries}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<FirmendatenIndexSeriesPage />} />}
  />
);

export default sysSettingsFirmendatenIndexSeriesPageRoute;
