import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useIbanFibuKontoBanknameListQuery } from '../gql/IbanFibuKontoQueries.types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = Omit<SelectProps, 'id' | 'size' | 'placeholder' | 'filterOption'>;

const BanknameListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useIbanFibuKontoBanknameListQuery();
  const list = data?.getIbanFibuKontoBanknameList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Bankname auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((bankname) => (
        <Select.Option key={bankname} value={bankname}>
          <DataWithShortenedText maxTextLength={40} text={bankname} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default BanknameListFormSelect;
