import { Route } from 'react-router';
import { URI_VERARBEITUNG_RECEIVED_BANK_TRANSACTION_VERBUCHEN_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import ReceivedBankTransactionVerbuchenDetailsPage from './ReceivedBankTransactionVerbuchenDetailsPage';

export type VerarbeitungReceivedBankTransactionVerbuchenDetailsPageRouteParams = { generierlaufId: string };

export const verarbeitungReceivedBankTransactionVerbuchenDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_RECEIVED_BANK_TRANSACTION_VERBUCHEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<ReceivedBankTransactionVerbuchenDetailsPage />} />}
  />
);
