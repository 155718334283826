import React, { FC } from 'react';
import { Box } from 'rebass';
import { Button, Drawer, Tabs, TabsProps } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { ITimelineState } from '../../../components/Timeline/useTimelineOpen';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import RepFondsKontenZuweisungVersionTimeline from './Version/RepFondsKontenZuweisungVersionTimeline';
import { RepFondsEinstellung } from '../../../types';
import theme from '../../../theme';

type Props = {
  repFondsEinstellung?: RepFondsEinstellung;
  timelineDrawer: ITimelineState;
  closeTimelineDrawer: () => void;
  reloadRepFondsCurrentKontenZuweisung: () => void;
};

const RepFondsKontenZuweisungDrawer: FC<Props> = ({ timelineDrawer, closeTimelineDrawer, reloadRepFondsCurrentKontenZuweisung }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined style={{ color: theme.colors.blue }} />
          Verlauf
        </span>
      ),
      children: (
        <Box p={16}>
          <RepFondsKontenZuweisungVersionTimeline reloadRepFondsCurrentKontenZuweisung={reloadRepFondsCurrentKontenZuweisung} />
        </Box>
      ),
    },
  ];

  return (
    <Drawer
      title="Bearbeiten"
      width={1280}
      open={timelineDrawer.visible}
      onClose={closeTimelineDrawer}
      destroyOnClose
      footer={
        <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
          <Button onClick={closeTimelineDrawer} type="primary">
            Schließen
          </Button>
        </ButtonsAligned>
      }
    >
      <Tabs defaultActiveKey="1" items={items} />
    </Drawer>
  );
};

export default RepFondsKontenZuweisungDrawer;
