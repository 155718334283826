import { FC } from 'react';
import { Typography } from 'antd';
import { AbrechnungsdefinitionType } from '../../../types';
import {
  isAbrDefTypeBkAbrechnung,
  isAbrDefTypeWeAbrechnung,
} from '../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import theme from '../../../theme';
import { isZuordnungKontoKlasseTypeVerrechnung, ZuordnungKontoKlasseType } from './AbrKreisKontenzuordnungHelpers';

type Props = {
  abrDefinitionType: AbrechnungsdefinitionType;
  zuordnungKontoKlasseType: ZuordnungKontoKlasseType;
};

const AbrKreisOfVerrechnungTypeName: FC<Props> = ({ abrDefinitionType, zuordnungKontoKlasseType }) =>
  (isAbrDefTypeWeAbrechnung(abrDefinitionType) || isAbrDefTypeBkAbrechnung(abrDefinitionType)) &&
  isZuordnungKontoKlasseTypeVerrechnung(zuordnungKontoKlasseType) ? (
    <Typography.Text>Nicht auswählbar</Typography.Text>
  ) : (
    <Typography.Text style={{ color: theme.textColor.danger }}>Keine Zuordnung</Typography.Text>
  );

export default AbrKreisOfVerrechnungTypeName;
