import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { isUstVomAufwandBuchungStatusVerbucht } from '../../../helpers/statusHelper';
import { UstVomAufwand } from '../../../types';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';

type UstVomAufwandAllBuchungLinkProps = {
  ustVomAufwand: UstVomAufwand;
};

const UstVomAufwandAllBuchungLink: FC<UstVomAufwandAllBuchungLinkProps> = ({ ustVomAufwand }) =>
  isUstVomAufwandBuchungStatusVerbucht(ustVomAufwand.buchungStatus) && ustVomAufwand.ustVomAufwandVertragList.length > 0 ? (
    <Link to={generatePathToBookingDetailsPage(ustVomAufwand.fibuBuchungIdList)}>Ust.-vom-Aufwand-Buchungen anzeigen</Link>
  ) : null;

export default UstVomAufwandAllBuchungLink;
