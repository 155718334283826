import React from 'react';
import { Tag, Tooltip, Typography } from 'antd';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../../components/Card';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import EntryListingTableActions from './EntryListingTableActions';
import FirmendatenKurzBezeichnung from '../../../shared/FirmendatenKurzBezeichnung';
import { BudgetingVPosAuftschluesselLinkage } from '../../../../types';

const entryListingTableColumns = (
  onAction: () => void,
  linkageEntryList: BudgetingVPosAuftschluesselLinkage[]
): TableWithColSelectorColumnProps<BudgetingVPosAuftschluesselLinkage>[] => {
  return [
    {
      title: 'Verwaltungsart',
      sorter: (a, b) => compareTwoStringsForSorting(a.objektVerwaltungsart.text, b.objektVerwaltungsart.text),
      defaultSelected: true,
      render: (text, record) => <Typography.Text>{record.objektVerwaltungsart.text}</Typography.Text>,
    },
    {
      title: 'Vertragsebene',
      sorter: (a, b) => compareTwoStringsForSorting(a.vertragLevel.text, b.vertragLevel.text),
      defaultSelected: true,
      render: (text, record) => <Typography.Text>{record.vertragLevel.text}</Typography.Text>,
    },

    {
      title: 'Vorscheibungposition',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.vorschreibungsposition.bezeichnung, b.vorschreibungsposition.bezeichnung),
      render: (text, record) => (
        <DataWithShortenedText maxTextLength={35} text={record.vorschreibungsposition.bezeichnung}>
          {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'Aufteilungsschlüssel',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.aufteilungsschluessel.kurzBezeichnung, b.aufteilungsschluessel.kurzBezeichnung),
      render: (text, record) => (
        <Tooltip title={record.aufteilungsschluessel.bezeichnung}>
          <Typography.Text>{record.aufteilungsschluessel.kurzBezeichnung}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: 'Firma',
      render: (text, record) => (record.firmendatenId ? <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} /> : <Tag>System</Tag>),
    },
    {
      title: 'Erstellt am',
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      render: (text, record) => (
        <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
      ),
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (text, record) => (
        <EntryListingTableActions budgetingVPosAuftschluesselLinkage={record} onAction={onAction} linkageEntryList={linkageEntryList} />
      ),
    },
  ];
};

export default entryListingTableColumns;
