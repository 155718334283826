import React, { FC } from 'react';
import { Tag } from 'antd';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../helpers/selectHelper';
import DataWithShortenedText from '../../components/Helpers/DataWithShortenedText';
import { usePaymentProposalListQuery } from './gql/PaymentProposalQueries.types';
import { PAYMENT_PROPOSAL_ID_NOT_ASSIGNED } from '../Payment/Listing/Filters/paymentListingFiltersFormMapper';
import { useAuth } from '../../shared/Auth/authContext';
import { PaymentProposalStatus } from '../../types';

type Props = {
  showNotAssignedOption: boolean;
  showOnlyForMitarbeiter?: boolean;
  statusList?: PaymentProposalStatus[];
} & SelectProps;

const PaymentProposalSelect: FC<Props> = ({ showOnlyForMitarbeiter, showNotAssignedOption, statusList, ...restProps }) => {
  const { mitarbeiter } = useAuth();

  const { loading, data } = usePaymentProposalListQuery({
    variables: {
      mitarbeiterId: showOnlyForMitarbeiter ? mitarbeiter?.mitarbeiterId : undefined,
      statusList,
    },
  });
  const zahlungsvorschlagList = data?.getPaymentProposalList.data;

  return (
    <Select
      id={restProps.name}
      loading={loading}
      size="small"
      placeholder="Zahlungsvorschlag auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      {...restProps}
    >
      {showNotAssignedOption && (
        <Select.Option key={PAYMENT_PROPOSAL_ID_NOT_ASSIGNED} value={PAYMENT_PROPOSAL_ID_NOT_ASSIGNED}>
          <Tag>Kein Zahlungsvorschlag zugewiesen</Tag>
        </Select.Option>
      )}
      {zahlungsvorschlagList?.map((zahlungsvorschlag) => {
        return (
          <Select.Option key={zahlungsvorschlag.paymentProposalId} value={zahlungsvorschlag.paymentProposalId}>
            <DataWithShortenedText maxTextLength={40} text={zahlungsvorschlag.bezeichnung}>
              {(shortenedText) => shortenedText}
            </DataWithShortenedText>
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default PaymentProposalSelect;
