import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_PAYMENT_CLEARING_PAGE } from '../../features/PaymentClearing/paymentClearingUriPaths';
import PaymentClearingPage from './PaymentClearingPage';

const paymentClearingPageRoute = (
  <Route path={URI_PAYMENT_CLEARING_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<PaymentClearingPage />} />} />
);

export default paymentClearingPageRoute;
