import React, { FC, useEffect, useState } from 'react';
import { Collapse, Space, Spin, Typography } from 'antd';
import { useSingleInvoiceStornoBuchungPreviewMutation } from '../../../../gql/SingleInvoiceMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { SingleInvoiceUpdateInput } from '../../../../../../types';
import { useToggle } from '../../../../../../hooks/useToggle';
import BookingPreviewTable from '../../shared/SingleInvoice/BookingPreviewTable';

type Props = {
  belegId: string;
  input: SingleInvoiceUpdateInput;
  disabled?: boolean;
  isSubmitting: boolean;
};

const DirectBookingPreviewDetails: FC<Props> = ({ belegId, input, disabled, isSubmitting }) => {
  const [open, setOpen] = useToggle(false);
  const [key, setKey] = useState<number>(-1);

  const [runGetBookingPreview, { loading, data }] = useSingleInvoiceStornoBuchungPreviewMutation({
    variables: { belegId, input },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  useEffect(() => {
    if (isSubmitting || disabled) {
      setKey(-1);
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, isSubmitting]);

  useEffect(() => {
    if (!open || disabled || isSubmitting) return;
    runGetBookingPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, open]);

  const previewData = data?.singleInvoiceStornoBuchungPreview.data;

  return (
    <Collapse
      collapsible={disabled ? 'disabled' : undefined}
      ghost
      activeKey={key}
      accordion
      onChange={(key) => {
        setOpen(!!key);
        setKey(Number(key));
      }}
    >
      <Collapse.Panel key={1} header="Buchungen Vorschau">
        {!previewData ? (
          <Spin />
        ) : (
          <>
            <Space direction="vertical" size={30} style={{ width: '100%' }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Title level={5}>Originalbuchung</Typography.Title>
                <Space direction="vertical" size={20} style={{ width: '100%' }}>
                  {previewData.originalBuchungList.map((buchung) => (
                    <BookingPreviewTable key={JSON.stringify(buchung)} data={buchung} loading={loading} />
                  ))}
                </Space>
              </Space>

              <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Title level={5}>Stornobuchung</Typography.Title>
                <Space direction="vertical" size={20} style={{ width: '100%' }}>
                  {previewData.stornoBuchungList.map((buchung) => (
                    <BookingPreviewTable key={JSON.stringify(buchung)} data={buchung} loading={loading} />
                  ))}
                </Space>
              </Space>

              {previewData.korrekturBuchung && (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Typography.Title level={5}>Korrekturbuchung</Typography.Title>
                  <BookingPreviewTable data={previewData.korrekturBuchung} loading={loading} />
                </Space>
              )}
            </Space>
          </>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default DirectBookingPreviewDetails;
