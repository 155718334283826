import React, { FC } from 'react';
import { Button, Divider, Space } from 'antd';
import ButtonsAligned, { ButtonsAlignedUsage } from './ButtonsAligned';

type Props = {
  onCancel: () => void;
};

const FormButtonClose: FC<Props> = ({ onCancel }) => {
  return (
    <Space direction="vertical" style={{ width: '100%', justifyContent: 'flex-end' }}>
      <Divider style={{ margin: '8px 0' }} />
      <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
        <Button onClick={onCancel}>Schließen</Button>
      </ButtonsAligned>
    </Space>
  );
};

export default FormButtonClose;
