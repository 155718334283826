import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBudgetingAbrDefVPosLinkageFirmendatenMutationVariables = Types.Exact<{
  firmenDatenId: Types.Scalars['ID']['input'];
  input: Types.BudgetingAbrDefVPosLinkageCreateInput;
}>;

export type CreateBudgetingAbrDefVPosLinkageFirmendatenMutation = {
  createBudgetingAbrDefVPosLinkageFirmendaten: {
    data: { budgetingAbrDefVPosLinkageId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type CreateBudgetingAbrDefVPosLinkageKundenSystemMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  input: Types.BudgetingAbrDefVPosLinkageCreateInput;
}>;

export type CreateBudgetingAbrDefVPosLinkageKundenSystemMutation = {
  createBudgetingAbrDefVPosLinkageKundenSystem: {
    data: { budgetingAbrDefVPosLinkageId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBudgetingAbrDefVPosLinkageMutationVariables = Types.Exact<{
  budgetingAbrDefVPosLinkageId: Types.Scalars['ID']['input'];
  input: Types.BudgetingAbrDefVPosLinkageUpdateInput;
}>;

export type UpdateBudgetingAbrDefVPosLinkageMutation = {
  updateBudgetingAbrDefVPosLinkage: {
    data: { budgetingAbrDefVPosLinkageId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBudgetingAbrDefVPosLinkageMutationVariables = Types.Exact<{
  budgetingAbrDefVPosLinkageId: Types.Scalars['ID']['input'];
}>;

export type DeleteBudgetingAbrDefVPosLinkageMutation = {
  deleteBudgetingAbrDefVPosLinkage: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export const CreateBudgetingAbrDefVPosLinkageFirmendatenDocument = gql`
  mutation CreateBudgetingAbrDefVPosLinkageFirmendaten($firmenDatenId: ID!, $input: BudgetingAbrDefVPosLinkageCreateInput!) {
    createBudgetingAbrDefVPosLinkageFirmendaten(firmendatenId: $firmenDatenId, input: $input) {
      data {
        budgetingAbrDefVPosLinkageId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateBudgetingAbrDefVPosLinkageFirmendatenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBudgetingAbrDefVPosLinkageFirmendatenMutation,
    CreateBudgetingAbrDefVPosLinkageFirmendatenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBudgetingAbrDefVPosLinkageFirmendatenMutation, CreateBudgetingAbrDefVPosLinkageFirmendatenMutationVariables>(
    CreateBudgetingAbrDefVPosLinkageFirmendatenDocument,
    options
  );
}
export type CreateBudgetingAbrDefVPosLinkageFirmendatenMutationHookResult = ReturnType<typeof useCreateBudgetingAbrDefVPosLinkageFirmendatenMutation>;
export type CreateBudgetingAbrDefVPosLinkageFirmendatenMutationResult = Apollo.MutationResult<CreateBudgetingAbrDefVPosLinkageFirmendatenMutation>;
export type CreateBudgetingAbrDefVPosLinkageFirmendatenMutationOptions = Apollo.BaseMutationOptions<
  CreateBudgetingAbrDefVPosLinkageFirmendatenMutation,
  CreateBudgetingAbrDefVPosLinkageFirmendatenMutationVariables
>;
export const CreateBudgetingAbrDefVPosLinkageKundenSystemDocument = gql`
  mutation CreateBudgetingAbrDefVPosLinkageKundenSystem($kundenSystemId: ID!, $input: BudgetingAbrDefVPosLinkageCreateInput!) {
    createBudgetingAbrDefVPosLinkageKundenSystem(kundenSystemId: $kundenSystemId, input: $input) {
      data {
        budgetingAbrDefVPosLinkageId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateBudgetingAbrDefVPosLinkageKundenSystemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBudgetingAbrDefVPosLinkageKundenSystemMutation,
    CreateBudgetingAbrDefVPosLinkageKundenSystemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBudgetingAbrDefVPosLinkageKundenSystemMutation, CreateBudgetingAbrDefVPosLinkageKundenSystemMutationVariables>(
    CreateBudgetingAbrDefVPosLinkageKundenSystemDocument,
    options
  );
}
export type CreateBudgetingAbrDefVPosLinkageKundenSystemMutationHookResult = ReturnType<
  typeof useCreateBudgetingAbrDefVPosLinkageKundenSystemMutation
>;
export type CreateBudgetingAbrDefVPosLinkageKundenSystemMutationResult = Apollo.MutationResult<CreateBudgetingAbrDefVPosLinkageKundenSystemMutation>;
export type CreateBudgetingAbrDefVPosLinkageKundenSystemMutationOptions = Apollo.BaseMutationOptions<
  CreateBudgetingAbrDefVPosLinkageKundenSystemMutation,
  CreateBudgetingAbrDefVPosLinkageKundenSystemMutationVariables
>;
export const UpdateBudgetingAbrDefVPosLinkageDocument = gql`
  mutation UpdateBudgetingAbrDefVPosLinkage($budgetingAbrDefVPosLinkageId: ID!, $input: BudgetingAbrDefVPosLinkageUpdateInput!) {
    updateBudgetingAbrDefVPosLinkage(budgetingAbrDefVPosLinkageId: $budgetingAbrDefVPosLinkageId, input: $input) {
      data {
        budgetingAbrDefVPosLinkageId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateBudgetingAbrDefVPosLinkageMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBudgetingAbrDefVPosLinkageMutation, UpdateBudgetingAbrDefVPosLinkageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBudgetingAbrDefVPosLinkageMutation, UpdateBudgetingAbrDefVPosLinkageMutationVariables>(
    UpdateBudgetingAbrDefVPosLinkageDocument,
    options
  );
}
export type UpdateBudgetingAbrDefVPosLinkageMutationHookResult = ReturnType<typeof useUpdateBudgetingAbrDefVPosLinkageMutation>;
export type UpdateBudgetingAbrDefVPosLinkageMutationResult = Apollo.MutationResult<UpdateBudgetingAbrDefVPosLinkageMutation>;
export type UpdateBudgetingAbrDefVPosLinkageMutationOptions = Apollo.BaseMutationOptions<
  UpdateBudgetingAbrDefVPosLinkageMutation,
  UpdateBudgetingAbrDefVPosLinkageMutationVariables
>;
export const DeleteBudgetingAbrDefVPosLinkageDocument = gql`
  mutation DeleteBudgetingAbrDefVPosLinkage($budgetingAbrDefVPosLinkageId: ID!) {
    deleteBudgetingAbrDefVPosLinkage(budgetingAbrDefVPosLinkageId: $budgetingAbrDefVPosLinkageId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteBudgetingAbrDefVPosLinkageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBudgetingAbrDefVPosLinkageMutation, DeleteBudgetingAbrDefVPosLinkageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBudgetingAbrDefVPosLinkageMutation, DeleteBudgetingAbrDefVPosLinkageMutationVariables>(
    DeleteBudgetingAbrDefVPosLinkageDocument,
    options
  );
}
export type DeleteBudgetingAbrDefVPosLinkageMutationHookResult = ReturnType<typeof useDeleteBudgetingAbrDefVPosLinkageMutation>;
export type DeleteBudgetingAbrDefVPosLinkageMutationResult = Apollo.MutationResult<DeleteBudgetingAbrDefVPosLinkageMutation>;
export type DeleteBudgetingAbrDefVPosLinkageMutationOptions = Apollo.BaseMutationOptions<
  DeleteBudgetingAbrDefVPosLinkageMutation,
  DeleteBudgetingAbrDefVPosLinkageMutationVariables
>;
