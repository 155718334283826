import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PostItFragmentDoc } from '../../PostIt/gql/PostItFragments.types';
export type RechtstraegerInfoFeldPersoneninfosFragment = {
  geburtsort?: string | null;
  identitaetsnachweis?: string | null;
  kundengruppeList?: Array<string> | null;
  merkmalList: Array<string>;
  staatsbuergerschaft?: string | null;
};

export type RechtstraegerInfoFeldFragment = {
  __typename: 'RechtstraegerInfoFeldPersoneninfos';
  rechtstraegerInfoFeldId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fileIdList: Array<string>;
  geburtsort?: string | null;
  identitaetsnachweis?: string | null;
  kundengruppeList?: Array<string> | null;
  merkmalList: Array<string>;
  staatsbuergerschaft?: string | null;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.InfoFeldStatus };
  type: { text: string; value: Types.RechtstraegerInfoFeldType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const RechtstraegerInfoFeldPersoneninfosFragmentDoc = gql`
  fragment RechtstraegerInfoFeldPersoneninfos on RechtstraegerInfoFeldPersoneninfos {
    geburtsort
    identitaetsnachweis
    kundengruppeList
    merkmalList
    staatsbuergerschaft
  }
`;
export const RechtstraegerInfoFeldFragmentDoc = gql`
  fragment RechtstraegerInfoFeld on AbstractRechtstraegerInfoFeld {
    __typename
    rechtstraegerInfoFeldId
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    fileIdList
    postIt {
      ...PostIt
    }
    status {
      text
      value
    }
    type {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
    ... on RechtstraegerInfoFeldPersoneninfos {
      ...RechtstraegerInfoFeldPersoneninfos
    }
  }
  ${PostItFragmentDoc}
  ${RechtstraegerInfoFeldPersoneninfosFragmentDoc}
`;
