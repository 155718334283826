import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppKontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
export type VorsteuerkuerzungEinstellungFieldsFragment = {
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  createTs: string;
  current: boolean;
  deletable?: boolean | null;
  validFrom: string;
  kommentar?: string | null;
  lastUpdateTs: string;
  vorsteuerkuerzungEinstellungId: string;
  versionIndex: number;
  historicizedList?: Array<{
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    createTs: string;
    current: boolean;
    deletable?: boolean | null;
    validFrom: string;
    kommentar?: string | null;
    lastUpdateTs: string;
    updatedByMitarbeiterId?: string | null;
    vorsteuerkuerzungEinstellungId: string;
    versionIndex: number;
    kontenZuweisungList: Array<{
      vorsteuerkuerzungKontenZuweisungId: string;
      vorsteuerkuerzungPauschalmieterKonto?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      vorsteuerkuerzungEigennutzungKonto?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      aufteilungsschluessel: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      aufwandsKontoList: Array<{ bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string }>;
      vorsteuerkuerzungKonto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      vorsteuerkuerzungWeMieterKonto?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
    }>;
    warningList: Array<{ message: string; type: string; attribute?: string | null }>;
  }> | null;
  kontenZuweisungList: Array<{
    vorsteuerkuerzungKontenZuweisungId: string;
    vorsteuerkuerzungPauschalmieterKonto?: {
      bezeichnung: string;
      firmendatenId?: string | null;
      kontoId: string;
      kontoKlasse: string;
      nummer: string;
    } | null;
    vorsteuerkuerzungEigennutzungKonto?: {
      bezeichnung: string;
      firmendatenId?: string | null;
      kontoId: string;
      kontoKlasse: string;
      nummer: string;
    } | null;
    aufteilungsschluessel: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    aufwandsKontoList: Array<{ bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string }>;
    vorsteuerkuerzungKonto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
    vorsteuerkuerzungWeMieterKonto?: {
      bezeichnung: string;
      firmendatenId?: string | null;
      kontoId: string;
      kontoKlasse: string;
      nummer: string;
    } | null;
  }>;
  warningList: Array<{ message: string; type: string; attribute?: string | null }>;
};

export const VorsteuerkuerzungEinstellungFieldsFragmentDoc = gql`
  fragment VorsteuerkuerzungEinstellungFields on VorsteuerkuerzungEinstellung {
    createdBy
    createdByMitarbeiterId
    createTs
    current
    deletable
    validFrom
    kommentar
    historicizedList {
      createdBy
      createdByMitarbeiterId
      createTs
      current
      deletable
      validFrom
      kommentar
      kontenZuweisungList {
        vorsteuerkuerzungPauschalmieterKonto {
          ...AppKontoFields
        }
        vorsteuerkuerzungEigennutzungKonto {
          ...AppKontoFields
        }
        aufteilungsschluessel {
          ...AufteilungsschluesselFields
        }
        aufwandsKontoList {
          ...AppKontoFields
        }
        vorsteuerkuerzungKontenZuweisungId
        vorsteuerkuerzungKonto {
          ...AppKontoFields
        }
        vorsteuerkuerzungWeMieterKonto {
          ...AppKontoFields
        }
      }
      lastUpdateTs
      updatedByMitarbeiterId
      vorsteuerkuerzungEinstellungId
      versionIndex
      warningList {
        message
        type
        attribute
      }
    }
    kontenZuweisungList {
      vorsteuerkuerzungPauschalmieterKonto {
        ...AppKontoFields
      }
      vorsteuerkuerzungEigennutzungKonto {
        ...AppKontoFields
      }
      aufteilungsschluessel {
        ...AufteilungsschluesselFields
      }
      aufwandsKontoList {
        ...AppKontoFields
      }
      vorsteuerkuerzungKontenZuweisungId
      vorsteuerkuerzungKonto {
        ...AppKontoFields
      }
      vorsteuerkuerzungWeMieterKonto {
        ...AppKontoFields
      }
    }
    lastUpdateTs
    vorsteuerkuerzungEinstellungId
    versionIndex
    warningList {
      message
      type
      attribute
    }
  }
  ${AppKontoFieldsFragmentDoc}
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
