import React, { FC } from 'react';
import { Empty } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import abrechnungTableColumns from './abrechnungTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { AbrechnungListEntry } from '../../../../types';
import { HandleTableSorting } from '../../../../shared/typeHelpers';

type ZinslisteTableProps = {
  abrechnungList?: AbrechnungListEntry[];
  loading: boolean;
  pagination: PaginationProps;
  handleTableSorting: HandleTableSorting;
};

const AbrechnungTable: FC<ZinslisteTableProps> = ({ abrechnungList, loading, pagination, handleTableSorting }) => {
  return (
    <TableWithColSelector<AbrechnungListEntry>
      size="small"
      tableLayout="auto"
      locale={{
        emptyText: <Empty description={<span>Keine Zinslisten vorhanden</span>} />,
      }}
      loading={loading}
      scroll={{ x: 900 }}
      pagination={pagination}
      columns={abrechnungTableColumns}
      dataSource={abrechnungList}
      onChange={handleTableSorting}
      filterIdentifier="main-abrechnung"
      rowKey={(record) => record.abrechnungId}
    />
  );
};

export default AbrechnungTable;
