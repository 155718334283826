import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import ListingFilters from './Filters/ListingFilters';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { BudgetingVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { verarbeitungTypes } from '../../verarbeitungHelpers';
import { BudgetingGenerierlauf } from '../../../../types';
import {
  budgetingVerarbeitungEntryFiltersFormInitialValues,
  BudgetingVerarbeitungEntryFiltersFormValues,
  mapFormValuesToBudgetingVerarbeitungFilters,
} from './Filters/ListingFiltersFormMapper';
import BudgetingVerarbeitungEntryTable from './Table/BudgetingVerarbeitungEntryTable';

type Props = {
  generierlauf: BudgetingGenerierlauf;
};

const BudgetingVerarbeitungEntryListing: FC<Props> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<BudgetingVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.Budgeting, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: BudgetingVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.Budgeting,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToBudgetingVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<BudgetingVerarbeitungEntryFiltersFormValues>
      initialValues={budgetingVerarbeitungEntryFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} entryList={generierlauf.entryList} />
          <BudgetingVerarbeitungEntryTable entryList={generierlauf.entryList} formikProps={formikProps} />
        </>
      )}
    </Formik>
  );
};

export default BudgetingVerarbeitungEntryListing;
