import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import { OrderAddress } from '../../../../types';

type Props = {
  title: string;
  address: OrderAddress;
};

const AddressInnerCard: FC<Props> = ({ title, address }) => {
  return (
    <CardDetailsInfoRow infoRowTitle={title}>
      <Space direction="vertical" size={0}>
        <Typography.Text>
          {address?.street} {address?.houseEntranceApartmentNumber}
        </Typography.Text>
        <Typography.Text>
          {address?.zipCode} {address?.city}
        </Typography.Text>
      </Space>
    </CardDetailsInfoRow>
  );
};

export default AddressInnerCard;
