import * as Yup from 'yup';
import { entityIsRequired } from '../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */
export const masseinheitValidationSchema = Yup.string().required(entityIsRequired('Maßeinheit'));

export const masseinheitSelectFormPartValidationSchema = Yup.object().shape({
  masseinheit: masseinheitValidationSchema,
});

/* eslint-enable no-template-curly-in-string */
