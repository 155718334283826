import React, { FC } from 'react';
import { FormikProps } from 'formik';
import {
  VertragVposIndexedValueVerarbeitungEntryFiltersFormValues,
  vertragVposIndexedValueVerarbEntryListingFiltersFormFields,
} from '../../../shared/VertragVposIndexedValue/ListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { AppGenerierlaufType } from '../../../../../types';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/App/GenerierlaufObjektFormSelect';
import GenerierlaufBestandseinheitFormSelect from '../../../shared/Filters/App/GenerierlaufBestandseinheitFormSelect';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/App/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufValuePreservationTypeFormSelect from '../../../shared/Filters/App/GenerierlaufValuePreservationTypeFormSelect';
import GenerierlaufIndexSeriesFormSelect from '../../../shared/Filters/App/GenerierlaufIndexSeriesFormSelect';
import GenerierlaufVertragFormSelect from '../../../shared/Filters/App/GenerierlaufVertragFormSelect';

type ListingFiltersProps = {
  formikProps: FormikProps<VertragVposIndexedValueVerarbeitungEntryFiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<ListingFiltersProps> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      leftColumn={
        <>
          <FormItemWithoutColon name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.objektId} label="Objekt">
            <GenerierlaufObjektFormSelect
              name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.VertragVposIndexedValuesErstellung}
              onChange={() => {
                formikProps.setFieldValue(vertragVposIndexedValueVerarbEntryListingFiltersFormFields.bestandseinheitIdList, []);
                formikProps.submitForm();
              }}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.bestandseinheitIdList} label="Bestandseinheit">
            <GenerierlaufBestandseinheitFormSelect
              name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.bestandseinheitIdList}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.VertragVposIndexedValuesErstellung}
              objektId={formikProps.values.objektId}
              mode="multiple"
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.vertragId} label="Vertrag">
            <GenerierlaufVertragFormSelect
              name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.vertragId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.VertragVposIndexedValuesErstellung}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.valuePreservationType} label="Wertsicherungsart">
            <GenerierlaufValuePreservationTypeFormSelect
              name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.valuePreservationType}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.VertragVposIndexedValuesErstellung}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.indexSeriesId} label="Indexreihen">
            <GenerierlaufIndexSeriesFormSelect
              name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.indexSeriesId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.VertragVposIndexedValuesErstellung}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListFormSelect
              name={vertragVposIndexedValueVerarbEntryListingFiltersFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.VertragVposIndexedValuesErstellung}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
