import React, { FC } from 'react';
import { Space, Typography } from 'antd';

import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import { vertragVersionFormValuesDescriptions } from './Form/vertragVersionFormMapper';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import { BeVertragVersion } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type TimelineContentProps = {
  timeblock: ITimeblock<BeVertragVersion>;
};

const VertragVersionCardContent: FC<TimelineContentProps> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <TimelineCardContentInfoRow infoRowTitle={vertragVersionFormValuesDescriptions.validFrom}>
      {timeblock.validFrom && <CustomFormattedDate value={timeblock.validFrom} />}
    </TimelineCardContentInfoRow>

    <TimelineCardContentInfoRow infoRowTitle={vertragVersionFormValuesDescriptions.dueDateVorschreibung}>
      {timeblock.dueDateVorschreibung && <Typography.Text>{timeblock.dueDateVorschreibung.text}</Typography.Text>}
    </TimelineCardContentInfoRow>

    <TimelineCardContentInfoRow infoRowTitle={vertragVersionFormValuesDescriptions.verrechnungsart}>
      {timeblock.verrechnungsart && <Typography.Text>{timeblock.verrechnungsart.text}</Typography.Text>}
    </TimelineCardContentInfoRow>

    <TimelineCardContentInfoRow infoRowTitle={vertragVersionFormValuesDescriptions.paymentMethod}>
      {timeblock.paymentMethod && <Typography.Text>{timeblock.paymentMethod.text}</Typography.Text>}
    </TimelineCardContentInfoRow>
  </Space>
);

export default VertragVersionCardContent;
