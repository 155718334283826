import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type TicketTemplateFieldsFragment = {
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  dueDateText?: string | null;
  firmendatenId?: string | null;
  isSubTicketTemplate: boolean;
  name: string;
  parentId?: string | null;
  position: number;
  reminderText?: string | null;
  ticketTemplateId: string;
  updatedByMitarbeiterId?: string | null;
  dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
  priority: { text: string; value: Types.TicketPriority };
  reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const TicketTemplateFieldsFragmentDoc = gql`
  fragment TicketTemplateFields on TicketTemplate {
    createTs
    createdBy
    createdByMitarbeiterId
    dueDate {
      amount
      unit {
        text
        value
      }
    }
    dueDateText
    firmendatenId
    isSubTicketTemplate
    name
    parentId
    position
    priority {
      text
      value
    }
    reminder {
      amount
      unit {
        text
        value
      }
    }
    reminderText
    ticketTemplateId
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
`;
