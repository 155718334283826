import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IncomingInvoiceBookingCreationGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type IncomingInvoiceBookingCreationGenerierlaufQuery = {
  getIncomingInvoiceBookingCreationGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoiceBookingCreationGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  invoiceDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceStatus?: Types.InputMaybe<Types.IncomingInvoiceStatus>;
  issuerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  payeeId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  order?: Types.InputMaybe<Types.IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type IncomingInvoiceBookingCreationGenerierlaufEntryListQuery = {
  getIncomingInvoiceBookingCreationGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        belegId: string;
        brutto: number;
        buchungIdList: Array<string>;
        buchungsdatum: string;
        createTs: string;
        createdByMitarbeiterId: string;
        currentStep?: number | null;
        dueDate: string;
        fileId: string;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        issuerBankDetailsIban: string;
        issuerBankDetailsId: string;
        issuerName: string;
        issuerRechtstraegerId: string;
        kundenNummer?: string | null;
        netto: number;
        objektBezeichnung?: string | null;
        objektId?: string | null;
        partialAmountName?: string | null;
        payeeName: string;
        payeeRechtstraegerId: string;
        paymentReferenceText?: string | null;
        purposeOfUseText?: string | null;
        rechnungsDatum: string;
        rechnungsNummer: string;
        recipientBankDetailsIban: string;
        recipientBankDetailsId: string;
        retry: boolean;
        sepaMandatReference?: string | null;
        stepCount?: number | null;
        type: Types.InvoiceType;
        vat: number;
        billingType: { text: string; value: Types.Verrechnungsart };
        buchungsanweisungStatus?: { text: string; value: Types.OrderBuchungsanweisungStatus; description?: string | null } | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        paymentMethod: { text: string; value: Types.PaymentMethod };
        status: { description?: string | null; text: string; value: Types.IncomingInvoiceStatus };
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalPages: number; totalElements: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const IncomingInvoiceBookingCreationGenerierlaufDocument = gql`
  query IncomingInvoiceBookingCreationGenerierlauf($generierlaufId: ID!) {
    getIncomingInvoiceBookingCreationGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          description
          text
          value
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoiceBookingCreationGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<IncomingInvoiceBookingCreationGenerierlaufQuery, IncomingInvoiceBookingCreationGenerierlaufQueryVariables> &
    ({ variables: IncomingInvoiceBookingCreationGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoiceBookingCreationGenerierlaufQuery, IncomingInvoiceBookingCreationGenerierlaufQueryVariables>(
    IncomingInvoiceBookingCreationGenerierlaufDocument,
    options
  );
}
export function useIncomingInvoiceBookingCreationGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoiceBookingCreationGenerierlaufQuery, IncomingInvoiceBookingCreationGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoiceBookingCreationGenerierlaufQuery, IncomingInvoiceBookingCreationGenerierlaufQueryVariables>(
    IncomingInvoiceBookingCreationGenerierlaufDocument,
    options
  );
}
export function useIncomingInvoiceBookingCreationGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IncomingInvoiceBookingCreationGenerierlaufQuery, IncomingInvoiceBookingCreationGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoiceBookingCreationGenerierlaufQuery, IncomingInvoiceBookingCreationGenerierlaufQueryVariables>(
    IncomingInvoiceBookingCreationGenerierlaufDocument,
    options
  );
}
export type IncomingInvoiceBookingCreationGenerierlaufQueryHookResult = ReturnType<typeof useIncomingInvoiceBookingCreationGenerierlaufQuery>;
export type IncomingInvoiceBookingCreationGenerierlaufLazyQueryHookResult = ReturnType<typeof useIncomingInvoiceBookingCreationGenerierlaufLazyQuery>;
export type IncomingInvoiceBookingCreationGenerierlaufSuspenseQueryHookResult = ReturnType<
  typeof useIncomingInvoiceBookingCreationGenerierlaufSuspenseQuery
>;
export type IncomingInvoiceBookingCreationGenerierlaufQueryResult = Apollo.QueryResult<
  IncomingInvoiceBookingCreationGenerierlaufQuery,
  IncomingInvoiceBookingCreationGenerierlaufQueryVariables
>;
export const IncomingInvoiceBookingCreationGenerierlaufEntryListDocument = gql`
  query IncomingInvoiceBookingCreationGenerierlaufEntryList(
    $generierlaufId: ID!
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $invoiceDateFrom: String
    $invoiceDateTo: String
    $invoiceNumber: String
    $invoiceStatus: IncomingInvoiceStatus
    $issuerId: String
    $objektId: String
    $payeeId: String
    $page: Int
    $pageSize: Int
    $order: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute
    $orderDir: String
  ) {
    getIncomingInvoiceBookingCreationGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      exitCodeList: $exitCodeList
      invoiceDateFrom: $invoiceDateFrom
      invoiceDateTo: $invoiceDateTo
      invoiceNumber: $invoiceNumber
      invoiceStatus: $invoiceStatus
      issuerId: $issuerId
      objektId: $objektId
      payeeId: $payeeId
      page: $page
      pageSize: $pageSize
      order: $order
      orderDir: $orderDir
    ) {
      data {
        contentList {
          belegId
          billingType {
            text
            value
          }
          brutto
          buchungsanweisungStatus {
            text
            value
            description
          }
          buchungIdList
          buchungsdatum
          createTs
          createdByMitarbeiterId
          currentStep
          dueDate
          errorList {
            message
            type
          }
          exitCode {
            text
            value
          }
          fileId
          generierlaufEntryId
          infoMessage
          issuerBankDetailsIban
          issuerBankDetailsId
          issuerName
          issuerRechtstraegerId
          kundenNummer
          netto
          objektBezeichnung
          objektId
          partialAmountName
          payeeName
          payeeRechtstraegerId
          paymentMethod {
            text
            value
          }
          paymentReferenceText
          purposeOfUseText
          rechnungsDatum
          rechnungsNummer
          recipientBankDetailsIban
          recipientBankDetailsId
          retry
          sepaMandatReference
          status {
            description
            text
            value
          }
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          type
          vat
          warningList {
            message
            type
          }
        }
        page {
          number
          size
          totalPages
          totalElements
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function useIncomingInvoiceBookingCreationGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IncomingInvoiceBookingCreationGenerierlaufEntryListQuery,
    IncomingInvoiceBookingCreationGenerierlaufEntryListQueryVariables
  > &
    ({ variables: IncomingInvoiceBookingCreationGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoiceBookingCreationGenerierlaufEntryListQuery, IncomingInvoiceBookingCreationGenerierlaufEntryListQueryVariables>(
    IncomingInvoiceBookingCreationGenerierlaufEntryListDocument,
    options
  );
}
export function useIncomingInvoiceBookingCreationGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IncomingInvoiceBookingCreationGenerierlaufEntryListQuery,
    IncomingInvoiceBookingCreationGenerierlaufEntryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IncomingInvoiceBookingCreationGenerierlaufEntryListQuery,
    IncomingInvoiceBookingCreationGenerierlaufEntryListQueryVariables
  >(IncomingInvoiceBookingCreationGenerierlaufEntryListDocument, options);
}
export function useIncomingInvoiceBookingCreationGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IncomingInvoiceBookingCreationGenerierlaufEntryListQuery,
        IncomingInvoiceBookingCreationGenerierlaufEntryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IncomingInvoiceBookingCreationGenerierlaufEntryListQuery,
    IncomingInvoiceBookingCreationGenerierlaufEntryListQueryVariables
  >(IncomingInvoiceBookingCreationGenerierlaufEntryListDocument, options);
}
export type IncomingInvoiceBookingCreationGenerierlaufEntryListQueryHookResult = ReturnType<
  typeof useIncomingInvoiceBookingCreationGenerierlaufEntryListQuery
>;
export type IncomingInvoiceBookingCreationGenerierlaufEntryListLazyQueryHookResult = ReturnType<
  typeof useIncomingInvoiceBookingCreationGenerierlaufEntryListLazyQuery
>;
export type IncomingInvoiceBookingCreationGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<
  typeof useIncomingInvoiceBookingCreationGenerierlaufEntryListSuspenseQuery
>;
export type IncomingInvoiceBookingCreationGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  IncomingInvoiceBookingCreationGenerierlaufEntryListQuery,
  IncomingInvoiceBookingCreationGenerierlaufEntryListQueryVariables
>;
