import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type BestandseinheitAufteilungsschluesselWertFieldsFragment = {
  aktiv: boolean;
  beAufteilungsschluesselWertId?: string | null;
  createTs?: string | null;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  current: boolean;
  geloescht: boolean;
  validFrom?: string | null;
  wert: number;
  verwaltungSeitRelation: { text: string; value: Types.BeAufteilingsschluesselWertVerwaltungSeitRelation };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const BestandseinheitAufteilungsschluesselWertFieldsFragmentDoc = gql`
  fragment BestandseinheitAufteilungsschluesselWertFields on BeAufteilingsschluesselWert {
    aktiv
    beAufteilungsschluesselWertId
    createTs
    createdBy
    createdByMitarbeiterId
    current
    geloescht
    validFrom
    verwaltungSeitRelation {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
    wert
  }
`;
