import { isLastIndexOfArray } from '../../helpers/arrayHelper';

export const isAddNewFieldArrayInputRowAllowed = (array: Record<string, any>, index: number) =>
  array && (array.length === 0 || isLastIndexOfArray(index, array));

// only works if newFieldValue is an object FIXME: type
export const addNewFieldArrayInputRow = (arrayHelpers: Record<string, any>, array: Record<string, any>, newFieldValue: Record<string, any>) => () =>
  arrayHelpers.insert(array.length, { ...newFieldValue });

// only works if newFieldValue is an object FIXME: type
export const removeFieldArrayInputRow =
  (arrayHelpers: Record<string, any>, array: Record<string, any>, currentIndex: number, newFieldValue: Record<string, any>) => () => {
    if (array.length === 1) {
      arrayHelpers.replace(currentIndex, { ...newFieldValue });
    } else {
      arrayHelpers.remove(currentIndex);
    }
  };

export const buildFieldArrayInputRowName = (arrayName: string, index: number, arrayObjectAttribute?: string) =>
  `${arrayName}[${index}]${arrayObjectAttribute || ''}`;
