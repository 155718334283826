import React, { FC } from 'react';

import { Divider, Typography } from 'antd';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import LinkToBelegFile from '../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import AddressInnerCard from './AddressInnerCard';
import ConsolidationCriteriaCardDetailsInfoRow from '../../../ConsolidationCriteria/ConsolidationCriteriaCardDetailsInfoRow';
import VorschreibungEmailDeliveryInfoRow from '../VorschreibungEmailDelivery/VorschreibungEmailDeliveryInfoRow';
import { Auftrag } from '../../../../types';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  auftrag: Auftrag;
};

const VorschreibungDetailsInfo: FC<Props> = ({ auftrag }) => {
  let rechnungsnummerRow = <>-</>;
  if (auftrag.eigenbeleg && auftrag.eigenbeleg.fileInfo.fileId) {
    rechnungsnummerRow = <LinkToBelegFile fileId={auftrag.eigenbeleg.fileInfo.fileId} belegnummer={auftrag.eigenbeleg.belegnummer} />;
  } else if (auftrag.eigenbeleg && auftrag.eigenbeleg.belegnummer) {
    rechnungsnummerRow = <Typography.Text>{auftrag.eigenbeleg.belegnummer}</Typography.Text>;
  }

  return (
    <>
      <Typography.Title level={5}>Allgemeine Informationen</Typography.Title>
      <CardDetailsInfoRow infoRowTitle="Vorschreibungsart">
        <Typography.Text>{auftrag.auftragsart.bezeichnung}</Typography.Text>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Verrechnungsart">
        <Typography.Text>{auftrag.auftragsKopf.verrechnungsart.text}</Typography.Text>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Vertragsart">{auftrag.auftragsKopf.vertragsart.text}</CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="Kundennummer">{auftrag.auftragsKopf.empfaenger.kundennummer}</CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="Rechnungsnummer">{rechnungsnummerRow}</CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="Rechnungsdatum">
        {auftrag.eigenbeleg ? (
          <>
            <CustomFormattedDate value={auftrag.eigenbeleg.belegdatum} />
          </>
        ) : (
          <>-</>
        )}
      </CardDetailsInfoRow>
      <Divider dashed />

      <Typography.Title level={5}>Zustellung</Typography.Title>
      <AddressInnerCard address={auftrag.auftragsKopf.deliveryAddress} title="Zustelladresse" />
      <AddressInnerCard address={auftrag.billingAddress} title="Rechnungsadresse" />
      <VorschreibungEmailDeliveryInfoRow auftrag={auftrag} />

      <Divider dashed />

      <Typography.Title level={5}>Verrechnung</Typography.Title>
      <ConsolidationCriteriaCardDetailsInfoRow consolidationCriteria={auftrag.auftragsKopf.consolidationCriteria} />

      <CardDetailsInfoRow infoRowTitle="Belegform">
        <Typography.Text>{auftrag.auftragsKopf.belegform.text}</Typography.Text>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Zahlungsart">
        <Typography.Text>{auftrag.auftragsKopf.paymentMethod.text}</Typography.Text>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Fälligkeit der Vorschreibung">
        <CustomFormattedDate value={auftrag.dueDate} />
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="SEPA Creditor-ID">
        {auftrag.auftragsKopf.sepaCreditorId ? (
          <Typography.Text>{auftrag.auftragsKopf.sepaCreditorId}</Typography.Text>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        )}
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="SEPA Mandatsreferenz">
        {auftrag.auftragsKopf.sepaMandatReference ? (
          <Typography.Text>{auftrag.auftragsKopf.sepaMandatReference}</Typography.Text>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        )}
      </CardDetailsInfoRow>
    </>
  );
};

export default VorschreibungDetailsInfo;
