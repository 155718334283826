import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { generatePathToObjektDetailsPage, pathsToObjektDetailsPage } from '../../Objekt/objektUriPaths';
import {
  generatePathToBestandseinheitDetailsPage,
  generatePathToBestandseinheitDetailsPageInfoFeldMenu,
} from '../../Bestandseinheit/bestandseinheitUriPaths';
import { generatePathToBeVertragDetailsPage, generatePathToBeVertragDetailsPageInfoFeldMenu } from '../../Vertrag/BeVertrag/beVertragUriPaths';
import { getObjektInfoFeldShortName } from '../../Objekt/InfoFeld/List/objektInfoFeldListHelpers';
import { TTicket } from '../ticketHelpers';
import { getBeInfoFeldShortName } from '../../Bestandseinheit/InfoFeld/List/beInfoFeldListHelpers';
import {
  getVertragInfoFeldKuendigungsinfosShortName,
  getVertragInfoFeldWeiterePersonShortName,
  isVertragInfoFeldKuendigungsinfos,
  isVertragInfoFeldWeiterePerson,
} from '../../Vertrag/BeVertrag/InfoFeld/List/vertragInfoFeldListHelpers';

type Props = {
  ticket: TTicket;
};

const TicketObjektInfoRows: FC<Props> = ({ ticket }) => {
  const { objekt, bestandseinheit, vertrag, objektInfoFeld, beInfoFeld, vertragInfoFeld } = ticket;

  return (
    <>
      {objekt && (
        <CardDetailsInfoRow infoRowTitle="Objekt">
          <DataWithShortenedText maxTextLength={25} text={objekt.kurzBezeichnung}>
            {(shortenedText) => (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <Link target="_blank" to={generatePathToObjektDetailsPage(objekt!.objektId)}>
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        </CardDetailsInfoRow>
      )}
      {objekt && objektInfoFeld && (
        <CardDetailsInfoRow infoRowTitle={objektInfoFeld.type.text}>
          <DataWithShortenedText maxTextLength={25} text={getObjektInfoFeldShortName(objektInfoFeld)}>
            {(shortenedText) => (
              <Link target="_blank" to={pathsToObjektDetailsPage(objekt.objektId).infoFeldMenu}>
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        </CardDetailsInfoRow>
      )}
      {objekt && bestandseinheit && (
        <CardDetailsInfoRow infoRowTitle="Bestandseinheit">
          <DataWithShortenedText maxTextLength={25} text={bestandseinheit.bezeichnung}>
            {(shortenedText) => (
              <Link target="_blank" to={generatePathToBestandseinheitDetailsPage(objekt.objektId, bestandseinheit.bestandseinheitId)}>
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        </CardDetailsInfoRow>
      )}
      {objekt && bestandseinheit && beInfoFeld && (
        <CardDetailsInfoRow infoRowTitle={beInfoFeld.type.text}>
          <DataWithShortenedText maxTextLength={25} text={getBeInfoFeldShortName(beInfoFeld)}>
            {(shortenedText) => (
              <Link target="_blank" to={generatePathToBestandseinheitDetailsPageInfoFeldMenu(objekt.objektId, bestandseinheit.bestandseinheitId)}>
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        </CardDetailsInfoRow>
      )}
      {vertrag && (
        <CardDetailsInfoRow infoRowTitle="Vertrag">
          <DataWithShortenedText maxTextLength={25} text={vertrag.kurzBezeichnung}>
            {(shortenedText) => (
              <Link target="_blank" to={generatePathToBeVertragDetailsPage(vertrag.objektId, vertrag.bestandseinheitId, vertrag.vertragId)}>
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        </CardDetailsInfoRow>
      )}
      {vertrag && vertragInfoFeld && isVertragInfoFeldWeiterePerson(vertragInfoFeld) && (
        <CardDetailsInfoRow infoRowTitle={vertragInfoFeld.type.text}>
          <DataWithShortenedText maxTextLength={25} text={getVertragInfoFeldWeiterePersonShortName(vertragInfoFeld)}>
            {(shortenedText) => (
              <Link
                target="_blank"
                to={generatePathToBeVertragDetailsPageInfoFeldMenu(vertrag.objektId, vertrag.bestandseinheitId, vertrag.vertragId)}
              >
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        </CardDetailsInfoRow>
      )}
      {vertrag && vertragInfoFeld && isVertragInfoFeldKuendigungsinfos(vertragInfoFeld) && (
        <CardDetailsInfoRow infoRowTitle={vertragInfoFeld.type.text}>
          <Link target="_blank" to={generatePathToBeVertragDetailsPageInfoFeldMenu(vertrag.objektId, vertrag.bestandseinheitId, vertrag.vertragId)}>
            {getVertragInfoFeldKuendigungsinfosShortName(vertragInfoFeld)}
          </Link>
        </CardDetailsInfoRow>
      )}
    </>
  );
};

export default TicketObjektInfoRows;
