import React from 'react';
import { friendlyFormatIBAN } from 'ibantools';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { IbanFibuKontoOrderBy, IbanListEntry } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { NestedTableColProps } from '../../../../../components/Table/NestedTable/NestedTable';
import { generatePathToKontenplanPage } from '../../../../../pages/SysSettingsPage/KundenSystem/KontenplanPage/routes';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import EbicsUserListColumn from './EbicsUserListColumn';
import DetailsColumn from './DetailsColumn';
import { generatePathToBankstatementListingPage } from '../../Bankstatement/Filters/filtersQueryParams';

const ibanFibuKontoTableColumns = (): NestedTableColProps<IbanListEntry>[] => [
  {
    title: 'IBAN',
    dataIndex: IbanFibuKontoOrderBy.Iban,
    sorter: true,
    render: (text, record) => <DataWithShortenedText text={friendlyFormatIBAN(record.iban) as string} maxTextLength={20} />,
  },
  {
    title: 'BIC',
    dataIndex: IbanFibuKontoOrderBy.Bic,
    sorter: true,
    render: (text, record) => <DataWithShortenedText text={record.bic} maxTextLength={25} />,
  },
  {
    title: 'Kontoinhaber',
    dataIndex: IbanFibuKontoOrderBy.AccountHolder,
    sorter: true,
    render: (text, record) => <DataWithShortenedText text={record.accountHolder} maxTextLength={25} />,
  },
  {
    title: 'Bankname',
    dataIndex: IbanFibuKontoOrderBy.Bankname,
    sorter: true,
    render: (text, record) => <DataWithShortenedText text={record.bankname} maxTextLength={20} />,
  },
  {
    title: 'FIBU-Kontonummer',
    dataIndex: IbanFibuKontoOrderBy.FibuAccountNumber,
    sorter: true,
    render: (text, record) => <Link to={generatePathToKontenplanPage()}>{record.fibuAccountNumber}</Link>,
  },
  {
    title: 'Zahlungsverkehrsart:',
    dataIndex: IbanFibuKontoOrderBy.PaymentTransactionType,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.paymentTransactionType.text}</Typography.Text>,
  },
  {
    title: 'EBICS Teilnehmer',
    render: (text, record) => <EbicsUserListColumn ebicsUserList={record.ebicsUserList} maxTextLength={25} />,
  },
  {
    title: 'Letzter Kontoauszug',
    dataIndex: IbanFibuKontoOrderBy.LastBankStatement,
    sorter: true,
    render: (text, record) =>
      record.lastBankStatementCreatedOn && record.dataCarrierId && record.statementNumber ? (
        <Link
          target="_blank"
          to={generatePathToBankstatementListingPage({
            statementNumber: record.statementNumber,
            iban: record.iban,
            // Use unique rowKey
            highlightedRowKey: `${record.dataCarrierId}_${record.statementNumber}`,
            expandedRowKeys: { dataCarrierId: record.dataCarrierId },
          })}
        >
          <FormattedDateTime createdTs={record.lastBankStatementCreatedOn} />
        </Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Erstellt am',
    dataIndex: IbanFibuKontoOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => (record.createTs ? <FormattedDateTime createdTs={record.createTs} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Details',
    render: (text, record) => <DetailsColumn record={record} />,
  },
];

export default ibanFibuKontoTableColumns;
