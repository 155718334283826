import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import { useToggle } from '../../../hooks/useToggle';
import IndexSeriesForm from '../Form/IndexSeriesForm';

type Props = {
  onSuccess: () => void;
};

const IndexSeriesBtnAndDrawer: FC<Props> = ({ onSuccess }) => {
  const [isDrawerOpen, toggleDrawer] = useToggle();

  const onAction = () => {
    toggleDrawer();
    onSuccess();
  };

  return (
    <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
      <Button icon={<PlusOutlined />} type="primary" onClick={toggleDrawer}>
        Indexreihe anlegen
      </Button>
      <Drawer title="Indexreihe anlegen" width={720} open={isDrawerOpen} onClose={toggleDrawer} destroyOnClose>
        <IndexSeriesForm onAction={onAction} onCancel={toggleDrawer} />
      </Drawer>
    </ButtonsAligned>
  );
};

export default IndexSeriesBtnAndDrawer;
