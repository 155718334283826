import React, { FC } from 'react';
import { Button, Divider, Dropdown, MenuProps } from 'antd';
import { EllipsisOutlined, SendOutlined } from '@ant-design/icons';
import CardWithFooter from '../../../components/Card';
import VorschreibungDetailsInfo from './VorschreibungDetailsInfo/VorschreibungDetailsInfo';
import RechnungRechtstraegerCollapse from './RechnungRechtstraeger/RechnungRechtstraegerCollapse';
import Steuertabelle from './Steuertabelle/Steuertabelle';
import VorschreibungZinsliste from './VorschreibungZinsliste/VorschreibungZinsliste';
import CardTitle from '../../../components/Card/CardTitle';
import { useToggle } from '../../../hooks/useToggle';
import EigenbelegFreigebenModal from './EigenbelegFreigebenModal/EigenbelegFreigebenModal';
import { Auftrag } from '../../../types';

type Props = {
  auftrag: Auftrag;
  isLoading: boolean;
};

const VorschreibungCard: FC<Props> = ({ auftrag, isLoading }) => {
  const [isCollapsedModal, onCollapseModal] = useToggle();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Freigeben',
      onClick: onCollapseModal,
      icon: <SendOutlined />,
    },
  ];

  return (
    <CardWithFooter
      loading={isLoading}
      mitarbeiterId={auftrag.createdByMitarbeiterId}
      userId={auftrag.createdBy}
      createTs={auftrag.createTs}
      bordered={false}
      title={<CardTitle title={vorschreibungCardTitle(auftrag)} status={auftrag.status} />}
      extra={
        <>
          <Dropdown menu={{ items }}>
            <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
          </Dropdown>
          <EigenbelegFreigebenModal auftrag={auftrag} visible={isCollapsedModal} onSuccess={onCollapseModal} onCancel={onCollapseModal} />
        </>
      }
    >
      <VorschreibungDetailsInfo auftrag={auftrag} />
      <Divider dashed />
      <RechnungRechtstraegerCollapse isLoading={isLoading} auftrag={auftrag} />
      <Divider dashed />
      <VorschreibungZinsliste auftrag={auftrag} />
      <Steuertabelle auftragId={auftrag.auftragId} />
    </CardWithFooter>
  );
};

const vorschreibungCardTitle = (auftrag: Auftrag) =>
  `${auftrag.auftragsKopf.auftragsartType.text}${auftrag.storniert ? ' (storniert)' : ''}, ${auftrag.auftragsKopf.fakturierungsperiode.monat}/${
    auftrag.auftragsKopf.fakturierungsperiode.jahr
  }`;

export default VorschreibungCard;
