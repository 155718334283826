import React from 'react';
import { Formik } from 'formik';
import ListingFilters from './Filters/ListingFilters';
import EntryListingTable from './Table/EntryListingTable';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useBudgetingVPosAuftschluesselLinkageListQuery } from '../gql/budgetingVPosAuftschluesselLinkageQueries.types';
import { listingFiltersFormInitialValues, ListingFiltersFormValues } from './Filters/listingFiltersFormMapper';

const EntryListing = () => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const { data, refetch, loading } = useBudgetingVPosAuftschluesselLinkageListQuery({ variables: { kundenSystemId } });

  const entryList = data?.getBudgetingVPosAuftschluesselLinkageList.data ?? [];

  return (
    <Formik<ListingFiltersFormValues> initialValues={listingFiltersFormInitialValues} onSubmit={() => {}}>
      {(formikProps) => (
        <>
          <ListingFilters onAction={refetch} linkageEntryList={entryList} formikProps={formikProps} />
          <EntryListingTable linkageEntryList={entryList} loading={loading} formikProps={formikProps} onAction={refetch} />
        </>
      )}
    </Formik>
  );
};

export default EntryListing;
