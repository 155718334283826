import { FormFields } from '../../../../helpers/formikHelper';
import { SubAdministration, SubAdministrationUpdateInput } from '../../../../types';
import { HAUPT_TAG_TEXT } from '../../../../components/Tag/tagHelpers';

export type SubAdministrationFormValues = {
  eigentuemerBezeichnung: string;
  deliveryAddressId?: string | null;
  saldouebertrag: number | null;
};

export const subAdministrationFormFields: FormFields<SubAdministrationFormValues> = {
  eigentuemerBezeichnung: 'eigentuemerBezeichnung',
  deliveryAddressId: 'deliveryAddressId',
  saldouebertrag: 'saldouebertrag',
};

export const mapSubAdministrationToFormValues = (subAdministration: SubAdministration): SubAdministrationFormValues => {
  return {
    eigentuemerBezeichnung: subAdministration.eigentuemerBezeichnung,
    deliveryAddressId: subAdministration.deliveryAddressHaupt ? HAUPT_TAG_TEXT : subAdministration?.deliveryAddressId,
    saldouebertrag: subAdministration.saldouebertrag,
  };
};

export const mapFormValuesToSubAdministrationUpdate = (values: SubAdministrationFormValues): SubAdministrationUpdateInput => ({
  deliveryAddressHaupt: values?.deliveryAddressId === HAUPT_TAG_TEXT,
  deliveryAddressId: values?.deliveryAddressId === HAUPT_TAG_TEXT ? null : values?.deliveryAddressId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  saldouebertrag: values.saldouebertrag!,
});
