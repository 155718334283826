import React, { FC } from 'react';
import { Drawer, Dropdown, MenuProps, Modal, Space, Typography } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../hooks/useToggle';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import LinkageForm from '../../Form/LinkageForm';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import { useDeleteBudgetingAbrDefVPosLinkageMutation } from '../../gql/budgetingAbrDefVPosLinkageMutations.types';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import LinkageChangeHistoryListingTable from './LinkageChangeHistoryListingTable';
import { BudgetingAbrDefVPosLinkage } from '../../../../types';

type Props = {
  linkage: BudgetingAbrDefVPosLinkage;
  linkageEntryList: BudgetingAbrDefVPosLinkage[];
  onAction: () => void;
};

const EntryListingTableActions: FC<Props> = ({ linkage, onAction, linkageEntryList }) => {
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runDelete] = useDeleteBudgetingAbrDefVPosLinkageMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Verknüpfung`);
      onAction();
    },
    variables: {
      budgetingAbrDefVPosLinkageId: linkage.budgetingAbrDefVPosLinkageId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Bearbeiten',
      onClick: toggleIsDrawerOpen,
      icon: <EditOutlined />,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, linkage),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <Drawer width={720} title="Verknüpfung bearbeiten" onClose={toggleIsDrawerOpen} open={isDrawerOpen} destroyOnClose>
        <LinkageForm
          linkage={linkage}
          linkageEntryList={linkageEntryList}
          onCancel={toggleIsDrawerOpen}
          onSuccess={() => {
            toggleIsDrawerOpen();
            onAction();
          }}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <LinkageChangeHistoryListingTable budgetingAbrDefVPosLinkageId={linkage.budgetingAbrDefVPosLinkageId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, linkage: BudgetingAbrDefVPosLinkage) => () => {
  Modal.confirm({
    title: `Möchten Sie die Verknüpfung löschen?`,
    content: (
      <Space direction="vertical" style={{ marginTop: 20 }} size={0}>
        <Typography.Text>Für die Abrechnungsdefinition:</Typography.Text>
        <Typography.Text>{linkage.abrechnungsdefinition.bezeichnung}</Typography.Text>
      </Space>
    ),
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default EntryListingTableActions;
