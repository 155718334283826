import { FC } from 'react';
import { useAddressChangeHistorListQuery } from '../../../gql/AddressesAndContactsQueries.types';
import { HistoryType } from '../../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../../History/HistoryListingTable';

type Props = {
  addressId: string;
};

const AddressChangeHistoryListingTable: FC<Props> = ({ addressId }) => {
  const { data, loading } = useAddressChangeHistorListQuery({ variables: { addressId } });
  const historyList = data?.getAddressChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="address" />;
};

export default AddressChangeHistoryListingTable;
