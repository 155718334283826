import React, { FC } from 'react';
import { Form, Input } from 'formik-antd';
import { Formik } from 'formik';
import { Col, Divider } from 'antd';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import FormButtons from '../../../../components/Button/FormButtons';
import { EbicsBankConfig } from '../../../../types';
import { useCreateEbicsBankConfigMutation, useUpdateEbicsBankConfigMutation } from '../gql/EbicsBankConfigMutations.types';
import {
  ebicsBankConfigFormFields,
  EbicsBankConfigFormValues,
  mapEbicsBankConfigToFormValues,
  mapFormValuesToEbicsConfig,
} from './ebicsBankConfigFormMapper';
import { ebicsBankConfigValidationSchema } from './ebicsBankConfigValidationSchema';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import EbicsVersionFormSelect from '../../shared/EbicsVersionFormSelect';
import Pain001FormSelect from '../../shared/Pain001FormSelect';
import Pain008FormSelect from '../../shared/Pain008FormSelect';
import DrawerRow from '../../../../components/Drawer/DrawerRow';
import { isEbicsVersionH005 } from '../../shared/ebicsHelpers';
import EbicsVersionH005FormInputs from './EbicsVersionH005FormInputs';

type Props = {
  ebicsBankConfig?: EbicsBankConfig;
  onAction: () => void;
  onCancel: () => void;
};

const EbicsBankConfigForm: FC<Props> = ({ ebicsBankConfig, onAction, onCancel }) => {
  const isUpdate = !!ebicsBankConfig;
  const entity = 'EBICS Bank';

  const [runCreate, { loading: loadingCreate }] = useCreateEbicsBankConfigMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateEbicsBankConfigMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<EbicsBankConfigFormValues>
      initialValues={mapEbicsBankConfigToFormValues(ebicsBankConfig)}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToEbicsConfig(values);
        if (isUpdate) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          runUpdate({ variables: { ebicsBankConfigId: ebicsBankConfig!.ebicsBankConfigId, input } }).finally(() => setSubmitting(false));
        } else {
          runCreate({ variables: { input } }).finally(() => setSubmitting(false));
        }
      }}
      validationSchema={ebicsBankConfigValidationSchema}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <DrawerRow
            rowTitle="Bank"
            content={
              <FormItemWithoutColon name={ebicsBankConfigFormFields.name} label="Bankname">
                <Input size="small" name={ebicsBankConfigFormFields.name} placeholder="z.B. Erste Bank" />
              </FormItemWithoutColon>
            }
          />
          <Divider />
          <DrawerRow
            rowTitle="EBICS"
            content={
              <>
                <Col span={24}>
                  <FormItemWithoutColon name={ebicsBankConfigFormFields.hostId} label="Host ID">
                    <Input size="small" name={ebicsBankConfigFormFields.hostId} placeholder="z.B. ERSTEBANK" />
                  </FormItemWithoutColon>
                </Col>
                <Col span={24}>
                  <FormItemWithoutColon name={ebicsBankConfigFormFields.url} label="Url">
                    <Input size="small" name={ebicsBankConfigFormFields.url} placeholder="z.B. https://www.erstebank.at/ebics/EbicsServlet" />
                  </FormItemWithoutColon>
                </Col>
                <Col span={24}>
                  <FormItemWithoutColon name={ebicsBankConfigFormFields.ebicsVersion} label="EBICS Version">
                    <EbicsVersionFormSelect
                      size="small"
                      name={ebicsBankConfigFormFields.ebicsVersion}
                      placeholder="EBICS Version auswählen"
                      onChange={(version) => {
                        if (!isEbicsVersionH005(version)) {
                          formikProps.setFieldValue(ebicsBankConfigFormFields.bankStatementBtf, '');
                          formikProps.setFieldValue(ebicsBankConfigFormFields.creditTransferBtf, '');
                          formikProps.setFieldValue(ebicsBankConfigFormFields.creditTransferStatusBtf, '');
                          formikProps.setFieldValue(ebicsBankConfigFormFields.directDebitBtf, '');
                          formikProps.setFieldValue(ebicsBankConfigFormFields.directDebitStatusBtf, '');
                          formikProps.setFieldValue(ebicsBankConfigFormFields.preApprovalBtf, '');
                        }
                      }}
                    />
                  </FormItemWithoutColon>
                </Col>
                {isEbicsVersionH005(formikProps.values.ebicsVersion) && <EbicsVersionH005FormInputs />}
              </>
            }
          />
          <Divider />
          <DrawerRow
            rowTitle="Datenträger"
            content={
              <>
                <Col span={24}>
                  <FormItemWithoutColon name={ebicsBankConfigFormFields.pain001Schema} label="XML Schema Überweisung (PAIN001)">
                    <Pain001FormSelect size="small" name={ebicsBankConfigFormFields.pain001Schema} placeholder="XML Schema Überweisung auswählen" />
                  </FormItemWithoutColon>
                </Col>
                <Col span={24}>
                  <FormItemWithoutColon name={ebicsBankConfigFormFields.pain008Schema} label="XML Schema Überweisung (PAIN008)">
                    <Pain008FormSelect size="small" name={ebicsBankConfigFormFields.pain008Schema} placeholder="XML Schema Überweisung auswählen" />
                  </FormItemWithoutColon>
                </Col>
              </>
            }
          />
          <FormButtons
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            updateMode={isUpdate}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EbicsBankConfigForm;
