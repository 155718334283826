import React, { FC } from 'react';
import { Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { HeVertragListEntry } from '../../../../../types';
import RechtstraegerWithContactsTooltip from '../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

type Props = {
  record: HeVertragListEntry;
};

const HeVertragTableVertragspartnerColumn: FC<Props> = ({ record }) => {
  return (
    <Space>
      <RechtstraegerWithContactsTooltip
        rechtstraegerName={record.vertragspartner.kurzBezeichnung}
        rechtstraegerId={record.vertragspartner.rechtstraegerId}
        maxTextLength={20}
        isCurrent={record.current}
        status={record.vertragspartner.status}
      />
      {record.inaktivSeit || record.aktivIn ? (
        <Tooltip
          title={
            record.inaktivSeit
              ? `inaktiv seit ${record.inaktivSeit} ${record.inaktivSeitUnit?.text}`
              : `aktiv in ${record.aktivIn} ${record.aktivInUnit?.text}`
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      ) : null}
    </Space>
  );
};

export default HeVertragTableVertragspartnerColumn;
