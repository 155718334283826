import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { IndexSeriesFieldsFragmentDoc } from './IndexSeriesFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexSeriesListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IndexSeriesListQuery = {
  getIndexSeriesList: {
    data: Array<{
      chainingFactor?: number | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      csvColumnImportId?: string | null;
      csvColumnIndexPoint?: string | null;
      csvColumnMonthYear?: string | null;
      csvRowImportId?: string | null;
      deletable: boolean;
      firmendatenId?: string | null;
      importUrl?: string | null;
      indexSeriesId: string;
      name: string;
      updatedByMitarbeiterId?: string | null;
      indexSeriesEntryList: Array<{
        annualAverageFinal?: number | null;
        annualAveragePreliminary?: number | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        indexSeriesEntryId: string;
        updatedByMitarbeiterId?: string | null;
        year: number;
        indexPointList: Array<{
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          finalValue?: number | null;
          indexPointId: string;
          month: string;
          preliminaryValue?: number | null;
          updatedByMitarbeiterId?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      indexType: { text: string; value: Types.IndexType };
      sourceType: { text: string; value: Types.IndexSeriesSourceType };
      status?: { text: string; value: Types.IndexSeriesStatus } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IndexSeriesQueryVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['ID']['input'];
}>;

export type IndexSeriesQuery = {
  getIndexSeries: {
    data: {
      chainingFactor?: number | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      csvColumnImportId?: string | null;
      csvColumnIndexPoint?: string | null;
      csvColumnMonthYear?: string | null;
      csvRowImportId?: string | null;
      deletable: boolean;
      firmendatenId?: string | null;
      importUrl?: string | null;
      indexSeriesId: string;
      name: string;
      updatedByMitarbeiterId?: string | null;
      indexSeriesEntryList: Array<{
        annualAverageFinal?: number | null;
        annualAveragePreliminary?: number | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        indexSeriesEntryId: string;
        updatedByMitarbeiterId?: string | null;
        year: number;
        indexPointList: Array<{
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          finalValue?: number | null;
          indexPointId: string;
          month: string;
          preliminaryValue?: number | null;
          updatedByMitarbeiterId?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      indexType: { text: string; value: Types.IndexType };
      sourceType: { text: string; value: Types.IndexSeriesSourceType };
      status?: { text: string; value: Types.IndexSeriesStatus } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const IndexSeriesListDocument = gql`
  query IndexSeriesList {
    getIndexSeriesList {
      data {
        ...IndexSeriesFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${IndexSeriesFieldsFragmentDoc}
`;
export function useIndexSeriesListQuery(baseOptions?: Apollo.QueryHookOptions<IndexSeriesListQuery, IndexSeriesListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IndexSeriesListQuery, IndexSeriesListQueryVariables>(IndexSeriesListDocument, options);
}
export function useIndexSeriesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndexSeriesListQuery, IndexSeriesListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IndexSeriesListQuery, IndexSeriesListQueryVariables>(IndexSeriesListDocument, options);
}
export function useIndexSeriesListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IndexSeriesListQuery, IndexSeriesListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IndexSeriesListQuery, IndexSeriesListQueryVariables>(IndexSeriesListDocument, options);
}
export type IndexSeriesListQueryHookResult = ReturnType<typeof useIndexSeriesListQuery>;
export type IndexSeriesListLazyQueryHookResult = ReturnType<typeof useIndexSeriesListLazyQuery>;
export type IndexSeriesListSuspenseQueryHookResult = ReturnType<typeof useIndexSeriesListSuspenseQuery>;
export type IndexSeriesListQueryResult = Apollo.QueryResult<IndexSeriesListQuery, IndexSeriesListQueryVariables>;
export const IndexSeriesDocument = gql`
  query IndexSeries($indexSeriesId: ID!) {
    getIndexSeries(indexSeriesId: $indexSeriesId) {
      data {
        ...IndexSeriesFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${IndexSeriesFieldsFragmentDoc}
`;
export function useIndexSeriesQuery(
  baseOptions: Apollo.QueryHookOptions<IndexSeriesQuery, IndexSeriesQueryVariables> &
    ({ variables: IndexSeriesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IndexSeriesQuery, IndexSeriesQueryVariables>(IndexSeriesDocument, options);
}
export function useIndexSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndexSeriesQuery, IndexSeriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IndexSeriesQuery, IndexSeriesQueryVariables>(IndexSeriesDocument, options);
}
export function useIndexSeriesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IndexSeriesQuery, IndexSeriesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IndexSeriesQuery, IndexSeriesQueryVariables>(IndexSeriesDocument, options);
}
export type IndexSeriesQueryHookResult = ReturnType<typeof useIndexSeriesQuery>;
export type IndexSeriesLazyQueryHookResult = ReturnType<typeof useIndexSeriesLazyQuery>;
export type IndexSeriesSuspenseQueryHookResult = ReturnType<typeof useIndexSeriesSuspenseQuery>;
export type IndexSeriesQueryResult = Apollo.QueryResult<IndexSeriesQuery, IndexSeriesQueryVariables>;
