import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Col, Divider, Row, Typography } from 'antd';
import FormButtons from '../../../components/Button/FormButtons';
import { aufteilungsschluesselFormValidationSchema } from './aufteilungsschluesselFormValidationSchema';
import BezugsbasisSelect from '../Bezugsbasis/BezugsbasisSelect';
import MasseinheitSelect from '../Masseinheit/MasseinheitSelect';
import {
  aufteilungsschluesselFormFields,
  AufteilungsschluesselFormValues,
  mapAufteilungsschluesselToFormValues,
  mapFormValuesToAufteilungsschluessel,
} from './aufteilungsschluesselFormMapper';
import { showSuccessMsgCreate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import {
  useCreateFdAufteilungsschluesselMutation,
  useCreateKsAufteilungsschluesselMutation,
  useUpdateAufteilungsschluesselMutation,
} from '../gql/AufteilungsschluesselMutations.types';
import KSFirmendatenSelect from '../../KSFirmendatenSelect/KSFirmendatenSelect';
// @ts-ignore
import KurzBezeichnungFormPart from '../../../shared/components/KurzBezeichnung/form/KurzBezeichnungFormPart';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { Spacer } from '../../../components/Grid';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { Aufteilungsschluessel } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

type Props = {
  aufteilungsschluessel?: Aufteilungsschluessel;
  onSuccess: () => void;
  onCancel: () => void;
};

const AufteilungsschluesselForm: FC<Props> = ({ aufteilungsschluessel, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Aufteilungsschluessel'>('Aufteilungsschluessel');

  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const isUpdate = !!aufteilungsschluessel;

  const entity = `Aufteilungsschlüssel`;

  const [createForFD, { loading: loadingCreateForFD }] = useCreateFdAufteilungsschluesselMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createForKS, { loading: loadingCreateForKS }] = useCreateKsAufteilungsschluesselMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [updateAufteilungsschluessel, { loading: loadingUpdate }] = useUpdateAufteilungsschluesselMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<AufteilungsschluesselFormValues>
      initialValues={mapAufteilungsschluesselToFormValues(aufteilungsschluessel)}
      validationSchema={aufteilungsschluesselFormValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        const aufteilungsschluesselRequest = mapFormValuesToAufteilungsschluessel(formValues);
        if (!aufteilungsschluessel) {
          if (formValues.firmendatenId) {
            createForFD({ variables: { firmendatenId: formValues.firmendatenId, input: aufteilungsschluesselRequest } }).finally(() =>
              formikHelpers.setSubmitting(false)
            );
          } else {
            createForKS({ variables: { input: aufteilungsschluesselRequest, kundenSystemId } }).finally(() => formikHelpers.setSubmitting(false));
          }
        } else {
          updateAufteilungsschluessel({
            variables: { input: aufteilungsschluesselRequest, aufteilungsschluesselId: aufteilungsschluessel.aufteilungsschluesselId },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Gültig für</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithoutColon
                name={aufteilungsschluesselFormFields.firmendatenId}
                label="Firma auswählen"
                help="Aufteilungsschlüssel wird nur für diese Firma angewendet"
              >
                <KSFirmendatenSelect
                  name={aufteilungsschluesselFormFields.firmendatenId}
                  disabled={isUpdate}
                  placeholder={!isUpdate ? 'Firma' : 'Keine Firma'}
                />
              </FormItemWithoutColon>
            </Col>
          </Row>

          <Spacer height={16} />

          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Kurzinformationen</Typography.Title>
            </Col>
            <Col span={18}>
              <Row gutter={8}>
                <Col span={12}>
                  <KurzBezeichnungFormPart<AufteilungsschluesselFormValues>
                    placeholder="z.B. NFL_42"
                    formikProps={formikProps}
                    fieldHelp={getFieldHelpText('Aufteilungsschluessel.kurzBezeichnung')}
                  />
                </Col>
                <Col span={12}>
                  <FormItemWithFieldHelp
                    name={aufteilungsschluesselFormFields.bezeichnung}
                    label="Bezeichnung"
                    fieldHelp={getFieldHelpText('Aufteilungsschluessel.bezeichnung')}
                  >
                    <Input
                      id={aufteilungsschluesselFormFields.bezeichnung}
                      name={aufteilungsschluesselFormFields.bezeichnung}
                      placeholder="z.B. Aufteilungsschlüssel 42"
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider />

          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Basis</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name={aufteilungsschluesselFormFields.masseinheit}
                label="Maßeinheit"
                fieldHelp={getFieldHelpText('Aufteilungsschluessel.masseinheit')}
              >
                <MasseinheitSelect />
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp
                name={aufteilungsschluesselFormFields.bezugsbasis}
                label="Bezugsbasis"
                fieldHelp={getFieldHelpText('Aufteilungsschluessel.bezugsbasis')}
              >
                <BezugsbasisSelect
                  name={aufteilungsschluesselFormFields.bezugsbasis}
                  disabled={!!aufteilungsschluessel && !aufteilungsschluessel.bezugsbasisEditable}
                />
              </FormItemWithFieldHelp>
            </Col>
          </Row>

          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreateForFD || loadingCreateForKS || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AufteilungsschluesselForm;
