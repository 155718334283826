import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { ITimeblock } from '../../../components/Timeline/timelineCtx';
import TimelineCardContentInfoRow from '../../../components/Timeline/Card/TimelineCardContentInfoRow';
import { VorschreibungspositionVersion } from '../../../types';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  timeblock: ITimeblock<VorschreibungspositionVersion>;
};

const VorschreibungspositionVersionCardContent: FC<Props> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      <CustomFormattedDate value={timeblock.validFrom} />
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle="USt.-Kennzeichen">
      <Typography.Text>{timeblock.umsatzsteuerkennzeichen.bezeichnung}</Typography.Text>
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle="Kontierungstabelle">
      <Typography.Text>{timeblock.kontierungstabelle.bezeichnung}</Typography.Text>
    </TimelineCardContentInfoRow>
  </Space>
);

export default VorschreibungspositionVersionCardContent;
