import React, { FC } from 'react';
import { Modal } from 'antd';
import StornoForm from './StornoForm/StornoForm';
import { StornoFormValues } from './StornoForm/stornoFormMapper';
import { IncomingInvoiceFormValues } from '../incomingInvoiceFormMapper';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (stornoFormValues: StornoFormValues) => void;
  belegId: string;
  values: IncomingInvoiceFormValues;
  loading: boolean;
};

const SingleInvoiceStornoModal: FC<Props> = ({ isOpen, onCancel, onSubmit, belegId, values, loading }) => {
  return (
    <Modal title="Eingangsrechnung korrigieren" open={isOpen} onCancel={onCancel} footer={null} destroyOnClose maskClosable={false} width={976}>
      <StornoForm
        onSubmit={(stornoFormValues) => onSubmit(stornoFormValues)}
        loading={loading}
        onCancel={onCancel}
        incomingInvoiceFormValues={values}
        belegId={belegId}
      />
    </Modal>
  );
};

export default SingleInvoiceStornoModal;
