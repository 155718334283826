import React, { FC } from 'react';
import { Space } from 'antd';
import { TicketBase } from '../../../../types';
import TruncatedText from '../../../../components/Helpers/TruncatedText';
import { getObjektInfoFeldShortName } from '../../../Objekt/InfoFeld/List/objektInfoFeldListHelpers';
import { TicketBaseFieldsFragment } from '../../gql/TicketFragments.types';
import { getBeInfoFeldShortName } from '../../../Bestandseinheit/InfoFeld/List/beInfoFeldListHelpers';
import { getVertragInfoFeldShortName } from '../../../Vertrag/BeVertrag/InfoFeld/List/vertragInfoFeldListHelpers';
import { getRechtstraegerInfoFeldShortName } from '../../../Rechtstraeger/InfoFeld/List/rechtstraegerInfoFeldListHelpers';

type Props = {
  ticket: TicketBase;
};

const TicketPreviewContent: FC<Props> = ({ ticket }) => {
  const { description, objekt, objektInfoFeld, bestandseinheit, beInfoFeld, vertrag, vertragInfoFeld, rechtstraeger, rechtstraegerInfoFeld } = ticket;
  const descriptionText = description && description.length > 100 ? `${description.substring(0, 100)} ...` : description;
  return (
    <Space direction="vertical">
      <Space direction="vertical" size={0}>
        {objekt && <TruncatedText text={objekt.kurzBezeichnung} />}
        {objektInfoFeld && (
          <TruncatedText
            text={
              <>
                {objektInfoFeld.type.text}: {getObjektInfoFeldShortName(objektInfoFeld as NonNullable<TicketBaseFieldsFragment['objektInfoFeld']>)}
              </>
            }
          />
        )}
        {bestandseinheit && <TruncatedText text={bestandseinheit.bezeichnung} />}
        {beInfoFeld && (
          <TruncatedText
            text={
              <>
                {beInfoFeld.type.text}: {getBeInfoFeldShortName(beInfoFeld as NonNullable<TicketBaseFieldsFragment['beInfoFeld']>)}
              </>
            }
          />
        )}
        {vertrag && <TruncatedText text={vertrag.kurzBezeichnung} />}
        {vertragInfoFeld && (
          <TruncatedText
            text={
              <>
                {vertragInfoFeld.type.text}:{' '}
                {getVertragInfoFeldShortName(vertragInfoFeld as NonNullable<TicketBaseFieldsFragment['vertragInfoFeld']>)}
              </>
            }
          />
        )}
        {rechtstraeger && <TruncatedText text={rechtstraeger.kurzBezeichnung} />}
        {rechtstraegerInfoFeld && (
          <TruncatedText
            text={
              <>
                {rechtstraegerInfoFeld.type.text}:{' '}
                {getRechtstraegerInfoFeldShortName(rechtstraegerInfoFeld as NonNullable<TicketBaseFieldsFragment['rechtstraegerInfoFeld']>)}
              </>
            }
          />
        )}
      </Space>
      {descriptionText && <div style={{ whiteSpace: 'pre-line' }}>{descriptionText}</div>}
    </Space>
  );
};

export default TicketPreviewContent;
