import React, { FC } from 'react';
import { FormikProps } from 'formik';
import FormItemWithoutColon from '../../../../../../components/Form/FormItemWithoutColon';
import { zahlungsregelFormFields, ZahlungsregelFormValues } from '../zahlungsregelFormMapper';
import ZahlungsregelProzentsatzInput from '../ZahlungsregelProzentsatzInput';

type Props = {
  objektId: string;
  formikProps: FormikProps<ZahlungsregelFormValues>;
};

const ProzenzsatzGesamtVSFormItem: FC<Props> = ({ formikProps, objektId }) => {
  return (
    <FormItemWithoutColon name={zahlungsregelFormFields.prozentsatz} label="Prozentsatz der Gesamt-Vorschreibung">
      <ZahlungsregelProzentsatzInput name={zahlungsregelFormFields.prozentsatz} objektId={objektId} prozentsatz={formikProps.values.prozentsatz} />
    </FormItemWithoutColon>
  );
};

export default ProzenzsatzGesamtVSFormItem;
