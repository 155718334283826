import React, { FC, useState } from 'react';
import { Button, Space, Typography } from 'antd';
import { FieldArray, FormikProps } from 'formik';
import { BookingSuggestion } from '../../../../types';
import BuchungszeileDetailsCardsBlock from './KontoDetailsCards/BuchungszeileDetailsCardsBlock';
import BuchungszeileTable from './Table/BuchungszeileTable';
import { Spacer } from '../../../../components/Grid';
import {
  bookingSuggestionFormFields,
  BookingSuggestionFormValues,
  bSBuchungsanweisungFormFields,
  BSBuchungszeileForTable,
} from '../Form/buchungsmaskeFormMapper';
import { handleAddBuchungszeile } from './Table/buchungszeileHelpers';

type Props = {
  bookingSuggestion: BookingSuggestion;
  formikProps: FormikProps<BookingSuggestionFormValues>;
  isUpdate: boolean;
};

const BuchungBlock: FC<Props> = ({ bookingSuggestion, formikProps, isUpdate }) => {
  const { buchungsanweisung } = bookingSuggestion;
  // There is always only one buchungszeile that is not editable
  const buchungszeileOriginal = buchungsanweisung.buchungszeileList.filter((buchungszeile) => !buchungszeile.editable)[0];

  const [selectedRow, setSelectedRow] = useState<BSBuchungszeileForTable>();

  const arrayFieldName = `${bookingSuggestionFormFields.buchungsanweisung}.${bSBuchungsanweisungFormFields.buchungszeileList}`;

  return (
    <FieldArray
      name={arrayFieldName}
      render={(arrayHelpers) => {
        return (
          <>
            {isUpdate && (
              <>
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Typography.Title level={5}>Buchung</Typography.Title>
                  <Button type="primary" onClick={() => handleAddBuchungszeile(arrayHelpers, arrayFieldName, buchungszeileOriginal, formikProps)}>
                    Neue Buchungszeile
                  </Button>
                </Space>
                <Spacer height={16} />
              </>
            )}
            {/*  Table */}
            <BuchungszeileTable
              bookingSuggestion={bookingSuggestion}
              buchungszeileList={formikProps.values.buchungsanweisung.buchungszeileList}
              formikProps={formikProps}
              onRowDelete={(index) => arrayHelpers.remove(index)}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              fieldNamePrefix={arrayFieldName}
            />
            {/* Soll-Haben Cards */}
            {selectedRow && <BuchungszeileDetailsCardsBlock selectedRow={selectedRow} />}
          </>
        );
      }}
    />
  );
};

export default BuchungBlock;
