import React, { FC } from 'react';
import { ColumnProps } from 'antd/es/table';
import { Empty, Table, Typography } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { BudgetingResultVertragVPosWertWarning, BudgetingResultWarningTuple, VertragsartTuple } from '../../../../../types';
import vertragVPosWerWarningTableColumns from './vertragVPosWerWarningTableColumns';
import { Spacer } from '../../../../../components/Grid';
import theme from '../../../../../theme';

export type VertragVPosWertWarning = {
  bestandseinheitBezeichnung: string;
  bestandseinheitId: string;
  rechtstraegerId: string;
  vertragsArt: VertragsartTuple;
  vertragspartnerKurzBezeichnung: string;
  vPosBezeichnung: string;
  warning?: BudgetingResultVertragVPosWertWarning | null;
};

type Props = {
  warningList: BudgetingResultWarningTuple[];
  vertragVposWertWarningList: VertragVPosWertWarning[];
  loading: boolean;
};

const BudgetingResultWarnings: FC<Props> = ({ warningList, vertragVposWertWarningList, loading }) => (
  <>
    <Spacer />
    <Typography.Title level={5}>
      <WarningTwoTone twoToneColor={theme.colors.warning} /> Einige Vertragswerte konnten nicht erstellt werden
    </Typography.Title>
    {warningList.length > 0 && (
      <>
        <Spacer />
        <Table<BudgetingResultWarningTuple>
          size="small"
          loading={loading}
          dataSource={warningList}
          columns={warningTableColumns}
          pagination={false}
          locale={{
            emptyText: <Empty description={<span>Keine Warnung vorhanden</span>} />,
          }}
        />
      </>
    )}
    {vertragVposWertWarningList.length > 0 && (
      <>
        <Spacer />
        <Table<VertragVPosWertWarning>
          size="small"
          loading={loading}
          dataSource={vertragVposWertWarningList}
          columns={vertragVPosWerWarningTableColumns}
          pagination={false}
          rowKey={(record) => `${record.bestandseinheitId}-${record.rechtstraegerId}-${record.warning}`}
          locale={{
            emptyText: <Empty description={<span>Keine Warnung vorhanden</span>} />,
          }}
        />
      </>
    )}
  </>
);

const warningTableColumns: ColumnProps<BudgetingResultWarningTuple>[] = [
  {
    title: 'Warnung',
    render: (_, record) => <Typography.Text>{record.text}</Typography.Text>,
  },
];

export default BudgetingResultWarnings;
