import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { showSuccessMsgUpdate } from '../../../../components/message';
import RechtstraegerBankDetailsSelect from '../../../BankDetails/Select/RechtstraegerBankDetailsSelect';
import FormButtons from '../../../../components/Button/FormButtons';
import { BelegFormArt } from '../../../../types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useUpdateBeVertragMutation } from '../gql/BeVertragMutations.types';
import {
  mapVertragPaymentDetailsFormValuesToVertragUpdate,
  mapVertragToVertragPaymentDetailsFormValues,
  vertragPaymentDetailsFormFields,
  VertragPaymentDetailsFormValues,
} from './vertragPaymentDetailsFormMapper';
import { vertragPaymentDetailsFormValidationSchema } from './vertragPaymentDetailsFormValidationSchema';
import { BeVertragFragment } from '../gql/BeVertragFragments.types';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertrag: BeVertragFragment;
  onSuccess: () => void;
  onCancel: () => void;
};

const VertragPaymentDetailsForm: FC<Props> = ({ onCancel, onSuccess, vertrag, objektId, bestandseinheitId }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeVertrag'>('BeVertrag');

  const entity = `Vertrag`;

  const [runUpdate, { loading: loadingUpdate }] = useUpdateBeVertragMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<VertragPaymentDetailsFormValues>
      initialValues={mapVertragToVertragPaymentDetailsFormValues(vertrag)}
      validationSchema={vertragPaymentDetailsFormValidationSchema}
      onSubmit={(values, formikHelpers) => {
        runUpdate({
          variables: {
            objektId,
            bestandseinheitId,
            vertragId: vertrag.vertragId,
            vertragInput: mapVertragPaymentDetailsFormValuesToVertragUpdate(values, vertrag),
          },
        }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          {vertrag.consolidationCriteriaEditable && (
            <FormItemWithFieldHelp
              name={vertragPaymentDetailsFormFields.consolidationCriteria}
              label="Zusammenfassungskriterium"
              fieldHelp={getFieldHelpText('BeVertrag.consolidationCriteria')}
            >
              <Input
                id={vertragPaymentDetailsFormFields.consolidationCriteria}
                name={vertragPaymentDetailsFormFields.consolidationCriteria}
                size="small"
              />
            </FormItemWithFieldHelp>
          )}
          <FormItemWithFieldHelp
            name={vertragPaymentDetailsFormFields.bankDetailsId}
            label="Bankverbindung"
            fieldHelp={getFieldHelpText('BeVertrag.bankDetailsId')}
          >
            <RechtstraegerBankDetailsSelect
              name={vertragPaymentDetailsFormFields.bankDetailsId}
              rechtstraegerId={vertrag.vertragspartner.rechtstraegerId}
              onNewItemCreateSelected={() => formikProps.setFieldValue(vertragPaymentDetailsFormFields.bankDetailsId, '')}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={vertragPaymentDetailsFormFields.belegform} label="Belegform">
            <Select
              id={vertragPaymentDetailsFormFields.belegform}
              name={vertragPaymentDetailsFormFields.belegform}
              placeholder="Belegform auswählen"
              size="small"
            >
              <Select.Option value={BelegFormArt.BruttoNettoKombination}>Brutto Netto Kombination</Select.Option>
            </Select>
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={vertragPaymentDetailsFormFields.vertragsart}
            label="Vertragsart"
            fieldHelp={getFieldHelpText('BeVertrag.vertragsart')}
          >
            <Input name={vertragPaymentDetailsFormFields.vertragsart} disabled />
          </FormItemWithFieldHelp>

          <FormButtons isOkLoading={loadingUpdate} isOkDisabled={formikProps.isSubmitting} onCancel={onCancel} updateMode />
        </Form>
      )}
    </Formik>
  );
};

export default VertragPaymentDetailsForm;
