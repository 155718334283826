import { FormFields } from '../../../../../helpers/formikHelper';
import { TVstKuerzungVerarbeitungQueryParams } from './filtersQueryParams';

export type VstKuerzungVerarbeitungEntryFiltersFormValues = {
  objektId?: string;
  buchungStatus?: string[];
};

export const vstKuerzungVerarbEntryListingFiltersFormFields: FormFields<VstKuerzungVerarbeitungEntryFiltersFormValues> = {
  objektId: 'objektId',
  buchungStatus: 'buchungStatus',
};

export const vstKuerzungVerarbEntryListingFiltersFormInitialValues = (
  queryParams: TVstKuerzungVerarbeitungQueryParams
): VstKuerzungVerarbeitungEntryFiltersFormValues => ({
  objektId: queryParams.objektId,
  buchungStatus: queryParams.buchungStatus ?? [],
});

export const mapFormValuesToVstKuerzungVerarbeitungFilters = (
  formValues: VstKuerzungVerarbeitungEntryFiltersFormValues
): TVstKuerzungVerarbeitungQueryParams => ({
  objektId: formValues.objektId,
  buchungStatus: formValues.buchungStatus,
});
