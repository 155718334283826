import React, { FC } from 'react';
import { Button, Popconfirm, Tooltip, Typography } from 'antd';
import { Comment } from '@ant-design/compatible';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useDeleteTicketCommentMutation } from '../../gql/TicketMutations.types';
import MitarbeiterOrUserName from '../../../../components/Card/MitarbeiterOrUserName';
import { MitarbeiterTooltip } from '../../../../components/Card';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { TicketCommentFieldsFragment } from '../../gql/TicketFragments.types';
import { useAuthorizedMitarbeiter } from '../../../../shared/Auth/useAuthorized';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { TicketBase } from '../../../../types';
import { isTicketArchived } from '../../ticketHelpers';

type Props = {
  ticket: TicketBase;
  comment: TicketCommentFieldsFragment;
  onDelete: () => void;
  onUpdate: (comment: TicketCommentFieldsFragment) => void;
};

const TicketComment: FC<Props> = ({ ticket, comment, onDelete, onUpdate }) => {
  const { user, mitarbeiter } = useAuthorizedMitarbeiter();

  const [runDelete, { loading: loadingDelete }] = useDeleteTicketCommentMutation({
    variables: { ticketId: ticket.ticketId, ticketCommentId: comment.ticketCommentId },
    onCompleted: onDelete,
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isArchived = isTicketArchived(ticket);

  const actions =
    !isArchived && isSameMitarbeiterOrUser(user.username, mitarbeiter.mitarbeiterId, comment)
      ? [
          <Tooltip title="Bearbeiten">
            <Button type="link" icon={<EditOutlined />} onClick={() => onUpdate(comment)} />
          </Tooltip>,
          <Popconfirm title="Sind Sie sicher?" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => runDelete()}>
            <Tooltip title="Löschen">
              <Button type="link" icon={<DeleteOutlined />} loading={loadingDelete} />
            </Tooltip>
          </Popconfirm>,
        ]
      : [];

  return (
    <Comment
      author={<MitarbeiterOrUserName userId={comment.createdBy} mitarbeiterId={comment.createdByMitarbeiterId} />}
      avatar={<MitarbeiterTooltip showsInitials userId={comment.createdBy} mitarbeiterId={comment.createdByMitarbeiterId} alignment="right" />}
      content={<Typography.Text style={{ whiteSpace: 'pre-line' }}>{comment.text}</Typography.Text>}
      datetime={<Tooltip title={<FormattedDateTime createdTs={comment.createTs} />}>{dayjsCustom(comment.createTs).fromNow()}</Tooltip>}
      actions={actions}
    />
  );
};

const isSameMitarbeiterOrUser = (userId: string, mitarbeiterId: string, comment: TicketCommentFieldsFragment) => {
  return comment.createdByMitarbeiterId === mitarbeiterId && comment.createdBy === userId;
};

export default TicketComment;
