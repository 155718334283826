import React, { FC } from 'react';
import { Space, Switch, Typography } from 'antd';
import { FormikProps } from 'formik';
import { SelectProps } from 'formik-antd';
import { kontoFormFields, KontoFormValues } from '../../kontoFormMapper';
import {
  isKontoVerwendungAllgemeineForderung,
  isKontoVerwendungBank,
  isKontoVerwendungDebitor,
  isKontoVerwendungKassa,
  isKontoVerwendungKreditor,
  isKontoVerwendungVorsteuer,
  isLieferverbindlichkeitAndPersonenkonto,
  isLieferverbindlichkeitAndSachkonto,
  isUmlaufVermoegenAndPersonenkonto,
  isUmlaufVermoegenAndSachkonto,
} from '../../kontoFormHelpers';
import KontoVerwendungSelect from './KontoVerwendungSelect';
import { KontoVerwendung } from '../../../../../types';
import EditableOrDisabledDrawerField from '../../EditableOrDisabledDrawerField';
import { useKontoVerwendungListQuery } from '../../../gql/KontoVerwendungQueries.types';

type Props = {
  name: string;
  disabled: boolean;
  formikProps: FormikProps<KontoFormValues>;
} & SelectProps;

const KontoFormVerwendung: FC<Props> = ({ name, disabled, formikProps, ...restProps }) => {
  const { loading, data } = useKontoVerwendungListQuery();

  const kontoVerwendungList = data?.getKontoVerwendungList?.data || [];

  let content;

  if (isUmlaufVermoegenAndPersonenkonto(formikProps.values.klasse, formikProps.values.type)) {
    const debitor = kontoVerwendungList.find((kontoVerwendung) => isKontoVerwendungDebitor(kontoVerwendung.value));
    formikProps.values.verwendung = debitor?.value;
    content = (
      <Typography.Text id={name} disabled={disabled}>
        {debitor?.text}
      </Typography.Text>
    );
  }
  if (isLieferverbindlichkeitAndPersonenkonto(formikProps.values.klasse, formikProps.values.type)) {
    const kreditor = kontoVerwendungList.find((kontoVerwendung) => isKontoVerwendungKreditor(kontoVerwendung.value));
    formikProps.values.verwendung = kreditor?.value;
    content = (
      <Typography.Text id={name} disabled={disabled}>
        {kreditor?.text}
      </Typography.Text>
    );
  }

  if (isUmlaufVermoegenAndSachkonto(formikProps.values.klasse, formikProps.values.type)) {
    const verwendungList = kontoVerwendungList.filter(
      (kontoVerwendung) =>
        isKontoVerwendungBank(kontoVerwendung.value) ||
        isKontoVerwendungKassa(kontoVerwendung.value) ||
        isKontoVerwendungVorsteuer(kontoVerwendung.value) ||
        isKontoVerwendungAllgemeineForderung(kontoVerwendung.value)
    );

    content = <KontoVerwendungSelect {...restProps} name={name} disabled={disabled} loading={loading} kontoVerwendungList={verwendungList} />;
  }

  if (isLieferverbindlichkeitAndSachkonto(formikProps.values.klasse, formikProps.values.type)) {
    content = (
      <Space>
        <Typography.Text>Umsatzsteuerkonto</Typography.Text>
        <Switch
          size="small"
          defaultChecked={formikProps.values.verwendung === KontoVerwendung.Umsatzsteuer}
          disabled={disabled}
          onChange={(checked) =>
            checked
              ? formikProps.setFieldValue(kontoFormFields.verwendung, KontoVerwendung.Umsatzsteuer)
              : formikProps.setFieldValue(kontoFormFields.verwendung, undefined)
          }
        />
      </Space>
    );
  }

  return <EditableOrDisabledDrawerField isDisabled={disabled}>{content}</EditableOrDisabledDrawerField>;
};

export default KontoFormVerwendung;
