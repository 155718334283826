import React from 'react';
import { Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../components/Card';
import { TicketTemplate } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import TicketTemplateTemplateTableActions from './TicketTemplateTemplateTableActions';

const ticketTemplateTemplateSubTicketTableColumns = (
  ticketTemplateList: TicketTemplate[],
  parentTicketTemplate?: TicketTemplate
): TableWithColSelectorColumnProps<TicketTemplate>[] => [
  { key: 'sort', defaultSelected: true, width: 15 },
  {
    title: 'Bezeichnung',
    defaultSortOrder: 'ascend',
    defaultSelected: true,
    width: 200,
    render: (text, record) => <DataWithShortenedText text={record.name} maxTextLength={25} />,
  },
  {
    title: 'Priorität',
    defaultSelected: true,
    width: 100,
    render: (text, record) => <Typography.Text>{record.priority.text}</Typography.Text>,
  },
  {
    title: 'Fälligkeit',
    defaultSelected: true,
    width: 150,
    render: (text, record) => <Typography.Text>{record.dueDate ? record.dueDateText : <TextForEmptyValue textToShow="minus" />}</Typography.Text>,
  },
  {
    title: 'Erinnerung',
    defaultSelected: true,
    width: 150,
    render: (text, record) => <Typography.Text>{record.reminder ? record.reminderText : <TextForEmptyValue textToShow="minus" />}</Typography.Text>,
  },
  {
    title: 'Erstellt am',
    width: 150,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    width: 80,
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    width: 100,
    key: 'action',
    render: (text, record) => (
      <TicketTemplateTemplateTableActions
        ticketTemplate={record}
        ticketTemplateList={ticketTemplateList}
        parentTicketTemplate={parentTicketTemplate}
      />
    ),
  },
];

export default ticketTemplateTemplateSubTicketTableColumns;
