import { FormFields } from '../../../../../helpers/formikHelper';
import { TUstVomAufwandVerarbeitungQueryParams } from './filtersQueryParams';

export type UstVomAufwandVerarbeitungEntryFiltersFormValues = {
  objektId?: string;
  buchungStatus?: string[];
};

export const ustVomAufwandVerarbEntryListingFiltersFormFields: FormFields<UstVomAufwandVerarbeitungEntryFiltersFormValues> = {
  objektId: 'objektId',
  buchungStatus: 'buchungStatus',
};

export const ustVomAufwandVerarbEntryListingFiltersFormInitialValues = (
  queryParams: TUstVomAufwandVerarbeitungQueryParams
): UstVomAufwandVerarbeitungEntryFiltersFormValues => ({
  objektId: queryParams.objektId,
  buchungStatus: queryParams.buchungStatus ?? [],
});

export const mapFormValuesToTopAbrVerarbeitungFilters = (
  formValues: UstVomAufwandVerarbeitungEntryFiltersFormValues
): TUstVomAufwandVerarbeitungQueryParams => ({
  objektId: formValues.objektId,
  buchungStatus: formValues.buchungStatus,
});
