import React from 'react';
import { Link } from 'react-router-dom';

import { Tag, Typography } from 'antd';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import GenerierlaufEntryStatus from '../../shared/GenerierlaufEntryStatus';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { compareTwoNumbersForSorting } from '../../../../helpers/numberHelper';
import { EuroAmount } from '../../../../components/Number';
import { generatePathToVorsteuerkuerzungDetailsPage } from '../../../Vorsteuerkuerzung/vorsteuerkuerzungUriPaths';
import { compareTwoDatesForSorting } from '../../../../helpers/dateHelper';
import DocumentStatusTag, { mapBuchungStatusToTag } from '../../../../components/Status/DocumentStatus/DocumentStatusTag';
import { isVstKuerzungBuchungStatusNichtVerbucht, StatusEnum } from '../../../../helpers/statusHelper';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { VorsteuerkuerzungGenerierlaufEntry } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

const textWithNaValue = <TextForEmptyValue textToShow="NV" />;

const vstKuerzungVerarbeitungEntryTableColumns: TableWithColSelectorColumnProps<VorsteuerkuerzungGenerierlaufEntry>[] = [
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Vertragsart',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.vertragsart.text, b.vertragsart.text),
    render: (text, record) => <Typography.Text>{record.vertragsart.text}</Typography.Text>,
  },
  {
    title: 'Monat',
    defaultSelected: true,
    sorter: (a, b) => compareTwoDatesForSorting(a.monat, b.monat),
    render: (text, record) => <CustomFormattedDate year="numeric" month="2-digit" value={record.monat} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.objekt.kurzBezeichnung, b.objekt.kurzBezeichnung),
    render: (text, record) => <DataWithShortenedText text={record.objekt.kurzBezeichnung} maxTextLength={30} />,
  },
  {
    title: 'ER Netto Summe',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.vorsteuerkuerzung?.sumBetrag, b.vorsteuerkuerzung?.sumBetrag),
    render: (text, record) => (record.vorsteuerkuerzung ? <EuroAmount value={record.vorsteuerkuerzung.sumBetrag} /> : textWithNaValue),
  },
  {
    title: 'Vst.Kürzung Summe',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.vorsteuerkuerzung?.sumVstKuerzung, b.vorsteuerkuerzung?.sumVstKuerzung),
    render: (text, record) => (record.vorsteuerkuerzung ? <EuroAmount value={record.vorsteuerkuerzung.sumVstKuerzung} /> : textWithNaValue),
  },
  {
    title: 'Vorsteuerkürzung-Status',
    defaultSelected: true,
    width: 168,
    render: (text, record) => {
      if (record.vorsteuerkuerzung) {
        return isVstKuerzungBuchungStatusNichtVerbucht(record.vorsteuerkuerzung.buchungStatus) ? (
          <Tag color={mapBuchungStatusToTag(StatusEnum.Erstellt)}>{StatusEnum.Erstellt}</Tag>
        ) : (
          <DocumentStatusTag documentStatus={record.vorsteuerkuerzung.buchungStatus} />
        );
      } else return textWithNaValue;
    },
  },

  {
    title: 'Erstellt am',
    render: (text, record) => (record.vorsteuerkuerzung ? <FormattedDateTime createdTs={record.vorsteuerkuerzung.createTs} /> : textWithNaValue),
  },
  {
    title: 'Ersteller',
    render: (text, record) =>
      record.vorsteuerkuerzung ? (
        <MitarbeiterTooltip
          mitarbeiterId={record.vorsteuerkuerzung.createdByMitarbeiterId}
          userId={record.vorsteuerkuerzung.createdBy}
          alignment="left"
          showsInitials
        />
      ) : (
        textWithNaValue
      ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) =>
      record.vorsteuerkuerzung ? (
        <Link to={generatePathToVorsteuerkuerzungDetailsPage(record.objekt.objektId, record.vorsteuerkuerzung.vorsteuerkuerzungId)} target="_blank">
          Details
        </Link>
      ) : (
        <TextForEmptyValue textToShow="none" />
      ),
  },
];

export default vstKuerzungVerarbeitungEntryTableColumns;
