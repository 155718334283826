import React, { FC } from 'react';
import HistoryListingTable from '../../../../History/HistoryListingTable';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import { useRepFondsErloesKontenZuweisungDeletionHistoryListQuery } from '../gql/RechnungsAusstellerQueries.types';

type Props = {
  rechtstraegerId: string;
};

const RepFondsKontenZuweisungDeletionHistoryListingTable: FC<Props> = ({ rechtstraegerId }) => {
  const { data, loading } = useRepFondsErloesKontenZuweisungDeletionHistoryListQuery({ variables: { rechtstraegerId } });
  const historyList = data?.getRepFondsErloesKontenZuweisungDeletionHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Deletion} historyList={historyList} loading={loading} filterIdPrefix="rep-fonds-konten-zuweisung" />
  );
};

export default RepFondsKontenZuweisungDeletionHistoryListingTable;
