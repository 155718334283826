import React, { FC } from 'react';
import { Divider, Typography } from 'antd';
import { useCreateVertragTicketMutation } from '../../../Ticket/gql/TicketMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { mapFormValuesToTicketCreateInput, TicketFormValues } from '../../../Ticket/Form/ticketFormMapper';
import TicketActionButtonsAndDrawer from '../../../Ticket/TicketActionButtonsAndDrawer';
import TicketListing from '../../../Ticket/Listing/TicketListing';
import TicketListingFilters from '../../../Ticket/Listing/TicketListingFilters';
import ticketTableColumns from '../../../Ticket/Listing/Table/ticketTableColumns';
import ticketTableObjektColumns from '../../../Ticket/Listing/Table/ticketTableObjektColumns';
import ticketTableInfoFeldColumn from '../../../Ticket/Listing/Table/ticketTableInfoFeldColumn';
import { gqlOperations } from '../../../../graphql/gqlNamedOperations-generated';
import TicketListingCalendar from '../../../Ticket/Listing/TicketListingCalendar';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
};

const BeVertragTicketListing: FC<Props> = ({ objektId, bestandseinheitId, vertragId }) => {
  const [runCreate, { loading: loadingCreate }] = useCreateVertragTicketMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSubmitCreate = async (values: TicketFormValues) => {
    const response = await runCreate({
      variables: { objektId, bestandseinheitId, vertragId, input: mapFormValuesToTicketCreateInput(values) },
      refetchQueries: () => [gqlOperations.Query.TicketList],
    });
    return response.data?.createVertragTicket.data;
  };

  return (
    <TicketListing
      tableFilterIdentifier="be-vertrag-ticket"
      objektId={objektId}
      bestandseinheitId={bestandseinheitId}
      vertragId={vertragId}
      tableColumns={(onAction) => ticketTableColumns(onAction, [...ticketTableObjektColumns(), ticketTableInfoFeldColumn(true)])}
      bottom={(formikProps) => (
        <>
          <Divider />
          <Typography.Title level={4} style={{ marginTop: 16 }}>
            Kalender
          </Typography.Title>
          <TicketListingCalendar formikProps={formikProps} objektId={objektId} bestandseinheitId={bestandseinheitId} vertragId={vertragId} />
        </>
      )}
    >
      {({ formikProps, refetch, queryParams }) => (
        <TicketListingFilters
          formikProps={formikProps}
          queryParams={queryParams}
          actionButtons={
            <TicketActionButtonsAndDrawer
              refetch={refetch}
              allowCreate
              loadingCreate={loadingCreate}
              onFormSubmitCreate={onSubmitCreate}
              formikProps={formikProps}
            />
          }
        />
      )}
    </TicketListing>
  );
};

export default BeVertragTicketListing;
