import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from '../../../hooks/useToggle';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { TKontenplanQueryParams } from '../../../pages/SysSettingsPage/KundenSystem/KontenplanPage/routes';
import KontenrahmenActionButtonsAndDrawer from './KontenrahmenActionButtonsAndDrawer';
import KontoTemplateTable from './Table/KontoTemplateTable';
import { useKontoTemplateListQuery } from '../gql/KontenrahmenQueries.types';
import { updateKontenrahmenQueryParams } from '../../../pages/AndromedaSystemSettingsPage/KundenSystem/KontenrahmenPage/routes';
import KontoListingFilters from '../../Kontenplan/Listing/KontoListingFilters';

const KontoTemplateListing = () => {
  const [showArchived, toggleShowArchived] = useToggle();
  const navigate = useNavigate();
  const queryParams = useQueryParams<TKontenplanQueryParams>();

  const { data, loading, refetch } = useKontoTemplateListQuery({
    variables: {
      includeArchiviert: showArchived,
      klasseList: queryParams.klasse ? [queryParams.klasse] : undefined,
      type: queryParams.type?.length === 1 ? queryParams.type[0] : undefined,
      verwendung: queryParams.verwendung,
    },
  });

  const kontoList = data?.getKontoTemplateList.data;

  return (
    <>
      <KontoListingFilters
        actionButtons={<KontenrahmenActionButtonsAndDrawer refetch={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />}
        filters={queryParams}
        onChange={(filters) => {
          updateKontenrahmenQueryParams(navigate, filters);
        }}
      />
      <KontoTemplateTable kontoList={kontoList} loading={loading} refetch={refetch} />
    </>
  );
};

export default KontoTemplateListing;
