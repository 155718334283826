import React, { FC, useState } from 'react';
import { Flex } from 'rebass';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Space, Tabs, TabsProps, Typography } from 'antd';
import { Spacer } from '../../../../components/Grid';
import { mapTextbausteinListToFormValues, TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { AbrechnungsdefinitionType } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import AbrDefTemplateVersionViewAbrKreisList from './AbrDefTemplateVersionViewAbrKreisList';
import AbrDefTemplateKontenzuordnung from '../../shared/AbrDefTemplateKontenzuordnung';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import { generatePathToAbrechnungsdefinitionTemplateVersionUpdatePage } from '../../abrDefTemplateUriPaths';
import { SubAdministrationAbrechnungsdefinitionVersionFieldsFragment } from '../../../Abrechnungsdefinition/gql/SubAdministrationAbrDef/SubAdministrationAbrDefVersionFragments.types';
import SubAbrDefTemplateVersionViewTabWithTemplate from '../../shared/Templates/SubTemplates/SubAbrDefTemplateVersionViewTabWithTemplate';
import SubAdministrationAbrechnungObjektTemplate from '../../../../pages/PDFTemplates/templates/subAdministrationAbrechnung/Objekt/SubAdministrationAbrechnungObjektTemplate';
import {
  createSubAdministrationAbrechnungPDFTemplateData,
  mapTextbausteinToSubAdministrationAbrechnungSelectableSection,
} from '../../../Abrechnungsdefinition/shared/Templates/SubTemplates/subAbrDefTemplateFormMapper';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersion: SubAdministrationAbrechnungsdefinitionVersionFieldsFragment;
  isHistoricized: boolean;
};

const SubAbrDefTemplateVersionView: FC<Props> = ({ abrechnungsdefinitionId, abrechnungsdefinitionVersion, isHistoricized }) => {
  const navigate = useNavigate();
  const [subAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(abrechnungsdefinitionVersion.subAdministrationAbrechnungTextbausteinList)
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Abrechnungskreise',
      children: (
        <>
          <Spacer height={16} />
          <AbrDefTemplateVersionViewAbrKreisList
            abrechdefAbrechkreisList={abrechnungsdefinitionVersion.abrechdefAbrechkreisList}
            abrDefType={AbrechnungsdefinitionType.SubAbrechnung}
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Subverwaltungsabrechnung',
      children: (
        <SubAbrDefTemplateVersionViewTabWithTemplate
          outputOptions={abrechnungsdefinitionVersion.outputOptions}
          subAbrDefTemplateValues={subAbrDefTemplateValues}
          renderTemplatePreview={({ selectedTextbaustein }) => (
            <SubAdministrationAbrechnungObjektTemplate
              data={createSubAdministrationAbrechnungPDFTemplateData(subAbrDefTemplateValues, abrechnungsdefinitionVersion.outputOptions)}
              selectedTextbaustein={mapTextbausteinToSubAdministrationAbrechnungSelectableSection(selectedTextbaustein)}
            />
          )}
        />
      ),
    },
    {
      key: '4',
      label: 'Kontenzuordnung',
      children: (
        <AbrDefTemplateKontenzuordnung
          abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
          abrechnungsdefinitionId={abrechnungsdefinitionId}
          abrDefinitionType={AbrechnungsdefinitionType.SubAbrechnung}
          mode="onlyView"
        />
      ),
    },
  ];

  return (
    <>
      <Flex>
        <Space>
          <Typography.Text strong>Gültig ab</Typography.Text>
          <Typography.Text>
            <CustomFormattedDate value={abrechnungsdefinitionVersion.validFrom} />
          </Typography.Text>
        </Space>
        <Space style={{ marginLeft: '10px' }}>
          <Typography.Text strong>Kommentar</Typography.Text>
          <Typography.Text>{abrechnungsdefinitionVersion.kommentar}</Typography.Text>
        </Space>
      </Flex>
      <Divider />
      <Tabs items={items} />
      <Spacer height={16} />
      <Space style={{ width: '100%', justifyContent: 'end' }}>
        <Button onClick={() => navigate(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage)}>Schließen</Button>
        <Button
          type="primary"
          disabled={isHistoricized}
          onClick={() =>
            navigate(
              generatePathToAbrechnungsdefinitionTemplateVersionUpdatePage(
                abrechnungsdefinitionId,
                abrechnungsdefinitionVersion.abrechnungsdefinitionVersionId
              )
            )
          }
        >
          Bearbeiten
        </Button>
      </Space>
    </>
  );
};

export default SubAbrDefTemplateVersionView;
