import React, { FC } from 'react';
import { Button, Col, Empty, Row, Table, Typography } from 'antd';
import { vertragVorschreibungspositionTableColumns } from './vertragVorschreibungspositionTableColumns';
import { useBeVertragVorschreibungspositionListQuery } from '../gql/BeVertragVorschreibungspositionQueries.types';
import { EuroAmount } from '../../../../../../components/Number';
import { Spacer } from '../../../../../../components/Grid';
import { useTableExpandedRowKeys } from '../../../../../../components/Table/shared/useTableExpandedRowKeys';
import VertragVorschreibungsPositionWertTable from './VertragVorschreibungspositionWertTable/VertragVorschreibungsPositionWertTable';
import { useToggle } from '../../../../../../hooks/useToggle';
import { useOnBeVertragDataChangedEvent } from '../../../useOnBeVertragDataChangedEvent';
import TabPaneContent from '../../../../../../components/Tabs/TabPaneContent';
import { BeVertragVorschreibunsposition } from '../../../../../../types';
import { QUERY_PARAM_KEY, useQueryParams } from '../../../../../../hooks/useStringQueryParams';
import NestedTable from '../../../../../../components/Table/NestedTable/NestedTable';

type Props = {
  bestandseinheitId: string;
  objektId: string;
  vertragId: string;
  vertragsBeginn: string;
};

const VertragVorschreibungspositionTable: FC<Props> = ({ bestandseinheitId, objektId, vertragId, vertragsBeginn }) => {
  const { expRowIdLvl1: vorschreibungspositionId } = useQueryParams<{ [QUERY_PARAM_KEY.EXP_ROW_ID_LVL1]: string }>();

  const [showArchived, toggleShowArchived] = useToggle();
  const { data, refetch } = useBeVertragVorschreibungspositionListQuery({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      includeArchiviert: showArchived,
    },
  });
  const beVertragVorschreibungspositionList = data?.getBeVertragVorschreibungspositionList.data;
  const loading = !beVertragVorschreibungspositionList;

  useOnBeVertragDataChangedEvent('VPList', refetch);

  const { expandedRowKeys, toggleExpandedRowKey } = useTableExpandedRowKeys(vorschreibungspositionId ? [vorschreibungspositionId] : undefined);

  return (
    <TabPaneContent distanceToTopBorder="small">
      <Row justify="space-between" align="middle">
        <Col flex="auto" />
        <Col>
          <Button size="small" onClick={toggleShowArchived}>
            Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}
          </Button>
        </Col>
      </Row>
      <Spacer />
      <NestedTable<BeVertragVorschreibunsposition>
        pagination={{ pageSize: 50 }}
        locale={{
          emptyText: <Empty description="Keine Vorschreibungspositionen vorhanden" />,
        }}
        loading={loading}
        colWidth={120}
        columns={vertragVorschreibungspositionTableColumns(objektId, bestandseinheitId, vertragId, toggleExpandedRowKey, expandedRowKeys)}
        dataSource={beVertragVorschreibungspositionList}
        rowKey={(record) => record.vorschreibungspositionId}
        summary={(pageData) => {
          const sumNetto = pageData.reduce((acc, current) => acc + (current.netto ?? 0), 0);
          const sumBrutto = pageData.reduce((acc, current) => acc + (current.brutto ?? 0), 0);
          const sumUst = pageData.reduce((acc, current) => acc + (current.ust ?? 0), 0);

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} />
                <Table.Summary.Cell index={1}>
                  <Typography.Text strong>Summe</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3} />
                <Table.Summary.Cell index={4}>
                  <Row>
                    <Col flex="auto" />
                    <Typography.Text strong>
                      <EuroAmount value={sumNetto} />
                    </Typography.Text>
                  </Row>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} />
                <Table.Summary.Cell index={6}>
                  <Row>
                    <Col flex="auto" />
                    <Typography.Text strong>
                      <EuroAmount value={sumUst} />
                    </Typography.Text>
                  </Row>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <Row>
                    <Col flex="auto" />
                    <Typography.Text strong>
                      <EuroAmount value={sumBrutto} />
                    </Typography.Text>
                  </Row>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} />
                <Table.Summary.Cell index={9} />
                <Table.Summary.Cell index={10} />
              </Table.Summary.Row>
            </>
          );
        }}
        expandable={{
          expandedRowKeys,
          onExpand: (expanded, record) => toggleExpandedRowKey(record.vorschreibungspositionId),
          expandedRowRender: (record, index, indent, expanded) => (
            <VertragVorschreibungsPositionWertTable
              expanded={expanded}
              objektId={objektId}
              bestandseinheitId={bestandseinheitId}
              vertragId={vertragId}
              vertragsBeginn={vertragsBeginn}
              vorschreibungspositionId={record.vorschreibungspositionId}
              onListUpdated={refetch}
            />
          ),
        }}
        useHighlighting
      />
    </TabPaneContent>
  );
};

export default VertragVorschreibungspositionTable;
