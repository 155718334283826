import React from 'react';
import { Empty, Col, Row } from 'antd';
import { useKundenSystemListQuery } from '../../../../features/KundenSystem/KundenSystem/gql/KundenSystemQueries.types';
import KundenSystemActions from '../../../../features/KundenSystem/KundenSystem/KundenSystemActions';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { Spacer } from '../../../../components/Grid';
import { KundenSystem } from '../../../../types';
import kundenSystemListTableColumns from '../../../../features/KundenSystem/KundenSystem/kundenSystemListTableColumns';

const KundenSystemListPage = () => {
  const { loading, data: maybeKundenSystemList, refetch } = useKundenSystemListQuery();
  const kundenSystemList = maybeKundenSystemList?.getKundenSystemList.data ?? [];

  return (
    <>
      <Row>
        <Col flex="auto" />
        <Col>
          <KundenSystemActions onSuccess={refetch} />
        </Col>
      </Row>
      <Spacer height={16} />
      <TableWithColSelector<KundenSystem>
        size="small"
        locale={{
          emptyText: <Empty description={<span>Kein Kundensystem vorhanden</span>} />,
        }}
        loading={loading}
        columns={kundenSystemListTableColumns}
        dataSource={kundenSystemList}
        rowKey={(record) => record.kundenSystemId}
        filterIdentifier="management-kundensystem"
      />
    </>
  );
};

export default KundenSystemListPage;
