import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import DataWithShortenedTextAndExtraInfo from '../Helpers/DataWithShortenedTextAndExtraInfo';

type FileUploadViewProps = {
  filename: string;
  onDelete: () => void;
};

const FileUploadView: FC<FileUploadViewProps> = ({ filename, onDelete }) => (
  <Space>
    <PaperClipOutlined />
    <DataWithShortenedTextAndExtraInfo text={filename} maxTextLength={30}>
      {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
    </DataWithShortenedTextAndExtraInfo>
    <DeleteOutlined onClick={onDelete} />
  </Space>
);

export default FileUploadView;
