import { useCallback } from 'react';
import appFieldHelpSelectors from './data/appFieldHelpSelectors.json';
import fibuFieldHelpSelectors from './data/fibuFieldHelpSelectors.json';
import orderFieldHelpSelectors from './data/orderFieldHelpSelectors.json';
import paymentFieldHelpSelectors from './data/paymentFieldHelpSelectors.json';
import { useAppFieldHelpListQuery } from './gql/AppFieldHelpQueries.types';
import { useFibuFieldHelpListQuery } from './gql/FibuFieldHelpQueries.types';
import { useOrderFieldHelpListQuery } from './gql/OrderFieldHelpQueries.types';
import { usePaymentFieldHelpListQuery } from './gql/PaymentFieldHelpQueries.types';

type FieldHelpEntityName<T> = keyof T; // The entity names
type FieldHelpSelector<T, E extends FieldHelpEntityName<T>> = keyof T[E]; // Selectors by a given entity name

type AppFieldHelpEntityName = FieldHelpEntityName<typeof appFieldHelpSelectors>;
type AppFieldHelpSelector<T extends AppFieldHelpEntityName> = FieldHelpSelector<typeof appFieldHelpSelectors, T>;

type FibuFieldHelpEntityName = FieldHelpEntityName<typeof fibuFieldHelpSelectors>;
type FibuFieldHelpSelector<T extends FibuFieldHelpEntityName> = FieldHelpSelector<typeof fibuFieldHelpSelectors, T>;

type OrderFieldHelpEntityName = FieldHelpEntityName<typeof orderFieldHelpSelectors>;
type OrderFieldHelpSelector<T extends OrderFieldHelpEntityName> = FieldHelpSelector<typeof orderFieldHelpSelectors, T>;

type PaymentFieldHelpEntityName = FieldHelpEntityName<typeof paymentFieldHelpSelectors>;
type PaymentFieldHelpSelector<T extends PaymentFieldHelpEntityName> = FieldHelpSelector<typeof paymentFieldHelpSelectors, T>;

export const useGetAppFieldHelpText = <T extends AppFieldHelpEntityName>(fieldHelpSelectorEntityName: T) => {
  const { data: fieldHelpData } = useAppFieldHelpListQuery({ variables: { selector: `${fieldHelpSelectorEntityName}.` } });
  const contentList = fieldHelpData?.getAppFieldHelpList.data.contentList;

  return useCallback(
    (selector: AppFieldHelpSelector<T>) => {
      return contentList?.find((fieldHelp) => fieldHelp.selector === (selector as string))?.fieldHelp;
    },
    [contentList]
  );
};

export const useGetFibuFieldHelpText = <T extends FibuFieldHelpEntityName>(fieldHelpSelectorEntityName: T) => {
  const { data: fieldHelpData } = useFibuFieldHelpListQuery({ variables: { selector: `${fieldHelpSelectorEntityName}.` } });
  const contentList = fieldHelpData?.getFibuFieldHelpList.data.contentList;

  return useCallback(
    (selector: FibuFieldHelpSelector<T>) => {
      return contentList?.find((fieldHelp) => fieldHelp.selector === (selector as string))?.fieldHelp;
    },
    [contentList]
  );
};

export const useGetOrderFieldHelpText = <T extends OrderFieldHelpEntityName>(fieldHelpSelectorEntityName: T) => {
  const { data: fieldHelpData } = useOrderFieldHelpListQuery({ variables: { selector: `${fieldHelpSelectorEntityName}.` } });
  const contentList = fieldHelpData?.getOrderFieldHelpList.data.contentList;

  return useCallback(
    (selector: OrderFieldHelpSelector<T>) => {
      return contentList?.find((fieldHelp) => fieldHelp.selector === (selector as string))?.fieldHelp;
    },
    [contentList]
  );
};

export const useGetPaymentFieldHelpText = <T extends PaymentFieldHelpEntityName>(fieldHelpSelectorEntityName: T) => {
  const { data: fieldHelpData } = usePaymentFieldHelpListQuery({ variables: { selector: `${fieldHelpSelectorEntityName}.` } });
  const contentList = fieldHelpData?.getPaymentFieldHelpList.data.contentList;

  return useCallback(
    (selector: PaymentFieldHelpSelector<T>) => {
      return contentList?.find((fieldHelp) => fieldHelp.selector === (selector as string))?.fieldHelp;
    },
    [contentList]
  );
};
