import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './listingFiltersFormMapper';
import WizardTypeSelect from './WizardTypeSelect';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import { TWizardQueryParams } from './filtersQueryParams';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import { getDefaultFilterList, onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import StatusListFormSelect from './StatusListFormSelect';
import WizardBtnAndModal from '../WizardBtnAndModal';
import FiltersWithSelectorWrapper from '../../../../components/Filters/FiltersWithSelector/FiltersWithSelectorWrapper';
import useFilterWithQueryParamsNew from '../../../../components/Filters/useFilterWithQueryParamsNew';
import FormItemForFilter from '../../../../components/Form/FormItemForFilter';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  queryParams: TWizardQueryParams;
};

const ListingFilters: FC<Props> = ({ formikProps, queryParams }) => {
  const defaultFilterList = getDefaultFilterList(filtersFormFields, formikProps);

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.createTsFromInclusive || filter === filtersFormFields.createTsToInclusive) {
      return filtersFormFields.createTsFromInclusive;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === filtersFormFields.type) {
      return (
        <FormItemForFilter name={filtersFormFields.type} key={filter}>
          <WizardTypeSelect name={filtersFormFields.type} onChange={formikProps.submitForm} prefix={listingLabelList.type} />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.statusList) {
      return (
        <FormItemForFilter name={filtersFormFields.statusList} key={filter}>
          <StatusListFormSelect
            name={filtersFormFields.statusList}
            onChange={formikProps.submitForm}
            mode="multiple"
            prefix={listingLabelList.statusList}
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.createTsFromInclusive) {
      return (
        <FormItemForFilter name="erstelltAm" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.createTsFromInclusive}
            toName={filtersFormFields.createTsToInclusive}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${listingLabelList.createTsFromInclusive} - von`}
            toPrefix={`${listingLabelList.createTsFromInclusive} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.erstellerMitarbeiterIdList) {
      return (
        <FormItemForFilter name={filtersFormFields.erstellerMitarbeiterIdList} key={filter}>
          <MitarbeiterSelect
            name={filtersFormFields.erstellerMitarbeiterIdList}
            onChange={formikProps.submitForm}
            mode="multiple"
            prefix={listingLabelList.erstellerMitarbeiterIdList}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.createTsFromInclusive) && formikProps.values.createTsToInclusive) {
      formikProps.setFieldValue(filtersFormFields.createTsToInclusive, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, filterList } = useFilterWithQueryParamsNew({
    defaultFilterList,
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWithSelectorWrapper
      selectedFilterList={selectedFilterList}
      onSelectedFilterTitleList={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
      allFilterTitles={listingLabelList}
      filters={<>{filterList}</>}
      actionButtons={<WizardBtnAndModal formikProps={formikProps} />}
    />
  );
};

export default ListingFilters;
