import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import useDebounce from '../../../../hooks/useDebounce';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';
import { useFilterReceivedBankTransactionPaymentReferenceTextListQuery } from '../../gql/ReceivedBankTransactionQueries.types';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../../helpers/stringHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  paymentReference?: string | null;
} & Omit<
  SelectProps,
  | 'id'
  | 'placeholder'
  | 'size'
  | 'allowClear'
  | 'showSearch'
  | 'filterOption'
  | 'onSelect'
  | 'onSearch'
  | 'onClear'
  | 'searchValue'
  | 'notFoundContent'
>;

const ReceivedBankTransactionPaymentReferenceTextSelect: FC<Props> = ({ paymentReference, ...restProps }) => {
  const [paymentReferenceText, setPaymentReferenceText] = useState<string>(paymentReference ? paymentReference.toString() : '');
  const debouncedText = useDebounce(paymentReferenceText);

  const { loading, data } = useFilterReceivedBankTransactionPaymentReferenceTextListQuery({
    variables: {
      paymentReferenceText: debouncedText as string,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const paymentReferenceTextList = data?.getFilterReceivedBankTransactionPaymentReferenceTextList.data ?? [];

  return (
    <Select
      {...restProps}
      id={restProps.name}
      loading={loading}
      size="small"
      allowClear
      showSearch
      placeholder="Text auswählen"
      filterOption={selectFilterOption}
      onSelect={setPaymentReferenceText}
      onSearch={(value) => {
        paymentReferenceText.length === 1 && !value && setPaymentReferenceText('');
        value && setPaymentReferenceText(value);
      }}
      onClear={() => setPaymentReferenceText('')}
      searchValue={paymentReferenceText}
      notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
    >
      {paymentReferenceTextList?.map((paymentRefText) => (
        <Select.Option key={paymentRefText} value={paymentRefText}>
          <DataWithShortenedText text={paymentRefText} maxTextLength={40} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default ReceivedBankTransactionPaymentReferenceTextSelect;
