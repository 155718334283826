import React, { FC } from 'react';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import FiltersWithMax3Fields from '../../../components/Filters/FiltersWithMax3Fields';
import { rechtstraegerInfoFeldListingFiltersFormFields } from './rechtstraegerInfoFeldListingFiltersFormMapper';
import RechtstraegerInfoFeldTypeFormSelect from './Filters/RechtstraegerInfoFeldTypeFormSelect';
import RechtstraegerInfoFeldDrawerAndButton from './RechtstraegerInfoFeldDrawerAndButton';

type Props = {
  onSuccess: () => void;
  rechtstraegerId: string;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const RechtstraegerInfoFeldListingFilters: FC<Props> = ({ onSuccess, rechtstraegerId, showArchived, toggleShowArchived }) => (
  <FiltersWithMax3Fields
    hideTitle
    filters={
      <>
        <FormItemWithoutColon name={rechtstraegerInfoFeldListingFiltersFormFields.type} label="Typ">
          <RechtstraegerInfoFeldTypeFormSelect name={rechtstraegerInfoFeldListingFiltersFormFields.type} />
        </FormItemWithoutColon>
      </>
    }
    actionButtons={
      <RechtstraegerInfoFeldDrawerAndButton
        onSuccess={onSuccess}
        rechtstraegerId={rechtstraegerId}
        showArchived={showArchived}
        toggleShowArchived={toggleShowArchived}
      />
    }
  />
);

export default RechtstraegerInfoFeldListingFilters;
