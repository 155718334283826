import React, { FC, ReactNode } from 'react';
import { Space, Tag } from 'antd';
import DataWithShortenedText from '../Helpers/DataWithShortenedText';

export type TextWithTagProps = {
  textContent: ReactNode;
  /** additional information about the data which can be automatically shortened setting the maxTextLength prop */
  tagContent: string;
  maxTextLength?: number;
  width?: string;
};

/**
 * <h2>Usage</h2>
 * Display data connected with each other, e.g. Konto number and name, Link to page and a tag
 * - Can be used in tables to show data in one column, rather than split it into two columns
 * - The text next to the tag can be a string, a link or any other element, e.g. shortened text with tooltip
 */
const TextWithTag: FC<TextWithTagProps> = ({ textContent, tagContent, maxTextLength, width }) => {
  return (
    <Space>
      <Space style={{ width: width ?? 48 }}>{textContent}</Space>
      <DataWithShortenedText maxTextLength={maxTextLength ?? tagContent.length} text={tagContent}>
        {(shortenedText) => <Tag>{shortenedText}</Tag>}
      </DataWithShortenedText>
    </Space>
  );
};

export default TextWithTag;
