import React, { FC } from 'react';
import { Formik } from 'formik';
import { AbrechdefAbrechkreis, AbrechnungsdefinitionType, Konto, KontoAbrechnungskreis } from '../../../types';
import { mapZuordnungListToFormValues, ZuordnungKontoListingFormValues } from '../../AbrKontenzuordnung/Form/zuordnungKontoListingFormMapper';
import { AbrKontenzuordnungFiltersFormValues } from '../../AbrKontenzuordnung/Listing/Filters/abrKontenzuordnungFiltersMapper';
import ZuordnungKontoListingFormContent from './ZuordnungKontoListingFormContent';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
  abrDefVersionAbrechkreisList: AbrechdefAbrechkreis[];
  abrDefinitionType: AbrechnungsdefinitionType;
  erloesKontoList: Konto[];
  aufwandKontoList: Konto[];
  verrechnungList: Konto[];
  zuordnungList: KontoAbrechnungskreis[];
  title: string;
  onClickEdit: () => void;
  isErloesKontoListOpen: boolean;
  isAufwandKontoListOpen: boolean;
  isVerrechnungListOpen: boolean;
  onValuesChange?: (isDirty: boolean) => void;
  withHeader: boolean;
  shouldSubmitOnChange?: boolean;
  onSubmit?: (formValues: ZuordnungKontoListingFormValues) => Promise<unknown>;
  isSubmitting?: boolean;
  filters?: AbrKontenzuordnungFiltersFormValues | null;
};

const ZuordnungKontoListingForm: FC<Props> = ({
  abrechnungsdefinitionId,
  abrechnungsdefinitionVersionId,
  abrDefVersionAbrechkreisList,
  abrDefinitionType,
  erloesKontoList,
  aufwandKontoList,
  verrechnungList,
  zuordnungList,
  title,
  onClickEdit,
  isErloesKontoListOpen,
  isAufwandKontoListOpen,
  isVerrechnungListOpen,
  onValuesChange,
  withHeader,
  shouldSubmitOnChange,
  onSubmit,
  isSubmitting,
  filters,
}) => {
  return (
    <Formik<ZuordnungKontoListingFormValues>
      initialValues={mapZuordnungListToFormValues(
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId,
        erloesKontoList,
        aufwandKontoList,
        verrechnungList,
        zuordnungList
      )}
      onSubmit={(formValues, formikHelpers) => {
        onSubmit?.(formValues).finally(() => {
          formikHelpers.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <ZuordnungKontoListingFormContent
          abrDefinitionType={abrDefinitionType}
          formikProps={formikProps}
          title={title}
          onClickEdit={onClickEdit}
          aufwandKontoList={aufwandKontoList}
          erloesKontoList={erloesKontoList}
          verrechnungKontoList={verrechnungList}
          isErloesKontoListOpen={isErloesKontoListOpen}
          isAufwandKontoListOpen={isAufwandKontoListOpen}
          isVerrechnungListOpen={isVerrechnungListOpen}
          onValuesChange={onValuesChange}
          withHeader={withHeader}
          shouldSubmitOnChange={shouldSubmitOnChange}
          isSubmitting={isSubmitting}
          filters={filters}
          abrDefVersionAbrechkreisList={abrDefVersionAbrechkreisList}
        />
      )}
    </Formik>
  );
};

export default ZuordnungKontoListingForm;
