import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, Space } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Konto } from '../../../../types';
import { useToggle } from '../../../../hooks/useToggle';
import { useSetKontoTagListMutation } from '../../gql/KontenplanMutations.types';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useKontenplanQuery } from '../../gql/KontenplanQueries.types';
import KontoBezugSelectAndTags from './KontoBezugSelectAndTags';
import { canBeHauptKonto, doesHauptKontoExist } from './kontoBezugHelpers';
import { addHauptToTagsIfNeccessary, HAUPT_TAG_TEXT } from '../../../../components/Tag/tagHelpers';

type Props = {
  konto: Konto;
};
const KontoBezug: FC<Props> = ({ konto }) => {
  const { data, loading, refetch } = useKontenplanQuery();
  const hauptKonten = data?.getKontenplan.data;
  const isHauptKonto = !!(
    hauptKonten &&
    (hauptKonten.hauptUmsatzsteuerKonto?.kontoId === konto.kontoId || hauptKonten.hauptVorsteuerKonto?.kontoId === konto.kontoId)
  );

  const [defaultTags, setDefaultTags] = useState(konto.tagList);
  const [tags, setTags] = useState<string[]>(defaultTags);

  const [inEditMode, setEditMode] = useToggle();
  const [shouldBeHaupt, setShouldBeHaupt] = useState(false);

  useEffect(() => {
    setTags(addHauptToTagsIfNeccessary(isHauptKonto, konto.tagList));
    setShouldBeHaupt(isHauptKonto);
  }, [isHauptKonto, konto.tagList]);

  const [runSetTagsList, { loading: isSaveLoading }] = useSetKontoTagListMutation({
    variables: {
      kontoId: konto.kontoId,
      input: { tagList: tags.filter((tag) => tag !== HAUPT_TAG_TEXT), haupt: shouldBeHaupt },
    },
    onCompleted: (data) => {
      setDefaultTags(data.actionSetKontoTagList.data.tagList);
      showSuccessMsgUpdate(`Bezug`).then(() => {
        refetch();
      });
    },
    onError: () => APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
      {tags.length === 0 && !inEditMode ? (
        <span /> // placeholder so that buttons will appears always on the right side
      ) : (
        <KontoBezugSelectAndTags
          tags={tags}
          loading={loading}
          canBeHauptKonto={canBeHauptKonto(konto)}
          isInEditMode={inEditMode}
          setShouldBeHaupt={setShouldBeHaupt}
          setTags={setTags}
        />
      )}
      <Space size={4}>
        <Button
          size="small"
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => {
            inEditMode && setTags(defaultTags);
            setEditMode();
          }}
        />
        <Button
          size="small"
          shape="circle"
          htmlType="submit"
          disabled={!inEditMode}
          icon={<SaveOutlined />}
          onClick={() => {
            if (shouldBeHaupt && !isHauptKonto) {
              doesHauptKontoExist(hauptKonten, konto.verwendung?.value) ? showConfirmHaupt(runSetTagsList, setShouldBeHaupt) : runSetTagsList();
            } else {
              runSetTagsList();
            }
            return setEditMode();
          }}
          loading={isSaveLoading}
        />
      </Space>
    </Space>
  );
};

export default KontoBezug;

const showConfirmHaupt = (runSetTagsList: () => void, setShouldBeHaupt: React.Dispatch<React.SetStateAction<boolean>>) => {
  Modal.confirm({
    title: `Ein anderes Konto ist als Haupt markiert. Möchten Sie hiermit das Hauptkonto wechseln?`,
    content: `EasyCloud ändert das Hauptkonto für Sie.`,
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return runSetTagsList();
    },
    onCancel() {
      setShouldBeHaupt(false);
      return runSetTagsList();
    },
  });
};
