import { FC } from 'react';
import { Col, Row } from 'antd';
import { FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { BookingSuggestion } from '../../../../types';
import GeneralInfoBlock from '../../shared/GeneralInfoBlock/GeneralInfoBlock';
import BookingSuggestionButtons from './BookingSuggestionButtons';
import { BookingSuggestionFormValues } from '../../shared/Form/buchungsmaskeFormMapper';
import useSettingFormDirty from '../../shared/Form/useSettingFormDirty';

type Props = {
  bookingSuggestion: BookingSuggestion;
  formikProps: FormikProps<BookingSuggestionFormValues>;
  isOkLoading?: boolean;
  onReset: () => void;
  onSubmit: () => void;
  showNotSavedWarning: boolean;
  setIsFormDirty: (toggle: boolean) => void;
  initialValuesForTracking: BookingSuggestionFormValues;
};

const GeneralInfoWithButtonsBlock: FC<Props> = ({
  bookingSuggestion,
  formikProps,
  isOkLoading,
  onReset,
  onSubmit,
  showNotSavedWarning,
  setIsFormDirty,
  initialValuesForTracking,
}) => {
  useSettingFormDirty(initialValuesForTracking, formikProps.values, setIsFormDirty);

  return (
    <Row align="stretch" style={{ marginTop: '24px' }}>
      <Col span={18}>
        <GeneralInfoBlock formikProps={formikProps} fibuBuchungStatus={bookingSuggestion.buchungsanweisung.fibuBuchungStatus} />
      </Col>
      <Col span={6}>
        <BookingSuggestionButtons
          onReset={onReset}
          onSubmit={onSubmit}
          showNotSavedWarning={showNotSavedWarning}
          isOkDisabled={formikProps.isSubmitting || !isEmpty(formikProps.errors)}
          isOkLoading={isOkLoading}
        />
      </Col>
    </Row>
  );
};

export default GeneralInfoWithButtonsBlock;
