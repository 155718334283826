import { useEffect, useState } from 'react';
import { QueryHookOptions, QueryResult } from '@apollo/client';
import { isEqual } from 'lodash';
import { PaginationProps } from 'antd/lib/pagination';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { Page } from '../types';
import { isNotNil } from '../helpers/assertionHelper';
import { usePrevious } from './usePrevious';

export const DEFAULT_CURRENT = 1;
export const DEFAULT_PAGE_SIZE = 30;
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 30, 50, 100];
export const DEFAULT_SIZE_CHANGER = true;

const usePagination = (paginationProps?: PaginationProps) => {
  const onChange = (pageNr: number) => {
    setPagination((pagination) => ({
      ...pagination,
      current: pageNr,
    }));
  };

  const onShowSizeChange = (current: number, pageSize: number) => {
    setPagination((pagination) => ({
      ...pagination,
      pageSize,
    }));
  };

  const [pagination, setPagination] = useState<PaginationProps>({
    current: DEFAULT_CURRENT,
    pageSize: DEFAULT_PAGE_SIZE,
    showSizeChanger: DEFAULT_SIZE_CHANGER,
    pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
    onShowSizeChange,
    onChange,
    ...paginationProps,
  });

  const resetPagination = () => setPagination((pagination) => ({ ...pagination, current: DEFAULT_CURRENT }));

  const setTotalElements = (totalElements: number) => {
    setPagination((pagination) => ({
      ...pagination,
      total: totalElements,
    }));
  };

  const setCurrentPage = (pageNr: number) => onChange(pageNr);

  return { pagination, setTotalElements, resetPagination, setCurrentPage };
};

const useSorting = () => {
  const [order, setOrder] = useState<{ order?: string; orderDir?: string }>({
    order: undefined,
    orderDir: undefined,
  });

  const setOrderBasedOnSorter = (sorter: SorterResult<unknown>) => {
    if (sorter.order) {
      return {
        order: sorter.field as string,
        orderDir: sorter.order === 'ascend' ? 'asc' : 'desc',
      };
    }
    return {
      order: undefined,
      orderDir: undefined,
    };
  };

  const handleTableSorting = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    if (!Array.isArray(sorter)) {
      setOrder(setOrderBasedOnSorter(sorter));
    }
  };

  return { order, handleTableSorting };
};

type PagingAndSortingQueryVariables = {
  currentPage?: number | null;
  pageSize?: number | null;
  order?: string | null;
  orderDir?: string | null;
};

// the key is always the concrete gql field, it varies for most gql, hence defined as string. (e.g. getRechtstraegerList, getAuftragList, ...)
type QueryWithPaginationResponse = Record<string, QueryWithPaginationResponseValue>;

type QueryWithPaginationResponseValue = {
  data?: QueryWithPaginationResponseData | null;
};

type QueryWithPaginationResponseData = {
  page: Page;
};

/**
 * queryHook.baseOptions: gql-codegen made an update and they generate baseOptions as optional or mandatory param based on whether the query has required
 * variables or not. Therefore the queryHook.baseOptions had to changed to mandatory, it works now because all of our gql with pagination have
 * required variables of course (page, ...) so it should not cause any problems.
 * https://github.com/dotansimha/graphql-code-generator/pull/5037
 */
export const useQueryWithPagingAndSorting = <
  QueryResponse extends QueryWithPaginationResponse,
  QueryVariables extends PagingAndSortingQueryVariables,
>(
  queryHook: (
    baseOptions: QueryHookOptions<QueryResponse, QueryVariables> & ({ variables: QueryVariables; skip?: boolean } | { skip: boolean })
  ) => QueryResult<QueryResponse, QueryVariables>,
  baseOptions?: QueryHookOptions<QueryResponse, QueryVariables>,
  paginationProps?: PaginationProps
) => {
  const { pagination, resetPagination, setTotalElements, setCurrentPage } = usePagination(paginationProps);
  const { order: sortOrder, handleTableSorting } = useSorting();

  // retrieve filter props from variables (all vars except paging and sorting props) because if they change we want to reset the pagination
  const variables = baseOptions?.variables ?? { pageSize: undefined, order: undefined, orderDir: undefined, currentPage: undefined };
  const { pageSize, order, orderDir, currentPage, ...newFilterProps } = variables;

  // store filter props in state as previous filter props in order to be able to compare them with the new filter props and recognize if any changes happened
  const prevFilterProps = usePrevious(newFilterProps);

  // if other query variables than paging and sorting variables have changed we reset pagination to go back to first page. because probably some
  // filter value changed and than we get a whole new result list.
  // Room for improvement: Currently after when a pagination reset occurs, we make 2 requests. One with the old page number and then a second one with 0.
  // We could try to reduce this to one call but it is a bit of a challenge. I guess maybe with lazy query hooks it would be possible.
  useEffect(() => {
    if (prevFilterProps !== undefined && !isEqual(newFilterProps, prevFilterProps)) {
      resetPagination();
    }
  }, [newFilterProps, prevFilterProps, resetPagination]);

  // call the hook with default and passed options
  const queryResult = queryHook({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    ...baseOptions,
    // unfortunately I don't know if this TS problem solvable
    // @ts-ignore
    variables: {
      page: pagination.current ? pagination.current - 1 : 0,
      pageSize: pagination.pageSize,
      order: sortOrder.order,
      orderDir: sortOrder.orderDir,
      ...baseOptions?.variables,
    },
  } as QueryHookOptions<QueryResponse, QueryVariables> & ({ variables: QueryVariables; skip?: boolean } | { skip: boolean }));

  const queryData = queryResult.data;
  // get totalElements from response and update pagination with the value
  for (const gqlField in queryData) {
    if (Object.prototype.hasOwnProperty.call(queryData, gqlField)) {
      const queryDataPage = queryData[gqlField].data?.page;

      if (isNotNil(queryDataPage)) {
        const totalElements = queryDataPage.totalElements;

        if (totalElements !== pagination.total) {
          setTotalElements(totalElements);

          // This is a workaround for the case that the last list element on a page has been deleted.
          // Until now, deleting the last element caused rendering a page with info that there are no elements in the list, was can be confusing for the user - this info is related only to the empty page and not the entire list.
          // With this solution, the user will be navigated to the 'pre-last' page, so that he can see the remaining data.
          // The new problem is: The cache is not updated and user cannot navigate to a new page if a new element has been added. A page refresh or logging out and in is needed to see then all the entries.
          // We decided to use this workaround for now and look in the future for a solution to update the cache just after the last list element has been deleted.
          const totalPages = queryDataPage.totalPages;
          if (pagination.current && totalPages <= pagination.current) {
            setCurrentPage(totalPages >= 1 ? totalPages : 1);
          }
        }
      }
    }
  }

  return {
    ...queryResult,
    pagination,
    handleTableSorting,
  };
};
