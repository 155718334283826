import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { OffenePostenFieldsFragmentDoc } from '../../OffenePosten/gql/OffenePostenFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuszifferungListQueryVariables = Types.Exact<{
  zahlungId: Types.Scalars['String']['input'];
}>;

export type AuszifferungListQuery = {
  getAuszifferungList: {
    data: Array<{
      auszifferungId: string;
      auszifferungsDatum: string;
      betrag: number;
      bookingSuggestionId?: string | null;
      buchungId: string;
      buchungsdatum: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      offenePostenId: string;
      storniert: boolean;
      storniertByMitarbeiterId?: string | null;
      storniertTs?: string | null;
      stornierteOffenePostenId?: string | null;
      stornoOffenePostenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      zahlungId?: string | null;
      source: { text: string; value: Types.AuszifferungSource };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuszifferungListWithOffenePostenQueryVariables = Types.Exact<{
  zahlungId: Types.Scalars['String']['input'];
}>;

export type AuszifferungListWithOffenePostenQuery = {
  getAuszifferungListWithOffenePosten: {
    data: Array<{
      auszifferungId: string;
      auszifferungsDatum: string;
      betrag: number;
      bookingSuggestionId?: string | null;
      buchungId: string;
      buchungsdatum: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      offenePostenId: string;
      storniert: boolean;
      storniertByMitarbeiterId?: string | null;
      storniertTs?: string | null;
      stornierteOffenePostenId?: string | null;
      stornoOffenePostenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      zahlungId?: string | null;
      offenePosten: {
        belegDatum: string;
        belegFileId?: string | null;
        belegnummer: string;
        buchungId: string;
        buchungsdatum: string;
        buchungskreisId: string;
        buchungskreisRechtstraegerId: string;
        buchungskreisRechtstraegerBezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dataCarrierBelegId?: string | null;
        dueDate: string;
        gesamtBetrag: number;
        iban?: string | null;
        letzteMahnstufe: number;
        offenePostenId: string;
        offenerBetrag: number;
        statementNumber?: string | null;
        auszifferungList: Array<{
          auszifferungId: string;
          auszifferungsDatum: string;
          betrag: number;
          bookingSuggestionId?: string | null;
          buchungId: string;
          buchungsdatum: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          offenePostenId: string;
          storniert: boolean;
          storniertByMitarbeiterId?: string | null;
          storniertTs?: string | null;
          stornierteOffenePostenId?: string | null;
          stornoOffenePostenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          zahlungId?: string | null;
          source: { text: string; value: Types.AuszifferungSource };
        }>;
        belegSymbol: { text: string; value: Types.FibuBelegSymbol; abbreviation: string };
        konto: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          haupt: boolean;
          kontoId: string;
          kontoTemplateId?: string | null;
          nummer: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          klasse: { text: string; value: Types.KontoKlasse };
          status: { description?: string | null; text: string; value: Types.KontoStatus };
          type: { text: string; value: Types.KontoType };
          verwendung?: { text: string; value: Types.KontoVerwendung } | null;
        };
        vertragspartner: {
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
        };
      };
      source: { text: string; value: Types.AuszifferungSource };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AuszifferungListDocument = gql`
  query AuszifferungList($zahlungId: String!) {
    getAuszifferungList(zahlungId: $zahlungId) {
      data {
        auszifferungId
        auszifferungsDatum
        betrag
        bookingSuggestionId
        buchungId
        buchungsdatum
        createTs
        createdBy
        createdByMitarbeiterId
        offenePostenId
        source {
          text
          value
        }
        storniert
        storniertByMitarbeiterId
        storniertTs
        stornierteOffenePostenId
        stornoOffenePostenId
        updatedByMitarbeiterId
        zahlungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAuszifferungListQuery(
  baseOptions: Apollo.QueryHookOptions<AuszifferungListQuery, AuszifferungListQueryVariables> &
    ({ variables: AuszifferungListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuszifferungListQuery, AuszifferungListQueryVariables>(AuszifferungListDocument, options);
}
export function useAuszifferungListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuszifferungListQuery, AuszifferungListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuszifferungListQuery, AuszifferungListQueryVariables>(AuszifferungListDocument, options);
}
export function useAuszifferungListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuszifferungListQuery, AuszifferungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuszifferungListQuery, AuszifferungListQueryVariables>(AuszifferungListDocument, options);
}
export type AuszifferungListQueryHookResult = ReturnType<typeof useAuszifferungListQuery>;
export type AuszifferungListLazyQueryHookResult = ReturnType<typeof useAuszifferungListLazyQuery>;
export type AuszifferungListSuspenseQueryHookResult = ReturnType<typeof useAuszifferungListSuspenseQuery>;
export type AuszifferungListQueryResult = Apollo.QueryResult<AuszifferungListQuery, AuszifferungListQueryVariables>;
export const AuszifferungListWithOffenePostenDocument = gql`
  query AuszifferungListWithOffenePosten($zahlungId: String!) {
    getAuszifferungListWithOffenePosten(zahlungId: $zahlungId) {
      data {
        auszifferungId
        auszifferungsDatum
        betrag
        bookingSuggestionId
        buchungId
        buchungsdatum
        createTs
        createdBy
        createdByMitarbeiterId
        offenePostenId
        offenePosten {
          ...OffenePostenFields
        }
        source {
          text
          value
        }
        storniert
        storniertByMitarbeiterId
        storniertTs
        stornierteOffenePostenId
        stornoOffenePostenId
        updatedByMitarbeiterId
        zahlungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OffenePostenFieldsFragmentDoc}
`;
export function useAuszifferungListWithOffenePostenQuery(
  baseOptions: Apollo.QueryHookOptions<AuszifferungListWithOffenePostenQuery, AuszifferungListWithOffenePostenQueryVariables> &
    ({ variables: AuszifferungListWithOffenePostenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuszifferungListWithOffenePostenQuery, AuszifferungListWithOffenePostenQueryVariables>(
    AuszifferungListWithOffenePostenDocument,
    options
  );
}
export function useAuszifferungListWithOffenePostenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuszifferungListWithOffenePostenQuery, AuszifferungListWithOffenePostenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuszifferungListWithOffenePostenQuery, AuszifferungListWithOffenePostenQueryVariables>(
    AuszifferungListWithOffenePostenDocument,
    options
  );
}
export function useAuszifferungListWithOffenePostenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AuszifferungListWithOffenePostenQuery, AuszifferungListWithOffenePostenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuszifferungListWithOffenePostenQuery, AuszifferungListWithOffenePostenQueryVariables>(
    AuszifferungListWithOffenePostenDocument,
    options
  );
}
export type AuszifferungListWithOffenePostenQueryHookResult = ReturnType<typeof useAuszifferungListWithOffenePostenQuery>;
export type AuszifferungListWithOffenePostenLazyQueryHookResult = ReturnType<typeof useAuszifferungListWithOffenePostenLazyQuery>;
export type AuszifferungListWithOffenePostenSuspenseQueryHookResult = ReturnType<typeof useAuszifferungListWithOffenePostenSuspenseQuery>;
export type AuszifferungListWithOffenePostenQueryResult = Apollo.QueryResult<
  AuszifferungListWithOffenePostenQuery,
  AuszifferungListWithOffenePostenQueryVariables
>;
