import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { EuroAmount } from '../../../../../../components/Number';
import { TABLE_ALIGNED_COLS_CLASSNAMES } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsClassnames';
import { TopAbrKonto } from '../../../../../../types';

type Props = {
  konto: TopAbrKonto;
};

const AufwandAufteilungKonto: FC<Props> = ({ konto }) => (
  <tr>
    <td colSpan={2} style={{ padding: 0 }}>
      <StyledTable style={{ width: '100%' }}>
        <tbody className={TABLE_ALIGNED_COLS_CLASSNAMES.tbody}>
          <tr>
            <td style={{ paddingLeft: '24px' }}>
              <Typography.Text>{konto.bezeichnung}</Typography.Text>
            </td>
            <td>
              <EuroAmount value={konto.summe} />
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </td>
  </tr>
);

const StyledTable = styled('table')`
  padding-left: 15px;

  tbody {
    tr {
      td {
        border: 0;
      }

      &:last-child td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
`;

export default AufwandAufteilungKonto;
