import React, { FC } from 'react';
import RechnungsAusstellerAbrDefTable from './Table/RechnungsAusstellerAbrDefTable';
import RechnungsAusstellerAbrDefListingBtnAndModal from './RechnungsAusstellerAbrDefListingBtnAndModal';
import { RechnungsAusstellerFieldsFragment } from '../gql/RechnungsAusstellerFragments.types';

type Props = {
  rechnungsAussteller: RechnungsAusstellerFieldsFragment;
  onSuccess: () => void;
  rechtstraegerId: string;
};

const RechnungsAusstellerAbrDefListing: FC<Props> = ({ rechnungsAussteller, onSuccess, rechtstraegerId }) => (
  <>
    <RechnungsAusstellerAbrDefListingBtnAndModal onSuccess={onSuccess} rechtstraegerId={rechtstraegerId} />
    <RechnungsAusstellerAbrDefTable rechnungsAussteller={rechnungsAussteller} onSuccess={onSuccess} rechtstraegerId={rechtstraegerId} />
  </>
);

export default RechnungsAusstellerAbrDefListing;
