import React, { FC } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { OffenePostenDebitorListData } from './op-list-debitor-types';
import pdfStyles from '../../styles/pdfStyles';
import Header from '../../shared/Header';
import OPDebitorTableBlock from './components/OPDebitorTableBlock/OPDebitorTableBlock';

type Props = {
  data: OffenePostenDebitorListData;
};

const OffenePostenDebitorTemplate: FC<Props> = ({ data }) => (
  <Document>
    <Page size="A4" style={[pdfStyles.body]} orientation="landscape">
      {/* Header */}
      {data.header && (
        <View>
          <Header header={data.header} />
        </View>
      )}
      {/* Title */}
      <View style={{ margin: '5mm 0', fontWeight: 'bold' }}>
        <Text>{data.title}</Text>
        {data.objektText && <Text style={pdfStyles.textNormal}>{data.objektText}</Text>}
        {data.dueDateToText && <Text style={pdfStyles.textNormal}>{data.dueDateToText}</Text>}
      </View>

      {/* Table */}
      <OPDebitorTableBlock data={data} />

      {/* Footer */}
      <View fixed style={pdfStyles.footer}>
        <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { borderBottomWidth: 0.4, marginTop: '2mm', marginBottom: '2mm' }]} />
        <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} fixed style={{ textAlign: 'right', fontSize: 6 }} />
      </View>
    </Page>
  </Document>
);

export default OffenePostenDebitorTemplate;
