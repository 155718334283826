import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { WizardStatus } from '../../../../types';

type Props = SelectProps;

const StatusListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const list = [
    {
      text: 'Erstellt',
      value: WizardStatus.Created,
    },
    {
      text: 'Verarbeitet',
      value: WizardStatus.Completed,
    },
  ];

  return (
    <Select size="middle" placeholder="" {...restProps} name={name} allowClear showSearch filterOption={selectFilterOption}>
      {list.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default StatusListFormSelect;
