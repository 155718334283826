import React, { FC } from 'react';
import RechnungsAusstellerMahnDefTable from './RechnungsAusstellerMahnDefTable';
import RechnungsAusstellerMahnDefListingBtnAndModal from '../RechnungsAusstellerMahnDefListingBtnAndModal';
import { RechnungsAusstellerFieldsFragment } from '../../gql/RechnungsAusstellerFragments.types';

type Props = {
  rechnungsAussteller: RechnungsAusstellerFieldsFragment;
  rechtstraegerId: string;
  onSuccess: () => void;
};

const RechnungsAusstellerMahnDefListing: FC<Props> = ({ rechnungsAussteller, rechtstraegerId, onSuccess }) => (
  <>
    <RechnungsAusstellerMahnDefListingBtnAndModal rechtstraegerId={rechtstraegerId} onSuccess={onSuccess} />
    <RechnungsAusstellerMahnDefTable rechnungsAussteller={rechnungsAussteller} rechtstraegerId={rechtstraegerId} onSuccess={onSuccess} />
  </>
);

export default RechnungsAusstellerMahnDefListing;
