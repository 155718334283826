import { FormFields } from '../../../../../../helpers/formikHelper';
import { Kuendigungsfrist, KuendigungsfristJeweilsZum, RechtstraegerType } from '../../../../../../types';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';

import { ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment } from '../../../gql/objektInfoFeldFragments.types';

export interface HausbetreuungUpdateFormValues extends InfoFeldFormValues {
  contactPersonId?: string;
  rechtstraegerType?: RechtstraegerType;
  beauftragungsgrund: string;
  beginn?: string;
  ende?: string;
  kuendigungsfrist: Kuendigungsfrist | null;
  kuendigungsfristDauer: number | null;
  kuendigungsfristJeweilsZum?: KuendigungsfristJeweilsZum;
  rechtstraegerId?: string;
  vertragsdaten?: string;
  wertsicherung?: string;
  deleteDocumentFileIdList: string[];
}

export const hausbetreuungUpdateFormFields: FormFields<HausbetreuungUpdateFormValues> = {
  contactPersonId: 'contactPersonId',
  rechtstraegerType: 'rechtstraegerType',
  beauftragungsgrund: 'beauftragungsgrund',
  beginn: 'beginn',
  ende: 'ende',
  kuendigungsfrist: 'kuendigungsfrist',
  kuendigungsfristDauer: 'kuendigungsfristDauer',
  kuendigungsfristJeweilsZum: 'kuendigungsfristJeweilsZum',
  rechtstraegerId: 'rechtstraegerId',
  vertragsdaten: 'vertragsdaten',
  wertsicherung: 'wertsicherung',
  documentList: 'documentList',
  deleteDocumentFileIdList: 'deleteDocumentFileIdList',
};

export const mapToUpdateFormValues = (infofeld: ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment): HausbetreuungUpdateFormValues => ({
  contactPersonId: infofeld.contactPerson?.contactPersonId ?? undefined,
  rechtstraegerType: infofeld.hausbetreuungRechtstraeger?.type.value ?? undefined,
  beauftragungsgrund: infofeld.beauftragungsgrund,
  beginn: infofeld.beginn ?? undefined,
  ende: infofeld.ende ?? undefined,
  kuendigungsfrist: infofeld.kuendigungsfrist?.value ?? null,
  kuendigungsfristDauer: infofeld.kuendigungsfristDauer ?? null,
  kuendigungsfristJeweilsZum: infofeld.kuendigungsfristJeweilsZum?.value ?? undefined,
  rechtstraegerId: infofeld.hausbetreuungRechtstraeger?.rechtstraegerId ?? undefined,
  vertragsdaten: infofeld.vertragsdaten ?? undefined,
  wertsicherung: infofeld.wertsicherung ?? undefined,
  documentList: [],
  deleteDocumentFileIdList: [],
});
