import { Dayjs } from 'dayjs';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { VertragVposIndexedValue, VertragVposIndexedValueUpdateInput } from '../../../../types';

// Define the form values
export type VertragVposIndexedValueFormValues = {
  vertragVposIndexedValueId: string;
  amount: number;
  factorPercent: number;
  validFrom: Dayjs | string;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  vorschreibungspositionId: string;
  indexationAgreementId: string;
};

// Define the form fields
export const vertragVposIndexedValueFormFields: FormFields<VertragVposIndexedValueFormValues> = {
  vertragVposIndexedValueId: 'vertragVposIndexedValueId',
  amount: 'amount',
  factorPercent: 'factorPercent',
  validFrom: 'validFrom',
  objektId: 'objektId',
  bestandseinheitId: 'bestandseinheitId',
  vertragId: 'vertragId',
  vorschreibungspositionId: 'vorschreibungspositionId',
  indexationAgreementId: 'indexationAgreementId',
};

// Define the mapper functions
export const mapVertragVposIndexedValueToFormValues = (indexedValue: VertragVposIndexedValue): VertragVposIndexedValueFormValues => ({
  vertragVposIndexedValueId: indexedValue.vertragVposIndexedValueId,
  amount: indexedValue.amountNew,
  factorPercent: indexedValue.factorNewPercent,
  validFrom: indexedValue.validFrom,
  objektId: indexedValue.objektId,
  bestandseinheitId: indexedValue.bestandseinheitId,
  vertragId: indexedValue.vertragId,
  vorschreibungspositionId: indexedValue.vorschreibungspositionId,
  indexationAgreementId: indexedValue.indexationAgreement.vertragVposIndexationAgreementId,
});

export const mapFormValuesToVertragVposIndexedValue = (values: VertragVposIndexedValueFormValues): VertragVposIndexedValueUpdateInput => ({
  amount: values.amount,
  factorPercent: values.factorPercent,
  validFrom: mapFormDateValueToDateString(dayjsCustom(values.validFrom).startOf('month')),
});
