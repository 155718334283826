import React, { FC } from 'react';
import { FormikProps } from 'formik';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { kontoFormFields, KontoFormValues } from '../../kontoFormMapper';
import KontoFormVerwendung from './KontoFormVerwendung';

type Props = {
  disabled: boolean;
  formikProps: FormikProps<KontoFormValues>;
};
const KontoFormItemVerwendung: FC<Props> = ({ disabled, formikProps }) => (
  <FormItemWithoutColon name={kontoFormFields.verwendung} label="Kontoverwendung">
    <KontoFormVerwendung
      id={kontoFormFields.verwendung}
      name={kontoFormFields.verwendung}
      placeholder="Kontoverwendung auswählen"
      allowClear={false}
      disabled={disabled}
      formikProps={formikProps}
    />
  </FormItemWithoutColon>
);

export default KontoFormItemVerwendung;
