import React, { FC } from 'react';
import { useField } from 'formik';
import { Select, SelectProps } from 'formik-antd';
import { kontoFormFields } from '../../kontoFormMapper';
import { useKontoKlasseListQuery } from '../../../gql/KontoKlasseQueries.types';

/**
 * <h2>Usage</h2>
 * As listing filter or in Create/Update form where a Konto Klasse has to be chosen from a dropdown list.
 *
 */
const KontoKlasseSelect: FC<SelectProps> = (props) => {
  const { loading, data } = useKontoKlasseListQuery();
  // FIXME: use onChange instead
  const [, , { setValue: setKontoVerwendungToUndefined }] = useField<undefined>(kontoFormFields.verwendung);

  const kontoKlasseList = data?.getKontoKlasseList?.data ?? [];

  return (
    <Select loading={loading} allowClear {...props} onChange={() => setKontoVerwendungToUndefined(undefined)}>
      {kontoKlasseList.map((kontoklasse) => (
        <Select.Option key={kontoklasse.text} value={kontoklasse.value}>
          {kontoklasse.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default KontoKlasseSelect;
