import * as Yup from 'yup';
import { validateGeneralBezeichnung } from '../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';
import { abrechnungskreisFormFields } from './abrechnungskreisFormMapper';
import { entityIsRequired } from '../../../components/message/validationMsg';
import { AbrechnungskreisType } from '../../../types';

/* eslint-disable no-template-curly-in-string */
export const abrechnungskreisValidationSchema = Yup.object().shape({
  [abrechnungskreisFormFields.bezeichnung]: validateGeneralBezeichnung(100, true),
  [abrechnungskreisFormFields.type]: Yup.string().required(entityIsRequired('Typ')),
  [abrechnungskreisFormFields.verrechnungUstVomAufwand]: Yup.string().when(abrechnungskreisFormFields.type, ([type], schema) =>
    type === AbrechnungskreisType.ReparaturFonds ? schema.required(entityIsRequired('Verrechnung Umsatzsteuer vom Aufwand')) : schema
  ),
});

/* eslint-enable no-template-curly-in-string */
