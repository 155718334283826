import { HeOrSubAbrDefFieldHelpNames, HeOrSubAbrDefOutputOptionsFieldHelpNames } from './abrDefFieldHelpMapper';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

export const useHeAbrDefFieldHelp = (): HeOrSubAbrDefFieldHelpNames => {
  const getFieldHelpText = useGetAppFieldHelpText<'HeAbrechnungsdefinition'>('HeAbrechnungsdefinition');

  const fieldHelpAbrDefAbrechkreisList = getFieldHelpText('HeAbrechnungsdefinition.abrechdefAbrechkreisList');

  const fieldHelpNamesOutputOptions: HeOrSubAbrDefOutputOptionsFieldHelpNames = {
    displayKontonummer: getFieldHelpText('HeAbrechnungsdefinition.outputOptions.displayKontonummer'),
    rueckstaendeAnzeigen: getFieldHelpText('HeAbrechnungsdefinition.outputOptions.rueckstaendeAnzeigen'),
    rueckstaendeInAbrechnungsergebnis: getFieldHelpText('HeAbrechnungsdefinition.outputOptions.rueckstaendeInAbrechnungsergebnis'),
  };

  return { fieldHelpAbrDefAbrechkreisList, fieldHelpNamesOutputOptions };
};
