import React, { FC, PropsWithChildren } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';

const styleForIconAndButton: React.CSSProperties = {
  width: '30px',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
};

const StyledSpaceForListItem = styled(Space)`
  display: flex;
  .ant-space-item {
    &:first-child {
      flex: 1;
    }
  }
`;

const StyledSpaceForListItemContent: FC<PropsWithChildren> = ({ children }) => (
  <Space align="start" style={{ alignItems: 'stretch', height: '100%' }}>
    {children}
  </Space>
);

const StyledSpaceForDetails: FC<PropsWithChildren> = ({ children }) => (
  <Space direction="vertical" style={{ width: '220px', padding: '10px 0' }}>
    {children}
  </Space>
);

export { styleForIconAndButton, StyledSpaceForListItemContent, StyledSpaceForDetails, StyledSpaceForListItem };
