import { message } from 'antd';
import Fetcher from '../../../../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../../../../helpers/APIUtils';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { mapErrorToErrorMessage } from '../../../../../../helpers/errorAndWarningHelper';
import { PaymentTransactionType } from '../../../../../../types';

export const handleImportBankstatementPerEbicsUserSubmit = (firmendatenId: string, ebicsUserId: string, onSuccess: () => void) => {
  const formData = new FormData();

  formData.append(
    'request',
    new Blob([JSON.stringify({ data: { ebicsUserId, paymentTransactionType: PaymentTransactionType.Ebics } })], { type: 'application/json' })
  );

  Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/camt-generierlauf`, {
      method: 'POST',
      body: formData,
    })
    .then(processResponse)
    .then((response: any) => {
      // @ts-ignore
      if (response.data.data) {
        showSuccessMsgOther(`EBICS Kontoauszüge wurden erfolgreich importiert`);
        onSuccess();
        // @ts-ignore
      } else if (response && response.data.warningList.length > 0) {
        message.warning(`${response.data.warningList[0].message}`);
      }
    })
    .catch(processAndReturnError)
    .catch((error) => {
      return message.error({
        content: `EBICS Kontoauszüge konnte nicht importiert werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};
