import React, { FC, useState } from 'react';
import { Drawer, Modal, Space } from 'antd';
import { RechtstraegerBase, Ticket } from '../../../../types';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { getGroupedParticipantListByContact, getGroupedParticipantListByEmail, TicketParticipantWithEmail } from './ticketParticipantHelpers';
import { useToggle } from '../../../../hooks/useToggle';
import { useTicketParticipantMutation } from '../../gql/TicketMutations.types';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import ParticipantWithRechtstraeger from './ParticipantWithRechtstraeger';
import { ParticipantWithEmailAddress } from './ParticipantWithEmailAddress';
import TicketParticipantAssignForm from '../../Form/TicketParticipantAssignForm/TicketParticipantAssignForm';

type Props = {
  ticket: Ticket;
  refetch: () => void;
};

const TicketParticipantsInfoRow: FC<Props> = ({ ticket, refetch }) => {
  const participants: TicketParticipantWithEmail[] = [
    ...ticket.participantList,
    ...ticket.emailList.map((email) => {
      return {
        ...email.ticketParticipant,
        email: {
          sender: email.sender,
          sentTs: email.sentTs,
          subject: email.subject,
        },
      };
    }),
  ];

  const groupedParticipantListByContact = getGroupedParticipantListByContact(participants);
  const groupedParticipantListByEmail = getGroupedParticipantListByEmail(participants);
  const [isOpen, toggleIsOpen] = useToggle();
  const [participantIds, setRarticipantIds] = useState<string[]>([]);
  const [emailAddress, setEmailAddress] = useState<string | null>(null);

  const handleModalClose = () => {
    setRarticipantIds([]);
    setEmailAddress(null);
    toggleIsOpen();
  };

  const [runAssign, { loading: loadingAssign }] = useTicketParticipantMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Die Beteiligten der Aufgabe');
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleAssignParticipant = (participantIds: string[], emailAddress: string) => {
    setEmailAddress(emailAddress);
    setRarticipantIds(participantIds);
    toggleIsOpen();
  };

  const handleUnassignParticipant = (participantIds: string[], rechtstraeger: RechtstraegerBase) => {
    showConfirmUnassign(rechtstraeger?.kurzBezeichnung ?? '', () => {
      runAssign({
        variables: {
          ticketId: ticket.ticketId,
          input: {
            participantIdList: participantIds,
            rechtstraegerId: null,
          },
        },
      });
    });
  };

  return (
    <>
      <CardDetailsInfoRow infoRowTitle="Beteiligte">
        {participants.length === 0 ? (
          <TextForEmptyValue textToShow="minus" />
        ) : (
          <>
            <Space direction="vertical" size={0}>
              {groupedParticipantListByContact.map((participant) => (
                <ParticipantWithRechtstraeger
                  key={participant.contact?.contactId}
                  ticket={ticket}
                  participant={participant}
                  handleUnassignParticipant={handleUnassignParticipant}
                  loadingAssign={loadingAssign}
                />
              ))}
              {groupedParticipantListByEmail.map((participant) => (
                <ParticipantWithEmailAddress
                  key={participant.emailAddress}
                  ticket={ticket}
                  participant={participant}
                  handleAssignParticipant={handleAssignParticipant}
                  loadingAssign={loadingAssign}
                />
              ))}
            </Space>
          </>
        )}
      </CardDetailsInfoRow>
      <Drawer title="Die E-Mail einer Firma/Person zuordnen" width={720} open={isOpen} onClose={handleModalClose} destroyOnClose>
        <TicketParticipantAssignForm
          ticketId={ticket.ticketId}
          participantIds={participantIds}
          emailAddress={emailAddress}
          onSuccess={() => {
            handleModalClose();
            refetch();
          }}
          onCancel={handleModalClose}
        />
      </Drawer>
    </>
  );
};

const showConfirmUnassign = (kurzBezeichnung: string, onOk: () => void) => {
  Modal.confirm({
    title: `Möchten Sie die Zuordnung für ${kurzBezeichnung} aufheben?`,
    okButtonProps: { danger: true },
    okText: 'Ja',
    cancelText: 'Nein',
    onOk,
  });
};

export default TicketParticipantsInfoRow;
