import React, { FC } from 'react';
import { Space, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { EuroAmount } from '../../../../../components/Number';
import { AbrechnungType } from '../../../../../types';
import { isAbrechnungTypeWeAbrechnung } from '../../../shared/abrechnungHelpers';
import { pathsToWeAbrechnungDetails } from '../../weAbrechnungUriPaths';
import { pathsToBkAbrechnungDetails } from '../../bkAbrechnungUriPaths';

type Props = {
  abrechnungType: AbrechnungType;
  rueckstand: number;
  objektId: string;
  objektAbrechnungId: string;
};

const AbrechnungsRueckstand: FC<Props> = ({ abrechnungType, rueckstand, objektAbrechnungId, objektId }) => {
  const linkToAbrechnung = isAbrechnungTypeWeAbrechnung(abrechnungType)
    ? pathsToWeAbrechnungDetails(objektId, objektAbrechnungId).zahlungsrueckstandTab
    : pathsToBkAbrechnungDetails(objektId, objektAbrechnungId).zahlungsrueckstandTab;

  return (
    <Space direction="vertical" align="end" style={{ width: '100%' }}>
      <Typography.Text style={{ fontWeight: 500 }}>Rückstand des Bestandnehmers:</Typography.Text>
      <Space style={{ justifyContent: 'space-between', width: '100%' }} size={0}>
        <Tag color={rueckstand > 0 ? 'red' : 'green'}>Offener Posten</Tag>
        <Link to={linkToAbrechnung} target="_blank">
          <EuroAmount value={rueckstand} />
        </Link>
      </Space>
    </Space>
  );
};

export default AbrechnungsRueckstand;
