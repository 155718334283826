import React, { useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { vorschreibungsPositionWertFormFields, VorschreibungspositionWertFormValues } from '../vertragVorschreibungsPositionWertMapper';
import { isBruttoValid, isNettoValid, isSteuersatzValid } from '../vertragVorschreibungsPositionWertSchema';
import { EuroAmount } from '../../../../../../../../components/Number';
import { calculateUstBasedOnBrutto, calculateUstBasedOnNetto } from '../../../../../../../../shared/BruttoNettoUst/bruttoNettoCalculator';
import { VorschreibungspositionBasis } from '../../../../../../../../types';
import SkeletonIfSteuersatzLoading from '../SkeletonIfSteuersatzLoading';

const Ust = () => {
  const fieldNameUst = vorschreibungsPositionWertFormFields.ust;
  const [{ value: ustValue }, , { setValue, setTouched }] = useField<number>(fieldNameUst);
  const { values } = useFormikContext<VorschreibungspositionWertFormValues>();

  const currentBasis = values.basis;
  const currentSteuersatzText = values.steuersatz;
  const currentNetto = values.netto;
  const currentBrutto = values.brutto;
  const currentSteuersatz = currentSteuersatzText;

  const previousNettoRef = useRef<number | null | ''>();
  const previousBruttoRef = useRef<number | null | ''>();
  const previousSteuersatzRef = useRef<number | null>();

  const previousNetto = previousNettoRef.current;
  const previousSteuersatz = previousSteuersatzRef.current;
  const previousBrutto = previousBruttoRef.current;

  useEffect(() => {
    // basis == netto and netto or steuersatz changes => update ust
    if (currentBasis === VorschreibungspositionBasis.Netto) {
      if (previousNetto === currentNetto && previousSteuersatz === currentSteuersatz) {
        return;
      }
      if (!isNettoValid(currentNetto) || !isSteuersatzValid(currentSteuersatz)) {
        return;
      }
      const ust = calculateUstBasedOnNetto(currentNetto as number, currentSteuersatz);
      setValue(ust);
      setTouched(true);
    }
    // basis == brutto and brutto or steuersatz changes => update ust
    if (currentBasis === VorschreibungspositionBasis.Brutto) {
      if (previousBrutto === currentBrutto && previousSteuersatz === currentSteuersatz) {
        return;
      }
      if (!isBruttoValid(currentBrutto) || !isSteuersatzValid(currentSteuersatz)) {
        return;
      }
      const ust = calculateUstBasedOnBrutto(currentBrutto as number, currentSteuersatz);
      setValue(ust);
      setTouched(true);
    }
  });

  useEffect(() => {
    previousNettoRef.current = currentNetto;
    previousBruttoRef.current = currentBrutto;
    previousSteuersatzRef.current = currentSteuersatz;
  });

  return (
    <SkeletonIfSteuersatzLoading>
      <EuroAmount value={ustValue} />
    </SkeletonIfSteuersatzLoading>
  );
};

export default Ust;
