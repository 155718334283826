import React, { FC } from 'react';
import { bestandseinheitAufteilungsschluesselWertFormValidationSchema } from '../../bestandseinheitAufteilungsschluesselWertFormValidationSchema';
import { showSuccessMsgCreate, showSuccessMsgUpdate, showWarningMessage } from '../../../../../components/message';
import {
  useCreateBestandseinheitAufteilungsschluesselWertOhneBezugMutation,
  useUpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutation,
} from '../../gql/BestandseinheitAufteilungsschluesselMutations.types';
import beAufteilungsschluesselWertOhneBezugTableColumns from './BeAufteilungsschluesselWertOhneBezugTableColumns';
import {
  BeAufteilungsschluesselWertOhneBezugFormValues,
  createDefaultNewEntryForWertOhneBezug,
  mapFormValuesToWertOhneBezug,
  mapOhneBezugWertToFormValues,
} from './BeAufteilungsschluesselWertOhneBezugMapper';
import { BeAufteilungsschluesselWertTableProps } from '../shared/BeAufteilungsschluesselWertTableProps';
import { bestandseinheitAufteilungsschluesselEditableTableGetRowKey } from '../shared/beAufteilungsschluesselWertHelper';
import { BeAufteilingsschluesselWert } from '../../../../../types';
import EditableTable from '../../../../../components/Table/EditableTableV2/EditableTable';

const BeAufteilungsschluesselWertOhneBezugTable: FC<BeAufteilungsschluesselWertTableProps> = ({
  expanded,
  aufteilungsschluessel,
  onSuccess,
  objektId,
  bestandseinheitId,
  bestandseinheitAufteilungsschluesselWertList,
  isBestandseinheitAufteilungsschluesselWertListLoading,
}) => {
  const { aufteilungsschluesselId } = aufteilungsschluessel;

  const entity = 'Wert';
  const errorMsg = 'Wert wurde nicht aktualisiert';

  const [runCreateWertOhneBezug] = useCreateBestandseinheitAufteilungsschluesselWertOhneBezugMutation();
  const [runUpdateWertOhneBezug] = useUpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutation();

  return (
    <EditableTable<BeAufteilingsschluesselWert, BeAufteilungsschluesselWertOhneBezugFormValues>
      pagination={false}
      expanded={expanded}
      getRowKey={bestandseinheitAufteilungsschluesselEditableTableGetRowKey}
      columns={beAufteilungsschluesselWertOhneBezugTableColumns(aufteilungsschluesselId, bestandseinheitAufteilungsschluesselWertList, onSuccess)}
      loading={isBestandseinheitAufteilungsschluesselWertListLoading}
      dataSource={bestandseinheitAufteilungsschluesselWertList}
      newEntryRowToBeAdded={{
        addButtonText: 'Wert hinzufügen',
        defaultNewEntry: createDefaultNewEntryForWertOhneBezug(bestandseinheitAufteilungsschluesselWertList),
      }}
      mapToFormValues={(selectedRecord) => mapOhneBezugWertToFormValues(bestandseinheitAufteilungsschluesselWertList, selectedRecord)}
      validationSchema={bestandseinheitAufteilungsschluesselWertFormValidationSchema}
      onSubmit={(formValues) => {
        const wert = mapFormValuesToWertOhneBezug(formValues);
        if (!formValues.beAufteilungsschluesselWertId) {
          return runCreateWertOhneBezug({
            variables: {
              objektId,
              bestandseinheitId,
              aufteilungsschluesselId,
              BeAufteilingsschluesselWertOhneBezugInput: wert,
            },
          })
            .then((response) => {
              showSuccessMsgCreate(entity);
              onSuccess();
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              return response.data!.createBeAufteilungsschluesselWertOhneBezug.data;
            })
            .catch((error) => {
              showWarningMessage(error, errorMsg);
              throw error;
            });
        } else {
          return runUpdateWertOhneBezug({
            variables: {
              objektId,
              bestandseinheitId,
              aufteilungsschluesselId,
              beAufteilungsschluesselWertId: formValues.beAufteilungsschluesselWertId,
              BeAufteilingsschluesselWertOhneBezugInput: wert,
            },
          })
            .then((response) => {
              showSuccessMsgUpdate(`Wert`);
              onSuccess();
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              return response.data!.updateBeAufteilungsschluesselWertOhneBezug.data;
            })
            .catch((error) => {
              showWarningMessage(error, errorMsg);
              throw error;
            });
        }
      }}
    />
  );
};

export default BeAufteilungsschluesselWertOhneBezugTable;
