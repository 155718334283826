import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateWeAbrechnungsdefinitionTemplateMutationVariables = Types.Exact<{
  input: Types.WeAbrechnungsdefinitionCreateInput;
}>;

export type CreateWeAbrechnungsdefinitionTemplateMutation = {
  createWeAbrechnungsdefinitionTemplate: {
    data: {
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateWeAbrechnungsdefinitionTemplateMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  input: Types.WeAbrechnungsdefinitionUpdateInput;
}>;

export type UpdateWeAbrechnungsdefinitionTemplateMutation = {
  updateWeAbrechnungsdefinitionTemplate: {
    data: {
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateWeAbrechnungsdefinitionTemplateDocument = gql`
  mutation createWeAbrechnungsdefinitionTemplate($input: WeAbrechnungsdefinitionCreateInput!) {
    createWeAbrechnungsdefinitionTemplate(input: $input) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useCreateWeAbrechnungsdefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWeAbrechnungsdefinitionTemplateMutation, CreateWeAbrechnungsdefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWeAbrechnungsdefinitionTemplateMutation, CreateWeAbrechnungsdefinitionTemplateMutationVariables>(
    CreateWeAbrechnungsdefinitionTemplateDocument,
    options
  );
}
export type CreateWeAbrechnungsdefinitionTemplateMutationHookResult = ReturnType<typeof useCreateWeAbrechnungsdefinitionTemplateMutation>;
export type CreateWeAbrechnungsdefinitionTemplateMutationResult = Apollo.MutationResult<CreateWeAbrechnungsdefinitionTemplateMutation>;
export type CreateWeAbrechnungsdefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateWeAbrechnungsdefinitionTemplateMutation,
  CreateWeAbrechnungsdefinitionTemplateMutationVariables
>;
export const UpdateWeAbrechnungsdefinitionTemplateDocument = gql`
  mutation UpdateWeAbrechnungsdefinitionTemplate($abrechnungsdefinitionId: ID!, $input: WeAbrechnungsdefinitionUpdateInput!) {
    updateWeAbrechnungsdefinitionTemplate(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $input) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useUpdateWeAbrechnungsdefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWeAbrechnungsdefinitionTemplateMutation, UpdateWeAbrechnungsdefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWeAbrechnungsdefinitionTemplateMutation, UpdateWeAbrechnungsdefinitionTemplateMutationVariables>(
    UpdateWeAbrechnungsdefinitionTemplateDocument,
    options
  );
}
export type UpdateWeAbrechnungsdefinitionTemplateMutationHookResult = ReturnType<typeof useUpdateWeAbrechnungsdefinitionTemplateMutation>;
export type UpdateWeAbrechnungsdefinitionTemplateMutationResult = Apollo.MutationResult<UpdateWeAbrechnungsdefinitionTemplateMutation>;
export type UpdateWeAbrechnungsdefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateWeAbrechnungsdefinitionTemplateMutation,
  UpdateWeAbrechnungsdefinitionTemplateMutationVariables
>;
