import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';

export type UstVomAufwandFreigbenModalValues = {
  buchungsdatum?: string | null;
};

export const ustVomAufwandFreigebenModalFormFields: FormFields<UstVomAufwandFreigbenModalValues> = {
  buchungsdatum: 'buchungsdatum',
};

export const ustVomAufwandFreigebenModalFormInitialValues: UstVomAufwandFreigbenModalValues = {
  buchungsdatum: null,
};

export const mapFormValuesToUstVomAufwandFreigeben = (values: UstVomAufwandFreigbenModalValues) => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  buchungsdatum: mapFormDateValueToDateString(values.buchungsdatum!),
});
