import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { FormikProps } from 'formik';
import { Tag, Typography } from 'antd';
import { selectFilterOption } from '../../helpers/selectHelper';
import { useFirmendatenObjektAbrechnungListQuery } from './gql/FirmendatenQueries.types';
import { AbrechnungType } from '../../types';

type Props = {
  name: string;
  formikProps: FormikProps<any>;
  objektIdList?: string[] | null;
  abrechnungTypeList?: AbrechnungType[] | null;
} & SelectProps;

const FirmendatenObjAbrSelectWithAllTag: FC<Props> = ({ name, formikProps, objektIdList, abrechnungTypeList, ...restProps }) => {
  const { data, loading } = useFirmendatenObjektAbrechnungListQuery({
    variables: {
      objektIdList,
      abrechnungTypeList,
    },
  });
  const objektAbrList = data?.getFirmendatenObjektAbrechnungList.data || [];

  const onChange = (value: string[], option: any | any[]) => {
    if (value.length === 0 || value[value.length - 1] === ALLE_TAG_TEXT) {
      formikProps.setFieldValue(name, [ALLE_TAG_TEXT]);
    } else {
      const newValue = value.filter((val: string) => val !== ALLE_TAG_TEXT);
      formikProps.setFieldValue(name, newValue);
    }
    restProps.onChange?.(value, option);
  };

  const tagRender = (props: { label: React.ReactNode; value: any; onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void }) => {
    const { label, value, onClose } = props;

    return (
      <Tag closable={value !== ALLE_TAG_TEXT} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  return (
    <Select
      {...restProps}
      name={name}
      loading={loading}
      placeholder="Objektabrechnung auswählen"
      showSearch
      filterOption={selectFilterOption}
      mode="multiple"
      style={{ width: '100%' }}
      onChange={onChange}
      tagRender={tagRender}
      defaultValue={[ALLE_TAG_TEXT]}
    >
      <Select.Option key={ALLE_TAG_TEXT} value={ALLE_TAG_TEXT} style={{ backgroundColor: '#f5f5f5' }}>
        <Typography.Text>{ALLE_TAG_TEXT}</Typography.Text>
      </Select.Option>
      {objektAbrList.map((objekt) => (
        <Select.Option key={objekt.objektAbrechnungId} value={objekt.objektAbrechnungId}>
          <Typography.Text>{objekt.bezeichnung}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export const ALLE_TAG_TEXT = 'Alle';

export default FirmendatenObjAbrSelectWithAllTag;
