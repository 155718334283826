import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { MahnungFieldsFragmentDoc } from '../../../Mahnung/gql/MahnungFragments.types';
import { FibuRechtstraegerFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { FibuObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MahnungVerbuchenGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type MahnungVerbuchenGenerierlaufQuery = {
  getMahnungVerbuchenGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      erstellerMitarbeiterIdList: Array<string>;
      generierDatum: string;
      generierlaufId: string;
      mahnstichtagBisInklusive?: string | null;
      mahnstichtagVonInklusive?: string | null;
      errorList: Array<{ message: string; type: string }>;
      mahnung?: {
        buchungId?: string | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        fileId?: string | null;
        freigegebenTs?: string | null;
        mahngebuehr: number;
        mahnstichtag: string;
        mahnungEmail?: string | null;
        mahnungId: string;
        opEmail: string;
        rechnungsAusstellerBankDetailsAccountHolder: string;
        rechnungsAusstellerBankDetailsIban: string;
        updatedByMitarbeiterId?: string | null;
        sumMahnbetrag: number;
        sumRechnungsbetrag: number;
        sumVerzugszinsen: number;
        sumZahlungen: number;
        versandEinschreiben: boolean;
        versendetTs?: string | null;
        verzugszinsen: number;
        buchungskreis: {
          buchungskreisId: string;
          rechtstraeger: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
        };
        debitorKonto: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          haupt: boolean;
          kontoId: string;
          kontoTemplateId?: string | null;
          nummer: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          klasse: { text: string; value: Types.KontoKlasse };
          status: { description?: string | null; text: string; value: Types.KontoStatus };
          type: { text: string; value: Types.KontoType };
          verwendung?: { text: string; value: Types.KontoVerwendung } | null;
        };
        mahnPositionList: Array<{
          belegDatum: string;
          belegFileId: string;
          belegnummer: string;
          buchungskreisId: string;
          dataCarrierBelegId?: string | null;
          dueDate: string;
          gesamtBetrag: number;
          iban?: string | null;
          letzterMahnstichtag?: string | null;
          mahnPositionId: string;
          mahnstufe: number;
          offenerBetrag: number;
          statementNumber?: string | null;
          verzugszinsen: number;
          verzugszinsenBerechnen: boolean;
          zahlungBetrag: number;
          belegSymbol: { abbreviation: string; text: string; value: Types.FibuBelegSymbol };
        }>;
        mahnungDeliveryAddress?: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          type: Types.AddressType;
          zipCode?: string | null;
        } | null;
        mailDelivery?: { mailId?: string | null; status: { text: string; value: Types.MahnungMailDeliveryStatus } } | null;
        objektList: Array<{ kurzBezeichnung: string; objektId: string }>;
        opDeliveryAddress: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          type: Types.AddressType;
          zipCode?: string | null;
        };
        opZustellRechtstraeger: {
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
        };
        rechnungsAussteller: {
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
        };
        status: { value: Types.MahnungStatus; text: string };
        vertragspartner: {
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
        };
      } | null;
      objekt?: { kurzBezeichnung: string; objektId: string } | null;
      rechnungsAussteller?: {
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
      } | null;
      status: { value: Types.GenerierlaufStatus; text: string };
      vertragspartner?: {
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
      } | null;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungVerbuchenGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsausstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.MahnungGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type MahnungVerbuchenGenerierlaufEntryListQuery = {
  getMahnungVerbuchenGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        retry: boolean;
        stepCount?: number | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        mahnung: {
          buchungId?: string | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          fileId?: string | null;
          freigegebenTs?: string | null;
          mahngebuehr: number;
          mahnstichtag: string;
          mahnungEmail?: string | null;
          mahnungId: string;
          opEmail: string;
          rechnungsAusstellerBankDetailsAccountHolder: string;
          rechnungsAusstellerBankDetailsIban: string;
          updatedByMitarbeiterId?: string | null;
          sumMahnbetrag: number;
          sumRechnungsbetrag: number;
          sumVerzugszinsen: number;
          sumZahlungen: number;
          versandEinschreiben: boolean;
          versendetTs?: string | null;
          verzugszinsen: number;
          buchungskreis: {
            buchungskreisId: string;
            rechtstraeger: {
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
            };
          };
          debitorKonto: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            haupt: boolean;
            kontoId: string;
            kontoTemplateId?: string | null;
            nummer: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            klasse: { text: string; value: Types.KontoKlasse };
            status: { description?: string | null; text: string; value: Types.KontoStatus };
            type: { text: string; value: Types.KontoType };
            verwendung?: { text: string; value: Types.KontoVerwendung } | null;
          };
          mahnPositionList: Array<{
            belegDatum: string;
            belegFileId: string;
            belegnummer: string;
            buchungskreisId: string;
            dataCarrierBelegId?: string | null;
            dueDate: string;
            gesamtBetrag: number;
            iban?: string | null;
            letzterMahnstichtag?: string | null;
            mahnPositionId: string;
            mahnstufe: number;
            offenerBetrag: number;
            statementNumber?: string | null;
            verzugszinsen: number;
            verzugszinsenBerechnen: boolean;
            zahlungBetrag: number;
            belegSymbol: { abbreviation: string; text: string; value: Types.FibuBelegSymbol };
          }>;
          mahnungDeliveryAddress?: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          } | null;
          mailDelivery?: { mailId?: string | null; status: { text: string; value: Types.MahnungMailDeliveryStatus } } | null;
          objektList: Array<{ kurzBezeichnung: string; objektId: string }>;
          opDeliveryAddress: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
          opZustellRechtstraeger: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
          rechnungsAussteller: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
          status: { value: Types.MahnungStatus; text: string };
          vertragspartner: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
        };
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { size: number; number: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const MahnungVerbuchenGenerierlaufDocument = gql`
  query MahnungVerbuchenGenerierlauf($generierlaufId: ID!) {
    getMahnungVerbuchenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        erstellerMitarbeiterIdList
        generierDatum
        generierlaufId
        mahnstichtagBisInklusive
        mahnstichtagVonInklusive
        mahnung {
          ...MahnungFields
        }
        objekt {
          ...FibuObjekt
        }
        rechnungsAussteller {
          ...FibuRechtstraeger
        }
        status {
          value
          text
        }
        vertragspartner {
          ...FibuRechtstraeger
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MahnungFieldsFragmentDoc}
  ${FibuObjektFragmentDoc}
  ${FibuRechtstraegerFragmentDoc}
`;
export function useMahnungVerbuchenGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<MahnungVerbuchenGenerierlaufQuery, MahnungVerbuchenGenerierlaufQueryVariables> &
    ({ variables: MahnungVerbuchenGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungVerbuchenGenerierlaufQuery, MahnungVerbuchenGenerierlaufQueryVariables>(
    MahnungVerbuchenGenerierlaufDocument,
    options
  );
}
export function useMahnungVerbuchenGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnungVerbuchenGenerierlaufQuery, MahnungVerbuchenGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungVerbuchenGenerierlaufQuery, MahnungVerbuchenGenerierlaufQueryVariables>(
    MahnungVerbuchenGenerierlaufDocument,
    options
  );
}
export function useMahnungVerbuchenGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnungVerbuchenGenerierlaufQuery, MahnungVerbuchenGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungVerbuchenGenerierlaufQuery, MahnungVerbuchenGenerierlaufQueryVariables>(
    MahnungVerbuchenGenerierlaufDocument,
    options
  );
}
export type MahnungVerbuchenGenerierlaufQueryHookResult = ReturnType<typeof useMahnungVerbuchenGenerierlaufQuery>;
export type MahnungVerbuchenGenerierlaufLazyQueryHookResult = ReturnType<typeof useMahnungVerbuchenGenerierlaufLazyQuery>;
export type MahnungVerbuchenGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useMahnungVerbuchenGenerierlaufSuspenseQuery>;
export type MahnungVerbuchenGenerierlaufQueryResult = Apollo.QueryResult<
  MahnungVerbuchenGenerierlaufQuery,
  MahnungVerbuchenGenerierlaufQueryVariables
>;
export const MahnungVerbuchenGenerierlaufEntryListDocument = gql`
  query MahnungVerbuchenGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $rechnungsausstellerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: MahnungGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getMahnungVerbuchenGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      rechnungsausstellerId: $rechnungsausstellerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          currentStep
          errorList {
            message
            type
          }
          exitCode {
            value
            text
          }
          generierlaufEntryId
          infoMessage
          mahnung {
            ...MahnungFields
          }
          retry
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          warningList {
            message
            type
          }
        }
        page {
          size
          number
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MahnungFieldsFragmentDoc}
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function useMahnungVerbuchenGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnungVerbuchenGenerierlaufEntryListQuery, MahnungVerbuchenGenerierlaufEntryListQueryVariables> &
    ({ variables: MahnungVerbuchenGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungVerbuchenGenerierlaufEntryListQuery, MahnungVerbuchenGenerierlaufEntryListQueryVariables>(
    MahnungVerbuchenGenerierlaufEntryListDocument,
    options
  );
}
export function useMahnungVerbuchenGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnungVerbuchenGenerierlaufEntryListQuery, MahnungVerbuchenGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungVerbuchenGenerierlaufEntryListQuery, MahnungVerbuchenGenerierlaufEntryListQueryVariables>(
    MahnungVerbuchenGenerierlaufEntryListDocument,
    options
  );
}
export function useMahnungVerbuchenGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MahnungVerbuchenGenerierlaufEntryListQuery, MahnungVerbuchenGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungVerbuchenGenerierlaufEntryListQuery, MahnungVerbuchenGenerierlaufEntryListQueryVariables>(
    MahnungVerbuchenGenerierlaufEntryListDocument,
    options
  );
}
export type MahnungVerbuchenGenerierlaufEntryListQueryHookResult = ReturnType<typeof useMahnungVerbuchenGenerierlaufEntryListQuery>;
export type MahnungVerbuchenGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useMahnungVerbuchenGenerierlaufEntryListLazyQuery>;
export type MahnungVerbuchenGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<typeof useMahnungVerbuchenGenerierlaufEntryListSuspenseQuery>;
export type MahnungVerbuchenGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  MahnungVerbuchenGenerierlaufEntryListQuery,
  MahnungVerbuchenGenerierlaufEntryListQueryVariables
>;
