import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { VorschreibungspositionVersionFragmentDoc } from '../../../Vorschreibungsposition/Version/gql/VorschreibungspositionFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorschreibungspositionTemplateVersionListQueryVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type VorschreibungspositionTemplateVersionListQuery = {
  getVorschreibungspositionTemplateVersionList: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      validFrom: string;
      vorschreibungspositionVersionId: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        validFrom: string;
        vorschreibungspositionVersionId: string;
        kontierungstabelle: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        umsatzsteuerkennzeichen: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }> | null;
      kontierungstabelle: {
        basiskonto: number;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kontierungstabelleId: string;
        updatedByMitarbeiterId?: string | null;
        errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
        status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
        subAdministrationExpenseAccount?: {
          bezeichnung: string;
          firmendatenId?: string | null;
          kontoId: string;
          kontoKlasse: string;
          nummer: string;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      umsatzsteuerkennzeichen: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      };
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungspositionTemplateVersionQueryVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  vorschreibungspositionVersionId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type VorschreibungspositionTemplateVersionQuery = {
  getVorschreibungspositionTemplateVersion: {
    data: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      validFrom: string;
      vorschreibungspositionVersionId: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        validFrom: string;
        vorschreibungspositionVersionId: string;
        kontierungstabelle: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        umsatzsteuerkennzeichen: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }> | null;
      kontierungstabelle: {
        basiskonto: number;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kontierungstabelleId: string;
        updatedByMitarbeiterId?: string | null;
        errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
        status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
        subAdministrationExpenseAccount?: {
          bezeichnung: string;
          firmendatenId?: string | null;
          kontoId: string;
          kontoKlasse: string;
          nummer: string;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      umsatzsteuerkennzeichen: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      };
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VorschreibungspositionTemplateVersionListDocument = gql`
  query VorschreibungspositionTemplateVersionList($vorschreibungspositionId: ID!, $withDetails: Boolean) {
    getVorschreibungspositionTemplateVersionList(vorschreibungspositionId: $vorschreibungspositionId, withDetails: $withDetails) {
      data {
        ...VorschreibungspositionVersion
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungspositionVersionFragmentDoc}
`;
export function useVorschreibungspositionTemplateVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<VorschreibungspositionTemplateVersionListQuery, VorschreibungspositionTemplateVersionListQueryVariables> &
    ({ variables: VorschreibungspositionTemplateVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungspositionTemplateVersionListQuery, VorschreibungspositionTemplateVersionListQueryVariables>(
    VorschreibungspositionTemplateVersionListDocument,
    options
  );
}
export function useVorschreibungspositionTemplateVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorschreibungspositionTemplateVersionListQuery, VorschreibungspositionTemplateVersionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungspositionTemplateVersionListQuery, VorschreibungspositionTemplateVersionListQueryVariables>(
    VorschreibungspositionTemplateVersionListDocument,
    options
  );
}
export function useVorschreibungspositionTemplateVersionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VorschreibungspositionTemplateVersionListQuery, VorschreibungspositionTemplateVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungspositionTemplateVersionListQuery, VorschreibungspositionTemplateVersionListQueryVariables>(
    VorschreibungspositionTemplateVersionListDocument,
    options
  );
}
export type VorschreibungspositionTemplateVersionListQueryHookResult = ReturnType<typeof useVorschreibungspositionTemplateVersionListQuery>;
export type VorschreibungspositionTemplateVersionListLazyQueryHookResult = ReturnType<typeof useVorschreibungspositionTemplateVersionListLazyQuery>;
export type VorschreibungspositionTemplateVersionListSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungspositionTemplateVersionListSuspenseQuery
>;
export type VorschreibungspositionTemplateVersionListQueryResult = Apollo.QueryResult<
  VorschreibungspositionTemplateVersionListQuery,
  VorschreibungspositionTemplateVersionListQueryVariables
>;
export const VorschreibungspositionTemplateVersionDocument = gql`
  query VorschreibungspositionTemplateVersion($vorschreibungspositionId: ID!, $vorschreibungspositionVersionId: ID!, $withDetails: Boolean) {
    getVorschreibungspositionTemplateVersion(
      vorschreibungspositionId: $vorschreibungspositionId
      vorschreibungspositionVersionId: $vorschreibungspositionVersionId
      withDetails: $withDetails
    ) {
      data {
        ...VorschreibungspositionVersion
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungspositionVersionFragmentDoc}
`;
export function useVorschreibungspositionTemplateVersionQuery(
  baseOptions: Apollo.QueryHookOptions<VorschreibungspositionTemplateVersionQuery, VorschreibungspositionTemplateVersionQueryVariables> &
    ({ variables: VorschreibungspositionTemplateVersionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungspositionTemplateVersionQuery, VorschreibungspositionTemplateVersionQueryVariables>(
    VorschreibungspositionTemplateVersionDocument,
    options
  );
}
export function useVorschreibungspositionTemplateVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorschreibungspositionTemplateVersionQuery, VorschreibungspositionTemplateVersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungspositionTemplateVersionQuery, VorschreibungspositionTemplateVersionQueryVariables>(
    VorschreibungspositionTemplateVersionDocument,
    options
  );
}
export function useVorschreibungspositionTemplateVersionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VorschreibungspositionTemplateVersionQuery, VorschreibungspositionTemplateVersionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungspositionTemplateVersionQuery, VorschreibungspositionTemplateVersionQueryVariables>(
    VorschreibungspositionTemplateVersionDocument,
    options
  );
}
export type VorschreibungspositionTemplateVersionQueryHookResult = ReturnType<typeof useVorschreibungspositionTemplateVersionQuery>;
export type VorschreibungspositionTemplateVersionLazyQueryHookResult = ReturnType<typeof useVorschreibungspositionTemplateVersionLazyQuery>;
export type VorschreibungspositionTemplateVersionSuspenseQueryHookResult = ReturnType<typeof useVorschreibungspositionTemplateVersionSuspenseQuery>;
export type VorschreibungspositionTemplateVersionQueryResult = Apollo.QueryResult<
  VorschreibungspositionTemplateVersionQuery,
  VorschreibungspositionTemplateVersionQueryVariables
>;
