import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { StyledCard } from '../Styled/Card.style';
import VersandCardInfoRow from './VersandCardInfoRow';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import VersandCardExtra from './VersandCardExtra';
import VersandCardPostAddress from './VersandCardPostAddress';
import { DokumentenversandEntryFragment } from '../gql/BeVertragDokumentenVersandFragments.types';
import AuditFooter from '../../../../../components/Footer/AuditFooter';

type VersandCardProps = {
  isOriginal: boolean;
  dokumentenversandEntry: DokumentenversandEntryFragment;
  onSuccess: () => void;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
};

const VersandCard: FC<VersandCardProps> = ({ isOriginal, dokumentenversandEntry, onSuccess, bestandseinheitId, vertragId, objektId }) => {
  const isAktivText = (infoAktiv: boolean) => (infoAktiv ? 'aktiviert' : 'deaktiviert');
  const isAktivIcon = (infoAktiv: boolean) => (infoAktiv ? <CheckCircleOutlined /> : <CloseCircleOutlined />);

  const footer = (
    <AuditFooter
      createTs={dokumentenversandEntry.createTs}
      userId={dokumentenversandEntry.createdBy}
      mitarbeiterId={dokumentenversandEntry.createdByMitarbeiterId}
    />
  );

  return (
    <StyledCard
      isOriginal={isOriginal}
      title={isOriginal ? 'Original' : 'Kopie'}
      extra={
        <VersandCardExtra
          isOriginal={isOriginal}
          onActionSuccess={onSuccess}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          objektId={objektId}
          dokumentenversandEntry={dokumentenversandEntry}
          rechtstraegerKurzbezeichnung={dokumentenversandEntry.rechtstraeger.kurzBezeichnung}
        />
      }
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <VersandCardInfoRow infoRowTitle="Dokumentenversand">
          <Space>
            <Typography.Text>
              {isAktivIcon(dokumentenversandEntry.emailAktiv)} E-Mail {isAktivText(dokumentenversandEntry.emailAktiv)}
            </Typography.Text>
            {isOriginal && (
              <Typography.Text>
                {isAktivIcon(dokumentenversandEntry.postAktiv)} Post {isAktivText(dokumentenversandEntry.postAktiv)}
              </Typography.Text>
            )}
          </Space>
        </VersandCardInfoRow>
        <VersandCardInfoRow infoRowTitle="An">
          <Link
            to={`${generatePathToRechtstraegerDetailsPage(dokumentenversandEntry.rechtstraeger.rechtstraegerId)}/adressen-kontakte-personenbezuege`}
            target="_blank"
          >
            {dokumentenversandEntry.rechtstraeger.kurzBezeichnung}
          </Link>
        </VersandCardInfoRow>
        <VersandCardInfoRow infoRowTitle="E-Mail an">
          {dokumentenversandEntry.emailContact ? (
            <Typography.Text>{dokumentenversandEntry.emailContact.wert}</Typography.Text>
          ) : (
            <Typography.Text type="danger">{dokumentenversandEntry.missingEmailContactReason?.text}</Typography.Text>
          )}
        </VersandCardInfoRow>
        {isOriginal && (
          <VersandCardInfoRow infoRowTitle="Post an">
            {dokumentenversandEntry.postAddress ? (
              <VersandCardPostAddress postAddress={dokumentenversandEntry.postAddress} />
            ) : (
              <Typography.Text type="danger">{dokumentenversandEntry.missingPostAddressReason?.text}</Typography.Text>
            )}
          </VersandCardInfoRow>
        )}
      </Space>
      {footer}
    </StyledCard>
  );
};

export default VersandCard;
