import React, { FC } from 'react';
import { Typography } from 'antd';

type Props = {
  loading: boolean;
  text: string;
};

const SelectNotFoundContent: FC<Props> = ({ loading, text }) => {
  if (loading) {
    return <Typography.Text type="secondary">Laden...</Typography.Text>;
  }
  if (text.length <= 3) {
    return <Typography.Text type="secondary">Mindestens 3 Zeichen eingeben</Typography.Text>;
  }
  return <Typography.Text type="secondary">Keine Daten</Typography.Text>;
};

export default SelectNotFoundContent;
