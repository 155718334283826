import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { DocumentRowProps, findLabelValueByLabelId } from './util';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import OutdatedData from '../../../../components/Helpers/OutdatedData';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';
import { pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import { uriFileOfFirmendaten } from '../../documentApiPaths';
import { useHeAbrechnungHeVertragQuery } from '../../../Abrechnung/He/gql/HeAbrechnungQueries.types';
import { pathsToHeAbrechnungDetails } from '../../../Abrechnung/He/heAbrechnungUriPaths';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const RowHeAbrechnungWithHeVertrag: FC<DocumentRowProps> = ({ record, rechnungTyp, ...restProps }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const objektId = findLabelValueByLabelId(record.labelList, 'objektId');
  const heAbrechnungId = findLabelValueByLabelId(record.labelList, 'heAbrechnungId');
  const heAbrechnungHeVertragId = findLabelValueByLabelId(record.labelList, 'heAbrechnungHeVertragId');
  const isOutdated = findLabelValueByLabelId(record.labelList, 'outdated');

  const { loading, data: maybeHeAbrechnung } = useHeAbrechnungHeVertragQuery({ variables: { objektId, heAbrechnungId, heAbrechnungHeVertragId } });
  const heAbrechnungHeVertrag = maybeHeAbrechnung?.getHeAbrechnungHeVertrag.data;

  const documentInfo: DocumentInfo | undefined = heAbrechnungHeVertrag
    ? {
        column1: (
          <OutdatedData isOutdated={isOutdated} isAvatar>
            <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.type} />
          </OutdatedData>
        ),
        column2: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Abrechnung"
              content={
                <DataWithShortenedText maxTextLength={28} text={heAbrechnungHeVertrag.bezeichnung}>
                  {(shortenedText) => (
                    <Link to={pathsToHeAbrechnungDetails(heAbrechnungHeVertrag.objekt.objektId, heAbrechnungId).heAbrTab}>{shortenedText}</Link>
                  )}
                </DataWithShortenedText>
              }
            />
          </OutdatedData>
        ),
        column3: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Objekt"
              content={
                <DataWithShortenedText maxTextLength={25} text={heAbrechnungHeVertrag.objekt.kurzBezeichnung}>
                  {(shortenedText) => (
                    <LinkWithStatus
                      to={pathsToObjektDetailsPage(heAbrechnungHeVertrag.objekt.objektId).objektDetails}
                      text={shortenedText}
                      status={heAbrechnungHeVertrag.objekt.status}
                    />
                  )}
                </DataWithShortenedText>
              }
            />
          </OutdatedData>
        ),
        column4: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Hauseigentümer"
              content={
                <RechtstraegerWithContactsTooltip
                  rechtstraegerId={heAbrechnungHeVertrag.hauseigentuemer.rechtstraegerId}
                  rechtstraegerName={heAbrechnungHeVertrag.hauseigentuemer.kurzBezeichnung}
                  maxTextLength={25}
                />
              }
            />
          </OutdatedData>
        ),
        column5: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="von" content={<CustomFormattedDate value={heAbrechnungHeVertrag.abrechnungszeitraumVon} />} />
          </OutdatedData>
        ),
        column6: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="bis" content={<CustomFormattedDate value={heAbrechnungHeVertrag.abrechnungszeitraumBis} />} />
          </OutdatedData>
        ),
        column7: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="Anteile" content={<Typography.Text>{heAbrechnungHeVertrag.anteile}</Typography.Text>} />
          </OutdatedData>
        ),
        column8: (
          <OutdatedData isOutdated={isOutdated}>
            <Button>
              <Link to={uriFileOfFirmendaten(activeForFirmendatenId, record.fileId, record.name)} target="_blank">
                Öffnen
              </Link>
            </Button>
          </OutdatedData>
        ),
      }
    : undefined;

  return (
    <DocumentRowContextProvider value={{ isLoading: loading, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

export default RowHeAbrechnungWithHeVertrag;
