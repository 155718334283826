import React, { FC } from 'react';

import { Space, Typography } from 'antd';
import { Dayjs, OpUnitType } from 'dayjs';
import { mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';

export type AnnualPeriodToDateProps = {
  date: Dayjs | Date | string;
  startOf?: OpUnitType;
  endOf?: OpUnitType;
  showBrackets?: boolean;
};

const AnnualPeriodToDate: FC<AnnualPeriodToDateProps> = ({ date, startOf = 'year', endOf = 'year', showBrackets }) => {
  const dateValue = dayjsCustom(date);
  if (!dateValue.isValid()) {
    throw new Error(`formDateValid is not a valid ISO_8601 date: ${date}`);
  }

  const fromDate = mapFormDateValueToDateString(dateValue.startOf(startOf));
  const toDate = mapFormDateValueToDateString(dateValue.endOf(endOf));

  const content = (
    <Space size={4}>
      <CustomFormattedDate value={mapFormDateValueToDateString(fromDate)} />
      <Typography.Text>-</Typography.Text>
      <CustomFormattedDate value={mapFormDateValueToDateString(toDate)} />
    </Space>
  );

  if (!showBrackets) return content;
  return <Typography.Text style={{ whiteSpace: 'nowrap' }}>({content})</Typography.Text>;
};

export default AnnualPeriodToDate;
