import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { useEbicsUserStatusListQuery } from '../EbicsUser/gql/EbicsUserQueries.types';

type Props = { name: string } & SelectProps;

const EbicsUserStatusSelectForm: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useEbicsUserStatusListQuery();

  const ebicsStatus = data?.getEbicsUserStatusList.data ?? [];

  return (
    <Select
      size="small"
      name={name}
      loading={loading}
      placeholder="Ebics Status auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      {...restProps}
    >
      {ebicsStatus.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          <DataWithShortenedText maxTextLength={35} text={status.text} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default EbicsUserStatusSelectForm;
