import React, { FC } from 'react';
import { Dropdown, MenuProps, Modal } from 'antd';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { DeleteOutlined, DownOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { isStatusActive } from '../../../../helpers/statusHelper';
import { useToggle } from '../../../../hooks/useToggle';
import {
  useArchiveVorschreibungspositionMutation,
  useDeleteVorschreibungspositionMutation,
  useUnarchiveVorschreibungspositionMutation,
} from '../../gql/VorschreibungspositionMutations.types';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import VorschreibungspositionEditDrawer from './VorschreibungspositionEditDrawer';
import { Vorschreibungsposition } from '../../../../types';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import VorschreibungspositionChangeHistoryListingTable from './VorschreibungspositionChangeHistoryListingTable';

type Props = {
  vorschreibungsposition: Vorschreibungsposition;
  onAction: () => void;
};

const VorschreibungspositionTableActionsAndDrawer: FC<Props> = ({ vorschreibungsposition, onAction }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const entity = `Vorschreibungsposition`;

  const [runDelete] = useDeleteVorschreibungspositionMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    variables: {
      vorschreibungspositionId: vorschreibungsposition.vorschreibungspositionId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runReaktivieren] = useUnarchiveVorschreibungspositionMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onAction();
    },
    variables: {
      vorschreibungspositionId: vorschreibungsposition.vorschreibungspositionId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchivieren] = useArchiveVorschreibungspositionMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onAction();
    },
    variables: {
      vorschreibungspositionId: vorschreibungsposition.vorschreibungspositionId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined />, disabled: !isStatusActive(vorschreibungsposition.status) },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: !isStatusActive(vorschreibungsposition.status) ? 'Reaktivieren' : 'Archivieren',
      onClick: showConfirmArchivierenReaktivieren(runReaktivieren, runArchivieren, vorschreibungsposition),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: !!vorschreibungsposition.deletable,
    },
    {
      key: '4',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, vorschreibungsposition),
      icon: <DeleteOutlined />,
      disabled: !vorschreibungsposition.deletable,
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <VorschreibungspositionEditDrawer
        isCollapsed={isCollapsed}
        onCollapse={onCollapse}
        onAction={onAction}
        vorschreibungsposition={vorschreibungsposition}
      />
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <VorschreibungspositionChangeHistoryListingTable vorschreibungspositionId={vorschreibungsposition.vorschreibungspositionId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, vorschreibungsPosition: Vorschreibungsposition) => () => {
  Modal.confirm({
    title: `Möchten Sie die Vorschreibungsposition löschen?`,
    content: `${vorschreibungsPosition.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmArchivierenReaktivieren =
  (runReaktivieren: () => void, runArchivieren: () => void, vorschreibungsPosition: Vorschreibungsposition) => () => {
    Modal.confirm({
      title: !isStatusActive(vorschreibungsPosition.status)
        ? `Möchten Sie die Vorschreibungsposition reaktivieren?`
        : `Möchten Sie die Vorschreibungsposition archivieren?`,
      content: `${vorschreibungsPosition.bezeichnung}`,
      okText: !isStatusActive(vorschreibungsPosition.status) ? 'Reaktivieren' : 'Archivieren',
      cancelText: 'Nein',
      onOk() {
        if (!isStatusActive(vorschreibungsPosition.status)) {
          return runReaktivieren();
        } else {
          return runArchivieren();
        }
      },
    });
  };

export default VorschreibungspositionTableActionsAndDrawer;
