import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AufteilungsschluesselListForSelectByFirmendatenIdQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AufteilungsschluesselListForSelectByFirmendatenIdQuery = {
  getAufteilungsschluesselListByFirmendatenId: {
    data: {
      contentList: Array<{
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AufteilungsschluesselListForSelectByKundenSystemIdQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AufteilungsschluesselListForSelectByKundenSystemIdQuery = {
  getAufteilungsschluesselListByKundenSystemId: {
    data: {
      contentList: Array<{
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AufteilungsschluesselListForSelectByFirmendatenIdDocument = gql`
  query AufteilungsschluesselListForSelectByFirmendatenId($firmendatenId: ID!, $includeArchiviert: Boolean) {
    getAufteilungsschluesselListByFirmendatenId(firmendatenId: $firmendatenId, includeArchiviert: $includeArchiviert) {
      data {
        contentList {
          ...AufteilungsschluesselFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useAufteilungsschluesselListForSelectByFirmendatenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AufteilungsschluesselListForSelectByFirmendatenIdQuery,
    AufteilungsschluesselListForSelectByFirmendatenIdQueryVariables
  > &
    ({ variables: AufteilungsschluesselListForSelectByFirmendatenIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AufteilungsschluesselListForSelectByFirmendatenIdQuery, AufteilungsschluesselListForSelectByFirmendatenIdQueryVariables>(
    AufteilungsschluesselListForSelectByFirmendatenIdDocument,
    options
  );
}
export function useAufteilungsschluesselListForSelectByFirmendatenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AufteilungsschluesselListForSelectByFirmendatenIdQuery,
    AufteilungsschluesselListForSelectByFirmendatenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AufteilungsschluesselListForSelectByFirmendatenIdQuery, AufteilungsschluesselListForSelectByFirmendatenIdQueryVariables>(
    AufteilungsschluesselListForSelectByFirmendatenIdDocument,
    options
  );
}
export function useAufteilungsschluesselListForSelectByFirmendatenIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AufteilungsschluesselListForSelectByFirmendatenIdQuery,
        AufteilungsschluesselListForSelectByFirmendatenIdQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AufteilungsschluesselListForSelectByFirmendatenIdQuery,
    AufteilungsschluesselListForSelectByFirmendatenIdQueryVariables
  >(AufteilungsschluesselListForSelectByFirmendatenIdDocument, options);
}
export type AufteilungsschluesselListForSelectByFirmendatenIdQueryHookResult = ReturnType<
  typeof useAufteilungsschluesselListForSelectByFirmendatenIdQuery
>;
export type AufteilungsschluesselListForSelectByFirmendatenIdLazyQueryHookResult = ReturnType<
  typeof useAufteilungsschluesselListForSelectByFirmendatenIdLazyQuery
>;
export type AufteilungsschluesselListForSelectByFirmendatenIdSuspenseQueryHookResult = ReturnType<
  typeof useAufteilungsschluesselListForSelectByFirmendatenIdSuspenseQuery
>;
export type AufteilungsschluesselListForSelectByFirmendatenIdQueryResult = Apollo.QueryResult<
  AufteilungsschluesselListForSelectByFirmendatenIdQuery,
  AufteilungsschluesselListForSelectByFirmendatenIdQueryVariables
>;
export const AufteilungsschluesselListForSelectByKundenSystemIdDocument = gql`
  query AufteilungsschluesselListForSelectByKundenSystemId($kundenSystemId: ID!, $includeArchiviert: Boolean) {
    getAufteilungsschluesselListByKundenSystemId(kundenSystemId: $kundenSystemId, includeArchiviert: $includeArchiviert) {
      data {
        contentList {
          ...AufteilungsschluesselFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useAufteilungsschluesselListForSelectByKundenSystemIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AufteilungsschluesselListForSelectByKundenSystemIdQuery,
    AufteilungsschluesselListForSelectByKundenSystemIdQueryVariables
  > &
    ({ variables: AufteilungsschluesselListForSelectByKundenSystemIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AufteilungsschluesselListForSelectByKundenSystemIdQuery, AufteilungsschluesselListForSelectByKundenSystemIdQueryVariables>(
    AufteilungsschluesselListForSelectByKundenSystemIdDocument,
    options
  );
}
export function useAufteilungsschluesselListForSelectByKundenSystemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AufteilungsschluesselListForSelectByKundenSystemIdQuery,
    AufteilungsschluesselListForSelectByKundenSystemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AufteilungsschluesselListForSelectByKundenSystemIdQuery,
    AufteilungsschluesselListForSelectByKundenSystemIdQueryVariables
  >(AufteilungsschluesselListForSelectByKundenSystemIdDocument, options);
}
export function useAufteilungsschluesselListForSelectByKundenSystemIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AufteilungsschluesselListForSelectByKundenSystemIdQuery,
        AufteilungsschluesselListForSelectByKundenSystemIdQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AufteilungsschluesselListForSelectByKundenSystemIdQuery,
    AufteilungsschluesselListForSelectByKundenSystemIdQueryVariables
  >(AufteilungsschluesselListForSelectByKundenSystemIdDocument, options);
}
export type AufteilungsschluesselListForSelectByKundenSystemIdQueryHookResult = ReturnType<
  typeof useAufteilungsschluesselListForSelectByKundenSystemIdQuery
>;
export type AufteilungsschluesselListForSelectByKundenSystemIdLazyQueryHookResult = ReturnType<
  typeof useAufteilungsschluesselListForSelectByKundenSystemIdLazyQuery
>;
export type AufteilungsschluesselListForSelectByKundenSystemIdSuspenseQueryHookResult = ReturnType<
  typeof useAufteilungsschluesselListForSelectByKundenSystemIdSuspenseQuery
>;
export type AufteilungsschluesselListForSelectByKundenSystemIdQueryResult = Apollo.QueryResult<
  AufteilungsschluesselListForSelectByKundenSystemIdQuery,
  AufteilungsschluesselListForSelectByKundenSystemIdQueryVariables
>;
