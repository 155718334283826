import { ActiveForFirmendaten, useAuth, User, Mitarbeiter } from './authContext';

type FDAuthorized = {
  user: User;
  activeForFirmendaten: ActiveForFirmendaten;
  activeForFirmendatenId: string;
};

/**
 * Use this hook in components which are only available for users who are already logged in and operating for a firmendaten.
 *
 * Convenience wrapper hook on top of useAuth. in state of useAuth user and activeForFirmendaten are nullable (or undefined) because they are being set during login
 * or on demand in case of ANDRO_ADMIN. But most components are only available when user is already logged in and operating for a firmendaten. To avoid extra typescript
 * checks and non-null-assertions for these states this hook has been implemented. It makes sure that these states are already set. If so then returns them but without the nullability
 * otherwise throws an error.
 */
export const useFDAuthorized = (): FDAuthorized => {
  const { user, activeForFirmendaten, activeForFirmendatenId } = useAuth();

  if (!user) {
    throw new Error(`usage of useFDAuthorized requires a user who is already logged in. user: ${user}`);
  }
  if (!activeForFirmendaten) {
    throw new Error(`usage of useFDAuthorized requires a user who is authorized for a firmendaten. activeForFirmendatenId: ${activeForFirmendaten}`);
  }
  if (!activeForFirmendatenId) {
    throw new Error(
      `usage of useFDAuthorized requires a user who is authorized for a firmendaten. activeForFirmendatenId: ${activeForFirmendatenId}`
    );
  }

  return { user, activeForFirmendaten, activeForFirmendatenId };
};

type AuthorizedMitarbeiter = FDAuthorized & {
  mitarbeiter: Mitarbeiter;
};

/**
 * Use this hook in components which are only available for logged in mitarbeiter. They have to have a mitarbeiter so do not use this hook in components
 * which are available for ANDO_ADMINS!
 *
 * See motivation in documentation of useFDAuthorized
 */
export const useAuthorizedMitarbeiter = (): AuthorizedMitarbeiter => {
  const fdAuthorizedState = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  if (!mitarbeiter) {
    throw new Error(`usage of useAuthorizedMitarbeiter requires a mitarbeiter. mitarbeiter: ${mitarbeiter}`);
  }

  return { ...fdAuthorizedState, mitarbeiter };
};
