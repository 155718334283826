import React from 'react';
import EmailDeliveryDefinitionTable from './Table/EmailDeliveryDefinitionTable';
import { useEmailDeliveryDefinitionListQuery } from './gql/EmailDeliveryDefinitionQueries.types';
import EmailDeliveryDefinitionButtonsAndDrawer from './EmailDeliveryDefinitionButtonsAndDrawer';

const EmailDeliveryDefinitionListing = () => {
  const { data, loading, refetch } = useEmailDeliveryDefinitionListQuery();

  const emailDeliveryDefList = data?.getEmailDeliveryDefinitionList.data ?? [];

  return (
    <>
      <EmailDeliveryDefinitionButtonsAndDrawer refetch={refetch} />
      <EmailDeliveryDefinitionTable emailDeliveryDefList={emailDeliveryDefList} loading={loading} onAction={refetch} />
    </>
  );
};

export default EmailDeliveryDefinitionListing;
