import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMitarbeiterMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  input: Types.MitarbeiterCreateInput;
}>;

export type CreateMitarbeiterMutation = {
  createMitarbeiter: {
    data: { mitarbeiterId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateMitarbeiterMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  mitarbeiterId: Types.Scalars['ID']['input'];
  input: Types.MitarbeiterUpdateInput;
}>;

export type UpdateMitarbeiterMutation = {
  updateMitarbeiter: {
    data: { mitarbeiterId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteMitarbeiterMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  mitarbeiterId: Types.Scalars['ID']['input'];
}>;

export type DeleteMitarbeiterMutation = {
  deleteMitarbeiter: {
    data: { mitarbeiterId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SperrenMitarbeiterMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  mitarbeiterId: Types.Scalars['ID']['input'];
}>;

export type SperrenMitarbeiterMutation = {
  actionSperrenMitarbeiter: {
    data: { mitarbeiterId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EntsperrenMitarbeiterMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  mitarbeiterId: Types.Scalars['ID']['input'];
}>;

export type EntsperrenMitarbeiterMutation = {
  actionEntsperrenMitarbeiter: {
    data: { mitarbeiterId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ResendEinladungMitarbeiterMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  mitarbeiterId: Types.Scalars['ID']['input'];
}>;

export type ResendEinladungMitarbeiterMutation = {
  actionResendEinladungMitarbeiter: {
    data: { mitarbeiterId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateMitarbeiterDocument = gql`
  mutation CreateMitarbeiter($firmendatenId: String!, $input: MitarbeiterCreateInput!) {
    createMitarbeiter(firmendatenId: $firmendatenId, input: $input) {
      data {
        mitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateMitarbeiterMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMitarbeiterMutation, CreateMitarbeiterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMitarbeiterMutation, CreateMitarbeiterMutationVariables>(CreateMitarbeiterDocument, options);
}
export type CreateMitarbeiterMutationHookResult = ReturnType<typeof useCreateMitarbeiterMutation>;
export type CreateMitarbeiterMutationResult = Apollo.MutationResult<CreateMitarbeiterMutation>;
export type CreateMitarbeiterMutationOptions = Apollo.BaseMutationOptions<CreateMitarbeiterMutation, CreateMitarbeiterMutationVariables>;
export const UpdateMitarbeiterDocument = gql`
  mutation UpdateMitarbeiter($firmendatenId: String!, $mitarbeiterId: ID!, $input: MitarbeiterUpdateInput!) {
    updateMitarbeiter(firmendatenId: $firmendatenId, mitarbeiterId: $mitarbeiterId, input: $input) {
      data {
        mitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateMitarbeiterMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMitarbeiterMutation, UpdateMitarbeiterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMitarbeiterMutation, UpdateMitarbeiterMutationVariables>(UpdateMitarbeiterDocument, options);
}
export type UpdateMitarbeiterMutationHookResult = ReturnType<typeof useUpdateMitarbeiterMutation>;
export type UpdateMitarbeiterMutationResult = Apollo.MutationResult<UpdateMitarbeiterMutation>;
export type UpdateMitarbeiterMutationOptions = Apollo.BaseMutationOptions<UpdateMitarbeiterMutation, UpdateMitarbeiterMutationVariables>;
export const DeleteMitarbeiterDocument = gql`
  mutation DeleteMitarbeiter($firmendatenId: String!, $mitarbeiterId: ID!) {
    deleteMitarbeiter(firmendatenId: $firmendatenId, mitarbeiterId: $mitarbeiterId) {
      data {
        mitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteMitarbeiterMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMitarbeiterMutation, DeleteMitarbeiterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMitarbeiterMutation, DeleteMitarbeiterMutationVariables>(DeleteMitarbeiterDocument, options);
}
export type DeleteMitarbeiterMutationHookResult = ReturnType<typeof useDeleteMitarbeiterMutation>;
export type DeleteMitarbeiterMutationResult = Apollo.MutationResult<DeleteMitarbeiterMutation>;
export type DeleteMitarbeiterMutationOptions = Apollo.BaseMutationOptions<DeleteMitarbeiterMutation, DeleteMitarbeiterMutationVariables>;
export const SperrenMitarbeiterDocument = gql`
  mutation SperrenMitarbeiter($firmendatenId: String!, $mitarbeiterId: ID!) {
    actionSperrenMitarbeiter(firmendatenId: $firmendatenId, mitarbeiterId: $mitarbeiterId) {
      data {
        mitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSperrenMitarbeiterMutation(
  baseOptions?: Apollo.MutationHookOptions<SperrenMitarbeiterMutation, SperrenMitarbeiterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SperrenMitarbeiterMutation, SperrenMitarbeiterMutationVariables>(SperrenMitarbeiterDocument, options);
}
export type SperrenMitarbeiterMutationHookResult = ReturnType<typeof useSperrenMitarbeiterMutation>;
export type SperrenMitarbeiterMutationResult = Apollo.MutationResult<SperrenMitarbeiterMutation>;
export type SperrenMitarbeiterMutationOptions = Apollo.BaseMutationOptions<SperrenMitarbeiterMutation, SperrenMitarbeiterMutationVariables>;
export const EntsperrenMitarbeiterDocument = gql`
  mutation EntsperrenMitarbeiter($firmendatenId: String!, $mitarbeiterId: ID!) {
    actionEntsperrenMitarbeiter(firmendatenId: $firmendatenId, mitarbeiterId: $mitarbeiterId) {
      data {
        mitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useEntsperrenMitarbeiterMutation(
  baseOptions?: Apollo.MutationHookOptions<EntsperrenMitarbeiterMutation, EntsperrenMitarbeiterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EntsperrenMitarbeiterMutation, EntsperrenMitarbeiterMutationVariables>(EntsperrenMitarbeiterDocument, options);
}
export type EntsperrenMitarbeiterMutationHookResult = ReturnType<typeof useEntsperrenMitarbeiterMutation>;
export type EntsperrenMitarbeiterMutationResult = Apollo.MutationResult<EntsperrenMitarbeiterMutation>;
export type EntsperrenMitarbeiterMutationOptions = Apollo.BaseMutationOptions<EntsperrenMitarbeiterMutation, EntsperrenMitarbeiterMutationVariables>;
export const ResendEinladungMitarbeiterDocument = gql`
  mutation ResendEinladungMitarbeiter($firmendatenId: String!, $mitarbeiterId: ID!) {
    actionResendEinladungMitarbeiter(firmendatenId: $firmendatenId, mitarbeiterId: $mitarbeiterId) {
      data {
        mitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useResendEinladungMitarbeiterMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendEinladungMitarbeiterMutation, ResendEinladungMitarbeiterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendEinladungMitarbeiterMutation, ResendEinladungMitarbeiterMutationVariables>(
    ResendEinladungMitarbeiterDocument,
    options
  );
}
export type ResendEinladungMitarbeiterMutationHookResult = ReturnType<typeof useResendEinladungMitarbeiterMutation>;
export type ResendEinladungMitarbeiterMutationResult = Apollo.MutationResult<ResendEinladungMitarbeiterMutation>;
export type ResendEinladungMitarbeiterMutationOptions = Apollo.BaseMutationOptions<
  ResendEinladungMitarbeiterMutation,
  ResendEinladungMitarbeiterMutationVariables
>;
