import React, { FC } from 'react';
import { generatePath } from 'react-router';
import { Button, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EllipsisOutlined, FileSearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { URI_OBJEKT_DETAILS_PAGE } from '../../../../Objekt/objektUriPaths';
import { showSuccessMsgDelete, showSuccessMsgOther } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useDeleteHeAbrechnungMutation, useHeAbrechnungMarkUnderReviewMutation } from '../../gql/HeAbrechnungMutations.types';
import { HeAbrechnung } from '../../../../../types';

type Props = {
  abrechnung: HeAbrechnung;
  objektId: string;
};
const HeAbrechnungCardActions: FC<Props> = ({ abrechnung, objektId }) => {
  const navigate = useNavigate();

  const [runDelete] = useDeleteHeAbrechnungMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Hauseigentümerabrechnung');
      navigate(generatePath(URI_OBJEKT_DETAILS_PAGE, { objektId }), { replace: true });
    },
    variables: {
      objektId,
      heAbrechnungId: abrechnung.heAbrechnungId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runMarkUnderReview] = useHeAbrechnungMarkUnderReviewMutation({
    onCompleted: () => {
      showSuccessMsgOther('Hauseigentümerabrechnung wird überprüft');
    },
    variables: {
      objektId,
      heAbrechnungId: abrechnung.heAbrechnungId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Prüfen',
      onClick: () => showConfirmMarkUnderReview(abrechnung, runMarkUnderReview),
      icon: <FileSearchOutlined />,
    },
    {
      key: '2',
      label: 'Löschen',
      onClick: () => showConfirmDelete(abrechnung, runDelete),
      icon: <DeleteOutlined />,
      danger: true,
      disabled: !abrechnung.deletable,
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
    </Dropdown>
  );
};

const showConfirmDelete = (abrechnung: HeAbrechnung, runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Hauseigentümerabrechnung löschen?',
    content: `${abrechnung.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmMarkUnderReview = (abrechnung: HeAbrechnung, runMarkUnderReview: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Hauseigentümerabrechnung prüfen?',
    content: `${abrechnung.bezeichnung}`,
    okText: 'Prüfen',
    cancelText: 'Abbrechen',
    onOk() {
      return runMarkUnderReview();
    },
  });
};

export default HeAbrechnungCardActions;
