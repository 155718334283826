import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../styles/pdfStyles';
import { BuchungAusgabe } from '../../../../../objektAbrechnung-types';

const OAAbrKreisKontoBuchungenAusgabe: FC<{ buchungAusgabe: BuchungAusgabe }> = ({ buchungAusgabe }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight]}>
    <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '30%' }]}>{buchungAusgabe.bezeichnung}</Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '10%' }]}>{buchungAusgabe.buchungsDatum}</Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '15%' }]}>{buchungAusgabe.belegnummer}</Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '35%' }]}>{buchungAusgabe.kurzbezeichnungVertragsparthner}</Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '10%', alignItems: 'flex-end', textAlign: 'right' }]}>
      {buchungAusgabe.betragNetto}
    </Text>
  </View>
);

export default OAAbrKreisKontoBuchungenAusgabe;
