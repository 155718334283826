import React, { FC } from 'react';
import { Divider, Space, Typography } from 'antd';

import DeviationChangeTypeTag from '../../../DeviationChangeTypeTag';
import { DeviationColumnTitleStyledText } from '../../../Styled/Deviation.style';
import DataWithShortenedTextAndExtraInfo from '../../../../Helpers/DataWithShortenedTextAndExtraInfo';
import { DeviationChangeTypeTuple, TopAbrechnungDeviationSourceEntity } from '../../../../../types';
import CustomFormattedDate from '../../../../FormattedDate/CustomFormattedDate';

type TopAbrechnungSourceEntityHeaderProps = {
  changeType: DeviationChangeTypeTuple;
  sourceEntity: TopAbrechnungDeviationSourceEntity;
};

const TopAbrechnungSourceEntityHeader: FC<TopAbrechnungSourceEntityHeaderProps> = ({ changeType, sourceEntity }) => {
  return (
    <Space align="baseline" size={8} split={<Divider type="vertical" />}>
      <Space size={4} align="baseline">
        <DeviationChangeTypeTag changeType={changeType} />
        <DeviationColumnTitleStyledText type="secondary">{sourceEntity.type.text}</DeviationColumnTitleStyledText>
      </Space>
      <Space size="large">
        <Space size={4} style={{ width: '200px' }}>
          <DataWithShortenedTextAndExtraInfo maxTextLength={30} text={sourceEntity.objektBezeichnung} />
        </Space>
        <Space size={4} style={{ width: '150px' }}>
          <DataWithShortenedTextAndExtraInfo maxTextLength={20} text={sourceEntity.bestandseinheitBezeichnung} />
        </Space>
        <Space size={8}>
          <Space size={4}>
            <Typography.Text type="secondary">von</Typography.Text>
            <CustomFormattedDate value={sourceEntity.fromInclusive} />
          </Space>
          <Space size={4}>
            <Typography.Text type="secondary">bis</Typography.Text>
            <CustomFormattedDate value={sourceEntity.toInclusive} />
          </Space>
        </Space>
      </Space>
    </Space>
  );
};

export default TopAbrechnungSourceEntityHeader;
