import React, { FC } from 'react';
import { Modal } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useSetObjektVorschreibungspositionVertragsartListMutation } from '../../gql/ObjektVorschreibungPositionMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { ObjektVorschreibungsposition, Verwaltungsart } from '../../../../../types';
import VertragVertragsartFormCheckbox from '../../../../Vertrag/shared/VertragVertragsartFormCheckbox';
import {
  assignVertragsartListFormFields,
  AssignVertragsartListFormValues,
  mapObjektVorschreibungspositionToFormValues,
} from './assignVertragartListFormMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import { gqlOperations } from '../../../../../graphql/gqlNamedOperations-generated';

type Props = {
  isModalOpen: boolean;
  toggleModal: () => void;
  objektId: string;
  onAction: () => void;
  vorschreibungsposition: ObjektVorschreibungsposition;
  verwaltungsart: Verwaltungsart;
};

const AssignVertragartListFormModal: FC<Props> = ({ objektId, vorschreibungsposition, isModalOpen, toggleModal, onAction, verwaltungsart }) => {
  const [runSetVertragsartList, { loading }] = useSetObjektVorschreibungspositionVertragsartListMutation({
    onCompleted: () => {
      onAction();
      toggleModal();
    },
    refetchQueries: [gqlOperations.Query.ObjektVorschreibungspositionList],
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Modal
      title={`Vertragsarten für ${vorschreibungsposition.kurzBezeichnung} zuweisen`}
      open={isModalOpen}
      onCancel={toggleModal}
      destroyOnClose
      footer={false}
    >
      <Formik<AssignVertragsartListFormValues>
        initialValues={mapObjektVorschreibungspositionToFormValues(vorschreibungsposition)}
        onSubmit={(values, formikHelpers) => {
          runSetVertragsartList({
            variables: {
              objektId,
              objektVorschreibungspositionId: vorschreibungsposition.objektVorschreibungspositionId,
              vertragsartList: values.vertragsartList,
            },
          }).finally(() => formikHelpers.setSubmitting(false));
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <VertragVertragsartFormCheckbox
              name={assignVertragsartListFormFields.vertragsartList}
              verwaltungsart={verwaltungsart}
              currentVertragsartList={formikProps.values.vertragsartList}
            />
            <FormButtons onCancel={toggleModal} updateMode={false} isOkLoading={loading} okText="Zuweisen" />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AssignVertragartListFormModal;
