import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { useIndexSeriesQuery } from '../../../features/IndexSeries/gql/IndexSeriesQueries.types';
import { IndexSeriesEntryDetailsRouteParams } from './routes';
import IndexSeriesDetailsListing from '../../../features/IndexSeries/shared/DetailsListing/IndexSeriesDetailsListing';

const IndexSeriesDetailsPage: FC = () => {
  const { indexSeriesId } = useParams() as IndexSeriesEntryDetailsRouteParams;
  const { data, refetch } = useIndexSeriesQuery({
    variables: { indexSeriesId },
  });

  const indexSeries = data?.getIndexSeries.data;

  if (!indexSeries) return <Skeleton active />;

  return <IndexSeriesDetailsListing indexSeries={indexSeries} onAction={refetch} isAndromedaAdmin />;
};

export default IndexSeriesDetailsPage;
