import React, { FC } from 'react';
import { Spin } from 'antd';
import BelegTexteForm from '../../../../Belegtext/Form/BelegTexteForm';
import { useBelegTexteFirmendatenQuery, useBelegTexteFirmendatenTemplatesQuery } from '../../../../Belegtext/gql/BelegtexteQueries.types';
import { useFirmendatenOperatingSiteListQuery } from '../../gql/FirmendatenOperatingSiteQueries.types';
import { BelegTextePlatzhalter } from '../../../../Belegtext/Form/belegTexteFormTypes';
import { findBelegTexteHauptAddress, findBelegTexteHauptEmail, findBelegTextePhoneNumber } from '../../../../shared/operatingSiteHelpers';
import { Firmendaten } from '../../../../../types';

type RechnungsAusstellerBelegtextListingProps = {
  firmendaten: Firmendaten;
};

const RechnungsAusstellerBelegtextListing: FC<RechnungsAusstellerBelegtextListingProps> = ({ firmendaten }) => {
  const { data: belegTexteData, loading, refetch } = useBelegTexteFirmendatenQuery({ variables: { firmendatenId: firmendaten.firmendatenId } });

  const belegTexte = belegTexteData?.getBelegTexteFirmendaten.data;

  const { data: belegTexteTemplateData } = useBelegTexteFirmendatenTemplatesQuery();

  const belegTexteTemplate = belegTexteTemplateData?.getBelegTexteFirmendatenTemplates.data;

  const { data: firmendatenOperatingSiteListData } = useFirmendatenOperatingSiteListQuery({
    variables: { firmendatenId: firmendaten.firmendatenId },
  });
  const operatingSiteList = firmendatenOperatingSiteListData?.getFirmendatenOperatingSiteList.data ?? [];

  if (!belegTexteTemplate || !belegTexte || !operatingSiteList) {
    return <Spin />;
  }

  const belegTextePlatzhalter: BelegTextePlatzhalter = {
    hauptAddress: findBelegTexteHauptAddress(operatingSiteList),
    vatIdentificationNumber: firmendaten.vatIdentificationNumber,
    companyRegistrationNumber: firmendaten.companyRegistrationNumber,
    phoneNumber: findBelegTextePhoneNumber(operatingSiteList),
    email: findBelegTexteHauptEmail(operatingSiteList),
  };

  return (
    <BelegTexteForm
      firmendatenId={firmendaten.firmendatenId}
      belegTexte={belegTexte}
      loading={loading}
      onSuccess={refetch}
      belegTexteTemplate={belegTexteTemplate}
      belegTextePlatzhalter={belegTextePlatzhalter}
    />
  );
};
export default RechnungsAusstellerBelegtextListing;
