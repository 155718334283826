import React from 'react';
import { Col, Row, Tabs } from 'antd';
import NestedTable from './NestedTable';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';

const Mieter = ({ rechtstraegerId }) => {
  const items = [
    {
      key: '1',
      label: 'Mieter',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <Row>
            <Col>
              <NestedTable rechtstraegerId={rechtstraegerId} />
            </Col>
          </Row>
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default Mieter;
