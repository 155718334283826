import React, { FC } from 'react';
import firmendatenKontoTableColumns from './firmendatenKontoTableColumns';
import { Firmendaten, FirmendatenBankDetails } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';

type Props = {
  bankDetailsList: FirmendatenBankDetails[];
  loading: boolean;
  firmendaten: Firmendaten;
  onAction: () => void;
};

const FirmendatenKontoTable: FC<Props> = ({ loading, bankDetailsList, firmendaten, onAction }) => {
  return (
    <NestedTable<FirmendatenBankDetails>
      expandable={{ rowExpandable: () => false }}
      pagination={false}
      loading={loading}
      dataSource={bankDetailsList}
      columns={firmendatenKontoTableColumns(firmendaten, onAction)}
      rowKey={(record) => record.bankDetailsId}
    />
  );
};

export default FirmendatenKontoTable;
