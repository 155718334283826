import React from 'react';
import { Modal, Button } from 'antd';
import { useToggle } from '../../../../hooks/useToggle';
import MahnungCreateForm from './MahnungCreateForm';

const MahnungCreateButtonAndModal = () => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      <Button type="primary" onClick={onCollapse}>
        Mahnungen erstellen
      </Button>
      <Modal title="Mahnungen erstellen" open={isCollapsed} onCancel={onCollapse} footer={null} destroyOnClose maskClosable={false}>
        <MahnungCreateForm onAction={onCollapse} />
      </Modal>
    </>
  );
};

export default MahnungCreateButtonAndModal;
