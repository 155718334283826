import { TFormattedDecimal } from '../../helpers/formikHelper';
import { getFormattedDecimalValueOrZero } from '../../components/Input-antd/formattedDecimalHelper';
import { dayjsCustom } from '../../helpers/dayjsCustom';

export const calculateSaldoOffenerBetrag = (opGesamtBetrag: number, opAuszifferungList: TAuszifferung[], auszifferung: TAuszifferung) => {
  const sumOpAzList = opAuszifferungList
    .filter((az) => dayjsCustom(az.createTs).isSameOrBefore(dayjsCustom(auszifferung.createTs)))
    .reduce((acc, currVal, currentIndex) => {
      const isPreviousAZStorniert = opAuszifferungList[currentIndex - 1]?.storniert ?? false;
      if (isPreviousAZStorniert) {
        const previousSaldo = opGesamtBetrag - acc;
        return acc + previousSaldo;
      }
      return acc + getFormattedDecimalValueOrZero(currVal.betrag);
    }, 0);
  return opGesamtBetrag - sumOpAzList;
};

type TAuszifferung = {
  betrag: TFormattedDecimal;
  createTs: string;
  storniert?: boolean;
};
