import React, { FC } from 'react';
import { Divider, Space } from 'antd';
import { useVorschreibungMailDeliveryQuery } from '../gql/VorschreibungMailDeliveryQueries.types';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import EmailDelivery from './EmailDelivery';

type Props = {
  vorschreibungMailDeliveryId: string;
};

const ResentDeliveryInfoRow: FC<Props> = ({ vorschreibungMailDeliveryId }) => {
  const { data } = useVorschreibungMailDeliveryQuery({ variables: { vorschreibungMailDeliveryId } });
  const vorschreibungMailDelivery = data?.getVorschreibungMailDelivery.data;

  if (!vorschreibungMailDelivery?.resentDeliveryList?.length) return null;

  return (
    <>
      <Divider />

      <CardDetailsInfoRow infoRowTitle="Erneut Versendet">
        <Space direction="vertical" size={20}>
          {vorschreibungMailDelivery.resentDeliveryList.map((emailDelivery, index) => (
            <EmailDelivery key={index} emailDelivery={emailDelivery} />
          ))}
        </Space>
      </CardDetailsInfoRow>
    </>
  );
};

export default ResentDeliveryInfoRow;
