import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useTextbausteinTemplateChangeHistoryListQuery } from '../gql/TextbausteinTemplateQueries.types';

type Props = {
  textbausteinId: string;
};

const TextbausteinTemplateChangeHistoryListingTable: FC<Props> = ({ textbausteinId }) => {
  const { data, loading } = useTextbausteinTemplateChangeHistoryListQuery({ variables: { textbausteinId } });
  const historyList = data?.getTextbausteinTemplateChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="textbaustein-template" />;
};

export default TextbausteinTemplateChangeHistoryListingTable;
