import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TextbausteinFieldsFragmentDoc } from '../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
export type EmailDeliveryDefinitionFieldsFragment = {
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  emailDeliveryDefinitionId: string;
  body?: {
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  } | null;
  subject: {
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  };
  type: { text: string; value: Types.EmailDeliveryDefinitionType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type EmailDeliveryDefinitionBaseFieldsFragment = {
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  emailDeliveryDefinitionId: string;
  type: { text: string; value: Types.EmailDeliveryDefinitionType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type EmailDeliveryDefinitionTemplatesFieldsFragment = {
  body?: {
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  } | null;
  subject: {
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  };
};

export const EmailDeliveryDefinitionFieldsFragmentDoc = gql`
  fragment EmailDeliveryDefinitionFields on EmailDeliveryDefinition {
    bezeichnung
    body {
      ...TextbausteinFields
    }
    createTs
    createdBy
    createdByMitarbeiterId
    emailDeliveryDefinitionId
    subject {
      ...TextbausteinFields
    }
    type {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
export const EmailDeliveryDefinitionBaseFieldsFragmentDoc = gql`
  fragment EmailDeliveryDefinitionBaseFields on EmailDeliveryDefinitionBase {
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    emailDeliveryDefinitionId
    type {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
  }
`;
export const EmailDeliveryDefinitionTemplatesFieldsFragmentDoc = gql`
  fragment EmailDeliveryDefinitionTemplatesFields on EmailDeliveryTemplate {
    body {
      ...TextbausteinFields
    }
    subject {
      ...TextbausteinFields
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
