import React, { FC } from 'react';
import { Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Box, Flex } from 'rebass';
import { useNavigate } from 'react-router-dom';
import AbrDefWeVersionTimeline from './AbrDefWeVersionTimeline';
import { generatePathToAbrechnungsdefinitionVersionCreatePage } from '../../abrDefUriPaths';
import { AbrechnungsdefinitionType } from '../../../../types';
import AbrDefHeVersionTimeline from './AbrDefHeVersionTimeline';
import AbrDefBkVersionTimeline from './AbrDefBkVersionTimeline';
import AbrDefSubVersionTimeline from './AbrDefSubVersionTimeline';

type Props = {
  abrechnungsdefinitionId: string;
  abrDefType: AbrechnungsdefinitionType;
};

const AbrDefVersionTimelineWithActions: FC<Props> = ({ abrechnungsdefinitionId, abrDefType }) => {
  const abrDefVersionTimeline = (abrDefType: AbrechnungsdefinitionType) => {
    switch (abrDefType) {
      case AbrechnungsdefinitionType.HeAbrechnung:
        return <AbrDefHeVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} />;
      case AbrechnungsdefinitionType.SubAbrechnung:
        return <AbrDefSubVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} />;
      case AbrechnungsdefinitionType.BkAbrechnung:
        return <AbrDefBkVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} />;
      case AbrechnungsdefinitionType.WeAbrechnung:
      default:
        return <AbrDefWeVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} />;
    }
  };

  return (
    <Box>
      <AbrDefVersionTimelineActions abrechnungsdefinitionId={abrechnungsdefinitionId} />
      <Box mt="24px" />
      {abrDefVersionTimeline(abrDefType)}
    </Box>
  );
};

type AbrDefVersionTimelineActionsProps = {
  abrechnungsdefinitionId: string;
};

const AbrDefVersionTimelineActions: FC<AbrDefVersionTimelineActionsProps> = ({ abrechnungsdefinitionId }) => {
  const navigate = useNavigate();

  return (
    <Flex width="100%">
      <Button
        block
        type="default"
        icon={<PlusOutlined />}
        onClick={() => {
          navigate(generatePathToAbrechnungsdefinitionVersionCreatePage(abrechnungsdefinitionId));
        }}
      >
        Neue Gültigkeit anlegen
      </Button>
      <Box width="16px" />
      {/* eslint-disable-next-line no-console */}
      <Input.Search placeholder="Änderung suchen" onSearch={(value) => console.log(value)} allowClear />
    </Flex>
  );
};

export default AbrDefVersionTimelineWithActions;
