import React, { FC } from 'react';
import { Spin, Tag, Select, SelectProps, Typography } from 'antd';
import { useField } from 'formik';
import { useRechtstraegerAndEmailListQuery } from '../../../Rechtstraeger/gql/RechtsTraegerQueries.types';
import { TicketParticipantFormValues } from '../ticketFormMapper';
import { RechtstraegerAndEmail } from '../../../../types';

import ParticipantSelectTag from './ParticipantSelectTag';
import { filterOptionForParticipantSelect } from './participantSelectFilterOption';

type Props = { name: string } & SelectProps;

const ParticipantSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useRechtstraegerAndEmailListQuery();
  const rechtstraegerAndEmailList = data?.getRechtstraegerAndEmailList.data || [];

  const [field, , helpers] = useField<TicketParticipantFormValues[]>(restProps.name);

  const handleSelect = (val: string, option: any) => {
    helpers.setValue([...field.value, option.key ? { contactId: val, emailAddress: null } : { contactId: null, emailAddress: val }]);
  };

  const handleDeselect = (val: string, option: any) => {
    helpers.setValue(field.value.filter((participant) => (option.key ? participant.contactId !== val : participant.emailAddress !== val)));
  };

  const options = rechtstraegerAndEmailList.map((item) => ({
    key: item.emailContact.contactId,
    value: item.emailContact.contactId,
    label: isHaupEmailContact(item, rechtstraegerAndEmailList) ? (
      <>
        <Typography.Text>
          {item.rechtstraeger.kurzBezeichnung} - {item.emailContact.wert}
        </Typography.Text>
        <Tag color="blue" style={{ marginLeft: 5, marginRight: 2, lineHeight: '12px' }}>
          Haupt
        </Tag>
      </>
    ) : (
      `${item.rechtstraeger.kurzBezeichnung} - ${item.emailContact.wert}`
    ),
  }));

  return (
    <Select
      allowClear
      showSearch
      filterOption={filterOptionForParticipantSelect}
      loading={loading}
      size="middle"
      placeholder="Beteiligten auswählen"
      mode="tags"
      value={field.value.map((participant) => participant.emailAddress ?? participant.contactId)}
      notFoundContent={loading ? <Spin size="small" /> : null}
      tagRender={(props) => <ParticipantSelectTag key={props.value} {...props} selectValue={field.value} />}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      {...restProps}
      options={options}
    />
  );
};

const isHaupEmailContact = (rechtstraegerAndEmail: RechtstraegerAndEmail, rechtstraegerAndEmailList: RechtstraegerAndEmail[]) => {
  const {
    emailContact: { contactId },
    rechtstraeger: { rechtstraegerId },
  } = rechtstraegerAndEmail;

  const otherContact = rechtstraegerAndEmailList.find((item) => {
    return item.emailContact.contactId !== contactId && item.rechtstraeger.rechtstraegerId === rechtstraegerId;
  });
  return !!(rechtstraegerAndEmail.emailContact.haupt && otherContact);
};

export default ParticipantSelect;
