import { Descriptions, Drawer, Skeleton } from 'antd';
import React, { JSX } from 'react';
import Maybe from '../../Maybe';

export const DetailDrawer = (props: {
  title: string;
  isCollapsed: boolean;
  onCollapse: any;
  onClose?: () => void;
  loading: boolean;
  detailSections: DevListDataType;
}) => (
  <Drawer
    width={720}
    title={props.title}
    placement="right"
    closable
    onClose={() => {
      props.onCollapse();
    }}
    afterOpenChange={(visible) => {
      if (!visible) {
        props.onClose && props.onClose();
      }
    }}
    open={props.isCollapsed}
    destroyOnClose
  >
    <DescriptionDevLog loading={props.loading} list={props.detailSections} />
  </Drawer>
);

type OptionalString = string | number | null;
type DevContentType = Maybe<OptionalString> | OptionalString | DevListDataType | JSX.Element | undefined;
type DevListDataDescriptionItemListType = Array<{ title: string; content: DevContentType }>;
export type DevListDataType = Array<{ title: string; descriptionItemList: DevListDataDescriptionItemListType }>;

export const DescriptionDevLog = (props: { list: DevListDataType; loading: boolean }) => (
  <>
    <Skeleton active loading={props.loading}>
      {props.list.map((item) => (
        <Descriptions key={item.title} style={{ marginTop: '24px' }} size="small" title={item.title} bordered column={{ xxl: 1 }}>
          {item.descriptionItemList.map((descriptionItem) => (
            <Descriptions.Item key={descriptionItem.title} label={descriptionItem.title}>
              {(() => {
                if (descriptionItem.content instanceof Maybe) {
                  return descriptionItem.content.valueOr(null);
                } else {
                  if (Array.isArray(descriptionItem.content)) {
                    return <DescriptionDevLog list={descriptionItem.content} loading={false} />;
                  }
                  return descriptionItem.content;
                }
              })()}
            </Descriptions.Item>
          ))}
        </Descriptions>
      ))}
    </Skeleton>
  </>
);
