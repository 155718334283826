import React, { FC } from 'react';
import { Col, Divider } from 'antd';
import { SollHaben } from '../../../../../../types';
import KtoDetailsCard from './KtoDetailsCard';
import { KontoForCard } from '../../KontoDetailsCard/KontoDetails';

type Props = {
  habenKonto?: KontoForCard | null;
  sollKonto?: KontoForCard | null;
  objektId?: string;
  buchungskreisRechtstraegerId: string;
  buchungskreisId: string;
};

const KtoDetailsCards: FC<Props> = ({ habenKonto, sollKonto, objektId, buchungskreisId, buchungskreisRechtstraegerId }) => {
  return (
    <>
      <Col span={11}>
        {sollKonto && (
          <KtoDetailsCard
            konto={sollKonto}
            kontoType={SollHaben.Soll}
            buchungskreisId={buchungskreisId}
            buchungskreisRechtstraegerId={buchungskreisRechtstraegerId}
            objektId={objektId}
          />
        )}
      </Col>
      <Col>{sollKonto && habenKonto && <Divider type="vertical" style={{ height: '100%' }} />}</Col>
      <Col span={11}>
        {habenKonto && (
          <KtoDetailsCard
            konto={habenKonto}
            kontoType={SollHaben.Haben}
            buchungskreisId={buchungskreisId}
            buchungskreisRechtstraegerId={buchungskreisRechtstraegerId}
            objektId={objektId}
          />
        )}
      </Col>
    </>
  );
};

export default KtoDetailsCards;
