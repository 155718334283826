import { Tag } from 'antd';
import React from 'react';
import { MitarbeiterTooltip } from '../../../components/Card';
import { compareTwoStringsForSorting } from '../../../helpers/stringHelper';
import { compareTwoDatesForSorting } from '../../../helpers/dateHelper';
import AuftragsartTableActions from './AuftragsartTableActions';
import FirmendatenKurzBezeichnung from '../../shared/FirmendatenKurzBezeichnung';
import { StatusEnum } from '../../../helpers/statusHelper';
import StatusBadge from '../../../components/Status/StatusBadge';
import DataWithStatus from '../../../components/Helpers/DataWithStatus';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { AuftragsartBase } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';

const auftragsartTableColumns = (onAction: () => void): TableWithColSelectorColumnProps<AuftragsartBase>[] => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    defaultSortOrder: 'ascend',
    render: (text, record) => (
      <DataWithStatus status={record.status} maxTextLength={30} text={record.bezeichnung} showStatusDescription={false} showBadgeDot={false} />
    ),
  },
  {
    title: 'Typ',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.type.text, b.type.text),
    render: (text, record) => (
      <DataWithStatus status={record.status} maxTextLength={40} text={record.type.text} showStatusDescription={false} showBadgeDot={false} />
    ),
  },
  {
    title: 'Firma',
    defaultSelected: true,
    width: 240,
    render: (text, record) => (
      <DataWithStatus text={record.createTs} status={record.status} showBadgeDot={false} showStatusDescription={false}>
        {() => (record.firmendatenId ? <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} /> : <Tag>System</Tag>)}
      </DataWithStatus>
    ),
  },
  {
    title: 'Erstellt am',
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => (
      <DataWithStatus text={record.createTs} status={record.status} showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    filters: [
      { text: 'Aktiv', value: StatusEnum.Aktiv },
      { text: 'Archiviert', value: StatusEnum.Archiviert },
    ],
    onFilter: (value, record) => record.status.value.includes(value as string),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <AuftragsartTableActions auftragsart={record} onAction={onAction} />,
  },
];

export default auftragsartTableColumns;
