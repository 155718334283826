import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import TicketPage from './TicketPage';
import { URI_TICKET_PAGE } from '../../features/Ticket/ticketUriPaths';

export type TicketDetailsPageRouteParams = {
  ticketId: string;
  subTicketId?: string;
};

const ticketPageRoute = (
  <Route path={URI_TICKET_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<TicketPage />} />} />
);

export default ticketPageRoute;
