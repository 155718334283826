import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Ticket, TicketBase } from '../../../../types';
import TicketSubTicketListingCollapse from './TicketSubTicketListingCollapse';
import TicketForm from '../../Form/TicketForm';
import { useToggle } from '../../../../hooks/useToggle';
import { useTicketCreateSubTicketMutation, useUpdateTicketMutation } from '../../gql/TicketMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { mapFormValuesToSubTicketCreateInput, TicketFormValues } from '../../Form/ticketFormMapper';
import { handleSubTicketMutationResponseWarnings } from '../../Form/ticketFormHelpers';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { useCustomModal } from '../../../../components/Modal/ModalProvider';
import { useShowArchivedQueryParamState } from '../../../../hooks/useShowArchivedQueryParamState';
import { isTicketArchived } from '../../ticketHelpers';

type Props = {
  ticket: Ticket;
  refetch: () => Promise<unknown>;
};

const TicketSubTicketListing: FC<Props> = ({ ticket, refetch }) => {
  const [isCreateDrawerOpen, toggleIsCreateDrawerOpen] = useToggle();
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();
  const [subTickets, setSubTickets] = useState<TicketBase[]>(ticket.subTicketList);
  const modal = useCustomModal();

  const isArchived = isTicketArchived(ticket);

  useEffect(() => {
    setSubTickets(ticket.subTicketList.filter((subTicket) => showArchived || !isTicketArchived(subTicket)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showArchived, ticket]);

  useEffect(() => {
    if (!isArchived) return;
    toggleShowArchived(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchived, showArchived]);

  const [runCreate, { loading: loadingCreate }] = useTicketCreateSubTicketMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateTicketMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSubmitCreate = async (values: TicketFormValues) => {
    const response = await runCreate({
      variables: { ticketId: ticket.ticketId, input: mapFormValuesToSubTicketCreateInput(values) },
    }).then((response) => {
      return handleSubTicketMutationResponseWarnings<typeof response>(response, ticket, runUpdate, refetch, (dueDate, onOk) => {
        modal.confirm({
          title: 'Fälligkeit der Hauptaufgabe ändern?',
          content: (
            <Typography.Text>
              Die Fälligkeit einer Unteraufgabe ist nach der Fälligkeit der Hauptaufgabe ausgerichtet. Möchten Sie die Fälligkeit der Hauptaufgabe
              ebenfalls auf <CustomFormattedDate value={dueDate} /> ändern?
            </Typography.Text>
          ),

          okText: 'Ja',
          cancelText: 'Nein',
          onOk,
        });
      });
    });
    return response.data?.actionTicketCreateSubTicket.data;
  };

  return (
    <>
      {!ticket.isSubTicket && (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Typography.Title level={5}>Unteraufgaben</Typography.Title>
            <Space>
              <Button size="small" disabled={isArchived} onClick={toggleShowArchived}>
                Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}
              </Button>
              <Button size="small" disabled={isArchived} type="primary" htmlType="button" onClick={toggleIsCreateDrawerOpen}>
                <PlusOutlined /> Neu anlegen
              </Button>
            </Space>
          </Space>
          {ticket.subTicketList.length > 0 ? (
            <TicketSubTicketListingCollapse subTicketList={subTickets} refetch={refetch} />
          ) : (
            <div
              style={{
                padding: '8px',
                color: 'rgba(0, 0, 0, 0.25)',
                textAlign: 'center',
              }}
            >
              Keine Unteraufgaben vorhanden
            </div>
          )}
        </Space>
      )}

      <Drawer title="Unteraufgabe anlegen" width={720} onClose={toggleIsCreateDrawerOpen} open={isCreateDrawerOpen} destroyOnClose>
        <TicketForm
          parentId={ticket.ticketId}
          isSubticket
          onSuccess={() => {
            toggleIsCreateDrawerOpen(false);
            refetch();
          }}
          onCancel={toggleIsCreateDrawerOpen}
          loadingCreate={loadingCreate || loadingUpdate}
          onFormSubmitCreate={onSubmitCreate}
        />
      </Drawer>
    </>
  );
};

export default TicketSubTicketListing;
