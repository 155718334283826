import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { BudgetingGenerierlaufEntry } from '../../../../../types';
import { EuroAmount, Percent } from '../../../../../components/Number';
import { compareTwoNumbersForSorting } from '../../../../../helpers/numberHelper';
import MitarbeiterTooltip from '../../../../../components/Card/MitarbeiterTooltip';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToBudgetingDetailsPage } from '../../../../Budgeting/budgetingUriPaths';
import { generatePathToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { isNotNil } from '../../../../../helpers/assertionHelper';

const budgetingVerarbeitungEntryTableColumns: TableWithColSelectorColumnProps<BudgetingGenerierlaufEntry>[] = [
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    render: (_, record) =>
      record.budgeting ? (
        <DataWithShortenedText text={record.budgeting.objekt.kurzBezeichnung} maxTextLength={25}>
          {(shortenedText) => {
            return (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <Link to={generatePathToObjektDetailsPage(record.budgeting!.objekt.objektId)} target="_blank">
                {shortenedText}
              </Link>
            );
          }}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.budgeting?.bezeichnung, b.budgeting?.bezeichnung),
    render: (text, record) =>
      record.budgeting ? (
        <DataWithShortenedText text={record.budgeting.bezeichnung} maxTextLength={20}>
          {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Vorschreibung Vorjahr',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.budgeting?.prevYear, b.budgeting?.prevYear),
    render: (text, record) =>
      record.budgeting ? <Typography.Text>{record.budgeting.prevYear}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Vorschreibung aktuell',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.budgeting?.currentYear, b.budgeting?.currentYear),
    render: (text, record) =>
      record.budgeting ? <Typography.Text>{record.budgeting.currentYear}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Vorschreibung Vorjahr Summe',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.budgeting?.vorschreibungPrevYearSum, b.budgeting?.vorschreibungPrevYearSum),
    render: (text, record) =>
      record.budgeting ? <EuroAmount value={record.budgeting.vorschreibungPrevYearSum} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Vorschreibung aktuell Summe',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.budgeting?.vorschreibungCurrentYearSum, b.budgeting?.vorschreibungCurrentYearSum),
    render: (text, record) =>
      record.budgeting ? <EuroAmount value={record.budgeting.vorschreibungCurrentYearSum} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Hochrechnung Restjahr',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.budgeting?.restOfCurrentYearSum, b.budgeting?.restOfCurrentYearSum),
    render: (text, record) =>
      isNotNil(record.budgeting?.restOfCurrentYearSum) ? (
        <EuroAmount value={record.budgeting.restOfCurrentYearSum} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Basisbetrag',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.budgeting?.totalCurrentYearSum, b.budgeting?.totalCurrentYearSum),
    render: (text, record) =>
      isNotNil(record.budgeting?.totalCurrentYearSum) ? (
        <EuroAmount value={record.budgeting.totalCurrentYearSum} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Erhöhung %',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.budgeting?.increase, b.budgeting?.increase),
    render: (text, record) =>
      isNotNil(record.budgeting?.increase) ? <Percent value={record.budgeting.increase / 100} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Jahresbudget Summe',
    align: 'right',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.budgeting?.budgetSumNextYear, b.budgeting?.budgetSumNextYear),
    render: (text, record) =>
      isNotNil(record.budgeting?.budgetSumNextYear) ? (
        <EuroAmount value={record.budgeting.budgetSumNextYear} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.budgeting?.status.text, b.budgeting?.status.text),
    render: (_, record) =>
      record.budgeting ? <Typography.Text>{record.budgeting?.status.text}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Verarbeitet aus',
    render: () => <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) =>
      record.budgeting ? <FormattedDateTime createdTs={record.budgeting.createTs} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Ersteller',
    render: (text, record) =>
      record.budgeting ? (
        <MitarbeiterTooltip
          mitarbeiterId={record.budgeting.createdByMitarbeiterId}
          userId={record.budgeting.createdBy}
          alignment="left"
          showsInitials
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },

  {
    title: 'Aktion',
    defaultSelected: true,
    render: (_, record) =>
      record.budgeting ? (
        <Link to={generatePathToBudgetingDetailsPage(record.budgeting.objekt.objektId, record.budgeting.budgetingId)} target="_blank">
          Details
        </Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
];
export default budgetingVerarbeitungEntryTableColumns;
