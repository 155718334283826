import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../../shared/dataChangedSubscription';
import { DataChangedChangeType, KontoVerwendung } from '../../../../types';

export const useOnIbanFibuKontoDataChangeEvents = (key: string, callback: () => Promise<unknown>) => {
  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.konto,
        params: {
          // show data change only for verwendung = Bank
          verwendung: KontoVerwendung.Bank,
        },
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Fibu-Konto wurde gerade erstellt',
      key: `${key}-create`,
      callback,
    },
  ]);
};
