import React, { FC } from 'react';
import { Collapse, Skeleton } from 'antd';
import RechtstraegerBankDetails from './RechtstraegerBankDetails';
import PanelHeader from './PanelHeader';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import { Auftrag } from '../../../../types';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

type Props = {
  auftrag: Auftrag;
  isLoading: boolean;
};

const RechnungRechtstraegerCollapse: FC<Props> = ({ isLoading, auftrag }) => {
  const { rechnungsAusstellerBezeichnung, aussteller, ausstellerBankDetails } = auftrag.auftragsKopf;

  const empfaenger = auftrag.auftragsKopf.empfaenger;
  const empfaengerBankDetails = auftrag.auftragsKopf.empfaengerBankDetails ?? undefined;

  return (
    <Skeleton active loading={isLoading}>
      <Collapse bordered={false} defaultActiveKey={[1, 2]}>
        <Collapse.Panel
          key={1}
          showArrow={false}
          header={
            <CardDetailsInfoRow infoRowTitle="Rechnungsaussteller">
              <RechtstraegerWithContactsTooltip
                rechtstraegerId={aussteller.rechtstraegerId}
                rechtstraegerName={rechnungsAusstellerBezeichnung}
                maxTextLength={20}
              />
            </CardDetailsInfoRow>
          }
        >
          <RechtstraegerBankDetails bankDetails={ausstellerBankDetails} />
        </Collapse.Panel>
        <Collapse.Panel key={2} showArrow={false} header={<PanelHeader text="Rechnungsempfänger" rechtstraeger={empfaenger} />}>
          <RechtstraegerBankDetails bankDetails={empfaengerBankDetails} />
        </Collapse.Panel>
      </Collapse>
    </Skeleton>
  );
};

export default RechnungRechtstraegerCollapse;
