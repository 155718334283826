import { AuftragsartType, EigenbelegType, FileInfoType, InvoiceType } from '../../../types';

const avatarColorForFileInfoType = (fileType: string) => {
  // EigenbelegType is used for Dokumenten listing, AuftragsartType is used for Vorschreibungen listing
  switch (fileType) {
    case FileInfoType.AusgangsRechnung:
    case AuftragsartType.VorschreibungDauerrechnung:
    case AuftragsartType.VorschreibungEinzel:
    case AuftragsartType.VorschreibungEinzelAbrechnungsergebnisGutschrift:
    case AuftragsartType.VorschreibungEinzelAbrechnungsergebnisRechnung:
    case EigenbelegType.VorschreibungDauerrechnung:
    case EigenbelegType.VorschreibungEinzel:
    case EigenbelegType.VorschreibungAbrErgebnisGutschrift:
    case EigenbelegType.VorschreibungAbrErgebnisRechnung:
      return 'rgb(89, 126, 247)'; // = '597EF7' = same as in avatarColorForFileInfoType
    case AuftragsartType.VorschreibungDauerrechnungStorno:
    case AuftragsartType.VorschreibungEinzelStorno:
    case AuftragsartType.VorschreibungEinzelAbrechnungsergebnisGutschriftStorno:
    case AuftragsartType.VorschreibungEinzelAbrechnungsergebnisRechnungStorno:
    case EigenbelegType.StornoRechnung:
      return '#E51AE2FF'; // = rgba von '#597EF7' = same as in avatarColorForBelegSymbol
    case InvoiceType.SingleInvoice:
    case InvoiceType.StandingInvoice:
      return '#f77e59';
    case FileInfoType.HeAbrechnung:
      return '#d22b58';
    case FileInfoType.BkObjektAbrechnung:
    case FileInfoType.WeObjektAbrechnung:
      return '#4B8A3B';
    case FileInfoType.BkTopAbrechnung:
    case FileInfoType.WeTopAbrechnung:
      return '#f50';
    case FileInfoType.SubAbrechnungObjekt:
      return '#ffc4c3';
    case FileInfoType.SubAbrechnungBestandseinheit:
      return '#f69f9f';
    case FileInfoType.MailAttachment:
      return '#943fb9';
    case FileInfoType.ObjektFinancialPlan:
      return '#109837';
    case FileInfoType.TopFinancialPlan:
      return '#cf2855';
    case 'Generierlauf':
      return '#1677ff';
    case 'Zinsliste':
      return '#1677ff';
    case 'MAHNUNG':
      return '#24d8ea';
    default:
      return '#ccc';
  }
};

export default avatarColorForFileInfoType;
