import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { isNotNil } from '../../../../../../../helpers/assertionHelper';
import { FibuBuchungszeile } from '../../../../../../../types';
import { selectFilterOption } from '../../../../../../../helpers/selectHelper';

type Props = {
  name: string;
  buchungszeileList: FibuBuchungszeile[];
  getItemForSelect: (data: FibuBuchungszeile) => string | null | undefined;
} & SelectProps;

const BzGeneralSelect: FC<Props> = ({ name, buchungszeileList, getItemForSelect, ...restProps }) => {
  const itemsForSelect = buchungszeileList.map(getItemForSelect).filter(isNotNil);
  const uniqueItemsForSelect = Array.from(new Set(itemsForSelect));

  return (
    <Select name={name} size="small" allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {uniqueItemsForSelect.map((item, index) => (
        <Select.Option key={index} value={item}>
          {item}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BzGeneralSelect;
