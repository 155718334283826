import React from 'react';
import { Box } from 'rebass';
import { ITimeblock, IVersionable, NewTimeblock, TimelineProvider, VersionTimelineOnDelete } from './timelineCtx';
import TimelineList from './TimelineList';
import { StyledSkeleton } from '../Loading';
import TimelineCreateItemBtnAndSearchField from './TimelineCreateItemBtnAndSearchField';

type VersionTimelineProps<T extends IVersionable<T>> = {
  dataSource: T[];
  versionIdKey: keyof T;
  loading?: boolean;
  defaultNewTimeblock: NewTimeblock<T>;
  renderForm: (timeblock: ITimeblock<T>) => React.ReactNode;
  kommentarForCardTitle?: (timeblock: ITimeblock<T>) => string | null | undefined;
  renderCardContent: (timeblock: ITimeblock<T>) => React.ReactNode;
  onDelete: VersionTimelineOnDelete<T>;
  isAllowedToDeleteInitialTimeblock?: boolean;
};

function VersionTimeline<T extends IVersionable<T>>({
  dataSource,
  versionIdKey,
  loading,
  defaultNewTimeblock,
  renderForm,
  kommentarForCardTitle,
  renderCardContent,
  onDelete,
  isAllowedToDeleteInitialTimeblock,
}: VersionTimelineProps<T>) {
  if (loading) {
    return <StyledSkeleton loading={loading} active paragraph={{ rows: 4 }} />;
  }

  return (
    <TimelineProvider<T> dataSource={dataSource} versionIdKey={versionIdKey} defaultNewTimeblock={defaultNewTimeblock}>
      <Box>
        <TimelineCreateItemBtnAndSearchField />
        <Box mt="24px" />
        <TimelineList<T>
          renderForm={renderForm}
          kommentarForCardTitle={kommentarForCardTitle}
          renderCardContent={renderCardContent}
          onDelete={onDelete}
          isAllowedToDeleteInitialTimeblock={isAllowedToDeleteInitialTimeblock}
        />
      </Box>
    </TimelineProvider>
  );
}

export default VersionTimeline;
