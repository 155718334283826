import React, { FC } from 'react';
import { Dropdown, MenuProps, Space } from 'antd';
import { DeviationColumnTitleStyledText } from '../../Styled/Deviation.style';
import DeviationChangeTypeTag from '../../DeviationChangeTypeTag';
import { DeviationChangeTypeTuple } from '../../../../types';

type Props = {
  type: DeviationChangeTypeTuple;
  showLinks?: boolean;
};

const DeviationChangeTypeCol: FC<Props> = ({ type, showLinks }) => {
  return (
    <Space direction="vertical">
      <DeviationColumnTitleStyledText type="secondary">Typ</DeviationColumnTitleStyledText>
      <DeviationChangeTypeTag changeType={type} />
      {showLinks && (
        <Dropdown.Button size="small" menu={{ items }}>
          Links
        </Dropdown.Button>
      )}
    </Space>
  );
};

const items: MenuProps['items'] = [
  { key: '1', label: 'Link 1' },
  { key: '2', label: 'Link 2' },
];

export default DeviationChangeTypeCol;
