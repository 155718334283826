import React, { FC } from 'react';
import { Button, Divider, Modal, Space, Spin } from 'antd';
import { FibuBuchungszeile } from '../../../../../../../types';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../../../../components/Button/ButtonsAligned';
import { useToggle } from '../../../../../../../hooks/useToggle';
import { useBuchungsanweisungDeviationListQuery } from '../../../../../gql/BuchungsanweisungDeviationQueries.types';
import ConfirmDeviationUebernehmenModal from './ConfirmDeviationUebernehmenModal';
import DeviationListGroupedByUrsache from '../../../../../../../components/Deviation/List/GroupedByUrsache/DeviationListGroupedByUrsache';
import { mapDeviationListResponseToGroupedList } from '../../../../../../../components/Deviation/deviationMapper';

type Props = {
  buchungsanweisungId: string;
  buchungszeileList: FibuBuchungszeile[];
  onAction: () => void;
  onCancel: () => void;
  open: boolean;
};

const BuchungsanweisungDeviationModal: FC<Props> = ({ buchungsanweisungId, buchungszeileList, onAction, onCancel, open }) => {
  const [isConfirmationModalOpen, toggleIsConfirmationModalOpen] = useToggle();

  const { loading, data } = useBuchungsanweisungDeviationListQuery({ variables: { buchungsanweisungId } });

  const buchungsanweisungDeviationList = data?.getBuchungsanweisungDeviationList.data || [];

  const buchungzeileInUse = buchungszeileList.find((buchungszeile) => buchungszeile.buchungsanweisungId === buchungsanweisungId);

  return (
    <Modal width={1200} title="Abweichungen übernehmen" open={open} onCancel={onCancel} footer={null} destroyOnClose closable>
      {loading ? (
        <Space style={{ width: '100%', justifyContent: 'center' }}>
          <Spin />
        </Space>
      ) : (
        <Space direction="vertical" style={{ width: '100%' }}>
          <DeviationListGroupedByUrsache
            deviationUrsacheGroupList={mapDeviationListResponseToGroupedList(buchungsanweisungDeviationList)}
            loading={loading}
            showSourceEntity
          />
          <Divider style={{ margin: 8 }} />
          <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
            <Button onClick={onCancel}>Schließen</Button>
            <Button type="primary" onClick={toggleIsConfirmationModalOpen}>
              Alle Abweichungen übernehmen
            </Button>
          </ButtonsAligned>
          <ConfirmDeviationUebernehmenModal
            buchungsanweisungId={buchungsanweisungId}
            buchungzeileInUse={buchungzeileInUse}
            onCancel={toggleIsConfirmationModalOpen}
            onSuccess={() => {
              toggleIsConfirmationModalOpen();
              onAction();
            }}
            isOpen={isConfirmationModalOpen}
          />
        </Space>
      )}
    </Modal>
  );
};

export default BuchungsanweisungDeviationModal;
