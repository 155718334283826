import React, { FC } from 'react';
import { Space, Spin, Typography } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';

import { Spacer } from '../../../components/Grid';
import { useTimelineOpen } from '../../../components/Timeline/useTimelineOpen';
import { useRepFondsEinstellungListQuery } from '../gql/RepFondsEinstellungQueries.types';
import RepFondsKontenZuweisungDrawer from './RepFondsKontenZuweisungDrawer';
import RepFondsKontenZuweisungListingEmpty from './RepFondsKontenZuweisungListingEmpty';
import RepFondsKontenZuweisungTable from './Table/Read/RepFondsKontenZuweisungTable';
import theme from '../../../theme';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';

const RepFondsKontenZuweisungListing: FC = () => {
  const { timelineDrawer, openTimelineUpdateDrawer, closeTimelineDrawer, openTimelineCreateDrawer } = useTimelineOpen();

  const { data, refetch: reloadRepFondsCurrentKontenZuweisung, loading } = useRepFondsEinstellungListQuery({ variables: { onlyCurrent: true } });

  const repFondsEinstellungList = data?.getRepFondsEinstellungList.data;

  const repFondsEinstellung = repFondsEinstellungList && repFondsEinstellungList.length > 0 ? repFondsEinstellungList[0] : undefined;
  const isLoading = !repFondsEinstellung && loading;

  if (isLoading)
    return (
      <Space style={{ width: '100%', justifyContent: 'center' }}>
        <Spin />
      </Space>
    );

  return (
    <>
      {repFondsEinstellung ? (
        <>
          <Space align="baseline">
            <Typography.Title level={5}>
              Reparaturfonds-Kontenzuweisung ab dem <CustomFormattedDate value={repFondsEinstellung.validFrom} />
            </Typography.Title>
            <HistoryOutlined onClick={openTimelineUpdateDrawer} style={{ color: theme.colors.blue }} />
          </Space>
          <Spacer height={16} />
          <RepFondsKontenZuweisungTable repFondsEinstellung={repFondsEinstellung} />
        </>
      ) : (
        <RepFondsKontenZuweisungListingEmpty
          openTimelineCreateDrawer={openTimelineCreateDrawer}
          openTimelineUpdateDrawer={openTimelineUpdateDrawer}
        />
      )}
      <RepFondsKontenZuweisungDrawer
        timelineDrawer={timelineDrawer}
        closeTimelineDrawer={closeTimelineDrawer}
        reloadRepFondsCurrentKontenZuweisung={reloadRepFondsCurrentKontenZuweisung}
      />
    </>
  );
};

export default RepFondsKontenZuweisungListing;
