import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { UstRegelsetUstKategorieTimelineDataSourceEntry } from '../ustRegelsetVersionTimelineMapper';
import { UstRegelsetUstKategorieZuweisungCreateInput, UstRegelsetUstKategorieZuweisungUpdateInput } from '../../../../../types';

export interface UstRegelsetVersionFormValues {
  validFrom: string;
  ustKategorieId: string;
}

export const ustRegelsetVersionFormFields: FormFields<UstRegelsetVersionFormValues> = {
  validFrom: 'validFrom',
  ustKategorieId: 'ustKategorieId',
};

export const mapUstRegelsetTimeblockToFormValues = (timeblock: UstRegelsetUstKategorieTimelineDataSourceEntry): UstRegelsetVersionFormValues => ({
  validFrom: timeblock.validFrom,
  ustKategorieId: timeblock.ustKategorie.ustKategorieId,
});

export const mapFormValuesToUstRegelsetVersion = (
  values: UstRegelsetVersionFormValues
): UstRegelsetUstKategorieZuweisungCreateInput | UstRegelsetUstKategorieZuweisungUpdateInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  ustKategorieId: values.ustKategorieId || '',
});
