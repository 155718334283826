import React from 'react';
import { Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { StyledContentTab } from '../../../components/Card/Styled/Card.style';
import Breadcrumbs from '../../../features/Breadcrumb';
import { ContentMain } from '../../../components/LayoutAndromeda/Styled/Layout.style';
import IncomingInvoicePreviewSaved from '../../../features/IncomingInvoice/shared/IncomingInvoicePreviewSaved';
import { useSingleInvoiceForDetailsAndUpdatePageQuery } from '../../../features/IncomingInvoice/gql/SingleInvoiceQueries.types';
import IncomingInvoiceForm from '../../../features/IncomingInvoice/Form/IncomingInvoiceForm';
import { StyledSpaceInvoicePage } from '../../../shared/Invoice/styled/StyledInvoicePage.styled';

type RouteParams = { fileId: string; belegId: string };

const SingleInvoiceUpdatePage = () => {
  const { fileId, belegId } = useParams() as RouteParams;

  const { loading, data, refetch } = useSingleInvoiceForDetailsAndUpdatePageQuery({
    variables: { belegId },
  });

  const singleInvoice = data?.getSingleInvoiceForDetailsAndUpdatePage.data;
  const singleInvoiceErrorList = data?.getSingleInvoiceForDetailsAndUpdatePage.errorList;

  return (
    <>
      <Breadcrumbs />
      <ContentMain style={{ borderRadius: '4px' }}>
        <StyledContentTab>
          <StyledSpaceInvoicePage>
            <IncomingInvoicePreviewSaved fileId={fileId} />
            <Skeleton active paragraph loading={loading}>
              {singleInvoice ? (
                <IncomingInvoiceForm
                  fileId={fileId}
                  incomingInvoice={singleInvoice}
                  incomingInvoiceLoading={loading}
                  refetchIncomingInvoice={refetch}
                  errorList={singleInvoiceErrorList}
                />
              ) : null}
            </Skeleton>
          </StyledSpaceInvoicePage>
        </StyledContentTab>
      </ContentMain>
    </>
  );
};

export default SingleInvoiceUpdatePage;
