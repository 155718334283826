import React from 'react';
import { Route } from 'react-router';
import { generatePath } from 'react-router-dom';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import SysSettingsKontenplanPage from './SysSettingsKontenplanPage';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import { KontoKlasse, KontoType, KontoVerwendung } from '../../../../types';
import { mapToQueryString } from '../../../../hooks/useStringQueryParams';

export const sysSettingsKontenplanPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.kontenplanPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsKontenplanPage />} />}
  />
);

export enum KontenplanQueryParams {
  KLASSE = 'klasse',
  TYPE = 'type',
  VERWENDUNG = 'verwendung',
}

export type TKontenplanQueryParams = {
  klasse?: KontoKlasse;
  type?: KontoType[];
  verwendung?: KontoVerwendung;
};

export const updateKontenplanQueryParams = (navigate: (path: string) => void, filters: TKontenplanQueryParams) =>
  navigate(generatePathToKontenplanPage(filters.klasse, filters.type, filters.verwendung));

export const generatePathToKontenplanPage = (kontoklasse?: string, kontotype?: KontoType[], kontoverwendung?: string) =>
  `${generatePath(URI_SYS_SETTINGS.kontenplanPage)}?${mapToQueryString({
    [KontenplanQueryParams.KLASSE]: kontoklasse,
    [KontenplanQueryParams.TYPE]: kontotype,
    [KontenplanQueryParams.VERWENDUNG]: kontoverwendung,
  })}`;

export default sysSettingsKontenplanPageRoute;
