import React, { FC } from 'react';
import { Col, Space, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { EuroAmount } from '../../../../../../components/Number';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { generatePathToKontoblattPage } from '../../../../../Kontoblatt/kontoblattUriPaths';
import { useSaldoQuery } from '../../../../../AbrKontenzuordnung/gql/KontoQueries.types';

type Props = {
  kontoId: string;
  objektId?: string;
  buchungskreisRechtstraegerId: string;
  buchungskreisId: string;
};

const KontoSaldoForBooking: FC<Props> = ({ kontoId, objektId, buchungskreisRechtstraegerId, buchungskreisId }) => {
  const { loading, data: maybeSaldo } = useSaldoQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { rechtstraegerId: buchungskreisRechtstraegerId!, kontoId },
  });
  const saldo = maybeSaldo?.getSaldo.data;

  const contentIfBuchungskreisIsChosen =
    loading || !saldo ? (
      <Spin />
    ) : (
      <Link to={generatePathToKontoblattPage({ kontoId, objektId, buchungskreisId })} target="_blank">
        <EuroAmount value={saldo.saldo} />
      </Link>
    );

  return (
    <Col>
      <Space>
        <Typography.Text type="secondary">Saldo</Typography.Text>
        {!buchungskreisRechtstraegerId ? <TextForEmptyValue textToShow="minus" /> : contentIfBuchungskreisIsChosen}
      </Space>
    </Col>
  );
};

export default KontoSaldoForBooking;
