import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { AbrechnungskreisNichtAbrechnen } from '../../AbrKontenzuordnung/Form/zuordnungKontoListingFormMapper';
import MitarbeiterTooltip from '../../../components/Card/MitarbeiterTooltip';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { AbrechnungsdefinitionKontoAbrechnungskreis } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../constants/andromedaSystemSettingsUriPaths';

const kontenZuordnungTemplateTableColumns: TableWithColSelectorColumnProps<AbrechnungsdefinitionKontoAbrechnungskreis>[] = [
  {
    title: 'Abrechnungsdefinition / Template',
    defaultSelected: true,
    render: (text, record) => <>{record.abrechnungsdefinition.bezeichnung}</>,
  },
  {
    title: 'Abrechnungskreis',
    defaultSelected: true,
    render: (text, record) => (
      <>
        {record.kontoAbrechnungskreisList[0]?.abrechnen && record.kontoAbrechnungskreisList[0]?.abrechnungskreis
          ? record.kontoAbrechnungskreisList[0].abrechnungskreis.bezeichnung
          : AbrechnungskreisNichtAbrechnen.text}
      </>
    ),
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.abrechnungsdefinition.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip
        userId={record.abrechnungsdefinition.createdBy}
        mitarbeiterId={record.abrechnungsdefinition.createdByMitarbeiterId}
        showsInitials
        alignment="left"
      />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => (
      <Link
        to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.abrechnungKontenZuordnungPage, {
          abrechnungsdefinitionId: record.abrechnungsdefinition.abrechnungsdefinitionId,
          kontoKlasse: record.kontoAbrechnungskreisList[0]?.konto?.kontoKlasse ?? undefined,
        })}
      >
        zur Kontenzuordnung
      </Link>
    ),
  },
];

export default kontenZuordnungTemplateTableColumns;
