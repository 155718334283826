import React, { FC } from 'react';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import RechtstraegerBankDetailsChangeHistoryListingTable from '../RechtstraegerBankDetails/RechtstraegerBankDetailsChangeHistoryListingTable';
import SepaMandatChangeHistoryListingTable from '../SepaMandat/SepaMandatChangeHistoryListingTable';

type Props = {
  bankDetailsId: string;
  isBankDetailsChangeHistoryOpen: boolean;
  isSepaMandatChangeHistoryOpen: boolean;
  toggleBankDetailsChangeHistoryOpen: () => void;
  toggleSepaMandatChangeHistoryOpen: () => void;
  rechtstraegerId: string;
};

const RechtstraegerBankDetailsHistoryModals: FC<Props> = ({
  bankDetailsId,
  isBankDetailsChangeHistoryOpen,
  isSepaMandatChangeHistoryOpen,
  toggleBankDetailsChangeHistoryOpen,
  toggleSepaMandatChangeHistoryOpen,
  rechtstraegerId,
}) => {
  return (
    <>
      <HistoryModal historyType={HistoryType.Change} open={isBankDetailsChangeHistoryOpen} onCancel={toggleBankDetailsChangeHistoryOpen}>
        <RechtstraegerBankDetailsChangeHistoryListingTable rechtstraegerId={rechtstraegerId} bankDetailsId={bankDetailsId} />
      </HistoryModal>
      <HistoryModal historyType={HistoryType.Change} open={isSepaMandatChangeHistoryOpen} onCancel={toggleSepaMandatChangeHistoryOpen}>
        <SepaMandatChangeHistoryListingTable rechtstraegerId={rechtstraegerId} bankDetailsId={bankDetailsId} />
      </HistoryModal>
    </>
  );
};

export default RechtstraegerBankDetailsHistoryModals;
