import React from 'react';
import { Link } from 'react-router-dom';
import { NestedTableWithColSelectorColProps } from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { SubAbrOffenerPosten } from '../../../../../../../types';
import DataWithShortenedText from '../../../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../../Rechtstraeger/rechtstraegerHelper';
import { generatePathToKontoblattPage } from '../../../../../../Kontoblatt/kontoblattUriPaths';
import CustomFormattedDate from '../../../../../../../components/FormattedDate/CustomFormattedDate';
import TextForEmptyValue from '../../../../../../../components/Text/TextForEmptyValue';
import { generatePathToOffenePostenPage } from '../../../../../../OffenePosten/offenePostenUriPaths';
import { EuroAmount } from '../../../../../../../components/Number';
import OffenePostenBestandseinheitColumn from '../../../../../shared/OffenePostenBestandseinheitColumn';

const subAbrTabOffenePostenDetailsTableColumns = (
  objektId: string,
  abrechnungszeitraumVon: string,
  abrechnungszeitraumBis: string
): NestedTableWithColSelectorColProps<SubAbrOffenerPosten>[] => [
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.vertragspartner.kurzBezeichnung}>
        {(shortenedText) => (
          <Link to={generatePathToRechtstraegerDetailsPage(record.vertragspartner.rechtstraegerId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Bestandseinheit',
    defaultSelected: true,
    render: (text, record) => <OffenePostenBestandseinheitColumn objektId={objektId} bestandseinheitList={record.bestandseinheitList} />,
  },
  {
    title: 'Fibu-Konto',
    defaultSelected: true,
    render: (text, record) => (
      <Link
        to={generatePathToKontoblattPage({
          objektId,
          kontoId: record.fibuKontoId,
          buchungsdatumFrom: abrechnungszeitraumVon,
          buchungsdatumTo: abrechnungszeitraumBis,
        })}
        target="_blank"
      >
        {record.fibuKontonummer}
      </Link>
    ),
  },
  {
    title: 'Letzte Zahlungsbuchung am',
    defaultSelected: true,
    render: (text, record) =>
      record.letzteBuchungAm ? <CustomFormattedDate value={record.letzteBuchungAm} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Fällig am',
    defaultSelected: true,
    render: (text, record) => (record.dueDate ? <CustomFormattedDate value={record.dueDate} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Offener OP-Betrag',
    align: 'right',
    defaultSelected: true,
    render: (text, record) => (
      <Link
        to={generatePathToOffenePostenPage({
          dueDateTo: record.dueDate,
          dueDateFrom: record.dueDate,
          vertragspartnerId: record.vertragspartner.rechtstraegerId,
          fibuKontoId: record.fibuKontoId,
          objektId,
        })}
        target="_blank"
      >
        <EuroAmount value={record.offenerBetrag} />
      </Link>
    ),
  },
];

export default subAbrTabOffenePostenDetailsTableColumns;
