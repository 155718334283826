import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAuftragsartTemplateMutationVariables = Types.Exact<{
  input: Types.AuftragsartCreateInput;
}>;

export type CreateAuftragsartTemplateMutation = {
  createAuftragsartTemplate: {
    data: { auftragsartId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateAuftragsartTemplateMutationVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
  input: Types.AuftragsartUpdateInput;
}>;

export type UpdateAuftragsartTemplateMutation = {
  updateAuftragsartTemplate: {
    data: { auftragsartId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteAuftragsartTemplateMutationVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
}>;

export type DeleteAuftragsartTemplateMutation = {
  deleteAuftragsartTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateAuftragsartTemplateDocument = gql`
  mutation CreateAuftragsartTemplate($input: AuftragsartCreateInput!) {
    createAuftragsartTemplate(input: $input) {
      data {
        auftragsartId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateAuftragsartTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAuftragsartTemplateMutation, CreateAuftragsartTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAuftragsartTemplateMutation, CreateAuftragsartTemplateMutationVariables>(
    CreateAuftragsartTemplateDocument,
    options
  );
}
export type CreateAuftragsartTemplateMutationHookResult = ReturnType<typeof useCreateAuftragsartTemplateMutation>;
export type CreateAuftragsartTemplateMutationResult = Apollo.MutationResult<CreateAuftragsartTemplateMutation>;
export type CreateAuftragsartTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateAuftragsartTemplateMutation,
  CreateAuftragsartTemplateMutationVariables
>;
export const UpdateAuftragsartTemplateDocument = gql`
  mutation UpdateAuftragsartTemplate($auftragsartId: ID!, $input: AuftragsartUpdateInput!) {
    updateAuftragsartTemplate(auftragsartId: $auftragsartId, input: $input) {
      data {
        auftragsartId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateAuftragsartTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAuftragsartTemplateMutation, UpdateAuftragsartTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAuftragsartTemplateMutation, UpdateAuftragsartTemplateMutationVariables>(
    UpdateAuftragsartTemplateDocument,
    options
  );
}
export type UpdateAuftragsartTemplateMutationHookResult = ReturnType<typeof useUpdateAuftragsartTemplateMutation>;
export type UpdateAuftragsartTemplateMutationResult = Apollo.MutationResult<UpdateAuftragsartTemplateMutation>;
export type UpdateAuftragsartTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateAuftragsartTemplateMutation,
  UpdateAuftragsartTemplateMutationVariables
>;
export const DeleteAuftragsartTemplateDocument = gql`
  mutation DeleteAuftragsartTemplate($auftragsartId: ID!) {
    deleteAuftragsartTemplate(auftragsartId: $auftragsartId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteAuftragsartTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAuftragsartTemplateMutation, DeleteAuftragsartTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAuftragsartTemplateMutation, DeleteAuftragsartTemplateMutationVariables>(
    DeleteAuftragsartTemplateDocument,
    options
  );
}
export type DeleteAuftragsartTemplateMutationHookResult = ReturnType<typeof useDeleteAuftragsartTemplateMutation>;
export type DeleteAuftragsartTemplateMutationResult = Apollo.MutationResult<DeleteAuftragsartTemplateMutation>;
export type DeleteAuftragsartTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteAuftragsartTemplateMutation,
  DeleteAuftragsartTemplateMutationVariables
>;
