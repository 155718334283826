import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { BudgetingAbrDefVPosLinkageFieldsFragmentDoc } from './budgetingAbrDefVPosLinkageFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BudgetingAbrDefVPosLinkageListQueryVariables = Types.Exact<{
  kundenSystemId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  firmenDatenId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type BudgetingAbrDefVPosLinkageListQuery = {
  getBudgetingAbrDefVPosLinkageList: {
    data: Array<{
      budgetingAbrDefVPosLinkageId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      kundenSystemId?: string | null;
      updatedByMitarbeiterId?: string | null;
      abrKreisVPosList: Array<{
        abrechnungskreis: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vorschreibungsposition: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          kontierungstabelle?: {
            basiskonto: number;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kontierungstabelleId: string;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
            subAdministrationExpenseAccount?: {
              bezeichnung: string;
              firmendatenId?: string | null;
              kontoId: string;
              kontoKlasse: string;
              nummer: string;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
          umsatzsteuerkennzeichen?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        };
      }>;
      abrechnungsdefinition: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
        type: { text: string; value: Types.AbrechnungsdefinitionType };
      };
      warningList: Array<{ attribute?: string | null; type: string; message: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingAbrDefVPosLinkageQueryVariables = Types.Exact<{
  budgetingAbrDefVPosLinkageId: Types.Scalars['ID']['input'];
}>;

export type BudgetingAbrDefVPosLinkageQuery = {
  getBudgetingAbrDefVPosLinkage: {
    data: {
      budgetingAbrDefVPosLinkageId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      kundenSystemId?: string | null;
      updatedByMitarbeiterId?: string | null;
      abrKreisVPosList: Array<{
        abrechnungskreis: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vorschreibungsposition: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          kontierungstabelle?: {
            basiskonto: number;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kontierungstabelleId: string;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
            subAdministrationExpenseAccount?: {
              bezeichnung: string;
              firmendatenId?: string | null;
              kontoId: string;
              kontoKlasse: string;
              nummer: string;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
          umsatzsteuerkennzeichen?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        };
      }>;
      abrechnungsdefinition: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
        type: { text: string; value: Types.AbrechnungsdefinitionType };
      };
      warningList: Array<{ attribute?: string | null; type: string; message: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingAbrDefVPosLinkageChangeHistoryListQueryVariables = Types.Exact<{
  budgetingAbrDefVPosLinkageId: Types.Scalars['ID']['input'];
}>;

export type BudgetingAbrDefVPosLinkageChangeHistoryListQuery = {
  getBudgetingAbrDefVPosLinkageChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const BudgetingAbrDefVPosLinkageListDocument = gql`
  query BudgetingAbrDefVPosLinkageList($kundenSystemId: String, $firmenDatenId: String) {
    getBudgetingAbrDefVPosLinkageList(kundenSystemId: $kundenSystemId, firmendatenId: $firmenDatenId) {
      data {
        ...BudgetingAbrDefVPosLinkageFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${BudgetingAbrDefVPosLinkageFieldsFragmentDoc}
`;
export function useBudgetingAbrDefVPosLinkageListQuery(
  baseOptions?: Apollo.QueryHookOptions<BudgetingAbrDefVPosLinkageListQuery, BudgetingAbrDefVPosLinkageListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingAbrDefVPosLinkageListQuery, BudgetingAbrDefVPosLinkageListQueryVariables>(
    BudgetingAbrDefVPosLinkageListDocument,
    options
  );
}
export function useBudgetingAbrDefVPosLinkageListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetingAbrDefVPosLinkageListQuery, BudgetingAbrDefVPosLinkageListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingAbrDefVPosLinkageListQuery, BudgetingAbrDefVPosLinkageListQueryVariables>(
    BudgetingAbrDefVPosLinkageListDocument,
    options
  );
}
export function useBudgetingAbrDefVPosLinkageListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BudgetingAbrDefVPosLinkageListQuery, BudgetingAbrDefVPosLinkageListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingAbrDefVPosLinkageListQuery, BudgetingAbrDefVPosLinkageListQueryVariables>(
    BudgetingAbrDefVPosLinkageListDocument,
    options
  );
}
export type BudgetingAbrDefVPosLinkageListQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageListQuery>;
export type BudgetingAbrDefVPosLinkageListLazyQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageListLazyQuery>;
export type BudgetingAbrDefVPosLinkageListSuspenseQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageListSuspenseQuery>;
export type BudgetingAbrDefVPosLinkageListQueryResult = Apollo.QueryResult<
  BudgetingAbrDefVPosLinkageListQuery,
  BudgetingAbrDefVPosLinkageListQueryVariables
>;
export const BudgetingAbrDefVPosLinkageDocument = gql`
  query BudgetingAbrDefVPosLinkage($budgetingAbrDefVPosLinkageId: ID!) {
    getBudgetingAbrDefVPosLinkage(budgetingAbrDefVPosLinkageId: $budgetingAbrDefVPosLinkageId) {
      data {
        ...BudgetingAbrDefVPosLinkageFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${BudgetingAbrDefVPosLinkageFieldsFragmentDoc}
`;
export function useBudgetingAbrDefVPosLinkageQuery(
  baseOptions: Apollo.QueryHookOptions<BudgetingAbrDefVPosLinkageQuery, BudgetingAbrDefVPosLinkageQueryVariables> &
    ({ variables: BudgetingAbrDefVPosLinkageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingAbrDefVPosLinkageQuery, BudgetingAbrDefVPosLinkageQueryVariables>(BudgetingAbrDefVPosLinkageDocument, options);
}
export function useBudgetingAbrDefVPosLinkageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetingAbrDefVPosLinkageQuery, BudgetingAbrDefVPosLinkageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingAbrDefVPosLinkageQuery, BudgetingAbrDefVPosLinkageQueryVariables>(BudgetingAbrDefVPosLinkageDocument, options);
}
export function useBudgetingAbrDefVPosLinkageSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BudgetingAbrDefVPosLinkageQuery, BudgetingAbrDefVPosLinkageQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingAbrDefVPosLinkageQuery, BudgetingAbrDefVPosLinkageQueryVariables>(
    BudgetingAbrDefVPosLinkageDocument,
    options
  );
}
export type BudgetingAbrDefVPosLinkageQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageQuery>;
export type BudgetingAbrDefVPosLinkageLazyQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageLazyQuery>;
export type BudgetingAbrDefVPosLinkageSuspenseQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageSuspenseQuery>;
export type BudgetingAbrDefVPosLinkageQueryResult = Apollo.QueryResult<BudgetingAbrDefVPosLinkageQuery, BudgetingAbrDefVPosLinkageQueryVariables>;
export const BudgetingAbrDefVPosLinkageChangeHistoryListDocument = gql`
  query BudgetingAbrDefVPosLinkageChangeHistoryList($budgetingAbrDefVPosLinkageId: ID!) {
    getBudgetingAbrDefVPosLinkageChangeHistoryList(budgetingAbrDefVPosLinkageId: $budgetingAbrDefVPosLinkageId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBudgetingAbrDefVPosLinkageChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<BudgetingAbrDefVPosLinkageChangeHistoryListQuery, BudgetingAbrDefVPosLinkageChangeHistoryListQueryVariables> &
    ({ variables: BudgetingAbrDefVPosLinkageChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingAbrDefVPosLinkageChangeHistoryListQuery, BudgetingAbrDefVPosLinkageChangeHistoryListQueryVariables>(
    BudgetingAbrDefVPosLinkageChangeHistoryListDocument,
    options
  );
}
export function useBudgetingAbrDefVPosLinkageChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BudgetingAbrDefVPosLinkageChangeHistoryListQuery,
    BudgetingAbrDefVPosLinkageChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingAbrDefVPosLinkageChangeHistoryListQuery, BudgetingAbrDefVPosLinkageChangeHistoryListQueryVariables>(
    BudgetingAbrDefVPosLinkageChangeHistoryListDocument,
    options
  );
}
export function useBudgetingAbrDefVPosLinkageChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BudgetingAbrDefVPosLinkageChangeHistoryListQuery, BudgetingAbrDefVPosLinkageChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingAbrDefVPosLinkageChangeHistoryListQuery, BudgetingAbrDefVPosLinkageChangeHistoryListQueryVariables>(
    BudgetingAbrDefVPosLinkageChangeHistoryListDocument,
    options
  );
}
export type BudgetingAbrDefVPosLinkageChangeHistoryListQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageChangeHistoryListQuery>;
export type BudgetingAbrDefVPosLinkageChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useBudgetingAbrDefVPosLinkageChangeHistoryListLazyQuery
>;
export type BudgetingAbrDefVPosLinkageChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useBudgetingAbrDefVPosLinkageChangeHistoryListSuspenseQuery
>;
export type BudgetingAbrDefVPosLinkageChangeHistoryListQueryResult = Apollo.QueryResult<
  BudgetingAbrDefVPosLinkageChangeHistoryListQuery,
  BudgetingAbrDefVPosLinkageChangeHistoryListQueryVariables
>;
