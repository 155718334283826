import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { generatePathToAufteilunsschluesselPage } from '../../../../Aufteilungsschluessel/aufteilungsschluesselUriPaths';
import { Verwaltungsart, VorsteuerkuerzungKontenZuweisung } from '../../../../../types';
import { vstKuerzungKontenZuweisungFormValuesDescriptions } from '../../Version/Form/vstKuerzungKontenZuweisungVersionFormMapper';
import VstKuerzungKonto from './VstKuerzungKonto';

// TODO: generatePathToKontoDetails - change Kontoplan Table to highlighted Table

const vstKuerzungKontenZuweisungMieteTableColumns = (
  isInCardContent: boolean,
  verwaltungsart: Verwaltungsart
): ColumnProps<VorsteuerkuerzungKontenZuweisung>[] => [
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).aufwandsKontoIdList,
    width: isInCardContent ? 200 : undefined,
    render: (text, record) => (
      <Space direction="vertical" size={2}>
        {record.aufwandsKontoList.map((konto) => (
          <VstKuerzungKonto isInCardContent={isInCardContent} konto={konto} />
        ))}
      </Space>
    ),
  },
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).aufteilungsschluesselId,
    width: isInCardContent ? 180 : undefined,
    render: (text, record) => (
      <Link to={generatePathToAufteilunsschluesselPage(record.aufteilungsschluessel.aufteilungsschluesselId, record.aufteilungsschluessel.status)}>
        {record.aufteilungsschluessel.bezeichnung}
      </Link>
    ),
  },
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungKontoId,
    width: isInCardContent ? 180 : undefined,
    render: (text, record) => <VstKuerzungKonto isInCardContent={isInCardContent} konto={record.vorsteuerkuerzungKonto} />,
  },
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungEigennutzungKontoId,
    width: isInCardContent ? 180 : undefined,
    // vorsteuerkuerzungEigennutzungKonto is non-nullable for Miet-Objek-Verwaltungsart
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    render: (text, record) => <VstKuerzungKonto isInCardContent={isInCardContent} konto={record.vorsteuerkuerzungEigennutzungKonto!} />,
  },
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungPauschalmieterKontoId,
    width: isInCardContent ? 180 : undefined,
    render: (text, record) => (
      // vorsteuerkuerzungPauschalmieterKonto is non-nullable for Miet-Objek-Verwaltungsart
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      <VstKuerzungKonto isInCardContent={isInCardContent} konto={record.vorsteuerkuerzungPauschalmieterKonto!} />
    ),
  },
];

export default vstKuerzungKontenZuweisungMieteTableColumns;
