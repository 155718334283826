import React, { FC } from 'react';
import { TabsProps } from 'antd';
import RouteTabs from '../../../../components/Tabs/RouteTabs';
import { pathsToHeAbrechnungDetails } from '../heAbrechnungUriPaths';
import HeAbrHeVertragListingTab from './Listing/HeAbrHeVertragListingTab';
import { HeAbrechnung } from '../../../../types';
import ZahlungsrueckstandTab from '../Zahlungsrueckstand/ZahlungsrueckstandTab';

type Props = {
  abrechnung: HeAbrechnung;
  loading: boolean;
  objektId: string;
};

const HeAbrDetailsTabs: FC<Props> = ({ abrechnung, loading, objektId }) => {
  const paths = pathsToHeAbrechnungDetails(objektId, abrechnung.heAbrechnungId);

  const items: TabsProps['items'] = [
    {
      key: paths.heAbrTab,
      label: 'Hauseigentümerabrechnungen',
      children: (
        <HeAbrHeVertragListingTab
          loading={loading}
          objektId={objektId}
          heAbrechnungId={abrechnung.heAbrechnungId}
          heAbrErrorList={abrechnung.errorList}
        />
      ),
    },
    {
      key: paths.zahlungsrueckstandTab,
      label: 'Zahlungsrückstände',
      children: <ZahlungsrueckstandTab abrechnung={abrechnung} loading={loading} objektId={objektId} />,
    },
  ];
  return <RouteTabs items={items} />;
};

export default HeAbrDetailsTabs;
