import * as Yup from 'yup';

import {
  kurzBezeichnungFormPartValidationSchema,
  validateGeneralBezeichnung,
} from '../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';
import { bezugsbasisSelectFormPartValidationSchema } from '../Bezugsbasis/bezugsbasisSelectValidationSchema';
import { masseinheitSelectFormPartValidationSchema } from '../Masseinheit/masseinheitSelectValidationSchema';

/* eslint-disable no-template-curly-in-string */
export const aufteilungsschluesselBezeichnungValidationSchema = validateGeneralBezeichnung(100, true);

export const aufteilungsschluesselFormValidationSchema = Yup.object()
  .shape({
    bezeichnung: aufteilungsschluesselBezeichnungValidationSchema,
  })
  // @ts-ignore
  .concat(kurzBezeichnungFormPartValidationSchema)
  // @ts-ignore
  .concat(masseinheitSelectFormPartValidationSchema)
  // @ts-ignore
  .concat(bezugsbasisSelectFormPartValidationSchema);

/* eslint-enable no-template-curly-in-string */
