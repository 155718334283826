import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import ListingFilters from './Filter/ListingFilters';
import EmailDeliveryTable from './Table/EmailDeliveryTable';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { useMailListQuery } from '../gql/EmailDeliveryQueries.types';
import { useOnEmailDeliveryListDataChangedEvents } from '../useOnEmailDeliveryDataChangedEvents';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import {
  FiltersFormValues,
  mapFormValuesToQueryParams,
  mapQueryParamsToFormValues,
  mapQueryParamsToMailListQueryVariables,
} from './Filter/filtersFormMapper';
import { TEmailDeliveryListingQueryParams, updateQueryParams } from './Filter/filtersQueryParams';

const EmailDeliveryListing = () => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  const navigate = useNavigate();
  const queryParams = useQueryParams<TEmailDeliveryListingQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  const { data, pagination, handleTableSorting, refetch } = useQueryWithPagingAndSorting(
    useMailListQuery,
    {
      variables: {
        ...mapQueryParamsToMailListQueryVariables(firmendatenId, queryParams),
      },
    },
    {
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const mailList = data?.getMailList.data.contentList;

  const mailIds = mailList?.map((mail) => mail.mailId);

  useOnEmailDeliveryListDataChangedEvents('emailDeliveryList', refetch, firmendatenId, mailIds);

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  return (
    <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} queryParams={queryParams} />
          <EmailDeliveryTable mailList={mailList} handleTableSorting={handleTableSorting} pagination={pagination} />
        </>
      )}
    </Formik>
  );
};

export default EmailDeliveryListing;
