import React, { FC } from 'react';
import { Divider, Typography } from 'antd';
import { Link } from 'react-router-dom';
import CardWithFooter from '../../../components/Card';
import CardTitle from '../../../components/Card/CardTitle';
import VorsteuerkuerzungActions from './VorsteuerkuerzungActions';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import { EuroAmount } from '../../../components/Number';
import AufwandKontoList from './AufwandKontoList';
import VstKuerzungKontoList from './VstKuerzungKontoList';
import DocumentStatusTag from '../../../components/Status/DocumentStatus/DocumentStatusTag';
import { Vorsteuerkuerzung } from '../../../types';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { generatePathToRechtstraegerDetailsRechnungAusstellerMenu } from '../../Rechtstraeger/rechtstraegerHelper';

type Props = {
  vstKuerzung: Vorsteuerkuerzung;
  objektId: string;
  onAction: () => void;
};

const VorsteuerkuerzungCard: FC<Props> = ({ vstKuerzung, objektId, onAction }) => {
  return (
    <CardWithFooter
      loading={false}
      createTs={vstKuerzung.createTs}
      title={<CardTitle title="Vorsteuerkürzung" status={vstKuerzung.status} />}
      extra={<VorsteuerkuerzungActions vstKuerzung={vstKuerzung} objektId={objektId} onAction={onAction} />}
      style={{ minWidth: '320px' }}
      size="default"
      bordered={false}
      userId={vstKuerzung.createdBy}
      mitarbeiterId={vstKuerzung.createdByMitarbeiterId}
    >
      <Typography.Title level={5}>Allgemeine Informationen</Typography.Title>
      <CardDetailsInfoRow infoRowTitle="Zeitraum">
        <CustomFormattedDate value={vstKuerzung.vonInklusive} year="numeric" month="2-digit" />
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Rechnungsaussteller">
        <DataWithShortenedText maxTextLength={25} text={vstKuerzung.rechnungsAussteller.kurzBezeichnung}>
          {(shortenedText) => (
            <Link to={generatePathToRechtstraegerDetailsRechnungAusstellerMenu(vstKuerzung.rechnungsAussteller.rechtstraegerId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Vertragsart">
        <Typography.Text>{vstKuerzung.vertragsart.text}</Typography.Text>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Fibu Status">
        <DocumentStatusTag documentStatus={vstKuerzung.buchungStatus} />
        {vstKuerzung.buchungErrorList.length > 0 && (
          // eslint-disable-next-line react/no-unescaped-entities
          <Typography.Text type="danger">Nach der Korrektur, starten Sie bitte erneut die Operation 'verbuchen'</Typography.Text>
        )}
      </CardDetailsInfoRow>
      <Divider dashed />
      <CardDetailsInfoRow infoRowTitle="Summe ER Netto">
        <EuroAmount value={vstKuerzung.sumBetrag} />
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Summe Vst.-Kürzung">
        <EuroAmount value={vstKuerzung.sumVstKuerzung} />
      </CardDetailsInfoRow>
      <Divider dashed />
      <Typography.Title level={5}>Aufwandskonten</Typography.Title>
      <AufwandKontoList vstKuerzung={vstKuerzung} objektId={objektId} />
      <Divider dashed />
      <Typography.Title level={5}>Vorsteuerkürzung-Konten</Typography.Title>
      <VstKuerzungKontoList vstKuerzung={vstKuerzung} objektId={objektId} />
    </CardWithFooter>
  );
};

export default VorsteuerkuerzungCard;
