import React, { FC } from 'react';
import { Skeleton } from 'antd';
import {
  useWeAbrechnungsdefinitionVersionListQuery,
  useWeAbrechnungsdefinitionVersionQuery,
} from '../../../../../../features/Abrechnungsdefinition/gql/WeAbrDef/WeAbrDefVersionQueries.types';
import WeAbrDefVersionUpdateForm from '../../../../../../features/Abrechnungsdefinition/Version/Form/Update/WeAbrDefVersionUpdateForm';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
};
const SysSettingsWeAbrechnungsdefinitionVersionUpdatePage: FC<Props> = ({ abrechnungsdefinitionId, abrechnungsdefinitionVersionId }) => {
  const { data: weAbrDefVersionListData } = useWeAbrechnungsdefinitionVersionListQuery({
    variables: { abrechnungsdefinitionId, withDetails: true },
  });
  const abrechnungsdefinitionVersionList = weAbrDefVersionListData?.getWeAbrechnungsdefinitionVersionList.data;

  const { data: weAbrDefVersion, refetch } = useWeAbrechnungsdefinitionVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId },
  });
  const abrechnungsdefinitionVersion = weAbrDefVersion?.getWeAbrechnungsdefinitionVersion.data;

  if (!abrechnungsdefinitionVersion || !abrechnungsdefinitionVersionList) return <Skeleton active />;

  const firmendatenId = abrechnungsdefinitionVersion.firmendatenId ?? '';

  return (
    <WeAbrDefVersionUpdateForm
      abrechnungsdefinitionVersionList={abrechnungsdefinitionVersionList}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      validFrom={abrechnungsdefinitionVersion.validFrom}
      kommentar={abrechnungsdefinitionVersion.kommentar ?? ''}
      onSuccess={refetch}
      firmendatenId={firmendatenId}
    />
  );
};

export default SysSettingsWeAbrechnungsdefinitionVersionUpdatePage;
