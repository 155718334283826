import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { useInfoMailDeliveryQuery } from '../gql/InfoMailDeliveryQueries.types';
import { generatePathToRechtstraegerDetailsPage } from '../../../Rechtstraeger/rechtstraegerHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  infoMailDeliveryId: string;
};

const RechtstraegerLink: FC<Props> = ({ infoMailDeliveryId }) => {
  const { data, loading } = useInfoMailDeliveryQuery({ variables: { infoMailDeliveryId } });
  const infoMailDelivery = data?.getInfoMailDelivery.data;

  return (
    <Skeleton paragraph={false} active loading={loading}>
      {infoMailDelivery && (
        <DataWithShortenedText text={infoMailDelivery.recipient.kurzBezeichnung} maxTextLength={25}>
          {(shortenedText) => <Link to={generatePathToRechtstraegerDetailsPage(infoMailDelivery.recipient.rechtstraegerId)}>{shortenedText}</Link>}
        </DataWithShortenedText>
      )}
    </Skeleton>
  );
};

export default RechtstraegerLink;
