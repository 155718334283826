import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../styles/pdfStyles';
import Absender from '../../shared/Absender';
import RechnungsEmpfaengerBlock from '../../shared/RechnungsEmpfaengerBlock';
import { LabeledValue, RechnungsEmpfaenger } from '../../shared/types';

const SubAdminAbrAdressorAndDateBlock: FC<{ absender: LabeledValue; rechnungsEmpfaenger: RechnungsEmpfaenger; abrechnungsDatumLine: string }> = ({
  absender,
  rechnungsEmpfaenger,
  abrechnungsDatumLine,
}) => (
  <View style={[pdfStyles.row, { marginTop: '3mm' }]}>
    <View style={[pdfStyles.column, pdfStyles.addressor]}>
      <Absender absender={absender} />
      <View style={[{ marginBottom: '7mm' }]} />
      {/* Rechnungsempfänger */}
      <RechnungsEmpfaengerBlock rechnungsEmpfaengerShippingLabel={rechnungsEmpfaenger.rechnungsEmpfaengerShippingLabel} />
    </View>
    <View style={[pdfStyles.column, { width: '100%', textAlign: 'right' }]}>
      <Text style={[pdfStyles.textNormal]}>
        {rechnungsEmpfaenger.kundennummer.text} {rechnungsEmpfaenger.kundennummer.nummer}
      </Text>
      {/* City+Datum */}
      <Text style={[pdfStyles.textNormal]}>{abrechnungsDatumLine}</Text>
    </View>
  </View>
);

export default SubAdminAbrAdressorAndDateBlock;
