import styled from 'styled-components';
import { Space } from 'antd';

export const StyledInfoFeldListItemSpace = styled(Space)<{ height?: number }>`
  width: 100%;
  > div:nth-child(2) {
    display: flex;
    flex: 1;
    align-items: end;
    height: ${(props) => props.height && props.height}px;
  }
`;
