import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { MahndefinitionFieldsFragmentDoc } from '../../Mahndefinition/gql/MahndefinitionFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MahndefinitionTemplateListQueryVariables = Types.Exact<{
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type MahndefinitionTemplateListQuery = {
  getMahndefinitionTemplateList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      mahndefinitionId: string;
      textBausteineBefuellt: boolean;
      status?: { text: string; value: Types.MahndefinitonStatus } | null;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahndefinitionTemplateSingleQueryVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
}>;

export type MahndefinitionTemplateSingleQuery = {
  getMahndefinitionTemplateSingle: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      mahndefinitionId: string;
      mahnfreieTage: number;
      textBausteineBefuellt: boolean;
      verzugszinsen: number;
      mahnstufe1: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        minBetrag: number;
        naechsteMahnungNachTage: number;
        updatedByMitarbeiterId?: string | null;
        versandEinschreiben: boolean;
        verzugszinsenBerechnen: boolean;
        mahngebuehrList: Array<{
          ab: number;
          betrag: number;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          mahngebuehrId: string;
        }>;
      };
      mahnstufe1TextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      mahnstufe2: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        minBetrag: number;
        naechsteMahnungNachTage: number;
        updatedByMitarbeiterId?: string | null;
        versandEinschreiben: boolean;
        verzugszinsenBerechnen: boolean;
        mahngebuehrList: Array<{
          ab: number;
          betrag: number;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          mahngebuehrId: string;
        }>;
      };
      mahnstufe2TextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      mahnstufe3: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        minBetrag: number;
        naechsteMahnungNachTage: number;
        updatedByMitarbeiterId?: string | null;
        versandEinschreiben: boolean;
        verzugszinsenBerechnen: boolean;
        mahngebuehrList: Array<{
          ab: number;
          betrag: number;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          mahngebuehrId: string;
        }>;
      };
      mahnstufe3TextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      mahnstufe4: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        minBetrag: number;
        naechsteMahnungNachTage: number;
        updatedByMitarbeiterId?: string | null;
        versandEinschreiben: boolean;
        verzugszinsenBerechnen: boolean;
        mahngebuehrList: Array<{
          ab: number;
          betrag: number;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          mahngebuehrId: string;
        }>;
      };
      mahnstufe4TextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      status?: { text: string; value: Types.MahndefinitonStatus } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahndefinitionTemplateChangeHistoryListQueryVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
}>;

export type MahndefinitionTemplateChangeHistoryListQuery = {
  getMahndefinitionTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type MahnstufeTemplateChangeHistoryListQueryVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
  mahnstufeIndex: Types.Scalars['Int']['input'];
}>;

export type MahnstufeTemplateChangeHistoryListQuery = {
  getMahnstufeTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type MahnungTemplateTemplateChangeHistoryListQueryVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
  mahnstufeIndex: Types.Scalars['Int']['input'];
}>;

export type MahnungTemplateTemplateChangeHistoryListQuery = {
  getMahnungTemplateTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const MahndefinitionTemplateListDocument = gql`
  query MahndefinitionTemplateList($withDetails: Boolean) {
    getMahndefinitionTemplateList(withDetails: $withDetails) {
      data {
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        deletable
        firmendatenId
        mahndefinitionId
        status {
          text
          value
        }
        textBausteineBefuellt
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMahndefinitionTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<MahndefinitionTemplateListQuery, MahndefinitionTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahndefinitionTemplateListQuery, MahndefinitionTemplateListQueryVariables>(MahndefinitionTemplateListDocument, options);
}
export function useMahndefinitionTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahndefinitionTemplateListQuery, MahndefinitionTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahndefinitionTemplateListQuery, MahndefinitionTemplateListQueryVariables>(MahndefinitionTemplateListDocument, options);
}
export function useMahndefinitionTemplateListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahndefinitionTemplateListQuery, MahndefinitionTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahndefinitionTemplateListQuery, MahndefinitionTemplateListQueryVariables>(
    MahndefinitionTemplateListDocument,
    options
  );
}
export type MahndefinitionTemplateListQueryHookResult = ReturnType<typeof useMahndefinitionTemplateListQuery>;
export type MahndefinitionTemplateListLazyQueryHookResult = ReturnType<typeof useMahndefinitionTemplateListLazyQuery>;
export type MahndefinitionTemplateListSuspenseQueryHookResult = ReturnType<typeof useMahndefinitionTemplateListSuspenseQuery>;
export type MahndefinitionTemplateListQueryResult = Apollo.QueryResult<MahndefinitionTemplateListQuery, MahndefinitionTemplateListQueryVariables>;
export const MahndefinitionTemplateSingleDocument = gql`
  query MahndefinitionTemplateSingle($mahndefinitionId: ID!) {
    getMahndefinitionTemplateSingle(mahndefinitionId: $mahndefinitionId) {
      data {
        ...MahndefinitionFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MahndefinitionFieldsFragmentDoc}
`;
export function useMahndefinitionTemplateSingleQuery(
  baseOptions: Apollo.QueryHookOptions<MahndefinitionTemplateSingleQuery, MahndefinitionTemplateSingleQueryVariables> &
    ({ variables: MahndefinitionTemplateSingleQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahndefinitionTemplateSingleQuery, MahndefinitionTemplateSingleQueryVariables>(
    MahndefinitionTemplateSingleDocument,
    options
  );
}
export function useMahndefinitionTemplateSingleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahndefinitionTemplateSingleQuery, MahndefinitionTemplateSingleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahndefinitionTemplateSingleQuery, MahndefinitionTemplateSingleQueryVariables>(
    MahndefinitionTemplateSingleDocument,
    options
  );
}
export function useMahndefinitionTemplateSingleSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahndefinitionTemplateSingleQuery, MahndefinitionTemplateSingleQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahndefinitionTemplateSingleQuery, MahndefinitionTemplateSingleQueryVariables>(
    MahndefinitionTemplateSingleDocument,
    options
  );
}
export type MahndefinitionTemplateSingleQueryHookResult = ReturnType<typeof useMahndefinitionTemplateSingleQuery>;
export type MahndefinitionTemplateSingleLazyQueryHookResult = ReturnType<typeof useMahndefinitionTemplateSingleLazyQuery>;
export type MahndefinitionTemplateSingleSuspenseQueryHookResult = ReturnType<typeof useMahndefinitionTemplateSingleSuspenseQuery>;
export type MahndefinitionTemplateSingleQueryResult = Apollo.QueryResult<
  MahndefinitionTemplateSingleQuery,
  MahndefinitionTemplateSingleQueryVariables
>;
export const MahndefinitionTemplateChangeHistoryListDocument = gql`
  query MahndefinitionTemplateChangeHistoryList($mahndefinitionId: ID!) {
    getMahndefinitionTemplateChangeHistoryList(mahndefinitionId: $mahndefinitionId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useMahndefinitionTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahndefinitionTemplateChangeHistoryListQuery, MahndefinitionTemplateChangeHistoryListQueryVariables> &
    ({ variables: MahndefinitionTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahndefinitionTemplateChangeHistoryListQuery, MahndefinitionTemplateChangeHistoryListQueryVariables>(
    MahndefinitionTemplateChangeHistoryListDocument,
    options
  );
}
export function useMahndefinitionTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahndefinitionTemplateChangeHistoryListQuery, MahndefinitionTemplateChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahndefinitionTemplateChangeHistoryListQuery, MahndefinitionTemplateChangeHistoryListQueryVariables>(
    MahndefinitionTemplateChangeHistoryListDocument,
    options
  );
}
export function useMahndefinitionTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MahndefinitionTemplateChangeHistoryListQuery, MahndefinitionTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahndefinitionTemplateChangeHistoryListQuery, MahndefinitionTemplateChangeHistoryListQueryVariables>(
    MahndefinitionTemplateChangeHistoryListDocument,
    options
  );
}
export type MahndefinitionTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useMahndefinitionTemplateChangeHistoryListQuery>;
export type MahndefinitionTemplateChangeHistoryListLazyQueryHookResult = ReturnType<typeof useMahndefinitionTemplateChangeHistoryListLazyQuery>;
export type MahndefinitionTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useMahndefinitionTemplateChangeHistoryListSuspenseQuery
>;
export type MahndefinitionTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  MahndefinitionTemplateChangeHistoryListQuery,
  MahndefinitionTemplateChangeHistoryListQueryVariables
>;
export const MahnstufeTemplateChangeHistoryListDocument = gql`
  query MahnstufeTemplateChangeHistoryList($mahndefinitionId: ID!, $mahnstufeIndex: Int!) {
    getMahnstufeTemplateChangeHistoryList(mahndefinitionId: $mahndefinitionId, mahnstufeIndex: $mahnstufeIndex) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useMahnstufeTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnstufeTemplateChangeHistoryListQuery, MahnstufeTemplateChangeHistoryListQueryVariables> &
    ({ variables: MahnstufeTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnstufeTemplateChangeHistoryListQuery, MahnstufeTemplateChangeHistoryListQueryVariables>(
    MahnstufeTemplateChangeHistoryListDocument,
    options
  );
}
export function useMahnstufeTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnstufeTemplateChangeHistoryListQuery, MahnstufeTemplateChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnstufeTemplateChangeHistoryListQuery, MahnstufeTemplateChangeHistoryListQueryVariables>(
    MahnstufeTemplateChangeHistoryListDocument,
    options
  );
}
export function useMahnstufeTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MahnstufeTemplateChangeHistoryListQuery, MahnstufeTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnstufeTemplateChangeHistoryListQuery, MahnstufeTemplateChangeHistoryListQueryVariables>(
    MahnstufeTemplateChangeHistoryListDocument,
    options
  );
}
export type MahnstufeTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useMahnstufeTemplateChangeHistoryListQuery>;
export type MahnstufeTemplateChangeHistoryListLazyQueryHookResult = ReturnType<typeof useMahnstufeTemplateChangeHistoryListLazyQuery>;
export type MahnstufeTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useMahnstufeTemplateChangeHistoryListSuspenseQuery>;
export type MahnstufeTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  MahnstufeTemplateChangeHistoryListQuery,
  MahnstufeTemplateChangeHistoryListQueryVariables
>;
export const MahnungTemplateTemplateChangeHistoryListDocument = gql`
  query MahnungTemplateTemplateChangeHistoryList($mahndefinitionId: ID!, $mahnstufeIndex: Int!) {
    getMahnungTemplateTemplateChangeHistoryList(mahndefinitionId: $mahndefinitionId, mahnstufeIndex: $mahnstufeIndex) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useMahnungTemplateTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnungTemplateTemplateChangeHistoryListQuery, MahnungTemplateTemplateChangeHistoryListQueryVariables> &
    ({ variables: MahnungTemplateTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungTemplateTemplateChangeHistoryListQuery, MahnungTemplateTemplateChangeHistoryListQueryVariables>(
    MahnungTemplateTemplateChangeHistoryListDocument,
    options
  );
}
export function useMahnungTemplateTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnungTemplateTemplateChangeHistoryListQuery, MahnungTemplateTemplateChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungTemplateTemplateChangeHistoryListQuery, MahnungTemplateTemplateChangeHistoryListQueryVariables>(
    MahnungTemplateTemplateChangeHistoryListDocument,
    options
  );
}
export function useMahnungTemplateTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MahnungTemplateTemplateChangeHistoryListQuery, MahnungTemplateTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungTemplateTemplateChangeHistoryListQuery, MahnungTemplateTemplateChangeHistoryListQueryVariables>(
    MahnungTemplateTemplateChangeHistoryListDocument,
    options
  );
}
export type MahnungTemplateTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useMahnungTemplateTemplateChangeHistoryListQuery>;
export type MahnungTemplateTemplateChangeHistoryListLazyQueryHookResult = ReturnType<typeof useMahnungTemplateTemplateChangeHistoryListLazyQuery>;
export type MahnungTemplateTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useMahnungTemplateTemplateChangeHistoryListSuspenseQuery
>;
export type MahnungTemplateTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  MahnungTemplateTemplateChangeHistoryListQuery,
  MahnungTemplateTemplateChangeHistoryListQueryVariables
>;
