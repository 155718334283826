import React, { FC } from 'react';
import { Checkbox } from 'formik-antd';
import { Checkbox as CheckboxAntd, Space } from 'antd';
import { CheckboxGroupProps } from 'formik-antd/src/checkbox';
import { useVertragsartListQuery } from '../../VertragsartSelect/gql/VertragsartQueries.types';
import { Vertragsart, Verwaltungsart } from '../../../types';

type Props = {
  verwaltungsart: Verwaltungsart;
  currentVertragsartList: Vertragsart[];
} & CheckboxGroupProps;

const VertragVertragsartFormCheckbox: FC<Props> = ({ verwaltungsart, currentVertragsartList, ...props }) => {
  const { data } = useVertragsartListQuery({ variables: { verwaltungsart } });

  const vertragsartList = data?.getVertragsartList.data ?? [];

  return (
    <Checkbox.Group {...props}>
      <Space direction="vertical">
        {vertragsartList.map((vertragsart) => (
          <CheckboxAntd
            key={vertragsart.value}
            value={vertragsart.value}
            disabled={currentVertragsartList.length <= 1 && currentVertragsartList.includes(vertragsart.value)}
          >
            {vertragsart.text}
          </CheckboxAntd>
        ))}
      </Space>
    </Checkbox.Group>
  );
};

export default VertragVertragsartFormCheckbox;
