import styled from 'styled-components';
import { Table as TableAntD } from 'antd';

export const StyledSmallTable = styled(TableAntD)`
  .ant-table-small {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-radius: 0 !important;
  }
` as any;
