export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute: [
      'AbstractAuftragVerbuchenGenerierlaufEntryAuftragDetails',
      'AbstractAuftragVerbuchenGenerierlaufEntrySingleInvoiceDetails',
    ],
    AbstractBeInfoFeld: ['BeInfoFeldKellerabteil', 'BeInfoFeldParkplatz', 'BeInfoFeldZimmer'],
    AbstractDeviationAttribute: [
      'DeviationAttributeAmount',
      'DeviationAttributeDate',
      'DeviationAttributeNumber',
      'DeviationAttributePercent',
      'DeviationAttributeString',
      'DeviationAttributeUriList',
    ],
    AbstractDeviationSourceEntity: [
      'AuftragDeviationSourceEntity',
      'BeVertragDeviationSourceEntity',
      'BuchungsanweisungDeviationSourceEntity',
      'HeAbrechnungHeVertragDeviationSourceEntity',
      'ObjektAbrechnungDeviationSourceEntity',
      'TopAbrechnungDeviationSourceEntity',
      'UstVomAufwandDeviationSourceEntity',
      'VorsteuerkuerzungDeviationSourceEntity',
    ],
    AbstractDeviationUriId: [
      'DeviationUriIdAuftrag',
      'DeviationUriIdBeNutzungsArt',
      'DeviationUriIdBeOptionSteuerpflicht',
      'DeviationUriIdBeVertrag',
      'DeviationUriIdBeVertragVorschreibungsposition',
      'DeviationUriIdBestandseinheit',
      'DeviationUriIdBkAbrechnungsdefinitionVersion',
      'DeviationUriIdEingangsrechnung',
      'DeviationUriIdHeAbrechnungHeVertragVersion',
      'DeviationUriIdHeAbrechnungsdefinitionVersion',
      'DeviationUriIdHeVertrag',
      'DeviationUriIdKontierungstabelle',
      'DeviationUriIdLeerstehungAufwandskonto',
      'DeviationUriIdPauschalmieteAufwandskonto',
      'DeviationUriIdRechtstraegerSteuerpflicht',
      'DeviationUriIdRepFondsEinstellung',
      'DeviationUriIdTopAbrechnung',
      'DeviationUriIdUstKategorie',
      'DeviationUriIdUstKategorieEntry',
      'DeviationUriIdUstRegelset',
      'DeviationUriIdUstVomAufwand',
      'DeviationUriIdVertragsart',
      'DeviationUriIdVertragspartner',
      'DeviationUriIdVorschreibungsposition',
      'DeviationUriIdVorschreibungspositionVersion',
      'DeviationUriIdVorsteuerkuerzung',
      'DeviationUriIdVorsteuerkuerzungEinstellung',
      'DeviationUriIdWeAbrechnungsdefinitionVersion',
      'DeviationUriIdZinsliste',
    ],
    AbstractHistoryAttribute: [
      'HistoryAmountAttribute',
      'HistoryBooleanAttribute',
      'HistoryDateAttribute',
      'HistoryFloatAttribute',
      'HistoryHtmlAttribute',
      'HistoryIntAttribute',
      'HistoryPercentAttribute',
      'HistoryStringAttribute',
      'HistoryStringListAttribute',
    ],
    AbstractObjektInfoFeld: [
      'ObjektInfoFeldAuflage',
      'ObjektInfoFeldGrundstuecksdaten',
      'ObjektInfoFeldHausbetreuung',
      'ObjektInfoFeldPerson',
      'ObjektInfoFeldVersicherung',
    ],
    AbstractRechtstraegerInfoFeld: ['RechtstraegerInfoFeldPersoneninfos'],
    AbstractVPosWertChangeReason: ['VPosWertChangeReasonBudgeting', 'VPosWertChangeReasonIndexedValue', 'VPosWertChangeReasonUst'],
    AbstractVertragInfoFeld: ['VertragInfoFeldKuendigungsinfos', 'VertragInfoFeldWeiterePerson'],
    AbstractVertragVposIndexationAgreement: [
      'VertragVposIndexationAgreementCategory',
      'VertragVposIndexationAgreementIndexAnnual',
      'VertragVposIndexationAgreementIndexThreshold',
      'VertragVposIndexationAgreementReferenceValue',
    ],
    IncomingInvoice: ['SingleInvoice', 'StandingInvoice'],
  },
};
export default result;
