import React, { FC } from 'react';
import { Modal } from 'antd';
import OperatingSiteForm from './Form/OperatingSiteForm';
import { TOperatingSiteList } from './List/OperatingSiteList';

type Props = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  firmendatenId?: string;
  firmaId?: string;
  operatingSite?: TOperatingSiteList;
  onSuccess: () => void;
};

const OperatingSiteModal: FC<Props> = ({ firmendatenId, firmaId, operatingSite, onSuccess, isModalOpen, toggleModalOpen }) => {
  const onSuccessAction = () => {
    onSuccess();
    toggleModalOpen();
  };
  const isUpdate = !!operatingSite;
  const modalTitle = `Betriebsstätte ${isUpdate ? 'bearbeiten' : 'anlegen'}`;

  return (
    <Modal title={modalTitle} open={isModalOpen} onCancel={toggleModalOpen} destroyOnClose footer={false}>
      <OperatingSiteForm
        firmendatenId={firmendatenId}
        firmaId={firmaId}
        operatingSite={operatingSite}
        onCancel={toggleModalOpen}
        onSuccess={onSuccessAction}
      />
    </Modal>
  );
};

export default OperatingSiteModal;
