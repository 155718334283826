import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import PositionSumme from './PositionSumme';
import HeABuchungszeile from '../../../hauseigentuemerAbrechnung/components/shared/HeABuchungszeile';
import { paddingLeftForRow } from '../../../hauseigentuemerAbrechnung/components/HeADetailsBlock/DetailsTable/details-table-styles';
import { SubAdminAbrEigentuemerverrechnungData } from '../subAdministrationAbrechnung-types';

const EigentuemerverrechnungBlock: FC<{ data: SubAdminAbrEigentuemerverrechnungData }> = ({ data }) => (
  <View style={[pdfStyles.column, { paddingTop: '5mm' }]}>
    <View style={pdfStyles.row}>
      <Text style={[pdfStyles.textNormal, { fontWeight: 'bold', textDecoration: 'underline' }]}>{data.positionName}</Text>
    </View>
    <View style={paddingLeftForRow.abrKreis}>
      <View style={[paddingLeftForRow.konto, { width: '85%' }]}>
        {data.kontoList.map((konto, index) => (
          <View key={index} style={pdfStyles.column}>
            <Text style={[pdfStyles.textNormal, { textDecoration: 'underline' }]}>{konto.kontoName}</Text>
            {konto.buchungszeileList?.map((buchungzeile, index) => <HeABuchungszeile key={index} buchungszeile={buchungzeile} />)}
          </View>
        ))}
      </View>
    </View>
    {/* Summe */}
    <PositionSumme data={data.summePosition} />
  </View>
);

export default EigentuemerverrechnungBlock;
