import React, { FC } from 'react';
import { Empty } from 'antd';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import vorschreibungVerbuchenVerarbeitungEntryTableColumns from './vorschreibungVerbuchenVerarbeitungEntryTableColumns';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import { ExpandedIcon } from './VorschreibungVerbuchenHelpers';
import { StyledTableVorschreibungVerbuchen } from './styled/VorschreibungVerbuchenTable.style';
import { AuftragVerbuchenGenerierlauf } from '../../../../../types';
import { TVorschreibungVerbuchenVerarbeitungQueryParams } from './Filters/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { useAuftragVerbuchenGenerierlaufEntryListQuery } from '../../../gql/AuftragGenerierlauf/AuftragVerbuchenGenerierlaufQueries.types';

type Props = {
  generierlauf: AuftragVerbuchenGenerierlauf;
  queryParams: TVorschreibungVerbuchenVerarbeitungQueryParams;
  isCardCollapsed: boolean;
};

const VorschreibungVerbuchenVerarbeitungEntryTable: FC<Props> = ({ generierlauf, queryParams, isCardCollapsed }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useAuftragVerbuchenGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId: generierlauf.generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getAuftragVerbuchenGenerierlaufEntryList.data.contentList || [];

  return (
    <StyledTableVorschreibungVerbuchen
      isCardCollapsed={isCardCollapsed}
      loading={loading}
      size="small"
      dataSource={entryList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={vorschreibungVerbuchenVerarbeitungEntryTableColumns(generierlauf)}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      rowKey={(record) => record.generierlaufEntryId}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
        expandIcon: ({ expanded, onExpand, record }) =>
          isVerarbeitungEntryTableRowExpandable(record) ? <ExpandedIcon expanded={expanded} onExpand={onExpand} record={record} /> : null,
      }}
      filterIdentifier="verarbeitung-vorschreibung-freigeben"
    />
  );
};

export default VorschreibungVerbuchenVerarbeitungEntryTable;
