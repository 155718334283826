import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { URI_MANAGEMENT_PAGE_SYSTEM_EMPLOYEE_DETAILS_PAGE } from '../../../constants/managementPageUriPaths';
import StatusBadge from '../../../components/Status/StatusBadge';
import MitarbeiterTooltip from '../../../components/Card/MitarbeiterTooltip';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterFragment } from '../gql/MitarbeiterFragments.types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';

// TODO: EmailVerified

const mitarbeiterTableColumns: TableWithColSelectorColumnProps<MitarbeiterFragment>[] = [
  {
    title: 'Vorname',
    defaultSelected: true,
    key: 'firstname',
    render: (text, record) => <span>{record.firstname ? record.firstname : 'nicht angegeben'}</span>,
  },
  {
    title: 'Nachname',
    defaultSelected: true,
    key: 'lastname',
    render: (text, record) => <span>{record.lastname ? record.lastname : 'nicht angegeben'}</span>,
  },
  {
    title: 'Benutzername',
    defaultSelected: true,
    key: 'username',
    render: (text, record) => (
      <Link
        key={record.mitarbeiterId}
        to={generatePath(URI_MANAGEMENT_PAGE_SYSTEM_EMPLOYEE_DETAILS_PAGE, {
          firmendatenId: record.firmendatenId,
          mitarbeiterId: record.mitarbeiterId,
        })}
      >
        {record.user?.email}
      </Link>
    ),
  },
  {
    title: 'E-Mail-Adresse',
    defaultSelected: true,
    key: 'email',
    render: (text, record) => <span>{record.user?.email}</span>,
  },
  {
    title: 'Status',
    defaultSelected: true,
    key: 'status',
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
];

export default mitarbeiterTableColumns;
