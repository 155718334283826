import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { ObjAbrBuchungszeile } from '../../../../../../types';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount, Percent } from '../../../../../../components/Number';
import SollHabenTag from '../../../../../shared/SollHabenTag';
import { NestedTableWithColSelectorColProps } from '../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { generatePathToBookingDetailsPage } from '../../../../../BookingDetails/bookingDetailsPaths';

const objektAbrechnungUstVomAufwandBuchungszeileTableColumns: NestedTableWithColSelectorColProps<ObjAbrBuchungszeile>[] = [
  {
    title: 'Text',
    width: 200,
    key: 'abrechnungskreis',
    render: (text, record) => <DataWithShortenedText text={record.text} maxTextLength={25} />,
  },
  {
    title: '',
    width: 100,
  },
  {
    title: '',
    width: 100,
  },
  {
    title: '',
    width: 100,
  },
  {
    title: 'Ust. vom Aufwand %',
    defaultSelected: true,
    width: 200,
    align: 'right',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    render: (text, record) => <Percent value={record.ustVomAufwandSteuersatz! / 100} />,
  },
  {
    title: 'Ausgaben',
    key: 'ausgaben',
    align: 'right',
    width: 180,
    render: (text, record: ObjAbrBuchungszeile) => (
      <Space size={0}>
        <SollHabenTag sollHaben={record.sollHaben} />
        <div style={{ width: '100px', display: 'inline-flex', justifyContent: 'end' }}>
          <Link target="_blank" to={generatePathToBookingDetailsPage(record.buchungIdList)}>
            <EuroAmount value={record.betrag} />
          </Link>
        </div>
      </Space>
    ),
  },
  {
    title: '',
    key: 'einnahmen',
    width: 180,
  },
  {
    title: '',
    key: 'saldo',
    width: 120,
  },
];

export default objektAbrechnungUstVomAufwandBuchungszeileTableColumns;
