import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EingangsRechnungVerrechnungsartListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type EingangsRechnungVerrechnungsartListQuery = {
  getEingangsRechnungVerrechnungsartList: {
    data: Array<{ text: string; value: Types.Verrechnungsart }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const EingangsRechnungVerrechnungsartListDocument = gql`
  query EingangsRechnungVerrechnungsartList {
    getEingangsRechnungVerrechnungsartList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useEingangsRechnungVerrechnungsartListQuery(
  baseOptions?: Apollo.QueryHookOptions<EingangsRechnungVerrechnungsartListQuery, EingangsRechnungVerrechnungsartListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EingangsRechnungVerrechnungsartListQuery, EingangsRechnungVerrechnungsartListQueryVariables>(
    EingangsRechnungVerrechnungsartListDocument,
    options
  );
}
export function useEingangsRechnungVerrechnungsartListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EingangsRechnungVerrechnungsartListQuery, EingangsRechnungVerrechnungsartListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EingangsRechnungVerrechnungsartListQuery, EingangsRechnungVerrechnungsartListQueryVariables>(
    EingangsRechnungVerrechnungsartListDocument,
    options
  );
}
export function useEingangsRechnungVerrechnungsartListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<EingangsRechnungVerrechnungsartListQuery, EingangsRechnungVerrechnungsartListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EingangsRechnungVerrechnungsartListQuery, EingangsRechnungVerrechnungsartListQueryVariables>(
    EingangsRechnungVerrechnungsartListDocument,
    options
  );
}
export type EingangsRechnungVerrechnungsartListQueryHookResult = ReturnType<typeof useEingangsRechnungVerrechnungsartListQuery>;
export type EingangsRechnungVerrechnungsartListLazyQueryHookResult = ReturnType<typeof useEingangsRechnungVerrechnungsartListLazyQuery>;
export type EingangsRechnungVerrechnungsartListSuspenseQueryHookResult = ReturnType<typeof useEingangsRechnungVerrechnungsartListSuspenseQuery>;
export type EingangsRechnungVerrechnungsartListQueryResult = Apollo.QueryResult<
  EingangsRechnungVerrechnungsartListQuery,
  EingangsRechnungVerrechnungsartListQueryVariables
>;
