import React, { FC } from 'react';
import { FormikProps } from 'formik';
import FiltersWith4OrMoreFields from '../../../../components/Filters/FiltersWith4OrMoreFields';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import ObjektVerwaltungsArtSelect from '../../../Objekt/VerwaltungsArt/ObjektVerwaltungsArtSelect';
import { listingFiltersFormFields, ListingFiltersFormValues } from './listingFiltersFormMapper';
import VorschreibungspositionSelect from '../../../VorschreibungspositionSelect/VorschreibungspositionSelect';
import AufteilungsschluesselSelect from '../../../AufteilungsschluesselSelect/AufteilungsschluesselSelect';
import LinkageDrawerAndButtons from '../../LinkageDrawerAndButtons';
import { BudgetingVPosAuftschluesselLinkage, VorschreibungspositionArt } from '../../../../types';
import VertragLevelSelect from '../../../VertragLevelSelect/gql/VertragLevelSelect';

type Props = {
  formikProps: FormikProps<ListingFiltersFormValues>;
  linkageEntryList: BudgetingVPosAuftschluesselLinkage[];
  onAction: () => void;
};

const ListingFilters: FC<Props> = ({ onAction, linkageEntryList, formikProps }) => {
  return (
    <FiltersWith4OrMoreFields
      actionButtons={<LinkageDrawerAndButtons onAction={onAction} linkageEntryList={linkageEntryList} />}
      leftColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.objektVerwaltungsart} label="Verwaltungsart">
            <ObjektVerwaltungsArtSelect allowClear name={listingFiltersFormFields.objektVerwaltungsart} />
          </FormItemWithoutColon>

          <FormItemWithoutColon name={listingFiltersFormFields.vorschreibungspositionId} label="Vorschreibungposition">
            <VorschreibungspositionSelect
              allowClear
              name={listingFiltersFormFields.vorschreibungspositionId}
              includeFromFirmendaten
              exludeArts={[VorschreibungspositionArt.Miete]}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.vertragLevel} label="Vertragsebene">
            <VertragLevelSelect
              allowClear
              name={listingFiltersFormFields.vertragLevel}
              verwaltungsart={formikProps.values.objektVerwaltungsart ?? undefined}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.aufteilungsschluesselId} label="Aufteilingsschlüssel">
            <AufteilungsschluesselSelect allowClear name={listingFiltersFormFields.aufteilungsschluesselId} includeFromFirmendaten />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
