import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useVertragspartnerKontoListQuery } from './gql/VertragspartnerKontoQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';

type Props = {
  name: string;
  buchungskreisId?: string | null;
} & SelectProps;

const VertragspartnerKontoFormSelect: FC<Props> = ({ name, buchungskreisId, ...restProps }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data, loading } = useVertragspartnerKontoListQuery({ variables: { buchungskreisId: buchungskreisId! }, skip: !buchungskreisId });

  const vertragspartnerKontoList = data?.getVertragspartnerKontoList.data || [];

  return (
    <Select
      name={name}
      id={name}
      loading={loading}
      placeholder="Vertragspartner auswählen"
      allowClear
      disabled={!buchungskreisId}
      showSearch
      filterOption={selectFilterOption}
      {...restProps}
    >
      {vertragspartnerKontoList.map((rechtstrager) => (
        <Select.Option key={rechtstrager.rechtstraegerId} value={rechtstrager.rechtstraegerId}>
          {rechtstrager.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default VertragspartnerKontoFormSelect;
