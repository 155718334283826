import { DataCarrierPainStatus, DataCarrierType, PaymentTransactionType } from '../../../../../types';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { TDirectDebitOrBankWireQueryParams } from './filtersQueryParams';
import { ObjectOfStrings } from '../../../../../shared/typeHelpers';

export type FiltersFormValues = {
  dataCarrierType?: DataCarrierType | null;
  name?: string | null;
  paymentProposalName?: string | null;
  paymentTransactionType?: PaymentTransactionType | null;
  iban?: string | null;
  createTsFrom?: string | null;
  createTsTo?: string | null;
  createdByMitarbeiterIdList?: string[] | null;
  status?: DataCarrierPainStatus | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  dataCarrierType: 'dataCarrierType',
  name: 'name',
  paymentProposalName: 'paymentProposalName',
  paymentTransactionType: 'paymentTransactionType',
  iban: 'iban',
  createTsFrom: 'createTsFrom',
  createTsTo: 'createTsTo',
  createdByMitarbeiterIdList: 'createdByMitarbeiterIdList',
  status: 'status',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TDirectDebitOrBankWireQueryParams => ({
  dataCarrierType: formValues.dataCarrierType,
  name: formValues.name,
  paymentProposalName: formValues.paymentProposalName,
  paymentTransactionType: formValues.paymentTransactionType,
  iban: formValues.iban,
  createTsFrom: formValues.createTsFrom ? mapFormDateValueToDateString(formValues.createTsFrom) : null,
  createTsTo: formValues.createTsTo ? mapFormDateValueToDateString(formValues.createTsTo) : null,
  createdByMitarbeiterIdList: formValues.createdByMitarbeiterIdList,
  status: formValues.status,
});

export const mapQueryParamsToFormValues = (queryParams: TDirectDebitOrBankWireQueryParams): FiltersFormValues => ({
  dataCarrierType: queryParams.dataCarrierType,
  name: queryParams.name,
  paymentProposalName: queryParams.paymentProposalName,
  paymentTransactionType: queryParams.paymentTransactionType,
  iban: queryParams.iban,
  createTsFrom: queryParams.createTsFrom,
  createTsTo: queryParams.createTsTo,
  createdByMitarbeiterIdList: queryParams.createdByMitarbeiterIdList,
  status: queryParams.status,
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'createTsTo'>> = {
  [filtersFormFields.dataCarrierType]: 'Datenträgertyp',
  [filtersFormFields.name]: 'Bezeichnung (des Datenträgers)',
  [filtersFormFields.paymentProposalName]: 'Zahlungsvorschlag',
  [filtersFormFields.paymentTransactionType]: 'Zahlungsverkehrsart',
  [filtersFormFields.iban]: 'IBAN',
  [filtersFormFields.createTsFrom]: 'Erstellt am',
  [filtersFormFields.createdByMitarbeiterIdList]: 'Ersteller',
  [filtersFormFields.status]: 'Status',
};
