import { useState, useEffect, useRef, RefObject, useCallback } from 'react';

const useResize = <T extends HTMLElement>(): {
  ref: RefObject<T>;
  entry: ResizeObserverEntry | null;
  disconnect: () => void;
} => {
  const ref = useRef<T>(null);
  const observerRef = useRef<ResizeObserver | null>(null);
  const [entry, setEntry] = useState<ResizeObserverEntry | null>(null);

  const disconnect = useCallback(() => {
    if (!observerRef.current || !ref.current) return;
    observerRef.current.unobserve(ref.current);
    observerRef.current.disconnect();
  }, []);

  useEffect(() => {
    const observe = () => {
      if (!observerRef.current) {
        observerRef.current = new ResizeObserver((entries) => {
          if (!entries.length) return;
          setEntry(entries[0]);
        });
      }

      if (!ref.current) return;
      observerRef.current.observe(ref.current);
    };

    observe();

    return () => {
      disconnect();
    };
  }, [disconnect]);

  return {
    ref,
    entry,
    disconnect,
  };
};

export default useResize;
