import { Col, Space, Typography } from 'antd';
import React, { FC } from 'react';
import { BankOutlined } from '@ant-design/icons';
import { friendlyFormatIBAN } from 'ibantools';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { StyledObjBankDetailsCard, StyledObjBankDetailsRow } from './styled/ObjBankDetailsListItem.style';
import ObjektBankDetailsListItemActions from './ObjektBankDetailsListItemActions';
import { ObjektBankDetails, ObjektBankDetailsVerwendung } from '../../../../types';
import EntityWarningList from '../../../../components/message/EntityWarningList';
import theme from '../../../../theme';

type Props = {
  objektBankDetails: ObjektBankDetails;
  objektId: string;
  onAction: () => void;
  verwendungListTypeInUse: ObjektBankDetailsVerwendung[];
  rechtstraegerId: string;
  weRechnungsAusstellerId?: string;
  rechtstraegerFibuKontoCreatable?: boolean;
  weRechnungsAusstellerFibuKontoCreatable?: boolean;
};

const ObjektBankDetailsListItem: FC<Props> = ({
  objektBankDetails,
  objektId,
  rechtstraegerId,
  onAction,
  verwendungListTypeInUse,
  weRechnungsAusstellerId,
  rechtstraegerFibuKontoCreatable,
  weRechnungsAusstellerFibuKontoCreatable,
}) => {
  const { bankDetails } = objektBankDetails;

  return (
    <StyledObjBankDetailsCard>
      <StyledObjBankDetailsRow>
        <Col style={{ background: theme.colors.grey, alignItems: 'baseline' }}>
          <BankOutlined />
        </Col>
        <Col>
          <Space direction="vertical" size={0}>
            <DataWithShortenedTextAndExtraInfo text={objektBankDetails.bezeichnung} maxTextLength={24}>
              {(shortenedText) => (
                <Space>
                  <Typography.Title level={5}>{shortenedText}</Typography.Title>
                  <EntityWarningList warningList={objektBankDetails.warningList} />
                </Space>
              )}
            </DataWithShortenedTextAndExtraInfo>
            <DataWithShortenedTextAndExtraInfo maxTextLength={18} text={`${friendlyFormatIBAN(bankDetails.iban)}`}>
              {(shortenedText) => (
                <Space>
                  <Typography.Text type="secondary">{bankDetails.accountHolder}</Typography.Text>
                  <Typography.Text type="secondary">{shortenedText}</Typography.Text>
                </Space>
              )}
            </DataWithShortenedTextAndExtraInfo>
          </Space>
        </Col>
        <Col>
          <ObjektBankDetailsListItemActions
            objektBankDetails={objektBankDetails}
            objektId={objektId}
            rechtstraegerId={rechtstraegerId}
            weRechnungsAusstellerId={weRechnungsAusstellerId}
            onAction={onAction}
            verwendungListTypeInUse={verwendungListTypeInUse}
            rechtstraegerFibuKontoCreatable={rechtstraegerFibuKontoCreatable}
            weRechnungsAusstellerFibuKontoCreatable={weRechnungsAusstellerFibuKontoCreatable}
          />
        </Col>
      </StyledObjBankDetailsRow>
    </StyledObjBankDetailsCard>
  );
};

export default ObjektBankDetailsListItem;
