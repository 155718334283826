import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentBelegSymbolListQueryVariables = Types.Exact<{
  includeStorno?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type PaymentBelegSymbolListQuery = {
  getPaymentBelegSymbolList: {
    data: Array<{ abbreviation: string; text: string; value: Types.PaymentBelegSymbol }>;
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const PaymentBelegSymbolListDocument = gql`
  query PaymentBelegSymbolList($includeStorno: Boolean) {
    getPaymentBelegSymbolList(includeStorno: $includeStorno) {
      data {
        abbreviation
        text
        value
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function usePaymentBelegSymbolListQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentBelegSymbolListQuery, PaymentBelegSymbolListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentBelegSymbolListQuery, PaymentBelegSymbolListQueryVariables>(PaymentBelegSymbolListDocument, options);
}
export function usePaymentBelegSymbolListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentBelegSymbolListQuery, PaymentBelegSymbolListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentBelegSymbolListQuery, PaymentBelegSymbolListQueryVariables>(PaymentBelegSymbolListDocument, options);
}
export function usePaymentBelegSymbolListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentBelegSymbolListQuery, PaymentBelegSymbolListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentBelegSymbolListQuery, PaymentBelegSymbolListQueryVariables>(PaymentBelegSymbolListDocument, options);
}
export type PaymentBelegSymbolListQueryHookResult = ReturnType<typeof usePaymentBelegSymbolListQuery>;
export type PaymentBelegSymbolListLazyQueryHookResult = ReturnType<typeof usePaymentBelegSymbolListLazyQuery>;
export type PaymentBelegSymbolListSuspenseQueryHookResult = ReturnType<typeof usePaymentBelegSymbolListSuspenseQuery>;
export type PaymentBelegSymbolListQueryResult = Apollo.QueryResult<PaymentBelegSymbolListQuery, PaymentBelegSymbolListQueryVariables>;
