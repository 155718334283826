import { Dayjs } from 'dayjs';
import { Period, PeriodUnit, TicketBase, TicketStatus } from '../../types';
import { mapFormDateValueToDateString } from '../../helpers/formikHelper';
import { dayjsCustom } from '../../helpers/dayjsCustom';
import { TicketListQueryVariables, TicketQuery } from './gql/TicketQueries.types';

export const convertPeriodToDateString = (
  period?: Period | null,
  initialDate?: string | number | Dayjs | Date | null | undefined,
  operation: 'add' | 'subtract' = 'add'
) => {
  if (!period) return '';
  const { amount, unit } = period;
  if (amount === null || unit === null) return '';
  const adjustedAmount = operation === 'add' ? amount : -1 * amount;

  switch (unit.value) {
    case PeriodUnit.Years:
      return mapFormDateValueToDateString(dayjsCustom(initialDate).add(adjustedAmount, 'year'));
    case PeriodUnit.Months:
      return mapFormDateValueToDateString(dayjsCustom(initialDate).add(adjustedAmount, 'months'));
    case PeriodUnit.Weeks:
      return mapFormDateValueToDateString(dayjsCustom(initialDate).add(adjustedAmount, 'weeks'));
    case PeriodUnit.Days:
      return mapFormDateValueToDateString(dayjsCustom(initialDate).add(adjustedAmount, 'days'));
    default:
      return '';
  }
};

export const getSubTicketById = (subTicketList: TicketBase[], ticketId: string) => subTicketList.find((subTicket) => subTicket.ticketId === ticketId);
export const isTicketArchived = (ticket: TicketBase) => ticket.ticketStatus.value === TicketStatus.Archived;
export const isTicketParerentArchived = (ticket: TicketBase) => (ticket.parent ? isTicketArchived(ticket.parent) : false);

export type TTicket = TicketQuery['getTicket']['data'];

export type TicketAttachedEmail = {
  sender?: string;
  sentTs?: string;
  subject?: string;
};

export const extendStatusListWithArchived = (status: TicketListQueryVariables['status'], showArchived?: boolean) => {
  let statusVariable = Array.isArray(status) ? [...status] : [status].filter((status) => !!status);
  if (showArchived && !statusVariable.includes(TicketStatus.Archived)) {
    statusVariable = statusVariable.length ? [...statusVariable, TicketStatus.Archived] : Object.values(TicketStatus);
  }
  return statusVariable;
};
