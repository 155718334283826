export enum HTTP_STATUS_CODE {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  SERVICE_UNAVAILABLE = 503,
}

export function processResponse(response: Response) {
  return new Promise((resolve, reject) => {
    const func = mapStatusCodeToResolveOrRejectFunction(response.status, resolve, reject);
    response
      .json()
      .then((data: any) => func({ status: response.status, statusText: response.statusText, data }))
      .catch(() => func({ status: response.status, statusText: response.statusText }));
  });
}

export const processResponseWithoutBody = (response: { status: number; statusText: any }) =>
  new Promise((resolve, reject) => {
    const func = mapStatusCodeToResolveOrRejectFunction(response.status, resolve, reject);
    func({ status: response.status, statusText: response.statusText });
  });

const mapStatusCodeToResolveOrRejectFunction = (
  statusCode: number,
  resolve: { (value?: unknown): void; (value?: unknown): void },
  reject: { (reason?: any): void; (reason?: any): void }
) => (statusCode < 400 ? resolve : reject);

// TODO: clean up: use errorStatus: contants instead of booleans
export const processAndReturnError = (response: { status: number; data: { errorList: any }; statusText: any }) =>
  new Promise((_, reject) => {
    if (response.status === HTTP_STATUS_CODE.BAD_REQUEST) {
      reject({ error: true, validationError: true, errorList: response.data.errorList });
    }
    if (response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
      reject({ error: true, unauthorizedError: true, validationError: false, message: response.statusText });
    }
    if (response.status === HTTP_STATUS_CODE.NOT_FOUND) {
      reject({ error: true, notFound: true, validationError: false, message: response.statusText });
    }
    if (response.status === HTTP_STATUS_CODE.SERVICE_UNAVAILABLE) {
      reject({ error: true, serviceUnavailable: true, validationError: false, message: response.statusText });
    }
    reject({ error: true, validationError: false, message: response.statusText, details: response });
  });

export const returnData = (response: any) => response.data.data;

export const returnDataAndWarnings = (response: { data: { data: any; warningList: any } }) => ({
  data: response.data.data,
  warningList: response.data.warningList,
});
