import React, { FC } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ContactPerson } from '../../../../types';

type Props = {
  contactPersonList: ContactPerson[];
  isVisible: boolean;
} & ButtonProps;

const AdditionalContactPersonButton: FC<Props> = ({ contactPersonList, isVisible, ...props }) =>
  isVisible ? (
    <Button icon={<PlusOutlined />} type="dashed" htmlType="button" block onClick={props.onClick}>
      {contactPersonList && contactPersonList.length > 0 ? 'Weiteren Ansprechpartner hinzufügen' : 'Ansprechpartner hinzufügen'}
    </Button>
  ) : null;

export default AdditionalContactPersonButton;
