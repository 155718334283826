import React, { FC } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form, Input, Radio } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import {
  mapFormValuesToZahlungsregel,
  mapZahlungsregelToFormValues,
  zahlungsregelFormFields,
  ZahlungsregelFormValues,
} from './zahlungsregelFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { HeVertragZahlungsregel, HeVertragZahlungsregelType } from '../../../../../types';
import RechtstraegerSelect from '../../../../../shared/Rechtstraeger/RechtstraegerSelect';
import RechtstraegerBankDetailsSelect from '../../../../BankDetails/Select/RechtstraegerBankDetailsSelect';
import FormButtons from '../../../../../components/Button/FormButtons';
import ZahlungsregelPeriodeSelect from './ZahlungsregelPeriodeSelect';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../components/DatePicker/DatePicker_formikAntD';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useCreateHeVertragZahlungsregelMutation, useUpdateHeVertragZahlungsregelMutation } from '../gql/HeVertragZahlungsregelMutations.types';
import { zahlungsregelFormValidationSchema } from './zahlungsregelFormValidationSchema';
import NextPaymentText from './NextPaymentText';
import { gqlOperations } from '../../../../../graphql/gqlNamedOperations-generated';
import {
  isZahlungsregelTypeFixbetrag,
  isZahlungsregelTypeProzentsatzGesamtVs,
  isZahlungsregelTypeProzentsatzHeAbrechnung,
} from './zahlungsregelHelpers';
import FixbetragFormItem from './FormItem/FixbetragFormItem';
import ProzentsatzHeAbrechnungFormItem from './FormItem/ProzentsatzHeAbrechnungFormItem';
import ProzenzsatzGesamtVSFormItem from './FormItem/ProzenzsatzGesamtVSFormItem';
import { HeAbrechnungsdefinitionFieldsFragment } from '../../../../Abrechnungsdefinition/gql/HeAbrDef/HeAbrDefFragments.types';

type Props = {
  objektId: string;
  heVertragId: string;
  onSuccess: () => void;
  onCancel: () => void;
  zahlungsregel?: HeVertragZahlungsregel;
  vertragspartnerId?: string;
  heAbrechnungsdefinition?: HeAbrechnungsdefinitionFieldsFragment | null;
};

const ZahlungsregelForm: FC<Props> = ({ objektId, heVertragId, onSuccess, onCancel, zahlungsregel, vertragspartnerId, heAbrechnungsdefinition }) => {
  const isUpdate = !!zahlungsregel;

  const entity = 'Zahlungsregel';

  const [runCreate] = useCreateHeVertragZahlungsregelMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    refetchQueries: () => [gqlOperations.Query.Zahlungsverlauf],
  });

  const [runUpdate] = useUpdateHeVertragZahlungsregelMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    refetchQueries: () => [gqlOperations.Query.Zahlungsverlauf],
  });

  return (
    <Formik<ZahlungsregelFormValues>
      initialValues={mapZahlungsregelToFormValues(zahlungsregel, vertragspartnerId)}
      validationSchema={zahlungsregelFormValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        const input = mapFormValuesToZahlungsregel(formValues);
        if (isUpdate) {
          runUpdate({
            variables: {
              objektId,
              heVertragId,
              zahlungsregelId: zahlungsregel.zahlungsregelId,
              input,
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({
            variables: {
              objektId,
              heVertragId,
              input,
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          {!isUpdate && (
            <Row align="top">
              <Col span={6}>
                <Typography.Title level={5}>Regeltyp</Typography.Title>
              </Col>
              <Col span={18}>
                <FormItemWithoutColon name={zahlungsregelFormFields.type}>
                  <Radio.Group
                    name={zahlungsregelFormFields.type}
                    onChange={(evt) => {
                      if (isZahlungsregelTypeFixbetrag(evt.target.value)) {
                        formikProps.setFieldValue(zahlungsregelFormFields.prozentsatz, null);
                        formikProps.setFieldValue(zahlungsregelFormFields.turnus, null);
                      } else if (isZahlungsregelTypeProzentsatzGesamtVs(evt.target.value)) {
                        formikProps.setFieldValue(zahlungsregelFormFields.fixbetrag, null);
                        formikProps.setFieldValue(zahlungsregelFormFields.prozentsatz, null);
                        formikProps.setFieldValue(zahlungsregelFormFields.turnus, null);
                      } else if (isZahlungsregelTypeProzentsatzHeAbrechnung(evt.target.value)) {
                        formikProps.setFieldValue(zahlungsregelFormFields.fixbetrag, null);
                        formikProps.setFieldValue(zahlungsregelFormFields.prozentsatz, null);
                      }
                    }}
                  >
                    <Radio.Button value={HeVertragZahlungsregelType.Fixbetrag}>Fixbetrag</Radio.Button>
                    <Radio.Button value={HeVertragZahlungsregelType.ProzentsatzGesamtVs}>Prozentsatz Gesamt-VS</Radio.Button>
                    <Radio.Button value={HeVertragZahlungsregelType.ProzentsatzHeAbrechnung}>Prozentsatz der HE-Abrechnung</Radio.Button>
                  </Radio.Group>
                </FormItemWithoutColon>
              </Col>
            </Row>
          )}
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Allgemein</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithoutColon name={zahlungsregelFormFields.bezeichnung} label="Bezeichnung">
                <Input
                  id={zahlungsregelFormFields.bezeichnung}
                  name={zahlungsregelFormFields.bezeichnung}
                  size="small"
                  placeholder="Bezeichnung eingeben"
                />
              </FormItemWithoutColon>
            </Col>
          </Row>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Empfänger</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithoutColon name={zahlungsregelFormFields.zahlungsempfaengerId} label="Empfänger">
                <RechtstraegerSelect
                  name={zahlungsregelFormFields.zahlungsempfaengerId}
                  onNewItemCreated={(rechtstraegerId) => formikProps.setFieldValue(zahlungsregelFormFields.zahlungsempfaengerId, rechtstraegerId)}
                  onNewItemCreateSelected={() => formikProps.setFieldValue(zahlungsregelFormFields.zahlungsempfaengerId, '')}
                  onChange={() => formikProps.setFieldValue(zahlungsregelFormFields.zahlungsempfaengerBankDetailsId, undefined)}
                  placeholder="Empfänger auswählen"
                />
              </FormItemWithoutColon>
              <FormItemWithoutColon name={zahlungsregelFormFields.zahlungsempfaengerBankDetailsId} label="Bankverbindung">
                <RechtstraegerBankDetailsSelect
                  name={zahlungsregelFormFields.zahlungsempfaengerBankDetailsId}
                  rechtstraegerId={formikProps.values.zahlungsempfaengerId}
                  onHasOnlyOneBankDetails={(id) => {
                    !zahlungsregel && formikProps.setFieldValue(zahlungsregelFormFields.zahlungsempfaengerBankDetailsId, id);
                  }}
                  onNewItemCreateSelected={() => formikProps.setFieldValue(zahlungsregelFormFields.zahlungsempfaengerBankDetailsId, '')}
                />
              </FormItemWithoutColon>
            </Col>
          </Row>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Durchführung</Typography.Title>
            </Col>
            <Col span={18}>
              <Row gutter={8} style={{ marginBottom: '8px' }}>
                <Col span={12}>
                  <FormItemWithoutColon name={zahlungsregelFormFields.periode} label="Durchführen am">
                    <ZahlungsregelPeriodeSelect name={zahlungsregelFormFields.periode} size="small" placeholder="Zahlungsintervall" />
                  </FormItemWithoutColon>
                </Col>
                <Col span={12}>
                  <FormItemWithoutColon name={zahlungsregelFormFields.durchfuehrenAm} label="Jeweils am (Tag des Monats)">
                    <FormattedDecimalFormInput
                      name={zahlungsregelFormFields.durchfuehrenAm}
                      min={1}
                      max={31}
                      style={{ width: '100%' }}
                      size="small"
                      placeholder="x-ten des Monats"
                      fractionDigits={0}
                    />
                  </FormItemWithoutColon>
                </Col>
                <NextPaymentText
                  periode={formikProps.values.periode}
                  durchfuehrenAm={formikProps.values.durchfuehrenAm}
                  beendetAm={formikProps.values.beendetAm}
                />
              </Row>
              <FormItemWithoutColon name={zahlungsregelFormFields.beendetAm} label="Beendet am">
                <DatePicker
                  size="small"
                  id={zahlungsregelFormFields.beendetAm}
                  name={zahlungsregelFormFields.beendetAm}
                  style={{ width: '100%' }}
                  format={DATE_DISPLAY_FORMAT_DEFAULT}
                  placeholder="Datum auswählen"
                />
              </FormItemWithoutColon>
              <FormItemWithoutColon name={zahlungsregelFormFields.verwendungszweck} label="Verwendungszweck">
                <Input
                  id={zahlungsregelFormFields.verwendungszweck}
                  name={zahlungsregelFormFields.verwendungszweck}
                  size="small"
                  placeholder="Verwendungszweck eingeben"
                  disabled={!!formikProps.values.zahlungsreferenz}
                />
              </FormItemWithoutColon>
              <FormItemWithoutColon name={zahlungsregelFormFields.zahlungsreferenz} label="Zahlungsreferenz">
                <Input
                  id={zahlungsregelFormFields.zahlungsreferenz}
                  name={zahlungsregelFormFields.zahlungsreferenz}
                  size="small"
                  placeholder="Zahlungsreferenz eingeben"
                  disabled={!!formikProps.values.verwendungszweck}
                />
              </FormItemWithoutColon>
              {isZahlungsregelTypeFixbetrag(formikProps.values.type) && <FixbetragFormItem />}
              {isZahlungsregelTypeProzentsatzHeAbrechnung(formikProps.values.type) && (
                <ProzentsatzHeAbrechnungFormItem heAbrechnungsdefinition={heAbrechnungsdefinition} />
              )}
              {isZahlungsregelTypeProzentsatzGesamtVs(formikProps.values.type) && (
                <ProzenzsatzGesamtVSFormItem formikProps={formikProps} objektId={objektId} />
              )}
            </Col>
          </Row>
          <FormButtons updateMode={isUpdate} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default ZahlungsregelForm;
