import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from 'rebass';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { generatePathToAbrechnungsdefinitionVersionUpdatePage, generatePathToAbrechnungsdefinitionVersionViewPage } from '../../abrDefUriPaths';
import { AbrechnungsdefinitionVersionListData } from '../../shared/abrDefTypes';

type AbrDefVersionTimelineCardExtraProps = {
  version: AbrechnungsdefinitionVersionListData;
  abrechnungsdefinitionId: string;
  onDelete?: (version: AbrechnungsdefinitionVersionListData) => Promise<unknown>;
  isDeleteAllowed?: boolean;
  isHistoricized?: boolean;
};

const AbrDefVersionTimelineCardExtra: FC<AbrDefVersionTimelineCardExtraProps> = ({
  version,
  abrechnungsdefinitionId,
  isDeleteAllowed,
  onDelete,
  isHistoricized,
}) => {
  const navigate = useNavigate();

  return (
    <Box>
      {!isHistoricized && (
        <>
          <Tooltip title="Bearbeiten" placement="topRight">
            <Button
              shape="circle"
              type="text"
              icon={<EditOutlined />}
              onClick={() => {
                navigate(generatePathToAbrechnungsdefinitionVersionUpdatePage(abrechnungsdefinitionId, version.abrechnungsdefinitionVersionId));
              }}
              size="small"
            />
          </Tooltip>
          {onDelete && <AbrDefVersionTimelineDeleteButton version={version} isDeleteAllowed={isDeleteAllowed} onDelete={onDelete} />}
        </>
      )}

      <Tooltip title="Anschauen">
        <Button
          shape="circle"
          type="text"
          icon={<EyeOutlined />}
          size="small"
          onClick={() => {
            navigate(
              generatePathToAbrechnungsdefinitionVersionViewPage(abrechnungsdefinitionId, version.abrechnungsdefinitionVersionId, !!isHistoricized)
            );
          }}
        />
      </Tooltip>
    </Box>
  );
};

type AbrDefVersionTimelineDeleteButtonProps = {
  version: AbrechnungsdefinitionVersionListData;
  isDeleteAllowed?: boolean;
  onDelete: (version: AbrechnungsdefinitionVersionListData) => Promise<unknown>;
};

const AbrDefVersionTimelineDeleteButton: FC<AbrDefVersionTimelineDeleteButtonProps> = ({ version, isDeleteAllowed, onDelete }) => {
  const isDeletionDisabled = !version.deletable || !isDeleteAllowed;

  return (
    <Popconfirm
      disabled={isDeletionDisabled}
      onConfirm={() => onDelete(version)}
      placement="rightBottom"
      title="Möchten Sie diesen Zeitblock löschen?"
      okText="Ja"
      cancelText="Nein"
    >
      <Tooltip title="Löschen">
        <Button disabled={isDeletionDisabled} shape="circle" type="text" icon={<DeleteOutlined />} size="small" />
      </Tooltip>
    </Popconfirm>
  );
};

export default AbrDefVersionTimelineCardExtra;
