import { generatePath } from 'react-router-dom';
import { URI_OBJEKT_DETAILS_PAGE } from '../../Objekt/objektUriPaths';

export const URI_SUB_ABRECHNUNG_DETAILS_PAGE = `${URI_OBJEKT_DETAILS_PAGE}/sub-abrechnung/:subAbrechnungId`;

export const generatePathToSubAbrechnungDetailsPage = (objektId: string, subAbrechnungId: string) =>
  generatePath(URI_SUB_ABRECHNUNG_DETAILS_PAGE, { objektId, subAbrechnungId });

const generatePathToSubAbrechnungDetailsPageAbrechnung = (objektId: string, subAbrechnungId: string) =>
  `${generatePathToSubAbrechnungDetailsPage(objektId, subAbrechnungId)}/abrechnungen`;

export const pathsToSubAbrechnungDetails = (objektId: string, subAbrechnungId: string) => ({
  subAbr: generatePathToSubAbrechnungDetailsPageAbrechnung(objektId, subAbrechnungId),
  subAbrTab: `${generatePathToSubAbrechnungDetailsPageAbrechnung(objektId, subAbrechnungId)}/subverwaltungsabrechnung`,
  documents: `${generatePathToSubAbrechnungDetailsPage(objektId, subAbrechnungId)}/documents`,
});
