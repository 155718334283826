import { FC } from 'react';
import { useBelegnummernkreisDefinitionChangeHistoryListQuery } from '../../../../BelegnummernkreisDefinition/gql/BelegnummernkreisDefinitionQueries.types';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../History/HistoryListingTable';

type Props = {
  rechtstraegerId: string;
  belegnummernkreisDefinitionId: string;
};

const RechnungsAusstellerBelegnummernkreisChangeHistoryListingTable: FC<Props> = ({ rechtstraegerId, belegnummernkreisDefinitionId }) => {
  const { data, loading } = useBelegnummernkreisDefinitionChangeHistoryListQuery({ variables: { rechtstraegerId, belegnummernkreisDefinitionId } });
  const historyList = data?.getBelegnummernkreisDefinitionChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="rechnung-aussteller-belegnummernkreis"
    />
  );
};

export default RechnungsAusstellerBelegnummernkreisChangeHistoryListingTable;
