import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmailIntegrationQueryVariables = Types.Exact<{ [key: string]: never }>;

export type EmailIntegrationQuery = {
  getEmailIntegration: {
    data?: {
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      emailAddress: string;
      firmendatenId: string;
      name?: string | null;
      updatedByMitarbeiterId?: string | null;
    } | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuthenticateForEmailIntegrationQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AuthenticateForEmailIntegrationQuery = { authenticateForEmailIntegration: string };

export const EmailIntegrationDocument = gql`
  query EmailIntegration {
    getEmailIntegration {
      data {
        createdBy
        createdByMitarbeiterId
        createTs
        emailAddress
        firmendatenId
        name
        updatedByMitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useEmailIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<EmailIntegrationQuery, EmailIntegrationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailIntegrationQuery, EmailIntegrationQueryVariables>(EmailIntegrationDocument, options);
}
export function useEmailIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailIntegrationQuery, EmailIntegrationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailIntegrationQuery, EmailIntegrationQueryVariables>(EmailIntegrationDocument, options);
}
export function useEmailIntegrationSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EmailIntegrationQuery, EmailIntegrationQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmailIntegrationQuery, EmailIntegrationQueryVariables>(EmailIntegrationDocument, options);
}
export type EmailIntegrationQueryHookResult = ReturnType<typeof useEmailIntegrationQuery>;
export type EmailIntegrationLazyQueryHookResult = ReturnType<typeof useEmailIntegrationLazyQuery>;
export type EmailIntegrationSuspenseQueryHookResult = ReturnType<typeof useEmailIntegrationSuspenseQuery>;
export type EmailIntegrationQueryResult = Apollo.QueryResult<EmailIntegrationQuery, EmailIntegrationQueryVariables>;
export const AuthenticateForEmailIntegrationDocument = gql`
  query AuthenticateForEmailIntegration {
    authenticateForEmailIntegration
  }
`;
export function useAuthenticateForEmailIntegrationQuery(
  baseOptions?: Apollo.QueryHookOptions<AuthenticateForEmailIntegrationQuery, AuthenticateForEmailIntegrationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthenticateForEmailIntegrationQuery, AuthenticateForEmailIntegrationQueryVariables>(
    AuthenticateForEmailIntegrationDocument,
    options
  );
}
export function useAuthenticateForEmailIntegrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuthenticateForEmailIntegrationQuery, AuthenticateForEmailIntegrationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthenticateForEmailIntegrationQuery, AuthenticateForEmailIntegrationQueryVariables>(
    AuthenticateForEmailIntegrationDocument,
    options
  );
}
export function useAuthenticateForEmailIntegrationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AuthenticateForEmailIntegrationQuery, AuthenticateForEmailIntegrationQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuthenticateForEmailIntegrationQuery, AuthenticateForEmailIntegrationQueryVariables>(
    AuthenticateForEmailIntegrationDocument,
    options
  );
}
export type AuthenticateForEmailIntegrationQueryHookResult = ReturnType<typeof useAuthenticateForEmailIntegrationQuery>;
export type AuthenticateForEmailIntegrationLazyQueryHookResult = ReturnType<typeof useAuthenticateForEmailIntegrationLazyQuery>;
export type AuthenticateForEmailIntegrationSuspenseQueryHookResult = ReturnType<typeof useAuthenticateForEmailIntegrationSuspenseQuery>;
export type AuthenticateForEmailIntegrationQueryResult = Apollo.QueryResult<
  AuthenticateForEmailIntegrationQuery,
  AuthenticateForEmailIntegrationQueryVariables
>;
