import React, { FC } from 'react';
import { Avatar } from 'antd';
import { Comment } from '@ant-design/compatible';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { CallLog } from '../../../../types';
import { showSuccessMsgCreate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import FormButtons from '../../../../components/Button/FormButtons';
import { formFields, FormValues } from './formMapper';
import { useUpdateCallLogMutation } from '../../../CallLog/gql/CallLogMutations.types';
import { mitarbeiterInitials } from '../../../../components/Card/MitarbeiterTooltip';

type Props = {
  rechtstraegerId: string;
  callLog: CallLog;
  onCancel: () => void;
  onSuccess: () => void;
};

const CallLogUpdateForm: FC<Props> = ({ rechtstraegerId, callLog, onCancel, onSuccess }) => {
  const [runUpdate, { loading }] = useUpdateCallLogMutation({
    onCompleted: () => {
      showSuccessMsgCreate('Kommentar');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const mitarbeiter = callLog.callee;

  return (
    <Comment
      avatar={
        <Avatar style={{ backgroundColor: '#f77e59', verticalAlign: 'middle' }} size="small">
          {mitarbeiterInitials(undefined, mitarbeiter)}
        </Avatar>
      }
      content={
        <Formik<FormValues>
          initialValues={{ kommentar: callLog.kommentar }}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            runUpdate({
              variables: {
                rechtstraegerId,
                callLogId: callLog.callLogId,
                input: { kommentar: values.kommentar },
              },
            }).finally(() => setSubmitting(false));
          }}
        >
          {(formikProps) => (
            <Form layout="vertical">
              <Form.Item name={formFields.kommentar}>
                <Input.TextArea size="large" name={formFields.kommentar} placeholder="Neuen Kommentar eingeben..." />
              </Form.Item>
              <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} />
            </Form>
          )}
        </Formik>
      }
    />
  );
};

export default CallLogUpdateForm;
