import { FC } from 'react';
import HistoryListingTable from '../History/HistoryListingTable';
import { HistoryType } from '../History/shared/HistoryModal';
import { useRechnungsAusstellerBankSettingsChangeHistoryListQuery } from '../Rechtstraeger/Rolle/RechnungsAussteller/gql/RechnungsAusstellerQueries.types';

type Props = {
  rechtstraegerId: string;
};

const RABankSettingsChangeHistoryListingTable: FC<Props> = ({ rechtstraegerId }) => {
  const { data, loading } = useRechnungsAusstellerBankSettingsChangeHistoryListQuery({ variables: { rechtstraegerId } });
  const historyList = data?.getRechnungsAusstellerBankSettingsChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="address" />;
};

export default RABankSettingsChangeHistoryListingTable;
