import React from 'react';
import { TableWithAlignedColsColumnType } from '../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { EuroAmount } from '../../../components/Number';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import KontoWithQuery from '../../../shared/components/Konto/KontoWithQuery';
import { VorsteuerkuerzungVstKrzKonto } from '../../../types';

const vstKuerzungKontoRowColumns = (objektId: string): TableWithAlignedColsColumnType<VorsteuerkuerzungVstKrzKonto>[] => [
  {
    title: '',
    align: 'left',
    render: (konto) => <KontoWithQuery kontoId={konto.vorsteuerkuerzungKontoId} objektId={objektId} width="140px" maxTextLength={20} />,
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
  {
    title: '',
    colSpan: 3,
    render: (konto) => <EuroAmount value={konto.sumSteuer} />,
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },

  {
    title: '',
    colSpan: 2,
    render: (konto) => <EuroAmount value={konto.sumVstKuerzung} />,
  },
];

export default vstKuerzungKontoRowColumns;
