import React, { FC } from 'react';
import { FormikProps } from 'formik';
import AufteilungsschluesselSelect from '../../../../../AufteilungsschluesselSelect/AufteilungsschluesselSelect';
import FormItemWithTooltip from '../../../../../../components/Form/FormItemWithTooltip';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import {
  repFondsKontenZuordnungVersionFormFields,
  repFondsKontenZuweisungFormFields,
  RepFondsKontenZuweisungVersionFormValues,
} from '../../../Version/Form/repFondsKontenZuweisungVersionFormMapper';

type Props = {
  formikProps: FormikProps<RepFondsKontenZuweisungVersionFormValues>;
  index: number;
};

const AufteilungsschluesselSelectColumn: FC<Props> = ({ formikProps, index }) => {
  const { activeForFirmendatenId } = useFDAuthorized();
  const name = `${repFondsKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${repFondsKontenZuweisungFormFields.aufteilungsschluesselId}`;

  const { repFondsKontoId } = formikProps.values.kontenZuweisungList[index];

  const excludedAuftSchluesselIds = formikProps.values.kontenZuweisungList
    .filter((konto, kontoIndex) => kontoIndex !== index && konto.repFondsKontoId === repFondsKontoId)
    .map((konto) => konto.aufteilungsschluesselId);

  return (
    <FormItemWithTooltip name={name}>
      <AufteilungsschluesselSelect
        name={name}
        allowClear={false}
        excludedAufteilungsschluesselIds={excludedAuftSchluesselIds}
        firmendatenId={activeForFirmendatenId}
      />
    </FormItemWithTooltip>
  );
};

export default AufteilungsschluesselSelectColumn;
