import React, { FC } from 'react';
import { Box } from 'rebass';
import { MessageOutlined, PhoneOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { ContactType } from './contactHelper';
import { ContactModel } from './contactMapper';

type ContactProps = {
  contact: ContactModel;
  boxPadding?: number | null;
};

const Contact: FC<ContactProps> = ({ contact = {}, boxPadding }) => (
  <Box py={boxPadding ?? 2}>
    <Space>
      {contact.type === ContactType.PHONE ? <PhoneOutlined /> : <MessageOutlined />}
      {contact.type === ContactType.PHONE ? (
        <a href={`tel:${contact.wert}`}>{contact.wert}</a>
      ) : (
        <a href={`mailto:${contact.wert}`}>{contact.wert}</a>
      )}
    </Space>
  </Box>
);

export default Contact;
