import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { OffenePostenFieldsFragmentDoc } from '../../OffenePosten/gql/OffenePostenFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAuszifferungListMutationVariables = Types.Exact<{
  zahlungId: Types.Scalars['String']['input'];
  input: Types.AuszifferungListCreateInput;
}>;

export type CreateAuszifferungListMutation = {
  createAuszifferungList: {
    data: Array<{ auszifferungId: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuszifferungStornierenMutationVariables = Types.Exact<{
  zahlungId: Types.Scalars['String']['input'];
  auszifferungId: Types.Scalars['String']['input'];
}>;

export type AuszifferungStornierenMutation = {
  actionAuszifferungStornieren: {
    data: {
      auszifferungId: string;
      auszifferungsDatum: string;
      betrag: number;
      buchungId: string;
      buchungsdatum: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      offenePostenId: string;
      storniert: boolean;
      storniertByMitarbeiterId?: string | null;
      storniertTs?: string | null;
      stornierteOffenePostenId?: string | null;
      stornoOffenePostenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      zahlungId?: string | null;
      offenePosten: {
        belegDatum: string;
        belegFileId?: string | null;
        belegnummer: string;
        buchungId: string;
        buchungsdatum: string;
        buchungskreisId: string;
        buchungskreisRechtstraegerId: string;
        buchungskreisRechtstraegerBezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dataCarrierBelegId?: string | null;
        dueDate: string;
        gesamtBetrag: number;
        iban?: string | null;
        letzteMahnstufe: number;
        offenePostenId: string;
        offenerBetrag: number;
        statementNumber?: string | null;
        auszifferungList: Array<{
          auszifferungId: string;
          auszifferungsDatum: string;
          betrag: number;
          bookingSuggestionId?: string | null;
          buchungId: string;
          buchungsdatum: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          offenePostenId: string;
          storniert: boolean;
          storniertByMitarbeiterId?: string | null;
          storniertTs?: string | null;
          stornierteOffenePostenId?: string | null;
          stornoOffenePostenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          zahlungId?: string | null;
          source: { text: string; value: Types.AuszifferungSource };
        }>;
        belegSymbol: { text: string; value: Types.FibuBelegSymbol; abbreviation: string };
        konto: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          haupt: boolean;
          kontoId: string;
          kontoTemplateId?: string | null;
          nummer: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          klasse: { text: string; value: Types.KontoKlasse };
          status: { description?: string | null; text: string; value: Types.KontoStatus };
          type: { text: string; value: Types.KontoType };
          verwendung?: { text: string; value: Types.KontoVerwendung } | null;
        };
        vertragspartner: {
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
        };
      };
      source: { text: string; value: Types.AuszifferungSource };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateAuszifferungListDocument = gql`
  mutation createAuszifferungList($zahlungId: String!, $input: AuszifferungListCreateInput!) {
    createAuszifferungList(zahlungId: $zahlungId, input: $input) {
      data {
        auszifferungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateAuszifferungListMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAuszifferungListMutation, CreateAuszifferungListMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAuszifferungListMutation, CreateAuszifferungListMutationVariables>(CreateAuszifferungListDocument, options);
}
export type CreateAuszifferungListMutationHookResult = ReturnType<typeof useCreateAuszifferungListMutation>;
export type CreateAuszifferungListMutationResult = Apollo.MutationResult<CreateAuszifferungListMutation>;
export type CreateAuszifferungListMutationOptions = Apollo.BaseMutationOptions<
  CreateAuszifferungListMutation,
  CreateAuszifferungListMutationVariables
>;
export const AuszifferungStornierenDocument = gql`
  mutation AuszifferungStornieren($zahlungId: String!, $auszifferungId: String!) {
    actionAuszifferungStornieren(zahlungId: $zahlungId, auszifferungId: $auszifferungId) {
      data {
        auszifferungId
        auszifferungsDatum
        betrag
        buchungId
        buchungsdatum
        createTs
        createdBy
        createdByMitarbeiterId
        offenePostenId
        offenePosten {
          ...OffenePostenFields
        }
        source {
          text
          value
        }
        storniert
        storniertByMitarbeiterId
        storniertTs
        stornierteOffenePostenId
        stornoOffenePostenId
        updatedByMitarbeiterId
        zahlungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OffenePostenFieldsFragmentDoc}
`;
export function useAuszifferungStornierenMutation(
  baseOptions?: Apollo.MutationHookOptions<AuszifferungStornierenMutation, AuszifferungStornierenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuszifferungStornierenMutation, AuszifferungStornierenMutationVariables>(AuszifferungStornierenDocument, options);
}
export type AuszifferungStornierenMutationHookResult = ReturnType<typeof useAuszifferungStornierenMutation>;
export type AuszifferungStornierenMutationResult = Apollo.MutationResult<AuszifferungStornierenMutation>;
export type AuszifferungStornierenMutationOptions = Apollo.BaseMutationOptions<
  AuszifferungStornierenMutation,
  AuszifferungStornierenMutationVariables
>;
