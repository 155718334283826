import { HEADER_EASYCLOUD_FIRMENDATEN_ID } from './easyCloudWebHeaders';

class Fetcher {
  private static instance: Fetcher;

  firmendatenId?: string;

  static getInstance() {
    if (!Fetcher.instance) {
      Fetcher.instance = new Fetcher();
    }
    return Fetcher.instance;
  }

  // apply easycloud_firmendaten_id for header on every fetch
  private updateOptions(options: RequestInit) {
    const update = { ...options };
    if (this.firmendatenId) {
      update.headers = {
        ...update.headers,
        [HEADER_EASYCLOUD_FIRMENDATEN_ID]: this.firmendatenId,
      };
    }
    return update;
  }

  fetch(input: RequestInfo, options?: RequestInit) {
    return fetch(input, options ? this.updateOptions(options) : undefined);
  }
}

export default Fetcher;
