import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../styles/pdfStyles';
import { AbrechnungskreisDetailsAbrechnungskreis } from '../../../../../topAbrechnung-types';
import TAAbrKreisTitle from '../TAAbrKreisTitle';
import TAAbrKreisKontoList from './TAAbrKreisRepFondsKontoList';
import TAAbrKreisKontoListColumnTitles from './TAAbrKreisKontoListColumnTitles';

const TAAbrKreisRepFonds: FC<{ abrechKreis: AbrechnungskreisDetailsAbrechnungskreis }> = ({ abrechKreis }) => (
  <View>
    <View style={[pdfStyles.column, { width: '100%', marginTop: '3mm' }]}>
      {/* Abrechnungskreis title */}
      <TAAbrKreisTitle abrechKreis={abrechKreis} />

      {/* kontoListRepFonds is always delivered if abrechKreis is RepFonds, This check is only to avoid non-null assertion check by esLint and errors while generating pdfs */}
      {abrechKreis.kontoListRepFonds && (
        <View style={[pdfStyles.borderBottomSmall]}>
          {/* COLUMN_NAMES */}
          <TAAbrKreisKontoListColumnTitles kontoListHeader={abrechKreis.kontoListRepFonds.kontoListHeader} />
          {/* Konten */}
          <TAAbrKreisKontoList kontoListRepFonds={abrechKreis.kontoListRepFonds.kontoList} />
        </View>
      )}
    </View>
  </View>
);

export default TAAbrKreisRepFonds;
