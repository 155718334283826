import React from 'react';
import { Tag } from 'antd';
import { EuroAmount } from '../../../../../components/Number';
import { TableWithAlignedColsColumnType } from '../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import AbrKreisBezeichnung from '../../../shared/AbrKreisBezeichnung';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import { compareTwoNumbersForSorting } from '../../../../../helpers/numberHelper';
import { ObjektAbrechnungkreis } from '../../../../../types';

const objektAbrechnungTableColumns: TableWithAlignedColsColumnType<ObjektAbrechnungkreis>[] = [
  {
    title: 'Abrechnungskreis',
    width: '250px',
    sorting: {
      index: 0,
      compareFn: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    },
    render: (abrKreis) => <AbrKreisBezeichnung abrKreis={abrKreis} />,
  },
  {
    title: 'Aufteilungsschlüssel',
    align: 'center',
    render: (abrKreis) => <Tag>{abrKreis.auftSchluesselBezeichnung}</Tag>,
  },
  {
    title: 'Ausgaben',
    colSpan: 2,
    sorting: {
      index: 2,
      compareFn: (a, b) => compareTwoNumbersForSorting(a.aufwand.summe, b.aufwand.summe),
    },
    align: 'right',
    render: (abrKreis) => <EuroAmount value={abrKreis.sumAufwand} />,
  },
  {
    title: 'Einnahmen',
    colSpan: 2,
    sorting: {
      index: 3,
      compareFn: (a, b) => compareTwoNumbersForSorting(a.erloes.summe, b.erloes.summe),
    },
    align: 'right',
    render: (abrKreis) => <EuroAmount value={abrKreis.erloes.summe} />,
  },
  {
    title: 'Saldo',
    sorting: {
      index: 4,
      compareFn: (a, b) => compareTwoNumbersForSorting(a.saldo, b.saldo),
    },
    align: 'right',
    render: (abrKreis) => <EuroAmount value={abrKreis.saldo} />,
  },
];

export default objektAbrechnungTableColumns;
