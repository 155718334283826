import React, { FC } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Dropdown, MenuProps, Modal, Tooltip } from 'antd';
import { DeleteOutlined, EllipsisOutlined, FilePdfOutlined, FileSearchOutlined, InteractionOutlined } from '@ant-design/icons';
import { URI_OBJEKT_DETAILS_PAGE } from '../../../Objekt/objektUriPaths';
import { useDeleteObjektAbrechnungMutation, useObjektAbrechnungMarkUnderReviewMutation } from '../gql/ObjektAbrechnungMutations.types';
import { useDeleteTopAbrechnungenMutation } from '../Top/gql/TopAbrechnungMutations.types';
import { useGoBack } from '../../../../shared/GoBack/GoBackContext';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { showSuccessMsgDelete, showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { uriFileOfFirmendaten } from '../../../Document/documentApiPaths';
import { isStatusValid } from '../../../../helpers/statusHelper';
import { useCreateAuftragGenerierlaufMutation } from '../../../Verarbeitung/gql/AuftragGenerierlauf/AuftragGenerierlaufMutation.types';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { isAbrechnungTypeWeAbrechnung } from '../../shared/abrechnungHelpers';
import { ObjektAbrechnung } from '../../../../types';

type Props = {
  abrechnung: ObjektAbrechnung;
  objektId: string;
  onCollapse: () => void;
  onSuccess: () => void;
};
const BkOrWeAbrechnungCardActions: FC<Props> = ({ abrechnung, objektId, onCollapse, onSuccess }) => {
  const topAbrechnungList = abrechnung.topAbrechnungOverview?.topAbrechnungList;
  const menuItemLoeschen = 'Löschen';
  const goBack = useGoBack(generatePath(URI_OBJEKT_DETAILS_PAGE, { objektId }));
  const { activeForFirmendatenId } = useFDAuthorized();

  const [runDeleteObjektAbrechnung] = useDeleteObjektAbrechnungMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Objektabrechnung');
      goBack();
    },
    variables: {
      objektId,
      objektAbrechnungId: abrechnung.objektAbrechnungId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDeleteTopAbrechnungen] = useDeleteTopAbrechnungenMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Topabrechnung');
      onSuccess();
    },
    variables: {
      objektId,
      objektAbrechnungId: abrechnung.objektAbrechnungId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreateTopAbrechnungAuftraege] = useCreateAuftragGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Aufträge für die Topabrechnungen erfolgreich angelegt.`);
      onSuccess();
    },
    variables: {
      auftragsGenerierlaufInput: {
        generierDatum: mapFormDateValueToDateString(dayjsCustom()),
        objektIdList: [objektId],
        objektAbrechnungId: abrechnung.objektAbrechnungId,
      },
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runObjektAbrechnungMarkUnderReview] = useObjektAbrechnungMarkUnderReviewMutation({
    onCompleted: () => {
      showSuccessMsgOther('Objektabrechnung wird überprüft');
    },
    variables: {
      objektId,
      objektAbrechnungId: abrechnung.objektAbrechnungId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      label: isAbrechnungTypeWeAbrechnung(abrechnung.type.value) ? 'WE-Objektabrechnung' : 'BK-Objektabrechnung',
      key: 'Objektabrechnung',
      children: [
        {
          key: 'objektabrechnung-item-1',
          label: (
            <Link
              to={abrechnung.file && abrechnung.fileId ? uriFileOfFirmendaten(activeForFirmendatenId, abrechnung.fileId, abrechnung.file.name) : ''}
              target="_blank"
            >
              Öffnen
            </Link>
          ),
          icon: <FilePdfOutlined />,
          disabled: !abrechnung.fileId,
        },
        {
          key: 'objektabrechnung-item-2',
          label: 'Prüfen',
          onClick: () => showConfirmObjektAbrechnungMarkUnderReview(abrechnung, runObjektAbrechnungMarkUnderReview),
          icon: <FileSearchOutlined />,
        },
        {
          key: 'objektabrechnung-item-3',
          label:
            topAbrechnungList && topAbrechnungList.length > 0 ? (
              <Tooltip title="Die Objektabrechnung kann nicht gelöscht werden, weil Topabrechnungen bereits erzeugt wurden">
                {menuItemLoeschen}
              </Tooltip>
            ) : (
              menuItemLoeschen
            ),
          onClick: () => showConfirmDeleteObjektAbrechnung(abrechnung, runDeleteObjektAbrechnung),
          icon: <DeleteOutlined />,
          disabled: topAbrechnungList && topAbrechnungList.length > 0,
          danger: true,
        },
      ],
    },
    {
      label: isAbrechnungTypeWeAbrechnung(abrechnung.type.value) ? 'WE-Topabrechnungen' : 'BK-Topabrechnungen',
      key: 'Topabrechnungen',
      children: [
        {
          key: 'topabrechnungen-item-1',
          label: menuItemLoeschen,
          onClick: () => showConfirmDeleteTopAbrechnungen(runDeleteTopAbrechnungen),
          icon: <DeleteOutlined />,
          disabled: !topAbrechnungList,
          danger: true,
        },
        {
          key: 'topabrechnungen-item-2',
          label: 'Erstellen',
          onClick: onCollapse,
          icon: <InteractionOutlined />,
          disabled: !isStatusValid(abrechnung.status) || (topAbrechnungList && topAbrechnungList.length > 0),
        },
        {
          key: 'topabrechnungen-item-3',
          label: 'Vorschreibungen erstellen',
          onClick: () => showConfirmCreateTopAbrechnungAuftraege(abrechnung, runCreateTopAbrechnungAuftraege),
          icon: <InteractionOutlined />,
          disabled: !topAbrechnungList,
        },
      ],
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
    </Dropdown>
  );
};

const showConfirmDeleteObjektAbrechnung = (abrechnung: ObjektAbrechnung, runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie diese Objektabrechnung löschen?',
    content: `${abrechnung.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmDeleteTopAbrechnungen = (runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Topabrechnungen löschen?',
    content: `Topabrechnungen`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmCreateTopAbrechnungAuftraege = (abrechnung: ObjektAbrechnung, runCreateTopAbrechnungAuftraege: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Aufträge für die Topabrechnungen erstellen?',
    content: `${abrechnung.bezeichnung}`,
    okText: 'Erstellen',
    cancelText: 'Abbrechen',
    onOk() {
      return runCreateTopAbrechnungAuftraege();
    },
  });
};

const showConfirmObjektAbrechnungMarkUnderReview = (abrechnung: ObjektAbrechnung, runMarkUnderReview: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Objektabrechnung prüfen?',
    content: `${abrechnung.bezeichnung}`,
    okText: 'Prüfen',
    cancelText: 'Abbrechen',
    onOk() {
      return runMarkUnderReview();
    },
  });
};

export default BkOrWeAbrechnungCardActions;
