import React, { FC, useEffect } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import { useField } from 'formik';
import { selectFilterOption } from '../../../../../../helpers/selectHelper';
import { useFilterPaymentPayeeIbanListQuery } from '../../../../../Payment/Listing/Filters/gql/PaymentFilterQueries.types';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';

type Props = { rechtstraegerId?: string | null } & Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const PaymentAssignmentPayeeIbanSelect: FC<Props> = ({ rechtstraegerId, ...restProps }) => {
  const { loading, data } = useFilterPaymentPayeeIbanListQuery({ variables: { rechtstraegerId } });
  const payeeIbanList = data?.getFilterPaymentPayeeIbanList.data;

  const [{ value: payeeIban }, , { setValue: setPayeeIban }] = useField(restProps.name);

  // On init remove payeIban if list does not include it
  useEffect(() => {
    if (payeeIban) {
      !payeeIbanList?.includes(payeeIban) && setPayeeIban(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Select
        {...restProps}
        id={restProps.name}
        loading={loading}
        size="small"
        placeholder="Bankverbindung auswählen"
        allowClear
        showSearch
        filterOption={selectFilterOption}
      >
        {payeeIbanList?.map((iban) => {
          return (
            <Select.Option key={iban} value={iban}>
              <DataWithShortenedText maxTextLength={40} text={`${friendlyFormatIBAN(iban)}`} />
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};

export default PaymentAssignmentPayeeIbanSelect;
