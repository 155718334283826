import React, { FC } from 'react';
import { Typography } from 'antd';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFilterPaymentClearingKontoVerwendungListQuery } from '../../gql/PaymentClearingQueries.types';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'loading' | 'allowClear' | 'showSearch' | 'filterOption'>;

const PaymentClearingKontoVerwendungListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useFilterPaymentClearingKontoVerwendungListQuery();

  const list = data?.getFilterPaymentClearingKontoVerwendungList.data ?? [];

  return (
    <Select
      size="small"
      placeholder="Kontoverwendung auswählen"
      loading={loading}
      name={name}
      id={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      {...restProps}
    >
      {list.map((kontoverwendung) => (
        <Select.Option key={kontoverwendung.value} value={kontoverwendung.value}>
          <Typography.Text>{kontoverwendung.text}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaymentClearingKontoVerwendungListFormSelect;
