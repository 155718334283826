import React, { FC } from 'react';
import { Space } from 'antd';
import { FormattedNumber } from 'react-intl';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import { ObjektSummeAnteile } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type ObjektSummeAnteileVersionCardContentProps = {
  timeblock: ITimeblock<ObjektSummeAnteile>;
};

const ObjektSummeAnteileVersionCardContent: FC<ObjektSummeAnteileVersionCardContentProps> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      <CustomFormattedDate value={timeblock.validFrom} />
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle="Summe Anteile">
      <FormattedNumber value={timeblock.summeAnteile} maximumFractionDigits={2} />
    </TimelineCardContentInfoRow>
  </Space>
);

export default ObjektSummeAnteileVersionCardContent;
