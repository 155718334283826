import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { ZahlungsverlaufDetails } from '../../topAbrechnung-types';
import { auszifferungColumnWidthsAndAlignment, columnWidthsAndAlignment } from './styles/zahlungsverlauf-table-styles';

type Props = {
  list: ZahlungsverlaufDetails[];
};

const LineList: FC<Props> = ({ list }) => (
  <View style={[pdfStyles.column, pdfStyles.borderBottomSmall, { marginTop: '2mm' }]}>
    {list.map((zahlungsverlaufLine, index) => (
      <View style={pdfStyles.column} key={index}>
        <View style={[pdfStyles.row, { width: '100%' }]}>
          <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.dueDate }]}>{zahlungsverlaufLine.dueDate}</Text>
          <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.belegnummer }]}>{zahlungsverlaufLine.belegnummer}</Text>
          <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.belegdatum }]}>{zahlungsverlaufLine.belegdatum}</Text>
          <Text style={{ width: columnWidthsAndAlignment.emptyAuszifferung.width }} />

          <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.vorschreibung }]}>{zahlungsverlaufLine.vorschreibung}</Text>

          <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.offenerBetrag }]}>{zahlungsverlaufLine.offenerBetrag}</Text>
        </View>

        {/* Auszifferung Lines */}
        <View style={[pdfStyles.row]}>
          <View style={[pdfStyles.column, { width: '100%', marginBottom: '2mm' }]}>
            {zahlungsverlaufLine.auszifferung.map((auszifferungLine, index) => (
              <View style={[pdfStyles.row]} key={index}>
                <Text style={{ width: auszifferungColumnWidthsAndAlignment.emptyDueDateBelegNumberDate.width }} />
                <Text
                  style={[
                    pdfStyles.textNormal,
                    {
                      ...auszifferungColumnWidthsAndAlignment.auszifferung,
                      paddingLeft: '5mm',
                    },
                  ]}
                >
                  {auszifferungLine.text}
                </Text>
                <Text
                  style={[
                    pdfStyles.textNormal,
                    {
                      ...auszifferungColumnWidthsAndAlignment.auszifferungValue,
                    },
                  ]}
                >
                  {auszifferungLine.betrag}
                </Text>
                <Text style={{ width: auszifferungColumnWidthsAndAlignment.emptyOffenerBetrag.width }} />
              </View>
            ))}
          </View>
        </View>
      </View>
    ))}
  </View>
);

export default LineList;
