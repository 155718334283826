import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { columnWidthsAndAlignment } from './styles/zahlRueckOtherPers-table-styles';
import { ZahlungsrueckstandOtherPersonsListHeader } from '../../topAbrechnung-types';

type ColumnTitlesProps = {
  titles: ZahlungsrueckstandOtherPersonsListHeader;
};
const ColumnTitles: FC<ColumnTitlesProps> = ({ titles }) => (
  <View
    style={[
      pdfStyles.row,
      pdfStyles.textNormal,
      pdfStyles.borderBottomSmall,
      {
        paddingTop: '3mm',
        fontWeight: 'bold',
      },
    ]}
  >
    <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.top }]}>{titles.topText}</Text>
    <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.vertragspartner }]}>{titles.vertragspartnerText}</Text>
    <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.offenerBetrag }]}>{titles.offenerBetragText}</Text>
  </View>
);

export default ColumnTitles;
