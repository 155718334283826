import { firmennameFormPartInitialValues, mapFirmennameToFormValues } from '../../../../shared/components/Firmenname/form/firmennameFormPartMapper';
import { legalFormSelectInitialValues, mapLegalFormToFormValues } from '../../../../shared/components/LegalFormSelect/legalFormSelectMapper';
import {
  mapVatIdNumberToFormValues,
  vatIdNumberFormPartInitialValues,
} from '../../../../shared/components/VatIdentificationNumber/form/vatIdNumberFormPartMapper';
import {
  companyRegistrationNumberFormPartInitialValues,
  mapCompanyRegistrationNumberToFormValues,
} from '../../../../shared/components/CompanyRegistrationNumber/form/companyRegistrationNumberFormPartMapper';
import {
  kurzBezeichnungFormPartInitialValues,
  mapKurzBezeichnungToFormValues,
} from '../../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartMapper';
import { Firmendaten } from '../../../../types';

const firmendatenBaseFormPartInitialValues = {
  ...firmennameFormPartInitialValues(),
  ...kurzBezeichnungFormPartInitialValues(),
  ...legalFormSelectInitialValues(),
  ...vatIdNumberFormPartInitialValues(),
  ...companyRegistrationNumberFormPartInitialValues(),
};

export const mapFirmendatenBaseToFormValues = (firmendaten?: Firmendaten) => {
  if (!firmendaten) {
    return firmendatenBaseFormPartInitialValues;
  }
  return {
    firmendatenId: firmendaten.firmendatenId,
    ...mapFirmennameToFormValues(firmendaten),
    ...mapKurzBezeichnungToFormValues(firmendaten.kurzBezeichnung),
    ...mapLegalFormToFormValues(firmendaten.legalForm),
    ...mapVatIdNumberToFormValues(firmendaten.vatIdentificationNumber),
    ...mapCompanyRegistrationNumberToFormValues(firmendaten.companyRegistrationNumber),
  };
};
