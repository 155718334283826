import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import { BeVertragFragmentDoc } from './BeVertragFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WeAndMietVertragListQueryVariables = Types.Exact<{
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragsartList?: Types.InputMaybe<Array<Types.InputMaybe<Types.Vertragsart>> | Types.InputMaybe<Types.Vertragsart>>;
  order?: Types.InputMaybe<Types.VertragOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type WeAndMietVertragListQuery = {
  getWeAndMietVertragList: {
    data: {
      contentList: Array<{
        bestandseinheitBezeichnung: string;
        bestandseinheitId: string;
        brutto: number;
        buchungskreisId?: string | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        debitorKontoId?: string | null;
        netto: number;
        objektId: string;
        objektKurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        ust: number;
        vertragId: string;
        vertragsBeginn: string;
        vertragsEnde?: string | null;
        vertragspartnerId: string;
        vertragspartnerKurzBezeichnung: string;
        rechnungsausstellerList: Array<{
          date: string;
          rechtstraeger: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
        }>;
        status: { text: string; value: Types.VertragStatus };
        vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BeVertragQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BeVertragQuery = {
  getBeVertrag: {
    data: {
      applyIndexedValues: boolean;
      consolidationCriteria?: string | null;
      consolidationCriteriaEditable: boolean;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      sumBrutto: number;
      sumNetto: number;
      sumUst: number;
      tagList?: Array<string> | null;
      vertragId: string;
      vertragsBeginn: string;
      vertragsEnde?: string | null;
      vertragsartAndPartnerUpdatable?: boolean | null;
      bankDetails?: {
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      belegform: { text: string; value: Types.BelegFormArt };
      dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
      paymentMethod: { text: string; value: Types.PaymentMethod };
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.VertragStatus; description?: string | null };
      verrechnungsart: { text: string; value: Types.Verrechnungsart };
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
  };
};

export type BeVertragBaseListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  showObjekt?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  showBestandseinheit?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  showVertragsdauer?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BeVertragBaseListQuery = {
  getBeVertragBaseList: {
    data: Array<{
      bestandseinheitId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektId: string;
      status: Types.VertragStatus;
      updatedByMitarbeiterId?: string | null;
      vertragId: string;
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BeVertragChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
}>;

export type BeVertragChangeHistoryListQuery = {
  getBeVertragChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type BeVertragKurzbezeichnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  showBestandseinheit?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  showObjekt?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BeVertragKurzbezeichnungQuery = {
  getBeVertragKurzBezeichnung: {
    data?: string | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VertragPaymentMethodListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type VertragPaymentMethodListQuery = {
  getVertragPaymentMethodList: {
    data: Array<{ text: string; value: Types.PaymentMethod }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VertragVerrechnungsartListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type VertragVerrechnungsartListQuery = {
  getVertragVerrechnungsartList: {
    data: Array<{ text: string; value: Types.Verrechnungsart }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const WeAndMietVertragListDocument = gql`
  query WeAndMietVertragList(
    $objektId: ID
    $rechnungsAusstellerId: ID
    $vertragspartnerId: ID
    $vertragsartList: [Vertragsart]
    $order: VertragOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getWeAndMietVertragList(
      objektId: $objektId
      rechnungsAusstellerId: $rechnungsAusstellerId
      vertragspartnerId: $vertragspartnerId
      vertragsartList: $vertragsartList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          bestandseinheitBezeichnung
          bestandseinheitId
          brutto
          buchungskreisId
          createTs
          createdBy
          createdByMitarbeiterId
          debitorKontoId
          netto
          objektId
          objektKurzBezeichnung
          rechnungsausstellerList {
            date
            rechtstraeger {
              ...RechtstraegerBaseFields
            }
          }
          status {
            text
            value
          }
          updatedByMitarbeiterId
          ust
          vertragId
          vertragsBeginn
          vertragsEnde
          vertragsart {
            supportsExplicitVertragsEnde
            text
            value
          }
          vertragspartnerId
          vertragspartnerKurzBezeichnung
          warningList {
            attribute
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useWeAndMietVertragListQuery(baseOptions?: Apollo.QueryHookOptions<WeAndMietVertragListQuery, WeAndMietVertragListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WeAndMietVertragListQuery, WeAndMietVertragListQueryVariables>(WeAndMietVertragListDocument, options);
}
export function useWeAndMietVertragListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WeAndMietVertragListQuery, WeAndMietVertragListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WeAndMietVertragListQuery, WeAndMietVertragListQueryVariables>(WeAndMietVertragListDocument, options);
}
export function useWeAndMietVertragListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WeAndMietVertragListQuery, WeAndMietVertragListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WeAndMietVertragListQuery, WeAndMietVertragListQueryVariables>(WeAndMietVertragListDocument, options);
}
export type WeAndMietVertragListQueryHookResult = ReturnType<typeof useWeAndMietVertragListQuery>;
export type WeAndMietVertragListLazyQueryHookResult = ReturnType<typeof useWeAndMietVertragListLazyQuery>;
export type WeAndMietVertragListSuspenseQueryHookResult = ReturnType<typeof useWeAndMietVertragListSuspenseQuery>;
export type WeAndMietVertragListQueryResult = Apollo.QueryResult<WeAndMietVertragListQuery, WeAndMietVertragListQueryVariables>;
export const BeVertragDocument = gql`
  query BeVertrag($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $withDetails: Boolean) {
    getBeVertrag(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, withDetails: $withDetails) {
      data {
        ...BeVertrag
      }
    }
  }
  ${BeVertragFragmentDoc}
`;
export function useBeVertragQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragQuery, BeVertragQueryVariables> &
    ({ variables: BeVertragQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragQuery, BeVertragQueryVariables>(BeVertragDocument, options);
}
export function useBeVertragLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeVertragQuery, BeVertragQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragQuery, BeVertragQueryVariables>(BeVertragDocument, options);
}
export function useBeVertragSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeVertragQuery, BeVertragQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragQuery, BeVertragQueryVariables>(BeVertragDocument, options);
}
export type BeVertragQueryHookResult = ReturnType<typeof useBeVertragQuery>;
export type BeVertragLazyQueryHookResult = ReturnType<typeof useBeVertragLazyQuery>;
export type BeVertragSuspenseQueryHookResult = ReturnType<typeof useBeVertragSuspenseQuery>;
export type BeVertragQueryResult = Apollo.QueryResult<BeVertragQuery, BeVertragQueryVariables>;
export const BeVertragBaseListDocument = gql`
  query BeVertragBaseList($objektId: ID!, $bestandseinheitId: ID!, $showObjekt: Boolean, $showBestandseinheit: Boolean, $showVertragsdauer: Boolean) {
    getBeVertragBaseList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      showObjekt: $showObjekt
      showBestandseinheit: $showBestandseinheit
      showVertragsdauer: $showVertragsdauer
    ) {
      data {
        bestandseinheitId
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        objektId
        status
        updatedByMitarbeiterId
        vertragId
        vertragsart {
          supportsExplicitVertragsEnde
          text
          value
        }
        vertragspartner {
          ...RechtstraegerBaseFields
        }
        warningList {
          attribute
          message
          type
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useBeVertragBaseListQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragBaseListQuery, BeVertragBaseListQueryVariables> &
    ({ variables: BeVertragBaseListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragBaseListQuery, BeVertragBaseListQueryVariables>(BeVertragBaseListDocument, options);
}
export function useBeVertragBaseListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeVertragBaseListQuery, BeVertragBaseListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragBaseListQuery, BeVertragBaseListQueryVariables>(BeVertragBaseListDocument, options);
}
export function useBeVertragBaseListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeVertragBaseListQuery, BeVertragBaseListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragBaseListQuery, BeVertragBaseListQueryVariables>(BeVertragBaseListDocument, options);
}
export type BeVertragBaseListQueryHookResult = ReturnType<typeof useBeVertragBaseListQuery>;
export type BeVertragBaseListLazyQueryHookResult = ReturnType<typeof useBeVertragBaseListLazyQuery>;
export type BeVertragBaseListSuspenseQueryHookResult = ReturnType<typeof useBeVertragBaseListSuspenseQuery>;
export type BeVertragBaseListQueryResult = Apollo.QueryResult<BeVertragBaseListQuery, BeVertragBaseListQueryVariables>;
export const BeVertragChangeHistoryListDocument = gql`
  query BeVertragChangeHistoryList($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!) {
    getBeVertragChangeHistoryList(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBeVertragChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragChangeHistoryListQuery, BeVertragChangeHistoryListQueryVariables> &
    ({ variables: BeVertragChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragChangeHistoryListQuery, BeVertragChangeHistoryListQueryVariables>(BeVertragChangeHistoryListDocument, options);
}
export function useBeVertragChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeVertragChangeHistoryListQuery, BeVertragChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragChangeHistoryListQuery, BeVertragChangeHistoryListQueryVariables>(BeVertragChangeHistoryListDocument, options);
}
export function useBeVertragChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeVertragChangeHistoryListQuery, BeVertragChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragChangeHistoryListQuery, BeVertragChangeHistoryListQueryVariables>(
    BeVertragChangeHistoryListDocument,
    options
  );
}
export type BeVertragChangeHistoryListQueryHookResult = ReturnType<typeof useBeVertragChangeHistoryListQuery>;
export type BeVertragChangeHistoryListLazyQueryHookResult = ReturnType<typeof useBeVertragChangeHistoryListLazyQuery>;
export type BeVertragChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useBeVertragChangeHistoryListSuspenseQuery>;
export type BeVertragChangeHistoryListQueryResult = Apollo.QueryResult<BeVertragChangeHistoryListQuery, BeVertragChangeHistoryListQueryVariables>;
export const BeVertragKurzbezeichnungDocument = gql`
  query BeVertragKurzbezeichnung($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $showBestandseinheit: Boolean, $showObjekt: Boolean) {
    getBeVertragKurzBezeichnung(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      showBestandseinheit: $showBestandseinheit
      showObjekt: $showObjekt
    ) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBeVertragKurzbezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragKurzbezeichnungQuery, BeVertragKurzbezeichnungQueryVariables> &
    ({ variables: BeVertragKurzbezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragKurzbezeichnungQuery, BeVertragKurzbezeichnungQueryVariables>(BeVertragKurzbezeichnungDocument, options);
}
export function useBeVertragKurzbezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeVertragKurzbezeichnungQuery, BeVertragKurzbezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragKurzbezeichnungQuery, BeVertragKurzbezeichnungQueryVariables>(BeVertragKurzbezeichnungDocument, options);
}
export function useBeVertragKurzbezeichnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeVertragKurzbezeichnungQuery, BeVertragKurzbezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragKurzbezeichnungQuery, BeVertragKurzbezeichnungQueryVariables>(BeVertragKurzbezeichnungDocument, options);
}
export type BeVertragKurzbezeichnungQueryHookResult = ReturnType<typeof useBeVertragKurzbezeichnungQuery>;
export type BeVertragKurzbezeichnungLazyQueryHookResult = ReturnType<typeof useBeVertragKurzbezeichnungLazyQuery>;
export type BeVertragKurzbezeichnungSuspenseQueryHookResult = ReturnType<typeof useBeVertragKurzbezeichnungSuspenseQuery>;
export type BeVertragKurzbezeichnungQueryResult = Apollo.QueryResult<BeVertragKurzbezeichnungQuery, BeVertragKurzbezeichnungQueryVariables>;
export const VertragPaymentMethodListDocument = gql`
  query VertragPaymentMethodList {
    getVertragPaymentMethodList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVertragPaymentMethodListQuery(
  baseOptions?: Apollo.QueryHookOptions<VertragPaymentMethodListQuery, VertragPaymentMethodListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragPaymentMethodListQuery, VertragPaymentMethodListQueryVariables>(VertragPaymentMethodListDocument, options);
}
export function useVertragPaymentMethodListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragPaymentMethodListQuery, VertragPaymentMethodListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragPaymentMethodListQuery, VertragPaymentMethodListQueryVariables>(VertragPaymentMethodListDocument, options);
}
export function useVertragPaymentMethodListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VertragPaymentMethodListQuery, VertragPaymentMethodListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragPaymentMethodListQuery, VertragPaymentMethodListQueryVariables>(VertragPaymentMethodListDocument, options);
}
export type VertragPaymentMethodListQueryHookResult = ReturnType<typeof useVertragPaymentMethodListQuery>;
export type VertragPaymentMethodListLazyQueryHookResult = ReturnType<typeof useVertragPaymentMethodListLazyQuery>;
export type VertragPaymentMethodListSuspenseQueryHookResult = ReturnType<typeof useVertragPaymentMethodListSuspenseQuery>;
export type VertragPaymentMethodListQueryResult = Apollo.QueryResult<VertragPaymentMethodListQuery, VertragPaymentMethodListQueryVariables>;
export const VertragVerrechnungsartListDocument = gql`
  query VertragVerrechnungsartList {
    getVertragVerrechnungsartList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVertragVerrechnungsartListQuery(
  baseOptions?: Apollo.QueryHookOptions<VertragVerrechnungsartListQuery, VertragVerrechnungsartListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragVerrechnungsartListQuery, VertragVerrechnungsartListQueryVariables>(VertragVerrechnungsartListDocument, options);
}
export function useVertragVerrechnungsartListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragVerrechnungsartListQuery, VertragVerrechnungsartListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragVerrechnungsartListQuery, VertragVerrechnungsartListQueryVariables>(VertragVerrechnungsartListDocument, options);
}
export function useVertragVerrechnungsartListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VertragVerrechnungsartListQuery, VertragVerrechnungsartListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragVerrechnungsartListQuery, VertragVerrechnungsartListQueryVariables>(
    VertragVerrechnungsartListDocument,
    options
  );
}
export type VertragVerrechnungsartListQueryHookResult = ReturnType<typeof useVertragVerrechnungsartListQuery>;
export type VertragVerrechnungsartListLazyQueryHookResult = ReturnType<typeof useVertragVerrechnungsartListLazyQuery>;
export type VertragVerrechnungsartListSuspenseQueryHookResult = ReturnType<typeof useVertragVerrechnungsartListSuspenseQuery>;
export type VertragVerrechnungsartListQueryResult = Apollo.QueryResult<VertragVerrechnungsartListQuery, VertragVerrechnungsartListQueryVariables>;
