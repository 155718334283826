import React, { FC } from 'react';
import { Space } from 'antd';
import { FormikProps } from 'formik';
import { DatePicker, Input, InputNumber } from 'formik-antd';
import { FormFields } from '../../../../../../helpers/formikHelper';
import { versicherungCreateFormFields, VersicherungCreateFormValues } from '../Create/versicherungCreateFormMapper';
import FirmaContactPersonSelect from '../../../../../../shared/components/Firma/FirmaContactPersonSelect';
import RechtstraegerSelect from '../../../../../../shared/Rechtstraeger/RechtstraegerSelect';
import { RechtstraegerType } from '../../../../../../types';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../../components/DatePicker/DatePicker_formikAntD';
import KuendigungfristSelect from '../../../../../../shared/components/Kuendigungsfrist/KuendigungfristSelect';
import KuendigungsfristJeweilsZumSelect from '../../../../../../shared/components/KuendigungsfristJeweilsZum/KuendigungsfristJeweilsZumSelect';
import ZahlungsperiodeSelect from '../../../../../../shared/components/Zahlungsperiode/ZahlungsperiodeSelect';
import { VersicherungUpdateFormValues } from '../Update/versicherungUpdateFormMapper';
import DatenTabColumnsLayout from '../../shared/DatenTabColumnsLayout';
import ObjektSachbearbeiterSelect from '../../../../Sachbearbeiter/ObjektSachbearbeiterSelect';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formFields: FormFields<VersicherungCreateFormValues> | FormFields<VersicherungUpdateFormValues>;
  formikProps: FormikProps<VersicherungCreateFormValues> | FormikProps<VersicherungUpdateFormValues>;
  firmaId?: string;
};

const VersicherungDatenTab: FC<Props> = ({ formFields, firmaId, formikProps }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ObjektInfoFeldVersicherung'>('ObjektInfoFeldVersicherung');

  return (
    <DatenTabColumnsLayout
      leftColumn={
        <>
          <FormItemWithFieldHelp
            name={formFields.versicherungsart}
            label="Versicherungsart"
            fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.versicherungsart')}
          >
            <Input id={formFields.versicherungsart} name={formFields.versicherungsart} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.firmaId} label="Vertragspartner" fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.firmaId')}>
            <RechtstraegerSelect
              name={formFields.firmaId}
              typeList={[RechtstraegerType.Firma]}
              onNewItemCreated={(rechtstraegerId) => formikProps.setFieldValue(versicherungCreateFormFields.firmaId, rechtstraegerId)}
              onNewItemCreateSelected={() => formikProps.setFieldValue(versicherungCreateFormFields.firmaId, '')}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.contactPersonId}
            label="Ansprechpartner"
            fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.contactPersonId')}
          >
            <FirmaContactPersonSelect name={formFields.contactPersonId} rechtstraegerId={firmaId} disabled={!firmaId} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.beginn} label="Beginn" fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.beginn')}>
            <DatePicker size="small" id={formFields.beginn} name={formFields.beginn} style={{ width: '100%' }} format={DATE_DISPLAY_FORMAT_DEFAULT} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.datumSchaetzung}
            label="Datumschätzung"
            fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.datumSchaetzung')}
          >
            <DatePicker
              size="small"
              id={formFields.datumSchaetzung}
              name={formFields.datumSchaetzung}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.ende} label="Ende" fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.ende')}>
            <DatePicker size="small" id={formFields.ende} name={formFields.ende} style={{ width: '100%' }} format={DATE_DISPLAY_FORMAT_DEFAULT} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.erstmalsFaellig}
            label="Erstmals fällig"
            fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.erstmalsFaellig')}
          >
            <DatePicker
              size="small"
              id={formFields.erstmalsFaellig}
              name={formFields.erstmalsFaellig}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.validFrom} label="Gültig ab" fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.validFrom')}>
            <DatePicker
              size="small"
              id={formFields.validFrom}
              name={formFields.validFrom}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.hauptDueDate}
            label="Hauptfälligkeit"
            fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.hauptDueDate')}
          >
            <DatePicker
              size="small"
              id={formFields.hauptDueDate}
              name={formFields.hauptDueDate}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
            />
          </FormItemWithFieldHelp>
        </>
      }
      rightColumn={
        <>
          <Space align="start">
            <FormItemWithFieldHelp
              name={formFields.kuendigungsfristDauer}
              label="Kündigungsfristdauer"
              fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.kuendigungsfristDauer')}
            >
              <InputNumber name={formFields.kuendigungsfristDauer} size="small" min={0} style={{ width: '100%' }} />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={formFields.kuendigungsfrist}
              label="Kündigungsfrist"
              fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.kuendigungsfrist')}
            >
              <KuendigungfristSelect name={formFields.kuendigungsfrist} />
            </FormItemWithFieldHelp>
          </Space>
          <FormItemWithFieldHelp
            name={formFields.kuendigungsfristJeweilsZum}
            label="Jeweils zum"
            fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.kuendigungsfristJeweilsZum')}
          >
            <KuendigungsfristJeweilsZumSelect name={formFields.kuendigungsfristJeweilsZum} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.maklerId} label="Makler" fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.maklerId')}>
            <RechtstraegerSelect name={formFields.maklerId} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.neubauwert}
            label="Neubauwert"
            fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.neubauwert')}
          >
            <InputNumber name={formFields.neubauwert} size="small" controls={false} min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.polizze} label="Polizze" fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.polizze')}>
            <Input id={formFields.polizze} name={formFields.polizze} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.sachbearbeiterId}
            label="Sachbearbeiter"
            fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.sachbearbeiterId')}
          >
            <ObjektSachbearbeiterSelect name={formFields.sachbearbeiterId} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.versicherungssumme}
            label="Versicherungssumme"
            fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.versicherungssumme')}
          >
            <InputNumber name={formFields.versicherungssumme} size="small" controls={false} min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.wertsicherung}
            label="Wertsicherung"
            fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.wertsicherung')}
          >
            <Input id={formFields.wertsicherung} name={formFields.wertsicherung} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.zahlungsperiode}
            label="Zahlungsperiode"
            fieldHelp={getFieldHelpText('ObjektInfoFeldVersicherung.zahlungsperiode')}
          >
            <ZahlungsperiodeSelect name={formFields.zahlungsperiode} />
          </FormItemWithFieldHelp>
        </>
      }
    />
  );
};

export default VersicherungDatenTab;
