import React from 'react';
import { FormikProps } from 'formik';
import { ColumnProps } from 'antd/es/table';
import { DeleteOutlined } from '@ant-design/icons';
import {
  repFondsKontenZuordnungVersionFormFields,
  repFondsKontenZuweisungDescriptionsForKommentar,
  repFondsKontenZuweisungFormFields,
  RepFondsKontenZuweisungFormValues,
  RepFondsKontenZuweisungVersionFormValues,
} from '../../Version/Form/repFondsKontenZuweisungVersionFormMapper';
import FormItemWithTooltip from '../../../../../components/Form/FormItemWithTooltip';
import AufteilungsschluesselSelectColumn from './columns/AufteilungschluesselSelectColumn';
import AufwandskontenSelect from '../../../../shared/AufwandskontenSelect';
import { KontoKlasse } from '../../../../../types';

const repFondsKontenZuweisungTableColumns = (
  formikProps: FormikProps<RepFondsKontenZuweisungVersionFormValues>,
  onDelete: (index: number) => void
): ColumnProps<RepFondsKontenZuweisungFormValues>[] => [
  {
    title: repFondsKontenZuweisungDescriptionsForKommentar.aufwandsKontoIdList,
    key: repFondsKontenZuweisungFormFields.aufwandsKontoIdList,
    render: (text, record, index) => {
      const name = `${repFondsKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${repFondsKontenZuweisungFormFields.aufwandsKontoIdList}`;

      const aufwandskontenIds = formikProps.values.kontenZuweisungList.map((konten) => konten.aufwandsKontoIdList).flat();
      const excludedAufwandsKontenIds = aufwandskontenIds.filter((kontoId) => !record.aufwandsKontoIdList.includes(kontoId));
      const repFondsKontoIds = formikProps.values.kontenZuweisungList?.map((konto) => konto.repFondsKontoId).flat();
      const excludedKontenIds = [...excludedAufwandsKontenIds, ...repFondsKontoIds];

      return (
        <FormItemWithTooltip name={name}>
          <AufwandskontenSelect
            key={index}
            name={name}
            mode="multiple"
            excludedKontenIds={excludedKontenIds}
            klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand]}
            showKontoNummerAsTag={false}
          />
        </FormItemWithTooltip>
      );
    },
  },
  {
    title: repFondsKontenZuweisungDescriptionsForKommentar.aufteilungsschluesselId,
    width: 220,
    key: repFondsKontenZuweisungFormFields.aufteilungsschluesselId,
    render: (text, record, index) => <AufteilungsschluesselSelectColumn index={index} formikProps={formikProps} />,
  },
  {
    title: repFondsKontenZuweisungDescriptionsForKommentar.repFondsKontoId,
    width: 200,
    key: repFondsKontenZuweisungFormFields.repFondsKontoId,
    render: (text, record, index) => {
      const name = `${repFondsKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${repFondsKontenZuweisungFormFields.repFondsKontoId}`;
      const aufwandsKontenIds = formikProps.values.kontenZuweisungList?.map((konto) => konto.aufwandsKontoIdList).flat();

      const excludedRepFondsKontoIdsWithSameAuftSchluesselId = formikProps.values.kontenZuweisungList
        .filter((konto) => konto.aufteilungsschluesselId === formikProps.values.kontenZuweisungList[index].aufteilungsschluesselId)
        .map((konto) => konto.repFondsKontoId)
        .filter((kontoId) => kontoId !== formikProps.values.kontenZuweisungList[index].repFondsKontoId)
        .filter((kontoId) => kontoId);

      const excludedKontenIds = [...excludedRepFondsKontoIdsWithSameAuftSchluesselId, ...aufwandsKontenIds];

      return (
        <FormItemWithTooltip name={name}>
          <AufwandskontenSelect
            key={index}
            name={name}
            excludedKontenIds={excludedKontenIds}
            showKontoNummerAsTag={false}
            klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand]}
          />
        </FormItemWithTooltip>
      );
    },
  },
  {
    title: 'Aktion',
    width: 50,
    align: 'center',
    render: (text, record, index) => <DeleteOutlined onClick={() => onDelete(index)} />,
  },
];

export default repFondsKontenZuweisungTableColumns;
