import { FC } from 'react';
import { Formik } from 'formik';
import { BeInfoFeldListingFiltersFormValues } from './beInfoFeldListingFiltersFormMapper';
import BeInfoFeldListingForm from './BeInfoFeldListingForm';

type BeInfoFeldListingBeInfoFeldListingProps = {
  objektId: string;
  bestandseinheitId: string;
};

const BeInfoFeldListing: FC<BeInfoFeldListingBeInfoFeldListingProps> = ({ objektId, bestandseinheitId }) => (
  <Formik<BeInfoFeldListingFiltersFormValues> initialValues={{ type: undefined }} onSubmit={() => {}}>
    {(formikProps) => <BeInfoFeldListingForm formikProps={formikProps} objektId={objektId} bestandseinheitId={bestandseinheitId} />}
  </Formik>
);

export default BeInfoFeldListing;
