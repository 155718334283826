import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import SysSettingsAllgemeineEinstellungPage from './SysSettingsAllgemeineEinstellungPage';

const sysSettingsAllgEinstellungPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.allgemeineEinstellungPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAllgemeineEinstellungPage />} />}
  />
);

export default sysSettingsAllgEinstellungPageRoute;
