import { generatePath } from 'react-router-dom';
import { URI_OBJEKT_DETAILS_PAGE } from '../../Objekt/objektUriPaths';
import { isStatusInvalid } from '../../../helpers/statusHelper';
import { AbrechnungStatusTuple } from '../../../types';

const URI_HE_ABRECHNUNG_DETAILS_PAGE = `${URI_OBJEKT_DETAILS_PAGE}/miet-abrechnung/he-abrechnung/:heAbrechnungId`;
const URI_HE_ABRECHNUNG_DETAILS_HE_VERTRAG_DETAILS_PAGE = `${URI_HE_ABRECHNUNG_DETAILS_PAGE}/he-vertrag/:heAbrechnungHeVertragId`;

const generatePathToHeAbrechnungDetailsPage = (objektId: string, heAbrechnungId: string) =>
  generatePath(URI_HE_ABRECHNUNG_DETAILS_PAGE, { objektId, heAbrechnungId });
const generatePathToHeAbrechnungDetailsHeVertragDetailsPage = (objektId: string, heAbrechnungId: string, heAbrechnungHeVertragId: string) =>
  generatePath(URI_HE_ABRECHNUNG_DETAILS_HE_VERTRAG_DETAILS_PAGE, { objektId, heAbrechnungId, heAbrechnungHeVertragId });

const generatePathToHeAbrechnungDetailsPageOrDiscrepancies = (objektId: string, heAbrechnungId: string, status: AbrechnungStatusTuple) =>
  isStatusInvalid(status)
    ? pathsToHeAbrechnungDetails(objektId, heAbrechnungId).deviations
    : pathsToHeAbrechnungDetails(objektId, heAbrechnungId).abrechnungDetails;

const generatePathToHeAbrechnungDetailsPageAbrechnung = (objektId: string, heAbrechnungId: string) =>
  `${generatePathToHeAbrechnungDetailsPage(objektId, heAbrechnungId)}/abrechnungen`;

const generatePathToHeAbrechnungDetailsHeVertragDetailsPageAbrechnungTab = (
  objektId: string,
  heAbrechnungId: string,
  heAbrechnungHeVertragId: string
) => `${generatePathToHeAbrechnungDetailsHeVertragDetailsPage(objektId, heAbrechnungId, heAbrechnungHeVertragId)}/abrechnung`;

export const pathsToHeAbrechnungDetails = (objektId: string, heAbrechnungId: string) => ({
  abrechnungDetails: generatePathToHeAbrechnungDetailsPage(objektId, heAbrechnungId),
  heAbr: `${generatePathToHeAbrechnungDetailsPageAbrechnung(objektId, heAbrechnungId)}`,
  heAbrTab: `${generatePathToHeAbrechnungDetailsPageAbrechnung(objektId, heAbrechnungId)}/he-abrechnung`,
  zahlungsrueckstandTab: `${generatePathToHeAbrechnungDetailsPageAbrechnung(objektId, heAbrechnungId)}/zahlungsrueckstand`,
  deviations: `${generatePathToHeAbrechnungDetailsPage(objektId, heAbrechnungId)}/discrepancies`,
});

export const pathsToHeAbrechnungHeVertragDetails = (objektId: string, heAbrechnungId: string, heAbrechnungHeVertragId: string) => ({
  abrechnungDetails: generatePathToHeAbrechnungDetailsHeVertragDetailsPage(objektId, heAbrechnungId, heAbrechnungHeVertragId),
  heAbr: generatePathToHeAbrechnungDetailsHeVertragDetailsPageAbrechnungTab(objektId, heAbrechnungId, heAbrechnungHeVertragId),
  deviations: `${generatePathToHeAbrechnungDetailsHeVertragDetailsPage(objektId, heAbrechnungId, heAbrechnungHeVertragId)}/deviations`,
});

export {
  generatePathToHeAbrechnungDetailsPage,
  generatePathToHeAbrechnungDetailsPageOrDiscrepancies,
  URI_HE_ABRECHNUNG_DETAILS_PAGE,
  generatePathToHeAbrechnungDetailsHeVertragDetailsPage,
  URI_HE_ABRECHNUNG_DETAILS_HE_VERTRAG_DETAILS_PAGE,
};
