import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../../../security/permissionChecks';
import SysSettingsAbrechnungsdefinitionVersionCreatePage from './SysSettingsAbrechnungsdefinitionVersionCreatePage';
import AuthRoute from '../../../../../../security/AuthRoute';
import { URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION } from '../../../../../../features/Abrechnungsdefinition/abrDefUriPaths';

export type AbrechnungsdefinitionVersionCreatePageRouteParams = { abrechnungsdefinitionId: string };

const sysSettingsAbrechnungsdefinitionVersionCreatePageRoute = (
  <Route
    path={URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.createPage}
    element={
      <AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAbrechnungsdefinitionVersionCreatePage />} />
    }
  />
);

export default sysSettingsAbrechnungsdefinitionVersionCreatePageRoute;
