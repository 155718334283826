import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { HeaderRender } from 'antd/es/calendar/generateCalendar';
import { Dayjs } from 'dayjs';
import { Spin } from 'antd';
import { dayjsCustom } from '../../helpers/dayjsCustom';
import { TicketListQueryVariables, useTicketListQuery } from '../Ticket/gql/TicketQueries.types';
import TicketCalenderHeader, { TicketCalenderHeaderProps } from './TicketCalenderHeader';
import CalendarDayjs from '../../components/Calendar/CalendarDayjs';
import { TicketBase } from '../../types';
import TicketCalendarDateCell from './TicketCalendarDateCell';
import { extendStatusListWithArchived } from '../Ticket/ticketHelpers';

export type TicketListGroupedByDueDate = Partial<Record<string, TicketBase[]>>;

type HeaderProps = {
  navigation?: TicketCalenderHeaderProps['navigation'];
  bottom?: TicketCalenderHeaderProps['bottom'];
};

type Props = {
  dueDate?: string | null;
  // Custom header renderer or props to pass to the default header
  header?: ((config: TicketCalenderHeaderProps) => React.ReactNode) | HeaderProps;
  dateCellRender?: (date: Dayjs, ticketList: TicketListGroupedByDueDate) => React.ReactNode;
  onChange?: (date: Dayjs) => void;
  onDateCellClick?: (date: Dayjs, event: MouseEvent<HTMLElement>) => void;
  showArchived?: boolean;
} & Omit<TicketListQueryVariables, 'dueDateFrom' | 'dueDateTo'>;

const TicketCalendar: FC<Props> = ({
  dueDate,
  header = TicketCalenderHeader,
  dateCellRender,
  onChange,
  onDateCellClick,
  showArchived,
  ...queryProps
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>();
  const [dueDateFrom, setDueDateFrom] = useState<TicketListQueryVariables['dueDateFrom']>();
  const [dueDateTo, setDueDateTo] = useState<TicketListQueryVariables['dueDateTo']>();

  useEffect(() => {
    setDueDateFrom(dayjsCustom(selectedDate).startOf('month').add(-1, 'month').format('YYYY-MM-DD'));
    setDueDateTo(dayjsCustom(selectedDate).endOf('month').add(1, 'month').format('YYYY-MM-DD'));
  }, [selectedDate]);

  useEffect(() => {
    setSelectedDate(dayjsCustom(dueDate ?? new Date()));
  }, [dueDate]);

  const { data, loading } = useTicketListQuery({
    variables: {
      searchInSubTickets: true,
      ...queryProps,
      dueDateFrom,
      dueDateTo,
      status: extendStatusListWithArchived(queryProps.status, showArchived),
    },
    skip: !selectedDate,
  });

  const ticketListByDueDate: TicketListGroupedByDueDate = Object.groupBy(data?.getTicketList.data.contentList ?? [], ({ dueDate }) => dueDate);

  const onCalendarChange = (value: Dayjs) => {
    setSelectedDate(value);
    onChange?.(value);
  };

  const headerRenderer: HeaderRender<Dayjs> = ({ value, onChange }) => {
    if (typeof header === 'function') {
      return header({ value, onChange, ...queryProps });
    }
    return <TicketCalenderHeader value={value} onChange={onChange} {...(header as HeaderProps)} />;
  };

  const dateCellRenderer = (date: Dayjs) => {
    if (typeof dateCellRender === 'function') {
      return dateCellRender(date, ticketListByDueDate);
    }
    return <TicketCalendarDateCell date={date} ticketListByDueDate={ticketListByDueDate} openLinkInNewTab showAssignee />;
  };

  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <div
          style={{
            position: 'absolute',
            background: 'rgba(255, 255, 255, 0.5)',
            width: '100%',
            height: '100%',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      )}
      <CalendarDayjs
        cellRender={(date) => (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: 86,
              top: 0,
              overflowY: 'auto',
              marginTop: 24,
            }}
            onClick={(event) => {
              onDateCellClick?.(date, event);
            }}
          >
            {dateCellRenderer(date)}
          </div>
        )}
        value={selectedDate}
        headerRender={headerRenderer}
        onChange={onCalendarChange}
      />
    </div>
  );
};

export default TicketCalendar;
