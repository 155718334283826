import { TAbrechnungQueryParams } from './filtersQueryParams';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { AbrechnungStatus, AbrechnungType, QueryGetAbrechnungListArgs } from '../../../../types';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';

export type FiltersFormValues = {
  abrechnungszeitraumFromInclusive?: string | null;
  abrechnungszeitraumToInclusive?: string | null;
  bezeichnung?: string | null;
  erstellDatumFromInclusive?: string | null;
  erstellDatumToInclusive?: string | null;
  erstellerMitarbeiterIdList?: string[] | null;
  objektIdList?: string[] | null;
  statusList?: AbrechnungStatus[] | null;
  typeList?: AbrechnungType[] | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  abrechnungszeitraumFromInclusive: 'abrechnungszeitraumFromInclusive',
  abrechnungszeitraumToInclusive: 'abrechnungszeitraumToInclusive',
  bezeichnung: 'bezeichnung',
  erstellDatumFromInclusive: 'erstellDatumFromInclusive',
  erstellDatumToInclusive: 'erstellDatumToInclusive',
  erstellerMitarbeiterIdList: 'erstellerMitarbeiterIdList',
  objektIdList: 'objektIdList',
  statusList: 'statusList',
  typeList: 'typeList',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TAbrechnungQueryParams => ({
  abrechnungszeitraumFromInclusive: formValues.abrechnungszeitraumFromInclusive
    ? mapFormDateValueToDateString(formValues.abrechnungszeitraumFromInclusive)
    : undefined,
  abrechnungszeitraumToInclusive: formValues.abrechnungszeitraumToInclusive
    ? mapFormDateValueToDateString(formValues.abrechnungszeitraumToInclusive)
    : undefined,
  bezeichnung: formValues.bezeichnung,
  erstellDatumFromInclusive: formValues.erstellDatumFromInclusive ? mapFormDateValueToDateString(formValues.erstellDatumFromInclusive) : undefined,
  erstellDatumToInclusive: formValues.erstellDatumToInclusive ? mapFormDateValueToDateString(formValues.erstellDatumToInclusive) : undefined,
  erstellerMitarbeiterIdList: formValues.erstellerMitarbeiterIdList,
  objektIdList: formValues.objektIdList,
  statusList: formValues.statusList,
  typeList: formValues.typeList,
});

export const mapQueryParamsToFormValues = (queryParams: TAbrechnungQueryParams): FiltersFormValues => ({
  abrechnungszeitraumFromInclusive: queryParams.abrechnungszeitraumFromInclusive,
  abrechnungszeitraumToInclusive: queryParams.abrechnungszeitraumToInclusive,
  bezeichnung: queryParams.bezeichnung,
  erstellDatumFromInclusive: queryParams.erstellDatumFromInclusive,
  erstellDatumToInclusive: queryParams.erstellDatumToInclusive,
  erstellerMitarbeiterIdList: queryParams.erstellerMitarbeiterIdList,
  objektIdList: queryParams.objektIdList,
  statusList: queryParams.statusList,
  typeList: queryParams.typeList,
});

export const mapQueryParamsToAbrechnungListQueryVariables = (queryParams: TAbrechnungQueryParams): QueryGetAbrechnungListArgs => ({
  ...queryParams,
  bezeichnung: queryParams.bezeichnung?.toString(),
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'erstellDatumToInclusive' | 'abrechnungszeitraumToInclusive'>> = {
  [filtersFormFields.abrechnungszeitraumFromInclusive]: 'Zeitraum',
  [filtersFormFields.bezeichnung]: 'Bezeichnung',
  [filtersFormFields.erstellDatumFromInclusive]: 'Erstellt am',
  [filtersFormFields.erstellerMitarbeiterIdList]: 'Ersteller',
  [filtersFormFields.objektIdList]: 'Objekt',
  [filtersFormFields.statusList]: 'Status',
  [filtersFormFields.typeList]: 'Abrechnungstyp',
};
