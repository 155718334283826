import React, { FC } from 'react';
import { Button, Divider, Drawer, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EllipsisOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import FirmendatenUpdateForm from '../form/FirmendatenUpdateForm';
import { useToggle } from '../../../../hooks/useToggle';
import CardWithFooter from '../../../../components/Card';
import {
  useDeleteFirmendatenMutation,
  useEntsperrenFirmendatenMutation,
  useRestoreDefaultFirmeneinstellungMutation,
  useSperrenFirmendatenMutation,
} from '../gql/FirmendatenMutations.types';
import { showSuccessMsgCreate } from '../../../../components/message';
import FirmeneinstellungForm from '../Firmeneinstellung/FirmeneinstellungForm';
import { useGoBack } from '../../../../shared/GoBack/GoBackContext';
import { isStatusDeleted, isStatusGesperrt } from '../../../../helpers/statusHelper';
import CardTitle from '../../../../components/Card/CardTitle';
import FirmendatenDetailedInfo from './FirmendatenDetailedInfo';
import FirmendatenBankDetails from '../../../BankDetails/FirmendatenBankDetails/FirmendatenBankDetails';
import { Firmendaten } from '../../../../types';
import { URI_DASHBOARD_WORKSPACE_PAGE } from '../../../../constants/appUriPaths';

type FirmenCardProps = {
  firmendaten: Firmendaten;
  onSuccess: () => void;
};

const FirmendatenCard: FC<FirmenCardProps> = ({ firmendaten, onSuccess }) => {
  const goBack = useGoBack(URI_DASHBOARD_WORKSPACE_PAGE);
  const [isCollapsedFirmendatenUpdate, onCollapseFirmendatenUpdate] = useToggle();
  const [isCollapsedFirmendateneinstellungenUpdate, onCollapseFirmendatenEinstellungenUpdate] = useToggle();

  const [runRestoreDefault] = useRestoreDefaultFirmeneinstellungMutation({
    variables: { firmendatenId: firmendaten.firmendatenId },
    onCompleted: () => {
      showSuccessMsgCreate(`Einstellungen erfolgreich zurückgesetzt`);
      onSuccess();
    },
  });

  const [runDelete] = useDeleteFirmendatenMutation({
    variables: { firmendatenId: firmendaten.firmendatenId },
    onCompleted: () => {
      showSuccessMsgCreate(`Admin erfolgreich gelöscht`);
      onSuccess();
      goBack();
    },
  });

  const [runSperren] = useSperrenFirmendatenMutation({
    variables: { firmendatenId: firmendaten.firmendatenId },
    onCompleted: () => {
      showSuccessMsgCreate(`Firmendaten erfolgreich gesperrt`);
      onSuccess();
    },
  });

  const [runEntsperren] = useEntsperrenFirmendatenMutation({
    variables: { firmendatenId: firmendaten.firmendatenId },
    onCompleted: () => {
      showSuccessMsgCreate(`Firmendaten erfolgreich entsperrt`);
      onSuccess();
    },
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Firmendaten bearbeiten', onClick: onCollapseFirmendatenUpdate },
    { key: '2', label: 'Einstellungen bearbeiten', onClick: onCollapseFirmendatenEinstellungenUpdate },
    { key: '3', label: 'Standardeinstellungen wiederherstellen', onClick: () => runRestoreDefault() },
    {
      key: '4',
      label: isStatusGesperrt(firmendaten.status) ? 'Entsperren' : 'Sperren',
      onClick: () => showConfirmLock(firmendaten, runSperren, runEntsperren),
      icon: isStatusGesperrt(firmendaten.status) ? <UnlockOutlined /> : <LockOutlined />,
      disabled: isStatusDeleted(firmendaten.status),
    },
    {
      key: '5',
      label: 'Firmendaten löschen',
      onClick: () => showConfirmDelete(firmendaten, runDelete),
      icon: <DeleteOutlined />,
      disabled: isStatusDeleted(firmendaten.status),
      danger: true,
    },
  ];

  return (
    <>
      <CardWithFooter
        style={{ minWidth: '320px' }}
        title={<CardTitle title={firmendaten.kurzBezeichnung} status={firmendaten.status} />}
        extra={
          <Dropdown menu={{ items }}>
            <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
          </Dropdown>
        }
        size="default"
        bordered={false}
        createTs={firmendaten.createTs}
        loading={false}
      >
        <FirmendatenDetailedInfo firmendaten={firmendaten} />
        <Divider dashed />
        <FirmendatenBankDetails firmendatenId={firmendaten.firmendatenId} />
      </CardWithFooter>
      <Drawer
        title="Firma bearbeiten"
        width={720}
        open={isCollapsedFirmendatenUpdate}
        onClose={onCollapseFirmendatenUpdate}
        footer={null}
        destroyOnClose
      >
        <FirmendatenUpdateForm
          firmendaten={firmendaten}
          onSuccess={() => {
            onSuccess();
            onCollapseFirmendatenUpdate();
          }}
          onCancel={onCollapseFirmendatenUpdate}
        />
      </Drawer>
      <Modal
        title="Firmeneinstellungen bearbeiten"
        open={isCollapsedFirmendateneinstellungenUpdate}
        onCancel={onCollapseFirmendatenEinstellungenUpdate}
        footer={null}
        destroyOnClose
        maskClosable={false}
      >
        <FirmeneinstellungForm
          firmendaten={firmendaten}
          onSuccess={() => {
            onCollapseFirmendatenEinstellungenUpdate();
            onSuccess();
          }}
          onCancel={onCollapseFirmendatenEinstellungenUpdate}
        />
      </Modal>
    </>
  );
};

const showConfirmLock = (firmendaten: Firmendaten, runSperren: () => void, runEntsperren: () => void) => {
  Modal.confirm({
    title: isStatusGesperrt(firmendaten.status) ? `Möchten Sie die Firmendaten entsperren?` : `Möchten Sie die Firmendaten sperren?`,
    content: `${firmendaten.kurzBezeichnung}`,
    okText: isStatusGesperrt(firmendaten.status) ? `Entsperren` : 'Sperren',
    cancelText: 'Nein',
    onOk() {
      if (isStatusGesperrt(firmendaten.status)) {
        return runEntsperren();
      } else {
        return runSperren();
      }
    },
  });
};

const showConfirmDelete = (firmendaten: Firmendaten, runDelete: () => void) => {
  Modal.confirm({
    title: `Möchten Sie die Firmendaten löschen?`,
    content: `${firmendaten.kurzBezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default FirmendatenCard;
