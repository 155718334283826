import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { ZinslisteStatus } from '../../../../types';

type Props = SelectProps;

const ZStatusFormSelect: FC<Props> = ({ ...restProps }) => {
  const statusList = [
    { value: ZinslisteStatus.Invalid, text: 'Ungültig' },
    { value: ZinslisteStatus.Valid, text: 'Gültig' },
    { value: ZinslisteStatus.UnderReview, text: 'in Prüfung' },
  ];

  return (
    <Select id={restProps.name} placeholder="" size="middle" allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {statusList.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          <Typography.Text>{status.text}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default ZStatusFormSelect;
