import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { VerwendungVonRepFondsTuple } from '../../shared/AbrKreisKontenzuordnungHelpers';

type Props = SelectProps;

const VerwendungRepFondsSelect: FC<Props> = ({ ...restProps }) => {
  const data = [
    { value: VerwendungVonRepFondsTuple.UST_VOM_AUFWAND, text: 'Ust.vom Aufwand' },
    { value: VerwendungVonRepFondsTuple.AUSGABEN, text: 'Ausgaben' },
  ];

  return (
    <Select id={restProps.name} allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {data.map((vRepFonds) => (
        <Select.Option key={vRepFonds.value} value={vRepFonds.value}>
          {vRepFonds.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default VerwendungRepFondsSelect;
