import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import CurrentBasedData from '../../../../components/Helpers/CurrentBasedData';
import { EuroAmount } from '../../../../components/Number';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { generatePathToBeVertragDetailsPageOrDiscrepancies } from '../../../Vertrag/BeVertrag/beVertragUriPaths';
import { BeVertragListEntry } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

export const bestandseinheitVertragTableColumns = (objektId: string, bestandseinheitId: string): ColumnProps<BeVertragListEntry>[] => [
  {
    title: 'Vertrag',
    render: (text, record) => (
      <CurrentBasedData
        text={record.vertragsart.text}
        status={record.status}
        isCurrent={record.current}
        activeFrom={record.vertragsBeginn}
        warningList={record.warningList}
      />
    ),
  },
  {
    title: 'Vertragspartner',
    ellipsis: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.vertragspartner.rechtstraegerId}
        rechtstraegerName={record.vertragspartner.kurzBezeichnung}
        maxTextLength={20}
        isCurrent={record.current}
        status={record.vertragspartner.status}
      />
    ),
  },
  {
    title: 'von',
    width: 100,
    render: (vertragsBeginn, record) => (
      <CurrentBasedData text="" status={record.status} isCurrent={record.current}>
        <CustomFormattedDate value={record.vertragsBeginn} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Netto',
    align: 'right',
    render: (text, record) => (
      <CurrentBasedData text="" status={record.status} isCurrent={record.current}>
        <EuroAmount value={record.sumNetto} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Ust',
    align: 'right',
    render: (text, record) => (
      <CurrentBasedData text="" status={record.status} isCurrent={record.current}>
        <EuroAmount value={record.sumUst} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Brutto',
    align: 'right',
    render: (text, record) => (
      <CurrentBasedData text="" status={record.status} isCurrent={record.current}>
        <EuroAmount value={record.sumBrutto} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Status',
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Aktion',
    render: (text, record) => (
      <Link
        key={record.vertragId}
        to={generatePathToBeVertragDetailsPageOrDiscrepancies(objektId, bestandseinheitId, record.vertragId, record.status)}
      >
        Details
      </Link>
    ),
  },
];
