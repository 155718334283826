import { generatePath } from 'react-router-dom';
import { mapToQueryString, QueryParams } from '../../hooks/useStringQueryParams';
import { KontoblattListingFiltersFormValues } from './Filters/kontoblattTableFiltersFormMapper';

export enum KontoblattPageQueryParamKey {
  BUCHUNGSKREIS_ID = 'buchungskreisId',
  KONTO_ID = 'kontoId',
  BUCHUNGSDATUM_FROM = 'buchungsdatumFrom',
  BUCHUNGSDATUM_TO = 'buchungsdatumTo',
  OBJEKT_ID = 'objektId',
  BELEGDATUM_FROM = 'belegdatumFrom',
  BELEGDATUM_TO = 'belegdatumTo',
  BELEGNUMMER = 'belegnummer',
  BELEG_SYMBOL = 'belegSymbol',
  BUCHUNGS_TYPE_LIST = 'buchungsTypeList',
  ERSTELLER_MITARBEITER_ID_LIST = 'erstellerMitarbeiterIdList',
  OFFENER_OP_BETRAG = 'offenerOPBetrag',
  GEGEN_KONTO_ID = 'gegenKontoId',
  VERTRAGSPARTNER_ID = 'vertragspartnerId',
  INCLUDE_STORNO = 'includeStorno',
}

export type KontoblattPageQueryParams = QueryParams<KontoblattListingFiltersFormValues>;

export const URI_KONTOBLATT_PAGE = '/dashboard/kontoblatt';

export const generatePathToKontoblattPage = (queryParams: KontoblattPageQueryParams) =>
  `${generatePath(URI_KONTOBLATT_PAGE)}?${mapToQueryString({
    [KontoblattPageQueryParamKey.BUCHUNGSKREIS_ID]: queryParams.buchungskreisId,
    [KontoblattPageQueryParamKey.KONTO_ID]: queryParams.kontoId,
    [KontoblattPageQueryParamKey.BUCHUNGSDATUM_FROM]: queryParams.buchungsdatumFrom,
    [KontoblattPageQueryParamKey.BUCHUNGSDATUM_TO]: queryParams.buchungsdatumTo,
    [KontoblattPageQueryParamKey.OBJEKT_ID]: queryParams.objektId,
    [KontoblattPageQueryParamKey.BELEGDATUM_FROM]: queryParams.belegdatumFrom,
    [KontoblattPageQueryParamKey.BELEGDATUM_TO]: queryParams.belegdatumTo,
    [KontoblattPageQueryParamKey.BELEGNUMMER]: queryParams.belegnummer,
    [KontoblattPageQueryParamKey.BELEG_SYMBOL]: queryParams.belegSymbol,
    [KontoblattPageQueryParamKey.BUCHUNGS_TYPE_LIST]: queryParams.buchungsTypeList,
    [KontoblattPageQueryParamKey.ERSTELLER_MITARBEITER_ID_LIST]: queryParams.erstellerMitarbeiterIdList,
    [KontoblattPageQueryParamKey.OFFENER_OP_BETRAG]: queryParams.offenerOPBetrag,
    [KontoblattPageQueryParamKey.GEGEN_KONTO_ID]: queryParams.gegenKontoId,
    [KontoblattPageQueryParamKey.VERTRAGSPARTNER_ID]: queryParams.vertragspartnerId,
    [KontoblattPageQueryParamKey.INCLUDE_STORNO]: queryParams.includeStorno,
  })}`;
