import React, { FC } from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const DeviationListGroupedBySourceEntityActions: FC = () => (
  <Dropdown menu={{ items }} trigger={['click']}>
    <Button icon={<EllipsisOutlined />} shape="circle" size="small" onClick={(e) => e.stopPropagation()} />
  </Dropdown>
);

const items: MenuProps['items'] = [
  { key: '1', label: 'Alle Korrekturen übernehmen' },
  { key: '2', label: 'Alle Korrekturen ignorieren' },
];

export default DeviationListGroupedBySourceEntityActions;
