import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import KontierungstabelleTemplateForm from './Form/KontierungstabelleTemplateForm';

type Props = {
  refetch: () => void;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const KontierungstabelleTemplateBtnAndDrawer: FC<Props> = ({ refetch, showArchived, toggleShowArchived }) => {
  const [isOpen, toggleOpen] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button onClick={toggleShowArchived}>Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}</Button>
        <Button icon={<PlusOutlined />} type="primary" onClick={toggleOpen}>
          Kontierungstabelle anlegen
        </Button>
      </ButtonsAligned>
      <Drawer title="Kontierungstabelle anlegen" width={720} onClose={toggleOpen} open={isOpen} destroyOnClose>
        <KontierungstabelleTemplateForm
          onSuccess={() => {
            toggleOpen();
            refetch();
          }}
          onCancel={toggleOpen}
        />
      </Drawer>
    </>
  );
};

export default KontierungstabelleTemplateBtnAndDrawer;
