import { ObjectOfStrings } from '../../../../shared/typeHelpers';
import { FormFields } from '../../../../helpers/formikHelper';
import { TPaymentClearingQueryParams } from './filtersQueryParams';
import { KontoVerwendung } from '../../../../types';

export type FiltersFormValues = {
  availableAmountFrom?: number | null;
  availableAmountTo?: number | null;
  buchungskreisId?: string | null;
  kontoId?: string | null;
  kontoVerwendung?: KontoVerwendung | null;
  outstandingAmountFrom?: number | null;
  outstandingAmountTo?: number | null;
  setDefaultValuesOnInit?: boolean | null;
  vertragspartnerId?: string | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  availableAmountFrom: 'availableAmountFrom',
  availableAmountTo: 'availableAmountTo',
  buchungskreisId: 'buchungskreisId',
  kontoId: 'kontoId',
  kontoVerwendung: 'kontoVerwendung',
  outstandingAmountFrom: 'outstandingAmountFrom',
  outstandingAmountTo: 'outstandingAmountTo',
  setDefaultValuesOnInit: 'setDefaultValuesOnInit',
  vertragspartnerId: 'vertragspartnerId',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TPaymentClearingQueryParams => ({
  availableAmountFrom: formValues.availableAmountFrom,
  availableAmountTo: formValues.availableAmountTo,
  buchungskreisId: formValues.buchungskreisId,
  kontoId: formValues.kontoId,
  kontoVerwendung: formValues.kontoVerwendung,
  outstandingAmountFrom: formValues.outstandingAmountFrom,
  outstandingAmountTo: formValues.outstandingAmountTo,
  vertragspartnerId: formValues.vertragspartnerId,
});

export const mapQueryParamsToFormValues = (queryParams: TPaymentClearingQueryParams): FiltersFormValues => ({
  availableAmountFrom: queryParams.availableAmountFrom ?? (queryParams.setDefaultValuesOnInit ? 0.01 : undefined),
  availableAmountTo: queryParams.availableAmountTo,
  buchungskreisId: queryParams.buchungskreisId,
  kontoId: queryParams.kontoId,
  kontoVerwendung: queryParams.kontoVerwendung,
  outstandingAmountFrom: queryParams.outstandingAmountFrom,
  outstandingAmountTo: queryParams.outstandingAmountTo,
  vertragspartnerId: queryParams.vertragspartnerId,
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'availableAmountTo' | 'outstandingAmountTo' | 'setDefaultValuesOnInit'>> = {
  [filtersFormFields.availableAmountFrom]: 'Verfügbarer Zahlungsbetrag',
  [filtersFormFields.buchungskreisId]: 'Buchungskreis',
  [filtersFormFields.kontoId]: 'Vertragspartner Fibu-Konto',
  [filtersFormFields.kontoVerwendung]: 'Debitor/Kreditor',
  [filtersFormFields.outstandingAmountFrom]: 'Offener Betrag des OPs',
  [filtersFormFields.vertragspartnerId]: 'Vertragspartner',
};
