import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_DASHBOARD_OBJEKT_BE_VERTRAG_DETAILS_PAGE } from '../../features/Vertrag/BeVertrag/beVertragUriPaths';
import BeVertragDetailsPage from './BeVertragDetailsPage';

export type BeVertragDetailsPageRouteParams = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  vorschreibungspositionId?: string;
};

const beVertragDetailsPageRoute = (
  <Route
    path={`${URI_DASHBOARD_OBJEKT_BE_VERTRAG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BeVertragDetailsPage />} />}
  />
);

export default beVertragDetailsPageRoute;
