import { dayjsCustom } from '../../../../../../helpers/dayjsCustom';
import { AbrechnungGenerierlaufType, AbrechnungType, ObjektAbrechnung, TopAbrechnungGenerierlaufCreateInput } from '../../../../../../types';
import { isAbrechnungTypeBkAbrechnung } from '../../../../shared/abrechnungHelpers';

export type TopAbrechnungCreateFormValues = {
  fakturierungsperiode: string;
};

export const topAbrechnungsCreateFormInitialValues: TopAbrechnungCreateFormValues = {
  fakturierungsperiode: dayjsCustom().format(),
};

export const mapFormValuesToTopAbrechnungCreateInput = (
  abrechnung: ObjektAbrechnung,
  formValues: TopAbrechnungCreateFormValues,
  objektId: string
): TopAbrechnungGenerierlaufCreateInput => ({
  abrechnungType: getTopAbrechnungType(abrechnung.type.value),
  objektAbrechnungIdList: [abrechnung.objektAbrechnungId],
  objektIdList: [objektId],
  type: AbrechnungGenerierlaufType.TopAbrechnungGenerierlauf,
  fakturierungsperiode: {
    jahr: dayjsCustom(formValues.fakturierungsperiode).year(),
    monat: dayjsCustom(formValues.fakturierungsperiode).month() + 1,
  },
});

const getTopAbrechnungType = (abrechnungType: AbrechnungType): AbrechnungType =>
  isAbrechnungTypeBkAbrechnung(abrechnungType) ? AbrechnungType.BkTopAbrechnung : AbrechnungType.WeTopAbrechnung;
