import { FC } from 'react';
import { useAuth } from '../../shared/Auth/authContext';
import compareFieldHelpFiles from './compareFieldHelpFiles';

// Check for the field help selectors list discrepancies - only for logged in users
const FieldHelpComparison: FC = () => {
  const { user } = useAuth();
  if (user) {
    compareFieldHelpFiles().then(() => {});
  }
  return null;
};

export default FieldHelpComparison;
