import React, { FC } from 'react';
import { useTicketQuery } from './gql/TicketQueries.types';
import { BreadcrumbLoadingText } from '../Breadcrumb/pathToBreadcrumbMappings';
import { TicketBaseFieldsFragment, TicketFieldsFragment } from './gql/TicketFragments.types';
import { getObjektInfoFeldShortName } from '../Objekt/InfoFeld/List/objektInfoFeldListHelpers';
import { getBeInfoFeldShortName } from '../Bestandseinheit/InfoFeld/List/beInfoFeldListHelpers';
import { useBeVertragKurzBezeichnungQuery } from '../Breadcrumb/gql/KurzbezeichnungQueries.types';
import { shortenTextIfNeeded } from '../../helpers/stringHelper';
import {
  getVertragInfoFeldKuendigungsinfosShortName,
  getVertragInfoFeldWeiterePersonShortName,
  isVertragInfoFeldKuendigungsinfos,
  isVertragInfoFeldWeiterePerson,
} from '../Vertrag/BeVertrag/InfoFeld/List/vertragInfoFeldListHelpers';

type Props = {
  ticketId: string;
};

const TicketDetailsPageBreadcrumb: FC<Props> = ({ ticketId }) => {
  const { loading: loadingTicket, data: ticketData } = useTicketQuery({ variables: { ticketId } });
  const ticket = ticketData?.getTicket.data;
  let ticketTitle = ticket?.title ? shortenTextIfNeeded(ticket.title, 100) : '';

  if (ticket?.objekt && ticket?.bestandseinheit && ticket?.vertrag && ticket?.vertragInfoFeld) {
    return (
      <TicketVertragInfoFeldBreadcrumb
        objektId={ticket.objekt.objektId}
        bestandseinheitId={ticket.bestandseinheit.bestandseinheitId}
        vertragId={ticket.vertrag.vertragId}
        ticketTitle={ticketTitle}
        vertragInfoFeld={ticket.vertragInfoFeld}
      />
    );
  }
  if (ticket?.objekt && ticket?.bestandseinheit && ticket?.vertrag) {
    return (
      <TicketVertragBreadcrumb
        objektId={ticket.objekt.objektId}
        bestandseinheitId={ticket.bestandseinheit.bestandseinheitId}
        vertragId={ticket.vertrag.vertragId}
        ticketTitle={ticketTitle}
      />
    );
  } else if (ticket?.objekt && ticket?.bestandseinheit && ticket?.beInfoFeld) {
    ticketTitle = getTicketBeInfoFeldBreadcrumbText(ticketTitle, ticket.objekt, ticket.bestandseinheit, ticket.beInfoFeld);
  } else if (ticket?.objekt && ticket?.bestandseinheit) {
    ticketTitle = getTicketBestandseinheitBreadcrumbText(ticketTitle, ticket.objekt, ticket.bestandseinheit);
  } else if (ticket?.objekt && ticket.objektInfoFeld) {
    ticketTitle = getTicketObjektInfoFeldBreadcrumbText(ticketTitle, ticket.objekt, ticket.objektInfoFeld);
  } else if (ticket?.objekt) {
    ticketTitle = getTicketObjektBreadcrumbText(ticketTitle, ticket.objekt, ticket.parent);
  } else if (ticket?.rechtstraeger && ticket?.rechtstraegerInfoFeld) {
    ticketTitle = getTicketRechtstraegerInfoFeldBreadcrumbText(ticketTitle, ticket.rechtstraeger, ticket.rechtstraegerInfoFeld);
  } else if (ticket?.rechtstraeger) {
    ticketTitle = getTicketRechtstraegerBreadcrumbText(ticketTitle, ticket.rechtstraeger);
  }

  return <BreadcrumbLoadingText data={ticketData} loading={loadingTicket} text={ticketTitle} />;
};

const getTicketObjektBreadcrumbText = (
  ticketTitle: string,
  objekt: NonNullable<TicketFieldsFragment['objekt']>,
  parentTicket?: TicketBaseFieldsFragment | null
) => {
  const parentTicketTitle = parentTicket?.title ?? '';
  const objektKurzBezeichnung = objekt.kurzBezeichnung;
  return parentTicket ? ` ${objektKurzBezeichnung} - ${parentTicketTitle} / ${ticketTitle}` : ` ${objektKurzBezeichnung} - ${ticketTitle}`;
};

const getTicketObjektInfoFeldBreadcrumbText = (
  ticketTitle: string,
  objekt: NonNullable<TicketFieldsFragment['objekt']>,
  objektInfoFeld: NonNullable<TicketFieldsFragment['objektInfoFeld']>
) => {
  const objektKurzBezeichnung = objekt.kurzBezeichnung;
  const infoFeldBezeichnung = `${objektInfoFeld.type.text}: ${getObjektInfoFeldShortName(objektInfoFeld)}`;

  return ` ${objektKurzBezeichnung} - ${infoFeldBezeichnung} - ${ticketTitle}`;
};

const getTicketBestandseinheitBreadcrumbText = (
  ticketTitle: string,
  objekt: NonNullable<TicketFieldsFragment['objekt']>,
  bestandseinheit: NonNullable<TicketFieldsFragment['bestandseinheit']>
) => {
  const objektKurzBezeichnung = objekt.kurzBezeichnung;
  const bestandseinheitBezeichnung = bestandseinheit.bezeichnung;
  return `${objektKurzBezeichnung} - ${bestandseinheitBezeichnung} - ${ticketTitle}`;
};

const getTicketBeInfoFeldBreadcrumbText = (
  ticketTitle: string,
  objekt: NonNullable<TicketFieldsFragment['objekt']>,
  bestandseinheit: NonNullable<TicketFieldsFragment['bestandseinheit']>,
  beInfoFeld: NonNullable<TicketFieldsFragment['beInfoFeld']>
) => {
  const objektKurzBezeichnung = objekt.kurzBezeichnung;
  const bestandseinheitBezeichnung = bestandseinheit.bezeichnung;
  const infoFeldBezeichnung = `${beInfoFeld.type.text}: ${getBeInfoFeldShortName(beInfoFeld)}`;

  return `${objektKurzBezeichnung} - ${bestandseinheitBezeichnung} - ${infoFeldBezeichnung} - ${ticketTitle}`;
};

const getTicketRechtstraegerBreadcrumbText = (ticketTitle: string, rechtstraeger: NonNullable<TicketFieldsFragment['rechtstraeger']>) => {
  const rechtstraegerKurzBezeichnung = rechtstraeger.kurzBezeichnung;
  return `${rechtstraegerKurzBezeichnung} - ${ticketTitle}`;
};

const getTicketRechtstraegerInfoFeldBreadcrumbText = (
  ticketTitle: string,
  rechtstraeger: NonNullable<TicketFieldsFragment['rechtstraeger']>,
  rechtstraegerInfoFeld: NonNullable<TicketFieldsFragment['rechtstraegerInfoFeld']>
) => {
  const rechtstraegerKurzBezeichnung = rechtstraeger.kurzBezeichnung;
  const infoFeldBezeichnung = `${rechtstraegerInfoFeld.type.text}: ${rechtstraegerInfoFeld.merkmalList[0]}`;

  return `${rechtstraegerKurzBezeichnung} - ${infoFeldBezeichnung} - ${ticketTitle}`;
};

const TicketVertragBreadcrumb: FC<{ objektId: string; bestandseinheitId: string; vertragId: string; ticketTitle: string }> = ({
  objektId,
  bestandseinheitId,
  vertragId,
  ticketTitle,
}) => {
  const { loading: loadingVertrag, data: vertragData } = useBeVertragKurzBezeichnungQuery({
    variables: { objektId, bestandseinheitId, vertragId, showObjekt: true, showBestandseinheit: true },
  });
  const vertragKurzBezeichnung = vertragData?.getBeVertragKurzBezeichnung.data;

  const loading = loadingVertrag;
  const data = vertragData;
  const refText = vertragKurzBezeichnung ?? '';

  const ticketBreadcrumbText = data ? `${refText} - ${ticketTitle}` : '';

  return <BreadcrumbLoadingText data={data} loading={loading} text={ticketBreadcrumbText} />;
};

const TicketVertragInfoFeldBreadcrumb: FC<{
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  ticketTitle: string;
  vertragInfoFeld: NonNullable<TicketFieldsFragment['vertragInfoFeld']>;
}> = ({ objektId, bestandseinheitId, vertragId, ticketTitle, vertragInfoFeld }) => {
  const { loading: loadingVertrag, data: vertragData } = useBeVertragKurzBezeichnungQuery({
    variables: { objektId, bestandseinheitId, vertragId, showObjekt: true, showBestandseinheit: true },
  });
  const vertragKurzBezeichnung = vertragData?.getBeVertragKurzBezeichnung.data;

  const loading = loadingVertrag;
  const data = vertragData;
  const refText = vertragKurzBezeichnung ?? '';

  let infoFeldBezeichnung;

  if (isVertragInfoFeldWeiterePerson(vertragInfoFeld)) {
    infoFeldBezeichnung = `${vertragInfoFeld.type.text}: ${getVertragInfoFeldWeiterePersonShortName(vertragInfoFeld)}`;
  } else if (isVertragInfoFeldKuendigungsinfos(vertragInfoFeld)) {
    infoFeldBezeichnung = (
      <>
        {vertragInfoFeld.type.text}: {getVertragInfoFeldKuendigungsinfosShortName(vertragInfoFeld)}
      </>
    );
  }

  const ticketBreadcrumbText = data ? (
    <>
      {refText} - {infoFeldBezeichnung} - {ticketTitle}
    </>
  ) : (
    ''
  );

  return <BreadcrumbLoadingText data={data} loading={loading} text={ticketBreadcrumbText} />;
};

export default TicketDetailsPageBreadcrumb;
