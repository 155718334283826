import React, { FC } from 'react';
import { useBeOptionSteuerpflichtVersionListQuery } from '../gql/BeOptionSteuerpflichtQueries.types';
import BeOptionSteuerpflichtVersionCardContent from './BeOptionSteuerpflichtVersionCardContent';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import { mapToVersionTimelineDataSourceWithAlwaysDeletableEntries } from '../../../../components/Timeline/versionTimelineHelper';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useDeleteBeOptionSteuerpflichtVersionMutation } from '../gql/BeOptionSteuerpflichtMutations.types';
import BeOptionSteuerpflichtVersionForm from './Form/BeOptionSteuerpflichtVersionForm';
import { BeOptionSteuerpflichtVersion } from '../../../../types';

type BeOptionSteuerpflichtVersionTimelineProps = {
  bestandseinheitId: string;
  objektId: string;
  reloadBestandseinheit: () => void;
};

const BeOptionSteuerpflichtVersionTimeline: FC<BeOptionSteuerpflichtVersionTimelineProps> = ({
  bestandseinheitId,
  reloadBestandseinheit,
  objektId,
}) => {
  const { data, loading, refetch } = useBeOptionSteuerpflichtVersionListQuery({
    variables: {
      objektId,
      bestandseinheitId,
    },
  });

  const onActionSuccess = () => {
    reloadBestandseinheit();
    refetch();
  };

  const [runDelete] = useDeleteBeOptionSteuerpflichtVersionMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Steuerpflicht`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const beOptionSteuerpflichVersionList = data?.getBeOptionSteuerpflichtVersionList.data ?? [];

  return (
    <VersionTimeline<BeOptionSteuerpflichtVersion>
      dataSource={mapToVersionTimelineDataSourceWithAlwaysDeletableEntries(beOptionSteuerpflichVersionList)}
      isAllowedToDeleteInitialTimeblock
      loading={loading}
      versionIdKey="beOptionSteuerpflichtId"
      defaultNewTimeblock={{
        beOptionSteuerpflichtId: beOptionSteuerpflichVersionList[0]?.beOptionSteuerpflichtId,
        optionSteuerpflicht: beOptionSteuerpflichVersionList[0]?.optionSteuerpflicht ?? false,
        warningList: beOptionSteuerpflichVersionList[0]?.warningList,
      }}
      renderForm={(timeblock) => (
        <BeOptionSteuerpflichtVersionForm
          timeblock={timeblock}
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          onActionSuccess={onActionSuccess}
        />
      )}
      renderCardContent={(timeblock) => <BeOptionSteuerpflichtVersionCardContent timeblock={timeblock} />}
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      onDelete={(timeblock) => {
        return runDelete({
          variables: {
            objektId,
            bestandseinheitId,
            beOptionSteuerpflichtVersionId: timeblock.beOptionSteuerpflichtId,
          },
        });
      }}
    />
  );
};

export default BeOptionSteuerpflichtVersionTimeline;
