import { useState } from 'react';

export const useTableExpandedRowKeys = (keys?: string[]) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>(keys ?? []);

  const toggleExpandedRowKey = (rowKey: string) => {
    if (!expandedRowKeys.includes(rowKey)) {
      setExpandedRowKeys([...expandedRowKeys, rowKey]);
    } else {
      const updatedExpandedRowKeys = expandedRowKeys.filter((value) => value !== rowKey);
      setExpandedRowKeys(updatedExpandedRowKeys);
    }
  };

  return { expandedRowKeys, toggleExpandedRowKey, setExpandedRowKeys };
};
