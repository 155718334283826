import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { useTicketTemplateListQuery } from '../../TicketTemplate/gql/TicketTemplateQueries.types';
import { TicketTemplate } from '../../../types';

type Props = {
  onSelectTemplate?: (ticketTemplate: TicketTemplate | undefined) => void;
} & SelectProps;

const TicketTemplateSelect: FC<Props> = ({ onSelectTemplate, ...props }) => {
  const { data, loading } = useTicketTemplateListQuery();

  const ticketTemplateList = data?.getTicketTemplateList.data || [];

  const handleSelect = (value: string) => {
    const findTicketTemplate = ticketTemplateList.find((ticketTemplate) => ticketTemplate.ticketTemplateId === value);
    onSelectTemplate?.(findTicketTemplate);
  };

  return (
    <Select loading={loading} size="small" id={props.name} placeholder="Art auswählen" {...props} onSelect={(value) => handleSelect(value)}>
      {ticketTemplateList.map(({ ticketTemplateId, name }) => {
        return (
          <Select.Option key={ticketTemplateId} value={ticketTemplateId}>
            {name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default TicketTemplateSelect;
