import { Divider, Tag, Typography } from 'antd';
import React, { FC } from 'react';

type Props = {
  tagList: string[];
};

const InnerCardTagList: FC<Props> = ({ tagList }) => {
  return (
    <>
      <Typography.Title level={5}>Merkmale</Typography.Title>
      {tagList.map((tag) => (
        <Tag key={tag}>{tag}</Tag>
      ))}
      <Divider style={{ marginTop: '8px', marginBottom: '8px' }} dashed />
    </>
  );
};

export default InnerCardTagList;
