import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { VertragVposIndexedValueGenerierlaufEntryFieldsFragmentDoc } from './VertragVposIndexedValueGenerierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VertragVposIndexedValueGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type VertragVposIndexedValueGenerierlaufQuery = {
  getVertragVposIndexedValueGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus; description?: string | null };
      type: { description?: string | null; text: string; value: Types.VertragVposIndexedValueGenerierlaufType };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VertragVposIndexedValueGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bestandseinheitIdList?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>
  >;
  vertragId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  valuePreservationType?: Types.InputMaybe<Types.ValuePreservationType>;
  indexSeriesId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.VertragVposIndexedValueGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type VertragVposIndexedValueGenerierlaufEntryListQuery = {
  getVertragVposIndexedValueGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        bestandseinheitBezeichnung: string;
        bestandseinheitId: string;
        currentStep: number;
        generierlaufEntryId: string;
        indexSeriesId: string;
        indexSeriesName: string;
        indexationAgreementId: string;
        infoMessage?: string | null;
        objektId: string;
        objektKurzBezeichnung?: string | null;
        rechnungsAusstellerBezeichnung: string;
        rechnungsAusstellerId: string;
        retry: boolean;
        stepCount: number;
        vertragBezeichnung: string;
        vertragId: string;
        vertragspartnerId: string;
        vertragspartnerKurzBezeichnung: string;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        indexedValue?: {
          amountNew: number;
          amountOld: number;
          bestandseinheitBezeichnung: string;
          bestandseinheitId: string;
          calculatedAmountNew: number;
          calculatedFactorNew: number;
          calculatedFactorNewPercent: number;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          factorNew: number;
          factorNewPercent: number;
          indexNew: number;
          indexNewPreliminary: boolean;
          indexOld: number;
          indexOldPreliminary: boolean;
          indexSeriesId: string;
          indexSeriesName: string;
          monthYearNew: string;
          monthYearOld: string;
          name: string;
          objektId: string;
          objektKurzBezeichnung: string;
          rechnungsAusstellerId: string;
          rechnungsAusstellerKurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragId: string;
          vertragKurzBezeichnung: string;
          vertragVposIndexedValueId: string;
          vertragspartnerId: string;
          vertragspartnerKurzBezeichnung: string;
          vorschreibungspositionId: string;
          vposWertNewId?: string | null;
          vposWertOldId?: string | null;
          indexationAgreement:
            | {
                __typename: 'VertragVposIndexationAgreementCategory';
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                indexOld?: number | null;
                indexOldPreliminary: boolean;
                monthYearOld: string;
                updatedByMitarbeiterId?: string | null;
                validFrom: string;
                vertragVposIndexationAgreementId: string;
                basedOnUsableArea: boolean;
                indexSeries: {
                  chainingFactor?: number | null;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  csvColumnImportId?: string | null;
                  csvColumnIndexPoint?: string | null;
                  csvColumnMonthYear?: string | null;
                  csvRowImportId?: string | null;
                  deletable: boolean;
                  firmendatenId?: string | null;
                  importUrl?: string | null;
                  indexSeriesId: string;
                  name: string;
                  updatedByMitarbeiterId?: string | null;
                  indexSeriesEntryList: Array<{
                    annualAverageFinal?: number | null;
                    annualAveragePreliminary?: number | null;
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    indexSeriesEntryId: string;
                    updatedByMitarbeiterId?: string | null;
                    year: number;
                    indexPointList: Array<{
                      createTs: string;
                      createdBy?: string | null;
                      createdByMitarbeiterId?: string | null;
                      finalValue?: number | null;
                      indexPointId: string;
                      month: string;
                      preliminaryValue?: number | null;
                      updatedByMitarbeiterId?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    }>;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  indexType: { text: string; value: Types.IndexType };
                  sourceType: { text: string; value: Types.IndexSeriesSourceType };
                  status?: { text: string; value: Types.IndexSeriesStatus } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                type: { text: string; value: Types.ValuePreservationType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }
            | {
                __typename: 'VertragVposIndexationAgreementIndexAnnual';
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                indexOld?: number | null;
                indexOldPreliminary: boolean;
                monthYearOld: string;
                updatedByMitarbeiterId?: string | null;
                validFrom: string;
                vertragVposIndexationAgreementId: string;
                annualAverage: boolean;
                minimumLimit?: number | null;
                shareInPercentToApply?: number | null;
                subsequentBilling: boolean;
                threshold: number;
                indexSeries: {
                  chainingFactor?: number | null;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  csvColumnImportId?: string | null;
                  csvColumnIndexPoint?: string | null;
                  csvColumnMonthYear?: string | null;
                  csvRowImportId?: string | null;
                  deletable: boolean;
                  firmendatenId?: string | null;
                  importUrl?: string | null;
                  indexSeriesId: string;
                  name: string;
                  updatedByMitarbeiterId?: string | null;
                  indexSeriesEntryList: Array<{
                    annualAverageFinal?: number | null;
                    annualAveragePreliminary?: number | null;
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    indexSeriesEntryId: string;
                    updatedByMitarbeiterId?: string | null;
                    year: number;
                    indexPointList: Array<{
                      createTs: string;
                      createdBy?: string | null;
                      createdByMitarbeiterId?: string | null;
                      finalValue?: number | null;
                      indexPointId: string;
                      month: string;
                      preliminaryValue?: number | null;
                      updatedByMitarbeiterId?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    }>;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  indexType: { text: string; value: Types.IndexType };
                  sourceType: { text: string; value: Types.IndexSeriesSourceType };
                  status?: { text: string; value: Types.IndexSeriesStatus } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                type: { text: string; value: Types.ValuePreservationType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                effectiveWithMonth?: { text: string; value: Types.Month } | null;
                monthOfIndexPoint: { text: string; value: Types.Month };
                subsequentBillingObjektVorschreibungsposition?: {
                  bezeichnung: string;
                  objektVorschreibungspositionId: string;
                  kurzBezeichnung: string;
                } | null;
                thresholdType: { text: string; value: Types.ThresholdType };
              }
            | {
                __typename: 'VertragVposIndexationAgreementIndexThreshold';
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                indexOld?: number | null;
                indexOldPreliminary: boolean;
                monthYearOld: string;
                updatedByMitarbeiterId?: string | null;
                validFrom: string;
                vertragVposIndexationAgreementId: string;
                effectiveAfterMonths?: number | null;
                minimumLimit?: number | null;
                shareInPercentToApply?: number | null;
                subsequentBilling: boolean;
                threshold: number;
                indexSeries: {
                  chainingFactor?: number | null;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  csvColumnImportId?: string | null;
                  csvColumnIndexPoint?: string | null;
                  csvColumnMonthYear?: string | null;
                  csvRowImportId?: string | null;
                  deletable: boolean;
                  firmendatenId?: string | null;
                  importUrl?: string | null;
                  indexSeriesId: string;
                  name: string;
                  updatedByMitarbeiterId?: string | null;
                  indexSeriesEntryList: Array<{
                    annualAverageFinal?: number | null;
                    annualAveragePreliminary?: number | null;
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    indexSeriesEntryId: string;
                    updatedByMitarbeiterId?: string | null;
                    year: number;
                    indexPointList: Array<{
                      createTs: string;
                      createdBy?: string | null;
                      createdByMitarbeiterId?: string | null;
                      finalValue?: number | null;
                      indexPointId: string;
                      month: string;
                      preliminaryValue?: number | null;
                      updatedByMitarbeiterId?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    }>;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  indexType: { text: string; value: Types.IndexType };
                  sourceType: { text: string; value: Types.IndexSeriesSourceType };
                  status?: { text: string; value: Types.IndexSeriesStatus } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                type: { text: string; value: Types.ValuePreservationType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                subsequentBillingObjektVorschreibungsposition?: {
                  bezeichnung: string;
                  objektVorschreibungspositionId: string;
                  kurzBezeichnung: string;
                } | null;
                thresholdType: { text: string; value: Types.ThresholdType };
              }
            | {
                __typename: 'VertragVposIndexationAgreementReferenceValue';
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                indexOld?: number | null;
                indexOldPreliminary: boolean;
                monthYearOld: string;
                updatedByMitarbeiterId?: string | null;
                validFrom: string;
                vertragVposIndexationAgreementId: string;
                indexSeries: {
                  chainingFactor?: number | null;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  csvColumnImportId?: string | null;
                  csvColumnIndexPoint?: string | null;
                  csvColumnMonthYear?: string | null;
                  csvRowImportId?: string | null;
                  deletable: boolean;
                  firmendatenId?: string | null;
                  importUrl?: string | null;
                  indexSeriesId: string;
                  name: string;
                  updatedByMitarbeiterId?: string | null;
                  indexSeriesEntryList: Array<{
                    annualAverageFinal?: number | null;
                    annualAveragePreliminary?: number | null;
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    indexSeriesEntryId: string;
                    updatedByMitarbeiterId?: string | null;
                    year: number;
                    indexPointList: Array<{
                      createTs: string;
                      createdBy?: string | null;
                      createdByMitarbeiterId?: string | null;
                      finalValue?: number | null;
                      indexPointId: string;
                      month: string;
                      preliminaryValue?: number | null;
                      updatedByMitarbeiterId?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    }>;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  indexType: { text: string; value: Types.IndexType };
                  sourceType: { text: string; value: Types.IndexSeriesSourceType };
                  status?: { text: string; value: Types.IndexSeriesStatus } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                type: { text: string; value: Types.ValuePreservationType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
          status: { text: string; value: Types.VertragVposIndexedValueStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        valuePreservationType: { text: string; value: Types.ValuePreservationType };
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VertragVposIndexedValueGenerierlaufDocument = gql`
  query VertragVposIndexedValueGenerierlauf($generierlaufId: ID!) {
    getVertragVposIndexedValueGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countVerarbeitet
        countNichtVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          text
          value
          description
        }
        type {
          description
          text
          value
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVertragVposIndexedValueGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<VertragVposIndexedValueGenerierlaufQuery, VertragVposIndexedValueGenerierlaufQueryVariables> &
    ({ variables: VertragVposIndexedValueGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragVposIndexedValueGenerierlaufQuery, VertragVposIndexedValueGenerierlaufQueryVariables>(
    VertragVposIndexedValueGenerierlaufDocument,
    options
  );
}
export function useVertragVposIndexedValueGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragVposIndexedValueGenerierlaufQuery, VertragVposIndexedValueGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragVposIndexedValueGenerierlaufQuery, VertragVposIndexedValueGenerierlaufQueryVariables>(
    VertragVposIndexedValueGenerierlaufDocument,
    options
  );
}
export function useVertragVposIndexedValueGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VertragVposIndexedValueGenerierlaufQuery, VertragVposIndexedValueGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragVposIndexedValueGenerierlaufQuery, VertragVposIndexedValueGenerierlaufQueryVariables>(
    VertragVposIndexedValueGenerierlaufDocument,
    options
  );
}
export type VertragVposIndexedValueGenerierlaufQueryHookResult = ReturnType<typeof useVertragVposIndexedValueGenerierlaufQuery>;
export type VertragVposIndexedValueGenerierlaufLazyQueryHookResult = ReturnType<typeof useVertragVposIndexedValueGenerierlaufLazyQuery>;
export type VertragVposIndexedValueGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useVertragVposIndexedValueGenerierlaufSuspenseQuery>;
export type VertragVposIndexedValueGenerierlaufQueryResult = Apollo.QueryResult<
  VertragVposIndexedValueGenerierlaufQuery,
  VertragVposIndexedValueGenerierlaufQueryVariables
>;
export const VertragVposIndexedValueGenerierlaufEntryListDocument = gql`
  query VertragVposIndexedValueGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $bestandseinheitIdList: [String]
    $vertragId: String
    $valuePreservationType: ValuePreservationType
    $indexSeriesId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: VertragVposIndexedValueGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getVertragVposIndexedValueGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      bestandseinheitIdList: $bestandseinheitIdList
      vertragId: $vertragId
      valuePreservationType: $valuePreservationType
      indexSeriesId: $indexSeriesId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...VertragVposIndexedValueGenerierlaufEntryFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VertragVposIndexedValueGenerierlaufEntryFieldsFragmentDoc}
`;
export function useVertragVposIndexedValueGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    VertragVposIndexedValueGenerierlaufEntryListQuery,
    VertragVposIndexedValueGenerierlaufEntryListQueryVariables
  > &
    ({ variables: VertragVposIndexedValueGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragVposIndexedValueGenerierlaufEntryListQuery, VertragVposIndexedValueGenerierlaufEntryListQueryVariables>(
    VertragVposIndexedValueGenerierlaufEntryListDocument,
    options
  );
}
export function useVertragVposIndexedValueGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VertragVposIndexedValueGenerierlaufEntryListQuery,
    VertragVposIndexedValueGenerierlaufEntryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragVposIndexedValueGenerierlaufEntryListQuery, VertragVposIndexedValueGenerierlaufEntryListQueryVariables>(
    VertragVposIndexedValueGenerierlaufEntryListDocument,
    options
  );
}
export function useVertragVposIndexedValueGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VertragVposIndexedValueGenerierlaufEntryListQuery, VertragVposIndexedValueGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragVposIndexedValueGenerierlaufEntryListQuery, VertragVposIndexedValueGenerierlaufEntryListQueryVariables>(
    VertragVposIndexedValueGenerierlaufEntryListDocument,
    options
  );
}
export type VertragVposIndexedValueGenerierlaufEntryListQueryHookResult = ReturnType<typeof useVertragVposIndexedValueGenerierlaufEntryListQuery>;
export type VertragVposIndexedValueGenerierlaufEntryListLazyQueryHookResult = ReturnType<
  typeof useVertragVposIndexedValueGenerierlaufEntryListLazyQuery
>;
export type VertragVposIndexedValueGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<
  typeof useVertragVposIndexedValueGenerierlaufEntryListSuspenseQuery
>;
export type VertragVposIndexedValueGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  VertragVposIndexedValueGenerierlaufEntryListQuery,
  VertragVposIndexedValueGenerierlaufEntryListQueryVariables
>;
