import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { verarbeitungTypes } from '../../verarbeitungHelpers';
import { filtersQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import ListingFilters from './Filters/ListingFilters';
import { listingFiltersFormInitialValues, ListingFiltersFormValues, mapFormValuesToListingFilters } from './Filters/listingFiltersFormMapper';
import EntryTable from './Table/EntryTable';
import { ZinslisteGenerierlauf } from '../../../../types';

type Props = {
  generierlauf: ZinslisteGenerierlauf;
};

const EntryListing: FC<Props> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<filtersQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.Zinsliste, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: ListingFiltersFormValues) => {
    updateQueryParams(verarbeitungTypes.Zinsliste, generierlauf.generierlaufId, navigate, mapFormValuesToListingFilters(values), paginationParams);
  };

  return (
    <Formik<ListingFiltersFormValues> initialValues={listingFiltersFormInitialValues(queryParams)} onSubmit={onChange}>
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />
          <EntryTable generierlaufId={generierlauf.generierlaufId} objektId={queryParams.objektId} exitCodeList={queryParams.exitCodeList} />
        </>
      )}
    </Formik>
  );
};

export default EntryListing;
