import React, { FC, useEffect, useState } from 'react';
import { Space, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { FILE_CONTENT_TYPE_PDF, IMAGE_SUPPORTED_FORMATS } from '../../../../../../helpers/fileHelper';
import { WizardFileInfo } from '../../../../../../types';
import { FileData, handleAddAttachments } from './handleAddAttachments';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import ErrorMessagePanel from '../../../../../../components/Error/ErrorMessagePanel';

type Props = {
  attachmentList: WizardFileInfo[];
  onAction: () => void;
  wizardId: string;
  vorschreibungMailDeliveryId: string;
  disabled?: boolean;
};

const AttachmentUpload: FC<Props> = ({ attachmentList, onAction, wizardId, vorschreibungMailDeliveryId, disabled }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  const [filesToUpload, setFilesToUpload] = useState<FileData[]>([]);

  useEffect(() => {
    if (!filesToUpload.length) return;
    handleAddAttachments(firmendatenId, wizardId, vorschreibungMailDeliveryId, filesToUpload, onAction);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload]);

  const [error, setError] = useState<string | undefined>();

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Upload.Dragger
        disabled={disabled}
        beforeUpload={(file, fileList) => {
          let files: FileData[] = [];

          fileList.forEach((file) => {
            if (validateFile(file, fileList, attachmentList, setError)) {
              const data: FileData = {
                filename: file.name,
                file,
              };
              if (files.indexOf(data) === -1) {
                files = [...files, data];
              } else {
                files = [data];
              }
            }
          });
          setFilesToUpload(files);
          return false;
        }}
        multiple
        showUploadList={false}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Dateien hinzufügen</p>
        <p className="ant-upload-hint">Eine oder mehrere Dateien hochladen</p>
      </Upload.Dragger>
      {error && <ErrorMessagePanel error={error} />}
    </Space>
  );
};

const validateFile = (
  currentFile: RcFile,
  currentFileList: RcFile[],
  attachmentList: WizardFileInfo[],
  setError: (error?: string) => void
): boolean => {
  let error;

  const hasNoType = !currentFile.type;
  if (hasNoType) {
    error = `${currentFile.name} hat keinen Dateityp. Ordner dürfen nicht hochgeladen werden.`;
  }

  const allowedFileContentTypes = [FILE_CONTENT_TYPE_PDF, ...IMAGE_SUPPORTED_FORMATS];
  if (!allowedFileContentTypes.includes(currentFile.type)) {
    error = `Das Format der Beleg Datei ${currentFile.type} wird nicht unterstützt, unterstützte Formate sind: ${allowedFileContentTypes}`;
  }

  const maxSizeMB = 7;
  const maxSizeBytes = maxSizeMB * 1024 * 1024;

  const totalSize = [...attachmentList.filter((attachment) => !!attachment.deletable), ...currentFileList].reduce(
    (prev, current) => prev + (current?.size || 0),
    0
  );

  const isTooLarge = totalSize > maxSizeBytes;
  if (isTooLarge) {
    error = `Dateien müssen kleiner als 7 MB sein.`;
  }

  if (error) {
    setError(error);
    return false;
  }

  setError(undefined);
  return true;
};

export default AttachmentUpload;
