import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { TableWithAlignedColsColumnType } from '../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import { generatePathToBeVertragDetailsPage } from '../../../Vertrag/BeVertrag/beVertragUriPaths';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount } from '../../../../components/Number';
import { UstVomAufwandVertrag } from '../../../../types';
import { generatePathToBookingDetailsPage } from '../../../BookingDetails/bookingDetailsPaths';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const ustVomAufwandDetailsTableColumns = (
  objektId: string,
  rechtstraegerId: string,
  fibuBuchungIdList: string[]
): TableWithAlignedColsColumnType<UstVomAufwandVertrag>[] => [
  {
    title: 'Top',
    align: 'left',
    render: (ustVomAufwandForVertrag) => (
      <Link to={generatePathToBestandseinheitDetailsPage(objektId, ustVomAufwandForVertrag.bestandseinheit.bestandseinheitId)}>
        {ustVomAufwandForVertrag.bestandseinheit.bezeichnung}
      </Link>
    ),
  },
  {
    title: 'Vertrag',
    align: 'left',
    render: (ustVomAufwandForVertrag) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={ustVomAufwandForVertrag.vertrag.vertragspartner.rechtstraegerId}
        rechtstraegerName={ustVomAufwandForVertrag.vertrag.vertragspartner.kurzBezeichnung}
        maxTextLength={15}
        linkTo={generatePathToBeVertragDetailsPage(
          objektId,
          ustVomAufwandForVertrag.bestandseinheit.bestandseinheitId,
          ustVomAufwandForVertrag.vertrag.vertragId
        )}
      />
    ),
  },
  {
    title: 'Vertragsart',
    align: 'left',
    render: (ustVomAufwandForVertrag) => <DataWithShortenedText maxTextLength={11} text={ustVomAufwandForVertrag.vertrag.vertragsart.text} />,
  },
  {
    title: 'Summe Aufwand Rep. Fonds',
    align: 'right',
    colSpan: 3,
    render: (ustVomAufwandForVertrag) => <EuroAmount value={ustVomAufwandForVertrag.sumNetto} />,
  },
  {
    title: 'Anz. berücksichtigter ER',
    align: 'center',
    width: '30%',
    render: (ustVomAufwandForVertrag) => {
      const allBuchungIds = ustVomAufwandForVertrag.repFondsKontoList
        .map((ustVomAufwand) =>
          ustVomAufwand.aufwandsKontoList.map((aufwandsKonto) => aufwandsKonto.aufteilungList.map((aufteilung) => aufteilung.buchungId).flat())
        )
        .flat();
      const uniqueBuchungIds = Array.from(new Set(allBuchungIds));

      return <Typography.Text>{uniqueBuchungIds.length}</Typography.Text>;
    },
  },
  {
    title: 'Summe-Ust.-vom-Aufwand',
    align: 'right',
    colSpan: 2,
    width: '20%',
    render: (ustVomAufwandForVertrag) =>
      fibuBuchungIdList.length > 0 ? (
        <Link to={generatePathToBookingDetailsPage(fibuBuchungIdList)}>
          <EuroAmount value={ustVomAufwandForVertrag.sumUstVomAufwand} />
        </Link>
      ) : (
        <EuroAmount value={ustVomAufwandForVertrag.sumUstVomAufwand} />
      ),
  },
];

export default ustVomAufwandDetailsTableColumns;
