import React, { FC, PropsWithChildren } from 'react';
import { Layout } from 'antd';
import { StyledSkeleton } from './Styled/Skeleton.style';

/**
 * <h2>Usage</h2>
 * Used when a page is being loaded
 *
 */

type Props = {
  loading: boolean;
};

const LoadingPage: FC<Props & PropsWithChildren> = ({ loading, children }) => {
  if (loading) {
    return (
      <Layout>
        <Layout.Content style={{ padding: '16px' }}>
          <StyledSkeleton loading={loading} active paragraph={{ rows: 8 }} />
        </Layout.Content>
      </Layout>
    );
  }
  return <>{children}</>;
};

export default LoadingPage;
