import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FibuFieldHelpListQueryVariables = Types.Exact<{
  selector?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fieldHelp?: Types.InputMaybe<Types.Scalars['String']['input']>;
  deprecated?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  order?: Types.InputMaybe<Types.FieldHelpOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type FibuFieldHelpListQuery = {
  getFibuFieldHelpList: {
    data: {
      contentList: Array<{ deprecated: boolean; fieldHelp?: string | null; fieldHelpId: string; selector: string }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FibuFieldHelpListDocument = gql`
  query FibuFieldHelpList(
    $selector: String
    $fieldHelp: String
    $deprecated: Boolean
    $order: FieldHelpOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getFibuFieldHelpList(
      selector: $selector
      fieldHelp: $fieldHelp
      deprecated: $deprecated
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          deprecated
          fieldHelp
          fieldHelpId
          selector
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFibuFieldHelpListQuery(baseOptions?: Apollo.QueryHookOptions<FibuFieldHelpListQuery, FibuFieldHelpListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FibuFieldHelpListQuery, FibuFieldHelpListQueryVariables>(FibuFieldHelpListDocument, options);
}
export function useFibuFieldHelpListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FibuFieldHelpListQuery, FibuFieldHelpListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FibuFieldHelpListQuery, FibuFieldHelpListQueryVariables>(FibuFieldHelpListDocument, options);
}
export function useFibuFieldHelpListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FibuFieldHelpListQuery, FibuFieldHelpListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FibuFieldHelpListQuery, FibuFieldHelpListQueryVariables>(FibuFieldHelpListDocument, options);
}
export type FibuFieldHelpListQueryHookResult = ReturnType<typeof useFibuFieldHelpListQuery>;
export type FibuFieldHelpListLazyQueryHookResult = ReturnType<typeof useFibuFieldHelpListLazyQuery>;
export type FibuFieldHelpListSuspenseQueryHookResult = ReturnType<typeof useFibuFieldHelpListSuspenseQuery>;
export type FibuFieldHelpListQueryResult = Apollo.QueryResult<FibuFieldHelpListQuery, FibuFieldHelpListQueryVariables>;
