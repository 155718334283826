import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../../security/AuthRoute';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../../../security/permissionChecks';
import SysSettingsAbrechnungsdefinitionVersionUpdatePage from './SysSettingsAbrechnungsdefinitionVersionUpdatePage';
import { URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION } from '../../../../../../features/Abrechnungsdefinition/abrDefUriPaths';

export type AbrechnungsdefinitionVersionUpdatePageRouteParams = { abrechnungsdefinitionId: string; abrechnungsdefinitionVersionId: string };

const sysSettingsAbrechnungsdefinitionVersionUpdatePageRoute = (
  <Route
    path={URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.updatePage}
    element={
      <AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAbrechnungsdefinitionVersionUpdatePage />} />
    }
  />
);

export default sysSettingsAbrechnungsdefinitionVersionUpdatePageRoute;
