import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import {
  URI_VERARBEITUNG_VORSCHREIBUNG_ERSTELLEN_DETAILS_PAGE,
  URI_VERARBEITUNG_VORSCHREIBUNG_VERBUCHEN_DETAILS_PAGE,
} from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VorschreibungErstellenDetailsPage from './VorschreibungErstellenDetailsPage/VorschreibungErstellenDetailsPage';
import VorschreibungVerbuchenDetailsPage from './VorschreibungVerbuchenDetailsPage/VorschreibungVerbuchenDetailsPage';

export type VerarbeitungVorschreibungDetailsPageRouteParams = { generierlaufId: string };

const verarbeitungVorschreibungErstellenDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_VORSCHREIBUNG_ERSTELLEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<VorschreibungErstellenDetailsPage />} />}
  />
);

const verarbeitungVorschreibungFreigebenDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_VORSCHREIBUNG_VERBUCHEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<VorschreibungVerbuchenDetailsPage />} />}
  />
);

export { verarbeitungVorschreibungErstellenDetailsPageRoute, verarbeitungVorschreibungFreigebenDetailsPageRoute };
