import React, { FC } from 'react';
import { DeviationChangeTypeTuple } from '../../types';
import { isDeviationChangeTypeAenderung, isDeviationChangeTypeEntfernt, isDeviationChangeTypeNeu } from './deviationHelpers';
import { DeviationStyledTag } from './Styled/Deviation.style';

type DeviationChangeTypeTagProps = {
  changeType: DeviationChangeTypeTuple;
};

const DeviationChangeTypeTag: FC<DeviationChangeTypeTagProps> = ({ changeType }) => {
  if (isDeviationChangeTypeAenderung(changeType)) return <DeviationStyledTag color="#fa8c16">{changeType.text}</DeviationStyledTag>;
  if (isDeviationChangeTypeEntfernt(changeType)) return <DeviationStyledTag color="#f5222d">{changeType.text}</DeviationStyledTag>;
  if (isDeviationChangeTypeNeu(changeType)) return <DeviationStyledTag color="#faad14">{changeType.text}</DeviationStyledTag>;
  return null;
};

export default DeviationChangeTypeTag;
