import React, { FC, useState } from 'react';
import { FormikProps } from 'formik';
import { Transfer } from 'antd';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import { mapToTransferItems } from './auftragsartTransferMapper';
import { textbausteinFormFields, TextbausteinFormValues } from '../textbausteinFormMapper';
import { useAuftragsartListQuery } from '../../../Auftragsart/gql/AuftragsartQueries.types';
import { isTextbausteinTypeIndexationInformation } from '../../textbausteinHelpers';
import { AuftragsartType } from '../../../../types';

type Props = {
  formikProps: FormikProps<TextbausteinFormValues>;
};

const AuftragsartListTransfer: FC<Props> = ({ formikProps }) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>(formikProps.values.auftragsartIdList || []);

  const { data } = useAuftragsartListQuery({
    variables: {
      includeArchiviert: true,
    },
  });

  const auftragsartList = data?.getAuftragsartList.data ?? [];

  const filteredAuftragsartList = auftragsartList.filter((auftragsart) =>
    isTextbausteinTypeIndexationInformation(formikProps.values.type)
      ? ALLOWED_AUFTRAGSART_TYPES_FOR_WERTSICHERUNG_INFORMATION.includes(auftragsart.type.value)
      : true
  );

  return (
    <Transfer
      dataSource={mapToTransferItems(filteredAuftragsartList)}
      titles={['nicht zugewiesen', 'zugewiesen']}
      locale={{ notFoundContent: 'Keine Auftragsart in dieser Liste' }}
      targetKeys={formikProps.values.auftragsartIdList}
      selectedKeys={selectedKeys}
      onChange={(nextTargetKeys) => {
        formikProps.setFieldValue(textbausteinFormFields.auftragsartIdList, nextTargetKeys);
      }}
      onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...(sourceSelectedKeys as string[]), ...(targetSelectedKeys as string[])]);
      }}
      render={(record) => <DataWithStatus status={record.status} text={record.bezeichnung} badgeOffset={[5, 5]} maxTextLength={35} />}
      listStyle={{
        width: 315,
        height: 200,
      }}
    />
  );
};

const ALLOWED_AUFTRAGSART_TYPES_FOR_WERTSICHERUNG_INFORMATION = [AuftragsartType.VorschreibungDauerrechnung, AuftragsartType.VorschreibungEinzel];

export default AuftragsartListTransfer;
