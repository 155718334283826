/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import {entityIsRequired} from '../../../../../../components/message/validationMsg';
import {rechnungsAusstellerRepFondsZuweisungFormFields} from './rechnAusstellerRepFondsKontoZuweisungFormMapper';

export const repFondsKontoZuweisungValidationSchema = Yup.object().shape({
  [rechnungsAusstellerRepFondsZuweisungFormFields.erloesKontoIdList]: Yup.array().min(1, entityIsRequired('Mindestens ein Erlöskonto')),
  [rechnungsAusstellerRepFondsZuweisungFormFields.fibuBankAccountId]: Yup.string().required(entityIsRequired('Bankverbindung')),
});

/* eslint-enable no-template-curly-in-string */
