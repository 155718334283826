import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { pathsToWeAbrechnungDetails } from '../../weAbrechnungUriPaths';
import { EuroAmount } from '../../../../../components/Number';
import AbrechnungsErgebnis from './AbrechnungsErgebnis';
import { TableWithAlignedColsColumnType } from '../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { TopAbrAbrechnungkreis } from '../../../../../types';

const topAbrechnungWithRepFondsDetailsTableColumns = (
  objektId: string,
  objektAbrechnungId: string
): TableWithAlignedColsColumnType<TopAbrAbrechnungkreis>[] => [
  {
    title: 'Abrechnungskreis',
    render: (abrKreis) => <Typography.Text>{abrKreis.bezeichnung}</Typography.Text>,
    align: 'left',
  },
  {
    title: 'Aufwand Gesamt',
    align: 'center',
    render: (abrKreis) => (
      <Link target="_blank" to={pathsToWeAbrechnungDetails(objektId, objektAbrechnungId).objAbrTab}>
        <EuroAmount value={abrKreis.aufwandGesamt} />
      </Link>
    ),
  },
  {
    title: 'Vorschreibung (Netto)',
    align: 'right',
    render: (abrKreis) => <EuroAmount value={abrKreis.einnahmen} />,
  },
  {
    title: 'U.v.Aufwand.Belastung',
    align: 'right',
    render: (abrKreis) => (
      <EuroAmount value={abrKreis.ustVomAufwandAnteilList.reduce((currentValue, ustVomAufwand) => currentValue + ustVomAufwand.belastung, 0)} />
    ),
  },
  {
    title: 'Abrechnungsergebnis',
    align: 'right',
    render: (abrKreis) => <AbrechnungsErgebnis brutto={abrKreis.brutto} />,
  },
];

export default topAbrechnungWithRepFondsDetailsTableColumns;
