import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AuftragsartBaseFieldsFragmentDoc } from '../../Auftragsart/gql/AuftragsartFragments.types';
import { TextbausteinFieldsFragmentDoc } from '../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
export type GeneralTextbausteinFieldsFragment = {
  bezeichnung: string;
  createdBy?: string | null;
  createTs: string;
  createdByMitarbeiterId?: string | null;
  firmendatenId?: string | null;
  textbausteinId: string;
  auftragsartList: Array<{
    auftragsartId: string;
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    showUstBlock: boolean;
    updatedByMitarbeiterId?: string | null;
    status: { text: string; value: Types.AuftragsartStatus };
    type: { text: string; value: Types.AuftragsartType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  textbaustein: {
    index: number;
    value?: string | null;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
    }>;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    type: { text: string; value: Types.TextbausteinType };
  };
};

export const GeneralTextbausteinFieldsFragmentDoc = gql`
  fragment GeneralTextbausteinFields on GeneralTextbaustein {
    auftragsartList {
      ...AuftragsartBaseFields
    }
    bezeichnung
    createdBy
    createTs
    createdByMitarbeiterId
    firmendatenId
    textbaustein {
      ...TextbausteinFields
      textbausteinList {
        ...TextbausteinFields
      }
    }
    textbausteinId
  }
  ${AuftragsartBaseFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
