import React, { FC } from 'react';
import { TabsProps } from 'antd';
import ObjektAbrechnungTab from './Objekt/ObjektAbrechnungTab';
import TopAbrechnungTab from './Top/TopAbrechnungTab';
import RouteTabs from '../../../components/Tabs/RouteTabs';
import { isAbrechnungTypeWeAbrechnung, pathToBkOrWeAbrechnungDetails } from '../shared/abrechnungHelpers';
import { ObjektAbrechnung } from '../../../types';
import ZahlungsrueckstandTab from './Zahlungsrueckstand/ZahlungsrueckstandTab';
import ObjektAbrechnungTab2 from './Objekt/ObjektAbrechnungTab2';

type Props = {
  abrechnung: ObjektAbrechnung;
  loading: boolean;
  objektId: string;
};

const BkOrWeAbrDetailsTabs: FC<Props> = ({ abrechnung, loading, objektId }) => {
  const { errorList: objektAbrErrorList } = abrechnung;
  const paths = pathToBkOrWeAbrechnungDetails(abrechnung.type.value, objektId, abrechnung.objektAbrechnungId);

  const items: TabsProps['items'] = [
    {
      key: paths.objAbrTab,
      label: isAbrechnungTypeWeAbrechnung(abrechnung.type.value) ? 'WE-Objektabrechnung' : 'BK-Objektabrechnung',
      children: <ObjektAbrechnungTab objektAbrErrorList={objektAbrErrorList} loading={loading} abrechnung={abrechnung} />,
    },
    {
      key: paths.objAbrTab2,
      label: isAbrechnungTypeWeAbrechnung(abrechnung.type.value) ? 'WE-Objektabrechnung2' : 'BK-Objektabrechnung2',
      children: <ObjektAbrechnungTab2 objektAbrErrorList={objektAbrErrorList} loading={loading} abrechnung={abrechnung} />,
    },
    {
      key: paths.topAbrTab,
      label: isAbrechnungTypeWeAbrechnung(abrechnung.type.value) ? 'WE-Topabrechnungen' : 'BK-Topabrechnungen',
      children: <TopAbrechnungTab abrechnung={abrechnung} loading={loading} objektId={objektId} objektAbrechnungId={abrechnung.objektAbrechnungId} />,
    },
    {
      key: paths.zahlungsrueckstandTab,
      label: 'Zahlungsrückstände',
      children: <ZahlungsrueckstandTab abrechnung={abrechnung} loading={loading} objektId={objektId} />,
    },
  ];
  return <RouteTabs items={items} />;
};

export default BkOrWeAbrDetailsTabs;
