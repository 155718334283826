import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateObjektSummeAnteileMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  input: Types.ObjektSummeAnteileInput;
}>;

export type CreateObjektSummeAnteileMutation = {
  createObjektSummeAnteile: {
    data: { objektSummeAnteileId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateObjektSummeAnteileMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  summeAnteileId: Types.Scalars['ID']['input'];
  input: Types.ObjektSummeAnteileInput;
}>;

export type UpdateObjektSummeAnteileMutation = {
  updateObjektSummeAnteile: {
    data: { objektSummeAnteileId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteObjektSummeAnteileMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  summeAnteileId: Types.Scalars['ID']['input'];
}>;

export type DeleteObjektSummeAnteileMutation = {
  deleteObjektSummeAnteile: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateObjektSummeAnteileDocument = gql`
  mutation CreateObjektSummeAnteile($objektId: ID!, $input: ObjektSummeAnteileInput!) {
    createObjektSummeAnteile(objektId: $objektId, input: $input) {
      data {
        objektSummeAnteileId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateObjektSummeAnteileMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateObjektSummeAnteileMutation, CreateObjektSummeAnteileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateObjektSummeAnteileMutation, CreateObjektSummeAnteileMutationVariables>(CreateObjektSummeAnteileDocument, options);
}
export type CreateObjektSummeAnteileMutationHookResult = ReturnType<typeof useCreateObjektSummeAnteileMutation>;
export type CreateObjektSummeAnteileMutationResult = Apollo.MutationResult<CreateObjektSummeAnteileMutation>;
export type CreateObjektSummeAnteileMutationOptions = Apollo.BaseMutationOptions<
  CreateObjektSummeAnteileMutation,
  CreateObjektSummeAnteileMutationVariables
>;
export const UpdateObjektSummeAnteileDocument = gql`
  mutation UpdateObjektSummeAnteile($objektId: String!, $summeAnteileId: ID!, $input: ObjektSummeAnteileInput!) {
    updateObjektSummeAnteile(objektId: $objektId, summeAnteileId: $summeAnteileId, input: $input) {
      data {
        objektSummeAnteileId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateObjektSummeAnteileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateObjektSummeAnteileMutation, UpdateObjektSummeAnteileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateObjektSummeAnteileMutation, UpdateObjektSummeAnteileMutationVariables>(UpdateObjektSummeAnteileDocument, options);
}
export type UpdateObjektSummeAnteileMutationHookResult = ReturnType<typeof useUpdateObjektSummeAnteileMutation>;
export type UpdateObjektSummeAnteileMutationResult = Apollo.MutationResult<UpdateObjektSummeAnteileMutation>;
export type UpdateObjektSummeAnteileMutationOptions = Apollo.BaseMutationOptions<
  UpdateObjektSummeAnteileMutation,
  UpdateObjektSummeAnteileMutationVariables
>;
export const DeleteObjektSummeAnteileDocument = gql`
  mutation DeleteObjektSummeAnteile($objektId: String!, $summeAnteileId: ID!) {
    deleteObjektSummeAnteile(objektId: $objektId, summeAnteileId: $summeAnteileId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteObjektSummeAnteileMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteObjektSummeAnteileMutation, DeleteObjektSummeAnteileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteObjektSummeAnteileMutation, DeleteObjektSummeAnteileMutationVariables>(DeleteObjektSummeAnteileDocument, options);
}
export type DeleteObjektSummeAnteileMutationHookResult = ReturnType<typeof useDeleteObjektSummeAnteileMutation>;
export type DeleteObjektSummeAnteileMutationResult = Apollo.MutationResult<DeleteObjektSummeAnteileMutation>;
export type DeleteObjektSummeAnteileMutationOptions = Apollo.BaseMutationOptions<
  DeleteObjektSummeAnteileMutation,
  DeleteObjektSummeAnteileMutationVariables
>;
