import React, { FC } from 'react';
import { URI_OBJEKT_DETAILS_PAGE } from '../Objekt/objektUriPaths';
import { URI_OBJEKT_BESTANDSEINHEIT_DETAILS_PAGE } from '../Bestandseinheit/bestandseinheitUriPaths';
import { URI_DASHBOARD_OBJEKT_BE_VERTRAG_DETAILS_PAGE } from '../Vertrag/BeVertrag/beVertragUriPaths';
import { URI_ZINSLISTE_DETAILS_PAGE } from '../Zinsliste/zinslisteUriPaths';
import { useZinslisteQuery } from '../Zinsliste/gql/ZinslisteQueries.types';
import { URI_VORSCHREIBUNG_DETAILS_PAGE, URI_VORSCHREIBUNG_PAGE } from '../Vorschreibung/vorschreibungUriPaths';
import {
  useAbrechnungsdefinitionKurzBezeichnungQuery,
  useAbrechnungsdefinitionTemplateKurzBezeichnungQuery,
  useAuftragKurzBezeichnungQuery,
  useBestandseinheitKurzBezeichnungQuery,
  useBeVertragKurzBezeichnungQuery,
  useFirmendatenKurzBezeichnungQuery,
  useHeAbrechnungHeVertragKurzBezeichnungQuery,
  useHeAbrechnungKurzBezeichnungQuery,
  useHeVertragKurzBezeichnungQuery,
  useKontoKurzBezeichnungQuery,
  useKontoTemplateKurzBezeichnungQuery,
  useObjektAbrKurzBezeichnungQuery,
  useObjektKurzBezeichnungQuery,
  useRechtstraegerKurzBezeichnungQuery,
  useSingleInvoiceKurzBezeichnungQuery,
  useStandingInvoiceKurzBezeichnungQuery,
  useTopAbrechnungKurzBezeichnungQuery,
} from './gql/KurzbezeichnungQueries.types';
import { URI_MANAGEMENT_PAGE_SYSTEM_FIRMENDATEN_DETAILS_PAGE } from '../../constants/managementPageUriPaths';
import { URI_DASHBOARD_PROFILE } from '../Profile/profileUriPaths';
import { URI_CONFIGURATION, URI_SYS_SETTINGS } from '../../constants/configurationUriPaths';
import { URI_DOCUMENT_PAGE } from '../../constants/documentsUriPaths';
import { URI_RECHTSTRAEGER_DETAILS_PAGE, URI_RECHTSTRAEGER_PAGE } from '../Rechtstraeger/rechtstraegerUriPaths';
import { URI_WE_ABRECHNUNG_DETAILS_PAGE, URI_WE_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE } from '../Abrechnung/BkOrWe/weAbrechnungUriPaths';
import { URI_SALDENLISTE_PAGE } from '../Saldenliste/saldenlisteUriPaths';
import { URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION } from '../Abrechnungsdefinition/abrDefUriPaths';
import { URI_SYS_SETTINGS_KONTO_DETAILS_PAGE } from '../Kontenplan/kontoUriPaths';
import URI_SYS_SETTINGS_MAHNDEFINITION_PAGE from '../Mahndefinition/mahndefinitonUriPaths';
import { URI_MAHNUNG_DETAILS_PAGE, URI_MAHNUNG_PAGE } from '../Mahnung/mahnungUriPaths';
import {
  URI_VERARBEITUNG_BELEG_ERSTELLEN_DETAILS_PAGE,
  URI_VERARBEITUNG_BK_OBJEKT_ABRECHNUNGEN_DETAILS_PAGE,
  URI_VERARBEITUNG_BK_TOP_ABRECHNUNGEN_DETAILS_PAGE,
  URI_VERARBEITUNG_BUDGETING_DETAILS_PAGE,
  URI_VERARBEITUNG_CAMT_DETAILS_PAGE,
  URI_VERARBEITUNG_CAMT_EBICS_MANUALLY_DETAILS_PAGE,
  URI_VERARBEITUNG_CAMT_ONLINE_BANKING_MANUALLY_DETAILS_PAGE,
  URI_VERARBEITUNG_DATA_CARRIER_PAIN_CREATION_DETAILS_PAGE,
  URI_VERARBEITUNG_HE_ABRECHNUNG_ERSTELLEN_DETAILS_PAGE,
  URI_VERARBEITUNG_INFO_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST_DETAILS_PAGE,
  URI_VERARBEITUNG_INFO_MAIL_DELIVERY_SEND_DETAILS_PAGE,
  URI_VERARBEITUNG_MAHNUNG_AUSGEBEN_DETAILS_PAGE,
  URI_VERARBEITUNG_MAHNUNG_ERSTELLEN_DETAILS_PAGE,
  URI_VERARBEITUNG_MAHNUNG_VERSENDEN_DETAILS_PAGE,
  URI_VERARBEITUNG_OBJEKT_FINANCIAL_PLAN,
  URI_VERARBEITUNG_PAYMENT_PROPOSAL_CREATE_DETAILS_PAGE,
  URI_VERARBEITUNG_PAYMENT_PROPOSAL_EXECUTE_DETAILS_PAGE,
  URI_VERARBEITUNG_SYNC_EBICS_USER_DETAILS_PAGE,
  URI_VERARBEITUNG_TOP_FINANCIAL_PLAN,
  URI_VERARBEITUNG_UST_VOM_AUFWAND_DETAILS_PAGE,
  URI_VERARBEITUNG_VORSCHREIBUNG_ERSTELLEN_DETAILS_PAGE,
  URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST_DETAILS_PAGE,
  URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_SEND_DETAILS_PAGE,
  URI_VERARBEITUNG_VORSTEUERKUERZUNG_ERSTELLEN_DETAILS_PAGE,
  URI_VERARBEITUNG_WE_OBJEKT_ABRECHNUNG_DETAILS_PAGE,
  URI_VERARBEITUNG_WE_TOP_ABRECHNUNG_DETAILS_PAGE,
  URI_VERARBEITUNG_ZINSLISTE_ERSTELLEN_DETAILS_PAGE,
  URI_VERARBEITUNG_FIRMENDATEN_INITIALIZER_DETAILS_PAGE,
} from '../Verarbeitung/verarbeitungApiPaths';
import { URI_VERARBEITUNG_PAGE } from '../../constants/appUriPaths';
import { verarbeitungDetailsCardTitles } from '../Verarbeitung/verarbeitungHelpers';
import { URI_VORSTEUERKUERZUNG_DETAILS_PAGE } from '../Vorsteuerkuerzung/vorsteuerkuerzungUriPaths';
import { useVorsteuerkuerzungQuery } from '../Vorsteuerkuerzung/gql/VorsteuerkuerzungQueries.types';
import { dayjsCustom } from '../../helpers/dayjsCustom';
import { URI_UST_VOM_AUFWAND_DETAILS_PAGE } from '../UstVomAufwand/ustVomAufwandUriPaths';
import { useUstVomAufwandQuery } from '../UstVomAufwand/gql/UstVomAufwandQueries.types';
import URI_SYS_SETTINGS_AUFTRAGSART_PAGE from '../Auftragsart/auftragsartUriPaths';
import { useAuftragsartKurzbezeichnungQuery } from '../Auftragsart/gql/AuftragsartQueries.types';
import { URI_EMAIL_VERSAND_DETAILS_PAGE, URI_EMAIL_VERSAND_PAGE } from '../EmailDelivery/emailDeliveryUriPaths';
import { useMailQuery } from '../EmailDelivery/gql/EmailDeliveryQueries.types';
import { URI_DASHBOARD_OBJEKT_HE_VERTRAG_DETAILS_PAGE } from '../Vertrag/HeVertrag/heVertragUriPaths';
import { URI_HE_ABRECHNUNG_DETAILS_HE_VERTRAG_DETAILS_PAGE, URI_HE_ABRECHNUNG_DETAILS_PAGE } from '../Abrechnung/He/heAbrechnungUriPaths';
import { URI_BK_ABRECHNUNG_DETAILS_PAGE, URI_BK_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE } from '../Abrechnung/BkOrWe/bkAbrechnungUriPaths';
import { useMahndefinitionQuery } from '../Mahndefinition/gql/MahndefinitionQueries.types';
import { URI_ANDROMEDA_SYS_SETTINGS_INDEX_SERIES_DETAILS_PAGE } from '../IndexSeries/indexSeriesUriPaths';
import { useIndexSeriesQuery } from '../IndexSeries/gql/IndexSeriesQueries.types';
import { URI_SYS_SETTINGS_FIRMENDATEN_INDEX_SERIES_DETAILS_PAGE } from '../Firmendaten/IndexSeries/firmendatenIndexSeriesUriPaths';
import { useFirmendatenIndexSeriesQuery } from '../Firmendaten/IndexSeries/gql/FirmendatenIndexSeriesQueries.types';
import { URI_BUDGETING_DETAILS_PAGE } from '../Budgeting/budgetingUriPaths';
import { useBudgetingQuery } from '../Budgeting/gql/BudgetingQueries.types';
import {
  URI_ANDROMEDA_SYS_SETTINGS,
  URI_ANDROMEDA_SYS_SETTINGS_ADMIN_DETAILS_PAGE,
  URI_ANDROMEDA_SYS_SETTINGS_KUNDEN_SYSTEM_DETAILS_PAGE,
  URI_ANDROMEDA_SYSTEM_SETTINGS,
} from '../../constants/andromedaSystemSettingsUriPaths';
import { URI_INDEXED_VALUE_PAGE } from '../IndexedValue/indexedValueUriPaths';
import { useUserQuery } from '../../graphql/Authentication/UserQueries.types';
import { useKundenSystemQuery } from '../KundenSystem/KundenSystem/gql/KundenSystemQueries.types';
import { URI_SUB_TICKET_DETAILS_PAGE, URI_TICKET_DETAILS_PAGE, URI_TICKET_PAGE } from '../Ticket/ticketUriPaths';
import { useTicketQuery } from '../Ticket/gql/TicketQueries.types';
import TicketDetailsPageBreadcrumb from '../Ticket/TicketDetailsPageBreadcrumb';
import {
  incomingInvoicePaths,
  URI_INCOMING_INVOICE_CREATE_INVOICE_PAGE,
  URI_INCOMING_INVOICE_PAGE,
} from '../IncomingInvoice/incomingInvoiceUriPaths';
import { URI_ANDROMEDA_SYS_SETTINGS_KONTO_DETAILS_PAGE } from '../Kontenrahmen/kontoTemplateUriPaths';
import { URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION } from '../AbrechnungsdefinitionTemplate/abrDefTemplateUriPaths';
import { URI_SUB_ADMINISTRATION_DETAILS_PAGE } from '../SubAdministration/subAdministrationUriPaths';
import { useSubAdministrationQuery } from '../SubAdministration/gql/SubAdministrationQueries.types';
import { URI_SUB_ABRECHNUNG_DETAILS_PAGE } from '../Abrechnung/Sub/subAbrechnungUriPaths';
import { useSubAbrechnungQuery } from '../Abrechnung/Sub/gql/SubAbrechnungQueries.types';
import { URI_SUB_ABRECHNUNG_EIGENTUEMER_DETAILS_PAGE } from '../Abrechnung/Sub/subAbrechnungEigentumerUriPaths';
import { useSubAbrechnungEigentuemerQuery } from '../Abrechnung/Sub/gql/SubAbrechnungEigentuemerQueries.types';

export interface PathToBreadcrumbMapping {
  path: string;
  Title: (props: Record<string, string>) => React.ReactElement;
}

/**
 * This central management of path-breadcrumb mappings is not ideal, it would be better with dependency injection because that way features could be
 * separated and breadcrumb component itself was independent of other features. => better separation of concerns, better code splitting, better maintainability,
 * better disposability
 * => this solution is ok for now, we will see if this solution works for our use cases if so then with time it can be improved as written above
 */
const pathToBreadcrumbMappings: PathToBreadcrumbMapping[] = [
  {
    path: URI_EMAIL_VERSAND_PAGE,
    Title: () => <>E-Mail Versand</>,
  },
  {
    path: URI_EMAIL_VERSAND_DETAILS_PAGE,
    Title: ({ mailId }) => {
      const { data, loading } = useMailQuery({ variables: { mailId } });

      const mailData = data?.getMail.data;
      const title = mailData ? `${mailData.useCase.text} an ${mailData.recipient}` : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={title} />;
    },
  },
  {
    path: URI_OBJEKT_DETAILS_PAGE,
    Title: ({ objektId }) => {
      const { loading, data } = useObjektKurzBezeichnungQuery({ variables: { objektId } });

      const objektKurzBezeichnung = data?.getObjektKurzBezeichnung.data ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={objektKurzBezeichnung} />;
    },
  },
  {
    path: URI_OBJEKT_BESTANDSEINHEIT_DETAILS_PAGE,
    Title: ({ objektId, bestandseinheitId }) => {
      const { loading, data } = useBestandseinheitKurzBezeichnungQuery({ variables: { objektId, bestandseinheitId } });

      const bestandseinheitBezeichnung = data?.getBestandseinheitBezeichnung.data ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={bestandseinheitBezeichnung} />;
    },
  },
  {
    path: URI_DASHBOARD_OBJEKT_BE_VERTRAG_DETAILS_PAGE,
    Title: ({ objektId, bestandseinheitId, vertragId }) => {
      const { loading, data } = useBeVertragKurzBezeichnungQuery({
        variables: { objektId, bestandseinheitId, vertragId, showVertragsdauer: false },
      });

      const vertragArtBezeichnung = data?.getBeVertragKurzBezeichnung.data ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={vertragArtBezeichnung} />;
    },
  },
  {
    path: URI_DASHBOARD_OBJEKT_HE_VERTRAG_DETAILS_PAGE,
    Title: ({ objektId, heVertragId }) => {
      const { loading, data } = useHeVertragKurzBezeichnungQuery({
        variables: { objektId, heVertragId },
      });

      const vertragpartnerBezeichnung = data?.getHeVertragKurzbezeichnung.data ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={`Hauseigentümer - ${vertragpartnerBezeichnung}`} />;
    },
  },
  {
    path: URI_ZINSLISTE_DETAILS_PAGE,
    Title: ({ objektId, zinslisteId }) => {
      const { loading, data } = useZinslisteQuery({ variables: { objektId, zinslisteId } });

      const vorschreibungsPeriode = data?.getZinsliste.data.kopfdaten.vorschreibungsperiode ?? { jahr: 0, monat: 0 };

      return <BreadcrumbLoadingText data={data} loading={loading} text={`Zinsliste ${vorschreibungsPeriode.monat}/${vorschreibungsPeriode.jahr}`} />;
    },
  },
  {
    path: URI_BUDGETING_DETAILS_PAGE,
    Title: ({ objektId, budgetingId }) => {
      const { loading, data } = useBudgetingQuery({ variables: { objektId, budgetingId } });
      const bezeichnung = data?.getBudgeting.data.bezeichnung ?? '';
      return <BreadcrumbLoadingText data={data} loading={loading} text={`Budgetierung/${bezeichnung}`} />;
    },
  },
  {
    path: URI_VORSCHREIBUNG_PAGE,
    Title: () => <>Vorschreibungen</>,
  },
  {
    path: URI_VORSCHREIBUNG_DETAILS_PAGE,
    Title: ({ auftragId }) => {
      const { loading, data } = useAuftragKurzBezeichnungQuery({ variables: { auftragId } });

      const auftragData = data?.getAuftrag.data;

      const auftragBezeichnung = auftragData?.eigenbeleg?.bezeichnung ?? 'Vorschreibung';
      const fakturierungsperiodeText = auftragData
        ? `${auftragData.auftragsKopf.fakturierungsperiode.monat}/${auftragData.auftragsKopf.fakturierungsperiode.jahr}`
        : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={`${auftragBezeichnung}, ${fakturierungsperiodeText}`} />;
    },
  },
  {
    path: URI_DASHBOARD_PROFILE,
    Title: () => <>Mein Profil</>,
  },
  {
    path: URI_INCOMING_INVOICE_PAGE,
    Title: () => <>Eingangsrechnungen</>,
  },
  {
    path: URI_INCOMING_INVOICE_CREATE_INVOICE_PAGE,
    Title: () => <>Rechnung erfassen</>,
  },
  {
    path: URI_DOCUMENT_PAGE,
    Title: () => <>Dokumente</>,
  },
  {
    path: incomingInvoicePaths.singleInvoiceDetails,
    Title: ({ belegId }) => {
      const { loading, data } = useSingleInvoiceKurzBezeichnungQuery({ variables: { belegId } });
      const rechnungsNummer = data?.getSingleInvoice.data.rechnungsNummer;
      const kurzBezeichnung = rechnungsNummer ? `Rechnungs Nr. ${rechnungsNummer}` : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: incomingInvoicePaths.standingInvoiceDetails,
    Title: ({ belegId }) => {
      const { loading, data } = useStandingInvoiceKurzBezeichnungQuery({ variables: { belegId } });
      const rechnungsNummer = data?.getStandingInvoice.data.rechnungsNummer;
      const kurzBezeichnung = rechnungsNummer ? `Rechnungs Nr. ${rechnungsNummer}` : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_MANAGEMENT_PAGE_SYSTEM_FIRMENDATEN_DETAILS_PAGE,
    Title: ({ firmendatenId }) => {
      const { loading, data } = useFirmendatenKurzBezeichnungQuery({ variables: { firmendatenId } });

      const kurzBezeichnung = data?.getFirmendaten.data.kurzBezeichnung ? `Verwaltung - ${data.getFirmendaten.data.kurzBezeichnung}` : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_CONFIGURATION,
    Title: () => <>Einstellungen</>,
  },
  {
    path: URI_SYS_SETTINGS.aufteilungsschluesselPage,
    Title: () => <>Aufteilungsschlüssel</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.adminPage,
    Title: () => <>Admins</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.user,
    Title: () => <>Benutzer</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.kundenSystemPage,
    Title: () => <>Kundensystem</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS_KUNDEN_SYSTEM_DETAILS_PAGE,
    Title: ({ kundenSystemId }) => {
      const { loading, data } = useKundenSystemQuery({ variables: { kundenSystemId } });

      const kurzBezeichnung = data?.getKundenSystem.data.name ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS_ADMIN_DETAILS_PAGE,
    Title: ({ username }) => {
      const { loading, data } = useUserQuery({ variables: { username } });

      const kurzBezeichnung = data?.getUser.data.username ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.aufteilungsschluesselPage,
    Title: () => <>Aufteilungsschlüssel</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.emailDeliveryDefinitionPage,
    Title: () => <>E-Mail Versanddefinition</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.kontierungstabellePage,
    Title: () => <>Kontierungstabellen</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.aufgabenartPage,
    Title: () => <>Aufgabenarten</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.abrechnungskreisPage,
    Title: () => <>Abrechnungskreise</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage,
    Title: () => <>Abrechnungsdefinitionen</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION.createPage,
    Title: () => <>Neuanlage</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.createPage,
    Title: ({ abrechnungsdefinitionId }) => {
      const { loading, data } = useAbrechnungsdefinitionTemplateKurzBezeichnungQuery({ variables: { abrechnungsdefinitionId } });

      const kurzBezeichnung = data?.getAbrechnungsdefinitionTemplateBezeichnung.data ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.updatePage,
    Title: ({ abrechnungsdefinitionId }) => {
      const { loading, data } = useAbrechnungsdefinitionTemplateKurzBezeichnungQuery({ variables: { abrechnungsdefinitionId } });

      const kurzBezeichnung = data?.getAbrechnungsdefinitionTemplateBezeichnung.data ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.abrechnungKontenZuordnungPage,
    Title: () => <>Abr.Kontenzuordnung</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.auftragsartPage,
    Title: () => <>Auftragsarten</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.textPage,
    Title: () => <>Texte</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.mahndefinitionPage,
    Title: () => <>Mahndefinitionen</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.ustKategoriePage,
    Title: () => <>Ust-Kategorien</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.ustRegelsetPage,
    Title: () => <>Ust-Regelwerke</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.budgetingVerknuepfungAbrDefVPosPage,
    Title: () => <>Verknüpfung Abrechnungsdefinition zu Vorschreibungspositionen</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.budgetingVerknuepfungVPosAuftSchluesselPage,
    Title: () => <>Verknüpfung Vorschreibungsposition zu Aufteilungsschlüssel</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.vorschreibungspositionPage,
    Title: () => <>Vorschreibungspositionen</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.vorsteuerkuerzungPage,
    Title: () => <>Vorsteuerkürzung</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.repFondsPage,
    Title: () => <>Reparaturfonds</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.kontenRahmenPage,
    Title: () => <>Kontenrahmen</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS_KONTO_DETAILS_PAGE,
    Title: ({ kontoId }) => {
      const { loading, data } = useKontoTemplateKurzBezeichnungQuery({ variables: { kontoId } });

      const kurzBezeichnung = data?.getKontoTemplate.data.bezeichnung ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_SYS_SETTINGS.vorsteuerkuerzungPage,
    Title: () => <>Vorsteuerkürzung</>,
  },
  {
    path: URI_SYS_SETTINGS.repFondsPage,
    Title: () => <>Reparaturfonds</>,
  },
  {
    path: URI_SYS_SETTINGS.budgetingVerknuepfungAbrDefVPosPage,
    Title: () => <>Verknüpfung Abrechnungsdefinition zu Vorschreibungspositionen</>,
  },
  {
    path: URI_SYS_SETTINGS.budgetingVerknuepfungVPosAuftSchluesselPage,
    Title: () => <>Verknüpfung Vorschreibungsposition zu Aufteilungsschlüssel</>,
  },
  {
    path: URI_SYS_SETTINGS.vorschreibungspositionPage,
    Title: () => <>Vorschreibungspositionen</>,
  },
  {
    path: URI_SYS_SETTINGS.kontierungstabellePage,
    Title: () => <>Kontierungstabellen</>,
  },
  {
    path: URI_SYS_SETTINGS.textPage,
    Title: () => <>Texte</>,
  },
  {
    path: URI_SYS_SETTINGS.aufgabenartPage,
    Title: () => <>Aufgagenarten</>,
  },
  {
    path: URI_SYS_SETTINGS.auftragsartPage,
    Title: () => <>Auftragsarten</>,
  },
  {
    path: URI_SYS_SETTINGS_AUFTRAGSART_PAGE.createPage,
    Title: () => <>Neuanlage</>,
  },
  {
    path: URI_SYS_SETTINGS_AUFTRAGSART_PAGE.updatePage,
    Title: ({ auftragsartId }) => {
      // TODO: UPDATE once BE is implemented
      const { loading, data } = useAuftragsartKurzbezeichnungQuery({ variables: { auftragsartId } });
      const maybeData = data?.getAuftragsart.data;
      const bezeichnung = maybeData ? maybeData.bezeichnung : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={bezeichnung} />;
    },
  },
  {
    path: URI_SYS_SETTINGS.abrechnungskreisPage,
    Title: () => <>Abrechnungskreise</>,
  },
  {
    path: URI_SYS_SETTINGS.abrechnungsdefinitionPage,
    Title: () => <>Abrechnungsdefinitionen</>,
  },
  {
    path: URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION.createPage,
    Title: () => <>Neuanlage</>,
  },
  {
    path: URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.createPage,
    Title: ({ abrechnungsdefinitionId }) => {
      const { loading, data } = useAbrechnungsdefinitionKurzBezeichnungQuery({ variables: { abrechnungsdefinitionId } });

      const kurzBezeichnung = data?.getAbrechnungsdefinitionBezeichnung.data ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.updatePage,
    Title: ({ abrechnungsdefinitionId }) => {
      const { loading, data } = useAbrechnungsdefinitionKurzBezeichnungQuery({ variables: { abrechnungsdefinitionId } });

      const kurzBezeichnung = data?.getAbrechnungsdefinitionBezeichnung.data ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_SYS_SETTINGS.abrechnungKontenZuordnungPage,
    Title: () => <>Abr.Kontenzuordnung</>,
  },
  {
    path: URI_SYS_SETTINGS.ustKategoriePage,
    Title: () => <>Ust-Kategorien</>,
  },
  {
    path: URI_SYS_SETTINGS.ustRegelsetPage,
    Title: () => <>Ust-Regelwerke</>,
  },
  {
    path: URI_SYS_SETTINGS.kontenplanPage,
    Title: () => <>Kontenplan</>,
  },
  {
    path: URI_SYS_SETTINGS_KONTO_DETAILS_PAGE,
    Title: ({ kontoId }) => {
      const { loading, data } = useKontoKurzBezeichnungQuery({ variables: { kontoId } });

      const kurzBezeichnung = data?.getKonto.data.bezeichnung ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_SYS_SETTINGS.emailDeliveryDefinitionPage,
    Title: () => <>E-Mail Versanddefinition</>,
  },
  {
    path: URI_BK_ABRECHNUNG_DETAILS_PAGE,
    Title: ({ objektId, objektAbrechnungId }) => {
      const { loading, data } = useObjektAbrKurzBezeichnungQuery({ variables: { objektId, objektAbrechnungId } });

      const kurzBezeichnung = data?.getObjektAbrechnung.data.bezeichnung ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_ANDROMEDA_SYSTEM_SETTINGS,
    Title: () => <>Andromeda Einstellungen</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS.indexSeries,
    Title: () => <>Indexreihen</>,
  },
  {
    path: URI_ANDROMEDA_SYS_SETTINGS_INDEX_SERIES_DETAILS_PAGE,
    Title: ({ indexSeriesId }) => {
      const { loading, data } = useIndexSeriesQuery({ variables: { indexSeriesId } });

      const kurzBezeichnung = data?.getIndexSeries.data.name ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_SYS_SETTINGS.firmendatenIndexSeries,
    Title: () => <>Indexreihen</>,
  },
  {
    path: URI_SYS_SETTINGS_FIRMENDATEN_INDEX_SERIES_DETAILS_PAGE,
    Title: ({ indexSeriesId }) => {
      const { loading, data } = useFirmendatenIndexSeriesQuery({ variables: { indexSeriesId } });

      const kurzBezeichnung = data?.getFirmendatenIndexSeries.data.name ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_HE_ABRECHNUNG_DETAILS_PAGE,
    Title: ({ objektId, heAbrechnungId }) => {
      const { loading, data } = useHeAbrechnungKurzBezeichnungQuery({ variables: { objektId, heAbrechnungId } });

      const kurzBezeichnung = data?.getHeAbrechnung.data.bezeichnung ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_HE_ABRECHNUNG_DETAILS_HE_VERTRAG_DETAILS_PAGE,
    Title: ({ objektId, heAbrechnungId, heAbrechnungHeVertragId }) => {
      const { loading, data } = useHeAbrechnungHeVertragKurzBezeichnungQuery({ variables: { objektId, heAbrechnungId, heAbrechnungHeVertragId } });

      const rechtstraegerKurzBezeichnung = data?.getHeAbrechnungHeVertrag.data.hauseigentuemer.kurzBezeichnung ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={`Hauseigentümerabrechnung - ${rechtstraegerKurzBezeichnung}`} />;
    },
  },
  {
    path: URI_WE_ABRECHNUNG_DETAILS_PAGE,
    Title: ({ objektId, objektAbrechnungId }) => {
      const { loading, data } = useObjektAbrKurzBezeichnungQuery({ variables: { objektId, objektAbrechnungId } });

      const kurzBezeichnung = data?.getObjektAbrechnung.data.bezeichnung ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_RECHTSTRAEGER_PAGE,
    Title: () => <>Firmen / Personen</>,
  },
  {
    path: URI_RECHTSTRAEGER_DETAILS_PAGE,
    Title: ({ rechtstraegerId }) => {
      const { loading, data } = useRechtstraegerKurzBezeichnungQuery({ variables: { rechtstraegerId } });
      const kurzBezeichnung = data?.getRechtstraeger.data.kurzBezeichnung ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_SALDENLISTE_PAGE,
    Title: () => <>Saldenliste</>,
  },
  {
    path: URI_BK_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE,
    Title: ({ objektId, objektAbrechnungId, topAbrechnungId }) => {
      const { loading, data } = useTopAbrechnungKurzBezeichnungQuery({ variables: { objektId, objektAbrechnungId, topAbrechnungId } });
      const maybeData = data?.getTopAbrechnung.data;

      const kurzBezeichnung = maybeData ? `${maybeData.bezeichnung} - ${maybeData.vertragspartner.kurzBezeichnung}` : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_WE_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE,
    Title: ({ objektId, objektAbrechnungId, topAbrechnungId }) => {
      const { loading, data } = useTopAbrechnungKurzBezeichnungQuery({ variables: { objektId, objektAbrechnungId, topAbrechnungId } });
      const maybeData = data?.getTopAbrechnung.data;

      const kurzBezeichnung = maybeData ? `${maybeData.bezeichnung} - ${maybeData.vertragspartner.kurzBezeichnung}` : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_SYS_SETTINGS.mahndefinitionPage,
    Title: () => <>Mahndefinitionen</>,
  },
  {
    path: URI_SYS_SETTINGS_MAHNDEFINITION_PAGE.createPage,
    Title: () => <>Neuanlage</>,
  },
  {
    path: URI_SUB_ABRECHNUNG_DETAILS_PAGE,
    Title: ({ objektId, subAbrechnungId }) => {
      const { loading, data } = useSubAbrechnungQuery({ variables: { objektId, subAbrechnungId } });

      const kurzBezeichnung = data?.getSubAbrechnung.data.bezeichnung ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_SUB_ABRECHNUNG_EIGENTUEMER_DETAILS_PAGE,
    Title: ({ objektId, subAbrechnungId, subAbrechnungEigentuemerId }) => {
      const { loading, data } = useSubAbrechnungEigentuemerQuery({ variables: { objektId, subAbrechnungId, subAbrechnungEigentuemerId } });

      const kurzBezeichnung = data?.getSubAbrechnungEigentuemer.data.bezeichnung ?? '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_SYS_SETTINGS_MAHNDEFINITION_PAGE.updatePage,
    Title: ({ mahndefinitionId }) => {
      const { loading, data } = useMahndefinitionQuery({
        variables: {
          mahndefinitionId,
        },
      });
      const maybeData = data?.getMahndefinition.data;

      const kurzBezeichnung = maybeData ? maybeData.bezeichnung : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_INDEXED_VALUE_PAGE,
    Title: () => <>Indexierte Werte</>,
  },
  {
    path: URI_MAHNUNG_PAGE,
    Title: () => <>Mahnungen</>,
  },
  {
    path: URI_MAHNUNG_DETAILS_PAGE,
    Title: () => <>Mahnung</>,
  },
  {
    path: URI_VERARBEITUNG_PAGE,
    Title: () => <>Verarbeitungen</>,
  },
  {
    path: URI_VERARBEITUNG_BELEG_ERSTELLEN_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.VorschreibungAusgeben}</>,
  },
  {
    path: URI_VERARBEITUNG_BUDGETING_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.Budgeting}</>,
  },
  {
    path: URI_VERARBEITUNG_CAMT_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.Camt}</>,
  },
  {
    path: URI_VERARBEITUNG_MAHNUNG_AUSGEBEN_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.MahnungAusgabe}</>,
  },
  {
    path: URI_VERARBEITUNG_MAHNUNG_ERSTELLEN_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.MahnungErstellung}</>,
  },
  {
    path: URI_VERARBEITUNG_MAHNUNG_VERSENDEN_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.MahnungVersand}</>,
  },
  {
    path: URI_VERARBEITUNG_PAYMENT_PROPOSAL_CREATE_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.PaymentProposalCreate}</>,
  },
  {
    path: URI_VERARBEITUNG_PAYMENT_PROPOSAL_EXECUTE_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.PaymentProposalExecute}</>,
  },
  {
    path: URI_VERARBEITUNG_WE_OBJEKT_ABRECHNUNG_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.WeObjektAbrechnung}</>,
  },
  {
    path: URI_VERARBEITUNG_WE_TOP_ABRECHNUNG_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.WeTopAbrechnung}</>,
  },
  {
    path: URI_VERARBEITUNG_BK_OBJEKT_ABRECHNUNGEN_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.BkObjektAbrechnung}</>,
  },
  {
    path: URI_VERARBEITUNG_BK_TOP_ABRECHNUNGEN_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.BkTopAbrechnung}</>,
  },
  {
    path: URI_VERARBEITUNG_HE_ABRECHNUNG_ERSTELLEN_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.HeAbrechnungErstellung}</>,
  },
  {
    path: URI_VERARBEITUNG_UST_VOM_AUFWAND_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.UstVomAufwand}</>,
  },
  {
    path: URI_VERARBEITUNG_VORSCHREIBUNG_ERSTELLEN_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.Auftragserstellung}</>,
  },
  {
    path: URI_VERARBEITUNG_VORSTEUERKUERZUNG_ERSTELLEN_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.VorsteuerkuerzungErstellung}</>,
  },
  {
    path: URI_VERARBEITUNG_INFO_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.InfoMailDeliveryDetermineRecipientList}</>,
  },
  {
    path: URI_VERARBEITUNG_INFO_MAIL_DELIVERY_SEND_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.InfoMailDeliverySend}</>,
  },
  {
    path: URI_VERARBEITUNG_OBJEKT_FINANCIAL_PLAN,
    Title: () => <>{verarbeitungDetailsCardTitles.ObjektFinancialPlan}</>,
  },
  {
    path: URI_VERARBEITUNG_TOP_FINANCIAL_PLAN,
    Title: () => <>{verarbeitungDetailsCardTitles.TopFinancialPlan}</>,
  },
  {
    path: URI_VERARBEITUNG_SYNC_EBICS_USER_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.SyncEbicsUser}</>,
  },
  {
    path: URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.VorschreibungMailDeliveryDetermineRecipientList}</>,
  },
  {
    path: URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_SEND_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.VorschreibungMailDeliverySend}</>,
  },
  {
    path: URI_VERARBEITUNG_ZINSLISTE_ERSTELLEN_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.ZinslisteErstellung}</>,
  },
  {
    path: URI_VERARBEITUNG_FIRMENDATEN_INITIALIZER_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.FirmendatenInitializer}</>,
  },
  {
    path: URI_VERARBEITUNG_CAMT_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.Camt}</>,
  },
  {
    path: URI_VERARBEITUNG_CAMT_EBICS_MANUALLY_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.CamtEbicsManually}</>,
  },
  {
    path: URI_VERARBEITUNG_CAMT_ONLINE_BANKING_MANUALLY_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.CamtOnlineBankingManually}</>,
  },
  {
    path: URI_VERARBEITUNG_DATA_CARRIER_PAIN_CREATION_DETAILS_PAGE,
    Title: () => <>{verarbeitungDetailsCardTitles.DataCarrierPainCreation}</>,
  },
  {
    path: URI_VORSTEUERKUERZUNG_DETAILS_PAGE,
    Title: ({ objektId, vorsteuerkuerzungId }) => {
      const { loading, data } = useVorsteuerkuerzungQuery({
        variables: {
          objektId,
          vorsteuerkuerzungId,
        },
      });
      const maybeData = data?.getVorsteuerkuerzung.data;

      const kurzBezeichnung = maybeData ? `Vst-Kürz ${dayjsCustom(maybeData.vonInklusive).format('M/YYYY')}` : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_UST_VOM_AUFWAND_DETAILS_PAGE,
    Title: ({ objektId, ustVomAufwandId }) => {
      const { loading, data } = useUstVomAufwandQuery({
        variables: {
          objektId,
          ustVomAufwandId,
        },
      });
      const maybeData = data?.getUstVomAufwand.data;

      const kurzBezeichnung = maybeData ? `Ust. vom Aufwand ${dayjsCustom(maybeData.vonInklusive).format('M/YYYY')}` : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_SUB_ADMINISTRATION_DETAILS_PAGE,
    Title: ({ objektId, subAdministrationId }) => {
      const { loading, data } = useSubAdministrationQuery({
        variables: {
          objektId,
          subAdministrationId,
        },
      });
      const maybeData = data?.getSubAdministration.data;

      const kurzBezeichnung = maybeData ? maybeData.eigentuemerBezeichnung : '';

      return <BreadcrumbLoadingText data={data} loading={loading} text={kurzBezeichnung} />;
    },
  },
  {
    path: URI_TICKET_PAGE,
    Title: () => <>Aufgaben</>,
  },
  {
    path: URI_TICKET_DETAILS_PAGE,
    Title: ({ ticketId }) => <TicketDetailsPageBreadcrumb ticketId={ticketId} />,
  },
  {
    path: URI_SUB_TICKET_DETAILS_PAGE,
    Title: ({ subTicketId }) => {
      const { loading: loadingTicket, data: ticketData } = useTicketQuery({ variables: { ticketId: subTicketId } });
      const ticket = ticketData?.getTicket.data;
      const ticketTitle = ticket?.title ?? '';

      return <BreadcrumbLoadingText data={ticketData} loading={loadingTicket} text={ticketTitle} />;
    },
  },
];

type BreadcrumbLoadingTextProps = {
  data: unknown;
  loading: boolean;
  text: string | React.ReactNode;
};

export const BreadcrumbLoadingText: FC<BreadcrumbLoadingTextProps> = ({ data, loading, text }) => {
  if (!data && loading) {
    return <span>...</span>;
  } else {
    return <span>{text}</span>;
  }
};

export default pathToBreadcrumbMappings;
