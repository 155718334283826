import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import ContactEmail from '../../../../../../shared/InfoFeld/ListItem/ContactEmail';
import ContactPhone from '../../../../../../shared/InfoFeld/ListItem/ContactPhone';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../../Rechtstraeger/rechtstraegerHelper';
import DataWithShortenedText from '../../../../../../../components/Helpers/DataWithShortenedText';
import { useRechtstraegerAddressesAndContactsQuery } from '../../../../../../Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsQueries.types';

type PersonInfoWithLinkToDetailsProps = {
  rechtstraegerId: string;
  personBezeichnung: string;
};

const PersonInfoWithLinkToDetails: FC<PersonInfoWithLinkToDetailsProps> = ({ rechtstraegerId, personBezeichnung }) => {
  const { data } = useRechtstraegerAddressesAndContactsQuery({ variables: { rechtstraegerId } });

  const addressesAndContacts = data?.getRechtstraegerAddressesAndContacts.data;

  const getFirstEmail = addressesAndContacts?.emailContactList[0];
  const getFirstTelefon = addressesAndContacts?.phoneContactList[0];

  return (
    <Space style={{ width: '100%' }}>
      <DataWithShortenedText text={personBezeichnung} maxTextLength={30}>
        {(shortenedText) => <Link to={generatePathToRechtstraegerDetailsPage(rechtstraegerId)}>{shortenedText}</Link>}
      </DataWithShortenedText>
      {getFirstEmail && <ContactEmail email={getFirstEmail.wert} />}
      {getFirstTelefon && <ContactPhone phone={getFirstTelefon.wert} />}
    </Space>
  );
};

export default PersonInfoWithLinkToDetails;
