import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppKontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import { BankDetailsFieldsFragmentDoc } from '../../BankDetails/gql/BankDetailsFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RepFondsErloesKontenZuweisungListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RepFondsErloesKontenZuweisungListQuery = {
  getRepFondsErloesKontenZuweisungList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      repFondsErloesKontenZuweisungId: string;
      updatedByMitarbeiterId?: string | null;
      erloesKontoList: Array<{ bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string }>;
      fibuBankAccount: {
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RepFondsErloesKontenZuweisungQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  repFondsErloesKontenZuweisungId: Types.Scalars['ID']['input'];
}>;

export type RepFondsErloesKontenZuweisungQuery = {
  getRepFondsErloesKontenZuweisung: {
    data: {
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      repFondsErloesKontenZuweisungId: string;
      erloesKontoList: Array<{ bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string }>;
      fibuBankAccount: {
        bankname: string;
        bankDetailsId: string;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        accountHolder: string;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
      };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RepFondsErloesKontenZuweisungChangeHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  repFondsErloesKontenZuweisungId: Types.Scalars['ID']['input'];
}>;

export type RepFondsErloesKontenZuweisungChangeHistoryListQuery = {
  getRepFondsErloesKontenZuweisungChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const RepFondsErloesKontenZuweisungListDocument = gql`
  query RepFondsErloesKontenZuweisungList($rechtstraegerId: ID!) {
    getRepFondsErloesKontenZuweisungList(rechtstraegerId: $rechtstraegerId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        erloesKontoList {
          ...AppKontoFields
        }
        fibuBankAccount {
          ...BankDetailsFields
        }
        repFondsErloesKontenZuweisungId
        updatedByMitarbeiterId
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AppKontoFieldsFragmentDoc}
  ${BankDetailsFieldsFragmentDoc}
`;
export function useRepFondsErloesKontenZuweisungListQuery(
  baseOptions: Apollo.QueryHookOptions<RepFondsErloesKontenZuweisungListQuery, RepFondsErloesKontenZuweisungListQueryVariables> &
    ({ variables: RepFondsErloesKontenZuweisungListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RepFondsErloesKontenZuweisungListQuery, RepFondsErloesKontenZuweisungListQueryVariables>(
    RepFondsErloesKontenZuweisungListDocument,
    options
  );
}
export function useRepFondsErloesKontenZuweisungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RepFondsErloesKontenZuweisungListQuery, RepFondsErloesKontenZuweisungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RepFondsErloesKontenZuweisungListQuery, RepFondsErloesKontenZuweisungListQueryVariables>(
    RepFondsErloesKontenZuweisungListDocument,
    options
  );
}
export function useRepFondsErloesKontenZuweisungListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RepFondsErloesKontenZuweisungListQuery, RepFondsErloesKontenZuweisungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RepFondsErloesKontenZuweisungListQuery, RepFondsErloesKontenZuweisungListQueryVariables>(
    RepFondsErloesKontenZuweisungListDocument,
    options
  );
}
export type RepFondsErloesKontenZuweisungListQueryHookResult = ReturnType<typeof useRepFondsErloesKontenZuweisungListQuery>;
export type RepFondsErloesKontenZuweisungListLazyQueryHookResult = ReturnType<typeof useRepFondsErloesKontenZuweisungListLazyQuery>;
export type RepFondsErloesKontenZuweisungListSuspenseQueryHookResult = ReturnType<typeof useRepFondsErloesKontenZuweisungListSuspenseQuery>;
export type RepFondsErloesKontenZuweisungListQueryResult = Apollo.QueryResult<
  RepFondsErloesKontenZuweisungListQuery,
  RepFondsErloesKontenZuweisungListQueryVariables
>;
export const RepFondsErloesKontenZuweisungDocument = gql`
  query RepFondsErloesKontenZuweisung($rechtstraegerId: ID!, $repFondsErloesKontenZuweisungId: ID!) {
    getRepFondsErloesKontenZuweisung(rechtstraegerId: $rechtstraegerId, repFondsErloesKontenZuweisungId: $repFondsErloesKontenZuweisungId) {
      data {
        createdBy
        createdByMitarbeiterId
        createTs
        erloesKontoList {
          bezeichnung
          firmendatenId
          kontoId
          kontoKlasse
          nummer
        }
        fibuBankAccount {
          bankname
          bankDetailsId
          fibuKontoId
          fibuKontonummer
          fibuStatus {
            text
            value
          }
          fibuVerwendung {
            text
            value
          }
          iban
          accountHolder
        }
        repFondsErloesKontenZuweisungId
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRepFondsErloesKontenZuweisungQuery(
  baseOptions: Apollo.QueryHookOptions<RepFondsErloesKontenZuweisungQuery, RepFondsErloesKontenZuweisungQueryVariables> &
    ({ variables: RepFondsErloesKontenZuweisungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RepFondsErloesKontenZuweisungQuery, RepFondsErloesKontenZuweisungQueryVariables>(
    RepFondsErloesKontenZuweisungDocument,
    options
  );
}
export function useRepFondsErloesKontenZuweisungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RepFondsErloesKontenZuweisungQuery, RepFondsErloesKontenZuweisungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RepFondsErloesKontenZuweisungQuery, RepFondsErloesKontenZuweisungQueryVariables>(
    RepFondsErloesKontenZuweisungDocument,
    options
  );
}
export function useRepFondsErloesKontenZuweisungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RepFondsErloesKontenZuweisungQuery, RepFondsErloesKontenZuweisungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RepFondsErloesKontenZuweisungQuery, RepFondsErloesKontenZuweisungQueryVariables>(
    RepFondsErloesKontenZuweisungDocument,
    options
  );
}
export type RepFondsErloesKontenZuweisungQueryHookResult = ReturnType<typeof useRepFondsErloesKontenZuweisungQuery>;
export type RepFondsErloesKontenZuweisungLazyQueryHookResult = ReturnType<typeof useRepFondsErloesKontenZuweisungLazyQuery>;
export type RepFondsErloesKontenZuweisungSuspenseQueryHookResult = ReturnType<typeof useRepFondsErloesKontenZuweisungSuspenseQuery>;
export type RepFondsErloesKontenZuweisungQueryResult = Apollo.QueryResult<
  RepFondsErloesKontenZuweisungQuery,
  RepFondsErloesKontenZuweisungQueryVariables
>;
export const RepFondsErloesKontenZuweisungChangeHistoryListDocument = gql`
  query RepFondsErloesKontenZuweisungChangeHistoryList($rechtstraegerId: ID!, $repFondsErloesKontenZuweisungId: ID!) {
    getRepFondsErloesKontenZuweisungChangeHistoryList(
      rechtstraegerId: $rechtstraegerId
      repFondsErloesKontenZuweisungId: $repFondsErloesKontenZuweisungId
    ) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useRepFondsErloesKontenZuweisungChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    RepFondsErloesKontenZuweisungChangeHistoryListQuery,
    RepFondsErloesKontenZuweisungChangeHistoryListQueryVariables
  > &
    ({ variables: RepFondsErloesKontenZuweisungChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RepFondsErloesKontenZuweisungChangeHistoryListQuery, RepFondsErloesKontenZuweisungChangeHistoryListQueryVariables>(
    RepFondsErloesKontenZuweisungChangeHistoryListDocument,
    options
  );
}
export function useRepFondsErloesKontenZuweisungChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RepFondsErloesKontenZuweisungChangeHistoryListQuery,
    RepFondsErloesKontenZuweisungChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RepFondsErloesKontenZuweisungChangeHistoryListQuery, RepFondsErloesKontenZuweisungChangeHistoryListQueryVariables>(
    RepFondsErloesKontenZuweisungChangeHistoryListDocument,
    options
  );
}
export function useRepFondsErloesKontenZuweisungChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RepFondsErloesKontenZuweisungChangeHistoryListQuery,
        RepFondsErloesKontenZuweisungChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RepFondsErloesKontenZuweisungChangeHistoryListQuery, RepFondsErloesKontenZuweisungChangeHistoryListQueryVariables>(
    RepFondsErloesKontenZuweisungChangeHistoryListDocument,
    options
  );
}
export type RepFondsErloesKontenZuweisungChangeHistoryListQueryHookResult = ReturnType<typeof useRepFondsErloesKontenZuweisungChangeHistoryListQuery>;
export type RepFondsErloesKontenZuweisungChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useRepFondsErloesKontenZuweisungChangeHistoryListLazyQuery
>;
export type RepFondsErloesKontenZuweisungChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useRepFondsErloesKontenZuweisungChangeHistoryListSuspenseQuery
>;
export type RepFondsErloesKontenZuweisungChangeHistoryListQueryResult = Apollo.QueryResult<
  RepFondsErloesKontenZuweisungChangeHistoryListQuery,
  RepFondsErloesKontenZuweisungChangeHistoryListQueryVariables
>;
