import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';
import { Kuendigungsfrist, KuendigungsfristJeweilsZum, Zahlungsperiode } from '../../../../../../types';
import { FormFields } from '../../../../../../helpers/formikHelper';

export interface VersicherungCreateFormValues extends InfoFeldFormValues {
  versicherungsart: string;
  firmaId?: string;
  contactPersonId?: string;
  beginn?: string;
  datumSchaetzung?: string;
  ende?: string;
  erstmalsFaellig?: string;
  validFrom?: string;
  hauptDueDate?: string;
  kuendigungsfrist: Kuendigungsfrist | null;
  kuendigungsfristDauer: number | null;
  kuendigungsfristJeweilsZum?: KuendigungsfristJeweilsZum;
  maklerId?: string;
  neubauwert?: number;
  polizze?: string;
  sachbearbeiterId?: string;
  versicherungssumme?: string;
  wertsicherung?: string;
  zahlungsperiode?: Zahlungsperiode;
}

export const versicherungCreateFormFields: FormFields<VersicherungCreateFormValues> = {
  contactPersonId: 'contactPersonId',
  beginn: 'beginn',
  datumSchaetzung: 'datumSchaetzung',
  ende: 'ende',
  erstmalsFaellig: 'erstmalsFaellig',
  firmaId: 'firmaId',
  validFrom: 'validFrom',
  hauptDueDate: 'hauptDueDate',
  kuendigungsfrist: 'kuendigungsfrist',
  kuendigungsfristDauer: 'kuendigungsfristDauer',
  kuendigungsfristJeweilsZum: 'kuendigungsfristJeweilsZum',
  maklerId: 'maklerId',
  neubauwert: 'neubauwert',
  polizze: 'polizze',
  sachbearbeiterId: 'sachbearbeiterId',
  versicherungsart: 'versicherungsart',
  versicherungssumme: 'versicherungssumme',
  wertsicherung: 'wertsicherung',
  zahlungsperiode: 'zahlungsperiode',
  documentList: 'documentList',
};

export const versicherungCreateFormInitialValues: VersicherungCreateFormValues = {
  documentList: [],
  versicherungsart: '',
  kuendigungsfrist: null,
  kuendigungsfristDauer: null,
};
