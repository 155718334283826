import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { URI_VERARBEITUNG_SYNC_EBICS_USER_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import SyncEbicsUserDetailsPage from './SyncEbicsUserDetailsPage';

export type SyncEbicsUserPageRouteParams = { generierlaufId: string };

export const verarbeitungSyncEbicsUserDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_SYNC_EBICS_USER_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<SyncEbicsUserDetailsPage />} />}
  />
);
