import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';
import EbicsUserForm from '../Form/EbicsUserForm';

type Props = {
  onAction: () => void;
};

const EbicsUserCreateBtnAndDrawer: FC<Props> = ({ onAction }) => {
  const [isDrawerOpen, toggleDrawer] = useToggle();

  const onSuccessAction = () => {
    toggleDrawer();
    onAction();
  };

  return (
    <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
      <Button icon={<PlusOutlined />} type="primary" onClick={toggleDrawer}>
        EBICS Bankteilnehmer anlegen
      </Button>
      <Drawer title="EBICS Bankteilnehmer anlegen" width={720} onClose={toggleDrawer} open={isDrawerOpen} destroyOnClose>
        <EbicsUserForm onCancel={toggleDrawer} onSuccess={onSuccessAction} />
      </Drawer>
    </ButtonsAligned>
  );
};

export default EbicsUserCreateBtnAndDrawer;
