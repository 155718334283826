import React, { FC, useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { Space } from 'antd';
import { MahnungUpdateFormValues, mapFormValuesToMahnungUpdate, mapMahnungToFormValues } from './mahnungUpdateFormMapper';
import { useSumVerzugszinsenQuery } from '../../gql/MahnungQueries.types';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useUpdateMahnungMutation } from '../../gql/MahnungMutations.types';
import { mahnungUpdateFormValidationSchema } from './mahnungUpdateFormValidationSchema';
import MahnungUpdateFormGeneralParams from './MahnungUpdateFormGeneralParams';
import MahnpositionTable from './Mahnposition/MahnpositionTable';
import MahnungUpdateFormButtons from './MahnungUpdateFormButtons';
import RouterPrompt from '../../../../components/Prompt/RouterPrompt';
import { Mahnung, MahnungStatus } from '../../../../types';

type Props = {
  mahnung: Mahnung;
  onActionSuccess: () => void;
};

const MahnungUpdateForm: FC<Props> = ({ mahnung, onActionSuccess }) => {
  const { sumVerzugszinsen, onMahnPositionVerzugszinsenBerechnenChange, setMahnungVerzugszinsenProzent } = useCalculateSumVerzugszinsen(mahnung);

  const [updateMahnung, { loading: loadingUpdate }] = useUpdateMahnungMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Mahnung`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });
  const isStatusErstellt = mahnung.status.value === MahnungStatus.Erstellt;

  return (
    <Formik<MahnungUpdateFormValues>
      initialValues={mapMahnungToFormValues(mahnung)}
      validationSchema={mahnungUpdateFormValidationSchema}
      onSubmit={(values) => {
        updateMahnung({
          variables: {
            mahnungId: mahnung.mahnungId,
            request: mapFormValuesToMahnungUpdate(values),
          },
        });
      }}
      enableReinitialize
    >
      {(formikProps) => (
        <>
          <Form>
            <Space size={24} direction="vertical" style={{ width: '100%' }}>
              <MahnungUpdateFormGeneralParams
                onMahnungVerzugszinsenProzentChange={setMahnungVerzugszinsenProzent}
                isStatusErstellt={isStatusErstellt}
                sumVerzugszinsenData={sumVerzugszinsen}
                isVersandEinschreiben={formikProps.values.versandEinschreiben}
              />
              <MahnpositionTable
                mahnungId={mahnung.mahnungId}
                formikProps={formikProps}
                isStatusErstellt={isStatusErstellt}
                onMahnPositionVerzugszinsenBerechnenChange={onMahnPositionVerzugszinsenBerechnenChange}
                sumVerzugszinsen={sumVerzugszinsen}
                vertragspartnerId={mahnung.vertragspartner.rechtstraegerId}
                fibuKontoId={mahnung.debitorKonto.kontoId}
              />
              <MahnungUpdateFormButtons formikProps={formikProps} mahnung={mahnung} loadingUpdate={loadingUpdate} />
            </Space>
          </Form>
          <RouterPrompt when={formikProps.dirty} />
        </>
      )}
    </Formik>
  );
};

const useCalculateSumVerzugszinsen = (mahnung: Mahnung) => {
  const [mahnPositionWithVerzugszinsenIds, setMahnPositionWithVerzugszinsenIds] = useState<string[]>(
    mahnung.mahnPositionList.filter((mp) => mp.verzugszinsenBerechnen).map((mp) => mp.mahnPositionId)
  );
  const onMahnPositionVerzugszinsenBerechnenChange = useCallback((isChecked: boolean, mahnPositionId: string) => {
    if (isChecked) {
      setMahnPositionWithVerzugszinsenIds((prevState) => [...prevState, mahnPositionId]);
    } else {
      setMahnPositionWithVerzugszinsenIds((prevState) => prevState.filter((mpId) => mpId !== mahnPositionId));
    }
  }, []);

  const [mahnungVerzugszinsenProzent, setMahnungVerzugszinsenProzent] = useState<number>(mahnung.verzugszinsen);

  const { data: sumVerzugszinsenData } = useSumVerzugszinsenQuery({
    variables: {
      mahnungId: mahnung.mahnungId,
      mahnPositionIdList: mahnPositionWithVerzugszinsenIds,
      verzugszinsenProzent: mahnungVerzugszinsenProzent,
    },
  });
  const sumVerzugszinsen = sumVerzugszinsenData?.getSumVerzugszinsen.data;

  return { sumVerzugszinsen, onMahnPositionVerzugszinsenBerechnenChange, setMahnungVerzugszinsenProzent };
};

export default MahnungUpdateForm;
