import { PaymentTransactionType } from '../../../../../types';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../../shared/typeHelpers';
import { TIbanQueryParams } from './filtersQueryParams';

export type FiltersFormValues = {
  accountHolder?: string | null;
  bankname?: string | null;
  bic?: string | null;
  fibuAccountNumber?: string | null;
  iban?: string | null;
  lastBankStatementFrom?: string | null;
  lastBankStatementTo?: string | null;
  paymentTransactionType?: PaymentTransactionType | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  accountHolder: 'accountHolder',
  bankname: 'bankname',
  bic: 'bic',
  fibuAccountNumber: 'fibuAccountNumber',
  iban: 'iban',
  lastBankStatementFrom: 'lastBankStatementFrom',
  lastBankStatementTo: 'lastBankStatementTo',
  paymentTransactionType: 'paymentTransactionType',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TIbanQueryParams => ({
  accountHolder: formValues.accountHolder,
  bankname: formValues.bankname,
  bic: formValues.bic,
  fibuAccountNumber: formValues.fibuAccountNumber,
  iban: formValues.iban,
  lastBankStatementFrom: formValues.lastBankStatementFrom ? mapFormDateValueToDateString(formValues.lastBankStatementFrom) : null,
  lastBankStatementTo: formValues.lastBankStatementTo ? mapFormDateValueToDateString(formValues.lastBankStatementTo) : null,
  paymentTransactionType: formValues.paymentTransactionType,
});

export const mapQueryParamsToFormValues = (queryParams: TIbanQueryParams): FiltersFormValues => ({
  accountHolder: queryParams.accountHolder,
  bankname: queryParams.bankname,
  bic: queryParams.bic,
  fibuAccountNumber: queryParams.fibuAccountNumber,
  iban: queryParams.iban,
  lastBankStatementFrom: queryParams.lastBankStatementFrom,
  lastBankStatementTo: queryParams.lastBankStatementTo,
  paymentTransactionType: queryParams.paymentTransactionType,
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'lastBankStatementTo'>> = {
  [filtersFormFields.accountHolder]: 'Kontoinhaber',
  [filtersFormFields.bankname]: 'Bankname',
  [filtersFormFields.bic]: 'BIC',
  [filtersFormFields.fibuAccountNumber]: 'FIBU-Kontonummer',
  [filtersFormFields.iban]: 'IBAN',
  [filtersFormFields.lastBankStatementFrom]: 'Letzter Kontoauszug',
  [filtersFormFields.paymentTransactionType]: 'Zahlungsverkehrsart',
};
