import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { LabeledValue } from '../../../shared/types';

type VTBelegDetailsProps = {
  belegnummer: LabeledValue;
  belegOrtUndDatum: string;
};

const VTBelegDetails: FC<VTBelegDetailsProps> = ({ belegnummer, belegOrtUndDatum }) => (
  <View style={pdfStyles.column}>
    <Text style={pdfStyles.textNormal}>
      {belegnummer.label} {belegnummer.value}
    </Text>
    <Text style={pdfStyles.textNormal}>{belegOrtUndDatum}</Text>
  </View>
);

export default VTBelegDetails;
