import React from 'react';
import { Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { DataCarrierPainListEntry, DataCarrierPainOrderBy } from '../../../../../types';
import { EuroAmount } from '../../../../../components/Number';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import XmlDataCarrierTableColumn from '../../shared/XmlDataCarrierTableColumn';
import { NestedTableColProps } from '../../../../../components/Table/NestedTable/NestedTable';
import DataCarrierIbanListColumn from '../../shared/DataCarrierIbanListColumn';
import DirectDebitOrBankWireTableActions from './DirectDebitOrBankWireTableActions';
import DirectDebitOrBankWireStatusBadge from './DirectDebitOrBankWireStatusBadge';

const directDebitOrBankWireColumns = (firmendatenId: string, onAction: () => void): NestedTableColProps<DataCarrierPainListEntry>[] => [
  {
    title: 'Datenträgertyp',
    dataIndex: DataCarrierPainOrderBy.DataCarrierType,
    sorter: true,
    render: (text, record) => <DataWithShortenedText text={record.dataCarrierType.text} maxTextLength={20} />,
  },
  {
    title: 'Bezeichnung',
    dataIndex: DataCarrierPainOrderBy.Name,
    sorter: true,
    render: (text, record) => <DataWithShortenedText text={record.name} maxTextLength={20} />,
  },
  {
    title: 'Status',
    dataIndex: DataCarrierPainOrderBy.Status,
    width: 200,
    sorter: true,
    render: (text, record) => <DirectDebitOrBankWireStatusBadge status={record.status} />,
  },
  {
    title: 'Zahlungsvorschlag',
    dataIndex: DataCarrierPainOrderBy.PaymentProposalName,
    sorter: true,
    render: (text, record) => <DataWithShortenedText text={record.paymentProposalName} maxTextLength={20} />,
  },
  {
    title: 'Zahlungsverkehrsart: EBICS / Onlinebanking',
    dataIndex: DataCarrierPainOrderBy.PaymentTransactionType,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.paymentTransactionType.text}</Typography.Text>,
  },
  {
    title: 'Bankverbindungen',
    render: (text, record) => <DataCarrierIbanListColumn ibanList={record.ibanList} maxTextLength={20} />,
  },
  {
    title: 'XML Datenträger',
    render: (text, record) => (
      <XmlDataCarrierTableColumn dataCarrierId={record.dataCarrierId} xmlFilename={record.xmlFilename} firmendatenId={firmendatenId} />
    ),
  },
  {
    title: 'Anzahl Transaktionen',
    dataIndex: DataCarrierPainOrderBy.CountTransactionInstructions,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.countTransactionInstructions}</Typography.Text>,
  },
  {
    title: 'Summe Transaktionen',
    dataIndex: DataCarrierPainOrderBy.SumTransactionInstruction,
    sorter: true,
    render: (text, record) => <EuroAmount value={record.sumTransactionInstruction} />,
  },
  {
    title: 'Erstellt am',
    dataIndex: DataCarrierPainOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    dataIndex: DataCarrierPainOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    render: (text, record) => <DirectDebitOrBankWireTableActions dataCarrier={record} onAction={onAction} />,
  },
];

export default directDebitOrBankWireColumns;
