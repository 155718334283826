import React, { FC } from 'react';
import TopAbrechnungTemplate from '../../../../../pages/PDFTemplates/templates/topAbrechnung/TopAbrechnungTemplate';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { TopAbrDefGeneralSettingsFormValues } from '../../../../Abrechnungsdefinition/shared/Templates/shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import TemplateAbrechnung from '../../../../Abrechnungsdefinition/shared/Templates/TemplateAbrechnung';
import {
  createTopAbrechnungTemplatePDFData,
  mapTextbausteinToTopAbrechnungSelectableSection,
} from '../../../../Abrechnungsdefinition/shared/Templates/WeTemplates/weAbrDefTemplateFormTopAbrMapper';

type TemplateTopAbrechnungProps = {
  values: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  topAbrDefGeneralSettingsValues: TopAbrDefGeneralSettingsFormValues;
};

const TemplateTopAbrechnung: FC<TemplateTopAbrechnungProps> = ({ values, onTemplateChange, topAbrDefGeneralSettingsValues }) => (
  <TemplateAbrechnung
    values={values}
    onTemplateChange={onTemplateChange}
    renderPreview={({ selectedTextbaustein }) => (
      <TopAbrechnungTemplate
        data={createTopAbrechnungTemplatePDFData(values, topAbrDefGeneralSettingsValues, selectedTextbaustein)}
        isInEditMode
        selectedTextbaustein={mapTextbausteinToTopAbrechnungSelectableSection(selectedTextbaustein)}
      />
    )}
  />
);

export default TemplateTopAbrechnung;
