export interface ErrorModel extends Error {
  validationError: boolean;
  unauthorizedError: boolean;
  errorList: [];
  type?: string;
  notFound: string;
}

export const mapErrorToErrorMessage = (error: ErrorModel) => {
  if (error.validationError) {
    return mapErrorListToErrorMessageList(error.errorList).join();
  }
  return error.message;
};

export const mapErrorListToErrorMessageList = (errorList: Error[]) => mapObjectListToMessageList(errorList);

export const mapErrorListToErrorTypeList = (errorList: ErrorModel[]) => {
  if (!errorList) {
    return [];
  }
  return errorList.map((error) => error.type);
};

export const mapWarningListToWarningMessageList = (warningList: { message: string }[]) => mapObjectListToMessageList(warningList);

const mapObjectListToMessageList = (objectList: { message: string }[]) => {
  if (!objectList) {
    return [];
  }
  return objectList.map((object) => object.message);
};
