import React, { FC, JSX } from 'react';
import { FormikProps } from 'formik';
import { zinslisteListingFiltersFormFields, ZinslisteListingFiltersFormValues, zinslisteListingLabelList } from './zinslisteListingFiltersFormMapper';
import ObjektSelect from '../../../ObjektSelect/ObjektSelect';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import { TZinslisteQueryParams } from './filtersQueryParams';
import ZStatusFormSelect from './ZStatusFormSelect';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import useFilterWithQueryParamsNew from '../../../../components/Filters/useFilterWithQueryParamsNew';
import FiltersWithSelectorWrapper from '../../../../components/Filters/FiltersWithSelector/FiltersWithSelectorWrapper';
import FormItemForFilter from '../../../../components/Form/FormItemForFilter';
import FromToFormInputNumberForFilter from '../../../shared/FromToForm/FromToFormInputNumberForFilter';

type Props = {
  actionButtons: JSX.Element;
  formikProps: FormikProps<ZinslisteListingFiltersFormValues>;
  queryParams: TZinslisteQueryParams;
};

const ZinslisteListingFilters: FC<Props> = ({ actionButtons, formikProps, queryParams }) => {
  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (
      filter === zinslisteListingFiltersFormFields.erstellDatumVonInklusive ||
      filter === zinslisteListingFiltersFormFields.erstellDatumBisInklusive
    ) {
      return zinslisteListingFiltersFormFields.erstellDatumVonInklusive;
    }
    if (filter === zinslisteListingFiltersFormFields.bruttoFrom || filter === zinslisteListingFiltersFormFields.bruttoTo) {
      return zinslisteListingFiltersFormFields.bruttoFrom;
    }
    if (filter === zinslisteListingFiltersFormFields.nettoFrom || filter === zinslisteListingFiltersFormFields.nettoTo) {
      return zinslisteListingFiltersFormFields.nettoFrom;
    }
    if (
      filter === zinslisteListingFiltersFormFields.vorschreibungsperiodeFrom ||
      filter === zinslisteListingFiltersFormFields.vorschreibungsperiodeTo
    ) {
      return zinslisteListingFiltersFormFields.vorschreibungsperiodeFrom;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === zinslisteListingFiltersFormFields.vorschreibungsperiodeFrom) {
      return (
        <FormItemForFilter name={zinslisteListingFiltersFormFields.vorschreibungsperiodeFrom} key={filter}>
          <FromToFormDatePicker
            format="month"
            fromName={zinslisteListingFiltersFormFields.vorschreibungsperiodeFrom}
            toName={zinslisteListingFiltersFormFields.vorschreibungsperiodeTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${zinslisteListingLabelList.vorschreibungsperiodeFrom} - von`}
            toPrefix={`${zinslisteListingLabelList.vorschreibungsperiodeFrom} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === zinslisteListingFiltersFormFields.objektId) {
      return (
        <FormItemForFilter name={zinslisteListingFiltersFormFields.objektId} key={filter}>
          <ObjektSelect
            name={zinslisteListingFiltersFormFields.objektId}
            style={{ width: '100%' }}
            onChange={formikProps.submitForm}
            prefix={zinslisteListingLabelList.objektId}
            size="middle"
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === zinslisteListingFiltersFormFields.erstellDatumVonInklusive) {
      return (
        <FormItemForFilter name="Erstelldatum" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={zinslisteListingFiltersFormFields.erstellDatumVonInklusive}
            toName={zinslisteListingFiltersFormFields.erstellDatumBisInklusive}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${zinslisteListingLabelList.erstellDatumVonInklusive} - von`}
            toPrefix={`${zinslisteListingLabelList.erstellDatumVonInklusive} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === zinslisteListingFiltersFormFields.erstellerList) {
      return (
        <FormItemForFilter name={zinslisteListingFiltersFormFields.erstellerList} key={filter}>
          <MitarbeiterSelect
            mode="multiple"
            name={zinslisteListingFiltersFormFields.erstellerList}
            onChange={formikProps.submitForm}
            prefix={zinslisteListingLabelList.erstellerList}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === zinslisteListingFiltersFormFields.bruttoFrom) {
      return (
        <FromToFormInputNumberForFilter
          name={zinslisteListingLabelList.bruttoFrom}
          fromName={zinslisteListingFiltersFormFields.bruttoFrom}
          toName={zinslisteListingFiltersFormFields.bruttoTo}
          onChange={formikProps.submitForm}
          key={filter}
          fromPrefix={`${zinslisteListingLabelList.bruttoFrom} - von`}
          toPrefix={`${zinslisteListingLabelList.bruttoFrom} - bis`}
        />
      );
    }
    if (filter === zinslisteListingFiltersFormFields.nettoFrom) {
      return (
        <FromToFormInputNumberForFilter
          name={zinslisteListingLabelList.nettoFrom}
          fromName={zinslisteListingFiltersFormFields.nettoFrom}
          toName={zinslisteListingFiltersFormFields.nettoTo}
          onChange={formikProps.submitForm}
          key={filter}
          fromPrefix={`${zinslisteListingLabelList.nettoFrom} - von`}
          toPrefix={`${zinslisteListingLabelList.nettoFrom} - bis`}
        />
      );
    }
    if (filter === zinslisteListingFiltersFormFields.status) {
      return (
        <FormItemForFilter name={zinslisteListingFiltersFormFields.status} key={filter}>
          <ZStatusFormSelect name={zinslisteListingLabelList.status} prefix={zinslisteListingLabelList.status} />
        </FormItemForFilter>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(zinslisteListingFiltersFormFields.erstellDatumBisInklusive) && formikProps.values.erstellDatumVonInklusive) {
      formikProps.setFieldValue(zinslisteListingFiltersFormFields.erstellDatumVonInklusive, undefined);
    }
    if (!selectedFilterList.includes(zinslisteListingFiltersFormFields.bruttoFrom) && formikProps.values.bruttoTo) {
      formikProps.setFieldValue(zinslisteListingFiltersFormFields.bruttoTo, undefined);
    }
    if (!selectedFilterList.includes(zinslisteListingFiltersFormFields.nettoFrom) && formikProps.values.nettoTo) {
      formikProps.setFieldValue(zinslisteListingFiltersFormFields.nettoTo, undefined);
    }
    if (!selectedFilterList.includes(zinslisteListingFiltersFormFields.vorschreibungsperiodeFrom) && formikProps.values.vorschreibungsperiodeTo) {
      formikProps.setFieldValue(zinslisteListingFiltersFormFields.vorschreibungsperiodeTo, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, filterList } = useFilterWithQueryParamsNew({
    updateFilterValues,
    filters,
    labelList: zinslisteListingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWithSelectorWrapper
      selectedFilterList={selectedFilterList}
      onSelectedFilterTitleList={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
      allFilterTitles={zinslisteListingLabelList}
      filters={<>{filterList}</>}
      actionButtons={actionButtons}
    />
  );
};

export default ZinslisteListingFilters;
