import numbro from 'numbro';
import { useEffect } from 'react';
// @ts-ignore
import numbroLanguages from 'numbro/dist/languages.min';
import { Locale } from './locale.model';
import { dayjsCustom } from '../../helpers/dayjsCustom';

/**
 * If locale in state changes we have to synchronize it with the currently set locale of numbro (lib used for formatting decimal inputs)
 * and dayjs (TODO)
 */
export const useLocaleSync = (locale: Locale) => {
  // TODO sync with dayjs locale
  dayjsCustom.locale('de');

  // numbro
  const registerAndSetNumbroLanguage = (locale: Locale) => {
    if (Object.values(numbro.languages()).find((l) => l.languageTag === locale)) {
      numbro.setLanguage(locale);
    } else {
      const numbroTranslations = Object.values(numbroLanguages as { [tag: string]: numbro.NumbroLanguage }).find((l) => l.languageTag === locale);
      if (numbroTranslations) {
        numbro.registerLanguage(numbroTranslations, true);
      } else {
        console.error('could not find numbro translation for locale', locale);
      }
    }
  };

  useEffect(() => {
    registerAndSetNumbroLanguage(locale);
  }, [locale]);
};
