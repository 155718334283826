import React, { FC } from 'react';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { BkAbrechnungsdefinitionVersionListEntry } from '../../../../types';
import { useBkAbrechnungsdefinitionTemplateVersionListQuery } from '../../gql/BkAbrDef/BkAbrDefTemplateVersionQueries.types';
import { useDeleteBkAbrechnungsdefinitionTemplateVersionMutation } from '../../gql/BkAbrDef/BkAbrDefTemplateVersionMutations.types';
import AbrDefTemplateVersionTimeline from './AbrDefTemplateVersionTimeline';

type Props = {
  abrechnungsdefinitionId: string;
};

const AbrDefTemplateBkVersionTimeline: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data, loading, refetch } = useBkAbrechnungsdefinitionTemplateVersionListQuery({ variables: { abrechnungsdefinitionId } });
  const versionList = data?.getBkAbrechnungsdefinitionTemplateVersionList.data;

  const [deleteBkAbrDefVersion] = useDeleteBkAbrechnungsdefinitionTemplateVersionMutation({
    onCompleted: () => {
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onDelete = (version: BkAbrechnungsdefinitionVersionListEntry): Promise<unknown> => {
    return deleteBkAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId: version.abrechnungsdefinitionVersionId,
      },
    });
  };

  return (
    <AbrDefTemplateVersionTimeline
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      loading={loading}
      versionList={versionList}
      onDelete={onDelete}
    />
  );
};

export default AbrDefTemplateBkVersionTimeline;
