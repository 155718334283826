import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type HistoryListFieldsFragment = {
  data: Array<{
    updatedBy?: string | null;
    updatedByMitarbeiterId?: string | null;
    updateTs: string;
    attributeList: Array<
      | { __typename: 'HistoryAmountAttribute'; type: Types.HistoryAttributeType; valueAmount?: number | null; name: { text: string; value: string } }
      | {
          __typename: 'HistoryBooleanAttribute';
          type: Types.HistoryAttributeType;
          valueBoolean?: boolean | null;
          name: { text: string; value: string };
        }
      | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
      | {
          __typename: 'HistoryFloatAttribute';
          scaling?: number | null;
          type: Types.HistoryAttributeType;
          valueFloat?: number | null;
          name: { text: string; value: string };
        }
      | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
      | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
      | {
          __typename: 'HistoryPercentAttribute';
          scaling?: number | null;
          type: Types.HistoryAttributeType;
          valuePercent?: number | null;
          name: { text: string; value: string };
        }
      | { __typename: 'HistoryStringAttribute'; type: Types.HistoryAttributeType; valueString?: string | null; name: { text: string; value: string } }
      | {
          __typename: 'HistoryStringListAttribute';
          longText: boolean;
          type: Types.HistoryAttributeType;
          valueStringList?: Array<string> | null;
          name: { text: string; value: string };
        }
    >;
  }>;
};

export type HistoryFieldsFragment = {
  updatedBy?: string | null;
  updatedByMitarbeiterId?: string | null;
  updateTs: string;
  attributeList: Array<
    | { __typename: 'HistoryAmountAttribute'; type: Types.HistoryAttributeType; valueAmount?: number | null; name: { text: string; value: string } }
    | {
        __typename: 'HistoryBooleanAttribute';
        type: Types.HistoryAttributeType;
        valueBoolean?: boolean | null;
        name: { text: string; value: string };
      }
    | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
    | {
        __typename: 'HistoryFloatAttribute';
        scaling?: number | null;
        type: Types.HistoryAttributeType;
        valueFloat?: number | null;
        name: { text: string; value: string };
      }
    | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
    | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
    | {
        __typename: 'HistoryPercentAttribute';
        scaling?: number | null;
        type: Types.HistoryAttributeType;
        valuePercent?: number | null;
        name: { text: string; value: string };
      }
    | { __typename: 'HistoryStringAttribute'; type: Types.HistoryAttributeType; valueString?: string | null; name: { text: string; value: string } }
    | {
        __typename: 'HistoryStringListAttribute';
        longText: boolean;
        type: Types.HistoryAttributeType;
        valueStringList?: Array<string> | null;
        name: { text: string; value: string };
      }
  >;
};

export type HistoryAttributeFields_HistoryAmountAttribute_Fragment = {
  __typename: 'HistoryAmountAttribute';
  type: Types.HistoryAttributeType;
  valueAmount?: number | null;
  name: { text: string; value: string };
};

export type HistoryAttributeFields_HistoryBooleanAttribute_Fragment = {
  __typename: 'HistoryBooleanAttribute';
  type: Types.HistoryAttributeType;
  valueBoolean?: boolean | null;
  name: { text: string; value: string };
};

export type HistoryAttributeFields_HistoryDateAttribute_Fragment = {
  __typename: 'HistoryDateAttribute';
  type: Types.HistoryAttributeType;
  valueDate?: string | null;
  name: { text: string; value: string };
};

export type HistoryAttributeFields_HistoryFloatAttribute_Fragment = {
  __typename: 'HistoryFloatAttribute';
  scaling?: number | null;
  type: Types.HistoryAttributeType;
  valueFloat?: number | null;
  name: { text: string; value: string };
};

export type HistoryAttributeFields_HistoryHtmlAttribute_Fragment = {
  __typename: 'HistoryHtmlAttribute';
  type: Types.HistoryAttributeType;
  valueHtml?: string | null;
  name: { text: string; value: string };
};

export type HistoryAttributeFields_HistoryIntAttribute_Fragment = {
  __typename: 'HistoryIntAttribute';
  type: Types.HistoryAttributeType;
  valueInt?: number | null;
  name: { text: string; value: string };
};

export type HistoryAttributeFields_HistoryPercentAttribute_Fragment = {
  __typename: 'HistoryPercentAttribute';
  scaling?: number | null;
  type: Types.HistoryAttributeType;
  valuePercent?: number | null;
  name: { text: string; value: string };
};

export type HistoryAttributeFields_HistoryStringAttribute_Fragment = {
  __typename: 'HistoryStringAttribute';
  type: Types.HistoryAttributeType;
  valueString?: string | null;
  name: { text: string; value: string };
};

export type HistoryAttributeFields_HistoryStringListAttribute_Fragment = {
  __typename: 'HistoryStringListAttribute';
  longText: boolean;
  type: Types.HistoryAttributeType;
  valueStringList?: Array<string> | null;
  name: { text: string; value: string };
};

export type HistoryAttributeFieldsFragment =
  | HistoryAttributeFields_HistoryAmountAttribute_Fragment
  | HistoryAttributeFields_HistoryBooleanAttribute_Fragment
  | HistoryAttributeFields_HistoryDateAttribute_Fragment
  | HistoryAttributeFields_HistoryFloatAttribute_Fragment
  | HistoryAttributeFields_HistoryHtmlAttribute_Fragment
  | HistoryAttributeFields_HistoryIntAttribute_Fragment
  | HistoryAttributeFields_HistoryPercentAttribute_Fragment
  | HistoryAttributeFields_HistoryStringAttribute_Fragment
  | HistoryAttributeFields_HistoryStringListAttribute_Fragment;

export const HistoryAttributeFieldsFragmentDoc = gql`
  fragment HistoryAttributeFields on AbstractHistoryAttribute {
    __typename
    name {
      text
      value
    }
    type
    ... on HistoryAmountAttribute {
      valueAmount: value
    }
    ... on HistoryBooleanAttribute {
      valueBoolean: value
    }
    ... on HistoryDateAttribute {
      valueDate: value
    }
    ... on HistoryFloatAttribute {
      valueFloat: value
      scaling
    }
    ... on HistoryHtmlAttribute {
      valueHtml: value
    }
    ... on HistoryPercentAttribute {
      valuePercent: value
      scaling
    }
    ... on HistoryStringAttribute {
      valueString: value
    }
    ... on HistoryStringListAttribute {
      valueStringList: value
      longText
    }
    ... on HistoryIntAttribute {
      valueInt: value
    }
  }
`;
export const HistoryFieldsFragmentDoc = gql`
  fragment HistoryFields on History {
    attributeList {
      ...HistoryAttributeFields
    }
    updatedBy
    updatedByMitarbeiterId
    updateTs
  }
  ${HistoryAttributeFieldsFragmentDoc}
`;
export const HistoryListFieldsFragmentDoc = gql`
  fragment HistoryListFields on HistoryListResponse {
    data {
      ...HistoryFields
    }
  }
  ${HistoryFieldsFragmentDoc}
`;
