import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { TextbausteinFieldsFragmentDoc } from '../../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateInfoMailDeliveryWizardMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  emailDeliveryDefinitionId: Types.Scalars['ID']['input'];
}>;

export type CreateInfoMailDeliveryWizardMutation = {
  createInfoMailDeliveryWizard: {
    data: {
      completed: boolean;
      currentStep: number;
      emailDeliveryDefinitionBezeichnung: string;
      stepCount: number;
      wizardId: string;
      attachmentInfoList: Array<{ fileId: string; name: string; size: number }>;
      body?: {
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      } | null;
      subject: {
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateInfoMailDeliveryMutationVariables = Types.Exact<{
  infoMailDeliveryWizardId: Types.Scalars['String']['input'];
  infoMailDeliveryId: Types.Scalars['ID']['input'];
  shouldSend: Types.Scalars['Boolean']['input'];
}>;

export type UpdateInfoMailDeliveryMutation = {
  updateInfoMailDelivery: {
    data: { infoMailDeliveryId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteInfoMailDeliveryWizardMutationVariables = Types.Exact<{
  infoMailDeliveryWizardId: Types.Scalars['ID']['input'];
}>;

export type DeleteInfoMailDeliveryWizardMutation = {
  deleteInfoMailDeliveryWizard: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type DeleteInfoMailDeliveryAttachmentMutationVariables = Types.Exact<{
  infoMailDeliveryWizardId: Types.Scalars['String']['input'];
  infoMailDeliveryId: Types.Scalars['String']['input'];
  attachmentId: Types.Scalars['ID']['input'];
}>;

export type DeleteInfoMailDeliveryAttachmentMutation = {
  deleteInfoMailDeliveryAttachment: {
    data: { infoMailDeliveryId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CompleteInfoMailDetermineRecipientListStepMutationVariables = Types.Exact<{
  infoMailDeliveryWizardId: Types.Scalars['ID']['input'];
}>;

export type CompleteInfoMailDetermineRecipientListStepMutation = {
  actionCompleteInfoMailDetermineRecipientListStep: {
    data: { completed: boolean; currentStep: number; generierlaufId: string; wizardId: string; stepCount: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SendInfoMailMutationVariables = Types.Exact<{
  infoMailDeliveryWizardId: Types.Scalars['ID']['input'];
}>;

export type SendInfoMailMutation = {
  actionSendInfoMail: {
    data?: { completed: boolean; currentStep: number; sendGenerierlaufId?: string | null; stepCount: number; wizardId: string } | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateInfoMailDeliveryWizardDocument = gql`
  mutation createInfoMailDeliveryWizard($objektId: ID!, $emailDeliveryDefinitionId: ID!) {
    createInfoMailDeliveryWizard(objektId: $objektId, emailDeliveryDefinitionId: $emailDeliveryDefinitionId) {
      data {
        attachmentInfoList {
          fileId
          name
          size
        }
        completed
        currentStep
        body {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        emailDeliveryDefinitionBezeichnung
        emailDeliveryDefinitionBezeichnung
        stepCount
        subject {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        wizardId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
export function useCreateInfoMailDeliveryWizardMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInfoMailDeliveryWizardMutation, CreateInfoMailDeliveryWizardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInfoMailDeliveryWizardMutation, CreateInfoMailDeliveryWizardMutationVariables>(
    CreateInfoMailDeliveryWizardDocument,
    options
  );
}
export type CreateInfoMailDeliveryWizardMutationHookResult = ReturnType<typeof useCreateInfoMailDeliveryWizardMutation>;
export type CreateInfoMailDeliveryWizardMutationResult = Apollo.MutationResult<CreateInfoMailDeliveryWizardMutation>;
export type CreateInfoMailDeliveryWizardMutationOptions = Apollo.BaseMutationOptions<
  CreateInfoMailDeliveryWizardMutation,
  CreateInfoMailDeliveryWizardMutationVariables
>;
export const UpdateInfoMailDeliveryDocument = gql`
  mutation updateInfoMailDelivery($infoMailDeliveryWizardId: String!, $infoMailDeliveryId: ID!, $shouldSend: Boolean!) {
    updateInfoMailDelivery(infoMailDeliveryWizardId: $infoMailDeliveryWizardId, infoMailDeliveryId: $infoMailDeliveryId, shouldSend: $shouldSend) {
      data {
        infoMailDeliveryId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateInfoMailDeliveryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInfoMailDeliveryMutation, UpdateInfoMailDeliveryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInfoMailDeliveryMutation, UpdateInfoMailDeliveryMutationVariables>(UpdateInfoMailDeliveryDocument, options);
}
export type UpdateInfoMailDeliveryMutationHookResult = ReturnType<typeof useUpdateInfoMailDeliveryMutation>;
export type UpdateInfoMailDeliveryMutationResult = Apollo.MutationResult<UpdateInfoMailDeliveryMutation>;
export type UpdateInfoMailDeliveryMutationOptions = Apollo.BaseMutationOptions<
  UpdateInfoMailDeliveryMutation,
  UpdateInfoMailDeliveryMutationVariables
>;
export const DeleteInfoMailDeliveryWizardDocument = gql`
  mutation deleteInfoMailDeliveryWizard($infoMailDeliveryWizardId: ID!) {
    deleteInfoMailDeliveryWizard(infoMailDeliveryWizardId: $infoMailDeliveryWizardId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteInfoMailDeliveryWizardMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInfoMailDeliveryWizardMutation, DeleteInfoMailDeliveryWizardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInfoMailDeliveryWizardMutation, DeleteInfoMailDeliveryWizardMutationVariables>(
    DeleteInfoMailDeliveryWizardDocument,
    options
  );
}
export type DeleteInfoMailDeliveryWizardMutationHookResult = ReturnType<typeof useDeleteInfoMailDeliveryWizardMutation>;
export type DeleteInfoMailDeliveryWizardMutationResult = Apollo.MutationResult<DeleteInfoMailDeliveryWizardMutation>;
export type DeleteInfoMailDeliveryWizardMutationOptions = Apollo.BaseMutationOptions<
  DeleteInfoMailDeliveryWizardMutation,
  DeleteInfoMailDeliveryWizardMutationVariables
>;
export const DeleteInfoMailDeliveryAttachmentDocument = gql`
  mutation deleteInfoMailDeliveryAttachment($infoMailDeliveryWizardId: String!, $infoMailDeliveryId: String!, $attachmentId: ID!) {
    deleteInfoMailDeliveryAttachment(
      infoMailDeliveryWizardId: $infoMailDeliveryWizardId
      infoMailDeliveryId: $infoMailDeliveryId
      attachmentId: $attachmentId
    ) {
      data {
        infoMailDeliveryId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteInfoMailDeliveryAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInfoMailDeliveryAttachmentMutation, DeleteInfoMailDeliveryAttachmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInfoMailDeliveryAttachmentMutation, DeleteInfoMailDeliveryAttachmentMutationVariables>(
    DeleteInfoMailDeliveryAttachmentDocument,
    options
  );
}
export type DeleteInfoMailDeliveryAttachmentMutationHookResult = ReturnType<typeof useDeleteInfoMailDeliveryAttachmentMutation>;
export type DeleteInfoMailDeliveryAttachmentMutationResult = Apollo.MutationResult<DeleteInfoMailDeliveryAttachmentMutation>;
export type DeleteInfoMailDeliveryAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteInfoMailDeliveryAttachmentMutation,
  DeleteInfoMailDeliveryAttachmentMutationVariables
>;
export const CompleteInfoMailDetermineRecipientListStepDocument = gql`
  mutation CompleteInfoMailDetermineRecipientListStep($infoMailDeliveryWizardId: ID!) {
    actionCompleteInfoMailDetermineRecipientListStep(infoMailDeliveryWizardId: $infoMailDeliveryWizardId) {
      data {
        completed
        currentStep
        generierlaufId
        wizardId
        stepCount
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCompleteInfoMailDetermineRecipientListStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteInfoMailDetermineRecipientListStepMutation,
    CompleteInfoMailDetermineRecipientListStepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteInfoMailDetermineRecipientListStepMutation, CompleteInfoMailDetermineRecipientListStepMutationVariables>(
    CompleteInfoMailDetermineRecipientListStepDocument,
    options
  );
}
export type CompleteInfoMailDetermineRecipientListStepMutationHookResult = ReturnType<typeof useCompleteInfoMailDetermineRecipientListStepMutation>;
export type CompleteInfoMailDetermineRecipientListStepMutationResult = Apollo.MutationResult<CompleteInfoMailDetermineRecipientListStepMutation>;
export type CompleteInfoMailDetermineRecipientListStepMutationOptions = Apollo.BaseMutationOptions<
  CompleteInfoMailDetermineRecipientListStepMutation,
  CompleteInfoMailDetermineRecipientListStepMutationVariables
>;
export const SendInfoMailDocument = gql`
  mutation sendInfoMail($infoMailDeliveryWizardId: ID!) {
    actionSendInfoMail(infoMailDeliveryWizardId: $infoMailDeliveryWizardId) {
      data {
        completed
        currentStep
        sendGenerierlaufId
        stepCount
        wizardId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useSendInfoMailMutation(baseOptions?: Apollo.MutationHookOptions<SendInfoMailMutation, SendInfoMailMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendInfoMailMutation, SendInfoMailMutationVariables>(SendInfoMailDocument, options);
}
export type SendInfoMailMutationHookResult = ReturnType<typeof useSendInfoMailMutation>;
export type SendInfoMailMutationResult = Apollo.MutationResult<SendInfoMailMutation>;
export type SendInfoMailMutationOptions = Apollo.BaseMutationOptions<SendInfoMailMutation, SendInfoMailMutationVariables>;
