import { FC } from 'react';
import { FormikProps } from 'formik';
import { BeInfoFeldListingFiltersFormValues } from './beInfoFeldListingFiltersFormMapper';
import { useBeInfoFeldListQuery } from './gql/BeInfoFeldQueries.types';
import BeInfoFeldListingFilters from './BeInfoFeldListingFilters';
import BeInfoFeldList from './List/BeInfoFeldList';
import { BeInfoFeldFragment } from './gql/BeInfoFeldFragments.types';
import { useToggle } from '../../../hooks/useToggle';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  formikProps: FormikProps<BeInfoFeldListingFiltersFormValues>;
};

const BeInfoFeldListingForm: FC<Props> = ({ objektId, bestandseinheitId, formikProps }) => {
  const [showArchived, toggleShowArchived] = useToggle();

  const { data, loading, refetch } = useBeInfoFeldListQuery({
    variables: {
      objektId,
      bestandseinheitId,
      includeArchiviert: showArchived,
    },
  });
  const infoFeldList = data?.getBeInfoFeldList.data ?? [];
  const infoFeldListForType: BeInfoFeldFragment[] = filterInfoFeldListByType(infoFeldList, formikProps);

  return (
    <>
      <BeInfoFeldListingFilters
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        onSuccess={refetch}
        showArchived={showArchived}
        toggleShowArchived={toggleShowArchived}
      />
      <BeInfoFeldList
        infoFeldList={infoFeldListForType}
        loading={loading}
        bestandseinheitId={bestandseinheitId}
        objektId={objektId}
        onSuccess={refetch}
      />
    </>
  );
};

const filterInfoFeldListByType = (beInfoFeldList: BeInfoFeldFragment[], formikProps: FormikProps<BeInfoFeldListingFiltersFormValues>) =>
  beInfoFeldList.filter((infoFeld) => (formikProps.values.type ? infoFeld.type.value === formikProps.values.type : true));

export default BeInfoFeldListingForm;
