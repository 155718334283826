import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { SubAdministrationListEntry } from '../../../../types';
import { NestedTableWithColSelectorColProps } from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import { generatePathToRechtstraegerDetailsPage } from '../../../Rechtstraeger/rechtstraegerHelper';
import { compareTwoNumbersForSorting } from '../../../../helpers/numberHelper';
import { compareTwoDatesForSorting } from '../../../../helpers/dateHelper';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { EuroAmount } from '../../../../components/Number';
import { generatePathToSubAdministrationDetailsPage } from '../../../SubAdministration/subAdministrationUriPaths';
import EntityWarningList from '../../../../components/message/EntityWarningList';

const objSubAdministrationTableColumns = (objektId: string): NestedTableWithColSelectorColProps<SubAdministrationListEntry>[] => [
  {
    title: 'Eigentümer',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.eigentuemerBezeichnung, b.eigentuemerBezeichnung),
    render: (text, record) => (
      <DataWithShortenedText text={record.eigentuemerBezeichnung} maxTextLength={25}>
        {(shortenedText) => (
          <Link to={generatePathToRechtstraegerDetailsPage(record.eigentuemerId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Wohnungen',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.countBestandseinheit, b.countBestandseinheit),
    render: (text, record) => <Typography.Text>{record.countBestandseinheit}</Typography.Text>,
  },
  {
    title: 'Subverwaltete Wohnungen',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.countSubAdministration, b.countSubAdministration),
    render: (text, record) => <Typography.Text>{record.countSubAdministration}</Typography.Text>,
  },
  {
    title: 'Beginn',
    defaultSelected: true,
    sorter: (a, b) => compareTwoDatesForSorting(a.from, b.from),
    render: (text, record) => <CustomFormattedDate value={record.from} />,
  },
  {
    title: 'Ende',
    defaultSelected: true,
    sorter: (a, b) => compareTwoDatesForSorting(a.to, b.to),
    render: (text, record) => (record.to ? <CustomFormattedDate value={record.to} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Adresse',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.deliveryAddress, b.deliveryAddress),
    render: (text, record) => {
      const addressWarningList = record.warningList.filter((warning) => warning.type === 'DELIVERY_ADDRESS_EMPTY');

      if (record.deliveryAddress) {
        return <DataWithShortenedText maxTextLength={25} text={record.deliveryAddress} />;
      }
      if (addressWarningList.length > 0) {
        return <EntityWarningList warningList={addressWarningList} />;
      }
      return <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Erster Saldovortrag',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.saldouebertrag, b.saldouebertrag),
    render: (text, record) => <EuroAmount value={record.saldouebertrag} />,
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => (
      <Link to={generatePathToSubAdministrationDetailsPage(objektId, record.subAdministrationId)} target="_blank">
        Aktion
      </Link>
    ),
  },
];

export default objSubAdministrationTableColumns;
