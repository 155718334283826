import React, { FC } from 'react';
import { Drawer, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { useToggle } from '../../../../hooks/useToggle';
import KontierungstabelleForm from '../../Form/KontierungstabelleForm';
import {
  useArchiveKontierungstabelleMutation,
  useDeleteKontierungstabelleMutation,
  useUnarchiveKontierungstabelleMutation,
} from '../../gql/KontierungstabelleMutations.types';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { Kontierungstabelle, KontierungstabelleStatus } from '../../../../types';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import KontierungstabelleChangeHistoryListingTable from './KontierungstabelleChangeHistoryListingTable';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';

type Props = {
  kontierungstabelle: Kontierungstabelle;
  refetch: () => void;
};

const KontierungstabelleTableActions: FC<Props> = ({ kontierungstabelle, refetch }) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const entity = `Kontierungstabelle`;
  const isArchived = kontierungstabelle.status.value === KontierungstabelleStatus.Archiviert;

  const [runDelete] = useDeleteKontierungstabelleMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      refetch();
    },
    variables: {
      kontierungstabelleId: kontierungstabelle.kontierungstabelleId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveKontierungstabelleMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      refetch();
    },
    variables: {
      kontierungstabelleId: kontierungstabelle.kontierungstabelleId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveKontierungstabelleMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      refetch();
    },
    variables: {
      kontierungstabelleId: kontierungstabelle.kontierungstabelleId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: toggleDrawerOpen, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(runUnarchive, kontierungstabelle) : showConfirmArchive(runArchive, kontierungstabelle),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: !!kontierungstabelle.deletable,
    },
    {
      key: '4',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, kontierungstabelle),
      icon: <DeleteOutlined />,
      danger: true,
      disabled: !kontierungstabelle.deletable,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Drawer title="Kontierungstabelle bearbeiten" width={720} onClose={toggleDrawerOpen} open={isDrawerOpen} destroyOnClose>
        <KontierungstabelleForm
          kontierungstabelle={kontierungstabelle}
          onSuccess={() => {
            toggleDrawerOpen();
            refetch();
          }}
          onCancel={toggleDrawerOpen}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <KontierungstabelleChangeHistoryListingTable kontierungstabelleId={kontierungstabelle.kontierungstabelleId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, kontierungstabelle: Kontierungstabelle) => () => {
  Modal.confirm({
    title: `Möchten Sie die Kontierungstabelle löschen?`,
    content: `${kontierungstabelle.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmArchive = (runArchive: () => void, kontierungstabelle: Kontierungstabelle) => () => {
  Modal.confirm({
    title: `Möchten Sie den Aufteilungsschlüssel archivieren?`,
    content: `${kontierungstabelle.bezeichnung}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (runUnarchive: () => void, kontierungstabelle: Kontierungstabelle) => () => {
  Modal.confirm({
    title: `Möchten Sie den Aufteilungsschlüssel reaktivieren?`,
    content: `${kontierungstabelle.bezeichnung}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

export default KontierungstabelleTableActions;
