import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { IncomingInvoiceFilterStatus } from '../../../../types';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const IncomingInvoiceFilterStatusListFormSelect: FC<Props> = ({ ...restProps }) => {
  const statusList = [
    {
      text: 'Erstellt',
      value: IncomingInvoiceFilterStatus.Erstellt,
    },
    {
      text: 'Freigegeben',
      value: IncomingInvoiceFilterStatus.Freigegeben,
    },
    {
      text: 'Übertragen',
      value: IncomingInvoiceFilterStatus.Uebertragen,
    },
    {
      text: 'Übertragen fehlerhaft',
      value: IncomingInvoiceFilterStatus.UebertragenFehler,
    },
    {
      text: 'Umbuchung Freigegeben',
      value: IncomingInvoiceFilterStatus.UmbuchungFreigegeben,
    },
    {
      text: 'Bezahlt',
      value: IncomingInvoiceFilterStatus.Bezahlt,
    },
  ];

  return (
    <Select {...restProps} id={restProps.name} size="small" placeholder="Status auswählen" allowClear showSearch filterOption={selectFilterOption}>
      {statusList.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default IncomingInvoiceFilterStatusListFormSelect;
