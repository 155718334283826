import React, { FC } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, ReadOutlined } from '@ant-design/icons';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { showSuccessMsgDelete } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useDeleteAuszahlungMutation } from '../gql/HeVertragAuszahlungMutations.types';
import { isStatusErstellt } from '../../../../../helpers/statusHelper';
import { HeVertragAuszahlungStatus, HeVertragAuszahlungType, HeVertragZahlungsverlaufListEntry } from '../../../../../types';
import { generatePathToBookingDetailsPage } from '../../../../BookingDetails/bookingDetailsPaths';

type Props = {
  objektId: string;
  heVertragId: string;
  onSuccess: () => void;
  zahlungsverlauf: HeVertragZahlungsverlaufListEntry;
  rechnungsAusstellerId: string;
};

const ZahlungsverlaufActions: FC<Props> = ({ objektId, heVertragId, onSuccess, zahlungsverlauf, rechnungsAusstellerId }) => {
  const navigate = useNavigate();
  const [runDelete] = useDeleteAuszahlungMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Zahlung`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  if (isStatusErstellt(zahlungsverlauf.status) && zahlungsverlauf.type.value === HeVertragAuszahlungType.Einmalzahlung) {
    return (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      <a onClick={(e) => runDelete({ variables: { objektId, heVertragId, heVertragAuszahlungId: zahlungsverlauf.heVertragAuszahlungId! } })}>
        Löschen
      </a>
    );
  }

  if (zahlungsverlauf.status.value === HeVertragAuszahlungStatus.Durchgefuehrt) {
    const items: MenuProps['items'] = [
      {
        key: '1',
        label: 'Buchungsdetails ansehen',
        onClick: () =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          navigate(generatePathToBookingDetailsPage([zahlungsverlauf.fibuBuchungId!])),
        icon: <ReadOutlined />,
      },
    ];

    return (
      <>
        <Dropdown menu={{ items }}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            Aktion <DownOutlined />
          </a>
        </Dropdown>
      </>
    );
  }

  return <TextForEmptyValue textToShow="minus" />;
};

export default ZahlungsverlaufActions;
