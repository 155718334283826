import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Tabs, TabsProps } from 'antd';
import { ObjektInfoFeldType } from '../../../../../../types';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import { hausbetreuungValidationSchema } from '../shared/hausbetreuungValidationSchema';
import HausbetreuungDatenTab from '../Tabs/HausbetreuungDatenTab';
import { handleUpdateSubmit } from '../../shared/handleSubmit';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';
import { hausbetreuungUpdateFormFields, HausbetreuungUpdateFormValues, mapToUpdateFormValues } from './hausbetreuungUpdateFormMapper';
import InfoFeldDocumentTab from '../../../../../shared/InfoFeld/Tabs/InfoFeldDocumentTab';
import { ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment } from '../../../gql/objektInfoFeldFragments.types';
import ObjektInfoFeldTicketListing from '../../Auflage/Ticket/ObjektInfoFeldTicketListing';
import FormButtonClose from '../../../../../../components/Button/FormButtonClose';
import { InfoFeldUpdateFormTabKey } from '../../../../../InfoFeld/infoFeldTypes';

type Props = {
  objektId: string;
  onCancel: () => void;
  onInfoFeldAction: () => void;
  onTicketAction: () => void;
  infoFeld: ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment;
  activeKeyTab: InfoFeldUpdateFormTabKey;
};

const HausbetreuungUpdateForm: FC<Props> = ({ objektId, onCancel, onInfoFeldAction, onTicketAction, infoFeld, activeKeyTab }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<HausbetreuungUpdateFormValues>
      initialValues={mapToUpdateFormValues(infoFeld)}
      validationSchema={hausbetreuungValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const { rechtstraegerType, ...valuesWithoutType } = values;
        handleUpdateSubmit(
          firmendatenId,
          objektId,
          infoFeld.objektInfoFeldId,
          ObjektInfoFeldType.Hausbetreuung,
          valuesWithoutType,
          setSubmitting,
          onInfoFeldAction
        );
      }}
    >
      {(formikProps) => {
        const items: TabsProps['items'] = [
          {
            key: InfoFeldUpdateFormTabKey.DATA,
            label: 'Daten',
            children: (
              <Form layout="vertical">
                <HausbetreuungDatenTab formFields={hausbetreuungUpdateFormFields} formikProps={formikProps} />
                <InfoFeldUpload
                  name={hausbetreuungUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.TICKET,
            label: 'Fristen/Aufgaben',
            children: (
              <>
                <ObjektInfoFeldTicketListing objektId={objektId} infoFeld={infoFeld} onSuccess={onTicketAction} />
                <FormButtonClose onCancel={onCancel} />
              </>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.DOCUMENT,
            label: 'Dokumente',
            children: (
              <Form layout="vertical">
                <InfoFeldDocumentTab
                  infoFeld={infoFeld}
                  deleteDocumentFileIdList={formikProps.values.deleteDocumentFileIdList}
                  onDocumentDelete={(value) => formikProps.setFieldValue(hausbetreuungUpdateFormFields.deleteDocumentFileIdList, value)}
                  firmendatenId={firmendatenId}
                />

                <InfoFeldUpload
                  name={hausbetreuungUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
        ];

        return <Tabs defaultActiveKey={activeKeyTab} style={{ minHeight: '420px' }} items={items} />;
      }}
    </Formik>
  );
};

export default HausbetreuungUpdateForm;
