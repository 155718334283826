import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { EuroAmount } from '../../../../../components/Number';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import LinkToBelegFile from '../../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import { isNotNil } from '../../../../../helpers/assertionHelper';
import { generatePathToVorschreibungDetailsPage } from '../../../../Vorschreibung/vorschreibungUriPaths';
import ObjektTopBezeichnungListing from '../../../../shared/ObjektTopBezeichnungListing/ObjektTopBezeichnungListing';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import RechtstraegerWithStatus from '../../../../Rechtstraeger/shared/RechtstraegerWithStatus';
import { EigenbelegErstellenGenerierlaufEntry, EigenbelegErstellenGenerierlaufOrderBy } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import GenerierlaufEntryStep from '../../../shared/GenerierlaufEntryStep';

const vorschreibungAusgebenVerarbeitungEntryTableColumns: TableWithColSelectorColumnProps<EigenbelegErstellenGenerierlaufEntry>[] = [
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: EigenbelegErstellenGenerierlaufOrderBy.Status,
    sorter: true,
    render: (text, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Schritte',
    render: (text, record) => <GenerierlaufEntryStep stepList={record.stepList} currentStep={record.currentStep} stepCount={record.stepCount} />,
  },
  {
    title: 'BelegNr',
    defaultSelected: true,
    dataIndex: EigenbelegErstellenGenerierlaufOrderBy.Belegnummer,
    sorter: true,
    render: (text, record) => {
      if (record.eigenbeleg) {
        return record.eigenbeleg.fileInfo.fileId ? (
          <LinkToBelegFile fileId={record.eigenbeleg.fileInfo.fileId} belegnummer={record.eigenbeleg.belegnummer} hideIcon />
        ) : (
          <Typography.Text>{Number(record.eigenbeleg.belegnummer)}</Typography.Text>
        );
      } else return <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Zinsliste',
    defaultSelected: true,
    dataIndex: EigenbelegErstellenGenerierlaufOrderBy.Zinsliste,
    sorter: true,
    render: (text, record) =>
      record.auftrag ? (
        <Typography.Text>
          {record.auftrag.auftragsKopf.fakturierungsperiode.monat} / {record.auftrag.auftragsKopf.fakturierungsperiode.jahr}
        </Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Aussteller',
    defaultSelected: true,
    dataIndex: EigenbelegErstellenGenerierlaufOrderBy.Aussteller,
    sorter: true,
    render: (text, record) => {
      return record.auftrag ? (
        <RechtstraegerWithStatus
          rechtstraegerId={record.auftrag.auftragsKopf.aussteller.rechtstraegerId}
          rechtstraegerKurzBezeichnung={record.auftrag.auftragsKopf.aussteller.kurzBezeichnung}
          maxTextLength={15}
          openInNewTab
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Empfänger',
    defaultSelected: true,
    dataIndex: EigenbelegErstellenGenerierlaufOrderBy.Empfaenger,
    sorter: true,
    render: (text, record) => {
      return record.auftrag ? (
        <RechtstraegerWithStatus
          rechtstraegerId={record.auftrag.auftragsKopf.empfaenger.rechtstraegerId}
          rechtstraegerKurzBezeichnung={record.auftrag.auftragsKopf.empfaenger.kurzBezeichnung}
          maxTextLength={15}
          openInNewTab
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Objekt-Top',
    defaultSelected: true,
    render: (text, record) => {
      return record.auftrag ? (
        <ObjektTopBezeichnungListing objektTopIdList={record.auftrag.objektBestandseinheitIdDataList} maxTextLengthForSingleObjektTop={20} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Netto',
    defaultSelected: true,
    align: 'right',
    dataIndex: EigenbelegErstellenGenerierlaufOrderBy.Netto,
    sorter: true,
    render: (text, record) =>
      isNotNil(record.auftrag?.sumNetto) ? <EuroAmount value={record.auftrag.sumNetto} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Ust.',
    defaultSelected: true,
    align: 'right',
    dataIndex: EigenbelegErstellenGenerierlaufOrderBy.Ust,
    sorter: true,
    render: (text, record) =>
      isNotNil(record.auftrag?.sumUst) ? <EuroAmount value={record.auftrag.sumUst} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Brutto',
    defaultSelected: true,
    align: 'right',
    dataIndex: EigenbelegErstellenGenerierlaufOrderBy.Brutto,
    sorter: true,
    render: (text, record) =>
      isNotNil(record.auftrag?.sumBrutto) ? <EuroAmount value={record.auftrag.sumBrutto} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => (record.auftrag ? <FormattedDateTime createdTs={record.auftrag.createTs} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Ersteller',
    render: (text, record) =>
      record.auftrag ? (
        <MitarbeiterTooltip mitarbeiterId={record.auftrag.createdByMitarbeiterId} userId={record.auftrag.createdBy} alignment="left" showsInitials />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    width: '75px',
    render: (text, record) =>
      record.auftrag ? (
        <Link to={generatePathToVorschreibungDetailsPage(record.auftrag.auftragId)} target="_blank">
          Details
        </Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
];

export default vorschreibungAusgebenVerarbeitungEntryTableColumns;
