import {
  bookingSuggestionFormFields,
  BookingSuggestionFormValues,
  bsOffenePostenForClearingSuggestionFormFields,
  bsOffenePostenForPersonenkontoFormFields,
  OffenePostenForClearingSuggestion,
} from '../Form/buchungsmaskeFormMapper';
import { getOffenePostenIndexFromMainOPList } from '../Form/buchungsmaskeFormHelpers';

export const getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex = (opIndex: number) =>
  `${bookingSuggestionFormFields.offenePostenForClearingSuggestionList}[${opIndex}].${bsOffenePostenForClearingSuggestionFormFields.auszifferungList}`;

export const getAuszifferungListFormFieldNameFromMainOPList = (
  formikValues: BookingSuggestionFormValues,
  offenePosten: OffenePostenForClearingSuggestion
) => {
  const opIndex = getOffenePostenIndexFromMainOPList(formikValues, offenePosten);
  return getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex(opIndex);
};

export const getAuszifferungListFormFieldNameFromPersonenkontoOPList = (
  formikValues: BookingSuggestionFormValues,
  offenePosten: OffenePostenForClearingSuggestion
) => {
  const opIndex = formikValues.offenePostenForPersonenkontoList.findIndex((op) => op.offenePostenId === offenePosten.offenePostenId);
  return `${bookingSuggestionFormFields.offenePostenForPersonenkontoList}[${opIndex}].${bsOffenePostenForPersonenkontoFormFields.auszifferungList}`;
};
