import React, { FC } from 'react';
import { Empty } from 'antd';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { useMahnungAusgebenGenerierlaufEntryListQuery } from '../../../gql/MahnungGenerierlauf/MahnungAusgebenGenerierlaufQueries.types';
import { TMahnungVerarbeitungQueryParams } from '../../shared/EntryListingFilters/filtersQueryParams';
import { isVerarbeitungEntryTableRowExpandable, MahnungAusgebenVersendenVerbuchenGenerierlaufEntryList } from '../../../verarbeitungHelpers';
import { mahnungAusgVersVerbVerarbEntryTableColumns } from './mahnungAusgVersVerbVerarbEntryTableColumns';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';

type Props = {
  queryParams: TMahnungVerarbeitungQueryParams;
  generierlaufId: string;
};

const MahnungAusgebenEntryTable: FC<Props> = ({ generierlaufId, queryParams }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useMahnungAusgebenGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getMahnungAusgebenGenerierlaufEntryList.data.contentList || [];

  return (
    <TableWithColSelector<MahnungAusgebenVersendenVerbuchenGenerierlaufEntryList>
      locale={{
        emptyText: <Empty description={<span>Keine Mahnungen vorhanden</span>} />,
      }}
      loading={loading}
      dataSource={entryList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={mahnungAusgVersVerbVerarbEntryTableColumns(true)}
      rowKey={(record) => record.generierlaufEntryId}
      size="small"
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-mahnung-ausgeben"
    />
  );
};

export default MahnungAusgebenEntryTable;
