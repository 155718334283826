import React, { FC } from 'react';
import { Empty } from 'antd';
import { IncomingInvoiceListEntry, IncomingInvoicePartialAmountListEntry } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import partialAmountTableColumns from './partialAmountTableColumns';
import BookingPositionTable from '../Level3/BookingPositionTable';

type Props = {
  incomingInvoice: IncomingInvoiceListEntry;
};

const PartialAmountTable: FC<Props> = ({ incomingInvoice }) => {
  const { partialAmountList } = incomingInvoice;

  return (
    <NestedTable<IncomingInvoicePartialAmountListEntry>
      level={2}
      colWidth={170}
      locale={{
        emptyText: <Empty description={<span>Keine Teilbeträge vorhanden</span>} />,
      }}
      dataSource={partialAmountList}
      columns={partialAmountTableColumns(incomingInvoice)}
      expandable={{
        rowExpandable: (record) => !!record.bookingPositionList.length,
        expandedRowRender: (record) => (
          <BookingPositionTable
            incomingInvoice={incomingInvoice}
            bookingPositionList={record.bookingPositionList}
            buchungsdatum={record.buchungsdatum}
          />
        ),
      }}
      rowKey={(record) => `${incomingInvoice.rechnungsNummer}-${record.name}}`}
    />
  );
};

export default PartialAmountTable;
