import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { verarbeitungPageAndMenuListTitles, verarbeitungTypes } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { IncomingInvoiceBookingCreationGenerierlauf } from '../../../types';
import { generatePathToVerarbeitungIncomingInvoiceBookingCreationDetailsPage } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungErrorAndWarningListing from '../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import IncomingInvoiceBookingCreation from '../../../features/Verarbeitung/IncomingInvoiceBookingCreation/IncomingInvoiceBookingCreation';

const menuListIncomingInvoiceBookingCreation = (generierlauf: IncomingInvoiceBookingCreationGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.IncomingInvoiceBookingCreation,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungIncomingInvoiceBookingCreationDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: () => (
          <IncomingInvoiceBookingCreation
            generierlauf={generierlauf}
            title={verarbeitungPageAndMenuListTitles.IncomingInvoiceBookingCreation}
            verarbeitungType={verarbeitungTypes.IncomingInvoiceBookingCreation}
          />
        ),
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungIncomingInvoiceBookingCreationDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListIncomingInvoiceBookingCreation;
