import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from '../filtersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FibuBaBelegnummerSelect from '../../../../BookingInstruction/shared/Filters/FibuBaBelegnummerSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
};

const BelegnummerSelectFormItem: FC<Props> = ({ formikProps }) => {
  return (
    <FormItemWithoutColon name={filtersFormFields.belegnummer} label={listingLabelList.belegnummer}>
      <FibuBaBelegnummerSelect
        name={filtersFormFields.belegnummer}
        belegnummer={formikProps.values.belegnummer}
        onChange={formikProps.submitForm}
        rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
      />
    </FormItemWithoutColon>
  );
};

export default BelegnummerSelectFormItem;
