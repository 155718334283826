import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuftragQuery } from '../../features/Vorschreibung/gql/AuftragQueries.types';
import VorschreibungCard from '../../features/Vorschreibung/Card/VorschreibungCard';
import { useOnVorschreibungDataChangedEvent } from '../../features/Vorschreibung/useOnVorschreibungDataChangedEvent';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import menuListVorschreibung from './menuListVorschreibung';
import { VorschreibungDetailsPageRouteParams } from './routes';
import { Auftrag } from '../../types';

const VorschreibungDetailsPage = () => {
  const { auftragId } = useParams() as VorschreibungDetailsPageRouteParams;

  const { data, refetch } = useAuftragQuery({ variables: { auftragId } });
  const auftrag = data?.getAuftrag.data;
  const loading = !auftrag;

  useOnVorschreibungDataChangedEvent('vorschreibung', refetch);

  return (
    <DetailsPageTemplate<Auftrag>
      data={auftrag}
      pageTitle={() => `Vorschreibungsdetails`}
      card={(auftrag) => <VorschreibungCard auftrag={auftrag} isLoading={loading} />}
      sidebarMenuList={(auftrag) => menuListVorschreibung(auftrag, loading, refetch)}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      contentContextList={(auftrag) => createVorschreibungContextList(auftrag)}
    />
  );
};

const createVorschreibungContextList = (auftrag: Auftrag) => (auftrag.hasAuftragskette ? ['AUFTRAGSKETTE'] : []);

export default VorschreibungDetailsPage;
