import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import { BeInfoFeldType } from '../../../../../../types';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { handleCreateSubmit } from '../../shared/handleSubmit';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';
import { parkplatzCreateFormFields, parkplatzCreateFormInitialValues, ParkplatzCreateFormValues } from './parkplatzCreateFormMapper';
import ParkplatzDatenTab from '../Tabs/ParkplatzDatenTab';
import { parkplatzValidationSchema } from '../shared/parkplatzValidationSchema';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const ParkplatzCreateForm: FC<Props> = ({ objektId, bestandseinheitId, onSuccess, onCancel }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<ParkplatzCreateFormValues>
      initialValues={parkplatzCreateFormInitialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleCreateSubmit(firmendatenId, objektId, bestandseinheitId, BeInfoFeldType.Parkplatz, values, setSubmitting, onSuccess);
      }}
      validationSchema={parkplatzValidationSchema}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <ParkplatzDatenTab formFields={parkplatzCreateFormFields} objektId={objektId} />
          <InfoFeldUpload name={parkplatzCreateFormFields.documentList} formikProps={formikProps} documentList={formikProps.values.documentList} />
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default ParkplatzCreateForm;
