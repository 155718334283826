import React from 'react';
import { Select } from 'formik-antd';
import { useMasseinheitListQuery } from './gql/MasseinheitQueries.types';
import { MasseinheitTuple } from '../../../types';

const MasseinheitSelect = ({ ...props }) => {
  const { loading, data } = useMasseinheitListQuery();

  const masseinheitList = data?.getMasseinheitList.data ?? [];

  return (
    <Select id="masseinheit" name="masseinheit" loading={loading} placeholder="Maßeinheit auswählen" {...props}>
      {masseinheitList.map((masseinheit: MasseinheitTuple) => (
        <Select.Option key={masseinheit.value} value={masseinheit.value}>
          {masseinheit.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default MasseinheitSelect;
