import React, { FC } from 'react';
import { Typography } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Spacer } from '../../../../../components/Grid';
import { AuftragsartType } from '../../../../../types';
import { auftragsartCreateParamFormValidationSchema } from '../../shared/ParamForm/Schema/auftragsartCreateParamFormValidationSchema';
import ParamFormItemBezeichnung from '../../shared/ParamForm/ParamFormItems/ParamFormItemBezeichnung';
import { isNotStornoAuftragsart } from '../../shared/auftragsartFormHelpers';
import ParamFormItemUstBlockSwitch from '../../shared/ParamForm/ParamFormItems/ParamFormItemUstBlockSwitch';
import FormButtons from '../../../../../components/Button/FormButtons';
import ParamFormItemStornoAuftragsartSelect from '../../shared/ParamForm/ParamFormItems/ParamFormItemStornoAuftragsartSelect';
import {
  auftragsartCreateParamFormFields,
  auftragsartCreateParamFormInitialValues,
  AuftragsartCreateParamFormValues,
} from './auftragsartCreateParamFormMapper';
import { StyledWizardSpaceForContent } from '../../../../shared/styled/WizardSpace.style';

type Props = {
  auftragsartBezeichnung: string;
  auftragsartType: AuftragsartType;
  paramFormValues?: AuftragsartCreateParamFormValues;
  setParamFormValues: React.Dispatch<React.SetStateAction<AuftragsartCreateParamFormValues | undefined>>;
  onSubmit: () => void;
  onCancel: () => void;
};

const AuftragsartCreateParamForm: FC<Props> = ({
  auftragsartBezeichnung,
  auftragsartType,
  paramFormValues,
  setParamFormValues,
  onSubmit,
  onCancel,
}) => {
  const ParamForm = () => (
    <Formik<AuftragsartCreateParamFormValues>
      initialValues={auftragsartCreateParamFormInitialValues(paramFormValues)}
      onSubmit={(formValues) => {
        setParamFormValues(formValues);
        onSubmit();
      }}
      validationSchema={auftragsartCreateParamFormValidationSchema}
    >
      {(formikProps) => (
        <Form>
          <StyledWizardSpaceForContent direction="vertical">
            <ParamFormItemBezeichnung name={auftragsartCreateParamFormFields.bezeichnung} placeholder="z.B. Standard Vorschreibung Dauerrechnung" />
            <ParamFormItemUstBlockSwitch
              name={auftragsartCreateParamFormFields.showUstBlock}
              defaultChecked={formikProps.values.showUstBlock}
              onChange={(checked) => formikProps.setFieldValue(auftragsartCreateParamFormFields.showUstBlock, checked)}
              shouldShowUstBlock={formikProps.values.showUstBlock}
            />
            {isNotStornoAuftragsart(auftragsartType) && (
              <ParamFormItemStornoAuftragsartSelect name={auftragsartCreateParamFormFields.stornoAuftragsartId} auftragsartType={auftragsartType} />
            )}
          </StyledWizardSpaceForContent>

          <FormButtons onCancel={onCancel} cancelText="Zurück" updateMode={false} okText="Weiter" isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      <Spacer height={16} />

      <Typography.Title level={5}>{auftragsartBezeichnung}</Typography.Title>
      <Spacer height={16} />

      <ParamForm />
    </>
  );
};

export default AuftragsartCreateParamForm;
