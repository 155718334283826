import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useRechtstraegerTagListQuery } from '../../gql/RechtsTraegerQueries.types';

type Props = Omit<SelectProps, 'id' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const RechtstraegerTagListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useRechtstraegerTagListQuery();

  const list = data?.getRechtstraegerTagList.data || [];

  return (
    <Select
      size="middle"
      placeholder=""
      {...restProps}
      loading={loading}
      name={name}
      id={name}
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((tag) => (
        <Select.Option key={tag} value={tag}>
          {tag}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RechtstraegerTagListFormSelect;
