import React, { FC, useEffect } from 'react';
import { Empty, Typography } from 'antd';
import { useReceivedBankTransactionListForPaymentQuery } from '../../../../../ReceivedBankTransaction/gql/ReceivedBankTransactionQueries.types';
import { ReceivedBankTransactionListEntry } from '../../../../../../types';
import { receivedBankTransactionTableColumns } from './receivedBankTransactionTableColumns';
import NestedTableWithColSelector from '../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

type Props = {
  paymentId: string;
  isRowExpanded: boolean;
  parentRowKeys?: string[];
};

const ReceivedBankTransactionRow: FC<Props> = ({ paymentId, isRowExpanded, parentRowKeys }) => {
  const { data, loading, refetch } = useReceivedBankTransactionListForPaymentQuery({
    variables: {
      paymentId,
    },
  });

  const receivedBankTransactionList = data?.getReceivedBankTransactionList.data.contentList ?? [];

  // UseEffect to refetch data when row gets expanded
  useEffect(() => {
    if (isRowExpanded) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRowExpanded]);

  return (
    <NestedTableWithColSelector<ReceivedBankTransactionListEntry>
      parentRowKeys={parentRowKeys}
      visible={isRowExpanded}
      aligned={false}
      colWidth={160}
      level={2}
      subLevel={2}
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Empfangene Bankbuchungen vorhanden</span>} />,
      }}
      title={() => <Typography.Text style={{ paddingLeft: '80px', fontWeight: 'bold' }}>Empfangene Bankbuchung</Typography.Text>}
      dataSource={receivedBankTransactionList}
      columns={receivedBankTransactionTableColumns(refetch)}
      expandable={{ rowExpandable: () => false }}
      rowKey={(record) => record.receivedBankTransactionId}
    />
  );
};

export default ReceivedBankTransactionRow;
