import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { topAbrDefGeneralSettingsFormFields, TopAbrDefGeneralSettingsFormValues } from '../../topAbrDefGeneralSettingsFormMapper';
import DisplayDueDateFormItem from './DisplayDueDateFormItem';
import { AbrechnungsdefinitionType, DueDateDisplayOptionsInput, DueDateDisplayOptionType } from '../../../../../../../../../types';
import DueDateDisplayOptions from './DueDateDisplayOptions';
import { isAbrDefTypeBkAbrechnung, isAbrDefTypeWeAbrechnung } from '../../../../../../AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { isDueDateOptionTypeVerrechnungsperiode } from '../../../dueDateHelpers';

type Props = {
  formikProps: FormikProps<TopAbrDefGeneralSettingsFormValues>;
  abrDefType: AbrechnungsdefinitionType;
  isDisabled: boolean;
  onValuesChange: (
    displayDueDate: boolean,
    dueDateDisplayOptionEigentuemerGuthaben?: DueDateDisplayOptionsInput | null,
    dueDateDisplayOptionEigentuemerNachzahlung?: DueDateDisplayOptionsInput | null,
    dueDateDisplayOptionMieter?: DueDateDisplayOptionsInput | null
  ) => void;
  fieldHelpNames: {
    displayDueDate?: string | null;
    dueDateDisplayOptionEigentuemerGuthaben?: {
      month?: string | null;
      type?: string | null;
    };
    dueDateDisplayOptionEigentuemerNachzahlung?: {
      month?: string | null;
      type?: string | null;
    };
    dueDateDisplayOptionMieter?: {
      month?: string | null;
      type?: string | null;
    };
  };
};

const DueDateFormItems: FC<Props> = ({ abrDefType, formikProps, isDisabled, onValuesChange, fieldHelpNames }) => {
  const isOptionDisabled = isDisabled || !formikProps.values.displayDueDate;
  const isMonthPickerDisabled = (type: DueDateDisplayOptionType | undefined) => isOptionDisabled || !isDueDateOptionTypeVerrechnungsperiode(type);

  return (
    <>
      <DisplayDueDateFormItem
        formikProps={formikProps}
        isDisabled={isDisabled}
        abrDefType={abrDefType}
        onChange={(value) => onValuesChange(value)}
        fieldHelp={fieldHelpNames.displayDueDate}
      />

      {/* DueDateForTenantBilling = "Fälligkeit der Abrechnung für Mieter" */}
      {isAbrDefTypeBkAbrechnung(abrDefType) && (
        <DueDateDisplayOptions
          name={topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionMieter}
          label="Fälligkeit der Abrechnung für Mieter"
          formikProps={formikProps}
          isDisabled={isOptionDisabled}
          isMonthPickerDisabled={isMonthPickerDisabled(formikProps.values.dueDateDisplayOptionMieter?.type)}
          onChange={(value) => onValuesChange(formikProps.values.displayDueDate, undefined, undefined, value)}
          fieldHelpNames={{
            month: fieldHelpNames.dueDateDisplayOptionMieter?.month,
            type: fieldHelpNames.dueDateDisplayOptionMieter?.type,
          }}
        />
      )}

      {isAbrDefTypeWeAbrechnung(abrDefType) && (
        <>
          {/* DueDateForOwnerWithCredit = "Fälligkeit der Abrechnung für Eigentümer mit Guthaben" */}
          <DueDateDisplayOptions
            name={topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionEigentuemerGuthaben}
            label="Fälligkeit der Abrechnung für Eigentümer mit Guthaben"
            formikProps={formikProps}
            isDisabled={isOptionDisabled}
            isMonthPickerDisabled={isMonthPickerDisabled(formikProps.values.dueDateDisplayOptionEigentuemerGuthaben?.type)}
            onChange={(value) =>
              onValuesChange(formikProps.values.displayDueDate, value, formikProps.values.dueDateDisplayOptionEigentuemerNachzahlung)
            }
            fieldHelpNames={{
              month: fieldHelpNames.dueDateDisplayOptionEigentuemerGuthaben?.month,
              type: fieldHelpNames.dueDateDisplayOptionEigentuemerGuthaben?.type,
            }}
          />
          {/* DueDateForOwnersWithAdditionalPayment = "Fälligkeit der Abrechnung für Eigentümer mit Nachzahlung" */}
          <DueDateDisplayOptions
            name={topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionEigentuemerNachzahlung}
            label="Fälligkeit der Abrechnung für Eigentümer mit Nachzahlung"
            formikProps={formikProps}
            isDisabled={isOptionDisabled}
            isMonthPickerDisabled={isMonthPickerDisabled(formikProps.values.dueDateDisplayOptionEigentuemerNachzahlung?.type)}
            onChange={(value) => onValuesChange(formikProps.values.displayDueDate, formikProps.values.dueDateDisplayOptionEigentuemerGuthaben, value)}
            fieldHelpNames={{
              month: fieldHelpNames.dueDateDisplayOptionEigentuemerNachzahlung?.month,
              type: fieldHelpNames.dueDateDisplayOptionEigentuemerNachzahlung?.type,
            }}
          />
        </>
      )}
    </>
  );
};
export default DueDateFormItems;
