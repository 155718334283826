import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Col, Divider } from 'antd';
import DrawerRow from '../../../../components/Drawer/DrawerRow';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { EbicsUser } from '../../../../types';
import { useCreateEbicsUserMutation, useUpdateEbicsUserMutation } from '../gql/EbicsUserMutations.types';
import { ebicsUserFormFields, EbicsUserFormValues, mapEbicsUserToFormValues, mapFormValuesToEbicsConfig } from './ebicsUserFormMapper';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import EbicsBankConfigSelectForm from '../../shared/EbicsBankConfigSelectForm';
import { ebicsUserValidationSchema } from './ebicsUserValidationSchema';
import EbicsUserPasswordFormInput from './EbicsUserPasswordFormInput';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  ebicsUser?: EbicsUser;
};

const EbicsUserForm: FC<Props> = ({ onCancel, onSuccess, ebicsUser }) => {
  const isUpdate = !!ebicsUser;
  const entity = 'EBICS Bankteilnehmer';

  const [runCreate, { loading: loadingCreate }] = useCreateEbicsUserMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateEbicsUserMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<EbicsUserFormValues>
      initialValues={mapEbicsUserToFormValues(ebicsUser)}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToEbicsConfig(values);
        if (isUpdate) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          runUpdate({ variables: { ebicsUserId: ebicsUser!.ebicsUserId, input } }).finally(() => setSubmitting(false));
        } else {
          runCreate({ variables: { input } }).finally(() => setSubmitting(false));
        }
      }}
      validationSchema={ebicsUserValidationSchema}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <DrawerRow
            rowTitle="Mitarbeiter"
            content={
              <FormItemWithoutColon name={ebicsUserFormFields.mitarbeiterId} label="Mitarbeiter">
                <MitarbeiterSelect name={ebicsUserFormFields.mitarbeiterId} placeholder="Mitarbeiter auswählen" />
              </FormItemWithoutColon>
            }
          />
          <Divider />
          <DrawerRow
            rowTitle="EBICS"
            content={
              <>
                <Col span={24}>
                  <FormItemWithoutColon name={ebicsUserFormFields.ebicsBankConfigId} label="Bankname">
                    <EbicsBankConfigSelectForm name={ebicsUserFormFields.ebicsBankConfigId} placeholder="EBICS Bank auswählen" />
                  </FormItemWithoutColon>
                </Col>
                <Col span={24}>
                  <FormItemWithoutColon name={ebicsUserFormFields.partnerId} label="Kundenkennung">
                    <Input size="small" name={ebicsUserFormFields.partnerId} placeholder="z.B. ERSTEBANK1326" />
                  </FormItemWithoutColon>
                </Col>
                <Col span={24}>
                  <FormItemWithoutColon name={ebicsUserFormFields.userId} label="Teilnehmerkennung">
                    <Input size="small" name={ebicsUserFormFields.userId} placeholder="z.B. ERSTEBANK1326_001" />
                  </FormItemWithoutColon>
                </Col>
                <Col span={24}>
                  <EbicsUserPasswordFormInput name={ebicsUserFormFields.password} formikProps={formikProps} />
                </Col>
              </>
            }
          />
          <FormButtons
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            updateMode={isUpdate}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EbicsUserForm;
