import { useState } from 'react';
import { useAppFieldHelpListQuery } from './gql/AppFieldHelpQueries.types';
import { useFibuFieldHelpListQuery } from './gql/FibuFieldHelpQueries.types';
import { FieldHelp } from '../../types';
import { useOrderFieldHelpListQuery } from './gql/OrderFieldHelpQueries.types';
import { usePaymentFieldHelpListQuery } from './gql/PaymentFieldHelpQueries.types';

export enum FieldHelpService {
  App = 'APP',
  Fibu = 'FIBU',
  Order = 'ORDER',
  Payment = 'PAYMENT',
}

export type FieldHelpQueryArgs = {
  selector?: string | undefined;
  fieldHelp?: string | undefined;
  deprecated?: boolean | undefined;
  services?: FieldHelpService[] | undefined;
};

export type FieldHelpWithService = FieldHelp & { service?: FieldHelpService };

const useFieldHelpList = () => {
  const [queryArgs, setQueryArgs] = useState<FieldHelpQueryArgs | undefined>();
  const { selector, fieldHelp, deprecated, services } = queryArgs || {};
  const serviceList = !services?.length ? Object.values(FieldHelpService) : services;
  const isAppServiceIncluded = serviceList.includes(FieldHelpService.App);
  const isFibuServiceIncluded = serviceList.includes(FieldHelpService.Fibu);
  const isOrderServiceIncluded = serviceList.includes(FieldHelpService.Order);
  const isPaymentServiceIncluded = serviceList.includes(FieldHelpService.Payment);

  const {
    data: appData,
    loading: appLoading,
    refetch: appRefetch,
  } = useAppFieldHelpListQuery({
    variables: { selector, fieldHelp, deprecated },
    skip: !isAppServiceIncluded,
  });

  const {
    data: fibuData,
    loading: fibuLoading,
    refetch: fibuRefetch,
  } = useFibuFieldHelpListQuery({
    variables: { selector, fieldHelp, deprecated },
    skip: !isFibuServiceIncluded,
  });

  const {
    data: orderData,
    loading: orderLoading,
    refetch: orderRefetch,
  } = useOrderFieldHelpListQuery({
    variables: { selector, fieldHelp, deprecated },
    skip: !isOrderServiceIncluded,
  });

  const {
    data: paymentData,
    loading: paymentLoading,
    refetch: paymentRefetch,
  } = usePaymentFieldHelpListQuery({
    variables: { selector, fieldHelp, deprecated },
    skip: !isPaymentServiceIncluded,
  });

  const isLoading = appLoading || fibuLoading || orderLoading || paymentLoading;

  const refetch = () => {
    isAppServiceIncluded && appRefetch();
    isFibuServiceIncluded && fibuRefetch();
    isOrderServiceIncluded && orderRefetch();
    isPaymentServiceIncluded && paymentRefetch();
  };

  const data: FieldHelpWithService[] = [
    ...(appData?.getAppFieldHelpList.data.contentList ?? []).map((fieldHelp) => ({ ...fieldHelp, service: FieldHelpService.App })),
    ...(fibuData?.getFibuFieldHelpList.data.contentList ?? []).map((fieldHelp) => ({ ...fieldHelp, service: FieldHelpService.Fibu })),
    ...(orderData?.getOrderFieldHelpList.data.contentList ?? []).map((fieldHelp) => ({ ...fieldHelp, service: FieldHelpService.Order })),
    ...(paymentData?.getPaymentFieldHelpList.data.contentList ?? []).map((fieldHelp) => ({ ...fieldHelp, service: FieldHelpService.Payment })),
  ];

  return {
    data,
    loading: isLoading,
    refetch,
    queryArgs,
    setQueryArgs,
  };
};

export default useFieldHelpList;
