import React, { FC, useState } from 'react';
import { Button, Dropdown, MenuProps, Modal } from 'antd';
import { CalculatorOutlined, DownOutlined, GoldOutlined, HomeOutlined, TeamOutlined } from '@ant-design/icons';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import AbrechnungCreateForm from './CreateForm/AbrechnungCreateForm';
import { useToggle } from '../../../hooks/useToggle';
import { isWegObjekt } from '../../Objekt/objektHelper';
import { AbrechnungType, Objekt } from '../../../types';
import { getAbrTypeName } from './abrechnungHelpers';

type Props = {
  objekt: Objekt;
  refetch: () => void;
};

const AbrErstellenBtnAndModal: FC<Props> = ({ objekt, refetch }) => {
  const [isOpen, toggleOpen] = useToggle();

  const [abrechnungType, setAbrechnungType] = useState<AbrechnungType>(AbrechnungType.WeAbrechnung);

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <AbrErstellenButtons objekt={objekt} setAbrechnungType={setAbrechnungType} toggleOpen={toggleOpen} />
      </ButtonsAligned>
      <Modal
        title={`${getAbrTypeName(abrechnungType)} erstellen`}
        onCancel={toggleOpen}
        open={isOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <AbrechnungCreateForm
          objekt={objekt}
          onSuccess={() => {
            refetch();
            toggleOpen();
          }}
          onCancel={toggleOpen}
          abrechnungType={abrechnungType}
        />
      </Modal>
    </>
  );
};

type AbrErstellenButtonsProps = {
  objekt: Objekt;
  setAbrechnungType: (abrechnungType: AbrechnungType) => void;
  toggleOpen: () => void;
};

const AbrErstellenButtons: FC<AbrErstellenButtonsProps> = ({ objekt, setAbrechnungType, toggleOpen }) => {
  const weItems: MenuProps['items'] = [
    { key: '1', label: 'WE-Abrechnung erstellen', onClick: () => setAbrechnungType(AbrechnungType.WeAbrechnung), icon: <TeamOutlined /> },
    { key: '2', label: 'BK-Abrechnung erstellen', onClick: () => setAbrechnungType(AbrechnungType.BkAbrechnung), icon: <CalculatorOutlined /> },
    { key: '3', label: 'SUB-Abrechnung erstellen', onClick: () => setAbrechnungType(AbrechnungType.SubAbrechnung), icon: <GoldOutlined /> },
  ];

  const mietItems: MenuProps['items'] = [
    { key: '1', label: 'HE-Abrechnung erstellen', onClick: () => setAbrechnungType(AbrechnungType.HeAbrechnung), icon: <HomeOutlined /> },
    { key: '2', label: 'BK-Abrechnung erstellen', onClick: () => setAbrechnungType(AbrechnungType.BkAbrechnung), icon: <CalculatorOutlined /> },
  ];

  return (
    <Dropdown menu={{ items: isWegObjekt(objekt.verwaltungsart.value) ? weItems : mietItems, onClick: toggleOpen }}>
      <Button type="primary" size="small">
        Abrechnung erstellen
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default AbrErstellenBtnAndModal;
