import React, { FC } from 'react';
import { Empty } from 'antd';
import { TicketTemplate } from '../../../types';
import ticketTemplateTableColumns from './ticketTemplateTableColumns';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';

type Props = {
  ticketTemplateList?: TicketTemplate[];
  loading: boolean;
};

const TicketTemplateTable: FC<Props> = ({ ticketTemplateList, loading }) => {
  return (
    <TableWithColSelector<TicketTemplate>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Aufgabenart vorhanden</span>} />,
      }}
      loading={loading}
      dataSource={ticketTemplateList}
      columns={ticketTemplateTableColumns(ticketTemplateList)}
      rowKey={(record) => record.ticketTemplateId}
      filterIdentifier="ticket-template"
      scroll={{ x: 900 }}
    />
  );
};

export default TicketTemplateTable;
