import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import IndexedValuePage from './IndexedValuePage';
import { URI_INDEXED_VALUE_PAGE } from '../../features/IndexedValue/indexedValueUriPaths';

const indexedValuePageRoute = (
  <Route path={URI_INDEXED_VALUE_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<IndexedValuePage />} />} />
);

export default indexedValuePageRoute;
