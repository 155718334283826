import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { HistoryFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmendatenBankSettingsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FirmendatenBankSettingsQuery = {
  getFirmendatenBankSettings: {
    data: { bankSettingsId?: string | null; sepaCreditorId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenBankSettingsChangeHistoryListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FirmendatenBankSettingsChangeHistoryListQuery = {
  getFirmendatenBankSettingsChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FirmendatenBankSettingsDocument = gql`
  query FirmendatenBankSettings {
    getFirmendatenBankSettings {
      data {
        bankSettingsId
        sepaCreditorId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenBankSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenBankSettingsQuery, FirmendatenBankSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenBankSettingsQuery, FirmendatenBankSettingsQueryVariables>(FirmendatenBankSettingsDocument, options);
}
export function useFirmendatenBankSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenBankSettingsQuery, FirmendatenBankSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenBankSettingsQuery, FirmendatenBankSettingsQueryVariables>(FirmendatenBankSettingsDocument, options);
}
export function useFirmendatenBankSettingsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenBankSettingsQuery, FirmendatenBankSettingsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenBankSettingsQuery, FirmendatenBankSettingsQueryVariables>(FirmendatenBankSettingsDocument, options);
}
export type FirmendatenBankSettingsQueryHookResult = ReturnType<typeof useFirmendatenBankSettingsQuery>;
export type FirmendatenBankSettingsLazyQueryHookResult = ReturnType<typeof useFirmendatenBankSettingsLazyQuery>;
export type FirmendatenBankSettingsSuspenseQueryHookResult = ReturnType<typeof useFirmendatenBankSettingsSuspenseQuery>;
export type FirmendatenBankSettingsQueryResult = Apollo.QueryResult<FirmendatenBankSettingsQuery, FirmendatenBankSettingsQueryVariables>;
export const FirmendatenBankSettingsChangeHistoryListDocument = gql`
  query FirmendatenBankSettingsChangeHistoryList {
    getFirmendatenBankSettingsChangeHistoryList {
      data {
        ...HistoryFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${HistoryFieldsFragmentDoc}
`;
export function useFirmendatenBankSettingsChangeHistoryListQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenBankSettingsChangeHistoryListQuery, FirmendatenBankSettingsChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenBankSettingsChangeHistoryListQuery, FirmendatenBankSettingsChangeHistoryListQueryVariables>(
    FirmendatenBankSettingsChangeHistoryListDocument,
    options
  );
}
export function useFirmendatenBankSettingsChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenBankSettingsChangeHistoryListQuery, FirmendatenBankSettingsChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenBankSettingsChangeHistoryListQuery, FirmendatenBankSettingsChangeHistoryListQueryVariables>(
    FirmendatenBankSettingsChangeHistoryListDocument,
    options
  );
}
export function useFirmendatenBankSettingsChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenBankSettingsChangeHistoryListQuery, FirmendatenBankSettingsChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenBankSettingsChangeHistoryListQuery, FirmendatenBankSettingsChangeHistoryListQueryVariables>(
    FirmendatenBankSettingsChangeHistoryListDocument,
    options
  );
}
export type FirmendatenBankSettingsChangeHistoryListQueryHookResult = ReturnType<typeof useFirmendatenBankSettingsChangeHistoryListQuery>;
export type FirmendatenBankSettingsChangeHistoryListLazyQueryHookResult = ReturnType<typeof useFirmendatenBankSettingsChangeHistoryListLazyQuery>;
export type FirmendatenBankSettingsChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useFirmendatenBankSettingsChangeHistoryListSuspenseQuery
>;
export type FirmendatenBankSettingsChangeHistoryListQueryResult = Apollo.QueryResult<
  FirmendatenBankSettingsChangeHistoryListQuery,
  FirmendatenBankSettingsChangeHistoryListQueryVariables
>;
