import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { InfoMailDeliveryFieldsFragmentDoc } from '../../../Wizard/InfoMailDelivery/gql/InfoMailDeliveryWizardFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InfoMailDeliveryDetermineRecipientListGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type InfoMailDeliveryDetermineRecipientListGenerierlaufQuery = {
  getInfoMailDeliveryDetermineRecipientListGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus; description?: string | null };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  emailAddress?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  recipientId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.InfoMailDeliveryDetermineRecipientListGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery = {
  getInfoMailDeliveryDetermineRecipientListGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        generierlaufEntryId: string;
        infoMessage?: string | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        infoMailDelivery?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          emailAddress: string;
          emailDeliveryDefinitionBezeichnung: string;
          emailDeliveryDefinitionId: string;
          infoMailDeliveryId: string;
          mailId?: string | null;
          original: boolean;
          sender?: string | null;
          sentTs?: string | null;
          shouldSend: boolean;
          objektTopVertrag: {
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            createTs: string;
            name: string;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              createTs: string;
              objektId: string;
              status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            objekt: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              erstelltAm: string;
              fotoFileId?: string | null;
              fotoType?: Types.FotoType | null;
              kurzBezeichnung: string;
              objektId: string;
              rechnungsAusstellerId: string;
              verwaltungBis?: string | null;
              status: { text: string; value: Types.ObjektStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            vertrag: {
              bestandseinheitId: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              createTs: string;
              kurzBezeichnung: string;
              objektId: string;
              vertragsBeginn: string;
              vertragId: string;
              vertragspartner: {
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                createTs: string;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                type: { text: string; value: Types.RechtstraegerType };
                status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
              status: { description?: string | null; text: string; value: Types.VertragStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          recipient: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const InfoMailDeliveryDetermineRecipientListGenerierlaufDocument = gql`
  query InfoMailDeliveryDetermineRecipientListGenerierlauf($generierlaufId: ID!) {
    getInfoMailDeliveryDetermineRecipientListGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          text
          value
          description
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useInfoMailDeliveryDetermineRecipientListGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<
    InfoMailDeliveryDetermineRecipientListGenerierlaufQuery,
    InfoMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
  > &
    ({ variables: InfoMailDeliveryDetermineRecipientListGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InfoMailDeliveryDetermineRecipientListGenerierlaufQuery, InfoMailDeliveryDetermineRecipientListGenerierlaufQueryVariables>(
    InfoMailDeliveryDetermineRecipientListGenerierlaufDocument,
    options
  );
}
export function useInfoMailDeliveryDetermineRecipientListGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InfoMailDeliveryDetermineRecipientListGenerierlaufQuery,
    InfoMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InfoMailDeliveryDetermineRecipientListGenerierlaufQuery,
    InfoMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
  >(InfoMailDeliveryDetermineRecipientListGenerierlaufDocument, options);
}
export function useInfoMailDeliveryDetermineRecipientListGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InfoMailDeliveryDetermineRecipientListGenerierlaufQuery,
        InfoMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InfoMailDeliveryDetermineRecipientListGenerierlaufQuery,
    InfoMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
  >(InfoMailDeliveryDetermineRecipientListGenerierlaufDocument, options);
}
export type InfoMailDeliveryDetermineRecipientListGenerierlaufQueryHookResult = ReturnType<
  typeof useInfoMailDeliveryDetermineRecipientListGenerierlaufQuery
>;
export type InfoMailDeliveryDetermineRecipientListGenerierlaufLazyQueryHookResult = ReturnType<
  typeof useInfoMailDeliveryDetermineRecipientListGenerierlaufLazyQuery
>;
export type InfoMailDeliveryDetermineRecipientListGenerierlaufSuspenseQueryHookResult = ReturnType<
  typeof useInfoMailDeliveryDetermineRecipientListGenerierlaufSuspenseQuery
>;
export type InfoMailDeliveryDetermineRecipientListGenerierlaufQueryResult = Apollo.QueryResult<
  InfoMailDeliveryDetermineRecipientListGenerierlaufQuery,
  InfoMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
>;
export const InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListDocument = gql`
  query InfoMailDeliveryDetermineRecipientListGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $emailAddress: String
    $vertragspartnerId: String
    $recipientId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: InfoMailDeliveryDetermineRecipientListGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getInfoMailDeliveryDetermineRecipientListGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      emailAddress: $emailAddress
      vertragspartnerId: $vertragspartnerId
      recipientId: $recipientId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          errorList {
            message
            type
          }
          exitCode {
            text
            value
          }
          generierlaufEntryId
          infoMailDelivery {
            ...InfoMailDeliveryFields
          }
          infoMessage
          warningList {
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${InfoMailDeliveryFieldsFragmentDoc}
`;
export function useInfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
    InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
  > &
    ({ variables: InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
    InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
  >(InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListDocument, options);
}
export function useInfoMailDeliveryDetermineRecipientListGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
    InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
    InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
  >(InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListDocument, options);
}
export function useInfoMailDeliveryDetermineRecipientListGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
        InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
    InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
  >(InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListDocument, options);
}
export type InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryHookResult = ReturnType<
  typeof useInfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery
>;
export type InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListLazyQueryHookResult = ReturnType<
  typeof useInfoMailDeliveryDetermineRecipientListGenerierlaufEntryListLazyQuery
>;
export type InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<
  typeof useInfoMailDeliveryDetermineRecipientListGenerierlaufEntryListSuspenseQuery
>;
export type InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
  InfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
>;
