import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { Abrechnungskreis } from '../../../objektAbrechnung-types';
import OAAufteilungsschluessel from './shared/OAAufteilungsschluessel';

const OAAbrKreisBezeichnung: FC<{ abrechKreis: Abrechnungskreis }> = ({ abrechKreis }) => (
  <View style={[pdfStyles.row]}>
    <Text
      style={[
        pdfStyles.column,
        pdfStyles.textNormal,
        {
          fontWeight: 'bold',
          width: '40%',
        },
      ]}
    >
      {abrechKreis.bezeichnung}
    </Text>
    <View style={[pdfStyles.column, { width: '60%' }]}>
      {abrechKreis.aufteilungsschluessel ? <OAAufteilungsschluessel aufteilungsschluessel={abrechKreis.aufteilungsschluessel} /> : null}
    </View>
  </View>
);

export default OAAbrKreisBezeichnung;
