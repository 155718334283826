import React, { FC } from 'react';
import { useFirmendatenBankDetailsListQuery } from '../../../../BankDetails/gql/BankDetailsQueries.types';
import FirmendatenKontoTable from './FirmendatenKontoTable';
import { Firmendaten } from '../../../../../types';

type Props = {
  firmendaten: Firmendaten;
};

const FirmendatenKontoListing: FC<Props> = ({ firmendaten }) => {
  const { loading, data, refetch } = useFirmendatenBankDetailsListQuery({
    variables: {
      firmendatenId: firmendaten.firmendatenId,
    },
  });
  const bankDetailsList = data?.getFirmendatenBankDetailsList.data ?? [];

  return <FirmendatenKontoTable bankDetailsList={bankDetailsList} firmendaten={firmendaten} loading={loading} onAction={refetch} />;
};

export default FirmendatenKontoListing;
