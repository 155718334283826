import React, { FC } from 'react';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { EllipsisOutlined, HistoryOutlined } from '@ant-design/icons';
import { AddressSharedModel } from '../../../../shared/components/Address/AddressSharedModel';
import { useToggle } from '../../../../hooks/useToggle';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import Addresse from '../../../../shared/components/Address/AddressText';
import { Spacer } from '../../../../components/Grid';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import AddressChangeHistoryListingTable from '../../../Rechtstraeger/AddressesAndContacts/List/ListItem/shared/AddressChangeHistoryListingTable';

type Props = {
  address: AddressSharedModel;
};

const InnerCardAddress: FC<Props> = ({ address }) => {
  const [isChangeHistoryOpen, toggleChangehistoryOpen] = useToggle();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Änderungsverlauf',
      onClick: toggleChangehistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  return (
    <>
      <Space direction="horizontal" align="start" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Addresse address={address} />
        <Dropdown menu={{ items }}>
          <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
        </Dropdown>
      </Space>
      <Spacer />
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangehistoryOpen}>
        <AddressChangeHistoryListingTable addressId={address.addressId} />
      </HistoryModal>
    </>
  );
};
export default InnerCardAddress;
