import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_SUB_ABRECHNUNG_EIGENTUEMER_DETAILS_PAGE } from '../../features/Abrechnung/Sub/subAbrechnungEigentumerUriPaths';
import SubAbrechnungEigentuemerDetailsPage from './SubAbrechnungEigentuemerDetailsPage';

export type SubAbrechnungEigentuemerDetailsPageRouteParams = { objektId: string; subAbrechnungId: string; subAbrechnungEigentuemerId: string };

const subAbrechnungEigentuemerDetailsPageRoute = (
  <Route
    path={`${URI_SUB_ABRECHNUNG_EIGENTUEMER_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<SubAbrechnungEigentuemerDetailsPage />} />}
  />
);

export default subAbrechnungEigentuemerDetailsPageRoute;
