import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import wizardListingTableColumns from './wizardListingTableColumns';
import { Wizard } from '../../../../types';
import { HandleTableSorting } from '../../../../shared/typeHelpers';

type Props = {
  handleTableSorting: HandleTableSorting;
  loading: boolean;
  pagination: PaginationProps;
  onAction: () => void;
  wizardList?: Wizard[];
};

const WizardListTable: FC<Props> = ({ handleTableSorting, loading, pagination, wizardList, onAction }) => {
  return (
    <TableWithColSelector<Wizard>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Assistenten vorhanden</span>} />,
      }}
      loading={!wizardList}
      pagination={pagination}
      columns={wizardListingTableColumns(onAction)}
      dataSource={wizardList}
      onChange={handleTableSorting}
      rowKey={(record) => record.wizardId}
      filterIdentifier="main-wizard"
    />
  );
};

export default WizardListTable;
