import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useHeVertragKurzBezeichnungQuery } from '../../Breadcrumb/gql/KurzbezeichnungQueries.types';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { generatePathToHeVertragDetailsPage } from '../../Vertrag/HeVertrag/heVertragUriPaths';

type Props = {
  objektId: string;
  heVertragId: string;
  fontWeight?: 'normal' | 'bold';
};

const PaymentHeVertragColumn: FC<Props> = ({ objektId, heVertragId, fontWeight = 'normal' }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data, loading } = useHeVertragKurzBezeichnungQuery({ variables: { objektId, heVertragId } });

  const bezeichnung = data?.getHeVertragKurzbezeichnung.data;

  return (
    <Skeleton active paragraph={{ rows: 0 }} loading={loading}>
      {bezeichnung ? (
        <DataWithShortenedText maxTextLength={20} text={bezeichnung}>
          {(shortenedText) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Link target="_blank" to={generatePathToHeVertragDetailsPage(objektId, heVertragId!)} style={{ fontWeight }}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      )}
    </Skeleton>
  );
};

export default PaymentHeVertragColumn;
