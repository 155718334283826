import { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../components/Grid';
import { verarbeitungPageAndMenuListTitles } from '../verarbeitungHelpers';
import InfoMailDeliverySendVerarbeitungEntryListing from './EntryListing/InfoMailDeliverySendVerarbeitungEntryListing';
import { InfoMailDeliverySendGenerierlauf } from '../../../types';

type Props = {
  generierlauf: InfoMailDeliverySendGenerierlauf;
};

const VerarbeitungInfoMailDeliverySend: FC<Props> = ({ generierlauf }) => {
  return (
    <>
      <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.InfoMailDeliverySend}</Typography.Title>
      <Spacer />
      <InfoMailDeliverySendVerarbeitungEntryListing generierlauf={generierlauf} />
    </>
  );
};

export default VerarbeitungInfoMailDeliverySend;
