import { toString } from 'lodash';

const stringOrEmpty = (string: any) => (string ? string.toString() : '');

const capitalizeString = (string: string) => {
  const removeUnderscore = string.replace(/[_-]/g, '');
  return capitalizeFirstLetter(removeUnderscore) + removeUnderscore.slice(1).toLowerCase();
};

const uppercaseString = (string: string) => string.toUpperCase();

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase();

const compareTwoStringsForSorting = (string1: string | null | undefined, string2: string | null | undefined) =>
  stringOrEmpty(string1).localeCompare(stringOrEmpty(string2));

const shouldTextBeShortened = (text: string, maxLength: number) => text.length > maxLength;

const shortenTextIfNeeded = (text: string | null | undefined, maxLength: number) => {
  if (text && shouldTextBeShortened(text, maxLength)) {
    const end = text.length - (text.length - maxLength);
    return text.substring(0, end) + '…';
  }
  return text ?? '';
};
const userToString = (user: { email?: string | null }) => toString(user.email);

const replaceNewLinesWithBr = (text: string) => text.replace(/\n/g, '<br />');

const isTextEqualOrLongerThanThreeCharacters = (text: string) => text.length >= 3;

export {
  capitalizeFirstLetter,
  capitalizeString,
  compareTwoStringsForSorting,
  isTextEqualOrLongerThanThreeCharacters,
  replaceNewLinesWithBr,
  shortenTextIfNeeded,
  shouldTextBeShortened,
  stringOrEmpty,
  uppercaseString,
  userToString,
};
