import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  bestandseinheitList: VerarbeitungBestandseinheitFormSelectProps[];
  objektId?: string;
  width?: string;
  maxTextLength?: number;
} & SelectProps;

export interface VerarbeitungBestandseinheitFormSelectProps {
  bestandseinheitId: string;
  bezeichnung: string;
}

const VerarbeitungBestandseinheitFormSelect: FC<Props> = ({ bestandseinheitList, objektId, width, maxTextLength, ...restProps }) => {
  return (
    <Select
      size="small"
      id={restProps.name}
      placeholder="Bestandseinheit auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      style={{ width: width || '100%' }}
      disabled={!objektId}
      {...restProps}
    >
      {bestandseinheitList.map((bestandseinheit) => (
        <Select.Option key={bestandseinheit.bestandseinheitId} value={bestandseinheit.bestandseinheitId}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? bestandseinheit.bezeichnung.length} text={bestandseinheit.bezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default VerarbeitungBestandseinheitFormSelect;
