import React, { FC } from 'react';
import { MenuProps, Modal } from 'antd';
import { FormikProps } from 'formik';
import { ReadOutlined } from '@ant-design/icons';
import ActionDropdown from '../../../components/Dropdown/ActionDropdown';
import { FiltersFormValues, mapFormValuesToBulkAction } from './Filters/filtersFormMapper';
import { showSuccessMsgVerbuchen } from '../../../components/message/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useCreateIncomingInvoiceBookingCreationGenerierlaufMutation } from '../../Verarbeitung/gql/IncomingInvoiceBookingCreationGenerierlauf/IncomingInvoiceBookingCreationGenerierlaufMutations.types';

type Props = {
  onAction: () => void;
  formikProps: FormikProps<FiltersFormValues>;
};

const IncomingInvoiceTableBulkActions: FC<Props> = ({ onAction, formikProps }) => {
  const [runVerbuchen] = useCreateIncomingInvoiceBookingCreationGenerierlaufMutation({
    variables: { input: mapFormValuesToBulkAction(formikProps.values) },
    onCompleted: () => {
      showSuccessMsgVerbuchen('Eingangsrechnungen');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Verbuchen',
      onClick: () => showConfirmVerbuchen(runVerbuchen),
      icon: <ReadOutlined />,
    },
  ];
  return <ActionDropdown items={items} title="Auf Liste anwenden" />;
};

export const showConfirmVerbuchen = (runFreigeben: () => Promise<unknown>) => {
  Modal.confirm({
    title: `Möchten Sie die Eingangsrechnungen verbuchen?`,
    okText: 'Verbuchen',
    cancelText: 'Abbrechen',
    onOk() {
      return runFreigeben();
    },
  });
};

export default IncomingInvoiceTableBulkActions;
