import { AbrechnungsdefinitionType, AbrechnungskreisType } from '../../../../types';

export const isAbrKreisTypeVstKuerzung = (abrKreisType?: AbrechnungskreisType) => abrKreisType === AbrechnungskreisType.Vorsteuerkuerzung;
export const isAbrKreisTypeRepFonds = (abrKreisType?: AbrechnungskreisType) => abrKreisType === AbrechnungskreisType.ReparaturFonds;
export const isAbrKreisTypeAusgaben = (abrKreisType?: AbrechnungskreisType) => abrKreisType === AbrechnungskreisType.Ausgaben;
export const isAbrKreisTypeBewirtschaftungskosten = (abrKreisType?: AbrechnungskreisType) =>
  abrKreisType === AbrechnungskreisType.Bewirtschaftungskosten;
export const isAbrKreisTypeEigentuemerverrechnung = (abrKreisType?: AbrechnungskreisType) =>
  abrKreisType === AbrechnungskreisType.Eigentuemerverrechnung;
export const isAbrKreisTypeEinnahmen = (abrKreisType?: AbrechnungskreisType) => abrKreisType === AbrechnungskreisType.Einnahmen;
export const isAbrKreisTypeInformation = (abrKreisType?: AbrechnungskreisType) => abrKreisType === AbrechnungskreisType.Information;

export const isAbrDefTypeWeAbrechnung = (abrDefType?: AbrechnungsdefinitionType | null) => abrDefType === AbrechnungsdefinitionType.WeAbrechnung;
export const isAbrDefTypeHeAbrechnung = (abrDefType?: AbrechnungsdefinitionType | null) => abrDefType === AbrechnungsdefinitionType.HeAbrechnung;
export const isAbrDefTypeBkAbrechnung = (abrDefType?: AbrechnungsdefinitionType | null) => abrDefType === AbrechnungsdefinitionType.BkAbrechnung;
export const isAbrDefTypeSubAbrechnung = (abrDefType?: AbrechnungsdefinitionType | null) => abrDefType === AbrechnungsdefinitionType.SubAbrechnung;
