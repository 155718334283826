import * as Yup from 'yup';
import { entityIsRequired, entityMustBeSelected } from '../../../../components/message/validationMsg';
import { ebicsUserFormFields } from './ebicsUserFormMapper';

export const ebicsUserValidationSchema = Yup.object().shape({
  [ebicsUserFormFields.ebicsBankConfigId]: Yup.string().required(entityMustBeSelected('Bankname')),
  [ebicsUserFormFields.mitarbeiterId]: Yup.string().required(entityMustBeSelected('Mitarbeiter')),
  [ebicsUserFormFields.partnerId]: Yup.string().required(entityMustBeSelected('Kundenkennung')),
  [ebicsUserFormFields.password]: Yup.string().required(entityIsRequired('Password')),
  [ebicsUserFormFields.userId]: Yup.string().required(entityIsRequired('Teilnehmerkennung')),
});
