import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { MitarbeiterFragmentDoc } from './MitarbeiterFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MitarbeiterListByFirmendatenIdQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  includeUser?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  userEmail?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type MitarbeiterListByFirmendatenIdQuery = {
  getMitarbeiterListByFirmendatenId: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileStorageInitialized: boolean;
      firmendatenId: string;
      firstname: string;
      funktionsModulList: Array<string>;
      lastname: string;
      mitarbeiterId: string;
      title?: string | null;
      titleTrailing?: string | null;
      username: string;
      contactPerson?: {
        contactPersonId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        tagList: Array<string>;
        title?: string | null;
        titleTrailing?: string | null;
        contactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rolleList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        name: string;
        rolleId: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
      status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
      user?: {
        email?: string | null;
        emailUnverified?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        role: string;
        username: string;
        status: { text: string; value: string };
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MitarbeiterByFirmendatenIdQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  mitarbeiterId: Types.Scalars['ID']['input'];
  includeUser?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type MitarbeiterByFirmendatenIdQuery = {
  getMitarbeiterDetailsByFirmendatenId: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileStorageInitialized: boolean;
      firmendatenId: string;
      firstname: string;
      funktionsModulList: Array<string>;
      lastname: string;
      mitarbeiterId: string;
      title?: string | null;
      titleTrailing?: string | null;
      username: string;
      contactPerson?: {
        contactPersonId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        tagList: Array<string>;
        title?: string | null;
        titleTrailing?: string | null;
        contactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rolleList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        name: string;
        rolleId: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
      status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
      user?: {
        email?: string | null;
        emailUnverified?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        role: string;
        username: string;
        status: { text: string; value: string };
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const MitarbeiterListByFirmendatenIdDocument = gql`
  query MitarbeiterListByFirmendatenId($firmendatenId: String!, $includeUser: Boolean, $userEmail: String) {
    getMitarbeiterListByFirmendatenId(firmendatenId: $firmendatenId, includeUser: $includeUser, userEmail: $userEmail) {
      data {
        ...Mitarbeiter
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MitarbeiterFragmentDoc}
`;
export function useMitarbeiterListByFirmendatenIdQuery(
  baseOptions: Apollo.QueryHookOptions<MitarbeiterListByFirmendatenIdQuery, MitarbeiterListByFirmendatenIdQueryVariables> &
    ({ variables: MitarbeiterListByFirmendatenIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MitarbeiterListByFirmendatenIdQuery, MitarbeiterListByFirmendatenIdQueryVariables>(
    MitarbeiterListByFirmendatenIdDocument,
    options
  );
}
export function useMitarbeiterListByFirmendatenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MitarbeiterListByFirmendatenIdQuery, MitarbeiterListByFirmendatenIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MitarbeiterListByFirmendatenIdQuery, MitarbeiterListByFirmendatenIdQueryVariables>(
    MitarbeiterListByFirmendatenIdDocument,
    options
  );
}
export function useMitarbeiterListByFirmendatenIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MitarbeiterListByFirmendatenIdQuery, MitarbeiterListByFirmendatenIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MitarbeiterListByFirmendatenIdQuery, MitarbeiterListByFirmendatenIdQueryVariables>(
    MitarbeiterListByFirmendatenIdDocument,
    options
  );
}
export type MitarbeiterListByFirmendatenIdQueryHookResult = ReturnType<typeof useMitarbeiterListByFirmendatenIdQuery>;
export type MitarbeiterListByFirmendatenIdLazyQueryHookResult = ReturnType<typeof useMitarbeiterListByFirmendatenIdLazyQuery>;
export type MitarbeiterListByFirmendatenIdSuspenseQueryHookResult = ReturnType<typeof useMitarbeiterListByFirmendatenIdSuspenseQuery>;
export type MitarbeiterListByFirmendatenIdQueryResult = Apollo.QueryResult<
  MitarbeiterListByFirmendatenIdQuery,
  MitarbeiterListByFirmendatenIdQueryVariables
>;
export const MitarbeiterByFirmendatenIdDocument = gql`
  query MitarbeiterByFirmendatenId($firmendatenId: String!, $mitarbeiterId: ID!, $includeUser: Boolean) {
    getMitarbeiterDetailsByFirmendatenId(firmendatenId: $firmendatenId, mitarbeiterId: $mitarbeiterId, includeUser: $includeUser) {
      data {
        ...Mitarbeiter
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MitarbeiterFragmentDoc}
`;
export function useMitarbeiterByFirmendatenIdQuery(
  baseOptions: Apollo.QueryHookOptions<MitarbeiterByFirmendatenIdQuery, MitarbeiterByFirmendatenIdQueryVariables> &
    ({ variables: MitarbeiterByFirmendatenIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MitarbeiterByFirmendatenIdQuery, MitarbeiterByFirmendatenIdQueryVariables>(MitarbeiterByFirmendatenIdDocument, options);
}
export function useMitarbeiterByFirmendatenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MitarbeiterByFirmendatenIdQuery, MitarbeiterByFirmendatenIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MitarbeiterByFirmendatenIdQuery, MitarbeiterByFirmendatenIdQueryVariables>(MitarbeiterByFirmendatenIdDocument, options);
}
export function useMitarbeiterByFirmendatenIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MitarbeiterByFirmendatenIdQuery, MitarbeiterByFirmendatenIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MitarbeiterByFirmendatenIdQuery, MitarbeiterByFirmendatenIdQueryVariables>(
    MitarbeiterByFirmendatenIdDocument,
    options
  );
}
export type MitarbeiterByFirmendatenIdQueryHookResult = ReturnType<typeof useMitarbeiterByFirmendatenIdQuery>;
export type MitarbeiterByFirmendatenIdLazyQueryHookResult = ReturnType<typeof useMitarbeiterByFirmendatenIdLazyQuery>;
export type MitarbeiterByFirmendatenIdSuspenseQueryHookResult = ReturnType<typeof useMitarbeiterByFirmendatenIdSuspenseQuery>;
export type MitarbeiterByFirmendatenIdQueryResult = Apollo.QueryResult<MitarbeiterByFirmendatenIdQuery, MitarbeiterByFirmendatenIdQueryVariables>;
