import React from 'react';
import { Budgeting, BudgetingBuchungszeile } from '../../../../../types';
import { EuroAmount } from '../../../../../components/Number';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import { uriFileOfFirmendaten } from '../../../../Document/documentApiPaths';
import { isNotNil } from '../../../../../helpers/assertionHelper';
import { IStatus } from '../../../../../helpers/statusHelper';
import LinkWithStatus from '../../../../../components/Link/LinkWithStatus';
import DataWithStatus from '../../../../../components/Helpers/DataWithStatus';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import { generatePathToBookingDetailsPage } from '../../../../BookingDetails/bookingDetailsPaths';
import { NestedTableWithColSelectorColProps } from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

const budgetingBuchungszeileTableColumns = (
  budgeting: Budgeting,
  activeForFirmendatenId: string,
  budgetingVPosStatus: IStatus
): NestedTableWithColSelectorColProps<BudgetingBuchungszeile>[] => {
  return [
    {
      title: 'Text',
      width: 200,
      key: 'vpos',
      render: (text, record) => (
        <DataWithStatus status={budgetingVPosStatus} text={record.text} maxTextLength={30} showBadgeDot={false} showStatusDescription={false} />
      ),
    },
    {
      title: 'Ausgaben Vorjahr',
      key: 'prev-year',
      width: 170,
      align: 'right',
      render: (text, record) =>
        isNotNil(record.ausgabenPrevYear) ? (
          <LinkWithStatus
            status={budgetingVPosStatus}
            text=""
            to={generatePathToBookingDetailsPage(record.buchungId ? [record.buchungId] : [])}
            openInNewTab
            showBadgeDot={false}
          >
            <EuroAmount
              value={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                record.ausgabenPrevYear!
              }
            />
          </LinkWithStatus>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: 'Ausgaben aktuelles jahr',
      key: 'current-year',
      width: 170,
      align: 'right',
      render: (text, record) =>
        isNotNil(record.ausgabenCurrentYear) ? (
          <LinkWithStatus
            status={budgetingVPosStatus}
            text=""
            to={generatePathToBookingDetailsPage(record.buchungId ? [record.buchungId] : [])}
            openInNewTab
            showBadgeDot={false}
          >
            <EuroAmount
              value={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                record.ausgabenCurrentYear!
              }
            />
          </LinkWithStatus>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: 'Belegdatum',
      defaultSelected: true,
      width: 200,
      align: 'right',
      render: (text, record) => (
        <DataWithStatus status={budgetingVPosStatus} text="" showBadgeDot={false} showStatusDescription={false}>
          {() => <CustomFormattedDate value={record.belegDatum} />}
        </DataWithStatus>
      ),
    },
    {
      title: 'Beleg-Nr.',
      defaultSelected: true,
      align: 'right',
      render: (text, record) =>
        record.belegnummer && record.belegFileId ? (
          <LinkWithStatus
            status={budgetingVPosStatus}
            text={record.belegnummer}
            to={uriFileOfFirmendaten(activeForFirmendatenId, record.belegFileId, '')}
            openInNewTab
            maxTextLength={30}
            showBadgeDot={false}
          />
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: '',
    },
    {
      title: 'Vertragspartner',
      defaultSelected: true,
      align: 'right',
      render: (text, record) =>
        record.vertragspartner ? (
          <LinkWithStatus
            status={budgetingVPosStatus}
            text={record.vertragspartner.kurzBezeichnung}
            to={generatePathToRechtstraegerDetailsPage(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              record.vertragspartner!.rechtstraegerId
            )}
            openInNewTab
            maxTextLength={30}
            showBadgeDot={false}
          />
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: '',
      key: 'jahresbudget-summe',
    },
    {
      title: '',
      width: 100,
      key: 'action',
    },
  ];
};

export default budgetingBuchungszeileTableColumns;
