import React, { FC } from 'react';
import { useFirmendatenBankDetailsListQuery } from '../gql/BankDetailsQueries.types';
import BankDetailsListing from '../BankDetailsListing';
import { useToggle } from '../../../hooks/useToggle';
import { useOnFirmendatenBankDetailsDataChangeEvent } from './useOnFirmendatenBankDetailsDataChangeEvent';

type Props = {
  firmendatenId: string;
};

const FirmendatenBankDetails: FC<Props> = ({ firmendatenId }) => {
  const [showArchived, toggleShowArchived] = useToggle();
  const { loading, data, refetch } = useFirmendatenBankDetailsListQuery({
    variables: {
      firmendatenId,
    },
  });
  const bankDetailsList = data?.getFirmendatenBankDetailsList.data ?? [];

  useOnFirmendatenBankDetailsDataChangeEvent('firmendatenBankDetails', refetch, firmendatenId);

  // INFO: fibuKontoCreatable always true for Firmendaten
  return (
    <BankDetailsListing
      firmendatenId={firmendatenId}
      loading={loading}
      bankDetailsList={bankDetailsList}
      onSuccess={refetch}
      fibuKontoCreatable
      showArchived={showArchived}
      toggleShowArchived={toggleShowArchived}
    />
  );
};

export default FirmendatenBankDetails;
