import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import SaldenlistePage from './SaldenlistePage';
import { URI_SALDENLISTE_PAGE } from '../../features/Saldenliste/saldenlisteUriPaths';

const saldenlistePageRoute = (
  <Route path={URI_SALDENLISTE_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<SaldenlistePage />} />} />
);

export default saldenlistePageRoute;
