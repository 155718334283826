import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { columnWidthsAndAlignment, grundlageColumnPaddingLeft } from './aufwandAufteilung-table-styles';
import { RepFondsAufwandAufteilungLine } from '../../../../topAbrechnung-types';

const ColumnTitles: FC<{ titles: RepFondsAufwandAufteilungLine }> = ({ titles }) => (
  <View
    style={[
      pdfStyles.row,
      pdfStyles.borderBottomSmall,
      {
        paddingTop: '3mm',
        fontWeight: 'bold',
      },
    ]}
  >
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.basis.width,
          textAlign: columnWidthsAndAlignment.basis.textAlign,
        },
      ]}
    >
      {titles.basis}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.anteilNetto.width,
          textAlign: columnWidthsAndAlignment.anteilNetto.textAlign,
        },
      ]}
    >
      {titles.anteilNetto}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.ustProzent.width,
          textAlign: columnWidthsAndAlignment.ustProzent.textAlign,
        },
      ]}
    >
      {titles.ustProzent}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.ust.width,
          textAlign: columnWidthsAndAlignment.ust.textAlign,
        },
      ]}
    >
      {titles.ust}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.summe.width,
          textAlign: columnWidthsAndAlignment.summe.textAlign,
        },
      ]}
    >
      {titles.summe}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.grundlage.width,
          textAlign: columnWidthsAndAlignment.grundlage.textAlign,
          paddingLeft: grundlageColumnPaddingLeft,
        },
      ]}
    >
      {titles.grundlage}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.belastung.width,
          textAlign: columnWidthsAndAlignment.belastung.textAlign,
        },
      ]}
    >
      {titles.belastung}
    </Text>
  </View>
);

export default ColumnTitles;
