import React, { FC } from 'react';
import { Skeleton, Typography } from 'antd';
import CardWithFooter from '../../../components/Card';
import CardTitle from '../../../components/Card/CardTitle';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import { TGenerierlauf } from '../verarbeitungHelpers';
import VerarbeitungDetailsPageCardActions from './VerarbeitungDetailsPageCardActions';
import { showSuccessMsgRestart } from '../../../components/message/message';

type Props = {
  generierlauf: TGenerierlauf;
  cardTitle: string;
  onGenerierlaufRestart?: () => Promise<any>;
};

const VerarbeitungDetailsPageCard: FC<Props> = ({ generierlauf, cardTitle, onGenerierlaufRestart }) => {
  const isRestartActionAvailable = !!onGenerierlaufRestart;

  const handleRestart = () => {
    onGenerierlaufRestart?.().finally(() => {
      showSuccessMsgRestart('Verarbeitung');
    });
  };

  return (
    <CardWithFooter
      createTs={generierlauf.createTs}
      mitarbeiterId={generierlauf.createdByMitarbeiterId}
      loading={false}
      style={{ minWidth: '320px' }}
      size="default"
      bordered={false}
      title={<CardTitle title={cardTitle} status={generierlauf.status} />}
      extra={
        isRestartActionAvailable && <VerarbeitungDetailsPageCardActions generierlaufStatus={generierlauf.status.value} onRestart={handleRestart} />
      }
    >
      <Skeleton active loading={false}>
        <CardDetailsInfoRow infoRowTitle="Verarbeitet">
          <Typography.Text>{generierlauf?.countVerarbeitet}</Typography.Text>
        </CardDetailsInfoRow>
        <CardDetailsInfoRow infoRowTitle="Fehler">
          <Typography.Text>{generierlauf?.countFehler}</Typography.Text>
        </CardDetailsInfoRow>
        <CardDetailsInfoRow infoRowTitle="Nicht verarbeitet">
          <Typography.Text>{generierlauf?.countNichtVerarbeitet}</Typography.Text>
        </CardDetailsInfoRow>
      </Skeleton>
    </CardWithFooter>
  );
};

export default VerarbeitungDetailsPageCard;
