import { Tag, TagProps } from 'antd';
import React, { FC } from 'react';

const PaidTag: FC<TagProps> = ({ ...restProps }) => (
  <Tag {...restProps} color="green">
    BEZAHLT
  </Tag>
);

export default PaidTag;
