import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isAdminOrHasSystemAdminFm } from '../../../security/permissionChecks';
import VerwaltungFirmendatenDetailsPage from './index';
import { URI_MANAGEMENT_PAGE_SYSTEM_FIRMENDATEN_DETAILS_PAGE } from '../../../constants/managementPageUriPaths';

export type FirmendatenDetailsPageRouteParams = { firmendatenId: string };

const verwaltungFirmendatenDetailsPageRoute = (
  <Route
    path={`${URI_MANAGEMENT_PAGE_SYSTEM_FIRMENDATEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isAdminOrHasSystemAdminFm} element={<VerwaltungFirmendatenDetailsPage />} />}
  />
);

export default verwaltungFirmendatenDetailsPageRoute;
