import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';
import { FiltersFormValues } from './listingFiltersFormMapper';

enum QueryParamKey {
  EXIT_CODE_LIST = 'exitCodeList',
  INVOICE_DATE_FROM = 'invoiceDateFrom',
  INVOICE_DATE_TO = 'invoiceDateTo',
  INVOICE_NUMBER = 'invoiceNumber',
  ISSUER_ID = 'issuerId',
  OBJEKT_ID = 'objektId',
  PAYEE_ID = 'payeeId',
}

export type TIncomingInvoiceBookingCreationQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  verarbeitungType: string,
  generierlaufId: string,
  filters: TIncomingInvoiceBookingCreationQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToIncomingInvoiceVerarbeitungPage(verarbeitungType, generierlaufId, filters, paginationParams));

export const generatePathToIncomingInvoiceVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  filters: TIncomingInvoiceBookingCreationQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [QueryParamKey.EXIT_CODE_LIST]: filters?.exitCodeList,
    [QueryParamKey.INVOICE_DATE_FROM]: filters?.invoiceDateFrom,
    [QueryParamKey.INVOICE_DATE_TO]: filters?.invoiceDateTo,
    [QueryParamKey.INVOICE_NUMBER]: filters?.invoiceNumber,
    [QueryParamKey.ISSUER_ID]: filters?.issuerId,
    [QueryParamKey.OBJEKT_ID]: filters?.objektId,
    [QueryParamKey.PAYEE_ID]: filters?.payeeId,
    ...paginationParams,
  })}`;
