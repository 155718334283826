import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { FibuObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { FibuRechtstraegerFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilterGenLaufFibuGenerierlaufEntryStatusListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.FibuGenerierlaufType;
}>;

export type FilterGenLaufFibuGenerierlaufEntryStatusListQuery = {
  getFilterGenLaufFibuGenerierlaufEntryStatusList: {
    data: Array<{ text: string; value: Types.GenerierlaufEntryExitCode }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufFibuObjektListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.FibuGenerierlaufType;
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type FilterGenLaufFibuObjektListQuery = {
  getFilterGenLaufFibuObjektList: {
    data: Array<{ kurzBezeichnung: string; objektId: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufFibuRechnungsAusstellerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.FibuGenerierlaufType;
}>;

export type FilterGenLaufFibuRechnungsAusstellerListQuery = {
  getFilterGenLaufFibuRechnungsAusstellerList: {
    data: Array<{
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FilterGenLaufFibuGenerierlaufEntryStatusListDocument = gql`
  query FilterGenLaufFibuGenerierlaufEntryStatusList($generierlaufId: ID!, $generierlaufType: FibuGenerierlaufType!) {
    getFilterGenLaufFibuGenerierlaufEntryStatusList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufFibuGenerierlaufEntryStatusListQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterGenLaufFibuGenerierlaufEntryStatusListQuery,
    FilterGenLaufFibuGenerierlaufEntryStatusListQueryVariables
  > &
    ({ variables: FilterGenLaufFibuGenerierlaufEntryStatusListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufFibuGenerierlaufEntryStatusListQuery, FilterGenLaufFibuGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufFibuGenerierlaufEntryStatusListDocument,
    options
  );
}
export function useFilterGenLaufFibuGenerierlaufEntryStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterGenLaufFibuGenerierlaufEntryStatusListQuery,
    FilterGenLaufFibuGenerierlaufEntryStatusListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufFibuGenerierlaufEntryStatusListQuery, FilterGenLaufFibuGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufFibuGenerierlaufEntryStatusListDocument,
    options
  );
}
export function useFilterGenLaufFibuGenerierlaufEntryStatusListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufFibuGenerierlaufEntryStatusListQuery, FilterGenLaufFibuGenerierlaufEntryStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufFibuGenerierlaufEntryStatusListQuery, FilterGenLaufFibuGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufFibuGenerierlaufEntryStatusListDocument,
    options
  );
}
export type FilterGenLaufFibuGenerierlaufEntryStatusListQueryHookResult = ReturnType<typeof useFilterGenLaufFibuGenerierlaufEntryStatusListQuery>;
export type FilterGenLaufFibuGenerierlaufEntryStatusListLazyQueryHookResult = ReturnType<
  typeof useFilterGenLaufFibuGenerierlaufEntryStatusListLazyQuery
>;
export type FilterGenLaufFibuGenerierlaufEntryStatusListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufFibuGenerierlaufEntryStatusListSuspenseQuery
>;
export type FilterGenLaufFibuGenerierlaufEntryStatusListQueryResult = Apollo.QueryResult<
  FilterGenLaufFibuGenerierlaufEntryStatusListQuery,
  FilterGenLaufFibuGenerierlaufEntryStatusListQueryVariables
>;
export const FilterGenLaufFibuObjektListDocument = gql`
  query FilterGenLaufFibuObjektList($generierlaufId: ID!, $generierlaufType: FibuGenerierlaufType!, $rechnungsAusstellerId: ID) {
    getFilterGenLaufFibuObjektList(
      generierlaufId: $generierlaufId
      generierlaufType: $generierlaufType
      rechnungsAusstellerId: $rechnungsAusstellerId
    ) {
      data {
        ...FibuObjekt
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FibuObjektFragmentDoc}
`;
export function useFilterGenLaufFibuObjektListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufFibuObjektListQuery, FilterGenLaufFibuObjektListQueryVariables> &
    ({ variables: FilterGenLaufFibuObjektListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufFibuObjektListQuery, FilterGenLaufFibuObjektListQueryVariables>(FilterGenLaufFibuObjektListDocument, options);
}
export function useFilterGenLaufFibuObjektListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufFibuObjektListQuery, FilterGenLaufFibuObjektListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufFibuObjektListQuery, FilterGenLaufFibuObjektListQueryVariables>(
    FilterGenLaufFibuObjektListDocument,
    options
  );
}
export function useFilterGenLaufFibuObjektListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterGenLaufFibuObjektListQuery, FilterGenLaufFibuObjektListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufFibuObjektListQuery, FilterGenLaufFibuObjektListQueryVariables>(
    FilterGenLaufFibuObjektListDocument,
    options
  );
}
export type FilterGenLaufFibuObjektListQueryHookResult = ReturnType<typeof useFilterGenLaufFibuObjektListQuery>;
export type FilterGenLaufFibuObjektListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufFibuObjektListLazyQuery>;
export type FilterGenLaufFibuObjektListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufFibuObjektListSuspenseQuery>;
export type FilterGenLaufFibuObjektListQueryResult = Apollo.QueryResult<FilterGenLaufFibuObjektListQuery, FilterGenLaufFibuObjektListQueryVariables>;
export const FilterGenLaufFibuRechnungsAusstellerListDocument = gql`
  query FilterGenLaufFibuRechnungsAusstellerList($generierlaufId: ID!, $generierlaufType: FibuGenerierlaufType!) {
    getFilterGenLaufFibuRechnungsAusstellerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...FibuRechtstraeger
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FibuRechtstraegerFragmentDoc}
`;
export function useFilterGenLaufFibuRechnungsAusstellerListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufFibuRechnungsAusstellerListQuery, FilterGenLaufFibuRechnungsAusstellerListQueryVariables> &
    ({ variables: FilterGenLaufFibuRechnungsAusstellerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufFibuRechnungsAusstellerListQuery, FilterGenLaufFibuRechnungsAusstellerListQueryVariables>(
    FilterGenLaufFibuRechnungsAusstellerListDocument,
    options
  );
}
export function useFilterGenLaufFibuRechnungsAusstellerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufFibuRechnungsAusstellerListQuery, FilterGenLaufFibuRechnungsAusstellerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufFibuRechnungsAusstellerListQuery, FilterGenLaufFibuRechnungsAusstellerListQueryVariables>(
    FilterGenLaufFibuRechnungsAusstellerListDocument,
    options
  );
}
export function useFilterGenLaufFibuRechnungsAusstellerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufFibuRechnungsAusstellerListQuery, FilterGenLaufFibuRechnungsAusstellerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufFibuRechnungsAusstellerListQuery, FilterGenLaufFibuRechnungsAusstellerListQueryVariables>(
    FilterGenLaufFibuRechnungsAusstellerListDocument,
    options
  );
}
export type FilterGenLaufFibuRechnungsAusstellerListQueryHookResult = ReturnType<typeof useFilterGenLaufFibuRechnungsAusstellerListQuery>;
export type FilterGenLaufFibuRechnungsAusstellerListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufFibuRechnungsAusstellerListLazyQuery>;
export type FilterGenLaufFibuRechnungsAusstellerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufFibuRechnungsAusstellerListSuspenseQuery
>;
export type FilterGenLaufFibuRechnungsAusstellerListQueryResult = Apollo.QueryResult<
  FilterGenLaufFibuRechnungsAusstellerListQuery,
  FilterGenLaufFibuRechnungsAusstellerListQueryVariables
>;
