import React, { FC, ReactNode } from 'react';
import { Divider, Space, Typography } from 'antd';
import { StyledFiltersFormWithMax3Fields } from './styled/Filters.style';
import FiltersWithoutTitleAndActions from './FiltersWithoutTitleAndActions';
import TextForEmptyValue from '../Text/TextForEmptyValue';

type FiltersWithMax3FieldsProps = {
  /** For Action Buttons like 'Mahnungen erstellen', 'Ausgezifferte OPs anzeigen' */
  actionButtons?: ReactNode;
  /** For action or bulk actions that should be done on the filtered list, e.g. 'Auf Liste anwenden' in Mahnungen listing */
  filterActions?: ReactNode;
  filters: ReactNode;
  /** If true, 'Filter' title will not be rendered */
  hideTitle?: boolean;
};

const FiltersWithMax3Fields: FC<FiltersWithMax3FieldsProps> = ({ actionButtons, filterActions, filters, hideTitle = false }) => {
  const styledFilters = <StyledFiltersFormWithMax3Fields size="small">{filters}</StyledFiltersFormWithMax3Fields>;

  return hideTitle && !actionButtons && !filterActions ? (
    <FiltersWithoutTitleAndActions styledFilters={styledFilters} />
  ) : (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space align="baseline" style={{ width: '100%', justifyContent: hideTitle ? 'right' : 'space-between' }}>
        {/* If Filters are it a tab on a DetailsPage, 'Filter' should not render
        EMPTY_VALUE needed for proper style in case 'Filter' is not rendered' */}
        {!hideTitle ? <Typography.Title level={4}>Filter</Typography.Title> : <TextForEmptyValue textToShow="none" />}
        <Space direction="vertical" align="end">
          <Space>{actionButtons}</Space>
          <Space>{filterActions}</Space>
        </Space>
      </Space>

      {styledFilters}
      <Divider style={{ marginTop: '8px', marginBottom: '24px' }} />
    </Space>
  );
};

export default FiltersWithMax3Fields;
