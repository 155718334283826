import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { UstRegelsetUstKategorieZuweisungFieldsFragmentDoc } from '../../../UstRegelset/Version/gql/UstRegelseUstKategorieFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstRegelsetTemplateUstKategorieZuweisungListQueryVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
}>;

export type UstRegelsetTemplateUstKategorieZuweisungListQuery = {
  getUstRegelsetTemplateUstKategorieZuweisungList: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      validFrom: string;
      ustRegelsetKategorieZuweisungId: string;
      lastUpdateTs: string;
      ustKategorie: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ message: string; attribute?: string | null; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstRegelsetTemplateUstKategorieZuweisungQueryVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Types.Scalars['ID']['input'];
}>;

export type UstRegelsetTemplateUstKategorieZuweisungQuery = {
  getUstRegelsetTemplateUstKategorieZuweisung: {
    data: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      validFrom: string;
      ustRegelsetKategorieZuweisungId: string;
      ustKategorie: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ message: string; attribute?: string | null; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UstRegelsetTemplateUstKategorieZuweisungListDocument = gql`
  query UstRegelsetTemplateUstKategorieZuweisungList($ustRegelsetId: ID!) {
    getUstRegelsetTemplateUstKategorieZuweisungList(ustRegelsetId: $ustRegelsetId) {
      data {
        ...UstRegelsetUstKategorieZuweisungFields
        lastUpdateTs: createTs
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstRegelsetUstKategorieZuweisungFieldsFragmentDoc}
`;
export function useUstRegelsetTemplateUstKategorieZuweisungListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UstRegelsetTemplateUstKategorieZuweisungListQuery,
    UstRegelsetTemplateUstKategorieZuweisungListQueryVariables
  > &
    ({ variables: UstRegelsetTemplateUstKategorieZuweisungListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstRegelsetTemplateUstKategorieZuweisungListQuery, UstRegelsetTemplateUstKategorieZuweisungListQueryVariables>(
    UstRegelsetTemplateUstKategorieZuweisungListDocument,
    options
  );
}
export function useUstRegelsetTemplateUstKategorieZuweisungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UstRegelsetTemplateUstKategorieZuweisungListQuery,
    UstRegelsetTemplateUstKategorieZuweisungListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstRegelsetTemplateUstKategorieZuweisungListQuery, UstRegelsetTemplateUstKategorieZuweisungListQueryVariables>(
    UstRegelsetTemplateUstKategorieZuweisungListDocument,
    options
  );
}
export function useUstRegelsetTemplateUstKategorieZuweisungListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UstRegelsetTemplateUstKategorieZuweisungListQuery, UstRegelsetTemplateUstKategorieZuweisungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstRegelsetTemplateUstKategorieZuweisungListQuery, UstRegelsetTemplateUstKategorieZuweisungListQueryVariables>(
    UstRegelsetTemplateUstKategorieZuweisungListDocument,
    options
  );
}
export type UstRegelsetTemplateUstKategorieZuweisungListQueryHookResult = ReturnType<typeof useUstRegelsetTemplateUstKategorieZuweisungListQuery>;
export type UstRegelsetTemplateUstKategorieZuweisungListLazyQueryHookResult = ReturnType<
  typeof useUstRegelsetTemplateUstKategorieZuweisungListLazyQuery
>;
export type UstRegelsetTemplateUstKategorieZuweisungListSuspenseQueryHookResult = ReturnType<
  typeof useUstRegelsetTemplateUstKategorieZuweisungListSuspenseQuery
>;
export type UstRegelsetTemplateUstKategorieZuweisungListQueryResult = Apollo.QueryResult<
  UstRegelsetTemplateUstKategorieZuweisungListQuery,
  UstRegelsetTemplateUstKategorieZuweisungListQueryVariables
>;
export const UstRegelsetTemplateUstKategorieZuweisungDocument = gql`
  query UstRegelsetTemplateUstKategorieZuweisung($ustRegelsetId: ID!, $ustRegelsetUstKategorieZuweisungId: ID!) {
    getUstRegelsetTemplateUstKategorieZuweisung(
      ustRegelsetId: $ustRegelsetId
      ustRegelsetUstKategorieZuweisungId: $ustRegelsetUstKategorieZuweisungId
    ) {
      data {
        ...UstRegelsetUstKategorieZuweisungFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstRegelsetUstKategorieZuweisungFieldsFragmentDoc}
`;
export function useUstRegelsetTemplateUstKategorieZuweisungQuery(
  baseOptions: Apollo.QueryHookOptions<UstRegelsetTemplateUstKategorieZuweisungQuery, UstRegelsetTemplateUstKategorieZuweisungQueryVariables> &
    ({ variables: UstRegelsetTemplateUstKategorieZuweisungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstRegelsetTemplateUstKategorieZuweisungQuery, UstRegelsetTemplateUstKategorieZuweisungQueryVariables>(
    UstRegelsetTemplateUstKategorieZuweisungDocument,
    options
  );
}
export function useUstRegelsetTemplateUstKategorieZuweisungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstRegelsetTemplateUstKategorieZuweisungQuery, UstRegelsetTemplateUstKategorieZuweisungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstRegelsetTemplateUstKategorieZuweisungQuery, UstRegelsetTemplateUstKategorieZuweisungQueryVariables>(
    UstRegelsetTemplateUstKategorieZuweisungDocument,
    options
  );
}
export function useUstRegelsetTemplateUstKategorieZuweisungSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UstRegelsetTemplateUstKategorieZuweisungQuery, UstRegelsetTemplateUstKategorieZuweisungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstRegelsetTemplateUstKategorieZuweisungQuery, UstRegelsetTemplateUstKategorieZuweisungQueryVariables>(
    UstRegelsetTemplateUstKategorieZuweisungDocument,
    options
  );
}
export type UstRegelsetTemplateUstKategorieZuweisungQueryHookResult = ReturnType<typeof useUstRegelsetTemplateUstKategorieZuweisungQuery>;
export type UstRegelsetTemplateUstKategorieZuweisungLazyQueryHookResult = ReturnType<typeof useUstRegelsetTemplateUstKategorieZuweisungLazyQuery>;
export type UstRegelsetTemplateUstKategorieZuweisungSuspenseQueryHookResult = ReturnType<
  typeof useUstRegelsetTemplateUstKategorieZuweisungSuspenseQuery
>;
export type UstRegelsetTemplateUstKategorieZuweisungQueryResult = Apollo.QueryResult<
  UstRegelsetTemplateUstKategorieZuweisungQuery,
  UstRegelsetTemplateUstKategorieZuweisungQueryVariables
>;
