// this is how should come from REST contextType: 'MIETER',

export const contactList = [
  {
    rechtstraegerId: '1000',
    vatIdentificationNumber: '',
    status: { value: 'AKTIV' },
    geburtsdatum: '17.07.1949',
    svaNr: '2456 17 07 1947',
    sterbedatum: '-',
    salutation: 'Herr',
    firstname: 'Dietmar',
    lastname: 'Steiner',
    title: 'Dr',
    titleTrailing: '',
    kurzBezeichnung: 'Steiner, Dietmar',
    type: 'NATUERLICHE_PERSON',
    // contextType: 'renter',
    contextTypes: ['owner', 'invoice-writer'],
    mainOperatingSite: {
      street: 'Elisabethgasse',
      houseEntranceApartmentNumber: '3',
      city: 'Weidling',
      zipCodeCity: '3400',
      bezirk: 'Klosterneuburg',
    },
    contactList: [
      { type: 'EMAIL', wert: 'contact@steiner.at' },
      { type: 'TELEFON', wert: '+43 644 233 21 80' },
    ],
    merkmale: ['Weihnachtskarte', 'WE-Newsletter'],
    bankDetails: {
      bankname: 'BAWAG PSK',
      iban: 'AT78 8000 4711 1442',
    },
    finanzamt: {
      city: 'Wien',
      bezirk: 'Klosterneuburg',
      Referat: '07',
      nr: '9/18/19',
      steuernummer: '1245-12',
      finanzamtzahlungen: '14257',
      bankDetails: 'AT41 5856 4778 XXXX',
      steuerbefreiung: 'keine',
    },
    stammdaten: {
      gewinnermittlung: 'E/A RechnungPage',
      ustSystem: 'SOLL',
      ustAnmeldung: 'vierteljährlich',
      uva: 'per Finanz Online',
    },
    rechnungseinstellungen: {
      rechnungsAusstellerBez: 'Steiner, Dietmar',
    },
    mahneinstellungen: {},
  },
  {
    rechtstraegerId: '2002',
    vatIdentificationNumber: 'ATU62756912',
    status: { value: 'AKTIV' },
    geburtsdatum: '',
    svaNr: '',
    sterbedatum: '',
    salutation: '',
    firstname: '',
    lastname: '',
    title: '',
    titleTrailing: '',
    kurzBezeichnung: 'WEG Barthgasse 3',
    type: 'PERSONEN_GEMEINSCHAFT',
    // contextType: 'renter',
    contextTypes: ['invoice-writer'],
    mainOperatingSite: {
      street: 'Barthgasse',
      houseEntranceApartmentNumber: '3',
      city: 'Wien',
      zipCodeCity: '1030',
      bezirk: 'Landstraße',
    },
    contactList: [],
    merkmale: [],
    bankDetails: {
      bank: 'BAWAG PSK',
      iban: 'AT78 8000 4711 1442',
    },
    finanzamt: {
      city: 'Wien',
      bezirk: 'Gerasdorf',
      Referat: '03',
      nr: '3/6/7/11/15',
      steuernummer: '105/4527',
      finanzamtzahlungen: '',
      bankDetails: '',
      steuerbefreiung: 'keine',
    },
    stammdaten: {
      gewinnermittlung: 'E/A RechnungPage',
      ustSystem: 'SOLL',
      ustAnmeldung: 'vierteljährlich',
      uva: 'per Finanz Online',
    },
    rechnungseinstellungen: {
      rechnungsAusstellerBez: 'WEG Barthgasse  3',
    },
    mahneinstellungen: {},
  },
];
