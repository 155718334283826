import React, { FC } from 'react';
import { Descriptions } from 'antd';
import { Box } from 'rebass';
import theme from '../../../../theme';
import { IncomingInvoiceSteuerzeile } from '../../../../types';
import { EuroAmount, Percent } from '../../../../components/Number';

type Props = {
  brutto: number;
  netto: number;
  steuerzeileList: IncomingInvoiceSteuerzeile[];
};

const InvoiceSumTotalContentBlock: FC<Props> = ({ brutto, netto, steuerzeileList }) => (
  <>
    <Descriptions labelStyle={{ color: theme.textColor.secondary }} bordered={false} colon={false} column={1} layout="horizontal" size="small">
      <Descriptions.Item label="Gesamt Netto">
        <Box mx="auto" />
        <EuroAmount value={netto} />
      </Descriptions.Item>

      {steuerzeileList.map((sz, index) => (
        <React.Fragment key={index}>
          <Descriptions.Item label="Steuersatz">
            <Box mx="auto" />
            <Percent value={sz.steuersatz / 100} />
          </Descriptions.Item>
          <Descriptions.Item label="Umsatzsteuer">
            <Box mx="auto" />
            <EuroAmount value={sz.betrag} />
          </Descriptions.Item>
        </React.Fragment>
      ))}
      <Descriptions.Item label="Gesamt Brutto">
        <Box mx="auto" />
        <EuroAmount value={brutto} />
      </Descriptions.Item>
    </Descriptions>
  </>
);

export default InvoiceSumTotalContentBlock;
