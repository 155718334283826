import styled from 'styled-components';
import React from 'react';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { TableWithColSelectorProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';

export const StyledBeTable = styled(TableWithColSelector)`
  .ant-table-expanded-row-level-1 {
    z-index: unset !important;
  }
` as <T>(props: TableWithColSelectorProps<T>) => React.ReactElement;
