import { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../components/Grid';
import { verarbeitungPageAndMenuListTitles } from '../verarbeitungHelpers';
import EntryListing from './EntryListing/EntryListing';
import { VorschreibungMailDeliverySendGenerierlauf } from '../../../types';

type Props = {
  generierlauf: VorschreibungMailDeliverySendGenerierlauf;
};

const VerarbeitungVorschreibungMailDeliverySend: FC<Props> = ({ generierlauf }) => {
  return (
    <>
      <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.VorschreibungMailDeliverySend}</Typography.Title>
      <Spacer />
      <EntryListing generierlauf={generierlauf} />
    </>
  );
};

export default VerarbeitungVorschreibungMailDeliverySend;
