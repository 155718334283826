import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KontoTemplateListForAbrKontenzuordnungQueryVariables = Types.Exact<{
  klasseList?: Types.InputMaybe<Array<Types.KontoKlasse> | Types.KontoKlasse>;
  verwendung?: Types.InputMaybe<Types.KontoVerwendung>;
}>;

export type KontoTemplateListForAbrKontenzuordnungQuery = {
  getKontoTemplateList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      haupt: boolean;
      kontoId: string;
      kontoTemplateId?: string | null;
      nummer: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      klasse: { text: string; value: Types.KontoKlasse };
      status: { description?: string | null; text: string; value: Types.KontoStatus };
      type: { text: string; value: Types.KontoType };
      verwendung?: { text: string; value: Types.KontoVerwendung } | null;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const KontoTemplateListForAbrKontenzuordnungDocument = gql`
  query KontoTemplateListForAbrKontenzuordnung($klasseList: [KontoKlasse!], $verwendung: KontoVerwendung) {
    getKontoTemplateList(klasseList: $klasseList, verwendung: $verwendung) {
      data {
        ...KontoFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontoFieldsFragmentDoc}
`;
export function useKontoTemplateListForAbrKontenzuordnungQuery(
  baseOptions?: Apollo.QueryHookOptions<KontoTemplateListForAbrKontenzuordnungQuery, KontoTemplateListForAbrKontenzuordnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoTemplateListForAbrKontenzuordnungQuery, KontoTemplateListForAbrKontenzuordnungQueryVariables>(
    KontoTemplateListForAbrKontenzuordnungDocument,
    options
  );
}
export function useKontoTemplateListForAbrKontenzuordnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KontoTemplateListForAbrKontenzuordnungQuery, KontoTemplateListForAbrKontenzuordnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoTemplateListForAbrKontenzuordnungQuery, KontoTemplateListForAbrKontenzuordnungQueryVariables>(
    KontoTemplateListForAbrKontenzuordnungDocument,
    options
  );
}
export function useKontoTemplateListForAbrKontenzuordnungSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<KontoTemplateListForAbrKontenzuordnungQuery, KontoTemplateListForAbrKontenzuordnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoTemplateListForAbrKontenzuordnungQuery, KontoTemplateListForAbrKontenzuordnungQueryVariables>(
    KontoTemplateListForAbrKontenzuordnungDocument,
    options
  );
}
export type KontoTemplateListForAbrKontenzuordnungQueryHookResult = ReturnType<typeof useKontoTemplateListForAbrKontenzuordnungQuery>;
export type KontoTemplateListForAbrKontenzuordnungLazyQueryHookResult = ReturnType<typeof useKontoTemplateListForAbrKontenzuordnungLazyQuery>;
export type KontoTemplateListForAbrKontenzuordnungSuspenseQueryHookResult = ReturnType<typeof useKontoTemplateListForAbrKontenzuordnungSuspenseQuery>;
export type KontoTemplateListForAbrKontenzuordnungQueryResult = Apollo.QueryResult<
  KontoTemplateListForAbrKontenzuordnungQuery,
  KontoTemplateListForAbrKontenzuordnungQueryVariables
>;
