import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import ListingFilters from './Filters/ListingFilters';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { ObjektFinancialPlanVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { verarbeitungTypes } from '../../verarbeitungHelpers';
import { ObjektFinancialPlanGenerierlauf } from '../../../../types';

import ObjektFinancialPlanVerarbeitungEntryTable from './Table/ObjektFinancialPlanVerarbeitungEntryTable';
import {
  mapFormValuesToObjektFinancialPlanVerarbeitungFilters,
  objektFinancialPlanVerarbeitungEntryFiltersFormInitialValues,
  ObjektFinancialPlanVerarbeitungEntryFiltersFormValues,
} from './Filters/ListingFiltersFormMapper';

type Props = {
  generierlauf: ObjektFinancialPlanGenerierlauf;
};

const ObjektFinancialPlanVerarbeitungEntryListing: FC<Props> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<ObjektFinancialPlanVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.ObjektFinancialPlan, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: ObjektFinancialPlanVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.ObjektFinancialPlan,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToObjektFinancialPlanVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<ObjektFinancialPlanVerarbeitungEntryFiltersFormValues>
      initialValues={objektFinancialPlanVerarbeitungEntryFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlauf={generierlauf} />
          <ObjektFinancialPlanVerarbeitungEntryTable generierlauf={generierlauf} formikProps={formikProps} />
        </>
      )}
    </Formik>
  );
};

export default ObjektFinancialPlanVerarbeitungEntryListing;
