import React, { FC } from 'react';
import { Divider, Drawer, Space, Typography } from 'antd';
import { useToggle } from '../../../hooks/useToggle';
import CardWithFooter from '../../../components/Card';
import Address from '../../../shared/components/Address/AddressText';
import InnerCardBestandseinheitInformation from './InnerCardBestandseinheitInformation';
import InnerCardBestandseinheitNutzung from './InnerCardBestandseinheitNutzung';
import ContextMenu from '../../../components/Timeline/ContextMenu';
import BestandseinheitUpdateForm from '../Form/Update/BestandseinheitUpdateForm';
import BestandseinheitCardActions from './BestandseinheitCardActions';
import CardTitle from '../../../components/Card/CardTitle';
import BePostIt from '../PostIt/BePostIt';
import InnerCardOptionSteuerpflicht from './InnerCardOptionSteuerpflicht';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import BestandseinheitChangeHistoryListingTable from './BestandseinheitChangeHistoryListingTable';
import { isStatusArchived } from '../../../helpers/statusHelper';
import { Bestandseinheit } from '../../../types';
import InnerCardTagList from '../../shared/InnerCardTagList';

type Props = {
  objektId: string;
  bestandseinheit: Bestandseinheit;
  isLoading: boolean;
  reloadBestandseinheit: () => void;
};

export const BestandseinheitCard: FC<Props> = ({ objektId, bestandseinheit, isLoading, reloadBestandseinheit }) => {
  const [isBestandseinheitUpdateOpen, toggleBestandseinheitUpdateOpen] = useToggle();
  const isBeArchived = isStatusArchived(bestandseinheit.status);

  const [isChangeHistoryOpen, toggleChangehistoryOpen] = useToggle();

  return (
    <>
      <CardWithFooter
        loading={isLoading}
        size="default"
        title={
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            {isBeArchived ? (
              <CardTitle title={bestandseinheit.bezeichnung} status={bestandseinheit.status} /> //no maxLength as bestandseinheit.bezeichnung can only have 30 characters
            ) : (
              <CardTitle title={bestandseinheit.bezeichnung} />
            )}
            <BePostIt
              objektId={objektId}
              bestandseinheitId={bestandseinheit.bestandseinheitId}
              postIt={bestandseinheit.postIt}
              refresh={reloadBestandseinheit}
            />
          </Space>
        }
        extra={
          <BestandseinheitCardActions
            objektId={objektId}
            bestandseinheit={bestandseinheit}
            onAction={reloadBestandseinheit}
            onChangeHistoryClicked={toggleChangehistoryOpen}
          />
        }
        createTs={bestandseinheit.createTs}
        userId={bestandseinheit.createdBy}
        mitarbeiterId={bestandseinheit.createdByMitarbeiterId}
      >
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Typography.Title level={5}>Adresse</Typography.Title>
          <ContextMenu openDrawerCreate={toggleBestandseinheitUpdateOpen} />
        </Space>
        <Address address={bestandseinheit.address} />
        <Divider dashed />

        <InnerCardBestandseinheitInformation bestandseinheit={bestandseinheit} />
        <Divider dashed />

        {bestandseinheit.tagList.length > 0 ? <InnerCardTagList tagList={bestandseinheit.tagList} /> : null}

        <InnerCardBestandseinheitNutzung objektId={objektId} bestandseinheit={bestandseinheit} reloadBestandseinheit={reloadBestandseinheit} />
        <Divider dashed />
        <InnerCardOptionSteuerpflicht
          steuerpflichtVersion={bestandseinheit.optionSteuerpflicht}
          objektId={objektId}
          bestandseinheitId={bestandseinheit.bestandseinheitId}
          reloadBestandseinheit={reloadBestandseinheit}
        />
      </CardWithFooter>
      <Drawer
        title="Bestandseinheit bearbeiten"
        width={720}
        onClose={toggleBestandseinheitUpdateOpen}
        open={isBestandseinheitUpdateOpen}
        destroyOnClose
      >
        <BestandseinheitUpdateForm
          objektId={objektId}
          bestandseinheit={bestandseinheit}
          onSubmit={() => {
            toggleBestandseinheitUpdateOpen();
            reloadBestandseinheit();
          }}
          onCancel={toggleBestandseinheitUpdateOpen}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangehistoryOpen}>
        <BestandseinheitChangeHistoryListingTable objektId={objektId} bestandseinheitId={bestandseinheit.bestandseinheitId} />
      </HistoryModal>
    </>
  );
};

export default BestandseinheitCard;
