import React, { FC } from 'react';
import { Typography } from 'antd';
import FormattedDecimalFormInput from '../../../components/Input-antd/FormattedDecimalFormInput';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { StyledSpaceFullWidthInFilter } from '../../../components/Space/styled/StyledSpaceFullWidthInFilter';

type Props = {
  /** form item name and label */
  name: string;
  fromName: string;
  toName: string;
  onChange?: () => void;
  fractionDigits?: number;
};

const FromToFormInputNumber: FC<Props> = ({ name, fromName, toName, onChange, fractionDigits }) => (
  <FormItemWithoutColon name={name} label={name}>
    <StyledSpaceFullWidthInFilter size="small">
      <FormattedDecimalFormInput
        name={fromName}
        style={{ width: '100%' }}
        onChange={onChange}
        min={0}
        placeholder={`${name} von`}
        fractionDigits={fractionDigits}
      />
      <Typography.Text>-</Typography.Text>
      <FormattedDecimalFormInput
        name={toName}
        style={{ width: '100%' }}
        onChange={onChange}
        min={0}
        placeholder={`${name} bis`}
        fractionDigits={fractionDigits}
      />
    </StyledSpaceFullWidthInFilter>
  </FormItemWithoutColon>
);

export default FromToFormInputNumber;
