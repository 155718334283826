import React, { FC, useContext } from 'react';
import { Button, Tooltip } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { IndexationAgreementContext } from '../../Level2/IndexationAgreementContext';
import { useApplyVertragVposIndexedValueMutation } from '../../../../../../../IndexedValue/gql/VertragVposIndexedValueMutations.types';
import { VertragVposIndexedValue } from '../../../../../../../../types';
import { showSuccessMsgOther } from '../../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../../helpers/apolloHelper';
import {
  isVertragVposIndexedValueStatusApplied,
  isVertragVposIndexedValueStatusNotApplied,
} from '../../../../../../../IndexedValue/shared/indexedValueHelpers';

type Props = {
  indexedValue: VertragVposIndexedValue;
  onSubmit: () => void;
};

const ActionApply: FC<Props> = ({ indexedValue, onSubmit }) => {
  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);

  const isDisabled =
    isVertragVposIndexedValueStatusNotApplied(indexedValue.status.value) || isVertragVposIndexedValueStatusApplied(indexedValue.status.value);

  const [runAnwenden] = useApplyVertragVposIndexedValueMutation({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      vorschreibungspositionId,
      indexationAgreementId: indexedValue.indexationAgreement.vertragVposIndexationAgreementId,
      indexedValueId: indexedValue.vertragVposIndexedValueId,
    },
    onCompleted: () => {
      showSuccessMsgOther(`Indexierter Wert ${indexedValue.name} angewendet`);
      onSubmit();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Tooltip title="Anwenden">
      <Button
        type="link"
        icon={<CheckCircleOutlined style={{ color: !isDisabled ? '#52c41a' : 'inherit' }} />}
        onClick={() => runAnwenden()}
        disabled={isDisabled}
      />
    </Tooltip>
  );
};

export default ActionApply;
