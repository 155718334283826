import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import {
  DocumentListingFiltersFormValues,
  mapFormValuesToQueryParams,
  mapQueryParamsToFormValues,
  TQueryParams,
  updateQueryParams,
} from './Filters/documentListingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import DocumentTable from './Table/DocumentTable';
import { DocumentTableProps } from './Table/documentTableType';

type Props = { hideFilters?: boolean; disabledFilters?: string[]; defaultFilters?: string[] } & DocumentTableProps;

const DocumentListing: FC<Props> = ({ hideFilters, disabledFilters, defaultFilters, ...restProps }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const queryParams = useQueryParams<TQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    const filters = mapQueryParamsToFormValues(queryParams);
    updateQueryParams(navigate, pathname, filters, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: DocumentListingFiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, pathname, filters, paginationParams);
  };

  return (
    <Formik<DocumentListingFiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={(values) => onChange(values)}>
      {(formikProps) => (
        <>
          {!hideFilters && (
            <ListingFilters
              formikProps={formikProps}
              queryParams={queryParams}
              disabledFilters={disabledFilters}
              defaultFilters={defaultFilters}
              objektId={restProps.objektId}
            />
          )}
          <DocumentTable queryParams={queryParams} {...restProps} />
        </>
      )}
    </Formik>
  );
};

export default DocumentListing;
