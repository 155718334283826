import React from 'react';
import { Badge, Button } from 'antd';
import { FormattedNumber } from 'react-intl';
import { isNotNil } from '../../../helpers/assertionHelper';
import DataWithStatus from '../../../components/Helpers/DataWithStatus';
import { compareTwoStringsForSorting } from '../../../helpers/stringHelper';
import { compareTwoNumbersForSorting } from '../../../helpers/numberHelper';
import { compareTwoBooleansForSorting } from '../../../helpers/booleanHelper';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { compareTwoDatesForSorting } from '../../../helpers/dateHelper';
import { MitarbeiterTooltip } from '../../../components/Card';
import { BeAufteilungsschluessel } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';

const bestandseinheitAufteilungsschluesselTableColumns = (
  toggleExpandedRowKey: (rowKey: string) => void,
  expandedRowKeys: string[]
): TableWithColSelectorColumnProps<BeAufteilungsschluessel>[] => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    render: (text, record) => (
      <DataWithStatus text={record.bezeichnung} maxTextLength={16} status={record.objektAufteilungsschluesselStatus} showBadgeDot={false} />
    ),
  },
  {
    title: 'Bezugsbasis',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.bezugsbasis.text, b.bezugsbasis.text),
    render: (text, record) => (
      <DataWithStatus text={record.bezugsbasis.text} status={record.objektAufteilungsschluesselStatus} showBadgeDot={false} />
    ),
  },
  {
    title: 'Wert',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.wert, b.wert),
    render: (text, record) =>
      isNotNil(record.wert) ? (
        <DataWithStatus text="Wert" status={record.objektAufteilungsschluesselStatus} showBadgeDot={false}>
          {/* FIXME - Try to omit the non-null-assertion for record.wert. The isNotNil check is ignored when using TextWithStatusBagde around a component */}
          {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
          {() => <FormattedNumber value={record.wert!} minimumFractionDigits={2} maximumFractionDigits={9} />}
        </DataWithStatus>
      ) : null,
  },
  {
    title: 'Basis',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.basis, b.basis),
    render: (text, record) => (
      <DataWithStatus text="Basis" status={record.objektAufteilungsschluesselStatus} showBadgeDot={false}>
        {() => <FormattedNumber value={record.basis} minimumFractionDigits={2} maximumFractionDigits={9} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Anteil',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.anteil, b.anteil),
    render: (text, record) => (
      <DataWithStatus text="Anteil" status={record.objektAufteilungsschluesselStatus} showBadgeDot={false}>
        {() => <FormattedNumber value={record.anteil} minimumFractionDigits={2} maximumFractionDigits={2} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => {
      const isAActive = isBestandseinheitAufteilungsschluesselStatusActive(a);
      const isBActive = isBestandseinheitAufteilungsschluesselStatusActive(b);
      return compareTwoBooleansForSorting(isAActive, isBActive);
    },
    render: (text, record) => {
      const isActive = isBestandseinheitAufteilungsschluesselStatusActive(record);
      return <Badge status={isActive ? 'success' : 'default'} text={isActive ? 'angewendet' : 'nicht angewendet'} />;
    },
  },
  {
    title: 'Erstellt am',
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    key: 'action',
    render: (text, record) => (
      <Button
        size="small"
        type="link"
        disabled={expandedRowKeys.includes(record.aufteilungsschluesselId)}
        onClick={() => toggleExpandedRowKey(record.aufteilungsschluesselId)}
      >
        Bearbeiten
      </Button>
    ),
  },
];

const isBestandseinheitAufteilungsschluesselStatusActive = (aufteilungsschluessel: BeAufteilungsschluessel) =>
  aufteilungsschluessel.wert && aufteilungsschluessel.wert !== 0;

export default bestandseinheitAufteilungsschluesselTableColumns;
