import React, { FC } from 'react';
import { useUstVomAufwandListQuery } from '../gql/UstVomAufwandQueries.types';
import UstVomAufwandErstellenBtnAndModal from './UstVomAufwandErstellenBtnAndModal';
import UstVomAufwandTable from './Table/UstVomAufwandTable';
import { useAuth } from '../../../shared/Auth/authContext';
import { useGetNotificationSubscription } from '../../Notification/gql/NotificationSubscription.types';
import { NotificationStatus, NotificationType } from '../../../types';
import { useOnUstVomAufwandDataChangedEvent } from '../useOnUstVomAufwandDataChangedEvent';

type Props = {
  objektId: string;
  objektVerwaltungSeit: string;
};

const UstVomAufwandListing: FC<Props> = ({ objektId, objektVerwaltungSeit }) => {
  const { user, mitarbeiter } = useAuth();
  const username = user?.username;
  const mitarbeiterId = mitarbeiter?.mitarbeiterId;

  const { data, loading, refetch } = useUstVomAufwandListQuery({
    variables: { objektId },
  });

  useGetNotificationSubscription({
    onData: (options) => {
      const notification = options.data.data?.getNotification;

      if (!notification) return;
      const { notificationType, status } = notification;
      if (notificationType !== NotificationType.UstVomAufwandErstellung && status !== NotificationStatus.Finished) return;
      refetch();
    },
    variables: {
      userId: username,
      mitarbeiterId,
    },
  });

  const ustVomAufwandList = data?.getUstVomAufwandList.data ?? [];

  useOnUstVomAufwandDataChangedEvent(
    'ustVomAufwandList',
    refetch,
    ustVomAufwandList.map((ustVomAufwand) => ustVomAufwand.ustVomAufwandId)
  );

  return (
    <>
      <UstVomAufwandErstellenBtnAndModal objektId={objektId} objektVerwaltungSeit={objektVerwaltungSeit} />
      <UstVomAufwandTable objektId={objektId} ustVomAufwandList={ustVomAufwandList} loading={loading} />
    </>
  );
};

export default UstVomAufwandListing;
