import React from 'react';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { Flex } from 'rebass';
import { EuroAmount } from '../../../../components/Number';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';
import { generatePathToWeAbrechnungDetailsTopabrechnungDetailsPage } from '../../../Abrechnung/BkOrWe/weAbrechnungUriPaths';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import GenerierlaufEntryStatus from '../../shared/GenerierlaufEntryStatus';
import { generatePathToRechtstraegerDetailsPage } from '../../../Rechtstraeger/rechtstraegerHelper';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { TopAbrechnungGenerierlaufEntry, TopAbrechnungGenerierlaufOrderBy } from '../../../../types';
import { generatePathToObjektDetailsPage, pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

const topAbrAuflistungTableColumns: TableWithColSelectorColumnProps<TopAbrechnungGenerierlaufEntry>[] = [
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: TopAbrechnungGenerierlaufOrderBy.Objekt,
    sorter: true,
    render: (text, record) => {
      if (record.topAbrechnung) {
        return (
          <Link to={generatePathToObjektDetailsPage(record.topAbrechnung.objektId)} target="_blank">
            {record.topAbrechnung.objektKurzBezeichnung}
          </Link>
        );
      }
      if (record.topAbrechnungOverview) {
        return (
          <Link to={generatePathToObjektDetailsPage(record.topAbrechnungOverview.objektId)} target="_blank">
            {record.topAbrechnungOverview.objektKurzBezeichnung}
          </Link>
        );
      }
      return <TextForEmptyValue textToShow="NV" />;
    },
  },
  {
    title: 'Bestandseinheit',
    defaultSelected: true,
    dataIndex: TopAbrechnungGenerierlaufOrderBy.Bestandseinheit,
    sorter: true,
    render: (text, record) => {
      if (record.topAbrechnung) {
        return (
          <Link to={generatePathToBestandseinheitDetailsPage(record.objektId, record.topAbrechnung.bestandseinheitId)} target="_blank">
            {record.topAbrechnung.bezeichnung}
          </Link>
        );
      }
      if (record.topAbrechnungOverview) {
        return (
          <Link to={pathsToObjektDetailsPage(record.objektId).topTab} target="_blank">
            Alle
          </Link>
        );
      }

      return <TextForEmptyValue textToShow="NV" />;
    },
  },
  {
    title: 'Rechnungsaussteller',
    defaultSelected: true,
    dataIndex: TopAbrechnungGenerierlaufOrderBy.Aussteller,
    sorter: true,
    render: (text, record) =>
      record.topAbrechnung ? (
        <LinkWithStatus
          status={record.topAbrechnung.rechnungsAussteller.status}
          text={record.topAbrechnung.rechnungsAussteller.kurzBezeichnung}
          maxTextLength={40}
          to={generatePathToRechtstraegerDetailsPage(record.topAbrechnung.rechnungsAussteller.rechtstraegerId)}
          openInNewTab
        />
      ) : (
        <TextForEmptyValue textToShow="NV" />
      ),
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    dataIndex: TopAbrechnungGenerierlaufOrderBy.Empfaenger,
    sorter: true,
    render: (text, record) =>
      record.topAbrechnung ? (
        <LinkWithStatus
          status={record.topAbrechnung.vertragspartner.status}
          text={record.topAbrechnung.vertragspartner.kurzBezeichnung}
          maxTextLength={40}
          to={generatePathToRechtstraegerDetailsPage(record.topAbrechnung.vertragspartner.rechtstraegerId)}
          openInNewTab
        />
      ) : (
        <TextForEmptyValue textToShow="NV" />
      ),
  },
  {
    title: 'Zeitraum',
    defaultSelected: true,
    dataIndex: TopAbrechnungGenerierlaufOrderBy.Zeitraum,
    sorter: true,
    render: (text, record) => {
      if (record.topAbrechnung) {
        return (
          <>
            <CustomFormattedDate value={record.topAbrechnung.fromInclusive} /> – <CustomFormattedDate value={record.topAbrechnung.toInclusive} />
          </>
        );
      }
      if (record.topAbrechnungOverview) {
        return (
          <>
            <CustomFormattedDate value={record.topAbrechnungOverview.fromInclusive} /> –{' '}
            <CustomFormattedDate value={record.topAbrechnungOverview.toInclusive} />
          </>
        );
      }
      return <TextForEmptyValue textToShow="NV" />;
    },
  },
  {
    title: 'Abrechnungsergebnis',
    defaultSelected: true,
    width: '100px',
    align: 'right',
    dataIndex: TopAbrechnungGenerierlaufOrderBy.Abrechnungsergebnis,
    sorter: true,
    render: (text, record) =>
      record.topAbrechnung ? (
        <Flex justifyContent="space-between">
          <Tag color={record.topAbrechnung.guthaben ? 'green' : 'red'}>{record.topAbrechnung.guthaben ? 'Guthaben' : 'Nachzahlung'}</Tag>
          <EuroAmount value={record.topAbrechnung.saldo} />
        </Flex>
      ) : (
        <TextForEmptyValue textToShow="NV" />
      ),
  },
  {
    title: 'Erstelldatum',
    render: (text, record) =>
      record.topAbrechnung ? <CustomFormattedDate value={record.topAbrechnung.createTs} /> : <TextForEmptyValue textToShow="NV" />,
  },
  {
    title: 'Ersteller',
    render: (text, record) =>
      record.topAbrechnung ? (
        <MitarbeiterTooltip
          mitarbeiterId={record.topAbrechnung.createdByMitarbeiterId}
          userId={record.topAbrechnung.createdBy}
          alignment="left"
          showsInitials
        />
      ) : (
        <TextForEmptyValue textToShow="NV" />
      ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: TopAbrechnungGenerierlaufOrderBy.Status,
    sorter: true,
    render: (text, record) => (record.topAbrechnung ? <Tag>{record.topAbrechnung.status.value}</Tag> : <TextForEmptyValue textToShow="NV" />),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) =>
      record.topAbrechnung ? (
        <Link
          to={generatePathToWeAbrechnungDetailsTopabrechnungDetailsPage(
            record.objektId,
            record.objektAbrechnungId,
            record.topAbrechnung.topAbrechnungId
          )}
          target="_blank"
        >
          Details
        </Link>
      ) : (
        <TextForEmptyValue textToShow="NV" />
      ),
  },
];

export default topAbrAuflistungTableColumns;
