import React, { FC } from 'react';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import { v4 as uuid4 } from 'uuid';
import { Empty, Table } from 'antd';
import { FormikFieldHookProps } from '../../../../helpers/formikHelper';
import { AbrechnungsdefinitionType } from '../../../../types';
import {
  AbrDefAbrKreisFormValues,
  AbrDefAbrKreisListingFormValues,
} from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import {
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
} from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import heAndSubAbrDefTemplateAbrKreisTableColumns from './heAndSubAbrDefTemplateAbrKreisTableColumns';
import weAndBkAbrDefTemplateAbrKreisTableColumns from './weAndBkAbrDefTemplateAbrKreisTableColumns';

type Props = {
  abrDefType: AbrechnungsdefinitionType;
  formikProps: FormikProps<AbrDefAbrKreisListingFormValues>;
  arrayHelpers: FieldArrayRenderProps;
  onAbrechnungskreisChange?: (newAbrechnungskreisId: string, fieldProps: FormikFieldHookProps<string>, onSuccess: () => void) => void;
  onZuweisungDelete?: (abrechnungskreisId: string, onDelete: () => void) => void;
};

const AbrDefTemplateAbrKreisTable: FC<Props> = ({ abrDefType, arrayHelpers, formikProps, onAbrechnungskreisChange, onZuweisungDelete }) => {
  const columns =
    isAbrDefTypeHeAbrechnung(abrDefType) || isAbrDefTypeSubAbrechnung(abrDefType)
      ? heAndSubAbrDefTemplateAbrKreisTableColumns(abrDefType, formikProps, arrayHelpers, onAbrechnungskreisChange, onZuweisungDelete)
      : weAndBkAbrDefTemplateAbrKreisTableColumns(abrDefType, formikProps, arrayHelpers, onAbrechnungskreisChange, onZuweisungDelete);

  return (
    <Table<AbrDefAbrKreisFormValues>
      locale={{
        emptyText: <Empty description={<span>Fügen Sie bitte mindestens eine Abrechnungskreiskonfiguration hinzu</span>} />,
      }}
      style={{ width: '100%' }}
      dataSource={formikProps.values.abrechdefAbrechkreisList}
      //name={abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}
      rowKey={() => uuid4()}
      size="small"
      pagination={false}
      columns={columns}
    />
  );
};

export default AbrDefTemplateAbrKreisTable;
