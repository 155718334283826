import React from 'react';
import { TableWithAlignedColsColumnType } from '../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import KontoWithQuery from '../../../../shared/components/Konto/KontoWithQuery';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { EuroAmount } from '../../../../components/Number';
import { UstVomAufwandRepFondsKonto } from '../../../../types';

const ustVomAufwandKontoRowColumns = (objektId: string): TableWithAlignedColsColumnType<UstVomAufwandRepFondsKonto>[] => {
  return [
    {
      title: '',
      align: 'left',
      render: (konto) => <KontoWithQuery kontoId={konto.repFondsKontoId} objektId={objektId} width="140px" maxTextLength={20} />,
    },
    {
      title: '',
      render: () => <TextForEmptyValue textToShow="none" />,
    },
    {
      title: '',
      render: () => <TextForEmptyValue textToShow="none" />,
    },
    {
      title: '',
      colSpan: 3,
      render: (konto) => <EuroAmount value={konto.sumNetto} />,
    },
    {
      title: '',
      render: () => <TextForEmptyValue textToShow="none" />,
    },

    {
      title: '',
      colSpan: 2,
      render: (konto) => <EuroAmount value={konto.sumUstVomAufwand} />,
    },
  ];
};

export default ustVomAufwandKontoRowColumns;
