import React, { FC } from 'react';
import { Button, Drawer, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../hooks/useToggle';
import ListingTitleWithButtons from '../../../../components/Listing/ListingTitleWithButtons';
import ZahlungsverlaufForm from './Form/ZahlungsverlaufForm';
import { HeVertrag } from '../../../../types';

type Props = {
  objektId: string;
  heVertragId: string;
  vertrag: HeVertrag;
  onSuccess: () => void;
  showProcessedPayments: boolean;
  toggleShowProcessedPayments: () => void;
};

const ZahlungsverlaufButtonsAndDrawer: FC<Props> = ({
  objektId,
  heVertragId,
  onSuccess,
  showProcessedPayments,
  toggleShowProcessedPayments,
  vertrag,
}) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();

  const onCreateSuccess = () => {
    onSuccess();
    toggleDrawerOpen();
  };

  return (
    <>
      <ListingTitleWithButtons
        title="Zahlungsverlauf"
        buttons={
          <Space>
            <Button size="small" onClick={toggleShowProcessedPayments}>
              Verarbeitete Zahlungen {showProcessedPayments ? 'ausblenden' : 'anzeigen'}
            </Button>
            <Button size="small" icon={<PlusOutlined />} type="primary" onClick={toggleDrawerOpen}>
              Einmalzahlung anlegen
            </Button>
          </Space>
        }
      />

      <Drawer title="Einmalzahlung anlegen" width={720} open={isDrawerOpen} onClose={toggleDrawerOpen} destroyOnClose>
        <ZahlungsverlaufForm
          objektId={objektId}
          heVertragId={heVertragId}
          onSuccess={onCreateSuccess}
          onCancel={toggleDrawerOpen}
          vertragspartnerId={vertrag.vertragspartner.rechtstraegerId}
        />
      </Drawer>
    </>
  );
};

export default ZahlungsverlaufButtonsAndDrawer;
