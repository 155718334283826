import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Col, Space, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { EuroAmount } from '../../../../../components/Number';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { BookingSuggestionFormValues } from '../../Form/buchungsmaskeFormMapper';
import { generatePathToKontoblattPage } from '../../../../Kontoblatt/kontoblattUriPaths';
import { useSaldoQuery } from '../../../../AbrKontenzuordnung/gql/KontoQueries.types';

type Props = {
  kontoId: string;
  objektId?: string;
};

const KontoSaldo: FC<Props> = ({ kontoId, objektId }) => {
  const { values: formikValues } = useFormikContext<BookingSuggestionFormValues>();
  const { buchungskreisRechtstraegerId: rechtstraegerId, buchungskreisId } = formikValues.buchungsanweisung;

  const { loading, data: maybeSaldo } = useSaldoQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { rechtstraegerId: rechtstraegerId!, kontoId },
    skip: !rechtstraegerId,
  });
  const saldo = maybeSaldo?.getSaldo.data;

  const contentIfBuchungskreisIsChosen =
    loading || !saldo ? (
      <Spin />
    ) : (
      <Link to={generatePathToKontoblattPage({ kontoId, objektId, buchungskreisId })} target="_blank">
        <EuroAmount value={saldo.saldo} />
      </Link>
    );

  return (
    <Col>
      <Space>
        <Typography.Text type="secondary">Saldo</Typography.Text>
        {!rechtstraegerId ? <TextForEmptyValue textToShow="minus" /> : contentIfBuchungskreisIsChosen}
      </Space>
    </Col>
  );
};

export default KontoSaldo;
