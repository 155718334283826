import React, { FC } from 'react';
import { Divider } from 'antd';
import EmailDeliveryDetailsCard from '../../Card/EmailDeliveryDetailsCard';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import RechtstraegerLink from './RechtstraegerLink';
import { findEmailDeliveryLabel } from '../../emailDeliveryHelpers';
import ObjektTopLink from './ObjektTopLink';
import EmailDeliveryActions from '../../Card/EmailDeliveryActions';
import { useResendVorschreibungMailDeliveryMutation } from '../gql/VorschreibungMailDeliveryMutations.types';
import ResentDeliveryInfoRow from './ResentDeliveryInfoRow';
import SourceDeliveryInfoRow from './SourceDeliveryInfoRow';
import { MailData } from '../../../../types';

type Props = {
  emailDelivery: MailData;
  refetch: () => void;
};

const VorschreibungMailDeliveryDetailsCard: FC<Props> = ({ emailDelivery, refetch }) => {
  const [runResend] = useResendVorschreibungMailDeliveryMutation();

  const { value: vorschreibungMailDeliveryId } = findEmailDeliveryLabel(emailDelivery, 'vorschreibungMailDeliveryId') || {};
  if (!vorschreibungMailDeliveryId) return null;

  const onSend = (emailAddress: string) => {
    return runResend({ variables: { vorschreibungMailDeliveryId, recipient: emailAddress } }).then(() => refetch());
  };

  return (
    <EmailDeliveryDetailsCard emailDelivery={emailDelivery} extra={<EmailDeliveryActions emailDelivery={emailDelivery} onSend={onSend} />}>
      <CardDetailsInfoRow infoRowTitle="an">
        <RechtstraegerLink vorschreibungMailDeliveryId={vorschreibungMailDeliveryId} />
      </CardDetailsInfoRow>
      <Divider />
      <CardDetailsInfoRow infoRowTitle="Objekt-Top">
        <ObjektTopLink vorschreibungMailDeliveryId={vorschreibungMailDeliveryId} />
      </CardDetailsInfoRow>
      <ResentDeliveryInfoRow vorschreibungMailDeliveryId={vorschreibungMailDeliveryId} />
      <SourceDeliveryInfoRow vorschreibungMailDeliveryId={vorschreibungMailDeliveryId} />
    </EmailDeliveryDetailsCard>
  );
};

export default VorschreibungMailDeliveryDetailsCard;
