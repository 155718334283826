import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SteuerpflichtListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SteuerpflichtListQuery = {
  getSteuerpflichtList: {
    data: Array<{ text: string; value: Types.Steuerpflicht }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const SteuerpflichtListDocument = gql`
  query SteuerpflichtList {
    getSteuerpflichtList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSteuerpflichtListQuery(baseOptions?: Apollo.QueryHookOptions<SteuerpflichtListQuery, SteuerpflichtListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SteuerpflichtListQuery, SteuerpflichtListQueryVariables>(SteuerpflichtListDocument, options);
}
export function useSteuerpflichtListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SteuerpflichtListQuery, SteuerpflichtListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SteuerpflichtListQuery, SteuerpflichtListQueryVariables>(SteuerpflichtListDocument, options);
}
export function useSteuerpflichtListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SteuerpflichtListQuery, SteuerpflichtListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SteuerpflichtListQuery, SteuerpflichtListQueryVariables>(SteuerpflichtListDocument, options);
}
export type SteuerpflichtListQueryHookResult = ReturnType<typeof useSteuerpflichtListQuery>;
export type SteuerpflichtListLazyQueryHookResult = ReturnType<typeof useSteuerpflichtListLazyQuery>;
export type SteuerpflichtListSuspenseQueryHookResult = ReturnType<typeof useSteuerpflichtListSuspenseQuery>;
export type SteuerpflichtListQueryResult = Apollo.QueryResult<SteuerpflichtListQuery, SteuerpflichtListQueryVariables>;
