import React, { FC } from 'react';
import { Descriptions, Skeleton, Space, Tag } from 'antd';
import { Box } from 'rebass';
import theme from '../../../theme';
import { IncomingInvoiceBuchungPosition } from '../../../types';
import { useKontoQuery } from '../../Kontenplan/gql/KontenplanQueries.types';
import { EuroAmount, Percent } from '../../../components/Number';

type Props = {
  position: IncomingInvoiceBuchungPosition;
};

const IncomingInvoiceDetailsBuchungPosition: FC<Props> = ({ position }) => {
  const { data, loading } = useKontoQuery({ variables: { kontoId: position.kontoId } });
  const konto = data?.getKonto.data;

  return (
    <Descriptions labelStyle={{ color: theme.textColor.secondary }} bordered={false} colon={false} column={1} layout="horizontal" size="small">
      <Descriptions.Item label="Bestandseinheit">
        <Box mx="auto" />
        {position.bestandseinheitBezeichnung}
      </Descriptions.Item>
      <Descriptions.Item label="Konto">
        <Box mx="auto" />
        <Skeleton active paragraph loading={loading}>
          {konto ? (
            <>
              <Space>
                {konto.bezeichnung} <Tag style={{ marginRight: 0 }}>{konto.nummer}</Tag>
              </Space>
            </>
          ) : (
            ''
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label="Buchungstext">
        <Box mx="auto" />
        {position.buchungstext}
      </Descriptions.Item>
      <Descriptions.Item label="Betrag">
        <Box mx="auto" />
        <EuroAmount value={position.betrag} />
      </Descriptions.Item>
      <Descriptions.Item label="Steuersatz">
        <Box mx="auto" />
        <Percent value={position.steuersatz / 100} />
      </Descriptions.Item>
    </Descriptions>
  );
};

export default IncomingInvoiceDetailsBuchungPosition;
