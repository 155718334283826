import React from 'react';
import { useShowArchivedQueryParamState } from '../../../hooks/useShowArchivedQueryParamState';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import VorschreibungspositionButtonsAndDrawer from './VorschreibungspositionButtonsAndDrawer';
import VorschreibungspositionTable from './Table/VorschreibungspositionTable';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { useKsVorschreibungspositionListQuery } from '../gql/VorschreibungspositionQueries.types';

const VorschreibungspositionListing = () => {
  const { currentPage, pageSize } = usePaginationQueryParams();
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const { data, refetch, pagination } = useQueryWithPagingAndSorting(
    useKsVorschreibungspositionListQuery,
    {
      variables: {
        kundenSystemId,
        withDetails: true,
        includeArchiviert: showArchived,
      },
    },
    {
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );
  const vorschreibungspositionList = data?.getVorschreibungspositionListByKundenSystemId.data?.contentList;

  return (
    <>
      <VorschreibungspositionButtonsAndDrawer refetch={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
      <VorschreibungspositionTable pagination={pagination} refetch={refetch} vorschreibungspositionList={vorschreibungspositionList} />
    </>
  );
};

export default VorschreibungspositionListing;
