import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { List, Modal, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useFileInfoForFirmendatenQuery } from '../../../Document/gql/FileStorageQueries.types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { LoadingCol } from '../../../../components/Loading';
import { uriFileOfFirmendaten } from '../../../Document/documentApiPaths';

type InfoFeldDocumentItemProps = {
  deleteDocumentFileIdList: string[];
  onDocumentDelete: (value: string[]) => void;
  fileId: string;
  firmendatenId: string;
};

const InfoFeldDocumentItem: FC<InfoFeldDocumentItemProps> = ({ deleteDocumentFileIdList, onDocumentDelete, fileId, firmendatenId }) => {
  const { data, loading } = useFileInfoForFirmendatenQuery({ variables: { firmendatenId, fileId } });

  const file = data?.getFileInfoForFirmendaten.data;

  const onDelete = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    onDocumentDelete([...deleteDocumentFileIdList, file!.fileId]);
  };

  return file ? (
    <List.Item>
      <Space style={{ width: '40%', justifyContent: 'space-between' }}>
        <DataWithShortenedText text={file.name} maxTextLength={35}>
          {(shortenedText) => (
            <Link to={uriFileOfFirmendaten(firmendatenId, file.fileId, file.name)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
        <DeleteOutlined onClick={showConfirmDelete(onDelete)} />
      </Space>
    </List.Item>
  ) : (
    <LoadingCol isLoading={loading} />
  );
};

const showConfirmDelete = (runDelete: () => void) => () => {
  Modal.confirm({
    title: 'Möchten Sie das Dokument löschen?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default InfoFeldDocumentItem;
