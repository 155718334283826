import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import DeviationTab from '../../../components/Deviation/Tab/DeviationTab';
import { useVorschreibungDeviationListQuery } from './gql/VorschreibungDeviationQueries.types';
import { AuftragStatusTuple } from '../../../types';
import { useVorschreibungApplyAllDeviationsMutation } from './gql/VorschreibungDeviationMutations.types';
import { showSuccessMsgOther } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../shared/Auth/authContext';
import { DEVIATIONS_TAB_TITLE } from '../../../components/Deviation/deviationHelpers';

type Props = {
  auftragId: string;
  status: AuftragStatusTuple;
  onAction: () => Promise<any>;
};

const VorschreibungDeviationList: FC<Props> = ({ auftragId, status, onAction }) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();
  const { data, refetch } = useVorschreibungDeviationListQuery({
    variables: { auftragId },
  });

  const onRefetch = () => onAction().then(() => refetch());

  useOnDataChangedEvent({
    filter: {
      type: dataChangedSubscriptionTypes.auftrag,
      userId: user.username,
      mitarbeiterId: mitarbeiter?.mitarbeiterId,
      params: { auftragId },
    },
    notificationDescription: 'Vorschreibungen wurden gerade verändert',
    key: 'vorschreibungDeviation',
    callback: onRefetch,
  });

  const [runApplyAllDeviations] = useVorschreibungApplyAllDeviationsMutation({
    variables: {
      auftragId,
    },
    onCompleted: () => {
      showSuccessMsgOther('Alle Abweichungen sind korrigiert.');
      refetch();
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const vorschreibungDeviationList = data?.getAuftragDeviationList.data;
  const loading = !vorschreibungDeviationList;

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: DEVIATIONS_TAB_TITLE,
      children: (
        <DeviationTab
          dataSource={vorschreibungDeviationList}
          loading={loading}
          status={status}
          runApplyAllDeviations={runApplyAllDeviations}
          showSourceEntity
        />
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default VorschreibungDeviationList;
