import React, { FC } from 'react';
import Erstelldatum from './Erstelldatum';
import FiltersWithMax3Fields from '../../../../components/Filters/FiltersWithMax3Fields';
import TypSelect from './TypSelect';
import { notificationListingFiltersFormFields } from './notificationListingFiltersFormMapper';

const NotificationListingFilters: FC = () => (
  <FiltersWithMax3Fields
    filters={
      <>
        <TypSelect name={notificationListingFiltersFormFields.type} style={{ width: '250px' }} />
        <Erstelldatum />
      </>
    }
  />
);

export default NotificationListingFilters;
