import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { TPaymentClearingQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { FiltersFormValues, mapFormValuesToQueryParams, mapQueryParamsToFormValues } from './Filters/filtersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { usePaymentClearingListQuery } from '../gql/PaymentClearingQueries.types';
import PaymentClearingTable from './Table/Level1/PaymentClearingTable';
import PaymentClearingListSummary from './PaymentClearingListSummary';

const PaymentClearingListing = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TPaymentClearingQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    usePaymentClearingListQuery,
    {
      variables: {
        ...queryParams,
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const paymentClearingList = data?.getPaymentClearingList.data.contentList ?? [];

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  return (
    <>
      <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => <ListingFilters formikProps={formikProps} queryParams={queryParams} />}
      </Formik>
      <PaymentClearingListSummary queryParams={queryParams} />
      <PaymentClearingTable
        handleTableSorting={handleTableSorting}
        loading={loading}
        pagination={pagination}
        paymentClearingList={paymentClearingList}
      />
    </>
  );
};

export default PaymentClearingListing;
