import React from 'react';
import { Input } from 'formik-antd';
// @ts-ignore
import { validateNewUsername } from './usernameValidation';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';

type NewUsernameFormPartProps = {
  usernameInputName: 'username' | string;
  usernameInputPlaceholder: 'Username' | string;
  usernameInputLabel: 'Username' | string;
};

const NewUsernameFormPart = (props: NewUsernameFormPartProps) => {
  const { usernameInputName, usernameInputPlaceholder, usernameInputLabel, ...restProps } = props;
  return (
    <FormItemWithoutColon name={usernameInputName} label={usernameInputLabel} validate={validateNewUsername} {...restProps}>
      <Input id={usernameInputName} name={usernameInputName} placeholder={usernameInputPlaceholder} />
    </FormItemWithoutColon>
  );
};

export default NewUsernameFormPart;
