import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { Tooltip } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { createCells, createRowWithCells, TextWithStyling } from '../../../Abrechnung/BkOrWe/shared/abrechnungTableHelper';
import { TableWithAlignedColsExpandedRowType } from '../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { StyledUstVomAufwandKontoDetailsTable } from './styled/UstVomAufwandKontoDetailsTable.styled';
import { EuroAmount, Percent } from '../../../../components/Number';
import { pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import { UstVomAufwandAufwandKonto } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { generatePathToStandingOrSingleInvoiceDetailsPage } from '../../../IncomingInvoice/incomingInvoiceUriPaths';
import { generatePathToBookingDetailsPage } from '../../../BookingDetails/bookingDetailsPaths';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const headerTexts: TextWithStyling[] = [
  { render: 'Buchungsdatum' },
  { render: 'ER BelegNr.' },
  { render: 'Lieferant (ER)' },
  { render: 'Aufwand Rep. Fonds' },
  { render: 'Gesamt Anteil Auft.Schlüssel' },
  { render: 'Einheitskosten' },
  { render: 'Top Anteil Auft.Schlüssel' },
  { render: 'Ust. vom Aufw. Prozent' },
  { render: 'Ust. vom Aufwand' },
];

const kontoAufteilungRow = (
  objektId: string,
  rechtstraegerId: string,
  bestandseinheitId: string
): TableWithAlignedColsExpandedRowType<UstVomAufwandAufwandKonto>[] => [
  {
    header: () => createCells(headerTexts, true),
    table: {
      colSpanSize: [12],
      StyledComponent: StyledUstVomAufwandKontoDetailsTable,
      positionOfButton: 3,
      showCellButton: false,
    },
    body: (konto) => (
      <>
        {konto.aufteilungList.map((aufteilung) => {
          const {
            buchungsdatum,
            lieferant,
            netto,
            anteilTopAufteilungsschluessel,
            gesamtAnteilAufteilungsschluessel,
            einheitskosten,
            ustVomAufwandAnteil,
            ustVomAufwandSteuersatz,
          } = aufteilung;

          const texts: TextWithStyling[] = [
            { render: <CustomFormattedDate value={buchungsdatum} /> },
            {
              render: (
                <Link
                  to={generatePathToStandingOrSingleInvoiceDetailsPage(
                    aufteilung.belegFileId,
                    aufteilung.belegId,
                    aufteilung.standingInvoicePartialAmountId
                  )}
                >
                  {aufteilung.belegnummer} <FilePdfOutlined />
                </Link>
              ),
            },
            {
              render: (
                <RechtstraegerWithContactsTooltip
                  rechtstraegerId={lieferant.rechtstraegerId}
                  rechtstraegerName={lieferant.kurzBezeichnung}
                  maxTextLength={20}
                />
              ),
            },
            {
              render: (
                <Link to={generatePathToBookingDetailsPage([aufteilung.buchungId])}>
                  <EuroAmount value={netto} />
                </Link>
              ),
            },
            {
              render: (
                <Link to={pathsToObjektDetailsPage(objektId).vorgabenTab}>
                  <FormattedNumber value={gesamtAnteilAufteilungsschluessel} /> {aufteilung.aufteilungsschluessel.kurzBezeichnung}
                </Link>
              ),
            },
            {
              render: (
                <Tooltip title={<EuroAmount value={einheitskosten} minimumFractionDigits={4} maximumFractionDigits={4} />}>
                  <>
                    <EuroAmount value={einheitskosten} minimumFractionDigits={2} maximumFractionDigits={2} />
                  </>
                </Tooltip>
              ),
            },
            {
              render: (
                <Link to={generatePathToBestandseinheitDetailsPage(objektId, bestandseinheitId)}>
                  <FormattedNumber value={anteilTopAufteilungsschluessel} /> {aufteilung.aufteilungsschluessel.kurzBezeichnung}
                </Link>
              ),
            },
            {
              render: <Percent value={ustVomAufwandSteuersatz / 100} />,
            },
            {
              render: (
                <Tooltip title={<EuroAmount value={ustVomAufwandAnteil} minimumFractionDigits={4} maximumFractionDigits={4} />}>
                  <>
                    <EuroAmount value={ustVomAufwandAnteil} minimumFractionDigits={2} maximumFractionDigits={2} />
                  </>
                </Tooltip>
              ),
            },
          ];

          return createRowWithCells(texts, false);
        })}
      </>
    ),
  },
];

export default kontoAufteilungRow;
