import React, { FC } from 'react';
import { Empty } from 'antd';
import { EbicsUser, EbicsUserIban } from '../../../../../../types';
import { useEbicsUserIbanListQuery } from '../../../gql/EbicsUserIbanQueries.types';
import NestedTable from '../../../../../../components/Table/NestedTable/NestedTable';
import ebicsUserIbanTableColumns from './ebicsUserIbanTableColumns';

type Props = {
  ebicsUser: EbicsUser;
};

const EbicsUserIbanTable: FC<Props> = ({ ebicsUser }) => {
  const { data, loading, refetch } = useEbicsUserIbanListQuery({
    variables: {
      ebicsUserId: ebicsUser.ebicsUserId,
    },
  });

  const ebicsUserIbanList = data?.getEbicsUserIbanList.data ?? [];
  return (
    <NestedTable<EbicsUserIban>
      level={2}
      colWidth={100}
      locale={{
        emptyText: <Empty description={<span>Keine Ebics User IBAN vorhanden</span>} />,
      }}
      loading={loading}
      columns={ebicsUserIbanTableColumns(ebicsUser.ebicsUserId, refetch)}
      dataSource={ebicsUserIbanList}
      rowKey={(record) => record.ebicsUserIbanId}
    />
  );
};

export default EbicsUserIbanTable;
