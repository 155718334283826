import * as Yup from 'yup';
import { validateRequiredStringWithMaxLength } from '../../../../helpers/validationHelper';

/* eslint-disable no-template-curly-in-string */
export const validateGeneralBezeichnung = (maxCharacters: number, isBezeichnung?: boolean) => {
  if (isBezeichnung === true) {
    return validateRequiredStringWithMaxLength('Bezeichnung', maxCharacters);
  } else {
    return validateRequiredStringWithMaxLength('Kurzbezeichnung', maxCharacters);
  }
};

export const kurzBezeichnungFormPartValidationSchema = Yup.object().shape({
  kurzBezeichnung: validateGeneralBezeichnung(25),
});

/* eslint-enable no-template-curly-in-string */
