import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungskreisTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AbrechnungskreisTypeListQuery = {
  getAbrechnungskreisTypeList: {
    data: Array<{ text: string; value: Types.AbrechnungskreisType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AbrechnungskreisTypeListDocument = gql`
  query AbrechnungskreisTypeList {
    getAbrechnungskreisTypeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAbrechnungskreisTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<AbrechnungskreisTypeListQuery, AbrechnungskreisTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungskreisTypeListQuery, AbrechnungskreisTypeListQueryVariables>(AbrechnungskreisTypeListDocument, options);
}
export function useAbrechnungskreisTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungskreisTypeListQuery, AbrechnungskreisTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungskreisTypeListQuery, AbrechnungskreisTypeListQueryVariables>(AbrechnungskreisTypeListDocument, options);
}
export function useAbrechnungskreisTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AbrechnungskreisTypeListQuery, AbrechnungskreisTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungskreisTypeListQuery, AbrechnungskreisTypeListQueryVariables>(AbrechnungskreisTypeListDocument, options);
}
export type AbrechnungskreisTypeListQueryHookResult = ReturnType<typeof useAbrechnungskreisTypeListQuery>;
export type AbrechnungskreisTypeListLazyQueryHookResult = ReturnType<typeof useAbrechnungskreisTypeListLazyQuery>;
export type AbrechnungskreisTypeListSuspenseQueryHookResult = ReturnType<typeof useAbrechnungskreisTypeListSuspenseQuery>;
export type AbrechnungskreisTypeListQueryResult = Apollo.QueryResult<AbrechnungskreisTypeListQuery, AbrechnungskreisTypeListQueryVariables>;
