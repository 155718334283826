import React, { FC } from 'react';
import { Space } from 'antd';
import { FormikProps } from 'formik';
import { Spacer } from '../../../../../components/Grid';
import DefinitionUpdateFormButtons from '../../../../shared/DefinitionUpdateFormButtons';
import { AuftragsartFieldsFragment } from '../../../../Auftragsart/gql/AuftragsartFragments.types';
import {
  auftragsartUpdateParamFormFields,
  AuftragsartUpdateParamFormValues,
} from '../../../../Auftragsart/Form/Update/ParameterForm/auftragsartUpdateParamFormMapper';
import { isNotStornoAuftragsart } from '../../../../Auftragsart/Form/shared/auftragsartFormHelpers';
import ParamFormItemStornoAuftragsartSelect from '../../shared/ParamFormItemStornoAuftragsartSelect';
import ParamFormItemBezeichnung from '../../../../Auftragsart/Form/shared/ParamForm/ParamFormItems/ParamFormItemBezeichnung';
import ParamFormItemUstBlockSwitch from '../../../../Auftragsart/Form/shared/ParamForm/ParamFormItems/ParamFormItemUstBlockSwitch';

type Props = {
  auftragsart: AuftragsartFieldsFragment;
  formikProps: FormikProps<AuftragsartUpdateParamFormValues>;
  onSubmit: () => void;
  closePath: string;
};

const AuftragsartTemplateUpdateParamForm: FC<Props> = ({ auftragsart, formikProps, onSubmit, closePath }) => (
  <>
    <Spacer height={16} />
    <Space direction="vertical" size="small" style={{ width: '50%', marginTop: '16px' }}>
      <ParamFormItemBezeichnung name={auftragsartUpdateParamFormFields.bezeichnung} placeholder="z.B. Standard Vorschreibung Dauerrechnung" />
      <ParamFormItemUstBlockSwitch
        name={auftragsartUpdateParamFormFields.showUstBlock}
        defaultChecked={formikProps.values.showUstBlock}
        onChange={(checked) => formikProps.setFieldValue(auftragsartUpdateParamFormFields.showUstBlock, checked)}
        shouldShowUstBlock={formikProps.values.showUstBlock}
      />
      {isNotStornoAuftragsart(auftragsart.type.value) && (
        <ParamFormItemStornoAuftragsartSelect
          name={auftragsartUpdateParamFormFields.stornoAuftragsartId}
          auftragsartType={auftragsart.type.value}
          isUpdate
        />
      )}
    </Space>
    <DefinitionUpdateFormButtons onSubmit={onSubmit} pushPath={closePath} />
  </>
);

export default AuftragsartTemplateUpdateParamForm;
