import { v4 as uuid4 } from 'uuid';
import { FormFields, TFormattedDecimal } from '../../../../../helpers/formikHelper';
import {
  Auszifferung,
  AuszifferungCreateInput,
  AuszifferungListCreateInput,
  AuszifferungSourceTuple,
  AuszifferungWithOffenePosten,
  FibuBelegSymbolTuple,
  OffenePosten,
} from '../../../../../types';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { mapFormattedDecimalOrThrowIfEmpty } from '../../../../../components/Input-antd/formattedDecimalHelper';
import { isExistingAuszifferung } from './auszifferungFormHelpers';
import { calculateInitialAvailableAmount } from './calculateAvailableAmount';

// form values
export type AuszifferungBlockFormValues = {
  availableAmount: number; // = Verfügbarer Betrag
  offenePostenFilter?: BSOffenePostenFilterFormValues;
  offenePostenListFromBooking: OffenePostenForBooking[];
  offenePostenForPersonenkontoList: OffenePostenForBooking[];
};

export type BSOffenePostenFilterFormValues = {
  belegnummer?: string | null;
  totalAmountFrom?: number | null;
  totalAmountTo?: number | null;
};

export type OffenePostenForBooking = {
  // # fields for Offene Posten table for OPs from Booking
  auszifferungList: AuszifferungFormValues[]; // values from booking and from added Auszifferung in Offene Posten table for Personenkonto
  belegDatum: string;
  belegFileId?: string | null;
  belegSymbol: FibuBelegSymbolTuple;
  belegnummer: string;
  buchungsdatum: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  dataCarrierBelegId?: string | null;
  dueDate: string;
  gesamtBetrag: number;
  iban?: string | null;
  offenePostenId: string;
  offenerBetrag: number;
  statementNumber?: string | null;
};

export type AuszifferungFormValues = {
  // AuszifferungCreateInput
  amount: TFormattedDecimal;
  offenePostenId: string;
  // other fields needed for AuszifferungTable
  auszifferungId: string;
  auszifferungsDatum?: string | null;
  buchungId?: string | null;
  buchungsdatum?: string | null; // nullable for newly added Auszifferung, non-nullable for booked Auszifferung
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  source?: AuszifferungSourceTuple | null;
  storniert: boolean;
  storniertByMitarbeiterId?: string | null;
  storniertTs?: string | null;
  zahlungId?: string | null;
};

// form fields
export const auszifferungFormFields: FormFields<AuszifferungBlockFormValues> = {
  availableAmount: 'availableAmount',
  offenePostenFilter: 'offenePostenFilter',
  offenePostenListFromBooking: 'offenePostenListFromBooking',
  offenePostenForPersonenkontoList: 'offenePostenForPersonenkontoList',
};

export const offenePostenListFromBookingFormFields: FormFields<OffenePostenForBooking> = {
  auszifferungList: 'auszifferungList',
  belegDatum: 'belegDatum',
  belegFileId: 'belegFileId',
  belegSymbol: 'belegSymbol',
  belegnummer: 'belegnummer',
  buchungsdatum: 'buchungsdatum',
  createTs: 'createTs',
  createdBy: 'createdBy',
  createdByMitarbeiterId: 'createdByMitarbeiterId',
  dataCarrierBelegId: 'dataCarrierBelegId',
  dueDate: 'dueDate',
  gesamtBetrag: 'gesamtBetrag',
  iban: 'iban',
  offenePostenId: 'offenePostenId',
  offenerBetrag: 'offenerBetrag',
  statementNumber: 'statementNumber',
};

export const offenePostenListForPersonenkontoFormFields: FormFields<OffenePostenForBooking> = {
  auszifferungList: 'auszifferungList',
  belegDatum: 'belegDatum',
  belegFileId: 'belegFileId',
  belegSymbol: 'belegSymbol',
  belegnummer: 'belegnummer',
  buchungsdatum: 'buchungsdatum',
  createTs: 'createTs',
  createdBy: 'createdBy',
  createdByMitarbeiterId: 'createdByMitarbeiterId',
  dataCarrierBelegId: 'dataCarrierBelegId',
  dueDate: 'dueDate',
  gesamtBetrag: 'gesamtBetrag',
  iban: 'iban',
  offenePostenId: 'offenePostenId',
  offenerBetrag: 'offenerBetrag',
  statementNumber: 'statementNumber',
};

export const offenePostenFilterFormFields: FormFields<BSOffenePostenFilterFormValues> = {
  belegnummer: 'belegnummer',
  totalAmountFrom: 'totalAmountFrom',
  totalAmountTo: 'totalAmountTo',
};

// initial values
const mapAzListToOPList = (auszifferungList: AuszifferungWithOffenePosten[]): OffenePostenForBooking[] => {
  const opList: OffenePostenForBooking[] = [];

  auszifferungList.map((auszifferung) => {
    const existstOPinOPList = opList.find((op) => op.offenePostenId === auszifferung.offenePostenId);

    if (!existstOPinOPList) {
      opList.push(mapAuszifferungToOffenePostenFormValues(auszifferung));
    }
  });

  return opList;
};

export const mapAuszifferungListToInitialFormValues = (
  amountFromPersonenkonto: number,
  auszifferungList: AuszifferungWithOffenePosten[],
  bookingZahlungId: string
): AuszifferungBlockFormValues => ({
  availableAmount: calculateInitialAvailableAmount(amountFromPersonenkonto, auszifferungList, bookingZahlungId),
  offenePostenListFromBooking: mapAzListToOPList(auszifferungList),
  offenePostenForPersonenkontoList: [],
});

// map values
export const mapAuszifferungToOffenePostenFormValues = (auszifferung: AuszifferungWithOffenePosten): OffenePostenForBooking => ({
  auszifferungList: mapAuszifferungToAuszifferungFormValues(auszifferung.offenePosten.auszifferungList),
  belegDatum: auszifferung.offenePosten.belegDatum,
  belegFileId: auszifferung.offenePosten.belegFileId ?? '',
  belegSymbol: auszifferung.offenePosten.belegSymbol,
  belegnummer: auszifferung.offenePosten.belegnummer,
  buchungsdatum: auszifferung.offenePosten.buchungsdatum,
  createTs: auszifferung.createTs,
  createdBy: auszifferung.createdBy,
  createdByMitarbeiterId: auszifferung.createdByMitarbeiterId,
  dataCarrierBelegId: auszifferung.offenePosten.dataCarrierBelegId ?? '',
  dueDate: auszifferung.offenePosten.dueDate,
  gesamtBetrag: auszifferung.offenePosten.gesamtBetrag,
  iban: auszifferung.offenePosten.iban ?? '',
  offenePostenId: auszifferung.offenePostenId,
  offenerBetrag: auszifferung.offenePosten.offenerBetrag,
  statementNumber: auszifferung.offenePosten.statementNumber ?? '',
});

export const mapAuszifferungToAuszifferungFormValues = (auszifferungList: Auszifferung[]): AuszifferungFormValues[] => {
  return auszifferungList.map((auszifferung) => ({
    amount: auszifferung.betrag,
    auszifferungsDatum: auszifferung.auszifferungsDatum,
    auszifferungId: auszifferung.auszifferungId,
    buchungId: auszifferung.buchungId,
    buchungsdatum: auszifferung.buchungsdatum,
    createTs: auszifferung.createTs,
    createdBy: auszifferung.createdBy,
    createdByMitarbeiterId: auszifferung.createdByMitarbeiterId,
    offenePostenId: auszifferung.offenePostenId,
    source: auszifferung.source,
    storniert: auszifferung.storniert,
    storniertByMitarbeiterId: auszifferung.storniertByMitarbeiterId,
    storniertTs: auszifferung.storniertTs,
    zahlungId: auszifferung.zahlungId,
  }));
};

export const mapOffenePostenToAuszifferungFormValues = (offenePosten: OffenePostenForBooking): AuszifferungFormValues[] => {
  return offenePosten.auszifferungList.map((auszifferung) => ({
    amount: auszifferung.amount,
    auszifferungId: auszifferung.auszifferungId,
    auszifferungsDatum: auszifferung.auszifferungsDatum,
    buchungId: auszifferung.buchungId,
    buchungsdatum: auszifferung.buchungsdatum,
    createTs: auszifferung.createTs,
    createdBy: auszifferung.createdBy,
    createdByMitarbeiterId: auszifferung.createdByMitarbeiterId,
    offenePostenId: offenePosten.offenePostenId,
    source: auszifferung.source,
    storniert: auszifferung.storniert ?? false,
    storniertTs: auszifferung.storniertTs,
    zahlungId: auszifferung.zahlungId,
  }));
};

export const mapOffenePostenForPersonenkontoToAuszifferungFormValues = (offenePosten: OffenePosten): AuszifferungFormValues[] => {
  return offenePosten.auszifferungList.map((auszifferung) => ({
    amount: auszifferung.betrag,
    auszifferungId: auszifferung.auszifferungId,
    auszifferungsDatum: auszifferung.auszifferungsDatum,
    buchungId: auszifferung.buchungId,
    buchungsdatum: auszifferung.buchungsdatum,
    createTs: auszifferung.createTs,
    createdBy: auszifferung.createdBy,
    createdByMitarbeiterId: auszifferung.createdByMitarbeiterId,
    offenePostenId: offenePosten.offenePostenId,
    source: auszifferung.source,
    storniert: auszifferung.storniert ?? false,
    storniertByMitarbeiterId: auszifferung.storniertByMitarbeiterId,
    storniertTs: auszifferung.storniertTs,
    zahlungId: auszifferung.zahlungId,
  }));
};

export const mapOffenePostenAuszifferungListToFormValues = (offenePostenList: OffenePosten[]): OffenePostenForBooking[] => {
  return offenePostenList.map((offenePosten) => mapOPForPersonenkontoToOPForBooking(offenePosten));
};

export const mapOPForPersonenkontoToOPForBooking = (offenePosten: OffenePosten): OffenePostenForBooking => ({
  auszifferungList: [...mapOffenePostenForPersonenkontoToAuszifferungFormValues(offenePosten)],
  belegDatum: offenePosten.belegDatum,
  belegFileId: offenePosten.belegFileId ?? '',
  belegSymbol: offenePosten.belegSymbol,
  belegnummer: offenePosten.belegnummer,
  buchungsdatum: offenePosten.buchungsdatum,
  createTs: offenePosten.createTs,
  createdBy: offenePosten.createdBy,
  createdByMitarbeiterId: offenePosten.createdByMitarbeiterId,
  dataCarrierBelegId: offenePosten.dataCarrierBelegId ?? '',
  dueDate: offenePosten.dueDate,
  gesamtBetrag: offenePosten.gesamtBetrag,
  iban: offenePosten.iban ?? '',
  offenePostenId: offenePosten.offenePostenId,
  offenerBetrag: offenePosten.offenerBetrag,
  statementNumber: offenePosten.statementNumber ?? '',
});

// map form values to Auszifferung list create input

export const mapFormValuesToAuszifferungListCreateInput = (opListForBooking: OffenePostenForBooking[]): AuszifferungListCreateInput => ({
  auszifferungList:
    opListForBooking
      .map((op) =>
        op.auszifferungList
          .filter((auszifferung) => !isExistingAuszifferung(auszifferung) && auszifferung.amount !== 0)
          .map(mapAuszifferungToCreateInput)
      )
      .flat() ?? [],
});

const mapAuszifferungToCreateInput = (values: AuszifferungFormValues): AuszifferungCreateInput => ({
  betrag: mapFormattedDecimalOrThrowIfEmpty(values.amount),
  offenePostenId: values.offenePostenId,
});

// -------------------

export const createNewAuszifferung = (
  offenePosten: OffenePostenForBooking,
  mitarbeiterId: string,
  username: string,
  initialBetragForNewAuszifferung: number
): AuszifferungFormValues => ({
  amount: initialBetragForNewAuszifferung,
  auszifferungId: uuid4(),
  auszifferungsDatum: undefined,
  buchungId: undefined,
  buchungsdatum: undefined,
  createTs: dayjsCustom().format(),
  createdBy: username,
  createdByMitarbeiterId: mitarbeiterId,
  offenePostenId: offenePosten.offenePostenId,
  source: undefined,
  storniert: false,
  zahlungId: undefined,
});
