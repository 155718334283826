import React from 'react';
import { Col, Row } from 'antd';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from '../../features/Breadcrumb';
import { GroupedMenueListInterface } from '../ContextSidebar/ContentWithSidebar';
import { ContentMain } from '../LayoutAndromeda/Styled/Layout.style';
import { StyledSkeleton, StyledSkeletonLayout } from '../Loading';
import DetailsPageContentMain from './DetailsPageContentMain';

type DetailsPageTemplateProps<Data> = {
  data?: Data;
  isLoading?: boolean; // only pass it if multiple objects should be loaded before details page is shown
  pageTitle: (data: Data) => string;
  card: (data: Data) => React.ReactNode;
  sidebarMenuList: (data: Data) => GroupedMenueListInterface;
  contentDefaultSelected?: string;
  contentContextList?: (data: Data) => string[] | undefined;
};

/*
Normal use case: Your details page is only depending on one object. In this case you should provide only data without isLoading.
Special use case: Your details page is depending on multiple objects, In this case you should provide your main object as data and you should pass isLoading
  which is depending on all objects which should be loaded before content for details page is shown
 */
function DetailsPageTemplate<Data>({
  data,
  pageTitle,
  card,
  sidebarMenuList,
  isLoading,
  contentContextList,
  contentDefaultSelected,
}: DetailsPageTemplateProps<Data>) {
  const isDataAvailable = isLoading !== undefined ? !isLoading && !!data : !!data;

  return (
    <>
      {isDataAvailable ? (
        <Helmet>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <title>{pageTitle(data!)}</title>
        </Helmet>
      ) : null}
      <Breadcrumbs />
      {isDataAvailable ? (
        <DetailsPageContentMain
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          card={card(data!)}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          sidebarMenuList={sidebarMenuList(data!)}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          contentContextList={contentContextList ? contentContextList(data!) : undefined}
          contentDefaultSelected={contentDefaultSelected}
        />
      ) : (
        <ContentMain>
          <Row gutter={16}>
            <Col span={6}>
              <StyledSkeleton loading active paragraph={{ rows: 4 }} />
            </Col>
            <Col span={18}>
              <StyledSkeletonLayout loading active paragraph={{ rows: 4 }} />
            </Col>
          </Row>
        </ContentMain>
      )}
    </>
  );
}

export default DetailsPageTemplate;
