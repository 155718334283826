import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { PaymentGenerierlaufType } from '../../../../../types';
import { useFilterGenLaufPaymentMitarbeiterListQuery } from '../../../gql/GenerierlaufFilter/PaymentGenerierlaufFilterQueries.types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: PaymentGenerierlaufType;
} & SelectProps;

const GenerierlaufMitarbeiterListFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufPaymentMitarbeiterListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufPaymentMitarbeiterList.data ?? [];

  return (
    <Select size="small" loading={loading} id={name} name={name} placeholder="Mitarbeiter auswählen" {...restProps}>
      {list.map((mitarbeiter) => (
        <Select.Option key={mitarbeiter.mitarbeiterId} value={mitarbeiter.mitarbeiterId}>
          <DataWithShortenedText maxTextLength={30} text={`${mitarbeiter.firstname}, ${mitarbeiter.lastname}`} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufMitarbeiterListFormSelect;
