import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValuePreservationTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ValuePreservationTypeListQuery = {
  getValuePreservationTypeList: {
    data: Array<{ text: string; value: Types.ValuePreservationType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ValuePreservationTypeListDocument = gql`
  query ValuePreservationTypeList {
    getValuePreservationTypeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useValuePreservationTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<ValuePreservationTypeListQuery, ValuePreservationTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValuePreservationTypeListQuery, ValuePreservationTypeListQueryVariables>(ValuePreservationTypeListDocument, options);
}
export function useValuePreservationTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValuePreservationTypeListQuery, ValuePreservationTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValuePreservationTypeListQuery, ValuePreservationTypeListQueryVariables>(ValuePreservationTypeListDocument, options);
}
export function useValuePreservationTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ValuePreservationTypeListQuery, ValuePreservationTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ValuePreservationTypeListQuery, ValuePreservationTypeListQueryVariables>(ValuePreservationTypeListDocument, options);
}
export type ValuePreservationTypeListQueryHookResult = ReturnType<typeof useValuePreservationTypeListQuery>;
export type ValuePreservationTypeListLazyQueryHookResult = ReturnType<typeof useValuePreservationTypeListLazyQuery>;
export type ValuePreservationTypeListSuspenseQueryHookResult = ReturnType<typeof useValuePreservationTypeListSuspenseQuery>;
export type ValuePreservationTypeListQueryResult = Apollo.QueryResult<ValuePreservationTypeListQuery, ValuePreservationTypeListQueryVariables>;
