import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MonthListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MonthListQuery = {
  getMonthList: {
    data: Array<{ text: string; value: Types.Month }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const MonthListDocument = gql`
  query MonthList {
    getMonthList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMonthListQuery(baseOptions?: Apollo.QueryHookOptions<MonthListQuery, MonthListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MonthListQuery, MonthListQueryVariables>(MonthListDocument, options);
}
export function useMonthListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthListQuery, MonthListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MonthListQuery, MonthListQueryVariables>(MonthListDocument, options);
}
export function useMonthListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MonthListQuery, MonthListQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MonthListQuery, MonthListQueryVariables>(MonthListDocument, options);
}
export type MonthListQueryHookResult = ReturnType<typeof useMonthListQuery>;
export type MonthListLazyQueryHookResult = ReturnType<typeof useMonthListLazyQuery>;
export type MonthListSuspenseQueryHookResult = ReturnType<typeof useMonthListSuspenseQuery>;
export type MonthListQueryResult = Apollo.QueryResult<MonthListQuery, MonthListQueryVariables>;
