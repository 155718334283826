import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { CamtGenerierlauf } from '../../../types';
import menuListCamtEbicsManually from './menuListCamtEbicsManually';
import { CamtEbicsManuallyPageRouteParams } from './routes';
import { useCamtGenerierlaufQuery } from '../../../features/Verarbeitung/gql/CamtGenerierlauf/CamtGenerierlaufQueries.types';

const CamtEbicsManuallyDetailsPage = () => {
  const { generierlaufId } = useParams() as CamtEbicsManuallyPageRouteParams;

  const { data } = useCamtGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getCamtGenerierlauf.data;

  return (
    <DetailsPageTemplate<CamtGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.CamtEbicsManually}
      card={(generierlauf) => <VerarbeitungDetailsPageCard generierlauf={generierlauf} cardTitle={verarbeitungDetailsCardTitles.CamtEbicsManually} />}
      isLoading={false}
      sidebarMenuList={menuListCamtEbicsManually}
    />
  );
};

export default CamtEbicsManuallyDetailsPage;
