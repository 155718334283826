/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../../../components/message/validationMsg';
import { zahlungsverlaufFormFields } from './zahlungsverlaufFormMapper';

export const zahlungsverlaufFormValidationSchema = Yup.object().shape({
  [zahlungsverlaufFormFields.zahlungsempfaengerId]: Yup.string().required(entityIsRequired('Zahlungsempfänger')),
  [zahlungsverlaufFormFields.zahlungsempfaengerBankDetailsId]: Yup.string().required(entityIsRequired('Bankverbindung')),
  [zahlungsverlaufFormFields.zahlungsdatum]: Yup.string().required(entityIsRequired('Zahlungsdatum')),
  [zahlungsverlaufFormFields.verwendungszweck]: Yup.string()
    .nullable()
    .when(zahlungsverlaufFormFields.zahlungsreferenz, ([zahlungsreferenz], schema) =>
      !zahlungsreferenz ? schema.required(entityIsRequired('Verwendungszweck')) : schema
    ),
  [zahlungsverlaufFormFields.zahlungsreferenz]: Yup.string().nullable().max(35, entityMaxCharLength('Zahlungsreferenz', 35)),
  [zahlungsverlaufFormFields.betrag]: Yup.number().required(entityIsRequired('Betrag')),
});
/* eslint-enable no-template-curly-in-string */
