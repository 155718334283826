import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBestandseinheitNutzflaecheVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  nutzflaecheVersion: Types.BestandseinheitNutzflaecheVersionInput;
}>;

export type CreateBestandseinheitNutzflaecheVersionMutation = {
  createBestandseinheitNutzflaecheVersion: {
    data: { beNutzflaecheId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBestandseinheitNutzflaecheVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  nutzflaecheVersionId: Types.Scalars['ID']['input'];
  nutzflaecheVersion: Types.BestandseinheitNutzflaecheVersionInput;
}>;

export type UpdateBestandseinheitNutzflaecheVersionMutation = {
  updateBestandseinheitNutzflaecheVersion: {
    data: { beNutzflaecheId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBestandseinheitNutzflaecheVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  nutzflaecheVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteBestandseinheitNutzflaecheVersionMutation = {
  deleteBestandseinheitNutzflaecheVersion: {
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateBestandseinheitNutzflaecheVersionDocument = gql`
  mutation CreateBestandseinheitNutzflaecheVersion(
    $objektId: ID!
    $bestandseinheitId: ID!
    $nutzflaecheVersion: BestandseinheitNutzflaecheVersionInput!
  ) {
    createBestandseinheitNutzflaecheVersion(objektId: $objektId, bestandseinheitId: $bestandseinheitId, nutzflaecheVersion: $nutzflaecheVersion) {
      data {
        beNutzflaecheId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateBestandseinheitNutzflaecheVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBestandseinheitNutzflaecheVersionMutation, CreateBestandseinheitNutzflaecheVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBestandseinheitNutzflaecheVersionMutation, CreateBestandseinheitNutzflaecheVersionMutationVariables>(
    CreateBestandseinheitNutzflaecheVersionDocument,
    options
  );
}
export type CreateBestandseinheitNutzflaecheVersionMutationHookResult = ReturnType<typeof useCreateBestandseinheitNutzflaecheVersionMutation>;
export type CreateBestandseinheitNutzflaecheVersionMutationResult = Apollo.MutationResult<CreateBestandseinheitNutzflaecheVersionMutation>;
export type CreateBestandseinheitNutzflaecheVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateBestandseinheitNutzflaecheVersionMutation,
  CreateBestandseinheitNutzflaecheVersionMutationVariables
>;
export const UpdateBestandseinheitNutzflaecheVersionDocument = gql`
  mutation UpdateBestandseinheitNutzflaecheVersion(
    $objektId: ID!
    $bestandseinheitId: ID!
    $nutzflaecheVersionId: ID!
    $nutzflaecheVersion: BestandseinheitNutzflaecheVersionInput!
  ) {
    updateBestandseinheitNutzflaecheVersion(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      nutzflaecheVersionId: $nutzflaecheVersionId
      nutzflaecheVersion: $nutzflaecheVersion
    ) {
      data {
        beNutzflaecheId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateBestandseinheitNutzflaecheVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBestandseinheitNutzflaecheVersionMutation, UpdateBestandseinheitNutzflaecheVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBestandseinheitNutzflaecheVersionMutation, UpdateBestandseinheitNutzflaecheVersionMutationVariables>(
    UpdateBestandseinheitNutzflaecheVersionDocument,
    options
  );
}
export type UpdateBestandseinheitNutzflaecheVersionMutationHookResult = ReturnType<typeof useUpdateBestandseinheitNutzflaecheVersionMutation>;
export type UpdateBestandseinheitNutzflaecheVersionMutationResult = Apollo.MutationResult<UpdateBestandseinheitNutzflaecheVersionMutation>;
export type UpdateBestandseinheitNutzflaecheVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateBestandseinheitNutzflaecheVersionMutation,
  UpdateBestandseinheitNutzflaecheVersionMutationVariables
>;
export const DeleteBestandseinheitNutzflaecheVersionDocument = gql`
  mutation DeleteBestandseinheitNutzflaecheVersion($objektId: ID!, $bestandseinheitId: ID!, $nutzflaecheVersionId: ID!) {
    deleteBestandseinheitNutzflaecheVersion(objektId: $objektId, bestandseinheitId: $bestandseinheitId, nutzflaecheVersionId: $nutzflaecheVersionId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteBestandseinheitNutzflaecheVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBestandseinheitNutzflaecheVersionMutation, DeleteBestandseinheitNutzflaecheVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBestandseinheitNutzflaecheVersionMutation, DeleteBestandseinheitNutzflaecheVersionMutationVariables>(
    DeleteBestandseinheitNutzflaecheVersionDocument,
    options
  );
}
export type DeleteBestandseinheitNutzflaecheVersionMutationHookResult = ReturnType<typeof useDeleteBestandseinheitNutzflaecheVersionMutation>;
export type DeleteBestandseinheitNutzflaecheVersionMutationResult = Apollo.MutationResult<DeleteBestandseinheitNutzflaecheVersionMutation>;
export type DeleteBestandseinheitNutzflaecheVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteBestandseinheitNutzflaecheVersionMutation,
  DeleteBestandseinheitNutzflaecheVersionMutationVariables
>;
