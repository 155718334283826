import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import { useFirmendatenBankDetailsListQuery, useRechtstraegerBankDetailsListQuery } from '../../../BankDetails/gql/BankDetailsQueries.types';
import BankDetailsCreateDrawer from '../../../BankDetails/Drawer/BankDetailsCreateDrawer';
import { useToggle } from '../../../../hooks/useToggle';
import { useCreateNewItemInSelect } from '../../../../components/Select/useCreateNewItemInSelect';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { ObjBankDetailsRechnungsausstellerType } from './objektBankDetailsFormMapper';
import { BankDetails } from '../../../../types';

type Props = {
  firmendatenId: string;
  rechtstraegerId?: string;
  onNewItemCreateSelected: () => void;
  onBankDetailsSelect?: (type: ObjBankDetailsRechnungsausstellerType) => void;
  onSetNewCreatedBankDetailsId?: (id: string, allowObjektZuweisung?: boolean) => void;
  weRechnungsAusstellerId?: string;
  rechtstraegerFibuKontoCreatable?: boolean;
  weRechnungsAusstellerFibuKontoCreatable?: boolean;
} & SelectProps;

const FirmendatenOrRechnungsAusstellerBankDetailsSelect: FC<Props> = ({
  firmendatenId,
  rechtstraegerId,
  onNewItemCreateSelected,
  onBankDetailsSelect,
  onSetNewCreatedBankDetailsId,
  weRechnungsAusstellerId,
  rechtstraegerFibuKontoCreatable,
  weRechnungsAusstellerFibuKontoCreatable,
  ...restProps
}) => {
  const [isCreateNewDrawerRAOpen, toggleCreateNewRADrawer] = useToggle();
  const [isCreateNewDrawerFDOpen, toggleCreateNewFDDrawer] = useToggle();
  const [isCreateNewDrawerWeRAOpen, toggleCreateNewWeRADrawer] = useToggle();

  const onNewItemCreateSelectedRAOpenDrawer = () => {
    onNewItemCreateSelected();
    toggleCreateNewRADrawer();
  };

  const onNewItemCreateSelectedFDOpenDrawer = () => {
    onNewItemCreateSelected();
    toggleCreateNewFDDrawer();
  };

  const onNewItemCreateSelectedWeRAOpenDrawer = () => {
    onNewItemCreateSelected();
    toggleCreateNewWeRADrawer();
  };

  const {
    data: fdBankDetailsListData,
    loading: loadingFdBankDetailsList,
    refetch: refetchFdBankDetailsList,
  } = useFirmendatenBankDetailsListQuery({ variables: { firmendatenId } });
  const {
    data: rtBankDetailsListData,
    loading: loadingRtBankDetailsList,
    refetch: refetchRtBankDetailsList,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = useRechtstraegerBankDetailsListQuery({ variables: { rechtstraegerId: rechtstraegerId! }, skip: !rechtstraegerId });

  const {
    data: weRtBankDetailsListData,
    loading: weRtLoadingRtBankDetailsList,
    refetch: refetchWeRtBankDetailsList,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = useRechtstraegerBankDetailsListQuery({ variables: { rechtstraegerId: weRechnungsAusstellerId! }, skip: !weRechnungsAusstellerId });

  const firmendatenBankDetailsList =
    fdBankDetailsListData?.getFirmendatenBankDetailsList.data.filter((bankDetails) => bankDetails.allowObjektZuweisung) ?? [];
  const rechtstraegerBankDetailsList = rtBankDetailsListData?.getRechtstraegerBankDetailsList.data ?? [];
  const weRaBankDetailsList = weRtBankDetailsListData?.getRechtstraegerBankDetailsList.data ?? [];

  const onAction = () => {
    refetchFdBankDetailsList();
    if (rechtstraegerId) refetchRtBankDetailsList();
    if (weRechnungsAusstellerId) refetchWeRtBankDetailsList();
  };

  const {
    handleSelect: handleSelectFD,
    handleSearch: handleSearchFD,
    renderCreateNewItem: renderCreateNewItemFD,
  } = useCreateNewItemInSelect(firmendatenBankDetailsList, onNewItemCreateSelectedFDOpenDrawer, 'Neue Bankverbindung für HV anlegen');

  const {
    handleSelect: handleSelectRA,
    handleSearch: handleSearchRA,
    renderCreateNewItem: renderCreateNewItemRA,
  } = useCreateNewItemInSelect(
    rechtstraegerBankDetailsList,
    onNewItemCreateSelectedRAOpenDrawer,
    'Neue Bankverbindung für RA anlegen',
    CREATE_NEW_ITEM_VALUE_RA
  );

  const {
    handleSelect: handleSelectWeRA,
    handleSearch: handleSearchWeRA,
    renderCreateNewItem: renderCreateNewItemWeRA,
  } = useCreateNewItemInSelect(
    weRaBankDetailsList,
    onNewItemCreateSelectedWeRAOpenDrawer,
    'Neue Bankverbindung für WE-RA anlegen',
    CREATE_NEW_ITEM_VALUE_WE_RA
  );

  return (
    <>
      <Select
        loading={loadingFdBankDetailsList || loadingRtBankDetailsList || weRtLoadingRtBankDetailsList}
        placeholder="Bankverbindung auswählen"
        filterOption={selectFilterOption}
        {...restProps}
        onSelect={(value: string) => {
          if (value === CREATE_NEW_ITEM_VALUE_WE_RA) {
            handleSelectWeRA?.(value);
          } else if (value === CREATE_NEW_ITEM_VALUE_RA) {
            handleSelectRA?.(value);
          } else {
            handleSelectFD?.(value);
          }
          onBankDetailsSelect?.(findSelectedBankDetails(firmendatenBankDetailsList, rechtstraegerBankDetailsList, weRaBankDetailsList, value));
        }}
        showSearch
        onSearch={(value) => {
          if (value === CREATE_NEW_ITEM_VALUE_WE_RA) {
            handleSearchWeRA?.(value);
          } else if (value === CREATE_NEW_ITEM_VALUE_RA) {
            handleSearchRA?.(value);
          } else {
            handleSearchFD?.(value);
          }
        }}
      >
        {weRechnungsAusstellerId && (
          <Select.OptGroup label="WE-Rechnungsaussteller">
            {weRaBankDetailsList.map((objBankDetails) => (
              <Select.Option key={objBankDetails.bankDetailsId} value={objBankDetails.bankDetailsId}>
                {objBankDetails.bankname} {friendlyFormatIBAN(objBankDetails.iban)}
              </Select.Option>
            ))}
            {renderCreateNewItemWeRA()}
          </Select.OptGroup>
        )}
        <Select.OptGroup label="Objekt-Rechnungsaussteller">
          {rechtstraegerBankDetailsList.map((bankDetails) => (
            <Select.Option key={bankDetails.bankDetailsId} value={bankDetails.bankDetailsId}>
              {bankDetails.bankname} {friendlyFormatIBAN(bankDetails.iban)}
            </Select.Option>
          ))}
          {renderCreateNewItemRA()}
        </Select.OptGroup>
        <Select.OptGroup label="Hausverwaltung">
          {firmendatenBankDetailsList.map((bankDetails) => (
            <Select.Option key={bankDetails.bankDetailsId} value={bankDetails.bankDetailsId}>
              {bankDetails.bankname} {friendlyFormatIBAN(bankDetails.iban)}
            </Select.Option>
          ))}
          {renderCreateNewItemFD()}
        </Select.OptGroup>
      </Select>
      <BankDetailsCreateDrawer
        isDrawerOpen={isCreateNewDrawerRAOpen}
        toggleDrawerOpen={toggleCreateNewRADrawer}
        rechtstraegerId={rechtstraegerId}
        onActionSuccess={onAction}
        onSetFieldValue={onSetNewCreatedBankDetailsId}
        fibuKontoCreatable={rechtstraegerFibuKontoCreatable}
      />
      <BankDetailsCreateDrawer
        isDrawerOpen={isCreateNewDrawerFDOpen}
        toggleDrawerOpen={toggleCreateNewFDDrawer}
        firmendatenId={firmendatenId}
        onActionSuccess={onAction}
        onSetFieldValue={onSetNewCreatedBankDetailsId}
        fibuKontoCreatable
      />
      <BankDetailsCreateDrawer
        isDrawerOpen={isCreateNewDrawerWeRAOpen}
        toggleDrawerOpen={toggleCreateNewWeRADrawer}
        rechtstraegerId={weRechnungsAusstellerId}
        onActionSuccess={onAction}
        onSetFieldValue={onSetNewCreatedBankDetailsId}
        fibuKontoCreatable={weRechnungsAusstellerFibuKontoCreatable}
      />
    </>
  );
};

const CREATE_NEW_ITEM_VALUE_RA = 'create-new-select-item-value-ra';
const CREATE_NEW_ITEM_VALUE_WE_RA = 'create-new-select-item-value-we-ra';

const findSelectedBankDetails = (
  firmendatenBankDetailsList: BankDetails[],
  rechtstraegerBankDetailsList: BankDetails[],
  weRaBankDetailsList: BankDetails[],
  value: string
): ObjBankDetailsRechnungsausstellerType => {
  const findFDBankDetails = firmendatenBankDetailsList.find((bankDetails) => bankDetails.bankDetailsId === value);
  const findRABankDetails = rechtstraegerBankDetailsList.find((bankDetails) => bankDetails.bankDetailsId === value);
  const findWeRABankDetails = weRaBankDetailsList.find((bankDetails) => bankDetails.bankDetailsId === value);

  if (!findFDBankDetails && !findRABankDetails && !findWeRABankDetails) {
    if (value === CREATE_NEW_ITEM_VALUE_WE_RA) {
      return ObjBankDetailsRechnungsausstellerType.WE_RECHNUNGS_AUSSTELLER;
    }
    if (value === CREATE_NEW_ITEM_VALUE_RA) {
      return ObjBankDetailsRechnungsausstellerType.RECHNUNGS_AUSSTELLER;
    }
    return ObjBankDetailsRechnungsausstellerType.HAUSVERWALTUNG;
  }
  if (findWeRABankDetails) {
    return ObjBankDetailsRechnungsausstellerType.WE_RECHNUNGS_AUSSTELLER;
  }
  if (findRABankDetails) {
    return ObjBankDetailsRechnungsausstellerType.RECHNUNGS_AUSSTELLER;
  }
  return ObjBankDetailsRechnungsausstellerType.HAUSVERWALTUNG;
};

export default FirmendatenOrRechnungsAusstellerBankDetailsSelect;
