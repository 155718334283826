import React from 'react';
import { CopyOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { generatePathToMahnungDetailsPage } from '../../features/Mahnung/mahnungUriPaths';
import MahnungUpdateForm from '../../features/Mahnung/Form/Update/MahnungUpdateForm';
import DocumentTab from '../../features/Document/DocumentTab';
import { Mahnung } from '../../types';

const menuListMahnung = (mahnung: Mahnung, mahnungId: string, onActionSuccess: () => void): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: 'Bearbeiten',
        icon: <HomeOutlined />,
        uri: `${generatePathToMahnungDetailsPage(mahnungId)}/edit`,
        content: () => <MahnungUpdateForm mahnung={mahnung} onActionSuccess={onActionSuccess} />,
      },
      {
        title: 'Dokumente',
        icon: <CopyOutlined />,
        uri: `${generatePathToMahnungDetailsPage(mahnungId)}/documents`,
        content: () => <DocumentTab mahnungId={mahnungId} hideFilters />,
      },
    ],
  };
};

export default menuListMahnung;
