import { FC } from 'react';
import HistoryListingTable from '../../../../History/HistoryListingTable';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import { useUstRegelsetTemplateChangeHistoryListQuery } from '../../gql/UstRegelsetTemplateQueries.types';

type Props = {
  ustRegelsetId: string;
};

const UstRegelsetChangeHistoryListingTable: FC<Props> = ({ ustRegelsetId }) => {
  const { data, loading } = useUstRegelsetTemplateChangeHistoryListQuery({ variables: { ustRegelsetId } });
  const historyList = data?.getUstRegelsetTemplateChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="ust-regelset-template" />;
};

export default UstRegelsetChangeHistoryListingTable;
