import React, { FC } from 'react';
import { Typography } from 'antd';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import { Bestandseinheit } from '../../../types';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  bestandseinheit: Bestandseinheit;
};

const InnerCardBestandseinheitInformation: FC<Props> = ({ bestandseinheit }) => {
  return (
    <>
      <Typography.Title level={5}>Allgemeine Informationen</Typography.Title>
      <CardDetailsInfoRow infoRowTitle="Bezeichnung">
        <Typography.Text>{bestandseinheit.bezeichnung ?? '-'}</Typography.Text>
      </CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="Geschoss">
        <Typography.Text>{bestandseinheit.geschoss ?? '-'}</Typography.Text>
      </CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="Stiege">
        <Typography.Text>{bestandseinheit.stiege ?? '-'}</Typography.Text>
      </CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="Bauteil">
        <Typography.Text>{bestandseinheit.bauteil ?? '-'}</Typography.Text>
      </CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="Aktiv ab">
        <Typography.Text>{bestandseinheit.aktivSeit ? <CustomFormattedDate value={bestandseinheit.aktivSeit} /> : '-'}</Typography.Text>
      </CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="Inaktiv ab">
        <Typography.Text>{bestandseinheit.inaktivSeit ? <CustomFormattedDate value={bestandseinheit.inaktivSeit} /> : '-'}</Typography.Text>
      </CardDetailsInfoRow>
    </>
  );
};

export default InnerCardBestandseinheitInformation;
