import React, { FC } from 'react';
import { MenuProps } from 'antd';
import { StyledListItem } from './styled/AddressAndContactList.style';
import { StyledSpaceForListItem, StyledSpaceForListItemContent } from './ContactListItemStyleHelpers';
import ContactListItemIcon from './ContactListItemIcon';
import ContactListItemDropdown from './ContactListItemDropdown';

type Props = {
  contactDetails: React.ReactNode;
  icon: React.ReactNode;
  menuItems: MenuProps['items'];
  isDraggable: boolean;
  footer: React.ReactNode;
  isHaupt?: boolean;
};

const ContactListItem: FC<Props> = ({ contactDetails, isHaupt = false, isDraggable, icon, menuItems, footer }) => (
  <StyledListItem>
    <StyledSpaceForListItem direction="vertical" style={{ width: '100%', height: '100%' }} size={0}>
      <StyledSpaceForListItemContent>
        <ContactListItemIcon isHaupt={isHaupt} icon={icon} isDraggable={isDraggable} />
        {contactDetails}
        <ContactListItemDropdown menuItems={menuItems} />
      </StyledSpaceForListItemContent>
      {footer}
    </StyledSpaceForListItem>
  </StyledListItem>
);

export default ContactListItem;
