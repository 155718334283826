import React from 'react';
import { Route } from 'react-router';
import { isAdmin } from '../../../../security/permissionChecks';
import VerwaltungAdminDetailsPage from './VerwaltungAdminDetailsPage';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS_ADMIN_DETAILS_PAGE } from '../../../../constants/andromedaSystemSettingsUriPaths';

export type AdminDetailsPageRouteParams = { username: string };

const andromedaSysSettingsAdminDetailsPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS_ADMIN_DETAILS_PAGE}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<VerwaltungAdminDetailsPage />} />}
  />
);

export default andromedaSysSettingsAdminDetailsPageRoute;
