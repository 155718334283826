import React, { FC } from 'react';
import { Switch } from 'formik-antd';
import { Divider } from 'antd';
import styled from 'styled-components';
import { AuflageCreateFormValues } from '../Create/auflageCreateFormMapper';
import { AuflageUpdateFormValues } from '../Update/auflageUpdateFormMapper';
import { FormFields } from '../../../../../../helpers/formikHelper';
import DatenTabColumnsLayout from '../../shared/DatenTabColumnsLayout';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formFields: FormFields<AuflageCreateFormValues | AuflageUpdateFormValues>;
};

const AuflageDatenTab: FC<Props> = ({ formFields }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ObjektInfoFeldAuflage'>('ObjektInfoFeldAuflage');

  return (
    <DatenTabColumnsLayout
      columnDivider={<Divider type="vertical" style={{ height: '240px' }} />}
      leftColumn={
        <>
          <StyledFormItem
            name={formFields.amtlicheQuadratmeterFestsetzung}
            label="Amtliche m² Festsetzung"
            fieldHelp={getFieldHelpText('ObjektInfoFeldAuflage.amtlicheQuadratmeterFestsetzung')}
          >
            <Switch id={formFields.amtlicheQuadratmeterFestsetzung} name={formFields.amtlicheQuadratmeterFestsetzung} size="small" />
          </StyledFormItem>
          <StyledFormItem
            name={formFields.assanierungsgebiet}
            label="Assanierungsgebiet"
            fieldHelp={getFieldHelpText('ObjektInfoFeldAuflage.assanierungsgebiet')}
          >
            <Switch id={formFields.assanierungsgebiet} name={formFields.assanierungsgebiet} size="small" />
          </StyledFormItem>
          <StyledFormItem name={formFields.bebaut} label="Bebaut" fieldHelp={getFieldHelpText('ObjektInfoFeldAuflage.bebaut')}>
            <Switch id={formFields.bebaut} name={formFields.bebaut} size="small" />
          </StyledFormItem>
          <StyledFormItem name={formFields.denkmalschutz} label="Denkmalschutz" fieldHelp={getFieldHelpText('ObjektInfoFeldAuflage.denkmalschutz')}>
            <Switch id={formFields.denkmalschutz} name={formFields.denkmalschutz} size="small" />
          </StyledFormItem>
          <StyledFormItem
            name={formFields.gruenderzeitviertel}
            label="Gründerzeitviertel"
            fieldHelp={getFieldHelpText('ObjektInfoFeldAuflage.gruenderzeitviertel')}
          >
            <Switch id={formFields.gruenderzeitviertel} name={formFields.gruenderzeitviertel} size="small" />
          </StyledFormItem>
        </>
      }
      rightColumn={
        <>
          <StyledFormItem name={formFields.naturschutz} label="Naturschutz" fieldHelp={getFieldHelpText('ObjektInfoFeldAuflage.naturschutz')}>
            <Switch id={formFields.naturschutz} name={formFields.naturschutz} size="small" />
          </StyledFormItem>
          <StyledFormItem name={formFields.schutzzone} label="Schutzzone" fieldHelp={getFieldHelpText('ObjektInfoFeldAuflage.schutzzone')}>
            <Switch id={formFields.schutzzone} name={formFields.schutzzone} size="small" />
          </StyledFormItem>
          <StyledFormItem
            name={formFields.vollanwendungMRG}
            label="Vollanwendung MRG"
            fieldHelp={getFieldHelpText('ObjektInfoFeldAuflage.vollanwendungMRG')}
          >
            <Switch id={formFields.vollanwendungMRG} name={formFields.vollanwendungMRG} size="small" />
          </StyledFormItem>
          <StyledFormItem name={formFields.wohnzone} label="Wohnzone" fieldHelp={getFieldHelpText('ObjektInfoFeldAuflage.wohnzone')}>
            <Switch id={formFields.wohnzone} name={formFields.wohnzone} size="small" />
          </StyledFormItem>
        </>
      }
    />
  );
};

const StyledFormItem = styled(FormItemWithFieldHelp)`
  & .ant-form-item-control {
    align-items: flex-end;
  }
`;

export default AuflageDatenTab;
