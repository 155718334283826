import React, { FC } from 'react';
import { FormItem, FormItemProps } from 'formik-antd';
import { InfoCircleOutlined } from '@ant-design/icons';

type Props = {
  fieldHelp?: string | null;
} & Omit<FormItemProps, 'htmlFor' | 'colon'>;

/**
 *
 * <h2>Usage</h2>
 * Used to display form item with an additional icon that shows you what the field is used for.
 *
 */
const FormItemWithFieldHelp: FC<Props> = ({ fieldHelp, children, ...restProps }) => {
  return (
    <FormItem
      hasFeedback={false}
      {...restProps}
      label={restProps.label}
      tooltip={
        // use ternary operator - instead of ?? - it ensures here that the icon will surely be only displayed if fieldHelp is provided
        fieldHelp
          ? {
              title: fieldHelp,
              icon: <InfoCircleOutlined style={{ marginLeft: '4px' }} />, // margin is needed in order to ensure a space between the label text and tooltip icon
            }
          : undefined
      }
      htmlFor={restProps.name}
      colon={false}
    >
      {children}
    </FormItem>
  );
};

export default FormItemWithFieldHelp;
