import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import { getEntityWarningMsgByAttribute } from '../../../../helpers/entityWarningHelper';
import ConsolidationCriteriaCardDetailsInfoRow from '../../../ConsolidationCriteria/ConsolidationCriteriaCardDetailsInfoRow';
import { BeVertragFragment } from '../gql/BeVertragFragments.types';
import BankDetailsCardDetailsInfoRow from '../../../BankDetails/shared/BankDetailsCardDetailsInfoRow';

type Props = {
  vertrag: BeVertragFragment;
};

const VertragPaymentDetails: FC<Props> = ({ vertrag }) => (
  <>
    {vertrag.consolidationCriteriaEditable && <ConsolidationCriteriaCardDetailsInfoRow consolidationCriteria={vertrag.consolidationCriteria} />}

    {vertrag.bankDetails ? <BankDetailsCardDetailsInfoRow bankDetails={vertrag.bankDetails} /> : null}
    {getEntityWarningMsgByAttribute(vertrag, 'bankDetails') ? (
      <Space align="baseline">
        <BankOutlined />
        <Typography.Text type="danger">{getEntityWarningMsgByAttribute(vertrag, 'bankDetails')}</Typography.Text>
      </Space>
    ) : null}

    <CardDetailsInfoRow infoRowTitle="Belegform">
      <Typography.Text>{vertrag.belegform.text}</Typography.Text>
    </CardDetailsInfoRow>
  </>
);

export default VertragPaymentDetails;
