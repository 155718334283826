import * as Yup from 'yup';
import { entityIsRequired } from '../../../../components/message/validationMsg';
import { createFormFields } from './createFormMapper';

/* eslint-disable no-template-curly-in-string */
export const createFormValidationSchema = Yup.object().shape({
  [createFormFields.emailDeliveryDefinitionId]: Yup.string().required(entityIsRequired('E-Mail Versanddefinition')),
  [createFormFields.objektId]: Yup.string().required(entityIsRequired('Objekt')),
});
/* eslint-enable no-template-curly-in-string */
