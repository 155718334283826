import React, { FC } from 'react';
import { Drawer, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { useToggle } from '../../../hooks/useToggle';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { Aufteilungsschluessel, AufteilungsschluesselStatus } from '../../../types';
import {
  useArchiveAufteilungsschluesselTemplateMutation,
  useDeleteAufteilungsschluesselTemplateMutation,
  useUnarchiveAufteilungsschluesselTemplateMutation,
} from '../gql/AufteilungsschluesselTemplateMutations.types';
import AufteilungsschluesselTemplateForm from '../Form/AufteilungsschluesselTemplateForm';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import AufteilungsschluesselTemplateChangeHistoryListingTable from './AufteilungsschluesselTemplateChangeHistoryListingTable';
import ActionDropdown from '../../../components/Dropdown/ActionDropdown';

type Props = {
  aufteilungsschluessel: Aufteilungsschluessel;
  onAction: () => void;
};

const AufteilungsschluesselTemplateTableActions: FC<Props> = ({ aufteilungsschluessel, onAction }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const entity = `Aufteilungsschlüssel`;
  const isArchived = aufteilungsschluessel.status.value === AufteilungsschluesselStatus.Archiviert;

  const [runDelete] = useDeleteAufteilungsschluesselTemplateMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    variables: {
      aufteilungsschluesselId: aufteilungsschluessel.aufteilungsschluesselId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveAufteilungsschluesselTemplateMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onAction();
    },
    variables: {
      aufteilungsschluesselId: aufteilungsschluessel.aufteilungsschluesselId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveAufteilungsschluesselTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onAction();
    },
    variables: {
      aufteilungsschluesselId: aufteilungsschluessel.aufteilungsschluesselId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Bearbeiten',
      onClick: onCollapse,
      icon: <EditOutlined />,
      disabled: aufteilungsschluessel.status.value !== AufteilungsschluesselStatus.Aktiv,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(runUnarchive, aufteilungsschluessel) : showConfirmArchive(runArchive, aufteilungsschluessel),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: !!aufteilungsschluessel.deletable,
    },
    {
      key: '4',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, aufteilungsschluessel),
      icon: <DeleteOutlined />,
      disabled: !aufteilungsschluessel.deletable,
      danger: true,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Drawer title="Aufteilungsschlüssel bearbeiten" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <AufteilungsschluesselTemplateForm
          aufteilungsschluessel={aufteilungsschluessel}
          onSuccess={() => {
            onCollapse();
            onAction();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <AufteilungsschluesselTemplateChangeHistoryListingTable aufteilungsschluesselId={aufteilungsschluessel.aufteilungsschluesselId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, aufteilungsschluessel: Aufteilungsschluessel) => () => {
  Modal.confirm({
    title: `Möchten Sie den Aufteilungsschlüssel löschen?`,
    content: `${aufteilungsschluessel.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmArchive = (runArchive: () => void, aufteilungsschluessel: Aufteilungsschluessel) => () => {
  Modal.confirm({
    title: `Möchten Sie den Aufteilungsschlüssel archivieren?`,
    content: `${aufteilungsschluessel.kurzBezeichnung}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (runUnarchive: () => void, aufteilungsschluessel: Aufteilungsschluessel) => () => {
  Modal.confirm({
    title: `Möchten Sie den Aufteilungsschlüssel reaktivieren?`,
    content: `${aufteilungsschluessel.kurzBezeichnung}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

export default AufteilungsschluesselTemplateTableActions;
