import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VertragVposIndexedValueStatusListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type VertragVposIndexedValueStatusListQuery = {
  getVertragVposIndexedValueStatusList: {
    data: Array<{ text: string; value: Types.VertragVposIndexedValueStatus }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VertragVposIndexedValueStatusListDocument = gql`
  query VertragVposIndexedValueStatusList {
    getVertragVposIndexedValueStatusList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVertragVposIndexedValueStatusListQuery(
  baseOptions?: Apollo.QueryHookOptions<VertragVposIndexedValueStatusListQuery, VertragVposIndexedValueStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragVposIndexedValueStatusListQuery, VertragVposIndexedValueStatusListQueryVariables>(
    VertragVposIndexedValueStatusListDocument,
    options
  );
}
export function useVertragVposIndexedValueStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragVposIndexedValueStatusListQuery, VertragVposIndexedValueStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragVposIndexedValueStatusListQuery, VertragVposIndexedValueStatusListQueryVariables>(
    VertragVposIndexedValueStatusListDocument,
    options
  );
}
export function useVertragVposIndexedValueStatusListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VertragVposIndexedValueStatusListQuery, VertragVposIndexedValueStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragVposIndexedValueStatusListQuery, VertragVposIndexedValueStatusListQueryVariables>(
    VertragVposIndexedValueStatusListDocument,
    options
  );
}
export type VertragVposIndexedValueStatusListQueryHookResult = ReturnType<typeof useVertragVposIndexedValueStatusListQuery>;
export type VertragVposIndexedValueStatusListLazyQueryHookResult = ReturnType<typeof useVertragVposIndexedValueStatusListLazyQuery>;
export type VertragVposIndexedValueStatusListSuspenseQueryHookResult = ReturnType<typeof useVertragVposIndexedValueStatusListSuspenseQuery>;
export type VertragVposIndexedValueStatusListQueryResult = Apollo.QueryResult<
  VertragVposIndexedValueStatusListQuery,
  VertragVposIndexedValueStatusListQueryVariables
>;
