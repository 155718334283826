import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateIndexSeriesMutationVariables = Types.Exact<{
  input: Types.IndexSeriesCreateInput;
}>;

export type CreateIndexSeriesMutation = {
  createIndexSeries: {
    data: { indexSeriesId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateIndexSeriesMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['ID']['input'];
  input: Types.IndexSeriesUpdateInput;
}>;

export type UpdateIndexSeriesMutation = {
  updateIndexSeries: {
    data: { indexSeriesId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteIndexSeriesMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['ID']['input'];
}>;

export type DeleteIndexSeriesMutation = {
  deleteIndexSeries: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateIndexSeriesDocument = gql`
  mutation CreateIndexSeries($input: IndexSeriesCreateInput!) {
    createIndexSeries(input: $input) {
      data {
        indexSeriesId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateIndexSeriesMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateIndexSeriesMutation, CreateIndexSeriesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateIndexSeriesMutation, CreateIndexSeriesMutationVariables>(CreateIndexSeriesDocument, options);
}
export type CreateIndexSeriesMutationHookResult = ReturnType<typeof useCreateIndexSeriesMutation>;
export type CreateIndexSeriesMutationResult = Apollo.MutationResult<CreateIndexSeriesMutation>;
export type CreateIndexSeriesMutationOptions = Apollo.BaseMutationOptions<CreateIndexSeriesMutation, CreateIndexSeriesMutationVariables>;
export const UpdateIndexSeriesDocument = gql`
  mutation UpdateIndexSeries($indexSeriesId: ID!, $input: IndexSeriesUpdateInput!) {
    updateIndexSeries(indexSeriesId: $indexSeriesId, input: $input) {
      data {
        indexSeriesId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateIndexSeriesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateIndexSeriesMutation, UpdateIndexSeriesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateIndexSeriesMutation, UpdateIndexSeriesMutationVariables>(UpdateIndexSeriesDocument, options);
}
export type UpdateIndexSeriesMutationHookResult = ReturnType<typeof useUpdateIndexSeriesMutation>;
export type UpdateIndexSeriesMutationResult = Apollo.MutationResult<UpdateIndexSeriesMutation>;
export type UpdateIndexSeriesMutationOptions = Apollo.BaseMutationOptions<UpdateIndexSeriesMutation, UpdateIndexSeriesMutationVariables>;
export const DeleteIndexSeriesDocument = gql`
  mutation DeleteIndexSeries($indexSeriesId: ID!) {
    deleteIndexSeries(indexSeriesId: $indexSeriesId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteIndexSeriesMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteIndexSeriesMutation, DeleteIndexSeriesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteIndexSeriesMutation, DeleteIndexSeriesMutationVariables>(DeleteIndexSeriesDocument, options);
}
export type DeleteIndexSeriesMutationHookResult = ReturnType<typeof useDeleteIndexSeriesMutation>;
export type DeleteIndexSeriesMutationResult = Apollo.MutationResult<DeleteIndexSeriesMutation>;
export type DeleteIndexSeriesMutationOptions = Apollo.BaseMutationOptions<DeleteIndexSeriesMutation, DeleteIndexSeriesMutationVariables>;
