import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUstRegelsetTemplateUstKategorieZuweisungMutationVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
  input: Types.UstRegelsetUstKategorieZuweisungCreateInput;
}>;

export type CreateUstRegelsetTemplateUstKategorieZuweisungMutation = {
  createUstRegelsetTemplateUstKategorieZuweisung: {
    data: { validFrom: string; ustRegelsetKategorieZuweisungId: string; ustKategorie: { ustKategorieId: string } };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateUstRegelsetTemplateUstKategorieZuweisungMutationVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Types.Scalars['ID']['input'];
  input: Types.UstRegelsetUstKategorieZuweisungUpdateInput;
}>;

export type UpdateUstRegelsetTemplateUstKategorieZuweisungMutation = {
  updateUstRegelsetTemplateUstKategorieZuweisung: {
    data: { validFrom: string; ustRegelsetKategorieZuweisungId: string; ustKategorie: { ustKategorieId: string } };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteUstRegelsetTemplateUstKategorieZuweisungMutationVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Types.Scalars['ID']['input'];
}>;

export type DeleteUstRegelsetTemplateUstKategorieZuweisungMutation = {
  deleteUstRegelsetTemplateUstKategorieZuweisung: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateUstRegelsetTemplateUstKategorieZuweisungDocument = gql`
  mutation CreateUstRegelsetTemplateUstKategorieZuweisung($ustRegelsetId: ID!, $input: UstRegelsetUstKategorieZuweisungCreateInput!) {
    createUstRegelsetTemplateUstKategorieZuweisung(ustRegelsetId: $ustRegelsetId, input: $input) {
      data {
        validFrom
        ustKategorie {
          ustKategorieId
        }
        ustRegelsetKategorieZuweisungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateUstRegelsetTemplateUstKategorieZuweisungMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUstRegelsetTemplateUstKategorieZuweisungMutation,
    CreateUstRegelsetTemplateUstKategorieZuweisungMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUstRegelsetTemplateUstKategorieZuweisungMutation, CreateUstRegelsetTemplateUstKategorieZuweisungMutationVariables>(
    CreateUstRegelsetTemplateUstKategorieZuweisungDocument,
    options
  );
}
export type CreateUstRegelsetTemplateUstKategorieZuweisungMutationHookResult = ReturnType<
  typeof useCreateUstRegelsetTemplateUstKategorieZuweisungMutation
>;
export type CreateUstRegelsetTemplateUstKategorieZuweisungMutationResult =
  Apollo.MutationResult<CreateUstRegelsetTemplateUstKategorieZuweisungMutation>;
export type CreateUstRegelsetTemplateUstKategorieZuweisungMutationOptions = Apollo.BaseMutationOptions<
  CreateUstRegelsetTemplateUstKategorieZuweisungMutation,
  CreateUstRegelsetTemplateUstKategorieZuweisungMutationVariables
>;
export const UpdateUstRegelsetTemplateUstKategorieZuweisungDocument = gql`
  mutation UpdateUstRegelsetTemplateUstKategorieZuweisung(
    $ustRegelsetId: ID!
    $ustRegelsetUstKategorieZuweisungId: ID!
    $input: UstRegelsetUstKategorieZuweisungUpdateInput!
  ) {
    updateUstRegelsetTemplateUstKategorieZuweisung(
      ustRegelsetId: $ustRegelsetId
      ustRegelsetUstKategorieZuweisungId: $ustRegelsetUstKategorieZuweisungId
      input: $input
    ) {
      data {
        validFrom
        ustKategorie {
          ustKategorieId
        }
        ustRegelsetKategorieZuweisungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateUstRegelsetTemplateUstKategorieZuweisungMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUstRegelsetTemplateUstKategorieZuweisungMutation,
    UpdateUstRegelsetTemplateUstKategorieZuweisungMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUstRegelsetTemplateUstKategorieZuweisungMutation, UpdateUstRegelsetTemplateUstKategorieZuweisungMutationVariables>(
    UpdateUstRegelsetTemplateUstKategorieZuweisungDocument,
    options
  );
}
export type UpdateUstRegelsetTemplateUstKategorieZuweisungMutationHookResult = ReturnType<
  typeof useUpdateUstRegelsetTemplateUstKategorieZuweisungMutation
>;
export type UpdateUstRegelsetTemplateUstKategorieZuweisungMutationResult =
  Apollo.MutationResult<UpdateUstRegelsetTemplateUstKategorieZuweisungMutation>;
export type UpdateUstRegelsetTemplateUstKategorieZuweisungMutationOptions = Apollo.BaseMutationOptions<
  UpdateUstRegelsetTemplateUstKategorieZuweisungMutation,
  UpdateUstRegelsetTemplateUstKategorieZuweisungMutationVariables
>;
export const DeleteUstRegelsetTemplateUstKategorieZuweisungDocument = gql`
  mutation DeleteUstRegelsetTemplateUstKategorieZuweisung($ustRegelsetId: ID!, $ustRegelsetUstKategorieZuweisungId: ID!) {
    deleteUstRegelsetTemplateUstKategorieZuweisung(
      ustRegelsetId: $ustRegelsetId
      ustRegelsetUstKategorieZuweisungId: $ustRegelsetUstKategorieZuweisungId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteUstRegelsetTemplateUstKategorieZuweisungMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUstRegelsetTemplateUstKategorieZuweisungMutation,
    DeleteUstRegelsetTemplateUstKategorieZuweisungMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUstRegelsetTemplateUstKategorieZuweisungMutation, DeleteUstRegelsetTemplateUstKategorieZuweisungMutationVariables>(
    DeleteUstRegelsetTemplateUstKategorieZuweisungDocument,
    options
  );
}
export type DeleteUstRegelsetTemplateUstKategorieZuweisungMutationHookResult = ReturnType<
  typeof useDeleteUstRegelsetTemplateUstKategorieZuweisungMutation
>;
export type DeleteUstRegelsetTemplateUstKategorieZuweisungMutationResult =
  Apollo.MutationResult<DeleteUstRegelsetTemplateUstKategorieZuweisungMutation>;
export type DeleteUstRegelsetTemplateUstKategorieZuweisungMutationOptions = Apollo.BaseMutationOptions<
  DeleteUstRegelsetTemplateUstKategorieZuweisungMutation,
  DeleteUstRegelsetTemplateUstKategorieZuweisungMutationVariables
>;
