import React, { FC, useState } from 'react';
import { Button, notification, Space } from 'antd';
import { NotificationInstance } from 'antd/lib/notification/interface';
import { Box, Flex } from 'rebass';

import { DataChangedEvent } from '../../types';

export const dataChangedInfo = (notificationDescription: string, onRefresh: () => Promise<unknown>, event?: DataChangedEvent) => {
  if (event) {
    const desc = (
      <Space direction="vertical">
        {notificationDescription}
        <Flex>
          <Box mx="auto" />
          <Button type="link" onClick={() => notification.destroy('data-change')}>
            Ignorieren
          </Button>
          <AktualisierenButton onRefresh={onRefresh} notification={notification} />
        </Flex>
      </Space>
    );
    notification.info({
      key: 'data-change',
      message: 'Nicht mehr aktuell',
      description: desc,
      duration: 0,
      placement: 'bottomRight',
    });
  }
};

type AktualisierenButtonProps = {
  notification: NotificationInstance;
  onRefresh: () => Promise<unknown>;
};

const AktualisierenButton: FC<AktualisierenButtonProps> = ({ notification, onRefresh }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Button
      type="link"
      onClick={() => {
        setIsLoading(true);
        onRefresh().finally(() => {
          setIsLoading(false);
          notification.destroy('data-change');
        });
      }}
      loading={isLoading}
    >
      Aktualisieren
    </Button>
  );
};
