import React, { FC } from 'react';
import { Avatar, Space, Typography } from 'antd';
import { uppercaseString } from '../../../../../helpers/stringHelper';
import { verarbeitungTypeAbkuerzungForAvatar } from './verarbeitungTypeAbkuerzungForAvatar';
import { avatarColorForVerarbeitungType } from './avatarColorForVerarbeitungType';
import { getVerarbeitungTypeTitles } from '../../../verarbeitungHelpers';

type Props = {
  verarbeitungType: string;
};

const AvatarForVerarbeitungen: FC<Props> = ({ verarbeitungType }) => (
  <Space size="middle">
    <Avatar shape="square" size="small" style={{ backgroundColor: avatarColorForVerarbeitungType(verarbeitungType) }}>
      {verarbeitungTypeAbkuerzungForAvatar(verarbeitungType)}
    </Avatar>
    <Typography.Text>{uppercaseString(getVerarbeitungTypeTitles(verarbeitungType))}</Typography.Text>
  </Space>
);

export default AvatarForVerarbeitungen;
