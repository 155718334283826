import React from 'react';
import { Route } from 'react-router';
import FirmendatenIndexSeriesDetailsPage from './FirmendatenIndexSeriesDetailsPage';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS_FIRMENDATEN_INDEX_SERIES_DETAILS_PAGE } from '../../../../features/Firmendaten/IndexSeries/firmendatenIndexSeriesUriPaths';

export type FirmendatenIndexSeriesDetailsRouteParams = { indexSeriesId: string };

const sysSettingsFirmendatenIndexSeriesDetailsPageRoute = (
  <Route
    path={URI_SYS_SETTINGS_FIRMENDATEN_INDEX_SERIES_DETAILS_PAGE}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<FirmendatenIndexSeriesDetailsPage />} />}
  />
);

export default sysSettingsFirmendatenIndexSeriesDetailsPageRoute;
