import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../../styles/pdfStyles';
import { columnWidthsAndAlignment } from './details-table-styles';
import { AnteilVonSummeData } from '../../../hauseigentuemerAbrechnung-types';

const AnteilVonSumme: FC<{ data: AnteilVonSummeData }> = ({ data }) => (
  <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { paddingTop: '3mm' }]}>
    <Text
      style={[
        pdfStyles.textNormal,
        {
          width: columnWidthsAndAlignment.positionText.width + columnWidthsAndAlignment.objektsummeText.width,
          textAlign: columnWidthsAndAlignment.positionText.textAlign,
          fontWeight: 'bold',
        },
      ]}
    >
      {data.label}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        { width: columnWidthsAndAlignment.objektsummeText.width, textAlign: columnWidthsAndAlignment.objektsummeText.textAlign, fontWeight: 'bold' },
      ]}
    >
      {data.objektSumme}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        { width: columnWidthsAndAlignment.anteilText.width, textAlign: columnWidthsAndAlignment.anteilText.textAlign, fontWeight: 'bold' },
      ]}
    >
      {data.anteil}
    </Text>
  </View>
);

export default AnteilVonSumme;
