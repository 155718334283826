import { FormikProps } from 'formik';
import { getFirmennameFields, updateFirmenname } from '../../../../shared/components/Firmenname/form/firmennameFormPartHelper';
import { getPersonsCommunityTypeFields } from '../PersonsCommunityTypeSelect/personsCommunityTypeSelectHelper';
import { updateTempHauptAddress } from '../../HauptAddress/HauptAddressOptionalFormPart';
import { URL_KURZBEZEICHNUNG_VORSCHLAG_PERSONEN_GEMEINSCHAFT } from '../../../../shared/api/ApiPaths';
import getKurzBezeichnungVorschlagText from '../../../../shared/api/KurzBezeichnungVorschlagApi';
import { PersonGemeinschaftFormValues } from './personenGemeinschaftFormMapper';

export const getKurzBezeichnungVorschlagFieldsToBeDebounced = (formikProps: FormikProps<PersonGemeinschaftFormValues>) => ({
  ...getFirmennameFields(formikProps),
});

export const getKurzBezeichnungVorschlagFieldsNotToBeDebounced = (formikProps: FormikProps<PersonGemeinschaftFormValues>) => ({
  ...getPersonsCommunityTypeFields(formikProps),
});

export const getPersonenGemeinschaftKurzBezeichnungVorschlag = (kurzBezeichnungVorschlagFields: PersonGemeinschaftFormValues) =>
  getKurzBezeichnungVorschlagText(URL_KURZBEZEICHNUNG_VORSCHLAG_PERSONEN_GEMEINSCHAFT, kurzBezeichnungVorschlagFields);

export const updateFirmennameAndAddress = (formikProps: FormikProps<PersonGemeinschaftFormValues>) => (data: any) => {
  updateFirmenname(formikProps, data);
  updateTempHauptAddress(formikProps, '', data);
};
