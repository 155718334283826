import { generatePath } from 'react-router-dom';
import { mapToQueryString, QUERY_PARAM_KEY } from '../../hooks/useStringQueryParams';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../constants/andromedaSystemSettingsUriPaths';

export const URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION = {
  viewPage: `${URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage}`,
  createPage: `${URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage}/new`,
  version: {
    createPage: `${URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage}/:abrechnungsdefinitionId/new-version`,
    updatePage: `${URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage}/:abrechnungsdefinitionId/versionen/:abrechnungsdefinitionVersionId`,
    viewPage: `${URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage}/:abrechnungsdefinitionId/versionen/view/:abrechnungsdefinitionVersionId`,
  },
};

export const generatePathToAbrechnungsdefinitionenTemplatePage = (abrechnungsdefinitionId: string): string => {
  return `${generatePath(URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION.viewPage)}?${mapToQueryString({
    [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: abrechnungsdefinitionId,
  })}`;
};

export const generatePathToAbrechnungsdefinitionTemplateVersionCreatePage = (abrechnungsdefinitionId: string): string =>
  generatePath(URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.createPage, {
    abrechnungsdefinitionId,
  });

export const generatePathToAbrechnungsdefinitionTemplateVersionUpdatePage = (
  abrechnungsdefinitionId: string,
  abrechnungsdefinitionVersionId: string
): string =>
  generatePath(URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.updatePage, {
    abrechnungsdefinitionId,
    abrechnungsdefinitionVersionId,
  });

export const generatePathToAbrechnungsdefinitionTemplateVersionViewPage = (
  abrechnungsdefinitionId: string,
  abrechnungsdefinitionVersionId: string,
  isHistoricized: boolean,
  abrechnungskreisId?: string
) => {
  return `${generatePath(URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.viewPage, {
    abrechnungsdefinitionId,
    abrechnungsdefinitionVersionId,
  })}?${mapToQueryString({
    [AbrechnungsdefinitionViewPageQueryParam.IS_HISTORICIZED]: isHistoricized,
    [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: abrechnungskreisId,
  })}`;
};

export enum AbrechnungsdefinitionViewPageQueryParam {
  IS_HISTORICIZED = 'isHistoricized',
}
