import React, { useState } from 'react';
import classnames from 'classnames';
import { isSortingAsc, isSortingDesc, setSortOrder, SortOrder } from '../TableSorting/sortingHelpers';
import TableHeadCell from './TableHeadCell';
import TableHeadCellWithSortCarets from '../TableSorting/TableHeadCellWithSortCarets';
import { StyledTableWithAlignedCols } from './styled/StyledTableWithAlignedCols.style';
import TableWithAlignedColsBody from './TableWithAlignedColsBody';
import { isNil } from '../../../helpers/assertionHelper';
import { TableWithAlignedColsColumnType, TableWithAlignedColsProps } from './tableWithAlignedColsProps';
import { TABLE_ALIGNED_COLS_CLASSNAMES } from './tableWithAlignedColsClassnames';

function TableWithAlignedCols<T>({ dataSource, columns, expandedRow, rowKey, iconCellStyle, tableId }: TableWithAlignedColsProps<T>) {
  const [sorting, setSorting] = useState({
    sortedColumnIndex: 0,
    sortOrder: SortOrder.NoSorting,
  });

  const handleSorting = (index: number) => {
    setSorting({
      sortedColumnIndex: index,
      sortOrder: setSortOrder(index, sorting.sortOrder, sorting.sortedColumnIndex),
    });
  };

  const sortedDataSource = sortTableWithAlignedCol<T>(dataSource, columns, sorting.sortOrder, sorting.sortedColumnIndex);

  return (
    <StyledTableWithAlignedCols className={TABLE_ALIGNED_COLS_CLASSNAMES.table} id={tableId}>
      <thead className={TABLE_ALIGNED_COLS_CLASSNAMES.thead}>
        <tr>
          <th
            className={classnames(TABLE_ALIGNED_COLS_CLASSNAMES.iconCell, TABLE_ALIGNED_COLS_CLASSNAMES.level0)}
            style={iconCellStyle ?? { width: '32px' }}
          />
          {columns.map((col, index) => {
            if (col.sorting) {
              return (
                <TableHeadCellWithSortCarets
                  key={index}
                  columnTitle={col.title}
                  align={col.align}
                  index={col.sorting.index}
                  sortedColumnIndex={sorting.sortedColumnIndex}
                  sortOrder={sorting.sortOrder}
                  handleSorting={handleSorting}
                  colSpan={col.colSpan}
                  width={col.width}
                />
              );
            }
            return <TableHeadCell key={index} title={col.title} align={col.align} colSpan={col.colSpan} width={col.width} />;
          })}
        </tr>
      </thead>
      <tbody className={TABLE_ALIGNED_COLS_CLASSNAMES.tbody}>
        {sortedDataSource.map((data, index) => {
          return (
            <TableWithAlignedColsBody<T>
              key={index}
              dataSource={data}
              columns={columns}
              expandedRow={(data) => expandedRow(data)}
              positionOfButton={0}
              rowKey={rowKey}
              sorting={sorting}
            />
          );
        })}
      </tbody>
    </StyledTableWithAlignedCols>
  );
}

function sortTableWithAlignedCol<T>(dataSource: T[], columns: TableWithAlignedColsColumnType<T>[], sortOrder: SortOrder, sortedColumnIndex: number) {
  const currentColumnSorting = columns[sortedColumnIndex]?.sorting;
  if (isNil(currentColumnSorting)) {
    return dataSource;
  }

  if (isSortingAsc(sortOrder)) {
    const compareFn = (a: T, b: T) => currentColumnSorting.compareFn(a, b);
    return [...dataSource].sort(compareFn);
  }

  if (isSortingDesc(sortOrder)) {
    const compareFn = (a: T, b: T) => currentColumnSorting.compareFn(b, a);
    return [...dataSource].sort(compareFn);
  }

  return dataSource;
}

export default TableWithAlignedCols;
