import React from 'react';
import { Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { TableWithColSelectorColumnProps } from '../../../../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import AvatarBasedOnBelegSymbolWithTooltip from '../../../../../../../../components/Avatar/BasedOnBelegSymbol/AvatarBasedOnBelegSymbolWithTooltip';
import { compareTwoNumbersForSorting } from '../../../../../../../../helpers/numberHelper';
import CustomFormattedDate from '../../../../../../../../components/FormattedDate/CustomFormattedDate';
import FormattedDateTime from '../../../../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../../../../components/Card';
import { compareTwoDatesForSorting } from '../../../../../../../../helpers/dateHelper';
import { EuroAmount } from '../../../../../../../../components/Number';
import LinkToBelegFile from '../../../../../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import { OffenePostenForBooking } from '../../../auszifferungFormMapper';
import DataWithShortenedText from '../../../../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToBankstatementListingPage } from '../../../../../../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';
import AddAuszifferungColumnForOPTableForPersonenkonto from '../../OffenePostenListingForPersonenkonto/Table/Level1/AddAuszifferungColumnForOPTableForPersonenkonto';
import { FibuBuchungType } from '../../../../../../../../types';

export const offenePostenTableColumns = (
  fieldNamePrefix: string,
  toggleExpandedRowKey: (rowKey: string) => void,
  bookingZahlungId: string,
  isOPForPersonenkonto: boolean,
  buchungType: FibuBuchungType
): TableWithColSelectorColumnProps<OffenePostenForBooking>[] => {
  return [
    {
      title: 'AR/ER',
      defaultSelected: true,
      render: (text, record) => {
        return (
          <Space size="small">
            <AvatarBasedOnBelegSymbolWithTooltip belegSymbol={record.belegSymbol} size="small" />
          </Space>
        );
      },
    },
    {
      title: 'Belegnummer',
      defaultSelected: true,
      sorter: (a, b) => compareTwoNumbersForSorting(Number(a.belegnummer), Number(b.belegnummer)),
      align: 'right',
      render: (text, record) => {
        if (record.dataCarrierBelegId) {
          return (
            // Space component is used to keep the entry alignment according to alignment of the column
            <Space size="small">
              <DataWithShortenedText maxTextLength={15} text={record.belegnummer}>
                {(shortenedText) => (
                  <Link
                    to={generatePathToBankstatementListingPage({
                      iban: record.iban,
                      statementNumber: record.statementNumber,
                    })}
                    target="_blank"
                  >
                    {shortenedText}
                  </Link>
                )}
              </DataWithShortenedText>
            </Space>
          );
        } else if (record.belegFileId) {
          return <LinkToBelegFile fileId={record.belegFileId} belegnummer={record.belegnummer} hideIcon />;
        } else {
          return <Typography.Text>{record.belegnummer}</Typography.Text>;
        }
      },
    },
    {
      title: 'Belegdatum',
      defaultSelected: true,
      sorter: (a, b) => compareTwoDatesForSorting(a.belegDatum, b.belegDatum),
      align: 'right',
      render: (text, record) => <CustomFormattedDate value={record.belegDatum} />,
    },
    {
      title: 'Buchungsdatum',
      defaultSelected: true,
      sorter: (a, b) => compareTwoDatesForSorting(a.buchungsdatum, b.buchungsdatum),
      align: 'right',
      render: (text, record) => <CustomFormattedDate value={record.buchungsdatum} />,
    },
    {
      title: 'Gesamtbetrag',
      defaultSelected: true,
      sorter: (a, b) => compareTwoNumbersForSorting(a.gesamtBetrag, b.gesamtBetrag),
      align: 'right',
      render: (text, record) => <EuroAmount value={record.gesamtBetrag} />,
    },
    {
      title: 'Offener Betrag',
      defaultSelected: true,
      sorter: (a, b) => compareTwoNumbersForSorting(a.offenerBetrag, b.offenerBetrag),
      align: 'right',
      render: (text, record) => <EuroAmount value={record.offenerBetrag} />,
    },
    {
      title: 'Fälligkeit',
      defaultSelected: true,
      sorter: (a, b) => compareTwoDatesForSorting(a.dueDate, b.dueDate),
      align: 'right',
      render: (text, record) => <CustomFormattedDate value={record.dueDate} />,
    },
    {
      title: 'Erstellt am',
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      render: (text, record) => (
        <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
      ),
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      align: 'right',
      render: (text, record, index) => {
        const toggleExpandedRow = () => toggleExpandedRowKey(record.offenePostenId);

        return isOPForPersonenkonto ? (
          <AddAuszifferungColumnForOPTableForPersonenkonto
            buchungType={buchungType}
            bookingZahlungId={bookingZahlungId}
            offenePostenForPersonenkonto={record}
            toggleExpandedRow={toggleExpandedRow}
            fieldNamePrefix={`${fieldNamePrefix}[${index}]`}
          />
        ) : null;
      },
    },
  ];
};
