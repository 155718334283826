import React, { FC } from 'react';
import { Typography } from 'antd';
import { SollHaben } from '../../../../../../types';
import { EuroAmount } from '../../../../../../components/Number';
import { useFirmendatenSettings } from '../../../../../../shared/FirmendatenSettings/FirmendatenSettingsProvider';

type BetragProps = {
  betrag: number;
  sollHaben: SollHaben;
};

const BetragBasedOnFirmendatenSettingsAndSollHaben: FC<BetragProps> = ({ betrag, sollHaben }) => {
  const { calculateBetragBasedOnSettings } = useFirmendatenSettings();
  const betragBasedOnSettings = calculateBetragBasedOnSettings(sollHaben, betrag);

  return (
    <Typography.Text type={betragBasedOnSettings < 0 ? 'danger' : undefined}>
      <EuroAmount value={betragBasedOnSettings} />
    </Typography.Text>
  );
};

export default BetragBasedOnFirmendatenSettingsAndSollHaben;
