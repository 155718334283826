import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { RechtstraegerFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubAbrechnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  subAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type SubAbrechnungQuery = {
  getSubAbrechnung: {
    data: {
      abrechnungszeitraumBis: string;
      abrechnungszeitraumVon: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable: boolean;
      subAbrechnungId: string;
      updatedByMitarbeiterId?: string | null;
      objekt: {
        countryCodeIso2: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fotoFileId?: string | null;
        fotoType?: Types.FotoType | null;
        kurzBezeichnung: string;
        objektId: string;
        rechnungsAusstellerBezeichnung?: string | null;
        rechnungsAusstellerChangeable?: boolean | null;
        subAdministration: boolean;
        sumNutzflaeche: number;
        sumNutzwert: number;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        verwaltungBis?: string | null;
        verwaltungSeit: string;
        verwaltungsartChangeable?: boolean | null;
        addressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        objektSummeAnteile?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          kommentar?: string | null;
          lastUpdateTs: string;
          objektSummeAnteileId: string;
          summeAnteile: number;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            kommentar?: string | null;
            lastUpdateTs: string;
            objektSummeAnteileId: string;
            summeAnteile: number;
            validFrom: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        sachbearbeiter?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          fileStorageInitialized: boolean;
          firmendatenId: string;
          firstname: string;
          funktionsModulList: Array<string>;
          lastname: string;
          mitarbeiterId: string;
          title?: string | null;
          titleTrailing?: string | null;
          username: string;
          contactPerson?: {
            contactPersonId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firstname: string;
            lastname: string;
            tagList: Array<string>;
            title?: string | null;
            titleTrailing?: string | null;
            contactList: Array<{
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            rechtstraeger?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rolleList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            name: string;
            rolleId: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
          user?: {
            email?: string | null;
            emailUnverified?: string | null;
            firstname?: string | null;
            lastname?: string | null;
            role: string;
            username: string;
            status: { text: string; value: string };
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { text: string; value: Types.AbrechnungStatus };
      subAbrechnungEigentuemerListEntryList: Array<{
        abrechnungszeitraumBis: string;
        abrechnungszeitraumVon: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        guthaben: boolean;
        saldo: number;
        subAbrechnungEigentuemerId: string;
        updatedByMitarbeiterId?: string | null;
        abrechnungsdefinitionType: { text: string; value: Types.AbrechnungsdefinitionType };
        eigentuemer: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.SubAbrechnungEigentuemerStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const SubAbrechnungDocument = gql`
  query SubAbrechnung($objektId: ID!, $subAbrechnungId: ID!) {
    getSubAbrechnung(objektId: $objektId, subAbrechnungId: $subAbrechnungId) {
      data {
        abrechnungszeitraumBis
        abrechnungszeitraumVon
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        deletable
        objekt {
          ...Objekt
        }
        status {
          text
          value
        }
        subAbrechnungEigentuemerListEntryList {
          abrechnungsdefinitionType {
            text
            value
          }
          abrechnungszeitraumBis
          abrechnungszeitraumVon
          createTs
          createdBy
          createdByMitarbeiterId
          eigentuemer {
            ...RechtstraegerFields
          }
          guthaben
          saldo
          status {
            text
            value
          }
          subAbrechnungEigentuemerId
          updatedByMitarbeiterId
          warningList {
            attribute
            message
            type
          }
        }
        subAbrechnungId
        updatedByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektFragmentDoc}
  ${RechtstraegerFieldsFragmentDoc}
`;
export function useSubAbrechnungQuery(
  baseOptions: Apollo.QueryHookOptions<SubAbrechnungQuery, SubAbrechnungQueryVariables> &
    ({ variables: SubAbrechnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubAbrechnungQuery, SubAbrechnungQueryVariables>(SubAbrechnungDocument, options);
}
export function useSubAbrechnungLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubAbrechnungQuery, SubAbrechnungQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubAbrechnungQuery, SubAbrechnungQueryVariables>(SubAbrechnungDocument, options);
}
export function useSubAbrechnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubAbrechnungQuery, SubAbrechnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubAbrechnungQuery, SubAbrechnungQueryVariables>(SubAbrechnungDocument, options);
}
export type SubAbrechnungQueryHookResult = ReturnType<typeof useSubAbrechnungQuery>;
export type SubAbrechnungLazyQueryHookResult = ReturnType<typeof useSubAbrechnungLazyQuery>;
export type SubAbrechnungSuspenseQueryHookResult = ReturnType<typeof useSubAbrechnungSuspenseQuery>;
export type SubAbrechnungQueryResult = Apollo.QueryResult<SubAbrechnungQuery, SubAbrechnungQueryVariables>;
