import React, { FC, useEffect } from 'react';
import { useField } from 'formik';
import { SelectProps } from 'formik-antd';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import {
  useAufteilungsschluesselListForSelectByFirmendatenIdQuery,
  useAufteilungsschluesselListForSelectByKundenSystemIdQuery,
} from './gql/AufteilungsschluesselQueries.types';
import { belongsToKundenSystem, belongsToSelectedFirmendaten } from '../../helpers/selectHelper';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { Aufteilungsschluessel } from '../../types';

type Props = {
  firmendatenId?: string | null;
  excludedAufteilungsschluesselIds?: string[];
  includeFromFirmendaten?: boolean; // applies if firmendatenId in not set
} & SelectProps;

const AufteilungsschluesselSelect: FC<Props> = ({
  name,
  firmendatenId,
  excludedAufteilungsschluesselIds,
  includeFromFirmendaten = false,
  ...restProps
}) => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const [{ value: selectedId }, , { setValue: setSelectedId }] = useField<string>(name);

  const { loading: loadingByKS, data: maybeListByKS } = useAufteilungsschluesselListForSelectByKundenSystemIdQuery({
    variables: {
      kundenSystemId,
      includeArchiviert: true,
    },
    skip: !!firmendatenId,
  });

  const { loading: loadingByFD, data: maybeListByFD } = useAufteilungsschluesselListForSelectByFirmendatenIdQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      firmendatenId: firmendatenId!,
      includeArchiviert: true,
    },
    skip: !firmendatenId,
  });

  const loading = loadingByKS || loadingByFD;
  const listByKS = maybeListByKS?.getAufteilungsschluesselListByKundenSystemId.data.contentList ?? [];
  const listByFD = maybeListByFD?.getAufteilungsschluesselListByFirmendatenId.data.contentList ?? [];

  const list =
    // eslint-disable-next-line no-nested-ternary
    listByKS.length > 0
      ? includeFromFirmendaten
        ? listByKS
        : listByKS.filter(belongsToKundenSystem)
      : listByFD.filter((item) => belongsToSelectedFirmendaten(item, firmendatenId));

  const filteredList = list.filter((entity) => !excludedAufteilungsschluesselIds?.includes(entity.aufteilungsschluesselId));

  // f_id changes => list changes => if there was already a selected entity it may not be available in the scope of newly selected f_id => clear entity
  useEffect(() => {
    if (!loading && selectedId && !filteredList.map((item) => item.aufteilungsschluesselId).includes(selectedId)) {
      setSelectedId('');
    }
  }, [loading, filteredList, selectedId, setSelectedId]);

  return (
    <EntityStatusFormSelect<Aufteilungsschluessel>
      list={filteredList}
      getSelectItemId={(aufteilungsschluessel) => aufteilungsschluessel.aufteilungsschluesselId}
      getSelectItemContent={(aufteilungsschluessel) => aufteilungsschluessel.kurzBezeichnung}
      getSelectItemTooltipTitle={(aufteilungsschluessel) => aufteilungsschluessel.kurzBezeichnung}
      id="aufteilungsschluessel"
      name={name}
      loading={loadingByFD || loadingByKS}
      placeholder="Aufteilungsschlüssel auswählen"
      {...restProps}
    />
  );
};

export default AufteilungsschluesselSelect;
