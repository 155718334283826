import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';

type Props = SelectProps;

const OPMahnstufeSelect: FC<Props> = ({ name, ...restProps }) => {
  const mahnstufeList = [0, 1, 2, 3];

  return (
    <Select allowClear name={name} id={name} placeholder="Mahnstufe auswählen" {...restProps}>
      {mahnstufeList.map((mahnstufe) => (
        <Select.Option key={mahnstufe} value={mahnstufe}>
          {mahnstufe}
        </Select.Option>
      ))}
    </Select>
  );
};

export default OPMahnstufeSelect;
