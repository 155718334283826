import { Select } from 'formik-antd';
import React, { FC } from 'react';
import { NotificationType } from '../../../../types';
import { capitalizedNotificationType } from '../../Shared/notificationHelpers';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';

type TypSelectProps = {
  name: string;
  style?: any;
};

const TypSelect: FC<TypSelectProps> = ({ name, style }) => {
  return (
    <FormItemWithoutColon name={name} label="Typ">
      <Select size="small" name={name} id={name} placeholder="Typ auswählen" allowClear style={style}>
        {Object.values(NotificationType).map((type) => (
          <Select.Option key={type} value={type}>
            {capitalizedNotificationType(type)}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithoutColon>
  );
};

export default TypSelect;
