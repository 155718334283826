import React from 'react';
import { Link } from 'react-router-dom';
import { Tag, Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../../components/Card';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { Generierlauf, GenerierlaufOrderBy, GenerierlaufStatus } from '../../../../types';
import { generatePathToVerarbeitungDetailsPage } from '../../verarbeitungApiPaths';
import AvatarForVerarbeitungen from './AvatarForVerarbeitung/AvatarForVerarbeitungen';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { verarbeitungTypes } from '../../verarbeitungHelpers';

const verarbeitungFormTableColumns: TableWithColSelectorColumnProps<Generierlauf>[] = [
  {
    title: 'Verarbeitung',
    defaultSelected: true,
    dataIndex: GenerierlaufOrderBy.Type,
    sorter: true,
    render: (text, record) => <AvatarForVerarbeitungen verarbeitungType={record.type} />,
  },
  {
    title: 'Details',
    defaultSelected: true,
    render: (text, record) => (
      <Typography.Text>
        {record.countEntries} {findGenerierlaufEntriesName(record.type, record.countEntries)}
      </Typography.Text>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: GenerierlaufOrderBy.Status,
    sorter: true,
    render: (text, record) => <Tag color={getStatusTagColor(record.status.value)}>{record.status.text}</Tag>,
  },
  {
    title: 'Erstellt am',
    dataIndex: GenerierlaufOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => (
      <Link to={generatePathToVerarbeitungDetailsPage(record.type, record.generierlaufId, record.status.value, record.countEntries)}>Details</Link>
    ),
  },
];

export default verarbeitungFormTableColumns;

const getStatusTagColor = (value: GenerierlaufStatus) => {
  if (value === GenerierlaufStatus.Beendet) {
    return 'green';
  }
  if (value === GenerierlaufStatus.BeendetFehler) {
    return 'red';
  }
  if (value === GenerierlaufStatus.BeendetWarning) {
    return 'orange';
  }
  return '';
};

const findGenerierlaufEntriesName = (verarbeitungType: string, countEntries: number) => {
  switch (verarbeitungType) {
    case verarbeitungTypes.Budgeting:
      return 'Budgetierung';
    case verarbeitungTypes.FirmendatenInitializer:
      return 'Firmendaten';
    case verarbeitungTypes.ObjektFinancialPlan:
      return countEntries === 1 ? 'Objekt Wirtschaftsplan' : 'Objekt Wirtschaftspläne';
    case verarbeitungTypes.TopFinancialPlan:
      return countEntries === 1 ? 'Top Wirtschaftsplan' : 'Top Wirtschaftspläne';
    case verarbeitungTypes.InfoMailDeliveryDetermineRecipientList:
    case verarbeitungTypes.InfoMailDeliverySend:
    case verarbeitungTypes.VorschreibungMailDeliveryDetermineRecipientList:
    case verarbeitungTypes.VorschreibungMailDeliverySend:
      return 'Empfänger';
    case verarbeitungTypes.VorschreibungAusgeben:
      return countEntries === 1 ? 'Beleg' : 'Belege';
    case verarbeitungTypes.MahnungAusgeben:
    case verarbeitungTypes.MahnungErzeugen:
    case verarbeitungTypes.MahnungVersenden:
    case verarbeitungTypes.MahnungVerbuchen:
      return countEntries === 1 ? 'Mahnung' : 'Mahnungen';
    case verarbeitungTypes.WeObjektAbrechnung:
      return countEntries === 1 ? 'WE-Objektabrechnung' : 'WE-Objektabrechnungen';
    case verarbeitungTypes.WeTopAbrechnung:
      return countEntries === 1 ? 'WE-Topabrechnung' : 'WE-Topabrechnungen';
    case verarbeitungTypes.UstVomAufwand:
    case verarbeitungTypes.VorsteuerkuerzungErzeugen:
      return countEntries === 1 ? 'Berechnung' : 'Berechnungen';
    case verarbeitungTypes.VorschreibungErstellen:
      return countEntries === 1 ? 'Vorschreibung' : 'Vorschreibungen';
    case verarbeitungTypes.VorschreibungVerbuchen:
    case verarbeitungTypes.BookingSuggestionVerbuchen:
    case verarbeitungTypes.ReceivedBankTransactionVerbuchen:
      return countEntries === 1 ? 'Buchung' : 'Buchungen';
    case verarbeitungTypes.PaymentProposalCreate:
    case verarbeitungTypes.PaymentProposalExecute:
      return countEntries === 1 ? 'Zahlung' : 'Zahlungen';
    case verarbeitungTypes.HeAbrechnung:
      return countEntries === 1 ? 'Hauseigentümerabrechnung' : 'Hauseigentümerabrechnungen';
    case verarbeitungTypes.Zinsliste:
      return countEntries === 1 ? 'Zinsliste' : 'Zinslisten';
    case verarbeitungTypes.BkObjektAbrechnung:
      return countEntries === 1 ? 'BK-Objektabrechnung' : 'BK-Objektabrechnungen';
    case verarbeitungTypes.BkTopAbrechnung:
      return countEntries === 1 ? 'BK-Topabrechnung' : 'BK-Topabrechnungen';
    case verarbeitungTypes.VertragVposIndexedValueManually:
    case verarbeitungTypes.VertragVposIndexedValueAutomatically:
    case verarbeitungTypes.ApplyVertragVposIndexedValue:
      return 'Indexierte Werte';
    case verarbeitungTypes.SyncEbicsUser:
    case verarbeitungTypes.Camt:
    case verarbeitungTypes.CamtEbicsManually:
      return 'EBICS Bankteilnehmer';
    case verarbeitungTypes.CamtOnlineBankingManually:
    case verarbeitungTypes.DataCarrierPainCreation:
      return 'Datenträger';
    case verarbeitungTypes.BookingSuggestionCreation:
      return countEntries === 1 ? 'Buchungsvorschlag' : 'Buchungsvorschläge';
    case verarbeitungTypes.IncomingInvoiceBookingCreation:
      return countEntries === 1 ? 'Eingangsrechnung' : 'Eingangsrechnungen';
    case verarbeitungTypes.SubAbrechnung:
      return countEntries === 1 ? 'Subverwaltungsabrechnung' : 'Subverwaltungsabrechnungen';
    default:
      return 'something';
  }
};
