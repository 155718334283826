import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { VertragVposIndexedValueFieldsFragmentDoc } from './VertragVposIndexedValueFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateVertragVposIndexedValueMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  bestandseinheitId: Types.Scalars['String']['input'];
  vertragId: Types.Scalars['String']['input'];
  vorschreibungspositionId: Types.Scalars['String']['input'];
  indexationAgreementId: Types.Scalars['String']['input'];
  indexedValueId: Types.Scalars['String']['input'];
  input: Types.VertragVposIndexedValueUpdateInput;
}>;

export type UpdateVertragVposIndexedValueMutation = {
  updateVertragVposIndexedValue: {
    data: {
      amountNew: number;
      amountOld: number;
      bestandseinheitBezeichnung: string;
      bestandseinheitId: string;
      calculatedAmountNew: number;
      calculatedFactorNew: number;
      calculatedFactorNewPercent: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      factorNew: number;
      factorNewPercent: number;
      indexNew: number;
      indexNewPreliminary: boolean;
      indexOld: number;
      indexOldPreliminary: boolean;
      indexSeriesId: string;
      indexSeriesName: string;
      monthYearNew: string;
      monthYearOld: string;
      name: string;
      objektId: string;
      objektKurzBezeichnung: string;
      rechnungsAusstellerId: string;
      rechnungsAusstellerKurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      validFrom: string;
      vertragId: string;
      vertragKurzBezeichnung: string;
      vertragVposIndexedValueId: string;
      vertragspartnerId: string;
      vertragspartnerKurzBezeichnung: string;
      vorschreibungspositionId: string;
      vposWertNewId?: string | null;
      vposWertOldId?: string | null;
      indexationAgreement:
        | {
            __typename: 'VertragVposIndexationAgreementCategory';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            basedOnUsableArea: boolean;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'VertragVposIndexationAgreementIndexAnnual';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            annualAverage: boolean;
            minimumLimit?: number | null;
            shareInPercentToApply?: number | null;
            subsequentBilling: boolean;
            threshold: number;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            effectiveWithMonth?: { text: string; value: Types.Month } | null;
            monthOfIndexPoint: { text: string; value: Types.Month };
            subsequentBillingObjektVorschreibungsposition?: {
              bezeichnung: string;
              objektVorschreibungspositionId: string;
              kurzBezeichnung: string;
            } | null;
            thresholdType: { text: string; value: Types.ThresholdType };
          }
        | {
            __typename: 'VertragVposIndexationAgreementIndexThreshold';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            effectiveAfterMonths?: number | null;
            minimumLimit?: number | null;
            shareInPercentToApply?: number | null;
            subsequentBilling: boolean;
            threshold: number;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            subsequentBillingObjektVorschreibungsposition?: {
              bezeichnung: string;
              objektVorschreibungspositionId: string;
              kurzBezeichnung: string;
            } | null;
            thresholdType: { text: string; value: Types.ThresholdType };
          }
        | {
            __typename: 'VertragVposIndexationAgreementReferenceValue';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
      status: { text: string; value: Types.VertragVposIndexedValueStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteVertragVposIndexedValueMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  bestandseinheitId: Types.Scalars['String']['input'];
  vertragId: Types.Scalars['String']['input'];
  vorschreibungspositionId: Types.Scalars['String']['input'];
  indexationAgreementId: Types.Scalars['String']['input'];
  indexedValueId: Types.Scalars['String']['input'];
}>;

export type DeleteVertragVposIndexedValueMutation = {
  deleteVertragVposIndexedValue: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ApplyVertragVposIndexedValueMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  bestandseinheitId: Types.Scalars['String']['input'];
  vertragId: Types.Scalars['String']['input'];
  vorschreibungspositionId: Types.Scalars['String']['input'];
  indexationAgreementId: Types.Scalars['String']['input'];
  indexedValueId: Types.Scalars['String']['input'];
}>;

export type ApplyVertragVposIndexedValueMutation = {
  actionApplyVertragVposIndexedValue: {
    data: {
      amountNew: number;
      amountOld: number;
      bestandseinheitBezeichnung: string;
      bestandseinheitId: string;
      calculatedAmountNew: number;
      calculatedFactorNew: number;
      calculatedFactorNewPercent: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      factorNew: number;
      factorNewPercent: number;
      indexNew: number;
      indexNewPreliminary: boolean;
      indexOld: number;
      indexOldPreliminary: boolean;
      indexSeriesId: string;
      indexSeriesName: string;
      monthYearNew: string;
      monthYearOld: string;
      name: string;
      objektId: string;
      objektKurzBezeichnung: string;
      rechnungsAusstellerId: string;
      rechnungsAusstellerKurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      validFrom: string;
      vertragId: string;
      vertragKurzBezeichnung: string;
      vertragVposIndexedValueId: string;
      vertragspartnerId: string;
      vertragspartnerKurzBezeichnung: string;
      vorschreibungspositionId: string;
      vposWertNewId?: string | null;
      vposWertOldId?: string | null;
      indexationAgreement:
        | {
            __typename: 'VertragVposIndexationAgreementCategory';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            basedOnUsableArea: boolean;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'VertragVposIndexationAgreementIndexAnnual';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            annualAverage: boolean;
            minimumLimit?: number | null;
            shareInPercentToApply?: number | null;
            subsequentBilling: boolean;
            threshold: number;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            effectiveWithMonth?: { text: string; value: Types.Month } | null;
            monthOfIndexPoint: { text: string; value: Types.Month };
            subsequentBillingObjektVorschreibungsposition?: {
              bezeichnung: string;
              objektVorschreibungspositionId: string;
              kurzBezeichnung: string;
            } | null;
            thresholdType: { text: string; value: Types.ThresholdType };
          }
        | {
            __typename: 'VertragVposIndexationAgreementIndexThreshold';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            effectiveAfterMonths?: number | null;
            minimumLimit?: number | null;
            shareInPercentToApply?: number | null;
            subsequentBilling: boolean;
            threshold: number;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            subsequentBillingObjektVorschreibungsposition?: {
              bezeichnung: string;
              objektVorschreibungspositionId: string;
              kurzBezeichnung: string;
            } | null;
            thresholdType: { text: string; value: Types.ThresholdType };
          }
        | {
            __typename: 'VertragVposIndexationAgreementReferenceValue';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
      status: { text: string; value: Types.VertragVposIndexedValueStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DoNotApplyVertragVposIndexedValueMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  bestandseinheitId: Types.Scalars['String']['input'];
  vertragId: Types.Scalars['String']['input'];
  vorschreibungspositionId: Types.Scalars['String']['input'];
  indexationAgreementId: Types.Scalars['String']['input'];
  indexedValueId: Types.Scalars['String']['input'];
}>;

export type DoNotApplyVertragVposIndexedValueMutation = {
  actionDoNotApplyVertragVposIndexedValue: {
    data: {
      amountNew: number;
      amountOld: number;
      bestandseinheitBezeichnung: string;
      bestandseinheitId: string;
      calculatedAmountNew: number;
      calculatedFactorNew: number;
      calculatedFactorNewPercent: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      factorNew: number;
      factorNewPercent: number;
      indexNew: number;
      indexNewPreliminary: boolean;
      indexOld: number;
      indexOldPreliminary: boolean;
      indexSeriesId: string;
      indexSeriesName: string;
      monthYearNew: string;
      monthYearOld: string;
      name: string;
      objektId: string;
      objektKurzBezeichnung: string;
      rechnungsAusstellerId: string;
      rechnungsAusstellerKurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      validFrom: string;
      vertragId: string;
      vertragKurzBezeichnung: string;
      vertragVposIndexedValueId: string;
      vertragspartnerId: string;
      vertragspartnerKurzBezeichnung: string;
      vorschreibungspositionId: string;
      vposWertNewId?: string | null;
      vposWertOldId?: string | null;
      indexationAgreement:
        | {
            __typename: 'VertragVposIndexationAgreementCategory';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            basedOnUsableArea: boolean;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'VertragVposIndexationAgreementIndexAnnual';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            annualAverage: boolean;
            minimumLimit?: number | null;
            shareInPercentToApply?: number | null;
            subsequentBilling: boolean;
            threshold: number;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            effectiveWithMonth?: { text: string; value: Types.Month } | null;
            monthOfIndexPoint: { text: string; value: Types.Month };
            subsequentBillingObjektVorschreibungsposition?: {
              bezeichnung: string;
              objektVorschreibungspositionId: string;
              kurzBezeichnung: string;
            } | null;
            thresholdType: { text: string; value: Types.ThresholdType };
          }
        | {
            __typename: 'VertragVposIndexationAgreementIndexThreshold';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            effectiveAfterMonths?: number | null;
            minimumLimit?: number | null;
            shareInPercentToApply?: number | null;
            subsequentBilling: boolean;
            threshold: number;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            subsequentBillingObjektVorschreibungsposition?: {
              bezeichnung: string;
              objektVorschreibungspositionId: string;
              kurzBezeichnung: string;
            } | null;
            thresholdType: { text: string; value: Types.ThresholdType };
          }
        | {
            __typename: 'VertragVposIndexationAgreementReferenceValue';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
      status: { text: string; value: Types.VertragVposIndexedValueStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SubmitVertragVposIndexedValueMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  bestandseinheitId: Types.Scalars['String']['input'];
  vertragId: Types.Scalars['String']['input'];
  vorschreibungspositionId: Types.Scalars['String']['input'];
  indexationAgreementId: Types.Scalars['String']['input'];
  indexedValueId: Types.Scalars['String']['input'];
}>;

export type SubmitVertragVposIndexedValueMutation = {
  actionSubmitVertragVposIndexedValue: {
    data: {
      amountNew: number;
      amountOld: number;
      bestandseinheitBezeichnung: string;
      bestandseinheitId: string;
      calculatedAmountNew: number;
      calculatedFactorNew: number;
      calculatedFactorNewPercent: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      factorNew: number;
      factorNewPercent: number;
      indexNew: number;
      indexNewPreliminary: boolean;
      indexOld: number;
      indexOldPreliminary: boolean;
      indexSeriesId: string;
      indexSeriesName: string;
      monthYearNew: string;
      monthYearOld: string;
      name: string;
      objektId: string;
      objektKurzBezeichnung: string;
      rechnungsAusstellerId: string;
      rechnungsAusstellerKurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      validFrom: string;
      vertragId: string;
      vertragKurzBezeichnung: string;
      vertragVposIndexedValueId: string;
      vertragspartnerId: string;
      vertragspartnerKurzBezeichnung: string;
      vorschreibungspositionId: string;
      vposWertNewId?: string | null;
      vposWertOldId?: string | null;
      indexationAgreement:
        | {
            __typename: 'VertragVposIndexationAgreementCategory';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            basedOnUsableArea: boolean;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'VertragVposIndexationAgreementIndexAnnual';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            annualAverage: boolean;
            minimumLimit?: number | null;
            shareInPercentToApply?: number | null;
            subsequentBilling: boolean;
            threshold: number;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            effectiveWithMonth?: { text: string; value: Types.Month } | null;
            monthOfIndexPoint: { text: string; value: Types.Month };
            subsequentBillingObjektVorschreibungsposition?: {
              bezeichnung: string;
              objektVorschreibungspositionId: string;
              kurzBezeichnung: string;
            } | null;
            thresholdType: { text: string; value: Types.ThresholdType };
          }
        | {
            __typename: 'VertragVposIndexationAgreementIndexThreshold';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            effectiveAfterMonths?: number | null;
            minimumLimit?: number | null;
            shareInPercentToApply?: number | null;
            subsequentBilling: boolean;
            threshold: number;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            subsequentBillingObjektVorschreibungsposition?: {
              bezeichnung: string;
              objektVorschreibungspositionId: string;
              kurzBezeichnung: string;
            } | null;
            thresholdType: { text: string; value: Types.ThresholdType };
          }
        | {
            __typename: 'VertragVposIndexationAgreementReferenceValue';
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            indexOld?: number | null;
            indexOldPreliminary: boolean;
            monthYearOld: string;
            updatedByMitarbeiterId?: string | null;
            validFrom: string;
            vertragVposIndexationAgreementId: string;
            indexSeries: {
              chainingFactor?: number | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              csvColumnImportId?: string | null;
              csvColumnIndexPoint?: string | null;
              csvColumnMonthYear?: string | null;
              csvRowImportId?: string | null;
              deletable: boolean;
              firmendatenId?: string | null;
              importUrl?: string | null;
              indexSeriesId: string;
              name: string;
              updatedByMitarbeiterId?: string | null;
              indexSeriesEntryList: Array<{
                annualAverageFinal?: number | null;
                annualAveragePreliminary?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                indexSeriesEntryId: string;
                updatedByMitarbeiterId?: string | null;
                year: number;
                indexPointList: Array<{
                  createTs: string;
                  createdBy?: string | null;
                  createdByMitarbeiterId?: string | null;
                  finalValue?: number | null;
                  indexPointId: string;
                  month: string;
                  preliminaryValue?: number | null;
                  updatedByMitarbeiterId?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              indexType: { text: string; value: Types.IndexType };
              sourceType: { text: string; value: Types.IndexSeriesSourceType };
              status?: { text: string; value: Types.IndexSeriesStatus } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            type: { text: string; value: Types.ValuePreservationType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
      status: { text: string; value: Types.VertragVposIndexedValueStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DoNotApplyVertragVposIndexedValueFirmendatenMutationVariables = Types.Exact<{
  input: Types.VertragVposIndexedValueUpdateStatusBulkActionInput;
}>;

export type DoNotApplyVertragVposIndexedValueFirmendatenMutation = {
  actionDoNotApplyVertragVposIndexedValueFirmendaten: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SubmitVertragVposIndexedValueFirmendatenMutationVariables = Types.Exact<{
  input: Types.VertragVposIndexedValueUpdateStatusBulkActionInput;
}>;

export type SubmitVertragVposIndexedValueFirmendatenMutation = {
  actionSubmitVertragVposIndexedValueFirmendaten: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateValidFromVertragVposIndexedValueFirmendatenMutationVariables = Types.Exact<{
  input: Types.VertragVposIndexedValueUpdateValidFromBulkActionInput;
}>;

export type UpdateValidFromVertragVposIndexedValueFirmendatenMutation = {
  actionUpdateValidFromVertragVposIndexedValueFirmendaten: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UpdateVertragVposIndexedValueDocument = gql`
  mutation UpdateVertragVposIndexedValue(
    $objektId: String!
    $bestandseinheitId: String!
    $vertragId: String!
    $vorschreibungspositionId: String!
    $indexationAgreementId: String!
    $indexedValueId: String!
    $input: VertragVposIndexedValueUpdateInput!
  ) {
    updateVertragVposIndexedValue(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      indexedValueId: $indexedValueId
      input: $input
    ) {
      data {
        ...VertragVposIndexedValueFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VertragVposIndexedValueFieldsFragmentDoc}
`;
export function useUpdateVertragVposIndexedValueMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVertragVposIndexedValueMutation, UpdateVertragVposIndexedValueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVertragVposIndexedValueMutation, UpdateVertragVposIndexedValueMutationVariables>(
    UpdateVertragVposIndexedValueDocument,
    options
  );
}
export type UpdateVertragVposIndexedValueMutationHookResult = ReturnType<typeof useUpdateVertragVposIndexedValueMutation>;
export type UpdateVertragVposIndexedValueMutationResult = Apollo.MutationResult<UpdateVertragVposIndexedValueMutation>;
export type UpdateVertragVposIndexedValueMutationOptions = Apollo.BaseMutationOptions<
  UpdateVertragVposIndexedValueMutation,
  UpdateVertragVposIndexedValueMutationVariables
>;
export const DeleteVertragVposIndexedValueDocument = gql`
  mutation DeleteVertragVposIndexedValue(
    $objektId: String!
    $bestandseinheitId: String!
    $vertragId: String!
    $vorschreibungspositionId: String!
    $indexationAgreementId: String!
    $indexedValueId: String!
  ) {
    deleteVertragVposIndexedValue(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      indexedValueId: $indexedValueId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteVertragVposIndexedValueMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVertragVposIndexedValueMutation, DeleteVertragVposIndexedValueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVertragVposIndexedValueMutation, DeleteVertragVposIndexedValueMutationVariables>(
    DeleteVertragVposIndexedValueDocument,
    options
  );
}
export type DeleteVertragVposIndexedValueMutationHookResult = ReturnType<typeof useDeleteVertragVposIndexedValueMutation>;
export type DeleteVertragVposIndexedValueMutationResult = Apollo.MutationResult<DeleteVertragVposIndexedValueMutation>;
export type DeleteVertragVposIndexedValueMutationOptions = Apollo.BaseMutationOptions<
  DeleteVertragVposIndexedValueMutation,
  DeleteVertragVposIndexedValueMutationVariables
>;
export const ApplyVertragVposIndexedValueDocument = gql`
  mutation ApplyVertragVposIndexedValue(
    $objektId: String!
    $bestandseinheitId: String!
    $vertragId: String!
    $vorschreibungspositionId: String!
    $indexationAgreementId: String!
    $indexedValueId: String!
  ) {
    actionApplyVertragVposIndexedValue(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      indexedValueId: $indexedValueId
    ) {
      data {
        ...VertragVposIndexedValueFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VertragVposIndexedValueFieldsFragmentDoc}
`;
export function useApplyVertragVposIndexedValueMutation(
  baseOptions?: Apollo.MutationHookOptions<ApplyVertragVposIndexedValueMutation, ApplyVertragVposIndexedValueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApplyVertragVposIndexedValueMutation, ApplyVertragVposIndexedValueMutationVariables>(
    ApplyVertragVposIndexedValueDocument,
    options
  );
}
export type ApplyVertragVposIndexedValueMutationHookResult = ReturnType<typeof useApplyVertragVposIndexedValueMutation>;
export type ApplyVertragVposIndexedValueMutationResult = Apollo.MutationResult<ApplyVertragVposIndexedValueMutation>;
export type ApplyVertragVposIndexedValueMutationOptions = Apollo.BaseMutationOptions<
  ApplyVertragVposIndexedValueMutation,
  ApplyVertragVposIndexedValueMutationVariables
>;
export const DoNotApplyVertragVposIndexedValueDocument = gql`
  mutation DoNotApplyVertragVposIndexedValue(
    $objektId: String!
    $bestandseinheitId: String!
    $vertragId: String!
    $vorschreibungspositionId: String!
    $indexationAgreementId: String!
    $indexedValueId: String!
  ) {
    actionDoNotApplyVertragVposIndexedValue(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      indexedValueId: $indexedValueId
    ) {
      data {
        ...VertragVposIndexedValueFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VertragVposIndexedValueFieldsFragmentDoc}
`;
export function useDoNotApplyVertragVposIndexedValueMutation(
  baseOptions?: Apollo.MutationHookOptions<DoNotApplyVertragVposIndexedValueMutation, DoNotApplyVertragVposIndexedValueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DoNotApplyVertragVposIndexedValueMutation, DoNotApplyVertragVposIndexedValueMutationVariables>(
    DoNotApplyVertragVposIndexedValueDocument,
    options
  );
}
export type DoNotApplyVertragVposIndexedValueMutationHookResult = ReturnType<typeof useDoNotApplyVertragVposIndexedValueMutation>;
export type DoNotApplyVertragVposIndexedValueMutationResult = Apollo.MutationResult<DoNotApplyVertragVposIndexedValueMutation>;
export type DoNotApplyVertragVposIndexedValueMutationOptions = Apollo.BaseMutationOptions<
  DoNotApplyVertragVposIndexedValueMutation,
  DoNotApplyVertragVposIndexedValueMutationVariables
>;
export const SubmitVertragVposIndexedValueDocument = gql`
  mutation SubmitVertragVposIndexedValue(
    $objektId: String!
    $bestandseinheitId: String!
    $vertragId: String!
    $vorschreibungspositionId: String!
    $indexationAgreementId: String!
    $indexedValueId: String!
  ) {
    actionSubmitVertragVposIndexedValue(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      indexedValueId: $indexedValueId
    ) {
      data {
        ...VertragVposIndexedValueFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VertragVposIndexedValueFieldsFragmentDoc}
`;
export function useSubmitVertragVposIndexedValueMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitVertragVposIndexedValueMutation, SubmitVertragVposIndexedValueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitVertragVposIndexedValueMutation, SubmitVertragVposIndexedValueMutationVariables>(
    SubmitVertragVposIndexedValueDocument,
    options
  );
}
export type SubmitVertragVposIndexedValueMutationHookResult = ReturnType<typeof useSubmitVertragVposIndexedValueMutation>;
export type SubmitVertragVposIndexedValueMutationResult = Apollo.MutationResult<SubmitVertragVposIndexedValueMutation>;
export type SubmitVertragVposIndexedValueMutationOptions = Apollo.BaseMutationOptions<
  SubmitVertragVposIndexedValueMutation,
  SubmitVertragVposIndexedValueMutationVariables
>;
export const DoNotApplyVertragVposIndexedValueFirmendatenDocument = gql`
  mutation DoNotApplyVertragVposIndexedValueFirmendaten($input: VertragVposIndexedValueUpdateStatusBulkActionInput!) {
    actionDoNotApplyVertragVposIndexedValueFirmendaten(input: $input) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDoNotApplyVertragVposIndexedValueFirmendatenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DoNotApplyVertragVposIndexedValueFirmendatenMutation,
    DoNotApplyVertragVposIndexedValueFirmendatenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DoNotApplyVertragVposIndexedValueFirmendatenMutation, DoNotApplyVertragVposIndexedValueFirmendatenMutationVariables>(
    DoNotApplyVertragVposIndexedValueFirmendatenDocument,
    options
  );
}
export type DoNotApplyVertragVposIndexedValueFirmendatenMutationHookResult = ReturnType<
  typeof useDoNotApplyVertragVposIndexedValueFirmendatenMutation
>;
export type DoNotApplyVertragVposIndexedValueFirmendatenMutationResult = Apollo.MutationResult<DoNotApplyVertragVposIndexedValueFirmendatenMutation>;
export type DoNotApplyVertragVposIndexedValueFirmendatenMutationOptions = Apollo.BaseMutationOptions<
  DoNotApplyVertragVposIndexedValueFirmendatenMutation,
  DoNotApplyVertragVposIndexedValueFirmendatenMutationVariables
>;
export const SubmitVertragVposIndexedValueFirmendatenDocument = gql`
  mutation SubmitVertragVposIndexedValueFirmendaten($input: VertragVposIndexedValueUpdateStatusBulkActionInput!) {
    actionSubmitVertragVposIndexedValueFirmendaten(input: $input) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSubmitVertragVposIndexedValueFirmendatenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitVertragVposIndexedValueFirmendatenMutation,
    SubmitVertragVposIndexedValueFirmendatenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitVertragVposIndexedValueFirmendatenMutation, SubmitVertragVposIndexedValueFirmendatenMutationVariables>(
    SubmitVertragVposIndexedValueFirmendatenDocument,
    options
  );
}
export type SubmitVertragVposIndexedValueFirmendatenMutationHookResult = ReturnType<typeof useSubmitVertragVposIndexedValueFirmendatenMutation>;
export type SubmitVertragVposIndexedValueFirmendatenMutationResult = Apollo.MutationResult<SubmitVertragVposIndexedValueFirmendatenMutation>;
export type SubmitVertragVposIndexedValueFirmendatenMutationOptions = Apollo.BaseMutationOptions<
  SubmitVertragVposIndexedValueFirmendatenMutation,
  SubmitVertragVposIndexedValueFirmendatenMutationVariables
>;
export const UpdateValidFromVertragVposIndexedValueFirmendatenDocument = gql`
  mutation UpdateValidFromVertragVposIndexedValueFirmendaten($input: VertragVposIndexedValueUpdateValidFromBulkActionInput!) {
    actionUpdateValidFromVertragVposIndexedValueFirmendaten(input: $input) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateValidFromVertragVposIndexedValueFirmendatenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateValidFromVertragVposIndexedValueFirmendatenMutation,
    UpdateValidFromVertragVposIndexedValueFirmendatenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateValidFromVertragVposIndexedValueFirmendatenMutation,
    UpdateValidFromVertragVposIndexedValueFirmendatenMutationVariables
  >(UpdateValidFromVertragVposIndexedValueFirmendatenDocument, options);
}
export type UpdateValidFromVertragVposIndexedValueFirmendatenMutationHookResult = ReturnType<
  typeof useUpdateValidFromVertragVposIndexedValueFirmendatenMutation
>;
export type UpdateValidFromVertragVposIndexedValueFirmendatenMutationResult =
  Apollo.MutationResult<UpdateValidFromVertragVposIndexedValueFirmendatenMutation>;
export type UpdateValidFromVertragVposIndexedValueFirmendatenMutationOptions = Apollo.BaseMutationOptions<
  UpdateValidFromVertragVposIndexedValueFirmendatenMutation,
  UpdateValidFromVertragVposIndexedValueFirmendatenMutationVariables
>;
