import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { KontoAbrechnungskreisFieldsFragmentDoc } from '../AbrDefFragments.types';
import { TextbausteinFieldsFragmentDoc } from '../../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBkAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  input: Types.BkAbrechnungsdefinitionVersionCreateInput;
}>;

export type CreateBkAbrechnungsdefinitionVersionMutation = {
  createBkAbrechnungsdefinitionVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      objektAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      topAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBkAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
  input: Types.BkAbrechnungsdefinitionVersionUpdateInput;
}>;

export type UpdateBkAbrechnungsdefinitionVersionMutation = {
  updateBkAbrechnungsdefinitionVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      objektAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      topAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBkAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteBkAbrechnungsdefinitionVersionMutation = {
  deleteBkAbrechnungsdefinitionVersion: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateBkAbrechnungsdefinitionVersionDocument = gql`
  mutation CreateBkAbrechnungsdefinitionVersion($abrechnungsdefinitionId: ID!, $input: BkAbrechnungsdefinitionVersionCreateInput!) {
    createBkAbrechnungsdefinitionVersion(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $input) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        objektAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        topAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useCreateBkAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBkAbrechnungsdefinitionVersionMutation, CreateBkAbrechnungsdefinitionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBkAbrechnungsdefinitionVersionMutation, CreateBkAbrechnungsdefinitionVersionMutationVariables>(
    CreateBkAbrechnungsdefinitionVersionDocument,
    options
  );
}
export type CreateBkAbrechnungsdefinitionVersionMutationHookResult = ReturnType<typeof useCreateBkAbrechnungsdefinitionVersionMutation>;
export type CreateBkAbrechnungsdefinitionVersionMutationResult = Apollo.MutationResult<CreateBkAbrechnungsdefinitionVersionMutation>;
export type CreateBkAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateBkAbrechnungsdefinitionVersionMutation,
  CreateBkAbrechnungsdefinitionVersionMutationVariables
>;
export const UpdateBkAbrechnungsdefinitionVersionDocument = gql`
  mutation UpdateBkAbrechnungsdefinitionVersion(
    $abrechnungsdefinitionId: ID!
    $abrechnungsdefinitionVersionId: ID!
    $input: BkAbrechnungsdefinitionVersionUpdateInput!
  ) {
    updateBkAbrechnungsdefinitionVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
      input: $input
    ) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        objektAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        topAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useUpdateBkAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBkAbrechnungsdefinitionVersionMutation, UpdateBkAbrechnungsdefinitionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBkAbrechnungsdefinitionVersionMutation, UpdateBkAbrechnungsdefinitionVersionMutationVariables>(
    UpdateBkAbrechnungsdefinitionVersionDocument,
    options
  );
}
export type UpdateBkAbrechnungsdefinitionVersionMutationHookResult = ReturnType<typeof useUpdateBkAbrechnungsdefinitionVersionMutation>;
export type UpdateBkAbrechnungsdefinitionVersionMutationResult = Apollo.MutationResult<UpdateBkAbrechnungsdefinitionVersionMutation>;
export type UpdateBkAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateBkAbrechnungsdefinitionVersionMutation,
  UpdateBkAbrechnungsdefinitionVersionMutationVariables
>;
export const DeleteBkAbrechnungsdefinitionVersionDocument = gql`
  mutation DeleteBkAbrechnungsdefinitionVersion($abrechnungsdefinitionId: ID!, $abrechnungsdefinitionVersionId: ID!) {
    deleteBkAbrechnungsdefinitionVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBkAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBkAbrechnungsdefinitionVersionMutation, DeleteBkAbrechnungsdefinitionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBkAbrechnungsdefinitionVersionMutation, DeleteBkAbrechnungsdefinitionVersionMutationVariables>(
    DeleteBkAbrechnungsdefinitionVersionDocument,
    options
  );
}
export type DeleteBkAbrechnungsdefinitionVersionMutationHookResult = ReturnType<typeof useDeleteBkAbrechnungsdefinitionVersionMutation>;
export type DeleteBkAbrechnungsdefinitionVersionMutationResult = Apollo.MutationResult<DeleteBkAbrechnungsdefinitionVersionMutation>;
export type DeleteBkAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteBkAbrechnungsdefinitionVersionMutation,
  DeleteBkAbrechnungsdefinitionVersionMutationVariables
>;
