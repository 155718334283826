import { defaultTo, isEmpty } from 'lodash';
import { v4 as uuid4 } from 'uuid';
import { Dayjs } from 'dayjs';
import { mapDateStringToFormDateValue } from '../../../../../helpers/formikHelper';
import { BeAufteilingsschluesselWert, BeAufteilingsschluesselWertVerwaltungSeitRelation } from '../../../../../types';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';

export const initialValuesBestandseinheitAufteilungsschluesselWert: BeAufteilingsschluesselWert = {
  aktiv: false,
  beAufteilungsschluesselWertId: '',
  current: false,
  geloescht: false,
  validFrom: dayjsCustom().format('YYYY-MM-DD'),
  verwaltungSeitRelation: {
    text: '',
    value: BeAufteilingsschluesselWertVerwaltungSeitRelation.NachVerwaltungSeit,
  },
  warningList: [],
  wert: 0,
};

export const createValidFromForNewEntry = (wertList: BeAufteilingsschluesselWert[]): string => {
  return findNextValidFromForCreate(wertList).format('YYYY-MM-DD');
};

/**
 * Returns
 *  - next available day if latest valid ab of aufteilungsschluesselWertList is today or later
 *  - today if
 *    - latest valid ab of aufteilungsschluesselWertList is before today or
 *    - aufteilungsschluesselWertList is empty
 *
 *  Reason: the user can only edit (create, update, delete) the last entry
 */
const findNextValidFromForCreate = (aufteilungsschluesselWertList: BeAufteilingsschluesselWert[]) => {
  const latestValidFrom = findLatestValidFrom(aufteilungsschluesselWertList);
  return latestValidFrom && latestValidFrom.isSameOrAfter(dayjsCustom(), 'day') ? latestValidFrom.add(1, 'day') : dayjsCustom();
};

export const findLatestValidFrom = (aufteilungsschluesselWertList: BeAufteilingsschluesselWert[]) => {
  const validFromList = aufteilungsschluesselWertList
    .filter((wert) => wert.beAufteilungsschluesselWertId)
    .map((wert) => (wert.validFrom ? mapDateStringToFormDateValue(wert.validFrom) : null))
    .filter((validFromOrNull): validFromOrNull is Dayjs => !!validFromOrNull);
  return !isEmpty(validFromList) ? dayjsCustom.max(validFromList) : null;
};

export const bestandseinheitAufteilungsschluesselEditableTableGetRowKey = (record: BeAufteilingsschluesselWert) =>
  defaultTo(record.beAufteilungsschluesselWertId, defaultTo(record.validFrom, uuid4()));
