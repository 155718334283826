import React, { FC } from 'react';
import { List, Skeleton } from 'antd';
import {
  getBuchungszeilenAnzeigenText,
  getVerwendungVonRepFondsText,
  isZuordnungKontoKlasseTypeEinnahmen,
  zuordnungFormFieldArrayListItemStyle,
  ZuordnungKontoKlasseType,
  ZuordnungViewModel,
} from '../shared/AbrKreisKontenzuordnungHelpers';
import { AbrechnungsdefinitionType } from '../../../types';
import {
  isAbrKreisTypeBewirtschaftungskosten,
  isAbrKreisTypeRepFonds,
} from '../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { AbrKontenzuordnungFiltersFormValues } from '../Listing/Filters/abrKontenzuordnungFiltersMapper';
import ZuordnungKontoItemContentViewAndEdit from './ZuordnungKontoItemContentViewAndEdit';

type Props = {
  zuordnung: ZuordnungViewModel;
  kontoKlasseType: ZuordnungKontoKlasseType;
  abrDefinitionType: AbrechnungsdefinitionType;
  filters?: AbrKontenzuordnungFiltersFormValues | null;
  loading?: boolean;
};

const ZuordnungKontoListingItemViewAndEdit: FC<Props> = ({ zuordnung, kontoKlasseType, abrDefinitionType, filters, loading = false }) => {
  const filterInUseAsNumber = filters ? Object.keys(filters).length : 0;
  const isActiveBuchungszeilen = filters?.buchungszeilenAnzeigen === getBuchungszeilenAnzeigenText(zuordnung.buchungszeilenAnzeigen);
  const isActiveRepFonds =
    zuordnung.abrechnungskreis && isAbrKreisTypeRepFonds(zuordnung.abrechnungskreis.type.value)
      ? filters?.verwendungVonRepFonds === getVerwendungVonRepFondsText(zuordnung.ustVomAufwand)
      : false;
  const isActiveDivergentDistributionKey =
    !!zuordnung.aufteilungsschluessel &&
    zuordnung.aufteilungsschluessel.aufteilungsschluesselId === filters?.displayAbweichenderAufteilungsschluesselId;

  const isActiveExpenseReducing =
    !!zuordnung.abrechnungskreis &&
    isAbrKreisTypeBewirtschaftungskosten(zuordnung.abrechnungskreis.type.value) &&
    isZuordnungKontoKlasseTypeEinnahmen(kontoKlasseType) &&
    zuordnung.expenseReducing === filters?.displayExpenseReducing;

  return (
    <List.Item
      style={zuordnungFormFieldArrayListItemStyle(
        filterInUseAsNumber,
        isActiveBuchungszeilen,
        isActiveRepFonds,
        isActiveDivergentDistributionKey,
        isActiveExpenseReducing
      )}
    >
      <Skeleton paragraph={false} active loading={loading}>
        <ZuordnungKontoItemContentViewAndEdit
          zuordnung={zuordnung}
          kontoKlasseType={kontoKlasseType}
          abrDefinitionType={abrDefinitionType}
          filters={filters}
        />
      </Skeleton>
    </List.Item>
  );
};

export default ZuordnungKontoListingItemViewAndEdit;
