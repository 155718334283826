import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBudgetingAbrDefVPosLinkageTemplateMutationVariables = Types.Exact<{
  input: Types.BudgetingAbrDefVPosLinkageCreateInput;
}>;

export type CreateBudgetingAbrDefVPosLinkageTemplateMutation = {
  createBudgetingAbrDefVPosLinkageTemplate: {
    data: { budgetingAbrDefVPosLinkageId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBudgetingAbrDefVPosLinkageTemplateMutationVariables = Types.Exact<{
  budgetingAbrDefVPosLinkageId: Types.Scalars['ID']['input'];
  input: Types.BudgetingAbrDefVPosLinkageUpdateInput;
}>;

export type UpdateBudgetingAbrDefVPosLinkageTemplateMutation = {
  updateBudgetingAbrDefVPosLinkageTemplate: {
    data: { budgetingAbrDefVPosLinkageId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBudgetingAbrDefVPosLinkageTemplateMutationVariables = Types.Exact<{
  budgetingAbrDefVPosLinkageId: Types.Scalars['ID']['input'];
}>;

export type DeleteBudgetingAbrDefVPosLinkageTemplateMutation = {
  deleteBudgetingAbrDefVPosLinkageTemplate: {
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateBudgetingAbrDefVPosLinkageTemplateDocument = gql`
  mutation CreateBudgetingAbrDefVPosLinkageTemplate($input: BudgetingAbrDefVPosLinkageCreateInput!) {
    createBudgetingAbrDefVPosLinkageTemplate(input: $input) {
      data {
        budgetingAbrDefVPosLinkageId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateBudgetingAbrDefVPosLinkageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBudgetingAbrDefVPosLinkageTemplateMutation,
    CreateBudgetingAbrDefVPosLinkageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBudgetingAbrDefVPosLinkageTemplateMutation, CreateBudgetingAbrDefVPosLinkageTemplateMutationVariables>(
    CreateBudgetingAbrDefVPosLinkageTemplateDocument,
    options
  );
}
export type CreateBudgetingAbrDefVPosLinkageTemplateMutationHookResult = ReturnType<typeof useCreateBudgetingAbrDefVPosLinkageTemplateMutation>;
export type CreateBudgetingAbrDefVPosLinkageTemplateMutationResult = Apollo.MutationResult<CreateBudgetingAbrDefVPosLinkageTemplateMutation>;
export type CreateBudgetingAbrDefVPosLinkageTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateBudgetingAbrDefVPosLinkageTemplateMutation,
  CreateBudgetingAbrDefVPosLinkageTemplateMutationVariables
>;
export const UpdateBudgetingAbrDefVPosLinkageTemplateDocument = gql`
  mutation UpdateBudgetingAbrDefVPosLinkageTemplate($budgetingAbrDefVPosLinkageId: ID!, $input: BudgetingAbrDefVPosLinkageUpdateInput!) {
    updateBudgetingAbrDefVPosLinkageTemplate(budgetingAbrDefVPosLinkageId: $budgetingAbrDefVPosLinkageId, input: $input) {
      data {
        budgetingAbrDefVPosLinkageId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateBudgetingAbrDefVPosLinkageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBudgetingAbrDefVPosLinkageTemplateMutation,
    UpdateBudgetingAbrDefVPosLinkageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBudgetingAbrDefVPosLinkageTemplateMutation, UpdateBudgetingAbrDefVPosLinkageTemplateMutationVariables>(
    UpdateBudgetingAbrDefVPosLinkageTemplateDocument,
    options
  );
}
export type UpdateBudgetingAbrDefVPosLinkageTemplateMutationHookResult = ReturnType<typeof useUpdateBudgetingAbrDefVPosLinkageTemplateMutation>;
export type UpdateBudgetingAbrDefVPosLinkageTemplateMutationResult = Apollo.MutationResult<UpdateBudgetingAbrDefVPosLinkageTemplateMutation>;
export type UpdateBudgetingAbrDefVPosLinkageTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateBudgetingAbrDefVPosLinkageTemplateMutation,
  UpdateBudgetingAbrDefVPosLinkageTemplateMutationVariables
>;
export const DeleteBudgetingAbrDefVPosLinkageTemplateDocument = gql`
  mutation DeleteBudgetingAbrDefVPosLinkageTemplate($budgetingAbrDefVPosLinkageId: ID!) {
    deleteBudgetingAbrDefVPosLinkageTemplate(budgetingAbrDefVPosLinkageId: $budgetingAbrDefVPosLinkageId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteBudgetingAbrDefVPosLinkageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBudgetingAbrDefVPosLinkageTemplateMutation,
    DeleteBudgetingAbrDefVPosLinkageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBudgetingAbrDefVPosLinkageTemplateMutation, DeleteBudgetingAbrDefVPosLinkageTemplateMutationVariables>(
    DeleteBudgetingAbrDefVPosLinkageTemplateDocument,
    options
  );
}
export type DeleteBudgetingAbrDefVPosLinkageTemplateMutationHookResult = ReturnType<typeof useDeleteBudgetingAbrDefVPosLinkageTemplateMutation>;
export type DeleteBudgetingAbrDefVPosLinkageTemplateMutationResult = Apollo.MutationResult<DeleteBudgetingAbrDefVPosLinkageTemplateMutation>;
export type DeleteBudgetingAbrDefVPosLinkageTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteBudgetingAbrDefVPosLinkageTemplateMutation,
  DeleteBudgetingAbrDefVPosLinkageTemplateMutationVariables
>;
