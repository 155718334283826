import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import { getWizardDetailsByUri } from '../../features/Wizard/wizardHelpers';
import { URI_WIZARD_PAGE } from '../../features/Wizard/wizardApiPaths';

type RouteParams = { type: string; wizardId: string; step: string };

const WizardUpdatePage = () => {
  const navigate = useNavigate();
  const { type, wizardId, step } = useParams() as RouteParams;

  const wizardType = getWizardDetailsByUri(type);
  const WizardPage = wizardType?.page;

  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    const stepNumber = Number((step ?? 'step1').replace('step', ''));
    setActiveStep(stepNumber);

    return () => {
      setActiveStep(1);
    };
  }, [step]);

  return (
    <>
      <Helmet>
        <title>Assistent</title>
      </Helmet>
      <PageLayout>{WizardPage && <WizardPage wizardId={wizardId} activeStep={activeStep} onCancel={() => navigate(URI_WIZARD_PAGE)} />}</PageLayout>
    </>
  );
};

export default WizardUpdatePage;
