import React from 'react';
import styled from 'styled-components';
import { Table, TableProps } from 'antd';

export const StyledTableWithPagination = styled(Table)`
  .ant-pagination-total-text {
    order: 5;
    flex-basis: 100%;
    text-align: right;
    margin: 0;
  }

  .row-with-deviations {
    background: #fff1f0;
  }
` as <T>(props: TableProps<T>) => React.ReactElement;
