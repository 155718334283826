import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartUstVomAufwandGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartUstVomAufwandGenerierlaufMutation = {
  actionRestartUstVomAufwandGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateUstVomAufwandGenerierlaufMutationVariables = Types.Exact<{
  request: Types.UstVomAufwandGenerierlaufInput;
}>;

export type CreateUstVomAufwandGenerierlaufMutation = {
  createUstVomAufwandGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartUstVomAufwandGenerierlaufDocument = gql`
  mutation RestartUstVomAufwandGenerierlauf($generierlaufId: ID!) {
    actionRestartUstVomAufwandGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartUstVomAufwandGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartUstVomAufwandGenerierlaufMutation, RestartUstVomAufwandGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartUstVomAufwandGenerierlaufMutation, RestartUstVomAufwandGenerierlaufMutationVariables>(
    RestartUstVomAufwandGenerierlaufDocument,
    options
  );
}
export type RestartUstVomAufwandGenerierlaufMutationHookResult = ReturnType<typeof useRestartUstVomAufwandGenerierlaufMutation>;
export type RestartUstVomAufwandGenerierlaufMutationResult = Apollo.MutationResult<RestartUstVomAufwandGenerierlaufMutation>;
export type RestartUstVomAufwandGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartUstVomAufwandGenerierlaufMutation,
  RestartUstVomAufwandGenerierlaufMutationVariables
>;
export const CreateUstVomAufwandGenerierlaufDocument = gql`
  mutation CreateUstVomAufwandGenerierlauf($request: UstVomAufwandGenerierlaufInput!) {
    createUstVomAufwandGenerierlauf(request: $request) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateUstVomAufwandGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUstVomAufwandGenerierlaufMutation, CreateUstVomAufwandGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUstVomAufwandGenerierlaufMutation, CreateUstVomAufwandGenerierlaufMutationVariables>(
    CreateUstVomAufwandGenerierlaufDocument,
    options
  );
}
export type CreateUstVomAufwandGenerierlaufMutationHookResult = ReturnType<typeof useCreateUstVomAufwandGenerierlaufMutation>;
export type CreateUstVomAufwandGenerierlaufMutationResult = Apollo.MutationResult<CreateUstVomAufwandGenerierlaufMutation>;
export type CreateUstVomAufwandGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateUstVomAufwandGenerierlaufMutation,
  CreateUstVomAufwandGenerierlaufMutationVariables
>;
