import React, { FC, PropsWithChildren } from 'react';
import { Col, Row } from 'antd';
import { StyledSkeleton, StyledSkeletonLayout } from './Styled/Skeleton.style';
import { ContentMain } from '../LayoutAndromeda/Styled/Layout.style';

export type LoadingDetailsPageProps = {
  loading: boolean;
};

/**
 * <h2>Usage</h2>
 * Used when a details page is being loaded
 *
 */
export const LoadingDetailsPage: FC<LoadingDetailsPageProps & PropsWithChildren> = ({ loading, children }) => {
  if (loading) {
    return (
      <>
        <ContentMain>
          <Row gutter={16}>
            <Col span={6}>
              <StyledSkeleton loading={loading} active paragraph={{ rows: 4 }} />
            </Col>
            <Col span={18}>
              <StyledSkeletonLayout loading={loading} active paragraph={{ rows: 4 }} />
            </Col>
          </Row>
        </ContentMain>
      </>
    );
  }
  return <>{children}</>;
};
