import { FibuBuchungsanweisungStatus } from '../../../types';
import { BookingInstructionFormValues } from '../Form/bookingInstructionFormMapper';

export const isFibuBuchungsanweisungVerbucht = (status: FibuBuchungsanweisungStatus) => status === FibuBuchungsanweisungStatus.Verbucht;
export const isFibuBuchungsanweisungOffen = (status: FibuBuchungsanweisungStatus) => status === FibuBuchungsanweisungStatus.Offen;

export const isBuchungskreisChosen = (formikValues: BookingInstructionFormValues) => Boolean(formikValues.buchungskreisRechtstraegerId);
export const isBelegSymbolChosen = (formikValues: BookingInstructionFormValues) => Boolean(formikValues.buchungsKopf?.belegSymbol);
export const isBuchungTypeChosen = (formikValues: BookingInstructionFormValues) => Boolean(formikValues.buchungsKopf?.buchungsType);
export const isVertragspartnerChosen = (formikValues: BookingInstructionFormValues) => Boolean(formikValues.buchungsKopf?.vertragspartnerId);
