import React, { FC } from 'react';
import { Divider, Space, Typography } from 'antd';
import { Switch } from 'formik-antd';
import {
  isZuordnungKontoKlasseTypeAusgaben,
  isZuordnungKontoKlasseTypeEinnahmen,
  ZuordnungKontoKlasseType,
} from '../../shared/AbrKreisKontenzuordnungHelpers';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import AbrKontenzuordnungAufteilungsschluesselSelect from '../../shared/AbrKontenzuordnungAufteilungsschluesselSelect';
import { AbrechdefAbrechkreis } from '../../../../types';

type Props = {
  isAbrKreisBewirtschaftungskosten: boolean;
  zuordnungKontoKlasseType: ZuordnungKontoKlasseType;
  nameForAufteilungsschluessel: string;
  nameForAufteilungsschluesselBezeichnung: string;
  nameForExpenseReducing: string;
  expenseReducing: boolean;
  abrechnungskreisId: string;
  abrDefVersionAbrechkreisList: AbrechdefAbrechkreis[];
};

const BewirtschaftungskostenFormItems: FC<Props> = ({
  isAbrKreisBewirtschaftungskosten,
  nameForAufteilungsschluesselBezeichnung,
  abrDefVersionAbrechkreisList,
  zuordnungKontoKlasseType,
  nameForAufteilungsschluessel,
  abrechnungskreisId,
  nameForExpenseReducing,
  expenseReducing,
}) => {
  return (
    <>
      <Divider>Bewirtschaftungskosten</Divider>
      {isZuordnungKontoKlasseTypeAusgaben(zuordnungKontoKlasseType) && (
        <FormItemWithoutColon name={nameForAufteilungsschluessel} label="Abweichender Aufteilungschlüssel">
          <AbrKontenzuordnungAufteilungsschluesselSelect
            name={nameForAufteilungsschluessel}
            nameForAufteilungsschluesselBezeichnung={nameForAufteilungsschluesselBezeichnung}
            abrechnungskreisId={abrechnungskreisId}
            abrDefVersionAbrechkreisList={abrDefVersionAbrechkreisList}
          />
        </FormItemWithoutColon>
      )}
      {isZuordnungKontoKlasseTypeEinnahmen(zuordnungKontoKlasseType) && (
        <FormItemWithoutColon name={nameForExpenseReducing} label="Aufwandsmindernd">
          <Space>
            <Switch name={nameForExpenseReducing} size="small" />
            <Typography.Text>{expenseReducing ? 'Ja' : 'Nein'}</Typography.Text>
          </Space>
        </FormItemWithoutColon>
      )}
    </>
  );
};

export default BewirtschaftungskostenFormItems;
