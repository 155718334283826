import React, { FC } from 'react';
import { Empty } from 'antd';
import { BudgetingBase } from '../../../../types';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import budgetingTableColumns from './budgetingTableColumns';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE } from '../../../../hooks/useQueryWithPagingAndSorting';

type Props = {
  budgetingList: BudgetingBase[];
  objektId: string;
  loading: boolean;
};

const BudgetingTable: FC<Props> = ({ budgetingList, objektId, loading }) => (
  <TableWithColSelector<BudgetingBase>
    size="small"
    locale={{
      emptyText: <Empty description={<span>Keine Budgetierung vorhanden</span>} />,
    }}
    loading={loading}
    dataSource={budgetingList}
    columns={budgetingTableColumns(objektId)}
    rowKey={(record) => record.budgetingId}
    filterIdentifier="budgeting-list"
    pagination={{
      showQuickJumper: true,
      showSizeChanger: true,
      current: DEFAULT_CURRENT,
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizeOptions: [10, 20, 30, 50, 100],
    }}
  />
);
export default BudgetingTable;
