import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useIbanFibuKontoIbanListQuery } from '../gql/IbanFibuKontoQueries.types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = Omit<SelectProps, 'id' | 'size' | 'placeholder' | 'filterOption'>;

const IbanListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useIbanFibuKontoIbanListQuery();
  const list = data?.getIbanFibuKontoIbanList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="IBAN auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((iban) => (
        <Select.Option key={iban} value={iban}>
          <DataWithShortenedText maxTextLength={40} text={friendlyFormatIBAN(iban) as string} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default IbanListFormSelect;
