import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBkAbrechnungsdefinitionTemplateMutationVariables = Types.Exact<{
  input: Types.BkAbrechnungsdefinitionCreateInput;
}>;

export type CreateBkAbrechnungsdefinitionTemplateMutation = {
  createBkAbrechnungsdefinitionTemplate: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBkAbrechnungsdefinitionTemplateMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  input: Types.BkAbrechnungsdefinitionUpdateInput;
}>;

export type UpdateBkAbrechnungsdefinitionTemplateMutation = {
  updateBkAbrechnungsdefinitionTemplate: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateBkAbrechnungsdefinitionTemplateDocument = gql`
  mutation CreateBkAbrechnungsdefinitionTemplate($input: BkAbrechnungsdefinitionCreateInput!) {
    createBkAbrechnungsdefinitionTemplate(input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBkAbrechnungsdefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBkAbrechnungsdefinitionTemplateMutation, CreateBkAbrechnungsdefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBkAbrechnungsdefinitionTemplateMutation, CreateBkAbrechnungsdefinitionTemplateMutationVariables>(
    CreateBkAbrechnungsdefinitionTemplateDocument,
    options
  );
}
export type CreateBkAbrechnungsdefinitionTemplateMutationHookResult = ReturnType<typeof useCreateBkAbrechnungsdefinitionTemplateMutation>;
export type CreateBkAbrechnungsdefinitionTemplateMutationResult = Apollo.MutationResult<CreateBkAbrechnungsdefinitionTemplateMutation>;
export type CreateBkAbrechnungsdefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateBkAbrechnungsdefinitionTemplateMutation,
  CreateBkAbrechnungsdefinitionTemplateMutationVariables
>;
export const UpdateBkAbrechnungsdefinitionTemplateDocument = gql`
  mutation UpdateBkAbrechnungsdefinitionTemplate($abrechnungsdefinitionId: ID!, $input: BkAbrechnungsdefinitionUpdateInput!) {
    updateBkAbrechnungsdefinitionTemplate(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateBkAbrechnungsdefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBkAbrechnungsdefinitionTemplateMutation, UpdateBkAbrechnungsdefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBkAbrechnungsdefinitionTemplateMutation, UpdateBkAbrechnungsdefinitionTemplateMutationVariables>(
    UpdateBkAbrechnungsdefinitionTemplateDocument,
    options
  );
}
export type UpdateBkAbrechnungsdefinitionTemplateMutationHookResult = ReturnType<typeof useUpdateBkAbrechnungsdefinitionTemplateMutation>;
export type UpdateBkAbrechnungsdefinitionTemplateMutationResult = Apollo.MutationResult<UpdateBkAbrechnungsdefinitionTemplateMutation>;
export type UpdateBkAbrechnungsdefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateBkAbrechnungsdefinitionTemplateMutation,
  UpdateBkAbrechnungsdefinitionTemplateMutationVariables
>;
