import React, { FC } from 'react';
import { List, Skeleton } from 'antd';
import {
  zuordnungFormFieldArrayListItemStyle,
  ZuordnungKontoKlasseType,
  ZuordnungViewModel,
} from '../../../../AbrKontenzuordnung/shared/AbrKreisKontenzuordnungHelpers';
import { AbrechnungsdefinitionType } from '../../../../../types';
import { getInformationNumber } from './onlyViewHelpers';
import ZuordnungKontoItemContentOnlyView from './ZuordnungKontoItemContentOnlyView';

type Props = {
  zuordnung: ZuordnungViewModel;
  kontoKlasseType: ZuordnungKontoKlasseType;
  abrDefinitionType: AbrechnungsdefinitionType;
  loading?: boolean;
};

const ZuordnungKontoListingItemOnlyView: FC<Props> = ({ zuordnung, kontoKlasseType, abrDefinitionType, loading = false }) => (
  <List.Item style={zuordnungFormFieldArrayListItemStyle(getInformationNumber(zuordnung, kontoKlasseType))}>
    <Skeleton paragraph={false} active loading={loading}>
      <ZuordnungKontoItemContentOnlyView zuordnung={zuordnung} kontoKlasseType={kontoKlasseType} abrDefinitionType={abrDefinitionType} />
    </Skeleton>
  </List.Item>
);

export default ZuordnungKontoListingItemOnlyView;
