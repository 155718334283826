import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Empty } from 'antd';
import { VstKuerzungVerarbeitungEntryFiltersFormValues } from './Filters/ListingFiltersFormMapper';
import GenerierlaufStatusExpandedRow from '../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../verarbeitungHelpers';
import vstKuerzungVerarbeitungEntryTableColumns from './vstKuerzungVerarbeitungEntryTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { VorsteuerkuerzungGenerierlaufEntry } from '../../../../types';

type VstKuerzungVerarbeitungEntryTableProps = {
  entryList: VorsteuerkuerzungGenerierlaufEntry[];
  formikProps: FormikProps<VstKuerzungVerarbeitungEntryFiltersFormValues>;
};

const VstKuerzungVerarbeitungEntryTable: FC<VstKuerzungVerarbeitungEntryTableProps> = ({ entryList, formikProps }) => {
  const { objektId, buchungStatus } = formikProps.values;

  const filterForObjektId = (entry: VorsteuerkuerzungGenerierlaufEntry) => (objektId ? entry.objekt.objektId === objektId : entry);

  const filterForBuchungStatus = (entry: VorsteuerkuerzungGenerierlaufEntry) => {
    if (buchungStatus && buchungStatus.length > 0) {
      return entry.vorsteuerkuerzung ? buchungStatus.includes(entry.vorsteuerkuerzung.buchungStatus.value) : false;
    }
    return entry;
  };

  const filteredEntryList = entryList.filter(filterForObjektId).filter(filterForBuchungStatus);

  return (
    <TableWithColSelector<VorsteuerkuerzungGenerierlaufEntry>
      loading={!entryList}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      size="small"
      dataSource={filteredEntryList}
      columns={vstKuerzungVerarbeitungEntryTableColumns}
      pagination={{ defaultPageSize: 20, showSizeChanger: true }}
      rowKey={(record) => record.generierlaufEntryId}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-vorsteuerkuerzung"
    />
  );
};

export default VstKuerzungVerarbeitungEntryTable;
