import React, { FC } from 'react';
import { Box } from 'rebass';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Divider } from 'antd';
import { getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../components/Timeline/timelineCtx';
import {
  mapFormValuesToVorschreibungspositionVersion,
  mapVorschreibungspositionTimeblockToFormValues,
  vorschreibungspositionVersionFormFields,
  VorschreibungspositionVersionFormValues,
  vorschreibungspositionVersionFormValuesDescriptionsForKommentar,
} from './vorschreibungspositionVersionFormMapper';
import { layoutTimelineForm } from '../../../../components/Input-antd/formLayoutHelper';
import TimelineFormDatePicker from '../../../../components/Timeline/Form/TimelineFormDatePicker';
import TimelineFormButtons from '../../../../components/Timeline/Form/TimelineFormButtons';
import { vorschreibungspositionVersionFormValidationSchema } from './vorschreibungspositionVersionFormValidationSchema';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../components/message';
import UmsatzsteuerkennzeichenSelect from '../../../shared/Umsatzsteuerkennzeichen/UmsatzsteuerkennzeichenSelect';
import {
  useCreateVorschreibungspositionVersionMutation,
  useUpdateVorschreibungspositionVersionMutation,
} from '../gql/vorschreibungspositionVersionMutations.types';
import KontierungstabelleSelect from '../../../shared/KontierungstabelleSelect';
import TimelineFormKommentar from '../../../../components/Timeline/Form/TimelineFormKommentar';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { VorschreibungspositionVersion } from '../../../../types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  vorschreibungspositionId: string;
  firmendatenId?: string | null;
  timeblock: ITimeblock<VorschreibungspositionVersion>;
  onSubmitSuccess: () => void;
};

const VorschreibungspositionVersionForm: FC<Props> = ({ vorschreibungspositionId, firmendatenId, timeblock, onSubmitSuccess }) => {
  const { dataSource, toggleEdit, changeToUpdateMode } = useTimeline<VorschreibungspositionVersion>();

  const isUpdate = !!timeblock.vorschreibungspositionVersionId;

  const entity = 'Vorschreibungsposition';
  const getFieldHelpText = useGetAppFieldHelpText<'VorschreibungspositionVersion'>('VorschreibungspositionVersion');

  const [runUpdate, { loading: loadingUpdate }] = useUpdateVorschreibungspositionVersionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      toggleEdit(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreate, { loading: loadingCreate }] = useCreateVorschreibungspositionVersionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      changeToUpdateMode(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<VorschreibungspositionVersionFormValues>
      initialValues={mapVorschreibungspositionTimeblockToFormValues(timeblock)}
      validationSchema={vorschreibungspositionVersionFormValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (isUpdate) {
          runUpdate({
            variables: {
              vorschreibungspositionId,
              vorschreibungspositionVersionId: timeblock.vorschreibungspositionVersionId,
              input: mapFormValuesToVorschreibungspositionVersion(formValues),
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({
            variables: { vorschreibungspositionId, input: mapFormValuesToVorschreibungspositionVersion(formValues) },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form {...layoutTimelineForm} layout="horizontal" labelAlign="left">
          <Box width={2 / 3}>
            <FormItemWithFieldHelp
              name={vorschreibungspositionVersionFormFields.validFrom}
              label="Gültig ab"
              fieldHelp={getFieldHelpText('VorschreibungspositionVersion.validFrom')}
            >
              <TimelineFormDatePicker<VorschreibungspositionVersion>
                id={vorschreibungspositionVersionFormFields.validFrom}
                name={vorschreibungspositionVersionFormFields.validFrom}
                timeblock={timeblock}
              />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={vorschreibungspositionVersionFormFields.umsatzsteuerkennzeichenId}
              label="Umsatzsteuerkennzeichen"
              fieldHelp={getFieldHelpText('VorschreibungspositionVersion.umsatzsteuerkennzeichenId')}
            >
              <UmsatzsteuerkennzeichenSelect name={vorschreibungspositionVersionFormFields.umsatzsteuerkennzeichenId} />
            </FormItemWithFieldHelp>

            <FormItemWithFieldHelp
              name={vorschreibungspositionVersionFormFields.kontierungstabelleId}
              label="Kontierungstabelle"
              fieldHelp={getFieldHelpText('VorschreibungspositionVersion.kontierungstabelleId')}
            >
              <KontierungstabelleSelect name={vorschreibungspositionVersionFormFields.kontierungstabelleId} firmendatenId={firmendatenId} />
            </FormItemWithFieldHelp>
          </Box>

          <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />

          <TimelineFormKommentar
            name={vorschreibungspositionVersionFormFields.kommentar}
            timeblock={timeblock}
            valuesDescriptions={vorschreibungspositionVersionFormValuesDescriptionsForKommentar}
          />

          <TimelineFormButtons<VorschreibungspositionVersion>
            timeblock={timeblock}
            isSubmitting={formikProps.isSubmitting}
            isSameAsPreviousVersion={isSameAsPreviousVersion(
              formikProps.values,
              getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
            )}
            submitBtnLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

const isSameAsPreviousVersion = (
  formValues: VorschreibungspositionVersionFormValues,
  previousTimeblock?: ITimeblock<VorschreibungspositionVersion>
) =>
  formValues.kontierungstabelleId === previousTimeblock?.kontierungstabelle.kontierungstabelleId &&
  formValues.umsatzsteuerkennzeichenId === previousTimeblock?.umsatzsteuerkennzeichen.umsatzsteuerkennzeichenId;

export default VorschreibungspositionVersionForm;
