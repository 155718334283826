import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { KontoAbrechnungskreisFieldsFragmentDoc } from '../AbrDefFragments.types';
import { TextbausteinFieldsFragmentDoc } from '../../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSubAdministrationAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  request: Types.SubAdministrationAbrechnungsdefinitionVersionCreateInput;
}>;

export type CreateSubAdministrationAbrechnungsdefinitionVersionMutation = {
  createSubAdministrationAbrechnungsdefinitionVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      subAdministrationAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateSubAdministrationAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
  request: Types.SubAdministrationAbrechnungsdefinitionVersionUpdateInput;
}>;

export type UpdateSubAdministrationAbrechnungsdefinitionVersionMutation = {
  updateSubAdministrationAbrechnungsdefinitionVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      subAdministrationAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteSubAdministrationAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteSubAdministrationAbrechnungsdefinitionVersionMutation = {
  deleteSubAdministrationAbrechnungsdefinitionVersion: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateSubAdministrationAbrechnungsdefinitionVersionDocument = gql`
  mutation CreateSubAdministrationAbrechnungsdefinitionVersion(
    $abrechnungsdefinitionId: ID!
    $request: SubAdministrationAbrechnungsdefinitionVersionCreateInput!
  ) {
    createSubAdministrationAbrechnungsdefinitionVersion(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $request) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        subAdministrationAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useCreateSubAdministrationAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubAdministrationAbrechnungsdefinitionVersionMutation,
    CreateSubAdministrationAbrechnungsdefinitionVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSubAdministrationAbrechnungsdefinitionVersionMutation,
    CreateSubAdministrationAbrechnungsdefinitionVersionMutationVariables
  >(CreateSubAdministrationAbrechnungsdefinitionVersionDocument, options);
}
export type CreateSubAdministrationAbrechnungsdefinitionVersionMutationHookResult = ReturnType<
  typeof useCreateSubAdministrationAbrechnungsdefinitionVersionMutation
>;
export type CreateSubAdministrationAbrechnungsdefinitionVersionMutationResult =
  Apollo.MutationResult<CreateSubAdministrationAbrechnungsdefinitionVersionMutation>;
export type CreateSubAdministrationAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateSubAdministrationAbrechnungsdefinitionVersionMutation,
  CreateSubAdministrationAbrechnungsdefinitionVersionMutationVariables
>;
export const UpdateSubAdministrationAbrechnungsdefinitionVersionDocument = gql`
  mutation UpdateSubAdministrationAbrechnungsdefinitionVersion(
    $abrechnungsdefinitionId: ID!
    $abrechnungsdefinitionVersionId: ID!
    $request: SubAdministrationAbrechnungsdefinitionVersionUpdateInput!
  ) {
    updateSubAdministrationAbrechnungsdefinitionVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
      input: $request
    ) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        subAdministrationAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useUpdateSubAdministrationAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubAdministrationAbrechnungsdefinitionVersionMutation,
    UpdateSubAdministrationAbrechnungsdefinitionVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSubAdministrationAbrechnungsdefinitionVersionMutation,
    UpdateSubAdministrationAbrechnungsdefinitionVersionMutationVariables
  >(UpdateSubAdministrationAbrechnungsdefinitionVersionDocument, options);
}
export type UpdateSubAdministrationAbrechnungsdefinitionVersionMutationHookResult = ReturnType<
  typeof useUpdateSubAdministrationAbrechnungsdefinitionVersionMutation
>;
export type UpdateSubAdministrationAbrechnungsdefinitionVersionMutationResult =
  Apollo.MutationResult<UpdateSubAdministrationAbrechnungsdefinitionVersionMutation>;
export type UpdateSubAdministrationAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubAdministrationAbrechnungsdefinitionVersionMutation,
  UpdateSubAdministrationAbrechnungsdefinitionVersionMutationVariables
>;
export const DeleteSubAdministrationAbrechnungsdefinitionVersionDocument = gql`
  mutation DeleteSubAdministrationAbrechnungsdefinitionVersion($abrechnungsdefinitionId: ID!, $abrechnungsdefinitionVersionId: ID!) {
    deleteSubAdministrationAbrechnungsdefinitionVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteSubAdministrationAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSubAdministrationAbrechnungsdefinitionVersionMutation,
    DeleteSubAdministrationAbrechnungsdefinitionVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSubAdministrationAbrechnungsdefinitionVersionMutation,
    DeleteSubAdministrationAbrechnungsdefinitionVersionMutationVariables
  >(DeleteSubAdministrationAbrechnungsdefinitionVersionDocument, options);
}
export type DeleteSubAdministrationAbrechnungsdefinitionVersionMutationHookResult = ReturnType<
  typeof useDeleteSubAdministrationAbrechnungsdefinitionVersionMutation
>;
export type DeleteSubAdministrationAbrechnungsdefinitionVersionMutationResult =
  Apollo.MutationResult<DeleteSubAdministrationAbrechnungsdefinitionVersionMutation>;
export type DeleteSubAdministrationAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteSubAdministrationAbrechnungsdefinitionVersionMutation,
  DeleteSubAdministrationAbrechnungsdefinitionVersionMutationVariables
>;
