import styled from 'styled-components';
import { Collapse } from 'antd';

export const StyledCollapseKonto = styled(Collapse)`
  &&& .ant-collapse-header {
    padding: 0 0 0 40px;
    background-color: #fafafa;
    display: flex;
    align-items: center;
  }
  &&& .ant-collapse-content-box {
    padding: 0 0 0 40px;
  }
`;

export const StyledCollapseKontozuordnung = styled(Collapse)`
  &&& .ant-collapse-header {
    padding: 0;
  }
  &&& .ant-collapse-content-box {
    padding: 0;
  }
`;
