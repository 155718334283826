import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungskreisListByKundenSystemIdQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AbrechnungskreisListByKundenSystemIdQuery = {
  getAbrechnungskreisListByKundenSystemId: {
    data: Array<{
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      deletable?: boolean | null;
      status: { description?: string | null; value: Types.AbrechnungskreisStatus; text: string };
      type: { value: Types.AbrechnungskreisType; text: string };
      verrechnungUstVomAufwand?: { description: string; value: Types.VerrechnungsUstVomAufwand; text: string } | null;
      warningList: Array<{ message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungskreisQueryVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
}>;

export type AbrechnungskreisQuery = {
  getAbrechnungskreis: {
    data: {
      abrechnungskreisId: string;
      bezeichnung: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      status: { description?: string | null; value: Types.AbrechnungskreisStatus; text: string };
      type: { value: Types.AbrechnungskreisType; text: string };
      verrechnungUstVomAufwand?: { description: string; value: Types.VerrechnungsUstVomAufwand; text: string } | null;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungskreisChangeHistoryListQueryVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
}>;

export type AbrechnungskreisChangeHistoryListQuery = {
  getAbrechnungskreisChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const AbrechnungskreisListByKundenSystemIdDocument = gql`
  query AbrechnungskreisListByKundenSystemId($kundenSystemId: ID!, $withDetails: Boolean, $includeArchiviert: Boolean) {
    getAbrechnungskreisListByKundenSystemId(kundenSystemId: $kundenSystemId, withDetails: $withDetails, includeArchiviert: $includeArchiviert) {
      data {
        abrechnungskreisId
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        firmendatenId
        deletable
        status {
          description
          value
          text
        }
        type {
          value
          text
        }
        verrechnungUstVomAufwand {
          description
          value
          text
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAbrechnungskreisListByKundenSystemIdQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungskreisListByKundenSystemIdQuery, AbrechnungskreisListByKundenSystemIdQueryVariables> &
    ({ variables: AbrechnungskreisListByKundenSystemIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungskreisListByKundenSystemIdQuery, AbrechnungskreisListByKundenSystemIdQueryVariables>(
    AbrechnungskreisListByKundenSystemIdDocument,
    options
  );
}
export function useAbrechnungskreisListByKundenSystemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungskreisListByKundenSystemIdQuery, AbrechnungskreisListByKundenSystemIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungskreisListByKundenSystemIdQuery, AbrechnungskreisListByKundenSystemIdQueryVariables>(
    AbrechnungskreisListByKundenSystemIdDocument,
    options
  );
}
export function useAbrechnungskreisListByKundenSystemIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AbrechnungskreisListByKundenSystemIdQuery, AbrechnungskreisListByKundenSystemIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungskreisListByKundenSystemIdQuery, AbrechnungskreisListByKundenSystemIdQueryVariables>(
    AbrechnungskreisListByKundenSystemIdDocument,
    options
  );
}
export type AbrechnungskreisListByKundenSystemIdQueryHookResult = ReturnType<typeof useAbrechnungskreisListByKundenSystemIdQuery>;
export type AbrechnungskreisListByKundenSystemIdLazyQueryHookResult = ReturnType<typeof useAbrechnungskreisListByKundenSystemIdLazyQuery>;
export type AbrechnungskreisListByKundenSystemIdSuspenseQueryHookResult = ReturnType<typeof useAbrechnungskreisListByKundenSystemIdSuspenseQuery>;
export type AbrechnungskreisListByKundenSystemIdQueryResult = Apollo.QueryResult<
  AbrechnungskreisListByKundenSystemIdQuery,
  AbrechnungskreisListByKundenSystemIdQueryVariables
>;
export const AbrechnungskreisDocument = gql`
  query Abrechnungskreis($abrechnungskreisId: ID!) {
    getAbrechnungskreis(abrechnungskreisId: $abrechnungskreisId) {
      data {
        abrechnungskreisId
        bezeichnung
        createdBy
        createdByMitarbeiterId
        deletable
        firmendatenId
        status {
          description
          value
          text
        }
        type {
          value
          text
        }
        verrechnungUstVomAufwand {
          description
          value
          text
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAbrechnungskreisQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungskreisQuery, AbrechnungskreisQueryVariables> &
    ({ variables: AbrechnungskreisQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungskreisQuery, AbrechnungskreisQueryVariables>(AbrechnungskreisDocument, options);
}
export function useAbrechnungskreisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungskreisQuery, AbrechnungskreisQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungskreisQuery, AbrechnungskreisQueryVariables>(AbrechnungskreisDocument, options);
}
export function useAbrechnungskreisSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AbrechnungskreisQuery, AbrechnungskreisQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungskreisQuery, AbrechnungskreisQueryVariables>(AbrechnungskreisDocument, options);
}
export type AbrechnungskreisQueryHookResult = ReturnType<typeof useAbrechnungskreisQuery>;
export type AbrechnungskreisLazyQueryHookResult = ReturnType<typeof useAbrechnungskreisLazyQuery>;
export type AbrechnungskreisSuspenseQueryHookResult = ReturnType<typeof useAbrechnungskreisSuspenseQuery>;
export type AbrechnungskreisQueryResult = Apollo.QueryResult<AbrechnungskreisQuery, AbrechnungskreisQueryVariables>;
export const AbrechnungskreisChangeHistoryListDocument = gql`
  query AbrechnungskreisChangeHistoryList($abrechnungskreisId: ID!) {
    getAbrechnungskreisChangeHistoryList(abrechnungskreisId: $abrechnungskreisId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useAbrechnungskreisChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungskreisChangeHistoryListQuery, AbrechnungskreisChangeHistoryListQueryVariables> &
    ({ variables: AbrechnungskreisChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungskreisChangeHistoryListQuery, AbrechnungskreisChangeHistoryListQueryVariables>(
    AbrechnungskreisChangeHistoryListDocument,
    options
  );
}
export function useAbrechnungskreisChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungskreisChangeHistoryListQuery, AbrechnungskreisChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungskreisChangeHistoryListQuery, AbrechnungskreisChangeHistoryListQueryVariables>(
    AbrechnungskreisChangeHistoryListDocument,
    options
  );
}
export function useAbrechnungskreisChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AbrechnungskreisChangeHistoryListQuery, AbrechnungskreisChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungskreisChangeHistoryListQuery, AbrechnungskreisChangeHistoryListQueryVariables>(
    AbrechnungskreisChangeHistoryListDocument,
    options
  );
}
export type AbrechnungskreisChangeHistoryListQueryHookResult = ReturnType<typeof useAbrechnungskreisChangeHistoryListQuery>;
export type AbrechnungskreisChangeHistoryListLazyQueryHookResult = ReturnType<typeof useAbrechnungskreisChangeHistoryListLazyQuery>;
export type AbrechnungskreisChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useAbrechnungskreisChangeHistoryListSuspenseQuery>;
export type AbrechnungskreisChangeHistoryListQueryResult = Apollo.QueryResult<
  AbrechnungskreisChangeHistoryListQuery,
  AbrechnungskreisChangeHistoryListQueryVariables
>;
