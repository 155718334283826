import { TSubAdministrationAbrechnungTop } from '../templates/subAdministrationAbrechnung/Top/subAdministrationAbrechnungTop-types';

export const mockSubAdministrationAbrechnungTop: TSubAdministrationAbrechnungTop = {
  type: 'SUB_ABRECHNUNG_BESTANDSEINHEIT',
  einleitungstext: 'einleitungstext',
  ergebnistext: 'ergebnistext',
  schlusstext: 'schlusstext',
  absender: {
    label: 'Abs.: ',
    value: 'kurzbezeichnung_1, Grillgasse 46, 1110 Wien',
  },
  eigentuemer: {
    rechnungsEmpfaengerShippingLabel: {
      line1: 'Herr und Frau',
      line2: 'name 1',
      line3: 'name 2',
      line4: 'name 3',
      line5: 'Grillgasse 46',
      line6: '1110 Wien',
      line7: null,
    },
    kundennummer: {
      text: 'Kundennummer:',
      nummer: '101',
    },
  },
  abrechnungsDatumLine: 'Wien, am 26.03.2025',
  bestandseinheitBlock: {
    positionListHeader: {
      positionText: 'Position',
    },
    saldovortrag: {
      label: 'Saldovortrag',
      value: '0,00',
    },
    bestandseinheitList: [
      {
        bezeichnung: 'Top 1-2',
        einnahmen: {
          positionName: 'Einnahmen',
          labelValueRequest: {
            label: 'Summe Einnahmen',
            value: '5.000,00',
          },
          abrKreisList: [
            {
              abrKreis: {
                label: 'Allg. Betriebskosten3',
                value: '5.000,00',
              },
              kontoList: [
                {
                  konto: {
                    label: 'sonstige BK',
                    value: '5.000,00',
                  },
                  buchungszeileList: [],
                },
              ],
            },
          ],
        },
        ausgaben: {
          positionName: 'Ausgaben',
          labelValueRequest: {
            label: 'Summe Ausgaben',
            value: '0,00',
          },
          abrKreisList: [
            {
              abrKreis: {
                label: 'Allg. Betriebskosten2',
                value: '0,00',
              },
              kontoList: [
                {
                  konto: {
                    label: 'sonstige BK',
                    value: '0,00',
                  },
                  buchungszeileList: [],
                },
                {
                  konto: {
                    label: 'sonstige BK',
                    value: '0,00',
                  },
                  buchungszeileList: [],
                },
              ],
            },
          ],
        },
        vorsteuerkuerzung: {
          positionName: 'Vorsteuerkürzung',
          labelValueRequest: {
            label: 'Summe Vorsteuerkürzung',
            value: '0,00',
          },
          abrKreisList: [],
        },
        zwischensumme: {
          label: 'Zwischensumme',
          value: '5.000,00',
        },
        eigentuemerverrechnung: null,
        bestandseinheitErgebnis: {
          label: 'Ergebnis Top 1-2',
          value: '5.000,00',
        },
      },
    ],
  },
  objekt: {
    einnahmen: {
      positionName: 'Allgemeine Einnahmen',
      labelValueRequest: {
        label: 'Summe Allgemeine Einnahmen',
        value: '0,00',
      },
      abrKreisList: [
        {
          abrKreis: {
            label: 'Allg. Betriebskosten3',
            value: '0,00',
          },
          kontoList: [
            {
              konto: {
                label: 'sonstige BK',
                value: '0,00',
              },
              buchungszeileList: [],
            },
          ],
        },
      ],
    },
    ausgaben: {
      positionName: 'Allgemeine Ausgaben',
      labelValueRequest: {
        label: 'Summe Allgemeine Ausgaben',
        value: '1.000,00',
      },
      abrKreisList: [
        {
          abrKreis: {
            label: 'Allg. Betriebskosten2',
            value: '1.000,00',
          },
          kontoList: [
            {
              konto: {
                label: 'sonstige BK',
                value: '1.000,00',
              },
              buchungszeileList: [],
            },
            {
              konto: {
                label: 'sonstige BK',
                value: '0,00',
              },
              buchungszeileList: [],
            },
          ],
        },
      ],
    },
    vorsteuerkuerzung: {
      positionName: 'Allgemeine Vorsteuerkürzung',
      labelValueRequest: {
        label: 'Summe Allgemeine Vorsteuerkürzung',
        value: '0,00',
      },
      abrKreisList: [],
    },
    zwischensumme: {
      label: 'Zwischensumme',
      value: '-1.000,00',
    },
    eigentuemerverrechnung: {
      positionName: 'Allgemeine Eigentümerverrechnung',
      kontoList: [
        {
          kontoName: 'sonstige BK',
          buchungszeileList: [],
        },
      ],
      summePosition: {
        label: 'Summe Allgemeine Eigentümerverrechnung',
        value: '500,00',
      },
    },
    objektErgebnis: {
      label: 'Ergebnis des Objekts',
      value: '3.500,00',
    },
  },
  abrechnungErgebnis: {
    label: 'Ergebnis der Abrechnung',
    value: '3.500,00',
  },
  endErgebnis: {
    label: 'Nachzahlung',
    value: '-500,00',
  },
  objektZahlungsrueckstaendeBlock: {
    zahlungsrueckstaendeListHeader: {
      bezeichnungText: 'Bezeichnung',
      topNummerText: 'Top',
      kontoNummerText: 'Kontonummer',
      letzteBuchungDateText: 'letzte Buchung am',
      saldoText: 'Saldobetrag',
      dueDateText: 'fällig am',
    },
    zahlungsrueckstaendeList: [
      {
        bezeichnung: 'persGem LabyJinmXy',
        topNummer: 'Top 1-2',
        kontoNummer: 'S57777',
        letzteBuchungDate: '26.03.2025',
        saldo: '4.000,00',
        dueDate: '01.01.2020',
      },
    ],
    zahlungsrueckstaendeSummary: {
      label: 'Endsumme',
      value: '4.000,00',
    },
  },
  informationAbrechnungskreiseBlock: null,
};
