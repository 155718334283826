import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { GetRowKey } from 'rc-table/lib/interface';
import { QUERY_PARAM_KEY, useQueryParams } from '../../../hooks/useStringQueryParams';
import { isNotNil } from '../../../helpers/assertionHelper';

type Props = {
  rowKey: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;

const TableRowWithHighlighting: FC<Props> = ({ rowKey, ...restProps }) => {
  const { highlightedRowKey } = useQueryParams<{ [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: string }>();

  if (isNotNil(rowKey) && rowKey === highlightedRowKey?.toString()) {
    return <StyledTableRow {...restProps} />;
  }
  return <tr {...restProps} />;
};

export function getRowKey<RecordType>(record: RecordType, rowKey?: string | GetRowKey<RecordType>) {
  if (record === undefined || rowKey === undefined) return undefined;
  if (typeof rowKey === 'function') return rowKey(record);
  // @ts-ignore
  else return record[rowKey];
}

export const StyledTableRow = styled('tr')`
  & > td {
    background-color: #e6f7ff !important;
  }
` as (props: Omit<Props, 'rowKey'>) => React.ReactElement;

export default TableRowWithHighlighting;
