import { generatePath } from 'react-router';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { FiltersFormValues } from './filtersFormMapper';
import { URI_BOOKING_FOLDER_PAGE } from '../../bookingFolderUriPaths';

export enum QueryParamKey {
  BELEGDATUM_FROM = 'belegdatumFrom',
  BELEGDATUM_TO = 'belegdatumTo',
  BELEGNUMMER = 'belegnummer',
  BELEG_SYMBOL_LIST = 'belegSymbolList',
  BESTANDSEINHEIT_ID = 'bestandseinheitId',
  BUCHUNGSDATUM_FROM = 'buchungsdatumFrom',
  BUCHUNGSDATUM_TO = 'buchungsdatumTo',
  BUCHUNGS_TYPE_LIST = 'buchungsTypeList',
  CREATE_BY_MITARBEITER_ID_LIST = 'createByMitarbeiterIdList',
  CREATE_TS_FROM = 'createTsFrom',
  CREATE_TS_TO = 'createTsTo',
  DUE_DATE_FROM = 'dueDateFrom',
  DUE_DATE_TO = 'dueDateTo',
  FACHLICHE_PRUEFUNG = 'fachlichePruefung',
  HE_VERTRAG_ID = 'heVertragId',
  OBJEKT_ID = 'objektId',
  RECHTSTRAEGER_ID_LIST = 'rechtstraegerIdList',
  STATUS = 'status',
  SUM_BRUTTO_FROM = 'sumBruttoFrom',
  SUM_BRUTTO_TO = 'sumBruttoTo',
  SUM_STEUER_FROM = 'sumSteuerFrom',
  SUM_STEUER_TO = 'sumSteuerTo',
  VERTRAG_ID = 'vertragId',
  VERTRAGSPARTNER_ID = 'vertragspartnerId',
  ZU_STORNIERENDE_BUCHUNG = 'zuStornierendeBuchung',
}

export type BookingFolderQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: BookingFolderQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToBookingFolderListingPage(filters, paginationParams));

export const generatePathToBookingFolderListingPage = (filters: BookingFolderQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_BOOKING_FOLDER_PAGE)}?${mapToQueryString({
    [QueryParamKey.BELEGDATUM_FROM]: filters?.belegDatumFrom,
    [QueryParamKey.BELEGDATUM_TO]: filters?.belegDatumTo,
    [QueryParamKey.BELEGNUMMER]: filters?.belegnummer,
    [QueryParamKey.BELEG_SYMBOL_LIST]: filters?.belegSymbolList,
    [QueryParamKey.BESTANDSEINHEIT_ID]: filters?.bestandseinheitId,
    [QueryParamKey.BUCHUNGSDATUM_FROM]: filters?.buchungsdatumFrom,
    [QueryParamKey.BUCHUNGSDATUM_TO]: filters?.buchungsdatumTo,
    [QueryParamKey.BUCHUNGS_TYPE_LIST]: filters?.buchungsTypeList,
    [QueryParamKey.CREATE_BY_MITARBEITER_ID_LIST]: filters?.createByMitarbeiterIdList,
    [QueryParamKey.CREATE_TS_FROM]: filters?.createTsFrom,
    [QueryParamKey.CREATE_TS_TO]: filters?.createTsTo,
    [QueryParamKey.DUE_DATE_FROM]: filters?.dueDateFrom,
    [QueryParamKey.DUE_DATE_TO]: filters?.dueDateTo,
    [QueryParamKey.FACHLICHE_PRUEFUNG]: filters?.fachlichePruefung,
    [QueryParamKey.HE_VERTRAG_ID]: filters?.heVertragId,
    [QueryParamKey.OBJEKT_ID]: filters?.objektId,
    [QueryParamKey.RECHTSTRAEGER_ID_LIST]: filters?.rechtstraegerIdList,
    [QueryParamKey.STATUS]: filters?.status,
    [QueryParamKey.SUM_BRUTTO_FROM]: filters?.sumBruttoFrom,
    [QueryParamKey.SUM_BRUTTO_TO]: filters?.sumBruttoTo,
    [QueryParamKey.SUM_STEUER_FROM]: filters?.sumSteuerFrom,
    [QueryParamKey.SUM_STEUER_TO]: filters?.sumSteuerTo,
    [QueryParamKey.VERTRAG_ID]: filters?.vertragId,
    [QueryParamKey.VERTRAGSPARTNER_ID]: filters?.vertragspartnerIdList,
    [QueryParamKey.ZU_STORNIERENDE_BUCHUNG]: filters?.zuStornierendeBuchungId,
    ...paginationParams,
  })}`;
