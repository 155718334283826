import React from 'react';
import { useParams } from 'react-router-dom';
import BkOrWeAbrechnungCard from '../../features/Abrechnung/BkOrWe/BkOrWeCard/BkOrWeAbrechnungCard';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import menuListBkAbr from './menuListBkAbr';
import { BkAbrDetailsPageRouteParams } from './routes';
import { useObjektAbrechnungQuery } from '../../features/Abrechnung/BkOrWe/gql/ObjektAbrechnungQueries.types';
import { useOnBkOrWeAbrechnungDataChangedEvents } from '../../features/Abrechnung/BkOrWe/useOnBkOrWeAbrechnungDataChangeEvent';
import { ObjektAbrechnung } from '../../types';

const BkAbrechnungDetailsPage = () => {
  const { objektId, objektAbrechnungId } = useParams() as BkAbrDetailsPageRouteParams;

  const { data, refetch } = useObjektAbrechnungQuery({
    variables: {
      objektId,
      objektAbrechnungId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });
  const objektAbrechnung = data?.getObjektAbrechnung.data;
  const loading = !objektAbrechnung;

  useOnBkOrWeAbrechnungDataChangedEvents('ObjektAbrechnung', refetch);

  return (
    <DetailsPageTemplate<ObjektAbrechnung>
      data={objektAbrechnung}
      pageTitle={(objektAbrechnung) => objektAbrechnung.bezeichnung}
      card={(objektAbrechnung) => <BkOrWeAbrechnungCard loading={loading} abrechnung={objektAbrechnung} onSuccess={refetch} objektId={objektId} />}
      sidebarMenuList={(objektAbrechnung) => menuListBkAbr(objektAbrechnung, refetch, objektId, loading)}
    />
  );
};

export default BkAbrechnungDetailsPage;
