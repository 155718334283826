import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Tabs, TabsProps } from 'antd';
import { useFDAuthorized } from '../../../../../../../shared/Auth/useAuthorized';
import { handleUpdateSubmit } from '../../shared/handleSubmit';
import { VertragInfoFeldType } from '../../../../../../../types';
import InfoFeldUpload from '../../../../../../shared/InfoFeld/InfoFeldUpload';
import FormButtons from '../../../../../../../components/Button/FormButtons';
import { kuendigungsinfosValidationSchema } from '../shared/kuendigungsinfosValidationSchema';
import KuendigungsinfosDatenTab from '../Tabs/KuendigungsinfosDatenTab';
import { kuendigungsinfosUpdateFormFields, KuendigungsinfosUpdateFormValues, mapToUpdateFormValues } from './kuendigungsinfosUpdateFormMapper';
import { weiterePersonUpdateFormFields } from '../../WeiterePerson/Update/weiterePersonUpdateFormMapper';
import InfoFeldDocumentTab from '../../../../../../shared/InfoFeld/Tabs/InfoFeldDocumentTab';

import { VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment } from '../../../gql/VertragInfoFeldFragments.types';
import VertragInfoFeldTicketListing from '../../../Ticket/VertragInfoFeldTicketListing';
import FormButtonClose from '../../../../../../../components/Button/FormButtonClose';
import { InfoFeldUpdateFormTabKey } from '../../../../../../InfoFeld/infoFeldTypes';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  onCancel: () => void;
  onInfoFeldAction: () => void;
  onTicketAction: () => void;
  infoFeld: VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment;
  activeKeyTab: InfoFeldUpdateFormTabKey;
};

const KuendigungsinfosUpdateForm: FC<Props> = ({
  objektId,
  bestandseinheitId,
  vertragId,
  onInfoFeldAction,
  onTicketAction,
  onCancel,
  infoFeld,
  activeKeyTab,
}) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<KuendigungsinfosUpdateFormValues>
      initialValues={mapToUpdateFormValues(infoFeld)}
      onSubmit={(values, { setSubmitting }) => {
        handleUpdateSubmit(
          firmendatenId,
          objektId,
          bestandseinheitId,
          vertragId,
          infoFeld.vertragInfoFeldId,
          VertragInfoFeldType.Kuendigungsinfos,
          values,
          setSubmitting,
          onInfoFeldAction
        );
      }}
      validationSchema={kuendigungsinfosValidationSchema}
    >
      {(formikProps) => {
        const items: TabsProps['items'] = [
          {
            key: InfoFeldUpdateFormTabKey.DATA,
            label: 'Daten',
            children: (
              <Form layout="vertical">
                <KuendigungsinfosDatenTab formFields={kuendigungsinfosUpdateFormFields} />
                <InfoFeldUpload
                  name={kuendigungsinfosUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.TICKET,
            label: 'Fristen/Aufgaben',
            children: (
              <>
                <VertragInfoFeldTicketListing
                  objektId={objektId}
                  bestandseinheitId={bestandseinheitId}
                  vertragId={vertragId}
                  infoFeld={infoFeld}
                  onSuccess={onTicketAction}
                />
                <FormButtonClose onCancel={onCancel} />
              </>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.DOCUMENT,
            label: 'Dokumente',
            children: (
              <Form layout="vertical">
                <InfoFeldDocumentTab
                  infoFeld={infoFeld}
                  deleteDocumentFileIdList={formikProps.values.deleteDocumentFileIdList}
                  onDocumentDelete={(value) => formikProps.setFieldValue(weiterePersonUpdateFormFields.deleteDocumentFileIdList, value)}
                  firmendatenId={firmendatenId}
                />
                <InfoFeldUpload
                  name={kuendigungsinfosUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
        ];

        return <Tabs defaultActiveKey={activeKeyTab} style={{ minHeight: '300px' }} items={items} />;
      }}
    </Formik>
  );
};

export default KuendigungsinfosUpdateForm;
