import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { RepFondsAufwandAufteilung } from '../../../topAbrechnung-types';
import TARepFondsAufwandAufteilungTableBlock from './AufwandAufteilungTableBlock/TARepFondsAufwandAufteilungTableBlock';
import pdfStyles from '../../../../styles/pdfStyles';

const TARepFondsAufwandAufteilungBlock: FC<{ aufwandAufteilung: RepFondsAufwandAufteilung }> = ({ aufwandAufteilung }) => (
  <View style={[pdfStyles.textNormal, { marginTop: '7mm' }]}>
    {/* Aufteilungschluessel name */}
    <Text>{aufwandAufteilung.aufteilungschluesselText}</Text>

    {/*  Anteil info */}
    <Text>{aufwandAufteilung.anteilProzentText}</Text>

    {/*  AufwandAufteilung table block */}
    <TARepFondsAufwandAufteilungTableBlock aufwandAufteilung={aufwandAufteilung} />
  </View>
);

export default TARepFondsAufwandAufteilungBlock;
