import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VertragInfoFeldTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type VertragInfoFeldTypeListQuery = {
  getVertragInfoFeldTypeList: {
    data: Array<{ text: string; value: Types.VertragInfoFeldType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VertragInfoFeldTypeListDocument = gql`
  query VertragInfoFeldTypeList {
    getVertragInfoFeldTypeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVertragInfoFeldTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<VertragInfoFeldTypeListQuery, VertragInfoFeldTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragInfoFeldTypeListQuery, VertragInfoFeldTypeListQueryVariables>(VertragInfoFeldTypeListDocument, options);
}
export function useVertragInfoFeldTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragInfoFeldTypeListQuery, VertragInfoFeldTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragInfoFeldTypeListQuery, VertragInfoFeldTypeListQueryVariables>(VertragInfoFeldTypeListDocument, options);
}
export function useVertragInfoFeldTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VertragInfoFeldTypeListQuery, VertragInfoFeldTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragInfoFeldTypeListQuery, VertragInfoFeldTypeListQueryVariables>(VertragInfoFeldTypeListDocument, options);
}
export type VertragInfoFeldTypeListQueryHookResult = ReturnType<typeof useVertragInfoFeldTypeListQuery>;
export type VertragInfoFeldTypeListLazyQueryHookResult = ReturnType<typeof useVertragInfoFeldTypeListLazyQuery>;
export type VertragInfoFeldTypeListSuspenseQueryHookResult = ReturnType<typeof useVertragInfoFeldTypeListSuspenseQuery>;
export type VertragInfoFeldTypeListQueryResult = Apollo.QueryResult<VertragInfoFeldTypeListQuery, VertragInfoFeldTypeListQueryVariables>;
