import queryString, { StringifyOptions } from 'query-string';
import { useLocation } from 'react-router';

const ARRAY_FORMAT = 'bracket';

// QueryParams are always optional because URI in browser can be modified directly
export type QueryParams<T> = Partial<T>;

export enum QUERY_PARAM_KEY {
  SHOW_ARCHIVED = 'showArchived',
  SHOW_DELETED = 'showDeleted',
  HIGHLIGHTED_ROW_KEY = 'highlightedRowKey',
  EXP_ROW_ID_LVL1 = 'expRowIdLvl1',
  EXP_ROW_ID_LVL2 = 'expRowIdLvl2',
  EXP_ROW_ID_LVL3 = 'expRowIdLvl3',
  EXPANDED_ROW_KEYS = 'expandedRowKeys',
  SEARCH = 'search',
}

// export const getIdForExpandedRowBasedOnTableLevel = (tableLevel: number, entityIds: string[])=> {
//
// }

export const mapToQueryString = (queryStringObjekt: { [key: string]: unknown }, options?: StringifyOptions) =>
  queryString.stringify(queryStringObjekt, { arrayFormat: ARRAY_FORMAT, skipNull: false, skipEmptyString: true, ...options });

interface ParsedStringQuery {
  [key: string]: string | undefined;
}

/**
 * you will get all your queryParams normalized to strings or undefined from the URi. works well for single stringquery params, for arrays
 * it will return the first element of the array because it is not customized for arrays yet because there is no use case for it for now.
 */
export const useStringQueryParams = (): ParsedStringQuery => {
  const { search } = useLocation();
  const currentSearch = queryString.parse(search, { arrayFormat: ARRAY_FORMAT });
  let normalizedQueryParams = {};
  for (const [key, value] of Object.entries(currentSearch)) {
    normalizedQueryParams = {
      ...normalizedQueryParams,
      [key]: getQueryParamValue(value),
    };
  }
  return normalizedQueryParams;
};

/**
 * You will get all your queryParams normalized to their type according QueryParams generic type or undefined from the URi.
 *  Return type have all attributes optional because queryParams are coming from the uri therefore they could be always missing.
 */
export function useQueryParams<
  QueryParams extends Record<string, string | number | boolean | null | (string | number | boolean | null)[]>,
>(): Partial<QueryParams> {
  const { search } = useLocation();
  const currentSearch = queryString.parse(search, { parseBooleans: true, parseNumbers: true, arrayFormat: ARRAY_FORMAT });
  let normalizedQueryParams = {};
  for (const [key, value] of Object.entries(currentSearch)) {
    normalizedQueryParams = {
      ...normalizedQueryParams,
      [key]: getQueryParamValue(value),
    };
  }
  return normalizedQueryParams as Partial<QueryParams>;
}

const getQueryParamValue = (searchValue: ParsedQueryValueType): string | boolean | number | undefined | (string | number | boolean | null)[] => {
  if (searchValue === null) {
    return undefined;
  }
  if (typeof searchValue === 'string' && !searchValue) {
    return undefined;
  }
  return searchValue;
};

export type ParsedQueryValueType = (string | number | boolean | null)[] | string | number | boolean | null | undefined;
