import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';

const DetailsColumnsTitle: FC<{ title: string }> = ({ title }) => (
  <View
    style={[
      pdfStyles.row,
      pdfStyles.textNormal,
      pdfStyles.borderBottomSmall,
      {
        paddingTop: '10mm',
        fontWeight: 'bold',
      },
    ]}
  >
    <Text style={[pdfStyles.textNormal]}>{title}</Text>
  </View>
);

export default DetailsColumnsTitle;
