import { FormFields } from '../../../../../../helpers/formikHelper';
import { Address, AddressType } from '../../../../../../types';

export interface StreetAddressUpdateFormValues {
  addressId: string;
  type: AddressType.Street;
  countryCodeIso2: string;
  city: string;
  cityAdditionalInformation: string;
  street: string;
  houseEntranceApartmentNumber: string;
  zipCode: string;
}

export const streetAddressUpdateFormFields: FormFields<StreetAddressUpdateFormValues> = {
  addressId: 'addressId',
  type: 'type',
  countryCodeIso2: 'countryCodeIso2',
  city: 'city',
  cityAdditionalInformation: 'cityAdditionalInformation',
  street: 'street',
  houseEntranceApartmentNumber: 'houseEntranceApartmentNumber',
  zipCode: 'zipCode',
};

export const mapStreetAddressToFormValues = (values: Address): StreetAddressUpdateFormValues => ({
  addressId: values.addressId,
  type: AddressType.Street,
  countryCodeIso2: values.countryCodeIso2,
  city: values.city,
  cityAdditionalInformation: values.cityAdditionalInformation ?? '',
  street: values.street ?? '',
  houseEntranceApartmentNumber: values.houseEntranceApartmentNumber ?? '',
  zipCode: values.zipCode ?? '',
});
