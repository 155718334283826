import { v4 as uuid4 } from 'uuid';
import {
  IncomingInvoiceBasis,
  IncomingInvoiceBuchungPosition,
  IncomingInvoiceBuchungPositionCreateInput,
  IncomingInvoiceBuchungPositionUpdateInput,
  PaymentMethod,
  RechtstraegerRolle,
  SingleInvoice,
  SingleInvoiceCreateInput,
  SingleInvoiceUpdateInput,
  StandingInvoice,
  StandingInvoiceCreateInput,
  StandingInvoiceUpdateInput,
  Verrechnungsart,
} from '../../../types';
import { FormFields, mapFormDateValueToDateString, TFormattedDecimal } from '../../../helpers/formikHelper';
import { isInvoiceTypeSingleInvoice } from './incomingInvoiceFormHelpers';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import { mapFormattedDecimalOrThrowIfEmpty } from '../../../components/Input-antd/formattedDecimalHelper';
import { isBillingTypeEinzelrechnung } from '../../Payment/paymentHelpers';
import { mapFormValuesToSingleInvoiceCreate, mapFormValuesToSingleInvoiceUpdate } from './singleInvoiceFormMapper';
import { mapFormValuesToStandingInvoiceCreate, mapFormValuesToStandingInvoiceUpdate } from './standingInvoiceFormMapper';
import { calculateBruttoNettoUst } from '../incomingInvoiceHelpers';

export type TInvoice = SingleInvoice | StandingInvoice;

export type IncomingInvoiceFormValues = {
  fileId: string;
  // Rechnungdetails Block
  // BillingType - Only used in CREATE
  ausstellerRechtstraegerId: string;
  billingType?: Verrechnungsart | null;
  dueDate?: string | null;
  kundenNummer?: string | null;
  objektId?: string | null;
  payeeRechtstraegerId: string;
  rechnungsDatum?: string | null;
  rechnungsNummer: string;
  // Payment Block
  issuerBankDetailsId: string;
  payeeBankDetailsId: string;
  paymentMethod: PaymentMethod;
  paymentReferenceText?: any | null;
  purposeOfUseText?: string | null;
  requestedPaymentDate?: string | null;
  sepaMandatReference?: string | null;
  // Abgrenzung Buchung Block - ONLY for SingleInvoice
  abgrenzungsBuchung?: boolean;
  abgrenzungsBuchungsdatum?: string | null;
  buchungsdatum?: string | null;
  // Buchung Position Block
  buchungPositionList?: IncomingInvoiceBuchungPositionFormValues[]; // Mandatory for SingleInvoice
  // Dauerrechnung Block
  partialAmountList?: PartialAmountFormValues[]; // Mandatory for StandingInvoice
  // Dauerrechnung + BuchungPosition - Get BASIS value by reference of inklusiveSteuer
  inklusiveSteuer: boolean;
  // Only for Single Invoice
  totalAmountOfBuchungsposition?: number | null;
  // Only used on FE to know if payeeId is we-,obj-rechnungsaussteller
  payeeRechtstraegerRolleList?: RechtstraegerRolle[];
};

export const incomingInvoiceFormFields: FormFields<IncomingInvoiceFormValues> = {
  fileId: 'fileId',
  // Rechnungdetails Block
  ausstellerRechtstraegerId: 'ausstellerRechtstraegerId',
  billingType: 'billingType',
  dueDate: 'dueDate',
  kundenNummer: 'kundenNummer',
  objektId: 'objektId',
  payeeRechtstraegerId: 'payeeRechtstraegerId',
  rechnungsDatum: 'rechnungsDatum',
  rechnungsNummer: 'rechnungsNummer',
  // Payment Block
  issuerBankDetailsId: 'issuerBankDetailsId',
  payeeBankDetailsId: 'payeeBankDetailsId',
  paymentMethod: 'paymentMethod',
  paymentReferenceText: 'paymentReferenceText',
  purposeOfUseText: 'purposeOfUseText',
  requestedPaymentDate: 'requestedPaymentDate',
  sepaMandatReference: 'sepaMandatReference',
  // Abgrenzung Buchung Block
  abgrenzungsBuchung: 'abgrenzungsBuchung',
  abgrenzungsBuchungsdatum: 'abgrenzungsBuchungsdatum',
  buchungsdatum: 'buchungsdatum',
  // Buchung Position Block
  buchungPositionList: 'buchungPositionList',
  // Dauerrechnung Block
  partialAmountList: 'partialAmountList',
  // Dauerrechnung + BuchungPosition
  inklusiveSteuer: 'inklusiveSteuer',
  // Only for Single Invoice
  totalAmountOfBuchungsposition: 'totalAmountOfBuchungsposition',
  // Only used on FE to know if payeeId is we-,obj-rechnungsaussteller
  payeeRechtstraegerRolleList: 'payeeRechtstraegerRolleList',
};

export type IncomingInvoiceBuchungPositionFormValues = {
  betrag: TFormattedDecimal;
  bestandseinheitId?: string | null;
  buchungstext: string;
  incomingInvoiceBuchungPositionId?: string;
  kontoId: string;
  steuersatz: TFormattedDecimal;
};

export const incomingInvoiceBuchungPositionFormFields: FormFields<IncomingInvoiceBuchungPositionFormValues> = {
  betrag: 'betrag',
  buchungstext: 'buchungstext',
  bestandseinheitId: 'bestandseinheitId',
  incomingInvoiceBuchungPositionId: 'incomingInvoiceBuchungPositionId',
  kontoId: 'kontoId',
  steuersatz: 'steuersatz',
};

export type PartialAmountFormValues = {
  id: string; // Need unique id so sorting after dueDate is possible
  buchungPositionList: IncomingInvoiceBuchungPositionFormValues[];
  buchungsdatum?: string | null;
  dueDate: string;
  partialAmountId?: string;
  requestedPaymentDate?: string | null;
};

export const partialAmountFormFields: FormFields<PartialAmountFormValues> = {
  id: 'id',
  buchungPositionList: 'buchungPositionList',
  buchungsdatum: 'buchungsdatum',
  dueDate: 'dueDate',
  partialAmountId: 'partialAmountId',
  requestedPaymentDate: 'requestedPaymentDate',
};

export const createPartialAmountFormValues = (partialAmount?: PartialAmountFormValues): PartialAmountFormValues => ({
  id: uuid4(),
  buchungPositionList: partialAmount?.buchungPositionList ?? [createIncomingInvoiceBuchungPositionFormValues()],
  buchungsdatum: partialAmount?.buchungsdatum ? dayjsCustom(partialAmount.buchungsdatum).add(1, 'month').format() : undefined,
  dueDate: partialAmount?.dueDate ? dayjsCustom(partialAmount.dueDate).add(1, 'month').format() : dayjsCustom().format('YYYY-MM-DD'),
  requestedPaymentDate: partialAmount?.requestedPaymentDate ? dayjsCustom(partialAmount.requestedPaymentDate).add(1, 'month').format() : undefined,
});

export const createIncomingInvoiceBuchungPositionFormValues = (): IncomingInvoiceBuchungPositionFormValues => ({
  betrag: 0,
  buchungstext: '',
  kontoId: '',
  steuersatz: 20,
});

const incomingInvoiceFormInitialValuesCreate = (fileId: string): IncomingInvoiceFormValues => ({
  fileId,

  ausstellerRechtstraegerId: '',
  billingType: Verrechnungsart.Einzelrechnung,
  dueDate: undefined,
  objektId: '',
  payeeRechtstraegerId: '',
  rechnungsDatum: null,
  rechnungsNummer: '',

  issuerBankDetailsId: '',
  payeeBankDetailsId: '',
  paymentMethod: PaymentMethod.Ueberweisung,

  abgrenzungsBuchung: false,
  abgrenzungsBuchungsdatum: null,
  buchungsdatum: null,

  buchungPositionList: [createIncomingInvoiceBuchungPositionFormValues()],
  inklusiveSteuer: false,
  totalAmountOfBuchungsposition: null,

  partialAmountList: [createPartialAmountFormValues()],
});

export const mapIncomingInvoiceToFormValues = (fileId: string, rechnung?: TInvoice): IncomingInvoiceFormValues => {
  // Create
  if (!rechnung) {
    return incomingInvoiceFormInitialValuesCreate(fileId);
  }

  // Update

  const sharedValues = {
    fileId,
    ausstellerRechtstraegerId: rechnung.ausstellerRechtstraegerId,
    billingType: rechnung.billingType.value,
    kundenNummer: rechnung.kundenNummer,
    objektId: rechnung.objektId,
    payeeRechtstraegerId: rechnung.payeeRechtstraegerId,
    rechnungsDatum: rechnung.rechnungsDatum,
    rechnungsNummer: rechnung.rechnungsNummer,

    payeeBankDetailsId: rechnung.payeeBankDetailsId,
    paymentMethod: rechnung.paymentMethod.value,
    paymentReferenceText: rechnung.paymentReferenceText,

    issuerBankDetailsId: rechnung.issuerBankDetailsId,
    purposeOfUseText: rechnung.purposeOfUseText,
    sepaMandatReference: rechnung.sepaMandatReference,

    inklusiveSteuer: isInklusiveSteuer(rechnung),
  };

  if (isInvoiceTypeSingleInvoice(rechnung.type)) {
    const singleInvoice = rechnung as SingleInvoice;
    const { brutto: sumBrutto } = calculateBruttoNettoUst(singleInvoice.buchungPositionList, isInklusiveSteuer(rechnung));
    return {
      ...sharedValues,
      abgrenzungsBuchung: singleInvoice.abgrenzungsBuchung,
      abgrenzungsBuchungsdatum: singleInvoice.abgrenzungsBuchungsdatum,
      buchungPositionList: singleInvoice.buchungPositionList.map(mapBuchungPositionToBuchungPositionFormValues),
      buchungsdatum: singleInvoice.buchungsdatum,
      dueDate: singleInvoice.dueDate,
      requestedPaymentDate: singleInvoice.requestedPaymentDate,
      totalAmountOfBuchungsposition: sumBrutto,
    };
  }

  const standingInvoice = rechnung as StandingInvoice;
  // partial amount list

  return {
    ...sharedValues,
    partialAmountList: standingInvoice.partialAmountList.map((partialAmount) => ({
      id: uuid4(),
      buchungPositionList: partialAmount.buchungPositionList.map(mapBuchungPositionToBuchungPositionFormValues),
      buchungsdatum: partialAmount.buchungsdatum,
      dueDate: partialAmount.dueDate,
      partialAmountId: partialAmount.partialAmountId,
      requestedPaymentDate: partialAmount.requestedPaymentDate,
    })),
  };
};

const mapBuchungPositionToBuchungPositionFormValues = (
  buchungPosition: IncomingInvoiceBuchungPosition
): IncomingInvoiceBuchungPositionFormValues => ({
  betrag: buchungPosition.betrag,
  bestandseinheitId: buchungPosition.bestandseinheitId,
  buchungstext: buchungPosition.buchungstext,
  incomingInvoiceBuchungPositionId: buchungPosition.incomingInvoiceBuchungPositionId,
  kontoId: buchungPosition.kontoId,
  steuersatz: buchungPosition.steuersatz,
});

const isInklusiveSteuer = (rechnung: TInvoice) => rechnung.basis.value === IncomingInvoiceBasis.Brutto;

export type SharedInvoiceValues = {
  ausstellerRechtstraegerId: string;
  basis: IncomingInvoiceBasis;
  fileId: string;
  issuerBankDetailsId: string;
  kundenNummer?: string | null;
  objektId?: string | null;
  payeeBankDetailsId: string;
  payeeRechtstraegerId: string;
  paymentMethod: PaymentMethod;
  paymentReferenceText?: any | null;
  purposeOfUseText?: string | null;
  rechnungsDatum: string;
  rechnungsNummer: string;
  sepaMandatReference?: string | null;
};

export const mapFormValuesForSharedValuesToInvoice = (values: IncomingInvoiceFormValues): SharedInvoiceValues => ({
  ausstellerRechtstraegerId: values.ausstellerRechtstraegerId,
  basis: values.inklusiveSteuer ? IncomingInvoiceBasis.Brutto : IncomingInvoiceBasis.Netto,
  payeeRechtstraegerId: values.payeeRechtstraegerId,
  fileId: values.fileId,
  issuerBankDetailsId: values.issuerBankDetailsId,
  kundenNummer: values.kundenNummer,
  objektId: values.objektId,
  paymentMethod: values.paymentMethod,
  paymentReferenceText: values.paymentReferenceText,
  purposeOfUseText: values.purposeOfUseText,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  rechnungsDatum: mapFormDateValueToDateString(values.rechnungsDatum!),
  rechnungsNummer: values.rechnungsNummer,
  payeeBankDetailsId: values.payeeBankDetailsId,
  sepaMandatReference: values.sepaMandatReference,
});

export const mapFormValuesToInvoiceCreate = (values: IncomingInvoiceFormValues): SingleInvoiceCreateInput | StandingInvoiceCreateInput => {
  if (isBillingTypeEinzelrechnung(values.billingType)) {
    return mapFormValuesToSingleInvoiceCreate(values);
  } else {
    return mapFormValuesToStandingInvoiceCreate(values);
  }
};

export const mapFormValuesToBuchungPositionCreate = (
  values: IncomingInvoiceBuchungPositionFormValues
): IncomingInvoiceBuchungPositionCreateInput => ({
  betrag: mapFormattedDecimalOrThrowIfEmpty(values.betrag),
  bestandseinheitId: values.bestandseinheitId,
  buchungstext: values.buchungstext,
  kontoId: values.kontoId,
  steuersatz: mapFormattedDecimalOrThrowIfEmpty(values.steuersatz),
});

export const mapFormValuesToBuchungPositionUpdate = (
  values: IncomingInvoiceBuchungPositionFormValues
): IncomingInvoiceBuchungPositionUpdateInput => ({
  incomingInvoiceBuchungPositionId: values.incomingInvoiceBuchungPositionId,
  betrag: mapFormattedDecimalOrThrowIfEmpty(values.betrag),
  bestandseinheitId: values.bestandseinheitId,
  buchungstext: values.buchungstext,
  kontoId: values.kontoId,
  steuersatz: mapFormattedDecimalOrThrowIfEmpty(values.steuersatz),
});

export const mapFormValuesToInvoiceUpdate = (values: IncomingInvoiceFormValues): SingleInvoiceUpdateInput | StandingInvoiceUpdateInput => {
  if (isBillingTypeEinzelrechnung(values.billingType)) {
    return mapFormValuesToSingleInvoiceUpdate(values);
  } else {
    return mapFormValuesToStandingInvoiceUpdate(values);
  }
};
