import React, { FC } from 'react';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import { mapToVersionTimelineDataSourceWithAlwaysDeletableEntries } from '../../../../components/Timeline/versionTimelineHelper';
import { usePauschalmieteAufwandskontoListQuery } from '../gql/PaulschalmieteQueries.types';
import { useDeletePauschalmieteAufwandskontoMutation } from '../gql/PaulschalmieteMutations.types';
import AufwandskontoVersionCardContent from './AufwandskontoVersionCardContent';
import AufwandskontoVersionForm from './Form/AufwandskontoVersionForm';
import { FirmendatenPauschalmieteAufwandskonto } from '../../../../types';

type Props = {
  onSuccess: () => void;
};

const AufwandskontoVersionTimeline: FC<Props> = ({ onSuccess }) => {
  const { data, loading, refetch } = usePauschalmieteAufwandskontoListQuery();

  const onActionSuccess = () => {
    onSuccess();
    refetch();
  };

  const [runDelete] = useDeletePauschalmieteAufwandskontoMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Pauschalmiete Aufwandskonto`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const aufwandskontoList = data?.getPauschalmieteAufwandskontoList.data ?? [];

  return (
    <VersionTimeline<FirmendatenPauschalmieteAufwandskonto>
      dataSource={mapToVersionTimelineDataSourceWithAlwaysDeletableEntries(aufwandskontoList)}
      isAllowedToDeleteInitialTimeblock
      loading={loading}
      versionIdKey="firmendatenPauschalmieteAufwandskontoId"
      defaultNewTimeblock={{
        firmendatenPauschalmieteAufwandskontoId: aufwandskontoList[0]?.firmendatenPauschalmieteAufwandskontoId,
        pauschalmieteAufwandskontoId: aufwandskontoList[0]?.pauschalmieteAufwandskontoId,
        warningList: aufwandskontoList[0]?.warningList,
      }}
      renderForm={(timeblock) => <AufwandskontoVersionForm timeblock={timeblock} onSubmitSuccess={onActionSuccess} />}
      renderCardContent={(timeblock) => <AufwandskontoVersionCardContent timeblock={timeblock} />}
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      onDelete={(timeblock) => {
        return runDelete({
          variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            firmendatenPauschalmieteAufwandskontoId: timeblock.firmendatenPauschalmieteAufwandskontoId,
          },
        });
      }}
    />
  );
};

export default AufwandskontoVersionTimeline;
