import { useCallback, useState } from 'react';
import { DataChangedEventConsumer } from './dataChangedSubscriptionContextProps';
import { useOnDataChangedSubscription } from './gql/DataChangedSubscription.types';
import { isTriggeredByOtherUserAndMatchingFilter } from './dataChangedEventFilterHelper';
import { dataChangedInfo } from './dataChangedSubscriptionHelper';

export const useOnDataChangedWithCallbacks = () => {
  const [consumers, setConsumers] = useState<DataChangedEventConsumer[]>([]);
  const addConsumer = useCallback((consumer: DataChangedEventConsumer) => {
    setConsumers((prevState) => (prevState.some((stateConsumer) => stateConsumer.key === consumer.key) ? prevState : [...prevState, consumer]));
  }, []);
  const removeConsumer = useCallback(
    (consumer: DataChangedEventConsumer) => setConsumers((prevState) => prevState.filter((stateConsumer) => stateConsumer.key !== consumer.key)),
    []
  );

  useOnDataChangedSubscription({
    ignoreResults: true,
    onData: (options) => {
      if (options.data.data) {
        const dataChanged = options.data.data.dataChanged;
        const matchingConsumers = consumers.filter((consumer) => isTriggeredByOtherUserAndMatchingFilter(dataChanged, consumer.filter));
        matchingConsumers.length > 0 &&
          dataChangedInfo(
            matchingConsumers[0].notificationDescription,
            () =>
              Promise.all(
                matchingConsumers.map((consumer) => {
                  return consumer.callback();
                })
              ),
            dataChanged
          );
      }
    },
  });

  return { addConsumer, removeConsumer };
};
