import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SingleInvoiceFieldsFragment = {
  abgrenzungsBuchung: boolean;
  abgrenzungsBuchungsdatum?: string | null;
  ausstellerBezeichnung: string;
  ausstellerRechtstraegerId: string;
  belegId: string;
  brutto: number;
  buchungIdList: Array<string>;
  buchungsanweisungIdList: Array<string>;
  buchungskreisId?: string | null;
  buchungsdatum: string;
  dueDate: string;
  fileId: string;
  issuerBankDetailsIban: string;
  issuerBankDetailsId: string;
  kundenNummer?: string | null;
  netto: number;
  offenerBetrag?: number | null;
  objektBezeichnung?: string | null;
  objektId?: string | null;
  paid: boolean;
  payeeBezeichnung: string;
  payeeBankDetailsIban: string;
  payeeBankDetailsId: string;
  payeeRechtstraegerId: string;
  paymentCreatable: boolean;
  paymentIdList: Array<string>;
  paymentReferenceText?: string | null;
  purposeOfUseText?: string | null;
  rechnungsDatum: string;
  rechnungsNummer: string;
  requestedPaymentDate?: string | null;
  sepaMandatReference?: string | null;
  type: Types.InvoiceType;
  basis: { text: string; value: Types.IncomingInvoiceBasis };
  billingType: { text: string; value: Types.Verrechnungsart };
  buchungErrorList: Array<{ message: string; type: string }>;
  buchungsanweisungListEntryDataList: Array<{
    buchungsanweisungId: string;
    status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
    fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
  }>;
  buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
  paymentMethod: { text: string; value: Types.PaymentMethod };
  buchungPositionList: Array<{
    betrag: number;
    bestandseinheitId?: string | null;
    bestandseinheitBezeichnung?: string | null;
    buchungstext: string;
    incomingInvoiceBuchungPositionId: string;
    kontoBezeichnung: string;
    kontoId: string;
    kontoNummer: string;
    steuersatz: number;
  }>;
  status: { text: string; value: Types.IncomingInvoiceStatus };
  steuerzeileList: Array<{ betrag: number; steuersatz: number }>;
  warningList: Array<{ message: string; type: string }>;
};

export type OrderBuchungsanweisungFieldsFragment = {
  buchungsdatum: string;
  buchungsanweisungId: string;
  fibuBuchungId?: string | null;
  buchungszeileList: Array<{
    betrag: number;
    buchungszeileId: string;
    kontoId?: string | null;
    steuer?: number | null;
    steuercode?: string | null;
    steuersatz?: number | null;
    objektId?: string | null;
    text: string;
    gegenKontoKey?: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } } | null;
    kontoKey: { nummer: string; klasse: { value: Types.KontoKlasse; text: string } };
    sollHaben: { text: string; value: Types.SollHaben };
  }>;
  fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
  status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
};

export const SingleInvoiceFieldsFragmentDoc = gql`
  fragment SingleInvoiceFields on SingleInvoice {
    abgrenzungsBuchung
    abgrenzungsBuchungsdatum
    ausstellerBezeichnung
    ausstellerRechtstraegerId
    belegId
    basis {
      text
      value
    }
    billingType {
      text
      value
    }
    brutto
    buchungErrorList {
      message
      type
    }
    buchungIdList
    buchungsanweisungIdList
    buchungsanweisungListEntryDataList {
      buchungsanweisungId
      status {
        description
        text
        value
      }
      fibuStatus {
        description
        text
        value
      }
    }
    buchungsanweisungStatus {
      description
      text
      value
    }
    buchungskreisId
    buchungsdatum
    dueDate
    fileId
    issuerBankDetailsIban
    issuerBankDetailsId
    kundenNummer
    netto
    offenerBetrag
    objektBezeichnung
    objektId
    paid
    payeeBezeichnung
    payeeBankDetailsIban
    payeeBankDetailsId
    payeeRechtstraegerId
    paymentCreatable
    paymentIdList
    paymentMethod {
      text
      value
    }
    paymentReferenceText
    buchungPositionList {
      betrag
      bestandseinheitId
      bestandseinheitBezeichnung
      buchungstext
      incomingInvoiceBuchungPositionId
      kontoBezeichnung
      kontoId
      kontoNummer
      steuersatz
    }
    purposeOfUseText
    rechnungsDatum
    rechnungsNummer
    requestedPaymentDate
    sepaMandatReference
    status {
      text
      value
    }
    steuerzeileList {
      betrag
      steuersatz
    }
    type
    warningList {
      message
      type
    }
  }
`;
export const OrderBuchungsanweisungFieldsFragmentDoc = gql`
  fragment OrderBuchungsanweisungFields on OrderBuchungsanweisung {
    buchungsdatum
    buchungsanweisungId
    buchungszeileList {
      betrag
      buchungszeileId
      gegenKontoKey {
        klasse {
          value
          text
        }
        nummer
      }
      kontoId
      kontoKey {
        klasse {
          value
          text
        }
        nummer
      }
      steuer
      steuercode
      steuersatz
      objektId
      sollHaben {
        text
        value
      }
      text
    }
    fibuBuchungId
    fibuStatus {
      description
      text
      value
    }
    status {
      description
      text
      value
    }
  }
`;
