import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { IncomingInvoiceBookingCreationGenerierlauf } from '../../../types';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { useIncomingInvoiceBookingCreationGenerierlaufQuery } from '../../../features/Verarbeitung/gql/IncomingInvoiceBookingCreationGenerierlauf/IncomingInvoiceBookingCreationGenerierlaufQueries.types';
import { IncomingInvoiceBookingCreationPageRouteParams } from './routes';
import menuListIncomingInvoiceBookingCreation from './menuListIncomingInvoiceBookingCreation';

const IncomingInvoiceBookingCreationDetailsPage = () => {
  const { generierlaufId } = useParams() as IncomingInvoiceBookingCreationPageRouteParams;

  const { data } = useIncomingInvoiceBookingCreationGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getIncomingInvoiceBookingCreationGenerierlauf.data;

  return (
    <DetailsPageTemplate<IncomingInvoiceBookingCreationGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.IncomingInvoiceBookingCreation}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard generierlauf={generierlauf} cardTitle={verarbeitungDetailsCardTitles.IncomingInvoiceBookingCreation} />
      )}
      isLoading={false}
      sidebarMenuList={menuListIncomingInvoiceBookingCreation}
    />
  );
};

export default IncomingInvoiceBookingCreationDetailsPage;
