import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { ZVorschreibungsposition } from '../../zinsliste-types';

type Props = {
  vorschreibungsposition: ZVorschreibungsposition;
  width: string;
};

const ZBeVertragTableItems: FC<Props> = ({ vorschreibungsposition, width }) => (
  <View style={[pdfStyles.row, { width }]}>
    <Text style={[pdfStyles.textBulletin, { width: '25%', textAlign: 'right' }]}>{vorschreibungsposition.netto}</Text>
    <Text style={[pdfStyles.textBulletin, { width: '25%', textAlign: 'right' }]}>{vorschreibungsposition.ustSatz}</Text>
    <Text style={[pdfStyles.textBulletin, { width: '25%', textAlign: 'right' }]}>{vorschreibungsposition.ustBetrag}</Text>
    <Text style={[pdfStyles.textBulletin, { width: '25%', textAlign: 'right' }]}>{vorschreibungsposition.brutto}</Text>
  </View>
);

export default ZBeVertragTableItems;
