import { BelegTexte, TextbausteinType } from '../../../types';
import { Mahnung } from '../../../pages/PDFTemplates/templates/mahnung/mahnung-types';
import { findTextbausteinByType, replacePlatzhalterValuesWithExampleValues } from '../../../components/Template/PDFTemplates/templateHelpers';
import { TextbausteinFormValues } from '../../../components/Template/PDFTemplates/templateMapper';
import {
  einleitungstextPlatzhalter,
  ergebnistextPlatzhalter,
  schlusstextPlatzhalter,
  titeltextPlatzhalter,
} from '../../../components/Template/PDFTemplates/templateDataExamples';
import { BelegTextePlatzhalter } from '../../Belegtext/Form/belegTexteFormTypes';
import {
  getDocumentFooterWithoutTextbaustein,
  getDocumentHeaderWithoutTextbaustein,
} from '../../shared/Belegtext/Template/belegTexteWithoutTextbausteinTemplateHelper';

export const createMahndefinitionPDFTemplateData = (
  textbausteinList: TextbausteinFormValues[],
  isVersandEinschreiben: boolean,
  belegTexte?: BelegTexte | undefined,
  belegTextePlatzhalter?: BelegTextePlatzhalter | undefined,
  firmendatenId?: string | undefined
): Mahnung => {
  // FIXME: mahndefinitionDataExample should come from backend

  const einleitungstext = findTextbausteinByType(textbausteinList, TextbausteinType.Einleitungstext);
  const ergebnistext = findTextbausteinByType(textbausteinList, TextbausteinType.Ergebnistext);
  const schlusstext = findTextbausteinByType(textbausteinList, TextbausteinType.Schlusstext);
  const titeltext = findTextbausteinByType(textbausteinList, TextbausteinType.Titeltext);

  return {
    ...mahndefinitionDataExample,
    einleitungstext: replacePlatzhalterValuesWithExampleValues(einleitungstext),
    ergebnistext: replacePlatzhalterValuesWithExampleValues(ergebnistext),
    schlusstext: replacePlatzhalterValuesWithExampleValues(schlusstext),
    titelText: replacePlatzhalterValuesWithExampleValues(titeltext),
    header:
      firmendatenId && belegTexte && belegTextePlatzhalter
        ? getDocumentHeaderWithoutTextbaustein(firmendatenId, belegTexte, belegTextePlatzhalter)
        : null,
    footer:
      firmendatenId && belegTexte && belegTextePlatzhalter
        ? getDocumentFooterWithoutTextbaustein(firmendatenId, belegTexte, belegTextePlatzhalter)
        : null,
    einschreibenText: isVersandEinschreiben ? 'EINSCHREIBEN' : undefined,
  };
};

type MahndefinitionSelectableSection = 'TITELTEXT' | 'EINLEITUNGSTEXT' | 'ERGEBNISTEXT' | 'SCHLUSSTEXT';

export const mapTextbausteinToMahndefinitionSelectableSection = (type?: TextbausteinType): MahndefinitionSelectableSection | undefined => {
  if (!type) {
    return undefined;
  }

  switch (type) {
    case TextbausteinType.Titeltext:
      return 'TITELTEXT';
    case TextbausteinType.Einleitungstext:
      return 'EINLEITUNGSTEXT';
    case TextbausteinType.Ergebnistext:
      return 'ERGEBNISTEXT';
    case TextbausteinType.Schlusstext:
      return 'SCHLUSSTEXT';
    default:
      return undefined;
  }
};

const mahndefinitionDataExample = {
  type: 'MAHNUNG',
  absender: { label: 'Absender:', value: 'Hausverwaltung XY, XY-Gasse 12, 1100 Wien' },
  einleitungstext: einleitungstextPlatzhalter,
  ergebnistext: ergebnistextPlatzhalter,
  gebuchtInfo: {
    text: 'Gebucht bis:',
    bis: '21.05.2022',
  },
  mahnungBlock: {
    mahngebuehr: { text: 'Mahngebühr', value: '5,00' },
    mahnungRowList: [
      {
        belegnummer: '123',
        belegdatum: '10.1.2022',
        rechnungsbetrag: '234,56',
        zahlung: '130',
        restbetrag: '134,56',
        mahnstufe: '2',
      },
      {
        belegnummer: '124',
        belegdatum: '15.1.2022',
        rechnungsbetrag: '200,00',
        zahlung: '150,00',
        restbetrag: '50,00',
        mahnstufe: '2',
      },
      {
        belegnummer: '145',
        belegdatum: '15.2.2022',
        rechnungsbetrag: '34,80',
        zahlung: '4,80',
        restbetrag: '30,00',
        mahnstufe: '1',
      },
      {
        belegnummer: '250',
        belegdatum: '1.3.2022',
        rechnungsbetrag: '1345,60',
        zahlung: '0,00',
        restbetrag: '1345,60',
        mahnstufe: '1',
      },
    ],
    mahnungTableTitles: {
      belegnummer: 'Belegnr.',
      belegdatum: 'Belegdatum',
      rechnungsbetrag: 'Rechnungsbetrag',
      zahlung: 'Zahlung',
      restbetrag: 'Restbetrag',
      mahnstufe: 'Mahnstufe',
    },
    offenerSaldo: { text: 'Offener Saldo in EUR', value: '1565,66' },
    verzugszinsen: { text: 'Verzugszinsen', value: '0,50' },
    zwischenSumme: { text: 'Zwischensumme in EUR', value: '1560,16' },
  },
  mahnungDatumLine: 'Wien, 28.06.2017',
  rechnungsAussteller: {
    kurzBezeichnung: 'WEG Mollardg. 48',
    kurzBezeichnungText: 'Rechnungsaussteller',
    vatIdentificationNumber: 'ATU66369066',
    vatIdentificationNumberText: 'UID-Nr. Rg.-Aussteller',
    bankDetails: {
      titel: 'Unsere Bankverbindung',
      iban: 'AT022050302101023600',
      bic: 'GIBAATWWXXX',
      accountHolder: 'Max Mustermann',
      accountHolderText: 'Kontoinhaber',
      bankname: 'Erste Bank und Sparkasse',
      bankText: 'Bank',
    },
  },
  rechnungsEmpfaenger: {
    rechnungsEmpfaengerShippingLabel: {
      line1: 'Herr',
      line2: 'Valenta Andreas',
      line3: 'Mollardgasse 48/TOP6',
      line4: '1060 Wien',
      line5: null,
      line6: null,
      line7: null,
    },
    kundennummer: {
      text: 'Kundennummer:',
      nummer: 'D-23245',
    },
  },
  sachbearbeiter: {
    heading: 'Ihr Sachbearbeiter',
    title: 'Dr.',
    firstname: 'Alexander',
    lastname: 'Wegerer',
    titleTrailing: 'B.Sc.',
    email: 'a.wegerer@@andromeda.software',
    emailText: 'E-Mail',
    phone: null,
    phoneText: 'Telefon',
  },
  schlusstext: schlusstextPlatzhalter,
  titelText: titeltextPlatzhalter,
};
