import React, { FC } from 'react';
import { MenuProps, Modal } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EuroCircleOutlined,
  EyeOutlined,
  HistoryOutlined,
  PlusSquareOutlined,
  ReadOutlined,
  TruckOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { generatePath } from 'react-router';
import { IncomingInvoiceListEntry, IncomingInvoiceStatus } from '../../../../../types';
import { useToggle } from '../../../../../hooks/useToggle';
import { useDeleteSingleInvoiceMutation, useSingleInvoiceFreigebenMutation } from '../../../gql/SingleInvoiceMutations.types';
import { showSuccessMsgDelete, showSuccessMsgFreigegeben } from '../../../../../components/message/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { isStatusErstellt, isStatusUebertragen, isStatusUebertragenFehler } from '../../../../../helpers/statusHelper';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import { generatePathToOffenePostenPage } from '../../../../OffenePosten/offenePostenUriPaths';
import { isPaymentMethodUeberweisung } from '../../../../Payment/paymentHelpers';
import { generatePathToPaymentPage } from '../../../../Payment/Listing/Filters/filtersQueryParams';
import PaymentCreateForm from '../../../../Payment/Form/Create/PaymentCreateForm';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';
import SingleInvoiceChangeHistoryListingTable from '../../../Form/shared/SingleInvoiceChangeHistoryListingTable';
import { isIncomingInvoiceFreigebenAllowed, isIncomingInvoiceUpdateAllowed } from '../../../incomingInvoiceHelpers';
import { generatePathToSingleInvoiceDetailsPage, incomingInvoicePaths } from '../../../incomingInvoiceUriPaths';
import { showConfirmIncomingInvoiceDelete } from '../../../shared/incomingInvoiceModals';
import { gqlOperations } from '../../../../../graphql/gqlNamedOperations-generated';
import { generatePathToBookingDetailsPage } from '../../../../BookingDetails/bookingDetailsPaths';

type Props = {
  invoice: IncomingInvoiceListEntry;
  onAction: () => void;
};

const SingleInvoiceTableActions: FC<Props> = ({ invoice, onAction }) => {
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const [isZahlungCreateOpen, toggleZahlungCreateOpen] = useToggle();

  const [eingangsRechnungFreigeben] = useSingleInvoiceFreigebenMutation({
    variables: { belegId: invoice.belegId },
    onCompleted: () => {
      showSuccessMsgFreigegeben('Eingangsrechnung');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDelete] = useDeleteSingleInvoiceMutation({
    variables: { belegId: invoice.belegId },
    onCompleted: () => {
      showSuccessMsgDelete('Eingangsrechnung');
    },
    refetchQueries: () => [gqlOperations.Query.IncomingInvoiceList, gqlOperations.Query.IncomingInvoiceListSummary],
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Details ansehen',
      onClick: () => window.open(generatePathToSingleInvoiceDetailsPage(invoice.fileId, invoice.belegId)),
      icon: <TruckOutlined />,
    },
    {
      key: '2a',
      label: 'Buchungsdetails ansehen',
      disabled: invoice.status.value !== IncomingInvoiceStatus.Uebertragen,
      onClick: () => window.open(generatePathToBookingDetailsPage(invoice.buchungIdList, undefined, invoice.belegId)),
      icon: <ReadOutlined />,
    },
    isIncomingInvoiceUpdateAllowed(invoice.status.value) || isStatusUebertragenFehler(invoice.status)
      ? {
          key: '3',
          label: 'Bearbeiten',
          onClick: () =>
            window.open(
              generatePath(incomingInvoicePaths.singleInvoiceUpdate, {
                fileId: invoice.fileId,
                belegId: invoice.belegId,
              })
            ),
          icon: <EditOutlined />,
        }
      : null,
    !isIncomingInvoiceUpdateAllowed(invoice.status.value)
      ? {
          key: '4',
          label: 'Ansehen',
          onClick: () =>
            window.open(
              generatePath(incomingInvoicePaths.singleInvoiceDetails, {
                fileId: invoice.fileId,
                belegId: invoice.belegId,
              })
            ),
          icon: <EyeOutlined />,
        }
      : null,
    {
      key: '5',
      label: 'Änderungsverlauf',
      onClick: () => toggleChangeHistoryOpen(),
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    ...(isStatusErstellt(invoice.status)
      ? [
          {
            key: '6',
            label: 'Verbuchen',
            onClick: () => eingangsRechnungFreigeben(),
            icon: <ReadOutlined />,
            disabled: !isIncomingInvoiceFreigebenAllowed(invoice.status.value),
          },
          {
            key: '7',
            label: 'Löschen',
            onClick: () => showConfirmIncomingInvoiceDelete(runDelete),
            danger: true,
            icon: <DeleteOutlined />,
          },
        ]
      : []),
    isStatusUebertragen(invoice.status) && invoice.buchungIdList.length > 0
      ? {
          key: '8',
          label: 'Offene Posten anzeigen',
          icon: <WalletOutlined />,
          onClick: () =>
            window.open(
              generatePathToOffenePostenPage({
                buchungskreisId: invoice.buchungskreisId,
                belegnummer: invoice.rechnungsNummer,
                vertragspartnerId: invoice.issuerRechtstraegerId,
                belegDatumTo: invoice.rechnungsDatum,
                belegDatumFrom: invoice.rechnungsDatum,
              })
            ),
        }
      : null,
    ...(isPaymentMethodUeberweisung(invoice.paymentMethod.value) && !isStatusErstellt(invoice.status)
      ? [
          {
            key: '9',
            label: 'Zahlungen anzeigen',
            icon: <EuroCircleOutlined />,
            onClick: () =>
              window.open(
                generatePathToPaymentPage({
                  belegnummer: invoice.rechnungsNummer,
                  invoiceDateFrom: invoice.rechnungsDatum,
                  invoiceDateTo: invoice.rechnungsDatum,
                  // INFO: This is the correct way of passing the values for Eingansrechnung. Don't change it
                  rechnungsAusstellerId: invoice.payeeRechtstraegerId,
                  payeeId: invoice.issuerRechtstraegerId,
                })
              ),
            disabled: invoice.paymentIdList.length <= 0,
          },
          {
            key: '10',
            label: 'Zahlung erstellen',
            icon: <PlusSquareOutlined />,
            onClick: toggleZahlungCreateOpen,
            disabled: !invoice.paymentCreatable,
          },
        ]
      : []),
  ];

  const onActionSuccess = () => {
    onAction();
    toggleZahlungCreateOpen();
  };

  return (
    <>
      <ActionDropdown items={items} />
      <Modal
        title="Zahlung erstellen"
        onCancel={toggleZahlungCreateOpen}
        open={isZahlungCreateOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <PaymentCreateForm
          onCancel={toggleZahlungCreateOpen}
          onSuccess={onActionSuccess}
          belegId={invoice.belegId}
          paymentMethod={invoice.paymentMethod.value}
          outstandingAmount={invoice.offenerBetrag}
        />
      </Modal>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <SingleInvoiceChangeHistoryListingTable belegId={invoice.belegId} />
      </HistoryModal>
    </>
  );
};

export default SingleInvoiceTableActions;
