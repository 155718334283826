import React, { FC } from 'react';
import { Button, Divider, Dropdown, MenuProps, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';

type Props = {
  onBack?: () => void;
  isBackDisabled?: boolean;
  isBackHidden?: boolean;
  onNext?: () => void;
  isNextDisabled?: boolean;
  isNextHidden?: boolean;
  onCancel?: () => void;
  isCancelDisabled?: boolean;
  onSave?: () => void;
  isSaveDisabled?: boolean;
  onDelete?: () => void;
  isDeleteDisabled?: boolean;
  isDropdownDisabled?: boolean;
  isDropdownHidden?: boolean;
};

const StepButtons: FC<Props> = ({
  isCancelDisabled,
  onCancel,
  isSaveDisabled,
  onSave,
  onDelete,
  isBackDisabled,
  isBackHidden,
  onBack,
  isNextDisabled,
  isNextHidden,
  onNext,
  isDropdownDisabled,
  isDropdownHidden,
}) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Abbrechen',
      onClick: () => onCancel?.(),
      disabled: isCancelDisabled,
    },
    {
      key: '2',
      label: 'Speichern',
      onClick: () => onSave?.(),
      disabled: isSaveDisabled,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: () => onDelete?.(),
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Divider style={{ margin: 8 }} />
      <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
        {!isDropdownHidden && (
          <Dropdown disabled={isDropdownDisabled} menu={{ items }}>
            <Button disabled={isDropdownDisabled}>
              Abbrechen
              <DownOutlined />
            </Button>
          </Dropdown>
        )}
        {!isBackHidden && (
          <Button type="default" disabled={isBackDisabled} onClick={() => onBack?.()}>
            Zurück
          </Button>
        )}
        {!isNextHidden && (
          <Button type="primary" disabled={isNextDisabled} onClick={() => onNext?.()}>
            Weiter
          </Button>
        )}
      </ButtonsAligned>
    </Space>
  );
};

export default StepButtons;
