import React, { useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { vorschreibungsPositionWertFormFields, VorschreibungspositionWertFormValues } from '../vertragVorschreibungsPositionWertMapper';
import { isNettoValid, isUstValid } from '../vertragVorschreibungsPositionWertSchema';
import { calculateBrutto } from '../../../../../../../../shared/BruttoNettoUst/bruttoNettoCalculator';
import { VorschreibungspositionBasis } from '../../../../../../../../types';
import SkeletonIfSteuersatzLoading from '../SkeletonIfSteuersatzLoading';
import FormattedDecimalFormInput from '../../../../../../../../components/Input-antd/FormattedDecimalFormInput';
import { TFormattedDecimal } from '../../../../../../../../helpers/formikHelper';
import FormItemWithTooltip from '../../../../../../../../components/Form/FormItemWithTooltip';

const BruttoFormItem = () => {
  const fieldNameBrutto = vorschreibungsPositionWertFormFields.brutto;
  const [, , { setValue, setTouched }] = useField<TFormattedDecimal>(fieldNameBrutto);
  const { values } = useFormikContext<VorschreibungspositionWertFormValues>();

  const currentBasis = values.basis;
  const currentNetto = values.netto;
  const currentUst = values.ust;

  const previousUstRef = useRef<number | null>();

  const previousUst = previousUstRef.current;

  useEffect(() => {
    // if basis netto and netto or ust changes => update brutto
    // not directly dependent on steuersatz because if steuersatz changes ust will be changed firstly and that will trigger changing of brutto
    if (currentBasis === VorschreibungspositionBasis.Netto) {
      if (previousUst === currentUst) {
        return;
      }
      if (!isNettoValid(currentNetto) || !isUstValid(currentUst)) {
        return;
      }
      const brutto = calculateBrutto(currentNetto as number, currentUst);
      setValue(brutto);
      setTouched(true);
    }
  });

  useEffect(() => {
    previousUstRef.current = currentUst;
  });

  if (currentBasis === VorschreibungspositionBasis.Brutto) {
    return (
      <SkeletonIfSteuersatzLoading>
        <FormItemWithTooltip name={vorschreibungsPositionWertFormFields.brutto} style={{ margin: 0 }}>
          <FormattedDecimalFormInput size="small" name={vorschreibungsPositionWertFormFields.brutto} isCurrency style={{ width: '100%' }} />
        </FormItemWithTooltip>
      </SkeletonIfSteuersatzLoading>
    );
  } else {
    return (
      <SkeletonIfSteuersatzLoading>
        <FormItemWithTooltip name={vorschreibungsPositionWertFormFields.brutto} style={{ margin: 0 }}>
          <FormattedDecimalFormInput size="small" name={vorschreibungsPositionWertFormFields.brutto} isCurrency style={{ width: '100%' }} disabled />
        </FormItemWithTooltip>
      </SkeletonIfSteuersatzLoading>
    );
  }
};

export default BruttoFormItem;
