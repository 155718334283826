import React from 'react';
import AbrKontenzuordnungTemplateListing from '../../../../features/AbrKontenzuordnungTemplate/Listing/AbrKontenzuordnungTemplateListing';

const AndromedaSysSettingsAbrKontenzuordnungPage = () => (
  <div style={{ paddingTop: '8px' }}>
    <AbrKontenzuordnungTemplateListing />
  </div>
);

export default AndromedaSysSettingsAbrKontenzuordnungPage;
