import { FC, PropsWithChildren } from 'react';
import { Tooltip } from 'antd';

type Props = {
  isDisabled: boolean;
};

const EditableOrDisabledDrawerField: FC<Props & PropsWithChildren> = ({ isDisabled, children }) =>
  isDisabled ? (
    <Tooltip placement="top" title="Das Konto wurde bereits in einer Buchung verwendet">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );

export default EditableOrDisabledDrawerField;
