import { Space } from 'antd';
import { FC, PropsWithChildren } from 'react';

const FiltersColumn: FC<PropsWithChildren> = ({ children }) => (
  <Space direction="vertical" style={{ width: '332px' }}>
    {children}
  </Space>
);

export default FiltersColumn;
