import React, { FC } from 'react';
import { Typography } from 'antd';
import { Address, AddressType } from '../../../../../types';

type Props = {
  postAddress: Address;
};

const VersandCardPostAddress: FC<Props> = ({ postAddress }) => {
  let address;
  if (postAddress.type === AddressType.PostofficeBox) {
    address = `${postAddress.postofficeBoxNumber} ${postAddress.postofficeBoxZipCode}, ${postAddress.city} `;
  } else {
    address = `${postAddress.street} ${postAddress.houseEntranceApartmentNumber}, ${postAddress.zipCode} ${postAddress.city}`;
  }

  return <Typography.Text>{address}</Typography.Text>;
};

export default VersandCardPostAddress;
