import { FormFields } from '../../../helpers/formikHelper';
import { IndexValueCreationCronJobSettings } from '../../../types';

export type IndexValueCreationCronJobSettingsFormValues = {
  indexAnnual: boolean;
  indexThreshold: boolean;
};

export const indexSeriesEntryFormFields: FormFields<IndexValueCreationCronJobSettingsFormValues> = {
  indexAnnual: 'indexAnnual',
  indexThreshold: 'indexThreshold',
};

export const mapIndexValueCreationCronJobSettingsToFormValues = (
  indexValueCreationCronJobSettings: IndexValueCreationCronJobSettings
): IndexValueCreationCronJobSettingsFormValues => ({
  indexAnnual: indexValueCreationCronJobSettings.indexAnnual,
  indexThreshold: indexValueCreationCronJobSettings.indexThreshold,
});
