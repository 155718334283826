import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { TextbausteinFieldsFragmentDoc } from '../../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import { InfoMailDeliveryFieldsFragmentDoc } from './InfoMailDeliveryWizardFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InfoMailDeliveryWizardTemplateStepQueryVariables = Types.Exact<{
  infoMailDeliveryWizardId: Types.Scalars['ID']['input'];
}>;

export type InfoMailDeliveryWizardTemplateStepQuery = {
  getInfoMailDeliveryWizardTemplateStep: {
    data: {
      completed: boolean;
      currentStep: number;
      emailDeliveryDefinitionBezeichnung: string;
      emailDeliveryDefinitionId: string;
      stepCount: number;
      wizardId: string;
      attachmentInfoList: Array<{ fileId: string; name: string; size: number }>;
      body?: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      } | null;
      subject: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type InfoMailDeliveryWizardDetermineRecipientListStepQueryVariables = Types.Exact<{
  infoMailDeliveryWizardId: Types.Scalars['ID']['input'];
}>;

export type InfoMailDeliveryWizardDetermineRecipientListStepQuery = {
  getInfoMailDeliveryWizardDetermineRecipientListStep: {
    data: { completed: boolean; currentStep: number; wizardId: string; generierlaufId: string; stepCount: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type InfoMailDeliveryWizardRecipientListQueryVariables = Types.Exact<{
  infoMailDeliveryWizardId: Types.Scalars['ID']['input'];
  order?: Types.InputMaybe<Types.InfoMailDeliveryRecipientListStepOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type InfoMailDeliveryWizardRecipientListQuery = {
  getInfoMailDeliveryWizardRecipientList: {
    data: {
      contentList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        emailAddress: string;
        emailDeliveryDefinitionBezeichnung: string;
        emailDeliveryDefinitionId: string;
        infoMailDeliveryId: string;
        mailId?: string | null;
        original: boolean;
        sender?: string | null;
        sentTs?: string | null;
        shouldSend: boolean;
        objektTopVertrag: {
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          createTs: string;
          name: string;
          bestandseinheit: {
            bestandseinheitId: string;
            bezeichnung: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            createTs: string;
            objektId: string;
            status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          objekt: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            erstelltAm: string;
            fotoFileId?: string | null;
            fotoType?: Types.FotoType | null;
            kurzBezeichnung: string;
            objektId: string;
            rechnungsAusstellerId: string;
            verwaltungBis?: string | null;
            status: { text: string; value: Types.ObjektStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          vertrag: {
            bestandseinheitId: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            createTs: string;
            kurzBezeichnung: string;
            objektId: string;
            vertragsBeginn: string;
            vertragId: string;
            vertragspartner: {
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              createTs: string;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              type: { text: string; value: Types.RechtstraegerType };
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
            status: { description?: string | null; text: string; value: Types.VertragStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        recipient: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vertragspartner: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type InfoMailDeliveryWizardRecipientListStepQueryVariables = Types.Exact<{
  infoMailDeliveryWizardId: Types.Scalars['ID']['input'];
}>;

export type InfoMailDeliveryWizardRecipientListStepQuery = {
  getInfoMailDeliveryWizardRecipientListStep: {
    data: { completed: boolean; currentStep: number; wizardId: string; sendGenerierlaufId?: string | null; stepCount: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type InfoMailDeliveryAttachmentListQueryVariables = Types.Exact<{
  infoMailDeliveryWizardId: Types.Scalars['String']['input'];
  infoMailDeliveryId: Types.Scalars['ID']['input'];
}>;

export type InfoMailDeliveryAttachmentListQuery = {
  getInfoMailDeliveryAttachmentList: {
    data: Array<{ fileId: string; name: string; size: number }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const InfoMailDeliveryWizardTemplateStepDocument = gql`
  query InfoMailDeliveryWizardTemplateStep($infoMailDeliveryWizardId: ID!) {
    getInfoMailDeliveryWizardTemplateStep(infoMailDeliveryWizardId: $infoMailDeliveryWizardId) {
      data {
        attachmentInfoList {
          fileId
          name
          size
        }
        completed
        currentStep
        body {
          ...TextbausteinFields
        }
        emailDeliveryDefinitionBezeichnung
        emailDeliveryDefinitionId
        stepCount
        subject {
          ...TextbausteinFields
        }
        wizardId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
export function useInfoMailDeliveryWizardTemplateStepQuery(
  baseOptions: Apollo.QueryHookOptions<InfoMailDeliveryWizardTemplateStepQuery, InfoMailDeliveryWizardTemplateStepQueryVariables> &
    ({ variables: InfoMailDeliveryWizardTemplateStepQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InfoMailDeliveryWizardTemplateStepQuery, InfoMailDeliveryWizardTemplateStepQueryVariables>(
    InfoMailDeliveryWizardTemplateStepDocument,
    options
  );
}
export function useInfoMailDeliveryWizardTemplateStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InfoMailDeliveryWizardTemplateStepQuery, InfoMailDeliveryWizardTemplateStepQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InfoMailDeliveryWizardTemplateStepQuery, InfoMailDeliveryWizardTemplateStepQueryVariables>(
    InfoMailDeliveryWizardTemplateStepDocument,
    options
  );
}
export function useInfoMailDeliveryWizardTemplateStepSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InfoMailDeliveryWizardTemplateStepQuery, InfoMailDeliveryWizardTemplateStepQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InfoMailDeliveryWizardTemplateStepQuery, InfoMailDeliveryWizardTemplateStepQueryVariables>(
    InfoMailDeliveryWizardTemplateStepDocument,
    options
  );
}
export type InfoMailDeliveryWizardTemplateStepQueryHookResult = ReturnType<typeof useInfoMailDeliveryWizardTemplateStepQuery>;
export type InfoMailDeliveryWizardTemplateStepLazyQueryHookResult = ReturnType<typeof useInfoMailDeliveryWizardTemplateStepLazyQuery>;
export type InfoMailDeliveryWizardTemplateStepSuspenseQueryHookResult = ReturnType<typeof useInfoMailDeliveryWizardTemplateStepSuspenseQuery>;
export type InfoMailDeliveryWizardTemplateStepQueryResult = Apollo.QueryResult<
  InfoMailDeliveryWizardTemplateStepQuery,
  InfoMailDeliveryWizardTemplateStepQueryVariables
>;
export const InfoMailDeliveryWizardDetermineRecipientListStepDocument = gql`
  query InfoMailDeliveryWizardDetermineRecipientListStep($infoMailDeliveryWizardId: ID!) {
    getInfoMailDeliveryWizardDetermineRecipientListStep(infoMailDeliveryWizardId: $infoMailDeliveryWizardId) {
      data {
        completed
        currentStep
        wizardId
        generierlaufId
        stepCount
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useInfoMailDeliveryWizardDetermineRecipientListStepQuery(
  baseOptions: Apollo.QueryHookOptions<
    InfoMailDeliveryWizardDetermineRecipientListStepQuery,
    InfoMailDeliveryWizardDetermineRecipientListStepQueryVariables
  > &
    ({ variables: InfoMailDeliveryWizardDetermineRecipientListStepQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InfoMailDeliveryWizardDetermineRecipientListStepQuery, InfoMailDeliveryWizardDetermineRecipientListStepQueryVariables>(
    InfoMailDeliveryWizardDetermineRecipientListStepDocument,
    options
  );
}
export function useInfoMailDeliveryWizardDetermineRecipientListStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InfoMailDeliveryWizardDetermineRecipientListStepQuery,
    InfoMailDeliveryWizardDetermineRecipientListStepQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InfoMailDeliveryWizardDetermineRecipientListStepQuery, InfoMailDeliveryWizardDetermineRecipientListStepQueryVariables>(
    InfoMailDeliveryWizardDetermineRecipientListStepDocument,
    options
  );
}
export function useInfoMailDeliveryWizardDetermineRecipientListStepSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InfoMailDeliveryWizardDetermineRecipientListStepQuery,
        InfoMailDeliveryWizardDetermineRecipientListStepQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InfoMailDeliveryWizardDetermineRecipientListStepQuery,
    InfoMailDeliveryWizardDetermineRecipientListStepQueryVariables
  >(InfoMailDeliveryWizardDetermineRecipientListStepDocument, options);
}
export type InfoMailDeliveryWizardDetermineRecipientListStepQueryHookResult = ReturnType<
  typeof useInfoMailDeliveryWizardDetermineRecipientListStepQuery
>;
export type InfoMailDeliveryWizardDetermineRecipientListStepLazyQueryHookResult = ReturnType<
  typeof useInfoMailDeliveryWizardDetermineRecipientListStepLazyQuery
>;
export type InfoMailDeliveryWizardDetermineRecipientListStepSuspenseQueryHookResult = ReturnType<
  typeof useInfoMailDeliveryWizardDetermineRecipientListStepSuspenseQuery
>;
export type InfoMailDeliveryWizardDetermineRecipientListStepQueryResult = Apollo.QueryResult<
  InfoMailDeliveryWizardDetermineRecipientListStepQuery,
  InfoMailDeliveryWizardDetermineRecipientListStepQueryVariables
>;
export const InfoMailDeliveryWizardRecipientListDocument = gql`
  query InfoMailDeliveryWizardRecipientList(
    $infoMailDeliveryWizardId: ID!
    $order: InfoMailDeliveryRecipientListStepOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getInfoMailDeliveryWizardRecipientList(
      infoMailDeliveryWizardId: $infoMailDeliveryWizardId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...InfoMailDeliveryFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${InfoMailDeliveryFieldsFragmentDoc}
`;
export function useInfoMailDeliveryWizardRecipientListQuery(
  baseOptions: Apollo.QueryHookOptions<InfoMailDeliveryWizardRecipientListQuery, InfoMailDeliveryWizardRecipientListQueryVariables> &
    ({ variables: InfoMailDeliveryWizardRecipientListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InfoMailDeliveryWizardRecipientListQuery, InfoMailDeliveryWizardRecipientListQueryVariables>(
    InfoMailDeliveryWizardRecipientListDocument,
    options
  );
}
export function useInfoMailDeliveryWizardRecipientListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InfoMailDeliveryWizardRecipientListQuery, InfoMailDeliveryWizardRecipientListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InfoMailDeliveryWizardRecipientListQuery, InfoMailDeliveryWizardRecipientListQueryVariables>(
    InfoMailDeliveryWizardRecipientListDocument,
    options
  );
}
export function useInfoMailDeliveryWizardRecipientListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InfoMailDeliveryWizardRecipientListQuery, InfoMailDeliveryWizardRecipientListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InfoMailDeliveryWizardRecipientListQuery, InfoMailDeliveryWizardRecipientListQueryVariables>(
    InfoMailDeliveryWizardRecipientListDocument,
    options
  );
}
export type InfoMailDeliveryWizardRecipientListQueryHookResult = ReturnType<typeof useInfoMailDeliveryWizardRecipientListQuery>;
export type InfoMailDeliveryWizardRecipientListLazyQueryHookResult = ReturnType<typeof useInfoMailDeliveryWizardRecipientListLazyQuery>;
export type InfoMailDeliveryWizardRecipientListSuspenseQueryHookResult = ReturnType<typeof useInfoMailDeliveryWizardRecipientListSuspenseQuery>;
export type InfoMailDeliveryWizardRecipientListQueryResult = Apollo.QueryResult<
  InfoMailDeliveryWizardRecipientListQuery,
  InfoMailDeliveryWizardRecipientListQueryVariables
>;
export const InfoMailDeliveryWizardRecipientListStepDocument = gql`
  query InfoMailDeliveryWizardRecipientListStep($infoMailDeliveryWizardId: ID!) {
    getInfoMailDeliveryWizardRecipientListStep(infoMailDeliveryWizardId: $infoMailDeliveryWizardId) {
      data {
        completed
        currentStep
        wizardId
        sendGenerierlaufId
        stepCount
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useInfoMailDeliveryWizardRecipientListStepQuery(
  baseOptions: Apollo.QueryHookOptions<InfoMailDeliveryWizardRecipientListStepQuery, InfoMailDeliveryWizardRecipientListStepQueryVariables> &
    ({ variables: InfoMailDeliveryWizardRecipientListStepQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InfoMailDeliveryWizardRecipientListStepQuery, InfoMailDeliveryWizardRecipientListStepQueryVariables>(
    InfoMailDeliveryWizardRecipientListStepDocument,
    options
  );
}
export function useInfoMailDeliveryWizardRecipientListStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InfoMailDeliveryWizardRecipientListStepQuery, InfoMailDeliveryWizardRecipientListStepQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InfoMailDeliveryWizardRecipientListStepQuery, InfoMailDeliveryWizardRecipientListStepQueryVariables>(
    InfoMailDeliveryWizardRecipientListStepDocument,
    options
  );
}
export function useInfoMailDeliveryWizardRecipientListStepSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InfoMailDeliveryWizardRecipientListStepQuery, InfoMailDeliveryWizardRecipientListStepQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InfoMailDeliveryWizardRecipientListStepQuery, InfoMailDeliveryWizardRecipientListStepQueryVariables>(
    InfoMailDeliveryWizardRecipientListStepDocument,
    options
  );
}
export type InfoMailDeliveryWizardRecipientListStepQueryHookResult = ReturnType<typeof useInfoMailDeliveryWizardRecipientListStepQuery>;
export type InfoMailDeliveryWizardRecipientListStepLazyQueryHookResult = ReturnType<typeof useInfoMailDeliveryWizardRecipientListStepLazyQuery>;
export type InfoMailDeliveryWizardRecipientListStepSuspenseQueryHookResult = ReturnType<
  typeof useInfoMailDeliveryWizardRecipientListStepSuspenseQuery
>;
export type InfoMailDeliveryWizardRecipientListStepQueryResult = Apollo.QueryResult<
  InfoMailDeliveryWizardRecipientListStepQuery,
  InfoMailDeliveryWizardRecipientListStepQueryVariables
>;
export const InfoMailDeliveryAttachmentListDocument = gql`
  query InfoMailDeliveryAttachmentList($infoMailDeliveryWizardId: String!, $infoMailDeliveryId: ID!) {
    getInfoMailDeliveryAttachmentList(infoMailDeliveryWizardId: $infoMailDeliveryWizardId, infoMailDeliveryId: $infoMailDeliveryId) {
      data {
        fileId
        name
        size
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useInfoMailDeliveryAttachmentListQuery(
  baseOptions: Apollo.QueryHookOptions<InfoMailDeliveryAttachmentListQuery, InfoMailDeliveryAttachmentListQueryVariables> &
    ({ variables: InfoMailDeliveryAttachmentListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InfoMailDeliveryAttachmentListQuery, InfoMailDeliveryAttachmentListQueryVariables>(
    InfoMailDeliveryAttachmentListDocument,
    options
  );
}
export function useInfoMailDeliveryAttachmentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InfoMailDeliveryAttachmentListQuery, InfoMailDeliveryAttachmentListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InfoMailDeliveryAttachmentListQuery, InfoMailDeliveryAttachmentListQueryVariables>(
    InfoMailDeliveryAttachmentListDocument,
    options
  );
}
export function useInfoMailDeliveryAttachmentListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InfoMailDeliveryAttachmentListQuery, InfoMailDeliveryAttachmentListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InfoMailDeliveryAttachmentListQuery, InfoMailDeliveryAttachmentListQueryVariables>(
    InfoMailDeliveryAttachmentListDocument,
    options
  );
}
export type InfoMailDeliveryAttachmentListQueryHookResult = ReturnType<typeof useInfoMailDeliveryAttachmentListQuery>;
export type InfoMailDeliveryAttachmentListLazyQueryHookResult = ReturnType<typeof useInfoMailDeliveryAttachmentListLazyQuery>;
export type InfoMailDeliveryAttachmentListSuspenseQueryHookResult = ReturnType<typeof useInfoMailDeliveryAttachmentListSuspenseQuery>;
export type InfoMailDeliveryAttachmentListQueryResult = Apollo.QueryResult<
  InfoMailDeliveryAttachmentListQuery,
  InfoMailDeliveryAttachmentListQueryVariables
>;
