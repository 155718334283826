import React, { FC } from 'react';
import { Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

type Props = {
  nameList: string[];
};

const InfoReferencedDataCarrierNameList: FC<Props> = ({ nameList }) => {
  if (nameList.length === 0) {
    return null;
  }

  const tooltipTitle = (
    <Space direction="vertical" size={6}>
      <div>Dieser Kontoauszug wurde über mehrere Datenträger importiert:</div>
      {nameList.map((name) => (
        <div>{name}</div>
      ))}
    </Space>
  );

  return (
    <Tooltip title={tooltipTitle} styles={{ root: { maxWidth: '300px' } }}>
      <InfoCircleOutlined />
    </Tooltip>
  );
};

export default InfoReferencedDataCarrierNameList;
