import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FibuBuchungTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FibuBuchungTypeListQuery = {
  getFibuBuchungTypeList: {
    data: Array<{ text: string; value: Types.FibuBuchungType }>;
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const FibuBuchungTypeListDocument = gql`
  query FibuBuchungTypeList {
    getFibuBuchungTypeList {
      data {
        text
        value
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFibuBuchungTypeListQuery(baseOptions?: Apollo.QueryHookOptions<FibuBuchungTypeListQuery, FibuBuchungTypeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FibuBuchungTypeListQuery, FibuBuchungTypeListQueryVariables>(FibuBuchungTypeListDocument, options);
}
export function useFibuBuchungTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FibuBuchungTypeListQuery, FibuBuchungTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FibuBuchungTypeListQuery, FibuBuchungTypeListQueryVariables>(FibuBuchungTypeListDocument, options);
}
export function useFibuBuchungTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FibuBuchungTypeListQuery, FibuBuchungTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FibuBuchungTypeListQuery, FibuBuchungTypeListQueryVariables>(FibuBuchungTypeListDocument, options);
}
export type FibuBuchungTypeListQueryHookResult = ReturnType<typeof useFibuBuchungTypeListQuery>;
export type FibuBuchungTypeListLazyQueryHookResult = ReturnType<typeof useFibuBuchungTypeListLazyQuery>;
export type FibuBuchungTypeListSuspenseQueryHookResult = ReturnType<typeof useFibuBuchungTypeListSuspenseQuery>;
export type FibuBuchungTypeListQueryResult = Apollo.QueryResult<FibuBuchungTypeListQuery, FibuBuchungTypeListQueryVariables>;
