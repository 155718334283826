import React, { FC } from 'react';
import { Box } from 'rebass';
import { Button, Drawer, MenuProps, Modal, Tabs, TabsProps } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import UstKategorieVersionTimeline from '../../Version/UstKategorieVersionTimeline';
import { useToggle } from '../../../../../hooks/useToggle';
import UstKategorieForm from '../../Form/UstKategorieForm';
import {
  useArchiveUstKategorieMutation,
  useDeleteUstKategorieMutation,
  useUnarchiveUstKategorieMutation,
} from '../../gql/UstKategorieMutations.types';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import { isStatusArchived } from '../../../../../helpers/statusHelper';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../../components/Button/ButtonsAligned';
import { UstKategorie } from '../../../../../types';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import UstKategorieChangeHistoryListingTable from './UstKategorieChangeHistoryListingTable';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';
import { UstKategorieWithStatus } from '../../ustKategorieUriHelper';

type Props = {
  onActionSuccess: () => void;
  ustKategorie: UstKategorieWithStatus;
};

const UstKategorieTableActions: FC<Props> = ({ onActionSuccess, ustKategorie }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const entity = 'USt.-Kategorie';
  const isArchived = isStatusArchived(ustKategorie.status);

  const [runDelete] = useDeleteUstKategorieMutation({
    variables: {
      ustKategorieId: ustKategorie.ustKategorieId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveUstKategorieMutation({
    variables: {
      ustKategorieId: ustKategorie.ustKategorieId,
    },
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveUstKategorieMutation({
    variables: {
      ustKategorieId: ustKategorie.ustKategorieId,
    },
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const menuItems: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(runUnarchive, ustKategorie) : showConfirmArchive(runArchive, ustKategorie),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: !!ustKategorie.deletable,
    },
    {
      key: '4',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, ustKategorie),
      icon: <DeleteOutlined />,
      disabled: !ustKategorie.deletable,
      danger: true,
    },
  ];

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined />
          Verlauf
        </span>
      ),
      children: (
        <Box p={16}>
          <UstKategorieVersionTimeline reloadUstKategorieList={onActionSuccess} ustKategorie={ustKategorie} />
        </Box>
      ),
    },
    {
      key: '2',
      label: (
        <span>
          <EditOutlined />
          Bearbeiten
        </span>
      ),
      children: (
        <Box p={16}>
          <UstKategorieForm
            ustKategorie={ustKategorie}
            onSuccess={() => {
              onActionSuccess();
              onCollapse();
            }}
            onCancel={onCollapse}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <ActionDropdown items={menuItems} />
      <Drawer
        title="USt.-Kategorie bearbeiten"
        width={720}
        onClose={onCollapse}
        open={isCollapsed}
        destroyOnClose
        footer={
          <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
            <Button onClick={onCollapse} type="primary">
              Schließen
            </Button>
          </ButtonsAligned>
        }
      >
        <Tabs defaultActiveKey="2" items={tabItems} />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <UstKategorieChangeHistoryListingTable ustKategorieId={ustKategorie.ustKategorieId} />
      </HistoryModal>
    </>
  );
};

const showConfirmArchive = (runArchive: () => void, ustKategorie: UstKategorie) => () => {
  Modal.confirm({
    title: `Möchten Sie die USt.-Kategorie archivieren?`,
    content: `${ustKategorie.kurzBezeichnung}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (runUnarchive: () => void, ustKategorie: UstKategorie) => () => {
  Modal.confirm({
    title: `Möchten Sie die USt.-Kategorie reaktivieren?`,
    content: `${ustKategorie.kurzBezeichnung}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

const showConfirmDelete = (runDelete: () => void, ustKategorie: UstKategorie) => () => {
  Modal.confirm({
    title: `Möchten Sie die USt.-Kategorie löschen?`,
    content: `${ustKategorie.kurzBezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default UstKategorieTableActions;
