import { RcFile } from 'antd/es/upload/interface';
import { FormFields, mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { PeriodUnit, TicketUpdateInput } from '../../../types';
import { mapPeriodValuesToPeriodUnit, TicketFormValues } from './ticketFormMapper';

export type TicketSendEmailFormValues = {
  toList: TicketParticipantFormValues[];
  ccList: TicketParticipantFormValues[];
  bccList: TicketParticipantFormValues[];
  subject: string;
  body: string;
  ticketAttachmentIds: string[];
  newAttachmentList: FileData[];
};

export type TicketSendEmailInput = {
  toList: TicketParticipantFormValues[];
  ccList: TicketParticipantFormValues[];
  bccList: TicketParticipantFormValues[];
  subject: string;
  body: string;
  ticketAttachmentIds: string[];
};

// type for newly added attachments
export type FileData = {
  filename: string;
  file?: RcFile;
};

export type TicketParticipantFormValues = {
  contactId?: string | null;
  emailAddress?: string | null;
  participantId?: string | null;
};

export const ticketSendEmailFormFields: FormFields<TicketSendEmailFormValues> = {
  toList: 'toList',
  ccList: 'ccList',
  bccList: 'bccList',
  subject: 'subject',
  body: 'body',
  ticketAttachmentIds: 'ticketAttachmentIds',
  newAttachmentList: 'newAttachmentList',
};

export const ticketSendEmailFormInitialValues: TicketSendEmailFormValues = {
  toList: [],
  ccList: [],
  bccList: [],
  subject: '',
  body: '',
  ticketAttachmentIds: [],
  newAttachmentList: [],
};

export const mapFormValuesToTicketSendEmailInput = (formValues: TicketSendEmailFormValues, subjectPrefix: string): TicketSendEmailInput => ({
  toList: formValues.toList,
  ccList: formValues.ccList,
  bccList: formValues.bccList,
  subject: `${subjectPrefix} ${formValues.subject}`,
  body: formValues.body,
  ticketAttachmentIds: formValues.ticketAttachmentIds,
});
