import { GenerierlaufEntryExitCode, QueryGetSubAbrechnungGenerierlaufEntryListArgs } from '../../../../../types';
import { TSubAbrechnungQueryParams } from './filtersQueryParams';
import { FormFields } from '../../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../../shared/typeHelpers';

export type FiltersFormValues = {
  objektId?: string;
  eigentuemerId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[] | null;
};

export const listingFiltersFormFields: FormFields<FiltersFormValues> = {
  objektId: 'objektId',
  eigentuemerId: 'eigentuemerId',
  exitCodeList: 'exitCodeList',
};

export const mapFormValuesToQueryParams = (values: FiltersFormValues): TSubAbrechnungQueryParams => ({
  ...values,
});

export const mapQueryParamsToSubAbrechnungGenEntryListQueryVariables = (
  queryParams: TSubAbrechnungQueryParams
): Omit<QueryGetSubAbrechnungGenerierlaufEntryListArgs, 'generierlaufId'> => ({
  ...queryParams,
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'valueDateTo' | 'amountTo'>> = {
  objektId: 'Objekt',
  eigentuemerId: 'Eigentümer',
  exitCodeList: 'Status',
};
