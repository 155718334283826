import React, { FC } from 'react';
import { Empty } from 'antd';
import { Budgeting, BudgetingResultBudgetingVPos } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import budgetingResultBudgetingVPosTableColumns from './budgetingResultBudgetingVPosTableColumns';

type Props = {
  budgeting: Budgeting;
  budgetingResultBudgetingVPosList: BudgetingResultBudgetingVPos[];
};

const BudgetingResultBudgetingVPosTable: FC<Props> = ({ budgeting, budgetingResultBudgetingVPosList }) => (
  <NestedTable<BudgetingResultBudgetingVPos>
    colWidth={100}
    level={5}
    locale={{
      emptyText: <Empty description={<span>Keine Vertragswerte vorhanden</span>} />,
    }}
    dataSource={budgetingResultBudgetingVPosList}
    columns={budgetingResultBudgetingVPosTableColumns(budgeting)}
    rowKey={(record) => record.budgetingVPos.budgetingVPosId}
  />
);
export default BudgetingResultBudgetingVPosTable;
