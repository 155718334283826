import React from 'react';
import { Button, Row, Col, Typography } from 'antd';

const AndromedaSysSettingsTokenPage = () => (
  <Row>
    <Col>
      <Typography.Title level={4}>Token Einstellungen</Typography.Title>
      <p>Ablaufzeit</p>
      <p>Mitarbeitereinladung: MM:SS, DD-MM-YYYY</p>
      <Button size="small">Bearbeiten</Button>
    </Col>
  </Row>
);

export default AndromedaSysSettingsTokenPage;
