import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import AndromedaSysSettingsAufteilungsschluesselPage from './AndromedaSysSettingsAufteilungsschluesselPage';
import { isAdmin } from '../../../../security/permissionChecks';

const andromedaSysSettingsAufteilungsschluesselPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.aufteilungsschluesselPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsAufteilungsschluesselPage />} />}
  />
);

export default andromedaSysSettingsAufteilungsschluesselPageRoute;
