import { isNil } from 'lodash';

export const isLastIndexOfArray = (currentIndex: number, array: Record<any, any>) => {
  if (isNil(array)) {
    throw new TypeError('array must not be null or undefined');
  }
  return currentIndex === array.length - 1;
};

export const isArrayNotEmpty = (list: any[]): boolean => list.length > 0;
