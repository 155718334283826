import { isEmpty } from 'lodash';
import { ContactType } from './contactHelper';

export interface ContactModel {
  addressId?: string | null;
  type: string;
  wert: string;
  operatingSiteId?: string;
}

export type ContactFormValues = {
  addressId?: string | null;
  wert: string;
};

export const newContactObject: ContactFormValues = { wert: '' };

export const mapContactListToFormValues = (contactList: ContactModel[] = [], contactType: ContactType): ContactFormValues[] => {
  const formContactList = contactList
    .filter((contact) => contact.type === contactType)
    .map((contact) => ({
      addressId: contact.addressId,
      wert: contact.wert,
    }));
  if (isEmpty(formContactList)) {
    return [newContactObject];
  }
  return formContactList;
};

export const mapFormValuesToContactList = (formEmailList?: ContactFormValues[], formPhoneList?: ContactFormValues[]) => [
  ...mapFormEmailListToContactList(formEmailList),
  ...mapFormPhoneListToContactList(formPhoneList),
];

const mapFormEmailListToContactList = (emailList: ContactFormValues[] = []): ContactModel[] =>
  emailList
    .filter((email) => email.wert)
    .map((email) => ({
      wert: email.wert,
      type: ContactType.EMAIL,
    }));

const mapFormPhoneListToContactList = (phoneList: ContactFormValues[] = []): ContactModel[] =>
  phoneList
    .filter((phone) => phone.wert)
    .map((phone) => ({
      wert: phone.wert,
      type: ContactType.PHONE,
    }));
