import React, { FC } from 'react';
import { Spin, Tabs, TabsProps } from 'antd';
import MahnstufeTemplate from './MahnstufeTemplate';
import MahnungTemplate from '../../../pages/PDFTemplates/templates/mahnung/MahnungTemplate';
import { createMahndefinitionPDFTemplateData, mapTextbausteinToMahndefinitionSelectableSection } from './mahndefinitionTemplateFormMapper';
import { TextbausteinFormValues } from '../../../components/Template/PDFTemplates/templateMapper';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useFirmendatenQuery } from '../../KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { useBelegTexteFirmendatenQuery } from '../../Belegtext/gql/BelegtexteQueries.types';
import { useFirmendatenOperatingSiteListQuery } from '../../KundenSystem/Firmendaten/gql/FirmendatenOperatingSiteQueries.types';
import { BelegTextePlatzhalter } from '../../Belegtext/Form/belegTexteFormTypes';
import { findBelegTexteHauptAddress, findBelegTexteHauptEmail, findBelegTextePhoneNumber } from '../../shared/operatingSiteHelpers';

type Props = {
  mahnstufe1TemplateValues: TextbausteinFormValues[];
  onMahnstufe1TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  mahnstufe2TemplateValues: TextbausteinFormValues[];
  onMahnstufe2TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  mahnstufe3TemplateValues: TextbausteinFormValues[];
  onMahnstufe3TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  mahnstufe4TemplateValues: TextbausteinFormValues[];
  onMahnstufe4TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  isVersandEinschreiben1: boolean;
  isVersandEinschreiben2: boolean;
  isVersandEinschreiben3: boolean;
  isVersandEinschreiben4: boolean;
};

const MahndefinitionTemplates: FC<Props> = ({
  mahnstufe1TemplateValues,
  onMahnstufe1TemplateValuesChange,
  mahnstufe2TemplateValues,
  onMahnstufe2TemplateValuesChange,
  mahnstufe3TemplateValues,
  onMahnstufe3TemplateValuesChange,
  mahnstufe4TemplateValues,
  onMahnstufe4TemplateValuesChange,
  isVersandEinschreiben1,
  isVersandEinschreiben2,
  isVersandEinschreiben3,
  isVersandEinschreiben4,
}) => {
  const { activeForFirmendatenId: firmendatenId } = useFDAuthorized();

  const { data: firmendatenData } = useFirmendatenQuery({ variables: { firmendatenId } });
  const firmendaten = firmendatenData?.getFirmendaten.data;

  const { data: belegTexteData } = useBelegTexteFirmendatenQuery({ variables: { firmendatenId } });

  const belegTexte = belegTexteData?.getBelegTexteFirmendaten.data;

  const { data: firmendatenOperatingSiteListData } = useFirmendatenOperatingSiteListQuery({ variables: { firmendatenId } });
  const operatingSiteList = firmendatenOperatingSiteListData?.getFirmendatenOperatingSiteList.data ?? [];

  if (!firmendaten || !belegTexte || !operatingSiteList) return <Spin />;

  const belegTextePlatzhalter: BelegTextePlatzhalter = {
    hauptAddress: findBelegTexteHauptAddress(operatingSiteList),
    vatIdentificationNumber: firmendaten.vatIdentificationNumber,
    companyRegistrationNumber: firmendaten.companyRegistrationNumber,
    phoneNumber: findBelegTextePhoneNumber(operatingSiteList),
    email: findBelegTexteHauptEmail(operatingSiteList),
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Mahnstufe 1',
      children: (
        <MahnstufeTemplate
          templateFormValues={mahnstufe1TemplateValues}
          onTemplateChange={onMahnstufe1TemplateValuesChange}
          renderPreview={({ selectedTextbaustein }) => {
            return (
              <MahnungTemplate
                data={createMahndefinitionPDFTemplateData(
                  mahnstufe1TemplateValues,
                  isVersandEinschreiben1,
                  belegTexte,
                  belegTextePlatzhalter,
                  firmendatenId
                )}
                isInEditMode
                selectedTextbaustein={mapTextbausteinToMahndefinitionSelectableSection(selectedTextbaustein)}
              />
            );
          }}
        />
      ),
    },
    {
      key: '2',
      label: 'Mahnstufe 2',
      children: (
        <MahnstufeTemplate
          templateFormValues={mahnstufe2TemplateValues}
          onTemplateChange={onMahnstufe2TemplateValuesChange}
          renderPreview={({ selectedTextbaustein }) => {
            return (
              <MahnungTemplate
                data={createMahndefinitionPDFTemplateData(
                  mahnstufe2TemplateValues,
                  isVersandEinschreiben2,
                  belegTexte,
                  belegTextePlatzhalter,
                  firmendatenId
                )}
                isInEditMode
                selectedTextbaustein={mapTextbausteinToMahndefinitionSelectableSection(selectedTextbaustein)}
              />
            );
          }}
        />
      ),
    },
    {
      key: '3',
      label: 'Mahnstufe 3',
      children: (
        <MahnstufeTemplate
          templateFormValues={mahnstufe3TemplateValues}
          onTemplateChange={onMahnstufe3TemplateValuesChange}
          renderPreview={({ selectedTextbaustein }) => {
            return (
              <MahnungTemplate
                data={createMahndefinitionPDFTemplateData(
                  mahnstufe3TemplateValues,
                  isVersandEinschreiben3,
                  belegTexte,
                  belegTextePlatzhalter,
                  firmendatenId
                )}
                isInEditMode
                selectedTextbaustein={mapTextbausteinToMahndefinitionSelectableSection(selectedTextbaustein)}
              />
            );
          }}
        />
      ),
    },
    {
      key: '4',
      label: 'Mahnstufe 4',
      children: (
        <MahnstufeTemplate
          templateFormValues={mahnstufe4TemplateValues}
          onTemplateChange={onMahnstufe4TemplateValuesChange}
          renderPreview={({ selectedTextbaustein }) => {
            return (
              <MahnungTemplate
                data={createMahndefinitionPDFTemplateData(
                  mahnstufe4TemplateValues,
                  isVersandEinschreiben4,
                  belegTexte,
                  belegTextePlatzhalter,
                  firmendatenId
                )}
                isInEditMode
                selectedTextbaustein={mapTextbausteinToMahndefinitionSelectableSection(selectedTextbaustein)}
              />
            );
          }}
        />
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default MahndefinitionTemplates;
