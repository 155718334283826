import React from 'react';
import { Route } from 'react-router';
import { isAdminOrHasSystemAdminFm } from '../../security/permissionChecks';
import AuthRoute from '../../security/AuthRoute';
import SysSettingsPage from './SysSettingsPage';
import { URI_CONFIGURATION } from '../../constants/configurationUriPaths';
import sysSettingsAbrechnungsdefinitionCreatePageRoute from './KundenSystem/AbrechnungsdefinitionPage/Create/routes';
import sysSettingsAbrechnungsdefinitionPageRoute from './KundenSystem/AbrechnungsdefinitionPage/Listing';
import sysSettingsAbrechnungsdefinitionVersionCreatePageRoute from './KundenSystem/AbrechnungsdefinitionPage/Version/Create';
import sysSettingsAbrechnungsdefinitionVersionUpdatePageRoute from './KundenSystem/AbrechnungsdefinitionPage/Version/Update';
import sysSettingsAbrechnungsdefinitionVersionViewPageRoute from './KundenSystem/AbrechnungsdefinitionPage/Version/View';
import sysSettingsAufgabenartPageRoute from './KundenSystem/AufgabenartPage';
import sysSettingsAbrechnungskreisPageRoute from './KundenSystem/AbrechnungskreisPage';
import sysSettingsAbrKontenzuordnungPageRoute from './KundenSystem/AbrKontenzuordnungPage';
import sysSettingsAllgEinstellungPageRoute from './KundenSystem/AllgemeineEinstellungPage';
import sysSettingsAufteilungsschluesselPageRoute from './KundenSystem/AufteilungsschluesselPage';
import sysSettingsAuftragsartCreatePageRoute from './KundenSystem/AuftragsartenPage/Create';
import sysSettingsAuftragsartenPageRoute from './KundenSystem/AuftragsartenPage/Listing';
import sysSettingsAuftragsartUpdatePageRoute from './KundenSystem/AuftragsartenPage/Update';
import sysSettingsBudgetingVerknuepfungAbrDefVPosPageRoute from './KundenSystem/BudgetingVerknuepfungAbrDefVPosPage';
import sysSettingsBudgetingVerknuepfungVPosAuftSchluesselPageRoute from './KundenSystem/BudgetingVerknuepfungVPosAuftSchluesselPage';
import sysSettingsEmailDeliveryDefinitionPageRoute from './KundenSystem/EmailDeliveryDefinitionPage';
import sysSettingsKontenplanPageRoute from './KundenSystem/KontenplanPage';
import sysSettingsKontierungstabellePageRoute from './KundenSystem/KontierungstabellePage';
import sysSettingsMahndefinitionCreatePageRoute from './KundenSystem/MahndefinitionPage/Create';
import sysSettingsMahndefinitionPageRoute from './KundenSystem/MahndefinitionPage/Listing';
import sysSettingsMahndefinitionUpdatePageRoute from './KundenSystem/MahndefinitionPage/Update';
import sysSettingsRepFondsPageRoute from './KundenSystem/RepFondsPage';
import sysSettingsTextPageRoute from './KundenSystem/TextPage';
import sysSettingsUstKategoriePageRoute from './KundenSystem/UstKategoriePage';
import sysSettingsUstRegelwerkPageRoute from './KundenSystem/UstRegelwerkPage';
import sysSettingsVorschreibungspositionPageRoute from './KundenSystem/VorschreibungspositionPage';
import sysSettingsVorsteuerkuerzungPageRoute from './KundenSystem/VorsteuerkuerzungPage';
import sysSettingsFirmendatenIndexSeriesDetailsPageRoute from './KundenSystem/FirmendatenIndexSeriesDetailsPage';
import sysSettingsFirmendatenIndexSeriesPageRoute from './KundenSystem/FirmendatenIndexSeriesPage';
import sysSettingsIndexValueCreationCronJobSettingsPageRoute from './KundenSystem/IndexValueCreationCronJobSettingsPage';
import sysSettingsEbicsUserPageRoute from './KundenSystem/EbicsUserPage';
import sysSettingsEmailIntegrationPageRoute from './KundenSystem/EmailIntegrationPage';

const sysSettingsPageRoute = (
  <Route path={URI_CONFIGURATION} element={<AuthRoute hasPermissionFn={isAdminOrHasSystemAdminFm} element={<SysSettingsPage />} />}>
    {sysSettingsAbrechnungsdefinitionPageRoute}
    {sysSettingsAbrechnungsdefinitionCreatePageRoute}
    {sysSettingsAbrechnungsdefinitionVersionCreatePageRoute}
    {sysSettingsAbrechnungsdefinitionVersionUpdatePageRoute}
    {sysSettingsAbrechnungsdefinitionVersionViewPageRoute}
    {sysSettingsAufgabenartPageRoute}
    {sysSettingsAbrechnungskreisPageRoute}
    {sysSettingsAbrKontenzuordnungPageRoute}
    {sysSettingsAllgEinstellungPageRoute}
    {sysSettingsAufteilungsschluesselPageRoute}
    {sysSettingsAuftragsartCreatePageRoute}
    {sysSettingsAuftragsartenPageRoute}
    {sysSettingsAuftragsartUpdatePageRoute}
    {sysSettingsBudgetingVerknuepfungAbrDefVPosPageRoute}
    {sysSettingsBudgetingVerknuepfungVPosAuftSchluesselPageRoute}
    {sysSettingsEmailDeliveryDefinitionPageRoute}
    {sysSettingsKontenplanPageRoute}
    {sysSettingsKontierungstabellePageRoute}
    {sysSettingsMahndefinitionCreatePageRoute}
    {sysSettingsMahndefinitionPageRoute}
    {sysSettingsMahndefinitionUpdatePageRoute}
    {sysSettingsRepFondsPageRoute}
    {sysSettingsTextPageRoute}
    {sysSettingsUstKategoriePageRoute}
    {sysSettingsUstRegelwerkPageRoute}
    {sysSettingsVorschreibungspositionPageRoute}
    {sysSettingsVorsteuerkuerzungPageRoute}
    {sysSettingsFirmendatenIndexSeriesDetailsPageRoute}
    {sysSettingsFirmendatenIndexSeriesPageRoute}
    {sysSettingsIndexValueCreationCronJobSettingsPageRoute}
    {sysSettingsEbicsUserPageRoute}
    {sysSettingsEmailIntegrationPageRoute}
  </Route>
);

export default sysSettingsPageRoute;
