import React, { FC } from 'react';
import { Empty } from 'antd';
import { useParams } from 'react-router-dom';
import { kontierungstabelleTableColumns } from './kontierungstabelleTableColumns';
import ErloesKontoTable from '../Level2/ErloesKontoTable';
import { Kontierungstabelle } from '../../../../types';
import { KontierungstabellePageRouteParams } from '../../../../pages/SysSettingsPage/KundenSystem/KontierungstabellePage/routes';
import NestedTableWithColSelector from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import NestedTableWithColSelectorWrapper from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';

type Props = {
  kontierungstabelleList?: Kontierungstabelle[];
  refetch: () => void;
};

const KontierungstabelleTable: FC<Props> = ({ kontierungstabelleList, refetch }) => {
  const { kontierungstabelleId } = useParams() as KontierungstabellePageRouteParams;
  const getRowKey = (record: Kontierungstabelle) => record.kontierungstabelleId;

  return (
    <NestedTableWithColSelectorWrapper filterIdentifier="sys-settings-kontierungstabelle">
      <NestedTableWithColSelector<Kontierungstabelle>
        locale={{
          emptyText: <Empty description={<span>Keine Kontierungstabellen vorhanden</span>} />,
        }}
        loading={!kontierungstabelleList}
        dataSource={kontierungstabelleList}
        columns={kontierungstabelleTableColumns(refetch)}
        rowKey={getRowKey}
        expandable={{
          rowExpandable: (record) => !!record.errechnetesErloesKontoList.length,
          expandedRowRender: (record, index, indent, expanded) => (
            <ErloesKontoTable visible={expanded} parentRowKeys={[getRowKey(record)]} erloesKontoList={record.errechnetesErloesKontoList} />
          ),
          defaultExpandedRowKeys: kontierungstabelleId ? [kontierungstabelleId] : [],
        }}
        useHighlighting
      />
    </NestedTableWithColSelectorWrapper>
  );
};

export default KontierungstabelleTable;
