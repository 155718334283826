import React from 'react';
import { friendlyFormatIBAN } from 'ibantools';
import { Space, Typography } from 'antd';

import { NestedTableColProps } from '../../../../../../components/Table/NestedTable/NestedTable';
import { BankStatement } from '../../../../../../types';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount } from '../../../../../../components/Number';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import InfoReferencedDataCarrierNameList from './InfoReferencedDataCarrierNameList';
import BankstatementTableActions from './BankstatementTableActions';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';

const bankstatementTableColumns = (dataCarrierId: string, onAction: () => void): NestedTableColProps<BankStatement>[] => [
  {
    title: 'IBAN',
    render: (text, record) => (
      <Space size={4}>
        <InfoReferencedDataCarrierNameList nameList={record.referencedDataCarrierNameList} />
        <DataWithShortenedText maxTextLength={20} text={friendlyFormatIBAN(record.iban) as string} />
      </Space>
    ),
  },
  {
    title: 'Kontoauszugsnummer',
    render: (text, record) => <DataWithShortenedText text={record.statementNumber} maxTextLength={20} />,
  },
  {
    title: 'Kontoauszugsdatum',
    render: (text, record) => <CustomFormattedDate value={record.createdOn} />,
  },
  {
    title: 'Summe Transaktionen',
    render: (text, record) => (record.sumTransactions ? <EuroAmount value={record.sumTransactions} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Kontostand alt',
    render: (text, record) => (record.openingBalance ? <EuroAmount value={record.openingBalance} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Kontostand neu',
    render: (text, record) => (record.closingBalance ? <EuroAmount value={record.closingBalance} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Anzahl Transaktionen',
    render: (text, record) =>
      record.countTransactions ? <Typography.Text>{record.countTransactions}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Anzahl gutgeschriebener Transaktionen',
    render: (text, record) =>
      record.countTransactionsCredited ? (
        <Typography.Text>{record.countTransactionsCredited}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Anzahl belastende Transaktionen',
    render: (text, record) =>
      record.countTransactionsDebited ? (
        <Typography.Text>{record.countTransactionsDebited}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  // TODO: add PDF Kontoauszug - to be implemented in EC-13333
  {
    title: 'Aktion',
    render: (text, record) => <BankstatementTableActions onAction={onAction} dataCarrierId={dataCarrierId} bankstatement={record} />,
  },
];

export default bankstatementTableColumns;
