import { FieldArray, useFormikContext } from 'formik';
import { Button, Card, Col, Row } from 'antd';
import { Input } from 'formik-antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import KontoFormSelect from '../../KontoSelect/KontoFormSelect';
import { KontoKlasse } from '../../../types';
import FormikFieldValidate from '../../../helpers/FormikFieldValidate';
import FormattedDecimalFormInput from '../../../components/Input-antd/FormattedDecimalFormInput';
import { Spacer } from '../../../components/Grid';
import {
  createIncomingInvoiceBuchungPositionFormValues,
  incomingInvoiceBuchungPositionFormFields,
  IncomingInvoiceBuchungPositionFormValues,
  IncomingInvoiceFormValues,
} from './incomingInvoiceFormMapper';
import RechnungsAusstellerBestandseinheitListFormSelect from '../../RechnungsAusstellerBestandseinheitSelect/RechnungsAusstellerBestandseinheitListFormSelect';

type Props = {
  name: string;
  buchungPositionList: IncomingInvoiceBuchungPositionFormValues[];
  shouldRestore: boolean;
};

const PositionListFormFieldArray: FC<Props> = ({ name, buchungPositionList, shouldRestore }) => {
  const { values: formikValues } = useFormikContext<IncomingInvoiceFormValues>();

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {buchungPositionList.map((pos, index) => {
            const buchPosName = `${name}.[${index}]`;
            return (
              <Card
                key={index}
                style={{ marginTop: index !== 0 ? 16 : 0 }}
                size="small"
                title="Position"
                extra={
                  <Button type="link" disabled={shouldRestore} onClick={() => arrayHelpers.remove(index)}>
                    Löschen
                  </Button>
                }
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <FormItemWithoutColon
                      name={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.bestandseinheitId}`}
                      label="Bestandseinheit"
                    >
                      <RechnungsAusstellerBestandseinheitListFormSelect
                        name={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.bestandseinheitId}`}
                        objektId={formikValues.objektId}
                        rechnungsAusstellerId={formikValues.payeeRechtstraegerId}
                        disabled={!(formikValues.objektId && formikValues.payeeRechtstraegerId)}
                      />
                    </FormItemWithoutColon>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <FormItemWithoutColon name={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.kontoId}`} label="Konto">
                      <KontoFormSelect
                        disabled={shouldRestore}
                        name={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.kontoId}`}
                        kontoKlasse={[KontoKlasse.BetrieblicherAufwand, KontoKlasse.HandelswarenEinsatz]}
                        placeholder="Konto auswählen"
                      />
                    </FormItemWithoutColon>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <FormItemWithoutColon
                      name={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.buchungstext}`}
                      id={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.buchungstext}`}
                      label="Buchungstext"
                    >
                      <Input
                        size="middle"
                        disabled={shouldRestore}
                        name={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.buchungstext}`}
                        id={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.buchungstext}`}
                        placeholder="Buchungstext"
                      />
                    </FormItemWithoutColon>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormikFieldValidate valueToWatch={buchungPositionList[index].betrag}>
                      <FormItemWithoutColon
                        name={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.betrag}`}
                        label="Betrag"
                        style={{ margin: 0 }}
                      >
                        <FormattedDecimalFormInput
                          disabled={shouldRestore}
                          size="middle"
                          name={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.betrag}`}
                          style={{ width: '100%' }}
                          min="0"
                        />
                      </FormItemWithoutColon>
                    </FormikFieldValidate>
                  </Col>
                  <Col span={12}>
                    <FormikFieldValidate valueToWatch={buchungPositionList[index].steuersatz}>
                      <FormItemWithoutColon
                        name={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.steuersatz}`}
                        label="Steuersatz"
                        style={{ margin: 0 }}
                      >
                        <FormattedDecimalFormInput
                          disabled={shouldRestore}
                          size="middle"
                          name={`${buchPosName}.${incomingInvoiceBuchungPositionFormFields.steuersatz}`}
                          fractionDigits={0}
                          style={{ width: '100%' }}
                        />
                      </FormItemWithoutColon>
                    </FormikFieldValidate>
                  </Col>
                </Row>
              </Card>
            );
          })}
          <Spacer />
          <Button
            disabled={shouldRestore}
            icon={<PlusOutlined />}
            type="dashed"
            htmlType="button"
            block
            onClick={() => arrayHelpers.push(createIncomingInvoiceBuchungPositionFormValues())}
          >
            Weitere Position hinzufügen
          </Button>
        </>
      )}
    />
  );
};
export default PositionListFormFieldArray;
