import React, { FC } from 'react';
import { DocumentRowProps, findLabelValueByLabelId } from './util';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import { generatePathToRechtstraegerDocuments } from '../../../Rechtstraeger/rechtstraegerHelper';
import { isNotNil } from '../../../../helpers/assertionHelper';
import { EuroAmount } from '../../../../components/Number';
import EigenbelegActions from '../../actions/EigenbelegActions';
import { useEigenbelegQuery } from '../../../../graphql/InvoiceService/EigenbelegQueries.types';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import RowEigenbelegStatus from './RowEigenbelegStatus';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const RowEigenbeleg: FC<DocumentRowProps> = ({ record, rechnungTyp, invoiceRecipientId, invoiceIssuerId, ...restProps }) => {
  const auftragId = findLabelValueByLabelId(record.labelList, 'auftragId');

  const { loading, data: maybeEigenbeleg, refetch } = useEigenbelegQuery({ variables: { auftragId } });

  const eigenbeleg = maybeEigenbeleg?.getEigenbeleg.data;

  const documentInfo: DocumentInfo | undefined = eigenbeleg
    ? {
        column1: <AvatarBasedOnFileInfoTypeWithTooltip fileType={eigenbeleg.type} data-testid={record.fileId} />,
        column2: (
          <ValueTitle
            small
            title="Rechnungsaussteller"
            content={
              <RechtstraegerWithContactsTooltip
                status={eigenbeleg.ausstellerRechtstraeger.status}
                rechtstraegerId={eigenbeleg.ausstellerRechtstraegerId}
                rechtstraegerName={eigenbeleg.ausstellerRechtstraeger.kurzBezeichnung}
                linkTo={generatePathToRechtstraegerDocuments(eigenbeleg.ausstellerRechtstraegerId, rechnungTyp, null, invoiceRecipientId)}
              />
            }
          />
        ),
        column3: (
          <ValueTitle
            small
            title="Rechnungsempfänger"
            content={
              <RechtstraegerWithContactsTooltip
                status={eigenbeleg.empfaengerRechtstraeger.status}
                rechtstraegerId={eigenbeleg.empfaengerRechtstraegerId}
                rechtstraegerName={eigenbeleg.empfaengerRechtstraeger.kurzBezeichnung}
                linkTo={generatePathToRechtstraegerDocuments(eigenbeleg.empfaengerRechtstraegerId, rechnungTyp, invoiceIssuerId, null)}
              />
            }
          />
        ),
        column4: <ValueTitle small title="Rechnungsnummer" content={eigenbeleg.belegnummer} />,
        column5: <ValueTitle small title="Datum" content={<CustomFormattedDate value={eigenbeleg.belegdatum} />} />,
        column6: <ValueTitle small title="brutto" content={isNotNil(eigenbeleg.sumBrutto) ? <EuroAmount value={eigenbeleg.sumBrutto} /> : '-'} />,
        column7: <RowEigenbelegStatus eigenbeleg={eigenbeleg} />,
        column8: <EigenbelegActions record={record} eigenbeleg={eigenbeleg} auftragId={auftragId} onAction={refetch} />,
      }
    : undefined;

  return (
    <DocumentRowContextProvider value={{ isLoading: loading, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

export default RowEigenbeleg;
