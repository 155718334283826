import React from 'react';
import { Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import EntryDetailsTable from './Table/EntryDetailsTable';
import { ContainerToPositionTopElement } from './VorschreibungVerbuchenHelpers';
import { AuftragVerbuchenGenerierlauf, AuftragVerbuchenGenerierlaufEntry, AuftragVerbuchenGenerierlaufOrderBy } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import GenerierlaufEntryStep from '../../../shared/GenerierlaufEntryStep';

const vorschreibungVerbuchenVerarbeitungEntryTableColumns = (
  generierlauf: AuftragVerbuchenGenerierlauf
): TableWithColSelectorColumnProps<AuftragVerbuchenGenerierlaufEntry>[] => [
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: AuftragVerbuchenGenerierlaufOrderBy.Status,
    sorter: true,
    render: (text, record) => (
      <ContainerToPositionTopElement>
        <GenerierlaufEntryStatus generierlaufEntry={record} />
      </ContainerToPositionTopElement>
    ),
  },
  {
    title: 'Schritte',
    render: (text, record) => (
      <ContainerToPositionTopElement>
        <GenerierlaufEntryStep currentStep={record.currentStep} stepCount={record.stepCount} stepList={record.stepList} />
      </ContainerToPositionTopElement>
    ),
  },
  {
    title: 'Fakturierungsperiode',
    defaultSelected: true,
    dataIndex: AuftragVerbuchenGenerierlaufOrderBy.Fakturierungsperiode,
    sorter: true,
    render: (text, record) => (
      <ContainerToPositionTopElement>
        <Typography.Text>
          {record.fakturierungsperiode.monat} / {record.fakturierungsperiode.jahr}
        </Typography.Text>
      </ContainerToPositionTopElement>
    ),
  },
  {
    title: 'Rechnungsaussteller',
    defaultSelected: true,
    dataIndex: AuftragVerbuchenGenerierlaufOrderBy.Rechnungsaussteller,
    sorter: true,
    render: (text, record) => (
      <ContainerToPositionTopElement>
        <Typography.Text>{record.rechnungsaussteller.kurzBezeichnung}</Typography.Text>
      </ContainerToPositionTopElement>
    ),
  },
  {
    title: 'Rechnungsempfänger',
    defaultSelected: true,
    dataIndex: AuftragVerbuchenGenerierlaufOrderBy.Rechnungsempfaenger,
    sorter: true,
    render: (text, record) => (
      <ContainerToPositionTopElement>
        <Typography.Text>{record.rechnungsempfaenger.kurzBezeichnung}</Typography.Text>
      </ContainerToPositionTopElement>
    ),
  },
  {
    title: 'Vorschreibung/Eingangsrechnung',
    defaultSelected: true,
    render: (text, record) => {
      return (
        record.entryDetailsList.length > 0 && (
          <EntryDetailsTable auftragDetailsList={record.entryDetailsList} rechtstreagerId={record.rechnungsaussteller.rechtstraegerId} />
        )
      );
    },
  },
  {
    title: 'Erstellt am',
    render: () => (
      <ContainerToPositionTopElement>
        <FormattedDateTime createdTs={generierlauf.createTs} />
      </ContainerToPositionTopElement>
    ),
  },
  {
    title: 'Ersteller',
    render: () => (
      <ContainerToPositionTopElement>
        <MitarbeiterTooltip mitarbeiterId={generierlauf.createdByMitarbeiterId} userId={generierlauf.createdBy} alignment="left" showsInitials />
      </ContainerToPositionTopElement>
    ),
  },
];

export default vorschreibungVerbuchenVerarbeitungEntryTableColumns;
