import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexValueCreationCronJobSettingsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IndexValueCreationCronJobSettingsQuery = {
  getIndexValueCreationCronJobSettings: {
    data: { indexAnnual: boolean; indexThreshold: boolean };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IndexValueCreationCronJobSettingsChangeHistoryListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IndexValueCreationCronJobSettingsChangeHistoryListQuery = {
  getIndexValueCreationCronJobSettingsChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const IndexValueCreationCronJobSettingsDocument = gql`
  query IndexValueCreationCronJobSettings {
    getIndexValueCreationCronJobSettings {
      data {
        indexAnnual
        indexThreshold
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIndexValueCreationCronJobSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<IndexValueCreationCronJobSettingsQuery, IndexValueCreationCronJobSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IndexValueCreationCronJobSettingsQuery, IndexValueCreationCronJobSettingsQueryVariables>(
    IndexValueCreationCronJobSettingsDocument,
    options
  );
}
export function useIndexValueCreationCronJobSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IndexValueCreationCronJobSettingsQuery, IndexValueCreationCronJobSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IndexValueCreationCronJobSettingsQuery, IndexValueCreationCronJobSettingsQueryVariables>(
    IndexValueCreationCronJobSettingsDocument,
    options
  );
}
export function useIndexValueCreationCronJobSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IndexValueCreationCronJobSettingsQuery, IndexValueCreationCronJobSettingsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IndexValueCreationCronJobSettingsQuery, IndexValueCreationCronJobSettingsQueryVariables>(
    IndexValueCreationCronJobSettingsDocument,
    options
  );
}
export type IndexValueCreationCronJobSettingsQueryHookResult = ReturnType<typeof useIndexValueCreationCronJobSettingsQuery>;
export type IndexValueCreationCronJobSettingsLazyQueryHookResult = ReturnType<typeof useIndexValueCreationCronJobSettingsLazyQuery>;
export type IndexValueCreationCronJobSettingsSuspenseQueryHookResult = ReturnType<typeof useIndexValueCreationCronJobSettingsSuspenseQuery>;
export type IndexValueCreationCronJobSettingsQueryResult = Apollo.QueryResult<
  IndexValueCreationCronJobSettingsQuery,
  IndexValueCreationCronJobSettingsQueryVariables
>;
export const IndexValueCreationCronJobSettingsChangeHistoryListDocument = gql`
  query IndexValueCreationCronJobSettingsChangeHistoryList {
    getIndexValueCreationCronJobSettingsChangeHistoryList {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useIndexValueCreationCronJobSettingsChangeHistoryListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IndexValueCreationCronJobSettingsChangeHistoryListQuery,
    IndexValueCreationCronJobSettingsChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IndexValueCreationCronJobSettingsChangeHistoryListQuery, IndexValueCreationCronJobSettingsChangeHistoryListQueryVariables>(
    IndexValueCreationCronJobSettingsChangeHistoryListDocument,
    options
  );
}
export function useIndexValueCreationCronJobSettingsChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexValueCreationCronJobSettingsChangeHistoryListQuery,
    IndexValueCreationCronJobSettingsChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndexValueCreationCronJobSettingsChangeHistoryListQuery,
    IndexValueCreationCronJobSettingsChangeHistoryListQueryVariables
  >(IndexValueCreationCronJobSettingsChangeHistoryListDocument, options);
}
export function useIndexValueCreationCronJobSettingsChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IndexValueCreationCronJobSettingsChangeHistoryListQuery,
        IndexValueCreationCronJobSettingsChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IndexValueCreationCronJobSettingsChangeHistoryListQuery,
    IndexValueCreationCronJobSettingsChangeHistoryListQueryVariables
  >(IndexValueCreationCronJobSettingsChangeHistoryListDocument, options);
}
export type IndexValueCreationCronJobSettingsChangeHistoryListQueryHookResult = ReturnType<
  typeof useIndexValueCreationCronJobSettingsChangeHistoryListQuery
>;
export type IndexValueCreationCronJobSettingsChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useIndexValueCreationCronJobSettingsChangeHistoryListLazyQuery
>;
export type IndexValueCreationCronJobSettingsChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useIndexValueCreationCronJobSettingsChangeHistoryListSuspenseQuery
>;
export type IndexValueCreationCronJobSettingsChangeHistoryListQueryResult = Apollo.QueryResult<
  IndexValueCreationCronJobSettingsChangeHistoryListQuery,
  IndexValueCreationCronJobSettingsChangeHistoryListQueryVariables
>;
