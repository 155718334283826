import React, { FC } from 'react';
import { Space } from 'antd';
import TopAbrechnungenStatus from './TopAbrechnungenStatus';
import { ObjektAbrechnung } from '../../../../types';

type Props = {
  abrechnung: ObjektAbrechnung;
};
const BkOrWeAbrechnungTopAbrStatus: FC<Props> = ({ abrechnung }) => {
  const topAbrechnungList = abrechnung.topAbrechnungOverview?.topAbrechnungList ?? [];
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {topAbrechnungList.length > 0 && <TopAbrechnungenStatus topAbrechnungList={topAbrechnungList} />}
    </Space>
  );
};

export default BkOrWeAbrechnungTopAbrStatus;
