import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import TicketDetailsPage from './TicketDetailsPage';
import { URI_SUB_TICKET_DETAILS_PAGE, URI_TICKET_DETAILS_PAGE } from '../../features/Ticket/ticketUriPaths';

const ticketDetailsPageRoute = (
  <Route path={`${URI_TICKET_DETAILS_PAGE}/*`} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<TicketDetailsPage />} />} />
);

export const subTicketDetailsPageRoute = (
  <Route
    path={`${URI_SUB_TICKET_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<TicketDetailsPage />} />}
  />
);

export default ticketDetailsPageRoute;
