import {
  kurzBezeichnungFormPartInitialValues,
  mapKurzBezeichnungToFormValues,
} from '../../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartMapper';
import { SPRACHE_DEFAULT } from '../../Sprache/spracheHelper';
import { personFormPartInitialValues } from '../../../../shared/components/Person/personHelper';
import { mapFormValuesToAddress } from '../../../../shared/components/Address/form/addressMapper';
import { NatuerlichePerson, NatuerlichePersonCreateInput, NatuerlichePersonUpdateInput, RechtstraegerType, Steuerpflicht } from '../../../../types';
import { StreetAddressFormValues } from '../../../../shared/components/Address/AddressSharedModel';
import { FormFields } from '../../../../helpers/formikHelper';

export interface NatuerlichePersonFormValues {
  type: RechtstraegerType;
  sprache: string;
  salutation?: string | null;
  firstname: string;
  lastname: string;
  title?: string;
  titleTrailing?: string;
  kurzBezeichnung: string;
  hauptAddress?: StreetAddressFormValues; // address is only available for create nor for update form
  tagList: string[];
  steuerpflicht: Steuerpflicht;
  // helper attributes for toggling and temporary store hauptAddress before adding it to personenGemeinschaft
  tempShowHauptAddressFormPartItem: boolean;
  tempHauptAddress?: StreetAddressFormValues;
}

export const natuerlichePersonFormFields: FormFields<NatuerlichePersonFormValues> = {
  type: 'type',
  sprache: 'sprache',
  salutation: 'salutation',
  firstname: 'firstname',
  lastname: 'lastname',
  title: 'title',
  titleTrailing: 'titleTrailing',
  kurzBezeichnung: 'kurzBezeichnung',
  hauptAddress: 'hauptAddress',
  tagList: 'tagList',
  steuerpflicht: 'steuerpflicht',
  tempShowHauptAddressFormPartItem: 'tempShowHauptAddressFormPartItem',
  tempHauptAddress: 'tempHauptAddress',
};

export const natuerlichePersonFormInitialValues: NatuerlichePersonFormValues = {
  type: RechtstraegerType.NatuerlichePerson,
  sprache: SPRACHE_DEFAULT,
  ...personFormPartInitialValues(),
  ...kurzBezeichnungFormPartInitialValues(),
  tagList: [],
  steuerpflicht: Steuerpflicht.Steuerpflichtig,
  tempShowHauptAddressFormPartItem: false, // close address form part initially for create because it is not mandatory
};

export const mapNatuerlichePersonToFormValues = (natuerlichePerson?: NatuerlichePerson): NatuerlichePersonFormValues => {
  if (!natuerlichePerson) {
    return natuerlichePersonFormInitialValues;
  }
  return {
    type: natuerlichePerson.type.value,
    ...mapKurzBezeichnungToFormValues(natuerlichePerson.kurzBezeichnung),
    firstname: natuerlichePerson.firstname,
    lastname: natuerlichePerson.lastname,
    title: natuerlichePerson.title ?? '',
    titleTrailing: natuerlichePerson.titleTrailing ?? '',
    salutation: natuerlichePerson.salutation?.value,
    sprache: natuerlichePerson.sprache.value,
    tempShowHauptAddressFormPartItem: false,
    steuerpflicht: natuerlichePerson.rechtstraegerSteuerpflicht?.steuerpflicht.value ?? Steuerpflicht.Steuerpflichtig,
    tagList: natuerlichePerson.tagList,
  };
};

export const mapFormValuesToNatuerlichePersonCreate = (values: NatuerlichePersonFormValues): NatuerlichePersonCreateInput => {
  let natuerlichePerson: NatuerlichePersonCreateInput = {
    sprache: values.sprache,
    salutation: values.salutation,
    firstname: values.firstname,
    lastname: values.lastname,
    title: values.title,
    titleTrailing: values.titleTrailing,
    kurzBezeichnung: values.kurzBezeichnung,
    hauptAddress: values.hauptAddress ?? undefined,
    steuerpflicht: { validFrom: '1900-01-01', steuerpflicht: values.steuerpflicht },
    tagList: values.tagList,
  };

  if (values.hauptAddress) {
    const hauptAddress = mapFormValuesToAddress(values.hauptAddress);
    natuerlichePerson = { ...natuerlichePerson, hauptAddress };
  }

  return natuerlichePerson;
};

export const mapFormValuesToNatuerlichePersonUpdate = (values: NatuerlichePersonFormValues): NatuerlichePersonUpdateInput => ({
  sprache: values.sprache,
  salutation: values.salutation,
  firstname: values.firstname,
  lastname: values.lastname,
  title: values.title,
  titleTrailing: values.titleTrailing,
  kurzBezeichnung: values.kurzBezeichnung,
  tagList: values.tagList,
});
