import React, { FC } from 'react';

type Props = {
  zipCode?: string;
  city?: string;
};

const AddressZipCodeCity: FC<Props> = ({ zipCode, city }) => <>{zipCode || city ? `${zipCode} ${city}` : null}</>;

export default AddressZipCodeCity;
