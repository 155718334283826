import React, { FC } from 'react';
import { Checkbox, CheckboxProps } from 'formik-antd';
import { Checkbox as CheckboxAntd, Space } from 'antd';
import { useObjektBankDetailsVerwendungListQuery } from '../gql/ObjektBankDetailsQueries.types';
import { ObjektBankDetailsVerwendung } from '../../../../types';

type Props = {
  verwendungListTypeInUse: ObjektBankDetailsVerwendung[];
  currentVerwendungList?: ObjektBankDetailsVerwendung[];
  weRechnungsAusstellerId?: string;
} & CheckboxProps;

const ObjBankDetailsVerwendungCheckbox: FC<Props> = ({ name, verwendungListTypeInUse, currentVerwendungList, weRechnungsAusstellerId }) => {
  const { data } = useObjektBankDetailsVerwendungListQuery();

  const verwendungList = data?.getObjektBankDetailsVerwendungList.data ?? [];

  const isVerwendungListAlreadyInUse = verwendungListTypeInUse.filter((verwendung) => !currentVerwendungList?.includes(verwendung));

  const filteredVerwendungList = verwendungList.filter((verwendung) =>
    weRechnungsAusstellerId ? !isObjektBankDetailsVerwendungReparaturFonds(verwendung.value) : true
  );

  return (
    <Checkbox.Group name={name}>
      <Space direction="vertical">
        {filteredVerwendungList.map((objBankDetailsVerwendung) => {
          return (
            <CheckboxAntd
              key={objBankDetailsVerwendung.value}
              value={objBankDetailsVerwendung.value}
              disabled={isVerwendungListAlreadyInUse.includes(objBankDetailsVerwendung.value)}
            >
              {objBankDetailsVerwendung.text}
            </CheckboxAntd>
          );
        })}
      </Space>
    </Checkbox.Group>
  );
};

const isObjektBankDetailsVerwendungReparaturFonds = (verwendung: ObjektBankDetailsVerwendung) =>
  verwendung === ObjektBankDetailsVerwendung.ReparaturFonds;

export default ObjBankDetailsVerwendungCheckbox;
