import React, { FC } from 'react';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import SteuerpflichtVersionForm from './SteuerpflichtVersionForm';
import SteuerpflichtTimelineContent from './SteuerpflichtVersionTimelineContent';
import { useRechtstraegerSteuerpflichtListQuery } from '../gql/RechtstraegerSteuerpflichtQueries.types';
import { useDeleteRechtstraegerSteuerpflichtMutation } from '../gql/RechtstraegerSteuerpflichtMutations.types';
import { RechtstraegerSteuerpflicht } from '../../../../types';

type SteuerpflichtVersionTimelineProps = {
  rechtstraegerId: string;
  reloadRechtstraeger: () => void;
};

const SteuerpflichtVersionTimeline: FC<SteuerpflichtVersionTimelineProps> = ({ rechtstraegerId, reloadRechtstraeger }) => {
  const {
    loading,
    data,
    refetch: reloadRechtstraegerSteuerpflichtVersionList,
  } = useRechtstraegerSteuerpflichtListQuery({ variables: { rechtstraegerId, withDetails: true } });
  const steuerpflichtVersionList = data?.getRechtstraegerSteuerpflichtList.data ?? [];

  const onActionSuccess = () => {
    reloadRechtstraeger();
    reloadRechtstraegerSteuerpflichtVersionList();
  };

  const [runDelete] = useDeleteRechtstraegerSteuerpflichtMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Steuerpflicht`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<RechtstraegerSteuerpflicht>
      dataSource={steuerpflichtVersionList}
      versionIdKey="rechtstraegerSteuerpflichtId"
      loading={loading}
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      renderCardContent={(timeblock) => <SteuerpflichtTimelineContent timeblock={timeblock} />}
      isAllowedToDeleteInitialTimeblock
      defaultNewTimeblock={{
        rechtstraegerSteuerpflichtId: steuerpflichtVersionList[0]?.rechtstraegerSteuerpflichtId,
        steuerpflicht: steuerpflichtVersionList[0]?.steuerpflicht,
        kommentar: steuerpflichtVersionList[0]?.kommentar,
        warningList: steuerpflichtVersionList[0]?.warningList,
      }}
      renderForm={(timeblock) => (
        <SteuerpflichtVersionForm timeblock={timeblock} rechtstraegerId={rechtstraegerId} onSubmitSuccess={onActionSuccess} />
      )}
      onDelete={(timeblock) =>
        runDelete({
          variables: {
            rechtstraegerSteuerpflichtId: timeblock.rechtstraegerSteuerpflichtId,
            rechtstraegerId,
          },
        })
      }
    />
  );
};

export default SteuerpflichtVersionTimeline;
