import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import KontierungstabelleForm from './Form/KontierungstabelleForm';
import { useToggle } from '../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';

type KontierungstabelleBtnAndDrawerProps = {
  refetch: () => void;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const KontierungstabelleBtnAndDrawer: FC<KontierungstabelleBtnAndDrawerProps> = ({ refetch, showArchived, toggleShowArchived }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button onClick={toggleShowArchived}>Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}</Button>
        <Button icon={<PlusOutlined />} type="primary" onClick={onCollapse}>
          Kontierungstabelle anlegen
        </Button>
      </ButtonsAligned>
      <Drawer title="Kontierungstabelle anlegen" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <KontierungstabelleForm
          onSuccess={() => {
            onCollapse();
            refetch();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
    </>
  );
};

export default KontierungstabelleBtnAndDrawer;
