import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Button, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import FormButtons from '../../../../../components/Button/FormButtons';
import {
  contactPersonFormFields,
  contactPersonFormInitialValues,
  ContactPersonFormValues,
  mapContactPersonToFormValues,
  mapFormValuesToContactPersonCreate,
  mapFormValuesToContactPersonUpdate,
} from './contactPersonFormMapper';
import PersonFormPart from '../../../../../shared/components/Person/PersonFormPart';
import ContactFormPart from '../../../../../shared/components/Contact/ContactFormPart';
import {
  useCreateFirmaContactPersonMutation,
  useCreateFirmendatenContactPersonMutation,
  useUpdateFirmaContactPersonMutation,
  useUpdateFirmendatenContactPersonMutation,
} from '../../gql/AddressesAndContactsMutations.types';
import { contactPersonValidationSchema } from './contactPersonValidationSchema';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { ContactModel } from '../../../../../shared/components/Contact/contactMapper';
import RechtstraegerSelect from '../../../../../shared/Rechtstraeger/RechtstraegerSelect';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { generatePathToRechtstraegerDetailsPage } from '../../../rechtstraegerHelper';
import { ContactType } from '../../../../../shared/components/Contact/contactHelper';
import { ContactPerson } from '../../../../../types';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  operatingSiteId: string;
  firmaId?: string;
  firmendatenId?: string;
  contactPerson?: ContactPerson;
};

const ContactPersonForm: FC<Props> = ({ onCancel, onSuccess, operatingSiteId, firmaId, firmendatenId, contactPerson }) => {
  const isUpdate = !!contactPerson;
  const entity = 'Ansprechpartner';

  const [runCreateFirmendatenContactPerson, { loading: loadingFirmendatenContactPerson }] = useCreateFirmendatenContactPersonMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreateFirmaContactPerson, { loading: loadingFirmaContactPerson }] = useCreateFirmaContactPersonMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runFirmendatenContactPersonUpdate, { loading: loadingFirmendatenContactPersonUpdate }] = useUpdateFirmendatenContactPersonMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runFirmaContactPersonUpdate, { loading: loadingFirmaContactPersonUpdate }] = useUpdateFirmaContactPersonMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<ContactPersonFormValues>
      initialValues={isUpdate ? mapContactPersonToFormValues(contactPerson) : contactPersonFormInitialValues}
      validationSchema={contactPersonValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (isUpdate) {
          const input = mapFormValuesToContactPersonUpdate(values);
          if (firmaId) {
            runFirmaContactPersonUpdate({
              variables: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                contactPersonId: contactPerson!.contactPersonId,
                firmaId,
                input,
              },
            }).then(() => setSubmitting(false));
          } else if (firmendatenId) {
            runFirmendatenContactPersonUpdate({
              variables: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                contactPersonId: contactPerson!.contactPersonId,
                firmendatenId,
                input,
              },
            }).then(() => setSubmitting(false));
          }
        } else {
          const input = mapFormValuesToContactPersonCreate(values, operatingSiteId);
          if (firmaId) {
            runCreateFirmaContactPerson({
              variables: {
                firmaId,
                input,
              },
            }).then(() => setSubmitting(false));
          } else {
            runCreateFirmendatenContactPerson({
              variables: {
                input,
              },
            }).then(() => setSubmitting(false));
          }
        }
      }}
    >
      {(formikProps) => {
        return (
          <Form layout="vertical">
            <PersonFormPart layout={{ size: 'small' }} />
            <FormItemWithoutColon name={contactPersonFormFields.rechtstraegerId} label="Rechtsträger">
              <Space>
                <RechtstraegerSelect
                  name={contactPersonFormFields.rechtstraegerId}
                  style={{ width: '340px' }}
                  placeholder="Firma/Person auswählen"
                  onNewItemCreated={(rechtstraegerId) => formikProps.setFieldValue(contactPersonFormFields.rechtstraegerId, rechtstraegerId)}
                  onNewItemCreateSelected={() => formikProps.setFieldValue(contactPersonFormFields.rechtstraegerId, '')}
                />
                <Button
                  shape="circle"
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => {
                    formikProps.values.rechtstraegerId && window.open(generatePathToRechtstraegerDetailsPage(formikProps.values.rechtstraegerId));
                  }}
                  size="small"
                  disabled={!formikProps.values.rechtstraegerId}
                />
              </Space>
            </FormItemWithoutColon>
            <Space direction="vertical">
              <Space direction="vertical">
                <Typography.Text>Telefonnummern</Typography.Text>
                <ContactFormPart
                  name={contactPersonFormFields.phoneList}
                  type={ContactType.PHONE}
                  wertList={getKontaktWertList(formikProps.values.phoneList)}
                  onRemove={() => formikProps.setFieldValue(contactPersonFormFields.phoneList, [''])}
                  isValueWertInObject
                  layout={{ size: 'small' }}
                />
              </Space>
              <Space direction="vertical">
                <Typography.Text>E-Mail-Adressen</Typography.Text>
                <ContactFormPart
                  name={contactPersonFormFields.emailList}
                  type={ContactType.EMAIL}
                  wertList={getKontaktWertList(formikProps.values.emailList)}
                  onRemove={() => formikProps.setFieldValue(contactPersonFormFields.emailList, [''])}
                  isValueWertInObject
                  layout={{ size: 'small' }}
                />
              </Space>
            </Space>
            <FormButtons
              updateMode={isUpdate}
              onCancel={onCancel}
              isOkLoading={
                loadingFirmaContactPerson ||
                loadingFirmendatenContactPerson ||
                loadingFirmendatenContactPersonUpdate ||
                loadingFirmaContactPersonUpdate
              }
              isOkDisabled={formikProps.isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const getKontaktWertList = (contactList: ContactModel[]): string[] => {
  const isEmpty = contactList.length === 0;
  return !isEmpty ? contactList.map((contact) => contact.wert) : [''];
};

export default ContactPersonForm;
