import React from 'react';
import { Formik } from 'formik';
import { NotificationListingFiltersFormValues } from './Filters/notificationListingFiltersFormMapper';
import NotificationListingForm from './NotificationListingForm';

const NotificationListing = () => {
  return (
    <Formik<NotificationListingFiltersFormValues> initialValues={{}} onSubmit={() => {}}>
      {(formikProps) => <NotificationListingForm formikProps={formikProps} />}
    </Formik>
  );
};

export default NotificationListing;
