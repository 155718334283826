import React from 'react';
import { Typography } from 'antd';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { Wizard, WizardOrderBy } from '../../../../types';
import { MitarbeiterTooltip } from '../../../../components/Card';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { getWizardDetailsByType } from '../../wizardHelpers';
import GenerierlaufEntryStep from '../../../Verarbeitung/shared/GenerierlaufEntryStep';

const wizardListingTableColumns = (onAction: () => void): TableWithColSelectorColumnProps<Wizard>[] => [
  {
    title: 'Assistent',
    defaultSelected: true,
    dataIndex: WizardOrderBy.Type,
    sorter: true,
    render: (text, record) => {
      const typeDetails = getWizardDetailsByType(record.type);
      return <Typography.Text>{typeDetails?.name ?? '-'}</Typography.Text>;
    },
  },
  {
    title: 'Schritte',
    defaultSelected: true,
    dataIndex: WizardOrderBy.CurrentStep,
    sorter: true,
    render: (text, record) => {
      return <GenerierlaufEntryStep currentStep={record.currentStep} stepCount={record.stepCount} />;
    },
  },

  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: WizardOrderBy.Status,
    sorter: true,
    render: (text, record) => {
      return <Typography.Text>{record.status.text}</Typography.Text>;
    },
  },
  {
    title: 'Erstellt am',
    dataIndex: WizardOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => {
      return <FormattedDateTime createdTs={record.createTs} />;
    },
  },
  {
    title: 'Ersteller',
    dataIndex: WizardOrderBy.CreatedByMitarbeiterId,
    sorter: true,
    render: (text, record) => {
      return <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />;
    },
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => {
      const typeDetails = getWizardDetailsByType(record.type);
      const ListingTableActions = typeDetails?.listingTableActions;
      if (!ListingTableActions) {
        return null;
      }
      return <ListingTableActions wizard={record} onAction={onAction} />;
    },
  },
];

export default wizardListingTableColumns;
