import React, { FC, useEffect } from 'react';
import { Space, Spin, Typography } from 'antd';
import { useSingleInvoiceAbgrenzungsBuchungPreviewMutation } from '../../../gql/SingleInvoiceMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { SingleInvoiceCreateInput } from '../../../../../types';
import BookingPreviewTable from '../shared/SingleInvoice/BookingPreviewTable';

type Props = {
  input: SingleInvoiceCreateInput;
};

const SingleInvoiceAccrualBookingPreviewDetails: FC<Props> = ({ input }) => {
  const [runGetBookingPreview, { loading, data }] = useSingleInvoiceAbgrenzungsBuchungPreviewMutation({
    variables: { input },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  useEffect(() => {
    runGetBookingPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const buchungPreview = data?.singleInvoiceAbgrenzungsBuchungPreview.data;

  return !buchungPreview ? (
    <Spin />
  ) : (
    <Space direction="vertical" size={30} style={{ width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={5}>Buchung mit Aufwand Abgrenzung</Typography.Title>
        <BookingPreviewTable
          key={JSON.stringify(buchungPreview.buchungMitAufwandAbgrenzung)}
          data={buchungPreview.buchungMitAufwandAbgrenzung}
          loading={loading}
        />
      </Space>

      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={5}>Aufwand Buchung der Aufwand Abgrenzung</Typography.Title>
        <BookingPreviewTable
          key={JSON.stringify(buchungPreview.aufwandBuchungDerAufwandAbgrenzung)}
          data={buchungPreview.aufwandBuchungDerAufwandAbgrenzung}
          loading={loading}
        />
      </Space>
    </Space>
  );
};

export default SingleInvoiceAccrualBookingPreviewDetails;
