import { FC } from 'react';
import { useBudgetingAbrDefVPosLinkageChangeHistoryListQuery } from '../../gql/budgetingAbrDefVPosLinkageQueries.types';
import { HistoryType } from '../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../History/HistoryListingTable';

type Props = {
  budgetingAbrDefVPosLinkageId: string;
};

const LinkageChangeHistoryListingTable: FC<Props> = ({ budgetingAbrDefVPosLinkageId }) => {
  const { data, loading } = useBudgetingAbrDefVPosLinkageChangeHistoryListQuery({ variables: { budgetingAbrDefVPosLinkageId } });
  const historyList = data?.getBudgetingAbrDefVPosLinkageChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="budgeting-abf-def-vpos-linkage"
    />
  );
};

export default LinkageChangeHistoryListingTable;
