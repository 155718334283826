import React, { FC, useEffect, useState } from 'react';
import { Empty } from 'antd';
import { useAuth } from '../../../../../shared/Auth/authContext';
import { useCompleteVorschreibungMailDetermineRecipientListStepMutation } from '../../gql/VorschreibungMailDeliveryWizardMutations.types';
import { useQueryWithPolling } from '../../../../../hooks/useQueryWithPolling';
import { useNotificationListQuery } from '../../../../Notification/gql/NotificationQuery.types';
import { NotificationStatus, NotificationType, Notification } from '../../../../../types';
import { findParameterValue, NotificationParameterKeyTypes } from '../../../../Notification/Shared/parameterHelper';
import { useGetNotificationSubscription } from '../../../../Notification/gql/NotificationSubscription.types';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import notificationTableColumns from '../../../../Notification/Listing/Table/notificationTableColumns';
import { DeleteWizard } from '../../../wizardHelpers';
import { generatePathToVerarbeitungVorschreibungMailDeliveryDetermineRecipientListDetailsPage } from '../../../../Verarbeitung/verarbeitungApiPaths';
import StepButtons from '../../../Shared/StepButtons';

type Props = {
  generierlaufId: string;
  wizardId: string;
  onStepComplete: () => void;
  isCompleted?: boolean;
  onCancel: () => void;
  onBack: () => void;
  onDelete: DeleteWizard;
  emptyText?: React.ReactNode | null;
};

const DetermineRecipientListStepNotificationList: FC<Props> = ({
  generierlaufId,
  wizardId,
  onStepComplete,
  isCompleted,
  onCancel,
  onBack,
  onDelete,
  emptyText,
}) => {
  const { user, mitarbeiter } = useAuth();
  const username = user?.username;
  const mitarbeiterId = mitarbeiter?.mitarbeiterId;

  const [inProgress, setInProgress] = useState(true);

  const [runCompleteDetermineRecipientListStep] = useCompleteVorschreibungMailDetermineRecipientListStepMutation({
    variables: {
      vorschreibungMailDeliveryWizardId: wizardId,
    },
  });

  const { data, loading, refetch } = useQueryWithPolling(
    useNotificationListQuery,
    { variables: { type: NotificationType.VorschreibungMailDeliveryDetermineRecipientList } },
    {
      shouldPoll: (notificationData) => notificationData?.getNotificationList.data.contentList.length === 0,
    }
  );

  useEffect(() => {
    setInProgress(loading);
  }, [loading]);

  const notificationList = data?.getNotificationList.data.contentList.filter((notification) => {
    const notificationGenerierlaufId = findParameterValue(notification.notificationInfo.parameterList, NotificationParameterKeyTypes.generierlaufId);
    return notificationGenerierlaufId === generierlaufId;
  });

  useGetNotificationSubscription({
    onData: (options) => {
      const maybeNotification = options.data.data?.getNotification;

      if (maybeNotification) {
        refetch();
      }
    },
    variables: {
      userId: username,
      mitarbeiterId,
    },
  });
  //
  // //@todo changes this later to allow only finished to go to next page
  const hasGenerierlaufListFinished =
    notificationList?.length &&
    !!notificationList?.every((notification) => {
      return (
        notification.progress.processed === notification.progress.total &&
        (notification.status === NotificationStatus.Finished || notification.status === NotificationStatus.Running)
      );
    });

  const onNext = () => {
    if (!isCompleted) {
      setInProgress(true);
      runCompleteDetermineRecipientListStep()
        .then(() => onStepComplete())
        .catch(() => {})
        .finally(() => {
          setInProgress(false);
        });
    } else {
      onStepComplete();
    }
  };

  return (
    <>
      <TableWithColSelector<Notification>
        locale={{
          emptyText: emptyText ?? <Empty description={<span>Keine Notifications vorhanden</span>} />,
        }}
        loading={loading}
        dataSource={notificationList}
        columns={notificationTableColumns({ width: '460px' }, errorLink)}
        rowKey={(record) => record.notificationId}
        size="small"
        filterIdentifier="notification-recipient-determine"
      />

      <StepButtons
        isDropdownHidden={isCompleted}
        isDropdownDisabled={inProgress}
        onNext={onNext}
        onCancel={onCancel}
        isSaveDisabled
        isBackDisabled={inProgress}
        isNextDisabled={!hasGenerierlaufListFinished || inProgress}
        onDelete={() => onDelete(!!isCompleted, setInProgress)}
        onBack={onBack}
      />
    </>
  );
};

const errorLink = (record: Notification) => {
  const generierlaufId = findParameterValue(record.notificationInfo.parameterList, NotificationParameterKeyTypes.generierlaufId);
  return generierlaufId && generatePathToVerarbeitungVorschreibungMailDeliveryDetermineRecipientListDetailsPage(generierlaufId);
};

export default DetermineRecipientListStepNotificationList;
