import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { PaymentGenerierlaufType } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { useFilterGenLaufPaymentBuchungskreisRechtstraegerListQuery } from '../../../gql/GenerierlaufFilter/PaymentGenerierlaufFilterQueries.types';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: PaymentGenerierlaufType;
} & SelectProps;

const GenerierlaufBuchungskreisRechtstraegerFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufPaymentBuchungskreisRechtstraegerListQuery({ variables: { generierlaufId, generierlaufType } });

  const list = data?.getFilterGenLaufPaymentBuchungskreisRechtstraegerList.data ?? [];

  return (
    <Select size="small" loading={loading} id={name} name={name} allowClear placeholder="Firma/Person auswählen" {...restProps}>
      {list.map((rechtstraeger) => (
        <Select.Option key={rechtstraeger.rechtstraegerId} value={rechtstraeger.rechtstraegerId}>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <DataWithShortenedText maxTextLength={30} text={rechtstraeger.kurzBezeichnung!} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufBuchungskreisRechtstraegerFormSelect;
