import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateKsVorschreibungspositionMutationVariables = Types.Exact<{
  input: Types.VorschreibungspositionCreateInput;
  kundenSystemId: Types.Scalars['ID']['input'];
}>;

export type CreateKsVorschreibungspositionMutation = {
  createKSVorschreibungsposition: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      tagList: Array<string>;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      status: { text: string; value: Types.VorschreibungspositionStatus };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFdVorschreibungspositionMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  input: Types.VorschreibungspositionCreateInput;
}>;

export type CreateFdVorschreibungspositionMutation = {
  createFDVorschreibungsposition: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      tagList: Array<string>;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      status: { text: string; value: Types.VorschreibungspositionStatus };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateVorschreibungspositionMutationVariables = Types.Exact<{
  input: Types.VorschreibungspositionUpdateInput;
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type UpdateVorschreibungspositionMutation = {
  updateVorschreibungsposition: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      tagList: Array<string>;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      status: { text: string; value: Types.VorschreibungspositionStatus };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteVorschreibungspositionMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type DeleteVorschreibungspositionMutation = {
  deleteVorschreibungsposition: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type UnarchiveVorschreibungspositionMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveVorschreibungspositionMutation = {
  actionUnarchiveVorschreibungsposition: {
    data: { vorschreibungspositionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveVorschreibungspositionMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type ArchiveVorschreibungspositionMutation = {
  actionArchiveVorschreibungsposition: {
    data: { vorschreibungspositionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateKsVorschreibungspositionDocument = gql`
  mutation CreateKSVorschreibungsposition($input: VorschreibungspositionCreateInput!, $kundenSystemId: ID!) {
    createKSVorschreibungsposition(input: $input, kundenSystemId: $kundenSystemId) {
      data {
        art {
          text
          value
        }
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        status {
          text
          value
        }
        tagList
        umsatzsteuerkennzeichen {
          bezeichnung
          createTs
          createdBy
          createdByMitarbeiterId
          kuerzel
          status {
            text
            value
          }
          umsatzsteuerkennzeichenId
        }
        vorschreibungspositionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateKsVorschreibungspositionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateKsVorschreibungspositionMutation, CreateKsVorschreibungspositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKsVorschreibungspositionMutation, CreateKsVorschreibungspositionMutationVariables>(
    CreateKsVorschreibungspositionDocument,
    options
  );
}
export type CreateKsVorschreibungspositionMutationHookResult = ReturnType<typeof useCreateKsVorschreibungspositionMutation>;
export type CreateKsVorschreibungspositionMutationResult = Apollo.MutationResult<CreateKsVorschreibungspositionMutation>;
export type CreateKsVorschreibungspositionMutationOptions = Apollo.BaseMutationOptions<
  CreateKsVorschreibungspositionMutation,
  CreateKsVorschreibungspositionMutationVariables
>;
export const CreateFdVorschreibungspositionDocument = gql`
  mutation CreateFDVorschreibungsposition($firmendatenId: ID!, $input: VorschreibungspositionCreateInput!) {
    createFDVorschreibungsposition(firmendatenId: $firmendatenId, input: $input) {
      data {
        art {
          text
          value
        }
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        status {
          text
          value
        }
        tagList
        umsatzsteuerkennzeichen {
          bezeichnung
          createTs
          createdBy
          createdByMitarbeiterId
          kuerzel
          status {
            text
            value
          }
          umsatzsteuerkennzeichenId
        }
        vorschreibungspositionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFdVorschreibungspositionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFdVorschreibungspositionMutation, CreateFdVorschreibungspositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFdVorschreibungspositionMutation, CreateFdVorschreibungspositionMutationVariables>(
    CreateFdVorschreibungspositionDocument,
    options
  );
}
export type CreateFdVorschreibungspositionMutationHookResult = ReturnType<typeof useCreateFdVorschreibungspositionMutation>;
export type CreateFdVorschreibungspositionMutationResult = Apollo.MutationResult<CreateFdVorschreibungspositionMutation>;
export type CreateFdVorschreibungspositionMutationOptions = Apollo.BaseMutationOptions<
  CreateFdVorschreibungspositionMutation,
  CreateFdVorschreibungspositionMutationVariables
>;
export const UpdateVorschreibungspositionDocument = gql`
  mutation UpdateVorschreibungsposition($input: VorschreibungspositionUpdateInput!, $vorschreibungspositionId: ID!) {
    updateVorschreibungsposition(input: $input, vorschreibungspositionId: $vorschreibungspositionId) {
      data {
        art {
          text
          value
        }
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        status {
          text
          value
        }
        tagList
        umsatzsteuerkennzeichen {
          bezeichnung
          createTs
          createdBy
          createdByMitarbeiterId
          kuerzel
          status {
            text
            value
          }
          umsatzsteuerkennzeichenId
        }
        vorschreibungspositionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateVorschreibungspositionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVorschreibungspositionMutation, UpdateVorschreibungspositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVorschreibungspositionMutation, UpdateVorschreibungspositionMutationVariables>(
    UpdateVorschreibungspositionDocument,
    options
  );
}
export type UpdateVorschreibungspositionMutationHookResult = ReturnType<typeof useUpdateVorschreibungspositionMutation>;
export type UpdateVorschreibungspositionMutationResult = Apollo.MutationResult<UpdateVorschreibungspositionMutation>;
export type UpdateVorschreibungspositionMutationOptions = Apollo.BaseMutationOptions<
  UpdateVorschreibungspositionMutation,
  UpdateVorschreibungspositionMutationVariables
>;
export const DeleteVorschreibungspositionDocument = gql`
  mutation DeleteVorschreibungsposition($vorschreibungspositionId: ID!) {
    deleteVorschreibungsposition(vorschreibungspositionId: $vorschreibungspositionId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteVorschreibungspositionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVorschreibungspositionMutation, DeleteVorschreibungspositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVorschreibungspositionMutation, DeleteVorschreibungspositionMutationVariables>(
    DeleteVorschreibungspositionDocument,
    options
  );
}
export type DeleteVorschreibungspositionMutationHookResult = ReturnType<typeof useDeleteVorschreibungspositionMutation>;
export type DeleteVorschreibungspositionMutationResult = Apollo.MutationResult<DeleteVorschreibungspositionMutation>;
export type DeleteVorschreibungspositionMutationOptions = Apollo.BaseMutationOptions<
  DeleteVorschreibungspositionMutation,
  DeleteVorschreibungspositionMutationVariables
>;
export const UnarchiveVorschreibungspositionDocument = gql`
  mutation UnarchiveVorschreibungsposition($vorschreibungspositionId: ID!) {
    actionUnarchiveVorschreibungsposition(vorschreibungspositionId: $vorschreibungspositionId) {
      data {
        vorschreibungspositionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveVorschreibungspositionMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveVorschreibungspositionMutation, UnarchiveVorschreibungspositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveVorschreibungspositionMutation, UnarchiveVorschreibungspositionMutationVariables>(
    UnarchiveVorschreibungspositionDocument,
    options
  );
}
export type UnarchiveVorschreibungspositionMutationHookResult = ReturnType<typeof useUnarchiveVorschreibungspositionMutation>;
export type UnarchiveVorschreibungspositionMutationResult = Apollo.MutationResult<UnarchiveVorschreibungspositionMutation>;
export type UnarchiveVorschreibungspositionMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveVorschreibungspositionMutation,
  UnarchiveVorschreibungspositionMutationVariables
>;
export const ArchiveVorschreibungspositionDocument = gql`
  mutation ArchiveVorschreibungsposition($vorschreibungspositionId: ID!) {
    actionArchiveVorschreibungsposition(vorschreibungspositionId: $vorschreibungspositionId) {
      data {
        vorschreibungspositionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveVorschreibungspositionMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveVorschreibungspositionMutation, ArchiveVorschreibungspositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveVorschreibungspositionMutation, ArchiveVorschreibungspositionMutationVariables>(
    ArchiveVorschreibungspositionDocument,
    options
  );
}
export type ArchiveVorschreibungspositionMutationHookResult = ReturnType<typeof useArchiveVorschreibungspositionMutation>;
export type ArchiveVorschreibungspositionMutationResult = Apollo.MutationResult<ArchiveVorschreibungspositionMutation>;
export type ArchiveVorschreibungspositionMutationOptions = Apollo.BaseMutationOptions<
  ArchiveVorschreibungspositionMutation,
  ArchiveVorschreibungspositionMutationVariables
>;
