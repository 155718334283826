import { Box } from 'rebass';
import React from 'react';
import { Button, Tooltip } from 'antd';
import { CompressOutlined, EditOutlined } from '@ant-design/icons';
import { TimelineDeleteButton } from '../TimelineDeleteButton';
import { ITimeblock, IVersionable, VersionTimelineOnDelete } from '../timelineCtx';
import theme from '../../../theme';

type TimelineCardExtraProps<T extends IVersionable<T>> = {
  editable?: boolean;
  timeblock: ITimeblock<T>;
  toggleEdit: (id: string) => void;
  toggleCollapsed: (id: string) => void;
  edit: boolean;
  onDelete?: VersionTimelineOnDelete<T>;
  isAllowedToDeleteInitialTimeblock?: boolean;
};

function TimelineCardExtra<T extends IVersionable<T>>({
  editable,
  timeblock,
  toggleEdit,
  toggleCollapsed,
  edit,
  onDelete,
  isAllowedToDeleteInitialTimeblock,
}: TimelineCardExtraProps<T>) {
  return (
    <Box>
      {editable ? (
        <Tooltip title="Bearbeiten" placement="topRight">
          <Button
            shape="circle"
            type="text"
            icon={<EditOutlined style={{ color: theme.colors.blue }} />}
            onClick={() => {
              toggleEdit(timeblock.uuid);
            }}
            size="small"
            disabled={edit}
          />
        </Tooltip>
      ) : null}
      <TimelineDeleteButton<T>
        timeblock={timeblock}
        isAllowedToDeleteInitialTimeblock={isAllowedToDeleteInitialTimeblock}
        editable={editable}
        onDelete={onDelete}
      />
      <Tooltip title="Details ausblenden" placement="topRight">
        <Button
          disabled={edit}
          shape="circle"
          type="text"
          icon={<CompressOutlined style={{ color: theme.colors.blue }} />}
          size="small"
          onClick={() => toggleCollapsed(timeblock.uuid)}
        />
      </Tooltip>
    </Box>
  );
}

export default TimelineCardExtra;
