import React from 'react';
import { CopyOutlined, EuroCircleOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { generatePathToHeVertragDetailsPage } from '../../features/Vertrag/HeVertrag/heVertragUriPaths';
import ZahlungsregelListing from '../../features/Vertrag/HeVertrag/Zahlungsregel/ZahlungsregelListing';
import ZahlungsverlaufListing from '../../features/Vertrag/HeVertrag/Zahlungsverlauf/ZahlungsverlaufListing';
import { Spacer } from '../../components/Grid';
import DocumentTab from '../../features/Document/DocumentTab';
import { HeVertrag } from '../../types';
import { DOCUMENT_FILTERS_SETTINGS } from '../../features/Document/Listing/Filters/listingFiltersHelper';

const menuListHeVertrag = (objektId: string, heVertragId: string, vertrag: HeVertrag, rechnungsAusstellerId: string): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Auszahlungen',
      icon: <EuroCircleOutlined />,
      uri: `${generatePathToHeVertragDetailsPage(objektId, heVertragId)}/auszahlungen`,
      content: () => (
        <>
          <ZahlungsregelListing objektId={objektId} heVertragId={heVertragId} vertrag={vertrag} rechnungsAusstellerId={rechnungsAusstellerId} />
          <Spacer height={32} />
          <ZahlungsverlaufListing objektId={objektId} heVertragId={heVertragId} vertrag={vertrag} rechnungsAusstellerId={rechnungsAusstellerId} />
        </>
      ),
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: `${generatePathToHeVertragDetailsPage(objektId, heVertragId)}/documents`,
      content: (props) => (
        <DocumentTab
          objektId={objektId}
          heVertragId={heVertragId}
          disabledFilters={DOCUMENT_FILTERS_SETTINGS.heVertrag.disabled}
          defaultFilters={DOCUMENT_FILTERS_SETTINGS.heVertrag.default}
          {...props}
        />
      ),
    },
  ],
});

export default menuListHeVertrag;
