import React from 'react';
import { Route } from 'react-router';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_OBJEKT_BESTANDSEINHEIT_DETAILS_PAGE } from '../../features/Bestandseinheit/bestandseinheitUriPaths';
import BestandseinheitDetailsPage from './BestandseinheitDetailsPage';
import AuthRoute from '../../security/AuthRoute';

export type BestandseinheitDetailsPageRouteParams = { objektId: string; bestandseinheitId: string };

const bestandseinheitDetailsPageRoute = (
  <Route
    path={`${URI_OBJEKT_BESTANDSEINHEIT_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BestandseinheitDetailsPage />} />}
  />
);

export default bestandseinheitDetailsPageRoute;
