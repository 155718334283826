import React, { FC, ReactNode, useState } from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import RechstraegerStreetAddressDeletionHistoryListingTable from './RechstraegerStreetAddressDeletionHistoryListingTable';
import RechstragerPostofficeBoxAddressDeletionHistoryListingTable from './RechstragerPostofficeBoxAddressDeletionHistoryListingTable';
import RechstragerEmailContactDeletionHistoryListingTable from './RechstragerEmailContactDeletionHistoryListingTable';
import RechstragerPhoneContactDeletionHistoryListingTable from './RechstragerPhoneContactDeletionHistoryListingTable';
import FirmaOperatingSiteDeletionHistoryListingTable from './FirmaOperatingSiteDeletionHistoryListingTable';
import FirmaContactPersonDeletionHistoryListingTable from './FirmaContactPersonDeletionHistoryListingTable';
import { RechtstraegerType } from '../../../types';

type Props = {
  rechtstraegerId: string;
  rechtstraegerType?: RechtstraegerType;
};

type DeletionHistory = {
  type: DeletionHistoryType;
  title: string;
  modalContent: ReactNode;
};

type DeletionHistoryBaseType = 'streetAddress' | 'postofficeBoxAddress' | 'emailContact' | 'phoneContact';
type DeletionHistoryFirmaType = 'operatingSite' | 'contactPerson';
type DeletionHistoryType = DeletionHistoryBaseType | DeletionHistoryFirmaType;

const RechtstraegerDeletionHistoryDropdown: FC<Props> = ({ rechtstraegerId, rechtstraegerType }) => {
  const deletionHistoryBaseTypes: Record<DeletionHistoryBaseType, DeletionHistory> = {
    streetAddress: {
      type: 'streetAddress',
      title: 'Strassenadresse',
      modalContent: <RechstraegerStreetAddressDeletionHistoryListingTable rechtstraegerId={rechtstraegerId} />,
    },
    postofficeBoxAddress: {
      type: 'postofficeBoxAddress',
      title: 'Postfachadresse',
      modalContent: <RechstragerPostofficeBoxAddressDeletionHistoryListingTable rechtstraegerId={rechtstraegerId} />,
    },
    emailContact: {
      type: 'emailContact',
      title: 'E-Mail',
      modalContent: <RechstragerEmailContactDeletionHistoryListingTable rechtstraegerId={rechtstraegerId} />,
    },
    phoneContact: {
      type: 'phoneContact',
      title: 'Telefon',
      modalContent: <RechstragerPhoneContactDeletionHistoryListingTable rechtstraegerId={rechtstraegerId} />,
    },
  };

  const deletionHistoryFirmaTypes: Record<DeletionHistoryFirmaType, DeletionHistory> = {
    operatingSite: {
      type: 'operatingSite',
      title: 'Betriebstätte',
      modalContent: <FirmaOperatingSiteDeletionHistoryListingTable firmaId={rechtstraegerId} />,
    },
    contactPerson: {
      type: 'contactPerson',
      title: 'Ansprechpartner',
      modalContent: <FirmaContactPersonDeletionHistoryListingTable firmaId={rechtstraegerId} />,
    },
  };

  const deletionHistoryTypes = {
    ...deletionHistoryBaseTypes,
    ...(rechtstraegerType === RechtstraegerType.Firma ? deletionHistoryFirmaTypes : {}),
  } as const;

  const [historyModal, setHistoryModal] = useState<DeletionHistoryType | undefined>();

  const items: MenuProps['items'] = Object.values(deletionHistoryTypes).map((entry, index) => {
    return {
      key: index,
      label: entry.title,
      onClick: () => {
        setHistoryModal(entry.type);
      },
    };
  });

  let modalTitle = '';
  let modalContent: ReactNode = null;

  if (historyModal) {
    modalTitle = deletionHistoryTypes[historyModal]?.title ?? '';
    modalContent = deletionHistoryTypes[historyModal]?.modalContent ?? null;
  }

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button>
          Löschverlauf anzeigen
          <DownOutlined />
        </Button>
      </Dropdown>
      <HistoryModal historyType={HistoryType.Deletion} entityName={modalTitle} open={!!historyModal} onCancel={() => setHistoryModal(undefined)}>
        {modalContent}
      </HistoryModal>
    </>
  );
};

export default RechtstraegerDeletionHistoryDropdown;
