import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';
import { WizardStatus } from '../../../../types';
import { TWizardQueryParams } from './filtersQueryParams';

export type FiltersFormValues = {
  createTsFromInclusive?: string | null;
  createTsToInclusive?: string | null;
  erstellerMitarbeiterIdList?: string[] | null;
  showCompleted: boolean;
  statusList?: WizardStatus[] | null;
  type?: string | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  createTsFromInclusive: 'createTsFromInclusive',
  createTsToInclusive: 'createTsToInclusive',
  erstellerMitarbeiterIdList: 'erstellerMitarbeiterIdList',
  showCompleted: 'showCompleted',
  statusList: 'statusList',
  type: 'type',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TWizardQueryParams => ({
  createTsFromInclusive: formValues.createTsFromInclusive ? mapFormDateValueToDateString(formValues.createTsFromInclusive) : null,
  createTsToInclusive: formValues.createTsToInclusive ? mapFormDateValueToDateString(formValues.createTsToInclusive) : null,
  erstellerMitarbeiterIdList: formValues.erstellerMitarbeiterIdList,
  showCompleted: formValues.showCompleted ?? false,
  statusList: formValues.statusList,
  type: formValues.type,
});

export const mapQueryParamsToFormValues = (queryParams: TWizardQueryParams): FiltersFormValues => ({
  createTsFromInclusive: queryParams.createTsFromInclusive,
  createTsToInclusive: queryParams.createTsToInclusive,
  erstellerMitarbeiterIdList: queryParams.erstellerMitarbeiterIdList,
  showCompleted: queryParams.showCompleted ?? false,
  statusList: queryParams.statusList,
  type: queryParams.type,
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'createTsToInclusive' | 'showCompleted'>> = {
  [filtersFormFields.createTsFromInclusive]: 'Erstellt am',
  [filtersFormFields.erstellerMitarbeiterIdList]: 'Ersteller',
  [filtersFormFields.statusList]: 'Status',
  [filtersFormFields.type]: 'Assistenttyp',
};
