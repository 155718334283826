import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { useAbrechnungTypeListQuery } from '../gql/AbrechnungQueries.types';

type Props = SelectProps;

const AbrechnungTypeSelect: FC<Props> = ({ name, size, ...restProps }) => {
  const { data } = useAbrechnungTypeListQuery();
  const abrechnungTypeList = data?.getAbrechnungTypeList.data || [];

  return (
    <Select name={name} allowClear showSearch placeholder="Abrechnungstyp auswählen" size={size ?? 'middle'} prefix="Typ" {...restProps}>
      {abrechnungTypeList.map((abrechnungType) => (
        <Select.Option key={abrechnungType.value} value={abrechnungType.value}>
          {abrechnungType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AbrechnungTypeSelect;
