import { useField } from 'formik';
import { vorschreibungsPositionWertFormFields } from './vertragVorschreibungsPositionWertMapper';
import { mapFormDateValueToDateString } from '../../../../../../../helpers/formikHelper';
import { showWarningMessage } from '../../../../../../../components/message';
import { useBeVertragVorschreibungspositionUstKategorieEntryQuery } from '../../gql/BeVertragVorschreibungspositionQueries.types';

const useVVPosUstKategorieEntryWarningAndLoading = (
  objektId: string,
  bestandseinheitId: string,
  vertragId: string,
  vorschreibungspositionId: string
) => {
  const [{ value: validFrom }] = useField<string>(vorschreibungsPositionWertFormFields.validFrom);

  const { loading: loadingVPUstKategorieEntry, data: maybeVPUstKategorieEntry } = useBeVertragVorschreibungspositionUstKategorieEntryQuery({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      vorschreibungspositionId,
      date: mapFormDateValueToDateString(validFrom),
    },
    onError: (error) => showWarningMessage(error, 'Der aktuelle Steuersatz konnte nicht ermittelt werden.'),
  });
  const vpUstKategorieEntry = maybeVPUstKategorieEntry?.getBeVertragVorschreibungspositionUstKategorieEntry.data;
  const vpNoUstKategorieEntryWarning = maybeVPUstKategorieEntry?.getBeVertragVorschreibungspositionUstKategorieEntry.warningList[0];

  return { vpUstKategorieEntry, vpNoUstKategorieEntryWarning, loadingVPUstKategorieEntry };
};

export default useVVPosUstKategorieEntryWarningAndLoading;
