import { Formik } from 'formik';
import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Box } from 'rebass';
import { Divider } from 'antd';
import { getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../../components/Timeline/timelineCtx';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import TimelineFormDatePicker from '../../../../../components/Timeline/Form/TimelineFormDatePicker';
import TimelineFormButtons from '../../../../../components/Timeline/Form/TimelineFormButtons';
import { layoutTimelineForm } from '../../../../../components/Input-antd/formLayoutHelper';
import {
  mapFormValuesToBestandseinheitVersion,
  mapTimeblockToFormValues,
  nutzflaecheVersionFormFields,
  NutzflaecheVersionFormValues,
  nutzflaecheVersionFormValuesDescriptionsForKommentar,
} from './nutzflaecheVersionFormMapper';
import { BENutzflaecheVersionTimelineDataSourceEntry } from '../nutzflaecheVersionTimelineMapper';
import TimelineFormKommentar from '../../../../../components/Timeline/Form/TimelineFormKommentar';
import {
  useCreateBestandseinheitNutzflaecheVersionMutation,
  useUpdateBestandseinheitNutzflaecheVersionMutation,
} from '../gql/NutzflaecheVersionMutations.types';
import { nutzflaecheVersionFormValidationSchema } from './nutzflaecheVersionFormValidationSchema';
import { gqlOperations } from '../../../../../graphql/gqlNamedOperations-generated';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';

type NutzflaecheVersionFormProps = {
  timeblock: ITimeblock<BENutzflaecheVersionTimelineDataSourceEntry>;
  objektId: string;
  bestandseinheitId: string;
  onSubmitSuccess: () => void;
};

const NutzflaecheVersionForm: FC<NutzflaecheVersionFormProps> = ({ timeblock, objektId, bestandseinheitId, onSubmitSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeNutzflaeche'>('BeNutzflaeche');

  const { dataSource, toggleEdit, changeToUpdateMode } = useTimeline<BENutzflaecheVersionTimelineDataSourceEntry>();
  const initialValues = mapTimeblockToFormValues(timeblock);
  const isUpdate = !!timeblock.beNutzflaecheId;

  const entity = 'Nutzfläche';

  const [runCreate, { loading: loadingCreate }] = useCreateBestandseinheitNutzflaecheVersionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      changeToUpdateMode(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    refetchQueries: () => [
      gqlOperations.Query.BestandseinheitAufteilungsschluesselList,
      gqlOperations.Query.BestandseinheitAufteilungsschluesselWertAenderungList,
    ],
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateBestandseinheitNutzflaecheVersionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      toggleEdit(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    refetchQueries: () => [
      gqlOperations.Query.BestandseinheitAufteilungsschluesselList,
      gqlOperations.Query.BestandseinheitAufteilungsschluesselWertAenderungList,
    ],
  });

  return (
    <Formik<NutzflaecheVersionFormValues>
      initialValues={initialValues}
      validationSchema={nutzflaecheVersionFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (isUpdate) {
          runUpdate({
            variables: {
              objektId,
              bestandseinheitId,
              nutzflaecheVersionId: timeblock.beNutzflaecheId,
              nutzflaecheVersion: mapFormValuesToBestandseinheitVersion(values),
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({
            variables: {
              objektId,
              bestandseinheitId,
              nutzflaecheVersion: mapFormValuesToBestandseinheitVersion(values),
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form {...layoutTimelineForm} layout="horizontal" labelAlign="left">
          <Box width={2 / 3}>
            <FormItemWithFieldHelp
              name={nutzflaecheVersionFormFields.validFrom}
              label="Gültig ab"
              fieldHelp={getFieldHelpText('BeNutzflaeche.validFrom')}
            >
              <TimelineFormDatePicker<BENutzflaecheVersionTimelineDataSourceEntry>
                id={nutzflaecheVersionFormFields.validFrom}
                name={nutzflaecheVersionFormFields.validFrom}
                timeblock={timeblock}
              />
            </FormItemWithFieldHelp>

            <FormItemWithFieldHelp
              name={nutzflaecheVersionFormFields.nutzflaeche}
              label="Nutzfläche"
              style={{ margin: 0 }}
              fieldHelp={getFieldHelpText('BeNutzflaeche.nutzflaeche')}
            >
              <FormattedDecimalFormInput
                size="small"
                id={nutzflaecheVersionFormFields.nutzflaeche}
                name={nutzflaecheVersionFormFields.nutzflaeche}
                placeholder="z.B. 123"
                style={{ width: '100%' }}
              />
            </FormItemWithFieldHelp>
          </Box>

          <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />

          <TimelineFormKommentar
            name={nutzflaecheVersionFormFields.kommentar}
            timeblock={timeblock}
            valuesDescriptions={nutzflaecheVersionFormValuesDescriptionsForKommentar}
          />

          <TimelineFormButtons<BENutzflaecheVersionTimelineDataSourceEntry>
            timeblock={timeblock}
            isSubmitting={formikProps.isSubmitting}
            isSameAsPreviousVersion={isSameAsPreviousVersion(
              formikProps.values,
              getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
            )}
            submitBtnLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

const isSameAsPreviousVersion = (
  formValues: NutzflaecheVersionFormValues,
  previousTimeblock?: ITimeblock<BENutzflaecheVersionTimelineDataSourceEntry>
) => formValues.nutzflaeche === previousTimeblock?.nutzflaeche;

export default NutzflaecheVersionForm;
