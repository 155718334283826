import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_ZINSLISTE_DETAILS_PAGE } from '../../features/Zinsliste/zinslisteUriPaths';
import ZinslisteDetailsPage from './ZinslisteDetailsPage';

export type ZinslisteDetailsPageRouteParams = { zinslisteId: string; objektId: string };

const zinslisteDetailsPageRoute = (
  <Route
    path={`${URI_ZINSLISTE_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<ZinslisteDetailsPage />} />}
  />
);

export default zinslisteDetailsPageRoute;
