import { Select, SelectProps } from 'formik-antd';
import React, { FC, useState } from 'react';
import useDebounce from '../../../../hooks/useDebounce';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../../helpers/stringHelper';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';
import { useFilterFibuBuchungsanweisungZuStornierendeBuchungListQuery } from '../../gql/BuchungsanweisungQueries.types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'placeholder' | 'size' | 'showSearch'>;

const FibuBaZuStornierendeBuchungSelect: FC<Props> = ({ onChange, ...restProps }) => {
  const [bezeichnungText, setBezeichnungText] = useState<string>('');
  const debouncedText = useDebounce(bezeichnungText);

  const { loading, data } = useFilterFibuBuchungsanweisungZuStornierendeBuchungListQuery({
    variables: {
      bezeichnung: debouncedText as string,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const buchungList = data?.getFilterFibuBuchungsanweisungZuStornierendeBuchungList.data ?? [];

  return (
    <Select
      {...restProps}
      id={restProps.name}
      loading={loading}
      size="small"
      allowClear
      showSearch
      placeholder="Zu stornierende Buchung auswählen"
      filterOption={selectFilterOption}
      onSelect={(value) => setBezeichnungText(value)}
      onSearch={(value) => {
        bezeichnungText.length === 1 && !value && setBezeichnungText(''); // Checking the length of the text makes the search component editable
        value && setBezeichnungText(value);
      }}
      onClear={() => setBezeichnungText('')}
      searchValue={bezeichnungText}
      notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
    >
      {buchungList?.map((buchung) => (
        <Select.Option key={buchung.buchungId} value={buchung.buchungId}>
          <DataWithShortenedText text={buchung.bezeichnung} maxTextLength={40} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default FibuBaZuStornierendeBuchungSelect;
