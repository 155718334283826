import React from 'react';
import { DatePicker, DatePickerProps } from 'formik-antd';
import { Dayjs, OpUnitType } from 'dayjs';
import { DATE_DISPLAY_FORMAT_DEFAULT, DATE_DISPLAY_MONTH_FORMAT_DEFAULT } from '../../DatePicker/DatePicker_formikAntD';
import { ITimeblock, IVersionable, useTimeline } from '../timelineCtx';
import { dayjsCustom } from '../../../helpers/dayjsCustom';

type TimelineFormDatePickerProps<T extends IVersionable<T>> = {
  timeblock: ITimeblock<T>;
  disabledDates?: (date: any) => boolean;
} & DatePickerProps;

function TimelineFormDatePicker<T extends IVersionable<T>>({ timeblock, disabledDates, ...restProps }: TimelineFormDatePickerProps<T>) {
  const { dataSource, onValidFromChange } = useTimeline();
  const isMonthPicker = restProps.picker === 'month';

  return (
    <DatePicker
      autoFocus
      id={restProps.name}
      style={{ width: '100%' }}
      format={isMonthPicker ? DATE_DISPLAY_MONTH_FORMAT_DEFAULT : DATE_DISPLAY_FORMAT_DEFAULT}
      disabledDate={(date) => isDateDisabled(dataSource, date, isMonthPicker, disabledDates)}
      onChange={(val) => {
        if (val) {
          // TODO EC-7903
          onValidFromChange(timeblock.uuid, val as unknown as Dayjs);
        }
        return val;
      }}
      size="small"
      allowClear={false}
      {...restProps}
    />
  );
}

function isDateDisabled<T extends IVersionable<T>>(
  dataSource: ITimeblock<T>[],
  date: any,
  isMonthPicker: boolean,
  disabledDate?: (date: any) => boolean
) {
  const isDateInUse = (current: Dayjs, unit?: OpUnitType) =>
    dataSource.map((item) => item.validFrom).some((disabledDate) => dayjsCustom(disabledDate).isSame(current, unit));

  const isDayInUse = (current: Dayjs) => isDateInUse(current, 'day');
  const isMonthInUse = (current: Dayjs) => isDateInUse(current, 'month');

  const dayjsDate = date as unknown as Dayjs;
  const customDisabledDate = disabledDate ? disabledDate(date) : false;
  if (isMonthPicker) {
    return isMonthInUse(dayjsDate) || customDisabledDate;
  } else {
    return isDayInUse(dayjsDate) || customDisabledDate;
  }
}

export default TimelineFormDatePicker;
