import { GenerierlaufEntryExitCode, QueryGetReceivedBankTransactionVerbuchenGenerierlaufEntryArgs } from '../../../../../types';
import { TReceivedBankTransactionVerbuchenQueryParams } from './filtersQueryParams';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../../shared/typeHelpers';

export type FiltersFormValues = {
  amountFrom?: number;
  amountTo?: number;
  creditor?: string;
  dataCarrierCamtName?: string;
  debtor?: string;
  exitCodeList?: GenerierlaufEntryExitCode[] | null;
  objektId?: string;
  statementNumber?: string;
  valueDateFrom?: string;
  valueDateTo?: string;
};

export const listingFiltersFormFields: FormFields<FiltersFormValues> = {
  amountFrom: 'amountFrom',
  amountTo: 'amountTo',
  creditor: 'creditor',
  dataCarrierCamtName: 'dataCarrierCamtName',
  debtor: 'debtor',
  exitCodeList: 'exitCodeList',
  objektId: 'objektId',
  statementNumber: 'statementNumber',
  valueDateFrom: 'valueDateFrom',
  valueDateTo: 'valueDateTo',
};

export const mapFormValuesToQueryParams = (values: FiltersFormValues): TReceivedBankTransactionVerbuchenQueryParams => ({
  amountFrom: values.amountFrom,
  amountTo: values.amountTo,
  creditor: values.creditor,
  dataCarrierCamtName: values.dataCarrierCamtName,
  debtor: values.debtor,
  exitCodeList: values.exitCodeList,
  objektId: values.objektId,
  statementNumber: values.statementNumber,
  valueDateFrom: values.valueDateFrom ? mapFormDateValueToDateString(values.valueDateFrom) : undefined,
  valueDateTo: values.valueDateTo ? mapFormDateValueToDateString(values.valueDateTo) : undefined,
});

export const mapQueryParamsToReceivedBankTransactionVerbuchenListQueryVariables = (
  queryParams: TReceivedBankTransactionVerbuchenQueryParams
): Omit<QueryGetReceivedBankTransactionVerbuchenGenerierlaufEntryArgs, 'generierlaufId'> => ({
  ...queryParams,
  statementNumber: queryParams.statementNumber?.toString(),
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'valueDateTo' | 'amountTo'>> = {
  amountFrom: 'Betrag',
  creditor: 'Kreditor',
  dataCarrierCamtName: 'Datenträger',
  debtor: 'Debitor',
  exitCodeList: 'Status',
  objektId: 'Objekt',
  statementNumber: 'Kontoauszugsnummer',
  valueDateFrom: 'Valutadatum',
};
