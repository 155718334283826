import { Space, Typography } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FileProtectOutlined } from '@ant-design/icons';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../Rechtstraeger/rechtstraegerHelper';
import InfoFeldListItem from '../InfoFeldListItem';
import VersicherungListItemActions from './VersicherungListItemActions';
import ListItemContact from '../../../../../shared/InfoFeld/ListItem/ListItemContact';
import { ObjektInfoFeldVersicherung } from '../../../../../../types';
import { StyledSpaceSplitA30 } from '../../../../../../components/Space/styled/Space.style';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  versicherung: ObjektInfoFeldVersicherung;
  objektId: string;
  onSuccess: () => void;
};

const VersicherungListItem: FC<Props> = ({ versicherung, objektId, onSuccess }) => {
  return (
    <InfoFeldListItem
      icon={<FileProtectOutlined />}
      content={{ fileIdList: versicherung.fileIdList, props: { height: 48 } }}
      actions={<VersicherungListItemActions infoFeld={versicherung} objektId={objektId} onSuccess={onSuccess} />}
    >
      <Space direction="vertical" style={{ width: '220px' }}>
        <Typography.Text type="secondary">Versicherung</Typography.Text>
        <Typography.Text>Art {versicherung.versicherungsart}</Typography.Text>
      </Space>
      <StyledSpaceSplitA30>
        {versicherung.firma && (
          <Link to={generatePathToRechtstraegerDetailsPage(versicherung.firma.rechtstraegerId)}>{versicherung.firma.kurzBezeichnung}</Link>
        )}
        {versicherung.contactPerson && (
          <ListItemContact
            contactList={versicherung.contactPerson.contactList}
            firstname={versicherung.contactPerson.firstname}
            lastname={versicherung.contactPerson.lastname}
          />
        )}
        {versicherung.ende && <CustomFormattedDate value={versicherung.ende} />}
      </StyledSpaceSplitA30>
    </InfoFeldListItem>
  );
};

export default VersicherungListItem;
