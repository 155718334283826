import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../styles/pdfStyles';
import { InfoAbrechnungskreis as TInfoAbrechnungskreis } from '../../objektAbrechnung/objektAbrechnung-types';
import InfoAbrKreisVorsaldo from './Information/InfoAbrKreisVorsaldo';
import InfoAbrKreisKontoList from './Information/InfoAbrKreisKontoList';

const InfoAbrechnungskreis: FC<{ abrechKreis: TInfoAbrechnungskreis }> = ({ abrechKreis }) => (
  <View style={[pdfStyles.column, { paddingTop: '5mm' }]}>
    <Text style={[pdfStyles.textNormal, { fontWeight: 'bold' }]}>{abrechKreis.bezeichnung}</Text>
    {/* Vorsaldo */}
    <InfoAbrKreisVorsaldo abrechKreis={abrechKreis} />

    {/* Aufwands-Konten-Auflistung */}
    <InfoAbrKreisKontoList abrechKreis={abrechKreis} />
  </View>
);

export default InfoAbrechnungskreis;
