import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { VBillingAddress } from '../../vorschreibung-types';

const VTBillingAddressBlock: FC<{ billingAddress: VBillingAddress }> = ({ billingAddress }) => (
  <View style={pdfStyles.column}>
    {billingAddress.line1 ? <Text style={pdfStyles.lineBlock}>{billingAddress.line1}</Text> : null}
    {billingAddress.line2 ? <Text style={pdfStyles.lineBlock}>{billingAddress.line2}</Text> : null}
    {billingAddress.line3 ? <Text style={pdfStyles.lineBlock}>{billingAddress.line3}</Text> : null}
    {billingAddress.line4 ? <Text style={pdfStyles.lineBlock}>{billingAddress.line4}</Text> : null}
    {billingAddress.line5 ? <Text style={pdfStyles.lineBlock}>{billingAddress.line5}</Text> : null}
    {billingAddress.line6 ? <Text style={pdfStyles.lineBlock}>{billingAddress.line6}</Text> : null}
    {billingAddress.line7 ? <Text style={pdfStyles.lineBlock}>{billingAddress.line7}</Text> : null}
  </View>
);

export default VTBillingAddressBlock;
