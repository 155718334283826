import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { BuchungszeilenAnzeigenTuple } from '../../shared/AbrKreisKontenzuordnungHelpers';

type Props = SelectProps;

const BuchungszeilenAnzeigenSelect: FC<Props> = ({ ...restProps }) => {
  const data = [
    { value: BuchungszeilenAnzeigenTuple.ANZEIGEN, text: 'Anzeigen' },
    { value: BuchungszeilenAnzeigenTuple.AUSBLENDEN, text: 'Ausblenden' },
  ];

  return (
    <Select id={restProps.name} allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {data.map((bz) => (
        <Select.Option key={bz.text} value={bz.text}>
          {bz.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BuchungszeilenAnzeigenSelect;
