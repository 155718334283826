import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { VVorschreibungspositionListHeader } from '../../../vorschreibung-types';
import { beColumnWidthsAndAlignment, vPosColumnWidthsAndAlignment } from '../objekt-block-tables-styles';

const VTBestandseinheitListColumnTitles: FC<{ titles: VVorschreibungspositionListHeader }> = ({ titles }) => (
  <View
    style={[
      pdfStyles.row,
      {
        paddingTop: '3mm',
        fontWeight: 'bold',
      },
    ]}
  >
    <View style={[{ width: beColumnWidthsAndAlignment.bestandseinheit.width }]} />
    <View style={[pdfStyles.row, { width: beColumnWidthsAndAlignment.vPosList.width }]}>
      <Text
        style={[
          pdfStyles.textBulletin,
          {
            width: vPosColumnWidthsAndAlignment.vorschreibungsposition.width,
            textAlign: vPosColumnWidthsAndAlignment.vorschreibungsposition.textAlign,
          },
        ]}
      >
        {titles.vorschreibungspositionText}
      </Text>
      <Text
        style={[
          pdfStyles.textBulletin,
          {
            width: vPosColumnWidthsAndAlignment.netto.width,
            textAlign: vPosColumnWidthsAndAlignment.netto.textAlign,
          },
        ]}
      >
        {titles.nettoText}
      </Text>
      <Text
        style={[
          pdfStyles.textBulletin,
          {
            width: vPosColumnWidthsAndAlignment.ustSatz.width,
            textAlign: vPosColumnWidthsAndAlignment.ustSatz.textAlign,
          },
        ]}
      >
        {titles.ustSatzText}
      </Text>
      <Text
        style={[
          pdfStyles.textBulletin,
          {
            width: vPosColumnWidthsAndAlignment.ustBetrag.width,
            textAlign: vPosColumnWidthsAndAlignment.ustBetrag.textAlign,
          },
        ]}
      >
        {titles.ustBetragText}
      </Text>
      <Text
        style={[
          pdfStyles.textBulletin,
          {
            width: vPosColumnWidthsAndAlignment.brutto.width,
            textAlign: vPosColumnWidthsAndAlignment.brutto.textAlign,
          },
        ]}
      >
        {titles.bruttoText}
      </Text>
    </View>
  </View>
);

export default VTBestandseinheitListColumnTitles;
