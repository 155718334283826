import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFileForMitarbeiterMutationVariables = Types.Exact<{
  mitarbeiterId: Types.Scalars['ID']['input'];
  fileId: Types.Scalars['ID']['input'];
}>;

export type DeleteFileForMitarbeiterMutation = {
  deleteFileForMitarbeiter: {
    data: { createTs: string; createdBy: string; createdByMitarbeiterId?: string | null };
    warningList: Array<{ message: string; type: string }>;
  };
};

export const DeleteFileForMitarbeiterDocument = gql`
  mutation DeleteFileForMitarbeiter($mitarbeiterId: ID!, $fileId: ID!) {
    deleteFileForMitarbeiter(mitarbeiterId: $mitarbeiterId, fileId: $fileId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteFileForMitarbeiterMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFileForMitarbeiterMutation, DeleteFileForMitarbeiterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFileForMitarbeiterMutation, DeleteFileForMitarbeiterMutationVariables>(DeleteFileForMitarbeiterDocument, options);
}
export type DeleteFileForMitarbeiterMutationHookResult = ReturnType<typeof useDeleteFileForMitarbeiterMutation>;
export type DeleteFileForMitarbeiterMutationResult = Apollo.MutationResult<DeleteFileForMitarbeiterMutation>;
export type DeleteFileForMitarbeiterMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileForMitarbeiterMutation,
  DeleteFileForMitarbeiterMutationVariables
>;
