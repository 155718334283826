import React, { FC } from 'react';
import NatuerlichePersonCard from '../../../features/Rechtstraeger/NatuerlichePerson/Card/NatuerlichePersonCard';
import menuListNatuerlichePerson from './menuListNatuerlichePerson';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useNatuerlichePersonQuery } from '../../../features/Rechtstraeger/NatuerlichePerson/gql/NatuerlichePersonQueries.types';
import { useRechtsTraegerRolleListQuery } from '../../../features/Rechtstraeger/Rolle/gql/RolleQueries.types';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { NatuerlichePerson, RechtstraegerType } from '../../../types';

type Props = {
  rechtstraegerId: string;
  rechtstraegerType: RechtstraegerType;
};

const NatuerlichePersonDetailPage: FC<Props> = ({ rechtstraegerId, rechtstraegerType }) => {
  const {
    activeForFirmendaten: { firmendatenId, kundenSystemId },
  } = useFDAuthorized();

  const { data: natuerlichePersonData, refetch } = useNatuerlichePersonQuery({ variables: { rechtstraegerId, withDetails: true } });
  const natuerlichePerson = natuerlichePersonData?.getNatuerlichePerson.data;

  const { data: rolleListData } = useRechtsTraegerRolleListQuery({ variables: { rechtstraegerId } });
  const rolleList = rolleListData?.getRechtstraegerRolleList.data;
  const loadingNP = !natuerlichePerson;

  return (
    <DetailsPageTemplate<NatuerlichePerson>
      data={natuerlichePerson}
      pageTitle={(natuerlichePerson) => natuerlichePerson.kurzBezeichnung}
      card={(natuerlichePerson) => (
        <NatuerlichePersonCard
          refetch={refetch}
          isLoading={loadingNP}
          firmendatenId={firmendatenId}
          rechtstraegerId={rechtstraegerId}
          natuerlichePerson={natuerlichePerson}
          kundenSystemId={kundenSystemId}
        />
      )}
      sidebarMenuList={(natuerlichePerson) => menuListNatuerlichePerson(rechtstraegerId, natuerlichePerson, refetch, rechtstraegerType)}
      contentContextList={() => rolleList}
    />
  );
};

export default NatuerlichePersonDetailPage;
