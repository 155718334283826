import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { useToggle } from '../../hooks/useToggle';
import { useAbrechnungsdefinitionListQuery } from './gql/AbrDefQueries.types';
import { URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION } from './abrDefUriPaths';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import AbrDefTable from './Table/AbrDefTable';

const AbrechnungsdefinitionListing = () => {
  const navigate = useNavigate();
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const [showArchived, toggleShowArchived] = useToggle();

  const { data, refetch } = useAbrechnungsdefinitionListQuery({
    variables: { kundenSystemId, withDetails: true, includeArchiviert: showArchived },
  });
  const abrechnungsdefinitionList = data?.getAbrechnungsdefinitionListByKundenSystemId.data?.contentList;
  const loading = !abrechnungsdefinitionList;

  const abrechnungsdefinitionButtons = (
    <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
      <Button onClick={toggleShowArchived}>Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}</Button>
      <Button icon={<PlusOutlined />} type="primary" onClick={() => navigate(URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION.createPage)}>
        Abrechnungsdefinition anlegen
      </Button>
    </ButtonsAligned>
  );

  return (
    <>
      {abrechnungsdefinitionButtons}
      <AbrDefTable abrechnungsdefinitionList={abrechnungsdefinitionList} loading={loading} refetch={refetch} />
    </>
  );
};

export default AbrechnungsdefinitionListing;
