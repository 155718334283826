import React from 'react';
import { Typography } from 'antd';
import { EuroAmount } from '../../../components/Number';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { SaldenlisteYearSummary, SaldoForMonat } from '../../../types';

const BEZEICHNUNG_EB_COLUMNS_LENGTH = 2;

const saldenlisteSummaryTableColumns = (saldoForMonatList: SaldoForMonat[]): TableWithColSelectorColumnProps<SaldenlisteYearSummary>[] => {
  const columnsWidth = 100 / (saldoForMonatList.length + BEZEICHNUNG_EB_COLUMNS_LENGTH);
  return [
    {
      title: 'Bezeichnung',
      defaultSelected: true,
      width: `${columnsWidth}%`,
      render: (text, record) => <Typography.Text>{buildBezeichnung(record.saldoForMonatList)}</Typography.Text>,
    },
    {
      title: 'EB',
      defaultSelected: true,
      width: `${columnsWidth}%`,
      align: 'right',
      render: (text, record) => <EuroAmount value={record.saldoEB} />,
    },
    ...buildMonthColumns(saldoForMonatList, columnsWidth),
  ];
};

const buildBezeichnung = (saldoForMonatList: SaldoForMonat[]) => {
  return `Saldo Gesamt ${formatMonth(saldoForMonatList[0].monat, 'MM')}-${formatMonth(saldoForMonatList[saldoForMonatList.length - 1].monat, 'MM')}`;
};

const buildMonthColumns = (saldoForMonatList: SaldoForMonat[], columnsWidth: number): TableWithColSelectorColumnProps<SaldenlisteYearSummary>[] => {
  return saldoForMonatList.map((saldoForMonat, index) => ({
    title: formatMonth(saldoForMonat.monat, 'MMMM'),
    defaultSelected: true,
    width: `${columnsWidth}%`,
    align: 'right',
    render: (text, record) => <EuroAmount value={record.saldoForMonatList[index].saldo} />,
  }));
};

const formatMonth = (month: string, format: string) => dayjsCustom(month).format(format);

export default saldenlisteSummaryTableColumns;
