import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { kundenSystemBaseValidationSchema } from './kundenSystemFieldsValidationSchema';
import KundenSystemBaseFormPart from '../KundenSystemBaseFormPart';
import { KundenSystemUpdateFormValues, mapFormValuesToKundenSystem, mapKundenSystemToFormValues } from './kundenSystemMapper';
import FormButtons from '../../../../components/Button/FormButtons';
import { useUpdateKundenSystemMutation } from '../gql/KundenSystemMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { showSuccessMsgCreate } from '../../../../components/message';
import { KundenSystem } from '../../../../types';

type KundenSystemUpdateFormProps = {
  kundenSystem: KundenSystem;
  onSuccess: () => void;
  onCancel: () => void;
};

const KundenSystemUpdateForm: FC<KundenSystemUpdateFormProps> = ({ kundenSystem, onSuccess, onCancel }) => {
  const [updateKundenSystem, { loading: loadingUpdateKundenSystem }] = useUpdateKundenSystemMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Kundensystem erfolgreich aktualisiert.`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<KundenSystemUpdateFormValues>
      initialValues={mapKundenSystemToFormValues(kundenSystem)}
      validationSchema={kundenSystemBaseValidationSchema}
      onSubmit={(values, formikHelpers) => {
        const kundenSystemRequest = mapFormValuesToKundenSystem(values);
        updateKundenSystem({
          variables: {
            kundenSystemId: kundenSystem.kundenSystemId,
            request: kundenSystemRequest,
          },
        }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form>
          <KundenSystemBaseFormPart />
          <FormButtons onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} updateMode isOkLoading={loadingUpdateKundenSystem} />
        </Form>
      )}
    </Formik>
  );
};

export default KundenSystemUpdateForm;
