import { Select, SelectProps } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { useFilterAuftragAusstellerIbanListQuery } from '../../gql/AuftragQueries.types';

type Props = {
  name: string;
} & SelectProps;

const AusstellerIbanListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { loading, data } = useFilterAuftragAusstellerIbanListQuery();
  const list = data?.getFilterAuftragAusstellerIbanList.data ?? [];

  return (
    <Select
      size="middle"
      placeholder=""
      style={{ minWidth: '360px' }}
      {...restProps}
      name={name}
      id={name}
      loading={loading}
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((iban) => (
        <Select.Option key={iban} value={iban}>
          <DataWithShortenedText maxTextLength={40} text={`${friendlyFormatIBAN(iban)}`} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default AusstellerIbanListFormSelect;
