import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';
import { FormFields } from '../../../../../../helpers/formikHelper';

export interface PersoneninfosCreateFormValues extends InfoFeldFormValues {
  merkmalList: string[];
  geburtsort?: string;
  staatsbuergerschaft?: string;
  kundengruppeList?: string[];
  identitaetsnachweis?: string;
}

export const personeninfosCreateFormFields: FormFields<PersoneninfosCreateFormValues> = {
  merkmalList: 'merkmalList',
  geburtsort: 'geburtsort',
  staatsbuergerschaft: 'staatsbuergerschaft',
  kundengruppeList: 'kundengruppeList',
  identitaetsnachweis: 'identitaetsnachweis',
  documentList: 'documentList',
};

export const personeninfosCreateFormInitialValues: PersoneninfosCreateFormValues = {
  documentList: [],
  merkmalList: [],
};
