import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import DataWithShortenedText from '../../../../../../../components/Helpers/DataWithShortenedText';
import { FibuBuchungszeile } from '../../../../../../../types';
import { getUniqueList } from '../../../../../../shared/getUniqueList';
import { selectFilterOption } from '../../../../../../../helpers/selectHelper';

type Props = {
  name: string;
  buchungszeileList: FibuBuchungszeile[];
} & SelectProps;

type BzVertragProps = {
  vertragId: string;
  bezeichnung: string;
};

const BzVertragSelect: FC<Props> = ({ name, buchungszeileList, ...restProps }) => {
  const uniqueList: BzVertragProps[] = getUniqueList(
    buchungszeileList
      .filter((buchungszeile) => buchungszeile.vertragId)
      .map((bz) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let bezeichnung = bz.vertragKurzbezeichnung!;

        if (bz.objekt?.kurzBezeichnung) {
          bezeichnung += ` - ${bz.objekt.kurzBezeichnung}`;
        }

        if (bz.bestandseinheit?.bezeichnung) {
          bezeichnung += ` - ${bz.bestandseinheit.bezeichnung}`;
        }

        return {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          vertragId: bz.vertragId!,
          bezeichnung,
        };
      }),
    (a, b) => a.vertragId === b.vertragId
  );

  return (
    <Select name={name} size="small" allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {uniqueList.map((vertrag, index) => (
        <Select.Option key={index} value={vertrag.vertragId} title={vertrag.bezeichnung}>
          <DataWithShortenedText text={vertrag.bezeichnung} maxTextLength={25}>
            {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
          </DataWithShortenedText>
        </Select.Option>
      ))}
    </Select>
  );
};

export default BzVertragSelect;
