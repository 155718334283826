import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { SubAdministrationAbrechnungsdefinitionVersionFieldsFragmentDoc } from './SubAdministrationAbrDefVersionFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubAdministrationAbrechnungsdefinitionVersionListQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type SubAdministrationAbrechnungsdefinitionVersionListQuery = {
  getSubAdministrationAbrechnungsdefinitionVersionList: {
    data: Array<{
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      textBausteineBefuellt: boolean;
      historicizedList?: Array<{
        abrechnungsdefinitionVersionId: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        textBausteineBefuellt: boolean;
        warningList: Array<{ message: string; type: string }>;
      }> | null;
      warningList: Array<{ message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type SubAdministrationAbrechnungsdefinitionVersionQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
  searchInHistory?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type SubAdministrationAbrechnungsdefinitionVersionQuery = {
  getSubAdministrationAbrechnungsdefinitionVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          kontierungstabelle?: {
            basiskonto: number;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kontierungstabelleId: string;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
            subAdministrationExpenseAccount?: {
              bezeichnung: string;
              firmendatenId?: string | null;
              kontoId: string;
              kontoKlasse: string;
              nummer: string;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
          umsatzsteuerkennzeichen?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      outputOptions: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        displayKontonummer?: boolean | null;
        rueckstaendeAnzeigen: boolean;
        rueckstaendeInAbrechnungsergebnis: boolean;
        updatedByMitarbeiterId?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      subAdministrationAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const SubAdministrationAbrechnungsdefinitionVersionListDocument = gql`
  query SubAdministrationAbrechnungsdefinitionVersionList($abrechnungsdefinitionId: ID!, $withDetails: Boolean) {
    getSubAdministrationAbrechnungsdefinitionVersionList(abrechnungsdefinitionId: $abrechnungsdefinitionId, withDetails: $withDetails) {
      data {
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        firmendatenId
        validFrom
        historicizedList {
          abrechnungsdefinitionVersionId
          createTs
          createdBy
          createdByMitarbeiterId
          current
          deletable
          firmendatenId
          validFrom
          kommentar
          lastUpdateTs
          textBausteineBefuellt
          warningList {
            message
            type
          }
        }
        kommentar
        lastUpdateTs
        textBausteineBefuellt
        warningList {
          message
          type
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useSubAdministrationAbrechnungsdefinitionVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubAdministrationAbrechnungsdefinitionVersionListQuery,
    SubAdministrationAbrechnungsdefinitionVersionListQueryVariables
  > &
    ({ variables: SubAdministrationAbrechnungsdefinitionVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubAdministrationAbrechnungsdefinitionVersionListQuery, SubAdministrationAbrechnungsdefinitionVersionListQueryVariables>(
    SubAdministrationAbrechnungsdefinitionVersionListDocument,
    options
  );
}
export function useSubAdministrationAbrechnungsdefinitionVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubAdministrationAbrechnungsdefinitionVersionListQuery,
    SubAdministrationAbrechnungsdefinitionVersionListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubAdministrationAbrechnungsdefinitionVersionListQuery, SubAdministrationAbrechnungsdefinitionVersionListQueryVariables>(
    SubAdministrationAbrechnungsdefinitionVersionListDocument,
    options
  );
}
export function useSubAdministrationAbrechnungsdefinitionVersionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SubAdministrationAbrechnungsdefinitionVersionListQuery,
        SubAdministrationAbrechnungsdefinitionVersionListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SubAdministrationAbrechnungsdefinitionVersionListQuery,
    SubAdministrationAbrechnungsdefinitionVersionListQueryVariables
  >(SubAdministrationAbrechnungsdefinitionVersionListDocument, options);
}
export type SubAdministrationAbrechnungsdefinitionVersionListQueryHookResult = ReturnType<
  typeof useSubAdministrationAbrechnungsdefinitionVersionListQuery
>;
export type SubAdministrationAbrechnungsdefinitionVersionListLazyQueryHookResult = ReturnType<
  typeof useSubAdministrationAbrechnungsdefinitionVersionListLazyQuery
>;
export type SubAdministrationAbrechnungsdefinitionVersionListSuspenseQueryHookResult = ReturnType<
  typeof useSubAdministrationAbrechnungsdefinitionVersionListSuspenseQuery
>;
export type SubAdministrationAbrechnungsdefinitionVersionListQueryResult = Apollo.QueryResult<
  SubAdministrationAbrechnungsdefinitionVersionListQuery,
  SubAdministrationAbrechnungsdefinitionVersionListQueryVariables
>;
export const SubAdministrationAbrechnungsdefinitionVersionDocument = gql`
  query SubAdministrationAbrechnungsdefinitionVersion(
    $abrechnungsdefinitionId: ID!
    $abrechnungsdefinitionVersionId: ID!
    $searchInHistory: Boolean
  ) {
    getSubAdministrationAbrechnungsdefinitionVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
      searchInHistory: $searchInHistory
    ) {
      data {
        ...SubAdministrationAbrechnungsdefinitionVersionFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${SubAdministrationAbrechnungsdefinitionVersionFieldsFragmentDoc}
`;
export function useSubAdministrationAbrechnungsdefinitionVersionQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubAdministrationAbrechnungsdefinitionVersionQuery,
    SubAdministrationAbrechnungsdefinitionVersionQueryVariables
  > &
    ({ variables: SubAdministrationAbrechnungsdefinitionVersionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubAdministrationAbrechnungsdefinitionVersionQuery, SubAdministrationAbrechnungsdefinitionVersionQueryVariables>(
    SubAdministrationAbrechnungsdefinitionVersionDocument,
    options
  );
}
export function useSubAdministrationAbrechnungsdefinitionVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubAdministrationAbrechnungsdefinitionVersionQuery,
    SubAdministrationAbrechnungsdefinitionVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubAdministrationAbrechnungsdefinitionVersionQuery, SubAdministrationAbrechnungsdefinitionVersionQueryVariables>(
    SubAdministrationAbrechnungsdefinitionVersionDocument,
    options
  );
}
export function useSubAdministrationAbrechnungsdefinitionVersionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SubAdministrationAbrechnungsdefinitionVersionQuery, SubAdministrationAbrechnungsdefinitionVersionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubAdministrationAbrechnungsdefinitionVersionQuery, SubAdministrationAbrechnungsdefinitionVersionQueryVariables>(
    SubAdministrationAbrechnungsdefinitionVersionDocument,
    options
  );
}
export type SubAdministrationAbrechnungsdefinitionVersionQueryHookResult = ReturnType<typeof useSubAdministrationAbrechnungsdefinitionVersionQuery>;
export type SubAdministrationAbrechnungsdefinitionVersionLazyQueryHookResult = ReturnType<
  typeof useSubAdministrationAbrechnungsdefinitionVersionLazyQuery
>;
export type SubAdministrationAbrechnungsdefinitionVersionSuspenseQueryHookResult = ReturnType<
  typeof useSubAdministrationAbrechnungsdefinitionVersionSuspenseQuery
>;
export type SubAdministrationAbrechnungsdefinitionVersionQueryResult = Apollo.QueryResult<
  SubAdministrationAbrechnungsdefinitionVersionQuery,
  SubAdministrationAbrechnungsdefinitionVersionQueryVariables
>;
