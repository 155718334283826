import { ZahlungsrueckstaendeColumnsStyle } from './zr-table-styles-types';

export const columnWidthsAndAlignment: ZahlungsrueckstaendeColumnsStyle = {
  bezeichnung: { width: '24%', textAlign: 'left' },
  topNummer: { width: '12%', textAlign: 'left' },
  kontoNummer: { width: '15%', textAlign: 'left' },
  letzteBuchungDate: { width: '20%', textAlign: 'right' },
  dueDate: { width: '13%', textAlign: 'right' },
  saldo: { width: '16%', textAlign: 'right' },
};
