import React, { FC } from 'react';
import { NutzungsArt, BeNutzungsArt } from '../../../../types';
import NutzungsartVersionCardContent from './NutzungsartVersionCardContent';
import { useBeNutzungsartVersionListQuery } from './gql/NutzungsartVersionQueries.types';
import { StyledSkeleton } from '../../../../components/Loading';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import { useDeleteBeNutzungsArtMutation } from './gql/NutzungsartVersionMutations.types';
import NutzungsartVersionForm from './Form/NutzungsartVersionForm';
import { gqlOperations } from '../../../../graphql/gqlNamedOperations-generated';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  reloadBestandseinheit: () => void;
};

const NutzungsartVersionTimeline: FC<Props> = ({ objektId, bestandseinheitId, reloadBestandseinheit }) => {
  const {
    data,
    loading,
    refetch: reloadVersionList,
  } = useBeNutzungsartVersionListQuery({
    variables: {
      objektId,
      bestandseinheitId,
    },
  });
  const nutzungsartVersionList = data?.getBeNutzungsArtVersionList?.data ?? [];

  const onActionSuccess = () => {
    reloadBestandseinheit();
    reloadVersionList();
  };

  const [runDelete] = useDeleteBeNutzungsArtMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Nutzfläche`);
      onActionSuccess();
    },
    refetchQueries: () => [
      gqlOperations.Query.BestandseinheitAufteilungsschluesselList,
      gqlOperations.Query.BestandseinheitAufteilungsschluesselWertAenderungList,
    ],
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  if (loading) {
    return <StyledSkeleton loading={loading} active paragraph={{ rows: 4 }} />;
  }

  return (
    <VersionTimeline<BeNutzungsArt>
      dataSource={nutzungsartVersionList}
      versionIdKey="beNutzungsArtId"
      defaultNewTimeblock={{
        beNutzungsArtId: '',
        nutzungsArt: { text: 'Wohnen', value: NutzungsArt.Wohnen },
        vertragId: '',
        vertragKurzBezeichnung: '',
        warningList: [],
      }}
      onDelete={(timeblock) => {
        return runDelete({
          variables: {
            objektId,
            bestandseinheitId,
            beNutzungsArtId: timeblock.beNutzungsArtId,
          },
        });
      }}
      isAllowedToDeleteInitialTimeblock={false}
      renderForm={(timeblock) => (
        <NutzungsartVersionForm objektId={objektId} bestandseinheitId={bestandseinheitId} timeblock={timeblock} onSubmitSuccess={onActionSuccess} />
      )}
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      renderCardContent={(timeblock) => (
        <NutzungsartVersionCardContent timeblock={timeblock} objektId={objektId} bestandseinheitId={bestandseinheitId} />
      )}
    />
  );
};

export default NutzungsartVersionTimeline;
