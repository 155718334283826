import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

/**
 * <h2>Usage</h2>
 * As listing filter or in Create/Update form where an Object has to be chosen from a dropdown list.
 *
 */

type Props = {
  items: string[];
  maxTextLength?: number;
  width?: string;
  onListChange?: () => void;
} & SelectProps;

const ObjektSelect: FC<Props> = ({ items, maxTextLength, width, onListChange, ...restProps }) => {
  return (
    <Select
      id={restProps.name}
      placeholder="Objekt auswählen"
      showSearch
      filterOption={selectFilterOption}
      allowClear
      style={{ width: width || '100%' }}
      {...restProps}
    >
      {items.map((item) => (
        <Select.Option key={item} value={item}>
          {maxTextLength ? <DataWithShortenedText maxTextLength={maxTextLength} text={item} /> : <Typography.Text>{item}</Typography.Text>}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ObjektSelect;
