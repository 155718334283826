import React, { FC, useEffect, useState } from 'react';
import { Steps, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { mapTextbausteinListToFormValues, TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import FormButtons from '../../../../components/Button/FormButtons';
import { Spacer } from '../../../../components/Grid';
import theme from '../../../../theme';
import { AuftragsartType } from '../../../../types';
import { showSuccessMsgCreate } from '../../../../components/message';
import { useCreateAuftragsartTemplateMutation } from '../../gql/AuftragsartTemplateMutations.types';
import AuftragsartTypeSelectForm from '../../../Auftragsart/Form/Create/TypeSelectForm/AuftragsartTypeSelectForm';
import { useAuftragsartTemplatesLazyQuery } from '../../../Auftragsart/gql/AuftragsartQueries.types';
import { mapCreateFormValuesToAuftragsart } from '../../../Auftragsart/Form/Create/auftragsartCreateFormMapper';
import { AuftragsartCreateParamFormValues } from '../../../Auftragsart/Form/Create/ParameterForm/auftragsartCreateParamFormMapper';
import AuftragsartTemplateCreateTemplateForm from './Template/AuftragsartTemplateCreateTemplateForm';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import AuftragsartTemplateCreateParamForm from './ParameterForm/AuftragsartTemplateCreateParamForm';

const AuftragsartTemplateCreateForm: FC = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [auftragsartType, setAuftragsartType] = useState<AuftragsartType | undefined>(undefined);
  const [paramFormValues, setParamFormValues] = useState<AuftragsartCreateParamFormValues>();
  // with React v18 we could use this:
  // const deferredAuftragsartType = useDeferredValue(auftragsartType);
  const [auftragsartTemplateValues, setAuftragsartTemplateValues] = useState<TextbausteinFormValues[]>();

  const [runAuftragsartTemplatesQuery] = useAuftragsartTemplatesLazyQuery();

  useEffect(() => {
    if (auftragsartType) {
      runAuftragsartTemplatesQuery({
        variables: {
          auftragsartType,
        },
      }).then((res) => {
        const textbausteinList = res.data?.getAuftragsartTemplates.data;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setAuftragsartTemplateValues(mapTextbausteinListToFormValues(textbausteinList!));
      });
    }
  }, [auftragsartType, runAuftragsartTemplatesQuery]);

  const [createAuftragsart] = useCreateAuftragsartTemplateMutation();

  const onCreateAuftragsart = (type: AuftragsartType, values: AuftragsartCreateParamFormValues) => {
    createAuftragsart({
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        input: mapCreateFormValuesToAuftragsart(type, values, auftragsartTemplateValues!),
      },
    }).then(() => {
      showSuccessMsgCreate('Auftragsart');
      navigate(URI_ANDROMEDA_SYS_SETTINGS.auftragsartPage);
    });
  };

  const auftragsartTypeEnumValue = Object.entries(AuftragsartType).find(([, value]) => value === auftragsartType);
  // Inserts space before capital letters
  const bezeichnung = auftragsartTypeEnumValue?.[0].replace(/([A-Z])/g, ' $1');

  let content;
  if (activeStep === 0) {
    content = (
      <AuftragsartTypeSelectForm
        auftragsartTypeValue={auftragsartType}
        onSubmit={(formValues) => {
          setAuftragsartType(formValues);
          setActiveStep(1);
        }}
        onCancel={() => navigate(URI_ANDROMEDA_SYS_SETTINGS.auftragsartPage)}
      />
    );
  } else if (activeStep === 1) {
    if (!auftragsartType) {
      content = <SomethingWentWrongMsg onBack={() => setActiveStep(0)} okText="Weiter" />;
    } else {
      content = (
        <AuftragsartTemplateCreateParamForm
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          auftragsartBezeichnung={bezeichnung!}
          auftragsartType={auftragsartType}
          paramFormValues={paramFormValues}
          setParamFormValues={setParamFormValues}
          onSubmit={() => setActiveStep(2)}
          onCancel={() => setActiveStep(0)}
        />
      );
    }
  } else {
    // activeStep === 2
    const onBack = () => setActiveStep(1);

    if (!auftragsartType || !paramFormValues || !auftragsartTemplateValues) {
      content = <SomethingWentWrongMsg onBack={onBack} />;
    } else {
      content = (
        <AuftragsartTemplateCreateTemplateForm
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          auftragsartBezeichnung={bezeichnung!}
          auftragsartTemplateValues={auftragsartTemplateValues}
          setAuftragsartTemplateValues={setAuftragsartTemplateValues}
          showUstBlock={paramFormValues.showUstBlock}
          onBack={onBack}
          onSubmit={() => onCreateAuftragsart(auftragsartType, paramFormValues)}
        />
      );
    }
  }

  return (
    <>
      <Steps type="navigation" size="small" current={activeStep} className="site-navigation-steps">
        <Steps.Step title="Auftragsart-Auswahl" />
        <Steps.Step title="Allgemeine Parameter" />
        <Steps.Step title="PDF-Vorlage" />
      </Steps>
      {content}
    </>
  );
};

const SomethingWentWrongMsg: FC<{ onBack: () => void; okText?: string }> = ({ onBack, okText }) => (
  <>
    <Spacer height={16} />
    <Typography.Title level={5} style={{ textAlign: 'center', color: theme.colors.overlay }}>
      Hoppala! Etwas ist schiefgelaufen.{' '}
      <span role="img" aria-label="confused">
        😕
      </span>
      <br />
      Probieren Sie es bitte noch einmal.
    </Typography.Title>
    <Spacer height={16} />
    <FormButtons onCancel={onBack} updateMode={false} cancelText="Zurück" okText={okText} isOkDisabled />
  </>
);
export default AuftragsartTemplateCreateForm;
