import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { BeOptionSteuerpflichtVersion, BeOptionSteuerpflichtVersionInput } from '../../../../../types';
import { ValuesDescriptionForKommentar } from '../../../../../components/Timeline/versionTimelineHelper';

export type BeOptionSteuerpflichtVersionFormValues = {
  validFrom: string;
  kommentar: string;
  steuerpflicht: boolean;
};

export const beOptionSteuerpflichtVersionFormFields: FormFields<BeOptionSteuerpflichtVersionFormValues> = {
  validFrom: 'validFrom',
  kommentar: 'kommentar',
  steuerpflicht: 'steuerpflicht',
};

export const mapTimeblockToFormValues = (timeblock: ITimeblock<BeOptionSteuerpflichtVersion>): BeOptionSteuerpflichtVersionFormValues => ({
  validFrom: timeblock.validFrom,
  kommentar: timeblock.kommentar ?? '',
  steuerpflicht: timeblock.optionSteuerpflicht ?? false,
});

export const mapFormValuesToBeOptionSteuerpflichtVersion = (values: BeOptionSteuerpflichtVersionFormValues): BeOptionSteuerpflichtVersionInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  kommentar: values.kommentar,
  optionSteuerpflicht: values.steuerpflicht,
});

export const steuerpflichtVersionFormValuesDescriptionsForKommentar: ValuesDescriptionForKommentar<BeOptionSteuerpflichtVersionFormValues> = {
  validFrom: 'Gültigkeitsdatum',
  steuerpflicht: 'Steuerpflicht',
};
