import { FormFields } from '../../../../../../helpers/formikHelper';
import { Address, AddressType } from '../../../../../../types';

export interface PostofficeBoxAddressUpdateFormValues {
  addressId: string;
  type: AddressType.PostofficeBox;
  countryCodeIso2: string;
  city: string;
  cityAdditionalInformation: string;
  postofficeBoxNumber: string;
  zipCode: string;
}

export const postofficeBoxAddressUpdateFormFields: FormFields<PostofficeBoxAddressUpdateFormValues> = {
  addressId: 'addressId',
  type: 'type',
  countryCodeIso2: 'countryCodeIso2',
  city: 'city',
  cityAdditionalInformation: 'cityAdditionalInformation',
  postofficeBoxNumber: 'postofficeBoxNumber',
  zipCode: 'zipCode',
};

export const mapPostofficeBoxAddressToFormValues = (values: Address): PostofficeBoxAddressUpdateFormValues => ({
  addressId: values.addressId,
  type: AddressType.PostofficeBox,
  countryCodeIso2: values.countryCodeIso2,
  city: values.city,
  cityAdditionalInformation: values.cityAdditionalInformation ?? '',
  postofficeBoxNumber: values.postofficeBoxNumber ?? '',
  zipCode: values.postofficeBoxZipCode ?? '',
});
