import React, { FC, useState } from 'react';
import { Select, Tag } from 'antd';
import {
  addHauptToTagsIfNeccessary,
  containsHauptTag,
  getTagColor,
  HAUPT_TAG_TEXT,
  isTagHauptTag,
  removeHauptTagDuplicatesFromTags,
} from '../../../../../components/Tag/tagHelpers';
import { capitalizeString } from '../../../../../helpers/stringHelper';
import { AddressAndContactTagListInput } from '../../../../../types';

type Props = {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  setTagList: (request: AddressAndContactTagListInput) => void;
  loading: boolean;
  canBeHaupt?: boolean | null;
};

const AddressOrContactListItemTags: FC<Props> = ({ tags, setTags, setTagList, loading, canBeHaupt }) => {
  const initialTagsForOptions = addHauptToTagsIfNeccessary(canBeHaupt !== false && !containsHauptTag(tags), tags).filter(
    (tag) => isTagHauptTag(tag) || !isTagHauptTag(capitalizeString(tag))
  );
  const [tagsForOptions, setTagsForOptions] = useState(initialTagsForOptions);

  const tagRender = (props: { label: React.ReactNode; value: any; onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void }) => {
    const { label, value, onClose } = props;

    const onPreventMouseDown = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
      event.preventDefault();
      event.stopPropagation();
    };

    if (value && containsHauptTag(tagsForOptions) && !isTagHauptTag(value) && isTagHauptTag(capitalizeString(value))) {
      return <></>;
    }

    return (
      <Tag
        color={getTagColor(typeof label === 'string' ? label : value)}
        onMouseDown={onPreventMouseDown}
        closable
        onClose={onClose}
        style={{
          margin: 2,
        }}
      >
        {isTagHauptTag(value) ? value : label}
      </Tag>
    );
  };

  const handleSelectionChange = (tagList: string[]) => {
    const tagsWithoutHauptTagDuplicates = removeHauptTagDuplicatesFromTags(tagList, canBeHaupt ?? true);
    setTags(tagsWithoutHauptTagDuplicates);

    const newTagsForOptions = addHauptToTagsIfNeccessary(
      canBeHaupt !== false && !containsHauptTag(tagsWithoutHauptTagDuplicates),
      tagsWithoutHauptTagDuplicates
    );
    setTagsForOptions(newTagsForOptions);

    const request = { tagList: newTagsForOptions.filter((tag) => tag !== HAUPT_TAG_TEXT), haupt: containsHauptTag(tagsWithoutHauptTagDuplicates) };
    setTagList(request);
  };

  return (
    <Select
      id="tags"
      mode="tags"
      defaultValue={tags}
      loading={loading}
      tagRender={tagRender}
      value={tags}
      style={{ minWidth: '100%' }}
      onChange={handleSelectionChange}
    >
      {tagsForOptions.map((tag) => {
        const selectOptionTag = isTagHauptTag(tag) ? <Tag color="blue">{tag}</Tag> : tag;
        return (
          <Select.Option key={tag} value={tag} label={tag}>
            {selectOptionTag}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default AddressOrContactListItemTags;
