import React from 'react';
import { Space, Tag } from 'antd';
import { Radio } from 'formik-antd';

import { EuroAmount } from '../../../../../../../components/Number';
import { vorschreibungsPositionWertFormFields, VorschreibungspositionWertFormValues } from './vertragVorschreibungsPositionWertMapper';
import { VorschreibungspositionBasis } from '../../../../../../../types';
import VertragVorschreibungsPositionWertActions from './VertragVorschreibungsPositionWertActions';
import { EditableTableColumnProps } from '../../../../../../../components/Table/EditableTableOld/EditableTableProps';
import { ActionButtons, BruttoFormItem, NettoFormItem, Steuersatz, Ust, ValidFrom } from './EditMode';
import EntityReasonChange from '../../../../../../../components/message/EntityReasonChange';
import { BeVertragVorschreibungsPositionWertFieldsFragment } from '../../gql/BeVertragVorschreibungspositionFragments.types';
import SteuersatzAdminLink from '../SteuersatzAdminLink';
import FormattedDateTime from '../../../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../../../components/Card';
import CustomFormattedDate from '../../../../../../../components/FormattedDate/CustomFormattedDate';
import { UstKategorieWithStatus } from '../../../../../../Ust/UstKategorie/ustKategorieUriHelper';

export const vertragVorschreibungsPositionWertTableColumns = (
  objektId: string,
  bestandseinheitId: string,
  vertragId: string,
  vorschreibungspositionId: string,
  onDeleteSuccess: () => void,
  vertragsBeginn: string,
  wertList: BeVertragVorschreibungsPositionWertFieldsFragment[]
): EditableTableColumnProps<BeVertragVorschreibungsPositionWertFieldsFragment, VorschreibungspositionWertFormValues>[] => [
  { title: '', width: 210 },
  {
    title: 'Gültig ab',
    key: 'gueltigAb',
    width: 140,
    render: (text, record) => {
      return (
        <Space>
          <CustomFormattedDate value={record.validFrom} />
          <EntityReasonChange reasonChange={record.changeReason} />
          {record.current ? <Tag>Aktiv</Tag> : null}
        </Space>
      );
    },
    editable: true,
    renderInEditMode: () => <ValidFrom vertragsBeginn={vertragsBeginn} wertList={wertList} />,
  },
  {
    title: 'Basis',
    key: 'basis',
    dataIndex: ['basis', 'text'],
    width: 110,
    editable: true,
    renderInEditMode: () => (
      <Radio.Group name={vorschreibungsPositionWertFormFields.basis} buttonStyle="solid" size="small" block>
        <Radio.Button value={VorschreibungspositionBasis.Brutto}>Brutto</Radio.Button>
        <Radio.Button value={VorschreibungspositionBasis.Netto}>Netto</Radio.Button>
      </Radio.Group>
    ),
  },
  {
    title: 'Netto',
    key: 'netto',
    width: 90,
    align: 'right',
    render: (text, record) => <EuroAmount value={record.netto} />,
    editable: true,
    renderInEditMode: () => <NettoFormItem />,
  },
  {
    title: 'Steuersatz',
    key: 'steuersatz',
    width: 90,
    align: 'right',
    editable: true,
    render: (text, record) => (
      <div style={{ justifyContent: 'right' }}>
        <SteuersatzAdminLink
          ustKategorie={record.ustKategorie as UstKategorieWithStatus}
          ustKategorieEntryId={record.ustKategorieEntry?.ustKategorieEntryId}
          steuersatz={record.steuersatz}
        />
      </div>
    ),
    renderInEditMode: ({ selectedRecord }) => (
      <div style={{ justifyContent: 'right' }}>
        <Steuersatz
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          vorschreibungspositionId={vorschreibungspositionId}
          selectedRecord={selectedRecord}
        />
      </div>
    ),
  },
  {
    title: 'Ust',
    key: 'ust',
    width: 90,
    align: 'right',
    render: (text, record) => <EuroAmount value={record.ust} />,
    editable: true,
    renderInEditMode: () => <Ust />,
  },
  {
    title: 'Brutto',
    key: 'brutto',
    width: 90,
    align: 'right',
    render: (text, record) => <EuroAmount value={record.brutto} />,
    editable: true,
    renderInEditMode: () => <BruttoFormItem />,
  },
  {
    title: 'Erstellt am',
    width: 120,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    width: 80,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    key: 'action',
    width: 100,
    render: (text, record) => (
      <VertragVorschreibungsPositionWertActions
        vorschreibungsPositionWert={record}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
        vorschreibungspositionId={vorschreibungspositionId}
        onDeleteSuccess={onDeleteSuccess}
      />
    ),
    editable: true,
    renderInEditMode: ({ cancelEdit, isSubmitLoading }) => (
      <ActionButtons
        cancelEdit={cancelEdit}
        isSubmitLoading={isSubmitLoading}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
        vorschreibungspositionId={vorschreibungspositionId}
      />
    ),
  },
];
