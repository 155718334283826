import Fetcher from '../../helpers/fetcher';
import { processAndReturnError, processResponse, returnData } from '../../helpers/APIUtils';
import { FirmendatenFormValues } from '../../features/KundenSystem/Firmendaten/form/fimendatenFormMapper';
import { FirmaFormValues } from '../../features/Rechtstraeger/Firma/Form/firmaFormMapper';
import { NatuerlichePersonFormValues } from '../../features/Rechtstraeger/NatuerlichePerson/Form/natuerlichePersonFormMapper';
import { PersonGemeinschaftFormValues } from '../../features/Rechtstraeger/PersonenGemeinschaft/Form/personenGemeinschaftFormMapper';

const getKurzBezeichnungVorschlagText = (
  uri: string,
  kurzBezeichnungVorschlagFields: FirmendatenFormValues | FirmaFormValues | NatuerlichePersonFormValues | PersonGemeinschaftFormValues
) =>
  Fetcher.getInstance()
    .fetch(uri, {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify({
        data: kurzBezeichnungVorschlagFields,
      }),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then(processResponse)
    .then(returnData)
    .catch(processAndReturnError);

export default getKurzBezeichnungVorschlagText;
