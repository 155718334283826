import React, { FC } from 'react';
import { Button, Divider, Popover, Space, Typography } from 'antd';
import { SplitCellsOutlined } from '@ant-design/icons';
import { ColSelectorPopoverGlobalStyles } from './styled/ColSelectorPopoverGlobalStyles';
import theme from '../../../theme';
import ColumnSelectorCheckboxFilter from './ColumnSelectorCheckboxFilter';
import { ColumnSelectorSetting, TableWithColSelectorType } from './tableWithColSelectorHelpers';

type ColumnSelectorProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  selectedColumnSelectorType: TableWithColSelectorType;
  allColumnTitles: (string | string[])[];
  selectedUserColumnTitles: string[];
  defaultUserColumnsTitles: string[];
  selectedSystemColumnTitles: string[];
  defaultSystemColumnsTitles: string[];
  onChangeSelectorType: (columnSelectorType: TableWithColSelectorType) => void;
  onSelectionChange: (selectedColumnSettings: ColumnSelectorSetting[]) => void;
  onRestoreDefault: () => void;
};

const ColumnSelector: FC<ColumnSelectorProps> = ({
  isOpen,
  onOpenChange,
  selectedColumnSelectorType,
  allColumnTitles,
  selectedUserColumnTitles,
  defaultUserColumnsTitles,
  selectedSystemColumnTitles,
  defaultSystemColumnsTitles,
  onChangeSelectorType,
  onSelectionChange,
  onRestoreDefault,
}) => {
  const isSelectedColumnSelectorType = (columnSelectorType: TableWithColSelectorType) => selectedColumnSelectorType === columnSelectorType;

  const onClickSelectorType = (columnSelectorType: TableWithColSelectorType) => {
    onChangeSelectorType(columnSelectorType);
  };
  return (
    <>
      <ColSelectorPopoverGlobalStyles />
      <Popover
        overlayClassName="col-selector-popover"
        title="Spalten auswählen"
        trigger="click"
        open={isOpen}
        onOpenChange={onOpenChange}
        placement="bottom"
        content={
          <Space direction="vertical">
            <Button type="link" style={{ width: '100%' }} onClick={onRestoreDefault}>
              Standardspalten auswählen
            </Button>
            <Divider style={{ margin: 0, padding: 0 }} />
            <Space style={{ width: '100%', justifyContent: 'center' }}>
              <Typography.Text style={columnSelectorTypeStyles(isSelectedColumnSelectorType('user'))} onClick={() => onClickSelectorType('user')}>
                Meine Auswahl
              </Typography.Text>
              <Divider type="vertical" style={{ margin: 0, padding: 0 }} />
              <Typography.Text style={columnSelectorTypeStyles(isSelectedColumnSelectorType('system'))} onClick={() => onClickSelectorType('system')}>
                System
              </Typography.Text>
            </Space>
            <Divider style={{ margin: 0, padding: 0 }} />
            <ColumnSelectorCheckboxFilter
              allColumnTitles={allColumnTitles}
              defaultSelectedColumnsTitles={isSelectedColumnSelectorType('user') ? defaultUserColumnsTitles : defaultSystemColumnsTitles}
              onSelectionChange={onSelectionChange}
              selectedColumns={isSelectedColumnSelectorType('user') ? selectedUserColumnTitles : selectedSystemColumnTitles}
            />
          </Space>
        }
      >
        <Button>
          Spalten <SplitCellsOutlined />
        </Button>
      </Popover>
    </>
  );
};

const columnSelectorTypeStyles = (isSelected: boolean): React.CSSProperties => ({
  color: isSelected ? ACTIVE_COLOR : 'inherit',
  backgroundColor: isSelected ? ACTIVE_BACKGROUND_COLOR : 'inherit',
  padding: '10px',
  cursor: 'pointer',
  width: 105,
  display: 'block',
  textAlign: 'center',
});

const ACTIVE_COLOR = theme.linkColor.default;
const ACTIVE_BACKGROUND_COLOR = theme.tableBackgroundColors.active;

export default ColumnSelector;
