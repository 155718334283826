import React, { FC } from 'react';
import { Space, Spin } from 'antd';
import { useBookingSuggestionQuery } from '../gql/BookingSuggestionQueries.types';
import BuchungsmaskeForm from '../Edit/Form/BuchungsmaskeForm';

type Props = {
  bookingSuggestionId: string;
};

const BookingSuggestionBuchungsmaske: FC<Props> = ({ bookingSuggestionId }) => {
  const { data, refetch } = useBookingSuggestionQuery({
    variables: {
      bookingSuggestionId,
    },
  });

  const bookingSuggestion = data?.getBookingSuggestion.data;

  if (!bookingSuggestion)
    return (
      <Space style={{ width: '100%', justifyContent: 'center' }}>
        <Spin />
      </Space>
    );

  return <BuchungsmaskeForm bookingSuggestion={bookingSuggestion} onUpdateSuccess={refetch} />;
};

export default BookingSuggestionBuchungsmaske;
