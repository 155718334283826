import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFirmendatenIndexSeriesMutationVariables = Types.Exact<{
  input: Types.IndexSeriesCreateInput;
}>;

export type CreateFirmendatenIndexSeriesMutation = {
  createFirmendatenIndexSeries: {
    data: { indexSeriesId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateFirmendatenIndexSeriesMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['ID']['input'];
  input: Types.IndexSeriesUpdateInput;
}>;

export type UpdateFirmendatenIndexSeriesMutation = {
  updateFirmendatenIndexSeries: {
    data: { indexSeriesId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteFirmendatenIndexSeriesMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['ID']['input'];
}>;

export type DeleteFirmendatenIndexSeriesMutation = {
  deleteFirmendatenIndexSeries: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveFirmendatenIndexSeriesMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['ID']['input'];
}>;

export type ArchiveFirmendatenIndexSeriesMutation = {
  actionArchiveFirmendatenIndexSeries: {
    data: { indexSeriesId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveFirmendatenIndexSeriesMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveFirmendatenIndexSeriesMutation = {
  actionUnarchiveFirmendatenIndexSeries: {
    data: { indexSeriesId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateFirmendatenIndexSeriesDocument = gql`
  mutation CreateFirmendatenIndexSeries($input: IndexSeriesCreateInput!) {
    createFirmendatenIndexSeries(input: $input) {
      data {
        indexSeriesId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmendatenIndexSeriesMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmendatenIndexSeriesMutation, CreateFirmendatenIndexSeriesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmendatenIndexSeriesMutation, CreateFirmendatenIndexSeriesMutationVariables>(
    CreateFirmendatenIndexSeriesDocument,
    options
  );
}
export type CreateFirmendatenIndexSeriesMutationHookResult = ReturnType<typeof useCreateFirmendatenIndexSeriesMutation>;
export type CreateFirmendatenIndexSeriesMutationResult = Apollo.MutationResult<CreateFirmendatenIndexSeriesMutation>;
export type CreateFirmendatenIndexSeriesMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmendatenIndexSeriesMutation,
  CreateFirmendatenIndexSeriesMutationVariables
>;
export const UpdateFirmendatenIndexSeriesDocument = gql`
  mutation UpdateFirmendatenIndexSeries($indexSeriesId: ID!, $input: IndexSeriesUpdateInput!) {
    updateFirmendatenIndexSeries(indexSeriesId: $indexSeriesId, input: $input) {
      data {
        indexSeriesId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateFirmendatenIndexSeriesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFirmendatenIndexSeriesMutation, UpdateFirmendatenIndexSeriesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmendatenIndexSeriesMutation, UpdateFirmendatenIndexSeriesMutationVariables>(
    UpdateFirmendatenIndexSeriesDocument,
    options
  );
}
export type UpdateFirmendatenIndexSeriesMutationHookResult = ReturnType<typeof useUpdateFirmendatenIndexSeriesMutation>;
export type UpdateFirmendatenIndexSeriesMutationResult = Apollo.MutationResult<UpdateFirmendatenIndexSeriesMutation>;
export type UpdateFirmendatenIndexSeriesMutationOptions = Apollo.BaseMutationOptions<
  UpdateFirmendatenIndexSeriesMutation,
  UpdateFirmendatenIndexSeriesMutationVariables
>;
export const DeleteFirmendatenIndexSeriesDocument = gql`
  mutation DeleteFirmendatenIndexSeries($indexSeriesId: ID!) {
    deleteFirmendatenIndexSeries(indexSeriesId: $indexSeriesId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteFirmendatenIndexSeriesMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFirmendatenIndexSeriesMutation, DeleteFirmendatenIndexSeriesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFirmendatenIndexSeriesMutation, DeleteFirmendatenIndexSeriesMutationVariables>(
    DeleteFirmendatenIndexSeriesDocument,
    options
  );
}
export type DeleteFirmendatenIndexSeriesMutationHookResult = ReturnType<typeof useDeleteFirmendatenIndexSeriesMutation>;
export type DeleteFirmendatenIndexSeriesMutationResult = Apollo.MutationResult<DeleteFirmendatenIndexSeriesMutation>;
export type DeleteFirmendatenIndexSeriesMutationOptions = Apollo.BaseMutationOptions<
  DeleteFirmendatenIndexSeriesMutation,
  DeleteFirmendatenIndexSeriesMutationVariables
>;
export const ArchiveFirmendatenIndexSeriesDocument = gql`
  mutation ArchiveFirmendatenIndexSeries($indexSeriesId: ID!) {
    actionArchiveFirmendatenIndexSeries(indexSeriesId: $indexSeriesId) {
      data {
        indexSeriesId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveFirmendatenIndexSeriesMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveFirmendatenIndexSeriesMutation, ArchiveFirmendatenIndexSeriesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveFirmendatenIndexSeriesMutation, ArchiveFirmendatenIndexSeriesMutationVariables>(
    ArchiveFirmendatenIndexSeriesDocument,
    options
  );
}
export type ArchiveFirmendatenIndexSeriesMutationHookResult = ReturnType<typeof useArchiveFirmendatenIndexSeriesMutation>;
export type ArchiveFirmendatenIndexSeriesMutationResult = Apollo.MutationResult<ArchiveFirmendatenIndexSeriesMutation>;
export type ArchiveFirmendatenIndexSeriesMutationOptions = Apollo.BaseMutationOptions<
  ArchiveFirmendatenIndexSeriesMutation,
  ArchiveFirmendatenIndexSeriesMutationVariables
>;
export const UnarchiveFirmendatenIndexSeriesDocument = gql`
  mutation UnarchiveFirmendatenIndexSeries($indexSeriesId: ID!) {
    actionUnarchiveFirmendatenIndexSeries(indexSeriesId: $indexSeriesId) {
      data {
        indexSeriesId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveFirmendatenIndexSeriesMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveFirmendatenIndexSeriesMutation, UnarchiveFirmendatenIndexSeriesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveFirmendatenIndexSeriesMutation, UnarchiveFirmendatenIndexSeriesMutationVariables>(
    UnarchiveFirmendatenIndexSeriesDocument,
    options
  );
}
export type UnarchiveFirmendatenIndexSeriesMutationHookResult = ReturnType<typeof useUnarchiveFirmendatenIndexSeriesMutation>;
export type UnarchiveFirmendatenIndexSeriesMutationResult = Apollo.MutationResult<UnarchiveFirmendatenIndexSeriesMutation>;
export type UnarchiveFirmendatenIndexSeriesMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveFirmendatenIndexSeriesMutation,
  UnarchiveFirmendatenIndexSeriesMutationVariables
>;
