import React from 'react';
import { Typography } from 'antd';
import FirmendatenBankSettingsCard from '../../../BankSettings/FirmendatenBankSettingsCard';

const RechnungsAusstellerGeneralInfo = () => {
  return (
    <>
      <Typography.Title level={4}>Allgemein</Typography.Title>
      <br />
      <FirmendatenBankSettingsCard />
    </>
  );
};

export default RechnungsAusstellerGeneralInfo;
