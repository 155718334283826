import * as Yup from 'yup';
import { entityMaxCharLength } from '../../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */
const companyRegistrationNumberValidationSchema = Yup.string().nullable().max(30, entityMaxCharLength('Firmenbuchnummer', 30));

export const companyRegistrationNumberFormPartValidationSchema = Yup.object().shape({
  companyRegistrationNumber: companyRegistrationNumberValidationSchema,
});

/* eslint-enable no-template-curly-in-string */
