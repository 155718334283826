import { FC, useContext } from 'react';
import { Formik } from 'formik';
import { Divider } from 'antd';
import { Form } from 'formik-antd';
import { IndexationAgreementContext } from '../../IndexationAgreementContext';
import { showSuccessMsgCreate } from '../../../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../../../helpers/apolloHelper';
import {
  useCreateVertragVposIndexationAgreementCategoryMutation,
  useUpdateVertragVposIndexationAgreementCategoryMutation,
} from '../../../../gql/VPosIndexationAgreementMutations.types';
import {
  indAgreeCategoryFormFields,
  IndexationAgreementCategoryFormValues,
  mapCategoryToFormValues,
  mapFormValuesToCategory,
} from './categoryFormMapper';
import { categoryFormValidationSchema } from './categoryFormValidationSchema';
import FormButtons from '../../../../../../../../../components/Button/FormButtons';
import IndexBlock from './IndexBlock';
import { ValuePreservationType, VertragVposIndexationAgreementCategory } from '../../../../../../../../../types';
import { useSetIndexOld } from '../shared/useSetIndexOld';
import LastValueBaseBlock from '../shared/LastValueBaseBlock';
import { useGetAppFieldHelpText } from '../../../../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  indexationAgreement?: VertragVposIndexationAgreementCategory;
  onCancel: () => void;
  onSuccess: () => void;
};

const CategoryForm: FC<Props> = ({ indexationAgreement, onCancel, onSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'VertragVposIndexationAgreementCategory'>('VertragVposIndexationAgreementCategory');

  const isUpdate = !!indexationAgreement;
  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);
  const entity = 'Kategorie';

  const [runCreate, { loading: loadingCreate }] = useCreateVertragVposIndexationAgreementCategoryMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateVertragVposIndexationAgreementCategoryMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const { handleIndexSeriesChange, handleMonthYearChange } = useSetIndexOld();

  return (
    <Formik<IndexationAgreementCategoryFormValues>
      initialValues={mapCategoryToFormValues(indexationAgreement)}
      validationSchema={categoryFormValidationSchema}
      onSubmit={(values, formikHelpers) => {
        isUpdate
          ? runUpdate({
              variables: {
                objektId,
                bestandseinheitId,
                vertragId,
                vorschreibungspositionId,
                indexationAgreementId: indexationAgreement.vertragVposIndexationAgreementId,
                input: mapFormValuesToCategory(values),
              },
            }).finally(() => formikHelpers.setSubmitting(false))
          : runCreate({
              variables: {
                objektId,
                bestandseinheitId,
                vertragId,
                vorschreibungspositionId,
                input: mapFormValuesToCategory(values),
              },
            }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <IndexBlock
            formikProps={formikProps}
            onIndexSeriesChange={(indexSeriesId) => handleIndexSeriesChange(indAgreeCategoryFormFields.indexOld, indexSeriesId, formikProps)}
            fieldHelpNames={{
              validFrom: getFieldHelpText('VertragVposIndexationAgreementCategory.validFrom.year'),
              indexSeriesId: getFieldHelpText('VertragVposIndexationAgreementCategory.indexSeriesId'),
              basedOnUsableArea: getFieldHelpText('VertragVposIndexationAgreementCategory.basedOnUsableArea'),
            }}
          />
          <Divider />
          <LastValueBaseBlock
            monthYearName={indAgreeCategoryFormFields.monthYearOld}
            onMonthYearChange={(month, year) => handleMonthYearChange(indAgreeCategoryFormFields.indexOld, month, year, formikProps)}
            indexOldName={indAgreeCategoryFormFields.indexOld}
            valuePreservationType={ValuePreservationType.Category}
            fieldHelpNames={{
              indexOld: getFieldHelpText('VertragVposIndexationAgreementCategory.indexOld'),
              monthYearOld: getFieldHelpText('VertragVposIndexationAgreementCategory.monthYearOld.year'),
            }}
          />
          <FormButtons
            updateMode={isUpdate}
            isOkLoading={loadingCreate || loadingUpdate}
            isOkDisabled={formikProps.isSubmitting}
            onCancel={onCancel}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CategoryForm;
