import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { Zinsliste } from '../../zinsliste-types';
import ZBeVertragInformation from './ZBeVertragInformation';
import ZVPosTableHeader from './ZVPosTableHeader';
import ZVPosTableSumme from './ZVPosTableSumme';
import ZBeVertragTableItems from './ZBeVertragTableItems';

type Props = {
  zinsliste: Zinsliste;
};

const ZBeVertragList: FC<Props> = ({ zinsliste }) => (
  <>
    {zinsliste.bestandseinheitVertragList.map((beVertrag, index) => (
      <View style={[pdfStyles.borderBottomSmall, { margin: '2mm 0' }]} key={index} wrap={false}>
        <ZBeVertragInformation beVertrag={beVertrag} />
        <View style={{ margin: '2mm 0' }}>
          <View style={[pdfStyles.row, { marginTop: '2mm' }]}>
            <Text style={[{ width: '42.84%', fontSize: '11px', fontWeight: 600 }]}>{beVertrag.vertragspartnerBezeichnung}</Text>
            <ZVPosTableHeader vorschreibungspositionText={zinsliste.vorschreibungspositionHeaderText} width="57.16%" />
          </View>
          {beVertrag.vorschreibungspositionList.map((vorschreibungsposition, vposIndex) => (
            <View style={[pdfStyles.row]} key={vposIndex}>
              <Text style={[pdfStyles.textBulletin, { width: '10.28%' }]}>{vorschreibungsposition.kurzbezeichnung}</Text>
              <Text style={[pdfStyles.textBulletin, { width: '32.56%' }]}>{vorschreibungsposition.bezeichnung}</Text>
              <ZBeVertragTableItems vorschreibungsposition={vorschreibungsposition} width="57.16%" />
            </View>
          ))}
          <ZVPosTableSumme vorschreibungspositionSumme={beVertrag.vorschreibungspositionSummeList} />
        </View>
      </View>
    ))}
  </>
);

export default ZBeVertragList;
