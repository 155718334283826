import React, { FC, useState } from 'react';
import { TextbausteinList } from '../../../../components/Template/PDFTemplates/TextbausteinList/TextbausteinList';
import { TextbausteinType } from '../../../../types';
import PDFTemplatePage, { TemplatePreviewProps } from '../../../../components/Template/PDFTemplates/PDFTemplatePage';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { updateTextbausteinListValuesWithType } from '../../../../components/Template/PDFTemplates/templateHelpers';

type TemplateAbrechnungProps = {
  values: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  renderPreview: (props: TemplatePreviewProps) => React.ReactNode;
};

const TemplateAbrechnung: FC<TemplateAbrechnungProps> = ({ values, onTemplateChange, renderPreview }) => {
  const [selectedTextbaustein, setSelectedTextbaustein] = useState<TextbausteinType>();

  const setSelectedTextbausteinToNewOrDefaultValue = (newValue: TextbausteinType) => {
    setSelectedTextbaustein((currentSelected) => (currentSelected !== newValue ? newValue : undefined));
  };

  return (
    <PDFTemplatePage
      textbausteinList={
        <TextbausteinList
          textbausteinList={values}
          onTemplateChange={onTemplateChange}
          onSelectTextbaustein={setSelectedTextbausteinToNewOrDefaultValue}
          onChange={(textbausteinType, newValue) => onTemplateChange(updateTextbausteinListValuesWithType(values, textbausteinType, newValue))}
          level={0}
        />
      }
      renderPreview={renderPreview}
      selectedTextbaustein={selectedTextbaustein}
    />
  );
};

export default TemplateAbrechnung;
