import React, { FC } from 'react';
import { WizardStepForm } from '../../../components/Wizard';
import { kundenSystemBaseValidationSchema } from './Form/kundenSystemFieldsValidationSchema';
import KundenSystemBaseFormPart from './KundenSystemBaseFormPart';
import { mapKundenSystemToFormValues } from './Form/kundenSystemMapper';

type KundenSystemBaseWizardStepFormProps = {
  stepId: string;
};

const KundenSystemBaseWizardStepForm: FC<KundenSystemBaseWizardStepFormProps> = ({ stepId }) => (
  <WizardStepForm
    stepId={stepId}
    formInitialValues={mapKundenSystemToFormValues()}
    formValidationSchema={kundenSystemBaseValidationSchema}
    formValidateOnChange={false}
    heading="Systemdaten"
    render={() => <KundenSystemBaseFormPart />}
  />
);

export default KundenSystemBaseWizardStepForm;
