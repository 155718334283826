import { FormFields } from '../../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../../shared/InfoFeld/infoFeldFormMapper';
import { KuendigungDurch } from '../../../../../../../types';

export interface KuendigungsinfosCreateFormValues extends InfoFeldFormValues {
  kuendigungBis: string;
  kuendigungsverzichtBestandgeberBis?: string;
  kuendigungsverzichtBestandnehmerBis?: string;
  bestandnehmerKuendigungsfrist?: number;
  kuendigungJeweilsZum?: string;
  kuendigungMoeglichZum?: string;
  gerichtlicheKuendigung?: boolean;
  kuendigungErfolgtAm?: string;
  kuendigungWirksamZum?: string;
  kuendigungDurch?: KuendigungDurch;
}

export const kuendigungsinfosCreateFormFields: FormFields<KuendigungsinfosCreateFormValues> = {
  kuendigungBis: 'kuendigungBis',
  kuendigungsverzichtBestandgeberBis: 'kuendigungsverzichtBestandgeberBis',
  kuendigungsverzichtBestandnehmerBis: 'kuendigungsverzichtBestandnehmerBis',
  bestandnehmerKuendigungsfrist: 'bestandnehmerKuendigungsfrist',
  kuendigungJeweilsZum: 'kuendigungJeweilsZum',
  kuendigungMoeglichZum: 'kuendigungMoeglichZum',
  gerichtlicheKuendigung: 'gerichtlicheKuendigung',
  kuendigungErfolgtAm: 'kuendigungErfolgtAm',
  kuendigungWirksamZum: 'kuendigungWirksamZum',
  kuendigungDurch: 'kuendigungDurch',
  documentList: 'documentList',
};

export const kuendigungsinfosCreateFormInitialValues: KuendigungsinfosCreateFormValues = {
  documentList: [],
  kuendigungBis: '',
};
