import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = {
  list: SyncEbicsUserTeilnehmerFormSelectProps[];
  width?: string;
} & SelectProps;

export interface SyncEbicsUserTeilnehmerFormSelectProps {
  bezeichnung: string;
}

const SyncEbicsUserTeilnehmerFormSelect: FC<Props> = ({ list, name, width, ...restProps }) => {
  return (
    <Select
      size="small"
      placeholder="Teilnehmer auswählen"
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      style={{ width: width || '100%' }}
      {...restProps}
    >
      {list.map((teilnehmer) => (
        <Select.Option key={teilnehmer.bezeichnung} value={teilnehmer.bezeichnung}>
          <DataWithShortenedText maxTextLength={25} text={teilnehmer.bezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default SyncEbicsUserTeilnehmerFormSelect;
