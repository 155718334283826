import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActivateEbicsUserIbanMutationVariables = Types.Exact<{
  ebicsUserId: Types.Scalars['ID']['input'];
  ebicsUserIbanId: Types.Scalars['ID']['input'];
}>;

export type ActivateEbicsUserIbanMutation = {
  actionActivateEbicsUserIban: {
    data: { ebicsUserIbanId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeactivateEbicsUserIbanMutationVariables = Types.Exact<{
  ebicsUserId: Types.Scalars['ID']['input'];
  ebicsUserIbanId: Types.Scalars['ID']['input'];
}>;

export type DeactivateEbicsUserIbanMutation = {
  actionDeactivateEbicsUserIban: {
    data: { ebicsUserIbanId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const ActivateEbicsUserIbanDocument = gql`
  mutation ActivateEbicsUserIban($ebicsUserId: ID!, $ebicsUserIbanId: ID!) {
    actionActivateEbicsUserIban(ebicsUserId: $ebicsUserId, ebicsUserIbanId: $ebicsUserIbanId) {
      data {
        ebicsUserIbanId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useActivateEbicsUserIbanMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateEbicsUserIbanMutation, ActivateEbicsUserIbanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateEbicsUserIbanMutation, ActivateEbicsUserIbanMutationVariables>(ActivateEbicsUserIbanDocument, options);
}
export type ActivateEbicsUserIbanMutationHookResult = ReturnType<typeof useActivateEbicsUserIbanMutation>;
export type ActivateEbicsUserIbanMutationResult = Apollo.MutationResult<ActivateEbicsUserIbanMutation>;
export type ActivateEbicsUserIbanMutationOptions = Apollo.BaseMutationOptions<ActivateEbicsUserIbanMutation, ActivateEbicsUserIbanMutationVariables>;
export const DeactivateEbicsUserIbanDocument = gql`
  mutation DeactivateEbicsUserIban($ebicsUserId: ID!, $ebicsUserIbanId: ID!) {
    actionDeactivateEbicsUserIban(ebicsUserId: $ebicsUserId, ebicsUserIbanId: $ebicsUserIbanId) {
      data {
        ebicsUserIbanId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeactivateEbicsUserIbanMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateEbicsUserIbanMutation, DeactivateEbicsUserIbanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeactivateEbicsUserIbanMutation, DeactivateEbicsUserIbanMutationVariables>(DeactivateEbicsUserIbanDocument, options);
}
export type DeactivateEbicsUserIbanMutationHookResult = ReturnType<typeof useDeactivateEbicsUserIbanMutation>;
export type DeactivateEbicsUserIbanMutationResult = Apollo.MutationResult<DeactivateEbicsUserIbanMutation>;
export type DeactivateEbicsUserIbanMutationOptions = Apollo.BaseMutationOptions<
  DeactivateEbicsUserIbanMutation,
  DeactivateEbicsUserIbanMutationVariables
>;
