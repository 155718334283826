import React from 'react';
import { Tag, Typography } from 'antd';
import UstRegelsetTableActionAndDrawer from './UstRegelsetTableActionAndDrawer';
import { generatePathToUstKategoriePage } from '../../UstKategorie/ustKategorieUriHelper';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { compareTwoDatesForSorting } from '../../../../helpers/dateHelper';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { UstKategorieStatusTuple, UstRegelset } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import FirmendatenKurzBezeichnung from '../../../shared/FirmendatenKurzBezeichnung';

export const ustRegelsetTableColumns = (onActionSuccess: () => void): TableWithColSelectorColumnProps<UstRegelset>[] => [
  {
    title: 'Kurzbezeichnung',
    defaultSelected: true,
    render: (text, record) => record.bezeichnung,
  },
  {
    title: 'Firma',
    defaultSelected: true,
    render: (text, record) => <>{record.firmendatenId ? <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} /> : <Tag>System</Tag>}</>,
  },
  {
    title: 'Land',
    defaultSelected: true,
    render: (text, record) => record.land?.bezeichnung ?? null,
  },
  {
    title: 'Nutzungsart',
    defaultSelected: true,
    render: (text, record) => record.nutzungsArt.text,
  },
  {
    title: 'Steuerpflicht',
    defaultSelected: true,
    render: (text, record) => record.steuerpflicht.text,
  },
  {
    title: 'Option zur Steuerpflicht',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.optionSteuerpflicht ? 'ja' : 'nein'}</Typography.Text>,
  },
  {
    title: 'Leerstehung',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.leerstehung ? 'ja' : 'nein'}</Typography.Text>,
  },
  {
    title: 'Eigennutzung',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.eigennutzung ? 'ja' : 'nein'}</Typography.Text>,
  },
  {
    title: 'USt.-Kategorie',
    defaultSelected: true,
    render: (text, record) =>
      /* eslint-disable-next-line no-nested-ternary */
      record.ustKategorie ? (
        <LinkWithStatus
          status={record.ustKategorie.status as UstKategorieStatusTuple}
          text={record.ustKategorie.kurzBezeichnung}
          maxTextLength={30}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          to={generatePathToUstKategoriePage(record.ustKategorie.status!.value, record.ustKategorie.ustKategorieId, {
            ustKategorieId: record.ustKategorie.ustKategorieId,
          })}
        />
      ) : (
        <Tag color="warning">Keine aktive Zuweisung</Tag>
      ),
  },
  {
    title: 'Erstellt am',
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    key: 'action',
    render: (text, record) => <UstRegelsetTableActionAndDrawer onActionSuccess={onActionSuccess} ustRegelset={record} />,
  },
];
