import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { FiltersFormValues, initialFormValues, mapFormValuesToQueryParams } from './Filters/filtersFormMapper';
import { BookingFolderQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import useOnEventsSetStickyFilterSummary from '../../../components/StickyHeader/useOnEventsSetStickyFilterSummary';
import ListingFilters from './Filters/ListingFilters';
import BookingInstructionTable from '../../BookingInstruction/Table/BookingInstructionTable';

const BookingFolderListing = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<BookingFolderQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  const filterRef = useRef<HTMLDivElement>(null);
  const summaryRef = useRef<HTMLDivElement>(null);
  useOnEventsSetStickyFilterSummary([filterRef, summaryRef]);

  return (
    <>
      <Formik<FiltersFormValues> initialValues={initialFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => <ListingFilters formikProps={formikProps} queryParams={queryParams} ref={filterRef} />}
      </Formik>
      {/*TODO: Auflistung der table summary*/}
      {/*<BookingInstructionListSummary queryParams={queryParams} ref={summaryRef} />*/}
      <BookingInstructionTable
        queryParams={queryParams}
        // filterRef={filterRef} summaryRef={summaryRef}
      />
    </>
  );
};

export default BookingFolderListing;
